import { E_SORT_ORDER, ISort } from '@patsnap/synapse_common_interface'
import { TREE_ROOT_ID } from '@pharmsnap/shared/constants'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { IBrowseCountType, IBrowseServiceKey, IBrowseType, IDicCode, IOrderByType, ISearchTreeResItem, ISelectedLabel } from '@pharmsnap/shared/types'
import { ComponentInternalInstance } from '@vue/composition-api'
import { unionBy } from 'lodash'

export const serviceConfig: Record<
  IBrowseType,
  {
    serviceKey: IBrowseServiceKey
    rootId: string
    btnGroup: IOrderByType[]
    dicCode?: IDicCode
    sort: { Hierarchy?: ISort[]; DrugCount?: ISort[]; PatentCount?: ISort[]; LetterIncrease?: ISort[] }
    otherCondition?: (browseCountType?: IBrowseCountType) => string
    customSelect?: (ins: ComponentInternalInstance, type: 'Tree' | 'Self', item: ISearchTreeResItem, selectData: ISelectedLabel[]) => ISelectedLabel[]
  }
> = {
  Target: {
    serviceKey: 'target',
    rootId: TREE_ROOT_ID.target,
    btnGroup: ['Hierarchy', 'DrugCount', 'PatentCount', 'LetterIncrease'],
    sort: {
      Hierarchy: [{ sort_field: 'TARGET_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      PatentCount: [{ sort_field: 'PATENT_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'SHORT_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  Mechanism: {
    serviceKey: 'mechanism',
    rootId: TREE_ROOT_ID.dictionary,
    btnGroup: ['DrugCount', 'LetterIncrease'],
    sort: {
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'SHORT_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  Disease: {
    serviceKey: 'disease',
    rootId: TREE_ROOT_ID.disease,
    btnGroup: ['Hierarchy', 'DrugCount', 'PatentCount', 'LetterIncrease'],
    sort: {
      Hierarchy: [{ sort_field: 'DISEASE_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      PatentCount: [{ sort_field: 'PATENT_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'DISEASE_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  DrugType: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.dictionary,
    btnGroup: ['Hierarchy', 'DrugCount', 'LetterIncrease'],
    dicCode: 'DRUG_TYPE',
    sort: {
      Hierarchy: [{ sort_field: 'DRUG_COUNT_ROLL_UP', sort_order: E_SORT_ORDER.DESC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  Organization: {
    serviceKey: 'organization',
    rootId: '',
    btnGroup: [],
    sort: {
      Hierarchy: [
        {
          sort_field: 'drug_count_roll_up',
          sort_order: E_SORT_ORDER.DESC,
        },
        {
          sort_field: 'name_keyword',
          sort_order: E_SORT_ORDER.ASC,
        },
      ],
    },
  },
  ATC: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.dictionary,
    btnGroup: ['Hierarchy', 'DrugCount', 'LetterIncrease'],
    dicCode: 'ATC_CODE',
    sort: {
      Hierarchy: [{ sort_field: 'ATC_CODE_SORT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'ATC_CODE_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  ADC_Antibody: {
    serviceKey: 'drug',
    rootId: TREE_ROOT_ID.dictionary,
    dicCode: 'DRUG_TYPE',
    btnGroup: ['Hierarchy', 'DrugCount', 'LetterIncrease'],
    customSelect: (ins, type, item, selectData) => {
      if (type === 'Self') {
        const index = selectData.findIndex((o) => o.id === item.id)
        if (index > -1) {
          showSingleToast({
            message: ins?.proxy.$t('common.excitedItem') as string,
            type: 'info',
          })
        } else {
          selectData.push(item)
        }
      } else {
        // 选择叶子节点
        if (item.is_leaf) {
          selectData.push(item)
        } else {
          selectData.push(...(item.children || []))
        }
      }
      return unionBy(selectData, (item) => item.id)
    },
    sort: {
      Hierarchy: [{ sort_field: 'ADC_COUNT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'ADC_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'DRUG_NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  ADC_Linker: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.dictionary,
    btnGroup: ['Hierarchy', 'DrugCount', 'LetterIncrease'],
    dicCode: 'DRUG_LINKER',
    sort: {
      Hierarchy: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  ADC_Payload: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.dictionary,
    btnGroup: ['Hierarchy', 'DrugCount', 'LetterIncrease'],
    dicCode: 'DRUG_PAYLOAD',
    sort: {
      Hierarchy: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
      DrugCount: [{ sort_field: 'DRUG_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  PATENT_TECHNOLOGY: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.dictionary,
    dicCode: 'PATENT_TECHNOLOGY',
    btnGroup: ['Hierarchy', 'PatentCount', 'LetterIncrease'],
    sort: {
      Hierarchy: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
      PatentCount: [{ sort_field: 'PATENT_COUNT', sort_order: E_SORT_ORDER.DESC }],
      LetterIncrease: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
  },
  DATA_SOURCE_MEETING: {
    serviceKey: 'dictionary',
    rootId: TREE_ROOT_ID.meetings,
    dicCode: 'DATA_SOURCE_MEETING',
    btnGroup: [],
    sort: {
      Hierarchy: [{ sort_field: 'NAME_EN_SORT', sort_order: E_SORT_ORDER.ASC }],
    },
    otherCondition: (browseCountType) => (browseCountType === 'translationalMedicineMeetings' ? 'TM_COUNT:[1 TO *]' : 'CR_COUNT:[1 TO *]'),
  },
}
