import { IAggregationValue } from '@patsnap/synapse_common_interface'
import { GFoldingContainer } from '@pharmsnap/shared/components/ui/GFoldingContainer/GFoldingContainer'
import { defineComponent, PropType, unref } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { BFilterAdvanceNextDialog } from './BFilterAdvanceNextDialog'
import $classes from './BFilterNext.module.scss'
import { BFilterItemSkeleton } from './components'
import { BFilterCheckboxList } from './components/BFilterCheckboxList'
import { BFilterCheckboxRollupList } from './components/BFilterCheckboxRollupList'
import { BFilterNoResult } from './components/BFilterNoResult'
import { BFilterSelectedGroups } from './components/BFilterSelectedGroups'
import { BFilterSimpleSearchInput } from './components/BFilterSimpleSearchInput'
import { BFilterSkeleton } from './components/BFilterSkeleton'
import { BFilterSubmit } from './components/BFilterSubmit'
import { useInjectFilterService } from './compositions/useProvideFilterService'
import cn from './locales/cn.json'
import en from './locales/en.json'
import jp from './locales/jp.json'
import tw from './locales/tw.json'
import { IFilterItemStateTreeNodeNext } from './type'

export const BFilterNext = defineComponent({
  name: 'BFilterNext',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    submitStyle: {
      type: [String, Object] as PropType<string | Record<string, any>>,
      default: '',
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    async handleFoldingChange(val: boolean, data: IFilterItemStateTreeNodeNext) {
      const service = this.filterService
      if (val) {
        await service.openFilterItem(data)
      } else {
        await service.closeFilterItem(data)
      }
    },
    shouldRenderFilterTopBlock() {
      const service = this.simpleSearchService

      const simpleSearchConfig = unref(service.config)

      const showSimpleSearch = simpleSearchConfig.visible

      const showSimpleSearchTop = !!simpleSearchConfig.topSlot

      const showSimpleSearchBottom = !!simpleSearchConfig.bottomSlot

      if (!showSimpleSearch && !showSimpleSearchTop && !showSimpleSearchBottom) {
        return false
      }

      return true
    },
    shouldRenderFilterSelectedBlock() {
      return unref(this.selectService.filterValueGroups).length !== 0
    },
    renderFilterTopBlock(cls = '') {
      if (!this.shouldRenderFilterTopBlock()) return null

      const service = this.simpleSearchService

      const simpleSearchConfig = unref(service.config)

      const showSimpleSearch = simpleSearchConfig.visible

      const showSimpleSearchTop = !!simpleSearchConfig.topSlot

      const showSimpleSearchBottom = !!simpleSearchConfig.bottomSlot

      return (
        <div class={['flex flex-col', cls]}>
          {showSimpleSearchTop && !!simpleSearchConfig.topSlot ? <div class="mb-4">{this.$slots[simpleSearchConfig.topSlot]}</div> : null}
          {showSimpleSearch ? <BFilterSimpleSearchInput></BFilterSimpleSearchInput> : null}
          {showSimpleSearchBottom && !!simpleSearchConfig.bottomSlot ? <div class="mt-4">{this.$slots[simpleSearchConfig.bottomSlot]}</div> : null}
        </div>
      )
    },
    renderFilterSelectedBlock() {
      if (unref(this.selectService.filterValueGroups).length === 0) return null
      return <BFilterSelectedGroups class={{ 'mt-6': this.shouldRenderFilterTopBlock() }}></BFilterSelectedGroups>
    },
    renderFilterItemTreeNodeContent(data: IFilterItemStateTreeNodeNext) {
      const { _meta } = data
      const { originLayoutConfig } = _meta
      const uiType = originLayoutConfig.uiAggField.uiType

      if (!data._base.isExpand) return null

      if (data._base.isLoading) return <BFilterItemSkeleton></BFilterItemSkeleton>

      if (uiType === 'checkbox') {
        return (
          <div class="max-h-[372px] overflow-y-auto">
            {data._base.isEmpty ? (
              <BFilterNoResult locale={unref(this.locale)}></BFilterNoResult>
            ) : (
              <BFilterCheckboxList
                identity={data.identity}
                scopedSlots={{
                  item: ({ item }: { item: IAggregationValue }) => {
                    const content = this.$scopedSlots?.item?.({ item })

                    if (content) return content

                    return null
                  },
                }}
              ></BFilterCheckboxList>
            )}
          </div>
        )
      }

      if (uiType === 'checkbox-rollup') {
        return (
          <div class="max-h-[372px] overflow-y-auto">
            <BFilterCheckboxRollupList
              identity={data.identity}
              scopedSlots={{
                item: ({ item }: { item: IAggregationValue }) => {
                  const content = this.$scopedSlots?.item?.({ item })

                  if (content) return content

                  return null
                },
              }}
            ></BFilterCheckboxRollupList>
          </div>
        )
      }

      return (
        <div class="max-h-[372px] overflow-auto">
          <div class="h-10">无相关配置</div>
        </div>
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderFilterItemTreeNode(data: IFilterItemStateTreeNodeNext, index: number, array: IFilterItemStateTreeNodeNext[]) {
      const { label, tooltip, _base, children, hasChildren, depth } = data

      const selectedCount = unref(this.selectService.filterValueSelectedCountRecord)[data.identity]

      const theme = unref(this.filterService.theme)

      const isFirst = index === 0

      return (
        <GFoldingContainer
          headerCls="py-[6px] pr-[4px] text-sm"
          contentCls="pr-[4px]"
          headerStyle={isFirst && depth === 0 ? '' : 'border-top: 1px solid #DFE1E6; border-bottom: 1px solid transparent;'}
          style={{ marginLeft: `${depth * 16}px` }}
          label={label}
          tip={tooltip}
          layout="loose"
          arrowMode="down-up"
          opened={_base.isExpand}
          iconSize={22}
          animated={true}
          onChange={(val: boolean) => this.handleFoldingChange(val, data)}
        >
          <template slot="default">
            {hasChildren ? (
              children?.map((child, i, ary) => this.renderFilterItemTreeNode(child, i, ary))
            ) : (
              <div class="pb-2">{this.renderFilterItemTreeNodeContent(data)}</div>
            )}
          </template>
          <template slot="iconLeft">
            {hasChildren ? null : selectedCount > 0 && !_base.isExpand ? (
              <span class={[theme === 'synapse' ? 'text-blue-default' : 'text-green-default']}>({selectedCount})</span>
            ) : null}
          </template>
        </GFoldingContainer>
      )
    },
    renderFilterMainBlock() {
      const service = this.filterService

      const tree = service.stateTree

      return (
        <div class={{ 'mt-3': this.shouldRenderFilterSelectedBlock() || this.shouldRenderFilterTopBlock() }}>
          <div class="flex items-center">
            <span class="text-xs leading-6 text-text-t3">{this.$t('bFilterNext.filters')}</span>
          </div>
          <div>{tree?.map((item, i, ary) => this.renderFilterItemTreeNode(item, i, ary))}</div>
        </div>
      )
    },
    renderFilterSubmitBlock() {
      return (
        <BFilterSubmit
          onExclude={() => this.filterService.submit('exclude')}
          onRefine={() => this.filterService.submit('refine')}
          class={[
            this.submitStyle ? $classes.submitBlockCustom : $classes.submitBlock,
            unref(this.filterService.initLoading) || unref(this.filterService.submitLoading) || this.isLoading ? 'hidden' : '',
          ]}
          style={this.submitStyle}
          layout="vertical"
          hideWhenDisabled={true}
        ></BFilterSubmit>
      )
    },
  },
  render() {
    return (
      <div class={['relative h-full border-r border-gray-40', this.filterService.className?.filterCls, $classes[unref(this.filterService.theme)]]}>
        <div class="h-full flex flex-col px-2 overflow-y-auto">
          {isUndefined(this.filterService) || unref(this.filterService.initLoading) || unref(this.filterService.submitLoading) || this.isLoading ? (
            <BFilterSkeleton></BFilterSkeleton>
          ) : (
            [this.renderFilterTopBlock(), this.renderFilterSelectedBlock(), this.renderFilterMainBlock()]
          )}
          {unref(this.advanceDialogService.visible) ? <BFilterAdvanceNextDialog></BFilterAdvanceNextDialog> : null}
        </div>
        {this.renderFilterSubmitBlock()}
      </div>
    )
  },
})
