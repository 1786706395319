import { computed, defineComponent, PropType } from '@vue/composition-api'
import '../../../assets/icon-svg/upgrade.svg'

export const BUpgradeButton = defineComponent({
  name: 'BUpgradeButton',
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'big'>,
      default: 'small',
    },
  },
  setup(props) {
    const iconSizeMap: Record<string, number> = {
      small: 16,
      medium: 18,
      big: 20,
    }

    const size = computed(() => iconSizeMap[props.size])

    return {
      iconSize: size,
    }
  },
  render() {
    return this.showIcon ? (
      <button class="pt-ui-btn" with-icon type="button" data-type="submit" data-size={this.size}>
        {/* <GIcon style="filter: brightness(0) invert(1);" svgName="Upgrade" size={this.iconSize as number}></GIcon> */}
        <span>{this.$tc('common.upgradeNow')}</span>
      </button>
    ) : (
      <button class="pt-ui-btn" type="button" data-type="submit" data-size={this.size}>
        <span>{this.$tc('common.upgrade')}</span>
      </button>
    )
  },
})
