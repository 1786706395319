import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { BIcon } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import type { IAggregationEntityValue } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { renderDisplayNameWithTitle2Line } from '../../../utils/render'
export const FilterOrgItem = defineComponent({
  name: 'FilterOrgItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IAggregationValue>,
    },
  },
  setup() {
    const { locale } = useLocale()
    return { locale }
  },
  render() {
    const _data = this.data as IAggregationEntityValue
    return (
      <div class="flex overflow-hidden">
        <BIcon class="mr-1 flex-shrink-0" type="company" src={_data?.logo_s3_path || _data?.logo || ''}></BIcon>
        <span class="flex-1">{renderDisplayNameWithTitle2Line(this.locale, _data, _data.start_up)}</span>
      </div>
    )
  },
})
