/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getClinicalResultSource } from '@patsnap/synapse_common_business'
import {
  renderClinicalPopulation,
  renderClinicalStudy,
  renderEnrollment,
  renderEvaluation,
  renderIntervention,
  renderPhase,
  renderPublishedDate,
  renderResults,
} from '@patsnap/synapse_common_components'
import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import {
  I18nLang,
  IBaseClinicalResult,
  IBaseDictItem,
  IBaseDiseaseInfo,
  IBaseMechanismInfo,
  IBaseTargetInfo,
  IClinicalResultDetail,
  IClinicalResultSource,
  IClinicalTrialResultTableItem,
  ILang,
  ITranslateLang,
} from '@patsnap/synapse_common_interface'
import { getPhaseItemName, getSpecialLang, openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { MANUAL_DICT_ID, MULTI_VALUE_SEPARATOR_COMMA } from '@pharmsnap/pharmsnap-web/views/clinical-result/utils/constant'
import { filter, find, get, map, reduce, uniq, uniqBy } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import { BDiseaseTag, BDiseaseTagPopperListCard, BMosaic, BSignaturePdf, BUpgradeWrap, GLimited, GLink, GTranslate } from '../components'
import { BEvaluationTag } from '../components/business/tag/BEvaluationTag/BEvaluationTag'
import { useAuthStore } from '../composition'
import { TEXT_SPLIT_SYMBOL } from '../constants'
import { sharedCtx } from '../context'
import { ElTag } from '../element-ui'
import { getDictItemName, isOpenCTResultTranslate, openNewTab } from '../utils'
import {
  renderAsyncListEntityCard,
  renderDrugTypeAsync,
  renderPopoverLimited,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedMechanismAsync,
  renderPopoverLimitedOrgAsync,
  renderPopoverLimitedTargets,
} from './baseRender'
import styles from './clinicalResultRender.module.scss'
/**
 * 试验代号
 */
export function renderClinicalResultRelatedId(data: IBaseClinicalResult, limitCount = 1) {
  const studyNumbers = (data.related_id || []).filter((item) => item.id_type === 'study_identifier').map((item) => item.id_value)

  if (!studyNumbers.length) {
    return null
  }

  return [
    renderPopoverLimited(studyNumbers, {
      limitCount,
      render: (item) => {
        return <span class="text-green-default text-sm">{item}</span>
      },
      gap: {
        x: 0,
        y: 0,
      },
      placement: 'right-start',
      popoverInline: false,
    }),
    <span class="mx-1">/</span>,
  ]
}

/**
 * 治疗类型
 * @param data
 * @param $i18n
 * @returns
 */
export function renderClinicalTherapyType(data: IBaseClinicalResult, $i18n: IVueI18n) {
  if (data.therapy_type && data.therapy_type_view) {
    return filter(
      map(data.therapy_type, (dict_id) => {
        const dictItem = find(data.therapy_type_view, ['dict_id', dict_id])
        return dictItem ? (
          <ElTag size="mini" class="b-phase-tag mr-1 max-w-60 truncate" effect="plain">
            {getDictItemName(dictItem, $i18n.locale.toUpperCase() as ILang)}
          </ElTag>
        ) : null
      }),
      Boolean
    )
  }
}

/**
 * 结果评价
 * @param data
 * @param localeUpcase
 * @returns
 */
export function renderGeneralEvaluation(data: IBaseClinicalResult, localeUpcase: ILang) {
  return (
    <BMosaic updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} trigger_point="CLINICAL_RESULT_EVALUATION">
      <BEvaluationTag
        class="mr-1"
        evaluationId={data.general_evaluation_id}
        evaluationIdView={data.general_evaluation_id_view}
        size="mini"
      ></BEvaluationTag>
    </BMosaic>
  )
}

/**
 * 临床结果分期
 * @param data
 * @param localeUpcase
 * @returns
 */
export function renderClinicalResultPhase(data: IBaseClinicalResult, localeUpcase: ILang) {
  return data.phase_id_view ? (
    <ElTag class="b-phase-tag mr-1" size="small" disable-transitions={true} effect="plain">
      {getPhaseItemName(data.phase_id_view, localeUpcase)}
    </ElTag>
  ) : null
}

/**
 * 适应症
 * @param data
 * @param localeUpcase
 * @returns
 */
export function renderClinicalResultConditionsAsync(data: IBaseClinicalResult) {
  const { condition_disease_id_view = [], condition_disease_id = [] } = data

  if (!condition_disease_id_view.length) {
    return null
  }

  return (
    <div class={condition_disease_id_view.length > 1 ? 'mr-1' : ''}>
      {renderAsyncListEntityCard({
        id_view: condition_disease_id_view as IBaseDiseaseInfo[],
        ids: condition_disease_id as string[],
        PopperListCardCom: BDiseaseTagPopperListCard,
        ItemCom: BDiseaseTag,
        limitCount: 1,
        isDisplayInOneLine: true,
        itemCompProps: { showIcon: false },
      })}
    </div>
  )
}

/**
 * 登记号
 */
export function renderClinicalResultRegisterNumber(data: IBaseClinicalResult | IClinicalResultDetail, limitCount = 3, style = {}) {
  const registerNum = Object.keys(data.register_number_map || {})
  const viewLen = registerNum.length
  const displayLen = viewLen < limitCount ? viewLen : limitCount

  return renderPopoverLimited(
    map(registerNum, (name) => ({
      name,
      href: sharedCtx.router.generatorClinicalPath(get(data.register_number_map, name, '')),
    })),
    {
      limitCount,
      placement: 'bottom-start',
      render: (item, index, isRenderInPopover) => (
        <GLink style={style} class="text-sm" href={item.href} newTab={true}>
          {item.name}
          {index !== (isRenderInPopover ? viewLen : displayLen) - 1 ? MULTI_VALUE_SEPARATOR_COMMA : ''}
        </GLink>
      ),
      gap: { x: 8, y: 0 },
    }
  )
}

export function renderClinicalResultManualOrAI(data: IBaseClinicalResult | IClinicalResultDetail, localeUpcase: ILang) {
  const annotationId = data.annotation_method_view?.dict_id || ''

  if (annotationId) {
    if (MANUAL_DICT_ID.includes(annotationId)) {
      return (
        <div class={styles.manuallyTag}>
          {localeUpcase === 'CN' ? '人工标引' : 'Manual'}
          <img src={require('../assets/img/vector.png')} alt="Manual" class={styles.annotationImg} />
        </div>
      )
    }
  }
}

export function getClinicalResultTitleTranslateText(data: IBaseClinicalResult, upperLocale: ILang) {
  return (
    find(data.clinical_trial_translation, ['lang', upperLocale])?.official_title ||
    find(data.clinical_trial_translation, ['lang', upperLocale])?.brief_title
  )
}

export function renderClinicalResultTrialTitleInTable(data: IBaseClinicalResult, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const translate = isOpenCTResultTranslate(data, customSettingTranslation)

  const originalText = data.official_title || data.brief_title
  const translateText = getClinicalResultTitleTranslateText(data, customSettingTranslation as ILang)

  return (
    <GTranslate execute={translate} content={translateText || EMPTY_PLACEHOLDER}>
      <BUpgradeWrap updateType={E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT} triggerPoint="CLINICAL_RESULT_TITLE">
        <GLink
          href={sharedCtx.router.generatorClinicalResultDetailPath(data.ct_result_id)}
          name={originalText || EMPTY_PLACEHOLDER}
          newTab={true}
          defaultColor="blue"
          class="whitespace-pre-wrap text-sm leading-6"
        ></GLink>
      </BUpgradeWrap>
    </GTranslate>
  )
}

export function renderClinicalResultStudy(data: IBaseClinicalResult, $i18n: IVueI18n) {
  return renderClinicalStudy({
    data,
    options: {
      isHoverLink: true,
      locale: $i18n.locale as I18nLang,
      click: (ct_result_id) => openNewTab(sharedCtx.router.generatorClinicalResultDetailPath(ct_result_id)),
    },
  })
}

export function renderClinicalResultPhaseInTable(data: IBaseClinicalResult, $i18n: IVueI18n) {
  return renderPhase({
    phase: data.phase_id_view,
    options: {
      locale: $i18n.locale as I18nLang,
    },
  })
}

export function renderClinicalResultPopulation(data: IBaseClinicalResult, $i18n: IVueI18n) {
  return renderClinicalPopulation({
    data,
    options: {
      locale: $i18n.locale as I18nLang,
      clickViewMore: () => openNewTab(sharedCtx.router.generatorClinicalResultDetailPath(data.ct_result_id)),
      clickDisease: (disease_id) => openNewTab(sharedCtx.router.generatorDiseasePath(disease_id)),
    },
  })
}

export function renderClinicalResultEnrollment(data: IBaseClinicalResult) {
  return renderEnrollment({
    enrollment: data.overall_enrollment,
  })
}

export function renderClinicalResultEvaluation(data: IBaseClinicalResult, $i18n: IVueI18n) {
  return (
    <BMosaic updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} trigger_point="CLINICAL_RESULT_EVALUATION">
      {renderEvaluation({
        general_evaluation_id_view: data.general_evaluation_id_view,
        options: {
          locale: $i18n.locale as I18nLang,
        },
      })}
    </BMosaic>
  )
}

export function renderClinicalResultPublishDate(data: IBaseClinicalResult, $i18n: IVueI18n) {
  return renderPublishedDate({
    published_time: data.published_time,
    options: {
      locale: $i18n.locale as I18nLang,
    },
  })
}

export function renderClinicalResultIntervention(data: IClinicalTrialResultTableItem) {
  return renderIntervention({
    names: data.intervention.characteristic,
    options: {
      getDrugUrl: (drugId: string) => sharedCtx.router.generatorDrugPath(drugId),
      extraNames: data.intervention.extraCharacteristic,
    },
  })
}

export function renderClinicalResultInfo(data: IClinicalTrialResultTableItem, $i18n: IVueI18n) {
  const {
    actions: { changeUpgrade },
  } = useAuthStore()

  return (
    <BMosaic updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} trigger_point="CLINICAL_RESULT_RESULTS">
      {renderResults({
        group_id: data.intervention.group_id,
        source_info: data.source_info,
        outcome_measures: data.interventionResult,
        outcomes: data.outcomes,
        options: {
          locale: $i18n.locale as I18nLang,
          clickViewMore: (ct_result_id) => {
            openPageWithoutPending(() => sharedCtx.router.generatorClinicalResultDetailPath(ct_result_id))
          },
        },
      })}
    </BMosaic>
  )
}

export function renderClinicalResultDiseaseAndTarget(data: IBaseClinicalResult, localeUpcase: ILang) {
  if (!(data.main_evaluate_drug_id_view || []).length) {
    return null
  }

  return (
    <div class="mr-2">
      {renderPopoverLimited(data.main_evaluate_drug_id_view || [], {
        limitCount: 1,
        render: (item, index, isRenderInPopover) => {
          const target_name = map(item.target_id_view || [], (target, index) => {
            const name = localeUpcase === 'EN' ? target.entity_name_en : target.entity_name_cn
            return [
              <GLink newTab class="whitespace-pre-wrap hover:text-blue-default text-sm" href={sharedCtx.router.generatorTargetPath(target.target_id)}>
                <div title={name} class={['truncate', isRenderInPopover ? 'max-w-[120px]' : 'max-w-[200px]']}>
                  {name}
                </div>
              </GLink>,
              index < (item.target_id_view || []).length - 1 ? TEXT_SPLIT_SYMBOL : '',
            ]
          })
          return (
            <div class="flex items-center flex-wrap">
              <GLink
                newTab
                class="whitespace-pre-wrap hover:text-blue-default text-sm flex-shrink-0"
                href={sharedCtx.router.generatorDrugPath(item.drug_id)}
              >
                <div
                  class={['truncate', isRenderInPopover ? 'max-w-[140px]' : 'max-w-[200px]']}
                  title={getSpecialLang({ data: item.drug_name || [], field: 'name', lang: localeUpcase })}
                >
                  {getSpecialLang({ data: item.drug_name || [], field: 'name', lang: localeUpcase })}
                </div>
              </GLink>
              {item.target_id_view?.length ? <div class="text-sm flex items-center flex-wrap">({target_name})</div> : null}
            </div>
          )
        },
        placement: 'right-start',
        popoverInline: false,
        appendToBody: true,
        gap: {
          x: 4,
          y: 0,
        },
      })}
    </div>
  )
}

export function renderClinicalResultOrgs(data: IBaseClinicalResult | IClinicalTrialResultTableItem, limitCount = 1) {
  return renderPopoverLimitedOrgAsync(data.org_master_entity_id_view || [], data.org_master_entity_id, limitCount, true)
}

export function renderClinicalResultTarget(data: IClinicalTrialResultTableItem) {
  const target_view = uniqBy(
    reduce(
      data.main_evaluate_drug_id_view,
      (acc, cur) => {
        if (cur.target_id_view) {
          acc.push(...cur.target_id_view)
        }
        return acc
      },
      [] as IBaseTargetInfo[]
    ),
    'target_id'
  )
  return renderPopoverLimitedTargets(target_view || [], 3)
}

export function renderClinicalResultDrugType(data: IClinicalTrialResultTableItem) {
  const drug_type_view = uniqBy(
    reduce(
      data.main_evaluate_drug_id_view,
      (acc, cur) => {
        if (cur.drug_type_view) {
          acc.push(...cur.drug_type_view)
        }
        return acc
      },
      [] as IBaseDictItem[]
    ),
    'dict_id'
  )

  const drug_type = uniq(
    reduce(
      data.main_evaluate_drug_id_view,
      (acc, cur) => {
        if (cur.drug_type) {
          acc.push(...cur.drug_type)
        }
        return acc
      },
      [] as string[]
    )
  )

  return renderDrugTypeAsync(drug_type_view || [], drug_type, 3)
}

export function renderClinicalResultMechanism(data: IClinicalTrialResultTableItem) {
  const mechanism_action_id_view = uniqBy(
    reduce(
      data.main_evaluate_drug_id_view,
      (acc, cur) => {
        if (cur.mechanism_action_id_view) {
          acc.push(...cur.mechanism_action_id_view)
        }
        return acc
      },
      [] as IBaseMechanismInfo[]
    ),
    'mechanism_id'
  )

  const mechanism_action_id = uniq(
    reduce(
      data.main_evaluate_drug_id_view,
      (acc, cur) => {
        if (cur.mechanism_action_id) {
          acc.push(...cur.mechanism_action_id)
        }
        return acc
      },
      [] as string[]
    )
  )

  return renderPopoverLimitedMechanismAsync(mechanism_action_id_view || [], mechanism_action_id || [], 3)
}

export function renderClinicalResultTherapeuticAreaInTable(data: IClinicalTrialResultTableItem) {
  return renderPopoverLimitedDiseaseAsync(data.therapeutic_area_view || [], data.therapeutic_area || [], 3)
}

export function renderClinicalResultSource(data: IClinicalResultDetail) {
  const sourceInfos = getClinicalResultSource(data.source_info)
  return (
    <GLimited
      mode="popover"
      placement="bottom"
      items={sourceInfos}
      limit={1}
      inline={true}
      gap={{ x: 0, y: 0 }}
      scopedSlots={{
        default: ({ item, isRenderInPopover }: { item: IClinicalResultSource; isRenderInPopover: boolean }) => {
          if (!isRenderInPopover) {
            return <span class="text-sm text-blue-default inline-flex items-center">{_renderClinicalResultSourceItem(item, 'text')}</span>
          } else {
            return _renderClinicalResultSourceItem(item)
          }
        },
      }}
    ></GLimited>
  )
}
function _renderClinicalResultSourceItem(sourceInfo: IClinicalResultSource, model: 'rich' | 'text' = 'rich') {
  const sourceName =
    (sourceInfo.normalized_source_id_view
      ? sourceInfo.normalized_source_id_view.fe_name_en || sourceInfo.normalized_source_id_view.fe_name_cn
      : '') || sourceInfo.source
  if (sourceInfo.attachment_type === 'PDF' && sourceInfo.attachment_path) {
    const style =
      model === 'rich'
        ? {
            border: '1px solid rgb(25, 118, 210)',
            padding: '2px 4px',
            color: 'rgb(25, 118, 210)',
            marginRight: '2px',
            marginBottom: '2px',
          }
        : {}
    return (
      <BSignaturePdf style={style} class="text-sm leading-5 text-blue-default inline-block" src={sourceInfo.attachment_path} action="preview">
        {sourceName}
        {sourceInfo.page_number ? `(page=${sourceInfo.page_number})` : ''}
      </BSignaturePdf>
    )
  }
  const tagColor = sourceInfo.source_url ? 'rgb(25, 118, 210)' : 'rgb(2 10 26)'
  const style =
    model === 'rich'
      ? {
          border: `1px solid ${tagColor}`,
          padding: '2px 4px',
          marginRight: '2px',
          marginBottom: '2px',
        }
      : {}
  return (
    <GLink style={style} class="text-sm leading-5" defaultColor={sourceInfo.source_url ? 'blue' : 'dark'} newTab href={sourceInfo.source_url}>
      {sourceName}
    </GLink>
  )
}
