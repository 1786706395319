import {
  AggStackBarChartConfig,
  AggStackBarChartEntity,
  AggStackLineChartConfig,
  AggStackLineChartEntity,
  AggTwoDimChartBaseEntity,
  AggTwoDimDataItem,
} from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { computed, markRaw, shallowRef, unref, watch } from '@vue/composition-api'
import { cloneDeep, uniqBy } from 'lodash'
import { IUseAnalysisTwoDimChartEntityOptions } from '../type'

export function useAnalysisTwoDimChartEntity(options: IUseAnalysisTwoDimChartEntityOptions) {
  const { chartType, data, yAxisName, xAxisName, layout, getChartConfig, optionAdjust } = options

  const { locale } = useLocale()

  const firstDimAggField = computed(() => unref(data).aggregation_result?.[0]?.aggregation_field)

  const secondDimAggField = computed(() => unref(data).aggregation_result?.[0]?.items?.[0]?.aggregation_result?.[0].aggregation_field)

  const chartEntity = shallowRef<AggTwoDimChartBaseEntity>(markRaw(getChartEntity()))

  const dataList = shallowRef<AggTwoDimDataItem[]>(markRaw(chartEntity.value.dataList))

  const uniqEntityDataList = shallowRef<AggTwoDimDataItem[]>(markRaw(uniqBy(chartEntity.value.dataList, 'yName')))

  function getChartEntity() {
    const type = unref(chartType)
    const aggData = cloneDeep(unref(data).aggregation_result?.[0])
    const partialConfig = getChartConfig?.(type, { firstAggField: unref(firstDimAggField), secondAggField: unref(secondDimAggField), aggData }) || {}

    if (type === 'bar') {
      const config: AggStackBarChartConfig = {
        locale: unref(locale),
        xAxisName: unref(layout) === 'horizontal' ? unref(yAxisName) : unref(xAxisName),
        yAxisName: unref(layout) === 'horizontal' ? unref(xAxisName) : unref(yAxisName),
        layout: unref(layout),
        optionAdjust,
        ySorter: () => (unref(layout) === 'horizontal' ? -1 : 1),
        ...(partialConfig as Partial<AggStackBarChartConfig>),
      }

      return new AggStackBarChartEntity(config, aggData)
    }

    const config: AggStackLineChartConfig = {
      locale: unref(locale),
      xAxisName: unref(xAxisName),
      yAxisName: unref(yAxisName),
      ySorter: () => 1,
      optionAdjust,
      ...(partialConfig as Partial<AggStackLineChartConfig>),
    }

    return new AggStackLineChartEntity(config, aggData)
  }

  watch(
    () => unref(data),
    () => {
      const entity = getChartEntity()
      dataList.value = markRaw(entity.dataList)
      uniqEntityDataList.value = markRaw(uniqBy(entity.dataList, 'yName'))
      chartEntity.value = markRaw(entity)
    }
  )

  return {
    firstDimAggField,
    secondDimAggField,
    chartEntity,
    dataList,
    uniqEntityDataList,
  }
}
