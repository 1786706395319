import '@patsnap-ui/icon/assets/solid/DoubleArrowDown.svg'
import '@patsnap-ui/icon/assets/solid/DoubleArrowUp.svg'
import { GLink } from '@pharmsnap/shared/components'
import { ElPopover } from '@pharmsnap/shared/src/element-ui'
import { computed, defineComponent, getCurrentInstance, PropType, ref, toRefs } from '@vue/composition-api'
import '../../../assets/icon-svg/outlink.svg'
import { GIcon } from '../GIcon/GIcon'
import styles from './GLimitedContainer.module.scss'

export const GLimitedContainer = defineComponent({
  name: 'GLimitedContainer',
  props: {
    moreType: {
      type: String as PropType<'dialog'>,
      default: '',
    },
    lineHeight: {
      type: Number,
      default: 20,
    },
    limitLines: {
      type: Number,
      default: 3,
    },
    moreText: {
      type: String,
    },
    lessText: {
      type: String,
    },
    href: {
      type: String,
      default: '',
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    moreColor: {
      type: String,
      default: '#fff',
    },
    moreFunc: {
      type: Function as PropType<(e: Event) => void>,
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { moreText, lessText, lineHeight, limitLines } = toRefs(props)
    const refContent = ref<Element>()
    const isOverflow = ref<boolean>(false)
    const showMore = ref<boolean>(false)
    const ins = getCurrentInstance()

    const displayMoreText = computed(() => moreText?.value || ins?.proxy.$tc('common.more') || '')
    const displayLessText = computed(() => lessText?.value || ins?.proxy.$tc('common.less') || '')

    const onToggle = (e: Event) => {
      if (props.moreType === 'dialog') {
        return
      }
      e.stopPropagation()
      ctx.emit(showMore.value ? 'less' : 'more')
      console.log('run')
      showMore.value = !showMore.value
    }

    const containerStyle = computed(() => {
      if (isOverflow.value) {
        if (showMore.value) {
          return {}
        } else {
          return {
            'max-height': `${lineHeight.value * limitLines.value}px`,
          }
        }
      }

      return {
        'max-height': `${lineHeight.value * limitLines.value}px`,
      }
    })
    return {
      containerStyle,
      onToggle,
      displayMoreText,
      displayLessText,
      refContent,
      isOverflow,
      showMore,
    }
  },
  mounted() {
    // 加个延迟，不然列表页表格内，高度计算不准确
    setTimeout(() => {
      this.check()
    }, 0)
  },
  methods: {
    check() {
      const { height: wrapHeight } = this.$el.getBoundingClientRect() as DOMRect
      const { height: contentHeight } = (this.refContent as Element).getBoundingClientRect()
      this.isOverflow = wrapHeight < contentHeight
    },
    renderLinkTrigger() {
      return this.moreFunc ? (
        <GLink defaultColor="blue" newTab={this.newTab} href={this.href} onBeforeGoTarget={this.moreFunc}>
          {this.displayMoreText}
        </GLink>
      ) : (
        <GLink defaultColor="blue" newTab={this.newTab} href={this.href}>
          {this.icon && <GIcon size={this.iconSize} svgName={this.icon} class="text-blue-outlink"></GIcon>}
          {this.displayMoreText}
        </GLink>
      )
    },
    renderExpandTrigger() {
      const triggerContent = (
        <div onClick={this.onToggle} class={styles.trigger}>
          <span class={styles.triggerText}>{this.showMore ? this.displayLessText : this.displayMoreText}</span>
          {this.moreType !== 'dialog' && (
            <GIcon class={styles.triggerIcon} size={20} svgName={this.showMore ? 'SolidDoubleArrowUp' : 'SolidDoubleArrowDown'}></GIcon>
          )}
        </div>
      )
      if (this.moreType === 'dialog') {
        return (
          <ElPopover visible-arrow={false} trigger="click" placement="left">
            <template slot="reference">{triggerContent}</template>
            <div style="width:420px; max-height: 500px;" class="overflow-y-auto p-2">
              {this.$slots.default}
            </div>
          </ElPopover>
        )
      }
      return triggerContent
    },
  },
  render() {
    return (
      <div class={styles.container} style={this.containerStyle}>
        <div ref="refContent">{this.$slots.default}</div>
        {this.isOverflow ? (
          <div
            style={{
              height: `${this.lineHeight}px`,
              lineHeight: `${this.lineHeight}px`,
              backgroundColor: this.moreColor,
              boxShadow: `-25px 0 15px 0 ${this.moreColor}`,
            }}
            class={[styles.actions, 'hoverClass']}
          >
            {this.$slots.customMore ? this.$slots.customMore : this.href ? this.renderLinkTrigger() : this.renderExpandTrigger()}
          </div>
        ) : null}
      </div>
    )
  },
})
