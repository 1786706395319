import type { I18nLang, IACListItem, IAutoCompleteType } from '@patsnap/synapse_common_interface'
import { createObservable, IHttpWrap } from '@pharmsnap/shared/service'
import type { IAutoCompleteParams, IAutoCompleteRes } from '@pharmsnap/shared/types'
import { convertDataStrategyMap, getAutoCompleteList } from '@pharmsnap/shared/utils'
import { ref, unref, watch } from '@vue/composition-api'
import { MaybeRef, tryOnBeforeUnmount } from '@vueuse/core'
import { isUndefined } from 'lodash'
import { filter, Subject, switchMap, tap } from 'rxjs'

type IUseAcInputOptions = {
  params: MaybeRef<Omit<IAutoCompleteParams, 'keywords' | 'cancelToken'>>
  locale: MaybeRef<I18nLang>
  hooks?: {
    onAfterAutocomplete?: () => void
    onBeforeAutocomplete?: () => boolean
  }
  options?: {
    customFetchAutoComplete?: (params: IAutoCompleteParams) => Promise<IHttpWrap<IAutoCompleteRes>>
    customShowIcon?: (type: IAutoCompleteType) => boolean
  }
}

export function useAcInput(options: IUseAcInputOptions) {
  const { params: autocompleteParams, locale, hooks, options: customOptions } = options

  const loading = ref(false)

  const isEmpty = ref(true)

  const { customFetchAutoComplete, customShowIcon } = customOptions || {}

  const { onAfterAutocomplete, onBeforeAutocomplete } = hooks || {}

  const input$ = new Subject<string>()

  const inputSubscribe$ = input$
    .pipe(
      tap(() => {
        autocompleteItems.value = []
        isEmpty.value = true
      }),
      filter((text) => text.length > 1),
      switchMap((text) => {
        const params = unref(autocompleteParams)
        loading.value = true
        return createObservable<IAutoCompleteParams, IAutoCompleteRes>(customFetchAutoComplete || getAutoCompleteList, {
          keywords: text,
          key: params.key,
          limit: !isUndefined(params.limit) ? params.limit : params.key === 'Core' ? 5 : 10,
          type: params.type,
          data_type: params.data_type,
          entity_type: params.entity_type,
        })
      })
    )
    .subscribe((res) => {
      const params = unref(autocompleteParams)
      if (res.success && res.data && unref(keywords)) {
        const resData = res.data || []
        const func = convertDataStrategyMap.get(params.key)
        const hideIconTypes: Array<IAutoCompleteType> = ['ADC_Linker', 'ADC_Payload', 'ADC_Antibody_Type', 'ADC_SITE']
        if (func) {
          const result: IACListItem[] =
            func({
              data: resData,
              locale: unref(locale),
              keywords: unref(keywords),
              showIcon: customShowIcon ? customShowIcon(params.key) : !hideIconTypes.includes(params.key),
              itemType: params.key,
            }) ?? []
          autocompleteItems.value = result
          isEmpty.value = !!unref(keywords).length && unref(autocompleteItems).every((i) => i.data.length === 0)
        }
      }
      loading.value = false
      onAfterAutocomplete?.()
    })

  const keywords = ref('')

  const autocompleteItems = ref<IACListItem[]>([])

  watch(keywords, () => {
    if (onBeforeAutocomplete) {
      if (!onBeforeAutocomplete()) {
        return
      }
    }
    input$.next(unref(keywords).trim())
  })

  tryOnBeforeUnmount(() => {
    inputSubscribe$.unsubscribe()
  })

  return {
    keywords,
    isEmpty,
    autocompleteItems,
  }
}
