/**
 * @file 这个文件包含了BEntitySelectForm组件，用于选择电子邮件警报的实体。
 * @module BEntitySelectForm
 */

import { alertEntityConfig } from '@patsnap/synapse_common_config'
import { IAlertEntity, IAutoCompleteType, ICreateUpdateAlertParams, IListItem } from '@patsnap/synapse_common_interface'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { DISEASE_ROOT_IDS } from '@pharmsnap/shared/src/domain/diseaseDomain/disease.config'
import { DRUG_TYPE_ROOT_IDS } from '@pharmsnap/shared/src/domain/drugTypeDomain/drugType.config'
import { TARGET_ROOT_IDS } from '@pharmsnap/shared/src/domain/targetDomain/target.config'
import { IBAcTag } from '@pharmsnap/shared/src/types'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { convertAcType2AlertEntityType, convertAcType2AlertType } from '@pharmsnap/shared/src/utils/business/emailAlert/email-alert-v2'
import { computed, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api'
import { BAutoEntity, GRadio } from 'pharmsnapMF_shared/components'
import { autoCompleteTypeLangMap } from '../../config'
import cn from './locales/cn.json'
import en from './locales/en.json'

/**
 * BEntitySelectForm组件。
 * @component
 */
export const BEntitySelectForm = defineComponent({
  name: 'BEntitySelectForm',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, { emit }) {
    const {
      state: { usageInfo },
      actions: { syncUserUsage },
    } = useAuthStore()
    const ins = getCurrentInstance()
    const { tsLangMap, $t, locale } = useLocale()
    const alertUsageInfo = computed(() => {
      const leaveLimitCount = (usageInfo.value?.alert.total || 0) - (usageInfo.value?.alert.current || 0)
      const leave = leaveLimitCount - autoCompleteValue.value.length
      return {
        total: usageInfo.value?.alert?.total || 0,
        current: usageInfo.value?.alert?.current || 0,
        leave: leave < 0 ? 0 : leave,
        leaveLimitCount,
      }
    })

    /**
     * 所有可用的自动完成类型。
     * @type {IAutoCompleteType[]}
     */
    const allAutoCompleteType: IAutoCompleteType[] = ['Drug', 'Target', 'Organization', 'DrugType', 'Disease']

    /**
     * 当前选中的自动完成类型。
     * @type {Ref<IAutoCompleteType>}
     */
    const currentAutoCompleteType = ref<IAutoCompleteType>(allAutoCompleteType[0])

    /**
     * 当前选中的自动完成值。
     * @type {Ref<IListItem[]>}
     */
    const autoCompleteValue = ref<IListItem[]>([])

    /**
     * 自动完成复选框选项。
     * @type {ComputedRef<{value: IAutoCompleteType, label: string}[]>}
     */
    const autoCompleteCheckboxOptions = computed(() => {
      return allAutoCompleteType.reduce(
        (acc, curr) => {
          const langConfig = autoCompleteTypeLangMap[curr]
          if (!langConfig) {
            console.error('未找到自动完成类型的语言配置:', curr)
            return acc
          }
          acc.push({
            value: curr,
            label: tsLangMap(langConfig),
          })
          return acc
        },
        [] as {
          value: IAutoCompleteType
          label: string
        }[]
      )
    })

    /**
     * 计算的电子邮件警报实体。
     * @type {ComputedRef<{data_type: string, value: {display_name_cn: string, display_name_en: string, id: string}[]}>}
     */
    const createEmailAlertEntity = computed<IAlertEntity[]>(() => {
      return autoCompleteValue.value.map((item) => {
        return {
          data_type: convertAcType2AlertEntityType(currentAutoCompleteType.value),
          value: [
            {
              display_name_cn: item.name_cn || item.name_en || '',
              display_name_en: item.name_en || '',
              id: item.id,
            },
          ],
        }
      })
    })

    /**
     * 计算的电子邮件警报配置。
     * @type {ComputedRef<IAlertConfig>}
     */
    const createEmailAlertConfig = computed<ICreateUpdateAlertParams>(() => {
      return {
        alert_type: convertAcType2AlertType(currentAutoCompleteType.value),
        batch_entity: createEmailAlertEntity.value,
      }
    })

    /**
     * 自动完成类型更改事件处理程序。
     * @function
     * @param {IAutoCompleteType} val - 选中的自动完成类型。
     * @returns {void}
     */
    function onChangeAutoCompleteType(val: IAutoCompleteType): void {
      currentAutoCompleteType.value = val
      autoCompleteValue.value = []
    }

    /**
     * 将选中的实体值添加到自动完成值中。
     * @function
     * @param {IListItem} value - 选中的实体值。
     * @returns {void}
     */
    function addSelectEntityValue(value: IListItem): void {
      const isExist = autoCompleteValue.value.find((tag) => {
        if (value.id) {
          return tag.id === value.id
        } else {
          if (tag.id) return false
          return locale.value === 'cn' ? tag.name_cn === value.name_cn : tag.name_en === value.name_en
        }
      })
      if (isExist) {
        showSingleToast({
          message: ins?.proxy.$t('common.excitedItem') as string,
          type: 'info',
        })
        return
      }
      if (_checkIsDisableEntity(value.id)) {
        const entityType = convertAcType2AlertEntityType(currentAutoCompleteType.value)
        const entityName = locale.value === 'cn' ? alertEntityConfig[entityType].cn : alertEntityConfig[entityType].en
        showSingleToast({
          message: ins?.proxy.$t('BEntitySelectForm.notSupportTips', { type: entityName }) as string,
          type: 'info',
        })
        return
      }
      autoCompleteValue.value.push(value)
    }

    function _checkIsDisableEntity(id: string) {
      if (currentAutoCompleteType.value === 'Target') {
        return TARGET_ROOT_IDS.includes(id)
      }
      if (currentAutoCompleteType.value === 'DrugType') {
        return DRUG_TYPE_ROOT_IDS.includes(id)
      }
      if (currentAutoCompleteType.value === 'Disease') {
        return DISEASE_ROOT_IDS.includes(id)
      }
      return false
    }

    /**
     * 在按下退格键时删除最后选中的实体值。
     * @function
     * @param {KeyboardEvent} e - 键盘事件。
     * @returns {void}
     */
    function removeSelectEntityValue(e: KeyboardEvent): void {
      const { key: keyboardKey } = e
      if (keyboardKey === 'Backspace') {
        if ((e.target as HTMLInputElement).value.length === 0) {
          autoCompleteValue.value.pop()
        }
      }
    }
    function onRemoveEntity(value: IBAcTag) {
      const index = autoCompleteValue.value.findIndex((item) => item.id === value.id)
      if (index > -1) {
        autoCompleteValue.value.splice(index, 1)
      }
    }
    function checkCanSubmit() {
      if (!autoCompleteValue.value.length) {
        showSingleToast({ message: $t('BEntitySelectForm.noEntityTips') as string, type: 'error' })
        return false
      }
      return true
    }
    watch(createEmailAlertConfig, (value) => {
      emit('update', value)
    })
    syncUserUsage('alert')

    return {
      alertUsageInfo,
      usageInfo,
      currentAutoCompleteType,
      autoCompleteCheckboxOptions,
      autoCompleteValue,
      checkCanSubmit,
      onRemoveEntity,
      onChangeAutoCompleteType,
      addSelectEntityValue,
      removeSelectEntityValue,
    }
  },
  render() {
    return (
      <div>
        <div class="font-bold">{this.$t('BEntitySelectForm.alertType')}</div>
        <div class="mt-2">
          <GRadio
            value={this.currentAutoCompleteType}
            onInput={this.onChangeAutoCompleteType}
            items={this.autoCompleteCheckboxOptions}
            mode="circle"
            inline={false}
          ></GRadio>
        </div>
        <BAutoEntity
          class="mt-2"
          type={this.currentAutoCompleteType}
          key={this.currentAutoCompleteType}
          tags={this.autoCompleteValue}
          disabled={this.alertUsageInfo.leave === 0}
          style="margin-bottom: 0"
          tagsLimit={this.alertUsageInfo.leaveLimitCount}
          customLimitMsg={this.$tc('common.limitMsg', undefined, { count: this.alertUsageInfo.leave })}
          emptyTip={this.$tc('common.noMatchedResults')}
          recommendTip={this.$tc('common.recommendTip')}
          placeHolder={this.$tc('BEntitySelectForm.acPlaceholder')}
          onSelect={(type: IAutoCompleteType, val: IListItem) => this.addSelectEntityValue(val)}
          onKeyDown={(e: KeyboardEvent) => this.removeSelectEntityValue(e)}
          onClose={(_type: IAutoCompleteType, val: IBAcTag) => this.onRemoveEntity(val)}
        ></BAutoEntity>
        <div class="flex flex-row-reverse">
          <span class="text-orange-dark">{this.$t('BEntitySelectForm.countLimitTips', this.alertUsageInfo)}</span>
        </div>
      </div>
    )
  },
})
