import { IAggregationData, IAggregationParams, ISearchParams } from '@patsnap/synapse_common_interface'
import { ISearchResult, IVCInvestmentInfoNew } from '@pharmsnap/shared/types'
import { decrypt, decryptNumberFieldInObj } from '@pharmsnap/shared/utils'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformInvestmentAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/investment'
import { IHttp, IHttpWrap } from '../http/types'
import { decryptNewsDate } from '../utils/decrypt-response/news'
import { BaseApi } from './baseApi'

export class InvestmentApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getInvestmentList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IVCInvestmentInfoNew>>> {
    const rt = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/funding-round/search'),
      method: 'POST',
      data: params,
      cache: true,
    })
    return decryptInvestmentList(rt)
  }
  async getInvestmentAgg(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/funding-round/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformInvestmentAggregationSingleResult))
  }
}

/**
 * 解密 InvestmentList 接口数据
 * @param data
 * @returns
 */
function decryptInvestmentList(data: IHttpWrap<ISearchResult<IVCInvestmentInfoNew>>): IHttpWrap<ISearchResult<IVCInvestmentInfoNew>> {
  if (data.success) {
    data.data.items?.forEach((o) => {
      if (o.money_raised_upper) {
        o.money_raised_upper = decryptNumberFieldInObj(o.money_raised_upper, ['value_cny', 'value_usd'], data.xCorrelationId)
      }
      if (o.money_raised_lower) {
        o.money_raised_lower = decryptNumberFieldInObj(o.money_raised_lower, ['value_cny', 'value_usd'], data.xCorrelationId)
      }
      o.news_id_view?.forEach((item) => {
        decryptNewsDate(item, data.xCorrelationId)
      })
      if (o.money_raised_cn) {
        o.money_raised_cn = decrypt(o.money_raised_cn, data.xCorrelationId)
      }
      if (o.money_raised_en) {
        o.money_raised_en = decrypt(o.money_raised_en, data.xCorrelationId)
      }
      o.fontClassName = data.fontClassName
    })
  }
  return data
}
