import { ELPopupManager } from '@pharmsnap/shared/element-ui'
import { defineComponent } from '@vue/composition-api'
import Vue, { Component, VNodeData } from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'

export const GMask = defineComponent({
  name: 'GMask',
  setup() {
    const zIndex = ELPopupManager.nextZIndex()

    return {
      zIndex,
    }
  },
  render() {
    return (
      <div style={{ 'z-index': this.zIndex }} class="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)]">
        {this.$slots.default}
      </div>
    )
  },
})

const GMaskConstructor = Vue.extend(GMask)

export function showMask(options: { component: Component; vNodeData?: VNodeData; router?: VueRouter; i18n?: VueI18n }) {
  const instance = new GMaskConstructor({
    router: options.router,
    i18n: options.i18n,
    beforeCreate() {
      this.$slots.default = [
        this.$createElement(options.component, {
          ...options.vNodeData,
        }),
      ]
    },
  })

  instance.$mount()

  document.body.appendChild(instance.$el)

  function close() {
    instance.$destroy()
    instance.$el.remove()
  }

  return {
    close,
  }
}
