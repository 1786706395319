import { showSingleToast } from '@pharmsnap/shared/utils'
import { defineComponent, getCurrentInstance } from '@vue/composition-api'
import '../../../assets/icon-svg/copy.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BCopy = defineComponent({
  name: 'BCopy',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    copyVal: {
      type: String,
      required: true,
    },
    successMsg: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const ins = getCurrentInstance()

    async function handleCopy() {
      if (!props.copyVal) return
      try {
        await navigator.clipboard.writeText(props.copyVal)
        showSingleToast({
          message: props.successMsg || (ins?.proxy.$tc('BCopy.copySuccess') as string),
          type: 'success',
        })
      } catch (error) {
        showSingleToast({
          message: props.errorMsg || (ins?.proxy.$tc('BCopy.copyFail') as string),
          type: 'error',
        })
      }
    }

    return { handleCopy }
  },
  render() {
    return (
      <div class="cursor-pointer" onClick={this.handleCopy}>
        {this.$slots.default || <GIcon svgName="Copy" class="text-gray-450" size={24}></GIcon>}
      </div>
    )
  },
})
