import '@patsnap-ui/icon/assets/solid/SearchLeft.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { IAutoCompleteType, IListItem } from '@pharmsnap/shared/types'
import { computed, defineComponent, onMounted, PropType, ref, toRefs, unref, watch } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'
import { debounce } from 'lodash'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { BAcList } from '../../BAcList/BAcList'
import { useFreeSearchAutocomplete } from '../compositions/useFreeSearchAutocomplete'
import $classes from './BFreeTextSearch.module.scss'

/**
 * @deprecated
 */
export const BFreeTextSearch = defineComponent({
  name: 'BFreeTextSearch',
  inheritAttrs: true,
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    searchBtnPosition: {
      type: String as PropType<'inside' | 'inside-pure' | 'outside'>,
      default: 'outside',
    },
    popperWidth: {
      type: String,
    },
    limit: {
      type: Number,
      default: 100,
    },
  },
  setup(props, { emit }) {
    const { placeholder } = toRefs(props)

    const { locale } = useLocale()
    const {
      inputKeywords,
      inputElRef,
      inputSelectionStart,
      inputDelText,
      inputSelectionEnd,
      autocompleteItems,
      autocompletePrefixStart,
      autocompletePrefixEnd,
      autocompletePrefix,
      autocompleteIsNotMatch,
      handleCompositionEnd,
      handleCompositionStart,
      handleCompositionUpdate,
      handleInput,
      handleFocus: handleOriginFocus,
      handleBlur,
      handleSelection,
      handleKeyup,
      inputFocused,
      replaceAutocompleteText,
      resetAutocomplete,
      reset,
    } = useFreeSearchAutocomplete({
      locale,
      onTextChecker: (text) => {
        if (text.trim().length > props.limit) {
          showSingleToast({
            type: 'info',
            message: locale.value === 'cn' ? `输入文本长度应小于${props.limit}` : `The length of the input text should be less than ${props.limit}`,
          })
          return false
        }

        return true
      },
    })

    const popperVisible = ref(false)

    const searchElRef = ref<HTMLDivElement | null>(null)

    const popperContentElRef = ref<HTMLDivElement | null>(null)

    const inputWrapperElRef = ref<HTMLDivElement | null>(null)

    const searchBtnElRef = ref<HTMLDivElement | null>(null)

    onClickOutside(
      searchElRef,
      () => {
        closePopper()
      },
      {
        ignore: [searchBtnElRef, popperContentElRef],
      }
    )

    const inputStyle = computed(() => {
      if (inputWrapperElRef.value) {
        const wrapperHeight = inputWrapperElRef.value.offsetHeight
        return {
          height: `${wrapperHeight - 5 * 2}px`,
          'line-height': `${wrapperHeight - 5 * 2}px`,
        }
      }
      return {}
    })

    const innerBtnStyle = computed(() => {
      return {
        height: '32px',
        width: '32px',
      }
    })

    const innerSplitStyle = computed(() => {
      return {
        height: '32px',
        'line-height': '32px',
        width: '14px',
      }
    })

    const popperContentStyle = computed(() => {
      if (searchElRef.value) {
        return {
          width: props.popperWidth ? props.popperWidth : `${searchElRef.value?.offsetWidth}px`,
        }
      }
      return {}
    })

    const realPlaceholder = computed(() => {
      if (unref(inputFocused)) return ''

      return unref(placeholder)
    })

    const handleEmitSearchDebounce = debounce(handleEmitSearch, 200)

    function closePopper() {
      popperVisible.value = false
    }

    function showPopper() {
      popperVisible.value = true
    }

    function focusInput() {
      if (inputElRef.value) {
        inputElRef.value.focus()
        inputFocused.value = true
      }
    }

    function handleClearText() {
      reset()
    }

    function handleFocus(e: FocusEvent) {
      handleOriginFocus(e)
      emit('focus')
    }

    function handleSelectItem(item: IListItem) {
      const supportEntityTypes: IAutoCompleteType[] = ['Drug', 'Disease', 'Organization', 'Target']
      if (item.itemType && supportEntityTypes.includes(item.itemType)) {
        const typeRecord: Partial<Record<IAutoCompleteType, string>> = {
          Drug: 'drug',
          Disease: 'disease',
          Organization: 'organization',
          Target: 'target',
        }

        emit('addEntity', {
          id: item.id,
          display_name_cn: item.name_cn ?? '',
          display_name_en: item.name_en ?? '',
          type: typeRecord[item.itemType],
        })
      }
      const text = item.itemType === 'Target' ? item.name_en ?? '' : unref(locale) === 'cn' ? item.name_cn ?? '' : item.name_en ?? ''
      replaceAutocompleteText(text)
      closePopper()
    }

    function handleEmitSearch() {
      emit('search', inputKeywords.value)
    }

    function handleSearch() {
      closePopper()
      resetAutocomplete()
      handleEmitSearchDebounce()
    }

    function handleKeypress(e: KeyboardEvent) {
      e.stopPropagation()
      const { key: keyboardKey } = e
      if (keyboardKey === 'Enter') {
        inputElRef.value?.blur()
        handleSearch()
      }
    }

    watch(inputKeywords, (keywords) => {
      emit('input', keywords)
      if (keywords.trim() === '' && inputFocused.value) {
        showPopper()
      }
    })

    watch(inputFocused, (focused) => {
      if (focused) {
        if (inputKeywords.value.trim()) {
          if (autocompletePrefix.value.trim()) {
            !autocompleteIsNotMatch.value && showPopper()
          } else {
            closePopper()
          }
        } else {
          showPopper()
        }
      }
    })

    watch(autocompletePrefix, () => {
      if (autocompletePrefix.value.trim()) {
        showPopper()
      } else {
        closePopper()
      }
    })

    watch(autocompleteIsNotMatch, () => {
      if (autocompleteIsNotMatch.value) {
        closePopper()
      } else {
        showPopper()
      }
    })

    onMounted(() => {
      if (inputElRef.value) {
        inputElRef.value.value = props.value
        inputKeywords.value = props.value
      }
    })

    return {
      inputKeywords,
      inputSelectionStart,
      inputDelText,
      inputElRef,
      inputSelectionEnd,
      autocompleteItems,
      autocompletePrefixStart,
      autocompletePrefixEnd,
      autocompletePrefix,
      handleCompositionEnd,
      handleCompositionStart,
      handleCompositionUpdate,
      handleInput,
      handleFocus,
      handleBlur,
      handleSelection,
      handleKeyup,
      inputWrapperElRef,
      searchBtnElRef,
      inputStyle,
      popperContentStyle,
      inputFocused,
      popperVisible,
      searchElRef,
      handleSearch,
      autocompleteIsNotMatch,
      popperContentElRef,
      closePopper,
      handleSelectItem,
      realPlaceholder,
      innerBtnStyle,
      focusInput,
      handleKeypress,
      innerSplitStyle,
      handleClearText,
    }
  },
  methods: {
    renderPopperContent() {
      if (this.inputKeywords.trim() === '') return <div class="p-3">{this.$slots.default}</div>

      if (this.autocompleteIsNotMatch) {
        // return <div>{this.$t('common.noMatchedResults')}</div>
        return null
      }

      return (
        <div class="p-3">
          <BAcList
            onSelectItem={this.handleSelectItem}
            items={this.autocompleteItems}
            viewDetailLabel={this.$t('common.viewDetail') as string}
            maxHeight={400}
            onViewDetail={(item: IListItem) => this.$emit('viewDetail', item)}
          ></BAcList>
        </div>
      )
    },
  },
  render() {
    return (
      <ElPopover
        class={$classes.search}
        placement="bottom-start"
        trigger="manual"
        transition="none"
        value={this.popperVisible}
        appendToBody={true}
        visibleArrow={false}
        popperClass={$classes.popper}
      >
        <template slot="reference">
          <div ref="searchElRef" data-selection-disabled="true" class={['flex items-stretch']}>
            <div
              ref="inputWrapperElRef"
              class={[
                'flex-1 overflow-hidden',
                'flex items-center',
                'border bg-white-default',
                this.searchBtnPosition === 'outside' ? 'border-r-0 rounded-l' : 'rounded',
                this.inputFocused ? 'border-blue-default' : 'border-gray-55',
              ]}
            >
              <input
                ref="inputElRef"
                type="text"
                style={this.inputStyle}
                class={[
                  'pl-[8px] pr-[15px] outline-none border-none',
                  // this.searchBtnPosition === 'outside' ? 'w-full' : '',
                  'flex-1 overflow-hidden mr-2',
                ]}
                value={this.inputKeywords}
                placeHolder={this.realPlaceholder}
                on={{
                  focus: this.handleFocus,
                  blur: this.handleBlur,
                  input: this.handleInput,
                  compositionstart: this.handleCompositionStart,
                  compositionupdate: this.handleCompositionUpdate,
                  compositionend: this.handleCompositionEnd,
                  keypress: this.handleKeypress,
                }}
              ></input>
              {this.inputKeywords.length > 0 ? (
                <span
                  onClick={this.handleClearText}
                  class={[
                    'inline-flex items-center justify-center rounded  hover:bg-gray-30 cursor-pointer',
                    this.searchBtnPosition !== 'outside' ? 'mr-1' : 'mr-2',
                  ]}
                  style={this.innerBtnStyle}
                >
                  <GIcon svgName="SolidCloseBig" size={24} useSvgDefaultColor></GIcon>
                </span>
              ) : null}
              {(this.searchBtnPosition === 'inside' || this.searchBtnPosition === 'inside-pure') && this.inputKeywords.length > 0 ? (
                <span style={this.innerSplitStyle} class="inline-flex items-center justify-center text-base text-text-t2">
                  |
                </span>
              ) : null}
              {this.searchBtnPosition === 'inside' || this.searchBtnPosition === 'inside-pure' ? (
                <span
                  onClick={this.handleSearch}
                  class={[
                    'inline-flex items-center justify-center rounded  hover:bg-blue-hover cursor-pointer ml-1 mr-[6px]',
                    this.searchBtnPosition === 'inside' ? 'bg-blue-default text-white-default' : '',
                    this.searchBtnPosition === 'inside-pure' ? 'text-gray-450 hover:text-white-default' : '',
                  ]}
                  style={this.innerBtnStyle}
                >
                  <GIcon svgName="SolidSearchLeft" size={24}></GIcon>
                </span>
              ) : null}
            </div>
            {this.searchBtnPosition === 'outside' ? (
              <div
                ref="searchBtnElRef"
                onClick={this.handleSearch}
                class={[
                  'flex-shrink-0',
                  'inline-flex items-center justify-center',
                  'w-24 text-base text-white-default bg-blue-default hover:bg-blue-hover cursor-pointer rounded-r',
                ]}
              >
                {this.$t('common.search')}
              </div>
            ) : null}
          </div>
        </template>
        <template slot="default">
          <div ref="popperContentElRef" style={this.popperContentStyle}>
            {this.renderPopperContent()}
          </div>
        </template>
      </ElPopover>
    )
  },
})
