import '@patsnap-ui/icon/assets/solid/CoreProductsBio.svg'
import { IBaseSequence, IBaseTargetInfo } from '@patsnap/synapse_common_interface'
import { sequenceField } from '@patsnap/synapse_domain'
import { VUE_APP_BIO_URL } from '@pharmsnap/pharmsnap-web/config'
import { useDomainCtx } from '@pharmsnap/shared/src/composition/useDomainCtx'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { renderLimitedTargets } from '@pharmsnap/shared/src/render'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon, SequenceViewer } from 'patsnap-biz'
import { useLocale } from '../../../composition/useLocale'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GLink } from '../../ui/GLink/GLink'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BCopy } from '../BCopy/BCopy'
import { BDrugItem } from '../BDrugItem/BDrugItem'
import { BEntityTag } from '../tag/BEntityTag/BEntityTag'
import $classes from './BSequenceItem.module.scss'

export const BSequenceItem = defineComponent({
  name: 'BSequenceItem',
  i18n: {
    messages: {
      cn: {
        SequenceDetailTips: '点击可查看序列结构及相关专利和文献。',
      },
      en: {
        SequenceDetailTips: 'Click to view sequence structure, related patents, and  literature.',
      },
    },
  },
  props: {
    sequence: {
      type: Object as PropType<IBaseSequence>,
      required: true,
    },
    targetView: {
      type: Object as PropType<IBaseTargetInfo>,
    },
  },
  setup(props, ctx) {
    const { localeUpcase, locale, tsText } = useLocale()

    const targetName = computed(() => props.targetView?.[`entity_name_${locale.value}`])
    const domainCtx = useDomainCtx()
    const sequenceHashId = computed(() => sequenceField.extractSequenceHashId(domainCtx, props.sequence))
    const sequenceText = computed(() => {
      return sequenceField.extractSequenceText(domainCtx, props.sequence)
    })
    const sequenceChainType = computed(() => sequenceField.extractSequenceChainType(domainCtx, props.sequence))
    const sequenceType = computed(() => sequenceField.extractSequenceType(domainCtx, props.sequence))
    const sequenceNumber = computed(() => sequenceField.extractSequenceNumber(domainCtx, props.sequence))
    const sequenceSource = computed(() => {
      return sequenceField.extractSequenceSource(domainCtx, props.sequence)
    })

    const onClickSimilar = () => {
      if (sequenceHashId.value) {
        sharedCtx.service.tracking.trackGeneral({
          event_code: 'VIEW_BIO_SEQUENCE',
          trigger_point: 'SIMILAR',
        })
        ctx.emit('clickSimilar', sequenceHashId.value)
      }
    }
    return {
      tsText,
      sequenceType,
      sequenceHashId,
      sequenceNumber,
      sequenceText,
      targetName,
      localeUpcase,
      sequenceSource,
      sequenceChainType,
      onClickSimilar,
    }
  },
  render() {
    const item = this.sequence
    return (
      <div class={$classes.sequence}>
        <div class="b-sequence-block">
          {this.targetName ? (
            <GLink
              class="text-green-text cursor-pointer font-semibold text-sm hover:underline"
              href={sharedCtx.router.generatorTargetPath(this.targetView?.target_id as string)}
            >
              {this.targetName}
            </GLink>
          ) : null}
          <div class="b-sequence-block__hd">
            {this.sequenceNumber && (
              <GTooltip theme="light" content={this.$tc('SequenceDetailTips')}>
                <a
                  class="b-sequence-block__code"
                  onClick={() =>
                    sharedCtx.service.tracking.trackGeneral({
                      event_code: 'VIEW_BIO_SEQUENCE',
                      trigger_point: 'DETAIL',
                      payload: {
                        sequence_hash_id: this.sequenceHashId,
                      },
                    })
                  }
                  target="_blank"
                  href={`${VUE_APP_BIO_URL}/search#/sequences/${this.sequenceHashId}/overview`}
                >
                  Sequence Code: {this.sequenceNumber}
                </a>
              </GTooltip>
            )}
            <span class="flex-1">
              {this.sequenceChainType?.map((i) => (
                <BEntityTag class="mr-1">{i}</BEntityTag>
              ))}
            </span>
            <BCopy class="text-xs text-gray-450 inline-flex items-center mr-2 pl-1 pr-2 rounded hover:bg-gray-30" copyVal={this.sequenceText}>
              <GIcon svgName="Copy" size={18}></GIcon>
              <span class="ml-1"> {this.localeUpcase === 'CN' ? '复制' : 'Copy'}</span>
            </BCopy>
            <span class="b-sequence-block__btn b-sequence-block-btn" onClick={this.onClickSimilar}>
              <PtIcon icon="SolidCoreProductsBio"></PtIcon>
              <span class="b-sequence-block-btn__text">{this.localeUpcase === 'CN' ? '查找相似序列' : 'Find Similar Sequence'}</span>
            </span>
          </div>
          <div class="b-sequence-block__bd" data-selection-disabled>
            <SequenceViewer
              maxHeight="180px"
              diabledSelectSearch={true}
              sequence={this.sequenceText}
              sequenceType={this.sequenceType}
              shouldShowSearchBtn={false}
            ></SequenceViewer>
          </div>
          {/* 注意，当dws_bio_sequence_drug_v2.original_drug_id不为空时，也不显示。 https://confluence.zhihuiya.com/display/DLS/SNP+202402+Online+UAT */}
          {this.sequenceSource.source && !item.sequence_refer?.original_drug_id_view && (
            <div class="b-sequence-block__hd flex items-center gap-2 leading-5">
              <div class="text-sm text-text-t2">{this.tsText('来源', 'Source')}:</div>
              {this.sequenceSource.sourceUrl ? (
                <GLink href={this.sequenceSource.sourceUrl} class="text-sm text-blue-default">
                  {this.sequenceSource.source}
                </GLink>
              ) : (
                <span class="text-sm">{this.sequenceSource.source}</span>
              )}
            </div>
          )}
          {item.sequence_refer?.chain_target_ids_view?.length && (
            <div class="b-sequence-block__hd flex items-center gap-2 mt-2">
              {/* 为了和tag对齐加了mb-1 */}
              <div class="mb-1 text-text-t2">{this.$t('synapse_i18n.data_type.target')}:</div>
              <div>{renderLimitedTargets(item.sequence_refer?.chain_target_ids_view, 3)}</div>
            </div>
          )}
          {item.sequence_refer?.original_drug_id_view && (
            <div class="b-sequence-block__hd flex items-center gap-2 mt-1">
              <div class="text-sm text-text-t2">{this.tsText('当前序列信息引自', 'The sequence is quoted from')}:</div>
              <div>
                <BDrugItem data={item.sequence_refer?.original_drug_id_view} showCard></BDrugItem>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  },
})
