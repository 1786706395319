import { toThousands } from '@patsnap/synapse_common_utils'
import { computed, defineComponent, unref, watch } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { RecycleScroller } from 'vue-virtual-scroller'
import { BAutoEntityInput } from '../../BAc/BAutoEntityInput/BAutoEntityInput'
import { useFilterUiListRollupService, useFilterUiListService } from '../compositions/useFilterListService'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterAggValueNext } from '../type'
import { BFilterAdvanceContentPanel } from './BFilterAdvanceContentPanel'

export const BFilterAdvanceContentListPanel = defineComponent({
  name: 'BFilterAdvanceContentListPanel',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const service = useInjectFilterService()!

    const selectedState = computed(() => unref(service.filterService.selectedState))

    const identity = computed(() => unref(selectedState).identity)

    const uiType = computed(() => unref(selectedState)._advance.activeUiType)

    const autocompleteType = computed(() => unref(selectedState)._advance.autocompleteType)

    const isLoading = computed(() => unref(selectedState)._advance.isLoading)

    const supportRollup = computed(() => unref(selectedState)._meta.originLayoutConfig.uiAggField.uiType === 'checkbox-rollup')

    const rollup = computed(() => service.filterService.checkIsRollupByAdvanceUiType(unref(selectedState), unref(uiType)))

    const theme = computed(() => unref(service.filterService.theme))

    const listService = unref(supportRollup)
      ? useFilterUiListRollupService({
          identity,
          uiType,
        })
      : useFilterUiListService({
          identity,
          uiType,
        })

    watch(rollup, (newVal) => {
      listService.onAutocompleteClear()
      const s = listService as ReturnType<typeof useFilterUiListRollupService>
      if (s.onRollupChange) {
        s.onRollupChange(newVal)
      }
    })

    return {
      ...listService,
      identity,
      selectedState,
      autocompleteType,
      isLoading,
      theme,
      disabled: computed(() => unref(isLoading)),
    }
  },
  methods: {
    async handleSelect(item: IFilterAggValueNext) {
      await this.onCheck(item, 'add')
    },
    renderItem(data: { item: IFilterAggValueNext }) {
      const displayName = this.locale
        ? this.locale === 'cn'
          ? data.item.display_name_cn || data.item.display_name_en
          : data.item.display_name_en
        : data.item.display_name_en || data.item.display_name_cn

      const content = this.$scopedSlots.item && this.$scopedSlots.item({ item: data.item.raw })

      const displayContent = content || displayName

      const isDisabled = this.checkIsDisabled(data.item)

      const isSelected = this.checkIsSelected(data.item)

      return (
        <div
          class={[
            'h-6 leading-6 text-sm text-text-t1 flex items-center pl-1 hover:bg-gray-30',
            isDisabled || isSelected ? 'cursor-not-allowed' : 'cursor-pointer',
          ]}
          onClick={() => this.handleSelect(data.item)}
        >
          <div class="truncate">{displayContent}</div>
          {!isUndefined(data.item.count) ? <span class="ml-1">({toThousands(data.item.count)})</span> : null}
        </div>
      )
    },
    renderAutocomplete() {
      if (!this.autocompleteType) return null

      return (
        <BAutoEntityInput
          prefix="SearchRight"
          style="width: 240px;"
          onSelect={this.onAutocompleteSelect}
          onClear={this.onAutocompleteClear}
          v-model={this.autocompleteKeywords}
          type={this.autocompleteType}
          placeHolder={this.autocompletePlaceholder}
          disabled={this.disabled}
          customFetchAutoComplete={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompleteFetch}
          customShowIcon={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompleteShowIcon}
          customPopoverCls={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompletePopperClass}
          customTagBoxCls={this.theme === 'workspace' ? 'border-gray-55' : ''}
        ></BAutoEntityInput>
      )
    },
    renderContent() {
      if (!this.selectedState) return null

      if (!this.identity) return null

      if (this.isLoading) return null

      if (this.theme === 'synapse') {
        return (
          <RecycleScroller
            class="h-full overflow-y-auto"
            items={this.displayList}
            item-size={24}
            key-field="uuid"
            scopedSlots={{
              default: (data: { item: IFilterAggValueNext }) => {
                return this.renderItem(data)
              },
            }}
          ></RecycleScroller>
        )
      }

      return <div class="h-full overflow-y-auto">{this.displayList.map((i) => this.renderItem({ item: i }))}</div>
    },
  },
  render() {
    let emptyText = ''

    if (this.isEmpty) {
      emptyText = this.$t('bFilterNext.noData') as string
    } else if (this.isAutocompleteEmpty) {
      emptyText = this.$t('bFilterNext.noDataSearch') as string
    }

    return (
      <BFilterAdvanceContentPanel isLoading={this.isLoading} isEmpty={this.isEmpty || this.isAutocompleteEmpty} emptyText={emptyText}>
        <template slot="topLeft">{this.renderAutocomplete()}</template>
        <template slot="default">{this.renderContent()}</template>
      </BFilterAdvanceContentPanel>
    )
  },
})
