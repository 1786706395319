import { ElPopover } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { isNumber, isObject } from 'lodash'
import VClickOutside from 'v-click-outside'
import { GIcon } from '../GIcon/GIcon'
import $classes from './GDropdownSelect.module.scss'

export const GDropdownSelect = defineComponent({
  name: 'GDropdownSelect',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    value: {
      type: [Object, String, Number],
      require: true,
    },
    options: {
      type: Array as PropType<Array<{ label: string; value: Record<string, unknown> | string | number }>>,
      default: () => [],
    },
    popoverClass: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 180,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    pickDisplayLabel: {
      type: Function as PropType<(item: { label: string; value: Record<string, unknown> | string | number }) => string>,
    },
    scrollToSelect: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { value, options, itemKey } = toRefs(props)
    const visible = ref<boolean>(false)
    const displayLabel = ref(getCurrentLabel())

    const dropdownList = ref<HTMLElement>()

    const dropdownHeader = ref<HTMLElement>()

    const dropdownHeaderHeight = computed(() => (dropdownHeader.value && dropdownHeader.value.offsetHeight) || 0)

    const validIsActive = (option: { label: string; value: Record<string, unknown> | string | number }) => {
      if (isObject(value?.value)) {
        return isObject(option.value) ? option.value[itemKey.value] === value?.value : false
      } else {
        return option.value === value?.value
      }
    }

    const handleTogglePanelVisible = () => {
      visible.value = !visible.value
      !visible.value && ctx.emit('hide')
    }

    const handleClosePanel = () => {
      visible.value = false
      ctx.emit('hide')
    }

    const handleSelect = (option: { label: string; value: Record<string, unknown> | string | number }) => {
      visible.value = false
      ctx.emit('hide')
      ctx.emit('input', option.value)
    }

    const handleAfterEnter = () => {
      props.scrollToSelect && scrollToActive()
    }

    function getCurrentLabel() {
      if (isObject(value?.value)) {
        const found = options.value.find((i) => (isObject(i.value) ? i.value[itemKey.value] === value?.value : false))
        return props.pickDisplayLabel && found ? props.pickDisplayLabel(found) : found?.label || ''
      } else {
        const found = options.value.find((i) => i.value === value?.value)
        return props.pickDisplayLabel && found ? props.pickDisplayLabel(found) : found?.label || ''
      }
    }

    function scrollToActive() {
      if (dropdownList.value) {
        const selected = dropdownList.value.querySelector(`.${$classes.dropdownItemActive}`) as HTMLDivElement | null
        if (selected) {
          const offset = selected.offsetTop
          dropdownList.value.scrollTop = offset - (dropdownHeaderHeight.value === 0 ? 8 : dropdownHeaderHeight.value + 8 + 8)
        }
      }
    }

    watch(
      () => props.value,
      () => {
        displayLabel.value = getCurrentLabel()
      }
    )

    return {
      visible,
      displayLabel,
      handleClosePanel,
      handleTogglePanelVisible,
      handleSelect,
      validIsActive,
      dropdownList,
      dropdownHeader,
      handleAfterEnter,
    }
  },
  methods: {
    renderContent() {
      return (
        <div class={$classes.dropdownContent}>
          {this.$scopedSlots.dropdownContent ? (
            this.$scopedSlots.dropdownContent({ displayLabel: this.displayLabel })
          ) : (
            <div class={$classes.dropdownContentLabel}>{this.displayLabel}</div>
          )}
        </div>
      )
    },
    renderAction() {
      return (
        <GIcon
          useSvgDefaultColor={true}
          class={[$classes.dropdownArrow, this.visible ? $classes.dropdownArrowRotate : '']}
          svgName="SolidDropdownOpen"
          size={24}
        ></GIcon>
      )
    },
  },
  render() {
    return (
      <ElPopover
        v-model={this.visible}
        v-click-outside={this.handleClosePanel}
        placement={'bottom-start'}
        trigger="manual"
        appendToBody={this.appendToBody}
        popper-class={[$classes.popover, this.popoverClass].join(' ')}
        on={{ 'after-enter': this.handleAfterEnter }}
      >
        <div
          on={{ click: this.handleTogglePanelVisible }}
          slot="reference"
          class={[$classes.dropdown]}
          style={{ width: isNumber(this.width) ? `${this.width}px` : this.width }}
        >
          <div class={$classes.dropdownBd}>{this.renderContent()}</div>
          <div class={$classes.dropdownFt}>{this.renderAction()}</div>
        </div>
        <div class={$classes.dropdownList} style={{ height: this.height }}>
          {this.$slots.listHeader ? (
            <div ref="dropdownHeader" class={$classes.dropdownListHd}>
              {this.$slots.listHeader}
            </div>
          ) : null}
          <div ref="dropdownList" class={$classes.dropdownListBd}>
            {this.options.map((op) => (
              <div onClick={() => this.handleSelect(op)} class={[$classes.dropdownItem, this.validIsActive(op) ? $classes.dropdownItemActive : '']}>
                {this.$scopedSlots.item ? this.$scopedSlots.item({ item: op }) : op.label}
              </div>
            ))}
          </div>
        </div>
      </ElPopover>
    )
  },
})
