const GLOBAL_MARVIN_IFRAME_ID = 'synapse-marvin-iframe'

function _createGlobalMarvinIframe(src: string) {
  const existingIframe = document.getElementById(GLOBAL_MARVIN_IFRAME_ID) as HTMLIFrameElement | null
  if (existingIframe) {
    return existingIframe
  }
  const iframe = document.createElement('iframe')
  iframe.setAttribute('src', src)
  iframe.setAttribute('id', GLOBAL_MARVIN_IFRAME_ID)
  iframe.style.width = '0px'
  iframe.style.height = '0px'
  iframe.style.display = 'initial'
  iframe.style.position = 'absolute'
  iframe.style.left = '-1000px'
  iframe.style.top = '-1000px'
  iframe.style.margin = '0'
  iframe.style.padding = '0'
  document.body.appendChild(iframe)
  return iframe
}

function _getPackage(wrapperElement: HTMLIFrameElement) {
  if (wrapperElement.contentWindow && (wrapperElement.contentWindow as any).marvin !== undefined) {
    return (wrapperElement.contentWindow as any).marvin
  }
  return null
}

function _createPackage(wrapperElement: HTMLIFrameElement, resolve: (pkg: any) => void, reject: (reason: string) => void) {
  const marvinPackage = _getPackage(wrapperElement)
  if (marvinPackage) {
    marvinPackage.onReady(() => {
      resolve(marvinPackage)
    })
  } else {
    // use listener
    wrapperElement.addEventListener('load', () => {
      const pkg = _getPackage(wrapperElement as HTMLIFrameElement)
      if (pkg) {
        pkg.onReady(() => {
          resolve(pkg)
        })
      } else {
        reject('Unable to find marvin package')
      }
    })
  }
}

export async function getMarvinPackage(wrapperElement: HTMLIFrameElement) {
  function createPackage(resolve: (pkg: any) => void, reject: (reason: string) => void) {
    _createPackage(wrapperElement, resolve, reject)
  }
  const pkg = await new Promise(createPackage)
  return pkg
}

export function getGlobalMarvinPackage(src: string) {
  _createGlobalMarvinIframe(src)
  const wrapperElement = document.getElementById(GLOBAL_MARVIN_IFRAME_ID) as HTMLIFrameElement
  return getMarvinPackage(wrapperElement)
}

export async function getGlobalMarvinImageExporter(src: string, imageWidth: number, imageHeight: number) {
  const marvin = await getGlobalMarvinPackage(src)
  // const chemicalUrl = VUE_APP_PRODUCT_CHEMICAL_URL
  const exporter = new marvin.ImageExporter({
    imageType: 'image/svg',
    settings: {
      carbonLabelVisible: false,
      cpkColoring: true,
      implicitHydrogen: 'HETERO',
      width: imageWidth,
      height: imageHeight,
    },
    inputFormat: null,
    // services: {
    //   molconvertws: `${chemicalUrl}/webservices/rest-v0/util/calculate/molExport`,
    // },
  })
  return exporter
}
