import { I18nLang, IBaseTargetInfo, ILang } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import Raven from 'raven-js'
import { pickFirstSpecialLangName } from './common'

/** 老的靶点取名逻辑 */
export function getTargetNameOld<T extends Pick<IBaseTargetInfo, 'target_id' | 'short_name' | 'target_name'>>(data: T, locale: I18nLang) {
  const name = pickFirstSpecialLangName(data.target_name || [], locale.toLocaleUpperCase() as ILang, 'EN')
  const shortName = pickFirstSpecialLangName(data.short_name || [], locale.toLocaleUpperCase() as ILang, 'EN')
  return shortName || name
}

/**
 * 获取靶点名称
 * @param data 包含靶点名称
 * @param locale I18nLang
 * @param isNameNeedDegrade 是否需要降级
 * @returns string
 */
export function getTargetName<T extends Pick<IBaseTargetInfo, 'target_id' | 'short_name' | 'target_name' | 'entity_name_cn' | 'entity_name_en'>>(
  data: T,
  locale: I18nLang,
  isNameNeedDegrade = false
) {
  // 根据语言环境展示靶点名称
  const entityName = getLangDegraded({ name_cn: data.entity_name_cn, name_en: data.entity_name_en }, locale)
  if (entityName) {
    return entityName
  }
  if (isNameNeedDegrade) {
    return getTargetNameOld(data, locale)
  }
  Raven.captureMessage(`[log]: target: ${data.target_id} lack entity_name`, {
    extra: {
      route: window.location.href,
    },
  })
}
