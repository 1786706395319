import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BCardViewType = defineComponent({
  name: 'BCardViewType',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const options = [
      {
        labelI18nKey: 'BCardViewType.normal',
        value: 'normal',
      },
      {
        labelI18nKey: 'BCardViewType.simple',
        value: 'simple',
      },
    ]
    const currentValue = ref('')
    const show = ref(false)
    const labelI18nKey = computed(() => {
      const findItem = options.find((item) => item.value === currentValue.value)
      if (findItem) {
        return findItem.labelI18nKey
      }
      return ''
    })
    watch(
      () => props.value,
      (value) => {
        if (value) {
          currentValue.value = value
        }
      },
      {
        immediate: true,
      }
    )
    function onSelect(value: string) {
      show.value = false
      currentValue.value = value
      emit('input', value)
    }
    return { options, show, labelI18nKey, onSelect, currentValue }
  },
  render() {
    const label = this.$tc(this.labelI18nKey)
    return (
      <ElPopover popperClass="border" v-model={this.show}>
        <template slot="reference">
          <span class="cursor-pointer inline-flex items-center text-sm h-8 px-1 rounded hover:bg-gray-30" style="max-width:300px">
            <span class="truncate" title={label}>
              {label}
            </span>
            <GIcon
              class="text-xl text-text-t2 cursor-pointer flex-shrink-0 ml-1"
              svgName={this.show ? 'SolidDropdownClose' : 'SolidDropdownOpen'}
              size={24}
            ></GIcon>
          </span>
        </template>
        <div>
          {this.options.map((item) => (
            <div
              onClick={() => this.onSelect(item.value)}
              class={['p-1', 'cursor-pointer', 'hover:bg-gray-30', 'rounded', { 'text-blue-default': this.currentValue === item.value }]}
              key={item.value}
              value={item.value}
            >
              {this.$tc(`${item.labelI18nKey}`)}
            </div>
          ))}
        </div>
      </ElPopover>
    )
  },
})
