import '@patsnap-ui/icon/assets/solid/ArrowRight.svg'
import '@patsnap-ui/icon/assets/solid/CircleClose.svg'
import '@patsnap-ui/icon/assets/solid/feedback.svg'
import '@patsnap-ui/icon/assets/solid/onlineConsult.svg'
import '@patsnap-ui/icon/assets/solid/wechatService.svg'
import operatorWechatImg from '@pharmsnap/pharmsnap-web/assets/img/operatorWechat.png'
import workWechatImg from '@pharmsnap/pharmsnap-web/assets/img/workWechat.png'
import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { GIcon } from '@pharmsnap/shared/src/components'
import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import { HelpService } from 'patsnap-biz'
import { ErrorType } from '../Feedback/Feedback'
import FeedbackDialog from '../Feedback/FeedbackDialog/FeedbackDialog'
import FeedbackSuccessDialog from '../Feedback/FeedbackSuccessDialog/FeedbackSuccessDialog'
import $class from './ServiceHelp.module.scss'

export const ServiceHelp = defineComponent({
  name: 'HelpService',
  props: {},
  setup(props) {
    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const ins = getCurrentInstance()
    const {
      state: { dialogVisible: ls360DialogVisible },
    } = useLs360ChatStore()
    const currentErrorType = ref<ErrorType>('data')
    const feedbackDialogVisible = ref(false)
    const feedbackBtnClicked = (errorType: ErrorType) => {
      currentErrorType.value = errorType
      feedbackDialogVisible.value = true
    }
    const feedbackSuccessDialogVisible = ref(false)
    const QIYUDOM = '#YSF-BTN-HOLDER'
    const menu = [
      {
        title: isFreeUser.value ? ins?.proxy.$t('helpMenu.feedback') : ins?.proxy.$t('helpMenu.onlineConsult'),
        icon: isFreeUser.value ? 'SolidFeedback' : 'SolidOnlineConsult',
        slotName: isFreeUser.value ? 'feedback' : '',
        show: true,
        clickHandler: () => {
          if (isFreeUser.value) return
          const qiyuBtn = document.querySelector<HTMLElement>(QIYUDOM)
          if (qiyuBtn) {
            if (document.all) {
              qiyuBtn.click()
            } else {
              const event = document.createEvent('MouseEvents')
              event.initEvent('click', true, true)
              qiyuBtn.dispatchEvent(event)
            }
          }
        },
      },
      {
        title: ins?.proxy.$t('helpMenu.wechatService'),
        icon: 'SolidWechatService',
        qrImage: operatorWechatImg,
        qrTitle: ins?.proxy.$t('helpMenu.qrTitle'),
        slotName: 'wechatService',
        show: !isFreeUser.value,
      },
    ].filter((item) => item.show)
    const feedbackItems: Array<{ title: string; errorType: ErrorType }> = [
      {
        title: ins?.proxy.$t('feedback.dataError') as string,
        errorType: 'data',
      },
      {
        title: ins?.proxy.$t('feedback.functionError') as string,
        errorType: 'function',
      },
    ]
    return {
      isFreeUser,
      menu,
      feedbackDialogVisible,
      feedbackBtnClicked,
      currentErrorType,
      feedbackSuccessDialogVisible,
      feedbackItems,
      ls360DialogVisible,
    }
  },
  methods: {
    renderFeedbackDialog() {
      return (
        <FeedbackDialog
          currentErrorType={this.currentErrorType}
          visible={this.feedbackDialogVisible}
          onClose={() => {
            this.feedbackDialogVisible = false
          }}
          onSubmitSuccess={() => {
            this.feedbackSuccessDialogVisible = true
          }}
        ></FeedbackDialog>
      )
    },
    renderFeedbackSuccessDialog() {
      return (
        <FeedbackSuccessDialog
          onClose={() => (this.feedbackSuccessDialogVisible = false)}
          visible={this.feedbackSuccessDialogVisible}
        ></FeedbackSuccessDialog>
      )
    },
  },
  render() {
    return (
      <div class="select-none" v-show={!this.ls360DialogVisible}>
        {/* 盖过el-backtop */}
        <div class="fixed bottom-4 right-6 z-[2]">
          <HelpService
            class={$class.helpService}
            menu={this.menu}
            entryText={this.$t('helpMenu.help&service')}
            scopedSlots={{
              wechatService: ({ item }: { item: { qrDesc: string; qrTitle: string } }) => (
                <div class="w-[108px] flex flex-col items-center">
                  <img src={this.isFreeUser ? operatorWechatImg : workWechatImg} alt="qrCode" />
                  <div class="font-semibold mt-1">{item.qrTitle}</div>
                  <div class="">{this.$t('helpMenu.qrDescOne')}</div>
                  <div class="">{this.$t('helpMenu.qrDescTwo')}</div>
                  <div class="">{this.$t('helpMenu.qrDescThree')}</div>
                </div>
              ),
              feedback: () => (
                <div class="w-[161px] space-y-1">
                  {this.feedbackItems.map((item) => (
                    <div
                      class="flex px-2 py-[2px] justify-between rounded hover:bg-gray-20 items-center text-black-default"
                      onClick={() => this.feedbackBtnClicked(item.errorType)}
                    >
                      <div class="text-sm">{item.title}</div>
                      <GIcon svgName="SolidArrowRight" size={24} />
                    </div>
                  ))}
                </div>
              ),
            }}
          ></HelpService>
        </div>
        {this.renderFeedbackDialog()}
        {this.renderFeedbackSuccessDialog()}
      </div>
    )
  },
})
