import { IGDescriptionItemProps, ISearchCollapseRule } from '@pharmsnap/shared/types'
import { PropType, computed, defineComponent, getCurrentInstance, ref, toRefs, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { GDescription } from '../../ui/GDescription/GDescription'
import { GDialog } from '../../ui/GDialog/GDialog'
import { GRadio } from '../../ui/GRadio/GRadio'
import $style from './BPtCollapseDialog.module.scss'
import { BPtCollapseOptionItem } from './BPtCollapseOptionItem'
import { PATENT_COLLAPSE_ALL_FIELD } from './config'
import { usePatentCollapse } from './usePatentCollapse'

export const BPtCollapseDialog = defineComponent({
  name: 'BPtCollapseDialog',
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultField: {
      type: String,
      default: '',
    },
    defaultRules: {
      type: Array as PropType<ISearchCollapseRule[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { defaultField, defaultRules } = toRefs(props)

    const { state, action } = usePatentCollapse(defaultField, defaultRules, {})
    const ins = getCurrentInstance()

    const currentSelectField = ref(state.collapseField === '' ? PATENT_COLLAPSE_ALL_FIELD : state.collapseField)

    const currentCollapseData = computed(() => {
      const data = state.collapseOptionRecord[currentSelectField.value]
      return data
    })

    const tip = computed(() => {
      const fieldLabelTexts = state.collapseFieldOptions.map((i) => i.label)
      const fieldTipTexts = state.collapseFieldOptions.map((i) => ins?.proxy.$tc(`patent.collapseTip.${i.value}`) || '')
      const group = fieldLabelTexts.map((text, index) => {
        const fieldTip = fieldTipTexts[index]

        return `<div style="font-size: 12px; color: #020A1A; line-height: 16px; margin-top: ${index === 0 ? 0 : 20}px;">
          <div class="font-bold">${text}:</div>
          <div>${fieldTip}</div>
        </div>`
      })

      return group.join('')
    })

    const descItems = computed<IGDescriptionItemProps[]>(() => [
      {
        label: ins?.proxy.$tc('patent.collapseSetting.resultDisplay') || '',
        tip: tip.value,
        field: 'collapse',
        labelCls: 'text-sm text-text-t1',
        tipPlacement: 'bottom-start',
      },
    ])

    watch(
      () => state.collapseField,
      () => {
        currentSelectField.value = state.collapseField
      }
    )

    // 阻止默认事件，避免点击弹窗，导致dashboard edit panel被关闭
    const handleStopPropagation = (e?: Event) => {
      e?.stopPropagation()
    }

    return {
      collapseFieldOptions: state.collapseFieldOptions,
      collapseField: state.collapseField,
      collapseRules: state.collapseRules,
      collapseOptionRecord: state.collapseOptionRecord,
      isUnCollapseField: action.isUnCollapseField,
      currentSelectField,
      currentCollapseData,
      updateOptionRecord: action.updateOptionRecord,
      addRule: action.addRule,
      deleteRule: action.deleteRule,
      pickCollapseParams: action.pickCollapseParams,
      descItems,
      handleStopPropagation,
    }
  },
  methods: {
    handleInput(visible: boolean) {
      this.$emit('input', visible)
    },
    handleChangeField(rule: ISearchCollapseRule, field: string) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, field: field })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleChangeType(rule: ISearchCollapseRule, type: ISearchCollapseRule['type']) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, type: type })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleChangeValue(rule: ISearchCollapseRule, value: string) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, value: value })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleAddRule(rule: ISearchCollapseRule) {
      this.addRule()
    },
    handleDeleteRule(rule: ISearchCollapseRule) {
      this.deleteRule(rule)
    },
    handleSave(e?: Event) {
      this.handleStopPropagation(e)
      const params = this.pickCollapseParams(this.currentSelectField)
      this.$emit('save', params)
    },

    renderCollapseSetting() {
      return this.collapseFieldOptions.map((op) => {
        const { value, label } = op

        return (
          <div style="line-height: 0;" key={value}>
            <GRadio class={$style.radio} v-model={this.currentSelectField} items={[{ label: label, value: value }]}></GRadio>
            {value === PATENT_COLLAPSE_ALL_FIELD ? null : (
              <BPtCollapseOptionItem
                class={$style.panelItem}
                v-show={this.currentSelectField === value}
                data={this.collapseOptionRecord[value]}
                onChangeField={this.handleChangeField}
                onChangeType={this.handleChangeType}
                onChangeValue={this.handleChangeValue}
                onAdd={this.handleAddRule}
                onDelete={this.handleDeleteRule}
              ></BPtCollapseOptionItem>
            )}
          </div>
        )
      })
    },
  },
  render() {
    return (
      <GDialog
        width="880px"
        size="medium"
        closeOnClickModal
        title={this.$tc('common.setting')}
        visible={this.visible}
        onInput={this.handleInput}
        confirmText={this.$tc('common.submit')}
        cancelText={this.$tc('common.cancel')}
        onConfirm={this.handleSave}
        onCancel={this.handleStopPropagation}
        onClose={this.handleStopPropagation}
      >
        <div onClick={this.handleStopPropagation}>
          <GDescription
            class={$style.container}
            width={130}
            data={{}}
            items={this.descItems}
            scopedSlots={{
              collapse: () => <div class="grid gap-1 grid-cols-1">{this.renderCollapseSetting()}</div>,
            }}
          ></GDescription>
        </div>
      </GDialog>
    )
  },
})
