import { getSingle } from '@patsnap/synapse_common_utils'
import { ref } from '@vue/composition-api'
import { IOsPlatform } from '../types'

const androidReg = /Android/
const iosReg = /iP(ad|hone|od)/
const windowsReg = /Windows/
const macosReg = /Mac OS X/
const linuxReg = /Linux/

const getPlatform = getSingle((): IOsPlatform => {
  const userAgent = navigator.userAgent
  if (androidReg.test(userAgent)) {
    return 'android'
  }
  if (iosReg.test(userAgent)) {
    return 'ios'
  }
  if (windowsReg.test(userAgent)) {
    return 'windows'
  }
  if (macosReg.test(userAgent)) {
    return 'macos'
  }
  if (linuxReg.test(userAgent)) {
    return 'linux'
  }
  return 'windows'
})

export function useOSPlatform() {
  const platform = ref<IOsPlatform>('windows')
  const judgePlatform = () => {
    platform.value = getPlatform()
  }
  judgePlatform()
  return {
    platform,
  }
}
