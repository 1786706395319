import { PersistentCacheConfig, PersistentCacheStrategy, requestConfigWrapper } from '@patsnap/common-ps-http/dist/PersistentCache'
import {
  IAggregationData,
  IAggregationParams,
  IAggregationSingleResult,
  IAggregationValue,
  IPatentBase,
  IPatentExtension,
  IPatentExtensionData,
  ISearchParams,
} from '@patsnap/synapse_common_interface'
import { IAutoCompleteParams, ILang } from '@pharmsnap/shared/types'
import {
  IPatentClaim,
  IPatentClaimTrans,
  IPatentClaimTree,
  IPatentDetail,
  IPatentImagesRes,
  IPatentNumberDetail,
  ISimpleFamilyParams,
  ITopAssigneeRes,
  ITopPatentCoAssignee,
} from '@pharmsnap/shared/types/patent'
import { ISearchResult } from '@pharmsnap/shared/types/search'
import { formatOrgName } from '@pharmsnap/shared/utils'
import { transformAggregationResponse, transformGroupAggregationSingleResultByMap } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformPatentAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/patent'
import { decrypt } from '@pharmsnap/shared/utils/encrypt/encrypt'
import { AxiosRequestConfig } from 'axios'
import { isString } from 'lodash'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import {
  decryptPatentDetail,
  decryptPatentTopAssignee,
  decryptTopPatentOrgNumber,
  formatPatentCoAssigneeName,
} from '../utils/decrypt-response/patent'
import { BaseApi } from './baseApi'
const persistCacheConfInst = new PersistentCacheConfig({
  strategy: PersistentCacheStrategy.CACHE_FIRST_WITH_SILENT_UPDATE,
  detectAllowCachingHook: PersistentCacheConfig.defaults.detectAllowCachingHook_statusdata,
})

const transformPatentList = (data: ISearchResult<IPatentDetail>, xCorrelationId: string) => {
  data.items?.forEach((item) => {
    const isStr = isString(item.curation)

    // 解密前是字符串
    if (isStr) {
      item.curation = decrypt(item.curation as unknown as string, xCorrelationId) === 'true' ? true : false
    }
  })
}
export class PatentApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string, public analyticsUrl: string) {
    super(http, baseUrl)
  }
  private getAnalyticsUrl(url: string) {
    return `${this.analyticsUrl}${url}`
  }
  /**
   * 专利搜索列表，不计搜索次数
   */
  async getPatentList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPatentDetail>>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/search'),
      cache: true,
      trackingName: 'PATENT_LIST',
    })) as IHttpWrap<ISearchResult<IPatentDetail>>
    if (rt.success && rt.data) {
      transformPatentList(rt.data, rt.xCorrelationId)
    }
    return rt
  }

  /**
   * 专利搜索列表，计搜索次数
   */
  async getPatentListWithCalcLimits(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPatentDetail>>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent'),
      cache: true,
      trackingName: 'PATENT_LIST',
    })) as IHttpWrap<ISearchResult<IPatentDetail>>
    if (rt.success && rt.data) {
      transformPatentList(rt.data, rt.xCorrelationId)
    }
    return rt
  }

  async getPatentSearchKey(params: ISearchParams): Promise<IHttpWrap<string>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/patent/search_for_key'),
      data: params,
      method: 'POST',
      cache: false,
    })
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/patent/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformPatentAggregationSingleResult))
  }

  async getLegalStatusAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    const legalStatusConfig = await this.getPatentLegalStatusConfig().then((rt) => rt.data.data)
    function injectLegalStatueName(data: IAggregationValue, isSimple = true): IAggregationValue {
      const legalStatusMap = isSimple ? legalStatusConfig.simpleLegalStatus : legalStatusConfig.legalStatus
      const value = legalStatusMap[data.key]
      if (!value) {
        return data
      }
      return {
        ...data,
        display_name_cn: value.title.cn,
        display_name_en: value.title.en,
      }
    }
    const patentFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
      SIMPLE_LEGAL_STATUS: injectLegalStatueName,
      LEGAL_STATUS: (data) => injectLegalStatueName(data, false),
    }
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/patent/aggregation/legal_status'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) =>
      transformAggregationResponse(rt, (data: IAggregationSingleResult, xCorrelationId: string) => {
        return transformGroupAggregationSingleResultByMap(data, patentFieldTransformFnMap, xCorrelationId)
      })
    )
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<{ limit: number; offset: number; total: number; uncollapse_total: number }>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/search_for_count'),
      cache: true,
    })
  }
  async getPatentDetail(id: string, lang?: ILang | undefined): Promise<IHttpWrap<IPatentDetail>> {
    const rt: IHttpWrap<IPatentDetail> = await this.http({
      method: 'GET',
      params: {
        translation: lang,
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}`),
      trackingName: 'PATENT_DETAIL',
    })
    if (rt.success && rt.data) {
      decryptPatentDetail(rt.data, rt.xCorrelationId, rt.fontClassName)
    }
    return rt
  }
  async getPatentLegalStatusConfig(): Promise<IHttpWrap<any>> {
    return this.http(
      requestConfigWrapper(
        {
          method: 'GET',
          url: this.getAnalyticsUrl(`core-basic-api/analytics/config/legal-status`),
        },
        persistCacheConfInst
      ) as AxiosRequestConfig
    )
  }

  async getPatentImages(id: string): Promise<IHttpWrap<IPatentImagesRes>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}/image`),
      cache: true,
    })
  }

  async getPatentClaim(id: string): Promise<IHttpWrap<IPatentClaim>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}/claim`),
      cache: true,
    })
  }

  async getPatentClaimTrans(id: string, lang: ILang): Promise<IHttpWrap<IPatentClaimTrans>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}/claim?translation=${lang}`),
      cache: true,
    })
  }

  async getPatentClaimTree(id: string): Promise<IHttpWrap<IPatentClaimTree>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}/claim-tree`),
      cache: true,
    })
  }

  async getPatentDetailByPatentNumber(patentNumber: string): Promise<IHttpWrap<IPatentNumberDetail>> {
    const rt = (await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/patent/pn'),
      data: {
        patent_number: patentNumber,
      },
      cache: true,
    })) as IHttpWrap<IPatentNumberDetail>
    if (rt.success && rt.data) {
      decryptPatentDetail(rt.data, rt.xCorrelationId, rt.fontClassName)
    }
    return rt
  }

  async getAutocomplete(params: IAutoCompleteParams) {
    const { keywords } = params
    return this.getPatentDetailByPatentNumber(keywords)
  }

  async getPatentCoAssignee(params: IAggregationParams): Promise<IHttpWrap<ITopPatentCoAssignee>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/aggregation/org-cooperation'),
      cache: true,
    })) as IHttpWrap<ITopPatentCoAssignee>
    if (rt.success && rt.data) {
      decryptTopPatentOrgNumber(rt.data, rt.xCorrelationId)
      formatPatentCoAssigneeName(rt.data.items)
    }
    return rt
  }

  async getPatentTopAssignee(params: IAggregationParams): Promise<IHttpWrap<ITopAssigneeRes>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/aggregation/top-org'),
      cache: true,
    }).then((rt: IHttpWrap<ITopAssigneeRes>) => {
      if (rt.success && rt.data) {
        decryptPatentTopAssignee(rt.data, rt.xCorrelationId)
        rt.data.items?.forEach((item) => formatOrgName(item.items))
      }
      return rt
    })
  }

  async getPnByPatentId(params: string[]): Promise<IHttpWrap<string[]>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/get_pn_by_patent_id'),
      cache: true,
    })
  }

  async getPatentPDF(id: string): Promise<IHttpWrap<{ pdf: string }>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/${id}/pdf`),
    })
  }

  /**
   * 药物详情页获取当前这个药物的专利分类
   * @param params
   * @returns
   */
  async getDrugTypeAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/patent/aggregation/core_drug_patent_type'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformPatentAggregationSingleResult))
  }

  /**
   * 药物详情页获取当前这个药物的专利
   * @param params
   * @returns
   */
  async getPatentListForDrugDetail(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPatentDetail> & { uncollapse_total: number }>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/patent/search_for_drug_detail'),
      cache: true,
    })) as IHttpWrap<ISearchResult<IPatentDetail> & { uncollapse_total: number }>
    if (rt.success) {
      rt.data.items?.forEach((item) => {
        decryptPatentDetail(item, rt.xCorrelationId, rt.fontClassName)
      })
    }
    return rt
  }
  /**
   * 获取专利延长信息
   * @param params
   * @returns
   */
  async getPatentExtension(patentId: string): Promise<IHttpWrap<IPatentExtensionData>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/extension/${patentId}`),
      cache: true,
    })
  }
  /**
   * 根据药物ID获取专利延长信息
   * @param params
   * @returns
   */
  async getPatentExtensionByDrugId(drugId: string, extensionType = 'SPC'): Promise<IHttpWrap<ISearchResult<IPatentExtension>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/extension`),
      cache: true,
      data: {
        drug_id: drugId,
        extension_type: extensionType,
      },
    })
  }

  async getPatentCardData({ id }: { id: string }, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/card/${id}`),
      cache: true,
      method: 'GET',
    })
  }
  /**
   * 获取同族专利列表 https://confluence.zhihuiya.com/display/DLS/SNP+202407+Online+UAT
   * @param params
   * @returns
   */
  async getPatentFamily(params: ISimpleFamilyParams): Promise<IHttpWrap<ISearchResult<IPatentBase>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/patent/family/member/search`),
      cache: true,
      data: params,
    })
  }
}
