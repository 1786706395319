import { HOT_TAG_SCENE_TYPE, IHotTagRes } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class TagApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getHotTags(data: { scene_type: HOT_TAG_SCENE_TYPE }): Promise<IHttpWrap<IHotTagRes>> {
    return this.http({
      method: 'POST',
      data: {
        scene_type: [data.scene_type],
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/hottag/search_v2`),
      cache: true,
    })
  }
}
