import { IBaseTargetInfo, ITargetCardInfo } from '@pharmsnap/shared/types'
import { decryptDevStatus, decryptTarget } from '@pharmsnap/shared/utils'
import { Ref, getCurrentInstance, onMounted, ref, shallowRef, watch } from '@vue/composition-api'
import { sharedCtx } from '../../../context'
import { IHttpWrap } from '../../../service/http/types'

function generatorEntityFetchCard<T>(func: (id: string, options?: { drug_id?: string }) => Promise<IHttpWrap<T>>) {
  return (id: Ref<string>, options?: Ref<{ drug_id?: string }>) => {
    const loading = ref(true)
    const ins = getCurrentInstance()
    const data = shallowRef<T>()
    const cachedIds = ref<string[]>([])
    const fetchCardInfo = async () => {
      if (!id.value) return
      if (!cachedIds.value.includes(id.value)) {
        loading.value = true
      }
      const oldId = id.value
      const rt = await func(id.value, options?.value)
      if (rt.success && rt.data) {
        if (oldId === id.value) {
          const { highest_dev_status_view } = rt.data as unknown as ITargetCardInfo
          decryptTarget(rt.data as unknown as IBaseTargetInfo, rt.xCorrelationId || '')
          highest_dev_status_view && decryptDevStatus(highest_dev_status_view, rt.xCorrelationId || '')
          data.value = { ...rt.data, fontClassName: rt.fontClassName }
        }
        if (!cachedIds.value.includes(id.value)) {
          ins?.emit('loaded')
          cachedIds.value.push(id.value)
        }
      }
      loading.value = false
    }

    watch(id, fetchCardInfo)

    onMounted(fetchCardInfo)

    return {
      loading,
      data,
    }
  }
}

export const useDiseaseFetchCard = (id: Ref<string>, options?: Ref<{ drug_id?: string }>) => {
  return generatorEntityFetchCard(sharedCtx.service.disease.getCardInfo.bind(sharedCtx.service.disease))(id, options)
}
export const useTargetFetchCard = (id: Ref<string>) =>
  generatorEntityFetchCard(sharedCtx.service.target.getCardInfo.bind(sharedCtx.service.target))(id)
export const useDrugFetchCard = (id: Ref<string>) => generatorEntityFetchCard(sharedCtx.service.drug.getCardInfo.bind(sharedCtx.service.drug))(id)
export const useOrganizationFetchCard = (id: Ref<string>) =>
  generatorEntityFetchCard(sharedCtx.service.organization.getCardInfo.bind(sharedCtx.service.organization))(id)
