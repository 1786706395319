import { IAnnotationTag } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { getTargetName } from '@pharmsnap/shared/src/utils/target'
import { IHistorySearchParams, ILang } from '@pharmsnap/shared/types'
import { getLangDegraded, getSpecialLang } from '@pharmsnap/shared/utils/lang'
import { PropType, computed, defineComponent } from '@vue/composition-api'

function getAnnotationTagDisplayName(data: IAnnotationTag, lang: ILang, isNameNeedDegrade = false): string {
  const i18nLang = lang.toLocaleLowerCase() as I18nLang
  switch (data.type) {
    case 'keyword':
      return data.value
    case 'disease':
      return getSpecialLang({ data: data.normalized_id_view.disease_name || [], field: 'name', lang }) || ''
    case 'drug':
      return getSpecialLang({ data: data.normalized_id_view.drug_name || [], field: 'name', lang }) || ''
    case 'target':
      return getTargetName(data.normalized_id_view, i18nLang, isNameNeedDegrade) || ''
    case 'organization':
      return getLangDegraded(data.normalized_id_view, i18nLang) || ''
    case 'org_master_entity':
      return getLangDegraded(data.normalized_id_view, i18nLang) || ''
    case 'custom':
      return getLangDegraded(data.normalized_id_view, i18nLang) || ''
  }
}

export const BNewsAnnotationTag = defineComponent({
  name: 'BFilterTag',
  props: {
    data: {
      type: Object as PropType<IAnnotationTag>,
      default: () => ({}),
    },
    /**
     * 取名逻辑是否需要降级
     * 邮件提醒变更详情，需要降级，不然后端需要刷历史变更数据
     */
    isNameNeedDegrade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { localeUpcase } = useLocale()
    const displayName = computed(() => {
      return getAnnotationTagDisplayName(props.data, localeUpcase.value, props.isNameNeedDegrade)
    })
    const id = computed(() => {
      return props.data.annotation_id
    })
    const handleClick = () => {
      const searchParams: IHistorySearchParams = {
        data_type: 'news',
        query: {
          type: 'group',
          filter: [
            {
              type: 'field',
              fields: ['annotation_tag'],
              value: [
                {
                  type: 'text',
                  value: id.value,
                  display_name_en: getAnnotationTagDisplayName(props.data, 'EN'),
                  display_name_cn: getAnnotationTagDisplayName(props.data, 'CN'),
                },
              ],
            },
          ],
        },
        hidden_flag: true,
      }
      openPageWithoutPending(async () => {
        const queryRes = await sharedCtx.service.history.saveQuery(searchParams)
        if (queryRes.success) {
          return sharedCtx.router.generatorNewsListPath(queryRes.data.id)
        }
      })
    }

    return {
      displayName,
      handleClick,
    }
  },
  render() {
    return (
      <span
        class="hover:bg-green-light bg-green-tag text-green-text hover:text-green-textHover hover:underline cursor-pointer text-sm py-0.5 px-2 rounded inline-flex"
        onClick={this.handleClick}
      >
        {this.displayName}
      </span>
    )
  },
})
