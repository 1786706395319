import { getErrorCodeMessage, showSingleToast } from '../utils'
import { useLocale } from './useLocale'

export function userErrorMessage() {
  const { locale } = useLocale()
  function showErrorMessageByToast(errorCode: number, customErrorMessage?: string) {
    const msg = getErrorCodeMessage(errorCode, locale.value)
    if (msg) {
      showSingleToast({
        type: 'error',
        message: msg,
      })
      return
    }
    if (customErrorMessage) {
      showSingleToast({
        type: 'error',
        message: customErrorMessage,
      })
    }
  }
  return {
    showErrorMessageByToast,
  }
}
