import {
  IAlertEntity,
  IAlertEntityType,
  IBaseClinicalResult,
  IBaseDictItem,
  IBaseDiseaseInfo,
  IBaseDrugInfo,
  IBaseOrgInfo,
  IBaseTargetInfo,
  IPatentDetail,
} from '@patsnap/synapse_common_interface'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IClinicalTrial } from '@pharmsnap/shared/src/types'
import { getOrganizationName, getSpecialLang } from '@pharmsnap/shared/src/utils'
import promisePoller, { CANCEL_TOKEN } from 'promise-poller'
import { IColumnFieldItem } from './types'

export function pickShowFiled(data: IColumnFieldItem[]): string[] {
  return data.filter((item) => item.show).map((item) => item.field)
}

async function checkExportStatusRequest(id: string) {
  const res = await sharedCtx.service.drug.exportStatus(id)
  if (res.success) {
    if (res.data.export_status === 'SUCCESS') {
      return Promise.resolve(res)
    }
    // 该 Promise.reject不会终止轮询
    return Promise.reject(res)
  } else {
    // 接口报错,直接终止轮询
    return Promise.reject(CANCEL_TOKEN)
  }
}
/**
 * 创建一个导出状态查询的轮询
 * @param id 导出任务id
 * @returns
 */
export async function createExportPoller(id: string) {
  function taskFn() {
    return checkExportStatusRequest(id)
  }
  return promisePoller({
    taskFn,
    /**
     * 心跳
     */
    interval: 2000,
    /**
     * 失败重试次数
     */
    retries: 10,
    /**
     * 超时时长
     */
    masterTimeout: 30000,
  })
}

declare module 'promise-poller' {
  export const CANCEL_TOKEN: string
}
export function entity2AlertEntity(
  alertEntityType: IAlertEntityType,
  data:
    | IBaseDrugInfo[]
    | IBaseClinicalResult[]
    | { target_id_view: IBaseTargetInfo[] }[]
    | { org_id_view: IBaseOrgInfo }[]
    | IClinicalTrial[]
    | IPatentDetail[]
    | { drug_type_id_view: IBaseDictItem }[]
    | { disease_id_view: IBaseDiseaseInfo }[]
): IAlertEntity[] | undefined {
  if (alertEntityType === 'drug') {
    const typeData = data as IBaseDrugInfo[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.drug_id,
            display_name_cn: getSpecialLang({ data: item.drug_name, field: 'name', lang: 'CN', isDegraded: true }) || '',
            display_name_en: getSpecialLang({ data: item.drug_name, field: 'name', lang: 'EN', isDegraded: true }) || '',
          },
        ],
      }
    })
  }
  if (alertEntityType === 'clinical_trial') {
    const typeData = data as IClinicalTrial[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.clinical_trial_id,
            register_number: item.register_number,
            display_name_cn: item.register_number,
            display_name_en: item.register_number,
          },
        ],
      }
    })
  }
  if (alertEntityType === 'organization') {
    const typeData = data as { org_id_view: IBaseOrgInfo }[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.org_id_view.entity_id,
            display_name_cn: getOrganizationName(item.org_id_view, 'cn'),
            display_name_en: getOrganizationName(item.org_id_view, 'en'),
          },
        ],
      }
    })
  }
  if (alertEntityType === 'patent') {
    const typeData = data as IPatentDetail[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.patent_id,
            display_name_cn: item.pn || '',
            display_name_en: item.pn || '',
          },
        ],
      }
    })
  }
  if (alertEntityType === 'target' || alertEntityType === 'target_extend') {
    const typeData = data as { target_id_view: IBaseTargetInfo[] }[]
    return typeData.reduce((acc, item) => {
      const firstTarget = item.target_id_view?.[0]
      if (!firstTarget) {
        return acc
      }
      return [
        ...acc,
        {
          data_type: alertEntityType,
          value: [
            {
              id: firstTarget.target_id,
              display_name_cn: firstTarget.entity_name_cn || '',
              display_name_en: firstTarget.entity_name_en || '',
            },
          ],
        },
      ]
    }, [] as IAlertEntity[])
  }
  if (alertEntityType === 'drug_type') {
    const typeData = data as { drug_type_id_view: IBaseDictItem }[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.drug_type_id_view.dict_id,
            display_name_cn: item.drug_type_id_view.name_cn,
            display_name_en: item.drug_type_id_view.name_en,
          },
        ],
      }
    })
  }
  if (alertEntityType === 'disease') {
    const typeData = data as { disease_id_view: IBaseDiseaseInfo }[]
    return typeData.map((item) => {
      return {
        data_type: alertEntityType,
        value: [
          {
            id: item.disease_id_view.disease_id,
            display_name_cn: getSpecialLang({ data: item.disease_id_view.disease_name, field: 'name', lang: 'CN', isDegraded: true }) || '',
            display_name_en: getSpecialLang({ data: item.disease_id_view.disease_name, field: 'name', lang: 'EN', isDegraded: true }) || '',
          },
        ],
      }
    })
  }
  throw new Error('entity2AlertEntity: alertEntityType is not match')
}
