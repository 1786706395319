import '@patsnap-ui/icon/assets/solid/DropdownClose.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import '@patsnap-ui/icon/assets/solid/TranslateChinese.svg'
import '@patsnap-ui/icon/assets/solid/TranslateEnglish.svg'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { IS_CN_REGION } from '@pharmsnap/shared/config'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { ILang, ITranslateLang } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, ref } from '@vue/composition-api'
import { PopoverPlacement } from 'element-ui/types/popover'
import VClickOutside from 'v-click-outside'
import { GIcon } from '../..'
import $classes from './GTranslateSwitch.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const GTranslateSwitch = defineComponent({
  name: 'GTranslateSwitch',
  props: {
    placement: {
      type: String as PropType<PopoverPlacement>,
      default: 'bottom-end',
    },
    customRefStyle: {
      type: Object,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const {
      actions,
      getters: { translation },
    } = useAuthStore()

    const { locale } = useLocale()

    const ins = getCurrentInstance()

    const setTranslateStatus = (lang: ITranslateLang = 'original') => {
      actions.changeTranslation(lang)
      emit('change')
      toggleOptions()
    }

    const options: { name: string; value: ILang }[] = IS_CN_REGION
      ? [
          {
            name: '简体中文',
            value: 'CN',
          },
        ]
      : [
          {
            name: 'English',
            value: 'EN',
          },
        ]

    const displayName = computed(() => {
      const target = options.find((o) => o.value === translation.value)
      if (target) {
        return target.name
      }
      return ins?.proxy.$t('GTranslateSwitch.translate')
    })

    function handelClickOutside() {
      showOptions.value = false
    }

    function toggleOptions() {
      showOptions.value = !showOptions.value
    }

    const svgName = computed(() => {
      return showOptions.value ? 'SolidDropdownClose' : 'SolidDropdownOpen'
    })

    const refClass = computed(() => {
      return showOptions.value ? $classes.refActive : $classes.refInactive
    })

    return {
      setTranslateStatus,
      options,
      displayName,
      toggleOptions,
      svgName,
      showOptions,
      handelClickOutside,
      locale,
      refClass,
    }
  },
  methods: {
    renderDisplay() {
      return (
        <div slot="reference" class="inline-flex items-center">
          <div class={this.refClass} onClick={this.toggleOptions} style={this.customRefStyle}>
            <GIcon
              class="text-text-t2 align-middle"
              svgName={this.locale === 'cn' ? 'SolidTranslateEnglish' : 'SolidTranslateChinese'}
              size={24}
            ></GIcon>
            <span class="text-text-t1 align-middle px-2">{this.displayName}</span>
            <GIcon class="text-text-t2 align-middle" svgName={this.svgName} size={24}></GIcon>
          </div>
        </div>
      )
    },
    renderOptions() {
      return (
        <div class={$classes.options}>
          <div class={$classes.original} onClick={() => this.setTranslateStatus()}>
            <div class="hover:bg-gray-30 px-2 rounded">{this.$t('GTranslateSwitch.original')}</div>
          </div>
          <div>
            <div class="text-xs pl-2 py-2">{this.$t('GTranslateSwitch.fullTranslation')}</div>
            <div>
              {this.options.map((option) => {
                return (
                  <div class={$classes.option} onClick={() => this.setTranslateStatus(option.value)}>
                    <div class="hover:bg-gray-30 px-2 rounded">{option.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <ElPopover
        value={this.showOptions}
        visibleArrow={false}
        trigger="manual"
        popperClass={$classes.popper}
        placement={this.placement}
        data-testid="g-translate-switch"
        {...{
          directives: [
            {
              name: 'click-outside',
              value: {
                handler: this.handelClickOutside,
              },
            },
          ],
        }}
      >
        {this.renderOptions()}
        {this.renderDisplay()}
      </ElPopover>
    )
  },
})
