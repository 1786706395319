import { E_NODATA_SIZE } from '@pharmsnap/shared/types'
import { defineComponent } from '@vue/composition-api'
import { GEmpty } from '../GEmpty/GEmpty'
import $classes from './GChartCard.module.scss'

export const GChartCard = defineComponent({
  name: 'GChartCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    emptyCls: {
      type: String,
      default: '',
    },
  },
  methods: {
    renderEmpty() {
      return <div class={[$classes.empty, this.emptyCls]}>{this.$slots.empty || <GEmpty isChart={true} size={E_NODATA_SIZE.SMALL}></GEmpty>}</div>
    },
  },
  render() {
    return (
      <div class="border border-gray-55 rounded flex flex-col" v-ls-loading={this.isLoading}>
        <div class="border-b border-gray-40 p-3 h-18 flex-shrink-0">{this.$slots.header}</div>
        <div class="p-2 flex-1 leading-none">{this.isEmpty ? this.renderEmpty() : this.$slots.default}</div>
      </div>
    )
  },
})
