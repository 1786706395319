<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      class="el-loading-mask"
      :style="{ backgroundColor: background || '' }"
      :class="[customClass, { 'is-fullscreen': fullscreen }]"
    >
      <div class="el-loading-spinner">
        <pt-icon icon="SolidLoadingBars" :style="{ fill: 'rgb(80, 95, 121)', fontSize: '50px' }"></pt-icon>
        <p v-if="text" class="el-loading-text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import { Icon as PtIcon } from 'patsnap-biz'
export default {
  components: {
    PtIcon,
  },
  data() {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
    }
  },

  methods: {
    handleAfterLeave() {
      this.$emit('after-leave')
    },
    setText(text) {
      this.text = text
    },
  },
}
</script>
