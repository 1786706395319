import type { IAggregationData, ITwoDimensionAggValue, ITwoDimensionTargetAggData } from '@patsnap/synapse_common_interface'
import {
  IAggregationParamsNew,
  IBullsEyeChartAggOtherInfo,
  ITopAggregationByPhaseBullsEyeChartAggParams,
  ITopAggregationDrugDetail,
  ITopAggregationParams,
  ITopAggregationParamsNew,
  ITopBullsEyeChartAggParams,
} from '@pharmsnap/shared/types'
import { IBaseEntityAnalysisOverview } from '@pharmsnap/shared/types/base'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformDrugAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/drug'
import { transformDrugDealAggregationSingleResult } from '../../utils/business/aggregationTransform/drug-deal'
import { transformTransMedicineAggregationSingleResult } from '../../utils/business/aggregationTransform/translational-medicine'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class AnalysisApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getOverview(params: {
    disease_id?: string
    drug_id?: string
    organization_id?: string
    target_ids?: string[]
    drug_type_id?: string
  }): Promise<IHttpWrap<IBaseEntityAnalysisOverview>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/overview'),
      cache: true,
      data: params,
    })
  }

  async getTrend(params: {
    disease_id?: string
    drug_id?: string
    organization_id?: string
    target_id?: string
    time_range_type: '5y' | '10y'
  }): Promise<IHttpWrap<any>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/trend'),
      cache: true,
      data: params,
    })
  }

  async getTopAggregation(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /**
   * ignore_query_condition传true,就不适配机构和适应症的二元组
   *
   * 走索引
   */
  async getTopDiseaseAggregationBySearch(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_disease_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDiseaseBullsEyeChartAggByPhase(
    params: ITopAggregationByPhaseBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_disease_by_phase_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopTargetAggregationByPhase(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_target_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopTargetBullsEyeChartAggByPhase(
    params: ITopAggregationByPhaseBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_target_by_phase_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDiseaseAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_disease/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  /**
   * ignore_query_condition传true,就不适配机构和适应症的二元组
   *
   * 走数仓
   */
  async getTopDiseaseAggregation(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_disease'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /** 参数简化版，返回结果和getTopDiseaseAggregation一样，简化入参params */
  async getTopDiseaseAggregationNew(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_disease'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDiseaseBullsEyeChartAgg(
    params: ITopBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/disease_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopTargetAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_target/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getTopTargetAggregation(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    const rt: IHttpWrap<ITwoDimensionTargetAggData> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_target'),
      data: params,
      method: 'POST',
      cache: true,
    })

    if (rt.success) {
      return transformAggregationResponse(rt as IHttpWrap<IAggregationData>, transformDrugAggregationSingleResult)
    }

    return rt
  }

  /** 参数简化版，返回结果和getTopTargetAggregation一样，简化入参params */
  async getTopTargetAggregationParamsNew(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    const rt: IHttpWrap<ITwoDimensionTargetAggData> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_target'),
      data: params,
      method: 'POST',
      cache: true,
    })

    if (rt.success) {
      return transformAggregationResponse(rt as IHttpWrap<IAggregationData>, transformDrugAggregationSingleResult)
    }

    return rt
  }

  /**
   * 获取药品交易近年趋势图表数据
   */
  async getDrugDealAnalysisOverview(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    const rt: IHttpWrap<IAggregationData> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug_deal/overview'),
      data: params,
      method: 'POST',
      cache: true,
    })

    if (rt.success) {
      return transformAggregationResponse(rt, transformDrugDealAggregationSingleResult)
    }

    return rt
  }
  /**
   * 获取近年转化医学总览
   */
  async getTransMedicineAnalysis(params: IAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    const rt: IHttpWrap<IAggregationData> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/translational_medicine'),
      data: params,
      method: 'POST',
      cache: true,
    })
    if (rt.success) {
      return transformAggregationResponse(rt, transformTransMedicineAggregationSingleResult)
    }
    return rt
  }

  async getTopTargetBullsEyeChartAgg(
    params: ITopBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/target_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDrugTypeAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_drug_type/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getTopDrugTypeAggregation(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_drug_type'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /** 参数简化版，返回结果和getTopDrugTypeAggregation一样，简化入参params */
  async getTopDrugTypeAggregationNew(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_drug_type'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDrugTypeBullsEyeChartAgg(
    params: ITopBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug_type_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /**
   * ignore_query_condition传true,就不适配机构和适应症的二元组
   */
  async getTopOrgAggregationByPhase(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_org_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopOrgBullsEyeChartAggByPhase(
    params: ITopAggregationByPhaseBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_org_by_phase_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDrugTypeAggregationByPhase(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_drug_type_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopDrugTypeBullsEyeChartAggByPhase(
    params: ITopAggregationByPhaseBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_drug_type_by_phase_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopOrgAggregationNew(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_org'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /** 参数简化版，返回结果和getTopOrgAggregationNew一样，简化入参params */
  async getTopOrgAggregationParamsSimplified(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_org'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopOrgBullsEyeChartAgg(
    params: ITopBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/org_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopOrgAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_org/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getTopRegionAggregationByPhase(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug/top_region_by_phase'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopRegionAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_region/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getTopRegionAggregation(params: ITopAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_region'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  /** 参数简化版，返回结果和getTopRegionAggregation一样，简化入参params */
  async getTopRegionAggregationNew(params: ITopAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_region'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTopRegionBullsEyeChartAgg(
    params: ITopBullsEyeChartAggParams
  ): Promise<IHttpWrap<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/region_bulls_eye_plot'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getBarTopDrugTypeAggDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/drug_type/drug_detail'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }
}
