import { ref } from '@vue/composition-api'

export function useLazyComponent() {
  const isScrollInView = ref(false)

  const handleScrollInView = () => (isScrollInView.value = true)

  return {
    isScrollInView,
    handleScrollInView,
  }
}
