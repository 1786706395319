import { IBaseCountry } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { GCountryFlag } from '../..'

export const BApprovalCountryItem = defineComponent({
  name: 'BApprovalCountryItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseCountry>,
    },
  },
  render() {
    const countryName = this.data.alpha_2_code || this.data.display_name
    return (
      <GCountryFlag
        class="mr-1 text-text-default"
        name={countryName}
        nameCn={this.data.display_name_cn}
        showName={true}
        useFullName={true}
        showUnknownCountryFlag
      ></GCountryFlag>
    )
  },
})
