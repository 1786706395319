import type { IAutoPreview, IAutoPreviewConfig } from '../types'

export const autoPreviewConfigMap: Record<IAutoPreview, IAutoPreviewConfig> = {
  Drug: {
    serviceKey: 'drug',
    dataType: 'drug',
  },
  Clinical: {
    serviceKey: 'trial',
    dataType: 'clinical_trial',
  },
  Literature: {
    serviceKey: 'literature',
    dataType: 'paper',
  },
  Patent: {
    serviceKey: 'patent',
    dataType: 'patent',
  },
  ClinicalResult: {
    serviceKey: 'clinicalResult',
    dataType: 'clinical_trial_result',
  },
  DrugDeal: {
    serviceKey: 'drugDeal',
    dataType: 'drug_deal',
  },
  TranslationalMedicine: {
    serviceKey: 'translationalMedicine',
    dataType: 'translational_medicine',
  },
}
