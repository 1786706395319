import { LIST_PAGE_DEFAULT_PAGE_SIZE } from '@pharmsnap/shared/constants'
import { inject, InjectionKey, provide, reactive, ref, shallowRef } from '@vue/composition-api'
import { ICommonListActions, ICommonListState } from './types'

export const commonListStateProvideKey: InjectionKey<ICommonListState> = Symbol('filterCommonListStateProvideKey')
export const commonListActionProvideKey: InjectionKey<ICommonListActions> = Symbol('commonListActionProvideKey')

/**
 * common list 的store
 * T list item的数据类型
 * @returns
 */
export function userProvideCommonListService<T = any>(initPageLimit = LIST_PAGE_DEFAULT_PAGE_SIZE) {
  const { value: page } = ref(1)
  const { value: limit } = ref(initPageLimit)
  const state: ICommonListState = reactive({
    page,
    limit,
    loading: true,
    listData: shallowRef<T[]>([]),
    checkedMap: {},
    checkedMaxCount: 0,
    columnFields: [],
    cardViewType: 'normal',
    selectAll: false,
  })
  const actions: ICommonListActions = {
    async reSearch() {
      console.log('reSearch')
    },
    clearListData() {
      state.listData = []
    },
    setListData(data: T[]) {
      state.listData = data
    },
    setListDataInFrame(data) {
      const cancelId = requestAnimationFrame(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.listData = data
        cancelAnimationFrame(cancelId)
      })
      // const chunks = chunk(data, 300)
      // const maxChunkIndex = chunks.length - 1
      // let cancelId: number | null = null
      // const updateListData = (chunkIndex: number) => {
      //   if (chunkIndex > maxChunkIndex) {
      //     if (cancelId !== null) {
      //       cancelAnimationFrame(cancelId)
      //     }
      //     return
      //   }
      //   cancelId = requestAnimationFrame(() => {
      //     const originData = state.listData.slice(0)
      //     originData.push(...chunks[chunkIndex])
      //     state.listData = originData
      //     updateListData(chunkIndex + 1)
      //   })
      // }
      // updateListData(0)
    },
    setSelectAll(selectAll: boolean) {
      state.selectAll = selectAll
    },
    setCheckedMap(data: Record<string, T>) {
      state.checkedMap = {
        ...state.checkedMap,
        ...data,
      }
    },
    cleanCheckedMap() {
      state.checkedMap = {}
    },
    setLoading(data: boolean) {
      state.loading = data
    },
    setPage(data: number) {
      state.page = data
    },
    setLimit(data: number) {
      state.limit = data
    },
    setColumnFields(data) {
      state.columnFields = data
    },
    setCardViewType(data) {
      state.cardViewType = data
    },
    setCheckedMaxCount(count) {
      state.checkedMaxCount = count
    },
  }
  provide(commonListStateProvideKey, state)
  provide(commonListActionProvideKey, actions)
  return { state, actions }
}
export function userInjectCommonListService() {
  const state = inject(commonListStateProvideKey)
  const actions = inject(commonListActionProvideKey)
  return { state, actions }
}
