import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.LITERATURE,
}

export default [
  {
    path: `literature-finder`,
    name: E_ROUTER_NAME.LITERATURE_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/literature/LiteratureFinder'),
    meta: {
      ...meta,
      title: createWebTitle('文献检索', 'Literature Finder'),
      hideQuickSearch: true,
    },
  },
  {
    path: 'literature-list',
    name: E_ROUTER_NAME.LITERATURE_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/literature/LiteratureList'),
    meta: {
      ...meta,
      title: createWebTitle('文献检索结果', 'Literature Search Results'),
      notWide: true,
    },
  },
  {
    path: `literature-detail/:${E_ROUTER_PARAMS.LITERATURE_ID}`,
    name: E_ROUTER_NAME.LITERATURE_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/literature/LiteratureDetail'),
    meta: {
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.LITERATURE_DETAIL,
    },
  },
] as RouteConfig[]
