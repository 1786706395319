import { TreeChart } from '@patsnap/synapse_tree_chart'
import { generateChartImage } from '@pharmsnap/pharmsnap-web/utils/generateChartImage'
import '@pharmsnap/shared/src/assets/icon-svg/collapsed.svg'
import '@pharmsnap/shared/src/assets/icon-svg/expanded.svg'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { showSingleToast, sleep } from '@pharmsnap/shared/src/utils'
import { getUaEngine } from '@pharmsnap/shared/src/utils/ua'
import { getCurrentInstance, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import html2canvas from 'html2canvas'
import { adapterCanvasSize } from '../utils/canvasAdapter'
import { WEB_IMAGE, WEB_IMAGE_CORS, WEB_IMAGE_CORS_TRUE } from '../utils/webImage'

export function useDownloadCorpTree(treeChart: MaybeRef<TreeChart<IOrgCorpTree>>, node: MaybeRef<HTMLElement>) {
  const { isCN } = useLocale()
  const {
    state: { userInfo },
  } = useAuthStore()
  const ins = getCurrentInstance()

  const downloading = ref(false)
  const handleDownload = async (name: string, id?: string) => {
    const treeNode = unref(node)
    if (!treeNode || unref(downloading)) return

    const toast = showSingleToast({
      message: ins?.proxy.$t('corpTree.exportInfo') as string,
      type: 'info',
      duration: 0,
    })
    const chart = unref(treeChart)
    downloading.value = true
    await sleep(100)

    const browserEngineName = getUaEngine().name?.toLowerCase() || ''
    if (!['blink', 'gecko'].includes(browserEngineName)) {
      await sleep(1000)
      downloading.value = false
      toast.close()
      showSingleToast({
        message: ins?.proxy.$t('corpTree.exportNotSupport') as string,
        type: 'error',
      })
      return
    }
    const cloneNode = treeNode.cloneNode(true) as HTMLElement

    const px = 50
    const py = 50
    const [width, height, ratio] = adapterCanvasSize(chart._width + 2 * px, chart._height + 2 * py)
    const chartWidth = width - 2 * px * ratio
    const chartHeight = height - 2 * py * ratio
    const svg = cloneNode.querySelector('svg')
    if (svg) {
      svg.style.width = `${chartWidth}px`
      svg.style.maxWidth = ``
      svg.style.height = `${chartHeight}px`
      svg.setAttribute('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
      cloneNode
        .querySelector(`.${TreeChart.GROUP_CLASS}`)
        ?.setAttribute('transform', `translate(0, ${-1 * chart._leftTopPoint.y * ratio}) scale(${ratio})`)
      cloneNode.querySelectorAll(WEB_IMAGE)?.forEach((img) => {
        img.setAttribute(WEB_IMAGE_CORS, WEB_IMAGE_CORS_TRUE)
      })
    }

    cloneNode.style.width = `${width}px`
    cloneNode.style.padding = `${py}px ${px}px`
    cloneNode.style.margin = ``
    cloneNode.style.height = `${height}px`

    treeNode.parentNode?.append(cloneNode)

    const reset = () => {
      treeNode.parentNode?.removeChild(cloneNode)
      downloading.value = false
      toast.close()
    }
    try {
      const canvas = await html2canvas(cloneNode, {
        useCORS: true,
        logging: false,
        width: width,
        height: height,
        onclone(doc, cloneNode) {
          if (!doc || !cloneNode) return
          const svgSprite = doc.getElementById('__SVG_SPRITE_NODE__')
          svgSprite && cloneNode.appendChild(svgSprite)
          cloneNode.style.position = 'fixed'
          cloneNode.style.left = '0'
          cloneNode.style.top = '0'
          return cloneNode
        },
        foreignObjectRendering: true,
      })

      await generateChartImage({
        chartUrl: canvas.toDataURL('image/png'),
        width: canvas.width,
        height: canvas.height,
        name: `${name}.png`,
        isCN: isCN.value,
        userInfo: { userId: userInfo.value?.user_id || '' },
      })
    } finally {
      reset()
    }
  }

  return {
    handleDownload,
    downloading,
  }
}
