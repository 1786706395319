import '@pharmsnap/pharmsnap-web/assets/icon-svg/children.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/earth.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/litigation.svg'
import { GCheckList, GCountryFlag, GEmpty, GMultiDropdown, GTooltip } from '@pharmsnap/shared/src/components'
import { useLocale, useMergedState } from '@pharmsnap/shared/src/composition'
import { ElOption, ElSelect, ElSwitch } from '@pharmsnap/shared/src/element-ui'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { E_NODATA_SIZE, IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { getOrganizationName } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType, ref, toRef } from '@vue/composition-api'
import { Icon } from 'patsnap-biz'
import '../../../assets/icon-svg/searchRight.svg'
import styles from './BCorpTree.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export interface ICorpTreeFilterParams {
  checkedCountries: string[]
  showCorpWithDrugs: boolean
}

export function getDefaultCorpTreeFilterParams(): ICorpTreeFilterParams {
  return {
    checkedCountries: [],
    showCorpWithDrugs: true,
  }
}
function filterCorpTree(tree: IOrgCorpTree, filter: (node: IOrgCorpTree) => boolean) {
  const treeMap = new WeakMap<IOrgCorpTree, IOrgCorpTree[]>()
  const set = new Set<IOrgCorpTree>()
  function postTraverseFilter(tree: IOrgCorpTree, filter: (node: IOrgCorpTree) => boolean) {
    if (tree.subsidiary) {
      const children = tree.subsidiary.filter((sub) => {
        const res = postTraverseFilter(sub, (node) => {
          if (treeMap.get(sub)?.length && !set.has(node)) {
            set.add(node)
            return true
          }
          const f = filter(node)
          if (f) {
            set.add(node)
          }
          return f
        })
        return res
      })
      treeMap.set(tree, children)
    }
    return filter(tree)
  }
  postTraverseFilter(tree, filter)
  set.add(tree)
  return Array.from(set.values())
}

const defaultMap = new Map([
  ['US', 1],
  ['EU', 2],
  ['JP', 3],
  ['CN', 4],
])

export const BCorpTreeFilter = defineComponent({
  name: 'BCorpTreeFilter',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  model: {
    prop: 'highlightOrg',
    event: 'update:highlightOrg',
  },
  props: {
    id: {
      type: String,
    },
    params: {
      type: Object as PropType<ICorpTreeFilterParams>,
    },
    highlightOrg: {
      type: String,
    },
    corpTree: {
      type: Object as PropType<IOrgCorpTree>,
    },
  },
  emits: ['change', 'update:highlightOrg', 'highlightChange'],
  setup(props, { emit }) {
    const { ts, locale } = useLocale()
    const getLocaleOrgName = (i: IOrgCorpTree) => {
      return getOrganizationName(i, locale.value)
    }
    const [mergedParams, setParams] = useMergedState(getDefaultCorpTreeFilterParams, {
      value: toRef(props, 'params'),
      onChange: (val, preVal) => {
        emit('change', val, preVal)
      },
    })
    const [mergedHighlightOrg, setHighlightOrg] = useMergedState('', {
      value: toRef(props, 'highlightOrg'),
      onChange: (val) => {
        emit('update:highlightOrg', val)
        emit('highlightChange', val)
      },
    })

    const flattenCorpTreeWithSwitcher = computed(() => {
      const nodes = props.corpTree
        ? filterCorpTree(props.corpTree, (node) => {
            if (node.id === props.id) return true
            if (mergedParams.value.showCorpWithDrugs) {
              return !!node.drug_count_roll_up
            }
            return true
          })
        : []
      return nodes.length
        ? nodes.sort((a, b) => getLocaleOrgName(a)?.localeCompare(getLocaleOrgName(b) || '') || 0)
        : ([props.corpTree].filter(Boolean) as IOrgCorpTree[])
    })
    const mergedTempParams = ref(getDefaultCorpTreeFilterParams())

    /* -------------------------- show corp with drugs -------------------------- */
    const radioFilter = computed(() => {
      return (
        <div class="inline-flex items-center gap-2">
          <ElSwitch
            onChange={(val: boolean) => {
              setParams({
                ...mergedParams.value,
                checkedCountries: [],
                showCorpWithDrugs: val,
              })
            }}
            value={mergedParams.value.showCorpWithDrugs}
          ></ElSwitch>
          <span class="text-sm">{ts('corpTree.showCorp')}</span>
          <GTooltip theme="light" mode="icon">
            <template slot="content">
              <div class="break-words max-w-xs text-xs leading-4" domPropsInnerHTML={ts('corpTree.showCorpTips')}></div>
            </template>
          </GTooltip>
        </div>
      )
    })

    /* ----------------------------- country/location related ---------------------------- */
    const countryMap = computed(() => {
      const cMap = new Map<string, number>()
      flattenCorpTreeWithSwitcher.value.forEach((node) => {
        if (node.country_short_name_en) {
          const count = cMap.get(node.country_short_name_en) || 0
          cMap.set(node.country_short_name_en, count + 1)
        }
      })
      return cMap
    })
    const countries = computed(() => {
      return Array.from(countryMap.value.entries())
        .sort((a, b) => {
          if (defaultMap.has(a[0]) && defaultMap.has(b[0])) {
            return defaultMap.get(a[0])! - defaultMap.get(b[0])!
          }
          if (defaultMap.has(a[0])) {
            return -1
          }
          if (defaultMap.has(b[0])) {
            return 1
          }
          return b[1] - a[1]
        })
        .map((i) => i[0])
    })

    const handleOpenCountryPanel = () => {
      mergedTempParams.value.checkedCountries = [...mergedParams.value.checkedCountries]
    }
    const handleFilterByCountry = () => {
      setParams({ ...mergedParams.value, checkedCountries: [...mergedTempParams.value.checkedCountries] })

      trackingEvent('FILTER_CORP_TREE_BY_COUNTRY', {
        payload: {
          entity_id: props.id!,
          show_corp_with_drugs: mergedParams.value.showCorpWithDrugs,
          filter_country: mergedTempParams.value.checkedCountries,
        },
      })
    }
    const handleClearSelectedCountry = () => {
      mergedTempParams.value.checkedCountries = []
      setParams({ ...mergedParams.value, checkedCountries: [] })
    }
    const countryFilter = computed(() => {
      return (
        <GMultiDropdown
          icon="Earth"
          label={ts('corpTree.Country/Location')}
          title={ts('corpTree.research.selectCountry/Location')}
          width={240}
          count={mergedParams.value.checkedCountries.length}
          popoverClass={styles.countryPopover}
          onOpen={handleOpenCountryPanel}
          onConfirm={handleFilterByCountry}
          onClear={handleClearSelectedCountry}
        >
          <GCheckList
            list={countries.value}
            v-show={countries.value.length > 0}
            v-model={mergedTempParams.value.checkedCountries}
            scopedSlots={{
              default: (props: { item: string }) => {
                const countryName = props.item
                return (
                  <span class="inline-flex">
                    <GCountryFlag name={countryName} showName useFullName showUnknownCountryFlag></GCountryFlag>
                    {`(${countryMap.value.get(countryName) || 0})`}
                  </span>
                )
              },
            }}
          ></GCheckList>
          <GEmpty size={E_NODATA_SIZE.SMALL} v-show={countries.value.length === 0}></GEmpty>
        </GMultiDropdown>
      )
    })
    /* -------------------------- organization related -------------------------- */
    const filterText = ref('')
    const onSelectOrg = (val: string) => {
      setHighlightOrg(val)

      if (val)
        trackingEvent('SEARCH_IN_CORP_TREE', {
          payload: {
            entity_id: props.id!,
            show_corp_with_drugs: mergedParams.value.showCorpWithDrugs,
            search_text: filterText.value,
            search_entity_id: val,
          },
        })
      filterText.value = ''
      requestAnimationFrame(() => {
        setHighlightOrg('')
      })
    }
    const corpTreeOptions = computed(() => {
      const countrySet = new Set(mergedParams.value.checkedCountries)

      const nodes = props.corpTree
        ? filterCorpTree(props.corpTree, (node) => {
            if (node.id === props.id) return true
            let filter = true
            if (mergedParams.value.showCorpWithDrugs) {
              filter = !!node?.drug_count_roll_up
            }
            if (countrySet.size) {
              const country = node?.country_short_name_en
              filter = filter && countrySet.has(country || '')
            }
            return filter
          })
        : []
      const filterNodes = nodes.length
        ? nodes.sort((a, b) => getLocaleOrgName(a)?.localeCompare(getLocaleOrgName(b) || '') || 0)
        : ([props.corpTree].filter(Boolean) as IOrgCorpTree[])

      return filterNodes.filter((i) =>
        filterText.value
          ? i.entity_name_cn?.toLocaleLowerCase().includes(filterText.value) || i.entity_name_en?.toLocaleLowerCase().includes(filterText.value)
          : true
      )
    })
    const handleLocaleOrg = (t: string) => {
      filterText.value = t?.toLocaleLowerCase() || ''
    }
    const organizationList = computed(() => {
      return (
        <ElSelect
          value={mergedHighlightOrg.value}
          filterable
          remote
          size="small"
          style={'width: 400px'}
          remoteMethod={handleLocaleOrg}
          onChange={onSelectOrg}
          placeholder={ts('corpTree.selectOrganization')}
        >
          {/* <template slot="prefix">
            <Icon icon="SearchRight"></Icon>
          </template> */}
          {corpTreeOptions.value.map((i) => (
            <ElOption style="max-width:500px" key={i.id} label={getOrganizationName(i, locale.value)} value={i.id}></ElOption>
          ))}
        </ElSelect>
      )
    })
    return () => {
      return (
        <div class={'py-3 flex justify-between leading-none'}>
          <div class="flex gap-4">
            {radioFilter.value}
            {countryFilter.value}
          </div>
          <div class="relative">
            {organizationList.value}
            <Icon icon="SearchRight" class="absolute right-1 top-1 w-[24px] h-[24px] text-text-t3" />
          </div>
        </div>
      )
    }
  },
})
