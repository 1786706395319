import { sharedCtx } from '@pharmsnap/shared/context'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { IImagePanelData } from '@pharmsnap/shared/types'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { ImagePanel } from 'patsnap-biz'
import '../../../assets/icon-svg/noImage.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GLoading } from '../../ui/GLoading/GLoading'
import $classes from './BImagePanel.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BImagePanel = defineComponent({
  name: 'BImagePanel',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    patentId: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
      required: true,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    showImagePanel: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'right',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showPanel = ref(false)
    const imageData = reactive<IImagePanelData>({
      list: [],
      index: 0,
      total: 0,
      type: 'image',
    })

    async function showPatentImages() {
      if (!props.showImagePanel) {
        return
      }
      const res = await sharedCtx.service.patent.getPatentImages(props.patentId)
      if (res.success && res.data.images?.length) {
        imageData.list = res.data.images.map((o, index) => {
          return {
            src: o.storage_path,
            item: {
              id: index,
              path: o.fulltext_image240_url,
            },
          }
        })
        imageData.total = res.data.total
        imageData.index = 0
        showPanel.value = true
      }
    }
    return {
      showPatentImages,
      showPanel,
      imageData,
    }
  },
  methods: {
    renderPreview() {
      return (
        <div class={$classes.previewPicContainer}>
          {this.previewUrl ? <div class={$classes.previewPic} style={{ backgroundImage: `url(${this.previewUrl})` }}></div> : this.renderEmpty()}
        </div>
      )
    },
    renderEmpty() {
      return (
        <div class="flex flex-col justify-center items-center">
          <GIcon svgName="NoImage" size={36}></GIcon>
          <div class="text-sm text-gray-90">{this.$t('BImagePanel.noImage')}</div>
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.picContainer}>
        <vue-lazy-component>
          <ElPopover appendToBody={this.appendToBody} placement={this.placement} visibleArrow={false} disabled={!this.showPreview} trigger="hover">
            {this.renderPreview()}
            <div slot="reference" class={[$classes.picSlot, this.showImagePanel && $classes.picSlotClickable]}>
              {this.thumbnailUrl ? (
                <div class={$classes.pic} style={{ backgroundImage: `url(${this.thumbnailUrl})` }} onClick={this.showPatentImages}></div>
              ) : (
                this.renderEmpty()
              )}
            </div>
          </ElPopover>

          <template slot="skeleton">
            <div class={$classes.picLoading}>
              <GLoading size={24}></GLoading>
            </div>
          </template>
        </vue-lazy-component>

        {this.showPanel && (
          <ImagePanel imageData={this.imageData} imagePanelShow={this.showPanel} onImage-panel-close={() => (this.showPanel = false)}></ImagePanel>
        )}
      </div>
    )
  },
})
