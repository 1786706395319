import '@patsnap-ui/icon/assets/solid/ChartChunk.svg'
import { getSingle } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { IHttpWrap } from '@pharmsnap/shared/src/service/http/types'
import {
  ContextPageType,
  CopilotCommand,
  CopilotPageContext,
  CopilotTaskStatus,
  ICopilotDetailPage,
  ICopilotPage,
  ICopilotReportPage,
  ICopilotSearchPage,
  IStreamResponse,
} from '@pharmsnap/shared/src/types'
import { CHAT_DIALOG_ID } from '@pharmsnap/shared/src/utils/business/ls360'
import {
  ComponentInternalInstance,
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  reactive,
  Ref,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import { sharedCtx } from 'pharmsnapMF_shared/context'
import { setLocalStorage, showSingleToast } from 'pharmsnapMF_shared/utils'
import Raven from 'raven-js'
import { CombinedVueInstance } from 'vue/types/vue'
import { CopilotDownloadReportBtn } from '../components/CopilotGpt/CopilotDownloadReportBtn'
import { CopilotProgressList } from '../components/CopilotGpt/CopilotProgressList'
import { ChatMessage, ChatType, MessageType } from '../components/CopilotGpt/type'
import { getService } from '../service/service'
import { E_ROUTER_NAME } from '../types/enum'
import { formatCopilotTimeStamp } from '../utils/copilot'
import { useMouseMove } from './useMouseMove'
import { useScroll } from './useScroll'

export const COPILOT_DIALOG_SHOW_KEY = 'COPILOT_DIALOG_SHOW'
export const useChatFactory = () => {
  const messageMap = reactive<Record<ChatType, ChatMessage[]>>({
    assistant: [],
    chat: [],
  })
  const showCopilotDialog = ref(false)
  const vifShowCopilotDialog = ref(false)
  const showCopilotEntry = ref(true)
  const showEditDialog = ref(false)
  const scrollContainerRef = ref<HTMLElement | null>(null)
  const editContent = ref('')
  const pageContext = ref<CopilotPageContext | null>(null)
  const inviteDialogVisible = ref(false)
  const reportRouteList = ref<ICopilotReportPage[]>([
    E_ROUTER_NAME.DRUG_LIST,
    E_ROUTER_NAME.DISEASE,
    E_ROUTER_NAME.TARGET,
    E_ROUTER_NAME.DRUG_TYPE,
    E_ROUTER_NAME.PATENT_LIST,
    E_ROUTER_NAME.DRUG_DEAL_LIST,
  ])
  const invitedPeopleCount = ref(0)
  const capacityDetail = reactive({
    used: 0,
    total: 0,
    newly_invited_user_count: 0,
  })

  const { isCN } = useLocale()
  let capacityTimer: NodeJS.Timeout | null
  const CAPACITY_TIMEOUT = 10 * 1000

  const isPrechecking = ref(false)
  const isFunctionDisabled = computed(() => {
    const latestMessage = actions.getLatestMessage('assistant')
    return (
      (latestMessage && latestMessage.type === MessageType.assistant && (latestMessage.loading || latestMessage.isTyping)) ||
      isPrechecking.value ||
      pageContext.value?.isLoading
    )
  })
  const { hasNotMovedForAWhile } = useMouseMove()
  const getCopilotCapacity = async () => {
    if (document.hidden || !showCopilotDialog.value || hasNotMovedForAWhile.value) {
      await new Promise((resolve) => (capacityTimer = setTimeout(resolve, CAPACITY_TIMEOUT)))
      getCopilotCapacity()
      return
    }
    try {
      const res = await sharedCtx.service.copilot.getCopilotCapacity()
      if (res.success) {
        capacityDetail.used = res.data.used
        capacityDetail.total = res.data.total
        capacityDetail.newly_invited_user_count = res.data.newly_invited_user_count
      }
      await new Promise((resolve) => (capacityTimer = setTimeout(resolve, CAPACITY_TIMEOUT)))
      getCopilotCapacity()
    } catch (e) {
      await new Promise((resolve) => (capacityTimer = setTimeout(resolve, CAPACITY_TIMEOUT)))
      getCopilotCapacity()
    }
  }

  // 只监听一次,第一次出现就开启轮询
  const unwatch = watch(showCopilotDialog, (newVal) => {
    if (newVal) {
      getCopilotCapacity()
      if (unwatch) unwatch()
    }
  })
  const handleAlertFunc = ref<(() => void) | null>(null)
  const copilotDialogRightDistanceToViewport = ref(0)
  const isLoadingOrTyping = computed(() => {
    return messageMap['assistant'].some((message) => message.type === MessageType.assistant && (message.loading || message.isTyping))
  })

  const ins = getCurrentInstance()
  // 可能没有ins会报错
  ins &&
    onBeforeUnmount(() => {
      if (capacityTimer) clearTimeout(capacityTimer)
    })

  const pageAlert = ref<boolean | null>(null)
  const isHistoryLoading = ref(false)
  const state = reactive({
    messageMap,
    showCopilotDialog,
    vifShowCopilotDialog,
    showCopilotEntry,
    scrollContainerRef,
    showEditDialog,
    editContent,
    pageContext,
    reportRouteList,
    inviteDialogVisible,
    invitedPeopleCount,
    capacityDetail,
    copilotDialogRightDistanceToViewport,
    handleAlertFunc,
    isLoadingOrTyping,
    pageAlert,
    isHistoryLoading,
    isFunctionDisabled,
    isPrechecking,
  })
  const { scrollToBottom, scrollToBottomIfAtBottom } = useScroll(scrollContainerRef)
  let controller = new AbortController()
  let startTimestamp = 0
  let stopTimestamp = 0
  // 超时计时器
  let timeoutTimer: NodeJS.Timeout | null = null
  let pollTimer: NodeJS.Timeout | null = null
  const actions = {
    setIsPrechecking(isPrechecking: boolean) {
      state.isPrechecking = isPrechecking
    },
    setIsHistoryLoading(isLoading: boolean) {
      state.isHistoryLoading = isLoading
    },
    updateCopilotRIghtDistance(distance?: number) {
      if (distance === 0) return (copilotDialogRightDistanceToViewport.value = distance)
      const copilotElement = document.querySelector(CHAT_DIALOG_ID)
      copilotDialogRightDistanceToViewport.value = window.innerWidth - (copilotElement?.getBoundingClientRect()?.left || 0)
    },
    addMessage: (tab: ChatType, message: ChatMessage) => {
      messageMap[tab].push(message)
    },
    scrollToBottom,
    scrollToBottomIfAtBottom,
    setShowCopilot: (isShow: boolean) => {
      vifShowCopilotDialog.value = true
      showCopilotDialog.value = isShow
      showCopilotEntry.value = !isShow
      setLocalStorage(COPILOT_DIALOG_SHOW_KEY, isShow.toString())
    },

    updateLatestMessage: (tab: ChatType, message: ChatMessage) => {
      const length = messageMap[tab].length
      const messages = messageMap[tab].slice() // 创建一个新的数组副本
      messages[length - 1] = message
      messageMap[tab] = messages // 更新 messageMap[tab]
    },
    getLatestMessage: (tab: ChatType): ChatMessage => {
      const length = messageMap[tab].length
      return messageMap[tab][length - 1]
    },
    /**
     * 获取上一个消息
     * @param tab
     * @param message
     * @returns
     */
    getPreMessage: (tab: ChatType, message: ChatMessage): ChatMessage | null => {
      const index = messageMap[tab].indexOf(message)
      const preIndex = index - 1
      if (preIndex < 0) {
        return null
      }
      return messageMap[tab][preIndex]
    },
    setPageAlert: (alert: boolean | null) => {
      pageAlert.value = alert
      if (state.pageContext) {
        actions.setPageContext(state.pageContext)
      }
    },
    handleCheckResult: async (ins: ComponentInternalInstance | null) => {
      actions.setIsPrechecking(true)
      const checkRes = await sharedCtx.service.copilot.getPreCheckResult()
      if (!checkRes.success) {
        if (checkRes.data?.numeric_error_code === 130113) {
          showSingleToast({
            duration: 3000,
            message: ins?.proxy.$t('copilotCheckFailed') as string,
            type: 'warning',
          })
        }
        if (checkRes.data?.numeric_error_code === 130114) {
          // showSingleToast({
          //   duration: 3000,
          //   // wip: 超限文案
          //   message: ins?.proxy.$t('copilotCapacityFailed') as string,
          //   type: 'warning',
          // })
          state.inviteDialogVisible = true
        }
        if (checkRes.data?.numeric_error_code === 130116) {
          showSingleToast({
            duration: 3000,
            // wip: 超限文案
            message: ins?.proxy.$t('error.highTraffic') as string,
            type: 'warning',
          })
        }
        actions.setIsPrechecking(false)

        return false
      }
      actions.setIsPrechecking(false)
      return true
    },
    clearAllMessages: (tab: ChatType) => {
      messageMap[tab] = []
    },
    /**
     * Copilot 需要加一个超时的时间，就像 chatgpt 如果服务一直没返回，会提示 Network Error 的
      可以先定个20秒，如果还没有开始打字，或者打字卡住了，可以提示用户如下内容：
      “情报助手生成内容失败”
      “Assistant failed to generation content”
    */
    handleTimeout: (controller: AbortController, ins: ComponentInternalInstance | null, command: CopilotCommand) => {
      if (timeoutTimer) clearTimeout(timeoutTimer)
      timeoutTimer = setTimeout(() => {
        Raven.captureMessage(`[log]: copilot timeout: ${command}`, {
          extra: {
            pageContext: state.pageContext,
            route: ins?.proxy.$route.fullPath,
          },
        })
        controller.abort()
        if (timeoutTimer) clearTimeout(timeoutTimer)
        const latestMessage = actions.getLatestMessage('assistant')
        if (latestMessage.type === MessageType.assistant && (latestMessage.loading || latestMessage.isTyping)) {
          return actions.updateLatestMessage('assistant', {
            type: MessageType.assistant,
            error: ins?.proxy.$t('error.generateFailed') as string,
            title: latestMessage.title,
            command,
            content: [...latestMessage.content],
            time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
            timestamp: new Date().getTime(),
          })
        }
      }, 20 * 1000)
    },
    handleErrorCode(res: IHttpWrap<any, any>, ins: ComponentInternalInstance | null) {
      const textMap: Record<string, string> = {
        130115: ins?.proxy.$t('error.illegalContent') as string,
        130116: ins?.proxy.$t('error.highTraffic') as string,
      }
      if (res.data?.numeric_error_code) {
        const errorCode = String(res.data?.numeric_error_code)
        if (Object.keys(textMap).includes(errorCode)) {
          if (timeoutTimer) clearTimeout(timeoutTimer)

          const latestMessage = actions.getLatestMessage('assistant')
          if (latestMessage.type === MessageType.assistant) {
            Raven.captureMessage(`[log]: copilot error: ${errorCode} ${textMap[errorCode]}`, {
              extra: {
                pageContext: state.pageContext,
                route: ins?.proxy.$route.fullPath,
              },
            })
            actions.updateLatestMessage('assistant', {
              type: MessageType.assistant,
              error: textMap[errorCode],
              title: latestMessage.title,
              command: latestMessage.command,
              content: [],
              time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
              timestamp: new Date().getTime(),
            })
          }
          return true
        }
      }

      return false
    },
    handleSelectEmitCommand: async (
      command: CopilotCommand,
      ins: ComponentInternalInstance | null,
      root: CombinedVueInstance<any, any, any, any, any>,
      selectedContent: Ref<string>,
      isResend = false
    ) => {
      if (messageMap['assistant'].some((message) => message.type === MessageType.assistant && (message.loading || message.isTyping))) {
        return showSingleToast({
          duration: 3000,
          // WIP:替换文案
          message: ins?.proxy.$t('copilotCheckFailed') as string,
          type: 'warning',
        })
      }
      startTimestamp = performance.now()
      const r = await actions.handleCheckResult(ins)
      if (!r) return
      // push一条消息: 根据command, payload: selectedContent.value,
      actions.setShowCopilot(true)
      const userContentCommandMap: Partial<Record<CopilotCommand, string>> = {
        [CopilotCommand.explain]: ins?.proxy.$t('userContent.explain') as string,
        [CopilotCommand.summarize]: ins?.proxy.$t('userContent.summarize') as string,
        [CopilotCommand.translate]: ins?.proxy.$t('userContent.translate') as string,
      }
      actions.addMessage('assistant', {
        type: MessageType.user,
        referencedContent: selectedContent.value,
        content: userContentCommandMap[command] || '',
        time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
        timestamp: new Date().getTime(),
      })
      /**
       * https://confluence.zhihuiya.com/pages/resumedraft.action?draftId=162593482&draftShareId=420f3609-923e-4add-9b12-349f1d86911c&
       * 划词“解释”，“总结” 的时候，前端统一做一个判断：
        纯单个字母，例如 a,b,c,d, A,B,C,D
        纯字符，例如 @!@#$%^&*() ，具体可以前端穷举一些常见字符
        纯数字，例如 1,2,3,4,5,6,7,8,9,0,111, 123,321

        统一提示如下：
        “抱歉，由于上下文缺失，我无法提供准确的解释。请提供更多信息以便我能够帮助您。”
        “Sorry, I can't provide an exact explanation due to lack of context. Please provide more information so that I can help you.”
       */
      const isPureLetter = /^[a-zA-Z]$/.test(selectedContent.value)
      const isPureNumber = /^\d+$/.test(selectedContent.value)
      // 表示不包含英文字母、数字和中文字符之外的任何字符
      const isPureSymbol = /^[^a-zA-Z0-9\u4e00-\u9fa5]+$/.test(selectedContent.value)
      if (isPureLetter || isPureNumber || isPureSymbol) {
        actions.addMessage('assistant', {
          type: MessageType.assistant,
          content: [ins?.proxy.$t('copilotNoContext') as string],
          command,
          time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
          timestamp: new Date().getTime(),
        })
        return
      }
      const loadingTitleMap: Partial<Record<CopilotCommand, string>> = {
        [CopilotCommand.explain]: ins?.proxy.$t('loadingTitle.explainLoadingTitle') as string,
        [CopilotCommand.summarize]: ins?.proxy.$t('loadingTitle.summarizeLoadingTitle') as string,
        [CopilotCommand.translate]: ins?.proxy.$t('loadingTitle.translateLoadingTitle') as string,
      }
      // 开始loading, 根据指令计算loading
      actions.addMessage('assistant', {
        type: MessageType.assistant,
        loading: true,
        title: loadingTitleMap[command],
        command,
        content: [],
        timestamp: new Date().getTime(),
      })
      const locale = ins?.proxy.$i18n.locale as string
      controller = new AbortController()
      try {
        const req = {
          page_context: {
            page_name: root.$route.name || '',
            params: {
              language: locale,
            },
          },
          content: selectedContent.value,
          action: command,
        }
        // 开始请求就计时
        actions.handleTimeout(controller, ins, command)
        const res = await sharedCtx.service.copilot.executeCopilotTask({
          ...req,
          signal: controller.signal,
          stream: true,
          onDownloadProgress: async (event: any) => {
            try {
              actions.handleTimeout(controller, ins, command)

              const xhr = event.target
              const { responseText } = xhr
              const resArr = responseText.split('\n\n')
              const resTitleMap: Partial<Record<CopilotCommand, string>> = {
                [CopilotCommand.explain]: ins?.proxy.$t('selectionResTitle.explain') as string,
                [CopilotCommand.summarize]: ins?.proxy.$t('selectionResTitle.summary') as string,
                [CopilotCommand.translate]: ins?.proxy.$t('selectionResTitle.translate') as string,
              }
              const resJsonArr: IStreamResponse[] = resArr
                .filter((i: string) => i)
                .filter((i: string) => i !== 'data:finish' && i !== 'data:_data:error')
                .map((item: string) => JSON.parse(item.slice('data:'.length)))
              if (resJsonArr.length) {
                actions.updateLatestMessage('assistant', {
                  type: MessageType.assistant,
                  title: resTitleMap[command],
                  command,
                  item_id: resJsonArr[0].item_id,
                  isTyping: true,
                  content: [
                    resJsonArr
                      .filter((item: IStreamResponse) => item.choices[0].delta.content)
                      .map((item: IStreamResponse) => item.choices[0].delta.content)
                      .join(''),
                  ],
                  time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
                  timestamp: new Date().getTime(),
                })
              }
              if (resArr && resArr.includes('data:finish')) {
                // 后置处理内容不合法
                const res = await sharedCtx.service.copilot.checkContent({ item_id: resJsonArr[0].item_id })
                const isIllegal = actions.handleErrorCode(res, ins)
                if (isIllegal) return

                if (timeoutTimer) clearTimeout(timeoutTimer)
                const latestMessage = actions.getLatestMessage('assistant')
                // 取消typing显示按钮和下载报告
                if (latestMessage.type === MessageType.assistant) {
                  actions.updateLatestMessage('assistant', { ...latestMessage, isTyping: false })
                }
                if (isResend) {
                  getService().tracking.trackGeneral({
                    event_code: 'CLICK_COPILOT_ACTION_BUTTON',
                    common2: command,
                    common1: 'REFRESH',
                    common3: JSON.stringify(req),
                    common4: typeof latestMessage.content === 'string' ? latestMessage.content : latestMessage.content.join(''),
                  })
                }
                return
              }
              if (resArr && resArr.includes('data:_data:error')) {
                actions.handleError(ins)
              }
            } catch (e) {
              return
            }
          },
        })
        // 取消也会调用
        if (!res.success) {
          console.log(res)
          actions.handleErrorCode(res, ins)
        }
      } catch (e) {
        console.log(e)
        actions.handleError(ins)
      }
    },
    handleError(ins: ComponentInternalInstance | null) {
      if (pollTimer) clearTimeout(pollTimer)
      if (timeoutTimer) clearTimeout(timeoutTimer)

      const latestMessage = actions.getLatestMessage('assistant')
      if (latestMessage.type === MessageType.assistant) {
        Raven.captureMessage(`[log]: copilot backend error: data:_data:error | report failed`, {
          extra: {
            pageContext: state.pageContext,
            route: ins?.proxy.$route.fullPath,
          },
        })

        return actions.updateLatestMessage('assistant', {
          ...latestMessage,
          type: MessageType.assistant,
          error: ins?.proxy.$t('error.highTraffic') as string,
          command: latestMessage.command,
          title: latestMessage.title,
          item_id: latestMessage.item_id,
          isTyping: false,
          loading: false,
          content: latestMessage.command === CopilotCommand.generate_report ? [] : [...latestMessage.content],
          time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
          timestamp: new Date().getTime(),
        })
      }
    },
    handlePageEmitCommand: async (
      command: CopilotCommand,
      ins: ComponentInternalInstance | null,
      root: CombinedVueInstance<any, any, any, any, any>,
      use_cache = true,
      // 针对聊天记录的上下文, 会覆盖掉store的pageContext
      tempPageContext?: CopilotPageContext,
      isResend = false
    ) => {
      if (messageMap['assistant'].some((message) => message.type === MessageType.assistant && (message.loading || message.isTyping))) {
        return showSingleToast({
          duration: 3000,
          // WIP:替换文案
          message: ins?.proxy.$t('copilotCheckFailed') as string,
          type: 'warning',
        })
      }
      const localPageContext = tempPageContext || state.pageContext
      startTimestamp = performance.now()
      const r = await actions.handleCheckResult(ins)
      if (!r) return
      const shouldShowProgressCommands = [CopilotCommand.generate_report].includes(command)

      const locale = ins?.proxy.$i18n.locale as string
      const commandMap: Partial<Record<CopilotCommand, Record<ICopilotPage, string>>> = {
        [CopilotCommand.page_summarize]: {
          [E_ROUTER_NAME.DRUG_LIST]: ins?.proxy.$t('summarizeDrugSearch') as string,
          [E_ROUTER_NAME.DRUG]: ins?.proxy.$t('summarizeDrug') as string,
          [E_ROUTER_NAME.ORGANIZATION]: ins?.proxy.$t('summarizeOrganization') as string,
          [E_ROUTER_NAME.DISEASE]: ins?.proxy.$t('summarizeIndication') as string,
          [E_ROUTER_NAME.TARGET]: ins?.proxy.$t('summarizeTarget') as string,
          [E_ROUTER_NAME.DRUG_TYPE]: ins?.proxy.$t('summarizeDrugType') as string,
          [E_ROUTER_NAME.PATENT_LIST]: ins?.proxy.$t('summarizePatentSearch') as string,
          [E_ROUTER_NAME.DRUG_DEAL_LIST]: ins?.proxy.$t('summarizeDrugDealSearch') as string,
        },
        [CopilotCommand.generate_report]: {
          [E_ROUTER_NAME.DRUG_LIST]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.DRUG]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.ORGANIZATION]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.DISEASE]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.TARGET]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.DRUG_TYPE]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.PATENT_LIST]: ins?.proxy.$t('generateReport') as string,
          [E_ROUTER_NAME.DRUG_DEAL_LIST]: ins?.proxy.$t('generateReport') as string,
        },
      }
      const loadingTitleMap: Partial<Record<CopilotCommand, string>> = {
        [CopilotCommand.page_summarize]: ins?.proxy.$t('loadingTitle.pageSummarizeLoading') as string,
        [CopilotCommand.generate_report]: ins?.proxy.$t('loadingTitle.generateReportLoading') as string,
      }
      const titleMap: Record<ICopilotSearchPage, string> = {
        [E_ROUTER_NAME.DRUG_LIST]: ins?.proxy.$t('drugFinder') as string,
        [E_ROUTER_NAME.PATENT_LIST]: ins?.proxy.$t('patentFinder') as string,
        [E_ROUTER_NAME.DRUG_DEAL_LIST]: ins?.proxy.$t('drugDealFinder') as string,
      }
      const titlePrefix =
        localPageContext?.type === ContextPageType.detail
          ? localPageContext.entity_name
          : titleMap[localPageContext?.page_name as ICopilotSearchPage]
          ? titleMap[localPageContext?.page_name as ICopilotSearchPage]
          : ''
      const detailTitleMap: Record<ICopilotDetailPage, string> = {
        [E_ROUTER_NAME.DRUG]: ins?.proxy.$t('common.drug') as string,
        [E_ROUTER_NAME.ORGANIZATION]: ins?.proxy.$t('common.organization') as string,
        [E_ROUTER_NAME.TARGET]: ins?.proxy.$t('common.target') as string,
        [E_ROUTER_NAME.DRUG_TYPE]: ins?.proxy.$t('common.drugType') as string,
        [E_ROUTER_NAME.DISEASE]: ins?.proxy.$t('common.disease') as string,
      }
      actions.addMessage('assistant', {
        type: MessageType.user,
        time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
        timestamp: new Date().getTime(),
        referencedContent:
          localPageContext?.type === ContextPageType.detail
            ? `${detailTitleMap[localPageContext.page_type as ICopilotDetailPage]}: ${localPageContext.entity_name}`
            : localPageContext?.query_string,
        content: localPageContext?.page_name ? commandMap[command]?.[localPageContext.page_name] || '' : '',
      })

      // 开始loading, 根据指令计算loading
      actions.addMessage('assistant', {
        type: MessageType.assistant,
        loading: true,
        title: loadingTitleMap[command],
        command,
        content: [],
        timestamp: new Date().getTime(),
      })

      controller = new AbortController()
      if (localPageContext?.page_name) {
        try {
          const req = {
            page_context: {
              page_name: localPageContext.page_name,
              params: {
                language: locale,
                use_cache,
                ...(localPageContext?.type === ContextPageType.search
                  ? {
                      query: localPageContext.query,
                      query_string: localPageContext.query_string,
                      refine: localPageContext.refine,
                      ...(localPageContext.group_by ? { group_by: localPageContext.group_by } : {}),
                    }
                  : {
                      entity_id: localPageContext?.entity_id,
                      entity_name: localPageContext?.entity_name,
                    }),
              },
            },
            action: command,
          }
          actions.handleTimeout(controller, ins, command)
          const res = await sharedCtx.service.copilot.executeCopilotTask({
            ...req,
            signal: controller.signal,
            stream: !shouldShowProgressCommands,
            ...(!shouldShowProgressCommands
              ? {
                  onDownloadProgress: async (event: any) => {
                    try {
                      actions.handleTimeout(controller, ins, command)

                      const xhr = event.target
                      const { responseText } = xhr
                      const resArr = responseText.split('\n\n')
                      const resJsonArr: IStreamResponse[] = resArr
                        .filter((i: string) => i)
                        .filter((i: string) => i !== 'data:finish' && i !== 'data:_data:error')
                        .map((item: string) => JSON.parse(item.slice('data:'.length)))

                      if (resJsonArr.length) {
                        actions.updateLatestMessage('assistant', {
                          type: MessageType.assistant,
                          title: `${titlePrefix} | ${ins?.proxy.$t('pageSummarize')}`,
                          time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
                          timestamp: new Date().getTime(),
                          isTyping: true,
                          item_id: resJsonArr[0].item_id,
                          content: [
                            resJsonArr
                              .filter((item: IStreamResponse) => item.choices[0].delta.content)
                              .map((item: IStreamResponse) => item.choices[0].delta.content)
                              .join(''),
                          ],
                          pageContext: localPageContext,
                          command,
                        })
                      }
                      if (resArr && resArr.includes('data:finish')) {
                        // const res = await sharedCtx.service.copilot.checkContent({ item_id: resJsonArr[0].item_id })
                        // const isIllegal = actions.handleErrorCode(res, ins)
                        // if (isIllegal) return
                        if (timeoutTimer) clearTimeout(timeoutTimer)

                        const regex = /(表格|图表|chart|table|Chart|Table)\s*(\d+)/g
                        const latestMessage = actions.getLatestMessage('assistant')
                        if (isResend) {
                          getService().tracking.trackGeneral({
                            event_code: 'CLICK_COPILOT_ACTION_BUTTON',
                            common2: command,
                            common1: 'REFRESH',
                            common3: JSON.stringify(req),
                            common4: typeof latestMessage.content === 'string' ? latestMessage.content : latestMessage.content.join(''),
                          })
                        }
                        // 取消typing显示按钮和下载报告
                        if (latestMessage.type === MessageType.assistant) {
                          actions.updateLatestMessage('assistant', {
                            ...latestMessage,
                            isTyping: false,
                            content: [
                              ...latestMessage.content.map((i) => {
                                if (typeof i === 'string') {
                                  return i.replaceAll(
                                    regex,
                                    `<span  data-anchor="chart-$2" data-pageName="${localPageContext.page_name}" class="text-blue-default cursor-pointer">$1 $2</span>`
                                  )
                                } else return i
                              }),
                              ...(state.reportRouteList.includes(localPageContext.page_name as ICopilotReportPage)
                                ? [
                                    () => (
                                      <div
                                        class="flex justify-center  py-2 border border-blue-default text-blue-default text-sm items-center space-x-1 hover:bg-blue-hover hover:text-white-default rounded-lg cursor-pointer"
                                        onClick={() => actions.handlePageEmitCommand(CopilotCommand.generate_report, ins, root)}
                                      >
                                        <GIcon svgName="SolidChartChunk" size={16} />
                                        {ins?.proxy.$t('generateReport')}
                                      </div>
                                    ),
                                  ]
                                : []),
                            ],
                          })
                        }
                        return
                      }
                      if (resArr && resArr.includes('data:_data:error')) {
                        actions.handleError(ins)
                      }
                    } catch (e) {
                      return
                    }
                  },
                }
              : {}),
          })
          if (res.success) {
            if (shouldShowProgressCommands) {
              if (isResend) {
                // 生成报告
                getService().tracking.trackGeneral({
                  event_code: 'CLICK_COPILOT_ACTION_BUTTON',
                  common2: command,
                  common1: 'REFRESH',
                  common3: JSON.stringify(req),
                  common4: '',
                })
              }
              // 轮询接口
              const itemId = res.data.item_id
              await actions.pollExecResult(itemId, 0, localPageContext, ins, titlePrefix)
            }
          } else {
            actions.handleErrorCode(res, ins)
          }
        } catch (e) {
          actions.handleError(ins)
        }
      }
    },
    getCurrentMessage(tab: ChatType, message: ChatMessage) {
      const index = messageMap[tab].indexOf(message)
      if (index < 1) return null
      else return messageMap[tab][index]
    },
    findPreviousMessage: (tab: ChatType, message: ChatMessage) => {
      const index = messageMap[tab].indexOf(message)
      if (index < 1) return null
      else return messageMap[tab][index - 1]
    },
    /**
     * 获取最后一条消息的用户划词文本或者详情页的id
     * @param tab
     * @returns
     */
    getLatestMessagePayload(tab: ChatType): string {
      const latestMessage = actions.getLatestMessage(tab)
      if (latestMessage?.type !== MessageType.assistant) {
        return ''
      }
      if (!latestMessage.command) {
        return ''
      }

      const getPageInfoCommand: CopilotCommand[] = [CopilotCommand.generate_report, CopilotCommand.page_summarize]
      if (getPageInfoCommand.includes(latestMessage.command)) {
        const pageContext = actions.getPageContext()
        if (pageContext?.type === ContextPageType.detail) {
          return pageContext.entity_id
        }
        return ''
      }
      const preMessage = actions.getPreMessage('assistant', latestMessage)
      if (preMessage && 'referencedContent' in preMessage) {
        return preMessage.referencedContent || ''
      }
      return ''
    },
    stopGenerating(ins: ComponentInternalInstance | null) {
      if (timeoutTimer) clearTimeout(timeoutTimer)
      if (pollTimer) clearTimeout(pollTimer)
      controller.abort()
      stopTimestamp = performance.now()
      const latestMessage = actions.getLatestMessage('assistant')
      if (latestMessage.type === MessageType.assistant) {
        getService().tracking.trackGeneral({
          event_code: 'CLICK_STOP_GENERATING',
          common1: latestMessage.command?.toUpperCase(),
          common2: String((stopTimestamp - startTimestamp) / 1000),
          common3: actions.getLatestMessagePayload('assistant'),
        })
      }

      if (latestMessage.type === MessageType.assistant && (latestMessage.loading || latestMessage.isTyping)) {
        actions.updateLatestMessage('assistant', {
          type: MessageType.assistant,
          command: latestMessage.command,
          isTyping: false,
          loading: false,
          title: latestMessage.title,
          time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
          timestamp: new Date().getTime(),
          error: ins?.proxy.$t('error.cancelRequest') as string,
          content: latestMessage.command !== CopilotCommand.generate_report ? [...latestMessage.content] : [],
        })
        if (latestMessage.item_id) {
          sharedCtx.service.copilot.stopTask({
            item_id: latestMessage.item_id,
          })
        }
        // latestMessage.content.push(() => <CopilotErrorMessage content={(ins?.proxy.$t('error.cancelRequest') as string) || ''} />)
      }
    },
    setPageContext(pageContext: CopilotPageContext) {
      state.pageContext = { ...pageContext, ...(state.pageAlert !== null ? { alert: state.pageAlert } : {}) }
    },
    getPageContext() {
      return state.pageContext
    },
    setHandleAlertFunc(func: () => void) {
      state.handleAlertFunc = func
    },
    callHandleAlertFunc() {
      if (state.handleAlertFunc) state.handleAlertFunc()
    },
    clearPageContext() {
      // state.pageContext = null
      pageContext.value = null
    },
    pollExecResult: async (
      item_id: string,
      index: number,
      pageContext: CopilotPageContext | undefined,
      ins: ComponentInternalInstance | null,
      titlePrefix: string | undefined
    ): Promise<void> => {
      // 开始轮询取消超时计数器
      if (timeoutTimer) clearTimeout(timeoutTimer)
      controller = new AbortController()
      const latestMessage = actions.getLatestMessage('assistant')
      const result = await sharedCtx.service.copilot.getExecutionResult(item_id, controller.signal)
      // console.log('result', result)
      const POLL_COUNT_LIMIT = 150
      if (result.success && pageContext?.page_name) {
        const runStatus = result.data.status

        if (runStatus === CopilotTaskStatus.FAILED) {
          return actions.handleError(ins)
        }
        if (runStatus === CopilotTaskStatus.STOPPED) {
          return actions.updateLatestMessage('assistant', {
            type: MessageType.assistant,
            title: `${titlePrefix} | ${ins?.proxy.$t('generateReport')}`,
            time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
            timestamp: new Date().getTime(),
            item_id,
            pageContext: pageContext,
            content: [],
            error: ins?.proxy.$t('error.cancelRequest') as string,
            command: CopilotCommand.generate_report,
          })
        }
        if (runStatus === CopilotTaskStatus.SUCCESS) {
          return actions.updateLatestMessage('assistant', {
            type: MessageType.assistant,
            title: `${titlePrefix} | ${ins?.proxy.$t('generateReport')}`,
            time: formatCopilotTimeStamp(new Date().getTime(), isCN.value),
            timestamp: new Date().getTime(),
            item_id,
            pageContext: pageContext,
            // wip
            content: [
              ins?.proxy.$t('reportGenerated') as string,
              () => (
                <CopilotDownloadReportBtn
                  s3_path={result.data.report_detail?.s3_path || ''}
                  reportName={result.data.report_detail?.report_name || ''}
                />
              ),
            ],
            command: CopilotCommand.generate_report,
          })
        }

        if (runStatus === CopilotTaskStatus.RUNNING) {
          const moduleStatus = result.data.module_status
          const message: ChatMessage = {
            type: MessageType.assistant,
            title: ins?.proxy.$t('loadingTitle.generateReportLoading') as string,
            command: CopilotCommand.generate_report,
            loading: true,
            pageContext,
            item_id,
            notShowLoading: true,
            content: [() => <CopilotProgressList moduleStatus={moduleStatus} />],
            timestamp: new Date().getTime(),
          }
          if (index === 0) {
            actions.scrollToBottom()
          }
          if (latestMessage && latestMessage.type === MessageType.assistant && latestMessage.command === CopilotCommand.generate_report) {
            actions.updateLatestMessage('assistant', message)
          } else {
            actions.addMessage('assistant', message)
          }

          await nextTick()
        }

        if (index >= POLL_COUNT_LIMIT) {
          return actions.handleError(ins)
        }

        // console.log('正在轮询')
        const currentIndex = index + 1
        // Use a promise to wait for the setTimeout
        await new Promise((resolve) => (pollTimer = setTimeout(resolve, 4000)))
        return await actions.pollExecResult(item_id, currentIndex, pageContext, ins, titlePrefix)
      } else {
        actions.handleError(ins)
      }
    },
  }
  return { state: toRefs(state), actions }
}

export const useChatStore = getSingle(useChatFactory)
window.useChatStore = useChatStore
