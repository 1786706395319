/* eslint-disable @typescript-eslint/no-explicit-any */
import { ALL_DEV_STATUS_MAP, CT_HEATMAP_DEV_STATUS_IDS, DRUG_HEATMAP_DEV_STATUS_IDS } from '@patsnap/synapse_common_config'
import type { IOneDimensionAggValue, ITwoDimensionAggData } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BasicHeatMapChart } from '@pharmsnap/shared/chart'
import {
  ILang,
  IQueryDataEntityType,
  IUseChartTwoDimTupleItem,
  IUseHeatmapChartCalcTotalItem,
  IUseHeatmapChartData,
  IUseHeatmapChartItem,
} from '@pharmsnap/shared/types'
import {
  getDisplayNameDegraded,
  getEntityTypeByAggField,
  isInactivePhase,
  replaceSameNameByIncreaseCount,
  sortCountryV2,
  transformTwoDimTupleItems2QueryItemField,
} from '@pharmsnap/shared/utils'
import { computed, getCurrentInstance, h, onBeforeUnmount, reactive, ref, Ref, watch } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { SliderDataZoomOption } from 'echarts/types/dist/shared'
import { cloneDeep, find, flatten, groupBy, omit, uniq } from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { BHeatMapTooltip } from '../components/business/BHeatMapTooltip/BHeatMapTooltip'
import { useChart } from '../composition'
import { useChartEntityTooltip } from './useChartEntityTooltip'
import { useLocale } from './useLocale'

function refixTooltipPosition(
  x: number,
  y: number,
  contentSize: [number, number],
  itemSize: [number, number],
  viewWidth: number,
  viewHeight: number
) {
  const gapH = -2
  const gapV = -2
  // 弹窗宽度
  const [width, height] = contentSize
  const [itemWidth] = itemSize

  if (gapH != null) {
    // Add extra 2 pixels for this case:
    // At present the "values" in defaut tooltip are using CSS `float: right`.
    // When the right edge of the tooltip box is on the right side of the
    // viewport, the `float` layout might push the "values" to the second line.
    if (x + width + gapH + 2 > viewWidth) {
      x -= itemWidth + width + gapH
    } else {
      x += gapH
    }
  }

  if (gapV != null) {
    if (y + height + gapV > viewHeight) {
      y -= height + gapV
    } else {
      y += gapV
    }
  }

  return [x, y]
}

export function transformHeatmapEventParams2QueryField(params: any) {
  const data = Array.isArray(params.value) ? (params.value[params.value.length - 1] as unknown as IUseChartTwoDimTupleItem[]) : []
  return transformTwoDimTupleItems2QueryItemField(data)
}

export function calcHeatMapTotalData<T = any>(
  aggData: ITwoDimensionAggData<T>,
  heatMapItems: Array<IUseHeatmapChartItem<T>>,
  xCategories: string[],
  options?: {
    aggTotalDataFormatter?: (items: IOneDimensionAggValue[]) => IOneDimensionAggValue[]
  }
): IUseHeatmapChartCalcTotalItem[] {
  if (
    aggData.aggregation_result &&
    aggData.aggregation_result[1] &&
    aggData.aggregation_result[1].items &&
    aggData.aggregation_result[1].items.length > 0
  ) {
    const aggTotalData = cloneDeep(aggData.aggregation_result[1].items)
    const formattedAggTotalData = options?.aggTotalDataFormatter ? options.aggTotalDataFormatter(aggTotalData) : aggTotalData

    return formattedAggTotalData.reduce(
      (acc, next) => {
        const xCategoryName = find(
          xCategories,
          (xCategory) =>
            xCategory === next.display_name ||
            xCategory === next.display_name_cn ||
            xCategory === next.display_name_en ||
            xCategory === next.key ||
            xCategory === next.key_as_string
        )
        if (xCategoryName) {
          acc.push({
            xCategoryName,
            total: next.count,
          })
        }
        return acc
      },
      [] as Array<{
        xCategoryName: string
        total: number
      }>
    )
  } else {
    const groupByXCategoryName = groupBy(heatMapItems, (item) => item.xCategoryName)

    return Object.values(groupByXCategoryName).map((heatMapItemList) => {
      const relatedDrugIds = heatMapItemList.reduce((acc, next) => {
        const { originData = [] } = next
        originData.forEach((data) => {
          const otherInfo = data[1] && (data[1].otherInfo as any)
          if (otherInfo && otherInfo.related_drug_id && otherInfo.related_drug_id.length) {
            acc.push(...otherInfo.related_drug_id)
          }
        })
        return acc
      }, [] as string[])
      const uniqRelatedDrugIds = uniq(relatedDrugIds)

      return {
        xCategoryName: heatMapItemList[0].xCategoryName,
        total: uniqRelatedDrugIds.length,
      }
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformTwoDimCountryAggData2HeatMapData<T = any>(
  options: {
    i18n: VueI18n
    useYShortName?: boolean
    useXShortName?: boolean
  },
  data?: ITwoDimensionAggData<T>
) {
  return transformTwoDimAggData2HeatMapData(
    {
      ...options,
      xSorter: (a, b) => {
        return sortCountryV2(a.display_name_en, b.display_name_en)
      },
    },
    data
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformTwoDimYearAggData2HeatMapData<T = any>(
  options: {
    i18n: VueI18n
    useYShortName?: boolean
    useXShortName?: boolean
    useXKeyAsName?: boolean
  },
  data?: ITwoDimensionAggData<T>
) {
  return transformTwoDimAggData2HeatMapData(
    {
      ...options,
      xSorter: (a, b) => {
        return Number(a.key) - Number(b.key)
      },
    },
    data
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformTwoDimAggData2HeatMapData<T = any>(
  options: {
    i18n: VueI18n
    useYShortName?: boolean
    useXShortName?: boolean
    // 主要用于处理年份聚合的情况，新闻年份聚合的时候会有一个key_as_string字段表示年份
    useXKeyAsName?: boolean
    xSorter?: (
      a: { display_name_en: string; display_name_cn: string; key: string; count: number },
      b: { display_name_en: string; display_name_cn: string; key: string; count: number }
    ) => number
    ySorter?: (
      a: { display_name_en: string; display_name_cn: string; key: string; count: number },
      b: { display_name_en: string; display_name_cn: string; key: string; count: number }
    ) => number
  },
  data?: ITwoDimensionAggData<T>
): IUseHeatmapChartData<T> {
  const emptyData = {
    xCategories: [],
    yCategories: [],
    items: [],
    calcTotals: [],
  }
  if (!data) return emptyData

  if (data && !data.aggregation_result) return emptyData

  if (data && data.aggregation_result && data.aggregation_result.length === 0) return emptyData

  if (data && data.aggregation_result && data.aggregation_result[0] && !data.aggregation_result[0].items) return emptyData

  if (data && data.aggregation_result && data.aggregation_result[0] && data.aggregation_result[0].items.length === 0) return emptyData

  const mustUseYShortName =
    data.aggregation_result[0].aggregation_field === 'TARGET_ID' ||
    data.aggregation_result[0].aggregation_field === 'PHS_TARGET_ID' ||
    data.aggregation_result[0].aggregation_field === 'phs_target_id'
  const mustUseXShortName =
    data.aggregation_result[0] &&
    data.aggregation_result[0].items &&
    data.aggregation_result[0].items.length > 0 &&
    data.aggregation_result[0].items[0].aggregation_result &&
    data.aggregation_result[0].items[0].aggregation_result.length > 0 &&
    data.aggregation_result[0].items[0].aggregation_result[0].aggregation_field === 'TARGET_ID'

  const useYShortName = !!options.useYShortName
  const useXShortName = !!options.useXShortName
  const useXKeyAsName = !!options.useXKeyAsName
  const getEnName = (
    i: { display_name_en: string; display_name_cn: string; short_name_en?: string[] },
    useShortName: boolean,
    mustUseShortName: boolean
  ) => {
    return useShortName || mustUseShortName ? i.short_name_en?.[0] || i.display_name_en : i.display_name_en
  }
  const getCnName = (
    i: { display_name_en: string; display_name_cn: string; short_name_cn?: string[] },
    useShortName: boolean,
    mustUseShortName: boolean
  ) => {
    return useShortName || mustUseShortName ? i.short_name_cn?.[0] || i.display_name_cn : i.display_name_cn
  }

  const locale = options.i18n.locale.toUpperCase() as ILang

  const yItems = data.aggregation_result[0].items
    .filter((i) => i.aggregation_result && i.aggregation_result[0] && i.aggregation_result[0].items && i.aggregation_result[0].items.length > 0)
    .reverse()

  const sortedYItems = options.ySorter ? [...yItems].sort(options.ySorter) : yItems

  const yNames = sortedYItems.map((i) => ({
    name:
      locale === 'CN' && !mustUseYShortName
        ? i.display_name_cn || getEnName(i, useYShortName, mustUseYShortName)
        : getEnName(i, useYShortName, mustUseYShortName) || getCnName(i, useYShortName, mustUseYShortName),
  }))

  const yCategories = replaceSameNameByIncreaseCount(yNames, {
    getName: (i) => i.name,
    updateVal: (i, name) => (i.name = name),
  }).map((i) => i.name)

  const xItems = flatten(
    sortedYItems
      .filter((i) => i.aggregation_result && i.aggregation_result[0] && i.aggregation_result[0].items && i.aggregation_result[0].items.length > 0)
      .map((i) => {
        return i.aggregation_result[0].items
      })
  )

  const xCategories = uniq(
    (options.xSorter ? [...xItems].sort(options.xSorter) : xItems).map((k) => {
      return useXKeyAsName
        ? k.key_as_string || k.key || ''
        : locale === 'CN' && !mustUseXShortName
        ? k.display_name_cn || getEnName(k, useXShortName, mustUseXShortName)
        : getEnName(k, useXShortName, mustUseXShortName) || getCnName(k, useXShortName, mustUseXShortName)
    })
  )

  const items = flatten(
    sortedYItems
      .filter((i) => i.aggregation_result && i.aggregation_result[0] && i.aggregation_result[0].items && i.aggregation_result[0].items.length > 0)
      .map((item, index) => {
        const yCategory = yCategories[index]
        const list = item.aggregation_result[0].items
        const innerItems = list
          // x 是时间维度，后端会返回带有0的数据，热力图这边过滤下
          .filter((i) => i.count !== 0)
          .map((i) => ({
            xCategoryName: useXKeyAsName
              ? i.key_as_string || i.key || ''
              : locale === 'CN' && !mustUseXShortName
              ? i.display_name_cn || getEnName(i, useXShortName, mustUseXShortName)
              : getEnName(i, useXShortName, mustUseXShortName) || getCnName(i, useXShortName, mustUseXShortName),
            yCategoryName: yCategory,
            count: i.count || 0,
            originData: [
              [
                {
                  display_name_cn: item.short_name_en?.[0] || item.display_name_cn,
                  display_name_en: getEnName(item, useYShortName, mustUseYShortName),
                  id: item.key,
                  field: data.aggregation_result[0].aggregation_field,
                },
                {
                  display_name_cn: useXKeyAsName ? i.key_as_string || i.key || '' : i.display_name_cn,
                  display_name_en: useXKeyAsName ? i.key_as_string || i.key || '' : getEnName(i, useXShortName, mustUseXShortName),
                  id: useXKeyAsName ? i.key_as_string || i.key : i.key,
                  field: item.aggregation_result[0].aggregation_field,
                  key: i.key,
                  otherInfo: i.other_info,
                },
              ],
            ],
          }))

        return innerItems
      })
  ) as Array<IUseHeatmapChartItem<T>>

  const calcTotals = calcHeatMapTotalData(data, items, xCategories, {
    aggTotalDataFormatter(list) {
      // x 是时间维度，后端会返回带有0的数据，热力图这边过滤下
      return list.filter((i) => i.count !== 0)
    },
  })

  return {
    xCategories,
    yCategories,
    items,
    calcTotals,
  }
}
// TODO: 将phase的逻辑抽象化，使用transformTwoDimAggData2HeatMapData
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformTwoDimPhaseAggData2HeatMapData<T = any>(
  options: { dimType: 'drug' | 'trial'; i18n: VueI18n; useYShortName?: boolean },
  data?: ITwoDimensionAggData<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): IUseHeatmapChartData<T> {
  const emptyData = {
    xCategories: [],
    yCategories: [],
    items: [],
    calcTotals: [],
  }
  if (!data) return emptyData

  if (data && !data.aggregation_result) return emptyData

  if (data && data.aggregation_result && data.aggregation_result.length === 0) return emptyData

  if (data && data.aggregation_result && data.aggregation_result[0] && !data.aggregation_result[0].items) return emptyData

  if (data && data.aggregation_result && data.aggregation_result[0] && data.aggregation_result[0].items.length === 0) return emptyData

  const useYShortName = options.useYShortName ? true : false

  const mustUseShortName = data.aggregation_result[0].aggregation_field === 'TARGET_ID'

  const locale = options.i18n.locale.toUpperCase() as ILang
  const getPhaseName = (phase: string) => (locale === 'CN' ? ALL_DEV_STATUS_MAP[phase].name_cn : ALL_DEV_STATUS_MAP[phase].name_en)
  const xCategories =
    options.dimType === 'drug'
      ? [locale === 'CN' ? '非在研' : 'Inactive', ...DRUG_HEATMAP_DEV_STATUS_IDS.map(getPhaseName)]
      : CT_HEATMAP_DEV_STATUS_IDS.map(getPhaseName)

  const yItems = [...data.aggregation_result[0].items].reverse().filter(
    (i) =>
      i.aggregation_result &&
      i.aggregation_result[0] &&
      i.aggregation_result[0].items &&
      i.aggregation_result[0].items.length > 0 &&
      // 过滤掉阶段都不再指定x轴类目里面的项
      i.aggregation_result[0].items.some(
        (ii) =>
          isInactivePhase(ii.key) || Number(ii.key) === -1 || xCategories.includes(options.i18n.tc(`clinical_trial.phase.${ii.display_name_en}`))
      )
  )

  const getYCategoryName = (i: { display_name_en: string; display_name_cn: string; short_name_en?: string[] }) => {
    const computedDisplayName = locale === 'CN' ? i.display_name_cn || i.display_name_en : i.display_name_en || i.display_name_cn
    const yEnName = mustUseShortName
      ? i.short_name_en?.[0] || computedDisplayName
      : useYShortName
      ? i.short_name_en?.[0] || computedDisplayName
      : computedDisplayName

    return locale === 'CN' && !mustUseShortName ? i.display_name_cn || yEnName : yEnName
  }

  const yNames = yItems.map((i) => ({ name: getYCategoryName(i) }))

  const yCategories = replaceSameNameByIncreaseCount(yNames, {
    getName: (i) => i.name,
    updateVal: (i, name) => (i.name = name),
  })

  const items = flatten(
    yItems.map((item, index) => {
      const yCategory = yCategories[index].name
      const list = item.aggregation_result[0].items
      const activeList = list.filter((i) => !isInactivePhase(i.display_name_en) && Number(i.key) !== -1)
      const inActiveList = list.filter((i) => !i.display_name_en || isInactivePhase(i.display_name_en) || Number(i.key) === -1)

      const [transformedActiveList, transformedInActiveList] = [activeList, inActiveList].map(
        (listItem) =>
          listItem.map((i) => ({
            xCategoryName: options.i18n.tc(`clinical_trial.phase.${i.display_name_en}`),
            yCategoryName: yCategory,
            count: i.count || 0,
            originData: [
              [
                {
                  display_name_cn: item.display_name_cn,
                  display_name_en: useYShortName ? item.short_name_en?.[0] || item.display_name_en : item.display_name_en,
                  id: item.key,
                  field: data.aggregation_result[0].aggregation_field,
                },
                {
                  display_name_cn: i.display_name_cn,
                  display_name_en: i.display_name_en,
                  id: i.key,
                  field: item.aggregation_result[0].aggregation_field,
                  otherInfo: i.other_info,
                },
              ],
            ],
          })) as IUseHeatmapChartItem<T>[]
      )

      if (transformedInActiveList.length > 0) {
        const mergedInActiveList = transformedInActiveList.reduce((pre, next) => {
          pre.xCategoryName = options.i18n.tc(`clinical_trial.phase.Inactive`)
          pre.yCategoryName = next.yCategoryName
          pre.count = Number(pre.count || 0) + Number(next.count || 0)
          if (pre.originData) {
            if (next.originData && next.originData[0]) {
              const nextOriginData = next.originData[0]
              pre.originData.push(nextOriginData)
            }
          } else {
            pre.originData = next.originData
          }
          return pre
        }, {} as IUseHeatmapChartItem<T>)

        transformedActiveList.push(mergedInActiveList)
      }

      return transformedActiveList
    })
  )
  const calcTotals = calcHeatMapTotalData(data, items, xCategories, {
    aggTotalDataFormatter(list) {
      const cloneList = cloneDeep(list)
      const filteredList = cloneList.filter(
        (item) =>
          isInactivePhase(item.key) ||
          Number(item.key) === -1 || // 后端聚合好的total数据，由于药物id需要去重，所以后端返回一个-1作为key的总数
          xCategories.includes(options.i18n.tc(`clinical_trial.phase.${item.display_name_en}`))
      )
      const activeList = filteredList.filter((i) => !isInactivePhase(i.display_name_en))
      const inActiveList = filteredList.filter((i) => Number(i.key) === -1 || !i.display_name_en || isInactivePhase(i.display_name_en))
      if (inActiveList.length > 0) {
        const inActiveCount = inActiveList.reduce((acc, next) => {
          acc += Number(next.count)
          return acc
        }, 0)
        activeList.push({
          display_name_cn: '非在研',
          display_name_en: 'Inactive',
          count: inActiveCount,
          key: '',
        })
      }
      return activeList
    },
  })

  return {
    xCategories,
    yCategories: yCategories.map((i) => i.name),
    items,
    calcTotals,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHeatmap<T = any>(
  data: Ref<IUseHeatmapChartData<T>>,
  config: {
    increasedImg?: string
    name?: string | (() => string)
    yLabelWidth?: number | (() => number)
    xLabelWidth?: number | (() => number)
    theme?: 'pink' | 'blue'
    calcTotal?: boolean
    showAllXCategory?: boolean
    showVisualMap?: boolean
    visualMapPosition?: 'bottom' | 'right'
    labelRotate?: number | (() => number)
    scrollY?: {
      show: boolean | (() => boolean)
      limit: number | (() => number)
    }
    scrollX?: {
      show: boolean | (() => boolean)
      limit: number | (() => number)
    }
    validIncreased?: (data: IUseHeatmapChartItem<T>['originData']) => boolean
    renderExtraTooltipInfo?: (data: IUseHeatmapChartItem<T>['originData']) => JSX.Element | JSX.Element[] | string | null
    registerEvent?: (instance: EChartsType) => void
    showBasicHoverInfo?: boolean
  }
) {
  const locale = useLocale()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let vm: any
  let echartInstance: EChartsType
  const tooltipProps = reactive({
    xCategory: '',
    yCategory: '',
    count: 0,
    color: '',
    seriesName: '',
    originData: {},
  })
  const axisDimPosition = ref({
    left: 0,
    top: 0,
  })
  const axisDimStyle = ref({})
  const axisEntity = ref<{ id: string; type: IQueryDataEntityType | 'text'; displayName?: string }>({
    id: '',
    type: 'text',
    displayName: '',
  })
  const ins = getCurrentInstance()
  const theme = config.theme || 'pink'
  const calcTotal = config.calcTotal || false
  const showAllXCategory = config.showAllXCategory || false
  const scrollYLimit = () => (config.scrollY ? (typeof config.scrollY.limit === 'function' ? config.scrollY.limit() : config.scrollY.limit) : 10)
  const showScrollY = () => (config.scrollY ? (typeof config.scrollY.show === 'function' ? config.scrollY.show() : !!config.scrollY.show) : false)
  const scrollXLimit = () => (config.scrollX ? (typeof config.scrollX.limit === 'function' ? config.scrollX.limit() : config.scrollX.limit) : 15)
  const showScrollX = () => (config.scrollX ? (typeof config.scrollX.show === 'function' ? config.scrollX.show() : !!config.scrollX.show) : false)
  const getYLabelWidth = () => (typeof config.yLabelWidth === 'function' ? config.yLabelWidth() : config.yLabelWidth || 120)
  const getXLabelWidth = () => (typeof config.xLabelWidth === 'function' ? config.xLabelWidth() : config.xLabelWidth || 80)
  const getLabelRotate = () => (typeof config.labelRotate === 'function' ? config.labelRotate() : config.labelRotate || 0)
  const getSeriesName = () => (typeof config.name === 'function' ? config.name() : config.name || '')
  const xCategories = computed(() => data.value.xCategories || [])
  const yCategories = computed(() => {
    const items = (data.value.yCategories || []).slice()
    if (calcTotal) {
      items.push(ins?.proxy.$tc('chart.total') || '')
    }

    return items
  })
  const canScrollY = computed(() => showScrollY() && scrollYLimit() < yCategories.value.length)
  const canScrollX = computed(() => showScrollX() && scrollXLimit() < xCategories.value.length)
  const shouldRegisterMouseWheel = computed(() => {
    return canScrollX.value || canScrollY.value
  })
  const chartData = computed(() => {
    const items = data.value.items.map((i) => ({
      x: i.xCategoryName,
      y: i.yCategoryName,
      value: i.count,
      originData: i.originData,
      useVisualMapColor: true,
    }))

    if (!calcTotal) {
      return items
    }

    const totalCategory = ins?.proxy.$tc('chart.total') || ''

    const totalItems = xCategories.value
      .map((item) => {
        const matchedItems = data.value.calcTotals.filter((i) => i.xCategoryName === item)
        const matchedData = data.value.items.filter((i) => i.xCategoryName === item)
        if (matchedItems.length === 0 || matchedData.length === 0) {
          return null
        }

        const originData = matchedData[0]?.originData?.[0]?.[1]
          ? [[undefined, cloneDeep(omit(matchedData[0].originData?.[0]?.[1], 'otherInfo'))]]
          : undefined

        return {
          x: item,
          y: totalCategory,
          value: matchedItems[0].total,
          ...(originData ? { originData } : undefined),
          useVisualMapColor: false,
        }
      })
      .filter((i) => i !== null) as BasicHeatMapChart['data']
    return items.concat(totalItems)
  })
  const yDimIsEntity = computed(() => {
    return chartData.value.some(
      (i) => i.originData && i.originData[0] && i.originData[0][0] && i.originData[0][0].field && getEntityTypeByAggField(i.originData[0][0].field)
    )
  })
  const isEmpty = computed(() => xCategories.value.length === 0 || yCategories.value.length === 0)
  const heatMapOption = computed<BasicHeatMapChart>(() => {
    const seriesName = getSeriesName()
    const showVisualMap = typeof config.showVisualMap === 'undefined' ? true : config.showVisualMap
    const visualMapPosition = typeof config.visualMapPosition === 'undefined' ? 'right' : config.visualMapPosition
    const option: BasicHeatMapChart = {
      type: 'heatmap',
      seriesName,
      grid: {
        top: getLabelRotate() > 0 ? getXLabelWidth() - 20 : 26,
        left: getYLabelWidth() + 20,
        right: visualMapPosition === 'right' ? (showVisualMap ? 140 : 10) : 10,
        bottom: visualMapPosition === 'bottom' ? (showVisualMap ? 50 : 10) : 10,
        containLabel: false,
      },
      data: chartData.value,
      xAxis: {
        name: '',
        data: xCategories.value,
        axisLabel: {
          rotate: getLabelRotate(),
          interval: showAllXCategory ? 0 : 'auto',
          ...(getLabelRotate() > 0
            ? {
                width: getXLabelWidth(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                overflow: 'truncate',
              }
            : undefined),
        },
        position: 'top',
      },
      yAxis: {
        name: '',
        data: yCategories.value,
        axisLabel: {
          // rotate: labelRotate,
          width: getYLabelWidth(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflow: 'truncate',
        },
        triggerEvent: true,
      },
      labelFormatter: (params) => {
        const value = params.value
        const encode = params.encode
        const originData = value[value.length - 1]
        const val = value[encode.value[0]]
        if (!originData) return toThousands(val)
        if (config.validIncreased && config.validIncreased(originData)) {
          return `{a|${toThousands(val)}}{increased|}`
        }

        return val
      },
      labelRich: {
        a: {
          lineHeight: 12,
          height: 12,
          fontSize: 12,
          verticalAlign: 'bottom',
          padding: [0, 2, 0, 0],
        },
        increased: {
          height: 14,
          backgroundColor: {
            image: config.increasedImg || '',
          },
        },
      },
      tooltip: {
        show: true,
        enterable: true,
        appendToBody: true,
        padding: 0,
        position(point: number[], params: any, dom: any, rect: any, size: any) {
          if (!echartInstance) return []
          const [mouseX, mouseY] = point
          const { target } = echartInstance.getZr().findHover(mouseX, mouseY)
          if (!target) return []
          const el = target.type === 'tspan' || target.type === 'image' ? target.parent.__hostTarget : target
          if (!el) return []
          const elRect = el.getBoundingRect().clone()
          el && elRect.applyTransform(el.transform)
          const { x: itemX, y: itemY, width: itemWidth, height: itemHeight } = elRect

          const {
            contentSize: [contentWidth, contentHeight],
            viewSize: [viewWidth, viewHeight],
          } = size
          const [positionX, positionY] = refixTooltipPosition(
            itemX + itemWidth,
            itemY + itemHeight / 2,
            [contentWidth, contentHeight],
            [itemWidth, itemHeight],
            viewWidth,
            viewHeight
          )

          return [positionX, positionY]
        },
        // showDelay: 200,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: tooltipFormatter,
      },
      visualMap: {
        show: showVisualMap,
        inRange: { color: theme === 'pink' ? ['#F4F5F7', '#FF6C73'] : ['#d4f0f6', '#1976D2'] },
        text: [seriesName, ''],
      },
    }

    if (visualMapPosition === 'right') {
      option.visualMap = {
        ...option.visualMap,
        right: 10,
        top: 'middle',
        orient: 'vertical',
      }
    }

    if (visualMapPosition === 'bottom') {
      option.visualMap = {
        ...option.visualMap,
        bottom: 0,
        left: 'middle',
        orient: 'horizontal',
      }
    }

    let ySliderDataZoom: SliderDataZoomOption | null = null
    let xSliderDataZoom: SliderDataZoomOption | null = null

    if (canScrollY.value) {
      ySliderDataZoom = {
        type: 'slider',
        yAxisIndex: 0,
        zoomLock: true,
        brushSelect: false,
        showDetail: false,
        handleSize: 0,
        width: 10,
        right: showVisualMap ? (visualMapPosition === 'right' ? 130 : 0) : 0,
        top: getLabelRotate() > 0 ? getXLabelWidth() - 20 : 26,
        bottom: visualMapPosition === 'bottom' ? 50 : 10,
        startValue: yCategories.value.length - 1,
        endValue: calcTotal ? yCategories.value.length - scrollYLimit() : yCategories.value.length - 1 - scrollYLimit(),
        borderColor: '#FAFBFC',
        borderRadius: 2,
        fillerColor: '#BCC2CC',
        // minValueSpan: calcTotal ? scrollYLimit() - 1 : scrollYLimit(),
      }
    }

    if (canScrollX.value) {
      xSliderDataZoom = {
        type: 'slider',
        xAxisIndex: 0,
        zoomLock: true,
        brushSelect: false,
        showDetail: false,
        handleSize: 0,
        height: 10,
        bottom: visualMapPosition === 'right' ? 0 : 40,
        left: getYLabelWidth() + 20,
        startValue: 0,
        endValue: scrollXLimit() - 1,
        borderColor: '#FAFBFC',
        borderRadius: 2,
        fillerColor: '#BCC2CC',
        // minValueSpan: scrollXLimit(),
      }
    }

    if (option.dataZoom) {
      if (!Array.isArray(option.dataZoom)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        option.dataZoom = [option.dataZoom]
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      option.dataZoom = []
    }

    if (!!xSliderDataZoom || !!ySliderDataZoom) {
      if (xSliderDataZoom) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        option.dataZoom.push(xSliderDataZoom)
      }
      if (ySliderDataZoom) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        option.dataZoom.push(ySliderDataZoom)
      }

      option.animation = false
    }

    return option
  })

  onBeforeUnmount(() => {
    if (vm) {
      vm.$destroy()
    }
    vm = null
  })

  const getDimDataFromAxisParam = (params: any) => {
    if (!params.value) return
    if (!params.event) return
    const name = params.value
    const found = chartData.value.find((i) => i.y === name)
    return found
  }

  const { initChart, chartContainer, render, options, width, height, getEchartsInstance } = useChart(heatMapOption, {
    autoResize: true,
    registerEvent,
  })

  useChartEntityTooltip(chartContainer, axisDimPosition, axisEntity, axisDimStyle)

  watch(yDimIsEntity, (val) => {
    if (val) {
      echartInstance && registerEntityEvent(echartInstance)
    } else {
      echartInstance && unRegisterEntityEvent(echartInstance)
    }
  })

  watch(shouldRegisterMouseWheel, (val) => {
    if (val) {
      echartInstance && registerMouseWheelEvent(echartInstance)
    } else {
      echartInstance && unRegisterMouseWheelEvent(echartInstance)
    }
  })

  function handleYAxisMouseOver(params: any) {
    const event = params.event
    if (!event) return
    const target = event.target
    if (!target) return
    const found = getDimDataFromAxisParam(params)
    const originData = found?.originData
    if (!originData) return
    const yDimData = originData[0][0]
    const field = yDimData.field
    const displayName = getDisplayNameDegraded(yDimData, locale.locale.value)
    const entityType = getEntityTypeByAggField(field)
    if (!entityType && !config.showBasicHoverInfo) return
    const targetAxisRect = target.getBoundingRect().clone()
    target && targetAxisRect.applyTransform(target.transform)
    axisDimPosition.value = {
      left: targetAxisRect.x,
      top: targetAxisRect.y,
    }
    axisDimStyle.value = {
      width: `${targetAxisRect.width}px`,
      height: `${targetAxisRect.height}px`,
    }
    axisEntity.value = {
      id: yDimData.id,
      type: entityType || 'text',
      displayName,
    }
  }

  function handleMouseWheel(e: any) {
    const originEvent = e.event as MouseEvent
    originEvent.preventDefault()
    const direction = getScrollDirection(e.event)
    if (!direction) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dataZoomCmp = echartInstance._componentsViews.find((i) => i.type === 'dataZoom.slider' && i._orient === direction)
    if (!dataZoomCmp) return
    const [startVal, endVal] = dataZoomCmp._range
    // 下面注释的功能为,在图表区域滚动,图表range到达边界,就不组织冒泡,由于交互不是很好,先注释
    // if (startVal !== 0 && endVal !== 100) {
    //   originEvent.stopPropagation()
    //   originEvent.preventDefault()
    // }
    const scrollDelta =
      (e.wheelDelta < 0 ? -1 : 1) * (direction === 'horizontal' ? getHorizontalDelta(dataZoomCmp._range) : getVerticalDelta(dataZoomCmp._range))
    const newStartVal = startVal + scrollDelta
    const newEndVal = endVal + scrollDelta
    echartInstance.dispatchAction({
      type: 'dataZoom',
      dataZoomIndex: canScrollY.value && canScrollX.value ? (direction === 'horizontal' ? 0 : 1) : 0,
      start: newStartVal,
      end: newEndVal,
    })
    dataZoomCmp._updateView(false)
    dataZoomCmp._dispatchZoomAction(true)
  }

  function registerEntityEvent(instance: EChartsType) {
    instance.on('mouseover', 'yAxis', handleYAxisMouseOver)
  }

  function unRegisterEntityEvent(instance: EChartsType) {
    instance.off('mouseover', handleYAxisMouseOver)
  }

  function registerMouseWheelEvent(instance: EChartsType) {
    instance.getZr().on('mousewheel', handleMouseWheel)
  }

  function unRegisterMouseWheelEvent(instance: EChartsType) {
    instance.getZr().off('mousewheel', handleMouseWheel)
  }

  function registerEvent(instance: EChartsType) {
    config && config.registerEvent && config.registerEvent(instance)
    echartInstance = instance
    if (config.showBasicHoverInfo) {
      registerEntityEvent(instance)
    } else if (yDimIsEntity.value) {
      registerEntityEvent(instance)
    }
    if (shouldRegisterMouseWheel.value) {
      registerMouseWheelEvent(instance)
    }
  }

  function seriesToolTipFormatter(params: any) {
    const { encode, value: data, color } = params
    const { value, x, y } = encode
    if (!encode) return ''
    if (!x) return ''
    if (!y) return ''
    if (!value) return ''
    if (!data) return ''
    const xCategoryIndex = data[x[0]]
    const yCategoryIndex = data[y[0]]
    const valueIndex = value[0]
    const xCategoryName = xCategories.value[xCategoryIndex]
    const yCategoryName = yCategories.value[yCategoryIndex]
    const originData = data[data.length - 1]
    const val = data[valueIndex]
    tooltipProps.color = color
    tooltipProps.count = val
    tooltipProps.xCategory = xCategoryName
    tooltipProps.yCategory = yCategoryName
    tooltipProps.seriesName = getSeriesName()
    tooltipProps.originData = originData
    if (yCategoryName === ins?.proxy.$tc('chart.total') || '') {
      return ''
    }
    if (!vm) {
      vm = new Vue({
        i18n: ins?.proxy.$i18n,
        render() {
          return h(BHeatMapTooltip, {
            props: {
              xCategory: tooltipProps.xCategory,
              yCategory: tooltipProps.yCategory,
              count: tooltipProps.count,
              color: tooltipProps.color,
              seriesName: tooltipProps.seriesName,
              originData: tooltipProps.originData,
            },
            scopedSlots: {
              default: () => {
                return config.validIncreased && config.validIncreased(tooltipProps.originData as any)
                  ? config.renderExtraTooltipInfo && config.renderExtraTooltipInfo(tooltipProps.originData as any)
                  : null
              },
            },
          })
        },
      })
      vm.$mount()
    }
    return vm.$el
  }

  function tooltipFormatter(params: any) {
    return seriesToolTipFormatter(params)
  }

  function getHorizontalDelta(range: [number, number]) {
    const [start, end] = range
    const total = Math.abs(start - end)
    const len = scrollXLimit()
    return total / len
  }

  function getVerticalDelta(range: [number, number]) {
    const [start, end] = range
    const total = Math.abs(start - end)
    const len = scrollYLimit()
    return total / len
  }

  function getScrollDirection(e: WheelEvent) {
    const deltaX = e.deltaX
    const deltaY = e.deltaY
    if (deltaX !== 0 && deltaY === 0) return 'horizontal'
    if (deltaX === 0 && deltaY !== 0) return 'vertical'
    if (deltaX === 0 && deltaY === 0) return
    const angleDegrees = (Math.atan2(Math.abs(deltaY), Math.abs(deltaX)) * 180) / Math.PI
    if (angleDegrees <= 45) return 'horizontal'
    return 'vertical'
  }

  return {
    chartContainer,
    initChart,
    render,
    options,
    isEmpty,
    width,
    height,
    getEchartsInstance,
  }
}
