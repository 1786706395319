const DEFAULT_BULLS_EYE_CHART_PHASE_IDS: string[] = [
  'b2ca28b01c34419db0365bad2f8dd09e',
  'e2c11c24a7ee4b4e81bbde9eb7127c6d',
  '7b79728539354f189772a4eab9cc9fff',
  'f835774c48404593b64ef05369eb8eba',
  '78c45f1d523646808032edcff807ca59',
  'efd6215835394b07b046125e3175d3fe',
]
interface BullsEyeSettingValue {
  phaseIds: string[]
  topN: number
}
export class BullsEyeSetting {
  defaultSetting: BullsEyeSettingValue = {
    phaseIds: DEFAULT_BULLS_EYE_CHART_PHASE_IDS,
    topN: 10,
  }

  localStorageKey = 'bulls_eye_setting'

  get setting() {
    return this.localStorageSetting || this.defaultSetting
  }

  get phaseIds() {
    return this.setting.phaseIds
  }

  set phaseIds(phaseIds: string[]) {
    const setting = this.setting
    setting.phaseIds = phaseIds
    localStorage.setItem(this.localStorageKey, JSON.stringify(setting))
  }

  get topN() {
    return this.setting.topN
  }

  set topN(topN: number) {
    const setting = this.setting
    setting.topN = topN
    localStorage.setItem(this.localStorageKey, JSON.stringify(setting))
  }

  get localStorageSetting() {
    const data = localStorage.getItem(this.localStorageKey)
    if (!data) {
      return null
    }
    try {
      const jsonData = JSON.parse(data) as BullsEyeSettingValue
      return jsonData
    } catch (error) {
      return null
    }
  }

  static instances: BullsEyeSetting | null = null

  static getSingleton() {
    if (!this.instances) {
      this.instances = new BullsEyeSetting()
    }
    return this.instances
  }
}
