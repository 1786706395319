import { E_SORT_ORDER } from '@patsnap/synapse_common_interface'

export const orderConfigMap: Record<E_SORT_ORDER, { i18nKey: string }> = {
  [E_SORT_ORDER.ASC]: {
    i18nKey: 'SortCardButton.ascending',
  },
  [E_SORT_ORDER.DESC]: {
    i18nKey: 'SortCardButton.descending',
  },
}
