import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { BDatePicker, GDialog, GRadio } from '@pharmsnap/shared/components'
import { userInjectFilterService } from '@pharmsnap/shared/components/business/BFilter/BFilterService'
import { IFilterConfig, IFilterItemTimeConfig, IFilterValue } from '@pharmsnap/shared/components/business/BFilter/types'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { getDateTypeConfigMap } from '@pharmsnap/shared/constants/time'
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from '@pharmsnap/shared/element-ui'
import { E_ALIGN_DIRECTION } from '@pharmsnap/shared/types/enum'
import { DATE_TYPE } from '@pharmsnap/shared/types/time'
import { getCustomTimeDisplayText, transformDateString2timestamp, transformTimestamp2DateString } from '@pharmsnap/shared/utils'
import { ComputedRef, PropType, computed, defineComponent, ref, watch } from '@vue/composition-api'
import { isEqual, isUndefined } from 'lodash'
import $style from './FilterItemTimeSelect.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const FilterItemTimeSelect = defineComponent({
  name: 'FilterItemTimeSelect',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemTimeConfig>,
    },
    filterConfigs: {
      type: Object as PropType<IFilterConfig<string>>,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()
    const { field, type, options } = props.config
    const { state, getters, actions } = userInjectFilterService()
    const isShowDialog = ref(false)
    const customTimeArr = ref<string[]>([])
    const currentValue = computed(() => state?.filterValueMapInTime[field])
    const currentStoreValue = ref<DATE_TYPE>((state?.filterValueArr.find((item) => item.field === field)?.value[0].value || options[0]) as DATE_TYPE)
    const isUtc = computed(() => (isUndefined(props.config.isUtc) ? true : props.config.isUtc))
    const dateTypeConfigMap = computed(() => getDateTypeConfigMap(isUtc.value))

    const currentDisplayText = computed(() => {
      let dateType: DATE_TYPE = 'ANY_TIME'
      if (currentValue.value?.length) {
        dateType = currentValue.value[0].value as DATE_TYPE
      }
      if (dateType === 'CUSTOM_DATE_RANGE') {
        return getCustomTimeDisplayText(customTimeArr.value)
      }
      const config = dateTypeConfigMap.value[dateType]
      return isCN.value ? config.display_name_cn : config.display_name_en
    })

    const timeSelectOptions = computed(() => {
      return props.config.options.map((item) => {
        const config = dateTypeConfigMap.value[item]
        return {
          label: isCN.value ? config.display_name_cn : config.display_name_en,
          dateType: item,
        }
      })
    })
    const existedValue: ComputedRef<IFilterValue[]> = computed(() => {
      const _currentValue = getters?.valueMap.value[field]
      return _currentValue || []
    })
    /**
     * 是否只配置了自定义时间渲染
     */
    const isOnlySupportCustomTime = computed(() => {
      return isEqual(props.config.options, ['CUSTOM_DATE_RANGE'])
    })
    /**
     * 是否是单选框格式
     */
    const isSelectTimeByRadio = computed(() => type === 'timeRadio')

    // watch 是为了回填数据
    watch(
      existedValue,
      (value) => {
        const customDateRangeTime = value.find((item) => item.value === 'CUSTOM_DATE_RANGE')
        if (customDateRangeTime?.from && customDateRangeTime.to) {
          // 若配置了format，需把时间转成string，不然dayjs转换会有问题
          customTimeArr.value = [customDateRangeTime.from, customDateRangeTime.to].map((time) =>
            transformTimestamp2DateString(props.config.format ? `${time}` : time, isUtc.value)
          )
        }
      },
      {
        immediate: true,
      }
    )
    /**
     * 设置自定义时间
     * @param data
     */
    function onChangeCustomTime(data: string[]) {
      customTimeArr.value = data
    }
    function hasRightsRefine() {
      if (props.filterConfigs?.onBeforeRefine) {
        const val = props.filterConfigs.onBeforeRefine([props.config], {
          isFreeUser: isFreeUser.value,
        })
        if (!val.valid) {
          val.upgrade && changeUpgrade({ ...val.upgrade, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL })
          return false
        }
      }
      return true
    }
    /**
     * 设置自定义时间自动refine
     * @param data
     */
    function onChangeCustomTimeAndRefine(data: string[]) {
      const hasRights = hasRightsRefine()
      if (!hasRights) return
      customTimeArr.value = data
      setCustomTimeFilterValueAndRefine()
    }
    /**
     * 设置自定义时间并重新检索
     */
    function setCustomTimeFilterValueAndRefine() {
      if (customTimeArr.value.length) {
        const [start, end] = customTimeArr.value
        const displayName = getCustomTimeDisplayText(customTimeArr.value)
        replaceFilterValueAndRefine([
          {
            display_name_cn: displayName,
            display_name_en: displayName,
            from: transformDateString2timestamp(start, false, 'day', isUtc.value, props.config.format),
            to: transformDateString2timestamp(end, true, 'day', isUtc.value, props.config.format),
            value: 'CUSTOM_DATE_RANGE',
            include_lower: true,
            // 时间戳维度，都是左闭右开，其他维度左闭右闭(同convertTimestamp2RangeQueryItem方法)
            include_upper: props.config.format ? true : false,
          },
        ])
      }
    }
    /**
     * 清空以前的所有的数据,设置新的数据,并检索
     * @param value
     */
    function replaceFilterValueAndRefine(value: IFilterValue[]) {
      actions?.removeAllFilterValueArrByFieldName(field)
      actions?.setFilterValue(field, value)
      actions?.refine(false)
    }
    function onConfirmCustomTime() {
      isShowDialog.value = false
      setCustomTimeFilterValueAndRefine()
    }
    function onChangeDateType(dateType: DATE_TYPE) {
      if (dateType === 'ANY_TIME') {
        replaceFilterValueAndRefine([])
        return
      }
      if (dateType === 'CUSTOM_DATE_RANGE') {
        isShowDialog.value = true
        return
      }
      const config = dateTypeConfigMap.value[dateType]
      replaceFilterValueAndRefine([
        {
          display_name_cn: config.display_name_cn,
          display_name_en: config.display_name_en,
          from: config.from,
          to: config.to,
          value: dateType,
        },
      ])
    }
    return {
      isShowDialog,
      currentDisplayText,
      customTimeArr,
      timeSelectOptions,
      currentValue,
      currentStoreValue,
      isSelectTimeByRadio,
      isOnlySupportCustomTime,
      onChangeDateType,
      onChangeCustomTimeAndRefine,
      onChangeCustomTime,
      onConfirmCustomTime,
    }
  },
  methods: {
    renderRadio() {
      return (
        <div>
          <GRadio
            value={this.currentStoreValue}
            onInput={(newValue: DATE_TYPE) => {
              if (newValue === 'CUSTOM_DATE_RANGE') {
                this.currentStoreValue = newValue
                return
              }
              this.onChangeDateType(newValue)
            }}
            class={$style.radio}
            inline={false}
            align={E_ALIGN_DIRECTION.VERTICAL}
            items={this.timeSelectOptions.map((item) => ({ value: item.dateType, label: item.label }))}
          />
          {this.renderDatePicker(this.currentStoreValue !== 'CUSTOM_DATE_RANGE')}
        </div>
      )
    },
    renderSelect() {
      return (
        <div class={$style.main}>
          <ElDropdown class="w-full" onCommand={this.onChangeDateType}>
            <span class="flex px-2 items-center">
              <span class="flex-1 truncate">{this.currentDisplayText}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <ElDropdownMenu style="width:265px" class="filter-time-range-dropdown" slot="dropdown">
              {this.timeSelectOptions.map((item) => {
                return <ElDropdownItem command={item.dateType}>{item.label}</ElDropdownItem>
              })}
            </ElDropdownMenu>
          </ElDropdown>
          <GDialog
            width="408px"
            title={this.$tc('FilterItemTime.SelectCustomDateRange')}
            confirmText={this.$tc('FilterItemTime.SetDateChange')}
            v-model={this.isShowDialog}
            onConfirm={this.onConfirmCustomTime}
          >
            <BDatePicker v-model={this.customTimeArr} onClick={this.onChangeCustomTime}></BDatePicker>
          </GDialog>
        </div>
      )
    },
    renderDatePicker(disabled = false) {
      return (
        <BDatePicker
          class="mt-2"
          width="275px"
          disabled={disabled}
          v-model={this.customTimeArr}
          onClick={this.onChangeCustomTimeAndRefine}
          clearable={false}
        ></BDatePicker>
      )
    },
  },
  render() {
    return <div>{this.isOnlySupportCustomTime ? this.renderDatePicker() : this.isSelectTimeByRadio ? this.renderRadio() : this.renderSelect()}</div>
  },
})
