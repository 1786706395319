import { XMLParser, XMLValidator } from 'fast-xml-parser'
import companyWhite from './default-company-white.svg'
import company from './default-company.svg'

export function isSvg(xmlData: string) {
  if (typeof xmlData !== 'string') {
    console.error('xmlData is no string', typeof xmlData)
    return false
  }

  if (xmlData.length === 0) {
    return false
  }

  // Has to be `!==` as it can also return an object with error info.
  if (XMLValidator.validate(xmlData) !== true) {
    return false
  }

  let jsonObject
  const parser = new XMLParser()

  try {
    jsonObject = parser.parse(xmlData)
  } catch {
    return false
  }

  if (!jsonObject) {
    return false
  }

  if (!('svg' in jsonObject)) {
    return false
  }

  return true
}

export function getBase64ByBlob(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export function getCacheStringSingle<T extends (s: string | undefined) => any>(fn: T): T {
  const map: Record<string | symbol, ReturnType<T>> = {}
  const symbolUndefined = Symbol('undefined')
  return ((s) => {
    const k = s || symbolUndefined
    return map[k] || (map[k] = fn(s))
  }) as T
}
function getBase64Img(url: string): Promise<string>
function getBase64Img(): Promise<undefined>
function getBase64Img(url?: string | undefined) {
  if (!url) return Promise.resolve()
  return fetch(url).then(async (res) => {
    const blob = await res.blob()
    if (!blob.size) return undefined
    let mimetype = (await res.headers.get('content-type')) || ''
    const isStream = mimetype === 'application/octet-stream'
    let base64 = await getBase64ByBlob(blob)
    if (isStream) {
      const text = await blob.text()
      const realSvg = isSvg(text)
      if (realSvg) {
        mimetype = 'image/svg+xml'
        base64 = base64.replace('application/octet-stream', 'image/svg+xml')
      }
    }
    return base64
  })
}

export const getLogo = getCacheStringSingle(getBase64Img)

export const getDefaultLogo = () => getLogo(company)

export const getDefaultWhiteLogo = () => getLogo(companyWhite)
