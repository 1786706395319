/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggStackLineChartEntity, AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { BStackBarTooltip } from '@pharmsnap/shared/components/business/BStackBarTooltip/BStackBarTooltip'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { ComponentProps } from '@pharmsnap/shared/types'
import { defineComponent, onMounted, PropType, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import { EChartsOption } from 'echarts'
import { cloneDeep } from 'lodash'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { IAnalysisDisplayIconType } from '../../type'
import { BAnalysisEChart } from './BAnalysisEchart'

export const BAnalysisStackLineEchart = defineComponent({
  name: 'BAnalysisStackLineEchart',
  props: {
    entity: {
      type: Object as PropType<AggStackLineChartEntity>,
      required: true,
    },
    displayType: {
      type: String as PropType<IAnalysisDisplayIconType>,
      default: 'multi-line',
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)

    const { locale } = useLocale()

    const { tooltipActiveData, tooltipCommonConfig, tooltipFormatter, tooltipRef, resetTooltipDataMap } = useAnalysisChartTooltip<{
      categoryName: ComponentProps<typeof BStackBarTooltip>['categoryName']
      items: ComponentProps<typeof BStackBarTooltip>['items']
    }>({
      pickTooltipDataFromChartParam,
    })

    const realOption = shallowRef<EChartsOption>()

    function updateRealOption() {
      const option = cloneDeep(unref(entity).getEchartsOptions())
      if (option) {
        option.tooltip = {
          show: true,
          enterable: true,
          hideDelay: 500,
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter: tooltipFormatter,
          ...tooltipCommonConfig,
        }
        option.animation = false
      }

      realOption.value = option
    }

    function pickTooltipDataFromChartParam(params: any) {
      if (!params) return
      if (!Array.isArray(params) || params.length === 0) return
      if (!params[0]) return
      const categoryName = params[0].axisValueLabel
      const items = params.map((i) => {
        const { x, count } = i.data as AggTwoDimDataItem

        return {
          color: i.color as string,
          count,
          name: x._meta.after,
          originalData: i.data,
        }
      })

      const data = {
        categoryName,
        items,
      }

      return [categoryName, data] as [string, typeof data]
    }

    function handleClickTooltipCount(data: AggTwoDimDataItem) {
      emit('clickItem', data)
    }

    function handleClickStackBarItem(params: any) {
      if (!params || !params.data) return
      emit('clickItem', params.data as AggTwoDimDataItem)
    }

    onMounted(() => updateRealOption())

    watch(entity, () => {
      resetTooltipDataMap()
      updateRealOption()
    })

    return {
      handleClickStackBarItem,
      handleClickTooltipCount,
      locale,
      realOption,
      tooltipRef,
      tooltipActiveData,
      ...useAnalysisEchartsHelpers(),
    }
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        option={this.realOption}
        displayType={this.displayType}
        axisPopperOption={{ xPopperEnable: false, yPopperEnable: false }}
        {...{
          on: {
            click: this.handleClickStackBarItem,
          },
        }}
      >
        <template slot="extra">
          <div ref="tooltipRef">
            <BStackBarTooltip
              countClickable={true}
              categoryName={this.tooltipActiveData?.categoryName || ''}
              items={this.tooltipActiveData?.items || []}
              onClick={this.handleClickTooltipCount}
            ></BStackBarTooltip>
          </div>
        </template>
      </BAnalysisEChart>
    )
  },
})
