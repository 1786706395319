import {
  BullsEyeChart,
  DEFAULT_ANGLE_REVERSED,
  DEFAULT_MARKER_COLOR,
  IBullsEyeChartConfig,
  IBullsEyeChartDataItem,
  IRelatedMarkerItem,
} from '@patsnap/synapse_bullseye_chart'
import { IAggregationData, ITwoDimensionAggValue } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending, toThousands } from '@patsnap/synapse_common_utils'
import { generateChartImage } from '@pharmsnap/pharmsnap-web/utils/generateChartImage'
import { BEntityFetchCard, useDrugFetchCard } from '@pharmsnap/shared/src/components'
import { useAuthStore, useElementSize, useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IBullsEyeChartAggOtherInfo, IBullsEyeChartItemWithOriginData, IQueryDataEntityType } from '@pharmsnap/shared/src/types'
import { getChartWaterMark, getDisplayNameDegraded, showSingleToast } from '@pharmsnap/shared/src/utils'
import { getBullsEyeChartOrderedPhase } from '@pharmsnap/shared/src/utils/business/bulls-eye-chart'
import { computed, defineComponent, onUnmounted, PropType, ref, toRefs, watch } from '@vue/composition-api'
import html2canvas from 'html2canvas'
import { max, min } from 'lodash'
import { BDrugBullsEyeChartCard } from '../../../card/BDrugCard/BDrugBullsEyeChartCard'
import { useAnalysisBullsEyeChart } from '../../compositions/useAnalysisBullsEyeChart'
import { useAnalysisBullsEyeChartTooltip } from '../../compositions/useAnalysisBullsEyeChartTooltip'

type IAnalysisType = Exclude<IQueryDataEntityType, 'drug' | 'mechanism'> | 'region'

export const BAnalysisBullsEyeChart = defineComponent({
  name: 'BAnalysisBullsEyeChart',
  props: {
    data: {
      required: true,
      type: Object as PropType<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>,
    },
    /** 图唯一id, 一个页面多张图时，必填 */
    uniqueId: {
      type: String,
      default: '',
    },
    innerCircleTitle: {
      type: String,
      default: '',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
    },
    chartMinHeight: {
      type: Number,
      default: 500,
    },
    selectedPhaseIds: {
      type: Array as PropType<string[]>,
    },
    /**
     * 分析实体
     */
    analysisEntity: {
      type: String as PropType<IAnalysisType>,
    },
  },
  setup(props, { emit }) {
    let bullsEyeChart: BullsEyeChart
    const { locale, localeUpcase, isCN } = useLocale()
    const {
      state: { userInfo },
      getters: { isBasicUser, isEnterpriseUser },
    } = useAuthStore()
    const { data } = toRefs(props)
    const { bullsEyeChartData, filteredBullsEyeChartData, filterItems, markerColor, legendData, handleFilter } = useAnalysisBullsEyeChart(data)
    const {
      hoverMarkerCountData,
      hoverMarker,
      hoverAngleAxisData,
      tooltipRef,
      hoverType,
      debounceMarkerCountMouseOver,
      handleMarkerCountMouseOut,
      debounceMarkerMouseOver,
      handleMarkerMouseOut,
      handleMouseOverTooltip,
      handleMouseOutTooltip,
      debounceAngleAxisMouseOver,
      handleAngleAxisMouseOut,
    } = useAnalysisBullsEyeChartTooltip()

    const drugId = computed(() => hoverMarker.value?.id || '')
    const { loading, data: drugData } = useDrugFetchCard(drugId)

    const analysisContainerRef = ref<HTMLDivElement>()
    const chartContainer = ref<HTMLDivElement>()
    const chartContainerWrapperRef = ref<HTMLDivElement>()
    const legendRef = ref<HTMLDivElement>()

    const { width, height } = useElementSize(chartContainerWrapperRef)

    const showLegend = computed(() => props.analysisEntity !== 'drug_type')

    const containerHeight = computed(() => {
      return height.value || max([chartContainer.value?.clientHeight, props.chartMinHeight])
    })

    const containerWidth = computed(() => width.value || chartContainer.value?.clientWidth)

    const chartConfig = computed<IBullsEyeChartConfig>(() => {
      const angleLabelLineLimit = 2
      const angleLabelFontSize = 12
      const angleLabelWidth = 120
      /** 上下/左右两侧文本占据空间 */
      const angleLabelNum = 2
      const watermarkConfig = isBasicUser.value || isEnterpriseUser.value ? undefined : getChartWaterMark(isCN.value)

      return {
        container: chartContainer.value as HTMLElement,
        width: containerWidth.value,
        height: containerHeight.value,
        uniqueId: props.uniqueId,
        reservedAngle: DEFAULT_ANGLE_REVERSED,
        // 减去角度坐标轴文本尺寸、间距，防止文本被盖住
        outerRadius:
          (min([
            (containerWidth.value || 0) - angleLabelWidth * angleLabelNum - 40,
            (containerHeight.value || 0) - angleLabelFontSize * angleLabelLineLimit * 2 - 48,
          ]) as number) / 2,
        innerCircle: {
          radius: 60,
          lineWidth: 2,
          stroke: '#5E9FE0',
          text: {
            content: props.innerCircleTitle,
            style: {
              lineLimit: 2,
              fontSize: 12,
              color: '#6AA8E5',
            },
          },
        },
        radiusAxisConfig: {
          fontStyle: {
            color: '#1D8820',
            fontSize: 12,
          },
          lineStyle: {
            color: '#5E9FE0',
            type: 'dashed',
          },
          fillColors: ['#FAFCFE', '#C3DCF4'],
          data: getBullsEyeChartOrderedPhase(props.selectedPhaseIds || [], localeUpcase.value),
        },
        angleAxisConfig: {
          fontStyle: {
            color: '#061632',
            width: angleLabelWidth,
            fontSize: angleLabelFontSize,
            lineLimit: angleLabelLineLimit,
          },
          lineStyle: {
            color: '#5E9FE0',
            type: 'dashed',
          },
          canClick: props.analysisEntity !== 'region',
        },
        markerConfig: {
          color: showLegend.value ? markerColor.value : DEFAULT_MARKER_COLOR,
          fontStyle: {
            color: '#020A1A',
            fontSize: 10,
            width: 150,
          },
        },
        watermarkConfig: watermarkConfig
          ? {
              image: watermarkConfig.waterMark,
              width: watermarkConfig.imgWidth,
              height: watermarkConfig.imgHeight,
            }
          : undefined,
      }
    })

    const initChart = () => {
      bullsEyeChart = new BullsEyeChart(filteredBullsEyeChartData.value, chartConfig.value)

      bullsEyeChart.render()

      bullsEyeChart.initMarkerLabel(props.showLabel)
      addChartEvent()
    }

    const handleAngelAxisClick = (params: { data: IBullsEyeChartDataItem }) => {
      const bullsEyeChartEntityPathMap: Record<Exclude<IAnalysisType, 'region'>, (id: string) => string> = {
        drug_type: sharedCtx.router.generatorDrugTypePath,
        disease: sharedCtx.router.generatorDiseasePath,
        target: sharedCtx.router.generatorTargetPath,
        organization: sharedCtx.router.generatorOrgPath,
      }
      if (props.analysisEntity && props.analysisEntity !== 'region') {
        const detailUrl = bullsEyeChartEntityPathMap[props.analysisEntity](params.data.angleAxisId as string)
        openPageWithoutPending(() => detailUrl)
      }
      emit('clickEntity', params.data.angleAxisId)
    }

    const addChartEvent = () => {
      if (bullsEyeChart) {
        bullsEyeChart.event.on('markerCount:click', (params: { data: IBullsEyeChartItemWithOriginData }) => {
          emit('clickCount', params.data)
        })
        bullsEyeChart.event.on('markerCount:mouseOver', debounceMarkerCountMouseOver)
        bullsEyeChart.event.on('markerCount:mouseOut', handleMarkerCountMouseOut)
        bullsEyeChart.event.on('marker:click', (params: { data: IRelatedMarkerItem }) => {
          emit('clickItem', params.data)
        })
        bullsEyeChart.event.on('marker:mouseOver', debounceMarkerMouseOver)
        bullsEyeChart.event.on('marker:mouseOut', handleMarkerMouseOut)
        bullsEyeChart.event.on('angleAxis:mouseOver', (params: { data: IBullsEyeChartDataItem; node: Element }) => {
          if (!props.analysisEntity || props.analysisEntity === 'region') {
            return
          }
          return debounceAngleAxisMouseOver(params)
        })
        bullsEyeChart.event.on('angleAxis:mouseOut', handleAngleAxisMouseOut)
        bullsEyeChart.event.on('angleAxis:click', handleAngelAxisClick)
      }
    }

    const handleDownload = async () => {
      const node = analysisContainerRef.value

      if (node) {
        try {
          const toast = showSingleToast({
            message: isCN.value ? '正导出中，请耐心等待' : 'Exporting, please wait patiently',
            type: 'info',
            duration: 0,
          })

          const bullsEyeChartWidth = document.querySelector(`.${bullsEyeChart?.getChartContainerCls()}`)?.getBoundingClientRect()?.width || 0
          const legendWidth = legendRef.value?.clientWidth || 0
          const analysisContainerWidth = analysisContainerRef.value?.clientWidth || 0
          const padding = 30

          const canvasWidth = max([bullsEyeChartWidth, legendWidth]) || undefined

          // 加个延迟，先显示toast提示，不然toast显示会延迟
          setTimeout(async () => {
            const canvas = await html2canvas(node, {
              useCORS: true,
              logging: false,
              imageTimeout: 0,
              scale: 1.5,
              x: canvasWidth ? (analysisContainerWidth - canvasWidth) / 2 - padding : undefined,
              width: canvasWidth ? canvasWidth + padding * 2 : undefined,
            })
            await generateChartImage({
              chartUrl: canvas.toDataURL('image/png'),
              width: canvas.width,
              height: canvas.height,
              name: `${props.innerCircleTitle}.png`,
              addWidth: 50,
              isCN: isCN.value,
              userInfo: { userId: userInfo.value?.user_id || '' },
            })
            toast.close()
          }, 100)
        } catch (error) {
          console.error(error)
        }
      }
    }

    const getMarkerColor = (colorId: string) => {
      if (showLegend.value) {
        return markerColor.value.find((colorItem) => colorItem.key === colorId)?.value || DEFAULT_MARKER_COLOR
      }
      return DEFAULT_MARKER_COLOR
    }

    watch([width, height], () => {
      if (bullsEyeChart) {
        bullsEyeChart.changeConfig(chartConfig.value)
        bullsEyeChart.initMarkerLabel(props.showLabel)
      } else {
        initChart()
      }
    })

    onUnmounted(() => bullsEyeChart?.event.off())

    watch(
      () => props.showLabel,
      () => {
        if (bullsEyeChart) {
          bullsEyeChart.setMarkerLabelShow(props.showLabel)
        }
      }
    )

    watch(filteredBullsEyeChartData, () => {
      if (bullsEyeChart) {
        bullsEyeChart.changeData(filteredBullsEyeChartData.value, chartConfig.value)
        bullsEyeChart.initMarkerLabel(props.showLabel)
      }
    })

    return {
      loading,
      drugData,
      locale,
      legendRef,
      chartContainer,
      chartContainerWrapperRef,
      analysisContainerRef,
      filterItems,
      bullsEyeChartData,
      legendData,
      markerColor,
      hoverMarkerCountData,
      hoverMarker,
      hoverAngleAxisData,
      hoverType,
      tooltipRef,
      showLegend,
      handleMouseOverTooltip,
      handleMouseOutTooltip,
      handleFilter,
      getMarkerColor,
      handleDownload,
      height,
      width,
    }
  },
  methods: {
    renderChart() {
      return <div ref="chartContainer" class="w-full h-full"></div>
    },
    renderOneDimLegend() {
      return (
        <div class="max-w-full inline-flex justify-start" ref="legendRef">
          {this.legendData.map((item, index) => {
            const color = this.getMarkerColor(item.id)
            const isFiltered = this.filterItems.includes(item.id)
            const backgroundColor = isFiltered ? '#BCC2CC' : color
            return (
              <div
                class={['overflow-hidden flex justify-start items-center cursor-pointer', index < this.legendData.length - 1 ? 'mr-3' : '']}
                onClick={() => this.handleFilter(item.id)}
              >
                <div class="w-[14px] h-[14px] flex-shrink-0 rounded mr-2" style={{ backgroundColor }}></div>
                <div class={['flex-1 text-xs truncate leading-[18px]', isFiltered ? 'text-gray-55' : ' text-text-default']}>
                  {getDisplayNameDegraded(item, this.locale)}
                </div>
              </div>
            )
          })}
        </div>
      )
    },
    renderMarkerCountTooltip() {
      if (this.hoverMarkerCountData) {
        return (
          <div class="px-4 py-2 max-w-[180px]">
            <div class="text-black-default text-sm font-semibold leading-5">
              {this.hoverMarkerCountData.radiusAxisName}(
              <span class="text-blue-default hover:underline cursor-pointer" onClick={() => this.$emit('clickCount', this.hoverMarkerCountData)}>
                {toThousands(this.hoverMarkerCountData.count)}
              </span>{' '}
              {this.$t('common.drug')})
            </div>
            {this.showLegend && (
              <div class="mt-[10px]">
                {this.hoverMarkerCountData.other_info?.stat_info.drug_type.map((item) => {
                  const color = this.getMarkerColor(item.id)
                  return (
                    <div class="flex items-center mb-1 leading-4 text-xs overflow-hidden text-black-default">
                      <div
                        class="flex-shrink-0"
                        style={{ background: color, width: '10px', height: '10px', 'border-radius': '5px', 'margin-right': '4px' }}
                      ></div>
                      <span class="truncate">{getDisplayNameDegraded(item, this.locale)}</span>:
                      <span
                        class="cursor-pointer ml-[2px] flex-shrink-0 text-blue-default hover:underline"
                        onClick={() => this.$emit('clickCount', this.hoverMarkerCountData, item.id)}
                      >
                        {toThousands(item.count)}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      }
      return null
    },
    renderAngleAxisTooltip() {
      if (this.analysisEntity === 'region') return null

      if (this.hoverAngleAxisData && this.analysisEntity) {
        if (this.analysisEntity === 'drug_type') {
          return <div class="px-4 py-2 text-text-default text-xs">{this.hoverAngleAxisData.angleAxisName}</div>
        }

        return <BEntityFetchCard id={this.hoverAngleAxisData.angleAxisId as string} type={this.analysisEntity}></BEntityFetchCard>
      }

      return null
    },
    renderMarkerTooltip() {
      if (this.hoverMarker) {
        const color = this.getMarkerColor(this.hoverMarker?.colorItem?.id || '')
        return (
          <BDrugBullsEyeChartCard class="w-[300px] min-h-[200px]" data={this.drugData} loading={this.loading}>
            <div
              slot="prefix"
              style={{ background: color, width: '10px', height: '10px', 'border-radius': '5px', 'margin-top': '4px', 'margin-right': '4px' }}
            ></div>
          </BDrugBullsEyeChartCard>
        )
      }
      return null
    },
    renderTooltip() {
      return (
        <div
          style={{
            'border-radius': '4px',
            'box-shadow': '0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 3px 8px 0px rgba(0, 0, 0, 0.14)',
            background: '#fff',
          }}
          ref="tooltipRef"
          onMouseenter={this.handleMouseOverTooltip}
          onMouseleave={this.handleMouseOutTooltip}
        >
          {this.hoverType === 'marker' && this.renderMarkerTooltip()}
          {this.hoverType === 'markerCount' && this.renderMarkerCountTooltip()}
          {this.hoverType === 'angleAxis' && this.renderAngleAxisTooltip()}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={['flex flex-col w-full h-full']} ref="analysisContainerRef">
        {this.showLegend && <div class="pt-3 flex justify-center">{this.renderOneDimLegend()}</div>}
        <div class="flex-1 w-full h-full relative overflow-hidden" ref="chartContainerWrapperRef">
          {this.renderChart()}
        </div>
        <div class="hidden">{this.renderTooltip()}</div>
      </div>
    )
  },
})
