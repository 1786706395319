import '@patsnap-ui/icon/assets/solid/SynapseDisease.svg'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDiseaseInfo, ILang } from '@pharmsnap/shared/types'
import { pickFirstSpecialLangName } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BDiseasePopperCard, BEntityTag, GIcon, GLink } from '../..'

export const BDiseaseItem = defineComponent({
  name: 'BDiseaseItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseDiseaseInfo>,
    },
    drug_id: {
      type: String,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const href = computed(() => sharedCtx.router.generatorDiseasePath(data?.value?.disease_id || ''))
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const name = computed(() => pickFirstSpecialLangName(data?.value?.disease_name || [], locale.value, 'EN'))

    return {
      locale,
      href,
      name,
    }
  },
  render() {
    const id = this.data.disease_id
    const content = (
      <BEntityTag data-testid="b-disease-item" title={this.name}>
        {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="SolidSynapseDisease" /> : null}
        <GLink data-selection-disabled={this.popover} href={this.href} name={this.name}></GLink>
      </BEntityTag>
    )
    if (id && this.popover) {
      return (
        <BDiseasePopperCard id={id} drug_id={this.drug_id}>
          {content}
        </BDiseasePopperCard>
      )
    }
    return content
  },
})
