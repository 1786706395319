import initialize, { interceptAxios as interceptAxiosWithToken } from '@patsnap-utils/passport'
import { getSingle } from '@patsnap/synapse_common_utils'
import {
  VUE_APP_ACCOUNT_BASEURL,
  VUE_APP_ACCOUNT_DOMAIN_URL,
  VUE_APP_ANALYTICS_URL,
  VUE_APP_API_URL,
  VUE_APP_CLIENT_ID,
  VUE_APP_CLIENT_NAME,
  VUE_APP_DEPLOY_REGION,
  VUE_APP_PASSPORT_BASEURL,
  VUE_APP_PRODUCT_CHEMICAL_URL,
  VUE_APP_STRAPI_URL,
  VUE_APP_WECHAT_BASEURL,
} from '@pharmsnap/pharmsnap-web/config'
import router from '@pharmsnap/pharmsnap-web/router'
import { E_ROUTER_NAME, E_ROUTER_PARAMS, E_ROUTER_QUERY } from '@pharmsnap/pharmsnap-web/types/enum'
import { initCustomerServiceSdk } from '@pharmsnap/pharmsnap-web/utils/auth'
import { loginUnited } from '@pharmsnap/pharmsnap-web/utils/business/auth'
import { IWebTrackingEvent } from '@pharmsnap/shared/types'
import { IUserInfo } from '@pharmsnap/shared/types/login'
import axios from 'axios'
import { buildWebStorage, setupCache } from 'axios-cache-interceptor'
import { get, throttle } from 'lodash'
import { ICommonListParams, sharedCtx } from 'pharmsnapMF_shared/context'
import { createService } from 'pharmsnapMF_shared/service'
import { showSingleToast } from 'pharmsnapMF_shared/utils'
import qs from 'qs'
import { initDevTools } from '../features/dev-tool'
import { deBuggerConfig } from '../features/dev-tool/config'
import { initClarity } from '../module/clarity/clarity'
import { getLocaleLang } from '../utils/business/locale'
import { ERROR_CODE_MAP } from './error-code-config'
import { interceptAxiosWithAuth } from './interceptAxiosWithAuth'
import { interceptAxiosWithBiz } from './interceptAxiosWithBiz'
import { interceptAxiosWithChatRecaptcha } from './interceptAxiosWithChatRecaptcha'
import { interceptAxiosWithDynamicFont } from './interceptAxiosWithDynamicFont'
import { interceptAxiosWithMock } from './interceptAxiosWithMock'
import { interceptAxiosWithSign } from './interceptAxiosWithSign'
import { interceptAxiosWithTracking } from './interceptAxiosWithTracking'

const locale = getLocaleLang()
export const getAxios = getSingle(() => {
  const _axios = axios.create({
    timeout: 3 * 60 * 1000,
  })
  const refreshAxios = axios.create({
    timeout: 3 * 60 * 1000,
  })
  // 必须优先注册这个拦截器
  interceptAxiosWithBiz(_axios)
  interceptAxiosWithMock(_axios)
  interceptAxiosWithAuth(_axios)
  interceptAxiosWithToken(_axios)
  interceptAxiosWithToken(refreshAxios)
  interceptAxiosWithSign(_axios)
  interceptAxiosWithSign(refreshAxios)
  interceptAxiosWithDynamicFont(_axios)
  interceptAxiosWithTracking(_axios)
  interceptAxiosWithChatRecaptcha({
    axios: _axios,
    refreshAxios,
  })
  if (deBuggerConfig.isOpenCache) {
    setupCache(_axios, {
      methods: ['get', 'post'],
      storage: buildWebStorage(localStorage, 'axios-cache:'),
    })
  }
  return _axios
})

export const getService = getSingle(() => {
  return createService({
    axios: getAxios(),
    baseUrl: `${VUE_APP_API_URL}/`,
    passportBaseUrl: `${VUE_APP_PASSPORT_BASEURL}/`,
    accountBaseUrl: `${VUE_APP_ACCOUNT_BASEURL}/`,
    weChatBaseUrl: `${VUE_APP_WECHAT_BASEURL}/`,
    analysisBaseUrl: `${VUE_APP_ANALYTICS_URL}/`,
    strapiBaseUrl: `${VUE_APP_STRAPI_URL}/`,
    chemicalBaseUrl: `${VUE_APP_PRODUCT_CHEMICAL_URL}/`,
  })
})
const showTokenExpiredMessage = throttle(
  () => {
    showSingleToast({ message: locale === 'en' ? 'You session has expired. Please login again.' : '您的会话已经过期。请重新登录', type: 'error' })
  },
  5000,
  {
    trailing: false,
  }
)
/**
 * 初始化登录服务
 * @returns passport对象
 */
export function initPassport() {
  return initialize({
    baseAuthServer: VUE_APP_PASSPORT_BASEURL,
    clientId: VUE_APP_CLIENT_ID,
    clientName: VUE_APP_CLIENT_NAME,
    redirectToLogin() {
      loginUnited(window.location.href)
    },
    onTokenRefresh() {
      console.log('onTokenRefresh')
    },
    detectResponseAsUnauthorized(error) {
      if (error.response?.status === 401) {
        const numericErrorCode = error?.response?.data?.numeric_error_code
        // No product permission
        if (numericErrorCode === ERROR_CODE_MAP.NO_PERMISSION_CODE_100063) {
          // redirect to login
          // 跳转没有权限页面
          showSingleToast({ message: locale === 'en' ? 'You do not have permission for this product' : '您没有此产品权限', type: 'error' })
          // router.replace({ name: E_ROUTER_NAME.NO_PERMISSION })
          return false
        }

        return true
      }
      return false
    },
    // core那边要求添加逻辑,主要是来限制多账号并发场景
    // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=133944490
    onPaymentRequired(err: any) {
      const urlParams = {
        from: VUE_APP_CLIENT_NAME,
        client_id: VUE_APP_CLIENT_ID,
        redirect_uri: encodeURIComponent(window.location.href),
        errorCode: get(err, 'errcode'),
      }
      const url = `${VUE_APP_ACCOUNT_DOMAIN_URL}/user-settings/#/upper-limit${qs.stringify(urlParams, { addQueryPrefix: true })}`
      window.location.replace(url)
    },
  })
}
/**
 * 初始化网站的的一些工具
 */
export function initTools(userInfo?: IUserInfo) {
  if (userInfo?.email && userInfo.email.endsWith('patsnap.com')) {
    initDevTools()
  }
  if (deBuggerConfig.isDebuggerModel) {
    return
  }
  setTimeout(() => {
    initCustomerServiceSdk(userInfo)
    initClarity(userInfo)
  }, 0)
}
function transformCommonListQuery(params: ICommonListParams) {
  const query: Record<string, string> = {
    [E_ROUTER_QUERY.QUERY_ID]: params.queryId,
  }
  if (params.queryType) {
    query[E_ROUTER_QUERY.QUERY_TYPE] = params.queryType
  }
  if (params.fromQueryId) {
    query[E_ROUTER_QUERY.FROM_QUERY_ID] = params.fromQueryId
  }
  if (params.tracking) {
    query[E_ROUTER_QUERY.TRACKING] = JSON.stringify(params.tracking)
  }
  return query
}
/**
 * 设置网站的全局上下文
 */
export function setAppContext() {
  sharedCtx.setContext({
    service: getService(),
    deployRegion: VUE_APP_DEPLOY_REGION,
    router: {
      generatorTargetPath(id) {
        if (id.includes('_')) id = id.replaceAll('_', ',')
        return router.resolve({
          name: E_ROUTER_NAME.TARGET,
          params: { [E_ROUTER_PARAMS.TARGET_ID]: id },
        }).href
      },
      generatorDiseasePath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.DISEASE,
          params: { [E_ROUTER_PARAMS.DISEASE_ID]: id },
        }).href
      },
      generatorDrugTypePath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_TYPE,
          params: { [E_ROUTER_PARAMS.DRUG_TYPE_ID]: id },
        }).href
      },
      generatorDrugPath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG,
          params: { [E_ROUTER_PARAMS.DRUG_ID]: id },
        }).href
      },
      generatorOrgPath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.ORGANIZATION,
          params: { [E_ROUTER_PARAMS.ORG_ID]: id },
        }).href
      },
      generatorClinicalPath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_PROGRESS_DETAIL,
          params: { [E_ROUTER_PARAMS.CLINICAL_TRIAL_ID]: id },
        }).href
      },
      generatorClinicalResultDetailPath(id, activeMenu) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_RESULT_DETAIL,
          params: { [E_ROUTER_PARAMS.CLINICAL_RESULT_ID]: id },
          query: { [E_ROUTER_QUERY.ACTIVE_MENU]: activeMenu },
        }).href
      },
      generatorDrugListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorDrugListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugListPathGroupByOrg(queryId, fromQueryId) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_ORG,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
          },
        }).href
      },
      generatorDrugListPathGroupByOrgV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_ORG,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugListPathGroupByIndication(queryId, fromQueryId) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DISEASE,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
          },
        }).href
      },
      generatorDrugListPathGroupByIndicationV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DISEASE,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugListPathGroupByDrugType(queryId, fromQueryId) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DRUG_TYPE,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
          },
        }).href
      },
      generatorDrugListPathGroupByDrugTypeV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DRUG_TYPE,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugListPathGroupByDevStatus(queryId, fromQueryId, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DEV_STATUS,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorDrugListPathGroupByDevStatusV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_DEV_STATUS,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugListPathGroupByTarget(queryId, fromQueryId) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_TARGET,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
          },
        }).href
      },
      generatorDrugListPathGroupByTargetV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_GROUP_BY_TARGET,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorClinicalListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_PROGRESS_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorClinicalListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_PROGRESS_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorClinicalResultListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_RESULT_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorClinicalResultListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.CLINICAL_RESULT_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorPatentListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.PATENT_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorPatentListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.PATENT_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorPatentDetailPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.PATENT_DETAIL,
          query: {
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
          params: { [E_ROUTER_PARAMS.PATENT_ID]: id },
        }).href
      },
      generatorNewsListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.LATEST_NEWS_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorHomePath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.HOME_SEARCH_RESULT,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorQuickHomePath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.HOME_QUICK_SEARCH_RESULT,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorNewsDetailPath(id, offset, sourceType, source, sort) {
        const query =
          typeof offset === 'number' && sourceType && source && sort
            ? {
                [E_ROUTER_QUERY.OFFSET]: offset.toString(),
                [E_ROUTER_QUERY.SOURCE_TYPE]: sourceType,
                [E_ROUTER_QUERY.SOURCE]: source,
                [E_ROUTER_QUERY.SORT]: sort,
              }
            : {}
        return router.resolve({
          name: E_ROUTER_NAME.LATEST_NEWS_DETAIL,
          params: { [E_ROUTER_PARAMS.LATEST_NEWS_ID]: id },
          query,
        }).href
      },
      generatorPaperListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.LITERATURE_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorTranslationalMedicineListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorTranslationalMedicineListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorPaperDetailPath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.LITERATURE_DETAIL,
          params: { [E_ROUTER_PARAMS.LITERATURE_ID]: id },
        }).href
      },
      generateUserSetting() {
        return router.resolve({
          name: E_ROUTER_NAME.PERSONAL_INFO,
        }).href
      },
      generatorCompareDrugPath(ids?: string) {
        return router.resolve({
          name: E_ROUTER_NAME.COMPARE_DRUG,
          query: { [E_ROUTER_QUERY.COMPARE_DATA]: ids },
        }).href
      },
      generatorCompareOrganizationPath(ids?: string) {
        return router.resolve({
          name: E_ROUTER_NAME.COMPARE_ORGANIZATION,
          query: { [E_ROUTER_QUERY.COMPARE_DATA]: ids },
        }).href
      },
      generatorCompareTargetPath(ids?: string) {
        return router.resolve({
          name: E_ROUTER_NAME.COMPARE_TARGET,
          query: { [E_ROUTER_QUERY.COMPARE_DATA]: ids },
        }).href
      },
      generatorCompareIndicationPath(ids?: string) {
        return router.resolve({
          name: E_ROUTER_NAME.COMPARE_INDICATION,
          query: { [E_ROUTER_QUERY.COMPARE_DATA]: ids },
        }).href
      },
      generatorReportDetailPath(id: string) {
        return router.resolve({
          name: E_ROUTER_NAME.REPORT_DETAIL,
          params: { [E_ROUTER_PARAMS.REPORT_ID]: id },
        }).href
      },
      generatorReportPreviewPath(id: string) {
        return router.resolve({
          name: E_ROUTER_NAME.REPORT_PREVIEW,
          params: { [E_ROUTER_PARAMS.REPORT_ID]: id },
        }).href
      },
      generatorKgRelationshipPath(id: string) {
        return router.resolve({
          name: E_ROUTER_NAME.KNOWLEDGE_GRAPH,
          params: { [E_ROUTER_PARAMS.KNOWLEDGE_GRAPH_ID]: id },
        }).href
      },
      generatorDrugDealListPath(id, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_DEAL_LIST,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: id,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorDrugDealListPathV2(params) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_DEAL_LIST,
          query: transformCommonListQuery(params),
        }).href
      },
      generatorDrugDealListPathGroupByDrug(queryId, fromQueryId, tracking?: IWebTrackingEvent[]) {
        return router.resolve({
          name: E_ROUTER_NAME.DRUG_DEAL_GROUP_BY_DRUG,
          query: {
            [E_ROUTER_QUERY.QUERY_ID]: queryId,
            [E_ROUTER_QUERY.FROM_QUERY_ID]: fromQueryId,
            ...(tracking
              ? {
                  [E_ROUTER_QUERY.TRACKING]: JSON.stringify(tracking),
                }
              : {}),
          },
        }).href
      },
      generatorEmailAlertDetailPath(id) {
        return router.resolve({
          name: E_ROUTER_NAME.EMAIL_ALERT_DETAIL,
          params: { [E_ROUTER_PARAMS.EMAIL_ALERT_ID]: id },
        }).href
      },
      generatorSearchHistoryPath(type: string) {
        return router.resolve({
          name: E_ROUTER_NAME.SEARCH_HISTORY,
          query: {
            [E_ROUTER_QUERY.SEARCH_HISTORY_LIST_TYPE]: type,
          },
        }).href
      },
    },
    VUE_APP_ANALYTICS_URL,
  })
}
