import { PropType } from '@vue/composition-api'
export type IGLinkColor = 'blue' | 'dark' | 'green'
export const GLinkProps = {
  href: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  newTab: {
    type: Boolean,
    default: true,
  },
  defaultColor: {
    type: String as PropType<IGLinkColor>,
    default: 'dark',
  },
  truncate: {
    type: Boolean,
    default: true,
  },
  encryptClass: {
    type: String,
  },
  customClass: {
    type: String,
  },
}

export const GIconProps = {
  src: {
    type: String,
  },
  fallbackSrc: {
    type: String,
  },
  border: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 16,
  },
  shape: {
    type: String as PropType<'circle' | 'square'>,
    default: 'square',
  },
  alt: {
    type: String,
    default: 'icon',
  },
  svgName: {
    type: String,
  },
  useSvgDefaultColor: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
  },
}

export const GDrawerLayoutProps = {
  expanded: {
    type: Boolean,
    default: true,
  },
  maxWidth: {
    type: Number,
    default: 160,
  },
  minWidth: {
    type: Number,
    default: 36,
  },
  triggerTop: {
    type: Number,
    default: 16,
  },
  showSide: {
    type: Boolean,
    default: true,
  },
  drawerPosition: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
  drawerBngColor: {
    type: String,
    default: '#F4F5F7',
  },
}

export const GBlockProps = {
  title: {
    type: String,
    default: '',
  },
  tip: {
    type: String,
    default: '',
  },
  desc: {
    type: String,
    default: '',
  },
  titleCls: {
    type: String,
    default: '',
  },
  descCls: {
    type: String,
    default: '',
  },
  contentCls: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isEmpty: {
    type: Boolean,
    default: false,
  },
}
