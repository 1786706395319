import SaveToWorkspace from '@patsnap-biz/save-to-workspace-v2'
import { defineComponent, getCurrentInstance, onUnmounted, ref } from '@vue/composition-api'
import { workspaceComponentProps } from '../../type'
import $classes from '../Workspace.module.scss'
import SaveDrugService from './SaveDrugService'

const { SaveDrugToWorkspace } = SaveToWorkspace
export const DrugWorkspace = defineComponent({
  name: 'DrugWorkspace',
  props: workspaceComponentProps(),
  setup(props, ctx) {
    const instance = getCurrentInstance()

    const DrugService = ref()

    DrugService.value = new SaveDrugService({ vm: instance?.proxy, ...props })

    const close = () => {
      DrugService.value = null
    }

    onUnmounted(close)

    return {
      DrugService,
    }
  },
  render() {
    if (this.DrugService) {
      return <SaveDrugToWorkspace service={this.DrugService} append-to-body class={$classes.workspace}></SaveDrugToWorkspace>
    }
    return <span></span>
  },
})
