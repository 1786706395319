/* eslint-disable @typescript-eslint/no-explicit-any */
import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import Vue from 'vue'
import { ITrackingConfig, ITrackingEventAll, ITrackingEventWithOption, ITrackingEventWithOutOption } from './type'
export async function trackingEvent<T extends ITrackingEventWithOption>(event: T, options: ITrackingConfig[T]): Promise<any>
export async function trackingEvent<T extends ITrackingEventWithOutOption>(event: T, options?: ITrackingConfig[T]): Promise<any>
export async function trackingEvent<T extends ITrackingEventAll>(event: T, options?: ITrackingConfig[T]): Promise<any> {
  trackingEvent.$bus.$emit(event, options)
  return getService().tracking.trackGeneral({
    event_code: event,
    ...(options || {}),
  })
}
trackingEvent.$bus = new Vue()
