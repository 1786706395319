export const TRACKING_QUERY_KEY = 'tracking'
export const TRACKING_DATA_SESSION_KEY = 'synapse_tracking'
import { ITrackData } from '@patsnap/synapse_common_interface'
import { getSingle } from '@patsnap/synapse_common_utils'
import { ITriggerPoint, IWebTrackingEvent } from '@pharmsnap/shared/types'

class TrackingEventSource {
  /**
   * 手动调用接口，需要埋trigger_pointer的，调用此方法
   * @param val
   */
  public setTrackingSessionStorage(val: IWebTrackingEvent[]) {
    window.sessionStorage.setItem(TRACKING_DATA_SESSION_KEY, JSON.stringify(val))
  }

  /**
   * 把路由上的tracking的值存下来，并从路由上删掉
   * @returns
   */
  public storeTrackingEventSourceFromRouter(trackingInfoStr: string) {
    window.sessionStorage.setItem(TRACKING_DATA_SESSION_KEY, trackingInfoStr)
  }

  /**
   * 通过接口上配置的trackingName消费者来获取session里面的值
   * @param consumerName
   * @returns
   */
  public getTrackingEventSourceByConsumer(consumerName: string): ITrackData<ITriggerPoint> | null {
    const trackingInfo = this.getTrackingDataFromSessionStorage()
    if (!trackingInfo?.length) {
      return null
    }
    const findIndex = trackingInfo.findIndex((item) => item.consumer === consumerName)
    if (findIndex < 0) {
      return null
    }
    const tackingData = trackingInfo[findIndex].tracking_data
    trackingInfo.splice(findIndex, 1)
    if (trackingInfo.length) {
      window.sessionStorage.setItem(TRACKING_DATA_SESSION_KEY, JSON.stringify(trackingInfo))
    } else {
      window.sessionStorage.removeItem(TRACKING_DATA_SESSION_KEY)
    }
    return tackingData
  }

  private getTrackingDataFromSessionStorage(): IWebTrackingEvent[] | null {
    const trackingInfoStr = window.sessionStorage.getItem(TRACKING_DATA_SESSION_KEY)
    if (!trackingInfoStr) {
      return null
    }
    try {
      return JSON.parse(trackingInfoStr)
    } catch (error) {
      return null
    }
  }
}
export const trackingEventSource = getSingle(() => new TrackingEventSource())
