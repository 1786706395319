import { VueConstructor } from 'vue'
import XEUtils from 'xe-utils'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import './cover-vex.scss'
import {
  // 核心
  VXETable,

  // 功能模块
  // Icon,
  // Filter,
  // Menu,
  // Edit,
  // Export,
  // Keyboard,
  // Validator,
  Header,
  // Footer,

  // 可选组件
  Column,
  // Colgroup,
  Grid,
  Toolbar,
  // Pager,
  // Checkbox,
  // CheckboxGroup,
  // Radio,
  // RadioGroup,
  // RadioButton,
  // Input,
  // Textarea,
  // Button,
  // Modal,
  // Tooltip,
  // Form,
  // FormItem,
  // FormGather,
  // Select,
  // Optgroup,
  // Option,
  // Switch,
  // List,
  // Pulldown,

  // 表格
  Table,
} from 'vxe-table'

export function useVxeTable(vue: VueConstructor) {
  vue.use(Header).use(Column).use(Table).use(Grid).use(Toolbar)
  VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
  })
}
