import { GCountryFlag } from '@pharmsnap/shared/components/ui/GCountryFlag/GCountryFlag'
import { GDropdownSelect } from '@pharmsnap/shared/components/ui/GDropdownSelect/GDropdownSelect'
import { GTooltip } from '@pharmsnap/shared/components/ui/GTooltip/GTooltip'
import { useLocale } from '@pharmsnap/shared/composition'
import { IAssigneeCountryItem } from '@pharmsnap/shared/types'
import { pickDisplayNameByLocale } from '@pharmsnap/shared/utils'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import cn from '../../../../locales/cn.json'
import en from '../../../../locales/en.json'
import $classes from './AssigneesCountry.module.scss'

export const AssigneesCountry = defineComponent({
  name: 'AssigneesCountry',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    isFullScreen: {
      type: Boolean,
    },
    value: {
      required: true,
      type: Array as PropType<string[]>,
    },
    options: {
      required: true,
      type: Array as PropType<IAssigneeCountryItem[]>,
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()

    const selectedRegion1 = ref('')
    const selectedRegion2 = ref('')

    const handleApply = () => emit('applyCountry', [selectedRegion1.value, selectedRegion2.value])
    const handleCancel = () => emit('cancel')

    watch(
      () => props.value,
      (value) => {
        const [region1, region2] = value
        selectedRegion1.value = region1
        selectedRegion2.value = region2
      },
      {
        immediate: true,
      }
    )

    return {
      locale,
      selectedRegion1,
      selectedRegion2,
      handleApply,
      handleCancel,
    }
  },
  methods: {
    renderButton() {
      if (this.isFullScreen) {
        return (
          <button class="pt-ui-btn mt-6 w-full" onClick={this.handleApply} type="submit">
            {this.$tc('apply')}
          </button>
        )
      }
      return (
        <div class={$classes.buttons}>
          <button class="pt-ui-btn mr-2" onClick={this.handleCancel} type="default" data-size="small">
            {this.$tc('common.cancel')}
          </button>
          <button class="pt-ui-btn" onClick={this.handleApply} type="submit" data-size="small">
            {this.$tc('apply')}
          </button>
        </div>
      )
    },
    renderRegionItem(code: string) {
      return (
        <div class="truncate" style="max-width: 154px">
          <GCountryFlag name={code} showName useFullName></GCountryFlag>
        </div>
      )
    },
    renderRegion(params: { options: IAssigneeCountryItem[]; valueKey: string; title?: string }) {
      const { options, valueKey, title } = params
      const formatOptions = options.map((item) => ({ ...item, value: item.id, label: pickDisplayNameByLocale(item, this.locale) }))
      return (
        <div class={$classes.region}>
          {title && <div class={$classes.regionTitle}>{title}</div>}
          <GDropdownSelect
            key={valueKey}
            value={this[valueKey]}
            onInput={(value: string) => (this[valueKey] = value)}
            options={formatOptions}
            appendToBody
            itemKey="id"
            height="200px"
            width="100%"
            pickDisplayLabel={(item) => {
              const { value } = item
              const selectedCountry = this.options.find((data) => data.id === value)
              return selectedCountry?.code as string
            }}
            scopedSlots={{
              item: (params: { item: IAssigneeCountryItem }) => this.renderRegionItem(params.item.code),
              dropdownContent: (params: { displayLabel: string }) => this.renderRegionItem(params.displayLabel),
            }}
          ></GDropdownSelect>
        </div>
      )
    },
    renderRegion1() {
      const options = this.options.filter((country) => country.id !== this.selectedRegion2)
      const regionTitle = this.$tc('topAssignees.region1')
      return this.renderRegion({ options, valueKey: 'selectedRegion1', title: regionTitle })
    },
    renderRegion2() {
      const options = this.options.filter((country) => country.id !== this.selectedRegion1)
      const regionTitle = this.$tc('topAssignees.region2')
      return this.renderRegion({ options, valueKey: 'selectedRegion2', title: regionTitle })
    },
  },
  render() {
    return (
      <div>
        <div class={$classes.title}>
          <span>{this.$t('topAssignees.assigneesRegion')}</span>
          <GTooltip content={this.$tc('topAssignees.assigneesRegionTip')} mode="icon"></GTooltip>
        </div>
        {this.renderRegion1()}
        {this.renderRegion2()}
        {this.renderButton()}
      </div>
    )
  },
})
