import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { deBuggerConfig } from '../dev-tool/config'
const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export async function initDevtoolsDetector(onFinished?: () => void) {
  if (!IS_PRODUCTION) {
    return
  }
  if (deBuggerConfig.isDebuggerModel) {
    return
  }
  const { addListener, launch } = await import('devtools-detector')
  addListener((isOpen) => {
    if (isOpen) {
      trackingEvent('OPEN_DEV_TOOL')
    }
  })
  launch()
  onFinished?.()
}
