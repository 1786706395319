import type { IAggregationData } from '@patsnap/synapse_common_interface'
import {
  ICompareFilterAggregationParams,
  ICompareMoreEntity,
  ICompareMoreEntityParams,
  ICompareResult,
  IDiseaseCompareData,
  IDiseaseCompareParams,
  IDrugCompareData,
  IDrugCompareParams,
  IFetchTargetCompareMechanisms,
  IMechanismData,
  IOrgCompareData,
  IOrgCompareParams,
  ISearchResult,
  ITargetCompareData,
  ITargetCompareParams,
} from '@pharmsnap/shared/types'
import { decryptDicItem, decryptMechanism, decryptNum, decryptTarget } from '@pharmsnap/shared/utils'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformDrugAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/drug'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'
export class CompareApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getOrganizationCompareData(params: IOrgCompareParams): Promise<IHttpWrap<ICompareResult<IOrgCompareData[]>>> {
    const res: IHttpWrap<ICompareResult<IOrgCompareData[]>> = await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/organization`),
      data: params,
      cache: true,
    })
    return res
  }

  async getTargetCompareData(params: ITargetCompareParams): Promise<IHttpWrap<ICompareResult<ITargetCompareData[]>>> {
    const res: IHttpWrap<ICompareResult<ITargetCompareData[]>> = await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/target`),
      data: params,
      cache: true,
    })
    if (res.success) {
      res.data.items.forEach((item) => {
        item.global_highest_phase_id_view && decryptDicItem(item.global_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
        item.cn_highest_phase_id_view && decryptDicItem(item.cn_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
      })
    }
    return res
  }

  async getDrugCompareData(params: IDrugCompareParams): Promise<IHttpWrap<ICompareResult<IDrugCompareData[]>>> {
    const res: IHttpWrap<ICompareResult<IDrugCompareData[]>> = await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/drug`),
      data: params,
      cache: true,
    })
    if (res.success) {
      res.data.items.forEach((item) => {
        item.target_id_view?.forEach((_item) => {
          decryptTarget(_item, res.xCorrelationId)
          _item.fontClassName = res.fontClassName
        })
        item.mechanism_action_id_view?.forEach((_item) => {
          decryptMechanism(_item, res.xCorrelationId)
          _item.fontClassName = res.fontClassName
        })
        item.global_highest_phase_id_view && decryptDicItem(item.global_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
        item.cn_highest_phase_id_view && decryptDicItem(item.cn_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
        item.global_first_approved_date?.forEach((approved) => {
          approved.date = decryptNum(approved.date, res.xCorrelationId)
        })
        item.cn_first_approved_date?.forEach((approved) => {
          approved.date = decryptNum(approved.date, res.xCorrelationId)
        })
      })
    }
    return res
  }

  async getDiseaseCompareData(params: IDiseaseCompareParams): Promise<IHttpWrap<ICompareResult<IDiseaseCompareData[]>>> {
    const res: IHttpWrap<ICompareResult<IDiseaseCompareData[]>> = await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/disease`),
      data: params,
      cache: true,
    })
    if (res.success) {
      res.data.items.forEach((item) => {
        item.global_highest_phase_id_view && decryptDicItem(item.global_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
        item.cn_highest_phase_id_view && decryptDicItem(item.cn_highest_phase_id_view, res.xCorrelationId, res.fontClassName)
        item.target_id?.items?.forEach((_item) => {
          decryptTarget(_item.target_id_view, res.xCorrelationId)
          _item.target_id_view.fontClassName = res.fontClassName
        })
      })
    }
    return res
  }

  async getAggregation(params: ICompareFilterAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/compare/drug/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getDrugDevAggregation(params: ICompareFilterAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/compare/drug/dev/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getTargetMechanism(params: IFetchTargetCompareMechanisms): Promise<IHttpWrap<ISearchResult<IMechanismData>>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/target/mechanism`),
      method: 'POST',
      data: params,
      cache: true,
    })
  }
  async getCompareMoreEntity(params: ICompareMoreEntityParams): Promise<IHttpWrap<ICompareMoreEntity>> {
    const res: IHttpWrap<ICompareMoreEntity> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/compare/related/entity`),
      method: 'POST',
      data: params,
      cache: true,
    })
    if (res.success) {
      res.data.items.forEach((item) => {
        if ('target_id_view' in item) {
          decryptTarget(item.target_id_view, res.xCorrelationId)
          item.target_id_view.fontClassName = res.fontClassName
        }
      })
    }
    return res
  }
}
