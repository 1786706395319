import { IQueryItemField } from '@patsnap/synapse_common_interface'
import { AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { getTimeRelatedQueryField, transAggregationValue2QueryItem } from '@pharmsnap/shared/src/utils'
import { IUseAnalysisTrialStackBarChartOptions } from '../../type'
import { useAnalysisTwoDimChartNavList } from '../useAnalysisTwoDimChartNavList'
import { useAnalysisTransMedicineTwoDimChartEntity } from './useAnalysisTransMedicineTwoDimChartEntity'

export function useAnalysisTransMedicineStackBarChart(options: IUseAnalysisTrialStackBarChartOptions) {
  const { data: aggData, query, layout, tracking, xAxisName, yAxisName } = options

  const { chartEntity, dataList, countFieldName, entityFieldName, stackFieldName, sortStackChartSubject } = useAnalysisTransMedicineTwoDimChartEntity(
    {
      chartType: 'bar',
      data: aggData!,
      xAxisName,
      yAxisName,
      layout,
    }
  )
  const { handleClick } = useAnalysisTwoDimChartNavList({
    chartType: 'bar',
    navList: 'translational_medicine',
    query,
    appendTo: ['filter', 'filter'],
    tracking,
    customQueryItemPicker: (params: AggTwoDimDataItem) => {
      const { x, y } = params

      let yAxisQuery: IQueryItemField | undefined

      if (y) {
        yAxisQuery =
          y.aggregation_field === 'PUB_DT_YEAR'
            ? getTimeRelatedQueryField(Number(y.key), 'PUB_DT', false, false, false)
            : transAggregationValue2QueryItem(y)
      }

      return {
        xAxisQuery: transAggregationValue2QueryItem(x),
        yAxisQuery: yAxisQuery as IQueryItemField,
      }
    },
  })

  return {
    chartEntity,
    dataList,
    entityFieldName,
    countFieldName,
    stackFieldName,
    handleClick,
    sortStackChartSubject,
  }
}
