import {
  Autocomplete,
  Avatar,
  Backtop,
  Button,
  ButtonGroup,
  Carousel,
  CarouselItem,
  Checkbox,
  CheckboxGroup,
  Col,
  Descriptions,
  DescriptionsItem,
  Dialog,
  Divider,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Image,
  Input,
  InputNumber,
  Loading,
  Message,
  MessageBox,
  Option,
  Pagination,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  Skeleton,
  SkeletonItem,
  Slider,
  Step,
  Steps,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from 'element-ui'
import PopupManager from 'element-ui/lib/utils/popup/popup-manager'
import Vue from 'vue'
import DatePicker from './date-picker/index.js'
import LsLoading from './loading'
import Tree from './tree/index.js'
Vue.use(LsLoading)
// export { showDeleteConfirm } from './deleteConfirm'
export const ElButton = Button
export const ElDrawer = Drawer
export const ElCheckbox = Checkbox
export const ElCheckboxGroup = CheckboxGroup
export const ElTabs = Tabs
export const ElMessage = Message
export const ElTabPane = TabPane
export const ElDescriptions = Descriptions
export const ElDescriptionsItem = DescriptionsItem
export const ElForm = Form
export const ElFormItem = FormItem
export const ElInput = Input
export const ElInputNumber = InputNumber
export const ElTag = Tag
export const ElAvatar = Avatar
export const ElTooltip = Tooltip
export const ElSteps = Steps
export const ElStep = Step
export const ElSkeleton = Skeleton
export const ElSkeletonItem = SkeletonItem
export const ElPopover = Popover
export const ElLoading = Loading
export const ElDatePicker = DatePicker
export const ElPagination = Pagination
export const ElRadioGroup = RadioGroup
export const ElDropdown = Dropdown
export const ElDropdownItem = DropdownItem
export const ElDropdownMenu = DropdownMenu
export const ElRadio = Radio
export const ElTable = Table
export const ElTableColumn = TableColumn
export const ElRow = Row
export const ElCol = Col
export const ElSelect = Select
export const ElOption = Option
export const ElSwitch = Switch
export const ElRadioButton = RadioButton
export const ElDialog = Dialog
export const ElProgress = Progress
export const ElButtonGroup = ButtonGroup
export const ElTree = Tree
export const ElDivider = Divider
export const ElMessageBox = MessageBox
export const ElCarousel = Carousel
export const ElCarouselItem = CarouselItem
export const ElImage = Image
export const ElBacktop = Backtop
export const ELPopupManager = PopupManager
export const ElUpload = Upload
export const ElSlider = Slider
export const ElAutocomplete = Autocomplete
