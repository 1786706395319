import { ELPopupManager } from '@pharmsnap/shared/element-ui'
import { defineComponent, PropType } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../card/config'
import { BFamilyTagPopperItem } from './BFamilyTagPopperItem'

export const BFamilyTagPopper = defineComponent({
  name: 'BFamilyTagPopper',
  props: {
    country: {
      required: true,
      type: String,
    },
    family: {
      required: true,
      type: String,
    },
    familyType: {
      required: true,
      type: String as PropType<'simple' | 'inpadoc'>,
    },
  },
  render() {
    return (
      <PtPopper
        data={{ country: this.country, family: this.family, familyType: this.familyType }}
        tippyConfig={getAsyncListTippyConfig()}
        getZIndex={() => ELPopupManager.nextZIndex()}
        component={BFamilyTagPopperItem}
      >
        {this.$slots.default}
      </PtPopper>
    )
  },
})
