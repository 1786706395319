import { formatOrgName } from '@pharmsnap/shared/utils'
import { formatKgTreeOrganizationName } from '@pharmsnap/shared/utils/business/knowledgeGraph'
import {
  E_VERTEX_TYPE,
  IKgDocumentItem,
  IKgExpandItem,
  IKgExpandParams,
  IKgExpandTreeItem,
  IKgExpandTreeParams,
  IKgIncrementParams,
  IKgNodeItem,
  IKGShortestPathData,
  IKgSubmitParams,
  IKgVertexDocumentParams,
  IKgVertexParams,
  IKnowledgeGraphData,
  ISearchResult,
  IVertexDonutCount,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class KnowledgeGraphApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  /** 校验 ids中是否有实体，如果有的话，会返回实体的 vertex */
  async checkIdsIsEntity(ids: string[]): Promise<IHttpWrap<{ vertices?: IKgVertexParams[] }>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/vertices'),
      method: 'POST',
      data: { ids },
      cache: true,
    })
  }

  async submitQuery4GraphId(params: IKgSubmitParams): Promise<IHttpWrap<IKnowledgeGraphData>> {
    const rt = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/submit'),
      method: 'POST',
      data: params,
    })
    return rt
  }

  async quitBridgeMode(graphId: string): Promise<IHttpWrap<IKnowledgeGraphData>> {
    const rt = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/kg/convert/${graphId}`),
      method: 'POST',
    })
    return rt
  }

  async getGraphData(params: { id: string; version?: number }): Promise<IHttpWrap<IKnowledgeGraphData>> {
    const { id, version } = params
    const rt = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/kg/${id}`),
      method: 'GET',
      params: {
        version,
      },
    })
    return rt
  }

  async getGraphId(params: { id: string; version?: number }): Promise<IHttpWrap<{ graph_id: string }>> {
    const rt = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/kg/profile/${params.id}`),
      method: 'GET',
    })
    return rt
  }

  async expandGraphNodes(params: IKgIncrementParams): Promise<IHttpWrap<IKnowledgeGraphData>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/expand'),
      method: 'POST',
      data: params,
      cache: true,
    })
  }

  async deleteGraphNodes(params: IKgIncrementParams): Promise<IHttpWrap<IKnowledgeGraphData>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/delete'),
      method: 'POST',
      data: params,
      cache: true,
    })
  }

  async getVertexDonutNum(id: string): Promise<IHttpWrap<IVertexDonutCount>> {
    return await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/kg/vertex/${id}`),
      method: 'GET',
      cache: true,
    })
  }

  async getExpandTree(params: IKgExpandTreeParams): Promise<IHttpWrap<ISearchResult<IKgExpandTreeItem>>> {
    const rt: IHttpWrap<ISearchResult<IKgExpandTreeItem>> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/expand/tree'),
      method: 'POST',
      data: params,
      cache: true,
    })
    if (rt.success && rt.data && rt.data.items) {
      formatKgTreeOrganizationName(rt.data.items)
    }
    return rt
  }

  async getExpandTreeItems(params: IKgExpandParams): Promise<IHttpWrap<ISearchResult<IKgExpandItem>>> {
    const data: IHttpWrap<ISearchResult<IKgExpandItem>> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/expand/detail'),
      method: 'POST',
      data: params,
      cache: true,
    })
    if (data.success && data.data && data.data.items) {
      formatKgTreeOrganizationName(data.data.items)
    }
    return data
  }

  async getVertexDocument(params: IKgVertexDocumentParams): Promise<IHttpWrap<IKgDocumentItem>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/document'),
      method: 'POST',
      data: params,
      cache: true,
    })
  }

  async updatePositionIncrementally(params: IKgVertexDocumentParams): Promise<IHttpWrap<IKnowledgeGraphData>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/position'),
      method: 'POST',
      data: params,
    })
  }

  async getShortestPath(params: { id: string; version?: number; limit: number; offset: number }): Promise<IHttpWrap<IKGShortestPathData>> {
    const { id, version, limit, offset } = params
    const res: IHttpWrap<IKGShortestPathData> = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/shortest_path'),
      method: 'POST',
      data: {
        graph_id: id,
        version,
        limit,
        offset,
      },
    })
    if (res.success && res.data && res.data.paths) {
      res.data.paths.forEach((path) => {
        path.forEach((node) => {
          if (node.type === E_VERTEX_TYPE.TARGET) {
            node.name_cn = node.name_en
          }
          if (node.type === E_VERTEX_TYPE.ORGANIZATION && 'is_china' in node) {
            formatOrgName(node, 'name_cn', 'name_en')
          }
        })
      })
    }
    return res
  }

  async getOrgChain(ids: string[]): Promise<IHttpWrap<{ org_path: IKgNodeItem[] }>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/kg/org/chain'),
      method: 'POST',
      cache: true,
      data: {
        ids,
      },
    })
  }
}
