import { IStructureItem } from '@patsnap/synapse_common_interface'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { useLocale } from '../../../composition/useLocale'
import { GDialog } from '../../ui/GDialog/GDialog'
import { GLoading } from '../../ui/GLoading/GLoading'
import { BStructureCard } from '../BStructureCard/BStructureCard'

export const BCasStructureDialog = defineComponent({
  name: 'BCasStructureDialog',
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    structureList: {
      type: Array as PropType<IStructureItem[]>,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const { structureList } = toRefs(props)

    const { localeUpcase } = useLocale()

    return {
      localeUpcase,
      displayStructureList: structureList,
    }
  },
  render() {
    return (
      <GDialog
        onInput={(val: boolean) => this.$emit('input', val)}
        width="800px"
        showFooter={false}
        visible={this.visible}
        title={this.localeUpcase === 'CN' ? '结构' : 'Structure'}
      >
        {this.loading ? (
          <div style="height: 600px; position: relative;">
            <GLoading></GLoading>
          </div>
        ) : (
          <div style="max-height: 580px; overflow-y: auto;">
            {this.displayStructureList.map((item, index) => (
              <div class={[{ 'mt-10': index !== 0 }, 'flex']} key={index}>
                {/* {this.displayStructureList.length > 1 ? <span class="flex-shrink-0 w-8 text-text-t1 text-xs leading-4"># {index + 1}</span> : null} */}
                <span class="flex-shrink-0 w-8 text-text-t1 text-xs leading-4"># {index + 1}</span>
                <BStructureCard
                  class="flex-1 overflow-hidden"
                  layout="horizontal"
                  data={item}
                  onCasImageClicked={() => this.$emit('casStructureItemClicked', index)}
                ></BStructureCard>
              </div>
            ))}
          </div>
        )}
      </GDialog>
    )
  },
})
