import { IBOFreeRegisterUrlParams } from '@patsnap/synapse_common_interface'
import {
  IS_CN_REGION,
  VUE_APP_ACCOUNT_DOMAIN_URL,
  VUE_APP_CLIENT_ID,
  VUE_APP_CLIENT_NAME,
  VUE_APP_PASSPORT_BASEURL,
} from '@pharmsnap/pharmsnap-web/config'
import { getRegisterTracking } from '@pharmsnap/pharmsnap-web/module/register-tracking/register-tracking'
import { removeSynapseLoginCookie } from '@pharmsnap/pharmsnap-web/module/synapse-cookie/synapse-cookie'
import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import JSEncrypt from 'jsencrypt'
import { getLocalStorage } from 'pharmsnapMF_shared/utils'
import qs from 'querystringify'
const authorizationsStoreKey = 'patsnap-authorizations'
const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export function checkHasUserToken() {
  return !!getUserToken()
}
export function getUserToken(): string | undefined {
  return getLocalStorage<{ token: string }>(authorizationsStoreKey, true)?.token
}
export function removeUserToken() {
  return localStorage.removeItem(authorizationsStoreKey)
}
/**
 * 登录地址
 * @returns
 */
async function getLoginUrl(redirect_uri = window.location.href) {
  const registerInfo = await getRegisterTracking()
  const query = {
    client_id: VUE_APP_CLIENT_ID,
    from: VUE_APP_CLIENT_NAME,
    redirect_uri,
  }
  const registerChannel: IBOFreeRegisterUrlParams = {
    channel_code: 'PA-002',
    channel_source: 'Synapse',
    created_from: 'PHARMSNAP_FREE_REGISTER',
  }
  if (IS_CN_REGION) {
    Object.assign(query, registerChannel)
  }
  if (registerInfo) {
    Object.assign(query, registerInfo)
  }
  return `${VUE_APP_ACCOUNT_DOMAIN_URL}${qs.stringify(query, true)}`
}
/**
 * 登出地址
 * @param redirectUrl
 * @returns
 */
function getLogoutUrl(redirectUrl?: string): string {
  return (
    VUE_APP_PASSPORT_BASEURL +
    '/logout?from=' +
    VUE_APP_CLIENT_NAME +
    '&redirect_uri=' +
    encodeURIComponent(removeLoginSuccessQuery(redirectUrl) || window.location.origin)
  )
}
/**
 * 登出的回掉地址不能有login_success, 不然跳转回来的地址还会带上,然后就会往正式版打流浪,然后检测到没有登录又登出了,导致死循环
 * @param url
 * @returns
 */
function removeLoginSuccessQuery(url?: string) {
  if (!url) {
    return url
  }
  try {
    const urlObj = new URL(url)
    urlObj.searchParams.delete('login_success')
    return urlObj.toString()
  } catch (error) {
    return url
  }
}
let isRedirectIng = false
/**
 * 跳转到登录页
 * @deprecated 请使用loginUnited
 */
export async function login() {
  // 防止多次调用
  if (isRedirectIng) {
    return
  }
  isRedirectIng = true
  removeSynapseLoginCookie()
  removeUserToken()
  location.replace(await getLoginUrl())
}
/**
 * 跳转到登出
 * @param logoutRedirectUrl 登出后跳转的地址
 */
export function logout(logoutRedirectUrl?: string) {
  // 防止多次调用
  if (isRedirectIng) {
    return
  }
  isRedirectIng = true
  removeUserToken()
  removeSynapseLoginCookie()
  location.replace(getLogoutUrl(logoutRedirectUrl))
}
/**
 * @param logoutRedirectUrl 登出后跳转的地址
 */
export function loginUnited(logoutRedirectUrl?: string) {
  if (IS_PRODUCTION) {
    //触发登出就会清楚掉所有的cookie,在spa前端有个网关服务,没有cookie就不会进入此服务,所以没有问题
    logout(logoutRedirectUrl)
  } else {
    // 但是本地服务是没有网关的,如果需要登录则需要跳转到登录页
    login()
  }
}

// 获取字符串的推荐颜色，可用于邮箱的背景色
const colors = ['#1976D2', '#FF0000', '#27B4D1', '#6F2EA3', '#DC7C08']
export function getSuggestionColor(letter: string) {
  const code = (letter || '').toUpperCase().charCodeAt(0)

  if (code >= 48 && code <= 57) {
    return colors[(code - 48) % colors.length]
  }

  if (code >= 65 && code <= 90) {
    return colors[(code - 65) % colors.length]
  }

  return colors[0]
}

/**
 *
 * @param token 检测是否有老synapse的权限
 * @returns
 */
export async function checkHasSynapseAuth(token: string) {
  // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=112005813
  const synapseProductId = 'e5ce470917314759bb2a3184b6e62ca8'
  const products = await getTokenProducts(token)
  return products.includes(synapseProductId)
}

/**
 * 获取用户拥有的产品权限
 * @param token
 * @returns
 */
async function getTokenProducts(token: string): Promise<string[]> {
  const Base64 = await import('js-base64')
  const tokens = token.split('.')
  const infoStr = tokens[1]
  if (!infoStr) {
    return []
  }
  try {
    const tokenInfo = JSON.parse(Base64.decode(infoStr))

    return tokenInfo.products
  } catch (error) {
    return []
  }
}
export async function encrypt(code: string) {
  const publicKeyData = await getService().login.getPublicKey()
  if (publicKeyData.success) {
    const encrypt = new JSEncrypt({})
    encrypt.setPublicKey(publicKeyData.data)
    return String(encrypt.encrypt(code))
  }
  return ''
}
