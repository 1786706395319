/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { toThousands } from '@patsnap/synapse_common_utils'
import { AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { computed, defineComponent, PropType, toRefs, unref } from '@vue/composition-api'
import { isUndefined, uniqBy } from 'lodash'
import { Grid } from 'vxe-table'

export const BAnalysisTwoDimStackTableProps = {
  entityFieldName: {
    type: String,
    required: true,
  },
  countFieldName: {
    type: String,
    required: true,
  },
  stackFieldName: {
    type: String,
    required: true,
  },
  list: {
    type: Array as PropType<Array<AggTwoDimDataItem>>,
    default: () => [],
  },
  displayMode: {
    type: String as PropType<'table' | 'record'>,
    default: 'table',
  },
  height: {
    type: String,
  },
  stackFieldNameSorter: {
    type: Function as PropType<(a: AggTwoDimDataItem, b: AggTwoDimDataItem) => number>,
  },
  recordModeEntityCellMinWidth: {
    type: Number,
    default: 100,
  },
} as const

export const BAnalysisTwoDimStackTable = defineComponent({
  name: 'BAnalysisTwoDimStackTable',
  props: BAnalysisTwoDimStackTableProps,
  setup(props) {
    const { entityFieldName, countFieldName, stackFieldName, list, displayMode, stackFieldNameSorter } = toRefs(props)

    const uniqEntityFieldItems = computed(() => uniqBy(list.value, 'yName'))

    const uniqEntityFieldIndexRecord = computed(() => {
      const record: Record<string, number> = {}
      unref(uniqEntityFieldItems).forEach((item) => {
        record[item.yName] = unref(list).findIndex((i) => i.yName === item.yName)
      })
      return record
    })

    const uniqEntityFieldCountRecord = computed(() => {
      const record: Record<string, number> = {}
      unref(uniqEntityFieldItems).forEach((item) => {
        record[item.yName] = unref(list).filter((i) => i.yName === item.yName).length
      })
      return record
    })

    const uniqEntityFieldNames = computed(() => unref(uniqEntityFieldItems).map((i) => i.yName))

    const uniqStackFieldNames = computed(() => {
      const items = uniqBy(list.value, 'xName')
      if (unref(stackFieldNameSorter)) {
        items.sort(unref(stackFieldNameSorter))
      }
      return items.map((i) => i.xName)
    })

    const entityStackRecord = computed(() =>
      list.value.reduce((pre, next) => ({ ...pre, [`${next.yName}_${next.xName}`]: next }), {} as Record<string, AggTwoDimDataItem>)
    )

    const recordModeRowNum = computed(() => uniqStackFieldNames.value.length + 1)

    const recordModeColNum = computed(() => 1 + uniqEntityFieldNames.value.length * 2)

    const recordModeList = computed(() => {
      //  const schema = {
      //    col_0: '',
      //    col_entity_col_1: {},
      //    col_entity_col_2: {},
      //  }

      const items: Array<Record<string, any>> = []

      for (let i = 0; i < unref(recordModeRowNum); i++) {
        const isFirstRow = i === 0
        const col_0 = isFirstRow ? entityFieldName.value : stackFieldName.value
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const item: any = {
          col_0,
        }
        for (let j = 0; j < uniqEntityFieldNames.value.length * 2; j++) {
          const originIndex = j % 2 === 0 ? j / 2 : (j - 1) / 2
          const entityFieldItem = unref(uniqEntityFieldItems)[originIndex]
          const entityStackItem = isFirstRow ? undefined : unref(entityStackRecord)[`${entityFieldItem.yName}_${unref(uniqStackFieldNames)[i - 1]}`]
          item[`col_entity_col_${j + 1}`] = isFirstRow ? entityFieldItem : entityStackItem
        }

        items.push(item)
      }

      return items
    })

    const recordModeColumns = computed(() => {
      // 列的个数
      const colNum = unref(recordModeColNum)

      const items: Grid['columns'] = []

      const colCommonConfig: any = {
        resizable: true,
        align: 'left',
        showOverflow: false,
      }

      for (let i = 0; i < colNum; i++) {
        // 0, 1, 2
        if (i === 0) {
          items.push({
            field: 'col_0',
            width: 135,
            fixed: 'left',
            className: 'bg-[#f8f8f9]',
            ...colCommonConfig,
          })
        } else {
          if (i % 2 !== 0) {
            // 第一行展示实体名称，非第一行展示聚合字段
            items.push({
              field: `col_entity_col_${i}`,
              minWidth: props.recordModeEntityCellMinWidth,
              ...colCommonConfig,
              slots: {
                default: (params) => {
                  const colIndex = params.columnIndex
                  const rowIndex = params.rowIndex
                  const rowData = params.row
                  const item = rowData[`col_entity_col_${colIndex}`] as AggTwoDimDataItem
                  if (rowIndex === 0) return [item?.yName || EMPTY_PLACEHOLDER]

                  return [unref(uniqStackFieldNames)[rowIndex - 1]]
                },
              },
            })
          } else {
            // 第一行展示实体名称，非第一行展示数量字段
            items.push({
              field: `col_entity_col_${i}`,
              minWidth: 80,
              ...colCommonConfig,
              slots: {
                default: (params) => {
                  const colIndex = params.columnIndex
                  const rowIndex = params.rowIndex
                  const rowData = params.row
                  const item = rowData[`col_entity_col_${colIndex}`] as AggTwoDimDataItem

                  if (rowIndex === 0) return [item?.yName || EMPTY_PLACEHOLDER]

                  return [item?.count || EMPTY_PLACEHOLDER]
                },
              },
            })
          }
        }
      }

      return items
    })

    const columns = computed(() => {
      const items: Grid['columns'] = [
        {
          field: 'entity',
          title: entityFieldName.value,
          resizable: true,
          align: 'left',
          showOverflow: false,
          slots: {
            default: (params) => {
              const rowData = params.row as AggTwoDimDataItem
              return [rowData.yName || EMPTY_PLACEHOLDER]
            },
          },
        },
        {
          field: 'stack',
          title: stackFieldName.value,
          resizable: true,
          align: 'left',
          showOverflow: false,
          slots: {
            default: (params) => {
              const rowData = params.row as AggTwoDimDataItem
              return [rowData.xName || EMPTY_PLACEHOLDER]
            },
          },
        },
        {
          field: 'count',
          title: countFieldName.value,
          resizable: true,
          align: 'left',
          showOverflow: false,
          slots: {
            default: (params) => {
              const rowData = params.row as AggTwoDimDataItem
              return [isUndefined(rowData.count) ? EMPTY_PLACEHOLDER : toThousands(rowData.count)]
            },
          },
        },
      ]

      return items
    })

    const tableData = computed(() => (unref(displayMode) === 'record' ? unref(recordModeList) : unref(list)))

    const tableColumns = computed(() => (unref(displayMode) === 'record' ? unref(recordModeColumns) : unref(columns)))

    const spanMethod: Grid['spanMethod'] = (params) => {
      const { columnIndex, rowIndex } = params

      if (unref(displayMode) === 'record') {
        if (columnIndex === 0) {
          if (rowIndex === 0) {
            return { rowspan: 1, colspan: 1 }
          } else {
            return { rowspan: rowIndex === 1 ? unref(recordModeRowNum) - 1 : 0, colspan: rowIndex === 1 ? 1 : 0 }
          }
        } else {
          if (columnIndex % 2 === 0) {
            if (rowIndex === 0) {
              return { rowspan: 0, colspan: 0 }
            } else {
              return { rowspan: 1, colspan: 1 }
            }
          } else {
            if (rowIndex === 0) {
              return { rowspan: 1, colspan: 2 }
            } else {
              return { rowspan: 1, colspan: 1 }
            }
          }
        }
      }

      // 非第一行，且非第一列，不合并
      if (columnIndex !== 0) {
        return { rowspan: 1, colspan: 1 }
      }

      const rowData = params.row as AggTwoDimDataItem
      const yName = rowData.yName
      const yNameIndex = unref(uniqEntityFieldIndexRecord)[yName]
      const yNameCount = unref(uniqEntityFieldCountRecord)[yName]

      if (rowIndex === yNameIndex) {
        return { rowspan: yNameCount, colspan: 1 }
      }

      return { rowspan: 0, colspan: 0 }
    }

    return {
      tableData,
      tableColumns,
      spanMethod,
    }
  },
  render() {
    const disableScroll = {
      enabled: false,
    }
    return (
      <vxe-grid
        showHeader={this.displayMode === 'table'}
        spanMethod={this.spanMethod}
        maxHeight={this.height}
        scrollY={disableScroll}
        scrollX={disableScroll}
        size="small"
        columns={this.tableColumns}
        data={this.tableData}
        border={true}
        class="z-[1]"
      ></vxe-grid>
    )
  },
})
