import { IFieldLangMap } from '@patsnap/synapse_common_interface'

export const SINGLE_OR_MULTI_LANG: IFieldLangMap = {
  multiple_drugs: {
    cn: '联合',
    en: 'Combo',
  },
  single_drug: {
    cn: '单药',
    en: 'Mono',
  },
  'N/A': {
    cn: 'N/A',
    en: 'N/A',
  },
}

export const CT_RESULT_DATA_SOURCE_LANG: IFieldLangMap = {
  LITERATURE: { en: 'Literature', cn: '文献' },
  CTGOV: { en: 'ClinicalTrials.gov', cn: 'CTgov官网' },
}

export const CT_RESULT_SPONSOR_EMP_NUM: Record<number, string> = {
  1: '< 10',
  2: '10-100',
  3: '100-500',
  4: '> 500',
}
