import { checkEmailValid } from '@patsnap/synapse_common_utils'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { BindEmailConfig, REVERSE_LANGUAGE_MAP } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElForm, ElFormItem, ElInput } from '@pharmsnap/shared/element-ui'
import { handleError, showSingleToast } from '@pharmsnap/shared/utils'
import { defineComponent, getCurrentInstance, reactive, ref } from '@vue/composition-api'
import $classes from './BBindEmail.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BBindEmail = defineComponent({
  name: 'BBindEmail',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    buttonTitle: {
      type: String,
    },
    defaultEmail: {
      type: String,
      default: '',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const form = ref()
    const emailCodeErr = ref('')
    const codeSent = ref(false)
    const buttonDisabled = ref(false)
    const codeForm = reactive({
      email: props.defaultEmail,
      code: '',
    })
    const { locale } = useLocale()
    const codeSentSeconds = ref(BindEmailConfig.VERIFICATION_EMAIL_CODE_INTERVAL)
    function validate(rule: { field: string }, value: string, callback: any) {
      if (!codeForm.email) {
        callback(new Error(ins?.proxy.$t('BBindEmail.pleaseInputEmail') as string))
      } else if (!checkEmailValid(codeForm.email)) {
        callback(new Error(ins?.proxy.$t('BBindEmail.emailIncorrect') as string))
      } else {
        callback()
      }
    }

    async function sendVerificationCode() {
      emailCodeErr.value = ''
      form.value.validateField('email', async (emailError: string) => {
        if (!emailError) {
          const updateTime = () => {
            if (codeSentSeconds.value === 0) {
              clearInterval(timerID)
              codeSent.value = false
              codeSentSeconds.value = BindEmailConfig.VERIFICATION_EMAIL_CODE_INTERVAL
            }
            codeSentSeconds.value = codeSentSeconds.value - 1
          }
          const timerID = setInterval(updateTime, BindEmailConfig.EMAIL_VERIFY_CODE_INTERVAL)
          codeSent.value = true
          const rt = await sharedCtx.service.login.getEmailVerifyCode({
            email: codeForm.email,
            language: REVERSE_LANGUAGE_MAP[locale.value],
          })
          if (rt.success) {
            showSingleToast({
              message: ins?.proxy.$t('BBindEmail.sendSuccessful') as string,
              type: 'success',
            })
          } else {
            handleError(rt, locale.value)
          }
        }
      })
    }

    function handleSubmit() {
      buttonDisabled.value = true
      if (!codeForm.code) {
        emailCodeErr.value = ins?.proxy.$t('BBindEmail.pleaseInputVerificationCode') as string
        buttonDisabled.value = false
        return
      }
      emailCodeErr.value = ''
      form.value.validate(async (valid: boolean) => {
        if (valid) {
          const rt = await sharedCtx.service.login.emailBindPreCheck({
            email: codeForm.email,
            code: codeForm.code,
          })
          if (rt.success) {
            const res = await sharedCtx.service.login.emailBind({ email: codeForm.email, code: codeForm.code })
            if (res.success) {
              const {
                actions: { syncUserInfo },
              } = useAuthStore()
              await syncUserInfo()
              emit('success')
            } else {
              handleError(res, locale.value)
            }
          } else {
            handleError(rt, locale.value)
          }
        }
        buttonDisabled.value = false
      })
    }
    return { form, codeForm, codeSent, codeSentSeconds, emailCodeErr, validate, sendVerificationCode, handleSubmit, buttonDisabled }
  },
  render() {
    const { codeForm, codeSent, codeSentSeconds, validate, sendVerificationCode, handleSubmit, buttonDisabled, emailCodeErr } = this
    return (
      <ElForm
        ref="form"
        class={[$classes.codeLogin, 'verify-code']}
        size="small"
        hide-required-asterisk={true}
        {...{
          props: {
            model: codeForm,
          },
        }}
      >
        <ElFormItem label={this.$t('BBindEmail.email')} prop="email" rules={[{ validator: validate, trigger: 'blur' }]}>
          <ElInput vModel={codeForm.email} placeholder={this.$t('BBindEmail.pleaseInputEmail')} disabled={this.disableEdit} />
        </ElFormItem>
        <ElFormItem error={this.$t(emailCodeErr)} class="code-label" label={this.$t('BBindEmail.code')} prop="code">
          <ElInput type="number" maxlength={6} vModel={codeForm.code} placeholder={this.$t('BBindEmail.pleaseInputVerificationCode')} />
          {codeSent ? (
            <p class={$classes.waitToSendCode}>{this.$t('BBindEmail.sendVerificationCodeLater', { seconds: codeSentSeconds })}</p>
          ) : (
            <p class={$classes.sendCode} onclick={sendVerificationCode}>
              {this.$t('BBindEmail.sendVerificationCode')}
            </p>
          )}
        </ElFormItem>
        <ElFormItem class="flex justify-end">
          {this.$slots.cancelButton || this.$scopedSlots.cancelButton?.({})}
          <button class="pt-ui-btn ml-2" disabled={buttonDisabled} type="button" data-type="submit" onClick={handleSubmit}>
            {this.buttonTitle || this.$t('BBindEmail.submit')}
          </button>
        </ElFormItem>
      </ElForm>
    )
  },
})
