/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggStackBarChartEntity, AggTwoDimDataItem, STACK_BAR_CHART_CALCULATE_TOTAL_STACK_NAME } from '@patsnap/synapse_domain'
import { BStackBarTooltip } from '@pharmsnap/shared/components/business/BStackBarTooltip/BStackBarTooltip'
import { BEntityFetchCard } from '@pharmsnap/shared/components/business/card/BEntityFetchCard'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { ComponentProps } from '@pharmsnap/shared/types'
import { computed, defineComponent, onMounted, PropType, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import { BarSeriesOption, EChartsOption } from 'echarts'
import { cloneDeep, isArray } from 'lodash'
import { useAnalysisChart } from '../../compositions/useAnalysisChart'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { IAnalysisDisplayIconType } from '../../type'
import { BAnalysisEChart } from './BAnalysisEchart'

export const BAnalysisStackBarEchart = defineComponent({
  name: 'BAnalysisStackBarEchart',
  props: {
    entity: {
      type: Object as PropType<AggStackBarChartEntity>,
      required: true,
    },
    displayType: {
      type: String as PropType<IAnalysisDisplayIconType>,
      default: 'horizontal-bar',
    },
    showTotalCount: {
      type: Boolean,
      default: false,
    },
    // 显示总数时，需要传
    uniqEntityDataList: {
      type: Array as PropType<AggTwoDimDataItem[]>,
      default: () => [],
    },
    // 显示总数时，需要传
    dataList: {
      type: Array as PropType<AggTwoDimDataItem[]>,
      default: () => [],
    },
    /**
     * 只有组件作为下载使用时，才会传selectedLegend
     * 因为显示dataZoom的堆叠图, 只绘画视图可见部分，下载图片会单独绘画一个全部数据的图
     * 传selectedLegend，用于下载用户选中图例后的图片
     */
    selectedLegend: {
      type: Object as PropType<{ [key: string]: boolean }>,
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)

    const { locale } = useLocale()

    const { entityType, entityId, entityCnName, entityEnName, entityNameInChart, handleAxisClick, handleXAxisMouseover, handleYAxisMouseover } =
      useAnalysisChart({
        entity,
      })

    const { tooltipActiveData, tooltipCommonConfig, tooltipFormatter, tooltipRef, resetTooltipDataMap } = useAnalysisChartTooltip<{
      categoryName: ComponentProps<typeof BStackBarTooltip>['categoryName']
      items: ComponentProps<typeof BStackBarTooltip>['items']
    }>({
      pickTooltipDataFromChartParam,
    })

    const layout = computed(() => unref(entity).layout)

    const xPopperEnable = computed(() => layout.value === 'vertical')

    const yPopperEnable = computed(() => layout.value === 'horizontal')

    const realOption = shallowRef<EChartsOption>()

    function updateRealOption() {
      const option = cloneDeep(unref(entity).getEchartsOptions())
      if (option) {
        option.tooltip = {
          show: true,
          enterable: true,
          hideDelay: 500,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            z: -1,
            shadowStyle: {
              color: '#F5F7FC',
            },
          },
          formatter: tooltipFormatter,
          ...tooltipCommonConfig,
        }
        option.animation = false

        if (props.selectedLegend) {
          const newSeriesList = getSeriesWithTotal(props.selectedLegend, option.series)

          if (newSeriesList?.length) {
            option.series = newSeriesList
            option.legend = {
              ...(option.legend || {}),
              selected: props.selectedLegend,
            }
          }
        }
      }
      realOption.value = option
    }

    function pickTooltipDataFromChartParam(params: any) {
      if (!Array.isArray(params)) return
      if (params.length === 0) return
      if (!params[0]) return
      const categoryName = params[0].axisValueLabel
      const items = params
        .filter((i) => !!i.seriesName)
        .map((i) => {
          const { x, count } = i.data as AggTwoDimDataItem

          return {
            color: i.color as string,
            count,
            name: x._meta.after,
            originalData: i.data,
          }
        })

      const data = {
        categoryName,
        items,
      }

      return [categoryName, data] as [string, typeof data]
    }

    function handleClickTooltipCount(data: AggTwoDimDataItem) {
      emit('clickItem', data)
    }

    function handleClickStackBarItem(params: any) {
      if (!params || !params.data) return
      emit('clickItem', params.data as AggTwoDimDataItem)
    }

    function getSeriesWithTotal(selected: { [key: string]: boolean }, series?: EChartsOption['series']) {
      if (series) {
        const selectedLegendNames = Object.keys(selected).reduce((acc, cur) => {
          if (selected[cur]) {
            acc.push(cur)
          }
          return acc
        }, [] as string[])

        const seriesList = (isArray(series) ? series : [series]) as BarSeriesOption[]

        const totalSeriesIndex = seriesList.findIndex((item) => item.stack === STACK_BAR_CHART_CALCULATE_TOTAL_STACK_NAME)
        const totalSeries = seriesList[totalSeriesIndex]

        if (totalSeries) {
          // 图例全部未选择，total series data 为0
          if (!selectedLegendNames.length) {
            totalSeries.data = props.uniqEntityDataList.map(() => 0)
          } else {
            // 有选择的图例，total series data为对应图例的和
            const selectedData = props.dataList.filter((item) => selectedLegendNames.includes(item.xName))

            const totalData = props.uniqEntityDataList.reduce((acc, cur) => {
              const yName = cur.yName
              const yNameData = selectedData.filter((item) => item?.yName === yName)
              const yNameTotal = yNameData.reduce((acc, cur) => {
                return acc + cur?.count
              }, 0)

              acc.push(yNameTotal)
              return acc
            }, [] as number[])

            totalSeries.data = totalData.reverse()
          }

          seriesList[totalSeriesIndex] = totalSeries

          return seriesList
        }
      }
    }

    function handleLegendSelectedChange(params: { selected: { [key: string]: boolean } }) {
      emit('legendSelectedChange', params.selected)

      const newSeriesList = getSeriesWithTotal(params.selected, realOption.value?.series)

      if (newSeriesList?.length) {
        realOption.value = {
          ...realOption.value,
          series: newSeriesList,
          legend: {
            ...(realOption.value?.legend || {}),
            selected: params.selected,
          },
        }
      }
    }

    onMounted(() => {
      updateRealOption()
    })

    watch(entity, () => {
      resetTooltipDataMap()
      updateRealOption()
    })

    return {
      handleXAxisMouseover,
      handleYAxisMouseover,
      handleLegendSelectedChange,
      handleClickStackBarItem,
      handleClickTooltipCount,
      handleAxisClick,
      xPopperEnable,
      yPopperEnable,
      locale,
      entityNameInChart,
      entityCnName,
      entityEnName,
      entityId,
      entityType,
      layout,
      realOption,
      tooltipRef,
      tooltipActiveData,
      ...useAnalysisEchartsHelpers(),
    }
  },
  methods: {
    renderEntityCardPopper() {
      if (!this.entityId || !this.entityType) return null
      return <BEntityFetchCard id={this.entityId} type={this.entityType}></BEntityFetchCard>
    },
    renderEntityTextPopper() {
      return <div class="rounded bg-white-default text-text-t1 text-xs py-2 px-3">{this.entityNameInChart}</div>
    },
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        displayType={this.displayType}
        option={this.realOption}
        axisPopperOption={{ xPopperEnable: this.xPopperEnable, yPopperEnable: this.yPopperEnable }}
        legendOption={{ watchLegendSelect: this.showTotalCount }}
        {...{
          on: {
            click: this.handleClickStackBarItem,
            axisClick: this.handleAxisClick,
            xAxisMouseover: this.handleXAxisMouseover,
            yAxisMouseover: this.handleYAxisMouseover,
            legendSelectedChange: this.handleLegendSelectedChange,
          },
        }}
      >
        <template slot="xAxisPopper">
          {!this.xPopperEnable ? null : this.layout === 'horizontal' ? this.renderEntityTextPopper() : this.renderEntityCardPopper()}
        </template>
        <template slot="yAxisPopper">
          {!this.yPopperEnable ? null : this.layout === 'horizontal' ? this.renderEntityCardPopper() : this.renderEntityTextPopper()}
        </template>
        <template slot="extra">
          <div ref="tooltipRef">
            <BStackBarTooltip
              countClickable={true}
              categoryName={this.tooltipActiveData?.categoryName || ''}
              items={this.tooltipActiveData?.items || []}
              onClick={this.handleClickTooltipCount}
            ></BStackBarTooltip>
          </div>
        </template>
      </BAnalysisEChart>
    )
  },
})
