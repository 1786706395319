import qs from 'qs'
import UrlParse from 'url-parse'

export function addClassToMatchPrefix(content: string, prefix: string, className: string): string {
  if (!prefix) {
    return content
  }

  const reg = new RegExp(RegExp.escape(prefix), 'i')

  return content.replace(reg, (subStr: string) => {
    return `<span class="${className}">${subStr}</span>`
  })
}

export function isStartWithHttp(path: string) {
  return path.startsWith('http')
}

/**
 * 补全http协议
 * @param url url
 */
export function formatURL(url: string): string {
  if (!url) return ''
  if (url.startsWith('http://') || url.startsWith('https://')) return url
  return `http://${url}`
}

/**
 * 获取域名，例如 www.baidu.com
 * @param url url
 */
export function getHost(url: string): string {
  if (!url) {
    return ''
  }

  if (!url.startsWith('http')) {
    url = `http://${url}`
  }

  return new UrlParse(url).host
}

export function removeHistoryQueries(queryKeys: string[]) {
  const newQuery = qs.parse(window.location.search.substr(1, window.location.search.length))
  queryKeys.forEach((item) => {
    delete newQuery[item]
  })
  window.history.replaceState('', document.title, `${window.location.pathname}${qs.stringify(newQuery, { addQueryPrefix: true })}`)
}

/**
 * 从html文档中提取body内部的内容
 * @param htmlDoc html文档
 */
export function pickBodyContentFromHtmlDoc(htmlDoc: string) {
  return htmlDoc.replace(/[\s\S]*<body[^>]*>([\s\S]*)<\/body>[\s\S]*/i, '$1')
}
