import { IQueryItem } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { useChart } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { IAssigneeItem, IQueryDataEntityType, ITopAssigneeItem } from '@pharmsnap/shared/types'
import { pickDisplayNameByLocale } from '@pharmsnap/shared/utils'
import { ComputedRef, Ref, computed, getCurrentInstance, ref } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import { clone, flatten, isUndefined, maxBy, orderBy } from 'lodash'
import { IPatentStatus, ITopAssigneesChartItem } from '../../../types'
import { useMouseOverAxis } from '../../../use/useMouseOverAxis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import { getChartHeight, getGrantedFilterItem, getHorizontalBarOptions } from '../../../utils'

const SPLIT_NUM = 5
const Y_AXIS_NAME_PLACEHOLDER = 'PLACEHOLDER_'
function addDataToSpecifiedNum<T extends { __name: string; count: number | undefined }>(data: T[], limit = 0) {
  const cloneData = clone(data)
  if (cloneData.length < limit) {
    const addedList = Array.from({ length: limit - cloneData.length }).map((item, index) => ({
      __name: `${Y_AXIS_NAME_PLACEHOLDER}${index}`,
      count: undefined,
    })) as T[]
    cloneData.push(...addedList)
  }
  return cloneData
}

function formatYAxisName(name: string) {
  return name.includes(Y_AXIS_NAME_PLACEHOLDER) ? '' : name
}
function founder(item: IAssigneeItem, name: string) {
  return item.display_name_cn === name || item.display_name_en === name
}

function getEntityId(item: IAssigneeItem) {
  return item.id
}
export function useTopAssigneesChart(options: {
  selectedPatentStatus: Ref<IPatentStatus>
  data: Ref<ITopAssigneeItem[]>
  isSingleBar: Ref<boolean>
  service: IQueryService
  aggregationField: Ref<string>
  locale: ComputedRef<I18nLang>
}) {
  const { selectedPatentStatus, data, isSingleBar, service, aggregationField, locale } = options
  const ins = getCurrentInstance()
  const { onClickChart } = usePatentChartDrill()

  const labelRotate = ref(0)
  const layout = ref<'vertical' | 'horizontal'>('horizontal')
  const entityType = ref<Exclude<IQueryDataEntityType, 'mechanism'>>('organization')

  const chartData = computed(() => {
    const formateData: ITopAssigneesChartItem[][] = data.value.map((item) =>
      item.items.map((assigneeItem) => ({
        ...assigneeItem,
        countryId: item.country_id,
        __name: pickDisplayNameByLocale(assigneeItem, locale.value),
      }))
    )
    // count最大的数据，展示在对比图左边
    return orderBy(formateData, (item) => item[0].count, ['desc'])
  })
  const maxCount = computed(() => {
    const [leftData] = chartData.value
    if (leftData && leftData[0]) {
      return leftData[0].count
    }
    return 0
  })

  const contactedChartData = computed(() => flatten(chartData.value))
  const xAxisName = computed(() =>
    selectedPatentStatus.value === 'application' ? ins?.proxy.$tc('patentsApplication') : ins?.proxy.$tc('patentsGranted')
  )

  const axisInterval = computed(() => Math.ceil(maxCount.value / SPLIT_NUM))
  const maxLengthData = computed(() => maxBy(chartData.value, (item) => item.length) || [])
  const dataMaxLength = computed(() => maxLengthData.value.length)

  const chartContainerHeight = computed(() => {
    const chartHeightUnit = isSingleBar.value ? 46 : 40
    return getChartHeight(maxLengthData.value, chartHeightUnit)
  })

  const toolTipFormatter = (params: any) => {
    const domItem = params.map((item: { data: ITopAssigneesChartItem; marker: string }, index: number) => {
      const {
        data: { count, __name },
        marker,
      } = item
      if (isUndefined(count) || __name.includes(Y_AXIS_NAME_PLACEHOLDER) || maxCount.value === 0) return ''
      const absCount = Math.abs(count)
      const percent = ((absCount / maxCount.value) * 100).toFixed(2)
      return `<div style="marginTop: ${index > 0 ? '8px' : 0}" class="flex items-start justify-start">
        <div class="mr-0.5">${marker}</div>
        <div>
          ${__name}
          <div>${xAxisName.value}: ${toThousands(absCount)}<span style="color: #5E6C84"> (${percent}%) </span></div>
        </div>
      </div>`.trim()
    })
    return `<div>${domItem.join('')}</div>`
  }

  const getSingleBarOption = () => {
    return getHorizontalBarOptions({
      source: chartData.value[0] || [],
      xAxisName: xAxisName.value,
      dimensionKey: ['count', '__name'],
    })
  }

  const getCompareBarOption = () => {
    const [leftData = [], rightData = []] = chartData.value
    const opt: BasicHorizontalBarChart = {
      type: 'horizontal-bar',
      dataset: [
        {
          dimensions: [{ name: 'count' }, { name: '__name' }],
          source: addDataToSpecifiedNum(leftData, dataMaxLength.value).map((item) => ({
            ...item,
            count: isUndefined(item.count) ? undefined : -item.count,
          })),
        },
        {
          dimensions: [{ name: 'count' }, { name: '__name' }],
          source: addDataToSpecifiedNum(rightData, dataMaxLength.value),
        },
      ],
      xAxis: {
        dataType: {
          valueType: 'count',
        },
        max: axisInterval.value * SPLIT_NUM,
        min: -axisInterval.value * SPLIT_NUM,
        splitNumber: SPLIT_NUM,
        interval: axisInterval.value,
        name: xAxisName.value,
        axisLabel: {
          formatter: (val: number) => toThousands(Math.abs(val)),
        },
      },
      yAxis: chartData.value.map(() => ({
        type: 'category',
        triggerEvent: true,
        inverse: true,
        axisLabel: {
          width: 140,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflow: 'truncate',
          formatter: (val: any) => formatYAxisName(val as string),
        },
      })),
      grid: {
        top: 18,
        bottom: 50,
        left: 140 + 20,
        right: 140 + 20,
        containLabel: false,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: '#F5F7FC',
          },
        },
        formatter: toolTipFormatter,
      },
      barWidth: 18,
      series: chartData.value.map((list, index) => ({
        name: xAxisName.value,
        cursor: 'pointer',
        color: index === 0 ? '#1976D2' : '#FABB27',
        encode: {
          x: 'count',
          y: '__name',
        },
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(223,225,230, 0.3)',
        },
        barMinHeight: 3,
        yAxisIndex: index,
        datasetIndex: index,
      })),
    }
    return opt
  }

  const chartOption = computed<BasicHorizontalBarChart>(() => {
    if (isSingleBar.value) {
      return getSingleBarOption()
    }
    return getCompareBarOption()
  })

  const handleDrill = (assignee: ITopAssigneesChartItem) => {
    const extraFilter: IQueryItem[] = [
      {
        type: 'field',
        fields: [aggregationField.value],
        value: [
          {
            type: 'text',
            value: assignee.id,
            display_name_cn: assignee.display_name_cn || assignee.display_name_en,
            display_name_en: assignee.display_name_en,
          },
        ],
      },
    ]
    if (selectedPatentStatus.value === 'granted') {
      extraFilter.push(getGrantedFilterItem())
    }
    onClickChart({ queryState: service.state, extraFilter })
  }

  const handleClickHorizontalBarChart = (params: any) => {
    const data = params.data as ITopAssigneesChartItem
    handleDrill(data)
  }

  const registerEvent = (ins: EChartsType) => {
    ins.on('click', handleClickHorizontalBarChart)
    ins.on('mouseover', 'yAxis', handleAxisMouseover)
  }

  const { chartContainer } = useChart(chartOption, {
    autoResize: true,
    registerEvent,
  })

  const { handleAxisMouseover } = useMouseOverAxis<IAssigneeItem>({
    container: chartContainer,
    list: contactedChartData,
    labelRotate,
    layout,
    entityType,
    founder,
    getEntityId,
  })

  const registerFullScreenEvent = (instance: EChartsType) => {
    instance.on('click', handleClickHorizontalBarChart)
    instance.on('mouseover', 'yAxis', handleFullScreenAxisMouseover)
  }

  const { chartContainer: fullScreenChartContainer } = useChart(chartOption, {
    autoResize: true,
    registerEvent: registerFullScreenEvent,
  })
  const { handleAxisMouseover: handleFullScreenAxisMouseover } = useMouseOverAxis<IAssigneeItem>({
    container: fullScreenChartContainer,
    list: contactedChartData,
    labelRotate,
    layout,
    entityType,
    founder,
    getEntityId,
  })

  return { chartData, chartContainer, fullScreenChartContainer, chartContainerHeight }
}
