import sentryPsPlugin from '@patsnap/common-ps-tracer'
import { IS_CN_REGION, VUE_APP_CLIENT_ID, VUE_APP_CLIENT_NAME } from '@pharmsnap/pharmsnap-web/config'
import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import { logout } from '@pharmsnap/pharmsnap-web/utils/business/auth'
import { getLocaleLang } from '@pharmsnap/pharmsnap-web/utils/business/locale'
import { IUserInfo } from '@pharmsnap/shared/types/login'
import { h } from '@vue/composition-api'
import { i18n } from 'pharmsnapMF_shared/i18n'
import { IReCaptchaResponse, loadScript, showSingleToast, unionCaptchaVerification } from 'pharmsnapMF_shared/utils'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import Vue from 'vue'
import FeedbackUS from '../components/Feedback/FeedbackUS'
import { deBuggerConfig } from '../features/dev-tool/config'
import { initBackOfficeWidget } from './backoffice-widget'

const VUE_APP_BUILD_VERSION = <string>process.env.VUE_APP_BUILD_VERSION
const IS_PRODUCTION = process.env.NODE_ENV === 'production'
const VUE_APP_SENTRY_DSN = <string>process.env.VUE_APP_SENTRY_DSN
// const VUE_APP_SENTRY_DSN = //f582b6ba77f449418e93d628edb89ba5@sentry.zhihuiya.com/234'
const lang = getLocaleLang()
const QIYU_KEY = '3eca47ab309bbfe24b69de9953c56de8'
let qiyuInit = false
let QIYU_INTERVAL_TIMER: NodeJS.Timeout
const DISABLE_CLASS_NAME = 'no_7_yu'
const GEETEST_FAIL_TIMES = 3
const GEETEST_WAIT = 130079
export function initSentry(userInfo?: IUserInfo | null) {
  if (!IS_PRODUCTION) {
    return
  }
  if (deBuggerConfig.isDebuggerModel) {
    return
  }
  Raven.setRelease(VUE_APP_BUILD_VERSION)
  if (userInfo) {
    Raven.setUserContext({
      account_id_cipher: userInfo.encrypt_id || '',
      company_id_cipher: userInfo.encrypt_company_id || '',
      email: userInfo.email || '',
      client_id: VUE_APP_CLIENT_ID,
    })
  }
  Raven.config(VUE_APP_SENTRY_DSN)
    .addPlugin(RavenVue, Vue)
    .addPlugin(sentryPsPlugin, {
      product: VUE_APP_CLIENT_NAME,
      redShiftIntegrationName: 'redshift-segmentdb',
      openSpendTimeTrack: true,
      // 测试时候使用
      // FORCE_SEGMENT_WRITE_KEY: 'xmVkdbJjJJKaBKXRGYbQPHXYIq12i37f',
      // 环境: 数字化运营库tidb-prod-us
      // 库: tidb_user_behavior
      // 数仓查询语句: select * from ads_user_page_track_time_stat WHERE prop_product='Synapse' order by event_ts DESC limit 10
    })
    .install()

  console.log('initSentry after', {
    encrypt_id: userInfo?.encrypt_id,
    VUE_APP_CLIENT_NAME,
    VUE_APP_SENTRY_DSN,
  })
}
function letElementMovable(targetElement: HTMLElement) {
  targetElement.querySelector('img')?.setAttribute('draggable', 'false')
  let offsetX = 0
  let offsetY = 0
  let isDrag = false
  function handleMousedown(e: MouseEvent) {
    offsetX = targetElement.clientWidth - e.x + targetElement.offsetLeft
    offsetY = targetElement.clientHeight - e.y + targetElement.offsetTop
    document.addEventListener('mousemove', handleMouseMove)
  }
  function handleMouseMove(e: MouseEvent) {
    targetElement.style.right = `${window.innerWidth - e.x - offsetX}px`
    targetElement.style.bottom = `${window.innerHeight - e.y - offsetY}px`
    isDrag = true
  }
  function handleMouseUp(e: MouseEvent) {
    window.addEventListener('click', captureClick, true)
    document.removeEventListener('mousemove', handleMouseMove)
  }
  function captureClick(e: Event) {
    if (isDrag) {
      e.stopPropagation()
    }
    window.removeEventListener('click', captureClick, true)
    isDrag = false
  }
  targetElement.addEventListener('mousedown', handleMousedown)
  targetElement.addEventListener('mouseup', handleMouseUp)
}

function addCloseButton() {
  const qiyuIconDom = document.getElementById('YSF-BTN-HOLDER')
  if (qiyuIconDom) {
    if (qiyuIconDom.querySelector('.qiyu-close-button')) {
      return window.clearInterval(QIYU_INTERVAL_TIMER)
    }
    const closeButton = document.createElement('div')
    closeButton.innerHTML = 'x'
    closeButton.setAttribute('class', 'qiyu-close-button')
    closeButton.setAttribute('title', 'Close')
    closeButton.style.cssText = `
            display: none;
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid rgb(70, 190, 138);
            z-index: 999999;
            cursor: pointer;
            color: rgb(70, 190, 138);
            text-align: center;
            line-height: 10px;
            font-size: 12px;
            right: -4px;
            top: 2px;
        `
    closeButton.onclick = (e) => {
      e.stopPropagation()
      e.preventDefault()
      document.body.removeChild(qiyuIconDom)
    }
    qiyuIconDom.appendChild(closeButton)
    letElementMovable(qiyuIconDom)
  }
}

function appendQiyuCss() {
  const css = `
        body.${DISABLE_CLASS_NAME} #YSF-BTN-HOLDER{
            display: none;
        }
        #YSF-BTN-HOLDER{
            right: 20px;
            max-width: none;
            max-height: none;
            padding: 8px;
        }
        #YSF-BTN-HOLDER .qiyu-close-button{
            display: none;
            font-family: sans-serif;
        }
        #YSF-BTN-HOLDER:hover .qiyu-close-button{
            display: block !important;
        }
  `
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')

  style.appendChild(document.createTextNode(css))
  head.appendChild(style)

  if (!QIYU_INTERVAL_TIMER) {
    QIYU_INTERVAL_TIMER = setInterval(addCloseButton, 20)
  }
}

function updateQiYuInfo(userInfo: IUserInfo) {
  if (!(window.ysf && window.ysf.logoff)) {
    return
  }
  const email = userInfo.email || ''
  const companyName = userInfo.company_name || ''
  const manualCompany = userInfo.manual_company || ''

  const config = {
    name: companyName, // 客服故意要求用公司名
    email,
    uid: email,
    groupid: '481980499',
    data: JSON.stringify([
      {
        key: 'Company Name',
        value: companyName,
        label: '公司名',
      },
      {
        key: 'Manual Company',
        value: manualCompany,
        label: '自注册客户名',
      },
    ]),
  }
  window.ysf.config(config)
}

export function initCustomerServiceSdk(userInfo?: IUserInfo) {
  const isFreeUser = userInfo?.phs_role === 'FREE'
  const useQiyuOrZendesk = userInfo && !isFreeUser
  if (!IS_CN_REGION && isFreeUser) return
  if (useQiyuOrZendesk) initBackOfficeWidget(true)
  else {
    // cn使用新版的帮助中心,us不动
    if (IS_CN_REGION) return
    const div = document.createElement('div')

    const vm = new Vue({
      i18n,
      render() {
        return h(FeedbackUS)
      },
    })
    vm.$mount(div)
    document.body.appendChild(vm.$el)
  }
}
function initQiyu(userInfo?: IUserInfo) {
  if (qiyuInit) {
    return
  }
  qiyuInit = true
  loadScript(`https://qiyukf.com/script/${QIYU_KEY}.js`)
    .then(() => {
      window.ysf.on({
        onload: () => {
          setTimeout(() => {
            userInfo && updateQiYuInfo(userInfo)
          }, 3000)
        },
      })
      window.ysf.style(['#YSF-BTN-HOLDER{bottom: 65px;}'])
      window.ysf.style(['#YSF-PANEL-CORPINFO{bottom: 65px;}'])
    })
    .then(() => {
      appendQiyuCss()
    })
}

export function showRemainTime(remaining: number) {
  const minuteCopy = lang === 'cn' ? '分' : ' minute'
  const secondCopy = lang === 'cn' ? '秒' : ' second'
  const remainingCopy = parseInt(String(remaining / 60)) + minuteCopy + (remaining % 60) + secondCopy
  document.querySelector('.geetest_captcha')?.remove()
  showSingleToast({
    message: lang === 'cn' ? `验证失败，${remainingCopy}后重新尝试` : `Verification failed, please try again after ${remainingCopy}`,
    type: 'error',
  })
}
function showBlockMessage() {
  showSingleToast({
    message:
      lang === 'cn'
        ? '您的帐号存在非法操作，已被禁用。您如果对此存在疑问，请联系您的管理员。'
        : 'Your account has been disabled for illegal activity. If you have questions about this, please contact your administrator.',
    type: 'error',
  })
}
let isBlocked = false
let failTime = 1
export function showCaptchaVerification() {
  if (isBlocked) {
    return
  }
  isBlocked = true
  const captchaValidate = (params: IReCaptchaResponse) => getService().login.captchaValidate(params)
  unionCaptchaVerification({
    lang: getLocaleLang(),
    onSuccess: async (data) => {
      isBlocked = false
      const rt = await captchaValidate(data)
      if (!rt.success) {
        return
      }
      if (rt.data.result === 'success') {
        location.reload()
        return
      } else {
        const remaining = rt.data.remaining
        if (remaining > 0) {
          showRemainTime(rt.data.remaining)
          return
        }
        if (remaining < 0) {
          showBlockMessage()
          logout()
        }
      }
    },
    onFail: async (params: { type?: string }) => {
      isBlocked = false
      if (params.type === 'close') {
        return
      }
      if (failTime >= GEETEST_FAIL_TIMES) {
        failTime = 1
        // 如果用户前端滑动,失败了3次,通知后端,锁住用户,并告知需要等待一段时间后再试
        const rt = await captchaValidate({ recaptcha_token: '' })
        if (rt.success) {
          if (rt.data.remaining > 0) {
            showRemainTime(rt.data.remaining)
          }
          if (rt.data.remaining < 0) {
            showBlockMessage()
            logout()
          }
        }
      }
      failTime++
    },
  })
}
