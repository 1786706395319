import { useLocale, useSplitData } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { IGTableColumnsProps } from '@pharmsnap/shared/types/component'
import { ISpecialApprovalItem } from '@pharmsnap/shared/types/drug'
import { handelCountryAlpha2Code } from '@pharmsnap/shared/utils'
import { getLangDegraded } from '@pharmsnap/shared/utils/lang'
import { formatTimestamp } from '@pharmsnap/shared/utils/time'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { sortBy } from 'lodash'
import { BDiseaseItem, GPagination } from '../..'
import { GTable } from '../../ui/GTable/GTable'
import { BOrganizationItem } from '../BOrganizationItem/BOrganizationItem'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BRegulationTable = defineComponent({
  name: 'BRegulationTable',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      required: true,
      type: Array as PropType<ISpecialApprovalItem[]>,
    },
    tableHeight: {
      type: String,
    },
  },
  setup(props) {
    const { localeUpcase, locale, isCN, ts } = useLocale()
    const data = computed(() => {
      //列表先根据审批日期倒序排，再根据机构名称alphabet 排
      return sortBy(props.data, [
        (item) => {
          return -(item.approval_time?.time_ts || 0)
        },
        (item) => {
          return item.org_master_entity_id_view?.name_en
        },
      ])
    })
    const { total, splitData, currentPage, pageSize, onCurrentPageChange } = useSplitData(data, 1, 20)
    const columns = computed<Array<IGTableColumnsProps<ISpecialApprovalItem>>>(() => {
      return [
        {
          label: ts('BRegulationTable.org'),
          renderCell(h, item) {
            if (!item.row.org_master_entity_id_view) {
              return EMPTY_PLACEHOLDER
            }
            return (
              <div style="line-height: 0">
                <BOrganizationItem data={item.row.org_master_entity_id_view}></BOrganizationItem>
              </div>
            )
          },
        },
        {
          label: ts('BRegulationTable.indication'),
          renderCell(h, item) {
            if (!item.row.disease_id_view) {
              return EMPTY_PLACEHOLDER
            }
            return <BDiseaseItem data={item.row.disease_id_view}></BDiseaseItem>
          },
        },
        {
          label: ts('BRegulationTable.special_review'),
          renderCell(h, item) {
            if (!item.row.special_review_view || !item.row.country_view) {
              return EMPTY_PLACEHOLDER
            }
            return `${getLangDegraded(item.row.special_review_view, locale.value)}(${handelCountryAlpha2Code(item.row.country_view, isCN.value)})`
          },
        },
        {
          label: ts('BRegulationTable.date'),
          renderCell(h, item) {
            if (!item.row.approval_time) {
              return EMPTY_PLACEHOLDER
            }
            return formatTimestamp(item.row.approval_time, {
              locale: localeUpcase.value,
            })
          },
        },
      ]
    })
    return { columns, total, splitData, currentPage, pageSize, onCurrentPageChange }
  },
  render() {
    return (
      <div>
        <GTable height={this.tableHeight} border columns={this.columns} data={this.splitData}></GTable>
        <GPagination
          class="text-center mt-4"
          disabled={this.loadingTable}
          layout="prev, pager, next"
          total={this.total}
          currentPage={this.currentPage}
          hideOnSinglePage={true}
          pageSize={this.pageSize}
          {...{ on: { 'current-change': this.onCurrentPageChange } }}
        ></GPagination>
      </div>
    )
  },
})
