import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { ref, shallowRef } from '@vue/composition-api'

export function useRequest<P, T>(
  config: {
    requestFn: (params: P) => Promise<IHttpWrap<T>>
    autoRun?: boolean
  },
  params: P
) {
  const { autoRun = true } = config
  const loading = ref(true)
  const success = ref(true)
  const data = shallowRef<T>()
  const xCorrelationId = ref('')
  function startRequest(newParams?: P) {
    loading.value = true
    config.requestFn(newParams || params).then((rt) => {
      if (rt.success) {
        data.value = rt.data
        xCorrelationId.value = rt.xCorrelationId
      }
      success.value = rt.success
      loading.value = false
    })
  }
  if (autoRun) {
    startRequest()
  }
  return {
    startRequest,
    loading,
    data,
    xCorrelationId,
    success,
  }
}
