import { E_TIME_TRUST_CODE, IBaseTrustTime } from '@patsnap/synapse_common_interface'
import dayjs, { QUnitType } from 'dayjs'
import { isNumber, isString, isUndefined } from 'lodash'
import { TIME_TRUST_CODE_FORMAT_RECORD, TIME_TRUST_CODE_FORMAT_RECORD_EN } from '../config'
import { EMPTY_PLACEHOLDER } from '../constants'
import { I18nLang } from '../i18n'
import { ILang } from '../types/base'
import { E_DATA_DURATION_TYPE, E_TIME_FORMAT } from '../types/enum'

export function formatTimestamp(
  time: number | string | IBaseTrustTime | undefined,
  options?: {
    format?: E_TIME_FORMAT
    locale?: ILang
    isUtc?: boolean
  }
): string {
  const locale = options?.locale || 'EN'
  if (isUndefined(time)) return EMPTY_PLACEHOLDER
  const format = isUndefined(options?.format) ? (locale === 'EN' ? E_TIME_FORMAT.DDD_MMM_YYYY : E_TIME_FORMAT.DD_MM_YYYY) : options?.format
  const { isUtc = true } = options || {}
  const _dayjs = isUtc ? dayjs.utc : dayjs

  if (isNumber(time) || isString(time)) return _dayjs(time).format(format)

  if (time.time_trust_code === E_TIME_TRUST_CODE.INVALID_DATE) {
    return EMPTY_PLACEHOLDER
  }

  return _dayjs(time.time_ts).format(
    (locale === 'EN' ? TIME_TRUST_CODE_FORMAT_RECORD_EN : TIME_TRUST_CODE_FORMAT_RECORD)[time.time_trust_code] || format
  )
}

export function formatTimestampToSemantic(timestamp: number, defaultFormat: E_TIME_FORMAT, isUtc?: boolean) {
  const now = dayjs()
  const limit = 30
  const time = dayjs(timestamp)

  if (timestamp < dayjs().subtract(limit, 'days').valueOf()) {
    return formatTimestamp(timestamp, {
      format: defaultFormat,
      isUtc,
    })
  }

  return time.from(now)
}

export function getLast7daysRange() {
  const to = dayjs.utc().endOf('day').valueOf()
  const from = dayjs.utc(to).subtract(7, 'day').startOf('day').valueOf()

  return {
    from,
    to,
  }
}

export function getLast30daysRange(isUtc = true) {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  const to = _dayjs().valueOf()
  const from = _dayjs(to).subtract(30, 'day').startOf('day').valueOf()

  return {
    from,
    to,
  }
}

export function getOneYearRangeByYear(year: number | string, isUtc = true, startNewYear = true) {
  const isSameYear = dayjs().isSame(year.toString(), 'year')
  const _dayjs = isUtc ? dayjs.utc : dayjs
  const from = _dayjs(`${year}`).valueOf()
  const to = isSameYear ? _dayjs().valueOf() : startNewYear ? _dayjs(from).add(1, 'years').valueOf() : _dayjs(from).endOf('year').valueOf()
  return {
    from,
    to,
  }
}

export function getYearRangeFromNow(offset: number, isUtc = true) {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  const now = dayjs().valueOf()
  const year = `${dayjs().year() - offset}`
  const from = _dayjs(year).valueOf()
  const to = now
  return {
    from,
    to,
  }
}

export function getYearRange(from: number, to: number) {
  const fromTimestamp = dayjs(String(from)).utc().add(1, 'day').startOf('day').valueOf()
  const toTimestamp = dayjs(String(to)).utc().add(1, 'years').valueOf()

  return {
    from: fromTimestamp,
    to: toTimestamp,
  }
}

export function getLangTime(
  locale: I18nLang,
  time?: number | string | IBaseTrustTime,
  cnTimeFormat: E_TIME_FORMAT = E_TIME_FORMAT.YYYY_MM_DD,
  enTimeFormat: E_TIME_FORMAT = E_TIME_FORMAT.DDD_MMM_YYYY
) {
  const format = locale === 'cn' ? cnTimeFormat : enTimeFormat

  if (isUndefined(time)) {
    return dayjs().format(format)
  }

  if (isNumber(time) || isString(time)) {
    return dayjs(time).format(format)
  }
  if (time.time_trust_code === E_TIME_TRUST_CODE.INVALID_DATE) {
    return EMPTY_PLACEHOLDER
  }

  return dayjs(time.time_ts).format(format)
}

export function formatTimeByDurationType(value: number, durationType: E_DATA_DURATION_TYPE) {
  if (durationType === E_DATA_DURATION_TYPE.YEARLY) {
    return dayjs(value).year()
  } else if (durationType === E_DATA_DURATION_TYPE.QUARTERLY) {
    return `${dayjs(value).year()}Q${dayjs(value).quarter()}`
  } else if (durationType === E_DATA_DURATION_TYPE.SEMI_ANNUAL) {
    return `${dayjs(value).year()}H${dayjs(value).quarter() > 2 ? 2 : 1}`
  } else {
    return value
  }
}

export function replaceTimeSplitCode(str: string) {
  return str.replaceAll('-', '/')
}
export function transformDateString2timestamp(
  str: string,
  isEndOfDay = false,
  unit: QUnitType = 'day',
  isUtc = false,
  format?: 'YYYYMMDD' | 'YYYYMM' | 'YYYY'
) {
  const _srt = replaceTimeSplitCode(str)
  const _dayjs = isUtc ? dayjs.utc : dayjs

  const value = isEndOfDay ? _dayjs(_srt).endOf(unit).valueOf() : _dayjs(_srt).startOf(unit).valueOf()
  return format ? Number(_dayjs(value).format(format)) : value
}
export function transformTimestamp2DateString(timestamp: number | string, isUtc = false): string {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  return _dayjs(timestamp).format('YYYY-MM-DD')
}
export function getCustomTimeDisplayText(data: string[]) {
  const [start, end] = data
  return `${replaceTimeSplitCode(start)}-${replaceTimeSplitCode(end)}`
}
