import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { AxiosInstance } from 'axios'

const xCorrelationIdKey = 'x-correlation-id'
export function interceptAxiosWithBiz(axiosIns: AxiosInstance) {
  axiosIns.interceptors.request.use((config) => {
    if (!config.injectUserInfo) {
      return config
    }
    if (config.method?.toLowerCase() !== 'post') {
      return config
    }
    const correlation_id = config.headers && config.headers[xCorrelationIdKey]
    const { state } = useAuthStore()
    const user_id = state.userInfo?.value?.user_id
    if (user_id && correlation_id) {
      Object.assign(config.data, {
        user_id,
        correlation_id,
      })
    }
    return config
  })
}
