import { defineComponent } from '@vue/composition-api'

import { GCollapsibleTreeNode } from './GCollapsibleTreeNode'

export const GCollapsibleTree = defineComponent({
  props: {
    node: {
      type: Object,
    },
  },
  render() {
    return (
      <GCollapsibleTreeNode
        isRoot
        node={this.node}
        scopedSlots={{
          default: ({ node }: never) => this.$scopedSlots.default?.({ node }),
        }}
      />
    )
  },
})
