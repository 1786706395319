import { IAnnotationTag, IBaseTrustTime } from '@patsnap/synapse_common_interface'
import { IBaseDiseaseInfo, IBaseDrugInfo } from '@pharmsnap/shared/types'
import { decrypt, decryptNum } from '@pharmsnap/shared/utils'
import { IBaseTargetInfo } from './../../../types/base'

/**
 * 不同类型新闻标签的解密配置
 */
const decryptConfig: Record<
  'custom' | 'organization' | 'target' | 'drug' | 'disease' | 'org_master_entity',
  (tag: any, xCorrelationId: string, fontClassName: string) => void
> = {
  custom: decryptName,
  organization: decryptOrgName,
  org_master_entity: decryptName,
  target: decryptTargetName,
  drug: decryptDrugName,
  disease: decryptDiseaseName,
}

function decryptName(item: { name_cn?: string; name_en?: string; fontClassName?: string }, xCorrelationId: string, fontClassName: string) {
  if (item.name_cn) {
    item.name_cn = decrypt(item.name_cn, xCorrelationId)
  }
  if (item.name_en) {
    item.name_en = decrypt(item.name_en, xCorrelationId)
  }
  item.fontClassName = fontClassName
}

function decryptOrgName(
  org: { name_cn?: string; name_en?: string; entity_name_cn?: string; entity_name_en?: string; fontClassName?: string },
  xCorrelationId: string,
  fontClassName: string
) {
  if (org.entity_name_cn) {
    org.entity_name_cn = decrypt(org.entity_name_cn, xCorrelationId)
  }
  if (org.entity_name_en) {
    org.entity_name_en = decrypt(org.entity_name_en, xCorrelationId)
  }
  decryptName(org, xCorrelationId, fontClassName)
}

function decryptDrugName(drug: IBaseDrugInfo, xCorrelationId: string, fontClassName: string) {
  drug.drug_name.forEach((o) => {
    o.name = decrypt(o.name, xCorrelationId)
  })
  drug.fontClassName = fontClassName
}

function decryptDiseaseName(disease: IBaseDiseaseInfo, xCorrelationId: string, fontClassName: string) {
  disease.disease_name.forEach((o) => {
    o.name = decrypt(o.name, xCorrelationId)
  })
  disease.fontClassName = fontClassName
}

function decryptTargetName(data: IBaseTargetInfo, xCorrelationId: string, fontClassName: string) {
  const short = data.short_name || []
  const target = data.target_name || []
  short.forEach((sh) => {
    sh.name = decrypt(sh.name, xCorrelationId)
  })
  target.forEach((ta) => {
    ta.name = decrypt(ta.name, xCorrelationId)
  })
  if (data.entity_name_cn) {
    data.entity_name_cn = decrypt(data.entity_name_cn, xCorrelationId)
  }
  if (data.entity_name_en) {
    data.entity_name_en = decrypt(data.entity_name_en, xCorrelationId)
  }
  data.fontClassName = fontClassName
}

/**
 * 新闻标签解密
 * @param tag
 * @param xCorrelationId
 * @param fontClassName
 */
export function decryptNewsTag(tag: IAnnotationTag, xCorrelationId: string, fontClassName: string) {
  if ('normalized_id_view' in tag) {
    const func = decryptConfig[tag.type]
    func && func(tag.normalized_id_view, xCorrelationId, fontClassName)
  }
}

/**
 * 新闻时间解密
 * @param item
 * @param xCorrelationId
 */
export function decryptNewsDate(item: { dmp_post_time?: IBaseTrustTime; post_time?: IBaseTrustTime }, xCorrelationId: string) {
  if (item.dmp_post_time) {
    item.dmp_post_time.time_ts = decryptNum(item.dmp_post_time.time_ts, xCorrelationId)
  }
  if (item.post_time?.time_ts) {
    item.post_time.time_ts = decryptNum(item.post_time.time_ts, xCorrelationId)
  }
}
