import SaveToWorkspace from '@patsnap-biz/save-to-workspace-v2'
import { VUE_APP_WORKSPACE_BASEURL, VUE_APP_WORKSPACE_DOMAIN_URL } from '@pharmsnap/pharmsnap-web/config'
import { getAxios } from '@pharmsnap/pharmsnap-web/service/service'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { ComponentInstance } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import { assign } from 'lodash'
import { Toast } from 'patsnap-biz'
import { IWorkspaceOriginParams, IWorkspaceServiceParams } from '../../type'
import { formatSubmitSaveToWorkspaceParams, getWorkspaceSearchId } from '../../util'

const { ClinicalService } = SaveToWorkspace

export default class SaveClinicalService extends ClinicalService {
  vm: ComponentInstance | undefined = undefined
  axiosInstance: AxiosInstance
  errorCodeI18nPrefix: string
  selectedIds: string[] = []
  workspaceSearchId: string

  constructor(params: IWorkspaceServiceParams) {
    super()

    const { vm, selectedIds = [], searchParams, total = 0 } = params

    this.vm = vm
    this.axiosInstance = getAxios()
    // 用于发送请求的前缀
    this.baseUrl = `${VUE_APP_WORKSPACE_BASEURL}/workspace/web`
    // 成功保存后用于生成跳转链接
    this.workspaceProductDomain = VUE_APP_WORKSPACE_DOMAIN_URL
    this.errorCodeI18nPrefix = 'errorMessage'
    this.selectedIds = selectedIds
    this.searchParams = searchParams
    this.workspaceSearchId = ''

    const selectCount: number = selectedIds.length

    if (selectCount > 0) {
      // 范围选择组件参数
      this.rangeSelector = {
        ...this.rangeSelector,
        // 已选择的临床数量
        allCounts: selectCount,
      }
      this.enableRange = false
    } else {
      this.rangeSelector = {
        ...this.rangeSelector,
        // 页面上临床数量，比如检索出10000条，那这里就是10000
        allCounts: total,
        maxInputLimit: 100000,
      }
      this.enableRange = true
    }

    this.init()
  }

  async getWorkspaceSearchId(params: IWorkspaceOriginParams) {
    const id = await getWorkspaceSearchId(assign({}, this.searchParams, { offset: params.from - 1, limit: params.to - params.from + 1 }))
    this.workspaceSearchId = id
  }

  // 提交保存到工作空间接口
  async generateSubmitSaveToWorkspaceParams(params: IWorkspaceOriginParams) {
    // 范围选择，需要先换取workspace查询id
    if (!this.selectedIds.length && !this.workspaceSearchId) {
      await this.getWorkspaceSearchId(params)
    }
    sharedCtx.service.tracking.trackGeneral({
      event_code: 'SAVE_TO_WORKSPACE',
    })
    return formatSubmitSaveToWorkspaceParams(params, this.type, this.workspaceSearchId, this.selectedIds)
  }

  // 出错提示回调
  onToastError({ message }: { message: string }) {
    Toast({ message, type: 'error' })
  }

  // 弹窗关闭事件（只是执行回调事件，不会关闭弹窗）
  onClose(success?: { template: string }) {
    // 触发时机：
    // 1. 用户提交，并且接口正常完成以后。
    // 2. 用户点击弹窗右上角叉叉时。
    // 3. 用户点击弹窗右下角取消按钮时。
    if (success) {
      const { template } = success
      Toast.closeAll()
      Toast({
        type: 'success',
        message: template,
        dangerouslyUseHTMLString: true,
        duration: 5000,
        showClose: true,
      })
    }
    this.vm?.$emit('close')
  }
}
