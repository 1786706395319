import { OrganizationTypeTextRecord } from '@patsnap/synapse_common_config'
import type { IOrganizationOwnershipType, IOrgType } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { orgField } from '@patsnap/synapse_domain'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { groupBy, reduce } from 'lodash'
import Raven from 'raven-js'
import { IS_CN_REGION, organizationTypeIconMap, ownershipTypeIconMap } from '../config'
import { EMPTY_PLACEHOLDER } from '../constants'
import { ORG_ENTITY_LANG } from '../constants/enum-lang/org'
import {
  E_ORGANIZATION_TYPE,
  IBaseNormalizedInfo,
  IBaseNormalizedOrgInfo,
  IBaseOrgInfo,
  ILang,
  IOrgHotTagInfo,
  ISearchTreeResItem,
  IVCBaseOrg,
  IVCPerson,
} from '../types'
import { formatOrgName } from './index'
import { getRangeNumber } from './number'

export function getOrganizationTypeDisplayName(type: IOrgType, lang: ILang) {
  const langConfig = ORG_ENTITY_LANG[type]
  if (!langConfig) {
    return type
  }
  return lang === 'CN' ? langConfig.cn : langConfig.en
}

export function getNormalizedLocationDisplayName<
  T extends { state_id_view?: IBaseNormalizedInfo; country_id_view?: IBaseNormalizedInfo; is_china?: boolean }
>(address: T | undefined, locale: I18nLang = 'en') {
  return orgField.extractOrgLocation(
    {
      locale: locale,
    },
    address || {
      state_id_view: undefined,
      country_id_view: undefined,
      is_china: true,
    }
  )
}

export function getEmployeesRangeNumber(num: number) {
  return getRangeNumber(num, [10, 50, 100, 250, 500, 1000, 5000, 10000, 50000, 100000])
}

export function getOwnerShip(
  item: {
    ownership_type?: IOrganizationOwnershipType[]
    entity_type?: E_ORGANIZATION_TYPE
    headquarter_country_cn?: string
    headquarter_country_en?: string
  },
  locale: 'en' | 'cn'
) {
  let ownerShip: string[] = []
  if (item.entity_type === E_ORGANIZATION_TYPE.COMPANY) {
    if (item.ownership_type && item.ownership_type.length) {
      if (locale === 'en') {
        ownerShip = item.ownership_type.concat()
      } else {
        ownerShip = item.ownership_type.map((o) => OrganizationTypeTextRecord[o])
      }
    }
  } else {
    const type = getOrganizationTypeDisplayName(item.entity_type as IOrgType, locale.toUpperCase() as ILang)
    if (type) {
      ownerShip.push(type)
    }
  }
  const country = item[`headquarter_country_${locale}`]
  if (country) {
    ownerShip.unshift(country)
  }
  return ownerShip.join(' | ')
}

export function getOrganizationNameAndIcon(
  item: { organizationType?: IOrgType; ownershipTypeList?: IOrganizationOwnershipType[] },
  locale: 'en' | 'cn'
): {
  iconName: string
  title: string
} {
  const { organizationType, ownershipTypeList } = item
  if (!organizationType) {
    return {
      iconName: '',
      title: '',
    }
  }

  let iconName = organizationTypeIconMap[organizationType]
  let title: string = locale === 'en' ? organizationType : OrganizationTypeTextRecord[organizationType]

  if (organizationType === 'Company') {
    const ownershipType = ownershipTypeList?.length ? ownershipTypeList[0] : ''
    if (ownershipType) {
      iconName = ownershipTypeIconMap[ownershipType]
      title = locale === 'en' ? `${ownershipType} Company` : OrganizationTypeTextRecord[ownershipType]
    }
  }

  return {
    iconName,
    title,
  }
}

export function getOrganizationStockSymbol(stock_exchange_code?: string, stock_symbol?: string, shouldShowPlaceholder = true) {
  if (stock_exchange_code && stock_symbol) {
    return `${stock_exchange_code}: ${stock_symbol}`
  }
  return shouldShowPlaceholder ? EMPTY_PLACEHOLDER : ''
}

export function getOrganizationTopNHottag(hotTags?: IOrgHotTagInfo[], limit = 3) {
  if (!hotTags) {
    return []
  }
  const groupTagByType = groupBy(hotTags, 'type')
  const groupedTagList = Object.values(groupTagByType)

  return reduce(
    groupedTagList,
    (result: IOrgHotTagInfo[], tagList: IOrgHotTagInfo[]) => {
      const topNTagList = tagList.slice(0, limit)
      return result.concat(topNTagList)
    },
    []
  )
}

export function transformerNormalizedOrg2BaseOrg(org: IBaseNormalizedOrgInfo): IBaseOrgInfo {
  const enName =
    org.normalized_name || org.normalized_display_name || org.original_name || (org.original_name_list && org.original_name_list[0]) || ''
  return {
    entity_id: org.normalized_id || '',
    name_en: enName,
    name_cn: org.normalized_name_cn || enName,
    entity_name_cn: org.normalized_name_cn || enName,
    entity_name_en: enName,
    logo: org.normalized_logo,
    logo_s3_path: org.normalized_logo_s3_path,
    is_ls_related: !!org.is_ls_related,
    entity_status: org.entity_status,
  }
}

export function getSimpleOwnerShip(org: { ownership_type?: IOrganizationOwnershipType[] }, locale: I18nLang) {
  const ownerShip = org.ownership_type ? org.ownership_type[0] : ''
  return ownerShip && locale === 'cn' ? OrganizationTypeTextRecord[ownerShip] : ownerShip
}

export function formatSearchTreeOrg(data: ISearchTreeResItem[]) {
  data.forEach((item) => {
    formatOrgName(item, 'name_cn', 'name_en')
    item.children && formatSearchTreeOrg(item.children)
  })
}

export function transformVcOrg2BaseOrg(params?: IVCBaseOrg | IVCPerson): IBaseOrgInfo {
  if (params && 'normalized_master_entity_id' in params) {
    return params.normalized_master_entity_id_view
  }
  return {
    entity_id: '',
    entity_name_cn: params?.original_name || '',
    entity_name_en: params?.original_name || '',
    name_cn: params?.original_name || '',
    name_en: params?.original_name || '',
  }
}

export function employNumScope(value: string, employeeNum?: number) {
  if (employeeNum === undefined) return false
  switch (value) {
    case 'All': {
      return true
    }
    case '1': {
      return employeeNum < 10
    }
    case '2': {
      return employeeNum >= 10 && employeeNum < 100
    }
    case '3': {
      return employeeNum >= 100 && employeeNum < 500
    }
    case '4': {
      return employeeNum > 500
    }
    default: {
      return false
    }
  }
}

/**
 * 老的获取企业名称逻辑
 * @param data 包含企业名称、is_china的数据
 * @param locale I18nLang
 * @returns string
 */
export function getOrganizationNameOld<T extends Pick<IBaseOrgInfo, 'name_cn' | 'name_en' | 'name_cn_translation' | 'is_china'>>(
  data: T,
  locale: I18nLang
) {
  const nameENWithDegraded = data.name_en || data.name_cn || data.name_cn_translation || ''
  const nameCNWithDegraded = data.name_cn || data.name_en || data.name_cn_translation || ''

  // 海外环境 || 海外企业 => 优先使用name_en
  if (!IS_CN_REGION || data.is_china === false) {
    return nameENWithDegraded
  }
  // 根据语言环境展示企业名称
  return locale === 'cn' ? nameCNWithDegraded : nameENWithDegraded
}

export function getOrganizationName<
  T extends Pick<IBaseOrgInfo, 'name_cn' | 'name_en' | 'name_cn_translation' | 'is_china' | 'entity_name_cn' | 'entity_name_en'>
>(data: T, locale: I18nLang, isNameNeedDegrade = false) {
  // 根据语言环境展示企业名称
  const entityName = getLangDegraded({ name_cn: data.entity_name_cn, name_en: data.entity_name_en }, locale)
  if (entityName) {
    return entityName
  }
  if (isNameNeedDegrade) {
    return getOrganizationNameOld(data, locale)
  }
  Raven.captureMessage(`[log]: organization: ${data.name_en || data.name_cn} lack entity_name`, {
    extra: {
      route: window.location.href,
    },
  })
  return ''
}

export function checkOrganizationIsClosed(entityStatus?: string) {
  return entityStatus === 'CLOSED'
}
