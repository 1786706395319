/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IAggregationValue } from '@patsnap/synapse_common_interface'
import { defineComponent, unref } from '@vue/composition-api'
import { useFilterUiListService } from '../compositions/useFilterListService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterAggValueNext } from '../type'
import { BFilterCheckbox } from './BFilterCheckbox'
import { BFilterLimitContainer } from './BFilterLimitContainer'

export const BFilterCheckboxList = defineComponent({
  name: 'BFilterCheckboxList',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return useFilterUiListService({ identity: props.identity, uiType: 'checkbox' })
  },
  methods: {
    async handleChange() {
      if (this.showMore) {
        await this.onCollapse()
      } else {
        await this.onExpand()
      }
    },
    async handleCheckToggle(val: boolean, item: IFilterAggValueNext) {
      await this.onCheck(item, val ? 'add' : 'remove')
    },
  },
  render() {
    const { locale, triggerText, displayList, showExpandTrigger, handleChange } = this

    return (
      <BFilterLimitContainer
        list={displayList}
        triggerText={triggerText}
        showTrigger={showExpandTrigger}
        onChange={handleChange}
        scopedSlots={{
          default: ({ item }: { item: IFilterAggValueNext; index: number }) => {
            return (
              <BFilterCheckbox
                value={item}
                showCount={true}
                disabled={this.checkIsDisabled(item)}
                selected={this.checkIsSelected(item)}
                showPercent={false}
                locale={unref(locale)}
                onChange={(val: boolean) => this.handleCheckToggle(val, item)}
                scopedSlots={{
                  default: ({ item }: { item: IAggregationValue }) => {
                    const content = this.$scopedSlots.item && this.$scopedSlots.item({ item })

                    if (content) return content

                    return null
                  },
                }}
              ></BFilterCheckbox>
            )
          },
        }}
      ></BFilterLimitContainer>
    )
  },
})
