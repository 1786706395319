import { E_NODATA_SIZE } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, Ref, ref, toRefs } from '@vue/composition-api'
import _, { cloneDeep } from 'lodash'
import { NormalizedScopedSlot } from 'vue/types/vnode'
import { GCheckList } from '../GCheckList/GCheckList'
import { GEmpty } from '../GEmpty/GEmpty'
import { GMultiDropdown } from '../GMultiDropdown/GMultiDropdown'
import $classes from './GMultiDropdownPopper.module.scss'

export const GMultiDropdownPopper = defineComponent({
  name: 'GMultiDropdownPopper',
  props: {
    icon: String,
    label: String,
    title: String,
    width: {
      type: Number,
      default: 200,
    },
    popoverClass: {
      type: String,
      default: '',
    },
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: [Object, Array] as PropType<Record<string, Array<any>>> | PropType<any[]>,
      default: () => ({}),
    },
    value: {
      type: [Object, Array] as PropType<Record<string, string[]>> | PropType<string[]>,
      default: () => ({}),
    },
    itemKey: {
      type: [Object, String] as PropType<Record<string, string>> | PropType<string>,
    },
    checkAllLabel: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    col: {
      type: Number,
      default: 1,
    },
    placement: {
      type: String as PropType<'bottom-start' | 'bottom' | 'bottom-end'>,
      default: 'bottom-start',
    },
    filter: {
      type: Function as PropType<(type: string, item: any) => boolean>,
    },
    sorter: {
      type: Function as PropType<(a: string, b: string) => number>,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOpenRecycleScroller: {
      type: Boolean,
      default: false,
    },
    itemHeight: {
      type: Number,
      default: 24,
    },
    limit: {
      type: Number,
    },
    /** 是否为多选 */
    isMultiSelect: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    /** 是否有权限：无权限时，点击出upgrade弹窗 */
    hasRights: {
      type: Boolean,
      default: true,
    },
    panelHeight: {
      type: String,
    },
  },
  setup(props, ctx) {
    const { value, data, limit, itemKey } = toRefs(props)
    // 展示勾选数据
    const tempChecked = ref(cloneDeep(value.value))
    const multiDropdown = ref()
    // 实际选中的数量
    const count = computed(() => getCount(value))
    // 总的展示数据数量
    const displayTotal = computed(() => getCount(data))
    // 判断是否只是渲染单个check list
    const isSingle = computed(() => Array.isArray(data.value) && Array.isArray(value.value))
    // 判断是否渲染空列表
    const isEmpty = computed(() => displayTotal.value === 0)
    // 处理显示popper
    const onOpen = () => {
      ctx.emit('open')
      // 同步实际勾选项目到视图勾选项目
      tempChecked.value = cloneDeep(value.value)
    }
    // 处理提交勾选项
    const onConfirm = () => {
      // 同步视图勾选项到实际勾选项
      ctx.emit('input', cloneDeep(tempChecked.value))
    }
    // 处理清空勾选数据
    const onClear = () => {
      // 根据传入的数据类型，进行不同的reset操作
      ctx.emit('clear')
      if (Array.isArray(data.value)) {
        tempChecked.value = []
        ctx.emit('input', [])
      } else {
        tempChecked.value = {}
        ctx.emit('input', {})
      }
    }

    function getCount(value: Ref<any[] | Record<string, any[]>>) {
      const val = value.value
      if (Array.isArray(val)) return val.length
      const keys = Object.keys(val)
      if (keys.length === 0) return 0
      return keys.reduce((pre, next) => pre + val[next].length, 0)
    }

    const disabledList = computed(() => {
      if (Array.isArray(data.value) && Array.isArray(tempChecked.value) && typeof itemKey?.value === 'string') {
        if (limit?.value && tempChecked.value.length >= limit.value) {
          return _.difference(
            data.value.map((o) => o[itemKey.value as string]),
            tempChecked.value
          )
        }
        return []
      }
      return []
    })

    const handleSelectSingleItem = (item: any, isMultiCheckPanel: boolean, dataKey: string) => {
      const itemKeyField = typeof itemKey?.value === 'string' ? itemKey.value : itemKey?.value?.[dataKey]
      if (itemKeyField) {
        const itemValue = item[itemKeyField]
        // 多个选择panel
        if (isMultiCheckPanel && !Array.isArray(tempChecked.value)) {
          if (tempChecked.value[dataKey].includes(itemValue)) {
            tempChecked.value[dataKey] = []
            return
          }
          tempChecked.value[dataKey] = [itemValue]
          return
        }
        if ((tempChecked.value as string[]).includes(itemValue)) {
          tempChecked.value = []
          return
        }
        tempChecked.value = [itemValue]
      }
      if (!props.showFooter) {
        onConfirm()
        multiDropdown.value?.handleClosePanel()
      }
    }

    return {
      multiDropdown,
      tempChecked,
      displayTotal,
      count,
      isSingle,
      isEmpty,
      disabledList,
      onOpen,
      onConfirm,
      onClear,
      handleSelectSingleItem,
    }
  },
  methods: {
    renderMultiCheckPanel() {
      const keys = Object.keys(this.data)
      const sortedKeys = this.sorter ? keys.sort(this.sorter) : keys

      const checkLists = sortedKeys.map((key) =>
        this.isMultiSelect ? (
          <GCheckList
            class={$classes.checkList}
            key={key}
            showCheckAll={true}
            checkAllLabel={this.checkAllLabel[key]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            v-show={(this.data as Record<string, any[]>)[key].length > 0}
            v-model={(this.tempChecked as Record<string, string[]>)[key]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            list={Array.isArray(this.data) ? this.data : (this.data as Record<string, any[]>)[key]}
            col={this.col}
            itemKey={typeof this.itemKey === 'string' ? this.itemKey : (this.itemKey as Record<string, string>)[key]}
            itemHeight={this.itemHeight}
            isOpenRecycleScroller={this.isOpenRecycleScroller}
            scopedSlots={{
              default: this.$scopedSlots[key],
            }}
          ></GCheckList>
        ) : (
          this.renderSingleSelectItem((this.data as Record<string, any[]>)[key], true, key)
        )
      )

      return [<div v-show={!this.isEmpty}>{checkLists}</div>, <GEmpty size={E_NODATA_SIZE.SMALL} v-show={this.isEmpty}></GEmpty>]
    },
    renderSingleSelectItem(data: any[], isMultiCheckPanel: boolean, dataKey = 'item') {
      return data.map((item) => {
        return (
          <div
            class={{
              [$classes.activeItem]: (this.tempChecked as string[]).includes(item[this.itemKey as string]),
              [$classes.singleSelectItem]: true,
            }}
            onClick={() => this.handleSelectSingleItem(item, isMultiCheckPanel, dataKey)}
          >
            {this.$scopedSlots[dataKey] && (this.$scopedSlots[dataKey] as NormalizedScopedSlot)({ item })}
          </div>
        )
      })
    },
    renderSingleCheckPanel() {
      return [
        this.isMultiSelect ? (
          <GCheckList
            v-show={!this.isEmpty}
            v-model={this.tempChecked as string[]}
            disabledValue={this.disabledList}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            list={this.data as any[]}
            itemKey={this.itemKey as string}
            col={this.col}
            itemHeight={this.itemHeight}
            isOpenRecycleScroller={this.isOpenRecycleScroller}
            scopedSlots={{
              default: this.$scopedSlots.item,
            }}
          ></GCheckList>
        ) : (
          this.renderSingleSelectItem(this.data as any[], false)
        ),
        <GEmpty size={E_NODATA_SIZE.SMALL} v-show={this.isEmpty}></GEmpty>,
      ]
    },
    renderCheckPanel() {
      return this.isSingle ? this.renderSingleCheckPanel() : this.renderMultiCheckPanel()
    },
  },
  render() {
    return (
      <GMultiDropdown
        ref="multiDropdown"
        count={this.count}
        popoverClass={this.popoverClass}
        icon={this.icon}
        label={this.label}
        title={this.title}
        width={this.width}
        placement={this.placement}
        isLoading={this.isLoading}
        panelHeight={this.panelHeight}
        onOpen={this.onOpen}
        onConfirm={this.onConfirm}
        onClear={this.onClear}
        onClose={() => this.$emit('close')}
        showFooter={this.showFooter}
        hasRights={this.hasRights}
        onClickWithoutRights={() => this.$emit('clickWithoutRights')}
      >
        <template slot="header">{this.$scopedSlots.header && this.$scopedSlots.header({ total: this.displayTotal })}</template>
        <template slot="default">{this.renderCheckPanel()}</template>
      </GMultiDropdown>
    )
  },
})
