import type { IAggregationData, IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { IFieldLangMap, ISearchResult, ITreeAggregationItem } from '@pharmsnap/shared/types'
import { formatOrgName } from '../../common'
import { decryptNum } from '../../encrypt/encrypt'

/**
 * 注入语言字段
 * @param langMap
 * @param data
 * @returns
 */
export function injectLangFieldByKeyLangMap(langMap: IFieldLangMap, data: IAggregationValue): IAggregationValue {
  return {
    ...data,
    display_name_cn: langMap[data.key]?.cn || data.key,
    display_name_en: langMap[data.key]?.en || data.key,
  }
}
export function injectLangFieldByKey(data: IAggregationValue): IAggregationValue {
  return {
    ...data,
    display_name_cn: data.key,
    display_name_en: data.key,
  }
}

export function injectLangFieldBySpecifiedLang(data: IAggregationValue, lang: 'en' | 'cn'): IAggregationValue {
  const displayName = data[`display_name_${lang}`]
  if (displayName) {
    return {
      ...data,
      display_name_cn: displayName,
      display_name_en: displayName,
    }
  }
  return data
}

export function injectLangFieldByKeyWithUnknown(data: IAggregationValue): IAggregationValue {
  return {
    ...data,
    display_name_cn: data.key.toLocaleLowerCase() === 'unknown' ? '未知' : data.key,
    display_name_en: data.key.toLocaleLowerCase() === 'unknown' ? 'Unknown' : data.key,
  }
}

export function filterInvalidData(data: IAggregationSingleResult<IAggregationValue>) {
  return {
    ...data,
    items: data.items.filter((item) => !!item.count),
  }
}
/**
 * 处理聚合的加密数据
 * @param data 接口返回的数据
 * @param xCorrelationId
 * @returns
 */
export function transformAggregationDecryptResultFn(data: IAggregationSingleResult<IAggregationValue>, xCorrelationId?: string) {
  function _decryptNum(num: number) {
    return decryptNum(String(num), xCorrelationId)
  }
  return {
    aggregation_field: data.aggregation_field,
    total: _decryptNum(data.total),
    items: data.items.map((item) => ({ ...item, count: _decryptNum(item.count) })),
  }
}
/**
 * 处理聚合的数据
 * @param response 接口返回的数据
 * @param transformAggregationSingleResultFn 转换数据的方法
 * @returns
 */
export function transformAggregationResponse(
  response: IHttpWrap<IAggregationData>,
  transformAggregationSingleResultFn: (data: IAggregationSingleResult, xCorrelationId: string) => IAggregationSingleResult
) {
  if (response.success) {
    return {
      ...response,
      data: {
        ...response.data,
        aggregation_result: (response.data.aggregation_result || []).map((item) => transformAggregationSingleResultFn(item, response.xCorrelationId)),
      },
    }
  }
  return response
}

export function transformGroupAggregationSingleResultByMap(
  data: IAggregationSingleResult,
  fieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue>,
  xCorrelationId: string
): IAggregationSingleResult {
  const transformFn = fieldTransformFnMap[data.aggregation_field]
  function _decryptNum(num: number) {
    return decryptNum(String(num), xCorrelationId)
  }
  function _decryptOtherInfo(otherInfo?: { [key: string]: any }) {
    if (!otherInfo) {
      return {}
    }

    /** 牛眼图特殊增加的字段，特殊处理 */
    if (otherInfo?.stat_info?.drug_type?.length) {
      otherInfo.stat_info.drug_type = otherInfo.stat_info.drug_type.map((item: any) => {
        return {
          ...item,
          count: _decryptNum(item.count),
        }
      })
    }
    const decryptKeys = ['recent_30', 'sum_money_amount', 'sum_amount', 'sum_money_amount_usd', 'sum_money_amount_cny']
    const decryptData = decryptKeys.reduce(
      (acc, curr) => {
        if (acc[curr]) {
          acc[curr] = _decryptNum(acc[curr])
        }
        return acc
      },
      { ...otherInfo }
    )
    return {
      other_info: decryptData,
    }
  }

  return {
    total: _decryptNum(data.total),
    aggregation_field: data.aggregation_field,
    items: data.items.map((item) => {
      const decryptOtherInfo = _decryptOtherInfo(item.other_info)
      return {
        ...(transformFn ? transformFn(item, xCorrelationId) : item),
        count: _decryptNum(item.count),
        ...decryptOtherInfo,
        ...(item.aggregation_result
          ? {
              aggregation_result: item.aggregation_result?.map((_item) =>
                transformGroupAggregationSingleResultByMap(_item, fieldTransformFnMap, xCorrelationId)
              ),
            }
          : undefined),
      }
    }),
  }
}

/** 使用后端处理过的名称 */
export function replaceDisplayNameByShortName<
  T extends {
    display_name_cn?: string
    display_name_en?: string
    entity_name_cn?: string
    entity_name_en?: string
  }
>(data: T): T {
  if (data.entity_name_en) {
    data.display_name_en = data.entity_name_en
  }
  if (data.entity_name_cn) {
    data.display_name_cn = data.entity_name_cn
  }
  return data
}

export function replaceTargetName(items: ITreeAggregationItem[]) {
  for (const item of items) {
    if (item.entity_name_en) {
      item.name_en = item.entity_name_en
    }
    if (item.entity_name_cn) {
      item.name_cn = item.entity_name_cn
    }
    if (item.children?.length) {
      replaceTargetName(item.children)
    }
  }
}

export function transformTargetName(res: IHttpWrap<ISearchResult<ITreeAggregationItem>>) {
  if (res.success) {
    replaceTargetName(res.data.items || [])
  }
  return res
}

export function injectOrgName(data: IAggregationValue): IAggregationValue {
  /** 使用后端处理过的名称，赋值display_name_cn、display_name_en */
  formatOrgName(data, 'display_name_cn', 'display_name_en')
  return data
}

export function injectLangFieldByKeyEnumMap(enumMap: Record<string, string>, data: IAggregationValue): IAggregationValue {
  return {
    ...data,
    display_name_cn: enumMap[data.key],
    display_name_en: enumMap[data.key],
  }
}

export const injectStartupName = (data: IAggregationValue) => {
  return {
    ...data,
    display_name_cn: data.key === 'true' || data.key === '1' ? '是' : '否',
    display_name_en: data.key === 'true' || data.key === '1' ? 'Yes' : 'No',
  }
}

export function replaceDisplayNameByShortLangMap(data: IAggregationValue): IAggregationValue {
  if (!data.short_name_en) return data
  if (data.short_name_en && data.short_name_en.length === 0) return data
  return {
    ...data,
    display_name_en: data.short_name_en[0] || '',
    display_name_cn: data.short_name_cn?.[0] || '',
  }
}

export function aggResultIsEmpty(aggData?: Array<IAggregationSingleResult>): boolean {
  return (
    !aggData ||
    aggData.length === 0 ||
    aggData[0].items.length === 0 ||
    aggData[0].items?.every((i) => i.count === 0) ||
    aggData[0].items?.every((i) => {
      if (i.aggregation_result?.length) {
        return aggResultIsEmpty(i.aggregation_result)
      }
      return false
    })
  )
}
