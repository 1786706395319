import { AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { usePhaseStackBarChartNavList, usePhaseStackBarChartWithIdsNavList } from '@pharmsnap/shared/composition'
import { transAggregationValue2QueryItem } from '@pharmsnap/shared/utils'
import { unref } from '@vue/composition-api'
import { IUseAnalysisTwoDimChartNavListOptions } from '../type'

export function useAnalysisTwoDimChartNavList<T = AggTwoDimDataItem>(options: IUseAnalysisTwoDimChartNavListOptions<T>) {
  const {
    navList,
    query = { type: 'group', must: [], filter: [] },
    appendTo = ['filter', 'filter'],
    chartType,
    interceptors,
    customAggValueTransformer,
    customQueryItemPicker,
    useIds = false,
    tracking,
    adjustSearchParams,
  } = options

  if (useIds) {
    const { onClickStackBar: handleClick } = usePhaseStackBarChartWithIdsNavList({
      query,
      appendTo,
      navList,
      customQueryItemPicker: customQueryItemPicker || innerCustomQueryItemPicker,
      adjustSearchParams,
    })

    return {
      handleClick,
    }
  }

  const { onClickStackBar: onClick } = usePhaseStackBarChartNavList({
    query,
    appendTo,
    navList,
    interceptors,
    customQueryItemPicker: customQueryItemPicker || innerCustomQueryItemPicker,
    tracking,
    adjustSearchParams,
  })

  function innerCustomQueryItemPicker(data: AggTwoDimDataItem) {
    const { x, y } = data

    const customXAxisQuery = customAggValueTransformer?.(x, 'x')

    const customYAxisQuery = y ? customAggValueTransformer?.(y, 'y') : undefined

    const xAxisQuery = customXAxisQuery || transAggregationValue2QueryItem(x._meta.mergedData ? [x, ...x._meta.mergedData] : x)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const yAxisQuery = customYAxisQuery || transAggregationValue2QueryItem(y!._meta.mergedData ? [y!, ...y!._meta.mergedData] : y!)

    return {
      xAxisQuery,
      yAxisQuery,
    }
  }

  function handleClick(data: T) {
    const type = unref(chartType)

    if (type === 'line' || type === 'bar') {
      onClick(data)
    }
  }

  return {
    handleClick,
  }
}
