import { ALL_DEV_STATUS_MAP, drugIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { IDrugIndexField } from '@patsnap/synapse_common_interface'
import { AggStackBarChartConfig } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { SHOW_DATA_ZOOM_LIMIT } from '@pharmsnap/shared/src/chart/constants'
import { getPhaseColor, isInactivePhase } from '@pharmsnap/shared/utils'
import { computed, unref } from '@vue/composition-api'
import { IUseAnalysisTwoDimChartEntityOptions } from '../../type'
import { useAnalysisTwoDimChartEntity } from '../useAnalysisTwoDimChartEntity'

export function useAnalysisDrugTwoDimChartEntity(options: Omit<IUseAnalysisTwoDimChartEntityOptions, 'getChartConfig'> & { showDataZoom?: boolean }) {
  const { xAxisName, yAxisName, layout, showDataZoom } = options
  const { locale } = useLocale()

  const getStackChartConfig: IUseAnalysisTwoDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { secondAggField, aggData } = config
    const chartConfig: Partial<AggStackBarChartConfig> = {}

    if (secondAggField === 'DEV_STATUS') {
      if (type === 'bar') {
        chartConfig.showOrder = true
        chartConfig.showDataZoom = showDataZoom ?? aggData?.items?.length > SHOW_DATA_ZOOM_LIMIT
        chartConfig.showTotalCount = true
        chartConfig.xSorter = (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        }
        chartConfig.color = (item) => {
          if (item._meta.after === 'Inactive' || item._meta.after === '非在研') {
            return '#6895F8'
          }
          return getPhaseColor(item.key)
        }
        chartConfig.xGroupStrategy = (item) => {
          if (!item.key || isInactivePhase(item.key)) {
            return locale.value === 'cn' ? '非在研' : 'Inactive'
          }
        }
      }
    }

    return chartConfig
  }

  const { firstDimAggField, secondDimAggField, chartEntity, dataList, uniqEntityDataList } = useAnalysisTwoDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'bar') return getStackChartConfig(type, config)
      return
    },
  })

  // 聚合实体名称，从轴名称中获取
  const entityFieldName = computed(() => (unref(layout) === 'horizontal' ? unref(yAxisName) : unref(xAxisName)))

  // 聚合数量名称，从轴名称中获取
  const countFieldName = computed(() => (unref(layout) === 'horizontal' ? unref(xAxisName) : unref(yAxisName)))

  // 聚合类目名称，从药物语言包获取
  const stackFieldName = computed(() => drugIndexFieldLangMap[unref(secondDimAggField) as IDrugIndexField]?.[unref(locale)] || '')

  return {
    firstDimAggField,
    secondDimAggField,
    chartEntity,
    dataList,
    uniqEntityDataList,
    entityFieldName,
    countFieldName,
    stackFieldName,
  }
}
