import { defineComponent } from '@vue/composition-api'
export const GRedPoint = defineComponent({
  name: 'GRedPoint',
  props: {
    showRedPoint: {
      type: Boolean,
      default: false,
    },
    redPointPositionCls: {
      type: String,
      default: '',
    },
    redPointSizeCls: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {}
  },
  render() {
    const redPointPosition = this.redPointPositionCls || 'top-0 right-0'
    const redPointSize = this.redPointSizeCls || 'w-1.5 h-1.5'
    return (
      <div class="relative">
        {this.showRedPoint && <div class={['absolute rounded-full bg-red-default', redPointPosition, redPointSize]}></div>}
        {this.$slots.default}
      </div>
    )
  },
})
