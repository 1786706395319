import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import { BFilterItemSkeleton } from '@pharmsnap/shared/src/components'
import { defineComponent } from '@vue/composition-api'

export const BFilterSkeleton = defineComponent({
  name: 'BFilterSkeleton',
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  render() {
    return (
      <ElSkeleton loading={true} animated={this.animated}>
        <template slot="template">
          <div class="flex flex-col">
            <ElSkeletonItem class="h-5 w-[70px]"></ElSkeletonItem>
            <ElSkeletonItem class="h-5 mt-2 w-[80px]"></ElSkeletonItem>
            <ElSkeletonItem class="h-[30px] mt-2"></ElSkeletonItem>
            <div class="mt-4">
              <BFilterItemSkeleton animated={this.animated} rounded={this.rounded}></BFilterItemSkeleton>
            </div>
            <ElSkeletonItem class="h-[30px] mt-4"></ElSkeletonItem>
            <div class="mt-4">
              <BFilterItemSkeleton animated={this.animated} rounded={this.rounded}></BFilterItemSkeleton>
            </div>
            <ElSkeletonItem class="h-[30px] mt-4"></ElSkeletonItem>
            <ElSkeletonItem class="h-[30px] mt-2"></ElSkeletonItem>
          </div>
        </template>
      </ElSkeleton>
    )
  },
})
