import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { defineComponent } from '@vue/composition-api'
import $cardClasses from '../EntityCard.module.scss'

export const BEntityLoadingCard = defineComponent({
  name: 'BEntityLoadingCard',
  props: {
    hasImg: {
      type: Boolean,
      default: false,
    },
    hasDesc: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    renderLoadingHeader() {
      const LoadingTitle = <ElSkeletonItem variant="p" style="width: 65%; height: 16px;"></ElSkeletonItem>
      const LoadingDesc = (
        <div>
          <ElSkeletonItem variant="p" style="width: 100%; height: 16px;"></ElSkeletonItem>
          <ElSkeletonItem variant="p" style="width: 65%; height: 16px; margin-top: 8px;"></ElSkeletonItem>
        </div>
      )
      const LoadingImg = <ElSkeletonItem variant="image" style="width: 78px; height: 78px;"></ElSkeletonItem>
      const LoadingTopContent = this.hasImg ? (
        <div class={$utilClasses.lsFlex}>
          <div class={$utilClasses.lsFlexShrink}>{LoadingImg}</div>
          <div style="margin-left: 12px;" class={$utilClasses.lsFlexTruncate}>
            {LoadingTitle}
          </div>
        </div>
      ) : (
        <div class={$utilClasses.lsFlex}>{LoadingTitle}</div>
      )

      return this.hasDesc ? [LoadingTopContent, <div style={`margin-top: ${this.hasImg ? 12 : 8}px`}>{LoadingDesc}</div>] : [LoadingTopContent]
    },
    renderLoadingBody() {
      return [
        <ElSkeletonItem variant="p" style="width: 100%; height: 16px;"></ElSkeletonItem>,
        <ElSkeletonItem variant="p" style="width: 80%; height: 16px; margin-top: 8px;"></ElSkeletonItem>,
        <ElSkeletonItem variant="p" style="width: 65%; height: 16px; margin-top: 8px;"></ElSkeletonItem>,
        <ElSkeletonItem variant="p" style="width: 50%; height: 16px; margin-top: 8px;"></ElSkeletonItem>,
      ]
    },
  },
  render() {
    return (
      <ElSkeleton loading={this.loading} animated={true}>
        <template slot="template">
          <div class={[$cardClasses.card, this.border ? '' : $cardClasses.cardNoBorder]} style="line-height: 1;">
            <div class={$cardClasses.header}>{this.renderLoadingHeader()}</div>
            <div class={$cardClasses.body}>{this.renderLoadingBody()}</div>
          </div>
        </template>
        <template slot="default">{this.$slots.default}</template>
      </ElSkeleton>
    )
  },
})
