import { E_AGGREGATION_TYPE, IAggregationData, IAggregationItem, IAggregationValue, IQuery, IQueryItem } from '@patsnap/synapse_common_interface'
import { BasicBarChart } from '@pharmsnap/shared/chart'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { useChart, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { getYearRange } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import { EChartsType } from 'echarts/core'
import _ from 'lodash'
import { TableOptions } from 'vxe-table'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import { checkAggDataIsEmpty, countColumnFormatter, countRowFormatter, generatePatentAggParams, getGrantedFilterItem } from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentSetting } from '../../ui/PatentSetting/PatentSetting'
import { TimeRangeField } from '../TimeRangeField/TimeRangeField'

const defaultSelectedTime = 20
const defaultCustomTime = [dayjs().subtract(defaultSelectedTime, 'years').year(), dayjs().year()]
const customTimeRange = [1780, dayjs().year()]

export const PatentsTrend = defineComponent({
  name: 'PatentsTrend',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const { locale } = useLocale()
    const ins = getCurrentInstance()
    const { onClickChart } = usePatentChartDrill()

    const selectedTime = ref(`${defaultSelectedTime}`)
    const selectedTimeValue = ref<number[]>(defaultCustomTime)
    const displayCustomTime = ref(defaultCustomTime)

    const params = computed(() => {
      const [start, end] = customTimeRange
      const {
        query: { must, filter = [] },
      } = props.queryService.state

      const customQuery: IQuery = {
        must,
        // 返回指定年份内的数据，即使数据为0也返回
        filter: filter.concat({
          type: 'field',
          fields: ['APD_YEAR'],
          value: [
            {
              type: 'range',
              from: start,
              to: end,
              display_name_cn: '',
              display_name_en: '',
              include_upper: true,
            },
          ],
        }),
        type: 'group',
      }

      const { from, to } = getYearRange(start, end)
      const aggregationTimeRange = { extended_bounds_from: from, extended_bounds_to: to }
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.DATE_HISTOGRAM_YEAR,
          aggregation_field: 'APD_YEAR',
          limit: end - start + 1,
          sort_type: 'index',
          order: 'asc',
          ...aggregationTimeRange,
          sub_aggregation: [
            {
              aggregation_type: E_AGGREGATION_TYPE.QUERY,
              aggregation_field: '',
              tag: 'granted_count',
              query: {
                type: 'group',
                must: [getGrantedFilterItem()],
              },
            },
          ],
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation, customQuery)
    })

    const {
      isFullScreen,
      popoverVisible,
      selectedField,
      isLoading,
      isEmpty,
      data: aggData,
      toggleSelectedField,
      toggleFullScreen,
      togglePopoverVisible,
      getChartData,
    } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getAggregation.bind(sharedCtx.service.patent),
      checkEmptyFn: checkAggDataIsEmpty,
    })

    const handleBarDrill = (params: any) => {
      if ((params.componentIndex === 0 && !params.value.count) || (params.componentIndex === 1 && !params.value.grantedCount)) return
      handleDrill({ title: params.value.key }, params.componentIndex === 0 ? 'application' : 'granted')
    }
    const handleDrill = (tooltipProps: any, type: 'application' | 'granted') => {
      const extraFilter: IQueryItem[] = [
        {
          type: 'field',
          fields: ['APD_YEAR'],
          value: [
            {
              type: 'text',
              value: tooltipProps.title,
              display_name_cn: tooltipProps.title,
              display_name_en: tooltipProps.title,
            },
          ],
        },
      ]
      if (type === 'granted') {
        extraFilter.push(getGrantedFilterItem())
      }
      onClickChart({ queryState: props.queryService.state, extraFilter })
    }
    const chartConfig = {
      autoResize: true,
      registerEvent: (instance: EChartsType) => {
        instance.on('click', handleBarDrill)
      },
    }

    const { isScrollInView, handleScrollInView } = useLazyComponent()
    const chartData = computed(() => {
      if (checkAggDataIsEmpty(aggData.value)) {
        return []
      }
      const items = (aggData.value as IAggregationData<IAggregationValue & { grantedCount: number }>).aggregation_result[0].items.map((item) => ({
        ...item,
        grantedCount: item?.other_info?.granted_count || 0,
      }))
      if (selectedTime.value === 'all') {
        const firstNonzeroIndex = _.findIndex(items, (item) => item.count > 0)
        return items.slice(firstNonzeroIndex)
      }

      const [fromYear, toYear] = selectedTimeValue.value
      return items.filter((item) => _.inRange(Number(item.key), fromYear, toYear + 1))
    })

    const chartOption = computed<BasicBarChart>(() => {
      const _outWidth = chartData.value.length > 30 ? '66%' : '20px'
      const _innerWidth = chartData.value.length > 30 ? '44%' : '14px'
      const opt: BasicBarChart = {
        type: 'bar',
        grid: {
          top: 40,
          bottom: 24,
          left: 20,
          right: 100,
          containLabel: true,
        },
        legend: {
          show: true,
          top: '30%',
          right: locale.value === 'en' ? 10 : 30,
          orient: 'vertical',
          itemWidth: 10,
          itemHeight: 10,
        },
        tooltip: {
          enterable: true,
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            shadowStyle: {
              color: '#F5F7FC',
            },
          },
          hideDelay: 1000,
        },
        dataset: {
          dimensions: [{ name: 'key' }, { name: 'count' }, { name: 'grantedCount' }],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          source: chartData.value,
        },
        xAxis: [
          { type: 'category', name: ins?.proxy.$tc('patentsTrend.applicationYear') },
          {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: { show: false },
          },
        ],
        yAxis: { dataType: { valueType: 'count' }, name: ins?.proxy.$tc('patents') },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            name: ins?.proxy.$tc('application'),
            type: 'bar',
            encode: { x: 'key', y: 'count' },
            xAxisIndex: 0,
            barWidth: _outWidth,
            barMinWidth: 2,
            z: 1,
            cursor: 'pointer',
          },
          {
            name: ins?.proxy.$tc('granted'),
            type: 'bar',
            encode: { x: 'key', y: 'grantedCount' },
            xAxisIndex: 1,
            z: 2,
            barWidth: _innerWidth,
            barMinWidth: 1,
            cursor: 'pointer',
          },
        ],
      }
      return opt
    })
    const { chartContainer } = useChart(chartOption, chartConfig)
    const { chartContainer: fullScreenChartContainer } = useChart(chartOption, chartConfig)
    const applyTime = (data: { type: string; value: string | number[] }) => {
      const { type, value } = data
      selectedTime.value = type
      if (data.type === 'all') {
        selectedTimeValue.value = [1780, dayjs().year()]
      } else {
        selectedTimeValue.value = value as number[]
      }
      if (data.type === 'custom') {
        displayCustomTime.value = value as number[]
      }
    }
    watch(
      () => [props.queryService.state.query, props.queryService.state.collapse],
      () => {
        getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      popoverVisible,
      chartContainer,
      selectedField,
      isFullScreen,
      selectedTime,
      isScrollInView,
      displayCustomTime,
      toggleFullScreen,
      togglePopoverVisible,
      toggleSelectedField,
      applyTime,
      fullScreenChartContainer,
      getChartData,
      chartData,
      handleScrollInView,
      isEmpty,
      isLoading,
    }
  },
  methods: {
    renderContent() {
      if (this.selectedField === 'chart') {
        return (
          <div class="w-full h-full">
            <div ref="chartContainer" class="w-full h-full"></div>
          </div>
        )
      }
      return this.renderTable()
    },
    getFullScreenColumnConfig() {
      const fullScreenColumnConfig = []
      fullScreenColumnConfig.push({
        field: 'col0',
        width: '172px',
        fixed: 'left',
      })
      for (let i = 0; i < this.chartData.length; i++) {
        fullScreenColumnConfig.push({
          field: `col${i + 1}`,
          width: '100px',
          formatter: countRowFormatter,
        })
      }
      return fullScreenColumnConfig
    },
    getFullScreenTableData(columnConfig: Array<{ field: 'key' | 'count' | 'grantedCount'; title: string }>) {
      const fullScreenTableData = []
      for (let i = 0; i < columnConfig.length; i++) {
        const obj: Record<string, string> = {}
        this.chartData.forEach((item, index) => {
          obj[`col${index + 1}`] = String(item[columnConfig[i].field])
        })
        obj.col0 = columnConfig[i].title
        fullScreenTableData.push(obj)
      }
      return fullScreenTableData
    },
    renderTable() {
      let vxeProps: TableOptions = {
        border: true,
        resizable: true,
        showOverflow: true,
        align: 'left',
      }
      const columnConfig: Array<{ field: 'key' | 'count' | 'grantedCount'; title: string; formatter?: ({ cellValue }: { cellValue: any }) => any }> =
        [
          { field: 'key', title: this.$tc('patentsTrend.applicationYear') },
          {
            field: 'count',
            title: this.$tc('patentsApplication'),
            formatter: countColumnFormatter,
          },
          {
            field: 'grantedCount',
            title: this.$tc('patentsGranted'),
            formatter: countColumnFormatter,
          },
        ]
      const fullScreenColumnConfig = this.getFullScreenColumnConfig()
      const fullScreenTableData = this.getFullScreenTableData(columnConfig)

      if (this.isFullScreen) {
        vxeProps = {
          ...vxeProps,
          showHeader: false,
          columns: fullScreenColumnConfig,
          data: fullScreenTableData,
        }
      } else {
        vxeProps = {
          ...vxeProps,
          maxHeight: '100%',
          columns: columnConfig,
          data: this.chartData,
        }
      }
      return <vxe-grid size="small" class={[this.isFullScreen ? $style.fullScreenTable : null]} props={vxeProps}></vxe-grid>
    },
    renderTimeRange() {
      return (
        <TimeRangeField
          title={this.$tc('time.range')}
          selected={this.selectedTime}
          isFullScreen={this.isFullScreen}
          otherTimeConfig={['10', `${defaultSelectedTime}`]}
          defaultCustomTime={this.displayCustomTime}
          onCancel={() => this.togglePopoverVisible(false)}
          onApply={(data: { type: string; value: string | number[] }) => {
            this.togglePopoverVisible(false)
            this.applyTime(data)
          }}
        ></TimeRangeField>
      )
    },
    renderAnalysis() {
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            blockTitle={this.$tc('patentsTrend.patentProfile')}
            title={this.$tc('patentsTrend.title')}
            desc={this.$tc('patentsTrend.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            isEmpty={this.isEmpty}
            isLoading={this.isLoading}
            containerHeight={498}
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="verticalBar"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              <PatentSetting visible={this.popoverVisible} onChangeVisible={this.togglePopoverVisible} tips={this.$tc('common.setting')}>
                {this.renderTimeRange()}
              </PatentSetting>
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            isEmpty={this.isEmpty}
            isLoading={this.isLoading}
            title={this.$tc('patentsTrend.title')}
            desc={this.$tc('patentsTrend.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            isFullScreen
            fullScreenSettings={[{ label: this.$tc('data'), content: this.renderTimeRange }]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div ref="fullScreenChartContainer" class={$style.fullScreenChart}></div>
                <div class="mt-8 flex-shrink-0">{this.renderTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
