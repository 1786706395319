import { computed, defineComponent } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'
export const GTelescopicContainer = defineComponent({
  name: 'GTelescopicContainer',
  props: {
    breakpoint: {
      type: Number,
      default: 1280,
    },
  },
  setup(props) {
    const { width } = useWindowSize()
    const shouldShowContent = computed(() => width.value > props.breakpoint)
    return { shouldShowContent }
  },
  render() {
    return (
      <div class="flex items-center">
        {this.$slots.icon}
        {this.shouldShowContent && <div>{this.$slots.default}</div>}
      </div>
    )
  },
})
