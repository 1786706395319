import { toThousands } from '@patsnap/synapse_common_utils'
import { computed, defineComponent, unref } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { GTree } from '../../../ui/GTree/GTree'
import { BAutoEntityInput } from '../../BAc/BAutoEntityInput/BAutoEntityInput'
import { useFilterUiTreeService } from '../compositions/useFilterTreeService'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterTreeValueNext } from '../type'
import { BFilterAdvanceContentPanel } from './BFilterAdvanceContentPanel'
import $classes from './BFilterAdvanceFullTree.module.scss'

export const BFilterAdvanceContentTreePanel = defineComponent({
  name: 'BFilterAdvanceContentTreePanel',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const service = useInjectFilterService()!

    const selectedState = computed(() => unref(service.filterService.selectedState))

    const identity = computed(() => unref(selectedState).identity)

    const uiType = computed(() => unref(selectedState)._advance.activeUiType)

    const autocompleteType = computed(() => unref(selectedState)._advance.autocompleteType)

    const isLoading = computed(() => unref(selectedState)._advance.isLoading)

    const theme = computed(() => unref(service.filterService.theme))

    return {
      ...useFilterUiTreeService({
        identity,
        uiType,
      }),
      identity,
      selectedState,
      autocompleteType,
      isLoading,
      theme,
      disabled: computed(() => unref(isLoading)),
    }
  },
  methods: {
    renderTreeNode(data: IFilterTreeValueNext) {
      const displayName = unref(this.locale) === 'cn' ? data.value.display_name_cn || data.value.display_name_en : data.value.display_name_en

      const isDisabled = this.checkIsDisabled(data.value)

      const isSelected = this.checkIsSelected(data.value)

      return (
        <span
          onClick={() => this.select(data)}
          data-id={data.uuidPath}
          class={['w-full truncate text-sm', isDisabled || isSelected ? 'cursor-not-allowed' : 'cursor-pointer']}
        >
          <span
            style={{ backgroundColor: this.autocompleteMatchedValues.map((i) => i.uuidPath).includes(data.uuidPath) ? '#F8D50F' : '' }}
            class={[data.count ? 'mr-1 text-text-t1' : 'mr-1 text-text-t2']}
            title={displayName}
          >
            {displayName}
            {isUndefined(data.count) ? '' : ` (${toThousands(data.count)})`}
          </span>
        </span>
      )
    },
    renderContent() {
      if (!this.selectedState) return null

      if (!this.identity) return null

      if (this.isLoading) return null
      return (
        <div ref="treeContainerElRef" class="h-full overflow-y-auto">
          <GTree
            class={$classes.tree}
            nodeKey="uuidPath"
            lazy={false}
            treeData={this.tree}
            showOrgLogo={false}
            expandedIds={this.autocompleteMatchedPathIds}
            showCheckbox={false}
            scopedSlots={{
              default: (scope: { data: IFilterTreeValueNext }) => this.renderTreeNode(scope.data),
            }}
          ></GTree>
        </div>
      )
    },
    renderAutocomplete() {
      if (!this.autocompleteType) return null
      return (
        <BAutoEntityInput
          prefix="SearchRight"
          style="width: 240px;"
          type={this.autocompleteType}
          v-model={this.autocompleteKeywords}
          placeHolder={this.autocompletePlaceholder}
          onSelect={this.onAutocompleteSelect}
          onClear={this.onAutocompleteClear}
          onSearchPrev={this.onAutocompleteSelectPre}
          onSearchNext={this.onAutocompleteSelectNext}
          currentIndex={this.autocompleteDisplayMatchedIndex}
          searchRes={this.autocompleteMatchedValues}
          disabled={this.disabled}
          customFetchAutoComplete={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompleteFetch}
          customShowIcon={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompleteShowIcon}
          customPopoverCls={this.selectedState._meta.originLayoutConfig.uiAggField.advanceAutoCompletePopperClass}
          customTagBoxCls={this.theme === 'workspace' ? 'border-gray-55' : ''}
        ></BAutoEntityInput>
      )
    },
  },
  render() {
    let emptyText = ''

    if (this.isEmpty) {
      emptyText = this.$t('bFilterNext.noData') as string
    } else if (this.isAutocompleteEmpty) {
      emptyText = this.$t('bFilterNext.noDataSearch') as string
    }

    return (
      <BFilterAdvanceContentPanel isLoading={this.isLoading} isEmpty={this.isEmpty || this.isAutocompleteEmpty} emptyText={emptyText}>
        <template slot="topLeft">{this.renderAutocomplete()}</template>
        <template slot="default">{this.renderContent()}</template>
      </BFilterAdvanceContentPanel>
    )
  },
})
