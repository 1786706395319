import { IFieldLangMap } from '@pharmsnap/shared/types/base'

export const ANS_TYPE_LANG: IFieldLangMap = {
  ACADEMY: {
    cn: '院校/研究所',
    en: 'Academy',
  },
  COMPANY: {
    cn: '公司',
    en: 'Company',
  },
  GOVERNMENT: {
    cn: '政府',
    en: 'Government',
  },
  PERSON: {
    cn: '个人',
    en: 'Person',
  },
  HOSPITAL: {
    cn: '医院',
    en: 'Hospital',
  },
  BANK: {
    cn: '银行',
    en: 'Bank',
  },
  OTHER: {
    cn: '其他',
    en: 'Other',
  },
}
