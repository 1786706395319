import { ISearchCollapseRule } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, toRefs } from '@vue/composition-api'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BPtCollapseOptionItem } from './BPtCollapseOptionItem'
import $style from './BPtCollapseOptionPanel.module.scss'
import { PATENT_COLLAPSE_APNO_FIELD } from './config'

export const BPtCollapseOptionPanel = defineComponent({
  name: 'BPtCollapseOptionPanel',
  props: {
    field: {
      type: String,
    },
    data: {
      type: Object as PropType<{ rules: Array<ISearchCollapseRule & { options?: string[] }>; limit: number }>,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const { field } = toRefs(props)
    const title = computed(() => ins?.proxy.$tc(`patent.collapseFields.${props.field}`))
    const tip = computed(() => ins?.proxy.$tc(`patent.collapseTip.${props.field}`))

    const width = computed(() => (field?.value !== PATENT_COLLAPSE_APNO_FIELD ? 720 : 540))

    const handleChangeField = (rule: ISearchCollapseRule, field: string) => {
      ctx.emit('changeField', rule, field)
    }

    const handleChangeType = (rule: ISearchCollapseRule, type: string) => {
      ctx.emit('changeType', rule, type)
    }

    const handleChangeValue = (rule: ISearchCollapseRule, value: string) => {
      ctx.emit('changeValue', rule, value)
    }

    const handleAddRule = (rule: ISearchCollapseRule) => {
      ctx.emit('add', rule)
    }
    const handleDeleteRule = (rule: ISearchCollapseRule) => {
      ctx.emit('delete', rule)
    }

    return {
      title,
      tip,
      handleChangeField,
      handleChangeType,
      handleAddRule,
      handleDeleteRule,
      handleChangeValue,
      width,
    }
  },
  render() {
    return (
      <div class={$style.panel} style={{ width: `${this.width}px` }}>
        <div class={$style.panelHeader}>
          <div class={$style.panelTitle}>{this.title}</div>
          <GTooltip theme="light" mode="icon" content={this.tip}></GTooltip>
        </div>
        <div class={$style.panelBody}>
          <BPtCollapseOptionItem
            data={this.data}
            onChangeField={this.handleChangeField}
            onChangeType={this.handleChangeType}
            onChangeValue={this.handleChangeValue}
            onAdd={this.handleAddRule}
            onDelete={this.handleDeleteRule}
          ></BPtCollapseOptionItem>
        </div>
        <div class={$style.panelFooter}>
          <button class="pt-ui-btn" type="button" data-type="default" onClick={() => this.$emit('cancel')}>
            {this.$tc('common.cancel')}
          </button>
          <button class="pt-ui-btn ml-2" type="button" data-type="submit" onClick={() => this.$emit('confirm')}>
            {this.$tc('common.confirm')}
          </button>
        </div>
      </div>
    )
  },
})
