import '@patsnap-ui/icon/assets/solid/filers.svg'
import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { GTooltip } from '@pharmsnap/shared/components/ui/GTooltip/GTooltip'
import { defineComponent, PropType } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import $classes from './PatentSetting.module.scss'

export const PatentSetting = defineComponent({
  name: 'PatentSetting',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<'displayTips' | 'tooltip' | 'tooltipWithClick' | ''>,
      default: 'tooltipWithClick',
    },
    displayTips: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
    },
    svgName: {
      type: String,
      default: 'SolidFilers',
    },
  },
  setup(props, { emit }) {
    const togglePopoverVisible = (val: boolean) => !props.disabled && emit('changeVisible', val)

    return {
      togglePopoverVisible,
    }
  },
  render() {
    const settingIcon = (
      <GTooltip content={this.tips} disabled={!this.tips || this.mode === 'displayTips'}>
        <GIcon class={this.disabled ? $classes.disable : $classes.setting} svgName={this.svgName} size={32}></GIcon>
      </GTooltip>
    )

    if (this.mode === 'displayTips') {
      return (
        <div class={$classes.settingTips}>
          {settingIcon}
          {this.tips}
        </div>
      )
    }

    if (this.mode === 'tooltip') {
      return settingIcon
    }

    if (this.mode === 'tooltipWithClick') {
      return (
        <div class="relative">
          <div onClick={() => this.togglePopoverVisible(!this.visible)}>{settingIcon}</div>
          {this.visible && (
            <div
              class={$classes.settingContent}
              {...{
                directives: [
                  {
                    name: 'click-outside',
                    value: () => this.togglePopoverVisible(false),
                  },
                ],
              }}
            >
              {this.$slots.default}
            </div>
          )}
        </div>
      )
    }

    return settingIcon
  },
})
