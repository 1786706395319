import { I18nLang } from '@patsnap/synapse_common_interface'
import { nextTick, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { diffChars } from 'diff'
import { useAcInput } from '../../BAc/compositions/useAcInput'

type IUseFreeSearchAutocompleteOptions = {
  locale: MaybeRef<I18nLang>
  mode?: 'full-text' | 'single-word'
  onTextChecker?: (text: string) => boolean
}

/**
 * @deprecated
 */
export function useFreeSearchAutocomplete(options: IUseFreeSearchAutocompleteOptions) {
  const { locale, mode = 'full-text', onTextChecker = () => true } = options

  const {
    keywords: autocompletePrefix,
    autocompleteItems,
    isEmpty: autocompleteIsNotMatch,
  } = useAcInput({
    params: {
      key: 'Core',
    },
    locale,
  })
  const inputElRef = ref<HTMLInputElement | null>(null)
  const inputKeywords = ref('')
  const inputIsComposing = ref(false)
  const inputSelectionStart = ref(0)
  const inputSelectionEnd = ref(0)
  const inputFocused = ref(false)
  const inputDelText = ref('')
  const autocompletePrefixStart = ref(0)
  const autocompletePrefixEnd = ref(0)
  const autocompleteVisible = ref(false)

  function handleCompositionStart() {
    inputIsComposing.value = true
  }

  function handleCompositionUpdate(event: CompositionEvent) {
    inputIsComposing.value = true
    const inputTarget = event.target as HTMLInputElement
    const text = inputTarget.value
    const lastCharacter = text[text.length - 1] || ''
    inputIsComposing.value = !isKorean(lastCharacter)
  }

  function handleCompositionEnd(event: CompositionEvent) {
    if (inputIsComposing.value) {
      inputIsComposing.value = false
      const inputTarget = event.target as HTMLInputElement
      const inputAllText = inputTarget.value

      if (onTextChecker(inputAllText)) {
        if (mode === 'full-text') {
          inputKeywords.value = inputAllText
          autocompletePrefix.value = inputAllText
          autocompletePrefixStart.value = 0
          autocompletePrefixEnd.value = inputAllText.length - 1
        } else {
          updateKeywords(inputAllText, true)
        }
      } else {
        inputTarget.value = inputKeywords.value
      }
    }
  }

  function handleInput(event: InputEvent) {
    if (inputIsComposing.value) return
    const inputTarget = event.target as HTMLInputElement
    const inputAllText = inputTarget.value

    if (onTextChecker(inputAllText)) {
      if (mode === 'full-text') {
        inputKeywords.value = inputAllText
        autocompletePrefix.value = inputAllText
        autocompletePrefixStart.value = 0
        autocompletePrefixEnd.value = inputAllText.length - 1
      } else {
        updateKeywords(inputAllText, false)
      }
    } else {
      inputTarget.value = inputKeywords.value
    }
  }

  function handleSelection(event: UIEvent) {
    const target = event.target as HTMLInputElement
    inputSelectionStart.value = target.selectionStart || 0
    inputSelectionEnd.value = target.selectionEnd || 0
  }

  function handleKeyup(event: KeyboardEvent) {
    if (inputIsComposing.value) return
    const target = event.target as HTMLInputElement
    inputSelectionStart.value = target.selectionStart || 0
    inputSelectionEnd.value = target.selectionEnd || 0
  }

  function handleFocus(event: FocusEvent) {
    const target = event.target as HTMLInputElement
    inputFocused.value = true
    nextTick(() => {
      inputSelectionStart.value = target.selectionStart || 0
      inputSelectionEnd.value = target.selectionEnd || 0
    })
  }

  function handleBlur() {
    inputFocused.value = false
  }

  function isKorean(text: string) {
    const reg = /([(\uAC00-\uD7AF)|(\u3130-\u318F)])+/gi
    return reg.test(text)
  }

  function isEnglish(char: string) {
    const reg = /^[0-9a-zA-Z-]+$/gi
    return reg.test(char)
  }

  function isChinese(char: string) {
    const reg = /^[\u4e00-\u9fa5]+$/gi
    return reg.test(char)
  }

  function resetAutocomplete() {
    autocompletePrefixStart.value = 0
    autocompletePrefixEnd.value = 0
    autocompleteVisible.value = false
    autocompletePrefix.value = ''
    autocompleteIsNotMatch.value = true
  }

  function reset() {
    inputKeywords.value = ''
    inputIsComposing.value = false
    inputSelectionStart.value = 0
    inputSelectionEnd.value = 0
    inputFocused.value = false
    inputDelText.value = ''
    resetAutocomplete()
    if (inputElRef.value) {
      inputElRef.value.value = ''
    }
  }

  function replaceAutocompleteText(text: string) {
    // 将 autocompletePrefixStart 到 autocompletePrefixEnd 之间的文本替换成 text
    // 将字符串拆分成三部分：替换前、替换文本、替换后
    if (!text.trim()) return

    if (mode === 'single-word') {
      const start = unref(autocompletePrefixStart)
      const end = unref(autocompletePrefixEnd)

      if (start === end) return

      const str = unref(inputKeywords)

      const before = str.slice(0, start)
      const after = str.slice(end + 1)

      // 构建最终的替换字符串
      const result = before + text + after

      if (onTextChecker(result)) {
        inputKeywords.value = result
        if (inputElRef.value) {
          inputElRef.value.value = result
        }

        autocompletePrefix.value = ''
        autocompletePrefixStart.value = 0
        autocompletePrefixEnd.value = 0
      }
    } else {
      if (onTextChecker(text)) {
        inputKeywords.value = text
        autocompletePrefix.value = ''
        autocompletePrefixStart.value = 0
        autocompletePrefixEnd.value = 0
        if (inputElRef.value) {
          inputElRef.value.value = text
        }
      }
    }
  }

  function updateKeywords(keywords: string, isComposing: boolean) {
    const oldKeywords = unref(inputKeywords)

    const newKeywords = keywords

    const diffResult = diffChars(oldKeywords, newKeywords)

    const lastDiffRt = diffResult[diffResult.length - 1]

    const headDiffRt = diffResult[0]

    const hasAdd = diffResult.some((item) => item.added)

    const hasDel = diffResult.some((item) => item.removed)

    const isAddAction = hasAdd && !hasDel

    const isDelAction = hasDel && !hasAdd

    const isMultiAction = hasAdd && hasDel

    const isAddTextInLast = isAddAction && diffResult[diffResult.length - 1].added

    const isAddTextInHead = isAddAction && diffResult[0].added

    const isAddTextInMiddle = isAddAction && !isAddTextInHead && !isAddTextInLast

    const isDelTextInLast = isDelAction && diffResult[diffResult.length - 1].removed

    const isDelTextInHead = isDelAction && diffResult[0].removed

    const isDelTextInMiddle = isDelAction && !isDelTextInHead && !isDelTextInLast

    const lastKeywordIndex = Math.max(newKeywords.length - 1, 0)

    // 在头部添加文本
    if (isAddTextInHead) {
      // autocomplete的关键词就是新增的文本
      autocompletePrefix.value = headDiffRt.value
      autocompletePrefixStart.value = 0
      autocompletePrefixEnd.value = headDiffRt.value.length - 1
    } else if (isAddTextInLast || isAddTextInMiddle) {
      // 在末尾添加文本
      const diffRtItemIndex = diffResult.findIndex((i) => i.added)
      const diffRtItem = diffRtItemIndex === -1 ? null : diffResult[diffRtItemIndex]
      if (diffRtItemIndex === -1 || !diffRtItem) return
      if (isComposing) {
        autocompletePrefix.value = diffRtItem.value
        autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
        autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
      } else {
        const originDiffRtList = diffResult.slice(0, diffRtItemIndex)
        const originDiffRtLen = originDiffRtList.reduce((prev, curr) => prev + curr.value.length, 0)
        const originLastCharIndex = originDiffRtLen - 1
        // console.log({
        //   oldKeywords,
        //   originLastCharIndex,
        //   end: autocompletePrefixEnd.value,
        //   diffResult,
        // })
        // 判断是否是在autocomplete文本后面添加内容
        if (originLastCharIndex === autocompletePrefixEnd.value) {
          const lastChar = oldKeywords[originLastCharIndex]
          if (lastChar && isEnglish(lastChar)) {
            if (isEnglish(diffRtItem.value)) {
              autocompletePrefix.value += diffRtItem.value
              autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
              autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
            } else if (diffRtItem.value === ' ') {
              autocompletePrefix.value = ''
              autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
              autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
            }
          }
          if (lastChar && isChinese(lastChar)) {
            if (isEnglish(diffRtItem.value)) {
              autocompletePrefix.value = diffRtItem.value
              autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
              autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
            } else if (diffRtItem.value === ' ') {
              autocompletePrefix.value = ''
              autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
              autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
            }
          }
          if (!lastChar) {
            autocompletePrefix.value = diffRtItem.value
            autocompletePrefixEnd.value = lastKeywordIndex
            autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
          }
        } else {
          autocompletePrefix.value = diffRtItem.value
          autocompletePrefixEnd.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
          autocompletePrefixStart.value = autocompletePrefixEnd.value - autocompletePrefix.value.length + 1
        }
      }
    } else if (isDelTextInHead || isDelTextInMiddle) {
      // 在头部删除文本 or 在中间删除文本
      autocompletePrefix.value = ''
      autocompletePrefixStart.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
      autocompletePrefixEnd.value = autocompletePrefixStart.value
    } else if (isDelTextInLast) {
      // 在末尾删除文本
      const delTextStartIndex = oldKeywords.lastIndexOf(lastDiffRt.value)
      const currentAutocompletePrefixStart = autocompletePrefixStart.value
      // 如果删除的字符落在autocomplete的区间内，更新下autocomplete的区间
      if (delTextStartIndex >= currentAutocompletePrefixStart && delTextStartIndex <= autocompletePrefixEnd.value) {
        autocompletePrefixEnd.value = lastKeywordIndex
        autocompletePrefix.value = newKeywords.slice(currentAutocompletePrefixStart, autocompletePrefixEnd.value + 1)
      } else {
        autocompletePrefix.value = ''
        autocompletePrefixStart.value = lastKeywordIndex
        autocompletePrefixEnd.value = lastKeywordIndex
      }
    } else if (isMultiAction) {
      autocompletePrefix.value = ''
      autocompletePrefixStart.value = Math.max((inputElRef.value?.selectionStart || 0) - 1, 0)
      autocompletePrefixEnd.value = autocompletePrefixStart.value
    }

    inputKeywords.value = newKeywords
  }

  return {
    inputKeywords,
    inputElRef,
    inputSelectionStart,
    inputDelText,
    inputSelectionEnd,
    autocompleteItems,
    autocompleteIsNotMatch,
    autocompletePrefixStart,
    autocompletePrefixEnd,
    autocompletePrefix,
    handleCompositionEnd,
    handleCompositionStart,
    handleCompositionUpdate,
    handleInput,
    handleFocus,
    handleBlur,
    inputFocused,
    handleSelection,
    handleKeyup,
    reset,
    replaceAutocompleteText,
    resetAutocomplete,
  }
}
