import { sharedCtx } from '@pharmsnap/shared/context'

export async function getAnalyticsPnUrl(ids: string[]) {
  const domain = sharedCtx.VUE_APP_ANALYTICS_URL
  const res = await sharedCtx.service.patent.getPnByPatentId(ids)
  if (res.success) {
    const query = res.data.join(' OR ')
    const url = `${domain}/search/result/tablelist/1?sort=sdesc&limit=100&q=PN:(${query})&_type=query`
    return url
  }
  return ''
}
