import { IInvestment, IVCInvestmentInfoNew } from '@pharmsnap/shared/types'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BInvestmentCard } from './BInvestmentCard'

export const BInvestmentPopperCard = defineComponent({
  name: 'BInvestmentPopperCard',
  props: {
    data: {
      type: Object as PropType<IVCInvestmentInfoNew>,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String as PropType<IInvestment>,
      required: true,
      default: 'invest',
    },
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper data={{ data: this.data, type: this.type }} tippyConfig={this.tippyConfig} component={BInvestmentCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
