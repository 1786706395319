import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.KNOWLEDGE_GRAPH,
  hideQuickSearch: true,
  title: createWebTitle('知识图谱', 'Knowledge Graph'),
}

export default [
  {
    path: `knowledge-graph`,
    name: E_ROUTER_NAME.KNOWLEDGE_EXPLORE,
    component: () => import('@pharmsnap/pharmsnap-web/views/knowledgeExplore/KnowledgeExplore'),
    meta,
  },
  {
    path: `knowledge-graph/:${E_ROUTER_PARAMS.KNOWLEDGE_GRAPH_ID}`,
    name: E_ROUTER_NAME.KNOWLEDGE_GRAPH,
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.KNOWLEDGE_GRAPH_ID],
    }),
    component: () => import('@pharmsnap/pharmsnap-web/views/knowledgeExplore/views/knowledgeGraph/KnowledgeGraphEntry'),
    meta: {
      ...meta,
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.KNOWLEDGE_GRAPH_DETAIL,
    },
  },
] as RouteConfig[]
