import { USAGE_LIMIT_ERROR_CODE } from 'pharmsnapMF_shared/constants'

/**
 * 接口 error code
 * https://confluence.zhihuiya.com/display/DLS/Error+Codes+-+s-pharmsnap-webapi
 */
export const ERROR_CODE_MAP = Object.freeze({
  NO_PERMISSION_CODE_100063: 100063,
  SESSION_EXPIRED_100064: 100064,
  /** 账号触发限流（单次）*/
  READ_LIMIT_SINGLE_130070: 130070,
  /** 7天内3次超过最大容量 */
  READ_LIMIT_SEVEN_DAY_130097: 130097,
  /** ip触发限流（单次） */
  IP_READ_LIMIT_SINGLE_130080: 130080,
  /** 免费用户search 达到阈值 */
  SEARCH_THRESHOLD_130071: 130071,
  /** 用户detail 达到阈值 */
  DETAIL_THRESHOLD_130073: 130073,
  /** 调用/chat接口每30次需要做一次人机检验 https://confluence.zhihuiya.com/pages/viewpage.action?pageId=215030946 */
  CHAT_GEETEST_NEED_160014: 160014,
  /** 前端人机校验失败 小于3次  https://confluence.zhihuiya.com/pages/viewpage.action?pageId=215030946 */
  CHAT_GEETEST_WAIT_160007: 160007,
  /** 前端人机校验失败 大于等于3次 https://confluence.zhihuiya.com/pages/viewpage.action?pageId=215030946 */
  CHAT_GEETEST_BLOCK_160004: 160004,
  /** 免费用户容量控制超限 */
  THRESHOLD_130066: 130066,
  /** 限流触发次数超限 */
  READ_LIMIT_130067: 130067,
  /** geetest验证失败被block */
  GEETEST_BLOCK_130077: 130077,
  /** 极验验证失败 等待 */
  GEETEST_WAIT_130079: 130079,
  /** 触发限流（拦截所有接口） */
  READ_FAST_130091: 130091,
  /** 免费用户search 达到最大值 */
  READ_FAST_130072: 130072,
  /** 免费用户detail 达到阈值 */
  CAPACITY_CONTROL_130073: 130073,
  /** 免费用户detail 达到最大值 */
  CAPACITY_CONTROL_130074: 130074,
  /** 免费用户没有绑定微信 */
  NOT_BIND_WECHAT_130101: 130101,
  /** 访问别人dashboard无权限 */
  NOT_AUTHORIZED_DASHBOARD__130103: 130103,
  NOT_EXIST_DASHBOARD__130104: 130104,
  /** 药物导出总量达到阈值 */
  DRUG_EXPORT_130091L: '130091L',
  /** 权限用量，使用次数达到上限 */
  USAGE_LIMIT_130081: USAGE_LIMIT_ERROR_CODE,
  /** 集团导出用量，使用次数达到上限 */
  GROUP_EXPORT_LIMIT_ERROR_CODE_130136: 130136,
  /** 无权限 */
  WITHOUT_ACCESS_10004: 10004,
  /** 需要补全 */
  NEED_COMPLETE_INFO_130122: 130122,
  /** 没有查看他人邮件提醒的权限 */
  NOT_AUTHORIZED_EMAIL_ALERT_130130: 130130,
  /** 当前邮件提醒被删除 */
  HAD_DELETE_EMAIL_ALERT_130131: 130131,
  /** 邮件历史已过期 */
  EMAIL_HISTORY_EXPIRED_130132: 130132,
})
