/* eslint-disable @typescript-eslint/no-explicit-any */
import { toThousands } from '@patsnap/synapse_common_utils'
import { AggBarChartEntity, AggOneDimDataItem } from '@patsnap/synapse_domain'
import { computed, defineComponent, onMounted, PropType, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import { EChartsOption } from 'echarts'
import { cloneDeep } from 'lodash'
import { BEntityFetchCard } from '../../../card/BEntityFetchCard'
import { useAnalysisChart } from '../../compositions/useAnalysisChart'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { IAnalysisDisplayIconType } from '../../type'
import { BAnalysisEChart } from './BAnalysisEchart'

type AnalysisBarEchartTooltipData = {
  seriesName: string
  categoryName: string
  count: number
  color: string
}

export const BAnalysisBarEchart = defineComponent({
  name: 'BAnalysisBarEchart',
  props: {
    entity: {
      type: Object as PropType<AggBarChartEntity>,
      required: true,
    },
    popperEnable: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String as PropType<IAnalysisDisplayIconType>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)

    const { tooltipRef, tooltipCommonConfig, tooltipActiveData, tooltipFormatter, resetTooltipDataMap } =
      useAnalysisChartTooltip<AnalysisBarEchartTooltipData>({
        pickTooltipDataFromChartParam,
      })

    const { entityType, entityId, entityNameInChart, handleAxisClick, handleXAxisMouseover, handleYAxisMouseover } = useAnalysisChart({
      entity,
    })

    const realOption = shallowRef<EChartsOption>()

    const layout = computed(() => unref(entity).layout)

    const xPopperEnable = computed(() => props.popperEnable && layout.value === 'vertical')

    const yPopperEnable = computed(() => props.popperEnable && layout.value === 'horizontal')

    function updateRealOption() {
      const option = cloneDeep(unref(entity).getEchartsOptions())

      if (option) {
        option.tooltip = {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            z: -1,
            shadowStyle: {
              color: '#F5F7FC',
            },
          },

          enterable: false,
          hideDelay: 500,
          triggerOn: 'mousemove|click',
          formatter: tooltipFormatter,
          ...tooltipCommonConfig,
        }

        option.animation = false
      }

      realOption.value = option
    }

    function pickTooltipDataFromChartParam(params: any) {
      if (!Array.isArray(params)) return
      const firstParam = params[0]
      if (!firstParam) return
      const seriesName = firstParam.seriesName
      if (!seriesName) return
      const value = firstParam.data as AggOneDimDataItem
      const info: AnalysisBarEchartTooltipData = {
        seriesName: seriesName,
        categoryName: value.name,
        count: value.count,
        color: toThousands(firstParam.color),
      }
      return [value.name, info] as [string, AnalysisBarEchartTooltipData]
    }

    function handleClickItem(params: any) {
      if (!params) return
      if (!params.data) return
      emit('clickItem', params.data as AggOneDimDataItem)
    }

    onMounted(() => {
      updateRealOption()
    })

    watch(entity, () => {
      resetTooltipDataMap()
      updateRealOption()
    })

    return {
      tooltipRef,
      realOption,
      tooltipActiveData,
      xPopperEnable,
      yPopperEnable,
      entityId,
      entityType,
      entityNameInChart,
      layout,
      handleClickItem,
      handleAxisClick,
      handleXAxisMouseover,
      handleYAxisMouseover,
      ...useAnalysisEchartsHelpers(),
    }
  },
  methods: {
    renderEntityCardPopper() {
      if (!this.entityId || !this.entityType) return null
      return <BEntityFetchCard id={this.entityId} type={this.entityType}></BEntityFetchCard>
    },
    renderEntityTextPopper() {
      return <div class="rounded bg-white-default text-text-t1 text-xs py-2 px-3">{this.entityNameInChart}</div>
    },
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        displayType={this.displayType}
        option={this.realOption}
        axisPopperOption={{ xPopperEnable: this.xPopperEnable, yPopperEnable: this.yPopperEnable }}
        {...{
          on: {
            click: this.handleClickItem,
            axisClick: this.handleAxisClick,
            xAxisMouseover: this.handleXAxisMouseover,
            yAxisMouseover: this.handleYAxisMouseover,
          },
        }}
      >
        <template slot="xAxisPopper">
          {!this.xPopperEnable ? null : this.layout === 'horizontal' ? this.renderEntityTextPopper() : this.renderEntityCardPopper()}
        </template>
        <template slot="yAxisPopper">
          {!this.yPopperEnable ? null : this.layout === 'horizontal' ? this.renderEntityCardPopper() : this.renderEntityTextPopper()}
        </template>
        <template slot="extra">
          <div ref="tooltipRef">
            <div class="p-2">
              <div class="flex items-center mb-2">
                <span
                  class="flex-shrink-0 inline-block h-[10px] w-[10px] rounded-full mr-2"
                  style={{ backgroundColor: this.tooltipActiveData?.color }}
                ></span>
                <span class="text-xs leading-4 font-bold text-text-t1">{this.tooltipActiveData?.categoryName}</span>
              </div>
              <div class="pl-[18px] mt-2">
                <div class="text-xs leading-4">
                  <span class="text-text-t1">{this.tooltipActiveData?.seriesName}: </span>
                  <span class="text-text-t2">{this.tooltipActiveData?.count}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </BAnalysisEChart>
    )
  },
})
