import { ITargetCardInfo } from '@pharmsnap/shared/types'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { useTargetFetchCard } from '../useEntityFetchCard'
import { BTargetCard } from './BTargetCard'

export const BTargetFetchCard = defineComponent({
  name: 'BTargetFetchCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props)
    const { loading, data } = useTargetFetchCard(id)
    return {
      loading,
      data,
    }
  },
  render() {
    return (
      <BTargetCard
        style={{ width: `${this.width}px` }}
        loading={this.loading}
        data={this.data as ITargetCardInfo}
        direction={this.direction}
        border={this.border}
      ></BTargetCard>
    )
  },
})
