import { IUserRole } from '@patsnap/synapse_common_interface'
import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { useUpgrade } from '@pharmsnap/pharmsnap-web/views/Upgrade/hook/useUpgrade'
import '@pharmsnap/shared/assets/icon-svg/upgrade.svg'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { computed, defineComponent } from '@vue/composition-api'
import $style from './BRoleTag.module.scss'
const roleConfigMap: Record<
  IUserRole,
  {
    cn: string
    en: string
    className: string
    showUpgrade?: boolean
  }
> = {
  FREE: {
    cn: '基础版',
    en: 'Basic',
    className: $style.gray,
    showUpgrade: true,
  },
  BASIC: {
    cn: IS_CN_REGION ? '标准版' : '专业版',
    en: IS_CN_REGION ? 'Standard' : 'Premium',
    className: $style.blue,
  },
  TRIAL: {
    cn: '专业版 (试用)',
    en: 'Premium (Trial)',
    className: IS_CN_REGION ? $style.gold : $style.blue,
  },
  ENTERPRISE: {
    cn: '专业版',
    en: 'Premium',
    className: $style.gold,
  },
}

export const BRoleTag = defineComponent({
  name: 'BRoleTag',
  props: {
    triggerPoint: {
      type: String,
    },
  },
  setup(props, { root }) {
    const {
      state: { role },
    } = useAuthStore()
    const { isCN } = useLocale()
    const { onViewPlans } = useUpgrade()
    const config = computed(() => {
      return roleConfigMap[role.value]
    })
    function onShowUpgrade() {
      onViewPlans(props.triggerPoint)
    }
    return { config, isCN, onShowUpgrade }
  },
  render() {
    return (
      <span class={[$style.main, this.config.className]} onClick={this.onShowUpgrade}>
        {this.isCN ? this.config.cn : this.config.en}
      </span>
    )
  },
})
