import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.COMPETITIVE_LANDSCAPE,
}

export default [
  {
    path: `competitive-landscape`,
    name: E_ROUTER_NAME.COMPETITIVE_LANDSCAPE,
    component: () => import('@pharmsnap/pharmsnap-web/views/competitive/Competitive'),
    meta: {
      ...meta,
      title: createWebTitle('竞争格局', 'Competitive Landscape'),
      hideQuickSearch: true,
    },
  },
] as RouteConfig[]
