import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export function useYearRange(years: (1 | 3 | 5 | 10)[], defaultYear?: 1 | 3 | 5 | 10) {
  const ins = getCurrentInstance()
  const selectedYear = ref(defaultYear || 1)
  const options = computed(() => {
    return years.map((i) => ({
      label: ins?.proxy.$tc(`common.yearRange.${i}`) || '',
      value: i,
    }))
  })

  return {
    selectedYear,
    options,
  }
}
