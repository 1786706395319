import { IS_CN_REGION, VUE_APP_CLARITY_ID } from '@pharmsnap/pharmsnap-web/config'
import { IUserInfo } from '@pharmsnap/shared/types/login'
/**
 * 初始化clarity
 * @param userInfo
 */
export function initClarity(userInfo?: IUserInfo) {
  if (!IS_CN_REGION) {
    return
  }
  if (!VUE_APP_CLARITY_ID) {
    return
  }
  if (userInfo?.phs_role !== 'FREE') {
    return
  }
  if (userInfo.email && userInfo.email.includes('patsnap')) {
    return
  }
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${VUE_APP_CLARITY_ID}");`
  document.head.appendChild(script)
}
