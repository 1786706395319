import UserSetting from '@patsnap-biz/user-setting'
import { ELPopupManager } from '@pharmsnap/shared/element-ui'
import { ISearchCollapseRule } from '@pharmsnap/shared/types'

export const getPtTippyConfig = (zIndex?: number) => ({
  interactive: true,
  arrow: false,
  trigger: 'mouseenter click',
  zIndex: typeof zIndex === 'undefined' ? ELPopupManager.nextZIndex() : zIndex,
  placement: 'bottom-start',
  maxWidth: '360px',
  delay: [200, 0],
  offset: 0,
  appendTo: 'parent',
})

export const COUNTRY_PLACEHOLDER = '_'

// 所有可选的国家
export const COUNTRY_2_CODE_LIST: string[] = Object.freeze(UserSetting.settingConfig.settingConfig.authorityType)

// // 默认国家优先级排序 西方
// export const US_FAMILY_COUNTRY_2_CODE_LIST = Object.freeze(['US', 'EP', 'WO', 'JP', 'KR'])

// // 默认国家优先级排序 中国
// export const CN_FAMILY_COUNTRY_2_CODE_LIST = Object.freeze(['CN', 'US', 'EP', 'JP', 'KR'])

// pharmsnap和core默认顺序不一样，且不用区分中美
// 默认国家优先级排序 西方
export const US_FAMILY_COUNTRY_2_CODE_LIST = Object.freeze(['WO', 'US', 'EP', 'CN', 'JP'])

// 默认国家优先级排序 中国
export const CN_FAMILY_COUNTRY_2_CODE_LIST = Object.freeze(['WO', 'US', 'EP', 'CN', 'JP'])

const DEFAULT_COUNTRY_LEN = US_FAMILY_COUNTRY_2_CODE_LIST.length

export const PATENT_COLLAPSE_ALL_FIELD = '__ALL__'

export const PATENT_COLLAPSE_APNO_FIELD = 'APNO_FACET'

const PATENT_COLLAPSE_FAMILY_FIELD = 'FAMILY_ID'
const PATENT_COLLAPSE_EXTEND_FAMILY_FIELD = 'EXTEND_FAMILY'

const PATENT_COLLAPSE_INPADOC_FIELD = 'INPADOC_FAMILY_ID'

/**
 * 支持的折叠
 * https://confluence.zhihuiya.com/pages/viewpage.action?pageId=115869571
 */
export const PATENT_COLLAPSE_FIELDS = Object.freeze([
  PATENT_COLLAPSE_ALL_FIELD,
  PATENT_COLLAPSE_APNO_FIELD,
  PATENT_COLLAPSE_FAMILY_FIELD,
  PATENT_COLLAPSE_INPADOC_FIELD,
  PATENT_COLLAPSE_EXTEND_FAMILY_FIELD,
])

export const PATENT_OTHER_COLLAPSE_FIELDS = Object.freeze([
  PATENT_COLLAPSE_APNO_FIELD,
  PATENT_COLLAPSE_FAMILY_FIELD,
  PATENT_COLLAPSE_INPADOC_FIELD,
  PATENT_COLLAPSE_EXTEND_FAMILY_FIELD,
])

export const OPTION_COUNTRY_FIELD = 'COUNTRY'

export const OPTION_APD_FIELD = 'APD_YEARMONTHDAY'

export const OPTION_PBDT_FIELD = 'PBDT_YEARMONTHDAY'

export const OPTION_LABEL_FIELD = 'PHS_LABEL_TYPE'

const OPTION_FIELDS = Object.freeze([OPTION_LABEL_FIELD, OPTION_COUNTRY_FIELD, OPTION_APD_FIELD, OPTION_PBDT_FIELD])

export const RELATED_OPTION_FIELD = 'score'

const INIT_OTHER_RULE: ISearchCollapseRule = {
  type: 'max',
  field: OPTION_PBDT_FIELD,
}
const INIT_LABEL_FIELD: ISearchCollapseRule = {
  type: 'max',
  field: OPTION_LABEL_FIELD,
}

export const DEFAULT_TYPE_RECORD: Record<string, ISearchCollapseRule['type']> = {
  [OPTION_COUNTRY_FIELD]: 'sort',
  [OPTION_APD_FIELD]: 'max',
  [OPTION_PBDT_FIELD]: 'max',
  [OPTION_LABEL_FIELD]: 'max',
  [RELATED_OPTION_FIELD]: 'relevant',
}

export const COLLAPSE_FIELDS_OPTION_RECORD: Record<string, { limit: number; fields: string[]; options: string[] }> = {
  [PATENT_COLLAPSE_ALL_FIELD]: {
    limit: 0,
    fields: [],
    options: [],
  },
  [PATENT_COLLAPSE_APNO_FIELD]: {
    limit: 2,
    fields: [OPTION_LABEL_FIELD, OPTION_PBDT_FIELD],
    options: [OPTION_LABEL_FIELD, OPTION_PBDT_FIELD],
  },
  [PATENT_COLLAPSE_FAMILY_FIELD]: {
    limit: 3,
    fields: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
    options: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
  },
  [PATENT_COLLAPSE_INPADOC_FIELD]: {
    limit: 3,
    fields: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
    options: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
  },
  [PATENT_COLLAPSE_EXTEND_FAMILY_FIELD]: {
    limit: 3,
    fields: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
    options: [...OPTION_FIELDS, RELATED_OPTION_FIELD],
  },
}

export const getInitOptionRecord = (rules: ISearchCollapseRule[], serviceArea: 'cn' | 'en') => {
  const optionsRecord: Record<string, { rules: Array<ISearchCollapseRule & { options?: string[] }>; limit: number }> = {
    [PATENT_COLLAPSE_ALL_FIELD]: {
      rules: [],
      limit: COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_ALL_FIELD].limit,
    },
    [PATENT_COLLAPSE_APNO_FIELD]: {
      rules: [
        { ...INIT_LABEL_FIELD, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_APNO_FIELD].options] },
        { ...INIT_OTHER_RULE, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_APNO_FIELD].options] },
      ],
      limit: COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_APNO_FIELD].limit,
    },
    [PATENT_COLLAPSE_FAMILY_FIELD]: {
      rules: [
        { ...INIT_LABEL_FIELD, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_FAMILY_FIELD].options] },
        { ...INIT_OTHER_RULE, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_FAMILY_FIELD].options] },
      ],
      limit: COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_FAMILY_FIELD].limit,
    },
    [PATENT_COLLAPSE_EXTEND_FAMILY_FIELD]: {
      rules: [
        { ...INIT_LABEL_FIELD, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_EXTEND_FAMILY_FIELD].options] },
        { ...INIT_OTHER_RULE, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_EXTEND_FAMILY_FIELD].options] },
      ],
      limit: COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_EXTEND_FAMILY_FIELD].limit,
    },
    [PATENT_COLLAPSE_INPADOC_FIELD]: {
      rules: [
        { ...INIT_LABEL_FIELD, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_INPADOC_FIELD].options] },
        { ...INIT_OTHER_RULE, options: [...COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_INPADOC_FIELD].options] },
      ],
      limit: COLLAPSE_FIELDS_OPTION_RECORD[PATENT_COLLAPSE_INPADOC_FIELD].limit,
    },
  }

  if (rules && rules.length > 0) {
    PATENT_OTHER_COLLAPSE_FIELDS.forEach((field) => {
      const supportFields = COLLAPSE_FIELDS_OPTION_RECORD[field]?.fields || []
      const supportOptions = COLLAPSE_FIELDS_OPTION_RECORD[field]?.options || []
      const filteredRules = rules.filter((i) => (supportFields || []).includes(i.field))
      if (filteredRules.length) {
        const tempOptions: string[] = []
        optionsRecord[field].rules = filteredRules.map((rule) => {
          const res = { ...rule, options: supportOptions.filter((i) => !tempOptions.includes(i)) }
          tempOptions.push(rule.field)
          return res
        })
      } else {
        optionsRecord[field].rules[0].options = [...supportOptions]
      }
      const countryRule = (optionsRecord[field].rules || []).find((i) => i.field === OPTION_COUNTRY_FIELD)
      if (countryRule) {
        const countryStr = countryRule.value || ''
        if (!countryStr) {
          countryRule.value = serviceArea === 'cn' ? CN_FAMILY_COUNTRY_2_CODE_LIST.join(',') : US_FAMILY_COUNTRY_2_CODE_LIST.join(',')
        } else {
          const countries = countryStr.split(',').slice(0, DEFAULT_COUNTRY_LEN)
          if (countries.length < DEFAULT_COUNTRY_LEN) {
            let offset = DEFAULT_COUNTRY_LEN - countries.length
            while (offset) {
              countries.push(COUNTRY_PLACEHOLDER)
              offset--
            }
            countryRule.value = countries.join(',')
          }
        }
      }
    })
  }

  return optionsRecord
}
