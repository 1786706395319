import { IQueryDataType } from '@patsnap/synapse_common_interface'
import { IConsumer } from '../types'

/**
 * 列表页的consumer
 */
export const listPageConsumerMap: Partial<Record<IQueryDataType, IConsumer>> = {
  drug: 'DRUG_LIST',
  clinical_trial: 'CLINICAL_LIST',
  patent: 'PATENT_LIST',
  news: 'NEWS_LIST',
  paper: 'PAPER_LIST',
  drug_deal: 'DRUG_DEAL_LIST',
}
