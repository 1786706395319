import { PatentLegalStatusService } from '@pharmsnap/shared/src/domain/patentLegalStatus/patentLegalStatus.service'
import { defineComponent, ref } from '@vue/composition-api'
import { TagLegal } from 'patsnap-biz'
export const BTagLegal = defineComponent({
  name: 'BTagLegal',
  props: {
    /**
     * 法律状态或法律事件状态码
     */
    code: {
      required: true,
      type: Number,
    },
    /**
     * 是否简单法律状态
     */
    simple: {
      type: Boolean,
      default: false,
    },
    /**
     * 法律状态是否是plain
     */
    isPlain: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const patentLegalStatusService = PatentLegalStatusService.createSingleton()
    const inited = ref(patentLegalStatusService.inited)
    if (!inited.value) {
      patentLegalStatusService.getPatentLegalStatusConfig().then(() => {
        inited.value = true
      })
    }
    return {
      inited,
    }
  },
  render() {
    return this.inited ? <TagLegal {...{ props: this.$props }} /> : <div></div>
  },
})
