/* eslint-disable @typescript-eslint/no-explicit-any */
import { IErrorResponse } from '@pharmsnap/shared/service/http/types'
import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { IToastParam } from '@pharmsnap/shared/types'
import { AxiosInstance } from 'axios'
import { merge, throttle } from 'lodash'
import { getErrorCodeMessage, showSingleToast } from 'pharmsnapMF_shared/utils'
import router from '../router'
import { E_ROUTER_NAME, E_ROUTER_QUERY, E_UPGRADE_TYPE } from '../types/enum'
import { showCaptchaVerification, showRemainTime } from '../utils/auth'
import { logout } from '../utils/business/auth'
import { getLocaleLang } from '../utils/business/locale'
import { ERROR_CODE_MAP } from './error-code-config'

const throttleGotoWechatBind = throttle(
  () => {
    if (!/wechatBind/.test(window.location.href)) {
      window.location.href = `/wechatBind?${E_ROUTER_QUERY.REDIRECT}=${encodeURIComponent(window.location.href)}`
    }
  },
  5000,
  {
    trailing: false,
  }
)
const throttleGotoDashboardList = throttle(
  () => {
    if (!/^dashboard$/.test(window.location.href)) {
      window.location.href = '/dashboard'
    }
  },
  5000,
  {
    trailing: false,
  }
)

const updateErrorData = (error: any, data?: Partial<IErrorResponse>) => {
  merge(error?.response?.data, data)
}

/**
 * 错误码处理策略表
 */
const errorCodeStrategies: Array<{
  /**
   * 策略名称
   */
  strategyName: string
  /**
   * 触发状态码
   */
  triggerErrorCodes: Array<number | string>
  /**
   * 处理逻辑
   */
  interceptResponse: (error: any, numeric_error_code: number) => Promise<any>
}> = [
  {
    strategyName: '触发极验',
    triggerErrorCodes: [ERROR_CODE_MAP.IP_READ_LIMIT_SINGLE_130080, ERROR_CODE_MAP.READ_LIMIT_SINGLE_130070, ERROR_CODE_MAP.SEARCH_THRESHOLD_130071],
    interceptResponse: (e) => {
      setTimeout(() => {
        showCaptchaVerification()
      }, 0)
      return Promise.reject(e)
    },
  },
  {
    strategyName: '访问太快',
    triggerErrorCodes: [ERROR_CODE_MAP.READ_FAST_130091],
    interceptResponse: (e) => {
      router.replace({
        name: E_ROUTER_NAME.READ_FAST,
      })
      return Promise.reject(e)
    },
  },
  {
    strategyName: '显示错误页面',
    triggerErrorCodes: [ERROR_CODE_MAP.THRESHOLD_130066],
    interceptResponse: (e, numeric_error_code) => {
      showErrorCode(numeric_error_code)
      updateErrorData(e, { hasShowedError: true })
      router.replace({
        name: E_ROUTER_NAME.ERROR_PAGE,
        query: {
          [E_ROUTER_QUERY.ERROR_CODE]: String(numeric_error_code),
        },
      })
      return Promise.reject(e)
    },
  },
  {
    strategyName: '用户Block,登出',
    triggerErrorCodes: [ERROR_CODE_MAP.READ_LIMIT_130067, ERROR_CODE_MAP.GEETEST_BLOCK_130077],
    interceptResponse: (e, numeric_error_code) => {
      showErrorCode(numeric_error_code)
      updateErrorData(e, { hasShowedError: true })
      setTimeout(logout, 2000)
      return Promise.reject(e)
    },
  },
  {
    strategyName: '显示极验等待时间',
    triggerErrorCodes: [ERROR_CODE_MAP.GEETEST_WAIT_130079],
    interceptResponse: (e) => {
      const resp = e.response
      const remaining = resp?.data?.remaining as number
      if (remaining > 0) {
        showRemainTime(remaining)
      }
      return Promise.reject(e)
    },
  },
  {
    strategyName: '免费用户需要绑定微信',
    triggerErrorCodes: [ERROR_CODE_MAP.NOT_BIND_WECHAT_130101],
    interceptResponse: (e) => {
      throttleGotoWechatBind()
      return Promise.reject(e)
    },
  },
  {
    strategyName: 'dashboard id异常',
    triggerErrorCodes: [ERROR_CODE_MAP.NOT_AUTHORIZED_DASHBOARD__130103, ERROR_CODE_MAP.NOT_EXIST_DASHBOARD__130104],
    interceptResponse: (e, numeric_error_code) => {
      showErrorCode(numeric_error_code)
      updateErrorData(e, { hasShowedError: true })
      throttleGotoDashboardList()
      return Promise.reject(e)
    },
  },
  {
    strategyName: '使用次数已达上限,显示错误页面',
    triggerErrorCodes: [
      ERROR_CODE_MAP.READ_FAST_130072,
      ERROR_CODE_MAP.DETAIL_THRESHOLD_130073,
      ERROR_CODE_MAP.CAPACITY_CONTROL_130074,
      ERROR_CODE_MAP.USAGE_LIMIT_130081,
      ERROR_CODE_MAP.READ_LIMIT_SEVEN_DAY_130097,
      ERROR_CODE_MAP.GROUP_EXPORT_LIMIT_ERROR_CODE_130136,
    ],
    interceptResponse: (e, numeric_error_code) => {
      showErrorCode(numeric_error_code, {
        type: 'info',
        duration: 5000,
      })
      updateErrorData(e, { hasShowedError: true })
      router.replace({
        name: E_ROUTER_NAME.ERROR_PAGE,
        query: {
          [E_ROUTER_QUERY.ERROR_CODE]: String(numeric_error_code),
          [E_ROUTER_QUERY.UPDATE_TYPE]: E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT,
        },
      })
      return Promise.reject(e)
    },
  },
  {
    strategyName: '无权限',
    triggerErrorCodes: [ERROR_CODE_MAP.WITHOUT_ACCESS_10004],
    interceptResponse: (e) => {
      useAuthStore().actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'WITHOUT_ACCESS' })
      updateErrorData(e, { hasShowedError: true })
      return Promise.reject(e)
    },
  },
  // {
  //   strategyName: '免费用户已达上限',
  //   triggerErrorCodes: [ERROR_CODE_MAP.NEED_COMPLETE_INFO_130122],
  //   interceptResponse: (e, numeric_error_code) => {
  //     showErrorCode(numeric_error_code, {
  //       type: 'info',
  //       duration: 5000,
  //     })
  //     getService().tracking.trackGeneral({
  //       event_code: 'TRIGGER_COMPLETE_INFO',
  //     })
  //     updateErrorData(e, { hasShowedError: true })
  //     setTimeout(() => {
  //       window.location.href = `/complete-info?redirectUrl=${encodeURIComponent(window.location.href)}`
  //     }, 3000)
  //     return Promise.reject(e)
  //   },
  // },
]

export function interceptAxiosWithAuth(_axios: AxiosInstance) {
  _axios.interceptors.response.use(undefined, (e) => {
    const resp = e.response
    const numeric_error_code = resp?.data?.numeric_error_code as number

    if (numeric_error_code && !(resp.config.ignoreErrorCodes || []).includes(numeric_error_code)) {
      for (const strategy of errorCodeStrategies) {
        if (strategy.triggerErrorCodes.includes(numeric_error_code)) {
          // console.log(`触发: ${strategy.strategyName}策略`)
          return strategy.interceptResponse(e, numeric_error_code)
        }
      }
    }
    return Promise.reject(e)
  })
}
function showErrorCode(numeric_error_code: number, options: Partial<IToastParam> = {}) {
  showSingleToast({
    ...options,
    message: getErrorCodeMessage(numeric_error_code, getLocaleLang()) || '当前账户使用存在异常',
    dangerouslyUseHTMLString: true,
  })
}
