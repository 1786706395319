export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value)
}

export function getLocalStorage<T>(key: string, parse = true): T | undefined {
  try {
    const stringVal = localStorage.getItem(key)

    if (!stringVal) return undefined

    return parse ? JSON.parse(stringVal) : stringVal
  } catch (error) {
    console.log(`${key}值parse失败`)
    return undefined
  }
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key)
}
