import { computed, isRef, ref, Ref, watch } from '@vue/composition-api'
type MaybeRef<T> = T | Ref<T>
/**
 * 假分页数据
 * @param data 原始数据
 * @param initCurrentPage 初始化页
 * @param initPageSize 一页展示多少条数据
 * @returns
 */
export function useSplitData<T>(data: MaybeRef<Array<T>>, initCurrentPage: MaybeRef<number>, initPageSize: MaybeRef<number>) {
  const dataRef = isRef(data) ? data : ref(data)
  const currentPage = isRef(initCurrentPage) ? initCurrentPage : ref(initCurrentPage)
  const pageSize = isRef(initPageSize) ? initPageSize : ref(initPageSize)
  /**
   * 数据变更重置分页
   */
  watch(
    () => dataRef.value,
    () => {
      currentPage.value = 1
    }
  )
  const splitData = computed(() => {
    const start = (currentPage.value - 1) * pageSize.value
    const end = start + pageSize.value
    return dataRef.value.slice(start, end)
  })
  const total = computed(() => {
    return dataRef.value.length
  })
  function onCurrentPageChange(page: number) {
    currentPage.value = page
  }

  return { splitData, currentPage, pageSize, total, onCurrentPageChange }
}
