import { generateSpan } from '@patsnap/query-translator'
import { useLocale } from '@pharmsnap/shared/composition'
import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const EditQueryContainer = defineComponent({
  name: 'EditQueryContainer',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {},
  setup(props, { emit }) {
    const { locale } = useLocale()
    const queryContainer = ref()
    let isCnTyping = false

    /**
     * tokens生成span
     */
    function handleGenerateSpan(str?: string) {
      const syntaxTree = generateSpan({
        dom: queryContainer.value,
        str,
        lang: locale.value,
      })
      emit('input', syntaxTree)
    }

    /**
     * 输入的回调
     * @param e
     * @returns
     */
    function onInput(e: KeyboardEvent) {
      const { code } = e
      if (code === 'Enter') return
      if (isCnTyping) return
      const syntaxTree = generateSpan({
        dom: queryContainer.value,
        lang: locale.value,
        syncCursorPos: true,
      })
      emit('input', syntaxTree)
    }

    /**
     * 支持中文输入
     */
    function onCompositionstart() {
      isCnTyping = true
    }
    function onCompositionend() {
      isCnTyping = false
      const syntaxTree = generateSpan({
        dom: queryContainer.value,
        lang: locale.value,
        syncCursorPos: true,
      })
      emit('input', syntaxTree)
    }

    function bindEvents() {
      queryContainer.value.addEventListener('input', onInput)
      queryContainer.value.addEventListener('compositionstart', onCompositionstart)
      queryContainer.value.addEventListener('compositionend', onCompositionend)
    }

    function disposeEvents() {
      if (!queryContainer.value) return
      queryContainer.value.removeEventListener('input', onInput)
      queryContainer.value.removeEventListener('compositionstart', onCompositionstart)
      queryContainer.value.removeEventListener('compositionend', onCompositionend)
    }

    onMounted(bindEvents)
    onUnmounted(disposeEvents)

    return { queryContainer, handleGenerateSpan }
  },
  methods: {
    setQueryStr(str: string) {
      if (!str) {
        this.handleGenerateSpan('')
      } else {
        this.handleGenerateSpan(str)
      }
    },
  },
  render() {
    return (
      <div
        ref="queryContainer"
        class="outline-none"
        contenteditable
        placeholder={this.$tc('BEditQueryDialog.placeholder')}
        onFocusin={() => {
          this.$emit('focusin')
        }}
        onFocusout={() => {
          this.$emit('focusout')
        }}
      ></div>
    )
  },
})
