import { defineComponent } from '@vue/composition-api'
import { VNodeData } from 'vue'
import { ElPagination } from '../../../element-ui/index'
import $classes from './GPagination.module.scss'

export const GPagination = defineComponent({
  name: 'GPagination',
  render() {
    const attrs = this.$attrs
    const listeners = this.$listeners

    const vNodeData: VNodeData = {
      props: {
        ...attrs,
        background: true,
      },
      on: listeners,
    }
    return (
      <div class={$classes.container}>
        <ElPagination data-testid="g-pagination" {...vNodeData}>
          <template slot="default">{this.$scopedSlots.default?.({})}</template>
        </ElPagination>
      </div>
    )
  },
})
