import { useMergedState } from '@pharmsnap/shared/src/composition'
import { IInfiniteLoadingState } from '@pharmsnap/shared/types'
import { defineComponent, PropType, toRef } from '@vue/composition-api'
import { chunk, isUndefined } from 'lodash'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueDraggable from 'vuedraggable'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const GColLayout = defineComponent({
  name: 'GColLayout',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  model: {
    prop: 'list',
    event: 'change',
  },
  props: {
    list: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: () => false,
    },
    col: {
      type: Number as PropType<number>,
      default: 1,
    },
    xGap: {
      type: Number as PropType<number>,
    },
    yGap: {
      type: Number as PropType<number>,
    },
    itemKey: {
      type: String,
      default: '',
    },
    itemHeight: {
      type: Number,
    },
    isOpenRecycleScroller: {
      type: Boolean,
      default: false,
    },
    isInfiniteModel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const [mergedList, setMergedList] = useMergedState([], {
      value: toRef(props, 'list'),
      onChange(v) {
        emit('change', v)
      },
    })
    const infiniteHandler = ($state: IInfiniteLoadingState) => {
      emit('infiniteLoading', $state)
    }

    return {
      mergedList,
      setMergedList,
      infiniteHandler,
    }
  },
  render() {
    const { col, xGap = 16, yGap = 16, list = [], itemHeight, isOpenRecycleScroller } = this

    const totalXGap = (col - 1) * xGap
    const avgXGap = totalXGap / col

    // 开启虚拟滚动
    if (isOpenRecycleScroller) {
      const itemSize = isUndefined(itemHeight) ? itemHeight : itemHeight + yGap
      const recycleScrollerList = chunk(list, col)

      return (
        <RecycleScroller
          class="h-full overflow-y-auto"
          items={recycleScrollerList}
          item-size={itemSize}
          key-field={this.itemKey}
          scopedSlots={{
            default: (data: { item: any[]; index: number }) => {
              const { item, index } = data
              return (
                <div key={index} class="g-col-layout flex flex-row flex-wrap">
                  {item.map((value: any, valueIndex: number) => {
                    const indexInOriginalList = index * col + valueIndex
                    const style = {
                      'margin-right': (indexInOriginalList + 1) % col === 0 ? '0' : `${xGap}px`,
                      'margin-bottom': `${yGap}px`,
                      width: `calc(${(1 / col) * 100}% - ${avgXGap}px)`,
                    }

                    return (
                      <div style={style} key={this.itemKey ? value[this.itemKey] : indexInOriginalList} class="g-col-layout__item">
                        {this.$scopedSlots &&
                          this.$scopedSlots.default &&
                          this.$scopedSlots.default({
                            item: value,
                          })}
                      </div>
                    )
                  })}
                </div>
              )
            },
          }}
        />
      )
    }

    return (
      <VueDraggable
        value={this.mergedList}
        onInput={(li: any[]) => {
          this.setMergedList(li)
        }}
        class="g-col-layout flex flex-row flex-wrap "
        style={{
          'column-gap': `${xGap}px`,
          'row-gap': `${yGap}px`,
        }}
        disabled={!this.draggable}
        animation={200}
        handle={'.g-col-layout__item'}
      >
        {list.map((item, index) => {
          const style = {
            width: `calc(${(1 / col) * 100}% - ${avgXGap}px)`,
          }
          return (
            <div
              key={this.itemKey ? item[this.itemKey] : index}
              style={style}
              class={['g-col-layout__item', !this.draggable ? '' : 'cursor-all-scroll']}
            >
              {this.$scopedSlots &&
                this.$scopedSlots.default &&
                this.$scopedSlots.default({
                  item,
                })}
            </div>
          )
        })}
        {this.isInfiniteModel ? (
          <infinite-loading onInfinite={this.infiniteHandler} distance={10} class="w-full">
            <span slot="spinner"></span>
            <div slot="no-more">
              {list.length ? <div class="border-t border-gray-30 flex justify-center pt-2 mb-4 mx-2">{this.$t('GColLayout.noMoreData')}</div> : null}
            </div>
            <span slot="no-results"></span>
          </infinite-loading>
        ) : null}
      </VueDraggable>
    )
  },
})
