/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getCountryAlpha2code, getCountryFullName, hasCountryFlag, toThousands } from '@patsnap/synapse_common_utils'
import { i18n } from '@pharmsnap/shared/i18n'
import { formatAmountV2 } from '@pharmsnap/shared/utils/amount'
import { TooltipComponentOption } from 'echarts/components'
import { BarSeriesOption, SingleAxisOption, VisualMapComponentOption, XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { isArray } from 'lodash'
import { getDefaultBarSeries, getDefaultRectXAxis, getDefaultRectYAxis, getDefaultSeries, getDefaultSingleAxis } from '../coordinate'
import { ECOption } from '../core/plot'
import { getDefaultContinuousVisualMap, getDefaultTooltip } from '../interactions'
import { BasicWorldMapChartDataItem, ECValueType, IECAxisDataType, RectTooltipExtraFormatterFunc } from '../type'
import { transformCountryNameToClass } from '../util'

export function getXAxis(options: ECOption): XAXisOption {
  return getDefaultRectXAxis(options)
}

export function getSingleAxis(options: ECOption): SingleAxisOption {
  return getDefaultSingleAxis(options)
}

export function getYAxis(options: ECOption): YAXisOption {
  return getDefaultRectYAxis(options)
}

export function getTooltip(options: ECOption): TooltipComponentOption {
  return getDefaultTooltip(options)
}

export function getBarSeries(options: ECOption): BarSeriesOption {
  return getDefaultBarSeries(options)
}

export function getSeries(options: ECOption): ECOption['series'] {
  return getDefaultSeries(options)
}

export function generateYAxisLabelFormatter(valueType?: ECValueType) {
  // tslint:disable-next-line: no-any
  return (value: any, count?: number) => {
    if (valueType === 'count') {
      return typeof value === 'undefined' ? 0 : formatAmountV2({ amount: value, hasMoneyType: false })
    } else if (valueType === 'money') {
      if (typeof count !== 'undefined') {
        if (count >= 0) {
          return value === 0 ? i18n.tc('common.undisclosed') : formatAmountV2({ amount: value, hasMoneyType: false })
        }
      }
      return typeof value === 'undefined' ? i18n.tc('common.undisclosed') : formatAmountV2({ amount: value, hasMoneyType: false })
    } else if (valueType === 'percent') {
      return typeof value === 'undefined' ? i18n.tc('common.undisclosed') : `${parseFloat((Math.min(value, 1) * 100).toFixed(2))}%`
    } else {
      return value
    }
  }
}

export function generateRectTooltipFormatter(
  dataTypes: IECAxisDataType[],
  options: { horizontal?: boolean; extraInfoFormatter?: RectTooltipExtraFormatterFunc } = {}
) {
  // tslint:disable-next-line: no-any
  return (params: any) => {
    if (isArray(params)) {
      // @ts-ignore
      const category = params[0].axisValueLabel
      const tooltip = `<span style="font-weight: bold; color: #020A1A;">${category}</span><br/>`
      const infos = options.extraInfoFormatter ? options.extraInfoFormatter(params[0].data) : []
      const extraInfo = `${infos.map((i) => `<div>${i[0]}: <span style="color: #495973;">${i[1]}</span></div>`).join()}`
      const series = params.map((item, index) => {
        const marker = item.marker
        const dataType = dataTypes[index]
        const encode = item.encode
        const formatter = generateYAxisLabelFormatter(dataType.valueType)
        const value = item.value[item.dimensionNames[options.horizontal ? encode.x[0] : encode.y[0]]]
        if (value === null) return ''
        const formattedValue = formatter(value, dataType.countKey ? item.value[dataType.countKey] : undefined)
        const seriesName = item.seriesName
        return `<div>${marker} ${seriesName}: <span class=" text-gray-300">${formattedValue}</span></div>`
      })

      return tooltip + extraInfo + series.join('')
    } else {
      const marker = params.marker
      const dataType = dataTypes[0]
      const seriesName =
        dataType.valueType === 'text' ? params.seriesName : dataType.valueType ? i18n.tc(`chart.valueType.${dataType.valueType}`) : ''
      const formatter = generateYAxisLabelFormatter(dataType.valueType)
      const value = formatter(params.value[params.dimensionNames[options.horizontal ? params.encode.x[0] : params.encode.y[0]]])
      const name = params.name
      const infos = options.extraInfoFormatter ? options.extraInfoFormatter(params.data) : []
      return `
      <div class="relative leading-normal" style="padding-left: 18px;">
        <span class="absolute" style="left: 0; top: 0;">${marker}</span>
        ${name}
      </div>
      <div>
        <span class="inline-block" style="margin-left: 18px;"></span>${seriesName}:
        <span style="color: #495973;">${value}</span>
      </div>
      ${infos
        .map(
          (i) => `<div>
        <span class="inline-block" style="margin-left: 18px;"></span>${i[0]}:
        <span style="color: #495973;">${i[1]}</span>
      </div>`
        )
        .join()}
      `.trim()
    }
  }
}

// tslint:disable-next-line: no-any
export function themeRiverTooltipFormatter(params: any) {
  let tooltip = ''
  // tslint:disable-next-line: no-any
  const items = params as any[]
  const series = items.map((item, index) => {
    const marker = item.marker
    const data = item.data
    const [value, count, name] = data
    if (tooltip === '') tooltip = `${value}<br/>`
    return `<div>${marker} ${name}: <span class=" text-gray-300">${count}</span></div>`
  })
  return tooltip + series.join('')
}

// $vars: (3) ['seriesName', 'name', 'value']
// borderColor: "#ffffff"
// color: "rgba(245,234,236,1)"
// componentIndex: 0
// componentSubType: "heatmap"
// componentType: "series"
// data: {value: Array(5)}
// dataIndex: 46
// dataType: undefined
// dimensionNames: (5) ['x', 'y', 'value', 'value0', 'value1']
// encode: {x: Array(1), y: Array(1), value: Array(1)}
// marker: "<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgba(245,234,236,1);\"></span>"
// name: "临床4期"
// seriesId: "\u0000临床\u00000"
// seriesIndex: 0
// seriesName: "临床"
// seriesType: "heatmap"
// value: (5) [7, 29, 1, 1, undefined]

export function generateHeatMapTooltipFormatter(xCategories: string[], yCategories: string[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (params: any) => {
    console.log({
      xCategories,
      yCategories,
      params,
    })

    const { marker, encode } = params
    const { value, x, y } = encode
    const xCategoryIndex = x[0]
    const yCategoryIndex = y[0]
    const xCategoryName = xCategories[xCategoryIndex]
    const yCategoryName = yCategories[yCategoryIndex]

    const tooltip = [
      `<div style="font-weight: bold; color: #020A1A;">${yCategoryName}</div>`,
      `<div class="relative leading-normal" style="padding-left: 18px; color: #495973;">
        <span class="absolute" style="left: 0; top: 0;">${marker}</span>
        ${xCategoryName}
      </div>`,
      `<div style="padding-left: 18px;"></div>`,
    ]

    return ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pieChartTooltipFormatter(params: any) {
  const seriesName = params.seriesName
  const value = params.value
  const count = value.value
  const percent = params.percent
  const marker = params.marker
  const name = params.name

  return `<div>${marker} <span style="font-weight: bold;">${seriesName}</span><br> <span  style="display: inline-block; margin-left: 18px;"></span>${name}: <span style="color: #495973;">${toThousands(
    count
  )} (${percent}%)</span></div>`
}

// tslint:disable-next-line: no-any
export function pieChartLabelFormatter(params: any) {
  const value = params.value
  const count = value.value
  const percent = params.percent
  const name = params.name

  return `${name}\n${percent}% (${toThousands(count)})`
}

function getCountryData(country: BasicWorldMapChartDataItem) {
  const singleCode = country.code
  const fullName = getCountryFullName(singleCode)
  const alpha2Code = getCountryAlpha2code(singleCode)
  const countryDisplayName = alpha2Code
    ? i18n.locale === 'cn'
      ? i18n.tc(`PtCountryFlag.${alpha2Code}`)
      : fullName || alpha2Code || singleCode
    : singleCode
  return { fullName, alpha2Code, countryDisplayName }
}

/**
 * 最原始的tooltip
 * @param params
 * @returns
 */
function renderCommonCountryTooltip(params: {
  hasFlag: boolean
  countryDisplayName: string
  seriesName: string
  count: number
  percent?: number
  countryName: string
}) {
  const { hasFlag, countryDisplayName, seriesName, count, percent, countryName } = params
  if (!hasFlag) {
    return `${countryDisplayName}<br/>${seriesName}: ${toThousands(count)}${percent ? ` (${(percent * 100).toFixed(2)}%)` : ''}`
  }

  return `<div><span style="inline-block; vertical-align: middle;" class="${transformCountryNameToClass(
    countryName
  )}"></span><span style="inline-block; vertical-align: middle; margin-left: 4px;">${countryDisplayName}</span></div><div><span style="display: inline-block; width: 24px; margin-right: 4px;"></span>${seriesName}: ${toThousands(
    count
  )}${percent ? ` (${(percent * 100).toFixed(2)}%)` : ''}</div>`
}

/**
 * 没有国旗
 * @param params
 * @returns
 */
function renderCountryNameWithLocation(params: { countryDisplayName: string; subLocations: BasicWorldMapChartDataItem[] }) {
  const { countryDisplayName, subLocations } = params
  return `${countryDisplayName}<br/>${subLocations
    .map((location) => {
      const { countryDisplayName: singleCountryDisplayName } = getCountryData(location)
      return `${singleCountryDisplayName}: ${toThousands(location.value)}${location.percent ? ` (${(location.percent * 100).toFixed(2)}%)` : ''}`
    })
    .join('<br/>')}`
}

/**
 * 显示国旗&国家，分行显示子地区数据
 * @param params
 * @returns
 */
function renderFlagWithLocation(params: {
  countryName: string
  countryDisplayName: string
  percent?: number
  count: number
  subLocations: BasicWorldMapChartDataItem[]
}) {
  const { countryName, countryDisplayName, percent, count, subLocations } = params
  return `<div>
  <span style="inline-block; vertical-align: middle;" class="${transformCountryNameToClass(countryName)}"></span>
    <span style="inline-block; vertical-align: middle; margin-left: 4px;">${countryDisplayName}</span>
    <br/>
  <span style="display: inline-block; width: 24px; margin-right: 8px;"></span>${countryDisplayName}: ${toThousands(count)}${
    percent ? ` (${(percent * 100).toFixed(2)}%)` : ''
  }
  <br/>
    ${subLocations
      .map((location) => {
        const { countryDisplayName: singleCountryDisplayName } = getCountryData(location)
        return `<span style="display: inline-block; width: 24px; margin-right: 8px;"></span>${singleCountryDisplayName}: ${toThousands(
          location.value
        )}${location.percent ? ` (${(location.percent * 100).toFixed(2)}%)` : ''}`
      })
      .join('<br/>')}
  </div>`
}

/**
 *
 * @param params
 * @param mixChildren 是否需要显示国家下的地区
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function worldMapIconTooltipFormatter(params: any, mixChildren = false) {
  if (!params.data) return ''
  const data = params.data
  const { code: countryName, value: count, percent } = data
  const subLocations: BasicWorldMapChartDataItem[] = data.subLocations
  const { countryDisplayName } = getCountryData(data)
  const hasFlag = hasCountryFlag(countryName)
  const seriesName = params.seriesName
  if (!mixChildren || !subLocations.length) {
    return renderCommonCountryTooltip({
      hasFlag,
      countryDisplayName,
      seriesName,
      count,
      percent,
      countryName,
    })
  }
  if (!hasFlag) {
    return renderCountryNameWithLocation({
      countryDisplayName,
      subLocations,
    })
  }
  return renderFlagWithLocation({
    countryName,
    countryDisplayName,
    count,
    percent,
    subLocations,
  })
}

export function adjustYAxisLabelFormatter(valueType: ECValueType, options: ECOption): ECOption {
  const yAxis = options.yAxis as YAXisOption

  if (!yAxis) return options

  if (isArray(yAxis)) {
    yAxis.forEach(
      (item) =>
        (item.axisLabel = {
          ...item.axisLabel,
          ...(item.axisLabel?.formatter ? { formatter: item.axisLabel.formatter } : { formatter: generateYAxisLabelFormatter(valueType) }),
        })
    )
  } else {
    yAxis.axisLabel = {
      ...yAxis.axisLabel,
      // @ts-ignore
      ...(yAxis.axisLabel?.formatter ? { formatter: yAxis.axisLabel.formatter } : { formatter: generateYAxisLabelFormatter(valueType) }),
    }
  }

  return options
}

export function getHeatMapVisualMap(options: ECOption): VisualMapComponentOption {
  const visualMap = getDefaultContinuousVisualMap(0, options)

  visualMap.inRange = {
    color: ['#F4F5F7', '#FF6C73'],
  }
  // https://echarts.apache.org/zh/option.html#visualMap-continuous.dimension
  // 这边由于有需求显示总计，但不需要总计映射颜色，所以visualMap的取值和series中的取值分开，visualMap中取3维度，series中取2维度
  visualMap.dimension = 3

  return visualMap
}

export function getChartColor(count: number) {
  if (count === 1) {
    return ['#1976D2']
  } else if (count === 2) {
    return ['#1976D2', '#43B74B']
  } else if (count === 3) {
    return ['#1976D2', '#43B74B', '#FABB27']
  } else if (count === 4) {
    return ['#1976D2', '#43B74B', '#FABB27', '#FD865A']
  } else if (count === 5) {
    return ['#1976D2', '#43B74B', '#A2C716', '#FABB27', '#FD865A']
  } else if (count === 6) {
    return ['#1976D2', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A']
  } else if (count === 7) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A']
  } else if (count === 8) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#E96A94']
  } else if (count === 9) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#E96A94', '#A863F7']
  } else if (count === 10) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#E96A94', '#A863F7', '#D174D6']
  } else if (count === 11) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#E96A94', '#A863F7', '#D174D6']
  } else if (count === 12) {
    return ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#E96A94', '#A863F7', '#D174D6', '#FA85B5']
  } else if (count === 13) {
    return [
      '#1976D2',
      '#27B4D1',
      '#43B74B',
      '#A2C716',
      '#CDCE12',
      '#FABB27',
      '#FD865A',
      '#FF6C73',
      '#E96A94',
      '#A863F7',
      '#D174D6',
      '#FA85B5',
      '#23A5A4',
    ]
  }

  return [
    '#1976D2',
    '#27B4D1',
    '#43B74B',
    '#A2C716',
    '#CDCE12',
    '#FABB27',
    '#FD865A',
    '#FF6C73',
    '#E96A94',
    '#A863F7',
    '#D174D6',
    '#FA85B5',
    '#23A5A4',
    '#1E8EBB',
    '#8F95AD',
  ]
}
