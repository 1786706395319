import { getDiagonalCx, getDiagonalCy, ITreeChartNodeSizeConfig, TreeChart } from '@patsnap/synapse_tree_chart'
import moveZoomPlugin from '@patsnap/synapse_tree_chart/plugins/moveZoom'
import shadowPlugin from '@patsnap/synapse_tree_chart/plugins/shadow'
import '@pharmsnap/shared/src/assets/icon-svg/arrowDownCircle.svg'
import '@pharmsnap/shared/src/assets/icon-svg/collapsed.svg'
import '@pharmsnap/shared/src/assets/icon-svg/expanded.svg'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { useEventListener } from '@vueuse/core'
import styles from './BCorpTree.module.scss'
import { useCorpCardRender } from './composition/useCorpCardRender'
import { useCorpLinkage } from './composition/useCorpLinkage'
import { useDownloadCorpTree } from './composition/useDownloadCorpTree'
import { VIEW_MORE } from './constant'
import cn from './locales/cn.json'
import en from './locales/en.json'
import highlightPlugin from './plugins/highlight'
import { getDefaultLogo, getDefaultWhiteLogo } from './utils/logo'
import { renderForeignObject, renderRect } from './utils/node'

export const BBasicCorpTree = defineComponent({
  name: 'BBasicCorpTree',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    id: {
      type: String,
    },
    corpTree: {
      type: Object as PropType<IOrgCorpTree>,
      required: true,
    },
    nodeSizeConfig: {
      type: Object as PropType<ITreeChartNodeSizeConfig>,
      default: () => ({
        width: 240,
        height: 96,
        xGap: 64,
        yGap: 16,
      }),
    },
    hasViewMore: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['viewAll'],
  setup(props, { emit }) {
    const { ts, locale } = useLocale()
    const { companyCardContainerRef, closeCompanyCard, onClickCompanyNode } = useCorpCardRender()
    const { containerRef, closeCard, onClickNode } = useCorpLinkage()
    const corpTreeRef = ref()
    const treeChart = new TreeChart({
      data: props.corpTree,
      dataKeyAccessor: (i) => i.id,
      childrenAccessor: (i) => i.subsidiary,
      nodeSizeConfig: props.nodeSizeConfig,
      nodeSizeAccessor: (data, w, h) => {
        if (data.id === VIEW_MORE) return [w, 32]
        return [w, h]
      },
      drawNode: [
        (g, rect, foreignObject) => {
          renderForeignObject(foreignObject, locale.value, props.id)
          foreignObject.on('click', (e, d) => {
            e.stopPropagation()
            onClickCompanyNode(d.data.id, `${TreeChart.ID_PREFIX}${d.data.id}`)
            treeChart.highlight(d.data.id)
          })

          return g
        },
        (g, rect) => {
          renderRect(rect)
          return g
        },
        (g, rect, foreignObject) => {
          foreignObject
            .filter((d) => d.data.id === VIEW_MORE)
            .html(() => {
              return `<div class="flex items-center h-full p-2 gap-1 text-sm bg-white-default rounded">
              <div style="width: 24px;height: 24px">

              <svg width="24" height="24" viewBox="0 0 24 24">
              <use href="#ArrowDownCircle"></use>
              </svg>
              </div>${ts('corpTree.viewAllCorp')}</div>`
            })
            .on('click', (e, d) => {
              e.stopPropagation()
              emit('viewAll')
            })
          rect.filter((d) => d.data.id === VIEW_MORE).attr('fill', '#FFF')
          return g
        },
      ],
      updateNode: (g, rect) => {
        renderRect(rect)
        return g
      },
      drawEdge(g, path) {
        const noLinked = g.filter((d) => d.parent?.data.id !== d.data.parent_id)
        noLinked.select('path').attr('stroke-dasharray', '5,5')
        noLinked
          .append('circle')
          .attr('r', 4)
          .attr('cx', (d) => getDiagonalCx(d, d.parent || d))
          .attr('cy', (d) => getDiagonalCy(d, d.parent || d))
          .attr('stroke', '#8993A4')
          .attr('fill', '#8993A4')
          .attr('cursor', 'pointer')
          .on('mouseover', (e, d) => {
            onClickNode(e.target)
          })
          .on('mouseout', closeCard)
        return g
      },
    })
    treeChart.use(moveZoomPlugin).use(shadowPlugin).use(highlightPlugin).init()
    useEventListener(document.body, 'click', closeCompanyCard)
    onMounted(() => {
      treeChart._zoomBehavior?.filter((e) => {
        if (e.type === 'wheel') return false
        return true
      })

      getDefaultLogo()
      getDefaultWhiteLogo()

      treeChart.render(corpTreeRef.value)
      treeChart.moveToCenter()
    })
    const { downloading, handleDownload } = useDownloadCorpTree(treeChart, corpTreeRef)
    const handleDownloadBriefCorpTree = (name: string, id: string) => {
      handleDownload(name, id)
      trackingEvent('EXPORT_BRIEF_CORP_TREE', {
        payload: {
          entity_id: id,
        },
      })
    }

    return {
      corpTreeRef,
      companyCardContainerRef,
      containerRef,
      treeChart,
      handleDownload: handleDownloadBriefCorpTree,
      downloading,
    }
  },
  render() {
    return (
      <div class="w-full h-full">
        <div class={[styles.corpTree]} ref="corpTreeRef"></div>
        <div ref="companyCardContainerRef" class="fixed z-50" onClick={(e: any) => e.stopPropagation()}></div>
        <div ref="containerRef" class="fixed z-50" onClick={(e: any) => e.stopPropagation()}></div>
      </div>
    )
  },
})
