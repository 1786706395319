import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { ref } from '@vue/composition-api'
import { IS_CN_REGION } from '../config'
import { sharedCtx } from '../context'
import { useAuthStore } from './useAuthStore'

export function useWorkspace() {
  const {
    getters: { isBasicUser, isTrialUser, isEnterpriseUser },
    actions,
  } = useAuthStore()

  const showWorkspaceDialog = ref(false)

  const handleClick = () => {
    if ((!IS_CN_REGION && isBasicUser.value) || isTrialUser.value || isEnterpriseUser.value) {
      showWorkspaceDialog.value = true
      sharedCtx.service.tracking.trackGeneral({
        event_code: 'CLICK_SAVE_TO_WORKSPACE_BUTTON',
      })
      return
    }
    actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_WORKSPACE, trigger_point: 'SAVE_WORKSPACE' })
  }

  const handleClose = () => (showWorkspaceDialog.value = false)
  return {
    showWorkspaceDialog,
    handleClick,
    handleClose,
  }
}
