import '@patsnap-ui/icon/assets/solid/notebook.svg'
import { GIcon } from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IReportListItem } from '@pharmsnap/shared/types'
import { formatTimestamp, getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import $classes from './BReportCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BReportCard = defineComponent({
  name: 'BReportCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    report: {
      type: Object as PropType<IReportListItem>,
      required: true,
    },
    downloadAble: {
      type: Function as PropType<() => boolean>,
      default: () => false,
    },
    onlineAble: {
      type: Function as PropType<() => boolean>,
      default: () => true,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { localeUpcase } = useLocale()
    const {
      getters: { isBasicUser },
    } = useAuthStore()

    const title = computed(() => {
      return getSpecialLang({ data: props.report.report_name, field: 'name', lang: localeUpcase.value, isDegraded: false }) || ''
    })

    const href = computed(() => sharedCtx.router.generatorReportDetailPath(props.report.report_id || ''))

    const postDate = ref('')

    return {
      href,
      title,
      postDate,
      isBasicUser,
    }
  },
  methods: {
    handleDownload(e: Event) {
      e.stopPropagation()
      !this.isDownloading && this.$emit('download', { report_id: this.report.report_id, file_name: `${this.title}.pdf` })
    },
    renderTitle() {
      return <div class="mt-4 mb-2 text-base font-semibold line-clamp-2">{this.title}</div>
    },
    renderDate() {
      const { localeUpcase } = useLocale()
      this.postDate = formatTimestamp(this.report.post_time, { locale: localeUpcase.value })
      return <div class="text-xs text-text-t2">{this.postDate}</div>
    },
    renderImage() {
      return (
        <div class="py-8 px-10 bg-gray-20 rounded relative">
          <img src={this.report.cover_image} style="width: 153px;height:210px; border-width: 3px" class="rounded border-white-default shadow-md" />
        </div>
      )
    },
    renderDownloadButton() {
      return (
        <GTooltip effect="dark" content={this.$tc('report.download')} placement="top">
          <div
            onClick={this.handleDownload}
            class={[
              this.isDownloading ? 'cursor-not-allowed' : 'cursor-pointer',
              'px-2',
              'py-1',
              'hidden',
              'rounded',
              'group-hover:block',
              'bg-blue-default',
              { 'hover:bg-blue-hover': !this.isDownloading },
              { 'bg-gray-30': this.isDownloading },
            ]}
          >
            <GIcon
              svgName={this.isDownloading ? 'SolidLoadingCircle' : 'SolidExport'}
              class={[this.isDownloading ? 'text-gray-60 animate-spin' : 'text-white-default', 'text-center']}
              size={24}
            ></GIcon>
          </div>
        </GTooltip>
      )
    },
    renderReadOnlineButton() {
      return (
        <GTooltip effect="dark" content={this.$tc('report.readOnline')} placement="top">
          <a
            href={this.href}
            onClick={(e: Event) => e.stopPropagation()}
            target="_blank"
            class={['cursor-pointer', 'px-2', 'py-1', 'ml-2', 'hidden', 'rounded', 'group-hover:block', 'bg-blue-default', 'hover:bg-blue-hover']}
          >
            <GIcon svgName="SolidNotebook" class={['text-white-default', 'text-center']} size={24}></GIcon>
          </a>
        </GTooltip>
      )
    },
  },
  render() {
    return (
      <div
        style="width: 250px"
        class={[$classes.card, 'p-3', 'group', 'relative', 'cursor-pointer']}
        onClick={() => this.$emit('click', { id: this.report.report_id, title: this.title, postDate: this.postDate })}
      >
        {this.renderImage()}
        {this.renderTitle()}
        <div class="mb-2 text-xs text-text-t2 line-clamp-1">{this.report.report_source}</div>
        {this.renderDate()}
        <div class={['absolute', 'right-3', 'bottom-3', 'inline-flex']}>
          {this.downloadAble?.() && this.renderDownloadButton()}
          {this.onlineAble?.() && this.renderReadOnlineButton()}
        </div>
      </div>
    )
  },
})
