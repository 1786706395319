import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { computed, ComputedRef, defineComponent, PropType, ref } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import { GIcon } from '../GIcon/GIcon'
import { GLoading } from '../GLoading/GLoading'
import $classes from './GMultiDropdownByPage.module.scss'

export const GMultiDropdownByPage = defineComponent({
  name: 'GMultiDropdownByPage',
  props: {
    icon: String,
    label: String,
    title: String,
    width: {
      type: Number,
      default: 200,
    },
    popoverClass: {
      type: String,
      default: '',
    },
    placement: {
      type: String as PropType<'bottom-start' | 'bottom' | 'bottom-end'>,
      default: 'bottom-start',
    },
    count: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onOpen: {
      type: Function as PropType<() => void>,
    },
    onClose: {
      type: Function as PropType<() => void>,
    },
    onConfirm: {
      type: Function as PropType<() => void>,
    },
    onClear: {
      type: Function as PropType<() => void>,
    },
  },
  setup(props, context) {
    const visible = ref<boolean>(false)

    const isChecked: ComputedRef<boolean> = computed(() => props.count > 0)

    const handleTogglePanelVisible = () => {
      visible.value = !visible.value
      context.emit(visible.value ? 'open' : 'close')
    }

    const handleClosePanel = () => {
      visible.value = false
      context.emit('close')
    }

    const handleSave = () => {
      visible.value = false
      context.emit('close')
      context.emit('confirm')
    }
    const handleClear = (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      context.emit('clear')
    }

    const renderContent = () => {
      return (
        <div class={$classes.gMultiDropdownContent}>
          {props.icon ? <GIcon class={[$classes.gMultiDropdownContentIcon]} svgName={props.icon} size={24}></GIcon> : null}
          <div class={$classes.gMultiDropdownContentLabel}>
            {props.label}
            {isChecked.value ? ` (${props.count})` : ''}
          </div>
        </div>
      )
    }

    const renderArrowAction = () => {
      return (
        <GIcon
          class={[$classes.gMultiDropdownArrow, visible.value ? $classes.gMultiDropdownArrowRotate : '']}
          svgName="SolidDropdownOpen"
          size={24}
        ></GIcon>
      )
    }

    const renderClearAction = () => {
      return <GIcon nativeOn={{ click: handleClear }} svgName="SolidCloseMedium" size={24}></GIcon>
    }

    const renderAction = () => {
      return (
        <span class={[$classes.gMultiDropdownAction, 'block']}>
          {isChecked.value ? (visible.value ? renderArrowAction() : renderClearAction()) : renderArrowAction()}
        </span>
      )
    }
    return {
      visible,
      isChecked,
      handleTogglePanelVisible,
      handleClosePanel,
      handleSave,
      handleClear,
      renderContent,
      renderAction,
    }
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  render() {
    return (
      <ElPopover
        v-model={this.visible}
        v-click-outside={this.handleClosePanel}
        placement={this.placement}
        trigger="manual"
        popper-class={[$classes.gMultiDropdownPopover, this.popoverClass].join(' ')}
      >
        <div
          on={{ click: this.handleTogglePanelVisible }}
          slot="reference"
          class={[
            $classes.gMultiDropdown,
            this.visible ? $classes.gMultiDropdownOpen : '',
            this.isChecked && !this.visible ? $classes.gMultiDropdownActive : '',
          ]}
          style={{ width: `${this.width}px` }}
        >
          <div class={$classes.gMultiDropdownBd}>{this.renderContent()}</div>
          <div class={$classes.gMultiDropdownFt}>{this.renderAction()}</div>
        </div>
        <div class={$classes.gMultiDropdownPanel}>
          <div class={$classes.gMultiDropdownPanelHd}>
            <div class={$classes.gMultiDropdownPanelTitle}>{this.title}</div>
            {this.$slots.header && <div class="mt-4">{this.$slots.header}</div>}
          </div>
          <div class={[$classes.gMultiDropdownPanelBd, this.$slots.header ? 'mt-4' : 'mt-5']}>
            {this.isLoading && <GLoading />}
            {this.$slots.default}
          </div>
          <div class={$classes.gMultiDropdownPanelFt}>
            <button class="pt-ui-btn" type="button" data-type="default" disabled={this.isLoading} on={{ click: this.handleClosePanel }}>
              {this.$t('common.cancel')}
            </button>
            <button class="pt-ui-btn ml-2" type="button" data-type="submit" disabled={this.isLoading} on={{ click: this.handleSave }}>
              {this.$t('common.save')}
            </button>
          </div>
        </div>
      </ElPopover>
    )
  },
})
