import '@patsnap-ui/icon/assets/DefaultPerson.svg'
import '@patsnap-ui/icon/assets/solid/ArrowRight.svg'
import '@patsnap-ui/icon/assets/solid/chain.svg'
import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { E_SOCIAL_MEDIA_TYPE } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/facebook.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/feed.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/linkedin.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/twitter.svg'
import { useChatStore } from '@pharmsnap/pharmsnap-web/composable/useChatStore'
import { useInviteLink } from '@pharmsnap/pharmsnap-web/composable/useInviteLink'
import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { useUpgrade } from '@pharmsnap/pharmsnap-web/views/Upgrade/hook/useUpgrade'
import { SHARE_TYPE } from '@pharmsnap/shared/src/types'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { some } from 'lodash'
import { GDialog, GIcon } from 'pharmsnapMF_shared/components'
import { useAuthStore, useLocale } from 'pharmsnapMF_shared/composition'
import { sharedCtx } from 'pharmsnapMF_shared/context'
import { ElButton, ElCheckbox, ElInput } from 'pharmsnapMF_shared/element-ui'
import { getSocialMedialIcon, showSingleToast } from 'pharmsnapMF_shared/utils'
import $style from './InviteCopilotDialog.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const InviteCopilotDialog = defineComponent({
  name: 'InviteCopilotDialog',
  i18n: {
    messages: {
      en,
      cn,
    },
  },
  setup(props, ctx) {
    const {
      state: { inviteDialogVisible, invitedPeopleCount },
    } = useChatStore()
    const inited = ref(false)
    const handleCloseDialog = () => {
      inviteDialogVisible.value = false
    }
    const { localeUpcase, isCN } = useLocale()
    const {
      state: { userInfo },
      getters: { isFreeUser, userName },
    } = useAuthStore()
    const { onViewPlans } = useUpgrade()
    const copilotCampaignPromotionPrefix = 'self_growth_copilot'
    const miniProgramCopilotRouterName = 'ASYNC_COPILOT_INVITE'
    const {
      qrCodeLoading,
      sceneLoading,
      qrCode,
      copilotInviteLink,
      copySuccess,
      copyInviteLink,
      getCopilotInviteLink,
      generateShareCode,
      scene,
      trackingShareInfo,
      init: initInviteLink,
    } = useInviteLink({
      linkConfig: {
        type: 'SHARE_LINK',
        miniProgramRouterName: miniProgramCopilotRouterName,
        registerInfo: {
          campaign_promotion: `${copilotCampaignPromotionPrefix}_share_link`,
        },
      },
      qrCodeConfig: {
        type: 'SHARE_CODE',
        miniProgramRouterName: miniProgramCopilotRouterName,
        registerInfo: {
          campaign_promotion: `${copilotCampaignPromotionPrefix}_share_code`,
        },
      },
    })

    const email = ref('')
    const sendEmailList = ref<string[]>([])
    const checkEmail = ref(false)

    const isEmail = (email: string) => EMAIL_REGEX.test(email)
    const hasErrorEmail = computed(() => {
      return some(sendEmailList.value, (email) => !isEmail(email))
    })
    const isCanSendEmail = computed(() => !hasErrorEmail.value && sendEmailList.value.length)

    const handleKeyDown = (e: KeyboardEvent) => {
      e.stopPropagation()
      // 中文输入法下，enter keyCode 229
      // 此时不需执行该函数
      if (e && e.keyCode === 229) return
      const { key: keyboardKey } = e
      if (keyboardKey === 'Enter') {
        if (sendEmailList.value.includes(email.value)) {
          showSingleToast({
            message: isCN.value ? '您输入的邮箱重复,请更换一个邮箱' : 'The email you entered is a duplicate, please enter a different email.',
            type: 'warning',
          })
          return
        }
        if (userInfo.value?.email && email.value === userInfo.value?.email) {
          showSingleToast({
            message: isCN.value ? '不能邀请您自己' : 'You cannot invite yourself',
            type: 'warning',
          })
          return
        }
        sendEmailList.value.push(email.value)
        email.value = ''
      }
      if (keyboardKey === 'Backspace') {
        if (email.value === '') {
          sendEmailList.value.pop()
        }
      }
    }

    function handleShowUpgrade() {
      onViewPlans('COPILOT_INVITE_DIALOG')
    }
    const getInvitedCount = async () => {
      const res = await sharedCtx.service.copilot.getUserInviteEarnInfo()
      if (res.success) {
        invitedPeopleCount.value = res.data.invite_user_count
      }
    }
    function copyLink() {
      copilotInviteLinkRef.value.focus()
      copilotInviteLinkRef.value.select()
      copyInviteLink(copilotInviteLink.value)
      trackingShareInfo({ scene: scene.value })
    }
    const init = async () => {
      if (inited.value) {
        return
      }
      initInviteLink()
      getInvitedCount()
    }
    const shareBtnGroup = computed(() => {
      /**
       * 'SolidChain', E_SOCIAL_MEDIA_TYPE.FACEBOOK, E_SOCIAL_MEDIA_TYPE.LINKEDIN, E_SOCIAL_MEDIA_TYPE.TWITTER生成对象数组和对应的clickHandler
       */
      return [
        {
          icon: 'SolidChain',
          clickHandler: () => {
            copyLink()
          },
        },
        {
          icon: E_SOCIAL_MEDIA_TYPE.FACEBOOK,
          clickHandler: () => {
            const shareType: SHARE_TYPE = 'SHARE_FACEBOOK'
            openPageWithoutPending(async () => {
              const scene = await generateShareCode({
                type: shareType,
                registerInfo: {
                  campaign_promotion: `${copilotCampaignPromotionPrefix}_${shareType.toLocaleLowerCase()}`,
                },
              })
              await trackingShareInfo({
                scene,
              })
              return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(getCopilotInviteLink(scene, true))
            })
          },
        },
        {
          icon: E_SOCIAL_MEDIA_TYPE.LINKEDIN,
          clickHandler: () => {
            const shareType: SHARE_TYPE = 'SHARE_LINKEDIN'
            openPageWithoutPending(async () => {
              const scene = await generateShareCode({
                type: shareType,
                registerInfo: {
                  campaign_promotion: `${copilotCampaignPromotionPrefix}_${shareType.toLocaleLowerCase()}`,
                },
              })
              await trackingShareInfo({
                scene,
              })
              return (
                'http://www.linkedin.com/shareArticle?mini=true&url=' +
                encodeURIComponent(getCopilotInviteLink(scene, true)) +
                '&title=' +
                encodeURIComponent(document.title)
              )
            })
          },
        },
        {
          icon: E_SOCIAL_MEDIA_TYPE.TWITTER,
          clickHandler: () => {
            const shareType: SHARE_TYPE = 'SHARE_TWITTER'
            openPageWithoutPending(async () => {
              const scene = await generateShareCode({
                type: shareType,
                registerInfo: {
                  campaign_promotion: `${copilotCampaignPromotionPrefix}_${shareType.toLocaleLowerCase()}`,
                },
              })
              await trackingShareInfo({
                scene,
              })
              return (
                'https://twitter.com/share?url=' +
                encodeURIComponent(getCopilotInviteLink(scene, true)) +
                '&text=' +
                encodeURIComponent(
                  'Sign up to use Hiro for free, an early access version of Patsnap GPT, powered by LLM. It can help you summarize pharmaceutical competitive landscapes, generate research reports, and explain biomedical terms with just one click.'
                )
              )
            })
          },
        },
      ]
    })
    const onSendEmail = async () => {
      if (hasErrorEmail.value) return
      const shareType: SHARE_TYPE = 'SHARE_EMAIL'
      const scene = await generateShareCode({
        type: shareType,
        miniProgramRouterName: miniProgramCopilotRouterName,
        registerInfo: {
          campaign_promotion: `${copilotCampaignPromotionPrefix}_${shareType.toLocaleLowerCase()}`,
        },
      })
      const res = await sharedCtx.service.copilot.sendEmail({
        receptor: sendEmailList.value,
        language: localeUpcase.value,
        user_name: userName.value,
        invite_link: getCopilotInviteLink(scene),
      })
      if (res.success) {
        trackingShareInfo({ scene, shareInfo: { email: sendEmailList.value } })
        showSingleToast({
          message: isCN.value
            ? '邀请邮件发送成功，请提醒你的朋友进入邮箱查看。'
            : 'The email invitation has been sent. Please remind your friend to check their mailbox',
          type: 'success',
        })
        sendEmailList.value = []
        checkEmail.value = false
      }
    }
    watch(
      () => inviteDialogVisible.value,
      (value) => {
        if (value) {
          init()
        }
      }
    )
    const copilotInviteLinkRef = ref()
    return {
      inviteDialogVisible,
      qrCodeLoading,
      sceneLoading,
      copySuccess,
      copilotInviteLink,
      email,
      checkEmail,
      sendEmailList,
      isCanSendEmail,
      hasErrorEmail,
      qrCode,
      handleCloseDialog,
      handleKeyDown,
      handleShowUpgrade,
      copyLink,
      isEmail,
      isFreeUser,
      invitedPeopleCount,
      shareBtnGroup,
      onSendEmail,
      copilotInviteLinkRef,
    }
  },
  methods: {
    renderQrCode() {
      return (
        <div class="mb-6">
          <span>{this.$tc('inviteCopilot.wechatScanDesc')}</span>
          {this.qrCodeLoading ? <div v-ls-loading={this.qrCodeLoading} class={$style.qrCode}></div> : <img src={this.qrCode} class={$style.qrCode} />}
        </div>
      )
    },
    renderPersonalLink() {
      return (
        <div class="mb-6">
          <span>{this.$tc('inviteCopilot.personalLinkDesc')}</span>
          <div class={$style.copyLink}>
            <ElInput
              v-ls-loading={this.sceneLoading}
              vModel={this.copilotInviteLink}
              readonly
              ref="copilotInviteLinkRef"
              class={$style.linkInput}
              nativeOnClick={this.copyLink}
            ></ElInput>
            {IS_CN_REGION ? (
              <ElButton class={this.copySuccess ? $style.copySuccessBtn : ''} type="primary" onClick={this.copyLink}>
                {this.copySuccess ? this.$t('inviteCopilot.copySuccess') : this.$t('inviteCopilot.copyLink')}
              </ElButton>
            ) : (
              <div class="space-x-3 flex items-center">
                {this.shareBtnGroup.map(({ icon: type, clickHandler }) => (
                  <div class={$style.socialMedialIcon} onClick={clickHandler}>
                    <GIcon svgName={getSocialMedialIcon(type as E_SOCIAL_MEDIA_TYPE) || type} size={24}></GIcon>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )
    },
    renderSendEmail() {
      return (
        <div class="mb-6">
          <span>{this.$tc('inviteCopilot.email')}</span>
          <div class={$style.copyLink}>
            <div class={[$style.emailContainer, 'space-y-1', this.hasErrorEmail ? 'border-red-default' : 'border-[#B3BAC5]']}>
              {this.sendEmailList.map((email) => (
                <div
                  key={email}
                  class={[
                    'rounded p-1 shrink-0 flex items-center mr-1',
                    this.isEmail(email) ? 'bg-gray-20 text-[#5e6c84]' : 'bg-red-light text-red-default',
                  ]}
                >
                  <GIcon
                    class={['rounded-full p-1 text-white-default ', this.isEmail(email) ? 'bg-text-t4' : 'bg-red-disable']}
                    svgName="DefaultPerson"
                    size={24}
                  ></GIcon>
                  <span class="mx-1">{email}</span>
                  <div
                    onClick={() => {
                      this.sendEmailList.splice(this.sendEmailList.indexOf(email), 1)
                    }}
                  >
                    <GIcon
                      class={['cursor-pointer', this.isEmail(email) ? 'text-black-default' : 'text-red-default']}
                      svgName="SolidCloseMedium"
                      size={20}
                    ></GIcon>
                  </div>
                </div>
              ))}
              <ElInput
                class="flex-1 min-w-8 mr-1 "
                vModel={this.email}
                size="mini"
                placeholder={!this.sendEmailList.length && this.$tc('inviteCopilot.sendToTip')}
                nativeOnKeydown={this.handleKeyDown}
              />
            </div>
            <button
              class="pt-ui-btn w-[196px] flex-shrink-0"
              data-type="submit"
              data-size="big"
              onClick={this.onSendEmail}
              disabled={!this.isCanSendEmail || !this.checkEmail}
            >
              {this.$t('inviteCopilot.send')}
            </button>
          </div>
          {this.hasErrorEmail ? <div class="text-red-default mt-1 text-xs">{this.$tc('inviteCopilot.emailError')}</div> : null}
          {this.isCanSendEmail ? (
            <div class="bg-gray-20 text-black-default text-xs p-2 mt-1 rounded flex">
              <ElCheckbox vModel={this.checkEmail}></ElCheckbox>
              <span class="ml-2">{this.$tc('inviteCopilot.emailToOtherTips')}</span>
            </div>
          ) : null}
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <GDialog
          append-to-body
          destroy-on-close
          close-on-click-modal
          title={this.$tc('inviteCopilot.title')}
          visible={this.inviteDialogVisible}
          width="680px"
          onClose={this.handleCloseDialog}
          onCancel={this.handleCloseDialog}
          showConfirm={false}
          showCancel={false}
        >
          <div class={$style.content}>
            <div class="text-sm">{this.$tc('inviteCopilot.inviteOne')}</div>
            <div class={$style.divider} />
            {IS_CN_REGION ? [this.renderQrCode(), this.renderPersonalLink()] : [this.renderPersonalLink(), this.renderSendEmail()]}
            <div class="mt-6">
              <i18n path="inviteCopilot.inviteSuccess" tag="span">
                <span class="text-orange-dark">{this.invitedPeopleCount}</span>
              </i18n>{' '}
              / <span>{this.$t('inviteCopilot.inviteLimit', [20])}</span>
            </div>
          </div>
          {this.isFreeUser && (
            <div slot="footer" class={$style.footer}>
              <div class={$style.footerDivider} />
              <div class="flex justify-between items-center">
                <div class="text-black-default font-semibold">{this.$tc('inviteCopilot.updateDesc')}</div>
                <ElButton type="text" onClick={this.handleShowUpgrade}>
                  <span class="font-semibold">{this.$tc('inviteCopilot.updateBtn')}</span>
                  <GIcon svgName="SolidArrowRight" size={16}></GIcon>
                </ElButton>
              </div>
            </div>
          )}
        </GDialog>
      </div>
    )
  },
})
