import { ILang } from '@patsnap/synapse_common_interface'
import {
  FeedbackType,
  IAsyncTaskResult,
  ICopilotCapacity,
  ICopilotHistoryRes,
  ICopilotReportParams,
  ICopilotReportRes,
  ICopilotTaskParams,
  ICopilotTaskRes,
} from '@pharmsnap/shared/types/copilot'
import dayjs from 'dayjs'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class CopilotApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async executeCopilotTask(params: ICopilotTaskParams): Promise<IHttpWrap<ICopilotTaskRes>> {
    const signal = params.signal
    delete params.signal
    const rt = await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot'),
      data: {
        ...params,
        // interrupt: false
      },
      signal,
      injectUserInfo: true,
      onDownloadProgress: params.onDownloadProgress,
      // 加了超时会提前阻断
      // timeout: 20000,
    }) as Promise<IHttpWrap<ICopilotTaskRes>>)

    return rt
  }
  async getExecutionResult(itemId: string, signal: AbortSignal): Promise<IHttpWrap<IAsyncTaskResult>> {
    return await (this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/copilot/conversation/${itemId}`),
      signal,
    }) as Promise<IHttpWrap<IAsyncTaskResult>>)
  }
  /**
   * 获取报告列表
   */
  async getReportList(params: ICopilotReportParams): Promise<IHttpWrap<ICopilotReportRes>> {
    const now = dayjs() // get current date and time
    const threeDaysAgo = now.subtract(3, 'day') // subtract three days from current date
    const fromTimestamp = threeDaysAgo.valueOf() // get timestamp in milliseconds
    const rt = await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/report/search'),
      data: { ...params, from: fromTimestamp, end: now.valueOf() },
    }) as Promise<IHttpWrap<ICopilotReportRes>>)

    return rt
  }
  /**
   * 删除
   */
  async deleteReport(reportId: string): Promise<IHttpWrap<void>> {
    const rt = await (this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/report/' + reportId),
    }) as Promise<IHttpWrap<void>>)

    return rt
  }
  async getCopilotCapacity() {
    return await (this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/copilot/capacity`),
    }) as Promise<IHttpWrap<ICopilotCapacity>>)
  }
  async getReportSignAddress(s3_path: string) {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/signature/sign'),
      data: {
        object_key: [s3_path],
        sign_type: 'synapse_attachment',
      },
    })
    if (rt.status === 200) {
      return [s3_path].map((item) => rt.data[item])
    }
    return []
  }
  async getPreCheckResult() {
    return await this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/copilot/pre-check`),
    })
  }
  async getUserInviteEarnInfo(): Promise<IHttpWrap<{ invite_earn: number; invite_user_count: number }>> {
    const res = await this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/point/invite_earn`),
    })
    return res
  }

  /**
   * 发送邮件
   */
  async sendEmail(data: { receptor: string[]; language: ILang; user_name: string; invite_link: string }) {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/send_invite_email'),
      data,
    })

    return rt
  }

  async getCopilotHistory(): Promise<IHttpWrap<ICopilotHistoryRes>> {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/history'),
      data: {
        offset: 0,
        limit: 15,
      },
    })
    return rt
  }

  async sendFeedback(params: { item_id: string; feedback: FeedbackType }): Promise<IHttpWrap<ICopilotHistoryRes>> {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/feedback'),
      data: params,
    })
    return rt
  }
  async deleteAllHistory() {
    const rt = await this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/history'),
    })
    return rt
  }
  async stopTask(params: { item_id: string }) {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/stop'),
      data: params,
    })
    return rt
  }

  async checkContent(params: { item_id: string }) {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/copilot/content_check'),
      data: params,
    })
    return rt
  }
}
