import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { API_MOCK_URL } from '../config'
import injectMockRequestInterceptors, { MockRequestInterceptorsOptions } from '../utils/mockInterceptor'

function afterHandelMockConfig(config: AxiosRequestConfig): AxiosRequestConfig {
  if (config.headers?.Authorization) {
    delete config.headers?.Authorization
  }
  return config
}
export const webAPIMockConfig: MockRequestInterceptorsOptions = {
  mockServerBaseUrl: API_MOCK_URL as string,

  mockUrlConfig: {
    // 'post:1.0/translational_medicine/aggregation': {},
    // 'post:1.0/translational_medicine': {},
    // 'post:1.0/translational_medicine/search': {},
    // 'post:1.0/alert/search': {
    //   apifoxApiId: '61335398',
    //   apifoxMockCases: ['Test'],
    // },
    // 'get:1.0/alert/1': {
    //   apifoxApiId: '61335401',
    //   // apifoxMockCases: ['Test'],
    // },
    // 'get:1.0/alert/history': {
    //   apifoxMockCases: ['Test'],
    // },
    // 'get:1.0/alert/snapshot/*': {
    //   apifoxMockCases: ['Test'],
    //   apifoxApiId: '123520967',
    // },
    // 同样前缀, 优先匹配长的
    // 'post:1.0/drug_deal': {},
    // 'post:1.0/drug_deal/aggregation': {},
    // 'post:1.0/drug_deal/group/search': {},
    // 'post:1.0/drug_deal/group/aggregation': {},
    // 'post:1.0/deal/batch': {},
    // 'get:1.0/copilot/capacity': {},
    // 'post:1.0/copilot/report/search': {},
    // 'post:1.0/copilot': {},
    // 'get:1.0/copilot/conversation/.*': {},
    // 'delete:1.0/copilot/report/.*': {},
    // 'get:1.0/copilot/pre-check': {},
    // 'get:1.0/public/news/home': {
    //   apifoxApiId: '64049757',
    // },
    // 'post:1.0/public/news': {
    //   apifoxApiId: '64053905',
    //   apifoxMockCases: ['news'],
    // },
    // 'get:1.0/public/drug/1': {
    //   apifoxApiId: '64128619',
    // },
    // 'get:1.0/public/news/1': {/*  */
    //   apifoxApiId: '64055882',
    //   apifoxMockCases: ['PROD'],
    // },
    // // 'get:1.0/public/report/.*': {},
    // 'get:1.0/public/organization/1': {
    //   apifoxApiId: '64085667',
    // },
    // 'post:synapse_tourapi/1.0/public/autocomplete': {
    //   apifoxResponseId: '162883670',
    // },
  },
  afterHandle: afterHandelMockConfig,
}

export function interceptAxiosWithMock(axiosIns: AxiosInstance) {
  if (API_MOCK_URL) {
    injectMockRequestInterceptors(axiosIns, webAPIMockConfig)
  }
}
