import { getClinicalResultName, getNormalizedInterventionName, integrateLinkSymbols } from '@patsnap/synapse_common_business'
import {
  I18nLang,
  IBaseCharacteristic,
  IBaseLineItem,
  IClinicalResultGroup,
  IClinicalResultOutComeItem,
  IClinicalResultOutcomeMeasure,
  ILang,
  IOutcomeAnalysisList,
  IOutcomeResultList,
} from '@patsnap/synapse_common_interface'
import { getDicItemName } from '@patsnap/synapse_common_utils'
import { assign, concat, filter, find, flattenDepth, get, includes, join, map } from 'lodash'
import { EMPTY_PLACEHOLDER } from 'pharmsnapMF_shared/constants'
import { IVueI18n } from 'vue-i18n'
import { HIDE_OUTCOME_MEASURE_PARAMS_TYPES, LOWER_DISPERSION_TYPES, OUTCOME_MEASURE_TYPE } from './constant'

export const getPercentNumber = (number: string | number) => `${(Number(number) * 100).toFixed(2)}%`

export function getBaseLineMeasureType(data: IBaseLineItem) {
  const { name = '', unit_of_measure = '', dispersion_type } = data
  const dispersionType = dispersion_type ? `(${dispersion_type})` : ''
  return [name, unit_of_measure, dispersionType].filter(Boolean).join(', ')
}

export function getBaselineOrOutcomeMeasureValue(
  data: IClinicalResultOutcomeMeasure,
  resultItem: IOutcomeResultList,
  isOutcomeEfficacyAndNotCTGov = false,
  lang: I18nLang
) {
  const { spread, lower_value, upper_value, value, participant_analyzed_units, participant_analyzed } = resultItem || {}
  const { dispersion_type } = data

  const participantUnits = participant_analyzed_units || ''

  const participant_value =
    data.unit_of_measure === '%' && participant_analyzed
      ? `${integrateLinkSymbols(value as string, lang)}(${participant_analyzed}${participantUnits ? ` ${participantUnits}` : ''})`
      : integrateLinkSymbols(value as string, lang)

  if (isOutcomeEfficacyAndNotCTGov) {
    const spread_value = spread ? `(${integrateLinkSymbols(spread, lang)})` : ''
    return join(filter([participant_value, spread_value], Boolean), ' ') || EMPTY_PLACEHOLDER
  }
  if (!dispersion_type) return participant_value || EMPTY_PLACEHOLDER
  if (LOWER_DISPERSION_TYPES.includes(dispersion_type) && spread) {
    return `${participant_value} (${spread})`
  } else if (lower_value && upper_value) {
    return `${participant_value} (${integrateLinkSymbols(`${lower_value} to ${upper_value}`, lang)})`
  }
  return participant_value || EMPTY_PLACEHOLDER
}

export function getLevelPaddingStyle(level: number, buffer = 0) {
  return { paddingLeft: `${level * 36 + buffer}px` }
}

export function getEfficacyOutcomeMeasureName(
  outcomeMeasure: IClinicalResultOutcomeMeasure,
  outcomes: IClinicalResultOutComeItem[] = [],
  source: string,
  locale: I18nLang
) {
  const resultName = getClinicalResultName(outcomeMeasure, outcomes, {
    isCTgov: source === 'CTgov',
    locale,
  })
  const unit = outcomeMeasure.unit_of_measure || ''
  const dispersionType = outcomeMeasure.dispersion_type ? `(${outcomeMeasure.dispersion_type})` : ''

  // CT-GOV来源
  if (source === 'CTgov') {
    /** 参数类型 */
    const paramType =
      !outcomeMeasure?.param_type || HIDE_OUTCOME_MEASURE_PARAMS_TYPES.includes(outcomeMeasure.param_type) ? '' : `(${outcomeMeasure.param_type})`

    return join(filter([`${resultName}${paramType}`, unit, dispersionType], Boolean), ', ')
  }
  const description = outcomeMeasure.description ? `(${outcomeMeasure.description})` : ''
  return join(filter([`${resultName}${description}`, unit, dispersionType], Boolean), ', ')
}

export function getSafetyOutcomeMeasureName(
  outcomeMeasure: IClinicalResultOutcomeMeasure,
  outcomes: IClinicalResultOutComeItem[] = [],
  source: string,
  locale: I18nLang
) {
  const resultName = getClinicalResultName(outcomeMeasure, outcomes, {
    isCTgov: source === 'CTgov',
    locale: locale,
  })
  const unit = outcomeMeasure.unit_of_measure || ''
  // CT-GOV来源
  if (source === 'CTgov') {
    return join(filter([resultName, unit], Boolean), ', ')
  }
  const description = outcomeMeasure.description ? `(${outcomeMeasure.description})` : ''
  return join(filter([`${resultName}${description}`, unit], Boolean), ', ')
}

export const getSpanMethod = ({ row, columnIndex }: { row: { merge: boolean }; columnIndex: number }, length: number) => {
  if (row.merge) {
    if (columnIndex === 0) {
      return {
        rowspan: 1,
        colspan: length + 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
  return {
    rowspan: 1,
    colspan: 1,
  }
}

export const getTreeData = <T>(parents: T[], data: T[], key = 'measure_id', parent_key = 'parent_measure_id'): T[] => {
  return map(parents, (parent) => {
    const children = filter(data, (item) => get(item, parent_key) === get(parent, key))
    return assign(
      parent,
      children.length
        ? {
            children: getTreeData(children, data),
            merge: true,
          }
        : {}
    )
  })
}

export const getDescriptiveResult = (
  parents: IClinicalResultOutcomeMeasure[],
  data: IClinicalResultOutcomeMeasure[],
  outcomes: IClinicalResultOutComeItem[] = [],
  key = 'measure_id',
  parent_key = 'parent_measure_id',
  locale: I18nLang
): string[][] => {
  return map(parents, (parent) => {
    const measure_id = get(parent, key, '') as string
    const descriptive_result = get(parent, 'descriptive_result', '') as string

    const children = filter(data, (item) => get(item, parent_key) === measure_id)

    const efficacyType =
      !parent.parent_measure_id && parent.type
        ? `<span
              style="
                line-height: 20px; border-radius: 4px; padding: 2px 4px; font-size:12px; display: inline-block;
                background: ${get(OUTCOME_MEASURE_TYPE, `${parent.type}.bg`)};
                border: 1px solid ${get(OUTCOME_MEASURE_TYPE, `${parent.type}.bg`)};
                color: ${get(OUTCOME_MEASURE_TYPE, `${parent.type}.color`)};">
              ${parent.type}
          </span>`
        : ''

    return concat(
      descriptive_result ? [`${getEfficacyOutcomeMeasureName(parent, outcomes, '', locale)} ${efficacyType} = ${descriptive_result}`] : [],
      children.length ? flattenDepth(getDescriptiveResult(children, data, outcomes, 'measure_id', 'parent_measure_id', locale)) : []
    )
  })
}

export const getGroupName = (group: IClinicalResultGroup, $i18n: IVueI18n, groups: IClinicalResultGroup[]) => {
  const parent_order = group.parent_group_id ? find(groups, { group_id: group.parent_group_id })?.design_group_order : ''
  const subgroup = parent_order ? `(${$i18n.t('clinical_result.subgroup', [parent_order])})` : ''
  return `${$i18n.tc('clinical_result.group')} ${group.design_group_order} ${subgroup} `
}

export const getOutcomeMeasureAnalysisValue = (groups: IClinicalResultGroup[], analysis: IOutcomeAnalysisList, $i18n: IVueI18n) => {
  const groupName = join(
    map(
      filter(groups, (group) => includes(analysis.group_id, group.group_id)).sort(
        (a, b) => analysis.group_id.indexOf(a.group_id) - analysis.group_id.indexOf(b.group_id)
      ),
      (item) => getGroupName(item, $i18n, groups)
    ),
    ' vs '
  )

  const { param_value = '', ci_value_lower, ci_value_upper, dispersion_type, dispersion_value, pvalue } = analysis

  let analysisValue = integrateLinkSymbols(param_value, $i18n.locale as I18nLang) || ''

  if (analysisValue) {
    const ci_value =
      ci_value_lower && ci_value_upper ? `(${integrateLinkSymbols(`${ci_value_lower} to ${ci_value_upper}`, $i18n.locale as I18nLang)})` : ''

    if (ci_value) {
      analysisValue += ` ${ci_value}`
    } else if (dispersion_type && dispersion_value) {
      analysisValue += ` (${[dispersion_type, dispersion_value].filter(Boolean).join(', ')})`
    }
  }

  const pValue = pvalue ? `${getOutcomeMeasureAnalysisName(analysis) !== 'P-Value' ? 'P-Value = ' : ''}${analysis.pvalue}` : ''

  return `${groupName}: ${[analysisValue, pValue].filter(Boolean).join(', ')}`
}

export const getOutcomeMeasureAnalysisName = (analysis: IOutcomeAnalysisList) => {
  const { param_type = '', ci_pct_value, pvalue } = analysis

  if (pvalue && !param_type && !ci_pct_value) {
    return 'P-Value'
  }

  const pct = ci_pct_value ? `(${ci_pct_value}% CI)` : ''
  return join(filter([param_type, pct], Boolean), ', ')
}

export const getGroupDomContent = (characteristics: IBaseCharacteristic[], extraNames?: IBaseCharacteristic[]) => {
  //分组特征
  const extraNamesStr = (extraNames || []).map((item) => item.name).join(' + ')
  const realExtraNamesStr = extraNamesStr ? `(${extraNamesStr})` : ''

  //用药方案
  const intervention = renderIntervention(characteristics)
  return intervention ? `${intervention} ${realExtraNamesStr}` : extraNamesStr
}

//用药方案
export const renderIntervention = (characteristics: IBaseCharacteristic[]) => {
  const names = filter(characteristics, ['type', 'drug'])
  return map(names, (name, index) => {
    const normalizedName = getNormalizedInterventionName(name)
    return map(normalizedName, 'name').join('') + (index === names.length - 1 ? '' : ' + ')
  }).join('')
}

// 分组特征
export const getCharacteristics = (characteristics: IBaseCharacteristic[], isManual: boolean) => {
  if (isManual) {
    return (
      map(
        [
          ...filter(characteristics, ['type', 'biomarker']),
          ...filter(characteristics, ['type', 'disease']),
          ...filter(characteristics, ['type', 'overall']),
        ],
        'name'
      ).join(', ') || EMPTY_PLACEHOLDER
    )
  }
  return find(characteristics, ['type', 'overall'])?.name || EMPTY_PLACEHOLDER
}
export const displayTogglerCls = 'js_clinical_result_display_toggler'

export function getDesignGroupType(data: IClinicalResultGroup, locale: ILang) {
  if (data.normalized_design_group_type_view) {
    return getDicItemName(data.normalized_design_group_type_view, locale)
  }
  return data.design_group_type
}
