import { GCountryFlag, GDescription } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { renderLimitedOrg } from '@pharmsnap/shared/render'
import { IBaseDevStatusCountryInfo, IGDescriptionItemProps } from '@pharmsnap/shared/types'
import { CTRL_C_INFO, formatTimestamp } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, getCurrentInstance, toRefs } from '@vue/composition-api'
import { BDevStatusDataSource } from '../../BDevStatusDataSource/BDevStatusDataSource'
import $classes from '../EntityCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDevStatusCard = defineComponent({
  name: 'BDevStatusCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IBaseDevStatusCountryInfo>,
      required: true,
    },
    isApproved: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { data, isApproved } = toRefs(props)
    const { locale, localeUpcase } = useLocale()
    const ins = getCurrentInstance()
    const countryName = computed(() => {
      const country = data.value.country_view
      return country?.alpha_2_code || country?.display_name || ''
    })
    const showApprovedDate = computed(() => isApproved.value && typeof data.value.approved_date !== 'undefined')
    const showWithDrawDate = computed(() => isApproved.value && typeof data.value.withdraw_date !== 'undefined')
    const isInactive = computed(() => !!data.value.inactive)
    const orgs = computed(() => data.value.organization_info?.map((item) => item.org_id_view) || [])
    const allSource = computed(() => {
      return data.value.organization_info?.flatMap((organizationInfo) => {
        return organizationInfo.data_source || []
      })
    })
    const descItems = computed(() => {
      const countryItem: IGDescriptionItemProps = {
        label: ins?.proxy.$tc('devStatusCard.country') || '',
        field: 'country',
        contentCls: 'flex items-center',
      }
      const orgItem: IGDescriptionItemProps = {
        label: isInactive.value ? ins?.proxy.$tc('devStatusCard.inActiveOrg') || '' : ins?.proxy.$tc('devStatusCard.activeOrg') || '',
        field: 'org',
      }

      const items = [countryItem, orgItem]

      if (showApprovedDate.value) {
        items.push({
          label: ins?.proxy.$tc('devStatusCard.firstApprovedDate') || '',
          field: 'firstApprovedDate',
        })
      }

      if (showWithDrawDate.value) {
        items.push({
          label: ins?.proxy.$tc('devStatusCard.withdrawDate') || '',
          field: 'withdrawDate',
        })
      }
      if (allSource.value?.length) {
        items.push({
          label: ins?.proxy.$tc('devStatusCard.source') || '',
          field: 'source',
        })
      }

      return items
    })

    return {
      allSource,
      countryName,
      showApprovedDate,
      showWithDrawDate,
      isInactive,
      orgs,
      locale,
      localeUpcase,
      descItems,
    }
  },
  render() {
    return (
      <div class={[$classes.card, CTRL_C_INFO]}>
        <GDescription
          data={this.data}
          width={this.locale === 'cn' ? 100 : 135}
          size="medium"
          items={this.descItems}
          scopedSlots={{
            country: () => (
              <GCountryFlag
                name={this.countryName}
                nameCn={this.data.country_view?.display_name_cn}
                useFullName={true}
                showName={true}
                showUnknownCountryFlag
              ></GCountryFlag>
            ),
            source: () =>
              this.allSource?.length && <BDevStatusDataSource source={this.allSource} limitCount={3} limitMode="expand"></BDevStatusDataSource>,
            org: () => renderLimitedOrg(this.orgs, 3, false),
            firstApprovedDate: ({ data }: { data: IBaseDevStatusCountryInfo }) =>
              data.approved_date ? formatTimestamp(data.approved_date, { locale: this.localeUpcase }) : EMPTY_PLACEHOLDER,
            withdrawDate: ({ data }: { data: IBaseDevStatusCountryInfo }) =>
              data.withdraw_date ? formatTimestamp(data.withdraw_date, { locale: this.localeUpcase }) : EMPTY_PLACEHOLDER,
          }}
        ></GDescription>
      </div>
    )
  },
})
