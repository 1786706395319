import { ElRadio, ElRadioButton, ElRadioGroup } from '@pharmsnap/shared/element-ui'
import { IGRadioItem } from '@pharmsnap/shared/types'
import { E_ALIGN_DIRECTION } from '@pharmsnap/shared/types/enum'
import { defineComponent, PropType } from '@vue/composition-api'
import $classes from './GRadio.module.scss'

export const GRadio = defineComponent({
  name: 'GRadio',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Number, Boolean] as PropType<string | number | boolean>,
    },
    mode: {
      type: String as PropType<'circle' | 'button'>,
      default: 'circle',
    },
    items: {
      type: Array as PropType<IGRadioItem[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String as PropType<E_ALIGN_DIRECTION>,
      default: E_ALIGN_DIRECTION.HORIZONTAL,
    },
  },
  methods: {
    handleInput(val: IGRadioItem['value']) {
      this.$emit('input', val)
    },
  },
  render() {
    return (
      <div
        class={[
          $classes.gRadioContainer,
          this.inline ? $classes.gRadioContainerInline : $classes.gRadioContainerBlock,
          this.mode === 'button' ? $classes.gRadioContainerButton : $classes.gRadioContainerCircle,
          this.align === E_ALIGN_DIRECTION.VERTICAL ? $classes.gRadioItemsBlock : '',
        ]}
      >
        <ElRadioGroup value={this.value} onInput={this.handleInput}>
          {this.items.map((item, index) =>
            this.mode === 'button' ? (
              <ElRadioButton key={index} label={item.value} name={item.name} data-testid={`g-radio--${item.value}`}>
                {item.customRender ? item.customRender() : item.label}
              </ElRadioButton>
            ) : (
              <ElRadio key={index} label={item.value} name={item.name} data-testid={`g-radio--${item.value}`}>
                {item.customRender ? item.customRender() : item.label}
              </ElRadio>
            )
          )}
        </ElRadioGroup>
      </div>
    )
  },
})
