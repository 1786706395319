import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/constants/enum-lang/org'
import {
  injectLangFieldByKeyLangMap,
  injectLangFieldByKeyWithUnknown,
  injectOrgName,
  injectStartupName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'
import { replaceDisplayNameByShortLangMap } from './clinical'

export const groupFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  entity_type: (data) => injectLangFieldByKeyLangMap(ORG_ENTITY_LANG, data),
  employee_num: injectLangFieldByKeyWithUnknown,
  ORGANIZATION_ID_ONEID: injectOrgName,
  ORG_ROOT_ID_ONEID: injectOrgName,
  ORGANIZATION_ID: injectOrgName,
  TARGET_ID: replaceDisplayNameByShortName,
  TARGET_ID_EXTEND: replaceDisplayNameByShortName,
  MECHANISM_ACTION_ID: replaceDisplayNameByShortLangMap,
  is_startups: injectStartupName,
}

export function transformGroupByEntityAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, groupFieldTransformFnMap, xCorrelationId)
}
