import { defineComponent, inject } from '@vue/composition-api'
import { anchorPrefixProvideKey } from './config'
export const GAnchorPanel = defineComponent({
  name: 'GAnchorPanel',
  props: {
    id: {
      required: true,
      type: String,
    },
    scrollMarginTop: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const anchorPrefixClass = inject(anchorPrefixProvideKey)
    return { anchorPrefixClass }
  },
  render() {
    return (
      <div
        class={`${this.anchorPrefixClass}-title ${this.anchorPrefixClass}_${this.id}`}
        data-id={this.id}
        style={{ 'scroll-margin-top': `${this.scrollMarginTop}px` }}
      >
        {this.$slots.default}
      </div>
    )
  },
})
