import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import { IUseAnalysisOneDimChartEntityOptions } from '../../type'
import { useAnalysisOneDimChartEntity } from '../useAnalysisOneDimChartEntity'

export function useAnalysisDrugDealOneDimChartEntity(options: Omit<IUseAnalysisOneDimChartEntityOptions, 'getChartConfig'>) {
  const getBarChartConfig: IUseAnalysisOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField, aggItems } = config
    if (aggField === 'NORMALIZED_PHASE_SIGN_ID_FLATTEN' || aggField === 'NORMALIZED_PHASE_SIGN_ID') {
      return {
        sorter: (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        },
        serverDtoAdjust: (data) => {
          data.items.forEach((item) => {
            item.count = item?.aggregation_result?.[0]?.total
          })
          data.items = data.items.filter((item) => item.count)
          return data
        },
        showLabel: true,
        xAxisNameRotate: aggItems.length > 9 ? 35 : 0,
      }
    }

    if (
      aggField === 'PRINCIPLE_ORG_ID' ||
      aggField === 'PRINCIPLE_ORG_ID_ROOT' ||
      aggField === 'PARTNER_ORG_ID' ||
      aggField === 'PARTNER_ORG_ID_ROOT' ||
      aggField === 'TARGET_ID_EXTEND' ||
      aggField === 'DRUG_TYPE' ||
      aggField === 'DEAL_DISEASE_ID'
    ) {
      return {
        sorter: (a, b) => {
          return a.count - b.count
        },
        barMaxWidth: 20,
        layout: 'horizontal',
        optionAdjust: (option) => {
          return {
            ...option,
            yAxis: {
              ...option.yAxis,
              name: '',
            },
          }
        },
      }
    }

    return {
      showLabel: true,
    }
  }

  return useAnalysisOneDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'bar') return getBarChartConfig(type, config)
      return
    },
  })
}
