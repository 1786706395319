import { E_AGGREGATION_TYPE, E_QUERY_ITEM_CONDITION, IAggregationItem, IQuery, IQueryItemField } from '@patsnap/synapse_common_interface'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { GMiniSwitch } from '@pharmsnap/shared/components/ui/GMiniSwitch/GMiniSwitch'
import { useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IAssigneeCountryItem, ITopAssigneeItem, ITopAssigneeRes } from '@pharmsnap/shared/types'
import { getLocalStorage, pickDisplayNameByLocale, removeLocalStorage, setLocalStorage } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { generatePatentAggParams, getGrantedFilterItem } from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentSetting } from '../../ui/PatentSetting/PatentSetting'
import { PatentStatusField } from '../PatentStatusField/PatentStatusField'
import { AssigneesCountry } from './AssigneesCountry/AssigneesCountry'
import $classes from './TopAssignees.module.scss'
import { useTopAssigneesChart } from './useTopAssigneesChart'
import { useTopAssigneesTable } from './useTopAssigneesTable'

const TOP_ASSIGNEES_STORE_KEY = 'PATENT-ANALYSIS-TOP-ASSIGNEES-STORE-KEY'
export const TopAssignees = defineComponent({
  name: 'TopAssignees',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const { isScrollInView, handleScrollInView } = useLazyComponent()
    const { locale } = useLocale()

    const isAggregationByParent = ref(true)
    const topAssignees = ref<ITopAssigneeItem[]>([])
    const countries = ref<IAssigneeCountryItem[]>([])
    const selectedCountryId = ref<string[]>([])

    const selectedCountryInfo = computed(() => {
      if (selectedCountryId.value.length && countries.value.length) {
        return selectedCountryId.value.map((countryId) => countries.value.find((country) => country.id === countryId) as IAssigneeCountryItem)
      }
      // 刚进入页面，查看storage是否存在选择过的国家
      return getStorageCountry()
    })

    const isSingleBar = computed(() => !topAssignees.value.length || topAssignees.value.length === 1)
    const aggregationField = computed(() => (isAggregationByParent.value ? 'PHS_ORG_ROOT_ID_ONEID' : 'PHS_ORG_ID_ONEID'))
    const disableSetting = computed(() => isSingleBar.value || isEmpty.value)
    const params = computed(() => {
      const {
        query: { must, filter = [] },
      } = props.queryService.state

      const selectedCountry = selectedCountryInfo.value
      const countryMustItem: IQueryItemField = {
        type: 'field',
        fields: ['V_ORG_COUNTRY'],
        condition: E_QUERY_ITEM_CONDITION.ANY,
        value: selectedCountry.map((country) => ({
          type: 'text',
          value: country.id,
          display_name_en: country.display_name_en || '',
          display_name_cn: country.display_name_cn || '',
          search_strategy: 'ID',
        })),
      }
      const customQuery: IQuery = {
        must: selectedCountry.length ? must.concat(countryMustItem) : must,
        filter: selectedPatentStatus.value === 'granted' ? filter.concat(getGrantedFilterItem()) : filter,
        type: 'group',
      }
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          aggregation_field: aggregationField.value,
          sort_type: 'count',
          order: 'desc',
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation, customQuery)
    })
    const {
      isLoading,
      isFullScreen,
      isEmpty,
      popoverVisible,
      selectedField,
      selectedPatentStatus,
      changePatentStatus,
      toggleSelectedField,
      toggleFullScreen,
      togglePopoverVisible,
      getChartData,
    } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getPatentTopAssignee.bind(sharedCtx.service.patent),
      checkEmptyFn: (data?: ITopAssigneeRes) => !data?.items?.length,
      successCallback: (data: ITopAssigneeRes) => {
        topAssignees.value = data.items || []
        countries.value = data.countries || []
      },
    })
    const { chartData, chartContainer, fullScreenChartContainer, chartContainerHeight } = useTopAssigneesChart({
      selectedPatentStatus,
      data: topAssignees,
      isSingleBar,
      service: props.queryService,
      aggregationField,
      locale,
    })
    const { tableColumns, tableData, fullScreenTableColumns, fullScreenTableData, mergeCellFun } = useTopAssigneesTable({
      chartData,
      selectedCountry: selectedCountryInfo,
      isSingleBar,
      selectedPatentStatus,
    })

    const clearStoreSelectedCountry = () => removeLocalStorage(TOP_ASSIGNEES_STORE_KEY)

    const getStorageCountry = () => {
      const queryId = props.queryService.state.queryId
      const storageCountry = getLocalStorage<{ [key: string]: IAssigneeCountryItem[] }>(TOP_ASSIGNEES_STORE_KEY, true)?.[queryId] || []
      if (storageCountry.length) return storageCountry
      /** 清除历史缓存，只存最新一条query对应的用户选择的国家 */
      clearStoreSelectedCountry()
      return []
    }

    const handleApplyCounty = async (val: string[]) => {
      selectedCountryId.value = val
      await getChartData()
      /** 只存最新一条query对应的用户选择的国家 */
      clearStoreSelectedCountry()
      setLocalStorage(TOP_ASSIGNEES_STORE_KEY, JSON.stringify({ [props.queryService.state.queryId]: selectedCountryInfo.value }))
    }

    watch(
      () => chartData.value,
      () => {
        if (chartData.value && chartData.value.length < 2) {
          return []
        }
        const [leftData, rightData] = chartData.value
        // 排序后的国家
        selectedCountryId.value = [leftData[0].countryId, rightData[0].countryId]
      },
      {
        deep: true,
      }
    )
    watch(
      () => [props.queryService.state.query, props.queryService.state.collapse],
      () => {
        // 数据集变更时，重置选中的国家，可能新的数据里，不存在该国家
        clearStoreSelectedCountry()
        selectedCountryId.value = []
      }
    )
    watch(
      () => [
        isScrollInView.value,
        isAggregationByParent.value,
        selectedPatentStatus.value,
        props.queryService.state.query,
        props.queryService.state.collapse,
      ],
      () => {
        if (isScrollInView.value) getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      locale,
      popoverVisible,
      selectedField,
      selectedCountryId,
      selectedCountryInfo,
      selectedPatentStatus,
      countries,
      isFullScreen,
      isAggregationByParent,
      isLoading,
      isEmpty,
      isSingleBar,
      disableSetting,
      chartContainer,
      fullScreenChartContainer,
      chartContainerHeight,
      tableColumns,
      tableData,
      fullScreenTableColumns,
      fullScreenTableData,
      mergeCellFun,
      toggleFullScreen,
      togglePopoverVisible,
      changePatentStatus,
      toggleSelectedField,
      handleScrollInView,
      handleApplyCounty,
    }
  },
  methods: {
    renderPatentStatus() {
      return (
        <PatentStatusField
          isFullScreen={this.isFullScreen}
          value={this.selectedPatentStatus}
          onChangePatentStatus={this.changePatentStatus}
        ></PatentStatusField>
      )
    },
    renderCountry() {
      return (
        <AssigneesCountry
          isFullScreen={this.isFullScreen}
          value={this.selectedCountryId}
          options={this.countries}
          onApplyCountry={(val: string[]) => {
            this.togglePopoverVisible(false)
            this.handleApplyCounty(val)
          }}
          onCancel={() => this.togglePopoverVisible(false)}
        ></AssigneesCountry>
      )
    },
    renderDataSetting() {
      return (
        <div>
          <div class={$classes.settingTitle}>{this.$t('topAssignees.assignees')}</div>
          <div>{this.renderAggregationByPatent()}</div>
          <div class="mt-8">{this.renderPatentStatus()}</div>
        </div>
      )
    },
    renderAggregationByPatent() {
      return (
        <GMiniSwitch
          v-model={this.isAggregationByParent}
          onChange={(val: boolean) => (this.isAggregationByParent = val)}
          width={16}
          active-color="#1976D2"
          inactive-color="#BCC2CC"
          active-text={this.$tc('chart.aggregation')}
        ></GMiniSwitch>
      )
    },
    renderSelectedCountry() {
      // 不渲染时仍返回div，用于VNode diff时，避免图container div和该片段div对比，导致container变更
      if (this.isSingleBar) return <div class="flex justify-around px-40 h-4"></div>
      return (
        <div class="flex justify-around px-40 h-4">
          {this.selectedCountryInfo.map((country) => (
            <div class="text-xs leading-4 text-text-t1">
              {pickDisplayNameByLocale(country, this.locale)} ({country.code})
            </div>
          ))}
        </div>
      )
    },
    renderTable() {
      const disableScroll = {
        enabled: false,
      }
      return (
        <vxe-grid
          scrollX={disableScroll}
          scrollY={disableScroll}
          maxHeight="100%"
          class={this.isSingleBar ? null : $style.fullScreenTableWithSpan}
          border={true}
          showHeader={this.isSingleBar}
          size="small"
          data={this.tableData}
          columns={this.tableColumns}
          span-method={this.mergeCellFun}
        ></vxe-grid>
      )
    },
    renderFullscreenTable() {
      const disableScroll = {
        enabled: false,
      }
      return (
        <vxe-grid
          scrollX={disableScroll}
          scrollY={disableScroll}
          showHeader={false}
          class={this.isSingleBar ? $style.fullScreenTable : $style.fullScreenTableWithSpan}
          border={true}
          size="small"
          data={this.fullScreenTableData}
          columns={this.fullScreenTableColumns}
          span-method={this.mergeCellFun}
        ></vxe-grid>
      )
    },
    renderContent() {
      if (this.selectedField === 'chart') {
        return (
          <div class="flex flex-col h-full">
            {this.renderSelectedCountry()}
            <div ref="chartContainer" class="w-full flex-1"></div>
          </div>
        )
      }
      return this.renderTable()
    },
    renderAnalysis() {
      const containerHeight = this.chartContainerHeight + 146 + 16
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            blockTitle={this.$tc('topAssignees.assigneesAnalysis')}
            title={this.$tc('topAssignees.title')}
            desc={this.isSingleBar ? this.$tc('topAssignees.desc') : this.$tc('topAssignees.compareDesc')}
            onToggleFullScreen={this.toggleFullScreen}
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            containerHeight={containerHeight}
            class="mt-12"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="horizonBar"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              <div class="flex items-center">
                <div class="mr-6">{this.renderAggregationByPatent()}</div>
                <div class="mr-6">{this.renderPatentStatus()}</div>
                <PatentSetting
                  visible={this.popoverVisible}
                  onChangeVisible={this.togglePopoverVisible}
                  disabled={this.disableSetting}
                  tips={this.disableSetting ? this.$tc('topAssignees.disableSettingTips') : this.$tc('common.setting')}
                >
                  {this.renderCountry()}
                </PatentSetting>
              </div>
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            title={this.$tc('topAssignees.title')}
            desc={this.isSingleBar ? this.$tc('topAssignees.desc') : this.$tc('topAssignees.compareDesc')}
            isFullScreen
            onToggleFullScreen={this.toggleFullScreen}
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            fullScreenSettings={[
              { label: this.$tc('data'), content: this.renderDataSetting },
              {
                label: this.$tc('chart'),
                content: this.renderCountry,
                disabled: this.disableSetting,
                tips: this.disableSetting ? this.$tc('topAssignees.disableSettingTips') : '',
              },
            ]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div class={[$style.fullScreenChart, $classes.fullScreenChart]} style={{ height: `${this.chartContainerHeight + 16}px` }}>
                  {this.renderSelectedCountry()}
                  <div ref="fullScreenChartContainer" class="w-full h-full"></div>
                </div>
                <div class="mt-8">{this.renderFullscreenTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
