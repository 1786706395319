import { ALL_SUBJECT_CONFIG_MAP } from '@patsnap/synapse_common_config'
import { IAggregationData, IAggregationValue, IQueryDataType } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sortSubject } from '@pharmsnap/shared/src/utils'
import { extractSpecifiedItemsFromAggResult } from '@pharmsnap/shared/src/utils/business/trans-medicine/trans-medicine-chart'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { max } from 'lodash'
import { ColumnInfo } from 'vxe-table'
import { BAnalysisEntityItemWithPopper } from './BAnalysisEntityItemWithPopper'
import { BAnalysisPieDoughnut } from './BAnalysisPieDoughnut'
import $style from './BAnalysisPieDoughnutTable.module.scss'
import { BAnalysisWaterMarkContainer } from './BAnalysisWaterMarkContainer'

type ITableItem = { [key: string]: IAggregationValue | undefined }

export const BAnalysisResearchTrendChart = defineComponent({
  name: 'BAnalysisResearchTrendChart',
  props: {
    aggData: {
      required: true,
      type: Object as PropType<IAggregationData>,
    },
    isEmpty: {
      type: Boolean,
    },
    analysisEntity: {
      type: String as PropType<Extract<IQueryDataType, 'target' | 'disease' | 'drug_type'>>,
      required: true,
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()

    const aggItems = computed<IAggregationValue[] | null>(() => {
      if (props.isEmpty) {
        return null
      }
      return props.aggData.aggregation_result[0].items
    })

    const renderAnalysisEntity = (item?: IAggregationValue) => {
      if (item) {
        return (
          <BAnalysisEntityItemWithPopper
            entity={{
              id: item.key,
              type: props.analysisEntity,
              name_cn: item?.display_name_cn,
              name_en: item?.display_name_en,
            }}
          ></BAnalysisEntityItemWithPopper>
        )
      }
      return <div class="mt-1 h-4"></div>
    }

    const pieTableChartColumn = computed<Partial<ColumnInfo>[]>(() => {
      if (aggItems.value) {
        return aggItems.value.map((item) => ({
          field: item.key,
          title: item.key,
          minWidth: 132,
          slots: {
            default: ({ row }: { row: ITableItem }) => {
              return [
                <div class="flex flex-col items-center" key={`${item.key}-${row[item.key]?.key}`}>
                  <BAnalysisPieDoughnut
                    data={row[item.key]}
                    firstDimName={getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, locale.value)}
                    onClickItem={(categoryItem: IAggregationValue) => handleClick(item, row[item.key]!, categoryItem)}
                    onClickTotal={() => handleClick(item, row[item.key]!)}
                  ></BAnalysisPieDoughnut>
                  {<div class="break-all">{renderAnalysisEntity(row[item.key])}</div>}
                </div>,
              ]
            },
            header: () => [<div class="font-semibold text-xs leading-4 text-center text-text-t1">{item.key}</div>],
          },
        }))
      }
      return []
    })

    const pieTableChartData = computed(() => {
      if (aggItems.value) {
        // 表格行数
        const tableRowsNum = max(aggItems.value.map((oneDimItem) => oneDimItem.aggregation_result?.[0]?.items?.length))
        const tableData: ITableItem[] = []

        if (tableRowsNum) {
          Array.from({ length: tableRowsNum }).forEach((_i, index) => {
            const tableItem: ITableItem = {}
            pieTableChartColumn.value.forEach((column) => {
              const oneDimData = aggItems.value?.find((item) => item.key === column.field)
              const twoDimData = oneDimData?.aggregation_result?.[0]?.items?.[index]
              if (twoDimData) {
                twoDimData.aggregation_field = oneDimData?.aggregation_result?.[0]?.aggregation_field
                tableItem[column.field] = twoDimData
              }
            })
            tableData.push(tableItem)
          })
        }
        return tableData
      }
      return []
    })

    const subjectItems = computed(() => {
      const items = extractSpecifiedItemsFromAggResult(props.aggData?.aggregation_result || [], 'SUBJECT_ID')
      return items.sort((a, b) => sortSubject(a.key, b.key))
    })

    function handleClick(oneDimItem: IAggregationValue, twoDimItem: IAggregationValue, threeDimItem?: IAggregationValue) {
      emit('click', {
        oneDimItem: {
          ...oneDimItem,
          aggregation_field: props.aggData.aggregation_result[0].aggregation_field,
        },
        twoDimItem,
        threeDimItem,
      })
    }

    return {
      pieTableChartColumn,
      pieTableChartData,
      subjectItems,
      locale,
    }
  },
  methods: {
    renderPieTable() {
      const enableScroll = {
        enabled: true,
        gt: 10,
      }
      return (
        <BAnalysisWaterMarkContainer>
          <vxe-grid
            class={[$style.pieDoughnutTable, 'z-[1]']}
            scrollX={enableScroll}
            scrollY={enableScroll}
            maxHeight={this.height}
            border={true}
            showOverflow
            showHeader
            size="small"
            show-header-overflow
            data={this.pieTableChartData}
            columns={this.pieTableChartColumn}
            row-config={{ height: 88 }}
          ></vxe-grid>
        </BAnalysisWaterMarkContainer>
      )
    },
    renderSubjectItems() {
      if (this.subjectItems.length) {
        return (
          <div class="mb-2 flex flex-wrap">
            {this.subjectItems.map((item) => (
              <div class="flex items-center text-xs leading-4 text-text-t1 mr-3">
                <span
                  class="inline-block h-[10px] w-[10px] rounded-[1px] mr-1"
                  style={{ backgroundColor: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2' }}
                ></span>
                <span>{getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, this.locale)}</span>
              </div>
            ))}
          </div>
        )
      }
    },
  },
  render() {
    return (
      <div class="flex flex-col w-full h-full">
        <div class="flex-shrink-0">{this.renderSubjectItems()}</div>
        <div class="flex-1 overflow-hidden">{this.renderPieTable()}</div>
      </div>
    )
  },
})
