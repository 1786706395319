import { AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash'
import md5 from 'md5'
import type { IRequestConfig } from './types'
const CACHE_LIMIT = 88

export class HttpCache {
  private readonly cachePool: Map<string, AxiosResponse> = new Map()

  get(config: IRequestConfig): AxiosResponse {
    const uuid = this.generateUuid(config)
    const value = <AxiosResponse>this.cachePool.get(uuid)

    return cloneDeep(value)
  }

  cache(config: IRequestConfig, data: AxiosResponse): void {
    const uuid = this.generateUuid(config)

    const clonedData = cloneDeep(data)
    this.cachePool.set(uuid, clonedData)
    if (this.cachePool.size > CACHE_LIMIT) {
      const firstKey = this.cachePool.keys().next().value
      this.cachePool.delete(firstKey)
    }
  }

  private generateUuid(config: IRequestConfig): string {
    const { method, url, params = {}, data = {} } = config
    return md5(`${method}|${url}|${JSON.stringify(params)}|${JSON.stringify(data)}}`)
  }
}
