import { IBaseDrugAdc } from '@patsnap/synapse_common_interface'
import { isUndefined } from 'lodash'
import type { IVueI18n } from 'vue-i18n'
import {
  BDrugAdcAntibodyField,
  BDrugAdcAntibodyTypeField,
  BDrugAdcConjugationSiteField,
  BDrugAdcDarField,
  BDrugAdcLinkerField,
  BDrugAdcPayloadField,
  BDrugAdcSiteSpecialField,
} from './BDrugAdcField'

export function getDrugAdRenderContentList(data: IBaseDrugAdc & { is_xdc?: boolean }, $i18n: IVueI18n) {
  return [
    {
      label: $i18n.tc('synapse_i18n.drug.adc.antibody'),
      content: BDrugAdcAntibodyField,
      invalid: (data: IBaseDrugAdc) => {
        return !data.antibody_drug_id_view
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.linker'),
      content: BDrugAdcLinkerField,
      invalid: (data: IBaseDrugAdc) => {
        return !data.linker_id_view
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.payload'),
      content: BDrugAdcPayloadField,
      invalid: (data: IBaseDrugAdc) => {
        return !data.payload_id_view
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.dar'),
      content: BDrugAdcDarField,
      invalid: (data: IBaseDrugAdc) => {
        return isUndefined(data.dar)
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.antibodyType'),
      content: BDrugAdcAntibodyTypeField,
      invalid: (data: IBaseDrugAdc) => {
        return !data.antibody_type_view
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.conjugationSite'),
      content: BDrugAdcConjugationSiteField,
      invalid: (data: IBaseDrugAdc) => {
        return !data.site_id_view
      },
    },
    {
      label: $i18n.tc('synapse_i18n.drug.adc.siteSpecial'),
      content: BDrugAdcSiteSpecialField,
      invalid: (data: IBaseDrugAdc) => {
        return isUndefined(data.specific_site)
      },
    },
  ].map((item) => ({ ...item, label: `${item.label}${$i18n.locale.toUpperCase() === 'CN' ? '：' : ': '}` }))
}
