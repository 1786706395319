import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { IBaseOrgWithItemInfo } from '@pharmsnap/shared/types'
import { checkOrganizationIsClosed, getDictItemName, getOrganizationName, getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIconLink } from '../../ui/GIconLink/GIconLink'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BIcon } from '../BIcon/BIcon'
import { BOrganizationPopperCard } from '../card/BOrganizationCard/BOrganizationPopperCard'
import { BEntityTag } from '../tag/BEntityTag/BEntityTag'
export const BOrganizationItemWithToolTip = defineComponent({
  name: 'BOrganizationItemWithToolTip',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseOrgWithItemInfo>,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    zIndex: Number,
    iconType: {
      type: String as PropType<'people' | 'company'>,
      default: 'company',
    },
  },
  setup(props) {
    const { isCN, localeUpcase, locale } = useLocale()
    const { data, iconType } = toRefs(props)
    const { org_id_view } = data.value
    const isCompany = computed(() => iconType.value === 'company')
    const href = computed(() =>
      isCompany.value && org_id_view?.is_ls_related && !checkOrganizationIsClosed(org_id_view?.entity_status) && org_id_view?.entity_id
        ? sharedCtx.router.generatorOrgPath(org_id_view.entity_id)
        : ''
    )
    const name = computed(() => getOrganizationName(org_id_view || {}, locale.value))
    const logo = computed(() => org_id_view?.logo_s3_path || org_id_view?.logo)
    return {
      isCompany,
      logo,
      href,
      name,
      isCN,
      localeUpcase,
    }
  },
  methods: {
    renderContent(item: IBaseOrgWithItemInfo) {
      return item.detail?.length ? (
        <div>
          {item.detail.map((d) => {
            const drugName = d.drug_id_view ? getSpecialLang({ data: d.drug_id_view.drug_name, field: 'name', lang: this.localeUpcase }) : ''
            const diseaseName = d.disease_id_view
              ? getSpecialLang({ data: d.disease_id_view.disease_name, field: 'name', lang: this.localeUpcase })
              : ''
            const phaseName = d.dev_status_view ? getDictItemName(d.dev_status_view, this.localeUpcase) : ''
            const displayLabel = [drugName, diseaseName, phaseName].filter((o) => o).join(' | ')
            return <div>{displayLabel}</div>
          })}
        </div>
      ) : null
    },
    renderToolTip(item: IBaseOrgWithItemInfo) {
      if (!item.detail?.length) return
      const validLength = item.detail.filter((d) => d.disease_id_view || d.drug_id_view).length
      if (!validLength) return
      return (
        <GTooltip class="flex-shrink-0" theme="light" mode="icon" placement="bottom">
          <template slot="content">{this.renderContent(item)}</template>
        </GTooltip>
      )
    },
  },
  render() {
    const id = this.data.org_id_view?.entity_id
    const content = (
      <BEntityTag data-testid="b-getOrganizationName-item-with-tooltip" class={{ 'cursor-pointer': id }}>
        <GIconLink href={this.href} name={this.name} ellipsisLine={1}>
          <BIcon
            border={true}
            slot="icon"
            style="margin-right: 4px;"
            class={[$utilClasses.lsFlexShrink]}
            type={this.iconType}
            src={this.isCompany ? this.logo : ''}
            size={16}
          ></BIcon>
        </GIconLink>
      </BEntityTag>
    )
    if (id && this.iconType === 'company' && this.popover) {
      return (
        <div class="flex items-center bg-gray-30 rounded max-w-full">
          <BOrganizationPopperCard id={id} zIndex={this.zIndex}>
            {content}
          </BOrganizationPopperCard>
          {this.renderToolTip(this.data)}
        </div>
      )
    }
    return content
  },
})
