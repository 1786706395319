import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { getErrorCodeMessage, handleError } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { noop } from 'lodash'
import { WechatCode } from 'patsnap-biz'
interface IGetCodeResponse {
  /**
   * 传给轮询接口的二维码识别码
   */
  callback_value: string
  /**
   * 二维码过期时间(单位秒)
   */
  expire_seconds?: number
  /**
   * 二维码地址
   */
  pic_url: string
}
export const BWechatBindCode = defineComponent({
  name: 'BWechatBindCode',
  props: {
    bindSuccessCallback: {
      type: Function as PropType<() => void>,
      default: noop,
    },
  },
  emits: ['success'],
  setup(props, ctx) {
    const { locale } = useLocale()
    async function getCodeFn() {
      const rt = await sharedCtx.service.login.getWechatBindCode()
      if (rt.success) {
        return rt.data
      }
      return Promise.reject()
    }
    async function pollingScanStatusFun(data: IGetCodeResponse) {
      const rt = await sharedCtx.service.login.checkWechatBindStatus(data.callback_value)
      if (rt.success) {
        if (rt.data.identify_token) {
          return true
        }
        return undefined
      } else {
        handleError(rt, locale.value)
        const error_code = rt.data?.numeric_error_code
        const error_msg = getErrorCodeMessage(error_code, locale.value)
        return Promise.reject({ error_msg })
      }
    }
    function onSuccess() {
      ctx.emit('success')
    }
    return { getCodeFn, pollingScanStatusFun, onSuccess }
  },
  render() {
    return <WechatCode onSuccess={this.onSuccess} getCodeFun={this.getCodeFn} pollingScanStatusFun={this.pollingScanStatusFun} />
  },
})
