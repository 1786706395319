import { IAggregationParams, IQuery, ITwoDimensionAggData } from '@patsnap/synapse_common_interface'
import { GMiniSwitch } from '@pharmsnap/shared/components'
import {
  transformPhaseStackBarEventParam2Count,
  transformPhaseStackBarEventParam2QueryField,
  transformTwoDimAggData2PhaseHorChartItems,
  useNavListIds,
  usePhaseStackBarChart,
} from '@pharmsnap/shared/composition'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ILang } from '@pharmsnap/shared/types'
import { checkGoingDownDrugListByDrugIds, transTwoDimTupleItems2Ids, transTwoDimTupleItems2QueryItem } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, PropType, ref, watch } from '@vue/composition-api'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $classes from './BTopChart.module.scss'

export const BTopChart = defineComponent({
  name: 'BTopChart',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    title: {
      required: true,
      type: String,
    },
    field: {
      required: true,
      type: String as PropType<'target' | 'disease' | 'org' | 'drugType'>,
    },
    desc: {
      type: String,
    },
    header: {
      type: String,
    },
  },
  setup(props) {
    const dataSource = ref<
      ITwoDimensionAggData<{
        related_drug_id: string[]
        recent_30: number
      }>
    >()
    const localeData = useLocale()
    const loading = ref(true)
    const ins = getCurrentInstance()
    const switchVal = ref(true)

    const getChartData = async () => {
      const aggParams: IAggregationParams = {
        data_type: props.queryService.state.data_type,
        query: { ...props.queryService.state.query, type: 'group' },
        aggregation: [],
        limit: 10,
        aggregate_by_parent: switchVal.value,
      }
      loading.value = true
      if (props.field === 'target') {
        const res = await sharedCtx.service.analysis.getTopTargetAggregationByPhase({ ...aggParams })
        if (res.success) {
          dataSource.value = res.data
        }
      } else if (props.field === 'disease') {
        const res = await sharedCtx.service.analysis.getTopDiseaseAggregationBySearch({ ...aggParams })
        if (res.success) {
          dataSource.value = res.data
        }
      } else if (props.field === 'org') {
        const res = await sharedCtx.service.analysis.getTopOrgAggregationByPhase({ ...aggParams })
        if (res.success) {
          dataSource.value = res.data
        }
      } else if (props.field === 'drugType') {
        const res = await sharedCtx.service.analysis.getTopDrugTypeAggregationByPhase({ ...aggParams })
        if (res.success) {
          dataSource.value = res.data
        }
      }
      loading.value = false
    }

    const navListQuery = computed<IQuery>(() => ({ type: 'group' }))

    const { onNavToListWithIds } = useNavListIds({
      navList: 'drug',
      query: navListQuery,
      appendTo: 'must',
      auth: true,
    })

    watch(
      () => [props.queryService.state.query, switchVal],
      () => {
        getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const useYShortName = computed(() => props.field === 'target')

    const phaseItems = computed(() => {
      return transformTwoDimAggData2PhaseHorChartItems<{
        related_drug_id: string[]
        recent_30: number
      }>({ locale: localeData.locale.value.toUpperCase() as ILang, useYShortName: useYShortName.value }, dataSource.value)
    })

    const { chartHeight, chartContainer, isEmpty, getEchartsInstance } = usePhaseStackBarChart(phaseItems, {
      xAxisName: () => (ins?.proxy.$tc('Analysis.No_Of_Drug') as string) || '',
      yAxisName: '',
      clickAble: true,
      noSortByCount: true,
      registerEvent: (instance) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        instance.on('click', async (params: any) => {
          const count = transformPhaseStackBarEventParam2Count(params)
          if (count === null || count === 0) return
          const tupleItems = transformPhaseStackBarEventParam2QueryField(params)
          const { xAxisQuery, yAxisQuery } = transTwoDimTupleItems2QueryItem(tupleItems)
          const drugIds = transTwoDimTupleItems2Ids<{ related_drug_id: string[] }>(tupleItems, 'related_drug_id')
          if (drugIds.length === 0) return
          const limitedDrugIds = await checkGoingDownDrugListByDrugIds(drugIds, ins?.proxy.$tc('drug.limitWarning') || '')
          await onNavToListWithIds(limitedDrugIds, 'drug', 'DRUG_ID', (searchParams) => {
            xAxisQuery && searchParams.query.must?.push(xAxisQuery)
            yAxisQuery && searchParams.query.must?.push(yAxisQuery)
            searchParams.query.filter && searchParams.query.must?.push(...searchParams.query.filter)
            searchParams.query.filter = []
            return searchParams
          })
        })
      },
    })

    const renderHeader = () => {
      return (
        <div>
          {props.header ? <h1 class={$classes.containerHeader}>{props.header}</h1> : null}
          {props.field === 'org' ? (
            // 要修改的地方
            <div class={$classes.switchContainer}>
              <GMiniSwitch
                width={16}
                value={switchVal.value}
                active-color="#1976D2"
                inactive-color="#BCC2CC"
                onChange={() => (switchVal.value = !switchVal.value)}
                active-text={ins?.proxy.$t('Analysis.SwitchLabel')}
              ></GMiniSwitch>
            </div>
          ) : null}
        </div>
      )
    }

    return { chartHeight, chartContainer, isEmpty, getEchartsInstance, loading, renderHeader }
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        <BCardContainer
          style={{ height: `${this.chartHeight + 64}px` }}
          isLoading={this.loading}
          isEmpty={this.isEmpty}
          title={this.title}
          desc={this.desc}
          titleClass={$classes.title}
        >
          <div ref="chartContainer" style="height: 100%;"></div>
        </BCardContainer>
      </div>
    )
  },
})
