import { defineComponent, reactive, toRefs, PropType, watch } from '@vue/composition-api'
import { ElDatePicker } from '@pharmsnap/shared/element-ui'
import VClickOutside from 'v-click-outside'
import { VNodeData } from 'vue'
import cn from './locales/cn.json'
import en from './locales/en.json'
import $classes from './BDatePicker.module.scss'
import { cloneDeep } from 'lodash'

export const BDatePicker = defineComponent({
  name: 'BDatePicker',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    value: {
      type: [Array, String] as PropType<string[] | string>,
      default: () => [],
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    format: {
      type: String,
      default: 'yyyy/MM/dd',
    },
    type: {
      type: String as PropType<
        'year' | 'month' | 'date' | 'dates' | 'week' | 'datetime' | 'datetimerange' | 'daterange' | 'monthrange' | 'yearrange'
      >,
      default: 'daterange',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '350px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'large' | 'small' | 'mini'>,
      default: 'small',
    },
    unlinkPanel: {
      type: Boolean,
      default: true,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, { emit }) {
    const data = reactive<{ date: string[] | string }>({
      date: [],
    })

    watch(
      () => props.value,
      () => {
        data.date = cloneDeep(props.value)
      },
      { immediate: true }
    )

    const handleClick = (date: string[]) => {
      emit('click', date)
    }

    const getPlaceholder = () => {
      if (props.type === 'year') {
        return 'YYYY'
      }
      return ''
    }

    function handelClickOutside() {
      emit('clickOutside')
    }

    return { ...toRefs(data), handleClick, getPlaceholder, handelClickOutside }
  },
  render() {
    const vNodeDatePicker: VNodeData = {
      props: {
        type: this.type,
        'range-separator': this.$t('DatePicker.range-separator'),
        'start-placeholder': this.$t(`DatePicker.start-${this.valueFormat}`),
        'end-placeholder': this.$t(`DatePicker.end-${this.valueFormat}`),
        placeholder: this.getPlaceholder(),
        'value-format': this.valueFormat,
        'unlink-panels': this.unlinkPanel,
        format: this.format,
      },
      on: {
        input: this.handleClick,
      },
    }
    return (
      <ElDatePicker
        value={this.date}
        disabled={this.disabled}
        style={`width:${this.width}`}
        class={$classes.datePicker}
        size={this.size}
        {...vNodeDatePicker}
        clearable={this.clearable}
        {...{
          directives: [
            {
              name: 'click-outside',
              value: this.handelClickOutside,
            },
          ],
        }}
      ></ElDatePicker>
    )
  },
})
