import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import {
  CT_DATA_SOURCE_LANG,
  CT_IF_RESULT_LANG,
  CT_IIT_LANG,
  CT_SINGLE_MULTI_CENTER_LANG,
  SINGLE_OR_MULTI_DRUG_LANG,
  SPONSOR_FUNDER_TYPE_LANG,
} from '@pharmsnap/shared/constants/enum-lang/clinical'
import {
  injectLangFieldByKey,
  injectLangFieldByKeyLangMap,
  injectOrgName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'

export function replaceDisplayNameByShortLangMap(data: IAggregationValue): IAggregationValue {
  if (!data.short_name_en) return data
  if (data.short_name_en && data.short_name_en.length === 0) return data
  return {
    ...data,
    display_name_en: data.short_name_en[0] || '',
    display_name_cn: data.short_name_cn?.[0] || '',
  }
}

export const clinicalFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  TARGET_ID: (data) => replaceDisplayNameByShortName(data),
  MECHANISM_ACTION_ID: (data) => replaceDisplayNameByShortLangMap(data),
  ENROLLMENT: (data) => injectLangFieldByKey(data),
  RECRUITMENT_STATUS_NAME_NOR: (data) => injectLangFieldByKey(data),
  STUDY_TYPE_NOR: (data) => injectLangFieldByKey(data),
  DATA_SOURCE: (data) => injectLangFieldByKeyLangMap(CT_DATA_SOURCE_LANG, data),
  REGISTRY_ID_ROOT_ONEID: injectOrgName,
  REGISTRY_ID_ONEID: injectOrgName,
  RESEARCH_ORG_ID_ROOT_ONEID: injectOrgName,
  NORMALIZED_ID_LEADER_ONEID: injectOrgName,
  SPONSOR_LEADER_ID_ROOT_ONEID: injectOrgName,
  NORMALIZED_ID_COLLABORATOR_ONEID: injectOrgName,
  SPONSOR_COLLABORATOR_ID_ROOT_ONEID: injectOrgName,
  COUNTRY_COUNT: (data) => injectLangFieldByKey(data),
  MULTI_CENTER: (data) => injectLangFieldByKeyLangMap(CT_SINGLE_MULTI_CENTER_LANG, data),
  IF_CT_RESULT: (data) => injectLangFieldByKeyLangMap(CT_IF_RESULT_LANG, data),
  SINGLE_OR_MULTI: (data) => injectLangFieldByKeyLangMap(SINGLE_OR_MULTI_DRUG_LANG, data),
  RECRUITMENT_STATUS_NAME_NOR_V2: (data) => injectLangFieldByKey(data),
  EXPANDED_ACCESS_NAME_NOR: (data) => injectLangFieldByKey(data),
  SPONSOR_FUNDER_TYPE: (data) => injectLangFieldByKeyLangMap(SPONSOR_FUNDER_TYPE_LANG, data),
  IIT: (data) => injectLangFieldByKeyLangMap(CT_IIT_LANG, data),
}

function removeSpecifiedKeyData(
  data: IAggregationSingleResult<IAggregationValue<any>>,
  key = 'UNKNOWN'
): IAggregationSingleResult<IAggregationValue<any>> {
  return {
    ...data,
    items: data.items.filter((item) => item.key !== key),
  }
}

const clinicalDataTransformFn = (data: IAggregationSingleResult<IAggregationValue<any>>) => {
  const clinicalFieldDataOrderMap: Record<
    string,
    (data: IAggregationSingleResult<IAggregationValue<any>>) => IAggregationSingleResult<IAggregationValue<any>>
  > = {
    SINGLE_OR_MULTI: (data) => removeSpecifiedKeyData(data),
    SPONSOR_FUNDER_TYPE: (data) => removeSpecifiedKeyData(data),
    STUDY_TYPE_NOR: (data) => removeSpecifiedKeyData(data, '<BLANK>'),
  }

  return clinicalFieldDataOrderMap[data.aggregation_field] ? clinicalFieldDataOrderMap[data.aggregation_field](data) : data
}

export function transformClinicalAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(clinicalDataTransformFn(data), clinicalFieldTransformFnMap, xCorrelationId)
}
