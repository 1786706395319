import '@patsnap-ui/icon/assets/solid/CoreProductsChemical.svg'
import { structureColorConfig, structureLangConfig } from '@patsnap/synapse_common_config'
import { VUE_APP_PRODUCT_CHEMICAL_URL } from '@pharmsnap/pharmsnap-web/config'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/src/constants'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IStructureItem } from '@pharmsnap/shared/src/types/drug'
import { openNewTab } from '@pharmsnap/shared/src/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { BCopy } from '../BCopy/BCopy'

export const BStructureCard = defineComponent({
  name: 'BStructureCard',
  props: {
    data: {
      required: true,
      type: Object as PropType<IStructureItem>,
    },
    layout: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'vertical',
    },
  },
  setup(props) {
    const { tsText, localeUpcase, locale } = useLocale()

    function clickStructureFind() {
      sharedCtx.service.tracking.trackGeneral({
        event_code: 'VIEW_CHEMICAL_STRUCTURE',
        trigger_point: 'SIMILAR',
      })
      openNewTab(`${VUE_APP_PRODUCT_CHEMICAL_URL}/search#/structure?inchiKey=${props.data.inchi_key}`)
    }
    return { tsText, localeUpcase, clickStructureFind, locale }
  },
  methods: {
    renderFinderSimilar() {
      return (
        <span onClick={this.clickStructureFind} class="inline-flex items-center text-blue-default cursor-pointer hover:underline">
          <PtIcon style="font-size: 24px;" icon="SolidCoreProductsChemical"></PtIcon>
          <span class="text-sm leading-6 ml-1">{this.localeUpcase === 'CN' ? '查看相似结构' : 'Find Similar'}</span>
        </span>
      )
    },
    renderStructureType() {
      const tagType = this.data.structure_type

      if (!tagType) return null

      const textColor = structureColorConfig[tagType].color
      const bgColor = structureColorConfig[tagType].bgColor
      const tagName = structureLangConfig[tagType].shortName[this.locale]

      return (
        <span
          style={{ color: textColor, backgroundColor: bgColor }}
          class={['h-6 inline-flex items-center px-2 rounded text-xs leading-4 max-w-full truncate']}
        >
          {tagName}
        </span>
      )
    },
  },
  render() {
    if (this.layout === 'horizontal') {
      return (
        <div class="flex">
          <div class="flex-shrink-0 border border-gray-60 rounded">
            <img width={300} height={300} src={this.data.base64} onClick={() => this.$emit('casImageClicked')} class="cursor-pointer"></img>
          </div>
          <div class="flex-1 overflow-hidden flex flex-col ml-4">
            <div>{this.renderFinderSimilar()}</div>
            <div class="mt-6">
              <div class="text-text-t2 text-sm leading-5">{this.$tc('synapse_i18n.drug.structure.molecularFormula')}</div>
              <div class="text-text-t1 text-sm leading-5 mt-1">{this.data.formula || EMPTY_PLACEHOLDER}</div>
            </div>
            <div class="mt-6">
              <div class="text-text-t2 text-sm leading-5">{this.$tc('synapse_i18n.drug.structure.standardInChIKey')}</div>
              <div class="text-text-t1 text-sm leading-5 mt-1">{this.data.inchi_key || EMPTY_PLACEHOLDER}</div>
            </div>
            {this.data.cas_no ? (
              <div class="mt-6">
                <div class="text-text-t2 text-sm leading-5">{this.$tc('synapse_i18n.drug.structure.casRegistry')}</div>
                <div class="mt-1">
                  <span class="inline-flex items-center">
                    <span class="text-text-t1 text-sm leading-5">{this.data.cas_no || EMPTY_PLACEHOLDER}</span>
                    {this.data.cas_no ? <BCopy class="ml-2" copyVal={this.data.cas_no || ''}></BCopy> : null}
                  </span>
                </div>
              </div>
            ) : null}
            <div class="mt-6">
              <div class="text-text-t2 text-sm leading-5">{this.locale === 'cn' ? '结构类型' : 'Structure Type'}</div>
              <div class="mt-1">
                <span class="inline-flex items-center">{this.renderStructureType()}</span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div class="w-[300px]">
        <div class="relative w-[300px] h-[300px] border border-gray-60 rounded flex flex-col justify-between overflow-hidden">
          <div class="text-sm h-[30px] px-2 text-right flex items-center justify-end">{this.renderFinderSimilar()}</div>
          <img
            class="cursor-pointer flex-1 w-[300px] h-[270px]"
            style="object-fit: contain;"
            src={this.data.base64}
            onClick={() => this.$emit('casImageClicked')}
          />
        </div>
        <div>
          {this.data.formula && (
            <div style="word-break: break-word;" class="flex items-center mt-2 text-sm">
              <span>{this.data.formula}</span>
            </div>
          )}

          {this.data.inchi_key && (
            <div style="word-break: break-word;" class="flex items-center mt-2 text-sm">
              <span>{this.data.inchi_key}</span>
            </div>
          )}
          {this.data.cas_no && (
            <div class="flex items-center mt-2 text-sm">
              <span>{this.data.cas_no || EMPTY_PLACEHOLDER}</span>
              <BCopy copyVal={this.data.cas_no} class="ml-2"></BCopy>
            </div>
          )}
          {this.data.structure_type ? <div class="flex items-center mt-2">{this.renderStructureType()}</div> : null}
        </div>
      </div>
    )
  },
})
