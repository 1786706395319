import { sharedCtx } from '@pharmsnap/shared/context/index'
import { openNewTab } from '@pharmsnap/shared/utils'
import { unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'

export function useAnalysisChartNavDetail(options: { id?: MaybeRef<string | undefined>; type?: MaybeRef<string | undefined> }) {
  function handleAxisClick() {
    const type = unref(options.type)
    const id = unref(options.id)
    if (!type || !id) return
    let href = ''
    if (type === 'disease') href = sharedCtx.router.generatorDiseasePath(id)
    else if (type === 'drug') href = sharedCtx.router.generatorDrugPath(id)
    else if (type === 'target') href = sharedCtx.router.generatorTargetPath(id)
    else if (type === 'organization') href = sharedCtx.router.generatorOrgPath(id)
    else if (type === 'drug_type') href = sharedCtx.router.generatorDrugTypePath(id)
    if (href) {
      openNewTab(href)
    }
  }

  return {
    handleAxisClick,
  }
}
