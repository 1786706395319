import { defineComponent, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BTargetFetchCard } from './BTargetFetchCard'

export const BTargetPopperCard = defineComponent({
  name: 'BTargetPopperCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper data={{ id: this.id }} tippyConfig={this.tippyConfig} component={BTargetFetchCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
