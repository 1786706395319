import { GLinkProps, IGLinkColor } from '@pharmsnap/shared/components/props'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { handleSpace2Str } from '@pharmsnap/shared/utils/encrypt/encryptSpace'
import { defineComponent } from '@vue/composition-api'
import $classes from './GLink.module.scss'
function getColorClass(params: IGLinkColor) {
  if (params === 'blue') {
    return $classes.linkBlue
  }
  if (params === 'green') {
    return $classes.linkGreen
  }
  return $classes.linkDark
}
export const GLink = defineComponent({
  name: 'GLink',
  props: GLinkProps,
  setup(props, { emit }) {
    function beforeGoTarget(e: Event) {
      emit('beforeGoTarget', e)
    }

    return {
      beforeGoTarget,
    }
  },
  render() {
    const slotDefault = this.$slots.default
    const displayName = this.name || EMPTY_PLACEHOLDER
    const colorClassName = getColorClass(this.defaultColor)
    const wrapLinkFn = (content: JSX.Element | JSX.Element[]) =>
      this.href && this.href !== '/' ? (
        <a
          class={[
            $classes.link,
            $classes.container,
            {
              truncate: this.truncate,
              'max-w-full': this.truncate,
            },
            colorClassName,
            'pharm-hover--enable',
            this.customClass,
          ]}
          href={this.href}
          target={this.newTab ? '_blank' : '_self'}
          onClick={this.beforeGoTarget}
        >
          {content}
        </a>
      ) : (
        <span
          class={[
            $classes.text,
            $classes.container,
            colorClassName,
            {
              truncate: this.truncate,
              'max-w-full': this.truncate,
            },
            this.customClass,
          ]}
        >
          {content}
        </span>
      )
    if (slotDefault) {
      return wrapLinkFn(slotDefault)
    }
    return wrapLinkFn(<span domPropsInnerHTML={this.encryptClass ? handleSpace2Str(displayName, this.encryptClass) : displayName}></span>)
  },
})
