import { defineComponent } from '@vue/composition-api'
import $style from './GBackToTop.module.scss'
export const GBackToTop = defineComponent({
  name: 'GBackToTop',
  props: {
    target: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      en: {
        slogan: 'AI-Powered Pharmaceutical Intelligence',
      },
      cn: {
        slogan: 'AI 赋能新药情报',
      },
    },
  },
  setup(props) {
    function onBackToTop() {
      const $target = document.querySelector(props.target)
      if ($target) {
        $target.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
    return { onBackToTop }
  },
  render() {
    return (
      <div class="text-center">
        <div class={$style.backToTop} onClick={this.onBackToTop}></div>
        <div class="mt-4 text-gray-90">{this.$t('slogan')}</div>
      </div>
    )
  },
})
