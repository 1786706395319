import Vue, { VNode, CreateElement, RenderContext } from 'vue'
const height = 'msPointerEnabled' in navigator || 'msDoNotTrack' in navigator ? '99%' : '100%'
export const BPdf = Vue.extend({
  name: 'pdf',
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode {
    const { staticClass, staticStyle } = context.data

    return <iframe class={staticClass} style={staticStyle} height={height} src={context.props.src} width="100%" frameborder="0" />
  },
})

function validIsIE(): boolean {
  // ie 支持到ie11
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}

function validPDFPluginInstall(): boolean {
  if (!validIsIE()) {
    // ie 浏览器 和  非ie浏览器支持
    // not ie
    if (navigator.plugins && navigator.plugins.length) {
      // / Adobe Reader | Adobe PDF | Acrobat | Chrome PDF Viewer
      // tslint:disable-next-line: prefer-for-of no-increment-decrement
      for (let i = 0; i < navigator.plugins.length; i++) {
        const plugin = navigator.plugins[i].name
        if (
          plugin === 'Adobe Reader' ||
          plugin === 'Adobe PDF' ||
          plugin === 'Acrobat' ||
          plugin === 'Chrome PDF Viewer' ||
          plugin === 'Shockwave Flash'
        ) {
          return true
        }
      }
    }

    return false
  } else {
    // ie
    let isInstalled = false
    // let version = null

    let control = null
    control = new ActiveXObject('AcroPDF.PDF')
    if (!control) {
      control = new ActiveXObject('PDF.PdfCtrl')
    }
    if (!control) {
      control = new ActiveXObject('Adobe Acrobat')
    }

    if (!control) {
      control = new ActiveXObject('Adobe PDF Plug-in')
    }
    if (control) {
      isInstalled = true
      // version = control.GetVersions().split(',')
      // version = version[0].split('=')
      // version = parseFloat(version[1])
    }

    return isInstalled
  }
}
