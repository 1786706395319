import type { IBaseSourceLangName, ILang } from '@patsnap/synapse_common_interface'
import { getSortFnByLang } from './common'

export function getDiseaseSynonyms<T extends { alias?: IBaseSourceLangName[] }>(data: T, lang: ILang) {
  return (
    data.alias
      ?.filter((item) => !!item.name)
      .map((item) => item.name)
      .sort(getSortFnByLang(lang)) || []
  )
}
