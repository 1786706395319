import { IDisposable } from './type'
import { addDisposableListener } from './utils'
export interface IPointerMoveCallback {
  (event: PointerEvent): void
}
export interface IOnStopCallback {
  (browserEvent?: PointerEvent | KeyboardEvent): void
}
export class PointerMoveMonitor {
  private readonly _hooks: IDisposable[] = []
  private _pointerMoveCallback: IPointerMoveCallback | null = null
  private _onStopCallback: IOnStopCallback | null = null

  public isMonitoring() {
    return !!this._pointerMoveCallback
  }

  public startMonitor(element: Element | null, pointerMoveCallback: IPointerMoveCallback, onStopCallback: IOnStopCallback) {
    this._pointerMoveCallback = pointerMoveCallback
    this._onStopCallback = onStopCallback
    const win = element?.ownerDocument?.defaultView || window
    this._hooks.push(
      addDisposableListener(win, 'pointermove', (e) => {
        e.preventDefault()
        this._pointerMoveCallback?.(e)
      })
    )
    this._hooks.push(addDisposableListener(win, 'pointerup', () => this.stopMonitor()))
  }

  public stopMonitor() {
    this._hooks.forEach((h) => h.dispose())
    const onStopCallback = this._onStopCallback
    this._pointerMoveCallback = null
    this._onStopCallback = null
    onStopCallback?.()
  }

  public dispose() {
    this.stopMonitor()
  }
}
