import { IPatentType } from '@pharmsnap/shared/types/patent'
import { defineComponent, PropType } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../config'
import { BPatentTechnologyFetchListCard } from './BPatentTechnologyFetchListCard'
export const BPatentTechnologyPopperListCard = defineComponent({
  name: 'BPatentTechnologyPopperListCard',
  props: {
    ids: {
      type: Array as PropType<IPatentType[]>,
      required: true,
    },
  },
  render() {
    return (
      <PtPopper data={{ ids: this.ids }} tippyConfig={getAsyncListTippyConfig()} component={BPatentTechnologyFetchListCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
