import { IFieldLangMap } from '@pharmsnap/shared/types'

export const LITERATURE_DISCIPLINE_CATEGORY: IFieldLangMap = {
  医学: {
    cn: '医学',
    en: 'MEDICINE',
  },
  生物学: {
    cn: '生物学',
    en: 'BIOLOGY',
  },
  工程技术: {
    cn: '工程技术',
    en: 'ENGINEERING & TECHNOLOGY',
  },
  法学: {
    cn: '法学',
    en: 'LAW',
  },
  心理学: {
    cn: '心理学',
    en: 'PSYCHOLOGY',
  },
  农林科学: {
    cn: '农林科学',
    en: 'AGRICULTURAL & FORESTRY SCIENCE',
  },
  计算机科学: {
    cn: '计算机科学',
    en: 'COMPUTER SCIENCE',
  },
  化学: {
    cn: '化学',
    en: 'CHEMISTRY',
  },
  环境科学与生态学: {
    cn: '环境科学与生态学',
    en: 'ENVIRONMENTAL SCIENCE & ECOLOGY',
  },
  人文科学: {
    cn: '人文科学',
    en: 'HUMANITIES',
  },
  数学: {
    cn: '数学',
    en: 'MATHEMATICS',
  },
  经济学: {
    cn: '经济学',
    en: 'ECONOMICS',
  },
  地球科学: {
    cn: '地球科学',
    en: 'EARTH SCIENCE',
  },
  材料科学: {
    cn: '材料科学',
    en: 'MATERIALS SCIENCE',
  },
  物理与天体物理: {
    cn: '物理与天体物理',
    en: 'PHYSICS & ASTROPHYSICS',
  },
  教育学: {
    cn: '教育学',
    en: 'EDUCATION',
  },
  管理学: {
    cn: '管理学',
    en: 'MANAGEMENT',
  },
  综合性期刊: {
    cn: '综合性期刊',
    en: 'CROSS-FIELD',
  },
}
