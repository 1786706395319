import { BDrugItemWithToolTip } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDrugWithItemInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BDrugWithToolTipFetchListCard = defineComponent({
  name: 'BDrugWithToolTipFetchListCard',
  props: {
    data: {
      type: Array as PropType<IBaseDrugWithItemInfo[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const ids = props.data.map((o) => o.drug_id)
    const { loading, data: drugData } = useRequest(
      {
        requestFn: sharedCtx.service.drug.getBatchDrugs.bind(sharedCtx.service.drug),
      },
      ids
    )

    const combineData = computed(() => {
      return props.data.map((item) => {
        const view = drugData.value?.find((o) => o.drug_id === item.drug_id)
        return {
          ...item,
          drug_id_view: view,
        }
      })
    })

    watch(loading, () => {
      ctx.emit('update')
    })

    return {
      loading,
      combineData,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {this.combineData?.map((item) => {
            return <BDrugItemWithToolTip class="mr-1 mb-1" data={item} showCard></BDrugItemWithToolTip>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
