import { E_NODATA_SIZE, IInfiniteLoadingState } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, Ref, ref, toRefs } from '@vue/composition-api'
import _, { cloneDeep } from 'lodash'
import { GCheckList } from '../GCheckList/GCheckList'
import { GEmpty } from '../GEmpty/GEmpty'
import { GMultiDropdownByPage } from './GMultiDropdownByPage'

export const GMultiDropdownPopperByPage = defineComponent({
  name: 'GMultiDropdownPopperByPage',
  props: {
    icon: String,
    label: String,
    title: String,
    width: {
      type: Number,
      default: 200,
    },
    popoverClass: {
      type: String,
      default: '',
    },
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      default: () => [],
    },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    itemKey: {
      type: String,
    },
    checkAllLabel: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    col: {
      type: Number,
      default: 1,
    },
    placement: {
      type: String as PropType<'bottom-start' | 'bottom' | 'bottom-end'>,
      default: 'bottom-start',
    },
    filter: {
      type: Function as PropType<(type: string, item: any) => boolean>,
    },
    sorter: {
      type: Function as PropType<(a: string, b: string) => number>,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOpenRecycleScroller: {
      type: Boolean,
      default: false,
    },
    itemHeight: {
      type: Number,
      default: 24,
    },
    limit: {
      type: Number,
    },
    displayTotal: {
      type: Number,
      default: 0,
    },
    isInfiniteModel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { value, data, limit, itemKey, displayTotal } = toRefs(props)
    // 展示勾选数据
    const tempChecked = ref(cloneDeep(value.value))
    // 实际选中的数量
    const count = computed(() => getCount(value))
    // 总的展示数据数量
    // const displayTotal = computed(() => getCount(data))

    // 判断是否渲染空列表
    const isEmpty = computed(() => displayTotal.value === 0)
    const isShow = ref(false)
    // 处理显示popper
    const onOpen = () => {
      isShow.value = true
      ctx.emit('open')
      // 同步实际勾选项目到视图勾选项目
      tempChecked.value = cloneDeep(value.value)
    }
    // 处理提交勾选项
    const onConfirm = () => {
      // 同步视图勾选项到实际勾选项
      ctx.emit('input', cloneDeep(tempChecked.value))
    }
    // 处理清空勾选数据
    const onClear = () => {
      // 根据传入的数据类型，进行不同的reset操作
      ctx.emit('clear')
      tempChecked.value = []
      ctx.emit('input', [])
    }

    function onInfiniteLoading($state: IInfiniteLoadingState) {
      ctx.emit('infiniteLoading', $state)
    }

    function getCount(value: Ref<any[] | Record<string, any[]>>) {
      const val = value.value
      if (Array.isArray(val)) return val.length
      const keys = Object.keys(val)
      if (keys.length === 0) return 0
      return keys.reduce((pre, next) => pre + val[next].length, 0)
    }

    const disabledList = computed(() => {
      if (Array.isArray(data.value) && Array.isArray(tempChecked.value) && typeof itemKey?.value === 'string') {
        if (limit?.value && tempChecked.value.length >= limit.value) {
          return _.difference(
            data.value.map((o) => o[itemKey.value as string]),
            tempChecked.value
          )
        }
        return []
      }
      return []
    })

    return {
      tempChecked,
      count,
      isEmpty,
      disabledList,
      onOpen,
      onConfirm,
      onClear,
      onInfiniteLoading,
      isShow,
    }
  },
  methods: {
    renderSingleCheckPanel() {
      return [
        <GCheckList
          v-model={this.tempChecked as string[]}
          disabledValue={this.disabledList}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          list={this.data as any[]}
          itemKey={this.itemKey as string}
          col={this.col}
          itemHeight={this.itemHeight}
          isOpenRecycleScroller={this.isOpenRecycleScroller}
          isInfiniteModel={this.isInfiniteModel}
          onInfiniteLoading={this.onInfiniteLoading}
          scopedSlots={{
            default: this.$scopedSlots.item,
          }}
        ></GCheckList>,
        <GEmpty size={E_NODATA_SIZE.SMALL} v-show={!this.isLoading && this.isEmpty}></GEmpty>,
      ]
    },
    renderCheckPanel() {
      return this.renderSingleCheckPanel()
    },
  },
  render() {
    return (
      <GMultiDropdownByPage
        count={this.count}
        popoverClass={this.popoverClass}
        icon={this.icon}
        label={this.label}
        title={this.title}
        width={this.width}
        placement={this.placement}
        isLoading={this.isLoading}
        onOpen={this.onOpen}
        onConfirm={this.onConfirm}
        onClear={this.onClear}
        onClose={() => this.$emit('close')}
      >
        <template slot="header">{this.$scopedSlots.header && this.$scopedSlots.header({ total: this.displayTotal })}</template>
        <template slot="default">{this.isShow && this.renderCheckPanel()}</template>
      </GMultiDropdownByPage>
    )
  },
})
