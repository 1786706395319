import { IPatentIndexField } from '@patsnap/synapse_common_interface'
import { IPatentOptionLabel } from '../types'
export const patentAttributeOptions: IPatentOptionLabel[] = [
  {
    name_cn: '标题/摘要',
    name_en: 'Title/Abstract',
    val: 'TA',
  },
  {
    name_cn: '标题',
    name_en: 'Title',
    val: 'TTL',
  },
  {
    name_cn: '标题/摘要/权利要求',
    name_en: 'Title/Abstract/Claims',
    val: 'TAC',
  },
  {
    name_cn: '标题/摘要/权利要求/说明书',
    name_en: 'Title/Abstract/Claims/Description',
    val: 'TACD',
  },
  {
    name_cn: '摘要',
    name_en: 'Abstract',
    val: 'ABST',
  },
  {
    name_cn: '权利要求',
    name_en: 'Claims',
    val: 'CLMS',
  },
  {
    name_cn: '说明书',
    name_en: 'Description',
    val: 'DESC',
  },
]

export const patentAttributeOptionValueMap: { trans: boolean; val: string; field: IPatentIndexField }[] = [
  {
    trans: false,
    val: 'TA',
    field: 'TA',
  },
  {
    trans: true,
    val: 'TA',
    field: 'TA_ALL',
  },
  {
    trans: false,
    val: 'TTL',
    field: 'TTL',
  },
  {
    trans: true,
    val: 'TTL',
    field: 'TTL_ALL',
  },
  {
    trans: false,
    val: 'TAC',
    field: 'TAC',
  },
  {
    trans: true,
    val: 'TAC',
    field: 'TAC_ALL',
  },
  {
    trans: false,
    val: 'TACD',
    field: 'TACD',
  },
  {
    trans: true,
    val: 'TACD',
    field: 'TACD_ALL',
  },
  {
    trans: false,
    val: 'ABST',
    field: 'ABST',
  },
  {
    trans: true,
    val: 'ABST',
    field: 'ABST_ALL',
  },
  {
    trans: false,
    val: 'CLMS',
    field: 'CLMS',
  },
  {
    trans: true,
    val: 'CLMS',
    field: 'CLMS_ALL',
  },
  {
    trans: false,
    val: 'DESC',
    field: 'DESC',
  },
  {
    trans: true,
    val: 'DESC',
    field: 'DESC_ALL',
  },
]

/**
 * 反转query的时候，只能通过field来转
 * no trans fields
 */
export const patentAttributeFields = ['TA', 'TTL', 'TAC', 'TACD', 'ABST', 'CLMS', 'DESC']
/**
 * trans fields
 */
export const patentAttributeTransFields: IPatentIndexField[] = ['TA_ALL', 'TTL_ALL', 'TAC_ALL', 'TACD_ALL', 'ABST_ALL', 'CLMS_ALL', 'DESC_ALL']

export type ICollapseOptionField = 'PBDT_YEARMONTHDAY' | 'PHS_LABEL_TYPE' | 'COUNTRY' | 'APD_YEARMONTHDAY' | 'score'
