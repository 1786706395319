import { defineComponent, ref, watchEffect } from '@vue/composition-api'
import { GLoading } from '../../ui/GLoading/GLoading'
/**
 * 内存加载图片利用缓存机制立刻渲染图片
 */
export const BLazyLoadImg = defineComponent({
  name: 'BLazyLoadImg',
  props: {
    src: {
      required: true,
      type: String,
    },
    imgClass: {
      type: Array,
    },
  },
  setup(props) {
    const isLoading = ref(true)
    watchEffect(() => {
      isLoading.value = true
      const img = new Image()
      img.src = props.src
      img.onload = () => (isLoading.value = false)
    })
    return {
      isLoading,
    }
  },
  render() {
    const loading = this.$slots.loading || (
      <div class="w-full h-full relative">
        <GLoading></GLoading>
      </div>
    )
    return <div>{this.isLoading ? loading : <img src={this.src} class={this.imgClass} onClick={() => this.$emit('click')} />}</div>
  },
})
