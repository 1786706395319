/* eslint-disable @typescript-eslint/no-explicit-any */
import { E_TIME_TRUST_CODE } from '@patsnap/synapse_common_interface'
import { getCountryAlpha2code, toThousands } from '@patsnap/synapse_common_utils'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { handleSpace } from '@pharmsnap/shared/utils/encrypt/encryptSpace'
import { IVueI18n } from 'vue-i18n'
import {
  BAtcItem,
  BDiseaseItem,
  BDrugItem,
  BDrugTypeTagItem,
  BEntityTag,
  BMechanismItem,
  BOrganizationItem,
  BPatentTechnologyItem,
  BPatentTypeItem,
  BPhaseTag,
  BTargetItem,
  GCountryFlag,
  GIcon,
  GLimited,
  GLink,
  GTooltip,
} from '../components'
import { BApprovalCountryItem } from '../components/business/BApprovalCountryItem/BApprovalCountryItem'
import { BApprovalCountryPopperListCard } from '../components/business/card/BApprovalCountryCard/BApprovalCountryPopperListCard'
import { BAtcPopperListCard } from '../components/business/card/BAtcCard/BAtcPopperListCard'
import { BDiseasePopperListCard } from '../components/business/card/BDiseaseCard/BDiseasePopperListCard'
import { BDrugPopperListCard } from '../components/business/card/BDrugCard/BDrugPopperListCard'
import { BDrugTypePopperListCard } from '../components/business/card/BDrugTypeCard/BDrugTypePopperListCard'
import { BMechanismPopperListCard } from '../components/business/card/BMechanismCard/BMechanismPopperListCard'
import { BOrganizationPopperListCard } from '../components/business/card/BOrganizationCard/BOrganizationPopperListCard'
import { BPatentTechnologyPopperListCard } from '../components/business/card/BPatentTechnology/BPatentTechnologyPopperListCard'
import { BPatentTypePopperListCard } from '../components/business/card/BPatentTypeCard/BPatentTypePopperListCard'
import { BTargetPopperListCard } from '../components/business/card/BTargetCard/BTargetPopperListCard'
import { EMPTY_PLACEHOLDER, TEXT_SPLIT_SYMBOL } from '../constants'
import { ElTag } from '../element-ui'
import { I18nLang } from '../i18n'
import {
  E_TIME_FORMAT,
  IBaseCountry,
  IBaseDictItem,
  IBaseDiseaseInfo,
  IBaseDrugInfo,
  IBaseLink,
  IBaseMechanismInfo,
  IBaseOrgInfo,
  IBaseTargetInfo,
  IDrugApprovalTimelineItem,
  ILang,
  IPatentType,
} from '../types'
import { encryptString, getDictItemName, getLangDegraded, getRedirectLinkStatic } from '../utils'
import { formatTimestamp } from '../utils/time'
export const COMMA = ',  '
export function renderLimited<T>(
  data: T[],
  options: {
    limitCount: number
    render: (item: T, index: number, items?: T[]) => JSX.Element | string
    gap?: { x: number; y: number; trigger?: number }
    inline?: boolean
  }
) {
  if (data.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>

  return (
    <GLimited
      items={data}
      limit={options.limitCount}
      mode="expand"
      gap={options.gap}
      inline={options.inline !== undefined ? options.inline : true}
      scopedSlots={{
        default: ({ item, index, items }: { item: T; index: number; items: T[] }) => options.render(item, index, items),
      }}
    ></GLimited>
  )
}

export function renderBlockLimited<T>(
  data: T[],
  options: { limitCount: number; render: (item: T) => JSX.Element | string; gap?: { x: number; y: number; trigger?: number } }
) {
  if (data.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>

  return (
    <GLimited
      items={data}
      inline={false}
      limit={options.limitCount}
      mode="expand"
      gap={options.gap}
      scopedSlots={{
        default: ({ item }: { item: T }) => options.render(item),
      }}
    ></GLimited>
  )
}

// 超出数量限制
export function renderLimited_customMore<T>(
  data: T[],
  options: { limitCount: number; render: (item: T) => JSX.Element | string; gap?: { x: number; y: number } },
  moreRender: JSX.Element | string
) {
  if (data.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  return (
    <GLimited
      items={data}
      limit={options.limitCount}
      mode="link"
      gap={options.gap}
      scopedSlots={{
        default: ({ item }: { item: T }) => options.render(item),
      }}
    >
      <template slot="more">{moreRender}</template>
    </GLimited>
  )
}

export function renderPopoverLimited<T>(
  data: T[],
  options: {
    appendToBody?: boolean
    placement?:
      | 'top'
      | 'top-start'
      | 'top-end'
      | 'bottom'
      | 'bottom-start'
      | 'bottom-end'
      | 'left'
      | 'left-start'
      | 'left-end'
      | 'right'
      | 'right-start'
      | 'right-end'
    limitCount: number
    render: (item: T, index: number, isRenderInPopover: boolean, items: T[]) => JSX.Element | string
    gap?: { x: number; y: number }
    truncate?: boolean
    inline?: boolean
    popoverInline?: boolean
    popoverStyle?: string
    popoverTrigger?: 'click' | 'hover'
    linkTip?: string
    link?: string
    mode?: 'expand' | 'popover' | 'link'
  }
) {
  if (data.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  const {
    truncate = true,
    inline = true,
    popoverInline = true,
    popoverStyle = '',
    popoverTrigger = 'click',
    linkTip,
    link,
    mode = 'popover',
  } = options

  return (
    <GLimited
      items={data}
      linkTip={linkTip}
      link={link}
      limit={options.limitCount}
      mode={mode}
      gap={options.gap}
      placement={options.placement || 'top-end'}
      appendToBody={typeof options.appendToBody === 'undefined' ? false : options.appendToBody}
      scopedSlots={{
        default: ({ item, index, isRenderInPopover, items }: { item: T; index: number; isRenderInPopover: boolean; items: T[] }) =>
          options.render(item, index, isRenderInPopover, items),
      }}
      truncate={truncate}
      inline={inline}
      popoverInline={popoverInline}
      popoverStyle={popoverStyle}
      popoverTrigger={popoverTrigger}
    ></GLimited>
  )
}

export function renderCountryApprovalDate(
  data: { country?: string; date?: number; indication?: IBaseDiseaseInfo[]; xCorrelationId?: string },
  locale: ILang
) {
  const showCountry = !!data.country
  const showDate = typeof data.date !== 'undefined'
  const formatDate = formatTimestamp(data.date ? { time_ts: data.date, time_trust_code: E_TIME_TRUST_CODE.YEAR_MONTH } : data.date, {
    locale: locale,
  })
  const vNodes = [
    showCountry && data.country ? (
      <GCountryFlag style="margin-right: 4px;" class={[$utilClasses.lsFlexShrink]} name={data.country} showName={true}></GCountryFlag>
    ) : null,
    showDate ? (
      <span class={[$utilClasses.lsFlexShrink, 'icon-font']} style="margin-right: 8px;">
        {handleSpace(`(${encryptString(formatDate)})`)}
      </span>
    ) : null,
  ]

  return vNodes.every((i) => i === null) ? (
    <span>{EMPTY_PLACEHOLDER}</span>
  ) : (
    <div class="flex items-start">
      <span class="inline-flex items-center">{vNodes}</span>
      <span class="flex-1">
        {!!data.indication?.length &&
          data.indication.map((item) => {
            return <BDiseaseItem class="mr-1 mb-1" data={item}></BDiseaseItem>
          })}
      </span>
    </div>
  )
}

export function renderCountryInListPage(params?: IBaseCountry[], limitCount = 4) {
  if (!params?.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderPopoverLimited(params, {
    limitCount,
    render: (item) => {
      const countryName = item.alpha_2_code || item.display_name
      return <GCountryFlag class="mr-1" name={countryName} showName={true}></GCountryFlag>
    },
    gap: {
      x: 4,
      y: 0,
    },
  })
}

export function renderPhase(
  data: { currentPhase?: IBaseDictItem; beforePhase?: IBaseDictItem; fontClassName?: string; approvedDate?: number },
  locale: ILang
) {
  const phase = data.currentPhase ? getDictItemName(data.currentPhase, locale) : ''
  const phaseBeforeTermination = data.beforePhase ? getDictItemName(data.beforePhase, locale) : ''
  if (!phase && !phaseBeforeTermination) return EMPTY_PLACEHOLDER

  const approvedDateJsx = data.approvedDate ? (
    <div class="mt-1">{formatTimestamp(data.approvedDate, { locale, format: E_TIME_FORMAT.DD_MM_YYYY })}</div>
  ) : null

  if (phase && !phaseBeforeTermination)
    return (
      <div>
        <BPhaseTag encryptClass={data.fontClassName} phase={phase}></BPhaseTag>
        {approvedDateJsx}
      </div>
    )
  return (
    <div class="flex items-center">
      <BPhaseTag encryptClass={data.fontClassName} phase={phase}></BPhaseTag>
      <span class={['ml-2 truncate', data.fontClassName]}>{handleSpace(`(${phaseBeforeTermination})`, data.fontClassName)}</span>
      {approvedDateJsx}
    </div>
  )
}
export function renderSinglePhase(data: IBaseDictItem | undefined, locale: ILang) {
  if (!data) {
    return EMPTY_PLACEHOLDER
  }
  return <BPhaseTag phase={getDictItemName(data, locale)} encryptClass={data.fontClassName}></BPhaseTag>
}

export function renderLimitedTagText(texts: string[], limitCount: number) {
  return renderLimited(texts, {
    limitCount,
    render: (item) => (
      <BEntityTag title={item} style="display: inline-block; font-size: 0.875rem;">
        {item}
      </BEntityTag>
    ),
  })
}

export function renderLimitedText(texts: string[], limitCount: number) {
  return renderLimited(texts, {
    limitCount,
    render: (item) => <span style="line-height: 24px; font-size: 14px; white-space: initial">{item}</span>,
    gap: { x: 16, y: 4, trigger: 4 },
  })
}

export function renderLimitedOverflowText(texts: string[], limitCount: number) {
  return renderLimited(texts, {
    limitCount,
    render: (item) => <span class=" inline-block leading-6 text-sm w-full  truncate">{item}</span>,
    gap: { x: 16, y: 4, trigger: 4 },
  })
}

export function renderLimitedSynonyms(texts: string[], limitCount: number) {
  return renderLimited(texts, {
    limitCount,
    render: (item, index) => (
      <span style="line-height: 24px; font-size: 14px;" class="whitespace-pre">
        {item}
        {index !== texts.length - 1 ? COMMA : ''}
      </span>
    ),
    gap: { x: 0, y: 4, trigger: 4 },
  })
}

export function renderPopoverLimitedText(texts: string[], limitCount: number, appendToBody = false) {
  return renderPopoverLimited(texts, {
    limitCount,
    render: (item, index, isRenderInPopover, items) => (
      <span class="text-sm leading-6" title={item}>
        {item}
        {items.length - 1 !== index && TEXT_SPLIT_SYMBOL}
      </span>
    ),
    appendToBody,
    gap: { x: 4, y: 4 },
    truncate: false,
  })
}

export function renderLimitedLinks(links: IBaseLink[], limitCount: number, defaultColor: 'blue' | 'dark', inline?: boolean) {
  return renderLimited(links, {
    limitCount,
    render: (item) => (
      <GLink style="font-size: 14px; line-height: 24px;" href={item.href} name={item.name} newTab={true} defaultColor={defaultColor}></GLink>
    ),
    gap: { x: 4, y: 4 },
    inline,
  })
}

export function renderLimitedOuterLinks(links: IBaseLink[], limitCount: number, defaultColor: 'blue' | 'dark', locale: I18nLang, inline?: boolean) {
  return renderLimited(links, {
    limitCount,
    render: (item, index) => (
      <GLink
        style="font-size: 14px; line-height: 24px;"
        href={getRedirectLinkStatic(item.href, locale)}
        name={`${item.name}${index !== links.length - 1 ? COMMA : ''}`}
        newTab={true}
        defaultColor={defaultColor}
      ></GLink>
    ),
    gap: { x: 4, y: 4 },
    inline,
  })
}
export function renderPopoverLimitedLinks(links: IBaseLink[], limitCount: number, defaultColor: 'blue' | 'dark') {
  return renderPopoverLimited(links, {
    limitCount,
    render: (item) => (
      <GLink style="font-size: 14px; line-height: 24px;" href={item.href} name={item.name} newTab={true} defaultColor={defaultColor}></GLink>
    ),
    gap: { x: 4, y: 4 },
  })
}

export function renderBlockLimitedLinks(links: { name: string; href: string }[], limitCount: number, defaultColor: 'blue' | 'dark') {
  return renderBlockLimited(links, {
    limitCount,
    render: (item) => (
      <GLink style="font-size: 14px; line-height: 24px;" href={item.href} name={item.name} newTab={true} defaultColor={defaultColor}></GLink>
    ),
    gap: { x: 4, y: 4 },
  })
}

export function renderLimitedTargets(targets: IBaseTargetInfo[], limitCount: number) {
  return renderLimited(targets, {
    limitCount,
    render: (item) => <BTargetItem data={item}></BTargetItem>,
  })
}
export function renderLimitedTargets_customMore(targets: IBaseTargetInfo[], limitCount: number, moreRender: JSX.Element) {
  return renderLimited_customMore(
    targets,
    {
      limitCount,
      render: (item) => <BTargetItem data={item} showCard></BTargetItem>,
    },
    moreRender
  )
}

export function renderPopoverLimitedTargets(targets: IBaseTargetInfo[], limitCount: number, popover = true) {
  return renderPopoverLimited(targets, {
    limitCount,
    render: (item) => <BTargetItem data={item} popover={popover}></BTargetItem>,
  })
}
export function renderPopoverLimitedTargetsAsync(
  data: IBaseTargetInfo[] = [],
  ids: string[] = [],
  limitCount = 1,
  showIcon = false,
  isDisplayInOneLine = false,
  popover = true,
  isNameNeedDegrade = false
) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BTargetPopperListCard,
    ItemCom: BTargetItem,
    limitCount,
    itemCompProps: { showIcon, popover, isNameNeedDegrade },
    isDisplayInOneLine,
  })
}

export function renderLimitedDisease(diseases: IBaseDiseaseInfo[], limitCount: number, options = { popover: true }) {
  return renderLimited(diseases, {
    limitCount,
    render: (item) => <BDiseaseItem data={item} popover={options.popover}></BDiseaseItem>,
  })
}

export function renderLimitedApprovalCountry(countries: IBaseCountry[], limitCount: number) {
  return renderLimited(countries, {
    limitCount,
    render: (item) => <BApprovalCountryItem data={item}></BApprovalCountryItem>,
  })
}

export function renderLimitedDisease_customMore(diseases: IBaseDiseaseInfo[], limitCount: number, moreRender: JSX.Element) {
  return renderLimited_customMore(
    diseases,
    {
      limitCount,
      render: (item) => <BDiseaseItem data={item} showCard></BDiseaseItem>,
    },
    moreRender
  )
}

export function renderPopoverLimitedDisease(diseases: IBaseDiseaseInfo[], limitCount: number, popover = true) {
  return renderPopoverLimited(diseases, {
    limitCount,
    render: (item) => <BDiseaseItem data={item} popover={popover}></BDiseaseItem>,
  })
}

export function renderPopoverLimitedDiseaseAsync(
  data: IBaseDiseaseInfo[] = [],
  ids: string[] = [],
  limitCount = 3,
  showIcon = false,
  drug_id?: string,
  isDisplayInOneLine?: boolean
) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BDiseasePopperListCard,
    ItemCom: BDiseaseItem,
    limitCount,
    itemCompProps: { showIcon, drug_id },
    isDisplayInOneLine,
  })
}

export function renderLimitedOrg(organizations: IBaseOrgInfo[], limitCount: number, popover = true, isSignature = true, isNameNeedDegrade = false) {
  return renderLimited(organizations, {
    limitCount,
    render: (item) => (
      <BOrganizationItem data={item} popover={popover} isSignature={isSignature} isNameNeedDegrade={isNameNeedDegrade}></BOrganizationItem>
    ),
  })
}

export function renderPopoverLimitedOrg(
  organizations: IBaseOrgInfo[],
  limitCount: number,
  popover = true,
  isSignature = true,
  appendToBody = false,
  placement = 'top-end',
  itemPlacement = 'bottom-start',
  isNameNeedDegrade = false
) {
  return renderPopoverLimited(organizations, {
    limitCount,
    appendToBody,
    placement: placement as any,
    render: (item) => (
      <BOrganizationItem
        data={item}
        popover={popover}
        isSignature={isSignature}
        placement={itemPlacement}
        isNameNeedDegrade={isNameNeedDegrade}
      ></BOrganizationItem>
    ),
  })
}

export function renderPopoverLimitedOrgAsync(
  data: IBaseOrgInfo[],
  ids: string[] = [],
  limitCount = 1,
  isDisplayInOneLine = false,
  isNameNeedDegrade = false
) {
  return renderAsyncListEntityCard({
    id_view: data,
    ids: ids,
    PopperListCardCom: BOrganizationPopperListCard,
    ItemCom: BOrganizationItem,
    itemCompProps: { isNameNeedDegrade },
    limitCount,
    isDisplayInOneLine,
  })
}

export function renderLimitedDrug(drugs: IBaseDrugInfo[], limitCount: number) {
  return renderLimited(drugs, {
    limitCount,
    render: (item) => <BDrugItem data={item} showCard></BDrugItem>,
  })
}
export function renderLimitedDrug_customMore(drugs: IBaseDrugInfo[], limitCount: number, moreRender: JSX.Element) {
  return renderLimited_customMore(
    drugs,
    {
      limitCount,
      render: (item) => <BDrugItem data={item} showCard></BDrugItem>,
    },
    moreRender
  )
}

export function renderPopoverLimitedDrug(drugs: IBaseDrugInfo[], limitCount: number, showCard = true) {
  return renderPopoverLimited(drugs, {
    limitCount,
    render: (item) => <BDrugItem data={item} showCard={showCard}></BDrugItem>,
  })
}

export function renderPopoverLimitedDrugAsync(
  data: IBaseDrugInfo[] = [],
  ids: string[] = [],
  limitCount = 1,
  showIcon = false,
  isDisplayInOneLine = false
) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids: ids,
    PopperListCardCom: BDrugPopperListCard,
    ItemCom: BDrugItem,
    limitCount,
    isDisplayInOneLine,
    itemCompProps: { showCard: true, showIcon },
  })
}

export function renderLimitedMechanism(mechanisms: IBaseMechanismInfo[], limitCount: number) {
  return renderLimited(mechanisms, {
    limitCount,
    render: (item) => <BMechanismItem data={item} truncate></BMechanismItem>,
    gap: { x: 8, y: 4 },
  })
}

export function renderPopoverLimitedMechanism(mechanisms: IBaseMechanismInfo[], limitCount: number) {
  return renderPopoverLimited(mechanisms, {
    limitCount,
    render: (item) => <BMechanismItem data={item} truncate></BMechanismItem>,
    gap: { x: 8, y: 4 },
  })
}

export function renderPopoverLimitedMechanismAsync(data: IBaseMechanismInfo[] = [], ids: string[] = [], limitCount = 1, isDisplayInOneLine = false) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids: ids,
    PopperListCardCom: BMechanismPopperListCard,
    ItemCom: BMechanismItem,
    limitCount,
    isShowBreak: true,
    isDisplayInOneLine,
    itemCompProps: {
      truncate: true,
    },
  })
}

export function renderPopoverLimitedAtc(data: IBaseLink[] = [], limitCount = 3) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderPopoverLimited(data, {
    appendToBody: true,
    placement: 'bottom-start',
    limitCount,
    render: (item) => <BAtcItem data={item} color="blue"></BAtcItem>,
    gap: { x: 16, y: 4 },
    inline: false,
    popoverInline: false,
  })
}

export function renderPopoverLimitedAtcAsync(data: IBaseLink[] = [], ids: string[] = [], limitCount = 3) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BAtcPopperListCard,
    ItemCom: BAtcItem,
    limitCount,
  })
}

export function renderApprovalCountryAsync(data: IBaseCountry[], ids: string[] = [], limitCount = 3) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BApprovalCountryPopperListCard,
    ItemCom: BApprovalCountryItem,
    limitCount,
    isDisplayBlock: true,
  })
}

export function renderDrugTypeAsync(data: IBaseDictItem[], ids: string[] = [], limitCount = 3) {
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BDrugTypePopperListCard,
    ItemCom: BDrugTypeTagItem,
    limitCount,
    isShowBreak: true,
  })
}

export function renderPatentTypeAsync(data: IPatentType[], ids: string[] = [], limitCount = 3) {
  // todo: 排序
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BPatentTypePopperListCard,
    ItemCom: BPatentTypeItem,
    limitCount,
  })
}

export function renderPatentTechnologyAsync(data: IPatentType[], ids: string[] = [], limitCount = 3) {
  // todo: 排序
  if (!data.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderAsyncListEntityCard({
    id_view: data,
    ids,
    PopperListCardCom: BPatentTechnologyPopperListCard,
    ItemCom: BPatentTechnologyItem,
    limitCount,
  })
}

/**
 * 渲染异步实体列表
 * @param params
 * @returns
 */
export function renderAsyncListEntityCard<T>(params: {
  /**
   * 在列表中已经显示的实体
   */
  id_view: T[]
  /**
   * 待批量查询的实体Id
   */
  ids: string[]
  /**
   * 实体弹窗组件
   */
  PopperListCardCom: any
  /**
   * 在列表中渲染的组件
   */
  ItemCom: any
  /**
   * 限制显示个数
   */
  limitCount?: number
  /**
   * 渲染组件的额外props
   */
  itemCompProps?: any
  /**
   * 是否在item中间添加 分隔符，默认不添加
   */
  isShowBreak?: boolean
  /**
   * 分隔符
   */
  breakContent?: string
  /**
   * 实体和+几在一行展示，只在limitCount=1时有效，默认false
   */
  isDisplayInOneLine?: boolean
  /**
   * 实体和+几各自都在一行上展示
   */
  isDisplayBlock?: boolean

  key?: string
}) {
  const {
    PopperListCardCom,
    ItemCom,
    ids = [],
    id_view = [],
    limitCount = 1,
    isDisplayInOneLine = false,
    isDisplayBlock = false,
    itemCompProps = {},
    isShowBreak = false,
    breakContent = TEXT_SPLIT_SYMBOL,
  } = params

  if (ids.length === 0 || id_view.length === 0) return EMPTY_PLACEHOLDER

  const needDisplayInOneLine = isDisplayInOneLine ? limitCount === 1 : isDisplayInOneLine

  const displayItems = id_view.slice(0, limitCount)

  const displayItemsLen = displayItems.length

  const actualItemsLen = ids.length

  const renderMore = () => {
    if (actualItemsLen <= limitCount) return null
    return (
      <PopperListCardCom class="flex-shrink-0" key={ids.join(',')} ids={ids}>
        <span data-testid="async-entity-list-card__trigger-more" class="cursor-pointer text-blue-default">
          [+{toThousands(actualItemsLen - limitCount)}]
        </span>
      </PopperListCardCom>
    )
  }

  const renderItem = (item: T, index: number, cls = '', block = false) => {
    const content = (
      <ItemCom
        {...{
          class: cls,
          props: {
            ...itemCompProps,
            data: item,
            breakContent: isShowBreak && index !== displayItemsLen - 1 ? breakContent : null,
          },
        }}
      ></ItemCom>
    )
    return block ? <div class={cls}>{content}</div> : content
  }

  const renderItemWrapper = (content: JSX.Element) => {
    return <div class="flex flex-wrap items-center max-w-full">{content}</div>
  }

  return (
    <div
      key={params.key}
      data-test-id="async-entity-list"
      class={[isDisplayBlock ? 'max-w-full' : 'flex items-center max-w-full', needDisplayInOneLine || isDisplayBlock ? '' : 'flex-wrap']}
    >
      {displayItems.map((item, index) => {
        if (needDisplayInOneLine) return renderItem(item, index, 'mr-1')
        if (isDisplayBlock) return renderItem(item, index, '', true)
        return renderItemWrapper(renderItem(item, index, 'mr-1 mb-1'))
      })}
      {renderMore()}
    </div>
  )
}

/**
 * 渲染实体带icon悬浮显示tooltip文案
 * @param params
 * @returns
 */
export function renderAsyncEntityWithToolTipListCard<T>(params: {
  /**
   * 在列表中已经显示的实体
   */
  id_view: T[]
  /**
   * 待批量查询的实体Id
   */
  ids: string[]
  /**
   * 实体弹窗组件
   */
  PopperListCardCom: any
  /**
   * 在列表中渲染的组件
   */
  ItemCom: any
  /**
   * 限制显示个数
   */
  limitCount?: number
  popperCompProps?: any
  /**
   * 渲染组件的额外props
   */
  itemCompProps?: any
  /**
   * 是否在item中间添加 分隔符，默认不添加
   */
  isShowBreak?: boolean
  // 分隔符
  breakContent?: string
  scopedSlots?: any
}) {
  const {
    PopperListCardCom,
    ItemCom,
    limitCount = 1,
    itemCompProps = {},
    isShowBreak = false,
    breakContent = TEXT_SPLIT_SYMBOL,
    scopedSlots = {},
    popperCompProps = {},
  } = params
  const length = params?.ids?.length
  if (!length) {
    return EMPTY_PLACEHOLDER
  }
  const more =
    length > limitCount ? (
      <PopperListCardCom class="flex-shrink-0" key={params.ids.join(',')} props={{ ...popperCompProps }}>
        <span class="cursor-pointer text-blue-default">[+{toThousands(length - limitCount)}]</span>
      </PopperListCardCom>
    ) : null
  const display = params.id_view.slice(0, limitCount)
  const displayLen = display.length
  return (
    <div class="flex flex-wrap items-center max-w-full">
      {display.map((item, index) => (
        <div class="flex flex-wrap items-center max-w-full">
          <ItemCom
            {...{
              class: 'mr-1 mb-1',
              props: {
                ...itemCompProps,
                data: item,
                breakContent: isShowBreak && index !== displayLen - 1 ? breakContent : null,
              },
            }}
            scopedSlots={scopedSlots}
          ></ItemCom>
        </div>
      ))}
      {more}
    </div>
  )
}

export function renderApprovalCountryInDrugDetail(params: IDrugApprovalTimelineItem[] = [], options: { locale: ILang; limitCount?: number }) {
  if (!params.length) {
    return EMPTY_PLACEHOLDER
  }

  return renderPopoverLimited(params, {
    limitCount: options.limitCount || 3,
    render: (item, index, isRenderInPopover) => {
      const { country_view } = item
      const countryCode = country_view.alpha_2_code || country_view.display_name
      const countryName = getLangDegraded(
        {
          name_cn: country_view.display_name_cn,
          name_en: country_view.display_name,
        },
        options.locale.toLowerCase() as I18nLang
      )

      if (isRenderInPopover) {
        return (
          <GCountryFlag
            name={countryCode}
            showName={true}
            useFullName
            showAlphaCode
            showUnknownCountryFlag
            style={{ width: '138px', marginRight: index % 2 === 0 ? '8px' : '0' }}
          ></GCountryFlag>
        )
      }
      return getCountryAlpha2code(countryCode) ? (
        <GCountryFlag style="max-width: 180px" name={countryCode} showName={true} useFullName showUnknownCountryFlag showAlphaCode></GCountryFlag>
      ) : (
        <GCountryFlag style="max-width: 180px" name="" showName={false} showUnknownCountryFlag></GCountryFlag>
      )
    },
    placement: 'right-end',
    appendToBody: true,
    gap: {
      x: 0,
      y: 0,
    },
    inline: false,
    popoverStyle: 'width: 300px; max-width: 300px; padding-right: 0',
    popoverTrigger: 'hover',
  })
}
export function renderPDFLink(url = '', $i18n: IVueI18n) {
  if (!url) return EMPTY_PLACEHOLDER
  return (
    <a class="inline-flex items-center border rounded px-1 hover:bg-gray-30" href={url} target="_blank">
      <GIcon size={20} svgName="SolidChain"></GIcon>
      <span>{$i18n.locale === 'cn' ? '原文链接' : 'Source'}</span>
    </a>
  )
}

export function renderPDFLinkInDetail(url = '', $i18n: IVueI18n, emptyPlaceholder = EMPTY_PLACEHOLDER) {
  if (!url) return emptyPlaceholder
  return (
    <a class="inline-flex items-center rounded px-1 py-0.5 hover:bg-gray-30" href={url} target="_blank">
      <GIcon size={24} svgName="SolidChain"></GIcon>
      <span class="ml-1 text-sm leading-6 text-black-default">{$i18n.locale === 'cn' ? '原文链接' : 'Source'}</span>
    </a>
  )
}

export function renderTag(text = '', options?: { size?: string; bgColor?: string; textColor?: string }) {
  if (text) {
    const { bgColor = '#DDEBF8', textColor = '#13599E', size = 'small' } = options || {}
    const style = {
      background: bgColor,
      color: textColor,
      borderColor: bgColor,
    }
    return (
      <ElTag size={size} disable-transitions={true} effect="dark" style={style}>
        {text}
      </ElTag>
    )
  }
  return null
}
export function filterTargetsBySearchString(items: IBaseTargetInfo[], searchContent: string): any[] {
  return items.filter((item) => {
    return _checkStringIsConsistOfStringArr(
      (item.short_name || item.target_name).map((item) => item.name),
      searchContent
    )
  })
}
/**
 * 判读一个字符创是否包含在一组数据中
 * @param searchStringArr 需要查找的数据集
 * @param searchContent 需要找的文本
 * @returns
 */
function _checkStringIsConsistOfStringArr(searchStringArr: string[], searchContent: string): boolean {
  for (const str of searchStringArr) {
    const reg = new RegExp(searchContent, 'i')
    if (str && str.search(reg) !== -1) {
      return true
    }
  }
  return false
}

export function renderUpgradeStar(params: { content?: string; callback?: () => void | Promise<void>; cls?: string }) {
  const { content, callback } = params
  return content ? (
    <GTooltip placement="top" theme="light" content={content}>
      <GIcon
        svgName="Upgrade"
        size={24}
        class={['cursor-pointer hover:bg-gray-40', params.cls]}
        style="color: #1854f1"
        {...(callback
          ? {
              nativeOn: {
                click: callback,
              },
            }
          : {})}
      ></GIcon>
    </GTooltip>
  ) : (
    <GIcon
      svgName="Upgrade"
      size={24}
      class={['cursor-pointer hover:bg-gray-40', params.cls]}
      style="color: #1854f1"
      {...(callback
        ? {
            nativeOn: {
              click: callback,
            },
          }
        : {})}
    ></GIcon>
  )
}
