import { I18nLang, IAutoCompleteType } from '@patsnap/synapse_common_interface'
import { IListItem } from '@pharmsnap/shared/types'
import { computed, getCurrentInstance, ref, Ref, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { flatten, uniq } from 'lodash'
import { IFilterAggValueNext, IFilterTreeValueNext } from '../type'
import { transUuidPathStr2PathIds, walkTreeLike } from '../utils'

type IUseFilterAutocompleteServiceOptions = {
  locale: MaybeRef<I18nLang>
}

type IUseFilterListAutocompleteServiceOptions = {
  locale: MaybeRef<I18nLang>
  list: MaybeRef<IFilterAggValueNext[]>
}

type IUseFilterTreeAutocompleteServiceOptions = {
  locale: MaybeRef<I18nLang>
  tree: MaybeRef<IFilterTreeValueNext[]>
  onAfterSelect: (value?: IFilterTreeValueNext) => void
  onAfterClear: () => void
  onCursor: (value?: IFilterTreeValueNext) => void
}

export function useFilterAutocompleteService(options: IUseFilterAutocompleteServiceOptions) {
  const { locale } = options

  const ins = getCurrentInstance()

  const keywords = ref('')

  const placeholder = computed(() => ins?.proxy.$tc('bFilterNext.autoPlaceholder') || '')

  return {
    keywords,
    placeholder,
  }
}

export function useFilterListAutocompleteService(options: IUseFilterListAutocompleteServiceOptions) {
  const { locale, list: originList } = options

  const selectedId = ref(null) as Ref<IFilterAggValueNext['uuid'] | null>

  const { keywords, placeholder } = useFilterAutocompleteService({
    locale,
  })

  const list = computed(() => (unref(selectedId) === null ? unref(originList) : unref(originList).filter((i) => i.uuid === unref(selectedId))))

  function onClear() {
    keywords.value = ''
    selectedId.value = null
  }

  function onSelect(type: IAutoCompleteType, item: IListItem) {
    selectedId.value = item.id
  }

  watch(keywords, () => {
    // 当前选中了autocomplete的内容，但是没有结果，并且手动清空了keywords，就清空选中的内容
    if (unref(keywords).length === 0 && unref(selectedId) !== null && unref(list).length === 0) {
      selectedId.value = null
    }
  })

  return {
    list,
    keywords,
    placeholder,
    onSelect,
    onClear,
    hasSelectFromAutocomplete: computed(() => unref(selectedId) !== null),
  }
}

export function useFilterTreeAutocompleteService(options: IUseFilterTreeAutocompleteServiceOptions) {
  const { locale, tree: originTree, onAfterSelect, onCursor, onAfterClear } = options

  const selectedId = ref(null) as Ref<IFilterAggValueNext['uuid'] | null>

  const matchedValues = ref([]) as Ref<IFilterTreeValueNext[]>

  const matchedPathIds = ref([]) as Ref<IFilterTreeValueNext['uuidPath'][]>

  const matchedIndex = ref(-1)

  const matchedActiveValue = computed(() => {
    if (unref(matchedIndex) === -1) return null
    // 判断下标是否越界，如果超过上限，就返回第一个，如果小于下限，就返回最后一个
    // 使用余数进行判断
    const index = unref(matchedIndex) % unref(matchedValues).length

    return unref(matchedValues)[index]
  })

  const displayMatchedIndex = computed(() => {
    if (unref(matchedIndex) === -1) return 0
    // 判断下标是否越界，如果超过上限，就返回第一个，如果小于下限，就返回最后一个
    // 使用余数进行判断
    const index = unref(matchedIndex) % unref(matchedValues).length

    return index + 1
  })

  const { keywords, placeholder } = useFilterAutocompleteService({
    locale,
  })

  function onClear() {
    keywords.value = ''
    selectedId.value = null
    matchedValues.value = []
    matchedPathIds.value = []
    matchedIndex.value = -1
    onAfterClear()
  }

  function onSelect(type: IAutoCompleteType, item: IListItem) {
    selectedId.value = item.id
    updateMatchedData(item.id)
    const activeValue = unref(matchedActiveValue)
    activeValue && onAfterSelect(activeValue)
  }

  function onSelectPre() {
    // matchedIndex.value--
    matchedIndex.value = matchedIndex.value - 1
    const activeValue = unref(matchedActiveValue)
    activeValue && onCursor(activeValue)
  }

  function onSelectNext() {
    // matchedIndex.value++
    matchedIndex.value = matchedIndex.value + 1
    const activeValue = unref(matchedActiveValue)
    activeValue && onCursor(activeValue)
  }

  function updateMatchedData(id: string) {
    // 遍历树，找到符合条件的节点
    // 如果找到了，就将这个节点的所有父节点都展开
    // 如果没有找到，不做处理

    const tree = unref(originTree)

    const matched: IFilterTreeValueNext[] = []

    walkTreeLike(tree, (item) => {
      if (item.value.uuid === id || item.raw?.id === id) {
        matched.push(item)
      }
    })

    if (matched.length) {
      matchedValues.value = matched
      matchedIndex.value = 0
      matchedPathIds.value = uniq(flatten(matched.map((i) => transUuidPathStr2PathIds(i.uuidPath))))
    }
  }

  watch(keywords, () => {
    if (unref(keywords).length === 0 && unref(selectedId) !== null && unref(matchedValues).length === 0) {
      selectedId.value = null
    }
  })

  return {
    hasSelectFromAutocomplete: computed(() => unref(selectedId) !== null),
    matchedPathIds,
    matchedActiveValue,
    matchedValues,
    displayMatchedIndex,
    keywords,
    placeholder,
    onClear,
    onSelect,
    onSelectPre,
    onSelectNext,
  }
}
