import { ALL_SUBJECT_CONFIG_MAP } from '@patsnap/synapse_common_config'
import { IAggregationValue } from '@patsnap/synapse_common_interface'
import { getLangDegraded, toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { ElPopover } from '@pharmsnap/shared/src/element-ui'
import { sortSubject } from '@pharmsnap/shared/src/utils'
import { adjustWeights } from '@pharmsnap/shared/src/utils/business/bulls-eye-chart'
import { PropType, computed, defineComponent, onBeforeMount, onMounted, ref, shallowRef, watch } from '@vue/composition-api'
import * as d3 from 'd3'
import { debounce } from 'lodash'
import tippy, { Instance } from 'tippy.js'

const PIE_OUTER_RADIUS_RATIO = 0.8
const PIE_INNER_RADIUS_RATIO = 0.55

type IPieDoughnutItem = IAggregationValue & {
  adjustCount: number // 调整后的count，保证最小角度
}
export const BAnalysisPieDoughnut = defineComponent({
  name: 'BAnalysisPieDoughnut',
  props: {
    data: {
      type: Object as PropType<IAggregationValue>,
    },
    firstDimName: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 60,
    },
    supportWaterMark: {
      type: Boolean,
      default: false,
    },
    observerResize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()

    const pieDoughtChart = ref<HTMLDivElement>()

    const hoverActiveData = ref<IAggregationValue>()

    let d3Container: d3.Selection<SVGGElement, unknown, null, undefined>

    const popperIns = shallowRef<Instance | null>(null)

    const tooltipRef = shallowRef<HTMLDivElement | null>(null)

    const secondDimName = computed(() =>
      getLangDegraded({ name_cn: props.data?.display_name_cn, name_en: props.data?.display_name_en }, locale.value)
    )

    const pieDoughnutData = computed<IPieDoughnutItem[]>(() => {
      const items = [...(props.data?.aggregation_result?.[0]?.items || [])].sort((a, b) => sortSubject(a.key, b.key))
      const adjustCountList = adjustWeights(
        items.map((item) => item.count),
        20,
        0
      )

      return items.map((item, index) => ({
        ...item,
        aggregation_field: props.data?.aggregation_result?.[0].aggregation_field,
        adjustCount: adjustCountList[index],
      }))
    })

    const pieDoughnutSize = computed(() => Math.min(props.width, props.height) / 2)

    const pieDoughnutOuterRadius = computed(() => pieDoughnutSize.value * PIE_OUTER_RADIUS_RATIO)
    const pieDoughnutInnerRadius = computed(() => pieDoughnutSize.value * PIE_INNER_RADIUS_RATIO)

    function destroyTooltipIns() {
      popperIns.value?.destroy()
      popperIns.value = null
    }

    function createTooltipInstance(node: Element) {
      // destroy previous instance
      if (popperIns.value) {
        destroyTooltipIns()
      }
      return tippy(node, {
        placement: 'right-start',
        interactive: true,
        trigger: 'hover',
        appendTo: document.body,
        arrow: false,
        allowHTML: true,
        offset: [10, 10],
        zIndex: 10,
        onShow: (ins) => {
          if (tooltipRef.value) {
            ins.setContent(tooltipRef.value)
            return
          }
          return false
        },
      })
    }

    function handleMouseOver(data: IAggregationValue, node: SVGPathElement | null) {
      hoverActiveData.value = data

      if (node) {
        popperIns.value = createTooltipInstance(node)
        popperIns.value.show()
      }
    }

    const debounceHandleMouseOver = debounce(handleMouseOver, 200)

    function handleMouseOut() {
      debounceHandleMouseOver.cancel()
      hoverActiveData.value = undefined
      destroyTooltipIns()
    }

    function initD3Container() {
      if (pieDoughtChart.value) {
        // 创建 SVG 元素
        d3Container = d3
          .select(pieDoughtChart.value)
          .append('svg')
          .attr('width', props.width)
          .attr('height', props.height)
          .append('g')
          .attr('transform', `translate(${props.width / 2},${props.height / 2})`)
      }
    }

    function drawPieDoughnutChart() {
      if (d3Container) {
        // 创建一个饼图生成器
        const pie = d3
          .pie<IPieDoughnutItem>()
          .padAngle(0.03)
          .value((d) => d.adjustCount)
          .sort(null)

        // 创建一个弧生成器
        const arc = d3
          .arc<d3.PieArcDatum<IPieDoughnutItem>>()
          .innerRadius(pieDoughnutInnerRadius.value) // 内半径
          .outerRadius(pieDoughnutOuterRadius.value) // 外半径

        // 定义悬停时的弧生成器
        const arcHover = d3.arc<d3.PieArcDatum<IPieDoughnutItem>>().innerRadius(pieDoughnutInnerRadius.value).outerRadius(pieDoughnutSize.value) // 悬停时增加外半径

        // 绘制圆环
        const arcs = d3Container.append('g').selectAll('arc').data(pie(pieDoughnutData.value)).enter().append('g')

        arcs
          .append('path')
          .attr('d', arc)
          .attr('fill', (d) => {
            return ALL_SUBJECT_CONFIG_MAP[d.data.key]?.color || '#1976D2'
          }) // 颜色
          .style('cursor', 'pointer')
          .on('mouseover', function (_event, d) {
            d3.select(this).transition().duration(200).attr('d', arcHover(d))
            debounceHandleMouseOver(d.data, d3.select(this).node())
          })
          .on('mouseout', function (_event, d) {
            d3.select(this).transition().duration(200).attr('d', arc(d))
            handleMouseOut()
          })
          .on('mousedown', function (_event, d) {
            handleClickItem(d.data)
          })
      }
    }

    function handleClickItem(data: IAggregationValue) {
      emit('clickItem', data)
    }

    function handleClickTotal() {
      emit('clickTotal', props.data)
    }

    onMounted(() => {
      initD3Container()
      drawPieDoughnutChart()
    })

    watch(
      () => props.data,
      () => {
        d3Container?.selectAll('*').remove()
        drawPieDoughnutChart()
      }
    )

    onBeforeMount(() => {
      hoverActiveData.value = undefined
      destroyTooltipIns()
    })

    return {
      secondDimName,
      pieDoughnutData,
      locale,
      pieDoughtChart,
      pieDoughnutOuterRadius,
      pieDoughnutInnerRadius,
      tooltipRef,
      hoverActiveData,
      handleClickItem,
      handleClickTotal,
    }
  },
  methods: {
    renderTooltipContent(title: string, items: Array<{ color?: string; name?: string; count?: string }>) {
      return (
        <div class="max-w-60">
          <div class="text-xs leading-4 font-semibold text-text-t1 w-full truncate">{title}</div>
          <div class="mt-1">
            {items.map((item) => (
              <div class="flex items-center text-xs leading-4 text-text-t1">
                <span class="flex-shrink-0 inline-block h-[10px] w-[10px] rounded-[1px] mr-2" style={{ backgroundColor: item.color }}></span>
                <span class="truncate mr-1">{item.name}:</span>
                <span>{toThousands(item.count || 0)}</span>
              </div>
            ))}
          </div>
        </div>
      )
    },
    renderItemTooltip() {
      return (
        <div ref="tooltipRef">
          {!!this.hoverActiveData && (
            <div
              class="p-3"
              style={{
                'border-radius': '4px',
                'box-shadow': '0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 3px 8px 0px rgba(0, 0, 0, 0.14)',
                background: '#fff',
              }}
            >
              {this.renderTooltipContent([this.firstDimName, this.secondDimName].join(' - '), [
                {
                  color: ALL_SUBJECT_CONFIG_MAP[this.hoverActiveData.key]?.color || '#1976D2',
                  name: getLangDegraded(
                    { name_cn: this.hoverActiveData.display_name_cn, name_en: this.hoverActiveData.display_name_en },
                    this.locale
                  ),
                  count: toThousands(this.hoverActiveData.count),
                },
              ])}
            </div>
          )}
        </div>
      )
    },
    renderTotalTooltip() {
      return this.renderTooltipContent(
        [this.firstDimName, this.secondDimName].join(' - '),
        this.pieDoughnutData.map((item) => ({
          color: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2',
          name: getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, this.locale),
          count: toThousands(item.count),
        }))
      )
    },
    renderTotal() {
      if (this.data?.count) {
        return (
          <ElPopover trigger="hover" open-delay={300} close-delay={0}>
            {this.renderTotalTooltip()}
            <div
              slot="reference"
              class="absolute cursor-pointer top-[50%] left-[50%] text-sm whitespace-nowrap font-semibold"
              style="transform: translate(-50%, -50%)"
              onClick={this.handleClickTotal}
            >
              {toThousands(this.data?.count as number)}
            </div>
          </ElPopover>
        )
      }
    },
  },
  render() {
    if (this.data) {
      return (
        <div
          class="relative"
          style={{
            width: `${this.width}px`,
            height: `${this.height}px`,
          }}
        >
          <div ref="pieDoughtChart" class="w-full h-full"></div>
          <div class="hidden">{this.renderItemTooltip()}</div>

          {this.renderTotal()}
        </div>
      )
    }
    return (
      <div
        class="flex items-center justify-center"
        style={{
          width: `${this.width}px`,
          height: `${this.height}px`,
        }}
      >
        <div
          style={{
            width: `${this.width * PIE_OUTER_RADIUS_RATIO}px`,
            height: `${this.height * PIE_OUTER_RADIUS_RATIO}px`,
            borderWidth: `${this.pieDoughnutOuterRadius - this.pieDoughnutInnerRadius}px`,
          }}
          class="rounded-[50%] border-solid border-[#DFE1E6]"
        ></div>
      </div>
    )
  },
})
