import {
  AggBarChartConfig,
  AggBarChartEntity,
  AggLineChartConfig,
  AggLineChartEntity,
  AggOneDimChartBaseEntity,
  AggOneDimDataItem,
  AggPieChartConfig,
  AggPieChartEntity,
  AggWorldChartConfig,
  AggWorldChartEntity,
  getChartColor,
} from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { computed, markRaw, shallowRef, unref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { IUseAnalysisOneDimChartEntityOptions } from '../type'

export function useAnalysisOneDimChartEntity(options: IUseAnalysisOneDimChartEntityOptions) {
  const { data, chartType, getChartConfig, categoryFieldName, countFieldName } = options

  const { locale } = useLocale()

  const firstDimAggField = computed(() => unref(data).aggregation_result?.[0]?.aggregation_field)

  const chartEntity = shallowRef<AggOneDimChartBaseEntity>(markRaw(getChartEntity()))

  const dataList = shallowRef<AggOneDimDataItem[]>(markRaw(chartEntity.value.oneDimDataList))

  function getChartEntity() {
    const type = unref(chartType)
    const aggData = cloneDeep(unref(data).aggregation_result[0])
    const partialConfig = getChartConfig?.(type, { aggField: unref(firstDimAggField), aggItems: aggData.items || [] }) || {}

    if (type === 'pie') {
      const config: AggPieChartConfig = {
        locale: unref(locale),
        seriesName: unref(categoryFieldName),
        color: getChartColor(),
        ...(partialConfig as Partial<AggPieChartConfig>),
      }
      return new AggPieChartEntity(config, aggData)
    } else if (type === 'world') {
      const config: AggWorldChartConfig = {
        locale: unref(locale),
        seriesName: unref(countFieldName),
        ...(partialConfig as Partial<AggWorldChartConfig>),
      }
      return new AggWorldChartEntity(config, aggData)
    } else if (type === 'bar') {
      const config: AggBarChartConfig = {
        locale: unref(locale),
        seriesName: unref(countFieldName),
        xAxisName: unref(categoryFieldName),
        yAxisName: unref(countFieldName),
        ...(partialConfig as Partial<AggBarChartConfig>),
      }
      return new AggBarChartEntity(config, aggData)
    } else {
      const config: AggLineChartConfig = {
        locale: unref(locale),
        seriesName: unref(countFieldName),
        xAxisName: unref(categoryFieldName),
        yAxisName: unref(countFieldName),
        ...(partialConfig as Partial<AggLineChartConfig>),
      }
      return new AggLineChartEntity(config, aggData)
    }
  }

  watch(
    () => unref(data),
    () => {
      const entity = getChartEntity()
      dataList.value = markRaw(entity.oneDimDataList)
      chartEntity.value = markRaw(entity)
    }
  )

  return {
    chartEntity,
    dataList,
    countFieldName,
    categoryFieldName,
  }
}
