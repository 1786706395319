import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { defineComponent } from '@vue/composition-api'
import { LS360SideBarDialog } from '../LS360/LS360SideBarDialog'
export const CopilotDialogWithAuth = defineComponent({
  name: 'CopilotDialogWithAuth',
  props: {
    isAbsolutePosition: {
      default: false,
      type: Boolean,
    },
    integratedMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      getters: { isSupportCopilot },
    } = useAuthStore()
    return { isSupportCopilot }
  },
  render() {
    if (this.isSupportCopilot) {
      return <LS360SideBarDialog {...{ props: this.$props }} />
      // return <CopilotDialog {...{ props: this.$props }}></CopilotDialog>
    }
    return <div></div>
  },
})
