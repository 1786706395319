import { defineComponent } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import './BPhaseTag.scss'
import { handleSpace } from '@pharmsnap/shared/utils/encrypt/encryptSpace'

export const BPhaseTag = defineComponent({
  name: 'BPhaseTag',
  props: {
    phase: {
      type: String,
      required: true,
    },
    encryptClass: {
      type: String,
      default: '',
    },
  },
  render() {
    return (
      <ElTag class="b-phase-tag" size="small" disable-transitions={true} effect="plain">
        {this.encryptClass ? handleSpace(this.phase, this.encryptClass) : this.phase}
      </ElTag>
    )
  },
})
