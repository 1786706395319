import { E_SOCIAL_MEDIA_TYPE } from '../types'
type I_REGION = 'cn' | 'us'
export const socialMedialIconMap: Record<E_SOCIAL_MEDIA_TYPE, string> = {
  [E_SOCIAL_MEDIA_TYPE.FACEBOOK]: 'Facebook',
  [E_SOCIAL_MEDIA_TYPE.TWITTER]: 'Twitter',
  [E_SOCIAL_MEDIA_TYPE.LINKEDIN]: 'Linkedin',
  [E_SOCIAL_MEDIA_TYPE.HOMEPAGE]: 'Feed',
  [E_SOCIAL_MEDIA_TYPE.WIKIPEDIA]: 'Feed',
}
export const VUE_APP_DEPLOY_REGION = (localStorage.getItem('VUE_APP_DEPLOY_REGION') as I_REGION) || (process.env.VUE_APP_DEPLOY_REGION as I_REGION)
/**
 * 是否是部署在中国
 */
export const IS_CN_REGION = VUE_APP_DEPLOY_REGION === 'cn'
