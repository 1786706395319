import { BNewsAnnotationTag } from '@pharmsnap/shared/components'
import { renderNewsPublishDate } from '@pharmsnap/shared/render'
import { INewsDetail } from '@pharmsnap/shared/src/types'
import { encryptNum } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { BLazyImg } from '../../BLazyImg/BLazyImg'

export const BColumnNewsCard = defineComponent({
  name: 'BColumnNewsCard',
  props: {
    news: {
      required: true,
      type: Object as PropType<INewsDetail>,
    },
    detailUrl: {
      type: String,
    },
  },
  methods: {
    renderItemCoverImg() {
      return this.news.images?.map((image) => {
        if (image.type === 'cover_image')
          return (
            <div onClick={this.redirectToDetail}>
              <BLazyImg
                class="w-40 ml-12 cursor-pointer"
                style="height: 90px"
                imgClass="w-full h-full rounded"
                imgStyle="object-fit: cover"
                src={image.s3_path as string}
                signType="discovery_attachment"
              ></BLazyImg>
            </div>
          )
      })
    },
    redirectToDetail() {
      const href = this.$router.resolve(this.detailUrl as string).href
      window.open(href, '_blank')
    },
  },
  render() {
    return (
      <div class="pt-6 pb-4 flex gap-x-12 group border-gray-40 border-b">
        <div class="flex-1">
          <div class="text-xl font-semibold mb-2 group-hover:text-blue-default hover:underline cursor-pointer" onClick={this.redirectToDetail}>
            {this.news.title}
          </div>

          <div class="text-sm line-clamp-2 mb-2 cursor-pointer" onClick={this.redirectToDetail}>
            {this.news.content}
          </div>
          <div class="flex align-middle items-baseline">
            <div
              class="text-sm icon-font flex items-center flex-shrink-0"
              domPropsInnerHTML={encryptNum(renderNewsPublishDate(this.news, this.$i18n))}
            ></div>
            {!!this.news.annotation_tag?.length && <span class="mx-1">·</span>}
            <div>
              {this.news.annotation_tag?.map((item) => {
                return <BNewsAnnotationTag class="mr-1 mb-1" data={item}></BNewsAnnotationTag>
              })}
            </div>
          </div>
        </div>
        {this.renderItemCoverImg()}
      </div>
    )
  },
})
