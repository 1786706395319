/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GEETEST_TYPE,
  GOOGLE_TYPE,
  IGeetestResponseData,
  IGoogleReCaptchaResponseData,
  ReCaptchaVerification,
} from '@patsnap-utils/captcha-verification'
import { IS_CN_REGION } from '@pharmsnap/shared/config/base'
import { BACK_OFFICE_GEETEST_CAPTCHA_ID, BACK_OFFICE_GOOGLE_CAPTCHA_ID, PHARMSNAP_GEETEST_CAPTCHA_ID } from '@pharmsnap/shared/constants/geetest'
import Raven from 'raven-js'
export type IReCaptchaResponse = IGoogleReCaptchaResponseData | IGeetestResponseData
export interface IUnionCaptchaVerificationParams {
  /**
   * 传此参数代表需要传递BO的极验
   */
  source?: 'BO'
  /**
   * 语言
   */
  lang: 'cn' | 'en' | 'tw' | 'jp'
  /**
   * 失败回调
   */
  onFail?: (params: any) => void
  /**
   * 成功回调
   */
  onSuccess: (params: IReCaptchaResponse) => any
}

/**
 * 滑块验证码校验
 * 中国用极验,美国用google
 * 文档: https://design.zhihuiya.com/storybook/next/?path=/story/utils-captcha-verification-readme--page
 * @param params
 */
export async function unionCaptchaVerification(params: IUnionCaptchaVerificationParams) {
  const id = IS_CN_REGION ? (params.source === 'BO' ? BACK_OFFICE_GEETEST_CAPTCHA_ID : PHARMSNAP_GEETEST_CAPTCHA_ID) : BACK_OFFICE_GOOGLE_CAPTCHA_ID
  const captchaVerification = new ReCaptchaVerification({
    type: IS_CN_REGION ? GEETEST_TYPE : GOOGLE_TYPE,
    id,
    lang: params.lang,
    obj: { outside: false },
    onFail: params.onFail,
  })
  try {
    const rt = await captchaVerification.captchaValidate()
    params.onSuccess(rt as IReCaptchaResponse)
  } catch (e) {
    const typeError = e as unknown as { type?: 'error' | 'close'; data?: any }
    if (typeError?.type === 'error') {
      Raven.captureMessage(`[log]:captcha verification error`, {
        extra: {
          error: typeError?.data,
        },
      })
    }
    params.onFail && params.onFail(e)
  }
}
