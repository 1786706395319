/* eslint-disable @typescript-eslint/no-unused-vars */
import '@patsnap-ui/icon/assets/solid/MoreHorizontal.svg'
import {
  getClinicalResultOutMeasureName,
  getClinicalResultOutMeasureValue,
  getClinicalResultSource,
  getInterventionList,
  getOrderedOutcomeMeasures,
} from '@patsnap/synapse_common_business'
import { renderIntervention } from '@patsnap/synapse_common_components'
import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import {
  I18nLang,
  IBaseCharacteristic,
  IBaseClinicalResult,
  IClinicalResultGroup,
  IClinicalResultOutcomeMeasure,
} from '@patsnap/synapse_common_interface'
import { formatTimestamp, getSpecialLang } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { getDesignGroupType } from '@pharmsnap/pharmsnap-web/views/clinical-result/utils/common'
import {
  MANUAL_DICT_ID,
  MULTI_VALUE_SEPARATOR,
  MULTI_VALUE_SEPARATOR_COMMA,
  OUTCOME_MEASURE_TYPE,
} from '@pharmsnap/pharmsnap-web/views/clinical-result/utils/constant'
import { openNewTab } from '@pharmsnap/shared/src/utils'
import { ITranslateLang } from '@pharmsnap/shared/types'
import { PropType, computed, defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import { assign, concat, filter, find, get, includes, isEmpty, join, map, slice, some, toNumber, trimEnd } from 'lodash'
import { BCardContainer, BEntityLoadingCard, BMosaic, BUpgradeWrap, GCollapsibleTree, GIcon, GLink, GTooltip } from 'pharmsnapMF_shared/components'
import { useAuthStore, useLocale } from 'pharmsnapMF_shared/composition'
import { sharedCtx } from 'pharmsnapMF_shared/context'
import { ElCheckbox, ElDescriptions, ElDescriptionsItem, ElDivider, ElTable, ElTableColumn, ElTag } from 'pharmsnapMF_shared/element-ui'
import {
  renderClinicalResultConditionsAsync,
  renderClinicalResultDiseaseAndTarget,
  renderClinicalResultManualOrAI,
  renderClinicalResultOrgs,
  renderClinicalResultPhase,
  renderClinicalResultRegisterNumber,
  renderClinicalResultRelatedId,
  renderClinicalResultSource,
  renderClinicalTherapyType,
  renderGeneralEvaluation,
} from 'pharmsnapMF_shared/render'
import { getLangDegraded } from 'pharmsnapMF_shared/utils'
import styles from './BCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

interface IChartNode {
  id?: string
  title?: string
  content?: string
  groupType?: string
  type?: string
  isFirst?: boolean
  data?: IChartNode[]
  characteristic?: IBaseCharacteristic[]
  extraCharacteristic?: IBaseCharacteristic[]
}

export const BCardClinicalResult = defineComponent({
  name: 'BCardClinicalResult',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IBaseClinicalResult>,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props) {
    const ins = getCurrentInstance()
    const { localeUpcase, locale } = useLocale()
    const groupRef = ref<HTMLElement>()

    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const annotationId = computed(() => props.data.annotation_method_view?.dict_id || '')
    const isManualAnnotation = computed(() => MANUAL_DICT_ID.includes(annotationId.value))

    const studyNumberList = (props.data.related_id || []).filter((item) => item.id_type === 'study_identifier').map((item) => item.id_value)

    const source = computed(() => getClinicalResultSource(props.data.source_info)[0]?.source)
    const sourceName = computed(() => {
      return join(
        map(
          getClinicalResultSource(props.data.source_info || []),
          (item) => getLangDegraded(item.normalized_source_id_view || {}, locale.value) || item.source
        ),
        MULTI_VALUE_SEPARATOR
      )
    })

    const intervention = computed(() =>
      getInterventionList(props.data.groups || [], {
        limit: 3,
        source: source.value,
      })
    )

    const isIntelligentMode = computed(() => props.mode === 'intelligent')

    const shownIntervention = computed(() => slice(intervention.value, 0, 2))

    // 获取需要显示的前三个指标结果, 并保证是有指标数据的
    const outcome_measures = computed(() => {
      const group_ids = map(shownIntervention.value, 'group_id')
      const outcomes = filter(props.data.outcome_measures, (item = {} as IClinicalResultOutcomeMeasure) => {
        const { result_list = [], descriptive_result = '', analysis_list = [] } = item

        if (some(result_list, (result) => includes(group_ids, result.group_id)) || descriptive_result) {
          return true
        }

        if (analysis_list.length) {
          return find(analysis_list, (analysis) => analysis.group_id.join('') === group_ids.join(''))
        }

        return false
      }) as IClinicalResultOutcomeMeasure[]

      return slice(getOrderedOutcomeMeasures(outcomes), 0, 3)
    })

    // 合并单元格
    const mergeRows = computed(() =>
      map(outcome_measures.value, (outcome) => {
        return !outcome.result_list && (outcome.descriptive_result || outcome.analysis_list)
      })
    )

    const groupNodes = computed<IChartNode[]>(() => {
      const groupData = shownIntervention.value.map((item) => {
        const title = `${ins?.proxy.$tc('BCard.group')} ${item.design_group_order} ${
          toNumber(item.participant_number) ? `(N=${item.participant_number})` : ''
        }`
        return {
          id: item.group_id,
          name: 'group',
          title,
          groupType: getDesignGroupType(item, localeUpcase.value),
          characteristic: item.characteristic,
          extraCharacteristic: item.extraCharacteristic,
        }
      })

      return [
        {
          name: 'group',
          data: groupData,
          children: [
            outcome_measures.value?.length
              ? {
                  type: 'table',
                  contentStyle: { width: '100%' },
                  style: { height: '100%' },
                  data: outcome_measures.value,
                }
              : {
                  type: 'conclusion',
                  style: { height: '100%' },
                  contentStyle: { width: '100%' },
                  data: props.data.conclusion,
                },
          ],
        },
      ]
    })

    const biomarker = computed(() => {
      const { biomarker_id_view = [], biomarker = [] } = props.data
      const validBioMakerIdView = filter(biomarker_id_view, 'biomarker_id')

      return validBioMakerIdView.length
        ? join(
            validBioMakerIdView.map((item, index) => {
              return `${
                getSpecialLang({
                  data: item.short_name || [],
                  field: 'name',
                  lang: localeUpcase.value,
                }) ||
                getSpecialLang({
                  data: item.biomarker_name,
                  field: 'name',
                  lang: localeUpcase.value,
                })
              } ${index === biomarker_id_view.length - 1 ? '' : MULTI_VALUE_SEPARATOR_COMMA}`
            }),
            ''
          )
        : biomarker.map((item) => item.name).join(MULTI_VALUE_SEPARATOR_COMMA)
    })

    const chartNodes = computed(() => {
      return {
        id: 'root',
        multiple: get(groupNodes.value, '[0].data', []).length > 1,
        data: [
          {
            title: `${ins?.proxy.$tc('BCard.enrollment')}${props.data.overall_enrollment ? ` (N=${props.data.overall_enrollment})` : ''}`,
          },
        ],
        children: concat(groupNodes.value, intervention.value.length > 2 ? [{ id: 'more' }] : []),
      }
    })

    return {
      groupRef,
      isManualAnnotation,
      studyNumberList,
      localeUpcase,
      groupNodes,
      chartNodes,
      source,
      sourceName,
      biomarker,
      outcome_measures,
      mergeRows,
      intervention,
      shownIntervention,
      isFreeUser,
      changeUpgrade,
      isIntelligentMode,
    }
  },
  methods: {
    renderCheckbox() {
      return (
        <div class="mr-2.5">
          <ElCheckbox value={this.isChecked} onChange={() => this.$emit('checkboxChange', { checked: !this.isChecked, row: this.data })} />
        </div>
      )
    },
    renderHeader() {
      return (
        <div class={['flex items-center text-text-t2 text-sm flex-wrap', this.isIntelligentMode ? '' : 'px-4 pt-3']} slot="header">
          {this.showCheckbox && this.renderCheckbox()}
          {renderClinicalResultRelatedId(this.data)}
          {renderClinicalResultDiseaseAndTarget(this.data, this.localeUpcase)}
          {renderClinicalResultPhase(this.data, this.localeUpcase)}
          {renderClinicalTherapyType(this.data, this.$i18n)}
          {renderClinicalResultConditionsAsync(this.data)}
          {renderGeneralEvaluation(this.data, this.localeUpcase)}
          {renderClinicalResultManualOrAI(this.data, this.localeUpcase)}
        </div>
      )
    },
    renderBody() {
      const { official_title, brief_title } = this.data
      return (
        <div class="mt-2">
          <BUpgradeWrap updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} triggerPoint="CLINICAL_RESULT_TITLE">
            <GLink
              newTab
              href={sharedCtx.router.generatorClinicalResultDetailPath(this.data.ct_result_id)}
              name={official_title || brief_title || EMPTY_PLACEHOLDER}
              class="whitespace-pre-wrap hover:text-blue-default line-clamp-2 text-base font-semibold leading-6 mb-2"
            />
          </BUpgradeWrap>
          <ElDescriptions column={2} size="medium" labelClassName="text-text-t2 leading-6 flex-shrink-0" contentClassName="text-text-t1">
            {map(
              [
                {
                  label: this.$tc('BCard.publishedDate'),
                  render: () => (
                    <span class="text-text-default">
                      {formatTimestamp(this.data.published_time, {
                        locale: this.localeUpcase,
                      })}
                    </span>
                  ),
                },
                {
                  label: this.$tc('BCard.source'),
                  field: 'source',
                  render: () => {
                    return renderClinicalResultSource(this.data)
                  },
                },
                {
                  label: this.$tc('BCard.sponsor'),
                  render: () => renderClinicalResultOrgs(this.data, 1),
                },
                {
                  label: this.$tc('BCard.register_number'),
                  field: 'register_number',
                  render: () => renderClinicalResultRegisterNumber(this.data, 3),
                },
              ],
              (item) => {
                return (
                  <ElDescriptionsItem contentClassName="self-center overflow-hidden" label={item.label}>
                    {item.render()}
                  </ElDescriptionsItem>
                )
              }
            )}
          </ElDescriptions>
        </div>
      )
    },
    renderChart() {
      return (
        <div class={styles.clinicalResult}>
          <ElDivider class="mt-4" />
          <GCollapsibleTree
            scopedSlots={{
              default: ({ node }: { node: IChartNode }) => {
                const { id, type } = node

                if (type === 'conclusion') {
                  return (
                    <div class="text-sm text-black-default border border-solid border-gray-40 rounded w-full">
                      <div class="bg-gray-20 text-black-default border-b border-solid border-gray-40 leading-10 px-2">
                        {this.$tc('BCard.conclusion')}
                      </div>
                      <div class="leading-6 break-words break-keep whitespace-pre-wrap p-2" domPropsInnerHTML={node.data || EMPTY_PLACEHOLDER}></div>
                    </div>
                  )
                }

                if (type === 'table') {
                  return (
                    <ElTable
                      data={this.shownIntervention}
                      span-method={(param: any) => {
                        const { columnIndex, rowIndex } = param
                        if (this.mergeRows[columnIndex]) {
                          if (rowIndex === 0) {
                            return {
                              rowspan: this.shownIntervention.length,
                              colspan: 1,
                            }
                          } else {
                            return {
                              rowspan: 0,
                              colspan: 0,
                            }
                          }
                        }
                      }}
                      border
                      rowKey="group_id"
                      class="w-full flex flex-col rounded"
                      header-row-style={{
                        background: '#F4F5F7',
                      }}
                    >
                      {map(this.outcome_measures, (outcome, columnIndex: number) => {
                        return (
                          <ElTableColumn
                            key={outcome.measure_id}
                            scopedSlots={{
                              header: () => {
                                const resultName = getClinicalResultOutMeasureName(
                                  {
                                    outcomeMeasure: outcome,
                                    source: this.source,
                                    outcomes: this.data.outcomes || [],
                                  },
                                  { locale: this.$i18n.locale as I18nLang }
                                )
                                return (
                                  <BMosaic
                                    slot="label"
                                    class="overflow-hidden"
                                    trigger_point="CLINICAL_RESULT_CARD"
                                    updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL}
                                  >
                                    <div class="font-normal text-black-default">
                                      <span
                                        class="inline-block truncate float-left max-w-full mr-2"
                                        title={resultName}
                                        domPropsInnerHTML={resultName}
                                      />
                                      {/* 免费用户不显示tag，因为打上马赛克后，tag文字像歪的 */}
                                      {!this.isFreeUser && outcome.type ? (
                                        <ElTag
                                          size="mini"
                                          style={{
                                            color: get(OUTCOME_MEASURE_TYPE, `${outcome.type}.color`),
                                            borderColor: get(OUTCOME_MEASURE_TYPE, `${outcome.type}.bg`),
                                          }}
                                          color={get(OUTCOME_MEASURE_TYPE, `${outcome.type}.bg`)}
                                        >
                                          {outcome.type}
                                        </ElTag>
                                      ) : null}
                                    </div>
                                  </BMosaic>
                                )
                              },
                              default: ({ row }: { row: IClinicalResultGroup }) => {
                                const isMergeRow = this.mergeRows[columnIndex]
                                const outcomeMeasure = assign(
                                  {},
                                  outcome,
                                  isMergeRow
                                    ? outcome.analysis_list
                                      ? {
                                          analysis_list: outcome.analysis_list.filter(
                                            (item) => item.group_id.join('') === map(this.shownIntervention, 'group_id').join('')
                                          ),
                                        }
                                      : {}
                                    : { analysis_list: [], descriptive_result: '' }
                                )

                                const { value: resultValue } = getClinicalResultOutMeasureValue(
                                  {
                                    outcomeMeasure,
                                    source: this.source,
                                    group_id: row.group_id,
                                  },
                                  this.$i18n.locale as I18nLang
                                )

                                return (
                                  <BMosaic
                                    class="overflow-hidden"
                                    trigger_point="CLINICAL_RESULT_CARD"
                                    updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL}
                                  >
                                    <div class="text-black-default pb-2">
                                      <span class="line-clamp-5 float-left whitespace-pre-wrap">{trimEnd(resultValue) || EMPTY_PLACEHOLDER}</span>
                                      {outcome.if_achieve !== undefined ? (
                                        <ElTag size="small" class={[styles.therapyTypeTag, 'ml-2']} effect="plain">
                                          {outcome.if_achieve
                                            ? this.$i18n.locale === 'cn'
                                              ? '达到'
                                              : 'Met'
                                            : this.$i18n.locale === 'cn'
                                            ? '未达到'
                                            : 'Not Met'}
                                        </ElTag>
                                      ) : null}
                                    </div>
                                  </BMosaic>
                                )
                              },
                            }}
                          />
                        )
                      })}
                    </ElTable>
                  )
                }
                if (id === 'more') {
                  return (
                    <GTooltip placement="top" content={this.$tc('BCard.viewMore')}>
                      <div
                        onClick={() => openNewTab(sharedCtx.router.generatorClinicalResultDetailPath(this.data.ct_result_id))}
                        class="w-6 h-6 bg-white-default rounded-full border border-solid border-gray-40 flex items-center justify-center mt-1.5 cursor-pointer"
                      >
                        <GIcon svgName="SolidMoreHorizontal" size={20} class="text-gray-450" />
                      </div>
                    </GTooltip>
                  )
                }

                const { data } = node

                return (
                  <div class="flex flex-col justify-between">
                    {map(data, (item, index) => {
                      const { title, groupType } = item
                      return (
                        <div class={index !== 0 ? 'relative' : ''} ref={index !== 0 ? 'groupRef' : ''}>
                          {/* 自主添加连线 */}
                          {(data || []).length > 1 && index === 0 ? (
                            <div
                              style={{ height: `calc(100% - ${this.groupRef?.getBoundingClientRect().height || 0}px)` }}
                              class="top-[18px] absolute -left-10 h-full border-l border-solid border-gray-40 pointer-events-none"
                            />
                          ) : null}
                          {index !== 0
                            ? [
                                <div class="absolute top-[18px] -left-10 border-b border-solid border-gray-40 w-10 m-0"></div>,
                                <div class="absolute top-[18px] border-b border-solid border-gray-40 w-10 m-0" style="width: 42px; right: -42px;" />,
                              ]
                            : null}
                          <div
                            class={[
                              'bg-white box-border text-center break-all p-2 border border-solid border-gray-40 h-fit rounded',
                              node.id === 'root' ? 'w-40' : 'xl:w-[160px] min-[1440px]:w-[172px] min-[1660px]:w-[215px] min-[1920px]:w-[380px]',
                            ]}
                          >
                            <div class="bg-gray-20 rounded-sm px-1">
                              <div class="text-sm break-keep text-gray-450">{title}</div>
                              {groupType ? (
                                <span class="inline-block leading-5 mb-1.5 bg-white-default rounded text-sm border border-solid border-blue-default text-center px-2 text-blue-default break-keep">
                                  {groupType}
                                </span>
                              ) : null}
                            </div>
                            {id === 'root' && (this.data.condition || this.biomarker) ? (
                              <div class="pt-2 leading-5 line-clamp-5 text-sm whitespace-pre-wrap">
                                {this.data.condition ? (
                                  <div style={{ 'word-break': 'break-word' }}>
                                    {join(map(this.data.condition, 'name'), MULTI_VALUE_SEPARATOR_COMMA)}
                                  </div>
                                ) : null}
                                {this.biomarker ? <div class="keep-all">{this.biomarker}</div> : null}
                              </div>
                            ) : null}
                            {item.characteristic?.length || item.extraCharacteristic ? (
                              <div class="pt-2 leading-5 line-clamp-5 text-sm whitespace-pre-wrap" style={{ 'word-break': 'break-word' }}>
                                {renderIntervention({
                                  names: item.characteristic || [],
                                  options: {
                                    extraNames: item.extraCharacteristic,
                                    getDrugUrl: (drugId: string) => sharedCtx.router.generatorDrugPath(drugId),
                                  },
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              },
            }}
            node={this.chartNodes}
          />
          {this.showFooter && <ElDivider />}
        </div>
      )
    },
    renderFooter() {
      if (!this.showFooter) {
        return null
      }
      /** 自定义footer */
      if (this.$slots.customFooter) {
        return this.$slots.customFooter
      }
      return null
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={[styles.container, this.isIntelligentMode ? 'mb-0' : '']}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.isIntelligentMode ? null : this.renderChart()}
          {this.renderFooter()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
