import { sharedCtx } from '@pharmsnap/shared/context'
import { renderPopoverPatentNumberTags } from '@pharmsnap/shared/render'
import { IBaseLink, ISimpleFamilyParams } from '@pharmsnap/shared/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import $classes from './BFamilyTagPopperItem.module.scss'

export const BFamilyTagPopperItem = defineComponent({
  name: 'BFamilyTagPopperItem',
  props: {
    country: {
      required: true,
      type: String,
    },
    family: {
      required: true,
      type: String,
    },
    familyType: {
      required: true,
      type: String as PropType<'simple' | 'inpadoc'>,
    },
  },
  setup(props, { emit }) {
    const links = ref<IBaseLink[]>([])
    const loading = ref(false)
    async function getFamilyMember() {
      loading.value = true
      const baseParams: ISimpleFamilyParams = {
        country: [props.country],
      }
      if (props.familyType === 'simple') {
        baseParams.family_original = props.family
      } else if (props.familyType === 'inpadoc') {
        baseParams.family_inpadoc = props.family
      }
      const res = await sharedCtx.service.patent.getPatentFamily(baseParams)
      if (res.success) {
        links.value = (res.data.items || []).map((o) => ({
          name: o.pn,
          href: sharedCtx.router.generatorPatentDetailPath(o.patent_id),
        }))
      }
      loading.value = false
      emit('update')
    }
    getFamilyMember()
    return { links, loading }
  },
  render() {
    return (
      <div>
        {this.loading ? (
          <div class={$classes.loading} v-ls-loading={this.loading}></div>
        ) : this.links.length ? (
          <div class={$classes.popover}>{this.links.length ? renderPopoverPatentNumberTags(this.links) : null}</div>
        ) : null}
      </div>
    )
  },
})
