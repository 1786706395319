import { IBaseTrustTime } from '@patsnap/synapse_common_interface'
import { IBaseCountry, IBaseDictItem, IBaseDiseaseInfo, IBaseDrugInfo, IBaseOrgInfo, IBaseTargetInfo, IDecryptIconFontData, ILang } from './base'
import { IRelatedTherapeuticArea } from './disease'
import { E_LEAD_OR_COLLABORATOR_TYPE } from './enum'
export interface ICTFacilityInfo {
  status?: string
  name?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  contacts?: {
    type?: string
    name?: string
  }[]
}
export interface IGroupIdView {
  clinical_trial_id: string
  group_id: string
  url: string
}

export interface ICTCountry {
  country_code: string
  name: string
  removed?: string
}

export interface ICTArmIntervention {
  design_group_type: string
  design_group_title: string
  design_group_description: string
  intervention?: ICTGOVArmInterventionIntervention[]
}

export interface ICTGOVArmInterventionIntervention {
  intervention_name: string
  intervention_description: string
  description?: string
  intervention_other_name: string[]
  intervention_type: string
  intervention_name_cn?: string
  trade_name?: string
}

export type ICTGOVArmInterventionForTable = ICTGOVArmInterventionIntervention & Omit<ICTArmIntervention, 'intervention'>

export interface ICTRecruitmentStatus extends IDecryptIconFontData {
  name: string
  normalized_status: string
}

export interface ICTCentralContact {
  contact_type?: string
  name?: string
  // CTR
  mobile?: string
  // CTGOV
  phone?: string
  email?: string
  post?: string
  telephone?: string
  address?: string
}

export type ICTSponsor = {
  name: string
  lead_or_collaborator?: E_LEAD_OR_COLLABORATOR_TYPE
  normalized_id?: string
  normalized_id_view?: IBaseOrgInfo
  sponsor_type?: 'primary' | 'secondary'
}

export type ICTGenderType = 'All' | 'Female' | 'Male'

export type ICTHealthyType = 'Accepts Healthy Volunteers' | 'No'

export interface ICTEligibility {
  gender?: ICTGenderType
  sampling_method?: string
  healthy_volunteers?: ICTHealthyType
  population?: string
  criteria?: string
  inclusion?: string[]
  exclusion?: string[]
  inclusion_criteria?: string
  exclusion_criteria?: string
}

export interface IClinicalSecondaryIdItem {
  id_field: string
  id_value: string[]
}
export interface ICTGOVGroupNormalizedSecondaryID {
  id_type: string
  id_value: string
  url: string
}

export interface ICTGOVIntervention {
  id: string
  name: string
  other_names: string[]
  type: string
  description: string
  normalized_id?: string
}

export interface ICTPhase {
  name: string
  normalized_phase: string
  normalized_phase_highest: string
  normalized_phase_view?: IBaseDictItem
  normalized_phase_highest_view: IBaseDictItem
}

export interface ICTPrimaryCompletionDate {
  completion_date: IBaseTrustTime
  date_type: string
}

export interface ICTGOVStudyType {
  name: string
  normalized_study_type: string
}

export interface IClinicalApprovalTimelineItem {
  country: string
  country_view: IBaseCountry
  dev_status_time: IBaseTrustTime
}

interface ICompletionDate {
  completion_date_type: string
  completion_date: IBaseTrustTime
}

interface ICTGOVEnrollment extends IDecryptIconFontData {
  enrollment?: string
  enrollment_type: string
}

interface ICTMaxAge {
  max_age: number
  max_age_unit: string
  max_age_text?: string
}

interface ICTMinAge {
  min_age: number
  min_age_unit: string
  min_age_text?: string
}

export interface ICTIdentifer {
  id_value?: string
  url?: string
}

interface ICTGOVStudyDesign {
  allocation: string
  intervention_model: string
  masking: string
  primary_purpose: string
}

interface ICTRStudyDesign {
  intervention_model?: string
  allocation?: string
  masking?: string
  study_category?: string
  location?: string
}

interface IWHOStudyDesign {
  intervention_model?: string
  allocation?: string
  masking?: string
}

export interface ICTOutcome {
  title: string
  outcome_type: 'Primary' | 'Secondary'
  endpoint_type: string
  time_frame: string
  description: string
}

export interface ICTRCentralInvestigator {
  name?: string
  degree?: string
  job_title?: string
  telephone?: string
  email?: string
  address?: string
  post?: string
  affiliation?: string
  role?: string
}

export interface ICTREnrollmentV2 extends IDecryptIconFontData {
  // 没有做标准化的原始值
  actual_text?: string
  already_text?: string
  estimated_text?: string
  // 国内国外
  location?: 'Domestic' | 'International'
}

export interface IWHOEnrollmentV2 {
  enrollment?: string
  enrollment_type?: string
}

export interface ICTCompletionDate {
  location: 'Domestic' | 'International'
  // 第一例受试者签署知情同意书日期
  first_sign_date: IBaseTrustTime
  // 第一例受试者入组日期
  first_participant_date: IBaseTrustTime
  // 试验暂停日期
  suspension_date?: IBaseTrustTime
  // 试验完成日期
  completion_date?: IBaseTrustTime
  // 试验终止日期
  termination_date?: IBaseTrustTime
}

export interface IWHOCompletionDate {
  // 试验完成日期
  completion_date?: IBaseTrustTime
  date_type?: string
}

export type IClinicalTranslationField =
  | 'brief_title'
  | 'official_title'
  | 'brief_summary'
  | 'study_purpose'
  | 'study_purpose'
  | 'detailed_description'
interface ICTDate {
  date: IBaseTrustTime
  date_type: string
}
export interface IClinicalTrial extends IDecryptIconFontData, IRelatedTherapeuticArea {
  arm_intervention?: ICTArmIntervention[]
  brief_summary: string
  detailed_description: string
  brief_title: string
  central_contact?: ICTCentralContact[]
  clinical_trial_id: string
  // 后面考虑是否放到各自的定义中
  clinical_trial_translation: Record<IClinicalTranslationField, string>
  // 请使用f_condition字段
  // condition?: { name: string }[]
  country?: ICTCountry[]
  country_count?: number
  disease_id_view?: IBaseDiseaseInfo[]
  single_or_multi: 'single_drug' | 'multiple_drugs'
  eligibility?: ICTEligibility
  facility?: ICTFacilityInfo[]
  facility_count?: number
  last_update_posted_date?: IBaseTrustTime
  last_updated_at: IBaseTrustTime
  if_ct_result?: number
  control_drug_id_view: IBaseDrugInfo[]
  control_drug_id?: string[]
  main_experimentation_drug_id_view: IBaseDrugInfo[]
  main_experimentation_drug_id?: string[]
  main_experimentation_drug_target_id_view: IBaseTargetInfo[]
  main_experimentation_drug_target_id?: string[]
  official_title: string
  phase?: ICTPhase
  recruitment_status?: ICTRecruitmentStatus
  expanded_access_status?: ICTRecruitmentStatus
  primary_completion_date_v2?: ICTDate
  register_number: string
  registry?: string[]
  sponsor?: ICTSponsor[]
  start_date_v2?: ICTDate
  study_first_posted_date?: IBaseTrustTime
  // ctgov publish time
  published_time?: { published_time: IBaseTrustTime; ct_result_id: string }
  completion_date: ICompletionDate
  url: string
  max_age?: ICTMaxAge
  min_age?: ICTMinAge
  outcome: ICTOutcome[]
  /**
   * 申办机构（标定值）
   */
  sponsor_org_master_entity_id_view?: IBaseOrgInfo[]
  sponsor_org_master_entity_id?: string[]
  org_master_entity_id?: string[]
  org_master_entity_id_view?: IBaseOrgInfo[]
  condition_disease_id?: string[]
  condition_disease_id_view?: IBaseDiseaseInfo[]
  /** 原始适应症 */
  f_condition?: string[]
  lang: ILang
  group_id_view?: IGroupIdView[]
  group_id_highlight?: string[]
  if_iit: boolean
  why_stopped?: string
  study_identifier?: string[]
}

export interface IClinicalHihFundedResearchIdView {
  funded_research_id: string
  project_id: string
  source: string
  source_url: string
}
export interface ICTGOVClinicalDetail extends IClinicalTrial {
  secondary_id?: IClinicalSecondaryIdItem[]
  normalized_secondary_id?: ICTGOVGroupNormalizedSecondaryID[]
  study_identifier?: string[]
  related_id?: string[]
  other_secondary_id?: string[]
  nih_funded_research_id_view?: IClinicalHihFundedResearchIdView[]
  detailed_description: string
  group_normalized_secondary_ids: ICTGOVGroupNormalizedSecondaryID[]
  intervention?: ICTGOVIntervention[]
  study_design: ICTGOVStudyDesign
  study_type?: ICTGOVStudyType
  enrollment?: ICTGOVEnrollment
  /**
   * 合作机构（标定值)
   */
  collaborator_org_master_entity_id_view?: IBaseOrgInfo[]
  collaborator_org_master_entity_id?: string[]
  data_source: 'CTGOV'
}

export interface IEthicCommitteeResult {
  index: number // 该评审结果的编号
  name: string // 评审委员会的名称
  approval_date?: IBaseTrustTime // 评审的时间
  review_conclusion: string // 评审的结论
}

export interface ICTRClinicalDetail extends IClinicalTrial {
  study_identifier?: string[]
  target_id?: string[]
  target_id_view?: IBaseTargetInfo[]
  applicant?: ICTSponsor[]
  study_design?: ICTRStudyDesign
  study_purpose?: string
  central_investigator?: ICTRCentralInvestigator[]
  enrollment_v2?: ICTREnrollmentV2[]
  completion_date_v2?: ICTCompletionDate[]
  data_source: 'CTR'
  study_number?: string
  ethic_committee: IEthicCommitteeResult[]
}

export interface IWHOClinicalDetail extends IClinicalTrial {
  group_normalized_secondary_ids?: ICTGOVGroupNormalizedSecondaryID[]
  study_type?: ICTGOVStudyType
  enrollment?: ICTGOVEnrollment
  completion_date: ICompletionDate
  completion_date_v2?: IWHOCompletionDate[]
  intervention?: { name: string }[]
  study_design?: IWHOStudyDesign
  enrollment_v2?: IWHOEnrollmentV2[]
  /**
   * 合作机构（标定值)
   */
  collaborator_org_master_entity_id_view?: IBaseOrgInfo[]
  collaborator_org_master_entity_id?: string[]
  data_source: 'WHO'
  nih_funded_research_id_view?: IClinicalHihFundedResearchIdView[]
  secondary_id?: IClinicalSecondaryIdItem[]
}

export type IClinicalDetail = ICTGOVClinicalDetail | ICTRClinicalDetail | IWHOClinicalDetail

export const isCtr = (data: IClinicalDetail): data is ICTRClinicalDetail => {
  return data.data_source === 'CTR'
}
