import MasterNav from '@patsnap-biz/master-nav/lib/index.js'
import '@patsnap-ui/icon/assets/solid/NavSearch.svg'
import { Site, SLD } from '@patsnap-utils/biz'
import { IClinicalItemKey, IEntityCompareItemKey, IPlaybookItemKey } from '@pharmsnap/shared/types'
import { computed, getCurrentInstance, reactive } from '@vue/composition-api'
import { createGlobalState, useLocalStorage } from '@vueuse/core'
import { showSingleToast } from 'pharmsnapMF_shared/utils'
import { EN_WIDE_STATUS, E_NAV_KEY, E_ROUTER_NAME } from '../types/enum'

type INav = {
  key: string
  icon?: string
  url?: string
  routeName?: string
  children?: INav[] /** 二级路由 */
}

export const PHARM_NAV_WIDE = 'pharmsnap_NavWide'

// 二级nav开关状态
const LV2_NAV_WIDE = 'pharmsnap_lv2_NavWide'

export const useGlobalNavWideState = createGlobalState(() => {
  const slideNavWide = useLocalStorage<EN_WIDE_STATUS>(PHARM_NAV_WIDE, EN_WIDE_STATUS.WIDE, {
    writeDefaults: false,
    listenToStorageChanges: true,
  })

  const lv2NavWide = useLocalStorage<Partial<Record<E_NAV_KEY, EN_WIDE_STATUS>>>(
    LV2_NAV_WIDE,
    {
      [E_NAV_KEY.SEARCH]: EN_WIDE_STATUS.WIDE,
    },
    {
      writeDefaults: false,
      listenToStorageChanges: true,
    }
  )

  const slideNavWidth = computed(() => (slideNavWide.value === EN_WIDE_STATUS.WIDE ? 220 : 64))

  const isWide = computed(() => slideNavWide.value === EN_WIDE_STATUS.WIDE)

  function setLocalNavWide(isWide: boolean) {
    // console.log(`isWide Mode: ${isWide}`)
    // 当前是否宽状态的变量(getter setter)
    // Note: Wide时 220px, 非Wide时64px
    slideNavWide.value = isWide ? EN_WIDE_STATUS.WIDE : EN_WIDE_STATUS.NOT_WIDE
  }

  function setLv2NavStatus(key: E_NAV_KEY, isWide: boolean) {
    lv2NavWide.value[key] = isWide ? EN_WIDE_STATUS.WIDE : EN_WIDE_STATUS.NOT_WIDE
  }

  function getLv2NavStatus(key: E_NAV_KEY) {
    return lv2NavWide.value[key]
  }

  return {
    setLocalNavWide,
    isWide,
    slideNavWidth,
    getLv2NavStatus,
    setLv2NavStatus,
  }
})

export function useNavService(options?: {
  onShowPlaybookDialog?: (key: IPlaybookItemKey) => void
  onShowCompareDialog?: (key: IEntityCompareItemKey) => void
}) {
  const ins = getCurrentInstance()

  const { setLocalNavWide, isWide, getLv2NavStatus, setLv2NavStatus } = useGlobalNavWideState()

  const playbookList: Array<{ key: IPlaybookItemKey }> = [
    {
      key: E_NAV_KEY.ORGANIZATION_DUE_DILIGENCE,
    },
    {
      key: E_NAV_KEY.DRUG_INSIGHT,
    },
    {
      key: E_NAV_KEY.DRUG_DEAL,
    },
    {
      key: E_NAV_KEY.FIND_ORGANIZATION,
    },
    {
      key: E_NAV_KEY.DRUG_CORE_PATENT,
    },
    {
      key: E_NAV_KEY.FIND_RELATIONSHIPS,
    },
  ]

  const entityCompareList: Array<{ key: IEntityCompareItemKey }> = [
    {
      key: E_NAV_KEY.DRUG_COMPARE,
    },
    {
      key: E_NAV_KEY.ORGANIZATION_COMPARE,
    },
    {
      key: E_NAV_KEY.TARGET_COMPARE,
    },
    {
      key: E_NAV_KEY.INDICATION_COMPARE,
    },
  ]

  const clinicalNavList: Array<{ key: IClinicalItemKey; routeName?: string }> = [
    {
      key: E_NAV_KEY.CLINICAL_TRIAL_FINDER,
      routeName: E_ROUTER_NAME.CLINICAL_FINDER,
    },
    {
      key: E_NAV_KEY.CLINICAL_RESULT_FINDER,
      routeName: E_ROUTER_NAME.CLINICAL_RESULT_FINDER,
    },
  ]

  const my_functionLinks: INav[] = [
    { key: E_NAV_KEY.HOMEPAGE, icon: 'SolidHome', routeName: E_ROUTER_NAME.HOME },
    {
      key: E_NAV_KEY.SEARCH,
      icon: 'SolidNavSearch',
      children: [
        { key: E_NAV_KEY.DRUG_FINDER, icon: 'SolidSynapseDrug', routeName: E_ROUTER_NAME.DRUG_FINDER },
        { key: E_NAV_KEY.PATENT, icon: 'SolidPatentReverse', routeName: E_ROUTER_NAME.PATENT_FINDER },
        ...clinicalNavList,
        { key: E_NAV_KEY.TRANSLATIONAL_MEDICINE_FINDER, icon: 'TranslationalMedicine', routeName: E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_FINDER },
        { key: E_NAV_KEY.DRUG_DEAL_FINDER, icon: 'DrugDeal', routeName: E_ROUTER_NAME.DRUG_DEAL_FINDER },
        { key: E_NAV_KEY.LITERATURE, icon: 'SolidTextFile', routeName: E_ROUTER_NAME.LITERATURE_FINDER },
      ],
    },
    { key: E_NAV_KEY.COMPETITIVE_LANDSCAPE, icon: 'SolidInsightsCompetitive', routeName: E_ROUTER_NAME.COMPETITIVE_LANDSCAPE },
    {
      key: E_NAV_KEY.PLAYBOOKS,
      icon: 'Playbooks',
      children: playbookList,
    },
    { key: E_NAV_KEY.SEARCH_HISTORY, icon: 'SolidHistoryRecord', routeName: E_ROUTER_NAME.SEARCH_HISTORY },
    { key: E_NAV_KEY.NEWS_HOME, icon: 'LatestNews', routeName: E_ROUTER_NAME.LATEST_NEWS_HOME },
    { key: E_NAV_KEY.REPORT_LIST, icon: 'SolidNotebook', routeName: E_ROUTER_NAME.REPORT_LIST },
  ]

  const partitions = [
    ...my_functionLinks,
    'tools', //分隔符
    'slot:mySlotName', //在对应位置插入自定义slot
    { key: E_NAV_KEY.EMAIL_ALERT, icon: 'SolidEmailAlerts', routeName: E_ROUTER_NAME.EMAIL_ALERT },
    { key: E_NAV_KEY.WORKSPACE, icon: 'Solid3D', url: new Site({ sld: SLD.WORKSPACE }).href },
    { key: E_NAV_KEY.ENTITY_COMPARE, icon: 'Vs', children: entityCompareList },
    { key: E_NAV_KEY.DASHBOARD, icon: 'SolidContentFormatingGrid3', routeName: E_ROUTER_NAME.DASHBOARD },
    { key: E_NAV_KEY.KNOWLEDGE_GRAPH, icon: 'KgExploration', routeName: E_ROUTER_NAME.KNOWLEDGE_EXPLORE },
  ]

  const navService = reactive(new MasterNav.NavService())
  const _toggleExpandLv2 = navService.toggleExpandLv2.bind(navService)

  navService.$t = (str: string) => {
    // 传入翻译
    // 必须提供： hideSidebar、expandSidebar
    if (navService.isWide) {
      return ins?.proxy.$i18n.t(`Nav.${str}`) as string
    }
    return ins?.proxy.$i18n.t(`Nav.${str}_not_wide`) as string
  }
  // 自行排版整个列表
  navService.partitions = partitions
  //点击链接callback
  navService.onClickedLinkHook = (key: string, o: INav) => {
    const playbookNavItem = playbookList.find((item) => item.key === key)
    if (playbookNavItem) {
      options?.onShowPlaybookDialog && options.onShowPlaybookDialog(playbookNavItem.key)
      return
    }
    const entityCompare = entityCompareList.find((item) => item.key === key)
    if (entityCompare) {
      options?.onShowCompareDialog && options.onShowCompareDialog(entityCompare.key)
      return
    }

    if (o.url) {
      window.open(o.url)
      return
    }

    if (
      o.routeName === E_ROUTER_NAME.HOME ||
      o.routeName === E_ROUTER_NAME.DRUG_FINDER ||
      o.routeName === E_ROUTER_NAME.CLINICAL_FINDER ||
      o.routeName === E_ROUTER_NAME.COMPETITIVE_LANDSCAPE ||
      o.routeName === E_ROUTER_NAME.PATENT_FINDER ||
      o.routeName === E_ROUTER_NAME.LATEST_NEWS_HOME ||
      // o.routeName === E_ROUTER_NAME.DASHBOARD
      o.routeName === E_ROUTER_NAME.DASHBOARD ||
      o.routeName === E_ROUTER_NAME.LITERATURE_FINDER ||
      o.routeName === E_ROUTER_NAME.KNOWLEDGE_GRAPH ||
      o.routeName === E_ROUTER_NAME.SEARCH_HISTORY ||
      o.routeName === E_ROUTER_NAME.EMAIL_ALERT ||
      o.routeName === E_ROUTER_NAME.KNOWLEDGE_EXPLORE ||
      o.routeName === E_ROUTER_NAME.REPORT_LIST ||
      o.routeName === E_ROUTER_NAME.CLINICAL_RESULT_FINDER ||
      o.routeName === E_ROUTER_NAME.DRUG_DEAL_FINDER ||
      o.routeName === E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_FINDER
    ) {
      ins?.proxy.$router.push({ name: o.routeName })
    } else {
      showSingleToast({
        message: ins?.proxy.$t('Nav.coming') as string,
        type: 'info',
      })
    }
  }
  //开关宽窄的callback
  navService.toggleWideHook = (isWide: boolean) => {
    setLocalNavWide(isWide)
    // 开关打开时，若用户未手动折叠检索nav，则默认打开检索nav
    if (isWide && getLv2NavStatus(E_NAV_KEY.SEARCH) !== EN_WIDE_STATUS.NOT_WIDE) {
      navService.toggleExpandLv2(E_NAV_KEY.SEARCH, true)
    }
  }

  // nav展开/折叠处理
  navService.toggleExpandLv2 = (key: string, isWide?: boolean) => {
    _toggleExpandLv2(key, isWide)
    // 存储检索nav的状态
    if (key === E_NAV_KEY.SEARCH) {
      const isExpanded = navService.isExpanded(key)
      setLv2NavStatus(key, isExpanded)
    }
  }

  navService.isWide = getWide()
  // 缩起的时候icon下方显示label
  navService.showLabelUnderIcon = true
  // 开关二级展开状态、key支持一级或二级的key
  // navService.toggleExpandLv2('my_workspace', true /*false*/)

  function getWide() {
    return isWide.value
  }

  function setCurrentKey(key: string) {
    navService.currentActiveKey = key
  }

  function toggleWideHook(isWide: boolean) {
    setLocalNavWide(isWide)
    navService.isWide = isWide
  }

  return { navService, setCurrentKey, toggleWideHook }
}
