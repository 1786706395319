import { E_NODATA_SIZE } from '@pharmsnap/shared/src/types'
import { defineComponent, unref } from '@vue/composition-api'
import { GEmpty } from '../../../ui/GEmpty/GEmpty'
import { GLoading } from '../../../ui/GLoading/GLoading'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterItemStateTreeNodeNext } from '../type'
import { BFilterAdvanceUiTypeRadio } from './BFilterAdvanceUiTypeRadio'

export const BFilterAdvanceContentPanel = defineComponent({
  name: 'BFilterAdvanceContentPanel',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: '',
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    renderUiTypeRadio() {
      const advanceUiState = unref(this.filterService.selectedState) as IFilterItemStateTreeNodeNext | undefined

      if (!advanceUiState?.identity) return null

      if (advanceUiState._advance.uiTypes.length <= 1) return null

      return [
        <span class="ml-auto mr-2 text-sm text-text-t2">{this.$t('bFilterNext.orderBy')}:</span>,
        <BFilterAdvanceUiTypeRadio></BFilterAdvanceUiTypeRadio>,
      ]
    },
    renderContent() {
      if (this.isLoading) {
        return (
          <div class="relative h-full">
            <GLoading></GLoading>
          </div>
        )
      }

      return [
        <div v-show={this.isEmpty} class="h-full flex items-center justify-center">
          <GEmpty size={E_NODATA_SIZE.SMALL} title={this.emptyText}></GEmpty>
        </div>,
        <div v-show={!this.isEmpty} class="h-full">
          {this.$slots.default}
        </div>,
      ]
    },
  },
  render() {
    return (
      <div class={['flex flex-col']}>
        <div class="flex-shrink-0 flex items-center h-10">
          {this.$slots.topLeft}
          {this.renderUiTypeRadio()}
        </div>
        <div class="mt-2 rounded border border-gray-40 flex-1 overflow-hidden p-2">{this.renderContent()}</div>
      </div>
    )
  },
})
