import { IAggregationSingleResult } from '@patsnap/synapse_common_interface'
import { userInjectFilterService } from '@pharmsnap/shared/components/business/BFilter/BFilterService'
import { computed, defineComponent, getCurrentInstance, PropType, ref } from '@vue/composition-api'
import { assign } from 'lodash'
import { SliderRange as PtSliderRange } from 'patsnap-biz'
import { IFilterItemSlideConfig } from '../../../../../types'
import $style from './FilterItemSlide.module.scss'

const defaultMinVal = 0
const defaultMaxVal = '*'

export const FilterItemSlide = defineComponent({
  name: 'FilterItemSlide',
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemSlideConfig>,
    },
    getSliderAggregationDataFn: {
      type: Function as PropType<(field: string) => Promise<IAggregationSingleResult[][]>>,
    },
  },
  i18n: {
    messages: {
      cn: {
        max: '最大值',
      },
      en: {
        max: 'Max',
      },
    },
  },
  setup(props) {
    const { field, mapList: defaultMapList } = props.config
    const { state, actions } = userInjectFilterService()
    const ins = getCurrentInstance()
    const range = ref<[number, number | string]>([defaultMinVal, defaultMaxVal])
    /**
     * 节点和真实值的映射关系
     */
    const mapList = defaultMapList || [{ value: 0 }, { value: '*', label: ins?.proxy.$tc('max') }]

    /**
     * query中是否存在当前field的过滤项的值
     */
    const filterVal = state?.filterValueArr.find((item) => item.field === field)?.value[0]

    /**
     * 获取初始化的最大最小值
     * 如果已经存在过滤项，就取过滤项的值
     * @returns
     */
    async function getRange() {
      if (filterVal) {
        range.value = [filterVal.from || defaultMinVal, filterVal.to || defaultMaxVal]
        return
      }
      if (!props.getSliderAggregationDataFn) return
      const [minRes, maxRes] = await props.getSliderAggregationDataFn(field)
      const minCount = getFirstAggResultFirstItemVal(minRes) || defaultMinVal
      const maxCount = getFirstAggResultFirstItemVal(maxRes) || defaultMaxVal
      range.value = [minCount, maxCount]
    }

    /**
     * 获取首个agg result的首个item的count
     * @param param
     * @returns
     */
    function getFirstAggResultFirstItemVal(param: IAggregationSingleResult[]) {
      return param.length && param[0].items.length && param[0].items[0].count ? param[0].items[0].count : null
    }

    function handleUpdateRange(range: [string, string]) {
      const [from, to] = range
      const from2Num = Number(from)
      const to2Num = Number(to)
      if (from2Num === defaultMinVal && to === defaultMaxVal) {
        return actions?.setFilterValue(field, [])
      }
      actions?.setFilterValue(field, [
        assign(
          {
            display_name_cn: `${from}-${to}`,
            display_name_en: `${from}-${to}`,
            from: from2Num,
            value: `${from}-${to}`,
            include_upper: true,
            include_lower: true,
          },
          to === '*' ? {} : { to: to2Num }
        ),
      ])
    }
    // slide 类型的过滤项索引不支持聚合了,故拿掉
    // getRange()

    const disabled = computed(() => !!filterVal)

    return { range, mapList, disabled, handleUpdateRange }
  },
  render() {
    return (
      <PtSliderRange
        v-model={this.range}
        mapList={this.mapList}
        disabled={this.disabled}
        class={[
          $style.slide,
          {
            [$style.hideSlide]: this.config.hideSlide,
          },
        ]}
        onUpdateModelEvent={this.handleUpdateRange}
      ></PtSliderRange>
    )
  },
})
