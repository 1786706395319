import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { ILoadingService, ISearchResult } from '@pharmsnap/shared/src/types'
import { PropType, defineComponent, watch } from '@vue/composition-api'
import { isEqual } from 'lodash'
import $classes from './BPatentAnalysis.module.scss'
import { PatentCliff } from './components/biz/PatentCliff/PatentCliff'
import { PatentsTrend } from './components/biz/PatentsTrend/PatentsTrend'
import { TargetsRank } from './components/biz/TargetsRank/TargetsRank'
import { TherapeuticAreas } from './components/biz/TherapeuticAreas/TherapeuticAreas'
import { TopAssignees } from './components/biz/TopAssignees/TopAssignees'
import { TopAssigneesCooperation } from './components/biz/TopAssigneesCooperation/TopAssigneesCooperation'
import { TopAuthorities } from './components/biz/TopAuthorities/TopAuthorities'
import cn from './locales/cn.json'
import en from './locales/en.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analysisList: any[] = [PatentsTrend, TopAuthorities, PatentCliff, TopAssignees, TopAssigneesCooperation, TargetsRank, TherapeuticAreas]
export const BPatentAnalysis = defineComponent({
  name: 'BPatentAnalysis',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { uncollapse_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props, { emit }) {
    const resolveUrl = (id: string) => {
      emit('resolveUrl', id)
    }

    async function getDataFn() {
      props.loadingService.action.setLoading(true)
      const rt = await props.getTotalFn()
      props.loadingService.action.setTotal(rt.total)
      props.loadingService.action.setUnCollapseTotal(rt.uncollapse_total)
      props.loadingService.action.setLoading(false)
    }

    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter, props.queryService.state.collapse],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        getDataFn()
      },
      {
        immediate: true,
      }
    )

    return { resolveUrl }
  },
  render() {
    return (
      <div class={$classes.patentChart}>
        {analysisList.map((Comp, index) => (
          <Comp queryService={this.queryService} onResolveUrl={this.resolveUrl} key={index} id={`chart-${index + 1}`}></Comp>
        ))}
      </div>
    )
  },
})
