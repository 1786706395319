import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.TRANSLATIONAL_MEDICINE_FINDER,
}

export default [
  {
    path: 'translational-medicine-finder',
    name: E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/translational-medicine/TranslationalMedicineFinder'),
    meta: {
      ...meta,
      title: createWebTitle('转化医学', 'Translational Medicine'),
      hideQuickSearch: true,
    },
  },
  {
    path: 'translational-medicine-list',
    name: E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/translational-medicine/TranslationalMedicineList'),
    meta: {
      ...meta,
      title: createWebTitle('转化医学', 'Translational Medicine'),
      notWide: true,
    },
  },
] as RouteConfig[]
