import '@patsnap-biz/save-to-workspace-v2/lib/style'
import '@patsnap-ui/icon/assets/solid/3d.svg'
import '@patsnap-ui/icon/assets/solid/FolderAdd.svg'
import '@patsnap-ui/icon/assets/solid/FolderClosed2.svg'
import '@patsnap-ui/icon/assets/solid/FolderMany.svg'
import { defineComponent, PropType } from '@vue/composition-api'
import { ClinicalWorkspace } from './components/ClinicalWorkspace/ClinicalWorkspace'
import { DrugWorkspace } from './components/DrugWorkspace/DrugWorkspace'
import { PatentWorkspace } from './components/PatentWorkspace/PatentWorkspace'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { ISupportWorkspaceDataType, IWorkspaceDataType, workspaceComponentProps } from './type'

const dataType2WorkspaceDataType: Record<ISupportWorkspaceDataType, IWorkspaceDataType> = {
  clinical_trial: 'CLINICAL',
  drug: 'DRUG',
  patent: 'PATENT',
}
const workspaceComMap: Record<IWorkspaceDataType, ReturnType<typeof defineComponent>> = {
  PATENT: PatentWorkspace,
  DRUG: DrugWorkspace,
  CLINICAL: ClinicalWorkspace,
}

export const BWorkspaceDialog = defineComponent({
  name: 'BWorkspaceDialog',
  i18n: {
    messages: { cn, en },
  },
  props: {
    dataType: {
      type: String as PropType<ISupportWorkspaceDataType>,
      required: true,
    },
    ...workspaceComponentProps(),
  },
  setup(props, ctx) {
    const handleClose = () => ctx.emit('close')

    return {
      handleClose,
    }
  },
  methods: {
    renderWorkspaceDialog() {
      if (this.showWorkspaceDialog) {
        const WorkspaceComponent = workspaceComMap[dataType2WorkspaceDataType[this.dataType]]
        return (
          <WorkspaceComponent
            onClose={this.handleClose}
            total={this.total}
            searchParams={this.searchParams}
            selectedIds={this.selectedIds}
          ></WorkspaceComponent>
        )
      }
      return null
    },
  },
  render() {
    const WorkspaceComponent = workspaceComMap[dataType2WorkspaceDataType[this.dataType]]
    return (
      <WorkspaceComponent
        onClose={this.handleClose}
        total={this.total}
        searchParams={this.searchParams}
        selectedIds={this.selectedIds}
      ></WorkspaceComponent>
    )
  },
})
