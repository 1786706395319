import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { IBaseDictItem, IBaseDrugAdc, IBaseSequence, IDrugDetail } from '@patsnap/synapse_common_interface'
import { getDicItemName, getSpecialLang } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { isUndefined, map } from 'lodash'
import { sharedCtx } from '../../../context'
import { getRedirectLinkStatic } from '../../../utils'
import { GDialogTrigger } from '../../ui/GDialogTrigger/GDialogTrigger'
import { GLink } from '../../ui/GLink/GLink'
import { BCasStructureDialog } from '../BCasStructure'
import { BSequenceDialog } from '../BSequence'

function drugAdcDictFieldComBuilder(field: keyof IBaseDrugAdc, name: string) {
  return defineComponent({
    name: name,
    props: {
      data: {
        type: Object as PropType<IBaseDrugAdc>,
        required: true,
      },
    },
    setup(props) {
      return () => {
        if (!props.data[field]) return <span>{EMPTY_PLACEHOLDER}</span>

        return <span>{getDicItemName(props.data[field] as IBaseDictItem, 'EN')}</span>
      }
    },
  })
}

export const BDrugAdcAntibodyField = defineComponent({
  name: 'BDrugAdcAntibodyField',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
  },
  setup(props) {
    return () => {
      if (!props.data.antibody_drug_id_view) return <span>{EMPTY_PLACEHOLDER}</span>

      const drugName = getSpecialLang({
        data: props.data.antibody_drug_id_view.drug_name || [],
        field: 'name',
        lang: 'EN',
        isDegraded: true,
      })
      const href = sharedCtx.router.generatorDrugPath(props.data.antibody_drug_id_view.drug_id)

      return (
        <a style="word-break: break-word;" href={href} target="_blank" title={drugName} class="text-blue-default hover:underline">
          {drugName}
        </a>
      )
    }
  },
})

export const BDrugAdcAntibodySequenceTrigger = defineComponent({
  name: 'BDrugAdcAntibodySequenceTrigger',
  props: {
    sequence: {
      type: Array as PropType<IBaseSequence[]>,
      default: () => [],
    },
    triggerText: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    function onSequenceSimilar(sequence_hash_id: string) {
      ctx.emit('clickSimilar', sequence_hash_id)
    }

    return () => {
      const sequence = props.sequence

      if (!sequence.length) return <span>{EMPTY_PLACEHOLDER}</span>

      return (
        <GDialogTrigger dialog={BSequenceDialog} onClickSimilar={onSequenceSimilar} targetSequenceList={map(sequence, (sequence) => ({ sequence }))}>
          <span class="text-sm leading-6 hover:underline cursor-pointer text-blue-default">{props.triggerText}</span>
        </GDialogTrigger>
      )
    }
  },
})

export const BDrugAdcLinkerField = drugAdcDictFieldComBuilder('linker_id_view', 'BDrugAdcLinkerField')

export const BDrugAdcLinkerTypeField = drugAdcDictFieldComBuilder('link_type_id_view', 'BDrugAdcLinkerTypeField')

export const BDrugAdcStructureTrigger = defineComponent({
  name: 'BDrugAdcLinkerStructureTrigger',
  props: {
    data: {
      type: Object as PropType<IDrugDetail & IBaseDrugAdc>,
      required: true,
    },
    field: {
      type: String as PropType<'linker_id_view' | 'payload_id_view'>,
      required: true,
    },
    triggerText: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const { data, field } = toRefs(props)

    const structureList = computed(() => {
      const structure =
        field.value === 'linker_id_view'
          ? data.value.structure?.filter((i) => i.structure_type === 'LINKER')
          : data.value.structure?.filter((i) => i.structure_type === 'PAYLOAD')
      if (!structure) return []
      return structure.map((i) => ({ ...i }))
    })

    function onStructureSimilar(sequence_hash_id: string) {
      ctx.emit('clickSimilar', sequence_hash_id)
    }

    return () => {
      if (!props.data[props.field]) return <span>{EMPTY_PLACEHOLDER}</span>

      return (
        <GDialogTrigger
          dialog={BCasStructureDialog}
          onClickSimilar={onStructureSimilar}
          structureList={structureList.value}
          onCasStructureItemClicked={(index: number) => ctx.emit('casStructureItemClicked', index)}
        >
          <span class="text-sm leading-6 hover:underline cursor-pointer text-blue-default">{props.triggerText}</span>
        </GDialogTrigger>
      )
    }
  },
})

export const BDrugAdcPayloadField = drugAdcDictFieldComBuilder('payload_id_view', 'BDrugAdcPayloadField')

export const BDrugAdcDarField = defineComponent({
  name: 'BDrugAdcDarField',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
  },
  setup(props) {
    return () => {
      if (isUndefined(props.data.dar)) return <span>{EMPTY_PLACEHOLDER}</span>
      let text = ''
      if (props.data.dar.value_type === 'RANGE') {
        const values = [props.data.dar.lower_value, props.data.dar.upper_value].filter((i) => !isUndefined(i))
        text = values.length > 1 ? values.join('-') : `${isUndefined(values[0]) ? '' : values[0]}`
      } else {
        text = `${isUndefined(props.data.dar.avg_value) ? '' : props.data.dar.avg_value}`
      }
      if (!text) return <span>{EMPTY_PLACEHOLDER}</span>
      return <span>{text}</span>
    }
  },
})

export const BDrugAdcAntibodyTypeField = drugAdcDictFieldComBuilder('antibody_type_view', 'BDrugAdcAntibodyTypeField')

export const BDrugAdcConjugationSiteField = drugAdcDictFieldComBuilder('site_id_view', 'BDrugAdcConjugationSiteField')

export const BDrugAdcSiteSpecialField = defineComponent({
  name: 'BDrugAdcSiteSpecialField',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
  },
  setup(props) {
    return () => {
      if (isUndefined(props.data.specific_site)) return <span>{EMPTY_PLACEHOLDER}</span>

      return <span>{props.data.specific_site === 1 ? 'Yes' : 'No'}</span>
    }
  },
})

export const BDrugAdcSourceField = defineComponent({
  name: 'BDrugAdcSourceField',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
    triggerText: {
      type: String,
      default: 'View',
    },
  },
  setup(props) {
    return () => {
      const { locale } = useLocale()
      if (!props.data.adc_reference?.length) return <span>{EMPTY_PLACEHOLDER}</span>

      if (props.data.adc_reference.every((i) => !i.source_url)) return <span>{EMPTY_PLACEHOLDER}</span>

      const foundSource = props.data.adc_reference.find((i) => !!i.source_url)

      if (!foundSource || !foundSource.source_url) return <span>{EMPTY_PLACEHOLDER}</span>

      return (
        <GLink href={getRedirectLinkStatic(foundSource.source_url, locale.value)} defaultColor="blue" newTab>
          {props.triggerText}
        </GLink>
      )
    }
  },
})
