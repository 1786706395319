import { encryptNum } from '@pharmsnap/shared/src/utils'
import { defineComponent } from '@vue/composition-api'
export const BEncryptText = defineComponent({
  name: 'BEncryptText',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  render() {
    return <span class="icon-font" domPropsInnerHTML={encryptNum(this.text)}></span>
  },
})
