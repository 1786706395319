/** copy from @patsnap-utils/axios-mock */
import { AxiosInstance, AxiosRequestConfig } from 'axios'
/**
 * mock接口配置
 */
interface MockConfig {
  /**
   * mock接口唯一标识
   */
  apifoxApiId?: string
  /**
   * mock接口响应唯一标识,如果配置了apifoxResponseId,则apifoxResponseIds不生效
   */
  apifoxResponseId?: string
  /**
   * mock接口响应唯一标识数组, 请求的时候回随机选择一个
   * 如果配置了apifoxResponseId,则apifoxResponseIds不生效
   */
  apifoxResponseIds?: string[]
  /**
   * 高级mock配置
   * 使用方法:
   * 第一步:打开高级mock配置,
   * 第二步:新建一个期望
   * 第三步:期望的条件配置 参数位置: "query",参数名称: "apifoxMockCase",参数值: 期望的值
   */
  apifoxMockCases?: string[]
}
/**
 * mock请求拦截器配置
 */
export interface MockRequestInterceptorsOptions {
  /**
   * 是否禁用mock拦截器
   */
  disable?: boolean
  /**
   * mock服务地址
   */
  mockServerBaseUrl: string
  /**
   * mock接口配置, key为请求地址, value为mock接口配置
   * key格式为: 请求方法:接口地址
   * 例如: 'get:1.0/public/home': { apifoxApiId: '61420870', apifoxResponseIds: ['156521902', '156521903'] }
   */
  mockUrlConfig: Record<string, MockConfig>
  /**
   * 后置处理函数, 命中mock接口后, 会调用该函数, 用于对请求参数进行处理
   * @param config axios请求配置 InternalAxiosRequestConfig
   * @returns InternalAxiosRequestConfig
   */
  afterHandle?: (config: AxiosRequestConfig) => AxiosRequestConfig
}
function getRandomElement(responseIds: string[]) {
  return responseIds[Math.floor(Math.random() * responseIds.length)]
}
/**
 * 获取返回responseId
 * @param config
 * @returns
 */
function getApifoxResponseId(config: MockConfig) {
  if (config.apifoxResponseId) {
    return config.apifoxResponseId
  }
  if (config.apifoxResponseIds) {
    return getRandomElement(config.apifoxResponseIds)
  }
  return undefined
}
function isPathMatch(path: string, paths: string[]): string | undefined {
  for (const key of paths) {
    const method = key.split(':')[0]
    const p = key.split(':')[1]
    if (new RegExp(method).test(path) && new RegExp(p).test(path)) {
      return key
    }
  }
}
export default function injectMockRequestInterceptors(axios: AxiosInstance, options: MockRequestInterceptorsOptions) {
  if (options.disable) {
    return
  }
  const mockUrlConfigKeys = Object.keys(options.mockUrlConfig)

  axios.interceptors.request.use((config) => {
    if (!config.url) {
      return config
    }
    const method = config.method?.toLowerCase() || 'get'
    const currentKey = `${method}:${config.url}`

    const key = isPathMatch(currentKey, mockUrlConfigKeys)
    if (!key) {
      return config
    }
    const mockConfig = options.mockUrlConfig[key]
    if (!mockConfig) {
      return config
    }
    config.url = options.mockServerBaseUrl + key
    config.params = {
      ...config.params,
      apifoxApiId: mockConfig.apifoxApiId,
      apifoxResponseId: getApifoxResponseId(mockConfig),
      apifoxMockCase: mockConfig.apifoxMockCases?.length ? getRandomElement(mockConfig.apifoxMockCases) : undefined,
    }

    console.warn(`[Mock Request] ${config.url}` + (config.params.apifoxResponseId ? ` with apifoxResponseId ${config.params.apifoxResponseId}` : ''))
    return options.afterHandle ? options.afterHandle(config) : config
  })
}
