import { ILoadingService, ISearchResult } from '@pharmsnap/shared/types'
import { defineComponent, PropType, watch } from '@vue/composition-api'
import { IQueryService } from '../../../composition/useQueryService'
import $classes from './BAnalysisChart.module.scss'
import { BPhaseCountChart } from './BPhaseCountChart/BPhaseCountChart'
import { BPieChart } from './BPieChart/BPieChart'
import { BTopChart } from './BTopChart/BTopChart'
import { BTopCountryChart } from './BTopCountryChart/BTopCountryChart'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BAnalysisChart = defineComponent({
  name: 'BAnalysisChart',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { sub_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props) {
    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter],
      async () => {
        props.loadingService.action.setLoading(true)
        const rt = await props.getTotalFn()
        props.loadingService.action.setTotal(rt.total) // 药物数量
        props.loadingService.action.setSubTotal(rt.sub_total) // 研发状态数量
        props.loadingService.action.setLoading(false)
      },
      {
        immediate: true,
      }
    )

    return {}
  },
  render() {
    return (
      <div class="h-full overflow-hidden overflow-y-auto">
        <BPhaseCountChart queryService={this.queryService} id="chart-1"></BPhaseCountChart>
        <vue-lazy-component threshold={'100px'} style={{ maxHeight: '800px', minHeight: '20px' }} id="chart-2">
          <BTopChart
            class="mt-4"
            header={this.$t('Analysis.Organization') as string}
            desc={this.$t('Analysis.OrgDesc') as string}
            field="org"
            queryService={this.queryService}
            title={this.$t('Analysis.Top_10_Org') as string}
          ></BTopChart>
        </vue-lazy-component>
        <div class={$classes.pieContainer} id="chart-3">
          <vue-lazy-component threshold={'100px'} style={{ maxHeight: '400px', minHeight: '20px' }} id="chart-4">
            <BPieChart
              seriesName={this.$t('Analysis.OrgType') as string}
              class={$classes.pie}
              title={this.$t('Analysis.OrgType') as string}
              desc={this.$t('Analysis.OrgTypeDesc') as string}
              aggregationField="entity_type"
              queryService={this.queryService}
              radius={['0', '65%']}
              total={this.loadingService.state.total}
            ></BPieChart>
            <BTopCountryChart
              class={$classes.pie}
              title={this.$t('Analysis.Top_5_Country') as string}
              desc={this.$t('Analysis.Top_5_Country_Desc') as string}
              aggregationField="country_id"
              queryService={this.queryService}
              limit={5}
              total={this.loadingService.state.total}
            ></BTopCountryChart>
          </vue-lazy-component>
        </div>
        <vue-lazy-component threshold={'100px'} style={{ maxHeight: '800px', minHeight: '20px' }} id="chart-5">
          <BTopChart
            class="mt-4"
            header={this.$t('Analysis.Target') as string}
            desc={this.$t('Analysis.TargetDesc') as string}
            field="target"
            queryService={this.queryService}
            title={this.$t('Analysis.Top_10_Target') as string}
          ></BTopChart>
        </vue-lazy-component>
        <vue-lazy-component threshold={'100px'} style={{ maxHeight: '800px', minHeight: '20px' }} id="chart-6">
          <BTopChart
            class="mt-4 mb-12"
            header={this.$t('Analysis.Indication') as string}
            desc={this.$t('Analysis.IndicationDesc') as string}
            field="disease"
            queryService={this.queryService}
            title={this.$t('Analysis.Top_10_Indication') as string}
          ></BTopChart>
        </vue-lazy-component>
        <vue-lazy-component threshold={'100px'} style={{ maxHeight: '800px', minHeight: '20px' }} id="chart-7">
          <BTopChart
            class="mt-4 mb-12"
            header={this.$t('Analysis.DrugType') as string}
            desc={this.$t('Analysis.DrugTypeDesc') as string}
            field="drugType"
            queryService={this.queryService}
            title={this.$t('Analysis.Top_10_DrugType') as string}
          ></BTopChart>
        </vue-lazy-component>
      </div>
    )
  },
})
