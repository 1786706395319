import { toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { ITopPatentCoAssignee } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType } from '@vue/composition-api'
import { TableOptions } from 'vxe-table'
import $style from '../../../style/Common.module.scss'
import { getAssigneeName } from '../util'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const FullTopAssigneeTable = defineComponent({
  name: 'FullTopAssigneeTable',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    chartData: {
      required: true,
      type: Object as PropType<ITopPatentCoAssignee>,
    },
  },
  setup(props) {
    const ins = getCurrentInstance()

    const { isCN } = useLocale()

    const columns = computed(() => {
      if (!props.chartData.items.length) return []
      const res = [{ field: 'assignee', title: ins?.proxy.$t('FullTopAssigneeTable.assignee') }]
      props.chartData.items.forEach((assignee, index) => {
        res.push({
          field: `coAssignee${index}`,
          title: ins?.proxy.$t('FullTopAssigneeTable.coAssigneeWithNo'),
        })
      })
      return res
    })

    const tableData = computed(() => {
      const res: any[] = []
      props.chartData.items &&
        props.chartData.items.forEach((assignee) => {
          const obj: { [key: string]: string | number } = {
            assignee: getAssigneeName(assignee, isCN.value),
          }
          if (assignee.items?.length) {
            assignee.items.forEach((coAssignee, index) => {
              obj[`coAssignee${index}`] = getAssigneeName(coAssignee, isCN.value)
              obj[`coAssignee${index}num`] = coAssignee.num ? toThousands(coAssignee.num) : 0
            })
          }
          res.push(obj)
        })
      return res
    })

    const vxeColumns = computed(() => {
      if (!tableData.value.length) return []
      const buildColumns: any[] = [
        {
          field: 'col0',
          fixed: 'left',
          minWidth: 130,
          showOverflow: false,
        },
      ]
      tableData.value.forEach((item, index) => {
        buildColumns.push({
          field: `col${index + 1}`,
          minWidth: 180,
          showOverflow: 'title',
        })
        buildColumns.push({
          field: `col${index + 1}-num`,
          minWidth: 50,
        })
      })
      return buildColumns
    })

    const gridOptions = computed(() => {
      const buildData = columns.value.map((column) => {
        const item: any = { col0: column.title }
        tableData.value.forEach((row, index) => {
          item[`col${index + 1}`] = row[column.field] || '-'
          item[`col${index + 1}-num`] = row[`${column.field}num`] || '-'
        })
        return item
      })

      const gridOptions: TableOptions = {
        border: true,
        showOverflow: false,
        showHeader: false,
        columns: [],
        data: [],
        size: 'small',
      }
      gridOptions.data = buildData
      gridOptions.columns = vxeColumns.value
      return gridOptions
    })

    const mergeCells = computed(() => {
      const cells = [{ row: 1, col: 0, rowspan: 10, colspan: 1 }]
      gridOptions.value.columns &&
        (gridOptions.value.columns as { field: string; minWidth: number }[]).forEach((item, index) => {
          if (index % 2 === 0) {
            cells.push({
              row: 0,
              col: index + 1,
              rowspan: 1,
              colspan: 2,
            })
          }
        })
      return cells
    })

    return {
      gridOptions,
      mergeCells,
    }
  },
  render() {
    return (
      <vxe-grid
        ref="commonListVxeGrid"
        class={$style.fullScreenTableWithSpan}
        mergeCells={this.mergeCells}
        {...{
          props: this.gridOptions,
        }}
      ></vxe-grid>
    )
  },
})
