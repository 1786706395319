export const enum E_ROUTER_NAME {
  HOME = 'HOME',
  HOME_SEARCH_RESULT = 'HOME_SEARCH_RESULT',
  HOME_QUICK_SEARCH_RESULT = 'HOME_QUICK_SEARCH_RESULT',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  PLANS = 'PLANS',
  USAGE = 'USAGE',
  SECURITY_SETTING = 'SecuritySetting',
  COMPLETE_INFO = 'COMPLETE_INFO',
  BOARDING = 'BOARDING',
  WECHAT_BIND = 'WECHAT_BIND',
  ERROR_PAGE = 'ERROR_PAGE',
  ERROR_PAGE_404 = 'ERROR_PAGE_404',
  READ_FAST = 'READ_FAST',
  FORGET = 'FORGET',
  WELCOME = 'WELCOME',
  DRUG = 'DRUG',
  DRUG_OVERVIEW = 'DRUG_OVERVIEW',
  DRUG_RESEARCH = 'DRUG_RESEARCH',
  DRUG_TRIAL = 'DRUG_TRIAL',
  DRUG_APPROVAL = 'DRUG_APPROVAL',
  DRUG_LIST = 'DRUG_LIST',
  JUMP_HOST = 'JUMP_HOST',
  DRUG_GROUP_BY_DEV_ORG = 'DRUG_GROUP_BY_DEV_ORG',
  DRUG_GROUP_BY_ORI_DEV_ORG = 'DRUG_GROUP_BY_ORI_DEV_ORG',
  DRUG_GROUP_BY_ORG = 'DRUG_GROUP_BY_ORG',
  DRUG_GROUP_BY_DEV_STATUS = 'DRUG_GROUP_BY_DEV_STATUS',
  DRUG_GROUP_BY_TARGET = 'DRUG_GROUP_BY_TARGET',
  DRUG_GROUP_BY_DISEASE = 'DRUG_GROUP_BY_DISEASE',
  DRUG_GROUP_BY_DRUG_TYPE = 'DRUG_GROUP_BY_DRUG_TYPE',
  PATENT_FINDER = 'PATENT_FINDER',
  PATENT_LIST = 'PATENT_LIST',
  PATENT_DETAIL = 'PATENT_DETAIL',
  TARGET = 'TARGET',
  TARGET_OVERVIEW = 'TARGET_OVERVIEW',
  DISEASE = 'DISEASE',
  DISEASE_OVERVIEW = 'DISEASE_OVERVIEW',
  DRUG_TYPE_OVERVIEW = 'DRUG_TYPE_OVERVIEW',
  DRUG_TYPE = 'DRUG_TYPE',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_OVERVIEW = 'ORGANIZATION_OVERVIEW',
  ORGANIZATION_PIPELINE = 'ORGANIZATION_PIPELINE',
  ORGANIZATION_FINANCIAL = 'ORGANIZATION_FINANCIAL',
  ORGANIZATION_FUNDING = 'ORGANIZATION_FUNDING',
  ORGANIZATION_INVESTMENT = 'ORGANIZATION_INVESTMENT',
  ORGANIZATION_FINANCING = 'ORGANIZATION_FINANCING',
  DRUG_FINDER = 'DRUG_FINDER',
  COMPETITIVE_LANDSCAPE = 'COMPETITIVE_LANDSCAPE',
  COMPETITIVE_TARGET_OVERVIEW = 'COMPETITIVE_TARGET_OVERVIEW',
  COMPETITIVE_INDICATION_OVERVIEW = 'COMPETITIVE_INDICATION_OVERVIEW',
  CLINICAL_FINDER = 'CLINICAL_FINDER',
  CLINICAL_RESULT_FINDER = 'CLINICAL_RESULT_FINDER',
  CLINICAL_PROGRESS_LIST = 'CLINICAL_PROGRESS_LIST',
  CLINICAL_PROGRESS_DETAIL = 'CLINICAL_PROGRESS_DETAIL',
  CLINICAL_RESULT_DETAIL = 'CLINICAL_RESULT_DETAIL',
  CLINICAL_RESULT_LIST = 'CLINICAL_RESULT_LIST',
  DRUG_DEAL_LIST = 'DRUG_DEAL_LIST',
  DRUG_DEAL_GROUP_BY_DRUG = 'DRUG_DEAL_GROUP_BY_DRUG',
  LATEST_NEWS_LIST = 'LATEST_NEWS_LIST',
  LATEST_NEWS_HOME = 'LATEST_NEWS_HOME',
  LATEST_NEWS_DETAIL = 'LATEST_NEWS_DETAIL',
  REPORT_LIST = 'REPORT_LIST',
  REPORT_DETAIL = 'REPORT_DETAIL',
  REPORT_PREVIEW = 'REPORT_PREVIEW',
  LITERATURE = 'LITERATURE',
  LITERATURE_FINDER = 'LITERATURE_FINDER',
  LITERATURE_LIST = 'LITERATURE_LIST',
  LITERATURE_DETAIL = 'LITERATURE_DETAIL',
  DRUG_DEAL_FINDER = 'DRUG_DEAL_FINDER',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_DETAIL = 'DASHBOARD_DETAIL',
  DASHBOARD_TPL_DETAIL = 'DASHBOARD_TPL_DETAIL',
  EMAIL_ALERT = 'EMAIL_ALERT',
  EMAIL_ALERT_DETAIL = 'EMAIL_ALERT_DETAIL',
  NEW_EMAIL_ALERT = 'NEW_EMAIL_ALERT',
  SEARCH_HISTORY = 'SEARCH_HISTORY',
  LINK = 'LINK',
  DATA_COVERAGE = 'DATA_COVERAGE',
  COMPARE_DRUG = 'COMPARE_DRUG',
  COMPARE_ORGANIZATION = 'COMPARE_ORGANIZATION',
  COMPARE_TARGET = 'COMPARE_TARGET',
  COMPARE_INDICATION = 'COMPARE_INDICATION',
  KNOWLEDGE_EXPLORE = 'KNOWLEDGE_EXPLORE',
  KNOWLEDGE_GRAPH = 'KNOWLEDGE_GRAPH',
  EXPORT = 'EXPORT',
  /**
   * 因为埋点的RouteName需要格式统一新增的枚举
   */
  DRUG_DETAIL = 'DRUG_DETAIL',
  CLINICAL_DETAIL = 'CLINICAL_DETAIL',
  DISEASE_DETAIL = 'DISEASE_DETAIL',
  TARGET_DETAIL = 'TARGET_DETAIL',
  DRUG_TYPE_DETAIL = 'DRUG_TYPE_DETAIL',
  ORGANIZATION_DETAIL = 'ORGANIZATION_DETAIL',
  KNOWLEDGE_GRAPH_DETAIL = 'KNOWLEDGE_GRAPH_DETAIL',
  TRANSLATIONAL_MEDICINE_LIST = 'TRANSLATIONAL_MEDICINE_LIST',
  TRANSLATIONAL_MEDICINE_FINDER = 'TRANSLATIONAL_MEDICINE_FINDER',
}

export const enum E_ROUTER_PARAMS {
  DRUG_ID = 'id',
  PATENT_ID = 'id',
  TARGET_ID = 'id',
  DISEASE_ID = 'id',
  DRUG_TYPE_ID = 'id',
  ORG_ID = 'id',
  DASHBOARD_ID = 'id',
  DASHBOARD_TPL_ID = 'id',
  CLINICAL_TRIAL_ID = 'id',
  CLINICAL_RESULT_ID = 'id',
  HOME_ID = 'id',
  LATEST_NEWS_ID = 'id',
  LITERATURE_ID = 'id',
  KNOWLEDGE_GRAPH_ID = 'id',
  REPORT_ID = 'id',
  EMAIL_ALERT_ID = 'id',
}
export const enum E_ROUTER_QUERY {
  LOGIN_FROM = 'login_from',
  QUERY = 'query',
  QUERY_ID = 'query_id',
  TRACKING = 'tracking',
  /**
   * query的类型, type的值可以为空和"alert"
   */
  QUERY_TYPE = 'query_type',
  /**
   *额外的query
   */
  EXTRA_QUERY = 'extra_query',
  FROM_QUERY_ID = 'from_query_id',
  // from_query_id 的base64表示
  FROM_QUERY = 'from_query',
  NOT_READ_STORE = 'not_read_store',

  PAGE = 'page',
  PAGE_SIZE = 'page_size',
  KEYWORD = 'keyword',
  // dashboard 列表类型 template | dashboard
  DASHBOARD_LIST_TYPE = 'list_type',
  TRANSLATE = 'translate',
  SEARCH_HISTORY_LIST_TYPE = 'history_type',
  OFFSET = 'offset',
  SOURCE_TYPE = 'source_type',
  SOURCE = 'source',
  SORT = 'sort',
  ALERT_TYPE = 'alert_type',
  COMPARE_DATA = 'compare_data',
  DEV_STATUS_DATA = 'dev_status_data',
  ERROR_CODE = 'error_code',
  // 收支明细 列表类型
  INCOME_EXPENSES_TYPE = 'coin_type',
  REDIRECT = 'redirect',
  FROM_PAGE = 'from',
  TARGET_NAME = 'target_name',
  QUERY_DATA = 'query_data',
  COPILOT_COMMAND = 'copilot_command',
  TAB = 'tab',
  UPDATE_TYPE = 'update_type',
  // Drug Finder search tab 类型
  DRUG_FINDER_QUERY_TYPE = 'query_type',
  ACTIVE_MENU = 'active_menu',
  /**
   * 专利的折叠设置模式
   * user_setting: 表示使用用户自定义设置
   */
  PATENT_COLLAPSE_MODE = 'patent_collapse_mode',
}

/**
 * 左侧可高亮的nav枚举值
 */
export enum E_NAV_KEY {
  HOMEPAGE = 'homepage',
  DRUG_FINDER = 'drug-finder',
  SEARCH = 'search',
  COMPETITIVE_LANDSCAPE = 'competitive-landscape',
  CLINICAL = 'clinical-progress',
  CLINICAL_TRIAL_FINDER = 'clinical-trial-finder',
  CLINICAL_RESULT_FINDER = 'clinical-result-finder',
  NEWS_HOME = 'news-home',
  LITERATURE = 'literature-finder',
  PATENT = 'patent-finder',
  DASHBOARD = 'dashboard',
  EMAIL_ALERT = 'email-alert',
  SEARCH_HISTORY = 'search-history',
  PLAYBOOKS = 'playbooks',
  ORGANIZATION_DUE_DILIGENCE = 'organization-due-diligence',
  DRUG_INSIGHT = 'drug-insight',
  FIND_ORGANIZATION = 'find-organization',
  DRUG_CORE_PATENT = 'drug-core-patent',
  ENTITY_COMPARE = 'entity-compare',
  DRUG_COMPARE = 'drug-compare',
  ORGANIZATION_COMPARE = 'organization-compare',
  TARGET_COMPARE = 'target-compare',
  INDICATION_COMPARE = 'indication-compare',
  FIND_RELATIONSHIPS = 'find-relationships',
  KNOWLEDGE_GRAPH = 'knowledge-graph',
  REPORT_LIST = 'report-list',
  DRUG_DEAL_FINDER = 'drug-deal-finder',
  DRUG_DEAL = 'drug-deal',
  WORKSPACE = 'workspace',
  TRANSLATIONAL_MEDICINE_FINDER = 'translational-medicine-finder',
}

export enum EN_WIDE_STATUS {
  WIDE = 'wide',
  NOT_WIDE = 'notWide',
}

export enum E_REPORT_TYPE {
  MONTHLY_REPORT = 'MONTHLY_REPORT',
  SPECIAL_REPORT = 'SPECIAL_REPORT',
}

export enum E_UPGRADE_TYPE {
  // A类 - 用量触发限制 - 搜索次数和搜索结果限制
  EXCEEDS_SEARCH_MAX_LIMIT = 'EXCEEDS_SEARCH_MAX_LIMIT',
  // A类 - 用量触发限制 - (自定义分析、实体对比、邮件提醒)
  EXCEEDS_FEATURE_MAX_LIMIT = 'EXCEEDS_FEATURE_MAX_LIMIT',
  // A类 - 用量触发限制 - 集团导出用量，使用次数达到上限
  GROUP_EXCEEDS_EXPORT_MAX_LIMIT = 'GROUP_EXCEEDS_EXPORT_MAX_LIMIT',
  // A 类 - 用量触发限制 - synapse chat，免费用户达到上限
  CHAT_EXCEEDS_MAX_LIMIT = 'CHAT_EXCEEDS_MAX_LIMIT',
  // B类 - 非用量限制的付费功能，但用户容易理解（导出、下钻...）
  USE_PAID_FEATURE_NORMAL = 'USE_PAID_FEATURE_NORMAL',
  // C类 - 非用量限制，用户难以理解 (ps: 需要添加GIF图)
  USE_PAID_FEATURE_KG = 'USE_PAID_FEATURE_KG', // KG
  USE_PAID_FEATURE_ADVANCE_FILTER = 'USE_PAID_FEATURE_ADVANCE_FILTER', // 高级过滤
  USE_PAID_FEATURE_GROUP_BY = 'USE_PAID_FEATURE_GROUP_BY', // 调整维度
  USE_PAID_FEATURE_WORKSPACE = 'USE_PAID_FEATURE_WORKSPACE', // 工作空间
  USE_PAID_FEATURE_INVESTMENT = 'USE_PAID_FEATURE_INVESTMENT', // 投资
  USE_PAID_FEATURE_FINANCING = 'USE_PAID_FEATURE_FINANCING', // 融资
  USE_PAID_FEATURE_COMPANY = 'USE_PAID_FEATURE_COMPANY', // 企业架构

  // D类 - 国内如信息齐全则直接联系销售否则显示不全信息弹框，国外都先显示弹框
  CONTACT_SALES = 'CONTACT_SALES',
}
