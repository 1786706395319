import { defineComponent, PropType } from '@vue/composition-api'
import { GMiniSwitch } from '../../../../ui/GMiniSwitch/GMiniSwitch'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'

export const BAnalysisChartRollupSwitch = defineComponent({
  name: 'BAnalysisChartRollupSwitch',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
    },
    text: {
      type: String,
    },
  },
  render() {
    return (
      <GMiniSwitch
        size="large"
        value={this.value}
        width={16}
        active-color="#1976D2"
        inactive-color="#BCC2CC"
        onInput={(val: boolean) => this.$emit('input', val)}
        active-text={this.text || this.$t('analysisChart.orgRollup')}
      ></GMiniSwitch>
    )
  },
})
