import { defineComponent, inject, onBeforeUnmount, onMounted, ref, toRefs } from '@vue/composition-api'
import { anchorScrollNextInjectKey } from './config'

export const GAnchorScrollLink = defineComponent({
  name: 'GAnchorScrollLink',
  props: {
    id: {
      type: String,
      required: true,
    },
    scrollMarginTop: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { id } = toRefs(props)

    const linkWrapper = ref<HTMLDivElement | null>(null)

    const injectData = inject(anchorScrollNextInjectKey)

    onMounted(() => {
      linkWrapper.value &&
        injectData?.registerAnchor({
          id: id.value,
          el: linkWrapper.value,
        })
    })

    onBeforeUnmount(() => {
      injectData?.unregisterAnchor(id.value)
    })

    return {
      linkWrapper,
    }
  },
  render() {
    return (
      <div ref="linkWrapper" id={this.id} style={{ 'scroll-margin-top': `${this.scrollMarginTop}px` }}>
        {this.$slots.default}
      </div>
    )
  },
})
