import { ITargetAutoCompleteItem, ITargetListInfo } from '@patsnap/synapse_common_interface'
import { decryptTarget } from '@pharmsnap/shared/utils'
import { transformTargetName } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import DataLoader from 'dataloader'
import {
  IAutoCompleteParams,
  IBaseTargetInfo,
  IEventTypeItem,
  IMechanismData,
  ISearchResult,
  ISearchTreeParams,
  ISearchTreeResult,
  ITargetCardInfo,
  ITreeAggregationItem,
  ITreeAggregationParams,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class TargetApi extends BaseApi {
  private batchGetTargetsLoader = new DataLoader((ids: readonly string[]) => this._getBatchTargets(ids))
  private batchGetMultiTargetsLoader = new DataLoader((ids: readonly string[]) => this._getBatchMultiTargets(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getCardInfo(id: string): Promise<IHttpWrap<ITargetCardInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/target/card/${id}`),
      cache: true,
    })
  }
  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<ITargetAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken, type } = params
    return this.http({
      method: 'GET',
      url: this.getUrl(`discoveryls_common/1.0/target/autocomplete`),
      params: {
        prefix: keywords,
        limit,
        ...(type
          ? {
              type,
            }
          : {}),
      },
      cache: true,
      cancelToken,
    })
  }
  async _getBatchTargets(ids: readonly string[]): Promise<Array<IBaseTargetInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/target/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseTargetInfo>>
    if (rt.success && rt.data) {
      return ids.map((id) => {
        const res = rt.data.items?.find((item) => item.target_id === id)
        if (res && rt.xCorrelationId) {
          decryptTarget(res, rt.xCorrelationId)
          res.fontClassName = rt.fontClassName
        }
        return res
      })
    }
    return []
  }
  async _getBatchMultiTargets(ids: readonly string[]): Promise<Array<IBaseTargetInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/target/batch/multi'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseTargetInfo>>
    if (rt.success && rt.data) {
      return ids.map((id) => {
        const res = rt.data.items?.find((item) => item.target_id === id)
        if (res && rt.xCorrelationId) {
          decryptTarget(res, rt.xCorrelationId)
          res.fontClassName = rt.fontClassName
        }
        return res
      })
    }
    return []
  }

  async getBatchMultiTargets(ids: readonly string[]): Promise<IHttpWrap<IBaseTargetInfo[]>> {
    const rt = await this.batchGetMultiTargetsLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBaseTargetInfo[],
      xCorrelationId: '',
    }
  }

  async getBatchTargets(ids: readonly string[]): Promise<IHttpWrap<IBaseTargetInfo[]>> {
    const rt = await this.batchGetTargetsLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBaseTargetInfo[],
      xCorrelationId: '',
    }
  }

  async getBatchSingleTargetByMultiTarget(ids: string[]): Promise<IHttpWrap<{ target_mapping: Record<string, IBaseTargetInfo[]> }>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/target/batch_mapping'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<{ target_mapping: Record<string, IBaseTargetInfo[]> }>
    if (rt.success && rt.data) {
      const keys = Object.keys(rt.data.target_mapping)
      keys.forEach((key) => {
        rt.data.target_mapping[key].forEach((target) => {
          decryptTarget(target, rt.xCorrelationId)
          target.fontClassName = rt.fontClassName
        })
      })
    }

    return rt
  }

  async getBasicInfo(id: string): Promise<IHttpWrap<IBaseTargetInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/target/${id}`),
      cache: true,
    })
  }

  async getMechanismAction(targetId: string): Promise<IHttpWrap<IMechanismData[]>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/target/${targetId}/mechanismAction`),
      method: 'GET',
      cache: true,
    })
  }

  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/target/tree/search`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getAdvanceTreeAggregation(params: ITreeAggregationParams): Promise<IHttpWrap<ISearchResult<ITreeAggregationItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/target/aggregation/tree'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformTargetName(rt))
  }

  async getTargetEventTypeTemplate(): Promise<IHttpWrap<IEventTypeItem[]>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/target/event_template`),
      method: 'GET',
      cache: true,
    })
  }

  async getBatchUnencrypt(ids: readonly string[]): Promise<IHttpWrap<ITargetListInfo>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/target/batchInfo/${ids.join(',')}`),
      method: 'GET',
      cache: true,
    })
  }

  async getTargetCardData({ id }: { id: string }) {
    return this.getCardInfo(id)
  }
}
