/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 列表页导出按钮组件
 * 需求文档链接: https://confluence.zhihuiya.com/pages/viewpage.action?pageId=119638691
 * 设计稿: https://lanhuapp.com/url/S8n42-AMqPas
 */
import '@patsnap-biz/select-menu/lib/style'
import '@patsnap-ui/icon/assets/solid/export.svg'
import { IQueryDataType } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { GIcon, GTooltip } from '@pharmsnap/shared/components'
import { useAuthStore, useExportDrug, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { PropType, computed, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'
import { ICommonListConfig } from '../../types'
import $class from './BExport.module.scss'
export const BExport = defineComponent({
  name: 'BExport',
  i18n: {
    messages: { cn, en },
  },
  props: {
    checkedMap: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    commonListConfig: {
      type: Object as PropType<ICommonListConfig>,
      required: true,
    },
    searchAllLength: {
      type: Number,
      default: 1000,
    },
    allowExportMaxLength: {
      type: Number,
      default: 300,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    // 展示用户头像和邮箱地址
    showUserInfo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否检测 用户的权限
    isCheckJurisdiction: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const { localeUpcase } = useLocale()
    const {
      state: { usageInfo },
      getters: {
        isBasicUser,
        isEnterpriseUser,
        patentExportLimit,
        drugExportLimit,
        drugDealExportLimit,
        clinicalTrialExportLimit,
        clinicalTrialResultExportLimit,
        translationalMedicineExportLimit,
        paperExportLimit,
      },
      actions,
    } = useAuthStore()
    actions.syncUserUsage()
    const { handleExportDrug } = useExportDrug()
    const checkedIdList = computed(() => {
      return Object.keys(props.checkedMap).reduce((acc, curr) => (props.checkedMap[curr] ? [...acc, curr] : acc), [] as string[])
    })

    /**
     * 导出限制配置
     * 未返回count：无限制
     * 返回0：不支持
     * 返回count：限制数量
     */
    const limitPreExportConfig = computed(() => {
      const fieldMap: Partial<Record<IQueryDataType, number | undefined>> = {
        patent: patentExportLimit.value,
        drug: drugExportLimit.value,
        drug_deal: drugDealExportLimit.value,
        organization: 0,
        target: 0,
        disease: 0,
        clinical_trial: clinicalTrialExportLimit.value,
        clinical_trial_result: clinicalTrialResultExportLimit.value,
        news: 0,
        paper: paperExportLimit.value,
        funding_round: 0,
        funded_research: 0,
        drug_type: 0,
        all: 0,
        region: 0,
        original_dev_organization: 0,
        dev_organization: 0,
        translational_medicine: translationalMedicineExportLimit.value,
      }
      return fieldMap
    })

    const limitPreExport = computed(() => {
      const preExportCount = props.commonListConfig.dataType ? limitPreExportConfig.value[props.commonListConfig.dataType] : 0

      if (isUndefined(preExportCount)) return props.searchAllLength

      return preExportCount
    })

    const showLimitPreExportTip = computed(() => {
      const preExportCount = props.commonListConfig.dataType ? limitPreExportConfig.value[props.commonListConfig.dataType] : 0
      return !isUndefined(preExportCount)
    })

    const exportLength = computed(() => {
      return checkedIdList.value.length ? checkedIdList.value.length : props.searchAllLength
    })
    /**
     * 检测是否拥有 导出 权限 付费(基础)用户true,其他用户false
     * @returns
     */
    function checkExportJurisdiction(): boolean {
      return isBasicUser.value || isEnterpriseUser.value
    }

    /**
     * 导出触发的回调
     * @returns
     */
    async function onClickExport() {
      if (props.isCheckJurisdiction && !checkExportJurisdiction()) {
        actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD' })
        return
      }
      handleExportDrug({
        disable: props.disabled,
        showUpgrade: props.isCheckJurisdiction && !checkExportJurisdiction(),
        dataType: props.queryService.state.data_type,
        lang: localeUpcase.value,
        checkedIdList: checkedIdList.value,
        commonListConfig: props.commonListConfig,
        checkedMap: props.checkedMap,
        queryService: props.queryService,
        resultExportLength: exportLength.value,
        limitPreExport: limitPreExport.value || 0,
        showLimitPreExportTip: showLimitPreExportTip.value,
      })
    }

    return {
      usageInfo,
      limitPreExport,
      checkedIdList,
      exportLength,
      onClickExport,
      isBasicUser,
      isEnterpriseUser,
    }
  },
  methods: {
    renderContent() {
      return (
        <div
          class={['inline-flex cursor-pointer items-center text-sm hover:bg-gray-30', this.disabled ? 'text-gray-55 cursor-not-allowed' : '']}
          data-testid="b-export"
          onClick={this.onClickExport}
        >
          <GIcon svgName="SolidExport" class={this.disabled ? 'text-gray-55' : 'text-gray-450'} size={20}></GIcon>
          {this.showText ? <span class={$class.titleText}>{this.$tc('export.export')}</span> : null}
        </div>
      )
    },
  },
  render() {
    const ele = this.renderContent() as JSX.Element
    return (this.isBasicUser || this.isEnterpriseUser) && !this.disabled && (this.limitPreExport as number) > 0 ? (
      <GTooltip placement="top" content={this.$t('export.exportToolTip', { limit: toThousands(this.limitPreExport) }) as string}>
        {ele}
      </GTooltip>
    ) : (
      ele
    )
  },
})
