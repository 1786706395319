import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { getChartWaterMark } from '@pharmsnap/shared/src/utils'
import { defineComponent } from '@vue/composition-api'
export const BAnalysisWaterMarkContainer = defineComponent({
  name: 'BAnalysisWaterMarkContainer',
  props: {},
  setup(props) {
    const {
      getters: { isFreeUser, isTrialUser },
    } = useAuthStore()
    const { isCN } = useLocale()

    const showWaterMark = isFreeUser.value || isTrialUser.value

    return {
      isCN,
      showWaterMark,
    }
  },
  methods: {
    renderWaterMark() {
      if (this.showWaterMark) {
        const { waterMark, imgHeight, imgWidth } = getChartWaterMark(this.isCN)

        return (
          <img
            src={waterMark}
            class="absolute z-[1] top-[50%] left-[50%] pointer-events-none object-contain"
            style={{ width: `${imgWidth}px`, height: `${imgHeight}px`, transform: 'translate(-50%, -50%)' }}
            alt=""
          />
        )
      }
      return null
    },
  },
  render() {
    return (
      <div class="relative w-full h-full">
        {this.$slots.default}
        {this.renderWaterMark()}
      </div>
    )
  },
})
