import { ref } from '@vue/composition-api'
import { throttle } from 'lodash'

export const useMouseMove = (MOUSE_MOVE_TIMEOUT = 20 * 1000) => {
  const hasNotMovedForAWhile = ref(false)
  let mouseMoveTimer: NodeJS.Timeout

  const mouseMoveHandler = () => {
    hasNotMovedForAWhile.value = false
    if (mouseMoveTimer) clearTimeout(mouseMoveTimer)
    mouseMoveTimer = setTimeout(() => {
      hasNotMovedForAWhile.value = true
    }, MOUSE_MOVE_TIMEOUT)
  }
  const throttledMousemoveHandler = throttle(mouseMoveHandler, 1000)
  document.addEventListener('mousemove', throttledMousemoveHandler)
  const removeListener = () => {
    if (mouseMoveTimer) clearTimeout(mouseMoveTimer)
    document.removeEventListener('mousemove', throttledMousemoveHandler)
  }
  return { hasNotMovedForAWhile, removeListener }
}
