/**
 * 需求链接: https://patsnap.atlassian.net/browse/PDLS-24581
 */
import { generate_correlation_nanoid, generate_correlation_salt, generate_correlation_sign } from '@patsnap/synapse_encryption'
import { AxiosError, AxiosInstance } from 'axios'
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { showSingleToast } from 'pharmsnapMF_shared/utils'
import { getLocaleLang } from '../utils/business/locale'
const xCorrelationIdKey = 'x-correlation-id'
const xFromKey = 'x-patsnap-from'
const xSignVersion = 'x-sign-version'
const timeOffsetStoreKey = 'pharmsnap/time-offset'
/**
 * 最大触发错误的次数
 */
const maxErrorTimes = 20
/**
 * 已经触发错误的次数
 */
let triggerErrorTimes = 0
/**
 * 签名已经过期的状态码
 * 文档地址: https://confluence.zhihuiya.com/display/DLS/Error+Codes+-+s-pharmsnap-webapi
 */
const SIGN_EXPIRE = [130083, 130084]

/**
 * 请求认证签名拦截器
 * @param axiosIns
 */
export function interceptAxiosWithSign(axiosIns: AxiosInstance) {
  axiosIns.interceptors.request.use((config) => {
    const ts = getNow() + getRequestTimeOffset()
    const xCorrelationId = generate_correlation_nanoid()
    const salt = generate_correlation_salt(ts.toString())
    const sign = generate_correlation_sign(ts.toString(), salt, xCorrelationId)

    config.headers = {
      ...config.headers,
      ts,
      salt,
      sign: sign,
      [xCorrelationIdKey]: xCorrelationId,
      [xFromKey]: 'w-pharmsnap-web',
      [xSignVersion]: '1.0',
    }
    return config
  })
  createAuthRefreshInterceptor(axiosIns, refreshLogic, {
    shouldRefresh: (error) => error.response?.status === 400 && SIGN_EXPIRE.includes(error?.response?.data?.numeric_error_code),
  })
  /**
   * 修复时间偏移量并重发业务请求
   * @param failedRequest
   * @returns
   */
  async function refreshLogic(failedRequest: AxiosError) {
    if (SIGN_EXPIRE.includes(failedRequest?.response?.data?.numeric_error_code)) {
      triggerErrorTimes++
      // 针对反复不通过的，可以5次之后有一个兜底的提示
      if (triggerErrorTimes >= maxErrorTimes) {
        showSingleToast({
          message:
            getLocaleLang() === 'cn'
              ? `当前您的系统存在异常，请尝试使用其他电脑以便正常访问。`
              : 'There is currently an exception on your system, please try using another computer for normal access.',
          type: 'error',
        })
        return failedRequest
      }
      const timestamp = failedRequest?.response?.data?.timestamp || 0
      setRequestTimeOffset(`${timestamp - getNow()}`)
      return axiosIns.request({ ...failedRequest.config, skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig)
    }
    return failedRequest
  }
}
/**
 * 获取当前的系统的时间
 * @returns
 */
function getNow() {
  return Date.now()
}
/**
 * 获取时间偏差
 * @returns
 */
function getRequestTimeOffset(): number {
  try {
    const stringValue = localStorage.getItem(timeOffsetStoreKey)
    if (!stringValue) {
      return 0
    }
    return Number(stringValue)
  } catch (error) {
    return 0
  }
}
/**
 * 设置时间偏差
 * @param value
 */
function setRequestTimeOffset(value: string) {
  localStorage.setItem(timeOffsetStoreKey, value)
}
