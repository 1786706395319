import { ALL_DEV_STATUS_MAP, ALL_SUBJECT_CONFIG_MAP, CT_FINDER_STUDY_STATUS_IDS } from '@patsnap/synapse_common_config'
import '@pharmsnap/shared/src/assets/icon-svg/fire.svg'
import { IAutoCompleteType } from '@pharmsnap/shared/types'
import { DataTagKey, ICustomDateKey, ICustomNumberRangeKey } from './type'
export interface IHardData {
  name_en: string
  name_cn: string
  value: string
  icon?: string
}
const drugHighestPhaseIds = [
  '8666aad5477e4272b56b94ca1183781d',
  'b2ca28b01c34419db0365bad2f8dd09e',
  '3da2ce3ce8b0443fb0b2d335be3e591a',
  'e6438687ed214800bb98742ebbeb70a1',
  '91ac63db1def4da58e890aec574fe7f4',
  'e2c11c24a7ee4b4e81bbde9eb7127c6d',
  '192e487c57e24234b7562b7eafaeb8b7',
  '7b79728539354f189772a4eab9cc9fff',
  '9f31233bceb94d9796d3f3556f17ab13',
  'f835774c48404593b64ef05369eb8eba',
  '78c45f1d523646808032edcff807ca59',
  'efd6215835394b07b046125e3175d3fe',
  '055a60cf40bb4b4eb69c41b492c45a03',
  '4d95bf5fb0c340fbb38cda626c1c9c60',
  'a5183ad464de4fe9bb9a3583425ae4a2',
  'e6a367db1b4a4819bcc16b76dd26d5f3',
  'cf139751c1fd4a5892c786230e6f6b6a',
  'c9e8fce7eb6b4bbd9cc66dc356017aab',
]
function getDevStatusHardData(devStatusId: string): IHardData {
  const config = ALL_DEV_STATUS_MAP[devStatusId]
  return {
    name_en: config.name_en,
    name_cn: config.name_cn,
    value: devStatusId,
  }
}
export const tagData: Partial<Record<DataTagKey, IHardData[]>> = {
  highestPhaseItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    ...drugHighestPhaseIds.map(getDevStatusHardData),
  ],
  phaseItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    ...drugHighestPhaseIds.map(getDevStatusHardData),
  ],
  dealCountryItems: [
    {
      name_en: 'Global',
      value: 'All',
      name_cn: '全球',
    },
    {
      name_en: 'US',
      name_cn: '美国',
      value: 'US',
    },
    {
      name_en: 'JP',
      name_cn: '日本',
      value: 'JP',
    },
    {
      name_en: 'CN',
      name_cn: '中国',
      value: 'CN',
    },
    {
      name_en: 'EUR',
      name_cn: '欧洲',
      value: 'EU',
    },
    {
      name_en: 'Other',
      name_cn: '其他',
      value: 'Other',
    },
  ],
  dealDirectionItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'Domestic only',
      name_cn: '仅境内',
      value: '5c672a2f1a0344ca83d08f710cbc5117',
    },
    {
      name_en: 'Overseas only',
      name_cn: '仅境外',
      value: '9226fe7163f848c98e40ff344f227646',
    },
    {
      name_en: 'From domestic to overseas',
      name_cn: '境内转境外',
      value: '4a71dbaf116c46a9a78ad6883c726001',
    },
    {
      name_en: 'From overseas to domestic',
      name_cn: '境外转境内',
      value: '5604e4327b4c4292af5b0ecb535ffb10',
    },
  ],
  dealPartnerPropertyItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'US',
      name_cn: '美国',
      value: 'US',
    },
    {
      name_en: 'CN',
      name_cn: '中国',
      value: 'CN',
    },
    {
      name_en: 'EUR',
      name_cn: '欧洲',
      value: 'EUR',
    },
    {
      name_en: 'JP',
      name_cn: '日本',
      value: 'JP',
    },
    {
      name_en: 'Outside US',
      name_cn: '美国外',
      value: 'Outside US',
    },
    {
      name_en: 'Outside CN',
      name_cn: '中国外',
      value: 'Outside CN',
    },
    {
      name_en: 'Outside EUR',
      name_cn: '欧洲外',
      value: 'Outside EUR',
    },
    {
      name_en: 'Outside JP',
      name_cn: '日本外',
      value: 'Outside JP',
    },
  ],
  dealPrinciplePropertyItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'US',
      name_cn: '美国',
      value: 'US',
    },
    {
      name_en: 'CN',
      name_cn: '中国',
      value: 'CN',
    },
    {
      name_en: 'EUR',
      name_cn: '欧洲',
      value: 'EUR',
    },
    {
      name_en: 'JP',
      name_cn: '日本',
      value: 'JP',
    },
    {
      name_en: 'Outside US',
      name_cn: '美国外',
      value: 'Outside US',
    },
    {
      name_en: 'Outside CN',
      name_cn: '中国外',
      value: 'Outside CN',
    },
    {
      name_en: 'Outside EUR',
      name_cn: '欧洲外',
      value: 'Outside EUR',
    },
    {
      name_en: 'Outside JP',
      name_cn: '日本外',
      value: 'Outside JP',
    },
  ],
  approvedCountryItems: [
    {
      name_en: 'Global',
      value: 'All',
      name_cn: '全球',
    },
    {
      name_en: 'United States',
      name_cn: '美国',
      value: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
    },
    {
      name_en: 'European Union',
      name_cn: '欧盟',
      value: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
    },
    {
      name_en: 'Japan',
      name_cn: '日本',
      value: '676de5ba-893a-36bb-8683-c93306aaf769',
    },
    {
      name_en: 'China',
      name_cn: '中国',
      value: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
    },
    {
      name_en: 'Other',
      name_cn: '其他',
      value: 'Other',
    },
  ],
  organizationTypeItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: 'Company',
      value: 'Company',
      name_cn: '公司',
    },
    {
      name_en: 'University/Institution',
      value: 'Education',
      name_cn: '大学/机构',
    },
    {
      name_en: 'Other',
      value: 'Other',
      name_cn: '其他',
    },
  ],
  // 修改 employeeNumberItems 时需要同时修改 function employNumScope
  employeeNumberItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: '<10',
      value: '1',
      name_cn: '<10',
    },
    {
      name_en: '10~100',
      value: '2',
      name_cn: '10~100',
    },
    {
      name_en: '100~500',
      value: '3',
      name_cn: '100~500',
    },
    {
      name_en: '>500',
      value: '4',
      name_cn: '>500',
    },
  ],
  startupItems: [
    {
      name_en: 'Startups Only',
      value: 'true',
      name_cn: '初创公司',
    },
  ],
  sponsorCountryItems: [
    {
      name_en: 'Global',
      value: 'All',
      name_cn: '全球',
    },
    {
      name_en: 'Asia',
      name_cn: '亚洲',
      value: '154a6734-0e8c-34dd-9253-dc4ff6120197',
    },
    {
      name_en: 'North America',
      name_cn: '北美洲',
      value: '5ffec2d8-7ab5-3820-af8b-549af380913a',
    },
    {
      name_en: 'Europe',
      name_cn: '欧洲',
      value: '912d59cd-f1d3-3551-bae2-1f6f0062258f',
    },
    {
      name_en: 'Oceania',
      name_cn: '大洋洲',
      value: '2d883619-0888-367b-97ce-332cad2aa247',
    },
    {
      name_en: 'South America',
      name_cn: '南美洲',
      value: 'aab422ac-b3d2-334a-adec-a0e1495745c2',
    },
    {
      name_en: 'US',
      name_cn: '美国',
      value: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
    },
    {
      name_en: 'EU',
      name_cn: '欧盟',
      value: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
    },
    {
      name_en: 'JP',
      name_cn: '日本',
      value: '676de5ba-893a-36bb-8683-c93306aaf769',
    },
    {
      name_en: 'CN',
      name_cn: '中国',
      value: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
    },
    {
      name_en: 'Other',
      name_cn: '其他',
      value: 'Other',
    },
  ],
  studyPhaseItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    ...CT_FINDER_STUDY_STATUS_IDS.map(getDevStatusHardData),
  ],
  estimatedEnrollmentItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: '0~15',
      value: '-15',
      name_cn: '0~15',
    },
    {
      name_en: '15~60',
      value: '15-60',
      name_cn: '15~60',
    },
    {
      name_en: '60~300',
      value: '60-300',
      name_cn: '60~300',
    },
    {
      name_en: '300~500',
      value: '300-500',
      name_cn: '300~500',
    },
    {
      name_en: '>500',
      value: '500-',
      name_cn: '>500',
    },
  ],
  recruitmentStatusItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: 'Not yet recruiting',
      value: 'Not yet recruiting',
      name_cn: 'Not yet recruiting',
    },
    {
      name_en: 'Recruiting',
      value: 'Recruiting',
      name_cn: 'Recruiting',
    },
    {
      name_en: 'Enrolling by invitation',
      value: 'Enrolling by invitation',
      name_cn: 'Enrolling by invitation',
    },
    {
      name_en: 'Active, not recruiting',
      value: 'Active, not recruiting',
      name_cn: 'Active, not recruiting',
    },
    {
      name_en: 'Suspended',
      value: 'Suspended',
      name_cn: 'Suspended',
    },
    {
      name_en: 'Terminated',
      value: 'Terminated',
      name_cn: 'Terminated',
    },
    {
      name_en: 'Completed',
      value: 'Completed',
      name_cn: 'Completed',
    },
    {
      name_en: 'Withdrawn',
      value: 'Withdrawn',
      name_cn: 'Withdrawn',
    },
    {
      name_en: 'Unknown status',
      value: 'Unknown status',
      name_cn: 'Unknown status',
    },
  ],
  simpleLegalStatusItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: 'Inactive',
      value: '0',
      name_cn: '失效',
    },
    {
      name_en: 'Active',
      value: '1',
      name_cn: '有效',
    },
    {
      name_en: 'Pending',
      value: '2',
      name_cn: '审中',
    },
    {
      name_en: 'PCT designated stage',
      value: '221',
      name_cn: 'PCT指定期内',
    },
    {
      name_en: 'PCT designated stage expired',
      value: '220',
      name_cn: 'PCT指定期满',
    },
    {
      name_en: 'Undetermined',
      value: '999',
      name_cn: '未确认',
    },
  ],
  assigneeTypeItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: 'Academy',
      value: 'ACADEMY',
      name_cn: '院校/研究所',
    },
    {
      name_en: 'Company',
      value: 'COMPANY',
      name_cn: '公司',
    },
    {
      name_en: 'Government',
      value: 'GOVERNMENT',
      name_cn: '政府',
    },
    {
      name_en: 'Person',
      value: 'PERSON',
      name_cn: '个人',
    },
    {
      name_en: 'Hospital',
      value: 'HOSPITAL',
      name_cn: '医院',
    },
    {
      name_en: 'Bank',
      value: 'BANK',
      name_cn: '银行',
    },
    {
      name_en: 'Other',
      value: 'OTHER',
      name_cn: '其他',
    },
  ],
  authorityCountryItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_en: 'WIPO',
      name_cn: '世界知识产权组织',
      value: 'WO',
    },
    {
      name_en: 'United States',
      name_cn: '美国',
      value: 'US',
    },
    {
      name_en: 'EPO',
      name_cn: '欧洲专利局',
      value: 'EP',
    },
    {
      name_en: 'China',
      name_cn: '中国',
      value: 'CN',
    },
    {
      name_en: 'Japan',
      name_cn: '日本',
      value: 'JP',
    },
  ],
  therapyTypeItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_cn: '一线',
      name_en: 'First line',
      value: '47ab7a553e2336a5b5ecde6d38d208e9',
    },
    {
      name_cn: '二线',
      name_en: 'Second line',
      value: 'c5e37a8173fb3e56b48d5d35b25e1a8b',
    },
    {
      name_cn: '三线',
      name_en: 'Third line',
      value: '62662f0b1d5b3fafabeb571a211e9a31',
    },
    {
      name_cn: '末线',
      name_en: 'Last line',
      value: '5d980a78d91b389fa653eab4c386dd57',
    },
    {
      name_cn: '辅助',
      name_en: 'Adjuvant',
      value: '41668084cc5234f0b29cb78894d0da62',
    },
    {
      name_cn: '新辅助',
      name_en: 'Neoadjuvant',
      value: '4e88ca62d0073b1ebb9700ab09cccd4d',
    },
    {
      name_cn: '维持',
      name_en: 'Maintenance',
      value: '10d0de28911c3f66863b9c8783f8148a',
    },
    {
      name_cn: '巩固',
      name_en: 'Consolidation',
      value: '4289ad5c614f3037a8810e869b56facd',
    },
    {
      name_cn: '追加',
      name_en: 'Add-on',
      value: 'b9aca50a3b39365a91ff3df678c21fe9',
    },
    {
      name_cn: '诱导',
      name_en: 'Induction',
      value: 'e8851e04a39e492e8958c64fe152c937',
    },
  ],
  meetingsItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_cn: 'ASCO_GU 2024',
      name_en: 'ASCO_GU 2024',
      value: '70df011c77204f4ab659f7d1f82cd635_2024',
      icon: 'Fire',
    },
    {
      name_cn: 'ASCO_GI 2024',
      name_en: 'ASCO_GI 2024',
      value: '489225142e814a34b532512dbe802237_2024',
      icon: 'Fire',
    },
    {
      name_cn: 'ESMO_IO 2023',
      name_en: 'ESMO_IO 2023',
      value: 'efdc61b767694f82886f304cfe67825d_2023',
    },
    {
      name_cn: 'ESMO_ASIA 2023',
      name_en: 'ESMO_ASIA 2023',
      value: '97ed102c8d8640708dab5bdd239450e2_2023',
    },
    {
      name_en: 'ESMO 2023',
      name_cn: 'ESMO 2023',
      value: '67fd8cea1a49410ebdd966dda2755bf6_2023',
    },
    {
      name_cn: 'ASH 2023',
      name_en: 'ASH 2023',
      value: 'ca88304bde6747888c1de53f13aa16fb_2023',
    },
    {
      name_en: 'WCLC 2023',
      name_cn: 'WCLC 2023',
      value: '670b55e05b3f478e89048a7dcb38ed40_2023',
    },
    {
      name_cn: 'AACR 2023',
      name_en: 'AACR 2023',
      value: '8806d6ca778544e497f77fce5165778f_2023',
    },
  ],
  evaluationItems: [
    {
      name_en: 'All',
      value: 'All',
      name_cn: 'All',
    },
    {
      name_cn: '优效',
      name_en: 'Superiority',
      value: 'b768d49203dc39919ae9651a0a0155ab',
    },
    {
      name_cn: '积极',
      name_en: 'Positive',
      value: '3289297424e03eda9b788c083bbf3147',
    },
    {
      name_cn: '非劣',
      name_en: 'Non-inferiority',
      value: '501e92dcf2b133f6a9ebae6117b59236',
    },
    {
      name_cn: '相似',
      name_en: 'Similar',
      value: '0efa04d99d83316f84d2618cf4e013d4',
    },
    {
      name_cn: '非优',
      name_en: 'Non-superiority',
      value: '15f2cafc40333f8b92096bd4a26c0b26',
    },
    {
      name_cn: '不佳',
      name_en: 'Negative',
      value: 'ffb9356ff2b73a85875c92fa7ea03b8b',
    },
  ],
  agreementTypeItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'Collaboration',
      name_cn: '合作',
      value: 'd6a713f676e84479918625e334e7ff9d',
    },
    {
      name_en: 'License',
      name_cn: '许可',
      value: 'c6bb6fa84d924b0cabd8f5dd2af17708',
    },
    {
      name_en: 'M&A',
      name_cn: '企业收购',
      value: '55a06cb457fb481d91a0a788eaad2c31',
    },
    {
      name_en: 'Asset Acquisition',
      name_cn: '项目收购',
      value: '21519be90c9840c8b002fd61194cacf4',
    },
    {
      name_en: 'Investment',
      name_cn: '投资',
      value: '72d4f04e831244e4806b927132e9fed0',
    },
    {
      name_en: 'Option',
      name_cn: '期权',
      value: '62e76fb48c6348e3b99fbfe6a1e3d919',
    },
  ],
  totalPaymentItems: [],
  amountDisclosureItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'Yes',
      name_cn: '是',
      value: 'true',
    },
    {
      name_en: 'No',
      name_cn: '否',
      value: 'false',
    },
  ],
  dealExistItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_en: 'Yes',
      name_cn: '是',
      value: 'true',
    },
    {
      name_en: 'No',
      name_cn: '否',
      value: 'false',
    },
  ],
  subjectItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    ...Object.values(ALL_SUBJECT_CONFIG_MAP).map((item) => ({
      name_en: item.name_en,
      name_cn: item.name_cn,
      value: item.dict_id,
    })),
  ],
  translationStageItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_cn: 'T0',
      name_en: 'T0',
      value: 'a48788bd63a0384087cd7d089af6c610',
    },
    {
      name_cn: 'T1',
      name_en: 'T1',
      value: 'ce499dea30cf3e118f4fe85da0227e83',
    },
    {
      name_cn: 'T2',
      name_en: 'T2',
      value: '71d2c46af01f3eea94a0f541243e297b',
    },
    {
      name_cn: 'T3',
      name_en: 'T3',
      value: '54b02ae91c8c3b0aac069c84b9f78178',
    },
  ],
  journalItems: [
    {
      name_en: 'All',
      name_cn: 'All',
      value: 'All',
    },
    {
      name_cn: 'Nature',
      name_en: 'Nature',
      value: '368cf867-f484-430e-9530-44f5cccdd973',
    },
    {
      name_cn: 'Science',
      name_en: 'Science',
      value: '5f29f5ca-fe36-42e2-9a82-3f0a241d6a7e',
    },
    {
      name_cn: 'Cell',
      name_en: 'Cell',
      value: 'a8dad243-17a0-4a94-9cfe-546ae6164b04',
    },
    {
      name_cn: 'Lancet',
      name_en: 'Lancet',
      value: '786e419c-333c-4f78-858d-e00ae8825d26',
    },
    {
      name_cn: 'N Engl J Med',
      name_en: 'N Engl J Med',
      value: 'cafa1341-670f-41a6-a532-a9109817f1a5',
    },
    {
      name_cn: 'Proc Natl Acad Sci U S A',
      name_en: 'Proc Natl Acad Sci U S A',
      value: '2f87450e-4447-43af-b10c-c903a5adbef3',
    },
    {
      name_cn: 'Nat Commun',
      name_en: 'Nat Commun',
      value: 'a52a273f-d04d-42b2-9c18-455778864b76',
    },
    {
      name_cn: 'Nat Genet',
      name_en: 'Nat Genet',
      value: '6f36bc22-4c54-4983-a46f-ac2c13632cb7',
    },

    {
      name_cn: 'Nat Med',
      name_en: 'Nat Med',
      value: 'dec8f629-42b4-43ed-8f5f-015f875eaa64',
    },
    {
      name_cn: 'Sci Transl Med',
      name_en: 'Sci Transl Med',
      value: 'ea68fe7b-5030-4ab7-b13c-f6246ad68e3e',
    },
    {
      name_cn: 'J Med Chem',
      name_en: 'J Med Chem',
      value: 'c76133d1-b056-4907-9edc-930de76e679d',
    },
  ],
}

/**
 * 自定义时间的tags配置项
 */
export const CustomDateRageConfig: Record<
  ICustomDateKey,
  { val: number; unit: string; name_en: string; name_cn: string; calcType: 'before' | 'after' }[]
> = {
  dateRangeItems: [
    {
      val: 1,
      unit: 'w',
      name_en: 'Within 1 week',
      name_cn: '1周内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'M',
      name_en: 'Within 1 Month',
      name_cn: '1个月内',
      calcType: 'before',
    },
    {
      val: 3,
      unit: 'M',
      name_en: 'Within 3 Months',
      name_cn: '3个月内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
  ],
  applicationDateItems: [
    {
      val: 3,
      unit: 'M',
      name_en: 'Within 3 Months',
      name_cn: '3个月内',
      calcType: 'before',
    },
    {
      val: 6,
      unit: 'M',
      name_en: 'Within 6 Months',
      name_cn: '6个月内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
  ],
  publicationDateItems: [
    {
      val: 1,
      unit: 'M',
      name_en: 'Within 1 Month',
      name_cn: '1个月内',
      calcType: 'before',
    },
    {
      val: 3,
      unit: 'M',
      name_en: 'Within 3 Months',
      name_cn: '3个月内',
      calcType: 'before',
    },
    {
      val: 6,
      unit: 'M',
      name_en: 'Within 6 Months',
      name_cn: '6个月内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
  ],
  estimatedExpiryDateItems: [
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'after',
    },
    {
      val: 3,
      unit: 'y',
      name_en: 'Within 3 Years',
      name_cn: '3年内',
      calcType: 'after',
    },
    {
      val: 5,
      unit: 'y',
      name_en: 'Within 5 Years',
      name_cn: '5年内',
      calcType: 'after',
    },
  ],
  /** 文献申请年：query使用整年，所以val比年数少1 */
  publicationYearItems: [
    {
      val: 0,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
    {
      val: 2,
      unit: 'y',
      name_en: 'Within 3 Years',
      name_cn: '3年内',
      calcType: 'before',
    },
    {
      val: 4,
      unit: 'y',
      name_en: 'Within 5 Years',
      name_cn: '5年内',
      calcType: 'before',
    },
  ],
  drugDealDateItems: [
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
    {
      val: 3,
      unit: 'y',
      name_en: 'Within 3 Years',
      name_cn: '3年内',
      calcType: 'before',
    },
    {
      val: 5,
      unit: 'y',
      name_en: 'Within 5 Years',
      name_cn: '5年内',
      calcType: 'before',
    },
  ],
  transMedicinePublicationDateItems: [
    {
      val: 1,
      unit: 'w',
      name_en: 'Within 1 week',
      name_cn: '1周内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'M',
      name_en: 'Within 1 Month',
      name_cn: '1个月内',
      calcType: 'before',
    },
    {
      val: 1,
      unit: 'y',
      name_en: 'Within 1 Year',
      name_cn: '1年内',
      calcType: 'before',
    },
    {
      val: 3,
      unit: 'y',
      name_en: 'Within 3 Years',
      name_cn: '3年内',
      calcType: 'before',
    },
  ],
}

/**
 * 自定义number的tags配置项
 */
export const CustomNumberRageConfig: Record<ICustomNumberRangeKey, { from: number; to?: number; name_en: string; name_cn: string }[]> = {
  numberRangeItems: [
    {
      from: 0,
      to: 15,
      name_en: '0~15',
      name_cn: '0~15',
    },
    {
      from: 15,
      to: 60,
      name_en: '15~60',
      name_cn: '15~60',
    },
    {
      from: 60,
      to: 300,
      name_en: '60~300',
      name_cn: '60~300',
    },
    {
      from: 300,
      to: 500,
      name_en: '300~500',
      name_cn: '300~500',
    },
    {
      from: 500,
      name_en: '≥500',
      name_cn: '≥500',
    },
  ],
  drugDealAmountItems: [
    {
      from: 0,
      to: 10,
      name_en: '< $10M',
      name_cn: '< $10M',
    },
    {
      from: 10,
      to: 50,
      name_en: '$10M ~ $50M',
      name_cn: '$10M ~ $50M',
    },
    {
      from: 50,
      to: 100,
      name_en: '$50M ~ $100M',
      name_cn: '$50M ~ $100M',
    },
    {
      from: 100,
      to: 500,
      name_en: '$100M ~ $500M',
      name_cn: '$100M ~ $500M',
    },
    {
      from: 500,
      to: 1000,
      name_en: '$500M ~ $1B',
      name_cn: '$500M ~ $1B',
    },
    {
      from: 1000,
      name_en: '> $1B',
      name_cn: '> $1B',
    },
  ],
}

export type ISimpleOrgType = 'Company' | 'Education' | 'Other'

export const orgTypeConfig: Record<ISimpleOrgType, { id: string; name_en: string; name_cn: string }> = {
  Company: {
    name_cn: '公司',
    name_en: 'Company',
    id: 'Company',
  },
  Education: {
    name_cn: '大学/机构',
    name_en: 'University/Institution',
    id: 'Education',
  },
  Other: {
    name_cn: '其他',
    name_en: 'Other',
    id: 'Other',
  },
}

export const disableViewDetailAutocompleteTypeList: IAutoCompleteType[] = ['Topic']
