import { TooltipComponentOption } from 'echarts/components'
import { BarSeriesOption, SingleAxisOption, VisualMapComponentOption, XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { isArray, merge } from 'lodash'
import { ECOption } from '../core/plot'

type ECMergeFunction<T> = (options: ECOption) => T
type ECMergeXAxisFunction = ECMergeFunction<XAXisOption>
type ECMergeYAxisFunction = ECMergeFunction<YAXisOption>
type ECMergeSingleAxisFunction = ECMergeFunction<SingleAxisOption>
type ECMergeTooltipFunction = ECMergeFunction<TooltipComponentOption>
type ECMergeVisualMapFunction = ECMergeFunction<VisualMapComponentOption>
type ECMergeSeriesBar = ECMergeFunction<BarSeriesOption>
type ECMergeSeries = ECMergeFunction<ECOption['series']>

export function mergeXAxis(func: ECMergeXAxisFunction, options: ECOption): ECOption {
  if (!options.xAxis) {
    options.xAxis = func(options)
    return options
  }

  if (isArray(options.xAxis)) {
    options.xAxis = options.xAxis.map((i) => merge(func(options), i))
  } else {
    options.xAxis = merge(func(options), options.xAxis)
  }

  return options
}

export function mergeSingleAxis(func: ECMergeSingleAxisFunction, options: ECOption): ECOption {
  if (!options.singleAxis) {
    options.singleAxis = func(options)
    return options
  }

  if (isArray(options.singleAxis)) {
    options.singleAxis = options.singleAxis.map((i) => merge(func(options), i))
  } else {
    options.singleAxis = merge(func(options), options.singleAxis)
  }
  return options
}

export function mergeYAxis(func: ECMergeYAxisFunction, options: ECOption): ECOption {
  if (!options.yAxis) {
    options.yAxis = func(options)
    return options
  }

  if (isArray(options.yAxis)) {
    options.yAxis = options.yAxis.map((i) => merge(func(options), i))
  } else {
    options.yAxis = merge(func(options), options.yAxis)
  }
  return options
}

export function mergeSeriesBar(func: ECMergeSeriesBar, options: ECOption): ECOption {
  if (!options.series) {
    options.series = [merge(func(options), { type: 'bar' })]
    return options
  }

  if (isArray(options.series)) {
    options.series = options.series.map((item) => {
      if (item.type === 'bar') {
        return merge(func(options), item)
      }

      return item
    })
  } else {
    if (options.series.type === 'bar') {
      options.series = merge(func(options), options.series)
    }
  }

  return options
}

export function mergeSeries(func: ECMergeSeries, options: ECOption): ECOption {
  if (!options.series) {
    options.series = func(options)
    return options
  }

  if (isArray(options.series)) {
    options.series = options.series.map((i) => merge(func(options), i))
  } else {
    options.series = merge(func(options), options.series)
  }

  return options
}

export function mergeTooltip(func: ECMergeTooltipFunction, options: ECOption): ECOption {
  if (!options.tooltip) {
    options.tooltip = func(options)

    return options
  }

  if (isArray(options.tooltip)) {
    options.tooltip = options.tooltip.map((i) => merge(func(options), i))
  } else {
    options.tooltip = merge(func(options), options.tooltip)
  }

  return options
}

export function mergeVisualMap(func: ECMergeVisualMapFunction, options: ECOption): ECOption {
  if (!options.visualMap) {
    options.visualMap = [func(options)]
    return options
  }
  if (Array.isArray(options.visualMap)) {
    options.visualMap = options.visualMap.map((visualMap) => merge(func(options), visualMap))
  } else {
    options.visualMap = [merge(func(options), options.visualMap)]
  }

  return options
}
