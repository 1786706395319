import { IQueryItem, IQueryItemField, ISearchParams } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { getCurrentInstance, onMounted, reactive, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { useAuthStore } from '.'
import { userInjectFilterService } from '../components/business/BFilter/BFilterService'
import { sharedCtx } from '../context'
import { IPatentService, ISearchCollapse } from '../types'
import { getAnalyticsPnUrl } from '../utils/business/patent'
import { IQueryService } from './useQueryService'

export function usePatentService(params: { queryService: IQueryService }): IPatentService {
  const { queryService } = params
  const {
    state: { queryId, query, data_type, collapse },
  } = queryService

  const { actions: filterActions } = userInjectFilterService()

  const state = reactive<{
    allPatentCount: number
    searchParams?: ISearchParams
    collapse?: ISearchCollapse
    loading: boolean
    viewManual: boolean
  }>({
    allPatentCount: 0,
    searchParams: undefined,
    collapse: collapse,
    loading: false,
    viewManual: false,
  })

  async function updateAllPatentsCount() {
    state.loading = true
    const cloneQuery = cloneDeep(query)
    const { must = [], filter = [] } = cloneQuery
    const newFilter = filter.filter((item) => !(item.type === 'field' && item.fields.includes('PHS_LABEL_TYPE')))
    const coreFields = must.filter((item) => item.type === 'field' && item.fields.includes('PHS_CORE_DRUG_ID')) as IQueryItemField[]
    if (!coreFields.length) return
    coreFields.forEach((item) => {
      item.fields = ['PHS_DRUG_ID']
    })
    state.searchParams = {
      limit: 0,
      offset: 0,
      collapse: state.collapse,
      data_type,
      query: {
        type: 'group',
        must,
        filter: newFilter,
      },
    }
    const res = await sharedCtx.service.patent.getSearchCount(state.searchParams)
    if (res.success) {
      state.allPatentCount = res.data.uncollapse_total
    } else {
      state.allPatentCount = 0
    }
    state.loading = false
  }

  onMounted(() => {
    updateManual(query.filter || [])
  })

  async function viewAllPatents() {
    openPageWithoutPending(async () => {
      if (!state.searchParams) return ''
      const query = {
        ...state.searchParams,
        sort: queryService.state.sort,
      }
      const queryRes = await sharedCtx.service.history.saveQuery(query)
      if (queryRes.success) {
        return sharedCtx.router.generatorPatentListPath(queryRes.data.id, [
          { consumer: 'PATENT_LIST', tracking_data: { trigger_point: 'VIEW_RELATED_PATENT' } },
        ])
      }
      return ''
    })
  }

  function updateCollapse(val: ISearchCollapse) {
    state.collapse = val
  }

  function updateManual(filterItems: IQueryItem[]) {
    state.viewManual = filterItems.some(
      (item) =>
        item.type === 'field' &&
        item.fields.includes('PHS_LABEL_TYPE') &&
        item.value.length === 1 &&
        item.value[0].type === 'text' &&
        item.value[0].value === '1'
    )
  }

  /**
   * 只看人工专利
   * @param val
   */
  async function handleOnlyViewManual(val: boolean) {
    if (val) {
      filterActions?.setFilterValue('PHS_LABEL_TYPE', [
        {
          display_name_cn: '人工标引',
          display_name_en: 'Manual',
          value: '1',
        },
      ])
    } else {
      filterActions?.removeAllFilterValueArrByFieldName('PHS_LABEL_TYPE')
    }
    filterActions?.refine(false)
  }
  const {
    getters: { isFreeUser },
    actions,
  } = useAuthStore()
  const ins = getCurrentInstance()
  async function navToAnalyticsFunc(ids: string[]) {
    if (isFreeUser.value) {
      actions.changeUpgrade({
        show: true,
        description: (ins?.proxy.$t('BFilterCommonList.upgradeTitlePatent') as string) || '',
        type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL,
        trigger_point: 'VIEW_IN_ANALYSIS',
      })
    } else {
      openPageWithoutPending(async () => await getAnalyticsPnUrl(ids))
    }
  }
  watch(() => [query, state.collapse], updateAllPatentsCount, {
    immediate: true,
    deep: true,
  })

  watch(() => query.filter, updateManual)

  return {
    state,
    actions: {
      updateAllPatentsCount,
      viewAllPatents,
      updateCollapse,
      handleOnlyViewManual,
      navToAnalyticsFunc,
    },
  }
}
