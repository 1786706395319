import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { toThousands } from '@patsnap/synapse_common_utils'
import { AggOneDimDataItem } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { computed, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { isUndefined } from 'lodash'
import type { Grid } from 'vxe-table'

interface IUseAnalysisOneDimTableOptions<T extends AggOneDimDataItem> {
  categoryFieldName: MaybeRef<string>
  countFieldName: MaybeRef<string>
  otherCountFieldName?: MaybeRef<string | undefined>
  showPercent: MaybeRef<boolean>
  list: MaybeRef<T[]>
  displayMode: MaybeRef<'table' | 'record'>
  useListSorter?: boolean
  customListSorter?: (a: T, b: T) => number
  customCategoryRender?: (data: T) => string | number | JSX.Element
  customCountRender?: (data: T) => string | number | JSX.Element
  customOtherCountRender?: (data: T) => string | JSX.Element
}

export function useAnalysisOneDimTable<T extends AggOneDimDataItem>(options: IUseAnalysisOneDimTableOptions<T>) {
  const {
    categoryFieldName,
    countFieldName,
    otherCountFieldName,
    showPercent,
    list: originList,
    displayMode,
    useListSorter = true,
    customListSorter,
    customCategoryRender,
    customCountRender,
    customOtherCountRender,
  } = options

  const { locale } = useLocale()

  const list = computed(() => {
    const targetList = [...unref(originList)]
    if (useListSorter) {
      if (customListSorter) {
        targetList.sort(customListSorter)
      } else {
        targetList.sort((a, b) => b.count - a.count)
      }
    }
    return targetList
  })

  const recordModeRowNum = computed(() => {
    const baseNum = 2
    return unref(otherCountFieldName) ? baseNum + 1 : baseNum
  })

  const recordModeColNum = computed(() => unref(list).length + 1)

  const recordModeList = computed(() => {
    const items: Array<Record<string, string | AggOneDimDataItem>> = []

    for (let i = 0; i < unref(recordModeRowNum); i++) {
      const item: Record<string, string | AggOneDimDataItem> = {}
      for (let j = 0; j < unref(recordModeColNum); j++) {
        // 第一行的数据
        if (i === 0) {
          // 第一行，第一列
          if (j === 0) {
            item[`col_${j}`] = unref(categoryFieldName)
          } else {
            item[`col_${j}`] = unref(list)[j - 1]
          }
        }
        if (i === 1) {
          if (j === 0) {
            item[`col_${j}`] = unref(countFieldName)
          } else {
            item[`col_${j}`] = unref(list)[j - 1]
          }
        }
        // if (i === 2) {
        //   if (unref(showPercent)) {
        //     if (j === 0) {
        //       item[`col_${j}`] = unref(locale) === 'cn' ? '百分比' : 'Percent'
        //     } else {
        //       item[`col_${j}`] = unref(list)[j - 1]
        //     }
        //   } else {
        //     if (j === 0) {
        //       item[`col_${j}`] = unref(otherCountFieldName) || ''
        //     } else {
        //       item[`col_${j}`] = unref(list)[j - 1]
        //     }
        //   }
        // }
        if (i === 2) {
          if (j === 0) {
            item[`col_${j}`] = unref(otherCountFieldName) || ''
          } else {
            item[`col_${j}`] = unref(list)[j - 1]
          }
        }
      }
      items.push(item)
    }

    return items
  })

  const recordModeColumns = computed(() => {
    const colNum = unref(recordModeColNum)

    const columns: Grid['columns'] = []

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const colCommonConfig: any = {
      resizable: true,
      align: 'left',
      showOverflow: false,
    }

    for (let i = 0; i < colNum; i++) {
      if (i === 0) {
        columns.push({
          field: `col_${i}`,
          width: 135,
          fixed: 'left',
          className: 'bg-[#f8f8f9]',
          ...colCommonConfig,
        })
      } else {
        columns.push({
          field: `col_${i}`,
          minWidth: 100,
          ...colCommonConfig,
          slots: {
            default: (params) => {
              const colIndex = params.columnIndex
              const rowIndex = params.rowIndex
              const rowData = params.row
              const item = rowData[`col_${colIndex}`] as T
              if (rowIndex === 0) return customCategoryRender ? [customCategoryRender(item)] : [item.name || EMPTY_PLACEHOLDER]

              if (rowIndex === 1)
                return customCountRender
                  ? [customCountRender(item)]
                  : [
                      unref(showPercent)
                        ? isUndefined(item.count)
                          ? EMPTY_PLACEHOLDER
                          : `${toThousands(item.count)} (${(item.percent * 100).toFixed(2)}%)`
                        : isUndefined(item.count)
                        ? EMPTY_PLACEHOLDER
                        : toThousands(item.count),
                    ]

              // if (unref(showPercent)) {
              //   return [isUndefined(item.percent) ? EMPTY_PLACEHOLDER : `${(item.percent * 100).toFixed(2)}%`]
              // }
              return customOtherCountRender
                ? [customOtherCountRender(item)]
                : [isUndefined(item.otherCount) ? EMPTY_PLACEHOLDER : toThousands(item.otherCount)]
            },
          },
        })
      }
    }

    return columns
  })

  const tableModeColumns = computed(() => {
    const columns: Grid['columns'] = [
      {
        field: 'category',
        title: unref(categoryFieldName),
        resizable: true,
        align: 'left',
        showOverflow: false,
        slots: {
          default: (params) => {
            const rowData = params.row as AggOneDimDataItem

            return [rowData.name || EMPTY_PLACEHOLDER]
          },
        },
      },
      {
        field: 'count',
        title: unref(countFieldName),
        resizable: true,
        align: 'left',
        showOverflow: false,
        slots: {
          default: (params) => {
            const rowData = params.row as AggOneDimDataItem

            return [
              unref(showPercent)
                ? isUndefined(rowData.count)
                  ? EMPTY_PLACEHOLDER
                  : `${toThousands(rowData.count)} (${(rowData.percent * 100).toFixed(2)}%)`
                : isUndefined(rowData.count)
                ? EMPTY_PLACEHOLDER
                : toThousands(rowData.count),
            ]
          },
        },
      },
    ]

    // if (unref(showPercent)) {
    //   columns.push({
    //     field: 'count',
    //     title: unref(locale) === 'cn' ? '百分比' : 'Percent',
    //     resizable: true,
    //     align: 'left',
    //     showOverflow: false,
    //     slots: {
    //       default: (params) => {
    //         const rowData = params.row as AggOneDimDataItem

    //         return [isUndefined(rowData.percent) ? EMPTY_PLACEHOLDER : `${(rowData.percent * 100).toFixed(2)}%`]
    //       },
    //     },
    //   })
    // }

    if (unref(otherCountFieldName)) {
      columns.push({
        field: 'count',
        title: unref(otherCountFieldName),
        resizable: true,
        align: 'left',
        showOverflow: false,
        slots: {
          default: (params) => {
            const rowData = params.row as T

            return customOtherCountRender
              ? ([customOtherCountRender(rowData)] as string[])
              : [isUndefined(rowData.otherCount) ? EMPTY_PLACEHOLDER : toThousands(rowData.otherCount)]
          },
        },
      })
    }

    return columns
  })

  const tableData = computed(() => (unref(displayMode) === 'record' ? unref(recordModeList) : unref(list)))

  const tableColumns = computed(() => (unref(displayMode) === 'record' ? unref(recordModeColumns) : unref(tableModeColumns)))

  return {
    tableData,
    tableColumns,
  }
}
