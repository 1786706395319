import { E_SORT_ORDER } from '@patsnap/synapse_common_interface'
import { defineComponent, PropType } from '@vue/composition-api'
import { orderConfigMap } from '../config'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $style from './SortOrder.module.scss'
const allOrder: E_SORT_ORDER[] = [E_SORT_ORDER.ASC, E_SORT_ORDER.DESC]
export const SortOrder = defineComponent({
  name: 'SortOrder',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    value: {
      type: String as PropType<E_SORT_ORDER>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    function onSelect(order: E_SORT_ORDER) {
      if (props.disabled) {
        return
      }
      ctx.emit('input', order)
    }
    return { onSelect }
  },
  render() {
    return (
      <div class={[$style.sortButtonGroup, { disabled: this.disabled }]}>
        {allOrder.map((item) => {
          const config = orderConfigMap[item]
          return (
            <div
              key={item}
              data-testid={`sort-order--${item}`}
              class={[
                $style.sortButtonItem,
                {
                  active: this.value === item,
                },
              ]}
              onClick={() => this.onSelect(item)}
            >
              {this.$tc(config.i18nKey)}
            </div>
          )
        })}
      </div>
    )
  },
})
