import {
  alertEntityConfig,
  alertType2AlertEntityTypeMap,
  alertType2AlertEventMap,
  eventSettingConfig,
  queryResultEventType,
  topEventConfigMap,
} from '@patsnap/synapse_common_config'
import { IAlertConfig, IAlertEventSetting, IAlertType, ICreateUpdateAlertParams } from '@patsnap/synapse_common_interface'
import { checkIsSearchEmailAlert, checkIsSupportRefine, getAlertSearchTypeAndGroupTypeByAlertType } from '@patsnap/synapse_common_utils'
import { StructureImg } from '@pharmsnap/pharmsnap-web/components/structure/components/StructureImg'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale, userErrorMessage } from '@pharmsnap/shared/src/composition'
import { MULTI_ENTITY_CONNECTOR } from '@pharmsnap/shared/src/constants'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { EMAIL_ALERT_GUIDANCE } from '@pharmsnap/shared/src/domain/emailAlertDomain/emailAlert.config'
import { ElCheckbox, ElInput } from '@pharmsnap/shared/src/element-ui'
import { renderUpgradeStar } from '@pharmsnap/shared/src/render'
import { getKeys, showSingleToast } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { GIcon, GTooltip } from 'pharmsnapMF_shared/components'
import { GGuideButton } from '../../ui/GGuideButton/GGuideButton'
import $classes from './BEmailAlertV2.module.scss'
import { BEmailPreview } from './components/BEmailPreview/BEmailPreview'
import { BEmailSendingConfigForm } from './components/BEmailSendingConfigForm/BEmailSendingConfigForm'
import { BEntitySelectForm } from './components/BEntitySelectForm/BEntitySelectForm'
import { BRefineEntityForm } from './components/BRefineEntityForm/BRefineEntityForm'
import cn from './locales/cn.json'
import en from './locales/en.json'
interface ISubEventModel {
  subscribed: boolean
  collapse: boolean
  subEventsModel: Record<string, boolean>
}
type ISettingFormModel = Record<keyof IAlertEventSetting, ISubEventModel>
export const BEmailAlertV2 = defineComponent({
  name: 'BEmailAlertV2',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /**
     * 默认的邮件提醒配置
     */
    defaultEmailAlertConfig: {
      type: Object as PropType<Partial<ICreateUpdateAlertParams>>,
      required: true,
    },
    /**
     * 模式, detail:详情模式,create:创建模式,update:更新模式
     */
    mode: {
      type: String as PropType<'detail' | 'create' | 'update'>,
      default: 'detail',
    },
    /**
     * 如果是搜索结果页,需要传此数量,不同的用户角色能创建的数量不同
     */
    searchResultCount: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const searchResultCountLimitMap: Partial<Record<IAlertType, number>> = {
      drug_search: 500,
      drug_search_group_by_organization: 50,
      drug_search_group_by_target: 50,
      drug_search_group_by_drug_type: 50,
      drug_search_group_by_disease: 50,
      patent_search: 1000,
      clinical_trial_search: 1000,
    }
    const { isCN, locale, localeUpcase, $t, tsLangMap } = useLocale()
    const { showErrorMessageByToast } = userErrorMessage()
    const {
      actions: { changeUpgrade },
      getters: { isFreeUser },
    } = useAuthStore()
    // 邮件提醒配置的model
    const alertConfigModel = ref<Partial<ICreateUpdateAlertParams>>(props.defaultEmailAlertConfig)
    //事件设置表单的model
    const eventSettingFormModel = reactive<ISettingFormModel>(
      getKeys(eventSettingConfig).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            subscribed: false,
            collapse: true,
            subEventsModel: eventSettingConfig[curr].subEvents.reduce((_acc, _curr) => {
              return {
                ..._acc,
                [_curr]: false,
              }
            }, {} as Record<string, boolean>),
          },
        }
      }, {} as ISettingFormModel)
    )
    // 实体选择表单的引用
    const BEntitySelectFormRef = ref()
    // 实体筛选表单的引用
    const BRefineEntityFormRef = ref()
    // 邮件发送配置表单的引用
    const BEmailSendingConfigFormRef = ref()
    // 是否全部折叠
    const collapseAll = ref(true)
    // 加载状态
    const loading = ref(false)
    // 邮件提醒类型
    const alertType = computed(() => {
      return alertConfigModel.value.alert_type as IAlertType
    })
    // 搜索结果数量限制
    const searchResultCountLimit = computed(() => {
      return searchResultCountLimitMap[alertType.value] || 0
    })
    // 所有顶级事件类型
    const allTopEvents = computed(() => {
      return alertType2AlertEventMap[alertType.value]
    })
    // 是否显示搜索结果事件
    const isShowSearchResultEvent = computed(() => {
      return allTopEvents.value.includes(queryResultEventType)
    })
    // 是否显示事件筛选
    const shouldShowRefine = computed(() => {
      return checkIsSupportRefine(alertType.value)
    })
    // 是否显示折叠按钮
    const shouldShowCollapseButton = computed(() => {
      return allTopEvents.value.some((item) => {
        const topEventConfig = eventSettingConfig[item]
        // 只有一个子事件的顶层事件不显示折叠按钮
        return topEventConfig.subEvents.length > 1
      })
    })
    // 是否是搜索结果页的alert
    const isSearchResultAlert = computed(() => {
      return checkIsSearchEmailAlert(alertType.value)
    })

    const isStructureSearchResultAlert = computed(() => {
      return isSearchResultAlert.value && alertConfigModel.value?.query?.query_type === 'STRUCTURE'
    })

    // 除了query_result以外的所有顶层事件
    const excludeQueryResultAllTopEvents = computed(() => {
      return allTopEvents.value.filter((item) => item !== queryResultEventType)
    })
    // 选中的顶层事件的键
    const topEventSelectKeys = computed(() => {
      return getKeys(eventSettingFormModel)
        .filter((item) => eventSettingFormModel[item].subscribed)
        .sort((a, b) => {
          const indexA = allTopEvents.value.indexOf(a)
          const indexB = allTopEvents.value.indexOf(b)
          return indexA - indexB
        })
    })
    // 邮件提醒实体类型
    const alertEntityType = computed(() => {
      return alertType2AlertEntityTypeMap[alertType.value]
    })
    // 邮件提醒实体名称
    const alertEntityName = computed(() => {
      return tsLangMap(alertEntityConfig[alertEntityType.value])
    })
    // 除了query_result以外的所有顶层事件的表单项
    const excludeQueryResultAllTopEventFormItems = computed(() => {
      return excludeQueryResultAllTopEvents.value.map((item) => {
        if (!item) {
          throw new Error('item is undefined')
        }
        const topEventConfig = topEventConfigMap[item]
        const subEventConfigMap = eventSettingConfig[item]
        return {
          key: item,
          label: isCN.value ? topEventConfig.cn : topEventConfig.en,
          disable: topEventConfig.auth && isFreeUser.value,
          tips: topEventConfig.tips ? (isCN.value ? topEventConfig.tips.cn : topEventConfig.tips.en) : '',
          subEvents: subEventConfigMap.subEvents.map((subEvent) => {
            const subEventConfig = subEventConfigMap.configMap[subEvent]
            return {
              key: subEvent,
              auth: subEventConfig.auth,
              disable: isFreeUser.value && (topEventConfig.auth || subEventConfig.auth),
              label: isCN.value ? subEventConfig.cn : subEventConfig.en,
              tips: subEventConfig.tips ? (isCN.value ? subEventConfig.tips.cn : subEventConfig.tips.en) : '',
            }
          }),
        }
      })
    })
    // 邮件提醒设置的警告提示
    const alertSettingWaringTip = computed(() => {
      if (checkIsSearchEmailAlert(alertType.value)) {
        const outerSearchResultCount = props.searchResultCount
        const innerSearchResultCount = alertConfigModel.value.original_query_count
        const searchResultCount = isUndefined(innerSearchResultCount) ? outerSearchResultCount : outerSearchResultCount || innerSearchResultCount
        if (searchResultCountLimit.value && searchResultCount > searchResultCountLimit.value) {
          return isCN.value
            ? `将检索结果数控制在${searchResultCountLimit.value}以内，可以提醒更多内容`
            : `Refine your results to fewer than ${searchResultCountLimit.value} to ensure you receive more events.`
        }
      }
      return ''
    })
    // 是否禁用主要事件
    const disableMainEvent = computed(() => {
      return !!alertSettingWaringTip.value
    })
    /**
     * 顶层事件的checkbox变化时，更新顶层事件和子的订阅状态
     * @param event
     * @param value
     */
    function onTopEventChange(event: keyof IAlertEventSetting, value: boolean) {
      eventSettingFormModel[event].subscribed = value
      const subEventNames = getKeys(eventSettingFormModel[event].subEventsModel)
      if (subEventNames.length > 0) {
        subEventNames.forEach((subEvent) => {
          eventSettingFormModel[event].subEventsModel[subEvent] = value
        })
      }
    }
    function onSubEventChange(topEvent: keyof IAlertEventSetting, _subEvent: string, value: boolean) {
      // 如果子事件的订阅状态为true,则将顶层事件的订阅状态设置为true
      if (value) {
        eventSettingFormModel[topEvent].subscribed = value
      } else {
        // 如果子事件的订阅状态为false,则检查所有子事件的订阅状态,如果所有子事件的订阅状态都为false,则将顶层事件的订阅状态设置为false
        const subEventNames = getKeys(eventSettingFormModel[topEvent].subEventsModel)
        if (subEventNames.every((subEventName) => !eventSettingFormModel[topEvent].subEventsModel[subEventName])) {
          eventSettingFormModel[topEvent].subscribed = false
        }
      }
    }
    function onChangeEventSettingCollapse() {
      const newValue = !collapseAll.value
      excludeQueryResultAllTopEvents.value.forEach((topEventName) => {
        eventSettingFormModel[topEventName].collapse = newValue
      })
      collapseAll.value = newValue
    }
    function onClickUpgrade() {
      return changeUpgrade({
        show: true,
        type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL,
        trigger_point: 'ALERT_DIALOG_EVENT',
        onViewPlanCallback: () => {
          onCancel()
        },
      })
    }
    function onUpdateEmailConfig(config: IAlertConfig) {
      const targetAlertType = config.alert_type
      const sourceAlertType = alertConfigModel.value.alert_type
      alertConfigModel.value = {
        ...alertConfigModel.value,
        ...config,
      }
      // 当是创建模式时，如果alert_type发生变化,则需要重置所有事件设置，因为config可能没有变更alert_type，所以需要判断目标alert_type是否存在
      if (props.mode === 'create' && !!targetAlertType && targetAlertType !== sourceAlertType) {
        _resetAllTopEvents()
      }
    }
    async function onSubmit() {
      const checkCanSubmit = _checkCanSubmit()
      if (checkCanSubmit === false) {
        return
      }
      const latestEmailAlertConfig = _getLatestEmailAlertConfig()
      if (latestEmailAlertConfig) {
        if (latestEmailAlertConfig.alert_ids?.length || latestEmailAlertConfig.alert_id) {
          const res = await sharedCtx.service.emailAlert.updateEmailAlertV2(latestEmailAlertConfig)
          if (res.success) {
            emit('submitSuccess', latestEmailAlertConfig)
            emit('confirm')
          } else {
            const error = res.data
            showErrorMessageByToast(error.numeric_error_code)
          }
        } else {
          const res = await sharedCtx.service.emailAlert.addEmailAlert(latestEmailAlertConfig)
          if (res.success) {
            emit('submitSuccess', res.data?.alert_config)
            if (res.data?.repeat_count) {
              const success_count = res.data?.success_count || 0
              const repeat_count = res.data?.repeat_count || 0
              const total_count = repeat_count + success_count
              showSingleToast({ message: $t('BEmailAlert.repeatCreateTip', { success_count, repeat_count, total_count }) as string, type: 'warning' })
            }
            emit('confirm')
          } else {
            const error = res.data
            showErrorMessageByToast(error.numeric_error_code)
          }
        }
      }
    }
    function onCancel() {
      emit('cancel')
    }
    function _checkCanSubmit() {
      // 如果所有的顶层事件都被禁用,则说明没有权限
      if (excludeQueryResultAllTopEventFormItems.value.length && excludeQueryResultAllTopEventFormItems.value.every((item) => item.disable)) {
        showErrorMessageByToast(130134)
        return false
      }
      if (_checkIsNotSetAlertSetting()) {
        showSingleToast({ message: $t('BEmailAlert.noEventSetting') as string, type: 'error' })
        return false
      }
      if (isSearchResultAlert.value && !alertConfigModel.value.title) {
        showSingleToast({ message: $t('BEmailAlert.noTitle') as string, type: 'error' })
        return false
      }
      if (BEntitySelectFormRef.value) {
        const rt = BEntitySelectFormRef.value.checkCanSubmit()
        if (!rt) {
          return false
        }
      }
      if (BEmailSendingConfigFormRef.value) {
        const rt = BEmailSendingConfigFormRef.value.checkCanSubmit()
        if (!rt) {
          return false
        }
      }
      return true
    }
    function _checkIsNotSetAlertSetting() {
      return !getKeys(eventSettingFormModel).some((item) => eventSettingFormModel[item].subscribed)
    }
    function _getLatestEmailAlertConfig(): ICreateUpdateAlertParams {
      const createUpdateAlertParams: ICreateUpdateAlertParams = {
        ...alertConfigModel.value,
        ..._getSendingConfig(),
        ..._getRefineCondition(),
        alert_type: alertType.value,
        event_setting: _eventSettingFormModel2EventSettingData(),
        language: localeUpcase.value as 'CN' | 'EN',
      }
      if (createUpdateAlertParams.alert_id) {
        // 如果存在alert_id,则为更新,需要强制将alert状态打开
        createUpdateAlertParams.status = true
      }
      return createUpdateAlertParams
    }
    function _getSendingConfig(): IAlertConfig | undefined {
      if (BEmailSendingConfigFormRef.value) {
        return BEmailSendingConfigFormRef.value.getSendingConfig()
      }
      return undefined
    }
    function _getRefineCondition(): IAlertConfig | undefined {
      if (BRefineEntityFormRef.value) {
        return BRefineEntityFormRef.value.getRefineCondition()
      }
      return undefined
    }
    /**
     * 邮件设置表单状态转换为服务端需要的数据格式
     * @returns
     */
    function _eventSettingFormModel2EventSettingData(): IAlertEventSetting {
      const eventSettingData: IAlertEventSetting = {}
      allTopEvents.value.forEach((topEventName) => {
        const topEventSetting = eventSettingFormModel[topEventName]
        if (topEventSetting.subscribed) {
          eventSettingData[topEventName] = {
            subscribed: true,
            sub_event_setting: getKeys(topEventSetting.subEventsModel).filter(
              (subEventName) => topEventSetting.subEventsModel[subEventName]
              // 此处的subEventName是IAlertDrugDevStatusEvent | IAlertFoundingRoundEvent | IAlertDrugDealEvent | IAlertPatentEvent所有的并集,但是不会混合出现,所以可以这样断言
              // eslint-disable-next-line prettier/prettier
            ) as any[],
          }
        }
      })
      return eventSettingData
    }
    /**
     * 初始化事件设置表单的model
     */
    function _initEventSettingFormModel() {
      const eventSetting = alertConfigModel.value?.event_setting
      // 有传入服务端配置则使用服务端配置
      if (eventSetting) {
        allTopEvents.value.forEach((topEventName) => {
          const topEventSetting = eventSetting[topEventName]
          // 服务端配置中没有该事件的配置则跳过
          if (!topEventSetting) {
            return
          }
          const topEventConfig = topEventConfigMap[topEventName]
          // 如果该事件需要付费,并且用户是免费用户,则跳过
          if (topEventConfig.auth && isFreeUser.value) {
            return
          }
          eventSettingFormModel[topEventName].subscribed = topEventSetting.subscribed
          const subEventConfig = eventSettingConfig[topEventName].configMap
          // 过滤掉不存在的事件配置的事件
          const subEventSetting = topEventSetting.sub_event_setting?.filter((item) => subEventConfig[item])
          if (subEventSetting?.length) {
            Object.assign(
              eventSettingFormModel[topEventName].subEventsModel,
              subEventSetting.reduce((acc, curr: string) => {
                return {
                  ...acc,
                  [curr]: subEventConfig[curr].auth && isFreeUser.value ? false : true,
                }
              }, {} as Record<string, boolean>)
            )
          }
        })
      } else {
        // 默认勾选全部事件
        _resetAllTopEvents()
      }
    }

    function _resetAllTopEvents() {
      allTopEvents.value.forEach((topEventName) => {
        const topEventConfig = topEventConfigMap[topEventName]
        // 如果检索结果是超过了指定数量,则不勾选除了query_result以外的所有事件
        if (disableMainEvent.value && topEventName !== 'query_result') {
          return
        }
        // 如果该事件需要付费,并且用户是免费用户,则跳过
        if (topEventConfig.auth && isFreeUser.value) {
          return
        }
        eventSettingFormModel[topEventName].subscribed = true
        const subEvents = eventSettingConfig[topEventName].subEvents
        const subEventConfig = eventSettingConfig[topEventName].configMap
        if (subEvents.length) {
          Object.assign(
            eventSettingFormModel[topEventName].subEventsModel,
            subEvents.reduce((acc, curr) => {
              return {
                ...acc,
                [curr]: subEventConfig[curr].auth && isFreeUser.value ? false : true,
              }
            }, {} as Record<string, boolean>)
          )
        }
      })
    }

    function _initEmailAlertConfigModel() {
      // 如果不存在标题,则需要填入默认标题
      if (!alertConfigModel.value.title) {
        alertConfigModel.value = {
          ...alertConfigModel.value,
          title: _getDefaultEmailTitle(),
        }
      }
    }
    function _getSingleUpdateAlertId() {
      if (alertConfigModel.value.alert_id) {
        return alertConfigModel.value.alert_id
      }
      // 批量更新,有且只有一个alert_id时候才需要获取更新的alert配置
      if (alertConfigModel.value.alert_ids?.length === 1) {
        return alertConfigModel.value.alert_ids[0]
      }
      return ''
    }
    async function _initGetNeedUpdateEmailAlertConfig() {
      if (props.mode !== 'update') {
        return
      }
      const updateAlertId = _getSingleUpdateAlertId()
      if (!updateAlertId) {
        return
      }
      loading.value = true
      const rt = await sharedCtx.service.emailAlert.getSingleEmailAlertDetail(updateAlertId)
      if (rt.success) {
        alertConfigModel.value = rt.data
      }
      loading.value = false
    }
    function _getDefaultEmailTitle() {
      // 如果是搜索结果页的alert,则使用空字符串作为默认标题
      if (!checkIsSearchEmailAlert(alertType.value)) {
        return ''
      }
      const { mainSearchEntity, groupEntity } = getAlertSearchTypeAndGroupTypeByAlertType(alertType.value)
      if (!mainSearchEntity) {
        return ''
      }
      const mainEntityNameMap = alertEntityConfig[mainSearchEntity]
      const mainEntityTitle = isCN.value ? `未命名的${mainEntityNameMap.cn}检索式 1` : `Untitled ${mainEntityNameMap.en} search 1`
      if (groupEntity) {
        const groupEntityNameMap = alertEntityConfig[groupEntity]
        return isCN.value ? `${mainEntityTitle}(${groupEntityNameMap.cn}维度)` : `${mainEntityTitle}(grouped by ${groupEntityNameMap.en})`
      }
      return mainEntityTitle
    }
    function _initEmailAlertSendingForm() {
      if (BEmailSendingConfigFormRef.value) {
        BEmailSendingConfigFormRef.value.initBizEmailAlertServiceState()
      }
    }
    function _initEmailAlertRefineItemsModelForm() {
      if (BRefineEntityFormRef.value) {
        BRefineEntityFormRef.value.initRefineItemsModel()
      }
    }
    async function _initFormModel() {
      await _initGetNeedUpdateEmailAlertConfig()
      _initEmailAlertConfigModel()
      _initEventSettingFormModel()
      _initEmailAlertSendingForm()
      _initEmailAlertRefineItemsModelForm()
    }

    watch(
      () => props.defaultEmailAlertConfig,
      () => {
        _initFormModel()
      },
      {
        immediate: true,
      }
    )

    return {
      locale,
      isCN,
      alertType,
      isSearchResultAlert,
      isStructureSearchResultAlert,
      isShowSearchResultEvent,
      isFreeUser,
      topEventSelectKeys,
      excludeQueryResultAllTopEventFormItems,
      eventSettingFormModel,
      alertConfigModel,
      alertEntityName,
      BEntitySelectFormRef,
      BRefineEntityFormRef,
      BEmailSendingConfigFormRef,
      shouldShowRefine,
      shouldShowCollapseButton,
      alertSettingWaringTip,
      disableMainEvent,
      collapseAll,
      loading,
      onUpdateEmailConfig,
      onTopEventChange,
      onSubEventChange,
      onChangeEventSettingCollapse,
      onClickUpgrade,
      onSubmit,
      onCancel,
    }
  },
  methods: {
    renderMultiEditAlertWarning() {
      const updateAlertIdLength = this.alertConfigModel.alert_ids?.length

      if (this.mode === 'update' && updateAlertIdLength && updateAlertIdLength > 1) {
        return (
          <div class="pr-4 bg-[#FAECDA] justify-start items-center flex">
            <div class="w-1 shrink-0 self-stretch bg-orange-dark"></div>
            <div style="word-break: break-word;" class="text-sm py-2.5 font-normal leading-5 text-text-default ml-4">
              {this.$tc('BEmailAlert.multiEditAlertWarning')}
            </div>
          </div>
        )
      }
    },
    renderCreatedAlertEntity() {
      if (this.mode !== 'create') {
        return null
      }
      return <BEntitySelectForm ref="BEntitySelectFormRef" onUpdate={this.onUpdateEmailConfig}></BEntitySelectForm>
    },
    renderAlertTitleForm() {
      const updateAlertIdLength = this.alertConfigModel.alert_ids?.length
      if (updateAlertIdLength) {
        return (
          <div class="mt-4">
            <div class="font-bold">{this.$t('BEmailAlert.monitoringEntity', { type: this.alertEntityName })}</div>
            <div class="truncate mt-2">{this.$t('BEmailAlert.hasCheckedWhatNumber', { num: updateAlertIdLength, type: this.alertEntityName })}</div>
          </div>
        )
      }
      if (this.isSearchResultAlert) {
        return (
          <div class="mt-4">
            <div class="font-bold">{this.$t('BEmailAlert.alertTitle')}</div>
            <div class="mt-2">
              <ElInput size="small" v-model={this.alertConfigModel.title}></ElInput>
            </div>
          </div>
        )
      }
      const batchEntityLength = this.alertConfigModel.batch_entity?.length
      if (batchEntityLength) {
        return (
          <div class="mt-4">
            <div class="font-bold">{this.$t('BEmailAlert.monitoringEntity', { type: this.alertEntityName })}</div>
            <div class="truncate mt-2">{this.$t('BEmailAlert.hasCheckedWhatNumber', { num: batchEntityLength, type: this.alertEntityName })}</div>
          </div>
        )
      }
      const entityValue = this.alertConfigModel.entity?.value
      if (entityValue?.length) {
        const entityNameString = entityValue
          ?.map((item) => (this.isCN ? item.display_name_cn : item.display_name_en))
          .filter((item) => item)
          .join(MULTI_ENTITY_CONNECTOR)
        return (
          <div class="mt-4">
            <div class="font-bold">{this.$t('BEmailAlert.monitoringEntity', { type: this.alertEntityName })}</div>
            <div class="truncate mt-2">{entityNameString}</div>
          </div>
        )
      }
      return null
    },
    renderEventSettingForm() {
      return (
        <div class="text-sm mt-4">
          <div class="font-bold">{this.$t('BEmailAlert.eventSetting')}</div>
          {this.alertSettingWaringTip ? <div class={[$classes.waringTip, 'mt-2']}>{this.alertSettingWaringTip}</div> : null}
          <div class="bg-gray-20 p-3 mt-2">
            {this.isShowSearchResultEvent ? (
              <div class="mb-4">
                <div class="font-bold">{this.$t('BEmailAlert.searchQuery')}</div>
                <div class="flex items-center mt-2">
                  <ElCheckbox
                    class="mr-2"
                    style="margin-right: 8px"
                    v-model={this.eventSettingFormModel.query_result.subscribed}
                    onChange={(value: boolean) => this.onTopEventChange('query_result', value)}
                  ></ElCheckbox>
                  <span class="text-text-t1">{this.$t('BEmailAlert.newRemovedWhat', { type: this.alertEntityName })}</span>
                </div>
              </div>
            ) : null}
            {this.excludeQueryResultAllTopEventFormItems.length ? (
              <div>
                <div class="flex justify-between mt-2">
                  <div class="inline-flex items-center">
                    <div class="font-bold">{this.$t('BEmailAlert.eventType')}</div>
                    {this.isShowSearchResultEvent && (
                      <GTooltip mode="icon">
                        <div slot="content">
                          {this.isCN ? (
                            <div>
                              <div>{`基于检索结果的${this.alertEntityName}列表，监控每个${this.alertEntityName}的事件类型，`}</div>
                              <div>{`您可以${this.shouldShowRefine ? '通过「添加过滤项」和' : ''}勾选不同「事件类型」实现精确监控`}</div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                {`Based on the ${this.alertEntityName} list of the search results, monitor the event type of each ${this.alertEntityName},`}
                              </div>
                              <div>{`You can ${
                                this.shouldShowRefine ? 'achieve precise monitoring by 「add filter item」and ' : ''
                              }checking different 「event type」.`}</div>
                            </div>
                          )}
                        </div>
                      </GTooltip>
                    )}
                  </div>
                  {this.shouldShowCollapseButton && (
                    <div class="text-blue-default cursor-pointer hover:text-blue-hover hover:underline" onClick={this.onChangeEventSettingCollapse}>
                      {this.collapseAll ? this.$t('BEmailAlert.expandEvent') : this.$t('BEmailAlert.collapseEvent')}
                    </div>
                  )}
                </div>
                <div class="mt-2">
                  {this.shouldShowRefine && (
                    <BRefineEntityForm
                      ref="BRefineEntityFormRef"
                      alertConfig={this.alertConfigModel}
                      disabled={this.disableMainEvent}
                    ></BRefineEntityForm>
                  )}
                  {this.excludeQueryResultAllTopEventFormItems.map((topEvent) => {
                    const hasOneMoreSubEvent = topEvent.subEvents.length > 1
                    return (
                      <div>
                        <div class="flex items-center mt-2">
                          <ElCheckbox
                            class={{ 'mr-2': !hasOneMoreSubEvent }}
                            style={!hasOneMoreSubEvent ? 'margin-right: 8px' : ''}
                            disabled={this.disableMainEvent || topEvent.disable}
                            v-model={this.eventSettingFormModel[topEvent.key].subscribed}
                            onChange={(value: boolean) => this.onTopEventChange(topEvent.key, value)}
                          ></ElCheckbox>
                          {hasOneMoreSubEvent && (
                            <GIcon
                              class={['cursor-pointer', { 'text-text-t3': this.disableMainEvent || topEvent.disable }]}
                              svgName={this.eventSettingFormModel[topEvent.key].collapse ? 'SolidDropdownRight' : 'SolidDropdownOpen'}
                              size={24}
                              nativeOnClick={() =>
                                (this.eventSettingFormModel[topEvent.key].collapse = !this.eventSettingFormModel[topEvent.key].collapse)
                              }
                            ></GIcon>
                          )}
                          <div class={this.disableMainEvent || topEvent.disable ? 'text-text-t3' : 'text-text-t1'}>{topEvent.label}</div>
                          {topEvent.disable && (
                            <div class="ml-2">
                              {renderUpgradeStar({
                                content: this.$tc('common.upgrade'),
                                callback: this.onClickUpgrade,
                              })}
                            </div>
                          )}
                          {topEvent.tips && <GTooltip class="ml-2" content={topEvent.tips} mode="icon"></GTooltip>}
                        </div>
                        {hasOneMoreSubEvent && !this.eventSettingFormModel[topEvent.key].collapse && (
                          <div class="flex flex-wrap items-center gap-2 p-2 pl-5">
                            {topEvent.subEvents.map((subEvent) => {
                              return (
                                <div class="min-w-[138px]">
                                  <ElCheckbox
                                    disabled={this.disableMainEvent || subEvent.disable}
                                    v-model={this.eventSettingFormModel[topEvent.key].subEventsModel[subEvent.key]}
                                    onChange={(value: boolean) => this.onSubEventChange(topEvent.key, subEvent.key, value)}
                                  >
                                    <div>{subEvent.label}</div>
                                  </ElCheckbox>
                                  {subEvent.disable && (
                                    <span class="ml-2">
                                      {renderUpgradeStar({
                                        content: this.$tc('common.upgrade'),
                                        callback: this.onClickUpgrade,
                                      })}
                                    </span>
                                  )}
                                  {subEvent.tips && <GTooltip class="ml-2" content={subEvent.tips} mode="icon"></GTooltip>}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )
    },
    renderSendingConfigForm() {
      return (
        <BEmailSendingConfigForm
          ref="BEmailSendingConfigFormRef"
          alertConfig={this.alertConfigModel}
          onUpdate={this.onUpdateEmailConfig}
        ></BEmailSendingConfigForm>
      )
    },
    renderSearchResultAlertCondition() {
      if (!this.isSearchResultAlert) return null

      const mustText = this.alertConfigModel.query_display_name?.[this.locale].must || ''
      const filterText = this.alertConfigModel.query_display_name?.[this.locale].filter || ''

      if (!mustText && !filterText) return null

      if (!this.isStructureSearchResultAlert) {
        return (
          <div class="mt-4 px-4 py-2 border border-gray-30 rounded space-y-1">
            <div class="flex items-start leading-6 text-sm text-text-t1" title={mustText}>
              <div class="flex-shrink-0 font-semibold">{this.$t('BEmailAlert.query')}:</div>
              <div class="truncate ml-2">{mustText}</div>
            </div>
            {filterText ? (
              <div class="flex items-start leading-6 text-sm text-text-t1" title={filterText}>
                <div class="flex-shrink-0 font-semibold">{this.$t('BEmailAlert.filter')}:</div>
                <div class="truncate ml-2">{filterText}</div>
              </div>
            ) : null}
          </div>
        )
      }

      if (this.isStructureSearchResultAlert && !this.alertConfigModel.query?.structure_query?.smiles) return null

      return (
        <div class="mt-4 px-4 py-2 border border-gray-30 rounded space-y-1">
          <div class="flex items-start leading-6 text-sm text-text-t1">
            <StructureImg
              class="flex-shrink-0"
              source={this.alertConfigModel.query?.structure_query?.smiles || ''}
              imgSize={100}
              showBackground
              showBorder
            ></StructureImg>
            <div class="line-clamp-3 leading-5 ml-2" title={mustText} domPropsInnerHTML={mustText}></div>
          </div>
          {filterText ? (
            <div class="flex items-start leading-6 text-sm text-text-t1" title={filterText}>
              <div class="flex-shrink-0 font-semibold">{this.$t('BEmailAlert.filter')}:</div>
              <div class="truncate ml-2">{filterText}</div>
            </div>
          ) : null}
        </div>
      )
    },
    renderFooter() {
      return (
        <div
          class="flex justify-end py-4 pr-4 bg-white-default"
          style="box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 3px 8px 0px rgba(0, 0, 0, 0.14);"
        >
          <button class={['pt-ui-btn']} type="button" data-type="default" onClick={this.onCancel}>
            {this.$t('common.cancel')}
          </button>
          <button class={['pt-ui-btn', 'ml-2']} type="button" data-type="submit" onClick={this.onSubmit}>
            {this.$t('common.submit')}
          </button>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="text-sm bg-white-default flex flex-col rounded" style="height: 80vh; min-width: 1080px" v-ls-loading={this.loading}>
        <div class="flex flex-1 overflow-hidden">
          <div style="width:50%;" class="p-6 pr-0 flex-1 flex flex-col">
            <div class="flex items-center">
              <div class="font-bold text-lg">{this.mode === 'update' ? this.$t('BEmailAlert.updateAlert') : this.$t('BEmailAlert.createAlert')}</div>
              <GGuideButton class="ml-2" href={EMAIL_ALERT_GUIDANCE}></GGuideButton>
            </div>
            <div class="overflow-y-auto mt-2 pr-6 flex-1">
              {this.renderMultiEditAlertWarning()}
              {this.renderCreatedAlertEntity()}
              {this.renderAlertTitleForm()}
              {this.renderSearchResultAlertCondition()}
              {this.renderEventSettingForm()}
              {this.renderSendingConfigForm()}
            </div>
          </div>
          <BEmailPreview
            style="width:50%"
            alertKeys={this.topEventSelectKeys}
            alertType={this.alertType}
            alertConfig={this.alertConfigModel}
          ></BEmailPreview>
        </div>
        <div>{this.renderFooter()}</div>
      </div>
    )
  },
})
