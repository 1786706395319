import { BAcTagList, BDatePicker } from '@pharmsnap/shared/components'
import { IBAcTag } from '@pharmsnap/shared/types'
import { CustomDateRageConfig, ICustomDateKey, replaceTimeSplitCode, transformDateString2timestamp } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, reactive, toRefs, watch } from '@vue/composition-api'
import $classes from './BCustomDatePicker.module.scss'

export const BCustomDatePicker = defineComponent({
  name: 'BCustomDatePicker',
  props: {
    selectedItems: {
      type: Array as PropType<IBAcTag[]>,
      required: true,
    },
    customItems: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    valKey: {
      type: String as PropType<ICustomDateKey>,
      required: true,
    },
    limit: {
      type: Number,
    },
    isClickAble: {
      type: Boolean,
      default: true,
    },
    disableLabel: {
      type: String,
      default: '',
    },
    isUtc: {
      type: Boolean,
      default: false,
    },
    dataPickerValueFormat: {
      type: String,
    },
    dataPickerFormat: {
      type: String,
    },
    dataPickerType: {
      type: String as PropType<
        'year' | 'month' | 'date' | 'dates' | 'week' | 'datetime' | 'datetimerange' | 'daterange' | 'monthrange' | 'yearrange'
      >,
    },
  },
  setup(props, { emit }) {
    const data = reactive<{
      dateTags: IBAcTag[]
      datePickerVisible: boolean
    }>({
      dateTags: generateDateRange(),
      datePickerVisible: false,
    })
    const totalLength = computed(() => data.dateTags.length)

    const datePopoverClass = computed(() => {
      const customTag = data.dateTags.find((o) => o.flag === 'custom')
      if (customTag && customTag.id) {
        return [$classes.datePickerContainer, '-right-36']
      }
      return [$classes.datePickerContainer, '-right-48']
    })

    function selectAll() {
      emit('selectItem', [
        {
          name_en: 'All',
          id: 'All',
          name_cn: 'All',
        },
      ])
    }

    function getInitCustomTag(): IBAcTag {
      return {
        name_en: 'Custom date range',
        id: '',
        name_cn: '自定义',
        flag: 'custom',
      }
    }

    function resetCustom() {
      const custom = data.dateTags.find((o) => o.flag === 'custom')
      if (custom && custom.id) {
        custom.name_en = 'Custom date range'
        custom.name_cn = '自定义'
        custom.id = ''
      }
    }

    function handleSelectTag(item: IBAcTag) {
      if (!props.isClickAble) return
      let selected = props.selectedItems
      resetCustom()
      if (item.id === 'All') {
        selectAll()
      } else {
        const isExist = selected.find((o) => o.id === item.id)
        if (isExist) {
          selected = selected.filter((o) => o.id !== item.id)
        } else {
          selected.push(item)
        }
        selected = selected.filter((o) => o.id !== 'All')
        if (!selected.length) {
          selected = [{ name_en: 'All', id: 'All' }]
        }
        if (props.limit && selected.length > props.limit) {
          selected = selected.slice(-props.limit)
        }
        const hasSelected = selected.length
        if (hasSelected === totalLength.value - 1) {
          selected = [{ name_en: 'All', id: 'All' }]
        }
        emit('selectItem', selected)
      }
    }

    function handleSelectDateRange(item: IBAcTag) {
      if (!props.isClickAble) return
      if (item.flag === 'custom') {
        data.datePickerVisible = true
      } else {
        handleSelectTag(item)
      }
    }

    function generateDateRange() {
      const valTags: IBAcTag[] = CustomDateRageConfig[props.valKey].map((item) => {
        return {
          name_en: item.name_en,
          name_cn: item.name_cn,
          id: `${item.val}-${item.unit}`,
          customDate: true,
          calcType: item.calcType,
        }
      })
      valTags.unshift({
        name_en: 'All',
        id: 'All',
        name_cn: 'All',
      })
      valTags.push(getInitCustomTag())
      return valTags
    }

    function handleDatePickerClick(date: string[]) {
      const custom = data.dateTags.find((o) => o.flag === 'custom')
      if (custom) {
        if (date && date.length) {
          const displayName = `${replaceTimeSplitCode(date[0])}-${replaceTimeSplitCode(date[1])}`
          custom.name_cn = displayName
          custom.name_en = displayName
          custom.id = `${transformDateString2timestamp(date[0], false, 'day', props.isUtc)}-${transformDateString2timestamp(
            date[1],
            true,
            'day',
            props.isUtc
          )}`
          emit('selectItem', [custom])
        } else {
          resetCustom()
          selectAll()
        }
      }
      data.datePickerVisible = false
    }

    const datePickerVal = computed(() => {
      const custom = data.dateTags.find((tag) => tag.flag === 'custom')
      if (custom && custom.id) {
        return custom.name_en.split('-').map((o) => o.replaceAll('/', '-'))
      }
      return []
    })

    watch(
      () => props.customItems,
      () => {
        if (props.customItems.length) {
          const custom = data.dateTags.find((o) => o.flag === 'custom')
          const [item] = props.customItems
          if (custom) {
            custom.name_en = item.name_en
            custom.name_cn = item.name_cn
            custom.id = item.id
          }
        } else {
          resetCustom()
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return { ...toRefs(data), handleSelectDateRange, handleDatePickerClick, datePickerVal, datePopoverClass }
  },
  render() {
    return (
      <div class="relative">
        <BAcTagList
          tagItems={this.dateTags}
          selectedItems={this.selectedItems}
          isClickAble={this.isClickAble}
          disableLabel={this.disableLabel}
          onClick={(val: IBAcTag) => this.handleSelectDateRange(val)}
        ></BAcTagList>
        {this.datePickerVisible ? (
          <div class={this.datePopoverClass}>
            <BDatePicker
              class={$classes.datePicker}
              value={this.datePickerVal}
              onClick={this.handleDatePickerClick}
              onClickOutside={() => (this.datePickerVisible = false)}
              type={this.dataPickerType}
              format={this.dataPickerFormat}
              valueFormat={this.dataPickerValueFormat}
            ></BDatePicker>
          </div>
        ) : null}
      </div>
    )
  },
})
