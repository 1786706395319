import { defineComponent, PropType } from '@vue/composition-api'
import { VNode } from 'vue'
import $classes from './GTimeline.module.scss'

export const GTimeline = defineComponent({
  name: 'GTimeline',
  props: {
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    mode: {
      type: String as PropType<'left' | 'right' | 'alternate' | 'top' | 'bottom'>,
      default: 'bottom',
    },
    innerContainerCls: {
      type: String,
      default: '',
    },
  },
  methods: {
    getDirectionCls(ele: VNode, idx: number) {
      if (this.mode === 'alternate') {
        if (this.direction === 'vertical') {
          return idx % 2 === 0 ? 'left' : 'right'
        } else {
          return idx % 2 === 0 ? 'top' : 'bottom'
        }
      } else {
        if (this.direction === 'vertical' && (this.mode === 'top' || this.mode === 'bottom')) {
          return 'right'
        }

        if (this.direction === 'horizontal' && (this.mode === 'left' || this.mode === 'right')) {
          return 'bottom'
        }
        return this.mode
      }
    },
  },
  render() {
    const { direction } = this
    const children: VNode[] = this.$slots.default || []

    const items = children?.map((ele: VNode, idx: number) => {
      ele.componentOptions && (ele.componentOptions.propsData = { ...ele.componentOptions?.propsData, mode: this.getDirectionCls(ele, idx) })
      return ele
    })

    return (
      <div class={direction === 'vertical' ? '' : 'flex items-center'} style="height:inherit; width:inherit">
        <div class={direction === 'vertical' ? '' : ['flex items-center h-full w-full', this.innerContainerCls]}>
          <ul class={direction === 'vertical' ? [$classes.timeline, $classes.timelineVertical] : [$classes.timeline, $classes.timelineHorizontal]}>
            {items}
          </ul>
        </div>
      </div>
    )
  },
})
