import { defineComponent, PropType } from '@vue/composition-api'
import $classes from './GNavMenu.module.scss'
export const GNavMenu = defineComponent({
  name: 'GNavMenu',
  props: {
    menu: {
      required: true,
      type: Array as PropType<Array<{ label: string; id: string; disabled?: boolean }>>,
    },
    activeId: {
      type: String,
    },
  },
  setup(props, ctx) {
    function onClickMenu(id: string) {
      ctx.emit('clickMenu', id)
    }
    return { onClickMenu }
  },
  render() {
    return (
      <div class={$classes.menu}>
        {this.menu.map((item) => {
          const { id, label, disabled } = item
          return (
            <div
              onClick={() => {
                this.onClickMenu(id)
              }}
              class={[
                $classes.menuItem,
                {
                  [$classes.menuItemActive]: id === this.activeId,
                  [$classes.menuItemDisabled]: disabled,
                },
              ]}
            >
              {label}
            </div>
          )
        })}
      </div>
    )
  },
})
