import '@pharmsnap/shared/src/assets/icon-svg/startups.svg'
import { defineComponent } from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BStartupTag = defineComponent({
  name: 'BStartupTag',
  props: {},
  i18n: {
    messages: {
      cn,
      en,
    },
  },

  render() {
    return (
      <div
        data-testid="b-startup"
        class="inline-flex items-center rounded py-[2px] px-1 space-x-1 bg-green-tag text-green-default text-xs h-5 self-center"
      >
        <GIcon svgName="Startups" color="#45A321" />
        <div>{this.$t('organizationCard.startups')}</div>
      </div>
    )
  },
})
