import PtSelectMenu from '@patsnap-biz/select-menu'
import { ICreateUpdateAlertParams, IQueryDataType, IQueryGroupType } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { useAuthStore, useExportDrug, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { useEmailAlertDialog } from '@pharmsnap/shared/src/composition/useEmailAlertDialog'
import { useWorkspace } from '@pharmsnap/shared/src/composition/useWorkspace'
import { ComputedRef, PropType, computed, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { useKgEntrance } from '../../../BKgEntrance'
import { BWorkspaceDialog } from '../../../BWorkspace/BWorkspaceDialog'
import { ISupportWorkspaceDataType } from '../../../BWorkspace/type'
import { ICheckMenuType, ICommonListConfig, ICommonListService } from '../../types'
import { entity2AlertEntity } from '../../utils'
import $classes from './BSelectMenu.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BSelectMenu = defineComponent({
  name: 'BSelectMenu',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    dataType: {
      type: String as PropType<IQueryDataType>,
      default: 'drug',
    },
    checkedMap: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    commonListConfig: {
      type: Object as PropType<ICommonListConfig>,
      required: true,
    },
    searchAllLength: {
      type: Number,
      default: 1000,
    },
    allowExportMaxLength: {
      type: Number,
      default: 300,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    commonListService: {
      type: Object as PropType<ICommonListService>,
    },
    groupByValue: {
      type: String as PropType<IQueryGroupType>,
    },
    menus: {
      type: Array as PropType<ICheckMenuType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否检测 用户的权限
    isCheckJurisdiction: {
      type: Boolean,
      default: true,
    },
    navToAnalyticsFunc: {
      type: Function as PropType<(ids: string[]) => void>,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const { localeUpcase, ts } = useLocale()
    const {
      getters: {
        isBasicUser,
        isEnterpriseUser,
        patentExportLimit,
        drugExportLimit,
        drugDealExportLimit,
        clinicalTrialExportLimit,
        clinicalTrialResultExportLimit,
        translationalMedicineExportLimit,
        paperExportLimit,
      },
    } = useAuthStore()
    const { showEmailAlertDialog } = useEmailAlertDialog()
    const { handleExportDrug } = useExportDrug()
    const { handleEnterKg } = useKgEntrance({
      state: props.queryService.state,
      commonListServiceState: props.commonListService?.state,
      type: props.groupByValue || props.dataType,
    })

    const { showWorkspaceDialog, handleClick: handleClickWorkspace, handleClose: handleCloseWorkspace } = useWorkspace()

    const menuConfig: ComputedRef<Record<ICheckMenuType, { icon: string; label: string; disabled?: boolean; limit?: number; tip?: string }>> =
      computed(() => {
        return {
          emailAlert: {
            icon: 'SolidEmailAlerts',
            label: ts('BSelectMenu.emailAlert'),
          },

          compare: {
            icon: 'Vs',
            label: ins?.proxy.$tc(`BSelectMenu.${props.commonListConfig.compareType}`) as string,
            limit: 4,
            tip: ts(`BSelectMenu.compareLimitTip.${props.commonListConfig.compareType}`, {
              count: 4,
            }),
          },
          export: {
            icon: 'SolidExport',
            label: ins?.proxy.$tc('BSelectMenu.export') as string,
          },
          enterKg: {
            icon: 'KgExploration',
            label: ts('BSelectMenu.enterKg'),
          },
          navToAnalytics: {
            icon: 'LogoAnalytics',
            label: ts('BSelectMenu.navToAnalytics'),
            tip: ts('BSelectMenu.navTip'),
            limit: 50,
          },
          workspace: {
            icon: 'SolidFolderMany',
            label: ts('BSelectMenu.workspace'),
          },
        }
      })

    function unselectClick() {
      emit('unselectClick')
    }
    function getDefaultEmailAlertConfig(): ICreateUpdateAlertParams | undefined {
      if (
        checkedIdList.value.length &&
        props.commonListConfig.emailAlertConfig?.entityAlertConfig?.alertType &&
        props.commonListConfig.emailAlertConfig.entityAlertConfig.entityType
      ) {
        return {
          alert_type: props.commonListConfig.emailAlertConfig?.entityAlertConfig?.alertType,
          batch_entity: entity2AlertEntity(
            props.commonListConfig.emailAlertConfig.entityAlertConfig.entityType,
            Object.values(props.checkedMap).filter(Boolean)
          ),
        }
      }
      return undefined
    }
    async function createEmailDialog() {
      const defaultEmailAlertConfig = getDefaultEmailAlertConfig()
      if (!defaultEmailAlertConfig) {
        return
      }
      showEmailAlertDialog({
        componentProps: {
          defaultEmailAlertConfig,
        },
      })
    }

    /**
     * 检测是否拥有 导出 权限 付费(基础)用户true,其他用户false
     * @returns
     */
    function checkExportJurisdiction(): boolean {
      return isBasicUser.value || isEnterpriseUser.value
    }

    async function onClickExport() {
      const fieldMap: Partial<Record<IQueryDataType, number>> = {
        patent: patentExportLimit.value,
        drug: drugExportLimit.value,
        organization: 0,
        target: 0,
        disease: 0,
        clinical_trial: clinicalTrialExportLimit.value,
        clinical_trial_result: clinicalTrialResultExportLimit.value,
        drug_deal: drugDealExportLimit.value,
        news: 0,
        paper: paperExportLimit.value,
        funding_round: 0,
        funded_research: 0,
        drug_type: 0,
        all: 0,
        region: 0,
        original_dev_organization: 0,
        dev_organization: 0,
        translational_medicine: translationalMedicineExportLimit.value,
      }
      const preExportCount = props.commonListConfig.dataType ? fieldMap[props.commonListConfig.dataType] : 0
      await handleExportDrug({
        disable: props.disabled,
        showUpgrade: props.isCheckJurisdiction && !checkExportJurisdiction(),
        dataType: props.queryService.state.data_type,
        lang: localeUpcase.value,
        checkedIdList: checkedIdList.value,
        commonListConfig: props.commonListConfig,
        checkedMap: props.checkedMap,
        queryService: props.queryService,
        resultExportLength: exportLength.value,
        limitPreExport: isUndefined(preExportCount) ? props.searchAllLength : preExportCount || 0,
      })
    }

    function handleClickMenu(e: Event, data: { menu: { icon: string; label: string; value: ICheckMenuType }; selecteds: string[] }) {
      if (data.menu.value === 'export') {
        onClickExport()
      }
      if (data.menu.value === 'compare' && props.commonListConfig.compareType && props.commonListConfig.compareFunc) {
        const func = props.commonListConfig.compareFunc
        const ids = data.selecteds.map((o) => o.split(',')).flat()
        openPageWithoutPending(() => func(ids.join(',')))
      }
      if (data.menu.value === 'enterKg') {
        handleEnterKg()
      }
      if (data.menu.value === 'navToAnalytics') {
        const func = props.navToAnalyticsFunc
        if (func) {
          func(data.selecteds)
        }
      }
      if (data.menu.value === 'workspace') {
        handleClickWorkspace()
      }
      if (data.menu.value === 'emailAlert') {
        createEmailDialog()
      }
    }

    const displayMenus = computed(() => {
      return props.menus.map((menu) => {
        const limit = menuConfig.value[menu].limit
        return {
          ...menuConfig.value[menu],
          value: menu,
          disabled: limit ? checkedIdList.value.length > limit : false,
        }
      })
    })

    const checkedRows = computed(() => {
      return Object.keys(props.checkedMap).reduce((acc, curr) => (props.checkedMap[curr] ? [...acc, curr] : acc), [] as string[])
    })

    const checkedIdList = computed(() => {
      return Object.keys(props.checkedMap).reduce((acc, curr) => (props.checkedMap[curr] ? [...acc, ...curr.split(',')] : acc), [] as string[])
    })

    const exportLength = computed(() => {
      return checkedIdList.value.length ? checkedIdList.value.length : props.searchAllLength
    })

    return { unselectClick, showWorkspaceDialog, handleCloseWorkspace, handleClickMenu, displayMenus, checkedIdList, checkedRows }
  },
  methods: {
    renderWorkspaceDialog() {
      if (this.showWorkspaceDialog) {
        return (
          <BWorkspaceDialog
            onClose={this.handleCloseWorkspace}
            dataType={this.dataType as ISupportWorkspaceDataType}
            selectedIds={this.checkedIdList}
          ></BWorkspaceDialog>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        <PtSelectMenu
          class={$classes.selectMenu}
          selecteds={this.checkedRows}
          menus={this.displayMenus}
          {...{
            on: {
              'unselect-click': this.unselectClick,
              'menu-click': this.handleClickMenu,
            },
          }}
        ></PtSelectMenu>
        {this.renderWorkspaceDialog()}
      </div>
    )
  },
})
