import { computed, isRef, Ref, ref } from '@vue/composition-api'
import { LIST_PAGE_PAGE_SIZE_LIST } from '../constants'

export function usePagination(
  initCurrentPage: number | Ref<number>,
  initPageSize: number | Ref<number>,
  func: (page: number, pageSize: number) => Promise<void> | void
) {
  const currentPage = isRef(initCurrentPage) ? initCurrentPage : ref(initCurrentPage)
  const pageSize = isRef(initPageSize) ? initPageSize : ref(initPageSize)
  const pageSizes = ref([...LIST_PAGE_PAGE_SIZE_LIST])
  const total = ref(0)
  const offset = computed(() => (currentPage.value - 1) * pageSize.value)
  const onCurrentPageChange = (page: number) => {
    currentPage.value = page
    func(currentPage.value, pageSize.value)
  }

  const onPageSizeChange = (size: number) => {
    currentPage.value = 1
    pageSize.value = size
    func(currentPage.value, size)
  }
  const onResetPage = () => {
    currentPage.value = 1
  }

  return {
    currentPage,
    pageSize,
    offset,
    pageSizes,
    total,
    onCurrentPageChange,
    onPageSizeChange,
    onResetPage,
  }
}
