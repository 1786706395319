import { useLocale } from '@pharmsnap/shared/composition'
import { IBaseDictItem } from '@pharmsnap/shared/types'
import { getDictItemName, informationSourceStyleMap } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import $style from './BInformationSourceTag.module.scss'

export const BInformationSourceTag = defineComponent({
  name: 'BInformationSourceTag',
  props: {
    tag: {
      type: Object as PropType<IBaseDictItem>,
      required: true,
    },
  },
  setup(props) {
    const { locale, localeUpcase } = useLocale()

    const tagClass = computed(() => informationSourceStyleMap[props.tag.dict_id]?.className)

    const displayName = computed(() => getDictItemName(props.tag, localeUpcase.value))

    return { locale, tagClass, displayName }
  },
  render() {
    return (
      <ElTag
        class={[$style.bInformationSourceTag, this.tagClass ? $style[this.tagClass] : '']}
        disable-transitions={true}
        type="info"
        size="small"
        effect="plain"
      >
        <div class="truncate">{this.displayName}</div>
      </ElTag>
    )
  },
})
