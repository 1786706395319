import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { injectLangFieldByKey, replaceDisplayNameByShortName, transformGroupAggregationSingleResultByMap } from './base'

export const patentFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  YEAR: injectLangFieldByKey,
  PHS_TARGET_ID: replaceDisplayNameByShortName,
  AUTHOR: injectLangFieldByKey,
  AUTHOR_FACET: injectLangFieldByKey,
  JOURNAL_FACET: injectLangFieldByKey,
  JOURNAL_PATSNAP_IF_WOS: injectLangFieldByKey,
}

export function transformLiteratureAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, patentFieldTransformFnMap, xCorrelationId)
}
