/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, getCurrentInstance, nextTick } from '@vue/composition-api'

export function useRouteQuery<T = any>(key: string, defaultVal: T, mode: 'replace' | 'push' = 'replace', transformer?: (data: T) => T) {
  const ins = getCurrentInstance()
  const route = computed(() => ins?.root.proxy.$route)
  const router = computed(() => ins?.root.proxy.$router)

  const value = computed<any>({
    get() {
      const data = route.value?.query[key]
      if (data === null || data === undefined) {
        return transformer ? transformer(defaultVal) : defaultVal
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return data.filter(Boolean).map((i) => (transformer ? transformer(i) : i))
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return transformer ? transformer(data) : data
    },
    set(v) {
      if (router.value) {
        router.value[mode]({
          query: { ...route.value?.query, [key]: v },
        }).catch((error) => ({}))
      }
    },
  })

  return {
    value,
  }
}
