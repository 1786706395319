import '@patsnap-ui/icon/assets/logo/Bio.svg'
import '@patsnap-ui/icon/assets/logo/Chemical.svg'
import { VUE_APP_BIO_URL, VUE_APP_PRODUCT_CHEMICAL_URL } from '@pharmsnap/pharmsnap-web/config'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { openNewTab } from '@pharmsnap/shared/src/utils'
import { PropType, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const ProductPromotionDoubleInnerCard = defineComponent({
  name: 'ProductPromotionDoubleInnerCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    type: {
      type: String as PropType<'bio' | 'chemical'>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const titleMap = {
      bio: ins?.proxy.$t('productPromotionDoubleInnerCard.bioTitle'),
      chemical: ins?.proxy.$t('productPromotionDoubleInnerCard.chemicalTitle'),
    }
    const logoMap = {
      bio: 'LogoBio',
      chemical: 'LogoChemical',
    }
    const brandNameMap = {
      bio: ins?.proxy.$t('productPromotionDoubleInnerCard.bio'),
      chemical: ins?.proxy.$t('productPromotionDoubleInnerCard.chemical'),
    }
    const descMap = {
      bio: [
        ins?.proxy.$t('productPromotionDoubleInnerCard.bioDesc1') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.bioDesc2') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.bioDesc3') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.bioDesc4') as string,
      ],
      chemical: [
        ins?.proxy.$t('productPromotionDoubleInnerCard.chemicalDesc1') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.chemicalDesc2') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.chemicalDesc3') as string,
        ins?.proxy.$t('productPromotionDoubleInnerCard.chemicalDesc4') as string,
      ],
    }

    const linkMap = {
      bio: `${VUE_APP_BIO_URL}/landing.html`,
      chemical: `${VUE_APP_PRODUCT_CHEMICAL_URL}/landing.html`,
    }

    const handleFreeTrial = () => {
      const link = linkMap[props.type]
      link && openNewTab(link)
      sharedCtx.service.tracking.trackGeneral({
        event_code: 'CLICK_LS_PRODUCT_GUIDANCE',
        trigger_point: 'DIALOG_GUIDANCE',
        common1: props.type.toUpperCase(),
      })
      emit('trial')
    }

    return { titleMap, logoMap, brandNameMap, descMap, handleFreeTrial }
  },
  render() {
    return (
      <div class="rounded-[20px] flex flex-col border border-blue-default w-[340px] overflow-hidden relative">
        <div
          class="ml-auto flex-shrink-0 text-white-default px-3 py-[2px] w-fit font-semibold rounded-bl-xl"
          style="background: linear-gradient(90deg, #1D8BF8 1.58%, #36C99E 110.94%);"
        >
          {this.titleMap[this.type]}
        </div>
        <div class="flex flex-col flex-1 px-[18px] pb-7">
          <div class="flex flex-shrink-0 gap-x-1 items-center">
            <PtIcon icon={this.logoMap[this.type]} class={['w-8 h-8']}></PtIcon>
            <div class="font-semibold text-2xl">{this.brandNameMap[this.type]}</div>
          </div>
          <div class="mt-6 flex-1 space-y-2">
            {this.descMap[this.type]
              .filter((item) => !!item?.trim())
              .map((item) => (
                <div class="flex items-center gap-x-3">
                  <PtIcon icon="SolidTick" class="text-green-default text-2xl flex-shrink-0" />
                  <div class="text-sm" style="word-break: break-word;">
                    {item}
                  </div>
                </div>
              ))}
          </div>

          <button onClick={this.handleFreeTrial} class="pt-ui-btn w-full mt-6 flex-shrink-0" type="button" data-type="submit" block>
            {this.$t('productPromotionDoubleInnerCard.freeTrial')}
          </button>
        </div>
      </div>
    )
  },
})
