import { drugDealColumnFieldLangMap, EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { IDrugDealDetail, ITranslateLang } from '@patsnap/synapse_common_interface'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import {
  renderDealCountryInTable,
  renderDealDateInTable,
  renderDealDrugInCard,
  renderDealIndicationTable,
  renderDealTotalValueInTable,
  renderPartnerOrgIdOneidInTable,
  renderPrincipleOrgIdOneidInTable,
  renderTargetDealInTable,
} from '@pharmsnap/shared/src/render'
import { getRedirectLinkStatic, isOpenTranslate } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import { GDescription } from '../../../ui/GDescription/GDescription'
import { GLink } from '../../../ui/GLink/GLink'
import { GTranslate } from '../../../ui/GTranslate/GTranslate'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import { BEntityLoadingCard } from '../../card/BEntityLoadingCard/BEntityLoadingCard'
import $classes from '../BCard.module.scss'

export const BCardDrugDeal = defineComponent({
  name: 'BCardDrugDeal',
  props: {
    data: {
      type: Object as PropType<IDrugDealDetail>,
      required: true,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props) {
    const { mode } = toRefs(props)
    const { locale } = useLocale()
    const {
      getters: { customSettingTranslation },
    } = useAuthStore()

    const translationLang = computed(() => props.forceTranslation || customSettingTranslation.value)
    const isExecuteTranslate = computed(() => {
      if (props.disabledTranslate) {
        return false
      }
      return isOpenTranslate(props.data.deal_title_translation, translationLang.value)
    })

    const isIntelligentMode = computed(() => mode.value === 'intelligent')

    return { locale, isExecuteTranslate, translationLang, isIntelligentMode }
  },
  methods: {
    renderHeader() {
      const sourceUrl = this.data.data_source?.find((item) => item.in_use)?.source_url
      const dealTitle = this.data.deal_title
      const dealTitleTranslation = this.data.deal_title_translation || []
      const translateTitle = dealTitleTranslation.find((item) => item.lang === this.translationLang)?.deal_title

      // 如果是智能检索场景下，卡片标题两行省略，并且需要展示Drug Deal类型标签
      const content =
        sourceUrl && dealTitle ? (
          <GLink
            href={getRedirectLinkStatic(sourceUrl, this.locale)}
            name={dealTitle}
            newTab={true}
            truncate={false}
            class={['font-semibold text-base leading-6 pharm-hover--enable', this.isIntelligentMode ? 'line-clamp-2' : 'line-clamp-1']}
          ></GLink>
        ) : (
          <div class={['font-semibold text-base text-text-t1 leading-6', this.isIntelligentMode ? 'line-clamp-2' : 'line-clamp-1']} slot="header">
            {dealTitle || EMPTY_PLACEHOLDER}
          </div>
        )

      return (
        <GTranslate execute={this.isExecuteTranslate} content={translateTitle}>
          {content}
        </GTranslate>
      )
    },
    renderBody() {
      return (
        <GDescription
          data={this.data}
          column={this.isIntelligentMode ? 4 : 6}
          labelWidthIsSpecified={false}
          items={[
            {
              label: `${this.$tc('synapse_i18n.data_type.drug')}:`,
              field: 'drug',
              contentCls: `${$classes.contentItem}`,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.data_type.target')}:`,
              field: 'target',
              contentCls: $classes.contentItem,
              span: 2,
            },
            {
              label: drugDealColumnFieldLangMap.DEAL_DISEASE_ID?.[this.locale] || '',
              field: 'disease',
              contentCls: $classes.contentItem,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.dealDate')}:`,
              field: 'dealDate',
              contentCls: `${$classes.contentItem} mr-4`,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.country')}:`,
              field: 'country',
              contentCls: $classes.contentItem,
              span: this.isIntelligentMode ? 2 : 4,
              labelCls: 'w-auto',
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.transferorInCard')}:`,
              field: 'transferor',
              contentCls: `${$classes.contentItem} mr-4`,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.acquirerInCard')}:`,
              field: 'acquirer',
              contentCls: $classes.contentItem,
              span: this.isIntelligentMode ? 2 : 4,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.totalPayment')}:`,
              field: 'totalPayment',
              contentCls: `${$classes.contentItem} mr-4`,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.upfrontPayment')}:`,
              field: 'upfrontPayment',
              contentCls: `${$classes.contentItem} mr-1`,
              span: 2,
            },
            {
              label: `${this.$tc('synapse_i18n.drug_deal.milestone')}:`,
              field: 'milestone',
              contentCls: $classes.contentItem,
              span: 2,
            },
          ]}
          scopedSlots={{
            drug: () => renderDealDrugInCard(this.data),
            target: () => renderTargetDealInTable(this.data, 1, true),
            disease: () => renderDealIndicationTable(this.data, 1, true),
            dealDate: () => renderDealDateInTable(this.data, this.$i18n),
            country: () => renderDealCountryInTable(this.data, this.$i18n, true),
            transferor: () => renderPrincipleOrgIdOneidInTable(this.data, 1, true),
            acquirer: () => renderPartnerOrgIdOneidInTable(this.data, 1, true),
            totalPayment: () => renderDealTotalValueInTable(this.data, 'total_value', this.$i18n),
            upfrontPayment: () => renderDealTotalValueInTable(this.data, 'upfront_payment', this.$i18n),
            milestone: () => renderDealTotalValueInTable(this.data, 'milestone_payment', this.$i18n),
          }}
        ></GDescription>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc={false} loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={[$classes.container, this.isIntelligentMode ? 'mb-0' : '']}>
          <div slot="header" class={[!this.isIntelligentMode ? 'px-4 pt-4' : '', 'mb-3']}>
            {this.renderHeader()}
          </div>
          {this.renderBody()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
