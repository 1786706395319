import '@patsnap-ui/icon/assets/solid/SearchLeft.svg'
import { ElInput } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, getCurrentInstance, ref, toRefs } from '@vue/composition-api'
import { GDropdownSelect } from '../../ui/GDropdownSelect/GDropdownSelect'
import { GIcon } from '../../ui/GIcon/GIcon'
import $style from './BPtCollapseCountry.module.scss'
import { COUNTRY_2_CODE_LIST, COUNTRY_PLACEHOLDER } from './config'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BPtCollapseCountry = defineComponent({
  name: 'BPtCollapseCountry',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    alpha2Code: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    addPlaceholderCountry: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { selectable, addPlaceholderCountry } = toRefs(props)
    const keyword = ref('')
    const ins = getCurrentInstance()

    const options = computed(() => {
      const selectOptions = selectable.value
        ? COUNTRY_2_CODE_LIST.map((code) => ({ value: code, label: `${code} - ${ins?.proxy.$tc(`collapseCountry.${code}`) || ''}` }))
        : []

      if (addPlaceholderCountry.value) {
        selectOptions.unshift({
          value: COUNTRY_PLACEHOLDER,
          label: ins?.proxy.$tc('collapseCountry.placeholder') || '',
        })
      }

      return selectOptions
    })

    const displayOptions = computed(() => {
      if (!keyword.value.trim()) return options.value

      const words = keyword.value.trim()

      if (words === COUNTRY_PLACEHOLDER) return []

      return options.value.filter((i) => i.label.toUpperCase().includes(words.toUpperCase()) || i.value.includes(words.toUpperCase()))
    })

    return {
      options: displayOptions,
      keyword,
    }
  },
  methods: {
    handleChange(val: string) {
      val !== this.alpha2Code && this.$emit('change', val)
    },
    handleHide() {
      this.keyword = ''
    },
  },
  render() {
    return (
      <GDropdownSelect
        scrollToSelect={true}
        pickDisplayLabel={(item) => (item.value === COUNTRY_PLACEHOLDER ? item.label : item.value) as string}
        width={60}
        height={'302px'}
        value={this.alpha2Code}
        options={this.options}
        popoverClass={$style.popperClass}
        onInput={this.handleChange}
        onHide={this.handleHide}
      >
        <div slot="listHeader">
          <ElInput size="small" v-model={this.keyword} placeholder={this.$tc('common.quickSearch')}>
            <GIcon slot="prefix" style="margin-top: 4px;" svgName="SolidSearchLeft" size={24}></GIcon>
          </ElInput>
        </div>
      </GDropdownSelect>
    )
  },
})
