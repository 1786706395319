import { ILang } from '@patsnap/synapse_common_interface'
import { getShownEntityProfile } from '@patsnap/synapse_common_utils'
import { join, map } from 'lodash'
import { GLimitedContainer } from '../components'
import { IBaseDiseaseInfo } from '../types'
import { getDiseaseSynonyms } from '../utils/disease'
import { renderLimitedSynonyms } from './baseRender'

export function renderDiseaseSynonyms(data: IBaseDiseaseInfo, lang: ILang) {
  return renderLimitedSynonyms(getDiseaseSynonyms(data, lang), 3)
}

export function renderDiseaseIntroductionPlain(data: IBaseDiseaseInfo) {
  return join(map(getShownEntityProfile(data.profile_v2), 'content'), '\n\n')
}

export function renderDiseaseIntroduction(data: IBaseDiseaseInfo) {
  const content = renderDiseaseIntroductionPlain(data)
  return (
    <GLimitedContainer limitLines={3} lineHeight={24}>
      <div domPropsInnerHTML={content} class="leading-6"></div>
    </GLimitedContainer>
  )
}
