import { IFieldLangMap } from '@pharmsnap/shared/types'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { computed } from '@vue/composition-api'
import { useLocale } from './useLocale'
export function useTranslateFieldLang(langMap: Partial<IFieldLangMap>) {
  const { locale } = useLocale()
  const translateField = computed(() => {
    return (field: string) => getFieldNameByLang(langMap, field, locale.value)
  })
  return { translateField }
}
