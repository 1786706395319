/* eslint-disable vue/no-mutating-props */
import { sharedCtx } from '@pharmsnap/shared/context'
import { useLocale } from '@pharmsnap/shared/src/composition'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { IBaseOrgInfo } from '@pharmsnap/shared/types'
import { checkOrganizationIsClosed, getOrganizationName } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import { GIconLink } from '../../ui/GIconLink/GIconLink'
import { BIcon } from '../BIcon/BIcon'
import { BOrganizationPopperCard } from '../card/BOrganizationCard/BOrganizationPopperCard'
import { BEntityTag } from '../tag/BEntityTag/BEntityTag'

export const BOrganizationItem = defineComponent({
  name: 'BOrganizationItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseOrgInfo>,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    zIndex: Number,
    placement: String,
    iconType: {
      type: String as PropType<'people' | 'company'>,
      default: 'company',
    },
    isSignature: {
      type: Boolean,
      default: true,
    },
    /**
     * 取名逻辑是否需要降级
     * 邮件提醒变更详情，需要降级，不然后端需要刷历史变更数据
     */
    isNameNeedDegrade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { data, iconType } = toRefs(props)
    const { locale } = useLocale()

    const isCompany = computed(() => iconType.value === 'company')
    const href = computed(() =>
      isCompany.value && data?.value.is_ls_related && !checkOrganizationIsClosed(data.value.entity_status) && data?.value?.entity_id
        ? sharedCtx.router.generatorOrgPath(data.value.entity_id)
        : ''
    )
    const name = computed(() => getOrganizationName(data.value, locale.value, props.isNameNeedDegrade))
    const logo = computed(() => data?.value?.logo_s3_path || data?.value?.logo)
    const isStartup = computed(() => data.value.start_up)
    return {
      isCompany,
      logo,
      href,
      name,
      isStartup,
    }
  },
  render() {
    const id = this.data.entity_id
    const content = (
      <BEntityTag
        data-testid="b-organizationName-item"
        title={this.name}
        class={{ 'cursor-pointer': id }}
        style={{ ...(this.isStartup ? { background: '#F1F8EE' } : {}) }}
      >
        <GIconLink href={this.href} name={this.name} ellipsisLine={1} data-selection-disabled={this.popover}>
          <BIcon
            border={true}
            slot="icon"
            style="margin-right: 4px;"
            class={[$utilClasses.lsFlexShrink]}
            type={this.iconType}
            src={this.isCompany ? this.logo : ''}
            isSignature={this.isSignature}
            size={16}
            {...{
              on: {
                update: (src: string) => {
                  // 避免研发状态列表开启虚拟滚动时每次都重新走一次签名组件初始化逻辑
                  if (this.isCompany) {
                    if (this.data.logo_s3_path) {
                      this.data.logo_s3_path = src
                    } else if (this.data.logo) {
                      this.data.logo = src
                    }
                  }
                },
              },
            }}
          ></BIcon>
          {this.isStartup && <GIcon svgName="Startups" slot="rightIcon" color="#45A321" class="ml-1" />}
        </GIconLink>
      </BEntityTag>
    )
    if (id && this.iconType === 'company' && this.popover) {
      return (
        <BOrganizationPopperCard id={this.data.entity_id} zIndex={this.zIndex} placement={this.placement}>
          {content}
        </BOrganizationPopperCard>
      )
    }
    return content
  },
})
