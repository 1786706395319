import { createGlobalState, useSessionStorage } from '@vueuse/core'

export function useCalculateRights() {
  const rightsState = useSessionStorage<Record<string, boolean>>('rightsSessionStorage', {})

  const handleUpdateRights = (key: string) => {
    const rightsObj = {
      ...rightsState.value,
      [key]: !rightsState.value[key],
    }
    rightsState.value = Object.keys(rightsObj).reduce((acc, cur) => {
      if (rightsObj[cur]) {
        acc[cur] = rightsObj[cur]
      }
      return acc
    }, {} as Record<string, boolean>)
  }

  return {
    rightsState,
    handleUpdateRights,
  }
}

export const useGlobalCalculateRights = createGlobalState(useCalculateRights)
