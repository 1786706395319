import '@patsnap-ui/icon/assets/solid/wechat.svg'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { BWeChatBindDialog, GIcon } from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { defineComponent, ref } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import qrCode from '../../../../../assets/img/qrCodeIcon.png'
import $style from './BWechatBind.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BWechatBind = defineComponent({
  name: 'BWechatBind',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {},
  setup() {
    const {
      getters: { hasFollowedPharmsnap },
      actions: { syncBoProfileInfo },
    } = useAuthStore()
    const showPopover = ref(false)
    const isShowBindDialog = ref(false)

    const { isCN } = useLocale()

    function bindWechatCallback() {
      syncBoProfileInfo()
      showPopover.value = false
    }

    function goUserSetting() {
      openPageWithoutPending(() => sharedCtx.router.generateUserSetting())
    }

    function handelClickOutside() {
      showPopover.value = false
    }

    function bindSuccessCallback() {
      syncBoProfileInfo()
    }
    /**
     * 绑定失败提示
     * @param error
     */
    function bindFailCallback(error: { errorMsg: string }) {
      showSingleToast({
        type: 'error',
        message: error.errorMsg,
      })
    }
    function showBindDialog() {
      isShowBindDialog.value = true
    }
    function onCloseFun() {
      isShowBindDialog.value = false
    }
    return {
      isCN,
      isShowBindDialog,
      hasFollowedPharmsnap,
      showPopover,
      bindWechatCallback,
      goUserSetting,
      handelClickOutside,
      bindSuccessCallback,
      bindFailCallback,
      showBindDialog,
      onCloseFun,
    }
  },
  methods: {
    renderFollowed() {
      return (
        <ElPopover
          placement="top-start"
          visibleArrow={false}
          trigger="manual"
          value={this.showPopover}
          offset={0}
          popperClass={this.hasFollowedPharmsnap ? $style.popperBound : $style.popperUnbound}
          {...{
            directives: this.showPopover
              ? [
                  {
                    name: 'click-outside',
                    value: {
                      handler: this.handelClickOutside,
                    },
                  },
                ]
              : [],
          }}
        >
          <div slot="reference" onClick={() => (this.showPopover = true)}>
            <div class="flex items-center pr-2 cursor-pointer">
              <GIcon svgName="SolidWechat" size={24} class={$style.wechatIcon}></GIcon>
              <div class={$style.boundLabel}>{this.$t('BWechatBind.boundWechat')}</div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex justify-between">
              <div class="flex flex-col">
                <div class="text-text-t3 text-lg font-semibold text-left mb-2">{this.$t('BWechatBind.boundTitle')}</div>
                <div class="text-text-default text-sm font-semibold leading-6">
                  <span class="leading-3">{this.$t('BWechatBind.boundContentPrev')}</span>
                  <span class="text-blue-default cursor-pointer leading-3" onClick={this.goUserSetting}>
                    {this.$t('BWechatBind.userSetting')}
                  </span>
                  <span class="leading-3" domPropsInnerHTML={this.$t('BWechatBind.boundContentNext')}></span>
                </div>
              </div>
              <div>
                <GIcon
                  svgName="SolidCloseBig"
                  size={24}
                  class="cursor-pointer flex-shrink-0"
                  nativeOn={{
                    click: () => (this.showPopover = false),
                  }}
                ></GIcon>
              </div>
            </div>
            <div class="flex justify-end mt-7">
              <button class="pt-ui-btn" type="button" data-type="submit" onClick={this.goUserSetting}>
                {this.$t('BWechatBind.goSetting')}
              </button>
            </div>
          </div>
        </ElPopover>
      )
    },
    renderUnFollowed() {
      return (
        <div class="relative">
          <div class="inline-flex items-center border border-gray-60 rounded pl-1 pr-2 cursor-pointer">
            <img src={qrCode} alt="" />
            <div class="text-black-default text-xs" onClick={this.showBindDialog}>
              {this.$t('BWechatBind.openWechat')}
            </div>
          </div>
          <BWeChatBindDialog
            title={this.$tc('BWechatBind.scanInfo')}
            desc={this.$tc('BWechatBind.scanMsg')}
            onSuccess={this.bindSuccessCallback}
            onClose={this.onCloseFun}
            visible={this.isShowBindDialog}
          ></BWeChatBindDialog>
        </div>
      )
    },
  },
  render() {
    return <div>{this.hasFollowedPharmsnap ? this.renderFollowed() : this.renderUnFollowed()}</div>
  },
})
