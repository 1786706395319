import World2021 from '@patsnap-ui/extensions/lib/geo/world2021/index.js'
import { getCountryAlpha2code, toThousands } from '@patsnap/synapse_common_utils'
import { BasicWorldMapChartDataItem } from '@pharmsnap/shared/chart'
import * as echarts from 'echarts/core'
import { max, min } from 'lodash'
import { BasicWorldMapChart } from '..'
import { TOOLTIP_BG_COLOR, TOOLTIP_BORDER_COLOR, TOOLTIP_BORDER_WIDTH, TOOLTIP_FONT_SIZE, TOOLTIP_PADDING, TOOLTIP_TEXT_COLOR } from '../constants'
import { ECOption, Plot } from '../core/plot'
import { worldMapIconTooltipFormatter } from './config'

const COUNTRY_LOCATION_MAP: Record<string, string[]> = {
  CN: ['TW', 'HK', 'MO'],
}

export class WorldMap extends Plot {
  public type = 'word-map'

  public static async registerMap() {
    // 注册世界地图json文件
    await World2021.install(echarts)
  }

  protected getDefaultOption(options: ECOption): ECOption {
    return options
  }

  protected adaptor(params: BasicWorldMapChart): ECOption {
    const { data, name, roam, showVisualMap = true } = params
    const list = data.map((i) => i.value)
    const maxVal = max(list) || 0
    const minVal = min(list) || 0

    const world2021 = new World2021(echarts)
    // world2021.getFullName = getCountryFullName
    world2021.formatNumber = toThousands
    world2021.config.labelVisible = false
    world2021.config.roam = !!roam
    world2021.config.visualMapVisible = [maxVal, minVal].every((i) => i === 0) ? false : showVisualMap
    const countryData = data.map((item) => {
      return {
        ...item,
        code: getCountryAlpha2code(item.code) || item.code,
      }
    })

    /**
     * 把一些地区的数据合并到父亲国家下
     */
    const mixSubLocation = countryData.map((country) => {
      const subLocations: BasicWorldMapChartDataItem[] = []
      if (params.mixShowChildren && COUNTRY_LOCATION_MAP[country.code]) {
        const subData = countryData.filter((item) => COUNTRY_LOCATION_MAP[country.code].includes(item.code))
        subLocations.push(...subData)
      }
      return {
        ...country,
        subLocations,
      }
    })

    const echartOption = world2021.getEchartOptions({
      dimensions: ['code', 'value'],
      source: mixSubLocation,
    })
    const geoOption = echartOption.geo
    const seriesOption = echartOption.series
    const visualMap = Array.isArray(echartOption.visualMap) ? echartOption.visualMap : [echartOption.visualMap]

    geoOption.select = {
      ...geoOption.select,
      itemStyle: {
        areaColor: '#4ABD40',
      },
    }

    echartOption.tooltip = {
      ...echartOption.tooltip,
      ...{
        formatter: (chartParams: any) => worldMapIconTooltipFormatter(chartParams, params.mixShowChildren),
        backgroundColor: TOOLTIP_BG_COLOR,
        borderColor: TOOLTIP_BORDER_COLOR,
        borderWidth: TOOLTIP_BORDER_WIDTH,
        padding: TOOLTIP_PADDING,
        textStyle: {
          color: TOOLTIP_TEXT_COLOR,
          fontSize: TOOLTIP_FONT_SIZE,
        },
        extraCssText: 'line-height: 24px;box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 6px; max-width: 300px; overflow: hidden; white-space: normal;',
      },
    }

    seriesOption.forEach((item: { name: string; nameMap: Record<string, string> }) => {
      item.name = name
    })

    visualMap.forEach((item: { text: string[]; color: string[] }) => {
      item.text = [`Max: ${toThousands(maxVal)}`, `Min: ${toThousands(minVal)}`]
    })

    return echartOption
  }
}
