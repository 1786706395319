import { query2Text, structureQuery2Text } from '@patsnap/synapse_common_business'
import { IQueryDataType, IQueryItem, ISearchParams, ISort } from '@patsnap/synapse_common_interface'
import { IQueryMustFilter, ISearchCollapse } from '@pharmsnap/shared/types/search'
import { inject, InjectionKey, provide, reactive, Ref, ref, watch } from '@vue/composition-api'
import { isEqual } from 'lodash'
import { useLocale } from './useLocale'
interface IQueryState {
  queryId: string
  collapse?: ISearchCollapse
  orgRollup?: boolean
  query: IQueryMustFilter
  sort?: ISort[]
  data_type: IQueryDataType
  queryMustText: string
  queryFilterText: string
  queryStructureText: string
  searchQueryType?: ISearchParams['query_type']
  structureQuery?: ISearchParams['structure_query']
}
interface IQueryAction {
  setQuery(type: keyof IQueryMustFilter, value: IQueryItem[]): void
  setSort(value: ISort[]): void
}
export interface IQueryService {
  state: IQueryState
  actions: IQueryAction
}

export const queryStateProvideKey: InjectionKey<IQueryState> = Symbol('queryServiceStateProvideKey')

/**
 *
 * @param queryIdRef  queryId的响应式对象,只读,和路由保持童虎
 * @param defaultQuery
 * @param queryType
 * @returns
 */
export function useQueryService(
  queryIdRef: Ref<string>,
  queryType: IQueryDataType,
  defaultQuery?: Ref<IQueryMustFilter>,
  collapseRef?: Ref<ISearchCollapse | undefined>,
  querySort?: ISort[] | undefined,
  orgRollup?: Ref<boolean | undefined>,
  searchQueryType?: Ref<ISearchParams['query_type']>,
  structureQuery?: Ref<ISearchParams['structure_query']>
): IQueryService {
  const query: IQueryMustFilter = reactive({
    must: defaultQuery?.value?.must ? [...defaultQuery?.value?.must] : [],
    filter: defaultQuery?.value?.filter ? [...defaultQuery?.value?.filter] : [],
  })
  const queryId = ref(queryIdRef.value)
  const queryMustText = ref('')
  const queryFilterText = ref('')
  const queryStructureText = ref('')
  const { locale } = useLocale()
  // 监听queryId更新导致的query变更,这个时候,列表的query状态也要同步更新
  watch(
    () => defaultQuery?.value,
    () => {
      queryId.value = queryIdRef.value
      if (!isEqual(defaultQuery?.value.must, query.must)) {
        query.must = defaultQuery?.value?.must ? [...defaultQuery?.value?.must] : []
      }
      if (!isEqual(defaultQuery?.value.filter, query.filter)) {
        query.filter = defaultQuery?.value?.filter ? [...defaultQuery?.value?.filter] : []
      }
    }
  )
  const state: IQueryState = reactive({
    queryId: queryId,
    collapse: collapseRef,
    query,
    data_type: queryType,
    sort: querySort,
    queryMustText,
    queryFilterText,
    queryStructureText,
    orgRollup,
    structureQuery,
    searchQueryType,
  })
  const actions: IQueryAction = {
    setQuery(type: keyof IQueryMustFilter, value: IQueryItem[]) {
      query[type] = value
    },
    setSort(value: ISort[]) {
      state.sort = value
    },
  }

  watch(
    () => state.structureQuery,
    () => {
      queryStructureText.value = structureQuery2Text(state.structureQuery || {}, locale.value)
    },
    {
      immediate: true,
      deep: true,
    }
  )

  watch(
    () => state.query,
    () => {
      queryMustText.value = query2Text([...state.query.must], locale.value, queryType)
      queryFilterText.value = query2Text([...state.query.filter], locale.value, queryType)
    },
    {
      immediate: true,
      deep: true,
    }
  )

  provide(queryStateProvideKey, state)
  return {
    state,
    actions,
  }
}

export function useInjectQueryService() {
  const state = inject(queryStateProvideKey)
  return { state }
}
