import { IQueryDataType, IQueryGroupType, IQueryItem } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import {
  IBaseDiseaseInfo,
  IBaseTargetInfo,
  IDrugDetail,
  IGraphType,
  IKgSubmitParams,
  IKgVertexParams,
  IOrganizationDetail,
  IQueryMustFilter,
} from '@pharmsnap/shared/types'
import { getKgUrl, handleNav2KGWithGetGraphIdFunc, showSingleToast } from '@pharmsnap/shared/utils'
import { UnwrapRef, computed, ref } from '@vue/composition-api'
import { isArray, isEmpty, isString, reduce, uniq } from 'lodash'
import { ICommonListState } from '../../BCommonList/types'

type IKgEntranceDataType = IQueryGroupType | IQueryDataType

/** 各分组维度数据中：实体id所对应的字段 */
const groupByType2EntityIdKeyConfig: Partial<Record<IKgEntranceDataType, string[]>> = {
  drug: ['drug_id', 'target_id', 'active_org_master_entity_id', 'research_disease', 'non_research_disease'],
  original_dev_organization: ['org_id', 'related_drug_id', 'related_target_id', 'related_disease_id'],
  dev_organization: ['org_id', 'related_drug_id', 'related_target_id', 'related_disease_id'],
  organization: ['org_id', 'related_drug_id', 'related_target_id', 'related_disease_id'],
  dev_status: ['related_disease_id', 'related_drug_id', 'related_org_id', 'related_target_id'],
  target: ['target_id', 'related_drug_id', 'related_org_id', 'related_disease_id'],
  disease: ['disease_id', 'related_drug_id', 'related_org_id', 'related_target_id'],
  drug_type: ['related_drug_id', 'related_org_id', 'related_target_id', 'related_disease_id'],
  drug_deal: ['deal_project_drug_id', 'target_id', 'partner_org_id', 'principle_org_id'],
  drug_deal_drug: ['drug_id', 'related_target_id'],
  translational_medicine: ['drug', 'multi_target_id', 'research_sponsor', 'disease'],
}

export type KgEntranceOptions = {
  state?: UnwrapRef<IQueryService['state']>
  commonListServiceState?: ICommonListState
  type?: IKgEntranceDataType
}

const KG_ENTITIES_LIMIT = 200

export function useKgEntrance(options?: KgEntranceOptions) {
  const { locale } = useLocale()
  const {
    getters: { isFreeUser, isBasicUser },
    actions,
  } = useAuthStore()

  // premium 实现在 BKgEntrance.tsx 里
  const queryDisabled = ref(true)
  const kgEntities = ref<IKgSubmitParams['vertices']>([])
  const query = computed(() => {
    return (options?.state?.query || {}) as IQueryMustFilter
  })

  const validCheckedMap = computed(() => {
    const checkedMap = options?.commonListServiceState?.checkedMap || {}
    return reduce(checkedMap, (acc, value, key) => (value ? { ...acc, [key]: value } : acc), {} as Record<string, any>)
  })

  const isDisable = computed(() => {
    if (!isEmpty(validCheckedMap.value)) {
      return false
    }
    return queryDisabled.value
  })

  const getKgGraphId = async (vertices: IKgVertexParams[], graph_type: IGraphType = 'relation') => {
    const params = {
      vertices: vertices,
      graph_type,
    } as IKgSubmitParams
    const res = await sharedCtx.service.knowledgeGraph.submitQuery4GraphId(params)
    if (res.success && res.data) {
      const { graph_id } = res.data
      return graph_id
    }
    return ''
  }

  const getQueryEntityIds = () => {
    const res: string[] = []

    function getEntityIds(items: IQueryItem[], arr: string[]) {
      items.forEach((item) => {
        if (item.type === 'field') {
          item.value.forEach((val) => {
            if ('id' in val && val.id) {
              arr.push(val.id)
            }
            if (
              'value' in val &&
              typeof val.value === 'string' &&
              val.value &&
              val.value.length === 32 &&
              val.value !== val.display_name_cn &&
              val.value !== val.display_name_en
            ) {
              arr.push(val.value)
            }
          })
        }

        if (item.type === 'group') {
          getEntityIds([...(item.must || []), ...(item.filter || [])], res)
        }
      })
    }
    getEntityIds([...(query.value.filter || []), ...(query.value.must || [])], res)
    return res
  }

  const getCheckedItemEntityIds = () => {
    if (!isEmpty(validCheckedMap.value) && options?.type) {
      const entityIdKeyList = groupByType2EntityIdKeyConfig[options.type] || []

      if (!entityIdKeyList.length) {
        console.error(`Enter KG: 未配置数据类型 ${options.type} 对应的实体id字段`)
        return []
      }

      // 获取勾选列表中涉及的实体ids
      return reduce(
        validCheckedMap.value,
        (acc, value) => {
          // 多靶点的key会是用,隔开的多个id,这边处理下
          // acc.push(...key.split(','))
          entityIdKeyList.forEach((key) => {
            if (isString(value[key])) {
              acc.push(value[key])
            }
            if (isArray(value[key])) {
              value[key].forEach((v: string | { normalized_id?: string }) => {
                if (isString(v)) {
                  acc.push(v)
                } else if (v.normalized_id) {
                  acc.push(v.normalized_id)
                }
              })
            }
          })
          return acc
        },
        [] as string[]
      )
    }
    return []
  }

  const handleEnterKg = () => {
    if (isFreeUser.value || (IS_CN_REGION && isBasicUser.value)) {
      actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_KG, trigger_point: 'ENTER_KG' })
      return
    }
    handleEnterKgByQuery()
  }

  const handleEnterKgByQuery = async () => {
    // 勾选列表中的实体ids
    const checkedEntityIds: string[] = getCheckedItemEntityIds()
    // query中包含的实体ids
    const queryEntityIds = getQueryEntityIds()

    // 所有的实体ids
    const allEntityIds = uniq(queryEntityIds.concat(checkedEntityIds))

    if (allEntityIds.length > KG_ENTITIES_LIMIT) {
      showSingleToast({
        message:
          locale.value === 'cn'
            ? '勾选的药物、机构、靶点和适应症总和不能超过200'
            : 'The total number of selected drugs, organizations, targets and indications cannot exceed 200',
        type: 'warning',
      })
      return
    }

    openPageWithoutPending(async () => {
      // query中包含的实体
      let vertices = kgEntities.value
      // 勾选列表存在实体，获取图数据库中所包含的实体
      if (checkedEntityIds.length) {
        vertices = await getEntities(allEntityIds)
      }
      const graphId = await getKgGraphId(vertices)
      return getKgUrl(graphId)
    })
  }

  const getEntities = async (ids: string[]) => {
    const res = await sharedCtx.service.knowledgeGraph.checkIdsIsEntity(ids)
    if (res.success && res.data) {
      const { vertices = [] } = res.data
      return vertices
    }
    return []
  }

  const checkKgDisabled = async () => {
    const queryIds = getQueryEntityIds()
    if (queryIds.length === 0) {
      kgEntities.value = []
      queryDisabled.value = true
    } else {
      const vertices = await getEntities(queryIds)
      if (vertices.length) {
        kgEntities.value = vertices
        queryDisabled.value = !vertices.length
      }
    }
  }

  const handleEnterKgByDrug = (entity?: IDrugDetail) => {
    entity &&
      handleNav2KGWithGetGraphIdFunc(() =>
        getKgGraphId([
          {
            id: entity.drug_id,
            type: 'drug',
          },
        ])
      )
  }

  const handleEnterKgByDisease = (entity?: IBaseDiseaseInfo) => {
    entity &&
      handleNav2KGWithGetGraphIdFunc(() =>
        getKgGraphId([
          {
            id: entity.disease_id,
            type: 'indication',
          },
        ])
      )
  }
  const handleEnterKgByOrganization = (entity?: IOrganizationDetail) => {
    entity &&
      handleNav2KGWithGetGraphIdFunc(() =>
        getKgGraphId([
          {
            id: entity.entity_id,
            type: 'organization',
          },
        ])
      )
  }

  const handleEnterKgByTarget = (entity?: IBaseTargetInfo[]) => {
    entity &&
      handleNav2KGWithGetGraphIdFunc(() =>
        getKgGraphId(
          entity.map((i) => ({
            id: i.target_id,
            type: 'target',
          }))
        )
      )
  }

  return {
    isDisable,
    handleEnterKg,
    handleEnterKgByQuery,
    checkKgDisabled,
    getKgGraphId,
    handleEnterKgByDrug,
    handleEnterKgByDisease,
    handleEnterKgByOrganization,
    handleEnterKgByTarget,
  }
}
