import { defineComponent, PropType, ref } from '@vue/composition-api'
import {
  BAnalysisBullsEyeChart,
  BAnalysisChartCard,
  BAnalysisTwoDimStackTableWithSorter,
  useAnalysisDrugTwoDimChartEntity,
} from '../../../BAnalysisChartNext'
import { E_DRUG_ANALYSIS_CHART, useDrugAggChart } from '../../compositions/useDrugAggChart'
export const BDrugBullsEyeChart = defineComponent({
  name: 'BDrugBullsEyeChart',
  props: {
    title: {
      type: String,
    },
    bullsEyeChartTitle: {
      type: String,
    },
    desc: {
      type: String,
    },
    cls: {
      type: String,
    },
    type: {
      required: true,
      type: String as PropType<E_DRUG_ANALYSIS_CHART>,
    },
    chartAgg: {
      required: true,
      type: Object as PropType<ReturnType<typeof useDrugAggChart>>,
    },
    bullsEyeChartEntity: {
      required: true,
      type: Object as PropType<ReturnType<typeof useAnalysisDrugTwoDimChartEntity>>,
    },
    analysisEntity: {
      type: String as PropType<'organization' | 'target' | 'disease' | 'drug_type'>,
    },
  },
  setup() {
    const bullsEyeChartRef = ref<InstanceType<typeof BAnalysisBullsEyeChart>>()

    return {
      bullsEyeChartRef,
    }
  },
  render() {
    const { model, controls, controlLayout, loading, isEmpty, bullEyeAggData, isBullEyeEmpty } = this.chartAgg
    const { dataList, countFieldName, stackFieldName, entityFieldName } = this.bullsEyeChartEntity

    const height = 80 * 10

    return (
      <BAnalysisChartCard
        key={this.type}
        class={this.cls}
        title={this.title}
        desc={this.desc}
        isLoading={loading.value}
        isEmpty={isEmpty.value}
        height={height}
        controls={controls.value}
        layout={controlLayout.value}
        value={model}
        downloadCfg={{ visible: model.display !== 'table', type: 'custom' }}
        onDownload={() => {
          this.bullsEyeChartRef?.handleDownload()
        }}
        onOpen={() => this.$emit('open')}
        onAfterDownload={() => this.$emit('afterDownload')}
      >
        {!isEmpty.value && <template slot="headerActionSlot">{this.$slots.headerActionSlot ? this.$slots.headerActionSlot : null}</template>}
        {model.display === 'bull-eye' && !isBullEyeEmpty.value ? (
          <div class="w-full h-full">
            <BAnalysisBullsEyeChart
              data={bullEyeAggData.value}
              uniqueId={this.type}
              innerCircleTitle={this.bullsEyeChartTitle}
              showLabel={model.showLabel || false}
              analysisEntity={this.analysisEntity}
              selectedPhaseIds={model.phases || []}
              onClickCount={this.$emit.bind(this, 'clickBullsEyeChartCount')}
              ref="bullsEyeChartRef"
              onClickItem={this.$emit.bind(this, 'clickBullsEyeChartItem')}
            ></BAnalysisBullsEyeChart>
          </div>
        ) : null}
        <BAnalysisTwoDimStackTableWithSorter
          class={[{ hidden: model.display !== 'table' }]}
          height={'100%'}
          displayMode="table"
          entityFieldName={entityFieldName.value}
          countFieldName={countFieldName.value}
          stackFieldName={stackFieldName.value}
          list={dataList.value}
        ></BAnalysisTwoDimStackTableWithSorter>
      </BAnalysisChartCard>
    )
  },
})
