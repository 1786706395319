import { toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { ITopPatentCoAssignee } from '@pharmsnap/shared/types'
import { computed, ComputedRef, defineComponent, getCurrentInstance, PropType } from '@vue/composition-api'
import { TableOptions } from 'vxe-table'
import { IPatentAssigneeTableData } from '../../../../types'
import { getAssigneeName } from '../util'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const SmallTopAssigneeTable = defineComponent({
  name: 'SmallTopAssigneeTable',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    chartData: {
      required: true,
      type: Object as PropType<ITopPatentCoAssignee>,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const ins = getCurrentInstance()
    const emptyPlaceholder = '-'

    const tableData = computed(() => {
      const res: IPatentAssigneeTableData[] = []
      props.chartData.items &&
        props.chartData.items.forEach((assignee) => {
          if (assignee.items?.length) {
            assignee.items.forEach((coAssignee) => {
              res.push({
                ...coAssignee,
                assigneeId: assignee.org_id,
                assignee: getAssigneeName(assignee, isCN.value),
                coAssignee: getAssigneeName(coAssignee, isCN.value),
                coCount: coAssignee.num ? toThousands(coAssignee.num) : '',
              })
            })
          } else {
            res.push({
              ...assignee,
              assigneeId: assignee.org_id,
              assignee: getAssigneeName(assignee, isCN.value),
              coAssignee: '',
              coCount: '',
            })
          }
        })
      return res
    })

    const mergeData = computed(() => {
      const res: { id: string; start: number; length: number }[] = []
      tableData.value.forEach((assignee, index) => {
        const x = res.find((o) => o.id === assignee.assigneeId)
        if (x) {
          x.length++
        } else {
          res.push({ id: assignee.assigneeId, start: index, length: 1 })
        }
      })
      return res
    })

    const mergeCellFun = ({ rowIndex, columnIndex }: { rowIndex: number; columnIndex: number }) => {
      if (columnIndex === 0) {
        const row = mergeData.value.find((o) => o.start === rowIndex)
        return {
          rowspan: row ? row.length : 0,
          colspan: row ? 1 : 0,
        }
      }
    }

    const vxeProps: ComputedRef<TableOptions> = computed(() => {
      const columnConfig = [
        {
          field: 'assignee',
          title: ins?.proxy.$tc('SmallTopAssigneeTable.assignee'),
          slots: {
            default: (data: { row: IPatentAssigneeTableData }) => [<div title={data.row.assignee}>{data.row.assignee}</div>],
          },
        },
        {
          field: 'coAssignee',
          title: ins?.proxy.$tc('SmallTopAssigneeTable.coAssignee'),
          slots: {
            default: (data: { row: IPatentAssigneeTableData }) => {
              const label = data.row.coAssignee || emptyPlaceholder
              return [<div title={label}>{label}</div>]
            },
          },
        },
        {
          field: 'coCount',
          title: ins?.proxy.$tc('SmallTopAssigneeTable.applicationNum'),
          slots: {
            default: (data: { row: IPatentAssigneeTableData }) => [<div>{data.row.coCount || emptyPlaceholder}</div>],
          },
        },
      ]

      return {
        border: true,
        maxHeight: '100%',
        columns: columnConfig,
        data: tableData.value,
      }
    })

    return { mergeCellFun, vxeProps }
  },
  render() {
    return (
      <vxe-grid
        size="small"
        props={this.vxeProps}
        cell-style={{ 'vertical-align': 'top' }}
        span-method={this.mergeCellFun}
        scrollX={{
          enabled: false,
        }}
        scrollY={{
          enabled: false,
        }}
      ></vxe-grid>
    )
  },
})
