import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { ISearchStrategy } from '@patsnap/synapse_common_interface'
import type { IAutoCompleteType } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { VNodeData } from 'vue/types/umd'
import { BIcon } from '../../../'
import '../../../../assets/icon-svg/info.svg'
import { ElTag } from '../../../../element-ui'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import $classes from './BAcTag.module.scss'

export const BAcTag = defineComponent({
  name: 'BAcTag',
  props: {
    name: {
      type: String,
    },
    /**
     * 是否显示关闭按钮，这边语义没有定义好
     */
    canClose: {
      type: Boolean,
      default: true,
    },
    ownerClass: {
      type: String,
    },
    /**
     * 会根据itemType来加icon
     */
    itemType: {
      type: String as PropType<IAutoCompleteType>,
    },
    /**
     * elementUI,是否禁用渐变动画
     */
    disableTransitions: {
      type: Boolean,
      default: true,
    },
    /**
     * elementUI,tag type
     */
    type: {
      type: String as PropType<'success' | 'info' | 'warning' | 'danger'>,
      default: 'info',
    },
    /**
     * elementUI,tag size
     */
    size: {
      type: String as PropType<'mini' | 'small' | 'medium' | 'default'>,
      default: 'small',
    },
    /**
     * elementUI,tag effect
     */
    effect: {
      type: String as PropType<'dark' | 'light' | 'plain'>,
      default: 'dark',
    },
    /**
     * tag的tooltip文本
     */
    infoContent: {
      type: String,
    },
    /**
     * tooltip的类型
     */
    mode: {
      type: String as PropType<'text' | 'icon'>,
      default: 'icon',
    },
    /**
     * 优先取icon，icon没有再取itemType
     */
    icon: {
      type: String,
    },
    /**
     * 机构的特殊图标
     */
    logo_s3_path: {
      type: String,
    },
    search_strategy: {
      type: String as PropType<ISearchStrategy>,
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click')
    }
    const handleClose = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      emit('close')
    }

    return { handleClick, handleClose }
  },
  methods: {
    renderToolTip() {
      return this.infoContent ? (
        <GTooltip theme="light" mode={this.mode}>
          <template slot="content">
            <div domPropsInnerHTML={this.infoContent}></div>
          </template>
        </GTooltip>
      ) : null
    },
  },
  render() {
    const vNodeData: VNodeData = {
      props: {
        type: this.type,
        size: this.size !== 'default' ? this.size : '',
        effect: this.effect,
      },
      attrs: {
        'disable-transitions': this.disableTransitions,
      },
      on: {
        click: this.handleClick,
      },
    }
    const tagClass = this.ownerClass ? [$classes[`${this.size}Tag`], this.ownerClass] : $classes[`${this.size}Tag`]

    if (this.$slots.default) {
      return (
        <ElTag {...vNodeData} class={[tagClass, 'acTag']}>
          {this.$slots.default}
        </ElTag>
      )
    }

    const renderIcon = () => {
      if (
        this.itemType === 'Topic' ||
        this.itemType?.startsWith('ADC') ||
        this.search_strategy == 'KEYWORD' ||
        this.search_strategy == 'ANS_KEYWORD'
      ) {
        return null
      }
      let icon = this.icon || this.itemType
      const iconClass = $classes[`${this.size}Icon`]
      if (this.search_strategy === 'ID_ROLLUP') {
        return <PtIcon icon="Tree" class={iconClass}></PtIcon>
      }
      if (!this.icon && this.itemType === 'Organization') {
        icon = 'Company'
      }
      if (this.logo_s3_path) {
        return <BIcon class={iconClass} src={this.logo_s3_path} type="company"></BIcon>
      }
      return icon ? <PtIcon icon={icon} class={iconClass}></PtIcon> : null
    }

    return (
      <ElTag {...vNodeData} class={[tagClass, 'acTag']} data-testid="b-ac-tag">
        {renderIcon()}
        <span data-testid="b-ac-tag__label" class={$classes.label}>
          {this.name}
        </span>
        {this.canClose ? (
          <div data-testid="b-ac-tag__close" class="flex items-center" onClick={(e: Event) => this.handleClose(e)}>
            <PtIcon icon="SolidCloseBig" class={$classes[`${this.size}Close`]}></PtIcon>
          </div>
        ) : null}
        {this.renderToolTip()}
      </ElTag>
    )
  },
})
