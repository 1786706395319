import { IHistorySearchParams, IQuickSearchHistoryParams } from '@pharmsnap/shared/types'
import { fromUint8Array, toUint8Array } from 'js-base64'
import { deflate, inflate } from 'pako'

export function queryBase64ToQueryParams(queryBase64String?: string): IHistorySearchParams | undefined {
  if (!queryBase64String) {
    return undefined
  }
  try {
    const queryUint8Array = toUint8Array(queryBase64String)
    const queryJsonSting = inflate(queryUint8Array, { to: 'string' })
    return JSON.parse(queryJsonSting)
  } catch (error) {
    console.error('queryBase64String is not a valid base64 string', error)
    return undefined
  }
}

export function queryBase64ToSmartQueryParams(queryBase64String?: string): IQuickSearchHistoryParams | undefined {
  if (!queryBase64String) {
    return undefined
  }
  try {
    const queryUint8Array = toUint8Array(queryBase64String)
    const queryJsonSting = inflate(queryUint8Array, { to: 'string' })
    return JSON.parse(queryJsonSting)
  } catch (error) {
    console.error('queryBase64String is not a valid base64 string', error)
    return undefined
  }
}

export function query2Base64String(query: IHistorySearchParams): string {
  const queryString = deflate(JSON.stringify(query))
  return fromUint8Array(queryString, true)
}
