import '@patsnap-ui/icon/assets/solid/ArrowLeft.svg'
import '@patsnap-ui/icon/assets/solid/ArrowRight.svg'
import { GDrawerLayoutProps } from '@pharmsnap/shared/components/props'
import { CTRL_C_INFO } from '@pharmsnap/shared/utils'
import { computed, defineComponent, toRefs } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GTooltip } from '../GTooltip/GTooltip'
import $classes from './GDrawerLayout.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const __G_DRAWER_TRIGGER_WIDTH__ = 24
export const GDrawerLayout = defineComponent({
  name: 'GDrawerLayout',
  model: {
    prop: 'expanded',
    event: 'input',
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: GDrawerLayoutProps,
  setup(props, ctx) {
    const { expanded } = toRefs(props)
    const sideStyle = computed(() => ({ width: `${expanded.value ? props.maxWidth : props.minWidth}px`, background: props.drawerBngColor }))
    const icon = computed(() => {
      if (props.drawerPosition === 'left') return expanded.value ? 'SolidArrowLeft' : 'SolidArrowRight'
      return expanded.value ? 'SolidArrowRight' : 'SolidArrowLeft'
    })
    const triggerStyle = computed(() => {
      const style = {
        top: `${props.triggerTop}px`,
        [props.drawerPosition]: `${
          expanded.value ? props.maxWidth - __G_DRAWER_TRIGGER_WIDTH__ / 2 : props.minWidth / 2 - __G_DRAWER_TRIGGER_WIDTH__ / 2
        }px`,
      }

      return style
    })
    const onToggle = () => ctx.emit('input', !expanded.value)
    return {
      icon,
      sideStyle,
      triggerStyle,
      onToggle,
    }
  },
  methods: {
    renderDrawer() {
      if (this.showSide) {
        return (
          <div class={[$classes.side, 'drawer-side']} style={this.sideStyle}>
            <div class="h-full" v-show={this.expanded}>
              {this.$slots.drawer}
            </div>
          </div>
        )
      }
    },
    renderContent() {
      return <div class={$classes.content}>{this.$slots.default}</div>
    },
    renderDrawerAndContent() {
      if (this.drawerPosition === 'left') return [this.renderDrawer(), this.renderContent()]
      return [this.renderContent(), this.renderDrawer()]
    },
  },
  render() {
    return (
      <div class={[$classes.layout, CTRL_C_INFO]}>
        {this.renderDrawerAndContent()}
        {this.showSide ? (
          <div onClick={this.onToggle} class={[$classes.trigger, 'drawer-trigger']} style={this.triggerStyle}>
            <GTooltip
              content={this.expanded ? this.$tc('GDrawerLayout.hide') : this.$tc('GDrawerLayout.show')}
              placement={this.expanded ? 'left' : 'right'}
            >
              <PtIcon icon={this.icon}></PtIcon>
            </GTooltip>
          </div>
        ) : null}
      </div>
    )
  },
})
