import { computed, nextTick, ref, Ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { isFunction, isUndefined } from 'lodash'
import { IFilterItemTreeData, IFilterItemUiType, IFilterTreeValueNext } from '../type'
import { flattenTreeLike } from '../utils'
import { useFilterTreeAutocompleteService } from './useFilterAutocompleteService'
import { IFilterServiceInjection, useInjectFilterService } from './useProvideFilterService'

type IUseFilterUiTreeServiceOptions<IDENTITY extends string = string> = {
  identity: MaybeRef<IDENTITY>
  uiType: MaybeRef<IFilterItemUiType>
}

export function useFilterUiTreeService<IDENTITY extends string = string>(options: IUseFilterUiTreeServiceOptions<IDENTITY>) {
  const { identity, uiType } = options

  const { filterService, selectService, locale } = useInjectFilterService() as IFilterServiceInjection

  const state = computed(() => filterService.stateRecord[unref(identity)])

  const data = computed(() => state.value.data?.[unref(uiType)] as IFilterItemTreeData | undefined)

  const tree = computed(() => unref(data)?.data || [])

  const selectStrategy = computed(() => unref(data)?.selectStrategy)

  const treeContainerElRef = ref(null) as Ref<HTMLDivElement | null>

  const {
    hasSelectFromAutocomplete,
    matchedActiveValue: autocompleteActiveMatchedValue,
    displayMatchedIndex: autocompleteDisplayMatchedIndex,
    keywords: autocompleteKeywords,
    matchedValues: autocompleteMatchedValues,
    matchedPathIds: autocompleteMatchedPathIds,
    placeholder: autocompletePlaceholder,
    onClear: onAutocompleteClear,
    onSelect: onAutocompleteSelect,
    onSelectPre: onAutocompleteSelectPre,
    onSelectNext: onAutocompleteSelectNext,
  } = useFilterTreeAutocompleteService({
    locale,
    tree,
    onAfterSelect: (value) => {
      if (!value) return
      select(value)
      setTimeout(() => {
        scrollIntoView(value)
      }, 1000)
    },
    onCursor: (value) => {
      if (!value) return
      nextTick(() => {
        scrollIntoView(value)
      })
    },
    onAfterClear: () => {
      const state = filterService.stateRecord[unref(identity)]
      if (state.data && state.data[unref(uiType)]) {
        const filterTreeData = state.data[unref(uiType)] as IFilterItemTreeData
        filterTreeData.data = [...filterTreeData.data]
      }
    },
  })

  function scrollIntoView(data: IFilterTreeValueNext) {
    const el = treeContainerElRef.value
    if (!el) return
    const itemEl = el.querySelector(`[data-id="${data.uuidPath}"]`)
    if (!itemEl) return
    itemEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  function select(item: IFilterTreeValueNext) {
    if (selectStrategy.value === 'single') {
      const selectFilter = unref(data)?.selectFilter

      if (selectFilter && isFunction(selectFilter)) {
        if (!selectFilter(item)) {
          return
        }
      }

      if (selectService.checkIsDisabled(item.value) || selectService.checkIsSelected(item.value)) {
        filterService.hooks.onSelectedValueExist?.()
        return
      }

      const limit = unref(filterService.selectedLimit)

      if (!isUndefined(limit) && filterService.hooks.onSelectedValueLimit) {
        if (unref(selectService.filterValueTemporaryList).length >= limit) {
          filterService.hooks.onSelectedValueLimit(limit)
          return
        }
      }

      selectService.addFilterValueToTemporary(item.value)
    }

    if (selectStrategy.value === 'rollup') {
      const items = flattenTreeLike([item])
      let validItems = items
      const selectFilter = unref(data)?.selectFilter

      if (selectFilter && isFunction(selectFilter)) {
        validItems = items.filter((i) => selectFilter(i))
      }

      if (!validItems.length) return

      if (validItems.every((i) => selectService.checkIsSelected(i.value) || selectService.checkIsDisabled(i.value))) {
        filterService.hooks.onSelectedValueExist?.()
        return
      }

      const limit = unref(filterService.selectedLimit)

      if (!isUndefined(limit) && filterService.hooks.onSelectedValueLimit) {
        if (unref(selectService.filterValueTemporaryList).length + validItems.length >= limit) {
          const countCanToAdd = limit - unref(selectService.filterValueTemporaryList).length

          if (countCanToAdd <= 0) {
            filterService.hooks.onSelectedValueLimit(limit)
            return
          }

          const canAddItems = validItems.slice(0, countCanToAdd)

          canAddItems.forEach((i) => {
            selectService.addFilterValueToTemporary(i.value)
          })

          return
        }
      }

      validItems.forEach((i) => {
        selectService.addFilterValueToTemporary(i.value)
      })
    }
  }

  return {
    tree,
    select,
    locale,
    checkIsSelected: selectService.checkIsSelected,
    checkIsDisabled: selectService.checkIsDisabled,
    isEmpty: computed(() => unref(tree).length === 0),
    isAutocompleteEmpty: computed(() => unref(hasSelectFromAutocomplete) && unref(autocompleteActiveMatchedValue) === null),
    autocompleteActiveMatchedValue,
    autocompleteDisplayMatchedIndex,
    autocompleteMatchedValues,
    autocompleteMatchedPathIds,
    autocompleteKeywords,
    autocompletePlaceholder,
    onAutocompleteClear,
    onAutocompleteSelect,
    onAutocompleteSelectPre,
    onAutocompleteSelectNext,
    treeContainerElRef,
  }
}
