import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { IAutoCompleteType, IHighLightValWithMulti } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BAutoEntityInput, BHighlightDom, BIcon, GDensityBar, GIcon, GLoading, GRadio, GTree, useDensityBarHub } from '@pharmsnap/shared/components'
import { sharedCtx } from '@pharmsnap/shared/context'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { BStartupTag } from '@pharmsnap/shared/src/components/business/card/BOrganizationCard/BStartupTag'
import {
  IBAcTag,
  IBrowseCountType,
  IBrowseType,
  IConvertResItem,
  IElTreeNode,
  IGRadioItem,
  IListItem,
  IOrderByType,
  ISearchTreeParams,
  ISearchTreeResItem,
  ISelectedLabel,
} from '@pharmsnap/shared/types'
import {
  convertDataStrategyMap,
  findTarget,
  getAllNotLeafId,
  getDisplayPercentNumber,
  getLangDegraded,
  getOwnerShip,
  getTargetNodePathV2,
  loopGetIdPath,
  showSingleToast,
} from '@pharmsnap/shared/utils'
import {
  computed,
  ComputedRef,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { cloneDeep, isEmpty, isUndefined } from 'lodash'
import { Icon as PtIcon } from 'patsnap-biz'
import VClickOutside from 'v-click-outside'
import { VNode } from 'vue'
import '../../../assets/icon-svg/ArrowEnter.svg'
import '../../../assets/icon-svg/atc.svg'
import '../../../assets/icon-svg/drugType.svg'
import '../../../assets/icon-svg/mechanism.svg'
import '../../../assets/icon-svg/searchRight.svg'
import '../../../assets/icon-svg/tree.svg'
import '../../../assets/icon-svg/warning.svg'
import chooseEmpty from '../../../assets/svg/chooseEmpty.svg'
import corporateTree from '../../../assets/svg/corporateTree.svg'
import { useLocale } from '../../../composition/useLocale'
import $classes from './BBrowseNew.module.scss'
import { serviceConfig } from './config'
import cn from './locales/cn.json'
import en from './locales/en.json'

interface IBtnGroupItem {
  name_en: string
  name_cn: string
  type: IOrderByType
}
type ISearchTreeResItemCountType = keyof Pick<
  ISearchTreeResItem,
  'drug_count_roll_up' | 'drug_count' | 'patent_count' | 'patent_count_roll_up' | 'adc_count' | 'cr_count' | 'tm_count'
>
const countTypeMap: Record<
  IBrowseCountType,
  { countKey: ISearchTreeResItemCountType; rollupKey: ISearchTreeResItemCountType; btnConfig?: IBtnGroupItem }
> = {
  drug: {
    countKey: 'drug_count',
    rollupKey: 'drug_count_roll_up',
    btnConfig: {
      name_en: 'Drug Count',
      name_cn: '药物数量',
      type: 'DrugCount',
    },
  },
  patent: {
    countKey: 'patent_count',
    rollupKey: 'patent_count_roll_up',
    btnConfig: {
      name_en: 'Patent Count',
      name_cn: '专利数量',
      type: 'PatentCount',
    },
  },
  antibody: {
    countKey: 'adc_count',
    rollupKey: 'adc_count',
    btnConfig: {
      name_en: 'XDC Count',
      name_cn: 'XDC数量',
      type: 'DrugCount',
    },
  },
  meetings: {
    countKey: 'cr_count',
    rollupKey: 'cr_count',
  },
  translationalMedicineMeetings: {
    countKey: 'tm_count',
    rollupKey: 'tm_count',
  },
}

const enFirstType: IBrowseType[] = ['DATA_SOURCE_MEETING']

export const BBrowseNew = defineComponent({
  name: 'BBrowseNew',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    /** 是否在按钮上方展示弹窗 */
    showOnTop: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    type: {
      type: String as PropType<IBrowseType>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    leftNum: {
      type: Number,
      default: 0,
    },
    tags: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    viewDetailLabel: {
      type: String,
    },
    countType: {
      type: String as PropType<IBrowseCountType>,
      default: 'drug',
    },
    searchByVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive<{
      keywords: string
      infiniteLoadingIdentifier: string
      isEntityKeywords: boolean
      activeOrderType: IOrderByType
      selectedList: ISelectedLabel[]
      dataCount: { [key: string]: number }
      treeData: IConvertResItem[]
      listData: IConvertResItem[]
      searchId: string
      loading: boolean
      /** 展开的节点id */
      expandedIds: string[]
      /** 高亮节点路径 */
      highlightPathArr: string[]
      /** 树高亮信息映射表 */
      highlightingDataMap: Record<string, IHighLightValWithMulti>
      offset: number
      limit: number
      showOrgLogo: boolean
      showOperateBtnItemId: string
      /** 高亮序号 */
      highlightIndex: number
      treeLoadLimit: number
      treeOffsetMap: Map<string, number>
      showMoreIds: string[]
    }>({
      keywords: '',
      infiniteLoadingIdentifier: 'initkey',
      isEntityKeywords: false,
      activeOrderType: 'Hierarchy',
      selectedList: [],
      dataCount: {},
      treeData: [],
      listData: [],
      searchId: '',
      loading: false,
      expandedIds: [],
      highlightPathArr: [],
      highlightingDataMap: {},
      offset: 0,
      limit: 20,
      showOrgLogo: false,
      showOperateBtnItemId: '',
      highlightIndex: 0,
      treeLoadLimit: 50,
      treeOffsetMap: new Map(),
      showMoreIds: [],
    })

    const rootData = reactive<{ rootHasMore: boolean; rootOffset: number }>({
      rootHasMore: false,
      rootOffset: 0,
    })

    const ins = getCurrentInstance()
    const localeData = useLocale()

    const btnGroup = computed<IBtnGroupItem[]>(() => {
      const btnList: IBtnGroupItem[] = [
        {
          name_en: 'Hierarchy',
          name_cn: '层级',
          type: 'Hierarchy',
        },
        {
          name_en: 'A → Z',
          name_cn: 'A → Z(EN)',
          type: 'LetterIncrease',
        },
      ]

      const btnConfig = countTypeMap[props.countType].btnConfig

      if (btnConfig) {
        btnList.splice(1, 0, btnConfig)
      }
      return btnList
    })
    const btnGroupValue: ComputedRef<IGRadioItem[]> = computed(() => {
      return btnGroup.value
        .filter((o) => serviceConfig[props.type].btnGroup.includes(o.type))
        .map((item) => {
          return {
            value: item.type,
            label: localeData.locale.value === 'cn' ? item.name_cn : item.name_en,
          }
        })
    })
    /**
     * 是否支持关键字搜索
     */
    const isSupportKeywordSearch = computed(() => {
      const types: IBrowseType[] = ['Target', 'Disease', 'DrugType', 'ATC', 'ADC_Linker', 'ADC_Payload', 'Mechanism', 'DATA_SOURCE_MEETING']
      return types.includes(props.type)
    })

    const canSearch = computed(() => {
      return data.keywords?.length > 1
    })

    const { refreshAllDensityBar, rafRefreshAllDensityBar } = useDensityBarHub()

    const handleCloseBrowse = () => {
      emit('closeBrowse')
    }

    const init = () => {
      data.activeOrderType = (btnGroupValue.value[0]?.value || 'Hierarchy') as IOrderByType
    }

    init()

    const clearData = () => {
      data.searchId = ''
      data.keywords = ''
      data.isEntityKeywords = false
      data.highlightIndex = 0
      data.treeData = []
      data.listData = []
      data.expandedIds = []
      data.highlightPathArr = []
      data.offset = 0
      data.showMoreIds = []
      resetOffsetMap()
      rootData.rootOffset = 0
    }

    const orderBy = async (type: IOrderByType) => {
      if (data.activeOrderType !== type) {
        clearData()
        data.activeOrderType = type
        if (type === 'Hierarchy') {
          await handleSearchRootTree()
        }
      }
    }

    const resetData = async () => {
      data.searchId = ''
      data.offset = 0
      data.listData = []
      resetOffsetMap()
      rootData.rootOffset = 0
    }
    const handleSelectItem = async (type: IBrowseType, val: IListItem) => {
      data.isEntityKeywords = true
      data.keywords = getLangDegraded(
        val,
        type === 'ADC_Antibody' || type === 'ADC_Linker' || type === 'ADC_Payload' || type === 'DATA_SOURCE_MEETING' ? 'en' : localeData.locale.value
      )
      resetData()
      data.searchId = val.id
      await handleSearch()
      handleSearch2Selected(val)
    }
    const handleInput = (val: string) => {
      data.keywords = val.trim()
    }
    const handleSearchManually = async () => {
      if (canSearch.value) {
        data.isEntityKeywords = false
        resetData()
        await handleSearch()
      }
    }
    const handleInputKeyDown = async (e: KeyboardEvent, keyword: string) => {
      // 如果不支持关键词检索，直接返回
      if (!isSupportKeywordSearch.value) {
        return
      }
      if (!keyword) {
        return
      }
      // 用户输入了关键词，就不再是entity的关键词了
      if (data.keywords !== keyword) {
        data.isEntityKeywords = false
      }
      // 如果是回车键，直接搜索
      if (e.key === 'Enter') {
        if (!canSearch.value) {
          return
        }
        data.keywords = keyword.trim()
        data.infiniteLoadingIdentifier = keyword
        resetData()
        await handleSearch()
      }
    }

    /**
     * 因为autocomplete返回的数据是没有is_leaf的，所以前端只能拿到treeData后再去递归找一下数据
     */
    function handleSearch2Selected(val: IListItem) {
      const d = cloneDeep(val)
      if (data.activeOrderType === 'Hierarchy') {
        const res = findTarget(data.treeData, val.id)
        if (res) {
          d.is_leaf = res.is_leaf
        }
      }
      handleItem2Select(d)
    }

    function getRootSearchParams() {
      const { sort, dicCode, otherCondition } = serviceConfig[props.type]
      const params: ISearchTreeParams = {
        limit: data.treeLoadLimit,
        offset: 0,
        sort: sort[data.activeOrderType],
      }
      if (data.activeOrderType === 'Hierarchy') {
        params.search_tree = true
        params.offset = rootData.rootOffset * data.treeLoadLimit
      } else {
        params.search_tree = false
        params.limit = data.limit
        params.offset = data.offset * data.limit
      }
      if (dicCode) {
        params.dict_type = dicCode
      }
      if (otherCondition) {
        params.other_condition = otherCondition(props.countType)
      }
      return params
    }

    const getSearchParams = (parent_id = '') => {
      const { sort, dicCode, otherCondition } = serviceConfig[props.type]
      const params: ISearchTreeParams = {
        limit: data.treeLoadLimit,
        offset: 0,
        sort: sort[data.activeOrderType],
      }
      if (data.activeOrderType === 'Hierarchy') {
        params.search_tree = true
      } else {
        params.search_tree = false
        params.limit = data.limit
        params.offset = data.offset * data.limit
      }
      if (dicCode) {
        params.dict_type = dicCode
      }
      if (otherCondition) {
        params.other_condition = otherCondition(props.countType)
      }
      if (parent_id) {
        // 如果是通过节点树展开的，需要传入parent_id,且不再是search_tree
        params.parent_id = parent_id
        params.search_tree = false
      } else {
        if (data.searchId) {
          params.id = data.searchId
        } else if (data.keywords) {
          params.keyword = data.keywords
        }
      }

      return params
    }

    const handleSearchRootTree = async () => {
      if (data.activeOrderType === 'Hierarchy' && !rootId.value) {
        data.treeData = []
        return
      }
      data.loading = true
      const params = getRootSearchParams()
      if (data.activeOrderType === 'Hierarchy' && rootId.value) {
        params.parent_id = rootId.value
      }
      const searchRes = await search(params)
      if (searchRes.success) {
        data.treeData.push(...convertUtil(searchRes.data.items ?? [], [], true))
        rootData.rootOffset += 1
        rootData.rootHasMore = data.treeData.length < searchRes.data.total
      }
      data.loading = false
    }

    const handleSearchTree = async () => {
      data.loading = true
      data.showMoreIds = []
      data.treeData = []
      const params = getSearchParams()

      const searchRes = await search(params)
      if (!searchRes.success) {
        data.loading = false
        return
      }
      if (props.type === 'Organization' && searchRes.data.items[0].tree_size && searchRes.data.items[0].tree_size < 150) {
        data.showOrgLogo = true
      } else {
        data.showOrgLogo = false
      }
      if (searchRes) {
        const updateData = () => {
          const treeData = convertUtil(searchRes.data.items ?? [], [], true)
          data.highlightPathArr = getTargetNodePathV2(treeData, searchRes.data.related_id || []).map((expandId) => expandId.join('-'))
          data.expandedIds = treeData.flatMap(getAllNotLeafId)
          data.treeData = treeData
          data.highlightingDataMap = searchRes.data.highlighting || {}
          data.highlightIndex = 0
          rootData.rootOffset += 1
          data.loading = false
          nextTick(searchNext)
        }
        // if (searchRes.data.total_related_id_count && searchRes.data.total_related_id_count > 100) {
        //   showSingleToast({
        //     type: 'warning',
        //     message: localeData.tsText(
        //       '当前关键词涉及内容过多，请进一步输入以便获得更好的使用体验。',
        //       'The current keyword involves too many hierarchies. Please provide more specific input for a better user experience.'
        //     ),
        //   })
        //   // updateData处理的节点比较多，会导致页面卡顿，所以加个延迟,先展示toast
        //   setTimeout(updateData, 400)
        // } else {
        updateData()
        // }
      } else {
        data.loading = false
      }
    }
    const handleSearchList = async () => {
      const params = getSearchParams()
      data.offset += 1
      const searchRes = await search(params)
      if (searchRes.success) {
        const newData = convertUtil(searchRes.data.items ?? [], rootId.value ? [rootId.value] : [])
        data.highlightingDataMap = searchRes.data.highlighting || {}
        data.listData = newData
      }
    }

    /**
     * 点击item search数据
     * 区分Hierarchy与其他
     */
    const handleSearch = async () => {
      if (data.activeOrderType === 'Hierarchy') {
        await handleSearchTree()
      } else {
        await handleSearchList()
      }
    }

    const handleClearSearch = async () => {
      clearData()
      if (data.activeOrderType === 'Hierarchy') {
        await handleSearchRootTree()
      } else {
        await handleSearchRootListData()
      }
    }

    const searchRootTree = async () => {
      if (props.visible) {
        data.selectedList = props.tags.map((tag) => {
          return {
            ...tag,
            label: getShowLabel(tag),
          }
        })
      }
      if (props.visible && props.type !== 'Organization') {
        if (data.activeOrderType === 'Hierarchy') {
          !data.treeData.length && (await handleSearchRootTree())
        } else {
          !data.listData.length && (await handleSearchRootListData())
        }
      }
    }

    watch(
      () => props.visible,
      () => {
        props.searchByVisible && searchRootTree()
      }
    )
    onMounted(() => {
      !props.searchByVisible && searchRootTree()
    })

    const rootId = computed(() => serviceConfig[props.type].rootId ?? '')

    const search = async (params: ISearchTreeParams) => {
      return sharedCtx.service[serviceConfig[props.type].serviceKey].searchTree(params)
    }

    /**
     * 只有Hierarchy的才需要判断isLeaf，其他状态拿到的数据都算作叶子节点
     * @param item
     * @returns
     */
    const isLeaf = (item: ISearchTreeResItem) => {
      if (data.activeOrderType === 'Hierarchy') {
        return item.is_leaf ?? true
      }
      return true
    }

    const getName = (item: ISearchTreeResItem, enFirst = false) => {
      if (enFirst) {
        return item.name_en || item.name_cn || '未命名'
      }
      return item[`name_${localeData.locale.value}`] || item.name_en || item.name_cn || '未命名'
    }

    const getShortName = (item: ISearchTreeResItem) => {
      const short = item[`short_name_${localeData.locale.value}`]
      const shortEn = item.short_name_en ? item.short_name_en[0] : undefined
      return short ? short[0] : shortEn
    }
    const getShowLabel = (item: ISearchTreeResItem) => {
      const getAtcCodeName = (item: ISearchTreeResItem) => {
        const actCode = item.atc_code

        const label = getShortName(item) || getName(item)

        if (actCode) return `${item.atc_code}-${label}`

        return label
      }

      // ADC Linker 和 Payload 只需要展示英文
      if (props.type === 'ADC_Linker' || props.type === 'ADC_Payload') {
        return getName(item, true)
      }

      if (props.type === 'Target') {
        return (localeData.locale.value === 'en' ? item.entity_name_en : item.entity_name_cn) || getName(item)
      }

      if (props.type === 'Mechanism') {
        return getShortName(item) || getName(item)
      }
      if (props.type === 'ATC') {
        return getAtcCodeName(item)
      }

      return getName(item, enFirstType.includes(props.type))
    }

    const getDataSourceShowLabel = (item: ISearchTreeResItem) => {
      if (props.type === 'DATA_SOURCE_MEETING') {
        // 包含年份的大会（id带下划线的大会），树中名称只显示年份
        if (item.is_leaf && item.id.includes('_')) {
          const name = item.id.split('_')[1]
          return name.length === 4 ? name : ''
        }
      }
    }

    const getCount = (item: ISearchTreeResItem) => {
      const { countKey, rollupKey } = countTypeMap[props.countType]
      let count = item[countKey]
      if (data.activeOrderType === 'Hierarchy') {
        count = item[rollupKey]
      }
      return isUndefined(count) ? undefined : toThousands(count)
    }

    const getMechanismName = (item: ISearchTreeResItem, lang: I18nLang) => {
      const curLangShortName = item[`short_name_${lang}`]
      if (curLangShortName) {
        return curLangShortName[0]
      }
      return item.short_name_en ? item.short_name_en[0] : item[`name_${lang}`] ?? item.name_en
    }

    const convertUtil = (items: ISearchTreeResItem[], parentIds: string[] = [], isRoot = false): IConvertResItem[] => {
      return items.map((o) => {
        const res: IConvertResItem = {
          ...o,
          parentIds,
          is_leaf: isLeaf(o),
          label: getShowLabel(o),
          disabled: o.ls_related === false,
        }
        if (o.children && o.children.length) {
          res.children = convertUtil(o.children, [...parentIds, o.id])
        }
        if (props.type === 'Mechanism') {
          res.name_en = getMechanismName(o, 'en')
          res.name_cn = getMechanismName(o, 'cn')
        }
        return res
      })
    }

    const asyncTreeDataByParentId = async (id: string) => {
      if (!id) return []
      let res: IConvertResItem[] = []
      const params = getSearchParams(id)
      const searchRes = await search(params)
      if (searchRes.success) {
        res = convertUtil(searchRes.data.items ?? [], rootId.value ? [rootId.value] : [])
      }
      return res
    }

    const handleNodeClick = async (
      node: { checked: boolean; level: number; id: number; data: ISearchTreeResItem },
      resolve: (data: IConvertResItem[]) => void
    ) => {
      if (data.expandedIds.includes(node.data.id)) {
        resolve([])
      } else if (props.visible && node.data.id) {
        data.showMoreIds.push(node.data.id)
        let res: IConvertResItem[] = []
        if (node.level === 0) {
          res = await asyncTreeDataByParentId(rootId.value)
        } else {
          res = await asyncTreeDataByParentId(node.data.id)
        }
        setTimeout(async () => {
          data.dataCount[node.data?.id] = res.length
          resolve(res)
        }, 500)
      }
    }

    function resetOffsetMap() {
      data.treeOffsetMap.clear()
    }

    function getTreeOffsetByKey(key: string) {
      let treeOffset = data.treeOffsetMap.get(key)
      if (treeOffset === undefined) {
        data.treeOffsetMap.set(key, 1)
        treeOffset = 1
      }
      return treeOffset
    }

    /**
     * 自定义的加载更多
     */
    async function customLoadMore(node: any, callback: any) {
      const browseTree = ins?.proxy.$refs.browseTree as any
      const params = getSearchParams(node.data.id)
      const idKey = loopGetIdPath(node).join('-')
      const treeOffset = getTreeOffsetByKey(idKey)
      params.limit = data.treeLoadLimit
      params.offset = treeOffset * data.treeLoadLimit

      const searchRes = await search(params)
      if (searchRes.success) {
        const res = convertUtil(searchRes.data.items ?? [], rootId.value ? [rootId.value] : [])
        data.treeOffsetMap.set(idKey, treeOffset + 1)
        res.forEach((o) => {
          browseTree.$children[0].append(o, node)
        })
        callback && callback(searchRes.data.total)
      }
    }
    const iconType = computed(() => {
      switch (props.type) {
        case 'Disease':
          return 'Disease'
        case 'Target':
          return 'Target'
        case 'Organization':
          return 'Company'
        case 'Mechanism':
          return 'Mechanism'
        case 'DrugType':
          return 'DrugType'
        case 'ATC':
          return 'Atc'
      }
      return ''
    })

    const handleSearchRootListData = async () => {
      data.loading = true
      const params = getSearchParams()
      data.offset += 1
      const searchRes = await search(params)
      if (searchRes.success) {
        const newData = convertUtil(searchRes.data.items ?? [], rootId.value ? [rootId.value] : [])
        const needAdd = newData.filter((o) => !data.listData.find((k) => k.id === o.id))
        const resHighlighting = searchRes.data.highlighting || {}
        if (params.keyword) {
          data.highlightingDataMap = { ...data.highlightingDataMap, ...resHighlighting }
        } else {
          data.highlightingDataMap = resHighlighting
        }
        data.listData = data.listData.concat(needAdd)
        data.loading = false
        return !!searchRes.data.items?.length
      } else {
        data.loading = false
      }
    }

    const infiniteHandler = async ($state: { loaded: () => void; complete: () => void }) => {
      const hasData = await handleSearchRootListData()

      if (hasData) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }

    const closeSelected = (item: ISelectedLabel) => {
      data.selectedList = data.selectedList.filter((o) => o.id !== item.id)
    }

    const getTitle = () => {
      let type = ''
      switch (props.type) {
        case 'Organization':
          type = ins?.proxy.$t('common.organization') as string
          break
        case 'Target':
          type = ins?.proxy.$t('common.target') as string
          break
        case 'Mechanism':
          type = ins?.proxy.$t('common.mechanism') as string
          break
        case 'Disease':
          type = ins?.proxy.$t('common.disease') as string
          break
        case 'DrugType':
          type = ins?.proxy.$t('common.drugType') as string
          break
        case 'ATC':
          type = ins?.proxy.$t('common.atc') as string
          break
        case 'ADC_Antibody':
          type = ins?.proxy.$t('synapse_i18n.drug.adc.antibody') as string
          break
        case 'ADC_Linker':
          type = ins?.proxy.$t('synapse_i18n.drug.adc.linker') as string
          break
        case 'ADC_Payload':
          type = ins?.proxy.$t('synapse_i18n.drug.adc.payload') as string
          break
      }
      return `${ins?.proxy.$t('Browse.EmptyStart')}${type}${ins?.proxy.$t('Browse.EmptyEnd')}`
    }

    const handleSubmit = () => {
      if (canSubmit.value) {
        data.selectedList = data.selectedList.slice(0, props.leftNum)
        emit('browseSubmit', data.selectedList)
        clearData()
        handleCloseBrowse()
      }
    }

    const canSubmit = computed(() => !!data.selectedList.length)

    const handelClickOutside = (e: Event) => {
      e.stopPropagation()
      emit('closeBrowse')
    }

    const addToSelect = (type: 'Tree' | 'Self', item: IConvertResItem) => {
      const customSelect = serviceConfig[props.type].customSelect
      if (!ins) {
        return
      }
      if (customSelect && typeof customSelect === 'function') {
        data.selectedList = customSelect(ins, type, item, data.selectedList)
        return
      }
      if (item.disabled) {
        showSingleToast({
          message: ins?.proxy.$t('Browse.notAvailable') as string,
          type: 'info',
        })
        return
      }
      const index = data.selectedList.findIndex((o) => o.id === item.id)
      const res = data.selectedList[index]

      if (res) {
        if (
          props.type === 'DATA_SOURCE_MEETING' ||
          (res.search_strategy === 'ID_ROLLUP' && type === 'Tree') ||
          (res.search_strategy === 'ID' && type === 'Self')
        ) {
          showSingleToast({
            message: ins?.proxy.$t('common.excitedItem') as string,
            type: 'info',
          })
          return
        }
      }

      if (type === 'Tree') {
        const res = data.selectedList.find((o) => o.id === item.id && o.search_strategy === 'ID_ROLLUP')
        if (res) {
          showSingleToast({
            message: ins?.proxy.$t('common.excitedItem') as string,
            type: 'info',
          })
        } else {
          data.selectedList.push({
            ...item,
            icon: 'Tree',
            search_strategy: 'ID_ROLLUP',
          })
        }
      } else {
        const res = data.selectedList.find((o) => o.id === item.id && o.search_strategy === 'ID')
        if (res) {
          showSingleToast({
            message: ins?.proxy.$t('common.excitedItem') as string,
            type: 'info',
          })
        } else {
          data.selectedList.push({
            ...item,
            icon: iconType.value,
            search_strategy: 'ID',
          })
        }
      }
    }

    function handleClickItem(e: Event, item: IConvertResItem) {
      e.preventDefault()
      e.stopPropagation()
      handleItem2Select(item)
    }

    function handleItem2Select(item: IConvertResItem) {
      if (item.is_leaf || data.activeOrderType !== 'Hierarchy') {
        addToSelect('Self', item)
      } else {
        addToSelect('Tree', item)
      }
    }

    const searchPrev = () => {
      if (!data.highlightPathArr.length) {
        return
      }
      if (data.highlightIndex === 1) {
        data.highlightIndex = data.highlightPathArr.length
      } else {
        data.highlightIndex -= 1
      }
      data.highlightPathArr[data.highlightIndex - 1].length && scrollNodeIntoView(data.highlightPathArr[data.highlightIndex - 1])
    }

    const searchNext = () => {
      if (!data.highlightPathArr.length) {
        return
      }
      if (data.highlightIndex === data.highlightPathArr.length) {
        data.highlightIndex = 1
      } else {
        data.highlightIndex += 1
      }
      scrollNodeIntoView(data.highlightPathArr[data.highlightIndex - 1])
    }

    function scrollNodeIntoView(id: string) {
      const browseTree = ins?.proxy.$refs.browseTree as any
      if (browseTree) {
        const $currentHighlightNode = browseTree.$el.querySelector('.current-highlight-node')
        if ($currentHighlightNode) {
          $currentHighlightNode?.classList.remove('current-highlight-node')
        }
        const el = browseTree.$el.querySelector(`#kg-expand-node--${id}`)
        if (el) {
          el.querySelector('.kg-expand-node__text')?.classList?.add('current-highlight-node')
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
          refreshAllDensityBar()
        }
      }
    }

    async function handleLoadRootMore() {
      await handleSearchRootTree()
    }

    function handleViewDetail(item: IListItem) {
      emit('viewDetail', item)
    }

    return {
      ...toRefs(data),
      ...toRefs(rootData),
      scrollRef: ref(),
      rafRefreshAllDensityBar,
      btnGroupValue,
      handleCloseBrowse,
      handelClickOutside,
      handleSelectItem,
      handleInputKeyDown,
      handleClearSearch,
      handleSearchManually,
      orderBy,
      handleNodeClick,
      infiniteHandler,
      closeSelected,
      handleSubmit,
      canSubmit,
      getTitle,
      addToSelect,
      localeData,
      getCount,
      searchPrev,
      searchNext,
      getShowLabel,
      getName,
      getShortName,
      getDataSourceShowLabel,
      handleClickItem,
      handleViewDetail,
      customLoadMore,
      handleLoadRootMore,
      handleInput,
      isSupportKeywordSearch,
      iconType,
      // expandIdArr,
      canSearch,
    }
  },
  methods: {
    renderHeader() {
      return (
        <div class={$classes.header}>
          <div class={$classes.title}>{this.title}</div>
          <PtIcon class={$classes.headClose} icon="SolidCloseBig" onClick={this.handleCloseBrowse}></PtIcon>
        </div>
      )
    },
    renderUtil() {
      return (
        <div class={$classes.utilContainer}>
          {this.renderSearch()}
          {this.renderOrderBy()}
        </div>
      )
    },
    renderSearch() {
      const enterButton = (
        <div class="inline-flex items-center bg-gray-40 rounded px-1 mx-1">
          <GIcon svgName="ArrowEnter" class="text-text-t2" size={20}></GIcon>
          <span>Enter</span>
        </div>
      )
      const customInputTip = this.isSupportKeywordSearch ? (
        <template slot="customInputTip">
          {this.localeData.locale.value === 'cn' ? (
            <div class="inline-flex items-center">提示：支持关键词匹配，可通过{enterButton}触发</div>
          ) : (
            <div class="inline-flex items-center">Tip: Support keyword search, which can be triggered by {enterButton}</div>
          )}
        </template>
      ) : null
      return (
        <div class="flex items-center gap-2">
          <BAutoEntityInput
            prefix={this.isSupportKeywordSearch ? '' : 'SearchRight'}
            onSelect={this.handleSelectItem}
            onKeyDown={this.handleInputKeyDown}
            onClear={this.handleClearSearch}
            onInput={this.handleInput}
            class={$classes.search}
            keywords={this.keywords}
            type={this.type}
            placeHolder={this.$t('Browse.SearchPlaceHolder') as string}
            onSearchPrev={this.searchPrev}
            onSearchNext={this.searchNext}
            currentIndex={this.highlightIndex}
            searchRes={this.highlightPathArr}
            hidePopperWhenEnter={this.isSupportKeywordSearch}
            viewDetailLabel={this.viewDetailLabel}
            onViewDetail={(item: IListItem) => this.handleViewDetail(item)}
          >
            {customInputTip}
            {this.isSupportKeywordSearch && (
              <template slot="prefix">
                {this.isEntityKeywords && this.iconType && (
                  <div class="h-8 flex items-center justify-center pl-2">
                    <GIcon size={24} svgName={this.iconType}></GIcon>
                  </div>
                )}
              </template>
            )}
          </BAutoEntityInput>
          {this.isSupportKeywordSearch && (
            <div
              onClick={this.handleSearchManually}
              class={[
                'w-[32px] h-[32px] flex items-center justify-center rounded border border-gray-55',
                this.canSearch ? 'cursor-pointer hover:bg-gray-40' : 'cursor-not-allowed bg-gray-30',
              ]}
            >
              <GIcon svgName="SearchRight" class={[this.canSearch ? 'text-text-t2' : 'text-text-t4']} size={24}></GIcon>
            </div>
          )}
        </div>
      )
    },
    renderOrderBy() {
      const btnData = this.btnGroupValue
      return btnData.length ? (
        <div>
          <span class={$classes.orderby}>{this.$t('Browse.Orderby')}</span>
          <GRadio onInput={this.orderBy} value={this.activeOrderType} mode="button" items={btnData}></GRadio>
          {/* <GGroupBtn onClick={this.orderBy} activeType={this.activeOrderType} btnGroup={btnData}></GGroupBtn> */}
        </div>
      ) : null
    },
    renderMessage() {
      const visible = this.selectedList.length > this.leftNum ? $classes.messageBox : $classes.messageBoxHidden
      return (
        <div class={visible}>
          <PtIcon icon="Warning" class={$classes.icon}></PtIcon>
          <span class={$classes.message}>{`${this.$t('Browse.WarningStart')}${this.leftNum}${this.$t('Browse.WarningMid')}${this.leftNum}${this.$t(
            'Browse.WarningEnd'
          )}`}</span>
        </div>
      )
    },
    renderContent() {
      return (
        <div class={[$classes.content, 'relative']}>
          {this.renderLeft()}
          {this.renderRight()}

          {this.treeData.length > 0 && this.highlightPathArr?.length > 0 ? (
            <GDensityBar markSelector=".highlight-node" el={this.scrollRef as any} observeMutation={false} observeResize={false}></GDensityBar>
          ) : null}
        </div>
      )
    },
    renderEmptyTree() {
      if (this.loading) {
        return null
      }
      return this.type === 'Organization' ? (
        <div class={$classes.emptyTree}>
          <img src={corporateTree} alt="" />
          <div class={$classes.emptyOrgLabel}>{this.$t('Browse.EmptyTree')}</div>
        </div>
      ) : (
        <div class={[$classes.emptyTree, 'text-center']}>
          <img src={corporateTree} />
          <div class={$classes.emptyOrgLabel}>{this.localeData.tsText('暂无数据', 'No Data')}</div>
        </div>
      )
    },
    renderLeft() {
      return this.activeOrderType === 'Hierarchy' ? (
        <div class={[$classes.left, 'h-full']} v-ls-loading={this.loading} ref="scrollRef">
          {this.treeData.length
            ? [
                <GTree
                  class={$classes.tree}
                  expandedIds={this.expandedIds}
                  searchId={this.searchId}
                  treeData={this.treeData}
                  ref="browseTree"
                  resolveFunc={this.handleNodeClick}
                  showOrgLogo={this.showOrgLogo}
                  showCheckbox={false}
                  scopedSlots={{
                    default: (scope: { node: IElTreeNode; data: IConvertResItem }) => {
                      return this.renderTreeList(scope)
                    },
                  }}
                  treeLimit={this.treeLoadLimit}
                  showMoreIds={this.showMoreIds}
                  onCustomLoadMore={this.customLoadMore}
                  customLoadMoreTitle={this.$t('Browse.customLoadMoreTitle') as string}
                  onNodeExpand={this.rafRefreshAllDensityBar}
                  onNodeCollapse={this.rafRefreshAllDensityBar}
                ></GTree>,
                this.rootHasMore ? (
                  <div class="px-2">
                    <span class="bg-gray-30 text-black-default rounded cursor-pointer px-1 py-0.5 hover:bg-gray-40" onClick={this.handleLoadRootMore}>
                      {this.$t('Browse.customLoadMoreTitle')}
                    </span>
                  </div>
                ) : null,
              ]
            : this.renderEmptyTree()}
        </div>
      ) : (
        this.renderFlatList()
      )
    },
    renderLabelWithHighlight(data: IConvertResItem): { VNode: VNode | VNode[] | string; text: string } {
      const nodeId = data.id
      const isMeetings = this.type === 'DATA_SOURCE_MEETING'
      const displayName = isMeetings ? this.getDataSourceShowLabel(data) || this.getShowLabel(data) : this.getShowLabel(data)
      const highlightingData = this.highlightingDataMap[nodeId]
      const defaultData = {
        VNode: displayName,
        text: displayName,
      }
      // 节点不需要高亮
      if (isEmpty(highlightingData)) {
        return defaultData
      }
      const convertDataFunc = convertDataStrategyMap.get(this.type as IAutoCompleteType)
      // 没有转换函数，直接返回
      if (!convertDataFunc) {
        console.warn(`没有找到${this.type}类型的高亮转换函数`)
        return defaultData
      }
      const convertDataRt = convertDataFunc({
        data: [{ ...data, highlight: highlightingData }],
        locale: this.localeData.locale.value,
        keywords: this.keywords,
        itemType: this.type as IAutoCompleteType,
      })
      const highlightItem = convertDataRt?.[0]?.data?.[0]
      const suggest = highlightItem?.suggest
      const strongArr = highlightItem?.strongArr
      const mainName = this.getShortName(highlightItem) || this.getName(highlightItem)
      if (this.type === 'ATC' && highlightItem?.highlight?.ATC_CODE) {
        if (suggest) {
          return {
            VNode: [
              <BHighlightDom name={highlightItem.atc_code} strongArr={strongArr}></BHighlightDom>,
              <span>-</span>,
              <BHighlightDom name={suggest}></BHighlightDom>,
            ],
            text: displayName,
          }
        } else {
          return {
            VNode: [
              <BHighlightDom name={highlightItem.atc_code} strongArr={strongArr}></BHighlightDom>,
              <span>-</span>,
              <BHighlightDom name={mainName}></BHighlightDom>,
            ],
            text: displayName,
          }
        }
      }
      if (suggest && suggest !== displayName) {
        return {
          VNode: [
            <BHighlightDom name={displayName}></BHighlightDom>,
            <BHighlightDom parentheses={true} name={highlightItem.suggest} strongArr={strongArr}></BHighlightDom>,
          ],
          text: displayName,
        }
      }
      if (this.type === 'ATC' && !highlightItem?.highlight?.ATC_CODE) {
        return {
          VNode: [
            <BHighlightDom name={highlightItem.atc_code}></BHighlightDom>,
            <span>-</span>,
            <BHighlightDom name={mainName} strongArr={strongArr}></BHighlightDom>,
          ],
          text: displayName,
        }
      }
      return {
        VNode: <BHighlightDom name={displayName} strongArr={strongArr}></BHighlightDom>,
        text: displayName,
      }
    },
    renderTreeList(scope: { node: IElTreeNode; data: IConvertResItem }) {
      const { data, node } = scope
      const count = this.getCount(data)
      const isMeetings = this.type === 'DATA_SOURCE_MEETING'
      const displayNameWithHighlight = this.renderLabelWithHighlight(scope.data)
      const classId = loopGetIdPath(node).join('-')
      const include = this.highlightPathArr.includes(classId)
      // 中国大会，name_cn存的是全称
      const aliasCNName = data.name_cn || data.alias_cn?.[0]
      const aliasENName = data.alias_en?.[0]
      const aliasName = this.localeData.isCN.value ? aliasCNName || aliasENName : aliasENName || aliasCNName
      return (
        <span
          class={['kg-expand-node', $classes.treeItem, data.disabled ? 'cursor-not-allowed text-text-t3' : 'cursor-pointer text-text-default']}
          id={`kg-expand-node--${classId}`}
          onClick={(e: Event) => this.handleClickItem(e, data)}
        >
          {this.showOrgLogo ? <BIcon class={'mr-1 flex-shrink-0'} type="company" src={data.logo_s3_path || data.logo} size={16}></BIcon> : null}
          <span
            class={['mr-1 truncate kg-expand-node__text', { 'highlight-node': include }]}
            title={isMeetings ? aliasName || displayNameWithHighlight.text : displayNameWithHighlight.text}
          >
            {displayNameWithHighlight.VNode}
            {count !== undefined ? `(${count})` : ''}
          </span>
          {this.type === 'Organization' && this.renderOrg(data)}
        </span>
      )
    },

    renderOrg(data: IConvertResItem) {
      const isStartup = data.start_up
      const ownerShip = getOwnerShip(data, this.localeData.locale.value)
      const res = ownerShip ? `| ${ownerShip}` : ownerShip
      const displayMaxPercent = getDisplayPercentNumber(data.max_percent)
      return (
        <div class={$classes.treeItemRight}>
          {isStartup ? [<span class="mr-1"> |</span>, <BStartupTag class="mr-1" />] : null}
          <span>{res}</span>
          {displayMaxPercent ? <span class="mx-1 rounded text-xs bg-blue-light text-blue-default px-1">{displayMaxPercent}</span> : null}
        </div>
      )
    },
    renderFlatList() {
      return (
        <div class={$classes.left} key={this.activeOrderType}>
          {this.listData.map((o) => {
            const count = this.getCount(o)
            const isMeetings = this.type === 'DATA_SOURCE_MEETING'
            const aliasCNName = o.name_cn || o.alias_cn?.[0]
            const aliasENName = o.alias_en?.[0]
            const aliasName = this.localeData.isCN.value ? aliasCNName || aliasENName : aliasENName || aliasCNName

            const displayNameWithHighlight = this.renderLabelWithHighlight(o)
            return (
              <div class={$classes.checkboxContainer} onClick={(e: Event) => this.handleClickItem(e, o)}>
                <span title={isMeetings ? aliasName || displayNameWithHighlight.text : displayNameWithHighlight.text} class={$classes.label}>
                  {displayNameWithHighlight.VNode}
                  {count !== undefined ? `(${count})` : ''}
                </span>
              </div>
            )
          })}
          <infinite-loading onInfinite={this.infiniteHandler} identifier={this.infiniteLoadingIdentifier} distance={10} ref="infiniteLoading">
            <template slot="spinner">
              <div class="relative h-[100px]">
                <GLoading></GLoading>
              </div>
            </template>
            <div slot="no-results" class={[$classes.emptyTree, 'text-center h-[300px]']}>
              <img src={corporateTree} />
              <div class={$classes.emptyOrgLabel}>{this.localeData.tsText('暂无数据', 'No Data')}</div>
            </div>
            <div class="py-2 pb-4" slot="no-more">
              <span>{this.$t('Browse.noMoreData')}</span>
            </div>
          </infinite-loading>
        </div>
      )
    },
    renderRight() {
      return this.selectedList.length ? (
        <div class={$classes.right}>
          {this.selectedList.map((o) => (
            <div class={$classes.rightTag}>
              {o.icon ? <PtIcon icon={o.icon} class={$classes.iconTop}></PtIcon> : null}
              <div class={$classes.tagLabel}>{this.getShowLabel(o)}</div>
              <PtIcon icon="SolidCloseBig" class={$classes.close} onClick={() => this.closeSelected(o)}></PtIcon>
            </div>
          ))}
        </div>
      ) : (
        <div class={[$classes.right, $classes.empty]}>
          <img src={chooseEmpty} alt="" />
          <div class={$classes.emptyTitle}>{this.getTitle()}</div>
        </div>
      )
    },
    renderBtnContainer() {
      return (
        <div class={$classes.btnContainer}>
          <button class={['pt-ui-btn', $classes.cancel]} type="button" data-type="default" onClick={this.handleCloseBrowse}>
            {this.$t('common.cancel')}
          </button>
          <button
            class={['pt-ui-btn', 'ml-2', this.canSubmit ? $classes.ableSubmit : $classes.disableSubmit]}
            type="button"
            data-type="submit"
            onClick={this.handleSubmit}
          >
            {this.$t('common.submit')}
          </button>
        </div>
      )
    },
  },
  render() {
    return (
      <div
        id={`browse_${this.type}`}
        class={[$classes.browseContainer, { hidden: !this.visible }, this.showOnTop ? 'bottom-10' : 'top-10']}
        {...{
          directives: this.visible
            ? [
                {
                  name: 'click-outside',
                  value: this.handelClickOutside,
                },
              ]
            : [],
        }}
      >
        {this.renderHeader()}
        {this.renderUtil()}
        {this.renderMessage()}
        {this.renderContent()}
        {this.renderBtnContainer()}
      </div>
    )
  },
})
