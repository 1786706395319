import { createIndependentVueInstance } from '@pharmsnap/shared/src/utils/vm'
import { Component } from 'vue'
let hasInited = false
export async function initDevTools() {
  if (hasInited) {
    return
  }
  hasInited = true
  const DevToolPanel = await (() => import('./panel'))
  createIndependentVueInstance({
    component: DevToolPanel as Component,
    componentVNodeData: {},
  })
}

window.openDevTool = async () => {
  await initDevTools()
  setTimeout(() => {
    window.dispatchEvent(new Event('devToolOpen'))
  }, 100)
}
