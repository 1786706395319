import { ISignType } from '@pharmsnap/shared/service/apis/searchApi'
import { defineComponent, onBeforeUnmount, PropType, ref } from '@vue/composition-api'
import { Subscription } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { VNode } from 'vue'
import { getNeedSignatureImgSubject, getSignatureImgRtStream } from './signature-subject'

export const BSignatureImg = defineComponent({
  name: 'BSignatureImg',
  props: {
    src: {
      required: true,
      type: String,
    },
    signType: {
      type: String as PropType<ISignType>,
      default: 'master_entity_org_logo',
    },
  },
  setup(props, ctx) {
    const signaturedSrc = ref('')
    const loading = ref(false)
    let subscription: Subscription | null = null
    const src = props.src
    init()
    function init() {
      // 若图片包含http，认为是可用于渲染的地址，不需要异步获取签名
      if (src.startsWith('http')) {
        signaturedSrc.value = src
      } else {
        subscription = getSignatureImgRtStream()
          .pipe(
            filter((logoMap) => !!logoMap[src]),
            map((logoMap) => logoMap[src]),
            take(1)
          )
          .subscribe((src) => {
            loading.value = false
            signaturedSrc.value = src
            ctx.emit('update', src)
          })
        loading.value = true
        getNeedSignatureImgSubject().next({ logo: src, signType: props.signType })
      }
    }
    onBeforeUnmount(() => {
      subscription?.unsubscribe()
    })
    return { signaturedSrc, loading }
  },
  render() {
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({ loading: this.loading, src: this.signaturedSrc }) as unknown as VNode
    }
    return null as unknown as VNode
  },
})
