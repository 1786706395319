import type { IACListItem } from '@patsnap/synapse_common_interface'
import { ElButton } from '@pharmsnap/shared/element-ui'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import type { IAutoCompleteType, IListItem } from '@pharmsnap/shared/types'
import { disableViewDetailAutocompleteTypeList, getOwnerShip } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { includes } from 'lodash'
import { BIcon } from '../..'
import atcIcon from '../../../assets/svg/atc.svg'
import diseaseIcon from '../../../assets/svg/disease.svg'
import drugIcon from '../../../assets/svg/drug.svg'
import drugTypeIcon from '../../../assets/svg/drugType.svg'
import gptLogo from '../../../assets/svg/gptLogo.svg'
import mechanismIcon from '../../../assets/svg/mechanism.svg'
import targetIcon from '../../../assets/svg/target.svg'
import topicIcon from '../../../assets/svg/topic.svg'
import { useLocale } from '../../../composition/useLocale'
import { BStartupTag } from '../../business/card/BOrganizationCard/BStartupTag'
import { BHighlightDom } from '../BHighlightDom/BHighlightDom'
import $classes from './BAcList.module.scss'

const acListIconMap: Map<IAutoCompleteType, any> = new Map()
acListIconMap.set('Drug', drugIcon)
acListIconMap.set('Target', targetIcon)
acListIconMap.set('Disease', diseaseIcon)
acListIconMap.set('DrugType', drugTypeIcon)
acListIconMap.set('Mechanism', mechanismIcon)
acListIconMap.set('ATC', atcIcon)
acListIconMap.set('Topic', topicIcon)

export const BAcList = defineComponent({
  name: 'BAcList',
  props: {
    inputText: {
      type: String,
      default: '',
    },
    items: {
      required: true,
      type: Array as PropType<IACListItem[]>,
      default: () => [],
    },
    viewDetailLabel: {
      type: String,
    },
    maxHeight: {
      type: Number,
      default: 256,
    },
  },
  setup(props, { emit }) {
    const { tsText } = useLocale()
    function trackingClick(item: IListItem, dataType: IACListItem['dataType'], index: number) {
      trackingEvent('CLICK_AUTOCOMPLETE_ITEM', {
        common1: props.inputText,
        common2: dataType === 'Recommend',
        common3: index,
        common4: item.itemType || '',
        common5: item.id || tsText(item.name_cn || item.name_en || '', item.name_en || item.name_cn || ''),
        payload: {
          all_data: props.items.filter((o) => o.data.length),
        },
      })
    }
    const viewDetail = (e: Event, item: IListItem, dataType: IACListItem['dataType'], index: number) => {
      e.preventDefault()
      e.stopPropagation()
      trackingClick(item, dataType, index)
      emit('viewDetail', item)
    }

    const selectItem = (e: Event, item: IListItem, dataType: IACListItem['dataType'], index: number) => {
      e.stopPropagation()
      trackingClick(item, dataType, index)
      emit('selectItem', item)
    }

    const onMouseup = (e: Event) => {
      e.stopPropagation()
      emit('mouseup')
    }

    const localeData = useLocale()

    return { onMouseup, selectItem, localeData, viewDetail }
  },
  methods: {
    renderATCLabel(item: IListItem) {
      const curLangKey: 'name_en' | 'name_cn' = `name_${this.localeData.locale.value}`
      if (item.suggest) {
        return [
          <BHighlightDom name={item.atc_code} strongArr={item.strongArr}></BHighlightDom>,
          '-',
          <BHighlightDom name={item.suggest}></BHighlightDom>,
        ]
      } else {
        return [
          <BHighlightDom name={item.atc_code} strongArr={item.strongArr}></BHighlightDom>,
          '-',
          <BHighlightDom name={item[curLangKey] || item.name_en}></BHighlightDom>,
        ]
      }
    },
    renderLabel(item: IListItem) {
      const curLangKey: 'name_en' | 'name_cn' = `name_${this.localeData.locale.value}`
      if (item.itemType === 'ATC' && item.highlight?.ATC_CODE) {
        return this.renderATCLabel(item)
      }
      // outcome autocomplete 特殊处理
      if (item.itemType === 'Outcome') {
        const shortNameEn = item.short_name_en?.[0] || ''
        const fullName = item[curLangKey] || item.name_en || ''
        const splitLine = shortNameEn && fullName ? <span class="inline-block mx-1">|</span> : ''

        if (shortNameEn !== fullName) {
          // 显示规则：简称｜全称｜（别名命中）
          // 如果别名命中为简称或者全称，直接高亮对应名称，不显示括号内别名命中
          if (item.suggest && item.suggest !== shortNameEn && item.suggest !== fullName) {
            return [
              shortNameEn,
              splitLine,
              fullName,
              <BHighlightDom parentheses={true} name={item.suggest} strongArr={item.strongArr}></BHighlightDom>,
            ]
          }
          if (item.suggest && item.suggest === shortNameEn) {
            return [<BHighlightDom name={shortNameEn} strongArr={item.strongArr}></BHighlightDom>, splitLine, fullName]
          }
          if (item.suggest && item.suggest === fullName) {
            return [shortNameEn, splitLine, <BHighlightDom name={fullName} strongArr={item.strongArr}></BHighlightDom>]
          }
          return [shortNameEn ? <BHighlightDom name={shortNameEn} strongArr={item.strongArr}></BHighlightDom> : '', splitLine, fullName]
        }
        // outcome 简称和全称一样时，走下面老逻辑，优先取简称
        item.name_en = shortNameEn || item.name_en
        item.name_cn = shortNameEn || item.name_cn
      }
      const mainLabel = item[curLangKey] || item.name_en || item.name_cn
      if (item.suggest && item.suggest !== mainLabel) {
        if (item[curLangKey]) {
          return [item[curLangKey], <BHighlightDom parentheses={true} name={item.suggest} strongArr={item.strongArr}></BHighlightDom>]
        } else {
          return [
            <BHighlightDom name={item.name_en || item.name_cn}></BHighlightDom>,
            <BHighlightDom parentheses={true} name={item.suggest} strongArr={item.strongArr}></BHighlightDom>,
          ]
        }
      } else {
        return <BHighlightDom name={mainLabel} strongArr={item.strongArr}></BHighlightDom>
      }
    },
    renderOrgOwnerShip(item: IListItem) {
      if (item.itemType !== 'Organization') return
      const ownerShip = getOwnerShip(item, this.localeData.locale.value)
      const res = ownerShip ? `| ${ownerShip}` : ownerShip
      return (
        <span class="ml-1 text-gray-450" title={ownerShip}>
          {res}
        </span>
      )
    },
    /** 机构显示的名称不包含输入的内容，展示别名命中 */
    renderOrgAliasNameHit(item: IListItem) {
      if (item.itemType !== 'Organization' || !!item.strongArr?.length) return
      return <span class="px-3 py-1 ml-1 rounded text-xs bg-gray-20 text-gray-90">{this.localeData.isCN.value ? '别名命中' : 'Alias hits'}</span>
    },
    renderViewDetail(item: IListItem, dataType: IACListItem['dataType'], index: number) {
      if (this.viewDetailLabel && !includes(disableViewDetailAutocompleteTypeList, item.itemType)) {
        return (
          <ElButton class={$classes.viewDetail} type="text" onClick={(e: Event) => this.viewDetail(e, item, dataType, index)}>
            <span class={$classes.viewDetailLabel}>{this.viewDetailLabel}</span>
          </ElButton>
        )
      }
    },
    renderStartup(item: IListItem) {
      if (item.itemType !== 'Organization') return
      if (!item.start_up) return
      return (
        <div class="flex">
          <div class="mx-1"> | </div>
          <BStartupTag />
        </div>
      )
    },
    renderLabelData(item: IListItem, dataType: IACListItem['dataType'], index: number, showIcon?: boolean) {
      const showDetail = this.viewDetailLabel && !includes(disableViewDetailAutocompleteTypeList, item.itemType)
      return (
        <div data-testid="b-ac-list__item" class={$classes.acItem} onClick={(e: Event) => this.selectItem(e, item, dataType, index)}>
          <div class={[$classes.labelContainer, showDetail ? 'flex-1 mr-1' : 'w-full']}>
            {showIcon && item.itemType === 'Organization' ? (
              <BIcon slot="icon" class={[$classes.icon, 'mr-2']} type="company" src={item.logo_s3_path || item.logo} size={20}></BIcon>
            ) : null}
            {showIcon && item.itemType && item.itemType !== 'Organization' ? (
              <div class={$classes.icon} style={{ backgroundImage: `url(${acListIconMap.get(item.itemType)})` }}></div>
            ) : null}
            <div data-testid="b-ac-list__item__label" class={$classes.label} title={item[`name_${this.localeData.locale.value}`] ?? item.name_en}>
              {item.itemType === 'ATC' && item.atc_code && !item.highlight?.ATC_CODE ? <span>{`${item.atc_code}-`}</span> : null}
              {this.renderLabel(item)}
              <div class="flex">
                {this.renderStartup(item)}
                {this.renderOrgOwnerShip(item)}
              </div>
            </div>
            <div class="justify-self-end">{this.renderOrgAliasNameHit(item)}</div>
          </div>
          {!!showDetail && (
            <div data-testid="b-ac-list__item__view-detail" class={$classes.suffixContainer}>
              {this.renderViewDetail(item, dataType, index)}
            </div>
          )}
        </div>
      )
    },
    renderRecommendTag() {
      return (
        <div class="inline-flex items-center ml-2 h-5 leading-5 px-1 rounded text-xs text-[#3C2FD3] bg-[#EDF1FF]">
          <img width={18} height={18} class="mr-1" src={gptLogo} />
          {this.localeData.isCN.value ? '芽仔推荐' : "Hiro's Recommendation"}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.acListContainer} style={{ maxHeight: `${this.maxHeight}px` }} onMouseup={(e: Event) => this.onMouseup(e)}>
        {this.items.map((o) => {
          return (
            <div>
              {o.name && o.data.length ? (
                <div class={$classes.acItemLabel}>
                  {o.name}
                  {o.dataType === 'Recommend' && this.renderRecommendTag()}
                </div>
              ) : null}
              {o.data.map((k, index) => {
                return this.renderLabelData(k, o.dataType, index, o.showIcon)
              })}
            </div>
          )
        })}
      </div>
    )
  },
})
