import { toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { IAssigneeCountryItem } from '@pharmsnap/shared/types'
import { pickDisplayNameByLocale } from '@pharmsnap/shared/utils'
import { computed, ComputedRef, getCurrentInstance, Ref } from '@vue/composition-api'
import { maxBy } from 'lodash'
import { GridColumns } from 'vxe-table'
import { IPatentStatus, ITopAssigneesChartItem } from '../../../types'
import { countRowFormatter } from '../../../utils'

export function useTopAssigneesTable(params: {
  chartData: ComputedRef<ITopAssigneesChartItem[][]>
  selectedCountry: ComputedRef<IAssigneeCountryItem[]>
  isSingleBar: Ref<boolean>
  selectedPatentStatus: Ref<IPatentStatus>
}) {
  const { chartData, selectedCountry, isSingleBar, selectedPatentStatus } = params
  const ins = getCurrentInstance()
  const { locale } = useLocale()

  const maxLengthData = computed(() => maxBy(chartData.value, (item) => item.length))
  const doubleBarTableColumns = computed<GridColumns[]>(() => {
    const buildColumns: GridColumns[] = [
      {
        field: 'col0',
        fixed: 'left',
        width: 140,
        showOverflow: false,
      },
    ]
    chartData.value.forEach((item, index) => {
      buildColumns.push(
        ...[
          {
            field: `col${index + 1}`,
            minWidth: 140,
            showOverflow: 'title',
          },
          {
            field: `col${index + 1}-num`,
            minWidth: 50,
          },
        ]
      )
    })
    return buildColumns
  })

  const doubleBarTableData = computed(() => {
    const columns = [{ field: 'country', title: ins?.proxy.$tc('topAssignees.assigneesRegion') }]
    if (maxLengthData.value) {
      maxLengthData.value.forEach((item, index) => {
        columns.push({
          field: `assignee${index}`,
          title:
            selectedPatentStatus.value === 'application'
              ? ins?.proxy.$tc('topAssignees.applicationPatents')
              : ins?.proxy.$tc('topAssignees.grantedPatents'),
        })
      })
    }

    const data: Array<{ [key: string]: string | number }> = []

    chartData.value.forEach((assigneeList, index) => {
      const country = selectedCountry.value[index]
      const obj: { [key: string]: string | number } = {
        country: `${pickDisplayNameByLocale(country, locale.value)} (${country.code})`,
      }
      assigneeList.forEach((assignee, index) => {
        obj[`assignee${index}`] = pickDisplayNameByLocale(assignee, locale.value)
        obj[`assignee${index}num`] = toThousands(assignee.count)
      })
      data.push(obj)
    })

    return columns.map((column) => {
      const item: { [key: string]: string | number } = { col0: column.title as string }
      data.forEach((row, index) => {
        item[`col${index + 1}`] = row[column.field] || '-'
        item[`col${index + 1}-num`] = row[`${column.field}num`] || '-'
      })
      return item
    })
  })
  const singleBarTableColumns = computed<GridColumns[]>(() => [
    {
      title: ins?.proxy.$tc('topAssignees.assignees'),
      field: '__name',
    },
    {
      title: selectedPatentStatus.value === 'application' ? ins?.proxy.$tc('patentsApplication') : ins?.proxy.$tc('patentsGranted'),
      field: 'count',
      slots: {
        default: ({ row }) => [toThousands(row.count)],
      },
    },
  ])

  const singleBarFullScreenTableColumns = computed<GridColumns[]>(() => {
    const buildColumns: GridColumns[] = [
      {
        field: 'col0',
        fixed: 'left',
        width: 170,
        showOverflow: false,
      },
    ]
    const data = chartData.value[0]
    data.forEach((item, index) => {
      buildColumns.push({
        field: `col${index + 1}`,
        minWidth: 160,
        showOverflow: 'title',
        formatter: countRowFormatter,
      })
    })
    return buildColumns
  })

  const singleBarFullScreenTableData = computed(() => {
    const data = chartData.value[0]
    return singleBarTableColumns.value.map((column) => {
      const columnData: Record<string, string> = { col0: column.title || EMPTY_PLACEHOLDER }
      data.forEach((item, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columnData[`col${index + 1}`] = column.field && item[column.field] ? item[column.field] : EMPTY_PLACEHOLDER
      })
      return columnData
    })
  })

  const tableColumns = computed(() => {
    if (isSingleBar.value) return singleBarTableColumns.value
    return doubleBarTableColumns.value
  })

  const tableData = computed(() => {
    if (isSingleBar.value) return chartData.value[0]
    return doubleBarTableData.value
  })

  const fullScreenTableColumns = computed(() => {
    if (isSingleBar.value) return singleBarFullScreenTableColumns.value
    return doubleBarTableColumns.value
  })

  const fullScreenTableData = computed(() => {
    if (isSingleBar.value) return singleBarFullScreenTableData.value
    return doubleBarTableData.value
  })

  const mergeCellFun = ({ rowIndex, columnIndex }: { rowIndex: number; columnIndex: number }) => {
    if (isSingleBar.value) return
    if (rowIndex === 0 && columnIndex > 0) {
      return { rowspan: 1, colspan: columnIndex % 2 === 0 ? 0 : 2 }
    }
    if (rowIndex > 0 && columnIndex === 0) {
      return { rowspan: maxLengthData.value?.length, colspan: rowIndex === 1 ? 1 : 0 }
    }
  }

  return {
    tableColumns,
    tableData,
    fullScreenTableColumns,
    fullScreenTableData,
    mergeCellFun,
  }
}
