import type { IBAcTag, tagType } from '@pharmsnap/shared/types'
import { getLangDegraded } from '@pharmsnap/shared/utils/lang'
import { PropType, defineComponent } from '@vue/composition-api'
import { useLocale } from '../../../composition/useLocale'
import { GCountryFlag } from '../../ui/GCountryFlag/GCountryFlag'
import { GLimited } from '../../ui/GLimited/GLimited'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BAcTag } from '../tag/BAcTag/BAcTag'
import $classes from './BAcTagList.module.scss'

export const BAcTagList = defineComponent({
  name: 'BAcTagList',
  props: {
    limit: {
      type: Number,
    },
    tagItems: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    tagType: {
      type: String as PropType<tagType>,
      default: 'normal',
    },
    isClickAble: {
      type: Boolean,
      default: true,
    },
    disableLabel: {
      type: String,
      default: '',
    },
    /** 全球是否展示flag */
    showGlobalFlag: {
      type: Boolean,
      default: false,
    },
    useFullName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()

    const handleClick = (item: IBAcTag) => {
      if (!props.isClickAble) return
      emit('click', item)
    }

    return { locale, handleClick }
  },
  methods: {
    renderSlot() {
      return [<div slot="more">{this.$t('common.more')}</div>, <div slot="less">{this.$t('common.less')}</div>]
    },

    renderCountryFlag(item: IBAcTag, ownerClass: string) {
      const isFakeData = ['All', 'Other'].includes(item.id)
      const name = isFakeData ? item[`name_${this.locale}`] || item.name_en : item.name_en
      return (
        <div class={[`${ownerClass} ${$classes.flag}`]} onClick={() => this.handleClick(item)}>
          <GCountryFlag useFullName={this.useFullName} name={name} showName={!isFakeData} isSmall showGlobalFlag={this.showGlobalFlag}></GCountryFlag>
        </div>
      )
    },

    renderCommonTag(item: IBAcTag, ownerClass: string) {
      return (
        <BAcTag
          ownerClass={ownerClass}
          name={getLangDegraded(item, this.locale)}
          icon={item.icon}
          canClose={this.canClose}
          onClick={() => this.handleClick(item)}
          infoContent={item.infoContent}
        >
          {this.$slots.default && this.$slots.default}
        </BAcTag>
      )
    },

    renderTag(item: IBAcTag) {
      const isSelected = this.selectedItems.find((o) => o.id === item.id)
      const ownerClass = isSelected ? $classes.selectedTag : this.isClickAble ? $classes.tagAble : $classes.tagDisable
      return (
        <GTooltip
          popperClass={$classes.tagPopper}
          placement="top"
          content={this.disableLabel}
          openDelay={1000}
          disabled={!this.isClickAble && this.disableLabel ? false : true}
        >
          {this.tagType === 'countryFlag' ? this.renderCountryFlag(item, ownerClass) : this.renderCommonTag(item, ownerClass)}
        </GTooltip>
      )
    },

    renderLimitComp() {
      return (
        <GLimited
          mode="expand"
          items={this.tagItems}
          limit={this.limit}
          inline={true}
          gap={{ x: 0, y: 0 }}
          class={$classes.tagListContainer}
          scopedSlots={{
            default: ({ item }: { item: IBAcTag }) => this.renderTag(item),
          }}
        >
          {this.renderSlot()}
        </GLimited>
      )
    },

    renderAll() {
      return <div class={$classes.tagListContainer}>{[this.tagItems.map((item: IBAcTag) => this.renderTag(item)), this.$slots.more]}</div>
    },

    renderData() {
      return this.limit ? this.renderLimitComp() : this.renderAll()
    },
  },
  render() {
    const ele = this.renderData() as JSX.Element
    return ele
  },
})
