import { BasicPieChart } from '@pharmsnap/shared/chart'
import { IUsePieChartData, IUsePieChartItem } from '@pharmsnap/shared/types/component'
import { computed, Ref } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { useChart } from './useChart'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePieChart<T = any>(
  data: Ref<IUsePieChartData<T>>,
  config: {
    radius?: string[]
    seriesName: string | (() => string)
    colorMap?: (item: IUsePieChartItem<T>) => string
    clickAble?: boolean
    registerEvent?: (instance: EChartsType) => void
  }
) {
  const clickAble = typeof config.clickAble === 'undefined' ? false : !!config.clickAble
  const isEmpty = computed(() => data.value.data.length === 0)

  const pieChartOption = computed<BasicPieChart>(() => {
    const seriesName = typeof config?.seriesName === 'function' ? config.seriesName() : config?.seriesName || ''

    const options: BasicPieChart = {
      type: 'pie',
      legend: {
        show: false,
      },
      data: data.value.data,
      series: {
        name: seriesName,
        radius: config?.radius || ['0', '45%'],
        cursor: clickAble ? 'pointer' : 'normal',
        ...(typeof config?.colorMap === 'function'
          ? {
              itemStyle: {
                color: (params) => {
                  const value = params.value as unknown as IUsePieChartItem<T>
                  return (config.colorMap && config.colorMap(value)) || ''
                },
              },
            }
          : undefined),
      },
    }

    return options
  })

  const { initChart, chartContainer, render, options } = useChart(pieChartOption, { autoResize: true, registerEvent: config.registerEvent })

  return {
    chartContainer,
    initChart,
    render,
    options,
    isEmpty,
  }
}
