import { E_QUERY_ITEM_CONDITION, IAggregationItem, IAggregationSingleResult, IAggregationValue, IQueryItem } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { GTree } from '@pharmsnap/shared/components'
import { userInjectFilterService } from '@pharmsnap/shared/components/business/BFilter/BFilterService'
import { userFilterItemState } from '@pharmsnap/shared/components/business/BFilter/composable/userFilterItemState'
import { IFilterItemLocationTreeConfig, IFilterValue, IFilterValueArrItem } from '@pharmsnap/shared/components/business/BFilter/types'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { useInjectQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { FREE_SHOW_MORE_UPGRADE_SHOWED } from '@pharmsnap/shared/constants'
import { IElTreeNode, ITreeCheckData } from '@pharmsnap/shared/types'
import { encryptNum, getLocalStorage, setLocalStorage } from '@pharmsnap/shared/utils'
import { computed, defineComponent, nextTick, onMounted, PropType, ref } from '@vue/composition-api'
import { filter, map } from 'lodash'
import { LimitContainer } from '../../../../ui/LimitContainer/LimitContainer'
import $style from './FilterItemLocationTree.module.scss'
/**
 * 从filterValueArr中获取某个location字段的值
 * @param filterItems
 * @param field
 * @returns
 */
function getSelectedLocationFieldDataDeep(filterItems: IFilterValueArrItem[] = [], field: string): IFilterValue[] {
  const rt: IFilterValue[] = []
  if (!filterItems.length) {
    return rt
  }
  for (const item of filterItems) {
    if (item.children?.length) {
      const childRt = getSelectedLocationFieldDataDeep(item.children, field)
      if (childRt.length) {
        rt.push(...childRt)
      }
      continue
    }
    if (item.field === field && item.value.length) {
      rt.push(...item.value)
    }
  }
  return rt
}

/**
 * 检测过滤项值中是否含有任意一个location字段,且条件关系是ANY的
 * @param filterItems
 * @param fields
 * @returns
 */
function checkHasAnyLocationField(filterItems: IFilterValueArrItem[] = [], fields: string[]): boolean {
  return filterItems.some((item) => {
    if (item.children?.length) {
      return checkHasAnyLocationField(item.children, fields)
    }
    if (item.condition === E_QUERY_ITEM_CONDITION.NONE) {
      return false
    }
    return fields.some((_item) => item.field.includes(_item))
  })
}

type ITreeDataItem = IAggregationValue & {
  id: string
  is_leaf?: boolean
  children?: ITreeDataItem[]
  level: number
  disabled?: boolean
}

export const FilterItemLocationTree = defineComponent({
  name: 'FilterItemLocationTree',
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemLocationTreeConfig>,
    },
    getTreeChildDataFn: {
      type: Function as PropType<(extraFilter: IQueryItem[], aggregationItems: IAggregationItem[]) => Promise<IAggregationSingleResult[]>>,
    },
  },
  i18n: {
    messages: {
      cn: {
        'FilterItemLocationTree.noData': '无结果，请尝试去掉一些已过滤项或调整检索式',
      },
      en: {
        'FilterItemLocationTree.noData': 'No results. Try removing some filters or broadening your search.',
      },
    },
  },
  setup(props) {
    const { field, treeFields = [], aggregationConfig, checkIsLeaf, isLoadFullTreeData } = props.config
    const { state, actions } = userInjectFilterService()
    const { state: queryState } = useInjectQueryService()
    const { isCN } = useLocale()
    const treeRef = ref()

    const { filterData } = userFilterItemState(props.config, 'list')

    /**
     * 大洲已选过滤值
     */
    const filterContinentVal = getSelectedLocationFieldDataDeep(state?.filterValueArr, treeFields[0])
    /**
     * 国家已选过滤值
     */
    const filterCountryVal = getSelectedLocationFieldDataDeep(state?.filterValueArr, treeFields[1])
    /**
     * 省份已选过滤值(期望是只有中国&国内版本才有值)
     */
    const filterStateVal = getSelectedLocationFieldDataDeep(state?.filterValueArr, treeFields[2])

    /**
     * 已选数据
     */
    const checkedIds = ref<string[]>([])

    /**
     *
     * @param node level从1开始
     * @param resolve
     */
    const handleLoadNodeData = async (
      node: { checked: boolean; level: number; id: number; data: ITreeDataItem },
      resolve: (data: ITreeDataItem[]) => void
    ) => {
      if (node.data.key) {
        let childrenNodes: ITreeDataItem[] = []
        childrenNodes = await getChildData(node.data, node.level, node.data.disabled)
        if (node.data.disabled) {
          const [elTreeRef] = treeRef.value.$children
          const checkedIds = elTreeRef.getCheckedKeys()
          elTreeRef.setCheckedKeys(Array.from(new Set([...checkedIds, ...childrenNodes.map((o) => o.key)])))
        }
        if (childrenNodes.every((o) => o.disabled)) {
          node.data.disabled = true
        }

        setTimeout(async () => {
          node.data.children = childrenNodes
          resolve(childrenNodes)
        }, 500)
      }
    }

    /**
     *
     * @param data
     * @param level 从0开始
     * @returns
     */
    function generateData(data: IAggregationValue, level: number, pDisabled = false) {
      let children: ITreeDataItem[] = []
      const disabled = checkedIds.value.includes(data.key) || pDisabled

      if (data.aggregation_result?.length) {
        const subAggItems = data.aggregation_result.find((o) => o.aggregation_field === treeFields[level + 1])?.items || []
        if (subAggItems.length) {
          // 若子节点disabled根据父节点是否勾选设置，当子节点disabled为true，父节点的勾选状态会失效,所有这里先默认false，在expandNode时，再处理子节点disabled状态
          children = subAggItems.map((o) => generateData(o, level + 1))
        }
      }

      return {
        ...data,
        ...(children.length ? { children } : {}),
        id: data.key,
        is_leaf: checkIsLeaf ? checkIsLeaf(data, treeFields[level] || treeFields[0]) : false,
        disabled,
        level,
      }
    }

    function handleCheck(node: ITreeDataItem, checkedData: ITreeCheckData<ITreeDataItem>) {
      const { checkedNodes } = checkedData
      let realCheckedNodes = checkedNodes.concat()
      checkedNodes
        .filter((o) => !!o.children?.length)
        .forEach((o) => {
          const children = getNodeAllChildren(o, [])
          if (!props.config.checkStrictly) {
            const isAllChildrenSelected = children.every((o) => checkedNodes.find((k) => k.id === o.id))
            if (isAllChildrenSelected) {
              realCheckedNodes = realCheckedNodes.filter((n) => !children.find((k) => k.id === n.id))
            }
          }
        })

      // 去除已经勾选的节点
      realCheckedNodes = realCheckedNodes.filter((item) => !item.disabled)
      treeFields.map((field, level) => {
        actions?.setFilterValue(
          field,
          // 有变更时，同步修改，没变更时，删除该field对应的缓存数据
          map(filter(realCheckedNodes, ['level', level]), (val) => ({
            display_name_cn: val.display_name_cn,
            display_name_en: val.display_name_en,
            value: val.key,
          })) || []
        )
      })
    }

    function handleNodeClick(node: ITreeDataItem) {
      if (node.disabled) {
        return
      }
      /** 点击元素本身，只提交此次点击的元素，
       * 之前已勾选未提交的元素，无需提交，
       * 所以先clearAll，清除已勾选的过滤项
       */
      actions?.clearAll()
      actions?.setFilterValue(treeFields[node.level], [
        {
          display_name_cn: node.display_name_cn,
          display_name_en: node.display_name_en,
          value: node.key,
        },
      ])
      nextTick(() => {
        actions?.refine(false)
      })
    }

    /**
     * 获取某个节点的所有子孙节点
     * @param node
     * @param arr
     * @returns
     */
    function getNodeAllChildren(node: ITreeDataItem, arr: ITreeDataItem[] = []) {
      if (!node.children) return arr
      node.children.forEach((o) => {
        arr.push(o)
        getNodeAllChildren(o, arr)
      })
      return arr
    }

    /**
     *
     * @param node
     * @param level 这边level统一为树的level，所以是从1开始
     * @returns
     */
    async function getChildData(node: ITreeDataItem, level: number, pDisabled = false): Promise<ITreeDataItem[]> {
      const filterField = treeFields[level - 1] || treeFields[0]
      const aggField = treeFields[level] || treeFields[0]
      if (!field || !queryState?.query || !props.getTreeChildDataFn) return []

      const res = await props.getTreeChildDataFn(
        [
          {
            type: 'field',
            fields: [filterField],
            value: [
              {
                type: 'text',
                value: node.key,
                display_name_cn: node.display_name_cn,
                display_name_en: node.display_name_en,
              },
            ],
          },
        ],
        aggregationConfig
          ? [
              {
                ...aggregationConfig,
                aggregation_field: aggField,
              },
            ]
          : []
      )

      return (res.find((o) => o.aggregation_field === aggField)?.items || []).map((o) => generateData(o, level, pDisabled))
    }

    const treeData = computed<ITreeDataItem[]>(() => filterData.value.map((o) => generateData(o, 0)))
    function getFilterValueInitCheckIds() {
      if (isLoadFullTreeData) {
        return []
      }
      if (checkHasAnyLocationField(state?.filterValueArr, treeFields)) {
        return treeData.value.map((item) => item.key)
      }
      return []
    }
    onMounted(async () => {
      const getValueFn = (o: IFilterValue) => o.value as string
      checkedIds.value = Array.from(
        new Set([
          ...filterContinentVal.map(getValueFn),
          ...filterCountryVal.map(getValueFn),
          ...filterStateVal.map(getValueFn),
          ...getFilterValueInitCheckIds(),
        ])
      )
    })

    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const handelClickMore = computed(() => {
      if (!props.config.isSupportAdvanceRefine) {
        return undefined
      }
      if (isFreeUser.value) {
        const showed = getLocalStorage(FREE_SHOW_MORE_UPGRADE_SHOWED)
        const stateShowFreeUpgrade = state?.freeUpgradeShow
        if (showed || stateShowFreeUpgrade) {
          return undefined
        }
        return () => {
          setLocalStorage(FREE_SHOW_MORE_UPGRADE_SHOWED, String(true))
          actions?.setFreeUpgradeShow(true)
        }
      }
      return () => {
        actions?.setAdvanceSelectField(field)
        actions?.setIsShowAdvanceDialog(true)
      }
    })

    return {
      isLoadFullTreeData,
      filterData,
      treeRef,
      treeData,
      handleNodeClick,
      handleLoadNodeData,
      isCN,
      checkedIds,
      handleCheck,
      handelClickMore,
    }
  },
  render() {
    return (
      <div>
        {this.treeData.length === 0 ? (
          <div class="text-xs text-text-t3">{this.$tc('FilterItemLocationTree.noData')}</div>
        ) : (
          <LimitContainer
            limit={this.config.limitShowCount}
            data={this.treeData}
            clickMore={this.handelClickMore}
            scopedSlots={{
              default: (data: ITreeDataItem[]) => {
                return (
                  <GTree
                    ref="treeRef"
                    class={$style.tree}
                    treeData={data}
                    lazy={!this.isLoadFullTreeData}
                    resolveFunc={this.isLoadFullTreeData ? undefined : this.handleLoadNodeData}
                    checkedIds={this.checkedIds}
                    expandedIds={this.config.checkStrictly ? this.checkedIds : []}
                    checkStrictly={this.config.checkStrictly}
                    scopedSlots={{
                      default: (scope: { node: IElTreeNode<IAggregationValue>; data: IAggregationValue }) => {
                        const { data } = scope
                        return (
                          <div class="text-xs flex items-center justify-between w-full hover:bg-gray-30 px-1 py-0.5 rounded">
                            <span>{this.isCN ? data.display_name_cn || data.display_name_en : data.display_name_en}</span>
                            <span class={[$style.count, 'icon-font']} domPropsInnerHTML={encryptNum(toThousands(data.count))}></span>
                          </div>
                        )
                      },
                    }}
                    onCheck={this.handleCheck}
                    onClickContent={this.handleNodeClick}
                  ></GTree>
                )
              },
            }}
          ></LimitContainer>
        )}
      </div>
    )
  },
})
