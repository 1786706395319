import { I18nLang, IAggregationData, IAggregationValue, IQuery, IQueryItemField } from '@patsnap/synapse_common_interface'
import { AggStackBarChartEntity, AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import '@pharmsnap/shared/assets/icon-svg/excel.svg'
import { useAuthStore, useLocale, usePhaseStackBarChartNavList, userErrorMessage } from '@pharmsnap/shared/src/composition'
import { IQueryService } from '@pharmsnap/shared/src/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { ICommonTrack, IHistorySearchParams, ILoadingService, ISearchResult } from '@pharmsnap/shared/src/types'
import {
  IResearchDirectionChartAnalysisEntityType,
  IResearchDirectionChartEntityItem,
  IResearchDirectionChartParams,
} from '@pharmsnap/shared/src/types/translational-medicine'
import {
  convert2EntityQueryItem,
  downloadBlob,
  getTimeRelatedQueryField,
  showSingleToast,
  transAggregationValue2QueryItem,
} from '@pharmsnap/shared/src/utils'
import { getSecondAggItemsMaxLength } from '@pharmsnap/shared/src/utils/business/trans-medicine/trans-medicine-chart'
import {
  exportTransMedicineResearchDirectionChart,
  exportTransMedicineResearchTrendChart,
} from '@pharmsnap/shared/src/utils/business/trans-medicine/trans-medicine-export'
import { PropType, computed, defineComponent, getCurrentInstance, reactive, ref, unref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import { isEqual, noop } from 'lodash'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import { BAnalysisChartCard, BAnalysisChartFullScreenCard, BAnalysisStackBarEchart, BAnalysisTwoDimStackTable } from '../../BAnalysisChartNext'
import { useAnalysisTransMedicineTwoDimChartEntity } from '../../BAnalysisChartNext/compositions'
import { useAnalysisTwoDimChartNavList } from '../../BAnalysisChartNext/compositions/useAnalysisTwoDimChartNavList'
import $classes from '../BAnalysisView.module.scss'
import {
  IUseTransMedicineAggChartType,
  RESEARCH_DIRECTION_CHART_LIMIT,
  RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT,
  stackBarChartTypes,
  useTransMedicineAggChart,
} from '../compositions/useTransMedicineAggChart'
import { BAnalysisResearchDirectionChart } from './components/BAnalysisResearchDirectionChart'
import { BAnalysisResearchTrendChart } from './components/BAnalysisResearchTrendChart'
import { BAnalysisThreeDimTable } from './components/BAnalysisThreeDimTable'
import cn from './locales/cn.json'
import en from './locales/en.json'

const researchDirectionEntityNameMap: Record<
  IResearchDirectionChartAnalysisEntityType,
  { oneDimName: Record<I18nLang, string>; twoDimName: Record<I18nLang, string> }
> = {
  TARGET_INDICATION: {
    oneDimName: {
      en: 'indications',
      cn: '适应症',
    },
    twoDimName: { en: 'targets', cn: '靶点' },
  },
  TARGET_DRUG_TYPE: {
    oneDimName: { en: 'drug types', cn: '药物类型' },
    twoDimName: { en: 'targets', cn: '靶点' },
  },
  DRUG_TYPE_INDICATION: {
    oneDimName: {
      en: 'indications',
      cn: '适应症',
    },
    twoDimName: { en: 'drug types', cn: '药物类型' },
  },
}

export const BTranslationalMedicineAnalysisView = defineComponent({
  name: 'BTranslationalMedicineAnalysisView',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { uncollapse_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props) {
    const showFullScreen = ref(false)
    const ins = getCurrentInstance()
    const activeFullScreen = ref<IUseTransMedicineAggChartType | ''>('')

    const { locale, localeUpcase } = useLocale()

    const { showErrorMessageByToast } = userErrorMessage()

    const {
      state: { userInfo },
      getters: { isFreeUser, isTrialUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const showWatermark = computed(() => isFreeUser.value || isTrialUser.value)

    const query = computed<IQuery>(() => {
      return {
        type: 'group',
        must: props.queryService.state.query.must,
        filter: props.queryService.state.query.filter,
      }
    })

    const chartTypes: IUseTransMedicineAggChartType[] = [
      'RESEARCH_TREND_MULTI_PIE_DOUGHNUT',
      'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT',
      'ACTIVE_ORGANIZATION_STACK_BAR',
    ]

    const chartAggRecord = chartTypes.reduce<Record<IUseTransMedicineAggChartType, ReturnType<typeof useTransMedicineAggChart>>>(
      (pre, next) => ({
        ...pre,
        [next]: useTransMedicineAggChart({ type: next, query }),
      }),
      {} as Record<IUseTransMedicineAggChartType, ReturnType<typeof useTransMedicineAggChart>>
    )

    const chartInitedRecord = reactive(
      chartTypes.reduce((pre, next) => ({ ...pre, [next]: false }), {} as Record<IUseTransMedicineAggChartType, boolean>)
    )

    const stackBarChartEntityRecord = stackBarChartTypes.reduce<
      Record<IUseTransMedicineAggChartType, ReturnType<typeof useAnalysisTransMedicineTwoDimChartEntity>>
    >(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTransMedicineTwoDimChartEntity({
          chartType: 'bar',
          data: chartAggRecord[next].aggData,
          xAxisName: chartAggRecord[next].countFieldName,
          yAxisName: chartAggRecord[next].categoryFieldName,
          layout: 'horizontal',
        }),
      }),
      {} as Record<IUseTransMedicineAggChartType, ReturnType<typeof useAnalysisTransMedicineTwoDimChartEntity>>
    )

    const { handleClick: handleClickStackBarChart } = useAnalysisTwoDimChartNavList({
      chartType: 'bar',
      navList: 'translational_medicine',
      query,
      appendTo: ['filter', 'filter'],
      interceptors: [
        (data: AggTwoDimDataItem) => {
          const orgFields = ['RESEARCH_SPONSOR_ID_ROOT', 'RESEARCH_SPONSOR_ID']

          const filterFields = props.queryService.state.query.filter
            .filter((i) => i.type === 'field')
            .reduce((pre, next) => (next.type === 'field' ? [...pre, ...next.fields] : pre), [] as string[])

          const hasOrgFields = orgFields.some((i) => filterFields.includes(i))

          const isInValidOrg =
            hasOrgFields &&
            !!data.y?.aggregation_field &&
            !filterFields.includes(data.y.aggregation_field) &&
            orgFields.includes(data.y.aggregation_field)

          if (isInValidOrg) {
            return {
              message: ins?.proxy.$tc('common.aggOrgTips') || '',
              type: 'error',
            }
          }

          return true
        },
      ],
    })

    function handleClickMultiPieChart(
      params: {
        oneDimItem: IAggregationValue
        twoDimItem: IAggregationValue
        threeDimItem?: IAggregationValue
      },
      appendTo: ['filter' | 'must', 'filter' | 'must'] = ['filter', 'filter']
    ) {
      const { oneDimItem, twoDimItem, threeDimItem } = params
      const { onClickStackBar: onClickMultiPieCHart } = usePhaseStackBarChartNavList({
        navList: 'translational_medicine',
        query,
        appendTo: appendTo,
        customQueryItemPicker: (params: { oneDimItem: IAggregationValue; twoDimItem: IAggregationValue }) => {
          const { oneDimItem, twoDimItem } = params

          let xAxisQuery: IQueryItemField | undefined
          let yAxisQuery: IQueryItemField | undefined

          if (oneDimItem.aggregation_field === 'PUB_DT_YEAR') {
            xAxisQuery = getTimeRelatedQueryField(Number(oneDimItem.key), 'PUB_DT', false, false, false)
          } else {
            xAxisQuery = transAggregationValue2QueryItem(oneDimItem)
          }

          if (twoDimItem.aggregation_field === 'THERAPEUTIC_AREA_ID') {
            yAxisQuery = {
              type: 'field',
              fields: ['DISEASE_ID'],
              value: convert2EntityQueryItem(
                [
                  {
                    id: twoDimItem.key,
                    name_en: twoDimItem.display_name_en,
                    name_cn: twoDimItem.display_name_cn,
                    search_strategy: 'ID_ROLLUP',
                  },
                ],
                'disease'
              ),
            }
          } else {
            yAxisQuery = transAggregationValue2QueryItem(twoDimItem)
          }

          return {
            xAxisQuery: xAxisQuery as IQueryItemField,
            yAxisQuery: yAxisQuery as IQueryItemField,
          }
        },
        adjustSearchParamsBeforeOpenPage: (params: IHistorySearchParams) => {
          if (threeDimItem) {
            if (!params.query.filter) {
              params.query.filter = []
            }
            params.query.filter.push(transAggregationValue2QueryItem(threeDimItem))
          }

          return params
        },
      })

      onClickMultiPieCHart({ oneDimItem, twoDimItem })
    }

    function getChartDescConfigByType(type: IUseTransMedicineAggChartType, analysisEntityName?: string) {
      switch (type) {
        case 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT':
          return {
            title: ins?.proxy?.$tc('researchTrend.title'),
            desc: ins?.proxy?.$t('researchTrend.desc', { entityName: analysisEntityName }) as string,
          }
        case 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT':
          return {
            title: ins?.proxy?.$tc('researchDirection.title'),
            desc: ins?.proxy?.$tc('researchDirection.desc'),
          }
        case 'ACTIVE_ORGANIZATION_STACK_BAR':
          return {
            title: ins?.proxy?.$tc('activeOrganizations.title'),
            desc: ins?.proxy?.$tc('activeOrganizations.desc'),
          }
        default:
          return {
            title: '',
            desc: '',
          }
      }
    }

    function handleOpenFullScreen(type: IUseTransMedicineAggChartType) {
      activeFullScreen.value = type
      showFullScreen.value = true
    }

    function handleCloseFullScreen() {
      showFullScreen.value = false
      activeFullScreen.value = ''
    }

    function getHandleClickFunc(
      type: IUseTransMedicineAggChartType,
      otherInfo?: {
        researchDirectionExpandItem?: IResearchDirectionChartEntityItem
        researchDirectionSelectedType?: IResearchDirectionChartAnalysisEntityType
      }
    ) {
      if (stackBarChartTypes.includes(type)) return handleClickStackBarChart
      if (type === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') return handleClickMultiPieChart
      if (type === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
        if (otherInfo?.researchDirectionExpandItem || otherInfo?.researchDirectionSelectedType === 'TARGET_DRUG_TYPE') return handleClickMultiPieChart
        // 治疗领域，放query中，过滤项中无治疗领域
        return (params: { oneDimItem: IAggregationValue; twoDimItem: IAggregationValue; threeDimItem?: IAggregationValue }) =>
          handleClickMultiPieChart(params, ['filter', 'must'])
      }
      return noop
    }

    function stackFieldNameSorter(a: AggTwoDimDataItem, b: AggTwoDimDataItem, type: IUseTransMedicineAggChartType) {
      if (type === 'ACTIVE_ORGANIZATION_STACK_BAR') {
        return stackBarChartEntityRecord[type].sortStackChartSubject(a, b)
      }
      return 0
    }

    function handleFetchChartData(type: IUseTransMedicineAggChartType) {
      chartAggRecord[type].fetchAggData()
      chartInitedRecord[type] = true
    }

    async function initData() {
      props.loadingService.action.setLoading(true)
      const rt = await props.getTotalFn()
      props.loadingService.action.setTotal(rt.total)
      props.loadingService.action.setUnCollapseTotal(rt.uncollapse_total)
      props.loadingService.action.setLoading(false)
    }

    function initAggData() {
      chartTypes.forEach((type) => {
        if (chartInitedRecord[type]) {
          chartAggRecord[type].expandDisease.value = undefined
          handleFetchChartData(type)
        }
      })
    }

    function handleTrack(event_code: ICommonTrack['event_code'], trigger_point?: string, payload?: Record<string, any>, common1?: string) {
      sharedCtx.service.tracking.trackGeneral({
        event_code,
        trigger_point,
        payload,
        common1,
      })
    }

    function handleTrackDownload(type: IUseTransMedicineAggChartType, common1?: string) {
      handleTrack('DOWNLOAD_CHART', type, undefined, common1)
    }

    function handleTrackExpand(type: IUseTransMedicineAggChartType, status: 'expand' | 'collapse') {
      handleTrack('CLICK_EXPAND', type, { action: status })
    }

    async function handleDownloadResearchTrendOverview(aggData: IAggregationData, title = 'Research Trend Overview') {
      const toast = showSingleToast({
        message: ins?.proxy.$tc('common.importTips') || '',
        type: 'info',
        duration: 0,
      })
      await exportTransMedicineResearchTrendChart(aggData, title, unref(locale), showWatermark.value, { userId: userInfo.value?.user_id || '' })
      handleTrackDownload('RESEARCH_TREND_MULTI_PIE_DOUGHNUT')
      toast.close()
    }

    async function handleDownloadResearchDirectionOverview(
      aggData: IAggregationData,
      otherInfo: {
        expandData?: IResearchDirectionChartEntityItem
        researchDirectionXDirectionList: IResearchDirectionChartEntityItem[]
        researchDirectionYDirectionList: IResearchDirectionChartEntityItem[]
      },
      title = 'Research Direction'
    ) {
      const toast = showSingleToast({
        message: ins?.proxy.$tc('common.importTips') || '',
        type: 'info',
        duration: 0,
      })

      const chartData = { ...aggData }

      // X轴方向超过限制时，只导出前 RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_LIMIT 条
      if (otherInfo.researchDirectionXDirectionList.length > RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT) {
        otherInfo.researchDirectionXDirectionList = otherInfo.researchDirectionXDirectionList.slice(
          0,
          RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT
        )
      }
      await exportTransMedicineResearchDirectionChart(chartData, otherInfo, title, unref(locale), showWatermark.value, {
        userId: userInfo.value?.user_id || '',
      })
      handleTrackDownload('RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT', 'CHART')
      toast.close()
    }

    async function handleDownloadResearchDirectionAllData(title = '', researchDirectionAggParams: IResearchDirectionChartParams) {
      if (isFreeUser.value) {
        return changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD' })
      }

      const toast = showSingleToast({
        message: ins?.proxy.$tc('common.importTips') || '',
        type: 'info',
        duration: 0,
      })

      const res = await sharedCtx.service.translationalMedicine.downloadResearchDirectionAllData({
        ...researchDirectionAggParams,
        language: localeUpcase.value,
      })

      toast.close()

      if (res.success) {
        downloadBlob(res.data, `${title || dayjs().format('YYYYMMDDHHmmss')}.XLS`)
      } else {
        // 处理没有正常下载的数据
        if (res.data.type === 'application/json') {
          const reader = new FileReader()
          reader.readAsText(res.data, 'utf-8')
          reader.onload = () => {
            const { numeric_error_code } = JSON.parse(reader.result as string)
            showErrorMessageByToast(numeric_error_code)
          }
        }
      }
      handleTrackDownload('RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT', 'TABLE')
    }

    function getResearchDirectionDownloadChartLabel(oneDimDataLength: number, selectedValue: IResearchDirectionChartAnalysisEntityType) {
      let downloadLabel = ins?.proxy.$tc('researchDirection.downloadChart') || ''
      if (oneDimDataLength > RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT) {
        const entityName = researchDirectionEntityNameMap[selectedValue]
        downloadLabel =
          ins?.proxy.$tc('researchDirection.downloadOverLimit', undefined, {
            oneDimLimit: RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT,
            oneDimName: entityName.oneDimName[locale.value],
            twoDimLimit: RESEARCH_DIRECTION_CHART_LIMIT,
            twoDimName: entityName.twoDimName[locale.value],
          }) || ''
      }
      return downloadLabel
    }

    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        initData()
      },
      {
        immediate: true,
      }
    )

    watch(query, () => {
      initAggData()
    })

    return {
      locale,
      chartAggRecord,
      stackBarChartEntityRecord,
      showFullScreen,
      activeFullScreen,
      getChartDescConfigByType,
      stackFieldNameSorter,
      handleFetchChartData,
      handleOpenFullScreen,
      handleCloseFullScreen,
      getHandleClickFunc,
      handleTrackDownload,
      handleTrackExpand,
      handleDownloadResearchTrendOverview,
      handleDownloadResearchDirectionOverview,
      handleDownloadResearchDirectionAllData,
      getResearchDirectionDownloadChartLabel,
    }
  },
  methods: {
    renderResearchTrendOverview(type: IUseTransMedicineAggChartType = 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
      const { model, controls, controlLayout, loading, isEmpty, settingPanelTitle, aggData, analysisEntityName, categoryFieldName, countFieldName } =
        this.chartAggRecord[type]
      const tableRowsNum = getSecondAggItemsMaxLength(aggData.value)
      // 为了水印可以垂直居中显示，动态计算图高度
      const height = Math.min(500, tableRowsNum * 88 + 40 + 48)
      // -60: 容器高度去掉翻页器高度
      const tableHeight = Math.max(height - 60, 250)
      const { title, desc } = this.getChartDescConfigByType(type, analysisEntityName.value)
      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class="mb-4"
            title={title}
            desc={desc}
            specifyContentHeight
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'custom' }}
            settingTitle={settingPanelTitle.value}
            onOpen={() => this.handleOpenFullScreen(type)}
            onDownload={() => this.handleDownloadResearchTrendOverview(aggData.value, title)}
          >
            <BAnalysisResearchTrendChart
              aggData={aggData.value}
              isEmpty={isEmpty.value}
              analysisEntity={model.entityType?.selectedValue || 'target'}
              class={[{ hidden: model.display === 'table' }]}
              onClick={this.getHandleClickFunc(type)}
            ></BAnalysisResearchTrendChart>
            <BAnalysisThreeDimTable
              class={[{ hidden: model.display === 'multi-pie-doughnut' }]}
              isEmpty={isEmpty.value}
              maxHeight={`${tableHeight}px`}
              categoryFieldName={categoryFieldName.value}
              twoDimName={analysisEntityName.value}
              countName={countFieldName.value}
              aggData={aggData.value}
            ></BAnalysisThreeDimTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderResearchTrendOverviewFullScreen(type: IUseTransMedicineAggChartType = 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
      if (type !== this.activeFullScreen) return null

      const {
        model,
        controls,
        controlLayoutFullscreen,
        loading,
        isEmpty,
        settingDataPanelTitle,
        settingChartPanelTitle,
        aggData,
        categoryFieldName,
        analysisEntityName,
        countFieldName,
      } = this.chartAggRecord[type]
      const tableRowsNum = getSecondAggItemsMaxLength(aggData.value)
      const height = Math.min(600, tableRowsNum * 88 + 40)
      const { title } = this.getChartDescConfigByType(type, analysisEntityName.value)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          isDrawer
          title={title}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{ visible: true, type: 'custom' }}
          onClose={this.handleCloseFullScreen}
          onDownload={() => this.handleDownloadResearchTrendOverview(aggData.value, title)}
        >
          <div>
            <BAnalysisResearchTrendChart
              height={`${height}px`}
              aggData={aggData.value}
              isEmpty={isEmpty.value}
              analysisEntity={model.entityType?.selectedValue || 'target'}
              onClick={this.getHandleClickFunc(type)}
            ></BAnalysisResearchTrendChart>
            <div class="mt-8">
              <BAnalysisThreeDimTable
                isEmpty={isEmpty.value}
                categoryFieldName={categoryFieldName.value}
                twoDimName={analysisEntityName.value}
                countName={countFieldName.value}
                aggData={aggData.value}
                maxHeight="440px"
              ></BAnalysisThreeDimTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderResearchDirectionDownloadAll(
      title: string,
      analysisEntity: IResearchDirectionChartAnalysisEntityType,
      params: IResearchDirectionChartParams
    ) {
      const entityName = researchDirectionEntityNameMap[analysisEntity].twoDimName[this.locale]
      return (
        <div class="flex items-center mt-3 text-xs text-gray-450 leading-4">
          {this.$t('researchDirection.limitTips', { limit: RESEARCH_DIRECTION_CHART_LIMIT, entityName })}
          <span class="text-blue-default cursor-pointer" onClick={() => this.handleDownloadResearchDirectionAllData(title, params)}>
            {this.$t('researchDirection.downloadAll')}
          </span>
        </div>
      )
    },
    renderResearchDirection(type: IUseTransMedicineAggChartType = 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      const {
        model,
        controls,
        controlLayout,
        loading,
        isEmpty,
        settingPanelTitle,
        aggData,
        researchDirectionXDirectionList,
        researchDirectionYDirectionList,
        expandDisease,
        handleToggleExpand,
        categoryFieldName,
        analysisEntityName,
        countFieldName,
        researchDirectionAggParams,
      } = this.chartAggRecord[type]
      const tableRowsNum = researchDirectionYDirectionList.value.length
      // 图表高度包含表头、图例高度，为了水印可以垂直居中显示，动态计算图高度
      const height = Math.min(500, tableRowsNum * 68 + (expandDisease.value ? 40 * 2 : 40) + 48)
      // -60: 容器高度去掉翻页器高度
      const tableHeight = Math.max(height - 60, 250)
      const { title, desc } = this.getChartDescConfigByType(type)
      const selectedValue = model.entityCombineType?.selectedValue || 'TARGET_INDICATION'

      let downloadLabel = ''

      if (model.display === 'table') {
        downloadLabel = this.$tc('researchDirection.downloadTable')
      }
      if (model.display === 'multi-pie-doughnut') {
        downloadLabel = this.getResearchDirectionDownloadChartLabel(researchDirectionXDirectionList.value.length, selectedValue)
      }

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class="mb-4"
            title={title}
            desc={desc}
            specifyContentHeight
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{
              visible: true,
              type: 'custom',
              label: downloadLabel,
            }}
            settingTitle={settingPanelTitle.value}
            onOpen={() => this.handleOpenFullScreen(type)}
            onDownload={() => {
              if (model.display === 'table') {
                return this.handleDownloadResearchDirectionAllData(title, researchDirectionAggParams.value)
              }
              this.handleDownloadResearchDirectionOverview(
                aggData.value,
                {
                  expandData: expandDisease.value,
                  researchDirectionXDirectionList: researchDirectionXDirectionList.value,
                  researchDirectionYDirectionList: researchDirectionYDirectionList.value,
                },
                title
              )
            }}
          >
            <div class={[{ hidden: model.display === 'table' }, 'flex flex-col h-full']}>
              <div class="flex-1 overflow-hidden">
                <BAnalysisResearchDirectionChart
                  aggData={aggData.value}
                  isEmpty={isEmpty.value}
                  xDirectionList={researchDirectionXDirectionList.value}
                  yDirectionList={researchDirectionYDirectionList.value}
                  analysisEntity={selectedValue}
                  expandDisease={expandDisease.value}
                  onToggleExpand={(expandData: IResearchDirectionChartEntityItem | undefined, status: 'expand' | 'collapse') => {
                    handleToggleExpand(expandData)
                    this.handleTrackExpand(type, status)
                  }}
                  onClick={this.getHandleClickFunc(type, {
                    researchDirectionExpandItem: expandDisease.value,
                    researchDirectionSelectedType: selectedValue,
                  })}
                ></BAnalysisResearchDirectionChart>
              </div>
              {researchDirectionYDirectionList.value.length >= RESEARCH_DIRECTION_CHART_LIMIT &&
                this.renderResearchDirectionDownloadAll(title || '', selectedValue, researchDirectionAggParams.value)}
            </div>
            <BAnalysisThreeDimTable
              class={[{ hidden: model.display === 'multi-pie-doughnut' }]}
              isEmpty={isEmpty.value}
              categoryFieldName={categoryFieldName.value}
              twoDimName={analysisEntityName.value}
              countName={countFieldName.value}
              aggData={aggData.value}
              maxHeight={`${tableHeight}px`}
            ></BAnalysisThreeDimTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderResearchDirectionFullScreen(type: IUseTransMedicineAggChartType = 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      if (type !== this.activeFullScreen) return null

      const {
        model,
        controls,
        controlLayoutFullscreen,
        loading,
        isEmpty,
        settingDataPanelTitle,
        settingChartPanelTitle,
        aggData,
        researchDirectionXDirectionList,
        researchDirectionYDirectionList,
        expandDisease,
        handleToggleExpand,
        categoryFieldName,
        analysisEntityName,
        countFieldName,
        researchDirectionAggParams,
      } = this.chartAggRecord[type]
      const tableRowsNum = researchDirectionYDirectionList.value.length
      const height = Math.min(600, tableRowsNum * 68 + 48)
      const { title } = this.getChartDescConfigByType(type)
      const selectedValue = model.entityCombineType?.selectedValue || 'TARGET_INDICATION'

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          isDrawer
          title={title}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{
            visible: true,
            type: 'custom',
            label: this.getResearchDirectionDownloadChartLabel(researchDirectionXDirectionList.value.length, selectedValue),
          }}
          onClose={this.handleCloseFullScreen}
          onDownload={() =>
            this.handleDownloadResearchDirectionOverview(
              aggData.value,
              {
                expandData: expandDisease.value,
                researchDirectionXDirectionList: researchDirectionXDirectionList.value,
                researchDirectionYDirectionList: researchDirectionYDirectionList.value,
              },
              title
            )
          }
        >
          <template slot="headerActionSlot">
            <GTooltip placement="top" theme="dark" content={this.$t('researchDirection.downloadTable') as string}>
              <span
                class="inline-flex items-center p-1 rounded hover:bg-gray-30 cursor-pointer"
                onClick={() => this.handleDownloadResearchDirectionAllData(title, researchDirectionAggParams.value)}
              >
                <GIcon svgName="Excel" size={24}></GIcon>
              </span>
            </GTooltip>
          </template>
          <div>
            <BAnalysisResearchDirectionChart
              height={`${height}px`}
              aggData={aggData.value}
              isEmpty={isEmpty.value}
              xDirectionList={researchDirectionXDirectionList.value}
              yDirectionList={researchDirectionYDirectionList.value}
              analysisEntity={selectedValue}
              expandDisease={expandDisease.value}
              onToggleExpand={(expandData: IResearchDirectionChartEntityItem | undefined, status: 'expand' | 'collapse') => {
                handleToggleExpand(expandData)
                this.handleTrackExpand(type, status)
              }}
              onClick={this.getHandleClickFunc(type, {
                researchDirectionExpandItem: expandDisease.value,
                researchDirectionSelectedType: selectedValue,
              })}
            ></BAnalysisResearchDirectionChart>
            {researchDirectionYDirectionList.value.length >= RESEARCH_DIRECTION_CHART_LIMIT &&
              this.renderResearchDirectionDownloadAll(title || '', selectedValue, researchDirectionAggParams.value)}
            <div class="mt-8">
              <BAnalysisThreeDimTable
                isEmpty={isEmpty.value}
                categoryFieldName={categoryFieldName.value}
                twoDimName={analysisEntityName.value}
                countName={countFieldName.value}
                aggData={aggData.value}
                maxHeight="440px"
              ></BAnalysisThreeDimTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderStackBarByType(type: IUseTransMedicineAggChartType, cls = '') {
      const { model, controls, controlLayout, loading, isEmpty, settingPanelTitle } = this.chartAggRecord[type]
      const { chartEntity, dataList, countFieldName, stackFieldName, entityFieldName, uniqEntityDataList } = this.stackBarChartEntityRecord[type]
      const height = Math.max(200, uniqEntityDataList.value.length * 64)
      const { title, desc } = this.getChartDescConfigByType(type)

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={title}
            desc={desc}
            specifyContentHeight
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            settingTitle={settingPanelTitle.value}
            onOpen={() => this.handleOpenFullScreen(type)}
            onAfterDownload={() => this.handleTrackDownload(type)}
          >
            <BAnalysisStackBarEchart
              onClickItem={this.getHandleClickFunc(type)}
              class={['h-full', { hidden: model.display === 'table' }]}
              entity={chartEntity.value as AggStackBarChartEntity}
            ></BAnalysisStackBarEchart>
            <BAnalysisTwoDimStackTable
              class={[{ hidden: model.display === 'horizontal-bar' }]}
              height={'100%'}
              displayMode="table"
              entityFieldName={entityFieldName.value}
              countFieldName={countFieldName.value}
              stackFieldName={stackFieldName.value}
              list={dataList.value}
              stackFieldNameSorter={(a: AggTwoDimDataItem, b: AggTwoDimDataItem) => this.stackFieldNameSorter(a, b, type)}
            ></BAnalysisTwoDimStackTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderStackBarFullScreen(type: IUseTransMedicineAggChartType) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayoutFullscreen, loading, isEmpty, settingDataPanelTitle, settingChartPanelTitle } = this.chartAggRecord[type]
      const { chartEntity, dataList, countFieldName, stackFieldName, entityFieldName, uniqEntityDataList } = this.stackBarChartEntityRecord[type]
      const height = Math.max(200, uniqEntityDataList.value.length * 64)
      const { title, desc } = this.getChartDescConfigByType(type)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          specifyContentHeight
          isDrawer
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          displayType="horizontal-bar"
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
          onAfterDownload={() => this.handleTrackDownload(type)}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisStackBarEchart
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggStackBarChartEntity}
                style={{ height: `${height}px` }}
              ></BAnalysisStackBarEchart>
            </div>
            <div class="mt-8">
              <BAnalysisTwoDimStackTable
                displayMode="record"
                recordModeEntityCellMinWidth={146}
                entityFieldName={entityFieldName.value}
                countFieldName={countFieldName.value}
                stackFieldName={stackFieldName.value}
                list={dataList.value}
                stackFieldNameSorter={(a: AggTwoDimDataItem, b: AggTwoDimDataItem) => this.stackFieldNameSorter(a, b, type)}
              ></BAnalysisTwoDimStackTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
  },
  render() {
    return (
      <div class="p-4 pb-16 h-full overflow-y-auto">
        <div class={[$classes.blockTitle, 'mb-4']}>{this.$t('overview')}</div>
        {this.renderResearchTrendOverview()}
        {this.renderResearchDirection()}
        {this.renderStackBarByType('ACTIVE_ORGANIZATION_STACK_BAR')}
        <div class={[$classes.fullscreen, { hidden: !this.showFullScreen }]}>
          {this.renderStackBarFullScreen('ACTIVE_ORGANIZATION_STACK_BAR')}
          {this.renderResearchDirectionFullScreen()}
          {this.renderResearchTrendOverviewFullScreen()}
        </div>
      </div>
    )
  },
})
