import Vue, { Component, VNodeData } from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'

/**
 * Creates an independent Vue instance with the specified component, component vnode data, i18n, and router.
 * @param params - The parameters for creating the Vue instance.
 * @param params.component - The Vue component to render.
 * @param params.componentVNodeData - The vnode data for the component.
 * @param params.i18n - The i18n instance.
 * @param params.router - The router instance.
 * @returns The created Vue instance.
 */
export function createIndependentVueInstance(params: { component: Component; componentVNodeData: VNodeData; i18n?: VueI18n; router?: VueRouter }) {
  const dom = document.createElement('div')
  document.body.appendChild(dom)
  const instance = new Vue({
    i18n: params.i18n,
    router: params.router,
    render: (h) => h(params.component, params.componentVNodeData),
  })
  instance.$mount(dom)
  return instance
}
