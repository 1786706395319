import { EMPTY_PLACEHOLDER } from '../constants'
import { COMPANY_EMPLOYEE_NUMBER_MAP } from '../constants/rangeConfig'
import { binarySearchNumber } from '../utils'

export function getOrgRenderField() {
  return {}
}
export function renderBasicEmployeeNumber(count?: number) {
  return count ? binarySearchNumber(count, COMPANY_EMPLOYEE_NUMBER_MAP) : EMPTY_PLACEHOLDER
}
