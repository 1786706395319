import { computed, defineComponent, provide, ref, toRefs } from '@vue/composition-api'
import { useElementBounding, useScroll, watchPausable } from '@vueuse/core'
import { anchorScrollNextInjectKey } from './config'

export const GAnchorScrollNext = defineComponent({
  name: 'GAnchorScrollNext',
  props: {
    offsetTop: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { offsetTop } = toRefs(props)

    const activeAnchorId = ref('')

    const anchorList: Array<{
      el: HTMLElement
      id: string
    }> = []

    const scrollWrapper = ref<HTMLDivElement | null>(null)

    const middleLineStyle = computed(() => {
      const offsetHeight = scrollWrapper.value?.offsetHeight ?? 0
      const middleHeight = offsetHeight / 2

      return {
        position: 'absolute',
        top: `${middleHeight + y.value}px`,
        left: '0',
        right: '0',
        height: '1px',
        'background-color': 'red',
      }
    })

    // 是否监听onscroll事件
    const onScrollEnable = ref(true)

    const { y } = useScroll(scrollWrapper, {
      onScroll: () => {
        ctx.emit('scroll', y.value)
        if (!onScrollEnable.value) {
          return
        }
        updateCurrentAnchorWhenScroll()
      },
      onStop: () => {
        onScrollEnable.value = true
      },
    })

    provide(anchorScrollNextInjectKey, {
      registerAnchor,
      unregisterAnchor,
      onLinkWrapperSizeChange: scrollToActiveAnchor,
    })

    // 外部控制滚动
    function scrollTo(id: string) {
      const target = anchorList.find((item) => item.id === id)
      if (target) {
        onScrollEnable.value = false
        target.el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }

    // 滚动到当前激活的锚点
    function scrollToActiveAnchor() {
      scrollTo(activeAnchorId.value)
    }

    function updateCurrentAnchorWhenScroll() {
      const scrollTop = y.value
      const offsetHeight = scrollWrapper.value?.offsetHeight ?? 0
      const anchor = anchorList.find((item) => {
        const top = item.el.offsetTop
        const bottom = top + item.el.offsetHeight
        return top - offsetTop.value <= scrollTop + offsetHeight / 2 && bottom - offsetTop.value >= scrollTop + offsetHeight / 2
      })
      if (anchor) {
        activeAnchorId.value = anchor.id
        ctx.emit('change', activeAnchorId.value)
      }
    }

    function registerAnchor(options: {
      el: HTMLElement
      id: string
      bounding: ReturnType<typeof useElementBounding>
      watcher: ReturnType<typeof watchPausable>
    }) {
      if (anchorList.length === 0) {
        activeAnchorId.value = options.id
        ctx.emit('change', activeAnchorId.value)
      }

      anchorList.push(options)
    }

    function unregisterAnchor(id: string) {
      const index = anchorList.findIndex((item) => item.id === id)
      if (index !== -1) {
        anchorList.splice(index, 1)
      }
    }

    return {
      scrollWrapper,
      scrollTo,
      middleLineStyle,
    }
  },
  render() {
    return (
      <div ref="scrollWrapper" class={['overflow-y-auto']}>
        {this.$slots.default}
      </div>
    )
  },
})

export type GAnchorScrollNextType = InstanceType<typeof GAnchorScrollNext>
