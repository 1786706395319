import { CopilotTaskStatus, ModuleStatus } from '@pharmsnap/shared/src/types'
import { PropType, defineComponent } from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import $class from './CopilotProgressList.module.scss'

export const CopilotProgressList = defineComponent({
  name: 'CopilotProgressList',
  props: {
    moduleStatus: {
      type: Array as PropType<ModuleStatus[]>,
    },
  },
  setup(props) {
    return {}
  },
  render() {
    return (
      <div class="space-y-2">
        {this.moduleStatus?.map((item, idx) => (
          <div class="relative">
            <div
              class={['flex items-center p-2', item.status === CopilotTaskStatus.RUNNING ? [$class.progressContainer, $class[`element${idx}`]] : '']}
            >
              <div class="w-1 h-1 bg-gray-600  rounded-full" style="margin: 6px"></div>
              <div class="ml-2 text-sm">{item.module_name}</div>
              {item.status === CopilotTaskStatus.SUCCESS && <GIcon svgName="SolidTick" class="text-blue-default ml-auto"></GIcon>}
            </div>
          </div>
        ))}
      </div>
    )
  },
})
