import { clinicalIndexFieldDescLangMap } from '@patsnap/synapse_common_config'
import '@pharmsnap/shared/assets/icon-svg/clinical.svg'
import { BCardContainer, BEntityLoadingCard, GDescription, GLimitedContainer, GLink, GTranslate } from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElCheckbox, ElDivider } from '@pharmsnap/shared/element-ui'
import {
  getClinicalTranslateText,
  renderClinicalDrugInList,
  renderClinicalEncryptStr,
  renderClinicalIdentifierInCard,
  renderClinicalIndicationInList,
  renderClinicalSponsor,
  renderClinicalStudyPhaseInTable,
  renderClinicalStudyStatusInList,
  renderClinicalTargetsInList,
} from '@pharmsnap/shared/render'
import { extractClinicalTitle } from '@pharmsnap/shared/src/domain/clinicalDomain/clinical.field'
import { IClinicalDetail, ICTRClinicalDetail, ILang, ITranslateLang } from '@pharmsnap/shared/types'
import { formatTimestamp, isOpenCTTranslate } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import styles from './BCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BCardClinical = defineComponent({
  name: 'BCardClinical',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IClinicalDetail>,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props) {
    const { forceTranslation, mode } = toRefs(props)
    const { locale } = useLocale()
    const {
      getters: { customSettingTranslation },
    } = useAuthStore()
    const isExecuteTranslate = computed(() => {
      if (props.disabledTranslate) {
        return false
      }
      return isOpenCTTranslate(props.data, forceTranslation.value || customSettingTranslation.value)
    })

    const isIntelligentMode = computed(() => mode.value === 'intelligent')

    return {
      locale,
      isExecuteTranslate,
      isIntelligentMode,
    }
  },
  methods: {
    renderHeader() {
      const { url, register_number } = this.data as IClinicalDetail
      return (
        <div class={['flex items-center', !this.isIntelligentMode ? 'px-4 pt-3' : '']} slot="header">
          {this.showCheckbox ? this.renderCheckbox() : null}
          {/* {this.isIntelligentMode ? <GIcon svgName="Clinical" size={24} useSvgDefaultColor={true}></GIcon> : null} */}
          {/* {this.isIntelligentMode
            ? [
                <span class="flex-shrink-0 leading-6 text-[#5173F0] text-sm">{this.locale === 'cn' ? '临床试验' : 'Clinical Trial'}</span>,
                <span class="flex-shrink-0 leading-6 mx-1 text-text-t2 text-sm">/</span>,
              ]
            : null} */}
          <GLink href={url} name={register_number} newTab={true} class="text-sm leading-6" style="color: #1D8820" />
          <span class="flex-shrink-0 leading-6 mx-1 text-text-t2 text-sm">·</span>
          {renderClinicalStudyStatusInList(this.data)}
          {this.data.phase && this.data.phase.normalized_phase_view
            ? [<span class="leading-6 flex-shrink-0 mx-1"></span>, renderClinicalStudyPhaseInTable(this.data, this.$i18n)]
            : null}
          {this.data.if_iit
            ? [
                <span class="leading-6 flex-shrink-0 mx-1"></span>,
                <span class="flex-shrink-0 px-1 bg-gray-20 text-sm leading-5 mt-[2px] rounded">IIT</span>,
              ]
            : null}
        </div>
      )
    },
    renderBody() {
      const { clinical_trial_id, brief_summary, start_date_v2, data_source, study_first_posted_date } = this.data
      const { study_purpose } = this.data as ICTRClinicalDetail
      return (
        <div class="mt-2">
          <GTranslate
            execute={this.isExecuteTranslate}
            content={getClinicalTranslateText(this.data, 'official_title') || getClinicalTranslateText(this.data, 'brief_title')}
          >
            <GLink
              href={sharedCtx.router.generatorClinicalPath(clinical_trial_id)}
              name={extractClinicalTitle(undefined, this.data) || EMPTY_PLACEHOLDER}
              newTab={true}
              class={[
                styles.title,
                this.isIntelligentMode ? 'text-base leading-6' : 'text-lg leading-6',
                'whitespace-pre-wrap  hover:text-blue-default font-semibold',
              ]}
            ></GLink>
          </GTranslate>
          {data_source !== 'WHO' && !this.isIntelligentMode ? (
            <GTranslate execute={this.isExecuteTranslate}>
              {getClinicalTranslateText(this.data, this.data.data_source === 'CTGOV' ? 'brief_summary' : 'study_purpose') && (
                <GLimitedContainer
                  slot="content"
                  newTab={true}
                  href={sharedCtx.router.generatorClinicalPath(clinical_trial_id)}
                  moreText={this.$tc('common.showAll')}
                  moreColor="#f0f1f3"
                >
                  <div
                    class="text-sm leading-5  break-words"
                    domPropsInnerHTML={getClinicalTranslateText(this.data, this.data.data_source === 'CTGOV' ? 'brief_summary' : 'study_purpose')}
                  ></div>
                </GLimitedContainer>
              )}
              <GLimitedContainer
                class="mr-2"
                newTab={true}
                href={sharedCtx.router.generatorClinicalPath(clinical_trial_id)}
                moreText={this.$tc('common.showAll')}
              >
                <div
                  class="whitespace-pre-wrap text-sm leading-5 text-text-t2 break-words list-none"
                  domPropsInnerHTML={this.data.data_source === 'CTGOV' ? brief_summary : study_purpose}
                ></div>
              </GLimitedContainer>
            </GTranslate>
          ) : null}
          <GDescription
            width={120}
            class="mt-2"
            data={this.data}
            items={[
              {
                label: `${this.$tc('clinical_trial.field.startDate')}:`,
                field: 'startDate',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('clinical_trial.field.firstPostDate')}:`,
                field: 'firstPostDate',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('clinical_trial.field.sponsor')}:`,
                field: 'sponsor',
                contentCls: styles.contentItem,
              },
              ...(this.data.group_id_view && !this.isIntelligentMode
                ? [
                    {
                      label: `${this.$tc('clinical_trial.field.identifier')}:`,
                      field: 'identifier',
                      contentCls: styles.contentItem,
                      tip:
                        this.$i18n.locale === 'cn'
                          ? clinicalIndexFieldDescLangMap['VIRTUAL_IDENTIFIER']?.cn
                          : clinicalIndexFieldDescLangMap['VIRTUAL_IDENTIFIER']?.en,
                      tipCls: 'w-80',
                    },
                  ]
                : []),
            ]}
            scopedSlots={{
              startDate: () => renderClinicalEncryptStr(formatTimestamp(start_date_v2?.date, { locale: this.$i18n.locale.toUpperCase() as ILang })),
              firstPostDate: () =>
                renderClinicalEncryptStr(formatTimestamp(study_first_posted_date, { locale: this.$i18n.locale.toUpperCase() as ILang })),
              sponsor: () => renderClinicalSponsor(this.data, 3),
              identifier: () => renderClinicalIdentifierInCard(this.data, 3),
            }}
          ></GDescription>
          {this.showFooter && !this.isIntelligentMode ? <ElDivider /> : null}{' '}
        </div>
      )
    },
    renderFooter() {
      if (!this.showFooter || this.mode === 'intelligent') {
        return null
      }
      /** 自定义footer */
      if (this.$slots.customFooter) {
        return this.$slots.customFooter
      }
      return (
        <div class="-mb-2">
          <div class="text-text-t2 mb-3 text-sm">{this.$tc('BCard.MentionedEntities')}</div>
          <GDescription
            width={125}
            data={this.data}
            items={[
              {
                label: `${this.$tc('BCard.Indication')}:`,
                field: 'disease',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.target')}:`,
                field: 'targets',
                tip: this.$tc('target.tooltip'),
                tipCls: 'w-80',
                tipPlacement: 'right',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('BCard.InterventionDrug')}:`,
                field: 'drugs',
                contentCls: styles.contentItem,
              },
            ]}
            scopedSlots={{
              disease: () => renderClinicalIndicationInList(this.data, 3),
              targets: () => renderClinicalTargetsInList(this.data, 3),
              drugs: () => renderClinicalDrugInList(this.data, 3),
            }}
          ></GDescription>
        </div>
      )
    },
    renderCheckbox() {
      return (
        <div class="mr-2.5 mt-[-4px]">
          <ElCheckbox value={this.isChecked} onChange={() => this.$emit('checkboxChange', { checked: !this.isChecked, row: this.data })}></ElCheckbox>
        </div>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={[styles.container, this.isIntelligentMode ? 'mb-0' : '']}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
