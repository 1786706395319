import { I18nLang } from '@pharmsnap/shared/i18n'
import { IAccountErrorResponse, IErrorResponse, IHttpWrapError } from '@pharmsnap/shared/service/http/types'
import { showSingleToast } from '../../toast'
const overLimitMessage = {
  cn: '亲爱的用户，您的账号检索使用已达上限，请点击右上角的账号升级申请获取更多权限',
  en: 'Dear user, your account retrieval has reached the limit, please click the upgrade button on the upper right corner',
}

const usageLimitMessage = {
  cn: '亲爱的用户，您当前功能的使用次数已达上限，<a href="/usage" target="_blank" style="cursor: pointer; text-decoration: underline">点击查看账户升级说明</a>',
  en: 'Dear user, your current usage in this function has reached the limit. <a href="/usage" target="_blank" style="cursor: pointer; text-decoration: underline">Please click to view the account upgrade</a>',
}
const withoutAccessMessage = {
  cn: '亲爱的用户，您当前权限不足，<a href="/usage" target="_blank" style="cursor: pointer; text-decoration: underline">点击查看账户升级说明</a>',
  en: "Dear user, you can't access to this function.  <a href='/usage' target='_blank' style='cursor: pointer; text-decoration: underline'>Please click to view the account upgrade</a>",
}
const errorCodeMessageMap: Record<number, { en: string; cn: string }> = {
  // '-1': {
  //   cn: '服务器内部错误',
  //   en: 'Serve Error',
  // },
  130068: {
    cn: '该手机号已注册，可直接使用手机号登录',
    en: 'Phone number has registered',
  },
  130069: {
    cn: '自定义分析名称已存在',
    en: 'Another dashboard with the same name already exists. Please enter a different name',
  },
  130076: {
    cn: '图表名称重复,请修改',
    en: 'Chart Already Exist',
  },
  130078: {
    cn: '公共号关注成功，但该微信已经绑定其他智慧芽账号，请使用其他微信账号',
    en: 'Already bound, please change another',
  },
  130081: usageLimitMessage,
  10004: withoutAccessMessage,
  130082: {
    cn: '图表数量超过每个自定义分析的最大限制',
    en: 'The number of charts exceeds the limit per dashboard',
  },
  130085: {
    cn: '已设置该情报监控',
    en: 'This email alert has been set',
  },
  130086: {
    cn: '已达到情报监控最大设置数量,请升级您的订阅计划',
    en: 'The maximum number of email alerts has been reached, please upgrade your subscription plan',
  },
  130087: {
    cn: '已达到最大可发送邮箱地址数',
    en: 'The maximum number of email addresses has been reached',
  },
  130088: {
    cn: '情报监控的标题已存在',
    en: 'Alert title already exist',
  },
  130133: {
    cn: '创建失败，检索结果数量超过限制',
    en: 'Create failed, the search results exceed the limit',
  },
  130135: {
    cn: '存在实体不支持设置监控',
    en: 'There are entities that do not support setting alerts',
  },
  30101: {
    cn: '该账号不存在',
    en: "this mobile number doesn't exist",
  },
  30102: {
    cn: '您的帐号存在非法操作，已被禁用。您如果对此存在疑问，请联系您的管理员。',
    en: 'Your account has been disabled for illegal activity. If you have questions about this, please contact your administrator.',
  },
  30103: {
    cn: '账号不存在或者密码错误',
    en: 'Account does not exist or password is incorrect',
  },
  30103.3: {
    cn: '新密码设置失败，请录入正确的原密码。',
    en: 'Failed to set the new password. Please enter the correct old password.',
  },
  30111: {
    cn: '您的帐号还未认证，请到您的邮箱点击激活',
    en: 'Your account has not been authenticated. Please activate your account from your email.',
  },
  30116: {
    cn: '密码错误次数到达上限，帐号被锁定。',
    en: 'Your account has been locked as you have reached the maximum number of failed login attempts.',
  },
  30118: {
    cn: '登录方式不支持',
    en: 'Login method is not supported',
  },
  30119: {
    cn: '验证码错误',
    en: 'Wrong verification code',
  },
  30122: {
    cn: '手机号未绑定，或账号不存在',
    en: 'Cell phone number is not bound, or the account does not exist',
  },
  30125: {
    cn: '公共号关注成功，但该微信已经绑定其他智慧芽账号，请使用其他微信账号',
    en: 'Already bound, please change another',
  },
  30127: {
    cn: '公共号关注成功，但该微信已经绑定其他智慧芽账号，请使用其他微信账号',
    en: 'Already bound, please change another',
  },
  30129: {
    cn: '该手机号/邮箱已被使用，请使用其他手机号/邮箱',
    en: 'This phone number or email is already in use, please try another one',
  },
  30178: {
    cn: '该手机号/邮箱已被使用，请使用其他手机号/邮箱',
    en: 'This phone number or email is already in use, please try another one',
  },
  30153: {
    cn: '密码仅可由数字，字母，与英文符号组成，必须包含数字与字母，长度为8-32。',
    en: 'The password can contain only letters, digits, and English symbols, must contain letters and digits, and length is 8 to 32.',
  },
  30123: {
    cn: '密码长度为12-32位字符，必须包含大小写字母，数字，英文符号。',
    en: 'Password must be 12 - 32 characters long and include uppercase and lowercase letters, digits, and English characters.',
  },
  30151: {
    cn: '密码长度为12-32位字符，必须包含大小写字母，数字，英文符号。',
    en: 'Password must be 12 - 32 characters long and include uppercase and lowercase letters, digits, and English characters.',
  },
  30130: {
    cn: '您的帐号存在非法操作，已被停用。您如果对此存在疑问，请联系您的管理员。',
    en: 'Your account has been deactivated for illegal activity. If you have questions about this, please contact your administrator.',
  },
  30143: {
    cn: '发送频率过快，请稍后再试',
    en: 'Sending frequency beyond limit',
  },
  30145: {
    cn: '您所在的组织要求您使用商务邮箱访问智慧芽，请通过邮箱登录',
    en: 'Your organization requires you to use your business email address to access Patsnap. Please login via email.',
  },
  31002: {
    cn: '无效的手机号',
    en: 'Invalid phone number',
  },
  610003: {
    cn: '验证码发送频率过高',
    en: 'The frequency of sending verification code is too high',
  },
  130066: {
    cn: '当前账号使用存在异常，请明日再来',
    en: 'There is an exception in the current account usage, please come back tomorrow',
  },
  130072: usageLimitMessage,
  130074: usageLimitMessage,
  130073: usageLimitMessage,
  130097: overLimitMessage,
  42003: {
    cn: '当前检索范围过大，请缩小',
    en: 'The current search range is too large, please narrow it down',
  },
  130099: {
    cn: '导出数量超过每日限制',
    en: 'Export exceed daily limit',
  },
  130103: {
    cn: '无权限访问他人dashboard',
    en: 'No permission to access this dashboard',
  },
  130104: {
    cn: '不存在的dashboard',
    en: 'The dashboard id is not exist',
  },
  130105: {
    cn: '验证码无效，请重新发送',
    en: 'Invalid verification code. Please resend the code.',
  },
  130106: {
    cn: '验证码失效',
    en: 'The verification code has expired.',
  },
  130107: {
    cn: '请上传认证图片',
    en: 'Please upload photo',
  },
  130108: {
    cn: '链接已过期',
    en: 'The link has expired',
  },
  130111: {
    cn: '当前药物没有研发状态',
    en: 'No R&D status for current drug',
  },
  130122: {
    cn: '请完善个人信息， 以获得更多产品权限',
    en: 'Please complete personal information to get more privileges.',
  },
  130134: {
    cn: '您的账号没有创建此邮件提醒的权限，请联系管理员',
    en: 'Your account does not have the permission to create this email alert. Please contact the administrator.',
  },
  130136: {
    cn: '当前集团导出总量已达上限，个人账号无法导出，具体请联系管理员',
    en: 'The total amount of group export has reached the limit, personal account can not export data, please contact your administrator.',
  },
}
export function getErrorCodeMessage(errorCode: number, lang: I18nLang): string {
  const langMap = errorCodeMessageMap[errorCode]
  if (!langMap) {
    console.error(`错误状态码:"${errorCode}"没有找到语言配置包,请检查!`)
    return ''
  }
  return lang === 'cn' ? langMap.cn : langMap.en
}

export function handleError(rt: IHttpWrapError<IAccountErrorResponse | IErrorResponse>, lang: I18nLang) {
  const { numeric_error_code, error_message } = rt.data || {}
  const errMsg = getErrorCodeMessage(numeric_error_code, lang)
  showSingleToast({
    message: errMsg || error_message,
    type: 'error',
  })
}
