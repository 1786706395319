import { patentExtensionStatusWeightColorMap, patentExtensionStatusWeightMap } from '@patsnap/synapse_common_config'
import { IPatentExtensionStatus } from '@patsnap/synapse_common_interface'
import { getObjectKeys } from '@pharmsnap/shared/src/utils'
import { PropType, computed, defineComponent } from '@vue/composition-api'
export const BPatentExtensionType = defineComponent({
  name: 'BPatentExtensionType',
  props: {
    extensionType: {
      type: String as PropType<'SPC' | 'PED' | 'PTE'>,
      default: 'SPC',
    },
    extensionStatus: {
      type: [String, Array] as PropType<IPatentExtensionStatus | IPatentExtensionStatus[]>,
    },
    color: {
      type: String,
    },
  },
  setup(props) {
    const maxWeight = Math.max(...getObjectKeys(patentExtensionStatusWeightColorMap))
    const extensionStatusWeightLevel = computed(() => {
      if (!props.extensionStatus) return maxWeight
      if (typeof props.extensionStatus === 'string') return patentExtensionStatusWeightMap[props.extensionStatus] || maxWeight
      const minWeight = Math.min(
        ...(props.extensionStatus as IPatentExtensionStatus[]).map((status) => patentExtensionStatusWeightMap[status] || maxWeight)
      )
      return minWeight as keyof typeof patentExtensionStatusWeightColorMap
    })
    const extensionStatusColor = computed(() => {
      if (props.color) return props.color
      return patentExtensionStatusWeightColorMap[extensionStatusWeightLevel.value]
    })
    return () => {
      return (
        <span
          class={['border rounded px-1  text-xs inline-flex items-center']}
          style={{
            color: extensionStatusColor.value,
            borderColor: extensionStatusColor.value,
          }}
        >
          {props.extensionType}
        </span>
      )
    }
  },
})
