import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import {
  I18nLang,
  IDrugDealDetail,
  IDrugDealOriginOrgInfo,
  IDrugDealTerritoryItem,
  ILang,
  ISimplifiedCountryInfo,
  ITranslateLang,
} from '@patsnap/synapse_common_interface'
import { formatTimestamp, getLangDegraded, toThousands } from '@patsnap/synapse_common_utils'
import { differenceBy, filter, isUndefined } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import {
  COMMA,
  renderDrugTypeAsync,
  renderPopoverLimited,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedOrgAsync,
  renderPopoverLimitedTargetsAsync,
} from '.'
import { BDrugItem, BOrganizationItem, BPhaseTag, GLink, GTranslate } from '../components'
import { IBaseOrgInfo } from '../types'
import { getDictItemName, getRedirectLinkStatic, isOpenTranslate } from '../utils'

export function renderDealTitleInTable(data: IDrugDealDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang, maxLine?: number) {
  const sourceUrl = data.data_source?.find((item) => item.in_use)?.source_url
  const dealTitle = data.deal_title
  const dealTitleTranslation = data.deal_title_translation || []

  const content =
    sourceUrl && dealTitle ? (
      <GLink
        href={getRedirectLinkStatic(sourceUrl, $i18n.locale as I18nLang)}
        name={dealTitle}
        newTab={true}
        defaultColor="blue"
        class={`whitespace-pre-wrap text-sm leading-6 line-clamp-${maxLine}`}
      ></GLink>
    ) : (
      <span class={`line-clamp-${maxLine}`}>{dealTitle || EMPTY_PLACEHOLDER}</span>
    )

  const isTranslate = isOpenTranslate(dealTitleTranslation, customSettingTranslation)
  const translateTitle = dealTitleTranslation.find((item) => item.lang === customSettingTranslation)?.deal_title
  const executeTranslate = translateTitle ? isTranslate : false

  return (
    <GTranslate execute={executeTranslate} content={translateTitle || EMPTY_PLACEHOLDER}>
      {content}
    </GTranslate>
  )
}

export function renderDealDateInTable(data: IDrugDealDetail, $i18n: IVueI18n) {
  const isTerminated = data.deal_status_view?.dict_id === '646fc7b85bd542b2903f5b4e533bb300'
  const dealTime = isTerminated ? data.deal_end_time?.time_ts : data.deal_start_time?.time_ts || data.deal_end_time?.time_ts
  if (dealTime) {
    const locale = $i18n.locale.toUpperCase() as ILang
    return formatTimestamp(dealTime, { locale })
  }
  return EMPTY_PLACEHOLDER
}

function _renderDealOrg(
  data: { org_id_view?: IBaseOrgInfo[]; org_id?: string[]; origin_org?: IDrugDealOriginOrgInfo[] },
  config: {
    limitCount: number
    isDisplayInOneLine: boolean
  }
) {
  const { org_id_view = [], org_id = [], origin_org = [] } = data
  const { limitCount = 3, isDisplayInOneLine = false } = config

  if (org_id_view.length) {
    return renderPopoverLimitedOrgAsync(org_id_view, org_id, limitCount, isDisplayInOneLine)
  }
  return renderPopoverLimited(origin_org, {
    limitCount,
    inline: isDisplayInOneLine,
    popoverInline: false,
    render: (item) => <BOrganizationItem data={{ entity_id: '', name_en: item.name, entity_name_en: item.name }}></BOrganizationItem>,
  })
}

export function renderPrincipleOrgIdOneidInTable(data: IDrugDealDetail, limitCount = 3, isDisplayInOneLine = false) {
  return _renderDealOrg(
    { org_id_view: data.principle_org_id_view, org_id: data.principle_org_id, origin_org: data.principle_org },
    { limitCount, isDisplayInOneLine }
  )
}

export function renderPartnerOrgIdOneidInTable(data: IDrugDealDetail, limitCount = 3, isDisplayInOneLine = false) {
  return _renderDealOrg(
    { org_id_view: data.partner_org_id_view, org_id: data.partner_org_id, origin_org: data.partner_org },
    { limitCount, isDisplayInOneLine }
  )
}

export function renderDealDrugInTable(data: IDrugDealDetail, $i18n: IVueI18n) {
  const drugs = data.deal_project_drug || []
  if (drugs.length) {
    return renderPopoverLimited(drugs, {
      limitCount: 3,
      inline: false,
      popoverInline: false,
      render: (item) => {
        const phase = item.deal_phase_view ? getDictItemName(item.deal_phase_view, $i18n.locale.toUpperCase() as ILang) : ''
        return (
          <div class="flex item-center w-full overflow-hidden">
            <BDrugItem data={item} showCard class="truncate"></BDrugItem>
            {phase ? <BPhaseTag phase={phase} class="flex-shrink-0 ml-1" /> : null}
          </div>
        )
      },
    })
  }
  return EMPTY_PLACEHOLDER
}

export function renderDealDrugInCard(data: IDrugDealDetail) {
  const drugs = data.deal_project_drug || []
  if (drugs.length) {
    return renderPopoverLimited(drugs, {
      limitCount: 1,
      render: (item) => <BDrugItem data={item}></BDrugItem>,
    })
  }
  return EMPTY_PLACEHOLDER
}

export function renderTargetDealInTable(data: IDrugDealDetail, limitCount = 3, isDisplayInOneLine = false) {
  if (!data.target_id?.length) return EMPTY_PLACEHOLDER
  return renderPopoverLimitedTargetsAsync(data.target_id_view || [], data.target_id || [], limitCount, isDisplayInOneLine)
}

function _formatCountryItems(items: ISimplifiedCountryInfo[], locale: I18nLang): IDrugDealTerritoryItem[] {
  if (items.length) {
    return items.map((item) => ({
      name: getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name }, locale),
      normalized_id: item.id,
    }))
  }
  return []
}

/**
 *
 * @param isInline 是否显示一行
 */
export function renderDealCountryInTable(data: IDrugDealDetail, $i18n: IVueI18n, isInline = false, lineClamp = 2) {
  const normalizedIncludeCountry = _formatCountryItems(data.territory_included_id_view || [], $i18n.locale as I18nLang)
  const normalizedExcludeCountry = _formatCountryItems(data.territory_excluded_id_view || [], $i18n.locale as I18nLang)

  const territoryIncludeCountry = normalizedIncludeCountry.concat(
    differenceBy(data.territory_included || [], normalizedIncludeCountry, 'normalized_id')
  )
  const territoryExcludeCountry = normalizedExcludeCountry.concat(
    differenceBy(data.territory_excluded || [], normalizedExcludeCountry, 'normalized_id')
  )

  if (!territoryIncludeCountry.length && !territoryExcludeCountry.length) {
    return EMPTY_PLACEHOLDER
  }

  const text: string[] = []
  const locale = $i18n.locale.toUpperCase() as ILang
  const separator = ', '

  const excludeCountry = filter(territoryExcludeCountry, (item) => !!item.name)
  const includeCountry = filter(territoryIncludeCountry, (item) => !!item.name)

  const excludeNames = excludeCountry.map((i) => i.name)
  const includeNames = includeCountry.map((i) => i.name)
  // 包括全球
  if (includeCountry.find((x) => x.normalized_id === '4cc6684d-f7b4-392b-9dec-6fce3264fac8')) {
    if (excludeCountry.length) {
      if (locale === 'CN') text.push(`${excludeNames.join(separator)} 以外的地区`)
      else text.push(`Excluded ${excludeNames.join(separator)}`)
    } else {
      if (locale === 'CN') text.push('全球')
      else text.push('World')
    }
  } else {
    if (includeCountry.length) {
      text.push(`${locale === 'CN' ? '包括:' : 'Included:'} ${includeNames.join(separator)}`)
    }
    if (excludeCountry.length) {
      text.push(`${locale === 'CN' ? '排除:' : 'Excluded:'} ${excludeNames.join(separator)}`)
    }
  }

  if (!text.length) return EMPTY_PLACEHOLDER
  if (isInline) return text.join('; ')

  if (text.length === 1) {
    return (
      <div class={`line-clamp-${lineClamp + 1}`} title={text[0]}>
        {text[0]}
      </div>
    )
  }
  return text.map((text) => (
    <div class={`line-clamp-${lineClamp}`} title={text}>
      {text}
    </div>
  ))
}

export function renderDealTypeInTable(data: IDrugDealDetail, $i18n: IVueI18n, usePlaceholder = true) {
  if (!data.deal_type_view) return usePlaceholder ? EMPTY_PLACEHOLDER : ''
  return data.deal_type_view?.map((i) => getDictItemName(i, $i18n.locale.toUpperCase() as ILang)).join(COMMA)
}

export function renderDealDrugTypeInTable(data: IDrugDealDetail) {
  if (!data.drug_type?.length) return EMPTY_PLACEHOLDER
  return renderDrugTypeAsync(data.drug_type_view || [], data.drug_type || [], 3)
}

export function renderDealTotalValueInTable(data: IDrugDealDetail, type: 'total_value' | 'upfront_payment' | 'milestone_payment', $i18n: IVueI18n) {
  const va = data.deal_value?.find((v) => v.type === type)
  if (!va || !va.value) return $i18n.locale === 'cn' ? '未披露' : 'Undisclosed'
  return `$${toThousands(va.value)}M`
}

export function renderDealStatusInTable(data: IDrugDealDetail, $i18n: IVueI18n) {
  if (!data.deal_status || !data.deal_status_view) return EMPTY_PLACEHOLDER
  return getDictItemName(data.deal_status_view, $i18n.locale.toUpperCase() as ILang)
}

export function renderDealCrossBorderInTable(data: IDrugDealDetail, $i18n: IVueI18n) {
  if (!data.cross_border_view) return EMPTY_PLACEHOLDER
  return getDictItemName(data.cross_border_view, $i18n.locale.toUpperCase() as ILang)
}

export function renderDrugIndicationTable(data: IDrugDealDetail) {
  return renderPopoverLimitedDiseaseAsync(data.drug_indication_id_view || [], data.drug_indication_id, 3)
}
export function renderDealIndicationTable(data: IDrugDealDetail, limit = 3, isDisplayInOneLine = false) {
  return renderPopoverLimitedDiseaseAsync(data.deal_indication_id_view || [], data.deal_indication_id, limit, false, undefined, isDisplayInOneLine)
}
export function renderDealAmountDisclosureInTable(data: IDrugDealDetail, $i18n: IVueI18n) {
  if (isUndefined(data.amount_disclosure)) return EMPTY_PLACEHOLDER
  return <span>{data.amount_disclosure ? $i18n.t('common.yes') : $i18n.t('common.no')}</span>
}
