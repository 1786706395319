import '@patsnap-ui/icon/assets/solid/LoadingCircle.svg'
import { toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { ElCheckbox, ElDivider, ElInput, ElPopover, ElRadio, ElRadioGroup } from '@pharmsnap/shared/element-ui'
import { IExportDataType } from '@pharmsnap/shared/types'
import { PropType, computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { noop, toString } from 'lodash'
import { Icon as PtIcon } from 'patsnap-biz'
import '../../../assets/icon-svg/excel.svg'
import '../../../assets/icon-svg/pdf.svg'
import { IExportFormatterType } from '../../business/BCommonList/types'
import { GIcon } from '../GIcon/GIcon'
import { GRadio } from '../GRadio/GRadio'
import { GSortField } from '../GSortField/GSortField'
import $class from './GExportDialog.module.scss'
import { IExportConfig, IExportFieldConfigItem, IExportFieldItem, IExportRangeType, IExportType } from './GExportDialogType'
import cn from './locales/cn.json'
import en from './locales/en.json'

const iconRecord: Record<IExportFormatterType, string> = {
  PDF: 'Pdf',
  XLSX: 'Excel',
}

export const GExportDialog = defineComponent({
  name: 'GExportDialog',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /**
     * 导出类型,checked为用户选择的固定条目,range为用户导出了所有结果,需要用户再选择导出范围
     */
    exportType: {
      type: String as PropType<IExportType>,
      default: 'selectData',
    },
    /**
     * 选择的总条数
     */
    total: {
      type: Number,
      default: 0,
    },
    /**
     * 一次最多支持多少条导出
     */
    limitPreExport: {
      type: Number,
      default: 500,
    },
    // 是否显示上限文案
    showMaxLimitTip: {
      type: Boolean,
      default: true,
    },
    /**
     * 限制导出深度(偏移值)
     */
    limitOffset: {
      type: Number,
      default: 50000,
    },
    /**
     * 导出字段配置
     */
    exportFieldConfig: {
      type: Array as PropType<Array<IExportFieldConfigItem>>,
      default: () => [],
    },
    cancelCallback: {
      type: Function as PropType<() => void>,
      default: noop,
    },
    exportCallBack: {
      type: Function as PropType<(data: IExportConfig) => Promise<void>>,
      default: noop,
    },
    optionType: {
      type: String as PropType<'radio' | 'select'>,
      default: 'select',
    },
    isShownInDialog: {
      type: Boolean,
      default: true,
    },
    from: {
      type: Number,
    },
    to: {
      type: Number,
    },
    exportFormatter: {
      type: Array as PropType<Array<{ field?: string; type: IExportFormatterType }>>,
      default: () => [{ type: 'XLSX' }],
    },
  },
  setup(props) {
    const includeDetail = ref(props.exportFieldConfig[0].include_detail)
    const { ts, isCN } = useLocale()
    const showOptions = ref(false)
    const selectAllIsDisabled = computed(() => {
      return props.total > props.limitPreExport
    })
    const exporting = ref(false)
    const exportRange = reactive<{
      type: IExportRangeType
      from: string
      to: string
    }>({
      type: 'all',
      from: toString(props.from) || '',
      to: toString(props.to) || '',
    })
    const exportDataType = ref(props.exportFieldConfig[0]?.exportDataType || '')
    const selectedExportFormatter = ref(props.exportFormatter[0].type)
    const sortFieldsMap = reactive<Record<string, IExportFieldItem[]>>(
      props.exportFieldConfig.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.exportDataType]: curr.fields,
        }
      }, {})
    )
    const rangeErrorMessage = computed(() => {
      const from = +exportRange.from
      const to = +exportRange.to
      if (exportRange.type === 'range') {
        if (from <= 0 || to <= 0) {
          return ts('GExportDialog.pleaseEnterAValidRange')
        }
        if (to > props.total) {
          return ts('GExportDialog.pleaseEnterAValidRange')
        }
        if (from > to) {
          return ts('GExportDialog.pleaseEnterAValidRange')
        }
        if (to - from > props.total - 1) {
          return ts('GExportDialog.pleaseEnterAValidRange')
        }
        if (to - from > props.limitPreExport - 1) {
          return ts('GExportDialog.pleaseEnterAValidRange')
        }
        if (from > props.limitOffset || to > props.limitOffset) {
          return ts('GExportDialog.overOffsetMsg', { count: toThousands(props.limitOffset) })
        }
      }
      return ''
    })
    const exportFormatterLabel = computed<Record<IExportFormatterType, string>>(() => {
      return {
        PDF: ts('GExportDialog.pdf'),
        XLSX: 'XLSX',
      }
    })
    init()
    function init() {
      if (props.total > props.limitPreExport) {
        exportRange.type = 'range'
        exportRange.from = '1'
        exportRange.to = `${props.limitPreExport}`
      } else {
        exportRange.type = 'all'
      }
    }
    async function onConfirm() {
      exporting.value = true
      const exportFiledConfig: IExportConfig['exportFieldsConfig'] = Object.keys(sortFieldsMap).map((item) => {
        const oldVal = props.exportFieldConfig.find((o) => o.exportDataType === item)?.include_detail
        return {
          export_data_type: item as IExportDataType,
          fields: sortFieldsMap[item].map((item) => ({ field: item.field, show: item.show })),
          include_detail: item === exportDataType.value ? includeDetail.value : typeof oldVal !== 'undefined' ? oldVal : true,
        }
      })
      const fields: string[] = sortFieldsMap[exportDataType.value].filter((item) => item.show).map((item) => item.field)
      const selectedExportFormatterField = props.exportFormatter.find((item) => item.type === selectedExportFormatter.value)?.field
      if (selectedExportFormatterField) {
        fields.push(selectedExportFormatterField)
      }
      if (props.exportType === 'selectData' && props.total <= props.limitPreExport) {
        const data: IExportConfig = {
          exportDataType: exportDataType.value,
          exportType: props.exportType,
          exportRangeType: 'all',
          fields,
          includeDetail: includeDetail.value,
          exportFieldsConfig: exportFiledConfig,
        }
        await props.exportCallBack(data)
        exporting.value = false
        return
      }
      if (props.exportType === 'allData' || props.total > props.limitPreExport) {
        const data: IExportConfig = {
          exportDataType: exportDataType.value,
          exportType: 'allData',
          exportRangeType: exportRange.type,
          from: +exportRange.from,
          to: +exportRange.to,
          fields,
          includeDetail: includeDetail.value,
          exportFieldsConfig: exportFiledConfig,
        }
        await props.exportCallBack(data)
        exporting.value = false
      }
    }

    function toggleShowOptions() {
      showOptions.value = !showOptions.value
    }

    function selectOption(item: IExportFieldConfigItem) {
      exportDataType.value = item.exportDataType
      showOptions.value = false
    }

    const label = computed(() => {
      const val = props.exportFieldConfig.find((o) => o.exportDataType === exportDataType.value)
      return val ? (isCN.value ? val.dataTypeNameCn : val.dataTypeNameEn) : ''
    })

    const radioItems = computed(() => {
      return props.exportFieldConfig.map((o) => {
        return {
          value: o.exportDataType,
          name: isCN.value ? o.dataTypeNameCn : o.dataTypeNameEn,
          label: isCN.value ? o.dataTypeNameCn : o.dataTypeNameEn,
        }
      })
    })

    watch(
      () => exportDataType.value,
      (val) => {
        const includeDetailVal = props.exportFieldConfig.find((o) => o.exportDataType === val)?.include_detail
        includeDetail.value = typeof includeDetailVal !== 'undefined' ? includeDetailVal : true
      }
    )

    return {
      exportDataType,
      sortFieldsMap,
      exportRange,
      selectedExportFormatter,
      includeDetail,
      onConfirm,
      rangeErrorMessage,
      selectAllIsDisabled,
      exporting,
      isCN,
      showOptions,
      toggleShowOptions,
      label,
      selectOption,
      radioItems,
      exportFormatterLabel,
    }
  },
  methods: {
    renderExportNumber() {
      const countText = `${toThousands(this.total)} ${this.$t('GExportDialog.recordTotal')}`
      const countMaxLimitText = `${this.$t('GExportDialog.recordMax')} ${toThousands(this.limitPreExport)}`
      // 单次导出上限小于limitOffset限制时，才显示上限文案，不然单次导出上限limitPreExport提示会和limitOffset数量的提示不一致
      const countTipText = this.showMaxLimitTip && this.limitPreExport <= this.limitOffset ? `(${countText}, ${countMaxLimitText})` : `(${countText})`

      if (this.exportType === 'selectData' && this.total <= this.limitPreExport) {
        return <div>{this.$tc('GExportDialog.SelectRecords', this.total, { count: this.total })}</div>
      } else {
        return (
          <ElRadioGroup v-model={this.exportRange.type}>
            <div>
              <ElRadio disabled={this.selectAllIsDisabled} label="all">{`${this.$t('GExportDialog.selectAll')} (${toThousands(
                this.total
              )})`}</ElRadio>
            </div>
            <div
              class={[
                'mt-1.5',
                {
                  [$class.rangerError]: this.rangeErrorMessage,
                },
                $class.rangeArea,
              ]}
            >
              <ElRadio label="range">
                <span class="inline-flex items-center text-sm">
                  <span>{this.$t('GExportDialog.from')}</span>
                  <ElInput style="width: 80px; font-size: 14px" class="mx-1" type="number" v-model={this.exportRange.from} size="mini"></ElInput>
                  <span>{this.$t('GExportDialog.to')}</span>
                  <ElInput style="width: 80px; font-size: 14px" class="mx-1" type="number" v-model={this.exportRange.to} size="mini"></ElInput>
                  <span>{countTipText}</span>
                </span>
              </ElRadio>
              {this.rangeErrorMessage ? <div class="text-sm text-red-default mt-1 pl-6 h-5">{this.rangeErrorMessage}</div> : null}
            </div>
          </ElRadioGroup>
        )
      }
    },
    renderSelect() {
      return (
        <ElPopover placement="bottom-start" visibleArrow={false} trigger="manual" value={this.showOptions} offset={0} popperClass={$class.popper}>
          {this.exportFieldConfig.length > 1 && (
            <div
              slot="reference"
              class="inline-flex cursor-pointer border border-gray-60 rounded h-8 items-center pl-3"
              onClick={this.toggleShowOptions}
            >
              <div class={$class.displayLabel} title={this.label}>
                {this.label}
              </div>
              <GIcon class="text-text-t2 ml-2.5 pr-1" svgName={this.showOptions ? 'SolidDropdownClose' : 'SolidDropdownOpen'} size={24}></GIcon>
            </div>
          )}
          <div
            class={$class.optionsContainer}
            {...{
              directives: this.showOptions
                ? [
                    {
                      name: 'click-outside',
                      value: {
                        handler: this.toggleShowOptions,
                      },
                    },
                  ]
                : [],
            }}
          >
            {this.exportFieldConfig.map((o) => {
              return (
                <div
                  key={o.exportDataType}
                  class={[$class.option, o.exportDataType === this.exportDataType ? $class.optionActive : '']}
                  onClick={() => this.selectOption(o)}
                >
                  {this.isCN ? o.dataTypeNameCn : o.dataTypeNameEn}
                </div>
              )
            })}
          </div>
        </ElPopover>
      )
    },
    renderRadio() {
      return <GRadio v-model={this.exportDataType} items={this.radioItems} mode="circle"></GRadio>
    },
    renderSelectComponent() {
      return this.optionType === 'select' ? this.renderSelect() : null
    },
    renderTooltip() {
      return (
        <div class="flex gap-x-1 items-center">
          <PtIcon icon="SolidInfo" class="w-6 h-6 text-text-t4 text-base " />
          <div>{this.isCN ? this.exportFieldConfig[0].tooltip?.cn : this.exportFieldConfig[0].tooltip?.en}</div>
        </div>
      )
    },
    renderExportFields() {
      return (
        <div class={$class.fieldContainer}>
          {!!this.exportFieldConfig.length && this.renderSelectComponent()}
          {this.exportFieldConfig[0].tooltip && this.renderTooltip()}
          <GSortField
            class={['border border-gray-60 p-2 mt-2 rounded', $class.sortField]}
            v-model={this.sortFieldsMap[this.exportDataType]}
          ></GSortField>
        </div>
      )
    },
    renderExportFormatter() {
      return (
        <ElRadioGroup v-model={this.selectedExportFormatter}>
          {this.exportFormatter.map((item) => {
            return (
              <ElRadio label={item.type} key={item.type}>
                <GIcon svgName={iconRecord[item.type]} size={24}></GIcon>
                <span>{this.exportFormatterLabel[item.type]}</span>
              </ElRadio>
            )
          })}
        </ElRadioGroup>
      )
    },
  },
  render() {
    return (
      <div class={['text-text-t1', this.isShownInDialog ? $class.dialogWrap : $class.fullScreenWrap]}>
        <div class={$class.scrollContainer}>
          {this.$slots.header}
          <div class={$class.label}>1.{this.$t('GExportDialog.exportNumber')}</div>
          <div class="mt-2">{this.renderExportNumber()}</div>
          {!this.isShownInDialog ? <ElDivider></ElDivider> : null}
          <div class={[$class.label, this.isShownInDialog ? 'mt-4' : '']}>2.{this.$t('GExportDialog.exportFormate')}</div>
          {this.renderExportFormatter()}
          {!this.isShownInDialog ? <ElDivider></ElDivider> : null}
          <div class={[$class.label, this.isShownInDialog ? 'mt-4' : '']}>3.{this.$t('GExportDialog.exportFields')}</div>
          {this.renderExportFields()}
        </div>

        <div class="mt-2">
          <ElCheckbox v-model={this.includeDetail}>{this.$t('GExportDialog.includeSearchDetail')}</ElCheckbox>
        </div>
        <div class={['mt-2 flex', this.isShownInDialog ? 'justify-end' : 'justify-start']}>
          {this.isShownInDialog ? (
            <button class="pt-ui-btn" data-type="default" style="width:100px" onClick={this.cancelCallback}>
              {this.$tc('common.cancel')}
            </button>
          ) : null}
          <button
            class="pt-ui-btn ml-2"
            data-type="submit"
            style="width:100px"
            disabled={this.exporting || !!this.rangeErrorMessage}
            onClick={this.onConfirm}
          >
            {this.exporting ? <GIcon svgName="SolidLoadingBars"></GIcon> : this.$tc('export.export')}
          </button>
        </div>
      </div>
    )
  },
})
