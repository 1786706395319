/* eslint-disable @typescript-eslint/no-explicit-any */
import '@patsnap-ui/icon/assets/solid/ResizeFullScreen.svg'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { GTooltip } from '@pharmsnap/shared/components/ui/GTooltip/GTooltip'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { exportChartImage } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import { BAnalysisChartRollupSwitch } from '.'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import { useAnalysisChartCard } from '../compositions/useAnalysisChartControls'
import { useAnalysisRegisterChartRefs } from '../compositions/useAnalysisRegisterChartRefs'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import {
  IAnalysisChartCheckboxControl,
  IAnalysisChartControlItem,
  IAnalysisChartControlLayoutItem,
  IAnalysisChartDisplayControl,
  IAnalysisChartDropDownSelectControl,
  IAnalysisChartDropDownSingleSelectControl,
  IAnalysisChartRadioControl,
  IAnalysisChartRollupControl,
  IAnalysisDisplayIconType,
} from '../type'
import { BAnalysisChartControlItem } from './BAnalysisChartControlItem'
import { BAnalysisChartSettingButton } from './BAnalysisChartSettingButton'
import { BAnalysisChartCheckbox } from './form-items/BAnalysisChartCheckbox'
import { BAnalysisChartDisplaySwitch } from './form-items/BAnalysisChartDisplaySwitch'
import { BAnalysisChartDropdownSelect } from './form-items/BAnalysisChartDropdownSelect'
import { BAnalysisChartDropdownSingleSelect } from './form-items/BAnalysisChartDropdownSingleSelect'
import { BAnalysisChartRadioGroup } from './form-items/BAnalysisChartRadioGroup'

export const BAnalysisChartCard = defineComponent({
  name: 'BAnalysisChartCard',
  i18n: {
    messages: { cn, en },
  },
  props: {
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showFullScreen: {
      type: Boolean,
      default: true,
    },
    fullscreenCfg: {
      type: Object as PropType<{ label?: string; showLabel?: boolean }>,
    },
    downloadCfg: {
      type: Object as PropType<{
        visible: boolean
        type?: 'custom' | 'chart-img'
        auth?: boolean
        label?: string
        showLabel?: boolean
        chartInsFinder?: (controls: IAnalysisChartControlItem[]) => IAnalysisDisplayIconType | undefined
      }>,
      default: () => ({ visible: false }),
    },
    height: {
      type: Number,
    },
    emptyHeight: {
      type: Number,
      default: 446,
    },
    controls: {
      type: Array as PropType<IAnalysisChartControlItem[]>,
      default: () => [],
    },
    layout: {
      type: Array as PropType<IAnalysisChartControlLayoutItem[]>,
      default: () => [],
    },
    settingTitle: {
      type: String,
    },
    settingDesc: {
      type: String,
    },
    value: {
      type: Object as PropType<Record<PropertyKey, any>>,
      default: () => ({}),
    },
    showSettings: {
      type: Boolean,
      default: true,
    },
    // 指定内容高度
    specifyContentHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { isCN } = useLocale()

    const {
      state: { userInfo },
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const { isLoading, isEmpty, height, emptyHeight, downloadCfg } = toRefs(props)

    const { layout, controls } = toRefs(props)

    const settingPopperVisible = ref(false)

    const { controlsPositionLeft, controlsPositionMiddle, controlsPositionSetting, controlsPositionTopRight, controlsPositionTopLeft } =
      useAnalysisChartCard({
        controls: controls,
        layout: layout,
      })

    const showDownload = computed(() => downloadCfg.value.visible)

    const downloadAuth = computed(() => downloadCfg.value.auth ?? true)

    const containerStyle = computed(() => {
      const h = !isLoading.value && isEmpty.value ? `${emptyHeight.value}px` : props.specifyContentHeight ? 'auto' : `${height?.value || 446}px`

      return {
        height: h,
        minHeight: `${446}px`,
      }
    })

    const { echartsRefs } = useAnalysisRegisterChartRefs()

    function handleDownload() {
      if (isEmpty.value) return

      if (isFreeUser.value && downloadAuth.value) {
        return changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD' })
      }

      const { type } = downloadCfg.value

      if (type === 'custom') {
        emit('download')
      }

      if (type === 'chart-img') {
        const echartInstance = echartsRefs.value.find((item) =>
          props.downloadCfg?.chartInsFinder
            ? item.displayType === props.downloadCfg?.chartInsFinder?.(props.controls)
            : item.displayType === props.value?.display
        )?.chartIns as EChartsType
        if (echartInstance) {
          exportChartImage(echartInstance, props.title || '', isCN.value, {
            userId: userInfo.value?.user_id || '',
          })
        }
      }
      emit('afterDownload')
    }

    function handleCancel() {
      settingPopperVisible.value = false
    }

    return {
      echartsRefs,
      containerStyle,
      controlsPositionLeft,
      controlsPositionMiddle,
      controlsPositionTopRight,
      controlsPositionSetting,
      controlsPositionTopLeft,
      handleDownload,
      showDownload,
      handleCancel,
      isCN,
      settingPopperVisible,
    }
  },
  methods: {
    renderDisplayControlItem(item: IAnalysisChartDisplayControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartDisplaySwitch v-model={this.value[item.bindField]} options={item.items}></BAnalysisChartDisplaySwitch>
        </BAnalysisChartControlItem>
      )
    },
    renderRadioControlItem(item: IAnalysisChartRadioControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartRadioGroup
            options={item.items}
            v-model={this.value[item.bindField]}
            layout={layout === 'horizontal' ? 'inline' : 'block'}
            mode={item.mode}
            isCN={this.isCN}
            isManualTrigger={item.isManualTrigger}
            showCancel={true}
            onCancel={this.handleCancel}
          ></BAnalysisChartRadioGroup>
        </BAnalysisChartControlItem>
      )
    },
    renderRollupControlItem(item: IAnalysisChartRollupControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartRollupSwitch text={item.text} v-model={this.value[item.bindField]}></BAnalysisChartRollupSwitch>
        </BAnalysisChartControlItem>
      )
    },
    renderCheckboxControlItem(item: IAnalysisChartCheckboxControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartCheckbox name={item.name} v-model={this.value[item.bindField]}></BAnalysisChartCheckbox>
        </BAnalysisChartControlItem>
      )
    },
    renderDropdownSelectControlItem(item: IAnalysisChartDropDownSelectControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartDropdownSelect
            title={item.title}
            label={item.name}
            min={item.minChecked}
            confirmAble={item.confirmAble}
            options={item.options}
            placement={item.placement}
            width={item.width}
            v-model={this.value[item.bindField]}
          ></BAnalysisChartDropdownSelect>
        </BAnalysisChartControlItem>
      )
    },
    renderDropdownSingleSelectControlItem(item: IAnalysisChartDropDownSingleSelectControl, layout: 'vertical' | 'horizontal') {
      return (
        <BAnalysisChartControlItem item={item} layout={layout}>
          <BAnalysisChartDropdownSingleSelect
            v-model={this.value[item.bindField]}
            options={item.options}
            width={item.width}
            title={item.title}
          ></BAnalysisChartDropdownSingleSelect>
        </BAnalysisChartControlItem>
      )
    },
    renderControl(item: IAnalysisChartControlItem, layout: 'vertical' | 'horizontal') {
      if (item.type === 'display') return this.renderDisplayControlItem(item, layout)
      if (item.type === 'radio') return this.renderRadioControlItem(item, layout)
      if (item.type === 'rollup') return this.renderRollupControlItem(item, layout)
      if (item.type === 'checkbox') return this.renderCheckboxControlItem(item, layout)
      if (item.type === 'dropdown-select') return this.renderDropdownSelectControlItem(item, layout)
      if (item.type === 'dropdown-single-select') return this.renderDropdownSingleSelectControlItem(item, layout)
      return null
    },
    renderLeftControls() {
      return (
        <div class="flex items-center">
          {this.controlsPositionLeft.map((item, index) => (
            <div class={{ 'ml-4': index !== 0 }}>{this.renderControl(item, 'horizontal')}</div>
          ))}
        </div>
      )
    },
    renderMiddleControls() {
      return (
        <div class="flex items-center">
          {this.controlsPositionMiddle.map((item, index) => (
            <div class={{ 'ml-4': index !== 0 }}>{this.renderControl(item, 'horizontal')}</div>
          ))}
        </div>
      )
    },
    renderSettingControls() {
      return (
        <BAnalysisChartSettingButton
          showFooter={false}
          popperVisible={this.settingPopperVisible}
          onToggle={(val: boolean) => (this.settingPopperVisible = val)}
        >
          <template slot="header">{this.settingTitle ? <div class="leading-6 text-base text-text-t1">{this.settingTitle}</div> : null}</template>
          <template slot="default">
            <div class="flex flex-col">
              {this.controlsPositionSetting.map((item, index) => (
                <div class={{ 'mt-4': index !== 0 }}>{this.renderControl(item, 'vertical')}</div>
              ))}
            </div>
          </template>
        </BAnalysisChartSettingButton>
      )
    },
    renderDownload() {
      return (
        <GTooltip placement="top" theme="dark" content={this.downloadCfg.label || (this.$t('common.export') as string)}>
          <span
            class={['inline-flex items-center p-1 rounded hover:bg-gray-30', this.isEmpty ? 'cursor-not-allowed text-text-t3' : 'cursor-pointer']}
            onClick={this.handleDownload}
          >
            <GIcon svgName="SolidExport" size={24} color={this.isEmpty ? '#b3bac5' : '#495973'}></GIcon>
            {this.downloadCfg.showLabel && <span class="ml-1">{this.downloadCfg.label || (this.$t('common.export') as string)}</span>}
          </span>
        </GTooltip>
      )
    },
    renderFullScreen() {
      return (
        <GTooltip theme="dark" content={this.fullscreenCfg?.label || (this.$t('analysisChart.fullScreen.open') as string)}>
          <span
            onClick={() => this.$emit('open')}
            style="line-height: 0;"
            class="inline-flex items-center p-1 rounded hover:bg-gray-30 cursor-pointer"
          >
            <GIcon svgName="SolidResizeFullScreen" useSvgDefaultColor={true} size={24}></GIcon>
            {this.fullscreenCfg?.showLabel && (
              <span class="ml-1">{this.fullscreenCfg?.label || (this.$t('analysisChart.fullScreen.open') as string)}</span>
            )}
          </span>
        </GTooltip>
      )
    },
    renderTopLeftControls(cls = '') {
      if (this.controlsPositionTopLeft.length === 0) return null

      return (
        <div class={[cls, 'flex items-center']}>
          {this.controlsPositionTopLeft.map((item, index) => (
            <div class={{ 'ml-4': index !== 0, 'flex-shrink-0': true }}>{this.renderControl(item, 'horizontal')}</div>
          ))}
        </div>
      )
    },
    renderTopRightControls(cls = '') {
      const fullScreenContent = this.showFullScreen ? (
        <span class="flex-shrink-0" style="line-height: 0;">
          {this.renderFullScreen()}
        </span>
      ) : null
      const downLoadContent = this.showDownload ? (
        <span class="flex-shrink-0" style="line-height: 0;">
          {this.renderDownload()}
        </span>
      ) : null
      const topRightControlsContent = this.controlsPositionTopRight.map((item) => (
        <div class="flex-shrink-0">{this.renderControl(item, 'horizontal')}</div>
      ))

      if (fullScreenContent === null && downLoadContent === null && topRightControlsContent.length === 0) return null

      return (
        <div class={['flex items-center space-x-2', cls]}>
          {...topRightControlsContent}
          {this.$slots.headerActionSlot ? this.$slots.headerActionSlot : null}
          {downLoadContent}
          {fullScreenContent}
        </div>
      )
    },
    renderHeader() {
      return (
        <div class="flex items-center">
          <div class="leading-8 text-sm text-text-t1 font-medium flex-shrink-0">{this.$slots.title || this.title}</div>
          {this.renderTopLeftControls('flex-shrink-0 ml-2')}
          {this.renderTopRightControls('ml-auto')}
        </div>
      )
    },
    renderDesc() {
      return this.desc ? <div class="text-xs leading-4 text-text-t2">{this.desc}</div> : null
    },
    renderSetting() {
      if (!this.controlsPositionLeft.length && !this.controlsPositionMiddle.length && !this.controlsPositionSetting.length) return null
      return (
        <div class="flex items-center mt-2">
          {this.controlsPositionLeft.length ? <div class="flex-shrink-0 mr-6">{this.renderLeftControls()}</div> : null}
          {this.controlsPositionMiddle.length ? (
            <div class={[this.controlsPositionSetting.length ? 'flex-1 overflow-hidden flex justify-end' : 'ml-auto']}>
              {this.renderMiddleControls()}
            </div>
          ) : null}
          {this.controlsPositionSetting.length ? (
            <div class={[this.controlsPositionMiddle.length ? 'ml-6' : 'ml-auto']}>{this.renderSettingControls()}</div>
          ) : null}
        </div>
      )
    },
  },
  render() {
    return (
      <BCardContainer class="p-4" emptyCls="pt-4" style={this.containerStyle} emptyMode={true} isLoading={this.isLoading} isEmpty={this.isEmpty}>
        <template slot="header">
          <div>{this.renderHeader()}</div>
        </template>
        <template slot="subHeader">
          {this.desc && <div class="mt-1">{this.renderDesc()}</div>}
          {this.showSettings && this.renderSetting()}
        </template>
        <template slot="default">
          <div class="h-full pt-3">
            <div style={{ height: this.specifyContentHeight ? `${this.height}px` : '100%' }}>{this.$slots.default}</div>
          </div>
        </template>
      </BCardContainer>
    )
  },
})
