import { i18n } from '@pharmsnap/shared/i18n'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import { BOrganizationFetchCard } from '../../card/BOrganizationCard/BOrganizationFetchCard'

const BOrganizationFetchCardCom = Vue.extend(BOrganizationFetchCard)

export function getDisplayPosition(
  domRect: DOMRect,
  cfg: { gap?: number; popWidth?: number; popHeight?: number } = {}
): { top: number; left: number } {
  const { top, left, width } = domRect
  const containerMiddleX = window.innerWidth / 2
  const containerMiddleY = window.innerHeight / 2

  const { gap = 20, popWidth = 350, popHeight = 350 } = cfg
  let rtTop = 0
  let rtLeft = 0
  if (left > containerMiddleX && top <= containerMiddleY) {
    // 第一象限
    rtTop = top
    rtLeft = left - popWidth - gap
  } else if (left <= containerMiddleX && top < containerMiddleY) {
    // 第二象限
    rtTop = top
    rtLeft = left + width + gap
  } else if (left < containerMiddleX && top >= containerMiddleY) {
    // 第三象限
    rtTop = top - popHeight
    rtLeft = left + width + gap
  } else if (left >= containerMiddleX && top > containerMiddleY) {
    // 第四象限
    rtTop = top - popHeight
    rtLeft = left - popWidth - gap
  }

  return {
    top: rtTop < 0 ? 0 : rtTop,
    left: rtLeft < 0 ? 0 : rtLeft,
  }
}

export function useCorpCardRender() {
  let cardVm: null | Vue = null
  const companyCardContainerRef = ref()
  const openedCardId = ref()
  function renderCardFn(id: string) {
    if (cardVm) {
      cardVm.$destroy()
    }
    cardVm = new BOrganizationFetchCardCom({
      i18n,
      propsData: {
        id,
      },
    })
    cardVm.$mount()
    return cardVm.$el
  }

  const showCompanyCard = (id: string, _id: string) => {
    const node = document.getElementById(_id)
    if (!node) {
      return
    }
    const { top, left } = getDisplayPosition(node.getBoundingClientRect())
    const el = renderCardFn(id)
    if (companyCardContainerRef.value.firstChild) {
      companyCardContainerRef.value.firstChild.replaceWith(el)
    } else {
      companyCardContainerRef.value.append(el)
    }
    companyCardContainerRef.value.setAttribute('style', `display: block; top: ${top}px; left: ${left}px;`)
  }
  const closeCompanyCard = () => {
    companyCardContainerRef.value.setAttribute('style', 'display: none;')
    openedCardId.value = undefined
  }

  const onClickCompanyNode = (id: string, _id: string) => {
    if (id === openedCardId.value) {
      return closeCompanyCard()
    }
    openedCardId.value = id
    showCompanyCard(id, _id)
  }

  return {
    companyCardContainerRef,
    renderCardFn,
    onClickCompanyNode,
    closeCompanyCard,
  }
}
