/**
 * 适应症树根节点id
 */
export const DISEASE_ROOT_IDS = [
  '54e2cf3dc3294445b4a27b990b0ba274',
  'c354b2ea74d349da99bef8388390c7d8',
  '234aad67e4bc46b3906543b77e393606',
  'c8df5dce70fd4e0cb3320058ddb95461',
  'fce2b462d4fa493eb9d82513b9007011',
  '5b1dfc2ca8564662860187aef864467d',
  '76732dd51f2842799e6843eef80820ea',
  '9a802adb6e94433f8f4eb9be678991b8',
  '20f1c6915ff140519285386c54883aae',
  '62eaee6d9ee44410b8aca59dfc7408e9',
  '042cbe45c973477b957717ded29ef7b6',
  '4e8519876cfb47e1a3b07957e869ef32',
  '948c9548c2ed46b5865b8693f7f96604',
  '63ed6e7cb91a42928300df21dd9d3ac1',
  'b3fbcd71f916470d88c3c590793fe4f3',
]
