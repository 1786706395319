import { defineComponent } from '@vue/composition-api'
import { get } from 'lodash'

export const GCollapsibleTreeNode = defineComponent({
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    showLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxChildWidth: 0,
      marginRight: 0,
    }
  },
  render() {
    return (
      <div class="inline-flex relative w-full" style={{ ...this.node?.style }}>
        {!this.isRoot && !this.isLast ? (
          <div class="absolute -left-10 top-[26px] h-full border-l border-solid border-gray-40 pointer-events-none"></div>
        ) : null}
        <div class="inline-flex relative mt-2 flex-shrink-0 max-w-full" style={{ ...this.node?.contentStyle }}>
          {!this.isRoot ? <div class="absolute top-[18px] -left-10 border-b border-solid border-gray-40 w-10 m-0"></div> : null}
          {this.$scopedSlots.default?.({ node: this.node })}
          {get(this.node, 'children', []).length > 0 || this.node.multiple ? (
            <div class="absolute top-[18px] border-b border-solid border-gray-40 w-10 m-0" style={{ width: `42px`, right: '-42px' }} />
          ) : null}
        </div>
        {get(this.node, 'children', []).length > 0 ? (
          <div class={['w-full flex-1 overflow-hidden', get(this.node, 'children', []).length === 1 && !this.node.multiple ? 'pl-10' : 'pl-20']}>
            {get(this.node, 'children', []).map((childNode: Record<string, unknown>, index: number) => (
              <GCollapsibleTreeNode
                node={childNode}
                isLast={index === get(this.node, 'children', []).length - 1}
                scopedSlots={{
                  default: ({ node }: never) => this.$scopedSlots.default?.({ node }),
                }}
              />
            ))}
          </div>
        ) : null}
      </div>
    )
  },
})
