import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.PATENT,
}

export default [
  {
    path: `patent-finder`,
    name: E_ROUTER_NAME.PATENT_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/patent/PatentFinder'),
    meta: {
      ...meta,
      title: createWebTitle('专利检索', 'Patent Finder'),
      hideQuickSearch: true,
    },
  },
  {
    path: `patent-list`,
    name: E_ROUTER_NAME.PATENT_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/patent/PatentList'),
    meta: {
      ...meta,
      title: createWebTitle('专利检索结果', 'Patent Search Results'),
      notWide: true,
    },
  },
  {
    path: `patent/:${E_ROUTER_PARAMS.PATENT_ID}`,
    name: E_ROUTER_NAME.PATENT_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/patent/PatentDetail'),
    meta: {
      notWide: true,
    },
  },
] as RouteConfig[]
