import { BaseApi } from '@pharmsnap/shared/service/apis/baseApi'
import { IHttp, IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { ICommonTrack } from '@pharmsnap/shared/types'
import { IUserShareInfo } from '@pharmsnap/shared/types/account'

export class TrackingApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async share(data: IUserShareInfo): Promise<IHttpWrap<void>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/event/share'),
      method: 'POST',
      data,
    })
  }
  async shareOpen(data: IUserShareInfo): Promise<IHttpWrap<void>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/event/share/open'),
      method: 'POST',
      data,
    })
  }

  /**
   * 通用汇报埋点
   * @deprecated 不建议直接调用接口方法,请使用 packages/shared/src/features/tracking/index.ts 的 trackingEvent方法
   * @param data
   * @returns
   */
  async trackGeneral(data: ICommonTrack): Promise<IHttpWrap<void>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/event/general'),
      method: 'POST',
      data: {
        channel: 'WEB',
        event_ts: Date.now(),
        ...data,
      },
    })
  }
}
