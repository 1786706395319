import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { BIcon } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import type { IAggregationEntityValue } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { renderDisplayNameWithTitle } from '../../../utils/render'
export const FilterOrgItemAdvance = defineComponent({
  name: 'FilterOrgItemAdvance',
  props: {
    data: {
      required: true,
      type: Object as PropType<IAggregationValue>,
    },
    isShowLogo: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { locale } = useLocale()
    return { locale }
  },
  render() {
    const _data = this.data as IAggregationEntityValue
    return (
      <div class="flex items-center overflow-hidden">
        {this.isShowLogo && <BIcon class="mr-1" type="company" src={_data?.logo_s3_path || _data?.logo || ''}></BIcon>}
        <span class="flex-1 truncate">{renderDisplayNameWithTitle(this.locale, _data, _data.start_up)}</span>
      </div>
    )
  },
})
