import { IQueryDataEntityType } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { BDiseaseFetchCard } from './BDiseaseCard/BDiseaseFetchCard'
import { BDrugFetchCard } from './BDrugCard/BDrugFetchCard'
import { BOrganizationFetchCard } from './BOrganizationCard/BOrganizationFetchCard'
import { BTargetFetchCard } from './BTargetCard/BTargetFetchCard'

export const BEntityFetchCard = defineComponent({
  name: 'BEntityFetchCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<Exclude<IQueryDataEntityType, 'mechanism'>>,
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  render() {
    if (this.type === 'disease') {
      return (
        <BDiseaseFetchCard
          key={`${this.id}_${this.type}`}
          width={this.width}
          onLoaded={() => this.$emit('loaded')}
          id={this.id}
          direction={this.direction}
          border={this.border}
        ></BDiseaseFetchCard>
      )
    } else if (this.type === 'drug') {
      return (
        <BDrugFetchCard
          key={`${this.id}_${this.type}`}
          width={this.width}
          onLoaded={() => this.$emit('loaded')}
          id={this.id}
          direction={this.direction}
          border={this.border}
        ></BDrugFetchCard>
      )
    } else if (this.type === 'target') {
      return (
        <BTargetFetchCard
          key={`${this.id}_${this.type}`}
          width={this.width}
          onLoaded={() => this.$emit('loaded')}
          id={this.id}
          direction={this.direction}
          border={this.border}
        ></BTargetFetchCard>
      )
    } else if (this.type === 'organization') {
      return (
        <BOrganizationFetchCard
          key={`${this.id}_${this.type}`}
          width={this.width}
          onLoaded={() => this.$emit('loaded')}
          id={this.id}
          direction={this.direction}
          border={this.border}
        ></BOrganizationFetchCard>
      )
    }

    return <div></div>
  },
})
