import { IHttp, IRequestConfig } from '../http/types'
import { BaseApi } from './baseApi'

export class TranslateApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async translateContent(__: undefined, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl('ls360_webapi/1.0/translate'),
      cache: true,
      method: 'POST',
    })
  }
}
