import { IAlertConfig, IAlertType, ICreateUpdateAlertParams } from '@patsnap/synapse_common_interface'
import { checkIsSearchEmailAlert, toThousands } from '@patsnap/synapse_common_utils'
import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { useLs360SynapseWujieEvents } from '@pharmsnap/pharmsnap-web/composable/useLs360SynapseWujieEvents'
import { E_ROUTER_NAME, E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale, userErrorMessage } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElSwitch } from '@pharmsnap/shared/element-ui'
import { useEmailAlertDialog } from '@pharmsnap/shared/src/composition/useEmailAlertDialog'
import { checkAlertTypeOnlyForBasicRole } from '@pharmsnap/shared/src/utils/business/emailAlert/email-alert-v2'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GTooltip } from '../..'
import $classes from './BEmailAlertWithSwitchV2.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BEmailAlertWithSwitchV2 = defineComponent({
  name: 'BEmailAlertWithSwitchV2',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    defaultEmailAlertConfig: {
      type: Object as PropType<Partial<ICreateUpdateAlertParams>>,
      default: () => ({ alert_type: 'drug_detail' }),
    },
    searchResultCount: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const { bus } = useLs360SynapseWujieEvents()
    const { state: ls360ChatState, actions: ls360ChatActions } = useLs360ChatStore()
    const {
      getters: { isFreeUser },
      actions,
    } = useAuthStore()
    const { showEmailAlertDialog } = useEmailAlertDialog()
    const { showErrorMessageByToast } = userErrorMessage()
    const { $t, isCN } = useLocale()
    const searchResultLimitMap: Partial<Record<IAlertType, number>> = {
      drug_search: 20000,
      clinical_trial_result_search: 20000,
      clinical_trial_search: 200000,
      translational_medicine_search: 20000,
      drug_deal_search: 5000,
      patent_search: 500000,
    }
    const emailConfig = ref<Partial<ICreateUpdateAlertParams>>({ ...props.defaultEmailAlertConfig })
    const alertType = computed(() => {
      return emailConfig.value?.alert_type as IAlertType
    })
    const disableReason = computed(() => {
      const _alertType = alertType.value
      const limitCount = searchResultLimitMap[_alertType]
      if (checkIsSearchEmailAlert(_alertType) && limitCount && props.searchResultCount > limitCount) {
        return isCN.value
          ? `当前检索结果数量超过${toThousands(limitCount)}，不可使用此功能`
          : `The current search results exceed ${toThousands(limitCount)}, and this feature is temporarily unavailable`
      }
      return ''
    })
    /**
     * switch状态
     */
    const innerAlertStatus = ref(false)
    const loading = ref(false)
    function updateLs360ChatHasSetAlert(hasSetAlert = false, opened = false) {
      if (opened && !hasSetAlert) {
        throw new Error('opened为true时，hasSetAlert必须为true')
      }
      const notSupportReason = isCN.value ? '邮件提醒已设置' : 'Alert has been set'
      ls360ChatActions.updateEmailAlertStatus({
        support: true,
        hasSetAlert,
        opened,
        notSupportReason: hasSetAlert && opened ? notSupportReason : '',
        notSupportTooltip: hasSetAlert && opened ? notSupportReason : '',
      })
    }
    function showEmailDialog(mode: 'detail' | 'update') {
      showEmailAlertDialog({
        componentProps: {
          mode,
          defaultEmailAlertConfig: emailConfig.value,
          searchResultCount: props.searchResultCount,
        },
        onSubmitSuccessCallBack: (emailConfig: IAlertConfig) => {
          updateLs360ChatHasSetAlert(true, true)
          emit('alertStatusChange', true)
          onSubmitSuccess(emailConfig)
        },
        onCloseCallBack: () => {
          emit('alertStatusChange', false)
        },
      })
    }
    async function handleAlertStatusChange() {
      if (props.disabled || disableReason.value) {
        return
      }
      if (loading.value) {
        return
      }
      const val = !innerAlertStatus.value

      if (val) {
        if (emailConfig.value.alert_type && checkAlertTypeOnlyForBasicRole(emailConfig.value.alert_type) && isFreeUser.value) {
          actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.EXCEEDS_FEATURE_MAX_LIMIT, trigger_point: 'SET_ALERT' })
          return
        }
        showEmailDialog(emailConfig.value.alert_id ? 'update' : 'detail')
      } else {
        // 关闭
        if (emailConfig.value.alert_id) {
          const res = await sharedCtx.service.emailAlert.toggleEmailAlertActiveStatus({ alert_id: emailConfig.value.alert_id, status: false })
          if (res.success) {
            showSingleToast({
              message: $t('BEmailAlert.closeSuccess'),
              type: 'info',
            })
            innerAlertStatus.value = val
          } else {
            const { numeric_error_code, error_message } = res.data
            showErrorMessageByToast(numeric_error_code, error_message)
          }
        }
      }
    }

    async function initData() {
      if (!emailConfig.value.entity && !emailConfig.value.query) {
        return
      }
      loading.value = true

      const res = await sharedCtx.service.emailAlert.checkEmailAlertExistV2({
        alert_type: alertType.value,
        entity: emailConfig.value.entity,
        query: emailConfig.value.query,
      })
      loading.value = false
      if (res.success && res.data.exist && res.data.item) {
        const item = res.data.item
        innerAlertStatus.value = item.status || false
        emailConfig.value = item
      } else {
        // innerAlertStatus.value = false
      }
    }
    initData()
    watch(
      innerAlertStatus,
      (val) => {
        if (val) {
          updateLs360ChatHasSetAlert(true, true)
        } else {
          updateLs360ChatHasSetAlert(true, false)
        }
      },
      {
        immediate: true,
      }
    )
    watch(
      () => props.defaultEmailAlertConfig,
      async (val) => {
        emailConfig.value = val
      }
    )

    /**
     * 创建邮件提醒或者更新邮件提醒成功
     */
    function onSubmitSuccess(_emailConfig?: IAlertConfig) {
      const href = ins?.proxy.$router.resolve({ name: E_ROUTER_NAME.EMAIL_ALERT }).href
      const link = `<a href="${href}" class="underline">${ins?.proxy.$t('BEmailAlert.gotoEmailAlertCenter')}</a>`
      const msg = emailConfig.value.alert_id ? ins?.proxy.$t('BEmailAlert.openSuccess') : ins?.proxy.$t('BEmailAlert.createSuccess', { link })
      showSingleToast({
        message: msg as string,
        dangerouslyUseHTMLString: true,
        type: 'success',
      })
      innerAlertStatus.value = true
      if (_emailConfig) {
        emailConfig.value = _emailConfig
      }
    }
    function onClickChatShowEmailDialog() {
      showEmailDialog(emailConfig.value.alert_id ? 'update' : 'detail')
    }
    onMounted(() => {
      bus.$on('micro-ls360-synapse:create-alert', onClickChatShowEmailDialog)
    })
    onBeforeUnmount(() => {
      bus.$off('micro-ls360-synapse:create-alert', onClickChatShowEmailDialog)
    })
    return {
      disableReason,
      innerAlertStatus,
      handleAlertStatusChange,
    }
  },
  render() {
    return (
      <GTooltip content={this.disableReason} disabled={!this.disableReason}>
        <div
          class={[$classes.switch, { 'cursor-not-allowed': this.disabled || this.disableReason }]}
          onClick={this.handleAlertStatusChange}
          data-testid="b-email-alert-with-switch"
        >
          <PtIcon icon="SolidEmailAlerts" class={['text-2xl', this.disabled || this.disableReason ? 'text-gray-90' : 'text-gray-450']}></PtIcon>
          <span class={['mr-1 text-sm', this.disabled || this.disableReason ? 'text-text-t2' : 'text-text-default']}>
            {this.$t('BEmailAlert.setAlert')}
          </span>
          <ElSwitch class={['w-8']} disabled={this.disabled || !!this.disableReason} value={this.innerAlertStatus}></ElSwitch>
        </div>
      </GTooltip>
    )
  },
})
