import { drugPatentTypeWeightMap } from '@pharmsnap/shared/config'
import { sharedCtx } from '@pharmsnap/shared/context'
import { HOT_TAG_SCENE_TYPE, IBAcTag, IHotTagType, IListItem, ISelectedLabel } from '@pharmsnap/shared/types'
import { FinderInputKey } from '../autocomplete'
interface IBaseFinderData {
  allFieldItems: IBAcTag[]
  drugItems: IBAcTag[]
  organizationItems: IBAcTag[]
  targetItems: IBAcTag[]
  indicationItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  organizationTypeItems: IBAcTag[]
  employeeNumberItems: IBAcTag[]
  therapeuticAreasItems: IBAcTag[]
  selectedHotTagItems: IBAcTag[]
}

export interface IDrugFinderData extends IBaseFinderData {
  mechanismItems: IBAcTag[]
  atcItems: IBAcTag[]
  approvedDateRange: string[]
  highestPhaseItems: IBAcTag[]
  phaseItems: IBAcTag[]
  approvedCountryItems: IBAcTag[]
  regulationItems: IBAcTag[]
  adcAntibodyItems: IBAcTag[]
  adcLinkerItems: IBAcTag[]
  adcPayloadItems: IBAcTag[]
  startupItems: IBAcTag[]
  dealExistItems: Array<Omit<IBAcTag, 'searchQuery' | 'child'>>
}

export interface IClinicalFinderData {
  allFieldItems: IBAcTag[]
  clinicalDrugItems: IBAcTag[]
  organizationItems: IBAcTag[]
  targetItems: IBAcTag[]
  indicationItems: IBAcTag[]
  employeeNumberItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  selectedHotTagItems: IBAcTag[]
  organizationTypeItems: IBAcTag[]
  nctItems: IBAcTag[]
  studyCodeItems: IBAcTag[]
  therapeuticAreasItems: IBAcTag[]
  clinicalTrialItems: IBAcTag[]
  mechanismItems: IBAcTag[]
  recruitmentStatusItems: IBAcTag[]
  studyPhaseItems: IBAcTag[]
  estimatedEnrollmentItems: IBAcTag[]
  selectedDateRangeItems: IBAcTag[]
  selectedFirstPostDateRangeItems: IBAcTag[]
  controlDrugItems: IBAcTag[]
  sponsorCountryItems: IBAcTag[]
}

export interface IClinicalResultFinderData {
  experimentalDrugItems: IBAcTag[]
  indicationItems: IBAcTag[]
  targetItems: IBAcTag[]
  sponsorItems: IBAcTag[]
  studyCodeItems: IBAcTag[]
  trialNumberItems: IBAcTag[]
  allFieldItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  controlDrugItems: IBAcTag[]
  therapeuticAreasItems: IBAcTag[]
  mechanismItems: IBAcTag[]
  meetingsItems: IBAcTag[]
  outcomeItems: IBAcTag[]
  therapyTypeItems: IBAcTag[]
  studyPhaseItems: IBAcTag[]
  publishedDateRange: string[]
  evaluationItems: IBAcTag[]
  analyzedParticipantsItems: IBAcTag[]
}

export interface IDrugDealFinderData {
  allFieldItems: IBAcTag[]
  drugItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  transferorItems: IBAcTag[]
  acquirerItems: IBAcTag[]
  targetItems: IBAcTag[]
  mechanismItems: IBAcTag[]
  indicationItems: IBAcTag[]
  highestPhaseItems: IBAcTag[]
  dealCountryItems: IBAcTag[]
  agreementTypeItems: IBAcTag[]
  amountDisclosureItems: IBAcTag[]
  totalPaymentItems: IBAcTag[]
  upfrontPaymentItems: IBAcTag[]
  milestoneItems: IBAcTag[]
  dealDateItems: IBAcTag[]
  drugIndicationItems: IBAcTag[]
  dealIndicationItems: IBAcTag[]
  dealDirectionItems: IBAcTag[]
  dealPrinciplePropertyItems: IBAcTag[]
  dealPartnerPropertyItems: IBAcTag[]
}

export interface ITransMedicineFinderData {
  translationalMedicineDrugItems: IBAcTag[]
  targetItems: IBAcTag[]
  indicationItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  mechanismItems: IBAcTag[]
  organizationItems: IBAcTag[]
  allFieldItems: IBAcTag[]
  subjectItems: IBAcTag[]
  translationStageItems: IBAcTag[]
  publicationDateItems: IBAcTag[]
  journalItems: IBAcTag[]
  meetingsItems: IBAcTag[]
}

export interface IPatentFinderData {
  allFieldItems: IBAcTag[]
  drugItems: IBAcTag[]
  targetItems: IBAcTag[]
  indicationItems: IBAcTag[]
  organizationItems: IBAcTag[]
  assigneeTypeItems: IBAcTag[]
  authorityCountryItems: IBAcTag[]
  simpleLegalStatusItems: IBAcTag[]
  applicationDateItems: IBAcTag[]
  publicationDateItems: IBAcTag[]
  estimatedExpiryDateItems: IBAcTag[]
  drugPatentTypeItems: IBAcTag[]
  attributeItems: IBAcTag[]
  patentNumberItems: IBAcTag[]
  patentTechnologyItems: IBAcTag[]
  coreDrugSourceItems: IBAcTag[]
  /** 发明人 */
  inventorItems: IBAcTag[]
  /** 药物类型 */
  drugTypeItems: IBAcTag[]
}

export interface ILiteratureFinderData {
  allFieldItems: IBAcTag[]
  drugItems: IBAcTag[]
  organizationItems: IBAcTag[]
  targetItems: IBAcTag[]
  indicationItems: IBAcTag[]
  drugTypeItems: IBAcTag[]
  authorItems: IBAcTag[]
  journalItems: IBAcTag[]
  publicationYearRange: IBAcTag[]
  customPublicationYearRange: IBAcTag[]
}

/**
 * browse提交
 * @param key
 * @param list
 * @returns
 */
export const handleBrowseSubmit = (key: FinderInputKey, list: ISelectedLabel[]) => {
  const browseData: IBAcTag[] = list.map((o) => {
    const nameParts = {
      name_cn: o.name_cn || '',
      name_en: o.name_en || '',
    }
    if (key === 'targetItems') {
      nameParts.name_cn = o.entity_name_cn || ''
      nameParts.name_en = o.entity_name_en || ''
    }

    if (key === 'adcAntibodyItems' || key === 'adcLinkerItems' || key === 'adcPayloadItems') {
      nameParts.name_cn = o.name_en || nameParts.name_cn || ''
      nameParts.name_en = o.name_en || ''
    }

    if (key === 'meetingsItems') {
      o.search_strategy = 'ID'
    }

    return {
      ...o,
      name: o.label,
      id: o.id,
      name_cn: nameParts.name_cn,
      name_en: nameParts.name_en,
      logo_s3_path: o.icon === 'Tree' ? undefined : o.logo_s3_path || o.logo,
    }
  })
  return browseData
}

/**
 * 获取热门标签
 * @param params
 * @returns
 */
export const getFinderHotTags = async (params: { scene_type: HOT_TAG_SCENE_TYPE }) => {
  const res = await sharedCtx.service.tag.getHotTags(params)
  if (res.success) {
    const items = res.data?.hot_tag_map[params.scene_type] || []
    const hotTags = items.map((o) => {
      return {
        is_hot: !!o?.if_hot,
        id: o.data_id,
        name_en: o.data_name_en,
        name_cn: o.data_name_cn,
        dict_type: o.dict_type as IHotTagType,
      }
    })
    return hotTags
  }
  return []
}

/**
 * 获取治疗领域的顶层数据(含others)
 * @returns
 */
export async function getTherapeuticAreasTags() {
  let result: IListItem[] = []
  const res = await sharedCtx.service.disease.getTherapeuticAreasTags()
  if (res.success) {
    result =
      res.data.map((item) => {
        return {
          id: item.id,
          name_en: item.name_en || '',
          name_cn: item.name_cn || item.name_en || '',
        }
      }) ?? []
  }
  return result
}

/**
 * 核心药物类型
 * @returns
 */
export async function getDrugPatentTypeTags() {
  let result: (IListItem & { weight: number })[] = []
  const res = await sharedCtx.service.dictionary.searchTreeWithoutLimit({ dict_type: 'PATENT_TYPE', offset: 0, limit: 100 })
  if (res.success) {
    result =
      res.data.items
        .filter((o) => drugPatentTypeWeightMap[o.id])
        .map((item) => {
          return {
            id: item.id,
            weight: drugPatentTypeWeightMap[item.id] || 0,
            name_en: item.name_en || '',
            name_cn: item.name_cn || item.name_en || '',
          }
        }) ?? []
  }
  return result.sort((a, b) => a.weight - b.weight)
}
/**
 * 专利信息源
 * 需求来源: https://confluence.zhihuiya.com/display/DLS/SNP+202403+Online+UAT
 * @returns
 */
export async function getPatentCoreDrugSource(): Promise<IListItem[]> {
  return [
    {
      id: 'd18af66d018b35a19d3ce5078f863e2c',
      name_en: 'FDA Orange Book',
      name_cn: 'FDA橙皮书',
    },
    {
      id: 'fe94e7420133347c99a701b933dfb5cd',
      name_en: 'FDA Purple Book',
      name_cn: 'FDA紫皮书',
    },
    {
      id: 'f8e054e3416d372e874492e25c38b3ec',
      name_en: 'CDE',
      name_cn: '中国上市药品专利信息登记平台',
    },
    {
      id: '0608bb50f1183d09b5643165e0d750ab',
      name_en: 'MedsPal',
      name_cn: 'MedsPal',
    },
    {
      id: '0b545d8e53633446bb7ae59c3753b688',
      name_en: 'VaxPal',
      name_cn: 'VaxPal',
    },
    {
      id: '38ff6277d5243f41abde0f5fec4284f2',
      name_en: 'MFDS',
      name_cn: '韩国药监局',
    },
  ]
}

/**
 * 专利技术类型
 * @returns
 */
export async function getPatentTechnologyTags() {
  return getHotTags('PATENT_TECH_TYPE')
}

export async function getHotTags(sceneType: HOT_TAG_SCENE_TYPE) {
  const res = await getFinderHotTags({ scene_type: sceneType })
  return res.map((item) => {
    return {
      id: item.id,
      name_en: item.name_en || item.name_cn,
      name_cn: item.name_cn || item.name_en,
      icon: item.is_hot ? 'Fire' : undefined,
    }
  })
}

export async function getClinicalResultMeetingTags() {
  return getHotTags('CT_RESULT_CONFERENCE')
}

export const convertItem2Tag = (key: FinderInputKey, item: IListItem, isKeyword = false): IBAcTag => {
  if (
    key === 'allFieldItems' ||
    key === 'attributeItems' ||
    key === 'patentNumberItems' ||
    isKeyword ||
    key === 'studyCodeItems' ||
    key === 'trialNumberItems' ||
    key === 'outcomeItems'
  ) {
    return convert2NoIcon(item, isKeyword)
  }
  if (
    key === 'experimentalDrugItems' ||
    key === 'controlDrugItems' ||
    key === 'clinicalDrugItems' ||
    key === 'drugItems' ||
    key === 'mechanismItems' ||
    key === 'nctItems' ||
    key === 'clinicalTrialItems' ||
    key === 'authorItems' ||
    key === 'journalItems' ||
    key === 'adcAntibodyItems' ||
    key === 'translationalMedicineDrugItems'
  ) {
    const tag = convert2SearchId(item, isKeyword)

    if (key === 'adcAntibodyItems') {
      tag.icon = undefined
      tag.name_cn = item.name_en || item.name_en
    }
    return tag
  } else {
    const tag = convert2SearchRollUp(item, isKeyword)
    if (key === 'adcLinkerItems' || key === 'adcPayloadItems') {
      tag.name_cn = item.name_en || item.name_en
    }
    return tag
  }
}

export function convert2NoIcon(item: IListItem, isKeyword = false): IBAcTag {
  return { ...item, icon: '', itemType: undefined, logo_s3_path: '', isKeyword }
}

export function convert2SearchId(item: IListItem, isKeyword = false): IBAcTag {
  return { ...item, search_strategy: 'ID', isKeyword }
}

export function convert2SearchRollUp(item: IListItem, isKeyword = false): IBAcTag {
  return { ...item, icon: 'Tree', search_strategy: 'ID_ROLLUP', logo_s3_path: '', isKeyword }
}

export function generateAllTag() {
  return { name_en: 'All', name_cn: 'All', id: 'All' }
}
