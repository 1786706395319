/**
 * 设置cookie
 * @param name
 * @param value
 * @param expires
 */
export function setCookieInBrowser(
  /** cookie名 */
  name: string,
  /** cookie值 */
  value: string,
  /** cookie过期时间,单位为毫秒 */
  expires: number
) {
  const exp = new Date()
  exp.setTime(exp.getTime() + expires)
  document.cookie = `${name}=${value}; expires=${exp.toUTCString()}; path=/`
}
