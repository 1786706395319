import { ALL_SUBJECT_CONFIG_MAP } from '@patsnap/synapse_common_config'
import { I18nLang, IAggregationData } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { createImgPromise, generateChartImage } from '@pharmsnap/pharmsnap-web/utils/generateChartImage'
import { IResearchDirectionChartEntityItem } from '@pharmsnap/shared/src/types/translational-medicine'
import { sortSubject } from '../../subject'
import { getChartWaterMark } from '../../waterMark'
import { extractSpecifiedItemsFromAggResult, getSecondAggItemsMaxLength } from './trans-medicine-chart'

const DEFAULT_FONT_FAMILY = 'Roboto,Helvetica Neue,Arial,PingFang SC,Microsoft YaHei,微软雅黑,sans-serif'

const MIN_TABLE_WIDTH = 400

const LEGEND_ITEM_STYLE_CONFIG = {
  padding: 4, // 标签和文本边距
  margin: 12, // 标签之间间距
  lineHeight: 12, // 行高
  tagWidth: 10, // 图例宽度
  tagHeight: 10, // 图例高度
  font: `12px ${DEFAULT_FONT_FAMILY}`, // 字体
}

// 绘制单元格边框
function drawCellBorder(ctx: CanvasRenderingContext2D | null, config: { x: number; y: number; width: number; height: number; background?: string }) {
  if (ctx) {
    const { x, y, width, height, background } = config
    if (background) {
      // 设置单元格背景颜色
      ctx.fillStyle = background
      // 绘制带有背景色的单元格
      ctx.fillRect(x, y, width, height)
    }

    ctx.beginPath()
    ctx.rect(x, y, width, height)
    ctx.lineWidth = 1
    ctx.strokeStyle = '#DFE1E6'
    ctx.stroke()
  }
}

// 绘制单元格文本
function drawCellText(
  ctx: CanvasRenderingContext2D | null,
  config: {
    x: number
    y: number
    width: number
    height: number
    text: string
    textAlign?: CanvasTextAlign
    baseline?: CanvasTextBaseline
    font?: string
  }
) {
  if (ctx) {
    const { x, y, width, height, text, textAlign = 'center', baseline = 'middle' } = config

    ctx.font = `12px ${DEFAULT_FONT_FAMILY}`
    ctx.textAlign = textAlign
    ctx.textBaseline = baseline
    ctx.fillStyle = '#020A1A'

    // 计算文本宽度
    const textWidth = ctx.measureText(text).width
    let drawText = text

    // 检查文本是否超出了单元格宽度
    if (textWidth > width) {
      const ellipsis = '...'
      const ellipsisWidth = ctx.measureText(ellipsis).width
      const fitWidth = width - ellipsisWidth
      let newText = text

      // 逐渐减少文本长度直到它加上省略号的宽度适应单元格宽度
      while (ctx.measureText(newText).width > fitWidth && newText.length > 1) {
        newText = newText.substring(0, newText.length - 1)
      }

      // 添加省略号
      drawText = newText.trim() + ellipsis
    }

    // 绘制调整后的文本
    let drawX = x
    const drawY = y + height / 2

    // 根据textAlign调整绘制起点
    if (textAlign === 'center') {
      drawX += width / 2
    } else if (textAlign === 'right') {
      drawX += width
    }

    ctx.fillText(drawText, drawX, drawY)
  }
}

// 绘制圆环
function drawRingChart(
  ctx: CanvasRenderingContext2D | null,
  data: Array<{ value: number; color: string }>,
  text: string | undefined,
  config: { x: number; y: number; width: number; height: number; radius?: number }
) {
  if (ctx) {
    const { x, y, width, height, radius = 24 } = config

    const centerX = x + width / 2
    const centerY = y + height / 2

    const outerRadius = radius
    const innerRadius = outerRadius * 0.6

    const totalValue = data.reduce((sum, item) => sum + item.value, 0)
    let startAngle = -Math.PI / 2

    // 绘制圆环中间的文本
    if (text) {
      drawCellText(ctx, { x, y, width, height, text })
    }

    for (const { value, color } of data) {
      const endAngle = startAngle + (Math.PI * 2 * value) / totalValue
      ctx.beginPath()
      ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle)
      ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true)
      ctx.closePath()
      ctx.fillStyle = color
      ctx.fill()
      startAngle = endAngle
    }

    // 绘制圆环图边框
    ctx.beginPath()
    ctx.arc(centerX, centerY, outerRadius, 0, Math.PI * 2)
  }
}

// 绘制水印
async function drawWatermark(ctx: CanvasRenderingContext2D | null, width: number, height: number, locale: I18nLang) {
  if (ctx) {
    const { waterMark, imgHeight, imgWidth } = getChartWaterMark(locale === 'cn')

    const img = await createImgPromise({ imgSrc: waterMark })

    ctx.drawImage(img.img, (width - imgWidth) / 2, (height - imgHeight) / 2, imgWidth, imgHeight)
  }
}

// 占位元素：用于计算文字宽度
let placeholderDivEle: HTMLDivElement | null = null

function getTextWidth(text: string, font: string) {
  try {
    if (!placeholderDivEle) {
      placeholderDivEle = document.createElement('div')
      placeholderDivEle.style.position = 'fixed'
      placeholderDivEle.style.zIndex = '-9999'
      placeholderDivEle.style.top = '-999px'
      document.body.appendChild(placeholderDivEle)
    }

    placeholderDivEle.style.font = font
    placeholderDivEle.textContent = text

    return placeholderDivEle.offsetWidth
  } catch (error) {
    /* empty */
    return 0
  }
}

function getLegendHeight(
  items: Array<{ name: string; color: string }>,
  config: {
    containerWidth: number
    padding: number
    margin: number
    lineHeight: number
    font: string
    tagWidth: number
  }
) {
  const { containerWidth, padding, margin, lineHeight, font, tagWidth } = config

  let height = 0
  let startX = 0

  items.forEach((item) => {
    const textWidth = getTextWidth(item.name, font)

    // 检查是否需要换行
    if (startX + tagWidth + textWidth + padding + margin > containerWidth) {
      height += lineHeight + margin
      startX = 0
    }
    // 更新下一个标签的起始X坐标
    startX += tagWidth + textWidth + padding + margin
  })
  return height + lineHeight + margin // 返回图例高度
}

// 绘制图例
function drawLegend(
  ctx: CanvasRenderingContext2D | null,
  items: Array<{ name: string; color: string }>,
  config: {
    x: number
    y: number
    width: number
    padding: number
    margin: number
    lineHeight: number
    font: string
    tagWidth: number
    tagHeight: number
  }
) {
  if (ctx) {
    const { x, y, width, padding, margin, lineHeight, tagWidth, tagHeight, font } = config
    let startX = x // 起始X坐标
    let startY = y // 起始Y坐标

    ctx.font = font

    items.forEach((item) => {
      const textWidth = getTextWidth(item.name, font)

      // 检查是否需要换行
      if (startX + tagWidth + textWidth + padding + margin > width) {
        startX = x // 重置X坐标到起始位置
        startY += lineHeight + margin // Y坐标下移
      }

      // 绘制标签背景
      ctx.fillStyle = item.color
      ctx.fillRect(startX, startY - tagHeight / 2, tagWidth, tagHeight)

      // 绘制标签文本
      ctx.fillStyle = '#000000' // 文本颜色设置为黑色
      ctx.textBaseline = 'middle' // 文本垂直对齐方式为middle
      ctx.fillText(item.name, startX + tagWidth + padding, startY)

      // 更新下一个标签的起始X坐标
      startX += tagWidth + textWidth + padding + margin
    })
  }
}

// 导出研究趋势图片
export async function exportTransMedicineResearchTrendChart(
  aggData: IAggregationData,
  title: string,
  locale: I18nLang,
  showWatermark = false,
  userInfo?: {
    userId: string
  }
) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.fillStyle = '#FFF'

    // 设置缩放，防止图模糊
    ctx.scale(1.5, 1.5)

    const padding = 16
    const tableRows = getSecondAggItemsMaxLength(aggData)
    const tableCols = aggData.aggregation_result[0].items.length
    let cellWidth = 132
    const cellHeight = 84
    const headerCellHeight = 32

    const actualTableWidth = cellWidth * tableCols

    const tableWidth = Math.max(actualTableWidth, MIN_TABLE_WIDTH)

    if (tableWidth !== actualTableWidth) {
      cellWidth = tableWidth / tableCols
    }

    canvas.width = padding * 2 + tableWidth

    const startX = (canvas.width - tableWidth) / 2

    const legendItems = extractSpecifiedItemsFromAggResult(aggData.aggregation_result || [], 'SUBJECT_ID')
      .sort((a, b) => sortSubject(a.key, b.key))
      .map((item) => ({
        name: getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, locale),
        color: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2',
      }))

    const legendHeight = getLegendHeight(legendItems, {
      containerWidth: canvas.width - startX,
      ...LEGEND_ITEM_STYLE_CONFIG,
    })

    const tableHeight = cellHeight * tableRows + headerCellHeight
    canvas.height = padding * 2 + tableHeight + legendHeight

    // 绘制图例
    drawLegend(ctx, legendItems, { x: startX, y: padding, width: canvas.width, ...LEGEND_ITEM_STYLE_CONFIG })

    const tableStartY = legendHeight + padding

    // 绘制表头
    for (let col = 0; col < tableCols; col++) {
      const x = startX + col * cellWidth
      const y = tableStartY

      drawCellBorder(ctx, { x, y, width: cellWidth, height: headerCellHeight, background: '#F4F5F7' })
      drawCellText(ctx, {
        x,
        y,
        width: cellWidth,
        height: headerCellHeight,
        text: aggData?.aggregation_result?.[0]?.items?.[col]?.key || '',
      })
    }

    // 绘制表格
    for (let row = 0; row < tableRows; row++) {
      for (let col = 0; col < tableCols; col++) {
        const x = startX + col * cellWidth
        const y = tableStartY + row * cellHeight + headerCellHeight // 加上一行表头的高度
        drawCellBorder(ctx, { x, y, width: cellWidth, height: cellHeight })
        const cellData = aggData?.aggregation_result?.[0]?.items?.[col]?.aggregation_result?.[0]?.items?.[row]
        const ringData = cellData?.aggregation_result?.[0]?.items?.map((item) => ({
          value: item.count,
          color: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2',
        })) || [{ value: 1, color: '#DFE1E6' }]
        const textHeight = 20
        const ringHeight = cellHeight - textHeight

        drawRingChart(ctx, ringData, cellData?.count ? `${cellData.count}` : '', { x, y, width: cellWidth, height: ringHeight })
        drawCellText(ctx, {
          x,
          y: y + ringHeight,
          width: cellWidth,
          height: textHeight,
          text: getLangDegraded({ name_cn: cellData?.display_name_cn, name_en: cellData?.display_name_en }, locale) || '',
        })
      }
    }

    // 绘制水印
    if (showWatermark) {
      await drawWatermark(ctx, canvas.width, canvas.height, locale)
    }

    await generateChartImage({
      chartUrl: canvas.toDataURL(),
      width: canvas.width,
      height: canvas.height,
      addHeight: 38,
      name: title,
      isCN: locale === 'cn',
      userInfo,
    })
  }
}

// 导出研究方向图片
export async function exportTransMedicineResearchDirectionChart(
  aggData: IAggregationData,
  otherInfo: {
    expandData?: IResearchDirectionChartEntityItem
    researchDirectionXDirectionList: IResearchDirectionChartEntityItem[]
    researchDirectionYDirectionList: IResearchDirectionChartEntityItem[]
  },
  title: string,
  locale: I18nLang,
  showWatermark = false,
  userInfo?: {
    userId: string
  }
) {
  const { expandData, researchDirectionXDirectionList, researchDirectionYDirectionList } = otherInfo

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.fillStyle = '#FFF'

    const padding = 16
    let cellWidth = 132
    const cellHeight = 64
    const singleHeaderCellHeight = 32
    const headerCellHeight = expandData ? singleHeaderCellHeight * 2 : singleHeaderCellHeight

    const tableRows = researchDirectionYDirectionList.length
    const tableCols = researchDirectionXDirectionList.length
    // 表格实际宽度
    const actualTableWidth = cellWidth * (tableCols + 1) // 加上左侧一列实体的宽度
    // 绘画的表格宽度
    const tableWidth = Math.max(actualTableWidth, MIN_TABLE_WIDTH)

    if (tableWidth !== actualTableWidth) {
      cellWidth = tableWidth / (tableCols + 1)
    }

    canvas.width = padding * 2 + tableWidth

    const startX = (canvas.width - tableWidth) / 2

    const legendItems = extractSpecifiedItemsFromAggResult(aggData.aggregation_result || [], 'SUBJECT_ID')
      .sort((a, b) => sortSubject(a.key, b.key))
      .map((item) => ({
        name: getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, locale),
        color: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2',
      }))

    const legendHeight = getLegendHeight(legendItems, {
      containerWidth: canvas.width - startX,
      ...LEGEND_ITEM_STYLE_CONFIG,
    })

    const tableHeight = cellHeight * tableRows + headerCellHeight
    canvas.height = padding * 2 + tableHeight + legendHeight

    // 绘制图例
    drawLegend(ctx, legendItems, { x: startX, y: padding, width: canvas.width, ...LEGEND_ITEM_STYLE_CONFIG })

    const tableStartY = padding + legendHeight
    // 绘制左上角第一个空格
    drawCellBorder(ctx, { x: startX, y: tableStartY, width: cellWidth, height: headerCellHeight, background: '#F4F5F7' })

    // 绘制跨行表头
    if (expandData) {
      const spanHeaderStartX = startX + cellWidth
      const spanHeaderWidth = cellWidth * tableCols
      drawCellBorder(ctx, {
        x: spanHeaderStartX,
        y: tableStartY,
        width: spanHeaderWidth,
        height: singleHeaderCellHeight,
        background: '#F4F5F7',
      })

      drawCellText(ctx, {
        x: spanHeaderStartX,
        y: tableStartY,
        width: spanHeaderWidth,
        height: singleHeaderCellHeight,
        text: getLangDegraded(expandData, locale) || '',
      })
    }

    // 绘制实体表头
    for (let col = 0; col < tableCols; col++) {
      const x = startX + cellWidth + col * cellWidth
      const y = tableStartY + (expandData ? singleHeaderCellHeight : 0)

      drawCellBorder(ctx, { x, y, width: cellWidth, height: singleHeaderCellHeight, background: '#F4F5F7' })
      drawCellText(ctx, {
        x,
        y,
        width: cellWidth,
        height: singleHeaderCellHeight,
        text: getLangDegraded(researchDirectionXDirectionList[col], locale) || '',
      })
    }

    // 绘制表格
    for (let row = 0; row < tableRows; row++) {
      // tableCols + 1: 增加左侧第一列实体
      for (let col = 0; col < tableCols + 1; col++) {
        const x = startX + col * cellWidth
        const y = tableStartY + headerCellHeight + row * cellHeight // 加上表头的高度
        drawCellBorder(ctx, { x, y, width: cellWidth, height: cellHeight, background: col === 0 ? '#F4F5F7' : '' })

        if (col === 0) {
          const data = researchDirectionYDirectionList[row]
          drawCellText(ctx, {
            x,
            y,
            width: cellWidth,
            height: cellHeight,
            text: getLangDegraded(data, locale) || '',
          })
        } else {
          const oneDimData = aggData?.aggregation_result?.[0]?.items?.find((item) => item.key === researchDirectionYDirectionList[row].id)
          const twoDimData = oneDimData?.aggregation_result?.[0]?.items?.find((item) => item.key === researchDirectionXDirectionList[col - 1].id)

          const ringData = twoDimData?.aggregation_result?.[0]?.items?.map((item) => ({
            value: item.count,
            color: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2',
          })) || [{ value: 1, color: '#DFE1E6' }]
          const ringHeight = cellHeight

          drawRingChart(ctx, ringData, twoDimData?.count ? `${twoDimData.count}` : '', { x, y, width: cellWidth, height: ringHeight })
        }
      }
    }

    // 绘制水印
    if (showWatermark) {
      await drawWatermark(ctx, canvas.width, canvas.height, locale)
    }

    await generateChartImage({
      chartUrl: canvas.toDataURL(),
      width: canvas.width,
      height: canvas.height,
      addHeight: 38,
      name: title,
      isCN: locale === 'cn',
      userInfo,
    })
  }
}
