import '@patsnap-ui/icon/assets/solid/info.svg'
import { SyntaxTree, SyntaxTreeRecognize } from '@patsnap/query-translator'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import { GDialog, showGDialog } from '../../ui/GDialog/GDialog'
import $classes from './BEditQueryDialog.module.scss'
import { EditQueryContainer } from './EditQueryContainer'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BEditQueryDialog = defineComponent({
  name: 'BEditQueryDialog',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    queryStr: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    limit: {
      type: Number,
      default: 2000,
    },
  },
  setup(props, { emit }) {
    const syntaxTree = ref<SyntaxTree>()
    const editQueryContainer = ref()
    const ins = getCurrentInstance()

    const ExampleSearch = {
      or: `${ins?.proxy.$t('BEditQueryDialog.PD-1')} OR ${ins?.proxy.$t('BEditQueryDialog.PD-L1')}`,
      and: `${ins?.proxy.$t('BEditQueryDialog.PD-1')} AND ${ins?.proxy.$t('BEditQueryDialog.inhibitors')}`,
      not: `${ins?.proxy.$t('BEditQueryDialog.PD-1')} NOT ${ins?.proxy.$t('BEditQueryDialog.PD-L1')}`,
      parentheses: `(${ins?.proxy.$t('BEditQueryDialog.PD-1')} OR ${ins?.proxy.$t('BEditQueryDialog.PD-L1')}) AND (${ins?.proxy.$t(
        'BEditQueryDialog.inhibitors'
      )} OR ${ins?.proxy.$t('BEditQueryDialog.modulators')}) NOT (${ins?.proxy.$t('BEditQueryDialog.NSCLC')} OR ${ins?.proxy.$t(
        'BEditQueryDialog.BreastCancer'
      )})`,
    }

    const useExample = debounce((str: string) => {
      editQueryContainer.value.setQueryStr(str)
      showSingleToast({
        type: 'success',
        message: ins?.proxy.$t('BEditQueryDialog.displayMsg') as string,
      })
    }, 200)

    function onOpened() {
      editQueryContainer.value.setQueryStr(props.queryStr)
    }

    function onClose() {
      emit('close')
    }

    /**
     * 清空输入内容
     */
    function onClear() {
      if (syntaxTree.value?.isEmpty()) return
      showGDialog(
        {
          width: '400px',
          visible: true,
          confirmType: 'danger',
          confirmText: ins?.proxy.$tc('BEditQueryDialog.confirm'),
          showClose: false,
          onConfirm: onClearConfirm,
          component: defineComponent({
            render() {
              return <div class="text-text-default">{ins?.proxy.$tc('BEditQueryDialog.clearConfirm')}</div>
            },
          }),
        },
        ins?.proxy.$router,
        ins?.proxy.$i18n
      )
    }

    function onClearConfirm() {
      if (syntaxTree.value?.getSource()) {
        editQueryContainer.value.setQueryStr('')
      }
      emit('clear')
    }

    /** 判断是否有中文双引号 */
    function checkHasChineseQuotes() {
      const source = syntaxTree.value?.getSource()
      // 输入了中文双引号时，替换为英文双引号
      if (source && /[\u201c\u201d]/.test(source)) {
        const newSource = source.replace(/[\u201c\u201d]/g, '"')
        editQueryContainer.value.setQueryStr(newSource)
      }
    }

    /**
     * 提交
     */
    function onConfirm() {
      checkHasChineseQuotes()
      if (syntaxTree.value?.isValid()) {
        const query = syntaxTree.value ? SyntaxTreeRecognize.getInstance().trans2QueryItem(syntaxTree.value) : undefined
        emit('confirm', {
          query,
          source: syntaxTree.value?.getSource() || '',
        })
      }
    }

    function handleQueryInput(val: SyntaxTree) {
      syntaxTree.value = val
    }

    const isOverLimit = computed(() => {
      return !!syntaxTree.value && syntaxTree.value.getSource().length > props.limit
    })

    const confirmDisabled = computed(() => {
      if (!syntaxTree.value) return true
      return syntaxTree.value.isEmpty() || !syntaxTree.value.isValid() || isOverLimit.value
    })

    const curInputLength = computed(() => syntaxTree.value?.getSource().length || 0)
    const isFocus = ref(false)
    return {
      ExampleSearch,
      handleQueryInput,
      onOpened,
      onClose,
      onClear,
      onConfirm,
      onClearConfirm,
      useExample,
      confirmDisabled,
      editQueryContainer,
      curInputLength,
      isOverLimit,
      isFocus,
    }
  },
  methods: {
    renderExample(ex1: string, ex2: string, logic: 'AND' | 'OR' | 'NOT') {
      return (
        <span>
          {ex1}
          <span class="text-blue-default"> {logic} </span>
          {ex2}
        </span>
      )
    },
    renderExampleParentheses() {
      return (
        <span>
          ({this.renderExample(this.$tc('BEditQueryDialog.PD-1'), this.$tc('BEditQueryDialog.PD-L1'), 'OR')})
          <span class="text-blue-default"> AND </span>(
          {this.renderExample(this.$tc('BEditQueryDialog.inhibitors'), this.$tc('BEditQueryDialog.modulators'), 'OR')})
          <span class="text-blue-default"> NOT </span>(<span>{this.$t('BEditQueryDialog.NSCLC')}</span>
          <span class="text-blue-default"> OR </span>
          <span>{this.$t('BEditQueryDialog.BreastCancer')}</span>)
        </span>
      )
    },
    renderTipContent() {
      return (
        <div class="text-gray-1100">
          <div class="mt-4 mb-2">{this.$tc('BEditQueryDialog.tips')}</div>
          <div>
            <i18n path={'BEditQueryDialog.andOrLabel'} tag="span">
              <span place="and" class="text-blue-default">
                AND
              </span>
              <span place="or" class="text-blue-default">
                OR
              </span>
              <span place="not" class="text-blue-default">
                NOT
              </span>
            </i18n>
            <span class="underline cursor-pointer" onClick={() => this.useExample(this.ExampleSearch.or)}>
              '{this.renderExample(this.$tc('BEditQueryDialog.PD-1'), this.$tc('BEditQueryDialog.PD-L1'), 'OR')}'
            </span>
            <span> , </span>
            <span class="underline cursor-pointer" onClick={() => this.useExample(this.ExampleSearch.and)}>
              '{this.renderExample(this.$tc('BEditQueryDialog.PD-1'), this.$tc('BEditQueryDialog.inhibitors'), 'AND')}'
            </span>
            <span> , </span>
            <span class="underline cursor-pointer" onClick={() => this.useExample(this.ExampleSearch.not)}>
              '{this.renderExample(this.$tc('BEditQueryDialog.PD-1'), this.$tc('BEditQueryDialog.PD-L1'), 'NOT')}'
            </span>
          </div>
          <div>
            <span>{this.$tc('BEditQueryDialog.parenthesesLabel')}</span>
            <span class="underline cursor-pointer" onClick={() => this.useExample(this.ExampleSearch.parentheses)}>
              {this.renderExampleParentheses()}
            </span>
          </div>
          <div class="mt-2 text-gray-450 leading-5">{this.$t('BEditQueryDialog.note')}</div>
        </div>
      )
    },
  },
  render() {
    return (
      <GDialog
        width="800px"
        visible={this.visible}
        title={this.title}
        onClose={this.onClose}
        onCancel={this.onClear}
        onConfirm={this.onConfirm}
        {...{
          props: {
            onOpened: this.onOpened,
          },
        }}
        confirmDisabled={this.confirmDisabled}
        cancelText={this.$tc('common.clear')}
        confirmText={this.$tc('common.submit')}
      >
        <div class={[$classes.container, this.isFocus ? 'border-blue-default' : '']}>
          <EditQueryContainer
            ref="editQueryContainer"
            class={$classes.queryContainer}
            onInput={this.handleQueryInput}
            onFocusin={() => (this.isFocus = true)}
            onFocusout={() => (this.isFocus = false)}
          ></EditQueryContainer>
          <div class={['flex justify-end pr-3 mb-2', this.isOverLimit ? 'text-red-default' : 'text-gray-90']}>
            {this.isOverLimit ? this.$tc('BEditQueryDialog.overLimit') : null}
            {this.curInputLength}/{this.limit}
          </div>
        </div>

        {this.renderTipContent()}
      </GDialog>
    )
  },
})
