import { E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { RouteConfig } from 'vue-router'

export default [
  {
    path: `organization/:${E_ROUTER_PARAMS.ORG_ID}`,
    name: E_ROUTER_NAME.ORGANIZATION,
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.ORG_ID],
    }),
    component: () => import('@pharmsnap/pharmsnap-web/views/organization/OrganizationDetail'),
    meta: {
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.ORGANIZATION_DETAIL,
    },
    children: [
      {
        path: 'overview',
        name: E_ROUTER_NAME.ORGANIZATION_OVERVIEW,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
      {
        path: 'pipeline',
        name: E_ROUTER_NAME.ORGANIZATION_PIPELINE,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
      {
        path: 'financial',
        name: E_ROUTER_NAME.ORGANIZATION_FINANCIAL,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
      {
        path: 'funding',
        name: E_ROUTER_NAME.ORGANIZATION_FUNDING,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
      {
        path: 'investment',
        name: E_ROUTER_NAME.ORGANIZATION_INVESTMENT,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
      {
        path: 'financing',
        name: E_ROUTER_NAME.ORGANIZATION_FINANCING,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.ORGANIZATION,
          }
        },
      },
    ],
  },
] as RouteConfig[]
