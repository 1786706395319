import { getCountryAlpha2code } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { defineComponent } from '@vue/composition-api'
import { CountryFlag as PtCountryFlag } from 'patsnap-biz'
import $classes from './GCountryFlag.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const GCountryFlag = defineComponent({
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    nameCn: {
      type: String,
      default: '',
    },
    showName: {
      type: Boolean,
    },
    fontClass: {
      type: String,
    },
    useFullName: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    /** 全球是否展示国旗 */
    showGlobalFlag: {
      type: Boolean,
      default: true,
    },
    /** 非国家是否展示国旗（非国家：例如Europe、Asia...） */
    showUnknownCountryFlag: {
      type: Boolean,
      default: false,
    },
    showAlphaCode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isCN } = useLocale()
    return { isCN }
  },
  render() {
    if (this.name === 'Global' || this.name === '全球') {
      const title = this.isCN ? '全球' : 'Global'
      return (
        <span class="inline-flex items-center">
          {this.showGlobalFlag && <span title={title} class={$classes.unknownCountry}></span>}
          <span class="ml-1">{title}</span>
        </span>
      )
    }

    const alpha2Code = getCountryAlpha2code(this.name)
    const flag =
      !alpha2Code && this.showUnknownCountryFlag ? (
        <span title={this.$t('GCountryFlag.unknown')} class={$classes.unknownCountry}></span>
      ) : (
        <PtCountryFlag name={this.name} showCountryName={false}></PtCountryFlag>
      )
    const name = alpha2Code ? (
      <span class={this.fontClass ? [$classes.name, this.fontClass] : $classes.name}>
        {this.$i18n.locale === 'cn' || this.useFullName
          ? this.showAlphaCode
            ? this.$tc(`PtCountryFlag.${alpha2Code}`) + `(${alpha2Code})`
            : this.$tc(`PtCountryFlag.${alpha2Code}`)
          : alpha2Code}
      </span>
    ) : (
      <span class={this.fontClass ? [$classes.name, this.fontClass] : $classes.name}>
        {this.$t('GCountryFlag.unknown')}
        {this.name || this.nameCn ? ` (${this.isCN ? this.nameCn || this.name : this.name || this.nameCn})` : ''}
      </span>
    )

    return (
      <span class={[$classes.container, { [$classes.small]: this.isSmall }]}>
        {flag}
        {this.showName ? name : null}
      </span>
    )
  },
})
