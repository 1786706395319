import { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import {
  injectLangFieldByKey,
  injectLangFieldBySpecifiedLang,
  injectOrgName,
  replaceDisplayNameByShortLangMap,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'

const transMedicineFieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue> = {
  TARGET_ID: replaceDisplayNameByShortName,
  TARGET_ID_EXTEND: replaceDisplayNameByShortName,
  RESEARCH_SPONSOR_ID: injectOrgName,
  RESEARCH_SPONSOR_ID_ROOT: injectOrgName,
  MECHANISM_ACTION_ID: replaceDisplayNameByShortLangMap,
  TRANSLATION_STAGE_ID: (data) => injectLangFieldBySpecifiedLang(data, 'en'),
  PUB_DT_YEAR: injectLangFieldByKey,
  JOURNAL_ID: (data) => injectLangFieldBySpecifiedLang(data, 'en'),
  MEETINGS: (data) => injectLangFieldBySpecifiedLang(data, 'en'),
}

export function transformTransMedicineAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, transMedicineFieldTransformFnMap, xCorrelationId)
}
