import { BDecodeUniCodeContainer, BOrganizationItem, GDescription } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { IS_CN_REGION } from '@pharmsnap/shared/config'
import { EMPTY_PLACEHOLDER, invalidOrgName } from '@pharmsnap/shared/constants'
import { renderNewsPublishDate, renderPopoverLimitedOrg } from '@pharmsnap/shared/render'
import { IInvestment, ILang, IVCInvestmentInfoNew, IVCOrg } from '@pharmsnap/shared/types'
import { encryptNum, formatTimestamp, getVCAmount, transformVcOrg2BaseOrg } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api'
import { GLink } from '../../../ui/GLink/GLink'
import $vcClasses from './BInvestmentCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BInvestmentCard = defineComponent({
  name: 'BInvestmentCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IVCInvestmentInfoNew>,
      required: true,
    },
    type: {
      type: String as PropType<IInvestment>,
      required: true,
      default: 'invest',
    },
  },
  setup(props) {
    const { data } = toRefs(props)
    const { locale, isCN } = useLocale()
    const leadOrg = computed(() => {
      return ((data.value.investors?.filter((o) => o.investor_type === 'organization' && o.is_lead_investors) as IVCOrg[]) || []).map(
        transformVcOrg2BaseOrg
      )
    })
    const otherOrg = computed(() => {
      const res = (
        data.value.investors?.filter((o) => !o.is_lead_investors && !invalidOrgName.find((name) => o.original_name.includes(name))) || []
      ).map(transformVcOrg2BaseOrg)
      return res
    })

    const fundedOrg = computed(() => {
      return transformVcOrg2BaseOrg(data.value.funded_organization)
    })

    const announcedTime = computed(() =>
      data.value.announced_time ? formatTimestamp(data.value.announced_time, { locale: locale.value.toUpperCase() as ILang }) : EMPTY_PLACEHOLDER
    )
    const investmentType = computed(() => {
      if (!data.value.funding_type) return ''
      return isCN.value
        ? data.value.funding_type.classification || data.value.funding_type.classification_en
        : data.value.funding_type.classification_en || data.value.funding_type.classification
    })

    const eventNews = computed(() => {
      const list = data.value.news_id_view || []

      return list
        .slice()
        .sort((a, b) => (a.post_time?.time_ts || 0) - (b.post_time?.time_ts || 0))
        .slice(0, 3)
    })

    const eventNewsUrl = computed(() => {
      const list = data.value.news_url || []

      return list.slice(0, 3)
    })

    return {
      locale,
      announcedTime,
      investmentType,
      eventNews,
      eventNewsUrl,
      isCN,
      leadOrg,
      otherOrg,
      fundedOrg,
    }
  },
  methods: {
    renderOrg() {
      const hasLeadedOrg = this.leadOrg.length > 0
      const hasOtherOrg = this.otherOrg.length > 0

      return (
        <div class="text-text-t2">
          {hasLeadedOrg && (
            <div class={['mb-1', hasOtherOrg ? '' : 'border-b border-gray-40']}>
              <div class="py-1">{this.$tc('BInvestmentCard.leadOrganization')}</div>
              {renderPopoverLimitedOrg(this.leadOrg, 3)}
            </div>
          )}
          {hasOtherOrg && (
            <div class="pb-3 border-b border-gray-40">
              <div class="py-1">{hasLeadedOrg ? this.$tc('BInvestmentCard.otherOrganization') : this.$tc('BInvestmentCard.investOrganization')}</div>
              {renderPopoverLimitedOrg(this.otherOrg, 3)}
            </div>
          )}
          {this.renderFundedOrg()}
        </div>
      )
    },
    renderFundedOrg() {
      return this.fundedOrg ? (
        <div class="py-3">
          <div class="py-1">{this.$tc('BInvestmentCard.fundedOrganization')}</div>
          <BOrganizationItem data={this.fundedOrg}></BOrganizationItem>
        </div>
      ) : null
    },
    renderNews() {
      if (this.eventNews.length === 0 && this.eventNewsUrl.length === 0) return null
      if (this.eventNews.length !== 0) {
        return (
          <div>
            <div class={$vcClasses.referenceTitle}>{this.$tc('investment.eventNews')}</div>
            <div>
              {this.eventNews.map((item, index) => {
                return (
                  <div class={$vcClasses.referenceItem}>
                    <div>
                      {item.source_url && (
                        <GLink key={index} truncate={false} defaultColor="dark" href={`/link?target=${encodeURIComponent(item.source_url)}`}>
                          <div style="word-break: break-word;" class="line-clamp-3" domPropsInnerHTML={item.title}></div>
                        </GLink>
                      )}
                    </div>
                    <div class="flex items-center">
                      <span class="mr-2 text-text-t3 text-xs flex-shrink-0 flex items-center">
                        <span class="icon-font mr-1" domPropsInnerHTML={encryptNum(renderNewsPublishDate(item, this.$i18n))}></span>
                        {this.$t('news.via')}
                      </span>
                      {item.source_url && (
                        <GLink
                          href={`/link?target=${encodeURIComponent(item.source_url)}`}
                          name={item.source_url_host}
                          newTab={true}
                          defaultColor="blue"
                          class="flex-shrink-0 text-sm"
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      } else
        return (
          <div>
            <div class={$vcClasses.referenceTitle}>{this.$tc('investment.eventNews')}</div>
            <div class={$vcClasses.referenceContent}>
              {this.eventNewsUrl.map((item, index) => {
                return (
                  <div class={$vcClasses.referenceItem}>
                    <a href={`/link?target=${encodeURIComponent(item)}`} target="_blank">
                      {index + 1}.{item}
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        )
    },
    renderDesc() {
      return (
        <GDescription
          data={{}}
          items={[
            {
              label: this.$tc('investment.announcedDate'),
              content: this.announcedTime,
            },
            {
              label: this.$t('investment.amount', { moneyUnit: IS_CN_REGION ? '人民币' : '美元' }) as string,
              field: 'amount',
            },
            {
              label: this.$tc('investment.investmentTypeAndSeries'),
              content: this.investmentType,
            },
          ]}
          scopedSlots={{
            amount: () => {
              return (
                <BDecodeUniCodeContainer
                  fontClassName={this.data.fontClassName}
                  domPropsInnerHTML={getVCAmount(this.data, this.locale)}
                ></BDecodeUniCodeContainer>
              )
            },
          }}
        ></GDescription>
      )
    },
  },
  render() {
    return (
      <div class="border border-gray-60 p-4 bg-white-default rounded">
        <div class="border-b border-gray-40 pb-2 mb-2">{this.renderOrg()}</div>
        <div class="border-b border-gray-40 pb-2 mb-2">{this.renderDesc()}</div>
        <div>{this.renderNews()}</div>
      </div>
    )
  },
})
