import { IBaseLink } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { GLink } from '../..'
import { IGLinkColor } from '../../props'

export const BAtcItem = defineComponent({
  name: 'BAtcItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseLink>,
    },
    color: {
      type: String as PropType<IGLinkColor>,
      default: 'dark',
    },
  },
  render() {
    return (
      <GLink style="font-size: 14px; line-height: 24px;" href={this.data.href} name={this.data.name} newTab={true} defaultColor={this.color}></GLink>
    )
  },
})
