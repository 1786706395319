import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { computed, defineComponent, getCurrentInstance } from '@vue/composition-api'

export const GTranslate = defineComponent({
  name: 'GTranslate',
  props: {
    execute: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
    },
  },
  setup(props) {
    const {
      getters: { translation },
    } = useAuthStore()
    const { isCN } = useLocale()
    const ins = getCurrentInstance()
    const isTranslate = computed(() => props.execute && (props.content || ins?.proxy.$slots.content))

    return {
      isTranslate,
      translation,
      isCN,
    }
  },
  methods: {
    renderLeft() {
      return this.isCN ? <span>【</span> : <span class="ml-1">[</span>
    },
    renderRight() {
      return this.isCN ? <span>】</span> : <span class="mr-1">]</span>
    },
  },
  render() {
    return (
      <div>
        {this.$slots.default}
        {this.isTranslate && (this.content || this.$slots.content) ? (
          <div class={['bg-gray-20 p-2 text-text-t1 text-sm my-2']}>
            <span class="float-left -ml-2 font-semibold">
              {this.renderLeft()}
              {this.$t('common.translate')}
              {this.renderRight()}
            </span>
            {this.$slots.content || <div class="whitespace-pre-wrap" domPropsInnerHTML={this.content}></div>}
          </div>
        ) : null}
      </div>
    )
  },
})
