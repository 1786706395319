import { I18nLang } from '@patsnap/synapse_common_interface'
import { ITreeChartNode } from '@patsnap/synapse_tree_chart'
import { IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { getOrganizationName } from '@pharmsnap/shared/src/utils'
import { Selection } from 'd3'
import { formateDesc } from '../../BCorporateTreeButton/utils'
import companyWhite from './default-company-white.svg'
import company from './default-company.svg'
import { WEB_IMAGE, WEB_IMAGE_CALLBACK } from './webImage'
export function renderRect(rect: Selection<SVGRectElement, ITreeChartNode<IOrgCorpTree>, SVGGElement, undefined>) {
  rect
    .filter((d) => !d.data.drug_count_roll_up)
    .attr('fill', '#F4F5F7')
    .attr('stroke', '#BCC2CC')
    .attr('stroke-width', 1)
    .attr('filter', '')
}

export function renderForeignObject(
  foreignObject: Selection<SVGForeignObjectElement, ITreeChartNode<IOrgCorpTree>, SVGGElement, undefined>,
  lang: I18nLang,
  id?: string,
  logos: {
    defaultLogo?: string
    defaultWhite?: string
  } = {}
) {
  const { defaultLogo = company, defaultWhite = companyWhite } = logos
  foreignObject
    .html((d) => {
      const name = getOrganizationName(d.data, lang) || ''
      const desc = formateDesc(d.data, lang)
      const logo = d.data.id === id ? defaultWhite : defaultLogo
      return `
          <div class="foreign-object ${d.data.id === id ? 'target-corp' : ''} ${!d.data.drug_count_roll_up ? 'no-pipeline-foreign' : ''}">
            <div class="w-full flex items-center text-sm font-semibold leading-6 h-6 gap-x-1">
              <${WEB_IMAGE} class="foreign-img" src=${d.data.logo || logo} ${WEB_IMAGE_CALLBACK}="${logo}">
                <img width="24" height="24" onerror="this.src = '${logo}'"/>
              </${WEB_IMAGE}>
              <span class="foreign-title truncate">${name}</span>
            </div>
            <div class="w-full text-sm truncate leading-6 h-6">${desc}</div>
            <div class="w-full text-sm truncate leading-6 h-6">${
              d.data.drug_count_roll_up
                ? `<span class="pipeline">${d.data.drug_count_roll_up} Drug${(d.data.drug_count_roll_up || 0) > 1 ? 's' : ''}</span>`
                : '<span class="no-pipeline">No Pipeline</span>'
            }</div>
          </div>
        `
    })
    .attr('cursor', 'pointer')
}
