import { IBaseDevStatusCountryInfo } from '@pharmsnap/shared/types'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BDevStatusCard } from './BDevStatusCard'

export const BDevStatusPopperCard = defineComponent({
  name: 'BDevStatusPopperCard',
  props: {
    data: {
      type: Object as PropType<IBaseDevStatusCountryInfo>,
      required: true,
    },
    isApproved: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper
        data={{ data: this.data, isApproved: this.isApproved }}
        tippyConfig={{ ...this.tippyConfig, placement: 'right-start' }}
        component={BDevStatusCard}
      >
        {this.$slots.default}
      </PtPopper>
    )
  },
})
