import { IS_CN_REGION, VUE_APP_DEPLOY_REGION } from '@pharmsnap/pharmsnap-web/config'
import { showGDialog, showMask } from '@pharmsnap/shared/src/components'
import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { getCurrentInstance } from '@vue/composition-api'
import { createGlobalState, useLocalStorage } from '@vueuse/core'
import { productGuidCardWidth, ProductGuideCard } from './ProductGuideCard'
import { ProductPromotionCard } from './ProductPromotionCard'
import { ProductPromotionDoubleCard } from './ProductPromotionDoubleCard'

function useProductPromotion() {
  let inited = false

  const LIMIT_SEARCH_COUNT = 3

  const {
    getters: { customSetting, isFreeUser, isProfileComplete },
  } = useAuthStore()

  const ins = getCurrentInstance()

  // 针对免费用户进行弹窗引导
  // 免费用户分为已经完善信息和未完善信息两种
  // 针对已经完善信息的用户
  // 1. 判断弹窗状态，如果是forever_close，那么不再弹窗
  // 2. 如果是search_open， 那么判断用户是否主动检索两次，如果满足条件，进行弹窗，弹出后更新弹窗状态为forever_close
  // 针对未完善信息的用户
  // 1. 在用户完善完信息之后进行弹窗，弹窗后更新弹窗状态为forever_close

  const key = '__SYNAPSE_WEB_PRODUCT_PROMOTION__'

  const state = useLocalStorage<{
    searchCount: number
    status: 'forever_close' | 'search_open' | 'profile_open'
  }>(
    key,
    {
      searchCount: 0,
      // 非免费用户默认不弹窗，免费用户分为现存的以及该功能上线后新增的，如果是现存的已经完善信息的用户，按照检索次数弹窗，没完善信息，等完善信息后进行弹窗
      status: !isFreeUser.value ? 'forever_close' : 'search_open',
    },
    {
      listenToStorageChanges: false,
    }
  )

  function init() {
    if (inited) return

    const status = state.value.status
    const searchCount = state.value.searchCount

    if (!isFreeUser.value) {
      inited = true
      return
    }

    if (status === 'search_open' && searchCount > LIMIT_SEARCH_COUNT) {
      state.value.status = 'forever_close'
    }

    if (status === 'profile_open') {
      state.value.status = 'forever_close'
      openDialog()
    }

    inited = true
  }

  function changeToProfileOpenStatus() {
    state.value.status = 'profile_open'
  }

  function execIncreaseSearchCount() {
    const openStatus = state.value.status
    const currentSearchCount = state.value.searchCount
    if (!isFreeUser.value) return
    if (!isProfileComplete.value && IS_CN_REGION) return
    if (openStatus !== 'search_open') return
    if (currentSearchCount >= LIMIT_SEARCH_COUNT) {
      state.value.status = 'forever_close'
      openDialog()
    } else {
      state.value.searchCount = state.value.searchCount + 1
    }
  }

  function getProductsIconPosition(): Promise<{ top: number; left: number }> {
    const selector = 'div.patsnap-biz-header-dropdown__reference > div[icon="Products"]'
    const el = document.querySelector(selector)
    // el 可能还不存在，如果不存在，使用requestAnimationFrame一直查找，直到找到，然后返回一个promise
    // 如果 el 已经存在了，返回一个promise.resolve

    if (el) {
      const rect = el.getBoundingClientRect()
      return Promise.resolve({
        top: rect.top,
        left: rect.left,
      })
    } else {
      const cb = (resolve: (value: { top: number; left: number }) => void) => {
        const el = document.querySelector(selector)
        if (el) {
          const rect = el.getBoundingClientRect()
          resolve({
            top: rect.top,
            left: rect.left,
          })
        } else {
          window.requestAnimationFrame(() => cb(resolve))
        }
      }

      return new Promise((resolve) => {
        window.requestAnimationFrame(() => cb(resolve))
      })
    }
  }

  function openDialog() {
    const applyBioTrial = !!customSetting.value.apply_bio_trial
    const applyChemicalTrial = !!customSetting.value.apply_chemical_trail

    if (applyBioTrial && applyChemicalTrial) {
      openProductTrialMask('double')
      return
    }

    if (applyBioTrial) {
      openSingleProductTrialDialog('bio')
      return
    }

    if (applyChemicalTrial) {
      openSingleProductTrialDialog('chemical')
      return
    }

    if (!applyBioTrial && !applyChemicalTrial) {
      openDoubleProductTrialDialog()
      return
    }
  }

  function scrollBodyToLeft() {
    const el = document.documentElement || document.body
    el.scrollLeft = 0
  }

  function scrollBodyToRight() {
    const el = document.documentElement || document.body
    el.scrollLeft = el.scrollWidth
  }

  // 关闭body的横向滚动
  function closeBodyScroll() {
    const el = document.documentElement || document.body
    el.style.overflowX = 'hidden'
  }

  // 打开body的横向滚动
  function openBodyScroll() {
    const el = document.documentElement || document.body
    el.style.overflowX = 'auto'
  }

  async function openProductTrialMask(type: 'bio' | 'chemical' | 'none' | 'double' = 'none') {
    scrollBodyToRight()
    closeBodyScroll()
    const { left, top } = await getProductsIconPosition()
    const { close: closeMask } = showMask({
      component: ProductGuideCard,
      vNodeData: {
        props: {
          type: type === 'double' ? 'trial' : 'view',
        },
        class: 'fixed',
        style: {
          left: `${left - productGuidCardWidth + 64}px`,
          top: `${top}px`,
        },
        on: {
          close: () => {
            openBodyScroll()
            scrollBodyToLeft()
            closeMask()
          },
        },
      },
      router: ins?.proxy.$router,
      i18n: ins?.proxy.$i18n,
    })
  }

  function openSingleProductTrialDialog(type: 'bio' | 'chemical') {
    showGDialog(
      {
        width: '468px',
        visible: true,
        showFooter: false,
        showClose: false,
        component: ProductPromotionCard,
        componentProps: {
          type: type,
        },
      },
      ins?.proxy.$router,
      ins?.proxy.$i18n,
      {
        close: () => {
          openProductTrialMask(type)
        },
      }
    )
  }

  function openDoubleProductTrialDialog() {
    showGDialog(
      {
        width: '839px',
        visible: true,
        showFooter: false,
        showClose: true,
        emptyMode: true,
        component: ProductPromotionDoubleCard,
        componentProps: {},
      },
      ins?.proxy.$router,
      ins?.proxy.$i18n,
      {
        close: () => {
          openProductTrialMask('none')
        },
      }
    )
  }

  return {
    init,
    changeToProfileOpenStatus,
    execIncreaseSearchCount,
    openSingleProductTrialDialog,
    openProductTrialMask,
  }
}

export const useGlobalProductPromotion = createGlobalState(useProductPromotion)
