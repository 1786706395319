import { IAggregationData, IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { max, uniqBy } from 'lodash'

export function extractSpecifiedItemsFromAggResult(
  aggResult: IAggregationSingleResult[],
  aggField: string,
  specifiedItems: IAggregationValue[] = []
): IAggregationValue[] {
  if (aggResult.length) {
    for (const aggItem of aggResult) {
      if (aggItem.aggregation_field === aggField) {
        specifiedItems.push(...aggItem.items)
      } else if (aggItem.items.length) {
        aggItem.items.forEach((item) => {
          if (item.aggregation_result) {
            extractSpecifiedItemsFromAggResult(item.aggregation_result, aggField, specifiedItems)
          }
        })
      }
    }
  }
  return uniqBy(specifiedItems, 'key')
}

// 返回第二维聚合元素个数最多的数字，用于计算表格的行数
export function getSecondAggItemsMaxLength(aggData?: IAggregationData) {
  return max(aggData?.aggregation_result?.[0]?.items?.map((oneDimItem) => oneDimItem.aggregation_result?.[0]?.items?.length)) || 0
}
