import { IUserRole } from '@patsnap/synapse_common_interface'
import { transformData } from '@pharmsnap/pharmsnap-web/views/account/common'
import { IS_CN_REGION } from '@pharmsnap/shared/config'
import { IAccountExtraResponse, IProfileUserInfo, ITrialApplication, IUpdateProfileUserInfo } from '@pharmsnap/shared/types/account'
import {
  ILoginEmailBindParam,
  ILoginEmailVerifyCodeParam,
  ILoginMobileNumberBindParam,
  ILoginMobileVerifyCodeParam,
  IPreCheck,
  IUserInfo,
  IUserNoticeParam,
  IUserSetting,
  IValidateData,
  IWorkInfo,
} from '@pharmsnap/shared/types/login'
import type { IReCaptchaResponse } from '@pharmsnap/shared/utils/business/captcha-verification'
import qs from 'qs'
import { IAccountErrorResponse, IHttp, IHttpWrap } from '../http/types'
import { LoginBaseApi } from './loginBaseApi'

export class LoginApi extends LoginBaseApi {
  constructor(http: IHttp, baseUrl: string, passportBashUrl: string, accountBaseUrl: string, weChatBaseUrl: string) {
    super(http, baseUrl, passportBashUrl, accountBaseUrl, weChatBaseUrl)
  }
  /**
   * 获取加密公钥
   *
   * @returns
   * @param params
   */
  async getPublicKey(): Promise<IHttpWrap<string>> {
    return this.http({
      url: this.getPassportUrl(`public/request_public_key`),
      method: 'GET',
    })
  }
  /**
   * 获取手机验证码
   *
   * @returns
   * @param params
   */
  async getVerifyCode(params: ILoginMobileVerifyCodeParam): Promise<IHttpWrap<string>> {
    return this.http({
      url: this.getPassportUrl(`public/mobile-number/verify-code`),
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cache: false,
      data: qs.stringify({
        ...params,
      }),
    })
  }
  /**
   * 获取邮箱验证码
   *
   * @returns
   * @param params
   */
  async getEmailVerifyCode(params: ILoginEmailVerifyCodeParam): Promise<IHttpWrap<string, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/email/verify-code`),
      method: 'POST',
      withCredentials: false,
      params,
    })
  }

  /**
   * 绑定手机号检查
   *
   * @returns
   * @param params
   */
  async mobileNumberBindPreCheck(params: ILoginMobileNumberBindParam): Promise<IHttpWrap<IPreCheck, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/mobile-number/bind/pre-check`),
      method: 'GET',
      withCredentials: false,
      params,
    })
  }
  /**
   * 绑定手机号
   *
   * @returns
   * @param params
   */
  async mobileNumberBind(params: ILoginMobileNumberBindParam): Promise<IHttpWrap<string, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/mobile-number/bind`),
      method: 'PUT',
      withCredentials: false,
      params,
    })
  }
  /**
   * 解绑定手机号
   *
   * @returns
   * @param params
   */
  async mobileNumberUnBind(): Promise<IHttpWrap<string, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/mobile-number/unbind`),
      method: 'PUT',
      withCredentials: false,
    })
  }

  /**
   * 绑定邮箱检查
   *
   * @returns
   * @param params
   */
  async emailBindPreCheck(params: ILoginEmailBindParam): Promise<IHttpWrap<IPreCheck, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/email/bind/pre-check`),
      method: 'GET',
      withCredentials: false,
      params,
    })
  }
  /**
   * 绑定邮箱
   *
   * @returns
   * @param params
   */
  async emailBind(params: ILoginEmailBindParam): Promise<IHttpWrap<string, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/accounts/email/bind`),
      method: 'PUT',
      withCredentials: false,
      params,
    })
  }
  /**
   * 补充用户邮箱信息接口,注意这个只能在用户邮箱信息为空的时候调用,且只能在中国调用
   *
   * @returns boolean 由于这个是一个为数据库定制的一个接口,返回的布尔值的含义是是否需要提示,所以当接口
    返回true则表示更新不成功,邮箱已存在,
    返回false则是更新成功
   * @param params
   */
  async completeEmailProfile(params: { id: string; email: string }): Promise<IHttpWrap<boolean>> {
    return this.http({
      url: this.getAccountUrl(`user-service/account-settings/complement-profile`),
      method: 'PUT',
      data: transformData(params),
    })
  }

  /**
   * 绑定公众号
   *
   * @returns
   * @param callback_value
   */
  async getWechatBindCode(): Promise<IHttpWrap<{ callback_value: string; expire_seconds: number; pic_url: number }>> {
    return this.http({
      url: this.getAccountUrl(`user-service/wechat/qrcode/app`),
      params: {
        app_name: 'SYNAPSE',
      },
      method: 'GET',
    })
  }
  /**
   * 检查绑定公众号状态
   *
   * @returns
   * @param callback_value
   */
  async checkWechatBindStatus(callback_value: string): Promise<
    IHttpWrap<
      {
        account_token?: boolean
        identify_token?: string
        unionid_token?: string
      },
      IAccountErrorResponse
    >
  > {
    return this.http({
      url: this.getAccountUrl(`user-service/wechat/identify-token-callback`),
      params: {
        callback_value,
      },
      method: 'GET',
    })
  }

  /**
   * 解绑微信
   *
   * @returns
   * @param params
   */
  async wechatUnbind(): Promise<IHttpWrap<string, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/account-bindings/wechat/unbind`),
      method: 'PUT',
      withCredentials: false,
    })
  }

  /**
   * 获取用户设置
   *
   * @returns
   * @param params
   */
  async getUserSetting(params?: { user_id: string }): Promise<IHttpWrap<IUserSetting>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/setting`),
      method: 'GET',
      params,
    })
  }
  /**
   * 更新用户信息
   *
   * @returns
   * @param params
   */
  async setUserSetting(params: IUserSetting): Promise<IHttpWrap<IUserSetting>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/setting`),
      method: 'PUT',
      data: params,
    })
  }
  /**
   * 获取BO用户信息
   *
   * @returns
   * @param params
   */
  async getBoUserProfile(params?: { user_id: string }): Promise<IHttpWrap<IProfileUserInfo, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl('user-service/account-settings/profile'),
      method: 'GET',
      params,
      // 临时关闭缓存,由于这个接口会在更新完用户后调用，但是更新完用户信息后，这个接口的缓存还没有更新，导致用户信息没有更新,等汪萧的persistentCache实现更新缓存的功能后，再打开
      // synapseCacheType: 'persistent',
    })
  }
  /**
   * 获取BO用户信息
   *
   * @returns
   * @param params
   */
  async getAccountExtraInfo(): Promise<IHttpWrap<IAccountExtraResponse, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl('user-service/account-info/extra-data'),
      method: 'GET',
      // 临时关闭缓存,由于这个接口会在更新完用户后调用，但是更新完用户信息后，这个接口的缓存还没有更新，导致用户信息没有更新,等汪萧的persistentCache实现更新缓存的功能后，再打开
      // synapseCacheType: 'persistent',
    })
  }
  /**
   * 设置用户job信息
   *
   * @returns
   * @param params
   */
  async setUserWork(params: IWorkInfo): Promise<IHttpWrap<IProfileUserInfo, IAccountErrorResponse>> {
    const p = new FormData()
    Object.entries(params).forEach((item) => {
      const [key, value] = item
      p.append(key, value)
    })
    return this.http({
      url: this.getAccountUrl('user-service/account-settings/profile'),
      method: 'PUT',
      data: p,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
      },
    })
  }
  /**
   * 更新用户信息
   *
   * @returns
   * @param params
   */
  async updateProfile(params: FormData): Promise<IHttpWrap<IProfileUserInfo, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/account-settings/profile`),
      method: 'PUT',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }

  /**
   * 更新用户密码
   *
   * @returns
   * @param params
   */
  async updateUserPassword(password: string): Promise<IHttpWrap<IProfileUserInfo, IAccountErrorResponse>> {
    return this.http({
      url: this.getAccountUrl(`user-service/account-settings/pwd`),
      method: 'PUT',
      data: {
        password,
      },
    })
  }

  /**
   * user
   *
   * @returns
   */
  async getUser(params?: { refresh_cache?: boolean }): Promise<IHttpWrap<IUserInfo>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user`),
      method: 'GET',
      params,
    })
  }

  /** 获取小程序二维码*/
  async getMiniCode(params: { page: string; check_path: boolean; mapping_id: string }): Promise<IHttpWrap<string>> {
    return this.http({
      url: this.getBaseUrl('pharmsnap_webapi/user/wechat/mini/qrcode'),
      method: 'GET',
      params: params,
      cache: true,
    })
  }

  async captchaValidate(params: IReCaptchaResponse): Promise<IHttpWrap<IValidateData>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/captcha/validate`),
      method: 'POST',
      data: params,
    })
  }

  /**
   * 发送销售线索
   * @returns
   */
  async putBOSaleLead(
    params: IUpdateProfileUserInfo & { campaign_id?: string } // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=181053163
  ): Promise<IHttpWrap<{ account_id: string; language: string }, IAccountErrorResponse>> {
    // trail apply接口需要这个code，但是这个code不需要传给account-info接口
    if (params.code) delete params.code
    const data: IUpdateProfileUserInfo = {
      lead_source: IS_CN_REGION ? 'PharmSnap免费用户' : 'Marketing: Synapse Freemium', //这个品牌名不改
      ...params,
      sales_lead_send: true,
    }
    return this.http({
      url: this.getAccountUrl(`user-service/account-info`),
      method: 'PUT',
      data,
    })
  }
  /**
   * 发送销售线索
   * @returns
   */
  async putSaleLead(data: {
    campaign_id?: string
    mobile_number_code?: string
    mobile_number?: string
  }): Promise<IHttpWrap<{ account_id: string; language: string }, IAccountErrorResponse>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/sales/lead`),
      method: 'POST',
      data,
    })
  }

  /**
   * 钉钉通知中国销售线索
   * @returns
   */
  async trailApply(params: IUpdateProfileUserInfo & { current_page_url: string; apply_plan?: IUserRole }): Promise<IHttpWrap<void>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/trial/apply`),
      method: 'POST',
      data: { ...params, country_code: '+86' },
    })
  }

  /**
   * 海外hubspot提交的时候同步国内钉钉群
   * @returns
   */
  async trailApplyAbroad(params: ITrialApplication): Promise<IHttpWrap<void>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/trial/apply/abroad`),
      method: 'POST',
      data: params,
    })
  }

  /**
   * 发送用户更新通知
   * @returns
   * @param params
   */
  async sendUserUpdateNotice(params: IUserNoticeParam): Promise<IHttpWrap<IUserNoticeParam>> {
    return this.http({
      url: this.getBaseUrl(`pharmsnap_webapi/user/notice`),
      method: 'POST',
      data: params,
    })
  }
  /**
   * 天眼查企业自动补全
   */
  async getTYCOrgAutocomplete(companyName: string): Promise<IHttpWrap<Array<string>>> {
    return this.http({
      url: this.getPassportUrl('public/tyc/companies'),
      method: 'GET',
      params: {
        companyName,
      },
    })
  }
}
