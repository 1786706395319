import { ICoreAutoCompleteRes } from '@patsnap/synapse_common_interface'
import { formatOrgName } from '@pharmsnap/shared/utils'
import { IAutoCompleteParams } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class CoreApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<ICoreAutoCompleteRes>> {
    const { keywords, limit = 5, cancelToken, data_type, sort_by_best_fit = true } = params
    const entity_type = params.entity_type?.join('_')
    const paramsData = data_type
      ? {
          prefix: keywords,
          limit,
          data_type,
          entity_type,
          sort_by_best_fit,
        }
      : {
          prefix: keywords,
          limit,
          entity_type,
          sort_by_best_fit,
        }
    const res = (await this.http({
      method: 'POST',
      url: this.getUrl(`discoveryls_common/1.0/core/autocomplete`),
      data: paramsData,
      cache: true,
      cancelToken,
    })) as IHttpWrap<ICoreAutoCompleteRes>
    if (res.success && res.data?.organization) {
      res.data.organization.forEach((org) => formatOrgName(org, 'name_cn', 'name_en'))
    }
    if (res.success && res.data?.topic) {
      res.data.topic.forEach((d) => {
        d.id = ''
      })
    }
    if (res.success && res.data.recommend) {
      res.data.recommend.organization && res.data.recommend.organization.forEach((org) => formatOrgName(org, 'name_cn', 'name_en'))
      res.data.recommend.topic &&
        res.data.recommend.topic.forEach((d) => {
          d.id = ''
        })
    }
    return res
  }
}
