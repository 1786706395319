import { E_ALIGN_DIRECTION } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType } from '@vue/composition-api'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { IPatentStatus } from '../../../types'
import { PatentFieldSelection } from '../../ui/PatentFieldSelection/PatentFieldSelection'

export const PatentStatusField = defineComponent({
  name: 'PatentStatusField',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String as PropType<IPatentStatus>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()

    const options = computed<Array<{ label: string; value: IPatentStatus }>>(() => [
      {
        label: ins?.proxy.$tc('application') as string,
        value: 'application',
      },
      {
        label: ins?.proxy.$tc('granted') as string,
        value: 'granted',
      },
    ])

    const changePatentStatus = (val: IPatentStatus) => emit('changePatentStatus', val)

    return {
      options,
      changePatentStatus,
    }
  },
  render() {
    return (
      <PatentFieldSelection
        options={this.options}
        value={this.value}
        align={this.isFullScreen ? E_ALIGN_DIRECTION.VERTICAL : E_ALIGN_DIRECTION.HORIZONTAL}
        title={this.isFullScreen ? this.$tc('patentType') : ''}
        onChangeField={this.changePatentStatus}
      ></PatentFieldSelection>
    )
  },
})
