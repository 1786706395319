import { GCheckList } from '@pharmsnap/shared/components'
import { defineComponent, PropType } from '@vue/composition-api'

export const BAnalysisChartCheckboxGroup = defineComponent({
  name: 'BAnalysisChartCheckboxGroup',
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    options: {
      type: Array as PropType<
        {
          id: string
          name: string
        }[]
      >,
      default: () => [],
    },
    min: {
      type: Number,
      default: -1,
    },
  },
  setup(props, ctx) {
    function handleUpdateValue(val: string[]) {
      ctx.emit('input', [...val])
    }

    return {
      handleUpdateValue,
    }
  },
  render() {
    return (
      <GCheckList
        list={this.options}
        itemKey="id"
        min={this.min}
        value={this.value}
        onInput={this.handleUpdateValue}
        scopedSlots={{
          default: (props: { item: { id: string; name: string } }) => {
            return <span class="leading-4 text-sm text-text-t2 font-normal">{props.item.name}</span>
          },
        }}
      ></GCheckList>
    )
  },
})
