import { defineComponent } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import './BBetaTag.scss'

export const BBetaTag = defineComponent({
  name: 'BBetaTag',
  render() {
    return (
      <ElTag class="b-beta-tag" size="mini" disable-transitions={true} effect="plain">
        Beta
      </ElTag>
    )
  },
})
