/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggLineBarChartEntity, AggOneDimDataItem } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { ComponentProps } from '@pharmsnap/shared/src/types'
import { PropType, defineComponent, onMounted, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import { EChartsOption } from 'echarts'
import { cloneDeep, isArray } from 'lodash'
import { BStackBarTooltip } from '../../../BStackBarTooltip/BStackBarTooltip'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { BAnalysisEChart } from './BAnalysisEchart'

export const BAnalysisLineBarEchart = defineComponent({
  name: 'BAnalysisLineBarEchart',
  props: {
    entity: {
      type: Object as PropType<AggLineBarChartEntity>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)
    const { locale } = useLocale()

    const { tooltipRef, tooltipCommonConfig, tooltipActiveData, tooltipFormatter, resetTooltipDataMap } = useAnalysisChartTooltip<{
      categoryName: ComponentProps<typeof BStackBarTooltip>['categoryName']
      items: ComponentProps<typeof BStackBarTooltip>['items']
    }>({
      pickTooltipDataFromChartParam,
    })

    const realOption = shallowRef<EChartsOption>()

    function updateRealOption() {
      const option = cloneDeep(unref(entity).getEchartsOptions())
      if (option) {
        option.tooltip = {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            z: -1,
            shadowStyle: {
              color: '#F5F7FC',
            },
          },
          enterable: false,
          hideDelay: 500,
          triggerOn: 'mousemove|click',
          formatter: tooltipFormatter,
          ...tooltipCommonConfig,
        }

        option.animation = false
      }

      realOption.value = option
    }

    function pickTooltipDataFromChartParam(params: any) {
      const { yAxis } = realOption.value || {}
      if (!params) return
      if (!Array.isArray(params) || params.length === 0) return
      if (!params[0]) return
      const categoryName = params[0].axisValueLabel
      const items = params.map((i, index) => {
        const { dimensionNames, encode } = i
        const countKey = dimensionNames?.[encode?.y?.[0]]
        const getCountFormatter = () => {
          // 交易总金额需要特殊处理，当有交易数量，但没有交易金额时，tooltip显示未披露
          const isSpecialDealTotalPayment =
            i.data.aggValue.aggregation_field === 'DEAL_TIME_YEAR' && countKey === 'otherCount' && i.data.otherCount === 0 && i.data.count !== 0

          const getDealUndisclosedTotalPayment = () => (locale.value === 'cn' ? '未披露' : 'Undisclosed')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const getAxisLabelFormatter = yAxis && isArray(yAxis) ? yAxis[index]?.axisLabel?.formatter : yAxis?.axisLabel?.formatter

          return isSpecialDealTotalPayment ? getDealUndisclosedTotalPayment : getAxisLabelFormatter
        }
        return {
          color: i.color as string,
          count: i.data[countKey],
          countFormatter: getCountFormatter(),
          name: i.seriesName,
          originalData: i.data,
        }
      })

      const data = {
        categoryName,
        items,
      }

      return [categoryName, data] as [string, typeof data]
    }

    function handleClickItem(params: any) {
      if (!params) return
      if (!params.data) return
      emit('clickItem', params.data as AggOneDimDataItem)
    }

    onMounted(() => {
      updateRealOption()
    })

    watch(entity, () => {
      resetTooltipDataMap()
      updateRealOption()
    })

    return {
      tooltipRef,
      realOption,
      tooltipActiveData,
      handleClickItem,
      ...useAnalysisEchartsHelpers(),
    }
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        displayType="line-bar"
        option={this.realOption}
        axisPopperOption={{ xPopperEnable: false, yPopperEnable: false }}
        {...{
          on: {
            click: this.handleClickItem,
          },
        }}
      >
        <template slot="extra">
          <div ref="tooltipRef">
            <BStackBarTooltip
              categoryName={this.tooltipActiveData?.categoryName || ''}
              items={this.tooltipActiveData?.items || []}
            ></BStackBarTooltip>
          </div>
        </template>
      </BAnalysisEChart>
    )
  },
})
