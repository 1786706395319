import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import '@patsnap-ui/icon/assets/solid/patent.svg'
import { toThousands } from '@patsnap/synapse_common_utils'
import { renderPopoverLimited } from '@pharmsnap/shared/render'
import { IBaseDrugInfo, IPatentService } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BDrugItem } from '../BDrugItem/BDrugItem'
import $classes from './BPatentHeader.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BPatentHeader = defineComponent({
  name: 'BPatentHeader',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    drugs: {
      type: Array as PropType<IBaseDrugInfo[]>,
      default: () => [],
    },
    patentService: {
      type: Object as PropType<IPatentService>,
      required: true,
    },
  },
  setup(props) {
    const { state, actions } = props.patentService

    async function handleViewAll() {
      if (!actions.viewAllPatents) return
      await actions.viewAllPatents()
    }

    return { state, handleViewAll }
  },
  methods: {
    renderCorePatentedDrugs() {
      return (
        <div class={$classes.corePatentedDrugs}>
          <span class="text-sm text-black-default flex-shrink-0">{this.$t('BPatentHeader.viewQueryDrugs')}</span>
          {renderPopoverLimited(this.drugs, {
            limitCount: 3,
            inline: true,
            render: (item, index, isRenderInPopover, items) => {
              if (isRenderInPopover) {
                return <BDrugItem data={item} showCard zIndex={3000}></BDrugItem>
              }
              return (
                <BDrugItem
                  data={item}
                  showCard
                  zIndex={3000}
                  class={index === items.length - 1 ? $classes.lastDrugItem : $classes.drugItem}
                ></BDrugItem>
              )
            },
            appendToBody: false,
            placement: 'right-start',
            gap: {
              x: 4,
              y: 0,
            },
          })}
        </div>
      )
    },
    renderViewAllRelatedPatents() {
      return (
        <GTooltip theme="light" placement="bottom">
          <div class={$classes.viewAllPatents} onClick={this.handleViewAll}>
            <GIcon svgName="SolidPatent" useSvgDefaultColor size={24}></GIcon>
            <i18n path={'BPatentHeader.viewAllRelatedPatents'} tag="div" class="text-sm text-black-default">
              {this.state.loading ? (
                <GIcon place="count" svgName="SolidLoadingBars"></GIcon>
              ) : (
                <span place="count">{toThousands(this.state.allPatentCount || 0)}</span>
              )}
            </i18n>
          </div>
          <template slot="content">
            <div domPropsInnerHTML={this.$t('BPatentHeader.viewAllInfo')}></div>
          </template>
        </GTooltip>
      )
    },
  },
  render() {
    return (
      <div class="flex items-center py-1">
        {this.renderCorePatentedDrugs()}
        {this.renderViewAllRelatedPatents()}
      </div>
    )
  },
})
