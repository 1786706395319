import { E_QUERY_ITEM_CONDITION, IQuery } from '@patsnap/synapse_common_interface'
import { IWidgetEntityItem } from '@pharmsnap/pharmsnap-web/views/dashboard/type'
import { computed, getCurrentInstance, onMounted, ref, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep } from 'lodash'
import { getChartColor } from '../chart'
import { BasicLineChart } from '../chart/type'
import { IHttpWrap } from '../service/http/types'
import { IBaseAnalysisTrendData, IDashboardWidgetPartialTimeRangeType, INavListType, IUseTrendChartData, IWebTrackingEvent } from '../types'
import { getNavListQueryByEntity, getTimeRelatedQueryField } from '../utils'
import { useChart } from './useChart'
import { useNavList } from './useNavList'

interface IUseTrendChartOptions {
  fetch?: (yearType: '5y' | '10y') => Promise<IHttpWrap<IBaseAnalysisTrendData>>
  initYearType?: '5y' | '10y'
  entity?: MaybeRef<IWidgetEntityItem[]>
  entityType?: MaybeRef<'drug' | 'disease' | 'target' | 'organization' | 'mechanism' | 'drug_type'>
  autoFetch?: boolean
  mini?: boolean
  showMedical?: boolean
  data?: MaybeRef<IBaseAnalysisTrendData | undefined>
  tracking?: MaybeRef<IWebTrackingEvent[] | undefined>
  condition?: MaybeRef<E_QUERY_ITEM_CONDITION>
}

/**
 * 将trend数据转化为图表需要的格式
 */
export function transformTrendInfo2ChartData(data?: IBaseAnalysisTrendData): IUseTrendChartData[] {
  if (!data) return []
  if (!data.clinical_trial && !data.paper && !data.patent && !data.news) return []

  const getChartInfo = (listKey: keyof IBaseAnalysisTrendData) => {
    const list = (data[listKey] || { items: [] }).items
    const keys: (keyof IBaseAnalysisTrendData)[] = ['clinical_trial', 'paper', 'patent', 'news']
    const isNews = listKey === 'news'
    return list.map((item) => {
      const { key: year, count, key_as_string: year2 } = item
      const extraKey = keys.filter((i) => i !== listKey)
      const [foundA, foundB, foundC] = extraKey.map((k) =>
        (data[k] || { items: [] }).items.find((i) => (isNews ? i.key === year2 : i.key === year || i.key_as_string === year))
      )

      return {
        year: isNews ? year2 : year,
        [listKey]: count,
        [extraKey[0]]: foundA ? foundA.count || 0 : 0,
        [extraKey[1]]: foundB ? foundB.count || 0 : 0,
        [extraKey[2]]: foundC ? foundC.count || 0 : 0,
      }
    })
  }

  if (data.clinical_trial && data.clinical_trial.items && data.clinical_trial.items.length > 0) {
    return getChartInfo('clinical_trial') as unknown as IUseTrendChartData[]
  }

  if (data.paper && data.paper.items && data.paper.items.length > 0) {
    return getChartInfo('paper') as unknown as IUseTrendChartData[]
  }

  if (data.patent && data.patent.items && data.patent.items.length > 0) {
    return getChartInfo('patent') as unknown as IUseTrendChartData[]
  }

  if (data.news && data.news.items && data.news.items.length > 0) {
    return getChartInfo('news') as unknown as IUseTrendChartData[]
  }

  return []
}

export function useTrendChart(options: IUseTrendChartOptions) {
  const { showMedical = true, data, mini = false, tracking, entity, entityType, fetch, autoFetch, condition } = options
  const ins = getCurrentInstance()
  const loading = ref<boolean>(true)
  const navListType = ref<INavListType>('clinical_trial')
  const fetchedTrendData = ref<IBaseAnalysisTrendData>()
  const selectedTrendYear = ref<IDashboardWidgetPartialTimeRangeType>(options.initYearType ? options.initYearType : '5y')
  const yearOptions = computed(() => [
    {
      label: ins?.proxy.$i18n.tc('common.yearRange.5') || '',
      value: '5y',
    },
    {
      label: ins?.proxy.$i18n.tc('common.yearRange.10') || '',
      value: '10y',
    },
  ])
  const query = computed<IQuery>(() => {
    const emptyQuery: IQuery = { type: 'group', must: [], filter: [] }
    const entityVal = unref(entity)
    const entityTypeVal = unref(entityType)
    if (!entityVal?.length || !entityTypeVal) return emptyQuery
    return getNavListQueryByEntity(entityVal, entityTypeVal, navListType.value, unref(condition))
  })
  const trendData = computed<IBaseAnalysisTrendData>({
    get() {
      return data ? unref(data) || {} : fetchedTrendData.value || {}
    },
    set(v) {
      fetchedTrendData.value = v
    },
  })
  const trendItems = computed(() => {
    return transformTrendInfo2ChartData(trendData.value)
  })
  const isEmpty = computed(() => trendItems.value.length === 0)
  const trendChartOption = computed<BasicLineChart>(() => {
    const keys: (keyof IBaseAnalysisTrendData)[] = ['paper', 'clinical_trial', 'patent', 'news']
    const option: BasicLineChart = {
      type: 'line',
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        name: '',
      },
      yAxis: {
        dataType: { valueType: 'count' },
        name: ins?.proxy.$i18n.tc('chart.valueType.count'),
      },
      legend: {
        bottom: 0,
      },
      grid: {
        top: 40,
        bottom: 30,
        containLabel: true,
        right: 10,
        left: 30,
      },
      dataset: {
        dimensions: ['year', 'paper', 'clinical_trial', 'patent'],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        source: trendItems.value,
      },
      color: getChartColor(4),
      series: keys
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .filter((i) => trendData.value && trendData.value[i] && trendData.value[i]!.items && trendData.value[i]!.items.length > 0)
        .map((name) => {
          const displayMedical = showMedical && ['paper', 'patent'].includes(name)
          return {
            name: `${ins?.proxy.$i18n.tc(`common.${name}`)}${displayMedical ? ` (${ins?.proxy.$i18n.tc('common.medical')})` : ''}`,
            // color: colorMap[name],
            encode: {
              x: 'year',
              y: name,
            },
          }
        }),
    }

    if (mini) {
      option.legend = { show: false }
      option.xAxis.axisLabel = { show: false }
      option.xAxis.axisTick = { show: false }
      option.xAxis.axisLine = { show: false }
      option.yAxis.axisLabel = { show: false }
      option.yAxis.axisTick = { show: false }
      option.yAxis.axisLine = { show: false }
      option.yAxis.name = ''
      option.grid = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        containLabel: true,
      }
      option.tooltip = {
        ...option.tooltip,
        appendToBody: true,
        confine: false,
      }

      option.series = option.series.map((i) => ({ ...i, symbol: 'none' }))
    }

    return option
  })

  const { navToList, dataType } = useNavList({
    auth: true,
    navList: navListType,
    tracking,
  })

  const {
    initChart,
    chartContainer,
    render,
    options: echartOptions,
    getEchartsInstance,
  } = useChart(trendChartOption, {
    autoResize: true,
    registerEvent: (chartIns) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartIns.on('click', handleClickChart)
    },
  })

  const handleClickChart = async (params: any) => {
    const { dimensionNames, encode } = params
    const yDimIndex = encode && encode.y[0]
    const yDim = yDimIndex && dimensionNames && dimensionNames[yDimIndex]
    if (!yDim) return
    const data = params.data as IUseTrendChartData
    if (!data) return
    const year = data.year
    if (!year) return
    const docType = yDim as 'clinical_trial' | 'patent' | 'news' | 'paper'
    if (!docType) return
    navListType.value = docType
    const fieldRecord: Record<typeof docType, { yearField: string; rangeField: string }> = {
      clinical_trial: {
        yearField: 'START_DATE',
        rangeField: 'START_DATE',
      },
      news: {
        yearField: 'dmp_post_time',
        rangeField: 'dmp_post_time',
      },
      paper: {
        yearField: 'YEAR',
        rangeField: 'YEAR',
      },
      patent: {
        yearField: 'PBDT_YEAR',
        rangeField: 'PBDT_YEARMONTHDAY',
      },
    }

    const { yearField, rangeField } = fieldRecord[docType]

    const yearQueryField = getTimeRelatedQueryField(Number(year), yearField, true, false)

    const rangeQueryField = getTimeRelatedQueryField(selectedTrendYear.value === '5y' ? 5 : 10, rangeField, true, true)

    const appendTo = docType === 'paper' || docType === 'patent' ? 'filter' : 'must'

    const originalQuery = cloneDeep(unref(query))

    if (!originalQuery.must) originalQuery.must = []
    if (!originalQuery.filter) originalQuery.filter = []

    if (docType === 'patent') {
      rangeQueryField && originalQuery.must.push(rangeQueryField)
    }

    yearQueryField && originalQuery[appendTo]?.push(yearQueryField)

    await navToList({
      data_type: unref(dataType),
      query: originalQuery,
      hidden_flag: true,
    })
  }

  const fetchTrendInfo = async () => {
    if (!fetch) return
    loading.value = true
    const rt = await fetch(selectedTrendYear.value)
    if (rt.success && rt.data) {
      trendData.value = rt.data
    }
    loading.value = false
  }

  watch(selectedTrendYear, () => {
    fetchTrendInfo()
  })

  autoFetch &&
    onMounted(() => {
      fetchTrendInfo()
    })

  return {
    chartContainer,
    initChart,
    selectedTrendYear,
    yearOptions,
    fetchTrendInfo,
    trendChartOption,
    loading,
    isEmpty,
    render,
    options: echartOptions,
    getEchartsInstance,
  }
}
