import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

export default [
  {
    path: 'report-list',
    name: E_ROUTER_NAME.REPORT_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/report/ReportList'),
    meta: {
      activeKey: E_NAV_KEY.REPORT_LIST,
      title: createWebTitle('精选报告', 'Featured Report'),
      notWide: true,
    },
  },
  {
    path: `report/:${E_ROUTER_PARAMS.REPORT_ID}`,
    name: E_ROUTER_NAME.REPORT_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/report/ReportDetail'),
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.REPORT_ID],
    }),
    meta: {
      notWide: true,
    },
  },
  {
    path: `report/:${E_ROUTER_PARAMS.REPORT_ID}/preview`,
    name: E_ROUTER_NAME.REPORT_PREVIEW,
    component: () => import('@pharmsnap/pharmsnap-web/views/report/ReportPreview'),
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.REPORT_ID],
    }),
    meta: {
      notWide: true,
    },
  },
  {
    path: `report-detail/:${E_ROUTER_PARAMS.REPORT_ID}`,
    redirect: () => {
      return {
        name: E_ROUTER_NAME.REPORT_PREVIEW,
      }
    },
  },
] as RouteConfig[]
