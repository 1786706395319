import type { IRegionAutoCompleteItem } from '@patsnap/synapse_common_interface'
import { IAutoCompleteParams, IBaseCountry, ISearchResult } from '@pharmsnap/shared/types'
import DataLoader from 'dataloader'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class RegionApi extends BaseApi {
  private batchGetRegionLoader = new DataLoader((ids: readonly string[]) => this._getBatchRegion(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async _getBatchRegion(ids: readonly string[]): Promise<Array<IBaseCountry | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/region/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseCountry>>
    if (rt.success && rt.data) {
      return rt.data.items || []
    }
    return []
  }
  async getBatchRegion(ids: readonly string[]): Promise<IHttpWrap<IBaseCountry[]>> {
    const rt = await this.batchGetRegionLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt as IBaseCountry[],
      xCorrelationId: '',
    }
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IRegionAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    return this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/region/autocomplete'),
      params: { prefix: keywords, limit },
      cache: true,
      cancelToken,
    })
  }
}
