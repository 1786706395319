import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { GFilterItemTrigger } from '../../../ui/GFilterItemTrigger/GFilterItemTrigger'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { BAdvanceFilterDialog } from '../BAdvanceFilterDialog/BAdvanceFilterDialog'
import { IAdvanceFilterConfig, ISelectedFilter } from '../type'

export const BAdvanceFilterItems = defineComponent({
  name: 'BAdvanceFilterItems',
  props: {
    filterConfigList: {
      required: true,
      type: Array as PropType<Array<IAdvanceFilterConfig>>,
    },
    selectedFilterData: {
      require: true,
      type: Object as PropType<Record<string, ISelectedFilter>>,
      default: () => ({}),
    },
    aggByParentConfig: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    hasAuth: {
      type: Boolean,
      default: true,
    },
    gap: {
      type: Object as PropType<{ x: number; y: number }>,
      default: () => ({ x: 4, y: 4 }),
    },
    /** 是否需要打平tree结构数据
     * 放在过滤项中的数据，树结构需要提交该节点及其子节点的数据
     */
    needSubmitFlattenTreeData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const {
      actions: { changeUpgrade },
    } = useAuthStore()

    const isShowDialog = ref(false)
    const clickedField = ref<string>('')

    const itemStyle = {
      marginRight: `${props.gap.x}px`,
      marginBottom: `${props.gap.y}px`,
    }

    const handleClickFilter = (field: string) => {
      if (props.hasAuth) {
        clickedField.value = field
        isShowDialog.value = !isShowDialog.value
        return
      }
      changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER, trigger_point: 'ADVANCE_FILTER' })
    }
    const handleClear = (field: string) => ctx.emit('clearFilter', field)

    const handleConfirm = (data: Record<string, ISelectedFilter>) => ctx.emit('confirm', data)

    const handleChangeSelectFilter = (field: string) => (clickedField.value = field)

    const handleAggregationFieldChange = (field: string) => ctx.emit('changeAggregationField', field)

    return {
      itemStyle,
      isShowDialog,
      clickedField,
      handleClickFilter,
      handleClear,
      handleConfirm,
      handleChangeSelectFilter,
      handleAggregationFieldChange,
    }
  },
  methods: {
    renderFilterIcon(selectedCount: number, field: string) {
      if (selectedCount) {
        return (
          <GIcon
            svgName="SolidCloseMedium"
            nativeOn={{
              click: (e: Event) => {
                e.stopPropagation()
                e.preventDefault()
                this.handleClear(field)
              },
            }}
            useSvgDefaultColor
            class="ml-1 flex-shrink-0 text-blue-default cursor-pointer"
            size={24}
          ></GIcon>
        )
      }
      return <GIcon svgName="SolidFilers" class="ml-1 flex-shrink-0" size={24}></GIcon>
    },
    renderFilter() {
      return [
        this.filterConfigList.map((config) => {
          const field = config.field
          const selectedCount = this.selectedFilterData[field].displayItems.length

          return (
            <GFilterItemTrigger
              style={this.itemStyle}
              label={config.label}
              width={184}
              iconMode="menu"
              icon={config.icon}
              count={selectedCount}
              key={config.field}
              onTrigger={() => this.handleClickFilter(field)}
              onClear={() => this.handleClear(field)}
            ></GFilterItemTrigger>
          )
        }),
      ]
    },
    renderFilterDialog() {
      return (
        <BAdvanceFilterDialog
          isShowDialog={this.isShowDialog}
          selectedFilterField={this.clickedField}
          selectedData={this.selectedFilterData}
          filterList={this.filterConfigList}
          needSubmitFlattenTreeData={this.needSubmitFlattenTreeData}
          aggByParentConfig={this.aggByParentConfig}
          onCloseDialog={() => (this.isShowDialog = false)}
          onConfirm={this.handleConfirm}
          onChangeSelectFilter={this.handleChangeSelectFilter}
          onChangeAggregationField={this.handleAggregationFieldChange}
        ></BAdvanceFilterDialog>
      )
    },
  },
  render() {
    return (
      <div>
        {this.renderFilter()}
        {this.renderFilterDialog()}
      </div>
    )
  },
})
