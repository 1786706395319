import { IDeleteParam, IHistory, IHistoryItem, IHistoryParams, ISearchItem } from '@pharmsnap/shared/types/searchHistory'
import { IncognitoFlag } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class SearchHistoryApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  /**
   * All
   */
  async getAllHistory(params: IHistoryParams): Promise<IHttpWrap<IHistoryItem & IncognitoFlag>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/history/search'),
      data: params,
    })
  }

  async deleteAllHistory(params: IDeleteParam[]): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/history'),
      data: params,
    })
  }

  async deleteAll(): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/history/batch'),
    })
  }

  /**
   * Search
   */
  async getSearchHistory(params: IHistoryParams): Promise<IHttpWrap<ISearchItem & IncognitoFlag>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/search/history/search'),
      data: params,
    })
  }

  async deleteSearchHistory(ids: string[]): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/search/history'),
      data: ids,
    })
  }
  async deleteAllSearch(): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/search/history/batch'),
    })
  }
  async updateQuery(params: { id: string }): Promise<IHttpWrap<IHistory>> {
    return this.http({
      method: 'PUT',
      url: this.getUrl(`pharmsnap_webapi/1.0/search/history/`) + params.id,
    })
  }

  /**
   * Export
   */
  async getExportHistory(params: IHistoryParams): Promise<IHttpWrap<IHistoryItem & IncognitoFlag>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/export/history/search'),
      data: params,
    })
  }
  async deleteExportHistory(ids: string[]): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/export/history'),
      data: ids,
    })
  }
  async deleteAllExport(): Promise<IHttpWrap<IHistoryItem>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl('pharmsnap_webapi/1.0/export/history/batch'),
    })
  }
}
