import { E_AGGREGATION_TYPE, E_QUERY_ITEM_CONDITION, IAggregationItem, IAggregationParams } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { useNavList } from '@pharmsnap/shared/composition/useNavList'
import { usePieChart } from '@pharmsnap/shared/composition/usePieChart'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/constants/enum-lang/org'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IHistorySearchParams, IUsePieChartItem } from '@pharmsnap/shared/types'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { PropType, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $classes from './BPieChart.module.scss'

export const BPieChart = defineComponent({
  name: 'BPieChart',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    aggregationField: {
      required: true,
      type: String as PropType<'entity_type'>,
    },
    title: {
      required: true,
      type: String,
    },
    desc: {
      type: String,
    },
    seriesName: {
      type: String,
    },
    limit: {
      type: Number,
    },
    radius: {
      type: Array as PropType<string[]>,
    },
    total: {
      type: Number,
    },
  },
  setup(props) {
    const localeData = useLocale()
    const ins = getCurrentInstance()
    const loading = ref(true)
    /**
     * 药物数量限制
     */
    const drugLimit = 5000

    const pieData = ref<{ data: IUsePieChartItem[] }>({
      data: [],
    })

    const getChartData = async () => {
      const aggregationItem: IAggregationItem = {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: props.aggregationField,
      }
      if (props.limit) {
        aggregationItem.limit = props.limit
      }
      const aggParams: IAggregationParams = {
        data_type: props.queryService.state.data_type,
        query: { ...props.queryService.state.query, type: 'group' },
        aggregation: [aggregationItem],
      }
      loading.value = true
      const res = await sharedCtx.service.drug.getDrugDevOrgAggregation(aggParams)
      if (res.success) {
        const aggData = res.data.aggregation_result.find((r) => r.aggregation_field === props.aggregationField)
        const d = aggData?.items ?? []
        pieData.value.data = d.map((o) => {
          return {
            name: ORG_ENTITY_LANG[o.key] ? ORG_ENTITY_LANG[o.key][localeData.locale.value] : '',
            value: o.count,
            originData: o,
          }
        })
      }
      loading.value = false
    }

    const { navToList } = useNavList({
      navList: 'drug_group_organization',
      auth: false,
    })

    const onClickChart = (params: any) => {
      const { originData } = params.data
      if (!originData) return
      const { key } = originData
      if (props.total && props.total > drugLimit) {
        return showSingleToast({
          message: ins?.proxy.$t('Analysis.limitWarning', { limit: drugLimit }) as string,
          type: 'warning',
        })
      }

      const searchParams: IHistorySearchParams = {
        data_type: 'drug',
        hidden_flag: true,
        query: {
          type: 'group',
          must: [...props.queryService.state.query.must, ...props.queryService.state.query.filter],
          filter: [
            {
              condition: E_QUERY_ITEM_CONDITION.ANY,
              fields: ['entity_type'],
              type: 'field',
              value: [
                {
                  type: 'text',
                  value: key,
                  display_name_cn: ORG_ENTITY_LANG[key].cn || '',
                  display_name_en: ORG_ENTITY_LANG[key].en || '',
                },
              ],
            },
          ],
        },
        org_roll_up: false,
      }

      navToList(searchParams)
    }

    watch(
      () => props.queryService.state.query,
      () => {
        getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const { chartContainer, isEmpty, options } = usePieChart(pieData, {
      seriesName: () => props.seriesName as string,
      clickAble: true,
      radius: props.radius,
      registerEvent: (instance) => {
        instance.on('click', onClickChart)
      },
    })

    return { chartContainer, isEmpty, loading, options }
  },
  render() {
    return (
      <div>
        <BCardContainer
          style={{ height: '400px' }}
          isLoading={this.loading}
          isEmpty={this.isEmpty}
          title={this.title}
          desc={this.desc}
          titleClass={$classes.title}
        >
          <div ref="chartContainer" style="height: 100%;"></div>
        </BCardContainer>
      </div>
    )
  },
})
