import { getLogo } from './logo'

const logoMap = new Map<string, string>()
const corsMap = new Map<string, string>()

export const WEB_IMAGE = 'web-image'
export const WEB_IMAGE_CORS = 'data-cors'
export const WEB_IMAGE_CORS_TRUE = 'true'
export const WEB_IMAGE_CALLBACK = 'data-callback'

class WebImage extends HTMLElement {
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
  }

  static get observedAttributes() {
    return ['src']
  }
  private _mounted = false

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (!this._mounted) return
    switch (name) {
      case 'src': {
        this.fetchAndRenderImage(newValue)
        break
      }
    }
  }

  // 这个函数异步还是同步对正常渲染没多大问题，反正都能执行到那一步操作，
  // 但是对于html2canvas 则不行，html2canvas 不会等 web-component 的标签请求，哐哐哐就渲染完了
  // 所以在 fetchAndRenderImage 函数里会有一个同步取 map 中的缓存函数来解决这个 html2canvas 的这个问题
  connectedCallback() {
    if (!this.shadowRoot) return
    // 初始化组件
    const slot = document.createElement('slot')
    this.shadowRoot.appendChild(slot)

    const img = this.querySelector('img')
    if (img) {
      const src = this.getAttribute('src')
      if (src) {
        this.fetchAndRenderImage(src)
      }
    }

    this._mounted = true
  }

  async fetchAndRenderImage(src: string) {
    const img = this.querySelector('img')
    if (!img) return
    const cors = this.getAttribute(WEB_IMAGE_CORS)
    const cachedBase64 = logoMap.get(src)
    if (cachedBase64) {
      img.src = cachedBase64
      return
    }
    const corsBase64 = corsMap.get(src)
    if (cors === WEB_IMAGE_CORS_TRUE && corsBase64) {
      img.src = corsBase64
      return
    }
    const callback = this.getAttribute(WEB_IMAGE_CALLBACK)
    // 获取 src 并处理为 base64
    return await getLogo(src).then(async (base64) => {
      if (base64) {
        logoMap.set(src, base64)
        img.src = base64
      } else {
        if (callback) {
          const _callback = await getLogo(callback)
          corsMap.set(src, _callback)
          if (cors === WEB_IMAGE_CORS_TRUE) {
            img.src = _callback
            return
          }
        }
        img.src = src
      }
    })
  }
}

export function defineWebImage() {
  if (!customElements.get(WEB_IMAGE)) {
    customElements.define(WEB_IMAGE, WebImage)
  }
}

export const clearWebImageCache = () => {
  // 这个要注意，在所有使用的公共位置调用，要不会导致注册在 map 里的数据丢失，造成导出时的渲染不一致
  logoMap.clear()
  corsMap.clear()
}
