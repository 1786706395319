import { defineComponent } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import $classes from './BBindEmailMessage.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BBindEmailMessage = defineComponent({
  name: 'BBindEmailMessage',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {},
  setup(props, { emit }) {
    function handelClickOutside() {
      emit('clickOutside')
    }

    function handleVerifyEmail() {
      emit('verifyEmail')
    }

    return { handelClickOutside, handleVerifyEmail }
  },
  render() {
    return (
      <div
        class="flex items-center"
        {...{
          directives: [
            {
              name: 'click-outside',
              value: this.handelClickOutside,
            },
          ],
        }}
      >
        <div class={$classes.container}>
          <div class={$classes.email}></div>
          <div class="mt-6 text-text-default font-bold text-xl">{this.$t('BBindEmailMessage.bindEmailMsg1')}</div>
          <div class="mt-2 text-gray-90 text-sm">{this.$t('BBindEmailMessage.bindEmailMsg2')}</div>
          <button class={['pt-ui-btn', $classes.verifyBtn]} type="button" data-type="submit" data-size="big" onClick={this.handleVerifyEmail}>
            {this.$t('BBindEmailMessage.verifyEmail')}
          </button>
        </div>
      </div>
    )
  },
})
