import { ElTabPane, ElTabs } from '@pharmsnap/shared/element-ui'
import { IGTabsPaneItem } from '@pharmsnap/shared/types/component'
import { PropType, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import $classes from './GTabs.module.scss'

export const GTabs = defineComponent({
  name: 'GTabs',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    panes: {
      type: Array as PropType<IGTabsPaneItem[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<'card' | 'border' | 'tag'>,
      default: 'border',
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: 'medium',
    },
    flex: {
      type: Boolean,
      default: false,
    },
    flexItem: {
      type: String as PropType<'start' | 'center'>,
      default: 'start',
    },
    noContent: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const closable = ref(false)
    const addable = ref(false)
    const editable = ref(false)
    const position = ref('top')
    const stretch = ref(false)

    const onChange = (key: string) => {
      ctx.emit('input', key)
    }
    const tabsRef = ref()
    let observer: MutationObserver
    onMounted(() => {
      const tabEle = tabsRef.value.$el
      const observedElement = tabEle.querySelector('.el-tabs__nav-wrap') as HTMLElement

      const mutationCallback = (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            const navPrevEle = tabEle.querySelector('.el-tabs__nav-prev') as HTMLElement
            const navNextEle = tabEle.querySelector('.el-tabs__nav-next') as HTMLElement
            const scrollContainer = tabEle.querySelector('.el-tabs__nav-scroll') as HTMLElement
            const scrollNav = tabEle.querySelector('.el-tabs__nav') as HTMLElement
            const match = scrollNav.style.transform.match(/translateX\(([-\d.]+)px\)/)

            if (match) {
              // The extracted value is in match[1]
              const numericValue = parseFloat(match[1])

              if (navPrevEle) {
                if (numericValue === 0) {
                  navPrevEle.style.opacity = '0'
                  navPrevEle.style.cursor = 'default'
                } else {
                  navPrevEle.style.removeProperty('opacity')
                  navPrevEle.style.cursor = 'pointer'
                }
              }

              if (navNextEle) {
                if (numericValue === scrollContainer.clientWidth - scrollNav.scrollWidth) {
                  navNextEle.style.opacity = '0'
                  navNextEle.style.cursor = 'default'
                } else {
                  navNextEle.style.removeProperty('opacity')
                  navNextEle.style.cursor = 'pointer'
                }
              }
            } else {
              console.log('No match found')
            }
          }
          if (mutation.type === 'childList') {
            // Check added nodes for the presence of a specific class
            mutation.addedNodes.forEach(function (addedNode) {
              if (addedNode.nodeType === 1 && (addedNode as HTMLElement).classList.contains('el-tabs__nav-prev')) {
                console.log('The added element has the class "xxx".')
                if (addedNode instanceof HTMLElement) addedNode.style.opacity = '0'
              }
            })
          }
        }
      }

      // Create a MutationObserver instance
      observer = new MutationObserver(mutationCallback)

      // Configure and start observing the target element
      const config = { attributes: true, attributeFilter: ['style'], subtree: true, childList: true }
      observer.observe(observedElement, config)
    })
    onUnmounted(() => {
      observer.disconnect()
    })
    return {
      closable,
      addable,
      editable,
      position,
      stretch,
      onChange,
      tabsRef,
    }
  },
  render() {
    return (
      <div
        class={[
          $classes.container,
          this.animation ? '' : $classes.containerNoAnimation,
          this.type === 'card' ? $classes.containerCard : this.type === 'tag' ? $classes.containerTag : $classes.containerBorder,
          this.flex ? $classes.containerFlex : '',
          this.type === 'border' && this.flexItem === 'center' ? $classes.containerBorderCenter : '',
          this.size === 'small' ? $classes.containerSmall : this.size === 'large' ? $classes.containerLarge : '',
          this.noContent ? $classes.containerNoContent : '',
        ]}
      >
        <ElTabs
          value={this.value}
          ref="tabsRef"
          type={this.type === 'card' ? 'card' : ''}
          closable={this.closable}
          addable={this.addable}
          editable={this.editable}
          tabPosition={this.position}
          stretch={this.stretch}
          onInput={this.onChange}
          onTab-click={(tab: any) => this.$emit('clickMenu', tab.name)}
          class={$classes.tabs}
        >
          {this.panes.map((pane) => (
            <ElTabPane name={pane.key} closable={false} disabled={!!pane.disabled}>
              <template slot="label">
                <span class={$classes.tabItem}>{typeof pane.label === 'function' ? pane.label() : pane.label}</span>
              </template>
              <template slot="default">{this.$slots[pane.key]}</template>
            </ElTabPane>
          ))}
        </ElTabs>
      </div>
    )
  },
})
