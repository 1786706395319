import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { NEWS_LANG_MAP, NEWS_SECTION_LANG_MAP } from '@pharmsnap/shared/constants/enum-lang/news'
import {
  injectLangFieldByKey,
  injectLangFieldByKeyLangMap,
  injectOrgName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'

export function replaceDisplayNameByShortLangMap(data: IAggregationValue): IAggregationValue {
  if (!data.short_name_en) return data
  if (data.short_name_en && data.short_name_en.length === 0) return data
  return {
    ...data,
    display_name_en: data.short_name_en[0] || '',
    display_name_cn: data.short_name_cn?.[0] || '',
  }
}

export const newsFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  phs_target_id: replaceDisplayNameByShortName,
  source: injectLangFieldByKey,
  language: (data) => injectLangFieldByKeyLangMap(NEWS_LANG_MAP, data),
  news_section: (data) => injectLangFieldByKeyLangMap(NEWS_SECTION_LANG_MAP, data),
  master_phs_organization_root_id: injectOrgName,
  master_phs_organization_id: injectOrgName,
}

export function transformNewsAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, newsFieldTransformFnMap, xCorrelationId)
}
