import {
  I18nLang,
  IAlertEntityType,
  IAlertEntityValue,
  IAlertEventRefineType,
  IAlertFrequency,
  IAlertType,
  IAutoCompleteType,
  ILang,
} from '@patsnap/synapse_common_interface'
import { IS_CN_REGION } from '@pharmsnap/shared/src/config'
import { HistoryEmails, MULTI_ENTITY_CONNECTOR } from '@pharmsnap/shared/src/constants'
import { ICommonListParams, sharedCtx } from '@pharmsnap/shared/src/context'
import { formatTimestamp, getDisplayNameDegraded, getLocalStorage, setLocalStorage } from '../..'

export function checkAlertTypeOnlyForBasicRole(alertType: IAlertType) {
  const alertTypeList: IAlertType[] = [
    'patent_detail',
    'patent_search',
    'clinical_trial_result_search',
    'drug_deal_search',
    'translational_medicine_search',
  ]
  return alertTypeList.includes(alertType)
}
export function convertAcType2AlertEventRefineFilterType(type: IAutoCompleteType): IAlertEventRefineType {
  switch (type) {
    case 'Drug':
      return 'drug'
    case 'Organization':
      return 'organization'
    case 'Target':
      return 'target'
    case 'Mechanism':
      return 'mechanism'
    case 'DrugType':
      return 'drug_type'
    case 'Disease':
      return 'disease'
    default:
      throw new Error('convertAcType2AlertEventRefineFilterType: not support type')
  }
}
export function convertAlertEventRefineFilterType2AcType(type: IAlertEventRefineType): IAutoCompleteType {
  switch (type) {
    case 'drug':
      return 'Drug'
    case 'organization':
      return 'Organization'
    case 'target':
      return 'Target'
    case 'mechanism':
      return 'Mechanism'
    case 'drug_type':
      return 'DrugType'
    case 'disease':
      return 'Disease'
  }
}
export function convertAcType2AlertEntityType(type: IAutoCompleteType): IAlertEntityType {
  switch (type) {
    case 'Drug':
      return 'drug'
    case 'Organization':
      return 'organization'
    case 'Target':
      return 'target'
    case 'DrugType':
      return 'drug_type'
    case 'Disease':
      return 'disease'
    default:
      throw new Error('convertAcType2AlertEventRefineFilterType: not support type')
  }
}
export function convertAcType2AlertType(type: IAutoCompleteType): IAlertType {
  switch (type) {
    case 'Drug':
      return 'drug_detail'
    case 'Organization':
      return 'organization_detail'
    case 'Target':
      return 'target_detail'
    case 'DrugType':
      return 'drug_type_detail'
    case 'Disease':
      return 'disease_detail'
    default:
      throw new Error('convertAcType2AlertType: not support type')
  }
}
/**
 * 存储最近发送过的20个收件人邮箱
 * @param emails
 */
export function storeEmails2Local(emails: string[]) {
  const historyEmails = getLocalStorage<string[]>(HistoryEmails) || []
  const newHistoryEmails = Array.from(new Set([...emails, ...historyEmails])).slice(0, 20)
  setLocalStorage(HistoryEmails, JSON.stringify(newHistoryEmails))
}
function getOrdinalIndicator(number: number) {
  const j = number % 10,
    k = number % 100
  if (j == 1 && k != 11) {
    return 'st'
  }
  if (j == 2 && k != 12) {
    return 'nd'
  }
  if (j == 3 && k != 13) {
    return 'rd'
  }
  return 'th'
}
export function getAlertFrequencyText(alertFrequency: IAlertFrequency, locale: I18nLang) {
  const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const zhDayNames = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']

  switch (alertFrequency.type) {
    case 'WEEKLY':
      if (alertFrequency.date !== undefined) {
        return locale === 'cn' ? `每${zhDayNames[alertFrequency.date - 1]}` : `Every ${dayNames[alertFrequency.date - 1]}`
      }
      throw new Error("getAlertFrequencyText: alertFrequency.date can't be undefined")
    case 'MONTHLY':
      if (alertFrequency.date === 29) {
        return locale === 'cn' ? '每月的最后一天' : 'Every last day of the month'
      }
      if (alertFrequency.date !== undefined) {
        return locale === 'cn' ? `每月${alertFrequency.date}号` : `${alertFrequency.date}${getOrdinalIndicator(alertFrequency.date)} of every month`
      }
      throw new Error("getAlertFrequencyText: alertFrequency.date can't be undefined")
    case 'DAILY':
      return locale === 'cn' ? '每天' : 'Everyday'
    default:
      throw new Error("getAlertFrequencyText: alertFrequency.date can't be undefined")
  }
}
export function getSearchAlertTypeSouseLink(type: IAlertType, alertId: string) {
  const params: ICommonListParams = {
    queryId: alertId,
    queryType: 'alert',
  }
  switch (type) {
    case 'drug_search':
      return sharedCtx.router.generatorDrugListPathV2(params)
    case 'drug_search_group_by_organization':
      return sharedCtx.router.generatorDrugListPathGroupByOrgV2(params)
    case 'drug_search_group_by_target':
      return sharedCtx.router.generatorDrugListPathGroupByTargetV2(params)
    case 'drug_search_group_by_drug_type':
      return sharedCtx.router.generatorDrugListPathGroupByDrugTypeV2(params)
    case 'drug_search_group_by_disease':
      return sharedCtx.router.generatorDrugListPathGroupByIndicationV2(params)
    case 'patent_search':
      return sharedCtx.router.generatorPatentListPathV2(params)
    case 'clinical_trial_search':
      return sharedCtx.router.generatorClinicalListPathV2(params)
    case 'clinical_trial_result_search':
      return sharedCtx.router.generatorClinicalResultListPathV2(params)
    case 'translational_medicine_search':
      return sharedCtx.router.generatorTranslationalMedicineListPathV2(params)
    case 'drug_deal_search':
      return sharedCtx.router.generatorDrugDealListPathV2(params)
    default:
      return ''
  }
}

export function getAlertEntityDisplayName(entity: IAlertEntityValue[], lang: I18nLang) {
  return entity
    .map((item) => getDisplayNameDegraded(item, lang))
    .filter(Boolean)
    .join(MULTI_ENTITY_CONNECTOR)
}
export function getAlertEntityIdLink(entity: IAlertEntityValue[]) {
  return entity
    .map((item) => item.id)
    .filter(Boolean)
    .join(',')
}
export function formateEmailAlertTime(time: number, lang: ILang) {
  // 海外版都需要用UTC时区
  return formatTimestamp(time, { locale: lang, isUtc: !IS_CN_REGION })
}
