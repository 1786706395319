import { getInternetExplorerVersion } from '@pharmsnap/shared/utils/common'
import { nextTick, onMounted, onUnmounted, Ref, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'
import $classes from './resize.module.scss'

let isIE = false
let inited = false

function initCompat() {
  if (!inited) {
    inited = true
    isIE = getInternetExplorerVersion() !== -1
  }
}

//github.com/Akryum/vue-resize/blob/master/packages/vue-resize/src/components/ResizeObserver.vue
export function useElementSize(el: Ref<HTMLDivElement | undefined>) {
  const width = ref(0)
  const height = ref(0)

  let object: HTMLObjectElement | null = null

  const compareAndNotify = debounce(() => {
    if (!el.value) return
    width.value = el.value.offsetWidth
    height.value = el.value.offsetHeight
  }, 400)

  // const object = document.createElement('object')
  const addResizeHandlers = () => {
    if (!object) return
    if (!object.contentDocument) return
    if (!object.contentDocument.defaultView) return
    object.contentDocument.defaultView.addEventListener('resize', compareAndNotify)
    compareAndNotify()
  }

  const removeResizeHandlers = () => {
    if (object && object.onload) {
      if (!isIE && object.contentDocument && object.contentDocument.defaultView) {
        object.contentDocument.defaultView.removeEventListener('resize', compareAndNotify)
      }
      if (el.value) {
        el.value.removeChild(object)
        object.onload = null
        object = null
      } else {
        object.onload = null
        object = null
      }
    }
  }

  const addSizeObserver = () => {
    if (object) return
    if (!el.value) return
    object = document.createElement('object')
    object.setAttribute('aria-hidden', 'true')
    object.setAttribute('tabindex', '-1')
    object.onload = addResizeHandlers
    object.type = 'text/html'
    object.className = $classes.resize
    if (isIE) {
      el.value.appendChild(object)
    }
    object.data = 'about:blank'
    if (!isIE) {
      el.value.appendChild(object)
    }
  }

  onMounted(async () => {
    initCompat()
    if (!el.value) return
    nextTick(() => {
      if (el.value) {
        width.value = el.value.offsetWidth
        height.value = el.value.offsetHeight
      }
    })
    addSizeObserver()
  })

  onUnmounted(() => {
    removeResizeHandlers()
  })

  watch(el, () => {
    if (el.value) {
      nextTick(() => {
        if (el.value) {
          width.value = el.value.offsetWidth
          height.value = el.value.offsetHeight
        }
      })
      addSizeObserver()
    } else {
      removeResizeHandlers()
    }
  })

  return {
    width,
    height,
  }
}
