import type { IBaseSourceLangName, IDrugDevStatusSearchField } from '@patsnap/synapse_common_interface'
import { ISelectedFilterItem } from '../components/business/BAdvanceFilter/type'
export interface IRankingData {
  items: IRankingItem[]
}

export interface IPhaseItem {
  phase: string
  count: number
  display_name_cn: string
  display_name_en: string
  aggregation_field: string
  other_info?: {
    drug_ids?: string[]
  }
}
export interface IEntityItem {
  display_name_en: string
  display_name_cn: string
  entity_id: string
  short_name: IBaseSourceLangName[]
  entity_name_cn?: string
  entity_name_en?: string
}
export interface IRankingItem {
  entity_id: string
  rank_change: string
  phase_items: IPhaseItem[]
  aggregation_field: string
  entity_items: IEntityItem[]
}
export enum START_DATE {
  ONE = '1',
  THREE = '3',
  FIVE = '5',
}

export enum TOP_NUMBER {
  TEN = '10',
  TWENTY = '20',
}

export enum COUNTRY {
  CN = 'cn',
  GLOBAL = 'global',
}

export enum COMPETITIVE_TYPE {
  TARGET = 'target',
  DISEASE = 'disease',
  DRUG_TYPE = 'drug_type',
}
export enum COMPETITIVE_SUB_TYPE {
  RD = 'RD',
  CT = 'CT',
}
export interface ITopParam {
  top: TOP_NUMBER
  country: COUNTRY
  data_type: COMPETITIVE_TYPE
}

export interface ITopClinicalParam extends ITopParam {
  start_date?: START_DATE
}

export interface ITopRDParam extends ITopParam {
  phase?: string
}

export enum FILTER_FIELD {
  CURRENT_REGION = 'CURRENT_REGION',
  FIRST_SELECT = 'FIRST_SELECT',
  TOP = 'TOP',
}

export interface ICompetitiveResult<T> {
  items: T[]
  update_time: string
  from?: number
  to?: number
}

// 竞争格局用于展示的过滤项
export type ICompetitiveDisplayFilterField = Extract<
  IDrugDevStatusSearchField,
  'ORGANIZATION_ID_ONEID' | 'TARGET_ID_EXTEND' | 'DISEASE_ID' | 'DRUG_TYPE' | 'COUNTRY_WITH_EU'
>

// 竞争格局过滤项：机构涉及开启父公司开关，有2个field
export type ICompetitiveFilterField = ICompetitiveDisplayFilterField | 'ORG_ROOT_ID_ONEID'

export type ICompetitiveSelectData = Partial<
  Record<
    ICompetitiveDisplayFilterField,
    {
      field: ICompetitiveFilterField
      items: ISelectedFilterItem[]
    }
  >
>
