import '@patsnap-ui/icon/assets/solid/ExportPdf.svg'
import type { IBaseLangName } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import dayjs from 'dayjs'
import { isUndefined } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import {
  filterTargetsBySearchString,
  renderBlockLimitedLinks,
  renderLimited,
  renderLimitedDisease,
  renderLimitedDisease_customMore,
  renderLimitedDrug,
  renderLimitedDrug_customMore,
  renderLimitedTargets,
  renderLimitedTargets_customMore,
  renderPDFLink,
  renderPDFLinkInDetail,
  renderPopoverLimited,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedDrugAsync,
  renderPopoverLimitedTargetsAsync,
  renderPopoverLimitedText,
  renderTag,
} from '.'
import { BEntityTag, BLimitedMore, BOrganizationItem, GLimitedContainer, GLink, GTranslate } from '../components'
import { EMPTY_PLACEHOLDER } from '../constants'
import { LITERATURE_DISCIPLINE_CATEGORY } from '../constants/enum-lang/literature'
import { sharedCtx } from '../context'
import { I18nLang } from '../i18n'
import type {
  Abst,
  Abstract,
  IBaseDrugInfo,
  IBaseIdViewItem,
  IBaseOrgInfo,
  IBaseTargetInfo,
  IJournalPartitionLevel,
  ILang,
  Institution,
  IPaperDetail,
  ITranslateLang,
} from '../types'
import { formatTimestamp, formatURL, generatorLangFieldISTranslateFn, getHost, pickBodyContentFromHtmlDoc, pickFirstSpecialLangTitle } from '../utils'

export function getLang(i18n: IVueI18n): ILang {
  return i18n.locale.toLocaleUpperCase() as ILang
}

/**
 * 返回机翻字段文本
 * @param data
 * @param field
 * @returns
 */
export function renderPaperTranslationField(data: IPaperDetail, field: 'title_translation'): string {
  return data[field]?.title || ''
}

export function renderPaperTitleInTable(data: IPaperDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const translate = generatorLangFieldISTranslateFn(customSettingTranslation)(data.title)
  const translateText = renderPaperTranslationField(data, 'title_translation')
  const executeTranslate = translateText ? translate : false

  return (
    <GTranslate execute={executeTranslate} content={translateText || EMPTY_PLACEHOLDER}>
      <GLink
        href={sharedCtx.router.generatorPaperDetailPath(data.paper_id)}
        name={renderLiteratureTitleText(data, $i18n)}
        newTab={true}
        defaultColor="blue"
        class="whitespace-pre-wrap text-sm leading-6"
      ></GLink>
    </GTranslate>
  )
}

export function renderPaperDetailText(text: string) {
  return <div class="text-sm text-black-default" style={{ 'word-break': 'break-word' }} domPropsInnerHTML={text || EMPTY_PLACEHOLDER}></div>
}

export function renderPaperTableText(text: string) {
  return <GLink name={text} class="whitespace-pre-wrap text-sm leading-6"></GLink>
}

// author数据目前是全量
export function renderPaperAuthorInTable(data: IPaperDetail) {
  return renderPopoverLimitedText(data?.biblio?.author?.map((item) => item.original_name) || [], 3)
}

function formatePaperTime(str: number | undefined, lang: ILang, isUtc = false) {
  if (!str) {
    return EMPTY_PLACEHOLDER
  }
  return formatTimestamp(String(str), { locale: lang, isUtc })
}

export function renderLiteratureTitleText(data: IPaperDetail, i18n: IVueI18n) {
  return pickFirstSpecialLangTitle(data.title, getLang(i18n))
}

export function renderPaperPopoverLimitedDrug(data: IPaperDetail, limit = 3) {
  return renderPopoverLimitedDrugAsync(data.metadata?.drug_id_view || [], data.metadata?.drug_id, limit)
}

export function renderPaperPopoverLimitedDisease(data: IPaperDetail, limit = 3) {
  return renderPopoverLimitedDiseaseAsync(data.metadata?.disease_id_view || [], data.metadata?.disease_id, limit)
}

export function renderPaperPopoverLimitedTarget(data: IPaperDetail, limit = 3) {
  return renderPopoverLimitedTargetsAsync(data.metadata?.target_id_view || [], data.metadata?.target_id, limit)
}

export function renderPaperAuthor(
  data: IPaperDetail,
  options?: { ordinal?: number; superscript?: number; handleClickSuperscript?: (data: { ordinal: number; superscript: number }) => void }
) {
  const { ordinal, superscript, handleClickSuperscript } = options || {}
  const canSuperscriptClick = !!handleClickSuperscript
  return (data.biblio?.author || []).map((item) => (
    <span>
      {item.original_name}
      <sup class="italic">
        {(item.superscript || []).map((superscriptNumber) => (
          <span
            class={{
              'mr-0.5': true,
              'cursor-pointer text-blue-default px-1 rounded-sm hover:bg-gray-30': canSuperscriptClick,
              'bg-gray-30': ordinal === item.ordinal && superscript === superscriptNumber,
            }}
            onClick={() => canSuperscriptClick && handleClickSuperscript({ ordinal: item.ordinal, superscript: superscriptNumber })}
          >
            {superscriptNumber}
          </span>
        ))}
      </sup>
      ;{' '}
    </span>
  ))
}

export function renderPaperAuthorInCard(data: IPaperDetail) {
  return renderPopoverLimited(data.biblio?.author || [], {
    limitCount: 3,
    render: (item) => <BEntityTag title={item.original_name}>{item.original_name || EMPTY_PLACEHOLDER}</BEntityTag>,
  })
}

export function renderPaperPublicationDate(data: IPaperDetail, lang: ILang) {
  const date = data?.biblio?.publication?.date
  if (date) {
    if (/\d{4}(^(00)\d{2})|(\d{2}(00)$)/g.test(date)) {
      return <div class="patent-content">{data.biblio?.year}</div>
    } else {
      return <div class="patent-content">{formatePaperTime(+date, lang)}</div>
    }
  }
  return <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderPaperEPubDate(data: IPaperDetail, lang: ILang) {
  const date = data?.biblio?.epub_date
  if (date) {
    if (/\d{4}(^(00)\d{2})|(\d{2}(00)$)/g.test(date)) {
      return <div>{data.biblio?.year}</div>
    } else {
      return <div>{formatePaperTime(+date, lang)}</div>
    }
  }
  return <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderPaperTitleInDetail(data: IPaperDetail, $i18n: IVueI18n, translate: boolean) {
  const translateText = renderPaperTranslationField(data, 'title_translation')
  return <GTranslate execute={translate} content={translateText}></GTranslate>
}

// 数据是全量
export function renderPaperSponsorInList(data: IPaperDetail, limitCount = 30, appendToBody?: boolean) {
  const institutionList = (data?.biblio?.institution || []).filter((institution) => institution.original_name)
  return renderPopoverLimited(institutionList, {
    limitCount,
    placement: 'bottom',
    render: (item: Institution) => (
      <BOrganizationItem
        data={
          item.normalized_master_entity_id_view ||
          ({
            entity_id: item.normalized_master_entity_id,
            name_en: item.original_name,
            name_cn: item.original_name,
            entity_name_cn: item.original_name,
            entity_name_en: item.original_name,
          } as IBaseOrgInfo)
        }
      ></BOrganizationItem>
    ),
    appendToBody,
  })
}

export function renderPaperSponsorInDetail(data: IPaperDetail, limitCount = 30) {
  return renderPopoverLimited(data?.biblio?.institution || [], {
    limitCount,
    render: (item: Institution) => (
      <span class="text-sm relative">
        <sup class="absolute left-0 top-0 italic">{item.ordinal}</sup>
        <BOrganizationItem
          data={
            item.normalized_master_entity_id_view ||
            ({
              entity_id: item.normalized_master_entity_id,
              name_en: item.original_name,
              name_cn: item.original_name,
            } as IBaseOrgInfo)
          }
        ></BOrganizationItem>
      </span>
    ),
  })
}

export function renderPaperAbstText(data: Abstract) {
  if (!data) return EMPTY_PLACEHOLDER
  const htmlStr = data.abstract_str_html
  if (htmlStr) {
    const content = pickBodyContentFromHtmlDoc(htmlStr || '')
    if (content) return <div class="pharmsnap-literature-abst" domPropsInnerHTML={content}></div>
  }
  if (data.abst.length) {
    return renderPaperAbstTextInTable(data.abst)
  }

  return EMPTY_PLACEHOLDER
}

export function renderPaperAbst(data: IPaperDetail, $i18n: IVueI18n, translate: boolean, limited = false) {
  function wrap<T>(slot: T) {
    if (!limited) {
      return slot
    }
    return (
      <GLimitedContainer
        limitLines={5}
        newTab={true}
        href={sharedCtx.router.generatorPaperDetailPath(data.paper_id)}
        moreColor="#f0f1f3"
        moreText={$i18n.tc('common.showAll')}
      >
        {slot}
      </GLimitedContainer>
    )
  }
  const currentLangAbst = data.abstract
    ? ((data.abstract.find((item) => item.lang === getLang($i18n)) || data.abstract.find((item) => item.lang === 'EN')) as Abstract)
    : null
  return (
    <GTranslate execute={translate && !!data.abstract_translation?.abst.length}>
      <div slot="content" class="clear-left">
        {wrap(renderPaperAbstText(data.abstract_translation))}
      </div>
      <div>{currentLangAbst ? wrap(renderPaperAbstText(currentLangAbst)) : EMPTY_PLACEHOLDER}</div>
    </GTranslate>
  )
}

export function renderPaperAbstTextInTable(data: Abst[]) {
  return data?.map((item) => {
    const label = item.label ? (item.label.endsWith(':') ? item.label : `${item.label}: `) : ''
    return (
      <div class="whitespace-pre-wrap text-sm leading-5 text-text-t2 break-words">
        <span class="float-left mr-1">{label}</span>
        {renderPaperDetailText(item.text)}
      </div>
    )
  })
}

export function renderPaperHeaderInCard(
  data: IPaperDetail,
  $i18n: IVueI18n,
  translate: boolean,
  moreText: string,
  isCN: boolean,
  author: string | undefined,
  renderImg: () => JSX.Element | null
) {
  const currentLangAbst = data.abstract
    ? ((data.abstract.find((item) => item.lang === getLang($i18n)) || data.abstract.find((item) => item.lang === 'EN')) as Abstract)
    : null

  const citeData = renderCite(data)
  const ePub = data?.biblio?.epub_date
  return (
    <div>
      <div class="flex items-end mb-4">
        <div class="flex-1 w-20">
          <div class="my-1.5 text-sm text-text-t2 line-clamp-3" style="max-width: 100%">
            {`${$i18n.t('BCard.author')}: ${author || EMPTY_PLACEHOLDER}`}
          </div>
          {citeData || ePub ? (
            <div class="flex flex-wrap my-1.5 text-sm">
              {citeData ? (
                <div class="text-text-t2 mr-28 flex">
                  <span class="flex-shrink-0">{`${$i18n.t('BCard.cite')}:`}</span>
                  <span class="inline-block text-text-t1 text-sm ml-2">{citeData}</span>
                </div>
              ) : null}
              {ePub ? (
                <div class="text-text-t2 flex">
                  <span class="flex-shrink-0">{`${$i18n.t('BCard.ePub')}:`}</span>
                  <span class="inline-block text-text-t1 text-sm ml-2">{renderPaperEPubDate(data, getLang($i18n))}</span>
                </div>
              ) : null}
            </div>
          ) : null}

          {currentLangAbst && currentLangAbst.abst ? (
            <GLimitedContainer
              limitLines={5}
              class="mr-2 mt-1.5"
              newTab={true}
              href={sharedCtx.router.generatorPaperDetailPath(data.paper_id)}
              moreText={moreText}
            >
              {renderPaperAbstTextInTable(currentLangAbst.abst)}
            </GLimitedContainer>
          ) : (
            <div class="text-sm mt-1.5">{`${$i18n.t('BCard.abstract')}: ${EMPTY_PLACEHOLDER}`}</div>
          )}
        </div>
        {renderImg()}
      </div>
      {translate ? (
        <div class={['bg-gray-20 p-2 text-text-t1 text-sm my-2']}>
          <span class="float-left -ml-2 font-semibold">
            {isCN ? <span>【</span> : <span class="ml-1">[</span>}
            {$i18n.t('common.translate')}
            {isCN ? <span>】</span> : <span class="mr-1">]</span>}
          </span>
          {renderPaperAbstTextInTable(data.abstract_translation?.abst) && (
            <GLimitedContainer
              limitLines={5}
              newTab={true}
              href={sharedCtx.router.generatorPaperDetailPath(data.paper_id)}
              moreColor="#f0f1f3"
              moreText={moreText}
            >
              {renderPaperAbstTextInTable(data.abstract_translation?.abst)}
            </GLimitedContainer>
          )}
        </div>
      ) : null}
    </div>
  )
}

export function renderPaperLimitedIndication(data: IPaperDetail, moreRender: JSX.Element) {
  return renderLimitedDisease_customMore(data.metadata?.disease_id_view || [], 10, moreRender)
}

export function renderPaperLimitedTargets(data: IPaperDetail, moreRender: JSX.Element) {
  return renderLimitedTargets_customMore(data.metadata?.target_id_view || [], 10, moreRender)
}

export function renderPaperLimitedDrugs(data: IPaperDetail, moreRender: JSX.Element) {
  return renderLimitedDrug_customMore(data.metadata?.drug_id_view || [], 10, moreRender)
}

function _findNameObjByLang(items: IBaseLangName[], lang: ILang): IBaseLangName | undefined {
  if (items.length) {
    return (
      items.find((item) => {
        return item.lang === lang
      }) || items[0]
    )
  }
}
function _matchingSearchContent(items: IBaseLangName[], lang: ILang, searchContent: string): boolean {
  const nameObj = _findNameObjByLang(items, lang)
  const reg = new RegExp(searchContent, 'i')
  return !!nameObj && nameObj.name.search(reg) !== -1
}
function _filterIndication(items: any[], searchContent: string, lang: ILang): any[] {
  return (items as IBaseIdViewItem[]).filter((item) => {
    return _matchingSearchContent(item.disease_name as IBaseLangName[], lang, searchContent)
  })
}
function _filterDrug(items: any[], searchContent: string, lang: ILang): any[] {
  return (items as IBaseDrugInfo[]).filter((item) => {
    return _matchingSearchContent(item.drug_name as IBaseLangName[], lang, searchContent)
  })
}
function _renderPaperViewAllDrug(data: IBaseDrugInfo[]) {
  return renderLimitedDrug(data || [], data ? data.length : 0)
}
function _renderPaperViewAllIndication(data: IBaseIdViewItem[]) {
  return renderLimitedDisease(data || [], data ? data.length : 0)
}
function _renderPaperViewAllTargets(data: IBaseTargetInfo[]) {
  return renderLimitedTargets(data || [], data ? data.length : 0)
}

// 插槽内容
export function renderPaperIndicationMore(data: IPaperDetail, title?: string): JSX.Element {
  return (
    <BLimitedMore
      items={data?.metadata?.disease_id_view}
      render={_renderPaperViewAllIndication}
      filterItem={_filterIndication}
      title={title}
    ></BLimitedMore>
  )
}

// 插槽内容
export function renderPaperTargetsMore(data: IPaperDetail, title?: string): JSX.Element {
  return (
    <BLimitedMore
      items={data?.metadata?.target_id_view}
      render={_renderPaperViewAllTargets}
      filterItem={filterTargetsBySearchString}
      title={title}
    ></BLimitedMore>
  )
}

// 插槽内容
export function renderPaperDrugMore(data: IPaperDetail, title?: string): JSX.Element {
  return <BLimitedMore items={data?.metadata?.drug_id_view} render={_renderPaperViewAllDrug} filterItem={_filterDrug} title={title}></BLimitedMore>
}

export function renderPaperDIOLink(data: IPaperDetail) {
  if (data.doi) return renderBlockLimitedLinks([{ name: data.doi, href: `https://doi.org/${data.doi}` }], 10, 'blue')
  return EMPTY_PLACEHOLDER
}

export function renderPaperPDFLink(
  data: IPaperDetail,
  $i18n: IVueI18n,
  translate?: ITranslateLang,
  options = { emptyPlaceholder: EMPTY_PLACEHOLDER, isInDetail: false }
) {
  const { emptyPlaceholder, isInDetail } = options
  const list = data?.biblio?.pdf
  if (!list) return emptyPlaceholder

  const pdf = list.find((i) => !!i.source && !!i.source_url)

  if (!pdf?.source_url) return emptyPlaceholder
  return isInDetail ? renderPDFLinkInDetail(pdf.source_url, $i18n, emptyPlaceholder) : renderPDFLink(pdf.source_url, $i18n)
}

export function renderPaperWebsiteLink(data: IPaperDetail) {
  const hasPubmed = data?.pubmed_id && !!data?.pubmed_id.length
  const hasSourceUrl = data?.paper_source && !!data?.paper_source.length && data?.paper_source.some((i) => !!i.source_url)

  if (!hasPubmed && !hasSourceUrl) return undefined
  const links: { name: string; href: string }[] = []
  if (hasPubmed) {
    const id = data?.pubmed_id && data?.pubmed_id[0]
    const href = formatURL(`https://pubmed.ncbi.nlm.nih.gov/${id}`)
    links.push({
      name: getHost(href),
      href,
    })
  }

  const sourceItem = data?.paper_source && data.paper_source.find((i) => !!i.source_url)

  if (sourceItem) {
    links.push({
      name: getHost(sourceItem.source_url),
      href: formatURL(sourceItem.source_url),
    })
  }

  return renderBlockLimitedLinks(links, 10, 'blue')
}
function renderPaperClinicalTrialItem(data: { name?: string; id?: string }) {
  if (data.id) {
    return (
      <span
        class="cursor-pointer hover:underline text-blue-default text-sm leading-6"
        onClick={() => openPageWithoutPending(() => (data.id ? sharedCtx.router.generatorClinicalPath(data.id) : ''))}
      >
        {data.name}
      </span>
    )
  }
  return <span class="text-sm leading-6">{data.name}</span>
}

export function renderPaperClinicalTrial(data: IPaperDetail) {
  const cts = data.metadata?.ct_number?.map((o) => o.clinical_trial_items || []).flat() || []
  return cts.length
    ? renderLimited(cts, {
        limitCount: 5,
        render: renderPaperClinicalTrialItem,
        gap: { x: 8, y: 8 },
      })
    : EMPTY_PLACEHOLDER
}

export function getLiteratureIF(data: IPaperDetail) {
  const { biblio } = data
  if (!biblio?.journal?.journal_impact_factor) return
  const patsnapIF = biblio.journal.journal_impact_factor.find((o) => o.source?.toLowerCase() === 'web of science')
  if (!patsnapIF || !patsnapIF.patsnap_if) return
  return patsnapIF.patsnap_if
}

export function renderLiteratureIfInTable(data: IPaperDetail) {
  const patsnapIF = getLiteratureIF(data)
  if (!patsnapIF) return EMPTY_PLACEHOLDER
  return <div>{patsnapIF}</div>
}

export function renderCite(data: IPaperDetail) {
  const { biblio } = data
  if (!biblio) return
  const { journal, publication } = biblio
  const isMissData =
    isUndefined(journal) ||
    isUndefined(journal.abbr_name) ||
    isUndefined(publication.date) ||
    isUndefined(publication.issue) ||
    isUndefined(publication.volume)
  if (isMissData) return
  return (
    <div>
      <span class="italic">{journal.abbr_name}</span>
      <span> {dayjs(publication.date).format('YYYY')},</span>
      <span> {publication.issue},</span>
      <span class="font-semibold"> {publication.volume}</span>
      {(publication.start_page || publication.end_page) && <span>,</span>}
      <span> {[publication.start_page, publication.end_page].filter(Boolean).join('-')}</span>
    </div>
  )
}

export function renderLiteraturePartitionLevel(data: IPaperDetail, $i18n: IVueI18n) {
  const journalPartition = data.biblio?.journal?.journal_partition || []
  if (journalPartition.length) {
    let bigCategoryInfo: IJournalPartitionLevel | undefined = undefined
    for (const partition of journalPartition) {
      const partitionLevel = partition.partition_level || []
      const bigCategory = partitionLevel.find((level) => level.type === '大类')
      if (bigCategory) {
        bigCategoryInfo = bigCategory
        break
      }
    }
    if (bigCategoryInfo) {
      const locale = $i18n.locale as I18nLang
      const partitionLevel = bigCategoryInfo.partition_level
      const partition = locale === 'cn' ? `${partitionLevel}区` : `Q${partitionLevel}`
      const disciplineCategory = bigCategoryInfo.discipline_category
      const category = LITERATURE_DISCIPLINE_CATEGORY[disciplineCategory]?.[locale] || disciplineCategory
      if (partition || category) {
        return (
          <span>
            {partition}
            {partition && category && <span class="mx-0.5">·</span>}
            {category}
          </span>
        )
      }
      return null
    }
    return null
  }
  return null
}

export function renderLiteraturePublicationAndJournalInfo(data: IPaperDetail, $i18n: IVueI18n) {
  const publicationDom = data?.biblio?.publication?.date ? renderPaperPublicationDate(data, getLang($i18n)) : null
  const journalName = data.biblio?.journal?.nlm_display_name || data.biblio?.journal?.name || null
  const journalNameDom = journalName ? (
    <div class="line-clamp-1">
      {!!publicationDom && <span class="mx-0.5">·</span>}
      {journalName}
    </div>
  ) : null
  const literaturePartitionLevelDom = renderLiteraturePartitionLevel(data, $i18n)
  // const patsnapIF = getLiteratureIF(data)
  return (
    <div class="flex items-center leading-5 text-sm text-text-t2">
      <div class="flex-shrink-0">{publicationDom}</div>
      {journalNameDom}
      {/* {patsnapIF ? <div class="ml-2 bg-gray-20 rounded h-5 px-2 text-xs">{`IF ${patsnapIF}`}</div> : null} */}
      {!!literaturePartitionLevelDom && (
        <div class={(!!publicationDom || !!journalNameDom) && 'ml-2 bg-gray-20 rounded h-5 px-2 text-xs flex-shrink-0'}>
          {literaturePartitionLevelDom}
        </div>
      )}
    </div>
  )
}

export function renderLiteratureType(data: IPaperDetail, isTag = true) {
  /** 后端对文献类型进行了过滤，理论上文献只有一个类型，所以取第一个 */
  const literatureType = (data.related_pub_types || [])[0]
  if (literatureType) return isTag ? renderTag(literatureType) : literatureType
  return null
}

export function renderLiteratureOA(data: IPaperDetail) {
  const isOpenAccess = data.biblio?.journal?.is_open_access === '1'
  if (isOpenAccess) {
    return renderTag('OA', { bgColor: '#E3F1DE', textColor: '#1C6606' })
  }
  return null
}
