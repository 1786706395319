import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import { getClinicalPieColor, getPhaseColor } from '@pharmsnap/shared/src/utils'
import { IUseAnalysisOneDimChartEntityOptions } from '../../type'
import { useAnalysisOneDimChartEntity } from '../useAnalysisOneDimChartEntity'

export function useAnalysisTrialOneDimChartEntity(options: Omit<IUseAnalysisOneDimChartEntityOptions, 'getChartConfig'>) {
  const getPieChartConfig: IUseAnalysisOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField } = config

    if (aggField === 'NORMALIZED_PHASE') {
      return {
        sorter: (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        },
        color: (item) => getPhaseColor(item.key),
      }
    }
    if (aggField === 'MULTI_CENTER') {
      return {
        color: (item) => getClinicalPieColor(item.key),
      }
    }
  }

  const getLineChartConfig: IUseAnalysisOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField } = config

    if (aggField === 'STUDY_FIRST_POSTED_DATE_YEAR') {
      return {
        categoryPicker: (item) => item.key,
      }
    }
  }

  const { chartEntity, dataList, countFieldName, categoryFieldName } = useAnalysisOneDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'pie') return getPieChartConfig(type, config)
      if (type === 'line') return getLineChartConfig(type, config)
      return
    },
  })

  return {
    chartEntity,
    dataList,
    countFieldName,
    categoryFieldName,
  }
}
