import 'setimmediate'
import { IHttp } from '../http/types'
import { AccountApi } from './account'
import { AnalysisApi } from './analysis'
import { ApprovalApi } from './approvalApi'
import { ATCApi } from './atcApi'
import { ClinicalResultApi } from './clinicalResultApi'
import { CompareApi } from './compareApi'
import { CompetitiveApi } from './competitiveApi'
import { CopilotApi } from './copilotApi'
import { CoreApi } from './coreApi'
import { DashboardApi } from './dashboardApi'
import { DictionaryApi } from './dictionaryApi'
import { DiseaseApi } from './diseaseApi'
import { DownloadApi } from './downloadApi'
import { DrugApi } from './drugApi'
import { DrugDealApi } from './drugDealApi'
import { DrugTypeApi } from './drugTypeApi'
import { EmailAlertApi } from './emailAlertApi'
import { FinancialApi } from './financialApi'
import { FundingApi } from './fundingApi'
import { HistoryApi } from './historyApi'
import { HomeApi } from './homeApi'
import { InvestmentApi } from './investmentApi'
import { KnowledgeGraphApi } from './knowledgeGraphApi'
import { LiteratureApi } from './literatureApi'
import { LoginApi } from './login'
import { Ls360Api } from './ls360Api'
import { MechanismApi } from './mechanismApi'
import { NewsApi } from './newsApi'
import { OrganizationApi } from './organizationApi'
import { OutcomeApi } from './outcomeApi'
import { PatentApi } from './patentApi'
import { RegionApi } from './regionApi'
import { ReportApi } from './reportApi'
import { SearchApi } from './searchApi'
import { SearchHistoryApi } from './searchHistoryApi'
import { StrapiApi } from './strapi'
import { StructureApi } from './structureApi'
import { StudyCodeApi } from './studyCodeApi'
import { TagApi } from './tagApi'
import { TargetApi } from './targetApi'
import { TopicApi } from './topicApi'
import { TrackingApi } from './trackingApi'
import { TranslateApi } from './translate'
import { TranslationalMedicineApi } from './translationalMedicineApi'
import { TrialApi } from './trialApi'
import { UserCertApi } from './userCertApi'
import { WorkspaceApi } from './workspaceApi'
// import { IApiService } from './types'

export function createApis(params: {
  http: IHttp
  baseUrl: string
  passportBaseUrl: string
  accountBaseUrl: string
  strapiBaseUrl: string
  weChatBaseUrl: string
  analysisBaseUrl: string
  chemicalBaseUrl: string
}) {
  const { http, baseUrl, strapiBaseUrl, passportBaseUrl, accountBaseUrl, weChatBaseUrl, analysisBaseUrl, chemicalBaseUrl } = params
  return {
    drug: new DrugApi(http, baseUrl),
    organization: new OrganizationApi(http, baseUrl),
    disease: new DiseaseApi(http, baseUrl),
    target: new TargetApi(http, baseUrl),
    history: new HistoryApi(http, baseUrl),
    core: new CoreApi(http, baseUrl),
    mechanism: new MechanismApi(http, baseUrl),
    atc: new ATCApi(http, baseUrl),
    tag: new TagApi(http, baseUrl),
    trial: new TrialApi(http, baseUrl),
    analysis: new AnalysisApi(http, baseUrl),
    search: new SearchApi(http, baseUrl),
    login: new LoginApi(http, baseUrl, passportBaseUrl, accountBaseUrl, weChatBaseUrl),
    competitive: new CompetitiveApi(http, baseUrl),
    account: new AccountApi(http, baseUrl),
    dashboard: new DashboardApi(http, baseUrl),
    dictionary: new DictionaryApi(http, baseUrl),
    home: new HomeApi(http, baseUrl),
    patent: new PatentApi(http, baseUrl, analysisBaseUrl),
    news: new NewsApi(http, baseUrl),
    literature: new LiteratureApi(http, baseUrl),
    searchHistory: new SearchHistoryApi(http, baseUrl),
    emailAlert: new EmailAlertApi(http, baseUrl),
    financial: new FinancialApi(http, baseUrl),
    funding: new FundingApi(http, baseUrl),
    investment: new InvestmentApi(http, baseUrl),
    region: new RegionApi(http, baseUrl),
    topic: new TopicApi(http, baseUrl),
    approval: new ApprovalApi(http, baseUrl),
    report: new ReportApi(http, baseUrl),
    knowledgeGraph: new KnowledgeGraphApi(http, baseUrl),
    compare: new CompareApi(http, baseUrl),
    download: new DownloadApi(http, baseUrl),
    tracking: new TrackingApi(http, baseUrl),
    userCert: new UserCertApi(http, baseUrl),
    strapi: new StrapiApi(http, strapiBaseUrl),
    drugType: new DrugTypeApi(http, baseUrl),
    copilot: new CopilotApi(http, baseUrl),
    clinicalResult: new ClinicalResultApi(http, baseUrl),
    outcome: new OutcomeApi(http, baseUrl),
    studyCode: new StudyCodeApi(http, baseUrl),
    drugDeal: new DrugDealApi(http, baseUrl),
    workspace: new WorkspaceApi(http, baseUrl),
    structure: new StructureApi(http, baseUrl, chemicalBaseUrl),
    translationalMedicine: new TranslationalMedicineApi(http, baseUrl),
    ls360Api: new Ls360Api(http, baseUrl),
    translate: new TranslateApi(http, baseUrl),
  }
}
