type I_REGION = 'cn' | 'us'
// 如果是用release-synapse-us.zhihuiya.com打开的, 则将环境变量设置成美国版
const isReleaseUs = /release.*-us\./.test(window.location.origin)
export const VUE_APP_CLIENT_ID = localStorage.getItem('VUE_APP_CLIENT_ID') || (process.env.VUE_APP_CLIENT_ID as string)
export const VUE_APP_PASSPORT_BASEURL = localStorage.getItem('VUE_APP_PASSPORT_BASEURL') || (process.env.VUE_APP_PASSPORT_BASEURL as string)
export const VUE_APP_WECHAT_BASEURL = localStorage.getItem('VUE_APP_WECHAT_BASEURL') || (process.env.VUE_APP_WECHAT_BASEURL as string)
export const VUE_APP_DOMAIN_URL = localStorage.getItem('VUE_APP_DOMAIN_URL') || (process.env.VUE_APP_DOMAIN_URL as string)
export const VUE_APP_ANALYTICS_URL = localStorage.getItem('VUE_APP_ANALYTICS_URL') || (process.env.VUE_APP_ANALYTICS_URL as string)
if (isReleaseUs) {
  localStorage.setItem('VUE_APP_API_URL', 'https://release-synapse-us.zhihuiya.com/api')
}
export const VUE_APP_API_URL = localStorage.getItem('VUE_APP_API_URL') || (process.env.VUE_APP_API_URL as string)
if (isReleaseUs) {
  localStorage.setItem('VUE_APP_STRAPI_URL', 'https://release-synapse-us.zhihuiya.com/strapi')
}
export const VUE_APP_STRAPI_URL = localStorage.getItem('VUE_APP_STRAPI_URL') || (process.env.VUE_APP_STRAPI_URL as string)
export const VUE_APP_CLIENT_NAME = 'synapse'
export const VUE_APP_ACCOUNT_BASEURL = localStorage.getItem('VUE_APP_ACCOUNT_BASEURL') || (process.env.VUE_APP_ACCOUNT_BASEURL as string)
export const VUE_APP_BIO_URL = localStorage.getItem('VUE_APP_BIO_URL') || (process.env.VUE_APP_BIO_URL as string)
export const VUE_APP_LS360_URL = localStorage.getItem('VUE_APP_LS360_URL') || (process.env.VUE_APP_LS360_URL as string)
export const VUE_APP_PRODUCT_CHEMICAL_URL =
  localStorage.getItem('VUE_APP_PRODUCT_CHEMICAL_URL') || (process.env.VUE_APP_PRODUCT_CHEMICAL_URL as string)
export const VUE_APP_STATIC_COMMON_PATH = localStorage.getItem('VUE_APP_STATIC_COMMON_PATH') || (process.env.VUE_APP_STATIC_COMMON_PATH as string)
/** synapse静态资源访问路径 */
export const VUE_APP_STATIC_PATH = localStorage.getItem('VUE_APP_PUBLIC_PATH') || (process.env.VUE_APP_PUBLIC_PATH as string)
if (isReleaseUs) {
  localStorage.setItem('VUE_APP_DEPLOY_REGION', 'us')
}
if (/region=([^&]*)/.test(window.location.search)) {
  const matched = window.location.search.match(/region=([^&]*)/)
  if (matched && ['cn', 'us'].includes(matched[1])) {
    localStorage.setItem('VUE_APP_DEPLOY_REGION', matched[1])
  }
}
export const VUE_APP_DEPLOY_REGION = (localStorage.getItem('VUE_APP_DEPLOY_REGION') as I_REGION) || (process.env.VUE_APP_DEPLOY_REGION as I_REGION)
export const VUE_APP_PHARMSNAP_MF_PDF_PATH =
  localStorage.getItem('VUE_APP_PHARMSNAP_MF_PDF_PATH') || (process.env.VUE_APP_PHARMSNAP_MF_PDF_PATH as string)
export const VUE_APP_ACCOUNT_DOMAIN_URL = localStorage.getItem('VUE_APP_ACCOUNT_DOMAIN_URL') || (process.env.VUE_APP_ACCOUNT_DOMAIN_URL as string)
export const VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL =
  localStorage.getItem('VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL') || (process.env.VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL as string)
export const VUE_APP_CLARITY_ID = localStorage.getItem('VUE_APP_CLARITY_ID') || (process.env.VUE_APP_CLARITY_ID as string)
export const VUE_APP_ADMIN_BASEURL = localStorage.getItem('VUE_APP_ADMIN_BASEURL') || (process.env.VUE_APP_ADMIN_BASEURL as string)
/**
 * 是否是部署在中国
 */
export const IS_CN_REGION = VUE_APP_DEPLOY_REGION === 'cn'
export const VUE_APP_ENV = process.env.NODE_ENV
export const API_MOCK_URL = process.env.VUE_APP_API_MOCK_URL
export const VUE_APP_WORKSPACE_BASEURL = localStorage.getItem('VUE_APP_WORKSPACE_BASEURL') || (process.env.VUE_APP_WORKSPACE_BASEURL as string)
export const VUE_APP_WORKSPACE_DOMAIN_URL =
  localStorage.getItem('VUE_APP_WORKSPACE_DOMAIN_URL') || (process.env.VUE_APP_WORKSPACE_DOMAIN_URL as string)
export const VUE_APP_MICRO_BASE_URL = localStorage.getItem('VUE_APP_MICRO_BASE_URL') || (process.env.VUE_APP_MICRO_BASE_URL as string)
export const VUE_APP_MICRO_URL = localStorage.getItem('VUE_APP_MICRO_URL') || (process.env.VUE_APP_MICRO_URL as string)
