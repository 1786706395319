import {
  IPatentAssignee,
  IPatentAssigneeLink,
  IPatentDetail,
  IPatentType,
  ITopAssigneeRes,
  ITopPatentCoAssignee,
} from '@pharmsnap/shared/types/patent'
import { formatOrgName } from '@pharmsnap/shared/utils'
import { decrypt, decryptNum } from '@pharmsnap/shared/utils/encrypt/encrypt'
/**
 * 解密专利的数据
 * @param data 专利数据
 * @param xCorrelationId
 * @param fontClassName
 */
export function decryptPatentDetail(data: IPatentDetail, xCorrelationId: string, fontClassName = '') {
  data.patent_type_id_view?.forEach((item) => decryptPatentType(item, xCorrelationId, fontClassName))
  data.phs_drug_patent_types?.forEach((item) => {
    if (item.patent_type_id_view) {
      decryptPatentType(item.patent_type_id_view, xCorrelationId, fontClassName)
    }
  })
  data.cde_drug_patent_types?.forEach((item) => {
    item.original_patent_type = decrypt(item.original_patent_type || '', xCorrelationId)
    item.fontClassName = fontClassName
    if (item.patent_type_id_view) {
      decryptPatentType(item.patent_type_id_view, xCorrelationId, fontClassName)
    }
  })
  data.pn && (data.pn = decrypt(data.pn, xCorrelationId))
  data.fontClassName = fontClassName
}
/**
 * 解密专利类型
 * @param data
 * @param xCorrelationId
 * @param fontClassName
 */
function decryptPatentType(data: IPatentType, xCorrelationId: string, fontClassName: string) {
  data.name_cn = decrypt(data.name_cn, xCorrelationId)
  data.name_en = decrypt(data.name_en, xCorrelationId)
  data.fontClassName = fontClassName
}

export function decryptTopPatentOrgNumber(data: ITopPatentCoAssignee, xCorrelationId: string) {
  loopDecryptItem(data.items, xCorrelationId)
  loopDecryptLink(data.links, xCorrelationId)
}

function loopDecryptItem(items: IPatentAssignee[], xCorrelationId: string) {
  items.forEach((item) => {
    item.count = decryptNum(item.count, xCorrelationId)
    if (item.num) {
      item.num = decryptNum(item.num, xCorrelationId)
    }
    if (item.items) {
      loopDecryptItem(item.items, xCorrelationId)
    }
  })
}

function loopDecryptLink(links: IPatentAssigneeLink[], xCorrelationId: string) {
  links.forEach((link) => {
    link.count = decryptNum(link.count, xCorrelationId)
  })
}

/**
 * 解密Top申请人的数据
 * @param data 专利申请人数据
 * @param xCorrelationId
 */
export function decryptPatentTopAssignee(data: ITopAssigneeRes, xCorrelationId: string) {
  if (data.items && data.items.length) {
    data.items.forEach((listItem) => {
      listItem.items.forEach((item) => (item.count = decryptNum(item.count, xCorrelationId)))
      return listItem
    })
  }
}

export function formatPatentCoAssigneeName(data: IPatentAssignee[]) {
  data.forEach((item) => {
    formatOrgName(item)
    item.items && formatPatentCoAssigneeName(item.items)
  })
}
