import { useOSPlatform } from '@pharmsnap/shared/composition/useOSPlatform'
import { IOsPlatform } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { omit } from 'lodash'
import '../../../assets/icon-svg/info.svg'
import { GTooltip } from './GTooltip'

import styles from './GTooltip.module.scss'

export const GShortcutTooltip = defineComponent({
  name: 'GShortcutTooltip',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    manual: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'dark',
    },
    mode: {
      type: String as PropType<'text' | 'icon'>,
      default: 'text',
    },
    content: {
      type: String,
      default: '',
    },
    placement: {
      type: String as PropType<
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end'
      >,
      default: 'top',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'Info',
    },
    showAnimate: {
      type: Boolean,
      default: true,
    },
    enterable: {
      type: Boolean,
      default: true,
    },
    openDelay: {
      type: Number,
      default: 0,
    },
    shortcut: {
      type: [Object, String] as PropType<Partial<Record<IOsPlatform, string>> | string>,
    },
  },
  setup() {
    const { platform } = useOSPlatform()
    return {
      platform,
    }
  },
  methods: {
    renderContent() {
      if (this.$slots.content) return this.$slots.content
      const curShortcut = typeof this.shortcut === 'string' ? this.shortcut : this.shortcut?.[this.platform]
      if (this.content && curShortcut) {
        return (
          <span>
            {this.content}
            {curShortcut && (
              <span class="ml-4 h-5 rounded px-2 inline-block" style="background: #6B778C">
                {curShortcut}
              </span>
            )}
          </span>
        )
      }
      return this.content
    },
  },
  render() {
    return (
      <GTooltip {...{ props: omit(this.$props, 'shortCut') }} popperClass={styles.shortcutTooltip}>
        {this.$slots.default}
        <template slot="content">{this.renderContent()}</template>
      </GTooltip>
    )
  },
})
