/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBaseOrgInfo, IDrugDealDetail } from '@patsnap/synapse_common_interface'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import {
  IBaseAnalysisTrendData,
  IBaseDictItem,
  IBaseEntityAnalysisOverview,
  IBaseMechanismInfo,
  IBaseTargetInfo,
  IBaseVCAnalysisItem,
  IClinicalDetail,
  IDrugDetail,
  IFinancialData,
  IFinancialOverviewData,
  IInvestmentOrgOverviewData,
  IPatentDetail,
  IRelatedTarget,
  ITrialIndicationPhaseAggRes,
} from '@pharmsnap/shared/types'
import { cloneDeep, isUndefined, toNumber } from 'lodash'
import { SECRET_INFO } from './encrypt-decrypt/encrypt-decrypt.min.js'
import charEncrypt from './font-1.map.json'
export const CTRL_C_INFO = 'addCopyInfo'
const DISABLE_DECRYPT_BY_DEV_TOOL = !!window.localStorage.getItem('synapse_dev_tool:disable-decrypt')
/**
 * 禁用加解密,如果是试用或者免费用户需要加密,正式用户不加密
 */
let disableDecrypt = false
export function initSecurity(_disableDecrypt = false) {
  disableDecrypt = _disableDecrypt
  if (!disableDecrypt) {
    createDefaultIconFontStyle()
  }
}
function createDefaultIconFontStyle() {
  const style = `
     .icon-font {
      font-family: 'icon-font-1' !important
     }
    `
  const styleNode = document.createElement('style')
  styleNode.innerHTML = style
  document.head.appendChild(styleNode)
}
//解密方法
export function decrypt(word: string, cryptId: string): string {
  if (disableDecrypt) {
    return word
  }
  if (DISABLE_DECRYPT_BY_DEV_TOOL) {
    return word
  }
  if (!cryptId) {
    return word
  }
  // 此方法是经过javascript-obfuscator 加密混淆后的结果，源文件是decryptInfo.js
  // 通过package.json中的yarn encryptJs构建后生成的encrypt-decrypt.min.js文件中包含该方法
  return SECRET_INFO(word, cryptId)
}
export function decryptNum(word: string | number, cryptId?: string) {
  if (disableDecrypt) {
    return toNumber(word) as number
  }
  if (!cryptId) return toNumber(word)
  // 使用正则判断word是否是一个数字
  const reg = /^-?\d+(\.\d+)?$/
  if (reg.test(word.toString())) {
    return toNumber(word)
  }
  const decryptedStr = decrypt(word.toString(), cryptId)
  return toNumber(decryptedStr)
}
/**
 * 解密一个对象中的数值字段, 字段不存在则原样返回
 * @param data
 * @param fields
 * @param cryptId
 * @returns
 */
export function decryptNumberFieldInObj<T extends Record<string, any>, P extends keyof T>(data: T, fields: P[], cryptId: string) {
  return fields.reduce((acc, curr) => {
    if (curr in acc) {
      return {
        ...acc,
        [curr]: decryptNum(data[curr], cryptId),
      }
    }
    return acc
  }, data)
}

export const encryptMap = new Map()
Object.keys(charEncrypt).forEach((item) => {
  encryptMap.set(item, (charEncrypt as Record<string, string>)[item])
})

/**
 * @deprecated 请使用encryptString
 * @param numStr
 * @param cryptId
 * @returns
 */
export function encryptNum(numStr: string) {
  if (disableDecrypt) {
    return numStr
  }
  return numStr
    .split('')
    .map((item) => {
      const getInfo = encryptMap.get(item)
      return getInfo ? getInfo : item
    })
    .join('')
}
/**
 * 用本地的动态字体加密字符串
 * @param numStr
 * @returns
 */
export function encryptString(numStr: string) {
  if (disableDecrypt) {
    return numStr
  }
  return numStr
    .split('')
    .map((item) => {
      const getInfo = encryptMap.get(item)
      return getInfo ? getInfo : item
    })
    .join('')
}

/**
 * 专业版：任何情况都不显示
 * 基础版：超过100个英文/中文，显示来源文案
 */
export const handleCtrlC = (limitLength = 100) => {
  document.addEventListener('copy', (e) => {
    const selectContent = document.getSelection()?.toString()
    if (selectContent && selectContent.length > limitLength) {
      let parentNode = <HTMLElement>e.target
      do {
        if ([...(<HTMLElement>parentNode).classList].includes(CTRL_C_INFO)) {
          const storeLocaleKey = 'pharmsnapLang'
          const message =
            localStorage.getItem(storeLocaleKey) === 'cn'
              ? `\n作者：智慧芽 新药情报库\n链接：${location.href}\n请勿将此内容用作任何商业用途。如需数据服务，请联系智慧芽相关人员。\n`
              : `\nAuthor：Patsnap 新药情报库\nWebsite Link：${location.href}\nPlease do not use this content for any commercial purpose. If you need any data services, please contact related personnel`
          e.clipboardData?.setData('text/plain', selectContent + message)
          e.preventDefault()
          break
        }
        parentNode = <HTMLElement>parentNode.parentNode
      } while (parentNode.parentNode)
    }
  })
}

/**
 * 判断是否需要注册copy监听
 * 基础版才需要监听
 */
export function shouldRegisterCopyFunc() {
  const {
    getters: { isFreeUser },
  } = useAuthStore()
  if (isFreeUser.value) {
    handleCtrlC()
  }
}

export const decryptOverview = (data: IHttpWrap<IBaseEntityAnalysisOverview>) => {
  if (data.success) {
    decryptOverviewData(data.data, data.xCorrelationId)
  }
  return data
}

export const decryptVCAnalysisItem = (data: IBaseVCAnalysisItem, xCorrelationId: string) => {
  const cloneData = cloneDeep(data)
  const keys = Object.keys(cloneData) as (keyof IBaseVCAnalysisItem)[]
  keys.forEach((key) => {
    cloneData[key] = Number(decrypt(String(data[key]), xCorrelationId))
  })
  return cloneData
}

export const decryptVCOrgOverview = (data: IHttpWrap<IInvestmentOrgOverviewData>) => {
  if (data.success && data.data) {
    if (data.data.last_three_month) {
      data.data.last_three_month = decryptVCAnalysisItem(data.data.last_three_month, data.xCorrelationId)
    }
    if (data.data.last_twelve_month) {
      data.data.last_twelve_month = decryptVCAnalysisItem(data.data.last_twelve_month, data.xCorrelationId)
    }
  }
  return data
}

export const decryptFinancialData = (data: IFinancialData, xCorrelationId: string) => {
  const decryptOmitKeys: (keyof IFinancialData)[] = [
    'acronym',
    'currency',
    'date',
    'duration_type',
    'factset_entity_id',
    'fds_primary_mic_exchange_code',
    'fds_primary_ticker_symbol',
    'fs_perm_sec_id',
    'id',
    'unix_date',
  ]
  const decryptKeys = Object.keys(data).filter((i) => !decryptOmitKeys.includes(i as keyof IFinancialData)) as (keyof IFinancialData)[]
  decryptKeys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data[key] = Number(decrypt(String(data[key]), xCorrelationId))
  })

  return data
}

function decryptNumberWithCheck(xCorrelationId: string, number?: number) {
  if (!isUndefined(number)) {
    return Number(decrypt(String(number), xCorrelationId))
  }
}

export const decryptFinancialOverviewData = (rt: IHttpWrap<IFinancialOverviewData>) => {
  if (rt.success && rt.data) {
    rt.data.ff_mkt_val = decryptNumberWithCheck(rt.xCorrelationId, rt.data.ff_mkt_val)
    rt.data.ff_sales = decryptNumberWithCheck(rt.xCorrelationId, rt.data.ff_sales)
    if (rt.data.detail && rt.data.detail.length > 0) {
      rt.data.detail = rt.data.detail.map((i) => ({
        ...i,
        ff_gross_margin: decryptNumberWithCheck(rt.xCorrelationId, i.ff_gross_margin),
        ff_mkt_val: decryptNumberWithCheck(rt.xCorrelationId, i.ff_mkt_val),
        ff_net_margin: decryptNumberWithCheck(rt.xCorrelationId, i.ff_net_margin),
        ff_sales: decryptNumberWithCheck(rt.xCorrelationId, i.ff_sales),
      }))
    }
  }
  return rt
}

export const decryptOverviewData = (data: IBaseEntityAnalysisOverview, xCorrelationId: string) => {
  const keys = Object.keys(data)
  keys.forEach((ele: string) => {
    const dataTemp = data[ele]
    dataTemp.increase_in_30_days && (dataTemp.increase_in_30_days = Number(decrypt(String(dataTemp.increase_in_30_days), xCorrelationId)))
    dataTemp.total && (dataTemp.total = Number(decrypt(String(dataTemp.total), xCorrelationId)))
  })
  return data
}

export const decryptHistogram = (data: IHttpWrap<ITrialIndicationPhaseAggRes>) => {
  if (data.success) {
    const keys = Object.keys(data.data)
    keys.forEach((key: string) => {
      const dataTemp = data.data[Number(key)]
      dataTemp.forEach((ele) => {
        ele.disease_num && (ele.disease_num = Number(decrypt(String(ele.disease_num), data.xCorrelationId)))
      })
    })
  }
  return data
}

export const decryptPatent = (data: IPatentDetail, xCorrelationId: string, fontClassName?: string) => {
  const { apdt, pbdt, exdt, simple_legal_status, legal_status, event_status, patent_type_id_view, pn } = data
  apdt && (data.apdt = decryptNum(String(data.apdt), xCorrelationId))
  pbdt && (data.pbdt = decryptNum(String(data.pbdt), xCorrelationId))
  exdt && (data.exdt = decryptNum(String(data.exdt), xCorrelationId))
  simple_legal_status && (data.simple_legal_status = decryptNum(String(data.simple_legal_status), xCorrelationId))
  legal_status && (data.legal_status = decryptLegalStatus(legal_status, xCorrelationId))
  event_status && (data.event_status = decryptLegalStatus(event_status, xCorrelationId))
  pn && (data.pn = decrypt(data.pn, xCorrelationId))
  if (patent_type_id_view?.length) {
    patent_type_id_view.forEach((item) => {
      item.name_cn && (item.name_cn = decrypt(item.name_cn, xCorrelationId))
      item.name_en && (item.name_en = decrypt(item.name_en, xCorrelationId))
      item.fontClassName = fontClassName
    })
  }
  data.fontClassName = fontClassName
}

const decryptLegalStatus = (status: number[], xCorrelationId: string) => {
  return status.map((ele) => {
    return decryptNum(String(ele), xCorrelationId)
  })
}

export const decryptClinical = (data: IClinicalDetail, xCorrelationId: string) => {
  const phaseNameCn = data?.phase?.normalized_phase_view?.name_cn
  const phaseNameEn = data?.phase?.normalized_phase_view?.name_en
  phaseNameCn && data.phase && data.phase.normalized_phase_view && (data.phase.normalized_phase_view.name_cn = decrypt(phaseNameCn, xCorrelationId))
  phaseNameEn && data.phase && data.phase.normalized_phase_view && (data.phase.normalized_phase_view.name_en = decrypt(phaseNameEn, xCorrelationId))
}

export const decryptDrug = (data: IDrugDetail, xCorrelationId: string, fontClassName?: string) => {
  const {
    highest_dev_status_cn_view,
    highest_dev_status_before_termination_cn_view,
    global_highest_dev_status_view,
    global_highest_dev_status_before_termination_view,
    target_id_view,
    mechanism_action_id_view,
    first_approved_date,
    first_approved_date_cn,
  } = data
  target_id_view?.forEach((view) => {
    decryptTarget(view, xCorrelationId)
    view.fontClassName = fontClassName
  })
  mechanism_action_id_view?.forEach((view) => {
    decryptMechanism(view, xCorrelationId)
    view.fontClassName = fontClassName
  })
  highest_dev_status_cn_view && decryptDevStatus(highest_dev_status_cn_view, xCorrelationId)
  highest_dev_status_before_termination_cn_view && decryptDevStatus(highest_dev_status_before_termination_cn_view, xCorrelationId)
  global_highest_dev_status_view && decryptDevStatus(global_highest_dev_status_view, xCorrelationId)
  global_highest_dev_status_before_termination_view && decryptDevStatus(global_highest_dev_status_before_termination_view, xCorrelationId)
  first_approved_date && (data.first_approved_date = decryptFirstApprovedDate(first_approved_date, xCorrelationId))
  first_approved_date_cn && (data.first_approved_date_cn = decryptFirstApprovedDate(first_approved_date_cn, xCorrelationId))
}

export const decryptDrugDeal = (data: IDrugDealDetail, xCorrelationId: string, fontClassName?: string) => {
  const { target_id_view } = data
  target_id_view?.forEach((view) => {
    decryptTarget(view, xCorrelationId)
    view.fontClassName = fontClassName
  })
}

export const decryptTarget = (data: IBaseTargetInfo, xCorrelationId: string) => {
  if (!xCorrelationId) {
    return
  }
  const short = data.short_name || []
  const target = data.target_name || []
  short.forEach((sh) => {
    sh.name = decrypt(sh.name, xCorrelationId)
  })
  target.forEach((ta) => {
    ta.name = decrypt(ta.name, xCorrelationId)
  })
  if (data.entity_name_cn) {
    data.entity_name_cn = decrypt(data.entity_name_cn, xCorrelationId)
  }
  if (data.entity_name_en) {
    data.entity_name_en = decrypt(data.entity_name_en, xCorrelationId)
  }
}

export const decryptOrg = (data: IBaseOrgInfo, xCorrelationId: string) => {
  if (!xCorrelationId) {
    return
  }
  if (data.entity_name_cn) {
    data.entity_name_cn = decrypt(data.entity_name_cn, xCorrelationId)
  }
  if (data.entity_name_en) {
    data.entity_name_en = decrypt(data.entity_name_en, xCorrelationId)
  }
  if (data.name_cn) {
    data.name_cn = decrypt(data.name_cn, xCorrelationId)
  }
  if (data.name_en) {
    data.name_en = decrypt(data.name_en, xCorrelationId)
  }
}

export function decryptDicItem(item: IBaseDictItem, xCorrelationId: string, fontClassName?: string) {
  if (item.name_cn) {
    item.name_cn = decrypt(item.name_cn, xCorrelationId)
  }
  if (item.name_en) {
    item.name_en = decrypt(item.name_en, xCorrelationId)
  }
  item.fontClassName = fontClassName
}

export const decryptMechanism = (data: IBaseMechanismInfo, xCorrelationId: string) => {
  if (!xCorrelationId) {
    return
  }
  const short = data.short_name || []
  const mechanism = data.mechanism_name || []
  short.forEach((sh) => {
    sh.name = decrypt(sh.name, xCorrelationId)
  })
  mechanism.forEach((me) => {
    me.name = decrypt(me.name, xCorrelationId)
  })
}

export const decryptDevStatus = (data: IBaseDictItem, xCorrelationId: string) => {
  if (!xCorrelationId) {
    return
  }
  const { name_cn, name_en } = data
  data.name_cn = decrypt(name_cn, xCorrelationId)
  data.name_en = decrypt(name_en, xCorrelationId)
}

const decryptFirstApprovedDate = (first_approved_date: number, xCorrelationId: string) => {
  return decryptNum(String(first_approved_date), xCorrelationId)
}

function handleTrendCount(items: { count: number }[], xCorrelationId: string) {
  items?.forEach((ele: { count: number }) => (ele.count = Number(decrypt(String(ele.count), xCorrelationId))))
}

export function handleTrend(data: IBaseAnalysisTrendData, xCorrelationId: string) {
  const { clinical_trial, paper, patent } = data
  clinical_trial && handleTrendCount(clinical_trial.items, xCorrelationId)
  paper && handleTrendCount(paper.items, xCorrelationId)
  patent && handleTrendCount(patent.items, xCorrelationId)
}

export function decryptDrugGroupByTarget(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  decryptDrugGroup(data, xCorrelationId, fontClassName)
}
export function decryptDrugGroupByOrg(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  decryptDrugGroup(data, xCorrelationId, fontClassName)
}
export function decryptDrugGroupByDisease(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  decryptDrugGroup(data, xCorrelationId, fontClassName)
}
export function decryptDrugGroupByDrugType(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  decryptDrugGroup(data, xCorrelationId, fontClassName)
}
export function decryptDrugGroupByDevStatus(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  decryptDrugGroup(data, xCorrelationId, fontClassName)
}

function decryptDrugGroup(data: IRelatedTarget, xCorrelationId: string, fontClassName?: string) {
  const { related_target_id_view } = data
  related_target_id_view?.forEach((view) => {
    decryptTarget(view, xCorrelationId)
    view.fontClassName = fontClassName
  })
}
