import { ITargetSequenceInfo } from '@pharmsnap/shared/src/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { useLocale } from '../../../composition/useLocale'
import { GDialog } from '../../ui/GDialog/GDialog'
import { GLoading } from '../../ui/GLoading/GLoading'
import { BSequenceItem } from './BSequenceItem'

export const BSequenceDialog = defineComponent({
  name: 'BSequenceDialog',
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    targetSequenceList: {
      type: Array as PropType<ITargetSequenceInfo[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { localeUpcase } = useLocale()

    const onClickSimilar = (id: string) => {
      ctx.emit('clickSimilar', id)
    }

    return {
      localeUpcase,
      onClickSimilar,
    }
  },
  render() {
    return (
      <GDialog
        onInput={(val: boolean) => this.$emit('input', val)}
        width="980px"
        showFooter={false}
        visible={this.visible}
        title={this.localeUpcase === 'CN' ? '序列' : 'Sequence'}
      >
        {this.loading ? (
          <div style="height: 600px; position: relative;">
            <GLoading></GLoading>
          </div>
        ) : (
          <div class="max-h-[560px] overflow-y-auto -mr-6 pr-6">
            {this.targetSequenceList.map((item, index) => (
              <div class={{ 'mt-10': index !== 0 }} key={index}>
                <BSequenceItem onClickSimilar={this.onClickSimilar} sequence={item.sequence} targetView={item.related_target_id_view}></BSequenceItem>
              </div>
            ))}
          </div>
        )}
      </GDialog>
    )
  },
})
