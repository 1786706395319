import { IAggregationData, IAggregationParams, ISearchParams } from '@patsnap/synapse_common_interface'
import { IFundingInfo, IFundingOrgOverviewData, IGrantTopAgenciesParams, ISearchResult } from '@pharmsnap/shared/types'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformFundedAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/funded'
import { decryptNumberFieldInObj } from '@pharmsnap/shared/utils/encrypt/encrypt'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class FundingApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getOrganizationOverview(id: string): Promise<IHttpWrap<IFundingOrgOverviewData>> {
    return this.http({
      url: this.getUrl(`discovery_webapi/2.0/funded-research/organization_detail/${id}/overview`),
      method: 'GET',
      cache: true,
    })
  }
  async getOrganizationTopAgencies(params: IGrantTopAgenciesParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/funded-research/organization_detail/${params.id}/top_agencies`),
      method: 'POST',
      data: {
        datetime_from: params.datetime_from,
        datetime_to: params.datetime_to,
      },
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformFundedAggregationSingleResult))
  }
  async getFundedResearchList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IFundingInfo>>> {
    const rt = await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/funded-research/search'),
      method: 'POST',
      data: params,
      cache: true,
    })
    return decryptFundedResearchList(rt)
  }
  async getFundedResearchAgg(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/funded-research/aggregation'),
      method: 'POST',
      data: params,
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformFundedAggregationSingleResult))
  }
}

/**
 * 解密 FundedResearchList 接口数据
 * @param data
 * @returns
 */
function decryptFundedResearchList(data: IHttpWrap<ISearchResult<IFundingInfo>>): IHttpWrap<ISearchResult<IFundingInfo>> {
  if (data.success) {
    data.data.items = data.data.items?.map((item) => {
      return decryptNumberFieldInObj(item, ['amount_usd'], data.xCorrelationId)
    })
  }
  return data
}
