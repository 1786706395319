import { toThousands } from '@patsnap/synapse_common_utils'

export function getRangeNumber(num: number, range: number[], start?: number, end?: number) {
  start = start || 0
  end = end || range.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    if (range[mid] > num) {
      end = mid - 1
    } else {
      start = mid + 1
    }
  }
  const startLabel = range[end]
  const endLabel = range[start]

  if (!endLabel) {
    return `> ${toThousands(startLabel)}`
  }
  if (!startLabel) {
    return `< ${toThousands(endLabel)}`
  }

  return `${toThousands(startLabel)}-${toThousands(endLabel)}`
}

export function binarySearchNumber(target: number, arr: number[], start?: number, end?: number): string {
  start = start || 0
  end = end || arr.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    if (arr[mid] > target) {
      end = mid - 1
    } else {
      start = mid + 1
    }
  }
  const startLabel = arr[end]
  const endLabel = arr[start]

  if (!endLabel) {
    return `> ${toThousands(startLabel)}`
  }
  if (!startLabel) {
    return `< ${toThousands(endLabel)}`
  }

  return `${toThousands(startLabel)}-${toThousands(endLabel)}`
}
/**
 * 获取一个数字区间的的展示名
 * @param isFirst 是否是第一个数字
 * @param isLast 是否是最后数字
 * @param curr  当前数字
 * @param _pre 前一个数字
 * @returns
 */
export function getNumberRangeDisplayName(isFirst: boolean, isLast: boolean, curr: number, _pre?: number) {
  if (isFirst) {
    return `< ${toThousands(curr)}`
  }
  if (isLast) {
    return `> ${toThousands(curr)}`
  }
  return `${toThousands(_pre as number)}-${toThousands(curr)}`
}

export function getDisplayPercentNumber(number?: number, toFixed = 2) {
  const maxPercent = number ? number * 100 : 0
  const decimalPart = (maxPercent.toString().split('.')[1] || '').length
  const truncatedNumber = decimalPart > toFixed ? maxPercent.toFixed(toFixed) : maxPercent
  return truncatedNumber ? `${truncatedNumber}%` : ''
}
