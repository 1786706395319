import { IQueryDataType } from '@patsnap/synapse_common_interface'
import { IExportDataType, IFieldLangMap } from '@pharmsnap/shared/types'
export const ExportDataTypeLangMap: Partial<IFieldLangMap<IExportDataType>> = {
  dev_status: {
    en: 'Drug R&D Status',
    cn: '药物研发状态',
  },
  drug: {
    en: 'Drug',
    cn: '药物',
  },
  patent: {
    en: 'Patent',
    cn: '专利',
  },
  organization: {
    en: 'Organization',
    cn: '机构',
  },
  target: {
    en: 'Target',
    cn: '靶点',
  },
  disease: {
    en: 'Indication',
    cn: '适应症',
  },
  drug_type: {
    en: 'Drug Type',
    cn: '药物类型',
  },
  clinical_trial: {
    en: 'Clinical Trial',
    cn: '临床试验',
  },
  clinical_trial_result: {
    en: 'Clinical Result',
    cn: '临床结果',
  },
  drug_deal: {
    en: 'Deal',
    cn: '药物交易',
  },
  drug_deal_drug: {
    en: 'Drug',
    cn: '药物',
  },
  translational_medicine: {
    en: 'Translational Medicine',
    cn: '转化医学',
  },
  paper: {
    en: 'Literature',
    cn: '文献',
  },
}
export const QueryDataTypeLangMap: Partial<IFieldLangMap<IQueryDataType>> = {
  drug: {
    en: 'Drug',
    cn: '药物',
  },
}
