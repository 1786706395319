import { REVERSE_LANGUAGE_MAP } from '@pharmsnap/pharmsnap-web/components/header/constants'
import type { I18nLang } from '@pharmsnap/shared/src/i18n'
import { setLangValue } from './uniform-setting'

const storeLocaleStorageKey = 'pharmsnapLang'
/**
 * 获取浏览器的语言
 * @returns {I18nLang}
 */
function getBrowserLang(): I18nLang {
  const lang = navigator.language.substr(0, 2)
  if (lang === 'zh') {
    return 'cn'
  }
  return 'en'
}
/**
 * 同步用户的语言设置到服务端和sessionStorage
 * @param locale 语言
 */
export async function setServeLangAndSyncLocalStorage(locale: I18nLang) {
  setLocaleLang(locale)
  await setLangValue(locale, true)
}
/**
 * pharmsnap 语言包到存储到BO的语言设置的映射
 * @param locale
 * @returns
 */
export function i18n2ServeLang(locale: I18nLang) {
  return REVERSE_LANGUAGE_MAP[locale]
}
/**
 * localStorage中的语言数据校验
 * @returns
 */
function getLocaleLangFromLocalStorage(): I18nLang | undefined {
  const rt = localStorage.getItem(storeLocaleStorageKey)
  if (!rt) {
    return undefined
  }
  if (['cn', 'en'].includes(rt)) {
    return rt as I18nLang
  }
  return undefined
}
/**
 * 用localStorage获取语言
 * @returns
 */
export function getLocaleLang(): I18nLang {
  return getLocaleLangFromLocalStorage() || getBrowserLang()
}
/**
 * 设置localStorage语言
 * @returns
 */
export function setLocaleLang(value: I18nLang) {
  return localStorage.setItem(storeLocaleStorageKey, value)
}
