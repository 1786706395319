import { E_QUERY_ITEM_CONDITION, I18nLang, IQuery, IQueryItemField, ISearchCollapse } from '@patsnap/synapse_common_interface'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale, useNavList } from '@pharmsnap/shared/composition'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import {
  IBaseEntityAnalysisOverview,
  IBaseEntityAnalysisOverviewItem,
  IBOverviewPanelItem,
  IHistorySearchParams,
  INavListType,
  IWebTrackingEvent,
} from '@pharmsnap/shared/types'
import { computed, onMounted, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import dayjs from 'dayjs'
import { cloneDeep, includes, isUndefined } from 'lodash'
import { listPageConsumerMap } from '../config/tracking'
import { getTimeRelatedQueryField } from '../utils/navList'
import { useNavListIds } from './useNavListIds'

interface IUseNavListOverviewOptions<T> {
  entity: MaybeRef<T>
  getQuery: (entity: T, navListType: INavListType) => IQuery
  data?: MaybeRef<IBaseEntityAnalysisOverview>
  fetch?: () => Promise<IHttpWrap<IBaseEntityAnalysisOverview>>
}

export function useNavListOverview<T>(options: IUseNavListOverviewOptions<T>) {
  const { data, fetch, getQuery, entity } = options

  const { locale } = useLocale()

  const loading = ref(fetch ? true : false)

  const fetchedData = ref<IBaseEntityAnalysisOverview>()

  const navList = ref<INavListType>('drug')

  const navListIdQuery = ref<IQuery>({
    type: 'group',
    must: [],
  })

  const tracking = ref<IWebTrackingEvent[] | undefined>()

  const {
    getters: { isFreeUser },
    actions,
  } = useAuthStore()

  // 跳转到药物列表
  const { navToList, dataType, defaultHistorySearchParams, isGroupListType } = useNavList({
    navList,
    tracking,
    auth: false,
  })

  const { onNavToListWithIds } = useNavListIds({
    appendTo: 'must',
    tracking,
    navList,
    multiTarget: true,
    query: navListIdQuery,
    auth: false,
  })

  const computedData = computed<IBaseEntityAnalysisOverview>({
    get() {
      return data ? unref(data) : unref(fetchedData) || {}
    },
    set(v) {
      fetchedData.value = v
    },
  })

  const overviewItems = computed<IBOverviewPanelItem[]>(() => {
    const keys: IBOverviewPanelItem['field'][] = [
      'drug',
      'disease',
      'target',
      'organization',
      'clinical_trial',
      'clinical_trial_result',
      'translational_medicine',
      'patent',
      'core_patent',
      'paper',
      'news',
      'drug_deal',
    ]

    if (!unref(computedData)) return []

    const items: IBOverviewPanelItem[] = keys
      .map((k) => generatorPanelItem(unref(computedData), k, locale.value))
      .filter((i) => !!i) as IBOverviewPanelItem[]

    return items
  })

  const someOneIncreaseIn30Days = computed(() => unref(overviewItems).some((i) => i.increase > 0))

  const fetchOverviewInfo = async () => {
    if (!fetch) return
    loading.value = true
    const rt = await fetch()
    if (rt.success && rt.data) {
      fetchedData.value = rt.data
    }
    loading.value = false
  }

  const updateTrackingEvent = (field: IBOverviewPanelItem['field']) => {
    let event: IWebTrackingEvent[] | undefined = undefined
    const consumer = listPageConsumerMap[field === 'core_patent' ? 'patent' : field]
    if (consumer) {
      event = [
        {
          consumer,
          tracking_data: { trigger_point: 'RELATED_ENTITY' },
        },
      ]
    }
    tracking.value = event
  }

  const updateNavListByField = (field: IBOverviewPanelItem['field']) => {
    if (includes(['clinical_trial', 'clinical_trial_result', 'news', 'paper', 'patent', 'drug_deal', 'translational_medicine'], field)) {
      navList.value = field as INavListType
    } else if (field === 'drug') {
      navList.value = 'drug'
    } else if (field === 'disease') {
      navList.value = 'drug_group_disease'
    } else if (field === 'organization') {
      navList.value = 'drug_group_organization'
    } else if (field === 'core_patent') {
      navList.value = 'patent'
    } else {
      navList.value = 'drug_group_target'
    }
  }

  const getCollapse = (_field: IBOverviewPanelItem['field']): ISearchCollapse | undefined => {
    // https://confluence.zhihuiya.com/display/DLS/SNP+202407+Online+UAT #8
    // 这里不在需要再设置折叠,跳转到列表后用用户的折叠方式,于2024.06.17下午16:19和在钉钉和侯东亮私聊确认
    return undefined
  }

  const getFilter = (field: IBOverviewPanelItem['field']): IQueryItemField[] => {
    return field === 'core_patent'
      ? [
          {
            condition: E_QUERY_ITEM_CONDITION.ANY,
            type: 'field',
            fields: ['PHS_LABEL_TYPE'],
            value: [
              {
                display_name_cn: '人工标引',
                display_name_en: 'Manual',
                type: 'text',
                value: '1',
              },
            ],
          },
        ]
      : []
  }

  const onClickTotalCount = async (item: IBOverviewPanelItem) => {
    if (isFreeUser.value && isGroupListType.value) {
      actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'CLICK_OVERVIEW' })
      return
    }
    if (item.count === 0) return
    if (!item.is_link) return
    if (item.not_allowed_tip) return

    updateNavListByField(item.field)

    updateTrackingEvent(item.field)

    const originQuery = cloneDeep(getQuery(unref(entity) as T, navList.value))

    const defaultParams: IHistorySearchParams = cloneDeep(defaultHistorySearchParams)

    const filter = (originQuery.filter || defaultParams.query.filter || []).concat(getFilter(item.field))

    const params: IHistorySearchParams = {
      ...defaultParams,
      collapse: getCollapse(item.field),
      data_type: dataType.value,
      query: {
        ...defaultParams.query,
        ...originQuery,
        filter,
      },
      org_roll_up: item.field !== 'organization',
    }

    await navToList(params)
  }

  const onClickIncreaseCount = async (item: IBOverviewPanelItem) => {
    if (isFreeUser.value && isGroupListType.value) {
      actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'CLICK_OVERVIEW' })
      return
    }
    if (!item.is_link) return
    if (item.increase === 0) return

    if (
      item.field === 'clinical_trial' ||
      item.field === 'news' ||
      item.field === 'patent' ||
      item.field === 'core_patent' ||
      item.field === 'clinical_trial_result' ||
      item.field === 'drug_deal' ||
      item.field === 'translational_medicine'
    ) {
      if (!item.from || !item.to) return

      const timeField = {
        clinical_trial: 'START_DATE',
        clinical_trial_result: 'PUBLISHED_TIME',
        news: 'dmp_post_time',
        core_patent: 'PBDT_YEARMONTHDAY',
        patent: 'PBDT_YEARMONTHDAY',
        drug_deal: 'DEAL_TIME',
        translational_medicine: 'PUB_DT',
      }[item.field]

      // 后段返回的from、to是格式化后的时间，需要转换成时间戳
      const formattedTimeFields = ['PUB_DT']

      let from = item.from
      let to = item.to

      if (formattedTimeFields.includes(timeField)) {
        from = dayjs(`${from}`).valueOf()
        to = dayjs(`${to}`).valueOf()
      }

      const queryItem = getTimeRelatedQueryField([from, to], timeField, timeField === 'dmp_post_time')

      updateNavListByField(item.field)
      updateTrackingEvent(item.field)

      const originQuery = cloneDeep(getQuery(unref(entity) as T, navList.value))

      const defaultParams: IHistorySearchParams = cloneDeep(defaultHistorySearchParams)

      queryItem && originQuery.must?.push(queryItem)
      const filter = (originQuery.filter || defaultParams.query.filter || []).concat(getFilter(item.field))
      const params: IHistorySearchParams = {
        ...defaultParams,
        collapse: getCollapse(item.field),
        data_type: dataType.value,
        query: {
          ...defaultParams.query,
          ...originQuery,
          filter,
        },
      }

      await navToList(params)

      return
    }

    if (item.field === 'drug' || item.field === 'disease' || item.field === 'organization' || item.field === 'target') {
      const ids = item.increase_id_in_30_days || []

      updateNavListByField(item.field)
      updateTrackingEvent(item.field)

      const originQuery = cloneDeep(getQuery(unref(entity) as T, navList.value))

      navListIdQuery.value = originQuery

      await onNavToListWithIds(ids, item.field, item.field === 'target' ? 'TARGET_ID_EXTEND' : undefined, (params) =>
        item.field === 'organization' ? { ...params, org_roll_up: false } : { ...params }
      )
    }
  }

  function generatorPanelItem(
    data: IBaseEntityAnalysisOverview,
    key: IBOverviewPanelItem['field'],
    locale: I18nLang
  ): IBOverviewPanelItem | undefined {
    if (isUndefined(data[key])) return
    const item = data[key]
    if (!item) return
    const { increase_in_30_days, total, from, to } = item as IBaseEntityAnalysisOverviewItem
    const notAllowedFields: IBOverviewPanelItem['field'][] = ['disease', 'target', 'organization']
    return {
      field: key,
      count: total,
      increase: increase_in_30_days || 0,
      drug_count: data.drug?.total,
      increase_id_in_30_days: item.increase_id_in_30_days || [],
      is_link: true,
      not_allowed_tip:
        // 靶点/机构/适应症重聚和需要药物数量少于5000
        notAllowedFields.includes(key) && (data.drug?.total || 0) > 5000
          ? locale === 'cn'
            ? '药物超过5000，无法跳转'
            : 'Drugs should be no more than 5000'
          : '',
      to,
      from,
    }
  }

  onMounted(fetchOverviewInfo)

  return {
    onClickIncreaseCount,
    onClickTotalCount,
    someOneIncreaseIn30Days,
    overviewItems,
    loading,
  }
}
