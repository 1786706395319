import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { E_NODATA_SIZE, IGTableColumnSlotScope, IGTableColumnsProps, IGTableThSlotScope } from '@pharmsnap/shared/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { throttle } from 'lodash'
import { VNode } from 'vue/types/vnode'
import { ElTable, ElTableColumn } from '../../../element-ui'
import { GEmpty } from '../GEmpty/GEmpty'

export const GTable = defineComponent({
  name: 'GTable',
  props: {
    columns: {
      type: Array as PropType<IGTableColumnsProps<any>[]>,
      default: () => [],
      required: true,
    },
    emptySize: {
      type: String as PropType<E_NODATA_SIZE>,
      default: E_NODATA_SIZE.MIDDLE,
    },
  },

  setup() {
    const table = ref(null) as any
    const tableBodyWrapper = ref<HTMLElement>()

    const scrollLeft = ref<number>()

    return {
      table,
      tableBodyWrapper,
      scrollLeft,
    }
  },

  created(): void {
    this.handleTableScroll = throttle(this.handleTableScroll, 300)
  },

  mounted(): void {
    const tableEl = this.table.$el
    this.tableBodyWrapper = tableEl.querySelector('.el-table__body-wrapper') as HTMLElement
    this.tableBodyWrapper.addEventListener('scroll', this.handleTableScroll, false)
    this.$emit('scrollH', { offsetWidth: this.tableBodyWrapper.offsetWidth, scrollLeft: 0 })
  },

  beforeDestroy(): void {
    this.tableBodyWrapper?.removeEventListener('scroll', this.handleTableScroll)
  },

  methods: {
    renderColumns(): VNode[] {
      return (this.columns || []).map((column) => {
        const { renderCell, renderTh, ...props } = column
        const options = {
          props: {
            ...props,
          },
        }
        // eslint-disable-next-line @typescript-eslint/ban-types
        const scopedSlots: { default?: Function; header?: Function } = {}
        if (renderCell && typeof renderCell === 'function') {
          scopedSlots.default = (scope: IGTableColumnSlotScope<any>) => {
            return renderCell(this.$createElement, scope, this) || EMPTY_PLACEHOLDER
          }
        }
        if (renderTh && typeof renderTh === 'function') {
          scopedSlots.header = (scope: IGTableThSlotScope<any>) => {
            return renderTh(this.$createElement, scope)
          }
        }

        Object.assign(options, { scopedSlots })

        return <ElTableColumn {...options}></ElTableColumn>
      })
    },
    handleTableScroll(e: Event): void {
      const el = e.target as HTMLElement
      const scrollLeft = el.scrollLeft
      if (scrollLeft !== this.scrollLeft) {
        this.$emit('scrollH', { offsetWidth: el.offsetWidth, scrollLeft: el.scrollLeft })
        this.scrollLeft = scrollLeft
      }
    },

    scrollToTop(): void {
      this.tableBodyWrapper && (this.tableBodyWrapper.scrollTop = 0)
    },
    scrollToLeft(): void {
      this.tableBodyWrapper && (this.tableBodyWrapper.scrollLeft = 0)
    },
  },
  render() {
    const attrs = this.$attrs
    const listeners = this.$listeners

    const options = {
      props: {
        ...attrs,
      },
      on: {
        ...listeners,
      },
      scopedSlots: {
        empty: () =>
          this.$slots.empty || (
            <div style="height: 400px;">
              <GEmpty size={this.emptySize}></GEmpty>
            </div>
          ),
      },
    }

    return (
      <ElTable style="width: 100%;" class="g-table" ref="table" {...options}>
        {this.renderColumns()}
      </ElTable>
    )
  },
})
