import { getChartWaterMark } from 'pharmsnapMF_shared/utils'
import { IS_CN_REGION } from '../config'

export interface IWaterMarkProps {
  canvasWidth: number
  canvasHeight: number
  chartUrl: string
  markerX?: number
  markerY?: number
  addHeight?: number
  addWidth?: number
  isCN: boolean
  /** 图片背景是否添加水印 */
  addBngMarker?: boolean
  userInfo?: {
    userId: string
  }
}

export interface IMarkItem {
  imgSrc: string
  x: number
  y: number
  width: number
  height: number
}

export function createImgPromise<T extends { imgSrc: string }>(data: T): Promise<T & { img: HTMLImageElement }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = data.imgSrc
    img.onload = () =>
      resolve({
        img,
        ...data,
      })
    img.onerror = (e) => {
      reject(e)
    }
  })
}
function injectUserInfoWaterMark(
  canvas: HTMLCanvasElement,
  userInfo: {
    userId: string
  }
) {
  const name = userInfo.userId
  if (!name) {
    return
  }
  const date = Date.now().toString()
  const ctx = canvas.getContext('2d') // 取得画布的2d绘图上下文
  const canvasWater = document.createElement('canvas')
  const gap = 160

  const ctxWater = canvasWater.getContext('2d')
  if (ctx && ctxWater) {
    const nameMetrics = ctx.measureText(name)
    const dateMetrics = ctx.measureText(date)
    const textWidth = Math.max(nameMetrics.width, dateMetrics.width)
    const textHeight = 20
    const waterMarkerWidth = textWidth + gap
    const waterMarkerHeight = textHeight + gap
    canvasWater.width = waterMarkerWidth
    canvasWater.height = waterMarkerHeight
    ctxWater.textAlign = 'left'
    ctxWater.textBaseline = 'top'
    ctxWater.font = '14px bold sans-serif'
    ctxWater.fillStyle = 'rgba(0, 0, 0, 0.01)'
    // ctxWater.fillStyle = 'rgba(0, 0, 0, 1)'
    ctxWater.rotate((-20 * Math.PI) / 180)
    ctxWater.fillText(name, 0, waterMarkerHeight - textHeight * 2)
    ctxWater.fillText(date, 0, waterMarkerHeight - textHeight)
    ctx.fillStyle = ctx.createPattern(canvasWater, 'repeat') as CanvasPattern // 绘制重复的水印
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }
}

function addWaterMark(params: IWaterMarkProps): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    try {
      const { chartUrl, canvasWidth, canvasHeight, markerX, markerY, addHeight = 0, addWidth = 0 } = params
      const imageList: IMarkItem[] = [
        {
          imgSrc: chartUrl,
          x: addWidth / 2,
          y: 0,
          width: canvasWidth,
          height: canvasHeight,
        },
        {
          imgSrc: IS_CN_REGION ? '/watermark/phs-text-mark.png' : '/watermark/synapse-text-mark.png',
          x: 30,
          y: canvasHeight - 20 + addHeight,
          width: 183,
          height: 12,
        },
        {
          imgSrc: params.isCN ? '/watermark/phs-logo-mark.png' : '/watermark/synapse-logo-mark.png',
          x: markerX || canvasWidth - 150,
          y: markerY || canvasHeight - 40 + addHeight,
          width: 130,
          height: 32,
        },
      ]
      /** g6图中显示的水印，下载图片将不会带有水印，需要手动绘画背景水印 */
      if (params.addBngMarker) {
        const { waterMark, imgWidth, imgHeight } = getChartWaterMark(params.isCN)
        imageList.push({
          imgSrc: waterMark,
          x: (canvasWidth - imgWidth) / 2,
          y: (canvasHeight - imgHeight) / 2 + addHeight,
          width: imgWidth,
          height: imgHeight,
        })
      }
      const canvas = document.createElement('canvas') //准备空画布
      canvas.width = canvasWidth + addWidth
      canvas.height = canvasHeight + addHeight
      const context = canvas.getContext('2d') //取得画布的2d绘图上下文

      if (context) {
        context.fillStyle = '#FFF'
        context.fillRect(0, 0, canvasWidth + addWidth, canvasHeight + addHeight)

        Promise.all(imageList.map(createImgPromise))
          .then((results) => {
            results.forEach((item) => {
              context.drawImage(item.img, item.x, item.y, item.width, item.height)
            })
            if (params.userInfo?.userId) {
              injectUserInfoWaterMark(canvas, params.userInfo)
            }
            resolve(canvas)
          })
          .catch((e) => {
            resolve(canvas)
          })
      }
    } catch (e) {
      reject(e)
    }
  })
}

export async function generateChartImage(params: {
  chartUrl: string
  width: number
  height: number
  name?: string
  addHeight?: number
  addWidth?: number
  isCN: boolean
  addBngMarker?: boolean
  userInfo?: {
    userId: string
  }
}): Promise<void> {
  try {
    const canvas = await addWaterMark({
      chartUrl: params.chartUrl,
      canvasWidth: params.width,
      canvasHeight: params.height,
      addHeight: params.addHeight,
      addWidth: params.addWidth,
      isCN: params.isCN,
      addBngMarker: params.addBngMarker,
      userInfo: params.userInfo,
    })
    const a = document.createElement('a')
    a.href = canvas.toDataURL('image/png') //将画布内的信息导出为png图片数据
    a.download = params.name || '' //设定下载名称
    a.click()
  } catch (e) {
    return Promise.reject(e)
  }
}
