import Color from 'color'
import { EventEmitter } from 'eventemitter3'
import { debounce, merge, uniqueId } from 'lodash'
import './densityBar.scss'
import { createFastDomNode, FastDomNode } from './fastDomNode'
import { PointerMoveMonitor } from './pointerMoveMonitor'
import { IDisposable } from './type'
import { addDisposableListener } from './utils'

export interface DensityBarOptions {
  /* ----------------------------- canvas related ----------------------------- */
  width?: number
  height?: number
  top?: number
  right?: number
  left?: number
  resizeDebounce?: number
  backgroundColor?: string
  zIndex?: number
  observeMutation?: boolean
  observeResize?: boolean
  /* --------------------------- canvas mark related -------------------------- */
  markSelector: string
  markHeight?: number
  computeMarkColor?: (item: HTMLElement) => string
  /* ------------------------ pointer indicator related ----------------------- */
  indicatorHeight?: number
  indicatorBackgroundColor?: string

  /* ----------------------------- slider related ----------------------------- */
  showSlider?: 'always' | 'mouseover'
  sliderMinHeight?: number
  sliderHeight?: number
}

export type RGBA = [number, number, number, number]
function calculateSliderHeight(options: Required<DensityBarOptions>) {
  return Math.max(options.sliderHeight, options.sliderMinHeight)
}
function getOptions(options: Partial<DensityBarOptions>, rawOptions: Partial<DensityBarOptions> = {}) {
  const defaultOptions: Required<DensityBarOptions> = {
    width: 15,
    height: 0,
    top: 0,
    right: 0,
    left: 0,
    resizeDebounce: 300,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 65535,
    observeMutation: true,
    observeResize: true,

    markSelector: '',
    markHeight: 2,
    computeMarkColor: (item) => getComputedStyle(item).backgroundColor,

    indicatorHeight: 4,
    indicatorBackgroundColor: 'rgba(100, 100, 100, 0.5)',

    showSlider: 'mouseover',
    sliderMinHeight: 8,
    sliderHeight: 0,
  }
  return merge({}, defaultOptions, rawOptions, options)
}

export class InnerDensityBar {
  private readonly _domNode: FastDomNode<HTMLElement>
  private readonly _canvas: FastDomNode<HTMLCanvasElement>
  private readonly _pointerIndicator: FastDomNode<HTMLElement>
  private readonly _slider: FastDomNode<HTMLElement>
  private readonly _sliderHorizontal: FastDomNode<HTMLElement>

  private readonly _domNodePointerDownListener: IDisposable
  private readonly _domNodePointerMoveListener: IDisposable
  private readonly _pointerIndicatorPointerDownListener: IDisposable
  private readonly _pointerIndicatorPointerLeaveListener: IDisposable
  private readonly _sliderPointerDownListener: IDisposable
  private readonly _sliderPointerMoveMonitor: PointerMoveMonitor

  constructor(private readonly _model: DensityBar) {
    this._domNode = createFastDomNode(document.createElement('div'))
    this._domNode.setClassName(this._getDensityBarDomNodeClassName())
    this._domNode.setPosition('absolute')
    this._domNode.setTop(0)

    this._canvas = createFastDomNode(document.createElement('canvas'))
    this._canvas.setPosition('absolute')
    this._canvas.setLeft(0)
    this._domNode.appendChild(this._canvas)

    this._pointerIndicator = createFastDomNode(document.createElement('div'))
    this._pointerIndicator.setPosition('absolute')
    this._pointerIndicator.setClassName('density-bar-pointer-indicator')
    this._domNode.appendChild(this._pointerIndicator)

    this._slider = createFastDomNode(document.createElement('div'))
    this._slider.setPosition('absolute')
    this._slider.setClassName('density-bar-slider')
    this._slider.setLayerHinting(true)
    this._slider.setContain('strict')
    this._domNode.appendChild(this._slider)

    this._sliderHorizontal = createFastDomNode(document.createElement('div'))
    this._sliderHorizontal.setPosition('absolute')
    this._sliderHorizontal.setClassName('density-bar-slider-horizontal')
    this._slider.appendChild(this._sliderHorizontal)

    this._applyLayout()
    this._domNodePointerDownListener = addDisposableListener(this._domNode.domNode, 'pointerdown', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const initialY = e.offsetY - Math.floor(this._model.options.sliderHeight / 2)
      this._setModelScrollTop(initialY)
      this._startSliderDragging(e, initialY)
    })
    this._domNodePointerMoveListener = addDisposableListener(
      this._domNode.domNode,
      'pointermove',
      (e) => {
        e.preventDefault()
        this._movePointerIndicator(e)
      },
      true
    )
    this._pointerIndicatorPointerDownListener = addDisposableListener(this._pointerIndicator.domNode, 'pointerdown', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const offsetY = Number(this._pointerIndicator.getAttribute('data-offsetY'))
      this._model.scrollIntoView(offsetY)
    })
    this._pointerIndicatorPointerLeaveListener = addDisposableListener(this._pointerIndicator.domNode, 'pointerleave', (e) => {
      e.preventDefault()
      this._pointerIndicator.setDisplay('none')
    })
    this._sliderPointerMoveMonitor = new PointerMoveMonitor()
    this._sliderPointerDownListener = addDisposableListener(this._slider.domNode, 'pointerdown', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this._startSliderDragging(e, this._slider.domNode.offsetTop)
    })
  }

  private _applyLayout() {
    this._domNode.setLeft(this._model.options.left)
    this._domNode.setTop(this._model.options.top)
    this._domNode.setWidth(this._model.options.width)
    this._domNode.setHeight(this._model.options.height)
    this._domNode.setBackgroundColor(this._model.options.backgroundColor)
    this._domNode.setZIndex(this._model.options.zIndex)

    this._canvas.setWidth(this._model.options.width)
    this._canvas.setHeight(this._model.options.height)
    this._canvas.domNode.width = this._model.options.width
    this._canvas.domNode.height = this._model.options.height

    this._pointerIndicator.setWidth(this._model.options.width)
    this._pointerIndicator.setHeight(this._model.options.indicatorHeight)
    this._pointerIndicator.setBackgroundColor(this._model.options.indicatorBackgroundColor)
    this._pointerIndicator.setDisplay('none')

    const sliderMinHeight = calculateSliderHeight(this._model.options)
    this._slider.setWidth(this._model.options.width)
    this._slider.setHeight(sliderMinHeight)
    this._sliderHorizontal.setWidth(this._model.options.width)
    this._sliderHorizontal.setHeight(sliderMinHeight)
  }

  private _getDensityBarDomNodeClassName(): string {
    const cls = ['density-bar']
    this._model.options.showSlider === 'mouseover' && cls.push('slider-mouseover')
    return cls.join(' ')
  }

  private _startSliderDragging(e: PointerEvent, initialY: number) {
    if (!e.target || !(e.target instanceof Element)) {
      return
    }
    this._slider.toggleClassName('active', true)
    this._sliderPointerMoveMonitor.startMonitor(
      e.target,
      (e) => {
        initialY += e.movementY
        this._setModelScrollTop(initialY)
      },
      () => {
        this._slider.toggleClassName('active', false)
      }
    )
  }

  private _movePointerIndicator(e: PointerEvent) {
    const offsetY = Math.floor(e.offsetY)
    const hitHighlightOffsetY = this._model.getHitOffsetHighlightOffsetY(offsetY)
    if (hitHighlightOffsetY) {
      this._pointerIndicator.setTop(hitHighlightOffsetY - Math.floor(this._model.options.indicatorHeight / 2 - this._model.options.markHeight / 2))
      this._pointerIndicator.setDisplay('unset')
      this._pointerIndicator.setAttribute('data-offsetY', hitHighlightOffsetY + '')
    }
  }

  private _setModelScrollTop(initialY: number) {
    this._model.setScrollTop(initialY)
  }

  private _getBuffer() {
    const ctx = this._canvas.domNode.getContext('2d')
    return ctx?.createImageData(this._model.options.width, this._model.options.height)
  }

  private _renderLines(target: ImageData, compressData: Map<number, Set<string>>) {
    // ImageData 每 4 位代表一个像素（rgba）一行共计 width * 4 pixels https://developer.mozilla.org/en-US/docs/Web/API/ImageData#instance_properties
    const widthPixels = this._model.options.width * 4
    Array.from(compressData.entries()).forEach(([top, set]) => {
      const size = set.size
      const colorList = Array.from(set.values()).map((c) => new Color(c).rgb().object())
      for (let h = 0; h < this._model.options.markHeight; h++) {
        const offsetTop = (top + h) * widthPixels
        const offsetTopNext = offsetTop + widthPixels
        for (let s = 0; s < size; s++) {
          const { r, g, b, alpha = 1 } = colorList[s]
          const range = Math.ceil(this._model.options.width / size)
          const offsetLeft = s * range * 4
          const dataOffset = offsetTop + offsetLeft
          for (let i = 0; i < range; i++) {
            const offset = dataOffset + i * 4
            // range 由于是 math.ceil 计算出来的，会有多出来的，为了防止下一行的空白行被多余出来的挤占，判断 offset 到下一行了就直接结束当前循环
            if (offset >= offsetTopNext) break
            target.data[offset + 0] = r
            target.data[offset + 1] = g
            target.data[offset + 2] = b
            target.data[offset + 3] = alpha * 255
          }
        }
        // 发现下一行被占了，那么就直接快进到下一条高亮行的渲染，这一行没必要渲染了，毕竟颜色会被下一行覆盖
        if (compressData.has(h + 1)) break
      }
    })
  }

  public onChangeOptions(): void {
    this._applyLayout()
  }

  public moveSliderTop(top: number, ratio = 1) {
    if (!ratio) return
    const transTop = Math.floor(top / ratio) // * ((this._model.options.height - calculateSliderHeight(this._model.options)) / this._model.options.height)
    const sliderMinHeight = calculateSliderHeight(this._model.options)
    const sliderTop = Math.max(0, Math.min(transTop, this._model.options.height - sliderMinHeight))
    this._slider.setTop(sliderTop)
  }

  public getDomNode() {
    return this._domNode.domNode
  }

  public dispose() {
    this._domNodePointerDownListener.dispose()
    this._domNodePointerMoveListener.dispose()
    this._pointerIndicatorPointerDownListener.dispose()
    this._pointerIndicatorPointerLeaveListener.dispose()
    this._sliderPointerMoveMonitor.dispose()
    this._sliderPointerDownListener.dispose()
  }

  public render(compress: Map<number, Set<string>>) {
    const imageData = this._getBuffer()
    if (!imageData) {
      return
    }

    this._renderLines(imageData, compress)

    const ctx = this._canvas.domNode.getContext('2d')
    ctx?.putImageData(imageData, 0, 0)
  }
}

export class DensityBar {
  private readonly _innerDensityBar: InnerDensityBar
  private _resizeObserver: ResizeObserver | undefined = undefined
  private _dom: HTMLElement | undefined = undefined
  private _mutationObserver: MutationObserver | undefined = undefined
  private _domScrollListener: IDisposable | undefined = undefined
  private _domScrollHDivideDensityBarHRatio = 1
  options: Required<DensityBarOptions>

  private compressScrollIntoViewMap: Map<number, () => void> | undefined = undefined
  // 兼容 vue 的 proxy 操作，proxy 后的对象就不是原对象了
  uuid = uniqueId()
  debounceRenderInnerDensityBar: (() => void) | undefined
  constructor(options: DensityBarOptions) {
    this.options = getOptions(options)
    this._innerDensityBar = new InnerDensityBar(this)
    densityBarMap.set(this.uuid, this)
  }

  private _getHighlightOffsetY<T extends HTMLElement>(h: T) {
    let offsetParent = h.offsetParent as HTMLElement | null
    let offset = h.offsetTop
    while (offsetParent && offsetParent !== this._dom) {
      offset += offsetParent.offsetTop || 0
      offsetParent = offsetParent.offsetParent as HTMLElement | null
    }
    return offset
  }

  private _renderInnerDensityBar() {
    if (!this._dom) return
    const clientHeight = this._dom.clientHeight
    const { height } = this._dom.getBoundingClientRect()
    if (!clientHeight) return
    let highlightList: HTMLElement[] = []
    try {
      // 没有高度的认为没有渲染（主要针对 display: 'none' 的节点会被认为 offsetTop = 0 ）
      highlightList = Array.from(this._dom.querySelectorAll(this.options.markSelector)).filter((i) => i.clientHeight > 0) as HTMLElement[]
    } catch (e) {
      console.error(e)
    }
    const scrollHeight = this._dom.scrollHeight
    this._domScrollHDivideDensityBarHRatio = scrollHeight / clientHeight
    const highlightMap = new Map<number, Set<string>>()
    const highlightQueue: (readonly [number, Set<string>, () => void])[] = []
    highlightList.forEach((item) => {
      const offset = this._getHighlightOffsetY(item)
      const set = highlightMap.get(offset) || new Set<string>()
      const backgroundColor = this.options.computeMarkColor(item)
      set.add(backgroundColor)
      if (!highlightMap.has(offset)) {
        highlightMap.set(offset, set)
        highlightQueue.push([
          offset,
          set,
          () =>
            item.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            }),
        ] as const)
      }
    })
    const compressScrollIntoViewMap = new Map<number, () => void>()
    const compressMap = new Map<number, Set<string>>()
    highlightQueue.forEach((item) => {
      const [h, set, scrollIntoView] = item
      const floorH = Math.floor(h / this._domScrollHDivideDensityBarHRatio)
      compressMap.set(floorH, set)
      compressScrollIntoViewMap.set(floorH, scrollIntoView)
    })

    this.onChangeOptions({
      height: clientHeight,
      top: Math.floor((height - clientHeight) / 2),
      sliderHeight: Math.round(clientHeight / this._domScrollHDivideDensityBarHRatio),
      left: this._dom.offsetLeft + this._dom.clientWidth - this.options.right - this.options.width,
    })
    this._domScrollListener?.dispose()
    this._innerDensityBar.moveSliderTop(this._dom.scrollTop, this._domScrollHDivideDensityBarHRatio)
    this._domScrollListener = addDisposableListener(this._dom, 'scroll', (e) => {
      e.preventDefault()
      const target = e.target as HTMLElement | null
      if (!target) return
      this._innerDensityBar.moveSliderTop(target.scrollTop, this._domScrollHDivideDensityBarHRatio)
    })
    this._innerDensityBar.render(compressMap)
    this.compressScrollIntoViewMap = compressScrollIntoViewMap
  }

  private _disposeObserver() {
    if (this._dom) {
      this._resizeObserver?.unobserve(this._dom)
      this._mutationObserver?.disconnect()
    }
  }

  public getHitOffsetHighlightOffsetY(offsetY: number) {
    return this.compressScrollIntoViewMap?.has(offsetY) ? offsetY : this.compressScrollIntoViewMap?.has(offsetY - 1) ? offsetY - 1 : undefined
  }
  public scrollIntoView(offsetY: number) {
    this.compressScrollIntoViewMap?.get(offsetY)?.()
  }
  public setScrollTop(top: number) {
    this._dom?.scrollTo({ top: Math.floor(top * this._domScrollHDivideDensityBarHRatio) })
  }

  public onChangeOptions(options: Partial<DensityBarOptions>) {
    this.options = getOptions(options, this.options)
    this._innerDensityBar.onChangeOptions()
  }

  public dispose() {
    if (this._dom) {
      this._dom?.parentElement?.removeChild(this._innerDensityBar.getDomNode())
    }
    this._innerDensityBar.dispose()
    this._disposeObserver()
    this._domScrollListener?.dispose()
    densityBarMap.delete(this.uuid)
  }

  render<T extends HTMLElement>(dom?: T) {
    this._disposeObserver()
    if (dom) {
      this._dom = dom
      this.debounceRenderInnerDensityBar = debounce(() => this._renderInnerDensityBar(), this.options.resizeDebounce)
      if (this.options.observeResize) {
        this._resizeObserver = new ResizeObserver(this.debounceRenderInnerDensityBar)
        this._resizeObserver.observe(this._dom)
      }
      if (this.options.observeMutation) {
        this._mutationObserver = new MutationObserver(this.debounceRenderInnerDensityBar)
        this._mutationObserver.observe(this._dom, { childList: true, subtree: true })
      }
      const domNode = this._innerDensityBar.getDomNode()
      // TODO if dom.parentElement's position is static, give a warning in development environment
      dom?.parentElement?.appendChild(domNode)
    } else {
      this._renderInnerDensityBar()
    }
  }
}

export const densityBarEventHub = new EventEmitter()
const densityBarMap = new Map<string, DensityBar>()
densityBarEventHub.on('update', () => {
  densityBarMap.forEach((densityBar) => densityBar.debounceRenderInnerDensityBar?.())
})
