import { ILS360UserSetting, ISynapseChatCapacityResponse, ISynapseChatRole, IUserRole } from '@patsnap/synapse_common_interface'
import { getSingle } from '@patsnap/synapse_common_utils'
import { useLs360SynapseWujieEvents } from '@pharmsnap/pharmsnap-web/composable/useLs360SynapseWujieEvents'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { computed, reactive, Ref, ref, toRefs } from '@vue/composition-api'
import { assign, isUndefined } from 'lodash'
import { IS_CN_REGION } from '../config'
import { sharedCtx } from '../context'
import { IHttpWrap } from '../service/http/types'
import { ILang, ITranslateLang } from '../types'
import { IAccountExtraData, IProfileUserInfo, IUserSecurityControl, IUserUsageInfo } from '../types/account'
import { IUserInfo, IUserSetting } from '../types/login'
export interface IUpgrade {
  /**
   * 是否显示升级弹窗
   */
  show: boolean
  /**
   * 升级弹窗的类型
   */
  type: E_UPGRADE_TYPE
  /**
   * 升级弹窗的方案
   */
  apply_plan?: IUserRole
  /**
   * 显示弹出的原因
   */
  title?: string
  /**
   * 触发点,用于埋点
   */
  trigger_point?: string
  /**
   * gif文件地址
   */
  file?: string
  /**
   * 功能描述, 只对 USE_PAID_FEATURE_NORMAL 生效
   */
  description?: string
  /**
   * 点击查看方案按钮
   */
  onViewPlanCallback?: () => void
}
/**
 * 默认的机翻语言
 * 中美的默认值不一样
 */
const defaultTranslateLang: ILang = IS_CN_REGION ? 'CN' : 'EN'
const limitFuncTuple: Array<Exclude<keyof IUserUsageInfo, 'other'>> = [
  IS_CN_REGION ? 'search' : 'patent_search',
  'drug_export',
  'alert',
  'dashboard',
  'search',
  IS_CN_REGION ? 'search' : 'clinical_trial_result_search',
  IS_CN_REGION ? 'search' : 'drug_deal_search',
  IS_CN_REGION ? 'search' : 'translational_medicine_search',
]

function useAuthFactory() {
  const { bus } = useLs360SynapseWujieEvents()
  const role = ref<IUserRole>('FREE')
  const synapseChatRole = ref<ISynapseChatRole>()
  const upgrade = ref<IUpgrade>({
    show: false,
    title: '',
    type: '' as E_UPGRADE_TYPE,
  })
  const userFeatures: Ref<string[]> = ref([])
  const userInfo: Ref<IUserInfo | null> = ref(null)
  const boProfileInfo: Ref<IProfileUserInfo | null> = ref(null)
  const accountExtraInfo: Ref<IAccountExtraData | null> = ref(null)
  const userSetting: Ref<IUserSetting> = ref({})
  const synapseChatUserSetting: Ref<ILS360UserSetting> = ref({})
  const usageInfo: Ref<IUserUsageInfo | null> = ref(null)
  const synapseChatUsage: Ref<ISynapseChatCapacityResponse | null> = ref(null)
  const groupUsageInfo: Ref<IUserUsageInfo | null> = ref(null)
  const getUserInfoLoading = ref(true)
  const securityControl: Ref<IUserSecurityControl> = ref({})
  const state = reactive({
    upgrade,
    userInfo,
    userSetting,
    usageInfo,
    groupUsageInfo,
    role,
    synapseChatRole,
    getUserInfoLoading,
    boProfileInfo,
    userFeatures,
    synapseChatUsage,
  })
  let promise: Promise<IHttpWrap<IUserUsageInfo>> | null = null
  const [
    checkPatentSearchLimit,
    checkExportDrugLimit,
    checkAlertLimit,
    checkDashboardLimit,
    checkSearchLimit,
    checkClinicalResultSearchLimit,
    checkDrugDealSearchLimit,
    checkTransMedicineSearchLimit,
  ] = limitFuncTuple.map((i) => {
    return async () => {
      if (!promise) {
        promise = sharedCtx.service.account.getUserUsage(i)
      }
      const rt = await promise
      promise = null
      if (rt.success && rt.data) {
        usageInfo.value = { ...usageInfo.value, ...rt.data }
        const currentUsage = usageInfo.value[i]
        if (currentUsage && !isUndefined(currentUsage.total) && currentUsage.current >= currentUsage.total) {
          // 超限
          return true
        } else {
          // 没有超限
          return false
        }
      }
      return false
    }
  })
  const getters = {
    /**
     * 用户名
     */
    userName: computed(() => {
      const data = userInfo.value
      if (data?.nickname) {
        return data.nickname
      }
      if (data?.firstname && data.lastname) {
        return `${data.firstname} ${data.lastname}`
      }
      if (data?.email) {
        return data.email.split('@')[0]
      }
      if (data?.mobile_number) {
        return data?.mobile_number
      }
      return ''
    }),
    isSupportIntelligentRetrieval: computed(() => userFeatures.value.includes('IntelligentRetrieval')),
    /** 是否支持Copilot */
    isSupportCopilot: computed(() => userFeatures.value.includes('Copilot')),

    /**
     * 是否是基础用户(国内/国外)
     */
    isFreeUser: computed(() => role.value === 'FREE'),
    /**
     * 是否是标准版（国内）or 是否是专业版（国外）
     */
    isBasicUser: computed(() => role.value === 'BASIC'),
    /**
     * 是否是专业版（国内试用，权限相当于ENTERPRISE）or 是否是专业版（国外试用，权限相当于BASIC）
     */
    isTrialUser: computed(() => role.value === 'TRIAL'),
    /**
     * 是否为企业版用户，只有中国版有企业版
     */
    isEnterpriseUser: computed(() => role.value === 'ENTERPRISE'),

    /**
     * synapse chat 是否为基础版
     */
    synapseChatIsFree: computed(() => synapseChatRole.value === 'FREE'),
    /**
     * synapse chat 是否为试用版
     */
    synapseChatIsTrial: computed(() => synapseChatRole.value === 'TRIAL'),
    /**
     * synapse chat 是否为专业版
     */
    synapseChatIsBasic: computed(() => synapseChatRole.value === 'BASIC'),
    /**
     * 根据region判断用户是否是试用/专业版用户
     */
    isTrialOrProfessionalUser: computed(() =>
      IS_CN_REGION ? role.value === 'TRIAL' || role.value === 'ENTERPRISE' : role.value === 'TRIAL' || role.value === 'BASIC'
    ),

    /** 是否有backoffice权限 */
    hasAdminConsole: computed(() => accountExtraInfo.value?.admin_console),
    /** 账号并发数 */
    accountConcurrency: computed(() => userInfo.value?.concurrency),
    /**
     * 是否开启机翻
     */
    translate: computed(() => {
      // 值为original 才认为关闭翻译功能
      if (userSetting.value.custom_setting?.translation === 'original') {
        return false
      }
      // 没有translation值则前端则需要按照部署区域默认开启翻译,所以也返回true
      return true
    }),
    defaultTranslateLang: computed(() => defaultTranslateLang),
    /**
     * 机翻语言(主要用与传给后端,告诉后端是否需要翻译,会将original处理成空字符给后端)
     */
    translation: computed(() => {
      if (
        // 如果没有值,则需要按照部署区域默认开启翻译
        !userSetting.value.custom_setting?.translation
      ) {
        return defaultTranslateLang
      }
      // 如果是设置的显示原文,不要开启翻译,需要传空字符串给后端
      if (userSetting.value.custom_setting?.translation === 'original') {
        return ''
      }
      // 显示用户设置的机翻语言
      return userSetting.value.custom_setting?.translation
    }),
    /**
     * 机翻语言(用户设置的原始值)
     */
    customSettingTranslation: computed(() => {
      return userSetting.value.custom_setting?.translation
    }),
    /**
     * 是否开启beta
     */
    isOpenChatBeta: computed(() => synapseChatUserSetting.value.custom_setting?.is_open_chat_beta ?? true),
    /**
     * dashboard 上限,未返回则无限制
     */
    totalDashboardLimit: computed(() => usageInfo.value?.dashboard.total || 0),
    /**
     * 当前已创建的dashboard,未返回则无限制
     */
    createdDashboardLimit: computed(() => usageInfo.value?.dashboard.current || 0),
    /**
     * 单个dashboard内widget上限,未返回则无限制
     */
    perDashboardWidgetLimit: computed(() => usageInfo.value?.other.max_widget_per_dashboard || 0),
    /**
     * 专利导出限制,企业版只有总数限制，其他版本，有单次导出限制
     */
    patentExportLimit: computed(() => usageInfo.value?.other.max_patent_export_per_time || usageInfo.value?.patent_export?.total),
    /**
     * 药物导出限制
     */
    drugExportLimit: computed(() => usageInfo.value?.other.max_drug_export_per_time || usageInfo.value?.drug_export?.total),
    /**
     * 交易导出限制
     */
    drugDealExportLimit: computed(() => usageInfo.value?.other.max_drug_deal_export_per_time || usageInfo.value?.drug_deal_export?.total),
    /**
     * 临床试验导出限制
     */
    clinicalTrialExportLimit: computed(
      () => usageInfo.value?.other.max_clinical_trial_export_per_time || usageInfo.value?.clinical_trial_export?.total
    ),
    /**
     * 临床结果导出限制
     */
    clinicalTrialResultExportLimit: computed(
      () => usageInfo.value?.other.max_clinical_trial_result_export_per_time || usageInfo.value?.clinical_trial_result_export?.total
    ),
    /**
     * 转化医学导出限制
     */
    translationalMedicineExportLimit: computed(
      () => usageInfo.value?.other.max_translational_medicine_export_per_time || usageInfo.value?.translational_medicine_export?.total
    ),
    /**
     * 文献导出限制
     */
    paperExportLimit: computed(() => usageInfo.value?.other.max_paper_export_per_time || usageInfo.value?.paper_export?.total),
    /**
     * 绑定邮箱
     */
    email: computed(() => userInfo.value?.email || ''),
    /**
     * 是否设置过自己感兴趣的实体
     */
    haveSetCustomEntity: computed(() => {
      return !!(
        userSetting.value?.onboarding?.diseases?.length ||
        userSetting.value?.onboarding?.targets?.length ||
        userSetting.value?.onboarding?.drugs?.length ||
        userSetting.value?.onboarding?.orgs?.length
      )
    }),
    hasFollowedPharmsnap: computed(() => {
      const profile = boProfileInfo.value
      return profile?.unionid_binded && profile?.app_names?.includes('SYNAPSE')
    }),
    /** 到期日期 */
    expireDate: computed(() => userInfo.value?.phs_role_end),
    /**
     * 用户是否已经完成了个人信息的填写
     */
    isProfileComplete: computed(() => userInfo.value?.profile_complete),
    company: computed(() => boProfileInfo.value?.manual_company || boProfileInfo.value?.company_name || ''),
    customSetting: computed(() => userSetting.value.custom_setting || {}),
    /**
     * 是否开启无痕模式
     */
    isIncognito: computed(() => securityControl.value.incognito_flag),
    synapseChatReachLimit: computed(() => {
      if (synapseChatRole.value === 'FREE') {
        if (!synapseChatUsage.value) return true

        const awardUsed = synapseChatUsage.value.award_used
        const awardTotal = synapseChatUsage.value.award_total
        const dailyTotal = synapseChatUsage.value.daily_total
        const dailyUsed = synapseChatUsage.value.daily_used

        return awardUsed === awardTotal && dailyTotal === dailyUsed
      }
      if (synapseChatRole.value === 'TRIAL') {
        if (!synapseChatUsage.value) return true

        const awardUsed = synapseChatUsage.value.award_used
        const awardTotal = synapseChatUsage.value.award_total
        const dailyTotal = synapseChatUsage.value.daily_total
        const dailyUsed = synapseChatUsage.value.daily_used

        return awardUsed === awardTotal && dailyTotal === dailyUsed
      }

      if (synapseChatRole.value === 'BASIC') {
        if (!synapseChatUsage.value) return true

        const threeHoursTotal = synapseChatUsage.value.three_hours_total
        const threeHoursUsed = synapseChatUsage.value.three_hours_used

        if (isUndefined(threeHoursTotal) || isUndefined(threeHoursUsed)) return true

        return threeHoursUsed >= threeHoursTotal
      }

      return true
    }),
  }
  const actions = {
    setUserInfo: (data: IUserInfo) => {
      userInfo.value = data
    },
    setRole: (data: IUserRole) => {
      role.value = data
    },
    setUpgrade: (data: IUpgrade) => {
      upgrade.value = data
    },
    syncUserInfo: async (refresh_cache?: boolean) => {
      const [userInfoRt] = await Promise.all([
        sharedCtx.service.login.getUser(refresh_cache ? { refresh_cache } : undefined),
        actions.syncBoProfileInfo(),
        actions.syncAccountExtraInfo(),
        actions.syncSynapseUserFeatures(),
        actions.syncSynapseUserSecurityControl(),
      ])
      if (userInfoRt.success && userInfoRt.data) {
        userInfo.value = userInfoRt.data
        userSetting.value = userInfoRt.data.setting || {}
        role.value = userInfoRt.data.phs_role
        synapseChatRole.value = userInfoRt.data.ls360_role
        /**
         * 处理中国用户的老数据,将以前设置翻译成英文的改为翻译成中文
         */
        if (userSetting.value.custom_setting?.translation === 'EN' && IS_CN_REGION) {
          actions.changeTranslation('CN')
        }
        /**
         * 处理海外用户的老数据,将以前设置翻译成中文的改为翻译成英文
         */
        if (userSetting.value.custom_setting?.translation === 'CN' && !IS_CN_REGION) {
          actions.changeTranslation('EN')
        }
      }
      getUserInfoLoading.value = false
    },
    syncSynapseChatUserInfo: async (refresh_cache?: boolean) => {
      const res = await sharedCtx.service.ls360Api.getUserProfile(refresh_cache ? { refresh_cache } : undefined)
      if (res.success && res.data) {
        synapseChatUserSetting.value = res.data.setting || {}
      }
    },

    /**
     * 同步用户的设置
     */
    syncUserSetting: async () => {
      const userInfoRt = await sharedCtx.service.login.getUserSetting()
      if (userInfoRt.success && userInfoRt.data) {
        userSetting.value = userInfoRt.data

        return true
      }
      return false
    },
    /**
     * 个人账号用量
     */
    syncUserUsage: async (usage_type?: keyof IUserUsageInfo) => {
      const userUsageRt = await sharedCtx.service.account.getUserUsage(usage_type)
      if (userUsageRt.success && userUsageRt.data) {
        usageInfo.value = assign({}, usageInfo.value, userUsageRt.data)
      }
    },
    /**
     * 组织用量，包含所有账号的用量
     */
    syncGroupUsage: async (usage_type?: keyof IUserUsageInfo) => {
      const groupUsageRt = await sharedCtx.service.account.getUserUsage(usage_type, 'group')
      if (groupUsageRt.success && groupUsageRt.data) {
        groupUsageInfo.value = assign({}, groupUsageInfo.value, groupUsageRt.data)
      }
    },
    /**
     * synapse chat 用量
     */
    syncSynapseChatUsage: async () => {
      const chatUsageRt = await sharedCtx.service.ls360Api.getChatCapacity()
      if (chatUsageRt.success && chatUsageRt.data) {
        synapseChatUsage.value = assign({}, synapseChatUsage.value, chatUsageRt.data)
        synapseChatUsage.value && bus.$emit('main-synapse:update-synapse-chat-usage', synapseChatUsage.value)
      }
    },
    syncBoProfileInfo: async () => {
      const userProfile = await sharedCtx.service.login.getBoUserProfile()
      if (userProfile.success && userProfile.data) {
        boProfileInfo.value = userProfile.data
      }
    },
    syncAccountExtraInfo: async () => {
      const rt = await sharedCtx.service.login.getAccountExtraInfo()
      if (rt.success && rt.data) {
        accountExtraInfo.value = rt.data.data || null
      }
    },
    syncSynapseUserFeatures: async () => {
      const rt = await sharedCtx.service.account.getFeatureConfig()
      if (rt.success && rt.data) {
        userFeatures.value = rt.data
      }
    },
    syncSynapseUserSecurityControl: async () => {
      const rt = await sharedCtx.service.account.getUserSecurityControl()
      if (rt.success && rt.data) {
        securityControl.value = rt.data
      }
    },
    /**
     * 切换语言,并同步设置
     * @param lang
     */
    changeTranslation: async (lang: ITranslateLang) => {
      updateUserSetting({
        custom_setting: { translation: lang },
      })
    },
    /**
     * 切换beta开关，并同步设置
     * @param status beta 开关 状态
     */
    changeChatBetaStatus: async (status: boolean) => {
      const res = await updateChatUserSetting({
        custom_setting: { is_open_chat_beta: status },
      })
      if (res.success && res.data) {
        const isOpenBeta = res.data.custom_setting?.is_open_chat_beta ?? true
        bus.$emit('main-synapse:update-beta-status', isOpenBeta)

        bus.$emit('main-synapse:update-feature-config', {
          canUseDeepSearch: isOpenBeta,
          canUseFileUpload: isOpenBeta,
        })
      }
    },
    changeUpgrade(data: IUpgrade) {
      if (data.show) {
        if (data.type === E_UPGRADE_TYPE.CONTACT_SALES) {
          sharedCtx.service.tracking.trackGeneral({
            event_code: 'CLICK_UPGRADE',
            trigger_point: data.trigger_point,
            common1: data.apply_plan,
          })
        } else {
          sharedCtx.service.tracking.trackGeneral({
            event_code: 'TRIGGER_UPGRADE',
            trigger_point: data.trigger_point,
          })
        }
      }
      upgrade.value = data
    },
    checkExportDrugLimit,
    checkAlertLimit,
    checkDashboardLimit,
    checkSearchLimit,
    updateUserSetting,
    updateChatUserSetting,
    checkPatentSearchLimit,
    checkClinicalResultSearchLimit,
    checkDrugDealSearchLimit,
    checkTransMedicineSearchLimit,
  }
  async function updateUserSetting(data: Partial<IUserSetting>) {
    // custom_setting字段比较特殊,后端存的是json,这个字段是覆盖更新,所以更新该字段需要带上以前用户的存储的值
    // 其他字段不需要处理
    if (data.custom_setting) {
      data.custom_setting = { ...userSetting.value.custom_setting, ...data.custom_setting }
    }
    Object.assign(userSetting.value, data)
    return sharedCtx.service.login.setUserSetting(data)
  }

  async function updateChatUserSetting(data: Partial<ILS360UserSetting>) {
    if (data.custom_setting) {
      data.custom_setting = { ...synapseChatUserSetting.value.custom_setting, ...data.custom_setting }
    }
    Object.assign(synapseChatUserSetting.value, data)
    return sharedCtx.service.ls360Api.updateUserSettings(data)
  }

  return { state: toRefs(state), getters, actions }
}
export const useAuthStore = getSingle(useAuthFactory)
window.useAuthStore = useAuthStore
