import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { defineComponent, unref } from '@vue/composition-api'
import { GDialog } from '../../ui/GDialog/GDialog'
import { GIcon } from '../../ui/GIcon/GIcon'
import { BFilterAdvanceNext } from './BFilterAdvanceNext'
import $classes from './BFilterAdvanceNext.module.scss'
import { BFilterSubmit } from './components/BFilterSubmit'
import { useInjectFilterService } from './compositions/useProvideFilterService'
import cn from './locales/cn.json'
import en from './locales/en.json'
import jp from './locales/jp.json'
import tw from './locales/tw.json'

export const BFilterAdvanceNextDialog = defineComponent({
  name: 'BFilterAdvanceNextDialog',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    const service = useInjectFilterService()

    return service
  },
  methods: {
    handleExclude() {
      this.advanceDialogService.submit('exclude')
    },
    handleRefine() {
      this.advanceDialogService.submit('refine')
    },
  },
  render() {
    return (
      <GDialog
        onClose={() => this.advanceDialogService.close()}
        onCancel={() => this.advanceDialogService.close()}
        emptyMode={true}
        width="910px"
        visible={unref(this.advanceDialogService.visible)}
        scopedSlots={{
          default: () => {
            return (
              <div class="flex flex-col" style="height: 558px;">
                <div class="flex-1 overflow-hidden">
                  <BFilterAdvanceNext class="h-full">
                    <template slot="topRight">
                      <GIcon
                        class="cursor-pointer hover:rounded hover:bg-gray-60_alpha"
                        svgName="SolidCloseBig"
                        size={24}
                        useSvgDefaultColor={true}
                        nativeOn={{
                          click: () => this.advanceDialogService.close(),
                        }}
                      ></GIcon>
                    </template>
                  </BFilterAdvanceNext>
                </div>
                <div class="flex-shrink-0 py-2 pr-2 text-right">
                  <span class="inline-flex items-center">
                    {this.$scopedSlots.actionBtn
                      ? this.$scopedSlots.actionBtn({
                          cancel: () => this.advanceDialogService.cancel(),
                          submit: () => this.advanceDialogService.submit('refine'),
                          exclude: () => this.advanceDialogService.submit('exclude'),
                        })
                      : [
                          <button
                            onClick={() => this.advanceDialogService.cancel()}
                            class={['pt-ui-btn mr-2', $classes.cancel]}
                            type="button"
                            data-type="transparent"
                          >
                            {this.$t('bFilterNext.cancel')}
                          </button>,
                          <BFilterSubmit
                            onExclude={this.handleExclude}
                            onRefine={this.handleRefine}
                            layout="horizontal"
                            hideWhenDisabled={false}
                          ></BFilterSubmit>,
                        ]}
                  </span>
                </div>
              </div>
            )
          },
        }}
      ></GDialog>
    )
  },
})
