import { DATE_TYPE, ITimeConfig } from '@pharmsnap/shared/types/time'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const getDateTypeConfigMap: (isUtc?: boolean) => Record<DATE_TYPE, ITimeConfig> = (isUtc = true) => {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  const now = _dayjs().endOf('day').valueOf()
  return {
    ANY_TIME: {
      display_name_cn: '任意时间',
      display_name_en: 'Any Time',
    },
    PAST_WEEK: {
      display_name_cn: '最近一周',
      display_name_en: 'Last Week',
      from: _dayjs().subtract(7, 'day').startOf('day').valueOf(),
      to: now,
    },
    PAST_MONTH: {
      display_name_cn: '最近30天',
      display_name_en: 'Past 30 days',
      from: _dayjs().subtract(30, 'day').startOf('day').valueOf(),
      to: now,
    },
    PAST_YEAR: {
      display_name_cn: '最近一年',
      display_name_en: 'Past Year',
      from: _dayjs().subtract(1, 'year').startOf('year').valueOf(),
      to: now,
    },
    PAST_FIVE_YEARS: {
      display_name_cn: '最近五年',
      display_name_en: 'Past 5 Years',
      from: _dayjs().subtract(5, 'year').startOf('year').valueOf(),
      to: now,
    },
    CUSTOM_DATE_RANGE: {
      display_name_cn: '自定义时间',
      display_name_en: 'Custom Date Range',
    },
  }
}

export const HOME_DRUG_STORE_RANGE = 'HOME_DRUG_STORE_RANGE'
export const HOME_RECOMMEND_LAST_UPDATE = 'HOME_RECOMMEND_LAST_UPDATE'

export const DEFAULT_HOME_LITERATURE_RANGE_TIME = [dayjs().subtract(1, 'year').format('YYYY'), dayjs().format('YYYY')]
export const DEFAULT_HOME_RANGE_TIME = [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
export const DEFAULT_HOME_RANGE_TIME_30DAYS = [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
export const DEFAULT_HOME_RANGE_TIME_6MONTH = [dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
