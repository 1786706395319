import '@patsnap-ui/icon/assets/solid/OfficeWord.svg'
import { defineComponent } from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import { useLocale } from 'pharmsnapMF_shared/composition'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { downloadCopilotReport } from './util'
export const CopilotDownloadReportBtn = defineComponent({
  name: 'CopilotDownloadReportBtn',
  props: {
    s3_path: {
      required: true,
      type: String,
    },
    reportName: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const handleDownloadReport = async () => {
      await downloadCopilotReport(props.s3_path, props.reportName, isCN.value)
    }
    return { handleDownloadReport, isCN }
  },
  render() {
    return (
      <div
        class="flex justify-center  py-2 px-3 border border-blue-default text-blue-default text-sm items-center space-x-1 hover:bg-blue-hover hover:text-white-default rounded-lg cursor-pointer"
        onClick={() => this.handleDownloadReport()}
      >
        <GIcon svgName="SolidOfficeWord" size={24} class="flex-shrink-0" />
        <span class="truncate ">
          {this.$t('export.export') + (!this.isCN ? ' ' : '')}
          {this.reportName}
        </span>
      </div>
    )
  },
})
