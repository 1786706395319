import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import { defineComponent } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import $classes from './GLoading.module.scss'

export const GLoading = defineComponent({
  name: 'GLoading',
  props: {
    size: {
      type: Number,
      default: 50,
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        <span class={$classes.loading}>
          <PtIcon icon="SolidLoadingBars" style={{ fill: 'rgb(80, 95, 121)', fontSize: `${this.size}px` }}></PtIcon>
          {this.$slots.default}
        </span>
      </div>
    )
  },
})
