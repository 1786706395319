import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { I18nLang } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { round } from 'lodash'
import { i18n } from '../i18n'
import { IAmountConfig, IFormatAmountV2param } from '../types/base'
import { E_AMOUNT_UNIT } from '../types/enum'

const AMOUNT_UNIT: Record<E_AMOUNT_UNIT, IAmountConfig> = Object.freeze({
  [E_AMOUNT_UNIT.ALL]: {
    unit: '',
    fullUnit: '',
    value: 1,
  },
  [E_AMOUNT_UNIT.THOUSAND]: {
    unit: 'K',
    fullUnit: 'thousand',
    value: 1.0e3,
  },
  [E_AMOUNT_UNIT.BILLION]: {
    unit: 'B',
    fullUnit: 'billion',
    value: 1.0e9,
  },
  [E_AMOUNT_UNIT.MILLION]: {
    unit: 'M',
    fullUnit: 'million',
    value: 1.0e6,
  },
})

/**
 * 格式化金钱
 */
export function formatAmountV2(param: IFormatAmountV2param): string {
  // 接收到空自字符串直接返回Undisclosed
  const mergeParam = {
    hasMoneyType: true,
    moneyType: 'USD',
    isAddUnit: true,
    toFixed: 2,
    ...param,
  }
  const { amount, hasMoneyType, moneyType, unit, isAddUnit, isShowThousand } = mergeParam
  if (typeof amount !== 'number' && !amount) {
    return i18n.tc('common.undisclosed')
  }
  const minus = amount < 0 ? '-' : ''

  let unitStr = ''
  const amountNumber = Math.abs(Number(amount || 0))
  let amountStr = amountNumber.toString()
  const currentMoneyType = hasMoneyType ? `${moneyType} ` : ''
  const ALL = AMOUNT_UNIT[E_AMOUNT_UNIT.ALL]
  if (unit) {
    const currentUnit = AMOUNT_UNIT[unit]
    if (currentUnit && amountNumber >= currentUnit.value) {
      // 如果起始单位大于1,则保留两位小数
      if (currentUnit.value > ALL.value) {
        amountStr = round(amountNumber / currentUnit.value, 2).toFixed(mergeParam.toFixed)
      } else {
        amountStr = round(amountNumber / currentUnit.value, 2).toString()
      }
      unitStr = ` ${currentUnit.unit}`
    }
  } else {
    const BILLION = AMOUNT_UNIT[E_AMOUNT_UNIT.BILLION]
    const MILLION = AMOUNT_UNIT[E_AMOUNT_UNIT.MILLION]
    const THOUSAND = AMOUNT_UNIT[E_AMOUNT_UNIT.THOUSAND]

    const compareThousandCondition = isShowThousand ? amountNumber > THOUSAND.value : false

    if (amountNumber >= BILLION.value) {
      amountStr = round(amountNumber / BILLION.value, 2).toFixed(mergeParam.toFixed)
      unitStr = ` ${BILLION.unit}`
    } else if (amountNumber >= MILLION.value) {
      amountStr = round(amountNumber / MILLION.value, 2).toFixed(mergeParam.toFixed)
      unitStr = ` ${MILLION.unit}`
    } else if (compareThousandCondition) {
      amountStr = round(amountNumber / THOUSAND.value, 2).toFixed(mergeParam.toFixed)
      unitStr = ` ${THOUSAND.unit}`
    }
  }

  return `${currentMoneyType}${minus}${toThousands(amountStr)}${isAddUnit ? unitStr : ''}`
}

export function getVCAmount<T extends { money_raised_cn?: string; money_raised_en?: string }>(data: T, locale: I18nLang): string {
  return (locale === 'cn' ? data.money_raised_cn : data.money_raised_en) || EMPTY_PLACEHOLDER
}
