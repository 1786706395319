import { ITreeChartNode, TreeChart, drawNodeRect } from '@patsnap/synapse_tree_chart'

declare module '@patsnap/synapse_tree_chart' {
  interface TreeChart<Datum> {
    filterLeaf: () => void
  }
}

export interface IHighlightOptions<Datum> {
  filterLeaf: (data: Datum) => boolean
}

function filter<Datum>(treeChart: typeof TreeChart, entity: TreeChart<Datum>, options?: IHighlightOptions<Datum>) {
  const traverse = function (d: ITreeChartNode<Datum>, callback: (node: Datum) => void) {
    if (d._children) {
      d.children = d._pretreated_children = d._children.filter((i) => {
        traverse(i, callback)
        return i._pretreated_children?.length || callback(i.data)
      })
      if (d.children?.length) {
        d._collapsed = false
      } else {
        d.children = undefined
      }
    }
  }
  const eachAfter = function (this: TreeChart<Datum>) {
    if (!options?.filterLeaf) {
      throw new Error('options.filterLeaf is required')
    }
    const nodeGroup = this._nodeGroup
    // node 节点样式重置
    const nodes = nodeGroup.selectAll<SVGGElement, ITreeChartNode<Datum>>('g')
    const rect = nodes.select<SVGGElement>('rect')
    drawNodeRect(rect)
    traverse(this._hierarchyNode, options.filterLeaf)

    // this._hierarchyNode.eachAfter((d) => {
    //   if (d._children) {
    //     d.children = d._pretreated_children = d._children.filter((i) => i._pretreated_children?.length || options.filterLeaf(i.data))
    //     if (d.children?.length) {
    //       d._collapsed = false
    //     } else {
    //       d.children = undefined
    //     }
    //   }
    // })
  }
  const proto = treeChart.prototype
  proto.filterLeaf = function () {
    eachAfter.call(this)
    this.render()
  }
  const boundedEachAfter = eachAfter.bind(entity)
  entity._emitter.on('beforeMount', boundedEachAfter)
}

export default {
  install: filter,
}
