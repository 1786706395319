import { IAggregationData, IAggregationParams, ILang, ISearchParams, ITransMedicineDetail } from '@patsnap/synapse_common_interface'
import { AxiosRequestConfig } from 'axios'
import { ISearchResult } from '../../types'
import { IResearchDirectionChartParams, IResearchDirectionChartResponse } from '../../types/translational-medicine'
import { transformAggregationResponse } from '../../utils/business/aggregationTransform/base'
import { transformTransMedicineAggregationSingleResult } from '../../utils/business/aggregationTransform/translational-medicine'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class TranslationalMedicineApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<any>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine/search_for_count'),
      cache: true,
    })
  }

  /**
   * 转化医学搜索列表，不计搜索次数
   */
  async getTranslationalMedicineList(
    params: ISearchParams & { all_org?: boolean },
    config?: AxiosRequestConfig
  ): Promise<IHttpWrap<ISearchResult<ITransMedicineDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine/search'),
      trackingName: 'TRANSLATIONAL_MEDICINE_LIST',
      cache: true,
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }
  /**
   * 转化医学搜索列表，计搜索次数
   */
  async getTranslationalMedicineWithCalcLimits(
    params: ISearchParams & { all_org?: boolean },
    config?: AxiosRequestConfig
  ): Promise<IHttpWrap<ISearchResult<ITransMedicineDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine'),
      trackingName: 'TRANSLATIONAL_MEDICINE_LIST',
      cache: true,
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }

  async getResearchDirectionChartData(params: IResearchDirectionChartParams): Promise<IHttpWrap<IResearchDirectionChartResponse>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine/research_direction'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformTransMedicineAggregationSingleResult))
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformTransMedicineAggregationSingleResult))
  }

  async downloadResearchDirectionAllData(params: IResearchDirectionChartParams & { language: ILang }): Promise<IHttpWrap<Blob, Blob>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/translational-medicine/research_direction/download'),
      data: params,
      method: 'POST',
      responseType: 'blob',
    })
  }
}
