import { defineComponent, nextTick, onMounted, PropType, ref } from '@vue/composition-api'
import $classes from './GTimeline.module.scss'

export const GTimelineItem = defineComponent({
  name: 'GTimelineItem',
  props: {
    content: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<'left' | 'right' | 'top' | 'bottom'>,
      default: 'top',
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const contentRef = ref()
    const itemRef = ref()
    onMounted(() => {
      if (contentRef.value && itemRef.value && props.autoWidth) {
        const width = contentRef.value.offsetWidth
        nextTick(() => {
          itemRef.value.style.width = `${width}px`
        })
      }
    })
    return {
      contentRef,
      itemRef,
    }
  },
  methods: {},
  render() {
    const clsStyle =
      this.mode === 'left'
        ? $classes.timelineItemLeft
        : this.mode === 'right'
        ? $classes.timelineItemRight
        : this.mode === 'top'
        ? $classes.timelineItemTop
        : this.mode === 'bottom'
        ? $classes.timelineItemBottom
        : ''
    return (
      <li class={[$classes.timelineItem, clsStyle]} ref="itemRef">
        <div class={$classes.timelineItemTail}></div>
        <div class={$classes.timelineItemContent} ref="contentRef">
          {this.$slots.default}
        </div>
      </li>
    )
  },
})
