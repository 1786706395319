import { defineComponent } from '@vue/composition-api'

export const BDrugTypeItem = defineComponent({
  name: 'BDrugTypeItem',
  props: {
    data: {
      required: true,
      type: String,
    },
    breakContent: {
      type: String,
      default: '',
    },
  },
  render() {
    return (
      <span class="text-sm leading-6 text-text-default" title={this.data}>
        {this.data}
        {this.breakContent ? this.breakContent : null}
      </span>
    )
  },
})
