import { unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { IFilterSimpleSearchValue } from '../type'

export type IUseFilterSimpleSearchService = {
  data: MaybeRef<IFilterSimpleSearchValue>
  onConditionChange?: (data: IFilterSimpleSearchValue) => void | Promise<void>
}

export function useFilterSimpleSearchService(options: IUseFilterSimpleSearchService) {
  const { data } = options

  function updateKeywords(keyword: string) {
    unref(data).keyword = keyword
  }

  async function updateCondition(condition: IFilterSimpleSearchValue['condition']) {
    unref(data).condition = condition
  }

  function clearKeywords() {
    unref(data).keyword = ''
  }

  return {
    updateKeywords,
    updateCondition,
    clearKeywords,
    data,
  }
}
