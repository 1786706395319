/* eslint-disable @typescript-eslint/ban-types */
import { ALL_DEV_STATUS_COLOR_MAP, CLINICAL_MULTI_CENTER_COLOR_MAP, INACTIVE_DEV_STATUS_IDS } from '@patsnap/synapse_common_config'
import {
  E_QUERY_ITEM_CONDITION,
  E_SORT_ORDER,
  IListItem,
  IQuery,
  IQueryItemField,
  IQueryValue,
  IQueryValueText,
  ISearchParams,
} from '@patsnap/synapse_common_interface'
import dayjs from 'dayjs'
import { findIndex, groupBy, isArray, map, slice, sortBy } from 'lodash'
import { INACTIVE_PHASE_EN_NAMES, IS_CN_REGION, PHASE_DEFAULT_COLOR, socialMedialIconMap } from '../config'
import { I18nLang } from '../i18n'
import {
  E_SOCIAL_MEDIA_TYPE,
  IBAcTag,
  IBaseDictItem,
  IBaseSocialMediaInfo,
  IHistorySearchParams,
  ILang,
  IQueryDataListType,
  ITarget2QueryParam,
  IUseChartTwoDimTupleItem,
} from '../types'
import { getOneYearRangeByYear } from './time'

export const convertTimestamp2RangeQueryItem = (item: number[], format?: 'YYYYMMDD' | 'YYYYMM' | 'YYYY', isUtc = false): IQueryValue[] => {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  const start = _dayjs(item[0]).format('YYYY/MM/DD')
  const end = _dayjs(item[1]).format('YYYY/MM/DD')

  return [
    {
      type: 'range',
      from: format ? Number(_dayjs(item[0]).format(format)) : item[0],
      to: format ? Number(_dayjs(item[1]).format(format)) : item[1],
      key: 'CUSTOM_DATE_RANGE',
      display_name_en: `${start}-${end}`,
      display_name_cn: `${start}-${end}`,
      include_lower: true,
      // 时间戳维度，都是左闭右开，其他维度左闭右闭
      include_upper: format ? true : false,
    },
  ]
}

/**
 * 获取指定语言的所有数据
 * @deprecated 请使用 packages/shared/src/utils/lang.ts:getAllSpecialLang
 * @param names 有name的字段的数组
 * @param lang 当前语言
 * @param defaultLang 默认语言
 * @returns
 */
export function pickSpecialLangName<T extends { name: string; lang: ILang }>(names: T[], lang: ILang, defaultLang: ILang = 'EN') {
  const getNames = (_lang: ILang) => {
    return (names || []).filter((i) => i.lang === _lang && !!i.name).map((i) => i.name)
  }

  const items = getNames(lang)
  if (items.length === 0 && lang !== defaultLang) {
    return getNames(defaultLang)
  }
  return items
}
/**
 * 获取指定语言的所有数据
 * @deprecated 请使用 packages/shared/src/utils/lang.ts:getAllSpecialLang
 * @param names 有title的字段的数组
 * @param lang 当前语言
 * @param defaultLang 默认语言
 * @returns
 */
export function pickSpecialLangTitle<T extends { title: string; lang: ILang }>(names: T[], lang: ILang, defaultLang: ILang = 'EN') {
  const getNames = (_lang: ILang) => {
    return (names || []).filter((i) => i.lang === _lang && !!i.title).map((i) => i.title)
  }

  const items = getNames(lang)
  if (items.length === 0) {
    return getNames(defaultLang)
  }
  return items
}

/**
 * 获取匹配的第一条指定语言的数据
 * @deprecated 请使用 packages/shared/src/utils/lang.ts:getSpecialLang
 * @param names 有name的字段的数组
 * @param lang 当前语言
 * @param defaultLang 默认语言
 * @returns
 */
export function pickFirstSpecialLangName<T extends { name: string; lang: ILang }>(names: T[], lang: ILang, defaultLang: ILang = 'EN') {
  return pickSpecialLangName(names, lang, defaultLang)[0] || undefined
}
/**
 * 获取匹配的第一条指定语言的数据
 * @deprecated 请使用 packages/shared/src/utils/lang.ts:getSpecialLang
 * @param names 有name的字段的数组
 * @param lang 当前语言
 * @param defaultLang 默认语言
 * @returns
 */
export function pickFirstSpecialLangTitle<T extends { title: string; lang: ILang }>(names: T[], lang: ILang, defaultLang: ILang = 'EN') {
  return pickSpecialLangTitle(names, lang, defaultLang)[0] || undefined
}
export function pickDisplayNameByLocale<
  T extends {
    display_name_en: string
    display_name_cn?: string
    short_name_en?: string[]
    short_name_cn?: string[]
    entity_name_cn?: string
    entity_name_en?: string
  }
>(data: T, lang: I18nLang, useShortName = false, useEntityName = false) {
  let name = ''

  if (useEntityName) {
    name = data[`entity_name_${lang}`] || ''
  }

  if (useShortName) {
    const shortNames = data[`short_name_${lang}`]
    if (shortNames?.length) {
      name = shortNames[0] || data.short_name_en?.[0] || ''
    }
  }

  if (name) return name

  return lang === 'cn' ? data.display_name_cn || data.display_name_en : data.display_name_en || data.display_name_cn || ''
}

export function getDictItemName<T extends IBaseDictItem>(data: T, locale: ILang = 'EN') {
  const { name_cn, name_en } = data

  return locale === 'CN' ? name_cn || name_en : name_en
}

export function removeHTML(html?: string): string {
  if (!html) {
    return ''
  }
  const reg = /(<([^>]+)>)/gi
  return html.replace(reg, '')
}

export function alphabetSort(a: string, b: string): number {
  return a.localeCompare(b)
}

export function isInactivePhase(enNameOrKey: string) {
  // 这里是兼容逻辑,之前INACTIVE_DEV_STATUS_IDS数组中包含 '' 和 '-1' 两个元素,现在已经去掉了
  if (enNameOrKey === '' || enNameOrKey === '-1') {
    return true
  }
  return INACTIVE_DEV_STATUS_IDS.includes(enNameOrKey) || INACTIVE_PHASE_EN_NAMES.includes(enNameOrKey)
}

export function getPhaseColor(enNameOrKey: string) {
  return ALL_DEV_STATUS_COLOR_MAP[enNameOrKey] || PHASE_DEFAULT_COLOR
}

export const getClinicalPieColor = (key: string) => {
  return CLINICAL_MULTI_CENTER_COLOR_MAP[key]
}

const DEVELOPED_COUNTRY = ['US', 'GB', 'DE', 'FR', 'JP', 'CA']

/**
 * 对国家排序，先按发展程度排序 US > GB > DE，不是发展国家的，按照A-Z排序
 */
export function sortCountry(a: string, b: string) {
  const foundIndexA = DEVELOPED_COUNTRY.findIndex((i) => i === a)
  const foundIndexB = DEVELOPED_COUNTRY.findIndex((i) => i === b)
  if (foundIndexA !== -1 && foundIndexB === -1) {
    return -1
  }
  if (foundIndexA === -1 && foundIndexB !== -1) {
    return 1
  }
  if (foundIndexA !== -1 && foundIndexB !== -1) {
    return foundIndexA - foundIndexB
  }

  return alphabetSort(a, b)
}

const CHART_COUNTRY_SORT = [
  {
    displayName: 'United States',
    code: 'US',
  },
  {
    displayName: 'China',
    code: 'CN',
  },
  {
    displayName: 'United Kingdom',
    code: 'GB',
  },
  {
    displayName: 'France',
    code: 'FR',
  },
  {
    displayName: 'Germany',
    code: 'DE',
  },
  {
    displayName: 'Italy',
    code: 'IT',
  },
  {
    displayName: 'Japan',
    code: 'JP',
  },
  {
    displayName: 'Canada',
    code: 'CA',
  },
  {
    displayName: 'Russia',
    code: 'RU',
  },
  {
    displayName: 'Australia',
    code: 'AU',
  },
]

/**
 * 传英文全称      // US>China>UK>France>German>Italy>Japan>Canada>Russia>Australia>剩余A-Z排序
 */
export function sortCountryV2(a: string, b: string) {
  const foundIndexA = CHART_COUNTRY_SORT.findIndex((i) => i.code === a || i.displayName === a)
  const foundIndexB = CHART_COUNTRY_SORT.findIndex((i) => i.code === b || i.displayName === b)
  if (foundIndexA !== -1 && foundIndexB === -1) {
    return -1
  }
  if (foundIndexA === -1 && foundIndexB !== -1) {
    return 1
  }
  if (foundIndexA !== -1 && foundIndexB !== -1) {
    return foundIndexA - foundIndexB
  }

  return alphabetSort(a, b)
}

export function getInternetExplorerVersion() {
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return -1
}

export function getBrowserInfo(): {
  /**
   * 是否是现代浏览器
   */
  isModern: boolean
  /**
   * 浏览器版本过低
   */
  browserToLow?: boolean
  /**
   * 是否是safari浏览器
   */
  isSafari?: boolean
} {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const chromeMatch = userAgent.match(/chrome\/([\d.]+)/)
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
  if (userAgent.match(/edge\/([\d.]+)/)) {
    return {
      isModern: true,
    }
  } else if (userAgent.match(/firefox\/([\d.]+)/)) {
    return {
      isModern: true,
    }
  } else if (isSafari) {
    const safariVersion = userAgent.match(/version\/(\d+\.\d+)/)
    const version = safariVersion ? parseFloat(safariVersion[1]) : 0
    if (version < 17) {
      return {
        isModern: false,
        browserToLow: true,
        isSafari: true,
      }
    }
    return {
      isModern: true,
    }
  } else if (chromeMatch) {
    const chrome = parseFloat(chromeMatch[1])
    if (chrome < 80) {
      return {
        isModern: false,
        browserToLow: true,
      }
    } else {
      return {
        isModern: true,
      }
    }
  }
  return {
    isModern: false,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function replaceSameNameByIncreaseCount<T extends object>(
  list: T[],
  config: { getName: (item: T) => string; updateVal: (item: T, name: string) => void }
) {
  const objList = list.slice()
  const listTemp = objList.map((i, index) => ({ ...i, __tempIndex: index }))
  const sortedListByName = [...listTemp].sort((a, b) => alphabetSort(config.getName(a) || '', config.getName(b) || ''))
  const sameList: T[] = []

  sortedListByName.forEach((item, index) => {
    if (sortedListByName[index + 1] && config.getName(sortedListByName[index + 1]) === config.getName(item)) {
      sameList.push(sortedListByName[index + 1])
    }
  })

  if (sameList.length > 0) {
    const grouped = groupBy(sameList, (i) => config.getName(i))
    const keys = Object.keys(grouped)
    keys.forEach((key) => {
      const val = grouped[key]
      val.forEach((item, index) => {
        config.updateVal(item, `${config.getName(item)}(${index + 1})`)
      })
    })
  }

  return sortedListByName
    .sort((a, b) => a.__tempIndex - b.__tempIndex)
    .map((i) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete i.__tempIndex
      return i
    }) as unknown as T[]
}

export function transformQueryPutAnyToMust(searchParams: IHistorySearchParams): IHistorySearchParams {
  const {
    query: { any, must, ...restQuery },
    ...restParams
  } = searchParams

  return {
    ...restParams,
    query: {
      must: any
        ? must?.concat({
            type: 'group',
            any,
          })
        : must,
      ...restQuery,
    },
    hidden_flag: true,
  }
}

export function transformTwoDimTupleItems2QueryItemField<T>(tuples: IUseChartTwoDimTupleItem<T>[]) {
  const yDimItem = tuples[0][0]
  const xDimItems = tuples.map((i) => i[1])
  const yQueryItemFiled: IQueryItemField = {
    type: 'field',
    fields: [yDimItem.field],
    value: [
      {
        display_name_cn: yDimItem.display_name_cn || '',
        display_name_en: yDimItem.display_name_en || '',
        type: 'text',
        value: yDimItem.id,
      },
    ],
  }
  let xQueryItemFiled: IQueryItemField = {
    type: 'field',
    fields: [xDimItems[0].field],
    value: xDimItems.map((i) => ({
      display_name_cn: i.display_name_cn || '',
      display_name_en: i.display_name_en || '',
      type: 'text',
      value: i.id,
    })),
  }

  if (xQueryItemFiled.fields && xQueryItemFiled.fields[0]) {
    const field = xQueryItemFiled.fields[0]
    if (field === 'START_DATE_YEAR') {
      const val = xQueryItemFiled.value[0] as IQueryValueText
      const { from, to } = getOneYearRangeByYear(val.value)
      xQueryItemFiled = {
        type: 'field',
        fields: ['START_DATE'],
        value: convertTimestamp2RangeQueryItem([from, to], undefined, true),
      }
    }
    if (field === 'dmp_post_time') {
      const val = xQueryItemFiled.value[0] as IQueryValueText
      const isUTC = !!xDimItems[0].key || false
      const { from, to } = getOneYearRangeByYear(val.value, isUTC)
      xQueryItemFiled = {
        type: 'field',
        fields: ['dmp_post_time'],
        value: convertTimestamp2RangeQueryItem([from, to], undefined, isUTC),
      }
    }
    if (field === 'APD_YEAR') {
      const val = xQueryItemFiled.value[0] as IQueryValueText
      xQueryItemFiled = {
        type: 'field',
        fields: ['APD_YEAR'],
        value: [
          {
            type: 'text',
            display_name_cn: val.value,
            display_name_en: val.value,
            value: val.value,
          },
        ],
      }
    }

    if (field === 'PBDT_YEAR') {
      const val = xQueryItemFiled.value[0] as IQueryValueText
      xQueryItemFiled = {
        type: 'field',
        fields: ['PBDT_YEAR'],
        value: [
          {
            type: 'text',
            display_name_cn: val.value,
            display_name_en: val.value,
            value: val.value,
          },
        ],
      }
    }

    // 文献
    if (field === 'YEAR') {
      const val = xQueryItemFiled.value[0] as IQueryValueText
      xQueryItemFiled = {
        type: 'field',
        fields: ['YEAR'],
        value: [
          {
            type: 'text',
            display_name_cn: val.value,
            display_name_en: val.value,
            value: val.value,
          },
        ],
      }
    }
  }

  return {
    yAxisQuery: yQueryItemFiled,
    xAxisQuery: xQueryItemFiled,
  }
}

export function transformHeatmapData(params: any) {
  const seriesName = params.name
  const xAxisData: ITarget2QueryParam[] = []
  let yAxisData: ITarget2QueryParam = { field: '', id: '' }

  const data = params.data.value?.[params.data.value.length - 1] as {
    display_name_cn: string
    display_name_en: string
    field: string
    id: string
    otherInfo?: { related_drug_id: string[] }
  }[][]
  if (seriesName === 'Inactive' || seriesName === '非在研') {
    const inActiveData = data?.filter((o) => isInactivePhase(o[1].id)) ?? []
    inActiveData.forEach((inActive) => {
      if (inActive[1]) {
        xAxisData.push({
          display_name_cn: inActive[1].display_name_cn ?? '',
          display_name_en: inActive[1].display_name_en ?? '',
          field: inActive[1].field,
          id: inActive[1].id,
        })
      }
    })
    const target = inActiveData[0][0]
    if (target) {
      yAxisData = { ...target }
    }
  } else {
    const activeData = (data ?? []).find((o) => o[1].display_name_en === seriesName || o[1].display_name_cn === seriesName)
    if (activeData) {
      xAxisData.push({
        display_name_cn: activeData[1].display_name_cn ?? '',
        display_name_en: activeData[1].display_name_en ?? '',
        field: activeData[1].field,
        id: activeData[1].id,
      })
      yAxisData = activeData[0] ? { ...activeData[0] } : ({} as ITarget2QueryParam)
    }
  }

  if (xAxisData.length && yAxisData.id) {
    const yAxisQuery: IQueryItemField = {
      type: 'field',
      fields: [yAxisData.field],
      value: [
        {
          display_name_cn: yAxisData?.display_name_cn ?? '',
          display_name_en: yAxisData?.display_name_en ?? '',
          type: 'text',
          value: yAxisData.id,
        },
      ],
    }

    const xAxisQuery = {
      type: 'field',
      fields: [xAxisData[0].field],
      value: xAxisData.map((phase) => {
        return {
          display_name_cn: phase?.display_name_cn ?? '',
          display_name_en: phase?.display_name_en ?? '',
          type: 'text',
          value: phase.id,
        }
      }),
    } as IQueryItemField

    return {
      xAxisQuery,
      yAxisQuery,
    }
  }
  return {}
}

interface IEChartParams {
  seriesName: string
  data: {
    data?: { display_name_cn: string; display_name_en: string; field: string; id: string; otherInfo?: { related_drug_id: string[] } }[][]
  }
}

export function transformPhaseHorBarData(params: IEChartParams) {
  const seriesName = params.seriesName
  const xAxisData: ITarget2QueryParam[] = []
  let yAxisData: ITarget2QueryParam = { field: '', id: '' }
  if (seriesName === 'Inactive' || seriesName === '非在研') {
    const inActiveData = params.data.data?.filter((o) => isInactivePhase(o[1].id)) ?? []
    inActiveData.forEach((inActive) => {
      if (inActive[1]) {
        xAxisData.push({
          display_name_cn: inActive[1].display_name_cn ?? '',
          display_name_en: inActive[1].display_name_en ?? '',
          field: inActive[1].field,
          id: inActive[1].id,
        })
      }
    })
    yAxisData = inActiveData[0][0] ? { ...inActiveData[0][0] } : ({} as ITarget2QueryParam)
  } else {
    const activeData = (params.data.data ?? []).find((o) => o[1].display_name_en === seriesName || o[1].display_name_cn === seriesName)
    if (activeData) {
      xAxisData.push({
        display_name_cn: activeData[1].display_name_cn ?? '',
        display_name_en: activeData[1].display_name_en ?? '',
        field: activeData[1].field,
        id: activeData[1].id,
      })
      yAxisData = activeData[0] ? { ...activeData[0] } : ({} as ITarget2QueryParam)
    }
  }

  if (xAxisData.length && yAxisData.id) {
    const yAxisQuery: IQueryItemField = {
      type: 'field',
      fields: [yAxisData.field],
      value: [
        {
          display_name_cn: yAxisData?.display_name_cn ?? '',
          display_name_en: yAxisData?.display_name_en ?? '',
          type: 'text',
          value: yAxisData.id,
        },
      ],
    }
    const xAxisQuery: IQueryItemField = {
      type: 'field',
      fields: [xAxisData[0].field],
      value: xAxisData.map((phase) => {
        return {
          display_name_cn: phase?.display_name_cn ?? '',
          display_name_en: phase?.display_name_en ?? '',
          type: 'text',
          value: phase.id,
        }
      }),
    }

    return {
      xAxisQuery,
      yAxisQuery,
    }
  }
  return {}
}

// trial 是为了兼容竞争格局部分的使用场景
export function getLast30daysIncreasedTip(name: IQueryDataListType | 'trial', locale: I18nLang, count: number) {
  const wrapCount = (count: number) => `<span style="color: #1976D2;">${count}</span>`

  if (name === 'drug') {
    if (locale === 'en') {
      return `${wrapCount(count)} ${count === 1 ? 'drug' : 'drugs'} increase in last 30 days`
    } else if (locale === 'cn') {
      return `最近30天增长${wrapCount(count)}个药物`
    }
  }

  if (name === 'trial' || name === 'clinical_trial') {
    if (locale === 'en') {
      return `${wrapCount(count)} ${count === 1 ? 'Clinical Trial' : 'Clinical Trials'} increase in last 30 days`
    } else if (locale === 'cn') {
      return `最近30天增长${wrapCount(count)}篇临床`
    }
  }

  if (name === 'news') {
    if (locale === 'en') {
      return `${wrapCount(count)} News increase in last 30 days`
    } else if (locale === 'cn') {
      return `最近30天增长${wrapCount(count)}篇新闻`
    }
  }

  if (name === 'patent') {
    if (locale === 'en') {
      return `${wrapCount(count)} ${count === 1 ? 'Patent' : 'Patents'} increase in last 30 days`
    } else if (locale === 'cn') {
      return `最近30天增长${wrapCount(count)}篇专利`
    }
  }
}

export const socialMedialOrder = Object.values(E_SOCIAL_MEDIA_TYPE)

export function getOrderedSocialMedial(socialMedialList?: IBaseSocialMediaInfo[], order = socialMedialOrder) {
  if (socialMedialList) {
    return sortBy(socialMedialList, (socialMedial) => findIndex(order, (val) => socialMedial.type === val))
  }
}

export function getSocialMedialIcon(socialMediaType: E_SOCIAL_MEDIA_TYPE) {
  return socialMedialIconMap[socialMediaType]
}

export function replaceBlank(str: string) {
  return str.replace(/\s/g, '')
}

/**
 * 对中英文混合列表进行排序, 中文在前，英文在后，英文按照a-z
 * @param a 名称a
 * @param b 名称b
 */
export function cnFrontAlphabetSort(a: string, b: string) {
  // 使用正则表达式检查字符串中是否包含中文字符
  const chinesePattern = /[\u4e00-\u9fa5]/

  // 使用localeCompare方法比较两个字符串
  if (chinesePattern.test(a) && !chinesePattern.test(b)) {
    // 如果a包含中文字符而b不包含，将a排在前面
    return -1
  } else if (!chinesePattern.test(a) && chinesePattern.test(b)) {
    // 如果b包含中文字符而a不包含，将b排在前面
    return 1
  } else {
    // 否则，使用默认的localeCompare比较方法排序（中文排在前面）
    return a.localeCompare(b)
  }
}
/**
 * 对中英文混合列表进行排序, 中文在前，英文在后，英文按照a-z
 * @param a 名称a
 * @param b 名称b
 */
export function enFrontAlphabetSort(a: string, b: string) {
  // 使用正则表达式检查字符串中是否包含中文字符
  const chinesePattern = /[\u4e00-\u9fa5]/

  // 使用localeCompare方法比较两个字符串
  if (chinesePattern.test(a) && !chinesePattern.test(b)) {
    // 如果a包含中文字符而b不包含，将a排在前面
    return 1
  } else if (!chinesePattern.test(a) && chinesePattern.test(b)) {
    // 如果b包含中文字符而a不包含，将b排在前面
    return -1
  } else {
    // 否则，使用默认的localeCompare比较方法排序（英文排在前面）
    return a.localeCompare(b)
  }
}
export function getSortFnByLang(lang: ILang) {
  return lang === 'CN' ? cnFrontAlphabetSort : enFrontAlphabetSort
}
export function getDisplaySource<T extends { source?: string; source_url_host?: string }>(params: T, locale: I18nLang) {
  const { source, source_url_host } = params
  return locale === 'cn' ? source || source_url_host : source_url_host
}

export function sortFunc<T>(params: { data: T[]; map: Record<string, number>; getKey: (d: T) => string; sort: 'ab' | 'ba' }) {
  const { data, map, getKey, sort } = params
  const cloneData = data.concat()
  const noWeightData: T[] = []
  const weightData: T[] = []
  cloneData.forEach((item) => {
    if (map[getKey(item)] === undefined) {
      noWeightData.push(item)
    } else {
      weightData.push(item)
    }
  })
  weightData.sort((a, b) => {
    const aWeight = map[getKey(a)]
    const bWeight = map[getKey(b)]
    return sort === 'ab' ? aWeight - bWeight : bWeight - aWeight
  })
  return [...weightData, ...noWeightData]
}

export class SearchParamBuilder {
  limit
  hidden
  query: IQuery = { type: 'group', filter: [], must: [] }
  constructor(limit?: number, hidden?: boolean) {
    this.limit = limit
    this.hidden = hidden
  }
  pushFilter(
    field: string,
    value: string | string[],
    display_name_cn = '',
    display_name_en = '',
    fieldHidden = false,
    condition = E_QUERY_ITEM_CONDITION.ANY
  ) {
    this.query.filter &&
      this.query.filter.push({
        type: 'field',
        fields: [field],
        condition,
        ...(fieldHidden
          ? {
              hidden: true,
            }
          : undefined),
        value: Array.isArray(value)
          ? map(value, (item) => ({
              type: 'text',
              value: item,
              display_name_cn,
              display_name_en,
            }))
          : [
              {
                type: 'text',
                value: value,
                display_name_cn,
                display_name_en,
              },
            ],
      })
    return this
  }
  pushMust(field: string, value: string, display_name_cn = '', display_name_en = '', fieldHidden = false) {
    this.query.must &&
      this.query.must.push({
        type: 'field',
        fields: [field],
        ...(fieldHidden
          ? {
              hidden: true,
            }
          : undefined),
        value: [
          {
            type: 'text',
            value: value,
            display_name_cn,
            display_name_en,
          },
        ],
      })
    return this
  }
  build(): ISearchParams | IHistorySearchParams {
    return {
      data_type: 'news',
      query: this.query,
      sort: [
        {
          sort_field: 'dmp_post_time',
          sort_order: E_SORT_ORDER.DESC,
        },
      ],
      limit: this.limit,
      hidden_flag: this.hidden,
    }
  }
}

export function downloadBlob(blob: Blob, fileName: string) {
  const aLink = document.createElement('a')
  if (typeof blob === 'object' && blob instanceof Blob) {
    aLink.href = URL.createObjectURL(blob) // 创建blob地址
  }
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || ''
  let event
  if (window.MouseEvent) {
    event = new MouseEvent('click')
  } else {
    // 移动端
    event = document.createEvent('MouseEvents')
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  }
  aLink.dispatchEvent(event)
}

/**
 * 根据后端处理后的entity_name_cn、entity_name_en，更新前端使用的名称字段
 * @param data 机构数据
 * @param cnKey 机构中文名字段
 * @param enKey 机构英文名字段
 */
export function formatOrgName<
  T extends {
    display_name_cn?: string
    display_name_en?: string
    name_en?: string
    name_cn?: string
    entity_name_cn?: string
    entity_name_en?: string
  }
>(data: T[] | T, cnKey: 'display_name_cn' | 'name_cn' = 'display_name_cn', enKey: 'display_name_en' | 'name_en' = 'display_name_en') {
  if (isArray(data)) {
    data.forEach((item) => {
      if (item.entity_name_cn) item[cnKey] = item.entity_name_cn
      if (item.entity_name_en) item[enKey] = item.entity_name_en
    })
    return
  }
  if (data.entity_name_cn) data[cnKey] = data.entity_name_cn
  if (data.entity_name_en) data[enKey] = data.entity_name_en
}

export function transformData(params: any) {
  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((item: any) => {
        formData.append(key, item)
      })
    } else formData.append(key, params[key])
  })
  return formData
}

export function toBooleanPromise<T>(p: PromiseLike<T>) {
  return p.then(
    () => true,
    () => false
  )
}

export function getObjectKeys<T>(map: T) {
  return Object.keys(map) as (keyof T)[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ExcludeFunction<T> = Exclude<T, Function>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueByConsumeFunc<T, K extends Array<any> = any[]>(value: T, params?: K): ExcludeFunction<T> {
  if (typeof value === 'function') {
    return value(...(params || []))
  }

  return value as ExcludeFunction<T>
}

export const NO_TIP_KEY = 'NO_TIP_KEY'

export function getRedirectLinkStatic(url: string, locale: I18nLang) {
  if (localStorage.getItem(NO_TIP_KEY) === 'true') return url
  else return `/link-${locale}.html?target=${encodeURIComponent(url)}`
}

export function getValueByRegion<T>(cnValue: T, usValue: T) {
  return IS_CN_REGION ? cnValue : usValue
}

/**
 * 专利信息来源的展示
 */
export const informationSourceStyleMap: Record<
  string,
  {
    className: string
  }
> = {
  d18af66d018b35a19d3ce5078f863e2c: {
    className: 'orangeBook',
  },
  fe94e7420133347c99a701b933dfb5cd: {
    className: 'purpleBook',
  },
  f8e054e3416d372e874492e25c38b3ec: {
    className: 'cde',
  },
  '0608bb50f1183d09b5643165e0d750ab': {
    className: 'medsPal',
  },
  '0b545d8e53633446bb7ae59c3753b688': {
    className: 'vaxPal',
  },
  '38ff6277d5243f41abde0f5fec4284f2': {
    className: 'cde',
  },
}

export function mergeViewAndIds<T>(views: T[] = [], ids: string[] = []) {
  return [...views, slice(ids, views.length)].flat()
}

export function checkExist(items: IBAcTag[], item: IListItem, isCN: boolean) {
  return items.find((tag) => {
    if (item.id) {
      return tag.id === item.id
    } else {
      if (tag.id) return false
      return isCN ? tag.name_cn === item.name_cn : tag.name_en === item.name_en
    }
  })
}

/**
 * 从源过滤掉指定的tag
 * @param originalTags
 * @param removeItem
 * @param isCN
 * @returns
 */
export function getRemoveResult(originalTags: IBAcTag[], removeItem: IBAcTag, isCN: boolean) {
  if (removeItem.id) {
    return originalTags.filter((o) => o.id !== removeItem.id)
  } else {
    return originalTags.filter((o) => o.id || (isCN ? o.name_cn !== removeItem.name_cn : o.name_en !== removeItem.name_en))
  }
}

export const getKeys = Object.keys as <T extends Record<string, any>>(obj: T) => Array<keyof T>
export const getCellStyle = (c = 1) => {
  return ({ columnIndex }: { columnIndex: number }) => {
    if (columnIndex === c) {
      return {
        'vertical-align': 'top',
        background: `linear-gradient(to right,
          #EDF4FCB2 0%,
          #EDF4FCB2 11.1111%,
          white 11.1111%,
          white 22.2222%,
          #EDF4FCB2 22.2222%,
          #EDF4FCB2 33.3333%,
          white 33.3333%,
          white 44.4444%,
          #EDF4FCB2 44.4444%,
          #EDF4FCB2 55.5555%,
          white 55.5555%,
          white 66.6666%,
          #EDF4FCB2 66.6666%,
          #EDF4FCB2 77.7777%,
          white 77.7777%,
          white 88.8888%,
          #EDF4FCB2 88.8888%,
          #EDF4FCB2 100%)`,
      }
    } else {
      return { 'vertical-align': 'top', background: 'white' }
    }
  }
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
