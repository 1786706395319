import { IQueryDataType } from '@patsnap/synapse_common_interface'
type TabType = 'list' | 'table'
type TabSetting = Partial<Record<IQueryDataType, TabType>>
export class CommonListTabSetting {
  supportedDataTypes: IQueryDataType[] = ['clinical_trial', 'translational_medicine', 'patent']
  defaultSetting: TabSetting = {
    clinical_trial: 'list',
    translational_medicine: 'list',
    patent: 'list',
  }
  localStorageKey = 'common_list_setting'

  get setting() {
    return this.localStorageSetting || this.defaultSetting
  }

  checkIsSupportDataType(dataType: IQueryDataType) {
    return this.supportedDataTypes.includes(dataType)
  }

  getTabType(dataType: IQueryDataType) {
    if (!this.supportedDataTypes.includes(dataType)) {
      return undefined
    }
    return this.setting[dataType]
  }

  setTabType(dataType: IQueryDataType, tabType: TabType) {
    // 限制只能设置支持的数据类型
    if (!this.supportedDataTypes.includes(dataType)) {
      return
    }
    // 限制只能设置支持的tab类型
    if (!['list', 'table'].includes(tabType)) {
      return
    }
    const setting = this.setting
    setting[dataType] = tabType
    localStorage.setItem(this.localStorageKey, JSON.stringify(setting))
  }

  get localStorageSetting() {
    const data = localStorage.getItem(this.localStorageKey)
    if (!data) {
      return null
    }
    try {
      const jsonData = JSON.parse(data) as TabSetting
      return jsonData
    } catch (error) {
      return null
    }
  }

  static ins: CommonListTabSetting
  static getSingleton() {
    return this.ins || (this.ins = new this())
  }
}
