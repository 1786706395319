import {
  E_QUERY_ITEM_CONDITION,
  E_SORT_ORDER,
  IAggregationRangeItem,
  IAggregationValue,
  IDrugDealIndexField,
  IQueryItem,
  ISort,
} from '@patsnap/synapse_common_interface'
import { sortBy } from 'lodash'
import { CustomNumberRageConfig } from './business/autocomplete'

/**
 * 药品交易的机构组装query特殊处理,机构作为转让方或者受让方
 * @param data 机构
 * @returns 交易的机构must
 */
export function getDrugDealOrganizationMust<T extends { id: string; display_name_cn: string; display_name_en: string }>(data: T | T[]): IQueryItem[] {
  const value = Array.isArray(data) ? data : [data]
  return [
    {
      type: 'field',
      fields: ['PRINCIPLE_ORG_ID', 'PARTNER_ORG_ID'],
      condition: E_QUERY_ITEM_CONDITION.ANY,
      value: value.map((item) => ({
        type: 'organization',
        ...item,
        id: item.id,
        display_name_cn: item.display_name_cn,
        display_name_en: item.display_name_en,
        search_strategy: 'ID_ROLLUP',
      })),
    },
  ]
}

/**
 *
 * @returns 药物交易列表默认排序
 */
export function getDrugDealListDefaultSort(): ISort[] {
  return [
    {
      sort_field: 'DEAL_TIME',
      sort_order: E_SORT_ORDER.DESC,
    },
    {
      sort_field: 'TOTAL_VALUE',
      sort_order: E_SORT_ORDER.DESC,
    },
    {
      sort_field: 'NORMALIZED_PHASE_SIGN_RANK_MAX',
      sort_order: E_SORT_ORDER.DESC,
    },
  ]
}

function getAggRangeList(list: { from: number; to?: number; name_en: string; name_cn: string }[]): IAggregationRangeItem[] {
  return list.map((item) => ({
    from: item.from,
    to: item.to,
    key: item.name_en,
  }))
}

export type IDrugDealPaymentIndexField = Extract<IDrugDealIndexField, 'TOTAL_VALUE' | 'UPFRONT_PAYMENT' | 'MILESTONE_PAYMENT'>

export function getDrugDealPaymentAggregationRange(aggregationField: IDrugDealPaymentIndexField): IAggregationRangeItem[] {
  const config = {
    TOTAL_VALUE: getAggRangeList(CustomNumberRageConfig.drugDealAmountItems),
    UPFRONT_PAYMENT: getAggRangeList(CustomNumberRageConfig.drugDealAmountItems),
    MILESTONE_PAYMENT: getAggRangeList(CustomNumberRageConfig.drugDealAmountItems),
  }
  return config[aggregationField]
}

export function sortBooleanData(data: IAggregationValue[]): IAggregationValue[] {
  return sortBy(data, (item) => ['true', 'false'].findIndex((key) => item.key === key))
}
