import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { useLs360SynapseWujieProps } from '@pharmsnap/pharmsnap-web/composable/useLs360SynapseWujieProps'
import { CHAT_DIALOG_ID } from '@pharmsnap/shared/src/utils/business/ls360'
import { defineComponent, onMounted } from '@vue/composition-api'
export const LS360SideBarDialog = defineComponent({
  name: 'LS360SideBarDialog',
  props: {
    isAbsolutePosition: {
      default: false,
      type: Boolean,
    },
    integratedMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { props: wujieProps, attrs: wujieAttrs, entryUrl: wujieEntryUrl } = useLs360SynapseWujieProps()
    const {
      state: { renderDialog, dialogVisible },
      actions: { setWujieMounted },
    } = useLs360ChatStore()
    const wujieUrl = `${wujieEntryUrl}#/chat`
    onMounted(() => wujieProps.bizHandler.getRandomQuestion())
    return {
      renderDialog,
      dialogVisible,
      wujieUrl,
      wujieProps,
      wujieAttrs,
      setWujieMounted,
    }
  },
  render() {
    if (!this.renderDialog) return <div></div>
    return (
      <div
        id={CHAT_DIALOG_ID}
        class={['h-full', this.isAbsolutePosition ? 'absolute right-0 bottom-0 z-[11] top-0' : 'relative z-[4]']}
        v-show={this.dialogVisible}
        style={[
          {
            width: '434px',
            background: 'white',
            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.08)',
          },
        ]}
      >
        <wujie-vue
          alive
          afterMount={this.setWujieMounted}
          width="100%"
          height="100%"
          attrs={this.wujieAttrs}
          name="LS360SiderBarDialog"
          url={this.wujieUrl}
          {...{ props: { attrs: this.wujieAttrs, props: this.wujieProps } }}
        ></wujie-vue>
      </div>
    )
  },
})
