import '@patsnap-ui/icon/assets/solid/DropdownClose.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import { BAcBase, BAcTag, GIcon, GTooltip } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { ElCheckbox, ElPopover } from '@pharmsnap/shared/element-ui'
import { IBAcTag, IExampleTag, IListItem, IPatentOptionLabel } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import $classes from './BOptionLabel.module.scss'

export const BOptionLabel = defineComponent({
  name: 'BOptionLabel',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    options: {
      required: true,
      type: Array as PropType<IPatentOptionLabel[]>,
    },
    selectedOption: {
      required: true,
      type: String,
    },
    tags: {
      required: true,
      type: Array as PropType<IBAcTag[]>,
    },
    /**
     * 机翻switch
     */
    showSwitch: {
      type: Boolean,
      default: true,
    },
    /**
     * 机翻switch val
     */
    switchVal: {
      type: Boolean,
      default: false,
    },
    /**
     * 机翻的tooltip
     */
    switchInfo: {
      type: String,
    },
    tagsLimit: {
      type: Number,
      default: 10,
    },
    tagSize: {
      type: String as PropType<'mini' | 'small' | 'medium' | 'default'>,
      default: 'small',
    },
    inputTip: {
      type: String,
    },
    exampleLabel: {
      type: String,
    },
    exampleClass: {
      type: String,
    },
    exampleTag: {
      type: Object as PropType<IExampleTag>,
    },
    popperClass: {
      type: String,
      default: '',
    },
    quickAddExample: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    blurClass: {
      type: String,
      default: '',
    },
    logicVal: {
      type: String as PropType<E_QUERY_ITEM_CONDITION>,
      default: E_QUERY_ITEM_CONDITION.ANY,
    },
    showLogicTag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const { locale } = useLocale()

    function handleKeyDown(e: KeyboardEvent, keyword: string) {
      emit('keyDown', e, keyword)
    }

    function handleKeyPress(e: KeyboardEvent, keyword: string) {
      emit('keyPress', e, keyword)
    }

    function handleClose(val: IBAcTag) {
      emit('close', val)
    }

    function handleMachineTrans() {
      emit('includeTrans')
    }

    function handleChangeLogicVal(val: E_QUERY_ITEM_CONDITION) {
      emit('changeLogicVal', val)
    }

    function clickOutside(keyword: string) {
      emit('clickOutside', keyword)
    }

    function handleAddExample() {
      emit('addExample')
    }

    function toggleShowOptions() {
      showOptions.value = !showOptions.value
    }

    function selectOption(option: IPatentOptionLabel) {
      emit('selectOption', option.val)
      showOptions.value = false
    }

    function revertTag(val: IListItem) {
      emit('revertTag', val)
    }

    const label = computed(() => {
      const config = props.options.find((o) => o.val === props.selectedOption)
      return config ? config[`name_${locale.value}`] : props.placeholder
    })

    const svgName = computed(() => (showOptions.value ? 'SolidDropdownClose' : 'SolidDropdownOpen'))

    const userExampleName = computed(() => (props.exampleTag ? props.exampleTag[`name_${locale.value}`] : ''))

    return {
      locale,
      label,
      showOptions,
      svgName,
      userExampleName,
      handleKeyDown,
      handleKeyPress,
      handleClose,
      clickOutside,
      handleAddExample,
      handleMachineTrans,
      toggleShowOptions,
      selectOption,
      revertTag,
      handleChangeLogicVal,
    }
  },
  methods: {
    renderCheckbox() {
      return (
        <div class={$classes.checkboxContainer}>
          <ElCheckbox value={this.switchVal} onChange={this.handleMachineTrans}>
            {this.$t('common.includeTranslation')}
          </ElCheckbox>
        </div>
      )
    },
    renderSwitch() {
      return this.showSwitch ? (
        <div class={$classes.switchContainer}>
          {this.switchInfo ? (
            <GTooltip theme="light">
              <template slot="content">
                <div domPropsInnerHTML={this.switchInfo}></div>
              </template>
              {this.renderCheckbox()}
            </GTooltip>
          ) : (
            this.renderCheckbox()
          )}
        </div>
      ) : null
    },
    renderExample() {
      return (
        <template slot="example">
          {this.placeHolder ? <div class={[$classes.example, this.exampleClass]}>{this.placeHolder}</div> : null}
          {this.exampleTag ? (
            <div class={[$classes.example, this.exampleClass]}>
              <div>{this.exampleLabel ?? this.$t('common.keywordSearchPlaceHolder')}</div>
              <BAcTag
                name={this.userExampleName}
                ownerClass={this.exampleIsTagStyle ? $classes.exampleTag : $classes.exampleText}
                canClose={false}
                size={this.tagSize}
              ></BAcTag>
            </div>
          ) : null}
        </template>
      )
    },
  },
  render() {
    return (
      <div class={[$classes.labelAcContainer, this.labelContainerClass]} ref="labelAC">
        <div class={$classes.label}>
          <ElPopover placement="bottom" visibleArrow={false} trigger="manual" value={this.showOptions} offset={0} popperClass={$classes.popper}>
            <div slot="reference" class="flex cursor-pointer" onClick={this.toggleShowOptions}>
              <div class={$classes.displayLabel} title={this.label}>
                {this.label}
              </div>
              <GIcon class="text-text-t2 ml-2.5" svgName={this.svgName} size={24}></GIcon>
            </div>
            <div
              {...{
                directives: this.showOptions
                  ? [
                      {
                        name: 'click-outside',
                        value: {
                          handler: this.toggleShowOptions,
                        },
                      },
                    ]
                  : [],
              }}
            >
              {this.options.map((o) => {
                return (
                  <div class={$classes.option} onClick={() => this.selectOption(o)}>
                    {o[`name_${this.locale}`]}
                  </div>
                )
              })}
            </div>
          </ElPopover>
        </div>

        <BAcBase
          clickOutClear={true}
          inputBlurClass={this.blurClass ? `${$classes.inputBlur} ${this.blurClass}` : $classes.inputBlur}
          popperClass={this.popperClass}
          tags={this.tags}
          tagSize={this.tagSize}
          tagsLimit={this.tagsLimit}
          inputTip={this.inputTip}
          showLogicTag={this.showLogicTag}
          logicVal={this.logicVal}
          onKeyDown={(e: KeyboardEvent, keyword: string) => this.handleKeyDown(e, keyword)}
          onKeyPress={(e: KeyboardEvent, keyword: string) => this.handleKeyPress(e, keyword)}
          onClose={(val: IBAcTag) => this.handleClose(val)}
          onClickOutside={(keyword: string) => this.clickOutside(keyword)}
          onRevertTag={this.revertTag}
          onChangeLogicVal={this.handleChangeLogicVal}
        >
          {this.renderExample()}
          {this.quickAddExample ? (
            <template slot="customInputTip">
              <span>{this.inputTip ?? this.$t('common.allFieldInputTip')}</span>
              <span>{this.$t('common.tryExample')}</span>
              <span class={$classes.exampleName} onClick={this.handleAddExample}>
                {this.userExampleName}
              </span>
            </template>
          ) : null}
        </BAcBase>
        {this.renderSwitch()}
      </div>
    )
  },
})
