import { IQueryDataType, ISearchParams } from '@patsnap/synapse_common_interface'
import { defineComponentProps } from '@pharmsnap/shared/src/utils'
import { ComponentInstance, PropType } from '@vue/composition-api'

export interface IWorkspaceServiceParams {
  vm?: ComponentInstance
  selectedIds?: string[]
  searchParams?: ISearchParams
  total?: number
}

export interface IWorkspaceOriginParams {
  // 专利同组类型
  familyExpansion: string
  // 是否为范围选择
  isRange: boolean
  to: number
  from: number
  // 文件夹id
  folderId: string
  counts: number
  // 工作空间id
  workspaceId: string
}

export type ISupportWorkspaceDataType = Extract<IQueryDataType, 'clinical_trial' | 'drug' | 'patent'>

export type IWorkspaceDataType = 'PATENT' | 'DRUG' | 'CLINICAL'

export const workspaceComponentProps = () =>
  defineComponentProps({
    selectedIds: {
      type: Array as PropType<string[]>,
    },
    searchParams: {
      type: Object as PropType<ISearchParams>,
    },
    total: {
      type: Number,
    },
  })
