import { ElPopover } from '@pharmsnap/shared/element-ui'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { computed, defineComponent, nextTick, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GLink } from '../..'
import '../../../assets/icon-svg/fold-less.svg'
import { GTooltip } from '../GTooltip/GTooltip'
import $classes from './GLimited.module.scss'

export const GLimited = defineComponent({
  name: 'GLimited',
  props: {
    totalCount: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String as PropType<'expand' | 'popover' | 'link'>,
      default: 'expand',
    },
    linkTip: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: true,
    },
    popoverInline: {
      type: Boolean,
      default: true,
    },
    popoverStyle: {
      type: String,
      default: '',
    },
    popoverTrigger: {
      type: String as PropType<'click' | 'hover'>,
      default: 'click',
    },
    truncate: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 5,
    },
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      default: () => [],
      required: true,
    },
    placement: {
      type: String as PropType<
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end'
      >,
      default: 'top-end',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    gap: {
      type: Object as PropType<{ x: number; y: number; trigger?: number }>,
      default: () => ({ x: 4, y: 4, trigger: 4 }),
    },
    onChange: {
      type: Function as PropType<() => void>,
    },
  },
  setup(props, ctx) {
    const { items, limit, mode, totalCount } = toRefs(props)
    const showMore = ref<boolean>(false)
    const popoverVisitable = ref<boolean>(false)
    const popoverCotentInited = ref<boolean>(false)
    const isExpandMode = computed(() => mode.value === 'expand')
    const isLinkMode = computed(() => mode.value === 'link')
    const isPopoverMode = computed(() => mode.value === 'popover')
    const total = computed(() => (!totalCount.value ? items.value.length : totalCount.value))
    const shouldRenderRest = computed(() => total.value > limit.value)
    const displayItems = computed(() => (shouldRenderRest.value ? items.value.slice(0, limit.value) : items.value))
    const restItems = computed(() => items.value.slice(0, total.value))
    const resetCount = computed(() => total.value - displayItems.value.length)
    const onTrigger = (e?: Event) => {
      // 弹层这边不要阻止冒泡
      if (!isPopoverMode.value) {
        e?.stopPropagation()
      }
      if (isExpandMode.value) {
        showMore.value = !showMore.value
        ctx.emit('change')
      }
      ctx.emit('clickTrigger')
    }
    /**
     * 保证如果limit里面的内容还有弹窗,slot弹窗在本弹窗只出现后再渲染
     * 因为弹窗的饿了么z-index是统一管理的,先渲染的z-index就会比较低,后渲染的会比较高,
     * 如果不加这段逻辑,slot里面会先渲染,再渲染ElPopover,导致ElPopover还有弹窗会显示在ElPopover下面
     * 但是这个会产生另外一个问题是ElPopover需要更具slot内容判断显示的区域,比如底部区域不够显示slot内容,他会自动现在顶部
     * 由于加了这段逻辑,ElPopover在创建的时候contentVisitable是false,slot是空div,就不知道slot内容的高度,他就永远显示配置的placement的位置
     * 在下次事件循环,contentVisitable设置为true,再渲染slot内容,这个时候就不会再更具内容的宽高重新定位
     * 所以产生了https://patsnap.atlassian.net/browse/PDLS-32326 bug
     * 为了解决这个bug,同时也没有找到slot中海油弹窗的场景,故注释掉这段逻辑
     */
    watch(popoverVisitable, (value) => {
      if (value) {
        nextTick(() => {
          if (popoverVisitable.value) popoverCotentInited.value = true
        })
      } else {
        popoverCotentInited.value = false
      }
    })

    const itemStyle = props.inline
      ? {
          marginRight: `${props.gap.x}px`,
          marginBottom: `${props.gap.y}px`,
        }
      : {
          marginBottom: `${props.gap.y}px`,
        }

    const triggerInlineStyle = props.inline
      ? {
          marginRight: `${props.gap.trigger || props.gap.x}px`,
          marginBottom: `${props.gap.y}px`,
        }
      : {
          marginTop: `${props.gap.trigger || props.gap.y}px`,
          marginBottom: '0px',
        }

    return {
      itemStyle,
      triggerInlineStyle,
      isExpandMode,
      isLinkMode,
      isPopoverMode,
      showMore,
      shouldRenderRest,
      displayItems,
      restItems,
      resetCount,
      popoverVisitable,
      popoverCotentInited,
      onTrigger,
    }
  },
  methods: {
    renderPopover() {
      return (
        <ElPopover
          class={$classes.trigger}
          popperClass="cursor-default"
          closeDelay={100}
          appendToBody={this.appendToBody}
          placement={this.placement}
          trigger={this.popoverTrigger}
          vModel={this.popoverVisitable}
        >
          <template slot="reference">{this.renderShowMoreTrigger()}</template>
          {this.popoverCotentInited ? (
            <div class={$classes.popover} style={this.popoverStyle}>
              {this.renderItems(this.restItems, this.popoverInline, true)}
            </div>
          ) : null}
        </ElPopover>
      )
    },

    renderShowMoreTrigger() {
      const trigger = (
        <span
          data-testid="g-limited__trigger-more"
          class={[this.isPopoverMode ? '' : $classes.trigger, this.isLinkMode ? $classes.triggerLink : '']}
          onClick={this.onTrigger}
        >
          {this.$slots.more ? this.$slots.more : `[+${this.resetCount}]`}
        </span>
      )
      if (this.isLinkMode && this.linkTip) {
        return (
          <GTooltip theme="light">
            <template slot="default">
              <GLink href={this.link} newTab={true} defaultColor="blue">
                {trigger}
              </GLink>
            </template>
            <template slot="content">{this.linkTip}</template>
          </GTooltip>
        )
      }

      return trigger
    },
    renderShowLessTrigger() {
      return (
        <span class={[$classes.trigger, $classes.triggerLess]} onClick={this.onTrigger}>
          {this.$slots.less ? this.$slots.less : <PtIcon icon="FoldLess" style={{ fontSize: '24px', fill: '#495973' }}></PtIcon>}
        </span>
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderItems(items: any[], inline: boolean, isRenderInPopover = false) {
      return items.map((item, index) => {
        return (
          <div
            data-testid="g-limited__item"
            style={{ ...this.itemStyle, marginBottom: index === 0 && items.length === 1 ? '0px' : this.itemStyle.marginBottom }}
            class={[inline ? [$classes.itemInline, 'limitedItem', this.truncate ? $utilClasses.lsTruncate : null] : $classes.itemBlock]}
          >
            {this.$scopedSlots && this.$scopedSlots.default && this.$scopedSlots.default({ item, index, isRenderInPopover, items })}
          </div>
        )
      })
    },
    renderAllContent() {
      return this.renderItems(this.items, this.inline)
    },
    renderMoreContent() {
      const vNodes = this.renderAllContent()
      vNodes.push(
        <div style={this.triggerInlineStyle} class={[this.inline ? $classes.itemInline : $classes.itemBlock]}>
          {this.renderShowLessTrigger()}
        </div>
      )
      return vNodes
    },
    renderLessContent() {
      const vNodes = this.renderItems(this.displayItems, this.inline)
      vNodes.push(
        <div style={this.triggerInlineStyle} class={[this.inline ? $classes.itemInline : $classes.itemBlock]}>
          {this.renderShowMoreTrigger()}
        </div>
      )
      return vNodes
    },
    renderPopoverContent() {
      const vNodes = this.renderItems(this.displayItems, this.inline)
      vNodes.push(
        <div style={this.triggerInlineStyle} class={[this.inline ? [$classes.itemInline, 'limitedItemPopover'] : $classes.itemBlock]}>
          {this.renderPopover()}
        </div>
      )
      return vNodes
    },
  },
  render() {
    let content: JSX.Element[] = []
    if (this.shouldRenderRest) {
      if (this.mode === 'expand') {
        if (this.showMore) content = this.renderMoreContent()
        else content = this.renderLessContent()
      } else if (this.mode === 'popover') {
        content = this.renderPopoverContent()
      } else if (this.mode === 'link') {
        content = this.renderLessContent()
      }
    } else {
      content = this.renderAllContent()
    }

    // drug R&D pipeline 中，国旗不对齐，这边只处理limit === 1
    const shouldTruncate = this.inline && this.limit === 1 && content.length === 2
    if (shouldTruncate) {
      const [itemVNode, triggerVNode] = content
      if (itemVNode.data) {
        if (Array.isArray(itemVNode.data.class)) {
          itemVNode.data.class.push($utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom)
        } else {
          itemVNode.data.class = itemVNode.data.class
            ? [itemVNode.data.class, $utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom]
            : [$utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom]
        }
      }
      if (triggerVNode.data) {
        if (Array.isArray(triggerVNode.data.class)) {
          triggerVNode.data.class.push($utilClasses.lsFlexShrink, $classes.itemInlineNoMarginBottom)
        } else {
          triggerVNode.data.class = triggerVNode.data.class
            ? [triggerVNode.data.class, $utilClasses.lsFlexShrink, $classes.itemInlineNoMarginBottom]
            : [$utilClasses.lsFlexShrink, $classes.itemInlineNoMarginBottom]
        }
      }
    }

    // drug R&D pipeline 中，国旗不对齐，这边只处理limit === 1
    if (content.length === 1 && this.limit === 1) {
      const [itemVNode] = content
      if (itemVNode.data) {
        if (Array.isArray(itemVNode.data.class)) {
          itemVNode.data.class.push($utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom)
        } else {
          itemVNode.data.class = itemVNode.data.class
            ? [itemVNode.data.class, $utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom]
            : [$utilClasses.lsTruncate, $classes.itemInlineNoMarginBottom]
        }
      }
    }

    return (
      <div
        data-testid="g-limited"
        class={[
          $classes.container,
          this.inline,
          // drug R&D pipeline 中，国旗不对齐，这边只处理limit === 1
          this.inline && this.limit === 1 && content.length === 2 ? [$utilClasses.lsFlex, $utilClasses.lsFlexItemsCenter] : '',
        ]}
      >
        {content}
      </div>
    )
  },
})
