import { IDiseaseAutoCompleteItem } from '@patsnap/synapse_common_interface'
import DataLoader from 'dataloader'
import {
  IAutoCompleteParams,
  IBaseDiseaseInfo,
  IDiseaseCardInfo,
  IDiseaseDevStatusItem,
  ISearchResult,
  ISearchTreeParams,
  ISearchTreeResult,
  ITherapeuticAreasTag,
  ITreeAggregationItem,
  ITreeAggregationParams,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DiseaseApi extends BaseApi {
  private batchGetDiseaseLoader = new DataLoader((ids: readonly string[]) => this._getBatchDisease(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getCardInfo(id: string, options?: { drug_id?: string }): Promise<IHttpWrap<IDiseaseCardInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/disease/card/${id}`),
      params: options,
      cache: true,
    })
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IDiseaseAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    return this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/disease/autocomplete'),
      params: { prefix: keywords, limit },
      cache: true,
      cancelToken,
    })
  }
  async getBasicInfo(id: string): Promise<IHttpWrap<IBaseDiseaseInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/disease/${id}`),
      cache: true,
    })
  }
  async _getBatchDisease(ids: readonly string[]): Promise<Array<IBaseDiseaseInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/disease/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseDiseaseInfo>>
    console.log(123, rt)
    if (rt.success && rt.data) {
      return ids.map((id) => rt.data.items?.find((item) => item.disease_id === id))
    }
    return []
  }
  async getBatchDisease(ids: readonly string[]): Promise<IHttpWrap<IBaseDiseaseInfo[]>> {
    const rt = await this.batchGetDiseaseLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBaseDiseaseInfo[],
      xCorrelationId: '',
    }
  }

  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/disease/tree/search`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  /**
   * 获取顶层治疗领域数据
   * @returns
   */
  async getTherapeuticAreasTags(): Promise<IHttpWrap<ITherapeuticAreasTag[]>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/disease/therapeutic_areas`),
      method: 'POST',
      cache: true,
    })
  }

  async getAdvanceTreeAggregation(params: ITreeAggregationParams): Promise<IHttpWrap<ISearchResult<ITreeAggregationItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/disease/aggregation/tree'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getAdvanceTreeByIds(ids: string[]): Promise<IHttpWrap<ISearchResult<ITreeAggregationItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/disease/tree'),
      data: ids,
      method: 'POST',
      cache: true,
    })
  }

  async getDiseaseDevStatus(params: { drug_id: string; disease_ids: string[] }): Promise<IHttpWrap<ISearchResult<IDiseaseDevStatusItem>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/2.0/drug/disease/dev_status/highest_phase'),
      data: params,
      cache: true,
    })
  }

  async getDiseaseCardData({ id }: { id: string }) {
    return this.getCardInfo(id)
  }
}
