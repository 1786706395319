import {
  IAggregationValue,
  IQuery,
  IQueryItem,
  IQueryItemField,
  IQueryValue,
  IQueryValueCommonEntity,
  IQueryValueDrugType,
  IQueryValueEntity,
  IQueryValueRange,
  IQueryValueText,
} from '@patsnap/synapse_common_interface'
import { FilterByValueType, ITreeAggregationItem } from '@pharmsnap/shared/src/types'
import { isFunction, isString, isUndefined, uniq } from 'lodash'
import {
  IFilterAggFieldConfig,
  IFilterAggValueNext,
  IFilterBoolean,
  IFilterItemConfigNext,
  IFilterLogic,
  IFilterText,
  IFilterTextSimple,
  IFilterTreeValueNext,
  IFilterValueGroupNext,
  IFilterValueNext,
  IFilterValueRecord,
} from '../type'

export function isEqualQueryValue(value1: IQueryValue, value2: IQueryValue) {
  const val1Type = value1.type
  const val2Type = value2.type

  if (val1Type !== val2Type) return false

  if (val1Type === 'range' && val2Type === 'range') {
    return value1.key === value2.key
  }

  if (val1Type === 'text' && val2Type === 'text') {
    return value1.value === value2.value
  }

  if (val1Type === 'exist' && val2Type === 'exist') {
    return value1.value === value2.value
  }

  if (val1Type === 'CommonEntity' && val2Type === 'CommonEntity') {
    return value1.id === value2.id
  }

  if (val1Type === 'drugType' && val2Type === 'drugType') {
    return value1.id === value2.id
  }

  if (val1Type === 'disease' && val2Type === 'disease') {
    return value1.id === value2.id
  }

  if (val1Type === 'drug' && val2Type === 'drug') {
    return value1.id === value2.id
  }

  if (val1Type === 'organization' && val2Type === 'organization') {
    return value1.id === value2.id
  }

  if (val1Type === 'target' && val2Type === 'target') {
    return value1.id === value2.id
  }

  return false
}

export function isEqualFilterValue(value1: IFilterValueNext, value2: IFilterValueNext) {
  if (value1.identity !== value2.identity) return false

  if (value1.field !== value2.field) return false

  if (value1.uuid !== value2.uuid) return false

  return isEqualQueryValue(value1, value2)
}

export function isExistInTheFilterValueList(list: IFilterValueNext[], value: IFilterValueNext) {
  return list.some((item) => isEqualFilterValue(item, value))
}

export function getRealText<IDENTITY extends string = string>(text: IFilterText<IDENTITY>, identity: IDENTITY, locale: string) {
  return text ? (isString(text) ? text : isFunction(text) ? text(identity, { locale: locale }) : text[locale]) : ''
}

export function getRealSimpleText(text: IFilterTextSimple, locale: string) {
  return text ? (isString(text) ? text : text[locale]) : ''
}

export function getRealBool<IDENTITY extends string = string>(bol: IFilterBoolean<IDENTITY>, identity: IDENTITY, locale: string, defaultBol = false) {
  return isFunction(bol) ? bol(identity, { locale: locale }) : bol ?? defaultBol
}

export function walkTreeLike<T extends { children?: T[] }>(tree: T[], cb: (item: T, depth: number) => void, depth = 0) {
  for (const item of tree) {
    cb(item, depth)
    if (item.children) {
      walkTreeLike(item.children, cb, depth + 1)
    }
  }
}

export function forkTreeLike<T extends { children?: T[] }, P extends { children?: P[] }>(
  tree: T[],
  cb: (item: T, depth: number) => P | null,
  depth = 0
) {
  return tree
    .map((item) => {
      const newItem = cb(item, depth)

      if (newItem === null) return null

      if (item.children) {
        const children = forkTreeLike(item.children, cb, depth + 1).filter((i) => !!i) as P[]

        if (children.length) {
          newItem.children = children
        }
      }
      return newItem
    })
    .filter((i) => !!i) as P[]
}

export function flattenTreeLike<T extends { children?: T[] }>(tree: T[]) {
  const list: T[] = []

  walkTreeLike(tree, (item) => {
    list.push(item)
  })

  return list
}

export function getDefaultOpenedFilterItems<IDENTITY extends string, AGG_FIELD extends string>(
  layout: Array<IFilterItemConfigNext<IDENTITY, AGG_FIELD>>,
  locale: string
) {
  const defaultOpenFilterItems: IFilterItemConfigNext<IDENTITY, AGG_FIELD>[] = []

  walkTreeLike(layout, (item) => {
    if (getRealBool(isUndefined(item.defaultOpen) ? false : item.defaultOpen, item.identity, locale, false)) {
      defaultOpenFilterItems.push(item)
    }
  })

  return defaultOpenFilterItems
}

export function getFilterValuesFromQueryFieldItems<IDENTITY extends string = string, AGG_FIELD extends string = string>(
  fieldItems: IQueryItemField[],
  fieldIdentityRecord: Record<AGG_FIELD, IDENTITY>
) {
  const filterFields: AGG_FIELD[] = []
  const filterValues: Array<IFilterValueNext<IDENTITY, AGG_FIELD>> = []

  fieldItems.forEach((item) => {
    if (item.type === 'field') {
      filterFields.push(...(item.fields as AGG_FIELD[]))
      const values: Array<IFilterValueNext<IDENTITY, AGG_FIELD>> = item.value?.map((i) => {
        const field = item.fields[0] as AGG_FIELD

        const filterValue: IFilterValueNext<IDENTITY, AGG_FIELD> = {
          ...i,
          field: field,
          identity: fieldIdentityRecord[field],
          uuid: i.type === 'text' ? i.value : i.type === 'exist' ? i.value : i.type === 'range' ? i.key || '' : i.id,
        }

        return filterValue
      })
      filterValues.push(...values)
    }
  })

  return {
    filterFields: uniq(filterFields),
    filterValues,
  }
}

export function getFilterValuesFromFilterGroups<IDENTITY extends string, AGG_FIELD extends string = string>(
  groups: IFilterValueGroupNext<IDENTITY, AGG_FIELD>[]
) {
  const filterFields: AGG_FIELD[] = []
  const filterValues: Array<IFilterValueNext<IDENTITY, AGG_FIELD>> = []

  groups.forEach((group) => {
    filterFields.push(...group.fields)
    filterValues.push(...group.values)
  })

  return {
    filterFields: uniq(filterFields),
    filterValues,
  }
}

export function getFilterValueRecordFromFilterGroups<AGG_FIELD extends string = string>(groups: IFilterValueGroupNext<AGG_FIELD>[]) {
  const record = {} as IFilterValueRecord<AGG_FIELD>

  const { filterValues } = getFilterValuesFromFilterGroups(groups)

  filterValues.forEach((value) => {
    const { field } = value
    if (!record[field]) {
      record[field] = []
    }
    record[field].push(value)
  })

  return record
}

export function getFieldsFromAggFieldConfigItem(item: IFilterAggFieldConfig) {
  if (item.type === 'single') {
    return [item.field]
  }
  if (item.type === 'group') {
    return item.fields
  }
  if (item.type === 'multi') {
    return item.fields.map((item) => item.field)
  }
  if (item.type === 'switch') {
    return item.fields.map((item) => item.field)
  }
  return []
}

export function transQueryItems2FilterValueGroups<IDENTITY extends string = string, AGG_FIELD extends string = string>(
  queryItems: IQueryItem[],
  fieldIdentityRecord: Record<AGG_FIELD, IDENTITY>
) {
  // 只支持 type 是 filed 或者 group 的情况
  const supportQueryItems = queryItems.filter((item) => item.type === 'field' || item.type === 'group') as Array<IQueryItemField | IQuery>

  const groups: IFilterValueGroupNext<IDENTITY, AGG_FIELD>[] = supportQueryItems.map((item) => {
    let filterLogic: IFilterLogic = 'AND'
    let filterFields: AGG_FIELD[] = []
    let filterValues: Array<IFilterValueNext<IDENTITY, AGG_FIELD>> = []
    let querySubType: 'must' | 'any' | undefined

    if (item.type === 'group') {
      const isNot = item.must?.length && item.must.every((item) => item.type === 'field' && item.condition === 'none')

      const isAnd = item.any?.length && item.any.every((item) => item.type === 'field' && item.condition === 'any')

      if (isNot && !isAnd) {
        filterLogic = 'NOT'
        const rt = getFilterValuesFromQueryFieldItems<IDENTITY, AGG_FIELD>(item.must as IQueryItemField[], fieldIdentityRecord)
        filterFields = rt.filterFields
        filterValues = rt.filterValues
        querySubType = 'must'
      }

      if (!isNot && isAnd) {
        filterLogic = 'AND'
        const rt = getFilterValuesFromQueryFieldItems<IDENTITY, AGG_FIELD>(item.any as IQueryItemField[], fieldIdentityRecord)
        filterFields = rt.filterFields
        filterValues = rt.filterValues
        querySubType = 'any'
      }
    } else {
      const condition = item.condition
      if (condition === 'none') {
        filterLogic = 'NOT'
      } else {
        filterLogic = 'AND'
      }
      const rt = getFilterValuesFromQueryFieldItems<IDENTITY, AGG_FIELD>([item], fieldIdentityRecord)
      filterFields = rt.filterFields
      filterValues = rt.filterValues
    }

    const group: IFilterValueGroupNext<IDENTITY, AGG_FIELD> = {
      fields: uniq(filterFields),
      condition: filterLogic,
      values: filterValues,
      label: '',
      _meta: {
        queryItem: item,
        // queryType,
        querySubType,
      },
    }

    return group
  })

  return groups
}

export function transAggValue2FilterAggValue<IDENTITY extends string, AGG_FIELD extends string>(
  aggValue: IAggregationValue,
  identity: IDENTITY,
  field: AGG_FIELD,
  type: IFilterValueNext['type']
): IFilterAggValueNext<IDENTITY, AGG_FIELD> | undefined {
  let filterValueRt: IFilterValueNext<IDENTITY, AGG_FIELD> | undefined
  if (type === 'CommonEntity') {
    const filerValue: IQueryValueCommonEntity & { field: AGG_FIELD } = {
      type: 'CommonEntity',
      id: aggValue.key,
      display_name_cn: aggValue.display_name_cn,
      display_name_en: aggValue.display_name_en,
      field,
    }
    filterValueRt = {
      ...filerValue,
      identity,
      uuid: aggValue.key,
    }
  }
  if (type === 'disease' || type === 'drug' || type === 'target' || type === 'organization' || type === 'drugType' || type === 'text') {
    const filerValue: IQueryValueText & { field: AGG_FIELD } = {
      type: 'text',
      value: aggValue.key,
      display_name_cn: aggValue.display_name_cn,
      display_name_en: aggValue.display_name_en,
      field,
    }
    filterValueRt = {
      ...filerValue,
      identity,
      uuid: aggValue.key,
    }
  }

  if (type === 'range') {
    const filerValue: IQueryValueRange & { field: AGG_FIELD } = {
      type,
      key: aggValue.key,
      from: aggValue.from,
      to: aggValue.to,
      display_name_cn: aggValue.display_name_cn || aggValue.key || aggValue.key_as_string || '',
      display_name_en: aggValue.display_name_en || aggValue.key || aggValue.key_as_string || '',
      field,
    }
    filterValueRt = {
      ...filerValue,
      identity,
      uuid: aggValue.key,
    }
  }

  if (filterValueRt) return { ...filterValueRt, raw: aggValue, count: aggValue.count }
}

export function transAggTree2FilterValueTree<IDENTITY extends string = string, AGG_FIELD extends string = string>(
  tree: ITreeAggregationItem[],
  options: {
    field: AGG_FIELD | ((item: ITreeAggregationItem) => AGG_FIELD)
    identity: IDENTITY
    type: IQueryValueText['type']
    countKey: keyof FilterByValueType<ITreeAggregationItem, number | undefined> | ((item: ITreeAggregationItem) => number | undefined)
    identityKey: keyof FilterByValueType<ITreeAggregationItem, string | undefined> | ((item: ITreeAggregationItem) => string)
    /** 是否使用英文简称，优先级比locale高 */
    useShortName?: boolean
    locale?: string
  }
): IFilterTreeValueNext<IDENTITY, AGG_FIELD, ITreeAggregationItem>[] {
  const { field, identity, countKey, type, locale, identityKey, useShortName = false } = options

  let identityPath: string[] = []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return forkTreeLike(tree, (item, depth) => {
    let filterValueRt: IFilterTreeValueNext<IDENTITY, AGG_FIELD> | undefined = undefined
    let queryValue: IQueryValueEntity | IQueryValueDrugType | IQueryValueText | undefined = undefined

    // 判断 identityKey 类型获取值
    const itemIdentity = isFunction(identityKey) ? identityKey(item) : item[identityKey] || item.id

    const itemCount = isFunction(countKey) ? countKey(item) : item[countKey] || 0

    const itemField = isFunction(field) ? field(item) : field

    if (depth === 0) {
      identityPath = [itemIdentity]
    } else {
      identityPath = [...identityPath, itemIdentity]
    }

    const shortNameEn = item.short_name_en ? item.short_name_en[0] : undefined

    const forceName = isUndefined(locale) ? undefined : useShortName ? shortNameEn || item.name_en : locale === 'en' ? item.name_en : item.name_cn

    const cnName = isUndefined(forceName) ? item.name_cn || item.name_en : forceName

    const enName = isUndefined(forceName) ? item.name_en : forceName

    const entityQueryValue: IQueryValueText = {
      type,
      value: itemIdentity,
      display_name_cn: cnName,
      display_name_en: enName,
    }

    queryValue = entityQueryValue

    filterValueRt = {
      value: { ...queryValue, uuid: itemIdentity, field: itemField, identity },
      raw: item,
      uuid: itemIdentity,
      count: itemCount,
      isLeaf: item.is_leaf,
      uuidPath: [...identityPath].join(','),
    }

    return filterValueRt
  })
}

export function transUuidPathStr2PathIds(uuidPathStr: string) {
  // 使用,分割，将分割后的没一项，和它后面的显使用,拼接
  const uuidPathStrList = uuidPathStr.split(',')

  const pathIds = uuidPathStrList.map((item, index) => {
    return uuidPathStrList.slice(0, index + 1).join(',')
  })

  return pathIds
}
