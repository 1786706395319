import axios, { CancelTokenSource } from 'axios'
import { Observable } from 'rxjs'
import { IAxiosObservable, ICancelAbleParams, IHttpWrap } from './types'

export function createObservable<R extends ICancelAbleParams, T>(promiseFactory: (args: R) => Promise<IHttpWrap<T>>, args: R): IAxiosObservable<T> {
  let cancelSource: CancelTokenSource
  const hasCancelToken = !!args.cancelToken
  if (hasCancelToken) {
    console.warn(`No need to use cancel token, just unsubscribe the subscription would cancel the http request automatically`)
  }

  const observable: IAxiosObservable<T> = new Observable((subscriber) => {
    if (!hasCancelToken) {
      cancelSource = axios.CancelToken.source()
      args.cancelToken = cancelSource.token
    }
    promiseFactory(args)
      .then((response) => {
        subscriber.next(response)
        subscriber.complete()
      })
      .catch((error) => subscriber.error(error))
  })

  const subscribe = observable.subscribe.bind(observable)

  observable.subscribe = (...args2: any[]) => {
    const subscription = subscribe(...args2)

    const unsubscribe = subscription.unsubscribe.bind(subscription)

    subscription.unsubscribe = () => {
      if (cancelSource) {
        cancelSource.cancel()
      }
      unsubscribe()
    }
    return subscription
  }

  return observable
}
