import { decryptNum } from '@pharmsnap/shared/utils'
import { ICompetitiveResult, IRankingItem, ITopAggregationDrugDetail, ITopAggregationParams, ITopClinicalParam, ITopRDParam } from '../../types'
import { replaceDisplayNameByShortName } from '../../utils/business/aggregationTransform/base'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class CompetitiveApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getTopClinicalData(param: ITopClinicalParam): Promise<IHttpWrap<ICompetitiveResult<IRankingItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_by_clinicaltrial'),
      data: param,
      method: 'POST',
      cache: true,
    }).then((rt) => {
      if (rt.success) {
        return {
          ...rt,
          data: {
            ...rt.data,
            items: ((rt.data.items as IRankingItem[]) || []).map((i) => {
              return {
                ...i,
                entity_items: i.entity_items.map((data) => {
                  if (i.aggregation_field === 'TARGET_ID') {
                    return replaceDisplayNameByShortName(data)
                  }
                  return {
                    ...data,
                  }
                }),
                phase_items: i.phase_items.map((data) => {
                  if (!data.display_name_en) return data
                  return {
                    ...data,
                    count: decryptNum(data.count, rt.xCorrelationId),
                  }
                }),
              }
            }),
          },
        }
      }
      return rt
    })
  }

  async getTopRdData(param: ITopRDParam): Promise<IHttpWrap<ICompetitiveResult<IRankingItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_by_drug'),
      data: param,
      method: 'POST',
      cache: true,
    }).then((rt) => {
      if (rt.success) {
        return {
          ...rt,
          data: {
            ...rt.data,
            items: ((rt.data.items as IRankingItem[]) || []).map((i) => {
              return {
                ...i,
                entity_items: i.entity_items.map((data) => {
                  if (i.aggregation_field === 'TARGET_ID') {
                    return replaceDisplayNameByShortName(data)
                  }
                  return {
                    ...data,
                  }
                }),
                phase_items: i.phase_items.map((data) => {
                  return {
                    ...data,
                    count: decryptNum(data.count, rt.xCorrelationId),
                  }
                }),
              }
            }),
          },
        }
      }
      return rt
    })
  }

  async getTopRdDataDrugDetail(params: ITopAggregationParams): Promise<IHttpWrap<ITopAggregationDrugDetail>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/top_by_drug/drug_detail_competitive'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }
}
