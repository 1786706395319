import { STUDY_STATUS_COLOR } from '@patsnap/synapse_common_config'
import { defineComponent } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import { BDecodeUniCodeContainer } from '../../BDecodeUniCodeContainer/BDecodeUniCodeContainer'
import './BStatusTag.scss'

export const BStatusTag = defineComponent({
  props: {
    status: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    fontClassName: {
      type: String,
    },
  },
  render() {
    return (
      <ElTag
        class="b-status-tag"
        size={this.size}
        disable-transitions={true}
        effect="dark"
        color={STUDY_STATUS_COLOR[this.status as keyof typeof STUDY_STATUS_COLOR]?.bg || '#F8E5CE'}
        style={{ color: STUDY_STATUS_COLOR[this.status as keyof typeof STUDY_STATUS_COLOR]?.text || '#764407' }}
      >
        <BDecodeUniCodeContainer fontClassName={this.fontClassName} domPropsInnerHTML={this.status}></BDecodeUniCodeContainer>
      </ElTag>
    )
  },
})
