const style =
  'fontFamily:-apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol'
/**
 * 将一个需要加密的字符串按照空格分割,用默认字体渲染空格,以解决加密字体空格太大的问题
 * @param displayContent
 * @param className
 * @returns
 */
export const handleSpace = (displayContent: string, className?: string | string[]) => {
  return displayContent.split(' ').map((ele, index) => {
    const content = <span class={className} domPropsInnerHTML={ele} />
    return index > 0 ? (
      <span>
        <span style={style}>&nbsp;</span>
        {content}
      </span>
    ) : (
      content
    )
  })
}
/**
 * 将一个需要加密的字符串按照空格分割,用默认字体渲染空格,以解决加密字体空格太大的问题 (文本版本)
 * @param displayContent
 * @param className
 * @returns
 */
export function handleSpace2Str(displayContent: string, className: string | string[] = ''): string {
  return displayContent.split(' ').reduce((acc, ele, index) => {
    const content = `<span class="${className}">${ele}</span>`
    if (index === 0) {
      return acc + content
    }
    return acc + `<span><span style="${style}">&nbsp;</span>${content}</span>`
  }, '')
}
