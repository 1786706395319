import { IHistorySearchParams, IQuickSearchHistoryParams, ISearchHistoryFromModule } from '@pharmsnap/shared/types/search'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export interface IHistoryResult {
  id: string
  from_module?: ISearchHistoryFromModule
  query_param: Omit<IHistorySearchParams, 'from_module'>
}

export interface IQuickHistoryResult {
  id: string
  from_module?: Extract<ISearchHistoryFromModule, 'Intelligent Retrieval'>
  query_param: IQuickSearchHistoryParams
}

export class HistoryApi extends BaseApi {
  queryIdRequestMap = new Map<string, Promise<IHttpWrap<IHistoryResult | IQuickHistoryResult>>>()
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getQueryById(params: {
    query_id: string
    query_type?: string
    extra_query?: string
    single?: AbortSignal
  }): Promise<IHttpWrap<IHistoryResult>> {
    const { query_id, query_type, extra_query } = params
    if (this.queryIdRequestMap.has(query_id)) {
      return this.queryIdRequestMap.get(query_id) as Promise<IHttpWrap<IHistoryResult>>
    }
    const promise = this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/search/history/${query_id}`),
      params: {
        query_type,
        extra_query,
      },
      signal: params.single,
    })
    this.queryIdRequestMap.set(query_id, promise)
    return promise
  }

  async getSmartQueryById(params: {
    query_id: string
    query_type?: string
    extra_query?: string
    signal?: AbortSignal
  }): Promise<IHttpWrap<IHistoryResult | IQuickHistoryResult>> {
    const { query_id, query_type, extra_query } = params
    if (this.queryIdRequestMap.has(query_id)) {
      return this.queryIdRequestMap.get(query_id) as Promise<IHttpWrap<IHistoryResult | IQuickHistoryResult>>
    }
    const promise = this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/search/history/${query_id}`),
      params: {
        query_type,
        extra_query,
      },
      signal: params.signal,
    })
    this.queryIdRequestMap.set(query_id, promise)
    return promise
  }

  async saveQuery(params: IHistorySearchParams): Promise<IHttpWrap<IHistoryResult>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl(`pharmsnap_webapi/1.0/search/history`),
    })) as IHttpWrap<IHistoryResult>
    if (rt.success && rt.data.id) {
      this.queryIdRequestMap.set(rt.data.id, Promise.resolve(rt))
    }
    return rt
  }

  async saveQuickQuery(params: IQuickSearchHistoryParams): Promise<IHttpWrap<IQuickHistoryResult>> {
    const rt = (await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl(`pharmsnap_webapi/1.0/search/history`),
    })) as IHttpWrap<IQuickHistoryResult>
    if (rt.success && rt.data.id) {
      this.queryIdRequestMap.set(rt.data.id, Promise.resolve(rt))
    }
    return rt
  }
}
