import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import '@pharmsnap/shared/src/assets/icon-svg/startups.svg'
import { ILang, IOrganizationCardInfo } from '@pharmsnap/shared/types'
import {
  checkOrganizationIsClosed,
  formatURL,
  getEmployeesRangeNumber,
  getHost,
  getNormalizedLocationDisplayName,
  getOrderedSocialMedial,
  getOrganizationName,
  getOrganizationStockSymbol,
  getOrganizationTypeDisplayName,
  getSocialMedialIcon,
} from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import dayjs from 'dayjs'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GLink } from '../../../ui/GLink/GLink'
import { BIcon } from '../../BIcon/BIcon'
import $classes from '../EntityIntelligentCard.module.scss'
import { BStartupTag } from './BStartupTag'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BOrganizationIntelligentCard = defineComponent({
  name: 'BOrganizationIntelligentCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IOrganizationCardInfo>,
    },
    layout: {
      type: String as PropType<'basic' | 'intermediate' | 'advanced'>,
      default: 'intermediate',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN, locale: lowerCaseLocale } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const href = computed(() =>
      data?.value?.entity_id && isLsRelated.value && !isClosed.value ? sharedCtx.router.generatorOrgPath(data.value.entity_id || '') : ''
    )
    const avatar = computed(() => data?.value?.logo_s3_path || data?.value?.logo || '')
    const type = computed(() =>
      data?.value?.normalized_entity_type_en
        ? getOrganizationTypeDisplayName(data?.value?.normalized_entity_type_en, locale.value)
        : EMPTY_PLACEHOLDER
    )
    const isLsRelated = computed(() => !!data?.value?.is_ls_related)
    const isClosed = computed(() => checkOrganizationIsClosed(data?.value?.entity_status))
    const founded = computed(() =>
      typeof data?.value?.founded_date === 'undefined' ? EMPTY_PLACEHOLDER : dayjs(`${data?.value?.founded_date}`).year()
    )
    const location = computed(() => getNormalizedLocationDisplayName(data?.value, lowerCaseLocale.value))
    const desc = computed(() => {
      const enDesc = data?.value?.short_description_en || data?.value?.long_description_en
      const cnDesc = data?.value?.description_cn
      const langDesc = lowerCaseLocale.value === 'cn' ? cnDesc || enDesc : enDesc || cnDesc
      return langDesc || data?.value?.description_cn_translation || EMPTY_PLACEHOLDER
    })
    const employees = computed(() =>
      typeof data?.value?.employee_number === 'undefined' ? EMPTY_PLACEHOLDER : getEmployeesRangeNumber(data?.value?.employee_number)
    )
    const isStartup = computed(() => {
      return data?.value?.start_up
    })

    const stockSymbol = computed(() => getOrganizationStockSymbol(data?.value?.stock_exchange_code, data?.value?.stock_symbol, false))

    const shouldDisplayCnName = !!IS_CN_REGION && !!isCN.value && data?.value?.is_china !== false && !!data?.value?.name_cn && !!data?.value?.name_en

    return {
      isLsRelated,
      isClosed,
      employees,
      desc,
      href,
      avatar,
      type,
      founded,
      location,
      isCN,
      isStartup,
      shouldDisplayCnName,
      stockSymbol,
    }
  },
  methods: {
    renderHeader() {
      if (this.layout === 'basic') {
        return (
          <div class={['flex items-center']}>
            <BIcon class={['flex-shrink-0', 'mr-2']} border={true} type="company" src={this.avatar} size={32}></BIcon>
            <div style="line-height: 0;" class={['flex-1 overflow-hidden']}>
              <div class="flex items-center leading-6">
                <GLink
                  class="font-semibold text-base block"
                  style="line-height: 24px;"
                  newTab={true}
                  href={this.href}
                  name={getOrganizationName(this.data || {}, 'en')}
                  truncate={true}
                  encryptClass={this.data?.fontClassName}
                ></GLink>
                {this.isStartup ? (
                  <span class="inline-flex items-center justify-center h-5 w-5 rounded bg-green-tag ml-1">
                    <GIcon svgName="Startups" size={16} color="#45A321" />
                  </span>
                ) : null}
              </div>
              {this.shouldDisplayCnName ? (
                <div domPropsInnerHTML={this.data?.name_cn} class={['leading-4 text-xs text-text-t1', this.data?.fontClassName]}></div>
              ) : null}
            </div>
          </div>
        )
      }

      if (this.layout === 'intermediate') {
        return (
          <div class={['flex items-start']}>
            <BIcon class={['flex-shrink-0', 'mr-3']} border={true} type="company" src={this.avatar} size={24}></BIcon>
            <div style="line-height: 0;" class={['flex-1']}>
              <GLink
                class="leading-[24px] font-semibold text-base block"
                newTab={true}
                href={this.href}
                name={getOrganizationName(this.data || {}, 'en')}
                truncate={true}
                encryptClass={this.data?.fontClassName}
              ></GLink>
              {this.shouldDisplayCnName ? (
                <div domPropsInnerHTML={this.data?.name_cn} class={['leading-5 text-sm text-text-t1', this.data?.fontClassName]}></div>
              ) : null}
              {this.isStartup ? <BStartupTag class="mt-1"></BStartupTag> : null}
            </div>
          </div>
        )
      }

      const website = this.data?.website

      const socialMedials = getOrderedSocialMedial(this.data?.social_medias)

      const shouldDisplayWebsite = !!website

      const shouldDisplayMedials = !!socialMedials?.length

      const shouldDisplayWebsiteOrMedials = shouldDisplayMedials || shouldDisplayWebsite

      return (
        <div class="border-b border-gray-30 pb-2">
          <div class={['flex items-center flex-row-reverse']}>
            <BIcon class={['flex-shrink-0', 'ml-3']} border={true} type="company" src={this.avatar} size={72}></BIcon>
            <div style="line-height: 0;" class={['flex-1']}>
              <GLink
                class="leading-[24px] font-semibold text-base block"
                newTab={true}
                href={this.href}
                name={getOrganizationName(this.data || {}, 'en')}
                truncate={false}
                encryptClass={this.data?.fontClassName}
              ></GLink>
              {this.shouldDisplayCnName ? (
                <div domPropsInnerHTML={this.data?.name_cn} class={['leading-5 text-sm text-text-t1', this.data?.fontClassName]}></div>
              ) : null}
              {this.isStartup ? <BStartupTag class="mt-1"></BStartupTag> : null}
            </div>
          </div>
          <div style="word-break: break-word;" class="text-sm leading-5 text-text-t2 line-clamp-3 mt-4">
            {this.desc}
          </div>
          {shouldDisplayWebsiteOrMedials ? (
            <div class="flex items-center mt-2">
              {shouldDisplayWebsite ? (
                <a class={['text-blue-default hover:underline', shouldDisplayMedials ? 'mr-2' : '']} target="_blank" href={formatURL(website)}>
                  {getHost(website)}
                </a>
              ) : null}
              {shouldDisplayMedials
                ? socialMedials.map((item, index) => (
                    <a href={item.url} target="_blank">
                      <GIcon
                        useSvgDefaultColor
                        svgName={getSocialMedialIcon(item.type)}
                        size={20}
                        class={[{ 'ml-1': index !== 0 }, ['cursor-pointer']]}
                      ></GIcon>
                    </a>
                  ))
                : null}
            </div>
          ) : null}
        </div>
      )
    },
    renderBody() {
      if (this.layout === 'basic') return null

      if (this.layout === 'intermediate') {
        return [
          <div style="word-break: break-word;" class="text-sm leading-5 text-text-t2 line-clamp-3 mt-4 mb-2">
            {this.desc}
          </div>,
          this.renderIntermediateFields(),
        ]
      }

      return <div class="pt-2">{this.renderFields()}</div>
    },
    renderIntermediateFields() {
      return (
        <div class="space-y-2 text-text-t1">
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.type')}
              {':'}
            </div>
            <div class="flex-1">{this.type}</div>
          </div>
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.location')}
              {':'}
            </div>
            <div class="flex-1">{this.location.displayLocation || EMPTY_PLACEHOLDER}</div>
          </div>
        </div>
      )
    },
    renderFields() {
      return (
        <div class="space-y-2 text-text-t1">
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.type')}
              {':'}
            </div>
            <div class="flex-1">{this.type}</div>
          </div>
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.founded')}
              {':'}
            </div>
            <div class="flex-1">{this.founded}</div>
          </div>
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.location')}
              {':'}
            </div>
            <div class="flex-1">{this.location.displayLocation || EMPTY_PLACEHOLDER}</div>
          </div>
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.employees')}
              {':'}
            </div>
            <div class="flex-1">{this.employees}</div>
          </div>
          <div class="leading-5 text-sm flex items-start overflow-hidden">
            <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth};`} class="mr-2 text-text-t2 flex-shrink-0">
              {this.$t('organizationCard.stock')}
              {':'}
            </div>
            <div class="flex-1">{this.stockSymbol || EMPTY_PLACEHOLDER}</div>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
})
