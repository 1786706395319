import { ElCheckbox } from '@pharmsnap/shared/element-ui'
import { defineComponent, PropType } from '@vue/composition-api'

export const BAnalysisChartCheckbox = defineComponent({
  name: 'BAnalysisChartCheckbox',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
    },
    name: {
      type: String,
    },
  },
  render() {
    return (
      <span class="flex items-start cursor-pointer">
        <ElCheckbox
          onChange={(val: boolean) => this.$emit('input', val)}
          value={this.value}
          class="flex-shrink-0 mt-[2px]"
          style="font-size:0;"
        ></ElCheckbox>
        <span class="flex-1 ml-1 text-sm leading-5" onClick={() => this.$emit('input', !this.value)}>
          {this.name}
        </span>
      </span>
    )
  },
})
