import '@patsnap-ui/icon/assets/solid/TextFile.svg'
import { BCardContainer, BEntityLoadingCard, BLazyImg, GDescription, GLimitedContainer, GLink, GTranslate } from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElDivider } from '@pharmsnap/shared/element-ui'
import {
  getLang,
  renderCite,
  renderLiteratureOA,
  renderLiteraturePublicationAndJournalInfo,
  renderLiteratureTitleText,
  renderLiteratureType,
  renderPaperAbstTextInTable,
  renderPaperEPubDate,
  renderPaperPopoverLimitedDisease,
  renderPaperPopoverLimitedDrug,
  renderPaperPopoverLimitedTarget,
  renderPaperTranslationField,
} from '@pharmsnap/shared/render'
import { ElCheckbox } from '@pharmsnap/shared/src/element-ui'
import { Abstract, IPaperDetail, ITranslateLang } from '@pharmsnap/shared/types'
import { generatorLangFieldISTranslateFn } from '@pharmsnap/shared/utils/translate'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import styles from './BCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BCardLiterature = defineComponent({
  name: 'BCardLiterature',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IPaperDetail>,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    paperSponsorAppendToBody: {
      type: Boolean,
      default: false,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
  },
  setup(props) {
    const { forceTranslation } = toRefs(props)
    const {
      getters: { customSettingTranslation },
    } = useAuthStore()
    const { locale, isCN } = useLocale()
    const detailUrl = computed(() => {
      return sharedCtx.router.generatorPaperDetailPath(props.data.paper_id)
    })
    const isExecuteTranslateFn = computed(() => {
      if (props.disabledTranslate) {
        return () => false
      }
      return generatorLangFieldISTranslateFn(forceTranslation.value || customSettingTranslation.value)
    })
    const isIntelligentMode = computed(() => props.mode === 'intelligent')
    return {
      locale,
      detailUrl,
      isExecuteTranslateFn,
      isCN,
      isIntelligentMode,
    }
  },
  methods: {
    renderCheckbox() {
      return (
        <div class="mr-2.5">
          <ElCheckbox value={this.isChecked} onChange={() => this.$emit('checkboxChange', { checked: !this.isChecked, row: this.data })}></ElCheckbox>
        </div>
      )
    },
    renderHeader() {
      return (
        <div class={['flex items-center text-sm text-text-t2', this.isIntelligentMode ? '' : 'pt-3']}>
          {/* {this.isIntelligentMode ? (
            <div class="flex items-center">
              <span class="flex-shrink-0 leading-6 text-[#C057D1]">{this.locale === 'cn' ? '文献' : 'Literature'}</span>
              <span class="flex-shrink-0 leading-6 mx-1 text-text-t2">/</span>
              {renderLiteraturePublicationAndJournalInfo(this.data, this.$i18n)}
            </div>
          ) : (
            )} */}
          {this.showCheckbox ? this.renderCheckbox() : null}
          {renderLiteraturePublicationAndJournalInfo(this.data, this.$i18n)}
        </div>
      )
    },
    renderBody() {
      const literatureType = renderLiteratureType(this.data)
      const literatureOA = this.data.biblio?.journal?.is_open_access === '1' ? <span class="ml-2">{renderLiteratureOA(this.data)}</span> : null
      const currentLangAbst = this.data.abstract
        ? ((this.data.abstract.find((item) => item.lang === getLang(this.$i18n)) ||
            this.data.abstract.find((item) => item.lang === 'EN')) as Abstract)
        : null

      return (
        <div class="mt-2">
          <div>
            <GTranslate execute={this.isExecuteTranslateFn(this.data.title)} content={renderPaperTranslationField(this.data, 'title_translation')}>
              <div class="flex justify-start flex-wrap items-center mb-1.5">
                <GLink
                  href={this.detailUrl}
                  newTab={true}
                  style="word-break: break-word;"
                  class={[
                    styles.title,
                    this.isIntelligentMode ? 'text-base leading-6' : 'text-lg leading-6',
                    'hover:text-blue-default font-semibold mr-2',
                  ]}
                >
                  <span domPropsInnerHTML={renderLiteratureTitleText(this.data, this.$i18n)}></span>
                </GLink>
              </div>
              {literatureType || literatureOA ? (
                <div class="my-1.5">
                  {literatureType}
                  {literatureOA}
                </div>
              ) : null}
            </GTranslate>
            <div>
              <div class="flex items-end">
                <div class="flex-1">
                  <div class="my-1.5 text-sm text-text-t2 line-clamp-3" style="max-width: 100%">
                    {`${this.$t('BCard.author')}: ${this.renderAuthor(this.data) || EMPTY_PLACEHOLDER}`}
                  </div>
                  {this.renderCiteWithEPub()}

                  {currentLangAbst && currentLangAbst.abst && !this.isIntelligentMode ? (
                    <GLimitedContainer
                      limitLines={5}
                      class="mr-2 mt-1.5"
                      newTab={true}
                      href={sharedCtx.router.generatorPaperDetailPath(this.data.paper_id)}
                      moreText={this.$tc('common.showAll')}
                    >
                      {renderPaperAbstTextInTable(currentLangAbst.abst)}
                    </GLimitedContainer>
                  ) : this.isIntelligentMode ? null : (
                    <div class="text-sm mt-1.5">{`${this.$t('BCard.abstract')}: ${EMPTY_PLACEHOLDER}`}</div>
                  )}
                </div>
              </div>
              {this.data?.abstract_translation?.abst?.length && !this.isIntelligentMode ? (
                <div class={['bg-gray-20 p-2 text-text-t1 text-sm my-2', 'mt-4']}>
                  <span class="float-left -ml-2 font-semibold">
                    {this.isCN ? <span>【</span> : <span class="ml-1">[</span>}
                    {this.$t('common.translate')}
                    {this.isCN ? <span>】</span> : <span class="mr-1">]</span>}
                  </span>
                  {renderPaperAbstTextInTable(this.data.abstract_translation?.abst) && (
                    <GLimitedContainer
                      limitLines={5}
                      newTab={true}
                      href={sharedCtx.router.generatorPaperDetailPath(this.data.paper_id)}
                      moreColor="#f0f1f3"
                      moreText={this.$tc('common.showAll')}
                    >
                      {renderPaperAbstTextInTable(this.data.abstract_translation?.abst)}
                    </GLimitedContainer>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {this.showFooter && !this.isIntelligentMode ? <ElDivider /> : null}
        </div>
      )
    },
    renderCiteWithEPub() {
      const citeData = renderCite(this.data)
      const ePub = this.data?.biblio?.epub_date
      return citeData || ePub ? (
        <div class="flex flex-wrap my-1.5 text-sm">
          {citeData ? (
            <div class="text-text-t2 mr-28 flex">
              <span class="flex-shrink-0">{`${this.$t('BCard.cite')}:`}</span>
              <span class="inline-block text-text-t1 text-sm ml-2">{citeData}</span>
            </div>
          ) : null}
          {ePub ? (
            <div class="text-text-t2 flex">
              <span class="flex-shrink-0">{`${this.$t('BCard.ePub')}:`}</span>
              <span class="inline-block text-text-t1 text-sm ml-2">{renderPaperEPubDate(this.data, getLang(this.$i18n))}</span>
            </div>
          ) : null}
        </div>
      ) : null
    },
    renderCoverImg() {
      const cover = this.data?.paper_image?.abstract_image
      if (!cover || !cover.singed_url) return null
      return (
        <div class="flex-shrink-0 pt-8 pr-4" style="width: 332px">
          <BLazyImg
            class="ml-4 cursor-pointer border rounded border-gray-40"
            style="height: 200px"
            imgClass="w-full h-full rounded"
            imgStyle="object-fit: contain"
            src={cover.singed_url}
          ></BLazyImg>
        </div>
      )
    },
    renderFooter() {
      if (!this.showFooter || this.isIntelligentMode) {
        return null
      }
      /** 自定义footer */
      if (this.$slots.customFooter) {
        return this.$slots.customFooter
      }
      return (
        <div class="-mb-2">
          <div class="text-text-t2 mb-3">{this.$tc('BCard.MentionedEntities')}</div>
          <GDescription
            width={85}
            data={this.data}
            items={[
              {
                label: `${this.$tc('common.disease')}:`,
                field: 'disease',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.target')}:`,
                field: 'targets',
                tipCls: 'w-80',
                tipPlacement: 'right',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.drug')}:`,
                field: 'drugs',
                contentCls: styles.contentItem,
              },
            ]}
            scopedSlots={{
              disease: () => renderPaperPopoverLimitedDisease(this.data, 3),
              targets: () => renderPaperPopoverLimitedTarget(this.data, 3),
              drugs: () => renderPaperPopoverLimitedDrug(this.data, 3),
            }}
          ></GDescription>
        </div>
      )
    },
    renderAuthor(data: IPaperDetail) {
      return data.biblio?.author?.map((item) => item.original_name).join('; ')
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={[styles.container, this.isIntelligentMode ? 'mb-0' : '']}>
          <div slot="header"></div>
          <div class="flex">
            <div class="flex-1 overflow-hidden">
              {this.renderHeader()}
              {this.renderBody()}
            </div>
            {this.renderCoverImg()}
          </div>
          {this.renderFooter()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
