import '@patsnap-ui/icon/assets/solid/AddLarge.svg'
import '@patsnap-ui/icon/assets/solid/delete.svg'
import { ISearchCollapseRule } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GDropdownSelect } from '../../ui/GDropdownSelect/GDropdownSelect'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BPtCollapseCountryMulti } from './BPtCollapseCountryMulti'
import $style from './BPtCollapseOptionItem.module.scss'
import { OPTION_APD_FIELD, OPTION_COUNTRY_FIELD, OPTION_LABEL_FIELD, OPTION_PBDT_FIELD, RELATED_OPTION_FIELD } from './config'

export const BPtCollapseOptionItem = defineComponent({
  name: 'BPtCollapseOptionItem',
  props: {
    data: {
      type: Object as PropType<{ rules: Array<ISearchCollapseRule & { options?: string[] }>; limit: number }>,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const rules = computed(() => data.value.rules)

    const handleChangeFieldSelect = (rule: ISearchCollapseRule, field: string) => {
      rule.field !== field && ctx.emit('changeField', rule, field)
    }

    const handleChangeTypeSelect = (rule: ISearchCollapseRule, type: string) => {
      rule.type !== type && ctx.emit('changeType', rule, type)
    }

    const handleChangeValueSelect = (rule: ISearchCollapseRule, value: string) => {
      rule.value !== value && ctx.emit('changeValue', rule, value)
    }

    const handleDeleteRule = (rule: ISearchCollapseRule) => {
      ctx.emit('delete', rule)
    }

    const handleAddRule = (rule: ISearchCollapseRule) => {
      ctx.emit('add', rule)
    }

    return {
      rules,
      handleChangeFieldSelect,
      handleChangeTypeSelect,
      handleChangeValueSelect,
      handleDeleteRule,
      handleAddRule,
    }
  },
  methods: {
    renderBaseSelect(rule: ISearchCollapseRule & { options?: string[] }) {
      const field = rule.field
      const options = rule.options || []
      return (
        <GDropdownSelect
          width={180}
          class={$style.itemSelect}
          value={field}
          options={options.map((i) => ({ value: i, label: this.$tc(`patent.collapseRule.options.${i}`) }))}
          onInput={(val: string) => this.handleChangeFieldSelect(rule, val)}
        ></GDropdownSelect>
      )
    },
    renderTypeSelect(rule: ISearchCollapseRule & { options?: string[] }) {
      if (rule.type === 'max' || rule.type === 'min') {
        return (
          <GDropdownSelect
            width={180}
            class={$style.itemSelect}
            value={rule.type}
            options={['max', 'min'].map((i) => ({ value: i, label: this.$tc(`patent.collapseRule.type.${i}`) }))}
            onInput={(val: string) => this.handleChangeTypeSelect(rule, val)}
          ></GDropdownSelect>
        )
      }

      return null
    },
    renderCountrySelect(rule: ISearchCollapseRule & { options?: string[] }) {
      return (
        <BPtCollapseCountryMulti
          onChange={(val: string) => this.handleChangeValueSelect(rule, val)}
          class={$style.itemSelect}
          value={rule.value}
        ></BPtCollapseCountryMulti>
      )
    },
    renderAPDContent(rule: ISearchCollapseRule & { options?: string[] }) {
      return [this.renderBaseSelect(rule), this.renderTypeSelect(rule)]
    },
    renderPBDTContent(rule: ISearchCollapseRule & { options?: string[] }) {
      return [this.renderBaseSelect(rule), this.renderTypeSelect(rule)]
    },
    renderCountryContent(rule: ISearchCollapseRule & { options?: string[] }) {
      return [this.renderBaseSelect(rule), this.renderCountrySelect(rule)]
    },
    renderRelatedContent(rule: ISearchCollapseRule & { options?: string[] }) {
      return this.renderBaseSelect(rule)
    },
    renderLabelContent(rule: ISearchCollapseRule & { options?: string[] }) {
      return this.renderBaseSelect(rule)
    },
    renderContent(rule: ISearchCollapseRule & { options?: string[] }) {
      const field = rule.field

      if (field === OPTION_COUNTRY_FIELD) {
        return this.renderCountryContent(rule)
      } else if (field === OPTION_APD_FIELD) {
        return this.renderAPDContent(rule)
      } else if (field === OPTION_PBDT_FIELD) {
        return this.renderPBDTContent(rule)
      } else if (field === RELATED_OPTION_FIELD) {
        return this.renderRelatedContent(rule)
      } else if (field === OPTION_LABEL_FIELD) {
        return this.renderLabelContent(rule)
      }

      return null
    },
    renderDeleteAction(rule: ISearchCollapseRule & { options?: string[] }) {
      return (
        <GTooltip theme="dark" content={this.$tc('common.removeRule')} placement="top">
          <span onClick={() => this.handleDeleteRule(rule)} class={$style.itemAction}>
            <GIcon useSvgDefaultColor={true} svgName="SolidDelete" size={24}></GIcon>
          </span>
        </GTooltip>
      )
    },
    renderAddAction(rule: ISearchCollapseRule & { options?: string[] }) {
      return (
        <GTooltip theme="dark" content={this.$tc('common.addRule')} placement="top">
          <span onClick={() => this.handleAddRule(rule)} class={$style.itemAction}>
            <GIcon useSvgDefaultColor={true} svgName="SolidAddLarge" size={24}></GIcon>
          </span>
        </GTooltip>
      )
    },
    renderAction(rule: ISearchCollapseRule & { options?: string[] }, total: number, index: number) {
      const limit = this.data.limit
      const shouldRenderAction = limit > 0
      if (!shouldRenderAction) return null
      const isLast = index === total - 1
      const hasReachLimit = total === limit
      const buttons = []
      if (total > 1) {
        buttons.push(this.renderDeleteAction(rule))
      }
      if (!hasReachLimit && isLast) {
        buttons.push(this.renderAddAction(rule))
      }
      return buttons
    },
  },
  render() {
    return (
      <div>
        {this.rules.map((rule, index) => {
          const label = this.$tc(`patent.collapseRule.label.${index}`)
          return (
            <div class={$style.item}>
              <div class={$style.itemLabel}>{label}</div>
              <div class={$style.itemContent}>
                {this.renderContent(rule)}
                {this.renderAction(rule, this.rules.length, index)}
              </div>
            </div>
          )
        })}
      </div>
    )
  },
})
