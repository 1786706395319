import { IBaseDrugAdc } from '@patsnap/synapse_common_interface'
import { PropType, defineComponent } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../card/config'
import { BDrugAdcFieldDesc } from './BDrugAdcFieldDesc'

export const BDrugAdcPopperDesc = defineComponent({
  name: 'BDrugAdcPopperDesc',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
  },
  render() {
    return (
      <PtPopper data={{ data: this.data, popperEnable: true }} tippyConfig={getAsyncListTippyConfig()} component={BDrugAdcFieldDesc}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
