import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import { PropType, defineComponent } from '@vue/composition-api'
import styles from './GListSkeleton.module.scss'

export const GListSkeleton = defineComponent({
  name: 'GListSkeleton',
  props: {
    type: {
      type: String as PropType<'default' | 'card' | 'withImg'>,
      default: 'default',
    },
  },
  setup(props) {
    return {}
  },
  methods: {
    renderCardSkeleton() {
      return (
        <ElSkeleton animated>
          <template slot="template">
            <ElSkeletonItem variant="p" class="w-2/5"></ElSkeletonItem>
            <div class="mt-7">
              {Array.from({ length: 6 }).map(() => (
                <div class="flex items-center mt-4">
                  <ElSkeletonItem variant="p" class="mr-8" style="width: 15%; height: 18px; margin-right: 16px"></ElSkeletonItem>
                  <ElSkeletonItem variant="p" style="width: 80%; height: 18px;"></ElSkeletonItem>
                </div>
              ))}
            </div>
          </template>
        </ElSkeleton>
      )
    },
    renderWithImgSkeleton() {
      return (
        <ElSkeleton animated>
          <template slot="template">
            <div class="flex">
              <div class="mr-4 flex-1">
                <div class="flex">
                  <ElSkeletonItem variant="p" class="mr-4" style="width: 128px;"></ElSkeletonItem>
                  <ElSkeletonItem variant="p" style="width: 56px;"></ElSkeletonItem>
                </div>
                <ElSkeletonItem variant="p" class="mt-4" style="width: 75%;"></ElSkeletonItem>
                <div class="mt-6">
                  {Array.from({ length: 3 }).map(() => (
                    <ElSkeletonItem variant="p" class="w-full "></ElSkeletonItem>
                  ))}
                </div>
                <div class="mt-9">
                  {Array.from({ length: 4 }).map(() => (
                    <ElSkeletonItem variant="p" class="mt-3" style="display: block; width: 50%;"></ElSkeletonItem>
                  ))}
                </div>
              </div>
              <ElSkeletonItem variant="p" style="width: 136px; height: 204px;"></ElSkeletonItem>
            </div>
          </template>
        </ElSkeleton>
      )
    },
    renderDefaultSkeleton() {
      return (
        <ElSkeleton animated>
          <template slot="template">
            <ElSkeletonItem variant="p" style="display: block; width: 96px;"></ElSkeletonItem>
            <ElSkeletonItem variant="p" style="display: block; width: 50%; margin-top: 16px"></ElSkeletonItem>
            <div class="mt-5">
              {Array.from({ length: 2 }).map(() => (
                <ElSkeletonItem variant="p" class="mt-3"></ElSkeletonItem>
              ))}
            </div>
          </template>
        </ElSkeleton>
      )
    },
    renderSkeleton() {
      if (this.type === 'card') {
        return this.renderCardSkeleton()
      }
      if (this.type === 'withImg') {
        return this.renderWithImgSkeleton()
      }
      return this.renderDefaultSkeleton()
    },
  },
  render() {
    return <div class={styles.skeletonContainer}>{this.renderSkeleton()}</div>
  },
})
