import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.NEWS_HOME,
}

export default [
  {
    path: `news-home`,
    redirect: () => {
      return {
        name: E_ROUTER_NAME.LATEST_NEWS_HOME,
      }
    },
  },
  {
    path: 'news',
    name: E_ROUTER_NAME.LATEST_NEWS_HOME,
    component: () => import('@pharmsnap/pharmsnap-web/views/news/NewsHome'),
    meta: {
      ...meta,
      title: createWebTitle('最新资讯', 'Latest News'),
      notWide: true,
    },
  },
  {
    path: 'news-list',
    name: E_ROUTER_NAME.LATEST_NEWS_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/news/NewsList'),
    meta: {
      ...meta,
      title: createWebTitle('新闻检索结果', 'News Search Results'),
      notWide: true,
    },
  },
  {
    path: `news-detail/:${E_ROUTER_PARAMS.LATEST_NEWS_ID}`,
    name: E_ROUTER_NAME.LATEST_NEWS_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/news/NewsDetail'),
    meta,
  },
] as RouteConfig[]
