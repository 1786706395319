/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropType, defineComponent, ref } from '@vue/composition-api'

export const GDialogTrigger = defineComponent({
  name: 'GDialogTrigger',
  props: {
    dialog: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup() {
    const visible = ref(false)

    return {
      visible,
    }
  },
  render() {
    const TriggerDialog = this.dialog as any
    console.log({
      attrs: this.$attrs,
      listeners: this.$listeners,
    })
    return (
      <span>
        <span style="line-height:0;" onClick={() => (this.visible = true)}>
          {this.$slots.default}
        </span>
        <TriggerDialog
          {...{
            props: {
              ...this.$attrs,
              visible: this.visible,
            },
            on: {
              ...this.$listeners,
              input: (val: boolean) => {
                this.visible = val
              },
            },
          }}
        ></TriggerDialog>
      </span>
    )
  },
})
