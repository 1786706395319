import { IHeaderTrackingData } from '@patsnap/synapse_common_interface'
import { transObj2CookieString } from '@patsnap/synapse_common_utils'
import { AxiosInstance } from 'axios'
import { trackingEventSource, trackingPage } from 'pharmsnapMF_shared/utils'
import router from '../router'

const X_TRACKING = 'x-tracking'

function getRouteNameByPathName(pathname: string) {
  const curRoute = router.match(pathname)
  return curRoute ? curRoute.meta?.trackingRouteName || curRoute.name : ''
}

const { referrer } = document

/**
 * 接口埋点拦截器
 * @param axiosIns axios实例
 */
export function interceptAxiosWithTracking(axiosIns: AxiosInstance) {
  axiosIns.interceptors.request.use((config) => {
    const prePageName = trackingPage().getPrePageName()
    const referrerPageName = referrer ? getRouteNameByPathName(new URL(referrer).pathname) : ''
    const fromPage = prePageName || referrerPageName
    let trigger_point = ''
    if (config.trackingName) {
      trigger_point = trackingEventSource().getTrackingEventSourceByConsumer(config.trackingName)?.trigger_point || ''
    }

    const trackingData: IHeaderTrackingData = {
      from_page: fromPage || '',
      from_page_url: trackingPage().getPrePageUrl() || referrer || '',
      current_page: trackingPage().getCurrentPageName() || '',
      current_page_url: trackingPage().getCurrentPageUrl() || '',
      trigger_point: trigger_point || '',
    }

    const tracking = transObj2CookieString(trackingData)

    config.headers = {
      ...config.headers,
      ...(tracking
        ? {
            [X_TRACKING]: tracking,
          }
        : {}),
    }
    return config
  })
}
