import { IFieldLangMap } from '@pharmsnap/shared/types'

export const NEWS_LANG_MAP: IFieldLangMap = {
  CN: {
    cn: '中文',
    en: 'CN',
  },
  EN: {
    cn: '英文',
    en: 'EN',
  },
}

export const NEWS_SECTION_LANG_MAP: IFieldLangMap = {
  0: {
    cn: '精选新闻',
    en: 'Featured News',
  },
  1: {
    cn: '全球快讯',
    en: 'Global Express',
  },
}
