import '@patsnap-ui/icon/assets/solid/EyeOff.svg'
import '@patsnap-ui/icon/assets/solid/move.svg'
import '@patsnap-ui/icon/assets/solid/see.svg'
import { GDialog } from '@pharmsnap/shared/components/ui/GDialog/GDialog'
import { useTranslateFieldLang } from '@pharmsnap/shared/composition'
import { IFieldLangMap } from '@pharmsnap/shared/types'
import { defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'
import { Icon as PtIcon } from 'patsnap-biz'
import Draggable from 'vuedraggable'
import { useLocale } from '../../../../../composition/useLocale'
import { ElInput, ElSwitch } from '../../../../../element-ui'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'
import { IColumnFieldItem, ICommonListFieldConfigMap } from '../../types'
import $class from './BFieldManage.module.scss'
interface IDragItem {
  // 用于提取得到最终值
  field: string
  // 用于搜索匹配的值
  en: string
  cn: string
  /**
   * 是否禁止 选择去拖拽 和 禁止替换此元素位置
   * true为禁止拖拽排序
   * false为允许拖拽排序
   */
  isDragForbid: boolean
  /**
   * 是否禁止用户隐藏此字段
   */
  isHideForbid: boolean
  show: boolean
}
interface IManageData {
  fieldList: IDragItem[]
  backUpFieldList: IDragItem[]
}
export const BFieldManage = defineComponent({
  name: 'BFieldManage',
  i18n: {
    messages: { cn, en },
  },
  props: {
    // 全部字段map
    fieldsConfigMap: {
      type: Object as PropType<Partial<Record<string, ICommonListFieldConfigMap>>>,
      required: true,
    },
    // 用户当前的设置情况
    userConfigFields: {
      type: Array as PropType<IColumnFieldItem[]>,
      default: () => [],
    },
    // 字段语言map
    fieldLangMap: {
      type: Object as PropType<Partial<IFieldLangMap>>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const draggable = ref<boolean>(false) // 整体是否 可以拖拽排序
    const { translateField } = useTranslateFieldLang(props.fieldLangMap)
    const manageData = reactive<IManageData>({
      fieldList: [],
      backUpFieldList: [], // 完整的数据，用于search前端过滤的备份
    })
    function init() {
      manageData.fieldList = props.userConfigFields.reduce((acc, curr) => {
        const { field, show } = curr
        const config = props.fieldsConfigMap[field]
        if (!config) {
          console.error(`BFiledManage: 字段${field}的配置不存在,请检查`)
          return acc
        }
        const langMap = props.fieldLangMap[field]
        if (!langMap) {
          console.error(`BFiledManage: 字段${field}的语言配置不存在,请检查`)
          return acc
        }
        const current: IDragItem = {
          field,
          isHideForbid: !!config.forbidConfig,
          isDragForbid: !!config.forbidDrag,
          cn: langMap.cn,
          en: langMap.en,
          show,
        }
        return [...acc, current]
      }, [] as IDragItem[])
    }
    function clearData() {
      manageData.fieldList = []
      manageData.backUpFieldList = []
      searchContent.value = ''
    }
    const isShowPopup = ref<boolean>(false) // 是否显示弹窗
    watch(isShowPopup, (value) => {
      if (value) {
        init()
      } else {
        clearData()
      }
    })

    // 搜索逻辑
    const { locale } = useLocale()
    const searchContent = ref<string>('')
    watch(
      searchContent,
      debounce((content) => {
        if (!content) {
          manageData.fieldList = manageData.backUpFieldList
          manageData.backUpFieldList = []
          draggable.value = false
        } else {
          draggable.value = true
          const lang = locale.value
          if (!manageData.backUpFieldList.length) {
            manageData.backUpFieldList = manageData.fieldList
          }
          const filterArr = manageData.fieldList.filter((item) => {
            const reg = new RegExp(content, 'i')
            return item[lang].search(reg) !== -1
          })
          manageData.fieldList = filterArr
        }
      }, 300)
    )

    // 事件
    function onShowDialog() {
      if (props.disabled) return
      isShowPopup.value = true
    }
    const onShowAllField = debounce(() => {
      manageData.fieldList.forEach((item) => {
        if (!item.isHideForbid) {
          item.show = true
        }
      })
    }, 300)
    const onHideAllField = debounce(() => {
      manageData.fieldList.forEach((item) => {
        if (!item.isHideForbid) {
          item.show = false
        }
      })
    }, 300)
    function onMove(e: { relatedContext: { element: IDragItem } }) {
      // 如果要拖拽到的 目标 上，有禁用字段，那么返回false，禁止插入
      return !e.relatedContext.element.isDragForbid
    }
    function onClickCancel() {
      isShowPopup.value = false
    }
    // 提交后，传出排序后的数据
    function onColumnFieldsChange() {
      const fieldList = searchContent.value ? manageData.backUpFieldList : manageData.fieldList
      const rt: IColumnFieldItem[] = fieldList.map((item) => {
        return {
          field: item.field,
          show: item.show,
        }
      })
      ctx.emit('columnFieldsChange', rt)
      isShowPopup.value = false
    }
    return {
      translateField,
      locale,
      manageData,
      searchContent,
      draggable,
      isShowPopup,
      onShowDialog,
      onShowAllField,
      onHideAllField,
      onMove,
      onClickCancel,
      onColumnFieldsChange,
    }
  },
  methods: {
    renderToggleShowFieldContent() {
      if (!this.searchContent) {
        return (
          <div class={$class.toggleShowField}>
            <div class={$class.toggleShowFieldItem} onClick={this.onShowAllField}>
              <PtIcon icon="SolidSee"></PtIcon>
              <span class={$class.toggleText}>{this.$tc('fieldManage.showAll')}</span>
            </div>
            <div class={$class.toggleShowFieldItem} onClick={this.onHideAllField}>
              <PtIcon icon="SolidEyeOff"></PtIcon>
              <span class={$class.toggleText}>{this.$tc('fieldManage.hideAll')}</span>
            </div>
          </div>
        )
      }
      return null
    },
    renderDraggableContent() {
      if (this.manageData.fieldList.length) {
        return this.manageData.fieldList.map((item) => {
          return (
            <div key={item.field} class={[this.draggable || item.isDragForbid ? 'forbid' : 'move-item', $class.fieldItem]}>
              <div class={$class.fieldItemLeft}>
                <ElSwitch v-model={item.show} disabled={item.isHideForbid} width={35}></ElSwitch>
                <div class={item.isDragForbid ? $class.disableColor : $class.moveColor}>{this.translateField(item.field)}</div>
              </div>
              {!item.isDragForbid ? (
                <div class={$class.fieldItemRight}>
                  <PtIcon icon="SolidMove"></PtIcon>
                </div>
              ) : (
                ''
              )}
            </div>
          )
        })
      } else {
        return <div class={$class.noFound}>{this.$tc('fieldManage.noFound')}</div>
      }
    },
  },
  render() {
    return (
      <div class={$class.container}>
        <div
          class={[$class.fieldIconContain, 'cursor-pointer text-sm', this.disabled ? 'cursor-not-allowed' : '']}
          onClick={this.onShowDialog}
          data-testid="b-field-manage"
        >
          <PtIcon icon="SolidSee" class={this.disabled ? 'text-gray-55' : 'text-gray-450'}></PtIcon>
          <span class={[$class.title, this.disabled ? 'text-gray-55' : 'text-text-default']}>{this.$tc('fieldManage.title')}</span>
        </div>
        {this.isShowPopup ? (
          <GDialog
            visible
            title={this.$tc('fieldManage.title')}
            cancelText={this.$tc('fieldManage.cancelText')}
            confirmText={this.$tc('fieldManage.confirmText')}
            width="560px"
            showFooter={!!this.manageData.fieldList.length}
            closeOnClickCancel={false}
            onClose={this.onClickCancel}
            onCancel={this.onClickCancel}
            onConfirm={this.onColumnFieldsChange}
          >
            <div style="height:500px" slot="default">
              <div class={$class.searchInput}>
                <ElInput
                  placeholder={this.$tc('fieldManage.inputPlaceholder')}
                  prefix-icon="el-icon-search"
                  v-model={this.searchContent}
                  clearable
                  autofocus
                ></ElInput>
              </div>
              {this.renderToggleShowFieldContent()}
              <Draggable
                v-model={this.manageData.fieldList}
                animation="300"
                disabled={this.draggable}
                filter=".forbid"
                move={this.onMove}
                class={$class.dragContain}
              >
                {this.renderDraggableContent()}
              </Draggable>
            </div>
          </GDialog>
        ) : (
          ''
        )}
      </div>
    )
  },
})
