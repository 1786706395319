import {
  IAggregationData,
  IAggregationParams,
  IBaseClinicalResult,
  IBaseDictItem,
  IClinicalResultBaseLine,
  IClinicalResultDetail,
  IClinicalResultOutcomeMeasureFlow,
  IClinicalResultParticipantFlow,
  ISearchParams,
} from '@patsnap/synapse_common_interface'
import { AxiosRequestConfig } from 'axios'
import { ISearchResult } from '../../types'
import { transformAggregationResponse } from '../../utils/business/aggregationTransform/base'
import { transformClinicalResultAggregationSingleResult } from '../../utils/business/aggregationTransform/clinical-result'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import { BaseApi } from './baseApi'

export class ClinicalResultApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getClinicalResultDetail(params: { id: string }): Promise<IHttpWrap<IClinicalResultDetail>> {
    const rt: IHttpWrap<IClinicalResultDetail> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/ct_result/${params.id}`),
      method: 'GET',
      cache: true,
    })
    return rt
  }

  async getClinicalResultParticipantFlow(params: { id: string }): Promise<IHttpWrap<IClinicalResultParticipantFlow>> {
    const rt: IHttpWrap<IClinicalResultParticipantFlow> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/ct_result/participant_flow/${params.id}`),
      method: 'GET',
      cache: true,
    })
    return rt
  }

  async getClinicalResultBaseline(params: { id: string }): Promise<IHttpWrap<IClinicalResultBaseLine>> {
    const rt: IHttpWrap<IClinicalResultBaseLine> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/ct_result/baseline/${params.id}`),
      method: 'POST',
      cache: true,
    })
    return rt
  }

  async getClinicalResultOutcomeMeasure(params: { id: string }): Promise<IHttpWrap<IClinicalResultOutcomeMeasureFlow>> {
    const rt: IHttpWrap<IClinicalResultOutcomeMeasureFlow> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/ct_result/endpoint/${params.id}`),
      method: 'POST',
      cache: true,
    })
    return rt
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IClinicalResultDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/ct_result/search_for_count'),
      cache: true,
    })
  }

  /**
   * 临床结果搜索列表，计搜索次数
   */
  async getClinicalTrialResultWithCalcLimits(
    params: ISearchParams,
    config?: AxiosRequestConfig
  ): Promise<IHttpWrap<ISearchResult<IBaseClinicalResult>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/ct_result'),
      cache: true,
      data: params,
      trackingName: 'CLINICAL_RESULT_LIST',
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }

  /**
   * 临床结果搜索列表，不计搜索次数
   */
  async getClinicalTrialResult(params: ISearchParams, config?: AxiosRequestConfig): Promise<IHttpWrap<ISearchResult<IBaseClinicalResult>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/ct_result/search'),
      cache: true,
      data: params,
      trackingName: 'CLINICAL_RESULT_LIST',
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/ct_result/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformClinicalResultAggregationSingleResult))
  }

  async getMeeting(): Promise<IHttpWrap<Array<{ conference_id: string; conference_id_view: IBaseDictItem; if_hot?: boolean; year?: number }>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/ct_result/meeting'),
      method: 'GET',
      cache: true,
    })
  }

  async getClinicalResultCardData({ id }: { id: string }, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl(`pharmsnap_webapi/1.0/ct_result/card/${id}`),
      cache: true,
      method: 'GET',
    })
  }
}
