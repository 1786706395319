import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { GIcon, GLoading } from '@pharmsnap/shared/components'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { defineComponent, ref } from '@vue/composition-api'
import { GTooltip } from '../..'
import styles from './BKgEntrance.module.scss'
import { bKgEntranceProps } from './BKgEntranceProps'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BKgEntrance = defineComponent({
  name: 'BKgEntrance',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: bKgEntranceProps(),
  setup(props, ctx) {
    const interceptor = ref(false)
    const {
      getters: { isFreeUser, isBasicUser },
      actions,
    } = useAuthStore()
    const handleEnterClick = async () => {
      if (interceptor.value) return
      if (props.disabled || props.isLoading) return
      if (isFreeUser.value || (IS_CN_REGION && isBasicUser.value)) {
        actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_KG, trigger_point: 'ENTER_KG' })
        return
      }
      interceptor.value = true
      await props.handleEnter(props.entity as any)
      interceptor.value = false
    }
    return {
      handleEnterClick,
    }
  },
  render() {
    return (
      <GTooltip
        placement={this.disabled ? 'top' : 'bottom'}
        content={this.disabled ? (this.$tc('disabledTooltip') as string) : (this.$t('enter') as string)}
        disabled={this.showTitle}
      >
        <div
          onClick={this.handleEnterClick}
          data-testid="b-kg-entrance"
          class={[
            'h-8 rounded inline-flex items-center text-sm hover:bg-gray-30 pr-1 pl-1',
            this.disabled || this.isLoading ? ['cursor-not-allowed', styles.disabled] : 'cursor-pointer',
          ]}
        >
          {this.isLoading ? (
            <div class="relative w-6 h-6">
              <GLoading size={20}></GLoading>
            </div>
          ) : (
            <GIcon svgName="KgExploration" color={this.disabled ? '#b3bac5' : '#495973'} size={this.iconSize}></GIcon>
          )}
          {this.showTitle && <span class="pl-1">{this.$tc('enter')}</span>}
        </div>
      </GTooltip>
    )
  },
})
