/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BasicHorizontalBarChart } from '..'
import { AXIS_LINE_COLOR } from '../constants'
import { ECOption } from '../core/plot'
import { Bar } from './bar'
import { generateRectTooltipFormatter } from './config'

export class HorizontalBar extends Bar {
  public type = 'horizontal-bar'

  protected getDefaultOption(options: ECOption): ECOption {
    const chartOption = super.getDefaultOption(options)

    const yAxis = chartOption.yAxis
    const xAxis = chartOption.xAxis

    if (yAxis) {
      if (Array.isArray(yAxis)) {
        chartOption.yAxis = yAxis.map((axis) => ({ ...axis, splitLine: { show: false }, axisTick: { show: false } }))
      } else {
        // @ts-ignore
        chartOption.yAxis = { ...yAxis, splitLine: { show: false }, axisTick: { show: false } }
      }
    }

    if (xAxis) {
      if (Array.isArray(xAxis)) {
        chartOption.xAxis = xAxis.map((axis) => ({ ...axis, splitLine: { show: true, lineStyle: { color: AXIS_LINE_COLOR, type: 'dashed' } } }))
      } else {
        // @ts-ignore
        chartOption.xAxis = { ...xAxis, splitLine: { show: true, lineStyle: { color: AXIS_LINE_COLOR, type: 'dashed' } } }
      }
    }

    return chartOption
  }

  // @ts-ignore
  protected adaptor(param: BasicHorizontalBarChart): ECOption {
    // @ts-ignore
    const chartOption = super.adaptor(param)
    const { barWidth, labelPadding, inside = false, tooltip: tooltipParam } = param
    const series = chartOption.series
    const yAxis = chartOption.yAxis
    const xAxis = chartOption.xAxis
    const tooltip = chartOption.tooltip

    if (tooltip && series && Array.isArray(series) && series.length > 0) {
      if (Array.isArray(tooltip)) {
        tooltip.forEach((i) => {
          i.formatter =
            tooltipParam?.formatter ||
            generateRectTooltipFormatter(
              series.map(() => param.xAxis.dataType || { valueType: 'text' }),
              {
                horizontal: true,
                extraInfoFormatter: param.extraInfoTooltipFormatter,
              }
            )
        })
      } else {
        tooltip.formatter =
          tooltipParam?.formatter ||
          generateRectTooltipFormatter(
            series.map(() => param.xAxis.dataType || { valueType: 'text' }),
            {
              horizontal: true,
              extraInfoFormatter: param.extraInfoTooltipFormatter,
            }
          )
      }
    }

    if (series && Array.isArray(series) && series.length > 0 && typeof barWidth !== 'undefined') {
      series.forEach((item) => {
        // @ts-ignore
        item.barWidth = barWidth
      })
    }

    if (yAxis) {
      if (Array.isArray(yAxis)) {
        if (typeof labelPadding !== 'undefined') {
          yAxis.forEach((item) => {
            // @ts-ignore
            item.axisLabel = { ...item.axisLabel, margin: 0, padding: labelPadding, inside, verticalAlign: inside ? 'bottom' : '' }
          })
        }
      } else {
        if (typeof labelPadding !== 'undefined') {
          // @ts-ignore
          yAxis.axisLabel = { ...item.axisLabel, margin: 0, padding: labelPadding, inside, verticalAlign: inside ? 'bottom' : '' }
        }
      }
    }

    if (param.xAxis && param.xAxis.dataType && param.xAxis.dataType.valueType && param.xAxis.dataType.valueType === 'count') {
      if (xAxis) {
        if (Array.isArray(xAxis)) {
          xAxis.forEach((item) => {
            // @ts-ignore
            item.minInterval = 1
          })
        } else {
          // @ts-ignore
          xAxis.minInterval = 1
        }
      }
    }

    return chartOption
  }
}
