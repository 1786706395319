import { IAutoCompleteType } from '@patsnap/synapse_common_interface'

export const autoCompleteTypeLangMap: Partial<Record<IAutoCompleteType, { cn: string; en: string }>> = {
  DrugType: {
    cn: '药物类型',
    en: 'Drug Type',
  },
  Target: {
    cn: '靶点',
    en: 'Target',
  },
  Disease: {
    cn: '适应症',
    en: 'Indication',
  },
  Mechanism: {
    cn: '作用机制',
    en: 'Mechanism',
  },
  Organization: {
    cn: '机构',
    en: 'Organization',
  },
  Drug: {
    cn: '药物',
    en: 'Drug',
  },
}
