import { VUE_APP_DOMAIN_URL } from '@pharmsnap/pharmsnap-web/config'
import { getGlobalMarvinImageExporter } from '@pharmsnap/pharmsnap-web/utils/marvin'
import { computed } from '@vue/composition-api'
import { MaybeRefOrGetter, toValue, useAsyncState } from '@vueuse/core'
import { partial } from 'lodash'

export function useStructureImgExporter(options: {
  source: MaybeRefOrGetter<string>
  imgWidth?: MaybeRefOrGetter<number>
  imgHeight?: MaybeRefOrGetter<number>
}) {
  const {
    state: structureSvgStr,
    isLoading,
    execute,
  } = useAsyncState(exportStructureSvg, '', {
    immediate: false,
    resetOnExecute: false,
    throwError: true,
  })

  const structureSvgBase64 = computed(() => {
    return structureSvgStr.value ? `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(structureSvgStr.value)))}` : ''
  })

  const structureSvgBackgroundImage = computed(() => {
    return structureSvgStr.value ? `url("data:image/svg+xml,${encodeURIComponent(structureSvgStr.value)}")` : ''
  })

  async function exportStructureSvg(): Promise<string> {
    const sourceVal = toValue(options.source)
    const width = toValue(options.imgWidth) || 200
    const height = toValue(options.imgHeight) || 200
    if (!sourceVal) return ''
    const editorUrl = `/marvin_latest/editor.html`
    const exporter = await getGlobalMarvinImageExporter(editorUrl, width, height)
    return await exporter.render(sourceVal)
  }

  return {
    structureSvgStr,
    structureSvgBase64,
    structureSvgBackgroundImage,
    isLoading,
    exeExportStructureSvg: partial(execute, 0),
  }
}
