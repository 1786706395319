import { BDrugTypeTagItem } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ILang } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BDrugTypeFetchListCard = defineComponent({
  name: 'BDrugTypeFetchListCard',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const lang = computed(() => {
      return ins?.proxy.$i18n.locale.toUpperCase() as ILang
    })
    const { loading, data } = useRequest(
      {
        requestFn: sharedCtx.service.dictionary.getBatchDictionary.bind(sharedCtx.service.dictionary),
      },
      props.ids
    )
    watch(loading, () => {
      ctx.emit('update')
    })
    return {
      loading,
      data,
      lang,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {(this.data || []).map((item, index) => {
            return <BDrugTypeTagItem class="mr-1 mb-1" data={item}></BDrugTypeTagItem>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
