/* eslint-disable @typescript-eslint/no-explicit-any */
import '@patsnap-ui/icon/assets/solid/settings.svg'
import { query2Text, structureQuery2Text } from '@patsnap/synapse_common_business'
import { ICreateUpdateAlertParams, IQueryGroupType, ISearchParams, ISort } from '@patsnap/synapse_common_interface'
import { E_ROUTER_QUERY, E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useUpgrade } from '@pharmsnap/pharmsnap-web/views/Upgrade/hook/useUpgrade'
import { BEmailAlertWithSwitchV2, BLS360AskMeCard, BWorkspaceButton, GTranslateSwitch } from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { FREE_USER_LIST_PAGE_PAGE_SIZE_LIST, LIST_PAGE_PAGE_SIZE_LIST } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { useGlobalCalculateRights } from '@pharmsnap/shared/src/composition/useCalculateRights'
import { ElCheckbox } from '@pharmsnap/shared/src/element-ui'
import {
  E_COMMON_LIST_MODE,
  IDrugDetail,
  IFieldLangMap,
  IGroupSearchParams,
  ILoadingService,
  IPatentService,
  ISearchResult,
  ITranslateLang,
} from '@pharmsnap/shared/types'
import { IFieldShowItem } from '@pharmsnap/shared/types/account'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { computed, defineComponent, getCurrentInstance, h, onMounted, onUnmounted, PropType, ref, watch } from '@vue/composition-api'
import { cloneDeep, isEqual, uniq } from 'lodash'
import { from, Subject, switchMap, tap } from 'rxjs'
import { IVueI18n } from 'vue-i18n'
import type { ColumnInfo, TableOptions } from 'vxe-table'
import { GEmpty, GTooltip } from '../..'
import { IQueryService } from '../../../composition/useQueryService'
import { FreeListExceedLimitCode, FreePatentSearchLimitCode } from '../../../config/business'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GListSkeleton } from '../../ui/GListSkeleton/GListSkeleton'
import { GPagination } from '../../ui/GPagination/GPagination'
import { ISupportWorkspaceDataType } from '../BWorkspace/type'
import $style from './BcommonList.module.scss'
import { BCardViewType } from './components/BCardViewType/BCardViewType'
import { BExport } from './components/BExport/BExport'
import { BFieldManage } from './components/BFieldManage/BFieldManage'
import { BFreeLimit } from './components/BFreeLimit/BFreeLimit'
import { BSelectMenu } from './components/BSelectMenu/BSelectMenu'
import { SortCardButton } from './components/SortCardButton/SortCardButton'
import { VxeGridLoading } from './components/VxeGridLoading/VxeGridLoading'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { ICardRenderModel, ICommonListConfig, ICommonListFieldConfigMap, ICommonListService } from './types'
import { pickShowFiled } from './utils'

/** 头部支持文案隐藏的列表页 */
const supportHiddenFieldTextDataType: ISupportWorkspaceDataType[] = ['drug', 'clinical_trial', 'patent']

const vxeTableStaticConfig: TableOptions = {
  border: true,
  resizable: true,
  showOverflow: false,
  height: 'auto',
  align: 'left',
  rowConfig: {
    isHover: true,
  },
  cellClassName: $style.cellClass,
}
/**
 * 创建表格的列配置
 * @param params
 * @returns
 */
function createColumns(params: {
  /**
   * 列字段数据组
   */
  columnFields: string[]
  /**
   * 字段配置
   */
  fieldsConfigMap: Partial<Record<string, ICommonListFieldConfigMap>>
  /**
   * 字段翻译配置
   */
  fieldLangMap: Partial<IFieldLangMap>
  /**
   * 是否在最左侧显示checkbox
   */
  showCheckBox?: boolean
  checkboxSlots?: {
    header?: () => JSX.Element[] | undefined
    checkbox?: (data: { rowIndex: number; row: any }) => JSX.Element[] | undefined
  }
  $i18n: IVueI18n
  /**
   * 用户的原始机翻设置
   */
  customSettingTranslation?: ITranslateLang

  queryService: IQueryService
}): Partial<ColumnInfo>[] {
  const { columnFields, fieldsConfigMap, showCheckBox, fieldLangMap, $i18n, customSettingTranslation, queryService } = params
  const locale = $i18n.locale as I18nLang
  const columns: Partial<ColumnInfo>[] = columnFields.reduce((acc, curr) => {
    const fieldConfig = fieldsConfigMap[curr]
    const title = getFieldNameByLang(fieldLangMap, curr, locale)
    const showTitle = fieldConfig?.showTitle === false ? false : true
    if (fieldConfig) {
      const slotsConfig = {}
      if (fieldConfig.renderFn) {
        Object.assign(slotsConfig, {
          default: (data: { row: IDrugDetail }) => {
            if (fieldConfig.renderFn) {
              const rt = fieldConfig.renderFn(data.row, $i18n, customSettingTranslation)
              // vxeTable 必须接收 vNode数组
              return Array.isArray(rt) ? rt : [rt]
            }
            return null
          },
        })
      } else {
        console.error(`[BCommonList]:字段 "${curr}" 没有配置渲染方法,请检查`)
      }
      if (fieldConfig.tooltip) {
        Object.assign(slotsConfig, {
          header: () => {
            const tooltipContent =
              typeof fieldConfig.tooltip === 'function' ? fieldConfig.tooltip(queryService.state)[locale] : fieldConfig.tooltip?.[locale] || ''

            const tooltipTheme =
              typeof fieldConfig.tooltip === 'function' ? fieldConfig.tooltip(queryService.state).theme : fieldConfig.tooltip?.theme || 'default'

            if (!tooltipContent) {
              return showTitle ? [title] : []
            }
            return [
              <div class="inline-flex items-center">
                {showTitle ? <span>{title}</span> : <span>&nbsp;</span>}
                <GTooltip iconTheme={tooltipTheme} theme="light" class={showTitle ? 'ml-1' : ''} mode="icon">
                  <div slot="content" domPropsInnerHTML={tooltipContent}></div>
                </GTooltip>
              </div>,
            ]
          },
        })
      }
      acc.push({
        field: curr,
        width: fieldConfig.width,
        fixed: fieldConfig.fixed,
        title: showTitle ? title : '',
        showOverflow: false,
        slots: slotsConfig,
      })
    } else {
      console.error(`[BCommonList]:没有找到字段 "${curr}" 的列表配置,忽略渲染该字段,请检查配置`)
    }
    return acc
  }, [] as Partial<ColumnInfo>[])
  if (showCheckBox) {
    // https://xuliangzhan_admin.gitee.io/vxe-table/#/table/base/selection
    columns.unshift({
      type: 'checkbox',
      fixed: 'left',
      align: 'center',
      showOverflow: false,
      width: params.checkboxSlots ? '56' : '40',
      slots: params.checkboxSlots,
    })
  }
  return columns
}
export const BCommonList = defineComponent({
  name: 'BCommonList',
  props: {
    service: {
      type: Object as PropType<ICommonListService>,
      required: true,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    config: {
      type: Object as PropType<ICommonListConfig>,
      required: true,
    },
    mode: {
      type: String as PropType<E_COMMON_LIST_MODE>,
      default: E_COMMON_LIST_MODE.TABLE,
    },
    hasEmailAlert: {
      type: Boolean,
      default: false,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
    patentService: {
      required: true,
      type: Object as PropType<IPatentService>,
    },
    shouldHidePagination: {
      type: Boolean,
      default: false,
    },
    groupByValue: {
      type: String as PropType<IQueryGroupType>,
    },
    getSearchParams: {
      required: true,
      type: Function as PropType<() => ISearchParams | IGroupSearchParams>,
    },
    filterExpand: {
      type: Boolean,
    },
    querySortCountLimit: {
      type: Number,
    },
    querySortOptions: {
      type: Array as PropType<
        {
          field: string
          i18nKey: string
        }[]
      >,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, ctx) {
    const {
      state: { role, usageInfo },
      getters: { translation, isFreeUser, customSettingTranslation },
      actions,
    } = useAuthStore()
    const { rightsState, handleUpdateRights } = useGlobalCalculateRights()
    const { onViewPlans } = useUpgrade()
    const $route = ctx.root.$route
    watch(
      () => props.mode,
      () => {
        props.service.actions.setSelectAll(false)
        props.service.actions.cleanCheckedMap()
      }
    )
    const { locale } = useLocale()
    const searchStream$ = new Subject<string>()
    const ins = getCurrentInstance()
    const headerRef = ref<HTMLElement>()

    const checkboxSlots = computed(() => {
      if (props.config.showCheckBox) {
        if (!props.config.checkBoxDefaultShow) {
          return {
            header: () => {
              const rowIdKey = props.config.rowIdKey
              if (!rowIdKey) {
                console.error(' 列表缺少配置 "rowIdKey",请检查')
                return
              }

              const listData = props.service.state.listData
              // 已选中的id
              const checkedIds = Object.entries(props.service.state.checkedMap)
                .filter(([, value]) => !!value)
                .map(([key]) => key)
              // 当前页已选中的id
              const checkedIdsLength = checkedIds.filter((id) => listData.find((item) => item[rowIdKey] === id)).length
              const totalDataLength = uniq(listData.map((item) => item[rowIdKey])).length
              const disabled = totalDataLength === 0
              const checked = !disabled && totalDataLength === checkedIdsLength
              const indeterminate = checkedIdsLength > 0 && checkedIdsLength < totalDataLength

              return [
                <ElCheckbox
                  disabled={disabled}
                  value={checked}
                  indeterminate={checkedIdsLength > 0 && checkedIdsLength < totalDataLength}
                  onChange={() => sharedListeners['checkbox-all']({ checked: indeterminate ? true : !checked })}
                ></ElCheckbox>,
              ]
            },
            checkbox: (data: { rowIndex: number; row: any }) => {
              const { rowIndex, row } = data

              const rowIdKey = props.config.rowIdKey
              if (!rowIdKey) {
                console.error(' 列表缺少配置 "rowIdKey",请检查')
                return
              }
              const id = data.row[rowIdKey]
              const checked = !!props.service.state.checkedMap[id]

              // 合并后的表格，rowIndex 仍为合并前的下标，所以data上提供_rowIndex，保证序号正确
              const rowIndexPerPage = props.config.spanMethod ? row._rowIndex || rowIndex : rowIndex

              return [
                <div>
                  <ElCheckbox
                    value={checked}
                    class={['listCheckbox', checked ? '!block' : '']}
                    onChange={() => sharedListeners['checkbox-change']({ checked: !checked, row })}
                  ></ElCheckbox>
                  <span class={['listIndex', checked ? '!hidden' : '']}>
                    {(props.service.state.page - 1) * props.service.state.limit + rowIndexPerPage + 1}
                  </span>
                </div>,
              ]
            },
          }
        }
      }
      return undefined
    })

    const columns = computed(() => {
      const showColumnFields = pickShowFiled(props.service.state.columnFields)

      return createColumns({
        columnFields: showColumnFields,
        fieldsConfigMap: props.config.fieldsConfigMap,
        fieldLangMap: props.config.fieldLangMap,
        $i18n: ins?.proxy.$i18n as IVueI18n,
        showCheckBox: props.config.showCheckBox,
        customSettingTranslation: customSettingTranslation.value,
        queryService: props.queryService,
        checkboxSlots: checkboxSlots.value,
      })
    })
    const pageSizes = computed(() => {
      return isFreeUser.value ? FREE_USER_LIST_PAGE_PAGE_SIZE_LIST : LIST_PAGE_PAGE_SIZE_LIST
    })
    const vxeGridProps = computed<TableOptions>(() => {
      const rt = {}
      Object.assign(rt, vxeTableStaticConfig, {
        columns: columns.value,
        data: props.service.state.listData,
        rowId: props.config.rowIdKey,
        spanMethod: props.config?.spanMethod,
      })
      if (props.config.disableYVirtualScroll) {
        Object.assign(rt, {
          scrollY: { enabled: false },
        })
      }
      if (props.config.showCheckBox) {
        if (props.config.rowIdKey) {
          Object.assign(rt, {
            rowId: props.config.rowIdKey,

            checkboxConfig: {
              checkRowKeys: Object.entries(props.service.state.checkedMap).reduce((acc, curr) => {
                const [id, value] = curr
                if (value) {
                  return [...acc, id]
                }
                return acc
              }, [] as string[]),
              reserve: true,
              // highlight: true,
            },
          })
        } else {
          console.log(`列表缺少配置 "rowIdKey",请检查`)
        }
      }

      return rt
    })
    const checkedIdList = computed(() => {
      const checkedMap = props.service.state.checkedMap
      return Object.keys(checkedMap).reduce((acc, curr) => (checkedMap[curr] ? [...acc, ...curr.split(',')] : acc), [] as string[])
    })
    const showFieldText = ref(true)
    const sharedListeners = {
      'checkbox-change': (data: { checked: boolean; row: any }) => {
        const rowIdKey = props.config.rowIdKey
        if (!rowIdKey) {
          console.error(' 列表缺少配置 "rowIdKey",请检查')
          return
        }
        const id = data.row[rowIdKey]
        props.service.actions.setCheckedMap({
          [id]: data.checked ? data.row : null,
        })
        if (props.mode === E_COMMON_LIST_MODE.CARD) {
          props.service.actions.setSelectAll(props.service.state.listData.every((item) => props.service.state.checkedMap[item[rowIdKey]]))
        }
      },
      'checkbox-all': (data: { checked: boolean }) => {
        const rowIdKey = props.config.rowIdKey
        if (!rowIdKey) {
          console.error(' 列表缺少配置 "rowIdKey",请检查')
          return
        }
        props.service.actions.setCheckedMap(
          props.service.state.listData.reduce((acc, curr) => {
            return {
              ...acc,
              [curr[rowIdKey]]: data.checked ? curr : null,
            }
          }, {} as Record<string, any>)
        )
        if (props.mode === E_COMMON_LIST_MODE.CARD) {
          props.service.actions.setSelectAll(data.checked)
        }
      },
    }
    const cardViewType = computed({
      get() {
        return props.service.state.cardViewType
      },
      set(value: ICardRenderModel) {
        props.service.actions.setCardViewType(value)
      },
    })
    init()

    watch(
      () => [
        props.queryService.state.query.filter,
        props.queryService.state.query.must,
        props.queryService.state.collapse,
        props.queryService.state.orgRollup,
        props.queryService.state.structureQuery,
      ],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        initColumnFields()
        resetPageAndReSearch()
      }
    )

    /**
     * 监听卡片的渲染模式,重新发起请求
     */
    watch(cardViewType, (value) => {
      if (props.config.dataType) {
        sharedCtx.service.account.setUserFieldConfig({
          data_type: props.config.dataType,
          view_type: value,
        })
        search()
      }
    })
    watch(
      () => props.service.state.checkedMap,
      (val) => {
        if (props.service.state.checkedMaxCount === -1) return
        const checkedData = Object.keys(val).reduce((acc, curr) => (val[curr] ? [...acc, curr] : acc), [] as string[])
        if (props.service.state.checkedMaxCount && checkedData.length > props.service.state.checkedMaxCount && props.config.useOverMaxCountFn) {
          props.config.useOverMaxCountFn({ locale: locale.value, limit: props.service.state.checkedMaxCount })
        }
      }
    )
    async function init() {
      subscribeSearchStream()
      await initColumnFields()
      search()
      initCheckCount()
    }
    /**
     * 初始化checkbox 状态
     */
    function initCheckCount() {
      actions.syncUserUsage().then(() => {
        if (usageInfo.value) {
          const maxCount = props.config.setCheckMaxCountFn?.(usageInfo.value)
          maxCount && props.service.actions.setCheckedMaxCount(maxCount)
        }
      })
      if (!props.config.showCheckBox) {
        return
      }
      if (!props.config.setCheckMaxCountFn) {
        console.warn(`${props.config.dataType}设置了showCheckBox,但是没有配置setCheckMaxCountFn方法,将忽略校验check数量`)
        return
      }
    }

    function getColumnFields() {
      const columnFields =
        typeof props.config.columnFields === 'function' ? props.config.columnFields(props.queryService.state) : props.config.columnFields

      return columnFields
    }

    /**
     * 初始化需要展示的字段状态
     */
    async function initColumnFields() {
      if (!props.config.fieldManageOptions || !props.config.fieldManageOptions.visible || !props.config.fieldManageOptions.dataType) {
        setLocalManageFields()
      } else {
        const fields = await setRemoteManageFields(props.config.fieldManageOptions.dataType)
        if (fields) {
          props.service.actions.setColumnFields(fields)
        } else {
          setLocalManageFields()
        }
      }

      function setLocalManageFields() {
        props.service.actions.setColumnFields(
          getColumnFields().map((item) =>
            typeof item === 'string'
              ? {
                  field: item,
                  show: true,
                }
              : item
          )
        )
      }

      async function setRemoteManageFields(dataType: string) {
        const rt = await sharedCtx.service.account.getUserFieldConfig(dataType)
        if (!rt.success) return void 0
        if (rt.data.view_type) {
          props.service.actions.setCardViewType(rt.data.view_type)
        }
        if (rt.data.field?.length) {
          const fieldShowItems: IFieldShowItem[] = rt.data.field
          const remoteFields = fieldShowItems.map((i) => i.field)
          //  检测用户参数是否 完整，将缺失数据添加进去
          const notExcitedFields = Object.keys(props.config.fieldsConfigMap).filter((field) => !remoteFields.includes(field))
          const columnFields = getColumnFields()
          const columnStrFields = columnFields.map((i) => (typeof i === 'string' ? i : i.field)) as string[]
          notExcitedFields.forEach((item) => {
            const foundIndex = columnFields.findIndex((i) => (typeof i === 'string' ? i === item : i.field === item))
            if (foundIndex !== -1) {
              const found = columnFields[foundIndex]
              const fieldShowItem = typeof found === 'string' ? { field: item, show: true } : { field: item, show: found.show }

              if (foundIndex === 0) {
                fieldShowItems.unshift(fieldShowItem)
              } else if (foundIndex === columnFields.length - 1) {
                fieldShowItems.push(fieldShowItem)
              } else {
                const leftField = columnFields[foundIndex - 1]
                const rightField = columnFields[foundIndex + 1]
                const leftIndex = fieldShowItems.findIndex((i) =>
                  typeof leftField === 'string' ? i.field === leftField : i.field === leftField.field
                )
                const rightIndex = fieldShowItems.findIndex((i) =>
                  typeof rightField === 'string' ? i.field === rightField : i.field === rightField.field
                )

                if (leftIndex !== -1 && rightIndex !== -1) {
                  fieldShowItems.splice(leftIndex + 1, 0, fieldShowItem)
                } else if (leftIndex === -1 && rightIndex !== -1) {
                  fieldShowItems.splice(rightIndex, 0, fieldShowItem)
                } else if (leftIndex !== -1 && rightIndex === -1) {
                  fieldShowItems.splice(leftIndex + 1, 0, fieldShowItem)
                }
              }
            }
          })

          if (!notExcitedFields.length) {
            return fieldShowItems.filter((i) => columnStrFields.includes(i.field))
          }

          return fieldShowItems
        }

        return void 0
      }
    }
    // true 限制，false不限制
    const freeUserLimitStatus = ref(false)
    function showLimitFreeUser() {
      freeUserLimitStatus.value = true
    }
    function hideLimitFreeUser() {
      freeUserLimitStatus.value = false
    }
    function freeUserErrorIntercept(res: IHttpWrap<ISearchResult<any>>): ISearchResult<any> {
      if ((res.data as any)?.numeric_error_code === FreeListExceedLimitCode) {
        showLimitFreeUser()
        return (
          (res.data as any).error_params || {
            total: 0,
            items: [],
          }
        )
      } else if ((res.data as any)?.numeric_error_code === FreePatentSearchLimitCode) {
        actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT, trigger_point: 'LIST_OVER_LIMIT' })
        return (
          (res.data as any).error_params || {
            total: 0,
            items: [],
          }
        )
      } else {
        hideLimitFreeUser()
        return res.data as ISearchResult<any>
      }
    }
    function onUpgrade() {
      onViewPlans('LIST_OVER_LIMIT')
    }

    function search() {
      searchStream$.next(Date.now().toString())
    }
    function subscribeSearchStream() {
      searchStream$
        .pipe(
          tap(() => {
            ctx.emit('beforeSearch')
            props.loadingService.action.setLoading(true)
            props.service.actions.setLoading(true)
            props.service.actions.clearListData()
          }),
          switchMap(() => {
            const searchParams = props.getSearchParams()
            const needsCalcLimits = !!rightsState.value[props.queryService.state.queryId]
            needsCalcLimits && handleUpdateRights(props.queryService.state.queryId)
            return from(props.config.getDataFn(searchParams, isFreeUser.value ? freeUserErrorIntercept : undefined, undefined, needsCalcLimits))
          })
        )
        .subscribe((rt) => {
          props.loadingService.action.setLoading(false)
          props.service.actions.setLoading(false)
          if (!rt) return
          const listData = Object.freeze(rt.items?.map((item) => Object.freeze(item)) || [])
          if (props.config.groupBy === 'dev_status') {
            props.service.actions.setListDataInFrame(listData)
          } else {
            props.service.actions.setListData(listData)
          }
          ctx.emit('afterSearch', listData)
          props.loadingService.action.setTotal(rt.total)
          props.loadingService.action.setUnCollapseTotal(rt.uncollapse_total)
          props.loadingService.action.setSubTotal(rt.sub_total)
          if (props.mode === E_COMMON_LIST_MODE.CARD) {
            props.service.actions.setSelectAll(listData.every((item) => Object.keys(props.service.state.checkedMap).includes(item.patent_id)))
          }
        })
    }
    function resetPageAndReSearch() {
      props.service.actions.cleanCheckedMap()
      props.service.actions.setPage(1)
      search()
    }
    function onCurrentChange(data: number) {
      props.service.actions.setPage(data)
      search()
    }
    function onSizeChange(data: number) {
      props.service.actions.setPage(1)
      props.service.actions.setLimit(data)
      search()
    }
    function onSortChange(data: ISort[]) {
      if (props.config.onBeforeSort) {
        const val = props.config.onBeforeSort({
          isFreeUser: isFreeUser.value,
        })
        if (!val.valid) {
          return val.upgrade ? actions.changeUpgrade(val.upgrade) : void 0
        }
      }
      props.service.actions.setPage(1)
      props.queryService.actions.setSort(data)
      search()
    }
    async function onColumnFieldsChange(fields: IFieldShowItem[]) {
      props.service.actions.setColumnFields(fields)
      if (props.config.fieldManageOptions?.dataType) {
        sharedCtx.service.account.setUserFieldConfig({
          data_type: props.config.fieldManageOptions.dataType,
          field: fields,
        })
      }
    }
    function onUnselectClick() {
      props.service.actions.cleanCheckedMap()
      if (props.mode === E_COMMON_LIST_MODE.TABLE) {
        const commonListVxeGrid = ins?.proxy.$refs.commonListVxeGrid
        commonListVxeGrid && (commonListVxeGrid as any).clearCheckboxRow()
      }
      if (props.mode === E_COMMON_LIST_MODE.CARD) {
        props.service.actions.setSelectAll(false)
      }
    }

    function onBack() {
      const curPage = props.service.state.page
      const targetPage = curPage > 1 ? curPage - 1 : curPage
      onCurrentChange(targetPage)
    }

    const updateFieldTextStatus = () => {
      const headerContainerWidth = headerRef.value?.offsetWidth
      const dataType = props.config.dataType
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (dataType && supportHiddenFieldTextDataType.includes(dataType) && headerContainerWidth && headerContainerWidth < 1021) {
        showFieldText.value = false
        return
      }
      showFieldText.value = true
    }
    const defaultEmailAlertConfig = computed(() => {
      if (props.config.emailAlertConfig?.queryAlertConfig?.alertType) {
        const dataType = props.config.groupBy || props.config.dataType || props.queryService.state.data_type
        const rt: ICreateUpdateAlertParams = {
          alert_type: props.config.emailAlertConfig?.queryAlertConfig?.alertType,
          query_id: props.queryService.state.queryId,
          from_query_id: ($route.query[E_ROUTER_QUERY.FROM_QUERY_ID] as string) || '',
          query: {
            collapse: props.queryService.state.collapse,
            org_roll_up: props.queryService.state.orgRollup,
            sort: props.queryService.state.sort,
            query: props.queryService.state.query,
            ...(props.queryService.state.searchQueryType === 'STRUCTURE'
              ? {
                  query_type: props.queryService.state.searchQueryType,
                  structure_query: props.queryService.state.structureQuery,
                }
              : undefined),
          },
          query_display_name: {
            cn: {
              must:
                props.queryService.state.searchQueryType === 'STRUCTURE'
                  ? structureQuery2Text(props.queryService.state.structureQuery || {}, 'cn')
                  : query2Text(props.queryService.state.query.must, 'cn', dataType),
              filter: query2Text(props.queryService.state.query.filter, 'cn', dataType),
            },
            en: {
              must:
                props.queryService.state.searchQueryType === 'STRUCTURE'
                  ? structureQuery2Text(props.queryService.state.structureQuery || {}, 'en')
                  : query2Text(props.queryService.state.query.must, 'en', dataType),
              filter: query2Text(props.queryService.state.query.filter, 'en', dataType),
            },
          },
        }
        return rt
      }
      return undefined
    })

    watch(() => props.filterExpand, updateFieldTextStatus)

    onMounted(() => {
      updateFieldTextStatus()
      addEventListener('resize', updateFieldTextStatus)
    })

    onUnmounted(() => {
      removeEventListener('resize', updateFieldTextStatus)
    })

    return {
      vxeGridProps,
      sharedListeners,
      freeUserLimitStatus,
      cardViewType,
      pageSizes,
      defaultEmailAlertConfig,
      resetPageAndReSearch,
      headerRef,
      showFieldText,
      checkedIdList,
      onCurrentChange,
      onSizeChange,
      onSortChange,
      onColumnFieldsChange,
      onUnselectClick,
      search,
      onUpgrade,
      onBack,
      locale,
    }
  },
  methods: {
    renderExport() {
      if (!this.config.export) {
        return null
      }
      if (!this.config.fromModule) {
        console.error(' 列表缺少配置 "fromModule",请检查')
        return null
      }
      if (!this.config.getExportDisplayCnEn) {
        console.error(' 列表缺少配置 "getExportDisplayCnEn",请检查')
        return null
      }
      return (
        <div>
          {
            <BExport
              class="mr-2 h-8 rounded hover:bg-gray-30 p-1"
              showText={this.showFieldText}
              checkedMap={this.service.state.checkedMap}
              commonListConfig={this.config}
              searchAllLength={this.loadingService.state.total}
              queryService={this.queryService}
              disabled={this.service.state.loading || (!this.service.state.loading && this.service.state.listData.length === 0)}
              onUnselectClick={this.onUnselectClick}
            ></BExport>
          }
        </div>
      )
    },
    renderWorkspace() {
      if (this.config.workspace) {
        const searchParams = cloneDeep(this.getSearchParams()) as ISearchParams

        return (
          <BWorkspaceButton
            dataType={this.config.dataType as ISupportWorkspaceDataType}
            showText={this.showFieldText}
            disabled={this.service.state.loading || (!this.service.state.loading && this.service.state.listData.length === 0)}
            total={this.loadingService.state.total}
            selectedIds={this.checkedIdList}
            searchParams={searchParams}
            class="ml-4"
          ></BWorkspaceButton>
        )
      }
      return null
    },
    renderAnalytics() {
      if (!this.$scopedSlots.analytics) return null

      return this.$scopedSlots.analytics({
        showText: this.showFieldText,
        loading: this.service.state.loading,
        total: this.loadingService.state.total,
        unCollapseTotal: this.loadingService.state.uncollapse_total,
        getSearchParams: this.getSearchParams,
        checkedMap: this.service.state.checkedMap,
      })
    },
    renderCheckDialog() {
      return this.config.showCheckDialog ? (
        <div>
          <BSelectMenu
            dataType={this.config.dataType}
            menus={this.config.checkMenus || []}
            checkedMap={this.service.state.checkedMap}
            commonListConfig={this.config}
            searchAllLength={this.loadingService.state.total}
            queryService={this.queryService}
            commonListService={this.service}
            groupByValue={this.groupByValue}
            disabled={this.service.state.loading}
            onUnselectClick={this.onUnselectClick}
            navToAnalyticsFunc={this.patentService.actions.navToAnalyticsFunc}
          ></BSelectMenu>
        </div>
      ) : null
    },
    renderEnterKG() {
      return this.$scopedSlots.kgEntrance?.(this.showFieldText)
    },
    renderEmailAlert() {
      if (!this.config.emailAlertConfig?.queryAlertConfig?.alertType) {
        return null
      }
      if (!this.defaultEmailAlertConfig) {
        return null
      }
      return (
        <BEmailAlertWithSwitchV2
          key={this.queryService.state.queryId}
          disabled={this.service.state.loading}
          searchResultCount={this.loadingService.state.total}
          defaultEmailAlertConfig={this.defaultEmailAlertConfig}
          class="ml-2"
        ></BEmailAlertWithSwitchV2>
      )
    },
    renderSettingBtn() {
      return (
        <span
          data-testid="b-common-list__setting-btn"
          onClick={() => !this.service.state.loading && this.$emit('showSetting')}
          class={[$style.settingBtn, this.service.state.loading ? $style.settingBtnDisabled : '']}
        >
          <GIcon svgName="SolidSettings" size={24}></GIcon>
        </span>
      )
    },
    renderContentByLimitFreeUser() {
      return this.freeUserLimitStatus ? (
        <div>
          <div class="absolute" style="top: 1px; bottom: 0; left: 0; right: 0;">
            <BFreeLimit
              maxLimit={this.config.freeMaxLength || 100}
              isShow={this.freeUserLimitStatus}
              total={this.loadingService.state.total}
              svgName="Upgrade"
              onUpgrade={this.onUpgrade}
              onBack={this.onBack}
            ></BFreeLimit>
          </div>
        </div>
      ) : null
    },
  },
  render() {
    return (
      <div class={['flex flex-col h-full', $style.main]} data-testid="b-common-list">
        <div class="flex-1 flex flex-col overflow-hidden">
          <div class="header flex items-center px-5" ref="headerRef">
            <div class="flex-1">
              <div class={['flex items-center']}>
                {!!this.config.sortConfig?.sortOptions?.length && (
                  <SortCardButton
                    class="mr-2 mt-1 mb-1 rounded hover:bg-gray-30 p-1 text-sm"
                    limitCount={this.querySortCountLimit || this.config.sortConfig.sortLimitCount}
                    value={this.queryService.state.sort}
                    disabled={this.service.state.loading}
                    onChange={this.onSortChange}
                    sortOptions={this.querySortOptions || this.config.sortConfig.sortOptions}
                    booleanSortOption={this.config.sortConfig.booleanSortOption}
                  ></SortCardButton>
                )}
                {this.config.fieldManageOptions &&
                  this.config.fieldManageOptions.visible &&
                  this.config.fieldManageOptions.dataType &&
                  this.mode === E_COMMON_LIST_MODE.TABLE && (
                    <BFieldManage
                      class="mr-2 mt-1 mb-1 rounded hover:bg-gray-30 p-1"
                      userConfigFields={this.service.state.columnFields}
                      disabled={this.service.state.loading}
                      fieldLangMap={this.config.fieldLangMap}
                      fieldsConfigMap={this.config.fieldsConfigMap}
                      onColumnFieldsChange={this.onColumnFieldsChange}
                    ></BFieldManage>
                  )}
              </div>
            </div>
            <div class="flex items-center">
              {this.renderExport()}
              {this.renderCheckDialog()}
              {this.renderAnalytics()}
              {this.renderEnterKG()}
              {this.renderEmailAlert()}
              {this.config.canTranslate && (
                <div class="h-8">
                  <GTranslateSwitch onChange={this.search} customRefStyle={{ height: '32px' }} class="ml-1" />
                </div>
              )}
              {this.config.canManageCardViewType && (
                <div class="h-8 ml-2">
                  <BCardViewType v-model={this.cardViewType}></BCardViewType>
                </div>
              )}
              {this.config.canManageSetting && this.renderSettingBtn()}
              {this.renderWorkspace()}
            </div>
          </div>

          {this.service.state.loading ? (
            this.mode === E_COMMON_LIST_MODE.TABLE ? (
              <VxeGridLoading></VxeGridLoading>
            ) : (
              <div style="padding: 17px" class="space-y-4">
                {Array.from({ length: 19 }).map(() =>
                  this.config.fromModule === 'Patent' ? <GListSkeleton type="withImg"></GListSkeleton> : <GListSkeleton></GListSkeleton>
                )}
              </div>
            )
          ) : (
            <div class={['flex-1 overflow-hidden relative', { 'border-t border-gray-40': this.mode === E_COMMON_LIST_MODE.CARD }]}>
              {{
                [E_COMMON_LIST_MODE.TABLE]: () => [
                  <vxe-grid
                    ref="commonListVxeGrid"
                    {...{
                      props: this.vxeGridProps,
                      on: this.sharedListeners,
                    }}
                  >
                    {this.$slots.customTableEmpty ? <template slot="empty">{this.$slots.customTableEmpty}</template> : <GEmpty slot="empty"></GEmpty>}
                  </vxe-grid>,
                  this.$slots.customTableLimitContent || null,
                ],
                [E_COMMON_LIST_MODE.CARD]: () =>
                  this.service.state.listData.length === 0
                    ? this.$slots.customTableEmpty || <GEmpty></GEmpty>
                    : h(this.config.renderCardModeComponent, {
                        props: {
                          data: this.service.state.listData,
                          viewType: this.service.state.cardViewType,
                          queryId: this.queryService.state.queryId,
                          page: this.service.state.page,
                          limit: this.service.state.limit,
                          sort: this.queryService.state.sort,
                          checkedMap: this.service.state.checkedMap,
                          selectAll: this.service.state.selectAll,
                        },
                        on: this.sharedListeners,
                        scopedSlots: {
                          customLimitContent: () => this.$slots.customLimitContent,
                        },
                      }),
              }[this.mode]()}
              {this.renderContentByLimitFreeUser()}
              <BLS360AskMeCard class="absolute z-10 bottom-2 right-[120px]" queryText={this.queryService.state.queryMustText}></BLS360AskMeCard>
            </div>
          )}
        </div>
        {!this.shouldHidePagination && (
          <div class="h-12 text-center p-2">
            {!this.service.state.loading && this.loadingService.state.total > 0 && (
              <GPagination
                {...{
                  attrs: {
                    background: true,
                    layout: this.config.paginationLayout || 'prev, pager, next, sizes',
                    total: this.config.paginationGetTotalFn
                      ? this.config.paginationGetTotalFn(this.loadingService.state)
                      : this.loadingService.state.total,
                    currentPage: this.service.state.page,
                    pageSize: this.service.state.limit,
                    pageSizes: this.pageSizes,
                    hideOnSinglePage: true,
                  },
                  on: {
                    'current-change': this.onCurrentChange,
                    'size-change': this.onSizeChange,
                  },
                  scopedSlots: {
                    default: () => this.$scopedSlots?.paginationSlot?.({ commonListService: this.service, loadingService: this.loadingService }),
                  },
                }}
              ></GPagination>
            )}
          </div>
        )}
      </div>
    )
  },
})
