import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { query2Text } from '@patsnap/synapse_common_business'
import { I18nLang } from '@patsnap/synapse_common_interface'
import '@pharmsnap/shared/assets/icon-svg/history.svg'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IHistory, IQuickHistory } from '@pharmsnap/shared/src/types/searchHistory'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'

export const BHistoryPanel = defineComponent({
  name: 'BHistoryPanel',
  props: {
    list: {
      type: Array as PropType<Array<IHistory> | Array<IQuickHistory>>,
      default: () => [],
    },
    locale: {
      type: String as PropType<I18nLang>,
      required: true,
    },
  },
  methods: {
    handleDeleteHistory(history: IHistory | IQuickHistory) {
      this.$emit('delete', history)
    },
  },
  render() {
    return (
      <div>
        <div class="pb-2 border-b border-gray-30 flex items-center">
          <span>{this.locale === 'cn' ? '历史记录' : 'Histories'}</span>
          <a
            class="ml-auto text-blue-default hover:text-blue-hover hover:underline"
            target="_blank"
            href={sharedCtx.router.generatorSearchHistoryPath('search')}
          >
            {this.locale === 'cn' ? '历史管理' : 'Manage history'}
          </a>
        </div>
        <div class="mt-2">
          {this.list.map((history) => {
            let queryText = ''

            if (history.query_param.data_type === 'intelligent_retrieval') {
              const quickHistory = history as IQuickHistory
              queryText = quickHistory.query_param?.query_tags?.join(' ') || ''
            } else {
              const commonHistory = history as IHistory
              const { query, data_type } = commonHistory.query_param
              const queryItems = [...(query.must || [])]
              queryText = query2Text(queryItems, this.locale, data_type)
            }

            return (
              <div
                onClick={() => this.$emit('clickHistory', history)}
                class="flex items-center p-1 cursor-pointer text-[#52188c] hover:rounded hover:bg-gray-20"
              >
                <GIcon class="flex-shrink-0" svgName="History" size={24}></GIcon>
                <div class="ml-2 flex-1 truncate">{queryText}</div>
                <GIcon
                  nativeOnClick={(e: MouseEvent) => {
                    e.stopPropagation()
                    this.handleDeleteHistory(history)
                  }}
                  class="flex-shrink-0 ml-2 rounded-full hover:bg-gray-40"
                  svgName="SolidCloseMedium"
                  size={24}
                ></GIcon>
              </div>
            )
          })}
        </div>
      </div>
    )
  },
})
