import UAParser from 'ua-parser-js'
export function getUserAgent() {
  return navigator.userAgent
}

const uaMap = new Map<string, UAParser>()

export function getUaParser(ua = getUserAgent()): UAParser {
  if (uaMap.has(ua)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return uaMap.get(ua)!
  }
  const parser = new UAParser(ua)
  uaMap.set(ua, parser)
  return parser
}

export function getUaEngine(ua = getUserAgent()) {
  const parser = getUaParser(ua)
  const engine = parser.getEngine()
  const major = Number(engine.version?.split('.')?.[0])
  return {
    ...engine,
    major: isNaN(major) ? undefined : major,
  }
}
