import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { GIcon } from '@pharmsnap/shared/src/components'
import { defineComponent } from '@vue/composition-api'
import fireIcon from './assets/fire.svg'
import giftImg from './assets/gift.png'
import girlImg from './assets/girl.png'
import waveImg from './assets/waveLine.png'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { ProductPromotionDoubleInnerCard } from './ProductPromotionDoubleInnerCard'

export const ProductPromotionDoubleCard = defineComponent({
  name: 'ProductPromotionDoubleCard',
  props: {},
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, { emit }) {
    const handleSkip = () => {
      emit('close')
    }

    return {
      handleSkip,
    }
  },
  render() {
    return (
      <div class="flex flex-col items-center relative pt-6 pb-[106px]">
        <div class="flex gap-x-3 items-center">
          <img src={fireIcon} />
          <div class="text-lg font-semibold">{this.$t('title')}</div>
        </div>
        <div class="mt-10 flex justify-between items-stretch gap-x-[70px]">
          <ProductPromotionDoubleInnerCard type="bio" />
          <ProductPromotionDoubleInnerCard type="chemical" />
        </div>
        <img src={waveImg} alt="" class="absolute bottom-0 pointer-events-none" />
        <img src={girlImg} alt="" class="absolute bottom-0 left-0 pointer-events-none" />
        <img src={giftImg} alt="" class="absolute bottom-0 right-0 pointer-events-none" />
        <GIcon
          nativeOn={{ click: this.handleSkip }}
          class="hover:bg-gray-60_alpha absolute top-[16px] right-[16px] cursor-pointer"
          useSvgDefaultColor={true}
          svgName="SolidCloseMedium"
          size={24}
        ></GIcon>
      </div>
    )
  },
})
