import { IAutoCompleteType, IBAcTag } from '@pharmsnap/shared/src/types'
import { ComponentInternalInstance } from '@vue/composition-api'
import _ from 'lodash'

export function isEntityEventTypeSelect<T extends { [key: string]: boolean | string[] }>(data: T) {
  for (const i of Object.keys(data)) {
    const val = data[i as keyof T]
    if (typeof val === 'boolean') {
      if (val) return true
    }
    if (Array.isArray(val)) {
      if (val.length) return true
    }
  }
  return false
}

// export const convertAcTypeToFilterType = (dataType: IAutoCompleteType): IAlertEventFilterType => {
//   return _.snakeCase(dataType) as IAlertEventFilterType
// }

export const convertRefineItemsToText = (
  items: {
    dataType: IAutoCompleteType
    tags: IBAcTag[]
  }[],
  ins: ComponentInternalInstance | null,
  isCN: boolean
) => {
  const rt: string[] = []
  items.forEach((item) => {
    const { dataType, tags } = item
    const filterType = _.camelCase(dataType)
    const filterTypeText = ins?.proxy.$t(`common.${filterType}`)
    const tagText = tags.map((tag) => (isCN ? tag.name_cn || tag.name_en : tag.name_en)).join(' OR ')
    rt.push(`${filterTypeText}: ${tagText}`)
  })
  return rt.join(' AND ')
}
