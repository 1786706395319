import type { IMechanismAutoCompleteItem } from '@patsnap/synapse_common_interface'
import { decryptMechanism } from '@pharmsnap/shared/utils'
import DataLoader from 'dataloader'
import { IAutoCompleteParams, IBaseMechanismInfo, ISearchResult, ISearchTreeParams, ISearchTreeResult } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class MechanismApi extends BaseApi {
  private batchGetMechanismLoader = new DataLoader((ids: readonly string[]) => this._getBatchMechanism(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IMechanismAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    return this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/drug_mechanism_action/autocomplete'),
      params: { prefix: keywords, limit },
      cache: true,
      cancelToken,
    })
  }
  private async _getBatchMechanism(ids: readonly string[]): Promise<Array<IBaseMechanismInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug_mechanism_action/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseMechanismInfo>>
    if (rt.success && rt.data) {
      rt.data.items?.forEach((item) => {
        decryptMechanism(item, rt.xCorrelationId)
        item.fontClassName = rt.fontClassName
      })
      return ids.map((id) => rt.data.items?.find((item) => item.mechanism_id === id))
    }
    return []
  }
  async getBatchMechanism(ids: readonly string[]): Promise<IHttpWrap<IBaseMechanismInfo[]>> {
    const rt = await this.batchGetMechanismLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBaseMechanismInfo[],
      xCorrelationId: '',
    }
  }

  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/drug_mechanism_action/tree/search`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }
}
