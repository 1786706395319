import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import $class from './VxeGridLoading.module.scss'
export const VxeGridLoading = () => {
  return (
    <ElSkeleton loading={true} animated count={1}>
      <template slot="template">
        {Array.from({ length: 19 }).map(() => (
          <div class={[$class.container, 'flex items-center border-b']}>
            <ElSkeletonItem class={$class.checkbox}></ElSkeletonItem>
            <div class={[$class.tableItemContain, 'flex-1 flex items-center']}>
              <ElSkeletonItem class={$class.tableItem}></ElSkeletonItem>
              <ElSkeletonItem class={$class.tableItem}></ElSkeletonItem>
              <ElSkeletonItem class={$class.tableItem}></ElSkeletonItem>
            </div>
          </div>
        ))}
      </template>
    </ElSkeleton>
  )
}
