import { defineComponent, PropType } from '@vue/composition-api'
import $class from './BField.module.scss'
import { ILang, IPatentDetail } from '@pharmsnap/shared/types'
interface IField {
  field: string
  fieldData: IPatentDetail
  render: (data: IPatentDetail, lang: ILang) => JSX.Element
}
export const BField = defineComponent({
  props: {
    fieldList: {
      type: Array as PropType<IField[]>,
      required: true,
    },
    lang: {
      type: String as PropType<ILang>,
      required: true,
    },
  },
  render() {
    return (
      <div class={$class.fieldContain}>
        {this.fieldList.map((fieldObj) => {
          return (
            <div class={$class.fieldWrap}>
              <div class={$class.fieldTitle}>{fieldObj.field}</div>
              <div>{fieldObj.render(fieldObj.fieldData, this.lang)}</div>
            </div>
          )
        })}
      </div>
    )
  },
})
