import { ITopAggregationDownloadParams, ITopAggregationDownloadParamsNew } from '@pharmsnap/shared/types'
import { IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DownloadApi extends BaseApi {
  async downloadAnalysisHeatmapFile(params: ITopAggregationDownloadParams): Promise<IHttpWrap<Blob, Blob>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/analysis/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }

  /** 参数简化版，返回结果和downloadAnalysisHeatmapFile一样，简化入参params */
  async downloadAnalysisHeatmapFileNew(params: ITopAggregationDownloadParamsNew): Promise<IHttpWrap<Blob, Blob>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/analysis/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }

  async downloadDrugTypeClinicalHeatmapByTargetFile(params: ITopAggregationDownloadParams): Promise<IHttpWrap<Blob, Blob>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/ct/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }

  /** 参数简化版，返回结果和downloadDrugTypeClinicalHeatmapByTargetFile一样，简化入参params */
  async downloadDrugTypeClinicalHeatmapByTargetFileNew(params: ITopAggregationDownloadParamsNew): Promise<IHttpWrap<Blob, Blob>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/ct/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }
  async downloadDrugTypeHeatmapByTargetFile(params: any): Promise<IHttpWrap<Blob, Blob>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/analysis/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }

  async downloadCtHeatmap(params: ITopAggregationDownloadParams): Promise<IHttpWrap<Blob, Blob>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/download/ct/heatmap'),
      data: params,
      method: 'POST',
      responseType: 'blob',
      cache: true,
    })
  }
}
