import { CreateConversationParams, CreateConversationResponse, FileAttachment } from '@patsnap/ls360_sdk_shared'
import { ILS360UserSetting, ISynapseChatCapacityResponse, ISynapseChatProfile } from '@patsnap/synapse_common_interface'
import { ISignType } from '@pharmsnap/shared/service/apis/searchApi'
import { IChatExampleSceneType, IGetQuestionRecommendParams, IQuestionRandom, IQuestionRecommend } from '../../types/ls360'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import { BaseApi } from './baseApi'

export class Ls360Api extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async uploadFile(data: File, onUploadProgress: (progressEvent: ProgressEvent) => void): Promise<IHttpWrap<FileAttachment>> {
    const formData = new FormData()
    formData.append('file', data)
    return await this.http({
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/file/upload'),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  async getQuestionRecommend(data: IGetQuestionRecommendParams): Promise<IHttpWrap<IQuestionRecommend>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`ls360_webapi/1.0/question/recommend`),
      data,
      cache: true,
    })
  }

  async createConversation(__: undefined, config: IRequestConfig<CreateConversationParams>): Promise<IHttpWrap<CreateConversationResponse>> {
    return await this.http({
      ...config,
      method: 'POST',
      trackingName: 'START_CHAT',
      url: this.getUrl('ls360_webapi/1.0/chat'),
    })
  }

  async streamConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl(`ls360_webapi/1.0/chat/stream`),
      timeout: 5 * 60 * 1000,
    })
  }

  async stopConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl(`ls360_webapi/1.0/chat/stop`),
    })
  }

  async chartConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/stream'),
      timeout: 5 * 60 * 1000,
    })
  }
  async tableConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/stream'),
      timeout: 5 * 60 * 1000,
    })
  }
  async conversationHistory(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/history'),
    })
  }
  async generateReport(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/report'),
    })
  }
  async getReportDetail({ report_id }: { report_id: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'GET',
      url: this.getUrl(`ls360_webapi/1.0/report/${report_id}`),
    })
  }
  async reportHistory(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/report/page'),
    })
  }
  async deleteReport({ reportId }: { reportId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'DELETE',
      url: this.getUrl(`ls360_webapi/1.0/report/${reportId}`),
    })
  }
  async conversationDetail({ chatId }: { chatId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'GET',
      url: this.getUrl(`ls360_webapi/1.0/chat/${chatId}`),
    })
  }
  async sharedConversationDetail({ chatId }: { chatId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'GET',
      url: this.getUrl(`ls360_webapi/1.0/public/share/${chatId}`),
    })
  }
  async nernorConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/ner_nor'),
    })
  }
  async shareConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/share'),
    })
  }
  async getConversationShareStatus(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/share/check'),
    })
  }
  async deleteConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'DELETE',
      url: this.getUrl('ls360_webapi/1.0/chat/history'),
    })
  }
  async renameConversation({ chatId }: { chatId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'PUT',
      url: this.getUrl(`ls360_webapi/1.0/chat/${chatId}`),
    })
  }
  async feedbackConversation(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/feedback'),
    })
  }
  async starConversation({ chatId }: { chatId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'PUT',
      url: this.getUrl(`ls360_webapi/1.0/chat/star/${chatId}`),
    })
  }
  async unstarConversation({ chatId }: { chatId: string }, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'PUT',
      url: this.getUrl(`ls360_webapi/1.0/chat/unstar/${chatId}`),
    })
  }
  async cancelNotLsRelatedConversationItem(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/cancel'),
    })
  }
  async checkTextSecurity(__: undefined, config: IRequestConfig) {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/chat/content-check'),
    })
  }

  async getChatExample(sceneType: IChatExampleSceneType = 'SYNAPSE_HOME_CHAT'): Promise<IHttpWrap<IQuestionRandom[]>> {
    return await this.http({
      method: 'GET',
      url: this.getUrl('ls360_webapi/1.0/question/example'),
      cache: true,
      params: {
        scene_type: sceneType,
      },
    })
  }

  async getChatCapacity(): Promise<IHttpWrap<ISynapseChatCapacityResponse>> {
    return await this.http({
      method: 'GET',
      url: this.getUrl('ls360_webapi/1.0/chat/capacity'),
    })
  }

  async getSignature(
    __: undefined,
    config: IRequestConfig<{ object_key: string[]; sign_type: ISignType }>
  ): Promise<IHttpWrap<Record<string, string>>> {
    return await this.http({
      ...config,
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/signature/sign'),
    })
  }

  async batchSignature(logoList: readonly string[], signType: ISignType = 'master_entity_org_logo'): Promise<string[]> {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('ls360_webapi/1.0/signature/sign'),
      data: {
        object_key: logoList,
        sign_type: signType,
      },
    })
    if (rt.status === 200) {
      return logoList.map((item) => rt.data[item])
    }
    return []
  }

  async getUserProfile(params?: { refresh_cache?: boolean }): Promise<IHttpWrap<ISynapseChatProfile>> {
    return await this.http({
      method: 'GET',
      url: this.getUrl('ls360_webapi/user'),
      params,
    })
  }
  async updateUserSettings(data: ILS360UserSetting): Promise<IHttpWrap<ILS360UserSetting>> {
    return await this.http({
      url: this.getUrl('ls360_webapi/user/setting'),
      method: 'PUT',
      data,
    })
  }
}
