import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { getSpecialLang } from '@patsnap/synapse_common_utils'
import { renderPhase } from '@pharmsnap/shared/render'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IDrugCardInfo } from '@pharmsnap/shared/src/types'
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api'
import { GDescription } from '../../../ui/GDescription/GDescription'
import { GLimited } from '../../../ui/GLimited/GLimited'
import { GLink } from '../../../ui/GLink/GLink'
import { GLoading } from '../../../ui/GLoading/GLoading'
import { BDiseaseItem } from '../../BDiseaseItem/BDiseaseItem'
import { BMechanismItem } from '../../BMechanismItem/BMechanismItem'
import { BOrganizationItem } from '../../BOrganizationItem/BOrganizationItem'
import { BTargetItem } from '../../BTargetItem/BTargetItem'
import $classes from '../EntityCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import $styles from './BDrugCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDrugBullsEyeChartCard = defineComponent({
  name: 'BDrugBullsEyeChartCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDrugCardInfo>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },
  },
  setup(props) {
    const { data } = toRefs(props)
    const { isCN, locale, localeUpcase } = useLocale()
    const target = computed(() => data?.value?.target_id_view || [])
    const mechanism = computed(() => data?.value?.mechanism_action_id_view || [])
    const devOrg = computed(() => data?.value?.active_org_master_entity_id_view || [])
    const phase = computed(() =>
      locale.value === 'cn'
        ? data?.value?.global_highest_dev_status_view?.name_cn || data?.value?.global_highest_dev_status_view?.name_en
        : data?.value?.global_highest_dev_status_view?.name_en
    )
    const researchDisease = computed(() => data?.value?.research_disease_view || [])
    const drugEnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'EN', isDegraded: false }))
    const drugCnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'CN', isDegraded: false }))

    return {
      isCN,
      localeUpcase,
      drugEnName,
      drugCnName,
      target,
      mechanism,
      devOrg,
      researchDisease,
      phase,
    }
  },
  methods: {
    renderHeader() {
      return (
        <div class="flex items-start overflow-hidden">
          {this.$slots.prefix}
          <div class="flex-1 truncate">
            <GLink
              class="block font-semibold leading-5 text-black-default text-sm"
              newTab={true}
              name={this.drugEnName}
              href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
              truncate={true}
            ></GLink>
            {this.renderCnName()}
          </div>
        </div>
      )
    },
    renderCnName() {
      if (this.isCN && !!this.drugCnName) {
        return (
          <GLink
            style={{ lineHeight: `16px` }}
            class="block text-xs text-text-t1"
            truncate={true}
            href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
            name={this.drugCnName}
          ></GLink>
        )
      }
      return null
    },
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={1}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderContent() {
      if (!this.data) return null

      return (
        <GDescription
          labelWidthIsSpecified={false}
          data={this.data}
          size={this.size}
          items={[
            {
              label: `${this.$t('drugCard.target')}:`,
              field: 'target',
            },
            {
              label: `${this.$t('drugCard.mechanism')}:`,
              field: 'mechanism',
            },
            {
              label: `${this.$t('drugCard.devOrg')}:`,
              field: 'org',
            },
            {
              label: `${this.$t('drugCard.researchDisease')}:`,
              field: 'researchDisease',
            },
            {
              label: `${this.$t('drugCard.highestPhase')}:`,
              field: 'highestPhase',
            },
          ]}
          scopedSlots={{
            target: () =>
              this.renderLimited(this.target, this.data?.target_id_count || 0, (item) => <BTargetItem popover={false} data={item}></BTargetItem>),
            mechanism: () =>
              this.renderLimited(this.mechanism, this.data?.mechanism_action_id_count || 0, (item) => (
                <BMechanismItem data={item} truncate class="leading-6"></BMechanismItem>
              )),
            org: () =>
              this.renderLimited(this.devOrg, this.data?.active_org_master_entity_id_count || 0, (item) => (
                <BOrganizationItem popover={false} data={item}></BOrganizationItem>
              )),
            researchDisease: () =>
              this.renderLimited(this.researchDisease, this.data?.research_disease_count || 0, (item) => (
                <BDiseaseItem popover={false} data={item}></BDiseaseItem>
              )),
            highestPhase: () => {
              return renderPhase(
                {
                  currentPhase: this.data?.global_highest_dev_status_view,
                  beforePhase: this.data?.global_highest_dev_status_termination_view,
                  fontClassName:
                    this.data?.global_highest_dev_status_view?.fontClassName ?? this.data?.global_highest_dev_status_termination_view?.fontClassName,
                },
                this.localeUpcase
              )
            },
          }}
        ></GDescription>
      )
    },
    renderLoading() {
      if (this.loading) {
        return (
          <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white-default">
            <GLoading></GLoading>
          </div>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div class={[$classes.card, $styles.card, $classes.cardNoBorder, 'relative']}>
        <div class="mb-[10px]">{this.renderHeader()}</div>
        <div class={$classes.body}>{this.renderContent()}</div>
        {this.renderLoading()}
      </div>
    )
  },
})
