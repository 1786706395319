import '@patsnap-ui/icon/assets/logo/Bio.svg'
import '@patsnap-ui/icon/assets/logo/Chemical.svg'
import '@patsnap-ui/icon/assets/Products.svg'
import '@patsnap-ui/icon/assets/solid/bulb.svg'
import { GIcon } from '@pharmsnap/shared/src/components'
import { defineComponent, PropType } from '@vue/composition-api'
import bulb from './assets/bulb.svg'
import lineCircle from './assets/lineWithCircle.svg'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const productGuidCardWidth = 320

export const ProductGuideCard = defineComponent({
  name: 'ProductGuideCard',
  props: {
    type: {
      type: String as PropType<'trial' | 'view'>,
      default: 'view',
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('close')
    }
    return {
      handleClick,
    }
  },
  render() {
    return (
      <div class="inline-block">
        <div class="text-right pr-8">
          <span class="p-1 inline-block" style="border-radius: 4px;background: linear-gradient(89deg, #E0FAFC 2.36%, #EFEBFB 99.77%);">
            <GIcon svgName="Products" color="#495973" size={24}></GIcon>
          </span>
        </div>
        <div class="text-right pr-[45px]">
          <img src={lineCircle} alt="" class="w-[6px] h-8" />
        </div>
        <div
          class="rounded-lg border-[#fff] px-5 py-4"
          style={{
            background: 'linear-gradient(90deg, rgba(29, 139, 248, 0.10) 1.58%, rgba(54, 201, 158, 0.10) 110.94%), #FFF',
            width: `${productGuidCardWidth}px`,
          }}
        >
          <div class="flex items-start">
            <img src={bulb} width={24} height={24}></img>
            <div
              class="ml-1 text-sm leading-5 text-text-t1"
              style="word-break: break-word;"
              domPropsInnerHTML={
                this.type === 'trial' ? this.$tc('productGuideCard.trialProductTitle') : this.$tc('productGuideCard.viewProductTitle')
              }
            ></div>
          </div>
          <div class="mt-4 border-blue-default rounded-2xl bg-white-default text-center">
            <div class="flex items-center justify-between h-[80px] px-8">
              <div class="flex-1">
                <GIcon svgName="LogoBio" size={32}></GIcon>
                <div class="mt-2 text-sm leading-5 text-text-t1">{this.$t('productGuideCard.bio')}</div>
              </div>
              <div class="flex-1">
                <GIcon svgName="LogoChemical" size={32}></GIcon>
                <div class="mt-2 text-sm leading-5 text-text-t1">{this.$t('productGuideCard.chemical')}</div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button onClick={this.handleClick} class="pt-ui-btn w-full" type="button" data-type="submit" block>
              {this.$t('productGuideCard.getIt')}
            </button>
          </div>
        </div>
      </div>
    )
  },
})
