import { IAlertConfig, ICreateUpdateAlertParams } from '@patsnap/synapse_common_interface'
import { E_ROUTER_NAME, E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { getCurrentInstance } from 'vue-demi'
import { useLocale } from '.'
import { BBindEmail, BBindEmailMessage, BEmailAlertV2, showGDialog } from '../components'
import { showSingleToast } from '../utils'
import { useAuthStore } from './useAuthStore'
export function useEmailAlertDialog() {
  const ins = getCurrentInstance()
  const { isCN } = useLocale()
  const {
    state: { usageInfo, userInfo },
    getters: { isBasicUser, isEnterpriseUser },
    actions: { checkAlertLimit, changeUpgrade },
  } = useAuthStore()
  /**
   * 检查邮件提醒是否已经超限
   */
  async function _showAlertOverLimitTip() {
    const total = usageInfo.value?.alert.total
    if (isBasicUser.value || isEnterpriseUser.value) {
      showSingleToast({
        message: isCN.value
          ? `您只可以创建${total}个情报监控，请升级您的订阅计划`
          : `You can only create ${total} alerts, please upgrade your subscription plan`,
        type: 'error',
      })
    } else {
      changeUpgrade({
        show: true,
        title: isCN.value
          ? `您只可以创建${total}个情报监控，请升级您的订阅计划`
          : `You can only create ${total} alerts, please upgrade your subscription plan`,
        type: E_UPGRADE_TYPE.EXCEEDS_FEATURE_MAX_LIMIT,
        trigger_point: 'SET_ALERT',
      })
    }
  }
  /**
   * 显示绑定邮箱弹窗
   * @param onBindSuccessCallBack
   */
  function _showBindEmail(onBindSuccessCallBack: () => void) {
    const dialogIns = showGDialog(
      {
        width: '400px',
        visible: true,
        title: isCN.value ? '绑定邮箱' : 'Bind Email',
        component: BBindEmail,
        showFooter: false,
        closeOnClickModal: false,
        componentVNodeData: {
          on: {
            success: () => {
              onBindSuccessCallBack?.()
              dialogIns.$destroy()
              dialogIns.$el.remove()
            },
          },
          scopedSlots: {
            cancelButton: () => {
              return (
                <button
                  class="pt-ui-btn"
                  type="button"
                  data-type="default"
                  onClick={() => {
                    dialogIns.$destroy()
                    dialogIns.$el.remove()
                  }}
                >
                  {isCN.value ? '取消' : 'Cancel'}
                </button>
              )
            },
          },
        },
        onClose: () => {
          dialogIns.$destroy()
          dialogIns.$el.remove()
        },
      },
      ins?.proxy.$router,
      ins?.proxy.$i18n
    )
  }
  /**
   * 显示需要绑定邮箱的提示
   * @param onBindSuccessCallBack
   */
  function _showNeedBindEmailTips(onBindSuccessCallBack: () => void) {
    const dialogIns = showGDialog(
      {
        width: '400px',
        visible: true,
        component: BBindEmailMessage,
        emptyMode: true,
        closeOnClickModal: true,
        componentVNodeData: {
          staticClass: 'p-10',
          on: {
            verifyEmail: () => {
              _showBindEmail(onBindSuccessCallBack)
              dialogIns.$destroy()
              dialogIns.$el.remove()
            },
          },
        },
      },
      ins?.proxy.$router,
      ins?.proxy.$i18n
    )
  }
  /**
   * 显示创建邮件提醒弹窗
   * @param params
   * @returns
   */
  async function showEmailAlertDialog(params: {
    componentProps: {
      defaultEmailAlertConfig: Partial<ICreateUpdateAlertParams>
      mode?: 'detail' | 'create' | 'update'
      searchResultCount?: number
    }
    onSubmitSuccessCallBack?: (emailConfig: IAlertConfig) => void
    onCloseCallBack?: () => void
  }) {
    if (!userInfo.value?.email) {
      _showNeedBindEmailTips(() => showEmailAlertDialog())
      return
    }
    showEmailAlertDialog()
    async function showEmailAlertDialog() {
      if (await checkAlertIsOverLimit()) {
        _showAlertOverLimitTip()
        return
      }
      showGDialog(
        {
          width: '1080px',
          visible: true,
          showFooter: false,
          emptyMode: true,
          customClass: 'overflow-hidden rounded',
          component: BEmailAlertV2,
          componentProps: params.componentProps,
          componentVNodeData: {
            on: {
              submitSuccess: (e: IAlertConfig) => {
                if (params.onSubmitSuccessCallBack) {
                  params.onSubmitSuccessCallBack(e)
                  return
                }
                const href = ins?.proxy.$router.resolve({ name: E_ROUTER_NAME.EMAIL_ALERT }).href
                const link = `<a href="${href}" class="underline">${isCN.value ? '前往情报监控中心查看' : 'Go to email alert center. '}</a>`
                showSingleToast({
                  message: isCN.value ? `您成功创建了情报监控功能, ${link}` : `You have successfully created email alert. ${link}`,
                  dangerouslyUseHTMLString: true,
                  type: 'success',
                })
              },
              cancel: () => {
                if (params.onCloseCallBack) {
                  params.onCloseCallBack()
                }
              },
            },
          },
        },
        ins?.proxy.$router,
        ins?.proxy.$i18n
      )
    }
    /**
     * 检查邮件提醒是否已经超限
     * @returns
     */
    async function checkAlertIsOverLimit() {
      // 如果是更新模式，不需要检查
      if (params.componentProps.mode === 'update') {
        return false
      }
      return await checkAlertLimit()
    }
  }
  return { showEmailAlertDialog }
}
