import { i18n } from '@pharmsnap/shared/i18n'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { defineComponent, ref } from '@vue/composition-api'
import Vue from 'vue'

export function useCorpLinkage() {
  let cardVm: null | Vue = null
  const containerRef = ref()
  const { ts } = useLocale()
  const Linkage = Vue.extend(
    defineComponent({
      name: 'Linkage',
      render() {
        return (
          <div class="bg-white-default rounded p-2 border border-[#BCC2CC] text-sm" style="box-shadow: 0px 2px 6px 0px #00000014;">
            {ts('corpTree.linkage')}
          </div>
        )
      },
    })
  )
  function renderCardFn() {
    if (cardVm) {
      cardVm.$destroy()
    }
    cardVm = new Linkage({
      i18n,
    })
    cardVm.$mount()
    return cardVm.$el
  }

  const showCard = (node?: any) => {
    if (!node) {
      return
    }
    const { top, left, width } = node.getBoundingClientRect()
    const el = renderCardFn()
    if (containerRef.value.firstChild) {
      containerRef.value.firstChild.replaceWith(el)
    } else {
      containerRef.value.append(el)
    }
    containerRef.value.setAttribute('style', `display: block; top: ${top - 48}px; left: ${left - 62}px;`)
  }
  const closeCard = () => {
    containerRef.value.setAttribute('style', 'display: none;')
  }

  const onClickNode = (node?: any) => {
    showCard(node)
  }

  return {
    containerRef,
    renderCardFn,
    closeCard,
    onClickNode,
  }
}
