import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.DRUG_DEAL_FINDER,
}

export default [
  {
    path: 'drug-deal-finder',
    name: E_ROUTER_NAME.DRUG_DEAL_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/drugDeal/DrugDealFinder'),
    meta: {
      ...meta,
      title: createWebTitle('药物交易', 'Deal'),
      hideQuickSearch: true,
    },
  },
  {
    path: 'drug-deal-list',
    name: E_ROUTER_NAME.DRUG_DEAL_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/drugDeal/DrugDealList'),
    meta: {
      ...meta,
      title: createWebTitle('药物交易检索结果', 'Deal Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-deal-group/drug`,
    name: E_ROUTER_NAME.DRUG_DEAL_GROUP_BY_DRUG,
    component: () => import('@pharmsnap/pharmsnap-web/views/drugDeal/DrugDealListGroupByDrug'),
    meta: {
      ...meta,
      title: createWebTitle('药物交易检索结果', 'Deal Search Results'),
      notWide: true,
    },
  },
] as RouteConfig[]
