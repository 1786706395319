/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, unref } from '@vue/composition-api'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem, IUseAnalysisChartControls } from '../type'

export function useAnalysisChartCard<T extends Record<PropertyKey, any>>(options: IUseAnalysisChartControls<T>) {
  const { controls = [], layout = [] } = options

  const controlsPositionLeft = computed(() => getControlsByPosition('left'))

  const controlsPositionMiddle = computed(() => getControlsByPosition('middle'))

  const controlsPositionSetting = computed(() => getControlsByPosition('setting'))

  const controlsPositionChartPanel = computed(() => getControlsByPosition('chart-panel'))

  const controlsPositionDataPanel = computed(() => getControlsByPosition('data-panel'))

  const controlsPositionTopRight = computed(() => getControlsByPosition('top-right'))

  const controlsPositionTopLeft = computed(() => getControlsByPosition('top-left'))

  function getControlsByPosition(position: IAnalysisChartControlLayoutItem<Extract<keyof T, string>>['position']) {
    const items = unref(layout).filter((item) => item.position === position)
    return items.map((i) => unref(controls).find((c) => c.bindField === i.field)).filter((i) => !!i) as Array<
      IAnalysisChartControlItem<Extract<keyof T, string>>
    >
  }

  return {
    controlsPositionTopRight,
    controlsPositionTopLeft,
    controlsPositionLeft,
    controlsPositionMiddle,
    controlsPositionSetting,
    controlsPositionChartPanel,
    controlsPositionDataPanel,
  }
}
