import { Chart } from '@antv/g2'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { radarDiseaseNameMap } from '@pharmsnap/shared/constants'
import { getChartWaterMark } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'
import { getChartColor } from '../../../chart/plots/config'
import { GChartCard } from '../../ui/GChartCard/GChartCard'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import cn from './locales/cn.json'
import en from './locales/en.json'

const color = getChartColor(1)[0]
export const BDrugRadar = defineComponent({
  name: 'BDrugRadar',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const {
      getters: { isFreeUser, isTrialUser },
    } = useAuthStore()
    const chartContainer = ref<HTMLElement>()
    const valueLabel = computed(() => {
      return isCN.value ? '适应症数量' : 'Indication Counts'
    })

    const dataRef = computed(() => {
      return Object.entries(radarDiseaseNameMap).map((item) => {
        const [key, value] = item
        return {
          name: isCN.value ? value.name_cn : value.name_en,
          score: props.data[key] || 0,
        }
      })
    })
    let chart: Chart
    function getChartIns() {
      if (chart) {
        return chart
      }
      if (!chartContainer.value) {
        return null
      }
      return (chart = new Chart({
        container: chartContainer.value as HTMLElement,
        autoFit: true,
        padding: 0,
      }))
    }
    watch(
      dataRef,
      (value) => {
        // 确保获取dom
        setTimeout(() => {
          if (chart) {
            chart.changeData(value)
          } else {
            renderChart()
          }
        }, 0)
      },
      {
        immediate: true,
      }
    )
    onBeforeUnmount(() => {
      chart?.destroy()
    })
    function renderChart() {
      const chart = getChartIns()
      if (!chart) {
        return
      }
      chart.data(dataRef.value)
      chart.scale('score', {
        alias: valueLabel.value,
        nice: true,
        min: 0,
        // 不显示坐标数值
        formatter() {
          return ''
        },
      })
      chart.coordinate('polar', {
        radius: 0.48,
      })
      chart.tooltip(false)
      chart.axis('name', {
        line: null,
        tickLine: null,
        label: {
          autoEllipsis: true,
          formatter(text, item, index) {
            return `${text} (${dataRef.value[index].score})`
          },
          style: {
            fill: '#020A1A',
            fontSize: 12,
            lineHeight: 16,
          },
        },
        grid: {
          line: {
            style: {
              lineDash: null,
            },
          },
        },
      })
      chart.axis('score', {
        line: null,
        tickLine: null,
        grid: {
          line: {
            type: 'line',
            style: {
              lineDash: null,
            },
          },
          alternateColor: 'rgba(0, 0, 0, 0.04)',
        },
      })
      if (isFreeUser.value || isTrialUser.value) {
        const { waterMark, imgWidth, imgHeight } = getChartWaterMark(isCN.value)
        const middleX = (chart.width - imgWidth) / 2
        const startXPercent = (middleX / chart.width) * 100

        chart.annotation().image({
          src: waterMark,
          // x, y 都是百分比的形式，如 30%，在绘图区域定位(即坐标系内)
          start: [`${startXPercent}%`, '30%'],
          end: ['100%', '100%'],
          style: {
            width: imgWidth,
            height: imgHeight,
          },
        })
      }

      chart.line().position('name*score').color(color).size(2)
      chart.area().position('name*score').color(color)
      chart.render()
    }

    return { chartContainer }
  },
  render() {
    return (
      <GChartCard class="w-full h-full" isLoading={this.isLoading} isEmpty={this.isEmpty}>
        <div slot="header">
          <div class="flex items-center text-base text-text-t1 font-medium">
            {this.$t('DrugRadar.disease_domain_score')}
            {!!this.tip && (
              <GTooltip theme="light" mode="icon" placement="top">
                <template slot="content">
                  <div domPropsInnerHTML={this.tip}></div>
                </template>
              </GTooltip>
            )}
          </div>
          <div class="text-sm mt-2 text-text-t2 truncate" title={this.desc}>
            {this.desc}
          </div>
        </div>
        <div ref="chartContainer" style="height: 100%;"></div>
      </GChartCard>
    )
  },
})
