import { BNewsAnnotationTag, GTextAutoLineClamp } from '@pharmsnap/shared/components'
import { renderNewsPublishDate } from '@pharmsnap/shared/render'
import { INewsDetail } from '@pharmsnap/shared/src/types'
import { encryptNum } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { BLazyImg } from '../../BLazyImg/BLazyImg'
import $styles from './index.module.scss'

export const BFeatureNewsCard = defineComponent({
  name: 'BFeatureNewsCard',
  props: {
    news: {
      required: true,
      type: Object as PropType<INewsDetail>,
    },
  },
  methods: {
    renderItemCoverImg(news: INewsDetail) {
      return news.images?.map((image) => {
        if (image.type === 'cover_image')
          return (
            <BLazyImg
              class="overflow-hidden w-full relative rounded h-0 bg-gray-20"
              style="padding-bottom: calc(9 / 16 * 100%)"
              imgClass={[
                'absolute',
                'top-0',
                'bottom-0',
                'left-0',
                'right-0',
                'w-full',
                'transition',
                'duration-200',
                'transform',
                'overflow-hidden',
                $styles.lazyImg,
              ]}
              imgStyle="object-fit: contain"
              src={image.s3_path as string}
              signType="discovery_attachment"
            ></BLazyImg>
          )
      })
    },
  },
  render() {
    return (
      <a href={`news-detail/${this.news.news_id}`} class="cursor-pointer h-full" target="_blank">
        <div class="w-full group h-full flex flex-col">
          {this.renderItemCoverImg(this.news)}
          <div class="text-xl font-semibold text-text-t1 mt-6 mb-4 line-clamp-2 group-hover:text-blue-default  hover:underline">
            {this.news.title}
          </div>
          <div class="flex-1">
            {this.news.content && <GTextAutoLineClamp class="text-text-t2 text-sm" content={this.news.content}></GTextAutoLineClamp>}
          </div>
          <div class="flex align-middle items-baseline" style="margin-top: 10px">
            <div
              class="text-sm icon-font flex items-center flex-shrink-0"
              domPropsInnerHTML={encryptNum(renderNewsPublishDate(this.news, this.$i18n))}
            ></div>
            {!!this.news.annotation_tag?.length && <span class="mx-1">·</span>}
            <div>
              {this.news.annotation_tag?.map((item) => {
                return <BNewsAnnotationTag class="mr-1 mb-1" data={item}></BNewsAnnotationTag>
              })}
            </div>
          </div>
        </div>
      </a>
    )
  },
})
