/* eslint-disable no-empty */
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { IAggregationItem, IAggregationSingleResult, IQueryItem } from '@patsnap/synapse_common_interface'
import { GFoldingContainer, GIcon } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { useInjectQueryService } from '@pharmsnap/shared/composition/useQueryService'
import '@pharmsnap/shared/src/assets/icon-svg/startups.svg'
import { IFieldLangMap } from '@pharmsnap/shared/types'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { isEqual, isFunction } from 'lodash'
import { userInjectFilterService } from '../../../BFilterService'
import { IFilterConfig, IFilterItemConfig } from '../../../types'
import { FilterCheckBoxLoading } from '../../ui/FilterLoading/FilterItemLoading'
import { FilterItemContent } from './components/FilterItemContent/FilterItemContent'
import $style from './FilterItem.module.scss'

/**
 * filter容器组件
 */
export const FilterItem = defineComponent({
  name: 'FilterItem',
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemConfig>,
    },
    filterConfigs: {
      type: Object as PropType<IFilterConfig>,
    },
    fieldLangMap: {
      required: true,
      type: Object as PropType<Partial<IFieldLangMap>>,
    },
    limitFilter: {
      type: Function as PropType<() => boolean>,
    },
    contentBorder: {
      type: Boolean,
      default: true,
    },
    labelBorder: {
      type: Boolean,
      default: false,
    },
    getTreeChildDataFn: {
      type: Function as PropType<(extraFilter: IQueryItem[], aggregationItems: IAggregationItem[]) => Promise<IAggregationSingleResult[]>>,
    },
    getSliderAggregationDataFn: {
      type: Function as PropType<(field: string) => Promise<IAggregationSingleResult[][]>>,
    },
  },

  setup(props, ctx) {
    const { field, label } = props.config
    const { locale } = useLocale()
    const { state: filterState, actions: filterActions } = userInjectFilterService()
    const { state: queryState } = useInjectQueryService()
    const title = computed(() => {
      const fieldName = getFieldNameByLang(props.fieldLangMap, field, locale.value)
      const originLabel = label ? label[locale.value] || fieldName : fieldName

      return props.config.customLabel
        ? isFunction(props.config.customLabel)
          ? props.config.customLabel(originLabel, locale.value)
          : props.config.customLabel
        : originLabel
    })
    const tooltip = computed(() => {
      return props.config.tooltip?.[locale.value] || ''
    })
    const opened = computed(() => {
      return filterState?.openingMap[field]
    })
    const loading = computed(() => {
      return filterState?.loadingMap[field]
    })
    function emitAggregation(field: string) {
      ctx.emit('aggregation', field)
    }
    function updateOpenState(value: boolean) {
      if (value) {
        const currentFieldSnapshot = filterState?.filterValueMapSnapshotMap[field]
        // 不存在该filter自己的快照或和现在query快照不一致则请求
        if (!currentFieldSnapshot || !isEqual(currentFieldSnapshot, queryState?.query)) {
          emitAggregation(field)
        }
      } else {
        filterActions?.takeFilterValueMapSnapshotMap(field, {
          query: queryState?.query,
          collapse: queryState?.collapse,
        })
      }
      filterActions?.switchFold(field, value)
      props.config.onFoldingChange && props.config.onFoldingChange(value)
    }

    return { title, tooltip, filterState, opened, loading, updateOpenState, emitAggregation }
  },
  methods: {
    renderContent() {
      return (
        <FilterItemContent
          filterConfig={this.config}
          filterConfigs={this.filterConfigs}
          limitFilter={this.limitFilter}
          getTreeChildDataFn={this.getTreeChildDataFn}
          getSliderAggregationDataFn={this.getSliderAggregationDataFn}
          {...{
            on: {
              aggregation: this.emitAggregation,
            },
          }}
        ></FilterItemContent>
      )
    },
  },
  render() {
    return (
      <GFoldingContainer
        class={[this.contentBorder ? $style.filterItemBorder : '']}
        label={this.title}
        tip={this.tooltip}
        layout="loose"
        arrowMode="down-up"
        opened={this.opened}
        onChange={this.updateOpenState}
        headerCls={[$style.filterItemLabel, this.labelBorder ? $style.filterItemLabelBorder : ''].join(' ')}
        iconSize={16}
      >
        <template slot="default">
          {this.loading ? (
            <div class="mt-4">
              <FilterCheckBoxLoading></FilterCheckBoxLoading>
            </div>
          ) : this.$slots.default ? (
            this.$slots.default
          ) : (
            this.renderContent()
          )}
        </template>
        {'iconName' in this.config ? (
          <template slot="iconSlot">
            <GIcon svgName={this.config.iconName} class="mx-1 text-text-t2" />
          </template>
        ) : null}
      </GFoldingContainer>
    )
  },
})
