import '@patsnap-ui/icon/assets/solid/AddMedium.svg'
import { checkEmailValid } from '@patsnap/synapse_common_utils'
import { useNavService } from '@pharmsnap/pharmsnap-web/composable/useNavService'
import { E_Feedback_Type } from '@pharmsnap/shared/src/types'
import { computed, defineComponent, getCurrentInstance, PropType, ref, watch } from '@vue/composition-api'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'
import { Icon as PtIcon } from 'patsnap-biz'
import { GDialog } from 'pharmsnapMF_shared/components'
import { useAuthStore } from 'pharmsnapMF_shared/composition'
import { sharedCtx } from 'pharmsnapMF_shared/context'
import { ElForm, ElFormItem, ElInput, ElOption, ElSelect, ElUpload } from 'pharmsnapMF_shared/element-ui'
import { showSingleToast, transformData } from 'pharmsnapMF_shared/utils'
import navCn from '../../nav/locales/cn.json'
import navEn from '../../nav/locales/en.json'
import { ErrorInfo, ErrorType } from '../Feedback'
import $styles from './FeedbackDialog.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export default defineComponent({
  name: 'FeedbackDialog',
  i18n: {
    messages: {
      cn: {
        ...cn,
        ...navCn,
      },
      en: {
        ...en,
        ...navEn,
      },
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentErrorType: {
      type: String as PropType<ErrorType>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { navService } = useNavService()
    const ins = getCurrentInstance()
    const images = ref<ElUploadInternalFileDetail[]>([])
    const uploadRef = ref()
    const formData = computed(() => ({
      description: description.value,
      email: email.value,
      option: feedbackOption.value,
      images: images.value,
    }))
    const {
      getters: { email: defaultEmail },
    } = useAuthStore()
    const form = ref()
    const validateRules = {
      description: [{ required: true, message: ins?.proxy.$t('descriptionNoContent'), trigger: 'blur' }],
      email: [
        { required: true, message: ins?.proxy.$t('noEmail'), trigger: 'blur' },
        { type: 'email', message: ins?.proxy.$t('emailInvalid'), trigger: ['blur', 'change'] },
      ],
    }
    const selectOptions = navService.partitions
      .filter((item: any) => typeof item === 'object')
      .map((item: any) => ({
        label: ins?.proxy.$t(`Nav.${item.key}`) as string,
        value: ins?.proxy.$t(`Nav.${item.key}`) as string,
      }))
    const errorInfoMap: Record<ErrorType, ErrorInfo> = {
      data: {
        title: ins?.proxy.$t('feedback.dataErrorTitle') as string,
        subTitle: ins?.proxy.$t('feedback.dataErrorSubTitle') as string,
        selectOptions: [
          {
            label: ins?.proxy.$t('feedback.drug') as string,
            value: ins?.proxy.$t('feedback.drug') as string,
          },
          {
            label: ins?.proxy.$t('feedback.clinical') as string,
            value: ins?.proxy.$t('feedback.clinical') as string,
          },
          {
            label: ins?.proxy.$t('feedback.patent') as string,
            value: ins?.proxy.$t('feedback.patent') as string,
          },
          {
            label: ins?.proxy.$t('feedback.literature') as string,
            value: ins?.proxy.$t('feedback.literature') as string,
          },
          {
            label: ins?.proxy.$t('feedback.news') as string,
            value: ins?.proxy.$t('feedback.news') as string,
          },
          {
            label: ins?.proxy.$t('feedback.org') as string,
            value: ins?.proxy.$t('feedback.org') as string,
          },
          {
            label: ins?.proxy.$t('feedback.target') as string,
            value: ins?.proxy.$t('feedback.target') as string,
          },
          {
            label: ins?.proxy.$t('feedback.indication') as string,
            value: ins?.proxy.$t('feedback.indication') as string,
          },
        ],
        defaultOption: ins?.proxy.$t('feedback.drug') as string,
      },
      function: {
        title: ins?.proxy.$t('feedback.functionErrorTitle') as string,
        subTitle: ins?.proxy.$t('feedback.functionErrorSubTitle') as string,
        selectOptions,
        defaultOption: ins?.proxy.$t('feedback.homepage') as string,
      },
    }
    const feedbackDialogTitle = computed(() => errorInfoMap[props.currentErrorType].title)
    const feedbackDialogSubtitle = computed(() => errorInfoMap[props.currentErrorType].subTitle)
    const feedbackSelectOptions = computed(() => errorInfoMap[props.currentErrorType].selectOptions)

    const feedbackOption = ref('')
    watch(
      () => props.currentErrorType,
      (newVal: ErrorType) => {
        feedbackOption.value = errorInfoMap[newVal].defaultOption
      },
      { immediate: true }
    )
    const description = ref('')
    const email = ref(defaultEmail.value)
    const isSendingFeedback = ref(false)
    const resetData = () => {
      description.value = ''
      email.value = defaultEmail.value
      images.value = []
    }

    const handleCloseDialog = () => {
      ctx.emit('close')
      resetData()
    }
    const handleBeforeUpload = (file: File) => {
      const isIMAGE = file.type === 'image/jpeg' || 'image/png'
      const isLt4M = file.size / 1024 / 1024 < 4

      if (!isIMAGE) {
        showSingleToast({ message: ins?.proxy.$t('feedback.imgRequired') as string, type: 'error' })
      }
      if (!isLt4M) {
        showSingleToast({ message: ins?.proxy.$t('feedback.exceedSize') as string, type: 'error' })
      }
      return isIMAGE && isLt4M
    }
    const feedTypeMap: Record<ErrorType, E_Feedback_Type> = {
      data: E_Feedback_Type.DATA_FEED,
      function: E_Feedback_Type.FUNCTION_FEED,
    }
    const submitData = computed(() => ({
      feed_type: feedTypeMap[props.currentErrorType],
      feed_section: feedbackOption.value,
      feed_detail: description.value,
      email: email.value,
      images: images.value.map((item) => item.raw),
    }))
    const handleSubmit = async () => {
      let hasError = false
      isSendingFeedback.value = true
      submitData.value.images.forEach((file) => {
        if (!handleBeforeUpload(file)) {
          hasError = true
          return
        }
      })
      if (hasError) {
        isSendingFeedback.value = false
        return
      }
      ctx.emit('close')
      const res = await sharedCtx.service.home.sendFeedback(transformData(submitData.value))
      resetData()
      if (res.success) {
        ctx.emit('submitSuccess')
      } else {
        showSingleToast({ message: ins?.proxy.$t('feedback.submitFail') as string, type: 'error' })
      }

      isSendingFeedback.value = false
    }
    const handleImageChange = (file: any, fileList: any) => {
      images.value = fileList
    }

    watch(images, (newVal) => {
      const inputElement = uploadRef.value.$el.querySelector('input[type="file"]')
      if (newVal.length >= 2) {
        inputElement.disabled = true
      } else {
        inputElement.disabled = false
      }
    })
    const isSubmitDisabled = async () => {
      return await form.value.validate()
    }

    return {
      handleSubmit,
      email,
      description,
      images,
      validateRules,
      formData,
      form,
      feedbackDialogTitle,
      feedbackSelectOptions,
      feedbackDialogSubtitle,
      feedbackOption,
      isSendingFeedback,
      handleCloseDialog,
      handleBeforeUpload,
      uploadRef,
      handleImageChange,
      isSubmitDisabled,
    }
  },
  methods: {},
  render() {
    return (
      <GDialog
        append-to-body={true}
        destroy-on-close={true}
        close-on-click-modal={false}
        title={this.feedbackDialogTitle}
        visible={this.visible}
        width="500px"
        onClose={this.handleCloseDialog}
        onCancel={this.handleCloseDialog}
        confirmDisabled={!(this.formData.description && this.formData.email && checkEmailValid(this.formData.email)) || this.isSendingFeedback}
        confirmText={this.$t('submit') as string}
        onConfirm={() => this.form.validate((valid: boolean) => valid && this.handleSubmit())}
      >
        <ElForm
          ref="form"
          rules={this.validateRules}
          {...{
            props: {
              model: this.formData,
            },
          }}
          class={$styles.form}
        >
          <div class="text-sm font-semibold mb-1 text-text-t1">{this.feedbackDialogSubtitle}</div>
          <ElSelect class={['w-full', $styles.select]} vModel={this.feedbackOption} popperClass={$styles.option}>
            {this.feedbackSelectOptions.map((item) => {
              return <ElOption key={item.value} label={item.label} value={item.value}></ElOption>
            })}
          </ElSelect>
          <ElFormItem prop="description">
            <ElInput
              class="mt-2"
              type="textarea"
              rows="10"
              placeholder={this.$t('textareaTip')}
              maxlength={500}
              showWordLimit={true}
              vModel={this.formData.description}
              onInput={(value: string) => (this.description = value)}
            ></ElInput>
          </ElFormItem>
          <ElUpload
            ref="uploadRef"
            autoUpload={false}
            {...{
              props: {
                onChange: this.handleImageChange,
                onRemove: this.handleImageChange,
              },
            }}
            multiple={true}
            action=""
            limit={2}
            accept="image/png, image/jpeg"
          >
            <button slot="trigger" class="pt-ui-btn" with-icon data-type="submit" type="button" disabled={this.images.length >= 2}>
              <PtIcon icon="SolidAddMedium" />
              <span>{this.$t('feedback.addImage')}</span>
              &nbsp;({this.images.length}/2)
            </button>
          </ElUpload>
          <div class="text-sm font-semibold mb-1 mt-5 text-text-t1">{this.$t('email')}</div>
          <ElFormItem prop="email" style="margin-bottom: 0">
            <ElInput
              class="mt-2"
              placeholder={this.$t('emailTip')}
              type="email"
              vModel={this.formData.email}
              onInput={(value: string) => (this.email = value)}
            ></ElInput>
          </ElFormItem>
          <div class="flex items-center mt-4">
            <PtIcon icon="SolidInfo" class="w-6 h-6 text-text-t4 text-base " />
            <div class="text-text-t1 text-sm ml-1">{this.$t('infoTip')}</div>
          </div>
        </ElForm>
      </GDialog>
    )
  },
})
