import { E_AGGREGATION_TYPE, IAggregationData, IAggregationItem, IAggregationParams, IQuery } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { getYearRangeFromNow } from '@pharmsnap/shared/src/utils'
import { computed, reactive, ref, shallowRef, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, isUndefined } from 'lodash'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem, IAnalysisDisplayIconType } from '../../BAnalysisChartNext/type'

export type IUseClinicalTrialAggChartType =
  | 'multi-center-pie'
  | 'phase-pie'
  | 'country-world'
  | 'register-line'
  | 'exp-drug-type-stack-bar'
  | 'exp-drug-stack-bar'
  | 'indication-stack-bar'
  | 'therapeutic-area-stack-bar'
  | 'sponsor-stack-bar'

type ClinicalTrialAggChartModel = {
  display: IAnalysisDisplayIconType
  top?: { selectedValue: number }
  rollup?: boolean
  orgType?: { selectedValue: 'all' | 'sponsor' | 'collaborator' }
  phaseType?: { selectedValue: 'all' | 'independent' }
}

export interface IUseClinicalTrialAggChartOptions {
  query?: MaybeRef<IQuery>
  type: MaybeRef<IUseClinicalTrialAggChartType>
}

const aggregationItemRecordType: Record<IUseClinicalTrialAggChartType, IAggregationItem> = {
  'multi-center-pie': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'MULTI_CENTER',
  },
  'phase-pie': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'NORMALIZED_PHASE',
  },
  'country-world': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'COUNTRY_NORMALIZED_ID',
  },
  'register-line': {
    aggregation_type: E_AGGREGATION_TYPE.DATE_HISTOGRAM_YEAR,
    aggregation_field: 'STUDY_FIRST_POSTED_DATE_YEAR',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
  'exp-drug-type-stack-bar': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'DRUG_TYPE',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
  'exp-drug-stack-bar': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'MAIN_EXPERIMENTATION_DRUG_ID',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
  'indication-stack-bar': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'CONDITION_DISEASE_ID',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
  'therapeutic-area-stack-bar': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'THERAPEUTIC_AREA',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
  'sponsor-stack-bar': {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'REGISTRY_ID_ROOT_ONEID',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'NORMALIZED_PHASE',
      },
    ],
  },
}

export const pieChartTypes: IUseClinicalTrialAggChartType[] = ['multi-center-pie', 'phase-pie']

export const stackBarChartTypes: IUseClinicalTrialAggChartType[] = [
  'exp-drug-type-stack-bar',
  'exp-drug-stack-bar',
  'indication-stack-bar',
  'therapeutic-area-stack-bar',
  'sponsor-stack-bar',
]

export const lineChartTypes: IUseClinicalTrialAggChartType[] = ['register-line']

export const worldChartTypes: IUseClinicalTrialAggChartType[] = ['country-world']

const supportTop10OrTop20ControlChartTypes: IUseClinicalTrialAggChartType[] = [
  'exp-drug-type-stack-bar',
  'exp-drug-stack-bar',
  'indication-stack-bar',
  'sponsor-stack-bar',
]

const supportRollupControlChartTypes: IUseClinicalTrialAggChartType[] = ['sponsor-stack-bar']

const supportSponsorControlChartTypes: IUseClinicalTrialAggChartType[] = ['sponsor-stack-bar']

const supportPhaseRadioTypes: IUseClinicalTrialAggChartType[] = ['register-line']

export function useClinicalTrialAggChart(options: IUseClinicalTrialAggChartOptions) {
  const { query = { type: 'group', must: [], filter: [] }, type } = options

  const fiveYearRange = getYearRangeFromNow(5, true)

  const { locale } = useLocale()

  const loading = ref(true)

  const isEmpty = ref(true)

  const model = reactive(initModel(unref(type)))

  const controls = reactive(initControls(unref(type)))

  const controlLayout = reactive(initControlLayout(unref(type), false))

  const controlLayoutFullscreen = reactive(initControlLayout(unref(type), true))

  const aggData = shallowRef<IAggregationData>({ aggregation_result: [{ items: [], aggregation_field: '', total: 0 }], total: 0 })

  const aggParams = computed(() => {
    const clonedQuery = cloneDeep(unref(query))
    const clonedAggregation = getAggregationItem(unref(type), cloneDeep(model))
    const params: IAggregationParams = {
      data_type: 'clinical_trial',
      query: clonedQuery,
      aggregation: [clonedAggregation],
    }
    return params
  })

  const categoryName = computed(() => {
    const chartType = unref(type)

    switch (chartType) {
      case 'exp-drug-stack-bar':
        return unref(locale) === 'cn' ? '药物' : 'Drug'
      case 'exp-drug-type-stack-bar':
        return unref(locale) === 'cn' ? '药物类型' : 'Drug Type'
      case 'indication-stack-bar':
        return unref(locale) === 'cn' ? '适应症' : 'Indication'
      case 'therapeutic-area-stack-bar':
        return unref(locale) === 'cn' ? '治疗领域' : 'Therapeutic Area'
      case 'sponsor-stack-bar':
        if (model.orgType?.selectedValue === 'all') {
          return unref(locale) === 'cn' ? '申办/合作机构' : 'Sponsor/Collaborator'
        } else if (model.orgType?.selectedValue === 'sponsor') {
          return unref(locale) === 'cn' ? '申办机构' : 'Sponsor'
        } else {
          return unref(locale) === 'cn' ? '合作机构' : 'Collaborator'
        }
      case 'multi-center-pie':
        return unref(locale) === 'cn' ? '多中心' : 'Multi-center'
      case 'phase-pie':
        return unref(locale) === 'cn' ? '试验分期' : 'Study Phase'
      case 'register-line':
        return unref(locale) === 'cn' ? '首次公开年' : 'First Post Year'
      default:
        return unref(locale) === 'cn' ? '国家/地区' : 'Country/Location'
    }
  })

  const countName = computed(() => (unref(locale) === 'cn' ? '临床数量' : 'No. of Clinical Trials'))

  const settingPanelTitle = computed(() => {
    const chartType = unref(type)
    if (chartType === 'sponsor-stack-bar') {
      return unref(locale) === 'cn' ? '机构排名' : 'Organization Range'
    }

    return ''
  })

  const settingDataPanelTitle = computed(() => {
    const chartType = unref(type)
    if (chartType === 'register-line') {
      return unref(locale) === 'cn' ? '试验分期' : 'Study Phase'
    }
    if (chartType === 'sponsor-stack-bar') {
      return unref(locale) === 'cn' ? '机构' : 'Organization'
    }

    return ''
  })

  const settingChartPanelTitle = computed(() => {
    const chartType = unref(type)
    if (chartType === 'exp-drug-type-stack-bar') {
      return unref(locale) === 'cn' ? '药物类型排名' : 'Drug Type Range'
    }
    if (chartType === 'exp-drug-stack-bar') {
      return unref(locale) === 'cn' ? '药物排名' : 'Drug Range'
    }
    if (chartType === 'indication-stack-bar') {
      return unref(locale) === 'cn' ? '适应症排名' : 'Indication Range'
    }
    if (chartType === 'therapeutic-area-stack-bar') {
      return unref(locale) === 'cn' ? '治疗领域排名' : 'Therapeutic Area Range'
    }
    if (chartType === 'sponsor-stack-bar') {
      return unref(locale) === 'cn' ? '机构排名' : 'Organization Range'
    }
    return ''
  })

  watch(model, () => fetchAggData(), { deep: true })

  async function fetchAggData() {
    loading.value = true
    const rt = await sharedCtx.service.trial.getAggregation(aggParams.value)
    if (rt.success) {
      isEmpty.value =
        !rt.data.aggregation_result ||
        rt.data.aggregation_result.length === 0 ||
        !rt.data.aggregation_result[0]?.items?.length ||
        rt.data.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
        rt.data.aggregation_result[0]?.items.every(
          (i) =>
            !!i.aggregation_result?.[0] &&
            (!i.aggregation_result?.[0].items ||
              i.aggregation_result?.[0].items.length === 0 ||
              i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
        )
      aggData.value = isEmpty.value ? { aggregation_result: [{ items: [], aggregation_field: '', total: 0 }], total: 0 } : rt.data
      loading.value = false
    } else {
      loading.value = false
      isEmpty.value = true
    }
  }

  function getAggregationItem(chartType: IUseClinicalTrialAggChartType, controlModel: ClinicalTrialAggChartModel): IAggregationItem {
    const clonedAggregationItem = cloneDeep(aggregationItemRecordType[chartType])

    const { top, rollup, orgType, phaseType } = controlModel

    if (!isUndefined(top)) {
      clonedAggregationItem.limit = top.selectedValue
    }

    if (!isUndefined(rollup) && !isUndefined(orgType)) {
      const sponsorValue = orgType
      if (sponsorValue?.selectedValue === 'sponsor') {
        clonedAggregationItem.aggregation_field = rollup ? 'SPONSOR_LEADER_ID_ROOT_ONEID' : 'NORMALIZED_ID_LEADER_ONEID'
      }
      if (sponsorValue?.selectedValue === 'collaborator') {
        clonedAggregationItem.aggregation_field = rollup ? 'SPONSOR_COLLABORATOR_ID_ROOT_ONEID' : 'NORMALIZED_ID_COLLABORATOR_ONEID'
      }
      if (sponsorValue?.selectedValue === 'all') {
        clonedAggregationItem.aggregation_field = rollup ? 'REGISTRY_ID_ROOT_ONEID' : 'REGISTRY_ID_ONEID'
      }
    }

    if (!isUndefined(phaseType)) {
      const selectedPhaseType = phaseType.selectedValue
      clonedAggregationItem.extended_bounds_from = fiveYearRange.from
      clonedAggregationItem.extended_bounds_to = fiveYearRange.to
      if (selectedPhaseType === 'all') {
        clonedAggregationItem.sub_aggregation = undefined
      }
    }

    return clonedAggregationItem
  }

  function initModel(chartType: IUseClinicalTrialAggChartType) {
    const model: ClinicalTrialAggChartModel = {
      display: 'global',
    }

    // 根据类型赋值不同的display初始值
    if (pieChartTypes.includes(chartType)) {
      model.display = 'pie'
    } else if (stackBarChartTypes.includes(chartType)) {
      model.display = 'horizontal-bar'
    } else if (lineChartTypes.includes(chartType)) {
      model.display = 'single-line'
    } else {
      model.display = 'global'
    }

    if (supportTop10OrTop20ControlChartTypes.includes(chartType)) {
      model.top = { selectedValue: 10 }
    }

    if (supportRollupControlChartTypes.includes(chartType)) {
      model.rollup = true
    }

    if (supportSponsorControlChartTypes.includes(chartType)) {
      model.orgType = { selectedValue: 'all' }
    }

    if (supportPhaseRadioTypes.includes(chartType)) {
      model.phaseType = { selectedValue: 'all' }
    }

    return model
  }

  function initControls(chartType: IUseClinicalTrialAggChartType) {
    const controls: IAnalysisChartControlItem<keyof ClinicalTrialAggChartModel>[] = []

    if (lineChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'single-line', value: 'single-line' },
          { type: 'table', value: 'table' },
        ],
      })

      if (supportPhaseRadioTypes.includes(chartType)) {
        controls.push({
          type: 'radio',
          bindField: 'phaseType',
          items: [
            { type: 'common', value: 'all', label: unref(locale) === 'cn' ? '全部' : 'All' },
            { type: 'common', value: 'independent', label: unref(locale) === 'cn' ? '分期' : 'Phase' },
          ],
        })
      }
    }

    if (pieChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'pie', value: 'pie' },
          { type: 'table', value: 'table' },
        ],
      })
    }

    if (worldChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'global', value: 'global' },
          { type: 'table', value: 'table' },
        ],
      })
    }

    if (stackBarChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'horizontal-bar', value: 'horizontal-bar' },
          { type: 'table', value: 'table' },
        ],
      })

      if (supportRollupControlChartTypes.includes(chartType)) {
        controls.push({
          type: 'rollup',
          bindField: 'rollup',
        })
      }

      if (supportSponsorControlChartTypes.includes(chartType)) {
        controls.push({
          type: 'radio',
          bindField: 'orgType',
          items: [
            {
              label: unref(locale) === 'cn' ? '申办/合作机构' : 'Sponsor/Collaborator',
              type: 'common',
              value: 'all',
            },
            {
              label: unref(locale) === 'cn' ? '申办机构' : 'Sponsor',
              type: 'common',
              value: 'sponsor',
            },
            {
              label: unref(locale) === 'cn' ? '合作机构' : 'Collaborator',
              type: 'common',
              value: 'collaborator',
            },
          ],
        })
      }

      if (supportTop10OrTop20ControlChartTypes.includes(chartType)) {
        controls.push({
          type: 'radio',
          bindField: 'top',
          items: [
            { label: unref(locale) === 'cn' ? '前10' : 'Top 10', type: 'common', value: 10 },
            { label: unref(locale) === 'cn' ? '前20' : 'Top 20', type: 'common', value: 20 },
          ],
        })
      }
    }

    return controls
  }

  function initControlLayout(chartType: IUseClinicalTrialAggChartType, isFullScreen = false) {
    const layoutItems: Array<IAnalysisChartControlLayoutItem<keyof ClinicalTrialAggChartModel>> = [
      {
        field: 'display',
        position: 'left',
      },
      {
        field: 'rollup',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'orgType',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'phaseType',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'top',
        position: isFullScreen ? 'chart-panel' : supportSponsorControlChartTypes.includes(chartType) ? 'setting' : 'middle',
      },
    ]

    return layoutItems
  }

  return {
    model,
    controls,
    controlLayout,
    controlLayoutFullscreen,
    aggData,
    categoryFieldName: categoryName,
    countFieldName: countName,
    fetchAggData,
    settingPanelTitle,
    loading,
    isEmpty,
    settingDataPanelTitle,
    settingChartPanelTitle,
  }
}
