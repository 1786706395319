import { ISearchParams } from '@patsnap/synapse_common_interface'
import type {
  IClinicalDetail,
  ICoverageData,
  IFeedListItem,
  IFeedListParams,
  IFeedStatus,
  IHomeFeedInfo,
  IHomeSearch,
  IHomeSearchQueryEntities,
} from '@pharmsnap/shared/types'
import {
  decryptDevStatus,
  decryptDicItem,
  decryptDrug,
  decryptMechanism,
  decryptNum,
  decryptOrg,
  decryptPatent,
  decryptTarget,
} from '@pharmsnap/shared/utils/encrypt/encrypt'
import { IHttp, IHttpWrap, IHttpWrapSuccess } from '../http/types'
import { decryptClinicalDetail } from '../utils/decrypt-response/clinical'
import { decryptNewsDate } from '../utils/decrypt-response/news'
import { BaseApi } from './baseApi'

const transformHomeSearchResult = (rt: IHttpWrapSuccess<IHomeSearch>) => {
  const xCorrelationId = rt.xCorrelationId
  const fontClassName = rt.fontClassName
  if (rt.data.clinical_trial_search_response) {
    rt.data.clinical_trial_search_response.response.total = decryptNum(rt.data.clinical_trial_search_response.response.total, xCorrelationId)
    rt.data.clinical_trial_search_response.response.items?.forEach((item) => {
      decryptClinicalDetail(item, xCorrelationId, fontClassName || '')
    })
  }
  if (rt.data.drug_search_response) {
    rt.data.drug_search_response.response.total = decryptNum(rt.data.drug_search_response.response.total, xCorrelationId)
  }
  if (rt.data.drug_deal_search_response) {
    rt.data.drug_deal_search_response.response.total = decryptNum(rt.data.drug_deal_search_response.response.total, xCorrelationId)
  }
  if (rt.data.drug_search_response && rt.data.drug_search_response.response?.card_items?.length) {
    rt.data.drug_search_response.response.card_items?.forEach((item) => {
      item.target_id_view?.forEach((_item) => {
        decryptTarget(_item, xCorrelationId)
        _item.fontClassName = fontClassName
      })
      if (item.global_highest_dev_status_view) {
        decryptDevStatus(item.global_highest_dev_status_view, xCorrelationId)
        item.global_highest_dev_status_view.fontClassName = fontClassName
      }
      if (item.global_highest_dev_status_termination_view) {
        decryptDevStatus(item.global_highest_dev_status_termination_view, xCorrelationId)
        item.global_highest_dev_status_termination_view.fontClassName = fontClassName
      }
      item.mechanism_action_id_view?.forEach((_item) => {
        decryptMechanism(_item, xCorrelationId)
        _item.fontClassName = fontClassName
      })
    })
  }
  if (rt.data.news_search_response) {
    rt.data.news_search_response.response.total = decryptNum(rt.data.news_search_response.response.total, xCorrelationId)
    rt.data.news_search_response.response.items?.forEach((item) => {
      decryptNewsDate(item, xCorrelationId)
      if (item.target_id_view?.length) {
        item.target_id_view.forEach((_item) => {
          _item.fontClassName = fontClassName
          decryptTarget(_item, xCorrelationId)
        })
      }
    })
  }
  if (rt.data.paper_search_response) {
    rt.data.paper_search_response.response.total = decryptNum(rt.data.paper_search_response.response.total, xCorrelationId)
  }
  if (rt.data.patent_search_response) {
    rt.data.patent_search_response.response.items?.forEach((item) => decryptPatent(item, xCorrelationId, fontClassName))
    rt.data.patent_search_response.response.total = decryptNum(rt.data.patent_search_response.response.total, xCorrelationId)
  }
  if (rt.data.clinical_trial_result_search_response) {
    rt.data.clinical_trial_result_search_response.response.total = decryptNum(
      rt.data.clinical_trial_result_search_response.response.total,
      xCorrelationId
    )
  }
  if (rt.data.target_search_response) {
    rt.data.target_search_response.response.total = decryptNum(rt.data.target_search_response.response.total, xCorrelationId)
    rt.data.target_search_response.response.card_items?.forEach((item) => {
      decryptTarget(item, xCorrelationId)
      item.fontClassName = fontClassName
    })
  }
  if (rt.data.disease_search_response) {
    rt.data.disease_search_response.response.total = decryptNum(rt.data.disease_search_response.response.total, xCorrelationId)
  }
  if (rt.data.organization_search_response) {
    rt.data.organization_search_response.response.total = decryptNum(rt.data.organization_search_response.response.total, xCorrelationId)
    rt.data.organization_search_response.response.card_items?.forEach((item) => {
      decryptOrg(item, xCorrelationId)
      item.fontClassName = fontClassName
    })
  }
  if (rt.data.drug_type_search_response) {
    rt.data.drug_type_search_response.response.total = decryptNum(rt.data.drug_type_search_response.response.total, xCorrelationId)
    rt.data.drug_type_search_response.response.card_items?.forEach((item) => {
      decryptDicItem(item, xCorrelationId)
      item.fontClassName = fontClassName
      item.related_target_id_view.forEach((_item) => {
        decryptTarget(_item, xCorrelationId)
        _item.fontClassName = fontClassName
      })
    })
  }
  if (rt.data.query_entities && rt.data.query_entities.length) {
    rt.data.query_entities.forEach((item) => {
      if (item.doc_type === 'target' || item.doc_type === 'target_extend') {
        if (item.details) {
          decryptTarget(item.details, xCorrelationId)
          item.details.fontClassName = fontClassName
        }
      }
      if (item.doc_type === 'org') {
        if (item.details) {
          decryptOrg(item.details, xCorrelationId)
          item.details.fontClassName = fontClassName
        }
      }
      if (item.doc_type === 'drug') {
        if (item.details) {
          item.details.target_id_view?.forEach((_item) => {
            decryptTarget(_item, xCorrelationId)
            _item.fontClassName = fontClassName
          })
        }
      }
      if (item.doc_type === 'drug_type') {
        if (item.details) {
          decryptDicItem(item.details, xCorrelationId)
          item.details.fontClassName = fontClassName
          item.details.related_target_id_view.forEach((_item) => {
            decryptTarget(_item, xCorrelationId)
            _item.fontClassName = fontClassName
          })
        }
      }
    })
  }
  if (rt.data.translational_medicine_search_response) {
    rt.data.translational_medicine_search_response.response.total = decryptNum(
      rt.data.translational_medicine_search_response.response.total,
      xCorrelationId
    )
  }
}
export class HomeApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getDataCoverage(): Promise<IHttpWrap<ICoverageData>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/home/data_coverage`),
    })
  }

  /**
   * 首页搜索，计搜索次数
   */
  async searchWithCalcLimits(
    data: ISearchParams & { language?: 'CN' | 'EN'; query_id?: string },
    signal?: AbortSignal
  ): Promise<IHttpWrap<IHomeSearch>> {
    const rt = (await this.http({
      method: 'POST',
      data,
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/home`),
      trackingName: 'HOME_PAGE',
    })) as IHttpWrap<IHomeSearch>
    if (rt.success && rt.data) {
      transformHomeSearchResult(rt)
    }
    return rt
  }
  /**
   * 首页搜索，不计搜索次数
   */
  async search(data: ISearchParams & { language?: 'CN' | 'EN'; query_id?: string }, signal?: AbortSignal): Promise<IHttpWrap<IHomeSearch>> {
    const rt = (await this.http({
      method: 'POST',
      data,
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/home/search`),
      trackingName: 'HOME_PAGE',
    })) as IHttpWrap<IHomeSearch>
    if (rt.success && rt.data) {
      transformHomeSearchResult(rt)
    }
    return rt
  }

  async getSmartSearchList(
    docType: IHomeSearchQueryEntities[number]['doc_type'],
    ids: string[],
    signal?: AbortSignal
  ): Promise<IHttpWrap<Array<IHomeSearchQueryEntities[number]['details']>>> {
    const rt = (await this.http({
      method: 'POST',
      data: {
        doc_type: docType,
        doc_ids: ids,
      },
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/retrieval/entity/list`),
    })) as IHttpWrap<Array<IHomeSearchQueryEntities[number]['details']>>
    if (rt.success && rt.data) {
      if (docType === 'target' || docType === 'target_extend') {
        if (rt.data.length) {
          rt.data.forEach((item) => {
            decryptTarget(item as any, rt.xCorrelationId)
            item.fontClassName = rt.fontClassName
          })
        }
      }
      if (docType === 'org') {
        if (rt.data.length) {
          rt.data.forEach((item) => {
            decryptOrg(item as any, rt.xCorrelationId)
            item.fontClassName = rt.fontClassName
          })
        }
      }

      if (docType === 'drug_type') {
        if (rt.data.length) {
          rt.data.forEach((item: any) => {
            decryptDicItem(item, rt.xCorrelationId, rt.fontClassName)
            item.fontClassName = rt.fontClassName
            item.related_target_id_view.forEach((_item: any) => {
              decryptTarget(_item, rt.xCorrelationId)
              _item.fontClassName = rt.fontClassName
            })
          })
        }
      }

      if (docType === 'drug') {
        if (rt.data.length) {
          rt.data.forEach((item: any) => {
            decryptDrug(item, rt.xCorrelationId, rt.fontClassName)
            item.fontClassName = rt.fontClassName
          })
        }
      }
    }
    return rt
  }

  async getFeedStatus(): Promise<IHttpWrap<IFeedStatus>> {
    return this.http({
      method: 'GET',
      url: this.getUrl('pharmsnap_webapi/1.0/home/feed/status'),
    })
  }

  async getFeedList(params: IFeedListParams): Promise<IHttpWrap<IHomeFeedInfo<IFeedListItem>>> {
    const { feedType, dataType, query, limit = 10, translation } = params
    const rt: IHttpWrap<IHomeFeedInfo<IFeedListItem>> = await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/home/feed/search/${dataType}/${feedType}`),
      data: {
        query,
        limit,
        translation,
      },
      cache: true,
    })
    if (rt.success) {
      if (dataType === 'news') {
        rt.data.search_response?.items?.forEach((item) => {
          if ('dmp_post_time' in item || 'post_time' in item) {
            decryptNewsDate(item, rt.xCorrelationId)
          }
        })
      }
      if (dataType === 'clinical_trial') {
        rt.data.search_response?.items?.forEach((item) => {
          decryptClinicalDetail(item as IClinicalDetail, rt.xCorrelationId, rt.fontClassName || '')
        })
      }
    }
    return rt
  }

  async sendFeedback(params: FormData): Promise<IHttpWrap<void>> {
    const rt: IHttpWrap<void> = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/feed'),
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    return rt
  }
}
