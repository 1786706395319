import dayjs from 'dayjs'

export const formatCopilotTimeStamp = (timestamp: number, isCN: boolean): string => {
  const now = dayjs()
  const target = dayjs(timestamp)

  if (now.isSame(target, 'day')) {
    return target.format('HH:mm')
  } else if (now.isSame(target, 'year')) {
    return target.format(isCN ? 'M月D日 HH:mm' : 'DD MMM HH:mm')
  } else if (now.year() > target.year()) {
    return target.format(isCN ? 'YYYY年M月D日 HH:mm' : 'DD MMM YYYY HH:mm')
  }
  return ''
}
