import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { IBaseDrugAdc } from '@patsnap/synapse_common_interface'
import { PropType, defineComponent } from '@vue/composition-api'
import { getDrugAdRenderContentList } from './config'

export const BDrugAdcFieldDesc = defineComponent({
  name: 'BDrugAdcFieldDesc',
  props: {
    data: {
      type: Object as PropType<IBaseDrugAdc>,
      required: true,
    },
    popperEnable: {
      type: Boolean,
      default: false,
    },
  },
  render() {
    const list = getDrugAdRenderContentList(this.data, this.$i18n)

    const contentList = list
      .filter((i) => !i.invalid(this.data))
      .map(({ label, content: Com }, index) => {
        const labelText = label
        return (
          <div class={['leading-5 text-sm text-text-t1', { 'mt-2': index !== 0 }]}>
            {labelText}
            <Com data={this.data}></Com>
          </div>
        )
      })

    if (contentList.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>

    return (
      <div class={this.popperEnable ? ['bg-white-default', 'border', 'border-gray-55', 'rounded', 'px-4', 'pt-4', 'pb-2', 'w-60'] : []}>
        {contentList}
      </div>
    )
  },
})
