import { IBaseDrugInfo } from '@patsnap/synapse_common_interface'
import { isNumber } from 'lodash'
import { showSingleToast } from '../toast'
/**
 * 药物下钻最多的数量
 */
export const MAX_LIMIT_DRUG_IDS = 1000
/**
 * 检查图表下钻的数量,超过限制就提示用户,并截取前1000条数据
 * @param ids
 * @param message
 * @returns
 */
export function checkGoingDownDrugListByDrugIds(ids: string[], message?: string): Promise<string[]> {
  return new Promise((resolve) => {
    if (isInvalidDrugIdCount(ids)) {
      message && showDrugIdMaxLimitWarning(message)
      setTimeout(() => {
        resolve(sliceMaxLimitDrugIds(ids))
      }, 3000)
    } else {
      resolve(ids)
    }
  })
}

export function checkGoingDownDrugListByDrugList(drugs: IBaseDrugInfo[], message?: string): Promise<IBaseDrugInfo[]> {
  return new Promise((resolve) => {
    if (isInvalidDrugIdCount(drugs.map((i) => i.drug_id))) {
      message && showDrugIdMaxLimitWarning(message)
      setTimeout(() => {
        resolve(drugs.slice(0, MAX_LIMIT_DRUG_IDS))
      }, 3000)
    } else {
      resolve(drugs)
    }
  })
}

export function isInvalidDrugIdCount(ids: string[] | number) {
  return (isNumber(ids) ? ids : ids.length) >= MAX_LIMIT_DRUG_IDS
}

export function showDrugIdMaxLimitWarning(message: string) {
  showSingleToast({
    type: 'warning',
    message,
  })
}

export function sliceMaxLimitDrugIds(ids: string[]) {
  return ids.slice(0, MAX_LIMIT_DRUG_IDS)
}
