import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { defineComponent, unref } from '@vue/composition-api'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterValueNext } from '../type'

export const BFilterAdvanceSelectedPanel = defineComponent({
  name: 'BFilterAdvanceSelectedPanel',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    renderSelectedItem(item: IFilterValueNext, cls = '') {
      const locale = unref(this.locale)

      const displayName = locale === 'cn' ? item.display_name_cn || item.display_name_en : item.display_name_en

      const fieldDisplayName = this.selectService?.getFieldLabelByField(item.field)

      return (
        <span
          key={`${item.identity}${item.field}${item.uuid}`}
          class={['max-w-full text-text-t1 inline-flex items-center text-xs rounded bg-blue-light pl-2 pr-1', cls]}
        >
          <span class="truncate flex-1">
            {fieldDisplayName ? <span class="font-semibold">{fieldDisplayName} : </span> : null}
            {<span>{displayName}</span>}
          </span>
          <GIcon
            nativeOn={{
              click: () => this.selectService.removeFilterValueFromTemporary(item),
            }}
            useSvgDefaultColor={true}
            class="cursor-pointer flex-shrink-0"
            size={24}
            svgName="SolidCloseMedium"
          ></GIcon>
        </span>
      )
    },
  },
  render() {
    return (
      <div class="border-gray-40 border rounded p-2 overflow-x-hidden overflow-y-auto">
        {this.selectService.showAllSelectedTemporaryList
          ? unref(this.selectService?.filterValueTemporaryList)?.map((item) => this.renderSelectedItem(item, 'mr-2 mb-2'))
          : unref(this.selectService?.filterValueTemporaryListExcludeReal)?.map((item) => this.renderSelectedItem(item, 'mr-2 mb-2'))}
      </div>
    )
  },
})
