/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IAggregationValue } from '@patsnap/synapse_common_interface'
import { GMiniSwitch } from '@pharmsnap/shared/components/ui/GMiniSwitch/GMiniSwitch'
import { GTooltip } from '@pharmsnap/shared/components/ui/GTooltip/GTooltip'
import { defineComponent, onMounted, unref } from '@vue/composition-api'
import { useFilterUiListRollupService } from '../compositions/useFilterListService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterAggValueNext } from '../type'
import { BFilterCheckbox } from './BFilterCheckbox'
import { BFilterItemSkeleton } from './BFilterItemSkeleton'
import { BFilterLimitContainer } from './BFilterLimitContainer'
import { BFilterNoResult } from './BFilterNoResult'

export const BFilterCheckboxRollupList = defineComponent({
  name: 'BFilterCheckboxRollupList',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      const div = document.createElement('div')
      div.className = 'g-tooltip'
      window.parent.document.body.append(div)
    })

    return useFilterUiListRollupService({ identity: props.identity, uiType: 'checkbox-rollup' })
  },
  methods: {
    async handleChange() {
      if (this.showMore) {
        await this.onCollapse()
      } else {
        await this.onExpand()
      }
    },
    async handleCheckToggle(val: boolean, item: IFilterAggValueNext) {
      await this.onCheck(item, val ? 'add' : 'remove')
    },
    renderRollupContent() {
      const { rollupTriggerText, rollupTriggerTooltip, data, onRollupChange } = this

      const renderTooltip = (content: JSX.Element) => {
        return (
          <GTooltip style="line-height:0; font-size:0;" theme="light" content={rollupTriggerTooltip}>
            {content}
          </GTooltip>
        )
      }

      const content = (
        <GMiniSwitch
          disabled={this.isLoading}
          value={!!data?.rollup}
          onChange={onRollupChange}
          width={16}
          active-color="#1976D2"
          inactive-color="#BCC2CC"
          active-text={rollupTriggerText}
        ></GMiniSwitch>
      )

      return rollupTriggerTooltip ? renderTooltip(content) : content
    },
  },
  render() {
    const { locale, triggerText, displayList, showExpandTrigger, handleChange } = this

    return (
      <div class="flex flex-col">
        <div class="mb-1">{this.renderRollupContent()}</div>
        {this.isLoading ? (
          <BFilterItemSkeleton></BFilterItemSkeleton>
        ) : this.isEmpty ? (
          <BFilterNoResult locale={unref(this.locale)}></BFilterNoResult>
        ) : (
          <BFilterLimitContainer
            list={displayList}
            triggerText={triggerText}
            showTrigger={showExpandTrigger}
            onChange={handleChange}
            scopedSlots={{
              default: ({ item }: { item: IFilterAggValueNext; index: number }) => {
                return (
                  <BFilterCheckbox
                    value={item}
                    showCount={true}
                    disabled={this.checkIsDisabled(item)}
                    selected={this.checkIsSelected(item)}
                    showPercent={false}
                    locale={unref(locale)}
                    onChange={(val: boolean) => this.handleCheckToggle(val, item)}
                    scopedSlots={{
                      default: ({ item }: { item: IAggregationValue }) => {
                        const content = this.$scopedSlots.item && this.$scopedSlots.item({ item })

                        if (content) return content

                        return null
                      },
                    }}
                  ></BFilterCheckbox>
                )
              },
            }}
          ></BFilterLimitContainer>
        )}
      </div>
    )
  },
})
