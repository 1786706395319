import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { getDisplayNameByLang } from '@patsnap/synapse_common_business'
import {
  E_AGGREGATION_TYPE,
  IAggregationItem,
  IAggregationParams,
  IAggregationSingleResult,
  IQueryItem,
  IQueryValueLang,
} from '@patsnap/synapse_common_interface'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { ISearchResult, ITreeAggregationItem, ITreeAggregationParams } from '@pharmsnap/shared/types/search'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { computed, defineComponent, PropType, watch } from '@vue/composition-api'
import { cloneDeep, includes, isArray, isEqual, isFunction } from 'lodash'
import { useLocale } from '../../../composition/useLocale'
import { IQueryService } from '../../../composition/useQueryService'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import $style from './BFilter.module.scss'
import { AdvanceFilter } from './components/biz/AdvanceFilter/AdvanceFilter'
import { FilterItem } from './components/biz/FilterItem/FilterItem'
import { FilterRefineKeywords } from './components/biz/FilterRefineKeywords/FilterRefineKeywords'
import { FilterLoading } from './components/ui/FilterLoading/FilterLoading'
import { FilterSelectCard } from './components/ui/FilterSelectCard/FilterSelectCard'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { IFilterConfig, IFilterItemConfig, IFilterNestFilterItem, IFilterSelectValue, IFilterService, IFilterValueArrItem } from './types'
import {
  aggregationData2keyMap,
  createRootField2FieldMap,
  filterValueArr2QueryItem,
  filterValueMap2FilterValueArr,
  getAggregationConfig,
  getAllFilterItems,
  getFilterConfigArr,
  queryItem2filterValueArr,
  splitCustomAggregationFields,
} from './utils/utils'

export const BFilter = defineComponent({
  name: 'BFilter',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterConfig>,
    },
    service: {
      required: true,
      type: Object as PropType<IFilterService>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    isNotReadStore: {
      default: false,
    },
  },
  setup(props, ctx) {
    const { locale } = useLocale()
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()
    const { filterItemConfigMap } = props.config
    /**
     * 所有配置项的数组
     */
    const filterConfigArr = getFilterConfigArr(props.config)
    /**
     * 上卷字段到原始字段的映射表
     */
    const rootField2FieldMap = createRootField2FieldMap(filterConfigArr)

    init()

    function init() {
      initConfigMap()
      initAllOpeningState()
      initFilterDefaultValue()
      batchGetAggregationData()
      props.service.actions.setFilterInited(true)
    }
    /**
     * 初始化filter的打开状态
     */
    function initFilterDefaultValue() {
      const filter = props.queryService.state.query.filter
      initAggregationByParentStateFromQuery(filter)
      if (filter.length) {
        setFilterQuery(queryItem2filterValueArr(filter, rootField2FieldMap, props.config.dataType))
      }
    }

    function initConfigMap() {
      props.service.actions.setConfigMap(props.config.filterItemConfigMap as Record<string, IFilterItemConfig<string>>)
    }
    /**
     * 初始化filter的打开状态
     */
    function initAllOpeningState() {
      const openingMap = filterConfigArr
        .filter((item) => (isFunction(item.defaultOpen) ? item.defaultOpen() : !!item.defaultOpen))
        .reduce((acc, curr) => {
          return {
            ...acc,
            [curr.field]: true,
          }
        }, {})
      props.service.actions.setAllOpeningMap(openingMap)
    }
    function initAggregationByParentStateFromQuery(queryItems: IQueryItem[]) {
      // 从过滤配置中找到需要支持rollup的过滤项，返回 Record<string/字段名, boolean>
      const defaultOpeningMap = filterConfigArr
        .filter((item) => !!item.aggregationByParentField)
        .reduce((acc, curr) => {
          return {
            ...acc,
            [curr.field]: !!curr.defaultAggregationByParent,
          }
        }, {})
      const openingMapFromQuery = queryItems.reduce((acc, curr) => {
        if (curr.type === 'field' && curr.fields.length) {
          const field = curr.fields[0]
          if (field in rootField2FieldMap) {
            // 如果存在，field 是一个rollup字段，但是存的是非rollup字段（filterConfig中的key都是非rollup字段）
            return {
              ...acc,
              [rootField2FieldMap[field]]: true,
            }
          } else if (field in defaultOpeningMap) {
            // 这里的field是非rollup字段，所以如果在defaultOpeningMap中存在，需要把rollup开关改为false
            return {
              ...acc,
              [field]: false,
            }
          }
        }
        return acc
      }, {} as Record<string, boolean>)
      const manualChangeAggregationByParentField = props.service.state.manualChangeAggregationByParentField
      if (manualChangeAggregationByParentField) {
        props.service.actions.removeManualChangeAggregationByParentField()
      }
      // 按照优先级对rollup开关的配置进行合并处理，手动操作记录优先级最高
      props.service.actions.setALLAggregationByParentMap({ ...defaultOpeningMap, ...openingMapFromQuery, ...manualChangeAggregationByParentField })
    }

    async function getNormalAggregationData(fields: string[]) {
      const batchAggregationConfig = getAggregationConfig(fields, props.config.filterItemConfigMap, props.service.state.aggregationByParentFieldMap)
      if (batchAggregationConfig.length) {
        fields.forEach((field) => props.service.actions.setLoadingMap(field, true))
        await getAggregationDataAndSetData(batchAggregationConfig)
        fields.forEach((field) => props.service.actions.setLoadingMap(field, false))
      }
    }

    async function getCustomAggregationAndSetData(field: string) {
      const getAggregationDataFunc = props.config.filterItemConfigMap[field]?.getAggregationDataFunc
      if (getAggregationDataFunc) {
        const aggregation = getAggregationConfig([field], props.config.filterItemConfigMap, props.service.state.aggregationByParentFieldMap)
        const res = await getAggregationData(aggregation, getAggregationDataFunc)

        props.service.actions.setFilterAllData({
          /**
           * 在切换字段的时候,会重新聚合所有打开的选项,但是没有打开的的选项的值也需要存储下
           */
          ...props.service.state.filterDataMap,
          ...aggregationData2keyMap(res, rootField2FieldMap),
        })
      }
    }

    async function getCustomAggregationData(fields: string[]) {
      fields.forEach((field) => props.service.actions.setLoadingMap(field, true))
      await Promise.all(fields.map((field) => getCustomAggregationAndSetData(field)))
      fields.forEach((field) => props.service.actions.setLoadingMap(field, false))
    }

    /**
     * 聚合单个/多个字段数据
     */
    function getAggregationDataByField(field: string | string[]) {
      const aggFieldList = isArray(field) ? field : [field]
      const [customAggFields, normalAggFields] = splitCustomAggregationFields(aggFieldList, props.config.filterItemConfigMap)
      normalAggFields.length && getNormalAggregationData(normalAggFields)
      customAggFields.length && getCustomAggregationData(customAggFields)
    }

    /**
     * 获取聚合数据
     * @param aggregationItems
     * @returns
     */
    function getAggregationData(
      aggregationItems: IAggregationItem[],
      getDataFn?: (data: IAggregationParams) => Promise<IAggregationSingleResult[]>
    ): Promise<IAggregationSingleResult[]> {
      let orgRollUp: boolean | undefined = undefined

      if (props.config.groupBy && props.config.supportOrgRollup) {
        orgRollUp = props.queryService.state.orgRollup
      }

      const params = {
        aggregation: aggregationItems,
        data_type: props.queryService.state.data_type,
        group_by: props.config.groupBy,
        org_roll_up: orgRollUp,
        query: {
          type: 'group',
          ...props.queryService.state.query,
        },
        ...(props.queryService.state.collapse ? { collapse: props.queryService.state.collapse } : undefined),
        ...(props.queryService.state.searchQueryType === 'STRUCTURE'
          ? {
              query_type: 'STRUCTURE',
              structure_query: cloneDeep(props.queryService.state.structureQuery),
            }
          : undefined),
      } as IAggregationParams

      if (getDataFn) return getDataFn(params)

      return props.config.getDataFn(params)
    }

    /**
     * 获取树形location filter数据
     * @param extraFilter
     * @param aggregationItems
     * @returns
     */
    function getTreeChildDataFn(extraFilter: IQueryItem[], aggregationItems: IAggregationItem[]): Promise<IAggregationSingleResult[]> {
      let orgRollUp: boolean | undefined = undefined
      if (props.config.groupBy) {
        if (includes(['dev_organization', 'original_dev_organization', 'organization'], props.config.groupBy)) {
          orgRollUp = props.queryService.state.orgRollup
        }
      }
      return props.config.getDataFn({
        aggregation: aggregationItems,
        data_type: props.queryService.state.data_type,
        group_by: props.config.groupBy,
        org_roll_up: orgRollUp,
        query: {
          type: 'group',
          ...props.queryService.state.query,
          filter: [...props.queryService.state.query.filter, ...extraFilter],
        },
        ...(props.queryService.state.collapse ? { collapse: props.queryService.state.collapse } : undefined),
      })
    }

    /**
     * slide的item获取最大与最小值
     * @param field
     * @returns
     */
    async function getSliderAggregationDataFn(field: string): Promise<IAggregationSingleResult[][]> {
      const minAggregationItem: IAggregationItem = {
        aggregation_field: field,
        aggregation_type: E_AGGREGATION_TYPE.MIN,
      }
      const maxAggregationItem: IAggregationItem = {
        aggregation_field: field,
        aggregation_type: E_AGGREGATION_TYPE.MAX,
      }
      return Promise.all([getAggregationData([minAggregationItem]), getAggregationData([maxAggregationItem])])
    }

    /**
     * 获取hierarchy的聚合数据
     * @param aggregationItems
     * @returns
     */
    function getTreeAggregationData(aggregationItems: IAggregationItem[]): Promise<ISearchResult<ITreeAggregationItem>> | undefined {
      const itemConfig = props.config.filterItemConfigMap[props.service.state.advanceSelectField]
      const params: ITreeAggregationParams = {
        aggregation: aggregationItems,
        data_type: props.queryService.state.data_type,
        group_by: props.config.groupBy,
        query: {
          type: 'group',
          ...props.queryService.state.query,
        },
      }
      return itemConfig?.getHierarchyDataFn && itemConfig.getHierarchyDataFn(params, true)
    }
    /**
     * 获取聚合数据并存储
     * @param batchAggregationConfig
     */
    async function getAggregationDataAndSetData(batchAggregationConfig: IAggregationItem[]) {
      const batchAggregationRt = await getAggregationData(batchAggregationConfig)
      props.service.actions.setFilterAllData({
        /**
         * 在切换字段的时候,会重新聚合所有打开的选项,但是没有打开的的选项的值也需要存储下
         */
        ...props.service.state.filterDataMap,
        ...aggregationData2keyMap(batchAggregationRt, rootField2FieldMap),
      })
    }
    /**
     * 获取高级聚合数据
     */
    async function getAdvanceAggregationDataByField(field: string) {
      const batchAggregationConfig = getAggregationConfig(
        [field],
        props.config.filterItemConfigMap,
        props.service.state.aggregationByParentFieldMap,
        10000
      )
      props.service.actions.setAdvanceFilterLoading(true)
      // count / a-z data
      const batchAggregationRt = await getAggregationData(batchAggregationConfig)
      props.service.actions.setAdvanceFilterAllData({
        ...props.service.state.advanceFilterDataMap,
        ...aggregationData2keyMap(batchAggregationRt, rootField2FieldMap),
      })
      const itemConfig = props.config.filterItemConfigMap[field]
      if (itemConfig?.getHierarchyDataFn) {
        // hierarchy data
        const treeAggregationRt = await getTreeAggregationData(batchAggregationConfig)
        treeAggregationRt &&
          props.service.actions.setAdvanceTreeData({
            ...props.service.state.advanceTreeDataMap,
            [field]: treeAggregationRt,
          })
      }
      props.service.actions.setAdvanceFilterLoading(false)
    }
    /**
     * 批量聚合所有的打开的filter
     */
    async function batchGetAggregationData() {
      props.service.actions.setGlobalLoading(true)
      const allFilterItems = getAllFilterItems(props.config.filterItems)
      const openingFilterItem = allFilterItems.filter((item) => {
        return props.service.state.openingMap[item]
      })

      const [customAggFields, normalAggFields] = splitCustomAggregationFields(openingFilterItem, props.config.filterItemConfigMap)

      if (customAggFields.length) {
        await Promise.all(customAggFields.map((field) => getCustomAggregationAndSetData(field)))
      }
      if (normalAggFields.length) {
        const batchAggregationConfig = getAggregationConfig(
          normalAggFields,
          props.config.filterItemConfigMap,
          props.service.state.aggregationByParentFieldMap
        )
        if (!batchAggregationConfig.length) {
          console.warn('batchGetAggregationData: 没有需要聚合的字段,跳过请求聚合')
          props.service.actions.setGlobalLoading(false)
          return
        }
        await getAggregationDataAndSetData(batchAggregationConfig)
      }

      props.service.actions.setGlobalLoading(false)
    }
    /**
     * 重新全部聚合
     */
    function onRefine(isExclude = false) {
      if (props.config.onBeforeRefine) {
        const fieldsInTime = Object.keys(props.service.state.filterValueMapInTime)
        const configAry = fieldsInTime.map((i) => props.config.filterItemConfigMap[i]).filter((i) => !!i) as IFilterItemConfig<string>[]
        const val = props.config.onBeforeRefine(configAry, {
          isFreeUser: isFreeUser.value,
        })
        if (!val.valid) {
          if (val.upgrade) {
            changeUpgrade(val.upgrade)
            // 关键词输入了内容，因把输入内容清除了，filter diff一直有变更，导致refine按钮无法消除
            // 主动清除
            props.service.actions.clearAll()
            return
          }
          return void 0
        }
      }
      props.service.actions.refine(isExclude)
    }

    function limitFilter(config: IFilterItemConfig<string>) {
      if (!props.config.onBeforeRefine) return true

      const val = props.config.onBeforeRefine([config], {
        isFreeUser: isFreeUser.value,
      })

      if (val.valid) return true

      val.upgrade && changeUpgrade({ ...val.upgrade, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL })

      return false
    }

    /**
     * 用本地语言展示聚合数据的标题
     * @param data
     * @returns
     */
    function getDisplayName<T extends IQueryValueLang>(data: T) {
      return getDisplayNameByLang(data, locale.value)
    }
    /**
     * 移除一个已选filter的值
     * @param field
     * @param valueKey
     */
    function onRemoveFilterValueArrByFilterValueItem(filterValue: IFilterSelectValue, filterItem: IFilterValueArrItem) {
      props.service.actions.removeFilterValueArrByFilterValueItem(filterValue, filterItem)
      onRefine()
    }
    /**
     * 移除改字段的所有值
     * @param field
     */
    function onRemoveFilterValueArrByFilterItem(filterItem: IFilterValueArrItem) {
      props.service.actions.removeFilterValueArrByFilterItem(filterItem)
      onRefine()
    }
    /**
     * 移除改字段的所有值
     * @param field
     */
    function onRemoveAllFilterValue() {
      props.service.actions.removeAllFilterValue()
      onRefine()
    }
    function syncFilterValue2QueryServiceValue(isExclude: boolean) {
      setFilterQuery(
        filterValueMap2FilterValueArr({
          data: props.service.state.filterValueMapInTime,
          isExclude,
          filterItemConfigMap: props.config.filterItemConfigMap,
          needPutInAGroupFilterItems: props.config.needPutInAGroupFilterItems,
        })
      )
      props.service.actions.setFilterValueMapInTime({})
    }
    function setFilterQuery(data: IFilterValueArrItem[]) {
      props.service.actions.addFilterValueArr(data)
      props.queryService.actions.setQuery(
        'filter',
        filterValueArr2QueryItem({
          data: props.service.state.filterValueArr,
          filterItemConfigMap: props.config.filterItemConfigMap,
          aggregationByParentMap: props.service.state.aggregationByParentFieldMap,
          dataType: props.config.dataType,
        })
      )
    }
    function toggleFreeUpgradeInfo() {
      changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER, trigger_point: 'FILTER_UPGRADE' })
    }

    const showUpgradeIcon = computed(() => isFreeUser.value && props.config.dataType === 'drug')

    watch(
      () => [
        props.queryService.state.query.must,
        props.queryService.state.query.filter,
        props.queryService.state.collapse,
        props.queryService.state.orgRollup,
        props.queryService.state.structureQuery,
      ],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        props.service.actions.removeAllFilterValue(true)
        initFilterDefaultValue()
        batchGetAggregationData()
      }
    )

    return {
      locale,
      onRefine,
      getAggregationDataByField,
      getAdvanceAggregationDataByField,
      getDisplayName,
      onRemoveAllFilterValue,
      onRemoveFilterValueArrByFilterItem,
      onRemoveFilterValueArrByFilterValueItem,
      syncFilterValue2QueryServiceValue,
      filterItemConfigMap,
      toggleFreeUpgradeInfo,
      showUpgradeIcon,
      getTreeChildDataFn,
      getSliderAggregationDataFn,
      limitFilter,
    }
  },
  methods: {
    renderRefineKeywords() {
      const allFilterItems = getAllFilterItems(this.config.filterItems)

      if (!allFilterItems.length) return null

      const refineKeywordsField = allFilterItems.find((field) => {
        const config = this.config.filterItemConfigMap[field]
        return config && config.type === 'text'
      })

      if (!refineKeywordsField) return null

      const config = this.config.filterItemConfigMap[refineKeywordsField]

      if (!config) return null

      return <FilterRefineKeywords class="mb-4" onRefine={this.onRefine} config={config}></FilterRefineKeywords>
    },
    renderFilterSelectedValue() {
      const filterValueArr = this.service.state.filterValueArr.filter((f) => !this.config.filterItemConfigMap[f.field]?.hidden)
      if (filterValueArr.length === 0) {
        return null
      }
      return (
        <div class="mb-4">
          <div class="flex items-center">
            <div class="text-xs text-text-t3 flex-1">{this.$tc('BFilter.selectValue')}</div>
            <div
              class="text-blue-default cursor-pointer text-xs p-1 hover:bg-gray-40 rounded"
              data-testid="b-filter__clear-all"
              onClick={this.onRemoveAllFilterValue}
            >
              {this.$tc('BFilter.clearAll')}
            </div>
          </div>
          {filterValueArr
            .filter(({ value, children }) => !!value.length || !!children?.length)
            .map((filterItem) => {
              const { field, value, condition, children } = filterItem
              if (children?.length) {
                const firstChildren = children[0]
                const firstField = firstChildren.field
                const config = this.filterItemConfigMap[firstField] as IFilterItemConfig
                if (!config) {
                  console.error(`renderFilterValue: 字段"${field} filter配置没有找到,请检查"`)
                  return <div>error</div>
                }
                const filterLabel = config.label ? config.label[this.locale] : getFieldNameByLang(this.config.fieldLangMap, firstField, this.locale)
                const mergeValue: IFilterSelectValue[] = children.reduce((acc, curr) => {
                  return [...acc, ...curr.value.map((item) => ({ ...item, filterItem: curr }))]
                }, [] as IFilterSelectValue[])
                return (
                  <FilterSelectCard
                    filterLabel={filterLabel}
                    condition={condition}
                    filterValue={mergeValue}
                    onRemoveAll={() => {
                      this.onRemoveFilterValueArrByFilterItem(filterItem)
                    }}
                    onRemoveValue={(value: IFilterSelectValue) => {
                      this.onRemoveFilterValueArrByFilterValueItem(value, filterItem)
                    }}
                  ></FilterSelectCard>
                )
              }
              const config = this.filterItemConfigMap[field] as IFilterItemConfig
              if (!config) {
                console.error(`renderFilterValue: 字段"${field} filter配置没有找到,请检查"`)
                return <div>error</div>
              }
              const filterLabel = config.label ? config.label[this.locale] : getFieldNameByLang(this.config.fieldLangMap, field, this.locale)
              return (
                <FilterSelectCard
                  filterLabel={filterLabel}
                  condition={condition}
                  filterValue={value}
                  onRemoveAll={() => {
                    this.onRemoveFilterValueArrByFilterItem(filterItem)
                  }}
                  onRemoveValue={(value: IFilterSelectValue) => {
                    this.onRemoveFilterValueArrByFilterValueItem(value, filterItem)
                  }}
                ></FilterSelectCard>
              )
            })}
        </div>
      )
    },
    renderFilterUpgradeContent() {
      const allFilterItems = getAllFilterItems(this.config.filterItems)

      if (!allFilterItems.length) return null

      const fields = allFilterItems.filter((field) => {
        const config = this.config.filterItemConfigMap[field]
        return config && config.type !== 'text'
      })

      if (!fields.length) return null

      return (
        <div class="text-xs text-text-t3 flex items-center">
          {this.$tc('BFilter.filter')}
          {this.showUpgradeIcon && (
            <GTooltip placement="top" content={this.$tc('BFilter.upgrade')}>
              <div class={$style.freeUpgrade} onClick={this.toggleFreeUpgradeInfo}>
                <GIcon svgName="Upgrade" style="color: #1854f1"></GIcon>
              </div>
            </GTooltip>
          )}
        </div>
      )
    },
    renderFilterSelectTree(arr: Array<IFilterNestFilterItem>, depth: number) {
      return arr.map((field) => {
        if (Array.isArray(field)) {
          const [parentFiled, restField] = field

          const config = this.config.filterItemConfigMap[parentFiled]

          if (!config) return null

          const childrenContent = this.renderFilterSelectTree(Array.isArray(restField) ? restField : [restField], depth + 1)

          return (
            <FilterItem contentBorder={false} labelBorder={true} key={parentFiled} config={config} fieldLangMap={this.config.fieldLangMap}>
              <template slot="default">{childrenContent}</template>
            </FilterItem>
          )
        } else {
          const config = this.config.filterItemConfigMap[field]

          if (!config) return null

          if (config.type === 'text') return null

          return (
            <FilterItem
              {...{
                on: {
                  aggregation: this.getAggregationDataByField,
                },
              }}
              data-testid={`filter-item--${field}`}
              key={field}
              style={{ marginLeft: `${depth * 16}px` }}
              config={config}
              filterConfigs={this.config}
              fieldLangMap={this.config.fieldLangMap}
              limitFilter={() => this.limitFilter(config)}
              getTreeChildDataFn={this.getTreeChildDataFn}
              getSliderAggregationDataFn={this.getSliderAggregationDataFn}
            ></FilterItem>
          )
        }
      })
    },
  },
  render() {
    if (this.service.state.loading) {
      return (
        <div class="p-2">
          <FilterLoading></FilterLoading>
        </div>
      )
    }
    return (
      <div class="h-full p-2 pr-0 relative">
        <div>
          {this.service.state.isShowAdvanceDialog && (
            <AdvanceFilter
              config={this.config}
              {...{
                on: {
                  aggregation: this.getAdvanceAggregationDataByField,
                },
              }}
            ></AdvanceFilter>
          )}
        </div>
        <div class="h-full overflow-y-auto pr-4">
          {this.$slots.header}
          {this.renderRefineKeywords()}
          {this.$slots.viewManual}
          {this.renderFilterSelectedValue()}
          {this.renderFilterUpgradeContent()}
          {this.renderFilterSelectTree(this.config.filterItems, 0)}
        </div>
        <div class={$style.controlBtn} v-show={this.service.state.hasChange}>
          <button
            data-testid="b-filter__refine"
            class="pt-ui-btn"
            style="border-radius:0 4px 4px 0;"
            type="button"
            data-type="submit"
            onClick={() => this.onRefine(false)}
          >
            {this.$tc('BFilter.refine')}
          </button>
          <button
            data-testid="b-filter__exclude"
            class="pt-ui-btn mt-1"
            style="border-radius:0 4px 4px 0;"
            type="button"
            data-type="danger"
            onClick={() => this.onRefine(true)}
          >
            {this.$tc('BFilter.exclude')}
          </button>
        </div>
      </div>
    )
  },
})
