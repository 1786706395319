import { computed, onUnmounted, ref, Ref, unref, watch } from '@vue/composition-api'

export interface Position {
  x: number
  y: number
}

export type PointerType = 'mouse' | 'touch' | 'pen'
export interface UseDraggableOptions {
  /**
   * Only start the dragging when click on the element directly
   *
   * @default false
   */
  exact?: boolean

  /**
   * Prevent events defaults
   *
   * @default false
   */
  preventDefault?: boolean

  /**
   * Prevent events propagation
   *
   * @default false
   */
  stopPropagation?: boolean

  /**
   * Element to attach `pointermove` and `pointerup` events to.
   *
   * @default window
   */
  container?: HTMLElement | SVGElement | null | undefined

  /**
   * Pointer types that listen to.
   *
   * @default ['mouse', 'touch', 'pen']
   */
  pointerTypes?: PointerType[]

  /**
   * Initial position of the element.
   *
   * @default { x: 0, y: 0 }
   */
  initialValue?: Position

  /**
   * Callback when the dragging starts. Return `false` to prevent dragging.
   */
  onStart?: (position: Position, event: PointerEvent) => void | false

  /**
   * Callback during dragging.
   */
  onMove?: (position: Position, event: PointerEvent) => void

  /**
   * Callback when dragging end.
   */
  onEnd?: (position: Position, event: PointerEvent) => void
}

/**
 * Make elements draggable.
 *
 * @see https://vueuse.org/useDraggable
 * @param target
 * @param options
 */
export function useDraggable(target: Ref<HTMLElement | SVGElement | null | undefined>, options: UseDraggableOptions = {}) {
  const position = ref<Position>(unref(options.initialValue) ?? { x: 0, y: 0 })
  const pressedDelta = ref<
    Position & {
      width: number
      height: number
    }
  >()

  const containerElement = computed(() => {
    return options.container ?? target.value?.parentElement ?? document.body
  })
  const filterEvent = (e: PointerEvent) => {
    if (options.pointerTypes) return options.pointerTypes.includes(e.pointerType as PointerType)
    return true
  }

  const handleEvent = (e: PointerEvent) => {
    if (options.preventDefault) e.preventDefault()
    if (options.stopPropagation) e.stopPropagation()
  }

  const start = (e: PointerEvent) => {
    if (!filterEvent(e)) return
    if (options.exact && e.target !== unref(target)) return
    const rect = unref(target)!.getBoundingClientRect()
    const pos = {
      x: e.pageX - rect.left,
      y: e.pageY - rect.top,
      width: rect.width,
      height: rect.height,
    }
    if (options.onStart?.(pos, e) === false) return
    pressedDelta.value = pos
    handleEvent(e)
  }
  const move = (e: PointerEvent) => {
    if (!filterEvent(e)) return
    if (!pressedDelta.value) return
    const containerRect = containerElement.value.getBoundingClientRect()
    position.value = {
      x: Math.min(Math.max(e.pageX - pressedDelta.value.x - containerRect.left, 0), containerRect.width - pressedDelta.value.width),
      y: Math.min(Math.max(e.pageY - pressedDelta.value.y - containerRect.top, 0), containerRect.height - pressedDelta.value.height),
    }
    options.onMove?.(position.value, e)
    handleEvent(e)
  }
  const end = (e: PointerEvent) => {
    if (!filterEvent(e)) return
    if (!pressedDelta.value) return
    pressedDelta.value = undefined
    options.onEnd?.(position.value, e)
    handleEvent(e)
  }

  watch(
    () => unref(target),
    (el) => {
      if (!el) return
      el.addEventListener('pointerdown', start as EventListenerOrEventListenerObject)
      containerElement.value.addEventListener('pointermove', move as EventListenerOrEventListenerObject, true)
      containerElement.value.addEventListener('pointerup', end as EventListenerOrEventListenerObject, true)
    },
    { immediate: true, flush: 'post' }
  )
  onUnmounted(() => {
    const el = unref(target)
    if (!el) return
    el.removeEventListener('pointerdown', start as EventListenerOrEventListenerObject)
    containerElement.value.removeEventListener('pointermove', move as EventListenerOrEventListenerObject)
    containerElement.value.removeEventListener('pointerup', end as EventListenerOrEventListenerObject)
  })

  return {
    position,
    isDragging: computed(() => !!pressedDelta.value),
    style: computed(() => `left:${position.value.x}px;top:${position.value.y}px;`),
  }
}

export type UseDraggableReturn = ReturnType<typeof useDraggable>
