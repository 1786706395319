/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthStore, useElementSize, useLocale } from '@pharmsnap/shared/composition'
import { getChartWaterMark } from '@pharmsnap/shared/utils'
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  shallowRef,
  toRefs,
  unref,
  watch,
} from '@vue/composition-api'
import type { EChartsOption } from 'echarts'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, GraphChart, HeatmapChart, LineChart, MapChart, PieChart, RadarChart, ThemeRiverChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  AxisPointerComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GeoComponent,
  GraphicComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  SingleAxisComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
} from 'echarts/components'
import { EChartsType, use } from 'echarts/core'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { cloneDeep, noop } from 'lodash'
import tippy, { Instance } from 'tippy.js'
import VChart from 'vue-echarts'
import { IEchartsRefOptions } from '../../compositions/useAnalysisRegisterChartRefs'
import { IAnalysisDisplayIconType } from '../../type'

use([
  BarChart,
  LineChart,
  HeatmapChart,
  MapChart,
  PieChart,
  ThemeRiverChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GeoComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  TransformComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  AxisPointerComponent,
  SingleAxisComponent,
  LabelLayout,
  CanvasRenderer,
  RadarChart,
  GraphChart,
  GraphicComponent,
])

export const BAnalysisEChart = defineComponent({
  name: 'BAnalysisEChart',
  props: {
    option: {
      type: Object as PropType<EChartsOption>,
      default: () => ({}),
    },
    legendOption: {
      type: Object as PropType<{ watchLegendSelect?: boolean }>,
      default: () => ({ watchLegendSelect: false }),
    },
    axisPopperOption: {
      type: Object as PropType<{ xPopperEnable?: boolean; yPopperEnable?: boolean }>,
      default: () => ({ xPopperEnable: false, yPopperEnable: false }),
    },
    displayType: {
      type: String as PropType<IAnalysisDisplayIconType>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { option, axisPopperOption, legendOption } = toRefs(props)

    const containerRef = ref<HTMLDivElement>()

    const { isCN } = useLocale()

    const {
      getters: { isFreeUser, isTrialUser },
    } = useAuthStore()

    const { width: containerWidth, height: containerHeight } = useElementSize(containerRef)

    const vChartRef = ref<InstanceType<typeof VChart>>(null)

    const mouseoverHasRegistered = ref(false)

    const axisPopperTriggerRef = shallowRef<HTMLDivElement | null>(null)

    const xAxisPopperContentRef = shallowRef<HTMLDivElement | null>(null)

    const yAxisPopperContentRef = shallowRef<HTMLDivElement | null>(null)

    const axisPopperIns = shallowRef<Instance | null>(null)

    const axisPopperInfo = reactive({
      axis: 'none',
      angle: 0,
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    })

    const axisTriggerStyle = computed(() => {
      const style: any = {
        width: `${axisPopperInfo.width}px`,
        height: `${axisPopperInfo.height}px`,
        left: `${axisPopperInfo.left}px`,
        top: `${axisPopperInfo.top}px`,
      }
      if (axisPopperInfo.angle !== 0) {
        style.transform = `rotate(${axisPopperInfo.angle}deg)`
        style['transform-origin'] = 'left top'
      }

      return style
    })

    const xAxisPopperEnable = computed(() => axisPopperOption.value.xPopperEnable ?? true)

    const yAxisPopperEnable = computed(() => axisPopperOption.value.yPopperEnable ?? true)

    const watchLegendSelect = computed(() => legendOption.value.watchLegendSelect ?? false)

    const realOption = shallowRef<EChartsOption>()

    function handleMouseover(params: any) {
      if (params.componentType === 'xAxis') {
        axisPopperInfo.axis = 'x'
        emit('xAxisMouseover', params)
        updateAxisPopperInfoByTarget(params?.event?.target)
      } else if (params.componentType === 'yAxis') {
        axisPopperInfo.axis = 'y'
        emit('yAxisMouseover', params)
        updateAxisPopperInfoByTarget(params?.event?.target)
      } else {
        updateAxisPopperInfoWithHidden()
      }
    }

    function updateRealOption() {
      const clonedOption = cloneDeep(unref(option))

      const { xAxis, yAxis, graphic = [{}] } = clonedOption

      const xAxisArray = Array.isArray(xAxis) ? xAxis : [xAxis]

      const yAxisArray = Array.isArray(yAxis) ? yAxis : [yAxis]

      xAxisArray.forEach((item) => {
        item && (item.triggerEvent = xAxisPopperEnable.value)
      })

      yAxisArray.forEach((item) => {
        item && (item.triggerEvent = yAxisPopperEnable.value)
      })

      const graphicOption = Array.isArray(graphic) ? graphic : [graphic]

      const hasAddWaterMark = graphicOption.some((item) => {
        if ('elements' in item) {
          return (item.elements || []).some((element) => element.id === 'waterMarkId')
        }
        return false
      })

      if (!hasAddWaterMark && (isFreeUser.value || isTrialUser.value)) {
        const latestGraphic = graphicOption[graphicOption.length - 1]
        const { waterMark, imgHeight, imgWidth } = getChartWaterMark(isCN.value)

        const waterMarkConfig = {
          type: 'image',
          id: 'waterMarkId',
          style: {
            image: waterMark,
            width: imgWidth,
            height: imgHeight,
          },
          left: (unref(containerWidth) - imgWidth) / 2,
          top: '30%',
          cursor: 'auto',
        }

        if ('elements' in latestGraphic) {
          latestGraphic.elements = [...(latestGraphic.elements || []), waterMarkConfig]
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          latestGraphic.elements = [waterMarkConfig]
        }

        realOption.value = {
          ...clonedOption,
          graphic: graphicOption,
        }
      } else {
        realOption.value = clonedOption
      }
    }

    function updateAxisPopperInfoWithHidden() {
      // 移走触发器
      axisPopperInfo.axis = 'none'
      axisPopperInfo.top = -10000
      axisPopperInfo.left = -10000
    }

    function updateAxisPopperInfoByTarget(target: any) {
      if (!target) return updateAxisPopperInfoWithHidden()
      const originRect = target.getBoundingRect().clone()
      const transformedRect = target.getBoundingRect().clone()
      transformedRect.applyTransform(target.getComputedTransform())
      const { width, height } = originRect
      const { x, y, height: transformedHeight } = transformedRect
      const angle = Math.floor(extractRotationAngle(target.getComputedTransform()))
      axisPopperInfo.width = width + 10
      axisPopperInfo.height = height + 4
      axisPopperInfo.left = x
      axisPopperInfo.top = angle === 0 ? y : y + transformedHeight - height / 2
      axisPopperInfo.angle = angle
    }

    function extractRotationAngle(matrix: number[]) {
      // 提取矩阵元素
      const aa = matrix[0]
      const ab = matrix[1]

      // 计算旋转角度
      const angleRad = Math.atan2(ab, aa)

      // 将弧度转换为角度并返回
      const angleDeg = angleRad * (180 / Math.PI)
      return angleDeg
    }

    function handleLegendSelectedChange(params: any) {
      emit('legendSelectedChange', params)
    }

    function registerLegendSelectedChangeEvent() {
      getEchartIns()?.on('legendselectchanged', handleLegendSelectedChange)
    }

    function unregisterLegendSelectedChangeEvent() {
      getEchartIns()?.off('legendselectchanged', handleLegendSelectedChange)
    }

    function registerMouseoverEvent() {
      getEchartIns()?.on('mouseover', handleMouseover)
      mouseoverHasRegistered.value = true
    }

    function unregisterMouseoverEvent() {
      getEchartIns()?.off('mouseover', handleMouseover)
      mouseoverHasRegistered.value = false
    }

    function createAxisPopperIns() {
      if (axisPopperTriggerRef.value) {
        axisPopperIns.value = tippy(axisPopperTriggerRef.value, {
          placement: 'right-start',
          interactive: true,
          trigger: 'mouseenter',
          animation: 'shift-toward',
          appendTo: document.body,
          delay: [50, 50],
          arrow: false,
          allowHTML: true,
          offset: [0, 0],
          onShow: (ins) => {
            if (axisPopperInfo.axis === 'x' && xAxisPopperContentRef.value && xAxisPopperEnable.value) {
              ins.setContent(xAxisPopperContentRef.value)
              return
            }
            if (axisPopperInfo.axis === 'y' && yAxisPopperContentRef.value && yAxisPopperEnable.value) {
              ins.setContent(yAxisPopperContentRef.value)
              return
            }

            return false
          },
        })
      }
    }

    function destroyAxisPopperIns() {
      axisPopperIns.value?.destroy()
      axisPopperIns.value = null
    }

    watch(option, () => {
      updateRealOption()
    })
    function getEchartIns() {
      return vChartRef?.value?.chart as EChartsType | undefined
    }

    watch([xAxisPopperEnable, yAxisPopperEnable], ([x, y]) => {
      updateRealOption()
      if (x || y) {
        if (!mouseoverHasRegistered.value) {
          registerMouseoverEvent()
        }
        if (!axisPopperIns.value) {
          createAxisPopperIns()
        }
      } else {
        unregisterMouseoverEvent()
        destroyAxisPopperIns()
      }
    })

    watch([watchLegendSelect], () => {
      if (watchLegendSelect.value) {
        registerLegendSelectedChangeEvent()
      }
    })

    watch([containerWidth, containerHeight], () => {
      updateRealOption()
      getEchartIns()?.resize()
    })

    onMounted(async () => {
      if (watchLegendSelect.value) {
        registerLegendSelectedChangeEvent()
      }
      if (xAxisPopperEnable.value || yAxisPopperEnable.value) {
        registerMouseoverEvent()
        createAxisPopperIns()
      }
      updateRealOption()
    })

    onBeforeUnmount(() => {
      unregisterMouseoverEvent()
      unregisterLegendSelectedChangeEvent()
      destroyAxisPopperIns()
    })

    const registerEChartsRef = inject('registerEChartsRef', noop) as (data: IEchartsRefOptions) => void

    const unregisterEChartsRef = inject('unregisterEChartsRef', noop) as (chartIns: EChartsType) => void

    onMounted(() => {
      if (vChartRef.value) {
        if (getEchartIns() && props.displayType && registerEChartsRef) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          registerEChartsRef({ chartIns: getEchartIns()!, displayType: props.displayType })
        }
      }
    })

    onBeforeUnmount(() => {
      if (vChartRef.value) {
        if (getEchartIns()) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          unregisterEChartsRef(getEchartIns()!)
        }
      }
    })

    return {
      realOption,
      vChartRef,
      getEchartIns,
      xAxisPopperContentRef,
      yAxisPopperContentRef,
      axisPopperTriggerRef,
      xAxisPopperEnable,
      yAxisPopperEnable,
      axisTriggerStyle,
      containerRef,
      containerHeight,
      // exportChartImage,
    }
  },
  render() {
    return (
      <div>
        <div ref="containerRef" class="relative h-full overflow-hidden">
          <VChart
            class={[this.containerHeight === 0 ? 'invisible' : 'visible']}
            ref="vChartRef"
            option={this.realOption}
            autoResize={false}
            {...{ on: this.$listeners }}
          ></VChart>
          <span
            ref="axisPopperTriggerRef"
            onClick={() => this.$emit('axisClick')}
            style={this.axisTriggerStyle}
            class="inline-block absolute cursor-pointer z-[1] bg-transparent"
          ></span>
          <div class="hidden">
            <div ref="xAxisPopperContentRef" class="text-text-t1">
              {this.$slots.xAxisPopper}
            </div>
            <div ref="yAxisPopperContentRef" class="text-text-t1">
              {this.$slots.yAxisPopper}
            </div>
            {this.$slots.extra}
          </div>
        </div>
      </div>
    )
  },
})

export type BAnalysisEChartIns = InstanceType<typeof BAnalysisEChart>
