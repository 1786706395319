import chromeIcon from '@pharmsnap/pharmsnap-web/assets/browser/chrome.png'
import edgeIcon from '@pharmsnap/pharmsnap-web/assets/browser/edge.png'
import firefoxIcon from '@pharmsnap/pharmsnap-web/assets/browser/firefox.png'
import { computed, defineComponent } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { useLocale } from 'pharmsnapMF_shared/composition'
import $style from './ChromeBar.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
const IS_US = /patsnap\.com$/.test(window.location.host)

export const ChromeBar = defineComponent({
  name: 'ChromeBar',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    browserToLow: {
      type: Boolean,
      default: false,
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { ts } = useLocale()
    const browserList = computed(() => {
      return [
        {
          title: ts('ChromeBar.downLoadGoogle'),
          icon: chromeIcon,
          url: IS_US ? 'https://www.google.com/chrome/' : 'https://www.google.cn/chrome/',
        },
        {
          title: ts('ChromeBar.downLoadEdge'),
          icon: edgeIcon,
          url: 'https://www.microsoft.com/edge/',
        },
        {
          title: ts('ChromeBar.downLoadFirefox'),
          icon: firefoxIcon,
          url: 'https://www.mozilla.org/firefox/new/',
        },
      ]
    })
    function listenerIgnore() {
      ctx.emit('ignore')
    }
    return { browserList, listenerIgnore }
  },
  methods: {
    renderChromeBarTips() {
      if (this.isSafari) {
        return <div>{this.$t('ChromeBar.lowSafariDesc')}</div>
      }
      return [this.browserToLow ? this.$t('ChromeBar.browserToLow') : '', this.$t('ChromeBar.desc')]
    },
  },
  render() {
    return (
      <div class="px-20 py-8 text-center chrome-bar w-full h-40" style="background:rgba(6, 22, 50)">
        <PtIcon
          icon="SolidCloseBig"
          class={[$style.icon, 'w-6 h-6 text-white-default absolute cursor-pointer']}
          onMousedown={this.listenerIgnore}
        ></PtIcon>
        {/*<GIcon class="text-white absolute cursor-pointer" name="icon-close-big" onClick={listenerIgnore} style={{ top: '8px', right: '8px' }}></GIcon>*/}
        <div class="mr-8 text-white-default leading-6">{this.renderChromeBarTips()}</div>
        <div class="flex items-center justify-center mt-2">
          {this.browserList.map((item) => {
            return (
              <a class={[$style.browserBtn, 'mr-2']} href={item.url} target="_blank">
                <span class={[$style.browserBtnIcon, 'mr-2']} style={`background-image: url('${item.icon}')`}></span>
                <span class="text-base text-black">{item.title}</span>
              </a>
            )
          })}
        </div>
      </div>
    )
  },
})
