import { IQueryDataType } from '@patsnap/synapse_common_interface'
import { downLoadFileByUrl } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useLocale } from '@pharmsnap/shared/composition'
import { getCurrentInstance } from '@vue/composition-api'
import { showGDialog } from '../components'
import { ICommonListConfig } from '../components/business/BCommonList/types'
import { createExportPoller } from '../components/business/BCommonList/utils'
import { BExportDialog } from '../components/business/BExportDialog/BExportDialog'
import { IExportConfig } from '../components/ui/GExportDialog/GExportDialogType'
import { ILang } from '../types'
import { showSingleToast } from '../utils'
import { useAuthStore } from './useAuthStore'
import { IQueryService } from './useQueryService'

export function useExportDrug() {
  const {
    actions,
    getters: { isIncognito },
  } = useAuthStore()
  const { localeUpcase } = useLocale()
  const ins = getCurrentInstance()
  const { isCN, ts } = useLocale()

  function handleExportPoller(id: string) {
    createExportPoller(id)
      .then((res) => {
        downLoadFileByUrl(res.data.download_url)
        showSingleToast({
          message: ins?.proxy.$t('export.exportSuccessInfo') as string,
          type: 'success',
          showClose: true,
          duration: 3000,
        })
      })
      .catch((errors) => {
        if (Array.isArray(errors)) {
          const length = errors.length
          if (length >= 1) {
            const lastError = errors[length - 1]
            if (lastError.success && lastError.data.export_status === 'PENDING') {
              return showViewInHistory()
            }
          }
        }
        showSingleToast({
          message: ins?.proxy.$t('export.exportFail') as string,
          type: 'warning',
        })
      })
  }

  function showViewInHistory() {
    showSingleToast({
      message: `${ins?.proxy.$t('export.exportTimeout', {
        page: `<a href="/search-history?history_type=export" target="_blank" style="cursor: pointer; color: yellow">${ins.proxy.$t(
          'export.viewPage'
        )}</a>`,
      })}`,
      type: 'success',
      dangerouslyUseHTMLString: true,
    })
  }

  async function handleExportDrug(params: {
    disable?: boolean
    showUpgrade?: boolean
    dataType: IQueryDataType
    lang: ILang
    checkedIdList: string[]
    checkedMap: Record<string, any>
    commonListConfig: ICommonListConfig
    queryService: IQueryService
    resultExportLength: number
    limitPreExport: number
    showLimitPreExportTip?: boolean
  }) {
    const {
      disable,
      showUpgrade,
      checkedIdList,
      checkedMap,
      commonListConfig,
      queryService,
      resultExportLength,
      showLimitPreExportTip = true,
    } = params
    if (disable) return
    if (showUpgrade) {
      actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD' })
      return
    }
    function getExportMessage(data: IExportConfig) {
      if (data.exportType === 'allData') {
        if (data.exportRangeType === 'all') {
          const localParams = { from: 1, to: params.resultExportLength }
          return isIncognito.value ? ts('export.startExportEmailAlert', localParams) : ts('export.startExportWithNumber', localParams)
        }
        if (data.exportRangeType === 'range') {
          const localParams = { from: data.from, to: data.to }
          return isIncognito.value ? ts('export.startExportEmailAlert', localParams) : ts('export.startExportWithNumber', localParams)
        }
      }

      return ts('export.startExport')
    }

    const exportSuccessCallBack = (data: IExportConfig, requestId: string) => {
      showSingleToast({
        message: getExportMessage(data),
        type: 'info',
        showClose: true,
        duration: 3000,
        dangerouslyUseHTMLString: true,
      })
      if (data.exportType !== 'allData') {
        handleExportPoller(requestId)
      }
    }

    showGDialog(
      {
        visible: true,
        width: '620px',
        showClose: true,
        title: isCN.value ? '导出' : 'Export',
        showFooter: false,
        component: BExportDialog,
        componentProps: {
          dataType: queryService.state.data_type,
          lang: localeUpcase.value,
          checkedIdList,
          commonListConfig,
          checkedMap,
          queryService,
          resultExportLength,
          limitPreExport: params.limitPreExport,
          exportCallBack: exportSuccessCallBack,
          optionType: 'radio',
          showMaxLimitTip: showLimitPreExportTip,
        },
      },
      ins?.proxy.$router,
      ins?.proxy.$i18n
    )
  }

  return {
    handleExportDrug,
    handleExportPoller,
  }
}
