import { IBaseDiseaseInfo, IBaseTargetInfo, IDrugDetail, IOrganizationDetail } from '@pharmsnap/shared/types'
import { defineComponentProps } from '@pharmsnap/shared/utils/component'
import { ExtractPropTypes, PropType } from '@vue/composition-api'

type OverloadEnter<T> = (entity?: T) => void

export const bKgEntranceProps = () =>
  defineComponentProps({
    showTitle: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    handleEnter: {
      type: Function as PropType<
        OverloadEnter<IOrganizationDetail> | OverloadEnter<IDrugDetail> | OverloadEnter<IBaseDiseaseInfo> | OverloadEnter<IBaseTargetInfo[]>
      >,
      default: () => Promise.resolve(),
    },
    entity: {
      type: [Object, Array] as PropType<IOrganizationDetail | IDrugDetail | IBaseDiseaseInfo | IBaseTargetInfo[]>,
    },
  })

export type BKgEntranceProps = ExtractPropTypes<ReturnType<typeof bKgEntranceProps>>
