export function loadScript(url: string, options?: { attrs?: Record<string, string> }) {
  return new Promise((resolve, reject) => {
    // Adding the script tag to the head as suggested before
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.async = true

    if (options?.attrs) {
      const { attrs } = options
      for (const key in attrs) {
        if (Object.prototype.hasOwnProperty.call(attrs, key)) {
          script.setAttribute(key, attrs[key])
        }
      }
    }
    script.onload = resolve
    script.onerror = reject

    // Fire the loading
    head.appendChild(script)
  })
}
