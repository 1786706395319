import VueI18n from 'vue-i18n'
import { GLink } from '../components'
import { EMPTY_PLACEHOLDER } from '../constants'
import { IBaseOrgInfo, IFundingInfo, ILang } from '../types'
import { formatAmountV2, formatTimestamp, formatURL, getHost } from '../utils'
import { renderLimitedOrg } from './baseRender'

export function renderFundedCloseDate(data: IFundingInfo, $i18n: VueI18n) {
  if (!data.close_time) return EMPTY_PLACEHOLDER
  return (
    <span>
      {formatTimestamp(data.close_time, {
        locale: $i18n.locale.toUpperCase() as ILang,
      })}
    </span>
  )
}

export function renderFundedOpenDate(data: IFundingInfo, $i18n: VueI18n) {
  if (!data.open_time) return EMPTY_PLACEHOLDER
  return (
    <span>
      {formatTimestamp(data.open_time, {
        locale: $i18n.locale.toUpperCase() as ILang,
      })}
    </span>
  )
}

export function renderFundedAwardOrgs(data: IFundingInfo) {
  const awardByOrgs = data.award_by_organization || []
  if (awardByOrgs.length === 0) return EMPTY_PLACEHOLDER
  const orgs: IBaseOrgInfo[] = awardByOrgs.map((i) =>
    i.normalized_master_entity_id
      ? i.normalized_master_entity_id_view
      : { name_en: i.original_name || '', entity_name_en: i.original_name || '', entity_id: '' }
  )
  return renderLimitedOrg(orgs, 1, true)
}

export function renderFundedAmount(data: IFundingInfo, $i18n: VueI18n) {
  if (typeof data.amount_usd === 'undefined') return $i18n.tc('common.undisclosed')

  return <span>{formatAmountV2({ amount: data.amount_usd, moneyType: '$' })}</span>
}

export function renderFundedNews(data: IFundingInfo) {
  const { title, source_url } = data

  if (!source_url) {
    if (!title) return EMPTY_PLACEHOLDER
    else {
      return (
        <span style="word-break: break-word;" class="line-clamp-3">
          {title}
        </span>
      )
    }
  } else {
    const href = `/link?target=${encodeURIComponent(source_url)}`
    if (!title) {
      return <GLink style="word-break: break-word;" truncate={false} defaultColor="blue" name={getHost(formatURL(source_url))} href={href}></GLink>
    } else {
      return (
        <GLink style="word-break: break-word;" truncate={false} defaultColor="blue" class="line-clamp-3" href={href}>
          {title}
        </GLink>
      )
    }
  }
}
