import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

export default [
  {
    path: `clinical-result-detail/:${E_ROUTER_PARAMS.CLINICAL_RESULT_ID}`,
    name: E_ROUTER_NAME.CLINICAL_RESULT_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical-result/ClinicalResultDetail'),
    meta: {
      notWide: true,
    },
  },
  {
    path: `clinical-result-finder`,
    name: E_ROUTER_NAME.CLINICAL_RESULT_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical-result/clinical-result-finder/ClinicalResultFinder'),
    meta: {
      activeKey: E_NAV_KEY.CLINICAL_RESULT_FINDER,
      title: createWebTitle('临床结果', 'Clinical Result Finder'),
      hideQuickSearch: true,
    },
  },
  {
    path: `clinical-result-list`,
    name: E_ROUTER_NAME.CLINICAL_RESULT_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical-result/ClinicalResultList'),
    meta: {
      title: createWebTitle('临床结果', 'Clinical Result'),
      notWide: true,
    },
  },
] as RouteConfig[]
