/* eslint-disable @typescript-eslint/no-explicit-any */
import World2021 from '@patsnap-ui/extensions/lib/geo/world2021/index.js'
import { hasCountryFlag, toThousands } from '@patsnap/synapse_common_utils'
import { AggWorldChartEntity, AggWorldDataItem } from '@patsnap/synapse_domain'
import { GCountryFlag } from '@pharmsnap/shared/src/components'
import { defineComponent, onMounted, PropType, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import type { EChartsOption } from 'echarts'
import * as echarts from 'echarts/core'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { BAnalysisEChart } from './BAnalysisEchart'

type AnalysisWorldEchartTooltipData = {
  seriesName: string
  categoryName: string
  count: string
  showFlag: boolean
  code: string
  children?: Array<AggWorldDataItem>
}

let mapHasInstalled = false

export const BAnalysisWorldEchart = defineComponent({
  name: 'BAnalysisWorldEchart',
  props: {
    entity: {
      type: Object as PropType<AggWorldChartEntity>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)

    const COUNTRY_MERGE_MAP: Record<string, string[]> = {
      CN: ['TW', 'HK', 'MO'],
    }

    const { tooltipRef, tooltipCommonConfig, tooltipActiveData, tooltipFormatter, resetTooltipDataMap } =
      useAnalysisChartTooltip<AnalysisWorldEchartTooltipData>({
        pickTooltipDataFromChartParam,
      })

    const realOption = shallowRef<EChartsOption>({})

    function getChildrenWorldDataList(code: string) {
      if (COUNTRY_MERGE_MAP[code]) {
        const childrenCodes = COUNTRY_MERGE_MAP[code]
        const children = childrenCodes
          .map((code) => {
            const child = unref(entity).findWorldDataByCodeOrName(code)
            return child
          })
          .filter((i) => !!i) as AggWorldDataItem[]

        return children
      }

      return []
    }

    function pickTooltipDataFromChartParam(params: any) {
      const seriesName = params.seriesName
      if (!seriesName) return
      const value = params.data as AggWorldDataItem
      if (!value) return

      const info: AnalysisWorldEchartTooltipData = {
        seriesName: seriesName,
        categoryName: value.name,
        count: toThousands(value.count),
        code: value.code,
        showFlag: hasCountryFlag(value.code || value.name),
      }

      const children = getChildrenWorldDataList(value.code)

      if (children.length) {
        info.children = children
      }

      return [value.code, info] as [string, AnalysisWorldEchartTooltipData]
    }

    function handleClickItem(params: any) {
      if (!params || !params.data) return
      const data = params.data as AggWorldDataItem
      const children = getChildrenWorldDataList(data.code)
      emit('clickItem', [data, ...children])
    }

    function getRealChartOption() {
      const max = unref(entity).max

      const min = unref(entity).min

      const seriesName = unref(entity).seriesName

      const world2021 = new World2021(echarts)

      world2021.formatNumber = toThousands
      world2021.config.labelVisible = false
      world2021.config.roam = false
      world2021.config.visualMapVisible = [max, min].every((i) => i === 0) ? false : true

      const world2021Option = world2021.getEchartOptions({
        dimensions: ['code', 'count'],
        source: unref(entity).oneDimDataList,
      })

      const geoOption = world2021Option.geo

      const series = Array.isArray(world2021Option.series) ? world2021Option.series : [world2021Option.series]

      const visualMap = Array.isArray(world2021Option.visualMap) ? world2021Option.visualMap : [world2021Option.visualMap]

      geoOption.select = {
        ...geoOption.select,
        itemStyle: {
          areaColor: '#4ABD40',
        },
      }

      visualMap.forEach((item: { text: string[]; color: string[]; bottom: number }) => {
        item.text = [`Max: ${toThousands(max)}`, `Min: ${toThousands(min)}`]
        item.bottom = 25
      })

      series.forEach((i: any) => {
        i.name = seriesName
      })

      world2021Option.tooltip = {
        ...world2021Option.tooltip,
        ...tooltipCommonConfig,
        formatter: tooltipFormatter,
      }

      return world2021Option as EChartsOption
    }

    onMounted(async () => {
      if (!mapHasInstalled) {
        mapHasInstalled = true
        await World2021.install(echarts)
      }
      realOption.value = getRealChartOption()
    })

    watch(
      () => unref(entity),
      () => {
        resetTooltipDataMap()
        realOption.value = getRealChartOption()
      }
    )

    return {
      tooltipRef,
      tooltipActiveData,
      handleClickItem,
      realOption,
      ...useAnalysisEchartsHelpers(),
    }
  },
  methods: {
    renderTooltip() {
      if (!this.tooltipActiveData) return null
      const { seriesName, categoryName, children, code, showFlag, count } = this.tooltipActiveData

      const renderCountry = () => {
        return (
          <div class="flex items-center mb-1">
            {showFlag ? <GCountryFlag name={code || categoryName} showName={false} showUnknownCountryFlag={true}></GCountryFlag> : null}
            <span class={['text-xs leading-4 font-bold text-text-t1', showFlag ? 'ml-1' : '']}>{categoryName}</span>
          </div>
        )
      }

      const renderContent = () => {
        if (!children?.length) {
          return (
            <div class="text-xs leading-4" style={{ paddingLeft: `${showFlag ? 28 : 0}px` }}>
              <span class="text-text-t1">{seriesName}: </span>
              <span class="text-text-t2">{count}</span>
            </div>
          )
        }

        return (
          <div class="text-xs leading-4" style={{ paddingLeft: `${showFlag ? 28 : 0}px` }}>
            <div>
              <span class="text-text-t1">{categoryName}: </span>
              <span class="text-text-t2">{count}</span>
            </div>
            {children.map((item) => {
              return (
                <div>
                  <span class="text-text-t1">{item.name}: </span>
                  <span class="text-text-t2">{toThousands(item.count)}</span>
                </div>
              )
            })}
          </div>
        )
      }

      return (
        <div class="p-2">
          {renderCountry()}
          {renderContent()}
        </div>
      )
    },
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        displayType="global"
        option={this.realOption}
        axisPopperOption={{ xPopperEnable: false, yPopperEnable: false }}
        {...{
          on: {
            click: this.handleClickItem,
          },
        }}
      >
        <template slot="extra">
          <div ref="tooltipRef">{this.renderTooltip()}</div>
        </template>
      </BAnalysisEChart>
    )
  },
})
