import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import { FilterItemLoading } from './FilterItemLoading'
export const FilterLoading = () => {
  return (
    <ElSkeleton loading={true} animated count={1}>
      <template slot="template">
        <ElSkeletonItem class="h-8 mb-12"></ElSkeletonItem>
        {Array.from({ length: 8 }).map(() => (
          <FilterItemLoading></FilterItemLoading>
        ))}
      </template>
    </ElSkeleton>
  )
}
