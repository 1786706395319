import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { PropType, defineComponent } from '@vue/composition-api'
import { VNode } from 'vue'
export const BUpgradeWrap = defineComponent({
  name: 'BUpgradeWrap',
  props: {
    needIntercept: {
      type: Boolean,
      default: true,
    },
    updateType: {
      type: String as PropType<E_UPGRADE_TYPE>,
      default: '',
    },
    triggerPoint: {
      type: String,
    },
  },
  setup(props) {
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    function blockFreeUser(e: Event) {
      if (isFreeUser.value) {
        e.preventDefault()
        changeUpgrade({ show: true, type: props.updateType, trigger_point: props.triggerPoint })
      }
    }

    return { blockFreeUser }
  },
  render() {
    return (
      this.$slots.default ? this.needIntercept ? <div onClick={this.blockFreeUser}>{this.$slots.default}</div> : this.$slots.default : null
    ) as VNode
  },
})
