import { legalFamilyStatusNum } from '@pharmsnap/shared/types'

/**
 * 专利法律状态配置,数据从https://qa-analytics.zhihuiya.com/core-basic-api/analytics/config/legal-status接口获取的
 */
interface ILegalStatusLang {
  tw: string
  jp: string
  en: string
  cn: string
}

interface ILegalStatusConfigItem {
  color: string
  related_code: string[]
  title: ILegalStatusLang
  desc?: ILegalStatusLang
}
export const legalStatusConfig: {
  simpleLegalStatus: Record<string, ILegalStatusConfigItem>
  legalStatus: Record<string, ILegalStatusConfigItem>
  legalEvents: Record<string, ILegalStatusConfigItem>
} = {
  simpleLegalStatus: {
    '0': {
      color: '#6B778C',
      related_code: ['8', '11', '17', '18', '13', '14', '15', '115', '16', '30', '130', '19', '20', '22', '122', '24'],
      title: {
        tw: '失效',
        jp: '無効',
        en: 'Inactive',
        cn: '失效',
      },
      desc: {
        tw: '專利申請權利已失效，可能是由於保護期屆滿，放棄或撤回，被駁回，被全部無效等任何一種原因導致失效。',
        jp: '保護期間の満了、放棄または撤回、解雇、すべての無効化などにより特許出願権が失効し、障害が発生する可能性があります。',
        en: 'The patent is not in force due to any of the following reasons: expiration, abandonment, withdrawal, rejection or invalidation. ',
        cn: '专利申请权利已失效，可能是由于保护期届满，放弃或撤回，被驳回，被全部无效等任何一种原因导致失效。',
      },
    },
    '1': {
      color: '#45A321',
      related_code: ['3', '103', '21', '23'],
      title: {
        tw: '有效',
        jp: '有効',
        en: 'Active',
        cn: '有效',
      },
      desc: {
        tw: '專利申請獲得專利權，可能是授權、部分無效或權利恢復狀態，仍在權利有效期內。',
        jp: '特許出願で特許権を取得するのは権利付け、一部無効、または権利の回復状態であり、まだ権利存続期間内である可能性があります。',
        en: 'The patent has been granted is due to any of following reasons: Granted, P-Revoked or Restoration. And still within patent term.',
        cn: '专利申请获得专利权，可能是授权、部分无效或权利恢复状态，仍在权利有效期内。',
      },
    },
    '2': {
      color: '#DC7C08',
      related_code: ['1', '101', '2'],
      title: {
        tw: '審中',
        jp: '審査係属中',
        en: 'Pending',
        cn: '审中',
      },
      desc: {
        tw: '專利申請處於公開或審查中的狀態，還未授權或者駁回。',
        jp: '特許出願は公開または審査状態であり、承認または却下されていません。',
        en: 'The application is published or under examination prior to the patent being issued or the application abandoned.',
        cn: '专利申请处于公开或审查中的状态，还未授权或者驳回。',
      },
    },
    '220': {
      color: '#6B778C',
      related_code: ['224', '225'],
      title: {
        tw: 'PCT指定期滿',
        jp: 'PCT国際段階(期限満了)',
        en: 'PCT designated stage expired',
        cn: 'PCT指定期满',
      },
      desc: {
        tw: 'PCT指定期滿: 已超出辦理進入指定國國家階段的期限',
        jp: 'PCT国際段階(期限満了): 指定国の国内段階へ移行させる手続を行う期間が過ぎました。',
        en: 'PCT designated stage expired: The deadline for entering the national phase of the designated country has been exceeded',
        cn: 'PCT指定期满: 已超出办理进入指定国国家/地区阶段的期限',
      },
    },
    '221': {
      color: '#DC7C08',
      related_code: ['222', '223'],
      title: {
        tw: 'PCT指定期內',
        jp: 'PCT国際段階(期限内)',
        en: 'PCT designated stage',
        cn: 'PCT指定期内',
      },
      desc: {
        tw: 'PCT指定期內: 在辦理進入指定國國家階段的期限內',
        jp: 'PCT国際段階(期限内): 指定国の国内段階へ移行させる手続を行う期間内です。',
        en: 'PCT designated stage: Within the deadline for entering the designated national phase',
        cn: 'PCT指定期内: 在办理进入指定国国家/地区阶段的期限内',
      },
    },
    '999': {
      color: '#9E9E9E',
      related_code: [],
      title: {
        tw: '未確認',
        jp: '未確認',
        en: 'Undetermined',
        cn: '未确认',
      },
      desc: {
        tw: '未收錄專利的法律狀態資訊，或者已獲取的法律狀態資訊不足以判斷當前狀態。',
        jp: '含まれていない特許の法的ステータス情報、または取得された法的ステータス情報が現在のステータスを判断するのに十分ではありません。',
        en: 'The legal information of the patent is unavailable, or unable to judge current status based on  the available legal information.',
        cn: '未收录专利的法律状态信息，或者已获取的法律状态信息不足以判断当前状态。',
      },
    },
  },
  legalStatus: {
    '1': {
      color: '#DC7C08',
      related_code: ['101'],
      title: {
        tw: '公開',
        jp: '公開',
        en: 'Published',
        cn: '公开',
      },
      desc: {
        tw: '申請公開，尚未開始實質審查。',
        jp: '出願人は公開されており、実質的な審査はまだ始まっていない。 ',
        en: "An application was published but haven't entered into substantive examination phase.",
        cn: '申请公开，尚未开始实质审查。',
      },
    },
    '2': {
      color: '#DC7C08',
      related_code: [],
      title: {
        tw: '實質審查',
        jp: '審査係属中',
        en: 'Examining',
        cn: '实质审查',
      },
      desc: {
        tw: '專利申請處於審查中的狀態，還未授權或者駁回。',
        jp: '特許出願は審査中であり、認可も拒否もされていない。 ',
        en: 'An application enters into substantive examination. <br>The status of a patent application that occurs before either an application is abandoned (withdrawn) or a patent issued.',
        cn: '专利申请处于审查中的状态，还未授权或者驳回。',
      },
    },
    '3': {
      color: '#45A321',
      related_code: ['103'],
      title: {
        tw: '授權',
        jp: '登録',
        en: 'Granted',
        cn: '授权',
      },
      desc: {
        tw: '專利申請獲得專利權，並且仍在權利有效期內。',
        jp: '特許出願は特許取得済みであり、依然として権利の有効期間内です。 ',
        en: 'The patent has been granted and still within patent term.',
        cn: '专利申请获得专利权，并且仍在权利有效期内。',
      },
    },
    '8': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: '避重授權',
        jp: '二重特許',
        en: 'Double',
        cn: '避重授权',
      },
      desc: {
        tw: '避免重複授權，同日申請的發明和實用新型，為了獲得發明專利權，需要放棄已經獲得的實用新型權利。',
        jp: '承認を繰り返さないよう、特許権を得るために、同一の発明および開示、本発明を提出し、請求項に係る発明を放棄する必要が得られています。 ',
        en: 'Abandonment of patent right or utility model to avoid double patenting.',
        cn: '避免重复授权，同日申请的发明和实用新型，为了获得发明专利权，需要放弃已经获得的实用新型权利。',
      },
    },
    '11': {
      color: '#6B778C',
      related_code: ['17', '18'],
      title: {
        tw: '撤回',
        jp: '取下',
        en: 'Withdrawn',
        cn: '撤回',
      },
      desc: {
        tw: '專利的撤回包括主動撤回和視為撤回。與放棄相似，視為撤回主要是由於申請人在指定期限未採取相應舉動而導致，主動撤回是申請人主動提交檔表示撤回專利申請。',
        jp: '特許の撤回には、自主的な撤回と引き出しとみなされます。そして、同様のをあきらめて、原因申請者に主に取り下げられたものとみなさ撤退するためのイニシアチブを主導し、指定期間内に適切な行動を取ることに失敗した自主的書類を提出する申請者は、特許出願を取り下げることを示しています。 ',
        en: 'An application open to public inspection was withdrawn at the request of the applicant.',
        cn: '专利的撤回包括主动撤回和视为撤回。与放弃相似，视为撤回主要是由于申请人在指定期限未采取相应举动而导致，主动撤回是申请人主动提交文件表示撤回专利申请。',
      },
    },
    '13': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: '駁回',
        jp: '拒絶査定',
        en: 'Rejected',
        cn: '驳回',
      },
      desc: {
        tw: '審查員認為某專利申請不具備可專利性駁回申請人的請求。',
        jp: '審査官は、特許出願が出願人の請求を拒絶する特許性を有していないと考えている。 ',
        en: 'The refusal of a patent Examiner to allow a claim based on specific legal grounds.',
        cn: '审查员认为某专利申请不具备可专利性驳回申请人的请求。',
      },
    },
    '14': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: '全部撤銷',
        jp: '全部無効',
        en: 'Revoked',
        cn: '全部撤销',
      },
      desc: {
        tw: '專利權通過無效或者異議程式等被宣佈無效。',
        jp: '特許権は、無効化または異議申立手続によって無効と宣言されている。 ',
        en: 'Similar to request to abandon by assignee all the patent or just some claims. OR patent invalidated in Opposition.',
        cn: '专利权通过无效或者异议程序等被宣布无效。',
      },
    },
    '15': {
      color: '#6B778C',
      related_code: ['115'],
      title: {
        tw: '期限屆滿',
        jp: '期間満了',
        en: 'Expired',
        cn: '期限届满',
      },
      desc: {
        tw: '專利的保護期限屆滿，不再具有法律效力。',
        jp: '特許の保護期間が満了し、もはや法的効力を失う。 ',
        en: 'A patent has run its full term and the invention is no longer protected.',
        cn: '专利的保护期限届满，不再具有法律效力。',
      },
    },
    '16': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: '未繳年費',
        jp: '年金不納',
        en: 'Non-payment',
        cn: '未缴年费',
      },
      desc: {
        tw: '申請人未繳納專利年費導致失效。',
        jp: '出願人は特許年会費を支払うことに失敗し、無効化を引き起こした。 ',
        en: 'Failure to pay annual fees.',
        cn: '申请人未缴纳专利年费导致失效。',
      },
    },
    '21': {
      color: '#45A321',
      related_code: [],
      title: {
        tw: '權利恢復',
        jp: '権利回復',
        en: 'Restoration',
        cn: '权利恢复',
      },
      desc: {
        tw: '權利人因不可抗拒的事由而延誤法定期限或者指定期限，導致其權利喪失的，在指定期限內，可以向專利局請求恢復權利。',
        jp: '権利者が法定期限または特定できない原因に起因する期限を遅らせて権利を失った場合は、特許庁に対し、指定期間内に権利を回復するよう要求することができる。',
        en: 'Where a time limit prescribed in the Patent Law is not observed by the patentee because of force majeure, resulting in loss of his or its rights, he or it may, within a specified time limit, request the Patent Office to restore his or its rights.',
        cn: '权利人因不可抗拒的事由而延误法定期限或者指定期限，导致其权利丧失的，在指定期限内，可以向专利局请求恢复权利。',
      },
    },
    '22': {
      color: '#6B778C',
      related_code: ['122'],
      title: {
        tw: '權利終止',
        jp: '権利消滅',
        en: 'Ceased',
        cn: '权利终止',
      },
      desc: {
        tw: '專利權保護期限已滿或由於某種原因專利權失效。',
        jp: '何らかの理由で特許保護期間が満了したか特許権が失効しました。',
        en: 'A patent has run its full term or ceased due to some reasons.',
        cn: '专利权保护期限已满或由于某种原因专利权失效。',
      },
    },
    '23': {
      color: '#45A321',
      related_code: [],
      title: {
        tw: '部分無效',
        jp: '一部無効',
        en: 'P-Revoked',
        cn: '部分无效',
      },
      desc: {
        tw: '經無效決定程式，專利的部分權利要求被宣告無效的。',
        jp: '失効手続の後、特許請求のいくつかが無効と宣告されました。',
        en: 'Part of Claims are declared invalid through invalidation procedure.',
        cn: '经无效决定程序，专利的部分权利要求被宣告无效的。',
      },
    },
    '24': {
      color: '#6B778C',
      related_code: ['130'],
      title: {
        tw: '申請終止',
        jp: '手続の停止',
        en: 'Discontinuation',
        cn: '申请终止',
      },
      desc: {
        tw: '專利申請由非撤回或放弃等未知原因導致的終止',
        jp: '不明な理由により特許出願を終了しました',
        en: 'Patent application terminated due to unknown reasons such as non-withdrawal or abandonment',
        cn: '专利申请由非撤回或放弃等未知原因导致的终止',
      },
    },
    '30': {
      color: '#6B778C',
      related_code: ['19', '20'],
      title: {
        tw: '放棄',
        jp: '放棄',
        en: 'Abandoned',
        cn: '放弃',
      },
      desc: {
        tw: '專利的放棄包括主動放棄和視為放棄。視為放棄主要是由於申請人在指定期限未採取相應舉動而導致，主動放棄是申請人主動提交檔表示放棄專利權。',
        jp: '特許放棄には、自発的放棄とみなし放棄が含まれます。権利放棄は、主に、出願人が指定された期間内に対応する措置を講じなかったことによるものです。権利放棄とは、特許権を放棄するための申請者のイニシアティブです。 ',
        en: 'To relinquish, either by express abandonment or by inaction, a patent application or issued patent.<br>(a) Abandonment by inaction typically involves failure to take a required action (e.g., filing a incomplete response or not paying a fee) during the statutory period for taking the action.<br>(b) A patent may be expressly abandoned by filing a written declaration of abandonment. ',
        cn: '专利的放弃包括主动放弃和视为放弃。视为放弃主要是由于申请人在指定期限未采取相应举动而导致，主动放弃是申请人主动提交文件表示放弃专利权。',
      },
    },
    '222': {
      color: '#DC7C08',
      related_code: [],
      title: {
        tw: 'PCT國際公布',
        jp: 'PCT国際公開',
        en: 'PCT publication',
        cn: 'PCT国际公布',
      },
      desc: {
        tw: 'PCT國際公布: PCT國際公布，且還未進入指定國',
        jp: 'PCT国際公開: PCTの国際公開であるが、指定国の国内段階移行手続きが行われていません。',
        en: 'PCT publication: PCT publication. Has not yet entered the designated country',
        cn: 'PCT国际公布: PCT国际公布，且还未进入指定国',
      },
    },
    '223': {
      color: '#DC7C08',
      related_code: [],
      title: {
        tw: 'PCT進入指定國(指定期內)',
        jp: 'PCT国内移行(期限内)',
        en: 'PCT-NP (In time limit)',
        cn: 'PCT进入指定国(指定期内)',
      },
      desc: {
        tw: 'PCT進入指定國(指定期內): 已進入指定國，且在指定期限內',
        jp: 'PCT国内移行(期限内): 指定国の国内段階への移行が所定の期間内に完了しました。',
        en: 'PCT-NP (In time limit): Has entered the designated country and is within the designated period',
        cn: 'PCT进入指定国(指定期内): 已进入指定国，且在指定期限内',
      },
    },
    '224': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: 'PCT進入指定國(指定期滿)',
        jp: 'PCT国内移行(期限満了)',
        en: 'PCT-NP (Past time limit)',
        cn: 'PCT进入指定国(指定期满)',
      },
      desc: {
        tw: 'PCT進入指定國(指定期滿): 已進入指定國，且已超出指定期限',
        jp: 'PCT国内移行(期限満了): 指定国の国内段階への移行が完了し、所定期間が満了しました。',
        en: 'PCT-NP (Past time limit): Has entered the designated country and has exceeded the designated period',
        cn: 'PCT进入指定国(指定期满): 已进入指定国，且已超出指定期限',
      },
    },
    '225': {
      color: '#6B778C',
      related_code: [],
      title: {
        tw: 'PCT未進指定國',
        jp: 'PCT国内移行ぜず',
        en: 'Non-Entry PCT-NP',
        cn: 'PCT未进指定国',
      },
      desc: {
        tw: 'PCT未進指定國: 未進入任何指定國，且已超出指定期限',
        jp: 'PCT国内移行ぜず: 各指定国の国内段階へ移行させる手続を行っておらず、所定期間が満了しました。',
        en: 'Non-Entry PCT-NP: Has not entered any designated country and has exceeded the designated period',
        cn: 'PCT未进指定国: 未进入任何指定国，且已超出指定期限',
      },
    },
  },
  legalEvents: {
    '61': {
      color: '#1976D2',
      related_code: ['161'],
      title: {
        tw: '權利轉移',
        jp: '譲渡',
        en: 'Transfer',
        cn: '权利转移',
      },
    },
    '62': {
      color: '#1976D2',
      related_code: ['162'],
      title: {
        tw: '許可',
        jp: 'ライセンス',
        en: 'License',
        cn: '许可',
      },
    },
    '63': {
      color: '#1976D2',
      related_code: ['163'],
      title: {
        tw: '質押',
        jp: '質権',
        en: 'Pledge',
        cn: '质押',
      },
    },
    '64': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '信托',
        jp: '信託',
        en: 'Trust',
        cn: '信托',
      },
    },
    '65': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '異議',
        jp: '異議',
        en: 'Opposition',
        cn: '异议',
      },
    },
    '66': {
      color: '#1976D2',
      related_code: ['166'],
      title: {
        tw: '復審',
        jp: '再審',
        en: 'Re-examination',
        cn: '复审',
      },
    },
    '68': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '部分無效',
        jp: '一部無効',
        en: 'P-Revoked',
        cn: '部分无效',
      },
    },
    '69': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '海關備案',
        jp: '輸入差止申立',
        en: 'Customs',
        cn: '海关备案',
      },
    },
    '70': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '訴訟',
        jp: '訴訟',
        en: 'Litigation',
        cn: '诉讼',
      },
    },
    '71': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '保全',
        jp: '保全',
        en: 'Preservation',
        cn: '保全',
      },
    },
    '72': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '無效程序',
        jp: '無効判定',
        en: 'Invalid-procedure',
        cn: '无效程序',
      },
    },
    '73': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '口頭審理',
        jp: '口頭審理',
        en: 'Oral-procedure',
        cn: '口头审理',
      },
    },
    '74': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '國防解密',
        jp: '機密解除',
        en: 'Declassification',
        cn: '国防解密',
      },
    },
    '75': {
      color: '#1976D2',
      related_code: [],
      title: {
        tw: '一案雙申',
        jp: '二重出願',
        en: 'Dual filing',
        cn: '一案双申',
      },
    },
  },
}

/**
 * patent 人工标注配置
 */
export const extractionMethodConfig: Record<string, { en: string; cn: string }> = {
  '-1': {
    en: 'Other',
    cn: '其他',
  },
  '0': {
    en: 'AI',
    cn: 'AI标引',
  },
  '1': {
    en: 'Manual',
    cn: '人工标引',
  },
}

export const LEGAL_STATUS_TIP_MAP: Record<legalFamilyStatusNum, { en: string; cn: string }> = {
  0: {
    en: 'All the family members are inactive',
    cn: '所有同族专利的状态均已失效',
  },
  1: {
    en: 'At least one family member is active',
    cn: '至少有一个同族专利的状态有效',
  },
  2: {
    en: 'Some family members are under examination',
    cn: '部分同族专利在审查中',
  },
  999: {
    en: 'The status of some family members are undetermined',
    cn: '部分同族专利的状态尚未确认',
  },
}

export const UPGRADE_TITLE = {
  FREE_LIMIT: {
    CN: '您只可以在专利板块搜索20次，请升级您的订阅计划',
    EN: 'You can only search 20 times in the Patent. Please upgrade your personal plan',
    JP: '',
  },
}
