import '@patsnap-ui/icon/assets/solid/SynapseDisease.svg'
import { ElTag } from '@pharmsnap/shared/src/element-ui'
import { IBaseDiseaseInfo, ILang } from '@pharmsnap/shared/types'
import { pickFirstSpecialLangName } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api'
import { GIcon } from '../../..'

export const BDiseaseTag = defineComponent({
  name: 'BDiseaseTag',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseDiseaseInfo>,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const name = computed(() => pickFirstSpecialLangName(data?.value?.disease_name || [], locale.value, 'EN'))

    return {
      locale,
      name,
    }
  },
  render() {
    return (
      <ElTag title={this.name} class="b-phase-tag max-w-[200px]" size="small" disable-transitions={true} effect="plain">
        {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="SolidSynapseDisease" /> : null}
        <span class="truncate">{this.name}</span>
      </ElTag>
    )
  },
})
