import { densityBarEventHub } from './densityBar'
export function useDensityBarHub() {
  function refreshAllDensityBar() {
    densityBarEventHub.emit('update')
  }

  function rafRefreshAllDensityBar() {
    setTimeout(() => refreshAllDensityBar(), 200)
  }

  return {
    refreshAllDensityBar,
    rafRefreshAllDensityBar,
  }
}
