import { ILang, ISearchParams } from '@patsnap/synapse_common_interface'
import { IExportDataType, IExportResult } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

interface IExportSort {
  field: string
  order: string
}
export interface IExportQueryParams {
  workspace_id: string
  folder_id: string
  qid: string
  efqid: string
  offset: number
  limit: number
  count: number
  data_type: string
  selected_ids: string[]
  mode: string
  sorts: IExportSort[]
}

export interface IExportWorkspaceParams {
  data_type: string
  lang: ILang
  export_fields?: string[]
  export_type?: IExportDataType
  query_name?: string
  query_url?: string
  export_time?: string
  offset: number
  limit: number
  workspace_query_id: string
  export_from: string
  from_module: string
  include_search_detail?: boolean
}

export class WorkspaceApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getWorkspaceSearchId(params: ISearchParams): Promise<IHttpWrap<{ query_id: string }>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/workspace/create'),
      data: params,
      method: 'POST',
    })
  }

  async getWorkspaceExportQuery(query_id: string): Promise<IHttpWrap<IExportQueryParams>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/workspace/export/query/${query_id}`),
      method: 'GET',
    })
  }

  async exportWorkspace(params: IExportWorkspaceParams): Promise<IHttpWrap<IExportResult>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/export'),
    })
  }
}
