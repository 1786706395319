import { IBaseDictItem } from '@pharmsnap/shared/types'
/**
 * 数据拷贝自
 * http://data-manage-platform.patsnap.info/dict/preview/SPECIAL_REVIEW页面的接口
 * curl 'https://data-manage-platform.zhihuiya.com/data_manage_platform/1.0/dictionary/dynamodb/search' \
 * --data-raw '{"limit":20,"offset":0,"sort":[{"sort_field":"name_en","sort_order":"desc"}],"dict_type":"SPECIAL_REVIEW"}' \
 */
export const allRegulationDictArr: IBaseDictItem[] = [
  {
    dict_id: '9eb8777c62af4712932b360e6ef3e454',
    name_cn: '特殊审批',
    name_en: 'Special Review Project',
  },
  {
    dict_id: '2b4f6fb42f1545ccb6a748dc42f4b88c',
    name_cn: '合格传染病产品',
    name_en: 'Qualified Infectious Disease Product',
  },
  {
    dict_id: '23d3523b7ece48ddacaddac53a66949d',
    name_cn: '优先审评',
    name_en: 'Priority Review',
  },
  {
    dict_id: 'b7d7a459842543b7913979bf604084e4',
    name_cn: '优先药物（PRIME）',
    name_en: 'PRIME',
  },
  {
    dict_id: '043a131d85994801b3617e822e7f87b3',
    name_cn: '孤儿药',
    name_en: 'Orphan Drug',
  },
  {
    dict_id: '4a280bd237a24cc4bbeb99b149be3c18',
    name_cn: '国家/地区科技重大专项',
    name_en: 'National Science and Technology Major Project',
  },
  {
    dict_id: 'ca2f2c7dacec450e8a45e2b7f4b74d05',
    name_cn: '快速通道',
    name_en: 'Fast Track',
  },
  {
    dict_id: 'c3c948340a7d40f3b7437081f5e8cb4e',
    name_cn: '突破性疗法',
    name_en: 'Breakthrough Therapy',
  },
  {
    dict_id: 'df5e83eb171a4eb095fcab2f1470348d',
    name_cn: '加速批准',
    name_en: 'Accelerated Approval',
  },
  {
    dict_id: '74efa92412694fcfaab6151e88ce57c2',
    name_cn: '紧急使用授权',
    name_en: 'Emergency Use Authorization',
  },
  {
    dict_id: '6501abeba2b6400ca8c822d8b2bff598',
    name_cn: '附条件批准',
    name_en: 'Conditional Marketing Approval',
  },
]
/**
 * 数据来自DMP
 * http://data-manage-platform.patsnap.info/dict/preview/COUNTRY
 */
export const countryDictArr: IBaseDictItem[] = [
  {
    dict_id: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
    name_cn: '中国',
    name_en: 'CN',
  },
  {
    dict_id: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
    name_cn: '美国',
    name_en: 'US',
  },
  {
    dict_id: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
    name_cn: '欧盟',
    name_en: 'EU',
  },
  {
    dict_id: '676de5ba-893a-36bb-8683-c93306aaf769',
    name_cn: '日本',
    name_en: 'JP',
  },
]

export const DRUG_PHASE_IDS_WITHOUT_TRIAL = [
  '8666aad5477e4272b56b94ca1183781d',
  'b2ca28b01c34419db0365bad2f8dd09e',
  '3da2ce3ce8b0443fb0b2d335be3e591a',
]

/**
 * browse与高级检索获取hierarchy树的顶层rootId
 */
export const TREE_ROOT_ID = {
  dictionary: '-1',
  target: 'b397c813fb3144079889408fd19293af',
  disease: '383538ad75a24989bb2f546dabbf21a3',
  meetings: '53449124a7794542b4e25f8b7db612e0',
}

export const OTHER_GENES_PROTEINS = 'b655878c81e04226ae8e55f4d8ad91fb'

export const radarDiseaseNameMap: Record<string, { name_en: string; name_cn: string }> = {
  c8df5dce70fd4e0cb3320058ddb95461: {
    name_cn: '内分泌与代谢疾病',
    name_en: 'Endocrinology and\nMetabolic',
  },
  '76732dd51f2842799e6843eef80820ea': {
    name_cn: '免疫系统',
    name_en: 'Immune\nSystem',
  },
  '9a802adb6e94433f8f4eb9be678991b8': {
    name_cn: '感染',
    name_en: 'Infectious',
  },
  '5b1dfc2ca8564662860187aef864467d': {
    name_cn: '血液及淋巴系统',
    name_en: 'Hemic and\nLymphatic',
  },
  '62eaee6d9ee44410b8aca59dfc7408e9': {
    name_cn: '肿瘤',
    name_en: 'Neoplasms',
  },
  '042cbe45c973477b957717ded29ef7b6': {
    name_cn: '神经系统',
    name_en: 'Nervous\nSystem',
  },
}

/**
 * 没有标准化的机构原始名称，如果是下面这些的，就过滤掉
 */
export const invalidOrgName = ['公开发行', '未披露', '个人投资者', '在册股东', '员工', '在册员工']
