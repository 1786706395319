import { IHomePlaybookTheme } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import $classes from './BCardPlaybook.module.scss'

export const BCardPlaybook = defineComponent({
  name: 'BCardPlaybook',
  props: {
    theme: {
      type: String as PropType<IHomePlaybookTheme>,
      default: 'blue',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
  render() {
    return (
      <div class={[$classes.card, $classes[`${this.theme}`]]} onClick={() => this.$emit('click')}>
        <div class={$classes.title}>{this.title}</div>
        <div class={$classes.desc}>{this.desc}</div>
      </div>
    )
  },
})
