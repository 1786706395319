import { defineComponent } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import '../../../assets/icon-svg/info.svg'
export const GExplain = defineComponent({
  name: 'GExplain',
  props: {
    iconSize: {
      type: Number,
      default: 24,
    },
  },
  render() {
    return (
      <div class="inline-flex items-center">
        <PtIcon class="text-gray-60" style={{ fontSize: `${this.iconSize}px` }} icon="Info"></PtIcon>
        <span>{this.$slots.default}</span>
      </div>
    )
  },
})
