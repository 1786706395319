import { E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import type { RouteConfig } from 'vue-router'

// 游客版的页面，pc端不存在，重定向至首页或pc端对应的页面
export default [
  {
    path: '/report-list/*',
    redirect: {
      name: E_ROUTER_NAME.REPORT_LIST,
    },
  },
  {
    path: '/news-list/*',
    redirect: {
      name: E_ROUTER_NAME.LATEST_NEWS_HOME,
    },
  },
  {
    path: '/error-page/read_fast',
    redirect: {
      name: E_ROUTER_NAME.READ_FAST,
    },
  },
  {
    path: '/plan',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/solution/en',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/solution/cn/*',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/use-case',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/use-case/*',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/release-log',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/faq',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/feature',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/example-data/*',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/contact',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/home',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
  {
    path: '/home/anaqua',
    redirect: {
      name: E_ROUTER_NAME.HOME,
    },
  },
] as RouteConfig[]
