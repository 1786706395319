import { patentExtensionStatusLangMap } from '@patsnap/synapse_common_config'
import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { ANS_TYPE_LANG } from '@pharmsnap/shared/constants/enum-lang/patent'
import { countryLangMap } from '@pharmsnap/shared/constants/static-data/country'
import { extractionMethodConfig } from '@pharmsnap/shared/constants/static-data/patent'
import {
  injectLangFieldByKey,
  injectLangFieldByKeyLangMap,
  injectOrgName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'

function injectPatentCountryName(data: IAggregationValue) {
  const key = data.key
  return {
    ...data,
    display_name_cn: countryLangMap.cn[key] || '',
    display_name_en: countryLangMap.en[key] || '',
  }
}

function injectPatentExtractionMethod(data: IAggregationValue) {
  const { key } = data
  const value = extractionMethodConfig[key]
  if (!value) {
    return data
  }
  return {
    ...data,
    display_name_cn: value.cn,
    display_name_en: value.en,
  }
}

export const patentFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  APD_YEAR: injectLangFieldByKey,
  PBDT_YEAR: injectLangFieldByKey,
  IN: injectLangFieldByKey,
  IN_FACET: injectLangFieldByKey,
  EXDT_Y: injectLangFieldByKey,
  PHS_LABEL_TYPE: injectPatentExtractionMethod,
  COUNTRY: injectPatentCountryName,
  FAM_COUNTRY: injectPatentCountryName,
  ANS_TYPE: (data) => injectLangFieldByKeyLangMap(ANS_TYPE_LANG, data),
  ANCS_TYPE: (data) => injectLangFieldByKeyLangMap(ANS_TYPE_LANG, data),
  PHS_TARGET_ID: replaceDisplayNameByShortName,
  PHS_ORG_ID_ONEID: injectOrgName,
  PHS_ORG_ROOT_ID_ONEID: injectOrgName,
  PHS_ANS_ORG_ID_ONEID: injectOrgName,
  PHS_ANS_ORG_ROOT_ID_ONEID: injectOrgName,
  PHS_EXTENSION_TYPE: injectLangFieldByKey,
  SPC_STATUS: (data) => injectLangFieldByKeyLangMap(patentExtensionStatusLangMap, data),
  PED_STATUS: (data) => injectLangFieldByKeyLangMap(patentExtensionStatusLangMap, data),
  PTE_STATUS: (data) => injectLangFieldByKeyLangMap(patentExtensionStatusLangMap, data),
  SPC_EXPIRY_DATE: injectLangFieldByKey,
  PED_EXPIRY_DATE: injectLangFieldByKey,
  PTE_EXPIRY_DATE: injectLangFieldByKey,
}

export function transformPatentAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, patentFieldTransformFnMap, xCorrelationId)
}
