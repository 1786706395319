import { GIconLink } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { renderPhase } from '@pharmsnap/shared/render'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import '../../../../assets/icon-svg/disease.svg'
import { EMPTY_PLACEHOLDER } from '../../../../constants'
import { sharedCtx } from '../../../../context'
import { IBaseDrugInfo, ILang } from '../../../../types'
import { IDiseaseCardInfo } from '../../../../types/disease'
import { CTRL_C_INFO, getAllSpecialLang, getSpecialLang } from '../../../../utils'
import { BEntityLoadingCard } from '../../../business/card/BEntityLoadingCard/BEntityLoadingCard'
import { GDescription } from '../../../ui/GDescription/GDescription'
import { GLimited } from '../../../ui/GLimited/GLimited'
import { BDrugItem } from '../../BDrugItem/BDrugItem'
import { BEntityTag } from '../../tag/BEntityTag/BEntityTag'
import $classes from '../EntityCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDiseaseCard = defineComponent({
  name: 'BDiseaseCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDiseaseCardInfo>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const relationDrugs = computed(() => data?.value?.related_drug_id_view || [])
    const displayName = computed(
      () => getSpecialLang({ data: data?.value?.disease_name || [], field: 'name', lang: locale.value }) || EMPTY_PLACEHOLDER
    )
    const renderName = computed(() => {
      const nameEn = getSpecialLang({ data: data?.value?.disease_name || [], field: 'name', lang: 'EN', isDegraded: false })
      const nameCn = getSpecialLang({ data: data?.value?.disease_name || [], field: 'name', lang: 'CN', isDegraded: false })
      return (
        <div class="flex flex-col">
          <GIconLink
            class={[$classes.title]}
            ellipsisLine={1}
            href={sharedCtx.router.generatorDiseasePath(data?.value?.disease_id || '')}
            name={nameEn && nameCn ? nameEn : displayName.value}
            svgName={props.showIcon ? 'Disease' : ''}
            newTab={true}
            defaultColor="blue"
            size={20}
          ></GIconLink>
          {nameCn && isCN.value ? (
            <GIconLink
              class={[$classes.title]}
              ellipsisLine={1}
              href={sharedCtx.router.generatorDiseasePath(data?.value?.disease_id || '')}
              name={nameCn}
              newTab={true}
              defaultColor="blue"
              size={20}
            >
              <span class="mr-2 inline-block w-5" slot="icon"></span>
            </GIconLink>
          ) : null}
        </div>
      )
    })

    const phase = computed(() => {
      const highestPhase = data?.value?.highest_dev_status_view
      const name = locale.value === 'CN' ? highestPhase?.name_cn || highestPhase?.name_en : highestPhase?.name_en
      return name
    })
    return {
      locale,
      relationDrugs,
      renderName,
      phase,
    }
  },
  methods: {
    renderContent() {
      if (!this.data) return null

      return (
        <GDescription
          width={135}
          data={this.data}
          size="medium"
          direction={this.direction}
          gapSize={this.direction === 'vertical' ? 'mini' : 'base'}
          items={[
            {
              label: this.$t('diseaseCard.synonyms') as string,
              field: 'synonyms',
            },
            {
              label: this.$t('diseaseCard.relatedDrugs') as string,
              field: 'relation_drug',
            },
            {
              label: this.$t('diseaseCard.highestPhase') as string,
              field: 'highest_dev_status',
            },
          ]}
          scopedSlots={{
            synonyms: ({ data }: { data: IDiseaseCardInfo }) => {
              if (!data.alias || !data.alias.length) return EMPTY_PLACEHOLDER
              return (
                <GLimited
                  mode="link"
                  items={getAllSpecialLang({ data: data?.alias, lang: this.locale, field: 'name' })}
                  limit={3}
                  linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
                  link={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                  scopedSlots={{
                    default: ({ item }: { item: string }) => (
                      <BEntityTag title={item} style="display: inline-block; font-size: 0.875rem;">
                        {item}
                      </BEntityTag>
                    ),
                  }}
                ></GLimited>
              )
            },
            relation_drug: ({ data }: { data: IDiseaseCardInfo }) => {
              const relatedDrugs = data.related_drug_id_view || []
              const totalCount = data.related_drug_id_count || 0
              if (relatedDrugs.length === 0 || totalCount === 0) return EMPTY_PLACEHOLDER
              return (
                <GLimited
                  mode="link"
                  totalCount={totalCount}
                  items={relatedDrugs}
                  limit={3}
                  linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
                  link={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                  inline={false}
                  scopedSlots={{
                    default: ({ item }: { item: IBaseDrugInfo }) => <BDrugItem data={item}></BDrugItem>,
                  }}
                ></GLimited>
              )
            },
            highest_dev_status: () =>
              renderPhase(
                { currentPhase: this.data?.highest_dev_status_view, beforePhase: this.data?.highest_dev_status_termination_view },
                this.locale
              ),
          }}
        ></GDescription>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc={true} loading={this.loading} border={this.border}>
        <div class={[$classes.card, CTRL_C_INFO, !this.border && $classes.cardNoBorder]}>
          <div class={$classes.header}>{this.renderName}</div>
          <div class={$classes.body}>{this.renderContent()}</div>
        </div>
      </BEntityLoadingCard>
    )
  },
})
