<template>
  <transition-group
    :tag="tagName"
    name="g-lazy-component"
    style="position: relative"
    @before-enter="(el) => $emit('before-enter', el)"
    @before-leave="(el) => $emit('before-leave', el)"
    @after-enter="(el) => $emit('after-enter', el)"
    @after-leave="(el) => $emit('after-leave', el)"
  >
    <div v-if="isInit" key="component">
      <slot :loading="loading"></slot>
    </div>
    <div v-else-if="$slots.skeleton" key="skeleton">
      <slot name="skeleton"></slot>
    </div>
    <div v-else key="loading"></div>
  </transition-group>
</template>

<script>
export default {
  name: 'GLazyComponent',
  props: {
    timeout: {
      type: Number,
    },
    /** 延迟展现的时间 */
    debounceShowTime: {
      type: Number,
      default: 500,
    },
    tagName: {
      type: String,
      default: 'div',
    },
    viewport: {
      type: typeof window !== 'undefined' ? window.HTMLElement : Object,
      default: () => null,
    },
    threshold: {
      type: String,
      default: '0px',
    },
    direction: {
      type: String,
      default: 'vertical',
    },
    maxWaitingTime: {
      type: Number,
      default: 50,
    },
  },

  data() {
    return {
      isInit: false,
      timer: null,
      io: null,
      checkIo: null,
      loading: false,
    }
  },
  computed: {
    // 根据滚动方向来构造视口外边距，用于提前加载
    rootMargin() {
      return this.direction === 'vertical' ? `${this.threshold} 0px` : `0px ${this.threshold}`
    },
  },
  created() {
    // 如果指定timeout则无论可见与否都是在timeout之后初始化
    if (this.timeout) {
      this.timer = setTimeout(() => {
        this.init()
      }, this.timeout)
    }
  },

  mounted() {
    if (!this.timeout) {
      try {
        // 观察视口与组件容器的交叉情况
        this.io = new window.IntersectionObserver(this.intersectionHandler, {
          rootMargin: this.rootMargin,
          root: this.viewport,
          threshold: [0.25, 0.5, 0.75],
        })

        this.io.observe(this.$el)
      } catch (e) {
        this.init()
      }
    }
  },

  beforeDestroy() {
    // 在组件销毁前取消观察
    if (this.io) {
      this.io.unobserve(this.$el)
    }
  },

  methods: {
    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
    checkIsIntersecting() {
      return new Promise((resolve, reject) => {
        const observer = new IntersectionObserver(
          (entries) => {
            resolve(entries[0].isIntersecting)
            observer.disconnect()
          },
          {
            rootMargin: this.rootMargin,
            root: this.viewport,
            threshold: [0.25, 0.5, 0.75],
          }
        )
        observer.observe(this.$el)
      })
    },
    // 交叉情况变化处理函数
    intersectionHandler(entries) {
      if (
        // 正在交叉
        entries[0].isIntersecting ||
        // 交叉率大于0
        entries[0].intersectionRatio
      ) {
        this.init()
      }
    },

    // 处理组件和骨架组件的切换
    async init() {
      if (this.isInit) {
        return
      }
      await this.sleep(this.debounceShowTime)
      const isIntersecting = await this.checkIsIntersecting()
      if (!isIntersecting) {
        return
      }
      this.io && this.io.unobserve(this.$el)
      // 此时说明骨架组件即将被切换
      this.$emit('beforeInit')
      this.$emit('before-init')

      // 此时可以准备加载懒加载组件的资源
      this.loading = true

      // 由于函数会在主线程中执行，加载懒加载组件非常耗时，容易卡顿
      // 所以在requestAnimationFrame回调中延后执行
      this.requestAnimationFrame(() => {
        this.isInit = true
        this.$emit('init')
      })
    },

    requestAnimationFrame(callback) {
      // 防止等待太久没有执行回调
      // 设置最大等待时间
      setTimeout(() => {
        if (this.isInit) return
        callback()
      }, this.maxWaitingTime)

      // 兼容不支持requestAnimationFrame 的浏览器
      return (window.requestAnimationFrame || ((callback) => setTimeout(callback, 1000 / 60)))(callback)
    },
  },
}
</script>
<style>
.g-lazy-component-enter {
  opacity: 0;
}

.g-lazy-component-enter-to {
  opacity: 1;
}

.g-lazy-component-enter-active {
  transition: opacity 0.3s 0.2s;
  position: absolute;
  top: 0;
  width: 100%;
}

.g-lazy-component-leave {
  opacity: 1;
}

.g-lazy-component-leave-to {
  opacity: 0;
}

.g-lazy-component-leave-active {
  transition: opacity 0.5s;
}
</style>
