/* eslint-disable @typescript-eslint/no-explicit-any */
import { I18nLang } from '@patsnap/synapse_common_interface'
import { computed, getCurrentInstance, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { DEFAULT_NO_LIMIT_COUNT } from '../config'
import { IFilterCheckNoopHook, IFilterItemLimitContainerConfig } from '../type'

type IUseFilterLimitContainerServiceOptions<ITEM = any> = {
  limitContainerConfig: MaybeRef<IFilterItemLimitContainerConfig>
  list: MaybeRef<ITEM[]>
  locale: MaybeRef<I18nLang>
  onBeforeExpand?: IFilterCheckNoopHook
  onBeforeCollapse?: IFilterCheckNoopHook
}

export function useFilterLimitContainerService<ITEM = any>(options: IUseFilterLimitContainerServiceOptions<ITEM>) {
  const { limitContainerConfig, list, onBeforeCollapse, onBeforeExpand } = options

  const ins = getCurrentInstance()

  const isForceShowMore = unref(limitContainerConfig).limitCount === DEFAULT_NO_LIMIT_COUNT

  const showMore = ref(isForceShowMore)

  const showExpandTrigger = computed(() => {
    const limitCount = unref(limitContainerConfig).limitCount

    const supportCollapse = unref(limitContainerConfig).supportCollapse

    // 默认就是展示全部，不需要展开按钮
    if (limitCount === DEFAULT_NO_LIMIT_COUNT) {
      return false
    }
    // 收起时判断是否需要显示展开按钮
    if (!unref(showMore)) {
      return unref(list).length > unref(limitCount)
    }

    // 展开时判断是否需要显示收起按钮
    return unref(supportCollapse)
  })

  const displayList = computed(() => {
    const limitCount = unref(limitContainerConfig).limitCount

    return unref(showMore) ? unref(list) : unref(list).slice(0, limitCount)
  })

  const triggerText = computed(() => {
    return unref(showMore) ? ins?.proxy.$tc('bFilterNext.showLess') || '' : ins?.proxy.$tc('bFilterNext.showMore') || ''
  })

  async function onExpand() {
    if (onBeforeExpand) {
      const rt = await onBeforeExpand()
      if (!rt) return
    }
    showMore.value = true
  }

  async function onCollapse() {
    if (onBeforeCollapse) {
      const rt = await onBeforeCollapse()
      if (!rt) return
    }
    showMore.value = false
  }

  return {
    showExpandTrigger,
    showMore,
    displayList,
    triggerText,
    onExpand,
    onCollapse,
  }
}
