import { defineComponent } from '@vue/composition-api'
import $classes from './GBlock.module.scss'

export const GBlock = defineComponent({
  name: 'GBlock',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    customBlockContentClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    renderTitle() {
      return this.$slots.title || <div class={$classes.blockItemTitle}>{this.title}</div>
    },
    renderDesc() {
      const shouldRenderDesc = this.$slots.desc || this.desc

      if (!shouldRenderDesc) return null

      if (this.$slots.desc) return <div class={$classes.blockItemDescCustom}>{this.$slots.desc}</div>

      return <div class={$classes.blockItemDesc}>{this.desc}</div>
    },
    renderContent() {
      return <div class={[$classes.blockItemContent, this.customBlockContentClass]}>{this.$slots.default}</div>
    },
  },
  render() {
    return (
      <div class={[$classes.blockItem]}>
        {this.renderTitle()}
        {this.renderDesc()}
        {this.renderContent()}
      </div>
    )
  },
})
