import { IConvertResItem, IElTreeNode, ITreeAggregationItem } from '@pharmsnap/shared/types'

interface IFlatItem {
  id: string
  sort_index?: number
  parentIds?: string[]
  children?: IFlatItem[]
}

export function flatTree<T extends IFlatItem>(trees: T[]): Array<IFlatItem> {
  function loop<T extends IFlatItem>(tree: T) {
    const rt = [
      {
        id: tree.id,
        parentIds: tree.parentIds || [],
      },
    ]
    if (tree.children?.length) {
      rt.push(...tree.children.flatMap(loop))
    }
    return rt
  }
  return trees.flatMap(loop)
}

export function getTargetNodePath<T extends IFlatItem>(trees: T[], id: string): string[][] {
  return flatTree(trees)
    .filter((item) => item.id === id)
    .map((item) => [...(item.parentIds || []), id])
}
export function getTargetNodePathV2<T extends IFlatItem>(trees: T[], ids: string[]): string[][] {
  const idsMap = ids.reduce((acc, cur) => {
    acc[cur] = true
    return acc
  }, {} as Record<string, boolean>)
  return flatTree(trees)
    .filter((item) => idsMap[item.id]) // 找到所有目标节点
    .map((item) => [...(item.parentIds || []), item.id])
}

export function getAllNotLeafId<T extends IFlatItem>(tree: T): string[] {
  if (tree.children) {
    return [tree.id, ...tree.children.flatMap(getAllNotLeafId)]
  } else {
    return []
  }
}

/**
 * 获取树中的目标节点
 * @param arr
 * @param id
 * @returns
 */
export function findTarget(arr: IConvertResItem[] | undefined, id: string): IConvertResItem | undefined {
  if (!arr) return undefined
  for (const obj of arr) {
    if (obj.id == id) {
      return obj
    }
    const ret = findTarget(obj.children, id)
    if (ret) return ret
  }
  return undefined
}

/**
 * 为一颗树添加全链路字段parentIds
 * @param items
 * @param parentIds
 * @returns
 */
export function convertTreeAggregationData(items: ITreeAggregationItem[], parentIds: string[] = []): ITreeAggregationItem[] {
  return items.map((o) => {
    const res: ITreeAggregationItem = {
      ...o,
      parentIds,
    }
    if (o.children && o.children.length) {
      res.children = convertTreeAggregationData(o.children, [...parentIds, o.id])
    }
    return res
  })
}

/** 获取树节点父级路径 */
export function loopGetIdPath(node: IElTreeNode): string[] {
  if (node.parent) {
    return [...loopGetIdPath(node.parent), node.data.id]
  }
  return []
}

/**
 * 针对这边树结构会有其实是父，isLeaf为false，但其子被过滤完了的情况
 * 统一前端再计算一遍，没有children就算作子节点
 */
export function calcIsLeaf(tree: IConvertResItem[]) {
  tree.forEach((item) => {
    if (!item.children || !item.children.length) {
      item.is_leaf = true
    }
    if (item.children) {
      calcIsLeaf(item.children)
    }
  })
}
