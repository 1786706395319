/**
 * 靶点树根节点id
 */
export const TARGET_ROOT_IDS = [
  'eff466dabeca49e18ee6125be1224b3a',
  '634fa5beefe341849ca027379aadfc2d',
  '123ae053565449148a273f4f77b6d400',
  'ab09671f8630434b8c5944037c5cedd2',
  '9885a175f82b4317b9e4ae7464ebd9a0',
  '5c6f0a34ca8345458147c3cd064606e6',
  '518146f621fd4d72be99a8a97d8310df',
  '8c85622539fc411b96bea23416115099',
  'a2894d4a66904b958b39e89aabcfeda4',
  'b655878c81e04226ae8e55f4d8ad91fb', //Other genes/proteins
]
