import '@patsnap-ui/icon/assets/solid/IntegrateHistory.svg'
import { BNewsAnnotationTag, GIcon } from '@pharmsnap/shared/components'
import { E_TIME_FORMAT, INewsDetail } from '@pharmsnap/shared/types'
import { encryptNum, formatTimestampToSemantic } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import cn from '../locales/cn.json'
import en from '../locales/en.json'

export const BNewsletterCard = defineComponent({
  name: 'BNewsletterCard',
  i18n: {
    messages: {
      en,
      cn,
    },
  },
  props: {
    news: {
      required: true,
      type: Object as PropType<INewsDetail>,
    },
    detailUrl: {
      type: String,
    },
  },
  methods: {
    renderHeader() {
      return (
        <div class={['rounded']} onClick={() => this.$emit('click')}>
          <div class="mb-2">
            <GIcon class="mr-1 text-text-t3" size={24} svgName="SolidIntegrateHistory"></GIcon>
            {this.news.dmp_post_time?.time_ts && (
              <span
                class="text-sm text-black-default icon-font"
                domPropsInnerHTML={encryptNum(
                  formatTimestampToSemantic(
                    this.news.dmp_post_time.time_ts,
                    this.$i18n.locale === 'cn' ? E_TIME_FORMAT.DD_MM_YYYY : E_TIME_FORMAT.DDD_MMM_YYYY,
                    false
                  )
                )}
              ></span>
            )}
          </div>
          <a class="block text-sm text-black-default hover:underline hover:text-blue-default cursor-pointer" href={this.detailUrl} target="_blank">
            {this.news.title}
          </a>
        </div>
      )
    },
    renderFooter() {
      return (
        <div class="space-y-2">
          {!!this.news.annotation_tag?.length && (
            <div class="pt-3">
              {this.news.annotation_tag.map((item) => {
                return <BNewsAnnotationTag class="mr-1 mb-1" data={item}></BNewsAnnotationTag>
              })}
            </div>
          )}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="mb-6 border-b border-gray-40 pb-4">
        {this.renderHeader()}
        <div>{this.renderFooter()}</div>
      </div>
    )
  },
})
