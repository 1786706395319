import '@patsnap-ui/icon/assets/solid/export.svg'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GMiniSwitch } from '../../ui/GMiniSwitch/GMiniSwitch'
import { GRadio } from '../../ui/GRadio/GRadio'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BCardContainer } from '../BCardContainer/BCardContainer'

export const BChartCardContainer = defineComponent({
  name: 'BChartCardContainer',
  props: {
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    switchConfig: {
      type: Object as PropType<{ visible?: boolean; value?: boolean; name?: string }>,
      default: () => ({ visible: false, value: false, name: '' }),
    },
    tabConfig: {
      type: Object as PropType<{ visible?: boolean; name?: string; value?: string; tabs?: Array<{ value: string; label: string }> }>,
      default: () => ({ visible: false, value: '', name: '', tabs: [] }),
    },
    downloadConfig: {
      type: Object as PropType<{ visible?: boolean }>,
      default: () => ({ visible: false }),
    },
  },
  setup(props, ctx) {
    const handleSwitchChange = (val: boolean) => {
      ctx.emit('switch-change', val)
    }

    const handleTabChange = (val: string) => {
      ctx.emit('tab-change', val)
    }

    const handleDownload = () => {
      if (props.isEmpty) return
      ctx.emit('download')
    }

    return {
      handleSwitchChange,
      handleTabChange,
      handleDownload,
    }
  },
  methods: {
    renderSwitch() {
      const { visible = false } = this.switchConfig

      if (!visible) return null

      return (
        <GMiniSwitch
          value={this.switchConfig.value}
          onInput={this.handleSwitchChange}
          width={16}
          active-color="#1976D2"
          inactive-color="#BCC2CC"
          active-text={this.switchConfig.name}
        ></GMiniSwitch>
      )
    },
    renderTabs() {
      const { visible = false } = this.tabConfig

      if (!visible) return null

      return (
        <span class="inline-flex items-center">
          {this.tabConfig.name ? <span class="text-sm mr-2">{this.tabConfig.name}</span> : null}
          {this.tabConfig.tabs && this.tabConfig.tabs.length > 0 ? (
            <GRadio mode="button" inline={true} value={this.tabConfig.value} onInput={this.handleTabChange} items={this.tabConfig.tabs}></GRadio>
          ) : null}
        </span>
      )
    },
    renderDownload() {
      const { visible = false } = this.downloadConfig

      if (!visible) return null

      return (
        <GTooltip placement="top" content={this.$t('common.export') as string}>
          <span
            class={[
              'w-8 h-8 hover:bg-gray-30 rounded inline-flex items-center justify-center',
              this.isEmpty ? 'cursor-not-allowed text-text-t3' : 'cursor-pointer',
            ]}
            onClick={this.handleDownload}
          >
            <GIcon svgName="SolidExport" size={24} color={this.isEmpty ? '#b3bac5' : '#495973'}></GIcon>
          </span>
        </GTooltip>
      )
    },
    renderRightAction() {
      const tabContent = this.renderTabs()
      const downloadContent = this.renderDownload()

      if (tabContent === null && downloadContent === null) return null

      if (tabContent === null && downloadContent !== null) return downloadContent

      if (tabContent !== null && downloadContent === null) return tabContent

      return (
        <div class="flex items-center">
          {tabContent}
          <span class="ml-6"></span>
          {downloadContent}
        </div>
      )
    },
  },
  render() {
    return (
      <BCardContainer isLoading={this.isLoading} isEmpty={this.isEmpty} title={this.title} desc={this.desc}>
        <template slot="headerMiddle">{this.renderSwitch()}</template>
        <template slot="headerRightAction">{this.renderRightAction()}</template>
        <template slot="default">{this.$slots.default}</template>
      </BCardContainer>
    )
  },
})
