import { IAggregationData } from '@patsnap/synapse_common_interface'
import { generateChartImage } from '@pharmsnap/pharmsnap-web/utils/generateChartImage'
import { EChartsType, init } from 'echarts/core'

export function exportChartImage(echartInstance: EChartsType, title: string, isCN: boolean, userInfo: { userId: string }) {
  if (!echartInstance) return
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const originalOption = echartInstance?.getOption() as any
  const div = document.createElement('div')
  div.style.width = echartInstance?.getWidth() + 'px'
  div.style.height = echartInstance?.getHeight() + 'px'
  originalOption.animation = false

  const ecInstance = init(div)
  ecInstance.setOption(originalOption)
  const url = ecInstance?.getDataURL({ backgroundColor: '#fff' })
  generateChartImage({
    chartUrl: url || '',
    width: echartInstance?.getWidth() as number,
    height: echartInstance?.getHeight() as number,
    addHeight: 38,
    name: title,
    isCN,
    userInfo,
  })
}

export function isAggEmpty(data: IAggregationData) {
  return (
    !data.aggregation_result ||
    data.aggregation_result.length === 0 ||
    !data.aggregation_result[0]?.items?.length ||
    data.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
    data.aggregation_result[0]?.items.every(
      (i) =>
        !!i.aggregation_result?.[0] &&
        (!i.aggregation_result?.[0].items ||
          i.aggregation_result?.[0].items.length === 0 ||
          i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
    )
  )
}

export function getEmptyAggData() {
  return {
    aggregation_result: [
      { items: [], aggregation_field: '', total: 0 },
      { items: [], aggregation_field: '', total: 0 },
    ],
    total: 0,
  }
}
