import { getDisplayNameByLang } from '@patsnap/synapse_common_business'
import { E_QUERY_ITEM_CONDITION, IQueryValueLang } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { defineComponent, PropType } from '@vue/composition-api'
import { Icon } from 'patsnap-biz'
import { IFilterSelectValue } from '../../../types'
import $style from './FilterSelectCard.module.scss'
export const FilterSelectCard = defineComponent({
  name: 'FilterSelectCard',
  props: {
    condition: {
      required: true,
      type: String as PropType<E_QUERY_ITEM_CONDITION>,
    },
    filterLabel: {
      required: true,
      type: String,
    },
    filterValue: {
      required: true,
      type: Array as PropType<IFilterSelectValue[]>,
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()
    function onRemoveAll() {
      emit('removeAll')
    }
    function onRemoveValue(value: IFilterSelectValue) {
      emit('removeValue', value)
    }
    /**
     * 用本地语言展示聚合数据的标题
     * @param data
     * @returns
     */
    function getDisplayName<T extends IQueryValueLang>(data: T) {
      return getDisplayNameByLang(data, locale.value)
    }
    return { onRemoveAll, onRemoveValue, getDisplayName }
  },
  render() {
    return (
      <div
        data-testid="filter-select-card"
        class={[
          'mt-2 p-2',
          $style.filterValueBox,
          {
            [$style.exclude]: this.condition === E_QUERY_ITEM_CONDITION.NONE,
          },
        ]}
      >
        <div class={['flex items-center text-xs p-1', $style.fieldTitle]}>
          <span class="flex-1 truncate">
            {this.condition === E_QUERY_ITEM_CONDITION.NONE ? 'NOT' : 'AND'}
            <span>&nbsp;</span>
            {this.filterLabel}
          </span>
          <div class={['w-6 text-center cursor-pointer', $style.close]} data-testid="filter-select-card__close-all" onClick={this.onRemoveAll}>
            <Icon class={['text-base', $style.close]} icon="SolidCloseBig"></Icon>
          </div>
        </div>
        <div class={['text-sm', $style.valueContainer]}>
          {this.filterValue.map((item) => {
            return (
              <div class={['flex items-center p-1', $style.filterValueItem]}>
                <div class="flex-1 truncate" data-testid="filter-select-card__item__name">
                  {this.getDisplayName(item)}
                </div>
                <div
                  class={['w-6 text-center cursor-pointer', $style.subClose]}
                  data-testid="filter-select-card__item__close"
                  onClick={() => {
                    this.onRemoveValue(item)
                  }}
                >
                  <Icon icon="SolidCloseBig"></Icon>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  },
})
