import { I18nLang } from '@patsnap/synapse_common_interface'

export * from './commonList'
export * from './data-type-config'
export * from './emailAlert'
export * from './enum-lang/news'
export * from './geetest'
export * from './highlight'
export * from './knowledgeGraph'
export { COMPANY_EMPLOYEE_NUMBER_MAP, ESTIMATED_ENROLLMENT_NUMBER_MAP } from './rangeConfig'
export * from './static-data'
export * from './static-data/country'
export * from './static-data/drug'
export * from './time'

export const EMPTY_PLACEHOLDER = '-'
export const TEXT_SPLIT_SYMBOL = ', '
export const UNDISCLOSED = 'Undisclosed'
export const UNAVAILABLE = 'Unavailable'
export const LIST_PAGE_DEFAULT_PAGE_SIZE = 20
export const LIST_PAGE_PAGE_SIZE_LIST = [10, LIST_PAGE_DEFAULT_PAGE_SIZE, 50, 100]
export const FREE_USER_LIST_PAGE_PAGE_SIZE_LIST = [10, LIST_PAGE_DEFAULT_PAGE_SIZE]
export const feedbackUSAddress = 'https://help.patsnap.com/hc/en-us/requests/new'
export const USAGE_LIMIT_ERROR_CODE = 130081
export const MULTI_ENTITY_CONNECTOR = ' x '
export const TEXT_SPLIT_MAP: Record<I18nLang, string> = {
  cn: TEXT_SPLIT_SYMBOL,
  en: '. ',
}
