import { ElInput } from '@pharmsnap/shared/src/element-ui'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import { isUndefined } from 'lodash'
import { IAnalysisYearRangeInputValue } from '../../type'
import $classes from './BAnalysisChartYearRangeInput.module.scss'
export const BAnalysisChartYearRangeInput = defineComponent({
  name: 'BAnalysisChartYearRangeInput',
  props: {
    value: {
      type: Array as unknown as PropType<IAnalysisYearRangeInputValue>,
      default: () => [],
    },
    validRange: {
      type: Array as unknown as PropType<[number, number]>,
      default: () => [1780, dayjs().year()],
    },
    initRange: {
      type: Array as unknown as PropType<IAnalysisYearRangeInputValue>,
      default: () => [],
    },
    inputDisable: {
      type: Boolean,
    },
    isCN: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { value, validRange } = toRefs(props)

    const yearReg = /^\d{4}$/

    const validStartYear = computed(() => validRange?.value?.[0])

    const validEndYear = computed(() => validRange?.value?.[1])

    const startYear = ref(convertYearToInputText(pickStartYearFromValue(value.value.length ? value.value : props.initRange)))

    const endYear = ref(convertYearToInputText(pickEndYearFromValue(value.value.length ? value.value : props.initRange)))

    const showError = computed(() => !checkStartYear(startYear.value) || !checkEndYear(endYear.value))

    function checkValidYear(year: string) {
      if (!!year && !yearReg.test(year)) return false
      return true
    }

    function checkStartYear(startYear: string) {
      // 判断是否是年份
      if (!checkValidYear(startYear)) return false
      // 判断年份是否小于合法的年份
      if (!isUndefined(validStartYear.value) && Number(startYear) < validStartYear.value) return false
      // 当结束年份存在时，判断年份是否小于等于结束年份
      if (checkValidYear(endYear.value) && Number(startYear) >= Number(endYear.value)) return false

      return true
    }

    function checkEndYear(endYear: string) {
      // 判断是否是年份
      if (!checkValidYear(endYear)) return false
      // 判断年份是否大于合法的年份
      if (!isUndefined(validEndYear.value) && Number(endYear) > validEndYear.value) return false
      // 当开始年份存在时，判断年份是否小于等于开始年份
      if (checkValidYear(startYear.value) && Number(endYear) <= Number(startYear.value)) return false

      return true
    }

    function pickStartYearFromValue(value: IAnalysisYearRangeInputValue) {
      return value[0] && yearReg.test(`${value[0]}`) ? value[0] : undefined
    }

    function pickEndYearFromValue(value: IAnalysisYearRangeInputValue) {
      return value[1] && yearReg.test(`${value[1]}`) ? value[1] : undefined
    }

    function convertYearToInputText(year: number | undefined) {
      return isUndefined(year) ? '' : `${year}`
    }

    function handleStartYearChange(val: string) {
      startYear.value = val
      if (!checkStartYear(val)) return
      emit('input', [Number(val), Number(endYear.value)])
    }

    function handleEndYearChange(val: string) {
      endYear.value = val
      if (!checkEndYear(val)) return
      emit('input', [Number(startYear.value), Number(val)])
    }

    watch(value, (newVal) => {
      startYear.value = convertYearToInputText(pickStartYearFromValue(newVal))
      endYear.value = convertYearToInputText(pickEndYearFromValue(newVal))
    })

    watch(showError, () => emit('error', showError.value))

    return {
      startYear,
      endYear,
      showError,
      handleStartYearChange,
      handleEndYearChange,
      checkStartYear,
      checkEndYear,
    }
  },
  render() {
    return (
      <div class="inline-block relative">
        <span class="flex items-center">
          <ElInput
            size="small"
            value={this.startYear}
            disabled={this.inputDisable}
            onInput={this.handleStartYearChange}
            maxLength={4}
            class={[$classes.input, !this.inputDisable && !this.checkStartYear(this.startYear) && $classes.inputError]}
          ></ElInput>
          <span class="inline-block px-2 text-text-t1 text-sm">{this.isCN ? '至' : 'To'}</span>
          <ElInput
            size="small"
            value={this.endYear}
            disabled={this.inputDisable}
            onInput={this.handleEndYearChange}
            maxLength={4}
            class={[$classes.input, !this.inputDisable && !this.checkEndYear(this.endYear) && $classes.inputError]}
          ></ElInput>
        </span>
        <div class={['absolute -bottom-4 text-xs leading-3 font-normal text-red-default', !this.inputDisable && this.showError ? 'block' : 'hidden']}>
          {this.isCN ? '无效时间或超出时间范围' : 'Invalid date or out of time range'}
        </div>
      </div>
    )
  },
})
