import { ElTree } from '@pharmsnap/shared/element-ui'
import { IConvertResItem, ISearchTreeResItem, ITreeCheckData } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'

type IProp = {
  children: string
  label: string
  isLeaf: string
}

export const GTree = defineComponent({
  name: 'GTree',
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    propsKey: {
      type: Object as PropType<IProp>,
      default: () => ({
        children: 'children',
        label: 'label',
        isLeaf: 'is_leaf',
      }),
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    expandedIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    checkedIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    resolveFunc: {
      type: Function as PropType<
        (node: { checked: boolean; level: number; id: number; data: any }, resolve: (data: IConvertResItem[]) => void) => Promise<void>
      >,
    },
    searchId: {
      type: String,
      default: '',
    },
    showOrgLogo: {
      type: Boolean,
      default: false,
    },
    showMoreIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    customLoadMoreTitle: {
      type: String,
    },
    treeLimit: {
      type: Number,
    },
    nodeKey: {
      type: String,
      default: 'id',
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleCheck = (node: ISearchTreeResItem, checkData: ITreeCheckData) => {
      emit('check', node, checkData)
    }

    const handleCustomLoadMore = (node: any, callback: any) => {
      emit('customLoadMore', node, callback)
    }

    const handleNodeClick = (node: ISearchTreeResItem) => {
      emit('clickContent', node)
    }
    const handleNodeExpand = (node: ISearchTreeResItem) => {
      emit('nodeExpand', node)
    }

    const handleNodeCollapse = (node: ISearchTreeResItem) => {
      emit('nodeCollapse', node)
    }

    return { handleCheck, handleNodeClick, handleNodeExpand, handleCustomLoadMore, handleNodeCollapse }
  },
  render() {
    return (
      <ElTree
        data={this.treeData}
        data-testid="el-tree"
        empty-text=""
        node-key={this.nodeKey}
        on-check={this.handleCheck}
        on-node-click={this.handleNodeClick}
        on-node-expand={this.handleNodeExpand}
        on-node-collapse={this.handleNodeCollapse}
        expand-on-click-node={false}
        default-expanded-keys={this.expandedIds}
        default-checked-keys={this.checkedIds}
        check-strictly={this.checkStrictly}
        {...{
          props: {
            props: this.propsKey,
          },
        }}
        showCheckbox={this.showCheckbox}
        load={this.resolveFunc}
        lazy={this.lazy}
        onCustomLoadMore={this.handleCustomLoadMore}
        customLoadMoreTitle={this.customLoadMoreTitle}
        showLoadMoreIds={this.showMoreIds}
        treeLimit={this.treeLimit}
        scopedSlots={{
          default: this.$scopedSlots.default,
        }}
      ></ElTree>
    )
  },
})
