import { ALL_SUBJECT_CONFIG_MAP, translationalMedicineIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { ITranslationalMedicineIndexField } from '@patsnap/synapse_common_interface'
import { AggStackBarChartConfig, AggStackLineChartConfig, AggTwoDimDataItem, getChartColor } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { sortSubject } from '@pharmsnap/shared/src/utils'
import { computed, unref } from '@vue/composition-api'
import { IUseAnalysisTwoDimChartEntityOptions } from '../../type'
import { useAnalysisTwoDimChartEntity } from '../useAnalysisTwoDimChartEntity'

export function useAnalysisTransMedicineTwoDimChartEntity(options: Omit<IUseAnalysisTwoDimChartEntityOptions, 'getChartConfig'>) {
  const { xAxisName, yAxisName, layout } = options

  const isH = computed(() => {
    return unref(layout) === 'horizontal'
  })

  const { locale } = useLocale()

  const sortStackChartSubject = (a: AggTwoDimDataItem, b: AggTwoDimDataItem) => {
    const aDictId = a.x.key
    const bDictId = b.x.key
    return sortSubject(aDictId, bDictId)
  }

  const getStackChartConfig: IUseAnalysisTwoDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { firstAggField, secondAggField } = config

    const chartConfig: Partial<AggStackBarChartConfig | AggStackLineChartConfig> = {}

    if (firstAggField === 'PUB_DT_YEAR') {
      if (type === 'bar') {
        chartConfig.serverDtoAdjust = (data) => {
          data.items.forEach((item) => {
            item.display_name_cn = item.key
            item.display_name_en = item.key
          })
          return data
        }
      }
    }

    if (secondAggField === 'SUBJECT_ID') {
      if (type === 'bar') {
        chartConfig.xSorter = (a, b) => {
          const aDictId = a.key
          const bDictId = b.key

          return sortSubject(aDictId, bDictId)
        }
        chartConfig.color = (item) => ALL_SUBJECT_CONFIG_MAP[item.key]?.color || getChartColor(1)[0]

        chartConfig.optionAdjust = (options) => {
          options.grid = {
            ...(options.grid || {}),
            right: 200, // 用于展示 legend
          }
          if (!unref(isH)) {
            // 纵向堆叠图，图例也放右侧
            options.legend = {
              show: true,
              type: 'scroll',
              itemHeight: 14,
              itemWidth: 14,
              orient: 'vertical',
              right: 10,
              top: 'middle',
            }
            options.grid = {
              ...(options.grid || {}),
              top: 42,
              left: 40,
            }
          }

          return options
        }
      }
    }

    return chartConfig
  }

  const { firstDimAggField, secondDimAggField, chartEntity, dataList, uniqEntityDataList } = useAnalysisTwoDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'bar' || type === 'line') return getStackChartConfig(type, config)
      return
    },
  })

  // 聚合数量名称，从轴名称中获取
  const countFieldName = computed(() => unref(xAxisName))

  // 聚合实体名称，从轴名称中获取
  const entityFieldName = computed(() => unref(yAxisName))

  // 聚合类目名称，从转换医学语言包获取
  const stackFieldName = computed(
    () => translationalMedicineIndexFieldLangMap[unref(secondDimAggField) as ITranslationalMedicineIndexField]?.[unref(locale)] || ''
  )

  return {
    firstDimAggField,
    secondDimAggField,
    chartEntity,
    dataList,
    uniqEntityDataList,
    entityFieldName,
    countFieldName,
    stackFieldName,
    sortStackChartSubject,
  }
}
