import { EMPTY_PLACEHOLDER, trueOrFalseLangMap } from '@patsnap/synapse_common_config'
import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import { useLocale, useNavList } from '@pharmsnap/shared/src/composition'
import { IDrugDetail, INavListType } from '@pharmsnap/shared/src/types'
import { getDrugNavListQuery } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { isUndefined } from 'lodash'

export const BDrugExistDealField = defineComponent({
  name: 'BDrugExistDealField',
  props: {
    data: {
      type: Object as PropType<IDrugDetail>,
      required: true,
    },
  },
  setup(props) {
    const navList: INavListType = 'drug_deal'

    const { locale } = useLocale()

    const query = computed(() => getDrugNavListQuery([props.data], navList, false, E_QUERY_ITEM_CONDITION.ANY))

    const existDeal = computed(() => props.data.exist_deal)

    const { navToList } = useNavList({
      auth: false,
      navList: navList,
    })

    const handleNavToList = () => {
      if (!existDeal.value) return
      navToList({
        data_type: 'drug_deal',
        query: query.value,
      })
    }

    return {
      handleNavToList,
      existDeal,
      locale,
    }
  },
  render() {
    const isUnknown = isUndefined(this.existDeal)

    return (
      <span
        onClick={this.handleNavToList}
        data-testid="b-drug-exist-deal-field"
        class={[!isUnknown && this.existDeal ? 'hover:underline text-blue-default cursor-pointer' : '']}
      >
        {isUnknown ? EMPTY_PLACEHOLDER : trueOrFalseLangMap[String(this.existDeal)][this.locale]}
      </span>
    )
  },
})
