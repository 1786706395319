// 引入柱状图图表，图表后缀都为 Chart
import {
  BarChart,
  BarSeriesOption,
  GraphChart,
  GraphSeriesOption,
  HeatmapChart,
  HeatmapSeriesOption,
  LineChart,
  LineSeriesOption,
  MapChart,
  MapSeriesOption,
  PieChart,
  PieSeriesOption,
  RadarChart,
  ThemeRiverChart,
} from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  AxisPointerComponent,
  AxisPointerComponentOption,
  DatasetComponent,
  DatasetComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GeoComponent,
  GeoComponentOption,
  GraphicComponent,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  LegendScrollComponent,
  MarkAreaComponent,
  MarkAreaComponentOption,
  ParallelComponentOption,
  SingleAxisComponent,
  SingleAxisComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  TransformComponent,
  VisualMapComponent,
  VisualMapComponentOption,
  VisualMapContinuousComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { ECharts } from 'echarts/core'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { GraphicComponentLooseOption, RadarSeriesOption } from 'echarts/types/dist/shared'
import { merge } from 'lodash'
import { ECChartOption } from '../type'

echarts.use([
  BarChart,
  LineChart,
  HeatmapChart,
  MapChart,
  PieChart,
  ThemeRiverChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GeoComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  TransformComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  AxisPointerComponent,
  SingleAxisComponent,
  LabelLayout,
  CanvasRenderer,
  RadarChart,
  GraphChart,
  GraphicComponent,
])
export const _echarts = echarts
export type ECOption = echarts.ComposeOption<
  | DataZoomComponentOption
  | ParallelComponentOption
  | SingleAxisComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | HeatmapSeriesOption
  | MapSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | GeoComponentOption
  | DatasetComponentOption
  | LegendComponentOption
  | MarkAreaComponentOption
  | VisualMapComponentOption
  | AxisPointerComponentOption
  | RadarSeriesOption
  | GraphSeriesOption
  | GraphicComponentLooseOption
>

export abstract class Plot {
  public abstract readonly type: string
  public options!: ECOption
  public readonly container!: HTMLElement
  public instance!: ECharts

  constructor(options: ECChartOption, container: string | HTMLElement) {
    if (typeof container === 'string') {
      const el = document.getElementById(container)
      if (!el) throw new Error('The container does not found.')
      else this.container = el
    } else this.container = container

    this.updateOption(options)
    this.instance = echarts.init(this.container)
  }

  public updateOption(options: ECChartOption) {
    const chartOption = this.adaptor(options)
    this.options = merge(this.getDefaultOption(chartOption))
  }

  protected abstract getDefaultOption(options: ECOption): ECOption

  protected abstract adaptor(options: ECChartOption): ECOption
}
