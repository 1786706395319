import '@patsnap-ui/icon/assets/solid/TickCircle.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { ElTag } from '@pharmsnap/shared/element-ui'
import { encryptString } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import $classes from './BManualTag.module.scss'

export const BManualTag = defineComponent({
  name: 'BManualTag',
  props: {
    mode: {
      type: String as PropType<'icon' | 'textIcon'>,
      default: 'textIcon',
    },
  },
  setup() {
    const { isCN } = useLocale()
    const tooltip = computed(() => {
      return isCN.value ? '人工标引' : 'Manual'
    })
    return { tooltip }
  },
  render() {
    if (this.mode === 'icon') {
      return (
        <GTooltip placement="top" theme="light" content={this.tooltip}>
          <PtIcon icon="SolidTickCircle" class="text-xl text-blue-default"></PtIcon>
        </GTooltip>
      )
    }
    return (
      <ElTag class={[$classes.tag, 'icon-font']} color="#1976D2" size="small" effect="plain">
        <div class="flex items-center">
          <span domPropsInnerHTML={encryptString(this.tooltip)}></span>
          <PtIcon icon="SolidTickCircle" class="text-xl ml-1"></PtIcon>
        </div>
      </ElTag>
    )
  },
})
