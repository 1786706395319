import { useChartEntityTooltip } from '@pharmsnap/shared/composition/useChartEntityTooltip'
import { IQueryDataEntityType } from '@pharmsnap/shared/types'
import { ref, Ref } from '@vue/composition-api'

export function useMouseOverAxis<T>(options: {
  container: Ref<HTMLElement | undefined>
  list: Ref<T[]>
  labelRotate: Ref<number>
  layout: Ref<'vertical' | 'horizontal'>
  entityType: Ref<Exclude<IQueryDataEntityType, 'mechanism'> | undefined>
  founder: (item: T, axisName: string) => boolean
  getEntityId: (item: T) => string
}) {
  const { list, container, founder, getEntityId, entityType, labelRotate, layout } = options

  const axisDimPosition = ref({
    left: 0,
    top: 0,
  })

  const axisEntity = ref<{ id: string; type: IQueryDataEntityType }>({
    id: '',
    type: 'mechanism',
  })

  const axisDimStyle = ref({})

  useChartEntityTooltip(container, axisDimPosition, axisEntity, axisDimStyle)

  function handleAxisMouseover(params: any) {
    if (!params.value) return
    if (!params.event) return
    const target = params.event.target
    if (!target) return
    const targetAxisRect = target.getBoundingRect().clone()
    target.transform && targetAxisRect.applyTransform(target.transform)
    const axisName = params.value
    const found = list.value.find((item) => founder(item, axisName))
    if (!found) return
    if (!entityType || !entityType.value) return
    const style: any = {
      width: `${targetAxisRect.width}px`,
      height: labelRotate.value !== 0 && layout.value === 'vertical' ? '16px' : `${targetAxisRect.height}px`,
    }
    if (labelRotate.value !== 0 && layout.value === 'vertical') {
      style['transform'] = `rotate(-${labelRotate}deg)`
      style['transformOrigin'] = 'right top'
    }
    axisDimPosition.value = {
      left: labelRotate.value !== 0 && layout.value === 'vertical' ? targetAxisRect.x - 16 : targetAxisRect.x,
      top: targetAxisRect.y,
    }

    axisDimStyle.value = style

    axisEntity.value = {
      id: getEntityId(found),
      type: entityType.value,
    }
  }

  return {
    handleAxisMouseover,
  }
}
