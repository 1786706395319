import { computed, getCurrentInstance, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { IFilterServiceInjection, useInjectFilterService } from './useProvideFilterService'

type IUseFilterAdvanceEnhanceServiceOptions<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  identity: MaybeRef<IDENTITY>
  isAdvance: MaybeRef<boolean>
  triggerText: MaybeRef<string>
  onExpand: () => Promise<void>
}

export function useFilterAdvanceEnhanceService<IDENTITY extends string = string, AGG_FIELD extends string = string>(
  options: IUseFilterAdvanceEnhanceServiceOptions<IDENTITY, AGG_FIELD>
) {
  const ins = getCurrentInstance()

  const { identity, isAdvance, triggerText, onExpand } = options

  const { filterService, advanceDialogService } = useInjectFilterService() as IFilterServiceInjection

  const state = computed(() => filterService.stateRecord[unref(identity)])

  const enhanceTriggerText = computed(() => {
    return !unref(isAdvance) ? unref(triggerText) : ins?.proxy.$tc('bFilterNext.showAll') || ''
  })

  const onExpandEnhanced = async () => {
    if (!unref(isAdvance)) {
      await onExpand()
      return
    }
    await advanceDialogService.open(unref(state))
  }

  return {
    triggerText: enhanceTriggerText,
    onExpand: onExpandEnhanced,
  }
}
