import watermarkCN from '../assets/img/watermarkCN.png'
import watermarkEN from '../assets/img/watermarkEN.png'
/**
 * 获取图表水印
 */
export function getChartWaterMark(isCN: boolean) {
  return {
    waterMark: isCN ? watermarkCN : watermarkEN,
    /** 1.5倍尺寸 */
    imgWidth: 222 * 1.25,
    imgHeight: 48 * 1.25,
  }
}
