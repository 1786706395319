import '@patsnap-biz/design-tokens/scss/_variables-IP.css'
import '@patsnap-ui/icon/assets/solid/EyeOff.svg'
import '@patsnap-ui/icon/assets/solid/FolderMany.svg'
import '@patsnap-ui/icon/assets/solid/move.svg'
import '@patsnap-ui/icon/assets/solid/see.svg'
import { removeRegisterTracking } from '@patsnap/synapse_common_business'
import initWasmEncryptionModule from '@patsnap/synapse_encryption'
import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import { IUserInfo } from '@pharmsnap/shared/src/types/login'
import VueCompositionAPI from '@vue/composition-api'
import VueLazyComponent from '@xunlei/vue-lazy-component'
import { useAuthStore } from 'pharmsnapMF_shared/composition'
import { createI18n, I18nLang } from 'pharmsnapMF_shared/i18n'
import { useVxeTable } from 'pharmsnapMF_shared/plugins'
import { getLocalStorage, initSecurity, removeHistoryQueries, shouldRegisterCopyFunc } from 'pharmsnapMF_shared/utils'
import PortalVue from 'portal-vue'
import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import WujieVue from 'wujie-vue2'
import App from './App'
import { IS_CN_REGION } from './config'
import { initDevtoolsDetector } from './features/devtools-detector'
import { FreemiumCompleteUserinfo } from './features/freemium-complete-userinfo'
import { SessionChecker } from './features/session-checker'
import { registerEvent } from './module/event/event'
import { registerRegisterTracking } from './module/register-tracking/register-tracking'
import { getSessionIdFromToken, setSynapseLoginCookie } from './module/synapse-cookie/synapse-cookie'
import router from './router'
import { initPassport, initTools, setAppContext } from './service/service'
import './style/style.scss'
import { initSentry } from './utils/auth'
import { getLocaleLang, setLocaleLang } from './utils/business/locale'
import { getLangValue } from './utils/business/uniform-setting'
Vue.use(WujieVue)
Vue.use(PortalVue)
Vue.use(VueCompositionAPI)
Vue.use(InfiniteLoading)
Vue.use(VueLazyComponent)
useVxeTable(Vue)
registerEvent()
registerRegisterTracking()
Vue.config.productionTip = false
;(async () => {
  const [passport] = await Promise.all([initPassport(), initWasmEncryptionModule()])
  const token = passport.getToken('token')
  setAppContext()
  removeHistoryQueries(['login_success'])
  const {
    actions,
    state,
    getters: { isBasicUser, isEnterpriseUser },
  } = useAuthStore()
  let lang = getLocaleLang()
  if (token) {
    const sessionChecker = new SessionChecker()
    const sessionId = getSessionIdFromToken(token)
    const isNewSession = sessionChecker.checkIsNewSession(sessionId)
    setSynapseLoginCookie(sessionId)
    removeRegisterTracking()
    const [userLang] = await Promise.all([getLangValue(true), actions.syncUserInfo(isNewSession)])
    // 有360权限的用户，获取一下360用量
    state.userInfo.value?.ls360_role && (await Promise.all([actions.syncSynapseChatUserInfo(isNewSession), actions.syncSynapseChatUsage()]))
    if (['cn', 'en'].includes(userLang)) {
      lang = userLang as I18nLang
      setLocaleLang(lang)
    }
    //必须在同步用户信息之后调用
    initSecurity(isBasicUser.value || isEnterpriseUser.value)
    FreemiumCompleteUserinfo.getSingleton().init({
      sessionId,
      userId: state.userInfo.value?.user_id || '',
      userInfoIsComplete: !!state.userInfo.value?.profile_complete,
      router,
    })
  }

  const i18n = await createI18n(lang)
  initTools(state.userInfo.value || undefined)
  await sendSaleLead(state.userInfo.value)
  new Vue({
    i18n,
    router,
    render: (h) => h(App),
  }).$mount('#app')
  setTimeout(() => {
    initWaterMark(state.userInfo.value?.user_id || '')
    initDevtoolsDetector(() => {
      // 需要在设置完控制台监控后再初始化sentry,否则会导致控制台监控无法正常工作
      // 原因猜测: DevtoolsDetector和sentry都劫持了console.log,如果先初始化sentry,则DevtoolsDetector的判断逻辑会错误
      initSentry(state.userInfo.value)
    })
    shouldRegisterCopyFunc()
  }, 0)
  /**
   * 发送销售线索
   * @param userInfo
   * @returns
   */
  async function sendSaleLead(userInfo: IUserInfo | null) {
    // 没有用户信息不发送销售线索
    if (!userInfo) {
      return
    }
    // 付费用户不发送销售线索
    if (userInfo.phs_role !== 'FREE') {
      return
    }
    // 老用户之前已经发送过销售线索不再发送
    if (userInfo?.setting?.custom_setting?.lead_sended_flag_backend) {
      return
    }
    // 新用户已发送过销售线索不再发送
    if (userInfo?.setting?.custom_setting?.lead_sended_flag_frontend) {
      return
    }
    if (IS_CN_REGION) {
      const registerSubmitInfoKey = 'registerSubmitInfo'
      // 游客版在全信息注册后会存储用户是否申请bio试用,是否申请化学试用的信息,在此处取出来发送销售线索
      const registerSubmitInfo =
        getLocalStorage<{ apply_bio_trial?: boolean; apply_chemical_trail?: boolean; campaign_id?: string }>(registerSubmitInfoKey)
      if (registerSubmitInfo) {
        localStorage.removeItem(registerSubmitInfoKey)
        return Promise.all([
          putSaleLead({ userInfo, campaign_id: registerSubmitInfo.campaign_id }), // 发送synapse销售线索
          // 通知后端是否需要通知申请bio/chemical试用,让后端通知bio/chemical业务发送bio/chemical的销售线索
          registerSubmitInfo.apply_bio_trial || registerSubmitInfo.apply_chemical_trail ? sendNotice(registerSubmitInfo) : null,
          actions.updateUserSetting({
            // 将注册时用户是否申请bio试用,是否申请化学试用的信息保存到用户设置中
            custom_setting: {
              lead_sended_flag_frontend: true,
              apply_bio_trial: !!registerSubmitInfo.apply_bio_trial,
              apply_chemical_trail: !!registerSubmitInfo.apply_chemical_trail,
            },
          }),
        ])
      }
    } else {
      // 海外都需要发送销售线索
      return Promise.all([
        putSaleLead({ userInfo }),
        actions.updateUserSetting({
          custom_setting: {
            lead_sended_flag_frontend: true,
          },
        }),
      ])
    }
  }
})()
async function sendNotice(sendNoticeParams: { apply_bio_trial?: boolean; apply_chemical_trail?: boolean }) {
  return getService().login.sendUserUpdateNotice({
    new_user: true,
    bio_notice: sendNoticeParams.apply_bio_trial,
    chemical_notice: sendNoticeParams.apply_chemical_trail,
  })
}
async function putSaleLead(putSaleLeadParams: { userInfo: IUserInfo | null; campaign_id?: string }) {
  const { userInfo, campaign_id } = putSaleLeadParams || {}
  if (!userInfo) {
    return
  }
  const promises = [
    getService().login.putBOSaleLead({
      email: userInfo.email || '',
      country: userInfo.country || '',
      firstname: userInfo.firstname || '',
      lastname: userInfo.lastname || '',
      job_role: userInfo.job_role || '',
      manual_company: userInfo.manual_company || '',
      media_type: userInfo.media_type || '',
      campaign_promotion: userInfo.campaign_promotion || '',
      channel_code: userInfo.channel_code || '',
      channel_source: userInfo.channel_source || '',
      lead_source: userInfo.lead_source || '',
      invitation_code: userInfo.invitation_code || '',
    }),
  ]
  if (campaign_id) {
    promises.push(
      getService().login.putSaleLead({
        // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=166231318#id-%E3%80%90PBI5289%E3%80%91%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%92%8C%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%B8%8E%E7%BD%91%E9%A1%B5%E6%8A%A5%E5%91%8A%E4%B8%8B%E8%BD%BD%E7%AD%96%E7%95%A5%E8%B0%83%E6%95%B4-%E9%94%80%E5%94%AE%E6%98%93%E6%B8%A0%E9%81%93%E7%A0%81
        // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=169965547
        // https://confluence.zhihuiya.com/pages/viewpage.action?pageId=181053163
        campaign_id,
      })
    )
  }
  return Promise.all(promises)
}
async function initWaterMark(userId: string) {
  if (!userId) {
    return
  }
  const { default: WaterMark } = await import('l-watermark')
  WaterMark.page({
    target: document.body,
    text: userId,
    color: 'rgba(73, 89, 115, 0.005)',
    fontSize: 14,
  })
}
