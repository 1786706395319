import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { defineComponent, unref } from '@vue/composition-api'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterValueGroupNext } from '../type'
import $classes from './BFilterSelectedGroups.module.scss'

export const BFilterSelectedGroups = defineComponent({
  name: 'BFilterSelectedGroups',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    renderSelectedGroup(group: IFilterValueGroupNext) {
      const { condition, label, values } = group

      const isNot = condition === 'NOT'

      const theme = unref(this.filterService.theme)

      return (
        <div
          class={[
            'rounded border py-1 text-xs',
            isNot
              ? ['border-red-default', 'text-red-default']
              : theme === 'synapse'
              ? ['border-blue-default', 'text-blue-default']
              : ['border-green-default', 'text-green-default'],
          ]}
        >
          <div class={['flex items-center leading-4 px-2']}>
            <span class="font-semibold flex-shrink-0 mr-2">{condition}</span>
            <span class="flex-1 truncate">{label}</span>
            <GIcon
              nativeOn={{
                click: () => this.selectService.removeFilterGroup(group),
              }}
              size={24}
              class={[
                'flex-shrink-0 ml-1 cursor-pointer',
                isNot
                  ? 'text-red-disable hover:text-red-default'
                  : theme === 'synapse'
                  ? 'text-blue-disable hover:text-blue-default'
                  : 'text-green-disable hover:text-green-default',
              ]}
              svgName="SolidCloseBig"
            ></GIcon>
          </div>
          <div>
            {values.map((i) => (
              <div
                class={[
                  'flex items-center rounded px-1 mx-1 group h-6',
                  isNot ? 'hover:bg-red-light' : theme === 'synapse' ? 'hover:bg-blue-light' : 'hover:bg-green-light',
                ]}
              >
                <span class="flex-1 truncate">{this.locale === 'cn' ? i.display_name_cn || i.display_name_en : i.display_name_en}</span>
                <GIcon
                  nativeOn={{
                    click: () => this.selectService.removeFilterGroupValue(group, i),
                  }}
                  size={24}
                  class={[
                    'flex-shrink-0 ml-1 cursor-pointer group-hover:inline hidden',
                    isNot
                      ? 'text-red-disable hover:text-red-default'
                      : theme === 'synapse'
                      ? 'text-blue-disable hover:text-blue-default'
                      : 'text-green-disable hover:text-green-default',
                  ]}
                  svgName="SolidCloseMedium"
                ></GIcon>
              </div>
            ))}
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <div class="flex items-center text-xs mb-2">
          <span class="flex-1 truncate text-text-t3">{this.$t('bFilterNext.selectedFilters')}</span>
          <button
            onClick={this.selectService.removeAllFilterGroup}
            data-size="small"
            data-type="transparent"
            class={['pt-ui-btn ml-1', $classes.clearAll]}
          >
            {this.$t('bFilterNext.clearAll')}
          </button>
        </div>
        {unref(this.selectService.filterValueGroups).map((group, index) => (
          <div class={{ 'mt-[10px]': index !== 0 }}>{this.renderSelectedGroup(group)}</div>
        ))}
      </div>
    )
  },
})
