export function getMergeCellMap<T>(data: T[], decideNeedFn: (a: T, b: T) => boolean) {
  const mergedMap: Record<number, { rowspan: number; colspan: number }> = {}
  for (let i = 0, totalLength = data.length; i < totalLength; ) {
    const currentData = data[i]
    let rowspan = 1
    let j = i + 1
    for (; j < totalLength && decideNeedFn(currentData, data[j]); j++) {
      mergedMap[j] = { rowspan: 0, colspan: 0 }
      rowspan++
    }
    mergedMap[i] = { rowspan, colspan: 1 }
    i = j
  }
  return mergedMap
}
