import SaveToWorkspace from '@patsnap-biz/save-to-workspace-v2'
import { defineComponent, getCurrentInstance, onUnmounted, ref } from '@vue/composition-api'
import { workspaceComponentProps } from '../../type'
import $classes from '../Workspace.module.scss'
import SaveClinicalService from './SaveClinicalService'

const { SaveClinicalToWorkspace } = SaveToWorkspace
export const ClinicalWorkspace = defineComponent({
  name: 'ClinicalWorkspace',
  props: workspaceComponentProps(),
  setup(props) {
    const instance = getCurrentInstance()

    const ClinicalService = ref()

    ClinicalService.value = new SaveClinicalService({ vm: instance?.proxy, ...props })

    const close = () => {
      ClinicalService.value = null
    }

    onUnmounted(close)

    return {
      ClinicalService,
    }
  },
  render() {
    if (this.ClinicalService) {
      return <SaveClinicalToWorkspace service={this.ClinicalService} append-to-body class={$classes.workspace}></SaveClinicalToWorkspace>
    }
    return <span></span>
  },
})
