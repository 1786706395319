import {
  convertATCDic,
  convertDic,
  convertDisease,
  convertDrug,
  convertDrugType,
  convertMechanism,
  convertMeetings,
  convertOrganization,
  convertOutcome,
  convertPhaseDic,
  convertRegion,
  convertStudyCode,
  convertTarget,
  convertTopic,
} from '@patsnap/synapse_autocomplete'
import {
  IACListItem,
  IAutoCompleteEntityRes,
  IAutoCompleteType,
  IClinicalIndexField,
  IClinicalResultIndexField,
  IConvertDataParams,
  ICoreAutoCompleteRes,
  IDiseaseAutoCompleteItem,
  IDrugAutoCompleteItem,
  IDrugDealIndexField,
  IDrugDevStatusIndexField,
  IDrugTypeDicAutoCompleteItem,
  ILiteratureIndexField,
  IOrgAutoCompleteItem,
  IPatentIndexField,
  IQueryItemCustomEntity,
  IQueryItemEntity,
  IQueryItemField,
  IQueryValue,
  IQueryValueText,
  ISearchStrategy,
  ITargetAutoCompleteItem,
  ITopicAutoCompleteRes,
  ITranslationalMedicineIndexField,
} from '@patsnap/synapse_common_interface'
import { dicCodeMap, type2ServiceKeyMap } from '@pharmsnap/shared/config/autocomplete'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import {
  IAutoCompleteParams,
  IAutoCompleteRes,
  IBAcTag,
  IHomeQueryValueEntityType,
  IHotTagType,
  IListItem,
  IPatentNumberDetail,
} from '@pharmsnap/shared/types'
import dayjs from 'dayjs'
import { isUndefined } from 'lodash'
import { I18nLang } from 'pharmsnapMF_shared/i18n'
import { getQueryValueEntityId } from '../../search'
import { transformDateString2timestamp } from '../../time'
import { sharedCtx } from './../../../context'
import { CustomNumberRageConfig, orgTypeConfig, tagData } from './config'
import {
  ClinicalFieldType,
  ClinicalFinderKey,
  ClinicalResultFieldType,
  DataTagKey,
  DrugDealFieldType,
  DrugFinderFieldType,
  DrugFinderKey,
  FinderFieldType,
  IClinicalResultFinderKey,
  IConvertQueryConfigItem,
  ICustomNumberRangeKey,
  IDrugDealFinderKey,
  IReverseConfig,
  ITranslationalMedicineFinderKey,
  LiteratureFinderKey,
  PatentFieldType,
  PatentFinderKey,
  TranslationalMedicineFieldType,
} from './type'

export const convertDataStrategyMap: Map<IAutoCompleteType, (params: IConvertDataParams) => IACListItem[]> = new Map()

const labelData = {
  drug: {
    name_en: 'Drug',
    name_cn: '药物',
  },
  disease: {
    name_en: 'Indication',
    name_cn: '适应症',
  },
  target: {
    name_en: 'Target',
    name_cn: '靶点',
  },
  organization: {
    name_en: 'Organization',
    name_cn: '机构',
  },
  topic: {
    name_en: 'Keyword',
    name_cn: '关键词',
  },
  drugType: {
    name_en: 'Drug Type',
    name_cn: '药物类型',
  },
  recommend: {
    name_en: 'Best Fit',
    name_cn: '最佳匹配',
  },
}

const convertCore = (params: IConvertDataParams<ICoreAutoCompleteRes>) => {
  const getConvertedDrug = (drugList: IDrugAutoCompleteItem[] = []) =>
    convertDrug({
      data: drugList,
      locale: params.locale,
      name: labelData.drug[`name_${params.locale}`],
      showIcon: true,
    })
  const getConvertedDisease = (diseaseList: IDiseaseAutoCompleteItem[] = []) =>
    convertDisease({
      data: diseaseList,
      locale: params.locale,
      name: labelData.disease[`name_${params.locale}`],
      showIcon: true,
    })
  const getConvertedTarget = (targetList: ITargetAutoCompleteItem[] = []) =>
    convertTarget({
      data: targetList,
      locale: params.locale,
      name: labelData.target[`name_${params.locale}`],
      showIcon: true,
    })
  const getConvertedOrganization = (organizationList: IOrgAutoCompleteItem[] = []) =>
    convertOrganization({
      data: organizationList,
      locale: params.locale,
      name: labelData.organization[`name_${params.locale}`],
      keywords: params.keywords,
      showIcon: true,
    })
  const getConvertedDrugType = (drugTypeList: IDrugTypeDicAutoCompleteItem[] = []) =>
    convertDrugType({
      data: drugTypeList,
      locale: params.locale,
      name: labelData.drugType[`name_${params.locale}`],
      keywords: params.keywords,
      showIcon: true,
      itemType: 'DrugType',
    })

  const getConvertedTopic = (topicList: ITopicAutoCompleteRes[] = []) =>
    convertTopic({
      data: topicList,
      locale: params.locale,
      keywords: params.keywords,
      name: labelData.topic[`name_${params.locale}`],
      showIcon: true,
    })

  const recommend = params.data.recommend || {}

  const recommendList: IACListItem<
    IDrugAutoCompleteItem | IDiseaseAutoCompleteItem | ITargetAutoCompleteItem | IOrgAutoCompleteItem | IDrugTypeDicAutoCompleteItem
  >['data'] = []

  const recommendMap: Partial<Record<keyof IAutoCompleteEntityRes, (list: any) => IACListItem[]>> = {
    drug: getConvertedDrug,
    disease: getConvertedDisease,
    target: getConvertedTarget,
    organization: getConvertedOrganization,
    drug_type: getConvertedDrugType,
  }

  Object.keys(recommend).forEach((key) => {
    const typedKey = key as keyof IAutoCompleteEntityRes
    const converter = recommendMap[typedKey]
    if (recommend[typedKey]?.length && !!converter) {
      recommendList.push(...(converter(recommend[typedKey])?.[0]?.data || []))
    }
  })

  // 猜你想搜
  const convertedRecommendData: IACListItem = {
    name: labelData.recommend[`name_${params.locale}`],
    showIcon: true,
    data: recommendList.sort((a, b) => (b.allData?.total_count || 0) - (a.allData?.total_count || 0)),
    dataType: 'Recommend',
  }

  return [
    convertedRecommendData,
    ...getConvertedDrug(params.data.drug),
    ...getConvertedDisease(params.data.disease),
    ...getConvertedTarget(params.data.target),
    ...getConvertedOrganization(params.data.organization),
    ...getConvertedDrugType(params.data.drug_type),
    ...getConvertedTopic(params.data.topic),
  ]
}

const convertPatent = (params: IConvertDataParams<IPatentNumberDetail>): IACListItem<IListItem>[] => {
  const { data } = params
  if (data.patent_id) {
    return [
      {
        data: [
          {
            id: data.patent_id,
            name_en: '',
            name_cn: '',
            allData: data,
          },
        ] as IListItem[],
      },
    ]
  }
  return []
}

convertDataStrategyMap.set('Drug', convertDrug)
convertDataStrategyMap.set('Disease', convertDisease)
convertDataStrategyMap.set('Target', convertTarget)
convertDataStrategyMap.set('Organization', convertOrganization)
convertDataStrategyMap.set('Mechanism', convertMechanism)
convertDataStrategyMap.set('ATC', convertATCDic)
convertDataStrategyMap.set('DrugType', convertDrugType)
convertDataStrategyMap.set('Core', convertCore)
convertDataStrategyMap.set('Region', convertRegion)
convertDataStrategyMap.set('Phase', convertPhaseDic)
convertDataStrategyMap.set('Regulation', convertDic)
convertDataStrategyMap.set('TherapeuticArea', convertDisease)
convertDataStrategyMap.set('Topic', convertTopic)
convertDataStrategyMap.set('PatentNumber', convertPatent)
convertDataStrategyMap.set('Outcome', convertOutcome)
convertDataStrategyMap.set('StudyCode', convertStudyCode)
convertDataStrategyMap.set('ADC_Antibody', convertDrug)
// ADC Linker 和 Payload 都是字典和 Drug Type 结构一致
convertDataStrategyMap.set('ADC_Linker', convertDrugType)
convertDataStrategyMap.set('ADC_Payload', convertDrugType)
convertDataStrategyMap.set('ADC_Antibody_Type', convertDrugType)
convertDataStrategyMap.set('ADC_SITE', convertDrugType)
convertDataStrategyMap.set('PATENT_TECHNOLOGY', convertDrugType)
convertDataStrategyMap.set('DATA_SOURCE_MEETING', convertMeetings)

export const drugFinderReverseMap: Partial<Record<IDrugDevStatusIndexField, IReverseConfig>> = {
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  DRUG_ID: {
    key: 'drugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  ORGANIZATION_ID_ONEID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  ORG_TYPE_ONEID: {
    key: 'organizationTypeItems',
    dataType: 'orgType',
  },
  DEV_ORG_ID_ONEID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  MECHANISM_ACTION_ID: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  ATC_CODE: {
    key: 'atcItems',
    dataType: 'input',
    icon: 'Atc',
  },
  DEV_STATUS: {
    key: 'phaseItems',
    dataType: 'tag',
  },
  GLOBAL_HIGHEST_DEV_STATUS: {
    key: 'highestPhaseItems',
    dataType: 'tag',
  },
  COUNTRY_COLLAPSED: {
    key: 'approvedCountryItems',
    dataType: 'tag',
  },
  DEV_STATUS_TIME: {
    key: 'approvedDateRange',
    dataType: 'date',
  },
  ORG_EMP_NUMBER_GENERAL_ONEID: {
    key: 'employeeNumberItems',
    dataType: 'tag',
  },
  THERAPEUTIC_AREA: {
    key: 'therapeuticAreasItems',
    dataType: 'tag',
  },
  SPECIAL_REVIEW_COUNTRY_BINARY_TUPLES: {
    key: 'regulationItems',
    dataType: 'tag',
  },
  ANTIBODY_DRUG_ID: {
    key: 'adcAntibodyItems',
    dataType: 'input',
  },
  LINKER_ID: {
    key: 'adcLinkerItems',
    dataType: 'input',
  },
  PAYLOAD_ID: {
    key: 'adcPayloadItems',
    dataType: 'input',
  },
  IS_STARTUPS: {
    key: 'startupItems',
    dataType: 'tag',
  },
  EXIST_DEAL: {
    key: 'dealExistItems',
    dataType: 'tag',
  },
}

export const clinicalProgressReverseMap: Partial<Record<IClinicalIndexField, IReverseConfig>> = {
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  ALL_FIELDS_TL: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  MAIN_EXPERIMENTATION_DRUG_ID: {
    key: 'clinicalDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  CONTROL_DRUG_ID: {
    key: 'controlDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  MECHANISM_ACTION_ID: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  STUDY_FIRST_POSTED_DATE: {
    key: 'selectedFirstPostDateRangeItems',
    dataType: 'date',
  },
  RECRUITMENT_STATUS_NAME_NOR_V2: {
    key: 'recruitmentStatusItems',
    dataType: 'tag',
  },
  COUNTRY_COLLAPSED: {
    key: 'sponsorCountryItems',
    dataType: 'tag',
  },
  REGISTRY_ID_ONEID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  CONDITION_DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  REGISTER_NUMBER: {
    key: 'nctItems',
    dataType: 'input',
  },
  SECONDARY_ID: {
    key: 'nctItems',
    dataType: 'input',
  },
  SECONDARY_ID_VALUE: {
    key: 'nctItems',
    dataType: 'input',
  },
  RELATED_ID: {
    key: 'nctItems',
    dataType: 'input',
  },
  STUDY_IDENTIFIER_SUGG: {
    key: 'studyCodeItems',
    dataType: 'input',
  },
  VIRTUAL_REGISTER_NUMBER: {
    key: 'nctItems',
    dataType: 'input',
  },
  VIRTUAL_TITLE: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  BRIEF_TITLE: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  BRIEF_TITLE_CN: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  OFFICIAL_TITLE: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  OFFICIAL_TITLE_CN: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  BRIEF_TITLE_WITH_ACRONYM: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  ACRONYM: {
    key: 'clinicalTrialItems',
    dataType: 'input',
  },
  RECRUITMENT_STATUS_NAME_NOR: {
    key: 'recruitmentStatusItems',
    dataType: 'tag',
  },
  NORMALIZED_PHASE: {
    key: 'studyPhaseItems',
    dataType: 'tag',
  },
  START_DATE: {
    key: 'selectedDateRangeItems',
    dataType: 'date',
  },
  ENROLLMENT: {
    key: 'estimatedEnrollmentItems',
    dataType: 'numberRange',
  },
  SPONSOR_TYPE_LEADER_ONEID: {
    key: 'organizationTypeItems',
    dataType: 'orgType',
  },
  SPONSOR_TYPE_COLLABORATOR_ONEID: {
    key: 'organizationTypeItems',
    dataType: 'orgType',
  },
  SPONSOR_EMP_NUM_LEADER_GENERAL_ONEID: {
    key: 'employeeNumberItems',
    dataType: 'tag',
  },
  SPONSOR_EMP_NUM_COLLABORATOR_GENERAL_ONEID: {
    key: 'employeeNumberItems',
    dataType: 'tag',
  },
  THERAPEUTIC_AREA: {
    key: 'therapeuticAreasItems',
    dataType: 'tag',
  },
}

export const clinicalResultReverseMap: Partial<Record<IClinicalResultIndexField, IReverseConfig>> = {
  MAIN_EXPERIMENTATION_DRUG_ID: {
    key: 'experimentalDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  MAIN_EVALUATE_DRUG_ID: {
    key: 'experimentalDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  CONDITION_DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  SPONSOR_COLLABORATOR_ID_ONEID: {
    key: 'sponsorItems',
    dataType: 'input',
    icon: 'Company',
  },
  STUDY_CODE: {
    key: 'studyCodeItems',
    dataType: 'input',
  },
  REGISTER_NUMBER_EXTEND: {
    key: 'trialNumberItems',
    dataType: 'input',
  },
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  ALL_FIELDS_TL: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  CONTROL_DRUG_ID: {
    key: 'controlDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  THERAPEUTIC_AREA: {
    key: 'therapeuticAreasItems',
    dataType: 'tag',
  },
  MECHANISM_ACTION_ID: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  SOURCE_INFO_NOR_ID: {
    key: 'meetingsItems',
    dataType: 'tag',
  },
  SOURCE_INFO_NOR_ANCESTOR_ID: {
    key: 'meetingsItems',
    dataType: 'tag',
  },
  THERAPY_TYPE_NOR_ID: {
    key: 'therapyTypeItems',
    dataType: 'tag',
  },
  NORMALIZED_PHASE: {
    key: 'studyPhaseItems',
    dataType: 'tag',
  },
  PUBLISHED_TIME: {
    key: 'publicationDateItems',
    dataType: 'date',
  },
  GENERAL_EVALUATION_NOR_ID: {
    key: 'evaluationItems',
    dataType: 'tag',
  },
  OVERALL_ENROLLMENT: {
    key: 'analyzedParticipantsItems',
    dataType: 'numberRange',
  },
}

export const patentFinderReverseMap: Partial<Record<IPatentIndexField, IReverseConfig>> = {
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  TACD: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TACD_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  CLMS: {
    key: 'attributeItems',
    dataType: 'input',
  },
  CLMS_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TAC: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TAC_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TA: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TA_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TTL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  TTL_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  ABST: {
    key: 'attributeItems',
    dataType: 'input',
  },
  ABST_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  DESC: {
    key: 'attributeItems',
    dataType: 'input',
  },
  DESC_ALL: {
    key: 'attributeItems',
    dataType: 'input',
  },
  PHS_CORE_DRUG_ID: {
    key: 'drugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  PHS_DRUG_TYPE_ID: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  PHS_ORG_ID_ONEID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  PHS_TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  PHS_DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  ANCS_TYPE: {
    key: 'assigneeTypeItems',
    dataType: 'tag',
  },
  ANS_TYPE: {
    key: 'assigneeTypeItems',
    dataType: 'tag',
  },
  COUNTRY: {
    key: 'authorityCountryItems',
    dataType: 'tag',
  },
  SIMPLE_LEGAL_STATUS: {
    key: 'simpleLegalStatusItems',
    dataType: 'tag',
  },
  APD_YEARMONTHDAY: {
    key: 'applicationDateItems',
    dataType: 'date',
  },
  PBDT_YEARMONTHDAY: {
    key: 'publicationDateItems',
    dataType: 'date',
  },
  EXDT_TS: {
    key: 'estimatedExpiryDateItems',
    dataType: 'date',
  },
  PHS_CORE_TYPE_ID: {
    key: 'drugPatentTypeItems',
    dataType: 'tag',
  },
  PHS_CORE_DRUG_SOURCE: {
    key: 'coreDrugSourceItems',
    dataType: 'tag',
  },
  PN: {
    key: 'patentNumberItems',
    dataType: 'tag',
  },
  PATENT_TECHNOLOGY: {
    key: 'patentTechnologyItems',
    dataType: 'tag',
  },
  IN: {
    key: 'inventorItems',
    dataType: 'tag',
  },
}

export const literatureReverseMap: Partial<Record<ILiteratureIndexField, IReverseConfig>> = {
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  ALL_FIELDS_TL: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  PHS_DRUG_ID: {
    key: 'drugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  PHS_ORG_ID_ONEID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  PHS_TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  PHS_DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  PHS_DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  JOURNAL: {
    key: 'journalItems',
    dataType: 'input',
  },
  AUTHOR: {
    key: 'authorItems',
    dataType: 'input',
  },
  YEAR: {
    key: 'publicationYearRange',
    dataType: 'date',
  },
}

export const drugDealReverseMap: Partial<Record<IDrugDealIndexField, IReverseConfig>> = {
  DRUG_ID: {
    key: 'drugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  DRUG_TYPE_FLATTEN: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  PRINCIPLE_ORG_ID: {
    key: 'transferorItems',
    dataType: 'input',
    icon: 'Company',
  },
  PARTNER_ORG_ID: {
    key: 'acquirerItems',
    dataType: 'input',
    icon: 'Company',
  },
  TARGET_ID_FLATTEN: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  MECHANISM_ACTION_ID: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  MECHANISM_ACTION_ID_FLATTEN: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  DISEASE_ID: {
    key: 'drugIndicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  DISEASE_ID_FLATTEN: {
    key: 'drugIndicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  DEAL_DISEASE_ID: {
    key: 'dealIndicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  NORMALIZED_PHASE_SIGN_ID: {
    key: 'highestPhaseItems',
    dataType: 'tag',
  },
  NORMALIZED_PHASE_SIGN_ID_FLATTEN: {
    key: 'highestPhaseItems',
    dataType: 'tag',
  },
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  COUNTRY: {
    key: 'dealCountryItems',
    dataType: 'tag',
  },
  DEAL_TYPE_NOR: {
    key: 'agreementTypeItems',
    dataType: 'tag',
  },
  AMOUNT_DISCLOSURE: {
    key: 'amountDisclosureItems',
    dataType: 'tag',
  },
  TOTAL_VALUE: {
    key: 'totalPaymentItems',
    dataType: 'numberRange',
  },
  UPFRONT_PAYMENT: {
    key: 'upfrontPaymentItems',
    dataType: 'numberRange',
  },
  MILESTONE_PAYMENT: {
    key: 'milestoneItems',
    dataType: 'numberRange',
  },
  DEAL_TIME: {
    key: 'dealDateItems',
    dataType: 'date',
  },
  CROSS_BORDER: {
    key: 'dealDirectionItems',
    dataType: 'tag',
  },
  PARTNER_ORG_ATTR: {
    key: 'dealPartnerPropertyItems',
    dataType: 'tag',
  },
  PRINCIPLE_ORG_ATTR: {
    key: 'dealPrinciplePropertyItems',
    dataType: 'tag',
  },
}

export const transMedicineReverseMap: Partial<Record<ITranslationalMedicineIndexField, IReverseConfig>> = {
  DRUG_ID: {
    key: 'translationalMedicineDrugItems',
    dataType: 'input',
    icon: 'Drug',
  },
  TARGET_ID: {
    key: 'targetItems',
    dataType: 'input',
    icon: 'Target',
  },
  DISEASE_ID: {
    key: 'indicationItems',
    dataType: 'input',
    icon: 'Disease',
  },
  DRUG_TYPE: {
    key: 'drugTypeItems',
    dataType: 'input',
    icon: 'DrugType',
  },
  MECHANISM_ACTION_ID: {
    key: 'mechanismItems',
    dataType: 'input',
    icon: 'Mechanism',
  },
  RESEARCH_SPONSOR_ID: {
    key: 'organizationItems',
    dataType: 'input',
    icon: 'Company',
  },
  ALL_FIELDS: {
    key: 'allFieldItems',
    dataType: 'input',
  },
  SUBJECT_ID: {
    key: 'subjectItems',
    dataType: 'tag',
  },
  TRANSLATION_STAGE_ID: {
    key: 'translationStageItems',
    dataType: 'tag',
  },
  PUB_DT: {
    key: 'publicationDateItems',
    dataType: 'date',
  },
  JOURNAL_ID: {
    key: 'journalItems',
    dataType: 'tag',
  },
  JOURNAL_ANCESTOR_ID: {
    key: 'journalItems',
    dataType: 'tag',
  },
  MEETINGS: {
    key: 'meetingsItems',
    dataType: 'tag',
  },
  MEETINGS_ANCESTOR_ID: {
    key: 'meetingsItems',
    dataType: 'tag',
  },
}

export const convertDrugQueryConfig: Partial<Record<DrugFinderKey, IConvertQueryConfigItem<IDrugDevStatusIndexField, DrugFinderFieldType>>> = {
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
  },
  drugItems: {
    type: 'drug',
    fields: ['DRUG_ID'],
  },
  organizationItems: {
    type: 'organization',
    fields: ['ORGANIZATION_ID_ONEID'],
  },
  targetItems: {
    type: 'target',
    fields: ['TARGET_ID'],
  },
  startupItems: {
    type: 'text',
    fields: ['IS_STARTUPS'],
  },
  mechanismItems: {
    type: 'text',
    fields: ['MECHANISM_ACTION_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['DISEASE_ID'],
  },
  approvedDateRange: {
    type: 'dateRange',
    fields: ['DEV_STATUS_TIME'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['DRUG_TYPE'],
  },
  atcItems: {
    type: 'atc',
    fields: ['ATC_CODE'],
  },
  highestPhaseItems: {
    type: 'text',
    fields: ['GLOBAL_HIGHEST_DEV_STATUS'],
  },
  phaseItems: {
    type: 'text',
    fields: ['DEV_STATUS'],
  },
  approvedCountryItems: {
    type: 'text',
    fields: ['COUNTRY_COLLAPSED'],
  },
  organizationTypeItems: {
    type: 'text',
    fields: ['ORG_TYPE_ONEID'],
  },
  employeeNumberItems: {
    type: 'text',
    fields: ['ORG_EMP_NUMBER_GENERAL_ONEID'],
  },
  therapeuticAreasItems: {
    type: 'text',
    fields: ['THERAPEUTIC_AREA'],
  },
  regulationItems: {
    type: 'text',
    fields: ['SPECIAL_REVIEW_COUNTRY_BINARY_TUPLES'],
  },
  adcAntibodyItems: {
    type: 'text',
    fields: ['ANTIBODY_DRUG_ID'],
  },
  adcLinkerItems: {
    type: 'text',
    fields: ['LINKER_ID'],
  },
  adcPayloadItems: {
    type: 'text',
    fields: ['PAYLOAD_ID'],
  },
  dealExistItems: {
    type: 'text',
    fields: ['EXIST_DEAL'],
  },
}

export const convertClinicalQueryConfig: Partial<Record<ClinicalFinderKey, IConvertQueryConfigItem<IClinicalIndexField, ClinicalFieldType>>> = {
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
    translationFields: ['ALL_FIELDS', 'ALL_FIELDS_TL'],
  },
  clinicalDrugItems: {
    type: 'drug',
    fields: ['MAIN_EXPERIMENTATION_DRUG_ID'],
  },
  controlDrugItems: {
    type: 'drug',
    fields: ['CONTROL_DRUG_ID'],
  },
  mechanismItems: {
    type: 'text',
    fields: ['MECHANISM_ACTION_ID'],
  },
  organizationItems: {
    type: 'organization',
    fields: ['REGISTRY_ID_ONEID'],
  },
  targetItems: {
    type: 'target',
    fields: ['TARGET_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['CONDITION_DISEASE_ID'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['DRUG_TYPE'],
  },
  nctItems: {
    type: 'text',
    fields: ['REGISTER_NUMBER', 'SECONDARY_ID', 'GROUP_ID', 'RELATED_ID', 'SECONDARY_ID_VALUE'],
  },
  studyCodeItems: {
    type: 'text',
    fields: ['STUDY_IDENTIFIER_SUGG'],
  },
  clinicalTrialItems: {
    type: 'text',
    fields: ['BRIEF_TITLE', 'BRIEF_TITLE_CN', 'OFFICIAL_TITLE', 'OFFICIAL_TITLE_CN', 'BRIEF_TITLE_WITH_ACRONYM', 'ACRONYM'],
  },
  recruitmentStatusItems: {
    type: 'text',
    fields: ['RECRUITMENT_STATUS_NAME_NOR_V2'],
  },
  studyPhaseItems: {
    type: 'text',
    fields: ['NORMALIZED_PHASE'],
  },
  selectedDateRangeItems: {
    type: 'dateRange',
    fields: ['START_DATE'],
  },
  selectedFirstPostDateRangeItems: {
    type: 'dateRange',
    fields: ['STUDY_FIRST_POSTED_DATE'],
  },
  estimatedEnrollmentItems: {
    type: 'numberRange',
    fields: ['ENROLLMENT'],
  },
  organizationTypeItems: {
    type: 'text',
    fields: ['SPONSOR_TYPE_LEADER_ONEID', 'SPONSOR_TYPE_COLLABORATOR_ONEID'],
  },
  employeeNumberItems: {
    type: 'text',
    fields: ['SPONSOR_EMP_NUM_LEADER_GENERAL_ONEID', 'SPONSOR_EMP_NUM_COLLABORATOR_GENERAL_ONEID'],
  },
  sponsorCountryItems: {
    type: 'text',
    fields: ['COUNTRY_COLLAPSED'],
  },
  therapeuticAreasItems: {
    type: 'text',
    fields: ['THERAPEUTIC_AREA'],
  },
}

export const convertClinicalResultQueryConfig: Partial<
  Record<IClinicalResultFinderKey, IConvertQueryConfigItem<IClinicalResultIndexField, ClinicalResultFieldType>>
> = {
  experimentalDrugItems: {
    type: 'drug',
    fields: ['MAIN_EXPERIMENTATION_DRUG_ID', 'MAIN_EVALUATE_DRUG_ID'],
    asMainDrugFields: ['MAIN_EVALUATE_DRUG_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['CONDITION_DISEASE_ID'],
  },
  targetItems: {
    type: 'target',
    fields: ['TARGET_ID'],
  },
  sponsorItems: {
    type: 'organization',
    fields: ['SPONSOR_COLLABORATOR_ID_ONEID'],
  },
  studyCodeItems: {
    type: 'text',
    fields: ['STUDY_CODE'],
  },
  trialNumberItems: {
    type: 'text',
    fields: ['REGISTER_NUMBER_EXTEND'],
  },
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
    translationFields: ['ALL_FIELDS', 'ALL_FIELDS_TL'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['DRUG_TYPE'],
  },
  controlDrugItems: {
    type: 'drug',
    fields: ['CONTROL_DRUG_ID'],
  },
  therapeuticAreasItems: {
    type: 'text',
    fields: ['THERAPEUTIC_AREA'],
  },
  mechanismItems: {
    type: 'text',
    fields: ['MECHANISM_ACTION_ID'],
  },
  meetingsItems: {
    type: 'meetings',
    fields: ['SOURCE_INFO_NOR_ID', 'SOURCE_INFO_NOR_ANCESTOR_ID'],
  },
  outcomeItems: {
    type: 'outcome',
    fields: [],
  },
  therapyTypeItems: {
    type: 'text',
    fields: ['THERAPY_TYPE_NOR_ID'],
  },
  studyPhaseItems: {
    type: 'text',
    fields: ['NORMALIZED_PHASE'],
  },
  publishedDateRange: {
    type: 'dateRange',
    fields: ['PUBLISHED_TIME'],
  },
  evaluationItems: {
    type: 'text',
    fields: ['GENERAL_EVALUATION_NOR_ID'],
  },
  analyzedParticipantsItems: {
    type: 'numberRange',
    fields: ['OVERALL_ENROLLMENT'],
  },
}

export const convertPatentQueryConfig: Partial<Record<PatentFinderKey, IConvertQueryConfigItem<IPatentIndexField, PatentFieldType>>> = {
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
  },
  drugItems: {
    type: 'drug',
    fields: ['PHS_CORE_DRUG_ID'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['PHS_DRUG_TYPE_ID'],
  },
  organizationItems: {
    type: 'organization',
    fields: [],
  },
  targetItems: {
    type: 'target',
    fields: ['PHS_TARGET_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['PHS_DISEASE_ID'],
  },
  assigneeTypeItems: {
    type: 'text',
    fields: ['ANCS_TYPE', 'ANS_TYPE'],
  },
  authorityCountryItems: {
    type: 'text',
    fields: ['COUNTRY'],
  },
  simpleLegalStatusItems: {
    type: 'text',
    fields: ['SIMPLE_LEGAL_STATUS'],
  },
  applicationDateItems: {
    type: 'dateRange',
    fields: ['APD_YEARMONTHDAY'],
  },
  publicationDateItems: {
    type: 'dateRange',
    fields: ['PBDT_YEARMONTHDAY'],
  },
  estimatedExpiryDateItems: {
    type: 'dateRange',
    fields: ['EXDT_TS'],
  },
  drugPatentTypeItems: {
    type: 'text',
    fields: ['PHS_CORE_TYPE_ID'],
  },
  patentTechnologyItems: {
    type: 'text',
    fields: ['PATENT_TECHNOLOGY'],
  },
  patentNumberItems: {
    type: 'text',
    fields: ['PN', 'APNO'],
  },
  coreDrugSourceItems: {
    type: 'text',
    fields: ['PHS_CORE_DRUG_SOURCE'],
  },
  inventorItems: {
    type: 'text',
    fields: ['IN'],
  },
}

export const convertLiteratureQueryConfig: Partial<Record<LiteratureFinderKey, IConvertQueryConfigItem<ILiteratureIndexField, FinderFieldType>>> = {
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
    translationFields: ['ALL_FIELDS', 'ALL_FIELDS_TL'],
  },
  drugItems: {
    type: 'drug',
    fields: ['PHS_DRUG_ID'],
  },
  organizationItems: {
    type: 'organization',
    fields: ['PHS_ORG_ID_ONEID'],
  },
  targetItems: {
    type: 'target',
    fields: ['PHS_TARGET_ID'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['PHS_DRUG_TYPE'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['PHS_DISEASE_ID'],
  },
  authorItems: {
    type: 'text',
    fields: ['AUTHOR'],
  },
  journalItems: {
    type: 'text',
    fields: ['JOURNAL'],
  },
  publicationYearRange: {
    type: 'dateRange',
    fields: ['YEAR'],
  },
}

export const convertDrugDealQueryConfig: Partial<Record<IDrugDealFinderKey, IConvertQueryConfigItem<IDrugDealIndexField, DrugDealFieldType>>> = {
  drugItems: {
    type: 'drug',
    fields: ['DRUG_ID'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['DRUG_TYPE'],
  },
  transferorItems: {
    type: 'organization',
    fields: ['PRINCIPLE_ORG_ID'],
  },
  acquirerItems: {
    type: 'organization',
    fields: ['PARTNER_ORG_ID'],
  },
  targetItems: {
    type: 'target',
    fields: ['TARGET_ID_FLATTEN'],
  },
  drugIndicationItems: {
    type: 'disease',
    fields: ['DISEASE_ID'],
  },
  dealIndicationItems: {
    type: 'disease',
    fields: ['DEAL_DISEASE_ID'],
  },
  mechanismItems: {
    type: 'text',
    fields: ['MECHANISM_ACTION_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['DISEASE_ID'],
  },
  highestPhaseItems: {
    type: 'text',
    fields: ['NORMALIZED_PHASE_SIGN_ID'],
  },
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
  },
  dealCountryItems: {
    type: 'text',
    fields: ['COUNTRY'],
  },
  agreementTypeItems: {
    type: 'text',
    fields: ['DEAL_TYPE_NOR'],
  },
  amountDisclosureItems: {
    type: 'text',
    fields: ['AMOUNT_DISCLOSURE'],
  },
  totalPaymentItems: {
    type: 'numberRange',
    fields: ['TOTAL_VALUE'],
  },
  upfrontPaymentItems: {
    type: 'numberRange',
    fields: ['UPFRONT_PAYMENT'],
  },
  milestoneItems: {
    type: 'numberRange',
    fields: ['MILESTONE_PAYMENT'],
  },
  dealDateItems: {
    type: 'dateRange',
    fields: ['DEAL_TIME'],
  },
  dealDirectionItems: {
    type: 'text',
    fields: ['CROSS_BORDER'],
  },
  dealPrinciplePropertyItems: {
    type: 'text',
    fields: ['PRINCIPLE_ORG_ATTR'],
  },
  dealPartnerPropertyItems: {
    type: 'text',
    fields: ['PARTNER_ORG_ATTR'],
  },
}

export const convertTransMedicineQueryConfig: Partial<
  Record<ITranslationalMedicineFinderKey, IConvertQueryConfigItem<ITranslationalMedicineIndexField, TranslationalMedicineFieldType>>
> = {
  translationalMedicineDrugItems: {
    type: 'drug',
    fields: ['DRUG_ID'],
  },
  targetItems: {
    type: 'target',
    fields: ['TARGET_ID'],
  },
  indicationItems: {
    type: 'disease',
    fields: ['DISEASE_ID'],
  },
  drugTypeItems: {
    type: 'text',
    fields: ['DRUG_TYPE'],
  },
  mechanismItems: {
    type: 'text',
    fields: ['MECHANISM_ACTION_ID'],
  },
  organizationItems: {
    type: 'organization',
    fields: ['RESEARCH_SPONSOR_ID'],
  },
  allFieldItems: {
    type: 'text',
    fields: ['ALL_FIELDS'],
    translationFields: ['ALL_FIELDS', 'ALL_FIELDS_TL'],
  },
  subjectItems: {
    type: 'text',
    fields: ['SUBJECT_ID'],
  },
  translationStageItems: {
    type: 'text',
    fields: ['TRANSLATION_STAGE_ID'],
  },
  publicationDateItems: {
    type: 'dateRange',
    fields: ['PUB_DT'],
  },
  journalItems: {
    type: 'text',
    fields: ['JOURNAL_ID', 'JOURNAL_ANCESTOR_ID'],
  },
  meetingsItems: {
    type: 'meetings',
    fields: ['MEETINGS', 'MEETINGS_ANCESTOR_ID'],
  },
}

/**
 * loadQuery的时候需要从field反推出datatype
 * @param fields
 * @returns
 */
export const getDataTypeByFields = (fields: string[]): IHotTagType | undefined => {
  if (fields.includes('RESEARCH_ORG_ID_ONEID') || fields.includes('ORIGINAL_DEV_ORG_ID_ONEID')) {
    return 'ORG_MASTER_ENTITY'
  }
  if (fields.includes('TARGET_ID')) {
    return 'TARGET'
  }
  if (fields.includes('MECHANISM_ACTION_ID')) {
    return 'DRUG_MECHANISM_ACTION'
  }
  if (fields.includes('RES_AND_NON_RES_DISEASE_ID') || fields.includes('CONDITION_DISEASE_ID')) {
    return 'DISEASE'
  }
  if (fields.includes('DRUG_TYPE')) {
    return 'DRUG_TYPE'
  }
  if (fields.includes('SPECIAL_REVIEW')) {
    return 'SPECIAL_REVIEW'
  }
  return undefined
}

/**
 * 实体entity转query
 * @param items
 * @param type
 * @returns
 */
export const convert2EntityQueryItem = (items: IBAcTag[], type: IHomeQueryValueEntityType): IQueryValue[] => {
  return items.map((o) => {
    return {
      type,
      id: o.id,
      display_name_en: o.name_en ?? '',
      display_name_cn: o.name_cn ?? '',
      search_strategy: o.search_strategy ?? 'ID',
    }
  })
}

/**
 * 转换patent的申请（专利权）人
 * @param items
 * @param type
 * @returns
 */
export function convertPatentOrg2QueryItem(items: IBAcTag[], noTransSearchStrategy = false, includeOriginal = true): IQueryValue[] {
  return items.map((o) => {
    const display_name_cn = o.name_cn ?? ''
    const display_name_en = o.name_en
    let searchStrategy: ISearchStrategy = 'ID'
    if (o.id) {
      searchStrategy = o.search_strategy || 'ID'
      if (includeOriginal) {
        if (searchStrategy === 'ANS_ID_ROLLUP') {
          searchStrategy = 'ID_ROLLUP'
        }
        if (searchStrategy === 'ANS_ID') {
          searchStrategy = 'ID'
        }
      } else {
        if (searchStrategy === 'ID_ROLLUP') {
          searchStrategy = 'ANS_ID_ROLLUP'
        }
        if (searchStrategy === 'ID') {
          searchStrategy = 'ANS_ID'
        }
      }
    } else {
      if (includeOriginal) {
        searchStrategy = 'KEYWORD'
      } else {
        searchStrategy = 'ANS_KEYWORD'
      }
    }
    return {
      type: 'organization',
      id: o.id || '',
      display_name_en,
      display_name_cn,
      search_strategy: noTransSearchStrategy ? o.search_strategy : searchStrategy,
    }
  })
}

/**
 * 转换临床结果指标(outcome)query
 * @param items
 * @param type
 * @returns
 */
export function convertClinicalResultOutcome2QueryItem(items: IBAcTag[], isPrimaryEndpoint = true): IQueryValue[] {
  return items.map((o) => {
    const display_name_cn = o.name_cn ?? ''
    const display_name_en = o.name_en
    let searchStrategy: ISearchStrategy = 'ID'
    if (o.id) {
      searchStrategy = isPrimaryEndpoint ? 'ID_EXTEND' : 'ID'
    } else {
      searchStrategy = isPrimaryEndpoint ? 'KEYWORD_EXTEND' : 'KEYWORD'
    }
    return {
      type: 'CommonEntity',
      id: o.id || '',
      display_name_en,
      display_name_cn,
      search_strategy: searchStrategy,
    }
  })
}

function flatGroupTag(items: IBAcTag[]) {
  return items.reduce((acc, item) => {
    if (item.child) {
      acc.push(...flatGroupTag(item.child))
    } else {
      acc.push(item)
    }
    return acc
  }, [] as IBAcTag[])
}
/**
 * text转query
 * @param items
 * @param locale
 * @returns
 */
export const convert2TextQueryItem = (items: IBAcTag[], locale: I18nLang, useEnName = false): IQueryValue[] => {
  return flatGroupTag(items).map((item) => {
    const display_name_cn = useEnName ? item.name_en : item.name_cn || ''
    const display_name_en = item.name_en
    let value = locale === 'cn' ? display_name_cn : display_name_en
    if (item.id) {
      value = item.id
    }
    return {
      type: 'text',
      value,
      display_name_cn,
      display_name_en,
      search_strategy: item.search_strategy ?? 'ID',
    }
  })
}

/**
 * atc转query
 * @param items
 * @returns
 */
export const convert2TextQueryItemForATC = (items: IBAcTag[]): IQueryValue[] => {
  return items.map((item) => {
    const display_name_cn = item.atc_code ?? ''
    const display_name_en = item.atc_code ?? ''
    const value = item.id
    return {
      type: 'text',
      value,
      display_name_cn,
      display_name_en,
      search_strategy: item.search_strategy ?? 'ID',
    }
  })
}

/**
 * date range转query
 * @param item
 * @returns
 */
export const convert2RangeQueryItem = (item: string[], type: 'ms' | 'YYYYMMDD' | 'yyyy' = 'ms'): IQueryValue[] => {
  const start = item[0]?.replaceAll('-', '/')
  const end = item[1]?.replaceAll('-', '/')
  if (type === 'YYYYMMDD') {
    return [
      {
        type: 'range',
        from: Number(item[0]?.replaceAll('-', '')),
        to: Number(item[1]?.replaceAll('-', '')),
        key: '',
        display_name_en: `${start}-${end}`,
        display_name_cn: `${start}-${end}`,
        include_upper: true,
        include_lower: true,
      },
    ]
  }
  if (type === 'yyyy') {
    let displayName = ''
    if (start && end) {
      displayName = `${start}-${end}`
    } else if (start) {
      displayName = `${start}-`
    } else if (end) {
      displayName = `-${end}`
    }
    return [
      {
        type: 'range',
        from: start ? Number(start) : undefined,
        to: end ? Number(end) : undefined,
        key: '',
        display_name_en: displayName,
        display_name_cn: displayName,
        include_upper: true,
        include_lower: true,
      },
    ]
  }
  return [
    {
      type: 'range',
      from: transformDateString2timestamp(item[0], false, 'day', true),
      to: transformDateString2timestamp(item[1], true, 'day', true),
      key: '',
      display_name_en: `${start}-${end}`,
      display_name_cn: `${start}-${end}`,
      include_upper: false,
      include_lower: true,
    },
  ]
}

/**
 * 两个值区间的转query
 * @param items
 * @returns
 */
export const convert2NumberRangeQueryItem = (items: IBAcTag[]): IQueryValue[] => {
  return items.map((item) => {
    const d = item.id.split('-')
    return {
      type: 'range',
      from: d[0] !== '' ? Number(d[0]) : undefined,
      to: d[1] !== '' ? Number(d[1]) : undefined,
      display_name_en: item.name_en,
      display_name_cn: item.name_cn ?? item.name_en,
    }
  })
}

/**
 * 因为不能把值直接放在tag上，否则转换回来的时候会有两个tag高亮，正常应该只高亮customTag
 * 所以普通的几个within的tag的id不会直接在构造的时候就存储时间range
 * 在select的时候再去dayjs通过tag上的id格式(${num}-${unit})转化成range query
 * @param items
 * @returns
 */
export const convertDateRange2NumberRangeQueryItem = (
  items: IBAcTag[],
  type?: 'YYYYMMDD' | 'YYYY',
  isUtc = false,
  config?: {
    include_lower: boolean
    include_upper: boolean
  }
): IQueryValue[] => {
  const _dayjs = isUtc ? dayjs.utc : dayjs
  return items.map((item) => {
    // 使用最后一个'-'分割，因为初始日期1970/01/01，非UTC时间，时间戳是负数，也带有'-'
    const splitIndex = item.id.lastIndexOf('-')
    const d = [item.id.substring(0, splitIndex), item.id.substring(splitIndex + 1)]

    const rangeConfig = config || {}
    if (item.customDate) {
      const from =
        item.calcType === 'after'
          ? Number(`${_dayjs().endOf('day').valueOf()}`)
          : Number(`${_dayjs().subtract(Number(d[0]), d[1]).startOf('day').valueOf()}`)
      const to =
        item.calcType === 'after'
          ? Number(`${_dayjs().add(Number(d[0]), d[1]).startOf('day').valueOf()}`)
          : Number(`${_dayjs().endOf('day').valueOf()}`)

      if (type) {
        return {
          type: 'range',
          from: Number(_dayjs(from).format(type)) || undefined,
          to: Number(_dayjs(to).format(type)) || undefined,
          display_name_en: item.name_en,
          display_name_cn: item.name_cn ?? item.name_en,
          ...rangeConfig,
        }
      }
      return {
        type: 'range',
        from: from || undefined,
        to: to || undefined,
        display_name_en: item.name_en,
        display_name_cn: item.name_cn ?? item.name_en,
        ...rangeConfig,
      }
    } else {
      if (type) {
        return {
          type: 'range',
          from: d[0] !== '' ? Number(_dayjs(Number(d[0])).format(type)) || undefined : undefined,
          to: d[1] !== '' ? Number(_dayjs(Number(d[1])).format(type)) || undefined : undefined,
          display_name_en: item.name_en,
          display_name_cn: item.name_cn ?? item.name_en,
          ...rangeConfig,
        }
      }
      return {
        type: 'range',
        from: d[0] !== '' ? Number(d[0]) : undefined,
        to: d[1] !== '' ? Number(d[1]) : undefined,
        display_name_en: item.name_en,
        display_name_cn: item.name_cn ?? item.name_en,
        ...rangeConfig,
      }
    }
  })
}

export const isNotEmpty = (items: IBAcTag[] | string[]) => {
  return (
    items.length &&
    !items.some((o) => {
      if (typeof o !== 'string') {
        return o.id === 'All'
      }
      return false
    })
  )
}

export const generateHardData = (key: DataTagKey): IListItem[] => {
  return (tagData[key] ?? []).map((o) => {
    return {
      ...o,
      name_en: o.name_en,
      name_cn: o.name_cn,
      id: o.value,
      type: key,
    }
  })
}

export const generateNumberRangeHardData = (key: ICustomNumberRangeKey): IBAcTag[] => {
  const valTags: IBAcTag[] = CustomNumberRageConfig[key].map((item) => {
    return {
      name_en: item.name_en,
      name_cn: item.name_cn,
      from: item.from,
      to: item.to,
      id: `${item.from}-${isUndefined(item.to) ? '' : item.to}`,
      customDate: true,
    }
  })
  valTags.unshift({
    name_en: 'All',
    id: 'All',
    name_cn: 'All',
  })
  valTags.push({
    name_en: 'Custom number range',
    id: '',
    name_cn: '自定义',
    flag: 'custom',
  })
  return valTags
}

/**
 * 中英文获取string的长度
 * @param s
 * @returns
 */
export const getStringLength = (s: string) => {
  let realLength = 0
  const len = s.length
  let charCode = -1
  for (let i = 0; i < len; i++) {
    charCode = s.charCodeAt(i)
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1
    } else {
      // 如果是中文则长度加2
      realLength += 2
    }
  }
  return realLength
}

/**
 * 接口返回数据转前端list数据结构
 * @param params
 * @returns
 */
export const getAutoCompleteList = async (params: IAutoCompleteParams): Promise<IHttpWrap<IAutoCompleteRes>> => {
  const { key, keywords, limit, data_type, entity_type } = params
  const res = await sharedCtx.service[type2ServiceKeyMap[key]]?.getAutocomplete<IAutoCompleteRes>({
    key,
    keywords,
    limit,
    dicType: dicCodeMap[key],
    cancelToken: params.cancelToken,
    type: params.type,
    data_type,
    entity_type,
  })
  return res
}

/**
 * 判断search_strategy
 * @param item
 * @returns
 */
export const getSearchStrategy = (item: IListItem): ISearchStrategy => {
  if (item.search_strategy) {
    return item.search_strategy
  }
  if (item.icon === 'Tree' || item.is_leaf === false) {
    return 'ID_ROLLUP'
  }
  return 'ID'
}

/**
 * 判断icon是Tree 还是本身
 * @param item
 * @returns
 */
export const getIcon = (item: IListItem) => {
  if (item.is_leaf === false || item.search_strategy === 'ID_ROLLUP') {
    return 'Tree'
  }
  return item.icon
}

/**
 * query input数据转成tag
 * @param oldItem
 * @param icon
 * @returns
 */
export function generateInputItem(oldItem: IQueryItemField, icon = '') {
  const items = oldItem.value
  const field = oldItem.fields
  const isATC = field.find((o) => o === 'ATC_CODE')
  const res: IListItem[] = []
  for (const item of items) {
    let id = ''
    if ('value' in item) {
      id = item.value as string
    }
    if ('id' in item) {
      id = item.id
    }
    if (id) {
      const mockItem: IListItem = {
        ...item,
        name_cn: item.display_name_cn || item.display_name_en,
        name_en: item.display_name_en,
        id,
        icon,
      }
      if (isATC) {
        mockItem.atc_code = item.display_name_en
      }
      if ('search_strategy' in item) {
        mockItem.icon = item.search_strategy?.toUpperCase() === 'ID_ROLLUP' || item.search_strategy?.toUpperCase() === 'ANS_ID_ROLLUP' ? 'Tree' : icon
        if (item.search_strategy === 'KEYWORD' || item.search_strategy === 'ANS_KEYWORD') {
          mockItem.icon = ''
        }
      }
      res.push(mockItem)
    }
  }
  return res
}

export function generatePatentAssigneeItem(oldItem: IQueryItemField | IQueryItemEntity, icon = '') {
  const items = oldItem.value
  const res: IListItem[] = []
  for (const item of items) {
    const id = getQueryValueEntityId(item)

    const mockItem: IListItem = {
      ...item,
      name_cn: item.display_name_cn || item.display_name_en,
      name_en: item.display_name_en,
      id,
      icon,
    }
    if ('search_strategy' in item) {
      mockItem.icon = item.search_strategy?.toUpperCase() === 'ID_ROLLUP' || item.search_strategy?.toUpperCase() === 'ANS_ID_ROLLUP' ? 'Tree' : icon
      if (item.search_strategy === 'KEYWORD' || item.search_strategy === 'ANS_KEYWORD') {
        mockItem.icon = ''
      }
    }
    res.push(mockItem)
  }
  return res
}

export function generateOutcomeItem(oldItem: IQueryItemCustomEntity) {
  const items = oldItem.value
  const res: IListItem[] = []
  for (const item of items) {
    const id = getQueryValueEntityId(item)
    const mockItem: IListItem = {
      ...item,
      name_cn: item.display_name_cn || item.display_name_en,
      name_en: item.display_name_en,
      id,
    }
    res.push(mockItem)
  }
  return res
}

/**
 * query tag数据转tag
 * @param oldItem
 * @returns
 */
export function generateTagItem(oldItem: IQueryItemField) {
  const items = oldItem.value
  const res: IListItem[] = []
  for (const item of items) {
    let id = ''
    if ('id' in item) {
      id = item.id
    }
    if ('value' in item) {
      id = item.value as string
    }
    res.push({
      name_cn: item.display_name_cn || item.display_name_en,
      name_en: item.display_name_en,
      id,
    })
  }
  return res
}

/**
 * query 时间range转化成finder数据
 * @param oldItem
 * @returns
 */
export function generateDateItem(oldItem: IQueryItemField) {
  return oldItem.value[0].display_name_en.split('-').map((o) => o.replaceAll('/', '-'))
}

/**
 * 后端返回的from、to可能是字符串的
 * [4,6,8]----> 2022、202210、20221010，这种用String去处理
 * 其他都认为是时间戳，转成数字处理
 * @param val
 * @returns
 */
export function time2Str(val?: string | number) {
  if (val && [4, 6, 8].includes(String(val).length)) {
    return dayjs(String(val))
  }
  return dayjs(Number(val))
}

/**
 * clinical把query里面的时间转换成customTag
 * @param oldItem
 * @returns
 */
export function convertCustomDate(oldItem: IQueryItemField, isUtc = false, format = 'YYYY/MM/DD') {
  const val = oldItem.value[0]
  if ('from' in val) {
    const { from, to } = val
    const start = time2Str(from)
    const end = time2Str(to)
    const utcTime = (time: dayjs.Dayjs) => (isUtc ? dayjs.utc(time) : time)
    const displayName = `${utcTime(start).format(format)}-${utcTime(end).format(format)}`
    const custom: IBAcTag = {
      name_en: displayName,
      id: `${start.valueOf()}-${end.valueOf()}`,
      name_cn: displayName,
      flag: 'custom',
    }
    return [custom]
  }
  return []
}

/**
 * query orgType转换成finder的tag 特殊处理
 * @param oldItem
 * @returns
 */
export function generateOrgTypeItem(oldItem: IQueryItemField) {
  const items = oldItem.value
  const res: IListItem[] = []
  const hasCompany = items.some((o) => (o as IQueryValueText).value === 'Company')
  const hasEducation = items.some((o) => (o as IQueryValueText).value === 'Education')
  const hasOther = items.some((o) => (o as IQueryValueText).value === 'Government' || (o as IQueryValueText).value === 'Nonprofit')
  if (hasCompany) {
    res.push(orgTypeConfig.Company)
  }
  if (hasEducation) {
    res.push(orgTypeConfig.Education)
  }
  if (hasOther) {
    res.push(orgTypeConfig.Other)
  }
  return res
}

export function generateNumberRangeItem(oldItem: IQueryItemField) {
  const items = oldItem.value
  const res: IListItem[] = []
  for (const item of items) {
    if ('from' in item || 'to' in item) {
      let itemVal = ''
      if (item.from === 0 || item.from) {
        itemVal = `${itemVal}${item.from}`
      }
      itemVal = `${itemVal}-`
      if (item.to === 0 || item.to) {
        itemVal = `${itemVal}${item.to}`
      }
      res.push({
        name_cn: item.display_name_cn || item.display_name_en,
        name_en: item.display_name_en,
        id: itemVal,
      })
    }
  }
  return res
}
