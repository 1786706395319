import Axios from 'axios'
import fileDownload from 'js-file-download'
export function openNewTab(url: string): void {
  const aLink = document.createElement('a')
  aLink.href = url
  aLink.target = '_blank'
  aLink.rel = 'opener'
  aLink.click()
}
/**
 * 下载一个文件
 * @param url 文件下载地址
 * @param filename 文件名
 */
export function downloadFileByUrl(url: string, filename: string) {
  return Axios.get(url, {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, filename)
  })
}

function getScrollTop(container?: HTMLElement) {
  let scrollTop = 0
  if (container) {
    scrollTop = container.scrollTop
  } else if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop
  } else if (document.body) {
    scrollTop = document.body.scrollTop
  }
  return scrollTop
}

export function scrollToViewCenter(el: HTMLElement, container?: HTMLElement) {
  const { top } = el.getBoundingClientRect()
  // 滚动区域的中心高度
  let center = 0
  if (container) {
    const { height: cHeight } = container.getBoundingClientRect()
    center = cHeight / 2
  } else {
    center = window.innerHeight / 2
  }
  const t = getScrollTop(container) - (center - top)
  scrollToTopDis(container || window, t)
}

export function scrollToTopDis(container: HTMLElement | typeof window, top = 0) {
  container.scrollTo({
    top,
    behavior: 'smooth',
  })
}
