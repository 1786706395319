import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { injectOrgName, replaceDisplayNameByShortName, transformGroupAggregationSingleResultByMap } from './base'

export const dashboardDimFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  TARGET_ID: (data) => replaceDisplayNameByShortName(data),
  phs_target_id: (data) => replaceDisplayNameByShortName(data),
  RESEARCH_ORG_ID_ROOT_ONEID: injectOrgName,
  RESEARCH_ORG_ID_ONEID: injectOrgName,
  REGISTRY_ID_ROOT_ONEID: injectOrgName,
  REGISTRY_ID_ONEID: injectOrgName,
  master_phs_organization_root_id: injectOrgName,
  master_phs_organization_id: injectOrgName,
  PHS_ORG_ROOT_ID_ONEID: injectOrgName,
  PHS_ORG_ID_ONEID: injectOrgName,
  ORGANIZATION_ID_ONEID: injectOrgName,
  ORG_ROOT_ID_ONEID: injectOrgName,
}

export function transformWidgetAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, dashboardDimFieldTransformFnMap, xCorrelationId)
}
