/* eslint-disable @typescript-eslint/no-unused-vars */
import { createNativeRange, highlightRange } from './selection-utils'
import { rgbaChangeAlpha, contrastColor, convertToRGBA } from '../colors'
import { LABEL_CLASS_NAME } from '@pharmsnap/shared/constants'
/**
 * 高亮一个标注
 * 此函数有副作用,会修改data的_dom,和_stylesheet属性
 * @param data 标注的数据
 * @param labels 标注的所有标签
 * @param rootRef 根元素
 */
export function highLightAnnotation(data: any, labels: readonly any[], rootRef: HTMLElement) {
  const range = createNativeRange(data.start_index, data.end_index, rootRef)
  const currentLabels = labels.find((_item) => _item.normalized_type === data.normalized_type)

  if (range && currentLabels) {
    const { dom, stylesheet } = applyHighlight({
      root: rootRef,
      range: range,
      label: currentLabels,
      unique_id: data.id,
      normalized_id: data.normalized_id,
      index: data._index,
      component: data.component,
    })
    data._dom = dom
    data._stylesheet = stylesheet
  }
}

/**
 * 高亮一段选取
 * @param root
 * @param range
 * @param label
 * @param unique_id
 * @returns
 */
export function applyHighlight(params: {
  root: HTMLElement
  range: Range
  label: any
  unique_id: string
  index: number
  normalized_id?: string
  component?: (content: string) => JSX.Element
}) {
  const { root, range, label, unique_id, normalized_id, index, component } = params
  const dom = highlightRange(range, {
    label: label.conf_ner_entity_name,
    label_id: label.normalized_type,
    unique_id,
    normalized_id,
    index,
    component,
    classNames: [LABEL_CLASS_NAME, `${LABEL_CLASS_NAME}-${label.normalized_type}`],
  })
  // 临时注释掉,后期可能需要要样式区别
  // const stylesheet = createSpanStylesheet(
  //   root.ownerDocument,
  //   label.normalized_type,
  //   convertToRGBA(label.color, 1),
  //   convertToRGBA(label.hoverColor, 1)
  // )
  return {
    dom,
    stylesheet: '',
  }
}

const stateClass = {
  active: '__active',
  highlighted: '__highlighted',
  collapsed: '__collapsed',
  hidden: '__hidden',
}
/**
 * Creates a separate stylesheet for every region
 * @param {string} identifier GUID identifier of a region
 * @param {string} color Default label color
 */
export function createSpanStylesheet(document: Document, identifier: string, color: string, hoverColor: string) {
  const className = `.${LABEL_CLASS_NAME}-${identifier}`
  const variables = {
    color: `--background-color-${identifier}`,
    hoverColor: `--backgroun-hover-color-${identifier}`,
    cursor: `--cursor-style-${identifier}`,
  }

  const classNames = {
    active: `${className}.${stateClass.active}`,
    highlighted: `${className}.${stateClass.highlighted}`,
  }

  const activeColorOpacity = 0.8
  const toActiveColor = (color: string) => rgbaChangeAlpha(color, activeColorOpacity)

  const initialActiveColor = toActiveColor(color)
  document.documentElement.style.setProperty(variables.color, color)
  document.documentElement.style.setProperty(variables.hoverColor, hoverColor)

  const rules = {
    [className]: `
      white-space: pre;
      background-color: var(${variables.color});
      cursor: var(${variables.cursor}, pointer);
      border: 1px dashed transparent;
    `,
    [`${className}:hover`]: `
      background-color: var(${variables.hoverColor});
    `,
    [classNames.active]: `
      color: ${contrastColor(initialActiveColor)};
      ${variables.color}: ${initialActiveColor}
    `,
    [classNames.highlighted]: `
      position: relative;
      border-color: rgb(0, 174, 255);
    `,
    [`${className}.${stateClass.hidden}`]: `
      border: none;
      background: none;
      padding: 0;
    `,
  }

  const styleTag = document.createElement('style')

  styleTag.type = 'text/css'
  styleTag.id = `highlight-${identifier}`
  document.head.appendChild(styleTag)

  const stylesheet = styleTag.sheet
  const supportInserion = !!stylesheet?.insertRule
  let lastRuleIndex = 0

  for (const ruleName in rules) {
    if (!Object.prototype.hasOwnProperty.call(rules, ruleName)) continue
    if (supportInserion) {
      stylesheet?.insertRule(`${ruleName} { ${rules[ruleName]} } `, lastRuleIndex++)
    } else {
      stylesheet?.addRule(ruleName, rules as any)
    }
  }

  /**
   * Set region color
   * @param {string} color
   */
  const setColor = (color: string) => {
    const newActiveColor = toActiveColor(color)
    const { style } = stylesheet?.rules[2] as any

    document.documentElement.style.setProperty(variables.color, color)

    style.backgroundColor = newActiveColor
    style.color = contrastColor(newActiveColor)
  }

  /**
   * Ser cursor style
   * @param {import("prettier").CursorOptions} cursor
   */
  const setCursor = (cursor: string) => {
    document.documentElement.style.setProperty(variables.cursor, cursor)
  }

  /**
   * Remove stylesheet
   */
  const remove = () => {
    styleTag.remove()
  }

  return {
    className: className.substr(1),
    state: stateClass,
    setColor,
    setCursor,
    remove,
  }
}
