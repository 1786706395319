import { ACTIVE_DEV_STATUS_IDS, ALL_DEV_STATUS_MAP, APPROVED_DEV_STATUS_ID, INACTIVE_DEV_STATUS_IDS } from '@patsnap/synapse_common_config'
import {
  E_QUERY_ITEM_CONDITION,
  IAggregationValue,
  IBaseDictItem,
  IBaseDiseaseInfo,
  IBaseDrugInfo,
  IBaseMechanismInfo,
  IBaseOrgInfo,
  IBaseSourceLangName,
  IBaseTargetInfo,
  IEntityType,
  IQuery,
  IQueryItemField,
  IQueryValue,
  IQueryValueText,
} from '@patsnap/synapse_common_interface'
import { AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { cloneDeep, flatten, isArray, isUndefined, omit, uniq } from 'lodash'
import { sharedCtx } from '../context'
import type { FilterByValueType, IBatchDrugInfo, INavListType, IUseBarCount, IUseChartTwoDimTupleItem } from '../types'
import { convertTimestamp2RangeQueryItem } from './common'
import { getSpecialLang } from './lang'
import { getOrganizationName } from './organization'
import { getOneYearRangeByYear, getYearRangeFromNow } from './time'

type INavListFieldRecord = Partial<Record<INavListType, string>>

export function validIsTargetRelatedField(field: string) {
  const fields = ['PHS_TARGET_ID', 'phs_target_id', 'TARGET_ID', 'TARGET_ID_EXTEND']
  return fields.includes(field)
}

export function validIsOrganizationRelatedField(field: string) {
  const fields = [
    'PHS_ORG_ID_ONEID',
    'master_phs_organization_id',
    'PHS_ORG_ID_ONEID',
    'REGISTRY_ID_ONEID',
    'ORGANIZATION_ID_ONEID',
    'PARTNER_ORG_ID',
    'PARTNER_ORG_ID_ROOT',
    'PRINCIPLE_ORG_ID',
    'PRINCIPLE_ORG_ID_ROOT',
  ]
  return fields.includes(field)
}

export function validIsDrugRelatedField(field: string) {
  const fields = ['PHS_DRUG_ID', 'phs_drug_id', 'PHS_CORE_DRUG_ID', 'MAIN_EXPERIMENTATION_DRUG_ID', 'DRUG_ID']
  return fields.includes(field)
}

export function validIsDiseaseRelatedField(field: string) {
  const fields = ['PHS_DISEASE_ID', 'phs_disease_id', 'CONDITION_DISEASE_ID', 'DISEASE_ID']
  return fields.includes(field)
}

export function validIsDrugTypeRelatedField(field: string) {
  const fields = ['PHS_DRUG_TYPE', 'DRUG_TYPE']
  return fields.includes(field)
}

export function transTextQueryField2EntityQueryField(queryField: IQueryItemField): IQueryItemField {
  const fields = queryField.fields
  if (!fields) return queryField
  if (fields.length > 1) return queryField
  let entityType: IEntityType | 'drugType' | null = null
  const validFunTypeTupleAry: Array<[typeof validIsDiseaseRelatedField, IEntityType | 'drugType']> = [
    [validIsDiseaseRelatedField, 'disease'],
    [validIsDrugRelatedField, 'drug'],
    [validIsDrugTypeRelatedField, 'drugType'],
    [validIsOrganizationRelatedField, 'organization'],
    [validIsTargetRelatedField, 'target'],
  ]

  const foundTuple = validFunTypeTupleAry.find((t) => t[0](fields[0]))

  if (foundTuple) {
    entityType = foundTuple[1]
  }

  if (!entityType) return queryField

  const entityQueryItemField: IQueryItemField = {
    ...omit(queryField, 'value'),
    value: queryField.value.map((item) => {
      if (item.type === 'text') {
        const { value, ...rest } = item
        return {
          type: entityType as IEntityType | 'drugType',
          id: value,
          ...omit(rest, 'type'),
        }
      }
      return item
    }),
  }

  return entityQueryItemField
}

export function transSingleBar2QueryItem(barItem: IUseBarCount): IQueryItemField {
  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [barItem.aggregation_field],
    value: barItem.data.map((item) => ({
      display_name_cn: item.display_name_cn || '',
      display_name_en: item.display_name_en || '',
      type: 'text',
      value: item.key,
    })),
  }

  return transTextQueryField2EntityQueryField(queryItem)
}

export function transBatchDrugList2QueryItem(list: IBaseDrugInfo[], field: string | string[] = 'DRUG_ID', rollup = false): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    const cnDta = o.drug_name.find((drug) => drug.lang === 'CN')
    const enData = o.drug_name.find((drug) => drug.lang === 'EN')
    return {
      display_name_cn: cnDta?.name || '',
      display_name_en: enData?.name || '',
      type: 'drug',
      search_strategy: rollup ? 'ID_ROLLUP' : 'ID',
      id: o.drug_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: isArray(field) ? field : [field],
    value: data,
  }

  return queryItem
}

export function transBatchOrgList2QueryItem(list: IBaseOrgInfo[], field = 'ORGANIZATION_ID_ONEID', rollup = false, ans = false): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    return {
      display_name_cn: getOrganizationName(o, 'cn'),
      display_name_en: getOrganizationName(o, 'en'),
      type: 'organization',
      search_strategy: ans ? (rollup ? 'ANS_ID_ROLLUP' : 'ANS_ID') : rollup ? 'ID_ROLLUP' : 'ID',
      id: o.entity_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: data,
  }

  const entityQueryItem: IQueryItemField = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    type: 'organization',
    value: data,
  }

  return ans ? entityQueryItem : queryItem
}

export function transBatchDiseaseList2QueryItem(list: IBaseDiseaseInfo[], field = 'DISEASE_ID', rollup = false): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    return {
      display_name_cn: getSpecialLang({ data: o.disease_name || [], field: 'name', lang: 'CN', isDegraded: true }) || '',
      display_name_en: getSpecialLang({ data: o.disease_name || [], field: 'name', lang: 'EN', isDegraded: false }) || '',
      type: 'disease',
      search_strategy: rollup ? 'ID_ROLLUP' : 'ID',
      id: o.disease_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: data,
  }

  return queryItem
}

export function transBatchTargetList2QueryItem(
  list: IBaseTargetInfo[],
  field = 'TARGET_ID',
  rollup = false,
  condition = E_QUERY_ITEM_CONDITION.ANY
): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    return {
      display_name_cn: o.entity_name_cn || '',
      display_name_en: o.entity_name_en || '',
      type: 'target',
      search_strategy: rollup ? 'ID_ROLLUP' : 'ID',
      id: o.target_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    condition,
    fields: [field],
    value: data,
  }

  return queryItem
}

export function transBatchDrugTypeList2QueryItem(list: IBaseDictItem[], field = 'DRUG_TYPE', rollup = false): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    return {
      display_name_cn: o.name_cn || o.name_en || '',
      display_name_en: o.name_en || o.name_cn || '',
      search_strategy: rollup ? 'ID_ROLLUP' : 'ID',
      type: 'drugType',
      id: o.dict_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: data,
  }

  return queryItem
}

export function transBatchMechanismList2QueryItem(list: IBaseMechanismInfo[], field = 'MECHANISM_ACTION_ID', rollup = false): IQueryItemField {
  const data: IQueryValue[] = list.map((o) => {
    return {
      display_name_cn:
        getSpecialLang({ data: o.short_name || [], field: 'name', lang: 'CN', isDegraded: true }) ||
        getSpecialLang({ data: o.mechanism_name || [], field: 'name', lang: 'CN', isDegraded: true }) ||
        '',
      display_name_en:
        getSpecialLang({ data: o.short_name || [], field: 'name', lang: 'EN', isDegraded: false }) ||
        getSpecialLang({ data: o.mechanism_name || [], field: 'name', lang: 'EN', isDegraded: false }) ||
        '',
      type: 'text',
      search_strategy: rollup ? 'ID_ROLLUP' : 'ID',
      value: o.mechanism_id,
    }
  })

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: data,
  }

  return queryItem
}

export function transTwoDimTupleItems2QueryItem<T>(tuples: IUseChartTwoDimTupleItem<T>[]): {
  yAxisQuery?: IQueryItemField
  xAxisQuery?: IQueryItemField
} {
  const yDimItem = tuples[0][0]
  const xDimItems = tuples.map((i) => i[1])
  console.log(cloneDeep(xDimItems))
  const yQueryItemFiled: IQueryItemField | undefined = yDimItem
    ? transTextQueryField2EntityQueryField({
        type: 'field',
        fields: [yDimItem.field],
        value: [
          {
            display_name_cn: yDimItem.display_name_cn || '',
            display_name_en: yDimItem.display_name_en || '',
            type: 'text',
            value: yDimItem.id,
          },
        ],
      })
    : undefined
  const xQueryItemFiled: IQueryItemField = transTextQueryField2EntityQueryField(
    maybeTimeRelatedQueryField({
      type: 'field',
      fields: [xDimItems[0].field],
      value: xDimItems.map((i) => ({
        display_name_cn: i.display_name_cn || '',
        display_name_en: i.display_name_en || '',
        type: 'text',
        value: i.id,
      })),
    })
  )

  return {
    yAxisQuery: yQueryItemFiled,
    xAxisQuery: xQueryItemFiled,
  }
}

export function transAggregationValue2QueryItem(aggValue: IAggregationValue | IAggregationValue[], field?: string) {
  const list = Array.isArray(aggValue) ? aggValue : [aggValue]

  if (list.every((i) => !i.aggregation_field) && !field) throw new Error('Missing Aggregation Field')

  const aggField = list[0].aggregation_field || field

  const queryItem: IQueryItemField = {
    type: 'field',
    fields: [aggField || ''],
    value: list.map((item) => ({
      display_name_cn: item.display_name_cn,
      display_name_en: item.display_name_en,
      type: 'text',
      value: item.key,
    })),
  }

  return transTextQueryField2EntityQueryField(queryItem)
}

export function transTwoDimTupleItems2Ids<T extends Record<string, any>>(
  tuples: IUseChartTwoDimTupleItem<T>[],
  key: keyof FilterByValueType<T, string | string[]>
) {
  const xDimItems = tuples.map((i) => i[1])
  const xDimOtherInfos = xDimItems.map((i) => i.otherInfo).filter((i) => !!i) as T[]

  const ids = uniq(flatten(xDimOtherInfos.map((i) => (Array.isArray(i[key]) ? (i[key] as string[]) : ([i[key]] as string[])))))

  return ids
}

export function transTwoDimItems2Ids<T extends Record<string, any>>(item: AggTwoDimDataItem, key: string) {
  const xDimItem = item.x
  const xDimOtherInfo = xDimItem.other_info as T
  const xMergedData = item.x._meta.mergedData
  const xMergedOtherInfos = (xMergedData?.map((i) => i.other_info) || []) as T[]
  const allOtherInfos = [xDimOtherInfo, ...xMergedOtherInfos]

  const ids = uniq(flatten(allOtherInfos.map((i) => (Array.isArray(i[key]) ? (i[key] as string[]) : ([i[key]] as string[]))))).filter((i) => !!i)

  return ids
}

export function pickDrugIdsFromSingleBar(barItem: IUseBarCount) {
  return uniq(flatten(barItem.data.map((i) => i.other_info?.related_drug_id || [])))
}

export function maybeTimeRelatedQueryField(queryField: IQueryItemField, isUtc = true) {
  const fields = queryField.fields
  const value = queryField.value
  if (!fields || fields.length === 0 || !fields[0]) return queryField
  if (!value || value.length === 0 || !value[0] || isUndefined((value[0] as IQueryValueText).value)) return queryField
  return getTimeRelatedQueryField(+(value[0] as IQueryValueText).value, fields[0], isUtc) || queryField
}

export function getTimeRelatedQueryField(time: number | [number, number], field: string, isUtc?: boolean, isOffset = false, startNewYear = true) {
  const isTimeRange = Array.isArray(time)
  const isActualUtc = isUndefined(isUtc) ? !isTimeRange : isUtc
  if (field === 'START_DATE_YEAR' || field === 'START_DATE') {
    return getRangeQueryField(time, 'START_DATE', undefined, isOffset, isActualUtc)
  }
  if (field === 'STUDY_FIRST_POSTED_DATE') {
    return getRangeQueryField(time, 'STUDY_FIRST_POSTED_DATE', undefined, isOffset, isActualUtc)
  }
  if (field === 'dmp_post_time') {
    return getRangeQueryField(time, 'dmp_post_time', undefined, isOffset, isActualUtc)
  }
  if (field === 'PBDT_YEARMONTHDAY') {
    return getRangeQueryField(time, 'PBDT_YEARMONTHDAY', 'YYYYMMDD', isOffset, isActualUtc)
  }
  if (field === 'CREATED_AT') {
    return getRangeQueryField(time, 'CREATED_AT', undefined, isOffset, isActualUtc)
  }
  if (field === 'APD_YEAR' || field === 'PBDT_YEAR' || field === 'YEAR') {
    if (isTimeRange) return
    return getYearQueryField(String(time), field)
  }
  if (field === 'DEV_STATUS_TIME') {
    return getRangeQueryField(time, 'DEV_STATUS_TIME', undefined, isOffset, isActualUtc)
  }
  if (field === 'PUBLISHED_TIME') {
    return getRangeQueryField(time, 'PUBLISHED_TIME', undefined, isOffset, isActualUtc)
  }
  if (field === 'DEAL_TIME_YEAR' || field === 'DEAL_TIME') {
    return getRangeQueryField(time, 'DEAL_TIME', undefined, isOffset, isActualUtc)
  }
  if (field === 'PUB_DT') {
    return getRangeQueryField(time, 'PUB_DT', 'YYYYMMDD', isOffset, isActualUtc, startNewYear)
  }
}

export function getTimeRelatedQueryFieldByNavList(time: number | [number, number], navListType: INavListType, isUtc?: boolean) {
  const funcRecord: Partial<Record<INavListType, () => ReturnType<typeof getTimeRelatedQueryField>>> = {
    clinical_trial: () => getTimeRelatedQueryField(time, 'START_DATE', isUtc),
    news: () => getTimeRelatedQueryField(time, 'dmp_post_time', isUtc),
    patent: () => getTimeRelatedQueryField(time, 'PBDT_YEARMONTHDAY', isUtc),
    drug: () => getTimeRelatedQueryField(time, 'CREATED_AT', isUtc),
    drug_group_dev_status: () => getTimeRelatedQueryField(time, 'CREATED_AT', isUtc),
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return funcRecord[navListType] && funcRecord[navListType]!()
}

export function getRangeQueryField(
  range: number | [number, number],
  field: string,
  format?: 'YYYYMMDD' | 'YYYYMM' | 'YYYY',
  isOffset = false,
  isUtc = false,
  startNewYear = true
) {
  let from, to
  if (!Array.isArray(range)) {
    const { from: oneYearFrom, to: oneYearTo } = isOffset ? getYearRangeFromNow(range, isUtc) : getOneYearRangeByYear(range, isUtc, startNewYear)
    from = oneYearFrom
    to = oneYearTo
  } else {
    from = range[0]
    to = range[1]
  }

  const queryField: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: convertTimestamp2RangeQueryItem([from, to], format, isUtc),
  }

  return queryField
}

export function getYearQueryField(year: string, field: string) {
  const queryField: IQueryItemField = {
    type: 'field',
    fields: [field],
    value: [
      {
        type: 'text',
        display_name_cn: year,
        display_name_en: year,
        value: year,
      },
    ],
  }

  return queryField
}

export function getInactiveDrugDevStatusField(devField = 'DEV_STATUS') {
  const queryField: IQueryItemField = {
    type: 'field',
    fields: [devField],
    condition: E_QUERY_ITEM_CONDITION.ANY,
    value: INACTIVE_DEV_STATUS_IDS.map((item) => {
      const config = ALL_DEV_STATUS_MAP[item]
      return {
        type: 'text',
        display_name_cn: config.name_cn,
        display_name_en: config.name_en,
        value: item,
      }
    }),
  }

  return queryField
}

export function getActiveDrugDevStatusField() {
  const queryField: IQueryItemField = {
    type: 'field',
    fields: ['DEV_STATUS'],
    condition: E_QUERY_ITEM_CONDITION.ANY,
    value: ACTIVE_DEV_STATUS_IDS.map((item) => {
      const config = ALL_DEV_STATUS_MAP[item]
      return {
        type: 'text',
        display_name_cn: config.name_cn,
        display_name_en: config.name_en,
        value: item,
      }
    }),
  }

  return queryField
}

export function getApprovedDrugDevStatusField() {
  const queryField: IQueryItemField = {
    type: 'field',
    fields: ['DEV_STATUS'],
    condition: E_QUERY_ITEM_CONDITION.ANY,
    value: [
      {
        display_name_cn: ALL_DEV_STATUS_MAP[APPROVED_DEV_STATUS_ID].name_cn,
        display_name_en: ALL_DEV_STATUS_MAP[APPROVED_DEV_STATUS_ID].name_en,
        type: 'text',
        value: APPROVED_DEV_STATUS_ID,
      },
    ],
  }

  return queryField
}

export function getTargetNavListQuery(data: IBaseTargetInfo[], navListType: INavListType, rollup = false, condition = E_QUERY_ITEM_CONDITION.ANY) {
  const fieldRecord: INavListFieldRecord = {
    paper: 'PHS_TARGET_ID',
    news: 'phs_target_id',
    patent: 'PHS_TARGET_ID',
    clinical_trial: 'TARGET_ID',
    clinical_trial_result: 'TARGET_ID',
    drug: 'TARGET_ID',
    drug_group_drug: 'TARGET_ID',
    drug_group_dev_status: 'TARGET_ID',
    drug_group_target: 'TARGET_ID',
    drug_group_organization: '',
    drug_group_disease: '',
    drug_group_drug_type: '',
    drug_deal: 'TARGET_ID_FLATTEN',
    drug_deal_group_drug_deal: 'TARGET_ID_FLATTEN',
    drug_deal_group_drug_deal_drug: '',
    translational_medicine: 'TARGET_ID',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少靶点跳转${navListType}的field配置，默认使用 TARGET_ID`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchTargetList2QueryItem(data, field || 'TARGET_ID', rollup),
        condition,
      },
    ],
  }

  return query
}

export function getDiseaseNavListQuery(data: IBaseDiseaseInfo[], navListType: INavListType, rollup = false, condition = E_QUERY_ITEM_CONDITION.ANY) {
  const fieldRecord: INavListFieldRecord = {
    paper: 'PHS_DISEASE_ID',
    news: 'phs_disease_id',
    patent: 'PHS_DISEASE_ID',
    clinical_trial: 'CONDITION_DISEASE_ID',
    clinical_trial_result: 'CONDITION_DISEASE_ID',
    drug: 'DISEASE_ID',
    drug_group_drug: 'DISEASE_ID',
    drug_group_dev_status: 'DISEASE_ID',
    drug_group_target: 'DISEASE_ID',
    drug_group_organization: '',
    drug_group_disease: 'DISEASE_ID',
    drug_group_drug_type: '',
    drug_deal_group_drug_deal: '',
    drug_deal_group_drug_deal_drug: '',
    drug_deal: '',
    translational_medicine: 'DISEASE_ID',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少适应症跳转${navListType}的field配置，默认使用 DISEASE_ID`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchDiseaseList2QueryItem(data, field || 'DISEASE_ID', rollup),
        condition,
      },
    ],
  }

  return query
}

export function getDrugTypeNavListQuery(data: IBaseDictItem[], navListType: INavListType, rollup = false, condition = E_QUERY_ITEM_CONDITION.ANY) {
  const fieldRecord: INavListFieldRecord = {
    paper: 'PHS_DRUG_TYPE',
    news: 'phs_drug_type',
    patent: 'PHS_DRUG_TYPE_ID',
    clinical_trial: 'DRUG_TYPE',
    clinical_trial_result: 'DRUG_TYPE',
    drug: 'DRUG_TYPE',
    drug_group_drug: 'DRUG_TYPE',
    drug_group_dev_status: 'DRUG_TYPE',
    drug_group_target: 'DRUG_TYPE',
    drug_group_organization: '',
    drug_group_disease: '',
    drug_group_drug_type: '',
    drug_deal: 'DRUG_TYPE',
    drug_deal_group_drug_deal: '',
    drug_deal_group_drug_deal_drug: '',
    translational_medicine: 'DRUG_TYPE',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少药物类型跳转${navListType}的field配置，默认使用 DRUG_TYPE`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchDrugTypeList2QueryItem(data, field || 'DRUG_TYPE', rollup),
        condition,
      },
    ],
  }

  return query
}

export function getDrugNavListQuery(data: IBaseDrugInfo[], navListType: INavListType, rollup = false, condition = E_QUERY_ITEM_CONDITION.ANY) {
  const fieldRecord: Partial<Record<INavListType, string | string[]>> = {
    paper: 'PHS_DRUG_ID',
    news: 'phs_drug_id',
    patent: 'PHS_CORE_DRUG_ID',
    clinical_trial: 'MAIN_EXPERIMENTATION_DRUG_ID',
    clinical_trial_result: ['MAIN_EVALUATE_DRUG_ID'],
    drug: 'DRUG_ID',
    drug_group_drug: 'DRUG_ID',
    drug_group_dev_status: 'DRUG_ID',
    drug_group_target: '',
    drug_group_organization: '',
    drug_group_disease: '',
    drug_group_drug_type: '',
    drug_deal_group_drug_deal: 'DRUG_ID',
    drug_deal_group_drug_deal_drug: '',
    drug_deal: 'DRUG_ID',
    translational_medicine: 'DRUG_ID',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少药物跳转${navListType}的field配置，默认使用 DRUG_ID`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchDrugList2QueryItem(data, field || 'DRUG_ID', rollup),
        condition,
      },
    ],
  }

  return query
}

export function getOrganizationNavListQuery(data: IBaseOrgInfo[], navListType: INavListType, rollup = false, condition = E_QUERY_ITEM_CONDITION.ANY) {
  const fieldRecord: Partial<Record<INavListType, string | string[]>> = {
    paper: 'PHS_ORG_ID_ONEID',
    news: 'master_phs_organization_id',
    patent: 'PHS_ORG_ID_ONEID',
    clinical_trial: 'REGISTRY_ID_ONEID',
    clinical_trial_result: 'SPONSOR_COLLABORATOR_ID_ONEID',
    drug: ['ORGANIZATION_ID_ONEID'],
    drug_group_drug: ['ORGANIZATION_ID_ONEID'],
    drug_group_dev_status: 'ORGANIZATION_ID_ONEID',
    drug_group_target: '',
    drug_group_organization: '',
    drug_group_disease: '',
    drug_group_drug_type: '',
    drug_deal: ['PRINCIPLE_ORG_ID', 'PARTNER_ORG_ID'],
    drug_deal_group_drug_deal: ['PRINCIPLE_ORG_ID', 'PARTNER_ORG_ID'],
    drug_deal_group_drug_deal_drug: '',
    translational_medicine: 'RESEARCH_SPONSOR_ID',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少机构跳转${navListType}的field配置，默认使用 ORGANIZATION_ID_ONEID`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchOrgList2QueryItem(data, 'ORGANIZATION_ID_ONEID', rollup, navListType === 'patent'),
        ...(navListType === 'patent'
          ? undefined
          : {
              fields: field ? (Array.isArray(field) ? field : [field]) : ['ORGANIZATION_ID_ONEID'],
              condition,
            }),
      },
    ],
  }

  return query
}

export function getMechanismNavListQuery(
  data: IBaseMechanismInfo[],
  navListType: INavListType,
  rollup = false,
  condition = E_QUERY_ITEM_CONDITION.ANY
) {
  const fieldRecord: INavListFieldRecord = {
    paper: '',
    news: '',
    patent: '',
    clinical_trial: 'MECHANISM_ACTION_ID',
    clinical_trial_result: 'MECHANISM_ACTION_ID',
    drug: '',
    drug_group_drug: '',
    drug_group_dev_status: '',
    drug_group_target: '',
    drug_group_organization: '',
    drug_group_disease: '',
    drug_group_drug_type: '',
    drug_deal: 'MECHANISM_ACTION_ID',
    drug_deal_group_drug_deal: 'MECHANISM_ACTION_ID',
    drug_deal_group_drug_deal_drug: '',
    translational_medicine: 'MECHANISM_ACTION_ID',
  }

  const field = fieldRecord[navListType]

  if (!field) {
    console.warn(`缺少作用机制跳转${navListType}的field配置，默认使用 MECHANISM_ACTION_ID`)
  }

  const query: IQuery = {
    type: 'group',
    must: [
      {
        ...transBatchMechanismList2QueryItem(data, field || 'MECHANISM_ACTION_ID', rollup),
        condition,
      },
    ],
  }

  return query
}

export function getEntityLangName(entity: { id: string; display_name_cn: string; display_name_en: string }): IBaseSourceLangName[] {
  return [
    {
      lang: 'CN',
      name: entity.display_name_cn || entity.display_name_en,
    },
    {
      lang: 'EN',
      name: entity.display_name_en,
    },
  ]
}

export function getNavListQueryByEntity(
  entity: Array<{ id: string; display_name_cn: string; display_name_en: string; entity_name_cn?: string; entity_name_en?: string }>,
  entityType: 'drug' | 'target' | 'organization' | 'disease' | 'drug_type' | 'mechanism',
  navListType: INavListType,
  condition = E_QUERY_ITEM_CONDITION.ANY
) {
  const type = entityType
  if (type === 'drug') {
    return getDrugNavListQuery(
      entity.map((item) => ({
        drug_id: item.id,
        drug_name: getEntityLangName(item),
      })),
      navListType,
      false,
      condition
    )
  } else if (type === 'disease') {
    return getDiseaseNavListQuery(
      entity.map((item) => ({
        disease_id: item.id,
        disease_name: getEntityLangName(item),
      })),
      navListType,
      true,
      condition
    )
  } else if (type === 'target') {
    return getTargetNavListQuery(
      entity.map((item) => ({
        target_id: item.id,
        target_name: getEntityLangName(item),
        short_name: getEntityLangName(item),
        entity_name_cn: item.entity_name_cn,
        entity_name_en: item.entity_name_en,
      })),
      navListType,
      true,
      condition
    )
  } else if (type === 'drug_type') {
    return getDrugTypeNavListQuery(
      entity.map((item) => ({
        dict_id: item.id,
        name_cn: item.display_name_cn,
        name_en: item.display_name_en,
      })),
      navListType,
      true,
      condition
    )
  } else if (type === 'organization') {
    return getOrganizationNavListQuery(
      entity.map((item) => ({
        entity_name_cn: item.entity_name_cn,
        entity_name_en: item.entity_name_en,
        entity_id: item.id,
        name_cn: item.display_name_cn,
        name_en: item.display_name_en,
      })),
      navListType,
      true,
      condition
    )
  } else {
    return getMechanismNavListQuery(
      entity.map((item) => ({
        mechanism_id: item.id,
        mechanism_name: getEntityLangName(item),
        short_name: getEntityLangName(item),
      })),
      navListType,
      false,
      condition
    )
  }
}

async function getSingleQueryFieldWithOrgIds(ids: string[], field?: string) {
  let list: IBaseOrgInfo[] = []
  const res = await sharedCtx.service.organization.getBatchOrg(ids)
  if (res.success) {
    list = res.data || []
  }
  const queryItem = transBatchOrgList2QueryItem(list, field)
  return queryItem
}

async function getSingleQueryFieldWithDrugIds(ids: string[], field?: string) {
  let list: IBatchDrugInfo[] = []
  const res = await sharedCtx.service.drug.getBatchDrugs(ids)
  if (res.success) {
    list = res.data || []
  }
  const queryItem = transBatchDrugList2QueryItem(list, field)
  return queryItem
}

async function getSingleQueryFieldWithDiseaseIds(ids: string[], field?: string) {
  let list: IBaseDiseaseInfo[] = []
  const res = await sharedCtx.service.disease.getBatchDisease(ids)
  if (res.success) {
    list = res.data || []
  }
  const queryItem = transBatchDiseaseList2QueryItem(list, field)
  return queryItem
}

async function getSingleQueryFieldWithTargetIds(ids: string[], field?: string) {
  let list: IBaseTargetInfo[] = []
  const res = await sharedCtx.service.target.getBatchTargets(ids)
  if (res.success) {
    list = res.data || []
  }
  const queryItem = transBatchTargetList2QueryItem(list, field)
  return queryItem
}

export async function getSingleQueryFieldWithMultiTargetIds(ids: string[], field?: string) {
  let list: IBaseTargetInfo[] = []
  const res = await sharedCtx.service.target.getBatchMultiTargets(ids)
  if (res.success) {
    list = res.data || []
  }
  const queryItem = transBatchTargetList2QueryItem(list, field)
  return queryItem
}

export async function getSingleQueryWithTargetIds(ids: string[], field = 'TARGET_ID', rollup = false) {
  const query: IQuery = {
    type: 'group',
    fields: [field],
  }
  const rt = await sharedCtx.service.target.getBatchSingleTargetByMultiTarget(ids)
  // 失败情况不考虑
  if (!rt.success) return query
  const targetsMap = rt.data.target_mapping || {}
  const multiTargetIds = Object.keys(targetsMap)
  const singleTargetIds = ids.filter((id) => !multiTargetIds.includes(id))
  const singleTargetSubQuery: IQuery[] = singleTargetIds.map((id) => ({
    type: 'group',
    must: [
      {
        ...transBatchTargetList2QueryItem([{ target_id: id, target_name: [], short_name: [] }], field, rollup),
        condition: E_QUERY_ITEM_CONDITION.ALL,
      },
    ],
    fields: [field],
  }))

  const multiTargetSubQueries = multiTargetIds.reduce((pre, next) => {
    const targets = targetsMap[next]
    if (targets.length === 0) return pre
    const subQuery: IQuery = {
      type: 'group',
      must: [{ ...transBatchTargetList2QueryItem(targets, field, rollup), condition: E_QUERY_ITEM_CONDITION.ALL }],
      fields: [field],
    }
    pre.push(subQuery)
    return pre
  }, [] as IQuery[])

  query.any = [...multiTargetSubQueries, ...singleTargetSubQuery]

  return query
}

export async function getSingleQueryFieldWithOneMultiTargetId(id: string, field = 'TARGET_ID', rollup = false) {
  let list: IBaseTargetInfo[] = []
  const rt = await sharedCtx.service.target.getBatchUnencrypt([id])
  if (rt.success) {
    list = rt.data.items || []
  }
  const queryItem = transBatchTargetList2QueryItem(list, field, rollup)

  queryItem.condition = E_QUERY_ITEM_CONDITION.ALL

  return queryItem
}

export async function getSingleQueryIdWithEntityIds(ids: string[], type: IEntityType, field?: string) {
  const funcRecord: Record<IEntityType, typeof getSingleQueryFieldWithDrugIds> = {
    target: getSingleQueryFieldWithTargetIds,
    drug: getSingleQueryFieldWithDrugIds,
    organization: getSingleQueryFieldWithOrgIds,
    disease: getSingleQueryFieldWithDiseaseIds,
  }

  return await funcRecord[type](ids, field)
}

export function getSingleQueryIdWithNoEntityIds(ids: string[], field: string): IQueryItemField {
  const value: IQueryValueText[] = ids.map((id) => ({
    type: 'text',
    value: id,
    display_name_cn: '',
    display_name_en: '',
  }))

  return {
    type: 'field',
    condition: E_QUERY_ITEM_CONDITION.ANY,
    fields: [field],
    display_name_cn: '提取自情报监控报告',
    display_name_en: 'Extracted from alert report',
    value,
  }
}
