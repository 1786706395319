import { requestConfigWrapper } from '@patsnap/common-ps-http/dist/PersistentCache'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { synapsePersistCacheConfInst } from './config'
import { HttpCache } from './httpCache'
import { ICreateServiceParams, IHttp, IHttpWrap, IRequestConfig } from './types'
const xCorrelationIdKey = 'x-correlation-id'
const X_FONT_FONT_CLASS = 'x-font-class'
export function createHttp(params: ICreateServiceParams): IHttp {
  const authAxiosInstance = params.axios
  const httpCache = new HttpCache()
  function http<T>(config: IRequestConfig): Promise<IHttpWrap<T>> {
    const isMemoryCache = (typeof config.cache === 'boolean' && config.cache) || config.synapseCacheType === 'memory'
    if (isMemoryCache) {
      const cacheData = httpCache.get(config)
      if (cacheData) {
        const xCorrelationId = cacheData.headers[xCorrelationIdKey]
        const fontClassName = cacheData.headers[X_FONT_FONT_CLASS]
        return Promise.resolve({
          data: cacheData.data,
          xCorrelationId,
          status: 200,
          success: true,
          fromCache: true,
          fontClassName,
        })
      }
    }
    if (config.synapseCacheType === 'persistent') {
      config = requestConfigWrapper(config as AxiosRequestConfig, synapsePersistCacheConfInst) as IRequestConfig
    }
    return authAxiosInstance
      .request(config)
      .then((resp) => {
        if (config.cache) {
          httpCache.cache(config, resp)
        }
        const fontClassName = resp.headers[X_FONT_FONT_CLASS]
        return {
          xCorrelationId: resp.headers[xCorrelationIdKey],
          fontClassName,
          data: resp.data,
          status: resp.status,
          success: true,
        }
      })
      .catch((e: AxiosError) => {
        const resp = e.response
        const errorMessage = resp?.data?.error_message as string
        // 有错误,并且显示错误就弹出错误
        if (errorMessage && config.showErrorMessage) {
          params.onGetError && params.onGetError(errorMessage)
        }
        return {
          xCorrelationId: resp?.headers[xCorrelationIdKey],
          data: resp?.data,
          status: resp?.status || 0,
          code: resp?.data?.code || '',
          error: e,
          success: false,
        }
      })
  }
  return http
}
