import { E_QUERY_ITEM_CONDITION, IACListItem } from '@patsnap/synapse_common_interface'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition/useAuthStore'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { LogicSortConfig, LogicTagConfig } from '@pharmsnap/shared/config'
import { ElInput, ElPopover } from '@pharmsnap/shared/element-ui'
import { createObservable } from '@pharmsnap/shared/service/http/create-observable'
import { IAutoCompleteParams, IAutoCompleteRes, IAutoCompleteType, IBAcTag, IListItem, ISearchDataType } from '@pharmsnap/shared/types'
import { convertDataStrategyMap, getAutoCompleteList, getStringLength, showSingleToast } from '@pharmsnap/shared/utils'
import {
  ComponentInternalInstance,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { filter, Subject, switchMap, tap } from 'rxjs'
import VClickOutside from 'v-click-outside'
import { VNode, VNodeData } from 'vue'
import { BAcList } from '../BAcList/BAcList'
import { BAcTag } from '../tag/BAcTag/BAcTag'
import $classes from './BAcBase.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

/**
 * 外部不要直接用这个组件了,请使用BAutoEntity
 */
export const BAcBase = defineComponent({
  name: 'BAcBase',
  i18n: {
    messages: {
      en,
      cn,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    clearAble: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    popperClass: {
      type: String,
      default: '',
    },
    inputBlurClass: {
      type: String,
      default: '',
    },
    tagsLimit: {
      type: Number,
      default: 20,
    },
    viewDetailLabel: {
      type: String,
    },
    tags: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    tagSize: {
      type: String as PropType<'mini' | 'small' | 'medium' | 'default'>,
      default: 'small',
    },
    showLimitNum: {
      type: Boolean,
      default: false,
    },
    inputTip: {
      type: String,
    },
    emptyTip: {
      type: String,
    },
    type: {
      type: String as PropType<IAutoCompleteType>,
    },
    recommendTip: {
      type: String,
    },
    clickOutClear: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
    },
    suffixText: {
      type: [String, Object] as PropType<string | VNode>,
    },
    suffixClass: {
      type: String,
      default: 'w-10',
    },
    suffixIconClass: {
      type: String,
      default: 'w-6 h-6',
    },
    popoverMaxHeight: {
      type: Number,
    },
    logicVal: {
      type: String as PropType<E_QUERY_ITEM_CONDITION>,
      default: E_QUERY_ITEM_CONDITION.ANY,
    },
    showLogicTag: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String as PropType<ISearchDataType>,
      default: '',
    },
    /**
     * 传'target'则靶点的autocomplete会过滤掉顶层的靶点
     */
    searchType: {
      type: String,
      default: '',
    },
    /**
     * core autocomplete 自定义 entityType 使用的参数
     */
    entityType: {
      type: Array as PropType<IAutoCompleteParams['entity_type']>,
    },
    customLimitMsg: {
      type: String,
    },
    validateFocusPermission: {
      type: Function as PropType<() => boolean>,
    },
    /**
     * 请求时后端返回的限制数
     */
    limit: {
      type: Number,
    },
    auth: {
      type: Boolean,
      default: false,
    },
    customLimitNumClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const input$ = new Subject<string>()
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const data = reactive<{
      keyword: string
      visible: boolean
      loading: boolean
      items: IACListItem[]
      focus: boolean
    }>({
      keyword: '',
      visible: false,
      loading: false,
      items: [],
      focus: false,
    })
    const editTag = ref<IListItem>()
    const editWord = ref('')

    const ins = getCurrentInstance() as ComponentInternalInstance
    const isLimited = computed(() => props.tagsLimit && props.tags.length >= props.tagsLimit)
    const localeData = useLocale()

    /**
     * enter会触发，但是中英文的时候交互与预期不符，enter使用keypress
     * @param e
     * @returns
     */
    const handleKeyDown = (e: KeyboardEvent) => {
      e.stopPropagation()
      const { key: keyboardKey } = e
      if (keyboardKey === 'Escape') {
        if (editTag.value && editWord.value && data.keyword && editWord.value === data.keyword) {
          handleRevertTag()
        }
        handleBlurContainer()
      }
      emit('keyDown', e, data.keyword)
    }

    const handleKeypress = (e: KeyboardEvent) => {
      e.stopPropagation()
      emit('keyPress', e, data.keyword)
      const { key: keyboardKey } = e
      if (keyboardKey === 'Enter' && props.clickOutClear) {
        if (data.keyword.length < 2) return
        handleCoreLogic()
      }
    }

    const handleInput = (keyword: string) => {
      if (props.auth) {
        if (isFreeUser.value) {
          changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'INPUT' })
          return
        }
      }

      if (isLimited.value) {
        handleOverLimit()
        return
      }
      data.keyword = keyword
      if (data.keyword) {
        data.focus = true
      }
    }

    const handleClear = () => {
      emit('clear')
      data.items = []
      data.keyword = ''
    }

    /**
     * 处理core的autocomplete的特殊逻辑
     */
    const handleCoreLogic = () => {
      data.keyword = ''
      data.items = []
    }

    const hasItemData = computed(() => data.items.some((o) => o.data.length))

    const logicLabel = computed(() => LogicTagConfig[props.logicVal])

    const handelClickOutside = () => {
      data.focus = false
      if (editTag.value && editWord.value && data.keyword && editWord.value === data.keyword) {
        handleRevertTag()
      } else if (data.keyword) {
        emit('clickOutside', data.keyword)
        // 点击outside需要转化为tag的需要清空keywords
        if (props.clickOutClear) {
          handleCoreLogic()
        } else {
          if (props.recommendTip && hasItemData.value) {
            showSingleToast({ message: props.recommendTip, type: 'info' })
          }
        }
      }
    }

    const handleOverLimit = () => {
      const message = props.customLimitMsg || (ins.proxy.$t('BAcBase.limitInfo', { limit: props.tagsLimit }) as string)
      showSingleToast({ message, type: 'info' })
    }

    const handleViewDetail = (item: IListItem) => {
      emit('viewDetail', item)
    }

    const selectItem = (item: IListItem) => {
      if (isLimited.value) {
        handleOverLimit()
        return
      }
      emit('selectItem', item)
      handleClear()
      handleFocusContainer()
    }

    const handleFocusContainer = () => {
      if (props.disabled) {
        return
      }
      const pass = props.validateFocusPermission?.()
      if (pass !== undefined && !pass) return
      data.focus = true
      const input = ins.proxy.$refs.cusInput as HTMLInputElement
      input && input.focus()
    }

    const handleBlurContainer = () => {
      data.focus = false
      const input = ins.proxy.$refs.cusInput as HTMLInputElement
      input && input.blur()
    }

    const closeTag = (tag: IBAcTag) => {
      emit('close', tag)
    }

    const clickTag = (tag: IBAcTag) => {
      if (data.keyword) {
        showSingleToast({ message: ins.proxy.$t('BAcBase.editInfo') as string, type: 'info' })
        return
      }
      if (tag.searchQuery) {
        emit('clickTag', tag)
      } else {
        emit('close', tag)
        data.keyword = getTagDisplayName(tag) || ''
        editTag.value = tag
        editWord.value = data.keyword
      }
    }

    function handleRevertTag() {
      emit('revertTag', editTag.value)
      editTag.value = undefined
      editWord.value = ''
      data.keyword = ''
    }

    function init() {
      const result$ = input$
        .pipe(
          tap(() => (data.items = [])),
          filter((text) => getStringLength(text) > 1),
          switchMap((text) => {
            return createObservable<IAutoCompleteParams, IAutoCompleteRes>(getAutoCompleteList, {
              keywords: text.trim(),
              key: props.type as IAutoCompleteType,
              limit: props.limit !== undefined ? props.limit : props.type === 'Core' ? 5 : 10,
              type: props.searchType,
              data_type: props.dataType,
              entity_type: props.entityType,
            })
          })
        )
        .subscribe((res) => {
          if (res?.success && res.data && data.keyword) {
            const resData = res.data || []
            const func = convertDataStrategyMap.get(props.type as IAutoCompleteType)
            // 记得改 BAcOnlyInput 文件
            const hideIconTypes: Array<typeof props.type> = ['ADC_Linker', 'ADC_Payload', 'ADC_Antibody_Type', 'ADC_SITE', 'DATA_SOURCE_MEETING']

            if (func) {
              const result: IACListItem[] =
                func({
                  data: resData,
                  locale: localeData.locale.value,
                  keywords: data.keyword.trim(),
                  showIcon: !hideIconTypes.includes(props.type),
                  itemType: props.type,
                }) ?? []
              data.items = result
              const popover = ins.proxy.$refs.popover as any
              nextTick(() => {
                popover && popover.updatePopper()
              })
            }
          }
        })

      onUnmounted(() => {
        result$.unsubscribe()
      })
    }

    init()

    watch(
      () => data.keyword,
      () => {
        if (props.auth && isFreeUser.value) {
          changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'INPUT' })
        } else {
          input$.next(data.keyword.trim())
        }
      }
    )

    const handleBlur = () => {
      const container = ins.proxy.$refs.container as HTMLElement
      container && container.classList.remove($classes.focusInput)
    }

    const handleFocus = () => {
      const container = ins.proxy.$refs.container as HTMLElement
      container && container.classList.add($classes.focusInput)
    }

    const handleSuffixClick = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      emit('suffixClick', data.keyword)
      data.keyword = ''
    }

    const onAfterClickSuffix = (e?: MouseEvent) => {
      e?.preventDefault()
      e?.stopPropagation()
      data.keyword = ''
    }

    const getTagDisplayName = (tag: IBAcTag) => {
      const defaultTagName = tag[`name_${localeData.locale.value}`] || tag.name_en || tag.name_cn
      const defaultTagForceEnName = tag.name_en || tag.name_cn

      if (
        props.type === 'ADC_Antibody' ||
        props.type === 'ADC_Linker' ||
        props.type === 'ADC_Payload' ||
        props.type === 'ADC_Antibody_Type' ||
        props.type === 'ADC_SITE'
      ) {
        return defaultTagForceEnName
      }

      return props.type === 'ATC' ? tag.atc_code : defaultTagName
    }

    function handleChangeLogicVal() {
      const index = LogicSortConfig.findIndex((o) => o === props.logicVal)
      if (index >= LogicSortConfig.length - 1) {
        emit('changeLogicVal', LogicSortConfig[0])
      } else {
        emit('changeLogicVal', LogicSortConfig[index + 1])
      }
    }

    const logicTagClass = computed(() => {
      if (props.logicVal === E_QUERY_ITEM_CONDITION.ANY) {
        return $classes.orTag
      }
      if (props.logicVal === E_QUERY_ITEM_CONDITION.ALL) {
        return $classes.andTag
      }
      return $classes.logicTag
    })

    return {
      ...toRefs(data),
      localeData,
      handleInput,
      handleClear,
      selectItem,
      handleViewDetail,
      handleKeyDown,
      handelClickOutside,
      handleFocusContainer,
      closeTag,
      clickTag,
      handleBlur,
      handleFocus,
      handleSuffixClick,
      onAfterClickSuffix,
      getTagDisplayName,
      handleKeypress,
      logicLabel,
      handleChangeLogicVal,
      logicTagClass,
    }
  },
  methods: {
    clearKeywords() {
      this.keyword = ''
    },
    renderContentState() {
      const vNodeData: VNodeData = {
        props: {
          inputText: this.keyword,
          items: this.items,
          viewDetailLabel: this.viewDetailLabel,
          maxHeight: this.popoverMaxHeight,
        },
        on: {
          clear: this.handleClear,
          selectItem: this.selectItem,
          viewDetail: this.handleViewDetail,
        },
      }
      return <BAcList {...vNodeData}></BAcList>
    },
    renderLoadingState() {
      return this.$slots.loadingComp ?? <div>default loading..</div>
    },

    renderTips() {
      return this.$slots.customInputTip ?? <div>{this.inputTip}</div>
    },

    renderEmptyState() {
      return <div>{this.emptyTip}</div>
    },
    renderNumLimit() {
      return this.showLimitNum ? (
        <div class={[$classes.numLimit, this.customLimitNumClass]}>
          {this.tags.length}/{this.tagsLimit}
        </div>
      ) : null
    },
    renderContent() {
      if (this.focus) {
        if (!this.tags.length && getStringLength(this.keyword) < 2 && this.inputTip) {
          this.visible = true
          return this.renderTips()
        } else if (this.items.length && this.items.every((o) => !o.data.length) && this.keyword && this.emptyTip) {
          this.visible = true
          return this.renderEmptyState()
        } else if (this.items.some((o) => o.data.length) && this.keyword) {
          this.visible = true
          return this.$scopedSlots.autocompleteContent
            ? this.$scopedSlots.autocompleteContent({ data: this.items, keyword: this.keyword })
            : this.renderContentState()
        }
      }
      this.visible = false
    },
    renderExample() {
      if (!this.focus && !this.tags.length && !this.placeholder && this.$slots.example && !this.keyword) {
        return this.$slots.example
      }
    },
    renderSuffix() {
      if (this.$slots.suffix || this.$scopedSlots.suffix)
        return this.$slots.suffix || this.$scopedSlots?.suffix?.({ keyword: this.keyword, onAfterClickSuffix: this.onAfterClickSuffix })
      if (this.suffix || this.suffixText) {
        return (
          <div class={[$classes.suffixIconContainer, this.suffixClass]} onClick={(e: Event) => this.handleSuffixClick(e)}>
            {this.suffix ? (
              <PtIcon class={this.suffixIconClass} icon={this.suffix}></PtIcon>
            ) : typeof this.suffixText === 'string' ? (
              <span>{this.suffixText}</span>
            ) : (
              this.suffixText
            )}
          </div>
        )
      }
      return null
    },
    renderLogicTag() {
      return (
        <BAcTag name={this.logicLabel} size={this.tagSize} canClose={false} class={this.logicTagClass} onClick={this.handleChangeLogicVal}></BAcTag>
      )
    },
    renderTagsContainer() {
      return (
        <template slot="reference">
          <div ref="container" class={[$classes.tagBox, 'tagBox', { 'cursor-not-allowed bg-[#F5F7FA]': this.disabled }]}>
            <div class={[$classes.content, 'tagContent']} onClick={this.handleFocusContainer}>
              {this.$slots.inlinePrefix}
              {this.showLogicTag && this.renderLogicTag()}
              <div class={[$classes.example, { 'cursor-not-allowed': this.disabled }]}>{this.renderExample()}</div>
              {this.tags.map((tag) => {
                const d = {
                  props: {
                    ...tag,
                    name: this.getTagDisplayName(tag),
                    canClose: true,
                    ownerClass: $classes.tag,
                    size: this.tagSize,
                  },
                }
                return <BAcTag {...d} onClose={() => this.closeTag(tag)} onClick={() => this.clickTag(tag)}></BAcTag>
              })}
              <div class="flex-1 min-w-8 inputContainer">
                <ElInput
                  data-testid="b-ac-base__input"
                  class={$classes.acInput}
                  size="small"
                  ref="cusInput"
                  value={this.keyword}
                  placeholder={this.placeholder}
                  onInput={this.handleInput}
                  onClear={this.handleClear}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  disabled={this.disabled}
                  /**
                   * 因为keydown在osx系统的中文输入法上表现的不符合预期，所以替换成keypress
                   * keydown：中文下enter会先把当前的keywords转换成tag，再把输入法中内容放到keywords中
                   * keypress：仅把输入法中内容加到keywords中，不会执行转换tag，需要二次enter，与chrome输入url跳转的操作方式一致
                   */
                  nativeOnKeypress={this.handleKeypress}
                  nativeOnKeydown={this.handleKeyDown}
                ></ElInput>
              </div>
            </div>
            {this.$slots.innerCheckbox}
            {this.renderNumLimit()}
            {this.renderSuffix()}
          </div>
        </template>
      )
    },
  },
  render() {
    const popperClass = this.popperClass ? `${$classes.defaultPopper} ${this.popperClass}` : $classes.defaultPopper
    return (
      <ElPopover
        ref="popover"
        placement="bottom-start"
        class={this.focus ? `${$classes.container}` : `${$classes.containerBlur} ${this.inputBlurClass}`}
        trigger="manual"
        transition="none"
        value={this.visible}
        popper-class={popperClass}
        appendToBody={true}
        visibleArrow={false}
        {...{
          directives: this.focus
            ? [
                {
                  name: 'click-outside',
                  value: {
                    handler: this.handelClickOutside,
                  },
                },
              ]
            : [],
        }}
      >
        {this.renderContent()}
        {this.renderTagsContainer()}
      </ElPopover>
    )
  },
})
