import { getDisplayNameByLang } from '@patsnap/synapse_common_business'
import { DRUG_DEV_STATUS_IDS, EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { formatTimestamp, getLangDegraded, getSpecialLang } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { phaseWeightMap } from '@pharmsnap/shared/src/config'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { ElTable, ElTableColumn } from '@pharmsnap/shared/src/element-ui'
import {
  E_NODATA_SIZE,
  IBaseDevStatusCountryInfo,
  IBaseDevStatusInfo,
  IBaseDictItem,
  ICommonTrack,
  IDrugDiseaseInfo,
  IDrugRDStatusInfo,
  IDrugRDTableStatusInfo,
  IOrganizationCurrentProjectDetail,
} from '@pharmsnap/shared/src/types'
import { sortFunc } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GCountryFlag } from '../../ui/GCountryFlag/GCountryFlag'
import { GEmpty } from '../../ui/GEmpty/GEmpty'
import { GLink } from '../../ui/GLink/GLink'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BDevStatusDataSource } from '../BDevStatusDataSource/BDevStatusDataSource'
import { BOrganizationItem } from '../BOrganizationItem/BOrganizationItem'
import $classes from './BPipelineTable.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

const stopIconSize = 20
const cellPadding = 8

interface IPipelineOriginTableData extends Partial<IDrugRDStatusInfo>, Partial<Omit<IOrganizationCurrentProjectDetail, 'status_tables'>> {}

/** pipeline table 打平的数据 */
interface IPipelineDisplayTableData extends Partial<IDrugRDTableStatusInfo>, Partial<Omit<IOrganizationCurrentProjectDetail, 'status_tables'>> {
  /** 折叠元素id */
  expandId?: string
  /** 折叠元素是否展开 */
  expandStatus?: boolean
  /** 隐藏的国家数量 */
  collapseCountryNum?: number
}

/** 计算开始merge的start与length */
const getMergeDataStartAndLength = (data: IPipelineDisplayTableData[], getIdFunc: (data: IPipelineDisplayTableData) => string) => {
  const res: {
    id: string
    start: number
    length: number
  }[] = []
  data.forEach((val, index) => {
    const id = getIdFunc(val)
    const data = res.find((o) => o.id === id)
    if (data) {
      data.length++
    } else {
      res.push({
        start: index,
        length: 1,
        id,
      })
    }
  })
  return res
}

export const BPipelineTable = defineComponent({
  name: 'BPipelineTable',
  props: {
    data: {
      required: true,
      type: Array as PropType<readonly IPipelineOriginTableData[]>,
    },
    // 是否显示药物列，机构详情页显示药物
    showDrugColumn: {
      type: Boolean,
      default: false,
    },
    // 默认展开的国家数量
    defaultExpandCountriesNum: {
      type: Number,
      default: 2,
    },
    isExpandAllCountry: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props) {
    const { localeUpcase, isCN, locale } = useLocale()
    // 元素的展开/折叠状态
    const expandStatusMap = ref<Record<string, boolean | undefined>>({})

    const highestPhaseColumnWidth = computed(() => (props.showDrugColumn ? 180 : 320))

    const flattenTableData = computed(() => {
      const res: IPipelineDisplayTableData[] = []

      props.data.forEach((item) => {
        // 研发状态排序
        const sortDevStatus = sortFunc<IBaseDevStatusInfo>({
          data: item.dev_status || [],
          map: phaseWeightMap,
          getKey: (d) => d.dev_status_id,
          sort: 'ba',
        })

        const flattenCountryData: Array<IBaseDevStatusCountryInfo & IBaseDevStatusInfo> = []

        sortDevStatus.forEach((status) => {
          // country数据打平
          status.country_info.forEach((country) => {
            flattenCountryData.push({
              ...country,
              ...status,
            })
          })
        })

        // 增加国家折叠/展示数据
        const expandId = (item.id || '') + (item.disease_id_view?.disease_id || '')

        let displayCountry = props.isExpandAllCountry ? flattenCountryData : flattenCountryData.slice(0, props.defaultExpandCountriesNum)

        if (expandStatusMap.value[expandId] === false) {
          displayCountry = flattenCountryData.slice(0, props.defaultExpandCountriesNum)
        }
        if (expandStatusMap.value[expandId] === true) {
          displayCountry = flattenCountryData
        }

        displayCountry.forEach((country) => {
          // 机构数据打平
          country.organization_info?.forEach((organizationInfo) => {
            const d: IPipelineDisplayTableData = {
              ...item,
              inactive: country.inactive,
              dev_status_id_view: country.dev_status_id_view,
              country_view: country.country_view,
              org_id: organizationInfo.org_id,
              org_id_view: organizationInfo.org_id_view,
              dev_status_earliest_time: organizationInfo.dev_status_time,
              data_source: organizationInfo.data_source,
            }
            res.push(d)
          })
        })

        // 插入country 折叠/展示
        if (displayCountry.length > props.defaultExpandCountriesNum) {
          // 插入 折叠
          res.push({
            ...item,
            expandId,
            expandStatus: true,
          })
        }
        if (displayCountry.length < flattenCountryData.length) {
          // 插入 展开
          res.push({
            ...item,
            expandId,
            expandStatus: false,
            collapseCountryNum: flattenCountryData.length - displayCountry.length,
          })
        }
      })
      return res
    })

    const mergeTableCellFun = ({ rowIndex, columnIndex, row }: { rowIndex: number; columnIndex: number; row: IPipelineDisplayTableData }) => {
      if (props.data.length === 0) return [0, 0]
      // 合并药物
      if (props.showDrugColumn && columnIndex === 0) {
        const mergeDrugStartWithLength = getMergeDataStartAndLength(flattenTableData.value, (data) => data.id || '')
        const mergeData = mergeDrugStartWithLength.find((o) => o.start === rowIndex)
        return {
          rowspan: mergeData ? mergeData.length : 0,
          colspan: mergeData ? 1 : 0,
        }
      }
      // 合并适应症
      if (props.showDrugColumn ? columnIndex === 1 : columnIndex === 0) {
        const mergeDiseaseStartWithLength = getMergeDataStartAndLength(
          flattenTableData.value,
          (data) => (data.id || '') + (data.disease_id_view?.disease_id || '')
        )
        const mergeData = mergeDiseaseStartWithLength.find((o) => o.start === rowIndex)
        return {
          rowspan: mergeData ? mergeData.length : 0,
          colspan: mergeData ? 1 : 0,
        }
      }

      const countryColumnIndex = props.showDrugColumn ? 2 : 1

      if (row.expandId) {
        // 合并 折叠/展示
        if (columnIndex === countryColumnIndex) {
          return {
            rowspan: 1,
            colspan: 5,
          }
        }
        if (columnIndex > countryColumnIndex) {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      } // 合并国家列和研发状态列
      else if (columnIndex === countryColumnIndex || columnIndex === countryColumnIndex + 1) {
        const mergeCountryStartWithLength = getMergeDataStartAndLength(
          flattenTableData.value,
          (data) => data.expandId || (data.id || '') + (data.disease_id_view?.disease_id || '') + (data.country_view?.id || 'unknown') // id + disease_id + country_id：折叠元素会和国家为unknown元素的id一样，所以id区分使用expandId 和 id + disease_id + country_id
        )
        const mergeData = mergeCountryStartWithLength.find((o) => o.start === rowIndex)
        return {
          rowspan: mergeData ? mergeData.length : 0,
          colspan: mergeData ? 1 : 0,
        }
      }
    }

    const uploadTracker = (eventCode: ICommonTrack['event_code'], triggerPoint?: string, payload?: Record<string, any>) => {
      sharedCtx.service.tracking.trackGeneral({
        event_code: eventCode,
        trigger_point: triggerPoint,
        payload,
      })
    }

    const handleExpand = (id: string, status: boolean) => {
      expandStatusMap.value = {
        ...expandStatusMap.value,
        [id]: status,
      }
      uploadTracker('CLICK_EXPAND', 'PIPELINE_EXPAND_ITEM', {
        action: status ? 'expand' : 'collapse',
      })
    }

    watch(
      () => props.isExpandAllCountry,
      () => (expandStatusMap.value = {})
    )

    return { locale, localeUpcase, highestPhaseColumnWidth, isCN, flattenTableData, mergeTableCellFun, expandStatusMap, handleExpand }
  },
  methods: {
    renderDrugCell(row: IPipelineDisplayTableData) {
      const targets = row.targets || []
      const drugName = getDisplayNameByLang({ display_name_cn: row.drug_name_cn || '', display_name_en: row.drug_name_en || '' }, this.locale)
      return (
        <div class={$classes.breakWords}>
          <GLink defaultColor="blue" href={row.id ? sharedCtx.router.generatorDrugPath(row.id) : undefined} name={drugName} title={drugName}></GLink>
          {!!targets.length && (
            <div class="text-text-t2">
              (
              {targets.map((target, index) => {
                const targetName = getLangDegraded({ name_cn: target.entity_name_cn, name_en: target.entity_name_en }, this.locale)
                return (
                  <span class={$classes.breakWords}>
                    {targetName}
                    {index < targets.length - 1 && ' / '}
                  </span>
                )
              })}
              )
            </div>
          )}
          {!!row.license_in && (
            <div class="inline-block mt-1 text-xs px-1 py-[2px] leading-5 rounded  text-green-text bg-green-tag">{this.$t('licenseIn')}</div>
          )}
        </div>
      )
    },
    renderIndication(disease?: IDrugDiseaseInfo) {
      if (disease) {
        const displayName = getSpecialLang({ data: disease.disease_name || [], field: 'name', lang: this.localeUpcase, isDegraded: false })
        const href = sharedCtx.router.generatorDiseasePath(disease.disease_id)
        return <GLink defaultColor="blue" href={href} name={displayName}></GLink>
      }
      return EMPTY_PLACEHOLDER
    },
    renderViewTrial(row: IPipelineDisplayTableData) {
      return (
        <div
          class="text-blue-clicked cursor-pointer rounded border border-blue-clicked px-1 leading-5 text-xs hover:bg-blue-light"
          onClick={() => this.$emit('viewTrial', row)}
        >
          {this.$tc('viewAllTrials')}
        </div>
      )
    },
    renderIndicationCell(row: IPipelineDisplayTableData) {
      return (
        <div class="flex items-center h-6">
          <div class="flex overflow-hidden leading-6">{this.renderIndication(row.disease_id_view)}</div>
          <div class="ml-2 flex-shrink-0">{row.disease_id_view && !!row.clinical_trial_num ? this.renderViewTrial(row) : null}</div>
        </div>
      )
    },
    renderCountryCell(row: IPipelineDisplayTableData) {
      const expandId = row.expandId
      // 渲染折叠/展示
      if (expandId) {
        return (
          <span
            class="inline-block text-xs leading-6 cursor-pointer text-blue-default"
            onClick={() => this.handleExpand(expandId, !row.expandStatus)}
          >
            {row.expandStatus ? this.$t('collapse') : `+[${row.collapseCountryNum} ${this.$t('R&DStatus')}]`}
          </span>
        )
      }
      return (
        <div class="flex items-center">
          <GCountryFlag
            name={row.country_view?.alpha_2_code || row.country_view?.display_name || ''}
            nameCn={row.country_view?.display_name_cn}
            showName
            useFullName
            showUnknownCountryFlag
          ></GCountryFlag>
        </div>
      )
    },
    renderHighestPhase(devStatus?: IBaseDictItem) {
      if (devStatus) {
        const devStatusId = devStatus.dict_id
        const devStatusIdIndex = DRUG_DEV_STATUS_IDS.findIndex((i) => i === devStatusId)
        const maxWidth = this.highestPhaseColumnWidth - cellPadding * 2 - stopIconSize
        const phaseWidth = ((devStatusIdIndex + 1) / DRUG_DEV_STATUS_IDS.length) * maxWidth
        return (
          <div class="relative h-6" style={{ 'min-width': `${phaseWidth}px` }}>
            <div class="absolute top-0 left-0 h-full bg-blue-light rounded" style={{ width: `${phaseWidth}px` }}></div>
            <div class="relative ml-1 text-blue-clicked font-semibold text-xs leading-6">{this.isCN ? devStatus.name_cn : devStatus.name_en}</div>
          </div>
        )
      }
      return EMPTY_PLACEHOLDER
    },
    renderInactiveTooltip() {
      return (
        <GTooltip theme="light" mode="text">
          <div class="flex items-start">
            <PtIcon class="cursor-pointer text-xl text-text-t2" style={{ fontSize: `${stopIconSize}px` }} icon="Stop"></PtIcon>
          </div>
          <div slot="content">{this.$tc('common.pipelineStop')}</div>
        </GTooltip>
      )
    },
    renderHighestPhaseCell(row: IPipelineDisplayTableData) {
      return (
        <div class="flex items-center">
          {this.renderHighestPhase(row.dev_status_id_view)}
          <div class={[row.inactive ? 'visible' : 'invisible', 'flex-shrink-0']}>{this.renderInactiveTooltip()}</div>
        </div>
      )
    },
  },
  render() {
    return (
      <ElTable
        header-cell-style={{ padding: `${cellPadding}px` }}
        cell-style={{ 'vertical-align': 'top', padding: `${cellPadding}px` }}
        border={true}
        data={this.flattenTableData}
        spanMethod={this.mergeTableCellFun}
        class={$classes.table}
      >
        {this.showDrugColumn && (
          <ElTableColumn
            prop="drug"
            width="200"
            label={this.$tc('drugAndTarget')}
            scopedSlots={{
              default: ({ row }: { row: IPipelineDisplayTableData }) => this.renderDrugCell(row),
            }}
          ></ElTableColumn>
        )}
        <ElTableColumn
          prop="indication"
          width="300"
          label={this.$tc('common.indication')}
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData }) => this.renderIndicationCell(row),
          }}
        ></ElTableColumn>
        <ElTableColumn
          prop="location"
          width="200"
          label={this.$tc('common.country&location')}
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData; $index: number }) => {
              return this.renderCountryCell(row)
            },
          }}
        ></ElTableColumn>
        <ElTableColumn
          prop="highestPhase"
          width={this.highestPhaseColumnWidth}
          label={this.$tc('common.highestPhase')}
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData }) => this.renderHighestPhaseCell(row),
          }}
        ></ElTableColumn>
        <ElTableColumn
          prop="activeOrganizations"
          label={this.$tc('common.organization')}
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData }) =>
              row.org_id_view ? (
                <div class="h-6">
                  <BOrganizationItem data={{ ...(row.org_id_view || {}) }} isSignature={true}></BOrganizationItem>
                </div>
              ) : (
                EMPTY_PLACEHOLDER
              ),
          }}
        ></ElTableColumn>
        <ElTableColumn
          prop="updateTime"
          label={this.$tc('updateTime')}
          width="120"
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData }) =>
              formatTimestamp(row.dev_status_earliest_time, {
                locale: this.localeUpcase,
              }),
          }}
        ></ElTableColumn>
        <ElTableColumn
          prop="dataSource"
          label={this.$tc('dataSource')}
          scopedSlots={{
            default: ({ row }: { row: IPipelineDisplayTableData }) => {
              if (row.data_source?.length) {
                return <BDevStatusDataSource source={row.data_source} limitInline={true}></BDevStatusDataSource>
              }
              return EMPTY_PLACEHOLDER
            },
          }}
        ></ElTableColumn>
        <div slot="empty" style="height: 400px">
          <GEmpty size={E_NODATA_SIZE.MIDDLE}></GEmpty>
        </div>
      </ElTable>
    )
  },
})
