import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { exportChartImage } from '@pharmsnap/shared/utils'
import { ref } from '@vue/composition-api'
import type { BAnalysisEChartIns } from '../components/chart/BAnalysisEchart'

export function useAnalysisEchartsHelpers() {
  const analysisChartRef = ref<BAnalysisEChartIns>()
  const {
    state: { userInfo },
  } = useAuthStore()

  const { isCN } = useLocale()

  function exportChartImg(title: string) {
    if (!analysisChartRef.value) return
    const chartIns = analysisChartRef.value.getEchartIns()
    if (!chartIns) return
    exportChartImage(chartIns, title, isCN.value, {
      userId: userInfo.value?.user_id || '',
    })
  }

  return {
    exportChartImg,
    analysisChartRef,
  }
}

export function useAnalysisChartHelpers() {
  const analysisChartRef = ref<Omit<ReturnType<typeof useAnalysisEchartsHelpers>, 'analysisChartRef'>>()

  // 接受一个函数的名称，返回一个函数，先判断analysisChartRef.value是存在，如果存在就调用它所对应函数名的函数，不存在return
  function wrapper(fnName: keyof Omit<ReturnType<typeof useAnalysisEchartsHelpers>, 'analysisChartRef'>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (...args: any[]) => {
      if (!analysisChartRef.value) return
      const fn = analysisChartRef.value[fnName]
      if (typeof fn !== 'function') return
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fn(...args)
    }
  }

  return {
    exportChartImg: wrapper('exportChartImg'),
    analysisChartRef,
  }
}
