import Vue, { CreateElement, RenderContext } from 'vue'
import { IFilterDisplayItem } from '../../../types'
import $style from './FilterValueTag.module.scss'
import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { GIcon } from '@pharmsnap/shared/components'
import { noop, pick } from 'lodash'
import { I18nLang } from '@pharmsnap/shared/i18n'
export const FilterValueTag = Vue.extend({
  functional: true,
  name: 'FilterValueTag',
  render(
    h: CreateElement,
    ctx: RenderContext<{
      data: IFilterDisplayItem
    }>
  ) {
    const { data } = ctx.props
    const parent = ctx.parent
    const locale = parent.$i18n.locale as I18nLang
    const onClickClose = (ctx.listeners.clickClose as (data: IFilterDisplayItem) => void) || noop
    return (
      <span class={$style.main} {...pick(ctx.data, ['class', 'style'])}>
        <span>
          <span class="font-black">{locale === 'cn' ? data.field_label_cn : data.field_label_en} : </span>
          {locale === 'cn' ? data.value_label_cn || data.value_label_en : data.value_label_en || data.value_label_cn}
        </span>
        <GIcon class="cursor-pointer" nativeOnClick={() => onClickClose(data)} size={20} svgName="SolidCloseBig"></GIcon>
      </span>
    )
  },
})
