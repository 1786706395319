import { IFinancialData, IFinancialOverviewData, IGetFinancialParams } from '@pharmsnap/shared/types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class FinancialApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getFinancialList(params: IGetFinancialParams): Promise<IHttpWrap<{ detail: IFinancialData[]; facet_entity_id: string }>> {
    return await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/organization/financial/search'),
      method: 'POST',
      data: params,
      cache: true,
    })
  }
  async getFinancialOverviewData(params: IGetFinancialParams): Promise<IHttpWrap<IFinancialOverviewData>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/organization/financial/overview`),
      method: 'POST',
      data: params,
      cache: true,
    })
  }
}
