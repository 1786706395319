import { BDiseaseItemWithToolTip } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDiseaseWithItemInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BDiseaseWithToolTipFetchListCard = defineComponent({
  name: 'BDiseaseWithToolTipFetchListCard',
  props: {
    data: {
      type: Array as PropType<IBaseDiseaseWithItemInfo[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const ids = props.data.map((o) => o.disease_id)
    const { loading, data: diseaseData } = useRequest(
      {
        requestFn: sharedCtx.service.disease.getBatchDisease.bind(sharedCtx.service.disease),
      },
      ids
    )
    watch(loading, () => {
      ctx.emit('update')
    })

    const combineData = computed(() => {
      return props.data.map((item) => {
        const view = diseaseData.value?.find((o) => o.disease_id === item.disease_id)
        return {
          ...item,
          disease_id_view: view,
        }
      })
    })

    return {
      loading,
      combineData,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {this.combineData.map((item) => {
            return <BDiseaseItemWithToolTip class="mr-1 mb-1" data={item}></BDiseaseItemWithToolTip>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
