import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import '@patsnap-ui/icon/assets/solid/tick.svg'
import { alertEntityConfig, alertRefineEntityConfig, alertType2AlertEntityTypeMap, topEventConfigMap } from '@patsnap/synapse_common_config'
import {
  E_QUERY_ITEM_CONDITION,
  I18nLang,
  IAlertRefineEntity,
  IAlertTopEventSettingType,
  IAlertType,
  IAutoCompleteType,
  ICreateUpdateAlertParams,
} from '@patsnap/synapse_common_interface'
import { BAutoEntity, GIcon, GTooltip } from '@pharmsnap/shared/src/components'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { ElPopover, ElTable, ElTableColumn } from '@pharmsnap/shared/src/element-ui'
import { IListItem } from '@pharmsnap/shared/src/types'
import {
  convertAcType2AlertEventRefineFilterType,
  convertAlertEventRefineFilterType2AcType,
} from '@pharmsnap/shared/src/utils/business/emailAlert/email-alert-v2'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { autoCompleteTypeLangMap } from '../../config'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'
import $classes from './BRefineEntityForm.module.scss'
interface IRefineItemModel {
  type: IAutoCompleteType
  showSelect: boolean
  logicVal: E_QUERY_ITEM_CONDITION
  values: IListItem[]
}
export const BRefineEntityForm = defineComponent({
  name: 'BRefineEntityForm',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    alertConfig: {
      required: true,
      type: Object as PropType<Partial<ICreateUpdateAlertParams>>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { tsLangMap, locale } = useLocale()
    const alertType = computed(() => {
      return props.alertConfig?.alert_type as IAlertType
    })
    const searchResultEntityType = computed(() => {
      return alertType2AlertEntityTypeMap[alertType.value]
    })
    const searchResultEntityName = computed(() => {
      return tsLangMap(alertEntityConfig[searchResultEntityType.value])
    })
    const refineItemsModel = ref<IRefineItemModel[]>([])

    const shouldShowAddRefineBtn = computed(() => {
      return refineItemsModel.value.length < 4
    })

    const autoCompleteKeys = computed<IAutoCompleteType[]>(() => {
      const isOrgRelatedAlertTypes: IAlertType[] = ['drug_search_group_by_organization', 'organization_detail']
      const isTargetRelatedAlertTypes: IAlertType[] = ['drug_search_group_by_target', 'target_detail']
      const isDrugTypeRelatedAlertTypes: IAlertType[] = ['drug_search_group_by_drug_type', 'drug_type_detail']
      const isDiseaseRelatedAlertTypes: IAlertType[] = ['drug_search_group_by_disease', 'disease_detail']
      if (isOrgRelatedAlertTypes.includes(alertType.value)) return ['DrugType', 'Target', 'Disease', 'Mechanism']
      if (isTargetRelatedAlertTypes.includes(alertType.value)) return ['DrugType', 'Organization', 'Disease', 'Mechanism']
      if (isDrugTypeRelatedAlertTypes.includes(alertType.value)) return ['Target', 'Organization', 'Disease', 'Mechanism']
      if (isDiseaseRelatedAlertTypes.includes(alertType.value)) return ['DrugType', 'Organization', 'Target', 'Mechanism']
      return []
    })

    const allTopEventTableData = computed(() => {
      const eventFilterTableData: Array<Partial<Record<IAutoCompleteType | 'eventName', boolean | IAlertTopEventSettingType>>> = [
        {
          eventName: 'drug_dev_status',
          DrugType: true,
          Organization: true,
          Disease: true,
          Mechanism: true,
          Target: true,
        },
        {
          eventName: 'special_approval',
          DrugType: true,
          Organization: true,
          Disease: true,
          Mechanism: true,
          Target: true,
        },
        {
          eventName: 'clinical_trial',
          DrugType: true,
          Organization: true,
          Disease: true,
          Mechanism: true,
          Target: true,
        },
        {
          eventName: 'drug_deal',
          DrugType: true,
          Organization: true,
          Disease: false,
          Mechanism: true,
          Target: true,
        },
        {
          eventName: 'patent',
          DrugType: false,
          Organization: true,
          Disease: true,
          Mechanism: false,
          Target: true,
        },
        {
          eventName: 'paper',
          DrugType: true,
          Organization: true,
          Disease: true,
          Mechanism: false,
          Target: true,
        },
        {
          eventName: 'translational_medicine',
          DrugType: true,
          Organization: true,
          Disease: true,
          Mechanism: true,
          Target: true,
        },
        {
          eventName: 'news',
          DrugType: false,
          Organization: true,
          Disease: true,
          Mechanism: false,
          Target: true,
        },
        {
          eventName: 'deal',
          DrugType: false,
          Organization: false,
          Disease: false,
          Mechanism: false,
          Target: false,
        },
      ]

      return eventFilterTableData
    })

    const showLogicTagAutoCompleteKeys: IAutoCompleteType[] = ['Target']

    const refineDropdownOptions = computed(() => {
      return autoCompleteKeys.value.reduce(
        (acc, curr) => {
          const langConfig = autoCompleteTypeLangMap[curr]
          if (!langConfig) {
            console.error('未找到自动完成类型的语言配置:', curr)
            return acc
          }
          acc.push({
            type: curr,
            label: tsLangMap(langConfig),
          })
          return acc
        },
        [] as {
          type: IAutoCompleteType
          label: string
        }[]
      )
    })

    watch(
      () => alertType.value,
      () => {
        refineItemsModel.value = []
      }
    )

    function initRefineItemsModel() {
      if (props.alertConfig.refine_condition?.length) {
        const model: IRefineItemModel[] = props.alertConfig.refine_condition.map((item) => {
          return {
            type: convertAlertEventRefineFilterType2AcType(item.data_type),
            showSelect: false,
            logicVal: item.condition,
            values: item.value.map((v) => {
              return {
                id: v.id,
                name_cn: v.display_name_cn,
                name_en: v.display_name_en,
              }
            }),
          }
        })
        refineItemsModel.value = model
      }
    }
    /**
     * 增加一个过滤项
     */
    function addRefineEntityItem() {
      if (props.disabled) {
        return
      }
      const suggestType = autoCompleteKeys.value.find((type) => !refineItemsModel.value.find((item) => item.type === type))
      refineItemsModel.value.push({
        type: suggestType as IAutoCompleteType,
        showSelect: false,
        logicVal: E_QUERY_ITEM_CONDITION.ANY,
        values: [],
      })
    }

    function removeRefineEntityItem(index: number) {
      refineItemsModel.value.splice(index, 1)
    }

    function getRefineCondition() {
      const refineEntity = _getRefineEntity()
      return {
        refine_condition: refineEntity,
        refine_condition_display_name: {
          cn: _getRefineEntityDisplayName(refineEntity, 'cn'),
          en: _getRefineEntityDisplayName(refineEntity, 'en'),
        },
      }
    }

    function getAcTypeName(type?: IAutoCompleteType) {
      if (!type) {
        return ''
      }
      const langConfig = autoCompleteTypeLangMap[type]
      if (!langConfig) {
        console.error(`getAcTypeName: 没有找到${type}的语言配置,请检查`)
        return ''
      }
      return tsLangMap(langConfig)
    }

    function setRefineItemType(type: IAutoCompleteType, index: number) {
      refineItemsModel.value[index].type = type
    }

    function addRefineEntityValue(value: IListItem, index: number) {
      refineItemsModel.value[index].values.push(value)
    }

    function onRemoveRefineEntityValue(value: IListItem, index: number) {
      refineItemsModel.value[index].values = refineItemsModel.value[index].values.filter((v) => v.id !== value.id)
    }

    function onKeyDown(e: KeyboardEvent, index: number) {
      const { key: keyboardKey } = e
      if (keyboardKey === 'Backspace') {
        if ((e.target as HTMLInputElement).value.length === 0) {
          refineItemsModel.value[index].values.pop()
        }
      }
    }

    function _getRefineEntity(): IAlertRefineEntity[] {
      const filteredRefineItems = refineItemsModel.value.filter((i) => i.type && i.values.length)
      return filteredRefineItems.map((item) => {
        if (!item.type) {
          throw new Error("refine item's type is undefined")
        }
        return {
          data_type: convertAcType2AlertEventRefineFilterType(item.type),
          condition: item.logicVal,
          value: item.values.map((item) => {
            return {
              display_name_cn: item.name_cn || item.name_en || '',
              display_name_en: item.name_en || '',
              id: item.id,
            }
          }),
        }
      })
    }

    function _getRefineEntityDisplayName(entities: IAlertRefineEntity[], lang: I18nLang): string {
      return entities
        .map((entity) => {
          const { data_type, value } = entity
          const entityTypeConfig = alertRefineEntityConfig[data_type]
          const dataTypeText = entityTypeConfig[lang]
          const logicText = entity.condition === E_QUERY_ITEM_CONDITION.ANY ? 'OR' : 'AND'
          const valueText = value.map((v) => (lang === 'cn' ? v.display_name_cn || v.display_name_en : v.display_name_en)).join(` ${logicText} `)
          const groupValue = value.length > 1 ? `(${valueText})` : valueText
          return `${dataTypeText}: ${groupValue}`
        })
        .join(' AND ')
    }

    return {
      locale,
      shouldShowAddRefineBtn,
      refineItemsModel,
      refineDropdownOptions,
      showLogicTagAutoCompleteKeys,
      searchResultEntityName,
      initRefineItemsModel,
      addRefineEntityItem,
      removeRefineEntityItem,
      addRefineEntityValue,
      onKeyDown,
      onRemoveRefineEntityValue,
      setRefineItemType,
      getAcTypeName,
      getRefineCondition,
      allTopEventTableData,
      autoCompleteKeys,
    }
  },
  methods: {
    renderTooltipTable() {
      return (
        <ElTable data={this.allTopEventTableData}>
          <ElTableColumn
            label={this.$t('BEmailAlert.eventType')}
            scopedSlots={{
              default: ({ row }: { row: { eventName: IAlertTopEventSettingType } }) => {
                return this.locale === 'cn' ? topEventConfigMap[row.eventName].cn : topEventConfigMap[row.eventName].en
              },
            }}
          ></ElTableColumn>
          <ElTableColumn label={this.$t('BEmailAlert.BRefineEntityForm.refineTitle', { type: this.searchResultEntityName })}>
            {this.refineDropdownOptions.map((item) => (
              <ElTableColumn
                key={item.type}
                label={item.label}
                scopedSlots={{
                  default: ({ row }: { row: Record<IAutoCompleteType, boolean> }) => {
                    return row[item.type] ? <GIcon svgName="SolidTick" size={20}></GIcon> : <GIcon svgName="SolidCloseBig" size={20}></GIcon>
                  },
                }}
              ></ElTableColumn>
            ))}
          </ElTableColumn>
        </ElTable>
      )
    },
  },
  render() {
    return (
      <div>
        <div class="space-y-2">
          {this.refineItemsModel.map((refineItem, refineItemIndex) => {
            const label = this.getAcTypeName(refineItem.type)
            return (
              <div class="flex items-center group text-sm">
                <ElPopover
                  v-model={this.refineItemsModel[refineItemIndex].showSelect}
                  placement="bottom"
                  trigger="click"
                  appendToBody
                  class={['self-stretch']}
                  popperClass={$classes.dropdownPopover}
                >
                  <div
                    slot="reference"
                    class={[
                      'inline-flex justify-between cursor-pointer items-center border border-text-t4 border-r-0 px-3 w-[120px] h-[32px] rounded-s-md',
                    ]}
                  >
                    <div class="truncate">{label}</div>
                    <GIcon
                      svgName={this.refineItemsModel[refineItemIndex].showSelect ? 'SolidDropdownClose' : 'SolidDropdownOpen'}
                      useSvgDefaultColor
                      size={24}
                    ></GIcon>
                  </div>
                  {this.refineDropdownOptions.map((o) => {
                    const disable = this.refineItemsModel.map((v) => v.type).includes(o.type) && o.type !== refineItem.type
                    return (
                      <div
                        class={[
                          'h-7 leading-7 py-1 px-2',
                          disable ? 'text-text-t3 cursor-not-allowed' : 'cursor-pointer',
                          {
                            'text-blue-default': label === o.label,
                          },
                        ]}
                        onClick={() => {
                          if (disable) {
                            return
                          }
                          this.setRefineItemType(o.type, refineItemIndex)
                        }}
                      >
                        {o.label}
                      </div>
                    )
                  })}
                </ElPopover>
                <BAutoEntity
                  type={refineItem.type}
                  key={refineItem.type}
                  tags={refineItem.values}
                  class={[$classes.autoEntityPopper]}
                  showLogicTag={this.showLogicTagAutoCompleteKeys.includes(refineItem.type)}
                  logicVal={refineItem.logicVal}
                  style="margin-bottom: 0"
                  tagsLimit={5}
                  showLimitNum
                  customLimitMsg={this.$tc('common.limitMsg', undefined, { count: '5' })}
                  emptyTip={this.$tc('common.noMatchedResults')}
                  recommendTip={this.$tc('common.recommendTip')}
                  placeHolder={this.$tc('BEmailAlert.BRefineEntityForm.acPlaceholder')}
                  onSelect={(type: IAutoCompleteType, val: IListItem) => this.addRefineEntityValue(val, refineItemIndex)}
                  onChangeLogicVal={(val: E_QUERY_ITEM_CONDITION) => {
                    refineItem.logicVal = val
                  }}
                  onClose={(_type: IAutoCompleteType, val: IListItem) => this.onRemoveRefineEntityValue(val, refineItemIndex)}
                  onKeyDown={(e: KeyboardEvent) => this.onKeyDown(e, refineItemIndex)}
                ></BAutoEntity>
                <div
                  class="bg-gray-30 p-1 rounded hover:bg-gray-40 ml-2 invisible group-hover:visible cursor-pointer"
                  onClick={() => this.removeRefineEntityItem(refineItemIndex)}
                >
                  <GIcon svgName="SolidDelete" class="text-text-t2" size={24}></GIcon>
                </div>
              </div>
            )
          })}
        </div>
        {this.shouldShowAddRefineBtn ? (
          <div>
            <div
              class={[
                'inline-flex items-center mt-2 text-sm  py-[2px] px-1 border border-text-t4 rounded',
                this.disabled ? 'cursor-not-allowed text-gray-60' : 'cursor-pointer',
              ]}
              onClick={() => !this.shouldShowAlertWarning && this.addRefineEntityItem()}
            >
              <GIcon svgName="SolidAddMedium" class="text-xl" />
              <div>{this.$t('BEmailAlert.BRefineEntityForm.addRefine')}</div>
            </div>
          </div>
        ) : null}
        <div class="mt-2 text-sm flex items-center text-text-t2 leading-6">
          <div style="word-break: break-word">{this.$t('BEmailAlert.BRefineEntityForm.refineTip', { type: this.searchResultEntityName })}</div>
          {this.allTopEventTableData.length ? (
            <GTooltip popperClass={$classes.eventFilterTableTooltip} theme="light" placement="right-start">
              <GIcon svgName="SolidInfo" size={24} class=" text-text-t4 cursor-pointer"></GIcon>
              <template slot="content">{this.renderTooltipTable()}</template>
            </GTooltip>
          ) : (
            <GIcon svgName="SolidInfo" size={24} class=" text-text-t4"></GIcon>
          )}
        </div>
      </div>
    )
  },
})
