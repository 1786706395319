import { GFoldingContainer } from '@pharmsnap/shared/components/ui/GFoldingContainer/GFoldingContainer'
import { defineComponent, unref } from '@vue/composition-api'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterItemStateTreeNodeNext } from '../type'

export const BFilterAdvanceSelectionPanel = defineComponent({
  name: 'BFilterAdvanceSelectionPanel',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    handleFoldingChange(val: boolean, data: IFilterItemStateTreeNodeNext) {
      data._base.isExpand = val
    },
    async handleSelectChange(data: IFilterItemStateTreeNodeNext) {
      await this.filterService.selectFilterItem(data)
    },
    renderFilterItemTreeNode(data: IFilterItemStateTreeNodeNext) {
      const { label, hasChildren, _base, children, depth } = data

      const activeIdentity = unref(this.filterService.selectedState)?.identity

      if (hasChildren) {
        return (
          <GFoldingContainer
            truncate
            headerCls="px-2 text-sm rounded h-10 leading-10 hover:bg-gray-40 cursor-pointer"
            labelStyle={`margin-left: ${depth * 8}px`}
            label={label}
            layout="loose"
            opened={_base.isExpand}
            onChange={(val: boolean) => this.handleFoldingChange(val, data)}
          >
            <template slot="default">{children?.map((item) => this.renderFilterItemTreeNode(item))}</template>
          </GFoldingContainer>
        )
      }

      return (
        <div
          onClick={() => this.handleSelectChange(data)}
          class={[
            'cursor-pointer px-2 text-sm rounded h-10 leading-10 hover:bg-gray-40 truncate',
            { 'bg-gray-30': data.identity === activeIdentity },
          ]}
          title={label}
        >
          <span style={{ marginLeft: `${depth * 8}px` }}>{label}</span>
        </div>
      )
    },
    renderFilterItemTree() {
      const service = this.filterService

      const tree = service.stateTree

      return tree.map((item) => this.renderFilterItemTreeNode(item))
    },
  },
  render() {
    return <div>{this.renderFilterItemTree()}</div>
  },
})
