import '@patsnap-ui/icon/assets/solid/SynapseDisease.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDiseaseWithItemInfo } from '@pharmsnap/shared/types'
import { getDictItemName, getOrganizationName, getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GLink } from '../../ui/GLink/GLink'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BDiseasePopperCard } from '../card/BDiseaseCard/BDiseasePopperCard'
import { BEntityTag } from '../tag/BEntityTag/BEntityTag'

export const BDiseaseItemWithToolTip = defineComponent({
  name: 'BDiseaseItemWithToolTip',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseDiseaseWithItemInfo>,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { data } = toRefs(props)
    const { disease_id_view } = data.value
    const { isCN, localeUpcase, locale } = useLocale()
    const href = computed(() => sharedCtx.router.generatorDiseasePath(data?.value?.disease_id || ''))
    const name = computed(() =>
      getSpecialLang({ data: disease_id_view?.disease_name || [], field: 'name', lang: localeUpcase.value, isDegraded: false })
    )

    return {
      href,
      name,
      isCN,
      localeUpcase,
      locale,
    }
  },
  methods: {
    renderContent(item: IBaseDiseaseWithItemInfo) {
      return item.detail?.length ? (
        <div>
          {item.detail.map((d) => {
            const orgName = getOrganizationName(d.org_id_view || {}, this.locale) || ''
            const drugName = d.drug_id_view ? getSpecialLang({ data: d.drug_id_view.drug_name, field: 'name', lang: this.localeUpcase }) : ''
            const phaseName = d.dev_status_view ? getDictItemName(d.dev_status_view, this.localeUpcase) : ''
            const displayLabel = [orgName, drugName, phaseName].filter((o) => o).join(' | ')
            return <div>{displayLabel}</div>
          })}
        </div>
      ) : null
    },
    renderToolTip(item: IBaseDiseaseWithItemInfo) {
      if (!item.detail?.length) return
      const validLength = item.detail.filter((d) => d.drug_id_view || d.org_id_view).length
      if (!validLength) return
      return (
        <GTooltip class="flex-shrink-0" theme="light" mode="icon" placement="bottom">
          <template slot="content">{this.renderContent(item)}</template>
        </GTooltip>
      )
    },
  },
  render() {
    const id = this.data.disease_id
    const content = (
      <BEntityTag data-testid="b-disease-item-with-tooltip">
        {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="SolidSynapseDisease" /> : null}
        <GLink href={this.href} name={this.name}></GLink>
      </BEntityTag>
    )
    if (id && this.popover) {
      return (
        <div class="flex items-center bg-gray-30 rounded max-w-full">
          <BDiseasePopperCard id={id}>{content}</BDiseasePopperCard>
          {this.renderToolTip(this.data)}
        </div>
      )
    }
    return content
  },
})
