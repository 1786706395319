import type {
  EmailAlertBeFrequency,
  EmailAlertType,
  IAlertDetail,
  IAlertType,
  IAddOrUpdateEmailAlert as ICommonAddOrUpdateEmailAlert,
  IQuery,
} from '@patsnap/synapse_common_interface'

export type AlertFeFrequency = 'M' | 'D' | 'W'
export type LayoutDirection = 'row' | 'column'

type GroupEmailItem = {
  group_id: string
  name: string
  total: string
}

export type EmailItem = {
  isValid: boolean
  label: string
  type: 'group' | 'email'
  value: string
}

type EmailList = {
  email: { email: string }[]
  group: GroupEmailItem[]
}

/**
 * M(月) D(日) W(周)
 */
export type EmailAlertFeFrequency = {
  frequency: AlertFeFrequency
  date?: number
}

export interface IUseEmailAlertParams {
  layout: LayoutDirection
  baseData: EmailAlertBaseData
  onClose(): void
  onSubmit(): void
  alertType: IAlertType
}

/**
 * core emailAlert组件内会抛出来的error code
 */
export enum E_ErrorCode {
  'NotHasEmail' = 'notHasEmail',
  'EmailNotCorrect' = 'emailNotCorrect',
  'SentToOtherUnchecked' = 'SentToOtherUnchecked',
}

/**
 * core email alert组件的service定义
 */
export interface IEmailAlertService extends IUseEmailAlertParams {
  onDataChange(err: E_ErrorCode | null, data?: IDrugAlertParams | ConditionSlotData | ITargetAlertParams | IOrganizationAlertParams): void
  getEmailList(keywords: string): Promise<EmailList>
  selfEmail?: string
  onValidationFailed(type: string): void
  onToastError(params: { message: string }): void
  setSendToMeStatus(val: boolean): void
  setAlertNoUpdateStatus(val: boolean): void
  setSendToOtherStatus(val: boolean): void
  onEmailUpperLimit(): void
  slotSubData?: IDrugAlertParams | ConditionSlotData | ITargetAlertParams | IOrganizationAlertParams
  filterFreqType: AlertFeFrequency
  selectedEmails: EmailItem[]
  sendToMe: boolean
  shouldSubmit?: boolean
  alertNoUpdate: boolean
  sendToOther: boolean
  alertFrequency: EmailAlertFeFrequency
  titleLayout?: LayoutDirection
  emailLimitNum: number
  slotDataError?: string
}

export type EmailAlertBaseData = {
  receptor?: string[]
  frequency?: EmailAlertBeFrequency
  always_alert?: boolean
  email_to_personal?: boolean
}

export interface IDrugAlertParams extends DrugSlotData {
  event_type: IDrugEventTypeRes
}

export interface ITargetAlertParams extends TargetSlotData {
  event_type: ITargetEventTypeRes
}

export interface IOrganizationAlertParams extends OrganizationSlotData {
  event_type: IOrganizationEventTypeRes
}

export type SlotType = 'DRUG_DETAIL' | 'DRUG_SEARCH' | 'TARGET' | 'ORGANIZATION'

export interface ICommonSlotData {
  name_en: string
  name_cn: string
  id: string
}

/**
 * 药物entity的form自定义数据
 */
export type DrugSlotData = ICommonSlotData & {
  type: 'drug_detail'
}

/**
 * 药物query的form自定义数据
 */
export type ConditionSlotData = {
  type: 'drug_search'
  title: string
  condition: IQuery
  displayQuery: {
    must: string
    filter: string
  }
  id: string
}

/**
 * 靶点entity的form自定义数据
 */
export type TargetSlotData = ICommonSlotData & {
  type: 'target'
}

export type OrganizationSlotData = ICommonSlotData & {
  type: 'organization'
  name_cn_translation?: string
  is_china?: boolean
}

export type EmailAlertSlotData = DrugSlotData | ConditionSlotData | TargetSlotData | OrganizationSlotData

export type DrugEventKey = 'documents' | 'drug_dev_status' | 'drug_clinical_trials'

export type IDrugEventTypeRes = Record<DrugEventKey, string[] | boolean>

export type TargetEventKey = 'active_drugs_dev_status' | 'inactive_drugs_dev_status' | 'active_indications' | 'active_organizations' | 'documents'

export type ITargetEventTypeRes = Record<TargetEventKey, string[] | boolean>

export type OrganizationEventKey = Extract<TargetEventKey, 'active_drugs_dev_status' | 'inactive_drugs_dev_status' | 'documents'> | 'active_targets'

export type IOrganizationEventTypeRes = Record<OrganizationEventKey, string[] | boolean>

export interface IEventTypeItem {
  key: string
  value: string
  display_name_en: string
  display_name_cn: string
  children?: IEventTypeItem[]
}

/**
 * 新增、编辑、搜索 返回数据item
 */
export interface IAddOrUpdateEmailAlert extends ICommonAddOrUpdateEmailAlert {
  event_type?: IDrugEventTypeRes | ITargetEventTypeRes | IOrganizationEventTypeRes
}

export interface ICheckEmailAlertExist {
  source_unique_id: string
  alert_type: Exclude<EmailAlertType, 'ALL'>
}

export interface IEmailAlertRes {
  exist: boolean
  item: IAddOrUpdateEmailAlert
  total?: number
}
export interface IEmailAlertResV2 {
  exist: boolean
  item?: IAlertDetail
  total?: number
}

export interface IEmailAlertResList {
  items: IAddOrUpdateEmailAlert[]
  total?: number
}

export interface IDeleteEmailAlertResult {
  result: boolean
}
export interface IUpdateEmailAlertResult {
  result: boolean
}
export interface IDeleteEmailAlertParams {
  alert_ids: string[]
}
/**
 * alert_total用户邮件提醒最大数量
 * alert_current存在的邮件提醒个数
 * receptor_total一封邮件发送给邮箱的最大数量
 * drug_detail药物的邮件提醒数量
 * drug_search检索式的邮件提醒数量
 */
export interface IUserEmailAlertQuota {
  alert_limitation: number
  recipient_limitation: number
}

export interface EmailAlertInfo {
  id: string
  display_name_en?: string
  display_name_cn?: string
}
