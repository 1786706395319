import { defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { GDialog } from '../..'
import $classes from './GDelConfirm.module.scss'

export const GDelConfirm = defineComponent({
  name: 'GDelConfirm',
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    width: {
      type: String,
      default: '400px',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    message: {
      type: String,
      default: '',
      required: true,
    },
    isAsync: {
      type: Boolean,
      default: false,
    },
    closeOnClickCancel: {
      type: Boolean,
      default: true,
    },
    deleteFunc: {
      type: Function as PropType<() => Promise<void>>,
    },
    onClose: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onAfterDelete: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
  },
  setup(props, ctx) {
    const { isAsync } = toRefs(props)
    const delDisabled = ref(false)
    const onClickDelete = async () => {
      if (!props.deleteFunc) return
      if (isAsync.value) {
        delDisabled.value = true
        await props.deleteFunc()
        delDisabled.value = false
      } else {
        props.deleteFunc()
      }
      ctx.emit('input', false)
      ctx.emit('afterDelete')
    }

    return { delDisabled, onClickDelete }
  },
  render() {
    return (
      <GDialog
        width={this.width}
        top="0"
        size="small"
        class={$classes.delConfirm}
        visible={this.visible}
        showCancel={true}
        showConfirm={true}
        confirmType="danger"
        title={this.title}
        showClose={true}
        closeOnClickCancel={this.closeOnClickCancel}
        confirmDisabled={this.delDisabled}
        cancelText={this.$tc('common.cancel')}
        confirmText={this.$tc('common.delete')}
        onInput={(val: boolean) => this.$emit('input', val)}
        onClose={() => this.$emit('close')}
        onCancel={() => this.$emit('cancel')}
        onConfirm={this.onClickDelete}
      >
        {this.$slots.default ? (
          <div class={$classes.delConfirmMsg}>{this.$slots.default}</div>
        ) : (
          <div class={$classes.delConfirmMsg}>{this.message}</div>
        )}
      </GDialog>
    )
  },
})
