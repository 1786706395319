import { IBullsEyeChartDataItem } from '@patsnap/synapse_bullseye_chart'
import { BULLS_EYE_CHART_COLOR, BULLS_EYE_CHART_DRUG_TYPE } from '@patsnap/synapse_common_config'
import { IAggregationData, ITwoDimensionAggValue } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/composition'
import { IBullsEyeChartAggOtherInfo, IBullsEyeChartAngleItemWithOtherInfo, IBullsEyeChartStatInfoCommon } from '@pharmsnap/shared/src/types'
import { getDisplayNameDegraded } from '@pharmsnap/shared/src/utils'
import {
  filterBullsEyeChartData,
  getBullsEyeChartAngleData,
  getBullsEyeChartWeight,
  getMergedDrugTypeList,
  mergeBullsEyeChartInactiveData,
} from '@pharmsnap/shared/src/utils/business/bulls-eye-chart'
import { Ref, computed, ref } from '@vue/composition-api'
import { cloneDeep, sortBy } from 'lodash'

export function useAnalysisBullsEyeChart(data: Ref<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>) {
  const { locale } = useLocale()
  const filterItems = ref<string[]>([])

  const originData = computed(() => {
    const cloneDeepData = cloneDeep(data.value)
    const aggItems = cloneDeepData.aggregation_result?.[0]?.items || []
    if (aggItems.length) {
      aggItems.forEach((item) => {
        if (item.aggregation_result?.[0]?.items) {
          item.aggregation_result[0].items = mergeBullsEyeChartInactiveData(item.aggregation_result[0].items)
        }
      })
    }
    return cloneDeepData
  })

  const bullsEyeChartData = computed<IBullsEyeChartDataItem[]>(() => {
    const oneDimAggData = originData.value.aggregation_result?.[0]
    const aggItems = oneDimAggData?.items || []
    if (aggItems.length) {
      const weightList = getBullsEyeChartWeight(aggItems)
      return aggItems.map((item, index) => {
        const subDimAggData = item.aggregation_result?.[0]
        const subAggItems = subDimAggData?.items || []
        return {
          angleAxisName: getDisplayNameDegraded(item, locale.value) || '',
          angleAxisId: item.key,
          _angleAxisMeta: {
            aggregation_field: oneDimAggData.aggregation_field,
            id: item.key,
            display_name_cn: item.display_name_cn,
            display_name_en: item.display_name_en,
          },
          angleData: getBullsEyeChartAngleData(subAggItems, subDimAggData?.aggregation_field || '', locale.value),
          weight: weightList[index],
        }
      })
    }
    return []
  })

  const filteredBullsEyeChartData = computed<IBullsEyeChartDataItem[]>(() => {
    if (filterItems.value.length) {
      return bullsEyeChartData.value.map((item) => {
        return {
          ...item,
          angleData: filterBullsEyeChartData(filterItems.value, cloneDeep(item.angleData)),
        }
      })
    }
    return bullsEyeChartData.value
  })

  const markerColor = computed(() => {
    const colorList = BULLS_EYE_CHART_COLOR.slice(0, legendData.value.length)

    return legendData.value.map((item, index) => ({
      key: item.id,
      value: colorList[index],
    }))
  })

  const legendData = computed(() => {
    const allDrugType = bullsEyeChartData.value.reduce((prev, cur) => {
      const angleData = cur.angleData as IBullsEyeChartAngleItemWithOtherInfo[]

      const angleDataDrugType = angleData.reduce((prev, cur) => {
        return [...prev, ...(cur.other_info?.stat_info.drug_type.map((item) => item) || [])]
      }, [] as IBullsEyeChartStatInfoCommon[])

      return [...prev, ...angleDataDrugType]
    }, [] as IBullsEyeChartStatInfoCommon[])

    const mergedDrugType = getMergedDrugTypeList(cloneDeep(allDrugType))

    return sortBy(mergedDrugType, (drugType) => BULLS_EYE_CHART_DRUG_TYPE.findIndex((id) => id === drugType.id))
  })

  const handleFilter = (id: string) => {
    if (filterItems.value.includes(id)) {
      filterItems.value = filterItems.value.filter((item) => item !== id)
    } else {
      filterItems.value.push(id)
    }
  }

  return {
    filteredBullsEyeChartData,
    filterItems,
    bullsEyeChartData,
    markerColor,
    legendData,
    handleFilter,
  }
}
