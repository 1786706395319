import { useLocale } from '@pharmsnap/shared/composition'
import { renderLimitedText, renderPopoverLimitedText } from '@pharmsnap/shared/render'
import { ISpecialApprovalItem } from '@pharmsnap/shared/types'
import { getDictItemName, handelCountryAlpha2Code } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { uniqBy } from 'lodash'
export const BRegulation = defineComponent({
  name: 'BRegulation',
  props: {
    regulationData: {
      type: Array as PropType<ISpecialApprovalItem[]>,
      default: () => [],
    },
    display: {
      type: String as PropType<'simple' | 'popover'>,
      default: 'simple',
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const { localeUpcase, isCN } = useLocale()

    const regulations = computed(() => {
      return uniqBy(
        props.regulationData.reduce(
          (acc, curr) => {
            const countryCode = handelCountryAlpha2Code(curr.country_view, isCN.value)
            if (!countryCode) {
              return acc
            }
            if (!curr.special_review_view) {
              return acc
            }
            return [
              ...acc,
              {
                label: `${getDictItemName(curr.special_review_view, localeUpcase.value)} (${countryCode})`,
                value: `${curr.special_review_view.dict_id}_${curr.country_view?.id}`,
              },
            ]
          },
          [] as Array<{
            label: string
            value: string
          }>
        ),
        (item) => item.value
      ).map((item) => item.label)
    })

    return { regulations }
  },

  render() {
    return (
      <div>
        {this.display === 'popover' ? renderPopoverLimitedText(this.regulations, this.limit, true) : renderLimitedText(this.regulations, this.limit)}
      </div>
    )
  },
})
