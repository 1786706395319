/* eslint-disable @typescript-eslint/no-explicit-any */
import { toThousands } from '@patsnap/synapse_common_utils'
import { AggOneDimDataItem, AggPieChartEntity } from '@patsnap/synapse_domain'
import { defineComponent, onMounted, PropType, shallowRef, toRefs, unref, watch } from '@vue/composition-api'
import { EChartsOption } from 'echarts'
import { cloneDeep } from 'lodash'
import { useAnalysisChartTooltip } from '../../compositions/useAnalysisChartTooltip'
import { useAnalysisEchartsHelpers } from '../../compositions/useAnalysisEchartsHelpers'
import { BAnalysisEChart } from './BAnalysisEchart'

type AnalysisPieEchartTooltipData = {
  seriesName: string
  categoryName: string
  color: string
  count: string
  percent: string
}

export const BAnalysisPieEchart = defineComponent({
  name: 'BAnalysisPieEchart',
  props: {
    entity: {
      type: Object as PropType<AggPieChartEntity>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { entity } = toRefs(props)

    const { tooltipRef, tooltipCommonConfig, tooltipActiveData, tooltipFormatter, resetTooltipDataMap } =
      useAnalysisChartTooltip<AnalysisPieEchartTooltipData>({
        pickTooltipDataFromChartParam,
      })

    const realOption = shallowRef<EChartsOption>()

    function updateRealOption() {
      const option = cloneDeep(unref(entity).getEchartsOptions())
      if (option) {
        option.tooltip = {
          show: true,
          triggerOn: 'mousemove|click',
          axisPointer: {
            shadowStyle: { color: '#96a0af', opacity: 0.1 },
            z: -1,
          },
          enterable: false,
          hideDelay: 500,
          formatter: tooltipFormatter,
          ...tooltipCommonConfig,
        }
        option.animation = false
      }
      realOption.value = option
    }

    function pickTooltipDataFromChartParam(params: any) {
      const seriesName = params.seriesName
      if (!seriesName) return
      const value = params.data as AggOneDimDataItem
      if (!value) return
      const info: AnalysisPieEchartTooltipData = {
        seriesName,
        count: toThousands(value.count),
        categoryName: value.name,
        color: params.color,
        percent: `${(value.percent * 100).toFixed(2)}`,
      }
      const key = value.name

      return [key, info] as [string, AnalysisPieEchartTooltipData]
    }

    function handleClickItem(params: any) {
      if (!params || !params.data) return
      emit('clickItem', params.data as AggOneDimDataItem)
    }

    onMounted(() => updateRealOption())

    watch(entity, () => {
      resetTooltipDataMap()
      updateRealOption()
    })

    return {
      tooltipRef,
      realOption,
      tooltipActiveData,
      handleClickItem,
      ...useAnalysisEchartsHelpers(),
    }
  },
  render() {
    return (
      <BAnalysisEChart
        ref="analysisChartRef"
        displayType="pie"
        option={this.realOption}
        axisPopperOption={{ xPopperEnable: false, yPopperEnable: false }}
        {...{
          on: {
            click: this.handleClickItem,
          },
        }}
      >
        <template slot="extra">
          <div ref="tooltipRef">
            <div class="p-2">
              <div class="flex items-center mb-2">
                <span
                  class="flex-shrink-0 inline-block h-[10px] w-[10px] rounded-full mr-2"
                  style={{ backgroundColor: this.tooltipActiveData?.color }}
                ></span>
                <span class="text-xs leading-4 font-bold text-text-t1">{this.tooltipActiveData?.seriesName}</span>
              </div>
              <div class="pl-[18px] mt-2">
                <div class="text-xs leading-4">
                  <span class="text-text-t1">{this.tooltipActiveData?.categoryName}: </span>
                  <span class="text-text-t2">
                    {this.tooltipActiveData?.count} ({this.tooltipActiveData?.percent}%)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </BAnalysisEChart>
    )
  },
})
