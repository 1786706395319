import Vue from 'vue'
import { i18n } from '@pharmsnap/shared/i18n'

function clamp(x: number, min: number, max: number) {
  return Math.min(max, Math.max(min, x))
}
/**
 * 计算基于一个根元素的偏移量
 * Convert Range to global offsets relative to a root
 * @param {Range} range
 * @param {Node} root
 */
export function rangeToGlobalOffset(range: Range, root: Element): number[] {
  return [findGlobalOffset(range.startContainer, range.startOffset, root), findGlobalOffset(range.endContainer, range.endOffset, root)]
}

/**
 * Find text offset for given node and position relative to a root
 * 计算相对一个节点的偏移量
 * @param {Node} node
 * @param {Number} position
 * @param {Node} root
 */
export function findGlobalOffset(node: Node, position: number, root: Element) {
  const walker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ALL)

  let globalPosition = 0
  let nodeReached = false
  let currentNode = walker.nextNode()

  while (currentNode) {
    // Indicates that we at or below desired node
    nodeReached = nodeReached || node === currentNode
    const atTargetNode = node === currentNode || currentNode.contains(node)

    // Stop iteration
    // Break if we passed target node and current node
    // is not target, nor child of a target
    // 遍历到过目标节点且当前遍历的节点不包含目标元素,那么下面就不需要计算了,因为位置信息已经无关紧要了,终止循环
    if (nodeReached && atTargetNode === false) {
      break
    }
    const isText = currentNode.nodeType === Node.TEXT_NODE
    const isBR = currentNode.nodeName === 'BR'

    if (isText || isBR) {
      let length = (currentNode as Text)?.length ?? 1

      if (atTargetNode) {
        length = Math.min(position, length)
      }

      globalPosition += length
    }

    currentNode = walker.nextNode()
  }

  return globalPosition
}
/**
 * Apply classes and styles to a span. Optionally add or remove label
 * @param {HTMLSpanElement} spanNode
 * @param {{classNames?: string[], cssStyles?: {}, label?: string}} param1
 */
function applySpanStyles(spanNode: Element, data: { classNames: string[] }) {
  const { classNames } = data
  if (classNames) {
    spanNode.className = ''
    spanNode.classList.add(...classNames)
  }
}
/**
 * Wrap text node with stylized span
 * @param {Text} node
 * @param {string[]} classNames
 * @param {object} cssStyles
 * @param {string} [label]
 */
export function wrapWithSpan(node: Text | Element, classNames: string[]) {
  const highlight = node.ownerDocument.createElement('span')

  highlight.appendChild(node)

  applySpanStyles(highlight, { classNames })

  return highlight
}

export function wrapWithVueComponent(content: string, classNames: string[], component: (content: string) => JSX.Element) {
  const instance = Vue.extend({
    render() {
      return component(content)
    },
  })
  const vm = new instance({ i18n }).$mount()
  applySpanStyles(vm.$el, { classNames })
  return vm.$el
}

/**
 * Takes original range and splits it into multiple text
 * nodes highlighting a part of the text, then replaces
 * original text node with highlighted one
 * @param {Node} container
 * @param {number} startOffset
 * @param {number} endOffset
 * @param {object} cssStyles
 * @param {string[]} classNames
 */
export function highlightRangePart(
  container: Element,
  startOffset: number,
  endOffset: number,
  classNames: string[],
  component?: (content: string) => JSX.Element
) {
  let spanHighlight
  const text = container.textContent
  const parent = container.parentNode as Element

  /**
   * In case we're inside another region, move the selection outside
   * to maintain proper nesting of highlight nodes
   * 两个标注完全重叠则只要在外面再嵌套一个高亮span
   */
  if (startOffset === 0 && container.textContent?.length === endOffset && parent?.classList.contains(classNames[0])) {
    const placeholder = container.ownerDocument.createElement('span')
    const parentNode = parent.parentNode

    parentNode?.replaceChild(placeholder, parent)
    spanHighlight = wrapWithSpan(parent, classNames)
    parentNode?.replaceChild(spanHighlight, placeholder)
  } else {
    // Extract text content that matches offsets
    const content = text?.substring(startOffset, endOffset)
    // Create text node that will be highlighted
    const highlitedNode = container.ownerDocument.createTextNode(content || '')

    // Split the container in three parts
    const noseNode = container.cloneNode()
    const tailNode = container.cloneNode()

    // Add all the text BEFORE selection
    noseNode.textContent = text?.substring(0, startOffset) || ''
    tailNode.textContent = text?.substring(endOffset, text.length) || ''

    // To avoid weird dom mutation we assemble replacement
    // beforehands, it allows to replace original node
    // directly without extra work
    const textFragment = container.ownerDocument.createDocumentFragment()

    spanHighlight = component ? wrapWithVueComponent(content as string, classNames, component) : wrapWithSpan(highlitedNode, classNames)

    if (noseNode.textContent.length) textFragment.appendChild(noseNode)
    textFragment.appendChild(spanHighlight)
    if (tailNode.textContent.length) textFragment.appendChild(tailNode)

    // At this point we have three nodes in the tree
    // one of them is our selected range
    parent.replaceChild(textFragment, container)
  }

  return spanHighlight
}
export function findNodesBetween(startNode: Element, endNode: Element, root: Element) {
  // Tree walker creates flat representation of DOM
  // it allows to iterate over nodes more efficiently
  // as we don't need to go up and down on a tree

  // Also we iterate over Text nodes only natively. That's
  // the only type of nodes we need to highlight.
  // No additional checks, long live TreeWalker :)
  const walker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ALL)

  // Flag indicates that we're somwhere between `startNode` and `endNode`
  let inRange = false

  // Here we collect all nodes between start and end
  // including ends
  const nodes = []
  let { currentNode } = walker

  while (currentNode) {
    if (currentNode === startNode) inRange = true
    if (inRange && currentNode.nodeType === Node.TEXT_NODE) nodes.push(currentNode)
    if (inRange && currentNode === endNode) break
    currentNode = walker.nextNode() as Node
  }

  return nodes
}
/**
 * Highlight gien Range
 * @param {Range} range
 * @param {{label: string, classNames: string[]}} param1
 */
export const highlightRange = (
  range: Range,
  data: {
    label: string
    label_id: string
    unique_id: string
    normalized_id?: string
    index: number
    classNames: string[]
    component?: (content: string) => JSX.Element
  }
) => {
  const { classNames, component } = data
  const { startContainer, endContainer, commonAncestorContainer } = range
  const { startOffset, endOffset } = range
  const highlights = []

  /**
   * Wrapper with predefined classNames and cssStyles
   * @param  {[Node, number, number]} args
   */
  const applyStyledHighlight = (container: Element, startOffset: number, endOffset: number) =>
    highlightRangePart(container, startOffset, endOffset, classNames, component)

  // If start and end nodes are equal, we don't need
  // to perform any additional work, just highlighting as is
  if (startContainer === endContainer) {
    highlights.push(applyStyledHighlight(startContainer as Element, startOffset, endOffset))
  } else {
    // When start and end are different we need to find all
    // nodes between as they could contain text nodes
    const nodesToHighlight = findNodesBetween(startContainer as Element, endContainer as Element, commonAncestorContainer as Element)

    // All nodes between start and end should be fully highlighted
    nodesToHighlight.forEach((node) => {
      let start = startOffset
      let end = endOffset

      if (node !== startContainer) start = 0
      if (node !== endContainer) end = node.textContent?.length || 0

      highlights.push(applyStyledHighlight(node as Element, start, end))
    })
  }

  // highlights.forEach((item) => {
  //   item.setAttribute(DATA_NAME__LABEL_ID, label_id)
  //   item.setAttribute(DATA_NAME__LABEL_UNIQUE_ID, unique_id)
  //   if (disable_delete) {
  //     item.setAttribute(DATA_NAME__LABEL_DISABLE_DELETE, '1')
  //   }
  // })

  // const firstLabel = highlights[0]
  // firstLabel.setAttribute(DATA_NAME__LABEL_INDEX, `${index}`)
  return highlights
}

export function createNativeRange(startOffset: number, endOffset: number, rootNode: Element) {
  const { startContainer, endContainer } = findRange(startOffset, endOffset, rootNode)
  if (startContainer && endContainer) {
    const range = document.createRange()
    range.setStart(startContainer.node, startContainer.position)
    range.setEnd(endContainer.node, endContainer.position)
    return range
  }
}
/**
 * Find a startContainer and endContainer by text offsets
 * @param {number} start
 * @param {number} end
 * @param {Node} root
 */
function findRange(start: number, end: number, root: Element) {
  return {
    startContainer: findOnPosition(root, start, 'right'),
    endContainer: findOnPosition(root, end, 'left'),
  }
}
/**
 * Find a node by text offset
 * @param {Node} root
 * @param {number} position
 */
function findOnPosition(root: Element, position: number, borderSide: 'left' | 'right' = 'left') {
  const walker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ALL)

  let lastPosition = 0
  let currentNode = walker.nextNode()
  let nextNode = walker.nextNode()

  while (currentNode) {
    const isText = currentNode.nodeType === Node.TEXT_NODE
    const isBR = currentNode.nodeName === 'BR'

    if (isText || isBR) {
      const length = currentNode.textContent?.length ?? 1

      if (length + lastPosition >= position || !nextNode) {
        if (borderSide === 'right' && length + lastPosition === position && nextNode) {
          return { node: nextNode, position: 0 }
        }
        return {
          node: currentNode,
          position: isBR ? 0 : clamp(position - lastPosition, 0, length),
        }
      } else {
        lastPosition += length
      }
    }

    currentNode = nextNode
    nextNode = walker.nextNode()
  }
}

function filterHtmlOrContainer(str: string, isbool: boolean): string {
  const reg2 = new RegExp('<(img|br|hr|input)[^>]*>', 'gi') //只匹配img、br、hr、input标签

  const reg3 = new RegExp('<(\\S*)[^>]*>[^<]*<\\/(\\1)>', 'gi') //分组匹配，过滤所有的html标签，包括内容

  if (typeof str != 'string') {
    //不是字符串
    return str
  }
  let result = str
  if (!isbool) {
    //先把单标签过滤了
    result = result.replace(reg2, '')
  }
  result = result.replace(reg3, '') //先经过分组匹配，把双标签去除，如果是嵌套标签，则会先将嵌套标签内的双标签过滤掉
  if (reg3.test(result)) {
    //如果为true，则代表还有标签
    return filterHtmlOrContainer(result, true)
  } else {
    return result
  }
}

export function checkTagIsClose(content: string) {
  const result = filterHtmlOrContainer(content, false)
  // 判断是否含有标签;
  if (result && result === content) return result.includes('>') || result.includes('<') || result.includes('/')

  // 如果有剩余的html标签说明是不闭合的
  return result
}
