import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.SEARCH_HISTORY,
  title: createWebTitle('历史记录', 'Search History'),
}

export default [
  {
    path: `search-history`,
    name: E_ROUTER_NAME.SEARCH_HISTORY,
    component: () => import('@pharmsnap/pharmsnap-web/views/searchHistory/SearchHistory'),
    meta,
  },
] as RouteConfig[]
