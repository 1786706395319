import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import '@patsnap-ui/icon/assets/solid/filers.svg'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import $classes from './GFilterItemTrigger.module.scss'

export const GFilterItemTrigger = defineComponent({
  name: 'GFilterItemTrigger',
  props: {
    icon: String,
    label: String,
    title: String,
    width: {
      type: Number,
      default: 200,
    },
    count: {
      type: Number,
      default: 0,
    },
    clearAble: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    activeAble: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
    },
    iconMode: {
      type: String as PropType<'arrow' | 'menu'>,
      default: 'arrow',
    },
    onClear: {
      type: Function as PropType<() => void>,
    },
    onTrigger: {
      type: Function as PropType<() => void>,
    },
  },
  setup(props, context) {
    const { count } = toRefs(props)

    const isChecked = computed(() => count.value > 0)

    const handleClear = (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      context.emit('clear')
    }

    const handleTrigger = (e: Event) => {
      // e.stopPropagation()
      e.preventDefault()
      context.emit('trigger')
    }

    return {
      isChecked,
      handleClear,
      handleTrigger,
    }
  },
  methods: {
    renderContent() {
      return (
        <div class={$classes.gMultiDropdownContent}>
          {this.icon ? <GIcon class={[$classes.gMultiDropdownContentIcon]} svgName={this.icon} size={24}></GIcon> : null}
          <span class={[$classes.gMultiDropdownContentLabel, this.labelClass || '']}>{this.label}</span>
          {this.isChecked ? <span class={[$classes.gMultiDropdownContentSelectedCount]}> ({this.count})</span> : null}
        </div>
      )
    },
    renderArrowAction() {
      return (
        <GIcon
          class={[$classes.gMultiDropdownArrow, this.isActive ? $classes.gMultiDropdownArrowRotate : '']}
          svgName="SolidDropdownOpen"
          size={24}
        ></GIcon>
      )
    },
    renderClearAction() {
      return <GIcon nativeOn={{ click: this.handleClear }} svgName="SolidCloseMedium" size={24}></GIcon>
    },
    renderFilterAction() {
      return <GIcon svgName="SolidFilers" size={24}></GIcon>
    },
    renderAction() {
      return (
        <span class={[$classes.gMultiDropdownAction, 'block']}>
          {this.iconMode === 'arrow'
            ? this.clearAble
              ? this.isChecked
                ? this.isActive
                  ? this.renderArrowAction()
                  : this.renderClearAction()
                : this.renderArrowAction()
              : this.renderArrowAction()
            : null}
          {this.iconMode === 'menu' ? (this.clearAble && this.isChecked ? this.renderClearAction() : this.renderFilterAction()) : null}
        </span>
      )
    },
  },
  render() {
    return (
      <div
        onClick={this.handleTrigger}
        class={[
          $classes.gMultiDropdown,
          this.isActive ? $classes.gMultiDropdownOpen : '',
          this.isChecked && !this.isActive && this.activeAble ? $classes.gMultiDropdownActive : '',
        ]}
        style={{ width: `${this.width}px` }}
      >
        <div class={$classes.gMultiDropdownBd}>{this.renderContent()}</div>
        <div class={$classes.gMultiDropdownFt}>{this.renderAction()}</div>
      </div>
    )
  },
})
