import {
  E_AGGREGATION_TYPE,
  IAggregationData,
  IAggregationItem,
  IAggregationParams,
  IQuery,
  ISearchParams,
  ITwoDimensionAggValue,
} from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { BullsEyeSetting } from '@pharmsnap/shared/src/features/bulls-eye-setting'
import { IBullsEyeChartAggOtherInfo } from '@pharmsnap/shared/src/types'
import {
  getBullsEyeChartPhaseOptions,
  getBullsEyeChartSelectedPhaseIds,
  getSpecifiedPhasesData,
} from '@pharmsnap/shared/src/utils/business/bulls-eye-chart'
import { computed, reactive, ref, shallowRef, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, debounce } from 'lodash'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem, IAnalysisDisplayIconType } from '../../BAnalysisChartNext/type'

export enum E_DRUG_ANALYSIS_CHART {
  HIGHEST_PHASE_BAR = 'HIGHEST_PHASE_BAR',
  ORGANIZATION_STACK_BAR = 'TOP_10_ORGANIZATION',
  ORGANIZATION_BULLS_EYE_CHART = 'TOP_ORGANIZATION_BULLS_EYE_CHART',
  ORGANIZATION_TYPE_PIE = 'ORGANIZATION_TYPE_PIE',
  COUNTRY_HORIZONTAL_BAR = 'COUNTRY_HORIZONTAL_BAR',
  TARGET_STACK_BAR = 'TOP_10_TARGET',
  TARGET_BULLS_EYE_CHART = 'TOP_TARGET_BULLS_EYE_CHART',
  DISEASE_STACK_BAR = 'TOP_10_DISEASE',
  DISEASE_BULLS_EYE_CHART = 'TOP_DISEASE_BULLS_EYE_CHART',
  DRUG_TYPE_STACK_BAR = 'TOP_10_DRUG_TYPE',
  DRUG_TYPE_BULLS_EYE_CHART = 'TOP_DRUG_TYPE_BULLS_EYE_CHART',
}

export type IDrugAggChartModel = {
  display: IAnalysisDisplayIconType
  rollup?: boolean
  dataType?: { selectedValue: 'drug' | 'dev_status' }
  showLabel?: boolean
  phases?: string[]
  top?: string
}

export interface IUseDrugAggChartOptions {
  query?: MaybeRef<IQuery>
  queryType?: MaybeRef<ISearchParams['query_type']>
  structureQuery?: MaybeRef<ISearchParams['structure_query']>
  type: MaybeRef<E_DRUG_ANALYSIS_CHART>
  fullscreenVisible: MaybeRef<boolean>
}

export const barChartTypes: E_DRUG_ANALYSIS_CHART[] = [E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR, E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR]
export const stackBarChartTypes: E_DRUG_ANALYSIS_CHART[] = [
  E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR,
]
export const bullsEyeChartTypes: E_DRUG_ANALYSIS_CHART[] = [
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART,
]
export const pieChartTypes: E_DRUG_ANALYSIS_CHART[] = [E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE]
export const supportRollupControlChartTypes: E_DRUG_ANALYSIS_CHART[] = [
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART,
]
export const supportDateTypeRadioTypes: E_DRUG_ANALYSIS_CHART[] = [E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR]
export const supportTopChartTypes = [...stackBarChartTypes, ...bullsEyeChartTypes]

const aggregationItemRecordType: Partial<Record<E_DRUG_ANALYSIS_CHART, IAggregationItem[]>> = {
  [E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR]: [],
  [E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE]: [{ aggregation_type: E_AGGREGATION_TYPE.TERMS, aggregation_field: 'entity_type' }],
  [E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR]: [{ aggregation_type: E_AGGREGATION_TYPE.TERMS, aggregation_field: 'country_id', limit: 5 }],
}

const emptyAggData: IAggregationData = {
  aggregation_result: [
    { items: [], aggregation_field: '', total: 0 },
    { items: [], aggregation_field: '', total: 0 },
  ],
  total: 0,
}

export function useDrugAggChart(options: IUseDrugAggChartOptions) {
  const { query = { type: 'group', must: [], filter: [] }, type, fullscreenVisible, queryType, structureQuery } = options

  // 存储堆叠图图例选中状态，用于下载图片时使用
  const stackBarChartLegendSelectedStatus = ref<{ [key: string]: boolean }>({})
  const fullScreenStackBarChartLegendSelectedStatus = ref<{ [key: string]: boolean }>({})

  const { locale, isCN, localeUpcase } = useLocale()

  const loading = ref(true)

  const loadingBullEye = ref(true)

  const isEmpty = ref(true)

  const model = reactive(initModel(unref(type)))

  const bullsEyeChartPhaseOptions = computed(() => {
    return getBullsEyeChartPhaseOptions(localeUpcase.value)
  })

  const controls = computed(() => {
    const chartType = unref(type)

    const isFullScreen = unref(fullscreenVisible)

    const controls: IAnalysisChartControlItem<keyof IDrugAggChartModel>[] = []

    if (barChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'vertical-bar', value: 'vertical-bar' },
          { type: 'table', value: 'table' },
        ],
      })

      if (supportDateTypeRadioTypes.includes(chartType)) {
        controls.push({
          type: 'radio',
          bindField: 'dataType',
          items: [
            { type: 'common', value: 'drug', label: unref(locale) === 'cn' ? '药物' : 'Drugs' },
            { type: 'common', value: 'dev_status', label: unref(locale) === 'cn' ? '研发状态' : 'R&D Status' },
          ],
        })
      }
    }

    if (pieChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'pie', value: 'pie' },
          { type: 'table', value: 'table' },
        ],
      })
    }

    if (bullsEyeChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'bull-eye', value: 'bull-eye' },
          { type: 'table', value: 'table' },
        ],
      })
      if (isFullScreen) {
        controls.push({
          type: 'checkbox',
          bindField: 'showLabel',
          name: isCN.value ? '药物名称' : 'Show Label',
          label: isCN.value ? '显示内容' : 'Content Display',
        })

        controls.push({
          type: 'checkbox-group',
          label: isCN.value ? '药物研发阶段' : 'Drug Phases',
          bindField: 'phases',
          options: bullsEyeChartPhaseOptions.value,
          minChecked: 1,
        })
        controls.push({
          type: 'dropdown-single-select',
          bindField: 'top',
          width: 140,
          label: `${categoryName.value}${isCN.value ? '排名' : 'Range'}`,
          options: [
            { name: isCN.value ? '前10' : 'Top 10', id: '10' },
            { name: isCN.value ? '前20' : 'Top 20', id: '20' },
            { name: isCN.value ? '前30' : 'Top 30', id: '30' },
          ],
        })
      } else {
        controls.push({
          type: 'checkbox',
          bindField: 'showLabel',
          name: isCN.value ? '药物名称' : 'Show Label',
        })

        controls.push({
          type: 'dropdown-select',
          bindField: 'phases',
          options: bullsEyeChartPhaseOptions.value,
          title: isCN.value ? '选择药物研发阶段' : 'Select Drug Phases',
          name: isCN.value ? '药物研发阶段' : 'Drug Phases',
          confirmAble: true,
          minChecked: 1,
          width: isCN.value ? 200 : 160,
          placement: 'bottom-end',
        })
        controls.push({
          type: 'dropdown-single-select',
          bindField: 'top',
          width: isCN.value ? 140 : 100,
          options: [
            { name: isCN.value ? '前10' : 'Top 10', id: '10' },
            { name: isCN.value ? '前20' : 'Top 20', id: '20' },
            { name: isCN.value ? '前30' : 'Top 30', id: '30' },
          ],
        })
      }
    }
    if (stackBarChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'horizontal-bar', value: 'horizontal-bar' },
          { type: 'table', value: 'table' },
        ],
      })

      if (isFullScreen) {
        controls.push({
          type: 'dropdown-single-select',
          bindField: 'top',
          width: 140,
          label: `${categoryName.value}${isCN.value ? '排名' : 'Range'}`,
          options: [
            { name: isCN.value ? '前10' : 'Top 10', id: '10' },
            { name: isCN.value ? '前20' : 'Top 20', id: '20' },
            { name: isCN.value ? '前50' : 'Top 50', id: '50' },
            { name: isCN.value ? '前100' : 'Top 100', id: '100' },
          ],
        })
      } else {
        controls.push({
          type: 'dropdown-single-select',
          bindField: 'top',
          width: 140,
          options: [
            { name: isCN.value ? '前10' : 'Top 10', id: '10' },
            { name: isCN.value ? '前20' : 'Top 20', id: '20' },
            { name: isCN.value ? '前50' : 'Top 50', id: '50' },
            { name: isCN.value ? '前100' : 'Top 100', id: '100' },
          ],
        })
      }
    }

    if (supportRollupControlChartTypes.includes(chartType)) {
      if (isFullScreen) {
        controls.push({
          type: 'rollup',
          bindField: 'rollup',
        })
      } else {
        controls.push({
          label: isCN.value ? '机构' : 'Organization',
          type: 'rollup',
          bindField: 'rollup',
        })
      }
    }

    return controls
  })

  const controlLayout = computed(() => {
    const isFullScreen = unref(fullscreenVisible)

    const layoutItems: Array<IAnalysisChartControlLayoutItem<keyof IDrugAggChartModel>> = [
      {
        field: 'display',
        position: 'left',
      },
      {
        field: 'rollup',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'dataType',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'showLabel',
        position: isFullScreen ? 'chart-panel' : 'middle',
      },
      {
        field: 'phases',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'top',
        position: isFullScreen ? 'chart-panel' : 'middle',
      },
    ]

    return layoutItems
  })

  const loadingComputed = computed(() => {
    if (bullsEyeChartTypes.includes(unref(type))) {
      return loadingBullEye.value
    }
    return loading.value
  })

  const isBullEyeEmpty = computed(() => {
    const isEmpty =
      !bullEyeAggData.value.aggregation_result ||
      bullEyeAggData.value.aggregation_result.length === 0 ||
      !bullEyeAggData.value.aggregation_result[0]?.items?.length ||
      bullEyeAggData.value.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
      bullEyeAggData.value.aggregation_result[0]?.items.every(
        (i) =>
          !!i.aggregation_result?.[0] &&
          (!i.aggregation_result?.[0].items ||
            i.aggregation_result?.[0].items.length === 0 ||
            i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
      )
    return isEmpty
  })

  const isEmptyComputed = computed(() => {
    if (bullsEyeChartTypes.includes(unref(type))) {
      return isBullEyeEmpty.value
    }
    return isEmpty.value
  })

  const aggData = shallowRef<IAggregationData>({
    aggregation_result: [
      { items: [], aggregation_field: '', total: 0 },
      { items: [], aggregation_field: '', total: 0 },
    ],
    total: 0,
  })

  const allBullEyeAggData = shallowRef<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>(emptyAggData)

  const bullEyeAggData = shallowRef<IAggregationData<ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>>>(emptyAggData)

  const aggParams = computed(() => {
    const cloneType = unref(type)
    const clonedQuery = cloneDeep(unref(query))
    const clonedAggregation = getAggregationItem(unref(type))
    const clonedQueyType = unref(queryType)
    const clonedStructureQuery = cloneDeep(unref(structureQuery))

    if (stackBarChartTypes.includes(cloneType) || bullsEyeChartTypes.includes(cloneType)) {
      return {
        data_type: 'drug',
        query: clonedQuery,
        aggregate_by_parent: unref(model).rollup === undefined ? true : unref(model).rollup,
        aggregation: clonedAggregation,
        limit: Number(unref(model).top) || 10,
        ...(clonedQueyType === 'STRUCTURE'
          ? {
              query_type: clonedQueyType,
              structure_query: clonedStructureQuery,
            }
          : undefined),
      } as IAggregationParams
    }

    if (supportDateTypeRadioTypes.includes(cloneType)) {
      return {
        data_type: unref(model.dataType)?.selectedValue || 'drug',
        query: clonedQuery,
        aggregation: clonedAggregation,
        ...(clonedQueyType === 'STRUCTURE'
          ? {
              query_type: clonedQueyType,
              structure_query: clonedStructureQuery,
            }
          : undefined),
      } as IAggregationParams
    }

    return {
      data_type: 'drug',
      query: clonedQuery,
      aggregation: clonedAggregation,
      ...(clonedQueyType === 'STRUCTURE'
        ? {
            query_type: clonedQueyType,
            structure_query: clonedStructureQuery,
          }
        : undefined),
    } as IAggregationParams
  })

  const categoryName = computed(() => {
    const chartType = unref(type)
    const dataType = unref(model.dataType)?.selectedValue || 'drug'
    switch (chartType) {
      case E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR:
        return {
          drug: unref(locale) === 'cn' ? '最高研发阶段' : 'Drug Highest Phase',
          dev_status: unref(locale) === 'cn' ? '研发状态（基于适应症）' : 'Phase (By Indication)',
        }[dataType]
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE:
        return unref(locale) === 'cn' ? '机构类型' : 'Organization Type'
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR:
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART:
        return unref(locale) === 'cn' ? '机构' : 'Organization'
      case E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR:
      case E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART:
        return unref(locale) === 'cn' ? '靶点' : 'Target'
      case E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR:
      case E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART:
        return unref(locale) === 'cn' ? '适应症' : 'Disease'
      case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR:
      case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART:
        return unref(locale) === 'cn' ? '药物类型' : 'Drug Type'
      case E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR:
        return unref(locale) === 'cn' ? '国家/地区' : 'Country/Location'
      default:
        return ''
    }
  })

  const countName = computed(() => {
    const chartType = unref(type)
    switch (chartType) {
      case E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR:
        return unref(locale) === 'cn' ? '数量' : 'Count'
      case E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR:
        return unref(locale) === 'cn' ? '机构数量' : 'No. of Orgs'
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE:
        return unref(locale) === 'cn' ? '机构数量' : 'No. of Orgs'
      default:
        return unref(locale) === 'cn' ? '药物数量' : 'No. of Drugs'
    }
  })

  const settingDataPanelTitle = computed(() => {
    const chartType = unref(type)
    if (supportRollupControlChartTypes.includes(chartType)) {
      return unref(locale) === 'cn' ? '机构' : 'Organization'
    }
    return ''
  })

  watch(
    () => model.dataType?.selectedValue,
    () => fetchAggData()
  )

  watch(
    () => model.top,
    (value) => {
      if (value && bullsEyeChartTypes.includes(unref(options.type))) {
        BullsEyeSetting.getSingleton().topN = +value
      }
      fetchAggData(false)
    }
  )

  watch(
    () => model.rollup,
    () => fetchAggData()
  )

  watch(
    () => [model.phases, allBullEyeAggData.value],
    () => {
      if (unref(fullscreenVisible)) {
        return debounceGetDrugBullEyeAggData()
      }
      getDrugBullEyeAggData()
    },
    {
      deep: true,
    }
  )
  watch(
    () => model.phases,
    (value) => {
      if (value?.length && bullsEyeChartTypes.includes(unref(options.type))) {
        BullsEyeSetting.getSingleton().phaseIds = value
      }
    }
  )

  /** 重新获取数据后，清空图例选中状态 */
  function clearStackBarChartLegendSelectedStatus() {
    if (stackBarChartTypes.includes(unref(type))) {
      stackBarChartLegendSelectedStatus.value = {}
      fullScreenStackBarChartLegendSelectedStatus.value = {}
    }
  }

  async function getData() {
    clearStackBarChartLegendSelectedStatus()
    switch (unref(type)) {
      case E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR:
        return sharedCtx.service.drug.getDrugDiseaseHighestPhaseDiseaseList(aggParams.value)
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR:
        return sharedCtx.service.analysis.getTopOrgAggregationByPhase(aggParams.value)
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE:
      case E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR:
        return sharedCtx.service.drug.getDrugDevOrgAggregation(aggParams.value)
      case E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR:
        return sharedCtx.service.analysis.getTopTargetAggregationByPhase(aggParams.value)
      case E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR:
        return sharedCtx.service.analysis.getTopDiseaseAggregationBySearch(aggParams.value)
      case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR:
        return sharedCtx.service.analysis.getTopDrugTypeAggregationByPhase(aggParams.value)
    }
  }

  async function getBullEyeData() {
    const params = {
      ...aggParams.value,
      threshold: 100,
      dev_status: [],
      // 按照研发进度聚合
      sort_by_phase: true,
    }
    switch (unref(type)) {
      case E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART:
        return sharedCtx.service.analysis.getTopOrgBullsEyeChartAggByPhase(params)
      case E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART:
        return sharedCtx.service.analysis.getTopTargetBullsEyeChartAggByPhase(params)
      case E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART:
        return sharedCtx.service.analysis.getTopDiseaseBullsEyeChartAggByPhase(params)
      case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART:
        return sharedCtx.service.analysis.getTopDrugTypeBullsEyeChartAggByPhase(params)
    }
  }

  const getDrugBullEyeAggData = () => {
    bullEyeAggData.value = getSpecifiedPhasesData(allBullEyeAggData.value, model.phases)
  }

  const debounceGetDrugBullEyeAggData = debounce(getDrugBullEyeAggData, 800)

  async function fetchBullEyeData(updatePhase = true) {
    loadingBullEye.value = true
    const rt = await getBullEyeData()

    if (!rt) {
      loadingBullEye.value = false
      allBullEyeAggData.value = emptyAggData
      model.phases = BullsEyeSetting.getSingleton().phaseIds
      return
    }

    if (rt.success) {
      const isBullEyeEmpty =
        !rt.data.aggregation_result ||
        rt.data.aggregation_result.length === 0 ||
        !rt.data.aggregation_result[0]?.items?.length ||
        rt.data.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
        rt.data.aggregation_result[0]?.items.every(
          (i) =>
            !!i.aggregation_result?.[0] &&
            (!i.aggregation_result?.[0].items ||
              i.aggregation_result?.[0].items.length === 0 ||
              i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
        )

      allBullEyeAggData.value = isBullEyeEmpty ? emptyAggData : rt.data
      if (updatePhase) {
        model.phases = getBullsEyeChartSelectedPhaseIds(allBullEyeAggData.value)
      }
      loadingBullEye.value = false
    } else {
      loadingBullEye.value = false
      allBullEyeAggData.value = emptyAggData
      if (updatePhase) {
        model.phases = BullsEyeSetting.getSingleton().phaseIds
      }
    }
  }

  async function fetchNoBullEyeData() {
    loading.value = true
    const rt = await getData()
    if (rt && rt.success) {
      isEmpty.value =
        !rt.data.aggregation_result ||
        rt.data.aggregation_result.length === 0 ||
        !rt.data.aggregation_result[0]?.items?.length ||
        rt.data.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
        rt.data.aggregation_result[0]?.items.every(
          (i) =>
            !!i.aggregation_result?.[0] &&
            (!i.aggregation_result?.[0].items ||
              i.aggregation_result?.[0].items.length === 0 ||
              i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
        )

      aggData.value = isEmpty.value
        ? {
            aggregation_result: [
              { items: [], aggregation_field: '', total: 0 },
              { items: [], aggregation_field: '', total: 0 },
            ],
            total: 0,
          }
        : rt.data

      loading.value = false
    } else {
      loading.value = false
      isEmpty.value = true
    }
  }

  async function fetchAggData(updatePhase?: boolean) {
    if (bullsEyeChartTypes.includes(unref(type))) {
      await fetchBullEyeData(updatePhase)
    } else {
      await fetchNoBullEyeData()
    }
  }

  function initModel(chartType: E_DRUG_ANALYSIS_CHART) {
    const model: IDrugAggChartModel = {
      display: 'vertical-bar',
    }

    // 根据类型赋值不同的display初始值
    if (pieChartTypes.includes(chartType)) {
      model.display = 'pie'
    } else if (stackBarChartTypes.includes(chartType)) {
      model.display = 'horizontal-bar'
    } else if (bullsEyeChartTypes.includes(chartType)) {
      model.display = 'bull-eye'

      model.showLabel = true

      model.phases = []
    }
    if (supportRollupControlChartTypes.includes(chartType)) {
      model.rollup = true
    }
    if (supportDateTypeRadioTypes.includes(chartType)) {
      model.dataType = { selectedValue: 'drug' }
    }
    if (supportTopChartTypes.includes(chartType)) {
      model.top = bullsEyeChartTypes.includes(chartType) ? BullsEyeSetting.getSingleton().topN.toString() : '10'
    }
    return model
  }

  function getAggregationItem(chartType: E_DRUG_ANALYSIS_CHART) {
    const clonedAggregationItem = cloneDeep(aggregationItemRecordType[chartType])

    return clonedAggregationItem as IAggregationItem[]
  }

  return {
    model,
    controls,
    controlLayout,
    // controlLayoutFullscreen,
    aggData,
    bullEyeAggData,
    categoryFieldName: categoryName,
    countFieldName: countName,
    fetchAggData,
    loading: loadingComputed,
    isEmpty: isEmptyComputed,
    isBullEyeEmpty,
    settingDataPanelTitle,
    stackBarChartLegendSelectedStatus,
    fullScreenStackBarChartLegendSelectedStatus,
  }
}
