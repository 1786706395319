import { defineComponent, PropType } from '@vue/composition-api'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'

export const BFilterNoResult = defineComponent({
  name: 'BFilterNoResult',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    locale: {
      type: String as PropType<string>,
    },
  },
  render() {
    return <div class="text-[#5e6c84] text-xs leading-4 text-left">{this.$t('bFilterNext.noData')}</div>
  },
})
