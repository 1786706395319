import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { ElTag } from '@pharmsnap/shared/element-ui'
import { IPatentType } from '@pharmsnap/shared/types/patent'
import { getDictItemName } from '@pharmsnap/shared/utils/common'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { BDecodeUniCodeContainer } from '../BDecodeUniCodeContainer/BDecodeUniCodeContainer'
import $classes from './BPatentTypeItem.module.scss'

export const BPatentTypeItem = defineComponent({
  name: 'BPatentTypeItem',
  props: {
    data: {
      type: [Object, String] as PropType<IPatentType | string | undefined>,
    },
    fontClassName: {
      type: String,
    },
  },
  setup(props) {
    const { localeUpcase } = useLocale()
    const name = computed(() => {
      if (!props.data) {
        return EMPTY_PLACEHOLDER
      }
      if (typeof props.data === 'string') {
        return props.data
      }
      return getDictItemName(props.data, localeUpcase.value)
    })
    return { name }
  },
  render() {
    return (
      <ElTag class={$classes.bEntityTag} disable-transitions={true} size="small" effect="plain" type="info">
        <BDecodeUniCodeContainer
          fontClassName={typeof this.data === 'string' ? this.fontClassName : this.data?.fontClassName}
          domPropsInnerHTML={this.name}
        ></BDecodeUniCodeContainer>
      </ElTag>
    )
  },
})
