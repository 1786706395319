import { renderNewsPublishDate } from '@pharmsnap/shared/render'
import { INewsDetail } from '@pharmsnap/shared/src/types'
import { encryptNum } from '@pharmsnap/shared/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { BLazyImg } from '../../BLazyImg/BLazyImg'
import $classes from './BSecondaryNewsCard.module.scss'

export const BSecondaryNewsCard = defineComponent({
  name: 'BSecondaryNewsCard',
  props: {
    news: {
      required: true,
      type: Object as PropType<INewsDetail>,
    },
    detailUrl: {
      type: String,
    },
  },
  methods: {
    renderItemCoverImg(news: INewsDetail) {
      return news.images?.map((image) => {
        if (image.type === 'cover_image')
          return (
            <BLazyImg
              class="overflow-hidden relative h-0 bg-gray-20 rounded"
              style="padding-bottom: calc(9 / 16 * 100%)"
              imgClass={['absolute top-0 right-0 bottom-0 left-0 transition duration-200 transform  w-full h-full rounded', $classes.lazyImg]}
              imgStyle="object-fit: contain"
              src={image.s3_path as string}
              signType="discovery_attachment"
            ></BLazyImg>
          )
      })
    },
  },
  render() {
    return (
      <a href={this.$router.resolve(this.detailUrl as string).href} target="_blank" class="w-full cursor-pointer">
        <div class="relative">
          {this.renderItemCoverImg(this.news)}
          <div
            class={['absolute bottom-0 text-white-default text-sm left-0 right-0 line-clamp-3 py-1 px-2 rounded', $classes.container]}
            style="background: linear-gradient(180deg, rgba(12,30,60,0.1) 0%, rgba(11,25,52,0.43) 27%, rgba(9,20,43,0.8) 100%);"
          >
            <div class="line-clamp-2 title">{this.news.title}</div>
            <div class="flex align-middle" style="margin-top: 10px">
              <div class="text-sm icon-font flex items-center" domPropsInnerHTML={encryptNum(renderNewsPublishDate(this.news, this.$i18n))}></div>
            </div>
          </div>
        </div>
      </a>
    )
  },
})
