import { DomainContext } from '@patsnap/synapse_domain/dist/base-domain/base.type'
import { IClinicalTrial } from '../../types'

/**
 * 提取临床标题
 * https://confluence.zhihuiya.com/display/DLS/SNP+202403+Online+UAT
 */
export function extractClinicalTitle(
  _ctx: DomainContext | undefined,
  data: IClinicalTrial,
  firstField: 'brief_title' | 'official_title' = 'official_title'
) {
  /** 字符中的中英文长度大于5算有效文本直接返回,否则算为空值 */
  function getCleanText(text?: string) {
    if (text) {
      // 只取中英文字符长度，不要数字和特殊字符
      const cleanText = text.replace(/[^a-zA-Z\u4e00-\u9fa5]/g, '')
      if (cleanText.length > 5) {
        return text
      }
    }
    return ''
  }
  if (firstField === 'brief_title') {
    return getCleanText(data.brief_title) || getCleanText(data.official_title) || data.brief_title || data.official_title
  } else {
    return getCleanText(data.official_title) || getCleanText(data.brief_title) || data.official_title || data.brief_title
  }
}
