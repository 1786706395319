import { IGRadioItem } from '@pharmsnap/shared/types'
import { computed, defineComponent, unref } from '@vue/composition-api'
import { IFilterItemAdvanceUiType } from '..'
import { GRadio } from '../../../ui/GRadio/GRadio'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import { ADVANCE_UI_TYPE_LANG_RECORD } from '../config'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'

export const BFilterAdvanceUiTypeRadio = defineComponent({
  name: 'BFilterAdvanceUiTypeRadio',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const service = useInjectFilterService()!

    const activeUiType = computed(() => {
      return unref(service.filterService.selectedState)._advance.activeUiType
    })

    const uiTypeRadios = computed<IGRadioItem[]>(() => {
      const locale = unref(service.locale)
      return unref(service.filterService.selectedState)._advance.uiTypes.map((item) => {
        return {
          label: ADVANCE_UI_TYPE_LANG_RECORD[item]?.[locale] || '',
          value: item,
        }
      })
    })

    async function handleChangeUiType(uiType: IFilterItemAdvanceUiType) {
      await service.filterService.changeAdvanceUiType(uiType)
    }

    return {
      activeUiType,
      uiTypeRadios,
      handleChangeUiType,
    }
  },
  render() {
    return <GRadio value={this.activeUiType} onInput={this.handleChangeUiType} mode="button" items={this.uiTypeRadios}></GRadio>
  },
})
