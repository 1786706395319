import { IAggregationData } from '@patsnap/synapse_common_interface'
import { usePagination } from '@pharmsnap/shared/src/composition'
import { PropType, defineComponent, ref, toRefs, watch } from '@vue/composition-api'
import { GPagination } from 'pharmsnapMF_shared/components'
import { ITransMedicineThreeDimTableItem, useAnalysisTransMedicineThreeDimTable } from '../../../BAnalysisChartNext/compositions'
export const BAnalysisThreeDimTable = defineComponent({
  name: 'BAnalysisThreeDimTable',
  props: {
    categoryFieldName: {
      type: String,
      default: '',
    },
    twoDimName: {
      type: String,
      default: '',
    },
    countName: {
      type: String,
      default: '',
    },
    isEmpty: {
      type: Boolean,
    },
    aggData: {
      type: Object as PropType<IAggregationData>,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '100%',
    },
  },
  setup(props) {
    const { categoryFieldName: oneDimName, twoDimName, countName, isEmpty, aggData } = toRefs(props)

    const { tableList, tableColumn } = useAnalysisTransMedicineThreeDimTable({
      oneDimName,
      twoDimName,
      countName,
      isEmpty,
      aggData,
    })

    const { currentPage, pageSize, onCurrentPageChange } = usePagination(1, 10, getTableList)

    const displayTableList = ref<ITransMedicineThreeDimTableItem[]>([])

    function getTableList(page: number, pageSize: number) {
      const startIndex = (page - 1) * pageSize
      const data = tableList.value.slice(startIndex, startIndex + pageSize)
      const oneDimRowSpanMap: Record<string, number> = {}
      const twoDimRowSpanMap: Record<string, number> = {}
      // 计算表格合并情况
      data.forEach((item) => {
        oneDimRowSpanMap[item.oneDim] = (oneDimRowSpanMap[item.oneDim] || 0) + 1
        const key = `${item.oneDim}-${item.twoDim}`
        twoDimRowSpanMap[key] = (twoDimRowSpanMap[key] || 0) + 1
      })

      displayTableList.value = data.map((item) => {
        let oneDimRowSpan = 0
        let twoDimRowSpan = 0

        if (oneDimRowSpanMap[item.oneDim] > 0) {
          oneDimRowSpan = oneDimRowSpanMap[item.oneDim]
          oneDimRowSpanMap[item.oneDim] = 0 // 确保只合并一次
        }

        const key = `${item.oneDim}-${item.twoDim}`
        if (twoDimRowSpanMap[key] > 0) {
          twoDimRowSpan = twoDimRowSpanMap[key]
          twoDimRowSpanMap[key] = 0 // 确保只合并一次
        }

        return {
          ...item,
          oneDimRowSpan,
          twoDimRowSpan,
        }
      })
    }

    const mergeCellFun = ({ row, columnIndex }: { row: ITransMedicineThreeDimTableItem; columnIndex: number }) => {
      if (columnIndex === 0) {
        return { rowspan: row.oneDimRowSpan, colspan: row.oneDimRowSpan > 0 ? 1 : 0 }
      }
      if (columnIndex === 1) {
        return { rowspan: row.twoDimRowSpan, colspan: row.twoDimRowSpan > 0 ? 1 : 0 }
      }
    }

    getTableList(currentPage.value, pageSize.value)

    watch(
      () => props.aggData,
      () => {
        onCurrentPageChange(1)
      }
    )

    return {
      mergeCellFun,
      tableList,
      tableColumn,
      displayTableList,
      currentPage,
      onCurrentPageChange,
    }
  },
  render() {
    const disableScroll = {
      enabled: false,
    }
    return (
      <div class="flex flex-col h-full items-center">
        <div class="flex-1 w-full">
          <vxe-grid
            scrollX={disableScroll}
            scrollY={disableScroll}
            max-height={this.maxHeight}
            border={true}
            showHeader
            size="small"
            class="z-[1] w-full"
            data={this.displayTableList}
            columns={this.tableColumn}
            span-method={this.mergeCellFun}
            show-overflow={false}
            show-header-overflow={false}
          ></vxe-grid>
        </div>
        <GPagination
          class="mt-6 flex-shrink-0"
          layout="prev, pager, next"
          total={this.tableList.length}
          currentPage={this.currentPage}
          {...{
            on: {
              'current-change': this.onCurrentPageChange,
            },
          }}
        />
      </div>
    )
  },
})
