import { GLoading } from '@pharmsnap/shared/src/components'
import { defineComponent, onMounted, toRefs, watch } from '@vue/composition-api'
import { useStructureImgExporter } from '../compositions/useStructureImgExporter'

export const StructureImg = defineComponent({
  name: 'StructureImg',
  props: {
    /**
     * 结构式smiles或inchi_key
     */
    source: {
      type: String,
      required: true,
    },
    /**
     * 结构式图片宽高
     */
    imgSize: {
      type: Number,
      default: 100,
    },
    /**
     * 是否显示渐变色背景
     */
    showBackground: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否显示边框
     */
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { imgSize, source } = toRefs(props)

    const { structureSvgBase64, exeExportStructureSvg, isLoading } = useStructureImgExporter({
      source,
      imgHeight: imgSize,
      imgWidth: imgSize,
    })

    onMounted(() => {
      exeExportStructureSvg()
    })

    watch([imgSize, source], () => {
      exeExportStructureSvg()
    })

    return {
      structureSvgBase64,
      isLoading,
    }
  },
  render() {
    return (
      <div
        style={{
          width: `${this.imgSize}px`,
          height: `${this.imgSize}px`,
          ...(this.showBackground
            ? {
                background: 'radial-gradient(50% 50% at 50% 50%, #FFFFFF 59.99%, #EBF5FF 100%)',
              }
            : undefined),
        }}
        class={['rounded', this.showBorder ? 'border border-gray-55' : '', this.isLoading ? 'relative' : '']}
      >
        {this.isLoading ? (
          <GLoading size={Math.round(this.imgSize / 3)}></GLoading>
        ) : (
          <img width="100%" src={this.structureSvgBase64} alt="structure" />
        )}
      </div>
    )
  },
})

//
