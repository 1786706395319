/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlowFunction } from '@pharmsnap/shared/types'
import { curry } from 'lodash'
import { BasicThemeRiverChart } from '..'
import { ECOption, Plot } from '../core/plot'
import { flow, mergeSingleAxis, mergeTooltip } from '../util'
import { getSingleAxis, getTooltip, themeRiverTooltipFormatter } from './config'

export class ThemeDriver extends Plot {
  public type = 'theme-driver'

  protected getDefaultOption(options: ECOption): ECOption {
    const singleAxis: FlowFunction<ECOption> = curry(mergeSingleAxis)(getSingleAxis)
    const tooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const chartOptions = flow<ECOption>(tooltip, singleAxis)(options)

    return chartOptions
  }

  // @ts-ignore
  protected adaptor(params: BasicThemeRiverChart): ECOption {
    const { tooltip, series, grid, legend, singleAxis, color } = params

    const options: ECOption = {
      ...(color ? { color } : undefined),
      tooltip: {
        ...tooltip,
        formatter: themeRiverTooltipFormatter,
      },
      grid,
      legend,
      singleAxis,
      // @ts-ignore
      series: [{ ...series, type: 'themeRiver' }],
    }

    return options
  }
}
