import {
  getFlatRegisterInfo,
  promotionQueryKeys,
  registerInfoQueryKey,
  shareCampaignPromotion,
  shareCodeQueryKey,
  shareCodeStoreKey,
  sharePromotionPlan,
} from '@patsnap/synapse_common_business'
import { IBOFreeRegisterUrlParams } from '@patsnap/synapse_common_interface'
import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IUserShareInfo } from '@pharmsnap/shared/src/types'
import { isEmpty } from 'lodash'
import { getLocalStorage, removeHistoryQueries } from 'pharmsnapMF_shared/utils'
import qs from 'qs'

let registerRegisterTrackingPromise: Promise<void> = Promise.resolve()
/**
 * 注册用户注册来源到到 localStorage
 * @param params
 */
export async function registerRegisterTracking() {
  const query = qs.parse(window.location.search.substring(1, window.location.search.length))
  const registerInfo = query[registerInfoQueryKey] as string
  const registerInfoStore = {}
  if (registerInfo) {
    localStorage.setItem(shareCodeStoreKey, registerInfo)
    removeHistoryQueries([registerInfoQueryKey])
    return
  }
  const shareCode = query[shareCodeQueryKey] as string
  if (shareCode) {
    removeHistoryQueries([shareCodeQueryKey])
    registerRegisterTrackingPromise = getService()
      .account.revertMappingId(shareCode)
      .then((rt) => {
        if (rt.success) {
          const val = qs.parse(rt.data.value) as unknown as { user_id: string; share_info: IUserShareInfo }
          if (val.user_id) {
            Object.assign(
              registerInfo,
              {
                promotion_plan: sharePromotionPlan,
                promotion_cell: val.user_id,
                campaign_promotion: shareCampaignPromotion,
              },
              getFlatRegisterInfo(val)
            )
            if (val.share_info) {
              sharedCtx.service.tracking.shareOpen(Object.assign({ access_user_id: val.user_id }, val.share_info))
            }
          }
        }
      })
  }
  // 这个是链接上拼的打平的注册参数, 优先级最高
  const flatRegisterInfo = getFlatRegisterInfo(query)
  if (!isEmpty(flatRegisterInfo)) {
    Object.assign(registerInfoStore, flatRegisterInfo)
    removeHistoryQueries(promotionQueryKeys)
  }
  if (!isEmpty(registerInfoStore)) {
    localStorage.setItem(shareCodeStoreKey, JSON.stringify(registerInfoStore))
  }
}
/**
 * 获取注册来源
 * @returns
 */
export async function getRegisterTracking() {
  await registerRegisterTrackingPromise
  return getLocalStorage<IBOFreeRegisterUrlParams>(shareCodeStoreKey)
}
