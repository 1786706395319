import { getSingle } from '@patsnap/synapse_common_utils'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IGetLogoSignatureParams, ISignType } from '@pharmsnap/shared/service/apis/searchApi'
import { groupBy } from 'lodash'
import { Subject } from 'rxjs'
import { bufferTime, filter, map, mergeMap, share } from 'rxjs/operators'

export interface ISignatureImgSubjectParams {
  logo: string
  signType: ISignType
}

// 这边包层函数，避免sharedCtx未初始化时就调用，导致报错
const getOrgLogoSignatureFn = (data: IGetLogoSignatureParams) => sharedCtx.service.search.logoBatchSignatureWithCache(data)

export const getNeedSignatureImgSubject = getSingle(() => new Subject<ISignatureImgSubjectParams>())
export const getSignatureImgRtStream = getSingle(() => {
  return getNeedSignatureImgSubject()
    .pipe(
      bufferTime(200),
      filter((data) => !!data.length),
      mergeMap((logoList) => Object.values(groupBy(logoList, (logoItem) => logoItem.signType)))
    )
    .pipe(
      mergeMap((logoList) => getOrgLogoSignatureFn({ logoList: logoList.map((logoItem) => logoItem.logo), signType: logoList[0].signType })),
      map((data) => (data.success && data.data ? data.data : ({} as Record<string, string>))),
      share()
    )
})
