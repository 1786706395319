import '@patsnap-ui/icon/assets/solid/AddLarge.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import {
  IAutoCompleteType,
  ICompareRow,
  ICompareType,
  IDiseaseCompareData,
  IDrugCompareData,
  IListItem,
  IOrgCompareData,
  ITargetCompareData,
  IUseCompareService,
} from '@pharmsnap/shared/types'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, nextTick, PropType, ref } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import { VNode } from 'vue'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BAutoEntity } from '../BAutoEntity/BAutoEntity'
import $style from './BEntityCompare.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BEntityCompare = defineComponent({
  name: 'BEntityCompare',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    rowConfig: {
      type: Array as PropType<
        ICompareRow<IDrugCompareData>[] | ICompareRow<IOrgCompareData>[] | ICompareRow<ITargetCompareData>[] | ICompareRow<IDiseaseCompareData>[]
      >,
      required: true,
    },
    compareService: {
      type: Object as PropType<IUseCompareService>,
      required: true,
    },
    type: {
      type: String as PropType<ICompareType>,
      required: true,
    },
    limit: {
      type: Number,
    },
    isStickyTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isEditModel = ref(false)
    const ins = getCurrentInstance()
    const { isCN } = useLocale()
    const {
      state: { dataList, entityIds },
      actions: { addItem },
    } = props.compareService

    function colSpanFn(row: ICompareRow<any>, index: number) {
      //  不显示加号按钮,或者 是第一行的所有td ,或者 不是最后一列
      if (!isShowAdd.value || row.add || index !== dataList.value.length - 1) {
        return 1
      }

      return 2
    }

    function handelClickedAdd(e: Event): void {
      isEditModel.value = true
      nextTick(handelFocusInput)
    }

    function handelClickOutside() {
      isEditModel.value = false
    }

    function handelFocusInput() {
      const autoEntity = ins?.refs.autoEntity as any
      const acBase = autoEntity?.$refs.acBase
      acBase && acBase.handleFocusContainer()
    }

    async function handleSelectEntity(type: IAutoCompleteType, entity: IListItem) {
      if (entityIds.value.includes(entity.id)) {
        showSingleToast({
          message: ins?.proxy.$t('BEntityCompare.hasChosen', { type: ins?.proxy.$t(`BEntityCompare.${props.type}`) }) as string,
          type: 'info',
        })
        return
      }
      addItem(entity.id)
    }

    const isShowAdd = computed(() => props.limit && dataList.value.length < props.limit)

    const addClass = computed(() => {
      const addBtnWidthClass = dataList.value.length === 1 ? $style.compareTableBiggerBtn : ''

      return isEditModel.value
        ? [$style.compareTableTd, $style.compareTableTdFull, addBtnWidthClass]
        : [$style.compareTableTd, $style.compareTableTdFixed, addBtnWidthClass]
    })

    const isEmptyDataList = computed(() => !dataList.value.length)

    const labelWidth = computed(() => (isEmptyDataList.value ? '' : '220px'))

    const addWidth = computed(() => {
      if (dataList.value.length > 1 && !isEditModel.value) {
        return '84px'
      }
      return isEmptyDataList.value ? '948px' : '320px'
    })

    const popperClass = computed(() => (isEmptyDataList.value ? $style.popper948 : $style.popper320))

    const addLabel = computed(() => {
      if (dataList.value.length === 0) {
        return ins?.proxy.$t('BEntityCompare.emptyAdd', { type: ins?.proxy.$t(`BEntityCompare.one${props.type}`) })
      }
      if (dataList.value.length === 1) {
        return ins?.proxy.$t('BEntityCompare.addAnotherEntity', { type: ins?.proxy.$t(`BEntityCompare.${props.type}`) })
      }
      return ins?.proxy.$t('BEntityCompare.addDefaultPlaceholder')
    })

    return {
      isShowAdd,
      addClass,
      isEmptyDataList,
      isEditModel,
      isCN,
      dataList,
      labelWidth,
      addWidth,
      addLabel,
      popperClass,
      colSpanFn,
      handelClickedAdd,
      handelClickOutside,
      handleSelectEntity,
    }
  },
  methods: {
    renderAddBox(): VNode {
      return this.isEditModel ? (
        <BAutoEntity
          ref="autoEntity"
          type={this.type}
          tags={[]}
          inputTip={this.$t('BEntityCompare.inputTip') as string}
          labelContainerClass={$style.labelContainer}
          popperClass={this.popperClass}
          onSelect={this.handleSelectEntity}
          suffix="SolidClear"
          suffixClass={$style.suffixClass}
          suffixIconClass={$style.suffixIconClass}
          onSuffixClick={() => (this.isEditModel = false)}
          {...{
            directives: [
              {
                name: 'click-outside',
                value: {
                  handler: this.handelClickOutside,
                },
              },
            ],
          }}
        ></BAutoEntity>
      ) : (
        <span class={$style.compareTableAdd} onClick={this.handelClickedAdd} role="none">
          <GIcon svgName="SolidAddLarge" size={24}></GIcon>
          {this.addLabel}
        </span>
      )
    },
    renderToolTip(info: string) {
      return (
        <GTooltip theme="light" mode="icon" class={$style.icon}>
          <template slot="content">
            <div domPropsInnerHTML={info}></div>
          </template>
        </GTooltip>
      )
    },
    renderStickyTable() {
      const row = this.rowConfig[0]
      return (
        <table class={[$style.compareTable, $style.stickyTable]} border="0" cellpadding="0" cellspacing="0">
          <tr ref={'stickyRef'}>
            <th width={this.labelWidth} class={$style.compareTableHeader}>
              <div class="flex items-center">
                <span>{this.$t(`BEntityCompare.${row.title}`)}</span>
              </div>
            </th>
            {this.dataList.map((entity, index) => (
              <th class={$style.compareTableTd} colspan={this.colSpanFn(row, index)}>
                {row.renderFunc &&
                  row.renderFunc({
                    data: entity as any,
                    index,
                    compareService: this.compareService,
                    $i18n: this.$i18n,
                  })}
              </th>
            ))}
            {this.isShowAdd && row.add ? (
              <th width={this.addWidth} class={[this.addClass, $style.addBtn]}>
                {this.renderAddBox()}
              </th>
            ) : null}
          </tr>
        </table>
      )
    },
    renderCommonTable() {
      return (
        <table class={[$style.compareTable, $style.normal]} border="0" cellpadding="0" cellspacing="0">
          {this.rowConfig.map((row) => {
            const CustomTag = row.tag || 'td'
            return (
              <tr class={row.class}>
                <CustomTag width={this.labelWidth} class={$style.compareTableHeader}>
                  <div class="flex items-center">
                    <span>{this.$t(`BEntityCompare.${row.title}`)}</span>
                    {row.info ? this.renderToolTip(this.$t(`BEntityCompare.${row.info}`) as string) : null}
                  </div>
                </CustomTag>
                {this.dataList.map((entity, index) => (
                  <CustomTag class={$style.compareTableTd} colspan={this.colSpanFn(row, index)}>
                    {row.renderFunc &&
                      row.renderFunc({
                        data: entity as any,
                        index,
                        compareService: this.compareService,
                        $i18n: this.$i18n,
                      })}
                  </CustomTag>
                ))}
                {this.isShowAdd && row.add ? (
                  <CustomTag width={this.addWidth} class={[this.addClass, $style.addBtn]}>
                    {this.renderAddBox()}
                  </CustomTag>
                ) : null}
                {this.isEmptyDataList && !row.add ? <CustomTag /> : null}
              </tr>
            )
          })}
        </table>
      )
    },
  },
  render() {
    return <div class="contents">{this.isStickyTable ? this.renderStickyTable() : this.renderCommonTable()}</div>
  },
})
