import { useUpgrade } from '@pharmsnap/pharmsnap-web/views/Upgrade/hook/useUpgrade'
import { defineComponent } from '@vue/composition-api'
import '../../../assets/icon-svg/upgrade.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import { BUpgradeButton } from './BUpgradeButton'

export const BUpgradeBlock = defineComponent({
  name: 'BUpgradeBlock',
  props: {
    content: {
      type: String,
      default: '',
    },
    trackPoint: {
      type: String,
    },
  },
  setup(props, { root }) {
    const { onViewPlans } = useUpgrade()
    function onClickUpgrade() {
      onViewPlans(props.trackPoint)
    }

    return {
      onClickUpgrade,
    }
  },
  render() {
    return (
      <div class="bg-blue-focus border-l-4 border-blue-default py-1.5 h-10 flex items-center pr-4">
        <GIcon style="color: #1854f1;" class="flex-shrink-0 ml-2 mr-2" svgName="Upgrade" size={24}></GIcon>
        <div class="text-text-t1 truncate text-sm">{this.content || this.$tc('common.premiumTitle')}</div>
        <BUpgradeButton {...{ nativeOn: { click: this.onClickUpgrade } }} class="flex-shrink-0 ml-4" size="small" showIcon={true}></BUpgradeButton>
      </div>
    )
  },
})
