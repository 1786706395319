import Vue, { CreateElement, RenderContext } from 'vue'
const style = {
  minHeight: '80px',
  maxHeight: '380px',
  minWidth: '200px',
  maxWidth: '300px',
}
export const BBasicLoadingCard = Vue.extend({
  functional: true,
  name: 'BBasicLoadingCard',
  render(
    h: CreateElement,
    ctx: RenderContext<{
      loading: string
    }>
  ) {
    const { loading } = ctx.props
    return (
      <div v-ls-loading={loading} class="p-4 bg-white-default border border-gray-55 rounded overflow-y-auto" style={style}>
        {!loading && ctx.slots().default}
      </div>
    )
  },
})
