import { IQueryItem, ISearchParams } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { useLocale, userErrorMessage } from '@pharmsnap/shared/composition'
import { autoPreviewConfigMap } from '@pharmsnap/shared/config'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IAutoPreview, ISearchCollapse } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, ref, watch } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'
import $classes from './BAutoPreviewCounts.module.scss'

export const BAutoPreviewCounts = defineComponent({
  name: 'BAutoPreviewCounts',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    type: {
      type: String as PropType<IAutoPreview>,
      required: true,
    },
    customDisplayType: {
      type: String,
    },
    query: {
      type: Array as PropType<IQueryItem[]>,
      required: true,
      default: () => [],
    },
    collapse: {
      type: Object as PropType<ISearchCollapse>,
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const { showErrorMessageByToast } = userErrorMessage()
    const { isCN } = useLocale()
    const loading = ref(false)
    const count = ref(0)

    const currentType = computed<Record<IAutoPreview, string | undefined>>(() => ({
      Drug: ins?.proxy.$tc('common.drug'),
      Clinical: ins?.proxy.$tc('common.clinical_trial'),
      Patent: ins?.proxy.$tc('BAutoPreview.patentCount'),
      Literature: ins?.proxy.$tc('common.paper'),
      ClinicalResult: ins?.proxy.$tc('common.clinicalResult'),
      DrugDeal: ins?.proxy.$tc('common.drugDeal'),
      TranslationalMedicine: ins?.proxy.$tc('common.translationalMedicine'),
    }))

    const getPreviewCounts = async () => {
      if (!props.query.length) return
      loading.value = true
      const { dataType, serviceKey } = autoPreviewConfigMap[props.type]

      const params: ISearchParams = {
        data_type: dataType,
        query: {
          type: 'group',
          must: props.query,
        },
        ...(props.collapse ? { collapse: props.collapse } : undefined),
        limit: 0,
        offset: 0,
      }

      const rt = await sharedCtx.service[serviceKey].getSearchCount(params)
      if (rt.success) {
        count.value = props.type === 'Patent' ? rt.data.uncollapse_total || 0 : rt.data.total
      } else {
        count.value = 0
        if (rt.status >= 500) {
          showErrorMessageByToast(rt.data?.numeric_error_code, ins?.proxy.$t('BAutoPreview.queryFail') as string)
        }
      }
      loading.value = false
      ctx.emit('updateCount', count.value)
    }

    watch(() => props.query, getPreviewCounts, { deep: true, immediate: true })

    return {
      loading,
      currentType,
      count,
      isCN,
    }
  },
  render() {
    return (
      <div class={$classes.numArea}>
        <i18n path={'BAutoPreview.foundRes'} tag="div" class="flex items-center text-gray-450 text-sm">
          {this.loading ? (
            <PtIcon place="count" class={this.isCN ? 'mx-2' : ''} icon="SolidLoadingBars"></PtIcon>
          ) : (
            <span
              data-testid="b-auto-preview-counts"
              place="count"
              class={[this.count ? $classes.forecast : $classes.forecastNone, this.isCN ? 'mx-2' : '']}
            >
              {toThousands(this.count || 0)}
            </span>
          )}
          <span place="type" class={[this.isCN ? '' : 'ml-2 mr-1']}>
            {this.customDisplayType || this.currentType[this.type]}
          </span>
          {this.type === 'Patent' && <span place="desc">{this.$t('BAutoPreview.patentInfo')}</span>}
        </i18n>
      </div>
    )
  },
})
