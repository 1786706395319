import { fieldMany2OneMap, fieldMany2OneMapByType, fields2UUKey, getSingleFieldName } from '@patsnap/synapse_common_business'
import {
  E_QUERY_ITEM_CONDITION,
  IAggregationRangeItem,
  IQuery,
  IQueryDataType,
  IQueryGroupType,
  IQueryItem,
  IQueryValue,
  ISearchParams,
  ISearchStrategy,
  ISort,
} from '@patsnap/synapse_common_interface'
import { cloneDeep, difference, some } from 'lodash'
import { AGG_DISEASE_FIELDS, AGG_DRUG_FIELDS, AGG_DRUG_TYPE_FIELDS, AGG_ORG_FIELDS, AGG_TARGET_FIELDS } from '../config'
import { sharedCtx } from '../context'
import { I18nLang } from '../i18n'
import {
  IAggDiseaseFieldType,
  IAggDrugFieldType,
  IAggOrgFieldType,
  IAggTargetFieldType,
  IHistorySearchParams,
  IQueryDataEntityType,
  IQueryItemEntityType,
  ISearchCollapse,
  ISearchHistoryFromModule,
} from '../types/search'
import { queryDataTypeQueryItemTransformMap } from './business/queryTransform'
import { getNumberRangeDisplayName } from './number'

export function getOneField2ManyFieldMap(dataType: IQueryDataType) {
  const config = fieldMany2OneMapByType[dataType] ?? {}
  return Object.entries(config).reduce((acc, curr) => {
    const [key, value] = curr
    return {
      ...acc,
      [value]: key,
    }
  }, {} as Record<string, string>)
}

export const oneField2ManyFieldMap: Record<string, string> = Object.entries(fieldMany2OneMap).reduce((acc, curr) => {
  const [key, value] = curr
  return {
    ...acc,
    [value]: key,
  }
}, {} as Record<string, string>)

/**
 * queryItem的转换方法
 * @param queryItem
 */
export function queryItemTransform(queryItems: IQueryItem[], dataType: IQueryDataType, type: 'display2store' | 'store2display'): IQueryItem[] {
  const fieldQueryItemTransformMap = queryDataTypeQueryItemTransformMap[dataType]
  if (!fieldQueryItemTransformMap) {
    console.error(`数据类型${dataType}, 没有配置字段转换方法的映射表,忽略转换`)
    return queryItems
  }
  return queryItems.map((queryItem) => {
    if (queryItem.type === 'field') {
      if (queryItem.fields.length) {
        const firstField = queryItem.fields[0]
        // fields包含多个field时，可使用虚拟field映射transform方法
        const mapField = fieldMany2OneMapByType[dataType]?.[fields2UUKey(queryItem.fields)]
        const fieldQueryItemTransformFn = (mapField && fieldQueryItemTransformMap[mapField]) || fieldQueryItemTransformMap[firstField]
        if (!fieldQueryItemTransformFn) {
          return queryItem
        }
        return fieldQueryItemTransformFn[type](queryItem)
      }
    }
    if (queryItem.type === 'group') {
      if ('source' in queryItem) {
        return queryItem
      }
      const newQueryItem: IQuery = { type: 'group' }
      if (queryItem.must) {
        newQueryItem.must = queryItemTransform(queryItem.must, dataType, type)
      }
      if (queryItem.filter) {
        newQueryItem.any = queryItemTransform(queryItem.filter, dataType, type)
      }
      if (queryItem.any) {
        newQueryItem.any = queryItemTransform(queryItem.any, dataType, type)
      }
      return newQueryItem
    }
    return queryItem
  })
}

export function createAggregationRangeItem(ranges: number[], fromNumber = 0): IAggregationRangeItem[] {
  const newRanges = ranges.concat(ranges.slice(-1))
  const rangesLength = newRanges.length
  const aggregationRangeItem: IAggregationRangeItem[] = []
  let pre = fromNumber
  newRanges.forEach((curr, index) => {
    const isFirst = index === 0
    const isLast = index === rangesLength - 1
    aggregationRangeItem.push(
      Object.assign(
        {
          key: getNumberRangeDisplayName(isFirst, isLast, curr, pre),
          from: pre,
        },
        isLast ? {} : { to: curr }
      )
    )
    pre = curr
  })
  return aggregationRangeItem
}

/**
 * 检查query中是否包含any的group
 * @param data
 * @returns boolean
 */
export function checkQueryHasAnyGroup(data: IQueryItem[]) {
  for (const queryItem of data) {
    if (queryItem.type === 'group') {
      if ('source' in queryItem) {
        return false
      }
      if (queryItem.any?.length) {
        return true
      }
      if (queryItem.must) {
        if (checkQueryHasAnyGroup(queryItem.must)) {
          return true
        }
      }
      if (queryItem.filter) {
        if (checkQueryHasAnyGroup(queryItem.filter)) {
          return true
        }
      }
    }
  }
  return false
}

export function checkQueryHasAnyQueryString(data: IQueryItem[]) {
  return data.some((i) => i.type === 'query_string')
}
export function checkQueryFieldItemHasName(data: IQueryItem[]) {
  // 如果filed字段本身就有name, 就不支持编辑
  return data.some((i) => i.type === 'field' && (i.display_name_cn || i.display_name_en))
}

export function checkQueryHasSource(data: IQueryItem[]) {
  for (const queryItem of data) {
    if (queryItem.type === 'group' && 'source' in queryItem) {
      return true
    }
  }
  return false
}
/**
 * 检查query中的字段是否缺少语言包配置
 * @param data 查询query
 * @param lang 语言类型
 * @param dataType 数据类型
 * @returns
 */
export function checkQuerySomeNotHaveLangConfig(data: IQueryItem[], lang: I18nLang, dataType: IQueryDataType) {
  return loop(data)
  function checkFields(fields: string[]) {
    if (fields.length === 1) {
      if (getSingleFieldName(fields[0], lang, dataType) === '') {
        return true
      }
    } else {
      const fieldMany2OneMapConfig = fieldMany2OneMapByType[dataType]
      if (fieldMany2OneMapConfig) {
        const mapField = fieldMany2OneMapConfig[fields2UUKey(fields)]
        if (mapField) {
          if (getSingleFieldName(mapField, lang, dataType) === '') {
            return true
          }
        }
      }
      if (fields.some((field) => getSingleFieldName(field, lang, dataType) === '')) {
        return true
      }
    }
    return false
  }
  function loop(data: IQueryItem[]): boolean {
    for (const item of data) {
      if (item.type === 'group') {
        if (item.fields) {
          return checkFields(item.fields)
        }
        return loop([...(item.must || []), ...(item.filter || [])])
      }
      if (item.type === 'field') {
        return checkFields(item.fields)
      }
    }
    return false
  }
}
/**
 * 检查query中的某个字段的的值是否超过指定数量
 * @param data query
 * @param field 字段
 * @param count 数量
 * @returns
 */
export function checkFiledValueIsOverLimit(data: IQueryItem[], field: string, count: number) {
  for (const queryItem of data) {
    if (queryItem.type === 'field' && queryItem.fields.includes(field)) {
      return queryItem.value.length > count
    }
    if (queryItem.type === 'group') {
      if ('source' in queryItem) {
        return false
      }
      if (queryItem.must) {
        if (checkQueryHasAnyGroup(queryItem.must)) {
          return true
        }
      }
      if (queryItem.filter) {
        if (checkQueryHasAnyGroup(queryItem.filter)) {
          return true
        }
      }
    }
  }
  return false
}

export function checkFieldValueIsSpecifiedSearchStrategy(data: IQueryItem[], field: string, searchStrategy: ISearchStrategy) {
  for (const queryItem of data) {
    if (queryItem.type === 'field' && queryItem.fields.includes(field)) {
      return queryItem.value.some((item) => 'search_strategy' in item && item.search_strategy === searchStrategy)
    }
  }
  return false
}

// 检查query中的某个field的数据，不是指定的searchStrategy
export function checkFieldValueIsNotSpecifiedSearchStrategy(data: IQueryItem[], field: string, searchStrategy: ISearchStrategy) {
  for (const queryItem of data) {
    if (queryItem.type === 'field' && queryItem.fields.includes(field)) {
      return queryItem.value.some((item) => 'search_strategy' in item && item.search_strategy !== searchStrategy)
    }
  }
  return false
}

/**
 * 检查query中的field是否为指定字段
 * @param data query
 * @param specifiedFields 指定字段
 * @param isStrictLimit 是否严格限制个数一致
 * @returns
 */
export function checkFiledValueIsSpecifiedValue(data: IQueryItem[], specifiedFields: string[], isStrictLimit = false) {
  for (const queryItem of data) {
    if (queryItem.type === 'field' && difference(queryItem.fields, specifiedFields).length === 0) {
      if (isStrictLimit) return queryItem.fields.length === specifiedFields.length
      return true
    }
  }
  return false
}

export function checkFieldConditionIsNone(data: IQueryItem[]): boolean {
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (item.type !== 'group') {
      if (item.condition === E_QUERY_ITEM_CONDITION.NONE) return true
    } else {
      const flag = checkFieldConditionIsNone(item.must || [])
      if (flag) return flag
    }
  }

  return false
}

/**
 * 检查query中是否存在多个「fields相同」的queryItem: 支持传入多个fields
 * @param queryItems query
 * @param specifiedFieldsList 多个fields字段
 * @returns
 */
export function checkSpecifiedFieldsListIsMultipleValue(queryItems: IQueryItem[], specifiedFieldsList: string[][]) {
  for (const specifiedFields of specifiedFieldsList) {
    if (checkSpecifiedFieldsIsMultipleValue(queryItems, specifiedFields)) {
      return true
    }
  }
  return false
}
export function checkIsRegisterNumber(queryItems: IQueryItem[]) {
  for (const item of queryItems) {
    if (item.type === 'field' && item.fields && item.fields.length === 1 && item.fields[0] === 'REGISTER_NUMBER') {
      return true
    }
  }
  return false
}
/**
 * 检查query中是否存在多个「fields相同」的queryItem
 * @param queryItems query
 * @param specifiedFields 指定fields字段
 * @returns
 */
export function checkSpecifiedFieldsIsMultipleValue(queryItems: IQueryItem[], specifiedFields: string[]) {
  // 多个queryItem情况: 判断多个queryItem中fields是否为指定值
  const specifiedFieldsQueryItems =
    queryItems.length > 1
      ? queryItems.filter((queryItem) => queryItem.type === 'field' && difference(queryItem.fields, specifiedFields).length === 0)
      : []
  if (specifiedFieldsQueryItems.length > 1) return true

  // queryItem内：condition条件为ALL，判断value长度
  let specifiedFieldsQueryValueIsMultiple = false
  for (const queryItem of queryItems) {
    if ('condition' in queryItem && queryItem.condition === E_QUERY_ITEM_CONDITION.ALL) {
      specifiedFieldsQueryValueIsMultiple =
        queryItem.type === 'field' && difference(queryItem.fields, specifiedFields).length === 0 && queryItem.value.length > 1
    }
    if (specifiedFieldsQueryValueIsMultiple) {
      break
    }
  }

  return specifiedFieldsQueryValueIsMultiple
}

/**
 * 检查query中是否存在多个「type相同」的queryItem
 * @param queryItems query
 * @param specifiedType 指定的type字段
 * @returns
 */
export function checkSpecifiedTypeIsMultipleValue(queryItems: IQueryItem[], specifiedType: IQueryItemEntityType) {
  // 多个queryItem情况: 判断多个queryItem中type是否为指定值
  const specifiedTypeQueryItems = queryItems.length > 1 ? queryItems.filter((queryItem) => queryItem.type === specifiedType) : []
  if (specifiedTypeQueryItems.length > 1) return true

  // queryItem内：condition条件为ALL，判断value长度
  let specifiedTypeQueryValueIsMultiple = false
  for (const queryItem of queryItems) {
    if ('condition' in queryItem && queryItem.condition === E_QUERY_ITEM_CONDITION.ALL) {
      specifiedTypeQueryValueIsMultiple = queryItem.type === specifiedType && queryItem.value.length > 1
    }
    if (specifiedTypeQueryValueIsMultiple) {
      break
    }
  }

  return specifiedTypeQueryValueIsMultiple
}

export function checkSpecifiedFieldValueIsSpecifiedSearchStrategy(
  data: IQueryItem[],
  specifiedFields: string[],
  specifiedSearchStrategy: ISearchStrategy
) {
  for (const queryItem of data) {
    if (queryItem.type === 'field' && difference(queryItem.fields, specifiedFields).length === 0) {
      if (some(queryItem.value, (valueItem) => 'search_strategy' in valueItem && valueItem.search_strategy === specifiedSearchStrategy)) {
        return true
      }
    }
  }
  return false
}

export function getEntityTypeByAggField(field: string): Exclude<IQueryDataEntityType, 'mechanism'> | undefined {
  if (AGG_DRUG_FIELDS.includes(field as IAggDrugFieldType)) return 'drug'
  if (AGG_DISEASE_FIELDS.includes(field as IAggDiseaseFieldType)) return 'disease'
  if (AGG_TARGET_FIELDS.includes(field as IAggTargetFieldType)) return 'target'
  if (AGG_ORG_FIELDS.includes(field as IAggOrgFieldType)) return 'organization'
  if (AGG_DRUG_TYPE_FIELDS.includes(field)) return 'drug_type'
}

/**
 * 为了转换那些query里面的value存的是id的数据
 * @param item
 * @returns
 */
export function getQueryValueEntityId(item: IQueryValue) {
  if ('value' in item) {
    const { value, display_name_cn, display_name_en } = item
    if (typeof value === 'string' && value.length === 32 && value !== display_name_cn && value !== display_name_en) {
      return value
    }
  }
  if (['drug', 'disease', 'target', 'organization', 'CommonEntity'].includes(item.type) && 'id' in item && item.id) {
    return item.id
  }
  return ''
}

export async function getQueryIdByQueryV2(
  query: IQuery,
  dataType: IQueryDataType,
  fromModule?: ISearchHistoryFromModule,
  collapse?: ISearchCollapse,
  groupBy?: IQueryGroupType,
  sort?: ISort[],
  queryType?: ISearchParams['query_type'],
  structureQuery?: ISearchParams['structure_query']
) {
  const searchParams: IHistorySearchParams = {
    data_type: dataType,
    query: cloneDeep(query),
    ...(collapse ? { collapse } : undefined),
    ...(fromModule ? { from_module: fromModule } : { hidden_flag: true }),
    ...(groupBy ? { group_by: groupBy } : undefined),
    ...(sort ? { sort } : undefined),
    ...(queryType ? { query_type: queryType } : undefined),
    ...(structureQuery ? { structure_query: structureQuery } : undefined),
  }
  const queryRes = await sharedCtx.service.history.saveQuery(searchParams)
  if (queryRes.success) {
    return queryRes.data.id
  }
  return ''
}

/**
 * 获取列表页groupBy的两个queryId
 * @param params
 * @returns
 */
export async function getGroupQueryIdsByQuery(params: {
  searchParams: IHistorySearchParams
  groupBy: IQueryGroupType
  originFilterStrategy?: 'clear' | 'appendToMust' | 'origin'
}) {
  const { searchParams, groupBy, originFilterStrategy = 'origin' } = params
  const [fromQuery, curQuery] = await Promise.all([
    sharedCtx.service.history.saveQuery({
      ...searchParams,
      query: {
        ...searchParams.query,
        ...(originFilterStrategy === 'appendToMust' ? { must: [...(searchParams.query.must || []), ...(searchParams.query.filter || [])] } : {}),
        ...(originFilterStrategy === 'clear' || originFilterStrategy === 'appendToMust' ? { filter: [] } : {}),
      },
    }),
    sharedCtx.service.history.saveQuery({ ...searchParams, group_by: groupBy }),
  ])
  if (!fromQuery.success || !curQuery.success) return {}
  return {
    fromQueryId: fromQuery.data.id,
    queryId: curQuery.data.id,
  }
}
