import { GCheckList, GEmpty, GMultiDropdown } from '@pharmsnap/shared/components'
import { E_NODATA_SIZE } from '@pharmsnap/shared/src/types'
import { PropType, defineComponent, ref } from '@vue/composition-api'

export const BAnalysisChartDropdownSelect = defineComponent({
  name: 'BAnalysisChartDropdownSelect',
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    options: {
      type: Array as PropType<
        {
          id: string
          name: string
        }[]
      >,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    confirmAble: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
      default: -1,
    },
    placement: {
      type: String as PropType<'bottom-start' | 'bottom' | 'bottom-end'>,
      default: 'bottom-start',
    },
    width: {
      type: Number,
      default: 240,
    },
  },
  setup(props, ctx) {
    const tempCheckedValue = ref<string[]>([...props.value])

    function handleUpdateTempValue(val: string[]) {
      tempCheckedValue.value = val
      if (!props.confirmAble) {
        ctx.emit('input', [...val])
      }
    }

    function handleOpen() {
      tempCheckedValue.value = [...props.value]
    }

    function handleSubmit() {
      ctx.emit('input', [...tempCheckedValue.value])
    }

    function handleCancel() {
      tempCheckedValue.value = [...props.value]
    }

    function handleClear() {
      ctx.emit('input', [])
      tempCheckedValue.value = []
    }

    return {
      handleUpdateTempValue,
      handleOpen,
      handleSubmit,
      handleCancel,
      handleClear,
      tempCheckedValue,
    }
  },
  render() {
    return (
      <GMultiDropdown
        width={this.width}
        popoverClass="w-[360px] h-[380px]"
        title={this.title}
        label={this.label}
        showFooter={this.confirmAble}
        placement={this.placement}
        clearAble={false}
        activeAble={false}
        count={this.value.length}
        onOpen={this.handleOpen}
        onClear={this.handleClear}
        onConfirm={this.handleSubmit}
        onClose={this.handleCancel}
      >
        <GCheckList
          list={this.options}
          itemKey="id"
          min={this.min}
          value={this.tempCheckedValue}
          onInput={this.handleUpdateTempValue}
          scopedSlots={{
            default: (props: { item: { id: string; name: string } }) => {
              return <span class="leading-4 text-sm text-text-t2 font-normal">{props.item.name}</span>
            },
          }}
        ></GCheckList>
        <GEmpty class="mt-[76px]" size={E_NODATA_SIZE.SMALL} v-show={this.options.length === 0}></GEmpty>
      </GMultiDropdown>
    )
  },
})
