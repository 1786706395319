import { sharedCtx } from '@pharmsnap/shared/src/context'
import { downloadFileByUrl, showSingleToast } from '@pharmsnap/shared/src/utils'

let isDownloading = false
export const downloadCopilotReport = async (s3_path: string, reportName: string, isCN: boolean) => {
  if (isDownloading || !s3_path) return
  showSingleToast({
    duration: 3000,
    message: isCN ? '下载中' : `Downloading`,
    type: 'success',
  })
  isDownloading = true
  const reportLink = await sharedCtx.service.copilot.getReportSignAddress(s3_path)
  if (reportLink.length) {
    await downloadFileByUrl(reportLink[0], `${reportName}.doc`)
  }
  isDownloading = false
}
