import '@patsnap-biz/save-to-workspace-v2/lib/style'
import '@patsnap-ui/icon/assets/solid/FolderMany.svg'
import { ElButton } from '@pharmsnap/shared/element-ui'
import { useWorkspace } from '@pharmsnap/shared/src/composition/useWorkspace'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BWorkspaceDialog } from './BWorkspaceDialog'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { ISupportWorkspaceDataType, workspaceComponentProps } from './type'

export const BWorkspaceButton = defineComponent({
  name: 'BWorkspaceButton',
  i18n: {
    messages: { cn, en },
  },
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String as PropType<ISupportWorkspaceDataType>,
      required: true,
    },
    ...workspaceComponentProps(),
  },
  setup() {
    const { showWorkspaceDialog, handleClick, handleClose } = useWorkspace()

    return {
      showWorkspaceDialog,
      handleClick,
      handleClose,
    }
  },
  methods: {
    renderWorkspaceDialog() {
      if (this.showWorkspaceDialog) {
        return (
          <BWorkspaceDialog
            onClose={this.handleClose}
            dataType={this.dataType}
            total={this.total}
            searchParams={this.searchParams}
            selectedIds={this.selectedIds}
          ></BWorkspaceDialog>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        <GTooltip placement="top" content={this.$tc('saveToWorkspace')} disabled={this.showText}>
          <ElButton
            class="px-2 py-1 inline-flex items-center cursor-pointer"
            type="primary"
            disabled={this.disabled}
            data-testid="b-workspace-button"
            onClick={this.handleClick}
          >
            <GIcon svgName="SolidFolderMany" class="text-white-default" size={20}></GIcon>
            {this.showText ? <span class="ml-1 align-middle">{this.$t('saveToWorkspace')}</span> : null}
          </ElButton>
        </GTooltip>

        {this.renderWorkspaceDialog()}
      </div>
    )
  },
})
