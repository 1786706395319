import { computed, Ref } from '@vue/composition-api'
import type { Placement, ReferenceElement } from 'tippy.js'
import { hideAll } from 'tippy.js'
import { detailCardTippyConfig } from './config'

export const useEnableMultiplePopper = (isMultiple: Ref<boolean>, zIndex?: number, placement?: Placement) => {
  const tippyConfig = computed(() =>
    Object.assign(
      {},
      detailCardTippyConfig(zIndex, placement),
      isMultiple.value
        ? {}
        : {
            onMount(instance: ReferenceElement) {
              hideAll({ exclude: instance })
            },
          }
    )
  )
  return { tippyConfig }
}
