import { I18nLang } from '@pharmsnap/shared/i18n'
import { computed, getCurrentInstance } from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import { ILang } from '../types/base'

export function useLocale() {
  const ins = getCurrentInstance()
  const locale = computed(() => {
    return ins?.proxy.$i18n.locale as I18nLang
  })
  const isCN = computed(() => {
    return locale.value === 'cn'
  })
  const $i18n = computed(() => {
    return ins?.proxy.$i18n as VueI18n
  })
  const localeUpcase = computed(() => {
    return locale.value.toUpperCase() as ILang
  })
  function tsLangMap(map?: Record<I18nLang, string>) {
    if (!map) {
      console.warn(`语言包配置表获取失败,请检查`)
      return ''
    }
    return map[locale.value]
  }
  function ts(key: string, values?: VueI18n.Values) {
    return ins?.proxy.$t(key, values) as string
  }

  function tsc(key: string, count: number) {
    return ins?.proxy.$tc(key, count) as string
  }
  function tsText(cn: string, en: string) {
    return isCN.value ? cn : en
  }

  return { locale, localeUpcase, isCN, $i18n, tsLangMap, ts, tsc, $t: ts, tsText }
}
