import { Ref, nextTick } from '@vue/composition-api'

export const useScroll = (scrollRef: Ref<HTMLElement | null>) => {
  const scrollToBottom = async () => {
    await nextTick()
    if (scrollRef.value) scrollRef.value.scrollTop = scrollRef.value.scrollHeight
  }
  // 针对流式内容,如果scrollToBottom会不能向上滚动
  const scrollToBottomIfAtBottom = async () => {
    await nextTick()
    if (scrollRef.value) {
      const threshold = 100 // 阈值，表示滚动条到底部的距离阈值
      const distanceToBottom = scrollRef.value.scrollHeight - scrollRef.value.scrollTop - scrollRef.value.clientHeight
      if (distanceToBottom <= threshold) scrollRef.value.scrollTop = scrollRef.value.scrollHeight
    }
  }
  return {
    scrollToBottom,
    scrollToBottomIfAtBottom,
  }
}
