/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { INavListType, IUseChartTwoDimTupleItem } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { sum } from 'lodash'
import { GIncreaseTip } from '../../ui/GIncreaseTip/GIncreaseTip'
import { GLimited } from '../../ui/GLimited/GLimited'
import { GLoading } from '../../ui/GLoading/GLoading'
import cn from './locale/cn.json'
import en from './locale/en.json'

export const BHeatmapIncreaseInfoNext = defineComponent({
  name: 'BHeatmapIncreaseInfoNext',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    listType: {
      type: String as PropType<INavListType>,
      required: true,
    },
    fetchData: {
      type: Function as PropType<
        (
          tupleItems: IUseChartTwoDimTupleItem<{ recent_30?: number; recent_30_from?: number; recent_30_to?: number }>[]
        ) => Promise<{ total: number; sub_total?: number; items: any[] }>
      >,
    },
    tupleItems: {
      type: Array as PropType<IUseChartTwoDimTupleItem<{ recent_30: number }>[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { tupleItems, fetchData, listType } = toRefs(props)
    const { locale } = useLocale()
    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const loading = ref(!!fetchData?.value)
    const ins = getCurrentInstance()
    const data = reactive({
      total: 0,
      sub_total: 0,
      items: [] as any[],
    })
    const increaseCount = computed(() => {
      if (fetchData?.value) {
        return data.total
      } else {
        const infoList = tupleItems.value.map((i) => i[1].otherInfo).filter((i) => !!i)
        if (infoList.length === 0) return 0
        return sum(infoList.map((i) => i?.recent_30 || 0))
      }
    })

    const wrapCount = (count: number | string) => `<span style="color: #1976D2;">${count}</span>`

    const increaseTip = computed(() => {
      const name = listType.value
      const count = increaseCount.value
      if (name === 'drug') {
        if (locale.value === 'en') {
          return `${wrapCount(count)} ${count === 1 ? 'drug' : 'drugs'} increase in last 30 days`
        } else if (locale.value === 'cn') {
          return `最近30天新增${wrapCount(count)}个药物`
        }
      }

      if (name === 'drug_group_dev_status') {
        if (fetchData?.value) {
          const drugCount = data.total || 0
          const statusCount = data.sub_total || 0
          if (locale.value === 'en') {
            return `${drugCount} ${drugCount === 1 ? 'drug' : 'drugs'}, including ${wrapCount(`${statusCount} R&D status`)} update in last 30 days`
          } else if (locale.value === 'cn') {
            return `最近30天更新${drugCount}个药物，涉及${wrapCount(`${statusCount}条研发状态`)}`
          }
        } else {
          if (locale.value === 'en') {
            return `${wrapCount(count)} ${'R&D status'} increase in last 30 days`
          } else if (locale.value === 'cn') {
            return `最近30天新增${wrapCount(count)}条研发状态`
          }
        }
      }

      if (name === 'clinical_trial') {
        if (locale.value === 'en') {
          return `${wrapCount(count)} ${count === 1 ? 'Clinical Trial' : 'Clinical Trials'} increase in last 30 days`
        } else if (locale.value === 'cn') {
          return `最近30天增长${wrapCount(count)}篇临床`
        }
      }

      if (name === 'news') {
        if (locale.value === 'en') {
          return `${wrapCount(count)} News increase in last 30 days`
        } else if (locale.value === 'cn') {
          return `最近30天增长${wrapCount(count)}篇新闻`
        }
      }

      if (name === 'patent') {
        if (locale.value === 'en') {
          return `${wrapCount(count)} ${count === 1 ? 'Patent' : 'Patents'} increase in last 30 days`
        } else if (locale.value === 'cn') {
          return `最近30天增长${wrapCount(count)}篇专利`
        }
      }

      return '无相关文案'
    })
    const fetchIncreaseData = async () => {
      loading.value = true
      if (fetchData?.value) {
        const rt = await fetchData.value(tupleItems.value)
        data.total = rt.total
        data.sub_total = rt.sub_total || 0
        data.items = rt.items
      }
      loading.value = false
    }

    const handleNavList = () => {
      ctx.emit('navList', tupleItems.value)
    }

    if (fetchData?.value) {
      fetchIncreaseData()
    }

    watch(tupleItems, () => {
      if (fetchData?.value) {
        fetchIncreaseData()
      }
    })

    return {
      isFreeUser,
      loading,
      locale,
      increaseCount,
      increaseTip,
      handleNavList,
      data,
    }
  },
  methods: {
    renderLoading() {
      return (
        <div key="loading" style="position: relative; height: 40px; min-width: 250px;">
          <GLoading size={24}></GLoading>
        </div>
      )
    },
    renderDrugs() {
      return (
        <div key="content" style="padding-left: 20px; margin-top: 4px;">
          <GLimited
            mode="link"
            totalCount={this.increaseCount}
            limit={3}
            items={this.data.items}
            linkTip={this.$tc('heatmapIncreaseInfo.viewInListTip')}
            inline={false}
            gap={{ x: 0, y: 4 }}
            scopedSlots={{
              default: ({ item }: { item: any }) => {
                return this.$scopedSlots && this.$scopedSlots.default && this.$scopedSlots.default({ item })
              },
            }}
            onClickTrigger={this.handleNavList}
          ></GLimited>
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        {this.loading
          ? this.renderLoading()
          : [
              <div key="tip" class={['cursor-pointer', 'pr-1', 'py-1', 'hover:bg-gray-30', 'rounded']} onClick={this.handleNavList}>
                <GIncreaseTip size="small" useHtml={true} tip={this.increaseTip}></GIncreaseTip>
              </div>,
              this.data.items.length && !this.isFreeUser ? this.renderDrugs() : null,
            ]}
      </div>
    )
  },
})
