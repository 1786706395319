import $jq from 'jquery'
import { forEach } from 'lodash'

export const setAlignStyle = (
  $origin: JQuery<HTMLElement>,
  $toAlign: JQuery<HTMLElement>,
  $origin0?: JQuery<HTMLElement>,
  $toAlign0?: JQuery<HTMLElement>
) => {
  const originList = $origin
  const transList = $toAlign
  let hsSetOffsetTop = false
  if (!originList.length || !transList.length || originList.length !== transList.length) return
  // 指定需要计算顶部偏移的元素

  if ($origin0 && $toAlign0) {
    const origin0Offset = $origin0.offset()
    const toAlign0Offset = $toAlign0.offset()
    let distance = 0
    if (origin0Offset && toAlign0Offset) {
      distance = origin0Offset.top - toAlign0Offset.top
    }
    ;(distance > 0 ? $toAlign0 : $origin0).css('margin-top', `${Math.abs(distance)}px`)
    hsSetOffsetTop = true
  }
  forEach(originList, (item, index) => {
    if (!item || !transList[index]) return
    const $origin = $jq(item)
    const $trans = $jq(transList[index])
    const originHeight = $origin.height() || 0
    const transHeight = $trans.height() || 0
    const originOffset = $origin.offset()
    const transOffset = $trans.offset()
    if (!hsSetOffsetTop && index === 0 && originOffset && transOffset) {
      const distance = originOffset.top - transOffset.top
      ;(distance > 0 ? $trans : $origin).css('margin-top', `${Math.abs(distance)}px`)
    }
    if (originHeight === transHeight) {
      return
    } else if (originHeight > transHeight) {
      $trans.css('height', `${originHeight}px`)
    } else {
      $origin.css('height', `${transHeight}px`)
    }
  })
}

export const removeAlignStyle = (
  $origin: JQuery<HTMLElement>,
  $align: JQuery<HTMLElement>,
  $origin0?: JQuery<HTMLElement>,
  $toAlign0?: JQuery<HTMLElement>
) => {
  const originList = $origin
  const transList = $align
  forEach(originList, (item) => {
    if (!item) return
    $jq(item).removeAttr('style')
  })
  forEach(transList, (item) => {
    if (!item) return
    $jq(item).removeAttr('style')
  })
  if ($origin0) $origin0.removeAttr('style')
  if ($toAlign0) $toAlign0.removeAttr('style')
}
