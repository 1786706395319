import '@patsnap-ui/icon/assets/solid/SynapseDisease.svg'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDictItem, ILang } from '@pharmsnap/shared/types'
import { getDictItemName } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BEntityTag, GLink } from '../..'

export const BDrugTypeTagItem = defineComponent({
  name: 'BDrugTypeTagItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseDictItem>,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const href = computed(() => sharedCtx.router.generatorDrugTypePath(data?.value?.dict_id || ''))
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const name = getDictItemName(data.value || [], locale.value)

    return {
      locale,
      href,
      name,
    }
  },
  render() {
    const content = (
      <BEntityTag data-testid="b-drug-type-item" title={this.name}>
        <GLink href={this.href} name={this.name}></GLink>
      </BEntityTag>
    )
    return content
  },
})
