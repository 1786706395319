import { createApis } from './apis'
import { createHttp } from './http/http'
import { ICreateServiceParams } from './http/types'
export { createObservable } from './http/create-observable'
export { IHttpWrap } from './http/types'
export function createService(config: ICreateServiceParams) {
  return createApis({
    http: createHttp(config),
    baseUrl: config.baseUrl,
    passportBaseUrl: config.passportBaseUrl,
    accountBaseUrl: config.accountBaseUrl,
    weChatBaseUrl: config.weChatBaseUrl,
    analysisBaseUrl: config.analysisBaseUrl,
    strapiBaseUrl: config.strapiBaseUrl,
    chemicalBaseUrl: config.chemicalBaseUrl,
  })
}
