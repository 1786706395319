/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, getCurrentInstance, ref, shallowRef } from '@vue/composition-api'
import { tryOnBeforeUnmount } from '@vueuse/core'

interface IUseAnalysisChartTooltipOption<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickTooltipDataFromChartParam: (params: any) => [string, T] | undefined
}

export function useAnalysisChartTooltip<T>(options: IUseAnalysisChartTooltipOption<T>) {
  const { pickTooltipDataFromChartParam } = options

  const tooltipDataMap = shallowRef<Record<string, T>>({})

  const uid = getCurrentInstance()?.uid

  const tooltipCls = `analysis-chart-tooltip-${uid}`

  const tooltipRef = ref<HTMLDivElement | null>(null)

  const tooltipActiveKey = ref('')

  const tooltipActiveData = computed(() => tooltipDataMap.value[tooltipActiveKey.value])

  const tooltipCommonConfig = {
    textStyle: {
      color: '#020A1A',
      fontSize: 12,
    },
    backgroundColor: '#fefefe',
    borderColor: '#333333',
    borderWidth: 0,
    appendToBody: true,
    padding: 0,
    className: tooltipCls,
    extraCssText: 'z-index: 999; border: none; box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 6px; max-width: 360px; overflow: hidden; white-space: normal;',
  }

  function tooltipFormatter(params: any) {
    const info = pickTooltipDataFromChartParam(params)
    if (!info) return ''
    const [key, data] = info
    tooltipDataMap.value = {
      ...tooltipDataMap.value,
      [key]: data,
    }
    tooltipActiveKey.value = key

    if (tooltipRef.value) return tooltipRef.value

    return ''
  }

  function resetTooltipDataMap() {
    tooltipDataMap.value = {}
  }

  tryOnBeforeUnmount(() => {
    tooltipDataMap.value = {}
    tooltipRef.value && tooltipRef.value.remove()
    tooltipRef.value = null
    const echartTooltipEl = document.querySelector(`.${tooltipCls}`)
    echartTooltipEl && echartTooltipEl.remove()
  })

  return {
    tooltipActiveData,
    tooltipFormatter,
    tooltipCls,
    tooltipRef,
    tooltipCommonConfig,
    resetTooltipDataMap,
  }
}
