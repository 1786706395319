import { ISearchParams } from '@patsnap/synapse_common_interface'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IWorkspaceDataType, IWorkspaceOriginParams } from './type'

const workspaceType2InterfaceKey: Record<
  IWorkspaceDataType,
  {
    countKey: string
    idKey: string
  }
> = {
  PATENT: {
    countKey: 'patent_count',
    idKey: 'patent_ids',
  },
  DRUG: {
    countKey: 'data_count',
    idKey: 'data_ids',
  },
  CLINICAL: {
    countKey: 'data_count',
    idKey: 'data_ids',
  },
}

export const SYNAPSE_QUERY_TAG_IN_WORKSPACE = 'SYNAPSE'

export function getWorkspaceQValue(id: string) {
  return `${SYNAPSE_QUERY_TAG_IN_WORKSPACE}:${id}`
}

export function formatSubmitSaveToWorkspaceParams(
  params: IWorkspaceOriginParams,
  dataType: IWorkspaceDataType,
  workspaceSearchId: string,
  selectedIds: string[]
) {
  const { countKey, idKey } = workspaceType2InterfaceKey[dataType]
  const commonParams = {
    data_type: dataType,
    family_expansion: params.familyExpansion,
    folder_id: params.folderId,
    space_id: params.workspaceId,
    [countKey]: params.counts,
    // 用于workspace业务埋点
    source_type: 'SYNAPSE',
  }
  if (selectedIds.length) {
    return {
      ...commonParams,
      type: dataType,
      [idKey]: selectedIds,
      [countKey]: selectedIds.length,
    }
  }
  return {
    ...commonParams,
    range: true,
    range_params: {
      end: params.to,
      q: getWorkspaceQValue(workspaceSearchId),
      query_id: workspaceSearchId,
      start: params.from,
    },
    // 用于workspace解析query,知道去哪个业务后端换取query内容
    type: SYNAPSE_QUERY_TAG_IN_WORKSPACE,
  }
}

export async function getWorkspaceSearchId(searchParams: ISearchParams) {
  const res = await sharedCtx.service.workspace.getWorkspaceSearchId(searchParams)
  if (res.success && res.data) {
    return res.data.query_id
  }
  return ''
}
