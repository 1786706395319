import { E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'

export default [
  {
    path: `compare-drug`,
    name: E_ROUTER_NAME.COMPARE_DRUG,
    component: () => import('@pharmsnap/pharmsnap-web/views/compare/CompareDrug'),
    meta: {
      title: createWebTitle('药物对比', 'Drug Compare'),
      notWide: true,
    },
  },
  {
    path: `compare-organization`,
    name: E_ROUTER_NAME.COMPARE_ORGANIZATION,
    component: () => import('@pharmsnap/pharmsnap-web/views/compare/CompareOrganization'),
    meta: {
      title: createWebTitle('机构对比', 'Organization Compare'),
      notWide: true,
    },
  },
  {
    path: `compare-target`,
    name: E_ROUTER_NAME.COMPARE_TARGET,
    component: () => import('@pharmsnap/pharmsnap-web/views/compare/CompareTarget'),
    meta: {
      title: createWebTitle('靶点对比', 'Target Compare'),
      notWide: true,
    },
  },
  {
    path: `compare-indication`,
    name: E_ROUTER_NAME.COMPARE_INDICATION,
    component: () => import('@pharmsnap/pharmsnap-web/views/compare/CompareIndication'),
    meta: {
      title: createWebTitle('适应症对比', 'Indication Compare'),
      notWide: true,
    },
  },
]
