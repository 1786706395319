import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { trackingEventSource, trackingPage } from 'pharmsnapMF_shared/utils'
import Vue from 'vue'
import VueRouter, { RawLocation, RouteConfig } from 'vue-router'
import { useLs360SynapseWujieEvents } from '../composable/useLs360SynapseWujieEvents'
import { checkHasUserToken, loginUnited } from '../utils/business/auth'
import { createWebTitle, updateDocumentTitle } from '../utils/business/document-title'
import clinicalRoutes from './modules/clinical'
import clinicalResultRoutes from './modules/clinical-result'
import compareRoutes from './modules/compare'
import competitiveRoutes from './modules/competitive'
import dashboard from './modules/dashboard'
import diseaseRoutes from './modules/disease'
import drugRoutes from './modules/drug'
import drugDealRoutes from './modules/drug-deal'
import drugTypeRoutes from './modules/drugType'
import emailAlertRoutes from './modules/emailAlert'
import homeRoutes from './modules/home'
import knowledgeGraphRoutes from './modules/knowledgeGraph'
import literatureRoutes from './modules/literature'
import newsRoutes from './modules/news'
import organizationRoutes from './modules/organization'
import patentRoutes from './modules/patent'
import reportRoutes from './modules/report'
import searchHistoryRoutes from './modules/searchHistory'
import ssrRedirectRouter from './modules/ssrRedirectRouter'
import targetRoutes from './modules/target'
import translationalMedicineRoutes from './modules/translational-medicine'

Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('../views/layout/Layout'),
    redirect: '/homepage',
    children: [
      {
        path: `homepage`,
        name: E_ROUTER_NAME.HOME,
        meta: {
          auth: true,
          activeKey: E_NAV_KEY.HOMEPAGE,
          hideQuickSearch: true,
        },
        component: () => import('@pharmsnap/pharmsnap-web/views/home/Home'),
      },
      ...homeRoutes,
      ...drugRoutes,
      ...diseaseRoutes,
      ...drugTypeRoutes,
      ...organizationRoutes,
      ...targetRoutes,
      ...competitiveRoutes,
      ...dashboard,
      ...clinicalRoutes,
      ...patentRoutes,
      ...newsRoutes,
      ...literatureRoutes,
      ...knowledgeGraphRoutes,
      ...searchHistoryRoutes,
      ...emailAlertRoutes,
      ...reportRoutes,
      ...compareRoutes,
      ...clinicalResultRoutes,
      ...drugDealRoutes,
      ...translationalMedicineRoutes,
      {
        path: '/read_fast',
        redirect: () => {
          return {
            name: E_ROUTER_NAME.READ_FAST,
          }
        },
      },
      {
        path: '/copilot-invite',
        redirect: () => {
          return {
            name: E_ROUTER_NAME.HOME,
          }
        },
      },
      {
        path: '/copilot-poster',
        redirect: () => {
          return {
            name: E_ROUTER_NAME.HOME,
          }
        },
      },
      {
        path: '/404',
        name: E_ROUTER_NAME.ERROR_PAGE_404,
        component: () => import('@pharmsnap/pharmsnap-web/views/errorPage/404'),
      },
      {
        path: '/error_page',
        name: E_ROUTER_NAME.ERROR_PAGE,
        component: () => import('@pharmsnap/pharmsnap-web/views/errorPage/ErrorPage'),
        meta: {
          hideQuickSearch: true,
        },
      },
      {
        path: 'error_page/read_fast',
        name: E_ROUTER_NAME.READ_FAST,
        component: () => import('@pharmsnap/pharmsnap-web/views/errorPage/ReadFast'),
      },
      {
        path: '/data_coverage',
        name: E_ROUTER_NAME.DATA_COVERAGE,
        component: () => import('@pharmsnap/pharmsnap-web/views/dataCoverage/DataCoverage'),
      },
      {
        path: '/export',
        name: E_ROUTER_NAME.EXPORT,
        component: () => import('@pharmsnap/pharmsnap-web/views/export/Export'),
      },
    ],
  },
  {
    path: '/',
    // component: HeaderLayout,
    component: () => import('@pharmsnap/pharmsnap-web/views/layout/HeaderLayout'),
    children: [
      {
        path: '/account-settings',
        name: E_ROUTER_NAME.ACCOUNT_SETTINGS,
        component: () => import('@pharmsnap/pharmsnap-web/views/account/AccountSettings'),
        children: [
          {
            path: '/personal-information',
            name: E_ROUTER_NAME.PERSONAL_INFO,
            component: () => import('@pharmsnap/pharmsnap-web/views/account/PersonalInformation/PersonalInformation'),
            meta: {
              hideQuickSearch: true,
            },
          },
          {
            path: '/usage',
            name: E_ROUTER_NAME.USAGE,
            component: () => import('@pharmsnap/pharmsnap-web/views/account/Usage/Usage'),
            meta: {
              hideQuickSearch: true,
            },
          },
          {
            path: '/plans',
            name: E_ROUTER_NAME.PLANS,
            component: () => import('@pharmsnap/pharmsnap-web/views/account/Plans/Plans'),
            meta: {
              hideQuickSearch: true,
            },
          },
          {
            path: '/security-setting',
            name: E_ROUTER_NAME.SECURITY_SETTING,
            component: () => import('@pharmsnap/pharmsnap-web/views/account/SecuritySetting/SecuritySetting'),
            meta: {
              hideQuickSearch: true,
            },
          },
        ],
      },
      {
        path: '/welcome',
        name: E_ROUTER_NAME.WELCOME,
        component: () => import('@pharmsnap/pharmsnap-web/views/welcome/Welcome'),
      },
      {
        path: '/complete-info',
        name: E_ROUTER_NAME.COMPLETE_INFO,
        component: () => import('@pharmsnap/pharmsnap-web/views/completeInfo/CompleteInfo'),
        meta: {
          hideQuickSearch: true,
        },
      },
    ],
  },
  {
    path: `/jump-host`,
    name: E_ROUTER_NAME.JUMP_HOST,
    component: () => import('@pharmsnap/pharmsnap-web/views/jumpHost/JumpHost'),
  },
  {
    path: '/onboarding',
    name: E_ROUTER_NAME.BOARDING,
    component: () => import('@pharmsnap/pharmsnap-web/views/onboarding/onboarding-v2'),
  },
  {
    path: '/wechatBind',
    name: E_ROUTER_NAME.WECHAT_BIND,
    component: () => import('@pharmsnap/pharmsnap-web/views/wechatBind/wechatBind'),
  },
  {
    path: '/link',
    name: E_ROUTER_NAME.LINK,
    component: () => import('@pharmsnap/pharmsnap-web/views/link/Link'),
  },
  // 游客版的页面，pc端不存在，重定向至首页或pc端对应的产品页
  ...ssrRedirectRouter,
  {
    path: '*',
    redirect: {
      name: E_ROUTER_NAME.ERROR_PAGE_404,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = async function push(location: RawLocation) {
  return originalPush
    .bind(router)(location)
    .catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = async function replace(location: RawLocation) {
  return originalReplace
    .bind(router)(location)
    .catch((err) => err)
}

function verify_webdriver() {
  const isWebDriver = navigator.webdriver
  if (isWebDriver) {
    // eslint-disable-next-line no-alert
    alert('请不要使用自动化测试工具访问网页')
    document.body.innerHTML = '请不要使用自动化测试工具访问网页'
    return false
  }
}

/**
 * 处理路由上的tracking数据
 */
router.beforeEach((to, from, next) => {
  const tracking = to.query.tracking as string
  if (tracking) {
    trackingEventSource().storeTrackingEventSourceFromRouter(tracking)
    delete to.query.tracking
    return next({
      ...to,
      replace: true,
    } as RawLocation)
  }
  return next()
})

router.beforeEach((to, from, next) => {
  verify_webdriver()
  window.dispatchEvent(new CustomEvent('SpaPageChange', { detail: { toPageName: to.name } }))
  const businessNext = () => {
    updateDocumentTitle(to.meta?.title || createWebTitle('全球新药竞争情报库', 'Global Drug Intelligence Database'))
    next()
  }
  if (to.meta?.auth) {
    // 需要鉴权的路由
    if (checkHasUserToken()) {
      // 需要鉴权,且已经登录 pass
      return businessNext()
    }
    // 没有登录,跳转到登录页
    return loginUnited()
  }
  // 不需要鉴权的页面,pass
  return businessNext()
})
const { bus } = useLs360SynapseWujieEvents()
router.afterEach((to, from) => {
  trackingPage().setPageInfo(to, from)
  bus.$emit('main-synapse:page-change', {
    from: {
      name: from.name || '',
      path: from.path,
      href: window.origin + router.resolve(from as any).href,
    },
    to: {
      name: to.name || '',
      path: to.path,
      href: window.origin + router.resolve(to as any).href,
    },
  })
})

export default router
