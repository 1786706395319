export class SessionChecker {
  sessionIdStoreKey = 'SYNAPSE_SESSION'
  checkIsNewSession(sessionId: string) {
    const oldSessionId = this.getStoreSessionId()
    this.tryStoreSessionId(sessionId)
    // 不存在即为新的session
    if (!oldSessionId) {
      return true
    }
    if (!sessionId) {
      console.warn('sessionId is empty')
      return false
    }

    return oldSessionId !== sessionId
  }
  private tryStoreSessionId(sessionId: string) {
    if (sessionId) {
      localStorage.setItem(this.sessionIdStoreKey, sessionId)
    }
  }
  private getStoreSessionId() {
    return localStorage.getItem(this.sessionIdStoreKey) || undefined
  }
}
