import { IListItem } from '@patsnap/synapse_common_interface'
import { IBAcTag, IBrowseCountType, IBrowseType, ISelectedLabel } from '@pharmsnap/shared/src/types'
import { PropType, computed, defineComponent, nextTick, ref, watch } from '@vue/composition-api'
import { differenceWith, filter } from 'lodash'
import { BAcTag, BAcTagList, BBrowseNew, BLabelAc, GDialog, GIcon } from 'pharmsnapMF_shared/components'
import $style from './BAcTagListWithBrowse.module.scss'

export const BAcTagListWithBrowse = defineComponent({
  name: 'BAcTagListWithBrowse',
  props: {
    /**
     * 前面的label
     */
    label: {
      type: String,
    },
    browseType: {
      type: String as PropType<IBrowseType>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<IBAcTag[]>,
      required: true,
    },
    tagItems: {
      type: Array as PropType<IListItem[]>,
      default: () => [],
    },
    isClickAble: {
      type: Boolean,
      default: true,
    },
    buttonTextWithSelected: {
      type: String,
    },
    buttonTextWithoutSelected: {
      type: String,
    },
    browseTitle: {
      type: String,
    },
    /**
     * browse的数量类型，可展示药物数量、专利数量
     */
    browseCountType: {
      type: String as PropType<IBrowseCountType>,
      default: 'drug',
    },
    /**
     * 剩余个数
     */
    leftNum: {
      type: Number,
    },
  },
  setup(props) {
    const browseVisible = ref(false)
    const browseDialogVisible = ref(false)

    watch(browseDialogVisible, (visible) => {
      nextTick(() => {
        browseVisible.value = visible
      })
    })

    const selectedMoreCount = computed(() => {
      const selectedDefaultTagItems = differenceWith(
        props.selectedItems,
        props.tagItems,
        (selectedItem, defaultTagItem) => selectedItem.id === defaultTagItem.id
      )
      return selectedDefaultTagItems.length
    })

    const browseSelectedItems = computed(() => {
      return filter(props.selectedItems, (item) => item.id !== 'All')
    })

    function handleClickMore() {
      if (!props.isClickAble) return
      browseDialogVisible.value = true
    }

    return {
      browseVisible,
      browseDialogVisible,
      selectedMoreCount,
      browseSelectedItems,
      handleClickMore,
    }
  },
  methods: {
    renderTagList() {
      return (
        <BLabelAc label={this.label}>
          <template slot="ac">
            <div class="flex flex-wrap">
              <BAcTagList
                tagItems={this.tagItems}
                selectedItems={this.selectedItems}
                onClick={(val: IBAcTag) => {
                  this.$emit('selectTag', val)
                }}
              >
                <div slot="more">{this.renderBrowseButton()}</div>
              </BAcTagList>
            </div>
          </template>
        </BLabelAc>
      )
    },
    renderBrowseButton() {
      if (this.$slots.browseButton) {
        return <div onClick={this.handleClickMore}>{this.$slots.browseButton}</div>
      }
      const buttonTextDom = this.selectedMoreCount ? (
        <div class="inline-flex items-center">
          +{this.selectedMoreCount}&nbsp;
          {this.buttonTextWithSelected}
          <GIcon svgName="SolidEdit" size={16} class="ml-1"></GIcon>
        </div>
      ) : (
        this.buttonTextWithoutSelected
      )

      const ownerClass = this.selectedMoreCount ? $style.tagSelected : this.isClickAble ? $style.tagAble : $style.tag

      return (
        <BAcTag canClose={false} onClick={this.handleClickMore} ownerClass={ownerClass}>
          {buttonTextDom}
        </BAcTag>
      )
    },
  },
  render() {
    return (
      <div class="my-2">
        {this.renderTagList()}
        <GDialog
          width="764px"
          emptyMode
          destroyOnClose
          visible={this.browseDialogVisible}
          onClose={() => {
            this.browseDialogVisible = false
          }}
        >
          <BBrowseNew
            title={this.browseTitle}
            style="position: static"
            showOnTop={false}
            type={this.browseType}
            searchByVisible
            visible={this.browseVisible}
            onCloseBrowse={() => {
              this.browseDialogVisible = false
            }}
            onBrowseSubmit={(list: ISelectedLabel[]) => {
              this.$emit('browseSubmit', list)
            }}
            leftNum={this.leftNum}
            tags={this.browseSelectedItems}
            countType={this.browseCountType}
          />
        </GDialog>
      </div>
    )
  },
})
