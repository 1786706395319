import '@patsnap-ui/icon/assets/solid/ResizeMaximum.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { GTooltip } from '../..'
import { GDialog } from '../GDialog/GDialog'
import { GIcon } from '../GIcon/GIcon'
import $style from './GExpandDialog.module.scss'
export const GExpandDialog = defineComponent({
  name: 'GExpandDialog',
  props: {
    dialogProps: {
      type: Object,
    },
    expandTitle: {
      type: String,
    },
  },
  setup(props) {
    const visible = ref(false)
    const { isCN } = useLocale()
    const isShowSlot = ref(false)
    const _expandTitle = computed(() => {
      if (props.expandTitle) {
        return props.expandTitle
      }
      return isCN.value ? '查看详情' : 'View Detail'
    })
    function onClickExpand() {
      visible.value = true
    }
    watch(visible, (value) => {
      // 为了解决slot的内容的如果还有弹窗的会显示在dialog的后面
      // 原因: slot的内容会先渲染,再渲染此组件,由于z-index的层次是统一管理的,后渲染的层级会更高,所以导致slot的内容的如果还有弹窗的会显示在dialog的后面
      // 解决方法: 在dialog弹窗渲染之后再渲染弹窗中的内容
      setTimeout(() => {
        isShowSlot.value = value
      }, 0)
    })
    return { visible, onClickExpand, _expandTitle, isShowSlot }
  },
  render() {
    return (
      <div class={$style.main} data-testid="g-expand-dialog">
        {this.$slots.default ? <div class={$style.content}>{this.$slots.default}</div> : null}
        {this.$slots.triggerDialog ? (
          <div onClick={this.onClickExpand}>{this.$slots.triggerDialog}</div>
        ) : (
          <div class={$style.expand} onClick={this.onClickExpand}>
            <GTooltip content={this._expandTitle}>
              <GIcon
                size={20}
                data-testid="g-expand-dialog__trigger"
                class="hover:bg-gray-30  text-gray-450 cursor-pointer pharmsnap-hover-visible"
                shape="square"
                svgName="SolidResizeMaximum"
              />
            </GTooltip>
          </div>
        )}
        <GDialog v-model={this.visible} {...{ props: this.dialogProps }}>
          {this.$scopedSlots.dialog && this.$scopedSlots.dialog({ isShowSlot: this.isShowSlot })}
          {this.$slots.title && <template slot="title">{this.$slots.title}</template>}
        </GDialog>
      </div>
    )
  },
})
