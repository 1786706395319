import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDrugInfo, ILang } from '@pharmsnap/shared/types'
import { getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BDrugPopperCard, BEntityTag, GIcon, GLink } from '../..'

export const BDrugItem = defineComponent({
  name: 'BDrugItem',
  props: {
    data: {
      type: Object as PropType<IBaseDrugInfo>,
    },
    defaultColor: {
      type: String as PropType<'blue' | 'dark'>,
      default: 'dark',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showCard: {
      type: Boolean,
      default: false,
    },
    // 是否显示双语，英文 然后换行中文
    showBilingual: {
      type: Boolean,
      default: false,
    },
    /**
     * 双语显示的位置
     */
    bilingualPosition: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'vertical',
    },
    /**
     * 显示纯文本，不加链接
     */
    textOnly: {
      type: Boolean,
      default: false,
    },
    zIndex: Number,
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const href = computed(() => (data?.value?.drug_id && !props.textOnly ? sharedCtx.router.generatorDrugPath(data.value.drug_id) : ''))
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const displayName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: locale.value }))
    const name_en = getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'EN', isDegraded: false })
    const name_cn = getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'CN', isDegraded: false })
    return {
      href,
      displayName,
      name_en,
      name_cn,
      isCN,
    }
  },
  methods: {
    renderTitle(name: string) {
      const _renderDrugContent = () => {
        return (
          <BEntityTag title={name} data-testid="b-drug-item">
            {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="Drug" /> : null}
            <GLink data-selection-disabled={this.showCard} href={this.href} name={name} defaultColor={this.defaultColor}></GLink>
          </BEntityTag>
        )
      }

      const id = this.data?.drug_id
      if (id && this.showCard) {
        return (
          <BDrugPopperCard id={id} zIndex={this.zIndex}>
            {_renderDrugContent()}
          </BDrugPopperCard>
        )
      }
      return _renderDrugContent()
    },
  },
  render() {
    if (this.showBilingual && this.name_cn && this.name_en) {
      const isHorizontal = this.bilingualPosition === 'horizontal'
      return (
        <div class={isHorizontal ? 'flex items-center' : ''}>
          {this.renderTitle(this.name_en)}
          {this.isCN && <div class="text-text-default text-sm truncate pl-2">{this.name_cn}</div>}
        </div>
      )
    }

    return (this as any).renderTitle(this.displayName || EMPTY_PLACEHOLDER)
  },
})
