import { I18nLang } from '@patsnap/synapse_common_interface'
import {
  IBaseReportHomeReportInfo,
  IReportDetail,
  IReportDownloadLink,
  IReportGetDetailParam,
  IReportListItem,
  IReportSearchParam,
  ISearchResult,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class ReportApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getReportList(params: IReportSearchParam): Promise<IHttpWrap<ISearchResult<IReportListItem>>> {
    return this.http({
      method: 'GET',
      params: {
        ...params,
        source: 'PC',
      },
      url: this.getUrl('pharmsnap_webapi/1.0/report'),
    })
  }

  async getReportDetail(params: IReportGetDetailParam, isRead?: boolean): Promise<IHttpWrap<IReportDetail>> {
    return this.http({
      method: 'GET',
      params: {
        source: 'PC',
        ...(isRead
          ? {
              action: 'read',
            }
          : {}),
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/report/${params.report_id}`),
    })
  }
  async getReportPreviewDetail(params: IReportGetDetailParam, isRead?: boolean): Promise<IHttpWrap<IReportDetail>> {
    return this.http({
      method: 'GET',
      params: {
        source: 'PC',
        ...(isRead
          ? {
              action: 'read',
            }
          : {}),
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/report/detail/${params.report_id}`),
    })
  }

  async getHomepageReport(): Promise<IHttpWrap<ISearchResult<IBaseReportHomeReportInfo>>> {
    return this.http({
      method: 'GET',
      params: {
        source: 'PC',
      },
      url: this.getUrl('pharmsnap_webapi/1.0/report/homepage'),
      cache: true,
    })
  }

  async getDownloadLink(
    params: IReportGetDetailParam,
    purpose: 'download' | 'sample' | 'online' = 'download',
    lang: I18nLang
  ): Promise<IHttpWrap<IReportDownloadLink>> {
    return this.http({
      method: 'GET',
      params: {
        purpose,
        lang,
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/report/${params.report_id}/url`),
      cache: false,
    })
  }
}
