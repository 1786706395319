import type { IOrganizationOwnershipType, IOrgType } from '@patsnap/synapse_common_interface'

export const organizationTypeIconMap: Record<IOrgType, string> = {
  Company: 'Company',
  Education: 'University',
  Government: 'Government',
  Nonprofit: 'Government',
}

export const ownershipTypeIconMap: Record<IOrganizationOwnershipType, string> = {
  Public: 'PublicCompany',
  Holding: 'HoldingCompany',
  'Joint Venture': 'JointVentureCompany',
  Private: 'Company',
  Subsidiary: 'SubsidiaryCompany',
}
