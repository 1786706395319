import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { BEntityLoadingCard, GDescription, GIconLink, GLimited } from '@pharmsnap/shared/components'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderPhase } from '@pharmsnap/shared/render'
import { useLocale } from '@pharmsnap/shared/src/composition'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { IBaseDrugInfo, ITargetCardInfo } from '@pharmsnap/shared/types'
import { CTRL_C_INFO, getAllSpecialLang } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api'
import '../../../../assets/icon-svg/target.svg'
import { BDrugItem } from '../../BDrugItem/BDrugItem'
import $classes from '../EntityCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BTargetCard = defineComponent({
  name: 'BTargetCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<ITargetCardInfo>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { locale, localeUpcase } = useLocale()
    const relationDrugs = computed(() => data?.value?.related_drug_id_view || [])
    const displayName = computed(
      () => getLangDegraded({ name_cn: data?.value?.entity_name_cn, name_en: data?.value?.entity_name_en }, locale.value) || EMPTY_PLACEHOLDER
    )
    const aliasName = computed(() => {
      const names = getAllSpecialLang({ data: data?.value?.alias || [], lang: localeUpcase.value, field: 'name' })
      if (names.length === 0) return ''
      return names.join(' | ')
    })
    const phase = computed(() => {
      const highestPhase = data?.value?.highest_dev_status_view
      const name = localeUpcase.value === 'CN' ? highestPhase?.name_cn || highestPhase?.name_en : highestPhase?.name_en
      return name
    })
    return {
      locale,
      localeUpcase,
      relationDrugs,
      displayName,
      aliasName,
      phase,
    }
  },
  methods: {
    renderContent() {
      if (!this.data) return null

      return (
        <GDescription
          width={135}
          data={this.data}
          size="medium"
          direction={this.direction}
          gapSize={this.direction === 'vertical' ? 'mini' : 'base'}
          items={[
            {
              label: this.$t('targetCard.relatedDrugs') as string,
              field: 'relation_drug',
            },
            {
              label: this.$t('targetCard.highestPhase') as string,
              field: 'highest_dev_status',
            },
          ]}
          scopedSlots={{
            relation_drug: ({ data }: { data: ITargetCardInfo }) => {
              const relatedDrugs = data.related_drug_id_view || []
              const totalCount = data.related_drug_id_count || 0
              if (relatedDrugs.length === 0 || totalCount === 0) return EMPTY_PLACEHOLDER
              return (
                <GLimited
                  mode="link"
                  totalCount={totalCount}
                  items={relatedDrugs}
                  limit={3}
                  linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
                  link={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
                  inline={false}
                  scopedSlots={{
                    default: ({ item }: { item: IBaseDrugInfo }) => <BDrugItem data={item}></BDrugItem>,
                  }}
                ></GLimited>
              )
            },

            highest_dev_status: () =>
              renderPhase(
                {
                  currentPhase: this.data?.highest_dev_status_view,
                  beforePhase: this.data?.highest_dev_status_termination_view,
                  fontClassName: this.data?.fontClassName,
                },
                this.localeUpcase
              ),
          }}
        ></GDescription>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc={true} loading={this.loading} border={this.border}>
        <div class={[$classes.card, CTRL_C_INFO, !this.border && $classes.cardNoBorder]}>
          <div class={$classes.header}>
            <div class={[$utilClasses.lsFlex]}>
              <GIconLink
                class={$classes.title}
                encryptClass={this.data?.fontClassName}
                ellipsisLine={1}
                href={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
                name={this.displayName}
                newTab={true}
                defaultColor="blue"
                svgName={this.showIcon ? 'Target' : ''}
                size={20}
              ></GIconLink>
            </div>
            {this.aliasName ? <div class={[$classes.subtitle, 'pl-7 mt-0']}>{this.aliasName}</div> : null}
          </div>
          <div class={$classes.body}>{this.renderContent()}</div>
        </div>
      </BEntityLoadingCard>
    )
  },
})
