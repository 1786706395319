import { Bar, Duplex, ECChartOption, Line, ThemeDriver } from '@pharmsnap/shared/chart'
import { ECOption } from '@pharmsnap/shared/chart/core/plot'
import { Graph } from '@pharmsnap/shared/chart/plots/graph'
import { HeatMap } from '@pharmsnap/shared/chart/plots/heatmap'
import { HorizontalBar } from '@pharmsnap/shared/chart/plots/horizontal-bar'
import { Pie } from '@pharmsnap/shared/chart/plots/pie'
import { RaDar } from '@pharmsnap/shared/chart/plots/radar'
import { WorldMap } from '@pharmsnap/shared/chart/plots/worldmap'
import { Ref, computed, onBeforeUnmount, ref, watch } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { getChartWaterMark } from '../utils'
import { useAuthStore } from './useAuthStore'
import { useElementSize } from './useElementSize'
import { useLocale } from './useLocale'

const waterMarkId = 'chartWaterMark'
export function useChart(
  chartOption: Ref<ECChartOption>,
  config: {
    autoResize?: boolean
    beforeInitHook?: () => Promise<void> | void
    registerEvent?: (instance: EChartsType) => void
    needWaterMark?: boolean
  }
) {
  const {
    getters: { isBasicUser, isEnterpriseUser },
  } = useAuthStore()
  const { isCN } = useLocale()

  const chartContainer = ref<HTMLElement>()
  const options = ref<ECOption>()

  const waterMarkInfo = computed(() => {
    if (isBasicUser.value || isEnterpriseUser.value) return undefined
    return getChartWaterMark(isCN.value)
  })

  let chart: Bar | Line | HorizontalBar | HeatMap | WorldMap | Pie | Duplex | ThemeDriver | RaDar | Graph | null
  let el: HTMLDivElement | null = null

  const initChart = () => {
    if (!chartContainer.value) return
    if (chart) return
    el = document.createElement('div')
    el.style.height = '100%'
    el.style.width = '100%'
    chartContainer.value.style.position = 'relative'
    chartContainer.value.appendChild(el)
    if (chartOption.value.type === 'bar') {
      chart = new Bar(chartOption.value, el)
    } else if (chartOption.value.type === 'line') {
      chart = new Line(chartOption.value, el)
    } else if (chartOption.value.type === 'horizontal-bar') {
      chart = new HorizontalBar(chartOption.value, el)
    } else if (chartOption.value.type === 'heatmap') {
      chart = new HeatMap(chartOption.value, el)
    } else if (chartOption.value.type === 'world-map') {
      chart = new WorldMap(chartOption.value, el)
    } else if (chartOption.value.type === 'pie') {
      chart = new Pie(chartOption.value, el)
    } else if (chartOption.value.type === 'duplex') {
      chart = new Duplex(chartOption.value, el)
    } else if (chartOption.value.type === 'theme-river') {
      chart = new ThemeDriver(chartOption.value, el)
    } else if (chartOption.value.type === 'radar') {
      chart = new RaDar(chartOption.value, el)
    } else if (chartOption.value.type === 'graph') {
      chart = new Graph(chartOption.value, el)
    }
    if (chart) {
      options.value = chart.options
    }
    // chart && config && config.autoResize && registerAutoResize()
    chart && config.registerEvent && config.registerEvent(chart.instance)
  }

  const getEchartsInstance = () => {
    if (!chart) initChart()
    if (chart) return chart.instance
  }

  const dispose = () => {
    const instance = getEchartsInstance()
    if (instance) instance.dispose()
  }

  const render = (op: ECOption) => {
    const { needWaterMark = true } = config
    const elements = op.graphic && 'elements' in op.graphic ? op.graphic.elements || [] : []
    const hasAddWaterMark = elements.find((element) => element.id === waterMarkId)

    if (needWaterMark && waterMarkInfo.value && !hasAddWaterMark) {
      const chartContainerClientRect = chartContainer.value?.getBoundingClientRect()
      const { waterMark, imgWidth, imgHeight } = waterMarkInfo.value

      let waterMarkLeft: string | number = '30%'
      if (chartContainerClientRect) {
        const { width } = chartContainerClientRect
        waterMarkLeft = (width - imgWidth) / 2
      }
      op.graphic = {
        ...op.graphic,
        elements: elements.concat([
          {
            type: 'image',
            id: waterMarkId,
            style: {
              image: waterMark,
              width: imgWidth,
              height: imgHeight,
            },
            left: waterMarkLeft,
            top: '30%',
            cursor: 'auto',
          },
        ]),
      }
    }
    options.value = op
    const chartInstance = getEchartsInstance()
    if (chartInstance) {
      chartInstance.clear()
      chartInstance.setOption(options.value as ECOption)
      chartInstance.resize()
    }
  }

  const resize = () => {
    const instance = getEchartsInstance()
    if (instance && chart) {
      // instance.clear()
      chart.updateOption(chartOption.value)
      instance.resize()
    }
  }

  const hideTooltip = () => {
    const instance = getEchartsInstance()
    if (!instance) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const components = instance._componentsViews
    if (!components) return
    const tooltipComponent = components.find((i: any) => i.type === 'tooltip')
    if (!tooltipComponent) return
    tooltipComponent._tooltipContent && tooltipComponent._tooltipContent.hide && tooltipComponent._tooltipContent.hide()
  }

  const { width, height } = useElementSize(chartContainer as Ref<HTMLDivElement>)

  if (config.autoResize) {
    watch([width, height], () => {
      resize()
    })
  }

  onBeforeUnmount(() => {
    dispose()
    // chart && config && config.autoResize && unRegisterAutoResize()
  })

  watch(chartOption, async (newVal, oldVal) => {
    if (!chart) return
    if (newVal.type === oldVal.type) {
      chart.updateOption(newVal)
      // https://github.com/apache/echarts/issues/5093
      // setOption在主线程中刷新数据，图表更新会有问题，看了下git，目前先放在setTimeout里面处理一下
      // await nextTick()
      setTimeout(() => {
        // 可能 chart 没了
        chart && render(chart.options)
      }, 16)
    } else {
      // 类型不同，需要重新设置chart
      chart.instance.dispose()
      if (newVal.type === 'bar') {
        chart = new Bar(newVal, chart.container)
      } else if (newVal.type === 'line') {
        chart = new Line(newVal, chart.container)
      } else if (newVal.type === 'horizontal-bar') {
        chart = new HorizontalBar(newVal, chart.container)
      } else if (newVal.type === 'heatmap') {
        chart = new HeatMap(newVal, chart.container)
      } else if (newVal.type === 'world-map') {
        chart = new WorldMap(newVal, chart.container)
      } else if (newVal.type === 'pie') {
        chart = new Pie(newVal, chart.container)
      } else if (chartOption.value.type === 'duplex') {
        chart = new Duplex(newVal, chart.container)
      } else if (chartOption.value.type === 'theme-river') {
        chart = new ThemeDriver(newVal, chart.container)
      } else if (chartOption.value.type === 'radar') {
        chart = new RaDar(newVal, chart.container)
      }
      chart && config.registerEvent && config.registerEvent(chart.instance)
      render(chart.options)
    }
  })

  watch(chartContainer, async (newVal) => {
    if (newVal) {
      if (config.beforeInitHook) await config.beforeInitHook()
      initChart()
      options.value && render(options.value)
    } else {
      dispose()
      chart = null
      el = null
    }
  })

  return {
    initChart,
    hideTooltip,
    getEchartsInstance,
    chartContainer,
    options,
    render,
    width,
    height,
  }
}
