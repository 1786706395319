import { I18nLang } from '../i18n'
import { IBaseDictItem, IFieldLangMap, ILang } from '../types'

export function getFieldNameByLang(langMap: Partial<IFieldLangMap>, field: string | undefined, lang: I18nLang) {
  if (!field) {
    console.warn(`getFieldLangName: 字段值为空,忽略翻译,返回空值`)
    return ''
  }
  const fieldLangs = langMap[field]
  if (!fieldLangs) {
    console.error(`getFieldLangName: 没有找到${field}的语言配置,请检查`)
    return ''
  }
  return fieldLangs[lang]
}

/**
 * 更具当前语言自动降级的获取语言字段
 * @param data 含有name_cn,name_en的数据
 * @param lang I18nLang
 * @returns string
 */
export function getLangDegraded<T extends { name_cn?: string; name_en?: string }>(data: T, lang: I18nLang) {
  return lang === 'cn' ? data?.name_cn || data?.name_en || '' : data?.name_en || data?.name_cn || ''
}

/**
 * 更具当前语言自动降级的获取语言字段
 * @param data 含有display_name_cn,display_name_en的数据
 * @param lang I18nLang
 * @returns string
 */
export function getDisplayNameDegraded<T extends { display_name_cn?: string; display_name_en?: string }>(data: T, lang: I18nLang) {
  return lang === 'cn' ? data.display_name_cn || data.display_name_en : data.display_name_en || data.display_name_cn
}

/**
 * 根据目标语言获取一个国际化的数据
 * @param data 含有lang字段的语言数据数组
 * @param field 文体字段
 * @param lang 目标语言
 * @param isDegraded 未匹配到目标语言的相关数据，是否降级查找其他语言
 * @returns
 */
export function getSpecialLang<T extends { lang: ILang }, P extends keyof T>(params: { data: T[]; field: P; lang: ILang; isDegraded?: boolean }) {
  const { data, field, lang, isDegraded = true } = params
  let nameDegradeChain: ILang[] = [lang]
  if (isDegraded) {
    nameDegradeChain = lang === 'CN' ? ['CN', 'EN'] : ['EN', 'CN']
  }
  for (const _lang of nameDegradeChain) {
    const rt = data.find((item) => item.lang === _lang && item[field])
    if (rt) {
      return rt[field]
    }
  }
  return undefined
}

/**
 * 根据目标语言获取匹配的国际化数组
 * @param params
 * @param params.data 含有lang字段的语言数据数组
 * @param params.field 文体字段
 * @param params.lang 目标语言
 * @param params.isDegraded 未匹配到目标语言的相关数据，是否降级查找其他语言
 * @returns
 */
export function getAllSpecialLang<T extends { lang: ILang }, P extends keyof T>(params: { data: T[]; field: P; lang: ILang; isDegraded?: boolean }) {
  const { data, field, lang, isDegraded = true } = params
  let nameDegradeChain: ILang[] = [lang]
  if (isDegraded) {
    nameDegradeChain = lang === 'CN' ? ['CN', 'EN'] : ['EN', 'CN']
  }

  for (const _lang of nameDegradeChain) {
    const rt = (data || []).filter((i) => i.lang === _lang && !!i[field]).map((i) => i[field])
    if (rt.length) {
      return rt
    }
  }
  return []
}

export function getDicItemName(data: IBaseDictItem, lang: ILang): string {
  return (lang === 'CN' ? data.name_cn : data.name_en) || ''
}
