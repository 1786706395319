import { AggOneDimDataItem } from '@patsnap/synapse_domain'
import { useBasicBarChartNavList, useWorldmapChartNavList } from '@pharmsnap/shared/src/composition'
import { transAggregationValue2QueryItem } from '@pharmsnap/shared/utils'
import { unref } from '@vue/composition-api'
import { IUseAnalysisOneDimChartNavListOptions } from '../type'

export function useAnalysisOneDimChartNavList<T extends AggOneDimDataItem = AggOneDimDataItem>(options: IUseAnalysisOneDimChartNavListOptions<T>) {
  const {
    query = { type: 'group', must: [], filter: [] },
    navList,
    appendTo = 'filter',
    chartType,
    customAggValueTransformer,
    customQueryItemPicker,
    adjustQueryField,
    interceptors,
    searchParams,
    tracking,
    adjustSearchParams,
  } = options

  const navListOptions = {
    query,
    appendTo,
    navList,
    interceptors,
    adjustQueryField,
    customQueryItemPicker: customQueryItemPicker || innerCustomQueryItemPicker,
    searchParams,
    tracking,
    adjustSearchParams,
  }

  const { onClickBar: onClickPieOrLine } = useBasicBarChartNavList(navListOptions)

  const { onClickWorldmap } = useWorldmapChartNavList(navListOptions)

  function innerCustomQueryItemPicker(data: T | T[]) {
    const formattedData = Array.isArray(data) ? data : [data]
    const aggValues = formattedData.map((item) => item.aggValue)
    const customQueryItem = customAggValueTransformer?.(aggValues)
    return customQueryItem || transAggregationValue2QueryItem(aggValues)
  }

  function handleClick(data: T | T[]) {
    const type = unref(chartType)
    if (type === 'pie' || type === 'line' || type === 'bar') {
      onClickPieOrLine(data)
    }
    if (type === 'world') {
      onClickWorldmap(data)
    }
  }

  return {
    handleClick,
  }
}
