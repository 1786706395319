import type { INewsTranslation } from '@patsnap/synapse_common_interface'
import { sharedCtx } from '@pharmsnap/shared/context'
import dayjs from 'dayjs'
import { IVueI18n } from 'vue-i18n'
import { BNewsChannelTag, GLink, GTranslate } from '../components'
import { EMPTY_PLACEHOLDER } from '../constants'
import { I18nLang } from '../i18n'
import type { ILang, INewsDetail, ITranslateLang } from '../types'
import { getDisplaySource, getRedirectLinkStatic } from '../utils/common'
import { encryptNum } from '../utils/encrypt/encrypt'
import { formatTimestamp } from '../utils/time'
import { isOpenNewsTranslate } from '../utils/translate'
import {
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedDrugAsync,
  renderPopoverLimitedOrgAsync,
  renderPopoverLimitedTargetsAsync,
} from './baseRender'

export function getNewsTranslateText(data: INewsDetail, key: keyof INewsTranslation): string {
  const { news_translation } = data
  if (!news_translation) {
    return ''
  }
  return (news_translation[key] || '') as string
}

export function renderNewsText(text?: string) {
  return (
    <div class="text-sm text-black-default" style={{ 'word-break': 'break-word' }}>
      {text || EMPTY_PLACEHOLDER}
    </div>
  )
}

export function renderNewsPublishDate(data: INewsDetail, $i18n: IVueI18n) {
  if (!data.dmp_post_time && !data.post_time) {
    return EMPTY_PLACEHOLDER
  }

  const postTime = data.dmp_post_time || data.post_time
  if (!postTime) {
    return ''
  }

  const diffHours = dayjs().diff(postTime.time_ts, 'hour')
  const locale = $i18n.locale.toUpperCase() as ILang
  return diffHours < 24 && diffHours > 0
    ? `${diffHours} ${$i18n.t('news.diffHour')}`
    : diffHours === 0
    ? `${$i18n.t('news.withinAnHour')}`
    : formatTimestamp(postTime, { locale, isUtc: false })
}

export function renderNewsTitleInTable(data: INewsDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const translate = isOpenNewsTranslate(data, customSettingTranslation)
  const originalText = data.title
  const translateText = ''
  const executeTranslate = translateText ? translate : false

  return (
    <GTranslate execute={executeTranslate} content={translateText || EMPTY_PLACEHOLDER}>
      <GLink
        href={sharedCtx.router.generatorNewsDetailPath(data.news_id)}
        name={originalText || EMPTY_PLACEHOLDER}
        newTab={true}
        defaultColor="blue"
        class="whitespace-pre-wrap text-sm leading-6"
      ></GLink>
    </GTranslate>
  )
}

export function renderNewsSourceUrl(data: INewsDetail) {
  return (
    <GLink
      href={data.source_url}
      name={data.source_url_host || EMPTY_PLACEHOLDER}
      newTab={true}
      defaultColor="blue"
      class="whitespace-pre-wrap text-sm leading-6"
    ></GLink>
  )
}
/**
 * 渲染新闻发布时间,来源,标签
 * @param data
 * @param $i18n
 * @returns
 */
export function renderNewsPublishDataAndSourceInfo(data: INewsDetail, $i18n: IVueI18n) {
  const publishDate = <span class="icon-font leading-6" domPropsInnerHTML={encryptNum(renderNewsPublishDate(data, $i18n))}></span>

  const channelView =
    data.channel_view && data.channel_view.name_en !== 'Other' ? <BNewsChannelTag data={data.channel_view} style="margin: 0"></BNewsChannelTag> : null

  const selfCreateTag = (
    <div class="px-2 h-5 inline-flex items-center justify-center bg-blue-default text-white-default text-sm rounded">{$i18n.t('news.original')}</div>
  )

  const sourceText = (
    <GLink class="leading-6" href={getRedirectLinkStatic(data.source_url || '', $i18n.locale as I18nLang)} defaultColor="blue" newTab>
      {getDisplaySource({ source: data.source, source_url_host: data.source_url_host }, $i18n.locale as I18nLang)}
    </GLink>
  )

  const isSelfCreate = !!data.self_created_flag

  return (
    <div class="flex items-center">
      {[
        publishDate,
        channelView ? <span class="mx-1 leading-6">·</span> : null,
        channelView,
        <span class="mx-1 leading-6">{isSelfCreate ? '' : '·'}</span>,
        isSelfCreate ? selfCreateTag : sourceText,
      ]}
    </div>
  )
}

export function renderNewsHoverPopoverLimitedDrug(data: INewsDetail, limit = 3, showIcon = false) {
  return renderPopoverLimitedDrugAsync(data.drug_id_view || [], data.drug_id, limit, showIcon)
}

export function renderNewsHoverPopoverLimitedDisease(data: INewsDetail, limit = 3, showIcon = false) {
  return renderPopoverLimitedDiseaseAsync(data.disease_id_view || [], data.disease_id, limit, showIcon)
}

export function renderNewsHoverPopoverLimitedTarget(data: INewsDetail, limit = 3, showIcon = false) {
  return renderPopoverLimitedTargetsAsync(data.target_id_view || [], data.target_id, limit, showIcon)
}

export function renderNewsHoverPopoverLimitedOrg(data: INewsDetail, limit = 3) {
  return renderPopoverLimitedOrgAsync(data.org_master_entity_id_view || [], data.org_master_entity_id, limit)
}
