import '@patsnap-ui/icon/assets/solid/ArrowRight.svg'
import { computed, defineComponent, toRefs } from '@vue/composition-api'
import { flatten } from 'lodash'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BPtCollapseCountry } from './BPtCollapseCountry'
import $style from './BPtCollapseCountryMulti.module.scss'
import { COUNTRY_PLACEHOLDER } from './config'

export const BPtCollapseCountryMulti = defineComponent({
  name: 'BPtCollapseCountryMulti',
  props: {
    value: {
      type: String,
      value: '',
    },
  },
  setup(props) {
    const { value } = toRefs(props)
    const countries = computed(() => (value?.value || '').split(','))

    const selectableAry = computed(() => {
      let flag = false

      return countries.value.map((code) => {
        if (code === COUNTRY_PLACEHOLDER) {
          if (!flag) {
            flag = true
            return true
          } else {
            return false
          }
        }

        return true
      })
    })

    const placeholderAry = computed(() => {
      return countries.value.map((i, index) => index !== 0)
    })

    return {
      countries,
      selectableAry,
      placeholderAry,
    }
  },
  methods: {
    handleChange(code: string, index: number) {
      const countries = [...this.countries]
      if (code === COUNTRY_PLACEHOLDER) {
        let offset = index
        const len = countries.length
        while (offset < len) {
          countries.splice(offset, 1, COUNTRY_PLACEHOLDER)
          offset++
        }
      } else {
        countries.splice(index, 1, code)
      }
      const changedValue = countries.join(',')
      changedValue !== this.value && this.$emit('change', changedValue)
    },
  },
  render() {
    const separatorVNode = <GIcon useSvgDefaultColor={true} class={$style.separator} svgName="SolidArrowRight" size={24}></GIcon>

    const tipVNode = <GTooltip mode="icon" content={this.$tc('patent.collapseRule.tip.country')} theme="light" placement="top-end"></GTooltip>

    const countryVNodes = this.countries.map((code, index) => {
      return [
        <div key={index} class={$style.countryItem}>
          <BPtCollapseCountry
            onChange={(changedCode: string) => this.handleChange(changedCode, index)}
            alpha2Code={code}
            selectable={this.selectableAry[index]}
            addPlaceholderCountry={this.placeholderAry[index]}
          ></BPtCollapseCountry>
        </div>,
        index === this.countries.length - 1 ? null : separatorVNode,
      ]
    })

    const vNodes = flatten(countryVNodes)

    vNodes.push(tipVNode)

    return <div class={$style.container}>{vNodes}</div>
  },
})
