import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { getShownEntityProfile } from '@patsnap/synapse_common_utils'
import '@pharmsnap/shared/assets/icon-svg/target.svg'
import { GIconLink, GLimited, GLimitedContainer } from '@pharmsnap/shared/components'
import { sharedCtx } from '@pharmsnap/shared/context'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { renderTargetIntroductionPlain } from '@pharmsnap/shared/src/render'
import { ITargetCardInfo } from '@pharmsnap/shared/types'
import { getAllSpecialLang, getLangDegraded } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BDrugItem } from '../../BDrugItem/BDrugItem'
import $classes from '../EntityIntelligentCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BTargetIntelligentCard = defineComponent({
  name: 'BTargetIntelligentCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<ITargetCardInfo>,
    },
    layout: {
      type: String as PropType<'basic' | 'intermediate' | 'advanced'>,
      default: 'intermediate',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    descMaxHeight: {
      type: String,
      default: '280px',
    },
    descLimitLines: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { locale, localeUpcase } = useLocale()
    const relationDrugs = computed(() => data?.value?.related_drug_id_view || [])
    const displayName = computed(
      () => getLangDegraded({ name_cn: data?.value?.entity_name_cn, name_en: data?.value?.entity_name_en }, locale.value) || EMPTY_PLACEHOLDER
    )
    const aliasName = computed(() => {
      const names = getAllSpecialLang({ data: data?.value?.alias || [], lang: localeUpcase.value, field: 'name' })
      if (names.length === 0) return ''
      return names.join(' | ')
    })
    const phase = computed(() => {
      const highestPhase = data?.value?.highest_dev_status_view
      const name = localeUpcase.value === 'CN' ? highestPhase?.name_cn || highestPhase?.name_en : highestPhase?.name_en
      return name
    })
    return {
      locale,
      localeUpcase,
      relationDrugs,
      displayName,
      aliasName,
      phase,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={3}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderField(label: string, content: JSX.Element | string) {
      return (
        <div class="flex items-start">
          <div style={this.labelWidth === 'auto' ? '' : `width: ${this.labelWidth}`} class="flex-shrink-0 text-sm text-text-t2 mr-2 leading-6">
            {label}
            {':'}
          </div>
          <div class="flex-1 overflow-hidden">{content}</div>
        </div>
      )
    },
    renderHeader() {
      if (this.layout === 'basic') {
        return (
          <div>
            <div style="line-height: 0;">
              <GIconLink
                class="font-semibold text-text-t1 text-base"
                ellipsisLine={1}
                href={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
                name={this.displayName}
                newTab={true}
                defaultColor="dark"
                size={32}
                svgName="Target"
                encryptClass={this.data?.fontClassName}
              ></GIconLink>
            </div>
          </div>
        )
      }

      if (this.layout === 'intermediate') {
        return (
          <div>
            <div style="line-height: 0;">
              <GIconLink
                class="font-semibold text-text-t1 text-base"
                ellipsisLine={1}
                href={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
                name={this.displayName}
                newTab={true}
                defaultColor="dark"
                size={24}
                svgName="Target"
                encryptClass={this.data?.fontClassName}
              ></GIconLink>
            </div>
          </div>
        )
      }

      const showProfile = this.data && !!getShownEntityProfile(this.data?.profile_v2).length

      return (
        <div class="pb-2 border-b border-gray-30">
          <div style="line-height: 0;">
            <GIconLink
              class="font-semibold text-text-t1 text-base"
              ellipsisLine={1}
              href={sharedCtx.router.generatorTargetPath(this.data?.target_id || '')}
              name={this.displayName}
              newTab={true}
              defaultColor="dark"
              size={32}
              border={true}
              svgName="Target"
              encryptClass={this.data?.fontClassName}
            ></GIconLink>
          </div>
          {showProfile ? (
            this.descLimitLines <= 0 ? (
              <div
                class="mt-3 text-sm overflow-y-auto leading-5 text-text-t2"
                style={{ maxHeight: this.descMaxHeight }}
                domPropsInnerHTML={renderTargetIntroductionPlain(this.data!)}
              ></div>
            ) : (
              <GLimitedContainer class="mt-3" limitLines={this.descLimitLines} lineHeight={20}>
                <div class="text-sm text-text-t2 leading-5" domPropsInnerHTML={renderTargetIntroductionPlain(this.data!)}></div>
              </GLimitedContainer>
            )
          ) : null}
        </div>
      )
    },
    renderBody() {
      if (this.layout === 'basic') return null

      const relatedDrugsContent = this.renderLimited(this.relationDrugs, this.data?.related_drug_id_count || 0, (item) => (
        <BDrugItem showCard={false} data={item}></BDrugItem>
      ))

      return <div class="space-y-1 mt-4">{this.renderField(this.$t('targetCard.relatedDrugs') as string, relatedDrugsContent)}</div>
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
})
