import { IBaseDevStatusDataSource } from '@patsnap/synapse_common_interface'
import '@pharmsnap/shared/src/assets/icon-svg/fileCommon.svg'
import '@pharmsnap/shared/src/assets/icon-svg/fileHtml.svg'
import '@pharmsnap/shared/src/assets/icon-svg/fileImg.svg'
import '@pharmsnap/shared/src/assets/icon-svg/filePdf.svg'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { downloadFileByUrl, getDictItemName, openNewTab } from '@pharmsnap/shared/src/utils'
import { createIndependentVueInstance } from '@pharmsnap/shared/src/utils/vm'
import { PropType, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { ImagePanel } from 'patsnap-biz'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GLimited } from '../../ui/GLimited/GLimited'

/**
 * Extracts the file name from a given URL.
 *
 * @param url - The URL from which to extract the file name.
 * @returns The extracted file name, or an empty string if no file name is found.
 *
 * @example
 * // Returns "example.txt"
 * extractFileName("https://example.com/files/example.txt");
 *
 * @example
 * // Returns "image.jpg"
 * extractFileName("https://example.com/images/image.jpg");
 */
function extractFileName(url: string) {
  const regex = /\/([^/]+)$/
  const match = url.match(regex)
  return match ? match[1] : ''
}
async function logoBatchSignatureUrl(url: string) {
  if (!url) {
    return ''
  }
  const rt = await sharedCtx.service.search.logoBatchSignature([url], 'discovery_attachment')
  if (rt.length) {
    return rt[0]
  }
  return ''
}
const attachmentTypeConfigMap: Record<string, { icon: string; action: 'download' | 'imgPreview' | 'openNewTab' }> = {
  'image/gif': {
    icon: 'FileImg',
    action: 'imgPreview',
  },
  'image/jpeg': {
    icon: 'FileImg',
    action: 'imgPreview',
  },
  'image/png': {
    icon: 'FileImg',
    action: 'imgPreview',
  },
  'application/pdf': {
    icon: 'FilePdf',
    action: 'openNewTab',
  },
  'text/html': {
    icon: 'FileHtml',
    action: 'openNewTab',
  },
  'application/x-zip-compressed': {
    icon: 'FileCommon',
    action: 'download',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    // pptx
    icon: 'FileCommon',
    action: 'download',
  },
}
export const BDevStatusDataSource = defineComponent({
  name: 'BDevStatusDataSource',
  props: {
    source: {
      required: true,
      type: Array as PropType<IBaseDevStatusDataSource[]>,
    },
    limitCount: {
      type: Number,
      default: 1,
    },
    limitMode: {
      type: String as PropType<'expand' | 'popover' | 'link'>,
      default: 'popover',
    },
    limitInline: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { localeUpcase, isCN } = useLocale()
    const ins = getCurrentInstance()
    async function onClickAttachment(data: IBaseDevStatusDataSource) {
      if (!data.attachment_path) {
        return
      }
      tackingClick(data)
      const signatureUrl = await logoBatchSignatureUrl(data.attachment_path)
      if (data.attachment_type) {
        const action = attachmentTypeConfigMap[data.attachment_type]?.action
        if (action === 'imgPreview') {
          const imagePanelIns = createIndependentVueInstance({
            component: ImagePanel,
            componentVNodeData: {
              props: {
                imageData: {
                  list: [
                    {
                      src: signatureUrl,
                      item: {
                        id: 1,
                        path: signatureUrl,
                      },
                    },
                  ],
                  total: 1,
                  index: 0,
                  type: 'image',
                },
                imagePanelShow: true,
              },
              style: {
                'z-index': PopupManager.nextZIndex(),
              },
              on: {
                'image-panel-close': () => {
                  imagePanelIns.$destroy()
                  imagePanelIns.$el.remove()
                },
              },
            },
            router: ins?.proxy.$router,
            i18n: ins?.proxy.$i18n,
          })
          return
        }
        if (action === 'openNewTab') {
          openNewTab(signatureUrl)
          return
        }
      }
      const fileName = extractFileName(data.attachment_path)
      await downloadFileByUrl(signatureUrl, fileName || `${Date.now()}`)
    }
    function tackingClick(data: IBaseDevStatusDataSource) {
      sharedCtx.service.tracking.trackGeneral({
        event_code: 'VIEW_DATA_SOURCE',
        trigger_point: 'DEV_STATUS',
        common1: data.source_url,
      })
    }
    return { localeUpcase, isCN, attachmentTypeConfigMap: attachmentTypeConfigMap, onClickAttachment, tackingClick }
  },
  methods: {
    renderSource(data: IBaseDevStatusDataSource, index: number, showIndex = false) {
      const emptySourceName = this.isCN ? '链接' : 'Link'
      const attachmentTypeIconName = (data.attachment_type && this.attachmentTypeConfigMap[data.attachment_type]?.icon) || 'FileCommon'
      const attachmentIcon = <GIcon size={24} class="bg-gray-40 rounded" svgName={attachmentTypeIconName}></GIcon>
      const attachmentIconWithLink = data.attachment_path ? (
        <div class="cursor-pointer ml-2" onClick={() => this.onClickAttachment(data)}>
          {attachmentIcon}
        </div>
      ) : (
        ''
      )
      let sourceName = data.normalized_source_id_view ? getDictItemName(data.normalized_source_id_view, this.localeUpcase) : data.source
      if (data.code) {
        sourceName = `${sourceName}(${data.code})`
      }
      const sourceNameWithLink = data.source_url ? (
        <a
          class="text-blue-default hover:underline truncate"
          href={data.source_url}
          onClick={() => {
            this.tackingClick(data)
          }}
          title={sourceName}
          target="_blank"
        >
          {sourceName || emptySourceName}
        </a>
      ) : sourceName ? (
        <span class="truncate" title={sourceName}>
          {sourceName}
        </span>
      ) : (
        ''
      )
      return (
        <div class="flex text-sm leading-6">
          {showIndex && <span class="mr-1 flex-shrink-0">{index + 1}.</span>}
          {sourceNameWithLink}
          {attachmentIconWithLink}
        </div>
      )
    },
  },
  render() {
    return (
      <GLimited
        mode={this.limitMode}
        totalCount={this.source.length}
        items={this.source}
        limit={this.limitCount}
        appendToBody={true}
        truncate={true}
        inline={this.limitInline}
        popoverInline={false}
        scopedSlots={{
          default: ({ item, index, isRenderInPopover }: { item: IBaseDevStatusDataSource; index: number; isRenderInPopover: boolean }) => {
            const showIndex = this.source.length > 1 && (isRenderInPopover || (!isRenderInPopover && this.limitCount > 1))
            return this.renderSource(item, index, showIndex)
          },
        }}
        gap={{ x: 4, y: 4, trigger: 4 }}
      ></GLimited>
    )
  },
})
