import { defineComponent, unref } from '@vue/composition-api'
import $classes from './BFilterAdvanceNext.module.scss'
import { BFilterAdvanceContentListPanel } from './components/BFilterAdvanceContentListPanel'
import { BFilterAdvanceContentTreePanel } from './components/BFilterAdvanceContentTreePanel'
import { BFilterAdvanceSelectedPanel } from './components/BFilterAdvanceSelectedPanel'
import { BFilterAdvanceSelectionPanel } from './components/BFilterAdvanceSelectionPanel'
import { BFilterAdvanceUiTypeRadio } from './components/BFilterAdvanceUiTypeRadio'
import { useInjectFilterService } from './compositions/useProvideFilterService'
import cn from './locales/cn.json'
import en from './locales/en.json'
import jp from './locales/jp.json'
import tw from './locales/tw.json'
import { IFilterItemStateTreeNodeNext } from './type'

export const BFilterAdvanceNext = defineComponent({
  name: 'BFilterAdvanceNext',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    renderContentPanel(cls = '') {
      const selectedState = unref(this.filterService.selectedState)

      const uiType = selectedState._advance.activeUiType

      if (uiType === 'advance-full-list-order-count' || uiType === 'advance-full-list-order-name') {
        return <BFilterAdvanceContentListPanel key={selectedState.identity} class={cls}></BFilterAdvanceContentListPanel>
      }

      if (uiType === 'advance-full-tree') {
        return <BFilterAdvanceContentTreePanel key={selectedState.identity} class={cls}></BFilterAdvanceContentTreePanel>
      }

      return null
    },
    renderUiTypeRadio() {
      const advanceUiState = unref(this.filterService.selectedState) as IFilterItemStateTreeNodeNext | undefined

      if (!advanceUiState?.identity) return null

      return [
        <span class="ml-auto mr-2 text-sm text-text-t2">{this.$t('bFilterNext.orderBy')}:</span>,
        <BFilterAdvanceUiTypeRadio></BFilterAdvanceUiTypeRadio>,
      ]
    },
  },
  render() {
    const advanceUiState = unref(this.filterService.selectedState) as IFilterItemStateTreeNodeNext | undefined

    const theme = unref(this.filterService.theme)

    const shouldHideSelectionPanel = this.filterService.stateTree.length === 1 && !this.filterService.stateTree[0]?.children?.length

    return (
      <div class={['flex flex-col', this.filterService.className?.advanceFilterCls]}>
        <div class={['flex-1 overflow-hidden', 'flex flex-row border-b border-gray-40']}>
          <div class={['flex-shrink-0 h-full', 'w-[220px] flex flex-col px-2 border-r border-gray-40', shouldHideSelectionPanel ? 'hidden' : '']}>
            <div class="flex-shrink-0 h-10 flex items-center leading-6">
              <span class="font-semibold text-text-t1 text-sm pl-2">{this.$t('bFilterNext.advanceRefine')}</span>
            </div>
            <div class="flex-1 overflow-x-hidden overflow-y-auto">
              <BFilterAdvanceSelectionPanel></BFilterAdvanceSelectionPanel>
            </div>
          </div>
          <div class={['flex-1 overflow-hidden h-full', 'flex flex-col px-2 pb-2']}>
            {/* 选中项的名称和预留的关闭按钮位置 */}
            <div class="flex-shrink-0 h-10 flex items-center">
              <span class={['font-semibold', theme === 'workspace' ? 'text-text-t1 text-sm' : 'text-text-t2 text-base']}>
                {advanceUiState?.label}
              </span>
              {this.$slots.topRight ? <span class="ml-auto">{this.$slots.topRight}</span> : null}
            </div>
            <div class="flex-1 overflow-hidden">{this.renderContentPanel('h-full')}</div>
          </div>
        </div>
        <div class="flex-shrink-0 flex items-center px-2 mt-2">
          <span class="text-text-t2 text-sm leading-6">{this.$t('bFilterNext.refineBy')}</span>
          <button
            onClick={() => this.selectService.clearAllFilterValueFromTemporary(this.selectService.checkDisableBeforeRemoveFromTemporaryList)}
            data-size="small"
            data-type="transparent"
            class={['pt-ui-btn ml-auto', $classes.clearAll]}
          >
            {this.$t('bFilterNext.clearAll')}
          </button>
        </div>
        <div class="flex-shrink-0 px-2 mt-2">
          <BFilterAdvanceSelectedPanel style={{ height: '84px' }}></BFilterAdvanceSelectedPanel>
        </div>
      </div>
    )
  },
})
