import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { ILang } from '@patsnap/synapse_common_interface'
import { getSpecialLang } from '@patsnap/synapse_common_utils'
import '@pharmsnap/shared/assets/icon-svg/drug.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderDrugGlobalFirstApprovalDate, renderPhase } from '@pharmsnap/shared/render'
import { IDrugCardInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GCountryFlag } from '../../../ui/GCountryFlag/GCountryFlag'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GIconLink } from '../../../ui/GIconLink/GIconLink'
import { GLimited } from '../../../ui/GLimited/GLimited'
import { GLink } from '../../../ui/GLink/GLink'
import { BDiseaseItem } from '../../BDiseaseItem/BDiseaseItem'
import { BMechanismItem } from '../../BMechanismItem/BMechanismItem'
import { BOrganizationItem } from '../../BOrganizationItem/BOrganizationItem'
import { BTargetItem } from '../../BTargetItem/BTargetItem'
import $classes from '../EntityIntelligentCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDrugIntelligentCard = defineComponent({
  name: 'BDrugIntelligentCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDrugCardInfo>,
    },
    layout: {
      type: String as PropType<'basic' | 'intermediate' | 'advanced'>,
      default: 'intermediate',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const target = computed(() => data?.value?.target_id_view || [])
    const mechanism = computed(() => data?.value?.mechanism_action_id_view || [])
    const devOrg = computed(() => data?.value?.active_org_master_entity_id_view || [])
    const originalDevOrg = computed(() => data?.value?.originator_org_master_entity_id_view || [])
    const phase = computed(() =>
      locale.value === 'CN'
        ? data?.value?.global_highest_dev_status_view?.name_cn || data?.value?.global_highest_dev_status_view?.name_en
        : data?.value?.global_highest_dev_status_view?.name_en
    )
    const researchDisease = computed(() => data?.value?.research_disease_view || [])
    const unResearchDisease = computed(() => data?.value?.non_research_disease_view || [])
    const displayName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: locale.value }) || EMPTY_PLACEHOLDER)
    const drugEnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'EN', isDegraded: false }))
    const drugCnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'CN', isDegraded: false }))

    return {
      locale,
      target,
      mechanism,
      devOrg,
      originalDevOrg,
      phase,
      researchDisease,
      unResearchDisease,
      displayName,
      drugEnName,
      drugCnName,
      isCN,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={3}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderHeader() {
      if (this.layout === 'basic') {
        return (
          <div class="flex items-center">
            <GIcon class="mr-2 flex-shrink-0" svgName="Drug" size={32} useSvgDefaultColor shape="square" border></GIcon>
            <div class="flex-1 overflow-hidden">
              <GLink
                style={{ lineHeight: `24px` }}
                class="block font-semibold text-text-t1 text-base"
                newTab={true}
                name={this.drugEnName}
                href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
                truncate={true}
              ></GLink>
              {this.isCN && !!this.drugCnName ? (
                <GLink
                  style={{ lineHeight: `16px` }}
                  class="block text-xs text-text-t1"
                  truncate={true}
                  href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
                  name={this.drugCnName}
                ></GLink>
              ) : null}
            </div>
          </div>
        )
      }

      if (this.layout === 'intermediate') {
        return (
          <div>
            <div style="line-height: 0;">
              <GIconLink
                class="font-semibold text-text-t1 text-base"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
                name={this.drugEnName}
                newTab={true}
                defaultColor="dark"
                size={24}
                svgName="Drug"
              ></GIconLink>
            </div>
            {this.isCN && !!this.drugCnName ? (
              <div style="line-height: 0;" class="pl-8">
                <GIconLink
                  class="text-sm text-text-t1"
                  ellipsisLine={1}
                  href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
                  name={this.drugCnName}
                  newTab={true}
                  defaultColor="dark"
                  size={20}
                ></GIconLink>
              </div>
            ) : null}
          </div>
        )
      }

      return (
        <div class="pb-2 border-b border-gray-30">
          <div style="line-height: 0;">
            <GIconLink
              class="font-semibold text-text-t1 text-base"
              ellipsisLine={1}
              href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
              name={this.drugEnName}
              newTab={true}
              defaultColor="dark"
              border={true}
              size={32}
              svgName="Drug"
            ></GIconLink>
          </div>
          {this.isCN && !!this.drugCnName ? (
            // pl 会收到是否显示边框影响
            <div style="line-height: 0;" class="pl-[42px]">
              <GIconLink
                class="text-sm text-text-t1"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
                name={this.drugCnName}
                newTab={true}
                defaultColor="dark"
                size={20}
              ></GIconLink>
            </div>
          ) : null}
        </div>
      )
    },
    renderField(label: string, content: JSX.Element | string, contentCls?: any) {
      return (
        <div class="flex items-start">
          <div style={this.labelWidth === 'auto' ? '' : `width:${this.labelWidth}`} class="flex-shrink-0 text-sm text-text-t2 mr-2 leading-6">
            {label}
            {':'}
          </div>
          <div class={['flex-1 overflow-hidden', contentCls]}>{content}</div>
        </div>
      )
    },
    renderBody() {
      if (this.layout === 'basic') return null

      const targetContent = this.renderLimited(this.target, this.data?.target_id_count || 0, (item) => (
        <BTargetItem popover={false} data={item}></BTargetItem>
      ))

      const mechanismContent = this.renderLimited(this.mechanism, this.data?.mechanism_action_id_count || 0, (item) => (
        <span class="inline-flex items-center h-6 max-w-full">
          <BMechanismItem data={item} truncate></BMechanismItem>
        </span>
      ))

      const originalOrgContent = this.renderLimited(this.originalDevOrg, this.data?.originator_org_master_entity_id_count || 0, (item) => (
        <BOrganizationItem popover={false} data={item}></BOrganizationItem>
      ))

      const devOrgContent = this.renderLimited(this.devOrg, this.data?.active_org_master_entity_id_count || 0, (item) => (
        <BOrganizationItem popover={false} data={item}></BOrganizationItem>
      ))

      const researchDiseaseContent = this.renderLimited(this.researchDisease, this.data?.research_disease_count || 0, (item) => (
        <BDiseaseItem popover={false} data={item}></BDiseaseItem>
      ))

      const noResearchDiseaseContent = this.renderLimited(this.unResearchDisease, this.data?.non_research_disease_count || 0, (item) => (
        <BDiseaseItem popover={false} data={item}></BDiseaseItem>
      ))

      const highestPhaseContent = renderPhase(
        {
          currentPhase: this.data?.global_highest_dev_status_view,
          beforePhase: this.data?.global_highest_dev_status_termination_view,
          fontClassName:
            this.data?.global_highest_dev_status_view?.fontClassName ?? this.data?.global_highest_dev_status_termination_view?.fontClassName,
        },
        this.locale
      )

      const firstApprovalDateContent = this.data ? renderDrugGlobalFirstApprovalDate(this.data, this.$i18n) : EMPTY_PLACEHOLDER

      const firstApprovalCountryContent = this.renderLimited(
        this.data?.first_approved_country_view || [],
        this.data?.first_approved_country?.length || 0,
        (item) => {
          const countryName = item.alpha_2_code || item.display_name
          return <GCountryFlag class="mr-1" name={countryName} showName={true}></GCountryFlag>
        }
      )

      const isAdvanceLayout = this.layout === 'advanced'

      const content = [
        this.renderField(this.$t('drugCard.target') as string, targetContent),
        isAdvanceLayout ? this.renderField(this.$t('drugCard.mechanism') as string, mechanismContent) : null,
        this.renderField(this.$t('drugCard.originalDevOrg') as string, originalOrgContent),
        this.renderField(this.$t('drugCard.devOrg') as string, devOrgContent),
        this.renderField(this.$t('drugCard.researchDisease') as string, researchDiseaseContent),
        isAdvanceLayout ? this.renderField(this.$t('drugCard.noResearchDisease') as string, noResearchDiseaseContent) : null,
        this.renderField(this.$t('drugCard.highestPhase') as string, highestPhaseContent),
        this.renderField(this.$t('drugCard.firstApprovalDate') as string, firstApprovalDateContent, 'text-sm leading-6'),
        this.renderField(this.$t('drugCard.firstApprovalCountry') as string, firstApprovalCountryContent),
      ]

      return <div class={['space-y-1', 'mt-4']}>{content}</div>
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
})
