import { provide, ref } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { IAnalysisDisplayIconType } from '../type'

export interface IEchartsRefOptions {
  chartIns: EChartsType
  displayType: IAnalysisDisplayIconType
}

// 收集下层图表的ref
export function useAnalysisRegisterChartRefs() {
  const echartsRefs = ref<IEchartsRefOptions[]>([])

  provide('registerEChartsRef', registerEChartsRef)
  provide('unregisterEChartsRef', unregisterEChartsRef)

  function registerEChartsRef({ chartIns, displayType }: IEchartsRefOptions) {
    echartsRefs.value.push({ chartIns, displayType })
  }

  function unregisterEChartsRef(chartIns: EChartsType) {
    const index = echartsRefs.value.findIndex((item) => item.chartIns === chartIns)

    if (index > -1) {
      echartsRefs.value.splice(index, 1)
    }
  }

  return {
    echartsRefs,
  }
}
