// import '@pharmsnap/pharmsnap-web/assets/icon-svg/closeCircleHoverSmall.svg'
import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import { GIcon } from 'pharmsnapMF_shared/components'
import './CopilotEntry.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const CopilotEntry = defineComponent({
  name: 'CopilotEntry',
  props: {},
  setup() {
    const { isCN } = useLocale()
    // const {
    //   state: { showCopilotEntry },
    //   actions: { setShowCopilot, scrollToBottom },
    // } = useChatStore()

    const {
      state: { dialogVisible },

      actions: { showAssistantDialog, scrollToBottom },
    } = useLs360ChatStore()

    const entryVisible = ref(true)

    const entryRef = ref()
    let isDragging = false
    let initialClientY = 0
    let currentY = 0
    let initialOffsetTop = 0
    let maxHeight = 0
    // TODO: 这边逻辑耦合了，click 事件和 move 事件耦合了，这两者可以分开来处理的
    const handleMousedown = (e: MouseEvent) => {
      isDragging = true
      initialOffsetTop = entryRef.value.offsetTop
      initialClientY = e.clientY
      maxHeight = window.innerHeight - entryRef.value.offsetHeight
    }
    const handleMousemove = (e: MouseEvent) => {
      if (!isDragging) return
      currentY = e.clientY
      const diff = currentY - initialClientY
      const top = initialOffsetTop + diff
      const newTopLimited = Math.min(Math.max(top, 0), maxHeight)
      entryRef.value.style.top = `${newTopLimited}px`
    }
    const handleDocumentMouseup = (e: MouseEvent) => {
      isDragging = false
      if (e.target === entryRef.value || entryRef.value.contains(e.target as Node)) {
        if (Math.abs(initialOffsetTop - entryRef.value.offsetTop) > 1) {
          return
        }
        showAssistantDialog(true)
        scrollToBottom()
      }
    }
    onMounted(() => {
      document.addEventListener('mousemove', handleMousemove)
      document.addEventListener('mouseup', handleDocumentMouseup)
    })
    onUnmounted(() => {
      document.removeEventListener('mousemove', handleMousemove)
      document.removeEventListener('mouseup', handleDocumentMouseup)
    })
    return { isCN, entryRef, dialogVisible, entryVisible, scrollToBottom, handleMousedown, handleMousemove }
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  render() {
    return (
      <div
        v-show={!this.dialogVisible && this.entryVisible}
        ref="entryRef"
        class="copilot-entry"
        onMousedown={this.handleMousedown}
        style={`--copilot-width:${this.isCN ? 192 : 156}px`}
      >
        <div ref="shapeRef" class="copilot-entry__shape">
          <span>{this.$t('assistant')}</span>
        </div>
        <span class="copilot-entry__close text-white-default">
          <GIcon
            svgName="SolidCloseBig"
            size={16}
            nativeOnMousedown={(e: Event) => {
              e.stopPropagation()
              this.entryVisible = false
            }}
          ></GIcon>
        </span>
      </div>
    )
  },
})
