import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDrugWithItemInfo } from '@pharmsnap/shared/types'
import { getDictItemName, getOrganizationName, getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GLink } from '../../ui/GLink/GLink'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BDrugPopperCard } from '../card/BDrugCard/BDrugPopperCard'
import { BEntityTag } from '../tag/BEntityTag/BEntityTag'

export const BDrugItemWithToolTip = defineComponent({
  name: 'BDrugItemWithToolTip',
  props: {
    data: {
      type: Object as PropType<IBaseDrugWithItemInfo>,
      required: true,
    },
    defaultColor: {
      type: String as PropType<'blue' | 'dark'>,
      default: 'dark',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showCard: {
      type: Boolean,
      default: false,
    },
    // 是否显示双语，英文 然后换行中文
    showBilingual: {
      type: Boolean,
      default: false,
    },
    /**
     * 双语显示的位置
     */
    bilingualPosition: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'vertical',
    },
    zIndex: Number,
  },
  setup(props) {
    const { data } = toRefs(props)
    const { drug_id_view } = data.value
    const { isCN, locale, localeUpcase } = useLocale()
    const href = computed(() => sharedCtx.router.generatorDrugPath(drug_id_view?.drug_id || ''))
    const displayName = computed(() => getSpecialLang({ data: drug_id_view?.drug_name || [], field: 'name', lang: localeUpcase.value }))
    const name_en = getSpecialLang({ data: drug_id_view?.drug_name || [], field: 'name', lang: 'EN', isDegraded: false })
    const name_cn = getSpecialLang({ data: drug_id_view?.drug_name || [], field: 'name', lang: 'CN', isDegraded: false })
    return {
      href,
      displayName,
      name_en,
      name_cn,
      isCN,
      locale,
      localeUpcase,
    }
  },
  methods: {
    renderContent(item: IBaseDrugWithItemInfo) {
      return item.detail ? (
        <div>
          {item.detail.map((d) => {
            const orgName = getOrganizationName(d.org_id_view || {}, this.locale) || ''
            const diseaseName = d.disease_id_view
              ? getSpecialLang({ data: d.disease_id_view.disease_name, field: 'name', lang: this.localeUpcase })
              : ''
            const phaseName = d.dev_status_view ? getDictItemName(d.dev_status_view, this.localeUpcase) : ''
            const displayLabel = [orgName, diseaseName, phaseName].filter((o) => o).join(' | ')
            return <div>{displayLabel}</div>
          })}
        </div>
      ) : null
    },
    renderToolTip(item: IBaseDrugWithItemInfo) {
      if (!item.detail) return
      const validLength = item.detail.filter((d) => d.disease_id_view || d.org_id_view).length
      if (!validLength) return
      return (
        <GTooltip class="flex-shrink-0" theme="light" mode="icon" placement="bottom">
          <template slot="content">{this.renderContent(item)}</template>
        </GTooltip>
      )
    },
    renderTitle(name: string) {
      const _renderDrugContent = () => {
        return (
          <BEntityTag>
            {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="Drug" /> : null}
            <GLink href={this.href} name={name} defaultColor={this.defaultColor}></GLink>
          </BEntityTag>
        )
      }

      const id = this.data?.drug_id
      if (id && this.showCard) {
        return (
          <div class="flex items-center bg-gray-30 rounded max-w-full">
            <BDrugPopperCard id={id} zIndex={this.zIndex}>
              {_renderDrugContent()}
            </BDrugPopperCard>
            {this.renderToolTip(this.data)}
          </div>
        )
      }
      return (
        <div class="flex items-center bg-gray-30 rounded max-w-full">
          {_renderDrugContent()}
          {this.renderToolTip(this.data)}
        </div>
      )
    },
  },
  render() {
    if (this.showBilingual && this.name_cn && this.name_en) {
      const isHorizontal = this.bilingualPosition === 'horizontal'
      return (
        <div class={isHorizontal ? 'flex items-center' : ''}>
          {this.renderTitle(this.name_en)}
          {this.isCN && <div class="text-text-default text-sm truncate pl-2">{this.name_cn}</div>}
        </div>
      )
    }

    return (this as any).renderTitle(this.displayName || EMPTY_PLACEHOLDER)
  },
})
