import { useMergedState } from '@pharmsnap/shared/composition'
import { defineComponent, toRef } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import { GTooltip } from '../GTooltip/GTooltip'
import styles from './GDrawer.module.scss'
import { gDrawerSideProps, GDrawerTabType } from './GDrawerProps'
import { GDrawerToggle } from './GDrawerToggle'

export const GDrawerSideBase = defineComponent({
  name: 'GDrawerSideBase',
  props: gDrawerSideProps(),
  setup(props, ctx) {
    const handleTabClick = (item: GDrawerTabType) => {
      if (item.disabled) return
      ctx.emit('click', item)
    }
    return {
      handleTabClick,
    }
  },
  methods: {
    renderItem(item: GDrawerTabType) {
      const { label, icon } = item
      return (
        <GTooltip placement={this.direction === 'rtl' ? 'left' : 'right'} content={label}>
          <div
            onClick={() => this.handleTabClick(item)}
            class={[
              'hover:bg-gray-30 mt-1 w-8 h-8 rounded inline-flex items-center justify-center',
              item.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            ]}
          >
            <GIcon svgName={icon} size={24} color={item.disabled ? '#B3BAC5' : '#495973'}></GIcon>
          </div>
        </GTooltip>
      )
    },
  },
  render() {
    return (
      <div class={[styles.gDrawerSideBase, !this.visible && styles.gDrawerSideBaseInvisible]}>
        <div class="w-full flex justify-center flex-wrap">{this.dataSource.map(this.renderItem)}</div>
        <div class="w-full flex justify-center flex-wrap">{this.tailDataSource.map(this.renderItem)}</div>
      </div>
    )
  },
})

export const GDrawerSide = defineComponent({
  name: 'GDrawerSide',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: gDrawerSideProps(),
  setup(props, ctx) {
    const [mergedVisible, setVisible] = useMergedState(false, {
      value: toRef(props, 'visible'),
      onChange(val) {
        ctx.emit('change', val)
      },
    })
    const handleTabClick = (item: GDrawerTabType) => {
      ctx.emit('click', item)
    }
    return {
      handleTabClick,
      mergedVisible,
      setVisible,
    }
  },
  render() {
    return (
      <div class={[styles.gDrawerSide]}>
        <GDrawerToggle
          class={[styles.gDrawerSideToggle, this.direction === 'rtl' ? 'right-full' : 'left-full']}
          value={this.mergedVisible}
          onChange={this.setVisible}
          direction={this.direction}
        />
        <GDrawerSideBase
          visible={!this.mergedVisible}
          onClick={this.handleTabClick}
          direction={this.direction}
          dataSource={this.dataSource}
          tailDataSource={this.tailDataSource}
        />
      </div>
    )
  },
})
