import { E_AGGREGATION_TYPE, IAggregationParams, IAggregationValue } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { computed, Ref, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { IPatentStatus } from '../../../types'
import { checkAggDataIsEmpty, getGrantedFilterItem } from '../../../utils'

type IPatentTherapeuticAreaViewType = 'chart' | 'table'

export function useTherapeuticArea(options: { service: IQueryService }) {
  const { locale } = useLocale()
  const isFullScreenRef = ref(false)
  const loadingRef = ref(true)
  const loadingFullScreenRef = ref(true)
  const selectedViewTypeRef = ref('chart') as Ref<IPatentTherapeuticAreaViewType>
  const selectedPatentTypeRef = ref('application') as Ref<IPatentStatus>
  const aggDataRef = ref<IAggregationValue[]>([])
  const computedAggData = computed<IAggregationValue[]>(() =>
    aggDataRef.value.map((item) => ({ ...item, __name: locale.value === 'cn' ? item.display_name_cn || item.display_name_en : item.display_name_en }))
  )
  const computedIsEmpty = computed(() => computedAggData.value.length === 0)
  const computedAggParams = computed<IAggregationParams>(() => {
    const { state } = options.service
    const { query, collapse } = state
    const { must = [], filter = [] } = query
    const queryMust = cloneDeep(must)
    const queryFilter = cloneDeep(filter)
    const queryCollapse = cloneDeep(collapse)
    if (selectedPatentTypeRef.value === 'granted') {
      queryFilter.push(getGrantedFilterItem())
    }
    const params: IAggregationParams = {
      data_type: 'patent',
      query: {
        type: 'group',
        must: queryMust,
        filter: queryFilter,
      },
      aggregation: [
        {
          aggregation_field: 'PHS_THERAPEUTIC_AREA',
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          limit: 10,
          sort_type: 'count',
          order: 'desc',
        },
      ],
      collapse: queryCollapse,
    }

    return params
  })

  function changeViewType(type: IPatentTherapeuticAreaViewType) {
    selectedViewTypeRef.value = type
  }

  function changePatentType(type: IPatentStatus) {
    selectedPatentTypeRef.value = type
  }

  async function fetchChartData() {
    if (isFullScreenRef.value) {
      loadingFullScreenRef.value = true
    } else {
      loadingRef.value = true
    }
    const rt = await sharedCtx.service.patent.getAggregation(computedAggParams.value)
    if (rt.success && !checkAggDataIsEmpty(rt.data)) {
      const aggField = rt.data.aggregation_result[0].aggregation_field
      aggDataRef.value = rt.data.aggregation_result[0].items.map((i) => ({ ...i, aggregation_field: aggField }))
    } else {
      aggDataRef.value = []
    }
    loadingRef.value = false
    loadingFullScreenRef.value = false
  }

  function handleToggleFullScreen(fullscreen: boolean) {
    isFullScreenRef.value = fullscreen
  }

  function handleChangeViewType(type: IPatentTherapeuticAreaViewType) {
    changeViewType(type)
  }

  function handleChangePatentType(type: IPatentStatus) {
    changePatentType(type)
  }

  watch(computedAggParams, fetchChartData)

  return {
    computedAggData,
    computedIsEmpty,
    isFullScreenRef,
    loadingRef,
    loadingFullScreenRef,
    selectedViewTypeRef,
    selectedPatentTypeRef,
    changeViewType,
    changePatentType,
    fetchChartData,
    handleToggleFullScreen,
    handleChangeViewType,
    handleChangePatentType,
  }
}
