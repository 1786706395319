import { VUE_APP_API_URL } from '@pharmsnap/pharmsnap-web/config'
import type { AxiosInstance, AxiosResponse } from 'axios'
import { noop } from 'lodash'

export interface IGoogleReCaptchaResponseData {
  recaptcha_token: string
}
export interface IGeetestResponseData {
  lot_number: string
  captcha_output: string
  pass_token: string
  gen_time: string
}
interface IValidateData {
  result: 'success' | 'fail'
  reason: string
  /**
   * 失败时，看锁定时间有多长，-1代表被,block=0代表没有锁定，可以直接重试, >0代表锁定状态,数量表述多少秒后重试
   */
  remaining: number
}
export type IReCaptchaResponse = IGoogleReCaptchaResponseData | IGeetestResponseData

export class CaptchaVerification {
  readonly BACK_OFFICE_GEETEST_CAPTCHA_ID = '93a93689c223c9f52de553e31fb60241'
  readonly PHARMSNAP_GEETEST_CAPTCHA_ID = '062d4e82a544a2ef8993546ee6e3082d'
  readonly BACK_OFFICE_GOOGLE_CAPTCHA_ID = '6LdeMwIeAAAAACnIGkZ0Z8LWbVr9R0I0GfLZh5jz'
  readonly GEETEST_FAIL_TIMES = 3
  isBlocked = false
  failTime = 1
  constructor(
    public options: {
      /** 如果错误过多需要显示等待时间 */
      showRemainTime?: (remaining: number) => void
      showBlockMessage: () => void
      /** 是否使用bo的极验 */
      useBoGeetest?: boolean
      /** 发起解锁请求的axios */
      refreshAxios: AxiosInstance
      /** 区域,中国用极验海外用google */
      region: 'cn' | 'us'
      getLang: () => 'cn' | 'en'
      onFail?: (e: any) => void
    }
  ) {}

  private get isCn() {
    return this.options.region === 'cn'
  }

  public showCaptchaVerification(params: { /** 验证成功回调 */ onSuccess: () => void; onClose: (e?: any) => void; onFail: (e?: any) => void }) {
    if (this.isBlocked) {
      return
    }
    this.isBlocked = true
    const tryUnlockUser = async (data: IReCaptchaResponse) => {
      const rt = await this.unLockUser(data)
      if (rt.data?.result === 'success') {
        params.onSuccess()
      } else {
        const remaining = rt.data.remaining
        // 等待时间>0 代表还在锁定中,请xx秒后重试
        if (remaining > 0) {
          this.options.showRemainTime?.(rt.data.remaining)
          params.onFail(rt)
        }
        // -1代表被block
        if (remaining < 0) {
          this.options.showBlockMessage()
          params.onFail(rt)
        }
      }
    }
    this.unionCaptchaVerification({
      onSuccess: async (data) => {
        this.isBlocked = false
        this.failTime = 1
        await tryUnlockUser(data)
      },
      onFail: async (e) => {
        this.isBlocked = false
        if (e.type === 'close') {
          this.failTime = 1
          params.onClose(e)
          return
        }
        if (this.failTime >= this.GEETEST_FAIL_TIMES) {
          this.failTime = 1
          await tryUnlockUser({
            recaptcha_token: '', // 给个空值,让后端知道是前端失败了3次,用于后端阶梯式锁定
          })
        }
        this.failTime++
      },
    })
  }

  private async unLockUser(params: IReCaptchaResponse): Promise<AxiosResponse<IValidateData>> {
    return this.options.refreshAxios({
      url: `${VUE_APP_API_URL}/ls360_webapi/1.0/captcha/validate`,
      method: 'POST',
      data: params,
    })
  }

  private async unionCaptchaVerification(params: {
    /**
     * 失败回调
     */
    onFail?: (params: any) => void
    /**
     * 成功回调
     */
    onSuccess: (params: IReCaptchaResponse) => any
  }) {
    const { ReCaptchaVerification, GEETEST_TYPE, GOOGLE_TYPE } = await import('@patsnap-utils/captcha-verification')
    const captchaVerification = new ReCaptchaVerification({
      type: this.isCn ? GEETEST_TYPE : GOOGLE_TYPE,
      id: this.isCn
        ? this.options.useBoGeetest
          ? this.BACK_OFFICE_GEETEST_CAPTCHA_ID
          : this.PHARMSNAP_GEETEST_CAPTCHA_ID
        : this.BACK_OFFICE_GOOGLE_CAPTCHA_ID,
      lang: this.options.getLang() === 'cn' ? 'cn' : 'en',
      obj: { outside: false },
      onFail: params.onFail || noop,
    })
    try {
      const rt = await captchaVerification.captchaValidate()
      params.onSuccess(rt as IReCaptchaResponse)
    } catch (e) {
      params.onFail?.(e)
    }
  }
}
