interface IRegulationCountryItem {
  regulation: string
  country: string
}
/**
 * 中国特殊审批国家二元组配置
 */
export const regulationCountryBinaryTuplesListCN: IRegulationCountryItem[] = [
  //优先审评（US）
  {
    regulation: '23d3523b7ece48ddacaddac53a66949d',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  //突破性疗法（US）
  {
    regulation: 'c3c948340a7d40f3b7437081f5e8cb4e',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  //快速通道（US）
  {
    regulation: 'ca2f2c7dacec450e8a45e2b7f4b74d05',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  // 加速批准（US）
  {
    regulation: 'df5e83eb171a4eb095fcab2f1470348d',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  // 紧急使用授权（美国）
  {
    regulation: '74efa92412694fcfaab6151e88ce57c2',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  //孤儿药（US）
  {
    regulation: '043a131d85994801b3617e822e7f87b3',
    country: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
  },
  //优先药物（PRIME）（EU）
  {
    regulation: 'b7d7a459842543b7913979bf604084e4',
    country: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
  },
  // 孤儿药（EU）
  {
    regulation: '043a131d85994801b3617e822e7f87b3',
    country: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
  },
  // 优先审评（CN)
  {
    regulation: '23d3523b7ece48ddacaddac53a66949d',
    country: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
  },
  //突破性疗法（CN）
  {
    regulation: 'c3c948340a7d40f3b7437081f5e8cb4e',
    country: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
  },
  //特殊审批（CN）
  {
    regulation: '9eb8777c62af4712932b360e6ef3e454',
    country: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
  },
  // 附条件批准（中国）
  {
    regulation: '6501abeba2b6400ca8c822d8b2bff598',
    country: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
  },
  //孤儿药（JP）
  {
    regulation: '043a131d85994801b3617e822e7f87b3',
    country: '676de5ba-893a-36bb-8683-c93306aaf769',
  },
]
export const regulationCountryBinaryTuplesSortIndex: Record<string, number> = regulationCountryBinaryTuplesListCN.reduce((acc, curr, index) => {
  return {
    ...acc,
    [`${curr.regulation}-${curr.country}`]: index,
  }
}, {})
