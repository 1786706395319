/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElCheckbox } from '@pharmsnap/shared/element-ui'
import { IInfiniteLoadingState } from '@pharmsnap/shared/types'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { VNodeData } from 'vue/types/vnode'
import { GColLayout } from '../GColLayout/GColLayout'
import $classes from './GCheckList.module.scss'

export const GCheckList = defineComponent({
  name: 'GCheckList',
  props: {
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 数据源列表，string[]或者object[]
    list: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    // 双向绑定的数组
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    // 需要被禁用的列表
    disabledValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    // 数据源中的key字段名称
    itemKey: {
      type: String,
    },
    // 布局列数
    col: {
      type: Number as PropType<number>,
      default: 1,
    },
    // 布局x方向的间距
    xGap: {
      type: Number as PropType<number>,
      default: 0,
    },
    // 布局y方向的间距
    yGap: {
      type: Number as PropType<number>,
      default: 8,
    },
    // 是否显示check all 控件
    showCheckAll: {
      type: Boolean,
      default: false,
    },
    // check all 的文案
    checkAllLabel: {
      type: String,
      default: '',
    },
    // 选项列表是否和check all部分对齐
    alignCheckAll: {
      type: Boolean,
      default: false,
    },
    // 选项列表元素hover是否显示背景色
    hoverBg: {
      type: Boolean,
      default: true,
    },
    // 每一行选项高度
    itemHeight: {
      type: Number,
      default: 24,
    },
    // 是否开启虚拟滚动
    isOpenRecycleScroller: {
      type: Boolean,
      default: false,
    },
    isInfiniteModel: {
      type: Boolean,
      default: false,
    },
    // 最少选中几项，默认-1，不做限制
    min: {
      type: Number,
      default: -1,
    },
  },
  emit: ['input'],
  setup(props, context) {
    const checkRecord = ref<Record<string, boolean>>({})
    const checkedAll = ref<boolean>(false)
    const checkedAllIndeterminate = ref<boolean>(false)

    const checkItem = (item: any) => {
      const key = typeof item === 'string' ? item : (item[props.itemKey as string] as unknown as string)
      context.emit('input', [...props.value, key])
    }

    const disableItem = (item: any) => {
      const key = typeof item === 'string' ? item : (item[props.itemKey as string] as unknown as string)
      return props.disabledValue.includes(key)
    }

    const unCheckItem = (item: any) => {
      const key = typeof item === 'string' ? item : (item[props.itemKey as string] as unknown as string)
      context.emit(
        'input',
        props.value.filter((i) => i !== key)
      )
    }

    const handleCheck = (item: any, check: boolean) => (check ? checkItem(item) : unCheckItem(item))

    const handleCheckAll = (check: boolean) => {
      if (check) {
        const keys = typeof props.list[0] === 'string' ? [...props.list] : (props.list.map((i) => i[props.itemKey as string]) as string[])
        context.emit('input', keys)
      } else {
        context.emit('input', [])
      }
    }

    const syncCheckState = () => {
      checkRecord.value = props.value.reduce((pre, next) => ({ ...pre, [next]: true }), {})
      checkedAll.value = props.value.length === props.list.length
      checkedAllIndeterminate.value = props.value.length !== 0 && props.value.length < props.list.length
    }

    watch(
      () => props.value,
      () => {
        syncCheckState()
      },
      { immediate: true }
    )

    return {
      checkedAll,
      checkedAllIndeterminate,
      checkRecord,
      checkItem,
      disableItem,
      handleCheck,
      handleCheckAll,
      syncCheckState,
    }
  },
  methods: {
    renderCheckAll() {
      if (!this.showCheckAll) return null
      return (
        <ElCheckbox
          class={[$classes.gCheckListItem, $classes.gCheckListItemCheckAll]}
          disabled={this.disabled}
          value={this.checkedAll}
          indeterminate={this.checkedAllIndeterminate}
          on={{ input: this.handleCheckAll }}
        >
          {this.checkAllLabel}
        </ElCheckbox>
      )
    },
  },
  render() {
    const vNodeData: VNodeData = {
      props: {
        list: this.list,
        col: this.col,
        xGap: this.xGap,
        yGap: this.yGap,
        itemHeight: this.itemHeight,
        isOpenRecycleScroller: this.isOpenRecycleScroller,
        isInfiniteModel: this.isInfiniteModel,
      },
      on: {
        infiniteLoading: ($state: IInfiniteLoadingState) => {
          this.$emit('infiniteLoading', $state)
        },
      },
      scopedSlots: {
        default: (props: { item: any }) => {
          if (!props.item) return null
          const { item } = props
          const key = typeof item === 'string' ? item : (item[this.itemKey as string] as unknown as string)
          const check = !!this.checkRecord[key]
          return (
            <ElCheckbox
              class={[$classes.gCheckListItem, this.hoverBg ? $classes.gCheckListItemHoverBg : '']}
              disabled={this.disabled || this.disableItem(item) || (this.min !== -1 && check && this.value.length <= this.min)}
              value={check}
              on={{ input: (val: boolean) => this.handleCheck(item, val) }}
            >
              {this.$scopedSlots.default && this.$scopedSlots.default({ item, check })}
            </ElCheckbox>
          )
        },
      },
    }

    if (!this.showCheckAll) {
      return <GColLayout {...vNodeData}></GColLayout>
    }

    return (
      <div class={$classes.gCheckList}>
        <div style={{ marginBottom: `${this.yGap}px` }} class={$classes.gCheckListHeader}>
          {this.renderCheckAll()}
        </div>
        <div style={{ paddingLeft: `${this.alignCheckAll ? 0 : 18}px` }} class={$classes.gCheckListBody}>
          <GColLayout {...vNodeData}></GColLayout>
        </div>
      </div>
    )
  },
})
