import { BDiseaseItem, BDrugItem, BDrugTypeTagItem, BOrganizationItem } from '../components'
import { BDiseasePopperListCard } from '../components/business/card/BDiseaseCard/BDiseasePopperListCard'
import { BDrugPopperListCard } from '../components/business/card/BDrugCard/BDrugPopperListCard'
import { BDrugTypePopperListCard } from '../components/business/card/BDrugTypeCard/BDrugTypePopperListCard'
import { BOrganizationPopperListCard } from '../components/business/card/BOrganizationCard/BOrganizationPopperListCard'
import { IRelatedDisease, IRelatedDrug, IRelatedDrugType, IRelatedOrganization, IRelatedTarget, IRelatedTherapeuticArea } from '../types'
import { IRelatedMechanism } from '../types/mechanism'
import {
  renderAsyncListEntityCard,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedMechanismAsync,
  renderPopoverLimitedTargetsAsync,
} from './baseRender'

export function renderRelatedDrug(data: IRelatedDrug) {
  return renderAsyncListEntityCard({
    id_view: data.related_drug_id_view,
    ids: data.related_drug_id,
    PopperListCardCom: BDrugPopperListCard,
    ItemCom: BDrugItem,
    limitCount: 3,
  })
}

export function renderRelatedDrugType(data: IRelatedDrugType) {
  return renderAsyncListEntityCard({
    id_view: data.related_drug_type_view,
    ids: data.related_drug_type,
    PopperListCardCom: BDrugTypePopperListCard,
    ItemCom: BDrugTypeTagItem,
    limitCount: 3,
  })
}

export function renderSingleOneLineRelatedDrugType(data: IRelatedDrugType) {
  return renderAsyncListEntityCard({
    id_view: data.related_drug_type_view,
    ids: data.related_drug_type,
    PopperListCardCom: BDrugTypePopperListCard,
    ItemCom: BDrugTypeTagItem,
    limitCount: 1,
    isDisplayInOneLine: true,
  })
}

export function renderRelatedTarget(data: IRelatedTarget) {
  return renderPopoverLimitedTargetsAsync(data.related_target_id_view, data.related_target_id, 3)
}
export function renderSingleOneLineRelatedTarget(data: IRelatedTarget) {
  return renderPopoverLimitedTargetsAsync(data.related_target_id_view, data.related_target_id, 1, false, true)
}
export function renderRelatedDisease(data: IRelatedDisease) {
  return renderPopoverLimitedDiseaseAsync(data.related_disease_id_view, data.related_disease_id, 3)
}
export function renderRelatedMechanism(data: IRelatedMechanism) {
  return renderPopoverLimitedMechanismAsync(data.related_mechanism_id_view, data.related_mechanism_id, 3)
}
export function renderSingleOneLineRelatedMechanism(data: IRelatedMechanism) {
  return renderPopoverLimitedMechanismAsync(data.related_mechanism_id_view, data.related_mechanism_id, 1, true)
}
export function renderRelatedOrg(data: IRelatedOrganization) {
  return renderAsyncListEntityCard({
    id_view: data.related_org_id_view,
    ids: data.related_org_id,
    PopperListCardCom: BOrganizationPopperListCard,
    ItemCom: BOrganizationItem,
    limitCount: 3,
  })
}
export function renderRelatedTherapeuticArea(data: IRelatedTherapeuticArea) {
  return renderAsyncListEntityCard({
    id_view: data.therapeutic_area_view,
    ids: data.therapeutic_area,
    PopperListCardCom: BDiseasePopperListCard,
    ItemCom: BDiseaseItem,
    limitCount: 3,
  })
}
