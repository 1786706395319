import SaveToWorkspace from '@patsnap-biz/save-to-workspace-v2'
import { defineComponent, getCurrentInstance, onUnmounted, ref } from '@vue/composition-api'
import { workspaceComponentProps } from '../../type'
import $classes from '../Workspace.module.scss'
import SavePatentService from './SavePatentService'

const { SavePatentToWorkspace } = SaveToWorkspace
export const PatentWorkspace = defineComponent({
  name: 'PatentWorkspace',
  props: workspaceComponentProps(),
  setup(props) {
    const instance = getCurrentInstance()

    const PatentService = ref()

    PatentService.value = new SavePatentService({ vm: instance?.proxy, ...props })

    const close = () => {
      PatentService.value = null
    }

    onUnmounted(close)

    return {
      PatentService,
    }
  },
  render() {
    if (this.PatentService) {
      return <SavePatentToWorkspace service={this.PatentService} append-to-body class={$classes.workspace}></SavePatentToWorkspace>
    }
    return <span></span>
  },
})
