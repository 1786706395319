import { IAggregationItem, IAggregationSingleResult, IQueryItem } from '@patsnap/synapse_common_interface'
import { IFilterConfig, IFilterItemConfig } from '@pharmsnap/shared/components/business/BFilter/types'
import { PropType, defineComponent } from '@vue/composition-api'
import { FilterItemCheckBox } from '../../FilterItemCheckBox'
import { FilterItemLocationTree } from '../FilterItemLocationTree/FilterItemLocationTree'
import { FilterItemSlide } from '../FilterItemSlide/FilterItemSlide'
import { FilterItemTimeSelect } from '../FilterItemTimeSelect/FilterItemTimeSelect'
export const FilterItemContent = defineComponent({
  name: 'FilterItemContent',
  props: {
    filterConfig: {
      required: true,
      type: Object as PropType<IFilterItemConfig<string>>,
    },
    filterConfigs: {
      type: Object as PropType<IFilterConfig<string>>,
    },
    limitFilter: {
      type: Function as PropType<() => boolean>,
    },
    getTreeChildDataFn: {
      type: Function as PropType<(extraFilter: IQueryItem[], aggregationItems: IAggregationItem[]) => Promise<IAggregationSingleResult[]>>,
    },
    getSliderAggregationDataFn: {
      type: Function as PropType<(field: string) => Promise<IAggregationSingleResult[][]>>,
    },
  },
  methods: {
    renderContent() {
      switch (this.filterConfig.type) {
        case 'checkbox':
          return (
            <FilterItemCheckBox
              {...{
                on: {
                  aggregation: (field: string) => this.$emit('aggregation', field),
                },
              }}
              config={this.filterConfig}
              limitFilter={this.limitFilter}
            ></FilterItemCheckBox>
          )
        case 'timeRadio':
        case 'timeSelect':
          return <FilterItemTimeSelect config={this.filterConfig} filterConfigs={this.filterConfigs}></FilterItemTimeSelect>
        case 'slide':
          return (
            <FilterItemSlide config={this.filterConfig} getSliderAggregationDataFn={this.getSliderAggregationDataFn} class="px-4"></FilterItemSlide>
          )
        case 'locationTree':
          return <FilterItemLocationTree config={this.filterConfig} getTreeChildDataFn={this.getTreeChildDataFn}></FilterItemLocationTree>

        default:
          console.error(`renderContent:Filter类型为${this.filterConfig.type} 没有对应的渲染组件,请检查`)
          return null
      }
    },
  },
  render() {
    return <div>{this.renderContent()}</div>
  },
})
