import { calculateAsPixels } from '@pharmsnap/shared/src/utils'

/**
 * copy from https://github.com/microsoft/vscode/tree/main/src/vs/base/browser/fastDomNode.ts
 */
export class FastDomNode<T extends HTMLElement> {
  private _maxWidth = ''
  private _width = ''
  private _height = ''
  private _top = ''
  private _left = ''
  private _bottom = ''
  private _right = ''
  private _paddingTop = ''
  private _paddingLeft = ''
  private _paddingBottom = ''
  private _paddingRight = ''
  private _fontFamily = ''
  private _fontWeight = ''
  private _fontSize = ''
  private _fontStyle = ''
  private _fontFeatureSettings = ''
  private _fontVariationSettings = ''
  private _textDecoration = ''
  private _lineHeight = ''
  private _letterSpacing = ''
  private _className = ''
  private _display = ''
  private _position = ''
  private _visibility = ''
  private _color = ''
  private _backgroundColor = ''
  private _layerHint = false
  private _contain: 'none' | 'strict' | 'content' | 'size' | 'layout' | 'style' | 'paint' = 'none'
  private _boxShadow = ''
  private _zIndex = ''

  constructor(public readonly domNode: T) {}

  public setMaxWidth(_maxWidth: number | string): void {
    const maxWidth = calculateAsPixels(_maxWidth)
    if (this._maxWidth === maxWidth) {
      return
    }
    this._maxWidth = maxWidth
    this.domNode.style.maxWidth = this._maxWidth
  }

  public setWidth(_width: number | string): void {
    const width = calculateAsPixels(_width)
    if (this._width === width) {
      return
    }
    this._width = width
    this.domNode.style.width = this._width
  }

  public setHeight(_height: number | string): void {
    const height = calculateAsPixels(_height)
    if (this._height === height) {
      return
    }
    this._height = height
    this.domNode.style.height = this._height
  }

  public setTop(_top: number | string): void {
    const top = calculateAsPixels(_top)
    if (this._top === top) {
      return
    }
    this._top = top
    this.domNode.style.top = this._top
  }

  public setLeft(_left: number | string): void {
    const left = calculateAsPixels(_left)
    if (this._left === left) {
      return
    }
    this._left = left
    this.domNode.style.left = this._left
  }

  public setBottom(_bottom: number | string): void {
    const bottom = calculateAsPixels(_bottom)
    if (this._bottom === bottom) {
      return
    }
    this._bottom = bottom
    this.domNode.style.bottom = this._bottom
  }

  public setRight(_right: number | string): void {
    const right = calculateAsPixels(_right)
    if (this._right === right) {
      return
    }
    this._right = right
    this.domNode.style.right = this._right
  }

  public setPaddingTop(_paddingTop: number | string): void {
    const paddingTop = calculateAsPixels(_paddingTop)
    if (this._paddingTop === paddingTop) {
      return
    }
    this._paddingTop = paddingTop
    this.domNode.style.paddingTop = this._paddingTop
  }

  public setPaddingLeft(_paddingLeft: number | string): void {
    const paddingLeft = calculateAsPixels(_paddingLeft)
    if (this._paddingLeft === paddingLeft) {
      return
    }
    this._paddingLeft = paddingLeft
    this.domNode.style.paddingLeft = this._paddingLeft
  }

  public setPaddingBottom(_paddingBottom: number | string): void {
    const paddingBottom = calculateAsPixels(_paddingBottom)
    if (this._paddingBottom === paddingBottom) {
      return
    }
    this._paddingBottom = paddingBottom
    this.domNode.style.paddingBottom = this._paddingBottom
  }

  public setPaddingRight(_paddingRight: number | string): void {
    const paddingRight = calculateAsPixels(_paddingRight)
    if (this._paddingRight === paddingRight) {
      return
    }
    this._paddingRight = paddingRight
    this.domNode.style.paddingRight = this._paddingRight
  }

  public setFontFamily(fontFamily: string): void {
    if (this._fontFamily === fontFamily) {
      return
    }
    this._fontFamily = fontFamily
    this.domNode.style.fontFamily = this._fontFamily
  }

  public setFontWeight(fontWeight: string): void {
    if (this._fontWeight === fontWeight) {
      return
    }
    this._fontWeight = fontWeight
    this.domNode.style.fontWeight = this._fontWeight
  }

  public setFontSize(_fontSize: number | string): void {
    const fontSize = calculateAsPixels(_fontSize)
    if (this._fontSize === fontSize) {
      return
    }
    this._fontSize = fontSize
    this.domNode.style.fontSize = this._fontSize
  }

  public setFontStyle(fontStyle: string): void {
    if (this._fontStyle === fontStyle) {
      return
    }
    this._fontStyle = fontStyle
    this.domNode.style.fontStyle = this._fontStyle
  }

  public setFontFeatureSettings(fontFeatureSettings: string): void {
    if (this._fontFeatureSettings === fontFeatureSettings) {
      return
    }
    this._fontFeatureSettings = fontFeatureSettings
    this.domNode.style.fontFeatureSettings = this._fontFeatureSettings
  }

  public setFontVariationSettings(fontVariationSettings: string): void {
    if (this._fontVariationSettings === fontVariationSettings) {
      return
    }
    this._fontVariationSettings = fontVariationSettings
    this.domNode.style.fontVariationSettings = this._fontVariationSettings
  }

  public setTextDecoration(textDecoration: string): void {
    if (this._textDecoration === textDecoration) {
      return
    }
    this._textDecoration = textDecoration
    this.domNode.style.textDecoration = this._textDecoration
  }

  public setLineHeight(_lineHeight: number | string): void {
    const lineHeight = calculateAsPixels(_lineHeight)
    if (this._lineHeight === lineHeight) {
      return
    }
    this._lineHeight = lineHeight
    this.domNode.style.lineHeight = this._lineHeight
  }

  public setLetterSpacing(_letterSpacing: number | string): void {
    const letterSpacing = calculateAsPixels(_letterSpacing)
    if (this._letterSpacing === letterSpacing) {
      return
    }
    this._letterSpacing = letterSpacing
    this.domNode.style.letterSpacing = this._letterSpacing
  }

  public setClassName(className: string): void {
    if (this._className === className) {
      return
    }
    this._className = className
    this.domNode.className = this._className
  }

  public toggleClassName(className: string, shouldHaveIt?: boolean): void {
    this.domNode.classList.toggle(className, shouldHaveIt)
    this._className = this.domNode.className
  }

  public setDisplay(display: string): void {
    if (this._display === display) {
      return
    }
    this._display = display
    this.domNode.style.display = this._display
  }

  public setPosition(position: string): void {
    if (this._position === position) {
      return
    }
    this._position = position
    this.domNode.style.position = this._position
  }

  public setVisibility(visibility: string): void {
    if (this._visibility === visibility) {
      return
    }
    this._visibility = visibility
    this.domNode.style.visibility = this._visibility
  }

  public setColor(color: string): void {
    if (this._color === color) {
      return
    }
    this._color = color
    this.domNode.style.color = this._color
  }

  public setBackgroundColor(backgroundColor: string): void {
    if (this._backgroundColor === backgroundColor) {
      return
    }
    this._backgroundColor = backgroundColor
    this.domNode.style.backgroundColor = this._backgroundColor
  }

  public setLayerHinting(layerHint: boolean): void {
    if (this._layerHint === layerHint) {
      return
    }
    this._layerHint = layerHint
    this.domNode.style.transform = this._layerHint ? 'translate3d(0px, 0px, 0px)' : ''
  }

  public setBoxShadow(boxShadow: string): void {
    if (this._boxShadow === boxShadow) {
      return
    }
    this._boxShadow = boxShadow
    this.domNode.style.boxShadow = boxShadow
  }

  public setContain(contain: 'none' | 'strict' | 'content' | 'size' | 'layout' | 'style' | 'paint'): void {
    if (this._contain === contain) {
      return
    }
    this._contain = contain
    this.domNode.style.contain = this._contain
  }

  public setZIndex(_zIndex: number | string): void {
    const zIndex = _zIndex + ''
    if (this._zIndex === zIndex) {
      return
    }
    this._zIndex = zIndex
    this.domNode.style.zIndex = zIndex
  }

  public setAttribute(name: string, value: string): void {
    this.domNode.setAttribute(name, value)
  }

  public removeAttribute(name: string): void {
    this.domNode.removeAttribute(name)
  }

  public getAttribute(name: string) {
    return this.domNode.getAttribute(name)
  }

  public appendChild(child: FastDomNode<T>): void {
    this.domNode.appendChild(child.domNode)
  }

  public removeChild(child: FastDomNode<T>): void {
    this.domNode.removeChild(child.domNode)
  }
}

export function createFastDomNode<T extends HTMLElement>(domNode: T): FastDomNode<T> {
  return new FastDomNode(domNode)
}
