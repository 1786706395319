import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { CopilotCommand } from '@pharmsnap/shared/src/types/copilot'
import { defineComponent, ref } from '@vue/composition-api'
import '../../../assets/icon-svg/chat.svg'
import '../../../assets/icon-svg/summary.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import $style from './BLS360AskMeCard.module.scss'
interface LS360AskMeCardStoreItemValue {
  showCount?: number
  clickCloseCount?: number
  clickAskCount?: number
}
type LS360AskMeCardStoreValue = Partial<Record<string, LS360AskMeCardStoreItemValue>>
class LS360AskMeCardStore {
  localeStorageKey = 'BLS360AskMeCard'
  todayDate = new Date().toLocaleDateString()
  countLimit = 2
  checkCanShow() {
    const localeStorageValue = this.getLocaleStorageValue()
    const todayValue = localeStorageValue[this.todayDate]
    // 当天点击关闭或者点击问我次数超过限制则不弹出
    if (todayValue && ((todayValue.clickCloseCount || 0) >= this.countLimit || (todayValue.clickAskCount || 0) >= this.countLimit)) {
      return false
    }
    // 当天的数据不在store中且已经有两天以上的数据则不弹出
    if (Object.keys(localeStorageValue).length >= 2) {
      return false
    }
    this.addShowCount()
    return true
  }

  addCloseCount() {
    this.incrementCount('clickCloseCount')
  }

  addClickAskCount() {
    this.incrementCount('clickAskCount')
  }

  addShowCount() {
    this.incrementCount('showCount')
  }

  private getLocaleStorageValue(): LS360AskMeCardStoreValue {
    try {
      const jsonStringValue = localStorage.getItem(this.localeStorageKey)
      return jsonStringValue ? (JSON.parse(jsonStringValue) as LS360AskMeCardStoreValue) : {}
    } catch (error) {
      return {}
    }
  }

  private incrementCount(key: keyof LS360AskMeCardStoreItemValue) {
    const localeStorageValue = this.getLocaleStorageValue()
    const todayValue = localeStorageValue[this.todayDate]
    const newTodayValue = {
      ...todayValue,
      [key]: (todayValue?.[key] || 0) + 1,
    }
    localStorage.setItem(this.localeStorageKey, JSON.stringify({ ...localeStorageValue, [this.todayDate]: newTodayValue }))
  }
}
export const BLS360AskMeCard = defineComponent({
  name: 'BLS360AskMeCard',
  props: {
    queryText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { tsText, localeUpcase } = useLocale()
    const question = ref('')
    const shouldShow = ref(false)
    const store = new LS360AskMeCardStore()
    const {
      state: { dialogVisible, pageContext },
      actions: { handleSelectEmitCommand },
    } = useLs360ChatStore()
    async function getQuestion() {
      const rt = await sharedCtx.service.ls360Api.getQuestionRecommend({
        text: props.queryText,
        page_context: {
          params: {
            language: localeUpcase.value,
          },
        },
      })
      if (rt.success) {
        question.value = rt.data.question?.[0]?.text
      }
    }
    function init() {
      if (!props.queryText) {
        return
      }
      // 如果hiro对话框已经显示则不再显示
      if (dialogVisible.value) {
        return
      }
      shouldShow.value = store.checkCanShow()
      if (shouldShow.value) {
        getQuestion()
      }
    }
    function onClose() {
      store.addCloseCount()
      shouldShow.value = false
    }
    async function onAsk() {
      handleSelectEmitCommand(CopilotCommand.start_chat_sent_immediately, question.value)
      store.addClickAskCount()
      shouldShow.value = false
    }
    function onSummarize() {
      handleSelectEmitCommand(CopilotCommand.page_summarize, tsText('总结当前页面', 'Summarize this page.'))
      store.addClickAskCount()
      shouldShow.value = false
    }
    init()
    return { tsText, shouldShow, question, pageContext, onClose, onAsk, onSummarize }
  },
  render() {
    if (!this.shouldShow) {
      return <div></div>
    }
    if (!this.question) {
      return <div></div>
    }
    return (
      <div data-selection-disabled="true">
        <div class={$style.hiro}></div>
        <div class={$style.mainCard}>
          <div class="absolute top-2 right-2 cursor-pointer z-10" onClick={this.onClose}>
            <GIcon svgName="SolidCloseMedium" size={24} class="cursor-pointer"></GIcon>
          </div>
          <div class={$style.mainCardContent}>
            <div class="flex justify-between items-center mb-4">
              <div class="text-base font-semibold">
                {this.tsText('Hi, 芽仔升级啦！你可以问我：', 'Hi, Hiro has leveled up! You can ask me anything.')}
              </div>
            </div>
            <div class="space-y-2">
              <span class={$style.questionItem} onClick={this.onAsk}>
                <GIcon class="flex-shrink-0" svgName="Chat" size={20}></GIcon>
                <span>{this.question}</span>
              </span>
              {this.pageContext && (
                <span class={$style.questionItem} onClick={this.onSummarize}>
                  <GIcon class="flex-shrink-0" svgName="Summary" size={20}></GIcon>
                  <span>{this.tsText('总结当前页面信息', 'Summarize the information on the current page')}</span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
})
