import { IQuery, IQueryItem } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { getQueryIdByQuery, showSingleToast } from '@pharmsnap/shared/utils'

/**
 * 检查query中是否包含所有字段的filter类型
 * @param queryItems
 * @param fields
 * @returns
 */
function checkQueryFilterHasALLFieldFilter(queryItems: IQueryItem[], fields: string[]) {
  for (const field of fields) {
    const rt = queryItems.some((queryFilter) => queryFilter.type === 'field' && queryFilter.fields.includes(field))
    if (rt === false) {
      return false
    }
  }
  return true
}

export function usePatentChartDrill() {
  const {
    getters: { isFreeUser },
    actions,
  } = useAuthStore()
  const { isCN } = useLocale()

  const onClickChart = (options: {
    queryState: IQueryService['state']
    extraMust?: IQueryItem[]
    extraFilter?: IQueryItem[]
    customQuery?: IQuery
  }) => {
    if (isFreeUser.value) {
      actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'CLICK_CHART' })
      return
    }

    const {
      queryState: { query, collapse },
      extraMust,
      extraFilter,
      customQuery,
    } = options
    const { must, filter } = query
    const mergeQuery = customQuery || { must: [...must, ...(extraMust || [])], filter: [...filter, ...(extraFilter || [])], type: 'group' }
    if (checkQueryFilterHasALLFieldFilter(mergeQuery.filter || [], ['PHS_ORG_ROOT_ID_ONEID', 'PHS_ORG_ID_ONEID'])) {
      showSingleToast({
        message: isCN.value
          ? '当前图表中以总公司聚合的设置与左侧过滤项不一致，请设置为相同后，再次尝试'
          : 'The setting of aggregation by parent company in the current chart is not the same as the filter item on the left, please set it to the same and try again.',
        type: 'error',
      })
      return
    }
    openPageWithoutPending(async () => {
      const queryId = await getQueryIdByQuery(mergeQuery, 'patent', collapse)

      if (queryId) {
        return sharedCtx.router.generatorPatentListPath(queryId)
      }
      return ''
    })
  }

  return {
    onClickChart,
  }
}
