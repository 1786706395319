import { IQueryItem } from '@patsnap/synapse_common_interface'
import { IHomeAutoCompleteEntityType, ISearchDataType } from '../types/autocomplete'
import { IHomeQueryValueEntityType, IQuickSearchDataType, ISearchHistoryFromModule } from '../types/search'
import {
  checkFieldConditionIsNone,
  checkFieldValueIsNotSpecifiedSearchStrategy,
  checkFieldValueIsSpecifiedSearchStrategy,
  checkFiledValueIsOverLimit,
  checkFiledValueIsSpecifiedValue,
  checkIsRegisterNumber,
  checkSpecifiedFieldsListIsMultipleValue,
  checkSpecifiedTypeIsMultipleValue,
} from '../utils/search'
/**
 * 各个数据类型的最大实体个数
 */
export const maxEntityTagCount = 300
/**
 * query里面药物搜索最大个数
 */
export const maxDrugCount = maxEntityTagCount

/**
 * 源query里面只包含其对应的supportTransFields才能转，否则直接clear
 */
export const dataTypeFieldConfig: Partial<
  Record<
    IQuickSearchDataType,
    {
      supportTransFields: string[]
      supportInputFields: string[]
      fromModule: ISearchHistoryFromModule
      /**
       * 控制值是否可以禁止编辑高级query语句的
       */
      disableQueryFn?: (queryItems: IQueryItem[]) => boolean
    }
  >
> = {
  all: {
    supportTransFields: ['DRUG_ID', 'DISEASE_ID', 'TARGET_ID', 'ALL_FIELDS', 'ORGANIZATION_ID_ONEID', 'DRUG_TYPE'],
    supportInputFields: ['DRUG_ID', 'DISEASE_ID', 'TARGET_ID', 'ALL_FIELDS', 'ORGANIZATION_ID_ONEID', 'DRUG_TYPE'],
    fromModule: 'Homepage',
  },
  drug: {
    // autocomplete 支持哪些实体，这些实体从药物检索变更为其他类型的检索支持的索引字段
    supportTransFields: ['DRUG_ID', 'DISEASE_ID', 'DRUG_TYPE', 'TARGET_ID', 'ORGANIZATION_ID_ONEID', 'ALL_FIELDS'],
    // autocomplete 支持哪些实体，这些实体在药物下搜索应该是什么索引字段
    supportInputFields: ['DRUG_ID', 'DISEASE_ID', 'DRUG_TYPE', 'TARGET_ID', 'ORGANIZATION_ID_ONEID', 'ALL_FIELDS'],
    fromModule: 'Drug Finder',
    disableQueryFn: (queryItems) => {
      /** drug finder页面没有单独的原研、在研、非在研query，所以不允许编辑高级检索 */
      return (
        checkFiledValueIsOverLimit(queryItems, 'DRUG_ID', maxDrugCount) ||
        checkFieldConditionIsNone(queryItems) ||
        checkFiledValueIsSpecifiedValue(queryItems, [
          'TARGET_ID_COMPETITIVE',
          'DISEASE_ID_ALL',
          'DRUG_TYPE_ALL',
          'CREATED_AT',
          'ORIGINAL_DEV_ORG_ID_ONEID',
          'ORIGINAL_DEV_ORG_ID_ROOT_ONEID',
          'DEV_ORG_ID_ONEID',
          'DEV_ORG_ID_ROOT_ONEID',
          'NON_DEV_ORG_ID_ONEID',
          'NON_DEV_ORG_ID_ROOT_ONEID',
          'HIGHEST_DEV_STATUS_CN',
          'APPROVAL_COUNTRY',
          'COUNTRY_WITH_EU',
          'THERAPEUTIC_AREA_DRUG',
          'DRUG_ORG_EMP_NUMBER_GENERAL_ONEID',
          'COUNTRY',
          'TARGET_ID_EXTEND',
          'ANTIBODY_TYPE',
          'SITE_ID',
          'SPECIFIC_SITE',
          'INCHI_KEY_ID',
        ]) ||
        checkFieldValueIsSpecifiedSearchStrategy(queryItems, 'ORGANIZATION_ID_ONEID', 'ID')
      )
    },
  },
  clinical_trial: {
    supportTransFields: [
      'MAIN_EXPERIMENTATION_DRUG_ID',
      'CONTROL_DRUG_ID',
      'CONDITION_DISEASE_ID',
      'TARGET_ID',
      'ALL_FIELDS',
      'ALL_FIELDS_TL',
      'REGISTRY_ID_ONEID',
      'DRUG_TYPE',
    ],
    supportInputFields: [
      'MAIN_EXPERIMENTATION_DRUG_ID',
      'CONTROL_DRUG_ID',
      'CONDITION_DISEASE_ID',
      'TARGET_ID',
      'ALL_FIELDS',
      'ALL_FIELDS_TL',
      'REGISTRY_ID_ONEID',
      'DRUG_TYPE',
    ],
    fromModule: 'Clinical Progress',
    disableQueryFn: (queryItems) => {
      return (
        checkFiledValueIsOverLimit(queryItems, 'MAIN_EXPERIMENTATION_DRUG_ID', maxDrugCount) ||
        checkFiledValueIsSpecifiedValue(queryItems, [
          'CREATED_AT',
          'TARGET_ID_COMPETITIVE',
          'CONDITION_DISEASE_ID_ALL',
          'DRUG_TYPE_ALL',
          'INCHI_KEY_ID',
        ]) ||
        checkSpecifiedFieldsListIsMultipleValue(queryItems, [['CONDITION_DISEASE_ID'], ['REGISTRY_ID_ONEID']]) ||
        // 临床注册号在项目中是几个字段的合集，但会有邮件/历史查询可能只带一个字段进行查询，这边禁止下只有一个注册号字段时的query编辑行为
        checkIsRegisterNumber(queryItems) ||
        checkFieldValueIsSpecifiedSearchStrategy(queryItems, 'REGISTRY_ID_ONEID', 'ID')
      )
    },
  },
  clinical_trial_result: {
    supportTransFields: [
      'MAIN_EVALUATE_DRUG_ID',
      'MAIN_EXPERIMENTATION_DRUG_ID',
      'CONTROL_DRUG_ID',
      'CONDITION_DISEASE_ID',
      'TARGET_ID',
      'ALL_FIELDS',
      'ALL_FIELDS_TL',
      'SPONSOR_COLLABORATOR_ID_ONEID',
      'DRUG_TYPE',
    ],
    supportInputFields: ['CONDITION_DISEASE_ID', 'DRUG_TYPE', 'TARGET_ID', 'SPONSOR_COLLABORATOR_ID_ONEID', 'ALL_FIELDS', 'ALL_FIELDS_TL'],
    fromModule: 'Clinical Result Finder',
    disableQueryFn: (queryItems) => {
      return (
        checkFiledValueIsOverLimit(queryItems, 'MAIN_EVALUATE_DRUG_ID', maxDrugCount) ||
        checkFiledValueIsOverLimit(queryItems, 'MAIN_EXPERIMENTATION_DRUG_ID', maxDrugCount) ||
        checkFiledValueIsSpecifiedValue(queryItems, ['TARGET_ID_EXTEND'])
      )
    },
  },
  news: {
    supportTransFields: ['phs_drug_id', 'phs_disease_id', 'phs_target_id', 'ALL_FIELDS', 'master_phs_organization_id'],
    supportInputFields: ['phs_drug_id', 'phs_disease_id', 'phs_target_id', 'ALL_FIELDS', 'master_phs_organization_id'],
    fromModule: 'Latest News',
  },
  paper: {
    supportTransFields: ['PHS_DRUG_ID', 'PHS_ORG_ID_ONEID', 'PHS_DISEASE_ID', 'PHS_TARGET_ID', 'ALL_FIELDS', 'ALL_FIELDS_TL', 'PHS_DRUG_TYPE'],
    supportInputFields: ['PHS_DRUG_ID', 'PHS_ORG_ID_ONEID', 'PHS_DISEASE_ID', 'PHS_TARGET_ID', 'ALL_FIELDS', 'ALL_FIELDS_TL', 'PHS_DRUG_TYPE'],
    fromModule: 'Literature',
    disableQueryFn: (queryItems) => {
      return (
        checkFiledValueIsOverLimit(queryItems, 'PHS_DRUG_ID', maxDrugCount) ||
        checkFiledValueIsSpecifiedValue(queryItems, ['CREATED_TS', 'SEMANTIC']) ||
        checkSpecifiedFieldsListIsMultipleValue(queryItems, [['PHS_ORG_ID_ONEID']])
      )
    },
  },
  patent: {
    supportTransFields: ['PHS_CORE_DRUG_ID', 'PHS_DISEASE_ID', 'PHS_TARGET_ID', 'ALL_FIELDS'],
    supportInputFields: ['PHS_CORE_DRUG_ID', 'PHS_DISEASE_ID', 'PHS_TARGET_ID', 'ALL_FIELDS'],
    fromModule: 'Patent',
    disableQueryFn: (queryItems) => {
      return (
        checkFiledValueIsOverLimit(queryItems, 'PHS_CORE_DRUG_ID', maxDrugCount) ||
        checkFiledValueIsOverLimit(queryItems, 'PHS_CORE_TYPE_ID', 2) ||
        checkFiledValueIsSpecifiedValue(queryItems, ['CREATE_TS', 'SEMANTIC', 'PHS_DRUG_ID']) ||
        checkSpecifiedFieldsListIsMultipleValue(queryItems, [['PHS_DISEASE_ID']]) ||
        checkSpecifiedTypeIsMultipleValue(queryItems, 'organization')
      )
    },
  },
  drug_deal: {
    supportTransFields: ['DRUG_ID', 'DRUG_TYPE', 'TARGET_ID', 'ALL_FIELDS', 'PRINCIPLE_ORG_ID', 'PARTNER_ORG_ID'],
    supportInputFields: ['DRUG_ID', 'DRUG_TYPE', 'TARGET_ID', 'ALL_FIELDS'],
    fromModule: 'Drug Deal Finder',
    disableQueryFn: (queryItems) => {
      return (
        checkFiledValueIsOverLimit(queryItems, 'DRUG_ID', maxDrugCount) ||
        checkFiledValueIsSpecifiedValue(queryItems, [
          'TARGET_ID_EXTEND',
          'TARGET_ID_EXTEND_FLATTEN',
          'PRINCIPLE_ORG_ID_ROOT',
          'PARTNER_ORG_ID_ROOT',
          'DEAL_PROJECT_DRUG_ID',
        ]) ||
        checkFiledValueIsSpecifiedValue(queryItems, ['PRINCIPLE_ORG_ID', 'PARTNER_ORG_ID'], true) ||
        (checkFiledValueIsSpecifiedValue(queryItems, ['DRUG_ID', 'TARGET_ID_FLATTEN', 'DRUG_TYPE', 'THERAPEUTIC_AREA', 'MECHANISM_ACTION_ID']) &&
          checkFiledValueIsSpecifiedValue(queryItems, ['NORMALIZED_PHASE_SIGN_ID'])) || // 交易列表，query中包含药物/药物类型/靶点/治疗领域/作用机制、研发状态，若不是拍平的，不允许编辑高级检索（因为高级检索中，若搜索了研发状态，药物/药物类型/靶点/作用机制/治疗领域是拍平的）
        (checkFiledValueIsSpecifiedValue(queryItems, ['TARGET_ID', 'DRUG_TYPE', 'THERAPEUTIC_AREA', 'MECHANISM_ACTION_ID']) &&
          checkFiledValueIsSpecifiedValue(queryItems, ['NORMALIZED_PHASE_SIGN_ID_FLATTEN'])) ||
        checkFieldValueIsNotSpecifiedSearchStrategy(queryItems, 'PRINCIPLE_ORG_ID', 'ID_ROLLUP') ||
        checkFieldValueIsNotSpecifiedSearchStrategy(queryItems, 'PARTNER_ORG_ID', 'ID_ROLLUP')
      )
    },
  },
  translational_medicine: {
    supportTransFields: ['DRUG_ID', 'DISEASE_ID', 'DRUG_TYPE', 'TARGET_ID', 'ALL_FIELDS', 'RESEARCH_SPONSOR_ID'],
    supportInputFields: ['DRUG_ID', 'DISEASE_ID', 'DRUG_TYPE', 'TARGET_ID', 'ALL_FIELDS', 'RESEARCH_SPONSOR_ID'],
    fromModule: 'Translational Medicine Finder',
    disableQueryFn: (queryItems) => {
      return checkFiledValueIsOverLimit(queryItems, 'DRUG_ID', maxDrugCount) || checkSpecifiedFieldsListIsMultipleValue(queryItems, [['DISEASE_ID']])
    },
  },
}

/**
 * autocomplete返回的Item可能的类型
 */
type transType = IHomeAutoCompleteEntityType | 'Topic'

/**
 * core autocomplete调用的时候，指定dataType来过滤掉不需要的数据
 */
export const dataType2SearchDataTypeMap: Partial<Record<IQuickSearchDataType, ISearchDataType>> = {
  paper: 'PAPER',
  news: 'NEWS',
  patent: 'PATENT',
  drug_deal: 'DRUG_DEAL',
}

/** 将query item 的type转成autoComplete type */
export const queryItemTypeMapAutoCompleteType: Record<IHomeQueryValueEntityType, IHomeAutoCompleteEntityType> = {
  drug: 'Drug',
  target: 'Target',
  disease: 'Disease',
  organization: 'Organization',
  drugType: 'DrugType',
}

/** 将autoComplete type转成query item type */
export const autoCompleteTypeMapQueryItemType: Record<IHomeAutoCompleteEntityType, IHomeQueryValueEntityType> = {
  Drug: 'drug',
  Target: 'target',
  Disease: 'disease',
  Organization: 'organization',
  DrugType: 'drugType',
}
