import { ALL_SUBJECT_CONFIG_MAP } from '@patsnap/synapse_common_config'
import { IAggregationData, IAggregationValue } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { IResearchDirectionChartAnalysisEntityType, IResearchDirectionChartEntityItem } from '@pharmsnap/shared/src/types/translational-medicine'
import { sortSubject } from '@pharmsnap/shared/src/utils'
import { extractSpecifiedItemsFromAggResult } from '@pharmsnap/shared/src/utils/business/trans-medicine/trans-medicine-chart'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ColumnInfo } from 'vxe-table'
import { BAnalysisEntityItemWithPopper } from './BAnalysisEntityItemWithPopper'
import { BAnalysisPieDoughnut } from './BAnalysisPieDoughnut'
import $style from './BAnalysisPieDoughnutTable.module.scss'
import { BAnalysisWaterMarkContainer } from './BAnalysisWaterMarkContainer'

type XDirectionItemDataType = { oneDimData?: IAggregationValue; twoDimData?: IAggregationValue }

type ITableItem = {
  yDirectionItem: IResearchDirectionChartEntityItem
  [key: string]: XDirectionItemDataType | IResearchDirectionChartEntityItem | undefined
}

export const BAnalysisResearchDirectionChart = defineComponent({
  name: 'BAnalysisResearchDirectionChart',
  props: {
    aggData: {
      required: true,
      type: Object as PropType<IAggregationData>,
    },
    isEmpty: {
      type: Boolean,
    },
    xDirectionList: {
      type: Array as PropType<IResearchDirectionChartEntityItem[]>,
      required: true,
    },
    yDirectionList: {
      type: Array as PropType<IResearchDirectionChartEntityItem[]>,
      required: true,
    },
    analysisEntity: {
      type: String as PropType<IResearchDirectionChartAnalysisEntityType>,
      required: true,
    },
    expandDisease: {
      type: Object as PropType<IResearchDirectionChartEntityItem>,
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  setup(props, { emit }) {
    const { locale } = useLocale()

    const aggItems = computed<IAggregationValue[] | null>(() => {
      if (props.isEmpty) {
        return null
      }
      return props.aggData.aggregation_result[0].items
    })

    /** X 轴方向表格列 */
    const xDirectionColumn = computed<Partial<ColumnInfo>[]>(() => {
      return props.xDirectionList.map((item) => ({
        field: item.id,
        title: getLangDegraded(item, locale.value),
        minWidth: 132,
        slots: {
          header: () => [
            <div class="flex items-center justify-center">
              <div class="break-all" key={item.id}>
                {renderEntity(item)}
              </div>
              {!props.expandDisease && props.analysisEntity !== 'TARGET_DRUG_TYPE' && <div class="ml-1 ">{renderAction('expand', item)}</div>}
            </div>,
          ],
          default: ({ row }: { row: ITableItem }) => {
            const xDirectionItemData = row[item.id] as XDirectionItemDataType | undefined
            return [
              <div class="flex flex-col items-center">
                <BAnalysisPieDoughnut
                  key={`${xDirectionItemData?.oneDimData?.key}-${xDirectionItemData?.twoDimData?.key}`}
                  data={xDirectionItemData?.twoDimData}
                  firstDimName={getLangDegraded(row.yDirectionItem, locale.value)}
                  onClickItem={(categoryItem: IAggregationValue) =>
                    handleClick(
                      xDirectionItemData?.oneDimData as IAggregationValue,
                      xDirectionItemData?.twoDimData as IAggregationValue,
                      categoryItem
                    )
                  }
                  onClickTotal={() =>
                    handleClick(xDirectionItemData?.oneDimData as IAggregationValue, xDirectionItemData?.twoDimData as IAggregationValue)
                  }
                ></BAnalysisPieDoughnut>
              </div>,
            ]
          },
        },
      }))
    })
    const pieTableChartColumn = computed(() => {
      if (!props.isEmpty) {
        const column: Partial<ColumnInfo>[] = [
          {
            field: 'yDirectionItem',
            className: 'bg-[#f8f8f9]',
            width: 116,
            fixed: 'left',
            slots: {
              header: () => [<div class="bg-[#f8f8f9]"></div>],
              default: ({ row }: { row: ITableItem }) => {
                return [row.yDirectionItem && <div key={row.yDirectionItem.id}>{renderEntity(row.yDirectionItem, 3)}</div>]
              },
            },
          },
        ]
        column.push(...xDirectionColumn.value)
        return column
      }
      return []
    })

    const pieTableChartData = computed(() => {
      if (aggItems.value) {
        const tableData: ITableItem[] = []

        props.yDirectionList.forEach((yDirectionItem) => {
          const tableItem: ITableItem = {
            yDirectionItem,
          }
          const oneDimData = aggItems.value?.find((item) => item.key === yDirectionItem.id)
          xDirectionColumn.value.forEach((xDirectionItem) => {
            const twoDimData = oneDimData?.aggregation_result?.[0]?.items?.find((item) => item.key === xDirectionItem.field)
            if (oneDimData && twoDimData) {
              oneDimData.aggregation_field = props.aggData?.aggregation_result?.[0]?.aggregation_field
              twoDimData.aggregation_field = oneDimData?.aggregation_result?.[0]?.aggregation_field
              tableItem[xDirectionItem.field] = {
                oneDimData,
                twoDimData,
              }
            }
          })
          tableData.push(tableItem)
        })
        return tableData
      }
      return []
    })

    const subjectItems = computed(() => {
      const items = extractSpecifiedItemsFromAggResult(props.aggData?.aggregation_result || [], 'SUBJECT_ID')
      return items.sort((a, b) => sortSubject(a.key, b.key))
    })

    function renderEntity(item: IResearchDirectionChartEntityItem, lineClamp = 1) {
      return <BAnalysisEntityItemWithPopper entity={item} lineClamp={lineClamp}></BAnalysisEntityItemWithPopper>
    }

    function renderAction(status: 'expand' | 'collapse', item: IResearchDirectionChartEntityItem) {
      return (
        <div
          class="w-4 h-4 rounded-[2px] flex items-center justify-center text-xs font-medium cursor-pointer bg-blue-light hover:bg-blue-disable text-blue-default"
          onClick={() => emit('toggleExpand', status === 'expand' ? item : undefined, status)}
        >
          {status === 'expand' ? '+' : '-'}
        </div>
      )
    }

    function handleClick(oneDimItem: IAggregationValue, twoDimItem: IAggregationValue, threeDimItem?: IAggregationValue) {
      emit('click', {
        oneDimItem,
        twoDimItem,
        threeDimItem,
      })
    }

    return {
      pieTableChartColumn,
      pieTableChartData,
      subjectItems,
      locale,
      renderEntity,
      renderAction,
    }
  },
  methods: {
    renderPieTable() {
      const enableScroll = {
        enabled: true,
        gt: 8,
      }
      return (
        <BAnalysisWaterMarkContainer>
          <vxe-grid
            class={[$style.pieDoughnutTable, 'z-[1]']}
            scrollX={enableScroll}
            scrollY={enableScroll}
            maxHeight={this.height}
            border={true}
            showHeader
            showOverflow={false}
            size="small"
            show-header-overflow={false}
            data={this.pieTableChartData}
            columns={this.pieTableChartColumn}
            row-config={{ height: 68 }}
          ></vxe-grid>
        </BAnalysisWaterMarkContainer>
      )
    },
    renderSubjectItems() {
      if (this.subjectItems.length) {
        return (
          <div class="mb-2 flex flex-wrap">
            {this.subjectItems.map((item) => (
              <div class="flex items-center text-xs leading-4 text-text-t1 mr-3">
                <span
                  class="inline-block h-[10px] w-[10px] rounded-[1px] mr-1"
                  style={{ backgroundColor: ALL_SUBJECT_CONFIG_MAP[item.key]?.color || '#1976D2' }}
                ></span>
                <span>{getLangDegraded({ name_cn: item.display_name_cn, name_en: item.display_name_en }, this.locale)}</span>
              </div>
            ))}
          </div>
        )
      }
    },
    renderExpandDisease() {
      if (this.expandDisease) {
        return (
          <div class="p-3 bg-[#f8f8f9] border border-solid border-[#e5e7eb] border-b-[0]">
            <div class="flex items-center justify-center  overflow-hidden">
              {<div class="truncate">{this.renderEntity(this.expandDisease)}</div>}
              <div class="ml-1">{this.renderAction('collapse', this.expandDisease)}</div>
            </div>
          </div>
        )
      }
    },
  },
  render() {
    return (
      <div class="flex flex-col w-full h-full">
        <div class="flex-shrink-0">{this.renderSubjectItems()}</div>
        {this.renderExpandDisease()}
        <div class="flex-1 overflow-hidden">{this.renderPieTable()}</div>
      </div>
    )
  },
})
