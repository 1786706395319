import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import { IBPageDrawerLayoutMenuItem } from '../../../../types/component'
import { GEmpty } from '../../../ui/GEmpty/GEmpty'
import { GHeaderDrawerLayout } from '../../../ui/GHeaderDrawerLayout/GHeaderDrawerLayout'
import $classes from './BPageDrawerLayout.module.scss'

export const BPageDrawerLayout = defineComponent({
  name: 'BPageDrawerLayout',
  model: {
    prop: 'activeMenu',
    event: 'input',
  },
  props: {
    activeMenu: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 160,
    },
    menu: {
      type: Array as PropType<IBPageDrawerLayoutMenuItem[]>,
      default: () => [],
    },
    mode: {
      type: String as PropType<'render' | 'noRender'>,
      default: 'render',
    },
    customMainCls: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { width, menu, activeMenu } = toRefs(props)
    const expanded = ref(true)
    const minWidth = ref(36)
    const maxWidth = width.value
    const hideSide = computed(() => menu.value.length === 0 || menu.value.every((i) => i.disabled))
    const isDisabled = computed(() => {
      const foundMenu = menu.value.find((i) => i.key === activeMenu.value)
      return (foundMenu && foundMenu.disabled) || false
    })

    return {
      expanded,
      minWidth,
      maxWidth,
      hideSide,
      isDisabled,
    }
  },
  methods: {
    onChangeMenu(key: string) {
      if (key !== this.activeMenu) {
        this.$emit('input', key)
        this.$emit('change', key)
      }
    },
    renderMenu() {
      return (
        <div class={$classes.menu}>
          {this.menu.map((item) => {
            const { disabled, key, label } = item
            const isActive = key === this.activeMenu
            return (
              <div
                onClick={() => this.onChangeMenu(key)}
                class={[$classes.menuItem, disabled ? $classes.menuItemDisabled : '', isActive ? $classes.menuItemActive : '']}
              >
                {typeof label === 'function' ? label() : label}
              </div>
            )
          })}
        </div>
      )
    },
    renderContent() {
      if (this.mode === 'noRender') {
        if (this.isDisabled) return <GEmpty></GEmpty>
        return <div class={$classes.main}>{this.$slots.default}</div>
      }

      return [
        <GEmpty v-show={this.isDisabled}></GEmpty>,
        <div class={this.customMainCls || $classes.main} v-show={!this.isDisabled}>
          {this.$slots.default}
        </div>,
      ]
    },
  },
  render() {
    return (
      <GHeaderDrawerLayout v-model={this.expanded} maxWidth={this.maxWidth} triggerTop={24} minWidth={this.minWidth} showSide={!this.hideSide}>
        <template slot="header">{this.$slots.header}</template>
        <template slot="drawer">{this.renderMenu()}</template>
        <template slot="default">
          <div class={$classes.content} id="content">
            {this.renderContent()}
          </div>
        </template>
      </GHeaderDrawerLayout>
    )
  },
})
