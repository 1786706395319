import { getCurrentInstance, h, onMounted, Ref, watch } from '@vue/composition-api'
import tippy, { Instance } from 'tippy.js'
import Vue from 'vue'
import { BEntityFetchCard } from '../components/business/card/BEntityFetchCard'
import { sharedCtx } from '../context'
import { IQueryDataEntityType } from '../types'
import { openNewTab } from '../utils/business/dom'

export function useChartEntityTooltip(
  container: Ref<HTMLElement | undefined>,
  position: Ref<{ left: number; top: number }>,
  entity: Ref<{ id: string; type: IQueryDataEntityType | 'text'; displayName?: string }>,
  style: Ref<any>,
  showTooltip = true
) {
  let el: HTMLElement | null = null
  let tooltip: Instance | null
  let vm: any
  let cachedOffset: number[] | null = null

  const useIns = getCurrentInstance()

  function navToDetailPage() {
    const { id, type } = entity.value
    let href = ''
    if (type === 'disease') href = sharedCtx.router.generatorDiseasePath(id)
    else if (type === 'drug') href = sharedCtx.router.generatorDrugPath(id)
    else if (type === 'target') href = sharedCtx.router.generatorTargetPath(id)
    else if (type === 'organization') href = sharedCtx.router.generatorOrgPath(id)
    else if (type === 'drug_type') href = sharedCtx.router.generatorDrugTypePath(id)
    if (href) {
      openNewTab(href)
    }
  }

  function createEl() {
    if (el) return
    el = document.createElement('div')
    container.value && container.value.appendChild(el)
    // options && options.registerEvent && options.registerEvent(el)
    el.addEventListener('click', navToDetailPage)
  }

  function createTooltip() {
    if (!el) return
    if (tooltip) return
    tooltip = tippy(el, {
      placement: 'right-start',
      interactive: true,
      trigger: 'mouseenter',
      animation: 'shift-toward',
      appendTo: document.body,
      delay: [50, 50],
      arrow: false,
      allowHTML: true,
      offset: [0, 0],
      onShow: onTooltipShow,
    })
  }

  function destroyTooltip() {
    tooltip && tooltip.destroy()
    tooltip = null
    vm && vm.$destroy()
    vm = null
    if (container.value && el) {
      container.value.removeChild(el)
    }
    if (el) {
      el.removeEventListener('click', navToDetailPage)
      el = null
    }
  }

  function getOffset() {
    if (!container.value) return [0, 0]
    if (cachedOffset) return cachedOffset
    const computedStyle = getComputedStyle(container.value)
    const pLeft = computedStyle.paddingLeft
    const pTop = computedStyle.paddingTop
    cachedOffset = [parseInt(pLeft), parseInt(pTop)]
    return [parseInt(pLeft), parseInt(pTop)]
  }

  function onTooltipShow(ins: Instance) {
    if (!vm) {
      vm = new Vue({
        i18n: useIns?.proxy.$i18n,
        render() {
          if (entity.value.type === 'text' || entity.value.type === 'drug_type')
            return entity.value.displayName
              ? h(
                  'div',
                  {
                    class: ['rounded', 'bg-white-default', 'text-text-default', 'text-xs'],
                    style: { boxShadow: 'rgba(0, 0, 0, 0.3) 1px 2px 6px', padding: '0.5rem 0.75rem' },
                  },
                  entity.value.displayName
                )
              : h('div')
          return h(BEntityFetchCard, {
            props: {
              id: entity.value.id,
              type: entity.value.type,
            },
            on: {
              loaded: () => {
                vm && vm.$el && ins.setContent(vm.$el)
              },
            },
          })
        },
      })
      vm.$mount()
      ins.setContent(vm.$el)
    } else {
      ins.setContent(vm.$el)
    }
  }

  function updateStyle() {
    if (!el) return
    const keys = Object.keys(style.value)
    keys.forEach((key: any) => {
      el && (el.style[key] = style.value[key])
    })
    const [offsetX, offsetY] = getOffset()
    el.style.position = 'absolute'
    el.style.top = `${position.value.top + offsetY}px`
    el.style.left = `${position.value.left + offsetX}px`
    el.style.cursor = 'pointer'
    el.style.zIndex = '1'
  }

  function initTooltip() {
    if (!container.value) return
    createEl()
    updateStyle()
    if (showTooltip) createTooltip()
  }

  onMounted(initTooltip)

  watch(container, (val) => {
    if (val) initTooltip()
    else destroyTooltip()
  })

  watch([position, style], updateStyle, {
    deep: true,
  })
}
