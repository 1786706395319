export const versionCookieKey = 'version'
export const deBuggerModelStoreKey = 'synapse_dev_tool'
export const deBuggerDisableDecryptStoreKey = 'synapse_dev_tool:disable-decrypt'
export const deBuggerHighlightIconFontStoreKey = 'synapse_dev_tool:hight-icon-font'
export const deBuggerCacheStoreKey = 'synapse_dev_tool:cache'
export const deBuggerConfig = {
  isDebuggerModel: !!window.localStorage.getItem(deBuggerModelStoreKey),
  isHighlightIconFont: !!window.localStorage.getItem(deBuggerHighlightIconFontStoreKey),
  isDisableDecryptIconFont: !!window.localStorage.getItem(deBuggerDisableDecryptStoreKey),
  isOpenCache: !!window.localStorage.getItem(deBuggerCacheStoreKey),
}
