import { I18nLang } from '@pharmsnap/shared/i18n'
import { IOrganizationDetail, IOrgCorpTree } from '@pharmsnap/shared/types/organization'
import { getOrganizationName, getOrganizationNameAndIcon } from '@pharmsnap/shared/utils'
import defaultCompanyLogo from './default-company-logo.svg'
export interface ICorporateTreeNode {
  /**
   * 唯一键值
   */
  id: string
  /**
   * 名称
   */
  display_name: string
  /**
   * 描述
   */
  desc?: string
  /**
   * logo, s3签名后的地址
   */
  logo?: string
  /**
   * 是否包含子
   */
  has_children: boolean
  /**
   * 子节点
   */
  children?: ICorporateTreeNode[]
}
export function transformOrganization2CorpTreeData(data: IOrganizationDetail, lang: I18nLang): ICorporateTreeNode {
  return {
    logo: data.logo || defaultCompanyLogo,
    id: data.entity_id,
    display_name: getOrganizationName(data, lang) || '',
    has_children: true,
  }
}
export function transformCorpTreeData(data: IOrgCorpTree, lang: I18nLang): ICorporateTreeNode {
  return {
    ...data,
    logo: data.logo || defaultCompanyLogo,
    has_children: !!data.has_subsidiary,
    display_name: getOrganizationName(data, lang),
    desc: formateDesc(data, lang),
    children: data.subsidiary ? data.subsidiary.map((item) => transformCorpTreeData(item, lang)) : [],
  }
}
export function getOwnershipType(data: IOrgCorpTree, lang: I18nLang): string {
  const { ownership_type = [], normalized_entity_type_en } = data
  const first = ownership_type[0]
  // 大部分的叶子节点都是子公司，没有显示的意义，隐藏子公司类型
  if (first === 'Subsidiary') {
    return ''
  }
  return getOrganizationNameAndIcon({ organizationType: normalized_entity_type_en, ownershipTypeList: ownership_type }, lang).title
}
export function formateDesc(data: IOrgCorpTree, lang: I18nLang): string {
  const country = (lang === 'cn' ? data.country_cn || data.country : data.country || data.country_cn) || ''
  return [getOwnershipType(data, lang), country].filter((item) => item).join(' | ')
}
