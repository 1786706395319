/* eslint-disable @typescript-eslint/no-explicit-any */
import { I18nLang, IQueryItem, IQueryValue } from '@patsnap/synapse_common_interface'
import { computed, ComputedRef, inject, InjectionKey, markRaw, provide, reactive, Ref, ref, set, unref } from '@vue/composition-api'
import { MaybeRef, tryOnMounted } from '@vueuse/core'
import { cloneDeep, isUndefined, sortBy, uniq } from 'lodash'
import { IFilterFoldingConfig, IFilterItemData, IFilterValueNext } from '..'
import { DEFAULT_LIMIT_CONTAINER_CONFIG, DEFAULT_NO_LIMIT_COUNT } from '../config'
import {
  IFilterCheckNoopHook,
  IFilterItemAdvanceUiType,
  IFilterItemAggFieldConfigRecord,
  IFilterItemBeforeChangeFoldingHook,
  IFilterItemChangeFoldingHook,
  IFilterItemConfigNext,
  IFilterItemInitDataHook,
  IFilterItemListData,
  IFilterItemListRollupData,
  IFilterItemStateTreeNodeNext,
  IFilterItemUiType,
  IFilterSimpleSearchConfig,
  IFilterSimpleSearchValue,
  IFilterTheme,
  IFilterValueGroupNext,
} from '../type'
import { forkTreeLike, getFieldsFromAggFieldConfigItem, getRealBool, getRealText, walkTreeLike } from '../utils'
import { useFilterSelectService } from './useFilterSelectService'
import { useFilterSimpleSearchService } from './useFilterSimpleSearchService'

export type IUseProvideFilterServiceOptions<IDENTITY extends string, AGG_FIELD extends string> = {
  theme?: MaybeRef<IFilterTheme>
  layout: MaybeRef<Array<IFilterItemConfigNext<IDENTITY, AGG_FIELD>>>
  locale: MaybeRef<I18nLang>
  simpleSearch?: MaybeRef<IFilterSimpleSearchConfig>
  folding?: MaybeRef<IFilterFoldingConfig>
  /** 选中项数量上限 默认 100 */
  selectedLimit?: MaybeRef<number>
  autoInit?: boolean
  /** 点击弹窗关闭按钮时是否移除所有选中项，默认 true */
  removeAllWhenCloseAdvanceDialog?: boolean
  /** 点击弹窗取消按钮时是否移除所有选中项，默认 true */
  removeAllWhenCancelAdvanceDialog?: boolean
  /** 点击弹窗cancel按钮时，是否关闭弹窗，默认 true */
  closeAdvanceDialogWhenCancel?: boolean
  /** 在advance dialog 中展示所有选中的项目，而不是排除真实作为查询条件的项目之后的临时选中项目，默认 false */
  showAllSelectedTemporaryList?: boolean
  /** 在advance dialog 中清除所有的选中项，不判断是否是当次临时选中的，默认true */
  checkDisableBeforeRemoveFromTemporaryList?: boolean
  className?: {
    advanceFilterCls?: string
    filterCls?: string
  }
  hooks: {
    onInitFilterSimpleSearch?: () => IFilterSimpleSearchValue | Promise<IFilterSimpleSearchValue>
    onInitFilterQueryItems?: () => Array<IQueryItem> | Promise<Array<IQueryItem>>
    onInitFilterItemData: IFilterItemInitDataHook<IDENTITY, AGG_FIELD>
    // onSimpleSearchConditionChange?: IUseFilterSimpleSearchService['onConditionChange']
    onBeforeOpenFolding?: IFilterItemBeforeChangeFoldingHook<IDENTITY>
    onOpenFolding?: IFilterItemChangeFoldingHook<IDENTITY>
    onBeforeCloseFolding?: IFilterItemBeforeChangeFoldingHook<IDENTITY>
    onCloseFolding?: IFilterItemChangeFoldingHook<IDENTITY>
    onBeforeExpand?: IFilterCheckNoopHook
    onBeforeCollapse?: IFilterCheckNoopHook
    onBeforeSubmit?: IFilterCheckNoopHook
    onBeforeAdvanceFilterItemSelect?: IFilterItemBeforeChangeFoldingHook<IDENTITY>
    onAfterCloseAdvanceDialog?: () => void | Promise<void>
    onAdvanceSelectFilterItem?: IFilterItemChangeFoldingHook<IDENTITY>
    // 验证选中项是否可选
    onBeforeSelectValue?: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => boolean
    // 当选中项已存在时触发
    onSelectedValueExist?: () => void | Promise<void>
    // 当选中项达到上限时触发
    onSelectedValueLimit?: (limitCount: number) => void | Promise<void>
    onBeforeRemoveGroup?: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>) => boolean | Promise<boolean>
    onBeforeRemoveAllGroup?: () => boolean | Promise<boolean>
    onBeforeRemoveGroupValue?: (
      group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>,
      value: IFilterValueNext<IDENTITY, AGG_FIELD>
    ) => boolean | Promise<boolean>
    onSubmit: (
      queryItems: IQueryItem[],
      type: 'refine' | 'exclude',
      queryValueRecord?: Record<AGG_FIELD, IQueryValue[]>,
      queryTextValue?: IFilterSimpleSearchValue
    ) => void | Promise<void>
  }
}

type IFilterSelectService<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  filterValueGroups: ComputedRef<IFilterValueGroupNext<IDENTITY, AGG_FIELD>[]>
  filterValueSelectedCountRecord: ComputedRef<Record<string, number>>
  filterValueTemporaryList: ComputedRef<IFilterValueNext<IDENTITY, AGG_FIELD>[]>
  filterValueTemporaryListExcludeReal: ComputedRef<IFilterValueNext<IDENTITY, AGG_FIELD>[]>
  filterValueRealList: ComputedRef<IFilterValueNext<IDENTITY, AGG_FIELD>[]>
  checkIsSelected: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => boolean
  checkIsDisabled: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => boolean
  checkFieldIsInGroup: (field: AGG_FIELD) => boolean
  addFilterValueToTemporary: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => void
  removeFilterValueFromTemporary: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => void
  getSubFilterValueRealList: (fields: AGG_FIELD[]) => IFilterValueNext<IDENTITY, AGG_FIELD>[]
  getSubFilterValueRealRecord: (fields: AGG_FIELD[]) => Record<AGG_FIELD, IFilterValueNext<IDENTITY, AGG_FIELD>[]>
  getSubFilterValueTemporaryList: (fields: AGG_FIELD[]) => IFilterValueNext<IDENTITY, AGG_FIELD>[]
  getSubFilterValueTemporaryRecord: (fields: AGG_FIELD[]) => Record<AGG_FIELD, IFilterValueNext<IDENTITY, AGG_FIELD>[]>
  getOriginQueryItems: (exclude?: AGG_FIELD[]) => IQueryItem[]
  removeAllFilterValueFromReal: (fields: AGG_FIELD[]) => void
  removeAllFilterValueFromTemporary: (fields: AGG_FIELD[]) => void
  removeFilterGroup: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>) => Promise<void>
  removeFilterGroupValue: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>, value: IFilterValueNext<IDENTITY, AGG_FIELD>) => Promise<void>
  removeAllFilterGroup: () => Promise<void>
  getFieldLabelByField: (field: AGG_FIELD) => string
  clearAllFilterValueFromTemporary: (checkDisable?: boolean) => void
  showAllSelectedTemporaryList: boolean
  checkDisableBeforeRemoveFromTemporaryList: boolean
}

type IFilterSimpleSearchService = {
  updateKeywords: (keyword: string) => void
  updateCondition: (condition: IFilterSimpleSearchValue['condition']) => void
  clearKeywords: () => void
  data: MaybeRef<IFilterSimpleSearchValue>
  config: MaybeRef<IFilterSimpleSearchConfig>
}

type IFilterService<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  stateRecord: Record<IDENTITY, IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>>
  stateTree: Array<IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>>
  selectedState: MaybeRef<IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>>
  initValueLoading: Ref<boolean>
  initStateLoading: Ref<boolean>
  submitLoading: Ref<boolean>
  initLoading: Ref<boolean>
  selectedLimit?: MaybeRef<number>
  theme: MaybeRef<IFilterTheme>
  openFilterItem: (item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) => Promise<void>
  closeFilterItem: (item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) => Promise<void>
  selectFilterItem: (item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) => Promise<void>
  changeAdvanceUiType: (uiType: IFilterItemAdvanceUiType) => Promise<void>
  checkIsRollupByAdvanceUiType: (data: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>, advanceUiType: IFilterItemAdvanceUiType) => boolean
  submit: (
    action: 'exclude' | 'refine',
    options?: { needSelectedValues?: boolean; needOriginQueryItems?: boolean; needQueryText?: boolean }
  ) => Promise<void>
  hooks: IUseProvideFilterServiceOptions<IDENTITY, AGG_FIELD>['hooks']
  updateFilterItemData: (
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    _meta?: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) => Promise<void>
} & Pick<IUseProvideFilterServiceOptions<IDENTITY, AGG_FIELD>, 'className'>

type IFilterAdvanceDialogService<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  visible: Ref<boolean>
  open: (item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) => Promise<void>
  submit: (action: 'exclude' | 'refine') => Promise<void>
  close: () => void
  cancel: () => void
}

export type IFilterServiceInjection<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  selectService: IFilterSelectService<AGG_FIELD>
  simpleSearchService: IFilterSimpleSearchService
  filterService: IFilterService<IDENTITY, AGG_FIELD>
  advanceDialogService: IFilterAdvanceDialogService
  locale: MaybeRef<I18nLang>
}

const filterServiceInjectionKey: InjectionKey<IFilterServiceInjection> = Symbol('filterServiceInjectionKey')

const DEFAULT_SIMPLE_SEARCH_LOGIC: IFilterSimpleSearchValue['condition'] = 'AND'

export function useProvideFilterService<IDENTITY extends string, AGG_FIELD extends string>(
  options: IUseProvideFilterServiceOptions<IDENTITY, AGG_FIELD>
) {
  const {
    theme = 'synapse',
    layout,
    locale,
    hooks,
    closeAdvanceDialogWhenCancel = true,
    removeAllWhenCloseAdvanceDialog = true,
    removeAllWhenCancelAdvanceDialog = true,
    showAllSelectedTemporaryList = false,
    checkDisableBeforeRemoveFromTemporaryList = true,
    autoInit = true,
    selectedLimit,
    simpleSearch = { visible: false, placeholder: '', showLogicToggle: false },
  } = options

  const {
    onInitFilterItemData,
    onInitFilterQueryItems = () => [],
    onInitFilterSimpleSearch = () => ({ keyword: '', condition: DEFAULT_SIMPLE_SEARCH_LOGIC }),
    onBeforeOpenFolding,
    onOpenFolding,
    onBeforeCloseFolding,
    onCloseFolding,
    onBeforeRemoveGroup,
    onBeforeRemoveGroupValue,
    onBeforeRemoveAllGroup,
    onAfterCloseAdvanceDialog,
    onBeforeAdvanceFilterItemSelect: onBeforeAdvanceSelect,
    onAdvanceSelectFilterItem: onAdvanceSelect,
    onBeforeSelectValue,
  } = hooks

  const filterQueryItems = ref([]) as Ref<Array<IQueryItem>>

  const filterItemAggFieldConfigRecord = reactive({}) as IFilterItemAggFieldConfigRecord<IDENTITY, AGG_FIELD>

  const filterItemStateRecord = reactive({}) as Record<IDENTITY, IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>>

  const filterItemStateTree = reactive([]) as Array<IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>>

  const filterSimpleSearchValue = reactive({ keyword: '', condition: DEFAULT_SIMPLE_SEARCH_LOGIC }) as IFilterSimpleSearchValue

  const filterSelectedIdentity = ref('') as Ref<IDENTITY>

  const filterAdvanceDialogVisible = ref(false)

  const filterSelectedState = computed(() => filterItemStateRecord[unref(filterSelectedIdentity)])

  const filterSelectService = useFilterSelectService<IDENTITY, AGG_FIELD>({
    initQueryItems: filterQueryItems,
    locale,
    onBeforeRemoveGroup,
    onBeforeRemoveGroupValue,
    onBeforeRemoveAllGroup,
    field: filterItemAggFieldConfigRecord,
    onRemoveGroup: async () => {
      await submit('refine', { needSelectedValues: false })
    },
    onRemoveGroupValue: async () => {
      await submit('refine', { needSelectedValues: false })
    },
    onRemoveAllGroup: async () => {
      await submit('refine', { needSelectedValues: false, needOriginQueryItems: false })
    },
    onBeforeAddValue: onBeforeSelectValue,
  })

  const filterSimpleSearchService = useFilterSimpleSearchService({
    data: filterSimpleSearchValue,
    onConditionChange: async (data) => {
      await submit(data.condition === 'AND' ? 'refine' : 'exclude', { needSelectedValues: false, needOriginQueryItems: true, needQueryText: true })
    },
  })

  const filterQueryItemLoading = ref(true)

  const filterStateLoading = ref(true)

  const filterSimpleSearchLoading = ref(true)

  const filterInitLoading = ref(true)

  const filterSubmitLoading = ref(false)

  function closeAdvanceDialog() {
    if (removeAllWhenCloseAdvanceDialog) {
      filterSelectService.clearAllFilterValueFromTemporary()
    }
    filterAdvanceDialogVisible.value = false
    onAfterCloseAdvanceDialog?.()
  }

  async function openAdvanceDialog(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeAdvanceSelect) {
      const rt = await onBeforeAdvanceSelect(item.identity)
      if (!rt) return
    }
    filterAdvanceDialogVisible.value = true
    await updateSelectFilterItem(item)
    onAdvanceSelect?.(item.identity)
  }

  async function openAdvanceDialogByIdentity(identity: IDENTITY) {
    const item = filterItemStateRecord[identity]
    if (item) {
      await openAdvanceDialog(item)
    }
  }

  function cancelAdvanceDialog() {
    if (removeAllWhenCancelAdvanceDialog) {
      filterSelectService.clearAllFilterValueFromTemporary()
    }

    if (closeAdvanceDialogWhenCancel) {
      filterAdvanceDialogVisible.value = false
      onAfterCloseAdvanceDialog?.()
    }
  }

  async function submitAdvanceDialog(action: 'exclude' | 'refine') {
    filterAdvanceDialogVisible.value = false
    onAfterCloseAdvanceDialog?.()
    await submit(action, { needSelectedValues: true, needOriginQueryItems: true })
  }

  async function submit(
    action: 'exclude' | 'refine',
    options?: { needSelectedValues?: boolean; needOriginQueryItems?: boolean; needQueryText?: boolean }
  ) {
    filterSubmitLoading.value = true
    const { needSelectedValues = true, needOriginQueryItems = true, needQueryText = unref(simpleSearch).visible ? true : false } = options || {}
    const queryItems = needOriginQueryItems ? filterSelectService.getOriginQueryItems() : []
    if (needSelectedValues) {
      const selectedFilterValues = cloneDeep(unref(filterSelectService.filterValueTemporaryListExcludeReal))
      const fields = uniq(selectedFilterValues.map((i) => i.field))
      const selectedFilterValueRecord = fields.reduce((record, field) => {
        const values = selectedFilterValues.filter((v) => v.field === field)

        record[field] = values.map((i) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { identity, uuid, field, ...queryValue } = i

          return queryValue
        })

        return record
      }, {} as Record<AGG_FIELD, IQueryValue[]>)
      if (needQueryText) {
        await hooks.onSubmit(queryItems, action, selectedFilterValueRecord, cloneDeep(filterSimpleSearchValue))
      } else {
        await hooks.onSubmit(queryItems, action, selectedFilterValueRecord)
      }
    } else {
      if (needQueryText) {
        await hooks.onSubmit(queryItems, action, undefined, cloneDeep(filterSimpleSearchValue))
      } else {
        await hooks.onSubmit(queryItems, action)
      }
    }
    filterSubmitLoading.value = false
  }

  async function openFilterItem(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeOpenFolding) {
      const rt = await onBeforeOpenFolding(item.identity)
      if (!rt) return
    }
    item._base.isExpand = true
    if (!item._base.isInited) {
      await initFilterItemBaseData(item, {
        rollup: checkIsRollup(item),
        uiType: item._meta.originLayoutConfig.uiAggField.uiType,
        fieldConfig: cloneDeep(item._meta.originAggFieldConfig),
      })
    }
    onOpenFolding?.(item.identity)
  }

  async function closeFilterItem(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeCloseFolding) {
      const rt = await onBeforeCloseFolding(item.identity)
      if (!rt) return
    }

    item._base.isExpand = false
    onCloseFolding?.(item.identity)
  }

  function checkIsRollupByAdvanceUiType(data: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>, advanceUiType: IFilterItemAdvanceUiType) {
    const isAdvanceListUiType = (['advance-full-list-order-count', 'advance-full-list-order-name'] as Array<IFilterItemAdvanceUiType>).includes(
      advanceUiType
    )

    let rollup = false

    if (isAdvanceListUiType) {
      const checkboxRollupData = data.data?.['checkbox-rollup'] as IFilterItemListRollupData | undefined
      rollup = checkboxRollupData?.rollup ?? checkIsRollup(data)
    }

    return rollup
  }

  async function selectFilterItem(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeAdvanceSelect) {
      const rt = await onBeforeAdvanceSelect(item.identity)
      if (!rt) return
    }

    await updateSelectFilterItem(item)

    onAdvanceSelect?.(item.identity)
  }

  async function updateSelectFilterItem(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    const originSelectedState = filterItemStateRecord[unref(filterSelectedIdentity)]

    const targetSelectedState = filterItemStateRecord[item.identity]

    if (!isUndefined(originSelectedState)) {
      originSelectedState._advance.isSelected = false
    }

    if (!isUndefined(targetSelectedState)) {
      targetSelectedState._advance.isSelected = true
      filterSelectedIdentity.value = item.identity
      targetSelectedState._advance.activeUiType = targetSelectedState._advance.uiTypes[0]

      const rollup = checkIsRollupByAdvanceUiType(targetSelectedState, targetSelectedState._advance.uiTypes[0])
      await initFilterItemAdvanceData(item, {
        uiType: targetSelectedState._advance.uiTypes[0],
        rollup: rollup,
        fieldConfig: cloneDeep(item._meta.originAggFieldConfig),
      })
    }
  }

  async function changeAdvanceUiType(uiType: IFilterItemAdvanceUiType) {
    if (!unref(filterSelectedIdentity)) return
    const targetSelectedState = filterItemStateRecord[unref(filterSelectedIdentity)]
    if (!targetSelectedState) return
    targetSelectedState._advance.activeUiType = uiType
    await initFilterItemAdvanceData(targetSelectedState, {
      uiType,
      rollup: checkIsRollupByAdvanceUiType(targetSelectedState, uiType),
      fieldConfig: cloneDeep(targetSelectedState._meta.originAggFieldConfig),
    })
  }

  async function initFilterValue() {
    filterQueryItemLoading.value = true
    filterQueryItems.value = await onInitFilterQueryItems()
    filterQueryItemLoading.value = false
  }

  function initFilterSyncState() {
    walkTreeLike(unref(layout), (item) => {
      const { identity, uiAggField } = item
      const fieldConfig = uiAggField.aggFieldConfig

      set(filterItemAggFieldConfigRecord, identity, cloneDeep(fieldConfig))
    })
  }

  async function initFilterAsyncState() {
    filterStateLoading.value = true

    const needInitDataFilterItems: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>[] = []

    const tree: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>[] = forkTreeLike(unref(layout), (item, depth) => {
      const { identity, uiAggField } = item
      const fieldConfig = uiAggField.aggFieldConfig
      const aggFields = getFieldsFromAggFieldConfigItem(fieldConfig) as AGG_FIELD[]

      const isExpand = isUndefined(item.defaultOpen) ? false : getRealBool(item.defaultOpen, identity, unref(locale), false)
      const labelText = getRealText(item.label, identity, unref(locale))
      const tooltipText = getRealText(item.tooltip || '', identity, unref(locale))
      const isLoading = isExpand

      const meta: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>['_meta'] = {
        originLayoutConfig: item,
        originAggFieldConfig: fieldConfig,
      }

      const advanceUiTypes = (item.uiAggField.advanceUiTypes || []).slice()

      const advanceAutoCompleteType = item.uiAggField.advanceAutoCompleteType

      const treeNode: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD> = {
        _meta: markRaw(meta),
        _base: {
          isInited: false,
          isEmpty: false,
          isExpand,
          isLoading,
        },
        _advance: {
          uiTypes: advanceUiTypes,
          activeUiType: advanceUiTypes[0],
          autocompleteType: advanceAutoCompleteType,
          isSelected: false,
          isLoading: false,
          isEmpty: false,
        },
        aggFields,
        depth,
        hasChildren: !!item.children?.length,
        label: labelText,
        tooltip: tooltipText,
        identity,
      }

      if (isExpand) {
        needInitDataFilterItems.push(treeNode)
      }
      set(filterItemStateRecord, identity, treeNode)

      return treeNode
    })
    filterItemStateTree.splice(0, filterItemStateTree.length, ...tree)

    if (needInitDataFilterItems.length) {
      const promises = needInitDataFilterItems.map((item) =>
        initFilterItemBaseData(item, {
          rollup: checkIsRollup(item),
          uiType: item._meta.originLayoutConfig.uiAggField.uiType,
          fieldConfig: cloneDeep(item._meta.originAggFieldConfig),
        })
      )
      await Promise.all(promises)
    }

    filterStateLoading.value = false
  }

  async function initFilterSimpleSearch() {
    filterSimpleSearchLoading.value = true
    const rt = await onInitFilterSimpleSearch()
    set(filterSimpleSearchValue, 'keyword', rt.keyword)
    set(filterSimpleSearchValue, 'condition', rt.condition)
    filterSimpleSearchLoading.value = false
  }

  async function initFilterData() {
    filterInitLoading.value = true
    initFilterSyncState()
    await Promise.all([initFilterValue(), initFilterSimpleSearch()])
    await initFilterAsyncState()
    filterInitLoading.value = false
  }

  async function resetFilterData() {
    await submit('refine', { needSelectedValues: false, needOriginQueryItems: false, needQueryText: false })
  }

  async function updateFilterItemData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) {
    const rt = await onInitFilterItemData({
      identity: item.identity,
      queryItems: filterSelectService.getOriginQueryItems(),
      locale: unref(locale),
      _meta: _meta,
    })

    if (!rt) return

    const uiType = _meta.uiType

    const advanceUiTypes: IFilterItemAdvanceUiType[] = ['advance-full-list-order-count', 'advance-full-list-order-name', 'advance-full-tree']

    const isAdvanceUiType = advanceUiTypes.includes(uiType as IFilterItemAdvanceUiType)

    if (!isAdvanceUiType) {
      processBaseUiTypeData(item, rt, _meta)
      if (isUndefined(item.data)) {
        set(item, 'data', { [uiType]: rt })
      } else {
        set(item.data, uiType, rt)
      }
      item._base.isEmpty = !rt.data.length
      syncBaseUiAndAdvanceUiFilterItemData(item, _meta, 'base')
    }

    if (isAdvanceUiType) {
      processAdvanceUiTypeData(item, rt, _meta)
      if (isUndefined(item.data)) {
        set(item, 'data', { [uiType]: rt })
      } else {
        set(item.data, uiType, rt)
      }
      item._advance.isEmpty = !rt.data.length
    }
  }

  /**
   * 当base ui 或者 advance ui 的数据更新之后，同步下两者之间的数据
   */
  function syncBaseUiAndAdvanceUiFilterItemData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta'],
    benchmark: 'base' | 'advance'
  ) {
    const dataRecord = item.data

    if (isUndefined(dataRecord)) return

    // 需要同步checkbox-rollup 和 advance-full-list-order-count 和 advance-full-list-order-name 的数据

    const checkboxRollupData = dataRecord['checkbox-rollup']

    const advanceFullListOrderCountData = dataRecord['advance-full-list-order-count']

    const advanceFullListOrderNameData = dataRecord['advance-full-list-order-name']

    if (!isUndefined(checkboxRollupData) && !isUndefined(advanceFullListOrderCountData)) {
      const baseUiData = checkboxRollupData as IFilterItemListRollupData
      const advanceUiData = advanceFullListOrderCountData as IFilterItemListRollupData

      syncRollupData(baseUiData, advanceUiData, benchmark === 'advance' ? 'checkbox-rollup' : 'advance-full-list-order-count')
    }

    if (!isUndefined(checkboxRollupData) && !isUndefined(advanceFullListOrderNameData)) {
      const baseUiData = checkboxRollupData as IFilterItemListRollupData
      const advanceUiData = advanceFullListOrderNameData as IFilterItemListRollupData

      syncRollupData(baseUiData, advanceUiData, benchmark === 'advance' ? 'checkbox-rollup' : 'advance-full-list-order-name')
    }

    function syncRollupData(baseUiData: IFilterItemListRollupData, advanceUiData: IFilterItemListRollupData, uiType: IFilterItemUiType) {
      const baseUiRollup = baseUiData.rollup
      const advanceUiRollup = advanceUiData.rollup

      // 根据 benchmark 同步下 rollup 字段的值
      if (benchmark === 'base') {
        if (baseUiRollup !== advanceUiRollup) {
          advanceUiData.rollup = baseUiRollup
        }
      }

      if (benchmark === 'advance') {
        if (baseUiRollup !== advanceUiRollup) {
          baseUiData.rollup = advanceUiRollup
        }
      }
    }
  }

  function processBaseUiTypeData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    rt: IFilterItemData,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) {
    const uiType = _meta.uiType
    const fieldType = item._meta.originAggFieldConfig.type
    if (uiType === 'checkbox' && fieldType === 'single') {
      const checkboxData = rt as IFilterItemListData
      processCheckboxData(item, checkboxData)
    }

    if (uiType === 'checkbox-rollup' && fieldType === 'switch') {
      const rollupCheckboxData = rt as IFilterItemListRollupData
      processCheckRollupData(item, rollupCheckboxData, _meta?.rollup ?? checkIsRollup(item))
    }
  }

  function processAdvanceUiTypeData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    rt: IFilterItemData,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) {
    const uiType = _meta.uiType
    const fieldType = item._meta.originAggFieldConfig.type
    if (uiType === 'advance-full-list-order-count' && fieldType === 'single') {
      const checkboxData = rt as IFilterItemListData
      processCheckboxData(item, checkboxData)
      checkboxData.data = [...checkboxData.data].sort((a, b) => b.count - a.count)
      checkboxData.limitContainer = {
        limitCount: DEFAULT_NO_LIMIT_COUNT,
        supportCollapse: false,
      }
    }

    if (uiType === 'advance-full-list-order-name' && fieldType === 'single') {
      const checkboxData = rt as IFilterItemListData
      processCheckboxData(item, checkboxData)
      checkboxData.data = sortBy(checkboxData.data, (item) => (unref(locale) === 'cn' ? item.display_name_cn : item.display_name_en))
      checkboxData.limitContainer = {
        limitCount: DEFAULT_NO_LIMIT_COUNT,
        supportCollapse: false,
      }
    }

    if (uiType === 'advance-full-list-order-count' && fieldType === 'switch') {
      const rollupCheckboxData = rt as IFilterItemListRollupData
      processCheckRollupData(item, rollupCheckboxData, _meta?.rollup ?? checkIsRollup(item))
      rollupCheckboxData.data = [...rollupCheckboxData.data].sort((a, b) => b.count - a.count)
      rollupCheckboxData.limitContainer = {
        limitCount: DEFAULT_NO_LIMIT_COUNT,
        supportCollapse: false,
      }
    }

    if (uiType === 'advance-full-list-order-name' && fieldType === 'switch') {
      const rollupCheckboxData = rt as IFilterItemListRollupData
      processCheckRollupData(item, rollupCheckboxData, _meta?.rollup ?? checkIsRollup(item))
      rollupCheckboxData.data = sortBy(rollupCheckboxData.data, (item) => (unref(locale) === 'cn' ? item.display_name_cn : item.display_name_en))
      rollupCheckboxData.limitContainer = {
        limitCount: DEFAULT_NO_LIMIT_COUNT,
        supportCollapse: false,
      }
    }
  }

  function processCheckboxData(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>, data: IFilterItemListData) {
    const uiType = item._meta.originLayoutConfig.uiAggField.uiType
    const fieldType = item._meta.originAggFieldConfig.type
    if (uiType === 'checkbox' && fieldType === 'single') {
      const limitContainerConfig = Object.assign({}, DEFAULT_LIMIT_CONTAINER_CONFIG, data.limitContainer)
      data.limitContainer = limitContainerConfig
    }
  }

  function processCheckRollupData(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>, data: IFilterItemListRollupData, isRollup: boolean) {
    const uiType = item._meta.originLayoutConfig.uiAggField.uiType
    const fieldType = item._meta.originAggFieldConfig.type
    if (uiType === 'checkbox-rollup' && fieldType === 'switch') {
      if (isUndefined(data.rollup)) {
        data.rollup = isRollup
      }
      const limitContainer = Object.assign({}, DEFAULT_LIMIT_CONTAINER_CONFIG, data.limitContainer)
      data.limitContainer = limitContainer
    }
  }

  async function initFilterItemBaseData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) {
    item._base.isLoading = true
    await updateFilterItemData(item, _meta)
    item._base.isInited = true
    item._base.isLoading = false
  }

  async function initFilterItemAdvanceData(
    item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>,
    _meta: Parameters<IFilterItemInitDataHook<IDENTITY, AGG_FIELD>>[0]['_meta']
  ) {
    item._advance.isLoading = true
    await updateFilterItemData(item, _meta)
    item._advance.isLoading = false
  }

  function checkIsRollup(item: IFilterItemStateTreeNodeNext<IDENTITY, AGG_FIELD>) {
    const uiType = item._meta.originLayoutConfig.uiAggField.uiType
    const fieldType = item._meta.originAggFieldConfig.type
    if (uiType === 'checkbox-rollup' && fieldType === 'switch') {
      const selectedFilterValue = filterSelectService.getSubFilterValueRealRecord(item.aggFields)
      const [noRollupField, rollupField] = item._meta.originAggFieldConfig.fields
      const hasNoRollupSelectedValues = !!selectedFilterValue[noRollupField.field] && selectedFilterValue[noRollupField.field].length > 0
      const hasRollupSelectedValues = !!selectedFilterValue[rollupField.field] && selectedFilterValue[rollupField.field].length > 0

      if (hasNoRollupSelectedValues && hasRollupSelectedValues) {
        return false
      }

      if (!hasNoRollupSelectedValues && hasRollupSelectedValues) {
        return true
      }

      return item._meta.originAggFieldConfig.defaultRollup ?? true
    }

    return false
  }

  provide(filterServiceInjectionKey, {
    selectService: {
      filterValueGroups: filterSelectService.filterValueGroups,
      filterValueTemporaryList: filterSelectService.filterValueTemporaryList,
      filterValueTemporaryListExcludeReal: filterSelectService.filterValueTemporaryListExcludeReal,
      addFilterValueToTemporary: filterSelectService.addFilterValueToTemporary,
      removeFilterValueFromTemporary: filterSelectService.removeFilterValueFromTemporary,
      getSubFilterValueRealList: filterSelectService.getSubFilterValueRealList,
      getSubFilterValueRealRecord: filterSelectService.getSubFilterValueRealRecord,
      getSubFilterValueTemporaryList: filterSelectService.getSubFilterValueTemporaryList,
      getSubFilterValueTemporaryRecord: filterSelectService.getSubFilterValueTemporaryRecord,
      checkFieldIsInGroup: filterSelectService.checkFieldIsInGroup,
      getOriginQueryItems: filterSelectService.getOriginQueryItems,
      removeAllFilterValueFromReal: filterSelectService.removeAllFilterValueFromReal,
      removeAllFilterValueFromTemporary: filterSelectService.removeAllFilterValueFromTemporary,
      removeFilterGroup: filterSelectService.removeFilterGroup,
      removeFilterGroupValue: filterSelectService.removeFilterGroupValue,
      removeAllFilterGroup: filterSelectService.removeAllFilterGroup,
      getFieldLabelByField: filterSelectService.getFieldLabelByField,
      filterValueRealList: filterSelectService.filterValueList,
      filterValueSelectedCountRecord: filterSelectService.filterValueSelectedCountRecord,
      clearAllFilterValueFromTemporary: filterSelectService.clearAllFilterValueFromTemporary,
      checkIsDisabled: filterSelectService.checkIsDisabled,
      checkIsSelected: filterSelectService.checkIsSelected,
      showAllSelectedTemporaryList,
      checkDisableBeforeRemoveFromTemporaryList,
    },
    simpleSearchService: {
      ...filterSimpleSearchService,
      config: simpleSearch,
    },
    filterService: {
      stateRecord: filterItemStateRecord,
      stateTree: filterItemStateTree,
      selectedState: filterSelectedState,
      initValueLoading: filterQueryItemLoading,
      initStateLoading: filterStateLoading,
      submitLoading: filterSubmitLoading,
      initLoading: filterInitLoading,
      updateFilterItemData: updateFilterItemData,
      selectFilterItem: selectFilterItem,
      changeAdvanceUiType: changeAdvanceUiType,
      checkIsRollupByAdvanceUiType: checkIsRollupByAdvanceUiType,
      advanceDialogVisible: filterAdvanceDialogVisible,
      openFilterItem,
      closeFilterItem,
      hooks,
      submit: submit,
      selectedLimit,
      theme,
      className: options.className,
    },
    advanceDialogService: {
      visible: filterAdvanceDialogVisible,
      open: openAdvanceDialog,
      close: closeAdvanceDialog,
      cancel: cancelAdvanceDialog,
      submit: submitAdvanceDialog,
    },
    locale,
  } as unknown as IFilterServiceInjection)

  tryOnMounted(() => {
    if (autoInit) {
      initFilterData()
    }
  })

  return {
    initFilterData: !autoInit ? initFilterData : async () => undefined,
    resetFilterData,
    openAdvanceDialogByIdentity,
  }
}

export function useInjectFilterService() {
  return inject(filterServiceInjectionKey)
}
