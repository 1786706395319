import type { IAggregationSingleResult } from '@patsnap/synapse_common_interface'
import { defineComponent, PropType } from '@vue/composition-api'
import { orderBy } from 'lodash'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { checkAggDataIsEmpty } from '../../../utils'
import $classes from './PatentCliffTooltip.module.scss'

export const PatentCliffTooltip = defineComponent({
  name: 'PatentCliffTooltip',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    count: {
      type: Number,
    },
    year: {
      required: true,
      type: String,
    },
    aggregation_result: {
      type: Array as PropType<IAggregationSingleResult[]>,
    },
  },
  setup(props) {
    return {}
  },
  methods: {
    renderExpiringPatents() {
      return [
        <div class={[$classes.tooltipTitle, 'mt-2']}>{this.$t('patentCliff.title')}</div>,
        <div class={$classes.tooltipValue}>
          {this.$t('patentCliff.expiringPatents')}：
          {this.$scopedSlots.expiringCount && this.$scopedSlots.expiringCount({ year: this.year, count: this.count })}
        </div>,
      ]
    },
    renderRelatedDrug() {
      if (this.aggregation_result && !checkAggDataIsEmpty({ aggregation_result: this.aggregation_result })) {
        const items = orderBy(this.aggregation_result[0].items, ['display_name_en'], ['asc'])
        return (
          <div>
            <div class={[$classes.tooltipTitle, 'mt-5']}>
              {this.$t('common.drug')}（{this.$t('patentCliff.expiringKeyPatents')}）
            </div>
            {items.map((item) => this.$scopedSlots.relatedDrug && this.$scopedSlots.relatedDrug({ year: this.year, drug: item }))}
          </div>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div class={$classes.tooltipContainer}>
        <div class={$classes.tooltipTitle}>{this.year}</div>
        {this.renderExpiringPatents()}
        {this.renderRelatedDrug()}
      </div>
    )
  },
})
