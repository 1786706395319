import '@patsnap-ui/icon/assets/solid/ChartChunk.svg'
import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import '@patsnap-ui/icon/assets/solid/DropdownClose.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import '@patsnap-ui/icon/assets/solid/edit.svg'
import '@patsnap-ui/icon/assets/solid/SearchLeft.svg'
import { IQueryDataType, IQueryGroupType, ISearchParams, ISort } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { useLs360ChatStore } from '@pharmsnap/pharmsnap-web/composable/useLs360ChatStore'
import { CommonListTabSetting } from '@pharmsnap/pharmsnap-web/features/commonlist-tab-setting'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useUpgrade } from '@pharmsnap/pharmsnap-web/views/Upgrade/hook/useUpgrade'
import example from '@pharmsnap/shared/assets/img/example.gif'
import { useAuthStore, useClinicalResultService, usePatentService } from '@pharmsnap/shared/composition'
import { useLoadingService } from '@pharmsnap/shared/composition/useLoadingService'
import { ElPopover, ElTabPane, ElTabs } from '@pharmsnap/shared/element-ui'
import { removeHistoryQueries } from '@pharmsnap/shared/src/utils'
import { E_COMMON_LIST_MODE } from '@pharmsnap/shared/types'
import { IGroupSearchParams, IQueryMustFilter, ISearchCollapse } from '@pharmsnap/shared/types/search'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { cloneDeep, includes, keyBy } from 'lodash'
import { Icon } from 'patsnap-biz'
import { NormalizedScopedSlot } from 'vue/types/vnode'
import '../../../assets/icon-svg/table.svg'
import { useLocale } from '../../../composition/useLocale'
import { useQueryService } from '../../../composition/useQueryService'
import { BBetaTag, BCommonList } from '../../index'
import { GHeaderDrawerLayout } from '../../ui/GHeaderDrawerLayout/GHeaderDrawerLayout'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BClinicalTrialAnalysisView, BDrugAnalysisView, BDrugDealAnalysisView, BTranslationalMedicineAnalysisView } from '../BAnalysisView'
import { userProvideCommonListService } from '../BCommonList/service'
import { ICommonListConfig } from '../BCommonList/types'
import { BFilter } from '../BFilter/BFilter'
import { userProvideFilterService } from '../BFilter/BFilterService'
import { IFilterConfig } from '../BFilter/types'
import { useKgEntrance } from '../BKgEntrance'
import { BPatentAnalysis } from '../BPatentAnalysis/BPatentAnalysis'
import $style from './BFilterCommonList.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export interface IBFilterCommonListTabItem {
  i18nKey: string
  icon: string
  name: string
  onClick?: (tabIns: { _props: { name: string } }) => void
}
export const BFilterCommonList = defineComponent({
  name: 'BFilterCommonList',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    collapse: {
      type: Object as PropType<ISearchCollapse>,
    },
    orgRollup: {
      type: Boolean,
      default: false,
    },
    searchQueryType: {
      type: String as PropType<ISearchParams['query_type']>,
    },
    structureQuery: {
      type: Object as PropType<ISearchParams['structure_query']>,
    },
    queryType: {
      required: true,
      type: String as PropType<IQueryDataType>,
    },
    query: {
      required: true,
      type: Object as PropType<IQueryMustFilter>,
    },
    queryId: {
      type: String,
      default: '',
    },
    /**
     * 过滤项配置
     */
    filterConfigs: {
      required: true,
      type: Object as PropType<IFilterConfig>,
    },
    /**
     * 列表配置
     */
    commonListConfig: {
      required: true,
      type: Object as PropType<ICommonListConfig>,
    },
    tabs: {
      type: Array as PropType<Array<IBFilterCommonListTabItem>>,
      default: () => [],
    },
    isNotReadStore: {
      default: false,
    },
    isShowBeta: {
      type: Boolean,
      default: false,
    },
    hasEmailAlert: {
      type: Boolean,
      default: false,
    },
    querySort: {
      type: Array as PropType<ISort[]>,
    },
    querySortOptions: {
      type: Array as PropType<
        {
          field: string
          i18nKey: string
        }[]
      >,
    },
    querySortCountLimit: {
      type: Number,
    },
    shouldHidePagination: {
      type: Boolean,
      default: false,
    },
    initPaginationSize: {
      type: Number,
    },
    collapseFieldOptions: {
      type: Array as PropType<Array<{ value: string; label: string }>>,
    },
  },
  setup(props, ctx) {
    const BCommonListRef = ref(null) as any
    const BFilterRef = ref(null) as any
    const expanded = ref(true)
    const { locale } = useLocale()
    const {
      getters: { isFreeUser, translation },
      actions,
    } = useAuthStore()
    const { onViewPlans } = useUpgrade()

    const { queryType } = toRefs(props)
    const groupByValue = ref<IQueryGroupType | undefined>(props.commonListConfig.defaultGroup)
    const groupConfigMap = computed(() => {
      if (props.commonListConfig.groupByOptions) {
        return keyBy(props.commonListConfig.groupByOptions, (item) => item.key)
      }
      return {}
    })
    const isShowGroupByPopover = ref(false)
    const currentTab = computed(() => {
      const tab = ctx.root.$route.query.tab
      if (tab) {
        if (props.tabs.find((item) => item.name === tab)) return tab
        // 列表页不存在该tab，去除链接url中tab参数
        removeHistoryQueries(['tab'])
      }
      return props.tabs[0].name
    })
    const commonListService = userProvideCommonListService(props.initPaginationSize)
    const filterService = userProvideFilterService()
    const queryIdRef = computed(() => props.queryId)
    const queryRef = computed(() => props.query)
    const collapseRef = computed(() => props.collapse)
    const orgRollupRef = computed(() => props.orgRollup)
    const queryService = useQueryService(
      queryIdRef,
      props.queryType,
      queryRef,
      collapseRef,
      props.querySort || props.commonListConfig.sortConfig?.defaultSort,
      orgRollupRef,
      computed(() => props.searchQueryType),
      computed(() => props.structureQuery)
    )
    const loadingService = useLoadingService()
    const patentService = usePatentService({ queryService })
    const clinicalResultService = useClinicalResultService({ queryService })

    const {
      handleEnterKgByQuery,
      isDisable: kgDisabled,
      checkKgDisabled,
    } = useKgEntrance({
      state: queryService.state,
      commonListServiceState: commonListService.state,
      type: groupByValue.value || queryType.value,
    })

    watch(currentTab, () => {
      if (commonListService.state.loading) return

      commonListService.actions.setLoading(true)
      setTimeout(() => {
        commonListService.actions.setLoading(false)
      }, 100)
    })

    watch(
      () => [props.querySort, props.commonListConfig.sortConfig?.defaultSort],
      () => {
        queryService.actions.setSort(props.querySort || props.commonListConfig.sortConfig?.defaultSort || [])
      }
    )

    const resolveUrl = (id: string) => {
      ctx.emit('resolveUrl', id)
    }
    init()
    function init() {
      filterService.actions.registerRefineFn(async (isExclude: boolean) => {
        BFilterRef.value.syncFilterValue2QueryServiceValue(isExclude)
        ctx.emit('filterRefine', {
          query: queryService.state.query,
          collapse: queryService.state.collapse,
          orgRollup: queryService.state.orgRollup,
        })
      })
    }

    function onClickGroup(group: IQueryGroupType, orgRollUp?: boolean, newTab?: boolean) {
      if (groupByValue.value === group) return
      if (isFreeUser.value && !includes(['drug', 'dev_status', 'drug_deal'], group)) {
        actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_GROUP_BY, trigger_point: `GROUP_BY_${group.toLocaleUpperCase()}` })
        return
      }
      if (!newTab) {
        groupByValue.value = group
      }
      ctx.emit('changGroupBy', group, orgRollUp, newTab)
    }

    function onClickSubTotal() {
      if (loadingService.state.sub_total) {
        ctx.emit('clickSubTotal')
      }
    }
    function getSearchParams(): ISearchParams {
      return {
        data_type: queryService.state.data_type,
        group_by: props.commonListConfig.groupBy,
        query: {
          type: 'group',
          ...queryService.state.query,
        },
        ...(queryService.state.collapse ? { collapse: queryService.state.collapse } : undefined),
        limit: commonListService.state.limit,
        sort: queryService.state.sort,
        offset: (commonListService.state.page - 1) * commonListService.state.limit,
        ...(props.searchQueryType === 'STRUCTURE'
          ? {
              query_type: props.searchQueryType,
              structure_query: cloneDeep(props.structureQuery),
            }
          : undefined),
      }
    }
    async function getTotalFn() {
      if (!props.commonListConfig.getTotalFn) {
        console.log(`IBFilterCommonListTabItem: 列表配置缺少获取数据总条数方法"getTotalFn",请检查!`)
        return {
          total: 0,
        }
      }
      const rt = await props.commonListConfig.getTotalFn({ ...getSearchParams(), limit: 0, offset: 0 })
      return rt
    }

    function onShowSetting() {
      ctx.emit('showSetting')
    }

    function closeFreeUpgradeInfo() {
      filterService.actions.setFreeUpgradeShow(false)
    }

    function handleUpgrade(triggerPoint: string) {
      closeFreeUpgradeInfo()
      onViewPlans(triggerPoint)
    }

    const getSearchParamsInCommonList = (): ISearchParams | IGroupSearchParams => {
      let orgRollUp: boolean | undefined = undefined

      let queryType: ISearchParams['query_type'] | undefined = undefined

      let structureQuery: ISearchParams['structure_query'] | undefined = undefined

      if (props.commonListConfig.groupBy && props.commonListConfig.supportOrgRollup) {
        orgRollUp = queryService.state.orgRollup
      }

      if (props.searchQueryType === 'STRUCTURE' && props.structureQuery) {
        queryType = props.searchQueryType
        structureQuery = cloneDeep(props.structureQuery)
      }

      return {
        data_type: queryService.state.data_type,
        group_by: props.commonListConfig.groupBy,
        org_roll_up: orgRollUp,
        query: {
          type: 'group',
          ...queryService.state.query,
        },
        ...(queryService.state.collapse ? { collapse: queryService.state.collapse } : undefined),
        ...(commonListService.state.cardViewType ? { view_type: commonListService.state.cardViewType } : undefined),
        limit: commonListService.state.limit,
        sort: queryService.state.sort,
        offset: (commonListService.state.page - 1) * commonListService.state.limit,
        ...(props.commonListConfig.canTranslate ? { translation: translation.value || '' } : {}),
        ...(props.commonListConfig.querySelectFields ? { select: props.commonListConfig.querySelectFields } : undefined),
        ...(queryType ? { query_type: queryType } : {}),
        ...(structureQuery ? { structure_query: structureQuery } : {}),
      }
    }
    const {
      actions: { setPageContext },
    } = useLs360ChatStore()

    if (props.commonListConfig.copilotEnabled) {
      watch(
        [queryService.state, loadingService.state],
        () => {
          const query = getSearchParamsInCommonList()
          const analysisTabRouteParams = {
            name: ctx.root.$route.name as string,
            params: ctx.root.$route.params,
            query: {
              ...ctx.root.$route.query,
              tab: 'analysis',
            },
          }
          setPageContext(
            {
              query,
              query_string:
                queryService.state.searchQueryType === 'STRUCTURE' ? queryService.state.queryStructureText : queryService.state.queryMustText,
              refine: queryService.state.queryFilterText,
              ...('collapse' in query && query.collapse?.field && props.collapseFieldOptions
                ? { group_by: props.collapseFieldOptions?.find((item) => item.value === query.collapse?.field)?.label }
                : {}),
            },
            {
              callback: () => {
                if (currentTab.value === 'analysis') return
                ctx.root.$router.replace(analysisTabRouteParams)
              },
              page_url: window.location.origin + ctx.root.$router.resolve(analysisTabRouteParams).href,
            }
          )
        },
        {
          immediate: true,
          deep: true,
        }
      )
    }
    return {
      locale,
      getTotalFn,
      onShowSetting,
      BCommonListRef,
      BFilterRef,
      queryService,
      commonListService,
      filterService,
      expanded,
      currentTab,
      groupByValue,
      groupConfigMap,
      isShowGroupByPopover,
      loadingService,
      onClickGroup,
      resolveUrl,
      closeFreeUpgradeInfo,
      handleUpgrade,
      onClickSubTotal,
      kgDisabled,
      checkKgDisabled,
      handleEnterKgByQuery,
      patentService,
      clinicalResultService,
      getSearchParamsInCommonList,
    }
  },
  methods: {
    renderKgEntrance(kgSlot?: NormalizedScopedSlot, showText = true) {
      return kgSlot?.({
        disabled: this.kgDisabled,
        handleEnter: this.handleEnterKgByQuery,
        showText,
      })
    },
    renderTabs() {
      if (this.tabs.length <= 1) {
        return null
      }
      return (
        <div class={['self-end', $style.tabContainer]}>
          <ElTabs
            class={$style.tab}
            {...{
              props: {
                value: this.currentTab,
              },
              on: {
                'tab-click': (tabIns: any) => {
                  if (tabIns.name === this.currentTab) return
                  CommonListTabSetting.getSingleton().setTabType(this.queryType, tabIns.name)
                  this.$router.replace({
                    name: this.$route.name as string,
                    params: this.$route.params,
                    query: {
                      ...this.$route.query,
                      tab: tabIns.name,
                    },
                  })
                },
              },
            }}
          >
            {this.tabs.map((item) => {
              return (
                <ElTabPane name={item.name}>
                  <span slot="label" data-testid={`b-filter-common-list__tab--${item.name}`}>
                    <Icon class="text-xl mr-1 text-gray-450" icon={item.icon}></Icon>
                    <span>{this.$tc(item.i18nKey)}</span>
                  </span>
                </ElTabPane>
              )
            })}
          </ElTabs>
        </div>
      )
    },
    renderContent() {
      // filter组件 没有渲染好,不渲染主体内容,
      // filter组件会往query里面注入过滤条件,所以必须要求filter提前注入好条件
      if (!this.filterService.state.inited) {
        return null
      }
      if (['table', 'list'].indexOf(this.currentTab as string) !== -1) {
        return (
          <BCommonList
            class="flex-1"
            ref="BCommonListRef"
            mode={this.currentTab === 'list' ? E_COMMON_LIST_MODE.CARD : E_COMMON_LIST_MODE.TABLE}
            config={this.commonListConfig}
            service={this.commonListService}
            querySortCountLimit={this.querySortCountLimit}
            queryService={this.queryService}
            querySortOptions={this.querySortOptions}
            groupByValue={this.groupByValue}
            hasEmailAlert={this.hasEmailAlert}
            loadingService={this.loadingService}
            patentService={this.patentService}
            shouldHidePagination={this.shouldHidePagination}
            getSearchParams={this.getSearchParamsInCommonList}
            filterExpand={this.expanded}
            scopedSlots={{
              analytics: this.$scopedSlots.analytics,
              paginationSlot: this.$scopedSlots.paginationSlot,
              kgEntrance: (showText: boolean) => this.renderKgEntrance(this.$scopedSlots.kgEntrance, showText),
            }}
            {...{
              on: {
                beforeSearch: this.checkKgDisabled,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                afterSearch: (data: any) => this.$emit('afterSearch', data),
                showSetting: this.onShowSetting,
              },
            }}
          >
            {this.$scopedSlots.customLimitContent ? (
              <template slot="customLimitContent">{this.$scopedSlots.customLimitContent({ loadingService: this.loadingService })}</template>
            ) : null}
            {this.$scopedSlots.customTableEmpty ? (
              <template slot="customTableEmpty">{this.$scopedSlots.customTableEmpty({ patentService: this.patentService })}</template>
            ) : null}
          </BCommonList>
        )
      }
      if (this.currentTab === 'analysis') {
        if (this.queryType === 'patent') {
          return (
            <BPatentAnalysis
              onResolveUrl={this.resolveUrl}
              queryService={this.queryService}
              getTotalFn={this.getTotalFn}
              loadingService={this.loadingService}
            ></BPatentAnalysis>
          )
        }
        if (this.queryType === 'clinical_trial') {
          return (
            <BClinicalTrialAnalysisView
              queryService={this.queryService}
              getTotalFn={this.getTotalFn}
              loadingService={this.loadingService}
            ></BClinicalTrialAnalysisView>
          )
        }
        if (this.queryType === 'drug_deal') {
          return (
            <BDrugDealAnalysisView
              queryService={this.queryService}
              getTotalFn={this.getTotalFn}
              loadingService={this.loadingService}
            ></BDrugDealAnalysisView>
          )
        }
        if (this.queryType === 'drug') {
          return (
            <BDrugAnalysisView
              queryService={this.queryService}
              getTotalFn={this.getTotalFn}
              loadingService={this.loadingService}
              onGroupByChange={this.onClickGroup}
            ></BDrugAnalysisView>
          )
        }
        if (this.queryType === 'translational_medicine') {
          return (
            <BTranslationalMedicineAnalysisView
              queryService={this.queryService}
              getTotalFn={this.getTotalFn}
              loadingService={this.loadingService}
            ></BTranslationalMedicineAnalysisView>
          )
        }
      }
      return null
    },
    renderGroup() {
      if (!this.commonListConfig.groupByOptions?.length || !this.groupByValue) {
        return null
      }
      const content = (
        <div class={['inline-flex items-center rounded hover:bg-gray-30 p-1 text-sm cursor-pointer']}>
          <span>{this.$tc(this.groupConfigMap[this.groupByValue].i18nKey)}</span>
          <GIcon size={20} class="ml-1" useSvgDefaultColor svgName={this.isShowGroupByPopover ? 'SolidDropdownClose' : 'SolidDropdownOpen'}></GIcon>
        </div>
      )
      return [
        <span class={$style.splitLine}></span>,
        <span class={['inline-flex items-center']}>
          <span class="mr-2 text-sm">{this.$tc('BFilterCommonList.groupedBy')}:</span>
          <ElPopover
            data-testid="b-filter-common-list__group"
            v-model={this.isShowGroupByPopover}
            placement="bottom-start"
            disabled={this.isDisableGroup}
          >
            <template slot="reference">{content}</template>
            <div>
              {this.commonListConfig.groupByOptions?.map((item) => {
                const isCurrent = item.key === this.groupByValue
                const isDisabled = this.commonListConfig.groupByDisabled
                  ? this.commonListConfig.groupByDisabled(
                      item,
                      this.commonListConfig.groupBy === 'dev_status' ? this.loadingService.state.sub_total : this.loadingService.state.total
                    )
                  : false

                return (
                  <div
                    class={[
                      'p-1 hover:bg-gray-20 rounded',
                      isDisabled
                        ? 'text-gray-60 cursor-not-allowed'
                        : isCurrent
                        ? 'text-blue-default cursor-pointer'
                        : 'text-black-default cursor-pointer',
                    ]}
                    key={item.key}
                    value={item.key}
                    data-testid={`b-filter-common-list__group-item--${item.key}`}
                    onClick={() => !isDisabled && this.onClickGroup(item.key)}
                  >
                    {this.$tc(item.i18nKey)}
                  </div>
                )
              })}
            </div>
          </ElPopover>
          {this.commonListConfig.groupByTipContent ? (
            <GTooltip
              iconSize={20}
              placement="right"
              theme="light"
              mode="icon"
              content={this.commonListConfig.groupByTipContent(this.locale)}
            ></GTooltip>
          ) : null}
        </span>,
      ]
    },
    renderCountTooltip() {
      if (!this.commonListConfig.getCountTooltipInnerHTMLFn) {
        return null
      }
      return (
        <GTooltip theme="light" mode="icon">
          <div slot="content" class="max-w-xs" domPropsInnerHTML={this.commonListConfig.getCountTooltipInnerHTMLFn(this.locale)}></div>
        </GTooltip>
      )
    },
    renderFreeUpgradeInfo() {
      return this.filterService.state.freeUpgradeShow ? (
        <div class={$style.freeUpgradeContainer}>
          <div class={$style.top}>
            <div class={$style.header}>
              <span>{this.$t('BFilterCommonList.advanceRefine')}</span>
              <div onclick={this.closeFreeUpgradeInfo}>
                <GIcon color="#8993A4" svgName="SolidCloseBig" size={24} class={['cursor-pointer text-white-default']}></GIcon>
              </div>
            </div>
            <div>
              <img src={example} alt="" />
            </div>
          </div>
          <div class={$style.bottom}>
            <div class={$style.title}>{this.$t('BFilterCommonList.upgradeTitle')}</div>
            <div class={$style.desc}>{this.$t('BFilterCommonList.upgradeDesc')}</div>
            <button class="pt-ui-btn" type="button" data-type="submit" with-icon onClick={() => this.handleUpgrade('ADVANCE_FILTER')}>
              <GIcon style="filter: brightness(0) invert(1);" svgName="Upgrade" size={16} class="w-5 h-5 mr-1.5"></GIcon>
              <span class="text-sm">{this.$t('common.upgradeNow')}</span>
            </button>
          </div>
        </div>
      ) : null
    },
  },
  render() {
    return (
      <GHeaderDrawerLayout v-model={this.expanded} maxWidth={300} minWidth={36} showSide={!this.hideSide}>
        <template slot="header">{this.$scopedSlots.header && this.$scopedSlots.header({ patentService: this.patentService })}</template>
        <template slot="default">
          <div class="h-full flex flex-col" data-testid="b-filter-common-list">
            <div class="h-10 border-b flex items-center px-5">
              {this.loadingService.state.loading ? (
                <div class={[$style.loadingText, 'flex-1 font-bold']}>{this.$tc('BFilterCommonList.loading')}</div>
              ) : (
                <div class="flex-1 flex items-center">
                  <span class="flex items-center">
                    {this.$scopedSlots.collapse
                      ? this.$scopedSlots.collapse({
                          total: this.loadingService.state.total,
                          unCollapsedTotal: this.loadingService.state.uncollapse_total,
                          patentService: this.patentService,
                        })
                      : [
                          <span class="text-xl font-medium leading-6 mr-1" data-testid="b-filter-common-list__total">
                            {toThousands(this.loadingService.state.total)}
                          </span>,
                          <span class="text-sm">{this.$tc(this.commonListConfig.resultCountI18nKey)}</span>,
                          this.commonListConfig.subResultCountI18nKey ? (
                            <span class="text-sm">
                              {this.locale === 'cn' ? '，' : ', '}
                              <span
                                onClick={this.onClickSubTotal}
                                data-testid="b-filter-common-list__sub-total"
                                class={['text-blue-default underline', this.loadingService.state.sub_total ? 'cursor-pointer' : 'cursor-not-allowed']}
                              >
                                {this.$t(this.commonListConfig.subResultCountI18nKey, {
                                  total: toThousands(this.loadingService.state.sub_total),
                                })}
                              </span>
                            </span>
                          ) : null,
                          this.commonListConfig.showNewTag ? (
                            <span class="px-2 h-5 inline-flex items-center rounded text-white-default text-xs bg-red-default ml-2">New</span>
                          ) : null,
                        ]}
                    {this.renderCountTooltip()}
                  </span>
                  {this.renderGroup()}
                  {this.isShowBeta && <BBetaTag class="ml-1"></BBetaTag>}
                </div>
              )}
              {this.renderTabs()}
              {this.renderKgEntrance(this.$scopedSlots.groupKgEntrance)}
            </div>
            <div class={['flex-1 overflow-hidden', $style.content]}>{this.renderContent()}</div>
            {this.$scopedSlots.customTableLimitContent && this.currentTab === 'table'
              ? this.$scopedSlots.customTableLimitContent({ loadingService: this.loadingService })
              : null}
            {this.renderFreeUpgradeInfo()}
          </div>
        </template>
        <template slot="drawer">
          <BFilter
            ref="BFilterRef"
            isNotReadStore={this.isNotReadStore}
            config={this.filterConfigs}
            service={this.filterService}
            queryService={this.queryService}
          >
            <template slot="header">{this.$scopedSlots.filterHeader ? this.$scopedSlots.filterHeader({}) : null}</template>
            <template slot="viewManual">
              {this.$scopedSlots.viewManual
                ? this.$scopedSlots.viewManual({
                    patentService: this.patentService,
                    clinicalResultService: this.clinicalResultService,
                  })
                : null}
            </template>
          </BFilter>
        </template>
      </GHeaderDrawerLayout>
    )
  },
})
