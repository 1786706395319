import type { ITwoDimensionAggData } from '@patsnap/synapse_common_interface'
import { BasicBarChart, BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { PHASE_APPROVED_COLOR, PHASE_CONTINUE_COLOR_LIST } from '@pharmsnap/shared/config'
import { ILang, IQueryDataEntityType } from '@pharmsnap/shared/types'
import { IUseChartTwoDimTupleItem, IUsePhaseHorChartDataItem } from '@pharmsnap/shared/types/component'
import { getEntityTypeByAggField, getPhaseColor, isInactivePhase, replaceSameNameByIncreaseCount } from '@pharmsnap/shared/utils'
import { Ref, computed, getCurrentInstance, h, onBeforeUnmount, reactive, ref, watch } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { isNumber, isObject, isUndefined } from 'lodash'
import Vue from 'vue'
import { BStackBarTooltip } from '../components'
import { useChart } from '../composition'
import { useChartEntityTooltip } from './useChartEntityTooltip'

export function getDefaultPhaseHorBarChartData(): IUsePhaseHorChartDataItem {
  return {
    category: '',
    data: undefined,
    Inactive: null,
    Unknown: null,
    Pending: null,
    Discontinued: null,
    Suspended: null,
    Withdrawn: null,
    Preclinical: null,
    'IND Application': null,
    'IND Approval': null,
    Clinical: null,
    'Early Phase 1': null,
    'Phase 1': null,
    'Phase 1/2': null,
    'Phase 2': null,
    'Phase 2/3': null,
    'Phase 3': null,
    'NDA/BLA': null,
    Approved: null,
    'Phase 4': null,
    'Not Applicable': null,
    Discovery: null,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformPhaseStackBarEventParam2Count(params: any) {
  const { dimensionNames, encode } = params
  const xDimIndex = encode?.x[0]
  const yDimIndex = encode?.y[0]
  if (!xDimIndex) return null
  if (!yDimIndex) return null
  const xDimName = dimensionNames[xDimIndex]
  const yDimName = dimensionNames[yDimIndex]
  if (!xDimName) return null
  if (!yDimName) return null
  const data = params.value
  if (!data) return null
  const xDimCount = data[xDimName]
  const yDimCount = data[yDimName]
  if (!isNumber(xDimCount) && !isNumber(yDimCount)) return null

  if (isNumber(xDimCount)) return xDimCount

  if (isNumber(yDimCount)) return yDimCount

  return null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformPhaseStackBarEventParam2QueryField<T = any>(params: any): IUseChartTwoDimTupleItem<T>[] {
  const { dimensionNames, encode } = params
  const xDimIndex = encode?.x[0]
  const yDimIndex = encode?.y[0]
  if (!xDimIndex) return []
  if (!yDimIndex) return []
  const xDimName = dimensionNames[xDimIndex]
  const yDimName = dimensionNames[yDimIndex]
  if (!xDimName) return []
  if (!yDimName) return []
  const data = params.value
  if (!data) return []
  const list = data.data as IUseChartTwoDimTupleItem[]
  if (!list || list.length === 0) return []
  const phaseName = xDimName === 'category' ? yDimName : xDimName
  if (phaseName === 'Inactive') {
    const k = list.filter((i) => {
      const phaseDim = i[1]
      return !phaseDim.id || phaseDim.id === '-1' || (phaseDim.display_name_en && isInactivePhase(phaseDim.display_name_en))
    })
    return k
  }
  return list.filter((i) => i[1].display_name_en === phaseName)
}

export function transformTwoDimAggData2PhaseHorChartItems<T>(
  options: { locale: ILang; useYShortName?: boolean } | ILang,
  aggData?: ITwoDimensionAggData
): IUsePhaseHorChartDataItem<T>[] {
  if (!aggData) return []
  if (aggData && !aggData.aggregation_result) return []
  if (aggData && aggData.aggregation_result.length === 0) return []

  const locale = isObject(options) ? options.locale : options
  const useYShortName = isObject(options) ? (isUndefined(options.useYShortName) ? false : options.useYShortName) : false
  const list = aggData.aggregation_result[0].items.map((item) => {
    const mustUseShortName =
      aggData.aggregation_result[0].aggregation_field === 'TARGET_ID' ||
      aggData.aggregation_result[0].aggregation_field === 'PHS_TARGET_ID' ||
      aggData.aggregation_result[0].aggregation_field === 'phs_target_id'

    const yEnName = useYShortName ? item.short_name_en?.[0] || item.display_name_en : item.display_name_en

    const chartDataItem: IUsePhaseHorChartDataItem<T> = {
      ...getDefaultPhaseHorBarChartData(),
      data: [],
      category: locale === 'CN' && !mustUseShortName ? item.display_name_cn || yEnName : yEnName || item.display_name_cn,
    }

    if (!item.aggregation_result) return chartDataItem

    if (item.aggregation_result && item.aggregation_result.length === 0) return chartDataItem
    item.aggregation_result[0].items.forEach((i) => {
      // 这边可能display_name_en 为 undefined
      const { display_name_en, count } = i
      if (typeof count !== 'undefined' && count !== 0) {
        // 将没有 display_name_en （相当于没有key）
        if (typeof display_name_en === 'undefined') {
          // 不能放在 Inactive, 在useHeatmap中会被忽略掉，随便放个Inactive的阶段
          // chartDataItem.Inactive = (chartDataItem.Inactive || 0) + count
          chartDataItem.Pending = (chartDataItem.Pending || 0) + count
        } else {
          // 由于可能有空的key，占用了Pending这个键，所以这边在原有值的基础上加上count
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          chartDataItem[display_name_en as unknown as keyof IUsePhaseHorChartDataItem] = (chartDataItem[display_name_en] || 0) + count
        }
        const firstDim = {
          display_name_cn: item.display_name_cn,
          display_name_en: yEnName,
          id: item.key,
          field: aggData.aggregation_result[0].aggregation_field,
        }

        const secondDim = {
          display_name_cn: i.display_name_cn || '',
          display_name_en: i.display_name_en || '',
          id: i.key || '',
          field: item.aggregation_result[0].aggregation_field,
          otherInfo: i.other_info,
        }

        chartDataItem.data && chartDataItem.data.push([firstDim, secondDim])
      }
    })
    return chartDataItem
  })

  const sortedList = replaceSameNameByIncreaseCount(list, {
    getName: (i) => i.category,
    updateVal: (i, name) => (i.category = name),
  })

  return sortedList
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePhaseStackBarChart<T = any>(
  data: Ref<IUsePhaseHorChartDataItem<T>[]>,
  config: {
    theme?: 'default' | 'continue'
    layout?: 'horizontal' | 'vertical' | (() => 'horizontal' | 'vertical')
    clickAble?: boolean
    xAxisName?: string | (() => string)
    yAxisName?: string | (() => string)
    labelWidth?: number | (() => number)
    showLegend?: boolean
    legendPosition?: 'top' | 'right'
    labelRotate?: number
    inside?: boolean
    needMergeInActive?: boolean
    registerEvent?: (instance: EChartsType) => void
    noSortByCount?: boolean
    onClickStackPhaseBarChart?: (params: any) => void
  }
) {
  let echartInstance: EChartsType
  let vm: any
  const axisDimPosition = ref({
    left: 0,
    top: 0,
  })
  const axisDimStyle = ref({})
  const axisEntity = ref<{ id: string; type: IQueryDataEntityType }>({
    id: '',
    type: 'mechanism',
  })
  const tooltipProps = reactive({
    axisName: '',
    items: [] as { name: string; color: string; count: number }[],
  })
  const ins = getCurrentInstance()
  const theme = config.theme ?? 'default'
  const getInside = () => (typeof config.inside === 'undefined' ? true : config.inside)
  const getLayout = () => (typeof config.layout === 'function' ? config.layout() : config.layout || 'horizontal')
  const getLabelWidth = () => (typeof config.labelWidth === 'function' ? config.labelWidth() : getLayout() === 'horizontal' ? 120 : 82)
  const labelRotate = config.labelRotate ?? 0
  const legendPosition = config.legendPosition ?? 'right'
  const clickAble = config.clickAble ?? false
  const needMergeInactive = config.needMergeInActive ?? true
  const phases: (keyof IUsePhaseHorChartDataItem<T>)[] = [
    'Unknown',
    'Pending',
    'Discontinued',
    'Suspended',
    'Withdrawn',
    'Inactive',
    'Not Applicable',
    'Discovery',
    'Preclinical',
    'IND Application',
    'IND Approval',
    'Clinical',
    'Early Phase 1',
    'Phase 1',
    'Phase 1/2',
    'Phase 2',
    'Phase 2/3',
    'Phase 3',
    'NDA/BLA',
    'Approved',
    'Phase 4',
  ]
  const isEmpty = computed(() => data.value.length === 0)
  const yDimIsEntity = computed(() => {
    const isEntity = data.value.some((i) => i.data && i.data[0] && i.data[0][0] && i.data[0][0].field && getEntityTypeByAggField(i.data[0][0].field))
    return isEntity
  })
  const displayPhase = computed(() => {
    if (!needMergeInactive) return phases.filter((i) => !data.value.every((item) => item[i] === null))
    let list = phases.filter((i) => !data.value.every((item) => item[i] === null) && i !== 'Inactive')
    if (list.some((i: string) => isInactivePhase(i))) {
      list = list.filter((i) => !isInactivePhase(i))
      list.unshift('Inactive')
    }
    return list
  })
  const sortedYCategories = computed(() => {
    let list = data.value.map((i) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { category, data, Inactive, ...phases } = i
      const total = Object.keys(phases).reduce((pre, next) => {
        const key = next as unknown as keyof IUsePhaseHorChartDataItem
        const count = Number(i[key] || 0) as unknown as number
        return pre + count
      }, 0)

      return {
        category,
        total,
      }
    })
    if (!config.noSortByCount) {
      // 总数相同不再按照A-Z排序，避免中英文切换顺序不一致
      list = list.sort((a, b) => (getLayout() === 'horizontal' ? a.total - b.total : b.total - a.total))
    } else {
      if (getLayout() === 'horizontal') {
        list.reverse()
      }
    }

    return list.map((i) => {
      return i.category
    })
  })
  const horChartOption = computed<BasicHorizontalBarChart | BasicBarChart>(() => {
    const legendVisible = typeof config.showLegend === 'undefined' ? true : config.showLegend
    const inside = getInside()
    const layout = getLayout()
    const xAxisName = typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName || ''
    const hasXName = !!xAxisName
    const yAxisName = typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName || ''
    const hasYName = !!yAxisName
    const horOption: BasicHorizontalBarChart = {
      type: 'horizontal-bar',
      tooltip: {
        // 实体卡片需要移入
        enterable: config.onClickStackPhaseBarChart ? true : false,
        hideDelay: config.onClickStackPhaseBarChart ? 500 : undefined,
        appendToBody: true,
        padding: 0,
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: tooltipFormatter,
      },
      showBarBorder: theme === 'continue',
      legend: {
        show: legendVisible,
        type: 'scroll',
        itemHeight: 14,
        itemWidth: 14,
      },
      grid: {},
      xAxis: {
        name: config.xAxisName ? (typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName) : '',
        dataType: { valueType: 'count' },
      },
      yAxis: {
        triggerEvent: yDimIsEntity.value,
        name: config.yAxisName ? (typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName) : '',
        type: 'category',
        data: sortedYCategories.value,
      },
      dataset: {
        dimensions: [...phases.map((i) => ({ name: i })), { name: 'category' }],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        source: data.value.map((item) => {
          const keys = Object.keys(item)
          if (needMergeInactive && keys.some((i) => isInactivePhase(i))) {
            const val = (item.Unknown || 0) + (item.Pending || 0) + (item.Discontinued || 0) + (item.Suspended || 0) + (item.Withdrawn || 0)
            item.Inactive = val === 0 ? null : val
          }
          return item
        }),
      },
      ...(theme === 'continue' ? { color: PHASE_CONTINUE_COLOR_LIST } : undefined),
      series: displayPhase.value.map((phase) => {
        return {
          name: ins?.proxy.$tc(`clinical_trial.phase.${phase}`),
          color: theme === 'continue' ? (phase === 'Approved' ? PHASE_APPROVED_COLOR : undefined) : getPhaseColor(phase),
          cursor: clickAble ? 'pointer' : 'normal',
          encode: {
            x: phase,
            y: 'category',
          },
          stack: 'phase-hor-bar',
        }
      }),
    }

    if (layout === 'horizontal') {
      if (inside) {
        horOption.inside = true
        horOption.labelPadding = [0, 0, 14, 0]
        horOption.grid = {
          ...horOption.grid,
          containLabel: true,
          left: 6,
          right: 30,
          bottom: hasXName ? 42 : 24,
          top: hasYName ? 42 : 16,
        }
      } else {
        if (Array.isArray(horOption.yAxis)) {
          horOption.yAxis = horOption.yAxis.map((item) => ({
            ...item,
            axisLabel: {
              width: getLabelWidth(),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              overflow: 'truncate',
            },
          }))
        } else {
          horOption.yAxis.axisLabel = {
            // width: 120,
            width: getLabelWidth(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            overflow: 'truncate',
          }
        }
        horOption.grid = {
          ...horOption.grid,
          containLabel: false,
          left: getLabelWidth() + 20,
          right: 30,
          bottom: hasXName ? 42 : 24,
          top: hasYName ? 42 : 16,
        }
      }

      if (legendVisible) {
        if (legendPosition === 'top') {
          horOption.grid.top = Number(horOption.grid.top || 0) + 36
          horOption.legend = {
            ...horOption.legend,
            orient: 'horizontal',
            top: 4,
            left: 'center',
          }
        }
        if (legendPosition === 'right') {
          horOption.grid.right = 160
          horOption.legend = {
            ...horOption.legend,
            orient: 'vertical',
            right: 10,
            top: theme === 'continue' ? '10%' : 'middle',
          }
        }
      }
    }

    const verOption: BasicBarChart = {
      type: 'bar',
      tooltip: {
        enterable: config.onClickStackPhaseBarChart ? true : false,
        appendToBody: true,
        padding: 0,
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: tooltipFormatter,
        hideDelay: config.onClickStackPhaseBarChart ? 500 : undefined,
      },
      showBarBorder: theme === 'continue',
      legend: {
        show: legendVisible,
        type: 'scroll',
        itemHeight: 14,
        itemWidth: 14,
      },
      grid: {},
      xAxis: {
        name: config.yAxisName ? (typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName) : '',
        type: 'category',
        data: sortedYCategories.value,
        triggerEvent: yDimIsEntity.value,
      },
      yAxis: {
        name: config.xAxisName ? (typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName) : '',
        dataType: { valueType: 'count' },
      },
      dataset: {
        dimensions: [...phases.map((i) => ({ name: i })), { name: 'category' }],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        source: data.value.map((item) => {
          const keys = Object.keys(item)
          if (needMergeInactive && keys.some((i) => isInactivePhase(i))) {
            const val = (item.Unknown || 0) + (item.Pending || 0) + (item.Discontinued || 0) + (item.Suspended || 0) + (item.Withdrawn || 0)
            item.Inactive = val === 0 ? null : val
          }
          return item
        }),
      },
      ...(theme === 'continue' ? { color: PHASE_CONTINUE_COLOR_LIST } : undefined),
      series: displayPhase.value.map((phase) => {
        return {
          name: ins?.proxy.$tc(`clinical_trial.phase.${phase}`),
          color: theme === 'continue' ? (phase === 'Approved' ? PHASE_APPROVED_COLOR : undefined) : getPhaseColor(phase),
          cursor: clickAble ? 'pointer' : 'normal',
          encode: {
            x: 'category',
            y: phase,
          },
          stack: 'phase-bar',
        }
      }),
    }

    if (layout === 'vertical') {
      if (labelRotate > 0) {
        verOption.grid = {
          ...verOption.grid,
          containLabel: false,
          left: hasXName ? 56 : 30,
          top: hasXName ? 42 : 16,
          right: 30,
          bottom: getLabelWidth() + 18,
        }
        const opts = {
          interval: 0,
          rotate: labelRotate,
          width: getLabelWidth(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflow: 'truncate',
        }
        if (!Array.isArray(verOption.xAxis)) {
          verOption.xAxis.axisLabel = opts
          verOption.xAxis.nameGap = getLabelWidth() - 16
        } else {
          verOption.xAxis.forEach((item) => {
            item.axisLabel = opts
            item.nameGap = getLabelWidth() - 16
          })
        }
      }

      if (labelRotate === 0) {
        verOption.grid = {
          ...verOption.grid,
          containLabel: true,
          top: hasXName ? 42 : 16,
          right: 30,
          left: hasXName ? 56 : 30,
          bottom: hasYName ? 42 : 16,
        }
      }

      if (legendVisible) {
        if (legendPosition === 'top') {
          verOption.grid = {
            ...verOption.grid,
            top: Number((verOption.grid && verOption.grid.top) || 0) + 36,
          }
          verOption.legend = {
            ...verOption.legend,
            orient: 'horizontal',
            top: 4,
            left: 'center',
          }
        }
        if (legendPosition === 'right') {
          verOption.grid = {
            ...verOption.grid,
            right: 160,
          }
          verOption.legend = {
            ...verOption.legend,
            orient: 'vertical',
            right: 10,
            top: theme === 'continue' ? '10%' : 'middle',
          }
        }
      }
    }

    return layout === 'horizontal' ? horOption : verOption
  })
  const chartHeight = computed(() => {
    const singleHeight = 40
    const gap = 14
    const height = data.value.length * (singleHeight + 2 * gap)

    return Math.max(height, 350)
  })
  const { initChart, chartContainer, render, options, getEchartsInstance } = useChart(horChartOption, {
    autoResize: true,
    registerEvent: registerEvent,
  })

  useChartEntityTooltip(chartContainer, axisDimPosition, axisEntity, axisDimStyle)

  onBeforeUnmount(() => {
    if (vm) {
      vm.$destroy()
    }
    vm = null
  })

  watch(yDimIsEntity, (val) => {
    if (val) {
      echartInstance && registerEntityEvent(echartInstance)
    } else {
      echartInstance && unRegisterEntityEvent(echartInstance)
    }
  })

  function handleAxisMouseover(params: any) {
    if (!params.value) return
    if (!params.event) return
    const target = params.event.target
    if (!target) return
    const targetAxisRect = target.getBoundingRect().clone()
    target && targetAxisRect.applyTransform(target.transform)
    const value = params.value
    const found = data.value.find((i) => i.data && i.data.some((i) => i[0].display_name_en === value || i[0].display_name_cn === value))
    if (!found || !found.data) return
    const entityType = getEntityTypeByAggField(found.data[0][0].field)
    if (!entityType) return
    const style: any = {
      width: `${targetAxisRect.width}px`,
      height: labelRotate !== 0 && getLayout() === 'vertical' ? '16px' : `${targetAxisRect.height}px`,
    }
    if (labelRotate !== 0 && getLayout() === 'vertical') {
      style['transform'] = `rotate(-${labelRotate}deg)`
      style['transformOrigin'] = 'right top'
    }

    axisDimPosition.value = {
      left: labelRotate !== 0 && getLayout() === 'vertical' ? targetAxisRect.x - 16 : targetAxisRect.x,
      top: targetAxisRect.y,
    }

    axisDimStyle.value = style

    axisEntity.value = {
      id: found.data[0][0].id,
      type: entityType,
    }
  }

  function registerEntityEvent(instance: EChartsType) {
    instance.on('mouseover', getLayout() === 'horizontal' ? 'yAxis' : 'xAxis', handleAxisMouseover)
  }

  function registerEvent(instance: EChartsType) {
    config && config.registerEvent && config.registerEvent(instance)
    echartInstance = instance
    if (yDimIsEntity.value) {
      registerEntityEvent(instance)
    }
  }

  function unRegisterEntityEvent(instance: EChartsType) {
    instance.off('mouseover', handleAxisMouseover)
  }

  function seriesToolTipFormatter(params: any) {
    if (!Array.isArray(params)) return ''
    if (params.length === 0) return ''
    const axisName = params[0].axisValueLabel
    if (!axisName) return ''
    const items = params
      .map((i) => {
        const encode = i.encode
        const count = i.value[i.dimensionNames[getLayout() === 'horizontal' ? encode.x[0] : encode.y[0]]]
        const name = i.seriesName
        const color = i.color

        return {
          color,
          count,
          name,
          originalData: i,
        }
      })
      .filter((i) => i.count !== null)
    tooltipProps.axisName = axisName
    tooltipProps.items = items
    if (!vm) {
      vm = new Vue({
        i18n: ins?.proxy.$i18n,
        render() {
          return h(BStackBarTooltip, {
            props: {
              categoryName: tooltipProps.axisName,
              items: tooltipProps.items,
              countClickable: true,
            },
            on: {
              click: (data: any) => {
                if (config.onClickStackPhaseBarChart) {
                  config.onClickStackPhaseBarChart(data)
                }
              },
            },
          })
        },
      })
      vm.$mount()
    }
    return vm.$el
  }

  function tooltipFormatter(params: any) {
    return seriesToolTipFormatter(params)
  }

  return {
    chartContainer,
    initChart,
    isEmpty,
    chartHeight,
    render,
    options,
    getEchartsInstance,
  }
}
