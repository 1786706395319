import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref } from '@vue/composition-api'
export const GTextAutoLineClamp = defineComponent({
  name: 'GTextAutoLineClamp',
  props: {
    lineHeight: {
      type: Number,
      default: 21,
    },
    textAlign: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'bottom',
    },
    content: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { lineHeight } = props
    const containerRef = ref<HTMLElement | null>(null)
    const clampLine = ref<number>(0)
    const style = computed(() => {
      const line = clampLine.value
      return {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': line,
        'line-height': `${lineHeight}px`,
        height: `${line * lineHeight}px`,
        [props.textAlign]: 0,
      }
    })
    const resizeHandler = () => {
      if (!containerRef.value) return
      const height = containerRef.value?.clientHeight
      if (!height) return
      clampLine.value = Math.floor(height / lineHeight)
    }
    const resizeObserver = new ResizeObserver(resizeHandler)
    onMounted(() => {
      if (containerRef.value) resizeObserver.observe(containerRef.value)
    })
    onBeforeUnmount(() => {
      if (containerRef.value) resizeObserver.unobserve(containerRef.value)
    })
    return { containerRef, style }
  },
  render() {
    return (
      <div class="h-full relative overflow-hidden" ref="containerRef">
        <div class={['absolute left-0 right-0 overflow-hidden']} style={this.style}>
          {this.content}
        </div>
      </div>
    )
  },
})
