import { ALL_DEV_STATUS } from '@patsnap/synapse_common_config'
import { E_TIME_TRUST_CODE } from '@patsnap/synapse_common_interface'
import { IAggDiseaseFieldType, IAggDrugFieldType, IAggOrgFieldType, IAggTargetFieldType } from '../types'
import { E_TIME_FORMAT } from '../types/enum'

export const DRUG_NAME = 'Drug'
export const DISEASE_NAME = 'Disease'
export const TARGET_NAME = 'Target'
export const COMPANY_NAME = 'Organization'

export const TIME_TRUST_CODE_FORMAT_RECORD: Partial<Record<E_TIME_TRUST_CODE, E_TIME_FORMAT>> = {
  [E_TIME_TRUST_CODE.YEAR]: E_TIME_FORMAT.YYYY,
  [E_TIME_TRUST_CODE.YEAR_DAY]: E_TIME_FORMAT.YYYY,
  [E_TIME_TRUST_CODE.YEAR_MONTH]: E_TIME_FORMAT.MM_YYYY,
  [E_TIME_TRUST_CODE.YEAR_MONTH_DAY]: E_TIME_FORMAT.DD_MM_YYYY,
}

export const TIME_TRUST_CODE_FORMAT_RECORD_EN: Partial<Record<E_TIME_TRUST_CODE, E_TIME_FORMAT>> = {
  [E_TIME_TRUST_CODE.YEAR]: E_TIME_FORMAT.YYYY,
  [E_TIME_TRUST_CODE.YEAR_DAY]: E_TIME_FORMAT.YYYY,
  [E_TIME_TRUST_CODE.YEAR_MONTH]: E_TIME_FORMAT.MMM_YYYY,
  [E_TIME_TRUST_CODE.YEAR_MONTH_DAY]: E_TIME_FORMAT.DDD_MMM_YYYY,
}

export const INACTIVE_PHASE_EN_NAMES = ['Unknown', 'Pending', 'Discontinued', 'Suspended', 'Withdrawn']

export const PHASE_CONTINUE_COLOR_LIST = [
  'rgba(25, 118, 210, 0.2)',
  'rgba(25, 118, 210, 0.28)',
  'rgba(25, 118, 210, 0.36)',
  'rgba(25, 118, 210, 0.44)',
  'rgba(25, 118, 210, 0.52)',
  'rgba(25, 118, 210, 0.6)',
  'rgba(25, 118, 210, 0.68)',
  'rgba(25, 118, 210, 0.76)',
  'rgba(25, 118, 210, 0.84)',
  'rgba(25, 118, 210, 0.92)',
  '#1976D2',
]

export const PHASE_APPROVED_COLOR = '#BD6CE7'

export const PHASE_DEFAULT_COLOR = '#6895F8'

export const FreeListExceedLimitCode = 130075
export const FreeDisableViewPatentDetailCode = 130093
export const FreePatentSearchLimitCode = 130092

export const AGG_DRUG_FIELDS: IAggDrugFieldType[] = ['MAIN_EXPERIMENTATION_DRUG_ID', 'PHS_DRUG_ID', 'phs_drug_id']
export const AGG_TARGET_FIELDS: IAggTargetFieldType[] = [
  'PHS_TARGET_ID',
  'TARGET_ID',
  'phs_target_id',
  'TARGET_ID_COMPETITIVE',
  'TARGET_ID_EXTEND',
  'TARGET_ID_EXTEND_FLATTEN',
]
export const AGG_DISEASE_FIELDS: IAggDiseaseFieldType[] = [
  'CONDITION_DISEASE_ID',
  'PHS_DISEASE_ID',
  'RESEARCH_DISEASE',
  'RES_AND_NON_RES_DISEASE_ID',
  'phs_disease_id',
  'CONDITION_DISEASE_ID_ALL',
  'DISEASE_ID_ALL',
  'DISEASE_ID',
]
export const AGG_ORG_FIELDS: IAggOrgFieldType[] = [
  'RESEARCH_ORG_ID_ONEID',
  'REGISTRY_ID_ONEID',
  'REGISTRY_ID_ROOT_ONEID',
  'PHS_ORG_ROOT_ID_ONEID',
  'RESEARCH_ORG_ID_ROOT_ONEID',
  'master_phs_organization_root_id',
  'master_phs_organization_id',
  'ORG_ROOT_ID_ONEID',
  'ORGANIZATION_ID_ONEID',
  'PHS_ORG_ID_ONEID',
  'PRINCIPLE_ORG_ID_ROOT',
  'PRINCIPLE_ORG_ID',
  'PARTNER_ORG_ID_ROOT',
  'PARTNER_ORG_ID',
  'RESEARCH_SPONSOR_ID',
  'RESEARCH_SPONSOR_ID_ROOT',
]
export const AGG_DRUG_TYPE_FIELDS = ['DRUG_TYPE_ALL', 'DRUG_TYPE']

export const phaseWeightMap = ALL_DEV_STATUS.reduce((acc, cur) => {
  return {
    ...acc,
    [cur.dict_id]: cur.rank,
  }
}, {})

/**
 * 药物详情页那边用的国家排序表
 */
export const countryWeightConfig: { key: string; name: string; weight: number }[] = [
  {
    key: '0dc5d896-7408-3c87-84c0-22e3d9b784be',
    name: '美国',
    weight: 0,
  },
  {
    key: '5a365096-b2a6-31cb-acdf-1de1f5ab3abe',
    name: '中国',
    weight: 1,
  },
  {
    key: '9a18968a-f130-3a00-80b6-8b9e2eec4838',
    name: '欧盟',
    weight: 2,
  },
  {
    key: '676de5ba-893a-36bb-8683-c93306aaf769',
    name: '日本',
    weight: 3,
  },
]

export const countryWeightMap: Record<string, number> = countryWeightConfig.reduce((acc, cur, index) => {
  return {
    ...acc,
    [cur.key]: index + 1,
  }
}, {})

export const approvalDiseaseCountryWeightMap = {
  '0dc5d896-7408-3c87-84c0-22e3d9b784be': 1,
  '9a18968a-f130-3a00-80b6-8b9e2eec4838': 2,
  '5a365096-b2a6-31cb-acdf-1de1f5ab3abe': 3,
  '676de5ba-893a-36bb-8683-c93306aaf769': 4,
}

export const FUNDING_TYPE_COLOR_MAP: Record<string, string> = {
  Venture: '#1976D2',
  Seed: '#27B4D1',
  'Post IPO Equity': '#43B74B',
  Grant: '#A2C716',
  'Private Equity': '#CDCE12',
  'Debt Financing': '#FABB27',
  'Post IPO Debt': '#FD865A',
  'Funding Round': '#FF6C73',
  'Pre Seed': '#E96A94',
  'Equity Crowdfunding': '#A863F7',
  'Corporate Round': '#D174D6',
  Angel: '#FA85B5',
  'Convertible Note': '#23A5A4',
  'Product Crowdfunding': '#1E8EBB',
  'Non Equity Assistance': '#8F95AD',
  'Secondary Market': '#1976D2',
  'Initial Coin Offering': '#27B4D1',
  'Post IPO Secondary': '#43B74B',
  Undisclosed: '#A2C716',
}

export const VENTURE_SERIES_COLOR_MAP: Record<string, string> = {
  A: '#1976D2',
  B: '#27B4D1',
  C: '#43B74B',
  D: '#A2C716',
  'E+': '#CDCE12',
  Seed: '#FABB27',
  Angel: '#FD865A',
  'Series Unknown': '#8F95AD',
}

/**
 * 药物专利分类排序
 * 化合物>序列>复方>组合物>衍生物>晶型>制备/工艺>医药用途>剂型>装置>诊断与分析化验>其他
 */
export const drugPatentTypeWeightMap: Record<string, number> = {
  '2633e3e2ab413e4d9a326d2c46fb7d62': 1, // 化合物
  '5d7f1bfbf0df38a881106caed3bff0c5': 2, // 序列
  '45ff23a7d91b3a4795037e97fc6fcb82': 3, // 复方
  '6489e0ed556f368aa8aced9c28a59062': 4, // 组合物
  '1091515825783c60b6e38b67b0e03cae': 5, // 衍生物
  '98a2c69889743aa1a0de44916a53d10d': 6, // 晶型
  b6ec7abeb6ae39ccb5a4b47475e12afe: 7, // 制备/工艺
  f6d60f79ffbf3d14b0b62f0821755eeb: 8, // 医药用途
  '07d42bbaac6d370bbd532d23298cd1c3': 9, // 剂型
  '9bc92f15dbaf3b1ba2c041d199111f67': 10, // 装置
  ae68b42adb363e369ba9f4580824db37: 11, // 诊断与分析化验
  '52ef9633d88a3480b3a938ff9eaa2a25': 12, // 其他
}

/**
 * 专利技术类型
 * ADC>腺相关病毒>溶瘤病毒>CRISPR/Cas9>外泌体
 */
export const patentTechnologyWeightMap: Record<string, number> = {
  '7e19a4b3743a47e48884bafeaec85b84': 1,
  '4c0fdeccbb6f4140bb00a4555bbc4f79': 2,
  '1a04711d6e304f36a84fc9a06dc2edb3': 3,
  b67f2b7aaebc481a9d81d94df3d32878: 4,
  '7214d1e4b6f443ce8328c9e1ecea81f7': 5,
}

/**
 * 简单法律状态排序
 * 有效>失效>审中>PCT指定期内>PCT指定期满>未确认
 */
export const simpleLegalStatusWeightMap: Record<string, number> = {
  '1': 1,
  '0': 2,
  '2': 3,
  '221': 4,
  '220': 5,
  '999': 6,
}

/**
 * 受理局排序
 * WO>US>EP>CN>JP>其他国家
 */
export const authorityWeightMap: Record<string, number> = {
  WO: 1,
  US: 2,
  EP: 3,
  CN: 4,
  JP: 5,
}
