import { IRelatedMarkerItem } from '@patsnap/synapse_bullseye_chart'
import { IAggregationData, IDrugDevStatusGroupField, IQuery } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { AggBarChartEntity, AggChartValue, AggPieChartEntity, AggStackBarChartEntity } from '@patsnap/synapse_domain'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/company.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/disease.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/drugType.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/earth.svg'
import '@pharmsnap/pharmsnap-web/assets/icon-svg/target.svg'
import { generateChartImage } from '@pharmsnap/pharmsnap-web/utils/generateChartImage'
import { SHOW_DATA_ZOOM_LIMIT } from '@pharmsnap/shared/src/chart/constants'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { IQueryService } from '@pharmsnap/shared/src/composition/useQueryService'
import { DRUG_LIMIT } from '@pharmsnap/shared/src/constants'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/src/constants/enum-lang/org'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IBullsEyeChartItemWithOriginData, ILoadingService, INavListType, ISearchResult } from '@pharmsnap/shared/src/types'
import { getInactiveDrugDevStatusField, showSingleToast, transAggregationValue2QueryItem, transTwoDimItems2Ids } from '@pharmsnap/shared/src/utils'
import { getBullsEyeChartCustomQuery } from '@pharmsnap/shared/src/utils/business/bulls-eye-chart'
import { computed, defineComponent, getCurrentInstance, PropType, reactive, Ref, ref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import html2canvas from 'html2canvas'
import { isEqual, map, noop } from 'lodash'
import { GIcon } from 'pharmsnapMF_shared/components'
import {
  BAnalysisBarEchart,
  BAnalysisBarTable,
  BAnalysisBullsEyeChart,
  BAnalysisChartCard,
  BAnalysisChartFullScreenCard,
  BAnalysisPieEchart,
  BAnalysisPieTable,
  BAnalysisStackBarEchart,
  BAnalysisTwoDimStackTableWithSorter,
  useAnalysisDrugOneDimChartEntity,
  useAnalysisDrugTwoDimChartEntity,
} from '../../BAnalysisChartNext'
import { useAnalysisOneDimChartNavList } from '../../BAnalysisChartNext/compositions/useAnalysisOneDimChartNavList'
import { useAnalysisTwoDimChartNavList } from '../../BAnalysisChartNext/compositions/useAnalysisTwoDimChartNavList'
import $classes from '../BAnalysisView.module.scss'
import {
  barChartTypes,
  bullsEyeChartTypes,
  E_DRUG_ANALYSIS_CHART,
  IDrugAggChartModel,
  pieChartTypes,
  stackBarChartTypes,
  useDrugAggChart,
} from '../compositions/useDrugAggChart'
import { BDrugBullsEyeChart } from './components/BDrugBullsEyeChart'
import cn from './locales/cn.json'
import en from './locales/en.json'

const chartTypes: E_DRUG_ANALYSIS_CHART[] = [
  E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR,
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE,
  E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR,
  E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR,
  E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART,
  E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART,
]

const bullsEyeChartType2EntityType: Partial<Record<E_DRUG_ANALYSIS_CHART, 'organization' | 'target' | 'disease' | 'drug_type'>> = {
  [E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART]: 'organization',
  [E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART]: 'target',
  [E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART]: 'disease',
  [E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART]: 'drug_type',
}

interface IViewAllConfig {
  svgName: string
  text?: string
  navList: IDrugDevStatusGroupField
}

export const BDrugAnalysisView = defineComponent({
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { uncollapse_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props, ctx) {
    const showFullScreen = ref(false)
    const ins = getCurrentInstance()
    const { isCN } = useLocale()
    const {
      state: { userInfo },
    } = useAuthStore()

    const activeFullScreen = ref<E_DRUG_ANALYSIS_CHART | ''>('')

    const bullsEyeChartFullScreenRef = ref<InstanceType<typeof BAnalysisBullsEyeChart>>()

    const downloadingStackBar = ref<E_DRUG_ANALYSIS_CHART>()
    const downloadingStackBarRef = ref()

    const query = computed<IQuery>(() => {
      return {
        type: 'group',
        must: props.queryService.state.query.must,
        filter: props.queryService.state.query.filter,
      }
    })

    const queryType = computed(() => props.queryService.state.searchQueryType)

    const structureQuery = computed(() => props.queryService.state.structureQuery)

    const chartAggRecord = chartTypes.reduce<Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useDrugAggChart>>>(
      (pre, next) => ({
        ...pre,
        [next]: useDrugAggChart({ type: next, query, queryType, structureQuery, fullscreenVisible: computed(() => activeFullScreen.value === next) }),
      }),
      {} as Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useDrugAggChart>>
    )

    const barChartEntityRecord = barChartTypes.reduce<Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugOneDimChartEntity>>>(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisDrugOneDimChartEntity({
          chartType: 'bar',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugOneDimChartEntity>>
    )

    const bullsEyeChartEntityRecord = bullsEyeChartTypes.reduce<Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugTwoDimChartEntity>>>(
      (pre, next) => {
        return {
          ...pre,
          [next]: useAnalysisDrugTwoDimChartEntity({
            chartType: 'bar',
            data: chartAggRecord[next].bullEyeAggData as Ref<IAggregationData>,
            xAxisName: chartAggRecord[next].countFieldName,
            yAxisName: chartAggRecord[next].categoryFieldName,
            layout: 'horizontal',
          }),
        }
      },
      {} as Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugTwoDimChartEntity>>
    )

    const stackBarChartEntityRecord = stackBarChartTypes.reduce<Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugTwoDimChartEntity>>>(
      (pre, next) => {
        return {
          ...pre,
          [next]: useAnalysisDrugTwoDimChartEntity({
            chartType: 'bar',
            data: chartAggRecord[next].aggData,
            xAxisName: chartAggRecord[next].countFieldName,
            yAxisName: chartAggRecord[next].categoryFieldName,
            layout: 'horizontal',
          }),
        }
      },
      {} as Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugTwoDimChartEntity>>
    )

    const getDownloadingStackBarChartEntity = () => {
      if (downloadingStackBar.value) {
        return useAnalysisDrugTwoDimChartEntity({
          chartType: 'bar',
          data: chartAggRecord[downloadingStackBar.value].aggData,
          xAxisName: chartAggRecord[downloadingStackBar.value].countFieldName,
          yAxisName: chartAggRecord[downloadingStackBar.value].categoryFieldName,
          layout: 'horizontal',
          showDataZoom: false,
        })
      }
    }
    const pieChartEntityRecord = pieChartTypes.reduce<Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugOneDimChartEntity>>>(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisDrugOneDimChartEntity({
          chartType: 'pie',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<E_DRUG_ANALYSIS_CHART, ReturnType<typeof useAnalysisDrugOneDimChartEntity>>
    )

    const chartInitedRecord = reactive(chartTypes.reduce((pre, next) => ({ ...pre, [next]: false }), {} as Record<E_DRUG_ANALYSIS_CHART, boolean>))

    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter, props.queryService.state.structureQuery],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        initData()
      },
      {
        immediate: true,
      }
    )

    watch(
      () => [query.value, props.queryService.state.structureQuery],
      () => {
        initAggData()
      }
    )

    async function initData() {
      props.loadingService.action.setLoading(true)
      const rt = await props.getTotalFn()
      props.loadingService.action.setTotal(rt.total)
      props.loadingService.action.setSubTotal(rt.sub_total)
      props.loadingService.action.setLoading(false)
    }

    function initAggData() {
      chartTypes.forEach((type) => {
        if (chartInitedRecord[type]) {
          handleFetchChartData(type)
        }
      })
    }

    function getChartDescConfigByType(type: E_DRUG_ANALYSIS_CHART, model?: IDrugAggChartModel) {
      const top = model?.top
      switch (type) {
        case E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR:
          return {
            drug: {
              title: ins?.proxy.$tc('Analysis.HighestPhase'),
              desc: ins?.proxy.$tc('Analysis.HighestPhaseDesc'),
              xAxisName: ins?.proxy.$tc('Analysis.HighestPhase'),
              yAxisName: ins?.proxy.$tc('Analysis.No_Of_Drug'),
            },
            dev_status: {
              title: ins?.proxy.$tc('Analysis.Phase'),
              desc: ins?.proxy.$tc('Analysis.PhaseDesc'),
              xAxisName: ins?.proxy.$tc('Analysis.Phase'),
              yAxisName: ins?.proxy.$tc('Analysis.No_Of_Drug'),
            },
          }[model?.dataType?.selectedValue || 'drug']
        case E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_10_Org', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.OrgDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_Org_Bulls_Eye', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.BullsEyeOrgDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE:
          return {
            title: ins?.proxy?.$tc('Analysis.OrgType'),
            desc: ins?.proxy?.$tc('Analysis.OrgTypeDesc'),
          }
        case E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_10_Target', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.TargetDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_Target_Bulls_Eye', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.BullsEyeTargetDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_10_Indication', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.IndicationDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_Disease_Bulls_Eye', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.BullsEyeDiseaseDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_10_DrugType', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.DrugTypeDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_Drug_Type_Bulls_Eye', undefined, { top }),
            desc: ins?.proxy?.$tc('Analysis.BullsEyeDrugTypeDesc', undefined, { top }),
          }
        case E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR:
          return {
            title: ins?.proxy?.$tc('Analysis.Top_5_Country'),
            desc: ins?.proxy?.$tc('Analysis.Top_5_Country_Desc'),
          }
        default:
          return {
            title: '',
            desc: '',
          }
      }
    }

    function getViewAllConfig(type: E_DRUG_ANALYSIS_CHART): IViewAllConfig | undefined {
      switch (type) {
        case E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART:
        case E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR:
          return {
            svgName: 'Company',
            text: ins?.proxy?.$tc('Analysis.viewAllOrg') || '',
            navList: 'organization',
          }
        case E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR:
        case E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART:
          return {
            svgName: 'Target',
            text: ins?.proxy?.$tc('Analysis.viewAllTarget') || '',
            navList: 'target',
          }
        case E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR:
        case E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART:
          return {
            svgName: 'Disease',
            text: ins?.proxy?.$tc('Analysis.viewAllDisease') || '',
            navList: 'disease',
          }
        case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR:
        case E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART:
          return {
            svgName: 'DrugType',
            text: ins?.proxy?.$tc('Analysis.viewAllDrugType') || '',
            navList: 'drug_type',
          }
        default:
          return undefined
      }
    }

    function handleFetchChartData(type: E_DRUG_ANALYSIS_CHART) {
      chartAggRecord[type].fetchAggData()
      chartInitedRecord[type] = true
    }

    function handleOpenFullScreen(type: E_DRUG_ANALYSIS_CHART) {
      activeFullScreen.value = type
      showFullScreen.value = true
    }

    function handleTrackDownload(type: E_DRUG_ANALYSIS_CHART, common1?: string) {
      sharedCtx.service.tracking.trackGeneral({
        event_code: 'DOWNLOAD_CHART',
        trigger_point: type,
        common1,
      })
    }

    function handleTrackBullsEyeDownload(type: E_DRUG_ANALYSIS_CHART) {
      const bullsEyeChartType2StackBarType: Partial<Record<E_DRUG_ANALYSIS_CHART, E_DRUG_ANALYSIS_CHART>> = {
        [E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART]: E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR,
        [E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART]: E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR,
        [E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART]: E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR,
        [E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART]: E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR,
      }

      const stackBarType = bullsEyeChartType2StackBarType[type]

      if (stackBarType) {
        handleTrackDownload(stackBarType, 'BULLS_EYE')
      } else {
        console.error('缺少牛眼图type对应的堆叠柱状图type配置')
      }
    }

    function handleCloseFullScreen() {
      showFullScreen.value = false
      activeFullScreen.value = ''
    }

    function handleClickStackBarChart() {
      const { handleClick: onClickPhaseStackBarChartWithIds } = useAnalysisTwoDimChartNavList({
        chartType: 'bar',
        navList: 'drug',
        query,
        useIds: true,
        adjustSearchParams: (params) => {
          return {
            ...params,
            ...(queryType.value === 'STRUCTURE'
              ? {
                  query_type: 'STRUCTURE',
                  structure_query: structureQuery.value,
                }
              : undefined),
          }
        },
      })

      return (data: any) => {
        const ids = transTwoDimItems2Ids(data, 'related_drug_id')
        onClickPhaseStackBarChartWithIds({ ...data, ids, dataType: 'drug', field: 'DRUG_ID' })
      }
    }

    const { handleClick: onClickBullsEyeChartWithIds } = useAnalysisTwoDimChartNavList<IBullsEyeChartItemWithOriginData>({
      chartType: 'bar',
      navList: 'drug',
      query,
      useIds: true,
      customQueryItemPicker: (data) => getBullsEyeChartCustomQuery(data),
      adjustSearchParams: (params) => {
        return {
          ...params,
          ...(queryType.value === 'STRUCTURE'
            ? {
                query_type: 'STRUCTURE',
                structure_query: structureQuery.value,
              }
            : undefined),
        }
      },
    })

    function handleClickBullsEyeChartCount(data: IBullsEyeChartItemWithOriginData, filterDrugTypeId?: string) {
      let ids = data.other_info?.related_drug_id || []
      if (filterDrugTypeId) {
        ids = data.other_info?.stat_info.drug_type.find((item) => item.id === filterDrugTypeId)?.related_drug_id || []
      }
      const params = { ...data, ids, dataType: 'drug', field: 'DRUG_ID' }
      onClickBullsEyeChartWithIds(params)
    }

    const handleClickBullsEyeChartMarker = (data: IRelatedMarkerItem) => {
      openPageWithoutPending(() => sharedCtx.router.generatorDrugPath(data.id as string))
    }

    const { handleClick: handleClickPieChart } = useAnalysisOneDimChartNavList({
      chartType: 'pie',
      navList: 'drug_group_organization',
      query,
      appendTo: 'filter',
      searchParams: {
        org_roll_up: false,
      },
      customAggValueTransformer: (value: AggChartValue[]) => {
        return transAggregationValue2QueryItem(
          map(value, (o) => ({
            ...o,
            value: o.key,
            display_name_cn: ORG_ENTITY_LANG[o.key].cn || '',
            display_name_en: ORG_ENTITY_LANG[o.key].en || '',
          }))
        )
      },
      adjustSearchParams: (params) => {
        return {
          ...params,
          ...(queryType.value === 'STRUCTURE'
            ? {
                query_type: 'STRUCTURE',
                structure_query: structureQuery.value,
              }
            : undefined),
        }
      },
    })

    const handleViewAll = (type: E_DRUG_ANALYSIS_CHART, navList: IDrugDevStatusGroupField) => {
      ctx.emit('groupByChange', navList, chartAggRecord[type].model.rollup, true)
    }

    function getHandleClickFunc(type: E_DRUG_ANALYSIS_CHART) {
      if (E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR === type) {
        const navList = { drug: 'drug', dev_status: 'drug_group_dev_status' }[chartAggRecord[type].model?.dataType?.selectedValue || 'drug']
        const { handleClick: onClickPhaseCountChart } = useAnalysisOneDimChartNavList({
          chartType: 'bar',
          navList: navList as MaybeRef<INavListType>,
          query,
          appendTo: 'filter',
          adjustQueryField: (queryField) => {
            const value = queryField.value
            const foundIndex = value.findIndex((i) => i.type === 'text' && i.value === '-1')
            if (foundIndex !== -1) {
              value.splice(foundIndex, 1)
              value.push(...getInactiveDrugDevStatusField('GLOBAL_HIGHEST_DEV_STATUS').value)
            }

            return queryField
          },
          adjustSearchParams: (params) => {
            return {
              ...params,
              ...(queryType.value === 'STRUCTURE'
                ? {
                    query_type: 'STRUCTURE',
                    structure_query: structureQuery.value,
                  }
                : undefined),
            }
          },
        })
        return onClickPhaseCountChart
      }
      if (E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR === type) {
        const { handleClick: onClickPhaseCountChart } = useAnalysisOneDimChartNavList({
          chartType: 'bar',
          navList: 'drug_group_organization',
          query,
          appendTo: 'filter',
          searchParams: {
            org_roll_up: false,
          },
          adjustSearchParams: (params) => {
            return {
              ...params,
              ...(queryType.value === 'STRUCTURE'
                ? {
                    query_type: 'STRUCTURE',
                    structure_query: structureQuery.value,
                  }
                : undefined),
            }
          },
        })
        return onClickPhaseCountChart
      }
      if (stackBarChartTypes.includes(type)) {
        return handleClickStackBarChart()
      }
      if (pieChartTypes.includes(type)) return handleClickPieChart

      return noop
    }

    async function handleStackBarChartDownload(type: E_DRUG_ANALYSIS_CHART) {
      if (downloadingStackBar.value) return

      const toast = showSingleToast({
        message: ins?.proxy.$tc('common.exporting') || '',
        type: 'info',
        duration: 0,
      })

      downloadingStackBar.value = type
      // 加个延迟，先显示toast提示，不然toast显示会延迟
      setTimeout(async () => {
        if (downloadingStackBarRef.value) {
          const { model } = chartAggRecord[type]
          const { title } = getChartDescConfigByType(type, model)

          const canvas = await html2canvas(downloadingStackBarRef.value, {
            useCORS: true,
            logging: false,
            imageTimeout: 0,
            scale: 1.5,
            x: 0,
          })
          await generateChartImage({
            chartUrl: canvas.toDataURL('image/png'),
            width: canvas.width,
            height: canvas.height,
            name: `${title}.png`,
            addHeight: 38,
            addWidth: 50,
            isCN: isCN.value,
            userInfo: { userId: userInfo.value?.user_id || '' },
          })
          downloadingStackBar.value = undefined
          toast.close()
        }
      }, 100)
    }

    function handleStackBarLegendSelectedChange(
      type: E_DRUG_ANALYSIS_CHART,
      selected: {
        [key: string]: boolean
      },
      isFullScreen = false
    ) {
      const chartAgg = chartAggRecord[type]
      if (isFullScreen) {
        chartAgg.fullScreenStackBarChartLegendSelectedStatus.value = selected
      } else {
        chartAgg.stackBarChartLegendSelectedStatus.value = selected
      }
    }

    return {
      bullsEyeChartFullScreenRef,
      chartAggRecord,
      showFullScreen,
      activeFullScreen,
      barChartEntityRecord,
      stackBarChartEntityRecord,
      bullsEyeChartEntityRecord,
      pieChartEntityRecord,
      downloadingStackBar,
      downloadingStackBarRef,
      getChartDescConfigByType,
      getViewAllConfig,
      handleFetchChartData,
      handleOpenFullScreen,
      handleCloseFullScreen,
      getDownloadingStackBarChartEntity,
      getHandleClickFunc,
      handleClickBullsEyeChartCount,
      handleClickBullsEyeChartMarker,
      handleTrackDownload,
      handleTrackBullsEyeDownload,
      handleViewAll,
      handleStackBarChartDownload,
      handleStackBarLegendSelectedChange,
    }
  },
  methods: {
    renderViewAll(type: E_DRUG_ANALYSIS_CHART) {
      const config = this.getViewAllConfig(type)
      if (config) {
        const disabled = this.loadingService.state.total >= DRUG_LIMIT

        return (
          <div
            class={[
              'flex items-center text-sm leading-6 text-black-default py-1 px-2 rounded',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-30',
            ]}
            onClick={() => !disabled && this.handleViewAll(type, config.navList)}
          >
            <GIcon class="w-6 h-6 mr-1 text-gray-450" svgName={config.svgName} size={24}></GIcon>
            <div>{config.text}</div>
          </div>
        )
      }
    },
    renderBarByType(type: E_DRUG_ANALYSIS_CHART, cls = '', id: string, height?: number) {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.barChartEntityRecord[type]
      const { title, desc } = this.getChartDescConfigByType(type, model)

      return (
        <vue-lazy-component key={`${type}_lazy`} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            title={title}
            desc={desc}
            class={cls}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls.value}
            layout={controlLayout.value}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
            onAfterDownload={() => this.handleTrackDownload(type)}
          >
            <BAnalysisBarEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggBarChartEntity}
              displayType="vertical-bar"
            ></BAnalysisBarEchart>
            <BAnalysisBarTable
              class={[{ hidden: model.display === 'vertical-bar' }]}
              height={'100%'}
              displayMode="table"
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              list={dataList.value}
              useListSorter={false}
            ></BAnalysisBarTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderBarByTypeFullScreen(type: E_DRUG_ANALYSIS_CHART) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]

      const { chartEntity, dataList } = this.barChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen`}
          class="h-full"
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls.value}
          layout={controlLayout.value}
          value={model}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
          onAfterDownload={() => this.handleTrackDownload(type)}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisBarEchart
                style={{ height: `600px` }}
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggBarChartEntity}
                displayType="vertical-bar"
              ></BAnalysisBarEchart>
              <div class="mt-8">
                <BAnalysisBarTable
                  displayMode="record"
                  categoryFieldName={categoryFieldName.value}
                  countFieldName={countFieldName.value}
                  list={dataList.value}
                  useListSorter={false}
                ></BAnalysisBarTable>
              </div>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderBullsEyeChartByType(type: E_DRUG_ANALYSIS_CHART, cls = '', id = '') {
      const { title, desc } = this.getChartDescConfigByType(type, this.chartAggRecord[type].model)
      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BDrugBullsEyeChart
            type={type}
            title={`${title} (${this.$tc('Analysis.sortByR&DStatus')})`}
            bullsEyeChartTitle={`${title}\n${this.$tc('Analysis.sortByR&DStatus')}`}
            desc={desc}
            cls={cls}
            analysisEntity={bullsEyeChartType2EntityType[type]}
            chartAgg={this.chartAggRecord[type]}
            bullsEyeChartEntity={this.bullsEyeChartEntityRecord[type]}
            onOpen={() => this.handleOpenFullScreen(type)}
            onClickBullsEyeChartCount={this.handleClickBullsEyeChartCount}
            onClickBullsEyeChartItem={this.handleClickBullsEyeChartMarker}
            onAfterDownload={() => this.handleTrackBullsEyeDownload(type)}
          >
            <template slot="headerActionSlot">{this.renderViewAll(type)}</template>
          </BDrugBullsEyeChart>
        </vue-lazy-component>
      )
    },
    renderBullsEyeChartFullScreen(type: E_DRUG_ANALYSIS_CHART) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayout, loading, isEmpty, settingDataPanelTitle, isBullEyeEmpty, bullEyeAggData } = this.chartAggRecord[type]

      const { countFieldName, dataList, stackFieldName, entityFieldName } = this.bullsEyeChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type, model)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={`${title} (${this.$tc('Analysis.sortByR&DStatus')})`}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls.value}
          layout={controlLayout.value}
          value={model}
          dataPanelTitle={settingDataPanelTitle.value}
          downloadCfg={{ visible: true, type: 'custom' }}
          onDownload={() => {
            this.bullsEyeChartFullScreenRef?.handleDownload()
          }}
          onAfterDownload={() => this.handleTrackBullsEyeDownload(type)}
          onClose={this.handleCloseFullScreen}
        >
          <template slot="headerActionSlot">{!isEmpty.value && this.renderViewAll(type)}</template>
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              {!isBullEyeEmpty.value ? (
                <BAnalysisBullsEyeChart
                  data={bullEyeAggData.value}
                  uniqueId={`${type}-fullscreen`}
                  innerCircleTitle={`${title}\n${this.$tc('Analysis.sortByR&DStatus')}`}
                  showLabel={model.showLabel || false}
                  selectedPhaseIds={model.phases || []}
                  analysisEntity={bullsEyeChartType2EntityType[type]}
                  chartMinHeight={700}
                  ref="bullsEyeChartFullScreenRef"
                  onClickCount={this.handleClickBullsEyeChartCount}
                  onClickItem={this.handleClickBullsEyeChartMarker}
                ></BAnalysisBullsEyeChart>
              ) : null}
            </div>
            <div class="mt-8">
              <BAnalysisTwoDimStackTableWithSorter
                displayMode="record"
                entityFieldName={entityFieldName.value}
                countFieldName={countFieldName.value}
                stackFieldName={stackFieldName.value}
                list={dataList.value}
              ></BAnalysisTwoDimStackTableWithSorter>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderDownloadingStackBarByType() {
      if (this.downloadingStackBar) {
        const stackBarChartEntity = this.getDownloadingStackBarChartEntity() as ReturnType<typeof useAnalysisDrugTwoDimChartEntity>
        const { chartEntity, dataList, uniqEntityDataList } = stackBarChartEntity

        const height = Math.max(400, uniqEntityDataList.value.length * 64)
        const chartAgg = this.chartAggRecord[this.downloadingStackBar]

        const legendSelectedStatus =
          this.activeFullScreen === this.downloadingStackBar
            ? chartAgg.fullScreenStackBarChartLegendSelectedStatus.value
            : chartAgg.stackBarChartLegendSelectedStatus.value

        return (
          <div class="absolute w-[1000px] z-[-10]" ref="downloadingStackBarRef">
            <BAnalysisStackBarEchart
              showTotalCount
              class="w-full"
              style={{ height: `${height}px` }}
              entity={chartEntity.value as AggStackBarChartEntity}
              uniqEntityDataList={uniqEntityDataList.value}
              dataList={dataList.value}
              selectedLegend={legendSelectedStatus}
            />
          </div>
        )
      }
    },
    renderStackBarByType(type: E_DRUG_ANALYSIS_CHART, cls = '', id: string) {
      const { model, controls, controlLayout, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList, countFieldName, stackFieldName, entityFieldName, uniqEntityDataList } = this.stackBarChartEntityRecord[type]
      const { title, desc } = this.getChartDescConfigByType(type, model)

      const height = Math.min(64 * 10, Math.max(400, uniqEntityDataList.value.length * 64))

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={title}
            desc={desc}
            specifyContentHeight
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls.value}
            layout={controlLayout.value}
            value={model}
            downloadCfg={{
              visible: model.display !== 'table',
              type: uniqEntityDataList.value.length > SHOW_DATA_ZOOM_LIMIT ? 'custom' : 'chart-img',
            }}
            onDownload={() => this.handleStackBarChartDownload(type)}
            onOpen={() => this.handleOpenFullScreen(type)}
            onAfterDownload={() => this.handleTrackDownload(type, 'STACK_BAR')}
          >
            <template slot="headerActionSlot">{!isEmpty.value && this.renderViewAll(type)}</template>
            <BAnalysisStackBarEchart
              showTotalCount
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggStackBarChartEntity}
              uniqEntityDataList={uniqEntityDataList.value}
              dataList={dataList.value}
              onLegendSelectedChange={(selected: { [key: string]: boolean }) => this.handleStackBarLegendSelectedChange(type, selected)}
            />
            <BAnalysisTwoDimStackTableWithSorter
              class={[{ hidden: model.display === 'horizontal-bar' }]}
              entityFieldName={entityFieldName.value}
              countFieldName={countFieldName.value}
              stackFieldName={stackFieldName.value}
              list={dataList.value}
              displayMode="table"
              height="100%"
            ></BAnalysisTwoDimStackTableWithSorter>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderStackBarFullScreen(type: E_DRUG_ANALYSIS_CHART) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayout, loading, isEmpty, settingDataPanelTitle } = this.chartAggRecord[type]

      const { chartEntity, countFieldName, dataList, entityFieldName, uniqEntityDataList, stackFieldName } = this.stackBarChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type, model)

      const height = Math.min(64 * 10, Math.max(400, uniqEntityDataList.value.length * 64))

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls.value}
          layout={controlLayout.value}
          value={model}
          dataPanelTitle={settingDataPanelTitle.value}
          downloadCfg={{ visible: true, type: uniqEntityDataList.value.length > SHOW_DATA_ZOOM_LIMIT ? 'custom' : 'chart-img' }}
          onDownload={() => this.handleStackBarChartDownload(type)}
          onAfterDownload={() => this.handleTrackDownload(type, 'STACK_BAR')}
          onClose={this.handleCloseFullScreen}
        >
          <template slot="headerActionSlot">{!isEmpty.value && this.renderViewAll(type)}</template>
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisStackBarEchart
                showTotalCount
                uniqEntityDataList={uniqEntityDataList.value}
                dataList={dataList.value}
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggStackBarChartEntity}
                style={{ height: `${height}px` }}
                onLegendSelectedChange={(selected: { [key: string]: boolean }) => this.handleStackBarLegendSelectedChange(type, selected, true)}
              ></BAnalysisStackBarEchart>
            </div>
            <div class="mt-8">
              <BAnalysisTwoDimStackTableWithSorter
                displayMode="record"
                entityFieldName={entityFieldName.value}
                countFieldName={countFieldName.value}
                stackFieldName={stackFieldName.value}
                list={dataList.value}
              ></BAnalysisTwoDimStackTableWithSorter>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderPieByType(type: E_DRUG_ANALYSIS_CHART, cls = '', id = '') {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]

      const { chartEntity, dataList } = this.pieChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type)

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={title}
            desc={desc}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={450}
            controls={controls.value}
            layout={controlLayout.value}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
            onAfterDownload={() => this.handleTrackDownload(type)}
          >
            <BAnalysisPieEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggPieChartEntity}
            />
            <BAnalysisPieTable
              class={[{ hidden: model.display === 'pie' }]}
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              list={dataList.value}
              displayMode="table"
              height="100%"
              showPercent={true}
            ></BAnalysisPieTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderPieFullScreen(type: E_DRUG_ANALYSIS_CHART) {
      if (type !== this.activeFullScreen) return null
      const { model, controls, controlLayout, categoryFieldName, countFieldName, settingDataPanelTitle, loading, isEmpty } = this.chartAggRecord[type]
      const { title, desc } = this.getChartDescConfigByType(type)
      const { chartEntity, dataList } = this.pieChartEntityRecord[type]

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls.value}
          layout={controlLayout.value}
          value={model}
          dataPanelTitle={settingDataPanelTitle.value}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onAfterDownload={() => this.handleTrackDownload(type)}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisPieEchart
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggPieChartEntity}
                style={{ height: `450px` }}
              ></BAnalysisPieEchart>
            </div>
            <div class="mt-8">
              <BAnalysisPieTable
                displayMode="record"
                categoryFieldName={categoryFieldName.value}
                countFieldName={countFieldName.value}
                list={dataList.value}
                showPercent={true}
              ></BAnalysisPieTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
  },
  render() {
    return (
      <div class="p-4 pb-16 h-full overflow-y-auto">
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('Analysis.Drug')}</div>
        {this.renderBarByType(E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR, 'mb-4', 'chart-1', 600)}
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('Analysis.Organization')}</div>
        {this.renderBullsEyeChartByType(E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART, 'mb-4')}
        {this.renderStackBarByType(E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR, 'mb-4', 'chart-2')}
        <div class="flex flex-row items-center mb-4">
          <div class="flex-1 overflow-hidden pr-2">{this.renderPieByType(E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE, '', 'chart-3')}</div>
          <div class="flex-1 overflow-hidden pl-2">{this.renderBarByType(E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR, '', 'chart-4', 450)}</div>
        </div>
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('Analysis.Target')}</div>
        {this.renderBullsEyeChartByType(E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART, 'mb-4')}
        {this.renderStackBarByType(E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR, 'mb-4', 'chart-5')}
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('Analysis.Indication')}</div>
        {this.renderBullsEyeChartByType(E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART, 'mb-4')}
        {this.renderStackBarByType(E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR, 'mb-4', 'chart-6')}
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('Analysis.DrugType')}</div>
        {this.renderBullsEyeChartByType(E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART, 'mb-4')}
        {this.renderStackBarByType(E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR, 'mb-4', 'chart-7')}
        <div class={[$classes.fullscreen, { hidden: !this.showFullScreen }]}>
          {this.renderBarByTypeFullScreen(E_DRUG_ANALYSIS_CHART.HIGHEST_PHASE_BAR)}
          {this.renderStackBarFullScreen(E_DRUG_ANALYSIS_CHART.ORGANIZATION_STACK_BAR)}
          {this.renderPieFullScreen(E_DRUG_ANALYSIS_CHART.ORGANIZATION_TYPE_PIE)}
          {this.renderStackBarFullScreen(E_DRUG_ANALYSIS_CHART.TARGET_STACK_BAR)}
          {this.renderStackBarFullScreen(E_DRUG_ANALYSIS_CHART.DISEASE_STACK_BAR)}
          {this.renderStackBarFullScreen(E_DRUG_ANALYSIS_CHART.DRUG_TYPE_STACK_BAR)}
          {this.renderBullsEyeChartFullScreen(E_DRUG_ANALYSIS_CHART.ORGANIZATION_BULLS_EYE_CHART)}
          {this.renderBullsEyeChartFullScreen(E_DRUG_ANALYSIS_CHART.TARGET_BULLS_EYE_CHART)}
          {this.renderBullsEyeChartFullScreen(E_DRUG_ANALYSIS_CHART.DISEASE_BULLS_EYE_CHART)}
          {this.renderBullsEyeChartFullScreen(E_DRUG_ANALYSIS_CHART.DRUG_TYPE_BULLS_EYE_CHART)}
          {this.renderBarByTypeFullScreen(E_DRUG_ANALYSIS_CHART.COUNTRY_HORIZONTAL_BAR)}
        </div>
        {this.renderDownloadingStackBarByType()}
      </div>
    )
  },
})
