import { getDisplayNameByLang } from '@patsnap/synapse_common_business'
import type { IQueryValueLang } from '@patsnap/synapse_common_interface'
import type { I18nLang } from '@pharmsnap/shared/i18n'
import '@pharmsnap/shared/src/assets/icon-svg/startups.svg'
import { GIcon } from '../../..'

// 加number兼容packages/shared/src/components/business/BFilter/components/biz/AdvanceFilter/AdvanceFilterContent.tsx类型
export function renderDisplayNameWithTitle<T extends IQueryValueLang>(local: I18nLang, data: T, isStartup?: boolean | number) {
  const displayName = getDisplayNameByLang(data, local)
  return (
    <span title={displayName} class="truncate">
      {displayName}
      {/* 有可能是数字, 必须严格是true */}
      {isStartup === true && (
        <span class="inline-block p-[2px] bg-green-tag rounded h-[20px] leading-4">
          <GIcon svgName="Startups" color="#45A321" />
        </span>
      )}
    </span>
  )
}
export function renderDisplayNameWithTitle2Line<T extends IQueryValueLang>(local: I18nLang, data: T, isStartup?: boolean) {
  const displayName = getDisplayNameByLang(data, local)
  return (
    <span class="line-clamp-2" title={displayName}>
      {displayName}
      {isStartup && (
        <span class="inline-block p-[2px] bg-green-tag rounded h-[20px] leading-4">
          <GIcon svgName="Startups" color="#45A321" />
        </span>
      )}
    </span>
  )
}
