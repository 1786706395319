import { formatTimestamp } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IDrugApprovalInfo, IDrugApprovalItem } from '@pharmsnap/shared/src/types'
import { PropType, defineComponent } from '@vue/composition-api'
import { GCountryFlag } from '../../ui/GCountryFlag/GCountryFlag'
import { GLimited } from '../../ui/GLimited/GLimited'
import { BDiseaseItem } from '../BDiseaseItem/BDiseaseItem'
import $classes from './BDrugApprovalTimeline.module.scss'

export const BDrugApprovalTimeline = defineComponent({
  name: 'BDrugApprovalTimeline',
  props: {
    data: {
      type: Object as PropType<IDrugApprovalInfo>,
    },
    drugId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { localeUpcase } = useLocale()

    return {
      localeUpcase,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return null
      if (totalCount === 0) return null

      return (
        <GLimited
          class="flex justify-center"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={1}
          linkTip={this.$t('common.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDrugPath(this.drugId)}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderTimelineData(item: IDrugApprovalItem | undefined, isApproval: boolean) {
      if (!item) return null
      return (
        <div class="flex-1 flex flex-col justify-center overflow-hidden">
          <div class="h-6">
            {this.renderLimited(item.approved_disease_id_view || [], item.approved_disease_id?.length || 0, (item) => (
              <BDiseaseItem popover={false} data={item}></BDiseaseItem>
            ))}
          </div>
          <div class="mt-1 text-center h-6">
            {item.approved_country_view?.length ? (
              this.renderLimited(item.approved_country_view || [], item.approved_country?.length || 0, (item) => {
                const countryName = item.alpha_2_code || item.display_name
                return <GCountryFlag name={countryName} showName useFullName showAlphaCode showUnknownCountryFlag></GCountryFlag>
              })
            ) : (
              <GCountryFlag name="" showName showUnknownCountryFlag class="max-auto"></GCountryFlag>
            )}
          </div>

          <div class="mt-5 flex items-center justify-center flex-wrap">
            <div class="text-text-t2 text-sm leading-6 mr-1">
              {formatTimestamp(item.approved_date, {
                locale: this.localeUpcase,
              })}
            </div>
            <div class={['rounded px-1 text-sm leading-5', isApproval ? 'text-green-text bg-green-light' : 'text-orange-hover bg-orange-light']}>
              {isApproval ? this.$t('common.firstApproval') : this.$t('common.new')}
            </div>
          </div>
        </div>
      )
    },
    renderTimelineItem() {
      return (
        <div class={$classes.timelineItem}>
          <div class={$classes.timelineItemTail}></div>
        </div>
      )
    },
    renderTimeline() {
      return (
        <div class={$classes.timeline}>
          {!!this.data?.first_approved && this.renderTimelineItem()}
          {!!this.data?.mid_process && <div class="w-[10%] border-b-2 border-dashed border-blue-default"></div>}
          {!!this.data?.latest_approved && this.renderTimelineItem()}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.timelineContainer}>
        {this.renderTimelineData(this.data?.first_approved, true)}
        {this.renderTimelineData(this.data?.latest_approved, false)}
        {this.renderTimeline()}
      </div>
    )
  },
})
