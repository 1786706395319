import { useLocale } from '@pharmsnap/shared/composition'
import { ElInput, ElSwitch } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep, debounce, isEqual } from 'lodash'
import { Icon as PtIcon } from 'patsnap-biz'
import Draggable from 'vuedraggable'
import { GTooltip } from '../GTooltip/GTooltip'
import $class from './GSortField.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
interface IDragItem {
  // 用于提取得到最终值
  field: string
  // 用于搜索匹配的值
  fieldNameEn: string
  fieldNameCn: string
  tooltip?: {
    cn: string
    en: string
  }
  /**
   * 是否禁止 选择去拖拽 和 禁止替换此元素位置
   * true为禁止拖拽排序
   * false为允许拖拽排序
   */
  isForbid: boolean
  /**
   * 是否勾选
   */
  show: boolean
}
interface IManageData {
  fieldList: IDragItem[]
  backUpFieldList: IDragItem[]
}
export const GSortField = defineComponent({
  name: 'GSortField',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /**
     * 是否默认勾选详情
     */
    value: {
      type: Array as PropType<Array<IDragItem>>,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { isCN } = useLocale()
    const draggable = ref<boolean>(false) // 整体是否 可以拖拽排序
    const cloneValue = cloneDeep(props.value)
    const manageData = reactive<IManageData>({
      fieldList: cloneValue,
      backUpFieldList: [], // 完整的数据，用于search前端过滤的备份
    })
    const searchContent = ref<string>('')
    const style = computed(() => {
      if (props.height) {
        return {
          height: props.height,
        }
      }
      return {}
    })
    watch(
      () => props.value,
      (value) => {
        if (isEqual(value, manageData.fieldList)) {
          return
        }

        manageData.fieldList = cloneDeep(value)
      }
    )
    watch(
      () => manageData.fieldList,
      () => {
        const fieldList = searchContent.value ? manageData.backUpFieldList : manageData.fieldList
        if (isEqual(props.value, fieldList)) {
          return
        }
        emit('input', fieldList)
      },
      {
        deep: true,
      }
    )
    watch(
      searchContent,
      debounce((content) => {
        if (!content) {
          manageData.fieldList = manageData.backUpFieldList
          manageData.backUpFieldList = []
          draggable.value = false
        } else {
          draggable.value = true
          if (!manageData.backUpFieldList.length) {
            manageData.backUpFieldList = manageData.fieldList
          }
          const filterArr = manageData.fieldList.filter((item) => {
            const reg = new RegExp(content, 'i')
            return isCN.value ? item.fieldNameCn.search(reg) !== -1 : item.fieldNameEn.search(reg) !== -1
          })
          manageData.fieldList = filterArr
        }
      }, 300)
    )

    function onMove(e: { relatedContext: { element: IDragItem } }) {
      // 如果要拖拽到的 目标 上，有禁用字段，那么返回false，禁止插入
      return !e.relatedContext.element.isForbid
    }
    return { searchContent, manageData, isCN, onMove, draggable, style }
  },
  methods: {
    renderDraggableContent() {
      if (this.manageData.fieldList.length) {
        return this.manageData.fieldList.map((item) => {
          return (
            <div key={item.field} class={[this.draggable || item.isForbid ? 'forbid' : 'move-item', $class.fieldItem]}>
              <div class={$class.fieldItemLeft}>
                <ElSwitch v-model={item.show} disabled={item.isForbid} width={35}></ElSwitch>
                <div class={item.isForbid ? $class.disableColor : $class.moveColor}>{this.isCN ? item.fieldNameCn : item.fieldNameEn}</div>
                {item.tooltip && <GTooltip mode="icon" content={this.isCN ? item.tooltip.cn : item.tooltip.en}></GTooltip>}
              </div>
              {!item.isForbid ? (
                <div class={$class.fieldItemRight}>
                  <PtIcon icon="SolidMove"></PtIcon>
                </div>
              ) : (
                ''
              )}
            </div>
          )
        })
      } else {
        return <div class={$class.noFound}>{this.$tc('GSortField.noFound')}</div>
      }
    },
  },
  render() {
    return (
      <div>
        {this.searchable && (
          <div class={$class.searchInput}>
            <ElInput
              placeholder={this.$tc('GSortField.inputPlaceholder')}
              prefix-icon="el-icon-search"
              v-model={this.searchContent}
              disabled={this.draggable}
              clearable
              autofocus
            ></ElInput>
          </div>
        )}
        <Draggable
          style={this.style}
          v-model={this.manageData.fieldList}
          animation="300"
          filter=".forbid"
          move={this.onMove}
          class={[$class.dragContain, 'dragContain']}
        >
          {this.renderDraggableContent()}
        </Draggable>
      </div>
    )
  },
})
