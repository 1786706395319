import { toThousands } from '@patsnap/synapse_common_utils'
import { ElCheckbox, ElRadio } from '@pharmsnap/shared/element-ui'
import { encryptNum } from '@pharmsnap/shared/utils/encrypt/encrypt'
import { defineComponent, PropType } from '@vue/composition-api'
import $style from './CheckBoxWithLine.module.scss'
export const CheckBoxWithLine = defineComponent({
  name: 'CheckBoxWithLine',
  props: {
    checked: {
      required: true,
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    width: {
      type: String,
      default: '0',
    },
    count: {
      type: Number,
      default: 0,
    },
    align: {
      type: String as PropType<'start' | 'center'>,
      default: 'start',
    },
    single: {
      type: Boolean,
      default: false,
    },
    singleValue: {
      type: [String, Number] as PropType<string | number>,
    },
  },
  setup(props, ctx) {
    function onChange(value: boolean) {
      ctx.emit('change', value)
    }
    function onClickContent() {
      ctx.emit('clickContent')
    }
    return {
      onChange,
      onClickContent,
    }
  },
  render() {
    return (
      <div
        data-testid="checkbox-with-line"
        class={[
          $style.main,
          {
            'items-center': this.align === 'center',
            'items-start': this.align === 'start',
          },
        ]}
      >
        {' '}
        {this.single ? (
          <ElRadio
            class={$style.radio}
            label={this.singleValue}
            onchange={this.onChange}
            disabled={this.disabled}
            value={this.checked ? this.singleValue : 'FILTER_SINGLE_PLACEHOLDER'}
          ></ElRadio>
        ) : (
          <ElCheckbox class={$style.checkbox} onchange={this.onChange} disabled={this.disabled} value={this.checked}></ElCheckbox>
        )}
        <div class="flex-1 overflow-hidden ml-1">
          <div class={[$style.container, { 'text-text-t3': this.disabled }]}>
            <div
              onClick={this.onClickContent}
              class={[
                $style.content,
                {
                  'cursor-pointer': !this.disabled,
                  'cursor-not-allowed': this.disabled,
                },
              ]}
            >
              {this.$slots.default}
            </div>
            <div
              data-testid="checkbox-with-line__count"
              class={[$style.count, 'icon-font']}
              domPropsInnerHTML={encryptNum(toThousands(this.count))}
            ></div>
          </div>

          {/* 调整bar的样式 */}
          <div style={{ width: this.width }} class={[$style.bar]}></div>
        </div>
      </div>
    )
  },
})
