import { E_VERTEX_TYPE } from '../../types'

export const baseKgEntityType = ['drug', 'target', 'disease', 'organization']

export const KgSearchStrategy: Record<E_VERTEX_TYPE, 'ID' | 'ID_ROLLUP'> = {
  [E_VERTEX_TYPE.DRUG]: 'ID',
  [E_VERTEX_TYPE.ORGANIZATION]: 'ID_ROLLUP',
  [E_VERTEX_TYPE.TARGET]: 'ID_ROLLUP',
  [E_VERTEX_TYPE.DISEASE]: 'ID_ROLLUP',
}
