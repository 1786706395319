import { GRadio } from '@pharmsnap/shared/components/ui/GRadio/GRadio'
import { E_ALIGN_DIRECTION, IGRadioItem } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import $classes from './PatentFieldSelection.module.scss'

export const PatentFieldSelection = defineComponent({
  name: 'PatentFieldSelection',
  props: {
    options: {
      type: Array as PropType<Array<{ label: string; value: IGRadioItem['value'] }>>,
      required: true,
    },
    value: {
      type: [String, Number, Boolean] as PropType<IGRadioItem['value']>,
      required: true,
    },
    title: {
      type: String,
    },
    align: {
      type: String as PropType<E_ALIGN_DIRECTION>,
      default: E_ALIGN_DIRECTION.HORIZONTAL,
    },
  },
  setup(props, { emit }) {
    const onInput = (val: IGRadioItem['value']) => emit('changeField', val)

    return {
      onInput,
    }
  },
  render() {
    return (
      <div class={$classes.selection}>
        {this.title && <div class={$classes.title}>{this.title}</div>}
        <GRadio value={this.value} onInput={this.onInput} items={this.options} mode="circle" inline={false} align={this.align}></GRadio>
      </div>
    )
  },
})
