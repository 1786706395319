import { defineComponent, ref, PropType, watch, getCurrentInstance, onUpdated } from '@vue/composition-api'
import { debounce } from 'lodash'
import { GDialog, GEmpty } from '../../../components'
import { ElInput } from '../../../element-ui'
import { ILang } from '../../../types'
import cn from './locales/cn.json'
import en from './locales/en.json'
import $class from './BLimitedMore.module.scss'
import { E_NODATA_SIZE } from '../../../types'

export const BLimitedMore = defineComponent({
  name: 'BLimitedMore',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    // 对应数据
    items: {
      type: Array as PropType<any[]>,
      require: true,
    },
    render: {
      type: Function as PropType<(data: any[]) => JSX.Element>,
      required: true,
    },
    // input搜索时匹配规则
    filterItem: {
      type: Function as PropType<(data: any[], searchContent: string, lang: ILang) => any[]>,
      required: true,
    },
    title: {
      type: String,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    // 是否在search后保持高度
    keepHeight: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const isShowDialog = ref<boolean>(false)
    const toggleShowDialog = debounce(() => {
      isShowDialog.value = !isShowDialog.value
    })
    const onClickCancel = debounce(() => {
      searchContent.value = ''
      toggleShowDialog()
    }, 100)
    const lang = ref(ctx.root.$i18n.locale.toUpperCase() as ILang)
    const searchContent = ref<string>('')
    const displayItems = ref(props.items || [])
    watch(searchContent, (content) => {
      if (!content) {
        displayItems.value = props.items || []
      } else {
        if (props.items && props.items.length) {
          displayItems.value = props.filterItem(props.items, content, lang.value)
        } else {
          displayItems.value = []
        }
      }
    })
    // keepHeight是否在search后保持高度
    const ins = getCurrentInstance()
    let contentH: number
    onUpdated(() => {
      if (!props.keepHeight) return
      const dom = ins?.refs.content as HTMLDivElement
      // 初次进入
      if (dom && !dom.style.height) {
        contentH = dom.offsetHeight
        contentH && (dom.style.height = `${contentH}px`)
      }
    })
    return {
      isShowDialog,
      toggleShowDialog,
      onClickCancel,
      searchContent,
      displayItems,
    }
  },
  render() {
    return (
      <div onClick={this.toggleShowDialog}>
        {this.items && this.items.length && (
          <div>
            <div>{this.$tc('viewAll')}</div>
            {this.isShowDialog && (
              <GDialog
                customClass={$class.limitedMoreDialog}
                visible
                title={this.title ? this.title : this.$tc('dialog.title')}
                width="560px"
                showFooter={this.showFooter}
                closeOnClickCancel={false}
                onClose={this.onClickCancel}
                onCancel={this.onClickCancel}
              >
                <div slot="default">
                  <div class="flex items-center justify-between">
                    <div>{this.$t('dialog.allTotal', { allNumber: this.items.length })}</div>
                    <ElInput
                      class={$class.inputWrap}
                      placeholder={this.$tc('dialog.placeholder')}
                      prefix-icon="el-icon-search"
                      v-model={this.searchContent}
                      clearable
                      autofocus
                    ></ElInput>
                  </div>
                  <div class={$class.content} ref="content">
                    {this.displayItems.length ? this.render(this.displayItems) : <GEmpty size={E_NODATA_SIZE.SMALL}></GEmpty>}
                  </div>
                </div>
              </GDialog>
            )}
          </div>
        )}
      </div>
    )
  },
})
