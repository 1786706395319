import { BaseApi } from '@pharmsnap/shared/service/apis/baseApi'
import { IHttp, IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { DetailType, IFieldConfigData, ISceneMappingData, IUserSecurityControl, IUserUsageInfo, SHARE_TYPE } from '@pharmsnap/shared/types/account'
import qs from 'qs'

export class AccountApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  /**
   * 获取用量信息
   *
   * @returns
   */
  async getUserUsage(usage_type?: keyof IUserUsageInfo, usage_scope?: 'group'): Promise<IHttpWrap<IUserUsageInfo>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/user/usage'),
      params: { usage_type, usage_scope },
      method: 'GET',
    })
  }
  /**
   * 获取用户安全控制
   * @returns
   * @param params
   */
  async getUserSecurityControl(): Promise<IHttpWrap<IUserSecurityControl>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/user/security/control`),
      method: 'GET',
    })
  }
  async getUserFieldConfig(type: string): Promise<IHttpWrap<IFieldConfigData>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/field-config/type/${type}`),
      method: 'GET',
    })
  }
  async setUserFieldConfig(params: IFieldConfigData): Promise<IHttpWrap<IFieldConfigData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/field-config'),
      method: 'POST',
      data: params,
    })
  }

  async getFeatureConfig(): Promise<IHttpWrap<string[]>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/feature-config'),
      method: 'GET',
    })
  }

  // 用户查看详情达到指定时间后调用此接口进行加分，type是实体类型
  viewDetailToAddPoint(type: DetailType) {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/point/view-detail?type=${type}`),
      method: 'POST',
    })
  }

  /** 绑定走的one patsnap接口，绑定后调用该接口，同步PHS后端绑定情况 */
  async syncBindInfoToPhsService() {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/point/complete_user_info'),
      method: 'POST',
    })
  }

  // SHARE_CODE类型时，如果传递value，那么value会在后端拼接上 &user_id=user_id
  async exchangeQrCodeMappingId(data: { type: SHARE_TYPE | 'PAGE'; value: ISceneMappingData }): Promise<IHttpWrap<string>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/point/mapping'),
      method: 'POST',
      data: {
        type: data.type,
        value: qs.stringify(data.value),
      },
      cache: true,
    })
  }

  // 当type = SHARE_CODE 时， value 存的是 share_code
  async revertMappingId(id: string): Promise<IHttpWrap<{ mapping_id: string; value: string; type: 'PAGE' | 'SHARE_CODE' }>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/point/revert_mapping'),
      method: 'GET',
      params: {
        mapping_id: id,
      },
      cache: true,
    })
  }
}
