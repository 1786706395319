import { toThousands } from '@patsnap/synapse_common_utils'
import { defineComponent } from '@vue/composition-api'
import { debounce } from 'lodash'
import { GIcon } from '../../../../ui/GIcon/GIcon'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $class from './BFreeLimit.module.scss'

export const BFreeLimit = defineComponent({
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
    },
    maxLimit: {
      type: Number,
      default: 100,
    },
    // 需要页面中引用 对应icon
    svgName: {
      type: String,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, { emit }) {
    const onUpgrade = debounce(() => {
      emit('upgrade')
    }, 100)

    const handleBack = () => {
      emit('back')
    }
    return {
      onUpgrade,
      handleBack,
    }
  },
  render() {
    return (
      <div>
        {this.isShow && (
          <div class={$class.container}>
            <div class="flex flex-col relative z-10 m-8">
              <div class={$class.title}>{this.$t('FreeLimit.title', { total: toThousands(this.total) })}</div>
              <i18n path={'FreeLimit.content'} tag="div" class={[$class.content, 'my-5']}>
                <span place="maxLimit" class="text-red-default">
                  {this.maxLimit}
                </span>
              </i18n>
              <div class="flex items-center my-6">
                <button class={['pt-ui-btn']} with-icon type="button" data-type="submit" onClick={this.onUpgrade}>
                  {this.svgName && <GIcon style="filter: brightness(0) invert(1);" svgName={this.svgName} size={16} class="w-5 h-5"></GIcon>}
                  <span>{this.$tc('FreeLimit.upgradeButton')}</span>
                </button>

                <span class={$class.backBtn} onClick={this.handleBack}>
                  {this.$tc('FreeLimit.back')}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  },
})
