import {
  IAggregationData,
  IAggregationParams,
  IDrugAutoCompleteItem,
  ISearchParams,
  IStructureItem,
  ITwoDimensionAggData,
} from '@patsnap/synapse_common_interface'
import { IDrugGroupByDataItem } from '@pharmsnap/shared/types/drug-group-by'
import {
  IExportParams,
  IExportResult,
  IExportStatusResult,
  IGroupAggregationParams,
  IGroupSearchParams,
  ISearchResult,
} from '@pharmsnap/shared/types/search'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformDrugAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/drug'
import { transformGroupByEntityAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/organization'
import { AxiosRequestConfig } from 'axios'
import DataLoader from 'dataloader'
import {
  IAutoCompleteParams,
  IBatchDrugInfo,
  IDrugCardInfo,
  IDrugDetail,
  IDrugRDStatusInfo,
  IEventTypeItem,
  ILang,
  ISearchTreeParams,
  ISearchTreeResult,
  ITrialIndicationPhaseAggRes,
} from '../../types'
import { decryptDrug } from '../../utils'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DrugApi extends BaseApi {
  private batchGetDrugsLoader = new DataLoader((ids: readonly string[]) => this._getBatchDrugs(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async export(params: IExportParams, config?: AxiosRequestConfig): Promise<IHttpWrap<IExportResult>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/export'),
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }
  async exportStatus(id: string): Promise<IHttpWrap<IExportStatusResult>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/export/${id}`),
    })
  }
  /**
   * 药物搜索列表，不计搜索次数
   */
  async getDrugList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IDrugDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug/search'),
      trackingName: 'DRUG_LIST',
      cache: true,
    })
  }
  /**
   * 药物搜索列表，计搜索次数
   */
  async getDrugListWithCalcLimits(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IDrugDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug'),
      trackingName: 'DRUG_LIST',
      cache: true,
    })
  }
  async getDrugGroupList(params: IGroupSearchParams): Promise<IHttpWrap<ISearchResult<IDrugGroupByDataItem>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug/group/search'),
      trackingName: 'DRUG_GROUP_LIST',
      cache: true,
    })
  }
  /** 根据药物研发状态聚合 */
  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }
  /** 根据药物聚合 */
  async getAggregationDrug(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/aggregation_drug'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }
  async getDrugDevAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/dev/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }
  async getDrugDevOrgAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/dev_org/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }
  async getGroupAggregation(params: IGroupAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/group/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformGroupByEntityAggregationSingleResult))
  }
  /** 根据研发状态聚合 */
  async getDevAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/dev/aggregation_pipeline'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }
  private async _getBatchDrugs(ids: readonly string[]): Promise<Array<IBatchDrugInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBatchDrugInfo>>
    if (rt.success && rt.data) {
      return ids.map((id) => rt.data.items?.find((item) => item.drug_id === id))
    }
    return []
  }
  async getBatchDrugs(ids: readonly string[]): Promise<IHttpWrap<IBatchDrugInfo[]>> {
    const rt = await this.batchGetDrugsLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBatchDrugInfo[],
      xCorrelationId: '',
    }
  }
  async getCardInfo(id: string): Promise<IHttpWrap<IDrugCardInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/card/${id}`),
      cache: true,
    })
  }
  async getOverviewInfo(id: string): Promise<IHttpWrap<IDrugDetail>> {
    const rt = await this.http({
      method: 'GET',
      params: {
        base64: true,
        width: 1000,
        height: 1000,
      },
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/${id}`),
      cache: true,
    })

    if (rt.success && rt.data) {
      rt.data.xCorrelationId = rt.xCorrelationId
      rt.data.fontClassName = rt.fontClassName
      decryptDrug(rt.data, rt.xCorrelationId, rt.fontClassName)
    }

    return rt
  }
  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IDrugAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    return this.http({
      method: 'POST',
      url: this.getUrl('discoveryls_common/1.0/drug/autocomplete'),
      cache: true,
      cancelToken,
      data: {
        prefix: keywords,
        limit,
        type: params.type,
      },
    })
  }
  async getDevStatusInfo(id: string): Promise<IHttpWrap<IDrugRDStatusInfo[]>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/rd_status/table/${id}`),
      cache: true,
    })
  }
  async getRdStatusHistogram(id: string): Promise<IHttpWrap<ITrialIndicationPhaseAggRes>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/rd_status/histogram/${id}`),
      cache: true,
    })
  }
  async getDataStatus(id: string): Promise<
    IHttpWrap<{
      approval?: boolean
      clinical_trial?: boolean
      rnd_status?: boolean
      special_review?: boolean
      core_patent?: boolean
      clinical_trial_result?: boolean
      core_patent_non_label?: boolean
      drug_deal?: boolean
      translational_medicine?: boolean
      spc?: boolean
    }>
  > {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/${id}/data/status`),
      cache: true,
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<any>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug/search_for_count'),
      cache: true,
    })
  }
  async getDownloadDrugDevStatusCount(): Promise<IHttpWrap<number>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/download/rd_status/times`),
    })
  }

  async getDrugEventTypeTemplate(): Promise<IHttpWrap<IEventTypeItem[]>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/drug_detail/event_template`),
      method: 'GET',
      cache: true,
    })
  }

  async downloadDrugRDStatus(params: { id: string; lang: ILang }): Promise<IHttpWrap<Blob, Blob>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/download/rd_status`),
      method: 'GET',
      params: {
        drug_id: params.id,
        language: params.lang,
      },
      responseType: 'blob',
    })
  }

  async getTargetPhaseByDrugTypeAndDrug(params: any): Promise<IHttpWrap<ITwoDimensionAggData>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug/aggregation_with_sort'),
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getDrugDiseaseHighestPhaseDiseaseList(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug/highest_phase_disease'),
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugAggregationSingleResult))
  }

  async getDrugStructure(casNo: string): Promise<IHttpWrap<{ structure: IStructureItem[] }>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/structure/${casNo}`),
      cache: true,
      params: {
        base64: true,
        width: 300,
        height: 300,
      },
    })
  }

  async getBatchDrugStructure(casNo: string[]): Promise<IHttpWrap<{ structure: IStructureItem[] }>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/structure/batch`),
      cache: true,
      data: {
        base64: true,
        width: 1000,
        height: 1000,
        cas_no: casNo,
      },
    })
  }

  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/drug/aggregation_targeting_moiety`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getDrugCardData({ id }: { id: string }) {
    return this.getCardInfo(id)
  }
}
