import { E_AGGREGATION_TYPE, E_QUERY_ITEM_CONDITION, IAggregationItem, IAggregationParams } from '@patsnap/synapse_common_interface'
import { BasicHorizontalBarChart, getChartColor } from '@pharmsnap/shared/chart'
import { useChart } from '@pharmsnap/shared/composition'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { useNavList } from '@pharmsnap/shared/composition/useNavList'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IHistorySearchParams, IUsePieChartItem } from '@pharmsnap/shared/types'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $classes from './BTopCountryChart.module.scss'

export const BTopCountryChart = defineComponent({
  name: 'BTopCountryChart',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    aggregationField: {
      required: true,
      type: String as PropType<'country_id'>,
    },
    title: {
      required: true,
      type: String,
    },
    desc: {
      type: String,
    },
    limit: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  setup(props) {
    const localeData = useLocale()
    const loading = ref(true)
    const ins = getCurrentInstance()
    /**
     * 药物数量限制
     */
    const drugLimit = 5000

    const countryData = ref<IUsePieChartItem[]>([])

    const chartOption = computed<BasicHorizontalBarChart>(() => {
      const opt: BasicHorizontalBarChart = {
        type: 'horizontal-bar',
        dataset: {
          dimensions: [{ name: 'name' }, { name: 'value' }],
          source: countryData.value,
        },
        xAxis: {
          dataType: {
            valueType: 'count',
          },
          name: ins?.proxy.$tc('Analysis.count'),
        },
        yAxis: {
          type: 'category',
          inverse: true,
        },
        barWidth: 16,
        inside: true,
        labelPadding: [0, 0, 10, 0],
        series: [
          {
            encode: {
              x: 'count',
              y: 'name',
            },
            cursor: 'pointer',
            itemStyle: {
              color: (params) => colorsMap.value[params.dataIndex],
            },
          },
        ],
      }

      return opt
    })

    const getChartData = async () => {
      const aggregationItem: IAggregationItem = {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: props.aggregationField,
      }
      if (props.limit) {
        aggregationItem.limit = props.limit
      }
      const aggParams: IAggregationParams = {
        data_type: props.queryService.state.data_type,
        query: { ...props.queryService.state.query, type: 'group' },
        aggregation: [aggregationItem],
      }
      loading.value = true
      const res = await sharedCtx.service.drug.getDrugDevOrgAggregation(aggParams)
      if (res.success) {
        const aggData = res.data.aggregation_result.find((r) => r.aggregation_field === props.aggregationField)
        const d = aggData?.items ?? []
        countryData.value = d.map((o) => {
          return {
            name: localeData.locale.value === 'cn' ? o.display_name_cn : o.display_name_en,
            value: o.count,
            originData: o,
          }
        })
      }
      loading.value = false
    }

    const { navToList } = useNavList({
      navList: 'drug_group_organization',
      auth: false,
    })

    const onClickChart = (params: any) => {
      const { originData } = params.data
      if (!originData) return
      const { key, display_name_en, display_name_cn } = originData
      if (props.total && props.total > drugLimit) {
        return showSingleToast({
          message: ins?.proxy.$t('Analysis.limitWarning', { limit: drugLimit }) as string,
          type: 'warning',
        })
      }

      const searchParams: IHistorySearchParams = {
        data_type: 'drug',
        hidden_flag: true,
        query: {
          type: 'group',
          must: [...props.queryService.state.query.must, ...props.queryService.state.query.filter],
          filter: [
            {
              condition: E_QUERY_ITEM_CONDITION.ANY,
              fields: ['country_id'],
              type: 'field',
              value: [
                {
                  type: 'text',
                  value: key,
                  display_name_cn,
                  display_name_en,
                },
              ],
            },
          ],
        },
        org_roll_up: false,
      }

      navToList(searchParams)
    }

    const { chartContainer, options } = useChart(chartOption, {
      autoResize: true,
      registerEvent: (instance) => {
        instance.on('click', onClickChart)
      },
    })

    const isEmpty = computed(() => !countryData.value.length)

    const colorsMap = computed(() => getChartColor(props.limit || 5))

    watch(
      () => props.queryService.state.query,
      () => {
        getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return { chartContainer, isEmpty, loading, options }
  },
  render() {
    return (
      <div>
        <BCardContainer
          style={{ height: '400px' }}
          isLoading={this.loading}
          isEmpty={this.isEmpty}
          title={this.title}
          desc={this.desc}
          titleClass={$classes.title}
        >
          <div ref="chartContainer" style="height: 100%;"></div>
        </BCardContainer>
      </div>
    )
  },
})
