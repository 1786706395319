import type { IBannerInfoData, IGoods, IStrapiRes, IVideoConfigData } from '@pharmsnap/shared/types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class StrapiApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getBannerInfo(): Promise<IHttpWrap<IBannerInfoData>> {
    const rt: IHttpWrap<IBannerInfoData> = await this.http({
      method: 'GET',
      url: this.getUrl('ad-home-banner'),
    })
    if (rt.success) {
      rt.data.data.ads = rt.data.data.ads.filter((item) => item.pc_img && item.is_active)
    }
    return rt
  }

  async getKgVideo(): Promise<IHttpWrap<IVideoConfigData>> {
    return this.http({
      method: 'GET',
      url: this.getUrl('kg-video-example'),
    })
  }

  async getGoodsList(): Promise<IHttpWrap<IStrapiRes<IGoods[]>>> {
    return this.http({
      method: 'GET',
      url: this.getUrl('goodss'),
    })
  }
}
