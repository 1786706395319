import { IDiseaseCardInfo, IDrugCardInfo, IOrganizationCardInfo, IQueryDataEntityType, ITargetCardInfo } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { BDiseaseCard } from './BDiseaseCard/BDiseaseCard'
import { BDrugCard } from './BDrugCard/BDrugCard'
import { BOrganizationCard } from './BOrganizationCard/BOrganizationCard'
import { BTargetCard } from './BTargetCard/BTargetCard'

export type EntityCardType = Exclude<IQueryDataEntityType, 'mechanism'>
export type EntityCardData<T extends EntityCardType> = T extends 'drug'
  ? IDrugCardInfo
  : T extends 'disease'
  ? IDiseaseCardInfo
  : T extends 'organization'
  ? IOrganizationCardInfo
  : T extends 'target'
  ? ITargetCardInfo
  : never

export const BEntityUICard = defineComponent({
  name: 'BEntityUICard',
  props: {
    type: {
      type: String as PropType<EntityCardType>,
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object as PropType<EntityCardData<EntityCardType>>,
    },
  },
  render() {
    if (this.type === 'disease') {
      return (
        <BDiseaseCard
          style={{ width: `${this.width}px` }}
          data={this.data as IDiseaseCardInfo}
          direction={this.direction}
          border={this.border}
        ></BDiseaseCard>
      )
    } else if (this.type === 'drug') {
      return (
        <BDrugCard style={{ width: `${this.width}px` }} data={this.data as IDrugCardInfo} direction={this.direction} border={this.border}></BDrugCard>
      )
    } else if (this.type === 'organization') {
      return (
        <BOrganizationCard
          style={{ width: `${this.width}px` }}
          data={this.data as IOrganizationCardInfo}
          direction={this.direction}
          border={this.border}
        ></BOrganizationCard>
      )
    } else if (this.type === 'target') {
      return (
        <BTargetCard
          style={{ width: `${this.width}px` }}
          data={this.data as ITargetCardInfo}
          direction={this.direction}
          border={this.border}
        ></BTargetCard>
      )
    }

    return <div></div>
  },
})
