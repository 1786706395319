import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.CLINICAL_TRIAL_FINDER,
}

export default [
  {
    path: `clinical-progress`,
    redirect: () => {
      return {
        name: E_ROUTER_NAME.CLINICAL_FINDER,
      }
    },
  },
  {
    path: `clinical-progress-finder`,
    name: E_ROUTER_NAME.CLINICAL_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical/clinicalprogress/ClinicalProgress'),
    meta: {
      ...meta,
      title: createWebTitle('临床进展', 'Clinical Progress'),
      hideQuickSearch: true,
    },
  },
  {
    path: `clinical-progress-list`,
    name: E_ROUTER_NAME.CLINICAL_PROGRESS_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical/ClinicalList'),
    meta: {
      ...meta,
      title: createWebTitle('临床进展检索结果', 'Clinical Progress Search Results'),
      notWide: true,
    },
  },
  {
    path: `clinical-progress-detail/:${E_ROUTER_PARAMS.CLINICAL_TRIAL_ID}`,
    name: E_ROUTER_NAME.CLINICAL_PROGRESS_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/clinical/ClinicalDetail'),
    meta: {
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.CLINICAL_DETAIL,
    },
  },
] as RouteConfig[]
