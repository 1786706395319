import companySvg from '../../assets/svg/whiteCompany.svg'
import diseaseSvg from '../../assets/svg/whiteDisease.svg'
import drugSvg from '../../assets/svg/whiteDrug.svg'
import targetSvg from '../../assets/svg/whiteTarget.svg'
import { E_KNOWLEDGE_COLOR, E_KNOWLEDGE_DONUT_DARK_COLOR, E_KNOWLEDGE_DONUT_LIGHT_COLOR, E_VERTEX_TYPE } from '../../types'

export const kGNodeColorMap = {
  [E_VERTEX_TYPE.DRUG]: E_KNOWLEDGE_COLOR.DRUG,
  [E_VERTEX_TYPE.DISEASE]: E_KNOWLEDGE_COLOR.DISEASE,
  [E_VERTEX_TYPE.TARGET]: E_KNOWLEDGE_COLOR.TARGET,
  [E_VERTEX_TYPE.ORGANIZATION]: E_KNOWLEDGE_COLOR.ORGANIZATION,
}

export const kGIconMap = {
  [E_VERTEX_TYPE.DRUG]: drugSvg,
  [E_VERTEX_TYPE.DISEASE]: diseaseSvg,
  [E_VERTEX_TYPE.TARGET]: targetSvg,
  [E_VERTEX_TYPE.ORGANIZATION]: companySvg,
}

export const kGDonutLightColorMap = {
  [E_VERTEX_TYPE.DRUG]: E_KNOWLEDGE_DONUT_LIGHT_COLOR.DRUG,
  [E_VERTEX_TYPE.DISEASE]: E_KNOWLEDGE_DONUT_LIGHT_COLOR.DISEASE,
  [E_VERTEX_TYPE.TARGET]: E_KNOWLEDGE_DONUT_LIGHT_COLOR.TARGET,
  [E_VERTEX_TYPE.ORGANIZATION]: E_KNOWLEDGE_DONUT_LIGHT_COLOR.ORGANIZATION,
}

export const kGDonutDarkColorMap = {
  [E_VERTEX_TYPE.DRUG]: E_KNOWLEDGE_DONUT_DARK_COLOR.DRUG,
  [E_VERTEX_TYPE.DISEASE]: E_KNOWLEDGE_DONUT_DARK_COLOR.DISEASE,
  [E_VERTEX_TYPE.TARGET]: E_KNOWLEDGE_DONUT_DARK_COLOR.TARGET,
  [E_VERTEX_TYPE.ORGANIZATION]: E_KNOWLEDGE_DONUT_DARK_COLOR.ORGANIZATION,
}
