/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlowFunction } from '@pharmsnap/shared/types'
import { YAXisOption } from 'echarts/types/dist/shared'
import { curry } from 'lodash'
import { IECAxisDataType } from '..'
import { ECOption, Plot } from '../core/plot'
import { BasicDuplexChart } from '../type'
import { flow, mergeSeries, mergeSeriesBar, mergeTooltip, mergeXAxis, mergeYAxis } from '../util'
import { adjustYAxisLabelFormatter, generateRectTooltipFormatter, getBarSeries, getSeries, getTooltip, getXAxis, getYAxis } from './config'

export class Duplex extends Plot {
  public type = 'duplex'

  protected getDefaultOption(options: ECOption): ECOption {
    const mergeRectChartXAxis: FlowFunction<ECOption> = curry(mergeXAxis)(getXAxis)
    const mergeRectChartYAxis: FlowFunction<ECOption> = curry(mergeYAxis)(getYAxis)
    const mergeRectTooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const mergeRectBarSeries: FlowFunction<ECOption> = curry(mergeSeriesBar)(getBarSeries)
    const mergeRectSeries: FlowFunction<ECOption> = curry(mergeSeries)(getSeries)
    const chartOptions = flow<ECOption>(mergeRectTooltip, mergeRectChartXAxis, mergeRectChartYAxis, mergeRectBarSeries, mergeRectSeries)(options)
    return chartOptions
  }

  protected getYAxisValueTypes(params: BasicDuplexChart) {
    const { leftYAxis, rightYAxis } = params
    const { series } = params
    const adjustedSeries = series.map((i) => ({ ...i, yAxisIndex: i.yAxisIndex === 'left' ? 0 : 1 }))
    return adjustedSeries.map((item) => (item.yAxisIndex === 0 ? leftYAxis.dataType : rightYAxis.dataType)).filter((i) => !!i) as IECAxisDataType[]
  }

  // @ts-ignore
  protected adaptor(params: BasicDuplexChart): ECOption {
    const { color, tooltip, legend, grid, leftYAxis, rightYAxis, xAxis, series, dataset } = params

    const mockLeftYAxisOption: ECOption = {
      yAxis: [leftYAxis.dataType && leftYAxis.dataType.valueType === 'count' ? { ...leftYAxis, minInterval: 1 } : leftYAxis],
    }

    const mockRightYAxisOption: ECOption = {
      yAxis: [rightYAxis.dataType && rightYAxis.dataType.valueType === 'count' ? { ...rightYAxis, minInterval: 1 } : rightYAxis],
    }

    const yAxis: YAXisOption[] = (
      adjustYAxisLabelFormatter((leftYAxis.dataType && leftYAxis.dataType.valueType) || 'count', mockLeftYAxisOption).yAxis as YAXisOption[]
    ).concat(
      adjustYAxisLabelFormatter((rightYAxis.dataType && rightYAxis.dataType.valueType) || 'count', mockRightYAxisOption).yAxis as YAXisOption[]
    )

    yAxis[1].splitLine = { show: false }

    const adjustedSeries = series.map((i) => ({ ...i, yAxisIndex: i.yAxisIndex === 'left' ? 0 : 1 }))
    // @ts-ignore
    const options: ECOption = {
      ...(color ? { color } : undefined),
      grid,
      tooltip: {
        ...tooltip,
        formatter: generateRectTooltipFormatter(this.getYAxisValueTypes(params)),
      },
      legend,
      yAxis,
      xAxis: [xAxis],
      series: adjustedSeries,
      dataset,
    }

    return options
  }
}
