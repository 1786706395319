import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { defineComponent, PropType } from '@vue/composition-api'
import { isUndefined } from 'lodash'
import { useLocale } from '../../../composition/useLocale'
import { IDrugDetail, IGTableColumnsProps } from '../../../types'
import { GTable } from '../../ui/GTable/GTable'
import {
  BDrugAdcAntibodyField,
  BDrugAdcAntibodySequenceTrigger,
  BDrugAdcAntibodyTypeField,
  BDrugAdcConjugationSiteField,
  BDrugAdcDarField,
  BDrugAdcLinkerField,
  BDrugAdcLinkerTypeField,
  BDrugAdcPayloadField,
  BDrugAdcSiteSpecialField,
  BDrugAdcSourceField,
  BDrugAdcStructureTrigger,
} from './BDrugAdcField'

export const BDrugAdcFieldTable = defineComponent({
  name: 'BDrugAdcFieldTable',
  props: {
    data: {
      type: Object as PropType<IDrugDetail>,
      required: true,
    },
    dialogEnable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { localeUpcase } = useLocale()

    function onSequenceSimilar(sequence_hash_id: string) {
      ctx.emit('sequenceSimilar', sequence_hash_id)
    }
    function onStructureSimilar(inchiKey: Exclude<string, ''>) {
      ctx.emit('structureSimilar', inchiKey)
    }

    return {
      localeUpcase,
      onSequenceSimilar,
      onStructureSimilar,
    }
  },
  render() {
    const tableData = [this.data]

    const columns: (IGTableColumnsProps<IDrugDetail> & { show?: () => boolean | boolean })[] = [
      {
        label: this.$tc('synapse_i18n.drug.adc.antibody'),
        minWidth: '160px',
        renderCell: (h, item) => {
          // 当XDC药物同时存在自身结构和 偶联类型结构时，保留自身结构，去除偶联类型下的「查看序列」按钮；若XDC无自身序列，但是偶联药物有序列，则保留「查看序列」按钮。
          // From: https://confluence.zhihuiya.com/display/DLS/SNP+202406+Online+UAT 第33点
          const sequence = !item.row.sequence?.length ? item.row.antibody_drug_id_view?.sequence : []
          const hasAntibody = item.row.antibody_drug_id_view
          const renderList = [
            hasAntibody ? <BDrugAdcAntibodyField class="leading-6 text-sm" data={item.row}></BDrugAdcAntibodyField> : null,
            sequence?.length ? (
              <BDrugAdcAntibodySequenceTrigger
                sequence={sequence}
                triggerText={this.localeUpcase === 'CN' ? '查看序列' : 'View Sequence'}
                onClickSimilar={this.onSequenceSimilar}
              ></BDrugAdcAntibodySequenceTrigger>
            ) : null,
          ]
            .filter((item) => Boolean(item))
            .map((item, index) => <div class={{ 'mt-2': index > 0 }}>{item}</div>)
          if (!renderList.length) {
            return <span>{EMPTY_PLACEHOLDER}</span>
          }
          return <div style="line-height: 0;">{renderList}</div>
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.linker'),
        minWidth: '160px',
        renderCell: (h, item) => {
          if (!item.row.linker_id_view) return <span>{EMPTY_PLACEHOLDER}</span>

          return (
            <div style="line-height: 0;">
              <div class="text-sm leading-6 text-text-t1">
                <BDrugAdcLinkerField data={item.row}></BDrugAdcLinkerField>
              </div>
              {item.row.structure?.filter((i) => i.structure_type === 'LINKER')?.length && this.dialogEnable ? (
                <div class="mt-2">
                  <BDrugAdcStructureTrigger
                    field="linker_id_view"
                    data={item.row}
                    onClickSimilar={this.onStructureSimilar}
                    onCasStructureItemClicked={(index: number) => this.$emit('linkerStructureItemClicked', index)}
                    triggerText={this.localeUpcase === 'CN' ? '查看结构' : 'View Structure'}
                  ></BDrugAdcStructureTrigger>
                </div>
              ) : null}
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.payload'),
        minWidth: '160px',
        renderCell: (h, item) => {
          if (!item.row.payload_id_view) return <span>{EMPTY_PLACEHOLDER}</span>
          return (
            <div style="line-height: 0;">
              <div class="text-sm leading-6 text-text-t1">
                <BDrugAdcPayloadField data={item.row}></BDrugAdcPayloadField>
              </div>
              {item.row.structure?.filter((i) => i.structure_type === 'PAYLOAD')?.length && this.dialogEnable ? (
                <div class="mt-2">
                  <BDrugAdcStructureTrigger
                    field="payload_id_view"
                    data={item.row}
                    onClickSimilar={this.onStructureSimilar}
                    onCasStructureItemClicked={(index: number) => this.$emit('casStructureItemClicked', index)}
                    triggerText={this.localeUpcase === 'CN' ? '查看结构' : 'View Structure'}
                  ></BDrugAdcStructureTrigger>
                </div>
              ) : null}
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.dar'),
        minWidth: '80px',
        renderCell: (h, item) => {
          if (isUndefined(item.row.dar)) return <span>{EMPTY_PLACEHOLDER}</span>

          return (
            <div style="line-height: 0;">
              <span class="text-sm leading-6 text-text-t1">
                <BDrugAdcDarField data={item.row} />
              </span>
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.antibodyType'),
        minWidth: '160px',
        show: () => tableData.some((item) => item.antibody_type_view),
        renderCell: (h, item) => {
          if (!item.row.antibody_type_view) return <span>{EMPTY_PLACEHOLDER}</span>
          return (
            <div style="line-height: 0;">
              <span class="text-sm leading-6 text-text-t1">
                <BDrugAdcAntibodyTypeField data={item.row}></BDrugAdcAntibodyTypeField>
              </span>
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.conjugationSite'),
        minWidth: '160px',
        renderCell: (h, item) => {
          if (!item.row.site_id_view) return <span>{EMPTY_PLACEHOLDER}</span>
          return (
            <div style="line-height: 0;">
              <span class="text-sm leading-6 text-text-t1">
                <BDrugAdcConjugationSiteField data={item.row}></BDrugAdcConjugationSiteField>
              </span>
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.siteSpecial'),
        minWidth: '80px',
        renderCell: (h, item) => {
          if (isUndefined(item.row.specific_site)) return <span>{EMPTY_PLACEHOLDER}</span>
          return (
            <div style="line-height: 0;">
              <span class="text-sm leading-6 text-text-t1">
                <BDrugAdcSiteSpecialField data={item.row}></BDrugAdcSiteSpecialField>
              </span>
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.linkerType'),
        minWidth: '160px',
        renderCell: (h, item) => {
          if (!item.row.link_type_id_view) return <span>{EMPTY_PLACEHOLDER}</span>
          return (
            <div style="line-height: 0;">
              <span class="text-sm leading-6 text-text-t1">
                <BDrugAdcLinkerTypeField data={item.row}></BDrugAdcLinkerTypeField>
              </span>
            </div>
          )
        },
      },
      {
        label: this.$tc('synapse_i18n.drug.adc.source'),
        minWidth: '100px',
        renderCell: (h, item) => {
          return (
            <div style="line-height: 0;">
              <BDrugAdcSourceField
                class="text-sm leading-6"
                data={item.row}
                triggerText={this.localeUpcase === 'CN' ? '查看' : 'View'}
              ></BDrugAdcSourceField>
            </div>
          )
        },
      },
    ]
    const filteredColumns = columns.filter((column) => {
      if ('show' in column) {
        return typeof column.show === 'function' ? column.show() : column.show
      }
      return true
    })

    return <GTable data={tableData} columns={filteredColumns} border></GTable>
  },
})
