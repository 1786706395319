import { IQuery } from '@patsnap/synapse_common_interface'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { computed, Ref, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { IPatentStatus, IPatentViewFieldType } from '../types'
import { getGrantedFilterItem } from '../utils'

export function usePatentAnalysis<P extends { query: IQuery }, T>(
  params: Ref<P>,
  config: {
    requestFn: (params: P) => Promise<IHttpWrap<T>>
    checkEmptyFn: (data?: T) => boolean
    successCallback?: (data: T) => void
  }
) {
  const { requestFn, checkEmptyFn, successCallback } = config
  const selectedPatentStatus = ref<IPatentStatus>('application')
  const isFullScreen = ref(false)
  const isLoading = ref(true)
  const popoverVisible = ref(false)
  const data = ref<T>()
  const selectedField = ref<IPatentViewFieldType>('chart')

  const requestParams = computed(() => {
    if (selectedPatentStatus.value === 'application') return params.value

    const grantedParams = cloneDeep(params.value)
    const {
      query: { filter = [] },
    } = grantedParams
    filter.push(getGrantedFilterItem())
    return grantedParams
  })
  const isEmpty = computed(() => {
    if (isLoading.value) return false
    return checkEmptyFn(data.value)
  })

  const changePatentStatus = (val: IPatentStatus) => (selectedPatentStatus.value = val)
  const toggleFullScreen = (val: boolean) => (isFullScreen.value = val)
  const togglePopoverVisible = (val: boolean) => (popoverVisible.value = val)
  const toggleSelectedField = (val: IPatentViewFieldType) => (selectedField.value = val)

  const getChartData = async () => {
    isLoading.value = true

    const res = await requestFn(requestParams.value)
    if (res.success) {
      data.value = res.data
      successCallback && successCallback(res.data)
    }

    isLoading.value = false
  }

  return {
    isLoading,
    isFullScreen,
    popoverVisible,
    isEmpty,
    data,
    selectedPatentStatus,
    selectedField,
    changePatentStatus,
    toggleSelectedField,
    toggleFullScreen,
    togglePopoverVisible,
    getChartData,
  }
}
