import { translationalMedicineIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { IAggregationData, IAggregationValue, ITranslationalMedicineIndexField } from '@patsnap/synapse_common_interface'
import { getLangDegraded, toThousands } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sortSubject } from '@pharmsnap/shared/src/utils'
import { computed, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { GridColumns } from 'vxe-table'

export interface ITransMedicineThreeDimTableItem {
  oneDim: string
  oneDimRowSpan: number
  twoDim: string
  twoDimRowSpan: number
  threeDim: string
  count: string
}

export function useAnalysisTransMedicineThreeDimTable(options: {
  aggData: MaybeRef<IAggregationData>
  isEmpty: MaybeRef<boolean>
  oneDimName: MaybeRef<string>
  countName: MaybeRef<string>
  twoDimName: MaybeRef<string>
}) {
  const { locale } = useLocale()
  const { aggData, isEmpty, oneDimName, countName, twoDimName } = options

  const threeDimAggField = computed(
    () => unref(aggData)?.aggregation_result?.[0]?.items?.[0]?.aggregation_result?.[0]?.items?.[0]?.aggregation_result?.[0]?.aggregation_field
  )

  // 三维聚合类目名称，从转换医学语言包获取
  const threeDimName = computed(() => {
    return translationalMedicineIndexFieldLangMap[unref(threeDimAggField) as ITranslationalMedicineIndexField]?.[unref(locale)] || ''
  })

  const aggItems = computed<IAggregationValue[] | null>(() => {
    if (unref(isEmpty)) {
      return null
    }
    return unref(aggData).aggregation_result[0].items
  })

  const tableList = computed<ITransMedicineThreeDimTableItem[]>(() => {
    const list: ITransMedicineThreeDimTableItem[] = []
    if (aggItems.value) {
      aggItems.value.forEach((oneDimItem) => {
        const twoDimAggData = oneDimItem.aggregation_result?.[0]?.items || []

        twoDimAggData.forEach((twoDimItem) => {
          const threeDimAggData = [...(twoDimItem.aggregation_result?.[0]?.items || [])]
          threeDimAggData.sort((a, b) => sortSubject(a.key, b.key))
          threeDimAggData.forEach((threeDimItem) => {
            list.push({
              oneDim: getLangDegraded({ name_cn: oneDimItem.display_name_cn, name_en: oneDimItem.display_name_en }, locale.value) || oneDimItem.key,
              oneDimRowSpan: 1,
              twoDim: getLangDegraded({ name_cn: twoDimItem.display_name_cn, name_en: twoDimItem.display_name_en }, locale.value),
              twoDimRowSpan: 1,
              threeDim: getLangDegraded({ name_cn: threeDimItem.display_name_cn, name_en: threeDimItem.display_name_en }, locale.value),
              count: toThousands(threeDimItem.count),
            })
          })
        })
      })
    }
    return list
  })

  const tableColumn = computed<GridColumns[]>(() => {
    return [
      {
        field: 'oneDim',
        title: unref(oneDimName),
        width: '25%',
      },
      {
        field: 'twoDim',
        title: unref(twoDimName),
      },
      {
        field: 'threeDim',
        title: unref(threeDimName),
        width: '25%',
      },
      {
        field: 'count',
        title: unref(countName),
        width: '15%',
      },
    ]
  })

  return {
    tableList,
    tableColumn,
  }
}
