// import { debounce } from 'lodash'
import type { IACListItem } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { isRootConfig } from '@pharmsnap/shared/config'
import { ElInput, ElPopover } from '@pharmsnap/shared/element-ui'
import { createObservable } from '@pharmsnap/shared/service/http/create-observable'
import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { IAutoCompleteParams, IAutoCompleteRes, IAutoCompleteType, IListItem } from '@pharmsnap/shared/types'
import { convertDataStrategyMap, getAutoCompleteList, getLangDegraded, getStringLength } from '@pharmsnap/shared/utils'
import { ComponentInternalInstance, defineComponent, getCurrentInstance, onUnmounted, PropType, reactive, toRefs, watch } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { debounceTime, filter, Subject, switchMap } from 'rxjs'
import VClickOutside from 'v-click-outside'
import { VNodeData } from 'vue'
import '../../../../assets/icon-svg/closeSmall.svg'
import { BAcList } from '../../BAcList/BAcList'
import $classes from './BAcOnlyInput.module.scss'

export const BAcOnlyInput = defineComponent({
  name: 'BAcOnlyInput',
  model: {
    prop: 'keywords',
    event: 'input',
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    placeholder: {
      type: String,
    },
    popperClass: {
      type: String,
      default: '',
    },
    inputBlurClass: {
      type: String,
      default: '',
    },
    viewDetailLabel: {
      type: String,
    },
    keywords: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<IAutoCompleteType>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popoverPlacement: {
      type: String,
      default: 'bottom-start',
    },
    customFetchAutoComplete: {
      type: Function as PropType<(params: IAutoCompleteParams) => Promise<IHttpWrap<IAutoCompleteRes>>>,
    },
    customShowIcon: {
      type: Function as PropType<(type: IAutoCompleteType) => boolean>,
    },
    customTagBoxCls: {
      type: String,
      default: '',
    },
    hidePopperWhenEnter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const input$ = new Subject<string>()
    const data = reactive<{
      displayKeywords: string
      keyword: string
      visible: boolean
      loading: boolean
      items: IACListItem[]
      focus: boolean
    }>({
      displayKeywords: props.keywords || '',
      keyword: '',
      visible: false,
      loading: false,
      items: [],
      focus: false,
    })

    const ins = getCurrentInstance() as ComponentInternalInstance
    const localeData = useLocale()

    const handleKeyDown = (e: KeyboardEvent) => {
      e.stopPropagation()
      emit('keyDown', e, data.keyword)
      if (e.keyCode === 13) {
        if (props.hidePopperWhenEnter) {
          data.visible = false
        }
        data.keyword = ''
      }
    }

    const handleInput = (keyword: string) => {
      data.displayKeywords = keyword
      changeKeyword()
      emit('input', keyword)
    }

    const handleClear = () => {
      data.keyword = ''
      data.displayKeywords = ''
      emit('clear')
      emit('input', '')
    }

    const handleBlur = () => {
      // 因为blur会先触发，所以设置一个延时
      setTimeout(() => {
        data.visible = false
      }, 400)
    }

    const handelClickOutside = () => {
      data.focus = false
    }

    const handleMouseup = () => {
      handleFocusContainer()
    }

    const handleViewDetail = (item: IListItem) => {
      emit('viewDetail', item)
    }

    const changeKeyword = () => {
      data.keyword = data.displayKeywords
    }

    // changeKeyword = debounce(changeKeyword, 300)

    const renderLoadingState = () => {
      return ins.proxy.$slots.loadingComp ?? <div v-ls-loading={data.loading} class={'h-10'}></div>
    }

    const selectItem = (item: IListItem) => {
      const name = getLangDegraded(
        item,
        props.type === 'ADC_Antibody' ||
          props.type === 'ADC_Linker' ||
          props.type === 'ADC_Payload' ||
          props.type === 'ADC_Antibody_Type' ||
          props.type === 'ADC_SITE' ||
          props.type === 'DATA_SOURCE_MEETING'
          ? 'en'
          : localeData.locale.value
      )
      data.displayKeywords = name
      emit('selectItem', item)
      emit('input', name)
    }

    const renderContentState = () => {
      const vNodeData: VNodeData = {
        props: {
          items: data.items,
          viewDetailLabel: props.viewDetailLabel,
        },
        on: {
          selectItem: selectItem,
          mouseup: handleMouseup,
          viewDetail: handleViewDetail,
        },
      }
      return <BAcList {...vNodeData}></BAcList>
    }

    const renderContent = () => {
      if (!data.displayKeywords && ins.proxy.$scopedSlots.customInputTip) {
        return ins.proxy.$scopedSlots.customInputTip?.({})
      }
      if (data.loading) {
        return renderLoadingState()
      }
      if (data.items.some((o) => o.data.length)) {
        return renderContentState()
      }
    }

    const handleFocusContainer = () => {
      data.focus = true
      if (!data.displayKeywords && ins.proxy.$scopedSlots.customInputTip) {
        data.visible = true
      }
      const input = ins.proxy.$refs.cusInput as HTMLInputElement
      input.focus()
    }

    const renderTagsContainer = () => {
      const vNodeData: VNodeData = {
        props: {
          clearable: false,
          value: data.displayKeywords,
        },
        attrs: {
          placeholder: data.focus ? '' : props.placeholder,
        },
        on: {
          input: handleInput,
          clear: handleClear,
          blur: handleBlur,
        },
        nativeOn: {
          keydown: handleKeyDown,
        },
      }
      return (
        <div slot="reference">
          {props.disabled && <div class="absolute w-full h-full bg-gray-60 opacity-10 z-10 cursor-not-allowed"></div>}
          <div
            class={data.focus ? [$classes.tagBoxFocus, props.customTagBoxCls] : [$classes.tagBox, props.customTagBoxCls]}
            onClick={handleFocusContainer}
          >
            {ins.slots.prefix}
            <div class={$classes.content}>
              <span class={$classes.spanContainer}>
                <ElInput {...vNodeData} ref="cusInput" class={$classes.acInput} data-testid="b-ac-input"></ElInput>
              </span>
            </div>
            {ins.slots.suffix}
            {ins.slots.searchRes}
            {data.displayKeywords ? (
              <div class={'flex items-center'} onClick={handleClear}>
                <PtIcon class={'text-2xl cursor-pointer'} icon={'CloseSmall'}></PtIcon>
              </div>
            ) : null}
          </div>
        </div>
      )
    }

    function init() {
      const result$ = input$
        .pipe(
          debounceTime(100),
          filter((text) => {
            if (getStringLength(text) > 1) {
              data.visible = true
              data.loading = true
              return true
            }
            return false
          }),
          switchMap((text) => {
            const params: IAutoCompleteParams = {
              keywords: text,
              key: props.type,
              limit: 10,
            }
            if (isRootConfig.includes(props.type)) {
              params.is_root = true
            }
            return createObservable<IAutoCompleteParams, IAutoCompleteRes>(props.customFetchAutoComplete || getAutoCompleteList, params)
          })
        )
        .subscribe((res) => {
          data.items = []
          if (res?.success && res.data) {
            const resData = res.data
            const func = convertDataStrategyMap.get(props.type)

            // 记得改 BAcBase 文件
            const hideIconTypes: Array<typeof props.type> = [
              'ADC_Linker',
              'ADC_Payload',
              'ADC_Antibody_Type',
              'ADC_SITE',
              'PATENT_TECHNOLOGY',
              'DATA_SOURCE_MEETING',
            ]
            if (func) {
              const result: IACListItem[] =
                func({
                  data: resData,
                  locale: localeData.locale.value,
                  keywords: data.keyword,
                  showIcon: props.customShowIcon ? props.customShowIcon(props.type) : !hideIconTypes.includes(props.type),
                  itemType: props.type,
                }) ?? []
              data.loading = false
              data.items = result
              if (data.items.some((o) => o.data.length) && data.keyword) {
                data.visible = true
              } else {
                data.visible = false
              }
            }
          } else {
            data.visible = false
            data.loading = false
          }
        })

      onUnmounted(() => {
        result$.unsubscribe()
      })
    }

    init()

    watch(
      () => data.keyword,
      async () => {
        input$.next(data.keyword.trim())
      }
    )

    watch(
      () => props.keywords,
      () => {
        if (!props.keywords) {
          data.keyword = ''
          data.displayKeywords = ''
        }
      }
    )

    return { ...toRefs(data), renderTagsContainer, handelClickOutside, renderContent }
  },
  render() {
    return (
      <ElPopover
        placement={this.popoverPlacement}
        class={this.focus ? `${$classes.container}` : `${$classes.containerBlur} ${this.inputBlurClass}`}
        trigger="manual"
        transition="none"
        value={this.visible}
        popper-class={this.popperClass}
        appendToBody={true}
        visibleArrow={false}
        {...{
          directives: [
            {
              name: 'click-outside',
              value: this.handelClickOutside,
            },
          ],
        }}
      >
        {this.renderContent()}
        {this.renderTagsContainer()}
      </ElPopover>
    )
  },
})
