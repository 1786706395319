import { Ref, ref, toRaw, unref, UnwrapRef, watch, watchEffect } from '@vue/composition-api'
import { getValueByConsumeFunc } from '../utils'

function hasValue<T>(value: T) {
  return value !== undefined
}

/** 源码参考 antdv 3.0 以及 react-component/utils */
export function useMergedState<T, R = Ref<T>>(
  defaultStateValue: T | (() => T),
  option?: {
    defaultValue?: T | (() => T)
    value?: Ref<T | undefined> | Ref<UnwrapRef<T | undefined>>
    onChange?: (val: T, prevValue: T) => void
    postState?: (val: T) => T
  }
): [R, (val: T) => void] {
  const { defaultValue, value = ref() } = option || {}
  let initValue: T | undefined
  if (hasValue(value.value)) {
    initValue = unref(value)
  } else if (hasValue(defaultValue)) {
    initValue = getValueByConsumeFunc(defaultValue)
  } else {
    initValue = getValueByConsumeFunc(defaultStateValue)
  }

  const innerValue = ref(initValue) as Ref<T>
  const mergedValue = ref(initValue) as Ref<T>
  // 不要把这个改成 async, 否则应用的地方可能会出现时序错误（宏微任务
  watchEffect(() => {
    let val = value.value !== undefined ? value.value : innerValue.value
    if (option?.postState) {
      val = option.postState(val as T)
    }
    mergedValue.value = val as T
  })

  function triggerChange(newValue: T) {
    const preVal = mergedValue.value
    innerValue.value = newValue
    if (toRaw(mergedValue.value) !== newValue && option?.onChange) {
      option.onChange(newValue, preVal)
    }
  }

  // Effect of reset value to `undefined`
  watch(value, () => {
    innerValue.value = value.value as T
  })

  return [mergedValue as unknown as R, triggerChange]
}
