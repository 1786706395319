import '@patsnap-ui/icon/assets/solid/SearchLeft.svg'
import { ElInput } from '@pharmsnap/shared/element-ui'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { Icon } from 'patsnap-biz'
import { userInjectFilterService } from '../../../BFilterService'
import { IFilterItemConfig, IFilterValue } from '../../../types'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const FilterRefineKeywords = defineComponent({
  name: 'FilterRefineKeywords',
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemConfig>,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, ctx) {
    const { field } = props.config
    const { /* state: filterState, */ actions: filterActions } = userInjectFilterService()
    // const currentValue = computed(() => filterState?.filterValueMapInTime[field])
    const currentText = ref('')
    // watch(
    //   currentValue,
    //   (value) => {
    //     if (value?.length) {
    //       const textValue = value[0].value as string
    //       if (!!textValue && textValue.trim() !== currentText.value.trim()) {
    //         currentText.value = textValue.trim()
    //       }
    //     }
    //   },
    //   { immediate: true }
    // )

    function setFilterValue() {
      const textTrimValue = currentText.value.trim()
      if (!textTrimValue) {
        return
      }
      const values: IFilterValue[] = [
        {
          display_name_cn: textTrimValue,
          display_name_en: textTrimValue,
          value: textTrimValue,
        },
      ]
      filterActions?.setFilterValue(field, values)
      currentText.value = ''
      ctx.emit('refine')
    }
    function onRefine() {
      setFilterValue()
    }
    function onKeyUp(e: KeyboardEvent) {
      if (e?.code?.toLocaleLowerCase() === 'enter') {
        setFilterValue()
      }
    }
    return { currentText, onKeyUp, onRefine }
  },
  render() {
    return (
      <ElInput
        v-model={this.currentText}
        data-testid="filter-refine-keywords"
        nativeOn={{
          keyup: this.onKeyUp,
        }}
        placeholder={this.$tc('FilterRefineKeywords.refineKeywords')}
        size="small"
      >
        <div class="h-full flex items-center" slot="suffix">
          <Icon onClick={this.onRefine} class="text-2xl cursor-pointer hover:bg-gray-30" icon="SolidSearchLeft"></Icon>
        </div>
      </ElInput>
    )
  },
})
