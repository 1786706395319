import { IHttpWrap } from '@pharmsnap/shared/service/http/types'
import { IAutoCompleteParams, IAutoCompleteRes, IAutoCompleteType, IBAcTag, IListItem } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import '../../../../assets/icon-svg/arrowDown.svg'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { BAcOnlyInput } from '../BAcOnlyInput/BAcOnlyInput'
import $classes from './BAutoEntityInput.module.scss'

export const BAutoEntityInput = defineComponent({
  model: {
    prop: 'keywords',
    event: 'input',
  },
  name: 'BAutoEntityInput',
  props: {
    keywords: {
      type: String,
      default: '',
    },
    type: {
      required: true,
      type: String as PropType<IAutoCompleteType>,
    },
    placeHolder: {
      type: String,
    },
    viewDetailLabel: {
      type: String,
    },
    suffix: {
      type: String,
    },
    prefix: {
      type: String,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    searchRes: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popoverPlacement: {
      type: String,
      default: 'bottom-start',
    },
    customPopoverCls: {
      type: String,
      default: '',
    },
    customFetchAutoComplete: {
      type: Function as PropType<(params: IAutoCompleteParams) => Promise<IHttpWrap<IAutoCompleteRes>>>,
    },
    customShowIcon: {
      type: Function as PropType<(type: IAutoCompleteType) => boolean>,
    },
    customTagBoxCls: {
      type: String,
      default: '',
    },
    hidePopperWhenEnter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleSelect = (val: IListItem) => {
      emit('select', props.type, val)
    }

    const handleInputKeyDown = (e: KeyboardEvent, keyword: string) => {
      emit('keyDown', e, keyword)
    }

    const handleClose = (val: IBAcTag) => {
      emit('close', props.type, val)
    }

    const handleViewDetail = (item: IListItem) => {
      emit('viewDetail', item)
    }

    const handleClear = () => {
      emit('clear')
    }

    const handleSuffixClick = () => {
      emit('suffixClick')
    }

    const searchPrev = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      emit('searchPrev')
    }

    const searchNext = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      emit('searchNext')
    }

    return { handleSuffixClick, handleSelect, handleInputKeyDown, handleClose, handleViewDetail, handleClear, searchPrev, searchNext }
  },
  methods: {
    renderPrefix() {
      if (this.$scopedSlots.prefix) {
        return <template slot="prefix">{this.$scopedSlots.prefix({})}</template>
      }
      if (this.prefix) {
        return (
          <template slot="prefix">
            <div class={$classes.prefixIconContainer}>
              {this.prefix && <GIcon class={$classes.prefixIcon} size={24} svgName={this.prefix}></GIcon>}
            </div>
          </template>
        )
      }
      return null
    },
    renderSuffix() {
      return this.suffix ? (
        <template slot="suffix">
          <div class={$classes.suffixIconContainer} onClick={this.handleSuffixClick}>
            <PtIcon class={$classes.suffixIcon} icon={this.suffix}></PtIcon>
          </div>
        </template>
      ) : null
    },
    renderRes() {
      return (
        <template slot="searchRes">
          {this.searchRes.length ? (
            <div class={$classes.resContainer}>
              <span class={'text-gray-450'}>{`${this.currentIndex}/${this.searchRes.length}`}</span>
              <PtIcon class={$classes.arrowIconDown} icon={'ArrowDown'} onClick={(e: Event) => this.searchPrev(e)}></PtIcon>
              <PtIcon class={$classes.arrowIconUp} icon={'ArrowDown'} onClick={(e: Event) => this.searchNext(e)}></PtIcon>
            </div>
          ) : null}
        </template>
      )
    },
  },
  render() {
    return (
      <div>
        <BAcOnlyInput
          type={this.type}
          disabled={this.disabled}
          keywords={this.keywords}
          popperClass={this.customPopoverCls || $classes.customPopover}
          popoverPlacement={this.popoverPlacement}
          customFetchAutoComplete={this.customFetchAutoComplete}
          customShowIcon={this.customShowIcon}
          customTagBoxCls={this.customTagBoxCls}
          inputBlurClass={$classes.inputBlur}
          viewDetailLabel={this.viewDetailLabel}
          hidePopperWhenEnter={this.hidePopperWhenEnter}
          onInput={(val: string) => this.$emit('input', val)}
          onSelectItem={this.handleSelect}
          onKeyDown={this.handleInputKeyDown}
          onClose={this.handleClose}
          onViewDetail={this.handleViewDetail}
          onClear={this.handleClear}
          placeholder={this.placeHolder}
          {...{
            scopedSlots: {
              customInputTip: this.$scopedSlots.customInputTip,
            },
          }}
        >
          {this.renderPrefix()}
          {this.renderSuffix()}
          {this.renderRes()}
        </BAcOnlyInput>
      </div>
    )
  },
})
