import { IQuery } from '@patsnap/synapse_common_interface'
import {
  AggLineChartEntity,
  AggPieChartEntity,
  AggStackBarChartEntity,
  AggStackLineChartEntity,
  AggTwoDimDataItem,
  AggWorldChartEntity,
  AggWorldDataItem,
} from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { ILoadingService, ISearchResult } from '@pharmsnap/shared/src/types'
import { getTimeRelatedQueryField } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, PropType, reactive, ref, unref, watch } from '@vue/composition-api'
import { isEqual, noop } from 'lodash'
import {
  BAnalysisChartCard,
  BAnalysisChartFullScreenCard,
  BAnalysisLineEchart,
  BAnalysisLineTable,
  BAnalysisPieEchart,
  BAnalysisPieTable,
  BAnalysisStackBarEchart,
  BAnalysisStackLineEchart,
  BAnalysisTwoDimStackTable,
  BAnalysisWorldEchart,
  BAnalysisWorldTable,
  useAnalysisTrialTwoDimChartEntity,
} from '../BAnalysisChartNext'
import { useAnalysisTrialOneDimChartEntity } from '../BAnalysisChartNext/compositions/clinical-trial/useAnalysisTrialOneDimChartEntity'
import { useAnalysisOneDimChartNavList } from '../BAnalysisChartNext/compositions/useAnalysisOneDimChartNavList'
import { useAnalysisTwoDimChartNavList } from '../BAnalysisChartNext/compositions/useAnalysisTwoDimChartNavList'
import $classes from './BAnalysisView.module.scss'
import {
  IUseClinicalTrialAggChartType,
  lineChartTypes,
  pieChartTypes,
  stackBarChartTypes,
  useClinicalTrialAggChart,
  worldChartTypes,
} from './compositions/useClincialTrialAggChart'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BClinicalTrialAnalysisView = defineComponent({
  name: 'BClinicalTrailAnalysisView',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { uncollapse_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props) {
    const showFullScreen = ref(false)
    const ins = getCurrentInstance()
    const activeFullScreen = ref<IUseClinicalTrialAggChartType | ''>('')

    const { locale } = useLocale()

    const query = computed<IQuery>(() => {
      return {
        type: 'group',
        must: props.queryService.state.query.must,
        filter: props.queryService.state.query.filter,
      }
    })

    const chartTypes: IUseClinicalTrialAggChartType[] = [
      'register-line',
      'country-world',
      'multi-center-pie',
      'phase-pie',
      'exp-drug-type-stack-bar',
      'exp-drug-stack-bar',
      'indication-stack-bar',
      'therapeutic-area-stack-bar',
      'sponsor-stack-bar',
    ]

    const chartAggRecord = chartTypes.reduce<Record<IUseClinicalTrialAggChartType, ReturnType<typeof useClinicalTrialAggChart>>>(
      (pre, next) => ({
        ...pre,
        [next]: useClinicalTrialAggChart({ type: next, query }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useClinicalTrialAggChart>>
    )

    const chartInitedRecord = reactive(
      chartTypes.reduce((pre, next) => ({ ...pre, [next]: false }), {} as Record<IUseClinicalTrialAggChartType, boolean>)
    )

    const lineChartEntityRecord = lineChartTypes.reduce<Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>>(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTrialOneDimChartEntity({
          chartType: 'line',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>
    )

    const worldChartEntityRecord = worldChartTypes.reduce<
      Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>
    >(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTrialOneDimChartEntity({
          chartType: 'world',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>
    )

    const pieChartEntityRecord = pieChartTypes.reduce<Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>>(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTrialOneDimChartEntity({
          chartType: 'pie',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialOneDimChartEntity>>
    )

    const stackBarChartEntityRecord = stackBarChartTypes.reduce<
      Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialTwoDimChartEntity>>
    >(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTrialTwoDimChartEntity({
          chartType: 'bar',
          data: chartAggRecord[next].aggData,
          xAxisName: chartAggRecord[next].countFieldName,
          yAxisName: chartAggRecord[next].categoryFieldName,
          layout: 'horizontal',
        }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialTwoDimChartEntity>>
    )

    const stackLineChartEntityRecord = lineChartTypes.reduce<
      Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialTwoDimChartEntity>>
    >(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisTrialTwoDimChartEntity({
          chartType: 'line',
          data: chartAggRecord[next].aggData,
          xAxisName: chartAggRecord[next].categoryFieldName,
          yAxisName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<IUseClinicalTrialAggChartType, ReturnType<typeof useAnalysisTrialTwoDimChartEntity>>
    )

    const { handleClick: handleClickWorldChart } = useAnalysisOneDimChartNavList({
      chartType: 'world',
      navList: 'clinical_trial',
      query,
      appendTo: 'filter',
    })

    const { handleClick: handleClickLineChart } = useAnalysisOneDimChartNavList({
      chartType: 'line',
      navList: 'clinical_trial',
      query,
      appendTo: 'filter',
      customAggValueTransformer: (aggValues) => {
        if (aggValues[0].aggregation_field === 'STUDY_FIRST_POSTED_DATE_YEAR') {
          const year = Number(aggValues[0]._meta.after)
          return getTimeRelatedQueryField(year, 'STUDY_FIRST_POSTED_DATE', true, false)
        }
      },
    })

    const { handleClick: handleClickPieChart } = useAnalysisOneDimChartNavList({
      chartType: 'pie',
      navList: 'clinical_trial',
      query,
      appendTo: 'filter',
    })

    const { handleClick: handleClickStackBarChart } = useAnalysisTwoDimChartNavList({
      chartType: 'bar',
      navList: 'clinical_trial',
      query,
      appendTo: ['filter', 'filter'],
      interceptors: [
        (data: AggTwoDimDataItem) => {
          const sponsorFields = ['SPONSOR_LEADER_ID_ROOT_ONEID', 'NORMALIZED_ID_LEADER_ONEID']
          const collaboratorFields = ['SPONSOR_COLLABORATOR_ID_ROOT_ONEID', 'NORMALIZED_ID_COLLABORATOR_ONEID']
          const registerFields = ['REGISTRY_ID_ROOT_ONEID', 'REGISTRY_ID_ONEID']

          const filterFields = props.queryService.state.query.filter
            .filter((i) => i.type === 'field')
            .reduce((pre, next) => (next.type === 'field' ? [...pre, ...next.fields] : pre), [] as string[])

          const hasSponsorFields = sponsorFields.some((i) => filterFields.includes(i))

          const hasCollaboratorFields = collaboratorFields.some((i) => filterFields.includes(i))

          const hasRegisterFields = registerFields.some((i) => filterFields.includes(i))

          const isInValidSponsor =
            hasSponsorFields &&
            !!data.y?.aggregation_field &&
            !filterFields.includes(data.y.aggregation_field) &&
            sponsorFields.includes(data.y.aggregation_field)

          const isInvalidCollaborator =
            hasCollaboratorFields &&
            !!data.y?.aggregation_field &&
            !filterFields.includes(data.y.aggregation_field) &&
            collaboratorFields.includes(data.y.aggregation_field)

          const isInvalidRegister =
            hasRegisterFields &&
            !!data.y?.aggregation_field &&
            !filterFields.includes(data.y.aggregation_field) &&
            registerFields.includes(data.y.aggregation_field)

          if (isInValidSponsor || isInvalidCollaborator || isInvalidRegister) {
            return {
              message:
                unref(locale) === 'cn'
                  ? '当前图表中以总公司聚合的设置与左侧过滤项不一致，请设置为相同后，再次尝试'
                  : 'The setting of aggregation by parent company in the current chart is not the same as the filter item on the left, please set it to the same and try again.',
              type: 'error',
            }
          }

          return true
        },
      ],
    })

    const { handleClick: handleClickStackLineChart } = useAnalysisTwoDimChartNavList({
      chartType: 'line',
      navList: 'clinical_trial',
      query,
      appendTo: ['filter', 'filter'],
      customAggValueTransformer: (aggValue, axis) => {
        if (axis === 'y') {
          const year = Number(aggValue._meta.after)
          return getTimeRelatedQueryField(year, 'STUDY_FIRST_POSTED_DATE', true, false)
        }
      },
    })

    function getHandleClickFunc(type: IUseClinicalTrialAggChartType) {
      if (lineChartTypes.includes(type)) {
        const model = chartAggRecord[type].model
        if (model.phaseType?.selectedValue === 'all') {
          return handleClickLineChart
        }
        if (model.phaseType?.selectedValue === 'independent') {
          return handleClickStackLineChart
        }
      }
      if (worldChartTypes.includes(type)) return handleClickWorldChart
      if (pieChartTypes.includes(type)) return handleClickPieChart
      if (stackBarChartTypes.includes(type)) return handleClickStackBarChart
      return noop
    }

    function getChartTitleByType(type: IUseClinicalTrialAggChartType) {
      switch (type) {
        case 'exp-drug-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.drugAnalysis.expDrug.title') || ''
        case 'exp-drug-type-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.drugAnalysis.expDrugType.title') || ''
        case 'therapeutic-area-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.diseaseAnalysis.therapeuticArea.title') || ''
        case 'indication-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.diseaseAnalysis.disease.title') || ''
        case 'sponsor-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.orgAnalysis.sponsor.title') || ''
        case 'multi-center-pie':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.multiCenter.title') || ''
        case 'phase-pie':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.phase.title') || ''
        case 'country-world':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.region.title') || ''
        case 'register-line':
          return ins?.proxy.$tc('analysisView.clinicalTrial.trendAnalysis.firstPostDate.title') || ''
        default:
          return ''
      }
    }

    function getChartDescByType(type: IUseClinicalTrialAggChartType) {
      switch (type) {
        case 'exp-drug-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.drugAnalysis.expDrug.desc') || ''
        case 'exp-drug-type-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.drugAnalysis.expDrugType.desc') || ''
        case 'therapeutic-area-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.diseaseAnalysis.therapeuticArea.desc') || ''
        case 'indication-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.diseaseAnalysis.disease.desc') || ''
        case 'sponsor-stack-bar':
          return ins?.proxy.$tc('analysisView.clinicalTrial.orgAnalysis.sponsor.desc') || ''
        case 'multi-center-pie':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.multiCenter.desc') || ''
        case 'phase-pie':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.phase.desc') || ''
        case 'country-world':
          return ins?.proxy.$tc('analysisView.clinicalTrial.studyDesignAnalysis.region.desc') || ''
        case 'register-line':
          return ins?.proxy.$tc('analysisView.clinicalTrial.trendAnalysis.firstPostDate.desc') || ''
        default:
          return ''
      }
    }

    async function initData() {
      props.loadingService.action.setLoading(true)
      const rt = await props.getTotalFn()
      props.loadingService.action.setTotal(rt.total)
      props.loadingService.action.setUnCollapseTotal(rt.uncollapse_total)
      props.loadingService.action.setLoading(false)
    }

    function initAggData() {
      chartTypes.forEach((type) => {
        if (chartInitedRecord[type]) {
          handleFetchChartData(type)
        }
      })
    }

    function handleFetchChartData(type: IUseClinicalTrialAggChartType) {
      chartAggRecord[type].fetchAggData()
      chartInitedRecord[type] = true
    }

    function handleOpenFullScreen(type: IUseClinicalTrialAggChartType) {
      activeFullScreen.value = type
      showFullScreen.value = true
    }

    function handleCloseFullScreen() {
      showFullScreen.value = false
      activeFullScreen.value = ''
    }

    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        initData()
      },
      {
        immediate: true,
      }
    )

    watch(query, () => {
      initAggData()
    })

    return {
      chartAggRecord,
      lineChartEntityRecord,
      worldChartEntityRecord,
      pieChartEntityRecord,
      stackBarChartEntityRecord,
      stackLineChartEntityRecord,
      getChartTitleByType,
      getChartDescByType,
      handleOpenFullScreen,
      handleCloseFullScreen,
      showFullScreen,
      activeFullScreen,
      handleFetchChartData,
      getHandleClickFunc,
    }
  },
  methods: {
    renderWorldByType(type: IUseClinicalTrialAggChartType, cls = '') {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.worldChartEntityRecord[type]
      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={this.getChartTitleByType(type)}
            desc={this.getChartDescByType(type)}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={600}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            <BAnalysisWorldEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggWorldChartEntity}
            />
            <BAnalysisWorldTable
              class={[{ hidden: model.display === 'global' }]}
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              list={dataList.value as AggWorldDataItem[]}
              displayMode="table"
              height="100%"
            ></BAnalysisWorldTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderLineByType(type: IUseClinicalTrialAggChartType, cls = '') {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.lineChartEntityRecord[type]

      const {
        chartEntity: stackLineChartEntity,
        dataList: stackLineDataList,
        countFieldName: stackLineCountFieldName,
        entityFieldName: stackLineEntityFieldName,
        stackFieldName,
      } = this.stackLineChartEntityRecord[type]

      const isRegisterLine = type === 'register-line'

      const selectedPhaseType = model.phaseType?.selectedValue

      const isStackLine = isRegisterLine && selectedPhaseType === 'independent'

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={this.getChartTitleByType(type)}
            desc={this.getChartDescByType(type)}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={600}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{
              visible: model.display !== 'table',
              type: 'chart-img',
              chartInsFinder: () => {
                if (model.display === 'table') return
                if (model.display === 'single-line') {
                  if (model.phaseType?.selectedValue === 'all') {
                    return 'single-line'
                  }
                  if (model.phaseType?.selectedValue === 'independent') {
                    return 'multi-line'
                  }
                }
              },
            }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            {isStackLine ? (
              <BAnalysisStackLineEchart
                class={['h-full', { hidden: model.display === 'table' }]}
                onClickItem={this.getHandleClickFunc(type)}
                entity={stackLineChartEntity.value as AggStackLineChartEntity}
              ></BAnalysisStackLineEchart>
            ) : (
              <BAnalysisLineEchart
                class={['h-full', { hidden: model.display === 'table' }]}
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggLineChartEntity}
              />
            )}
            {isStackLine ? (
              <BAnalysisTwoDimStackTable
                class={[{ hidden: model.display === 'single-line' }]}
                height={'100%'}
                displayMode="table"
                entityFieldName={stackLineEntityFieldName.value}
                countFieldName={stackLineCountFieldName.value}
                stackFieldName={stackFieldName.value}
                list={stackLineDataList.value}
              ></BAnalysisTwoDimStackTable>
            ) : (
              <BAnalysisLineTable
                class={[{ hidden: model.display === 'single-line' }]}
                categoryFieldName={categoryFieldName.value}
                countFieldName={countFieldName.value}
                list={dataList.value as AggWorldDataItem[]}
                displayMode="table"
                height="100%"
              ></BAnalysisLineTable>
            )}
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderLineFullScreen(type: IUseClinicalTrialAggChartType) {
      if (type !== this.activeFullScreen) return null

      const {
        model,
        controls,
        controlLayoutFullscreen,
        categoryFieldName,
        countFieldName,
        loading,
        isEmpty,
        settingDataPanelTitle,
        settingChartPanelTitle,
      } = this.chartAggRecord[type]

      const { chartEntity, dataList } = this.lineChartEntityRecord[type]

      const {
        chartEntity: stackLineChartEntity,
        dataList: stackLineDataList,
        countFieldName: stackLineCountFieldName,
        entityFieldName: stackLineEntityFieldName,
        stackFieldName,
      } = this.stackLineChartEntityRecord[type]

      const isRegisterLine = type === 'register-line'

      const selectedPhaseType = model.phaseType?.selectedValue

      const isStackLine = isRegisterLine && selectedPhaseType === 'independent'

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen`}
          class="h-full"
          title={this.getChartTitleByType(type)}
          desc={this.getChartDescByType(type)}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          displayType="single-line"
          layout={controlLayoutFullscreen}
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{
            visible: true,
            type: 'chart-img',
            chartInsFinder: () => {
              if (model.phaseType?.selectedValue === 'all') {
                return 'single-line'
              }
              if (model.phaseType?.selectedValue === 'independent') {
                return 'multi-line'
              }
            },
          }}
          value={model}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              {isStackLine ? (
                <BAnalysisStackLineEchart
                  onClickItem={this.getHandleClickFunc(type)}
                  entity={stackLineChartEntity.value as AggStackLineChartEntity}
                  style={{ height: `600px` }}
                ></BAnalysisStackLineEchart>
              ) : (
                <BAnalysisLineEchart
                  onClickItem={this.getHandleClickFunc(type)}
                  entity={chartEntity.value as AggLineChartEntity}
                  style={{ height: `600px` }}
                />
              )}
            </div>
            <div class="mt-8">
              {isStackLine ? (
                <BAnalysisTwoDimStackTable
                  displayMode="record"
                  entityFieldName={stackLineEntityFieldName.value}
                  countFieldName={stackLineCountFieldName.value}
                  stackFieldName={stackFieldName.value}
                  list={stackLineDataList.value}
                ></BAnalysisTwoDimStackTable>
              ) : (
                <BAnalysisLineTable
                  categoryFieldName={categoryFieldName.value}
                  countFieldName={countFieldName.value}
                  list={dataList.value as AggWorldDataItem[]}
                  displayMode="record"
                ></BAnalysisLineTable>
              )}
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderWorldFullScreen(type: IUseClinicalTrialAggChartType) {
      if (type !== this.activeFullScreen) return null
      const {
        model,
        controls,
        controlLayoutFullscreen,
        categoryFieldName,
        countFieldName,
        settingChartPanelTitle,
        settingDataPanelTitle,
        loading,
        isEmpty,
      } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.worldChartEntityRecord[type]
      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={this.getChartTitleByType(type)}
          desc={this.getChartDescByType(type)}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          displayType="global"
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisWorldEchart
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggWorldChartEntity}
                style={{ height: `600px` }}
              ></BAnalysisWorldEchart>
            </div>
            <div class="mt-8">
              <BAnalysisWorldTable
                displayMode="record"
                categoryFieldName={categoryFieldName.value}
                countFieldName={countFieldName.value}
                list={dataList.value as AggWorldDataItem[]}
              ></BAnalysisWorldTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderPieByType(type: IUseClinicalTrialAggChartType, cls = '') {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]

      const { chartEntity, dataList } = this.pieChartEntityRecord[type]

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={this.getChartTitleByType(type)}
            desc={this.getChartDescByType(type)}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={450}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            <BAnalysisPieEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggPieChartEntity}
            />
            <BAnalysisPieTable
              class={[{ hidden: model.display === 'pie' }]}
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              list={dataList.value}
              displayMode="table"
              height="100%"
            ></BAnalysisPieTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderPieFullScreen(type: IUseClinicalTrialAggChartType) {
      if (type !== this.activeFullScreen) return null
      const {
        model,
        controls,
        controlLayoutFullscreen,
        categoryFieldName,
        countFieldName,
        settingChartPanelTitle,
        settingDataPanelTitle,
        loading,
        isEmpty,
      } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.pieChartEntityRecord[type]
      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={this.getChartTitleByType(type)}
          desc={this.getChartDescByType(type)}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          displayType="pie"
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisPieEchart
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggPieChartEntity}
                style={{ height: `450px` }}
              ></BAnalysisPieEchart>
            </div>
            <div class="mt-8">
              <BAnalysisPieTable
                displayMode="record"
                categoryFieldName={categoryFieldName.value}
                countFieldName={countFieldName.value}
                list={dataList.value}
              ></BAnalysisPieTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderStackBarByType(type: IUseClinicalTrialAggChartType, cls = '') {
      const { model, controls, controlLayout, loading, isEmpty, settingPanelTitle } = this.chartAggRecord[type]

      const { chartEntity, dataList, countFieldName, stackFieldName, entityFieldName, uniqEntityDataList } = this.stackBarChartEntityRecord[type]

      const height = model.display === 'horizontal-bar' ? Math.max(600, uniqEntityDataList.value.length * 80) : 80 * 10

      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={this.getChartTitleByType(type)}
            desc={this.getChartDescByType(type)}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls}
            layout={controlLayout}
            value={model}
            settingTitle={settingPanelTitle.value}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            <BAnalysisStackBarEchart
              onClickItem={this.getHandleClickFunc(type)}
              class={['h-full', { hidden: model.display === 'table' }]}
              entity={chartEntity.value as AggStackBarChartEntity}
            ></BAnalysisStackBarEchart>
            <BAnalysisTwoDimStackTable
              class={[{ hidden: model.display === 'horizontal-bar' }]}
              height={'100%'}
              displayMode="table"
              entityFieldName={entityFieldName.value}
              countFieldName={countFieldName.value}
              stackFieldName={stackFieldName.value}
              list={dataList.value}
            ></BAnalysisTwoDimStackTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderStackBarFullScreen(type: IUseClinicalTrialAggChartType) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayoutFullscreen, loading, isEmpty, settingChartPanelTitle, settingDataPanelTitle } = this.chartAggRecord[type]

      const { chartEntity, countFieldName, dataList, entityFieldName, uniqEntityDataList, stackFieldName } = this.stackBarChartEntityRecord[type]

      const height = Math.max(600, uniqEntityDataList.value.length * 70)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen}`}
          class="h-full"
          title={this.getChartTitleByType(type)}
          desc={this.getChartDescByType(type)}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          displayType="horizontal-bar"
          dataPanelTitle={settingDataPanelTitle.value}
          chartPanelTitle={settingChartPanelTitle.value}
          onClose={this.handleCloseFullScreen}
          downloadCfg={{ visible: true, type: 'chart-img' }}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisStackBarEchart
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggStackBarChartEntity}
                style={{ height: `${height}px` }}
              ></BAnalysisStackBarEchart>
            </div>
            <div class="mt-8">
              <BAnalysisTwoDimStackTable
                displayMode="record"
                entityFieldName={entityFieldName.value}
                countFieldName={countFieldName.value}
                stackFieldName={stackFieldName.value}
                list={dataList.value}
              ></BAnalysisTwoDimStackTable>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
  },
  render() {
    return (
      <div class="p-4 pb-16 h-full overflow-y-auto">
        <div class={[$classes.blockTitle, 'mb-4']}>{this.$t('analysisView.clinicalTrial.trendAnalysisTitle')}</div>
        {this.renderLineByType('register-line')}
        <div class={[$classes.blockTitle, 'mt-8 mb-4']}>{this.$t('analysisView.clinicalTrial.studyDesignAnalysisTitle')}</div>
        {this.renderWorldByType('country-world', 'mb-4')}
        <div class="flex flex-row items-center">
          <div class="flex-1 overflow-hidden pr-2">{this.renderPieByType('multi-center-pie')}</div>
          <div class="flex-1 overflow-hidden pl-2">{this.renderPieByType('phase-pie')}</div>
        </div>
        <div class={[$classes.blockTitle, 'mt-8 mb-4']}>{this.$t('analysisView.clinicalTrial.drugAnalysisTitle')}</div>
        {this.renderStackBarByType('exp-drug-type-stack-bar', 'mb-4')}
        {this.renderStackBarByType('exp-drug-stack-bar')}
        <div class={[$classes.blockTitle, 'mt-8 mb-4']}>{this.$t('analysisView.clinicalTrial.diseaseAnalysisTitle')}</div>
        {this.renderStackBarByType('therapeutic-area-stack-bar', 'mb-4')}
        {this.renderStackBarByType('indication-stack-bar')}
        <div class={[$classes.blockTitle, 'mt-8 mb-4']}>{this.$t('analysisView.clinicalTrial.orgAnalysisTitle')}</div>
        {this.renderStackBarByType('sponsor-stack-bar')}
        <div class={[$classes.fullscreen, { hidden: !this.showFullScreen }]}>
          {this.renderStackBarFullScreen('exp-drug-type-stack-bar')}
          {this.renderStackBarFullScreen('exp-drug-stack-bar')}
          {this.renderStackBarFullScreen('therapeutic-area-stack-bar')}
          {this.renderStackBarFullScreen('indication-stack-bar')}
          {this.renderStackBarFullScreen('sponsor-stack-bar')}
          {this.renderPieFullScreen('multi-center-pie')}
          {this.renderPieFullScreen('phase-pie')}
          {this.renderWorldFullScreen('country-world')}
          {this.renderLineFullScreen('register-line')}
        </div>
      </div>
    )
  },
})
