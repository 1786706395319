/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ECOption, Plot } from '../core/plot'
import { BasicRadarChart } from '../type'
import { getChartColor } from './config'

export class RaDar extends Plot {
  public type = 'radar'

  protected getDefaultOption(options: ECOption): ECOption {
    return options
  }

  protected adaptor(params: BasicRadarChart): ECOption {
    const colors = getChartColor(params.series.length)
    // @ts-ignore
    const options: ECOption = {
      ...params,
      series: params.series.map((item, index) => ({
        ...item,
        areaStyle: {
          color: colors[index],
          opacity: 0.15,
        },
        tooltip: {
          trigger: 'item',
        },
        data:
          item.data?.map((_item) => {
            return {
              ..._item,
              symbol: 'circle',
              symbolSize: 1,
              label: {
                show: true,
                formatter(data) {
                  return data.value > 0 ? `${data.value}` : ''
                },
              },
            }
          }) || [],
        type: 'radar',
      })),
    }
    return options
  }
}
