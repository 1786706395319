import { defineComponent } from '@vue/composition-api'
import { GDrawerLayoutProps } from '../../props'
import { GDrawerLayout } from '../GDrawerLayout/GDrawerLayout'
import $classes from './GHeaderDrawerLayout.module.scss'

export const GHeaderDrawerLayout = defineComponent({
  name: 'GHeaderDrawerLayout',
  model: {
    prop: 'expanded',
    event: 'input',
  },
  props: {
    ...GDrawerLayoutProps,
  },
  render() {
    return (
      <div class={$classes.layout}>
        <div class={$classes.header}>{this.$slots.header}</div>
        {this.$slots.nav ? <div class={$classes.tab}>{this.$slots.nav}</div> : null}
        <GDrawerLayout
          class={$classes.content}
          expanded={this.expanded}
          maxWidth={this.maxWidth}
          minWidth={this.minWidth}
          triggerTop={this.triggerTop}
          showSide={this.showSide}
          onInput={(val: boolean) => this.$emit('input', val)}
        >
          <template slot="drawer">{this.$slots.drawer}</template>
          <template slot="default">{this.$slots.default}</template>
          {/* <template slot="drawer">{<div>D</div>}</template>
          <template slot="default">{<div>A</div>}</template> */}
        </GDrawerLayout>
      </div>
    )
  },
})
