import { E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { RouteConfig } from 'vue-router'

export default [
  {
    path: `disease/:${E_ROUTER_PARAMS.DISEASE_ID}`,
    name: E_ROUTER_NAME.DISEASE,
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.DISEASE_ID],
    }),
    component: () => import('@pharmsnap/pharmsnap-web/views/disease/DiseaseDetail'),
    meta: {
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.DISEASE_DETAIL,
    },
    children: [
      {
        path: 'overview',
        name: E_ROUTER_NAME.DISEASE_OVERVIEW,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DISEASE,
          }
        },
      },
    ],
  },
] as RouteConfig[]
