import { fieldMany2OneMapByType, fields2UUKey, splitFields } from '@patsnap/synapse_common_business'
import {
  E_QUERY_ITEM_CONDITION,
  IAggregationItem,
  IAggregationSingleResult,
  IQueryDataType,
  IQueryItem,
  IQueryValue,
  IQueryValueEntity,
  IQueryValueExist,
  IQueryValueText,
} from '@patsnap/synapse_common_interface'
import { IFieldLangMap } from '@pharmsnap/shared/types'
import { IQueryValueType } from '@pharmsnap/shared/types/search'
import { getOneField2ManyFieldMap } from '@pharmsnap/shared/utils'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { partition } from 'lodash'
import { defaultLimitAggregationCount } from '../config'
import { IFilterConfig, IFilterDisplayItem, IFilterItemConfig, IFilterNestFilterItem, IFilterValue, IFilterValueArrItem } from '../types'
/**
 * filter的值转换成query
 * @param filterValue
 * @param type
 */
function filterValue2QueryValue(filterValue: IFilterValue, type: IQueryValueType): IQueryValue {
  switch (type) {
    case 'text':
      return {
        type: 'text',
        value: filterValue.value,
        display_name_cn: filterValue.display_name_cn,
        display_name_en: filterValue.display_name_en,
      } as IQueryValueText
    case 'organization':
    case 'disease':
    case 'target':
    case 'drug':
      return {
        type: type,
        id: filterValue.value,
        display_name_cn: filterValue.display_name_cn,
        display_name_en: filterValue.display_name_en,
      } as IQueryValueEntity
    case 'range':
      return {
        type: type,
        from: filterValue.from,
        to: filterValue.to,
        // 存储动态时间范围的Date_type
        key: filterValue.value as string,
        display_name_cn: filterValue.display_name_cn,
        display_name_en: filterValue.display_name_en,
        ...(filterValue.include_lower !== undefined && { include_lower: filterValue.include_lower }),
        ...(filterValue.include_upper !== undefined && { include_upper: filterValue.include_upper }),
      }
    case 'exist':
      return {
        type: type,
        value: !!filterValue.value,
        display_name_cn: filterValue.display_name_cn,
        display_name_en: filterValue.display_name_en,
      } as IQueryValueExist
  }
}
/**
 * filter的值转换成query
 * @param queryValue
 * @param type
 */
function queryValue2FilterValue(queryValue: IQueryValue): IFilterValue {
  switch (queryValue.type) {
    case 'organization':
    case 'disease':
    case 'target':
    case 'drug':
    case 'CommonEntity':
    case 'drugType':
      return {
        value: queryValue.id,
        display_name_cn: queryValue.display_name_cn,
        display_name_en: queryValue.display_name_en,
      }
    case 'range':
      return {
        value: queryValue.key || '',
        from: queryValue.from,
        to: queryValue.to,
        display_name_cn: queryValue.display_name_cn,
        display_name_en: queryValue.display_name_en,
        ...(queryValue.include_lower !== undefined && { include_lower: queryValue.include_lower }),
        ...(queryValue.include_upper !== undefined && { include_upper: queryValue.include_upper }),
      }
    case 'text':
    case 'exist':
      return {
        value: queryValue.value,
        display_name_cn: queryValue.display_name_cn,
        display_name_en: queryValue.display_name_en,
      }
  }
}
function filterItem2QueryItem(
  field: string,
  condition = E_QUERY_ITEM_CONDITION.ANY,
  filterValues: IFilterValue[],
  config: IFilterItemConfig,
  dataType: IQueryDataType
): IQueryItem {
  const { queryItemType } = config
  if (queryItemType === 'field') {
    let fields: string[] = [field]
    const oneField2ManyFIeldConfig = getOneField2ManyFieldMap(dataType)
    if (oneField2ManyFIeldConfig) {
      const manyFieldsStr = oneField2ManyFIeldConfig[field]
      if (manyFieldsStr) {
        fields = splitFields(manyFieldsStr)
      }
    } else {
      console.error(`dataType:${dataType}缺少多对一配置表`)
    }
    return {
      type: queryItemType,
      fields,
      condition,
      value: filterValues.map((item) => filterValue2QueryValue(item, config.queryValueType)),
    }
  } else {
    return {
      type: queryItemType,
      condition,
      value: filterValues.map((item) => filterValue2QueryValue(item, config.queryValueType) as IQueryValueEntity),
    }
  }
}
/**
 * filter的值map结合filter filed的配置 转成queryItem
 * @param data
 * @param filterItemConfigMap
 * @returns
 */

/**
 * 将字典结构转数据结构
 * @param data
 * @returns
 */
export function filterValueMap2FilterValueArr(options: {
  data: Partial<Record<string, IFilterValue[]>>
  isExclude: boolean
  filterItemConfigMap: Partial<Record<string, IFilterItemConfig<string>>>
  needPutInAGroupFilterItems?: string[][]
}): IFilterValueArrItem[] {
  const { data, isExclude, filterItemConfigMap, needPutInAGroupFilterItems } = options
  const groupQueryItemsMap: Partial<Record<string, IFilterValueArrItem>> = {}
  const notGroupQueryItems: IFilterValueArrItem[] = []
  for (const field in data) {
    const filterValues = data[field]
    if (!filterValues?.length) {
      continue
    }
    const config = filterItemConfigMap[field]
    if (!config) {
      console.error(`filterValueArr2QueryItem: 没有找到字段${field}的 配置项,请检查`)
      continue
    }
    const currentFilterValue: IFilterValueArrItem = {
      field: field,
      condition: isExclude ? E_QUERY_ITEM_CONDITION.NONE : E_QUERY_ITEM_CONDITION.ANY,
      value: filterValues,
    }
    const groupUUId = needPutInAGroupFilterItems
      ?.find((item) => item.includes(field))
      ?.sort()
      .join('|')
    if (groupUUId) {
      const currentGroupItem = groupQueryItemsMap[groupUUId]
      if (!currentGroupItem) {
        groupQueryItemsMap[groupUUId] = {
          field: groupUUId,
          condition: isExclude ? E_QUERY_ITEM_CONDITION.NONE : E_QUERY_ITEM_CONDITION.ANY,
          value: [],
          children: [currentFilterValue],
        }
      } else {
        currentGroupItem.children?.push(currentFilterValue)
      }
    } else {
      notGroupQueryItems.push(currentFilterValue)
    }
  }
  return [...notGroupQueryItems, ...(Object.values(groupQueryItemsMap) as IFilterValueArrItem[])]
}

/**
 * filter存储的值转换为query条件
 * @param data
 * @param filterItemConfigMap
 * @returns
 */
export function filterValueArr2QueryItem(options: {
  data: IFilterValueArrItem[]
  filterItemConfigMap: Partial<Record<string, IFilterItemConfig<string>>>
  aggregationByParentMap: Partial<Record<string, boolean>>
  dataType: IQueryDataType
}): IQueryItem[] {
  const { data, filterItemConfigMap, aggregationByParentMap, dataType } = options
  return data.reduce((acc, curr) => {
    const { field: _field, condition, value, children } = curr
    if (children?.length) {
      const groupConditionField = curr.condition === E_QUERY_ITEM_CONDITION.NONE ? 'must' : 'any'
      return [
        ...acc,
        {
          type: 'group',
          [groupConditionField]: filterValueArr2QueryItem({
            data: children,
            filterItemConfigMap,
            aggregationByParentMap,
            dataType,
          }),
        },
      ]
    }
    const config = filterItemConfigMap[_field]
    if (!config) {
      console.error(`filterValueArr2QueryItem: 没有找到字段${_field}的 配置项,请检查`)
      return acc
    }
    if (!value.length) {
      return acc
    }
    const field = getAggregationField(_field, config, aggregationByParentMap)
    acc.push(filterItem2QueryItem(field, condition, value, config, dataType))
    return acc
  }, [] as IQueryItem[])
}
/**
 * 将query中的过滤项转换为filter存储的数据结构
 * @param queryItems
 * @param rootField2FieldMap
 * @returns
 */
export function queryItem2filterValueArr(
  queryItems: IQueryItem[],
  rootField2FieldMap: Record<string, string>,
  dataType: IQueryDataType
): IFilterValueArrItem[] {
  return queryItems.reduce((acc, curr) => {
    if (curr.type === 'field' && curr.fields.length) {
      const condition = curr.condition || E_QUERY_ITEM_CONDITION.ANY
      const fieldsLength = curr.fields.length
      if (fieldsLength === 1) {
        // filter中的 filter只会有一个
        const _field = curr.fields[0]
        // 如果传过来的是一个上卷的字段,需要把它存储到不上卷的字段上
        const field = rootField2FieldMap[_field] || _field
        const currentFilterValue: IFilterValue[] = curr.value.map(queryValue2FilterValue)
        return [
          ...acc,
          {
            field,
            condition,
            value: currentFilterValue,
          },
        ]
      } else if (fieldsLength > 1) {
        // 如果是多个字段
        const fieldMany2OneMapConfig = fieldMany2OneMapByType[dataType]
        if (!fieldMany2OneMapConfig) {
          console.error(`dataType:${dataType}缺少多对一配置表`)
          return acc
        }
        const mapField = fieldMany2OneMapConfig[fields2UUKey(curr.fields)]
        if (!mapField) {
          console.error(`queryItem2filterValueArr: 没有配置多字段映射成一个字段的映射表,请检查`)
          return acc
        }
        const currentFilterValue: IFilterValue[] = curr.value.map(queryValue2FilterValue)
        return [
          ...acc,
          {
            field: mapField,
            condition: condition,
            value: currentFilterValue,
          },
        ]
      }
    }
    if (curr.type === 'group') {
      const childQueryItems = curr.any || curr.must || []
      if (!childQueryItems.length) {
        return acc
      }
      const allFields = childQueryItems
        .map((item) => {
          if (item.type === 'field') {
            return item.fields
          }
          return []
        })
        .flat()
        .filter(Boolean)
        .join('|')
      return [
        ...acc,
        {
          field: allFields,
          condition: curr.any ? E_QUERY_ITEM_CONDITION.ANY : E_QUERY_ITEM_CONDITION.NONE,
          value: [],
          children: queryItem2filterValueArr(childQueryItems, rootField2FieldMap, dataType),
        },
      ]
    }
    console.error(`[queryItem2filterValueMap]:恢复的query中包含非"field"类型的,忽略转换,请检查`)
    return acc
  }, [] as IFilterValueArrItem[])
}

export function getFilterConfigArr(filterConfig: IFilterConfig): IFilterItemConfig<string>[] {
  const allFilterItems = getAllFilterItems(filterConfig.filterItems)
  return allFilterItems.reduce((acc, curr) => {
    const config = filterConfig.filterItemConfigMap[curr]
    if (!config) {
      console.error(`[BFilter]: 没有找到字段 "${curr}" 的过滤项配置,请检查`)
      return acc
    }
    return [...acc, config]
  }, [] as IFilterItemConfig[])
}

/**
 * 将自定义聚合的field、和通用方法聚合的field分开
 * @param fields 聚合字段
 * @param configMap
 * @returns
 */
export function splitCustomAggregationFields(fields: string[], configMap: Partial<Record<string, IFilterItemConfig>>) {
  return partition(fields, (field) => {
    const config = getFiledConfig(field, configMap)
    return !!config?.getAggregationDataFunc
  })
}

/**
 * 生成聚合的参数
 * @param fields
 * @returns
 */
export function getAggregationConfig(
  fields: string[],
  configMap: Partial<Record<string, IFilterItemConfig>>,
  aggregationByParentFieldMap: Partial<Record<string, boolean>>,
  limit?: number
) {
  return fields.reduce((acc, curr) => {
    const config = getFiledConfig(curr, configMap)
    const configLimit = limit || config?.limitAggregationCount || defaultLimitAggregationCount
    if (config) {
      const { aggregationConfig, notAggregation } = config
      if (notAggregation) {
        // 不支持聚合,不需要请求
        return acc
      }
      if (aggregationConfig) {
        acc.push({
          ...aggregationConfig,
          limit: configLimit,
          aggregation_field: getAggregationField(curr, config, aggregationByParentFieldMap),
        })
      } else {
        console.warn(`[BFilter]: 没有找到字段 "${curr}" 的聚合项配置,忽略聚合`)
      }
    }
    return acc
  }, [] as IAggregationItem[])
}

/**
 * 获取字段的配置
 * @param field
 * @returns
 */
function getFiledConfig(field: string, configMap: Partial<Record<string, IFilterItemConfig>>) {
  const config = configMap[field]
  if (config) {
    return config
  } else {
    console.error(`[BFilter]: 没有找到字段 "${field}" 的过滤项配置,请检查`)
    return undefined
  }
}
/**
 * 判断聚合需要用的字段,
 */
function getAggregationField(
  originFields: string,
  aggregationConfig: IFilterItemConfig,
  aggregationByParentFieldMap: Partial<Record<string, boolean>>
): string {
  // 支持上卷聚合,且开关开着,则用Parent field去聚合
  if (aggregationConfig.aggregationByParentField && aggregationByParentFieldMap[originFields]) {
    return aggregationConfig.aggregationByParentField
  }
  return originFields
}

export function aggregationData2keyMap(
  data: IAggregationSingleResult[],
  rootField2FieldMap: Record<string, string>
): Record<string, IAggregationSingleResult> {
  return data.reduce((acc, curr) => {
    // 如果是按照上卷字段聚合的,存储的聚合数据需要存在非上卷字段上
    const key = rootField2FieldMap[curr.aggregation_field] || curr.aggregation_field
    return {
      ...acc,
      [key]: {
        aggregation_field: curr.aggregation_field,
        total: curr.total,
        items: curr.items.filter((item) => !!item.count),
      },
    }
  }, {} as Record<string, IAggregationSingleResult>)
}
/**
 * 创建上卷字段到普通字段的映射
 * @param data
 * @returns
 */
export function createRootField2FieldMap(data: IFilterItemConfig[]): Record<string, string> {
  return data.reduce((acc, curr) => {
    if (curr.aggregationByParentField) {
      return {
        ...acc,
        [curr.aggregationByParentField]: curr.field,
      }
    }
    return acc
  }, {} as Record<string, string>)
}
function filterItem2QueryDisplayItem(
  field: string,
  filterValues: IFilterValue[],
  config: IFilterItemConfig,
  fieldLangMap: Partial<IFieldLangMap>
): IFilterDisplayItem[] {
  return filterValues.map((item) => {
    return {
      field: field,
      field_label_cn: getFieldNameByLang(fieldLangMap, field, 'cn'),
      field_label_en: getFieldNameByLang(fieldLangMap, field, 'en'),
      value: item.value,
      value_label_cn: item.display_name_cn,
      value_label_en: item.display_name_en,
    }
  })
}
export function filterValueMap2QueryDisplayItem(
  data: Partial<Record<string, IFilterValue[]>>,
  filterItemConfigMap: Partial<Record<string, IFilterItemConfig<string>>>,
  fieldLangMap: Partial<IFieldLangMap>
): IFilterDisplayItem[] {
  return Object.entries(data).reduce((acc, curr) => {
    const [field, filterValues] = curr
    const config = filterItemConfigMap[field]
    if (config && filterValues?.length) {
      return [...acc, ...filterItem2QueryDisplayItem(field, filterValues, config, fieldLangMap)]
    }
    return acc
  }, [] as IFilterDisplayItem[])
}

export function getAllFilterItems<T extends string>(arr: Array<IFilterNestFilterItem<T>>) {
  const elements: string[] = []

  function traverseArray(array: Array<IFilterNestFilterItem<T>>) {
    for (let i = 0; i < array.length; i++) {
      const element = array[i]

      if (Array.isArray(element)) {
        traverseArray(element) // 递归遍历子数组
      } else {
        elements.push(element) // 将元素添加到结果数组中
      }
    }
  }

  traverseArray(arr)

  return elements
}
/**
 * 递归移除值为空的过滤项
 * @param items
 * @returns
 */
export function deleteEmptyValueChildren(items: IFilterValueArrItem[]): IFilterValueArrItem[] {
  const rt = []
  for (const item of items) {
    if (item.children && item.children.length) {
      const children = deleteEmptyValueChildren(item.children)
      if (children.length) {
        item.children = children
        rt.push(item)
        continue
      }
    }
    if (item.value.length) {
      rt.push(item)
    }
  }
  return rt
}
