import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { convertTreeAggregationData, showSingleToast } from '@pharmsnap/shared/utils'
import { Ref, computed, ref, watch } from '@vue/composition-api'
import { unionBy } from 'lodash'
import { userInjectFilterService } from '../BFilterService'
import { IFilterItemConfig, IFilterValue } from '../types'
type IHandelAggregationDataFn<T> = (data: T[]) => T[]
/**
 * 过滤项状态复用
 * @param config 当前过滤项的配置
 * @param type 过滤项数据存储的地方,list是左侧filter列表存储的数据,advance是高级过滤存储的数据,区别就是高级过滤会聚合更多的数据
 * @returns
 */
export function userFilterItemState(
  config: IFilterItemConfig,
  type: 'list' | 'advance',
  handelAggregationDataFn?: IHandelAggregationDataFn<IAggregationValue>,
  limit?: number
) {
  const { locale, isCN } = useLocale()
  const { field, defineKeyFn } = config
  const { state, getters, actions } = userInjectFilterService()
  const currentValue = computed(() => state?.filterValueMapInTime[field])
  function transformAggregationData(data?: IAggregationValue[]) {
    if (data?.length) {
      return handelAggregationDataFn ? handelAggregationDataFn(data) : data
    }
    return []
  }

  const treeData = computed(() => {
    return convertTreeAggregationData(state?.advanceTreeDataMap[field]?.items || [])
  })

  const filterData =
    type === 'advance'
      ? computed(() => {
          return state?.advanceFilterDataMap[field]?.items ? transformAggregationData(state?.advanceFilterDataMap[field].items) : []
        })
      : computed(() => {
          return state?.filterDataMap[field]?.items ? transformAggregationData(state?.filterDataMap[field].items) : []
        })
  const filterTotal =
    type === 'advance'
      ? computed(() => {
          return state?.advanceFilterDataMap[field]?.total || 0
        })
      : computed(() => {
          return state?.filterDataMap[field]?.total || 0
        })
  const checkedMap: Ref<Record<string, boolean>> = ref({})
  const disabledMap: Ref<Record<string, boolean>> = computed(() => {
    const _currentValue = getters?.valueMap.value[field]
    if (!_currentValue?.length) {
      return {}
    }
    return _currentValue.reduce((acc, curr) => {
      return {
        ...acc,
        [getUuKey(curr)]: true,
      }
    }, {})
  })
  /**
   * @param key 当前点击的元素的key
   * @param value 是否选中
   * @param isImmediatelyRefine 是否立即触发过滤; 若立即触发过滤，只需提交用户点击的元素，点击之前勾选的元素，不提交
   */
  function onChange(key: string, value: boolean, isImmediatelyRefine = false) {
    if (config.type === 'checkbox' && (config.single || isImmediatelyRefine)) {
      checkedMap.value = {
        [key]: value,
      }
    } else {
      checkedMap.value = {
        ...checkedMap.value,
        [key]: value,
      }
    }

    const values: IFilterValue[] = filterData.value
      .filter((item) => checkedMap.value[getUuKey(item)])
      .map((item) => {
        return {
          display_name_cn: item.display_name_cn,
          display_name_en: item.display_name_en,
          value: item.key,
          from: item.from,
          to: item.to,
          _raw: item,
        }
      })
    if (isImmediatelyRefine) {
      actions?.clearAll()
    }
    actions?.setFilterValue(field, values)
  }
  function selectItem(item: IFilterValue) {
    const totalCount = getters?.totalCount.value || 0
    const curFieldValues = actions?.getFilterValueByField(field) || []
    const exist = curFieldValues.find((o) => o.value === item.value)
    if (exist) {
      showSingleToast({
        type: 'warning',
        message: isCN.value ? '当前内容已存在' : 'The current content already exists',
      })
    } else {
      if (limit && totalCount >= limit) {
        return showSingleToast({
          type: 'warning',
          message: isCN.value ? `最多${limit}个过滤项` : `Up to ${limit} filters`,
        })
      }
      const values: IFilterValue[] = [...curFieldValues, item]
      actions?.setFilterValue(field, values)
    }
  }

  function selectMultiItem(selected: IFilterValue, items: IFilterValue[]) {
    const totalCount = getters?.totalCount.value || 0
    const curFieldValues = actions?.getFilterValueByField(field) || []
    let unionArr = unionBy(curFieldValues, items, 'value')
    if (limit) {
      const left = limit - totalCount
      if (totalCount < limit) {
        unionArr = unionArr.slice(0, curFieldValues.length + left)
        actions?.setFilterValue(field, unionArr)
      } else {
        showSingleToast({
          type: 'warning',
          message: isCN.value ? `最多${limit}个过滤项` : `Up to ${limit} filters`,
        })
      }
    } else {
      actions?.setFilterValue(field, unionArr)
    }
  }

  watch(
    currentValue,
    (value) => {
      checkedMap.value = filterValue2IndexMap(value)
    },
    { immediate: true }
  )
  // init()
  // function init() {
  //   checkedMap.value = filterValue2IndexMap(currentValue.value)
  // }

  function filterValue2IndexMap(values?: IFilterValue[]): Record<string, boolean> {
    if (!values?.length) {
      return {}
    }
    return values.reduce((acc, curr) => {
      return {
        ...acc,
        [getUuKey(curr)]: true,
      }
    }, {})
  }
  function getUuKey<
    T extends {
      key?: string
      value?: string | boolean
      display_name_en: string
      display_name_cn: string
    }
  >(data: T): string {
    if (defineKeyFn) {
      return defineKeyFn(data)
    }
    if (typeof data.value === 'boolean') {
      return data.value.toString()
    }
    return data.value || data.key || data.display_name_en || data.display_name_cn
  }

  return { locale, filterData, treeData, filterTotal, currentValue, checkedMap, disabledMap, getUuKey, onChange, selectItem, selectMultiItem }
}
