import { createUniformSetting } from '@patsnap/uniform-setting'
import { VUE_APP_ACCOUNT_BASEURL, VUE_APP_DEPLOY_REGION } from '@pharmsnap/pharmsnap-web/config'
import { getAxios } from '@pharmsnap/pharmsnap-web/service/service'

let setting: ReturnType<typeof createUniformSetting>
function initLocale(isLogin = true) {
  if (setting) {
    setting.locale.LoginIn = isLogin
  } else {
    setting = createUniformSetting({
      region: VUE_APP_DEPLOY_REGION,
      loggedIn: isLogin,
      baseAccountUrl: VUE_APP_ACCOUNT_BASEURL,
      axiosInstance: getAxios() as any,
    })
  }
  return setting.locale
}

export async function getLangValue(isLogin: boolean) {
  const { default: default_lang, user } = await initLocale(isLogin).getLocale()
  return user || default_lang
}

export async function setLangValue(lang: 'cn' | 'en', isLogin: boolean) {
  await initLocale(isLogin).setLocale(lang)
}
