/* eslint-disable @typescript-eslint/no-unused-vars */
import { TooltipComponentOption } from 'echarts/components'
import { TOOLTIP_BG_COLOR, TOOLTIP_BORDER_COLOR, TOOLTIP_BORDER_WIDTH, TOOLTIP_FONT_SIZE, TOOLTIP_PADDING, TOOLTIP_TEXT_COLOR } from '../../constants'
import { ECOption } from '../../core/plot'

const defaultTooltip = (options: ECOption): TooltipComponentOption => {
  return {
    show: true,
    triggerOn: 'mousemove|click',
    axisPointer: {
      z: -1,
    },
    enterable: false,
    renderMode: 'html',
    appendToBody: false,
    confine: true,
    backgroundColor: TOOLTIP_BG_COLOR,
    borderColor: TOOLTIP_BORDER_COLOR,
    borderWidth: TOOLTIP_BORDER_WIDTH,
    padding: TOOLTIP_PADDING,
    textStyle: {
      color: TOOLTIP_TEXT_COLOR,
      fontSize: TOOLTIP_FONT_SIZE,
    },
    extraCssText:
      'z-index: 999; line-height: 24px;box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 6px; max-width: 360px; overflow: hidden; white-space: normal;',
  }
}

export function getDefaultTooltip(options: ECOption): TooltipComponentOption {
  return defaultTooltip(options)
}
