import '@patsnap-ui/icon/assets/solid/ChartSingle.svg'
import '@patsnap-ui/icon/assets/solid/ContentFormatingTable.svg'
import '@pharmsnap/shared/assets/icon-svg/horBarChart.svg'
import '@pharmsnap/shared/assets/icon-svg/relationship.svg'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { GChartTypeButton } from '../..'
import $classes from './GAnalysisDisplayField.module.scss'

type IChartType = 'verticalBar' | 'horizonBar' | 'line' | 'relationship'
interface IFieldItem {
  value: string
  icon: string
  label?: string
  desc?: string
}

export const GAnalysisDisplayField = defineComponent({
  name: 'GAnalysisDisplayField',
  props: {
    options: {
      type: Array as PropType<IFieldItem[]>,
      default: () => [],
    },
    chartType: {
      type: String as PropType<IChartType>,
    },
    selectedValue: {
      type: String,
      default: 'chart',
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'small',
    },
  },
  setup(props, { emit }) {
    const { tsText } = useLocale()
    const iconMap: Record<IChartType, { icon: string; desc: string }> = {
      verticalBar: { icon: 'SolidChartChunk', desc: tsText('柱状图', 'Column Chart') },
      horizonBar: { icon: 'HorBarChart', desc: tsText('柱状图', 'Column Chart') },
      line: { icon: 'SolidChartSingle', desc: tsText('曲线图', 'Curve Chart') },
      relationship: { icon: 'Relationship', desc: tsText('关系图', 'Relation') },
    }
    const fieldList = computed<IFieldItem[]>(() => {
      if (props.options.length) {
        return props.options
      }

      const list = [{ value: 'table', icon: 'SolidContentFormatingTable', desc: tsText('表格', 'Table') }]
      if (props.chartType) {
        const config = iconMap[props.chartType]
        if (config) {
          list.unshift({ value: 'chart', icon: config.icon, desc: config.desc })
        }
      }
      return list
    })

    const handleChangeField = (val: string) => emit('changeField', val)

    return {
      fieldList,
      handleChangeField,
    }
  },
  render() {
    return (
      <div class={$classes.fieldContainer}>
        {this.fieldList.map((field) => (
          <GChartTypeButton
            key={field.value}
            icon={field.icon}
            text={field.label || field.desc}
            active={field.value === this.selectedValue}
            onClick={() => this.handleChangeField(field.value)}
          ></GChartTypeButton>
        ))}
      </div>
    )
  },
})
