import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
export const FilterCheckBoxLoading = () => {
  return (
    <ElSkeleton loading={true} animated count={1}>
      <template slot="template">
        <div class="mb-10">
          <div style="width:80%">
            {Array.from({ length: 6 }).map(() => {
              return (
                <div class="flex mb-4">
                  <ElSkeletonItem class="h-4 w-4"></ElSkeletonItem>
                  <ElSkeletonItem class="ml-2 h-4"></ElSkeletonItem>
                </div>
              )
            })}
          </div>
        </div>
      </template>
    </ElSkeleton>
  )
}
export const FilterItemLoading = () => {
  return (
    <ElSkeleton loading={true} animated count={1}>
      <template slot="template">
        <div class="mb-10">
          <ElSkeletonItem style="width:50%" class="h-6 mb-4"></ElSkeletonItem>
          <div style="width:80%">
            {Array.from({ length: 4 }).map(() => {
              return (
                <div class="flex mb-4">
                  <ElSkeletonItem class="h-4 w-4"></ElSkeletonItem>
                  <ElSkeletonItem class="ml-2 h-4"></ElSkeletonItem>
                </div>
              )
            })}
          </div>
        </div>
      </template>
    </ElSkeleton>
  )
}
