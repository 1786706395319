import { IBaseOrgWithItemInfo } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../config'
import { BOrganizationWithToolTipFetchListCard } from './BOrganizationWithToolTipFetchListCard'

export const BOrganizationWithToolTipPopperListCard = defineComponent({
  name: 'BOrganizationWithToolTipPopperListCard',
  props: {
    data: {
      type: Array as PropType<IBaseOrgWithItemInfo[]>,
      required: true,
    },
  },
  render() {
    return (
      <PtPopper data={{ data: this.data }} tippyConfig={getAsyncListTippyConfig()} component={BOrganizationWithToolTipFetchListCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
