import '@patsnap-ui/icon/assets/solid/DropdownLeft.svg'
import '@patsnap-ui/icon/assets/solid/DropdownRight.svg'
import { useMergedState } from '@pharmsnap/shared/composition'
import { computed, defineComponent, PropType, toRef } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import { GShortcutTooltip } from '../GTooltip/GShortcutTooltip'
import styles from './GDrawer.module.scss'
import cn from './locale/cn.json'
import en from './locale/en.json'
export const GDrawerToggle = defineComponent({
  name: 'GDrawerToggle',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String as PropType<'ltr' | 'rtl'>,
      default: 'rtl',
    },
  },
  setup(props, ctx) {
    const [pullout, setPullout] = useMergedState(false, {
      value: toRef(props, 'value'),
      onChange(val) {
        ctx.emit('change', val)
      },
    })
    const handleToggleClick = () => {
      setPullout(!pullout.value)
    }

    const dropdowns = computed(() => {
      return props.direction === 'rtl' ? ['SolidDropdownLeft', 'SolidDropdownRight'] : ['SolidDropdownRight', 'SolidDropdownLeft']
    })
    return {
      pullout,
      dropdowns,
      handleToggleClick,
    }
  },
  render() {
    return (
      <div class={[styles.gDrawerToggleContainer, this.pullout && styles.gDrawerTogglePullout]} onClick={this.handleToggleClick}>
        <GShortcutTooltip
          placement={this.direction === 'rtl' ? 'left' : 'right'}
          content={this.pullout ? this.$tc('collapse') : this.$tc('expand')}
          shortcut={']'}
        >
          <GIcon svgName={this.pullout ? this.dropdowns[1] : this.dropdowns[0]} size={16}></GIcon>
        </GShortcutTooltip>
      </div>
    )
  },
})
