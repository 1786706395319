import { I18nLang, IQueryItem } from '@patsnap/synapse_common_interface'
import { computed, reactive, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep } from 'lodash'
import { IFilterValueUiState } from '..'
import { IFilterAggFieldConfig, IFilterItemAggFieldConfigRecord, IFilterValueGroupNext, IFilterValueNext, IFilterValueRecord } from '../type'
import {
  getFilterValuesFromFilterGroups,
  getRealSimpleText,
  isEqualFilterValue,
  isEqualQueryValue,
  transQueryItems2FilterValueGroups,
} from '../utils'

export type IUseFilterSelectServiceOptions<IDENTITY extends string = string, AGG_FIELD extends string = string> = {
  initQueryItems: MaybeRef<IQueryItem[]>
  field: MaybeRef<IFilterItemAggFieldConfigRecord<IDENTITY, AGG_FIELD>>
  locale: MaybeRef<I18nLang>
  onBeforeAddValue?: (value: IFilterValueNext<IDENTITY, AGG_FIELD>) => boolean
  onBeforeRemoveGroup?: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>) => boolean | Promise<boolean>
  onBeforeRemoveGroupValue?: (
    group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>,
    value: IFilterValueNext<IDENTITY, AGG_FIELD>
  ) => boolean | Promise<boolean>
  onBeforeRemoveAllGroup?: (groups: IFilterValueGroupNext<IDENTITY, AGG_FIELD>[]) => boolean | Promise<boolean>
  onRemoveAllGroup?: () => void | Promise<void>
  onRemoveGroup?: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>) => void | Promise<void>
  onRemoveGroupValue?: (group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>, value: IFilterValueNext<IDENTITY, AGG_FIELD>) => void | Promise<void>
}

export function useFilterSelectService<IDENTITY extends string = string, AGG_FIELD extends string = string>(
  options: IUseFilterSelectServiceOptions<IDENTITY, AGG_FIELD>
) {
  const {
    initQueryItems,
    field: fieldConfigRecord,
    locale,
    onBeforeRemoveGroup,
    onBeforeRemoveGroupValue,
    onRemoveGroup,
    onRemoveGroupValue,
    onBeforeRemoveAllGroup,
    onRemoveAllGroup,
    onBeforeAddValue,
  } = options

  const filterValueGroupsObj = reactive({ groups: [] }) as { groups: IFilterValueGroupNext<IDENTITY, AGG_FIELD>[] }

  const filterValueListObj = reactive({ list: [] }) as { list: IFilterValueNext<IDENTITY, AGG_FIELD>[] }

  const filterValueTemporaryListObj = reactive({ list: [] }) as { list: IFilterValueNext<IDENTITY, AGG_FIELD>[] }

  const filterValueTemporaryListExcludeReal = computed(() => {
    const realFilterValues = filterValueListObj.list

    const temporaryFilterValues = filterValueTemporaryListObj.list

    const selectedFilterValuesNotInReal = temporaryFilterValues.filter((i) => !realFilterValues.some((j) => isEqualFilterValue(i, j)))

    return selectedFilterValuesNotInReal
  })

  const filterValueSelectedCountRecord = computed(() => {
    const selectedFilterValueList = filterValueTemporaryListObj.list

    const selectedCountRecord = selectedFilterValueList.reduce((record, item) => {
      const { identity } = item

      if (record[identity]) {
        record[identity] += 1
      } else {
        record[identity] = 1
      }

      return record
    }, {} as Record<IDENTITY, number>)

    return selectedCountRecord
  })

  const filterValueUiStateRecord = computed(() => {
    const realSelectedFilterValueList = filterValueListObj.list
    const selectedFilterValueList = filterValueTemporaryListObj.list

    const disabledUuids = realSelectedFilterValueList.map((i) => getUuid(i))

    const uiStateRecord = selectedFilterValueList.reduce((record, item) => {
      const realUuid = getUuid(item)

      record[realUuid] = {
        isSelected: true,
        isDisabled: disabledUuids.includes(realUuid),
      }

      return record
    }, {} as Record<string, IFilterValueUiState>)

    return uiStateRecord
  })

  const fieldLabelRecord = computed(() => {
    const configRecord = unref(fieldConfigRecord) as IFilterItemAggFieldConfigRecord<IDENTITY, AGG_FIELD>

    const fieldConfigList = Object.values(configRecord) as Array<IFilterAggFieldConfig<AGG_FIELD>>

    const fieldLabelRecord = fieldConfigList.reduce((record, config) => {
      if (config.type === 'single') {
        record[config.field] = getRealSimpleText(config.label, unref(locale))
      }
      if (config.type === 'group') {
        record[[...config.fields].sort().join('|')] = getRealSimpleText(config.label, unref(locale))
      }
      if (config.type === 'multi' || config.type === 'switch') {
        config.fields.forEach((subConfig) => {
          record[subConfig.field] = getRealSimpleText(subConfig.label, unref(locale))
        })
      }

      return record
    }, {} as Record<string, string>)

    return fieldLabelRecord
  })

  const fieldIdentityRecord = computed(() => {
    const configRecord = unref(fieldConfigRecord) as IFilterItemAggFieldConfigRecord<IDENTITY, AGG_FIELD>

    const identityKeys = Object.keys(configRecord) as IDENTITY[]

    const fieldIdentityRecord = identityKeys.reduce((record, identity) => {
      const config = configRecord[identity]

      if (config.type === 'single') {
        record[config.field] = identity
      }
      if (config.type === 'group') {
        config.fields.forEach((field) => {
          record[field] = identity
        })
      }
      if (config.type === 'multi' || config.type === 'switch') {
        config.fields.forEach((subConfig) => {
          record[subConfig.field] = identity
        })
      }

      return record
    }, {} as Record<AGG_FIELD, IDENTITY>)

    return fieldIdentityRecord
  })

  const filterFieldsInGroup = computed(() => {
    const { filterFields } = getFilterValuesFromFilterGroups(filterValueGroupsObj.groups)
    return filterFields
  })

  function getSubFilterValueRealRecord(fields: AGG_FIELD[]) {
    return getSubFilterValueRecord(fields, filterValueListObj.list)
  }

  function getSubFilterValueRealList(fields: AGG_FIELD[]) {
    return getSubFilterValueList(fields, filterValueListObj.list)
  }

  function addFilterValueToReal(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    return addFilterValue(value, filterValueListObj.list)
  }

  function removeFilterValueFromReal(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    return removeFilterValue(value, filterValueListObj.list)
  }

  function removeAllFilterValueFromReal(fields: AGG_FIELD[]) {
    return removeAllFilterValue(fields, filterValueListObj.list)
  }

  function getSubFilterValueTemporaryRecord(fields: AGG_FIELD[]) {
    return getSubFilterValueRecord(fields, filterValueTemporaryListObj.list)
  }

  function getSubFilterValueTemporaryList(fields: AGG_FIELD[]) {
    return getSubFilterValueList(fields, filterValueTemporaryListObj.list)
  }

  function addFilterValueToTemporary(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    return addFilterValue(value, filterValueTemporaryListObj.list)
  }

  function removeFilterValueFromTemporary(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    return removeFilterValue(value, filterValueTemporaryListObj.list)
  }

  function removeAllFilterValueFromTemporary(fields: AGG_FIELD[]) {
    return removeAllFilterValue(fields, filterValueTemporaryListObj.list)
  }

  async function clearAllFilterValueFromTemporary(checkDisable = true) {
    filterValueTemporaryListObj.list = checkDisable ? filterValueTemporaryListObj.list.filter((i) => checkIsDisabled(i)) : []
  }

  function getSubFilterValueRecord(field: AGG_FIELD[], filterValueList: IFilterValueNext<IDENTITY, AGG_FIELD>[]) {
    return field.reduce((record, field) => {
      record[field] = filterValueList.filter((i) => i.field === field)
      return record
    }, {} as IFilterValueRecord<IDENTITY, AGG_FIELD>)
  }

  function getSubFilterValueList(field: AGG_FIELD[], filterValueList: IFilterValueNext<IDENTITY, AGG_FIELD>[]) {
    return filterValueList.filter((i) => field.includes(i.field))
  }

  function addFilterValue(value: IFilterValueNext<IDENTITY, AGG_FIELD>, filterValueList: IFilterValueNext<IDENTITY, AGG_FIELD>[]) {
    if (onBeforeAddValue) {
      const shouldAdd = onBeforeAddValue(value)
      if (!shouldAdd) return
    }

    // 检查是否存在
    const index = filterValueList.findIndex((item) => isEqualFilterValue(item, value))
    // 存在就不添加
    if (index > -1) return

    filterValueList.push(value)
  }

  function removeFilterValue(value: IFilterValueNext<IDENTITY, AGG_FIELD>, filterValueList: IFilterValueNext<IDENTITY, AGG_FIELD>[]) {
    const index = filterValueList.findIndex((item) => isEqualFilterValue(item, value))
    if (index > -1) {
      filterValueList.splice(index, 1)
    }
  }

  function removeAllFilterValue(field: AGG_FIELD[], filterValueList: IFilterValueNext<IDENTITY, AGG_FIELD>[]) {
    const removedList = filterValueList.filter((i) => !field.includes(i.field))

    replaceOriginFilterValues(filterValueList, removedList)
  }

  function checkFieldIsInGroup(field: AGG_FIELD) {
    return unref(filterFieldsInGroup).includes(field)
  }

  async function removeFilterGroup(group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeRemoveGroup) {
      const shouldRemove = await onBeforeRemoveGroup(group)
      if (!shouldRemove) return
    }

    const { fields } = group

    const index = filterValueGroupsObj.groups.findIndex((i) => i === group)

    if (index > -1) {
      filterValueGroupsObj.groups.splice(index, 1)
      removeAllFilterValueFromTemporary(fields)
      removeAllFilterValueFromReal(fields)
      await onRemoveGroup?.(group)
    }
  }

  async function removeFilterGroupValue(group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>, value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    if (onBeforeRemoveGroupValue) {
      const shouldRemove = await onBeforeRemoveGroupValue(group, value)
      if (!shouldRemove) return
    }

    const index = group.values.findIndex((i) => isEqualFilterValue(i, value))

    if (index > -1) {
      // 判断下当前group是否只有一个值，如果只有一个值，那么就删除整个group，否则只删除当前值
      if (group.values.length === 1) {
        removeFilterGroup(group)
        return
      }

      group.values.splice(index, 1)
      updateFilterGroupOriginQueryItem(group, value)
      removeFilterValueFromTemporary(value)
      removeFilterValueFromReal(value)
      await onRemoveGroupValue?.(group, value)
    }
  }

  async function removeAllFilterGroup() {
    if (onBeforeRemoveAllGroup) {
      const shouldRemove = await onBeforeRemoveAllGroup(filterValueGroupsObj.groups)
      if (!shouldRemove) return
    }

    const allSavedFields = cloneDeep(unref(filterFieldsInGroup))

    filterValueGroupsObj.groups.splice(0, filterValueGroupsObj.groups.length)
    removeAllFilterValueFromTemporary(allSavedFields)
    removeAllFilterValueFromReal(allSavedFields)
    await onRemoveAllGroup?.()
  }

  function updateFilterGroupOriginQueryItem(group: IFilterValueGroupNext<IDENTITY, AGG_FIELD>, value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    const originQueryItem = group._meta.queryItem
    const originQuerySubType = group._meta.querySubType

    if (originQueryItem.type === 'field') {
      const originQueryValues = originQueryItem.value
      const index = originQueryValues.findIndex((i) => isEqualQueryValue(i, value))
      if (index > -1) {
        originQueryValues.splice(index, 1)
      }
    }

    if (originQueryItem.type === 'group') {
      if (!originQuerySubType) return
      const originQueryValues = originQueryItem[originQuerySubType] || []
      originQueryValues.forEach((queryItem, queryItemIndex) => {
        if (queryItem.type === 'field') {
          const index = queryItem.value.findIndex((i) => isEqualQueryValue(i, value))
          if (index > -1) {
            queryItem.value.splice(index, 1)
          }
          if (queryItem.value.length === 0) {
            originQueryValues.splice(queryItemIndex, 1)
          }
        }
      })
    }
  }

  function getFieldLabelByField(field: AGG_FIELD) {
    const label = unref(fieldLabelRecord)[field]

    if (label) return label

    const filedGroupStrList = Object.keys(unref(fieldLabelRecord))

    const foundGroupStr = filedGroupStrList.find((groupStr) => groupStr.split('|').includes(field))

    if (foundGroupStr) {
      return unref(fieldLabelRecord)[foundGroupStr]
    }

    return ''
  }

  function getUuid(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    return [value.identity, value.field, value.uuid].map(String).join('|')
  }

  function checkIsSelected(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    const realUuid = getUuid(value)

    return unref(filterValueUiStateRecord)[realUuid]?.isSelected ?? false
  }

  function checkIsDisabled(value: IFilterValueNext<IDENTITY, AGG_FIELD>) {
    const realUuid = getUuid(value)

    return unref(filterValueUiStateRecord)[realUuid]?.isDisabled ?? false
  }

  function syncInit() {
    const filterValueGroupTpl = cloneDeep(
      transQueryItems2FilterValueGroups(unref(initQueryItems), unref(fieldIdentityRecord))
    ) as IFilterValueGroupNext<IDENTITY, AGG_FIELD>[]

    const filterValueListTpl = cloneDeep(getFilterValuesFromFilterGroups(filterValueGroupTpl)).filterValues

    const filterValueTemporaryListTmp = cloneDeep(getFilterValuesFromFilterGroups(filterValueGroupTpl)).filterValues

    filterValueGroupTpl.forEach((group) => {
      const groupFieldStr = [...group.fields].sort().join('|')
      group.label = unref(fieldLabelRecord)[groupFieldStr]
    })

    filterValueGroupsObj.groups = filterValueGroupTpl

    filterValueListObj.list = filterValueListTpl

    filterValueTemporaryListObj.list = filterValueTemporaryListTmp
  }

  function replaceOriginFilterValues(
    originalFilterValues: IFilterValueNext<IDENTITY, AGG_FIELD>[],
    targetFilterValues: IFilterValueNext<IDENTITY, AGG_FIELD>[]
  ) {
    originalFilterValues.splice(0, originalFilterValues.length, ...targetFilterValues)
  }

  function getOriginQueryItems(exclude: AGG_FIELD[] = []) {
    const queryItems = filterValueGroupsObj.groups.filter((i) => i.fields.every((i) => !exclude.includes(i))).map((i) => cloneDeep(i._meta.queryItem))

    return queryItems
  }

  watch(
    () => unref(initQueryItems),
    () => {
      syncInit()
    }
  )

  return {
    filterValueGroups: computed(() => filterValueGroupsObj.groups),
    filterValueList: computed(() => filterValueListObj.list),
    filterValueTemporaryList: computed(() => filterValueTemporaryListObj.list),
    filterValueTemporaryListExcludeReal,
    filterValueSelectedCountRecord,
    fieldLabelRecord,
    checkIsSelected,
    checkIsDisabled,
    checkFieldIsInGroup,
    getSubFilterValueRealRecord,
    getOriginQueryItems,
    getSubFilterValueRealList,
    addFilterValueToReal,
    removeFilterValueFromReal,
    getSubFilterValueTemporaryRecord,
    getSubFilterValueTemporaryList,
    addFilterValueToTemporary,
    removeFilterValueFromTemporary,
    removeAllFilterValueFromReal,
    removeAllFilterValueFromTemporary,
    removeFilterGroup,
    removeFilterGroupValue,
    removeAllFilterGroup,
    getFieldLabelByField,
    clearAllFilterValueFromTemporary,
    syncInit,
  }
}
