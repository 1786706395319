/* eslint-disable @typescript-eslint/no-explicit-any */
import '@patsnap-ui/icon/assets/solid/CloseBig.svg'
import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import Vue, { Component, VNodeData } from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import { GIcon } from '../..'
import { ElDialog } from '../../../element-ui'
import $classes from './GDialog.module.scss'

export const GDialog = defineComponent({
  name: 'GDialog',
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    emptyMode: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },
    width: {
      type: String,
      default: '50%',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: '15vh',
    },
    modal: {
      type: Boolean,
      default: true,
    },
    modalAppendToBody: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: '',
    },
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    closeOnClickCancel: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    beforeClose: {
      type: Function as PropType<(done: () => void) => void>,
    },
    onOpen: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onOpened: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onClose: {
      type: Function as PropType<(e?: Event) => void>,
      default: () => undefined,
    },
    onClosed: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onCancel: {
      type: Function as PropType<(e?: Event) => void>,
      default: () => undefined,
    },
    onConfirm: {
      type: Function as PropType<(e?: Event) => void>,
      default: () => undefined,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showConfirm: {
      type: Boolean,
      default: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
    },
    confirmType: {
      type: String as PropType<'submit' | 'danger' | 'default'>,
      default: 'submit',
    },
    footerTop: {
      type: String,
      default: '',
    },
    footerLayout: {
      type: String as PropType<'right' | 'between'>,
      default: 'right',
    },
    title: {
      type: String,
    },
    closeIconSize: {
      type: Number,
      default: 24,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const onInput = (visible: boolean) => {
      ctx.emit('input', visible)
    }

    const modelClass = computed(() => {
      const cls = [props.customClass, $classes.gDialog]
      if (props.size === 'medium') {
        cls.push($classes.gDialogMedium)
      }
      if (props.size === 'small') {
        cls.push($classes.gDialogSmall)
      }
      if (props.footerLayout === 'between' && props.showCancel && props.showConfirm) {
        cls.push($classes.gDialogFooterBetween)
      } else {
        cls.push($classes.gDialogFooterRight)
      }
      if (props.footerLayout === 'right') {
        cls.push($classes.gDialogFooterRight)
      }
      if (props.emptyMode) {
        cls.push($classes.gDialogEmptyMode)
      }

      return cls
    })

    const onClickCancel = (e?: Event) => {
      if (props.closeOnClickCancel) {
        ctx.emit('input', false)
      }
      ctx.emit('cancel', e)
    }

    const onClickConfirm = (e?: Event) => {
      ctx.emit('confirm', e)
    }

    const onClickClose = (e?: Event) => {
      ctx.emit('input', false)
      ctx.emit('close', e)
    }

    return {
      onInput,
      modelClass,
      onClickCancel,
      onClickClose,
      onClickConfirm,
    }
  },
  methods: {
    renderClose() {
      const shouldRenderClose = !!this.showClose
      if (!shouldRenderClose) return null

      return (
        <GIcon
          nativeOn={{ click: this.onClickClose }}
          data-testid="g-dialog-close"
          class={this.emptyMode ? $classes.gDialogEmptyModeClose : $classes.gDialogClose}
          useSvgDefaultColor={true}
          svgName="SolidCloseBig"
          size={this.closeIconSize}
        ></GIcon>
      )
    },
    renderCancel() {
      return (
        <button class="pt-ui-btn" type="button" data-type="default" disabled={this.cancelDisabled} onClick={this.onClickCancel}>
          {this.cancelText || this.$tc('common.cancel')}
        </button>
      )
    },
    renderConfirm() {
      return (
        <button
          class="pt-ui-btn ml-2"
          type="button"
          data-type={this.confirmType}
          disabled={this.confirmDisabled || this.confirmLoading}
          onClick={this.onClickConfirm}
        >
          {this.confirmText || this.$tc('common.create')}
        </button>
      )
    },
    renderHeader() {
      if (this.emptyMode) return null

      const shouldRenderTitle = !!this.title || this.$slots.title

      const titleContent = shouldRenderTitle ? this.$slots.title || <div class={$classes.gDialogTitle}>{this.title}</div> : null
      return (
        shouldRenderTitle && (
          <div class={$classes.gDialogHeader}>
            {titleContent}
            {this.renderClose()}
          </div>
        )
      )
    },
    renderBody() {
      const shouldRenderTitle = !!this.title || this.$slots.title

      if (this.emptyMode) {
        return (
          <div class={shouldRenderTitle ? $classes.gDialogBody : ''}>
            {(this.$scopedSlots &&
              this.$scopedSlots.default &&
              this.$scopedSlots.default({ renderClose: this.renderClose, renderCancel: this.renderCancel, renderConfirm: this.renderConfirm })) ||
              this.$slots.default}
          </div>
        )
      }
      return <div class={shouldRenderTitle ? $classes.gDialogBody : ''}>{this.$slots.default}</div>
    },
    renderFooter() {
      const shouldRenderDefaultFooter = !this.$slots.footer

      if (!this.showFooter || this.emptyMode) return null

      if (this.$scopedSlots && this.$scopedSlots.customFooter) {
        return (
          <div style={this.footerTop ? { marginTop: this.footerTop } : {}} class={$classes.gDialogFooter}>
            {this.$scopedSlots.customFooter({ renderCancel: this.renderCancel, renderConfirm: this.renderConfirm })}
          </div>
        )
      }

      if (shouldRenderDefaultFooter) {
        return (
          <div style={this.footerTop ? { marginTop: this.footerTop } : {}} class={$classes.gDialogFooter}>
            {this.showCancel ? this.renderCancel() : null}
            {this.showConfirm ? this.renderConfirm() : null}
          </div>
        )
      }

      return (
        <div style={this.footerTop ? { marginTop: this.footerTop } : {}} class={$classes.gDialogFooter}>
          {this.$slots.footer}
        </div>
      )
    },
  },
  render() {
    return (
      <ElDialog
        class={$classes.gDialogWrapper}
        visible={this.visible}
        width={this.width}
        fullScreen={this.fullScreen}
        top={'0px'}
        modal={this.modal}
        customClass={this.modelClass.join(' ')}
        modalAppendToBody={this.modalAppendToBody}
        appendToBody={this.appendToBody}
        closeOnClickModal={this.closeOnClickModal}
        closeOnPressEscape={this.closeOnPressEscape}
        destroyOnClose={this.destroyOnClose}
        beforeClose={this.beforeClose}
        onInput={this.onInput}
        onOpen={this.onOpen}
        onOpened={this.onOpened}
        onClose={this.onClickClose}
        onClosed={this.onClosed}
      >
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </ElDialog>
    )
  },
})

const GDialogConstructor = Vue.extend(GDialog)

export function showGDialog(
  option: InstanceType<typeof GDialog>['$props'] & { component: Component; componentProps?: any; componentVNodeData?: VNodeData },
  router?: VueRouter,
  i18n?: VueI18n,
  listeners?: Record<string, (...args: any[]) => any>
) {
  const { onClose, onConfirm, onCancel } = option

  const dom = document.createElement('div')

  document.body.appendChild(dom)

  const dialogVm = new GDialogConstructor({
    router,
    i18n,
    propsData: option,
  })

  const slotVNode = dialogVm.$createElement(option.component, {
    props: option.componentProps || {},
    ...option.componentVNodeData,
  })
  dialogVm.$slots.default = [slotVNode]
  dialogVm.$mount(dom)

  const destroyDialog = () => {
    if (dialogVm) {
      dialogVm.$destroy()
    }
    if (dialogVm?.$el) {
      dialogVm.$el.remove()
    }
  }

  const onCloseCb = () => {
    onClose && onClose()
    destroyDialog()
  }

  const onConfirmCb = () => {
    onConfirm && onConfirm()
    destroyDialog()
  }

  const onCancelCb = () => {
    onCancel && onCancel()
    destroyDialog()
  }

  dialogVm?.$on('close', onCloseCb)
  dialogVm?.$on('confirm', onConfirmCb)
  dialogVm?.$on('cancel', onCancelCb)

  dialogVm.$nextTick(() => {
    const slotComponentInstance = slotVNode.componentInstance
    if (listeners) {
      const keys = Object.keys(listeners)
      keys.forEach((key) => {
        listeners[key] && slotComponentInstance?.$on(key, listeners[key])
      })
    }
    slotComponentInstance?.$on('close', onCloseCb)
    slotComponentInstance?.$on('confirm', onConfirmCb)
    slotComponentInstance?.$on('cancel', onCancelCb)
  })
  return dialogVm
}
