import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { VUE_APP_LS360_URL } from '@pharmsnap/pharmsnap-web/config'
import { E_ROUTER_NAME, E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/src/composition/useAuthStore'
import { openNewTab } from '@pharmsnap/shared/src/utils'
import { getCurrentInstance } from '@vue/composition-api'
import { map, noop } from 'lodash'
import { BLazyLoadImg, GIcon } from 'pharmsnapMF_shared/components'
import featureBgImg from '../assets/paid_feature.png'
import { UPGRADE_GIF_MAP } from '../constant'
import styles from '../Upgrade.module.scss'

export function useUpgrade() {
  const ins = getCurrentInstance()
  const {
    state: { upgrade },
    getters: { isFreeUser },
    actions: { changeUpgrade },
  } = useAuthStore()
  const $router = ins?.proxy?.$router

  const closeUpgrade = () => {
    changeUpgrade({ ...upgrade.value, show: false, onViewPlanCallback: noop })
  }

  const onViewPlans = (triggerPoint?: string) => {
    const link = $router?.resolve({
      name: E_ROUTER_NAME.PLANS,
      query: {
        trigger_point: triggerPoint,
        from_page: ins?.root.proxy.$route.name,
      },
    }).href

    if (link) {
      openNewTab(link)
    }
  }

  const onViewChatPlans = (triggerPoint?: string, fromPageName?: string) => {
    openPageWithoutPending(
      () => `${VUE_APP_LS360_URL}/user/plans?trigger_point=${triggerPoint}&from_page=SYNAPSE_${fromPageName || ins?.root.proxy.$route.name}`
    )
  }

  const onConfirm = (triggerPoint?: string, type?: E_UPGRADE_TYPE) => {
    if (type === E_UPGRADE_TYPE.CHAT_EXCEEDS_MAX_LIMIT) {
      return onViewChatPlans(triggerPoint)
    }
    if (upgrade.value.onViewPlanCallback) {
      upgrade.value.onViewPlanCallback()

      closeUpgrade()
    }

    if (type === E_UPGRADE_TYPE.GROUP_EXCEEDS_EXPORT_MAX_LIMIT) {
      closeUpgrade()
    } else {
      onViewPlans(triggerPoint)
    }
  }

  function renderGIfUpgrade(type: E_UPGRADE_TYPE) {
    const options = UPGRADE_GIF_MAP[type]
    return (
      <div>
        {options?.gifFile && <BLazyLoadImg src={options?.gifFile} class="mb-3 w-[642px] h-[350px]"></BLazyLoadImg>}
        <div class="text-left text-text-t1">{ins?.proxy.$tc(options?.i18nKey as string)}</div>
      </div>
    )
  }

  function renderContent(type?: E_UPGRADE_TYPE) {
    const finalType = type || upgrade.value.type

    if (finalType === E_UPGRADE_TYPE.CONTACT_SALES) {
      return null
    }
    return {
      [E_UPGRADE_TYPE.CHAT_EXCEEDS_MAX_LIMIT]: (
        <div class={styles.background}>
          <div class="mb-2">{ins?.proxy.$tc('upgrade.maxLimit.content')}</div>
          {map([ins?.proxy.$tc('upgrade.maxLimit.chatList.1'), '...'], (content) => {
            return (
              <div class="flex items-center mb-2">
                <GIcon svgName="SolidTick" color="#45A321" size={24} class="mr-3 flex-shrink-0" />
                {content}
              </div>
            )
          })}
        </div>
      ),
      [E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT]: (
        <div class={styles.background}>
          <div class="mb-2">{ins?.proxy.$tc('upgrade.maxLimit.content')}</div>
          {map([ins?.proxy.$tc('upgrade.maxLimit.list.1'), ins?.proxy.$tc('upgrade.maxLimit.list.2'), '...'], (content) => {
            return (
              <div class="flex items-center mb-2">
                <GIcon svgName="SolidTick" color="#45A321" size={24} class="mr-3 flex-shrink-0" />
                {content}
              </div>
            )
          })}
        </div>
      ),
      [E_UPGRADE_TYPE.GROUP_EXCEEDS_EXPORT_MAX_LIMIT]: (
        <div class={styles.background}>
          <img class="mb-4 block mx-auto" src={featureBgImg} />
          <div class="text-center" style="word-break: break-word">
            {upgrade.value.description || ins?.proxy.$tc('upgrade.maxLimit.groupExportLimit')}
          </div>
        </div>
      ),
      [E_UPGRADE_TYPE.EXCEEDS_FEATURE_MAX_LIMIT]: (
        <div class={styles.background}>
          <img class="mb-4 block mx-auto" src={featureBgImg} />
          <div class="text-center">
            {isFreeUser.value ? ins?.proxy.$tc('upgrade.maxLimit.removeLimit') : ins?.proxy.$tc('upgrade.maxLimit.content')}
          </div>
        </div>
      ),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL]: (
        <div class={styles.background}>
          <img class="mb-4 block mx-auto" src={featureBgImg} />
          <div class="text-center">{upgrade.value.description || ins?.proxy.$tc('upgrade.paidFeature.content')}</div>
        </div>
      ),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_COMPANY]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_COMPANY),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_FINANCING]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_FINANCING),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_INVESTMENT]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_INVESTMENT),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_GROUP_BY]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_GROUP_BY),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_KG]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_KG),
      [E_UPGRADE_TYPE.USE_PAID_FEATURE_WORKSPACE]: renderGIfUpgrade(E_UPGRADE_TYPE.USE_PAID_FEATURE_WORKSPACE),
    }[finalType]
  }

  function renderTitle(type?: E_UPGRADE_TYPE) {
    const finalType = type || upgrade.value.type
    return finalType === E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT ||
      finalType === E_UPGRADE_TYPE.GROUP_EXCEEDS_EXPORT_MAX_LIMIT ||
      finalType === E_UPGRADE_TYPE.CHAT_EXCEEDS_MAX_LIMIT
      ? ins?.proxy.$tc('upgrade.maxLimit.title')
      : isFreeUser.value
      ? ins?.proxy.$tc('upgrade.paidFeature.title')
      : ins?.proxy.$tc('upgrade.advanceFeature.title')
  }

  return {
    onViewPlans,
    onViewChatPlans,
    onConfirm,
    closeUpgrade,
    renderTitle,
    renderContent,
    renderGIfUpgrade,
  }
}
