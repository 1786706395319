import { alertEntityConfig, alertType2AlertEntityTypeMap } from '@patsnap/synapse_common_config'
import { IAlertConfig, IAlertTopEventSettingType, IAlertType } from '@patsnap/synapse_common_interface'
import { VUE_APP_STATIC_PATH } from '@pharmsnap/pharmsnap-web/config'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import cn from '../../locales/cn.json'
import en from '../../locales/en.json'
const demoImgMap: Record<IAlertType, { en: string; cn: string }> = {
  drug_search: {
    cn: 'drug_search_cn.png',
    en: 'drug_search_en.png',
  },
  drug_deal_search: {
    cn: 'drug_deal_search_cn.png',
    en: 'drug_deal_search_en.png',
  },
  translational_medicine_search: {
    cn: 'translational_medicine_cn.png',
    en: 'translational_medicine_en.png',
  },
  organization_detail: {
    cn: 'organization_detail_cn.png',
    en: 'organization_detail_en.png',
  },
  clinical_trial_result_search: {
    cn: 'clinical_trial_result_search_cn.png',
    en: 'clinical_trial_result_search_en.png',
  },
  clinical_trial_search: {
    cn: 'clinical_trial_search_cn.png',
    en: 'clinical_trial_search_en.png',
  },
  clinical_trial_detail: {
    cn: 'clinical_trial_detail_cn.png',
    en: 'clinical_trial_detail_en.png',
  },
  drug_detail: {
    cn: 'drug_detail_cn.png',
    en: 'drug_detail_en.png',
  },
  patent_detail: {
    cn: 'patent_detail_cn.png',
    en: 'patent_detail_en.png',
  },
  patent_search: {
    cn: 'patent_search_cn.png',
    en: 'patent_search_en.png',
  },
  target_detail: {
    cn: 'target_detail_cn.png',
    en: 'target_detail_en.png',
  },
  disease_detail: {
    cn: 'disease_detail_cn.png',
    en: 'disease_detail_en.png',
  },
  drug_type_detail: {
    cn: 'drug_type_detail_cn.png',
    en: 'drug_type_detail_en.png',
  },
  drug_search_group_by_drug_type: {
    cn: 'drug_search_group_by_drug_type_cn.png',
    en: 'drug_search_group_by_drug_type_en.png',
  },
  drug_search_group_by_target: {
    cn: 'drug_search_group_by_target_cn.png',
    en: 'drug_search_group_by_target_en.png',
  },
  drug_search_group_by_organization: {
    cn: 'drug_search_group_by_organization_cn.png',
    en: 'drug_search_group_by_organization_en.png',
  },
  drug_search_group_by_disease: {
    cn: 'drug_search_group_by_disease_cn.png',
    en: 'drug_search_group_by_disease_en.png',
  },
}
export const BEmailPreview = defineComponent({
  name: 'BEmailPreview',
  props: {
    alertKeys: {
      type: Array as PropType<IAlertTopEventSettingType[]>,
      required: true,
    },
    alertConfig: {
      type: Object as PropType<Partial<IAlertConfig>>,
      require: true,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props) {
    const { tsLangMap, $t, isCN } = useLocale()
    const alertType = computed(() => {
      return props.alertConfig?.alert_type as IAlertType
    })
    const previewImg = computed(() => {
      const imgName = isCN.value ? demoImgMap[alertType.value].cn : demoImgMap[alertType.value].en
      return `${VUE_APP_STATIC_PATH}/static/img/email_alert_preview/${imgName}`
    })
    const searchResultEntityType = computed(() => {
      return alertType2AlertEntityTypeMap[alertType.value]
    })
    const searchResultEntityName = computed(() => {
      return tsLangMap(alertEntityConfig[searchResultEntityType.value])
    })
    const previewTitle = computed(() => {
      if (props.alertConfig?.title) {
        return props.alertConfig.title
      }
      return $t(`BEmailAlert.previewTitle`, {
        type: searchResultEntityName.value,
      }) as string
    })
    return { previewTitle, previewImg }
  },
  render() {
    return (
      <div class="bg-[#EAF2FB] py-5 px-6 flex flex-col">
        <div class="text-sm font-semibold">{this.$t('BEmailAlert.receiveAlert')}</div>
        <div class="mt-4 rounded flex flex-col flex-1 overflow-hidden">
          <div class="py-2 px-4" style="background: linear-gradient(90deg, #3097FC 0%, #1976D2 100%);">
            <div class="text-white-default text-sm font-semibold">{this.previewTitle}</div>
          </div>
          <div class="flex-1 bg-white-default overflow-hidden">
            <img class="w-full" src={this.previewImg}></img>
          </div>
        </div>
      </div>
    )
  },
})
