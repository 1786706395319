import { Data } from '@vue/composition-api'
import { PropValidator } from 'vue/types/options'

export type ComponentObjectPropsOptions<P = Data> = {
  [K in keyof P]: PropValidator<P[K]> | null
}

export function defineComponentProps<
  // the Readonly constraint allows TS to treat the type of { required: true }
  // as constant instead of boolean.
  T extends ComponentObjectPropsOptions
>(props: T): Readonly<T> {
  return props
}
