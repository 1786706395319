import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import type { IAutoCompleteService, IAutoCompleteType, IDicCode } from '../types/autocomplete'

/**
 * 字典的类型
 */
export const dicCodeMap: Partial<Record<IAutoCompleteType, IDicCode>> = {
  DrugType: 'DRUG_TYPE',
  ATC: 'ATC_CODE',
  Phase: 'DEV_STATUS',
  Regulation: 'APPROVAL_COUNTRY_TUPLE',
  ADC_Linker: 'DRUG_LINKER',
  ADC_Payload: 'DRUG_PAYLOAD',
  ADC_Antibody_Type: 'DRUG_ANTIBODY_TYPE',
  ADC_SITE: 'DRUG_SITE_NAME',
  PATENT_TECHNOLOGY: 'PATENT_TECHNOLOGY',
  DATA_SOURCE_MEETING: 'DATA_SOURCE_MEETING',
}

/**
 * type转到调用的service
 */
export const type2ServiceKeyMap: Record<IAutoCompleteType, IAutoCompleteService> = {
  Drug: 'drug',
  Organization: 'organization',
  Target: 'target',
  Disease: 'disease',
  Mechanism: 'mechanism',
  DrugType: 'dictionary',
  ATC: 'dictionary',
  Core: 'core',
  Phase: 'dictionary',
  TherapeuticArea: 'disease',
  Regulation: 'dictionary',
  Region: 'region',
  Topic: 'topic',
  PatentNumber: 'patent',
  Outcome: 'outcome',
  StudyCode: 'studyCode',
  ADC_Antibody: 'drug',
  ADC_Linker: 'dictionary',
  ADC_Payload: 'dictionary',
  ADC_Antibody_Type: 'dictionary',
  ADC_SITE: 'dictionary',
  PATENT_TECHNOLOGY: 'dictionary', // todo 为了先解决common包升级报错
  DATA_SOURCE_MEETING: 'dictionary',
}

/**
 * 获取autocomplete的时候需要传is_root的类型
 */
export const isRootConfig: IAutoCompleteType[] = ['TherapeuticArea']

export const LogicTagConfig: Record<string, string> = {
  all: 'AND',
  any: 'OR',
  none: 'NOT',
}

export const LogicSortConfig = [E_QUERY_ITEM_CONDITION.ANY, E_QUERY_ITEM_CONDITION.ALL]
