import { IHttp } from '../http/types'

export class LoginBaseApi {
  constructor(
    protected http: IHttp,
    private baseUrl: string,
    private passportBaseUrl: string,
    private accountBaseUrl: string,
    private weChatBaseUrl: string
  ) {}
  protected getBaseUrl(url: string) {
    return `${this.baseUrl}${url}`
  }
  protected getPassportUrl(url: string) {
    return `${this.passportBaseUrl}${url}`
  }
  protected getAccountUrl(url: string) {
    return `${this.accountBaseUrl}${url}`
  }
  protected getWechatUrl(url: string) {
    return `${this.weChatBaseUrl}${url}`
  }
}
