import { getUaEngine } from '@pharmsnap/shared/src/utils/ua'
// canvas size https://jhildenbiddle.github.io/canvas-size/#/?id=test-results
function getCanvasSize() {
  const browserEngine = getUaEngine()
  switch (browserEngine.name?.toLowerCase()) {
    case 'blink': {
      if (browserEngine.major && browserEngine.major >= 73) {
        return [50 * 1000, 50 * 1000, 250 * 1000 * 1000]
      }
      return [30 * 1000, 30 * 1000, 250 * 1000 * 1000]
    }
    case 'gecko': {
      if (browserEngine.major && browserEngine.major >= 122) {
        return [30 * 1000, 30 * 1000, 500 * 1000 * 1000]
      }
      return [30 * 1000, 30 * 1000, 120 * 1000 * 1000]
    }
    default: {
      throw new Error('Browser is not support')
    }
  }
}

export function adapterCanvasSize(width: number, height: number) {
  const canvasSize = getCanvasSize()
  const [maxW, maxH, maxArea] = canvasSize
  let ratio = 1
  if (width > height && width > maxW) {
    ratio = maxW / width
  } else if (height > maxH) {
    ratio = maxH / height
  }
  let realWidth = ratio * width
  let realHeight = ratio * height
  while (realWidth * realHeight > maxArea) {
    realWidth = realWidth * 0.9
    realHeight = realHeight * 0.9
    ratio = ratio * 0.9
  }
  return [realWidth, realHeight, ratio]
}
