import type { AlertBeFrequency, EmailAlertType, IQueryDataType } from '@patsnap/synapse_common_interface'
import { I18nLang } from '../i18n'
import { AlertFeFrequency } from '../types'

export const HistoryEmails = 'HistoryEmails'

/**
 * email alert frequency前端与后台的转换
 */
export const frequencyFE2BEConfig: Record<AlertFeFrequency, AlertBeFrequency> = {
  D: 'DAILY',
  W: 'WEEKLY',
  M: 'MONTHLY',
}

export const frequencyBE2FEConfig: Record<AlertBeFrequency, AlertFeFrequency> = {
  DAILY: 'D',
  WEEKLY: 'W',
  MONTHLY: 'M',
}

export const BindEmailConfig = {
  VERIFICATION_CODE_INTERVAL: 59,
  EMAIL_VERIFY_CODE_INTERVAL: 1000,
  VERIFICATION_EMAIL_CODE_INTERVAL: 59,
}

export const REVERSE_LANGUAGE_MAP: Record<I18nLang, string> = {
  cn: 'zh_CN',
  en: 'en_US',
}

/**
 * alert转datatype，为了query转display的时候使用
 */
export const REVERSE_ALERT_TYPE_2_DATA_TYPE: Record<Exclude<EmailAlertType, 'ALL'>, IQueryDataType> = {
  DRUG_SEARCH: 'drug',
  DRUG_DETAIL: 'drug',
  TARGET: 'target',
  ORGANIZATION: 'organization',
}
