import { IBullsEyeChartDataItem, IRelatedMarkerItem } from '@patsnap/synapse_bullseye_chart'
import { IBullsEyeChartItemWithOriginData } from '@pharmsnap/shared/src/types'
import { onBeforeUnmount, ref, shallowRef } from '@vue/composition-api'
import { debounce } from 'lodash'
import tippy, { Instance, Props } from 'tippy.js'

export function useAnalysisBullsEyeChartTooltip() {
  const hoverMarkerCountData = ref<IBullsEyeChartItemWithOriginData>()
  const hoverMarker = ref<IRelatedMarkerItem>()
  const hoverAngleAxisData = ref<IBullsEyeChartDataItem>()
  const hoverType = ref<'marker' | 'markerCount' | 'angleAxis'>()
  const popperIns = shallowRef<Instance | null>(null)
  const tooltipRef = shallowRef<HTMLDivElement | null>(null)

  let timer: NodeJS.Timeout

  const createTooltipInstance = (node: Element, optionalProps: Partial<Props> = {}) => {
    // destroy previous instance
    if (popperIns.value) {
      destroyTooltipIns()
    }
    popperIns.value = tippy(node, {
      placement: 'right-start',
      interactive: true,
      trigger: 'manual',
      appendTo: document.body,
      delay: [50, 50],
      arrow: false,
      allowHTML: true,
      offset: [10, 10],
      zIndex: 10,
      ...optionalProps,
      onShow: (ins) => {
        if (tooltipRef.value) {
          ins.setContent(tooltipRef.value)
          return
        }
        return false
      },
    })
  }

  const destroyTooltipIns = () => {
    popperIns.value?.destroy()
    popperIns.value = null
  }

  const handleMarkerCountMouseOver = (params: { data: IBullsEyeChartItemWithOriginData; node: Element }) => {
    timer && clearTimeout(timer)
    hoverType.value = 'markerCount'
    hoverMarkerCountData.value = params.data

    createTooltipInstance(params.node)
    popperIns.value?.show()
  }

  const debounceMarkerCountMouseOver = debounce(handleMarkerCountMouseOver, 300)

  const handleMarkerCountMouseOut = () => {
    debounceMarkerCountMouseOver.cancel()
    timer = setTimeout(() => {
      hoverType.value = undefined
      destroyTooltipIns()
    }, 500)
  }

  const handleMarkerMouseOver = (params: { data: IRelatedMarkerItem; node: Element }) => {
    timer && clearTimeout(timer)
    hoverType.value = 'marker'
    hoverMarker.value = params.data

    createTooltipInstance(params.node)
    popperIns.value?.show()
  }

  const debounceMarkerMouseOver = debounce(handleMarkerMouseOver, 300)

  const handleMarkerMouseOut = () => {
    debounceMarkerMouseOver.cancel()
    timer = setTimeout(() => {
      hoverType.value = undefined
      destroyTooltipIns()
    }, 500)
  }

  const handleAngleAxisMouseOver = (params: { data: IBullsEyeChartDataItem; node: Element }) => {
    timer && clearTimeout(timer)
    hoverType.value = 'angleAxis'
    hoverAngleAxisData.value = params.data

    createTooltipInstance(params.node, { placement: 'bottom', offset: [0, 0] })
    popperIns.value?.show()
  }

  const debounceAngleAxisMouseOver = debounce(handleAngleAxisMouseOver, 300)

  const handleAngleAxisMouseOut = () => {
    debounceAngleAxisMouseOver.cancel()
    timer = setTimeout(() => {
      hoverType.value = undefined
      destroyTooltipIns()
    }, 500)
  }

  const handleMouseOverTooltip = () => {
    timer && clearTimeout(timer)
  }

  const handleMouseOutTooltip = () => {
    hoverType.value = undefined
    destroyTooltipIns()
  }

  onBeforeUnmount(() => {
    timer && clearTimeout(timer)
    destroyTooltipIns()
  })

  return {
    hoverMarkerCountData,
    hoverMarker,
    hoverAngleAxisData,
    tooltipRef,
    hoverType,
    debounceMarkerMouseOver,
    handleMarkerCountMouseOut,
    debounceMarkerCountMouseOver,
    handleMarkerMouseOut,
    handleMouseOverTooltip,
    handleMouseOutTooltip,
    debounceAngleAxisMouseOver,
    handleAngleAxisMouseOut,
  }
}
