import { setCookieInBrowser } from '@pharmsnap/pharmsnap-web/utils/dom'
import jwtDecode from 'jwt-decode'
const oneYearTime = 365 * 24 * 60 * 60 * 1000
const sessionKey = 'sy_session'
interface TokenInfo {
  client_id: string
  session: string
  products: string[]
}
export function setSynapseLoginCookie(token: string) {
  setCookieInBrowser(sessionKey, token, oneYearTime)
}
export function removeSynapseLoginCookie() {
  setCookieInBrowser(sessionKey, '0', -oneYearTime)
}

export function getSessionIdFromToken(token: string) {
  try {
    const decodedToken: TokenInfo = jwtDecode(token)
    return decodedToken.session
  } catch (error) {
    return ''
  }
}
