import { IEntityType } from '@patsnap/synapse_common_interface'
import { getLangDegraded } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { PropType, computed, defineComponent } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useAnalysisChartNavDetail } from '../../../BAnalysisChartNext/compositions/useAnalysisChartNavDetail'
import { BEntityFetchCard } from '../../../card/BEntityFetchCard'
import { detailCardTippyConfig } from '../../../card/config'

export const BAnalysisEntityItemWithPopper = defineComponent({
  name: 'BAnalysisEntityItemWithPopper',
  props: {
    entity: {
      required: true,
      type: Object as PropType<{ id: string; type: IEntityType | 'drug_type'; name_cn?: string; name_en?: string }>,
    },
    lineClamp: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { locale } = useLocale()

    const id = computed(() => props.entity.id)

    const type = computed(() => props.entity.type)

    const { handleAxisClick: handleClick } = useAnalysisChartNavDetail({ id, type })

    return { locale, handleClick }
  },
  render() {
    return (
      <PtPopper data={{ id: this.entity.id, type: this.entity.type }} tippyConfig={detailCardTippyConfig()} component={BEntityFetchCard}>
        <div
          class={['text-xs leading-4 truncate whitespace-break-spaces text-text-t1 cursor-pointer', `line-clamp-${this.lineClamp}`]}
          onClick={this.handleClick}
        >
          {getLangDegraded(this.entity, this.locale)}
        </div>
      </PtPopper>
    )
  },
})
