/* eslint-disable @typescript-eslint/no-explicit-any */
// 热力图
// 饼图
import '@patsnap-ui/icon/assets/solid/ChartDonut2.svg'
// 双线
import '@patsnap-ui/icon/assets/solid/ChartDouble.svg'
import '@patsnap-ui/icon/assets/solid/ChartHeatmap.svg'
// 水平柱子
import '@patsnap-ui/icon/assets/solid/ChartHorizontal.svg'
// 单线
import '@patsnap-ui/icon/assets/solid/ChartSingle.svg'
// 牛眼图
import '@patsnap-ui/icon/assets/solid/ChartSun.svg'
// 垂直柱子
import '@patsnap-ui/icon/assets/solid/ChartVertical.svg'
// 表格
import '@patsnap-ui/icon/assets/solid/ContentFormatingTable.svg'
// 世界地图
import '@patsnap-ui/icon/assets/solid/global.svg'
// 3D气泡图
import '@patsnap-ui/icon/assets/solid/Insights3DBubble.svg'
// 折柱混合土
import '@patsnap-ui/icon/assets/solid/InsightsCombinationchart.svg'
// 关系图
import '@pharmsnap/shared/assets/icon-svg/relationship.svg'
import { GChartTypeButton } from '@pharmsnap/shared/src/components'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { defineComponent, PropType } from '@vue/composition-api'
import { IAnalysisDisplayIconType, IAnalysisDisplayOptionItem } from '../../type'

export const BAnalysisChartDisplaySwitch = defineComponent({
  name: 'BAnalysisChartDisplaySwitch',
  props: {
    options: {
      type: Array as PropType<Array<IAnalysisDisplayOptionItem>>,
      required: true,
      default: () => [],
    },
    value: {
      type: String as PropType<string>,
    },
  },
  setup(props, { emit }) {
    const { tsText } = useLocale()
    const handleChange = (value: string) => {
      emit('input', value)
    }
    const iconMap: Record<IAnalysisDisplayIconType, { icon: string; desc: string }> = {
      heatmap: { icon: 'SolidChartHeatmap', desc: tsText('热力图', 'Heat Map') },
      pie: { icon: 'SolidChartDonut2', desc: tsText('饼图', 'Pie Chart') },
      'single-line': { icon: 'SolidChartSingle', desc: tsText('曲线图', 'Curve Chart') },
      'multi-line': { icon: 'SolidChartDouble', desc: tsText('曲线图', 'Curve Chart') },
      'horizontal-bar': { icon: 'SolidChartHorizontal', desc: tsText('柱状图', 'Column Chart') },
      'vertical-bar': { icon: 'SolidChartVertical', desc: tsText('柱状图', 'Column Chart') },
      relation: { icon: 'Relationship', desc: tsText('关系图', 'Relation') },
      global: { icon: 'SolidGlobal', desc: tsText('世界地图', 'World Map') },
      table: { icon: 'SolidContentFormatingTable', desc: tsText('表格', 'Table') },
      'line-bar': { icon: 'SolidInsightsCombinationchart', desc: tsText('折柱图', 'Column Chart') },
      'bull-eye': { icon: 'SolidChartSun', desc: tsText('牛眼图', 'Bullseye Chart') },
      'multi-pie-doughnut': { icon: 'SolidInsights3DBubble', desc: tsText('点阵图', 'Bitmap') },
    }

    return {
      iconMap,
      handleChange,
    }
  },
  render() {
    return (
      <div class="inline-flex items-center gap-1">
        {this.options.map((item, index) => (
          <GChartTypeButton
            icon={this.iconMap[item.type].icon}
            text={this.iconMap[item.type].desc}
            active={item.value === this.value}
            onClick={() => this.handleChange(item.value)}
          ></GChartTypeButton>
        ))}
      </div>
    )
  },
})
