import PtEmailAlert from '@patsnap-biz/email-alert'
import { IAlertConfig, IAlertFrequencyType, ICreateUpdateAlertParams } from '@patsnap/synapse_common_interface'
import { checkEmailValid } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { GIcon } from '@pharmsnap/shared/src/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { IS_CN_REGION } from '@pharmsnap/shared/src/config'
import { getLocalStorage, setLocalStorage, showSingleToast } from '@pharmsnap/shared/src/utils'
import { getAlertFrequencyText } from '@pharmsnap/shared/src/utils/business/emailAlert/email-alert-v2'
import { PropType, computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { EmailAlert } from 'patsnap-biz'
import { BWechatBind } from '../BWechatBind/BWechatBind'
import $classes from './BEmailSendingConfigForm.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
interface IEmailItem {
  isValid: boolean
  label: string
  type: 'group' | 'email'
  value: string
}
export type AlertFeFrequency = 'M' | 'D' | 'W'
interface BizEmailAlertService {
  hasSendToMe: boolean
  sendToMe: boolean
  selfEmail: string
  sendToMeDisplayLabel: string
  currentInputtingEmail: string
  emailAutoCompletePlaceholder: string
  passportUrl: string
  alertFrequency: {
    frequency: AlertFeFrequency
    date: number
  }
  alertFrequencyOptions: string[]
  alertNoUpdate: boolean
  selectedEmails: IEmailItem[]
  shouldSubmit: boolean
  sendToOther: boolean
  innerLoading: boolean
  outLoading: boolean
  titleLayout: string
  filterFreqType: string
  freqInitialType: string
  completeScrollSelector: string
  emailLimitNum?: number
  showLimitLabel: boolean
  enableSelectWhenOverLimit: boolean
  slotSubData: any // 你可能需要更具体的类型来替代 'any'
  slotDataError: any // 你可能需要更具体的类型来替代 'any'

  concatEmail: any[] // 你可能需要更具体的类型来替代 'any'
  isOnlySendToSelf: boolean
  currentSelectedEmailCount: number

  setInnerLoadingStatus(status: boolean): void
  setOutLoadingStatus(status: boolean): void
  setSendToMeStatus(status: boolean): void
  setSendToOtherStatus(status: boolean): void
  setAlertNoUpdateStatus(status: boolean): void
  onToastError({ message }: { message: string }): string
  onError(error: any): string // 你可能需要更具体的类型来替代 'any'
  getEmailList(keywords: string): Promise<{
    email: { email: string }[]
    group: {
      group_id: string
      name: string
      total: string
    }[]
  }>
  onClose(hide: () => void): void
  onValidationFailed(type: string): void
  onValidate(): boolean
  onSubmit(): void
  onDataChange(err: any, data: any): void // 你可能需要更具体的类型来替代 'any'
  onEmailUpperLimit(): void
  updateCurrentSelectedEmailCount(count: number): void
}
/**
 * email alert frequency前端与后台的转换
 */
export const bizFrequency2SynapseFrequencyMap: Record<AlertFeFrequency, IAlertFrequencyType> = {
  D: 'DAILY',
  W: 'WEEKLY',
  M: 'MONTHLY',
}
/**
 * email alert frequency后台与前端的转换
 */
export const synapseFrequency2BizFrequencyMap: Record<IAlertFrequencyType, AlertFeFrequency> = {
  DAILY: 'D',
  WEEKLY: 'W',
  MONTHLY: 'M',
}
export const BEmailSendingConfigForm = defineComponent({
  name: 'BEmailSendingConfigForm',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    alertConfig: {
      type: Object as PropType<Partial<IAlertConfig>>,
      require: true,
    },
  },
  setup(props, { emit }) {
    let cancelWatchFn: null | (() => void) = null
    const {
      getters: { isFreeUser, isTrialUser, email, hasFollowedPharmsnap },
      actions: { changeUpgrade },
    } = useAuthStore()
    const { $t, isCN, locale } = useLocale()
    const defaultFrequencyConfig = {
      frequency: 'W',
      date: 1,
    } as const
    const HistoryEmailsStoreKey = 'HistoryEmails'
    const bizEmailAlertService = reactive<BizEmailAlertService>(new PtEmailAlert.EmailAlertService())
    const isAllEmailValid = computed(() => bizEmailAlertService.selectedEmails.every((e) => checkEmailValid(e.value)))
    const showEmailSendingConfig = ref(false)
    const allEmailString = computed(() => {
      const emails: string[] = []
      if (bizEmailAlertService.selfEmail) {
        emails.push(bizEmailAlertService.selfEmail)
      }
      if (bizEmailAlertService.selectedEmails.length > 0) {
        emails.push(...bizEmailAlertService.selectedEmails.map((item) => item.value))
      }
      return emails.join(', ')
    })
    const frequencyString = computed(() => {
      const frequency = getSendingConfig().frequency
      if (!frequency) {
        return ''
      }
      return getAlertFrequencyText(frequency, locale.value)
    })
    const alwaysAlertString = computed(() => {
      return props.alertConfig?.always_alert ? $t('BEmailSendingConfigForm.alwaysAlertYes') : $t('BEmailSendingConfigForm.alwaysAlertNo')
    })
    /**
     * 超出限制个数时的回调
     */
    function _handleUpperLimit() {
      const message = $t('BEmailSendingConfigForm.upperEmailLimit', { limit: bizEmailAlertService.emailLimitNum }) as string
      if (isFreeUser.value || isTrialUser.value) {
        changeUpgrade({ show: true, title: message, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'EMAIL_OVER_LIMIT' })
      } else {
        showSingleToast({
          message,
          type: 'error',
        })
      }
    }
    async function _getEmailList(keywords: string) {
      const emails = getLocalStorage<string[]>(HistoryEmailsStoreKey) || []
      const filterEmails = emails.filter((email) => email.includes(keywords))
      const recommendEmails = keywords ? filterEmails : emails
      const data = {
        email: recommendEmails.map((o) => ({ email: o })),
        group: [],
      }
      return data
    }
    /**
     * 存储最近发送过的20个收件人邮箱
     * @param emails
     */
    function storeEmails() {
      const historyEmails = getLocalStorage<string[]>(HistoryEmailsStoreKey) || []
      const emails: string[] = bizEmailAlertService.selectedEmails.map((item) => item.value)
      const newHistoryEmails = Array.from(new Set([...emails, ...historyEmails])).slice(0, 20)
      setLocalStorage(HistoryEmailsStoreKey, JSON.stringify(newHistoryEmails))
    }

    function initConfig() {
      bizEmailAlertService.emailLimitNum = 5
      bizEmailAlertService.selfEmail = email.value
      bizEmailAlertService.onEmailUpperLimit = _handleUpperLimit
      bizEmailAlertService.getEmailList = _getEmailList
    }
    function initBizEmailAlertServiceState() {
      const config = props.alertConfig
      if (!config) {
        return
      }
      if (cancelWatchFn) {
        cancelWatchFn()
      }
      bizEmailAlertService.alertNoUpdate = config.always_alert || false
      bizEmailAlertService.alertFrequency = {
        frequency: config.frequency?.type ? synapseFrequency2BizFrequencyMap[config.frequency.type] : defaultFrequencyConfig.frequency,
        date: config.frequency?.date || defaultFrequencyConfig.date,
      }
      bizEmailAlertService.sendToMe = config.send_to_me ?? true
      bizEmailAlertService.selectedEmails = (config.recipient || []).map((item) => ({
        isValid: true,
        label: item,
        type: 'email',
        value: item,
      }))
      if (bizEmailAlertService.selectedEmails.length > 0) {
        bizEmailAlertService.sendToOther = true
      }
      _addWatch()
    }
    /**
     * 获取发送配置(给外部使用))
     * @returns
     */
    function getSendingConfig() {
      const selectEmails = bizEmailAlertService.selectedEmails.map((item) => item.value)
      const createUpdateAlertParams: Pick<ICreateUpdateAlertParams, 'recipient' | 'send_to_me' | 'always_alert' | 'frequency'> = {
        recipient: selectEmails,
        send_to_me: bizEmailAlertService.sendToMe,
        always_alert: bizEmailAlertService.alertNoUpdate,
        frequency: {
          type: bizFrequency2SynapseFrequencyMap[bizEmailAlertService.alertFrequency.frequency],
          date: bizEmailAlertService.alertFrequency.date,
        },
      }
      // core那边没处理不选自己，但收件人里面填写自己邮箱的case
      // 我们这边直接传数据的时候处理一下，改成发送给自己
      if (email.value && selectEmails.includes(email.value)) {
        createUpdateAlertParams.recipient = selectEmails.filter((o) => o !== email.value)
        createUpdateAlertParams.send_to_me = true
      }
      return createUpdateAlertParams
    }
    /**
     * 提交校验(给外部使用)
     * @returns
     */
    function checkCanSubmit(): boolean {
      if (!bizEmailAlertService.sendToMe && !bizEmailAlertService.selectedEmails.length) {
        showSingleToast({ message: $t('BEmailSendingConfigForm.notHasEmail') as string, type: 'error' })
        return false
      }
      if (bizEmailAlertService.selectedEmails.length && !bizEmailAlertService.sendToOther) {
        showSingleToast({ message: $t('BEmailSendingConfigForm.confirmSendToOthersTipMsg') as string, type: 'error' })
        return false
      }
      return true
    }
    function _addWatch() {
      cancelWatchFn = watch(
        () => bizEmailAlertService,
        (value) => {
          if (value) {
            emit('update', getSendingConfig())
          }
        },
        {
          deep: true,
        }
      )
    }
    initConfig()

    return {
      initBizEmailAlertServiceState,
      allEmailString,
      alwaysAlertString,
      frequencyString,
      bizEmailAlertService,
      isAllEmailValid,
      showEmailSendingConfig,
      hasFollowedPharmsnap,
      storeEmails,
      checkCanSubmit,
      getSendingConfig,
    }
  },
  render() {
    return (
      <div class="mt-4 text-sm">
        {this.showEmailSendingConfig ? (
          <EmailAlert.AlertCommonModule class={$classes.emailAlert} service={this.bizEmailAlertService} isAllEmailValid={this.isAllEmailValid}>
            {IS_CN_REGION ? (
              <template slot="extra">
                <BWechatBind class="mt-2"></BWechatBind>
              </template>
            ) : null}
          </EmailAlert.AlertCommonModule>
        ) : (
          <div>
            <div class="flex justify-between gap-x-10">
              <div>
                <span
                  domPropsInnerHTML={this.$t('BEmailSendingConfigForm.alertSettingTip', {
                    frequency: this.frequencyString,
                    email: this.allEmailString,
                  })}
                ></span>
                <span>,</span>
                <span>{this.alwaysAlertString}</span>
              </div>
              <div class="text-blue-default flex-shrink-0 cursor-pointer" onClick={() => (this.showEmailSendingConfig = true)}>
                {this.$t('BEmailSendingConfigForm.sendSettings')}
              </div>
            </div>
            {IS_CN_REGION && this.hasFollowedPharmsnap && (
              <div class="mt-2 p-2 bg-gray-20">
                <GIcon class="text-green-default" svgName="SolidWechat" size={24}></GIcon>
                <span class="ml-2">{this.$t('BEmailSendingConfigForm.hasBindWeChat')}</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  },
})
