/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, h, PropType, Ref } from '@vue/composition-api'
import { VNodeData } from 'vue'
interface IHashRouterConfig {
  component: any
}
export const BHashRouterView = defineComponent({
  name: 'BHashRouterView',
  props: {
    router: {
      required: true,
      type: Object as PropType<Record<string, IHashRouterConfig>>,
    },
    propsData: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const routeHash = computed(() => ctx.root.$route.hash)
    const currentComponent = computed(() => {
      if (!routeHash.value) {
        return undefined
      }
      return props.router[routeHash.value.substring(1)]?.component
    })
    const VNodeDataValue: Ref<VNodeData> = computed(() => {
      return {
        props: props.propsData,
      }
    })
    return { currentComponent, VNodeDataValue }
  },
  render() {
    return this.currentComponent ? h(this.currentComponent as any, this.VNodeDataValue as VNodeData) : <div></div>
  },
})
