import { E_TIME_TRUST_CODE, I18nLang } from '@patsnap/synapse_common_interface'
import VueI18n, { IVueI18n } from 'vue-i18n'
import { BDecodeUniCodeContainer, BOrganizationItem, GLink, GTooltip } from '../components'
import { EMPTY_PLACEHOLDER } from '../constants'
import { IBaseOrgInfo, ILang, IVCInvestmentInfoNew, IVCOrg, IVCPerson } from '../types'
import { formatTimestamp, getVCAmount, transformerNormalizedOrg2BaseOrg } from '../utils'
import { renderPopoverLimited } from './baseRender'

export function renderVCAnnouncedDate(data: IVCInvestmentInfoNew, $i18n: VueI18n) {
  if (!data.announced_time) return EMPTY_PLACEHOLDER
  return (
    <span>
      {formatTimestamp(data.announced_time, {
        locale: $i18n.locale.toUpperCase() as ILang,
      })}
    </span>
  )
}

export function renderVCAmount(data: IVCInvestmentInfoNew, $i18n: VueI18n) {
  return (
    <BDecodeUniCodeContainer
      fontClassName={data.fontClassName}
      domPropsInnerHTML={getVCAmount(data, $i18n.locale as I18nLang)}
    ></BDecodeUniCodeContainer>
  )
}

export function renderVCType(data: IVCInvestmentInfoNew, $i18n: VueI18n) {
  if (!data.funding_type) return ''
  return <span>{$i18n.locale === 'cn' ? data.funding_type.classification || '' : data.funding_type.classification_en || ''}</span>
}

export function renderVCOrg(data: IVCInvestmentInfoNew) {
  const org = data.funded_organization
  if (!org) return EMPTY_PLACEHOLDER
  const baseOrg: IBaseOrgInfo = org.normalized_master_entity_id_view
    ? org.normalized_master_entity_id_view
    : {
        name_en: org.original_name || '',
        entity_name_en: org.original_name || '',
        entity_id: '',
      }
  return <BOrganizationItem data={baseOrg}></BOrganizationItem>
}

export function renderVCInvestorOrg(data: IVCInvestmentInfoNew, $i18n: IVueI18n) {
  const orgs = (data.investors?.filter((o) => o.investor_type === 'organization') as IVCOrg[]) || []
  const peoples = (data.investors?.filter((o) => o.investor_type === 'person') as IVCPerson[]) || []
  if (orgs.length === 0 && peoples.length === 0) return EMPTY_PLACEHOLDER
  const transformedOrgs = orgs.map((i) => {
    return {
      ...(i.normalized_master_entity_id_view
        ? i.normalized_master_entity_id_view
        : { name_en: i.original_name, entity_name_en: i.original_name, entity_id: '' }),
      lead_investor: !!i.is_lead_investors,
      is_org: true,
    }
  })
  const transformedPeoples = peoples.map((i) => {
    return {
      ...transformerNormalizedOrg2BaseOrg(i),
      lead_investor: !!i.is_lead_investors,
      is_ls_related: false,
      is_org: false,
    }
  })
  const sortedItems = [...transformedOrgs, ...transformedPeoples].sort((a, b) => {
    const isLeadA = a.lead_investor ? 1 : 0
    const isLeadB = b.lead_investor ? 1 : 0
    return isLeadB - isLeadA
  })

  return renderPopoverLimited(sortedItems, {
    limitCount: 1,
    appendToBody: true,
    render: (item) => {
      const content = (
        <BOrganizationItem
          zIndex={3000}
          data={item}
          iconType={item.is_org ? 'company' : 'people'}
          style={{ fontWeight: item.lead_investor ? 'bold' : 'normal' }}
        ></BOrganizationItem>
      )
      return item.lead_investor ? (
        <GTooltip placement="left" theme="dark" content={$i18n.tc('investment.leadInvestor')}>
          {content}
        </GTooltip>
      ) : (
        content
      )
    },
  })
}

export function renderVCEventNews(data: IVCInvestmentInfoNew) {
  const eventNews = data.news_id_view || []
  if (eventNews.length === 0) return EMPTY_PLACEHOLDER
  const sortedEventNews = [...eventNews].sort((a, b) => (a.post_time?.time_ts || 0) - (b.post_time?.time_ts || 0))
  const firstNewsInfoWithoutPostTime = sortedEventNews.find((i) => !!i.source_url && !!i.title)
  const firstNewsInfoWithPostTime = sortedEventNews.find(
    (i) => !!i.source_url && !!i.title && !!i.post_time && !!i.post_time.time_ts && i.post_time.time_trust_code !== E_TIME_TRUST_CODE.INVALID_DATE
  )

  const firstNewsInfo = firstNewsInfoWithPostTime || firstNewsInfoWithoutPostTime

  if (!firstNewsInfo?.source_url) return EMPTY_PLACEHOLDER

  const href = `/link?target=${encodeURIComponent(firstNewsInfo.source_url)}`

  return (
    <GLink truncate={false} defaultColor="blue" href={href}>
      <div style="word-break: break-word;" class="line-clamp-3" domPropsInnerHTML={firstNewsInfo.title}></div>
    </GLink>
  )
}
