import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import { ElPopover } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, PropType, ref, toRefs } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import { GFilterItemTrigger } from '../GFilterItemTrigger/GFilterItemTrigger'
import { GLoading } from '../GLoading/GLoading'
import $classes from './GMultiDropdown.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const GMultiDropdown = defineComponent({
  name: 'GMultiDropdown',
  props: {
    icon: String,
    label: String,
    title: String,
    width: {
      type: Number,
      default: 200,
    },
    popoverClass: {
      type: String,
      default: '',
    },
    popoverWidth: {
      type: Number,
    },
    placement: {
      type: String as PropType<'bottom-start' | 'bottom' | 'bottom-end'>,
      default: 'bottom-start',
    },
    count: {
      type: Number,
      default: 0,
    },
    panelHeight: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onOpen: {
      type: Function as PropType<() => void>,
    },
    onClose: {
      type: Function as PropType<() => void>,
    },
    onConfirm: {
      type: Function as PropType<() => void>,
    },
    onClear: {
      type: Function as PropType<() => void>,
    },
    clearAble: {
      type: Boolean,
      default: true,
    },
    activeAble: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    /** 是否有权限：无权限时，点击出upgrade弹窗 */
    hasRights: {
      type: Boolean,
      default: true,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props, context) {
    const visible = ref<boolean>(false)

    const { count } = toRefs(props)

    const isChecked = computed(() => count.value > 0)

    const handleTogglePanelVisible = () => {
      if (props.hasRights) {
        visible.value = !visible.value
        context.emit(visible.value ? 'open' : 'close')
        return
      }
      context.emit('clickWithoutRights')
    }

    const handleClosePanel = () => {
      if (visible.value) {
        visible.value = false
        context.emit('close')
      }
    }

    const handleSave = () => {
      visible.value = false
      context.emit('confirm')
    }
    const handleClear = () => {
      context.emit('clear')
    }

    return {
      visible,
      handleTogglePanelVisible,
      handleClosePanel,
      handleSave,
      handleClear,
    }
  },
  directives: {
    clickOutside: VClickOutside.directive,
  },
  render() {
    return (
      <ElPopover
        v-model={this.visible}
        v-click-outside={this.handleClosePanel}
        placement={this.placement}
        trigger="manual"
        popper-class={[$classes.gMultiDropdownPopover, this.popoverClass].join(' ')}
      >
        <GFilterItemTrigger
          slot="reference"
          icon={this.icon}
          label={this.label}
          title={this.title}
          width={this.width}
          count={this.count}
          clearAble={this.clearAble}
          isActive={this.visible}
          activeAble={this.activeAble}
          labelClass={this.labelClass}
          iconMode="arrow"
          onClear={this.handleClear}
          onTrigger={this.handleTogglePanelVisible}
        ></GFilterItemTrigger>
        <div class={$classes.gMultiDropdownPanel} style={this.panelHeight ? `height: ${this.panelHeight}` : ''}>
          <div class={$classes.gMultiDropdownPanelHd}>
            <div class={$classes.gMultiDropdownPanelTitle}>{this.title}</div>
            {!this.isLoading && this.$slots.header && <div class="mt-4">{this.$slots.header}</div>}
          </div>
          <div
            class={[$classes.gMultiDropdownPanelBd, !this.title && !this.$slots.header ? 'mt-0' : this.$slots.header ? 'mt-4' : 'mt-5']}
            style={{ width: `${this.popoverWidth}px` }}
          >
            {this.isLoading ? <GLoading /> : this.showFooter ? this.$slots.default : <div onClick={this.handleClosePanel}>{this.$slots.default}</div>}
          </div>
          {this.showFooter ? (
            <div class={$classes.gMultiDropdownPanelFt}>
              <button class="pt-ui-btn" type="button" data-type="default" disabled={this.isLoading} on={{ click: this.handleClosePanel }}>
                {this.$t('button.cancel')}
              </button>
              <button class="pt-ui-btn ml-2" type="button" data-type="submit" disabled={this.isLoading} on={{ click: this.handleSave }}>
                {this.$t('button.save')}
              </button>
            </div>
          ) : null}
        </div>
      </ElPopover>
    )
  },
})
