import { defineComponent, PropType } from '@vue/composition-api'
import { ElButtonGroup, ElButton } from '@pharmsnap/shared/element-ui'
import { IBtn, IOrderByType } from '@pharmsnap/shared/types'
import $classes from './GGroupBtn.module.scss'

export const GGroupBtn = defineComponent({
  name: 'GGroupBtn',
  props: {
    btnGroup: {
      type: Array as PropType<IBtn[]>,
      required: true,
    },
    activeType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleClick = (type: IOrderByType) => {
      emit('click', type)
    }
    return { handleClick }
  },
  render() {
    return (
      <ElButtonGroup>
        {this.btnGroup.map((btn) => (
          <ElButton class={btn.type === this.activeType ? $classes.active : $classes.normal} size="small" onClick={() => this.handleClick(btn.type)}>
            {btn.name}
          </ElButton>
        ))}
      </ElButtonGroup>
    )
  },
})
