import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import '@pharmsnap/shared/assets/icon-svg/drugType.svg'
import { BDrugItem, GIcon, GIconLink, GLimited, GLimitedContainer, GLink } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IDrugTypeCardInfo } from '@pharmsnap/shared/src/types/drugType'
import { ILang } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BDiseaseItem } from '../../BDiseaseItem/BDiseaseItem'
import { BTargetItem } from '../../BTargetItem/BTargetItem'
import $classes from '../EntityIntelligentCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDrugTypeIntelligentCard = defineComponent({
  name: 'BDrugTypeIntelligentCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDrugTypeCardInfo>,
    },
    layout: {
      type: String as PropType<'basic' | 'intermediate' | 'advanced'>,
      default: 'intermediate',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    descMaxHeight: {
      type: String,
      default: '280px',
    },
    descLimitLines: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const relationDrugs = computed(() => data?.value?.related_drug_id_view || [])
    const relationTargets = computed(() => data?.value?.related_target_id_view || [])
    const relationDiseases = computed(() => data?.value?.related_disease_id_view || [])
    const displayNameCn = computed(() => data?.value?.name_cn || '')
    const displayNameEn = computed(() => data?.value?.name_en || '')
    const displayName = computed(() => (locale.value === 'CN' ? displayNameCn.value || displayNameEn.value : displayNameEn.value) ?? '')

    return {
      displayName,
      displayNameCn,
      displayNameEn,
      relationDrugs,
      relationTargets,
      relationDiseases,
      locale,
      isCN,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={3}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderField(label: string, content: JSX.Element | string) {
      return (
        <div class="flex items-start">
          <div style={this.labelWidth === 'auto' ? '' : `width:${this.labelWidth}`} class="flex-shrink-0 text-sm text-text-t2 mr-2 leading-6">
            {label}
            {this.labelWidth === 'auto' ? ':' : ''}
          </div>
          <div class="flex-1 overflow-hidden">{content}</div>
        </div>
      )
    },
    renderHeader() {
      const nameEn = this.displayNameEn
      const nameCn = this.displayNameCn

      if (this.layout === 'basic') {
        return (
          <div class="flex items-center">
            <GIcon class="mr-2 flex-shrink-0" svgName="DrugType" size={32} useSvgDefaultColor shape="square" border></GIcon>
            <div class="flex-1 overflow-hidden">
              <GLink
                style={{ lineHeight: `24px` }}
                class="block font-semibold text-text-t1 text-base"
                newTab={true}
                name={nameEn && nameCn ? nameEn : this.displayName}
                href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
                truncate={true}
                encryptClass={this.data?.fontClassName}
              ></GLink>
              {this.isCN && !!nameCn ? (
                <GLink
                  style={{ lineHeight: `16px` }}
                  class="block text-xs text-text-t1"
                  ellipsisLine={1}
                  href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
                  name={nameCn}
                  truncate={true}
                  encryptClass={this.data?.fontClassName}
                ></GLink>
              ) : null}
            </div>
          </div>
        )
      }

      if (this.layout === 'intermediate') {
        return (
          <div>
            <div style="line-height: 0;">
              <GIconLink
                class="font-semibold text-text-t1 text-base"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
                name={nameEn && nameCn ? nameEn : this.displayName}
                newTab={true}
                defaultColor="dark"
                size={24}
                svgName="DrugType"
                encryptClass={this.data?.fontClassName}
              ></GIconLink>
            </div>
            {this.isCN && !!nameCn ? (
              <div style="line-height: 0;" class="pl-8">
                <GIconLink
                  class="text-sm text-text-t1"
                  ellipsisLine={1}
                  href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
                  name={nameCn}
                  newTab={true}
                  defaultColor="dark"
                  size={20}
                  encryptClass={this.data?.fontClassName}
                ></GIconLink>
              </div>
            ) : null}
          </div>
        )
      }

      const showProfile = this.data && !!this.data.desc_en

      return (
        <div class="pb-2 border-b border-gray-30">
          <div style="line-height: 0;">
            <GIconLink
              class="font-semibold text-text-t1 text-base"
              ellipsisLine={1}
              href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
              name={nameEn && nameCn ? nameEn : this.displayName}
              newTab={true}
              defaultColor="dark"
              size={32}
              svgName="DrugType"
              border={true}
              encryptClass={this.data?.fontClassName}
            ></GIconLink>
          </div>
          {this.isCN && !!nameCn ? (
            // pl 会收到是否显示边框影响
            <div style="line-height: 0;" class="pl-[42px]">
              <GIconLink
                class="text-sm text-text-t1"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDrugTypePath(this.data?.dict_id || '')}
                name={nameCn}
                newTab={true}
                defaultColor="dark"
                size={20}
                encryptClass={this.data?.fontClassName}
              ></GIconLink>
            </div>
          ) : null}
          {showProfile ? (
            this.descLimitLines <= 0 ? (
              <div
                class="mt-3 text-sm overflow-y-auto leading-5 text-text-t2"
                style={{ maxHeight: this.descMaxHeight }}
                domPropsInnerHTML={this.data?.desc_en}
              ></div>
            ) : (
              <GLimitedContainer class="mt-3" limitLines={this.descLimitLines} lineHeight={20}>
                <div class="text-sm text-text-t2 leading-5" domPropsInnerHTML={this.data?.desc_en}></div>
              </GLimitedContainer>
            )
          ) : null}
        </div>
      )
    },
    renderBody() {
      if (this.layout === 'basic') return null

      const relatedDrugsContent = this.renderLimited(this.relationDrugs, this.data?.related_drug_id_count || 0, (item) => (
        <BDrugItem showCard={false} data={item}></BDrugItem>
      ))

      const relatedTargetContent = this.renderLimited(this.relationTargets, this.data?.related_target_id_count || 0, (item) => (
        <BTargetItem popover={false} data={item}></BTargetItem>
      ))

      const relatedDiseaseContent = this.renderLimited(this.relationDiseases, this.data?.related_disease_id_count || 0, (item) => (
        <BDiseaseItem popover={false} data={item}></BDiseaseItem>
      ))

      const content = [
        this.renderField(this.$t('drugTypeCard.relatedDrugs') as string, relatedDrugsContent),
        this.renderField(this.$t('drugTypeCard.relatedTargets') as string, relatedTargetContent),
        this.renderField(this.$t('drugTypeCard.relatedDiseases') as string, relatedDiseaseContent),
      ]

      return <div class="space-y-1 mt-4">{content}</div>
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
})
