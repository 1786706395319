import { ConversationTableData, MicroLs360SynapseProps, UploadProgressPayload } from '@patsnap/ls360_sdk_shared'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { IS_CN_REGION } from '@pharmsnap/shared/src/config'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { PatentLegalStatusService } from '@pharmsnap/shared/src/domain/patentLegalStatus/patentLegalStatus.service'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { TermsOfServiceHrefByCN, TermsOfServiceHrefByUS } from '../components/header/constants'
import { getExamples } from '../components/LS360/util'
import { VUE_APP_LS360_URL, VUE_APP_MICRO_BASE_URL, VUE_APP_MICRO_URL, VUE_APP_PHARMSNAP_MF_PDF_PATH } from '../config'
import { getService } from '../service/service'
import { E_ROUTER_NAME } from '../types/enum'
import { useUpgrade } from '../views/Upgrade/hook/useUpgrade'
import { useLs360ChatStore } from './useLs360ChatStore'
import { useLS360Rights } from './useLS360Rights'

async function uploadFile(selectedFile: File, uploadProgress: (uploadStatus: UploadProgressPayload) => void) {
  if (!selectedFile) return
  function onUploadProgress(progressEvent: ProgressEvent) {
    if (progressEvent.lengthComputable) {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      uploadProgress({
        uploadStatus: 'uploading',
        progress,
      })
    }
  }
  getService()
    .ls360Api.uploadFile(selectedFile, onUploadProgress)
    .then((rt) => {
      if (rt.success) {
        uploadProgress({
          uploadStatus: 'uploaded',
          file: rt.data,
        })
      } else {
        const message = rt.data.error_message
        uploadProgress({
          uploadStatus: 'failed',
          failedMessage: message,
        })
      }
    })
    .catch(() => {
      uploadProgress({
        uploadStatus: 'failed',
        failedMessage: 'Upload failed',
      })
    })
}
function getSynapsePageUrl(type: keyof ConversationTableData) {
  const combineUrl = (url: string) => {
    return window.location.origin + url
  }
  switch (type) {
    case 'drug_tables':
      return combineUrl('/drug-list')
    case 'disease_tables':
      return combineUrl('/drug-group/disease')
    case 'target_tables':
      return combineUrl('/drug-group/target')
    case 'drug_type_tables':
      return combineUrl('/drug-group/drug-type')
    case 'org_tables':
      return combineUrl('/drug-group/organization')
    case 'paper_tables':
      return combineUrl('/literature-list')
    case 'patent_tables':
      return combineUrl('/patent-list')
    case 'news_tables':
      return combineUrl('/news-list')
    case 'clinical_trial_tables':
      return combineUrl('/clinical-progress-list')
    case 'ct_result_tables':
      return combineUrl('/clinical-result-list')
    case 'drug_deal_tables':
      return combineUrl('/drug-deal-list')
    default:
      return combineUrl('/drug-list')
  }
}
function signature(params: { object_key: string[]; sign_type: 'ls360_chat_file' }) {
  return sharedCtx.service.ls360Api.getSignature(undefined, { data: params }).then((rt) => rt.data as Record<string, string>)
}

export function useLs360SynapseWujieProps(customBizHandler?: Partial<MicroLs360SynapseProps['bizHandler']>) {
  const {
    actions: { showAssistantDialog, setPrinting, initEmailAlertStatus, getPageContext, position2Anchor, gotoSearchResult, onCallbackSummarize },
  } = useLs360ChatStore()
  const { checkHasChatRights } = useLS360Rights()
  const { onViewChatPlans } = useUpgrade()
  const {
    getters: { userName, isOpenChatBeta },
    state: { userInfo, synapseChatUsage, synapseChatRole },
    actions: { syncSynapseChatUsage, changeChatBetaStatus },
  } = useAuthStore()
  const { locale, isCN, localeUpcase } = useLocale()
  const patentLegalStatusService = PatentLegalStatusService.createSingleton()

  async function getRandomQuestion() {
    // const res = await sharedCtx.service.ls360Api
    //   .getChatExample()
    //   .then((res) => {
    //     if (res.success && res.data) {
    //       return (res.data || []).map((i) => (isCN.value ? i.question_cn : i.question_en)).filter(Boolean)
    //     }
    //     return []
    //   })
    const res = await getExamples(isCN.value)
    return res
  }

  async function getPatentLegalStatus() {
    if (patentLegalStatusService.legalStatusConfig) {
      return {
        data: patentLegalStatusService.legalStatusConfig,
      }
    }
    const res = await patentLegalStatusService.getPatentLegalStatusConfig()
    return {
      data: res,
    }
  }
  function getFeatureConfig() {
    return {
      canUseDeepSearch: isOpenChatBeta.value,
      canUseFileUpload: isOpenChatBeta.value,
      canUseMarvin: true,
    }
  }

  function handleGetMoreTimes() {
    onViewChatPlans('ASSISTANT_CLICK_MORE')
  }

  function updateBetaStatus(newStatus: boolean) {
    changeChatBetaStatus(newStatus)
  }

  const wuJieEmptyUrl = `${window.location.origin}/wujie/empty.html`
  const props: MicroLs360SynapseProps = {
    ctx: {
      locale: locale.value,
    },
    initEmailAlertStatus,
    uploadFile,
    signature,
    showToast: showSingleToast,
    // synapse 前端网关代理了marvin_latest,所以直接用synapse域名下marvin_latest
    marvinEditorUrl: `${window.location.origin}/marvin_latest/editor.html`,
    /** 用户协议地址 */
    agreementUrl: IS_CN_REGION ? TermsOfServiceHrefByCN : TermsOfServiceHrefByUS,
    pdfMicroAppBaseUrl: VUE_APP_PHARMSNAP_MF_PDF_PATH,
    reportableRouteList: [
      E_ROUTER_NAME.DRUG_LIST,
      E_ROUTER_NAME.DISEASE,
      E_ROUTER_NAME.TARGET,
      E_ROUTER_NAME.DRUG_TYPE,
      E_ROUTER_NAME.PATENT_LIST,
      E_ROUTER_NAME.DRUG_DEAL_LIST,
    ],
    microEntityCardProps: {
      entryUrl: VUE_APP_MICRO_URL || VUE_APP_MICRO_BASE_URL,
      iframeUrl: wuJieEmptyUrl,
      // TODO: 临时增加占位
      queryCardData: (() => ({})) as any,
    },
    bizApi: {
      ls360Style: false,
      chat: sharedCtx.service.ls360Api,
      drug: sharedCtx.service.drug,
      target: sharedCtx.service.target,
      org: sharedCtx.service.organization,
      disease: sharedCtx.service.disease,
      news: sharedCtx.service.news,
      patent: sharedCtx.service.patent,
      paper: sharedCtx.service.literature,
      clinicalResult: sharedCtx.service.clinicalResult,
      clinicalTrial: sharedCtx.service.trial,
      translate: sharedCtx.service.translate,
    },
    bizHandler: {
      getRandomQuestion,
      handleClose: () => showAssistantDialog(false),
      handleFullscreen: (route: string) => {
        trackingEvent('GOTO_LS360', {
          trigger_point: 'SIDE_BAR_FULL_SCREEN',
        })
        window.open(`${VUE_APP_LS360_URL}/${route}`, '_blank')
      },
      getRecommend: async (text: string) =>
        await sharedCtx.service.ls360Api.getQuestionRecommend({
          text: text,
          page_context: {
            params: {
              language: localeUpcase.value,
            },
          },
        }),
      getTranslation: () => console.log('translation'),
      setPrinting,
      getPageContext,
      position2Anchor,
      gotoSearchResult,
      getSynapsePageUrl,
      getCurrentPageUrl: () => window.location.href,
      syncSynapseChatUsage,
      handleGetMoreTimes,
      checkHasChatRights,
      updateBetaStatus,
      ...(customBizHandler || {}),
    },
    userInfo: {
      name: userName.value || '',
      id: userInfo.value?.id,
      avatar: userInfo.value?.avatar || (userInfo.value?.avatar_color ? `#${userInfo.value.avatar_color.toString(16)}` : ''),
    },
    bizCallback: {
      summarize: onCallbackSummarize,
    },
    synapseChatRole: synapseChatRole.value,
    synapseChatUsage: synapseChatUsage.value,
    getClinicalDetailLink: (id) => `${window.location.origin}/clinical-progress-detail/${id}`,
    getOrganizationDetailLink: (id, isLsRelated, isClosed) => (isLsRelated && !isClosed ? `${window.location.origin}/organization/${id}` : ''),
    getDrugDetailLink: (id) => `${window.location.origin}/drug/${id}`,
    getClinicalResultDetailLink: (id) => `${window.location.origin}/clinical-result-detail/${id}`,
    getDiseaseDetailLink: (id) => `${window.location.origin}/disease/${id}`,
    getDrugTypeDetailLink: (id) => `${window.location.origin}/drug-type/${id}`,
    getTargetDetailLink: (id) => `${window.location.origin}/target/${id}`,
    getNewsDetailLink: (id) => `${window.location.origin}/news-detail/${id}`,
    getPaperDetailLink: (id) => `${window.location.origin}/literature-detail/${id}`,
    getPatentDetailLink: (id) => `${window.location.origin}/patent/${id}`,
    getPatentLegalStatus,
    getFeatureConfig,
    isOpenBeta: isOpenChatBeta.value,
  }

  const attrs = {
    src: wuJieEmptyUrl,
  }
  const versionTag = 'release20240815'
  const entryUrl =
    process.env.NODE_ENV === 'production' && versionTag
      ? `${VUE_APP_MICRO_BASE_URL}/micro-ls360-synapse/${versionTag}/index.html`
      : `${VUE_APP_MICRO_BASE_URL}/micro-ls360-synapse/index.html`
  return { props, attrs, entryUrl }
}
