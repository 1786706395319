export enum E_QUERY_ITEM_TYPE {
  KEYWORD = 'keyword',
  DRUG = 'drug',
  DISEASE = 'disease',
  TARGET = 'target',
  ORGANIZATION = 'organization',
  FIELD = 'field',
  RANGE_FIELD = 'range_field',
  GROUP = 'group', // must和嵌套时，才使用
  FIELD_EXIST = 'field_exist',
  /** 特殊的类型，用户organization view all list 查询 */
  ORG_ID_SEARCH = 'org_id_search',
}

export enum E_VERTEX_TYPE {
  DISEASE = 'indication',
  DRUG = 'drug',
  TARGET = 'target',
  ORGANIZATION = 'organization',
}

export enum E_KNOWLEDGE_COLOR {
  DRUG = 'rgba(195, 63, 63, 0.85)',
  DISEASE = 'rgba(81,157,85,0.85)',
  TARGET = 'rgba(204,159,59,0.85)',
  ORGANIZATION = 'rgba(49,124,215,0.85)',
}
export enum E_KNOWLEDGE_DONUT_LIGHT_COLOR {
  DRUG = '#E4AFAF',
  TARGET = '#E7D5AD',
  DISEASE = '#B5D4B7',
  ORGANIZATION = '#A9C7EB',
}
export enum E_KNOWLEDGE_DONUT_DARK_COLOR {
  DRUG = '#C95252',
  TARGET = '#D1A84E',
  DISEASE = '#62A666',
  ORGANIZATION = '#4589DB',
}

export enum E_ICON_SIZE {
  XS = 12,
  SM = 14,
  BASE = 16,
  LG = 18,
  XL = 20,
  XL_2 = 24,
  XL_3 = 30,
  XL_4 = 36,
  XL_5 = 48,
  XL_6 = 64,
}

export enum E_ORGANIZATION_TYPE {
  COMPANY = 'Company',
  EDUCATION = 'Education',
  GOVERNMENT = 'Government',
  NONPROFIT = 'Nonprofit',
}

export enum E_TIME_FORMAT {
  DD_MM_YYYY = 'YYYY-MM-DD',
  MM_YYYY = 'YYYY-MM',
  DD_MMM_YYYY_HH_MM_SS = 'YYYY-MM-DD HH:mm:ss',
  DD_MMM_YYYY_HH_MM = 'YYYY-MM-DD HH:mm',
  DD_MMM_YYYY_hh_mm_a = 'DD MMM YYYY hh:mm a',
  MMM_YYYY = 'MMM YYYY',
  DDD_MMM_YYYY = 'DD MMM YYYY',
  YYYY = 'YYYY',
  MMM = 'MMM',
  YYYY_MM_DD = 'YYYY年MM月DD日',
  YYYY_MM = 'YYYY年MM月',
}

export enum E_AMOUNT_UNIT {
  MILLION = 'MILLION',
  BILLION = 'BILLION',
  THOUSAND = 'THOUSAND',
  ALL = 'ALL',
}

export enum E_NODATA_SIZE {
  SMALL = 'small',
  MIDDLE = 'middle',
  BIG = 'big',
}

export enum E_LEAD_OR_COLLABORATOR_TYPE {
  COLLABORATOR = 'collaborator',
  LEAD = 'lead',
}

export enum E_COMMON_LIST_MODE {
  CARD = 'card',
  TABLE = 'table',
}

export enum E_DATA_COVERAGE_KEYS {
  DRUG = 'drug',
  TARGET = 'target',
  ORGANIZATION = 'org',
  NEWS = 'news',
  LITERATURE = 'paper',
  CLINICAL = 'clinical',
  CLINICAL_RESULT = 'clinical_trial_result',
  DRUG_DEAL = 'drug_deal_result',
  INDICATION = 'indication',
  FUNDING_RESEARCH = 'funded_research',
  APPROVAL = 'approval',
  TRANSLATIONAL_MEDICINE = 'translational_medicine',
}

export enum E_ALIGN_DIRECTION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum E_DATA_DURATION_TYPE {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUAL = 'SEMI-ANNUAL',
  YEARLY = 'YEARLY',
}

export enum E_SOCIAL_MEDIA_TYPE {
  HOMEPAGE = 'HOMEPAGE',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  WIKIPEDIA = 'WIKIPEDIA',
}

export enum E_Feedback_Type {
  DATA_FEED = 'DATA_FEED',
  FUNCTION_FEED = 'FUNCTION_FEED',
}
