import { loadScript } from 'pharmsnapMF_shared/utils'
import { VUE_APP_ACCOUNT_BASEURL, VUE_APP_CLIENT_ID, VUE_APP_PASSPORT_BASEURL, VUE_APP_STATIC_COMMON_PATH } from '../config'
import { getAxios } from '../service/service'
import { getLocaleLang } from './business/locale'

// https://confluence.zhihuiya.com/pages/viewpage.action?pageId=143762595
export function initBackOfficeWidget(useQiyuOrZendesk: boolean) {
  loadScript(`${VUE_APP_STATIC_COMMON_PATH}/backoffice-widget@1.0.0/umd/backoffice-widget.min.no-cache.js`).then(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config: any = {
      locale: getLocaleLang(),
      clientId: VUE_APP_CLIENT_ID, // 产品id
      axios: getAxios(),
      serverBase: VUE_APP_PASSPORT_BASEURL,
      accountServerBase: VUE_APP_ACCOUNT_BASEURL,
      usePublishRemind: false, // 不使用发版提示
      useBrowerUpdateRemind: false, // 不使用浏览器更新提示
      useExpirationRemind: false, // 不使用过期提示
      useQiyuOrZendesk: useQiyuOrZendesk,
    }

    if (useQiyuOrZendesk) {
      // https://patsnap.atlassian.net/browse/PBI-3970
      config.qiyuConfig = {
        ...config.qiyuConfig,
        groupid: '481980499',
      }
    }

    /** http://git.patsnap.com/core-product/micro-fe/common-widget/blob/Rel-1.0/backoffice-widget/src/library.tsx#L101 */
    window.backoffceWeight.init(config)
  })
}
