import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BasicGraphChart } from '@pharmsnap/shared/chart'
import { useChart, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { ITopPatentCoAssignee } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import { linearMap } from 'echarts/lib/util/number'
import { cloneDeep, maxBy, minBy } from 'lodash'
import { IGraphItem } from '../../../../types'
import { usePatentChartDrill } from '../../../../use/usePatentChartDrill'
import $style from '../../../style/Common.module.scss'
import { getAssigneeName } from '../util'
import $classes from './TopAssigneeChart.module.scss'

/**
 * 指定size范围内，根据value获取size
 * @param value 当前数值
 * @param min 数值中的最小值
 * @param max  数值中的最大值
 * @param sizeRange size范围
 * @returns
 */
function getLinerSize(value: number, min = 0, max = 0, sizeRange: [number, number]) {
  const domain = [min, max]
  return linearMap(value, domain, sizeRange, true)
}

export const TopAssigneeChart = defineComponent({
  name: 'TopAssigneeChart',
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    chartData: {
      required: true,
      type: Object as PropType<ITopPatentCoAssignee>,
    },
    hasBox: {
      type: Boolean,
    },
    aggregateByParent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const { onClickChart } = usePatentChartDrill()

    const chartConfig = {
      autoResize: true,
      registerEvent: (instance: EChartsType) => {
        instance.on('click', handleClick)
      },
    }

    const colorConfig = ['#1976D2', '#27B4D1', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#E96A94', '#A863F7', '#8992EB']

    /**
     * 计算尺寸
     * @param data 数据集
     * @param value 当前数据
     * @param range 尺寸范围
     */
    function calcSize<T extends { count: number }>(data: T[], value: number, range: [number, number] = [8, 40]) {
      const minValue = minBy(data, 'count')?.count
      const maxValue = maxBy(data, 'count')?.count
      return getLinerSize(value, minValue, maxValue, range)
    }

    const seriesData = computed(() => {
      const data: IGraphItem[] = (props.chartData?.items || []).map((assignee, index) => {
        return {
          ...assignee,
          id: assignee.org_id,
          name: getAssigneeName(assignee, isCN.value, true),
          value: toThousands(assignee.count),
          category: index,
        }
      })
      props.chartData.items?.forEach((assignee, index) => {
        if (assignee.items?.length) {
          assignee.items.forEach((coAssignee) => {
            const isExist = data.find((o) => o.id === coAssignee.org_id)
            if (!isExist) {
              data.push({
                ...coAssignee,
                id: coAssignee.org_id,
                name: getAssigneeName(coAssignee, isCN.value, true),
                value: toThousands(coAssignee.count),
                category: index,
              })
            }
          })
        }
      })
      data.forEach((item) => (item.symbolSize = calcSize(data, item.count)))
      return data
    })

    const seriesLinks = computed(() => {
      const links = cloneDeep(props.chartData.links)
      links.forEach((item) => (item.lineWidth = calcSize(links, item.count, [1, 6])))
      return links
    })

    const categories = computed(() => {
      return Array(10)
        .fill('')
        .map((c, index) => ({
          name: `${index}`,
        }))
    })

    const chartOption = computed<BasicGraphChart>(() => {
      const opt: BasicGraphChart = {
        type: 'graph',
        color: colorConfig,
        tooltip: {
          formatter: (params: any) => {
            if (!params.data.name) {
              return ''
            }
            return `
          <span style="display:inline-block;width:10px;height:10px;border-radius: 50%; background-color:${params.color}"></span>
          <span style="color:#061632;font-size:12px;line-height:14px;margin-left:2px;">${getAssigneeName(params.data, isCN.value)}: ${
              params.data.value
            }</span><br>
          `
          },
        },
        series: [
          {
            type: 'graph',
            layout: 'force',
            zoom: 2.5,
            force: {
              repulsion: 80,
              gravity: 0.15,
              edgeLength: [40, 70],
            },
            data: seriesData.value,
            links: seriesLinks.value.map((link) => {
              return {
                ...link,
                label: {
                  show: true,
                  position: 'middle',
                  formatter: () => toThousands(link.count),
                },
                lineStyle: {
                  width: link.lineWidth || 2,
                  color: 'source',
                  curveness: 0,
                },
              }
            }),
            categories: categories.value,
            roam: true,
            draggable: true,
            cursor: 'pointer',
            label: {
              show: true,
              width: 100,
              overflow: 'break',
              position: 'bottom',
              formatter: '{b}',
              textBorderColor: '#fff',
              textBorderWidth: 4,
              color: '#000000',
            },
            animationDuration: 500,
            scaleLimit: {
              min: 1,
              max: 4,
            },
          },
        ],
      }
      return opt
    })

    const { chartContainer } = useChart(chartOption, chartConfig)

    function handleClick(params: any) {
      if (params.data.name) {
        // node
        const { id } = params.data
        handleClickNode(id)
      } else {
        // link
        const { source, target } = params.data
        handleClickLink(source, target)
      }
    }

    function handleClickNode(orgId: string) {
      const org = seriesData.value.find((o) => o.id === orgId)
      if (!org) return
      onClickChart({
        queryState: props.queryService.state,
        extraFilter: [
          {
            type: 'field',
            fields: [props.aggregateByParent ? 'PHS_ORG_ROOT_ID_ONEID' : 'PHS_ORG_ID_ONEID'],
            value: [
              {
                display_name_cn: org.display_name_cn,
                display_name_en: org.display_name_en,
                type: 'organization',
                id: org.id,
                search_strategy: 'ANS_ID',
              },
            ],
          },
        ],
      })
    }

    function handleClickLink(sourceId: string, targetId: string) {
      const sourceOrg = seriesData.value.find((o) => o.id === sourceId)
      const targetOrg = seriesData.value.find((o) => o.id === targetId)
      if (!sourceOrg || !targetOrg) return
      const fields = [props.aggregateByParent ? 'PHS_ORG_ROOT_ID_ONEID' : 'PHS_ORG_ID_ONEID']
      onClickChart({
        queryState: props.queryService.state,
        extraFilter: [
          {
            condition: E_QUERY_ITEM_CONDITION.ALL,
            type: 'field',
            fields,
            value: [
              {
                display_name_cn: sourceOrg.display_name_cn,
                display_name_en: sourceOrg.display_name_en,
                type: 'organization',
                id: sourceOrg.id,
                search_strategy: 'ANS_ID',
              },
            ],
          },
          {
            condition: E_QUERY_ITEM_CONDITION.ALL,
            type: 'field',
            fields,
            value: [
              {
                display_name_cn: targetOrg.display_name_cn,
                display_name_en: targetOrg.display_name_en,
                type: 'organization',
                id: targetOrg.id,
                search_strategy: 'ANS_ID',
              },
            ],
          },
        ],
      })
    }

    return { chartContainer }
  },
  render() {
    return <div ref="chartContainer" class={this.hasBox ? [$style.fullScreenChart, $classes.fullScreenChart] : $classes.chartContainer}></div>
  },
})
