import { IQueryItem } from '@patsnap/synapse_common_interface'
import { MANUAL_DICT_ID } from '@pharmsnap/pharmsnap-web/views/clinical-result/utils/constant'
import { onMounted, reactive, watch } from '@vue/composition-api'
import { userInjectFilterService } from '../components/business/BFilter/BFilterService'
import { IClinicalResultService } from '../types/clinical-result'
import { IQueryService } from './useQueryService'

export function useClinicalResultService(params: { queryService: IQueryService }): IClinicalResultService {
  const { queryService } = params
  const {
    state: { query },
  } = queryService

  const { actions: filterActions } = userInjectFilterService()

  const state = reactive<{
    viewManual: boolean
  }>({
    viewManual: false,
  })

  onMounted(() => {
    updateManual(query.filter || [])
  })

  watch(() => query.filter, updateManual)

  function updateManual(filterItems: IQueryItem[]) {
    state.viewManual = filterItems.some(
      (item) =>
        item.type === 'field' &&
        item.fields.includes('ANNOTATION_METHOD_ID') &&
        item.value.length === 1 &&
        item.value[0].type === 'text' &&
        item.value[0].value === MANUAL_DICT_ID[0]
    )
  }

  /**
   * 只看人工专利
   * @param val
   */
  async function handleOnlyViewManual(val: boolean) {
    if (val) {
      filterActions?.setFilterValue('ANNOTATION_METHOD_ID', [
        {
          display_name_cn: '人工标引',
          display_name_en: 'Manual',
          value: MANUAL_DICT_ID[0],
        },
      ])
    } else {
      filterActions?.removeAllFilterValueArrByFieldName('ANNOTATION_METHOD_ID')
    }
    filterActions?.refine(false)
  }

  return {
    state,
    actions: {
      handleOnlyViewManual,
    },
  }
}
