import {
  E_AGGREGATION_TYPE,
  IAggregationData,
  IAggregationItem,
  IAggregationParams,
  IQuery,
  ISearchCollapse,
} from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { getDrugDealPaymentAggregationRange, getYearRangeFromNow } from '@pharmsnap/shared/src/utils'
import { computed, reactive, ref, shallowRef, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, isUndefined } from 'lodash'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem, IAnalysisDisplayIconType } from '../../BAnalysisChartNext/type'

export type IUseDrugDealAggChartType =
  | 'line-bar'
  | 'total-payment-bar'
  | 'upfront-payment-bar'
  | 'milestone-bar'
  | 'phase-bar'
  | 'principal-horizontal-bar'
  | 'partner-horizontal-bar'
  | 'target-horizontal-bar'
  | 'drug-type-horizontal-bar'
  | 'deal-indication-horizontal-bar'

export interface IUseDrugDealAggChartOptions {
  query?: MaybeRef<IQuery>
  type: MaybeRef<IUseDrugDealAggChartType>
}

type IDrugDealAggChartModel = {
  display: IAnalysisDisplayIconType
  rollup?: boolean
}

export const lineBarChartTypes: IUseDrugDealAggChartType[] = ['line-bar']
export const verticalBarChartTypes: IUseDrugDealAggChartType[] = ['total-payment-bar', 'upfront-payment-bar', 'milestone-bar', 'phase-bar']
export const horizontalBarChartTypes: IUseDrugDealAggChartType[] = [
  'principal-horizontal-bar',
  'partner-horizontal-bar',
  'target-horizontal-bar',
  'drug-type-horizontal-bar',
  'deal-indication-horizontal-bar',
]
const supportRollupControlChartTypes: IUseDrugDealAggChartType[] = ['principal-horizontal-bar', 'partner-horizontal-bar']
const collapseChartTypes: IUseDrugDealAggChartType[] = [
  'line-bar',
  'total-payment-bar',
  'upfront-payment-bar',
  'milestone-bar',
  ...horizontalBarChartTypes,
]
const needSpecialAnalysisChartTypes: IUseDrugDealAggChartType[] = ['phase-bar']
const aggregationItemRecordType: Record<IUseDrugDealAggChartType, IAggregationItem[]> = {
  'line-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.DATE_HISTOGRAM_YEAR,
      aggregation_field: 'DEAL_TIME_YEAR',
      sub_aggregation: [
        {
          aggregation_type: E_AGGREGATION_TYPE.SUM,
          aggregation_field: 'TOTAL_VALUE',
        },
      ],
    },
  ],
  'total-payment-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.RANGE,
      aggregation_field: 'TOTAL_VALUE',
      range: getDrugDealPaymentAggregationRange('TOTAL_VALUE'),
    },
  ],
  'upfront-payment-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.RANGE,
      aggregation_field: 'UPFRONT_PAYMENT',
      range: getDrugDealPaymentAggregationRange('UPFRONT_PAYMENT'),
    },
  ],
  'milestone-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.RANGE,
      aggregation_field: 'MILESTONE_PAYMENT',
      range: getDrugDealPaymentAggregationRange('MILESTONE_PAYMENT'),
    },
  ],
  'phase-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'NORMALIZED_PHASE_SIGN_ID_FLATTEN',
      sub_aggregation: [
        {
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          aggregation_field: 'DRUG_ID',
          limit: 2000,
        },
      ],
    },
  ],
  'principal-horizontal-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'PRINCIPLE_ORG_ID',
      limit: 10,
    },
  ],
  'partner-horizontal-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'PARTNER_ORG_ID',
      limit: 10,
    },
  ],
  'target-horizontal-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'TARGET_ID_EXTEND',
      limit: 10,
    },
  ],
  'drug-type-horizontal-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'DRUG_TYPE',
      limit: 10,
    },
  ],
  'deal-indication-horizontal-bar': [
    {
      aggregation_type: E_AGGREGATION_TYPE.TERMS,
      aggregation_field: 'DEAL_DISEASE_ID',
      limit: 10,
    },
  ],
}

function initModel(chartType: IUseDrugDealAggChartType) {
  const model: IDrugDealAggChartModel = {
    display: 'vertical-bar',
  }

  // 根据类型赋值不同的display初始值
  if (lineBarChartTypes.includes(chartType)) {
    model.display = 'line-bar'
  } else if (horizontalBarChartTypes.includes(chartType)) {
    model.display = 'horizontal-bar'
  }

  if (supportRollupControlChartTypes.includes(chartType)) {
    model.rollup = true
  }

  return model
}

function initControls(chartType: IUseDrugDealAggChartType) {
  const controls: IAnalysisChartControlItem<keyof IDrugDealAggChartModel>[] = []

  if (lineBarChartTypes.includes(chartType)) {
    controls.push({
      type: 'display',
      bindField: 'display',
      items: [
        { type: 'line-bar', value: 'line-bar' },
        { type: 'table', value: 'table' },
      ],
    })
  }
  if (verticalBarChartTypes.includes(chartType)) {
    controls.push({
      type: 'display',
      bindField: 'display',
      items: [
        { type: 'vertical-bar', value: 'vertical-bar' },
        { type: 'table', value: 'table' },
      ],
    })
  }
  if (horizontalBarChartTypes.includes(chartType)) {
    controls.push({
      type: 'display',
      bindField: 'display',
      items: [
        { type: 'horizontal-bar', value: 'horizontal-bar' },
        { type: 'table', value: 'table' },
      ],
    })

    if (supportRollupControlChartTypes.includes(chartType)) {
      controls.push({
        type: 'rollup',
        bindField: 'rollup',
      })
    }
  }

  return controls
}

function initControlLayout(isFullScreen = false) {
  const layoutItems: Array<IAnalysisChartControlLayoutItem<keyof IDrugDealAggChartModel>> = [
    {
      field: 'display',
      position: 'left',
    },
    {
      field: 'rollup',
      position: isFullScreen ? 'data-panel' : 'middle',
    },
  ]

  return layoutItems
}

function getAggregationItem(chartType: IUseDrugDealAggChartType, controlModel: IDrugDealAggChartModel) {
  const clonedAggregationItem = cloneDeep(aggregationItemRecordType[chartType])

  const { rollup } = controlModel

  if (!isUndefined(rollup) && supportRollupControlChartTypes.includes(chartType)) {
    clonedAggregationItem.forEach((item) => {
      if (chartType === 'principal-horizontal-bar') {
        item.aggregation_field = rollup ? 'PRINCIPLE_ORG_ID_ROOT' : 'PRINCIPLE_ORG_ID'
      }
      if (chartType === 'partner-horizontal-bar') {
        item.aggregation_field = rollup ? 'PARTNER_ORG_ID_ROOT' : 'PARTNER_ORG_ID'
      }
    })
  }

  if (chartType === 'line-bar') {
    const fiveYearRange = getYearRangeFromNow(5, true)

    clonedAggregationItem.forEach((item) => {
      item.extended_bounds_from = fiveYearRange.from
      item.extended_bounds_to = fiveYearRange.to
    })
  }

  return clonedAggregationItem
}

export function useDrugDealAggChart(options: IUseDrugDealAggChartOptions) {
  const { query = { type: 'group', must: [], filter: [] }, type } = options

  const { locale } = useLocale()

  const loading = ref(true)

  const isEmpty = ref(true)

  const model = reactive(initModel(unref(type)))

  const controls = reactive(initControls(unref(type)))

  const controlLayout = reactive(initControlLayout(false))

  const controlLayoutFullscreen = reactive(initControlLayout(true))

  const aggData = shallowRef<IAggregationData>({
    aggregation_result: [
      { items: [], aggregation_field: '', total: 0 },
      { items: [], aggregation_field: '', total: 0 },
    ],
    total: 0,
  })

  const aggParams = computed(() => {
    const clonedQuery = cloneDeep(unref(query))
    const clonedAggregation = getAggregationItem(unref(type), cloneDeep(model))
    const collapse: ISearchCollapse | undefined = collapseChartTypes.includes(unref(type))
      ? {
          field: 'DEAL_ID',
          rules: [
            {
              field: 'NORMALIZED_PHASE_SIGN_RANK_FLATTEN',
              type: 'max',
            },
          ],
        }
      : undefined
    const params: IAggregationParams = {
      data_type: 'drug_deal',
      query: clonedQuery,
      aggregation: clonedAggregation,
      collapse,
    }
    return params
  })

  const categoryName = computed(() => {
    const chartType = unref(type)
    switch (chartType) {
      case 'line-bar':
        return unref(locale) === 'cn' ? '交易日期' : 'Deal Date'
      case 'phase-bar':
        return unref(locale) === 'cn' ? '最高研发状态' : 'Highest Phase'
      case 'total-payment-bar':
      case 'upfront-payment-bar':
      case 'milestone-bar':
        return unref(locale) === 'cn' ? '交易金额' : 'Deal Payment'
      case 'principal-horizontal-bar':
        return unref(locale) === 'cn' ? '转让方' : 'Principal Organization'
      case 'partner-horizontal-bar':
        return unref(locale) === 'cn' ? '受让方' : 'Partner Organization'
      case 'target-horizontal-bar':
        return unref(locale) === 'cn' ? '靶点' : 'Target'
      case 'drug-type-horizontal-bar':
        return unref(locale) === 'cn' ? '药物类型' : 'Drug Type'
      case 'deal-indication-horizontal-bar':
        return unref(locale) === 'cn' ? '交易适应症' : 'Deal Indication'
      default:
        return 'todo category name'
    }
  })

  const countName = computed(() => {
    const chartType = unref(type)
    switch (chartType) {
      case 'line-bar':
      case 'total-payment-bar':
      case 'upfront-payment-bar':
      case 'milestone-bar':
      case 'principal-horizontal-bar':
      case 'partner-horizontal-bar':
      case 'target-horizontal-bar':
      case 'drug-type-horizontal-bar':
      case 'deal-indication-horizontal-bar':
        return unref(locale) === 'cn' ? '交易数量' : 'No. of Deals'
      default:
        return unref(locale) === 'cn' ? '药物数量' : 'No. of Drugs'
    }
  })

  const lineCountName = computed(() => {
    const chartType = unref(type)
    if (chartType === 'line-bar') {
      return unref(locale) === 'cn' ? '总金额' : 'Total Payment'
    }
    return ''
  })

  const settingDataPanelTitle = computed(() => {
    const chartType = unref(type)
    if (supportRollupControlChartTypes.includes(chartType)) {
      return unref(locale) === 'cn' ? '机构' : 'Organization'
    }
    return ''
  })

  watch(model, () => fetchAggData(), { deep: true })

  // 将聚合结果，处理成折柱混合图的数据格式：2个aggregation_result，一个是柱状图的数据，一个是折线图的数据
  function getLineBarData(data: IAggregationData): IAggregationData {
    if (data.aggregation_result?.[0]?.items?.length) {
      const items = data.aggregation_result[0].items
      data.aggregation_result.push({
        aggregation_field: 'TOTAL_VALUE',
        total: data.aggregation_result?.[0]?.total || 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        items: items.map((item) => ({
          key: item.key,
          display_name_cn: item.key,
          display_name_en: item.key,
          count: (item.other_info?.sum || 0) as number,
        })),
      })
    }
    return data
  }

  async function getData() {
    if (needSpecialAnalysisChartTypes.includes(unref(type))) {
      return sharedCtx.service.drugDeal.getDealAnalysisAggregation(aggParams.value)
    }
    return sharedCtx.service.drugDeal.getAggregation(aggParams.value)
  }
  async function fetchAggData() {
    loading.value = true
    const rt = await getData()
    if (rt.success) {
      isEmpty.value =
        !rt.data.aggregation_result ||
        rt.data.aggregation_result.length === 0 ||
        !rt.data.aggregation_result[0]?.items?.length ||
        rt.data.aggregation_result[0]?.items?.every((i) => i.count === 0) ||
        rt.data.aggregation_result[0]?.items.every(
          (i) =>
            !!i.aggregation_result?.[0] &&
            (!i.aggregation_result?.[0].items ||
              i.aggregation_result?.[0].items.length === 0 ||
              i.aggregation_result?.[0]?.items?.every((ii) => ii.count === 0))
        )

      aggData.value = isEmpty.value
        ? {
            aggregation_result: [
              { items: [], aggregation_field: '', total: 0 },
              { items: [], aggregation_field: '', total: 0 },
            ],
            total: 0,
          }
        : unref(type) === 'line-bar'
        ? getLineBarData(rt.data)
        : rt.data
      loading.value = false
    } else {
      loading.value = false
      isEmpty.value = true
    }
  }

  return {
    model,
    controls,
    controlLayout,
    controlLayoutFullscreen,
    aggData,
    categoryFieldName: categoryName,
    countFieldName: countName,
    lineCountFieldName: lineCountName,
    fetchAggData,
    loading,
    isEmpty,
    settingDataPanelTitle,
  }
}
