import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { getShownEntityProfile } from '@patsnap/synapse_common_utils'
import { filter, unionBy } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import { BSequenceDialog, BSequenceItem, GDialogTrigger, GLimitedContainer } from '../components'
import { IBaseTargetInfo, ILang, ITargetSequenceInfo } from '../types'
import { getSortFnByLang, getSpecialLang } from '../utils'
import { renderLimitedSynonyms } from './baseRender'

export function renderTargetSynonyms(data: IBaseTargetInfo, lang: ILang) {
  const aliasTexts = (data.alias || [])
    .map((i) => i.name)
    .filter((i) => !!i)
    .sort(getSortFnByLang(lang))
  return renderLimitedSynonyms(aliasTexts, 3)
}
export function renderTargetFullName(data: IBaseTargetInfo, $i18n: IVueI18n) {
  return getSpecialLang({ data: data.target_name, lang: $i18n.locale.toUpperCase() as ILang, field: 'name' })
}

function _addPubMedLinks(text: string) {
  // 使用正则表达式找到匹配 PubMed 引用的数字部分
  const regex = /PubMed:(\d+)/g
  const newText = text.replaceAll(regex, (match, p1) => {
    return `PubMed:<a class="underline text-blue-default" target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/${p1}" target="_blank">${p1}</a>`
  })

  return newText
}

export function renderTargetIntroductionPlain(data: IBaseTargetInfo) {
  return getShownEntityProfile(data.profile_v2)
    .map((item) => {
      return _addPubMedLinks(item.content)
    })
    .join('\n\n')
}

export function renderTargetIntroduction(data: IBaseTargetInfo) {
  const content = renderTargetIntroductionPlain(data)

  return (
    <GLimitedContainer limitLines={3} lineHeight={24}>
      <div domPropsInnerHTML={content} class="leading-6"></div>
    </GLimitedContainer>
  )
}

export function renderTargetGeneSequence(data: IBaseTargetInfo, $i18n: IVueI18n, callback: (sequence_hash_id: string) => void) {
  const sequenceList = unionBy(
    filter(data.sequence_info, (item) => item.sequence),
    'sequence_number'
  ) as ITargetSequenceInfo[]

  if (!sequenceList?.length) return EMPTY_PLACEHOLDER

  const [{ sequence, related_target_id_view }] = sequenceList

  return (
    <div>
      <div style="width: 800px">
        <BSequenceItem
          onClickSimilar={callback}
          // 只有一个的情况下，默认是该靶点本身，无需显示靶点名称
          targetView={sequenceList.length > 1 ? related_target_id_view : ({} as IBaseTargetInfo)}
          sequence={sequence}
        ></BSequenceItem>
      </div>
      {sequenceList.length > 1
        ? [
            <GDialogTrigger dialog={BSequenceDialog} onClickSimilar={callback} targetSequenceList={sequenceList}>
              <span class="text-sm leading-6 hover:underline cursor-pointer text-blue-default">
                {$i18n.tc('common.viewAll')}({sequenceList.length})
              </span>
            </GDialogTrigger>,
          ]
        : null}
    </div>
  )
}
