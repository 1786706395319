import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class UserCertApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  public async verifyEmail(email: string): Promise<IHttpWrap<{ repeat_flag: boolean; whitelist_flag: boolean }>> {
    return await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/user_cert/cert_email/verify'),
      data: {
        email: email,
        verify_type: ['repeat', 'whitelist'],
      },
    })
  }

  /**
   * 提交用户反馈
   */
  public async submitFeedBack(content: string) {
    return await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/user_cert/reply'),
      data: {
        content,
      },
    })
  }
}
