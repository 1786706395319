import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { GDialog, GFoldingContainer, GIcon } from '@pharmsnap/shared/components'
import { I18nLang } from '@pharmsnap/shared/i18n'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { PropType, computed, defineComponent } from '@vue/composition-api'
import { isFunction } from 'lodash'
import { userInjectFilterService } from '../../../BFilterService'
import { IFilterConfig, IFilterDisplayItem, IFilterItemConfig, IFilterNestFilterItem } from '../../../types'
import { filterValueMap2QueryDisplayItem, getFilterConfigArr } from '../../../utils/utils'
import { FilterValueTag } from '../../ui/FilterValueTag/FilterValueTag'
import $style from './AdvanceFilter.module.scss'
import { AdvanceFilterContent } from './AdvanceFilterContent'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const AdvanceFilter = defineComponent({
  name: 'AdvanceFilter',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterConfig>,
    },
  },
  setup(props, ctx) {
    const { state, actions } = userInjectFilterService()
    const { locale } = useLocale()
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()
    const advanceFilterConfigArr = getFilterConfigArr(props.config).filter((item) => item.isSupportAdvanceRefine)
    const currentSelectAdvanceFieldTitle = computed(() => {
      const field = state?.advanceSelectField
      if (!field) {
        return ''
      }
      return getFieldNameByLang(props.config.fieldLangMap, field, ctx.parent?.$i18n.locale as I18nLang)
    })
    const filterDisplayItem = computed(() => {
      if (state?.filterValueMapInTime) {
        return filterValueMap2QueryDisplayItem(state?.filterValueMapInTime, props.config.filterItemConfigMap, props.config.fieldLangMap)
      }
      return []
    })
    function init() {
      if (state?.advanceSelectField) {
        ctx.emit('aggregation', state?.advanceSelectField)
      }
    }
    init()
    function onClickField(config: IFilterItemConfig) {
      if (props.config.onBeforeRefine) {
        const val = props.config.onBeforeRefine([config], {
          isFreeUser: isFreeUser.value,
        })
        if (!val.valid) {
          val.upgrade && changeUpgrade({ ...val.upgrade, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER })
          return
        }
      }

      actions?.setAdvanceSelectField(config.field)
      ctx.emit('aggregation', config.field)
    }
    function onClose() {
      actions?.setFilterValueMapInTime({})
      actions?.setIsShowAdvanceDialog(false)
    }
    function refine(isExclude: boolean) {
      actions?.setIsShowAdvanceDialog(false)
      actions?.refine(isExclude)
    }
    function onRemoveFilterValue(data: IFilterDisplayItem) {
      const { field } = data
      const currenFilterValueMapInTime = state?.filterValueMapInTime
      if (currenFilterValueMapInTime) {
        actions?.setFilterValue(field, currenFilterValueMapInTime[field]?.filter((item) => item.value !== data.value) || [])
      }
    }

    function clearAll() {
      actions?.clearAll()
    }

    function onFoldingChange(field: string, val: boolean) {
      actions?.switchFold(field, val)
      const config = props.config?.filterItemConfigMap[field]
      config && config.onFoldingChange && config.onFoldingChange(val)
    }

    return {
      locale,
      advanceFilterConfigArr,
      onClickField,
      onClose,
      state,
      currentSelectAdvanceFieldTitle,
      filterDisplayItem,
      refine,
      onRemoveFilterValue,
      clearAll,
      onFoldingChange,
    }
  },
  methods: {
    renderFilterTreeList(arr: Array<IFilterNestFilterItem>, depth: number) {
      return arr.map((field) => {
        if (Array.isArray(field)) {
          const [parentField, restField] = field

          const config = this.config.filterItemConfigMap[parentField]

          if (!config) return null

          const childrenContent = this.renderFilterTreeList(Array.isArray(restField) ? restField : [restField], depth + 1)

          const opened = this.state?.openingMap[parentField] || false

          const originLabel = getFieldNameByLang(this.config.fieldLangMap, parentField, this.locale)

          const currentLabel = config.customLabel
            ? isFunction(config.customLabel)
              ? config.customLabel(originLabel, this.locale)
              : config.customLabel
            : originLabel

          return (
            <GFoldingContainer
              headerCls={$style.menu}
              truncate={true}
              label={currentLabel}
              layout="loose"
              arrowMode="down-up"
              opened={opened}
              onChange={(val: boolean) => this.onFoldingChange(parentField, val)}
            >
              <template slot="default">{childrenContent}</template>
            </GFoldingContainer>
          )
        } else {
          const config = this.config.filterItemConfigMap[field]

          if (!config) return null

          if (config.type === 'text') return null

          if (!config.isSupportAdvanceRefine) return null

          const originLabel = getFieldNameByLang(this.config.fieldLangMap, field, this.locale)

          const currentLabel = config.customLabel
            ? isFunction(config.customLabel)
              ? config.customLabel(originLabel, this.locale)
              : config.customLabel
            : originLabel

          return (
            <div
              class={[
                'cursor-pointer',
                $style.menu,
                {
                  'bg-gray-30': this.state?.advanceSelectField === field,
                },
              ]}
              title={currentLabel}
              onClick={() => this.onClickField(config)}
            >
              <span style={{ marginLeft: `${depth * 8}px` }}>{currentLabel}</span>
            </div>
          )
        }
      })
    },

    renderFilterList() {
      return (
        <div class="flex flex-col h-full p-2">
          <div class={[$style.title, 'p-2']}>{this.$tc('AdvanceFilter.advanceRefine')}</div>
          <div class="flex-1 h-full overflow-y-auto">{this.renderFilterTreeList(this.config.filterItems, 0)}</div>
        </div>
      )
    },
    renderFilterContent() {
      const currentSelectAdvanceField = this.state?.advanceSelectField
      if (!currentSelectAdvanceField) {
        return null
      }
      const config = this.config.filterItemConfigMap[currentSelectAdvanceField]
      return (
        <div class="p-2 pt-4 flex flex-col h-full" v-ls-loading={this.state?.advanceFilterLoading}>
          {!!currentSelectAdvanceField && config && (
            <AdvanceFilterContent
              isLoading={this.state?.advanceFilterLoading}
              class="flex-1 overflow-hidden"
              config={config}
              fieldLangMap={this.config.fieldLangMap}
              key={currentSelectAdvanceField}
              field={currentSelectAdvanceField}
            ></AdvanceFilterContent>
          )}
        </div>
      )
    },
    renderRefineQuery() {
      return (
        <div class="p-2 h-full flex flex-col">
          <div class="flex">
            <div>{this.$t('AdvanceFilter.refineBY')}</div>
            <div class="ml-auto text-blue-default rounded px-1 cursor-pointer hover:bg-gray-30" onClick={this.clearAll}>
              {this.$t('AdvanceFilter.clearAll')}
            </div>
          </div>
          <div class="flex-1 overflow-y-auto border-gray-40 border rounded mt-2 mb-2 p-2">
            {this.filterDisplayItem.map((item) => {
              return (
                <FilterValueTag key={item.field + item.value} class="mr-2 mb-2" data={item} onclickClose={this.onRemoveFilterValue}></FilterValueTag>
              )
            })}
          </div>
          <div class="flex justify-end">
            <button class={$style.cancelBtn} type="button" data-type="default" onClick={this.onClose}>
              {this.$tc('common.cancel')}
            </button>
            <button class="pt-ui-btn ml-2" type="button" disabled={!this.state?.hasChange} data-type="danger" onClick={() => this.refine(true)}>
              {this.$tc('AdvanceFilter.exclude')}
            </button>
            <button class="pt-ui-btn ml-2" type="button" disabled={!this.state?.hasChange} data-type="submit" onClick={() => this.refine(false)}>
              {this.$tc('AdvanceFilter.refine')}
            </button>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <GDialog
        v-model={this.state!.isShowAdvanceDialog}
        width="910px"
        emptyMode={true}
        scopedSlots={{
          default: () => {
            return (
              <div class={['flex flex-col']} style="height:558px">
                <div class="flex-1 flex overflow-hidden relative">
                  <GIcon
                    class={['cursor-pointer', $style.close]}
                    nativeOnClick={this.onClose}
                    size={24}
                    useSvgDefaultColor
                    svgName="SolidCloseMedium"
                  ></GIcon>
                  <div style="width:220px" class="border-gray-40 border-0 border-r h-full whitespace-nowrap">
                    {this.renderFilterList()}
                  </div>
                  <div class="flex-1 h-full overflow-hidden">{this.renderFilterContent()}</div>
                </div>
                <div style="height:168px" class="border-gray-40 border-0 border-t">
                  {this.renderRefineQuery()}
                </div>
              </div>
            )
          },
        }}
      ></GDialog>
    )
  },
})
