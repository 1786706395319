import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import { GMiniSwitch } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { IAutoCompleteType, IBAcTag, IExampleTag, IListItem, ISearchDataType } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, ref } from '@vue/composition-api'
import { BAcBase } from '../../BAc/BAcBase'
import { BLabelAc } from '../../BLabelAc/BLabelAc'
import { BAcTag } from '../../tag/BAcTag/BAcTag'
import $classes from './BLabelInput.module.scss'

export const BLabelInput = defineComponent({
  name: 'BLabelInput',
  props: {
    label: {
      required: true,
      type: String,
    },
    tags: {
      required: true,
      type: Array as PropType<IBAcTag[]>,
    },
    tagsLimit: {
      type: Number,
      default: 10,
    },
    tagSize: {
      type: String as PropType<'mini' | 'small' | 'medium' | 'default'>,
      default: 'small',
    },
    infoContent: {
      type: String,
    },
    showLimitNum: {
      type: Boolean,
      default: false,
    },
    inputTip: {
      type: String,
    },
    exampleLabel: {
      type: String,
    },
    exampleTag: {
      type: Object as PropType<IExampleTag>,
    },
    exampleClass: {
      type: String,
    },
    /**
     * 只显示placeholder
     */
    placeHolder: {
      type: String,
    },
    quickAddExample: {
      type: Boolean,
      default: false,
    },
    showInnerCheckbox: {
      type: Boolean,
      default: false,
    },
    innerCheckboxVal: {
      type: Boolean,
      default: false,
    },
    showSwitch: {
      type: Boolean,
      default: false,
    },
    switchInfo: {
      type: String,
    },
    switchVal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<IAutoCompleteType>,
      required: false,
    },
    /**
     * popover样式
     */
    popperClass: {
      type: String,
      default: '',
    },
    /**
     * 自定义label container样式
     */
    labelContainerClass: {
      type: String,
      default: '',
    },
    /**
     * example是否为tag样式
     */
    exampleIsTagStyle: {
      type: Boolean,
      default: true,
    },
    clickOutClear: {
      type: Boolean,
      default: true,
    },
    logicVal: {
      type: String as PropType<E_QUERY_ITEM_CONDITION>,
      default: E_QUERY_ITEM_CONDITION.ANY,
    },
    showLogicTag: {
      type: Boolean,
      default: false,
    },
    blurClass: {
      type: String,
      default: '',
    },
    dataType: {
      type: String as PropType<ISearchDataType>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const localeData = useLocale()
    const acBaseRef = ref()

    const handleKeyDown = (e: KeyboardEvent, keyword: string) => {
      emit('keyDown', e, keyword)
    }

    const handleKeyPress = (e: KeyboardEvent, keyword: string) => {
      emit('keyPress', e, keyword)
    }

    const handleClose = (val: IBAcTag) => {
      emit('close', val)
    }

    const userExampleName = computed(() => (props.exampleTag ? props.exampleTag[`name_${localeData.locale.value}`] : ''))

    const renderExample = () => {
      return (
        <template slot="example">
          {props.placeHolder ? <div class={[$classes.example, props.exampleClass]}>{props.placeHolder}</div> : null}
          {props.exampleTag ? (
            <div class={[$classes.example, props.exampleClass]}>
              <div>{props.exampleLabel ?? ins?.proxy.$t('common.keywordSearchPlaceHolder')}</div>
              <BAcTag
                name={userExampleName.value}
                ownerClass={props.exampleIsTagStyle ? $classes.exampleTag : $classes.exampleText}
                canClose={false}
                size={props.tagSize}
              ></BAcTag>
            </div>
          ) : null}
        </template>
      )
    }

    const clickOutside = (keyword: string) => {
      emit('clickOutside', keyword)
    }

    const handleAddExample = () => {
      emit('addExample')
    }

    const handleChangeCheckbox = (val: boolean) => {
      emit('changeCheckbox', val)
    }

    const handleIncludeTrans = (includeTrans: boolean) => {
      emit('includeTrans', includeTrans)
    }

    const handleSelect = (val: IListItem) => {
      emit('select', props.type, val)
    }

    function revertTag(val: IListItem) {
      emit('revertTag', val)
    }

    function handleChangeLogicVal(val: E_QUERY_ITEM_CONDITION) {
      emit('changeLogicVal', val)
    }

    function handleClickTag(val: IBAcTag) {
      emit('clickTag', val)
    }

    return {
      acBaseRef,
      renderExample,
      handleAddExample,
      handleKeyDown,
      handleKeyPress,
      handleClose,
      clickOutside,
      handleChangeCheckbox,
      handleIncludeTrans,
      handleSelect,
      userExampleName,
      revertTag,
      handleChangeLogicVal,
      handleClickTag,
    }
  },
  methods: {
    clearKeywords() {
      return this.acBaseRef.clearKeywords()
    },
    renderIncludeInactive() {
      return (
        <GMiniSwitch
          active-text={this.$t('AutoEntity.includeInactive')}
          class={$classes.miniSwitch}
          value={this.innerCheckboxVal}
          onChange={(val: boolean) => this.handleChangeCheckbox(val)}
        ></GMiniSwitch>
      )
    },
  },
  render() {
    return (
      <BLabelAc
        tags={this.tags}
        type={this.type}
        leftNum={this.tagsLimit}
        label={this.label}
        infoContent={this.infoContent}
        showSwitch={this.showSwitch}
        switchVal={this.switchVal}
        switchInfo={this.switchInfo}
        labelContainerClass={this.labelContainerClass}
        onIncludeTrans={this.handleIncludeTrans}
      >
        <template slot="ac">
          <BAcBase
            ref="acBaseRef"
            type={this.type}
            dataType={this.dataType}
            logicVal={this.logicVal}
            showLogicTag={this.showLogicTag}
            clickOutClear={this.clickOutClear}
            inputBlurClass={this.blurClass ? `${$classes.inputBlur} ${this.blurClass}` : $classes.inputBlur}
            popperClass={this.popperClass}
            tags={this.tags}
            tagSize={this.tagSize}
            showLimitNum={this.showLimitNum}
            tagsLimit={this.tagsLimit}
            inputTip={this.inputTip}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            onClose={this.handleClose}
            onClickTag={this.handleClickTag}
            onClickOutside={this.clickOutside}
            onSelectItem={this.handleSelect}
            onRevertTag={this.revertTag}
            onChangeLogicVal={this.handleChangeLogicVal}
            scopedSlots={{
              autocompleteContent: this.$scopedSlots.autocompleteContent,
            }}
          >
            {this.renderExample()}
            {this.quickAddExample ? (
              <template slot="customInputTip">
                <span>{this.inputTip ?? this.$t('common.allFieldInputTip')}</span>
                <span>{this.$t('common.tryExample')}</span>
                <span class={$classes.exampleName} onClick={this.handleAddExample}>
                  {this.userExampleName}
                </span>
              </template>
            ) : null}
            {this.showInnerCheckbox ? <template slot="innerCheckbox">{this.renderIncludeInactive()}</template> : null}
            {this.$slots.suffix && <template slot="suffix">{this.$slots.suffix}</template>}
          </BAcBase>
        </template>
        {this.$slots.customLabelSuffix && (
          <template slot="customLabelSuffix">
            <div class="ml-1">{this.$slots.customLabelSuffix}</div>
          </template>
        )}
      </BLabelAc>
    )
  },
})
