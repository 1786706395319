import { IApiService } from '../service/apis/types'
import { IWebTrackingEvent } from '../types'
export interface ICommonListParams {
  queryId: string
  fromQueryId?: string
  queryType?: 'alert'
  tracking?: IWebTrackingEvent[]
}
interface IRouterGenerator {
  generatorDrugPath: (id: string) => string
  generatorTargetPath: (id: string) => string
  generatorOrgPath: (id: string) => string
  generatorDiseasePath: (id: string) => string
  generatorDrugTypePath: (id: string) => string
  generatorClinicalPath: (id: string) => string
  generatorClinicalResultDetailPath: (id: string, activeMenu?: string) => string
  generatorDrugListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorDrugListPathV2: (params: ICommonListParams) => string
  generatorDrugListPathGroupByOrg: (queryId: string, fromQueryId: string) => string
  generatorDrugListPathGroupByOrgV2: (params: ICommonListParams) => string
  generatorDrugListPathGroupByIndication: (queryId: string, fromQueryId: string) => string
  generatorDrugListPathGroupByIndicationV2: (params: ICommonListParams) => string
  generatorDrugListPathGroupByTarget: (queryId: string, fromQueryId: string) => string
  generatorDrugListPathGroupByTargetV2: (params: ICommonListParams) => string
  generatorDrugListPathGroupByDrugType: (queryId: string, fromQueryId: string) => string
  generatorDrugListPathGroupByDrugTypeV2: (params: ICommonListParams) => string
  generatorDrugListPathGroupByDevStatus: (queryId: string, fromQueryId: string, tracking?: IWebTrackingEvent[]) => string
  generatorDrugListPathGroupByDevStatusV2: (params: ICommonListParams) => string
  generatorDrugDealListPathGroupByDrug: (queryId: string, fromQueryId: string, tracking?: IWebTrackingEvent[]) => string
  generatorClinicalListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorClinicalListPathV2: (params: ICommonListParams) => string
  generatorClinicalResultListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorClinicalResultListPathV2: (params: ICommonListParams) => string
  generatorDrugDealListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorDrugDealListPathV2: (params: ICommonListParams) => string
  generatorPatentListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorPatentListPathV2: (params: ICommonListParams) => string
  generatorPatentDetailPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorNewsListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorNewsDetailPath: (id: string, offset?: number, sourceType?: 'search_result' | 'related_news', source?: string, sort?: string) => string
  generatorPaperListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorTranslationalMedicineListPath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorTranslationalMedicineListPathV2: (params: ICommonListParams) => string
  generatorPaperDetailPath: (id: string) => string
  generateUserSetting: () => string
  generatorCompareDrugPath: (ids?: string) => string
  generatorCompareOrganizationPath: (ids?: string) => string
  generatorCompareTargetPath: (ids?: string) => string
  generatorCompareIndicationPath: (ids?: string) => string
  generatorReportDetailPath: (id: string) => string
  generatorReportPreviewPath: (id: string) => string
  generatorKgRelationshipPath: (id: string) => string
  generatorHomePath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorQuickHomePath: (id: string, tracking?: IWebTrackingEvent[]) => string
  generatorEmailAlertDetailPath: (id: string) => string
  generatorSearchHistoryPath: (type: string) => string
}
interface IContext {
  service: IApiService
  router: IRouterGenerator
  /**
   * 部署区域
   */
  deployRegion: 'cn' | 'us'
  VUE_APP_ANALYTICS_URL: string
}
class SharedContext {
  private _context!: IContext
  private static instance: SharedContext

  get service() {
    return this._context.service
  }

  get router() {
    return this._context.router
  }
  get deployRegion() {
    return this._context.deployRegion
  }
  get VUE_APP_ANALYTICS_URL() {
    return this._context.VUE_APP_ANALYTICS_URL
  }

  /**
   * setContext
   */
  public setContext(context: IContext, init?: () => void) {
    if (init) {
      init()
    }
    this._context = context
  }
  public static getInstance(): SharedContext {
    if (!SharedContext.instance) {
      SharedContext.instance = new SharedContext()
    }

    return SharedContext.instance
  }
}
export const sharedCtx = SharedContext.getInstance()
