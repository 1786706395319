import '@patsnap-ui/icon/assets/solid/SearchLeft.svg'
import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { ElInput } from '@pharmsnap/shared/element-ui'
import { defineComponent, unref } from '@vue/composition-api'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { getRealSimpleText } from '../utils'
import $classes from './BFilterSimpleSearchInput.module.scss'

export const BFilterSimpleSearchInput = defineComponent({
  name: 'BFilterSimpleSearchInput',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  setup() {
    return useInjectFilterService()
  },
  methods: {
    search() {
      const service = this.simpleSearchService
      const simpleSearchData = unref(service.data)
      this.filterService.submit(simpleSearchData.condition === 'AND' ? 'refine' : 'exclude', {
        needOriginQueryItems: true,
        needQueryText: true,
        needSelectedValues: false,
      })
    },
    handleInput(keywords: string) {
      const service = this.simpleSearchService
      service.updateKeywords(keywords)
    },
    handleChangeLogic() {
      const service = this.simpleSearchService
      const simpleSearchData = unref(service.data)
      const activeLogic = simpleSearchData.condition
      service.updateCondition(activeLogic === 'AND' ? 'NOT' : 'AND')
    },
    handleKeypress(e: KeyboardEvent) {
      e.stopPropagation()
      const service = this.simpleSearchService
      const simpleSearchConfig = unref(service.config)
      const { key: keyboardKey } = e
      if (keyboardKey === 'Enter' && simpleSearchConfig.searchOnEnter) {
        this.search()
      }
    },
  },
  render() {
    const locale = unref(this.locale)
    const service = this.simpleSearchService
    const simpleSearchData = unref(service.data)
    const simpleSearchConfig = unref(service.config)
    const simpleSearchPlaceholder = getRealSimpleText(simpleSearchConfig.placeholder, locale)
    const showLogicToggle = simpleSearchConfig.showLogicToggle ?? false
    const activeLogic = simpleSearchData.condition
    const logicText = activeLogic

    return (
      <ElInput
        class={$classes.simpleSearch}
        value={simpleSearchData.keyword}
        onInput={this.handleInput}
        placeholder={simpleSearchPlaceholder}
        size="small"
        nativeOnKeypress={this.handleKeypress}
      >
        <template slot="prefix">
          {showLogicToggle ? (
            <span class="h-full inline-flex items-center justify-center">
              <span
                onClick={this.handleChangeLogic}
                class={[
                  'text-xs h-6 leading-6 w-[42px] rounded bg-gray-30',
                  'cursor-pointer select-none',
                  activeLogic === 'NOT' ? 'text-red-default' : 'text-blue-default',
                ]}
              >
                {logicText}
              </span>
            </span>
          ) : null}
        </template>
        <template slot="suffix">
          <span class="h-full inline-flex items-center justify-center">
            <GIcon
              nativeOn={{
                click: () => this.search(),
              }}
              class="cursor-pointer hover:bg-gray-30 rounded mb-0"
              size={24}
              svgName="SolidSearchLeft"
              useSvgDefaultColor
            ></GIcon>
          </span>
        </template>
      </ElInput>
    )
  },
})
