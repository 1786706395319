import { IStudyCodeAutoCompleteItem } from '@patsnap/synapse_common_interface'
import { IAutoCompleteParams } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class StudyCodeApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IStudyCodeAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    return this.http({
      method: 'GET',
      url: this.getUrl('pharmsnap_webapi/1.0/study_identifier/autocomplete'),
      params: { prefix: keywords, limit },
      cache: true,
      cancelToken,
    })
  }
}
