import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { computed, getCurrentInstance } from '@vue/composition-api'
import { FreemiumCompleteUserinfo } from '../features/freemium-complete-userinfo'
import { E_UPGRADE_TYPE } from '../types/enum'

export function useLS360Rights() {
  const {
    state: { synapseChatRole },
    getters: { isProfileComplete, synapseChatReachLimit, synapseChatIsFree, synapseChatIsBasic },
    actions: { changeUpgrade },
  } = useAuthStore()

  const ins = getCurrentInstance()

  const hasSynapseChatRights = computed(() => isProfileComplete.value && !synapseChatReachLimit.value)

  /** 未授权synapse chat 或者免费用户，信息不完整时，需要完善信息*/
  const chatNeedCompleteInfo = computed(() => (!synapseChatRole.value || synapseChatIsFree.value) && !isProfileComplete.value)

  function checkHasChatRights() {
    if (chatNeedCompleteInfo.value) {
      FreemiumCompleteUserinfo.getSingleton().gotoCompleteUserInfo()
      return false
    }

    if (synapseChatIsBasic.value && synapseChatReachLimit.value) {
      showSingleToast({
        message: ins?.proxy.$t('ls360.basicRoleReachLimit') as string,
        type: 'warning',
      })
      return false
    }

    if (synapseChatReachLimit.value) {
      changeUpgrade({
        show: true,
        type: E_UPGRADE_TYPE.CHAT_EXCEEDS_MAX_LIMIT,
        trigger_point: 'CHAT_EXCEEDS_MAX_LIMIT',
      })
      return false
    }

    return true
  }

  return {
    chatNeedCompleteInfo,
    checkHasChatRights,
    hasSynapseChatRights,
  }
}
