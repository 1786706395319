import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import type { IAggregationData, IAggregationValue } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BasicBarChart, BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { I18nLang, i18n } from '@pharmsnap/shared/i18n'
import { IUseBarCount } from '@pharmsnap/shared/types'
import { isInactivePhase } from '@pharmsnap/shared/utils'
import { Ref, computed } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { useChart } from './useChart'

export function transformPhase2BarItem(locale: I18nLang, aggData?: IAggregationData): IUseBarCount[] {
  if (!aggData) return []
  if (aggData && !aggData.aggregation_result) return []
  if (aggData && aggData.aggregation_result.length === 0) return []
  const serviceData = aggData.aggregation_result[0].items
  const activePhase: IAggregationValue[] = []
  const inActivePhase: IAggregationValue[] = []
  serviceData.forEach((o) => {
    if (isInactivePhase(o.key)) {
      inActivePhase.push(o)
    } else {
      activePhase.push(o)
    }
  })

  const activeData: Array<IUseBarCount & { weight: number }> = activePhase.map((item) => {
    return {
      name: locale === 'cn' ? item.display_name_cn || item.display_name_en : item.display_name_en,
      count: item.count,
      aggregation_field: aggData.aggregation_result[0].aggregation_field,
      data: [item],
      weight: ALL_DEV_STATUS_MAP[item.key]?.rank ?? 0,
    }
  })

  const inActiveUnion: IUseBarCount & { weight: number } = {
    name: locale === 'cn' ? '非在研' : 'Inactive',
    count: inActivePhase.reduce((val, cur) => val + cur.count, 0),
    aggregation_field: aggData.aggregation_result[0].aggregation_field,
    data: inActivePhase,
    weight: 0,
  }
  const chartData: Array<IUseBarCount & { weight: number }> = [...activeData]
  if (inActiveUnion.count) {
    chartData.push(inActiveUnion)
  }

  chartData.sort((a, b) => a.weight - b.weight)

  return chartData
}

export function transformAggData2BarItem(locale: I18nLang, aggData?: IAggregationData, reverse?: boolean): IUseBarCount[] {
  if (!aggData) return []
  if (aggData && !aggData.aggregation_result) return []
  if (aggData && aggData.aggregation_result.length === 0) return []
  const serviceData = aggData.aggregation_result[0].items

  const chartData: Array<IUseBarCount> = serviceData.map((item) => {
    return {
      // 简称逻辑在api层处理掉了
      name: locale === 'cn' ? item.display_name_cn || item.display_name_en : item.display_name_en,
      count: item.count,
      aggregation_field: aggData.aggregation_result[0].aggregation_field,
      data: [item],
    }
  })

  if (reverse) {
    chartData.reverse()
  }

  return chartData
}

export function useBasicBarCountChart(
  data: Ref<IUseBarCount[]>,
  config: {
    clickAble: boolean
    layout?: 'horizontal' | 'vertical' | (() => 'horizontal' | 'vertical')
    labelInSide?: boolean
    labelWidth?: number | (() => number)
    xAxisName?: string | (() => string)
    yAxisName?: string | (() => string)
    labelRotate?: number
    color?: string
    registerEvent?: (instance: EChartsType) => void
  }
) {
  const isEmpty = computed(() => data.value.length === 0)
  const layout = () => (typeof config.layout === 'function' ? config.layout() : config.layout || 'vertical')
  const getLabelWidth = () => (typeof config.labelWidth === 'function' ? config.labelWidth() : layout() === 'horizontal' ? 120 : 82)
  const chartOption = computed<BasicBarChart | BasicHorizontalBarChart>(() => {
    return getChartOption()
  })

  // const chartHeight = computed(() => {
  //   const singleHeight = 30
  //   const gap = 10
  //   const height = data.value.length * (singleHeight + 2 * gap)
  //   return Math.max(height, 400)
  // })

  const { initChart, chartContainer, render, options, getEchartsInstance, width } = useChart(chartOption, {
    autoResize: true,
    registerEvent: config.registerEvent,
  })
  const computedRotate = computed(() => {
    if (!width || !data || !width.value || !data.value.length) return 0
    return width.value < 936 && data.value.length > 11 ? -45 : 0
  })

  function getChartOption() {
    const isHor = layout() === 'horizontal'
    const labelRotate = typeof config.labelRotate === 'undefined' ? 0 : config.labelRotate
    const maxVal = Math.floor(Math.max(...data.value.map((i) => i.count)) * 1.1)
    const xaxisName = config.xAxisName ? (typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName) : ''
    const basicBarOption: BasicBarChart = {
      type: 'bar',
      color: config.color ? [config.color] : undefined,
      grid: {
        containLabel: true,
        right: 10,
        left: 30,
        bottom: 24,
        top: 30,
      },
      xAxis: {
        name: computedRotate && computedRotate.value ? `\n\n\n${xaxisName}` : xaxisName,
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: labelRotate || (computedRotate && computedRotate.value),
        },
      },
      yAxis: {
        name: config.yAxisName ? (typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName) : '',
        dataType: { valueType: 'count' },
      },
      dataset: {
        source: data.value,
        dimensions: [{ name: 'name' }, { name: 'count' }],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          z: -1,
          shadowStyle: {
            color: '#F5F7FC',
          },
        },
      },
      series: [
        {
          name: i18n.t('chart.valueType.count') as string,
          encode: { x: 'name', y: 'count' },
          label: {
            show: true,
            position: 'top',
            formatter: (val) => {
              return toThousands((val.data as IUseBarCount).count)
            },
          },
          cursor: 'pointer',
        },
      ],
    }

    const basicHorBarChartOption: BasicHorizontalBarChart = {
      type: 'horizontal-bar',
      color: config.color ? [config.color] : undefined,
      grid: {},
      xAxis: {
        name: config.yAxisName ? (typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName) : '',
        max: maxVal,
        dataType: { valueType: 'count' },
      },
      yAxis: {
        name: config.xAxisName ? (typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName) : '',
        type: 'category',
        axisLabel: {
          interval: 0,
        },
      },
      dataset: {
        source: [...data.value].reverse(),
        dimensions: [{ name: 'name' }, { name: 'count' }],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          z: -1,
          shadowStyle: {
            color: '#F5F7FC',
          },
        },
      },
      series: [
        {
          name: i18n.t('chart.valueType.count') as string,
          encode: { x: 'count', y: 'name' },
          label: {
            show: true,
            position: 'right',
            formatter: (val) => {
              return toThousands((val.data as IUseBarCount).count)
            },
          },
          cursor: 'pointer',
        },
      ],
    }
    if (config.labelInSide) {
      basicHorBarChartOption.inside = true
      basicHorBarChartOption.labelPadding = [0, 0, 14, 0]
      basicHorBarChartOption.grid = {
        ...basicHorBarChartOption.grid,
        containLabel: true,
        left: 6,
        right: 30,
        bottom: config.yAxisName ? 42 : 24,
        top: config.xAxisName ? 42 : 16,
      }
    } else {
      if (Array.isArray(basicHorBarChartOption.yAxis)) {
        basicHorBarChartOption.yAxis = basicHorBarChartOption.yAxis.map((item) => ({
          ...item,
          axisLabel: {
            width: getLabelWidth(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            overflow: 'truncate',
          },
        }))
      } else {
        basicHorBarChartOption.yAxis.axisLabel = {
          // width: 120,
          width: getLabelWidth(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overflow: 'truncate',
        }
      }
      basicHorBarChartOption.grid = {
        ...basicHorBarChartOption.grid,
        containLabel: false,
        left: getLabelWidth() + 20,
        right: 30,
        bottom: config.xAxisName ? 42 : 24,
        top: config.yAxisName ? 42 : 16,
      }
    }

    return isHor ? basicHorBarChartOption : basicBarOption
  }

  return {
    chartContainer,
    initChart,
    isEmpty,
    // chartHeight,
    render,
    options,
    getEchartsInstance,
  }
}
