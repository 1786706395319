import { MicroLs360SynapseWujieEventsMap } from '@patsnap/ls360_sdk_shared'
import WujieVue from 'wujie-vue2'

export interface wujieEventBus<E = MicroLs360SynapseWujieEventsMap> {
  $on: <K extends keyof E>(event: K, fn: (...args: E[K][]) => void) => wujieEventBus<E>
  $onAll: (fn: (event: keyof E, ...args: any[]) => void) => wujieEventBus<E>
  $once: <K extends keyof E>(event: K, fn: (...args: E[K][]) => void) => void
  $off: <K extends keyof E>(event: K, fn: (...args: E[K][]) => void) => wujieEventBus<E>
  // eslint-disable-next-line @typescript-eslint/ban-types
  $offAll: (fn: Function) => wujieEventBus<E>
  $emit: <K extends keyof E>(event: K, ...args: E[K][]) => wujieEventBus<E>
  $clear: () => wujieEventBus<E>
}
export function useLs360SynapseWujieEvents() {
  const { bus } = WujieVue
  const typeBus = bus as wujieEventBus
  return { bus: typeBus }
}
