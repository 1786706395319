import { getSingle } from '@patsnap/synapse_common_utils'
import { Route } from 'vue-router'
class TrackingPage {
  private from?: Route
  private to?: Route
  public setPageInfo(to: Route, from?: Route) {
    console.log({ to, from })
    this.to = to
    this.from = from
  }
  public getCurrentPageName() {
    return this.to?.meta?.trackingRouteName || this.to?.name || ''
  }
  public getPrePageName() {
    return this.from?.meta?.trackingRouteName || this.from?.name || ''
  }

  public getCurrentPageUrl() {
    const path = this.to?.fullPath
    return removeQuery(path ? `${window.location.origin}${path}` : '')
  }
  public getPrePageUrl() {
    const path = this.from?.fullPath
    return removeQuery(path ? (path !== '/' ? `${window.location.origin}${path}` : '') : '')
  }
}
/**
 * 仅移除url中的字段为query的参数查询参数,因为从游客版或者ls360跳转过来的query参数太长,导致头部信息过大,服务器拒绝访问
 * @param url string
 @ example http://xx.com?query=xxx&a=1&b=2 => http://xx.com?a=1&b=2
 * @returns 
 */
function removeQuery(url: string) {
  try {
    const urlObj = new URL(url)
    urlObj.searchParams.delete('query')
    return urlObj.toString()
  } catch (error) {
    return url
  }
}

export const trackingPage = getSingle(() => new TrackingPage())
