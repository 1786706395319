import { defineComponent, PropType } from '@vue/composition-api'

export const BFilterLimitContainer = defineComponent({
  name: 'BFilterLimitContainer',
  props: {
    list: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    showTrigger: {
      type: Boolean,
      default: true,
    },
    triggerText: {
      type: String,
      required: true,
    },
  },
  render() {
    return (
      <div>
        <div>
          {this.list.map((item, index) => (
            <div>{this.$scopedSlots.default && this.$scopedSlots.default({ item, index })}</div>
          ))}
        </div>
        <div class={['mt-2 flex items-center justify-center', { hidden: !this.showTrigger }]}>
          <button onClick={() => this.$emit('change')} class="pt-ui-btn" type="button" data-type="plain" border data-size="small">
            {this.triggerText}
          </button>
        </div>
      </div>
    )
  },
})
