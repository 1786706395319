import type {
  IAlertConfig,
  IAlertData,
  IAlertDetail,
  IAlertHistoryList,
  IAlertList,
  ICreateUpdateAlertParams,
  ISearchAlertParams,
} from '@patsnap/synapse_common_interface'
import {
  ICheckEmailAlertExist,
  IDeleteEmailAlertParams,
  IDeleteEmailAlertResult,
  IEmailAlertRes,
  IEmailAlertResV2,
  IUpdateEmailAlertResult,
  IUserEmailAlertQuota,
} from '@pharmsnap/shared/types/emailAlert'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export interface ISearchEmailHistoryParams {
  alert_id: string
  offset?: number
  limit?: number
  from_search?: boolean
}

export class EmailAlertApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getEmailAlertList(params: ISearchAlertParams): Promise<IHttpWrap<IAlertList>> {
    const rt = (await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/search`),
      data: params,
    })) as IHttpWrap<IAlertList>
    return rt
  }

  async getEmailAlertHistory(data: ISearchEmailHistoryParams) {
    const rt = (await this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/history`),
      data,
    })) as IHttpWrap<IAlertHistoryList>
    return rt
  }

  async getEmailAlertHistoryDetail(history_id: string) {
    const rt = (await this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/snapshot/${history_id}`),
    })) as IHttpWrap<IAlertData>
    return rt
  }

  async addEmailAlert(params: ICreateUpdateAlertParams): Promise<
    IHttpWrap<{
      /** 创建单个的email alert 会返回这个字段 */
      alert_config?: IAlertDetail
      success_count?: number
      repeat_count?: number
    }>
  > {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert`),
      data: params,
    })
  }

  async updateEmailAlertV2(params: ICreateUpdateAlertParams): Promise<IHttpWrap<void>> {
    return this.http({
      method: 'PUT',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert`),
      data: params,
    })
  }

  async checkEmailAlertExist(params: ICheckEmailAlertExist): Promise<IHttpWrap<IEmailAlertRes>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/check`),
      data: params,
    })
  }

  async checkEmailAlertExistV2(params: Pick<IAlertConfig, 'alert_type' | 'query' | 'entity'>): Promise<IHttpWrap<IEmailAlertResV2>> {
    return this.http({
      method: 'POST',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/check`),
      data: params,
    })
  }

  async toggleEmailAlertActiveStatus(params: { alert_id: string; status: boolean }): Promise<IHttpWrap<IUpdateEmailAlertResult>> {
    return this.http({
      method: 'PUT',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/status`),
      data: params,
    })
  }

  async getSingleEmailAlertDetail(id: string): Promise<IHttpWrap<IAlertDetail>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/${id}`),
    })
  }

  async deleteSingleEmailAlert(id: string): Promise<IHttpWrap<IDeleteEmailAlertResult>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/${id}`),
    })
  }

  async deleteEmailAlert(params: IDeleteEmailAlertParams): Promise<IHttpWrap<IDeleteEmailAlertResult>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert`),
      data: params,
    })
  }

  async getUserQuota(): Promise<IHttpWrap<IUserEmailAlertQuota>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/quota`),
    })
  }

  async getAlertEventType<T>(id: string): Promise<IHttpWrap<T>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/event_type`),
      method: 'GET',
      params: { id },
    })
  }
}
