import { computed, inject, InjectionKey, provide, reactive, watch } from '@vue/composition-api'
import { cloneDeep, omit } from 'lodash'
import type {
  IFilterAction,
  IFilterGetter,
  IFilterItemConfig,
  IFilterSelectValue,
  IFilterService,
  IFilterState,
  IFilterValueArrItem,
  IFilterValueSnapshotMap,
} from './types'
import { deleteEmptyValueChildren } from './utils/utils'

export const filterStateProvideKey: InjectionKey<IFilterState> = Symbol('filterStateProvideKey')
export const filterGetterProvideKey: InjectionKey<IFilterGetter> = Symbol('filterGetterProvideKey')
export const filterActionProvideKey: InjectionKey<IFilterAction> = Symbol('filterActionProvideKey')
export function userProvideFilterService(): IFilterService {
  const state: IFilterState = reactive<IFilterState>({
    inited: false,
    hasChange: false,
    /**
     * 点击refine之后会执行的方法的列表
     */
    _refineFns: [],
    /**
     * 字段的发开状态
     */
    openingMap: {},
    /**
     * 整体的loading状态
     */
    loading: false,
    /**
     *  局部的loading状态
     */
    loadingMap: {},
    /**
     * filter 字段的过滤项配置
     */
    configMap: {},
    /**
     * filter的聚合数据
     */
    filterDataMap: {},
    filterValueMapSnapshotMap: {},
    /**
     * 上次过滤条件的快照
     */
    filterValueArr: [],
    filterValueMapSnapSort: {},
    /**
     * 实时变化的过滤条件
     */
    filterValueMapInTime: {},
    /**
     * 是否按照root id聚合的map
     */
    aggregationByParentFieldMap: {},
    manualChangeAggregationByParentField: {},
    isShowAdvanceDialog: false,
    advanceFilterLoading: false,
    advanceSelectField: '',
    advanceFilterValueMapInTime: {},
    advanceFilterDataMap: {},
    advanceTreeDataMap: {},
    freeUpgradeShow: false,
  })
  const getters: IFilterGetter = {
    valueMap: computed(() => {
      return state.filterValueArr.reduce((acc, curr) => {
        if (curr.value.length) {
          return {
            ...acc,
            [curr.field]: [...(acc[curr.field] || []), ...curr.value],
          }
        }
        return acc
      }, {} as IFilterValueSnapshotMap)
    }),
    totalCount: computed(() => {
      return Object.entries(state.filterValueMapInTime).reduce((acc, curr) => {
        const [field, filterValues] = curr
        return acc + (filterValues || []).length
      }, 0)
    }),
  }
  watch(
    () => [state.filterValueMapInTime, state.filterValueMapSnapSort],
    () => {
      state.hasChange = JSON.stringify(state.filterValueMapInTime) !== JSON.stringify(state.filterValueMapSnapSort)
    }
  )
  const actions: IFilterAction = {
    getFilterValueByField(field) {
      return state.filterValueMapInTime[field] || []
    },
    setAdvanceSelectField(field) {
      state.advanceSelectField = field
    },
    takeFilterValueMapSnapshotMap(field, value) {
      state.filterValueMapSnapshotMap = {
        ...state.filterValueMapSnapshotMap,
        [field]: cloneDeep(value),
      }
    },
    setAllOpeningMap(data) {
      state.openingMap = data
    },
    setConfigMap(map: Record<string, IFilterItemConfig<string>>) {
      state.configMap = map
    },
    setALLAggregationByParentMap(data) {
      state.aggregationByParentFieldMap = data
    },
    setAggregationByParentMap(field, value) {
      state.manualChangeAggregationByParentField = {
        ...state.manualChangeAggregationByParentField,
        [field]: value,
      }
      state.aggregationByParentFieldMap = {
        ...state.aggregationByParentFieldMap,
        [field]: value,
      }
    },
    removeManualChangeAggregationByParentField() {
      state.manualChangeAggregationByParentField = {}
    },
    setFilterValueMapInTime(data: IFilterValueSnapshotMap) {
      state.filterValueMapInTime = data
      state.filterValueMapSnapSort = cloneDeep(data)
    },
    registerRefineFn(fn: (isExclude: boolean) => Promise<any>) {
      state._refineFns = [...state._refineFns, fn]
    },
    async refine(isExclude) {
      // 拿到所有已存在的filterArr，通过fields来过滤掉已经存在的数据，再去refine
      const allData = state.filterValueArr
      for (const i in state.filterValueMapInTime) {
        const values = allData
          .filter((item) => item.field === i)
          .map((o) => o.value)
          .flat()
        if (values.length) {
          state.filterValueMapInTime[i] = state.filterValueMapInTime[i]?.filter((o) => !values.find((k) => k.value === o.value))
        }
      }
      state.filterValueMapSnapSort = cloneDeep(state.filterValueMapInTime)
      if (state._refineFns.length) {
        const promises = state._refineFns.map((func) => func(isExclude))
        await Promise.all(promises)
      }
    },
    setFilterValue(field, value) {
      if (value.length) {
        state.filterValueMapInTime = {
          ...state.filterValueMapInTime,
          [field]: value,
        }
      } else {
        state.filterValueMapInTime = omit(state.filterValueMapInTime, field)
      }
    },
    setFilterData(field, value) {
      state.filterDataMap[field] = value
    },
    setFilterAllData(value) {
      state.filterDataMap = value
    },
    setAdvanceFilterAllData(value) {
      state.advanceFilterDataMap = value
    },
    setAdvanceTreeData(value) {
      state.advanceTreeDataMap = value
    },
    setIsShowAdvanceDialog(value) {
      state.isShowAdvanceDialog = value
    },
    setGlobalLoading(value) {
      state.loading = value
    },
    setLoadingMap(field, value) {
      state.loadingMap = {
        ...state.loadingMap,
        [field]: value,
      }
    },
    switchFold(field, value) {
      state.openingMap = {
        ...state.openingMap,
        [field]: value,
      }
    },
    setFilterInited(value) {
      state.inited = value
    },
    addFilterValueArr(value) {
      value.forEach((item) => {
        const fieldConfig = state.configMap[item.field]
        const hasIn = state.filterValueArr.find((o) => o.field === item.field)
        if (fieldConfig?.noClear && hasIn) {
          return
        } else {
          state.filterValueArr.push(item)
        }
      })
    },

    removeAllFilterValueArrByFieldName(field) {
      state.filterValueArr = state.filterValueArr.filter((item) => item.field !== field)
    },
    removeFilterValueArrByFilterItem(filterItem: IFilterValueArrItem) {
      const index = state.filterValueArr.indexOf(filterItem)
      if (index > -1) {
        state.filterValueArr.splice(index, 1)
      }
    },
    removeAllFilterValue(isForce = false) {
      state.filterValueArr = isForce ? [] : state.filterValueArr.filter((o) => state.configMap[o.field]?.noClear)
    },
    removeFilterValueArrByFilterValueItem(filterValue: IFilterSelectValue, filterItem: IFilterValueArrItem) {
      // 由于一个组的的过滤项是打平展示的,当前删除的值所属的filterItem会作为属性传过来
      const _filterItem = filterValue.filterItem || filterItem
      _filterItem.value = _filterItem.value.filter((item) => item.value !== filterValue.value)
      state.filterValueArr = deleteEmptyValueChildren(state.filterValueArr)
    },
    setAdvanceFilterLoading(value: boolean) {
      state.advanceFilterLoading = value
    },
    setFreeUpgradeShow(val: boolean) {
      state.freeUpgradeShow = val
    },
    clearAll() {
      state.filterValueMapInTime = {}
      state.filterValueMapSnapSort = {}
    },
  }
  provide(filterStateProvideKey, state)
  provide(filterGetterProvideKey, getters)
  provide(filterActionProvideKey, actions)
  return { state, getters, actions }
}
export function userInjectFilterService(): Partial<IFilterService> {
  const state = inject(filterStateProvideKey)
  const getters = inject(filterGetterProvideKey)
  const actions = inject(filterActionProvideKey)
  return { state, getters, actions }
}
