import { BasicBarChart, BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { IS_CN_REGION, PHASE_APPROVED_COLOR, PHASE_CONTINUE_COLOR_LIST } from '@pharmsnap/shared/config'
import { ILang, IQueryDataEntityType, IRankingItem } from '@pharmsnap/shared/src/types'
import { IUseChartTwoDimTupleItem, IUsePhaseHorChartDataItem, IUsePhaseHorChartRankingDataItem } from '@pharmsnap/shared/types/component'
import { getEntityTypeByAggField, getPhaseColor, getSpecialLang, isInactivePhase, replaceSameNameByIncreaseCount } from '@pharmsnap/shared/utils'
import { Ref, computed, getCurrentInstance, h, onBeforeUnmount, reactive, ref, watch } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { isObject, isUndefined } from 'lodash'
import Vue from 'vue'
import { BStackBarTooltip } from '../components'
import { getDefaultPhaseHorBarChartData, useChart } from '../composition'
import { useChartEntityTooltip } from './useChartEntityTooltip'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePhaseStackBarRankingChart<T = any>(
  data: Ref<IUsePhaseHorChartRankingDataItem[]>,
  config: {
    theme?: 'default' | 'continue'
    clickAble?: boolean
    xAxisName?: string | (() => string)
    yAxisName?: string | (() => string)
    labelWidth?: number | (() => number)
    showLegend?: boolean
    legendPosition?: 'top' | 'right'
    inside?: boolean
    needMergeInActive?: boolean
    registerEvent?: (instance: EChartsType) => void
    updateDate: Ref<string>
    onClickStackPhaseBarChart: (params: any) => void
  }
) {
  let echartInstance: EChartsType
  let vm: any
  const axisDimPosition = ref({
    left: 0,
    top: 0,
  })
  const axisDimStyle = ref({})
  const axisEntity = ref<{ id: string; type: IQueryDataEntityType }>({
    id: '',
    type: 'mechanism',
  })
  const tooltipProps = reactive({
    axisName: '',
    items: [] as { name: string; color: string; count: number }[],
  })
  const ins = getCurrentInstance()
  const theme = config.theme ?? 'default'
  const legendPosition = config.legendPosition ?? 'right'
  const clickAble = config.clickAble ?? false
  const needMergeInactive = config.needMergeInActive ?? true
  const phases: (keyof IUsePhaseHorChartRankingDataItem)[] = [
    'Unknown',
    'Pending',
    'Discontinued',
    'Suspended',
    'Withdrawn',
    'Inactive',
    'Not Applicable',
    'Discovery',
    'Preclinical',
    'IND Application',
    'Clinical',
    'IND Approval',
    'Early Phase 1',
    'Phase 1',
    'Phase 1/2',
    'Phase 2',
    'Phase 2/3',
    'Phase 3',
    'NDA/BLA',
    'Approved',
    'Phase 4',
  ]
  const isEmpty = computed(() => data.value.length === 0)
  const yDimIsEntity = computed(() => {
    const isEntity = data.value.some((i) => i.data && i.data[0] && i.data[0][0] && i.data[0][0].field && getEntityTypeByAggField(i.data[0][0].field))
    return isEntity
  })
  const displayPhase = computed(() => {
    if (!needMergeInactive) return phases.filter((i) => !data.value.every((item) => item[i] === null))
    let list = phases.filter((i) => !data.value.every((item) => item[i] === null) && i !== 'Inactive')
    if (list.some((i: string) => isInactivePhase(i))) {
      list = list.filter((i) => !isInactivePhase(i))
      list.unshift('Inactive')
    }
    return list
  })
  const sortedYCategories = computed(() => {
    return data.value
      .map((data) => {
        return {
          value: data.category,
          textStyle: {
            // 没有rich会有样式问题
            rich: {},
          },
        }
      })
      .reverse()
  })
  const sortedYCategories2 = computed(() => {
    return data.value
      .map((data, index) => {
        const ranking = index + 1
        let res = ''
        if (ranking <= 3) res = `{indexHighlight|${ranking}}`
        else res = `{indexDefault|${ranking}}`
        const rankChange = data.rankChange
        if (rankChange === 'new') res += '{new|New}'
        else {
          const rankChangeNum = parseInt(rankChange) || 0
          if (rankChangeNum > 0) res += `{up|↑ ${rankChangeNum}}`
          else if (rankChangeNum < 0) res += `{down|↓ ${-rankChangeNum}}`
          // 占位保持对齐
          else res += '{flat|↑ 0}'
        }

        return {
          value: res,
          textStyle: {
            rich: {
              indexHighlight: {
                color: '#D11A32',
                fontWeight: 'bold',
                padding: [0, 8, 0, 0],
              },
              indexDefault: {
                color: '#020A1A',
                fontWeight: 'bold',
                padding: [0, 8, 0, 0],
              },
              up: {
                backgroundColor: IS_CN_REGION ? '#FCEDEF' : '#EDF4FC',
                color: IS_CN_REGION ? '#D11A32' : '#1976D2',
                padding: [4, 6],
                borderRadius: 2,
                fontWeight: 'bold',
              },
              down: {
                backgroundColor: IS_CN_REGION ? '#EDF4FC' : '#FCEDEF',
                color: IS_CN_REGION ? '#1976D2' : '#D11A32',
                padding: [4, 6],
                borderRadius: 2,
                fontWeight: 'bold',
              },
              flat: {
                // 不知为什么padding要比其他两个小一点才能对齐
                padding: [4, 5],
                color: '#FFFFFF',
                borderRadius: 2,
                fontWeight: 'bold',
              },
              new: {
                backgroundColor: '#FDF5EC',
                color: '#DC7C08',
                padding: [4, 6],
                borderRadius: 2,
                fontWeight: 'bold',
              },
            },
          },
        }
      })
      .reverse()
  })
  const horChartOption = computed<BasicHorizontalBarChart | BasicBarChart>(() => {
    const legendVisible = typeof config.showLegend === 'undefined' ? true : config.showLegend
    const horOption: any = {
      type: 'horizontal-bar',
      tooltip: {
        // 实体卡片需要移入
        enterable: true,
        appendToBody: true,
        padding: 0,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          z: -1,
          shadowStyle: {
            color: '#F5F7FC',
          },
        },
        formatter: tooltipFormatter,
        hideDelay: 500,
      },
      showBarBorder: theme === 'continue',
      legend: {
        show: legendVisible,
        type: 'scroll',
        itemHeight: 14,
        itemWidth: 14,
        top: 'middle',
      },
      grid: {
        left: 100,
        right: 130,
      },
      xAxis: {
        name: config.xAxisName ? (typeof config.xAxisName === 'function' ? config.xAxisName() : config.xAxisName) : '',
        dataType: { valueType: 'count' },
        position: 'top',
      },
      yAxis: [
        {
          triggerEvent: yDimIsEntity.value,
          name: config.yAxisName ? (typeof config.yAxisName === 'function' ? config.yAxisName() : config.yAxisName) : '',
          type: 'category',
          data: sortedYCategories.value,
          axisLabel: {
            inside: true,
            padding: [0, 0, 35, -8],
          },
        },
        {
          type: 'category',
          data: sortedYCategories2.value,
          position: 'left',
          axisLabel: {
            padding: [0, 0, 35, -8],
          },
        },
      ],
      dataset: {
        dimensions: [...phases.map((i) => ({ name: i })), { name: 'category' }],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        source: data.value.map((item) => {
          const keys = Object.keys(item)
          if (needMergeInactive && keys.some((i) => isInactivePhase(i))) {
            const val = (item.Unknown || 0) + (item.Pending || 0) + (item.Discontinued || 0) + (item.Suspended || 0) + (item.Withdrawn || 0)
            item.Inactive = val === 0 ? null : val
          }
          return item
        }),
      },
      ...(theme === 'continue' ? { color: PHASE_CONTINUE_COLOR_LIST } : undefined),
      series: displayPhase.value.map((phase) => {
        return {
          name: ins?.proxy.$tc(`clinical_trial.phase.${phase}`),
          color: theme === 'continue' ? (phase === 'Approved' ? PHASE_APPROVED_COLOR : undefined) : getPhaseColor(phase),
          cursor: clickAble ? 'pointer' : 'normal',
          encode: {
            x: phase,
            y: 'category',
          },
          stack: 'phase-hor-bar',
          barMaxWidth: '18',
        }
      }),
      graphic: {
        elements: [
          {
            type: 'text',
            right: 12,
            bottom: 12,
            style: {
              text: `${ins?.proxy.$t('competitive.updateDate')}：${config.updateDate.value}`,
              font: '14px',
            },
            z: 100,
            cursor: 'auto',
          },
          {
            type: 'text',
            left: 40,
            top: 40,
            style: {
              text: ins?.proxy.$t('competitive.ranking') as string,
              font: 'bolder 12px "Microsoft YaHei", sans-serif',
            },
            z: 100,
            cursor: 'auto',
          },
          {
            type: 'line',
            left: 20,
            shape: {
              x2: 90,
              y2: 60,
              x1: 165,
              y1: 60,
            },
            style: {
              fill: '#dfe1e6',
              stroke: '#dfe1e6',
              lineWidth: 1,
            },
            z: 100,
            cursor: 'auto',
          },
        ],
      },
    }

    if (legendVisible) {
      if (legendPosition === 'right') {
        horOption.legend = {
          ...horOption.legend,
          orient: 'vertical',
          right: 10,
          top: 'middle',
        }
      }
    }

    return horOption
  })
  watch(horChartOption, () => {
    axisDimStyle.value = {
      width: 0,
      height: 0,
    }
  })
  const chartHeight = computed(() => {
    const singleHeight = 50
    const gap = 8
    const height = data.value.length * (singleHeight + 2 * gap) + 140

    return Math.max(height, 350)
  })
  const { initChart, chartContainer, render, options, getEchartsInstance } = useChart(horChartOption, {
    autoResize: true,
    registerEvent: registerEvent,
  })
  useChartEntityTooltip(chartContainer, axisDimPosition, axisEntity, axisDimStyle, false)
  onBeforeUnmount(() => {
    if (vm) {
      vm.$destroy()
    }
    vm = null
  })

  watch(yDimIsEntity, (val) => {
    if (val) {
      echartInstance && registerEntityEvent(echartInstance)
    } else {
      echartInstance && unRegisterEntityEvent(echartInstance)
    }
  })

  function handleAxisMouseover(params: any) {
    if (!params.value) return
    if (!params.event) return
    const target = params.event.target
    if (!target) return
    const targetAxisRect = target.getBoundingRect().clone()
    target && targetAxisRect.applyTransform(target.transform)
    const value = params.value
    const found = data.value.find((i) => i.data && i.data.some((i) => i[0].display_name_en === value || i[0].display_name_cn === value))
    if (!found || !found.data) return
    const entityType = getEntityTypeByAggField(found.data[0][0].field)
    if (!entityType) return
    const style: any = {
      width: `${targetAxisRect.width}px`,
      height: `${targetAxisRect.height}px`,
    }

    axisDimPosition.value = {
      left: targetAxisRect.x,
      top: targetAxisRect.y,
    }

    axisDimStyle.value = style

    axisEntity.value = {
      id: found.data[0][0].id,
      type: entityType,
    }
  }

  function registerEntityEvent(instance: EChartsType) {
    instance.on('mouseover', 'yAxis', handleAxisMouseover)
  }

  function registerEvent(instance: EChartsType) {
    config && config.registerEvent && config.registerEvent(instance)
    echartInstance = instance
    if (yDimIsEntity.value) {
      registerEntityEvent(instance)
    }
  }

  function unRegisterEntityEvent(instance: EChartsType) {
    instance.off('mouseover', handleAxisMouseover)
  }

  function tooltipFormatter(params: any) {
    if (!Array.isArray(params)) return ''
    if (params.length === 0) return ''
    const axisName = params[0].axisValueLabel
    if (!axisName) return ''
    const items = params
      .map((i) => {
        const encode = i.encode
        const count = i.value[i.dimensionNames[encode.x[0]]]
        const name = i.seriesName
        const color = i.color

        return {
          color,
          count,
          name,
          originalData: i,
        }
      })
      .filter((i) => i.count !== null)
    tooltipProps.axisName = axisName
    tooltipProps.items = items
    if (!vm) {
      vm = new Vue({
        i18n: ins?.proxy.$i18n,
        render() {
          return h(BStackBarTooltip, {
            props: {
              categoryName: tooltipProps.axisName,
              items: tooltipProps.items,
              countClickable: true,
            },
            on: {
              click: (data: any) => {
                config.onClickStackPhaseBarChart(data)
              },
            },
          })
        },
      })
      vm.$mount()
    }
    return vm.$el
  }

  return {
    chartContainer,
    initChart,
    isEmpty,
    chartHeight,
    render,
    options,
    getEchartsInstance,
  }
}

export function transformRankingData2PhaseHorChartItems<T>(
  options: { locale: ILang; useYShortName?: boolean } | ILang,
  rankItems?: IRankingItem[]
): IUsePhaseHorChartRankingDataItem[] {
  if (!rankItems || !rankItems.length) return []
  const locale = isObject(options) ? options.locale : options
  const useYShortName = isObject(options) ? (isUndefined(options.useYShortName) ? false : options.useYShortName) : false
  const list = rankItems.map((item) => {
    const shortName = item.entity_items
      .map((i) => {
        if (i.short_name && i.short_name.length > 0) {
          return getSpecialLang({ data: i.short_name || [], field: 'name', lang: locale })
        } else {
          return locale === 'CN' ? i.display_name_cn || i.display_name_en : i.display_name_en
        }
      })
      .join('&')

    const displayNameEn = item.entity_items.map((i) => i.display_name_en).join('&')
    const displayNameCn = item.entity_items.map((i) => i.display_name_cn).join('&')
    const yEnName = useYShortName ? shortName || displayNameEn : displayNameEn
    const yCnName = useYShortName ? shortName || displayNameCn : displayNameCn || displayNameEn
    const chartDataItem: IUsePhaseHorChartRankingDataItem = {
      ...getDefaultPhaseHorBarChartData(),
      data: [],
      category: locale === 'CN' && !useYShortName ? displayNameCn || yEnName : yEnName,
      rankChange: item.rank_change,
    }

    if (!item.phase_items) return chartDataItem

    if (item.phase_items && item.phase_items.length === 0) return chartDataItem
    item.phase_items.forEach((i) => {
      // 这边可能display_name_en 为 undefined
      const { display_name_en, count } = i
      if (typeof count !== 'undefined' && count !== 0) {
        // 将没有 display_name_en （相当于没有key）
        if (typeof display_name_en === 'undefined') {
          // 不能放在 Inactive, 在useHeatmap中会被忽略掉，随便放个Inactive的阶段
          // chartDataItem.Inactive = (chartDataItem.Inactive || 0) + count
          chartDataItem.Pending = (chartDataItem.Pending || 0) + count
        } else {
          // 由于可能有空的key，占用了Pending这个键，所以这边在原有值的基础上加上count
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          chartDataItem[display_name_en as unknown as keyof IUsePhaseHorChartDataItem] = (chartDataItem[display_name_en] || 0) + count
        }
        const firstDim: IUseChartTwoDimTupleItem[0] = {
          display_name_cn: yCnName,
          display_name_en: yEnName,
          id: item.entity_id,
          field: item.aggregation_field,
        }

        const secondDim: IUseChartTwoDimTupleItem[1] = {
          display_name_cn: i.display_name_cn || '',
          display_name_en: i.display_name_en || '',
          id: i.phase || '',
          field: i.aggregation_field,
          otherInfo: i.other_info,
        }

        chartDataItem.data && chartDataItem.data.push([firstDim, secondDim])
      }
    })
    return chartDataItem
  })
  const sortedList = replaceSameNameByIncreaseCount(list, {
    getName: (i) => i.category,
    updateVal: (i, name) => (i.category = name),
  })

  return sortedList
}
