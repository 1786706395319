import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { IS_CN_REGION } from '@pharmsnap/shared/config'
import { sharedCtx } from '@pharmsnap/shared/context'
import { formatOrgName } from '../common'

export function formatKgTreeOrganizationName<T extends { name_en?: string; name_cn?: string; children?: T[] }>(data: T[]) {
  data.forEach((item) => {
    if ('is_china' in item || !IS_CN_REGION) {
      formatOrgName(item, 'name_cn', 'name_en')
      if (item.children) {
        formatKgTreeOrganizationName(item.children)
      }
    }
  })
}

export function handleNav2KGWithGetGraphIdFunc(getGraphId: () => Promise<string | undefined> | string) {
  openPageWithoutPending(async () => {
    const graphId = await getGraphId()
    return getKgUrl(graphId)
  })
}

export function getKgUrl(graphId?: string) {
  if (graphId) {
    return sharedCtx.router.generatorKgRelationshipPath(graphId)
  }
  return ''
}
