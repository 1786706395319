import { I18nLang, IBaseDrugInfo, IBaseTargetInfo } from '@patsnap/synapse_common_interface'
import { sharedCtx } from '@pharmsnap/shared/context'
import { difference, groupBy } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import {
  BDecodeUniCodeContainer,
  BDiseaseItem,
  BDrugItem,
  BOrganizationItem,
  BStatusTag,
  BTargetItem,
  GCountryFlag,
  GLimited,
  GLimitedContainer,
  GLink,
  GTranslate,
} from '../components'
import { EMPTY_PLACEHOLDER, TEXT_SPLIT_SYMBOL } from '../constants'
import { extractClinicalTitle } from '../domain/clinicalDomain/clinical.field'
import {
  IBaseDiseaseInfo,
  IBaseOrgInfo,
  IClinicalDetail,
  IClinicalTranslationField,
  IClinicalTrial,
  ICTCountry,
  ICTGOVClinicalDetail,
  ICTIdentifer,
  ICTSponsor,
  ILang,
  isCtr,
  ITranslateLang,
  IWHOClinicalDetail,
} from '../types'
import { alphabetSort, encryptNum, formatURL, isOpenCTTranslate, mergeViewAndIds, removeHTML } from '../utils'
import { formatTimestamp } from '../utils/time'
import {
  COMMA,
  renderLimited,
  renderLimitedLinks,
  renderLimitedOuterLinks,
  renderPhase,
  renderPopoverLimited,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedDrugAsync,
  renderPopoverLimitedOrgAsync,
  renderPopoverLimitedTargets,
  renderPopoverLimitedTargetsAsync,
} from './baseRender'

function sortIdentifiers(items: ICTIdentifer[], selfId: string) {
  const selfIdentifier = items.find((i) => i.id_value === selfId)
  const identifiers = [...items].filter((i) => i.id_value !== selfId)
  const nctIdentifiers = identifiers
    .filter((i) => i.id_value && i.id_value.toLocaleUpperCase().startsWith('NCT'))
    .sort((a, b) => alphabetSort(a.id_value || '', b.id_value || ''))
  const notNctIdentifiers = identifiers
    .filter((i) => i.id_value && !i.id_value.toLocaleUpperCase().startsWith('NCT'))
    .sort((a, b) => alphabetSort(a.id_value || '', b.id_value || ''))

  const sortedIdentifiers = [...nctIdentifiers, ...notNctIdentifiers]

  if (selfIdentifier) {
    sortedIdentifiers.unshift(selfIdentifier)
  }
  return sortedIdentifiers
}

export function getClinicalTranslateText(data: IClinicalTrial, key: IClinicalTranslationField): string {
  const { clinical_trial_translation } = data
  return (clinical_trial_translation || {})[key] || ''
}

export function renderClinicalEncryptStr(date?: string) {
  return date ? <div class="icon-font" style={{ 'word-break': 'break-word' }} domPropsInnerHTML={encryptNum(date)}></div> : EMPTY_PLACEHOLDER
}

export function renderClinicalEnrollment(enrollment?: string, fontClassName?: string) {
  return enrollment ? (
    <BDecodeUniCodeContainer fontClassName={fontClassName} domPropsInnerHTML={enrollment}></BDecodeUniCodeContainer>
  ) : (
    EMPTY_PLACEHOLDER
  )
}

export function renderClinicalPlainText(text?: string | number, useHtml?: boolean) {
  return text ? (
    useHtml ? (
      <div title={text} domPropsInnerHTML={text} style={{ 'word-break': 'break-word', 'white-space': 'pre-line' }}></div>
    ) : (
      <div title={text} style={{ 'word-break': 'break-word' }}>
        {text}
      </div>
    )
  ) : (
    EMPTY_PLACEHOLDER
  )
}

export function renderClinicalNCTCodeInTable(data: IClinicalDetail) {
  const { register_number, url } = data
  return (
    <div>
      <div>
        {renderLimitedLinks(
          [
            {
              name: register_number,
              href: url,
            },
          ],
          1,
          'blue'
        )}
      </div>
      <div class="mt-1">{data.if_iit ? <div class="bg-gray-20 px-2 py-1 inline-block text-xs rounded">IIT</div> : null}</div>
    </div>
  )
}

export function renderClinicalTrialTitleInTable(data: IClinicalDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const translate = isOpenCTTranslate(data, customSettingTranslation)
  const translateText = getClinicalTranslateText(data, 'official_title') || getClinicalTranslateText(data, 'brief_title')
  const executeTranslate = translateText ? translate : false

  return (
    <GTranslate execute={executeTranslate} content={translateText || EMPTY_PLACEHOLDER}>
      <GLink
        href={sharedCtx.router.generatorClinicalPath(data.clinical_trial_id)}
        name={extractClinicalTitle(undefined, data) || EMPTY_PLACEHOLDER}
        newTab={true}
        defaultColor="blue"
        class="whitespace-pre-wrap text-sm leading-6"
      ></GLink>
    </GTranslate>
  )
}

export function renderClinicalTrialTitleInWorkspaceTable(data: IClinicalDetail) {
  return (
    <GLink
      href={sharedCtx.router.generatorClinicalPath(data.clinical_trial_id)}
      name={extractClinicalTitle(undefined, data) || EMPTY_PLACEHOLDER}
      newTab={true}
      defaultColor="blue"
      class="whitespace-pre-wrap text-sm leading-6 align-middle"
    ></GLink>
  )
}

export function renderClinicalStudyPhaseInTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPhase(
    {
      currentPhase: data.phase?.normalized_phase_view,
      fontClassName: data.fontClassName,
    },
    $i18n.locale.toUpperCase() as ILang
  )
}

export function renderClinicalTargetsInList(data: IClinicalDetail, limit = 1) {
  return renderPopoverLimitedTargetsAsync(data.main_experimentation_drug_target_id_view || [], data.main_experimentation_drug_target_id, limit)
}

export function renderClinicalTargetsInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.main_experimentation_drug_target_id_view, data.main_experimentation_drug_target_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BTargetItem data={item as IBaseTargetInfo} popover={false}></BTargetItem>,
  })
}

export function renderClinicalStudyStatusInList(data: IClinicalDetail) {
  const status = data?.recruitment_status?.normalized_status || data?.recruitment_status?.name
  const fontClassName = data?.recruitment_status?.fontClassName
  return status ? <BStatusTag status={status} fontClassName={fontClassName} /> : EMPTY_PLACEHOLDER
}

export function renderClinicalStudyTypeInList(data: IClinicalDetail) {
  return renderClinicalPlainText((data as ICTGOVClinicalDetail).study_type?.normalized_study_type)
}

export function renderClinicalDrugInList(data: IClinicalDetail, limit = 3) {
  return renderPopoverLimitedDrugAsync(data.main_experimentation_drug_id_view || [], data.main_experimentation_drug_id, limit)
}

export function renderClinicalControlDrugInList(data: IClinicalDetail, limit = 3) {
  return renderPopoverLimitedDrugAsync(data.control_drug_id_view || [], data.control_drug_id, limit)
}

export function renderClinicalDrugInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.main_experimentation_drug_id_view, data.main_experimentation_drug_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDrugItem data={item as IBaseDrugInfo} popover={false} isSignature={false}></BDrugItem>,
  })
}

export function renderClinicalControlDrugInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.control_drug_id_view, data.control_drug_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDrugItem data={item as IBaseDrugInfo} popover={false} isSignature={false}></BDrugItem>,
  })
}

export function renderClinicalTherapeuticAreaInTable(data: IClinicalDetail) {
  return renderPopoverLimitedDiseaseAsync(data.therapeutic_area_view || [], data.therapeutic_area || [], 3)
}

export function renderClinicalTherapeuticAreaInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.therapeutic_area_view, data.therapeutic_area), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDiseaseItem data={item as IBaseDiseaseInfo} popover={false}></BDiseaseItem>,
  })
}

export function renderClinicalCountryCount(data: IClinicalDetail, $i18n: IVueI18n) {
  if (!data.country_count) return $i18n.locale === 'cn' ? '未知' : 'Unknown'
  return renderClinicalPlainText(data.country_count)
}

export function renderClinicalSponsor(data: IClinicalDetail, limitCount = 3) {
  const sponsors = data.org_master_entity_id_view || []
  const sponsorIds = data.org_master_entity_id || []
  return renderPopoverLimitedOrgAsync(sponsors, sponsorIds, limitCount)
}

export function renderClinicalSponsorInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.org_master_entity_id_view, data.org_master_entity_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderOnlyClinicalSponsor(data: IClinicalDetail, limitCount = 3) {
  const sponsors = [...(data.sponsor_org_master_entity_id_view || [])]
  const sponsorIds = [...(data.sponsor_org_master_entity_id || [])]
  return renderPopoverLimitedOrgAsync(sponsors, sponsorIds, limitCount)
}

export function renderOnlyClinicalSponsorInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.sponsor_org_master_entity_id_view, data.sponsor_org_master_entity_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderOnlyClinicalCollaborator(data: IClinicalDetail, limitCount = 3) {
  const sponsors = [...(data.data_source === 'CTGOV' || data.data_source === 'WHO' ? data.collaborator_org_master_entity_id_view || [] : [])]
  const sponsorIds = [...(data.data_source === 'CTGOV' || data.data_source === 'WHO' ? data.collaborator_org_master_entity_id || [] : [])]
  return renderPopoverLimitedOrgAsync(sponsors, sponsorIds, limitCount)
}

export function renderOnlyClinicalCollaboratorInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const sponsors = [...(data.data_source === 'CTGOV' || data.data_source === 'WHO' ? data.collaborator_org_master_entity_id_view || [] : [])]
  const sponsorIds = [...(data.data_source === 'CTGOV' || data.data_source === 'WHO' ? data.collaborator_org_master_entity_id || [] : [])]
  return renderPopoverLimited(mergeViewAndIds(sponsors, sponsorIds), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderClinicalIdentifierInCard(data: IClinicalDetail, limitCount = 3) {
  if (data.data_source === 'CTR') {
    if (!data.register_number) return EMPTY_PLACEHOLDER
    else
      return (
        <a class="text-blue-default cursor-pointer hover:underline truncate" target="_blank" href={formatURL(data.url)}>
          {data.register_number}
        </a>
      )
  } else {
    if (!data.group_id_view) return EMPTY_PLACEHOLDER
    const viewLen = data.group_id_view.length
    const displayLen = viewLen < limitCount ? viewLen : limitCount
    return renderPopoverLimited(data.group_id_view, {
      limitCount,
      render: (item, index, isRenderInPopover) => {
        const className = data.group_id_highlight?.includes(item.group_id) ? 'bg-red-highlight' : ''
        return (
          <div class="text-sm text-blue-default truncate" style="line-height: 24px">
            <a href={item.url} class={className} target="_blank">
              {item.group_id}
            </a>
            {index !== (isRenderInPopover ? viewLen : displayLen) - 1 ? ',' : ''}
          </div>
        )
      },
      gap: { x: 4, y: 4 },
    })
  }
}

// sponsor字段返回了全部数据，不需要异步加载
export function renderClinicalSponsorInList(organization: ICTSponsor[], limitCount = 30) {
  return renderPopoverLimited(organization || [], {
    limitCount,
    render: (item: ICTSponsor) => (
      <BOrganizationItem
        data={
          item.normalized_id_view ||
          ({
            entity_id: item.normalized_id,
            name_en: item.name,
            name_cn: item.name,
          } as IBaseOrgInfo)
        }
      ></BOrganizationItem>
    ),
  })
}

export function renderClinicalIndicationInList(data: IClinicalDetail, limitCount = 3) {
  return renderPopoverLimitedDiseaseAsync(data.condition_disease_id_view || [], data.condition_disease_id, limitCount)
}
/**
 * 原始适应症
 * @param data
 * @returns
 */
export function renderClinicalOriginalIndicationInList(data: IClinicalDetail, $i18n: IVueI18n) {
  if (!data.f_condition?.length) return EMPTY_PLACEHOLDER
  const allConditions = data.f_condition.join('<br>')
  return (
    <GLimitedContainer lineHeight={22} limitLines={5} moreType="dialog" newTab={true} moreColor="#f0f1f3" moreText={$i18n.tc('common.showAll')}>
      <p domPropsInnerHTML={allConditions}></p>
    </GLimitedContainer>
  )
}

export function renderClinicalIndicationInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.condition_disease_id_view, data.condition_disease_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorClinicalPath(data.clinical_trial_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDiseaseItem data={item as IBaseDiseaseInfo} popover={false}></BDiseaseItem>,
  })
}

export function renderClinicalStartDate(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return renderClinicalEncryptStr(formatTimestamp(data.start_date_v2?.date, { locale }))
}

export function renderClinicalStartDateInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return formatTimestamp(data.start_date_v2?.date, { locale })
}

export function renderClinicalFirstPostedDate(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return renderClinicalEncryptStr(formatTimestamp(data.study_first_posted_date, { locale }))
}

export function renderClinicalFirstPostedDateInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return formatTimestamp(data.study_first_posted_date, { locale })
}

export function renderClinicalPrimaryCompletionDate(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return renderClinicalEncryptStr(formatTimestamp(data.primary_completion_date_v2?.date, { locale }))
}

export function renderClinicalPrimaryCompletionDateInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return formatTimestamp(data.primary_completion_date_v2?.date, { locale })
}

export function renderClinicalCompletionDate(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  if (isCtr(data)) {
    const domesticItem = data.completion_date_v2?.find((i) => i.location === 'Domestic')
    const internationalItem = data.completion_date_v2?.find((i) => i.location === 'International')
    if (!domesticItem?.completion_date && !internationalItem?.completion_date) {
      return EMPTY_PLACEHOLDER
    }
    return (
      <div>
        {domesticItem?.completion_date
          ? renderClinicalEncryptStr(`${formatTimestamp(domesticItem?.completion_date, { locale })} ${$i18n.locale === 'en' ? '(China)' : '(国内)'}`)
          : ''}
        {internationalItem?.completion_date
          ? renderClinicalEncryptStr(
              `${formatTimestamp(internationalItem?.completion_date, { locale })} ${$i18n.locale === 'en' ? '(Global)' : '(国际)'}`
            )
          : ''}
      </div>
    )
  }

  return renderClinicalEncryptStr(formatTimestamp(data.completion_date?.completion_date, { locale }))
}

export function renderClinicalCompletionDateInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  if (isCtr(data)) {
    const domesticItem = data.completion_date_v2?.find((i) => i.location === 'Domestic')
    const internationalItem = data.completion_date_v2?.find((i) => i.location === 'International')
    if (!domesticItem?.completion_date && !internationalItem?.completion_date) {
      return EMPTY_PLACEHOLDER
    }
    return (
      <div>
        {domesticItem?.completion_date
          ? `${formatTimestamp(domesticItem?.completion_date, { locale })} ${$i18n.locale === 'en' ? '(China)' : '(国内)'}`
          : ''}
        {internationalItem?.completion_date
          ? `${formatTimestamp(internationalItem?.completion_date, { locale })} ${$i18n.locale === 'en' ? '(Global)' : '(国际)'}`
          : ''}
      </div>
    )
  }

  return formatTimestamp(data.completion_date?.completion_date, { locale })
}

export function renderClinicalUpdateDate(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return renderClinicalEncryptStr(formatTimestamp(data.last_update_posted_date?.time_ts, { locale }))
}

export function renderClinicalUpdateDateInWorkspaceTable(data: IClinicalDetail, $i18n: IVueI18n) {
  const locale = $i18n.locale.toUpperCase() as ILang
  return formatTimestamp(data.last_update_posted_date?.time_ts, { locale })
}

export function renderClinicalRegister(data: IClinicalDetail) {
  if (!data.registry || data.registry.length === 0) {
    if (data.data_source === 'CTGOV') return 'ClinicalTrials.gov'
    if (data.data_source === 'CTR') return 'CTR'
    return EMPTY_PLACEHOLDER
  }
  return <div style="word-break: break-word;">{data.registry.join(' ; ')}</div>
}

export function renderClinicalOtherStudyId(data: IClinicalDetail) {
  let otherStudyId: string[] = []

  if (data.data_source === 'CTR') {
    otherStudyId = data.study_number ? [data.study_number] : []
  }
  if (data.data_source === 'CTGOV') {
    const {
      secondary_id = [],
      normalized_secondary_id = [],
      nih_funded_research_id_view = [],
      register_number,
      study_identifier = [],
      related_id = [],
    } = data
    const secondaryIds = secondary_id.reduce((acc, cur) => {
      acc.push(...cur.id_value)
      return acc
    }, [] as string[])
    otherStudyId = difference(secondaryIds, [
      ...normalized_secondary_id.map((item) => item.id_value),
      ...nih_funded_research_id_view.map((item) => item.project_id),
      register_number,
      ...study_identifier,
      ...related_id,
    ])
  }
  if (data.data_source === 'WHO') {
    const { group_normalized_secondary_ids = [], register_number, nih_funded_research_id_view = [], study_identifier = [], secondary_id = [] } = data
    // 相关试验
    const relatedTrial = group_normalized_secondary_ids.length ? group_normalized_secondary_ids.map((item) => item.id_value) : [register_number]
    const secondaryIds = secondary_id.reduce((acc, cur) => {
      acc.push(...cur.id_value)
      return acc
    }, [] as string[])
    otherStudyId = difference(secondaryIds, [...relatedTrial, ...nih_funded_research_id_view.map((item) => item.project_id), ...study_identifier])
  }

  return renderLimited(otherStudyId, {
    limitCount: 3,
    render: (item, index) => (
      <div class="leading-6 text-sm">
        {item}
        {index !== otherStudyId.length - 1 ? COMMA : ''}
      </div>
    ),
  })
}

export function renderClinicalIdentifier(data: IClinicalDetail) {
  if (data.data_source === 'CTGOV' || data.data_source === 'WHO') {
    const { group_normalized_secondary_ids, register_number, url } = data
    const sortedIdentifiers = sortIdentifiers(group_normalized_secondary_ids || [], register_number)
    if (sortedIdentifiers.length === 0) {
      return [
        <a class="text-blue-default cursor-pointer hover:underline" target="_blank" href={formatURL(url)}>
          {register_number}
        </a>,
      ]
    }
    return sortedIdentifiers.map((item, index, ary) => {
      if (item.id_value && item.url) {
        return (
          <a class="mr-1 text-blue-default cursor-pointer hover:underline" target="_blank" href={formatURL(item.url)}>
            {item.id_value}
            {index === ary.length - 1 ? '' : COMMA}
          </a>
        )
      }
      return (
        <span class="mr-1">
          {item.id_value}
          {index === ary.length - 1 ? '' : COMMA}
        </span>
      )
    })
  } else if (data.data_source === 'CTR') {
    const { url, register_number } = data
    return [
      <a class="text-blue-default cursor-pointer hover:underline" target="_blank" href={formatURL(url)}>
        {register_number}
      </a>,
    ]
  }

  return EMPTY_PLACEHOLDER
}

export function renderClinicalNih(data: IClinicalDetail, locale: I18nLang) {
  if (data.data_source === 'CTGOV' || data.data_source === 'WHO') {
    if (!data.nih_funded_research_id_view || !data.nih_funded_research_id_view.length) return '-'
    return renderLimitedOuterLinks(
      data.nih_funded_research_id_view.map((i) => ({
        name: i.project_id,
        href: i.source_url,
      })),
      3,
      'blue',
      locale
    )
  }
}

export function renderClinicalHoverPopoverLimitedDrug(data: IClinicalDetail, limit?: number) {
  return renderPopoverLimitedDrugAsync(data.main_experimentation_drug_id_view || [], data.main_experimentation_drug_id, limit || 30)
}

export function renderClinicalHoverPopoverLimitedControlDrug(data: IClinicalDetail, limit?: number) {
  return renderPopoverLimitedDrugAsync(data.control_drug_id_view || [], data.control_drug_id || [], limit || 3)
}

export function renderClinicalHoverPopoverLimitedDisease(data: IBaseDiseaseInfo[], ids: string[], limit?: number) {
  return renderPopoverLimitedDiseaseAsync(data, ids, limit || 3)
}

export function renderClinicalHoverPopoverLimitedTarget(data: IClinicalDetail, limit?: number) {
  return renderPopoverLimitedTargets(data.main_experimentation_drug_target_id_view || [], limit || 3)
}
export function renderClinicalStudyCode(data: IClinicalDetail) {
  return data.study_identifier?.join(COMMA) || EMPTY_PLACEHOLDER
}
export function renderClinicalCountries(data: IClinicalDetail) {
  if (!data.country?.length) {
    return EMPTY_PLACEHOLDER
  }
  return (
    <GLimited
      items={data.country}
      limit={3}
      mode="popover"
      appendToBody
      scopedSlots={{
        default: ({ item, index }: { item: ICTCountry; index: number }) =>
          item.country_code ? (
            <GCountryFlag showName name={item.country_code} class="mr-2"></GCountryFlag>
          ) : (
            <span class="inline-block max-w-full mr-2 bg-gray-30 rounded h-6  leading-6 px-1 text-black-default text-sm truncate">{item.name}</span>
          ),
      }}
    ></GLimited>
  )
}

export function renderClinicalInterventions(data: ICTGOVClinicalDetail) {
  if (!data.intervention || data.intervention.length === 0) return EMPTY_PLACEHOLDER
  const grouped = groupBy(data.intervention, (i) => i.type)
  const types = Object.keys(grouped)
  if (types.length === 0) return EMPTY_PLACEHOLDER

  return types.map((type) => {
    const label = type === 'undefined' ? '' : `${type}: `
    const content = grouped[type].map((i) => i.name).join(TEXT_SPLIT_SYMBOL)
    const html = `${label}${content}`
    return <div class="text-sm text-black-default break-words">{removeHTML(html)}</div>
  })
}

export function renderWHOClinicalInterventions(data: IWHOClinicalDetail) {
  if (!data.intervention || data.intervention.length === 0) return EMPTY_PLACEHOLDER
  return <div class="text-sm text-black-default break-words">{data.intervention.map((o) => o.name).join(',')}</div>
}

export function renderFacilityInTable(data: IClinicalDetail, $i18n: IVueI18n) {
  if (!data.facility_count || data.facility_count === 0) return $i18n.locale === 'cn' ? '未知' : 'Unknown'
  else if (data.facility_count === 1) return $i18n.locale === 'cn' ? '单中心' : 'Single Center'
  else return $i18n.locale === 'cn' ? '多中心' : 'Multi Center'
}

/**if_ct_result
0 → 无， 1 → 有
 */
export function renderIfCtInTable(data: IClinicalDetail, $i18n: IVueI18n) {
  if (!data.if_ct_result || data.if_ct_result === 0) return $i18n.locale === 'cn' ? '无' : 'No'
  else if (data.if_ct_result === 1) return $i18n.locale === 'cn' ? '有' : 'Yes'
  else return EMPTY_PLACEHOLDER
}

/**single_or_multi
取值为：单药（single_drug）或联合（multiple_drugs）
*/
export function renderSingleOrMultiInTable(data: IClinicalDetail, $i18n: IVueI18n) {
  if (!data.single_or_multi) return $i18n.locale === 'cn' ? '未知' : 'Unknown'
  if (data.single_or_multi === 'single_drug') return $i18n.locale === 'cn' ? '单药' : 'Single Drug'
  else if (data.single_or_multi === 'multiple_drugs') return $i18n.locale === 'cn' ? '联合' : 'Multiple Drugs'
  else return EMPTY_PLACEHOLDER
}
