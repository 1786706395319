import { patentIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { BDrugItem, BPatentTypeItem, GDescription, GIcon, GLimited, GLink, GLoading } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderPatentAbstText, renderPatentPublicationData, renderPatentTitleText, renderStdPatentPeopleItem } from '@pharmsnap/shared/render'
import { IPatentDetail } from '@pharmsnap/shared/types'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { PropType, computed, defineComponent } from '@vue/composition-api'
import { groupBy, unionBy } from 'lodash'
import styles from '../BCard.module.scss'
import $classes from './BCardPatentSimple.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BCardPatentSimple = defineComponent({
  name: 'BCardPatentSimple',
  i18n: {
    messages: {
      cn: {
        ...cn,
      },
      en: {
        ...en,
      },
    },
  },
  props: {
    data: {
      type: Object as PropType<IPatentDetail>,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useLocale()
    const detailUrl = computed(() => {
      return sharedCtx.router.generatorPatentDetailPath(props.data.patent_id)
    })

    return {
      locale,
      detailUrl,
    }
  },
  methods: {
    renderLimited<T>(list: T[] = [], render: (item: T) => JSX.Element, limitCount = 3, totalCount?: number) {
      if (list.length === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={limitCount}
          linkTip={this.$t('BCardPatentSimple.viewInDetailTip') as string}
          link={this.detailUrl}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
          gap={{ x: 4, y: 4, trigger: 4 }}
        ></GLimited>
      )
    },
    renderPublicationInfo() {
      return (
        <div class={$classes.publicationInfo}>
          <div class={$classes.publicationItem}>
            {`${getFieldNameByLang(patentIndexFieldLangMap, 'PN', this.locale)}:`}
            {this.data.pn ? (
              <GLink href={this.detailUrl} class={[styles.title, $classes.publicationValue]}>
                {this.data.pn}
              </GLink>
            ) : (
              <span class={$classes.publicationValue}>{EMPTY_PLACEHOLDER}</span>
            )}
          </div>
          <div class={$classes.publicationItem}>
            {`${getFieldNameByLang(patentIndexFieldLangMap, 'PBDT_YEARMONTHDAY', this.locale)}:`}
            <span class={$classes.publicationValue}>{renderPatentPublicationData(this.data, this.$i18n)}</span>
          </div>
        </div>
      )
    },
    renderRight() {
      return (
        <div>
          <GLink href={this.detailUrl} defaultColor="blue" class={[$classes.title, 'whitespace-pre-wrap']}>
            {renderPatentTitleText(this.data, this.$i18n)}
          </GLink>
          {this.renderPublicationInfo()}
          <GDescription
            class="mt-2"
            width={156}
            data={this.data}
            items={[
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_ORG_ID_ONEID', this.locale)}:`,
                field: 'PHS_ORG_ID_ONEID',
                contentCls: styles.contentItem,
                labelCls: 'text-sm',
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PATENTED_DRUG', this.locale)}:`,
                field: 'PATENTED_DRUG',
                contentCls: styles.contentItem,
                labelCls: 'text-sm',
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_CORE_TYPE_ID', this.locale)}:`,
                field: 'PHS_CORE_TYPE_ID',
                contentCls: styles.contentItem,
                labelCls: 'text-sm',
              },
            ]}
            scopedSlots={{
              PHS_ORG_ID_ONEID: () => {
                const data = Object.entries(
                  groupBy(
                    (this.data.current_assignee || []).filter((item) => item.organization || item.display_name),
                    (item) => item.name_id
                  )
                ).map(([key, value]) => value)

                return this.renderLimited(data, (data) => renderStdPatentPeopleItem(data, this.$i18n))
              },
              PATENTED_DRUG: () => {
                const data = unionBy([...(this.data.core_drug_id_view || []), ...(this.data.core_recommend_drug_id_view || [])], 'drug_id')
                const total = unionBy([...(this.data.core_drug_id || []), ...(this.data.core_recommend_drug_id || [])]).length

                return this.renderLimited(
                  data,
                  (item) => {
                    return <BDrugItem showCard data={item}></BDrugItem>
                  },
                  3,
                  total
                )
              },
              PHS_CORE_TYPE_ID: () => {
                const total = (this.data.patent_type_id || []).length
                return this.renderLimited(
                  this.data.patent_type_id_view || [],
                  (item) => {
                    return <BPatentTypeItem data={item}></BPatentTypeItem>
                  },
                  3,
                  total
                )
              },
            }}
          ></GDescription>
          <div class={$classes.abstract} domPropsInnerHTML={renderPatentAbstText(this.data, this.$i18n)}></div>
        </div>
      )
    },
    renderLeft() {
      const thumbnailUrl = this.data.patent_image120?.fulltext_image_url
      return (
        <div class={$classes.picContainer}>
          <vue-lazy-component>
            <div class={$classes.picSlot}>
              {thumbnailUrl ? (
                <div class={$classes.pic} style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>
              ) : (
                <div class="h-full flex flex-col justify-center items-center">
                  <GIcon svgName="NoImage" size={36}></GIcon>
                  <div class="text-sm text-gray-90">{this.$t('BCardPatentSimple.noImage')}</div>
                </div>
              )}
            </div>
            <template slot="skeleton">
              <div class={$classes.picLoading}>
                <GLoading></GLoading>
              </div>
            </template>
          </vue-lazy-component>
        </div>
      )
    },
  },
  render() {
    return (
      <div class={[styles.container, $classes.container]}>
        {this.renderLeft()}
        {this.renderRight()}
      </div>
    )
  },
})
