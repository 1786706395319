import { IAggregationSingleResult, IAggregationValue, IAutoCompleteType } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { E_NODATA_SIZE, IElTreeNode, ISearchResult, ITreeAggregationItem } from '@pharmsnap/shared/src/types'
import {
  calcIsLeaf,
  convertTreeAggregationData,
  findTarget,
  getDisplayNameDegraded,
  getTargetNodePath,
  loopGetIdPath,
  showSingleToast,
} from '@pharmsnap/shared/src/utils'
import { PropType, computed, defineComponent, getCurrentInstance, nextTick, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { cloneDeep, difference, isUndefined, map, uniqBy } from 'lodash'
import { Icon as PtIcon } from 'patsnap-biz'
import { RecycleScroller } from 'vue-virtual-scroller'
import { GDialog } from '../../../ui/GDialog/GDialog'
import { GEmpty } from '../../../ui/GEmpty/GEmpty'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GMiniSwitch } from '../../../ui/GMiniSwitch/GMiniSwitch'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import { GTree } from '../../../ui/GTree/GTree'
import { BAutoEntityInput } from '../../BAc/BAutoEntityInput/BAutoEntityInput'
import { IFilterItemConfig } from '../../BFilter/types'
import { renderDisplayNameWithTitle } from '../../BFilter/utils/render'
import { aggregationData2keyMap, createRootField2FieldMap } from '../../BFilter/utils/utils'
import { IAdvanceFilterConfig, ISelectedFilter, ISelectedFilterItem, ISelectedTreeFilterItem } from '../type'
import $classes from './BAdvanceFilterDialog.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BAdvanceFilterDialog = defineComponent({
  name: 'BAdvanceFilterDialog',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    isShowDialog: {
      required: true,
      type: Boolean,
    },
    selectedFilterField: {
      required: true,
      type: String,
    },
    selectedData: {
      required: true,
      type: Object as PropType<Record<string, ISelectedFilter>>,
    },
    aggByParentConfig: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    filterList: {
      required: true,
      type: Array as PropType<Array<IAdvanceFilterConfig>>,
    },
    needSubmitFlattenTreeData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const { locale } = useLocale()

    const isLoading = ref(false)
    // 所有过滤项的结果
    const filterData = reactive<Record<string, IAggregationSingleResult<ITreeAggregationItem | IAggregationValue>>>(
      props.filterList.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.field]: {
            total: 0,
            items: [],
            aggregation_field: curr.field,
          },
        }
      }, {})
    )

    // 已选中的过滤项结果
    const selectedFilterData = reactive<Record<string, ISelectedFilter>>(
      props.filterList.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.field]: {
            displayItems: [],
            // 多靶点使用：多靶点无roll up，所以靶点不使用roll up，展示只显示用户选择的靶点，但把用户选择的靶点及其子靶点，都给后端
            actualItems: [],
            field: curr.field,
          },
        }
      }, {} as Record<string, ISelectedFilter>)
    )

    const inputData = reactive<{
      filterText: string
      searchId: string
      expandedIds: string[]
      expandIdArr: string[][]
      currentIndex: number
    }>({
      filterText: '',
      searchId: '',
      expandedIds: [],
      expandIdArr: [],
      currentIndex: 0,
    })
    // 过滤后的list data
    const filteredListData = ref<IAggregationValue[]>([])
    // 过滤后的tree data
    const filteredTreeData = ref<ITreeAggregationItem[]>([])

    const currentFilterConfig = computed(() => {
      return props.filterList.find((item) => item.field === props.selectedFilterField)
    })
    const currentFilterData = computed<Array<ITreeAggregationItem | IAggregationValue>>(() => {
      return filterData[props.selectedFilterField]?.items || []
    })
    const searchTreeRes = computed(() => !!inputData.expandIdArr.length)

    const confirmDisabled = computed(() => {
      if (isLoading.value) return true

      let isDisabled = true

      // 已选择的内容未变更时，按钮disabled
      for (const key of Object.keys(selectedFilterData)) {
        const selectedKeys = map(selectedFilterData[key].displayItems, 'value')
        const originSelectedKeys = map(props.selectedData[key].displayItems, 'value')
        if (
          // 机构变更总公司开关，field会变，若field变更&&有数据选择 => 需允许点击提交
          ((selectedKeys.length || originSelectedKeys.length) && selectedFilterData[key].field !== props.selectedData[key].field) ||
          selectedKeys.length !== originSelectedKeys.length ||
          difference(selectedKeys, originSelectedKeys).length
        ) {
          isDisabled = false
          break
        }
      }
      return isDisabled
    })

    const handleClearAll = () => {
      props.filterList.forEach((item) => {
        selectedFilterData[item.field].actualItems = []
        selectedFilterData[item.field].displayItems = []
      })
    }

    const handleSelectAutocompleteItem = (type: IAutoCompleteType, item: ITreeAggregationItem) => {
      inputData.searchId = item.id
      handleSearch()
      // 有效的autocomplete内容，增加至已选择的filter中
      if (inputData.expandIdArr.length || filteredListData.value.length) {
        if (currentFilterConfig.value?.isTree) {
          const res = findTarget(filteredTreeData.value, item.id)
          res && handleSelectTreeItem(res as ITreeAggregationItem)
        } else {
          const res = filteredListData.value.find((o) => o.key === item.id)
          res && handleSelectListItem(item.id)
        }
      }
    }

    const checkSelectedItemIsExist = (itemId: string) => {
      const currentSelectData = selectedFilterData[props.selectedFilterField].displayItems

      const isExist = currentSelectData.find((o) => o.value === itemId)

      if (isExist) {
        showSingleToast({
          type: 'warning',
          message: ins?.proxy.$i18n.tc('common.exist') || '',
        })
        return true
      }
      return false
    }

    const updateSelectedFilterData = (item: ISelectedFilterItem) => {
      const isExist = checkSelectedItemIsExist(item.value)

      if (isExist) {
        return
      }

      selectedFilterData[props.selectedFilterField].displayItems.push(item)
    }

    const handleSelectListItem = (key: string) => {
      const res = (currentFilterData.value as IAggregationValue[]).find((o) => o.key === key)
      if (res) {
        const item = {
          display_name_cn: res.display_name_cn || res.display_name_en,
          display_name_en: res.display_name_en,
          value: res.key,
          dataTypeLabel: currentFilterConfig.value?.label || '',
        }
        updateSelectedFilterData(item)
      }
    }

    const formatTreeItem = (item: ITreeAggregationItem): ISelectedTreeFilterItem => {
      const { name_cn, name_en, ext_id } = item
      const formattedItem: ISelectedTreeFilterItem = {
        display_name_cn: name_cn || name_en || '',
        display_name_en: name_en || '',
        value: item.id,
        icon: item.is_leaf ? '' : 'Tree',
        ext_id,
        dataTypeLabel: currentFilterConfig.value?.label || '',
      }
      // 多靶点特殊处理：需要存储用户选择的靶点及其子节点
      // 提交的时候，若需要把tree拍平：也需要存储用户选择的节点及其子节点
      if (item.children?.length && (props.selectedFilterField === 'TARGET_ID_EXTEND' || props.needSubmitFlattenTreeData)) {
        formattedItem.children = item.children.map((item) => formatTreeItem(item))
      }
      return formattedItem
    }

    const handleSelectTreeItem = (item: ITreeAggregationItem) => {
      const isExist = checkSelectedItemIsExist(item.id)
      if (isExist) {
        return
      }
      const formattedItem = formatTreeItem(item)
      updateSelectedFilterData(formattedItem)
    }

    const handleResetData = () => {
      inputData.filterText = ''
      inputData.searchId = ''
      inputData.expandedIds = []
      inputData.expandIdArr = []
      inputData.currentIndex = 0
    }
    const handleSearch = () => {
      if (currentFilterConfig.value?.isTree) {
        searchByIdForTree()
      } else {
        searchByIdForList()
      }
    }
    const handleClearSearch = () => {
      handleResetData()
      handleSearch()
    }

    const scrollNodeIntoView = (id: string) => {
      const browseTree = ins?.proxy.$refs.browseTree as any
      if (browseTree) {
        const el = browseTree.$el.querySelector(`#kg-expand-node--${id}`)
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    }
    const handleSearchPrev = () => {
      if (inputData.currentIndex === 1) {
        inputData.currentIndex = inputData.expandIdArr.length
      } else {
        inputData.currentIndex -= 1
      }
      const ids = inputData.expandIdArr[inputData.currentIndex - 1]
      ids && ids.length && scrollNodeIntoView(ids.join('-'))
    }
    const handleSearchNext = () => {
      if (inputData.currentIndex === inputData.expandIdArr.length) {
        inputData.currentIndex = 1
      } else {
        inputData.currentIndex += 1
      }
      const ids = inputData.expandIdArr[inputData.currentIndex - 1]
      ids && ids.length && scrollNodeIntoView(ids.join('-'))
    }
    const searchByIdForList = () => {
      const listData = currentFilterData.value as IAggregationValue[]
      filteredListData.value = listData
      if (inputData.searchId) {
        filteredListData.value = listData.filter((item) => item.key === inputData.searchId)
      }
    }

    const handleDeleteItem = (filter: string, item: ISelectedFilterItem) => {
      const filterData = selectedFilterData[filter].displayItems || []
      selectedFilterData[filter].displayItems = filterData.filter((o) => o.value !== item.value)
    }

    const searchByIdForTree = () => {
      const treeData = currentFilterData.value as ITreeAggregationItem[]
      filteredTreeData.value = treeData

      calcIsLeaf(filteredTreeData.value)
      if (inputData.searchId) {
        inputData.expandIdArr = getTargetNodePath(filteredTreeData.value, inputData.searchId)
        inputData.expandedIds = inputData.expandIdArr.flat()
        inputData.currentIndex = 0
        nextTick(handleSearchNext)
      }
    }
    // 多靶点，使用ext_id作为value,并存储子节点的数据
    const getTargetRollupItems = (item: ISelectedTreeFilterItem, res: ISelectedTreeFilterItem[]) => {
      if (item.ext_id) {
        const selected = {
          ...item,
          value: item.ext_id || '',
        }
        res.push(selected)
      }
      if (item.children?.length) {
        item.children.forEach((child) => {
          getTargetRollupItems(child, res)
        })
      }
    }

    const getTreeRollupItems = (item: ISelectedTreeFilterItem, res: ISelectedTreeFilterItem[]) => {
      res.push(item)

      if (item.children?.length) {
        item.children.forEach((child) => {
          getTreeRollupItems(child, res)
        })
      }
    }

    const handleClose = () => {
      ctx.emit('closeDialog')
    }
    const handleConfirm = () => {
      handleClose()
      if (selectedFilterData.TARGET_ID_EXTEND) {
        const selectedTargetData = selectedFilterData.TARGET_ID_EXTEND.displayItems
        const fullPathTargetData: ISelectedTreeFilterItem[] = []
        selectedTargetData.forEach((item) => {
          getTargetRollupItems(item, fullPathTargetData)
        })
        selectedFilterData.TARGET_ID_EXTEND.actualItems = uniqBy(fullPathTargetData, 'value')
      }

      if (props.needSubmitFlattenTreeData) {
        const treeField: string[] = []
        props.filterList.forEach((item) => {
          // 多靶点树默认拍平，拍平逻辑和其他树不一样,所以单独处理其他树结构
          if (item.isTree && item.field !== 'TARGET_ID_EXTEND') {
            treeField.push(item.field)
          }
        })
        treeField.forEach((field) => {
          const selectedTreeData = selectedFilterData[field].displayItems
          const fullPathTreeData: ISelectedTreeFilterItem[] = []
          selectedTreeData.forEach((item) => {
            getTreeRollupItems(item, fullPathTreeData)
          })
          selectedFilterData[field].actualItems = uniqBy(fullPathTreeData, 'value')
        })
      }

      ctx.emit('confirm', cloneDeep(selectedFilterData))
    }
    const handleClickFilterItem = (field: string) => {
      if (field !== props.selectedFilterField) {
        ctx.emit('changeSelectFilter', field)
      }
    }
    const handleChangeAggregationField = () => {
      selectedFilterData[props.selectedFilterField].displayItems = []
      selectedFilterData[props.selectedFilterField].actualItems = []
      ctx.emit('changeAggregationField', props.selectedFilterField)
    }

    const fetchSelectedFilterData = async () => {
      isLoading.value = true
      const res = await currentFilterConfig.value?.requestFunc()
      if (res) {
        if (currentFilterConfig.value?.isTree) {
          const { items, total } = res as ISearchResult<ITreeAggregationItem>
          filterData[props.selectedFilterField] = {
            total: total,
            items: convertTreeAggregationData(items || []),
            aggregation_field: props.selectedFilterField,
          }
        } else {
          filterData[props.selectedFilterField] = aggregationData2keyMap(
            res as IAggregationSingleResult[],
            createRootField2FieldMap(props.filterList as unknown as IFilterItemConfig[])
          )[props.selectedFilterField]

          selectedFilterData[props.selectedFilterField].field = filterData[props.selectedFilterField].aggregation_field
        }
      }
      isLoading.value = false
    }

    const syncSelectedFilterData = () => {
      const selectedData = cloneDeep(props.selectedData)
      props.filterList.forEach((item) => (selectedFilterData[item.field].displayItems = selectedData[item.field].displayItems))
    }

    watch(
      () => [props.isShowDialog, props.selectedFilterField, props.aggByParentConfig],
      async () => {
        if (props.isShowDialog) {
          await fetchSelectedFilterData()
        }
        handleClearSearch()
      },
      { immediate: true, deep: true }
    )

    watch(
      () => [props.isShowDialog, props.selectedData],
      () => {
        if (props.isShowDialog) {
          syncSelectedFilterData()
        }
      },
      { immediate: true }
    )

    return {
      ...toRefs(inputData),
      locale,
      isLoading,
      filterData,
      filteredTreeData,
      filteredListData,
      selectedFilterData,
      currentFilterConfig,
      searchTreeRes,
      confirmDisabled,
      handleClearAll,
      handleClose,
      handleConfirm,
      handleClickFilterItem,
      handleSelectAutocompleteItem,
      handleSelectListItem,
      handleSelectTreeItem,
      handleClearSearch,
      handleDeleteItem,
      handleSearchPrev,
      handleSearchNext,
      handleChangeAggregationField,
    }
  },
  methods: {
    renderFilterList() {
      return (
        <div class="p-2">
          <div class="p-2 font-semibold leading-6 text-base text-text-t2">{this.$tc('advanceRefine')}</div>
          {this.filterList.map((item) => (
            <div
              class={{
                [$classes.filterItem]: true,
                'bg-gray-30': item.field === this.selectedFilterField,
              }}
              onClick={() => this.handleClickFilterItem(item.field)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )
    },
    renderSearch() {
      let inputDom = null
      let aggByParentDom = null
      if (this.currentFilterConfig?.autocompleteType) {
        inputDom = (
          <BAutoEntityInput
            style="width: 240px; margin-top: 8px;"
            prefix="SearchRight"
            disabled={!this.filterData[this.selectedFilterField].items.length}
            onSelect={this.handleSelectAutocompleteItem}
            onClear={this.handleClearSearch}
            v-model={this.filterText}
            type={this.currentFilterConfig?.autocompleteType}
            placeHolder={this.$t('searchPlaceHolder') as string}
            onSearchPrev={this.handleSearchPrev}
            onSearchNext={this.handleSearchNext}
            currentIndex={this.currentIndex}
            searchRes={this.expandIdArr}
          ></BAutoEntityInput>
        )
      }
      if (!isUndefined(this.aggByParentConfig[this.selectedFilterField])) {
        aggByParentDom = (
          <GTooltip theme="light" content={this.$tc('groupByParentTooltip')}>
            <GMiniSwitch
              value={this.aggByParentConfig[this.selectedFilterField]}
              onChange={this.handleChangeAggregationField}
              width={16}
              active-color="#1976D2"
              inactive-color="#BCC2CC"
              active-text={this.$tc('chart.aggregation')}
            ></GMiniSwitch>
          </GTooltip>
        )
      }
      return (
        <div class="flex justify-between items-end">
          {inputDom}
          {aggByParentDom}
        </div>
      )
    },
    renderEmpty(text: string) {
      return (
        <div class="h-full flex items-center justify-center">
          <GEmpty size={E_NODATA_SIZE.SMALL} title={text}></GEmpty>
        </div>
      )
    },
    renderTreeItem(scope: { node: IElTreeNode; data: ITreeAggregationItem }) {
      const { data, node } = scope
      const expandIdArr = this.expandIdArr.map((expandId) => expandId.join('-'))
      const displayName =
        this.currentFilterConfig?.autocompleteType === 'Target' ? data[`entity_name_${this.locale}`] : data[`name_${this.locale}`] || data.name_en
      const label = displayName
      const classId = loopGetIdPath(node).join('-')
      const include = expandIdArr.includes(classId)
      return (
        <span class={$classes.treeItem} id={`kg-expand-node--${classId}`} onclick={() => this.handleSelectTreeItem(data)}>
          <span class="mr-1 text-text-t2" style={{ backgroundColor: include ? '#F8D50F' : '' }} title={label}>
            {label}
          </span>
        </span>
      )
    },
    renderTree() {
      const emptyText = this.searchId && !this.searchTreeRes ? this.$tc('noData') : !this.filteredTreeData.length ? this.$tc('common.noData') : ''
      if (emptyText) {
        return this.renderEmpty(emptyText)
      }

      return (
        <div class="h-full overflow-y-auto">
          <GTree
            key={this.searchId}
            lazy={false}
            class={$classes.tree}
            expandedIds={this.expandedIds}
            searchId={this.searchId}
            treeData={this.filteredTreeData}
            ref="browseTree"
            showOrgLogo={false}
            showCheckbox={false}
            scopedSlots={{
              default: (scope: { node: IElTreeNode; data: ITreeAggregationItem }) => {
                return this.renderTreeItem(scope)
              },
            }}
          ></GTree>
        </div>
      )
    },
    renderList() {
      if (this.filteredListData.length) {
        return (
          <RecycleScroller
            class="h-full overflow-y-auto"
            items={this.filteredListData}
            item-size={24}
            key-field="key"
            scopedSlots={{
              default: (data: { item: IAggregationValue }) => {
                const { item } = data
                return (
                  <div class={$classes.listItem} onclick={() => this.handleSelectListItem(item.key)}>
                    {renderDisplayNameWithTitle(this.locale, item)}
                  </div>
                )
              },
            }}
          ></RecycleScroller>
        )
      }
      const emptyText =
        this.searchId && !this.filteredListData.length ? this.$tc('noData') : !this.filteredListData.length ? this.$tc('common.noData') : ''

      if (emptyText) {
        return this.renderEmpty(emptyText)
      }
    },
    renderFilterContent() {
      if (this.isLoading) return null
      return this.currentFilterConfig?.isTree ? this.renderTree() : this.renderList()
    },
    renderContent() {
      const currentSelectFieldTotal = this.filterData[this.selectedFilterField]?.total
      return (
        <div class="p-4 h-full flex flex-col" v-ls-loading={this.isLoading}>
          <div class="text-text-t2 font-semibold text-base">
            {this.currentFilterConfig?.label}
            {currentSelectFieldTotal ? ` (${currentSelectFieldTotal})` : ''}
          </div>
          {this.renderSearch()}
          <div class="mt-3 flex-1 p-2 border border-gray-40 overflow-hidden rounded">{this.renderFilterContent()}</div>
        </div>
      )
    },
    renderFooter() {
      return (
        <div class="h-full flex flex-col overflow-hidden">
          <div class="flex justify-between text-sm leading-5">
            <span class="text-black-default">{this.$t('refine')}</span>
            <span class="text-blue-default cursor-pointer" onClick={this.handleClearAll}>
              {this.$t('common.clearAll')}
            </span>
          </div>
          <div class="flex-1 flex-wrap overflow-auto mt-2 p-2 rounded border-gray-40 border">
            {Object.entries(this.selectedFilterData).map(([key, filterList]) =>
              filterList.displayItems.map((filter) => (
                <div class={$classes.selectedItem} key={`${key}-${filter.value}`}>
                  {filter.icon ? (
                    <PtIcon icon={filter.icon} fontSize={20} class="w-5 h-5 mr-1 text-grey-450 flex-shrink-0"></PtIcon>
                  ) : (
                    <span class="h-5" />
                  )}
                  <span class="mr-1 font-semibold">{filter.dataTypeLabel}:</span>
                  <span class="flex-1 truncate mr-1">{getDisplayNameDegraded(filter, this.locale)}</span>
                  <PtIcon class="cursor-pointer w-5 h-5" icon="SolidCloseBig" onClick={() => this.handleDeleteItem(key, filter)}></PtIcon>
                </div>
              ))
            )}
          </div>
          <div class="mt-3 flex justify-end">
            <button class="pt-ui-btn mr-2" type="button" data-type="default" onClick={this.handleClose}>
              {this.$tc('common.cancel')}
            </button>
            <button class="pt-ui-btn" type="button" data-type="submit" onClick={this.handleConfirm} disabled={this.confirmDisabled}>
              {this.$tc('common.submit')}
            </button>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <GDialog
        v-model={this.isShowDialog}
        emptyMode={true}
        width="910px"
        scopedSlots={{
          default: () => {
            return (
              <div class="flex flex-col h-[568px]">
                <div class="flex-1 flex overflow-hidden relative">
                  <GIcon
                    class="cursor-pointer absolute top-4 right-4"
                    nativeOnClick={this.handleClose}
                    size={24}
                    useSvgDefaultColor
                    svgName="SolidCloseBig"
                  ></GIcon>
                  <div class="w-[200px] border-r border-gray-40 h-full whitespace-nowrap">{this.renderFilterList()}</div>
                  <div class="flex-1 h-full overflow-hidden">{this.renderContent()}</div>
                </div>
                <div class="h-[168px] px-4 py-2 border-gray-40 border-t">{this.renderFooter()}</div>
              </div>
            )
          },
        }}
      ></GDialog>
    )
  },
})
