import { defineComponent, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BDiseaseFetchCard } from './BDiseaseFetchCard'

export const BDiseasePopperCard = defineComponent({
  name: 'BDiseasePopperCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    drug_id: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper data={{ id: this.id, drug_id: this.drug_id, showIcon: true }} tippyConfig={this.tippyConfig} component={BDiseaseFetchCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
