import { ILang } from '@patsnap/synapse_common_interface'
import { GLimitedContainer } from '../components'
import { IBaseDictionaryInfo } from '../types'
import { getSortFnByLang } from '../utils'
import { getDiseaseSynonyms } from '../utils/disease'
import { renderLimitedSynonyms } from './baseRender'

export function renderDrugTypeSynonyms(data: IBaseDictionaryInfo, lang: ILang) {
  const names = getDiseaseSynonyms(data, lang)
  return renderLimitedSynonyms(names.sort(getSortFnByLang(lang)), 3)
}

export function renderDrugTypeIntroduction(data: IBaseDictionaryInfo) {
  return (
    <GLimitedContainer limitLines={3} lineHeight={24}>
      {data.desc_en}
    </GLimitedContainer>
  )
}
