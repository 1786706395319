import { IStructureAutocompleteItem, IStructureHighlightInfo, IStructurePreviewInfo } from '../../types/structure'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class StructureApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string, public chemicalUrl: string) {
    super(http, baseUrl)
  }

  async preview(inchi_key: string, smiles: string, signal?: AbortSignal): Promise<IHttpWrap<IStructurePreviewInfo>> {
    return this.http({
      method: 'POST',
      data: {
        inchi_key,
        smiles,
      },
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/structure/preview`),
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async upload(file: File): Promise<IHttpWrap<{ code: 200; results: { mol: string } }>> {
    const formData = new FormData()
    formData.append('file', file)
    return this.http({
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: this.getUrl(`chemical-service-api/chemical-data/image/preview`),
    })
  }

  async highlight(inchi_keys: string[], mrv: string, type: 'SIM' | 'SUB', signal?: AbortSignal): Promise<IHttpWrap<IStructureHighlightInfo[]>> {
    return this.http({
      method: 'POST',
      data: {
        type: type,
        inchi_key: inchi_keys,
        smiles: mrv,
      },
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/structure/highlight`),
    })
  }

  async autocomplete(keywords: string, signal?: AbortSignal): Promise<IHttpWrap<IStructureAutocompleteItem[]>> {
    return this.http({
      method: 'POST',
      data: {
        prefix: keywords,
      },
      signal,
      url: this.getUrl(`pharmsnap_webapi/1.0/structure/autocomplete`),
    })
  }
}
