import { IQueryDataType, IQueryItemField, IQueryValueText } from '@patsnap/synapse_common_interface'
type IFieldQueryItemTransformMap = Record<
  string,
  {
    display2store: (queryItem: IQueryItemField) => IQueryItemField
    store2display: (queryItem: IQueryItemField) => IQueryItemField
  }
>

export const drugFieldQueryItemTransformMap: IFieldQueryItemTransformMap = {
  DEV_ORG_TYPE_ONEID: {
    display2store(queryItem: IQueryItemField) {
      const fieldValue = queryItem.value as IQueryValueText[]
      const otherValue = fieldValue.find((item) => item.value === 'Other')
      if (!otherValue) {
        return queryItem
      }
      const newFieldValue = fieldValue
        .filter((item) => item.value !== 'Other')
        .concat([
          {
            type: 'text',
            display_name_cn: '政府',
            display_name_en: 'Government',
            value: 'Government',
          },
          {
            type: 'text',
            display_name_cn: '非营利组织',
            display_name_en: 'Nonprofit',
            value: 'Nonprofit',
          },
        ])
      return {
        ...queryItem,
        value: newFieldValue,
      }
    },
    store2display(queryItem: IQueryItemField) {
      const fieldValue = queryItem.value as IQueryValueText[]
      const otherValues = fieldValue.filter((item) => ['Government', 'Nonprofit'].includes(item.value))
      if (!otherValues.length) {
        return queryItem
      }
      const newFieldValue = fieldValue
        .filter((item) => !['Government', 'Nonprofit'].includes(item.value))
        .concat([
          {
            type: 'text',
            display_name_cn: '其他',
            display_name_en: 'Other',
            value: 'Other',
          },
        ])
      return {
        ...queryItem,
        value: newFieldValue,
      }
    },
  },
}

export const clinicalQueryItemTransformMap: IFieldQueryItemTransformMap = {
  VIRTUAL_SPONSOR_TYPE: {
    display2store(queryItem: IQueryItemField) {
      const fieldValue = queryItem.value as IQueryValueText[]
      const otherValue = fieldValue.find((item) => item.value === 'Other')
      if (!otherValue) {
        return queryItem
      }
      const newFieldValue = fieldValue
        .filter((item) => item.value !== 'Other')
        .concat([
          {
            type: 'text',
            display_name_cn: '政府',
            display_name_en: 'Government',
            value: 'Government',
          },
          {
            type: 'text',
            display_name_cn: '非营利组织',
            display_name_en: 'Nonprofit',
            value: 'Nonprofit',
          },
        ])
      return {
        ...queryItem,
        value: newFieldValue,
      }
    },
    store2display(queryItem: IQueryItemField) {
      const fieldValue = queryItem.value as IQueryValueText[]
      const otherValues = fieldValue.filter((item) => ['Government', 'Nonprofit'].includes(item.value))
      if (!otherValues.length) {
        return queryItem
      }
      const newFieldValue = fieldValue
        .filter((item) => !['Government', 'Nonprofit'].includes(item.value))
        .concat([
          {
            type: 'text',
            display_name_cn: '其他',
            display_name_en: 'Other',
            value: 'Other',
          },
        ])
      return {
        ...queryItem,
        value: newFieldValue,
      }
    },
  },
}
export const clinicalTrialResultQueryItemTransformMap: IFieldQueryItemTransformMap = {}

export const patentQueryItemTransformMap: IFieldQueryItemTransformMap = {}

export const paperQueryItemTransformMap: IFieldQueryItemTransformMap = {}

export const drugDealQueryItemTransformMap: IFieldQueryItemTransformMap = {}

export const transMedicineQueryItemTransformMap: IFieldQueryItemTransformMap = {}

export const queryDataTypeQueryItemTransformMap: Partial<Record<IQueryDataType, IFieldQueryItemTransformMap>> = {
  drug: drugFieldQueryItemTransformMap,
  clinical_trial: clinicalQueryItemTransformMap,
  patent: patentQueryItemTransformMap,
  paper: paperQueryItemTransformMap,
  clinical_trial_result: clinicalTrialResultQueryItemTransformMap,
  drug_deal: drugDealQueryItemTransformMap,
  translational_medicine: transMedicineQueryItemTransformMap,
}
