import { defineComponent, h, PropType } from '@vue/composition-api'
import { VNode } from 'vue'
export const BHighlightDom = defineComponent({
  name: 'BHighlightDom',
  props: {
    name: {
      required: true,
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'span',
    },
    strongArr: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },
    parentheses: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {}
  },
  render() {
    if (this.strongArr.length) {
      const orderedStrongArr = this.strongArr.slice().sort((a, b) => a - b)

      const res: Array<string | VNode> = []
      orderedStrongArr.forEach((o, i) => {
        if (i === orderedStrongArr.length - 1 && o === this.name.length) {
          return
        }
        if (i === orderedStrongArr.length - 1 && o !== this.name.length) {
          // strong的最后一个index，且不是label的最后一位
          const label = this.name.slice(o, orderedStrongArr[orderedStrongArr.length])
          res.push(label)
          return
        }
        if (i === 0 && o !== 0) {
          // 第一个 且 strong开头不是0
          const label = this.name.slice(0, o)
          res.push(label)
        }

        const l = this.name.slice(o, orderedStrongArr[i + 1])
        if (i % 2 === 0) {
          // strong
          res.push(h('strong', {}, l))
        } else {
          // normal
          res.push(l)
        }
      })
      if (this.parentheses) {
        res.unshift('(')
        res.push(')')
      }
      return h(this.tag as string, {}, res)
    }
    return <span data-testid="b-highlight-dom">{this.name}</span>
  },
})
