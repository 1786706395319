import { IAggregationData, IAggregationParams, ISearchParams } from '@patsnap/synapse_common_interface'
import { IPaperDetail, IRelatedPaperParams, ITranslateLang } from '@pharmsnap/shared/types'
import { ISearchResult } from '@pharmsnap/shared/types/search'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformLiteratureAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/literature'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import { BaseApi } from './baseApi'

export class LiteratureApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  /**
   * 文献搜索列表，不计搜索次数
   */
  async getPaperList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPaperDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/paper/search'),
      cache: true,
      trackingName: 'PAPER_LIST',
    })
  }

  /**
   * 文献搜索列表，计搜索次数
   */
  async getPaperListWithCalcLimits(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPaperDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/paper'),
      cache: true,
      trackingName: 'PAPER_LIST',
    })
  }

  async getRelatedPaperList<T>(params: IRelatedPaperParams): Promise<IHttpWrap<ISearchResult<T>>> {
    const { paperId, type, limit = 10, offset = 0 } = params
    return this.http({
      method: 'GET',
      data: params,
      url: this.getUrl(`pharmsnap_webapi/1.0/paper/${type}/${paperId}?limit=${limit}&offset=${offset}`),
      cache: true,
    })
  }

  async getPaperDetail(params: { id: string; translation: Omit<ITranslateLang, 'original'> }): Promise<IHttpWrap<IPaperDetail>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/paper/${params.id}`),
      params: { translation: params.translation },
      method: 'GET',
      cache: true,
    })
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IPaperDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/paper/search_for_count'),
      cache: true,
    })
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/paper/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformLiteratureAggregationSingleResult))
  }

  async getPaperCardData({ id }: { id: string }, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl(`pharmsnap_webapi/1.0/paper/card/${id}`),
      cache: true,
      method: 'GET',
    })
  }
}
