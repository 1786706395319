import { defineComponent, onBeforeUnmount, onMounted, PropType, provide, Ref, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'
import { anchorPrefixProvideKey } from './config'
export const GAnchorScroll = defineComponent({
  name: 'GAnchorScroll',
  props: {
    catalogs: {
      type: Array as PropType<Array<{ label: string; id: string }>>,
      default: () => [],
    },
    anchorPrefixClass: {
      type: String,
      default: 'pharmsnap-scroll',
    },
  },
  setup(props, ctx) {
    provide(anchorPrefixProvideKey, props.anchorPrefixClass)
    const scrollContainer: Ref<HTMLElement | null> = ref() as any
    const activeId = ref('')
    let hasRegisteredScrollHandler = false
    onMounted(() => {
      _enableScroll()
    })
    onBeforeUnmount(() => {
      _disableScroll()
    })
    watch(activeId, (value) => {
      ctx.emit('activeIdChange', value)
    })
    const _debounceScrollHandler = debounce(_scrollHandler, 16)
    const _debounceScrollTo = debounce((dom: HTMLElement) => {
      dom.scrollIntoView({
        behavior: 'smooth',
      })
      _enableScroll()
    }, 500)
    /**
     * 滚动到指定节点位置
     * @param id
     * @returns
     */
    function scrollIntoElement(id: string) {
      const stDom = document.querySelector(`.${props.anchorPrefixClass}_${id}`) as HTMLElement
      if (!stDom) return
      _disableScroll()
      stDom.scrollIntoView({
        behavior: 'smooth',
      })
      // 第一次触发滚动之后,一些异步元素开始加载,内容的高度会有变动,导致没有滚动到指定位置,所以需要再次触发一次滚动
      _debounceScrollTo(stDom)
      // _enableScroll()
    }
    function _scrollHandler() {
      if (!scrollContainer.value) {
        return
      }
      const scrollTop = scrollContainer.value.scrollTop
      const offsetHeight = scrollContainer.value.offsetHeight
      let nearestDom: HTMLElement | undefined = undefined
      const descTitleDom: NodeListOf<HTMLElement> = document.querySelectorAll(`.${props.anchorPrefixClass}-title`)
      descTitleDom.forEach((item) => {
        const { offsetTop } = item
        // 元素滚动到容器中间的时候表示看到该元素
        if (scrollTop > offsetTop - offsetHeight / 2) {
          nearestDom = item
        }
      })
      if (nearestDom) {
        const id = (nearestDom as HTMLElement).getAttribute('data-id')
        if (id) {
          activeId.value = id
        }
      }
    }
    function _enableScroll() {
      if (hasRegisteredScrollHandler) {
        return
      }
      if (!scrollContainer.value) {
        return
      }
      scrollContainer.value.addEventListener('scroll', _debounceScrollHandler)
      hasRegisteredScrollHandler = true
    }
    function _disableScroll() {
      if (!scrollContainer.value) {
        return
      }
      scrollContainer.value.removeEventListener('scroll', _debounceScrollHandler)
      hasRegisteredScrollHandler = false
    }
    return {
      activeId,
      scrollContainer,
      scrollIntoElement,
    }
  },
  render() {
    return (
      <div ref="scrollContainer" class="h-full overflow-y-auto">
        {this.$slots.default}
      </div>
    )
  },
})
