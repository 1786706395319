import { defineComponent, PropType } from '@vue/composition-api'
import { GMultiDropdown } from 'pharmsnapMF_shared/components'

export const BAnalysisChartDropdownSingleSelect = defineComponent({
  name: 'BAnalysisChartDropdownSelect',
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array as PropType<
        {
          id: string
          name: string
        }[]
      >,
      default: () => [],
    },
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 140,
    },
  },
  setup(props, ctx) {
    const handleChange = (value: string) => {
      ctx.emit('input', value)
    }

    return {
      handleChange,
    }
  },
  render() {
    const currentSelect = this.options.find((option) => option.id === this.value)
    return (
      <div>
        {this.title && <div class="mb-2 font-semibold leading-5 text-sm text-text-t1">{this.title}</div>}
        <GMultiDropdown
          width={this.width}
          label={currentSelect?.name}
          showFooter={false}
          clearAble={false}
          activeAble={false}
          // 通过popoverWidth控制宽度，设置最小宽度，为了覆盖组件默认最小宽度
          popoverClass="min-w-[10px] px-2"
          popoverWidth={this.width - 8}
        >
          {this.options.map((optionItem) => {
            return (
              <div
                onClick={() => this.handleChange(optionItem.id)}
                key={optionItem.id}
                class={[
                  'h-8 leading-8 w-full truncate text-sm px-5 rounded hover:bg-gray-30 cursor-pointer',
                  optionItem.id === currentSelect?.id ? 'text-blue-default' : 'text-gray-450',
                ]}
              >
                {optionItem.name}
              </div>
            )
          })}
        </GMultiDropdown>
      </div>
    )
  },
})
