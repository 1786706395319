import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { computed, getCurrentInstance, Ref } from '@vue/composition-api'
import { GridColumns } from 'vxe-table'
import { IPatentStatus } from '../../../types'
import { countRowFormatter } from '../../../utils'

export function useTherapeuticAreaTable(options: { aggDataRef: Ref<IAggregationValue[]>; selectedPatentTypeRef: Ref<IPatentStatus> }) {
  const { aggDataRef, selectedPatentTypeRef } = options
  const ins = getCurrentInstance()
  const computedColumns = computed<GridColumns[]>(() => [
    {
      title: ins?.proxy.$tc('therapeuticAreas.therapeuticAreas'),
      field: '__name',
    },
    {
      title: selectedPatentTypeRef.value === 'application' ? ins?.proxy.$tc('patentsApplication') || '' : ins?.proxy.$tc('patentsGranted') || '',
      field: 'count',
      slots: {
        default: ({ row }) => [toThousands(row.count)],
      },
    },
  ])

  const reversedInfo = computed(() => reverseTable())

  const computedFullScreenColumns = computed(() => reversedInfo.value.buildColumns)

  const computedFullScreenData = computed(() => reversedInfo.value.buildData)

  function reverseTable() {
    const buildData = computedColumns.value.map((column) => {
      const item: Record<string, string> = { col0: column.title || EMPTY_PLACEHOLDER }
      aggDataRef.value.forEach((row, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        item[`col${index + 1}`] = column.field && row[column.field] ? row[column.field] : EMPTY_PLACEHOLDER
      })
      return item
    })
    const buildColumns: GridColumns[] = [
      {
        field: 'col0',
        fixed: 'left',
        width: 170,
      },
    ]
    aggDataRef.value.forEach((item, index) => {
      buildColumns.push({
        field: `col${index + 1}`,
        minWidth: 140,
        formatter: countRowFormatter,
      })
    })

    return {
      buildData,
      buildColumns,
    }
  }

  return {
    computedColumns,
    computedFullScreenColumns,
    computedFullScreenData,
  }
}
