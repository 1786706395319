import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDictItem, IHistorySearchParams } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import $style from './BFilterTag.module.scss'
type ColorMap = { bgColor: string; bgHoverColor: string }
function getColumnColor(data: IBaseDictItem) {
  const { name_en } = data
  const colorMaps: Partial<Record<string, ColorMap>> = {
    'R＆D': {
      bgColor: 'rgba(145, 74, 237, 1)',
      bgHoverColor: 'rgba(117, 51, 203, 1)',
    },
    Deals: {
      bgColor: 'rgba(39, 180, 209, 1)',
      bgHoverColor: 'rgba(34, 161, 187, 1)',
    },
    Investment: {
      bgColor: 'rgba(220, 124, 8, 1)',
      bgHoverColor: 'rgba(194, 106, 5, 1)',
    },
    Policy: {
      bgColor: 'rgba(184, 23, 44, 1)',
      bgHoverColor: 'rgba(158, 20, 38, 1)',
    },
    Perspective: {
      bgColor: 'rgba(29, 136, 32, 1)',
      bgHoverColor: 'rgba(28, 102, 6, 1)',
    },
  }
  return colorMaps[name_en]
}
export const BNewsChannelTag = defineComponent({
  name: 'BFilterTag',
  props: {
    data: {
      type: Object as PropType<IBaseDictItem>,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const styleObj = computed(() => {
      const color = getColumnColor(props.data)
      if (color) {
        return {
          '--bg-color': color.bgColor,
          '--bg-hover-color': color.bgHoverColor,
          '--height': '20px',
        }
      }
      return {}
    })
    const { isCN } = useLocale()
    const displayName = computed(() => {
      return isCN.value ? props.data.name_cn : props.data.name_en
    })
    const handleClick = () => {
      const searchParams: IHistorySearchParams = {
        data_type: 'news',
        query: {
          type: 'group',
          filter: [
            {
              type: 'field',
              fields: ['phs_channel'],
              value: [
                {
                  type: 'text',
                  value: props.data.dict_id,
                  display_name_en: props.data.name_en,
                  display_name_cn: props.data.name_cn,
                },
              ],
            },
          ],
        },
        hidden_flag: true,
      }
      openPageWithoutPending(async () => {
        const queryRes = await sharedCtx.service.history.saveQuery(searchParams)
        if (queryRes.success) {
          return sharedCtx.router.generatorNewsListPath(queryRes.data.id)
        }
      })
    }
    return {
      styleObj,
      displayName,
      handleClick,
    }
  },
  render() {
    return (
      <ElTag class={$style.main} style={this.styleObj} disable-transitions={true} type="info" size="small" effect="dark" onClick={this.handleClick}>
        {this.displayName}
      </ElTag>
    )
  },
})
