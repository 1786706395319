import '@patsnap-biz/design-tokens/scss/_button.scss'
import VueCompositionAPI from '@vue/composition-api'
import Vue from 'vue'
import GLazyComponentDefault from './ui/GLazyComponent/GLazyComponent.vue'
export { BAcBase } from './business/BAc/BAcBase'
export { BAutoEntityInput } from './business/BAc/BAutoEntityInput/BAutoEntityInput'
export { useAcInput } from './business/BAc/compositions/useAcInput'
export { BAcList } from './business/BAcList/BAcList'
export { BAcTagList } from './business/BAcTagList/BAcTagList'
// export { BBrowse } from './business/BBrowse/BBrowse'
export { BAcTagListWithBrowse } from './business/BAcTagListWithBrowse/BAcTagListWithBrowse'
export { BAdvanceFilterDialog } from './business/BAdvanceFilter/BAdvanceFilterDialog/BAdvanceFilterDialog'
export { BAdvanceFilterItems } from './business/BAdvanceFilter/BAdvanceFilterItems/BAdvanceFilterItems'
export { BAnalysisChart } from './business/BAnalysisChart/BAnalysisChart'
export * from './business/BAnalysisChartNext/index'
export * from './business/BAnalysisView'
export { BApprovalCountryItem } from './business/BApprovalCountryItem/BApprovalCountryItem'
export { BAtcItem } from './business/BAtcItem/BAtcItem'
export { BAutoEntity } from './business/BAutoEntity/BAutoEntity'
export { BLabelInput } from './business/BAutoEntity/BLabelInput/BLabelInput'
export { BAutoPreview } from './business/BAutoPreview/BAutoPreview'
export { BAutoPreviewCounts } from './business/BAutoPreview/components/BAutoPreviewCounts/BAutoPreviewCounts'
export { BBindEmailMessage } from './business/BBindEmailMessage/BBindEmailMessage'
export { BBrowseNew } from './business/BBrowse/BBrowseNew'
export { BCardClinical } from './business/BCard/BCardClinical'
export { BCardClinicalResult } from './business/BCard/BCardClinicalResult'
export { BCardDrugDeal } from './business/BCard/BCardDrugDeal/BCardDrugDeal'
export { BCardDrugDealFetchList } from './business/BCard/BCardDrugDeal/BCardDrugDealFetchList'
export { BCardLiterature } from './business/BCard/BCardLiterature'
export { BCardNews } from './business/BCard/BCardNews'
export { BCardPatent } from './business/BCard/BCardPatent/BCardPatent'
export { BCardPatentSimple } from './business/BCard/BCardPatentSimple/BCardPatentSimple'
export { BCardTranslationalMedicine } from './business/BCard/BCardTranslationalMedicine/BCardTranslationalMedicine'
export { BCardContainer } from './business/BCardContainer/BCardContainer'
export { BCardList } from './business/BCardList/BCardList'
export { BCardPlaybook } from './business/BCardPlaybook/BCardPlaybook'
export { BCasStructureDialog } from './business/BCasStructure'
export { BChartCardContainer } from './business/BChartCardContainer/BChartCardContainer'
export { BCoInvestorsChart } from './business/BCoInvestorsChart/BCoInvestorsChart'
export { BCommonList } from './business/BCommonList/BCommonList'
export { BCompareBtn } from './business/BCompareBtn/BCompareBtn'
export { BCopy } from './business/BCopy/BCopy'
export { BCorporateTreeButton } from './business/BCorporateTreeButton/BCorporateTreeButton'
export { BCorpTree } from './business/BCorpTree/BCorpTree'
export { BCorpTreeOverview } from './business/BCorpTree/BCorpTreeOverview'
export { BCustomDatePicker } from './business/BCustomDatePicker/BCustomDatePicker'
export { BDatePicker } from './business/BDatePicker/BDatePicker'
export { BDecodeUniCodeContainer } from './business/BDecodeUniCodeContainer/BDecodeUniCodeContainer'
export { BDevStatusPipeline } from './business/BDevStatusPipeline/BDevStatusPipeline'
export { BDiseaseItem } from './business/BDiseaseItem/BDiseaseItem'
export { BDiseaseItemWithToolTip } from './business/BDiseaseItemWithToolTip/BDiseaseItemWithToolTip'
export * from './business/BDrugAdcField'
export * from './business/BDrugField'
export { BDrugItem } from './business/BDrugItem/BDrugItem'
export { BDrugItemWithToolTip } from './business/BDrugItemWithToolTip/BDrugItemWithToolTip'
export { BDrugRadar } from './business/BDrugRadar/BDrugRadar'
export { BDrugTypeItem } from './business/BDrugTypeItem/BDrugTypeItem'
export { BDrugTypeTagItem } from './business/BDrugTypeTagItem/BDrugTypeTagItem'
export { BEditQueryDialog } from './business/BEditQueryDialog/BEditQueryDialog'
export { BEditQueryDialogWithLabel } from './business/BEditQueryDialog/BEditQueryDialogWithLabel/BEditQueryDialogWithLabel'
export { BEmailAlertV2 } from './business/BEmailAlertV2/BEmailAlertV2'
export { BEmailAlertWithSwitchV2 } from './business/BEmailAlertV2/BEmailAlertWithSwitchV2'
export { BBindEmail } from './business/BEmailAlertV2/components/BBindEmail/BBindEmail'
export { BEncryptText } from './business/BEncryptText/BEncryptText'
export { BEntityCompare } from './business/BEntityCompare/BEntityCompare'
export { BEntityPopoverLoadMore } from './business/BEntityPopoverLoadMore/BEntityPopoverLoadMore'
export { BFamilyTag } from './business/BFamilyTag/BFamilyTag'
export { BField } from './business/BField/BField'
export { FilterOrgItem } from './business/BFilter/components/biz/FilterValue/FilterOrgItem'
export { FilterOrgItemAdvance } from './business/BFilter/components/biz/FilterValue/FilterOrgItemAdvance'
export { BFilterCommonList } from './business/BFilterCommonList/BFilterCommonList'
export * from './business/BFilterNext'
export * from './business/BFreeTextSearch'
export { BHashRouterView } from './business/BHashRouterView/BHashRouterView'
export { BHeatmapIncreaseInfo } from './business/BHeatmapIncreaseInfo/BHeatmapIncreaseInfo'
export { BHeatmapIncreaseInfoNext } from './business/BHeatmapIncreaseInfo/BHeatmapIncreaseInfoNext'
export { BHighlightDom } from './business/BHighlightDom/BHighlightDom'
export { BHistoryPanel } from './business/BHistoryPanel/BHistoryPanel'
export { BHubSpotForm } from './business/BHubSpotForm/BHubSpotForm'
export { BIcon } from './business/BIcon/BIcon'
export { BImagePanel } from './business/BImagePanel/BImagePanel'
export * from './business/BKgEntrance'
export { BLabelAc } from './business/BLabelAc/BLabelAc'
export { BLazyImg } from './business/BLazyImg/BLazyImg'
export { BLazyLoadImg } from './business/BLazyLoadImg/BLazyLoadImg'
export { BLimitedMore } from './business/BLimitedMore/BLimitedMore'
export { BLS360AskMeCard } from './business/BLS360AskMeCard/BLS360AskMeCard'
export { BMechanismItem } from './business/BMechanismItem/BMechanismItem'
export { BMosaic } from './business/BMosaic/BMosaic'
export { BColumnNewsCard } from './business/BNewsCard/BColumnNewsCard'
export { BFeatureNewsCard } from './business/BNewsCard/BFeatureNewsCard'
export { BNewsletterCard } from './business/BNewsCard/BNewsletterCard'
export { BSecondaryNewsCard } from './business/BNewsCard/BSecondaryNewsCard'
export { BOptionLabel } from './business/BOptionLabel/BOptionLabel'
export { BOrganizationItem } from './business/BOrganizationItem/BOrganizationItem'
export { BOrganizationItemWithToolTip } from './business/BOrganizationItemWithToolTip/BOrganizationItemWithToolTip'
export { BOverviewPanel } from './business/BOverviewPanel/BOverviewPanel'
export { BPtCollapseDialog } from './business/BPatentCollapse/BPtCollapseDialog'
export { BPtCollapseOptions } from './business/BPatentCollapse/BPtCollapseOptions'
export { useProvidePatentCollapse } from './business/BPatentCollapse/usePatentCollapse'
export { BPatentExtensionType } from './business/BPatentExtensionType/BPatentExtensionType'
export { BPatentHeader } from './business/BPatentHeader/BPatentHeader'
export { BPatentTechnologyItem } from './business/BPatentTechnologyItem/BPatentTechnologyItem'
export { BPatentTypeItem } from './business/BPatentTypeItem/BPatentTypeItem'
export { BPdf } from './business/BPdf/BPdf'
export { BPipelineTable } from './business/BPipelineTable/BPipelineTable'
export { BRegulation } from './business/BRegulation/BRegulation'
export { BRegulationTable } from './business/BRegulationTable/BRegulationTable'
export { BReportCard } from './business/BReportCard/BReportCard'
export { BRoleTag } from './business/BRoleTag/BRoleTag'
export { BSemanticSearchBox } from './business/BSemanticSearchBox/BSemanticSearchBox'
export { BSequenceDialog, BSequenceItem } from './business/BSequence'
export { BSignatureImg } from './business/BSignatureImg/BSignatureImg'
export { BSignaturePdf } from './business/BSignaturePdf/BSignaturePdf'
export { BStackBarTooltip } from './business/BStackBarTooltip/BStackBarTooltip'
export { BTagExtraction } from './business/BTagExtraction/BTagExtraction'
export { BTagLegal } from './business/BTagLegal/BTagLegal'
export { BTargetItem } from './business/BTargetItem/BTargetItem'
export { BTextHighLight } from './business/BTextHighLight/BTextHighLight'
export * from './business/BUpgrade'
export { BUpgradeWrap } from './business/BUpgradeWrap/BUpgradeWrap'
export { BWechatBindButton } from './business/BWechatBindButton/BWechatBindButton'
export { BWechatBindCode } from './business/BWechatBindCode/BWechatBindCode'
export { BWeChatBindDialog } from './business/BWeChatBindDialog/BWeChatBindDialog'
export { BWorkspaceButton } from './business/BWorkspace/BWorkspaceButton'
export { BDevStatusPopperCard } from './business/card/BDevStatusCard/BDevStatusPopperCard'
export { BDiseaseCard } from './business/card/BDiseaseCard/BDiseaseCard'
export { BDiseaseFetchCard } from './business/card/BDiseaseCard/BDiseaseFetchCard'
export { BDiseaseIntelligentCard } from './business/card/BDiseaseCard/BDiseaseIntelligentCard'
export { BDiseasePopperCard } from './business/card/BDiseaseCard/BDiseasePopperCard'
export { BDiseasePopperListCard } from './business/card/BDiseaseCard/BDiseasePopperListCard'
export { BDiseaseTagPopperListCard } from './business/card/BDiseaseCard/BDiseaseTagPopperListCard'
export { BDiseaseWithToolTipPopperListCard } from './business/card/BDiseaseCard/BDiseaseWithToolTipPopperListCard'
export { BDrugApprovalCard } from './business/card/BDrugCard/BDrugApprovalDrug'
export { BDrugCard } from './business/card/BDrugCard/BDrugCard'
export { BDrugFetchCard } from './business/card/BDrugCard/BDrugFetchCard'
export { BDrugIntelligentCard } from './business/card/BDrugCard/BDrugIntelligentCard'
export { BDrugPopperCard } from './business/card/BDrugCard/BDrugPopperCard'
export { BDrugWithToolTipPopperListCard } from './business/card/BDrugCard/BDrugWithToolTipPopperListCard'
export { BDrugTypeIntelligentCard } from './business/card/BDrugTypeCard/BDrugTypeIntelligentCard'
export { BEntityFetchCard } from './business/card/BEntityFetchCard'
export { BEntityLoadingCard } from './business/card/BEntityLoadingCard/BEntityLoadingCard'
export { BEntityUICard } from './business/card/BEntityUICard'
export { BInvestmentCard } from './business/card/BInvestmentCard/BInvestmentCard'
export { BInvestmentPopperCard } from './business/card/BInvestmentCard/BInvestmentPopperCard'
export { BOrganizationCard } from './business/card/BOrganizationCard/BOrganizationCard'
export { BOrganizationFetchCard } from './business/card/BOrganizationCard/BOrganizationFetchCard'
export { BOrganizationIntelligentCard } from './business/card/BOrganizationCard/BOrganizationIntelligentCard'
export { BOrganizationPopperCard } from './business/card/BOrganizationCard/BOrganizationPopperCard'
export { BOrganizationWithToolTipPopperListCard } from './business/card/BOrganizationCard/BOrganizationWithToolTipPopperListCard'
export { BTargetCard } from './business/card/BTargetCard/BTargetCard'
export { BTargetFetchCard } from './business/card/BTargetCard/BTargetFetchCard'
export { BTargetIntelligentCard } from './business/card/BTargetCard/BTargetIntelligentCard'
export { BTargetPopperCard } from './business/card/BTargetCard/BTargetPopperCard'
export { detailCardTippyConfig } from './business/card/config'
export { useDiseaseFetchCard, useDrugFetchCard, useOrganizationFetchCard, useTargetFetchCard } from './business/card/useEntityFetchCard'
export { BPageDrawerLayout } from './business/layout/BPageDrawerLayout/BPageDrawerLayout'
export { BAcTag } from './business/tag/BAcTag/BAcTag'
export { BBetaTag } from './business/tag/BBetaTag/BBetaTag'
export { BDiseaseTag } from './business/tag/BDiseaseTag/BDiseaseTag'
export { BEntityTag } from './business/tag/BEntityTag/BEntityTag'
export { BInformationSourceTag } from './business/tag/BInformationSourceTag/BInformationSourceTag'
export { BManualTag } from './business/tag/BManualTag/BManualTag'
export { BNewsAnnotationTag } from './business/tag/BNewsAnnotationTag/BNewsAnnotationTag'
export { BNewsChannelTag } from './business/tag/BNewsChannelTag/BNewsChannelTag'
export { BPhaseTag } from './business/tag/BPhaseTag/BPhaseTag'
export { BStatusTag } from './business/tag/BStatusTag/BStatusTag'
export { GAnalysisDisplayField } from './ui/GAnalysisDisplayField/GAnalysisDisplayField'
export { GAnchorPanel } from './ui/GAnchor/GAnchorPanel'
export { GAnchorScroll } from './ui/GAnchor/GAnchorScroll'
export { GAnchorScrollLink, GAnchorScrollNext } from './ui/GAnchorScrollNext/index'
export { GArrowLine } from './ui/GArrowLine/GArrowLine'
export { GBackToTop } from './ui/GBackToTop/GBackToTop'
export { GBlock } from './ui/GBlock/GBlock'
export { GBlockNext } from './ui/GBlockNext/GBlockNext'
export { GChartCard } from './ui/GChartCard/GChartCard'
export { GChartTypeButton } from './ui/GChartTypeButton/GChartTypeButton'
export { GCheckList } from './ui/GCheckList/GCheckList'
export { GCollapsibleTree } from './ui/GCollapsibleTree/GCollapsibleTree'
export { GCollapsibleTreeNode } from './ui/GCollapsibleTree/GCollapsibleTreeNode'
export { GColLayout } from './ui/GColLayout/GColLayout'
export { GCountryFlag } from './ui/GCountryFlag/GCountryFlag'
export { GDelConfirm } from './ui/GDelConfirm/GDelConfirm'
export { DensityBar } from './ui/GDensityBar/densityBar'
export { GDensityBar } from './ui/GDensityBar/GDensityBar'
export { useDensityBarHub } from './ui/GDensityBar/useDensityBarHub'
export { GDescription } from './ui/GDescription/GDescription'
export { GDialog, showGDialog } from './ui/GDialog/GDialog'
export { GDialogTrigger } from './ui/GDialogTrigger/GDialogTrigger'
export { default as GDrawer, GDrawerSide, GDrawerToggle } from './ui/GDrawer'
export type { GDrawerProps, GDrawerSideProps, GDrawerTabType } from './ui/GDrawer'
export { GDrawerLayout } from './ui/GDrawerLayout/GDrawerLayout'
export { GDropdownSelect } from './ui/GDropdownSelect/GDropdownSelect'
export { GEmpty } from './ui/GEmpty/GEmpty'
export { GExplain } from './ui/GExplain/GExplain'
export { GExportDialog } from './ui/GExportDialog/GExportDialog'
export { GFilterItemTrigger } from './ui/GFilterItemTrigger/GFilterItemTrigger'
export { GFoldingContainer } from './ui/GFoldingContainer/GFoldingContainer'
export { GFunctionButton } from './ui/GFunctionButton/GFunctionButton'
export { GGroupBtn } from './ui/GGroupBtn/GGroupBtn'
export { GHeaderDrawerLayout } from './ui/GHeaderDrawerLayout/GHeaderDrawerLayout'
export { GIcon } from './ui/GIcon/GIcon'
export { GIconLink } from './ui/GIconLink/GIconLink'
export { GIncreaseTip } from './ui/GIncreaseTip/GIncreaseTip'
export { GLimited } from './ui/GLimited/GLimited'
export { GLimitedContainer } from './ui/GLimitedContainer/GLimitedContainer'
export { GLink } from './ui/GLink/GLink'
export { GListSkeleton } from './ui/GListSkeleton/GListSkeleton'
export { GLoading } from './ui/GLoading/GLoading'
export { showMask } from './ui/GMask/GMask'
export { GMiniSwitch } from './ui/GMiniSwitch/GMiniSwitch'
export { GMultiDropdown } from './ui/GMultiDropdown/GMultiDropdown'
export { GMultiDropdownPopper } from './ui/GMultiDropdownPopper/GMultiDropdownPopper'
export { GMultiDropdownPopperByPage } from './ui/GMultiDropdownPopperByPage/GMultiDropdownPopperByPage'
export { GNavMenu } from './ui/GNavMenu/GNavMenu'
export { GPagination } from './ui/GPagination/GPagination'
export { GRadio } from './ui/GRadio/GRadio'
export { GRedPoint } from './ui/GRedPoint/GRedPoint'
export { GTable } from './ui/GTable/GTable'
export { GTabs } from './ui/GTabs/GTabs'
export { GTelescopicContainer } from './ui/GTelescopicContainer/GTelescopicContainer'
export { GTextAutoLineClamp } from './ui/GTextAutoLineClamp/GTextAutoLineClamp'
export { GTimeline } from './ui/GTimeline/GTimeline'
export { GTimelineItem } from './ui/GTimeline/GTimelineItem'
export { GShortcutTooltip } from './ui/GTooltip/GShortcutTooltip'
export { GTooltip } from './ui/GTooltip/GTooltip'
export { GTranslate } from './ui/GTranslate/GTranslate'
export { GTranslateSwitch } from './ui/GTranslate/GTranslateSwitch'
export { GTree } from './ui/GTree/GTree'
Vue.use(VueCompositionAPI)
export const GLazyComponent = GLazyComponentDefault
