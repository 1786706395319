import { useLocale, useSplitData } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderPhase, renderPopoverLimited } from '@pharmsnap/shared/render'
import { IBaseCountry } from '@pharmsnap/shared/types'
import { IGTableColumnsProps } from '@pharmsnap/shared/types/component'
import { IDiseaseDevStatusItem } from '@pharmsnap/shared/types/disease'
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api'
import { BDiseaseItem, GCountryFlag, GPagination } from '../..'
import { GTable } from '../../ui/GTable/GTable'
import { BOrganizationItem } from '../BOrganizationItem/BOrganizationItem'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BDiseaseResearchStatus = defineComponent({
  name: 'BDiseaseResearchStatus',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    disease_ids: {
      required: true,
      type: Array as PropType<string[]>,
    },
    drug_id: {
      required: true,
      type: String,
    },
    tableHeight: {
      type: String,
    },
  },
  setup(props) {
    const { localeUpcase, ts } = useLocale()
    const data: Ref<IDiseaseDevStatusItem[]> = ref([])
    const loading = ref(false)
    const table = ref<any>()
    const { total, splitData, currentPage, pageSize, onCurrentPageChange } = useSplitData(data, 1, 20)
    function onCurrentPageChangeAndScrollTop(page: number) {
      onCurrentPageChange(page)
      table.value.scrollToTop()
    }
    const columns = computed<Array<IGTableColumnsProps<IDiseaseDevStatusItem>>>(() => {
      return [
        {
          label: ts('BDiseaseResearchStatus.indication'),
          renderCell(h, item) {
            if (!item.row.highest_phase.disease_id_view) {
              return EMPTY_PLACEHOLDER
            }
            return <BDiseaseItem data={item.row.highest_phase.disease_id_view}></BDiseaseItem>
          },
        },
        {
          label: ts('BDiseaseResearchStatus.org'),
          renderCell(h, item) {
            if (!item.row.highest_phase.org_master_entity_id_view) {
              return EMPTY_PLACEHOLDER
            }
            return (
              <div style="line-height: 0">
                <BOrganizationItem data={item.row.highest_phase.org_master_entity_id_view}></BOrganizationItem>
              </div>
            )
          },
        },
        {
          label: ts('BDiseaseResearchStatus.phase'),
          renderCell(h, item) {
            return renderPhase(
              {
                currentPhase: item.row.highest_phase.dev_status_view,
                beforePhase: item.row.termination_phase?.dev_status_view,
              },
              localeUpcase.value
            )
          },
        },
        {
          label: ts('BDiseaseResearchStatus.country'),
          renderCell(h, item) {
            if (!item.row.highest_phase.country_views.length) {
              return EMPTY_PLACEHOLDER
            }
            return renderPopoverLimited<IBaseCountry>(item.row.highest_phase.country_views || [], {
              limitCount: 3,
              inline: false,
              render: (item: IBaseCountry) => {
                const countryName = item.alpha_2_code || item.display_name
                return <GCountryFlag class={'mr-2'} useFullName={true} showName={true} name={countryName}></GCountryFlag>
              },
            })
          },
        },
      ]
    })
    async function fetchData() {
      loading.value = true
      const rt = await sharedCtx.service.disease.getDiseaseDevStatus({
        disease_ids: props.disease_ids,
        drug_id: props.drug_id,
      })
      if (rt.success) {
        data.value = rt.data.items || []
      }
      loading.value = false
    }
    fetchData()
    return { columns, total, splitData, currentPage, pageSize, onCurrentPageChangeAndScrollTop, loading, table }
  },
  render() {
    return (
      <div>
        <GTable ref="table" border v-ls-loading={this.loading} height={this.tableHeight} columns={this.columns} data={this.splitData}></GTable>
        <GPagination
          class="text-center mt-4"
          disabled={this.loadingTable}
          layout="prev, pager, next"
          total={this.total}
          currentPage={this.currentPage}
          hideOnSinglePage={true}
          pageSize={this.pageSize}
          {...{ on: { 'current-change': this.onCurrentPageChangeAndScrollTop } }}
        ></GPagination>
      </div>
    )
  },
})
