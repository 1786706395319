import { BApprovalCountryItem } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { defineComponent, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BApprovalCountryFetchListCard = defineComponent({
  name: 'BApprovalCountryFetchListCard',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { loading, data } = useRequest(
      {
        requestFn: sharedCtx.service.region.getBatchRegion.bind(sharedCtx.service.region),
      },
      props.ids
    )
    watch(loading, () => {
      ctx.emit('update')
    })
    return {
      loading,
      data,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {this.data?.map((item) => {
            return <BApprovalCountryItem class="mr-1 mb-1" data={item}></BApprovalCountryItem>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
