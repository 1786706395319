import '@patsnap-ui/icon/assets/solid/ResizeCollapse.svg'
import '@patsnap-ui/icon/assets/solid/ResizeFullScreen.svg'
import { BCardContainer } from '@pharmsnap/shared/components/business/BCardContainer/BCardContainer'
import { GTooltip } from '@pharmsnap/shared/components/ui/GTooltip/GTooltip'
import { ElTabPane, ElTabs } from '@pharmsnap/shared/element-ui'
import { defineComponent, PropType } from '@vue/composition-api'
import { ISettingTabItem } from '../../../types'
import { PatentSetting } from '../PatentSetting/PatentSetting'
import $classes from './AnalysisBlock.module.scss'

const EMPTY_CHART_HEIGHT = 446
export const AnalysisBlock = defineComponent({
  name: 'AnalysisBlock',
  props: {
    containerHeight: {
      type: Number,
    },
    /** 区块标题 */
    blockTitle: {
      type: String,
    },
    /** 图表标题 */
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    showFullScreen: {
      type: Boolean,
      default: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    fullScreenSettings: {
      type: Array as PropType<ISettingTabItem[]>,
    },
    fullScreenMainLayout: {
      type: String as PropType<'full' | 'left-right'>,
      default: 'left-right',
    },
  },
  setup(props) {
    return {}
  },
  methods: {
    renderHeader() {
      return (
        <div class={$classes.header}>
          <div>{this.title}</div>
          {this.showFullScreen && (
            <div onClick={() => this.$emit('toggleFullScreen', !this.isFullScreen)}>
              <PatentSetting
                mode={this.isFullScreen ? 'displayTips' : 'tooltip'}
                tips={this.isFullScreen ? this.$tc('common.collapseScreen') : this.$tc('common.fullScreen')}
                svgName={this.isFullScreen ? 'SolidResizeCollapse' : 'SolidResizeFullScreen'}
              ></PatentSetting>
            </div>
          )}
        </div>
      )
    },
    renderDesc() {
      if (this.desc) {
        return (
          <div class={[$classes.desc, this.isFullScreen ? 'mt-2' : 'mt-1']}>
            <div>{this.desc}</div>

            {this.$slots.aggregationBtn ? <div>{this.$slots.aggregationBtn}</div> : null}
          </div>
        )
      }
      return null
    },
    renderSettings() {
      if (this.$slots.settings) {
        return <div class={$classes.settings}>{this.$slots.settings}</div>
      }
      return null
    },
    renderDescAndSettings() {
      return [this.renderDesc(), this.renderSettings()]
    },
    renderBody() {
      return <div class={$classes.body}>{this.$slots.default}</div>
    },
    renderFullScreenHeader() {
      return (
        <div class={$classes.fullScreenHeader}>
          {this.renderHeader()}
          {this.renderDesc()}
        </div>
      )
    },
    renderFullScreenBody() {
      return <div class={$classes.fullScreenBody}>{this.$slots.default}</div>
    },
    renderFullScreenRight() {
      if (this.$slots.right) return <div class={$classes.fullScreenRight}>{this.$slots.right}</div>
      if (this.fullScreenSettings?.length) {
        return (
          <ElTabs value={this.fullScreenSettings[0].label} class={$classes.fullScreenRight}>
            {this.fullScreenSettings.map((tab) => {
              let tabName = <span>{tab.label}</span>
              if (tab.tips) {
                tabName = (
                  <GTooltip content={tab.tips}>
                    <span class="cursor-not-allowed">{tab.label}</span>
                  </GTooltip>
                )
              }
              return (
                <ElTabPane disabled={tab.disabled} key={tab.label} name={tab.label}>
                  <template slot="label">{tabName}</template>
                  <template slot="default">{tab.content()}</template>
                </ElTabPane>
              )
            })}
          </ElTabs>
        )
      }
    },
    renderBlock() {
      if (this.isFullScreen) {
        return (
          <BCardContainer
            emptyCls={$classes.fullScreenEmpty}
            emptyMode={true}
            border={false}
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            class={$classes.fullScreen}
            mainLayout={this.fullScreenMainLayout}
          >
            <template slot="header">{this.renderFullScreenHeader()}</template>
            <template slot="default">{this.renderFullScreenBody()}</template>
            <template slot="right">{this.renderFullScreenRight()}</template>
          </BCardContainer>
        )
      }
      return (
        <div class={$classes.block}>
          {this.blockTitle && <div class={$classes.blockTitle}>{this.blockTitle}</div>}
          <BCardContainer
            emptyCls={$classes.empty}
            emptyMode={true}
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            class={$classes.analysis}
            style={{
              height: `${!this.isLoading && this.isEmpty ? EMPTY_CHART_HEIGHT : this.containerHeight}px`,
              minHeight: `${EMPTY_CHART_HEIGHT}px`,
            }}
          >
            <template slot="header">{this.renderHeader()}</template>
            <template slot="subHeader">{this.renderDescAndSettings()}</template>
            <template slot="default">{this.renderBody()}</template>
          </BCardContainer>
        </div>
      )
    },
  },
  render() {
    return <div>{this.renderBlock()}</div>
  },
})
