import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { defineComponent, PropType } from '@vue/composition-api'
import { VNode } from 'vue/types/vnode'
import $class from './BMosaic.module.scss'

export const BMosaic = defineComponent({
  name: 'BMosaic',
  props: {
    block: {
      type: Boolean,
      default: true,
    },
    trigger_point: {
      type: String,
    },
    updateType: {
      type: String as PropType<E_UPGRADE_TYPE>,
      default: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL,
    },
    forceMosaic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const openUpgrade = () => {
      if (props.forceMosaic) return
      changeUpgrade({
        show: true,
        type: props.updateType,
        trigger_point: props.trigger_point,
      })
      ctx.emit('click')
    }

    return {
      isFreeUser,
      openUpgrade,
    }
  },
  render() {
    if (this.isFreeUser || this.forceMosaic) {
      return this.block ? (
        <div onClick={this.openUpgrade} class={$class.mosaic}>
          {this.$slots.default}
        </div>
      ) : (
        <span onClick={this.openUpgrade} class={$class.mosaic}>
          {this.$slots.default}
        </span>
      )
    }

    return this.$slots.default as unknown as VNode
  },
})
