import { defineComponent, PropType } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../config'
import { BDiseaseFetchListCard } from './BDiseaseFetchListCard'

export const BDiseasePopperListCard = defineComponent({
  name: 'BDiseasePopperListCard',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  render() {
    return (
      <PtPopper data={{ ids: this.ids }} tippyConfig={getAsyncListTippyConfig()} component={BDiseaseFetchListCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
