import { E_AGGREGATION_TYPE, IAggregationData, IAggregationItem, IQueryItem } from '@patsnap/synapse_common_interface'
import { BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { useChart, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { pickDisplayNameByLocale } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, PropType, watch } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import type { TableOptions } from 'vxe-table'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import {
  checkAggDataIsEmpty,
  countColumnFormatter,
  countRowFormatter,
  generatePatentAggParams,
  getChartHeight,
  getGrantedFilterItem,
  getHorizontalBarOptions,
} from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentStatusField } from '../PatentStatusField/PatentStatusField'

export const TopAuthorities = defineComponent({
  name: 'TopAuthorities',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const { locale } = useLocale()
    const ins = getCurrentInstance()
    const { isScrollInView, handleScrollInView } = useLazyComponent()

    const params = computed(() => {
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          aggregation_field: 'COUNTRY',
          limit: 10,
          sort_type: 'count',
          order: 'desc',
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation)
    })

    const {
      isLoading,
      isEmpty,
      isFullScreen,
      data: aggData,
      selectedPatentStatus,
      selectedField,
      changePatentStatus,
      getChartData,
      toggleFullScreen,
      toggleSelectedField,
    } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getAggregation.bind(sharedCtx.service.patent),
      checkEmptyFn: checkAggDataIsEmpty,
    })

    const chartData = computed(() => {
      if (checkAggDataIsEmpty(aggData.value)) {
        return []
      }
      return (aggData.value as IAggregationData).aggregation_result[0].items.map((item) => ({
        ...item,
        name: pickDisplayNameByLocale(item, locale.value),
      }))
    })
    const { onClickChart } = usePatentChartDrill()
    const handleDrill = (tooltipData: any) => {
      const extraFilter: IQueryItem[] = [
        {
          type: 'field',
          fields: ['COUNTRY'],
          value: [
            {
              type: 'text',
              value: tooltipData.key,
              display_name_cn: tooltipData.display_name_cn,
              display_name_en: tooltipData.display_name_en,
            },
          ],
        },
      ]
      if (selectedPatentStatus.value === 'granted') {
        extraFilter.push(getGrantedFilterItem())
      }
      onClickChart({ queryState: props.queryService.state, extraFilter })
    }

    const handleBarDrill = (params: any) => {
      handleDrill({ key: params.value.key, display_name_en: params.value.display_name_en, display_name_cn: params.value.display_name_cn })
    }
    const chartOption = computed<BasicHorizontalBarChart>(() => {
      const name = selectedPatentStatus.value === 'application' ? ins?.proxy.$tc('patentsApplication') : ins?.proxy.$tc('patentsGranted')
      return getHorizontalBarOptions({
        source: chartData.value,
        xAxisName: name,
      })
    })
    const chartContainerHeight = computed(() => getChartHeight(chartData.value))

    const chartConfig = {
      autoResize: true,
      registerEvent: (instance: EChartsType) => {
        instance.on('click', handleBarDrill)
      },
    }
    const { chartContainer } = useChart(chartOption, chartConfig)
    const { chartContainer: fullScreenChartContainer } = useChart(chartOption, chartConfig)

    watch(
      () => [isScrollInView.value, props.queryService.state.query, props.queryService.state.collapse, selectedPatentStatus.value],
      () => {
        if (isScrollInView.value) getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      locale,
      chartContainer,
      chartData,
      isLoading,
      chartContainerHeight,
      handleScrollInView,
      fullScreenChartContainer,
      selectedField,
      isFullScreen,
      selectedPatentStatus,
      toggleFullScreen,
      changePatentStatus,
      toggleSelectedField,
      isEmpty,
    }
  },
  methods: {
    getFullScreenColumnConfig() {
      const fullScreenColumnConfig = []
      fullScreenColumnConfig.push({
        field: 'col0',
        width: '172px',
        fixed: 'left',
      })
      for (let i = 0; i < this.chartData.length; i++) {
        fullScreenColumnConfig.push({
          field: `col${i + 1}`,
          formatter: countRowFormatter,
          minWidth: 100,
        })
      }
      return fullScreenColumnConfig
    },
    getFullScreenTableData(columnConfig: Array<{ field: 'display_name_en' | 'display_name_cn' | 'count'; title: string }>) {
      const fullScreenTableData = []
      for (let i = 0; i < columnConfig.length; i++) {
        const obj: Record<string, string> = {}
        this.chartData.forEach((item, index) => {
          obj[`col${index + 1}`] = String(item[columnConfig[i].field])
        })
        obj.col0 = columnConfig[i].title
        fullScreenTableData.push(obj)
      }
      return fullScreenTableData
    },
    renderPatentStatus(isFullScreen: boolean) {
      return (
        <PatentStatusField
          isFullScreen={isFullScreen}
          value={this.selectedPatentStatus}
          onChangePatentStatus={this.changePatentStatus}
        ></PatentStatusField>
      )
    },
    renderTable() {
      let vxeProps: TableOptions = {
        border: true,
        resizable: true,
        align: 'left',
      }
      const columnConfig: Array<{
        field: 'display_name_en' | 'display_name_cn' | 'count'
        title: string
        formatter?: ({ cellValue }: { cellValue: any }) => any
      }> = [
        { field: this.locale === 'en' ? 'display_name_en' : 'display_name_cn', title: this.$tc('topAuthorities.authorities') },
        {
          field: 'count',
          title: this.selectedPatentStatus === 'application' ? this.$tc('patentsApplication') : this.$tc('patentsGranted'),
          formatter: countColumnFormatter,
        },
      ]
      const fullScreenColumnConfig = this.getFullScreenColumnConfig()
      const fullScreenTableData = this.getFullScreenTableData(columnConfig)

      if (this.isFullScreen) {
        vxeProps = {
          ...vxeProps,
          showHeader: false,
          showOverflow: 'title',
          columns: fullScreenColumnConfig,
          data: fullScreenTableData,
        }
      } else {
        vxeProps = {
          ...vxeProps,
          maxHeight: '100%',
          columns: columnConfig,
          data: this.chartData,
        }
      }
      return <vxe-grid size="small" class={[this.isFullScreen ? $style.fullScreenTable : null]} props={vxeProps}></vxe-grid>
    },
    renderContent() {
      if (this.selectedField === 'chart') {
        return <div ref="chartContainer" class="w-full h-full"></div>
      }
      return this.renderTable()
    },
    renderAnalysis() {
      const containerHeight = this.chartContainerHeight + 146
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            isLoading={this.isLoading}
            title={this.$tc('topAuthorities.title')}
            desc={this.$tc('topAuthorities.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            containerHeight={containerHeight}
            isEmpty={this.isEmpty}
            class="mt-4"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="horizonBar"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              {this.renderPatentStatus(false)}
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            isLoading={this.isLoading}
            title={this.$tc('topAuthorities.title')}
            desc={this.$tc('topAuthorities.desc')}
            isFullScreen
            isEmpty={this.isEmpty}
            onToggleFullScreen={this.toggleFullScreen}
            fullScreenSettings={[{ label: this.$tc('data'), content: () => this.renderPatentStatus(true) }]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div ref="fullScreenChartContainer" class={$style.fullScreenChart} style={{ height: this.chartContainerHeight }}></div>
                <div class="mt-8 flex-shrink-0">{this.renderTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
