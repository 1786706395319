/**
 * 药物类型根节点id
 */
export const DRUG_TYPE_ROOT_IDS = [
  '1b5ad16f8e6946e29ab70ca460d3fe90',
  '06bc75afc2f44364a9663730fa37c37a',
  '3b73c4ecbe95459da252b83f9b630015',
  'd44cc4ee34c4454d9d91c94b2d902cab',
  '679157c100db499cb44a3d862eba6211',
  '20a3b1b0beb74d9fb8649b0afa104f67',
]
