import { defineComponent, onUnmounted, PropType, watch } from '@vue/composition-api'
import { DensityBar, DensityBarOptions } from './densityBar'
export const GDensityBar = defineComponent({
  name: 'GDensityBar',
  props: {
    markSelector: {
      type: String,
      required: true,
    },
    el: {
      type: Element,
    },
    width: {
      type: Number,
    },
    right: {
      type: Number,
    },
    resizeDebounce: {
      type: Number,
    },
    zIndex: {
      type: Number,
      default: 8,
    },
    observeMutation: {
      type: Boolean,
      default: true,
    },
    observeResize: {
      type: Boolean,
      default: true,
    },
    markHeight: {
      type: Number,
    },
    computeMarkColor: {
      type: Function as PropType<DensityBarOptions['computeMarkColor']>,
    },
    showSlider: {
      type: String as PropType<DensityBarOptions['showSlider']>,
    },
    sliderMinHeight: {
      type: Number,
    },
  },
  setup(props) {
    const densityBar = new DensityBar({
      markSelector: props.markSelector,
      width: props.width,
      right: props.right,
      resizeDebounce: props.resizeDebounce,
      zIndex: props.zIndex,
      observeMutation: props.observeMutation,
      observeResize: props.observeResize,
      markHeight: props.markHeight,
      computeMarkColor: props.computeMarkColor,
      showSlider: props.showSlider,
      sliderMinHeight: props.sliderMinHeight,
    })
    watch(
      () => props.el,
      () => {
        props.el && densityBar.render(props.el as HTMLElement)
      },
      {
        immediate: true,
      }
    )
    onUnmounted(() => {
      densityBar.dispose()
    })

    return () => {
      return <div></div>
    }
  },
})
