import { getService } from '@pharmsnap/pharmsnap-web/service/service'
import { getLocaleLang } from '@pharmsnap/pharmsnap-web/utils/business/locale'
import { closeAllToast, downloadFileByUrl, showSingleToast } from 'pharmsnapMF_shared/utils'
export async function downloadFileEvent(params: { url: string; file_name?: string }) {
  if (!params.url) {
    return
  }
  const service = getService()
  const rt = await service.search.logoBatchSignature([params.url], 'phs_report')
  if (rt.length) {
    const signPath = rt[0]
    if (signPath) {
      showSingleToast({
        duration: 1000000,
        message:
          getLocaleLang() === 'cn'
            ? '系统正在为您自动下载报告，请及时关注您浏览器的下载列表'
            : `The system is in the process of automatically downloading the report for you, please keep an eye on your browser's download list.`,
        type: 'success',
      })
      try {
        await downloadFileByUrl(signPath, params.file_name || `${Date.now()}.pdf`)
      } catch (error) {
        closeAllToast()
      }
      closeAllToast()
    }
  }
}
