import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
async function loadHsFormsSdk() {
  return new Promise<void>((resolve) => {
    if (window.hbspt) {
      return resolve()
    }
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.addEventListener('load', () => {
      resolve()
    })
    document.body.appendChild(script)
  })
}
interface FormSubmitData {
  name: string
  value: string
}
interface FormSubmittedData {
  conversionId: string
  formGuid: string
  submissionValues: Record<string, string>
}
export const BHubSpotForm = defineComponent({
  name: 'BHubSpotForm',
  props: {
    formId: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    const uuid = Math.random().toString(36).substring(7)
    const loading = ref(false)
    const formDomId = computed(() => {
      return `hubspotForm${uuid}`
    })
    function hsFormCallbackMessageHandler(event: MessageEvent) {
      if (event.data.type === 'hsFormCallback') {
        if (event.data.eventName === 'onFormSubmit') {
          emit('submit', event.data)
          console.log('onSubmit', event.data as { data: FormSubmitData[] })
        }
        if (event.data.eventName === 'onFormSubmitted') {
          emit('submitted', event.data as { data: FormSubmittedData })
          console.log('onSubmitted', event.data)
        }
      }
    }
    onMounted(() => {
      loading.value = true
      loadHsFormsSdk().then(() => {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '2149185', // HubSpot 中的账户的唯一标识符
          formId: props.formId,
          target: `#${formDomId.value}`,
        })
        loading.value = false
        window.addEventListener('message', hsFormCallbackMessageHandler)
      })
    })
    onUnmounted(() => {
      window.removeEventListener('message', hsFormCallbackMessageHandler)
    })
    return { formDomId, loading }
  },
  render() {
    return <div id={this.formDomId} v-ls-loading={this.loading}></div>
  },
})
