import axios from 'axios'
import { showSingleToast } from 'pharmsnapMF_shared/utils'
const versionInfo = 'VERSION_INFO'
interface IVersionInfo {
  version: string
  buildTime: string
}

export function checkVersion() {
  if (process.env.NODE_ENV === 'production') {
    window.setInterval(async () => {
      let version = ''
      // 判断当前时间和localStorage更新时间是否超过10分钟，如果没超过就不检查版本
      const versionInfoStr = localStorage.getItem(versionInfo)
      if (versionInfoStr) {
        const versionInfoObj: IVersionInfo = JSON.parse(versionInfoStr)
        const currentTime = new Date().getTime()
        const buildTime = Number(versionInfoObj.buildTime)
        if (currentTime - buildTime < 10 * 60 * 1000) {
          version = versionInfoObj.version
        } else {
          version = await getVersion()
        }
      }
      if (version && version !== process.env.VUE_APP_BUILD_VERSION) {
        const message = 'New version launched, please wait for reloading.'
        showSingleToast({ message, type: 'warning' })
        setTimeout(() => {
          location.reload()
        }, 3000)
      }
    }, 10 * 60 * 1000)
  }
}

async function getVersion(): Promise<string> {
  const script = `${process.env.BASE_URL}static/version/version.json?${Date.now()}`
  try {
    const content = await axios.get(script)

    const versionData: IVersionInfo = <IVersionInfo>content.data
    if (versionData) {
      versionData.buildTime = new Date().getTime().toString()
      localStorage.setItem(versionInfo, JSON.stringify(versionData))
      return versionData.version
    }

    return ''
  } catch (error) {
    return ''
  }
}
