import { ISearchParams } from '@patsnap/synapse_common_interface'
import { IApprovalStatus, IDrugApproval, IDrugApprovalDetail } from '@pharmsnap/shared/types/approval'
import { ISearchResult } from '@pharmsnap/shared/types/search'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class ApprovalApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async search(params: Omit<ISearchParams, 'data_type'>): Promise<IHttpWrap<ISearchResult<IDrugApproval>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/approval/search'),
      cache: true,
    }).then((res: IHttpWrap<ISearchResult<IDrugApproval>>) => {
      if (res.success) {
        res.data.items?.forEach((item) => {
          if (item.product_data_source?.name_cn === '橙皮书' || item.product_data_source?.name_en === 'Orange Book') {
            item.product_data_source.name_cn = 'CDER'
            item.product_data_source.name_en = 'CDER'
          }
        })
      }

      return res
    })
  }
  async getFDAApprovalDetail(id: string): Promise<IHttpWrap<IDrugApprovalDetail>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/approval/${id}`),
      cache: true,
    })
  }
  async getEMAApprovalDetail(id: string): Promise<IHttpWrap<IDrugApprovalDetail>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/approval/ema/${id}`),
      cache: true,
    })
  }
  async getDataStatus(drugId: string): Promise<IHttpWrap<IApprovalStatus>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/approval/${drugId}/data/status`),
      cache: true,
    })
  }
}
