/* eslint-disable @typescript-eslint/no-unused-vars */
import { BarSeriesOption, SingleAxisOption, XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { merge } from 'lodash'
import { AXIS_LINE_COLOR, AXIS_LINE_LABEL_COLOR, AXIS_NAME_COLOR, AXIS_NAME_LINE_HEIGHT, AXIS_X_NAME_GAP, AXIS_Y_NAME_GAP } from '../../constants'
import { ECOption } from '../../core/plot'

const defaultAxis = (options: ECOption): Omit<XAXisOption, 'mainType'> => {
  return {
    show: true,
    axisLine: {
      show: true,
      lineStyle: {
        color: AXIS_LINE_COLOR,
      },
    },
    axisLabel: {
      color: AXIS_LINE_LABEL_COLOR,
    },
    nameTextStyle: {
      color: AXIS_NAME_COLOR,
      lineHeight: AXIS_NAME_LINE_HEIGHT,
    },
  }
}

const defaultBarSeries = (options: ECOption): BarSeriesOption => {
  return {
    barMaxWidth: 20,
    barMinWidth: 5,
    barMinHeight: 1,
  }
}

const defaultSeries = (options: ECOption): ECOption['series'] => {
  return {
    cursor: 'auto',
  }
}

export function getDefaultRectXAxis(options: ECOption): XAXisOption {
  return merge(defaultAxis(options), { nameLocation: 'middle', nameGap: AXIS_X_NAME_GAP, splitLine: { show: false } } as XAXisOption)
}

export function getDefaultSingleAxis(options: ECOption): SingleAxisOption {
  return merge(defaultAxis(options), { nameLocation: 'middle', nameGap: AXIS_X_NAME_GAP, splitLine: { show: true } } as SingleAxisOption)
}

export function getDefaultRectYAxis(options: ECOption): YAXisOption {
  return merge(defaultAxis(options), {
    nameLocation: 'end',
    nameGap: AXIS_Y_NAME_GAP,
    splitLine: { show: true, lineStyle: { color: AXIS_LINE_COLOR, type: 'dashed' } },
  } as YAXisOption)
}

export function getDefaultBarSeries(options: ECOption): BarSeriesOption {
  return defaultBarSeries(options)
}

export function getDefaultSeries(options: ECOption): ECOption['series'] {
  return defaultSeries(options)
}
