import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { decryptNum } from '../../encrypt/encrypt'
import { injectOrgName, transformGroupAggregationSingleResultByMap } from './base'

export const fundedFieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue> = {
  open_ts: (item, xCorrelationId) => {
    if (item.other_info && typeof item.other_info.sum_amount !== 'undefined' && xCorrelationId) {
      item.other_info = {
        ...item.other_info,
        sum_amount: decryptNum(String(item.other_info.sum_amount), xCorrelationId),
      }
    }
    return item
  },
  master_normalized_award_by_id: injectOrgName,
}

export function transformFundedAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, fundedFieldTransformFnMap, xCorrelationId)
}
