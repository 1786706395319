import { E_AGGREGATION_TYPE, IAggregationData, IAggregationItem, IAggregationValue, IQuery, IQueryItem } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { getChartColor } from '@pharmsnap/shared/chart'
import { BasicLineChart } from '@pharmsnap/shared/chart/type'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { GLink } from '@pharmsnap/shared/components/ui/GLink/GLink'
import { useChart, useLocale } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { getLangDegraded, getYearRange } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, h, onBeforeUnmount, PropType, reactive, ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import { EChartsType } from 'echarts/core'
import { findIndex, findLastIndex, inRange, orderBy } from 'lodash'
import Vue from 'vue'
import { GridColumns } from 'vxe-table'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import { checkAggDataIsEmpty, generatePatentAggParams } from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentSetting } from '../../ui/PatentSetting/PatentSetting'
import { PatentCliffTooltip } from '../PatentCliffTooltip/PatentCliffTooltip'
import { TimeRangeField } from '../TimeRangeField/TimeRangeField'
import $classes from './PatentCliff.module.scss'

const defaultCustomTime = [dayjs().subtract(19, 'years').year(), dayjs().add(20, 'years').year()]
const customTimeRange = [1780, dayjs().add(40, 'years').year()]
export const PatentCliff = defineComponent({
  name: 'PatentCliff',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let vm: any

    const ins = getCurrentInstance()
    const { locale } = useLocale()
    const { isScrollInView, handleScrollInView } = useLazyComponent()
    const { onClickChart } = usePatentChartDrill()

    const selectedTime = ref('custom')
    const selectedTimeValue = ref<number[]>(defaultCustomTime)
    const displayCustomTime = ref(defaultCustomTime)
    const tooltipData = reactive({
      year: '',
      aggregation_result: [],
      count: 0,
    })

    const chartData = computed(() => {
      if (checkAggDataIsEmpty(aggData.value)) {
        return []
      }
      const items = (aggData.value as IAggregationData).aggregation_result[0].items
      if (selectedTime.value === 'all') {
        const firstNonzeroIndex = findIndex(items, (item) => item.count > 0)
        const lastNonzeroIndex = findLastIndex(items, (item) => item.count > 0)
        return items.slice(firstNonzeroIndex, lastNonzeroIndex + 1)
      }

      const [fromYear, toYear] = selectedTimeValue.value
      return items.filter((item) => inRange(Number(item.key), fromYear, toYear + 1))
    })
    const tableColumn = computed<GridColumns[]>(() => [
      {
        title: ins?.proxy.$tc('patentCliff.estimatedExpiryYear'),
        field: 'key',
      },
      {
        title: ins?.proxy.$tc('patentCliff.expiringPatents'),
        field: 'count',
        slots: {
          default: ({ row }) => [toThousands(row.count)],
        },
      },
      {
        title: ins?.proxy.$tc('common.drug'),
        field: 'drugName',
        slots: {
          default: ({ row }) => [row.drugName || EMPTY_PLACEHOLDER],
        },
      },
      {
        title: ins?.proxy.$tc('patentCliff.expiringKeyPatents'),
        field: 'drugCount',
        slots: {
          default: ({ row }) => [toThousands(row.drugCount) || EMPTY_PLACEHOLDER],
        },
      },
    ])
    const tableData = computed(() => {
      const tableList: Array<IAggregationValue & { drugName?: string; drugCount?: number; rowSpan: number }> = []
      chartData.value.forEach((data) => {
        const isAggDrugEmpty = checkAggDataIsEmpty(data)
        if (isAggDrugEmpty) {
          tableList.push({
            ...data,
            rowSpan: 1,
          })
        } else {
          const drugList = orderBy(data.aggregation_result[0].items, ['display_name_en'], ['asc'])
          drugList.forEach((drug, index) => {
            tableList.push({
              ...data,
              drugName: getLangDegraded({ name_en: drug.display_name_en, name_cn: drug.display_name_cn }, locale.value),
              drugCount: drug.count,
              rowSpan: index === 0 ? drugList.length : 0,
            })
          })
        }
      })
      return tableList
    })

    const params = computed(() => {
      const [start, end] = customTimeRange
      const {
        query: { must, filter = [] },
      } = props.queryService.state

      const customQuery: IQuery = {
        must,
        // 返回指定年份内的数据，即使数据为0也返回
        filter: filter.concat({
          type: 'field',
          fields: ['EXDT_Y'],
          value: [
            {
              type: 'range',
              from: start,
              to: end,
              display_name_cn: '',
              display_name_en: '',
              include_upper: true,
            },
          ],
        }),
        type: 'group',
      }

      const { from, to } = getYearRange(start, end)
      const aggregationTimeRange = { extended_bounds_from: from, extended_bounds_to: to }
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.DATE_HISTOGRAM_YEAR,
          aggregation_field: 'EXDT_Y',
          limit: end - start + 1,
          sort_type: 'index',
          order: 'asc',
          ...aggregationTimeRange,
          sub_aggregation: [
            {
              aggregation_type: E_AGGREGATION_TYPE.TERMS,
              aggregation_field: 'PHS_MACROMOLCOMPOUND_DRUG_ID',
              limit: 100,
            },
          ],
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation, customQuery)
    })

    const {
      isFullScreen,
      popoverVisible,
      selectedField,
      isLoading,
      isEmpty,
      data: aggData,
      toggleSelectedField,
      toggleFullScreen,
      togglePopoverVisible,
      getChartData,
    } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getAggregation.bind(sharedCtx.service.patent),
      checkEmptyFn: checkAggDataIsEmpty,
    })

    const applyTime = (data: { type: string; value: string | number[] }) => {
      const { type, value } = data
      selectedTime.value = type
      if (data.type === 'all') {
        selectedTimeValue.value = [1780, dayjs().year()]
      } else {
        selectedTimeValue.value = value as number[]
      }

      if (data.type === 'custom') {
        displayCustomTime.value = value as number[]
      }
    }

    const handleDrugDrill = (params: { year: string; drug: IAggregationValue }) => {
      const { year, drug } = params
      const customQuery: IQuery = {
        type: 'group',
        must: [
          {
            type: 'field',
            fields: ['PHS_MACROMOLCOMPOUND_DRUG_ID'],
            value: [
              {
                ...drug,
                type: 'text',
                value: drug.key,
              },
            ],
          },
          {
            type: 'field',
            fields: ['PHS_CORE_TYPE_ID'],
            value: [
              {
                type: 'text',
                value: '5d7f1bfbf0df38a881106caed3bff0c5',
                display_name_cn: '大分子',
                display_name_en: 'Product Macromolecule',
                search_strategy: 'ID',
              },
              {
                type: 'text',
                value: '2633e3e2ab413e4d9a326d2c46fb7d62',
                display_name_cn: '化合物',
                display_name_en: 'Product Compound',
                search_strategy: 'ID',
              },
            ],
          },
          {
            type: 'field',
            fields: ['EXDT_Y'],
            value: [{ display_name_cn: year, display_name_en: year, type: 'text', value: year }],
          },
        ],
      }
      onClickChart({ queryState: props.queryService.state, extraFilter: [], customQuery })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDrill = (params: any) => {
      const {
        value: { key, count },
      } = params
      if (count > 0) {
        const extraFilter: IQueryItem[] = [
          {
            type: 'field',
            fields: ['EXDT_Y'],
            value: [{ display_name_cn: key, display_name_en: key, type: 'text', value: key }],
          },
        ]
        onClickChart({ queryState: props.queryService.state, extraFilter })
      }
    }

    const renderExpiringCount = (params: { year: string; count: number }) => {
      const { year, count } = params
      return (
        <span class={count > 0 && $classes.tooltipCount} onClick={() => handleDrill({ value: { key: year, count } })}>
          {toThousands(count || 0)}
        </span>
      )
    }

    const renderRelatedDrug = (params: { year: string; drug: IAggregationValue }) => {
      const { year, drug } = params
      return (
        <div class={$classes.relatedDrug} key={drug.key}>
          <GLink
            href={sharedCtx.router.generatorDrugPath(drug.key)}
            name={getLangDegraded({ name_en: drug.display_name_en, name_cn: drug.display_name_cn }, locale.value)}
          ></GLink>
          <div class="ml-2 mt-0.5 flex-shrink-0 cursor-pointer" onClick={() => handleDrugDrill({ year, drug })}>
            (<span class={drug.count > 0 && $classes.tooltipCount}>{toThousands(drug.count)}</span>)
          </div>
        </div>
      )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function toolTipFormatter(params: any) {
      const value = params[0].value
      const { key: year, aggregation_result, count } = value
      tooltipData.year = year
      tooltipData.aggregation_result = aggregation_result
      tooltipData.count = count

      if (!vm) {
        vm = new Vue({
          i18n: ins?.proxy.$i18n,
          render() {
            return h(PatentCliffTooltip, {
              props: tooltipData,
              scopedSlots: {
                expiringCount: renderExpiringCount,
                relatedDrug: renderRelatedDrug,
              },
            })
          },
        })
        vm.$mount()
      }
      return vm.$el
    }

    const chartOption = computed<BasicLineChart>(() => {
      const opt: BasicLineChart = {
        type: 'line',
        color: getChartColor(1),
        grid: {
          containLabel: true,
          top: 40,
          left: 20,
          right: 100,
          bottom: 24,
        },
        dataset: {
          dimensions: [
            {
              name: 'key',
            },
            {
              name: 'count',
            },
          ],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          source: chartData.value,
        },
        xAxis: {
          type: 'category',
          name: ins?.proxy.$tc('patentCliff.estimatedExpiryYear') || '',
          axisLabel: {
            rotate: -45,
          },
          axisTick: {
            show: false,
          },
          nameGap: 40,
        },
        yAxis: {
          name: ins?.proxy.$tc('patents') || '',
          dataType: { valueType: 'count' },
        },
        tooltip: {
          trigger: 'axis',
          enterable: true,
          appendToBody: true,
          hideDelay: 500,
          padding: 0,
          formatter: toolTipFormatter,
          position(point: number[], params: any, dom: any, rect: any, size: any) {
            const [x, y] = point
            const {
              contentSize: [contentWidth],
              viewSize: [viewWidth],
            } = size

            if (contentWidth + x > viewWidth) return [x - contentWidth - 8, y]
            return [x + 8, y]
          },
        },
        series: [
          {
            encode: {
              x: 'key',
              y: 'count',
            },
            cursor: 'pointer',
          },
        ],
      }

      opt.series = opt.series.map((item) => ({
        ...item,
        smooth: true,
        symbol: (data: IAggregationData) => {
          const isEmpty = checkAggDataIsEmpty(data)
          return isEmpty ? 'emptyCircle' : 'circle'
        },
        symbolSize: (data: IAggregationData) => {
          const isEmpty = checkAggDataIsEmpty(data)
          return isEmpty ? 4 : 8
        },
        itemStyle: {
          color: (data: { data: IAggregationData }) => {
            const isEmpty = checkAggDataIsEmpty(data.data)
            return isEmpty ? '#1976D2' : '#D11A32'
          },
        },
        emphasis: {
          lineStyle: {
            width: 2,
          },
        },
      }))
      return opt
    })

    const chartConfig = {
      autoResize: true,
      registerEvent: (instance: EChartsType) => {
        instance.on('click', handleDrill)
      },
    }
    const { chartContainer } = useChart(chartOption, chartConfig)
    const { chartContainer: fullScreenChartContainer } = useChart(chartOption, chartConfig)

    const mergeCellFun = ({ row, columnIndex }: { row: { rowSpan: number }; columnIndex: number }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        return { rowspan: row.rowSpan, colspan: row.rowSpan > 0 ? 1 : 0 }
      }
    }

    watch(
      () => [isScrollInView.value, props.queryService.state.query, props.queryService.state.collapse],
      () => {
        if (isScrollInView.value) getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    onBeforeUnmount(() => {
      if (vm) {
        vm.$destroy()
      }
      vm = null
    })

    return {
      locale,
      isLoading,
      isEmpty,
      popoverVisible,
      selectedField,
      isFullScreen,
      selectedTime,
      displayCustomTime,
      chartContainer,
      chartData,
      tableData,
      tableColumn,
      fullScreenChartContainer,
      toggleFullScreen,
      togglePopoverVisible,
      toggleSelectedField,
      handleScrollInView,
      applyTime,
      mergeCellFun,
    }
  },
  methods: {
    renderLegend() {
      return (
        <div class={[$classes.legend, this.isFullScreen && 'right-2']}>
          <div class={$classes.legendItem}>{this.$t('patentCliff.estimatedExpiringPatents')}</div>
          <div class={$classes.legendItem}>{this.$t('patentCliff.keyPatentExpiringDrugs')}</div>
        </div>
      )
    },
    renderTimeRange() {
      return (
        <TimeRangeField
          title={this.$tc('time.range')}
          selected={this.selectedTime}
          customTimeRange={customTimeRange}
          defaultCustomTime={this.displayCustomTime}
          isFullScreen={this.isFullScreen}
          onCancel={() => this.togglePopoverVisible(false)}
          onApply={(data: { type: string; value: string | number[] }) => {
            this.togglePopoverVisible(false)
            this.applyTime(data)
          }}
        ></TimeRangeField>
      )
    },
    renderTable() {
      const disableScroll = {
        enabled: false,
      }
      return (
        <vxe-grid
          scrollX={disableScroll}
          scrollY={disableScroll}
          max-height={this.isFullScreen ? '433' : '100%'}
          border={true}
          showHeader
          size="small"
          data={this.tableData}
          columns={this.tableColumn}
          span-method={this.mergeCellFun}
        ></vxe-grid>
      )
    },
    renderContent() {
      if (this.selectedField === 'chart') {
        return (
          <div class={$classes.chartContainer}>
            <div ref="chartContainer" class="w-full h-full"></div>
            {this.renderLegend()}
          </div>
        )
      }
      return this.renderTable()
    },
    renderAnalysis() {
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            title={this.$tc('patentCliff.title')}
            desc={this.$tc('patentCliff.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            containerHeight={540}
            class="mt-4"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="line"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              <PatentSetting visible={this.popoverVisible} onChangeVisible={this.togglePopoverVisible} tips={this.$tc('common.setting')}>
                {this.renderTimeRange()}
              </PatentSetting>
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            isLoading={this.isLoading}
            isEmpty={this.isEmpty}
            title={this.$tc('patentCliff.title')}
            desc={this.$tc('patentCliff.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            isFullScreen
            fullScreenSettings={[{ label: this.$tc('data'), content: this.renderTimeRange }]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div class={[$style.fullScreenChart, 'relative']}>
                  <div ref="fullScreenChartContainer" class="w-full h-96"></div>
                  {this.renderLegend()}
                </div>
                <div class="mt-8">{this.renderTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
