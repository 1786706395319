/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IAggregationData, IAggregationParams, IQuery } from '@patsnap/synapse_common_interface'
import { GRadio } from '@pharmsnap/shared/components'
import { transformPhase2BarItem, useBasicBarCountChart } from '@pharmsnap/shared/composition/useBasicBarChart'
import { useLocale } from '@pharmsnap/shared/composition/useLocale'
import { useBasicBarChartNavList } from '@pharmsnap/shared/composition/useNavListChart'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { INavListType, IUseBarCount } from '@pharmsnap/shared/types'
import { getInactiveDrugDevStatusField } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, PropType, ref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $classes from './BPhaseCountChart.module.scss'

export const BPhaseCountChart = defineComponent({
  name: 'BPhaseCountChart',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const ins = getCurrentInstance()
    const dataSource = ref<IAggregationData>()
    const localeData = useLocale()
    const loading = ref(true)

    const phaseItems = computed<IUseBarCount[]>(() => transformPhase2BarItem(localeData.locale.value, dataSource.value))

    const dataType = ref<'drug' | 'dev_status'>('drug')
    const navList = computed(() => ({ drug: 'drug', dev_status: 'drug_group_dev_status' }[dataType.value]))
    const getDevStatusInfo = async () => {
      const aggParams: IAggregationParams = {
        // @ts-ignore
        data_type: dataType.value,
        query: { ...props.queryService.state.query, type: 'group' },
        aggregation: [],
      }
      loading.value = true
      const res = await sharedCtx.service.drug.getDrugDiseaseHighestPhaseDiseaseList(aggParams)
      loading.value = false
      if (res.success) {
        dataSource.value = res.data
      }
    }

    const navListQuery = computed<IQuery>(() => ({ ...props.queryService.state.query, type: 'group' }))

    const { onClickBar: onClickPhaseCountChart } = useBasicBarChartNavList({
      navList: navList as MaybeRef<INavListType>,
      query: navListQuery,
      appendTo: 'filter',
      adjustQueryField: (queryField) => {
        const value = queryField.value
        const foundIndex = value.findIndex((i) => i.type === 'text' && i.value === '-1')
        if (foundIndex !== -1) {
          value.splice(foundIndex, 1)
          value.push(...getInactiveDrugDevStatusField('GLOBAL_HIGHEST_DEV_STATUS').value)
        }

        return queryField
      },
    })

    const message = computed(() => {
      return {
        drug: {
          title: ins?.proxy.$tc('Analysis.HighestPhase'),
          desc: ins?.proxy.$tc('Analysis.HighestPhaseDesc'),
          xAxisName: ins?.proxy.$tc('Analysis.HighestPhase'),
          yAxisName: ins?.proxy.$tc('Analysis.No_Of_Drug'),
        },
        dev_status: {
          title: ins?.proxy.$tc('Analysis.Phase'),
          desc: ins?.proxy.$tc('Analysis.PhaseDesc'),
          xAxisName: ins?.proxy.$tc('Analysis.Phase'),
          yAxisName: ins?.proxy.$tc('Analysis.No_Of_Drug'),
        },
      }[dataType.value]
    })

    const { chartContainer, isEmpty } = useBasicBarCountChart(phaseItems, {
      xAxisName: () => message.value.xAxisName || '',
      yAxisName: () => message.value.yAxisName || '',
      clickAble: true,
      registerEvent: (instance) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        instance.on('click', (param) => onClickPhaseCountChart(param))
      },
    })

    watch(
      [() => props.queryService.state.query, () => dataType.value],
      () => {
        getDevStatusInfo()
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      message,
      chartContainer,
      isEmpty,
      loading,
      dataType,
    }
  },
  render() {
    return (
      <div class={$classes.container}>
        <h1 class={$classes.containerHeader}>{this.$t('Analysis.Drug')}</h1>
        <BCardContainer
          style={{ height: '470px' }}
          isLoading={this.loading}
          isEmpty={this.isEmpty}
          title={this.message.title}
          desc={this.message.desc}
          titleClass={$classes.title}
        >
          <template slot="headerRightAction">
            <GRadio
              v-model={this.dataType}
              items={[
                {
                  label: this.$tc('common.drug'),
                  value: 'drug',
                },
                {
                  label: this.$tc('common.drug_dev_status'),
                  value: 'dev_status',
                },
              ]}
            ></GRadio>
          </template>
          <div ref="chartContainer" style="height: 100%"></div>
        </BCardContainer>
      </div>
    )
  },
})
