import { toThousands } from '@patsnap/synapse_common_utils'
import { defineComponent, PropType } from '@vue/composition-api'
import $class from './BStackBarTooltip.module.scss'

export const BStackBarTooltip = defineComponent({
  name: 'BStackBarTooltip',
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<{ color: string; name: string; count: number; originalData: any; countFormatter?: any }[]>,
      required: true,
    },
    countClickable: {
      type: Boolean,
      default: false,
    },
  },
  render() {
    // 由于柱状图类目轴需要显示实体卡片，所以取消了echarts tooltip 上的 padding，这边需要添加下
    return (
      <div style="padding: 8px 8px 8px 8px">
        <div class={$class.category}>{this.categoryName}</div>
        {this.items.map((i, index) => (
          <div class={$class.item}>
            <span style={{ backgroundColor: i.color }} class={$class.itemMarker}></span>
            <span class={$class.itemName}>{i.name}:&nbsp;</span>
            <span
              class={[$class.itemCount, this.countClickable ? $class.countHighlight : null]}
              onClick={() => {
                if (this.countClickable) this.$emit('click', i.originalData)
              }}
            >
              {i.countFormatter ? i.countFormatter(i.count) : toThousands(i.count)}
            </span>
          </div>
        ))}
      </div>
    )
  },
})
