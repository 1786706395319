import { ChromeBar } from '@pharmsnap/pharmsnap-web/components/browser/ChromeBar'
import { checkVersion } from '@pharmsnap/pharmsnap-web/utils/version/version-check'
import { useAuthStore } from '@pharmsnap/shared/src/composition'
import { computed, defineAsyncComponent, defineComponent, onMounted, ref } from '@vue/composition-api'
import { getBrowserInfo } from 'pharmsnapMF_shared/utils'
import { CopilotDialogWithAuth } from './components/CopilotGpt/CopilotDialogWithAuth'
import { CopilotEntry } from './components/CopilotGpt/CopilotEntry'
import { InviteCopilotDialog } from './components/InviteCopilot/InviteCopilotDialog'
import { useGlobalProductPromotion } from './components/ProductPromotion/useProductPromotion'
import { ServiceHelp } from './components/ServiceHelp/ServiceHelp'
import { useLs360Provider } from './composable/useLs360ChatStore'
import { useShowNav } from './composable/useShowNav'
import { IS_CN_REGION } from './config'
import { getService } from './service/service'
import { E_ROUTER_NAME } from './types/enum'

export default defineComponent({
  components: {
    'selection-tool': defineAsyncComponent(() => import('./components/CopilotGpt/SelectionTool').then((r) => r.SelectionTool)),
  },
  name: 'APP',
  provide: {
    configProvider: {
      service: getService(),
    },
  },
  setup(_, { root }) {
    const {
      getters: { isSupportCopilot },
    } = useAuthStore()
    useLs360Provider()
    checkVersion()
    const browserInfo = getBrowserInfo()
    const ignoreChromeBar = ref(browserInfo.isModern)
    const handleIgnore = () => {
      ignoreChromeBar.value = true
    }
    const isShowCopilot = computed(() => {
      if (!isSupportCopilot.value) {
        return false
      }
      const noCopilotRoute = [E_ROUTER_NAME.KNOWLEDGE_EXPLORE, E_ROUTER_NAME.KNOWLEDGE_GRAPH, E_ROUTER_NAME.EXPORT, E_ROUTER_NAME.COMPLETE_INFO]
      return !noCopilotRoute.includes(root.$route.name as E_ROUTER_NAME)
    })

    const { isPopperCopilotDialog } = useShowNav(root)

    const { init: initProductPromotionState } = useGlobalProductPromotion()

    onMounted(() => {
      initProductPromotionState()
    })

    return { ignoreChromeBar, browserInfo, handleIgnore, isShowCopilot, isPopperCopilotDialog }
  },
  render() {
    return this.ignoreChromeBar ? (
      <div>
        <router-view class="h-screen" />
        <div class="absolute right-0 bottom-0" style="top: 64px">
          {this.isPopperCopilotDialog && <CopilotDialogWithAuth isAbsolutePosition />}
          {this.isShowCopilot && <CopilotEntry />}
          <InviteCopilotDialog />
        </div>
        {this.isShowCopilot ? <selection-tool></selection-tool> : null}
        {IS_CN_REGION && <ServiceHelp />}
      </div>
    ) : (
      <div class="flex flex-col h-screen">
        <ChromeBar onIgnore={this.handleIgnore} browserToLow={this.browserInfo.browserToLow} isSafari={this.browserInfo.isSafari} />
        <router-view class="flex-1" />
      </div>
    )
  },
})
