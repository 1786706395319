/* eslint-disable @typescript-eslint/no-explicit-any */

import { IS_CN_REGION } from '@pharmsnap/pharmsnap-web/config'
import { getLocalStorage, removeHistoryQueries } from 'pharmsnapMF_shared/utils'
import qs from 'qs'
import { downloadFileEvent } from './events/download-file'
const eventStoreKey = 'pharmsnap:event'

/**
 * 活动策略表
 */
const eventMap: Record<string, (...arg: any[]) => any> = {
  downloadFile: (data) => {
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'promotion', 'download promotion file automatic'])
    }
    downloadFileEvent(data)
  },
}

/**
 * 注册活动到 localStorage
 * 将url上的event参数存到 localStorage,等后续流程消费
 * @param params
 */
export function registerEvent() {
  if (!IS_CN_REGION) {
    return
  }
  const query = qs.parse(window.location.search.substring(1, window.location.search.length))
  if (query.event) {
    localStorage.setItem(eventStoreKey, query.event as string)
    removeHistoryQueries(['event'])
  }
}
/**
 * 消费注册的事件
 * @returns
 */
export function consumeEvent() {
  if (!IS_CN_REGION) {
    return
  }
  const eventData = getLocalStorage<{ event_name: string; event_params?: string }>(eventStoreKey)
  if (eventData?.event_name) {
    executeEvent(eventData.event_name, eventData.event_params)
    localStorage.removeItem(eventStoreKey)
  }
}
/**
 * 执行活动
 * @param event_name 活动名
 * @param eventParams 活动参数
 */
async function executeEvent(event_name: string, eventParams?: any): Promise<{ status: boolean; error?: any }> {
  if (event_name in eventMap) {
    try {
      await eventMap[event_name](eventParams)
      return {
        status: true,
      }
    } catch (error) {
      return {
        status: false,
        error,
      }
    }
  } else {
    const message = `[Event]: 未找到活动${event_name},请检查`
    console.error(message)
    return {
      status: false,
      error: message,
    }
  }
}
