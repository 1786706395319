import { IFieldLangMap } from '@patsnap/synapse_common_interface'

export const drugDealOrgAttrLangMap: IFieldLangMap = {
  US: {
    cn: '美国',
    en: 'US',
  },
  CN: {
    cn: '中国',
    en: 'CN',
  },
  EUR: {
    cn: '欧洲',
    en: 'EUR',
  },
  JP: {
    cn: '日本',
    en: 'JP',
  },
  'Outside US': {
    cn: '美国外',
    en: 'Outside US',
  },
  'Outside CN': {
    cn: '中国外',
    en: 'Outside CN',
  },
  'Outside EUR': {
    cn: '欧洲外',
    en: 'Outside EUR',
  },
  'Outside JP': {
    cn: '日本外',
    en: 'Outside JP',
  },
}
