import { APPROVED_DEV_STATUS_ID, DRUG_DEV_STATUS_IDS } from '@patsnap/synapse_common_config'
import '@pharmsnap/shared/assets/icon-svg/stop.svg'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { IBaseDevStatusCountryInfo, IBaseDevStatusInfo } from '@pharmsnap/shared/types'
import { PropType, computed, defineComponent, toRefs } from '@vue/composition-api'
import { GArrowLine } from '../../ui/GArrowLine/GArrowLine'
import { GCountryFlag } from '../../ui/GCountryFlag/GCountryFlag'
import { GLimited } from '../../ui/GLimited/GLimited'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BDevStatusPopperCard } from '../card/BDevStatusCard/BDevStatusPopperCard'
import $classes from './BDevStatusPipeline.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDevStatusPipeline = defineComponent({
  name: 'BDevStatusPipeline',
  i18n: {
    messages: { cn, en },
  },
  props: {
    items: {
      type: Array as PropType<IBaseDevStatusInfo[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { items } = toRefs(props)
    const len = computed(() => items.value.length)
    const highestDevStatus = computed(() => items.value[items.value.length - 1])
    const highestDevStatusIndex = computed(() =>
      len.value === 0 ? -1 : DRUG_DEV_STATUS_IDS.findIndex((i) => i === highestDevStatus.value.dev_status_id)
    )
    const width = computed(() => `${((highestDevStatusIndex.value + 1) / DRUG_DEV_STATUS_IDS.length) * 100}%`)

    return {
      width,
    }
  },
  methods: {
    renderPhaseContainer() {
      return <div class={$classes.pipelinePhaseContainer}>{DRUG_DEV_STATUS_IDS.map((item) => this.renderPhaseItem(item))}</div>
    },
    renderArrowLineContainer() {
      return (
        <div class="px-2.5" style={{ width: this.width }}>
          <GArrowLine></GArrowLine>
        </div>
      )
    },
    renderPhaseCountry(countryInfo: IBaseDevStatusCountryInfo[], isApproved: boolean) {
      return (
        <GLimited
          mode="expand"
          limit={4}
          items={countryInfo}
          inline={true}
          scopedSlots={{
            default: (props: { item: IBaseDevStatusCountryInfo }) => {
              const { country_view, inactive } = props.item
              const name = country_view?.alpha_2_code || country_view?.display_name || ''
              const inactiveContent = inactive ? (
                <GTooltip theme="light" mode="icon" iconName="Stop">
                  <div slot="content">{this.$tc('devStatusPipeline.stopMessage')}</div>
                </GTooltip>
              ) : (
                <span class={$classes.pipelineCountryPlaceholder}></span>
              )

              return (
                <span class={$classes.pipelineCountryItem}>
                  <BDevStatusPopperCard data={props.item} isApproved={isApproved}>
                    <GCountryFlag
                      class={$classes.pipelineCountryItemFlag}
                      name={name}
                      nameCn={country_view?.display_name_cn}
                      showName={false}
                      showUnknownCountryFlag
                    ></GCountryFlag>
                  </BDevStatusPopperCard>
                  {inactiveContent}
                </span>
              )
            },
          }}
        ></GLimited>
      )
    },
    renderPhaseItem(id: string) {
      const found = this.items.find((i) => i.dev_status_id === id)

      return (
        <div class={$classes.pipelinePhaseItem}>
          {found ? this.renderPhaseCountry(found.country_info, found.dev_status_id === APPROVED_DEV_STATUS_ID) : null}
        </div>
      )
    },
  },
  render() {
    if (this.items.length) {
      return (
        <div>
          {this.renderPhaseContainer()}
          {this.renderArrowLineContainer()}
        </div>
      )
    }
    return <span>{EMPTY_PLACEHOLDER}</span>
  },
})
