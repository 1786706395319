import { useLocale } from '@pharmsnap/shared/composition'
import { extractionMethodConfig } from '@pharmsnap/shared/constants/static-data/patent'
import { computed, defineComponent, PropType } from '@vue/composition-api'
export const BTagExtraction = defineComponent({
  name: 'BTagExtraction',
  props: {
    type: {
      required: true,
      type: String as PropType<'0' | '1'>,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const name = computed(() => {
      const val = extractionMethodConfig[props.type]
      return isCN.value ? val.cn : val.en
    })
    return { name }
  },
  render() {
    return <span>{this.name}</span>
  },
})
