import { trueOrFalseLangMap } from '@patsnap/synapse_common_config'
import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/constants/enum-lang/org'
import { COMPANY_EMPLOYEE_NUMBER_MAP } from '@pharmsnap/shared/constants/rangeConfig'
import { getNumberRangeDisplayName } from '../../number'
import {
  injectLangFieldByKeyLangMap,
  injectOrgName,
  injectStartupName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'
const companyEmployeeNumber2DisplayNameMap: Record<string, string> = (() => {
  const newRanges = [...COMPANY_EMPLOYEE_NUMBER_MAP].concat(COMPANY_EMPLOYEE_NUMBER_MAP.slice(-1))
  const rangesLength = newRanges.length
  let pre = 0
  let rt: Record<string, string> = {}
  newRanges.forEach((item, index) => {
    rt = {
      ...rt,
      [`${index + 1}`]: getNumberRangeDisplayName(index === 0, index === rangesLength - 1, item, pre),
    }
    pre = item
  })
  return rt
})()

function injectOrgEmployeeNumberName(data: IAggregationValue) {
  const name = companyEmployeeNumber2DisplayNameMap[data.key] || ''
  if (!name) {
    console.error('药物机构员工数索引字段文案映射未找到,请检查')
  }
  return {
    ...data,
    display_name_cn: name,
    display_name_en: name,
  }
}

const orgFieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue> = {
  DEV_ORG_TYPE_ONEID: (data) => injectLangFieldByKeyLangMap(ORG_ENTITY_LANG, data),
  TARGET_ID: replaceDisplayNameByShortName,
  TARGET_ID_EXTEND: replaceDisplayNameByShortName,
  ORG_EMP_NUMBER_GENERAL_ONEID: injectOrgEmployeeNumberName,
  DRUG_ORG_EMP_NUMBER_GENERAL_ONEID: injectOrgEmployeeNumberName,
  ALL_ORG_TYPE_ONEID: (data) => injectLangFieldByKeyLangMap(ORG_ENTITY_LANG, data),
  DEV_ORG_ID_ROOT_ONEID: injectOrgName,
  NON_DEV_ORG_ID_ONEID: injectOrgName,
  NON_DEV_ORG_ID_ROOT_ONEID: injectOrgName,
  DEV_ORG_ID_ONEID: injectOrgName,
  ORGANIZATION_ID_ONEID: injectOrgName,
  ORG_ROOT_ID_ONEID: injectOrgName,
  ORIGINAL_DEV_ORG_ID_ONEID: injectOrgName,
  ORIGINAL_DEV_ORG_ID_ROOT_ONEID: injectOrgName,
  RESEARCH_ORG_ID_ONEID: injectOrgName,
  RESEARCH_ORG_ID_ROOT_ONEID: injectOrgName,
  REGISTRY_ID_ROOT_ONEID: injectOrgName,
  REGISTRY_ID_ONEID: injectOrgName,
  IS_STARTUPS: injectStartupName,
  IS_COMPOUND_MEDICINE: injectStartupName,
  EXIST_DEAL: (data) => injectLangFieldByKeyLangMap(trueOrFalseLangMap, data),
}

export function transformDrugAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, orgFieldTransformFnMap, xCorrelationId)
}
