import { defineComponent, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BDrugFetchCard } from './BDrugFetchCard'

export const BDrugPopperCard = defineComponent({
  name: 'BDrugPopperCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    zIndex: Number,
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple, props.zIndex)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper data={{ id: this.id }} tippyConfig={this.tippyConfig} component={BDrugFetchCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
