import { ISort } from '@patsnap/synapse_common_interface'
import '@pharmsnap/shared/assets/icon-svg/news.svg'
import {
  BCardContainer,
  BEntityLoadingCard,
  BLazyImg,
  BNewsAnnotationTag,
  GDescription,
  GLimitedContainer,
  GLink,
  GTranslate,
} from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElDivider } from '@pharmsnap/shared/element-ui'
import {
  getNewsTranslateText,
  renderNewsHoverPopoverLimitedDisease,
  renderNewsHoverPopoverLimitedDrug,
  renderNewsHoverPopoverLimitedOrg,
  renderNewsHoverPopoverLimitedTarget,
  renderNewsPublishDataAndSourceInfo,
} from '@pharmsnap/shared/render'
import { INewsDetail, ITranslateLang } from '@pharmsnap/shared/types'
import { isOpenNewsTranslate } from '@pharmsnap/shared/utils/translate'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import styles from './BCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BCardNews = defineComponent({
  name: 'BCardNews',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<INewsDetail>,
      required: true,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: 'normal',
    },
    queryId: {
      type: String,
    },
    offset: {
      type: Number,
    },
    sort: {
      type: Array as PropType<ISort[]>,
      default: () => [],
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    customGeneratorNewsDetailPath: {
      type: Function as PropType<(newsId: string) => string>,
    },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props) {
    const { forceTranslation } = toRefs(props)
    const {
      getters: { customSettingTranslation },
    } = useAuthStore()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { locale, localeUpcase } = useLocale()
    const showAll = computed(() => props.viewType === 'normal')
    const detailUrl = computed(() => {
      if (props.customGeneratorNewsDetailPath) return props.customGeneratorNewsDetailPath(props.data.news_id)

      return sharedCtx.router.generatorNewsDetailPath(props.data.news_id)
    })
    const isExecuteTranslate = computed(() => {
      if (props.disabledTranslate) {
        return false
      }
      return isOpenNewsTranslate(props.data, forceTranslation.value || customSettingTranslation.value)
    })
    const isIntelligentMode = computed(() => props.mode === 'intelligent')
    return {
      locale,
      localeUpcase,
      detailUrl,
      isExecuteTranslate,
      showAll,
      isIntelligentMode,
    }
  },
  methods: {
    renderHeader() {
      return (
        <div class={['text-sm', !this.isIntelligentMode ? 'px-4 pt-3' : '']} slot="header">
          {/* {this.isIntelligentMode ? (
            <div class="flex items-center">
              <span class="flex-shrink-0 leading-6 text-[#DD8501]">{this.$tc('common.news')}</span>
              <span class="flex-shrink-0 leading-6 mx-1 text-text-t2">/</span>
              {renderNewsPublishDataAndSourceInfo(this.data, this.$i18n)}
            </div>
          ) : (
            )} */}
          {renderNewsPublishDataAndSourceInfo(this.data, this.$i18n)}
        </div>
      )
    },
    renderBody() {
      return (
        <div>
          <div class="mt-2 flex">
            <div class="flex-1">
              <GTranslate execute={this.isExecuteTranslate} content={getNewsTranslateText(this.data, 'title')}>
                <GLink
                  href={this.detailUrl}
                  name={this.data.title}
                  newTab={true}
                  class={[
                    styles.title,
                    this.isIntelligentMode ? 'text-base leading-6' : 'text-xl leading-6',
                    'whitespace-pre-wrap hover:text-blue-default font-semibold',
                  ]}
                ></GLink>
              </GTranslate>
              {this.showAll ? (
                <div class="mt-2">
                  <GTranslate execute={this.isExecuteTranslate && !!getNewsTranslateText(this.data, 'content')}>
                    <GLimitedContainer class="mr-2" newTab={true} href={this.detailUrl} moreText={this.$tc('common.showAll')}>
                      <div class="text-sm leading-5 text-text-t2 break-words" domPropsInnerHTML={this.data.content}></div>
                    </GLimitedContainer>
                    <GLimitedContainer slot="content" newTab={true} href={this.detailUrl} moreText={this.$tc('common.showAll')} moreColor="#f0f1f3">
                      <div class="text-sm leading-5 break-words" domPropsInnerHTML={getNewsTranslateText(this.data, 'content')}></div>
                    </GLimitedContainer>
                  </GTranslate>
                </div>
              ) : null}
              {this.data.annotation_tag?.length ? (
                <div class="mt-2">
                  {this.data.annotation_tag.map((item) => {
                    return <BNewsAnnotationTag class="mr-1 mb-1" data={item}></BNewsAnnotationTag>
                  })}
                </div>
              ) : null}
            </div>
            <div>{this.renderCoverImg()}</div>
          </div>
          {this.showAll && this.showFooter && !this.isIntelligentMode ? <ElDivider /> : null}
        </div>
      )
    },
    renderCoverImg() {
      const cover = this.data?.images?.find((image) => image.type === 'cover_image')
      if (!cover) return null
      return (
        <div>
          <BLazyImg
            class="w-40 ml-4 cursor-pointer"
            style="height: 90px"
            imgClass="w-full h-full rounded"
            imgStyle="object-fit: cover"
            src={cover.s3_path as string}
            signType="discovery_attachment"
          ></BLazyImg>
        </div>
      )
    },
    renderFooter() {
      if (!this.showFooter || this.isIntelligentMode) return
      /** 自定义footer */
      if (this.$slots.customFooter) {
        return this.$slots.customFooter
      }
      return (
        <div class="-mb-2">
          <div class="text-text-t2 mb-3 text-sm">{this.$tc('BCard.MentionedEntities')}</div>
          <GDescription
            width={100}
            data={this.data}
            items={[
              {
                label: `${this.$tc('common.organization')}:`,
                field: 'organizations',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.disease')}:`,
                field: 'disease',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.target')}:`,
                field: 'targets',
                tipCls: 'w-80',
                tipPlacement: 'right',
                contentCls: styles.contentItem,
              },
              {
                label: `${this.$tc('common.drug')}:`,
                field: 'drugs',
                contentCls: styles.contentItem,
              },
            ]}
            scopedSlots={{
              organizations: () => renderNewsHoverPopoverLimitedOrg(this.data, 3),
              disease: () => renderNewsHoverPopoverLimitedDisease(this.data, 3),
              targets: () => renderNewsHoverPopoverLimitedTarget(this.data, 3),
              drugs: () => renderNewsHoverPopoverLimitedDrug(this.data, 3),
            }}
          ></GDescription>
        </div>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={[styles.container, this.isIntelligentMode ? 'mb-0' : '']}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.viewType === 'normal' && this.renderFooter()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
