import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BAutoEntityInput, GEmpty, GRadio, GTree } from '@pharmsnap/shared/components'
import { useTranslateFieldLang } from '@pharmsnap/shared/composition'
import {
  E_NODATA_SIZE,
  IAutoCompleteType,
  IConvertResItem,
  IElTreeNode,
  IFieldLangMap,
  IGRadioItem,
  IOrderBy,
  ITreeAggregationItem,
} from '@pharmsnap/shared/types'
import { encryptNum, findTarget, getTargetNodePath, showSingleToast } from '@pharmsnap/shared/utils'
import { computed, ComputedRef, defineComponent, getCurrentInstance, PropType, reactive, Ref, ref, toRefs, watch } from '@vue/composition-api'
import { cloneDeep, sortBy } from 'lodash'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { userInjectFilterService } from '../../../BFilterService'
import { userFilterItemState } from '../../../composable/userFilterItemState'
import { IFilterItemConfig, IFilterValue } from '../../../types'
import { renderDisplayNameWithTitle } from '../../../utils/render'
import $classes from './AdvanceFilterContent.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const AdvanceFilterContent = defineComponent({
  name: 'AdvanceFilterContent',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemConfig>,
    },
    fieldLangMap: {
      required: true,
      type: Object as PropType<Partial<IFieldLangMap>>,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ins = getCurrentInstance()
    const { autocompleteType, getHierarchyDataFn, useRollup } = props.config
    const { state } = userInjectFilterService()
    const { translateField } = useTranslateFieldLang(props.fieldLangMap)
    const { locale, filterData, treeData, filterTotal, currentValue, checkedMap, disabledMap, getUuKey, onChange, selectItem, selectMultiItem } =
      userFilterItemState(props.config, 'advance', undefined, 100)
    const renderItemFn = computed(() => {
      return props.config.advanceRenderFn || renderDisplayNameWithTitle.bind(null, locale.value)
    })
    const currentSelectAdvanceFieldTitle = computed(() => {
      const field = state?.advanceSelectField
      if (!field) {
        return ''
      }
      return translateField.value(field)
    })
    const titleWithCount = computed(() => {
      if (!filterTotal.value) {
        return currentSelectAdvanceFieldTitle.value
      }
      return (
        <span>
          {`${currentSelectAdvanceFieldTitle.value} `}
          <span class="icon-font" domPropsInnerHTML={` (${encryptNum(String(filterTotal.value))})`} />
        </span>
      )
    })

    const sortedData: Ref<IAggregationValue<any>[]> = ref([])
    const hierarchyTreeData: Ref<IConvertResItem[]> = ref(treeData.value)
    const sortType: Ref<IOrderBy> = ref(getHierarchyDataFn ? 'hierarchy' : 'count')
    const filteredData: Ref<IAggregationValue<any>[]> = ref([])
    const compData = reactive<{
      filterText: string
      searchId: string
      expandedIds: string[]
      expandIdArr: string[][]
      currentIndex: number
    }>({
      filterText: '',
      searchId: '',
      expandedIds: [],
      expandIdArr: [],
      currentIndex: 0,
    })

    const sortFn = computed(() => {
      if (props.config.disableAdvanceSorter) return undefined

      if (sortType.value === 'alphabet') {
        return locale.value === 'cn' ? (data: IAggregationValue) => data.display_name_cn : (data: IAggregationValue) => data.display_name_en
      }

      return (data: IAggregationValue) => -data.count
    })

    const sortedDataLength = computed(() => {
      return sortedData.value.length
    })
    const searchTreeRes = computed(() => !!compData.expandIdArr.length)

    const sortItem: ComputedRef<IGRadioItem[]> = computed(() => {
      const isCn = locale.value === 'cn'
      const res = [
        {
          value: 'count',
          label: isCn ? '数量' : 'Count',
        },
        {
          value: 'alphabet',
          label: 'A->Z',
        },
      ]
      if (getHierarchyDataFn) {
        res.unshift({
          value: 'hierarchy',
          label: isCn ? '层级' : 'Hierarchy',
        })
      }
      return res
    })

    function resetData() {
      compData.filterText = ''
      compData.searchId = ''
      compData.expandedIds = []
      compData.expandIdArr = []
      compData.currentIndex = 0
    }

    /**
     * 搜索获取treeData数据
     */
    function handleSearch() {
      if (sortType.value === 'hierarchy') {
        searchByIdForTree()
      } else {
        searchByIdForList()
      }
    }

    function searchPrev() {
      if (compData.currentIndex === 1) {
        compData.currentIndex = compData.expandIdArr.length
      } else {
        compData.currentIndex -= 1
      }
      const ids = compData.expandIdArr[compData.currentIndex - 1]
      ids && ids.length && scrollNodeIntoView(ids.join('-'))
    }

    function searchNext() {
      if (compData.currentIndex === compData.expandIdArr.length) {
        compData.currentIndex = 1
      } else {
        compData.currentIndex += 1
      }
      const ids = compData.expandIdArr[compData.currentIndex - 1]
      ids && ids.length && scrollNodeIntoView(ids.join('-'))
    }

    function scrollNodeIntoView(id: string) {
      const browseTree = ins?.proxy.$refs.browseTree as any
      if (browseTree) {
        const el = browseTree.$el.querySelector(`#kg-expand-node--${id}`)
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    }

    /**
     * 针对这边树结构会有其实是父，isLeaf为false，但其子被过滤完了的情况
     * 统一前端再计算一遍，没有children就算作子节点
     * 主要是为了解决前面的icon问题
     */
    function calcIsLeaf(tree: IConvertResItem[]) {
      tree.forEach((item) => {
        if (!item.children || !item.children.length) {
          item.is_leaf = true
        }
        if (item.children) {
          calcIsLeaf(item.children)
        }
      })
    }

    function searchByIdForTree() {
      hierarchyTreeData.value = treeData.value
      calcIsLeaf(hierarchyTreeData.value)
      if (compData.searchId) {
        compData.expandIdArr = getTargetNodePath(hierarchyTreeData.value, compData.searchId)
        compData.expandedIds = compData.expandIdArr.flat()
        compData.currentIndex = 0
        setTimeout(() => {
          searchNext()
        }, 1000)
      }
    }

    function searchByIdForList() {
      if (compData.searchId) {
        filteredData.value = filterData.value.filter((item) => {
          return item.key === compData.searchId
        })
      } else {
        filteredData.value = filterData.value
      }
      sortedData.value = sortFn.value ? sortBy(filteredData.value, sortFn.value) : filteredData.value
    }

    /**
     * 把选中的直接加到selected list中
     * 因为autocomplete推荐的是全量的数据
     * 所以搜索完成之后，判断下有无结果，以及结果的drug_count是否有值，以及是否已经被选择
     * 再决定是否要直接加到selected中
     */
    function handleSelectItem(type: IAutoCompleteType, item: ITreeAggregationItem) {
      const target = cloneDeep(item)
      compData.searchId = target.id
      handleSearch()
      if (compData.expandIdArr.length || sortedData.value.length) {
        if (sortType.value === 'hierarchy') {
          const res = findTarget(hierarchyTreeData.value, target.id)
          res && selectItem2Tag(res as ITreeAggregationItem)
        } else {
          const res = sortedData.value.find((o) => o.key === target.id)
          if (res) {
            target.drug_count = res.count
          }
          selectItem2Tag(target)
        }
      }
    }

    function handleClearSearch() {
      resetData()
      handleSearch()
    }

    function loopGetIdPath(node: IElTreeNode): string[] {
      if (node.parent) {
        return [...loopGetIdPath(node.parent), node.data.id]
      } else {
        return []
      }
    }

    /**
     * count
     * a->z
     * @param key
     */
    function selectListItem(key: string, checked = false) {
      if (checked) return
      const res = filterData.value.find((o) => o.key === key)
      if (res) {
        const item = {
          display_name_cn: res.display_name_cn || res.display_name_en,
          display_name_en: res.display_name_en,
          value: res.key,
          from: res.from,
          to: res.to,
          _raw: res,
        }
        selectItem(item)
      }
    }

    /**
     * tree数据转化成下方的tag list数据
     * @param item
     */
    function selectItem2Tag(item: ITreeAggregationItem, checked = false) {
      if (useRollup) {
        selectRollup(item, checked)
      } else {
        selectNotRollup(item, checked)
      }
    }

    function getRollupItems(item: ITreeAggregationItem, res: IFilterValue<any>[]) {
      if (item.drug_count) {
        const { name_cn, name_en, id, ext_id } = item
        const selected = {
          display_name_cn: name_cn || name_en,
          display_name_en: name_en,
          value: props.config.field === 'TARGET_ID_EXTEND' ? ext_id || '' : id,
          from: undefined,
          to: undefined,
          _raw: item,
        }
        res.push(selected)
      }
      if (item.children?.length) {
        item.children.forEach((child) => {
          getRollupItems(child, res)
        })
      }
    }

    function selectRollup(item: ITreeAggregationItem, checked = false) {
      if (checked) return
      const res: IFilterValue<any>[] = []
      getRollupItems(item, res)
      const { name_cn, name_en, id } = item
      const selected = {
        display_name_cn: name_cn || name_en,
        display_name_en: name_en,
        value: id,
        from: undefined,
        to: undefined,
        _raw: item,
      }
      selectMultiItem(selected, res)
    }

    function selectNotRollup(item: ITreeAggregationItem, checked = false) {
      if (item.drug_count) {
        if (checked) return
        const { name_cn, name_en, id } = item
        const selected = {
          display_name_cn: name_cn || name_en,
          display_name_en: name_en,
          value: id,
          from: undefined,
          to: undefined,
          _raw: item,
        }
        selectItem(selected)
      } else {
        showSingleToast({
          type: 'warning',
          message: ins?.proxy.$t('AdvanceFilter.selectDisableItem') as string,
        })
      }
    }

    /**
     * 监听state里面的数据状态变化，再去触发初始化数据
     */
    watch(() => [state?.advanceFilterDataMap, state?.advanceTreeDataMap, sortType.value], handleClearSearch, {
      immediate: true,
    })

    return {
      ...toRefs(compData),
      locale,
      filterData,
      titleWithCount,
      currentValue,
      checkedMap,
      disabledMap,
      sortedDataLength,
      searchTreeRes,
      getUuKey,
      onChange,
      renderItemFn,
      sortedData,
      hierarchyTreeData,
      sortItem,
      sortType,
      autocompleteType,
      handleSelectItem,
      handleClearSearch,
      loopGetIdPath,
      selectListItem,
      selectItem2Tag,
      searchPrev,
      searchNext,
      useRollup,
    }
  },
  methods: {
    renderSearch() {
      return (
        this.autocompleteType && (
          <BAutoEntityInput
            prefix="SearchRight"
            onSelect={this.handleSelectItem}
            onClear={this.handleClearSearch}
            style="width:240px"
            v-model={this.filterText}
            type={this.autocompleteType}
            placeHolder={this.$t('AdvanceFilter.searchPlaceHolder') as string}
            onSearchPrev={this.searchPrev}
            onSearchNext={this.searchNext}
            currentIndex={this.currentIndex}
            searchRes={this.expandIdArr}
          ></BAutoEntityInput>
        )
      )
    },
    renderContent() {
      if (this.isLoading) return null
      return this.sortType === 'hierarchy' ? this.renderHierarchy() : this.renderList()
    },
    renderTreeList(scope: { node: IElTreeNode; data: ITreeAggregationItem }) {
      const { data, node } = scope
      const expandIdArr = this.expandIdArr.map((expandId) => expandId.join('-'))
      const displayName =
        (this.config.autocompleteType === 'Target' ? data[`entity_name_${this.locale}`] : data[`name_${this.locale}`]) || data.name_en
      const count = this.useRollup ? data.drug_count_roll_up : data.drug_count
      const label = count ? `${displayName}(${toThousands(count)})` : displayName
      const classId = this.loopGetIdPath(node).join('-')
      const include = expandIdArr.includes(classId)
      const labelClass = count ? ['mr-1 text-text-default'] : ['mr-1 text-text-t2']
      const checked = !count ? 'cursor-not-allowed' : 'cursor-pointer'
      return (
        <span class={[$classes.treeItem, checked]} id={`kg-expand-node--${classId}`} onclick={() => this.selectItem2Tag(data, !count)}>
          <span class={labelClass} style={{ backgroundColor: include ? '#F8D50F' : '' }} title={label}>
            {label}
          </span>
        </span>
      )
    },
    renderHierarchy() {
      if (this.searchId) {
        if (!this.searchTreeRes) return this.renderEmpty()
      } else {
        if (!this.hierarchyTreeData.length) return this.renderEmpty()
      }

      return (
        <div class={$classes.treeContainer}>
          <GTree
            key={this.searchId}
            lazy={false}
            class={$classes.tree}
            expandedIds={this.expandedIds}
            searchId={this.searchId}
            treeData={this.hierarchyTreeData}
            ref="browseTree"
            showOrgLogo={false}
            showCheckbox={false}
            scopedSlots={{
              default: (scope: { node: IElTreeNode; data: ITreeAggregationItem }) => {
                return this.renderTreeList(scope)
              },
            }}
          ></GTree>
        </div>
      )
    },
    renderList() {
      return this.sortedDataLength ? (
        <RecycleScroller
          class="h-full overflow-y-auto"
          items={this.sortedData}
          item-size={24}
          key-field="key"
          scopedSlots={{
            default: (data: { item: IAggregationValue }) => {
              const { item } = data
              const key = this.getUuKey(item)
              const disabled = !!this.disabledMap[key]
              return (
                <div
                  class={[$classes.listItem, 'font-sm', disabled ? 'cursor-not-allowed' : 'cursor-pointer']}
                  onclick={() => this.selectListItem(key, disabled)}
                >
                  {this.renderItemFn(item, this.sortedDataLength)}
                  {item.count ? <span class={$classes.count}>{`(${toThousands(item.count)})`}</span> : null}
                </div>
              )
            },
          }}
        ></RecycleScroller>
      ) : (
        this.renderEmpty()
      )
    },
    renderEmpty() {
      return (
        <div class="h-full flex items-center justify-center">
          <GEmpty size={E_NODATA_SIZE.SMALL} title={this.$t('AdvanceFilter.noData') as string}></GEmpty>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="flex flex-col">
        <div>
          <span class="font-medium">{this.titleWithCount}</span>
        </div>
        {this.config.hideAdvanceFilterHeader ? null : (
          <div class="flex mt-2">
            <div class="flex-1 flex item-center">{this.renderSearch()}</div>
            <div class="flex items-center">
              <span class="mr-2">{this.$t('AdvanceFilter.orderBy')}:</span>
              <GRadio v-model={this.sortType} mode="button" items={this.sortItem}></GRadio>
            </div>
          </div>
        )}
        <div class="mt-4 flex-1 p-2 border border-gray-40 overflow-hidden rounded">{this.renderContent()}</div>
      </div>
    )
  },
})
