import { sharedCtx } from '@pharmsnap/shared/context'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { getTargetName } from '@pharmsnap/shared/src/utils/target'
import { IBaseTargetInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { BEntityTag, BTargetPopperCard, GIcon, GLink } from '../..'

export const BTargetItem = defineComponent({
  name: 'BTargetItem',
  props: {
    data: {
      required: true,
      type: Object as PropType<IBaseTargetInfo>,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * 取名逻辑是否需要降级
     * 邮件提醒变更详情，需要降级，不然后端需要刷历史变更数据
     */
    isNameNeedDegrade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { locale } = useLocale()

    const { data } = toRefs(props)
    const href = computed(() => sharedCtx.router.generatorTargetPath(data?.value?.target_id || ''))
    const name = computed(() => {
      if (data.value) {
        return getTargetName(data.value, locale.value, props.isNameNeedDegrade)
      }
      return ''
    })
    return {
      href,
      name,
    }
  },
  render() {
    const id = this.data.target_id
    const fontClassName = this.data.fontClassName
    const content = (
      <BEntityTag data-testid="b-target-item">
        {this.showIcon ? <GIcon class="text-text-t2 mr-1 flex-shrink-0" svgName="Target" /> : null}
        <GLink data-selection-disabled={this.popover} href={this.href} name={this.name} encryptClass={fontClassName || ''}></GLink>
      </BEntityTag>
    )
    if (id && this.popover) {
      return <BTargetPopperCard id={id}>{content}</BTargetPopperCard>
    }
    return content
  },
})
