import { IAggregationItem, IAggregationParams, IAggregationSingleResult, IQuery, IQueryItem } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { BasicHorizontalBarChart, getChartColor } from '@pharmsnap/shared/chart'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'

export function generatePatentAggParams(
  queryState: IQueryService['state'],
  aggregation: IAggregationItem[],
  customQuery?: IQuery
): IAggregationParams {
  const { data_type, query, collapse } = queryState

  return {
    data_type,
    query: customQuery || { ...query, type: 'group' },
    aggregation,
    collapse,
  }
}

export function checkAggDataIsEmpty(aggData?: { aggregation_result: IAggregationSingleResult[] }): boolean {
  if (
    aggData &&
    aggData.aggregation_result &&
    aggData.aggregation_result[0] &&
    aggData.aggregation_result[0].items &&
    aggData.aggregation_result[0].items.length
  ) {
    return false
  }
  return true
}

export function countColumnFormatter(x: any) {
  return toThousands(x.cellValue)
}

export function countRowFormatter(x: any) {
  if (x.rowIndex !== 0) return toThousands(x.cellValue)
  else return x.cellValue
}

export function getGrantedFilterItem(): IQueryItem {
  return {
    type: 'field',
    fields: ['LEGAL_STATUS'],
    value: [
      {
        type: 'text',
        value: '3',
        display_name_cn: '授权',
        display_name_en: 'Granted',
      },
    ],
  }
}

export function getHorizontalBarOptions<T>(config: {
  source: T[]
  color?: string[]
  dimensionKey?: string[]
  xAxisName?: string
}): BasicHorizontalBarChart {
  const { source, color = getChartColor(1), dimensionKey = ['count', 'name'], xAxisName } = config
  const op: BasicHorizontalBarChart = {
    type: 'horizontal-bar',
    color,
    dataset: {
      dimensions: dimensionKey.map((key) => ({ name: key })),
      source,
    },
    inside: true,
    labelPadding: [0, 0, 10, 0],
    xAxis: {
      dataType: {
        valueType: 'count',
      },
      name: xAxisName,
    },
    yAxis: {
      type: 'category',
      triggerEvent: true,
      inverse: true,
    },
    grid: {
      top: 12,
      bottom: 50,
      left: 20,
      right: 100,
      containLabel: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: '#F5F7FC',
        },
      },
    },
    barWidth: 18,
    series: [
      {
        name: xAxisName,
        cursor: 'pointer',
        label: {
          show: true,
          position: 'right',
          formatter: (val: any) => toThousands(val.data.count),
        },
        encode: {
          x: dimensionKey[0],
          y: dimensionKey[1],
        },
      },
    ],
  }

  return op
}

export function getChartHeight<T>(data: T[], singleHeight = 46, minChartHeight = 300): number {
  const chartHeight = data.length * singleHeight
  return Math.max(minChartHeight, chartHeight)
}
