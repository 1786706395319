/**
 * BO发送手机验证码用的极验公钥
 */
export const BACK_OFFICE_GEETEST_CAPTCHA_ID = '93a93689c223c9f52de553e31fb60241'
/**
 * pharmsnap极验公钥
 */
export const PHARMSNAP_GEETEST_CAPTCHA_ID = '062d4e82a544a2ef8993546ee6e3082d'
/**
 * BO google验证码公钥
 */
export const BACK_OFFICE_GOOGLE_CAPTCHA_ID = '6LdeMwIeAAAAACnIGkZ0Z8LWbVr9R0I0GfLZh5jz'
