/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlowFunction } from '@pharmsnap/shared/types'
import { curry } from 'lodash'
import { ECOption, Plot } from '../core/plot'
import { BasicBarChart, IECAxisDataType } from '../type'
import { flow, mergeSeries, mergeSeriesBar, mergeTooltip, mergeXAxis, mergeYAxis } from '../util'
import {
  adjustYAxisLabelFormatter,
  generateRectTooltipFormatter,
  getBarSeries,
  getChartColor,
  getSeries,
  getTooltip,
  getXAxis,
  getYAxis,
} from './config'

export class Bar extends Plot {
  public type = 'bar'

  protected getDefaultOption(options: ECOption): ECOption {
    const mergeRectChartXAxis: FlowFunction<ECOption> = curry(mergeXAxis)(getXAxis)
    const mergeRectChartYAxis: FlowFunction<ECOption> = curry(mergeYAxis)(getYAxis)
    const mergeRectTooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const mergeRectBarSeries: FlowFunction<ECOption> = curry(mergeSeriesBar)(getBarSeries)
    const mergeRectSeries: FlowFunction<ECOption> = curry(mergeSeries)(getSeries)
    const chartOptions = flow<ECOption>(mergeRectTooltip, mergeRectChartXAxis, mergeRectChartYAxis, mergeRectBarSeries, mergeRectSeries)(options)
    return chartOptions
  }

  protected getYAxisValueTypes(params: BasicBarChart) {
    return params.series.map(
      () => (Array.isArray(params.yAxis) ? params.yAxis[0]?.dataType : params.yAxis.dataType) || ({ valueType: 'text' } as IECAxisDataType)
    )
  }

  protected adaptor(params: BasicBarChart): ECOption {
    const { color, tooltip, legend, xAxis, yAxis, series, dataset, grid, extraInfoTooltipFormatter, showBarBorder = false } = params
    // @ts-ignore
    const options: ECOption = {
      ...(color ? { color } : { color: getChartColor(series.length) }),
      grid,
      tooltip: {
        ...tooltip,
        formatter:
          tooltip?.formatter || generateRectTooltipFormatter(this.getYAxisValueTypes(params), { extraInfoFormatter: extraInfoTooltipFormatter }),
      },
      legend,
      xAxis: Array.isArray(xAxis) ? xAxis : [xAxis],
      yAxis: Array.isArray(yAxis)
        ? yAxis.map((item) => (item.dataType && item.dataType.valueType === 'count' ? { ...item, minInterval: 1 } : item))
        : [yAxis.dataType && yAxis.dataType.valueType === 'count' ? { ...yAxis, minInterval: 1 } : yAxis],
      series: series.map((item) => ({
        ...item,
        type: 'bar',
        ...(showBarBorder ? { itemStyle: { borderWidth: 0.5, borderColor: '#ffffff' } } : undefined),
      })),
      dataset,
      graphic: params.graphic,
    }

    return flow<ECOption>(
      curry(adjustYAxisLabelFormatter)(
        (Array.isArray(yAxis) ? yAxis[0]?.dataType && yAxis[0]?.dataType?.valueType : yAxis.dataType && yAxis.dataType.valueType) || 'text'
      )
    )(options)
  }
}
