import { AggChartValue, AggTwoDimChartBaseEntity, AggOneDimChartBaseEntity } from '@patsnap/synapse_domain'
import { getEntityTypeByAggField } from '@pharmsnap/shared/utils'
import { computed, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { useAnalysisChartNavDetail } from './useAnalysisChartNavDetail'

export function useAnalysisChart(options: { entity?: MaybeRef<AggTwoDimChartBaseEntity | AggOneDimChartBaseEntity> }) {
  const axisDataItem = ref<AggChartValue | null>(null)

  const entityType = computed(() => getEntityTypeByAggField(axisDataItem?.value?.aggregation_field || ''))

  const entityId = computed(() => axisDataItem.value?.key || '')

  const entityCnName = computed(() => axisDataItem.value?.display_name_cn)

  const entityEnName = computed(() => axisDataItem.value?.display_name_en)

  const entityNameInChart = computed(() => axisDataItem.value?._meta.after)

  const { handleAxisClick } = useAnalysisChartNavDetail({ id: entityId, type: entityType })

  function handleXAxisMouseover(params: { value: string }) {
    const chartEntity = unref(options.entity)
    const foundItem = chartEntity?.findAxisDataItemByName(params.value) || null
    axisDataItem.value = foundItem
  }

  function handleYAxisMouseover(params: { value: string }) {
    const chartEntity = unref(options.entity)
    const foundItem = chartEntity?.findAxisDataItemByName(params.value) || null
    axisDataItem.value = foundItem
  }

  return {
    axisDataItem,
    entityType,
    entityId,
    entityCnName,
    entityEnName,
    entityNameInChart,
    handleAxisClick,
    handleXAxisMouseover,
    handleYAxisMouseover,
  }
}
