import '@patsnap-ui/icon/assets/solid/CoreProductsCompass.svg'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { computed, defineComponent } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
export const GGuideButton = defineComponent({
  name: 'GGuideButton',
  props: {
    name: {
      type: String,
    },
    href: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { isCN } = useLocale()
    const displayName = computed(() => {
      if (props.name) {
        return props.name
      }
      return isCN.value ? '使用指南' : 'Guide'
    })
    return { displayName }
  },
  render() {
    return (
      <a
        target="_blank"
        href={this.href}
        class="font-normal text-sm inline-flex items-center pl-1 pr-3 rounded text-blue-default hover:bg-blue-focus cursor-pointer"
      >
        <GIcon size={24} class="mr-0.5" svgName="SolidCoreProductsCompass"></GIcon>
        <span>{this.displayName}</span>
      </a>
    )
  },
})
