/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlowFunction } from '@pharmsnap/shared/types/base'
import { curry, max, merge } from 'lodash'
import { BasicHeatMapChart } from '..'
import { HEATMAP_LABEL_COLOR } from '../constants'
import { ECOption, Plot } from '../core/plot'
import { flow, mergeSeries, mergeTooltip, mergeVisualMap, mergeXAxis, mergeYAxis } from '../util'
import { getHeatMapVisualMap, getSeries, getTooltip, getXAxis, getYAxis } from './config'

export class HeatMap extends Plot {
  public type = 'heatmap'

  protected getDefaultOption(options: ECOption): ECOption {
    const mergeRectChartXAxis: FlowFunction<ECOption> = curry(mergeXAxis)(getXAxis)
    const mergeRectChartYAxis: FlowFunction<ECOption> = curry(mergeYAxis)(getYAxis)
    const mergeRectTooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const mergeRectSeries: FlowFunction<ECOption> = curry(mergeSeries)(getSeries)
    const visualMap: FlowFunction<ECOption> = curry(mergeVisualMap)(getHeatMapVisualMap)
    const chartOptions = flow<ECOption>(mergeRectTooltip, visualMap, mergeRectChartXAxis, mergeRectChartYAxis, mergeRectSeries)(options)
    return chartOptions
  }

  protected adaptor(params: BasicHeatMapChart): ECOption {
    const { tooltip, grid, xAxis, yAxis, data, seriesName, series, visualMap, dataZoom, labelFormatter, labelRich, animation = true } = params
    // @ts-ignore
    const yAxisData = yAxis.data || []
    // @ts-ignore
    const xAxisData = xAxis.data || []

    const seriesData = data.map((i) => {
      const { x, y, useVisualMapColor } = i
      // @ts-ignore
      const xIndex = xAxisData.findIndex((item) => item === x)
      // @ts-ignore
      const yIndex = yAxisData.findIndex((item) => item === y)

      return useVisualMapColor
        ? {
            value: [xIndex, yIndex, i.value, i.value, i.originData],
          }
        : {
            value: [xIndex, yIndex, i.value, null, i.originData],
            itemStyle: {
              color: '#F4F5F7',
            },
            visualMap: false,
          }
    })

    // const tooltipFormatter = generateHeatMapTooltipFormatter(xAxisData, yAxisData)

    const values = data.filter((i) => !!i.useVisualMapColor).map((i) => i.value)

    // const minVal = min(values) || 0
    const maxVal = max(values) || 0

    // @ts-ignore
    const options: ECOption = {
      // @ts-ignore
      animation: animation,
      tooltip: {
        ...tooltip,
        // formatter: tooltipFormatter,
      },
      dataZoom: dataZoom,
      grid,
      visualMap: visualMap ? [merge(visualMap, { min: 0, max: maxVal })] : [{ min: 0, max: maxVal }],
      legend: {
        show: false,
      },
      yAxis: [yAxis].map((i) => ({
        ...i,
        type: 'category',
        splitArea: {
          show: true,
          interval: 0,
          areaStyle: { color: ['rgba(244,245,247,0.2)', 'rgba(223,225,230,0.2)'] },
        },
        splitLine: { show: true, lineStyle: { color: '#ffffff', type: 'solid', width: 1 } },
      })),
      xAxis: [xAxis].map((i) => ({
        ...i,
        type: 'category',
        splitArea: {
          show: true,
          interval: 0,
          areaStyle: { color: ['rgba(244,245,247,0.2)', 'rgba(223,225,230,0.2)'] },
        },
        splitLine: { show: true, lineStyle: { color: '#ffffff', type: 'solid', width: 1 } },
      })),
      series: [
        {
          name: seriesName,
          data: seriesData,
          type: 'heatmap',
          emphasis: {
            itemStyle: {
              borderWidth: 1,
              borderColor: '#495973',
            },
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: '#ffffff',
          },
          ...merge(series, {
            label: {
              show: true,
              color: HEATMAP_LABEL_COLOR,
              ...(labelFormatter ? { formatter: labelFormatter } : undefined),
              ...(labelRich ? { rich: labelRich } : { rich: {} }),
            },
          }),
        },
      ],
    }

    return options
  }
}
