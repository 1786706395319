import type { IBaseLangName } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { handleSpace } from '@pharmsnap/shared/utils/encrypt/encryptSpace'
import { groupBy, isNumber, unionBy } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import {
  BDecodeUniCodeContainer,
  BDrugItem,
  BDrugTypeTagItem,
  BEntityTag,
  BFamilyTag,
  BInformationSourceTag,
  BManualTag,
  BOrganizationItem,
  BPatentExtensionType,
  BUpgradeWrap,
  GCountryFlag,
  GLimited,
  GLink,
  GTranslate,
} from '../components'
import { BLimitedMore } from '../components/business/BLimitedMore/BLimitedMore'
import { BTagLegal } from '../components/business/BTagLegal/BTagLegal'
import { EMPTY_PLACEHOLDER } from '../constants'
import { countryLangMap } from '../constants/static-data/country'
import { sharedCtx } from '../context'
import {
  IBaseDictItem,
  IBaseDrugInfo,
  IBaseIdViewItem,
  IBaseLink,
  IBaseTargetInfo,
  ILang,
  IPatentBase,
  IPatentDetail,
  IPatentFamilyItemNew,
  IPatentPeople,
  ITranslateLang,
} from '../types'
import { encryptNum, formatTimestamp, generatorLangFieldISTranslateFn, getDicNames } from '../utils'
import { getSpecialLang } from '../utils/lang'
import {
  filterTargetsBySearchString,
  renderLimited,
  renderLimitedDisease,
  renderLimitedDisease_customMore,
  renderLimitedDrug,
  renderLimitedDrug_customMore,
  renderLimitedLinks,
  renderLimitedTargets,
  renderLimitedTargets_customMore,
  renderPatentTechnologyAsync,
  renderPatentTypeAsync,
  renderPopoverLimited,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedDrugAsync,
  renderPopoverLimitedTargetsAsync,
} from './baseRender'
function getLang(i18n: IVueI18n): ILang {
  return i18n.locale.toLocaleUpperCase() as ILang
}
/**
 * 处理专利的时间的格式化
 * @param str
 * @returns
 */
function formatePatentTime(str: number | undefined, lang: ILang) {
  if (!str) {
    return EMPTY_PLACEHOLDER
  }
  return formatTimestamp(String(str), { locale: lang, isUtc: false })
}

export function renderPatentTitleText(data: IPatentDetail, i18n: IVueI18n) {
  const title = getSpecialLang({ data: data.title || [], field: 'text', lang: getLang(i18n) })
  if (title) {
    return <span class="patentTitleText" domPropsInnerHTML={title}></span>
  }
  // 如果没有中英文就取第一个语言
  if (data.title?.length) {
    return <span class="patentTitleText" domPropsInnerHTML={data.title[0].text}></span>
  }
  return ''
}
/**
 * 返回机翻字段文本
 * @param data
 * @param field
 * @returns
 */
export function renderPatentTranslationField(data: IPatentDetail, field: 'title_translation' | 'abst_translation'): string {
  return data[field]?.text || ''
}
/**
 *  渲染patentDetail的title及title_translation内容
 */
export function renderPatentTitleInTableInDetail(data: IPatentDetail, $i18n: IVueI18n, translate: boolean, title?: string) {
  const translateText = renderPatentTranslationField(data, 'title_translation')
  const content = title ? `${title}: ${translateText}` : translateText
  return <GTranslate execute={translate} content={content}></GTranslate>
}
export function renderPatentTitleInTable(data: IPatentDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const translate = generatorLangFieldISTranslateFn(customSettingTranslation)(data.title)
  const translateText = renderPatentTranslationField(data, 'title_translation')
  const href = sharedCtx.router.generatorPatentDetailPath(data?.patent_id || '')

  return (
    <GTranslate execute={translate} content={translateText}>
      <BUpgradeWrap updateType={E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT} triggerPoint="PATENT_TITLE">
        <GLink href={href} newTab={true} defaultColor="dark" class="whitespace-pre-wrap text-sm leading-6">
          {renderPatentTitleText(data, $i18n)}
        </GLink>
      </BUpgradeWrap>
    </GTranslate>
  )
}
/**
 * 渲染摘要文本
 */
export function renderPatentAbstText(data: IPatentDetail, i18n: IVueI18n) {
  const abst = getSpecialLang({ data: data.abst || [], field: 'text', lang: getLang(i18n) })
  if (abst) return abst
  // 如果没有中英文就取第一个语言
  if (data.abst?.length) {
    return data.abst[0].text
  }
  return ''
}
/**
 *  渲染patentDetail的abst及abst_translation内容
 */
export function renderPatentAbstTextInTable(data: IPatentDetail, $i18n: IVueI18n, translate: boolean) {
  const translateText = renderPatentTranslationField(data, 'abst_translation')
  return (
    <GTranslate execute={translate} content={translateText}>
      <div>{renderPatentAbstText(data, $i18n)}</div>
    </GTranslate>
  )
}

/**
 * 渲染专利申请时间
 * @param data
 * @param i18n
 * @returns
 */
export function renderPatentApplicationData(data: IPatentBase, $i18n: IVueI18n) {
  const resDate = formatePatentTime(data.apdt, getLang($i18n))
  return handleSpace(encryptNum(resDate), 'icon-font')
}

/**
 * 渲染专利预估到期时间
 * @param data
 * @param $i18n
 * @returns
 */
export function renderPatentEstimatedExpiryData(data: IPatentBase, $i18n: IVueI18n) {
  const resDate = formatePatentTime(data.exdt, getLang($i18n))
  return handleSpace(encryptNum(resDate), 'icon-font')
}
/**
 * 渲染法律状态/事件
 */
export function renderPatentLegalStatusAndLegalEvent(data: IPatentBase, inlineShow = false, empty = EMPTY_PLACEHOLDER) {
  const rt = [...(data.legal_status || []), ...(data.event_status || [])]
  if (!rt.length) {
    return empty
  }
  const tagClass = inlineShow ? 'flex-shrink-0' : rt.length > 1 ? 'mb-1' : ''
  const total = rt.length
  return rt.map((item, index) => {
    return <BTagLegal class={[tagClass, { 'mr-1': index < total - 1 }]} code={item} />
  })
}
/**
 * 渲染简单法律状态
 */
export function renderPatentSimpleLegalStatus(data: IPatentDetail) {
  if (!isNumber(data.simple_legal_status)) {
    return EMPTY_PLACEHOLDER
  }
  return <BTagLegal code={data.simple_legal_status} simple />
}

/**
 * 渲染专利技术类型
 */
export function renderPatentTechnology(data: IPatentDetail) {
  return renderPatentTechnologyAsync(data.patent_technology_id_view || [], data.patent_technology_id, 3)
}

export function renderPatentTechnologyInDetail(data: IPatentDetail) {
  return renderPatentTechnologyAsync(data.patent_technology_id_view || [], data.patent_technology_id, Number.MAX_VALUE)
}

/**
 * 渲染简单同族状态
 * @param data
 * @returns
 */
export function renderPatentSimpleFamilyStatus(data: IPatentDetail) {
  if (!isNumber(data.simple_family_status)) {
    return EMPTY_PLACEHOLDER
  }
  return <BTagLegal code={data.simple_family_status} simple />
}

/**
 * 渲染inpadoc同族状态
 * @param data
 * @returns
 */
export function renderPatentInpadocFamilyStatus(data: IPatentDetail) {
  if (!isNumber(data.inpadoc_family_status)) {
    return EMPTY_PLACEHOLDER
  }
  return <BTagLegal code={data.inpadoc_family_status} simple />
}

function renderPatentSimpleFamily(params: {
  family: IPatentFamilyItemNew[]
  limitCount?: number
  inline?: boolean
  appendToBody?: boolean
  linkTip?: string
  link?: string
  mode?: 'expand' | 'popover' | 'link'
}) {
  const { family, limitCount = 3, inline = true, appendToBody, mode = 'popover', linkTip, link } = params
  return renderPopoverLimited(family, {
    limitCount,
    render: (item) => (
      <BFamilyTag class="flex items-center mr-1" country={item.country} count={item.count} family={item.family} familyType={'simple'}></BFamilyTag>
    ),
    inline,
    appendToBody,
    linkTip,
    link,
    mode,
  })
}

function getSortSimpleFamilyData(data: IPatentDetail) {
  const simpleFamilyData: IPatentFamilyItemNew[] = []
  for (const i in data.simple_family_jurisdiction) {
    simpleFamilyData.push({
      country: i,
      count: data.simple_family_jurisdiction[i],
      family: data.family_original,
    })
  }
  const { cn } = countryLangMap
  const weightMap: Record<string, number> = Object.keys(cn).reduce((prev, cur, idx) => ({ ...prev, [cur]: idx }), {})
  simpleFamilyData.sort((a, b) => weightMap[a.country] - weightMap[b.country])
  return simpleFamilyData
}

/**
 * 渲染简单同族国家地图-card
 * @param data
 * @param limitCount
 * @returns
 */
export function renderLimitedPatentSimpleFamilyInCard(params: { data: IPatentDetail; limitCount?: number; appendToBody?: boolean }) {
  const { data, limitCount = 3, appendToBody = false } = params
  const familyData = getSortSimpleFamilyData(data)
  return renderPatentSimpleFamily({
    family: familyData,
    limitCount,
    appendToBody,
  })
}

/**
 * 渲染简单同族国家地图-table
 * @param data
 * @returns
 */
export function renderLimitedPatentSimpleFamilyJurisdictionInTable(data: IPatentDetail) {
  const familyData = getSortSimpleFamilyData(data)
  return renderPatentSimpleFamily({
    family: familyData,
    inline: false,
  })
}

export function renderLimitedPatentSimpleFamilyPatentInTable(data: IPatentDetail, $i18n: IVueI18n) {
  return renderLimitedPatentSimpleFamilyPatent(data, $i18n)
}

export function renderLimitedPatentSimpleFamilyPatentInCard(data: IPatentDetail, $i18n: IVueI18n) {
  return renderLimitedPatentSimpleFamilyPatent(data, $i18n, true)
}
/**
 * 渲染简单同族专利
 * @param data
 * @returns
 */
function renderLimitedPatentSimpleFamilyPatent(data: IPatentDetail, $i18n: IVueI18n, inline = false) {
  if (!data.simple_family_patent?.length) {
    return EMPTY_PLACEHOLDER
  }
  return (
    <GLimited
      mode="link"
      totalCount={data.simple_family_count}
      items={data.simple_family_patent}
      limit={3}
      linkTip={$i18n.locale === 'cn' ? '前往详情页查看' : 'View in detail page'}
      link={sharedCtx.router.generatorPatentDetailPath(data?.patent_id || '')}
      inline={inline}
      scopedSlots={{
        default: ({ item, index, items }: { item: IPatentBase; index: number; items: IPatentBase[] }) => {
          return (
            <span class="inline-flex items-center">
              <GLink
                href={sharedCtx.router.generatorPatentDetailPath(item.patent_id || '')}
                name={item.pn}
                newTab={true}
                defaultColor="green"
                class="text-sm"
                style="color: #1D8820"
              ></GLink>
              {item.curation && <BManualTag mode="icon"></BManualTag>}
              {inline && items.length > 1 && index !== items.length - 1 && (
                <span class="text-sm" style="color: #1D8820">
                  ;
                </span>
              )}
            </span>
          )
        },
      }}
      gap={{ x: 4, y: 4, trigger: 4 }}
    ></GLimited>
  )
}

export function renderLimitedPatentSimpleFamilyJurisdictionInTableUseLink(params: {
  data: IPatentDetail
  limitCount?: number
  appendToBody?: boolean
  linkTip?: string
  link?: string
  mode?: 'expand' | 'popover' | 'link'
}) {
  const { data, limitCount = 3, appendToBody = false, linkTip, link, mode = 'popover' } = params
  const familyData = getSortSimpleFamilyData(data)
  return renderPatentSimpleFamily({
    family: familyData,
    limitCount,
    inline: true,
    appendToBody,
    mode,
    linkTip,
    link,
  })
}

export function renderDrugPatentTypeInTable(data: IPatentDetail) {
  return renderPatentTypeAsync(data.patent_type_id_view || [], data.patent_type_id, 3)
}

/**
 * 渲染popover专利号
 * @param data
 * @param limit
 * @returns
 */
export function renderPopoverPatentNumberTags(data: IBaseLink[], limit = 3) {
  return renderLimitedLinks(data, limit, 'blue', false)
}

/**
 * 专利受理局
 */
export function renderAuthority(data: IPatentDetail) {
  if (!data.country) {
    return EMPTY_PLACEHOLDER
  }
  return <GCountryFlag name={data.country} showName={true}></GCountryFlag>
}
/**
 * 被引用数量
 */
export function renderCitedByCount(data: IPatentDetail) {
  if (!data.cited_by_count) {
    return EMPTY_PLACEHOLDER
  }
  return toThousands(data.cited_by_count)
}
export function renderAbst(data: IPatentDetail | null, lang: ILang) {
  if (data && data.abst && data.abst.length) {
    return data.abst.reduce((acc, cur) => {
      return (acc = acc + (cur.lang === lang ? cur.text : '')) || EMPTY_PLACEHOLDER
    }, '')
  }
  return EMPTY_PLACEHOLDER
}
/**
 * 渲染专利公开时间
 * @param data
 * @param i18n
 * @returns
 */
export function renderPatentPublicationData(data: IPatentBase, $i18n: IVueI18n) {
  const resDate = formatePatentTime(data.pbdt, getLang($i18n))
  return handleSpace(encryptNum(resDate), 'icon-font')
}

/**
 * 渲染专利首次公开时间
 * @param data
 * @param i18n
 * @returns
 */
export function renderPatentFirstPublicationData(data: IPatentDetail, $i18n: IVueI18n) {
  return formatePatentTime(data.first_pbdt, getLang($i18n))
}

export function renderPatentLimitedIndication(data: IPatentDetail, moreRender: JSX.Element) {
  return renderLimitedDisease_customMore(data.disease_id_view || [], 10, moreRender)
}
export function renderPatentIndicationInTable(data: IPatentDetail) {
  return renderPopoverLimitedDiseaseAsync(data.disease_id_view, data.disease_id, 3)
}
export function renderPatentIndicationInCard(data: IPatentDetail) {
  return renderPopoverLimitedDiseaseAsync(data.disease_id_view, data.disease_id, 3)
}

export function renderPatentLimitedTargets(data: IPatentDetail, moreRender: JSX.Element) {
  return renderLimitedTargets_customMore(data.target_id_view || [], 10, moreRender)
}
export function renderPatentTargetsInTable(data: IPatentDetail) {
  return renderPopoverLimitedTargetsAsync(data.target_id_view, data.target_id, 3)
}
export function renderPatentTargetsInCard(data: IPatentDetail) {
  return renderPopoverLimitedTargetsAsync(data.target_id_view, data.target_id, 3)
}

export function renderPatentLimitedDrugs(data: IPatentDetail, moreRender: JSX.Element) {
  return renderLimitedDrug_customMore(data.drug_id_view || [], 10, moreRender)
}

// 插槽内容
export function renderPatentIndicationMore(data: IPatentDetail, title?: string): JSX.Element {
  return (
    <BLimitedMore items={data?.disease_id_view} render={_renderPatentViewAllIndication} filterItem={_filterIndication} title={title}></BLimitedMore>
  )
}
// 插槽内容
export function renderPatentTargetsMore(data: IPatentDetail, title?: string): JSX.Element {
  return (
    <BLimitedMore
      items={data?.target_id_view}
      render={_renderPatentViewAllTargets}
      filterItem={filterTargetsBySearchString}
      title={title}
    ></BLimitedMore>
  )
}
// 插槽内容
export function renderPatentDrugMore(data: IPatentDetail, title?: string): JSX.Element {
  return <BLimitedMore items={data?.drug_id_view} render={_renderPatentViewAllDrug} filterItem={_filterDrug} title={title}></BLimitedMore>
}

export function renderPatentedDrugsAsync(data: {
  core_drug_id_view?: IBaseDrugInfo[]
  core_drug_id?: string[]
  core_recommend_drug_id?: string[]
  core_recommend_drug_id_view?: IBaseDrugInfo[]
}) {
  return renderPopoverLimitedDrugAsync(
    unionBy([...(data.core_drug_id_view || []), ...(data.core_recommend_drug_id_view || [])], 'drug_id'),
    unionBy([...(data.core_drug_id || []), ...(data.core_recommend_drug_id || [])]),
    3
  )
}

export function renderPatentedDrugs(drugs: IBaseDrugInfo[], limit = 3) {
  return renderPopoverLimited(drugs, {
    limitCount: limit,
    appendToBody: true,
    placement: 'right-end',
    render: (item) => <BDrugItem data={item} showCard zIndex={3000}></BDrugItem>,
  })
}

export function renderPatentDrugsInTable(data: { drug_id_view?: IBaseDrugInfo[]; drug_id?: string[] }) {
  return renderPopoverLimitedDrugAsync(data.drug_id_view, data.drug_id, 3)
}
export function renderPatentDrugsInCard(data: IPatentDetail) {
  return renderPopoverLimitedDrugAsync(data.drug_id_view, data.drug_id, 3)
}

export function renderApplicationDate(data: IPatentDetail, lang: ILang) {
  return data.apdt ? <div class="patent-content">{formatePatentTime(data.apdt, lang)}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderGreenApplicationNumber(data: IPatentDetail) {
  return data.apno ? <div class="patent-hight-light text-green-text">{data.apno}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}
export function renderGreenApplicationNumberInTable(data: IPatentDetail) {
  return data.apno || EMPTY_PLACEHOLDER
}
export function renderApplicationNumber(data: IPatentDetail) {
  return data.apno ? <div class="patent-content">{data.apno}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderIssueDate(data: IPatentDetail, lang: ILang) {
  return data.isdt ? <div class="patent-content">{formatePatentTime(data.isdt, lang)}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderPublicationDate(data: IPatentDetail, lang: ILang) {
  return data.pbdt ? <div class="patent-content">{formatePatentTime(data.pbdt, lang)}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}
export function renderFirstPublicationDate(data: IPatentDetail, lang: ILang) {
  return data.first_pbdt ? <div class="patent-content">{formatePatentTime(data.first_pbdt, lang)}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}

export function renderGreenPublicationNumber(data: IPatentDetail) {
  return data.pn ? (
    <BDecodeUniCodeContainer
      fontClassName={data.fontClassName}
      domPropsInnerHTML={data.pn}
      class="patent-hight-light text-green-text"
    ></BDecodeUniCodeContainer>
  ) : (
    <div>{EMPTY_PLACEHOLDER}</div>
  )
}

export function renderGreenPublicationNumberWithManualTagInTable(data: IPatentDetail, $i18n: IVueI18n) {
  if (!data.pn) {
    return EMPTY_PLACEHOLDER
  }
  const href = sharedCtx.router.generatorPatentDetailPath(data?.patent_id || '')
  return (
    <div>
      <BUpgradeWrap updateType={E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT} triggerPoint="PATENT_PN">
        <GLink href={href} encryptClass={data.fontClassName} name={data.pn} newTab={true} defaultColor="green" style="color: #1D8820"></GLink>
      </BUpgradeWrap>
      <div class="flex flex-wrap gap-1">
        {renderPatentTag(data)}
        {renderPatentSpcPteTag(data)}
      </div>
    </div>
  )
}
export function renderGreenPublicationNumberWithManualTag(data: IPatentBase) {
  if (!data.pn) {
    return EMPTY_PLACEHOLDER
  }
  const href = sharedCtx.router.generatorPatentDetailPath(data?.patent_id || '')
  return (
    <div>
      <BUpgradeWrap updateType={E_UPGRADE_TYPE.EXCEEDS_SEARCH_MAX_LIMIT} triggerPoint="PATENT_PN">
        <GLink href={href} encryptClass={data.fontClassName} name={data.pn} newTab={true} defaultColor="green" style="color: #1D8820"></GLink>
      </BUpgradeWrap>
      <div class="flex flex-wrap gap-1">{renderPatentTag(data)}</div>
    </div>
  )
}

export function renderPatentTag(data: IPatentBase) {
  if (data.curation) {
    return <BManualTag></BManualTag>
  }
  return null
}
export function renderPatentSpcPteTag(data: IPatentDetail) {
  if (data.extension?.length) {
    return data.extension.map((e) => <BPatentExtensionType extensionType={e.extension_type} extensionStatus={e.extension_status} />)
  }
  if (data.extension_type?.length) {
    return <BPatentExtensionType extensionType={data.extension_type[0]} color="rgb(25 118 210/var(--tw-text-opacity))" />
  }
  return null
}

export function renderEstimatedExpiryDate(data: IPatentDetail, lang: ILang) {
  return data.exdt ? <div class="patent-content">{formatePatentTime(data.exdt, lang)}</div> : <div>{EMPTY_PLACEHOLDER}</div>
}
export function renderPatentAuthorityType(data: IPatentDetail, lang: ILang) {
  const left = lang === 'CN' ? '（' : '('
  const right = lang === 'CN' ? '）' : ')'
  return data.patent_type ? (
    <div class="patent-content flex items-center">
      {renderPatentType(data.patent_type.slice(2), lang)}
      {left}
      <GCountryFlag name={data.patent_type.slice(0, 2)} useFullName showName={true}></GCountryFlag>
      {right}
    </div>
  ) : (
    <div>{EMPTY_PLACEHOLDER}</div>
  )
}
export function renderPatentType(type: string, lang: ILang) {
  interface IPatentTypeItem {
    CN: string
    EN: string
    JP?: string
  }
  const patentTypeMap: {
    [key: string]: IPatentTypeItem
  } = {
    A: {
      CN: '发明申请',
      EN: 'Applications',
    },
    B: {
      CN: '授权发明',
      EN: 'Patents',
    },
    D: {
      CN: '外观设计',
      EN: 'Designs',
    },
    U: {
      CN: '实用新型',
      EN: 'Utility models',
    },
  }
  // type keys = keyof typeof patentTypeMap
  return patentTypeMap[type] ? patentTypeMap[type][lang] || patentTypeMap[type].CN || '' : ''
}

export function renderStandardizedCurrentAssignee(data: IPatentDetail, lang: ILang) {
  return _renderStdPatentPeopleInDetail(data.current_assignee, lang)
}

export function renderCurrentAssignee(data: IPatentDetail, lang: ILang) {
  return _renderNotStdPatentPeopleInDetail(data.current_assignee, lang)
}
export function renderPatentCurrentAssigneeInCard(data: IPatentDetail, $i18n: IVueI18n) {
  return _renderStdPatentPeoplePopover(data.current_assignee, 3, $i18n)
}
export function renderPatentCurrentAssigneeInTable(data: IPatentDetail, $i18n: IVueI18n) {
  return _renderStdPatentPeoplePopover(data.current_assignee, 3, $i18n)
}

export function renderStandardizedOriginalAssignee(data: IPatentDetail, lang: ILang) {
  return _renderStdPatentPeopleInDetail(data.original_assignee, lang)
}
export function renderPatentOriginalAssigneeInCard(data: IPatentDetail, $i18n: IVueI18n) {
  return _renderStdPatentPeoplePopover(data.original_assignee, 3, $i18n)
}

export function renderOriginalAssignee(data: IPatentDetail, lang: ILang) {
  return _renderNotStdPatentPeopleInDetail(data.original_assignee, lang)
}
export function renderPatentOriginAssigneeInCard(data: IPatentDetail, $i18n: IVueI18n) {
  return _renderNotStdPatentPeoplePopover(data.original_assignee, getLang($i18n), 3)
}
export function renderPatentOriginAssigneeInTable(data: IPatentDetail, $i18n: IVueI18n) {
  return _renderNotStdPatentPeoplePopover(data.original_assignee, getLang($i18n), 3)
}

export function renderInventor(data: IPatentDetail, lang: ILang) {
  return _renderNotStdBracketsPatentPeopleInDetail(data.inventor, lang)
}

export function renderInventorOther(data: IPatentDetail, lang: ILang) {
  return _renderNotStdPatentPeopleInDetail(data.inventor_other, lang)
}
/**
 * 根据用户使用的网站的语言给出合适的标[原始/当前]申请人
 * 规则来自 core后端
 * EN/JP: nname_en > nname_jp > nname_kr > nname_latin > nname_other > cn_nname
 * CN/TW: cn_nname > nname_en > nname_jp > nname_kr > nname_latin > nname_other
 * @param people 标准人
 * @param lang 语言
 */
export function renderStdPeopleName(people: IPatentPeople, lang: ILang) {
  return lang === 'CN'
    ? people.normalized_name_cn || people.normalized_name || people.normalized_name_jp || people.normalized_name_kr || people.normalized_name_other
    : people.normalized_name || people.normalized_name_jp || people.normalized_name_kr || people.normalized_name_other || people.normalized_name_cn
}
/**
 * 渲染标准申请人元素
 */
export function renderStdPatentPeopleItem(item: IPatentPeople[], $i18n: IVueI18n, isNameNeedDegrade = false) {
  const firstItem = item[0]
  const firstItemOrganization = firstItem.organization
  if (firstItemOrganization) {
    return <BOrganizationItem data={firstItemOrganization} isNameNeedDegrade={isNameNeedDegrade}></BOrganizationItem>
  }
  return (
    <BEntityTag>
      <div class="truncate">{renderStdPeopleName(firstItem, getLang($i18n))}</div>
    </BEntityTag>
  )
}

export function renderPatentInformationSourceInCard(patentSource: IBaseDictItem[], empty = EMPTY_PLACEHOLDER) {
  if (!patentSource.length) return empty
  return (
    <div class="flex flex-wrap">
      {patentSource.map((o) => (
        <BInformationSourceTag tag={o} class="mr-1"></BInformationSourceTag>
      ))}
    </div>
  )
}

export function renderPatentInformationSourceInTable(patentSource: IBaseDictItem[]) {
  if (!patentSource.length) return EMPTY_PLACEHOLDER
  return (
    <div class="flex flex-wrap">
      {patentSource.map((o) => (
        <BInformationSourceTag tag={o} class="mr-1 mb-1"></BInformationSourceTag>
      ))}
    </div>
  )
}

export function renderLimitedPatentMask(params: {
  header?: string
  description?: string
  callback: (triggerPoint: string) => void | Promise<void>
  $i18n: IVueI18n
  imgSrc: any
}) {
  const { header, description, callback, $i18n, imgSrc } = params
  return (
    <div class="max-w-7xl relative">
      <img src={imgSrc} alt="" style={{ 'min-height': '200px' }} />
      <div class="absolute top-7 left-3">
        {header ? <div class="text-2xl font-semibold text-text-default">{header}</div> : null}
        {description ? <div class="text-sm font-normal my-4">{description}</div> : null}

        <div
          class="inline-flex items-center text-white-default bg-blue-default cursor-pointer py-1 px-3 rounded hover:bg-blue-hover"
          onClick={() => callback('PATENT_LIST')}
        >
          <span class="ml-0.5">{$i18n.t('common.upgradeNow')}</span>
        </div>
      </div>
    </div>
  )
}
export function renderPatentDrugType(data: IPatentDetail, $i18n: IVueI18n) {
  const types = getDicNames(data.drug_type_view || [], $i18n.locale.toUpperCase() as ILang)
  if (types.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  return renderLimited(data.drug_type_view || [], {
    limitCount: 10,
    render: (item) => <BDrugTypeTagItem data={item}></BDrugTypeTagItem>,
  })
}

/**
 * 渲染非标的专利人
 * @param data
 * @param lang
 * @returns
 */
function _renderNotStdPatentPeopleInDetail(data: IPatentPeople[] | undefined, lang: ILang) {
  if (!data?.length) {
    return <div class="patent-content">{EMPTY_PLACEHOLDER}</div>
  }
  return (
    <div>
      {_classifiCationByIdWithFilterKeys(data, ['display_name', 'organization', 'name'])?.map((userList) => {
        const userObj = _getUserObjByLang(userList, lang)
        return (
          <div>
            <div class="patent-content">{userObj.name}</div>
            {userObj.address?.address && <div class="patent-content-supplement text-black-light">{userObj.address?.address}</div>}
          </div>
        )
      })}
    </div>
  )
}
/**
 *  渲染非标的专利人,并在括号中显示地址
 * @param data
 * @param lang
 * @returns
 */
function _renderNotStdBracketsPatentPeopleInDetail(data: IPatentPeople[] | undefined, lang: ILang) {
  if (!data?.length) {
    return <div class="patent-content">{EMPTY_PLACEHOLDER}</div>
  }
  return (
    <div>
      {_classifiCationByIdWithFilterKeys(data, ['display_name', 'organization', 'name'])?.map((userList) => {
        const userObj = _getUserObjByLang(userList, lang)
        return (
          <div>
            <div class="patent-content">
              {userObj.name}
              {userObj.address?.address && <span class="patent-content-supplement text-black-light">({userObj.address?.address})</span>}
            </div>
          </div>
        )
      })}
    </div>
  )
}
/**
 * 渲染标准的专利人
 * @param data
 * @param lang
 * @returns
 */
function _renderStdPatentPeopleInDetail(data: IPatentPeople[] | undefined, lang: ILang) {
  if (!data?.length) {
    return <div class="patent-content">{EMPTY_PLACEHOLDER}</div>
  }
  const d = _classifiCationByIdWithFilterKeys(data)
  if (!d.length) {
    return <div class="patent-content">{EMPTY_PLACEHOLDER}</div>
  }
  return (
    <div>
      {d.map((userList) => {
        const firstItem = userList[0]
        const firstItemOrganization = firstItem.organization
        // 如果有实体链接显示实体链接卡片
        if (firstItemOrganization) {
          return (
            <div>
              <div class="patent-content">
                <BOrganizationItem data={firstItemOrganization}></BOrganizationItem>
              </div>
            </div>
          )
        }
        return (
          <div class="patent-content" style="margin-right: 4px; margin-bottom: 8px;">
            <BEntityTag>{renderStdPeopleName(firstItem, lang)}</BEntityTag>
          </div>
        )
      })}
    </div>
  )
}
/**
 *  以弹窗显示更多的标准申请人
 */
function _renderStdPatentPeoplePopover(data: IPatentPeople[] | undefined, limitCount = 1, $i18n: IVueI18n) {
  if (!data?.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderPopoverLimited(_classifiCationByIdWithFilterKeys(data), {
    limitCount,
    render: (item) => renderStdPatentPeopleItem(item, $i18n),
  })
}
/**
 *  以弹窗显示更多的非标准申请人
 */
function _renderNotStdPatentPeoplePopover(data: IPatentPeople[] | undefined, lang: ILang, limitCount = 1) {
  const length = data?.length
  if (!length) {
    return EMPTY_PLACEHOLDER
  }
  return renderPopoverLimited(_classifiCationByIdWithFilterKeys(data), {
    limitCount,
    render: (item, index) => {
      return (
        <span class="text-sm pt-1 block truncate">
          {_getUserObjByLang(item, lang).name}
          {index < length - 1 ? ';' : ''}
        </span>
      )
    },
  })
}

export function _classifiCationByIdWithFilterKeys(
  target?: IPatentPeople[],
  keys: ('organization' | 'display_name' | 'name')[] = ['organization', 'display_name']
): Array<IPatentPeople[]> {
  if (target?.length) {
    return Object.entries(
      groupBy(
        target.filter((item) => keys.some((k) => item[k])),
        (item) => item.name_id
      )
    ).map(([key, value]) => value)
  }
  return []
}
function _getUserObjByLang(userIdList: IPatentPeople[], lang: ILang) {
  const curUserObjByLang = userIdList.find((user) => user.lang === lang)
  if (curUserObjByLang) {
    return curUserObjByLang
  }
  return userIdList[0]
}
function _findNameObjByLang(items: IBaseLangName[], lang: ILang): IBaseLangName | undefined {
  if (items.length) {
    return (
      items.find((item) => {
        return item.lang === lang
      }) || items[0]
    )
  }
}
function _matchingSearchContent(items: IBaseLangName[], lang: ILang, searchContent: string): boolean {
  const nameObj = _findNameObjByLang(items, lang)
  const reg = new RegExp(searchContent, 'i')
  return !!nameObj && nameObj.name.search(reg) !== -1
}

function _filterIndication(items: any[], searchContent: string, lang: ILang): any[] {
  return (items as IBaseIdViewItem[]).filter((item) => {
    return _matchingSearchContent(item.disease_name as IBaseLangName[], lang, searchContent)
  })
}

function _filterDrug(items: any[], searchContent: string, lang: ILang): any[] {
  return (items as IBaseDrugInfo[]).filter((item) => {
    return _matchingSearchContent(item.drug_name as IBaseLangName[], lang, searchContent)
  })
}
function _renderPatentViewAllDrug(data: IBaseDrugInfo[]) {
  return renderLimitedDrug(data || [], data ? data.length : 0)
}
function _renderPatentViewAllIndication(data: IBaseIdViewItem[]) {
  return renderLimitedDisease(data || [], data ? data.length : 0)
}
function _renderPatentViewAllTargets(data: IBaseTargetInfo[]) {
  return renderLimitedTargets(data || [], data ? data.length : 0)
}
