import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from '../..'
import { BSignatureImg } from '../BSignatureImg/BSignatureImg'
import defaultCompanyLogo from './default-company-logo.svg'
import defaultPeopleLogo from './default-people-logo.svg'

export const BIcon = defineComponent({
  name: 'BIcon',
  props: {
    src: {
      type: String,
    },
    size: {
      type: Number,
      default: 16,
    },
    type: {
      type: String as PropType<'people' | 'company'>,
      required: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    isSignature: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { type } = toRefs(props)
    const shape = computed(() => (type.value === 'company' ? 'square' : 'circle'))
    const fallbackSrc = computed<string>(() => (type.value === 'company' ? defaultCompanyLogo : defaultPeopleLogo))

    return {
      shape,
      fallbackSrc,
    }
  },
  render() {
    if (!this.isSignature) {
      return <GIcon key={this.src} src="" border={this.border} shape={this.shape} fallbackSrc={this.fallbackSrc} size={this.size}></GIcon>
    }
    if (this.src) {
      return (
        <BSignatureImg
          key={this.src}
          src={this.src}
          scopedSlots={{
            default: (data: { src: string }) => (
              <GIcon key={data.src} src={data.src} border={this.border} shape={this.shape} fallbackSrc={this.fallbackSrc} size={this.size}></GIcon>
            ),
          }}
          {...{
            on: this.$listeners,
          }}
        ></BSignatureImg>
      )
    }
    return <GIcon key={this.src} src={this.src} border={this.border} shape={this.shape} fallbackSrc={this.fallbackSrc} size={this.size}></GIcon>
  },
})
