import { ElInput } from '@pharmsnap/shared/element-ui'
import { E_ALIGN_DIRECTION, IGRadioItem } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, reactive, ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { PatentFieldSelection } from '../../ui/PatentFieldSelection/PatentFieldSelection'
import $classes from './TimeRangeField.module.scss'

const reg = /^\d{4}$/
export const TimeRangeField = defineComponent({
  name: 'TimeRangeField',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /** 除All、自定义时间外，其他时间范围 */
    otherTimeConfig: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** 当前选中的时间：例如：all、custom、 10 、20 */
    selected: {
      type: String,
      required: true,
    },
    /** 自定义时间范围 */
    customTimeRange: {
      type: Array as PropType<number[]>,
      default: () => [1780, dayjs().year()],
    },
    /** 自定义时间默认值，不传使用customTimeRange */
    defaultCustomTime: {
      type: Array as PropType<number[]>,
    },
    title: {
      type: String,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const customTime = reactive({
      min: 0,
      max: 0,
    })
    const selectedTime = ref<string>('all')

    const timeList = computed<IGRadioItem[]>(() => {
      const list: IGRadioItem[] = [
        {
          value: 'all',
          label: ins?.proxy.$tc('time.all') as string,
        },
      ]
      if (props.otherTimeConfig.length) {
        props.otherTimeConfig.forEach((time) => {
          list.push({
            value: time,
            label: ins?.proxy.$t('time.lastYears', { count: time }) as string,
          })
        })
      }
      list.push({
        value: 'custom',
        label: '',
        customRender: renderCustomTime,
      })
      return list
    })

    const showError = computed(() => selectedTime.value === 'custom' && !(validateMin() && validateMax()))

    watch(
      () => props.selected,
      () => (selectedTime.value = props.selected),
      {
        immediate: true,
      }
    )
    watch(
      [() => props.defaultCustomTime, () => props.customTimeRange],
      () => {
        customTime.min = props.defaultCustomTime?.[0] || props.customTimeRange[0]
        customTime.max = props.defaultCustomTime?.[1] || props.customTimeRange[1]
      },
      {
        immediate: true,
      }
    )

    const validateYear = (num: number) => {
      if (num >= props.customTimeRange[0] && num <= props.customTimeRange[1] && reg.test(String(num))) {
        return true
      }
      return false
    }

    const validateMin = () => {
      if (validateYear(customTime.min) && customTime.min <= customTime.max) {
        return true
      }
      return false
    }

    const validateMax = () => {
      if (validateYear(customTime.max) && customTime.max >= customTime.min) {
        return true
      }
      return false
    }

    const renderCustomTime = () => {
      return (
        <div class={$classes.customTime}>
          <ElInput
            vModel={customTime.min}
            maxLength={4}
            disabled={selectedTime.value !== 'custom'}
            class={[$classes.input, !validateMin() && $classes.inputError]}
          ></ElInput>
          <span class="px-2 text-sm leading-5 text-text-t1">{ins?.proxy.$t('time.to')}</span>
          <ElInput
            vModel={customTime.max}
            maxLength={4}
            disabled={selectedTime.value !== 'custom'}
            class={[$classes.input, !validateMax() && $classes.inputError]}
          ></ElInput>
          <div class={[$classes.errorTips, showError.value ? 'visible' : 'invisible']}>{ins?.proxy.$tc('time.error')}</div>
        </div>
      )
    }

    const handleChangeSelect = (val: string) => (selectedTime.value = val)
    const handleApply = () => {
      let selectedValue: string | number[] = 'all'
      if (selectedTime.value === 'custom') {
        selectedValue = [Number(customTime.min), Number(customTime.max)]
      } else if (selectedTime.value !== 'all') {
        const currentYear = dayjs().year()
        selectedValue = [dayjs().subtract(Number(selectedTime.value), 'years').year(), currentYear]
      }
      emit('apply', { type: selectedTime.value, value: selectedValue })
    }

    return {
      timeList,
      selectedTime,
      showError,
      handleChangeSelect,
      handleApply,
    }
  },
  methods: {
    renderTimeRange() {
      return (
        <PatentFieldSelection
          title={this.title}
          options={this.timeList}
          value={this.selectedTime}
          align={E_ALIGN_DIRECTION.VERTICAL}
          onChangeField={this.handleChangeSelect}
          class={$classes.timeRange}
        ></PatentFieldSelection>
      )
    },
    renderButton() {
      if (this.isFullScreen) {
        return (
          <button class="pt-ui-btn mt-4" onClick={this.handleApply} type="submit" disabled={this.showError}>
            {this.$tc('apply')}
          </button>
        )
      }
      return (
        <div class={$classes.buttons}>
          <button class="pt-ui-btn mr-2" onClick={() => this.$emit('cancel')} type="default" data-size="small">
            {this.$tc('common.cancel')}
          </button>
          <button class="pt-ui-btn" onClick={this.handleApply} type="submit" disabled={this.showError} data-size="small">
            {this.$tc('apply')}
          </button>
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderTimeRange()}
        {this.renderButton()}
      </div>
    )
  },
})
