import { E_NODATA_SIZE } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import chartSvgImg from './chart-no-data.svg'
import $classes from './GEmpty.module.scss'
import svgImg from './no-data.svg'

export const GEmpty = defineComponent({
  name: 'GEmpty',
  props: {
    size: {
      type: String as PropType<E_NODATA_SIZE>,
      default: E_NODATA_SIZE.MIDDLE,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    htmlDesc: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: true,
    },
    titleClass: {
      type: String,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    hasBg: {
      type: Boolean,
      default: false,
    },
    isChart: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: '',
    },
  },
  render() {
    const cls = [
      $classes.gNoData,
      this.containerClass,
      this.center ? $classes.gNoDataCenter : '',
      this.hasBg || this.isChart ? $classes.gNoDataBg : '',
    ]
    if (this.size === E_NODATA_SIZE.MIDDLE) {
      cls.push($classes.gNoDataMiddle)
    } else if (this.size === E_NODATA_SIZE.BIG) {
      cls.push($classes.gNoDataBig)
    } else if (this.size === E_NODATA_SIZE.SMALL) {
      cls.push($classes.gNoDataSmall)
    }

    const content = (
      <div class={cls}>
        <div style={{ backgroundImage: this.isChart ? `url(${chartSvgImg})` : `url(${svgImg})` }} class={$classes.gNoDataCover}></div>
        <div v-show={this.showTitle} class={this.titleClass ? [$classes.gNoDataTitle, this.titleClass] : $classes.gNoDataTitle}>
          {this.title ? this.title : this.$tc('common.noData')}
        </div>
        {this.desc || this.htmlDesc ? (
          <div domPropsInnerHTML={this.htmlDesc || this.desc} class={$classes.gNoDataDesc}></div>
        ) : this.$slots.desc ? (
          <div class={$classes.gNoDataDesc}>{this.$slots.desc}</div>
        ) : null}
      </div>
    )

    // return this.center ? <div class={[$classes.container, this.hasBg ? $classes.containerBg : '']}>{content}</div> : content
    return content
  },
})
