import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import { getChartColor } from '@patsnap/synapse_domain'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/src/constants/enum-lang/org'
import type { CallbackDataParams, XAXisOption, YAXisOption, ZRColor } from 'echarts/types/dist/shared'
import { includes } from 'lodash'
import { IUseAnalysisOneDimChartEntityOptions } from '../../type'
import { useAnalysisOneDimChartEntity } from '../useAnalysisOneDimChartEntity'

export function useAnalysisDrugOneDimChartEntity(options: Omit<IUseAnalysisOneDimChartEntityOptions, 'getChartConfig'>) {
  const getPieChartConfig: IUseAnalysisOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField } = config
    if (aggField === 'entity_type') {
      return {
        categoryPicker(item, locale) {
          return ORG_ENTITY_LANG[item.key] ? ORG_ENTITY_LANG[item.key][locale] : ''
        },
        color: getChartColor(config.aggItems.length),
      }
    }
  }

  const getBarChartConfig: IUseAnalysisOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField, aggItems } = config
    if (includes(['GLOBAL_HIGHEST_DEV_STATUS', 'DEV_STATUS'], aggField)) {
      return {
        sorter: (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        },
        barMaxWidth: 20,
        showLabel: true,
        categoryPicker(item, locale) {
          if (item.key === '-1') {
            return locale === 'cn' ? '非在研' : 'Inactive'
          }
          return locale === 'cn' ? item.display_name_cn || item.display_name_en : item.display_name_en || item.display_name_cn
        },
        xAxisNameRotate: aggItems.length > 9 ? 35 : 0,
      }
    }
    if (aggField === 'country_id') {
      return {
        sorter: (a, b) => {
          return b.count - a.count
        },
        optionAdjust: (op) => {
          const temXAxis = op.xAxis
          const colorMap = getChartColor(5)
          return {
            ...op,
            xAxis: {
              ...(op.yAxis as XAXisOption),
              nameLocation: 'middle',
              nameGap: 28,
            },
            yAxis: [
              {
                ...(temXAxis as YAXisOption),
                color: '#020A1A',
                inside: true,
                nameLocation: 'end',
                nameGap: 14,
                inverse: true,
                name: '',
                axisLabel: {
                  ...(temXAxis as YAXisOption).axisLabel,
                  inside: true,
                  margin: 0,
                  padding: [0, 0, 10, 0],
                  verticalAlign: 'bottom',
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
              },
            ],
            dataset: {
              ...op.dataset,
              dimensions: ['count', 'name'],
            },
            series: {
              ...op.series,
              encode: {
                x: 'count',
                y: 'name',
              },
              itemStyle: {
                color: ((params: CallbackDataParams) => colorMap[params.dataIndex]) as ZRColor,
              },
            },
          }
        },
        barMaxWidth: 16,
      }
    }

    return {
      showLabel: true,
    }
  }

  return useAnalysisOneDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'bar') return getBarChartConfig(type, config)
      if (type === 'pie') return getPieChartConfig(type, config)
      return
    },
  })
}
