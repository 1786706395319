import { reactive } from '@vue/composition-api'
import { ILoadingService } from '../types'

export function useLoadingService(): ILoadingService {
  const state = reactive<ILoadingService['state']>({
    loading: true,
    total: 0,
    uncollapse_total: 0,
    sub_total: 0,
  })

  const action: ILoadingService['action'] = {
    setLoading: (val) => {
      state.loading = val
    },
    setTotal: (val: number) => {
      state.total = val
    },
    setUnCollapseTotal: (val) => {
      state.uncollapse_total = val || 0
    },
    setSubTotal: (val) => {
      state.sub_total = val || 0
    },
  }

  return {
    state,
    action,
  }
}
