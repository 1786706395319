import '@patsnap-ui/icon/assets/solid/ChartChunk.svg'
import {
  ConversationChatType,
  CreateConversationParams,
  EmailAlertStatusPayload,
  GotoSearchResultOptions,
  PageContext,
} from '@patsnap/ls360_sdk_shared'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { CopilotCommand } from '@pharmsnap/shared/src/types'
import { CHAT_DIALOG_ID } from '@pharmsnap/shared/src/utils/business/ls360'
import { computed, getCurrentInstance, inject, nextTick, provide, ref, watch } from '@vue/composition-api'
import { openNewTab, setLocalStorage, sleep } from 'pharmsnapMF_shared/utils'
import { VUE_APP_LS360_URL } from '../config'
import router from '../router'
import { E_ROUTER_NAME } from '../types/enum'
import { createChatUUid } from '../utils/business/ls360'
import { query2Base64String } from '../utils/business/search'
import { useLs360SynapseWujieEvents } from './useLs360SynapseWujieEvents'

const getChatType = (command: CopilotCommand): ConversationChatType => {
  switch (command) {
    case CopilotCommand.explain: {
      return 'explain'
    }
    case CopilotCommand.translate: {
      return 'translate'
    }
    case CopilotCommand.summarize: {
      return 'summarize'
    }
    case CopilotCommand.page_summarize: {
      return 'page_summarize'
    }
    default:
      return 'normal'
  }
}
export const ASSISTANT_DIALOG_VISIBLE_KEY = 'ASSISTANT_DIALOG_VISIBLE'
const LS360_SYMBOL = Symbol('LS360_SYMBOL')
// 这个不是 hook... 这里不要用其他 vue hook，要不会有很多问题
export const useLs360Provider = () => {
  const dialogVisible = ref(false)
  const { localeUpcase, tsText } = useLocale()
  const { bus } = useLs360SynapseWujieEvents()
  const ins = getCurrentInstance()
  const route = computed(() => ins?.root.proxy.$route)
  /** 弹窗是否被渲染 */
  const renderDialog = ref(false)
  /** isPrinting: 理解问题 or 打字中 */
  const isPrinting = ref(false)
  /** assistant 是否挂载 */
  const mounted = ref(false)
  /** 返回顶部的按钮 right 距离 */
  const copilotDialogRightDistanceToViewport = ref(0)
  /** pageContext 页面总结用 */
  const pageContext = ref<PageContext>()
  const summarizeCallback = ref<() => void>()
  /** 当前页面是否支持设置邮件提醒,以及不支持原因 */
  const emailAlertStatus = ref<EmailAlertStatusPayload>({
    support: true,
    hasSetAlert: false,
    opened: false,
  })
  /**
   * 初始化邮件提醒状态
   * @returns
   */
  function initEmailAlertStatus(): EmailAlertStatusPayload {
    if (!router.currentRoute)
      return {
        support: false,
        notSupportReason: 'router.currentRoute is undefined',
      }
    const { name } = router.currentRoute
    if (!name) {
      return {
        support: false,
        notSupportReason: 'router.currentRoute.name is undefined',
      }
    }
    const canSetEmailAlertListPageNames: E_ROUTER_NAME[] = [
      E_ROUTER_NAME.DRUG_LIST,
      E_ROUTER_NAME.DRUG_DEAL_LIST,
      E_ROUTER_NAME.CLINICAL_PROGRESS_LIST,
      E_ROUTER_NAME.PATENT_LIST,
      E_ROUTER_NAME.TRANSLATIONAL_MEDICINE_LIST,
      E_ROUTER_NAME.DRUG_GROUP_BY_DISEASE,
      E_ROUTER_NAME.DRUG_GROUP_BY_DEV_ORG,
      E_ROUTER_NAME.DRUG_GROUP_BY_TARGET,
      E_ROUTER_NAME.DRUG_GROUP_BY_DRUG_TYPE,
    ]
    const canSetEmailAlertDetailPageNames: E_ROUTER_NAME[] = [
      E_ROUTER_NAME.DRUG,
      E_ROUTER_NAME.CLINICAL_DETAIL,
      E_ROUTER_NAME.DISEASE,
      E_ROUTER_NAME.ORGANIZATION,
      E_ROUTER_NAME.TARGET,
      E_ROUTER_NAME.DRUG_TYPE,
      E_ROUTER_NAME.PATENT_DETAIL,
    ]
    if (![...canSetEmailAlertListPageNames, ...canSetEmailAlertDetailPageNames].includes(name as E_ROUTER_NAME)) {
      const notSupport = tsText('抱歉，当前页面不支持设置邮件提醒。', 'Sorry, the current page does not support setting email alerts.')
      return {
        support: false,
        notSupportReason: notSupport,
        notSupportTooltip: notSupport,
      }
    }
    if (canSetEmailAlertListPageNames.includes(name as E_ROUTER_NAME)) {
      const tab = router.currentRoute.query.tab
      if (tab === 'analysis') {
        const notSupport = tsText('抱歉，当前页面不支持设置邮件提醒。', 'Sorry, the current page does not support setting email alerts.')
        return {
          support: false,
          notSupportReason: notSupport,
          notSupportTooltip: notSupport,
        }
      }
    }
    return emailAlertStatus.value
  }
  const updateDistance = () => {
    const copilotElement = document.getElementById(CHAT_DIALOG_ID)
    copilotDialogRightDistanceToViewport.value = copilotElement?.getBoundingClientRect()?.width || 0
  }
  function addWujieEventListener() {
    bus.$on('micro-ls360-synapse:share-chat', (payload) => {
      const url = new URL(`${VUE_APP_LS360_URL}/chat/${payload.chatId}`)
      url.searchParams.set('action', 'open_share')
      if (payload.chatItemId) {
        url.searchParams.set('chat_item_id', payload.chatItemId)
      }
      trackingEvent('GOTO_LS360', {
        common1: payload.chatId,
        trigger_point: 'SIDE_BAR_SHARE',
      })
      window.open(url.toString(), '_blank')
    })
    bus.$on('micro-ls360-synapse:deep-chat', (payload) => {
      const chatId = createChatUUid()
      const url = new URL(`${VUE_APP_LS360_URL}/chat/search/${chatId}`)
      const deepSearchPreviousItemId = payload.deepSearchPreviousItemId
      url.searchParams.set('action', 'open_deep_chat')
      if (deepSearchPreviousItemId) {
        url.searchParams.set('deepSearchPreviousItemId', deepSearchPreviousItemId)
      }
      trackingEvent('GOTO_LS360', {
        common1: chatId,
        trigger_point: 'SIDE_BAR_DEEP_SEARCH',
      })
      window.open(url.toString(), '_blank')
    })
  }
  addWujieEventListener()
  const updateMicroPageContext = () => {
    bus.$emit('main-synapse:update-page-summarize-context', pageContext.value)
  }
  const actions = {
    setWujieMounted() {
      mounted.value = true
    },
    awaitWujieMounted: async () => {
      actions.showAssistantDialog(true)
      // 轮询等 LS360SiderBarDialog 加载完
      await Promise.race([
        (async () => {
          while (!mounted.value) {
            await sleep(100)
          }
        })(),
        sleep(3000),
      ])
    },
    scrollToBottom: () => {
      // TODO:
      // bus.$on()
    },
    showAssistantDialog: (visible: boolean) => {
      renderDialog.value = true
      dialogVisible.value = visible
      setLocalStorage(ASSISTANT_DIALOG_VISIBLE_KEY, visible.toString())
      nextTick(() => updateDistance())
    },
    setPrinting: (printing = false) => {
      isPrinting.value = printing
    },
    handleSelectEmitCommand: async (command: CopilotCommand, selectedContent: string, isResend = false) => {
      await actions.awaitWujieMounted()
      if (command === CopilotCommand.start_chat) {
        bus.$emit('main-synapse:start-chat', selectedContent)
        return
      }
      if (command === CopilotCommand.start_chat_sent_immediately) {
        const req: CreateConversationParams = {
          query: selectedContent,
          chat_type: getChatType(command),
        }
        bus.$emit('main-synapse:start-underline-chat', req)
        return
      }
      if (command === CopilotCommand.page_summarize) {
        const req: CreateConversationParams = {
          query: selectedContent,
          chat_type: getChatType(command),
          page_context: actions.getPageContext(),
        }
        bus.$emit('main-synapse:start-underline-chat', req)
        return
      }

      const req: CreateConversationParams = {
        query: selectedContent,
        chat_type: getChatType(command),
        page_context: {
          page_name: route.value?.name || '',
          page_url: window.location.href || '',
          params: {
            language: localeUpcase.value,
            content: selectedContent,
          },
        },
      }
      bus.$emit('main-synapse:start-underline-chat', req)
    },
    gotoSearchResult(options: GotoSearchResultOptions) {
      const { baseUrl, query, newTab = true, dataType = 'all', collapse } = options
      const queryParam = {
        data_type: dataType,
        query,
      }
      if (collapse) {
        Object.assign(queryParam, { collapse })
      }
      const url = new URL(baseUrl)
      url.searchParams.append('query', query2Base64String(queryParam))
      // 添加这个标识是为了告诉网关将流量打到正式版而不是游客版
      url.searchParams.append('login_success', 'true')
      if (newTab) {
        openNewTab(url.toString())
      } else {
        window.location.href = url.toString()
      }
    },
    /** 设置页面总结参数 */
    setPageContext(
      pageContextParams: Omit<PageContext['params'], 'language'>,
      ctx?: Partial<Omit<PageContext, 'params'> & { callback?: () => void }>
    ) {
      const { page_url = window.location.href || '', page_name = route.value?.name || '', callback } = ctx || {}
      pageContext.value = {
        page_name,
        page_url,
        params: {
          ...(pageContextParams as any),
          language: localeUpcase.value,
        },
      }
      summarizeCallback.value = callback
      updateMicroPageContext()
    },
    /** 获取页面总结参数 */
    getPageContext() {
      return pageContext.value
    },
    /** 清空页面总结参数 */
    clearPageContext() {
      pageContext.value = undefined
      summarizeCallback.value = undefined
      updateMicroPageContext()
    },
    onCallbackSummarize() {
      summarizeCallback.value?.()
    },
    position2Anchor(anchor: string) {
      if (!anchor) return
      const anchorElement = document.getElementById(anchor)
      // Scroll to the anchor element
      anchorElement && anchorElement.scrollIntoView()
    },
    /**
     * 更新邮件提醒状态
     * @param payload
     */
    updateEmailAlertStatus: (payload: EmailAlertStatusPayload) => {
      emailAlertStatus.value = payload
      bus.$emit('main-synapse:update-email-alert-status', emailAlertStatus.value)
    },
    initEmailAlertStatus,
  }

  watch(
    () => route.value?.name,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        actions.clearPageContext()
      }
    },
    {
      immediate: true,
    }
  )
  const res = {
    state: {
      emailAlertStatus,
      dialogVisible,
      renderDialog,
      isPrinting,
      pageContext,
      copilotDialogRightDistanceToViewport,
    },
    actions,
  }
  provide(LS360_SYMBOL, res)
  return res
}

export const useLs360ChatStore = () => {
  const chatStore = inject(LS360_SYMBOL)
  return chatStore as ReturnType<typeof useLs360Provider>
}
