/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlowFunction } from '@pharmsnap/shared/types'
import { curry } from 'lodash'
import { ECOption, Plot } from '../core/plot'
import { BasicLineChart } from '../type'
import { flow, mergeTooltip, mergeXAxis, mergeYAxis } from '../util'
import { adjustYAxisLabelFormatter, generateRectTooltipFormatter, getChartColor, getTooltip, getXAxis, getYAxis } from './config'

export class Line extends Plot {
  public type = 'line'
  protected getDefaultOption(options: ECOption): ECOption {
    const mergeRectChartXAxis: FlowFunction<ECOption> = curry(mergeXAxis)(getXAxis)
    const mergeRectChartYAxis: FlowFunction<ECOption> = curry(mergeYAxis)(getYAxis)
    const mergeRectTooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const chartOptions = flow<ECOption>(mergeRectTooltip, mergeRectChartXAxis, mergeRectChartYAxis)(options)
    return chartOptions
  }
  protected adaptor(params: BasicLineChart): ECOption {
    const { tooltip, legend, xAxis, yAxis, series, dataset, grid, color } = params

    // @ts-ignore
    const options: ECOption = {
      ...(color ? { color } : { color: getChartColor(series.length) }),
      grid,
      tooltip: {
        ...tooltip,
        formatter: tooltip?.formatter || generateRectTooltipFormatter(series.map(() => yAxis.dataType || { valueType: 'text' })),
      },
      legend,
      xAxis: [xAxis],
      yAxis: [yAxis.dataType && yAxis.dataType.valueType === 'count' ? { ...yAxis, minInterval: 1 } : yAxis],
      series: series.map((item) => ({ ...item, type: 'line' })),
      dataset,
    }

    return flow<ECOption>(curry(adjustYAxisLabelFormatter)((yAxis.dataType && yAxis.dataType.valueType) || 'text'))(options)
  }
}
