import { ISearchCollapse } from '@pharmsnap/shared/src/types'

export class PatentCollapseSetting {
  defaultCollapseSetting: ISearchCollapse = {
    field: 'APNO_FACET',
    rules: [
      { type: 'max', field: 'PHS_LABEL_TYPE' },
      { type: 'max', field: 'PBDT_YEARMONTHDAY' },
    ],
  }

  localStorageKey: string

  constructor(localStorageKey: string) {
    this.localStorageKey = localStorageKey
  }

  get collapseSetting() {
    return this.localStorageSetting || this.defaultCollapseSetting
  }

  set collapseSetting(setting: ISearchCollapse) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(setting))
  }

  get localStorageSetting() {
    const data = localStorage.getItem(this.localStorageKey)
    if (!data) {
      return null
    }
    try {
      const jsonData = JSON.parse(data) as ISearchCollapse
      return jsonData
    } catch (error) {
      return null
    }
  }

  static instances = new Map<string, PatentCollapseSetting>()

  static getSingleton(localStorageKey = 'patent_collapse_setting') {
    if (!this.instances.has(localStorageKey)) {
      this.instances.set(localStorageKey, new this(localStorageKey))
    }
    return this.instances.get(localStorageKey) as PatentCollapseSetting
  }
}
