import { IBaseDictItem } from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { ElTag } from '@pharmsnap/shared/src/element-ui'
import { getDictItemName } from '@pharmsnap/shared/src/utils'
import { defineComponent, PropType } from '@vue/composition-api'
import { ElementUIComponentSize } from 'element-ui/types/component'
import { VNode } from 'vue'

const evaluationColorMap: Record<string, string> = {
  b768d49203dc39919ae9651a0a0155ab: '#45A321',
  '3289297424e03eda9b788c083bbf3147': '#45A321',
  '0efa04d99d83316f84d2618cf4e013d4': '#DC7C08',
  '15f2cafc40333f8b92096bd4a26c0b26': '#DC7C08',
  '501e92dcf2b133f6a9ebae6117b59236': '#DC7C08',
  ffb9356ff2b73a85875c92fa7ea03b8b: '#495973',
}

export const BEvaluationTag = defineComponent({
  name: 'BEvaluationTag',
  props: {
    evaluationId: {
      type: String,
    },
    evaluationIdView: {
      type: Object as PropType<IBaseDictItem>,
    },
    size: {
      type: String as PropType<ElementUIComponentSize>,
      default: 'small',
    },
  },
  setup() {
    const { localeUpcase } = useLocale()
    return { localeUpcase }
  },
  render() {
    if (!this.evaluationId || !this.evaluationIdView) {
      return {} as VNode
    }

    const color = evaluationColorMap[this.evaluationId]
    const dictName = getDictItemName(this.evaluationIdView, this.localeUpcase)

    return (
      <ElTag color={color} size={this.size} effect="dark" class="border-transparent inline-flex items-center leading-none">
        {dictName}
      </ElTag>
    )
  },
})
