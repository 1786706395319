import { FlowFunction } from '@pharmsnap/shared/types'
import { curry } from 'lodash'
import { ECOption, Plot } from '../core/plot'
import { BasicGraphChart } from '../type'
import { flow, mergeSeries, mergeTooltip } from '../util'
import { getSeries, getTooltip } from './config'

export class Graph extends Plot {
  public type = 'graph'

  protected getDefaultOption(options: ECOption): ECOption {
    const tooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const mergeRectSeries: FlowFunction<ECOption> = curry(mergeSeries)(getSeries)
    return flow<ECOption>(tooltip, mergeRectSeries)(options)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  protected adaptor(params: BasicGraphChart): ECOption {
    const { series, color, tooltip } = params

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const options: ECOption = {
      ...(color ? { color } : {}),
      tooltip,
      series,
    }

    return options
  }
}
