import { ISearchCollapse, ISearchCollapseRule } from '@pharmsnap/shared/types'
import { getCurrentInstance, inject, InjectionKey, provide, reactive, Ref, watch } from '@vue/composition-api'
import { cloneDeep, uniqBy } from 'lodash'
import {
  CN_FAMILY_COUNTRY_2_CODE_LIST,
  COLLAPSE_FIELDS_OPTION_RECORD,
  COUNTRY_PLACEHOLDER,
  DEFAULT_TYPE_RECORD,
  getInitOptionRecord,
  OPTION_APD_FIELD,
  OPTION_COUNTRY_FIELD,
  OPTION_PBDT_FIELD,
  PATENT_COLLAPSE_ALL_FIELD,
  PATENT_COLLAPSE_FIELDS,
  PATENT_OTHER_COLLAPSE_FIELDS,
  US_FAMILY_COUNTRY_2_CODE_LIST,
} from './config'
import { IPtCollapseAction, IPtCollapseState } from './type'

const ptCollapseStateProvideKey: InjectionKey<IPtCollapseState> = Symbol('ptCollapseStateProvideKey')
const ptCollapseActionProvideKey: InjectionKey<IPtCollapseAction> = Symbol('ptCollapseActionProvideKey')

export function usePatentCollapse(
  defaultCollapseField: Ref<string>,
  defaultCollapseRules: Ref<ISearchCollapseRule[]>,
  options: {
    // 部署的区域
    serviceArea?: 'cn' | 'en'
  }
) {
  const serviceArea = options.serviceArea || 'cn'
  const ins = getCurrentInstance()
  const state = reactive<IPtCollapseState>({
    collapseField: defaultCollapseField.value === '' ? PATENT_COLLAPSE_ALL_FIELD : defaultCollapseField.value,
    collapseRules: defaultCollapseRules.value || [],
    collapseOptionRecord: getInitOptionRecord(defaultCollapseRules.value || [], serviceArea),
    collapseFieldOptions: PATENT_COLLAPSE_FIELDS.map((i) => ({ value: i, label: ins?.proxy.$tc(`patent.collapseFields.${i}`) || '' })),
  })
  const action: IPtCollapseAction = {
    updateOptionRecord,
    addRule,
    deleteRule,
    isUnCollapseField,
    pickCollapseParams,
  }

  watch([defaultCollapseField, defaultCollapseRules], () => {
    state.collapseField = defaultCollapseField.value
    state.collapseRules = defaultCollapseRules.value
    state.collapseOptionRecord = getInitOptionRecord(defaultCollapseRules.value || [], serviceArea)
  })

  // 对record中所有符合变更条件的规则进行更新
  function updateOptionRecord(originRule: ISearchCollapseRule, rule: ISearchCollapseRule) {
    const { value, field } = rule
    // 公开日和申请日修改，不用调整类型
    const isAPDOrPBDT = [originRule.field, rule.field].every((t) => [OPTION_APD_FIELD, OPTION_PBDT_FIELD].includes(t))
    PATENT_OTHER_COLLAPSE_FIELDS.forEach((i) => {
      const collapse = state.collapseOptionRecord[i]
      const rules = collapse.rules || []
      // 找到原来的规则，且这个规则的选项中支持变更的规则
      const found = rules.find((i) => i.field === originRule.field && i.options && i.options.includes(field))
      if (found) {
        found.value =
          rule.field === OPTION_COUNTRY_FIELD && originRule.field !== OPTION_COUNTRY_FIELD
            ? serviceArea === 'cn'
              ? CN_FAMILY_COUNTRY_2_CODE_LIST.join(',')
              : US_FAMILY_COUNTRY_2_CODE_LIST.join(',')
            : value
        found.type = isAPDOrPBDT ? rule.type : DEFAULT_TYPE_RECORD[field]
        found.field = field
        // 如果选的规则都一样，取第一个
        if (uniqBy(rules, (item) => item.field).length === 1) {
          collapse.rules = [rules[0]]
        }
        updateRuleOption(i, collapse.rules)
      }
      // 数据变更触发渲染
      state.collapseOptionRecord[i] = cloneDeep(collapse)
    })
  }

  function addRule() {
    PATENT_OTHER_COLLAPSE_FIELDS.forEach((i) => {
      const limit = COLLAPSE_FIELDS_OPTION_RECORD[i]?.limit || 0
      const options = COLLAPSE_FIELDS_OPTION_RECORD[i]?.options || []
      if (limit > 1) {
        const collapse = state.collapseOptionRecord[i]
        const rules = collapse.rules
        if (rules.length < limit) {
          const addedFields = rules.map((ii) => ii.field)
          const supportOptions = options.filter((ii) => !addedFields.includes(ii))
          if (supportOptions.length) {
            const addedRule: ISearchCollapseRule & { options: string[] } = {
              field: supportOptions[0],
              options: supportOptions,
              type: DEFAULT_TYPE_RECORD[supportOptions[0]],
            }
            if (addedRule.field === 'COUNTRY') {
              addedRule.value = serviceArea === 'cn' ? CN_FAMILY_COUNTRY_2_CODE_LIST.join(',') : US_FAMILY_COUNTRY_2_CODE_LIST.join(',')
            }
            collapse.rules.push(addedRule)
            updateRuleOption(i, collapse.rules)
          }
        }
      }
    })
  }

  function deleteRule(rule: ISearchCollapseRule) {
    PATENT_OTHER_COLLAPSE_FIELDS.forEach((i) => {
      const limit = COLLAPSE_FIELDS_OPTION_RECORD[i]?.limit || 0
      if (limit > 1) {
        const collapse = state.collapseOptionRecord[i]
        const rules = collapse.rules
        const foundIndex = rules.findIndex((ii) => ii.field === rule.field)
        if (foundIndex !== -1) {
          rules.splice(foundIndex, 1)
          updateRuleOption(i, rules)
        }
      }
    })
  }

  /**
   * 更新折叠字段的选项
   * @param collapse_field 折叠字段
   * @param rules 折叠的规则
   * @returns
   */
  function updateRuleOption(collapse_field: string, rules: Array<ISearchCollapseRule & { options?: string[] }>) {
    // 该折叠选项所有可选的字段的选项
    const allOptions = COLLAPSE_FIELDS_OPTION_RECORD[collapse_field]?.options
    if (!allOptions.length) {
      console.error(`折叠字段${collapse_field}没有配置选项`)
    }
    // 已经选择的字段
    const addedFields = rules.map((rule) => rule.field)
    rules.forEach((rule) => {
      // 除了当前规则字段，其他规则的已经选择的选项
      const otherAddedFields = addedFields.filter((i) => i !== rule.field)
      const supportOptions = allOptions.filter((ii) => !otherAddedFields.includes(ii))
      rule.options = supportOptions
    })
    return rules
  }

  function isUnCollapseField(field: string) {
    return field === PATENT_COLLAPSE_ALL_FIELD
  }

  function pickCollapseParams(field: string) {
    const data = state.collapseOptionRecord[field]
    const collapseField = field === PATENT_COLLAPSE_ALL_FIELD ? '' : field
    const rules =
      field === PATENT_COLLAPSE_ALL_FIELD
        ? []
        : data.rules.map(({ options, ...rest }) => {
            const { field } = rest
            if (field === OPTION_COUNTRY_FIELD) {
              rest.value = (rest.value || '')
                .split(',')
                .filter((i) => i !== COUNTRY_PLACEHOLDER)
                .join(',')
            } else {
              delete rest.value
            }
            return rest
          })
    const params: ISearchCollapse = {
      field: collapseField,
      rules: rules,
    }

    return params
  }

  return {
    state,
    action,
  }
}

export function useProvidePatentCollapse(
  defaultCollapseField: Ref<string>,
  defaultCollapseRules: Ref<ISearchCollapseRule[]>,
  options: {
    // 部署的区域
    serviceArea?: 'cn' | 'en'
  }
) {
  const { state, action } = usePatentCollapse(defaultCollapseField, defaultCollapseRules, options)
  provide(ptCollapseStateProvideKey, state)
  provide(ptCollapseActionProvideKey, action)

  return { state, action }
}

export function useInjectPatentCollapse() {
  const state = inject(ptCollapseStateProvideKey)
  const action = inject(ptCollapseActionProvideKey)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { state: state!, action: action! }
}
