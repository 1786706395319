import { IBaseMechanismInfo, ILang } from '@pharmsnap/shared/types'
import { pickFirstSpecialLangName } from '@pharmsnap/shared/utils'
import { omit } from 'lodash'
import Vue, { CreateElement, RenderContext } from 'vue'
import { GLink } from '../..'

export const BMechanismItem = Vue.extend<{ data: IBaseMechanismInfo }>({
  functional: true,
  render(h: CreateElement, ctx: RenderContext<{ data: IBaseMechanismInfo; truncate?: boolean; breakContent?: string }>) {
    const { data, truncate = false, breakContent } = ctx.props
    const fontClassName = data.fontClassName
    const locale = ctx.parent.$i18n.locale.toUpperCase() as ILang
    const name = pickFirstSpecialLangName(data?.short_name || [], locale, 'EN') || pickFirstSpecialLangName(data?.mechanism_name || [], locale, 'EN')
    return breakContent ? (
      <div class="w-full flex">
        <GLink
          truncate={truncate}
          title={fontClassName ? '' : name}
          class="text-sm"
          encryptClass={fontClassName}
          name={name}
          {...omit(ctx.data, 'props')}
        ></GLink>
        {breakContent && <span class="mr-1">{breakContent}</span>}
      </div>
    ) : (
      <GLink
        truncate={truncate}
        title={fontClassName ? '' : name}
        class="text-sm"
        encryptClass={fontClassName}
        name={name}
        {...omit(ctx.data, 'props')}
      ></GLink>
    )
  },
})
