import { IFieldLangMap } from '@pharmsnap/shared/types'

export const ORG_ENTITY_LANG: IFieldLangMap = {
  Education: {
    cn: '教育机构',
    en: 'Education',
  },
  Government: {
    cn: '政府',
    en: 'Government',
  },
  Company: {
    cn: '公司',
    en: 'Company',
  },
  Nonprofit: {
    cn: '非盈利组织',
    en: 'Nonprofit',
  },
  Hospital: {
    cn: '医院',
    en: 'Hospital',
  },
}
