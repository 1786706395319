import { useAuthStore } from '@pharmsnap/shared/composition'
import { computed, defineComponent, getCurrentInstance, PropType, ref } from '@vue/composition-api'
import { useElementBounding, useMemoize } from '@vueuse/core'
import { defaultLimitShowCount } from '../../../config'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const LimitContainer = defineComponent({
  name: 'LimitContainer',
  props: {
    limit: {
      type: Number,
      default: defaultLimitShowCount,
    },
    clickMore: {
      type: Function as PropType<() => void>,
    },
    data: {
      required: true,
      type: Array,
    },
  },
  i18n: {
    messages: { cn, en },
  },
  setup(props) {
    const ins = getCurrentInstance()
    const contentWrapper = ref<HTMLElement | null>(null)
    const contentWrapperStyle = ref<Partial<CSSStyleDeclaration>>({})
    const { height: contentWrapperHeight, update: updateContentWrapperBounding } = useElementBounding(contentWrapper, {
      windowResize: false,
      windowScroll: false,
      immediate: false,
    })
    const updateWrapperContentStyle = useMemoize(
      () => {
        updateContentWrapperBounding()
        contentWrapperStyle.value = {
          maxHeight: `${contentWrapperHeight.value}px`,
          overflow: 'auto',
        }
      },
      {
        getKey: () => 'updateWrapperContentStyle',
      }
    )
    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const folded = ref(true)
    const isShowSwitch = computed(() => {
      return props.data.length > props.limit
    })
    const count = computed(() => {
      if (folded.value) {
        return props.data.length > props.limit ? props.limit : props.data.length
      }
      return props.data.length
    })
    const limitData = computed(() => {
      if (isShowSwitch) {
        return props.data.slice(0, count.value)
      }
      return props.data
    })
    function switchFn() {
      if (folded.value && props.clickMore) {
        props.clickMore()
        return
      }
      updateWrapperContentStyle()
      folded.value = !folded.value
    }

    /**
     * 业务有调整，所以不能只通过有无show more的方法来判断
     */
    const label = computed(() => {
      if (folded.value) {
        if (!props.clickMore) {
          return ins?.proxy.$t('LimitContainer.showMore')
        } else {
          if (isFreeUser.value) {
            return ins?.proxy.$t('LimitContainer.showMore')
          }
        }
        return ins?.proxy.$t('LimitContainer.showALL')
      } else {
        return ins?.proxy.$t('LimitContainer.showLess')
      }
    })

    return { isShowSwitch, limitData, switchFn, folded, label, contentWrapper, contentWrapperStyle }
  },
  render() {
    return (
      <div class="mt-1 mb-2">
        <div ref="contentWrapper" style={this.contentWrapperStyle} class={['pb-2']}>
          {this.$scopedSlots.default && this.$scopedSlots.default(this.limitData)}
        </div>
        {this.isShowSwitch && (
          <div class="text-center">
            <button
              data-testid="limit-container__trigger"
              class="pt-ui-btn"
              type="button"
              data-type="default"
              data-size="small"
              onClick={this.switchFn}
            >
              {this.label}
            </button>
          </div>
        )}
      </div>
    )
  },
})
