import { ELPopupManager } from '@pharmsnap/shared/element-ui'
import type { Placement } from 'tippy.js'

const asyncListTippyConfig = Object.freeze({
  interactive: true,
  trigger: 'click',
  zIndex: 3000,
  placement: 'bottom-start',
  maxWidth: '360px',
  arrow: false,
  delay: [500, 0],
})
export function getAsyncListTippyConfig() {
  return {
    ...asyncListTippyConfig,
    zIndex: ELPopupManager.nextZIndex(),
  }
}
export const detailCardTippyConfig = (zIndex?: number, placement?: Placement) => {
  return {
    interactive: true,
    arrow: false,
    zIndex: typeof zIndex === 'undefined' ? ELPopupManager.nextZIndex() : zIndex,
    placement: placement || 'bottom-start',
    maxWidth: '360px',
    delay: [500, 0],
  }
}
