import { BPatentTypeItem } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ILang } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BPatentTypeFetchListCard = defineComponent({
  name: 'BPatentTypeFetchListCard',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const ins = getCurrentInstance()
    const lang = computed(() => {
      return ins?.proxy.$i18n.locale.toUpperCase() as ILang
    })
    const { loading, data, xCorrelationId } = useRequest(
      {
        requestFn: sharedCtx.service.dictionary.getBatchDictionaryWithoutId.bind(sharedCtx.service.dictionary),
      },
      props.ids
    )
    const displayData = computed(() => {
      return data.value || []
    })
    watch(loading, () => {
      ctx.emit('update')
    })
    return {
      loading,
      displayData,
      xCorrelationId,
      lang,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {this.displayData.map((item, index) => {
            return <BPatentTypeItem class="mr-1 mb-1" data={item}></BPatentTypeItem>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
