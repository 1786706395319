/* eslint-disable @typescript-eslint/no-explicit-any */
import { E_QUERY_ITEM_CONDITION } from '@patsnap/synapse_common_interface'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition/useAuthStore'
import { ElCheckbox } from '@pharmsnap/shared/element-ui'
import {
  IAutoCompleteParams,
  IAutoCompleteType,
  IBAcTag,
  IBrowseCountType,
  IExampleTag,
  IListItem,
  ISearchDataType,
  ISelectedLabel,
} from '@pharmsnap/shared/types'
import { PropType, computed, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { isArray } from 'lodash'
import { VNode } from 'vue'
import { useLocale } from '../../../composition/useLocale'
import { BAcBase } from '../BAc/BAcBase'
import { BLabelAc } from '../BLabelAc/BLabelAc'
import { BAcTag } from '../tag/BAcTag/BAcTag'
import $classes from './BAutoEntity.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BAutoEntity = defineComponent({
  name: 'BAutoEntity',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /**
     * 决定调用接口
     */
    type: {
      required: true,
      type: String as PropType<IAutoCompleteType>,
    },
    /**
     * BAcTag itemType 的样式，区分于 type，兼容 type 和 tag 不一致的情况
     */
    tagItemType: {
      type: String as PropType<IAutoCompleteType>,
    },
    /**
     * 传入的tags
     */
    tags: {
      required: true,
      type: Array as PropType<IBAcTag[]>,
    },
    tagSize: {
      type: String as PropType<'mini' | 'small' | 'medium' | 'default'>,
      default: 'small',
    },
    /**
     * 是否显示前方label
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否显示example
     */
    showExample: {
      type: Boolean,
      default: false,
    },
    /**
     * example的label  ------>  ${exampleLabel}exampleSlots
     */
    exampleLabel: {
      type: String,
    },
    /**
     * 只显示placeholder
     */
    placeHolder: {
      type: String,
    },
    /**
     * 查看详情的文案
     */
    viewDetailLabel: {
      type: String,
    },
    /**
     * tags限制
     */
    tagsLimit: {
      type: Number,
      default: 10,
    },
    /**
     * 是否显示限制文案  ------>    ${tags.length}/${tagsLimit}
     */
    showLimitNum: {
      type: Boolean,
      default: false,
    },
    /**
     * 后缀icon的名字
     */
    suffix: {
      type: String,
    },
    /**
     * 后缀文本
     */
    suffixText: {
      type: [String, Object] as PropType<string | VNode>,
    },
    suffixClass: {
      type: String,
      default: 'w-10',
    },
    suffixIconClass: {
      type: String,
      default: 'w-6 h-6',
    },
    /**
     * popover样式
     */
    popperClass: {
      type: String,
      default: '',
    },
    blurClass: {
      type: String,
      default: '',
    },
    /**
     * focus且keyword<2时候的显示文案
     */
    inputTip: {
      type: String,
    },
    /**
     * 未搜索到结果的文案
     */
    emptyTip: {
      type: String,
    },
    /**
     * 有keywords的时候clickOutside的文案
     */
    recommendTip: {
      type: String,
    },
    /**
     * 是否显示browse，只有几种类型的才支持
     */
    showBrowse: {
      type: Boolean,
      default: false,
    },
    /**
     * browse的数量类型，可展示药物数量、专利数量
     */
    browseCountType: {
      type: String as PropType<IBrowseCountType>,
      default: 'drug',
    },
    /**
     * 是否显示机翻
     */
    showSwitch: {
      type: Boolean,
      default: false,
    },
    /**
     * 机翻的值
     */
    switchVal: {
      type: Boolean,
      default: false,
    },
    /**
     * 机翻的info
     */
    switchInfo: {
      type: String,
    },
    /**
     * 需要快速添加example的时候使用
     */
    quickAddExample: {
      type: Boolean,
      default: false,
    },
    /**
     * 通过type自动出来的title不能满足的时候使用
     */
    cusTitle: {
      type: String,
    },
    showInnerCheckbox: {
      type: Boolean,
      default: false,
    },
    innerCheckboxVal: {
      type: Boolean,
      default: false,
    },
    innerCheckboxLabel: {
      type: String,
    },
    infoContent: {
      type: String,
    },
    browseLabel: {
      type: String,
    },
    exampleTag: {
      type: [Object, Array] as PropType<IExampleTag | IExampleTag[]>,
    },
    /**
     * popover最大高度
     */
    popoverMaxHeight: {
      type: Number,
    },
    clickOutClear: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: String,
      default: '',
    },
    logicVal: {
      type: String as PropType<E_QUERY_ITEM_CONDITION>,
      default: E_QUERY_ITEM_CONDITION.ANY,
    },
    showLogicTag: {
      type: Boolean,
      default: false,
    },
    /**
     * core autocomplete还要再依赖一个dataType参数
     */
    dataType: {
      type: String as PropType<ISearchDataType>,
      default: '',
    },
    /**
     * core autocomplete 自定义 entityType 使用的参数
     */
    entityType: {
      type: Array as PropType<IAutoCompleteParams['entity_type']>,
    },
    customLimitMsg: {
      type: String,
    },
    labelContainerClass: {
      type: String,
      default: '',
    },
    validateFocusPermission: {
      type: Function as PropType<() => boolean>,
    },
    limit: {
      type: Number,
    },
    auth: {
      type: Boolean,
      default: false,
    },
    customLimitNumClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()

    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    /**
     * 接受一个函数作为入参 func，返回一个函数接受任意参数，内部判断auth，如果是免费用户则弹出升级弹窗，否则执行func，并传入参数
     */
    const authWrapper =
      (triggerPoint: string, func: (...args: any[]) => any) =>
      (...args: any[]) => {
        if (props.auth && isFreeUser.value) {
          changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: triggerPoint })
        } else {
          func(...args)
        }
      }

    const localeData = useLocale()

    const getLabel = () => {
      return props.showLabel ? props.cusTitle ?? (ins?.proxy.$t(`AutoEntity.${props.type}`) as string) : ''
    }

    const handleSelect = (val: IListItem) => {
      emit('select', props.type, val)
    }

    const handleKeyDown = (e: KeyboardEvent, keyword: string) => {
      emit('keyDown', e, keyword)
    }

    const handleKeyPress = (e: KeyboardEvent, keyword: string) => {
      emit('keyPress', e, keyword)
    }

    const handleClose = (val: IBAcTag) => {
      emit('close', props.type, val)
    }

    const handleViewDetail = (item: IListItem) => {
      emit('viewDetail', item)
    }

    const userExampleName = computed(() => {
      const exampleTag = isArray(props.exampleTag) ? props.exampleTag : [props.exampleTag]
      const exampleNames = exampleTag.filter(Boolean).map((tag) => tag?.[`name_${localeData.locale.value}`]) as string[]
      return exampleNames.length ? exampleNames : [ins?.proxy.$t(`AutoEntity.${props.type}Example`) as string]
    })

    const clickOutside = (keyword: string) => {
      emit('clickOutside', keyword)
    }

    const handleSubmit = (list: ISelectedLabel[]) => {
      emit('browseSubmit', list)
    }

    const handleIncludeTrans = (includeTrans: boolean) => {
      emit('includeTrans', includeTrans)
    }

    const handleAddExample = authWrapper('ADD_EXAMPLE', () => {
      emit('addExample')
    })

    const handleChangeCheckbox = (val: boolean) => {
      emit('changeCheckbox', val)
    }

    const handleSuffixClick = (keyword: string) => {
      emit('suffixClick', keyword)
    }

    function handleChangeLogicVal(val: E_QUERY_ITEM_CONDITION) {
      emit('changeLogicVal', val)
    }

    function revertTag(val: IListItem) {
      emit('revertTag', val)
    }

    return {
      getLabel,
      handleSubmit,
      handleIncludeTrans,
      handleAddExample,
      localeData,
      handleSelect,
      handleKeyDown,
      handleKeyPress,
      handleClose,
      handleViewDetail,
      clickOutside,
      handleChangeCheckbox,
      handleSuffixClick,
      userExampleName,
      handleChangeLogicVal,
      revertTag,
    }
  },
  methods: {
    renderIncludeInactive() {
      return (
        <div class={$classes.checkboxContainer}>
          <ElCheckbox value={this.innerCheckboxVal} onChange={this.handleChangeCheckbox}>
            {this.innerCheckboxLabel || this.$t('AutoEntity.includeInactive')}
          </ElCheckbox>
        </div>
      )
    },
    renderInputTip() {
      if (this.$slots.customInputTip) {
        return <template slot="customInputTip">{this.$slots.customInputTip}</template>
      }
      if (this.quickAddExample) {
        return (
          <template slot="customInputTip">
            <span>{this.inputTip ?? this.$t('common.inputTip')}</span>
            <span>{this.$t('common.tryExample')}</span>
            <span class={$classes.exampleName} onClick={this.handleAddExample}>
              {this.userExampleName.join(',')}
            </span>
          </template>
        )
      }
      return null
    },
    renderExample() {
      return (
        <template slot="example">
          {this.placeHolder ? <div class={[$classes.example, { 'cursor-not-allowed': this.disabled }]}>{this.placeHolder}</div> : null}
          {this.showExample ? (
            <div class={$classes.example}>
              <div>{this.exampleLabel ? this.exampleLabel : this.$t('common.exampleLabel')}</div>
              {this.$scopedSlots.userExample
                ? this.$scopedSlots.userExample({
                    icon: this.type === 'ATC' ? 'Atc' : '',
                    type: this.tagItemType || this.type,
                    exampleTagCls: $classes.exampleTag,
                    tagSize: this.tagSize,
                  })
                : this.userExampleName.map((name) => (
                    <BAcTag
                      icon={this.type === 'ATC' ? 'Atc' : ''}
                      itemType={this.tagItemType || this.type}
                      name={name}
                      ownerClass={$classes.exampleTag}
                      canClose={false}
                      size={this.tagSize}
                    ></BAcTag>
                  ))}
            </div>
          ) : null}
        </template>
      )
    },
  },
  render() {
    return (
      <BLabelAc
        tags={this.tags}
        auth={this.auth}
        leftNum={this.tagsLimit}
        label={this.getLabel()}
        type={this.type}
        labelContainerClass={this.labelContainerClass}
        showBrowse={this.showBrowse}
        browseCountType={this.browseCountType}
        browseLabel={this.browseLabel}
        showSwitch={this.showSwitch}
        switchVal={this.switchVal}
        switchInfo={this.switchInfo}
        onBrowseSubmit={this.handleSubmit}
        onIncludeTrans={this.handleIncludeTrans}
        infoContent={this.infoContent}
        viewDetailLabel={this.viewDetailLabel}
        onViewDetail={(item: IListItem) => this.handleViewDetail(item)}
      >
        <template slot="ac">
          <BAcBase
            ref="acBase"
            auth={this.auth}
            popperClass={this.popperClass ? `${$classes.customPopover} ${this.popperClass}` : $classes.customPopover}
            inputBlurClass={this.blurClass ? `${$classes.inputBlur} ${this.blurClass}` : $classes.inputBlur}
            tags={this.tags}
            tagSize={this.tagSize}
            logicVal={this.logicVal}
            showLogicTag={this.showLogicTag}
            viewDetailLabel={this.viewDetailLabel}
            showLimitNum={this.showLimitNum}
            tagsLimit={this.tagsLimit}
            limit={this.limit}
            inputTip={this.inputTip}
            emptyTip={this.emptyTip}
            recommendTip={this.recommendTip}
            type={this.type}
            dataType={this.dataType}
            popoverMaxHeight={this.popoverMaxHeight}
            onSelectItem={(val: IListItem) => this.handleSelect(val)}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            onClose={(val: IBAcTag) => this.handleClose(val)}
            onViewDetail={(item: IListItem) => this.handleViewDetail(item)}
            onClickOutside={(keyword: string) => this.clickOutside(keyword)}
            onChangeLogicVal={this.handleChangeLogicVal}
            suffix={this.suffix}
            suffixText={this.suffixText}
            suffixClass={this.suffixClass}
            suffixIconClass={this.suffixIconClass}
            onSuffixClick={this.handleSuffixClick}
            clickOutClear={this.clickOutClear}
            searchType={this.searchType}
            entityType={this.entityType}
            customLimitMsg={this.customLimitMsg}
            onRevertTag={this.revertTag}
            validateFocusPermission={this.validateFocusPermission}
            customLimitNumClass={this.customLimitNumClass}
            disabled={this.disabled}
            scopedSlots={{
              autocompleteContent: this.$scopedSlots.autocompleteContent,
              suffix: this.$scopedSlots.suffix,
            }}
          >
            {this.$slots.inlinePrefix && <template slot="inlinePrefix">{this.$slots.inlinePrefix}</template>}
            {this.renderExample()}
            {this.renderInputTip()}
            {this.showInnerCheckbox ? <template slot="innerCheckbox">{this.renderIncludeInactive()}</template> : null}
            {this.$slots.suffix && <template slot="suffix">{this.$slots.suffix}</template>}
          </BAcBase>
        </template>
        {this.$slots.customLabelSuffix && (
          <template slot="customLabelSuffix">
            <div class="ml-1">{this.$slots.customLabelSuffix}</div>
          </template>
        )}
      </BLabelAc>
    )
  },
})
