import type { ITopicAutoCompleteItem } from '@patsnap/synapse_common_interface'
import { IAutoCompleteParams } from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class TopicApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<ITopicAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    const res = (await this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/topic/autocomplete'),
      params: { prefix: keywords, limit },
      cache: true,
      cancelToken,
    })) as IHttpWrap<ITopicAutoCompleteItem[]>
    if (res.success && res.data) {
      res.data.forEach((d) => {
        d.id = ''
      })
    }
    return res
  }
}
