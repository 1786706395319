import '@pharmsnap/pharmsnap-web/assets/icon-svg/check.svg'
import { defineComponent } from '@vue/composition-api'
import { GDialog, GIcon } from 'pharmsnapMF_shared/components'
import qrImg from '../qr.png'
import cn from './locales/cn.json'
import en from './locales/en.json'

export default defineComponent({
  name: 'FeedbackSuccessDialog',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    return {}
  },
  render() {
    return (
      <GDialog
        append-to-body={true}
        showFooter={false}
        onClose={() => this.$emit('close')}
        destroy-on-close={true}
        close-on-click-modal={false}
        visible={this.visible}
        width="500px"
      >
        <div class="flex mb-8 justify-center">
          <GIcon svgName="Check" size={60} class="flex-shrink-0"></GIcon>
        </div>
        <div class="mb-14 text-center text-text-t1 text-tiny">{this.$t('submitSuccess')}</div>
        <div class="text-center text-text-t1 text-tiny">{this.$t('joinGroup')}</div>
        <div class="flex justify-center mt-6 mb-16">
          <img src={qrImg} alt="" width="120" />
        </div>
      </GDialog>
    )
  },
})
