import dayjs from 'dayjs'
import localeDate from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import ElementLocale from 'element-ui/lib/locale'
import { includes } from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

dayjs.extend(relativeTime)
dayjs.extend(localeDate)
dayjs.extend(localizedFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(utc)
Vue.use(VueI18n)
const loadedLanguages: I18nLang[] = [] // 我们的预装默认语言

export type I18nLang = 'en' | 'cn'

export let i18n!: VueI18n
export async function createI18n(locale: I18nLang = 'en') {
  if (i18n) {
    return i18n
  }

  // 因为在workspace微前端有日文+繁体，所以这里需要判断一下
  const langMessage = includes(['cn', 'en'], locale) ? await import(/* webpackChunkName: "lang-biz" */ `./messages/biz-${locale}`) : {}
  i18n = new VueI18n({
    locale: locale, // 设置语言环境
    fallbackLocale: locale,
    messages: {
      [locale]: langMessage.default,
    },
    silentTranslationWarn: true,
  })
  ElementLocale.i18n((key: string, value: string) => i18n.t(key, value))
  dayjs.locale(locale === 'cn' ? 'zh-cn' : 'en')
  loadedLanguages.push(locale)
  return i18n
}

export function setI18nLanguage(lang: I18nLang) {
  i18n.locale = lang
  dayjs.locale(lang === 'cn' ? 'zh-cn' : 'en')

  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync(lang: I18nLang) {
  if (!i18n) {
    return createI18n(lang)
  }
  // 如果语言相同
  if (i18n.locale === lang) {
    return i18n
  }

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
    setI18nLanguage(lang)
    return i18n
  }

  // 如果尚未加载语言
  await Promise.all([import(/* webpackChunkName: "lang-biz" */ `./messages/biz-${lang}`)]).then(([bizMessages]) => {
    i18n.setLocaleMessage(lang, { ...bizMessages.default })
    loadedLanguages.push(lang)
    setI18nLanguage(lang)
    return lang
  })
  return i18n
}
