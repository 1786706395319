import { defineComponent, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { VNodeData } from 'vue'
import '../../../assets/icon-svg/info.svg'
import { ElTooltip } from '../../../element-ui'
import $classes from './GTooltip.module.scss'

export const GTooltip = defineComponent({
  name: 'GTooltip',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    manual: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'dark',
    },
    mode: {
      type: String as PropType<'text' | 'icon'>,
      default: 'text',
    },
    content: {
      type: String,
      default: '',
    },
    placement: {
      type: String as PropType<
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end'
      >,
      default: 'top',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'Info',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    showAnimate: {
      type: Boolean,
      default: true,
    },
    enterable: {
      type: Boolean,
      default: true,
    },
    openDelay: {
      type: Number,
      default: 0,
    },
    popperClass: {
      type: String,
    },
    iconTheme: {
      type: String as PropType<'default' | 'warning'>,
      default: 'default',
    },
  },
  methods: {
    renderReference() {
      if (this.mode === 'text') return this.$slots.default
      return (
        <PtIcon
          class={[$classes.icon, this.iconTheme === 'default' ? $classes.iconDefault : $classes.iconWarning]}
          style={{ fontSize: `${this.iconSize}px` }}
          icon={this.iconName}
        ></PtIcon>
      )
    },
    renderContent() {
      return this.content ? null : this.$slots.content
    },
  },
  render() {
    const vNodeData: VNodeData = {
      props: {
        value: this.value,
        manual: this.manual,
        effect: this.theme,
        content: this.content,
        placement: this.placement,
        disabled: this.disabled,
        enterable: this.enterable,
        'visible-arrow': false,
        'popper-class': [$classes.tooltip, this.theme === 'dark' ? $classes.tooltipDark : $classes.tooltipLight, this.popperClass].join(' '),
        'open-delay': this.openDelay,
      },
    }

    if (!this.showAnimate && vNodeData.props) {
      vNodeData.props.transition = ''
    }

    return (
      <ElTooltip {...vNodeData}>
        {this.renderReference()}
        <template slot="content">{this.renderContent()}</template>
      </ElTooltip>
    )
  },
})
