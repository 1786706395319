import { IQueryItemField } from '@patsnap/synapse_common_interface'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseDrugInfo, IBatchDrugInfo, IQueryDataListType, IUseChartTwoDimTupleItem } from '@pharmsnap/shared/types'
import { getLast30daysIncreasedTip, transformTwoDimTupleItems2QueryItemField } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'
import { flatten, sum, uniq } from 'lodash'
import { GIncreaseTip } from '../../ui/GIncreaseTip/GIncreaseTip'
import { GLimited } from '../../ui/GLimited/GLimited'
import { GLoading } from '../../ui/GLoading/GLoading'
import { BDrugItem } from '../BDrugItem/BDrugItem'
import cn from './locale/cn.json'
import en from './locale/en.json'

export const BHeatmapIncreaseInfo = defineComponent({
  name: 'BHeatmapIncreaseInfo',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    dataType: {
      type: String as PropType<IQueryDataListType | 'trial'>,
      required: true,
    },
    items: {
      type: Array as PropType<IUseChartTwoDimTupleItem<{ related_drug_id?: string[]; related_drug_id_in_30_days?: string[]; recent_30: number }>[]>,
      required: true,
    },
    fetchDrugInfo: {
      type: Function as PropType<
        (query: {
          yAxisQuery: IQueryItemField
          xAxisQuery: IQueryItemField
        }) => Promise<{ drugs: IBatchDrugInfo[]; increasedDrugs: IBatchDrugInfo[] }>
      >,
    },
  },
  setup(props) {
    const { items } = toRefs(props)
    const { locale } = useLocale()
    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const loading = ref(false)
    const drugs = ref<IBaseDrugInfo[]>([])
    const isDrug = computed(
      () => !!props.fetchDrugInfo || items.value.some((i) => i[1].otherInfo?.related_drug_id && i[1].otherInfo?.related_drug_id.length > 0)
    )
    const increaseCount = computed(() => sum(items.value.map((i) => i[1].otherInfo?.recent_30 || 0)))
    const drugIds = computed(() => uniq(flatten(items.value.map((i) => i[1].otherInfo?.related_drug_id_in_30_days || []))))
    const fetchDrugs = async () => {
      loading.value = true
      if (props.fetchDrugInfo) {
        const { xAxisQuery, yAxisQuery } = transformTwoDimTupleItems2QueryItemField(items.value)
        const drugRt = await props.fetchDrugInfo({ xAxisQuery, yAxisQuery })
        drugs.value = drugRt.increasedDrugs || []
      } else {
        const rt = await sharedCtx.service.drug.getBatchDrugs(drugIds.value)
        if (rt.success && rt.data) {
          drugs.value = rt.data as IBaseDrugInfo[]
        }
      }
      loading.value = false
    }
    if (isDrug.value) {
      fetchDrugs()
    }

    watch(items, () => {
      if (isDrug.value) {
        fetchDrugs()
      }
    })

    return {
      isFreeUser,
      drugs,
      isDrug,
      loading,
      locale,
      increaseCount,
    }
  },
  methods: {
    renderDrugs() {
      if (this.loading) {
        return (
          <div style="position: relative; height: 50px;">
            <GLoading size={24}></GLoading>
          </div>
        )
      }
      return (
        <div style="padding-left: 20px; margin-top: 4px;">
          <GLimited
            mode="link"
            totalCount={this.increaseCount}
            limit={3}
            items={this.drugs}
            linkTip={this.$tc('heatmapIncreaseInfo.viewInListTip')}
            inline={false}
            gap={{ x: 0, y: 4 }}
            scopedSlots={{ default: ({ item }: { item: IBaseDrugInfo }) => <BDrugItem data={item}></BDrugItem> }}
            onClickTrigger={() => this.$emit('navList')}
          ></GLimited>
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <div class={['cursor-pointer', 'pr-1', 'py-1', 'hover:bg-gray-30', 'rounded']} onClick={() => this.$emit('navList')}>
          <GIncreaseTip
            size="small"
            useHtml={true}
            tip={getLast30daysIncreasedTip(this.dataType, this.locale, this.increaseCount) || ''}
          ></GIncreaseTip>
        </div>
        {this.isDrug && !this.isFreeUser ? this.renderDrugs() : null}
      </div>
    )
  },
})
