import { IOrgAutoCompleteItem, ISearchParams } from '@patsnap/synapse_common_interface'
import { formatOrgName, formatSearchTreeOrg } from '@pharmsnap/shared/utils'
import DataLoader from 'dataloader'
import {
  IAutoCompleteParams,
  IBaseOrgInfo,
  IEventTypeItem,
  IInvestmentOrgOverviewData,
  IOrgCorpTree,
  IOrgCorpTreeBasic,
  IOrganizationCardInfo,
  IOrganizationCurrentProjectDetail,
  IOrganizationDetail,
  IOrganizationPipelineSnapshot,
  IOrganizationStatus,
  ISearchResult,
  ISearchTreeParams,
  ISearchTreeResult,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class OrganizationApi extends BaseApi {
  private batchGetDiseaseLoader = new DataLoader((ids: readonly string[]) => this._getBatchOrg(ids))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  async getCardInfo(id: string): Promise<IHttpWrap<IOrganizationCardInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/organization/card/${id}`),
      cache: true,
    })
  }
  async getAutocomplete(params: IAutoCompleteParams): Promise<IHttpWrap<IOrgAutoCompleteItem[]>> {
    const { keywords, limit = 5, cancelToken } = params
    const rt = (await this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/organization/autocomplete'),
      cache: true,
      cancelToken,
      params: { prefix: keywords, limit },
    })) as IHttpWrap<IOrgAutoCompleteItem[]>
    if (rt.success) {
      rt.data.forEach((i) => formatOrgName(i, 'name_cn', 'name_en'))
    }
    return rt
  }
  async _getBatchOrg(ids: readonly string[]): Promise<Array<IBaseOrgInfo | undefined>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/organization/batch'),
      data: ids,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchResult<IBaseOrgInfo>>
    if (rt.success && rt.data) {
      return ids.map((id) => rt.data.items?.find((item) => item.entity_id === id))
    }
    return []
  }
  async getBatchOrg(ids: readonly string[]): Promise<IHttpWrap<IBaseOrgInfo[]>> {
    const rt = await this.batchGetDiseaseLoader.loadMany(ids)
    return {
      success: true,
      status: 200,
      data: rt.filter((item) => !!item) as IBaseOrgInfo[],
      xCorrelationId: '',
    }
  }
  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    const rt = (await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/organization/tree/search`),
      data: params,
      method: 'POST',
      cache: true,
    })) as IHttpWrap<ISearchTreeResult>
    if (rt.success) {
      formatSearchTreeOrg(rt.data.items)
    }
    return rt
  }
  async getCorpTree(id: string, limit = -1): Promise<IHttpWrap<IOrgCorpTree>> {
    return this.http({
      url: this.getUrl(`discoveryls_common/1.0/organization/corptree/${id}?limit=${limit}`),
      method: 'GET',
      cache: true,
    })
  }
  async getCorpTreeBasic(id: string): Promise<IHttpWrap<IOrgCorpTreeBasic>> {
    return this.http({
      url: this.getUrl(`discoveryls_common/1.0/organization/corptree/${id}/basic`),
      method: 'GET',
      cache: true,
    })
  }

  async getOrganizationStatus(id: string): Promise<IHttpWrap<IOrganizationStatus>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/2.0/organization/${id}/data/status`),
      cache: true,
    })
  }

  async getOrganizationDetail(id: string): Promise<IHttpWrap<IOrganizationDetail>> {
    const rt = (await this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/2.0/organization/${id}`),
      cache: true,
    })) as IHttpWrap<IOrganizationDetail>
    return rt
  }

  async getOrganizationPipelineSnapshot(params: { id: string }): Promise<IHttpWrap<IOrganizationPipelineSnapshot>> {
    const rt = (await this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/2.0/organization/pipeline/snapshot/${params.id}`),
      cache: true,
    })) as IHttpWrap<IOrganizationPipelineSnapshot>
    return rt
  }

  async getOrganizationCurrentProject(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IOrganizationCurrentProjectDetail>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/2.0/organization/pipeline/current_project'),
      cache: true,
      data: params,
    })
  }

  async getFundingRoundOverview(id: string): Promise<IHttpWrap<IInvestmentOrgOverviewData>> {
    return await this.http({
      url: this.getUrl(`pharmsnap_webapi/2.0/organization/${id}/funding-round/overview`),
      method: 'GET',
      cache: true,
    })
  }

  async getOrganizationEventTypeTemplate(): Promise<IHttpWrap<IEventTypeItem[]>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/alert/organization/event_template`),
      method: 'GET',
      cache: true,
    })
  }

  async getOrganizationMapping(id: string): Promise<
    IHttpWrap<{
      /** one id 的org id */
      master_entity_id: string
      /** 老的org id */
      organization_entity_id: string
    } | null>
  > {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/public/organization_mapping/${id}`),
      cache: true,
    })
  }

  async getOrgCardData({ id }: { id: string }) {
    return this.getCardInfo(id)
  }
}
