/* eslint-disable @typescript-eslint/no-unused-vars */
import { ContinousVisualMapOption } from 'echarts/types/dist/shared'
import { ECOption } from '../../core/plot'

const defaultContinuousVisualMap = (options: ECOption): ContinousVisualMapOption => {
  return {
    show: true,
    itemHeight: 50,
    itemWidth: 12,
    inRange: { color: ['#d4f0f6', '#1976D2'] },
    text: ['High', 'Low'],
    type: 'continuous',
    // dimension: 'value',
    calculable: true,
  }
}

export function getDefaultContinuousVisualMap(seriesIndex: number, options: ECOption): ContinousVisualMapOption {
  return { ...defaultContinuousVisualMap(options), seriesIndex }
}
