import { IQuery } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { AggBarChartEntity, AggLineBarChartEntity, AggOneDimDataItem } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { IQueryService } from '@pharmsnap/shared/src/composition/useQueryService'
import { ILoadingService, ISearchResult } from '@pharmsnap/shared/src/types'
import { getTimeRelatedQueryField, transAggregationValue2QueryItem } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, getCurrentInstance, PropType, reactive, ref, unref, watch } from '@vue/composition-api'
import { cloneDeep, isEqual, noop } from 'lodash'
import {
  BAnalysisBarEchart,
  BAnalysisBarTable,
  BAnalysisChartCard,
  BAnalysisChartFullScreenCard,
  BAnalysisLineBarEchart,
} from '../../BAnalysisChartNext'
import { BAnalysisLineBarTable } from '../../BAnalysisChartNext/components/table/BAnalysisLineBarTable'
import { useAnalysisDrugDealOneDimChartEntity } from '../../BAnalysisChartNext/compositions/drug-deal'
import { useAnalysisDrugDealMultiOneDimChartEntity } from '../../BAnalysisChartNext/compositions/drug-deal/useAnalysisDrugDealMultiOneDimChartEntity'
import { useAnalysisOneDimChartNavList } from '../../BAnalysisChartNext/compositions/useAnalysisOneDimChartNavList'
import $classes from '../BAnalysisView.module.scss'
import {
  horizontalBarChartTypes,
  IUseDrugDealAggChartType,
  lineBarChartTypes,
  useDrugDealAggChart,
  verticalBarChartTypes,
} from '../compositions/useDrugDealAggCharts'
import cn from './locales/cn.json'
import en from './locales/en.json'

const chartTypes: IUseDrugDealAggChartType[] = [
  'line-bar',
  'total-payment-bar',
  'upfront-payment-bar',
  'milestone-bar',
  'phase-bar',
  'principal-horizontal-bar',
  'partner-horizontal-bar',
  'target-horizontal-bar',
  'drug-type-horizontal-bar',
  'deal-indication-horizontal-bar',
]

const barChartTypes = [...verticalBarChartTypes, ...horizontalBarChartTypes]

export const BDrugDealAnalysisView = defineComponent({
  name: 'BDrugDealAnalysisView',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getTotalFn: {
      required: true,
      type: Function as PropType<() => Promise<ISearchResult<any> & { uncollapse_total?: number }>>,
    },
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
    loadingService: {
      required: true,
      type: Object as PropType<ILoadingService>,
    },
  },
  setup(props) {
    const showFullScreen = ref(false)
    const ins = getCurrentInstance()
    const activeFullScreen = ref<IUseDrugDealAggChartType | ''>('')

    const { locale, tsText } = useLocale()

    const query = computed<IQuery>(() => {
      return {
        type: 'group',
        must: props.queryService.state.query.must,
        filter: props.queryService.state.query.filter,
      }
    })

    const chartAggRecord = chartTypes.reduce<Record<IUseDrugDealAggChartType, ReturnType<typeof useDrugDealAggChart>>>(
      (pre, next) => ({
        ...pre,
        [next]: useDrugDealAggChart({ type: next, query }),
      }),
      {} as Record<IUseDrugDealAggChartType, ReturnType<typeof useDrugDealAggChart>>
    )

    const chartInitedRecord = reactive(chartTypes.reduce((pre, next) => ({ ...pre, [next]: false }), {} as Record<IUseDrugDealAggChartType, boolean>))

    const lineBarChartEntityRecord = lineBarChartTypes.reduce<
      Record<IUseDrugDealAggChartType, ReturnType<typeof useAnalysisDrugDealMultiOneDimChartEntity>>
    >(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisDrugDealMultiOneDimChartEntity({
          chartType: 'line-bar',
          data: chartAggRecord[next].aggData,
          categoryFieldName: [chartAggRecord[next].categoryFieldName.value, chartAggRecord[next].categoryFieldName.value],
          countFieldName: [chartAggRecord[next].countFieldName.value, chartAggRecord[next].lineCountFieldName.value],
        }),
      }),
      {} as Record<IUseDrugDealAggChartType, ReturnType<typeof useAnalysisDrugDealMultiOneDimChartEntity>>
    )

    const barChartEntityRecord = barChartTypes.reduce<Record<IUseDrugDealAggChartType, ReturnType<typeof useAnalysisDrugDealOneDimChartEntity>>>(
      (pre, next) => ({
        ...pre,
        [next]: useAnalysisDrugDealOneDimChartEntity({
          chartType: 'bar',
          data: chartAggRecord[next].aggData,
          categoryFieldName: chartAggRecord[next].categoryFieldName,
          countFieldName: chartAggRecord[next].countFieldName,
        }),
      }),
      {} as Record<IUseDrugDealAggChartType, ReturnType<typeof useAnalysisDrugDealOneDimChartEntity>>
    )

    const handleClickBarChart = (appendTo: 'must' | 'filter' = 'filter') => {
      const { handleClick } = useAnalysisOneDimChartNavList({
        chartType: 'bar',
        navList: 'drug_deal',
        query,
        appendTo,
        customAggValueTransformer: (aggValues) => {
          const aggValue = aggValues[0]
          const aggField = aggValue.aggregation_field || ''

          const paymentFields = ['TOTAL_VALUE', 'UPFRONT_PAYMENT', 'MILESTONE_PAYMENT']
          if (paymentFields.includes(aggField)) {
            return {
              type: 'field',
              fields: [aggField],
              value: [
                {
                  type: 'range',
                  key: aggValue.key,
                  display_name_cn: aggValue.display_name_cn,
                  display_name_en: aggValue.display_name_en,
                  from: aggValue?.from,
                  to: aggValue?.to,
                },
              ],
            }
          }
          if (aggField === 'DEAL_TIME_YEAR') {
            const year = Number(aggValues[0]._meta.after)
            return getTimeRelatedQueryField(year, aggField, true, false)
          }
          if (aggField === 'NORMALIZED_PHASE_SIGN_ID_FLATTEN') {
            const aggValue = cloneDeep(aggValues)
            aggValue[0].aggregation_field = 'NORMALIZED_PHASE_SIGN_ID'
            return transAggregationValue2QueryItem(aggValue)
          }
        },
        interceptors: [
          (data: AggOneDimDataItem) => {
            const principalFields = ['PRINCIPLE_ORG_ID_ROOT', 'PRINCIPLE_ORG_ID']
            const partnerFields = ['PARTNER_ORG_ID_ROOT', 'PARTNER_ORG_ID']

            const filterFields = props.queryService.state.query.filter
              .filter((i) => i.type === 'field')
              .reduce((pre, next) => (next.type === 'field' ? [...pre, ...next.fields] : pre), [] as string[])

            const hasPrincipalFields = principalFields.some((i) => filterFields.includes(i))

            const hasPartnerFields = partnerFields.some((i) => filterFields.includes(i))

            const isInValidPrincipal =
              hasPrincipalFields &&
              !!data.aggValue.aggregation_field &&
              !filterFields.includes(data.aggValue.aggregation_field) &&
              principalFields.includes(data.aggValue.aggregation_field)

            const isInvalidPartner =
              hasPartnerFields &&
              !!data.aggValue.aggregation_field &&
              !filterFields.includes(data.aggValue.aggregation_field) &&
              partnerFields.includes(data.aggValue.aggregation_field)

            if (isInValidPrincipal || isInvalidPartner) {
              return {
                message:
                  unref(locale) === 'cn'
                    ? '当前图表中以总公司聚合的设置与左侧过滤项不一致，请设置为相同后，再次尝试'
                    : 'The setting of aggregation by parent company in the current chart is not the same as the filter item on the left, please set it to the same and try again.',
                type: 'error',
              }
            }

            return true
          },
        ],
      })
      return handleClick
    }

    function getChartDescConfigByType(type: IUseDrugDealAggChartType) {
      switch (type) {
        case 'line-bar':
          return {
            title: ins?.proxy?.$tc('overview.title'),
            desc: ins?.proxy?.$tc('overview.desc'),
          }
        case 'total-payment-bar':
          return {
            title: ins?.proxy?.$tc('totalPayment.title'),
            desc: ins?.proxy?.$tc('totalPayment.desc'),
          }
        case 'upfront-payment-bar':
          return {
            title: ins?.proxy?.$tc('upfrontPayment.title'),
            desc: ins?.proxy?.$tc('upfrontPayment.desc'),
          }
        case 'milestone-bar':
          return {
            title: ins?.proxy?.$tc('milestonePayment.title'),
            desc: ins?.proxy?.$tc('milestonePayment.desc'),
          }
        case 'phase-bar':
          return {
            title: ins?.proxy?.$tc('highestPhase.title'),
            desc: ins?.proxy?.$tc('highestPhase.desc'),
          }
        case 'principal-horizontal-bar':
          return {
            title: ins?.proxy?.$tc('principle.title'),
            desc: ins?.proxy?.$tc('principle.desc'),
          }
        case 'partner-horizontal-bar':
          return {
            title: ins?.proxy?.$tc('partner.title'),
            desc: ins?.proxy?.$tc('partner.desc'),
          }
        case 'target-horizontal-bar':
          return {
            title: ins?.proxy?.$tc('target.title'),
            desc: ins?.proxy?.$tc('target.desc'),
          }
        case 'drug-type-horizontal-bar':
          return {
            title: ins?.proxy?.$tc('drugType.title'),
            desc: ins?.proxy?.$tc('drugType.desc'),
          }
        case 'deal-indication-horizontal-bar':
          return {
            title: ins?.proxy?.$tc('dealIndication.title'),
            desc: ins?.proxy?.$tc('dealIndication.desc'),
          }
        default:
          return {
            title: '',
            desc: '',
          }
      }
    }

    function handleFetchChartData(type: IUseDrugDealAggChartType) {
      chartAggRecord[type].fetchAggData()
      chartInitedRecord[type] = true
    }

    function handleOpenFullScreen(type: IUseDrugDealAggChartType) {
      activeFullScreen.value = type
      showFullScreen.value = true
    }

    function getHandleClickFunc(type: IUseDrugDealAggChartType) {
      if (barChartTypes.includes(type) || lineBarChartTypes.includes(type)) {
        return handleClickBarChart('filter')
      }
      return noop
    }

    function handleCloseFullScreen() {
      showFullScreen.value = false
      activeFullScreen.value = ''
    }

    async function initData() {
      props.loadingService.action.setLoading(true)
      const rt = await props.getTotalFn()
      props.loadingService.action.setTotal(rt.total)
      props.loadingService.action.setSubTotal(rt.sub_total)
      props.loadingService.action.setLoading(false)
    }

    function initAggData() {
      chartTypes.forEach((type) => {
        if (chartInitedRecord[type]) {
          handleFetchChartData(type)
        }
      })
    }

    function customLineBarCountRender(item: AggOneDimDataItem) {
      const { otherCount, count } = item
      if (count && !otherCount) {
        return locale.value === 'cn' ? '未披露' : 'Undisclosed'
      }
      return `$${toThousands(otherCount || 0)}M`
    }

    watch(
      () => [props.queryService.state.query.must, props.queryService.state.query.filter],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) {
          return
        }
        initData()
      },
      {
        immediate: true,
      }
    )

    watch(query, () => {
      initAggData()
    })

    return {
      tsText,
      chartAggRecord,
      lineBarChartEntityRecord,
      barChartEntityRecord,
      showFullScreen,
      activeFullScreen,
      getChartDescConfigByType,
      handleFetchChartData,
      handleOpenFullScreen,
      getHandleClickFunc,
      handleCloseFullScreen,
      customLineBarCountRender,
    }
  },
  methods: {
    renderLineBar(type: IUseDrugDealAggChartType, cls = '', id: string) {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, lineCountFieldName, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.lineBarChartEntityRecord[type]
      const { title, desc } = this.getChartDescConfigByType(type)
      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={title}
            desc={desc}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={600}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            <BAnalysisLineBarEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggLineBarChartEntity}
            ></BAnalysisLineBarEchart>
            <BAnalysisLineBarTable
              class={[{ hidden: model.display === 'vertical-bar' }]}
              height={'100%'}
              displayMode="table"
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              otherCountFieldName={lineCountFieldName.value}
              list={dataList.value}
              customOtherCountRender={this.customLineBarCountRender}
              useListSorter={false}
            ></BAnalysisLineBarTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderBarByType(type: IUseDrugDealAggChartType, cls = '', id: string, popperEnable = false, useListSorter = false) {
      const { model, controls, controlLayout, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]
      const { chartEntity, dataList } = this.barChartEntityRecord[type]
      const { title, desc } = this.getChartDescConfigByType(type)
      const height = horizontalBarChartTypes.includes(type) ? Math.max(600, dataList.value.length * 80) : 600
      return (
        <vue-lazy-component key={`${type}_lazy`} style={{ minHeight: `${400}px` }} id={id} onInit={() => this.handleFetchChartData(type)}>
          <BAnalysisChartCard
            key={type}
            class={cls}
            title={title}
            desc={desc}
            isLoading={loading.value}
            isEmpty={isEmpty.value}
            height={height}
            controls={controls}
            layout={controlLayout}
            value={model}
            downloadCfg={{ visible: model.display !== 'table', type: 'chart-img' }}
            onOpen={() => this.handleOpenFullScreen(type)}
          >
            <BAnalysisBarEchart
              class={['h-full', { hidden: model.display === 'table' }]}
              displayType={model.display}
              popperEnable={popperEnable}
              onClickItem={this.getHandleClickFunc(type)}
              entity={chartEntity.value as AggBarChartEntity}
            ></BAnalysisBarEchart>
            <BAnalysisBarTable
              class={[{ hidden: model.display === 'vertical-bar' }]}
              height={'100%'}
              displayMode="table"
              categoryFieldName={categoryFieldName.value}
              countFieldName={countFieldName.value}
              list={dataList.value}
              useListSorter={useListSorter}
            ></BAnalysisBarTable>
          </BAnalysisChartCard>
        </vue-lazy-component>
      )
    },
    renderLineBarFullScreen(type: IUseDrugDealAggChartType) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayoutFullscreen, categoryFieldName, countFieldName, lineCountFieldName, loading, isEmpty } =
        this.chartAggRecord[type]

      const { chartEntity, dataList } = this.lineBarChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type)

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen`}
          class="h-full"
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisLineBarEchart
                style={{ height: `600px` }}
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggLineBarChartEntity}
              ></BAnalysisLineBarEchart>
              <div class="mt-8">
                <BAnalysisLineBarTable
                  displayMode="record"
                  categoryFieldName={categoryFieldName.value}
                  countFieldName={countFieldName.value}
                  otherCountFieldName={lineCountFieldName.value}
                  list={dataList.value}
                  customOtherCountRender={this.customLineBarCountRender}
                  useListSorter={false}
                ></BAnalysisLineBarTable>
              </div>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
    renderBarByTypeFullScreen(type: IUseDrugDealAggChartType, popperEnable = false, useListSorter = false) {
      if (type !== this.activeFullScreen) return null

      const { model, controls, controlLayoutFullscreen, categoryFieldName, countFieldName, loading, isEmpty } = this.chartAggRecord[type]

      const { chartEntity, dataList } = this.barChartEntityRecord[type]

      const { title, desc } = this.getChartDescConfigByType(type)

      const height = horizontalBarChartTypes.includes(type) ? Math.max(600, dataList.value.length * 80) : 600

      return (
        <BAnalysisChartFullScreenCard
          key={`${type}_fullscreen`}
          class="h-full"
          title={title}
          desc={desc}
          isLoading={loading.value}
          isEmpty={isEmpty.value}
          controls={controls}
          layout={controlLayoutFullscreen}
          value={model}
          downloadCfg={{ visible: true, type: 'chart-img' }}
          onClose={this.handleCloseFullScreen}
        >
          <div>
            <div class="p-4 border border-solid border-gray-40 rounded">
              <BAnalysisBarEchart
                style={{ height: `${height}px` }}
                popperEnable={popperEnable}
                displayType={model.display}
                onClickItem={this.getHandleClickFunc(type)}
                entity={chartEntity.value as AggBarChartEntity}
              ></BAnalysisBarEchart>
              <div class="mt-8">
                <BAnalysisBarTable
                  displayMode="record"
                  categoryFieldName={categoryFieldName.value}
                  countFieldName={countFieldName.value}
                  list={dataList.value}
                  useListSorter={useListSorter}
                ></BAnalysisBarTable>
              </div>
            </div>
          </div>
        </BAnalysisChartFullScreenCard>
      )
    },
  },
  render() {
    return (
      <div class="p-4 pb-16 h-full overflow-y-auto">
        {/* 交易总览 */}
        <div class={[$classes.blockTitle, 'mb-2']}>{this.$t('overviewAnalysis')}</div>
        {this.renderLineBar('line-bar', '', 'chart-1')}
        {/* 金额维度分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('paymentAnalysis')}</div>
        {this.renderBarByType('total-payment-bar', 'mb-4', 'chart-2')}
        {this.renderBarByType('upfront-payment-bar', 'mb-4', 'chart-3')}
        {this.renderBarByType('milestone-bar', '', 'chart-4')}
        {/* 药物分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('drugAnalysis')}</div>
        {this.renderBarByType('phase-bar', '', 'chart-5')}

        {/* 机构分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('organizationAnalysis')}</div>
        {this.renderBarByType('principal-horizontal-bar', 'mb-4', 'chart-6', true, true)}
        {this.renderBarByType('partner-horizontal-bar', '', 'chart-7', true, true)}

        {/* 靶点分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('targetAnalysis')}</div>
        {this.renderBarByType('target-horizontal-bar', '', 'chart-8', true, true)}
        {/* 交易适应症分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('dealIndicationAnalysis')}</div>
        {this.renderBarByType('deal-indication-horizontal-bar', '', 'chart-9', true, true)}
        {/* 药物类型分析 */}
        <div class={[$classes.blockTitle, 'mt-4 mb-2']}>{this.$t('drugTypeAnalysis')}</div>
        {this.renderBarByType('drug-type-horizontal-bar', '', 'chart-10', true, true)}

        {/* 全屏 */}
        <div class={[$classes.fullscreen, { hidden: !this.showFullScreen }]}>
          {/* 交易总览 全屏 */}
          {this.renderLineBarFullScreen('line-bar')}
          {/* 金额维度分析图 全屏 */}
          {this.renderBarByTypeFullScreen('total-payment-bar')}
          {this.renderBarByTypeFullScreen('upfront-payment-bar')}
          {this.renderBarByTypeFullScreen('milestone-bar')}
          {/* 药物分析全屏 */}
          {this.renderBarByTypeFullScreen('phase-bar')}
          {/* 机构分析全屏 */}
          {this.renderBarByTypeFullScreen('principal-horizontal-bar', true, true)}
          {this.renderBarByTypeFullScreen('partner-horizontal-bar', true, true)}
          {/* 靶点分析全屏 */}
          {this.renderBarByTypeFullScreen('target-horizontal-bar', true, true)}
          {/* 交易适应症分析全屏 */}
          {this.renderBarByTypeFullScreen('deal-indication-horizontal-bar', true, true)}
          {/* 药物类型分析全屏 */}
          {this.renderBarByTypeFullScreen('drug-type-horizontal-bar', true, true)}
        </div>
      </div>
    )
  },
})
