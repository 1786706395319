import '@patsnap-ui/icon/assets/solid/ArrowDown.svg'
import '@patsnap-ui/icon/assets/solid/ArrowRight.svg'
import '@patsnap-ui/icon/assets/solid/ArrowUp.svg'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import { GTooltip } from '../GTooltip/GTooltip'
import $classes from './GFoldingContainer.module.scss'

export const GFoldingContainer = defineComponent({
  name: 'GFoldingContainer',
  props: {
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    layout: {
      type: String as PropType<'compact' | 'loose'>,
      default: 'compact',
    },
    arrowMode: {
      type: String as PropType<'down-up' | 'right-down'>,
      default: 'down-up',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    headerCls: {
      type: String,
      default: '',
    },
    headerStyle: {
      type: [String, Object],
      default: '',
    },
    labelCls: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: '',
    },
    contentCls: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { opened, arrowMode, animated } = toRefs(props)

    const iconName = computed(() => {
      if (arrowMode.value === 'down-up') {
        return animated.value ? 'SolidArrowDown' : opened.value ? 'SolidArrowUp' : 'SolidArrowDown'
      }

      return animated.value ? 'SolidArrowRight' : opened.value ? 'SolidArrowDown' : 'SolidArrowRight'
    })

    const iconCls = computed(() => {
      if (!animated.value) return ''

      if (arrowMode.value === 'down-up') {
        return opened.value ? [$classes.arrowDownUp, $classes.arrowDownUpActive].join(' ') : $classes.arrowDownUp
      }

      if (arrowMode.value === 'right-down') {
        return opened.value ? [$classes.arrowRightDown, $classes.arrowRightDownActive].join(' ') : $classes.arrowRightDown
      }

      return ''
    })

    function onToggle() {
      ctx.emit('change', !opened.value)
    }

    return {
      iconName,
      iconCls,
      onToggle,
    }
  },
  methods: {
    renderTip() {
      return (
        <GTooltip theme="light" mode="icon">
          <template slot="content">
            <div class="break-words max-w-xs text-xs leading-4" domPropsInnerHTML={this.tip}></div>
          </template>
        </GTooltip>
      )
    },
  },
  render() {
    return (
      <div>
        <div style={this.headerStyle} class={['flex items-center cursor-pointer', this.headerCls]} onClick={this.onToggle}>
          <div style={this.labelStyle} class={[this.truncate ? 'truncate' : '', 'leading-6', this.labelCls]} title={this.label}>
            {this.label}
          </div>
          {this.$slots.iconSlot ?? null}
          {this.tip ? (
            <div class="flex-shrink-0" style="line-height: 0;">
              {this.renderTip()}
            </div>
          ) : null}
          <span class={['flex-shrink-0', this.layout === 'loose' ? 'ml-auto' : 'ml-1']}>
            {this.$slots.iconLeft ? (
              <span class="mr-[10px] h-6 inline-block" style={{ 'line-height': `${this.iconSize}px` }}>
                {this.$slots.iconLeft}
              </span>
            ) : null}
            <GIcon
              data-testid="g-folding-container__trigger-btn"
              class={[this.iconCls]}
              svgName={this.iconName}
              size={this.iconSize}
              useSvgDefaultColor={true}
            ></GIcon>
          </span>
        </div>
        <div class={[{ hidden: !this.opened }, this.contentCls]}>{this.$slots.default}</div>
      </div>
    )
  },
})
