import feedbackCN from '@pharmsnap/pharmsnap-web/assets/img/feedbackCN.png'
import feedbackEN from '@pharmsnap/pharmsnap-web/assets/img/feedbackEN.png'
import { defineComponent, ref } from '@vue/composition-api'
import { useLocale } from 'pharmsnapMF_shared/composition'
import { feedbackUSAddress } from 'pharmsnapMF_shared/constants'
import styles from './Feedback.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export default defineComponent({
  name: 'FeedbackUS',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup() {
    const { isCN } = useLocale()
    const showFeedback = ref(true)
    const handleLinkToHelp = () => {
      // new Vue没有router对象,所以用原生实现跳转
      window.open(feedbackUSAddress, '_blank')
    }
    return {
      showFeedback,
      handleLinkToHelp,
      feedbackUSAddress,
      isCN,
    }
  },
  methods: {
    renderFeedback() {
      return (
        <div class={styles.feedback} onClick={this.handleLinkToHelp}>
          <img src={this.isCN ? feedbackCN : feedbackEN} />
          <div class={styles.closeButton} vOn:click_prevent_stop={() => (this.showFeedback = false)}>
            x
          </div>
        </div>
      )
    },
  },
  render() {
    return <div>{this.showFeedback && this.renderFeedback()}</div>
  },
})
