import { IEntityType, IQuery } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, isUndefined } from 'lodash'
import { IHistorySearchParams } from '../types'
import { getSingleQueryFieldWithMultiTargetIds, getSingleQueryIdWithEntityIds, getSingleQueryIdWithNoEntityIds } from '../utils'
import { IUseNavListBaseOptions, useNavList } from './useNavList'

interface IUseNavListIdsOptions extends IUseNavListBaseOptions {
  appendTo?: MaybeRef<'must' | 'filter'>

  multiTarget?: MaybeRef<boolean>
  /**
   * query 结构
   */
  query: MaybeRef<IQuery>
}

export function useNavListIds(options: IUseNavListIdsOptions) {
  const { appendTo = 'must', query, multiTarget = false, ...rest } = options

  const { dataType, getQueryIdInfo, generatorPathFunc, enhanceAuthNavList, defaultHistorySearchParams } = useNavList({
    auth: true,
    ...rest,
  })

  const onNavToListWithIds: (
    ids: string[],
    type: IEntityType,
    field?: string,
    adjustParams?: (params: IHistorySearchParams) => IHistorySearchParams | Promise<IHistorySearchParams>,
    newTab?: boolean
  ) => void = enhanceAuthNavList(
    (
      ids: string[],
      type: IEntityType,
      field?: string,
      adjustParams?: (params: IHistorySearchParams) => IHistorySearchParams | Promise<IHistorySearchParams>,
      newTab?: boolean
    ) => {
      const originQuery = cloneDeep(unref(query))
      const getQueryIdInfoWrapper = async () => {
        // 多靶点id跳转
        if (type === 'target' && unref(multiTarget)) {
          const query = await getSingleQueryFieldWithMultiTargetIds(ids, field)
          if (!originQuery.must) originQuery.must = []
          originQuery.must.push(query)
        } else {
          const queryItem = await getSingleQueryIdWithEntityIds(ids, type, field)
          if (!originQuery[unref(appendTo)]) originQuery[unref(appendTo)] = []
          originQuery[unref(appendTo)]?.push(queryItem)
        }
        const defaultParams = cloneDeep(defaultHistorySearchParams)
        const params: IHistorySearchParams = {
          ...defaultParams,
          data_type: dataType.value,
          query: {
            ...defaultParams.query,
            ...originQuery,
          },
        }

        const adjustedParams = adjustParams ? await adjustParams(params) : params

        const { queryId, fromQueryId } = await getQueryIdInfo(adjustedParams)

        return {
          queryId,
          fromQueryId,
        }
      }

      if (isUndefined(newTab) || newTab === true) {
        openPageWithoutPending(async () => {
          const { queryId, fromQueryId } = await getQueryIdInfoWrapper()
          return generatorPathFunc.value(queryId, fromQueryId)
        })
      } else {
        getQueryIdInfoWrapper().then(({ queryId, fromQueryId }) => {
          const href = generatorPathFunc.value(queryId, fromQueryId)

          if (!href) return

          location.replace(href)
        })
      }
    }
  )

  return {
    onNavToListWithIds,
  }
}

export function useNavListIdsNoEntity(options: IUseNavListIdsOptions) {
  const MAX_LIMIT_IDS = 200

  const { appendTo = 'must', query, ...rest } = options

  const { dataType, getQueryIdInfo, generatorPathFunc, enhanceAuthNavList, defaultHistorySearchParams } = useNavList({
    auth: true,
    ...rest,
  })

  const onNavToListWithIds: (
    ids: string[],
    field?: string,
    adjustParams?: (params: IHistorySearchParams) => IHistorySearchParams,
    newTab?: boolean
  ) => void = enhanceAuthNavList(
    (ids: string[], field: string, adjustParams?: (params: IHistorySearchParams) => IHistorySearchParams, newTab?: boolean) => {
      const originQuery = cloneDeep(unref(query))
      const getQueryIdInfoWrapper = async () => {
        const defaultParams = cloneDeep(defaultHistorySearchParams)

        originQuery[unref(appendTo)]?.push(getSingleQueryIdWithNoEntityIds(ids, field))
        const params: IHistorySearchParams = {
          ...defaultParams,
          data_type: dataType.value,
          query: {
            ...defaultParams.query,
            ...originQuery,
          },
        }
        const { queryId, fromQueryId } = await getQueryIdInfo(adjustParams ? adjustParams(params) : params)

        return {
          queryId,
          fromQueryId,
        }
      }

      if (isUndefined(newTab) || newTab === true) {
        openPageWithoutPending(async () => {
          const { queryId, fromQueryId } = await getQueryIdInfoWrapper()
          return generatorPathFunc.value(queryId, fromQueryId)
        })
      } else {
        getQueryIdInfoWrapper().then(({ queryId, fromQueryId }) => {
          const href = generatorPathFunc.value(queryId, fromQueryId)

          if (!href) return

          location.replace(href)
        })
      }
    }
  )

  return {
    onNavToListWithIds,
  }
}
