import { IDrugDealDetail } from '@patsnap/synapse_common_interface'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { BCardDrugDeal } from './BCardDrugDeal'
export const BCardDrugDealFetchList = defineComponent({
  name: 'BCardDrugDealFetchList',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
    /**
     * 是否翻译
     */
    translation: {
      type: Boolean,
      default: false,
    },
    formattedDataFunc: {
      type: Function as PropType<(data: IDrugDealDetail[]) => IDrugDealDetail[]>,
    },
  },
  setup(props, ctx) {
    const { loading, data } = useRequest(
      {
        requestFn: sharedCtx.service.drugDeal.getBatchDeals.bind(sharedCtx.service.drugDeal),
      },
      {
        ids: props.ids,
        translation: props.translation,
      }
    )

    const formattedData = computed(() => {
      const originList = data.value?.items || []
      if (props.formattedDataFunc) {
        return props.formattedDataFunc(originList)
      }
      return originList
    })

    return {
      loading,
      formattedData,
    }
  },
  render() {
    return (
      <div v-ls-loading={this.loading} class="min-h-[200px] overflow-auto">
        {this.formattedData.map((item) => {
          return <BCardDrugDeal class="mb-4" data={item}></BCardDrugDeal>
        })}
      </div>
    )
  },
})
