/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import type { IQueryDataType, IQueryGroupType } from '@patsnap/synapse_common_interface'
import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { E_ROUTER_QUERY, E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { sharedCtx } from '@pharmsnap/shared/context'
import { computed, Ref, unref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import type { IHistorySearchParams, INavListType, IQueryDataListType, IToastParam, IWebTrackingEvent } from '../types'
import { getGroupQueryIdsByQuery, getQueryIdByQueryV2, showSingleToast } from '../utils'
import { useAuthStore } from './useAuthStore'

type MaybeRef<T> = T | Ref<T>

type IGroupRouteType = Exclude<IQueryGroupType, 'drug' | 'drug_deal'>

export type IUseNavListInterceptor<T extends any[] = any[]> = (...params: T) => { message: string; type?: IToastParam['type'] } | boolean

export interface IUseNavListBaseOptions {
  /**
   * 跳转的页面，包含 临床/专利/新闻/文献/药物/药物重聚合页面
   */
  navList: MaybeRef<INavListType>
  /**
   * 免费用户显示弹窗
   */
  auth?: boolean
  /** 拦截器 */
  interceptors?: IUseNavListInterceptor[]
  /** 埋点数据 */
  tracking?: MaybeRef<IWebTrackingEvent[] | undefined>
}

export function useNavList(options: IUseNavListBaseOptions) {
  const { navList, auth = true, tracking, interceptors = [] } = options

  const {
    getters: { isFreeUser },
    actions,
  } = useAuthStore()

  const authInterceptor: IUseNavListInterceptor = () => {
    if (isFreeUser.value) {
      actions.changeUpgrade({
        show: true,
        type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL,
        trigger_point: unref(tracking)?.[0].tracking_data.trigger_point,
      })
      return false
    }
    return true
  }

  const actualInterceptors = auth ? [authInterceptor, ...interceptors] : [...interceptors]

  const defaultHistorySearchParams: IHistorySearchParams = {
    data_type: 'all',
    query: {
      type: 'group',
      must: [],
      filter: [],
    },
    hidden_flag: true,
  }

  const routePathMap: Record<IQueryDataListType, (id: string, tracking?: IWebTrackingEvent[]) => string> = {
    drug: sharedCtx.router.generatorDrugListPath,
    clinical_trial: sharedCtx.router.generatorClinicalListPath,
    patent: sharedCtx.router.generatorPatentListPath,
    news: sharedCtx.router.generatorNewsListPath,
    paper: sharedCtx.router.generatorPaperListPath,
    clinical_trial_result: sharedCtx.router.generatorClinicalResultListPath,
    drug_deal: sharedCtx.router.generatorDrugDealListPath,
    translational_medicine: sharedCtx.router.generatorTranslationalMedicineListPath,
  }

  const groupRoutePathMap: Record<IGroupRouteType, (queryId: string, fromQueryId: string, tracking?: IWebTrackingEvent[]) => string> = {
    target: sharedCtx.router.generatorDrugListPathGroupByTarget,
    disease: sharedCtx.router.generatorDrugListPathGroupByIndication,
    organization: sharedCtx.router.generatorDrugListPathGroupByOrg,
    drug_type: sharedCtx.router.generatorDrugListPathGroupByDrugType,
    dev_status: sharedCtx.router.generatorDrugListPathGroupByDevStatus,
    drug_deal_drug: sharedCtx.router.generatorDrugDealListPathGroupByDrug,
  }

  const isGroupListType = computed(() => {
    const type = unref(navList)
    return type.startsWith('drug_group')
  })

  const isDrugDealGroupListType = computed(() => {
    const type = unref(navList)
    return type.startsWith('drug_deal_group')
  })

  const dataType = computed<IQueryDataType>(() => {
    if (isGroupListType.value || unref(navList) === 'drug') return 'drug'
    if (isDrugDealGroupListType.value || unref(navList) === 'drug_deal') return 'drug_deal'
    return unref(navList) as IQueryDataListType
  })

  const listType = computed<IQueryDataListType | IQueryGroupType>(() => {
    const type = unref(navList)
    if (isGroupListType.value) return type.replace('drug_group_', '') as IQueryGroupType
    if (isDrugDealGroupListType.value) return type.replace('drug_deal_group_', '') as IQueryGroupType
    return type as IQueryDataListType
  })

  const generatorPathFunc = computed(() => {
    return (id: string, fromId?: string) => {
      if ((isGroupListType.value || isDrugDealGroupListType.value) && groupRoutePathMap[listType.value as IGroupRouteType]) {
        return groupRoutePathMap[listType.value as IGroupRouteType](id, fromId || '', unref(tracking))
      }
      if (!isGroupListType.value && !isDrugDealGroupListType.value && routePathMap[listType.value as IQueryDataListType]) {
        return routePathMap[listType.value as IQueryDataListType](id, cloneDeep(unref(tracking)))
      }
      return ''
    }
  })

  function enhanceAuthNavList(fn: (...args: any[]) => Promise<any> | any, interceptors = actualInterceptors) {
    return async (...params: any[]) => {
      for (let i = 0; i < interceptors.length; i++) {
        const interceptor = interceptors[i]
        const res = interceptor(...params)
        if (res === false) return
        if (res === true) continue
        showSingleToast({
          type: 'warning',
          ...res,
        })
        return
      }
      return await fn(...params)
    }
  }

  async function getSingleQueryId(params: IHistorySearchParams) {
    return await getQueryIdByQueryV2(
      params.query,
      params.data_type,
      undefined,
      params.collapse,
      undefined,
      undefined,
      params.query_type,
      params.structure_query
    )
  }

  async function getGroupQueryIds(params: IHistorySearchParams) {
    return await getGroupQueryIdsByQuery({
      searchParams: params,
      groupBy: listType.value as IQueryGroupType,
      // originFilterStrategy: unref(navList) === 'drug_group_dev_status' ? 'clear' : 'clear',
      originFilterStrategy: 'clear',
    })
  }

  async function getQueryIdInfo(params: IHistorySearchParams) {
    const info = {
      queryId: '',
      fromQueryId: '',
    }
    if (isGroupListType.value || isDrugDealGroupListType.value) {
      const groupIdInfo = await getGroupQueryIds(params)
      Object.assign(info, groupIdInfo)
    } else {
      info.queryId = await getSingleQueryId(params)
    }

    return info
  }

  async function navToList(
    params: IHistorySearchParams,
    _options?: {
      adjustSearchParamsBeforeOpenPage?: (params: IHistorySearchParams) => Promise<IHistorySearchParams> | IHistorySearchParams
      adjustSearchParamsAfterOpenPage?: (params: IHistorySearchParams) => Promise<IHistorySearchParams> | IHistorySearchParams
    }
  ) {
    const { adjustSearchParamsAfterOpenPage, adjustSearchParamsBeforeOpenPage } = _options || {}
    const adjustedBeforeParams = adjustSearchParamsBeforeOpenPage ? await adjustSearchParamsBeforeOpenPage(params) : params
    openPageWithoutPending(async () => {
      const adjustedAfterParams = adjustSearchParamsAfterOpenPage ? await adjustSearchParamsAfterOpenPage(adjustedBeforeParams) : adjustedBeforeParams
      const { queryId, fromQueryId } = await getQueryIdInfo(adjustedAfterParams)
      const href = generatorPathFunc.value(queryId, fromQueryId)
      const hrefUrl = new URL(window.location.origin + href)
      if (params.data_type === 'patent') {
        hrefUrl.searchParams.set(E_ROUTER_QUERY.PATENT_COLLAPSE_MODE, 'user_setting')
      }
      return hrefUrl.toString()
    })
  }

  return {
    actualInterceptors,
    enhanceAuthNavList,
    generatorPathFunc,
    isGroupListType,
    getQueryIdInfo,
    dataType,
    listType,
    defaultHistorySearchParams,
    navToList,
  }
}
