import {
  IAutoCompleteParams,
  IBaseDictionaryInfo,
  IBaseDictItem,
  IBaseLink,
  ISearchResult,
  ISearchTreeParams,
  ISearchTreeResult,
  ITreeAggregationItem,
  ITreeAggregationParams,
} from '@pharmsnap/shared/types'
import { decrypt, getDrugAtcLinks } from '@pharmsnap/shared/utils'
import DataLoader from 'dataloader'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DictionaryApi extends BaseApi {
  private batchGetDictionaryLoader = new DataLoader((ids: readonly string[]) => this._getBatchDictionary(ids))
  private batchGetDictionaryWithoutIdLoader = new DataLoader((ids: readonly string[]) => this._getBatchDictionary(ids, true))
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async searchTree(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/dictionary/tree/search`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async searchTreeWithoutLimit(params: ISearchTreeParams): Promise<IHttpWrap<ISearchTreeResult>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/dictionary/search`),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getAutocomplete<T>(params: IAutoCompleteParams): Promise<IHttpWrap<T>> {
    const { keywords, limit = 5, cancelToken, dicType } = params
    return this.http({
      method: 'GET',
      url: this.getUrl('discoveryls_common/1.0/dictionary/autocomplete'),
      params: { prefix: keywords, limit, dict_type: dicType },
      cache: true,
      cancelToken,
    })
  }

  async _getBatchDictionary(ids: readonly string[], withoutId = false): Promise<Array<IBaseDictItem>> {
    const rt = (await this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/dictionary/batch'),
      data: ids,
      method: 'POST',
      cache: true,
      // type为0的含义为,不返回字典的id,防止别人获取我们的字典数据
      params: withoutId ? { type: 0 } : undefined,
    })) as IHttpWrap<ISearchResult<IBaseDictItem>>
    if (rt.success && rt.data) {
      return decryptDictItem(rt.data.items, rt.xCorrelationId, rt.fontClassName)
    }
    return []
  }
  /**
   * 批量获取字典数据
   * @param ids
   * @returns
   */
  async getBatchDictionary(ids: readonly string[]): Promise<IHttpWrap<IBaseDictItem[]>> {
    const rt = (await this.batchGetDictionaryLoader.loadMany(ids)) as IBaseDictItem[]
    return {
      success: true,
      status: 200,
      data: rt,
      xCorrelationId: '',
    }
  }
  /**
   * 批量获取字典数据,不返回字典的id
   * @param ids
   * @returns
   */
  async getBatchDictionaryWithoutId(ids: readonly string[]): Promise<IHttpWrap<IBaseDictItem[]>> {
    const rt = (await this.batchGetDictionaryWithoutIdLoader.loadMany(ids)) as IBaseDictItem[]
    return {
      success: true,
      status: 200,
      data: rt,
      xCorrelationId: '',
    }
  }
  async getBatchAtc(ids: readonly string[]): Promise<IHttpWrap<IBaseLink[]>> {
    const rt = (await this.batchGetDictionaryLoader.loadMany(ids)) as IBaseDictItem[]
    return {
      success: true,
      status: 200,
      data: getDrugAtcLinks(rt),
      xCorrelationId: '',
    }
  }
  async getAdvanceTreeAggregation(params: ITreeAggregationParams): Promise<IHttpWrap<ISearchResult<ITreeAggregationItem>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/dictionary/aggregation/tree'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }
  async getBasicInfo(id: string): Promise<IHttpWrap<IBaseDictionaryInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/dictionary/${id}`),
      cache: true,
    })
  }
}

function decryptDictItem<T extends Partial<Pick<IBaseDictItem, 'dict_type' | 'name_cn' | 'name_en'>>>(
  list: T[] = [],
  xCorrelationId: string,
  fontClassName?: string
): T[] {
  const needDecryptDictType = ['PATENT_TYPE']
  return (
    list.map((item) => {
      if (needDecryptDictType.includes(item.dict_type || '')) {
        return {
          ...item,
          name_en: item.name_en ? decrypt(item.name_en, xCorrelationId) : '',
          name_cn: item.name_cn ? decrypt(item.name_cn, xCorrelationId) : '',
          fontClassName: fontClassName,
        }
      }
      return item
    }) || []
  )
}
