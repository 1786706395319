export function getExamples(isCN = true) {
  return isCN
    ? [
        'siRNA药物的适应症有哪些?',
        '308239-86-3的专利有哪些?',
        '度伐利尤单抗针对非小细胞肺癌的临床结果有哪些?',
        '在FDA的临床试验中, 如何确保受试者的知情同意?',
      ]
    : [
        'For what indications are siRNA drugs being investigated?',
        'What patents exist for compound 308239-86-3?',
        'What are the clinical outcomes of durvalumab in NSCLC?',
        'How is informed consent ensured in FDA clinical trials?',
      ]
}
