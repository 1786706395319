import G6, { EdgeConfig, Graph, INode, Item, NodeConfig } from '@antv/g6'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { getChartWaterMark } from '@pharmsnap/shared/utils'
import { PropType, computed, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import companyLogo from '../../../assets/img/company.svg'

/**
 *  图片等比缩放，适应容器,类似 object-fit: contain;效果
 *
 * @param {number} containerWidth 容器宽度
 * @param {number} containerHeight 容器高度
 * @param {number} imageWidth 图片宽度
 * @param {number} imageHeight 图片高度
 * @return {*}
 */
function containFit(containerWidth: number, containerHeight: number, imageWidth: number, imageHeight: number) {
  const containerRatio = containerWidth / containerHeight
  const imageRatio = imageWidth / imageHeight
  let width, height

  if (imageRatio < containerRatio) {
    // 图片宽高比小于容器宽高比，以容器高度为基准计算宽度
    height = containerHeight
    width = height * imageRatio
  } else if (imageRatio > containerRatio) {
    // 图片宽高比大于容器宽高比，以容器宽度为基准计算高度
    width = containerWidth
    height = width / imageRatio
  } else {
    // 图片宽高比等于容器宽高比，尺寸与容器相等
    width = containerWidth
    height = containerHeight
  }

  return { width, height }
}
export const BCoInvestorsChart = defineComponent({
  name: 'BCoInvestorsChart',
  props: {
    nodes: {
      type: Array as PropType<NodeConfig[]>,
      default: () => [],
    },
    edges: {
      type: Array as PropType<EdgeConfig[]>,
      default: () => [],
    },
    showCard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const graph = ref<Graph | null>()
    const ins = getCurrentInstance()
    const { isCN } = useLocale()
    const {
      getters: { isFreeUser, isTrialUser },
    } = useAuthStore()

    const data = computed(() => {
      return {
        nodes: props.nodes,
        edges: props.edges,
      }
    })

    watch([() => data.value], () => {
      if (graph.value) {
        graph.value.clear()
        graph.value = null
      }
      const { offsetWidth, offsetHeight } = ins?.refs.container as HTMLDivElement
      const width = offsetWidth || 600
      const height = offsetHeight || 400
      const chartData = cloneDeep(data.value)
      chartData.nodes.forEach((o) => {
        if (o.fixCenter) {
          o.fx = width / 2
          o.fy = height / 2
        }
      })
      graph.value = new G6.Graph({
        container: ins?.refs.container as HTMLDivElement,
        width,
        height,
        layout: {
          type: 'gForce',
          linkDistance: 120,
          preventOverlap: true,
          workerEnabled: true,
          gpuEnabled: true,
          workerScriptURL: `${process.env.VUE_APP_PUBLIC_PATH}/js/layout.min.js`,
        },
        nodeStateStyles: {
          hoverEnable: {
            stroke: '#495973',
            lineWidth: 2,
            cursor: 'pointer',
          },
          hoverDisable: {
            cursor: 'not-allowed',
          },
          click: {
            stroke: '#495973',
            lineWidth: 2,
          },
        },
        edgeStateStyles: {
          hover: {
            stroke: '#914AED',
            lineWidth: 2,
            cursor: 'pointer',
          },
          click: {
            stroke: '#914AED',
            lineWidth: 2,
          },
        },
      })

      graph.value.data(chartData)
      bindEvents()
      graph.value.render()
      renderLogo()
      ;(isFreeUser.value || isTrialUser.value) && addWaterMark()
    })

    watch(
      () => props.showCard,
      () => {
        if (!props.showCard) {
          clearSelected()
        }
      }
    )

    function addWaterMark() {
      if (!graph.value) return
      const graphWidth = graph.value.getWidth()
      const graphHeight = graph.value.getHeight()
      const { waterMark, imgWidth, imgHeight } = getChartWaterMark(isCN.value)
      graph.value.setImageWaterMarker(waterMark, {
        width: graphWidth / 2,
        height: graphHeight / 2,
        image: { width: imgWidth, height: imgHeight, x: graphWidth / 6, y: graphHeight / 4 },
      })
    }

    function renderLogo() {
      if (!graph.value) return
      const visibleNodeList = graph.value?.getNodes().filter((node) => node.isVisible()) || []
      if (visibleNodeList.length) {
        visibleNodeList.forEach((node) => addNodeLogo(node))
      }
    }

    function addNodeLogo(node: INode) {
      const group = node.getContainer()
      const size = node.getModel().size as number
      const imgSrc = node.getModel().img || companyLogo
      const imageSize = size - 8
      const $img = new Image()

      $img.onload = () => {
        const { width, height } = containFit(imageSize, imageSize, $img.width, $img.height)
        const imageShape = group.addShape('image', {
          name: 'image-shape',
          attrs: {
            x: -width / 2,
            y: -height / 2,

            img: node.getModel().img || companyLogo,
            width,
            height,
            cursor: node.getModel().disable ? 'not-allowed' : 'pointer',
          },
          capture: true,
        })
        imageShape.setClip({
          type: 'circle',
          attrs: {
            r: size / 2 - 2,
            y: 0,
            x: 0,
          },
        })
        /**
         * 图片的stroke无法生效，
         * 所以再画一个circle，为了能够高亮那一圈黄色
         */
        group.addShape('circle', {
          name: 'image-border',
          attrs: {
            stroke: '#ffffff',
            lineWidth: 3,
            r: size / 2 - 2.5,
          },
        })
      }
      $img.src = imgSrc
    }

    function clearSelected() {
      const clickEdges = graph.value?.findAllByState('edge', 'click')
      clickEdges?.forEach((cn) => {
        graph.value?.setItemState(cn, 'click', false)
      })
      const clickNodes = graph.value?.findAllByState('node', 'click')
      clickNodes?.forEach((cn) => {
        graph.value?.setItemState(cn, 'click', false)
        setImageBorderAttr(cn, { stroke: '#ffffff' })
        cn.getModel().clicked = false
      })
    }

    function getEdgeByNode(nodeId: string) {
      const edges = graph.value?.getEdges()
      const res = edges?.find((edge) => edge.getModel().source === nodeId)
      return res
    }

    function getNodeByEdge(edgeId: string) {
      const nodes = graph.value?.getNodes()
      const res = nodes?.find((node) => node.getModel().id === edgeId)
      return res
    }

    function setEdgeClassByNodeId(nodeId: string, className: string, active: boolean) {
      const edge = nodeId ? getEdgeByNode(nodeId) : undefined
      if (edge) {
        const edgeId = edge.getModel().id
        edgeId && graph.value?.setItemState(edgeId, className, active)
      }
    }

    function setNodeClass(node: Item, className: string, active: boolean) {
      const nodeId = node.getModel().id
      nodeId && graph.value?.setItemState(nodeId, className, active)
    }

    function setImageBorderAttr(node: Item, attrs: any = {}) {
      const group = node?.getContainer()
      if (group) {
        const imageBorderShape = group.find((o) => o.get('name') === 'image-border')
        imageBorderShape.attr(attrs)
      }
    }

    function bindRenderEvent() {
      if (graph.value) {
        graph.value.on('beforelayout', () => {
          emit('changeLoading', true)
        })
        graph.value.on('afterlayout', () => {
          emit('changeLoading', false)
          graph.value?.fitView()
        })
      }
    }

    function bindNodeEvents() {
      if (graph.value) {
        graph.value.on('node:mouseenter', (e) => {
          const nodeItem = e.item // 获取鼠标进入的节点元素对象
          const id = nodeItem?.getModel().id
          const hoverType = nodeItem?.getModel().disable ? 'hoverDisable' : 'hoverEnable'
          nodeItem && graph.value?.setItemState(nodeItem, hoverType, true) // 设置当前节点的 hover 状态为 true
          nodeItem && !nodeItem?.getModel().disable && setImageBorderAttr(nodeItem, { stroke: '#F7F28D' })
          id && setEdgeClassByNodeId(id, 'hover', true)
        })
        graph.value.on('node:mouseleave', (e) => {
          const nodeItem = e.item // 获取鼠标进入的节点元素对象
          const id = nodeItem?.getModel().id
          const hoverType = nodeItem?.getModel().disable ? 'hoverDisable' : 'hoverEnable'
          nodeItem && graph.value?.setItemState(nodeItem, hoverType, false) // 设置当前节点的 hover 状态为 true
          const isClicked = nodeItem?.getModel().clicked
          nodeItem && !isClicked && setImageBorderAttr(nodeItem, { stroke: '#ffffff' })
          id && setEdgeClassByNodeId(id, 'hover', false)
        })
        graph.value.on('node:click', (e) => {
          const nodeItem = e.item
          if (nodeItem?.getModel().disable) return
          clearSelected()
          const id = nodeItem?.getModel().id
          if (id) {
            emit('selectOrg', id)
            setEdgeClassByNodeId(id, 'click', true)
          }
          if (nodeItem) {
            nodeItem.getModel().clicked = true
            graph.value?.setItemState(nodeItem, 'click', true)
            setImageBorderAttr(nodeItem, { stroke: '#F7F28D' })
          }
        })
      }
    }

    function bindEdgeEvents() {
      if (graph.value) {
        graph.value.on('edge:mouseenter', (e) => {
          const edgeItem = e.item // 获取鼠标进入的节点元素对象
          const id = edgeItem?.getModel().source as string
          edgeItem && graph.value?.setItemState(edgeItem, 'hover', true) // 设置当前节点的 hover 状态为 true
          const nodeItem = id ? getNodeByEdge(id) : undefined
          if (nodeItem) {
            setNodeClass(nodeItem, 'hoverEnable', true)
            setImageBorderAttr(nodeItem, { stroke: '#F7F28D' })
          }
        })
        graph.value.on('edge:mouseleave', (e) => {
          const edgeItem = e.item // 获取鼠标进入的节点元素对象
          const id = edgeItem?.getModel().source as string
          edgeItem && graph.value?.setItemState(edgeItem, 'hover', false) // 设置当前节点的 hover 状态为 true
          const nodeItem = id ? getNodeByEdge(id) : undefined
          if (nodeItem) {
            setNodeClass(nodeItem, 'hoverEnable', false)
            const isClicked = nodeItem.getModel().clicked
            !isClicked && setImageBorderAttr(nodeItem, { stroke: '#ffffff' })
          }
        })
        graph.value.on('edge:click', (e) => {
          clearSelected()
          const edgeItem = e.item // 获取鼠标进入的节点元素对象
          const id = edgeItem?.getModel().source as string
          id && emit('selectOrg', id)
          edgeItem && graph.value?.setItemState(edgeItem, 'click', true)
          const nodeItem = id ? getNodeByEdge(id) : undefined
          if (nodeItem) {
            nodeItem.getModel().clicked = true
            setNodeClass(nodeItem, 'click', true)
            setImageBorderAttr(nodeItem, { stroke: '#F7F28D' })
          }
        })
      }
    }

    function bindEvents() {
      bindRenderEvent()
      bindNodeEvents()
      bindEdgeEvents()
    }

    return {}
  },
  render() {
    return <div ref="container"></div>
  },
})
