import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import {
  CT_RESULT_DATA_SOURCE_LANG,
  CT_RESULT_SPONSOR_EMP_NUM,
  SINGLE_OR_MULTI_LANG,
} from '@pharmsnap/shared/src/constants/enum-lang/clinical-result'
import { ORG_ENTITY_LANG } from '@pharmsnap/shared/src/constants/enum-lang/org'
import { filter, toNumber } from 'lodash'
import {
  injectLangFieldByKey,
  injectLangFieldByKeyEnumMap,
  injectLangFieldByKeyLangMap,
  injectOrgName,
  transformGroupAggregationSingleResultByMap,
} from './base'

export function replaceDisplayNameByShortLangMap(data: IAggregationValue): IAggregationValue {
  if (!data.short_name_en) return data
  if (data.short_name_en && data.short_name_en.length === 0) return data
  return {
    ...data,
    display_name_en: data.short_name_en[0] || '',
    display_name_cn: data.short_name_cn?.[0] || '',
  }
}

function sortGroupAggregationSingleResult(
  data: IAggregationSingleResult<IAggregationValue<any>>,
  order: string[]
): IAggregationSingleResult<IAggregationValue<any>> {
  return {
    ...data,
    items: data.items.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key)),
  }
}

function filterNoNameDrugs(data: IAggregationSingleResult<IAggregationValue<any>>): IAggregationSingleResult<IAggregationValue<any>> {
  return {
    ...data,
    items: filter(data.items, 'display_name_en'),
  }
}

function filterDataWithNAKey(data: IAggregationSingleResult<IAggregationValue<any>>): IAggregationSingleResult<IAggregationValue<any>> {
  return {
    ...data,
    items: filter(data.items, (item) => item.key !== 'N/A'),
  }
}

export const clinicalFieldTransformFnMap: Record<string, (data: IAggregationValue) => IAggregationValue> = {
  SPONSOR_COLLABORATOR_ORG_TYPE: (data) => injectLangFieldByKeyLangMap(ORG_ENTITY_LANG, data),
  SINGLE_OR_MULTI: (data) => injectLangFieldByKeyLangMap(SINGLE_OR_MULTI_LANG, data),
  SPONSOR_COLLABORATOR_ROOT_ID_ONEID: injectOrgName,
  SPONSOR_COLLABORATOR_ID_ONEID: injectOrgName,
  DATA_SOURCE: (data) => injectLangFieldByKeyLangMap(CT_RESULT_DATA_SOURCE_LANG, data),
  SPONSOR_COLLABORATOR_EMP_NUM_GENERAL: (data) => injectLangFieldByKeyEnumMap(CT_RESULT_SPONSOR_EMP_NUM, data),
  OVERALL_ENROLLMENT: injectLangFieldByKey,
}

const clinicalDataTransformFn = (data: IAggregationSingleResult<IAggregationValue<any>>) => {
  const clinicalFieldDataOrderMap: Record<
    string,
    (data: IAggregationSingleResult<IAggregationValue<any>>) => IAggregationSingleResult<IAggregationValue<any>>
  > = {
    DATA_SOURCE: (data) => sortGroupAggregationSingleResult(data, ['CTGOV', 'LITERATURE']),
    SPONSOR_COLLABORATOR_EMP_NUM_GENERAL: (data) => {
      return {
        ...data,
        items: data.items.sort((a, b) => toNumber(a.key) - toNumber(b.key)),
      }
    },
    SINGLE_OR_MULTI: (data) => sortGroupAggregationSingleResult(filterDataWithNAKey(data), ['single_drug', 'multiple_drugs']),
    THERAPY_TYPE_NOR_ID: (data) =>
      sortGroupAggregationSingleResult(data, [
        '47ab7a553e2336a5b5ecde6d38d208e9', //一线
        'c5e37a8173fb3e56b48d5d35b25e1a8b', //二线
        '62662f0b1d5b3fafabeb571a211e9a31', //三线
        '5d980a78d91b389fa653eab4c386dd57', //末线
        '41668084cc5234f0b29cb78894d0da62', //辅助
        '4e88ca62d0073b1ebb9700ab09cccd4d', //新辅助
        '10d0de28911c3f66863b9c8783f8148a', //维持
        '4289ad5c614f3037a8810e869b56facd', //巩固
        'b9aca50a3b39365a91ff3df678c21fe9', //追加
        'e8851e04a39e492e8958c64fe152c937', //诱导
      ]),
    GENERAL_EVALUATION_NOR_ID: (data) =>
      sortGroupAggregationSingleResult(filterDataWithNAKey(data), [
        'b768d49203dc39919ae9651a0a0155ab', //优效
        '3289297424e03eda9b788c083bbf3147', //积极
        '501e92dcf2b133f6a9ebae6117b59236', //非劣
        '0efa04d99d83316f84d2618cf4e013d4', //相似
        '15f2cafc40333f8b92096bd4a26c0b26', //非优
        'ffb9356ff2b73a85875c92fa7ea03b8b', //不佳
      ]),
    MAIN_EVALUATE_DRUG_ID: filterNoNameDrugs,
    MAIN_EVAL_AND_EXPER_DRUG_ID: filterNoNameDrugs,
  }

  return clinicalFieldDataOrderMap[data.aggregation_field] ? clinicalFieldDataOrderMap[data.aggregation_field](data) : data
}

export function transformClinicalResultAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(clinicalDataTransformFn(data), clinicalFieldTransformFnMap, xCorrelationId)
}
