import { BEntityLoadingCard, GCountryFlag, GDescription, GIconLink, GLimited } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElDivider } from '@pharmsnap/shared/element-ui'
import { renderDrugGlobalFirstApprovalDate } from '@pharmsnap/shared/render'
import { IDrugApprovalCardInfo, ILang } from '@pharmsnap/shared/types'
import { CTRL_C_INFO, getSpecialLang } from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import '../../../../assets/icon-svg/drug.svg'
import { BDiseaseItem } from '../../BDiseaseItem/BDiseaseItem'
import { BDrugApprovalTimeline } from '../../BDrugApprovalTimeline/BDrugApprovalTimeline'
import { BOrganizationItem } from '../../BOrganizationItem/BOrganizationItem'
import { BTargetItem } from '../../BTargetItem/BTargetItem'
import $classes from '../EntityCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import $styles from './BDrugCard.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDrugApprovalCard = defineComponent({
  name: 'BDrugApprovalCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDrugApprovalCardInfo>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    singleNamePlaceholder: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    /** 是否使用浅色边框：搜索结果页边框使用 gray-40 */
    isLighterBorder: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const target = computed(() => data?.value?.target_id_view || [])
    const devOrg = computed(() => data?.value?.active_org_master_entity_id_view || [])
    const originalDevOrg = computed(() => data?.value?.originator_org_master_entity_id_view || [])
    const approvalDisease = computed(() => data?.value?.approved_disease_id_view || [])
    const drugEnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'EN', isDegraded: false }))
    const drugCnName = computed(() => getSpecialLang({ data: data?.value?.drug_name || [], field: 'name', lang: 'CN', isDegraded: false }))
    // 是否有首次获批时间
    const hasApprovedTime = computed(() => !!data?.value?.drug_approval_timeline?.first_approved?.approved_date)

    return {
      locale,
      target,
      devOrg,
      originalDevOrg,
      approvalDisease,
      drugEnName,
      drugCnName,
      isCN,
      hasApprovedTime,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={1}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item }: { item: T }) => {
              return render(item)
            },
          }}
        ></GLimited>
      )
    },
    renderContent() {
      if (!this.data) return null

      return (
        <GDescription
          width={120}
          data={this.data}
          size="medium"
          direction={this.direction}
          gapSize={this.direction === 'vertical' ? 'mini' : 'base'}
          items={[
            {
              label: this.$t('drugCard.target') as string,
              field: 'target',
            },
            {
              label: this.$t('drugCard.originalDevOrg') as string,
              field: 'originalOrg',
            },
            {
              label: this.$t('drugCard.devOrg') as string,
              field: 'org',
            },
            {
              label: this.$t('common.onlyDisease') as string,
              field: 'approvalDisease',
              isShowFn: () => !this.hasApprovedTime,
            },
            {
              label: this.$t('drugCard.firstApprovalDate') as string,
              field: 'globalFirstApprovalDate',
              isShowFn: () => !this.hasApprovedTime,
            },
            {
              label: this.$t('drugCard.firstApprovalCountry') as string,
              field: 'globalFirstApprovalCountry',
              isShowFn: () => !this.hasApprovedTime,
            },
          ]}
          scopedSlots={{
            target: () =>
              this.renderLimited(this.target, this.data?.target_id_count || 0, (item) => <BTargetItem popover={false} data={item}></BTargetItem>),
            originalOrg: () =>
              this.renderLimited(this.originalDevOrg, this.data?.originator_org_master_entity_id_count || 0, (item) => (
                <BOrganizationItem popover={false} data={item}></BOrganizationItem>
              )),
            org: () =>
              this.renderLimited(this.devOrg, this.data?.active_org_master_entity_id_count || 0, (item) => (
                <BOrganizationItem popover={false} data={item}></BOrganizationItem>
              )),
            // 获批适应症
            approvalDisease: () =>
              this.renderLimited(this.approvalDisease, this.data?.approved_disease_id?.length || 0, (item) => (
                <BDiseaseItem popover={false} data={item}></BDiseaseItem>
              )),
            globalFirstApprovalDate: () => (this.data ? renderDrugGlobalFirstApprovalDate(this.data, this.$i18n) : EMPTY_PLACEHOLDER),
            globalFirstApprovalCountry: () =>
              this.renderLimited(this.data?.first_approved_country_view || [], this.data?.first_approved_country?.length || 0, (item) => {
                const countryName = item.alpha_2_code || item.display_name
                return <GCountryFlag class="mr-1" name={countryName} showName={true}></GCountryFlag>
              }),
          }}
        ></GDescription>
      )
    },
    renderApprovedTimeLine() {
      if (this.hasApprovedTime) {
        return (
          <div class="mt-4 p-2 rounded bg-gray-10">
            <BDrugApprovalTimeline drugId={this.data?.drug_id || ''} data={this.data?.drug_approval_timeline}></BDrugApprovalTimeline>
          </div>
        )
      }
    },
    renderCnName() {
      if (this.isCN && this.drugCnName) {
        return (
          <GIconLink
            class={$classes.title}
            ellipsisLine={1}
            href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
            name={this.drugCnName}
            newTab={true}
            defaultColor="blue"
          >
            <span class="mr-2 inline-block w-5" slot="icon"></span>
          </GIconLink>
        )
      }
      return null
    },
    renderDrugName() {
      return (
        <div class="flex flex-col overflow-hidden">
          <GIconLink
            class={$classes.title}
            ellipsisLine={1}
            href={sharedCtx.router.generatorDrugPath(this.data?.drug_id || '')}
            name={this.drugEnName}
            newTab={true}
            defaultColor="blue"
            size={20}
            svgName={this.showIcon ? 'Drug' : ''}
          ></GIconLink>
          {this.renderCnName()}
        </div>
      )
    },
    renderFooter() {
      if (this.showFooter) {
        return (
          <div>
            <ElDivider />
            {this.$slots.customFooter ? this.$slots.customFooter : null}
          </div>
        )
      }
      return null
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc={true} loading={this.loading} border={this.border}>
        <div class={[$classes.card, $styles.card, CTRL_C_INFO, this.isLighterBorder && 'border-gray-40', !this.border && $classes.cardNoBorder]}>
          <div class={[$classes.header, $styles.header]}>{this.renderDrugName()}</div>
          <div class={$classes.body}>{this.renderContent()}</div>
          {this.renderApprovedTimeLine()}
          <div class={$styles.footer}>{this.renderFooter()}</div>
        </div>
      </BEntityLoadingCard>
    )
  },
})
