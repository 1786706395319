import { ElSkeleton, ElSkeletonItem } from '@pharmsnap/shared/element-ui'
import { defineComponent } from '@vue/composition-api'

export const BFilterItemSkeleton = defineComponent({
  name: 'BFilterItemSkeleton',
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  render() {
    return (
      <ElSkeleton loading={true} animated={this.animated}>
        <template slot="template">
          {Array.from({ length: 6 }).map((_, index) => {
            return (
              <div class={['flex items-center', { 'mt-4': index !== 0 }]}>
                <ElSkeletonItem class={['h-4 w-4 flex-shrink-0', { 'rounded-none': !this.rounded }]}></ElSkeletonItem>
                <ElSkeletonItem class={['ml-4 h-4 flex-1 overflow-hidden', { 'rounded-none': !this.rounded }]}></ElSkeletonItem>
              </div>
            )
          })}
        </template>
      </ElSkeleton>
    )
  },
})
