import { E_NAV_KEY, E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import type { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.HOMEPAGE,
}

export default [
  {
    path: `homepage/search`,
    name: E_ROUTER_NAME.HOME_SEARCH_RESULT,
    component: () => import('@pharmsnap/pharmsnap-web/views/home/HomeSmartSearch'),
    meta,
  },
] as RouteConfig[]
