import '@patsnap-ui/icon/assets/solid/filers.svg'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import $classes from './BAnalysisChartSettingButton.module.scss'

export const BAnalysisChartSettingButton = defineComponent({
  name: 'BAnalysisChartSettingButton',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '240px',
    },
    popperVisible: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false)

    const popperRef = ref<HTMLElement | null>(null)

    const buttonRef = ref<HTMLElement | null>(null)

    onClickOutside(
      popperRef,
      () => {
        if (visible.value) {
          visible.value = false
        }
      },
      {
        ignore: [buttonRef],
      }
    )

    const handleToggle = () => {
      visible.value = !visible.value
      emit('toggle', visible.value)
    }

    const handleClickOutside = () => {
      visible.value = false
    }

    const handleCancel = () => {
      emit('cancel')
    }

    const handleConfirm = () => {
      emit('confirm')
    }

    watch(
      () => props.popperVisible,
      (val: boolean) => {
        if (val !== visible.value) {
          visible.value = val
        }
      }
    )

    return {
      visible,
      popperRef,
      buttonRef,
      handleToggle,
      handleClickOutside,
      handleCancel,
      handleConfirm,
    }
  },
  methods: {
    renderTrigger() {
      return (
        <button
          ref="buttonRef"
          onClick={this.handleToggle}
          showAnimate={false}
          class={[
            'inline-flex items-center',
            'box-border  outline-none',
            'h-8 pl-1 pr-2',
            'leading-8',
            'border border-gray-55 rounded bg-white-default ',
            'hover:bg-gray-30',
          ]}
        >
          <GIcon svgName="SolidFilers" size={24} useSvgDefaultColor></GIcon>
          <span class="text-text-t1 ml-1 text-sm">{this.$t('analysisChart.setting.title')}</span>
        </button>
      )
    },
    renderFooter() {
      return (
        <div class="text-right">
          <button onClick={this.handleCancel} class="pt-ui-btn mr-2" data-size="small" type="default">
            {this.$t('analysisChart.setting.cancel')}
          </button>
          <button onClick={this.handleConfirm} class="pt-ui-btn" data-size="small" type="submit">
            {this.$t('analysisChart.setting.apply')}
          </button>
        </div>
      )
    },
    renderContent() {
      // visible时，重新渲染内容，避免输入过程中的选项缓存，与图实际选项不匹配
      if (this.visible) {
        return (
          <div class={$classes.popperContent} style={{ width: this.width }} ref="popperRef">
            {this.$slots.header ? <div class="mb-2">{this.$slots.header}</div> : null}
            {this.$slots.default ? <div>{this.$slots.default}</div> : null}
            {this.showFooter ? <div class="mt-4">{this.renderFooter()}</div> : null}
          </div>
        )
      }
    },
  },
  render() {
    return (
      <GTooltip popperClass={$classes.popper} mode="text" theme="light" placement="bottom-end" enterable={true} manual={true} value={this.visible}>
        <template slot="default">{this.renderTrigger()}</template>
        <template slot="content">{this.renderContent()}</template>
      </GTooltip>
    )
  },
})
