import '@patsnap-ui/icon/assets/solid/edit.svg'
import { IQuery } from '@patsnap/synapse_common_interface'
import { GIcon } from '@pharmsnap/shared/components'
import { defineComponent, ref } from '@vue/composition-api'
import { BEditQueryDialog } from '../BEditQueryDialog'
import $classes from './BEditQueryDialogWithLabel.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BEditQueryDialogWithLabel = defineComponent({
  name: 'BEditQueryDialogWithLabel',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    editStr: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false)

    function showDialog() {
      visible.value = true
    }

    function hideDialog() {
      visible.value = false
    }

    function onConfirm(params: { query?: IQuery; source: string }) {
      emit('confirm', params)
      hideDialog()
    }

    return { visible, showDialog, hideDialog, onConfirm }
  },
  methods: {
    showEditQueryDialog() {
      this.showDialog()
    },
  },
  render() {
    return (
      <div class={$classes.editQueryContainer}>
        <div class={$classes.editQuery} onClick={this.showDialog}>
          {this.$slots.default || (
            <div>
              <GIcon svgName="SolidEdit" size={16} class="mr-1"></GIcon>
              <span>{this.$tc('BEditQueryDialogWithLabel.edit')}</span>
            </div>
          )}
        </div>
        <BEditQueryDialog
          title={this.$tc('BEditQueryDialogWithLabel.edit')}
          visible={this.visible}
          queryStr={this.editStr}
          onClose={this.hideDialog}
          onConfirm={this.onConfirm}
        ></BEditQueryDialog>
      </div>
    )
  },
})
