import { IVueI18n } from 'vue-i18n'
import { BDiseaseItem, BDrugAdcPopperDesc, BDrugTypeTagItem, BMosaic, BOrganizationItem, getDrugAdRenderContentList } from '../components'
import { BDevStatusDataSource } from '../components/business/BDevStatusDataSource/BDevStatusDataSource'
import { EMPTY_PLACEHOLDER } from '../constants'
import { ORG_ENTITY_LANG } from '../constants/enum-lang/org'
import { I18nLang } from '../i18n'
import { ILang } from '../types'
import { IDrugGroupByDataItem } from '../types/drug-group-by'
import { getFieldNameByLang, getLangDegraded } from '../utils/lang'
import { renderPopoverLimitedTargetsAsync, renderSinglePhase } from './baseRender'
import { renderBasicEmployeeNumber } from './orgRender'

export function renderSingleOrg(data: IDrugGroupByDataItem) {
  return <BOrganizationItem data={data.org_id_view}></BOrganizationItem>
}
export function renderMultipleTarget(data: IDrugGroupByDataItem) {
  return renderPopoverLimitedTargetsAsync(data.target_id_view || [], data.target_id || [], 3)
}
export function renderSingleDisease(data: IDrugGroupByDataItem) {
  return <BDiseaseItem data={data.disease_id_view}></BDiseaseItem>
}

export function renderSingleDrugType(data: IDrugGroupByDataItem) {
  return <BDrugTypeTagItem data={data.drug_type_id_view}></BDrugTypeTagItem>
}

export function renderEmployeeNumber(data: IDrugGroupByDataItem) {
  return renderBasicEmployeeNumber(data.employee_number)
}
export function renderEntityType(data: IDrugGroupByDataItem, $i18n: IVueI18n) {
  return getFieldNameByLang(ORG_ENTITY_LANG, data.entity_type, $i18n.locale as I18nLang) || EMPTY_PLACEHOLDER
}
export function renderDrugGroupPhase(data: IDrugGroupByDataItem, $i18n: IVueI18n) {
  return renderSinglePhase(data.phase_view, $i18n.locale.toUpperCase() as ILang)
}
export function renderCountry(data: IDrugGroupByDataItem, $i18n: IVueI18n) {
  return (
    getLangDegraded({ name_cn: data.country_id_view?.display_name_cn, name_en: data.country_id_view?.display_name }, $i18n.locale as I18nLang) ||
    EMPTY_PLACEHOLDER
  )
}

export function renderDrugAdcOneLineInTable(data: IDrugGroupByDataItem, $i18n: IVueI18n) {
  const renderInnerContent = () => {
    if (!data.is_xdc) return EMPTY_PLACEHOLDER

    const contentList = getDrugAdRenderContentList(data, $i18n)

    const foundValidContentList = contentList.filter((item) => !item.invalid(data))

    if (!foundValidContentList.length) return EMPTY_PLACEHOLDER

    const { label, content: Com } = foundValidContentList[0]

    return (
      <div class="flex items-center text-sm leading-6">
        <span class="text-text-t1 flex-shrink-0">{label}</span>
        <Com data={data} class="truncate leading-6"></Com>
        {foundValidContentList.length === 1 ? null : (
          <BDrugAdcPopperDesc data={data}>
            <span class="cursor-pointer text-blue-default ml-1">[more]</span>
          </BDrugAdcPopperDesc>
        )}
      </div>
    )
  }

  return (
    <BMosaic block={true} trigger_point="DRUG_ADC">
      {renderInnerContent()}
    </BMosaic>
  )
}
export function renderDevStatusDataSource(data: IDrugGroupByDataItem) {
  if (!data.data_source?.length) {
    return EMPTY_PLACEHOLDER
  }
  return <BDevStatusDataSource source={data.data_source} limitInline={true}></BDevStatusDataSource>
}
