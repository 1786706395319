import { E_AGGREGATION_TYPE, IAggregationItem } from '@patsnap/synapse_common_interface'
import { GMiniSwitch } from '@pharmsnap/shared/components'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ITopPatentCoAssignee } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { generatePatentAggParams } from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { FullTopAssigneeTable } from './FullTopAssigneeTable/FullTopAssigneeTable'
import { SmallTopAssigneeTable } from './SmallTopAssigneeTable/SmallTopAssigneeTable'
import { TopAssigneeChart } from './TopAssigneeChart/TopAssigneeChart'

export const TopAssigneesCooperation = defineComponent({
  name: 'TopAssigneesCooperation',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const chartData = reactive<ITopPatentCoAssignee>({
      items: [],
      links: [],
    })
    const isAggregationByParent = ref(true)
    const aggregationField = computed(() => (isAggregationByParent.value ? 'PHS_ORG_ROOT_ID_ONEID' : 'PHS_ORG_ID_ONEID'))
    const params = computed(() => {
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          aggregation_field: aggregationField.value,
          limit: 10,
          sub_aggregation: [
            {
              aggregation_type: E_AGGREGATION_TYPE.TERMS,
              aggregation_field: aggregationField.value,
              limit: 11,
            },
          ],
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation)
    })

    const { isLoading, isFullScreen, isEmpty, selectedField, toggleSelectedField, toggleFullScreen, getChartData } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getPatentCoAssignee.bind(sharedCtx.service.patent),
      checkEmptyFn: (data?: ITopPatentCoAssignee) => !data?.items.length,
      successCallback: (data: ITopPatentCoAssignee) => {
        chartData.items = data.items
        chartData.links = data.links
      },
    })

    const { isScrollInView, handleScrollInView } = useLazyComponent()

    watch(
      () => [isScrollInView.value, isAggregationByParent.value, props.queryService.state.query, props.queryService.state.collapse],
      async () => {
        if (isScrollInView.value) {
          await getChartData()
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      selectedField,
      isFullScreen,
      chartData,
      isLoading,
      isEmpty,
      toggleFullScreen,
      toggleSelectedField,
      handleScrollInView,
      isAggregationByParent,
    }
  },
  methods: {
    renderAggregationByPatent() {
      return (
        <GMiniSwitch
          v-model={this.isAggregationByParent}
          onChange={(val: boolean) => (this.isAggregationByParent = val)}
          width={16}
          active-color="#1976D2"
          inactive-color="#BCC2CC"
          active-text={this.$tc('chart.aggregation')}
        ></GMiniSwitch>
      )
    },
    renderTopAssigneeChart(isFull = false) {
      return (
        <TopAssigneeChart
          aggregateByParent={this.isAggregationByParent}
          hasBox={isFull}
          queryService={this.queryService}
          chartData={this.chartData}
        />
      )
    },
    renderAnalysis() {
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            isLoading={this.isLoading}
            title={this.$tc('topAssigneesCooperation.title')}
            desc={this.$tc('topAssigneesCooperation.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            containerHeight={626}
            isEmpty={this.isEmpty}
            class="mt-4"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="relationship"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              {this.renderAggregationByPatent()}
            </template>
            <template slot="default">
              {this.selectedField === 'chart' ? this.renderTopAssigneeChart() : <SmallTopAssigneeTable chartData={this.chartData} />}
            </template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            isLoading={this.isLoading}
            title={this.$tc('topAssigneesCooperation.title')}
            desc={this.$tc('topAssigneesCooperation.desc')}
            isFullScreen
            onToggleFullScreen={this.toggleFullScreen}
            mainLayout="full"
            isEmpty={this.isEmpty}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                {this.renderTopAssigneeChart(true)}
                <FullTopAssigneeTable chartData={this.chartData} class="mt-8" />
              </div>
            </template>
            <template slot="aggregationBtn">{this.renderAggregationByPatent()}</template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
