import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { defineComponent, PropType, watch } from '@vue/composition-api'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentStatusField } from '../PatentStatusField/PatentStatusField'
import { useTherapeuticArea } from './useTherapeuticArea'
import { useTherapeuticAreaChart } from './useTherapeuticAreaChart'
import { useTherapeuticAreaTable } from './useTherapeuticAreaTable'

export const TherapeuticAreas = defineComponent({
  name: 'TherapeuticAreas',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const {
      computedAggData,
      loadingRef,
      computedIsEmpty,
      loadingFullScreenRef,
      isFullScreenRef,
      selectedPatentTypeRef,
      selectedViewTypeRef,
      fetchChartData,
      handleChangePatentType,
      handleChangeViewType,
      handleToggleFullScreen,
    } = useTherapeuticArea({ service: props.queryService })

    const { chartContainer, fullScreenChartContainer, chartContainerHeight } = useTherapeuticAreaChart({
      service: props.queryService,
      aggDataRef: computedAggData,
      selectedPatentTypeRef,
    })

    const { computedColumns, computedFullScreenColumns, computedFullScreenData } = useTherapeuticAreaTable({
      aggDataRef: computedAggData,
      selectedPatentTypeRef,
    })

    const { isScrollInView, handleScrollInView } = useLazyComponent()

    watch(isScrollInView, fetchChartData)
    watch(
      () => [props.queryService.state.query, props.queryService.state.collapse],
      () => {
        if (isScrollInView.value) {
          fetchChartData()
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    return {
      computedAggData,
      loadingRef,
      computedIsEmpty,
      loadingFullScreenRef,
      computedFullScreenColumns,
      computedFullScreenData,
      fullScreenChartContainer,
      chartContainer,
      isFullScreenRef,
      computedColumns,
      selectedPatentTypeRef,
      selectedViewTypeRef,
      chartContainerHeight,
      handleScrollInView,
      handleChangePatentType,
      handleChangeViewType,
      handleToggleFullScreen,
    }
  },
  methods: {
    renderChartView() {
      return <div key="chart-view" class="w-full h-full" ref="chartContainer"></div>
    },
    renderTableView() {
      return (
        <div key="table-view" class="w-full h-full">
          <vxe-grid size="small" maxHeight="100%" border showHeader columns={this.computedColumns} data={this.computedAggData}></vxe-grid>
        </div>
      )
    },
    renderContent() {
      return this.selectedViewTypeRef === 'chart' ? this.renderChartView() : this.renderTableView()
    },
    renderFullScreenChart() {
      return <div ref="fullScreenChartContainer" class="w-full h-full"></div>
    },
    renderFullScreenTable() {
      return (
        <vxe-grid
          border
          size="small"
          showOverflow="title"
          showHeader={false}
          columns={this.computedFullScreenColumns}
          data={this.computedFullScreenData}
        ></vxe-grid>
      )
    },
    renderPatentStatus(fullScreen: boolean) {
      return (
        <PatentStatusField
          isFullScreen={fullScreen}
          value={this.selectedPatentTypeRef}
          onChangePatentStatus={this.handleChangePatentType}
        ></PatentStatusField>
      )
    },
    renderAnalysis() {
      const containerHeight = this.chartContainerHeight + 146
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            isLoading={this.loadingRef}
            isEmpty={this.computedIsEmpty}
            blockTitle={this.$tc('therapeuticAreas.therapeuticAreasAnalysis')}
            title={this.$tc('therapeuticAreas.title')}
            desc={this.$tc('therapeuticAreas.desc')}
            onToggleFullScreen={this.handleToggleFullScreen}
            containerHeight={containerHeight}
            class="mt-12"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="horizonBar"
                selectedValue={this.selectedViewTypeRef}
                onChangeField={this.handleChangeViewType}
              ></GAnalysisDisplayField>
              {this.renderPatentStatus(false)}
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreenRef) {
        return (
          <AnalysisBlock
            isLoading={this.loadingFullScreenRef}
            isEmpty={this.computedIsEmpty}
            title={this.$tc('therapeuticAreas.title')}
            desc={this.$tc('therapeuticAreas.desc')}
            isFullScreen
            onToggleFullScreen={this.handleToggleFullScreen}
            fullScreenSettings={[{ label: this.$tc('data'), content: () => this.renderPatentStatus(true) }]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div class={$style.fullScreenChart} style={{ height: `${this.chartContainerHeight}px` }}>
                  {this.renderFullScreenChart()}
                </div>
                <div class={['flex-shrink-0 mt-8', $style.fullScreenTable]}>{this.renderFullScreenTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
