import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import { getShownEntityProfile, getSpecialLang } from '@patsnap/synapse_common_utils'
import '@pharmsnap/shared/assets/icon-svg/disease.svg'
import { BDrugItem, GIcon, GIconLink, GLimited, GLimitedContainer, GLink } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderDiseaseIntroductionPlain } from '@pharmsnap/shared/src/render'
import { getAllSpecialLang } from '@pharmsnap/shared/src/utils'
import { IDiseaseCardInfo, ILang } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import $classes from '../EntityIntelligentCard.module.scss'
import cmpCn from '../locales/cn.json'
import cmpEn from '../locales/en.json'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BDiseaseIntelligentCard = defineComponent({
  name: 'BDiseaseIntelligentCard',
  i18n: {
    messages: {
      cn: { ...cn, ...cmpCn },
      en: { ...en, ...cmpEn },
    },
  },
  props: {
    data: {
      type: Object as PropType<IDiseaseCardInfo>,
    },
    layout: {
      type: String as PropType<'basic' | 'intermediate' | 'advanced'>,
      default: 'intermediate',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    descMaxHeight: {
      type: String,
      default: '280px',
    },
    descLimitLines: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const relationDrugs = computed(() => data?.value?.related_drug_id_view || [])
    const displayName = computed(
      () => getSpecialLang({ data: data?.value?.disease_name || [], field: 'name', lang: locale.value }) || EMPTY_PLACEHOLDER
    )

    const phase = computed(() => {
      const highestPhase = data?.value?.highest_dev_status_view
      const name = locale.value === 'CN' ? highestPhase?.name_cn || highestPhase?.name_en : highestPhase?.name_en
      return name
    })
    return {
      displayName,
      locale,
      relationDrugs,
      phase,
      isCN,
    }
  },
  methods: {
    renderLimited<T>(list: T[], totalCount: number, render: (item: T, items: T[], index: number) => JSX.Element) {
      if (list.length === 0) return EMPTY_PLACEHOLDER
      if (totalCount === 0) return EMPTY_PLACEHOLDER

      return (
        <GLimited
          class="max-w-full"
          mode="link"
          totalCount={totalCount}
          items={list}
          limit={3}
          linkTip={this.$t('sharedComponent.viewInDetailTip') as string}
          link={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
          inline={true}
          scopedSlots={{
            default: ({ item, index, items }: { item: T; items: T[]; index: number }) => {
              return render(item, items, index)
            },
          }}
        ></GLimited>
      )
    },
    renderField(label: string, content: JSX.Element | string) {
      return (
        <div class="flex items-start">
          <div style={this.labelWidth === 'auto' ? '' : `width:${this.labelWidth}`} class="flex-shrink-0 text-sm text-text-t2 mr-2 leading-6">
            {label}
            {':'}
          </div>
          <div class="flex-1 overflow-hidden">{content}</div>
        </div>
      )
    },
    renderHeader() {
      const nameEn = getSpecialLang({ data: this.data?.disease_name || [], field: 'name', lang: 'EN', isDegraded: false })
      const nameCn = getSpecialLang({ data: this.data?.disease_name || [], field: 'name', lang: 'CN', isDegraded: false })

      if (this.layout === 'basic') {
        return (
          <div class="flex items-center">
            <GIcon class="mr-2 flex-shrink-0" svgName="Disease" size={32} useSvgDefaultColor shape="square" border></GIcon>
            <div class="flex-1 overflow-hidden">
              <GLink
                style={{ lineHeight: `24px` }}
                class="block font-semibold text-text-t1 text-base"
                newTab={true}
                name={nameEn && nameCn ? nameEn : this.displayName}
                href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                truncate={true}
              ></GLink>
              {this.isCN && !!nameCn ? (
                <GLink
                  style={{ lineHeight: `16px` }}
                  class="block text-xs text-text-t1"
                  ellipsisLine={1}
                  href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                  name={nameCn}
                  truncate={true}
                ></GLink>
              ) : null}
            </div>
          </div>
        )
      }

      if (this.layout === 'intermediate') {
        return (
          <div>
            <div style="line-height: 0;">
              <GIconLink
                class="font-semibold text-text-t1 text-base"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                name={nameEn && nameCn ? nameEn : this.displayName}
                newTab={true}
                defaultColor="dark"
                size={24}
                svgName="Disease"
              ></GIconLink>
            </div>
            {this.isCN && !!nameCn ? (
              <div style="line-height: 0;" class="pl-8">
                <GIconLink
                  class="text-sm text-text-t1"
                  ellipsisLine={1}
                  href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                  name={nameCn}
                  newTab={true}
                  defaultColor="dark"
                  size={20}
                ></GIconLink>
              </div>
            ) : null}
          </div>
        )
      }

      const showProfile = this.data && !!getShownEntityProfile(this.data.profile_v2).length

      return (
        <div class="pb-2 border-b border-gray-30">
          <div style="line-height: 0;">
            <GIconLink
              class="font-semibold text-text-t1 text-base"
              ellipsisLine={1}
              href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
              name={nameEn && nameCn ? nameEn : this.displayName}
              newTab={true}
              defaultColor="dark"
              size={32}
              svgName="Disease"
              border={true}
            ></GIconLink>
          </div>
          {this.isCN && !!nameCn ? (
            <div style="line-height: 0;" class="pl-10">
              <GIconLink
                class="text-sm text-text-t1"
                ellipsisLine={1}
                href={sharedCtx.router.generatorDiseasePath(this.data?.disease_id || '')}
                name={nameCn}
                newTab={true}
                defaultColor="dark"
                size={20}
              ></GIconLink>
            </div>
          ) : null}
          {showProfile ? (
            this.descLimitLines <= 0 ? (
              <div
                class="mt-3 text-sm overflow-y-auto leading-5 text-text-t2"
                style={{ maxHeight: this.descMaxHeight }}
                domPropsInnerHTML={renderDiseaseIntroductionPlain(this.data!)}
              ></div>
            ) : (
              <GLimitedContainer class="mt-3" limitLines={this.descLimitLines} lineHeight={20}>
                <div class="text-sm text-text-t2 leading-5" domPropsInnerHTML={renderDiseaseIntroductionPlain(this.data!)}></div>
              </GLimitedContainer>
            )
          ) : null}
        </div>
      )
    },
    renderBody() {
      if (this.layout === 'basic') return null

      const synonymsList = getAllSpecialLang({ data: this.data?.alias || [], lang: this.locale, field: 'name' })

      const relatedDrugsContent = this.renderLimited(this.relationDrugs, this.data?.related_drug_id_count || 0, (item) => (
        <BDrugItem showCard={false} data={item}></BDrugItem>
      ))

      const relatedSynonymsContent = this.renderLimited(synonymsList, synonymsList.length, (item, items, index) => (
        <span style="line-height: 24px; font-size: 14px;" class="whitespace-pre inline-block max-w-full truncate">
          {item}
          {index !== items.length - 1 ? ', ' : ''}
        </span>
      ))

      const content = [
        this.renderField(this.$t('diseaseCard.synonyms') as string, relatedSynonymsContent),
        this.renderField(this.$t('diseaseCard.relatedDrugs') as string, relatedDrugsContent),
      ]

      return <div class="space-y-1 mt-4">{content}</div>
    },
  },
  render() {
    return (
      <div class={$classes.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
})
