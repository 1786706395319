import { PersistentCacheConfig, PersistentCacheStrategy } from '@patsnap/common-ps-http/dist/PersistentCache'
export const synapsePersistCacheConfInst = new PersistentCacheConfig({
  strategy: PersistentCacheStrategy.CACHE_FIRST_WITH_SILENT_UPDATE,
  async detectAllowCachingHook(resp): Promise<boolean> {
    if (!resp.ok) {
      return false
    }
    return true
  },
})
