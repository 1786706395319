import { IAggregationValue, IQueryItem } from '@patsnap/synapse_common_interface'
import { BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { useChart } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { IQueryDataEntityType } from '@pharmsnap/shared/types'
import { computed, getCurrentInstance, ref, Ref, watch } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import { IPatentStatus } from '../../../types'
import { useMouseOverAxis } from '../../../use/useMouseOverAxis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import { getChartHeight, getGrantedFilterItem, getHorizontalBarOptions } from '../../../utils'

export function useTherapeuticAreaChart(options: {
  service: IQueryService
  aggDataRef: Ref<IAggregationValue[]>
  selectedPatentTypeRef: Ref<IPatentStatus>
}) {
  const ins = getCurrentInstance()

  const { aggDataRef, selectedPatentTypeRef } = options

  const labelRotate = ref(0)

  const layout = ref<'vertical' | 'horizontal'>('horizontal')

  const entityType = ref<Exclude<IQueryDataEntityType, 'mechanism'>>('disease')

  const chartOptions = ref<BasicHorizontalBarChart>(getChartOptions())

  const chartFullScreenOptions = ref<BasicHorizontalBarChart>(getChartOptions())

  const { onClickChart } = usePatentChartDrill()

  const chartContainerHeight = computed(() => getChartHeight(aggDataRef.value))

  const onClickHorizontalBarChart = (params: any) => {
    const data = params.data as IAggregationValue
    const extraFilter: IQueryItem[] = data.aggregation_field
      ? [
          {
            type: 'field',
            fields: [data.aggregation_field],
            value: [
              {
                type: 'text',
                value: data.key,
                display_name_cn: data.display_name_cn,
                display_name_en: data.display_name_en,
              },
            ],
          },
        ]
      : []
    if (options.selectedPatentTypeRef.value === 'granted') {
      extraFilter.push(getGrantedFilterItem())
    }
    onClickChart({ queryState: options.service.state, extraFilter })
  }

  const { chartContainer } = useChart(chartOptions, {
    autoResize: true,
    registerEvent,
  })

  const { handleAxisMouseover } = useMouseOverAxis<IAggregationValue>({
    container: chartContainer,
    list: aggDataRef,
    labelRotate,
    layout,
    entityType,
    founder,
    getEntityId,
  })

  const { chartContainer: fullScreenChartContainer } = useChart(chartFullScreenOptions, { autoResize: true, registerEvent: registerFullScreenEvent })

  const { handleAxisMouseover: handleFullScreenAxisMouseover } = useMouseOverAxis<IAggregationValue>({
    container: fullScreenChartContainer,
    list: aggDataRef,
    labelRotate,
    layout,
    entityType,
    founder,
    getEntityId,
  })

  function getChartOptions() {
    const name = selectedPatentTypeRef.value === 'application' ? ins?.proxy.$tc('patentsApplication') || '' : ins?.proxy.$tc('patentsGranted') || ''
    return getHorizontalBarOptions({
      source: aggDataRef.value,
      dimensionKey: ['count', '__name'],
      color: ['#27B4D1'],
      xAxisName: name,
    })
  }

  function founder(item: IAggregationValue, name: string) {
    return item.display_name_cn === name || item.display_name_en === name
  }

  function getEntityId(item: IAggregationValue) {
    return item.key
  }

  function registerEvent(ins: EChartsType) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ins.on('click', onClickHorizontalBarChart)
    ins.on('mouseover', 'yAxis', handleAxisMouseover)
  }

  function registerFullScreenEvent(ins: EChartsType) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ins.on('click', onClickHorizontalBarChart)
    ins.on('mouseover', 'yAxis', handleFullScreenAxisMouseover)
  }

  watch(aggDataRef, () => {
    chartOptions.value = getChartOptions()
    chartFullScreenOptions.value = getChartOptions()
  })

  return {
    chartContainer,
    fullScreenChartContainer,
    chartContainerHeight,
  }
}
