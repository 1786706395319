import '@patsnap-ui/icon/assets/solid/ArrowUp.svg'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import $classes from './GIncreaseTip.module.scss'

export const GIncreaseTip = defineComponent({
  name: 'GIncreaseTip',
  props: {
    tip: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },
    useHtml: {
      type: Boolean,
      default: true,
    },
  },
  render() {
    return (
      <div class={[$classes.container, this.size === 'small' ? $classes.containerSmall : $classes.containerMedium]}>
        <GIcon class={$classes.icon} size={this.size === 'small' ? 16 : 20} svgName="ArrowUp"></GIcon>
        {this.useHtml ? <div class={$classes.tip} {...{ domProps: { innerHTML: this.tip } }}></div> : <div class={$classes.tip}>{this.tip}</div>}
      </div>
    )
  },
})
