import '@patsnap-ui/icon/assets/solid/PatentReverse.svg'
import { patentIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import {
  BCardContainer,
  BEntityLoadingCard,
  BImagePanel,
  BUpgradeWrap,
  GDescription,
  GLimitedContainer,
  GLink,
  GTranslate,
} from '@pharmsnap/shared/components'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ElCheckbox, ElDivider } from '@pharmsnap/shared/element-ui'
import {
  renderDrugPatentTypeInTable,
  renderLimitedPatentSimpleFamilyInCard,
  renderLimitedPatentSimpleFamilyPatentInCard,
  renderPatentAbstText,
  renderPatentApplicationData,
  renderPatentCurrentAssigneeInCard,
  renderPatentDrugsInCard,
  renderPatentedDrugsAsync,
  renderPatentEstimatedExpiryData,
  renderPatentIndicationInCard,
  renderPatentInformationSourceInCard,
  renderPatentLegalStatusAndLegalEvent,
  renderPatentOriginalAssigneeInCard,
  renderPatentPublicationData,
  renderPatentSpcPteTag,
  renderPatentTag,
  renderPatentTargetsInCard,
  renderPatentTechnology,
  renderPatentTitleText,
  renderPatentTranslationField,
} from '@pharmsnap/shared/render'
import { IPatentDetail, ITranslateLang } from '@pharmsnap/shared/types'
import { getFieldNameByLang } from '@pharmsnap/shared/utils/lang'
import { generatorLangFieldISTranslateFn } from '@pharmsnap/shared/utils/translate'
import { computed, defineComponent, getCurrentInstance, PropType, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import styles from '../BCard.module.scss'
import BCardCn from '../locales/cn.json'
import BCardEn from '../locales/en.json'
import $classes from './BCardPatent.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BCardPatent = defineComponent({
  name: 'BCardPatent',
  i18n: {
    messages: {
      cn: {
        ...cn,
        ...BCardCn,
      },
      en: {
        ...en,
        ...BCardEn,
      },
    },
  },
  props: {
    data: {
      type: Object as PropType<IPatentDetail>,
      required: true,
    },
    /** 右侧内容的footer */
    showRelated: {
      type: Boolean,
      default: true,
    },
    /** 卡片底部footer */
    showFooter: {
      type: Boolean,
      default: false,
    },
    popoverAppendToBody: {
      type: Boolean,
      default: false,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    /** 是否展示patent图片预览图 */
    showPreviewImage: {
      type: Boolean,
      default: true,
    },
    /** 点击图片是否可查看所有图 */
    showImagePanel: {
      type: Boolean,
      default: true,
    },
    /** 简单同族：limited的popover是否插入body */
    patentSimpleFamilyAppendToBody: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否隐藏没有数据的字段
     */
    hideNoResultField: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props, { root }) {
    const { forceTranslation } = toRefs(props)
    const {
      getters: { isFreeUser, customSettingTranslation },
      actions,
    } = useAuthStore()
    const isExecuteTranslateFn = computed(() => {
      if (props.disabledTranslate) {
        return () => false
      }
      return generatorLangFieldISTranslateFn(forceTranslation.value || customSettingTranslation.value)
    })
    const showCorePatentType = computed(() => {
      return !!props.data.patent_type_id_view?.length
    })
    const isIntelligentMode = computed(() => props.mode === 'intelligent')
    const showCoreDrug = computed(() => !!props.data.core_drug_id_view?.length || !!props.data.core_recommend_drug_id_view?.length)

    const showCorePatentTechnology = computed(() => {
      return !!props.data.patent_technology_id_view?.length || !!props.data.patent_technology_id?.length
    })

    const ins = getCurrentInstance()

    const { locale, localeUpcase } = useLocale()
    const detailUrl = computed(() => {
      return sharedCtx.router.generatorPatentDetailPath(props.data.patent_id)
    })

    function blockFreeUser(e: Event) {
      if (isFreeUser.value) {
        e.preventDefault()
        actions.changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'PATENT_CARD_VIEW_ALL' })
      }
    }
    /**
     * 是否展示字段的代理
     * @param show
     * @returns
     */
    function showFiledProxy(show: boolean) {
      if (!props.hideNoResultField) {
        return true
      }
      return show
    }

    const showPatentSource = computed(() => !!props.data.patent_type_source_view?.length)

    return {
      locale,
      localeUpcase,
      detailUrl,
      isExecuteTranslateFn,
      blockFreeUser,
      showFiledProxy,
      showCoreDrug,
      showCorePatentTechnology,
      showCorePatentType,
      showPatentSource,
      isIntelligentMode,
    }
  },
  methods: {
    renderHeader() {
      const estimatedExpiryDate = this.data.exdt ? (
        <div class="text-text-t2 flex flex-row flex-nowrap items-center">
          <span class="text-xs mr-2 pb-0.5">·</span>
          <span>
            {this.$t('BCardPatent.estimatedExpiryDate')}: {renderPatentEstimatedExpiryData(this.data, this.$i18n)}
          </span>
        </div>
      ) : null
      return (
        <div class={['flex items-center text-sm', this.isIntelligentMode ? '' : 'px-4 pt-3']} slot="header">
          <BUpgradeWrap class="flex items-center" updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} triggerPoint="PATENT_PN">
            {/* {this.isIntelligentMode ? <GIcon svgName="SolidPatentReverse" useSvgDefaultColor={true} size={24}></GIcon> : null} */}
            {/* {this.isIntelligentMode
              ? [
                  <span class="flex-shrink-0 leading-6 text-[#1D8820]">{this.locale === 'cn' ? '专利' : 'Patent'}</span>,
                  <span class="flex-shrink-0 leading-6 mx-1 text-text-t2">/</span>,
                ]
              : null} */}
            <GLink
              href={this.detailUrl}
              encryptClass={this.data.fontClassName}
              name={this.data.pn}
              newTab={true}
              class="text-sm leading-6 mr-2"
              style="color: #1D8820"
            />
          </BUpgradeWrap>
          {estimatedExpiryDate}
        </div>
      )
    },
    renderDate() {
      return (
        <div class="flex mt-3 text-sm">
          <div class={['text-text-t2', this.isIntelligentMode ? 'mr-10' : 'mr-28']}>
            {`${getFieldNameByLang(patentIndexFieldLangMap, 'APD_YEARMONTHDAY', this.locale)}:`}
            <span class="text-text-t1 text-sm ml-2">{renderPatentApplicationData(this.data, this.$i18n)}</span>
          </div>
          <div class="text-text-t2">
            {`${getFieldNameByLang(patentIndexFieldLangMap, 'PBDT_YEARMONTHDAY', this.locale)}:`}
            <span class="text-text-t1 text-sm ml-2">{renderPatentPublicationData(this.data, this.$i18n)}</span>
          </div>
        </div>
      )
    },
    renderBody() {
      const abstTranslation = renderPatentTranslationField(this.data, 'abst_translation')
      const patentTag = renderPatentTag(this.data)
      const patentLegalTag = renderPatentLegalStatusAndLegalEvent(this.data, true, '')
      const patentSpcPteTag = renderPatentSpcPteTag(this.data)
      return (
        <div class="mt-2">
          <GTranslate execute={this.isExecuteTranslateFn(this.data.title)} content={renderPatentTranslationField(this.data, 'title_translation')}>
            <BUpgradeWrap updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL} triggerPoint="PATENT_TITLE">
              <GLink
                href={this.detailUrl}
                newTab={true}
                class={[styles.title, 'whitespace-pre-wrap text-lg leading-6 hover:text-blue-default font-semibold']}
              >
                {renderPatentTitleText(this.data, this.$i18n)}
              </GLink>
            </BUpgradeWrap>
          </GTranslate>
          {patentTag || patentLegalTag || patentSpcPteTag ? (
            <div class="mb-2 flex flex-wrap gap-1">
              {patentTag}
              {patentLegalTag}
              {patentSpcPteTag}
            </div>
          ) : null}
          {this.isIntelligentMode ? null : (
            <GTranslate execute={this.isExecuteTranslateFn(this.data.abst)}>
              {abstTranslation && (
                <GLimitedContainer
                  slot="content"
                  newTab={true}
                  href={this.detailUrl}
                  moreText={this.$tc('common.showAll')}
                  moreFunc={this.blockFreeUser}
                  moreColor="#f0f1f3"
                >
                  <div class="text-sm leading-5  break-words" domPropsInnerHTML={abstTranslation}></div>
                </GLimitedContainer>
              )}

              <GLimitedContainer class="mr-2" newTab href={this.detailUrl} moreText={this.$tc('common.showAll')} moreFunc={this.blockFreeUser}>
                <div
                  class="whitespace-pre-wrap text-sm leading-5 text-text-t2 break-words"
                  domPropsInnerHTML={renderPatentAbstText(this.data, this.$i18n)}
                ></div>
              </GLimitedContainer>
            </GTranslate>
          )}

          {this.renderDate()}

          <GDescription
            class="mt-2"
            width={this.isIntelligentMode ? 160 : 200}
            data={this.data}
            items={[
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_ORG_ID_ONEID', this.locale)}:`,
                field: 'PHS_ORG_ID_ONEID',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_ANS_ORG_ID_ONEID', this.locale)}:`,
                field: 'ORIGINAL_ASSIGNEE',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => !this.isIntelligentMode,
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'SIMPLE_FAMILY_JURISDICTION', this.locale)}:`,
                field: 'SIMPLE_FAMILY_JURISDICTION',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => !this.isIntelligentMode,
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'SIMPLE_FAMILY_PATENT', this.locale)}:`,
                field: 'SIMPLE_FAMILY_PATENT',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => !this.isIntelligentMode && !!this.data.simple_family_patent?.length,
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PATENTED_DRUG', this.locale)}:`,
                field: 'PATENTED_DRUG',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => this.showFiledProxy(this.showCoreDrug),
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PATENT_TECHNOLOGY', this.locale)}:`,
                field: 'PATENT_TECHNOLOGY',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => this.showFiledProxy(this.showCorePatentTechnology),
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_CORE_TYPE_ID', this.locale)}:`,
                field: 'PHS_CORE_TYPE_ID',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => !this.isIntelligentMode && this.showFiledProxy(this.showCorePatentType),
              },
              {
                label: `${getFieldNameByLang(patentIndexFieldLangMap, 'PHS_CORE_DRUG_SOURCE', this.locale)}:`,
                field: 'PHS_CORE_DRUG_SOURCE',
                labelCls: 'text-sm',
                contentCls: styles.contentItem,
                isShowFn: () => !this.isIntelligentMode && this.showFiledProxy(this.showPatentSource),
              },
            ]}
            scopedSlots={{
              PHS_ORG_ID_ONEID: () => renderPatentCurrentAssigneeInCard(this.data, this.$i18n),
              ORIGINAL_ASSIGNEE: () => renderPatentOriginalAssigneeInCard(this.data, this.$i18n),
              SIMPLE_FAMILY_JURISDICTION: () =>
                renderLimitedPatentSimpleFamilyInCard({ data: this.data, appendToBody: this.patentSimpleFamilyAppendToBody }),
              SIMPLE_FAMILY_PATENT: () => renderLimitedPatentSimpleFamilyPatentInCard(this.data, this.$i18n),
              PATENTED_DRUG: () => renderPatentedDrugsAsync(this.data),
              PATENT_TECHNOLOGY: () => renderPatentTechnology(this.data),
              PHS_CORE_TYPE_ID: () => renderDrugPatentTypeInTable(this.data),
              PHS_CORE_DRUG_SOURCE: () => renderPatentInformationSourceInCard(this.data.patent_type_source_view || []),
            }}
          ></GDescription>
        </div>
      )
    },
    renderFooter() {
      if (!this.showRelated) {
        return null
      }
      return (
        <div>
          <ElDivider />
          <div class="-mb-2">
            <div class="text-text-t2 mb-3 text-sm">{this.$tc('BCardPatent.relatedKeywords')}</div>
            <GDescription
              width={75}
              data={this.data}
              items={[
                {
                  label: `${this.$tc('common.drug')}:`,
                  field: 'drugs',
                  labelCls: 'text-sm',
                  contentCls: styles.contentItem,
                },
                {
                  label: `${this.$tc('common.target')}:`,
                  field: 'targets',
                  tipCls: 'w-80',
                  tipPlacement: 'right',
                  labelCls: 'text-sm',
                  contentCls: styles.contentItem,
                },
                {
                  label: `${this.$tc('common.disease')}:`,
                  field: 'disease',
                  labelCls: 'text-sm',
                  contentCls: styles.contentItem,
                },
              ]}
              scopedSlots={{
                drugs: () => renderPatentDrugsInCard(this.data),
                targets: () => renderPatentTargetsInCard(this.data),
                disease: () => renderPatentIndicationInCard(this.data),
              }}
            ></GDescription>
          </div>
        </div>
      )
    },
    renderLeft() {
      return (
        <div class={this.isIntelligentMode ? 'pl-4' : 'p-4'}>
          <BImagePanel
            thumbnailUrl={this.data.patent_image120?.fulltext_image_url || ''}
            previewUrl={this.data.patent_image?.fulltext_image_url || ''}
            patentId={this.data.patent_id}
            showPreview={this.showPreviewImage}
            showImagePanel={this.showImagePanel}
            appendToBody={this.popoverAppendToBody}
            placement="left-start"
          ></BImagePanel>
        </div>
      )
    },
    renderRight() {
      return (
        <BCardContainer class={[styles.container, this.isIntelligentMode ? 'pr-4' : '']} border={false} emptyMode={this.isIntelligentMode}>
          {this.renderHeader()}
          {this.renderBody()}
        </BCardContainer>
      )
    },
    renderCardFooter() {
      if (!this.showFooter) {
        return null
      }

      if (this.showFooter) {
        return (
          <div class={$classes.cardFooter}>
            <ElDivider></ElDivider>
            {this.$slots.customFooter ? this.$slots.customFooter : null}
          </div>
        )
      }
    },
    renderCheckbox() {
      return (
        <ElCheckbox
          class={$classes.checkbox}
          value={this.isChecked}
          onChange={() => this.$emit('checkboxChange', { checked: !this.isChecked, row: this.data })}
        ></ElCheckbox>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc loading={isEmpty(this.data)}>
        <div class={[this.isIntelligentMode ? $classes.containerPure : $classes.container]}>
          <div class={$classes.content}>
            {this.showCheckbox && this.renderCheckbox()}
            {this.renderRight()}
            {this.renderLeft()}
          </div>
          {this.renderCardFooter()}
        </div>
      </BEntityLoadingCard>
    )
  },
})
