import { ElInput } from '@pharmsnap/shared/element-ui'
import { computed, defineComponent, ref } from '@vue/composition-api'
import $classes from './BSemanticSearchBox.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BSemanticSearchBox = defineComponent({
  name: 'BSemanticSearchBox',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    limit: {
      type: Number,
      default: 200,
    },
  },
  setup(props, { emit }) {
    const text = ref('')

    function onClear() {
      text.value = ''
    }

    async function onSearch() {
      if (isSearchDisabled.value) return
      const queryItems = [
        {
          type: 'field',
          fields: ['SEMANTIC'],
          value: [
            {
              type: 'text',
              display_name_cn: text.value,
              display_name_en: text.value,
              value: text.value,
            },
          ],
        },
      ]
      emit('search', queryItems)
    }

    const isOverLimit = computed(() => text.value.length > props.limit)

    const isSearchDisabled = computed(() => !text.value || isOverLimit.value)

    return { text, onClear, onSearch, isOverLimit, isSearchDisabled }
  },
  render() {
    return (
      <div>
        <div class="text-sm text-text-t2">{this.$t('BSemanticSearchBox.label', { count: this.limit })}</div>
        <div class="mt-4 mb-8 border border-gray-60 rounded bg-white-default">
          <ElInput
            class={$classes.textarea}
            type="textarea"
            autosize={{ minRows: 6, maxRows: 10 }}
            placeholder={this.$t('BSemanticSearchBox.placeholder')}
            v-model={this.text}
          ></ElInput>
          <div class="text-xs text-text-t2 flex justify-end pr-2 py-2">
            {this.isOverLimit && <span class="text-red-default mr-0.5">{this.$t('BSemanticSearchBox.overLimit')}</span>}
            <span class={this.isOverLimit ? 'text-red-default' : ''}>{this.text.length}</span>
            <span>/{this.limit}</span>
          </div>
        </div>

        <div class="flex justify-end">
          <div class={$classes.cancel} onClick={this.onClear}>
            {this.$tc('common.clear')}
          </div>
          <div class={this.isSearchDisabled ? $classes.searchDisable : $classes.search} onClick={this.onSearch}>
            {this.$tc('common.search')}
          </div>
        </div>
      </div>
    )
  },
})
