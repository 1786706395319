import { getLocaleLang } from './locale'

const isCnLang = getLocaleLang() === 'cn'
const globalTitle = isCnLang ? '全球新药竞争情报库' : 'Global Drug Intelligence Database'

export function createWebTitle(cnStr?: string, enStr?: string): string | undefined {
  return isCnLang ? cnStr : enStr
}
export function updateDocumentTitle(title: string = globalTitle) {
  document.title = `${title} - Synapse`
}
