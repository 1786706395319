import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { ElDescriptions, ElDescriptionsItem } from '@pharmsnap/shared/element-ui'
import $utilClasses from '@pharmsnap/shared/style/util.module.scss'
import { IGDescriptionItemProps } from '@pharmsnap/shared/types/index'
import { PropType, defineComponent } from '@vue/composition-api'
import { NormalizedScopedSlot } from 'vue/types/vnode'
import { GTooltip } from '../..'
import $classes from './GDescription.module.scss'

export const GDescription = defineComponent({
  name: 'GDescription',
  props: {
    width: {
      type: Number,
      default: 135,
    },
    data: {
      type: Object,
      required: true,
    },
    items: {
      type: Array as PropType<IGDescriptionItemProps[]>,
      required: true,
      default: () => [],
    },
    gapSize: {
      type: String as PropType<'mini' | 'base' | 'large'>,
      default: 'base',
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium',
    },
    border: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Number,
      default: 1,
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    /** 文本是否指定宽度 */
    labelWidthIsSpecified: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    renderLabel(item: IGDescriptionItemProps) {
      const { label, tip } = item
      if (label && tip) {
        return (
          <div class={[$utilClasses.lsFlex, $utilClasses.lsFlexItemsCenter]}>
            <span class={[$utilClasses.lsFlexShrink]}>{label}</span>
            <GTooltip theme="light" mode="icon" placement={item.tipPlacement}>
              <template slot="content">
                <div domPropsInnerHTML={tip} class={item.tipCls}></div>
              </template>
            </GTooltip>
          </div>
        )
      }

      return label
    },
  },
  render() {
    return (
      <ElDescriptions
        class={[
          $classes.container,
          this.gapSize === 'base' ? $classes.containerBase : this.gapSize === 'mini' ? $classes.containerMini : $classes.containerLarge,
        ]}
        labelClassName={$classes.label}
        contentClassName={$classes.content}
        labelStyle={{ width: `${this.width}px` }}
        column={this.column}
        border={this.border}
        direction={this.direction}
        size={this.size}
        colon={false}
      >
        {this.items
          .filter((item) => {
            if (item.isShowFn) {
              return item.isShowFn()
            }
            return true
          })
          .map((item) => (
            <ElDescriptionsItem
              span={item.span || 1}
              label={this.renderLabel(item)}
              labelClassName={[$classes.label, item.labelCls].join(' ')}
              labelStyle={{ width: this.labelWidthIsSpecified ? `${this.width}px` : 'auto' }}
              contentClassName={[$classes.content, item.contentCls].join(' ')}
            >
              <template slot="default">
                {item.field
                  ? this.$scopedSlots[item.field] && (this.$scopedSlots[item.field] as NormalizedScopedSlot)({ data: this.data })
                  : item.content || EMPTY_PLACEHOLDER}
              </template>
            </ElDescriptionsItem>
          ))}
      </ElDescriptions>
    )
  },
})
