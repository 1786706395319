import { onUnmounted, ref } from '@vue/composition-api'
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils'

/**
 * 计算倒计时
 * @param timeKey 本地localStorage存的key
 * @param total 总的倒计时数
 * @param step 倒计时步长
 * @returns
 */
export function useCount(timeKey: string, total: number, step = 1000) {
  const inCounting = ref(false)
  const leftSeconds = ref(0)
  let timerID: NodeJS.Timer

  function startTimer() {
    const localStartTime = getLocalStorage<string>(timeKey, false)
    const startTime = Number(localStartTime) || Date.now()
    leftSeconds.value = total - Math.floor((Date.now() - startTime) / 1000)
    inCounting.value = true
    if (leftSeconds.value <= 0 || leftSeconds.value > total) {
      removeLocalStorage(timeKey)
      stopTimer()
    } else {
      timerID = setTimeout(startTimer, step)
    }
  }
  function stopTimer() {
    inCounting.value = false
    timerID && clearTimeout(timerID)
  }

  function startCount() {
    const startTime = String(Date.now())
    setLocalStorage(timeKey, startTime)
    startTimer()
  }

  function init() {
    const localCodeInterval = getLocalStorage<string>(timeKey, false)
    if (localCodeInterval) {
      startTimer()
    }
  }
  init()

  onUnmounted(stopTimer)

  return { inCounting, leftSeconds, startCount }
}
