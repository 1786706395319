import { IFilterItemAdvanceUiType } from '..'
import { IFilterItemLimitContainerConfig } from '../type'

export const DEFAULT_LIMIT_COUNT = 6
export const DEFAULT_NO_LIMIT_COUNT = -1
export const DEFAULT_SUPPORT_COLLAPSE = true

export const DEFAULT_LIMIT_CONTAINER_CONFIG: IFilterItemLimitContainerConfig = {
  limitCount: DEFAULT_LIMIT_COUNT,
  supportCollapse: DEFAULT_SUPPORT_COLLAPSE,
}

export const ADVANCE_UI_TYPE_LANG_RECORD: Record<IFilterItemAdvanceUiType, Record<string, string>> = {
  'advance-full-list-order-count': {
    cn: '数量',
    en: 'Count',
    jp: '数量',
    tw: '數量',
  },
  'advance-full-list-order-name': {
    cn: 'A-Z',
    en: 'A-Z',
    jp: 'A-Z',
    tw: 'A-Z',
  },
  'advance-full-tree': {
    cn: '层级',
    en: 'Hierarchy',
    jp: '階層',
    tw: '層級',
  },
}
