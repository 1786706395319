import { VUE_APP_DEPLOY_REGION } from '@pharmsnap/shared/src/config'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import type { AxiosError, AxiosInstance } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { getLocaleLang } from '../utils/business/locale'
import { CaptchaVerification } from '../utils/CaptchaVerification'
import { ERROR_CODE_MAP } from './error-code-config'
export function interceptAxiosWithChatRecaptcha(params: { axios: AxiosInstance; refreshAxios: AxiosInstance }) {
  function isCnLang() {
    return getLocaleLang() === 'cn'
  }
  function tryRemoveCaptcha() {
    document.querySelectorAll('.geetest_captcha')?.forEach((el) => {
      el.remove()
    })
  }
  function showRemainTime(remaining: number) {
    const minuteCopy = isCnLang() ? '分' : ' minute'
    const secondCopy = isCnLang() ? '秒' : ' second'
    const remainingCopy = Number.parseInt(String(remaining / 60)) + minuteCopy + (remaining % 60) + secondCopy
    tryRemoveCaptcha()
    showSingleToast({
      type: 'error',
      message: isCnLang() ? `验证失败，${remainingCopy}后重新尝试` : `Verification failed, please try again after ${remainingCopy}`,
    })
  }
  function showBlockMessage() {
    tryRemoveCaptcha()
    const errorMsg = isCnLang()
      ? '芽仔-生物医药功能已禁用，请联系管理员处理 。'
      : 'Hiro-LS has been disabled. Please contact the administrator for assistance.'
    showSingleToast({
      type: 'error',
      message: errorMsg,
    })
  }
  const captchaVerification = new CaptchaVerification({
    region: VUE_APP_DEPLOY_REGION,
    refreshAxios: params.refreshAxios,
    showBlockMessage,
    showRemainTime,
    getLang: getLocaleLang,
  })

  function refreshLogic(e: AxiosError) {
    return new Promise<void>((resolve, reject) => {
      captchaVerification.showCaptchaVerification({
        onSuccess: () => {
          resolve()
        },
        onFail: () => {
          reject(e)
        },
        onClose: () => {
          reject(e)
        },
      })
    })
  }
  createAuthRefreshInterceptor(params.axios, refreshLogic, {
    // pauseInstanceWhileRefreshing: true,
    shouldRefresh: (error) => {
      const numeric_error_code = (error?.response?.data as any)?.numeric_error_code as number
      // 需要做题。chat接口触发规则后所有接口都会被拦截。
      if (numeric_error_code === 160014) {
        return true
      }
      const resp = error.response
      const remaining = (resp?.data as any)?.remaining as number
      // 做题失败，锁定中，还没有到三次且等待时间为0, 也需要做题
      if (numeric_error_code === ERROR_CODE_MAP.CHAT_GEETEST_WAIT_160007 && remaining === 0) {
        return true
      }
      return false
    },
  })
  params.axios.interceptors.response.use(undefined, (e) => {
    const resp = e.response
    const numeric_error_code = resp?.data?.numeric_error_code as number
    // 网关拦截所有接口，做题失败三次以上，触发了永久禁用
    if (numeric_error_code === ERROR_CODE_MAP.CHAT_GEETEST_BLOCK_160004) {
      showBlockMessage()
    } else if (numeric_error_code === ERROR_CODE_MAP.CHAT_GEETEST_WAIT_160007) {
      // 网关拦截所有接口，做题失败，锁定中，还没有到三次
      const remaining = resp?.data?.remaining as number
      if (remaining > 0) {
        showRemainTime(remaining)
      }
    }
    return Promise.reject(e)
  })
}
