/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type Excel from 'exceljs'
import { downloadBlob } from './common'

interface ICellStyle {
  numFmt?: string
  font?: Partial<Excel.Font>
  alignment?: Partial<Excel.Alignment>
  protection?: Partial<Excel.Protection>
  border?: Partial<Excel.Borders>
  fill?: Excel.Fill
}

/**
 * 单元格数据
 */
export interface ISheetCell {
  displayName: string // 显示文本
  type: 'text' | 'link' // 目前就用到了文本与超链接
  link?: string // 超链接地址
}

/**
 * 列配置 表头
 */
export interface IColumn {
  header: string
  key: string
  width?: number
  style?: ICellStyle
}

export interface ISheetConfig {
  data: ISheetCell[][]
  /**
   * sheet name
   */
  sheetName: string
  /**
   * 列数据
   */
  columns?: IColumn[]
  /**
   * 合并单元格的数据
   * [开始row，开始col，结束row，结束col][]
   */
  merges?: [number, number, number, number][]
}

interface exportExcelConfig {
  fileName: string
  sheets: ISheetConfig[]
}

interface IXlsxUtil {
  workBook: Excel.Workbook | null
  createSheets(params: exportExcelConfig): Promise<void>
}

export class XlsxUtil implements IXlsxUtil {
  private readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  private static _ins: XlsxUtil | null = null
  private _workBook: Excel.Workbook | null = null

  /**
   * 获取XLSXUtil单例
   * @returns
   */
  public static getInstance() {
    if (!XlsxUtil._ins) {
      XlsxUtil._ins = new XlsxUtil()
    }
    return XlsxUtil._ins
  }

  get workBook() {
    return this._workBook
  }

  /**
   * 构造sheets数据
   * @param params
   */
  public async createSheets(params: exportExcelConfig) {
    await this._initWorkBook()

    const { sheets } = params
    sheets.forEach((sheet) => {
      this._createSheet(sheet)
    })

    await this._writeAndDownload(params.fileName)
  }

  /**
   * 创建单张sheet
   * @param sheet
   */
  private _createSheet(sheet: ISheetConfig) {
    const { data: sheetData, columns, merges, sheetName } = sheet
    const workSheet = this._workBook!.addWorksheet(sheetName || 'data', {
      pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
    })
    workSheet.columns = columns || []

    sheetData &&
      sheetData.forEach((d) => {
        const value = d.map((o) => {
          return this._handleCellVal(o)
        })
        workSheet.addRow(value)
      })

    if (merges && merges.length) {
      this._handleMergeCells(workSheet, merges)
    }

    workSheet.getRow(1).font = { color: { argb: 'FF000000' } }
  }

  /**
   * 初始化工作簿
   */
  private async _initWorkBook() {
    if (!this._workBook) {
      const Excel = await import('exceljs')
      this._workBook = new Excel.Workbook()
      this._clearWorkBook()
      this._workBook.created = new Date()
      this._workBook.modified = new Date()
      // 将工作簿日期设置为 1904 年日期系统
      this._workBook.properties.date1904 = true
      // 在加载时强制工作簿计算属性
      this._workBook.calcProperties.fullCalcOnLoad = true

      this._workBook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 1,
          visibility: 'visible',
        },
      ]
    }
  }

  /**
   * 初始化清空sheets
   */
  private _clearWorkBook() {
    if (this._workBook) {
      this._workBook.eachSheet((sheet) => {
        this._workBook!.removeWorksheet(sheet.id)
      })
    }
  }

  /**
   * 写入buffer并生成文件
   * @param fileName
   */
  private async _writeAndDownload(fileName: string) {
    const data = await this._workBook!.xlsx.writeBuffer()
    const blob = new Blob([data], { type: this.EXCEL_TYPE })
    downloadBlob(blob, `${fileName}.XLSX`)
  }

  /**
   * 处理单元格数据转换
   * @param cell
   * @returns
   */
  private _handleCellVal(cell: ISheetCell) {
    if (cell.type === 'link') {
      return cell.link
        ? {
            text: cell.displayName,
            hyperlink: cell.link,
          }
        : cell.displayName
    }
    if (cell.type === 'text') {
      return cell.displayName
    }
    console.error('暂不支持的单元格类型')
    return ''
  }

  /**
   * 处理单元格合并
   * @param sheet
   * @param merges
   */
  private _handleMergeCells(sheet: Excel.Worksheet, merges: [number, number, number, number][]) {
    merges.forEach((merge) => {
      sheet.mergeCells(merge)
    })
  }
}
