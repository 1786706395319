import { Toast } from 'patsnap-biz'
import { IToastParam } from '../types'

/**
 * 清空全部toast，显示当前toast
 */
export const showSingleToast = (params: IToastParam) => {
  Toast.closeAll()
  return Toast({
    ...params,
  })
}
export function closeAllToast() {
  Toast.closeAll()
}
