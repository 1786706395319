import { openPageWithoutPending } from '@patsnap/synapse_common_utils'
import { sharedCtx } from '@pharmsnap/shared/context'
import { ICompareType } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { GTooltip } from '../..'
import '../../../assets/icon-svg/VS.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import $classes from './BCompare.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BCompareBtn = defineComponent({
  name: 'BCompareBtn',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    compareType: {
      type: String as PropType<ICompareType>,
      required: true,
    },
    id: {
      type: String,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const config: Record<ICompareType, (ids?: string) => string> = {
      Drug: sharedCtx.router.generatorCompareDrugPath,
      Organization: sharedCtx.router.generatorCompareOrganizationPath,
      Target: sharedCtx.router.generatorCompareTargetPath,
      Disease: sharedCtx.router.generatorCompareIndicationPath,
    }

    function handleCompare() {
      openPageWithoutPending(() => config[props.compareType](props.id))
    }

    return { handleCompare }
  },
  render() {
    return (
      <GTooltip placement="bottom">
        <div class={[$classes.compareBtn, this.hasBorder ? 'border border-text-t4 rounded' : '']} onClick={this.handleCompare}>
          <GIcon svgName="Vs" size={24} class={this.hasBorder ? 'text-blue-default' : 'text-gray-450'}></GIcon>
          {this.showTitle ? <div class="leading-6">{this.$t('BCompareBtn.startCompare')}</div> : null}
        </div>
        <div slot="content">{this.$t('BCompareBtn.startCompare')}</div>
      </GTooltip>
    )
  },
})
