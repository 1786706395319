import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.DRUG_FINDER,
}

export default [
  {
    path: `drug-finder`,
    name: E_ROUTER_NAME.DRUG_FINDER,
    component: () => import('@pharmsnap/pharmsnap-web/views/drugfinder/DrugFinder'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索', 'Drug Finder'),
      hideQuickSearch: true,
    },
  },
  {
    path: `drug-list`,
    name: E_ROUTER_NAME.DRUG_LIST,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugList'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/dev-organization`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_DEV_ORG,
    redirect: {
      name: E_ROUTER_NAME.DRUG_GROUP_BY_ORG,
    },
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/ori-dev-organization`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_ORI_DEV_ORG,
    redirect: {
      name: E_ROUTER_NAME.DRUG_GROUP_BY_ORG,
    },
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/organization`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_ORG,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugListGroupByOrg'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/dev-status`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_DEV_STATUS,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugListGroupDevStatus'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/target`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_TARGET,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugListGroupByTarget'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug-group/disease`,
    name: E_ROUTER_NAME.DRUG_GROUP_BY_DISEASE,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugListGroupByDisease'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: 'drug-group/drug-type',
    name: E_ROUTER_NAME.DRUG_GROUP_BY_DRUG_TYPE,
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugListGroupByDrugType'),
    meta: {
      ...meta,
      title: createWebTitle('药物检索结果', 'Drug Search Results'),
      notWide: true,
    },
  },
  {
    path: `drug/:${E_ROUTER_PARAMS.DRUG_ID}`,
    name: E_ROUTER_NAME.DRUG,
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.DRUG_ID],
    }),
    component: () => import('@pharmsnap/pharmsnap-web/views/drug/DrugDetail'),
    meta: {
      ...meta,
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.DRUG_DETAIL,
    },
    children: [
      {
        path: 'overview',
        name: E_ROUTER_NAME.DRUG_OVERVIEW,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DRUG,
          }
        },
      },
      {
        path: 'research',
        name: E_ROUTER_NAME.DRUG_RESEARCH,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DRUG,
          }
        },
      },
      {
        path: 'trial',
        name: E_ROUTER_NAME.DRUG_TRIAL,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DRUG,
          }
        },
      },
      {
        path: 'approval',
        name: E_ROUTER_NAME.DRUG_APPROVAL,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DRUG,
          }
        },
      },
    ],
  },
] as RouteConfig[]
