import '@patsnap-ui/icon/assets/solid/ArrowTailUp.svg'
import { toThousands } from '@patsnap/synapse_common_utils'
import { IBOverviewPanelItem } from '@pharmsnap/shared/types'
import { encryptNum } from '@pharmsnap/shared/utils/encrypt/encrypt'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import '../../../assets/icon-svg/arrowUp.svg'
import '../../../assets/icon-svg/clinical.svg'
import '../../../assets/icon-svg/clinicalResult.svg'
import '../../../assets/icon-svg/company.svg'
import '../../../assets/icon-svg/disease.svg'
import '../../../assets/icon-svg/drug.svg'
import '../../../assets/icon-svg/drugDeal.svg'
import '../../../assets/icon-svg/news.svg'
import '../../../assets/icon-svg/paper.svg'
import '../../../assets/icon-svg/patent.svg'
import '../../../assets/icon-svg/target.svg'
import '../../../assets/icon-svg/translationalMedicine.svg'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import $classes from './BOverviewPanel.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
const iconMap: Record<IBOverviewPanelItem['field'], string> = {
  patent: 'Patent',
  core_patent: 'Patent',
  target: 'Target',
  paper: 'Paper',
  organization: 'Company',
  news: 'News',
  drug: 'Drug',
  clinical_trial: 'Clinical',
  clinical_trial_result: 'ClinicalResult',
  disease: 'Disease',
  drug_deal: 'DrugDeal',
  translational_medicine: 'TranslationalMedicine',
}

export const BOverviewPanel = defineComponent({
  name: 'BOverviewPanel',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    items: {
      type: Array as PropType<IBOverviewPanelItem[]>,
      required: true,
    },
    itemWidth: {
      type: String,
      default: '',
    },
    customItemCls: {
      type: String,
      default: '',
    },
    showPatentTips: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const itemStyle = computed(() => (props.itemWidth ? { width: props.itemWidth } : {}))

    return { itemStyle }
  },
  methods: {
    renderTotalCount(item: IBOverviewPanelItem) {
      const content = (
        <span
          onClick={() => {
            this.$emit('totalClick', item)
          }}
          class={[
            item.is_link && item.count !== 0
              ? item.not_allowed_tip
                ? 'cursor-not-allowed'
                : `${[$classes.itemLinkCount]} panel-link-count cursor-pointer hover:underline`
              : 'cursor-text',
          ]}
        >
          <div class="icon-font" domPropsInnerHTML={encryptNum(toThousands(item.count))} />
        </span>
      )

      if (item.not_allowed_tip)
        return (
          <GTooltip content={item.not_allowed_tip} mode="text" theme="light">
            {content}
          </GTooltip>
        )

      return content
    },
    renderItem(item: IBOverviewPanelItem) {
      return (
        <div key={item.field} class={[$classes.item, this.customItemCls]} style={this.itemStyle}>
          <div class={$classes.itemHeader}>
            <GIcon useSvgDefaultColor={true} svgName={iconMap[item.field]} size={20} border={false}></GIcon>
            <div class={$classes.itemLabel}>{this.$tc(`common.${item.field}`)}</div>
          </div>
          <div class={$classes.itemBody}>
            <div class={$classes.itemCount}>{this.renderTotalCount(item)}</div>
            <div
              class={[$classes.itemIncrease, item.is_link && item.increase ? 'cursor-pointer' : 'cursor-text']}
              onClick={() => this.$emit('increaseClick', item)}
            >
              {item.increase > 0 && (
                <div class="relative bottom-2 flex">
                  <GIcon class="text-green-default" size={24} svgName="ArrowUp"></GIcon>
                  <span class={[item.is_link && $classes.link, item.is_link ? 'panel-link' : '']}>{toThousands(item.increase)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <div class={$classes.overview}>{this.items.map((item) => this.renderItem(item))}</div>
        {this.showPatentTips && <div class="text-xs text-text-t2">{this.$tc('BOverviewPanel.tips')}</div>}
      </div>
    )
  },
})
