import { I18nLang } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { ElCheckbox } from '@pharmsnap/shared/element-ui'
import { defineComponent, PropType } from '@vue/composition-api'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
import { IFilterAggValueNext } from '../type'

export const BFilterCheckbox = defineComponent({
  name: 'BFilterCheckbox',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    value: {
      type: Object as PropType<IFilterAggValueNext>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
    showPercent: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String as PropType<I18nLang>,
    },
  },
  render() {
    return (
      <div class={['flex items-start cursor-pointer', { 'pb-1': this.showPercent }]}>
        <ElCheckbox
          onChange={(val: boolean) => this.$emit('change', val)}
          disabled={this.disabled}
          value={this.selected}
          class="flex-shrink-0 w-[14px] h-[14px] mt-1"
          style="font-size:0;"
        ></ElCheckbox>
        <div
          class={[
            'flex-1 ml-2 flex items-start p-1 hover:bg-gray-30 relative',
            { 'cursor-pointer': !this.disabled, 'cursor-not-allowed': this.disabled },
          ]}
          onClick={() => !this.disabled && this.$emit('change', !this.selected)}
        >
          <div class="flex-1">
            {(this.$scopedSlots.default && this.$scopedSlots.default({ item: this.value.raw })) || (
              <div class="text-text-t1 text-xs leading-4">
                {this.locale
                  ? this.locale === 'cn'
                    ? this.value.display_name_cn || this.value.display_name_en
                    : this.value.display_name_en
                  : this.value.display_name_en || this.value.display_name_cn}
              </div>
            )}
          </div>
          <div class="text-xs ml-4 leading-4 icon-font text-text-t2 flex-shrink-0 max-w-[80px] truncate">{toThousands(this.value.count)}</div>
          {this.showPercent ? <div class="absolute left-0 bottom-0 bg-blue-disable rounded h-1 min-w-[2px]" style="width: 50%;"></div> : null}
        </div>
      </div>
    )
  },
})
