import '@analytics/common-components/packages/theme-chalk/src/sequence-viewer.scss'
import '@patsnap-ui/icon/assets/solid/CoreProductsBio.svg'
import '@patsnap-ui/icon/assets/solid/CoreProductsChemical.svg'
import { E_TIME_TRUST_CODE, IBaseCountry, IBaseDiseaseInfo, IBaseDrugAdc, IBaseOrgInfo, IBaseTargetInfo } from '@patsnap/synapse_common_interface'
import { handleSpace } from '@pharmsnap/shared/utils/encrypt/encryptSpace'
import { cloneDeep, groupBy, isUndefined, some, uniqBy } from 'lodash'
import { IVueI18n } from 'vue-i18n'
import {
  BApprovalCountryItem,
  BDiseaseItem,
  BDrugAdcFieldDesc,
  BDrugExistDealField,
  BDrugItem,
  BDrugTypeTagItem,
  BMechanismItem,
  BMosaic,
  BOrganizationItem,
  BRegulation,
  BSequenceItem,
  BSignaturePdf,
  BTargetItem,
  GCountryFlag,
  GLink,
  GTimeline,
  GTimelineItem,
  GTooltip,
} from '../components'
import { BDiseaseResearchStatus } from '../components/business/BDiseaseResearchStatus/BDiseaseResearchStatus'
import { BRegulationTable } from '../components/business/BRegulationTable/BRegulationTable'
import { GExpandDialog } from '../components/ui/GExpandDialog/GExpandDialog'
import { EMPTY_PLACEHOLDER, TEXT_SPLIT_SYMBOL } from '../constants'
import { sharedCtx } from '../context'
import { ElPopover } from '../element-ui'
import { ISignType } from '../service/apis/searchApi'
import { IBaseDictItem, IDrugApprovalTimelineItem, IDrugDetail, ILang } from '../types'
import { IDrugApprovalSourceItem } from '../types/approval'
import { decrypt, encryptString, getDicNames, getDrugAtcLinks, getSortFnByLang, getSpecialLang, mergeViewAndIds } from '../utils'
import { formatTimestamp } from '../utils/time'
import {
  renderApprovalCountryAsync,
  renderApprovalCountryInDrugDetail,
  renderDrugTypeAsync,
  renderLimited,
  renderLimitedDisease,
  renderLimitedMechanism,
  renderLimitedOrg,
  renderLimitedSynonyms,
  renderLimitedTargets,
  renderPhase,
  renderPopoverLimited,
  renderPopoverLimitedAtc,
  renderPopoverLimitedAtcAsync,
  renderPopoverLimitedDiseaseAsync,
  renderPopoverLimitedMechanismAsync,
  renderPopoverLimitedOrgAsync,
  renderPopoverLimitedTargetsAsync,
  renderPopoverLimitedText,
} from './baseRender'

export function renderDrugNameInTable(data: IDrugDetail) {
  return <BDrugItem data={data} defaultColor="blue" showBilingual></BDrugItem>
}

export function renderDrugType(data: IDrugDetail, $i18n: IVueI18n) {
  const types = getDicNames(data.drug_type_view || [], $i18n.locale.toUpperCase() as ILang)
  if (types.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  return renderLimited(data.drug_type_view || [], {
    limitCount: 10,
    render: (item) => <BDrugTypeTagItem data={item}></BDrugTypeTagItem>,
  })
}

export function renderDrugTypeInTable(data: IDrugDetail) {
  return renderDrugTypeAsync(data.drug_type_view || [], data.drug_type, 3)
}

/**
 * 渲染药物别名：商品名>研发代码>别名;
 * 因为要导出,所以走后端排序,前端不排了
 * @param data
 * @returns
 */
export function renderDrugSynonyms(data: IDrugDetail, lang: ILang, limit = 10, isPopover = false) {
  const aliasNames = data.alias || []
  const tradeNames = data.trade_name || []
  const codeNames = data.dev_code || []
  const aliasNameTexts = aliasNames.map((i) => i.name)
  const tradeNameTexts = tradeNames.map((i) => i.name)
  const codeNameTexts = codeNames.map((i) => i.code)
  const allAlias = [...aliasNameTexts, ...codeNameTexts, ...tradeNameTexts].sort(getSortFnByLang(lang))
  if (isPopover) {
    return renderPopoverLimitedText(allAlias, limit, true)
  }
  return renderLimitedSynonyms(allAlias, limit)
}

export function renderDrugTargets(data: IDrugDetail) {
  return renderLimitedTargets(data.target_id_view || [], 10)
}

export function renderDrugTargetsInTable(data: IDrugDetail) {
  return renderPopoverLimitedTargetsAsync(data.target_id_view || [], data.target_id, 3)
}

export function renderDrugTargetsInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.target_id_view, data.target_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BTargetItem data={item as IBaseTargetInfo} popover={false}></BTargetItem>,
  })
}

export function renderDrugMechanism(data: IDrugDetail) {
  return renderLimitedMechanism(data.mechanism_action_id_view || [], 10)
}

export function renderDrugMechanismInTable(data: IDrugDetail) {
  return renderPopoverLimitedMechanismAsync(data.mechanism_action_id_view || [], data.mechanism_action_id, 3)
}

export function renderDrugMechanismInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.mechanism_action_id_view, data.mechanism_action_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BMechanismItem data={item} truncate></BMechanismItem>,
    gap: { x: 8, y: 4 },
  })
}

export function renderDrugTherapeuticArea(data: IDrugDetail) {
  return renderLimitedDisease(data.therapeutic_area_view || [], 10)
}

export function renderDrugTherapeuticAreaInTable(data: IDrugDetail) {
  return renderPopoverLimitedDiseaseAsync(data.therapeutic_area_view || [], data.therapeutic_area, 3)
}

export function renderDrugTherapeuticAreaInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.therapeutic_area_view || [], data.therapeutic_area), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDiseaseItem data={item as IBaseDiseaseInfo} popover={false}></BDiseaseItem>,
  })
}

export function renderDrugStructureInTable(data: IDrugDetail) {
  const { structure = [] } = data
  if (!structure.length) {
    return ''
  }
  const firstStructure = structure[0]
  const restCount = structure.length - 1
  const href = sharedCtx.router.generatorDrugPath(data?.drug_id || '')
  return (
    <div class="relative">
      <ElPopover appendToBody={true} placement="right" visibleArrow={false} trigger="hover">
        <div style="width:300px;height:300px">
          <img width={300} height={300} src={firstStructure.base64} />
        </div>
        <div slot="reference">
          <img class="block" width={108} height={108} src={firstStructure.base64} />
        </div>
      </ElPopover>
      {restCount > 0 && (
        <a href={href} target="_blank" class="absolute top-1 left-1 bg-gray-30 px-1 text-blue-default">
          {restCount}
        </a>
      )}
    </div>
  )
}

export function renderDrugActiveIndication(data: IDrugDetail) {
  return renderLimitedDisease(data.research_disease_view || [], 10)
}

export function renderDrugActiveIndicationInTable(data: IDrugDetail, $i18n: IVueI18n) {
  return _renderDrugInIndicationInTable({
    data,
    id_view_field: 'research_disease_view',
    id_field: 'research_disease',
    $i18n,
  })
}

export function renderDrugActiveIndicationInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.research_disease_view, data.research_disease), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDiseaseItem data={item as IBaseDiseaseInfo} popover={false}></BDiseaseItem>,
  })
}

export function renderDrugInActiveIndication(data: IDrugDetail) {
  return renderLimitedDisease(data.non_research_disease_view || [], 10)
}

export function renderDrugInActiveIndicationInTable(data: IDrugDetail, $i18n: IVueI18n) {
  return _renderDrugInIndicationInTable({
    data,
    id_view_field: 'non_research_disease_view',
    id_field: 'non_research_disease',
    $i18n,
  })
}

export function renderDrugInActiveIndicationInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.non_research_disease_view, data.non_research_disease), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BDiseaseItem data={item as IBaseDiseaseInfo} popover={false}></BDiseaseItem>,
  })
}

/**
 * 在列表页渲染适应症
 * @param params  queryIdRef
 * @returns
 */
function _renderDrugInIndicationInTable(params: {
  data: IDrugDetail
  id_view_field: 'non_research_disease_view' | 'research_disease_view'
  id_field: 'non_research_disease' | 'research_disease'
  $i18n: IVueI18n
}) {
  const { id_view_field, id_field, data, $i18n } = params
  if (!data[id_view_field]?.length) {
    return EMPTY_PLACEHOLDER
  }
  const lang = $i18n.locale.toUpperCase() as ILang
  return (
    <GExpandDialog
      dialogProps={{
        showFooter: false,
        width: '840px',
        title: _getDrugName(data, lang === 'CN'),
      }}
    >
      {renderPopoverLimitedDiseaseAsync(data[id_view_field] || [], data[id_field], 3, false)}
      <div slot="dialog">
        <BDiseaseResearchStatus tableHeight="500px" drug_id={data.drug_id} disease_ids={data[id_field] || []}></BDiseaseResearchStatus>
      </div>
      <div slot="title" class="flex items-center truncate">
        <BDrugItem showIcon={true} data={data} showBilingual bilingualPosition="horizontal"></BDrugItem>
        <GTooltip theme="light" class="ml-1" mode="icon">
          <div
            slot="content"
            domPropsInnerHTML={
              lang === 'CN'
                ? '每个适应症只展示最高研发状态所在国家/地区，可进入详情页查看其它国家/地区的研发进度'
                : 'Only the country/location with highest phase is displayed for each indication. You can view the progress in other countries/locations in detail page.'
            }
          ></div>
        </GTooltip>
      </div>
    </GExpandDialog>
  )
}

export function renderDrugOriginalOrganization(data: IDrugDetail) {
  return renderLimitedOrg(data.originator_org_master_entity_id_view || [], 10)
}

// 原研机构
export function renderDrugOriginalOrganizationInTable(data: IDrugDetail) {
  return renderPopoverLimitedOrgAsync(data.originator_org_master_entity_id_view || [], data.originator_org_master_entity_id, 3)
}

export function renderDrugOriginalOrganizationInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.originator_org_master_entity_id_view, data.originator_org_master_entity_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderDrugInActiveOrganization(data: IDrugDetail) {
  return renderLimitedOrg(data.inactive_org_master_entity_id_view || [], 10)
}

export function renderDrugInActiveOrganizationInTable(data: IDrugDetail) {
  return renderPopoverLimitedOrgAsync(data.inactive_org_master_entity_id_view || [], data.inactive_org_master_entity_id, 3)
}

export function renderDrugInActiveOrganizationInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.inactive_org_master_entity_id_view, data.inactive_org_master_entity_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderDrugActiveOrganization(data: IDrugDetail) {
  return renderLimitedOrg(data.active_org_master_entity_id_view || [], 10)
}

// 在研机构
export function renderDrugActiveOrganizationInTable(data: IDrugDetail) {
  return renderPopoverLimitedOrgAsync(data.active_org_master_entity_id_view || [], data.active_org_master_entity_id, 3)
}

// 在研机构
export function renderDrugActiveOrganizationInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.active_org_master_entity_id_view, data.active_org_master_entity_id), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BOrganizationItem data={item as IBaseOrgInfo} popover={false} isSignature={false}></BOrganizationItem>,
  })
}

export function renderDrugGlobalHighestPhase(data: IDrugDetail, $i18n: IVueI18n, showApprovedDate = false) {
  return renderPhase(
    {
      currentPhase: data.global_highest_dev_status_view,
      beforePhase: data.global_highest_dev_status_before_termination_view,
      fontClassName: data.fontClassName,
      approvedDate: showApprovedDate ? data.first_approved_date : undefined,
    },
    $i18n.locale.toUpperCase() as ILang
  )
}

export function renderDrugGlobalFirstApprovalDate(data: IDrugDetail, $i18n: IVueI18n) {
  const { first_approved_date } = data
  if (!first_approved_date) {
    return EMPTY_PLACEHOLDER
  }
  return formatTimestamp(first_approved_date, {
    locale: $i18n.locale as ILang,
  })
}
export function renderDrugGlobalFirstApprovalCountry(data: IDrugDetail) {
  const { first_approved_country_view = [] } = data
  if (first_approved_country_view.length === 0) return EMPTY_PLACEHOLDER
  return first_approved_country_view?.map((item) => {
    const countryName = item.alpha_2_code || item.display_name
    return <GCountryFlag class="mr-1" name={countryName} showName={true}></GCountryFlag>
  })
}
export function renderDrugGlobalFirstApprovalDisease(data: IDrugDetail) {
  const { first_approved_indication_view = [] } = data
  if (first_approved_indication_view.length === 0) return EMPTY_PLACEHOLDER
  return first_approved_indication_view?.map((item) => {
    return <BDiseaseItem class="mr-1 mb-1" data={item}></BDiseaseItem>
  })
}

export function renderDrugChinaHighestPhase(data: IDrugDetail, $i18n: IVueI18n, showApprovedDate = false) {
  return renderPhase(
    {
      currentPhase: data.highest_dev_status_cn_view,
      beforePhase: data.highest_dev_status_before_termination_cn_view,
      fontClassName: data.fontClassName,
      approvedDate: showApprovedDate ? data.first_approved_date_cn : undefined,
    },
    $i18n.locale.toUpperCase() as ILang
  )
}

export function renderApprovalCountry(data: IDrugDetail) {
  return renderApprovalCountryAsync(data.approval_country_view || [], data.approval_country, 3)
}

export function renderApprovalCountryInWorkspaceTable(data: IDrugDetail, $i18n: IVueI18n) {
  return renderPopoverLimited(mergeViewAndIds(data.approval_country_view, data.approval_country), {
    limitCount: 3,
    mode: 'link',
    link: sharedCtx.router.generatorDrugPath(data.drug_id),
    linkTip: $i18n.tc('common.viewInDetailTip'),
    render: (item) => <BApprovalCountryItem data={item as IBaseCountry}></BApprovalCountryItem>,
  })
}

export function renderDrugRegulation(data: IDrugDetail, $i18n: IVueI18n) {
  return <BRegulation regulationData={data.special_approval || []} limit={10}></BRegulation>
}

export function renderDrugRegulationInTable(data: IDrugDetail, $i18n: IVueI18n) {
  if (!data.special_approval?.length) {
    return EMPTY_PLACEHOLDER
  }
  return (
    <GExpandDialog
      dialogProps={{
        showFooter: false,
        width: '840px',
        title: _getDrugName(data, ($i18n.locale.toUpperCase() as ILang) === 'CN'),
      }}
      scopedSlots={{
        dialog: ({ isShowSlot }: { isShowSlot: boolean }) => {
          if (!isShowSlot) {
            return null
          }
          return <BRegulationTable tableHeight="500px" data={data.special_approval || []}></BRegulationTable>
        },
      }}
    >
      <BRegulation regulationData={data.special_approval} display="popover"></BRegulation>
      <div slot="title">
        <BDrugItem showIcon={true} data={data} showBilingual bilingualPosition="horizontal"></BDrugItem>
      </div>
    </GExpandDialog>
  )
}

export function renderDrugRegulationInWorkspaceTable(data: IDrugDetail) {
  return <BRegulation display="popover" regulationData={data.special_approval || []}></BRegulation>
}

export function renderDrugAdcInTable(data: IBaseDrugAdc & { is_xdc?: boolean }) {
  return (
    <BMosaic block={true} trigger_point="DRUG_ADC">
      {!data.is_xdc ? <span>{EMPTY_PLACEHOLDER}</span> : <BDrugAdcFieldDesc data={data}></BDrugAdcFieldDesc>}
    </BMosaic>
  )
}

export function renderDrugAdcInWorkspaceTable(data: IBaseDrugAdc & { is_xdc?: boolean }) {
  return !data.is_xdc ? <span>{EMPTY_PLACEHOLDER}</span> : <BDrugAdcFieldDesc data={data}></BDrugAdcFieldDesc>
}

export function renderDrugAtc(data?: IBaseDictItem[]) {
  const codeLinks = getDrugAtcLinks(data || [])
  return renderPopoverLimitedAtc(codeLinks)
}
export function renderDrugAtcInTable(data: IDrugDetail) {
  return renderPopoverLimitedAtcAsync(getDrugAtcLinks(data.atc_code_view || []), data.atc_code)
}

export function renderDrugExistDealInTable(data: IDrugDetail) {
  if (isUndefined(data.exist_deal)) return EMPTY_PLACEHOLDER

  return <BDrugExistDealField data={data}></BDrugExistDealField>
}

export function renderDrugWiki(link?: string) {
  if (!link) return EMPTY_PLACEHOLDER
  return <GLink defaultColor="blue" style="display: inline" href={link} name={link}></GLink>
}

export function renderGeneSequence(data: IDrugDetail, callback: (sequence_hash_id: string) => void) {
  const sequenceList = data.sequence

  if (!sequenceList?.length) return EMPTY_PLACEHOLDER

  return (
    <div>
      {sequenceList.map((item, index) => {
        return (
          <div style="width: 800px" class={{ 'mt-10': index !== 0 }}>
            <BSequenceItem onClickSimilar={callback} sequence={item}></BSequenceItem>
          </div>
        )
      })}
    </div>
  )
}
export function renderDrugKegg(ids?: string[]) {
  if (!ids?.length) return EMPTY_PLACEHOLDER

  return ids.map((id) => (
    <GLink style="margin-right: 8px;display: inline-flex" defaultColor="blue" name={id} href={`https://www.genome.jp/entry/dr:${id}`}></GLink>
  ))
}

export function renderDrugDrugBank(ids?: string[]) {
  if (!ids?.length) return EMPTY_PLACEHOLDER

  return ids.map((id) => (
    <GLink style="margin-right: 8px; display: inline" defaultColor="blue" name={id} href={`https://go.drugbank.com/drugs/${id}`}></GLink>
  ))
}

const renderDate = (date: string, xCorrelationId?: string) => {
  return <div class={['text-sm font-semibold text-black-default leading-5 whitespace-nowrap', 'icon-font']}>{handleSpace(encryptString(date))}</div>
}

export function renderDrugApprovalTimeline(data: IDrugDetail, $i18n: IVueI18n) {
  const { approval_timeline = [] } = data
  if (approval_timeline.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  const filteredTimeline = approval_timeline.filter(
    (i) =>
      i.country &&
      i.country_view &&
      i.dev_status_time &&
      i.dev_status_time.time_trust_code !== E_TIME_TRUST_CODE.INVALID_DATE &&
      typeof i.dev_status_time.time_ts !== 'undefined'
  )
  if (filteredTimeline.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>
  const uniqTimeline = uniqBy(filteredTimeline, (timeline) => {
    const { country, dev_status_time } = timeline
    return `${country} ${formatTimestamp(dev_status_time, { locale })}`
  })
    .map((timeLime) => {
      const cloneTimeLine = cloneDeep(timeLime)
      const timeTs = cloneTimeLine.dev_status_time.time_ts
      const decodeTimeTs = Number(decrypt(String(timeTs), data.xCorrelationId || ''))
      cloneTimeLine.dev_status_time.time_ts = decodeTimeTs
      return cloneTimeLine
    })
    .sort((a, b) => a.dev_status_time.time_ts - b.dev_status_time.time_ts)

  if (uniqTimeline.length === 0) return <span>{EMPTY_PLACEHOLDER}</span>

  const groupedTimeline = groupBy(uniqTimeline, (timeline) => timeline.dev_status_time.time_ts)
  // 同一时间点国家显示优先级如下：美国>欧盟>中国>日本>其他国家，越重要的国家离时间轴越近
  const sortByArr = ['US', 'EU', 'CN', 'JP']
  const groupedTimelineList = Object.entries(groupedTimeline)
    .map(([key, value]) => value)
    .map((i) =>
      i
        .filter((x) => sortByArr.includes(x.country_view.alpha_2_code || ''))
        .sort((x, y) => sortByArr.indexOf(x.country_view.alpha_2_code || '') - sortByArr.indexOf(y.country_view.alpha_2_code || ''))
        .concat(i.filter((x) => !sortByArr.includes(x.country_view.alpha_2_code || '')))
    )

  const locale = $i18n.locale.toUpperCase() as ILang
  const renderItem = (timelineList: IDrugApprovalTimelineItem[], index: number) => {
    if (!timelineList.length) {
      return null
    }
    const { dev_status_time } = timelineList[0]
    const decrypted_dev_status_time = { ...dev_status_time }
    const date = formatTimestamp(decrypted_dev_status_time, { locale })
    return (
      <div class="text-left pl-4" style="min-width: 120px;">
        {index % 2 === 0 ? renderDate(date, data.xCorrelationId) : null}
        {renderApprovalCountryInDrugDetail(timelineList, {
          locale,
          limitCount: 3,
        })}
        {index % 2 !== 0 ? renderDate(date, data.xCorrelationId) : null}
      </div>
    )
  }

  const timeLineHeight = () => {
    if (some(groupedTimelineList, (timelineList) => timelineList.length >= 3)) {
      return 264
    }
    if (some(groupedTimelineList, (timelineList) => timelineList.length >= 2)) {
      return 184
    }
    return 156
  }

  return (
    <div style={`height: ${timeLineHeight()}px; overflow: auto; overflow-y: hidden;`}>
      <GTimeline mode="alternate">
        {groupedTimelineList.map((timelineList, index) => (
          <GTimelineItem key={index}>{renderItem(timelineList, index)}</GTimelineItem>
        ))}
      </GTimeline>
    </div>
  )
}

function _getDrugName(data: IDrugDetail, isShowCn = true) {
  return [
    getSpecialLang({ data: data.drug_name, field: 'name', lang: 'EN' as ILang, isDegraded: false }),
    isShowCn ? getSpecialLang({ data: data.drug_name, field: 'name', lang: 'CN' as ILang, isDegraded: false }) : '',
  ]
    .filter(Boolean)
    .join(' ')
}

export function renderPDF(item: Array<IDrugApprovalSourceItem & { feType?: string }>, signType: ISignType = 'synapse_attachment', locale: ILang) {
  if (!item.length) {
    return EMPTY_PLACEHOLDER
  }
  const pdfLrlpLength = item.length
  // 防止popover溢出
  const limitCount = 10000
  const displayLen = pdfLrlpLength < limitCount ? pdfLrlpLength : limitCount
  return renderPopoverLimited(item, {
    limitCount,
    render: (item, index, isRenderInPopover) => {
      const type = item.feType || item.type
      const time = item.time_ts && formatTimestamp(item.time_ts, { locale })
      if (!item.s3_path)
        return (
          <span>
            {type}
            {`${time ? `(${time})` : ''}`}
          </span>
        )
      const s3pathArr = item.s3_path.split('.')
      return (
        <BSignaturePdf
          class="text-blue-default text-sm truncate w-full inline-block"
          style="line-height: 23px"
          src={item.s3_path}
          title={type}
          signType={signType}
          extension={s3pathArr[s3pathArr.length - 1].toLowerCase()}
        >
          {type}
          {`${time ? `(${time})` : ''}`}
          {index !== (isRenderInPopover ? pdfLrlpLength : displayLen) - 1 ? TEXT_SPLIT_SYMBOL : ''}
        </BSignaturePdf>
      )
    },
    gap: { x: 4, y: 4 },
  })
}
