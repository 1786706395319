import DataLoader from 'dataloader'
import { ICancelAbleParams, IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'
export type ILogoSignatureData = Record<string, string>

export type ISignType = 'master_entity_org_logo' | 'phs_report' | 'discovery_attachment' | 'synapse_attachment' | 'ls360_chat_file'
export interface IGetLogoSignatureParams extends ICancelAbleParams {
  logoList: string[]
  signType?: ISignType
}

export class SearchApi extends BaseApi {
  private signatureImgDataLoader: Partial<Record<ISignType, DataLoader<string, string, string>>> = {}
  private getDataLoader(signType: ISignType): DataLoader<string, string, string> {
    if (!this.signatureImgDataLoader[signType]) {
      this.signatureImgDataLoader[signType] = new DataLoader((logoList: readonly string[]) => this.logoBatchSignature(logoList, signType))
    }
    return this.signatureImgDataLoader[signType] as DataLoader<string, string, string>
  }
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }
  public async logoBatchSignature(logoList: readonly string[], signType: ISignType = 'master_entity_org_logo'): Promise<string[]> {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/signature/sign'),
      data: {
        object_key: logoList,
        sign_type: signType,
      },
    })
    if (rt.status === 200) {
      return logoList.map((item) => rt.data[item])
    }
    return []
  }

  async getS3UploadUrl(params: {
    file_name: string
    sign_type: 'organization_logo' | 'dashboard_icon' | 'user_cert'
  }): Promise<IHttpWrap<{ get_url: string; put_url: string; s3_path: string }>> {
    return await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/signature/get_upload_url'),
      data: params,
    })
  }

  async putS3File(params: { put_url: string; file: Blob }): Promise<IHttpWrap<void>> {
    return await this.http({
      method: 'PUT',
      headers: {
        'content-type': 'image/png;charset=utf-8',
      },
      url: params.put_url,
      data: params.file,
      transformRequest: (data, headers) => {
        // s3 有自己的验证策略，如果传递了Authorization，会尝试验证，因此这边需要删除下
        // https://stackoverflow.com/questions/46656474/axios-remove-headers-authorization-in-1-call-only
        if (headers) {
          delete headers['Authorization']
        }
        return data
      },
    })
  }

  async logoBatchSignatureWithCache(data: IGetLogoSignatureParams): Promise<IHttpWrap<ILogoSignatureData>> {
    const { logoList, signType = 'master_entity_org_logo' } = data
    const rt = (await this.getDataLoader(signType).loadMany(logoList)) as string[]
    return {
      success: true,
      status: 200,
      xCorrelationId: '',
      data: rt.reduce((acc, curr: string, index) => {
        return {
          ...acc,
          [logoList[index]]: curr || '',
        }
      }, {} as Record<string, string>),
    }
  }
}
