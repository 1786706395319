import { E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { getLocaleLang } from '@pharmsnap/pharmsnap-web/utils/business/locale'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import VueRouter from 'vue-router'
interface FreemiumCompleteUserinfoConfig {
  userId: string
  sessionId: string
  userInfoIsComplete: boolean
  router: VueRouter
}
interface FreemiumCompleteStoreInfo {
  userId: string
  sessionId: string
  times?: number
  skip?: boolean
}
export class FreemiumCompleteUserinfo {
  searchLimitTimes = 3
  localStorageKey = 'freemium_complete_userinfo'
  _config: FreemiumCompleteUserinfoConfig | null = null

  get config() {
    if (!this._config) {
      throw new Error('FreemiumCompleteUserinfo not init')
    }
    return this._config
  }

  private checkSkip() {
    return this.storedInfo?.sessionId === this.config.sessionId && this.storedInfo.skip
  }

  private mergeStoreInfo(data: { times?: number; skip?: boolean }): FreemiumCompleteStoreInfo {
    const storedInfo = this.storedInfo
    // 初次或者不是同一个用户则重置数据
    if (!storedInfo?.userId || (storedInfo?.userId && storedInfo?.userId !== this.config.userId)) {
      return {
        ...data,
        userId: this.config.userId,
        sessionId: this.config.sessionId,
      }
    }
    // 相同的用户且是相同的sessionId则合并数据
    if (storedInfo?.sessionId === this.config.sessionId) {
      return {
        ...storedInfo,
        ...data,
        userId: this.config.userId,
        sessionId: this.config.sessionId,
      }
    } else {
      // 同用户但是不同的sessionId则仅仅保留用户的使用次数
      return {
        times: storedInfo?.times || 0,
        ...data,
        userId: this.config.userId,
        sessionId: this.config.sessionId,
      }
    }
  }

  private storeInfo(data: { times?: number; skip?: boolean }) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.mergeStoreInfo(data)))
  }

  private get storedInfo(): FreemiumCompleteStoreInfo | null {
    const data = localStorage.getItem(this.localStorageKey)
    if (!data) {
      return null
    }
    try {
      const jsonData = JSON.parse(data) as FreemiumCompleteStoreInfo
      // 如果不是同一个用户则清除数据
      if (jsonData.userId !== this.config.userId) {
        localStorage.removeItem(this.localStorageKey)
        return null
      }
      return jsonData
    } catch (error) {
      return null
    }
  }

  public gotoCompleteUserInfo() {
    const lang = getLocaleLang()
    showSingleToast({
      type: 'info',
      duration: 5000,
      message: lang === 'cn' ? '请完善个人信息， 以获得更多产品权限' : 'Please complete personal information to get more privileges.',
    })
    trackingEvent('TRIGGER_COMPLETE_INFO')
    setTimeout(() => {
      this.config.router.push({
        name: E_ROUTER_NAME.COMPLETE_INFO,
        query: {
          redirectUrl: window.location.href,
        },
      })
    }, 300)
  }

  public init(config: FreemiumCompleteUserinfoConfig) {
    if (this._config) {
      throw new Error("FreemiumCompleteUserinfo can't init twice")
    }
    this._config = config
    // 用户信息完整则不需要添加监听
    if (this.config.userInfoIsComplete) {
      return
    }
    // 用户主动取消完善用户信息则不需要添加监听
    if (this.checkSkip()) {
      return
    }
    trackingEvent.$bus.$on('ACTIVE_SEARCH', () => {
      // 用户主动取消完善用户信息则不需要触
      if (this.checkSkip()) {
        return
      }
      this.storeInfo({
        times: (this.storedInfo?.times || 0) + 1,
      })
      const searchCount = this.storedInfo?.times || 0
      if (searchCount > this.searchLimitTimes) {
        this.gotoCompleteUserInfo()
      }
    })
  }

  public skip() {
    this.storeInfo({
      skip: true,
    })
  }

  static ins: FreemiumCompleteUserinfo

  static getSingleton() {
    return FreemiumCompleteUserinfo.ins || (FreemiumCompleteUserinfo.ins = new FreemiumCompleteUserinfo())
  }
}
