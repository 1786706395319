import {
  IClinicalDetail,
  IClinicalTrial,
  ICTGOVClinicalDetail,
  ICTPhase,
  ICTRClinicalDetail,
  ICTRecruitmentStatus,
  IWHOClinicalDetail,
} from '@pharmsnap/shared/types'
import { decrypt, decryptNum, decryptTarget } from '@pharmsnap/shared/utils'

export function decryptClinicalDate(item: IClinicalTrial, xCorrelationId: string) {
  if (item?.start_date_v2) {
    item.start_date_v2.date.time_ts = decryptNum(item.start_date_v2.date.time_ts, xCorrelationId)
  }
  if (item?.study_first_posted_date) {
    item.study_first_posted_date.time_ts = decryptNum(item.study_first_posted_date.time_ts, xCorrelationId)
  }
}

export function decryptClinicalRecruitmentStatus(status: ICTRecruitmentStatus | undefined, xCorrelationId: string, fontClassName: string) {
  if (!status) return
  if (status.normalized_status) {
    status.normalized_status = decrypt(status.normalized_status, xCorrelationId)
  }
  if (status.name) {
    status.name = decrypt(status.name, xCorrelationId)
  }
  status.fontClassName = fontClassName
}

export function decryptClinicalPhase(phase: ICTPhase | undefined, xCorrelationId: string, fontClassName?: string) {
  if (!phase) return
  if (phase.name) {
    phase.name = decrypt(phase.name, xCorrelationId)
  }
  if (phase.normalized_phase_view) {
    phase.normalized_phase_view.name_en = decrypt(phase.normalized_phase_view.name_en, xCorrelationId)
    phase.normalized_phase_view.name_cn = decrypt(phase.normalized_phase_view.name_cn, xCorrelationId)
    phase.normalized_phase_view.fontClassName = fontClassName
  }
  if (phase.normalized_phase_highest_view) {
    phase.normalized_phase_highest_view.name_en = decrypt(phase.normalized_phase_highest_view.name_en, xCorrelationId)
    phase.normalized_phase_highest_view.name_cn = decrypt(phase.normalized_phase_highest_view.name_cn, xCorrelationId)
    phase.normalized_phase_highest_view.fontClassName = fontClassName
  }
}

export function decryptClinicalDetail(item: IClinicalDetail, xCorrelationId: string, fontClassName: string) {
  item.fontClassName = fontClassName
  decryptClinicalDate(item, xCorrelationId)
  decryptClinicalRecruitmentStatus(item.recruitment_status, xCorrelationId, fontClassName)
  decryptClinicalPhase(item.phase, xCorrelationId, fontClassName)
  if (item.main_experimentation_drug_target_id_view) {
    item.main_experimentation_drug_target_id_view?.forEach((view) => {
      decryptTarget(view, xCorrelationId)
      view.fontClassName = fontClassName
    })
  }
  if (item.data_source === 'CTR') {
    decryptCTRClinicalDetail(item, xCorrelationId, fontClassName)
  } else if (item.data_source === 'CTGOV' || item.data_source === 'WHO') {
    decryptCTGOVClinicalDetail(item, xCorrelationId, fontClassName)
  }
}

export function decryptCTRClinicalDetail(item: ICTRClinicalDetail, xCorrelationId: string, fontClassName: string) {
  if (item.enrollment_v2) {
    item.enrollment_v2.forEach((enrollment) => {
      if (enrollment.actual_text) {
        enrollment.actual_text = decrypt(enrollment.actual_text, xCorrelationId)
      }
      if (enrollment.already_text) {
        enrollment.already_text = decrypt(enrollment.already_text, xCorrelationId)
      }
      if (enrollment.estimated_text) {
        enrollment.estimated_text = decrypt(enrollment.estimated_text, xCorrelationId)
      }
      enrollment.fontClassName = fontClassName
    })
  }
  if (item.completion_date_v2) {
    item.completion_date_v2.forEach((date) => {
      if (date.first_sign_date) {
        date.first_sign_date.time_ts = decryptNum(date.first_sign_date.time_ts, xCorrelationId)
      }
      if (date.first_participant_date) {
        date.first_participant_date.time_ts = decryptNum(date.first_participant_date.time_ts, xCorrelationId)
      }
      if (date.completion_date) {
        date.completion_date.time_ts = decryptNum(date.completion_date.time_ts, xCorrelationId)
      }
      if (date.suspension_date) {
        date.suspension_date.time_ts = decryptNum(date.suspension_date.time_ts, xCorrelationId)
      }
      if (date.termination_date) {
        date.termination_date.time_ts = decryptNum(date.termination_date.time_ts, xCorrelationId)
      }
    })
  }
}

export function decryptCTGOVClinicalDetail(item: ICTGOVClinicalDetail | IWHOClinicalDetail, xCorrelationId: string, fontClassName: string) {
  if (item.enrollment) {
    if (item.enrollment.enrollment) {
      item.enrollment.enrollment = decrypt(item.enrollment.enrollment, xCorrelationId)
    }
    item.enrollment.fontClassName = fontClassName
  }
  if (item.start_date_v2) {
    item.start_date_v2.date.time_ts = decryptNum(item.start_date_v2.date.time_ts, xCorrelationId)
  }
  if (item.primary_completion_date_v2?.date) {
    item.primary_completion_date_v2.date.time_ts = decryptNum(item.primary_completion_date_v2.date.time_ts, xCorrelationId)
  }
  if (item.completion_date?.completion_date) {
    item.completion_date.completion_date.time_ts = decryptNum(item.completion_date.completion_date.time_ts, xCorrelationId)
  }
}
