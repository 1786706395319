import { AxiosInstance } from 'axios'
import Fontfaceobserver from 'fontfaceobserver'
import { customAlphabet } from 'nanoid'
import { lowercase, uppercase } from 'nanoid-dictionary'
import Raven from 'raven-js'
import { deBuggerConfig } from '../features/dev-tool/config'
const X_FONT_URL = 'x-font-url'
const X_FONT_FONT_CLASS = 'x-font-class'
const X_FONT_VERSION = 'x-encrypt-version'
const LOADED_FONT_MAP = new Map<string, string>()
/**
 * 动态字体拦截器
 * @param axiosIns axios实例
 */
export function interceptAxiosWithDynamicFont(axiosIns: AxiosInstance) {
  axiosIns.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      [X_FONT_VERSION]: '1.0',
    }
    return config
  })
  axiosIns.interceptors.response.use(async (response) => {
    const fontUrl = response.headers[X_FONT_URL] as string
    if (fontUrl) {
      const fontName = await createDynamicFontStyleWithCache(fontUrl)
      const fontClassHeader = {
        [X_FONT_FONT_CLASS]: getFontClassName(fontName),
      }
      if (response.headers) {
        Object.assign(response.headers, fontClassHeader)
      } else {
        response.headers = fontClassHeader
      }
    }
    return response
  })
}
async function createDynamicFontStyleWithCache(fontUrl: string): Promise<string> {
  if (!LOADED_FONT_MAP.has(fontUrl)) {
    const nanoid = customAlphabet(`${lowercase}${uppercase}`, 12)
    const fontName = nanoid()
    LOADED_FONT_MAP.set(fontUrl, fontName)
    createDynamicFontStyle(fontUrl, fontName)
    const font = new Fontfaceobserver(fontName)
    await font.load().catch(() => {
      const msg = `[log]:load font failed, font url: ${fontUrl}`
      console.error(msg)
      Raven.captureMessage(msg)
    })
    return fontName
  } else {
    return LOADED_FONT_MAP.get(fontUrl) || ''
  }
}
/**
 * 创建动态字体样式
 * @param fontUrl 字体id
 */
function createDynamicFontStyle(fontUrl: string, fontName: string) {
  const styleElement = document.createElement('style')
  styleElement.innerHTML = `
  @font-face {
    font-family: '${fontName}';
    src: url('${fontUrl}');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .${getFontClassName(fontName)} {
    font-family: '${fontName}' !important;
    ${deBuggerConfig.isHighlightIconFont ? 'background-color: gold;' : ''}
  }`
  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(styleElement)
}

function getFontClassName(iconName: string) {
  return `icon-font-${iconName}`
}
