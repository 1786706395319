import { VUE_APP_BIO_URL, VUE_APP_PRODUCT_CHEMICAL_URL } from '@pharmsnap/pharmsnap-web/config'
import { GIcon } from '@pharmsnap/shared/components'
import { openNewTab } from '@pharmsnap/shared/src/utils'
import { defineComponent, getCurrentInstance, PropType } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import bioBg from './assets/bio.png'
import chemicalBg from './assets/chemical.png'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const ProductPromotionCard = defineComponent({
  name: 'ProductPromotionCard',
  props: {
    type: {
      type: String as PropType<'bio' | 'chemical'>,
      required: true,
    },
  },
  i18n: {
    messages: { cn, en },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const bgMap = {
      bio: bioBg,
      chemical: chemicalBg,
    }
    const iconNameMap = {
      bio: 'SolidCoreProductsBio',
      chemical: 'SolidCoreProductsChemical',
    }
    const titleMap = {
      bio: ins?.proxy.$t('productPromotionCard.bio'),
      chemical: ins?.proxy.$t('productPromotionCard.chemical'),
    }

    const descMap = {
      bio: [
        ins?.proxy.$t('productPromotionCard.bioDesc1'),
        ins?.proxy.$t('productPromotionCard.bioDesc2'),
        ins?.proxy.$t('productPromotionCard.bioDesc3'),
        ins?.proxy.$t('productPromotionCard.bioDesc4'),
      ],
      chemical: [
        ins?.proxy.$t('productPromotionCard.chemicalDesc1'),
        ins?.proxy.$t('productPromotionCard.chemicalDesc2'),
        ins?.proxy.$t('productPromotionCard.chemicalDesc3'),
        ins?.proxy.$t('productPromotionCard.chemicalDesc4'),
      ],
    } as Record<'bio' | 'chemical', string[]>

    const trialDescMap = {
      bio: ins?.proxy.$t('productPromotionCard.bioTrialDesc'),
      chemical: ins?.proxy.$t('productPromotionCard.chemicalTrialDesc'),
    }

    const linkMMap = {
      bio: `${VUE_APP_BIO_URL}`,
      chemical: `${VUE_APP_PRODUCT_CHEMICAL_URL}`,
    }

    const handleSkip = () => {
      emit('close')
    }

    const handleTrial = () => {
      const link = linkMMap[props.type]
      link && openNewTab(link)
      emit('close')
    }

    return { bgMap, iconNameMap, titleMap, descMap, handleSkip, handleTrial, trialDescMap }
  },
  render() {
    return (
      <div>
        <div class="rounded-xl border border-gray-40 overflow-hidden">
          <div
            class="flex flex-col items-center text-white-default py-10"
            style={{ 'background-image': `url(${this.bgMap[this.type]})`, 'background-repeat': 'no-repeat', 'background-size': 'cover' }}
          >
            <PtIcon icon={this.iconNameMap[this.type]} class="w-[70px] h-[70px] "></PtIcon>
            <div class="text-[32px] font-semibold mt-2">{this.titleMap[this.type]}</div>
            <div class="mt-6  space-y-3 px-4">
              {this.descMap[this.type]
                .filter((item) => !!item.trim())
                .map((item) => (
                  <div class="flex items-center gap-x-3" style="word-break: break-word;">
                    <GIcon class="flex-shrink-0" svgName="SolidTick" size={24} />
                    <div class="text-base">{item}</div>
                  </div>
                ))}
            </div>
          </div>
          <div class="mt-6 mb-6 flex items-center justify-center gap-x-8 text-xl">
            <div class=" text-blue-default cursor-pointer" onClick={this.handleSkip}>
              {this.$t('productPromotionCard.tryLater')}
            </div>
            <div
              class="text-white-default px-[60px] py-[7px] rounded-3xl flex gap-x-4 text-white items-center cursor-pointer"
              style="background: linear-gradient(270deg, #155EE1 0%, #2592DD 100%)"
              onClick={this.handleTrial}
            >
              <div class="font-semibold">{this.$t('productPromotionCard.freeTrial')}</div>
              <GIcon svgName="SolidArrowTailRight" size={24} />
            </div>
          </div>
        </div>
        <div class="mt-6 border-t border-gray-40 pt-3 flex items-start">
          <span class="flex-shrink-0 inline-block h-[6px] w-[6px] rounded-full bg-[#bcc2cc] mt-[7px] mr-[6px]"></span>
          <div style="word-break: break-word;" class="text-text-t1 leading-5 text-sm" domPropsInnerHTML={this.trialDescMap[this.type]}></div>
        </div>
      </div>
    )
  },
})
