import { defineComponentProps } from '@pharmsnap/shared/utils'
import { ExtractPropTypes, PropType } from '@vue/composition-api'

export const gDrawerProps = () =>
  defineComponentProps({
    direction: {
      type: String as PropType<'ltr' | 'rtl'>,
      default: 'rtl',
    },
    size: {
      type: [Number, String],
      default: '30%',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    defaultVisible: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
    },
    wrapperMaskClass: {
      type: String,
    },
    maskDisabled: {
      type: Boolean,
      default: false,
    },
  })

export type GDrawerProps = ExtractPropTypes<ReturnType<typeof gDrawerProps>>

export type GDrawerTabType = { label: string; icon: string; value: string; disabled?: boolean }

export const gDrawerSideProps = () =>
  defineComponentProps({
    direction: {
      type: String as PropType<'ltr' | 'rtl'>,
      default: 'rtl',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Array as PropType<GDrawerTabType[]>,
      default: () => [],
    },
    tailDataSource: {
      type: Array as PropType<GDrawerTabType[]>,
      default: () => [],
    },
  })

export type GDrawerSideProps = ExtractPropTypes<ReturnType<typeof gDrawerSideProps>>
