import { AggOneDimDataItem } from '@patsnap/synapse_domain'
import { defineComponent, PropType, toRefs } from '@vue/composition-api'
import { useAnalysisOneDimTable } from '../../compositions/useAnalysisOneDimTable'

export const BAnalysisOneDimTable = defineComponent({
  name: 'BAnalysisOneDimTable',
  props: {
    categoryFieldName: {
      type: String,
      required: true,
    },
    countFieldName: {
      type: String,
      required: true,
    },
    showPercent: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array as PropType<Array<AggOneDimDataItem>>,
      default: () => [],
    },
    displayMode: {
      type: String as PropType<'table' | 'record'>,
      default: 'table',
    },
    height: {
      type: [Number, String],
    },
  },
  setup(props) {
    const { categoryFieldName, countFieldName, showPercent, list, displayMode } = toRefs(props)

    const { tableColumns, tableData } = useAnalysisOneDimTable({
      categoryFieldName,
      countFieldName,
      showPercent,
      list,
      displayMode,
    })

    return {
      tableData,
      tableColumns,
    }
  },
  render() {
    return (
      <vxe-grid
        showHeader={this.displayMode === 'table'}
        height={this.height}
        size="small"
        columns={this.tableColumns}
        data={this.tableData}
        border={true}
      ></vxe-grid>
    )
  },
})

export function analysisOneDimTableBuilder<T extends AggOneDimDataItem = AggOneDimDataItem>(componentName: string) {
  return defineComponent({
    name: componentName,
    props: {
      categoryFieldName: {
        type: String,
        required: true,
      },
      countFieldName: {
        type: String,
        required: true,
      },
      otherCountFieldName: {
        type: String,
      },
      showPercent: {
        type: Boolean,
        default: false,
      },
      list: {
        type: Array as PropType<Array<T>>,
        default: () => [],
      },
      displayMode: {
        type: String as PropType<'table' | 'record'>,
        default: 'table',
      },
      height: {
        type: [Number, String],
      },
      customOtherCountRender: {
        type: Function as PropType<(data: T) => string | JSX.Element>,
      },
      useListSorter: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const { categoryFieldName, countFieldName, otherCountFieldName, showPercent, list, displayMode } = toRefs(props)

      const { tableColumns, tableData } = useAnalysisOneDimTable({
        categoryFieldName,
        countFieldName,
        otherCountFieldName,
        showPercent,
        list,
        displayMode,
        useListSorter: props.useListSorter,
        customOtherCountRender: props.customOtherCountRender,
      })

      return {
        tableData,
        tableColumns,
      }
    },
    render() {
      return (
        <vxe-grid
          showHeader={this.displayMode === 'table'}
          maxHeight={this.height}
          size="small"
          columns={this.tableColumns}
          data={this.tableData}
          border={true}
        ></vxe-grid>
      )
    },
  })
}
