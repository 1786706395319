import type { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { decryptNum } from '../../encrypt/encrypt'
import { injectOrgName, transformGroupAggregationSingleResultByMap } from './base'

export const investmentFieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue> = {
  funding_type: (item, xCorrelationId) => {
    if (item.other_info && xCorrelationId) {
      item.other_info = {
        ...item.other_info,
        ...(typeof item.other_info.sum_money_amount !== 'undefined'
          ? {
              sum_money_amount: decryptNum(String(item.other_info.sum_money_amount), xCorrelationId),
            }
          : {}),
      }
    }
    return item
  },
  master_org_ultimate_parent_id: injectOrgName,
}

export function transformInvestmentAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(data, investmentFieldTransformFnMap, xCorrelationId)
}
