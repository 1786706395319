/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineComponent, PropType } from '@vue/composition-api'
import { IAnalysisChartControlItem } from '../type'

export const BAnalysisChartControlItem = defineComponent({
  name: 'BAnalysisChartControlItem',
  props: {
    item: {
      type: Object as PropType<IAnalysisChartControlItem>,
      required: true,
    },
    layout: {
      type: String as PropType<'horizontal' | 'vertical'>,
      default: 'horizontal',
    },
    blod: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    renderVerticalLayoutControl(item: IAnalysisChartControlItem, controlContent: JSX.Element) {
      const hasLabel = !!item.label
      // const hasDesc = !!item.description

      return (
        <div class="flex flex-col">
          {hasLabel ? <div class={['text-sm leading-5 text-text-t1', this.blod ? 'font-semibold' : '']} domPropsInnerHTML={item.label}></div> : null}
          <div class="mt-3">{controlContent}</div>
        </div>
      )
    },
    renderHorizontalLayoutControl(item: IAnalysisChartControlItem, controlContent: JSX.Element) {
      const hasLabel = !!item.label
      // const hasDesc = !!item.description

      return (
        <div class={['flex flex-row', hasLabel ? 'items-center' : '']}>
          {hasLabel ? (
            <div class={['text-sm leading-5 text-text-t1 mr-2', this.blod ? 'font-semibold' : '']} domPropsInnerHTML={item.label}></div>
          ) : null}
          <div>{controlContent}</div>
        </div>
      )
    },
  },
  render() {
    return this.layout === 'horizontal'
      ? // @ts-ignore
        this.renderHorizontalLayoutControl(this.item, this.$slots.default)
      : // @ts-ignore
        this.renderVerticalLayoutControl(this.item, this.$slots.default)
  },
})
