import { IAggregationData, IAggregationParams, ISearchParams } from '@patsnap/synapse_common_interface'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformClinicalAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/clinical'
import { IAggregationParamsNew, IClinicalDetail, ISearchResult, ITranslateLang } from '../../types'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import { decryptClinicalDetail } from '../utils/decrypt-response/clinical'
import { BaseApi } from './baseApi'

const transformClinicalListData = (
  data: ISearchResult<IClinicalDetail>,
  xCorrelationId: string,
  fontClassName = ''
): ISearchResult<IClinicalDetail> => {
  data.items?.forEach((item) => {
    decryptClinicalDetail(item, xCorrelationId, fontClassName || '')
  })
  return {
    ...data,
    items: data.items?.map((i) => {
      if (i.country?.length) {
        return {
          ...i,
          country: i.country.filter((item) => item.name !== '/'),
        }
      } else {
        return i
      }
    }),
  }
}
export class TrialApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/clinical-trial/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformClinicalAggregationSingleResult))
  }

  async getAggregationNew(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/clinical_trial'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformClinicalAggregationSingleResult))
  }

  /** 参数简化版，返回结果和getAggregationNew一样，简化入参params */
  async getAggregationParamsSimplified(params: IAggregationParamsNew): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/analysis/clinical_trial'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformClinicalAggregationSingleResult))
  }

  /**
   * 临床搜索列表，计搜索次数
   */
  async getClinicalListWithCalcLimits(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IClinicalDetail>>> {
    const rt: IHttpWrap<ISearchResult<IClinicalDetail>> = await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/clinical-trial'),
      cache: true,
      trackingName: 'CLINICAL_LIST',
    })
    if (rt.success) {
      transformClinicalListData(rt.data, rt.xCorrelationId, rt.fontClassName)
    }
    return rt
  }

  /**
   * 临床搜索列表，不计搜索次数
   */
  async getClinicalList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IClinicalDetail>>> {
    const rt: IHttpWrap<ISearchResult<IClinicalDetail>> = await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/clinical-trial/search'),
      cache: true,
      trackingName: 'CLINICAL_LIST',
    })
    if (rt.success) {
      transformClinicalListData(rt.data, rt.xCorrelationId, rt.fontClassName)
    }
    return rt
  }

  async getClinicalTrialDetail(params: { id: string; translation: Omit<ITranslateLang, 'original'> }): Promise<IHttpWrap<IClinicalDetail>> {
    const rt: IHttpWrap<IClinicalDetail> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/clinical-trial/${params.id}`),
      params: { translation: params.translation },
      method: 'GET',
      cache: true,
    })
    if (rt.success) {
      decryptClinicalDetail(rt.data, rt.xCorrelationId, rt.fontClassName || '')
      rt.data = {
        ...rt.data,
        ...(rt.data.country?.length
          ? {
              country: rt.data.country.filter((item) => item.name !== '/'),
            }
          : {}),
      }
    }
    return rt
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IClinicalDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/clinical-trial/search_for_count'),
      cache: true,
    })
  }

  async getDataStatus(id: string): Promise<
    IHttpWrap<{
      clinical_trial_result?: boolean
    }>
  > {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/clinical-trial/${id}/data/status`),
      cache: true,
    })
  }

  async getClinicalLocationAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/clinical-trial/aggregation_region'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }

  async getClinicalTrialCardData({ id }: { id: string }, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl(`pharmsnap_webapi/1.0/clinical-trial/card/${id}`),
      cache: true,
      method: 'GET',
    })
  }
}
