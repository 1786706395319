import { IAggregationData } from '@patsnap/synapse_common_interface'
import { toThousands } from '@patsnap/synapse_common_utils'
import { IUseAnalysisMultiOneDimChartEntityOptions } from '../../type'
import { useAnalysisMultiOneDimChartEntity } from '../useAnalysisMultiOneDimChartEntity'

export function getDrugDealLineBarData(data: IAggregationData): IAggregationData {
  if (data.aggregation_result?.[0]?.items?.length) {
    const items = data.aggregation_result[0].items
    data.aggregation_result.push({
      aggregation_field: 'TOTAL_VALUE',
      total: data.aggregation_result?.[0]?.total || 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items: items.map((item) => ({
        key: item.key,
        display_name_cn: item.key,
        display_name_en: item.key,
        count: (item.other_info?.sum || 0) as number,
      })),
    })
  }
  return data
}

export function useAnalysisDrugDealMultiOneDimChartEntity(options: Omit<IUseAnalysisMultiOneDimChartEntityOptions, 'getChartConfig'>) {
  const getLineBarChartConfig: IUseAnalysisMultiOneDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { aggField } = config

    if (aggField === 'TOTAL_VALUE') {
      return {
        categoryPicker: (item) => item.key,
        yAxisLabelFormatter: (value: number) => {
          return `$${toThousands(value)}M`
        },
      }
    }

    return {
      categoryPicker: (item) => item.key,
    }
  }

  return useAnalysisMultiOneDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'line-bar') return getLineBarChartConfig(type, config)
      return
    },
  })
}
