import { AggBarChartConfig, AggLineBarChartEntity, AggLineChartConfig, AggOneDimDataItem } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { computed, markRaw, shallowRef, unref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { IUseAnalysisMultiOneDimChartEntityOptions } from '../type'

export function useAnalysisMultiOneDimChartEntity(options: IUseAnalysisMultiOneDimChartEntityOptions) {
  const { data, chartType, getChartConfig, categoryFieldName, countFieldName } = options

  const { locale } = useLocale()

  const firstDimAggField = computed(() => unref(data).aggregation_result?.[0]?.aggregation_field)
  const secondDimAggField = computed(() => unref(data).aggregation_result?.[1]?.aggregation_field)

  const chartEntity = shallowRef(markRaw(getChartEntity()))

  const dataList = shallowRef<AggOneDimDataItem[]>(markRaw(chartEntity.value.oneDimDataList))

  function getChartEntity() {
    const type = unref(chartType)
    const aggData = cloneDeep(unref(data).aggregation_result)
    const firstPartialConfig = getChartConfig?.(type, { aggField: unref(firstDimAggField), aggItems: aggData[0]?.items || [] }) || {}
    const secondPartialConfig = getChartConfig?.(type, { aggField: unref(secondDimAggField), aggItems: aggData[1]?.items || [] }) || {}

    const barConfig: AggBarChartConfig = {
      locale: unref(locale),
      seriesName: unref(countFieldName)[0],
      xAxisName: unref(categoryFieldName)[0],
      yAxisName: unref(countFieldName)[0],
      showLabel: true,
      ...(firstPartialConfig as Partial<AggBarChartConfig>),
    }

    const lineConfig: AggLineChartConfig = {
      locale: unref(locale),
      seriesName: unref(countFieldName)[1],
      xAxisName: unref(categoryFieldName)[1],
      yAxisName: unref(countFieldName)[1],
      color: '#FABB27',
      ...(secondPartialConfig as Partial<AggLineChartConfig>),
    }
    return new AggLineBarChartEntity([barConfig, lineConfig], aggData)
  }

  watch(
    () => unref(data),
    () => {
      const entity = getChartEntity()
      dataList.value = markRaw(entity.oneDimDataList)
      chartEntity.value = markRaw(entity)
    }
  )

  return {
    chartEntity,
    dataList,
    countFieldName,
    categoryFieldName,
  }
}
