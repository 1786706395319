import { E_NODATA_SIZE } from '@pharmsnap/shared/types'
import { removeHTML } from '@pharmsnap/shared/utils'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { GDrawerLayout } from '../../ui/GDrawerLayout/GDrawerLayout'
import { GEmpty } from '../../ui/GEmpty/GEmpty'
import { GLoading } from '../../ui/GLoading/GLoading'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import $classes from './BCardContainer.module.scss'

export const BCardContainer = defineComponent({
  name: 'BCardContainer',
  props: {
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: '',
    },
    titleTip: {
      type: String,
      default: '',
    },
    // 适配逻辑
    wrapHeader: {
      type: Boolean,
      default: false,
    },
    emptyMode: {
      type: Boolean,
      default: false,
    },
    emptyCls: {
      type: String,
      default: '',
    },
    mainLayout: {
      type: String as PropType<'full' | 'left-right'>,
      default: 'full',
    },
    border: {
      type: Boolean,
      default: true,
    },
    isDrawer: {
      type: Boolean,
      default: false,
    },
    drawMaxWidth: {
      type: Number,
    },
    drawerPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
  },
  setup() {
    const expanded = ref(true)

    return {
      expanded,
    }
  },
  created() {
    this.isRenderedDefault = false
  },
  methods: {
    renderTitle() {
      return <div domPropsInnerHTML={this.title} title={removeHTML(this.title)} class={`${$classes.title} ${this.titleClass}`}></div>
    },
    renderDesc() {
      if (!this.desc) return null
      return <div domPropsInnerHTML={this.desc} class={$classes.desc}></div>
    },
    renderHeader() {
      return [
        <div class={$classes.header}>
          <div class={$classes.headerLeft}>{this.renderTitle()}</div>
          {this.titleTip ? (
            <GTooltip class={$classes.headerTip} theme="light" mode="icon">
              <template slot="content">
                <div class="max-w-xs" domPropsInnerHTML={this.titleTip}></div>
              </template>
            </GTooltip>
          ) : null}
          {(this.$slots.headerMiddle && <div class={$classes.headerMiddle}>{this.$slots.headerMiddle}</div>) || null}
          {(this.$slots.headerRightAction && <div class={$classes.headerRight}>{this.$slots.headerRightAction}</div>) || null}
        </div>,
        this.renderDesc(),
        this.renderExtra(),
      ]
    },
    renderExtra() {
      return this.$slots.extra
    },
    renderEmpty() {
      this.isRenderedDefault = false
      return <div class={[$classes.empty, this.emptyCls]}>{this.$slots.empty || <GEmpty isChart={true} size={E_NODATA_SIZE.SMALL}></GEmpty>}</div>
    },
    renderMain() {
      this.isRenderedDefault = true

      return this.$slots.default
    },
    renderBody() {
      if (this.mainLayout === 'full') {
        if (this.isLoading && !this.isRenderedDefault) {
          return null
        }

        return [
          <div class={$classes.containerMain} v-show={this.isEmpty}>
            {this.renderEmpty()}
          </div>,
          <div class={$classes.containerMain} v-show={!this.isEmpty}>
            {this.renderMain()}
          </div>,
        ]
      }

      if (this.mainLayout === 'left-right') {
        if (this.isLoading && !this.isRenderedDefault) {
          return null
        }

        if (this.isDrawer) {
          return (
            <GDrawerLayout v-model={this.expanded} maxWidth={this.drawMaxWidth} drawerPosition={this.drawerPosition} drawerBngColor="#fff">
              <template slot="drawer">{this.$slots.right}</template>
              <template slot="default">
                <div class={$classes.containerMain}>
                  <div v-show={this.isEmpty} class={$classes.containerMainLeft}>
                    {this.renderEmpty()}
                  </div>
                  <div v-show={!this.isEmpty} class={$classes.containerMainLeft}>
                    {this.renderMain()}
                  </div>
                </div>
              </template>
            </GDrawerLayout>
          )
        }

        return (
          <div class={$classes.containerMain}>
            <div v-show={this.isEmpty} class={$classes.containerMainLeft}>
              {this.renderEmpty()}
            </div>
            <div v-show={!this.isEmpty} class={$classes.containerMainLeft}>
              {this.renderMain()}
            </div>
            <div class={$classes.containerLeftRight}>{this.$slots.right}</div>
          </div>
        )
      }
    },
  },
  render() {
    return (
      <div
        class={[
          $classes.container,
          this.mainLayout === 'left-right' ? $classes.containerLeftRight : '',
          this.emptyMode ? $classes.containerEmptyMode : '',
          this.border ? '' : $classes.containerNoBorder,
        ]}
      >
        {this.$slots.header ? (
          this.wrapHeader ? (
            <div class={$classes.containerHeader}>{this.$slots.header}</div>
          ) : (
            this.$slots.header
          )
        ) : (
          <div class={$classes.containerHeader}>{this.renderHeader()}</div>
        )}
        {this.$slots.subHeader ? <div class={$classes.containerSubHeader}>{this.$slots.subHeader}</div> : null}
        <div class={$classes.containerBody}>{this.renderBody()}</div>
        {this.isLoading && (
          <div class="loading-container absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white-default opacity-95 z-[2]">
            <GLoading></GLoading>
          </div>
        )}
      </div>
    )
  },
})
