import { computed } from '@vue/composition-api'
import { CombinedVueInstance } from 'vue/types/vue'
import { E_ROUTER_NAME } from '../types/enum'

export const useShowNav = (root: CombinedVueInstance<any, any, any, any, any>) => {
  const isShowNav = computed(() => {
    const noNavRoutes = [
      E_ROUTER_NAME.DRUG,
      E_ROUTER_NAME.TARGET,
      E_ROUTER_NAME.DISEASE,
      E_ROUTER_NAME.ORGANIZATION,
      E_ROUTER_NAME.DRUG_TYPE,
      E_ROUTER_NAME.CLINICAL_RESULT_DETAIL,
      E_ROUTER_NAME.HOME_QUICK_SEARCH_RESULT,
      E_ROUTER_NAME.EMAIL_ALERT_DETAIL,
    ]
    return !noNavRoutes.includes(root.$route.name as E_ROUTER_NAME)
  })

  // 页面的copilot弹窗是不占页面空间
  const isPopperCopilotDialog = computed(() => {
    const noNavRoutes = [
      E_ROUTER_NAME.DRUG,
      E_ROUTER_NAME.TARGET,
      E_ROUTER_NAME.DISEASE,
      E_ROUTER_NAME.ORGANIZATION,
      E_ROUTER_NAME.DRUG_TYPE,
      E_ROUTER_NAME.CLINICAL_RESULT_DETAIL,
      E_ROUTER_NAME.CLINICAL_PROGRESS_DETAIL,
    ]
    return !noNavRoutes.includes(root.$route.name as E_ROUTER_NAME)
  })
  return { isShowNav, isPopperCopilotDialog }
}
