import { EMPTY_PLACEHOLDER } from '@patsnap/synapse_common_config'
import {
  I18nLang,
  IBaseDiseaseInfo,
  IBaseDrugInfo,
  IBaseOrgInfo,
  ITransMedicineDetail,
  ITransMedicineEntityItem,
} from '@patsnap/synapse_common_interface'
import { DomainContext, translationalMedicineField } from '@patsnap/synapse_domain'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { ElementUIComponentSize } from 'element-ui/types/component'
import { IVueI18n } from 'vue-i18n'
import {
  BDiseaseItem,
  BDrugItem,
  BEntityTag,
  BOrganizationItem,
  BTargetItem,
  BUpgradeWrap,
  GLimitedContainer,
  GLink,
  GTooltip,
  GTranslate,
} from '../components'
import { sharedCtx } from '../context'
import { ElTag } from '../element-ui'
import { ITranslateLang } from '../types'
import { getRedirectLinkStatic, isOpenTranslate } from '../utils'
import { renderDrugTypeAsync, renderPopoverLimited, renderPopoverLimitedMechanismAsync } from './baseRender'

export function renderViewOutcomeMeasuresTag(ctResultId = '', $i18n: IVueI18n) {
  if (!ctResultId) return null

  return (
    <BUpgradeWrap class="text-[0]" updateType={E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL}>
      <GLink href={sharedCtx.router.generatorClinicalResultDetailPath(ctResultId, 'outcome_measures')} newTab={true}>
        <ElTag class="b-phase-tag cursor-pointer hover:bg-gray-20" size="mini" disable-transitions={true} effect="plain">
          <span class="font-normal text-xs text-blue-clicked">{$i18n.t('synapse_i18n.translational_medicine.viewClinicalResult')}</span>
        </ElTag>
      </GLink>
    </BUpgradeWrap>
  )
}

export function renderTransMedicineTitle(data: ITransMedicineDetail, $i18n: IVueI18n, customSettingTranslation?: ITranslateLang) {
  const ctx: DomainContext = { locale: $i18n.locale as I18nLang }
  const title = translationalMedicineField.extractTransMedicineTitle(ctx, data)

  const isTranslate = isOpenTranslate(data.title_translation, customSettingTranslation)
  const translateTitle = customSettingTranslation
    ? translationalMedicineField.extractTransMedicineTranslateTitle({ locale: customSettingTranslation.toLocaleLowerCase() as I18nLang }, data)
    : ''
  const executeTranslate = translateTitle ? isTranslate : false
  const sourceUrl = data.source_url

  return [
    <GTranslate execute={executeTranslate} content={translateTitle}>
      <GLink truncate={false} href={sourceUrl ? getRedirectLinkStatic(sourceUrl, ctx.locale) : ''} name={title || EMPTY_PLACEHOLDER}></GLink>
    </GTranslate>,
    <div class={{ 'mt-2': data.ct_result_id }}>{renderViewOutcomeMeasuresTag(data.ct_result_id, $i18n)}</div>,
  ]
}

export function renderTransMedicineHighlight(
  data: ITransMedicineDetail,
  $i18n: IVueI18n,
  isTooltip = false,
  limitedContainerOptions?: { limitLines?: number; moreText?: string },
  placeHolder = EMPTY_PLACEHOLDER
) {
  const ctx: DomainContext = { locale: $i18n.locale as I18nLang }
  const highlightText = translationalMedicineField.extractTransMedicineSummary(ctx, data)

  if (highlightText) {
    const { limitLines = 4, moreText = '' } = limitedContainerOptions || {}
    return (
      <GLimitedContainer lineHeight={20} limitLines={limitLines} moreText={moreText}>
        <div class="leading-5 text-sm" domPropsInnerHTML={highlightText}></div>
        {isTooltip && (
          <GTooltip slot="customMore" theme="light" placement="right-end">
            <div>{$i18n.t('common.showAll')}</div>
            <div slot="content" class="leading-5 text-sm max-h-48 overflow-y-auto" domPropsInnerHTML={highlightText}></div>
          </GTooltip>
        )}
      </GLimitedContainer>
    )
  }
  return placeHolder
}

export function renderTransMedicineSubject(data: ITransMedicineDetail, $i18n: IVueI18n, size: ElementUIComponentSize = 'small') {
  if (data.subject?.length) {
    const ctx: DomainContext = { locale: $i18n.locale as I18nLang }

    return (
      <div class="flex items-center gap-1">
        {data.subject.map((item) => {
          const name = translationalMedicineField.extractTransMedicineDictName(ctx, item)
          return (
            <ElTag class="max-w-full overflow-hidden truncate" size={size} title={name} color="#E3F1DE" style="color: #1D8820;border: none">
              {name}
            </ElTag>
          )
        })}
      </div>
    )
  }
  return EMPTY_PLACEHOLDER
}

export function renderTransMedicineJournalOrMeetings(data: Pick<ITransMedicineDetail, 'journal_id_view' | 'meeting_id_view'>, $i18n: IVueI18n) {
  const ctx: DomainContext = { locale: $i18n.locale as I18nLang }
  const journal = translationalMedicineField.extractTransMedicineJournal(ctx, data)

  if (journal) return journal
  return EMPTY_PLACEHOLDER
}

export function renderTransMedicinePublicationDate(data: ITransMedicineDetail, $i18n: IVueI18n) {
  if (!data.pub_dt) return EMPTY_PLACEHOLDER
  return <div class="text-sm">{translationalMedicineField.extractTransMedicinePublicationDate({ locale: $i18n.locale as I18nLang }, data)}</div>
}

function _renderPopoverLimitedEntity(
  items: Array<ITransMedicineEntityItem<IBaseDrugInfo | IBaseOrgInfo | IBaseDiseaseInfo>>,
  type: 'drug' | 'organization' | 'target' | 'disease',
  limitCount: number,
  popover = true
) {
  if (!items.length) {
    return EMPTY_PLACEHOLDER
  }
  return renderPopoverLimited(items, {
    limitCount,
    render: (item) => {
      if (item.normalized_id_view) {
        switch (type) {
          case 'drug':
            return <BDrugItem data={item.normalized_id_view as IBaseDrugInfo} showCard={popover}></BDrugItem>
          case 'organization':
            return <BOrganizationItem data={item.normalized_id_view as IBaseOrgInfo}></BOrganizationItem>
          case 'disease':
            return <BDiseaseItem data={item.normalized_id_view as IBaseDiseaseInfo}></BDiseaseItem>
        }
      }

      return (
        <BEntityTag title={item.name} class="overflow-hidden">
          <span class="max-w-full truncate">{item.name}</span>
        </BEntityTag>
      )
    },
  })
}

export function renderTransMedicineDrug(data: ITransMedicineDetail, limitCount = 3) {
  return _renderPopoverLimitedEntity(data.drug || [], 'drug', limitCount)
}

export function renderTransMedicineTarget(data: ITransMedicineDetail, limitCount = 3) {
  if (data.multi_target_id_view?.length) {
    return renderPopoverLimited(data.multi_target_id_view, {
      limitCount,
      render: (item) => <BTargetItem data={item}></BTargetItem>,
    })
  }
  return EMPTY_PLACEHOLDER
}

export function renderTransMedicineDisease(data: ITransMedicineDetail, limitCount = 3) {
  return _renderPopoverLimitedEntity(data.disease || [], 'disease', limitCount)
}

export function renderTransMedicineOrganization(data: ITransMedicineDetail, limitCount = 3) {
  return _renderPopoverLimitedEntity(data.research_sponsor || [], 'organization', limitCount)
}

export function renderTransMedicineDrugType(data: ITransMedicineDetail, limitCount = 3) {
  return renderDrugTypeAsync(data.drug_type_id_view || [], data.drug_type_id, limitCount)
}

export function renderTransMedicineMechanism(data: ITransMedicineDetail, limitCount = 3) {
  return renderPopoverLimitedMechanismAsync(data.mechanism_action_id_view || [], data.mechanism_action_id, limitCount)
}

export function renderTransMedicineTechnology(data: ITransMedicineDetail, $i18n: IVueI18n, limitCount = 3) {
  if (data.technology?.length) {
    const ctx: DomainContext = { locale: $i18n.locale as I18nLang }
    return renderPopoverLimited(data.technology, {
      limitCount,
      render: (item) => {
        const technologyName = translationalMedicineField.extractTransMedicineDictName(ctx, item)
        return (
          <BEntityTag>
            <div class="max-w-full truncate" title={technologyName}>
              {technologyName}
            </div>
          </BEntityTag>
        )
      },
    })
  }
  return EMPTY_PLACEHOLDER
}
