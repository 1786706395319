import { defineComponent } from '@vue/composition-api'
import { GDialog } from '../../ui/GDialog/GDialog'
import { BWechatBindCode } from '../BWechatBindCode/BWechatBindCode'
import $style from './BWeChatBindDialog.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BWeChatBindDialog = defineComponent({
  name: 'BWeChatBindDialog',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    visible: {
      required: true,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
  emits: ['success', 'close'],
  setup(props, ctx) {
    function onSuccess() {
      ctx.emit('success')
    }
    function closeWechatDialog() {
      ctx.emit('close')
    }
    return { closeWechatDialog, onSuccess }
  },
  render() {
    return (
      <GDialog
        append-to-body={true}
        class={$style.wechatBody}
        onclose={this.closeWechatDialog}
        visible={this.visible}
        title={this.title || this.$tc('BWeChatBindDialog.syncWechat')}
        width="400px"
        showFooter={false}
      >
        <div class={$style.wechatCodeContainer}>{this.visible && <BWechatBindCode onSuccess={this.onSuccess}></BWechatBindCode>}</div>
        <div class={$style.wechatMsg}>{this.desc || this.$t('BWeChatBindDialog.scanQR')}</div>
      </GDialog>
    )
  },
})
