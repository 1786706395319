import type { IAggregationData } from '@patsnap/synapse_common_interface'
import { decryptOverviewData, handleTrend } from '@pharmsnap/shared/utils'
import { injectOrgName } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformWidgetAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/dashboard'
import {
  IBaseAnalysisTrendData,
  IBaseEntityAnalysisOverview,
  IDashboardItemInfo,
  IDashboardUpdateParams,
  IDashboardWidgetCreateParams,
  IDashboardWidgetItemInfo,
  IDashboardWidgetUpdateParams,
  IDashboardWithWidgetItemInfo,
  ILang,
  ISearchResult,
} from '../../types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DashboardApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async createOrUpdate(params: IDashboardUpdateParams): Promise<IHttpWrap<IDashboardWithWidgetItemInfo>> {
    const rt = await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard'),
      data: params,
    }) as Promise<IHttpWrap<IDashboardWithWidgetItemInfo>>)

    return rt
  }

  async copy(params: IDashboardUpdateParams): Promise<IHttpWrap<IDashboardItemInfo>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/copy'),
      data: params,
    })
  }

  async search(params: { offset: number; limit: number; keyword: string; language: ILang }): Promise<IHttpWrap<ISearchResult<IDashboardItemInfo>>> {
    return this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/search'),
      data: params,
    })
  }

  async list(params: { keyword: string; type: 'report_demo' | 'template'; language: ILang }): Promise<IHttpWrap<ISearchResult<IDashboardItemInfo>>> {
    const rt = await this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/list'),
      data: params,
    })
    if (rt.success && rt.data) {
      ;(rt.data.items || []).forEach((item: IDashboardItemInfo) => {
        if (item.type === 'report_demo') {
          if (item.overview_data) {
            decryptOverviewData(item.overview_data, rt.xCorrelationId)
          }
          if (item.trend_data) {
            handleTrend(item.trend_data, rt.xCorrelationId)
          }
        }
      })
    }
    return rt
  }

  async delete(id: string): Promise<IHttpWrap<void>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl(`pharmsnap_webapi/1.0/dashboard/${id}`),
    })
  }

  async getDetail(id: string, language: ILang): Promise<IHttpWrap<IDashboardWithWidgetItemInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/dashboard/${id}`),
      params: {
        language,
      },
    })
  }

  private handleEncryptData(rt: IHttpWrap<IDashboardWidgetItemInfo>) {
    if (rt.success && rt.data) {
      if (rt.data.data) {
        if (rt.data.type === 'bar' || rt.data.type === 'heatmap' || rt.data.type === 'world' || rt.data.type === 'line_bar') {
          const aggData = rt.data.data as IAggregationData
          if (aggData.aggregation_result) {
            aggData.aggregation_result = aggData.aggregation_result.map((item) => transformWidgetAggregationSingleResult(item, rt.xCorrelationId))
          }
        } else if (rt.data.type === 'overview') {
          const overviewData = rt.data.data as IBaseEntityAnalysisOverview
          if (overviewData) {
            decryptOverviewData(overviewData, rt.xCorrelationId)
          }
        } else if (rt.data.type === 'trend') {
          const trendData = rt.data.data as IBaseAnalysisTrendData
          if (trendData) {
            handleTrend(trendData, rt.xCorrelationId)
          }
        }
      }
    }
    return rt
  }

  async createWidget(params: IDashboardWidgetCreateParams): Promise<IHttpWrap<IDashboardWidgetItemInfo>> {
    const rt = await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/widget'),
      data: params,
    }) as Promise<IHttpWrap<IDashboardWidgetItemInfo>>)
    if (rt.success && rt.data.data && 'aggregation_result' in rt.data.data) {
      ;(rt.data.data as IAggregationData).aggregation_result.forEach((item) => {
        item.items.forEach((item) => {
          injectOrgName(item)
        })
      })
    }
    return this.handleEncryptData(rt)
  }

  async updateWidget(params: IDashboardWidgetUpdateParams): Promise<IHttpWrap<IDashboardWidgetItemInfo>> {
    const rt = await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/widget'),
      data: params,
    }) as Promise<IHttpWrap<IDashboardWidgetItemInfo>>)

    return this.handleEncryptData(rt)
  }

  async deleteWidget(id: string): Promise<IHttpWrap<void>> {
    return this.http({
      method: 'DELETE',
      url: this.getUrl(`pharmsnap_webapi/1.0/dashboard/widget/${id}`),
    })
  }

  async getWidget(widgetId: string, language: ILang): Promise<IHttpWrap<IDashboardWidgetItemInfo>> {
    const rt = await (this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/dashboard/widget/${widgetId}`),
      params: { language },
      // cache: true,
    }) as Promise<IHttpWrap<IDashboardWidgetItemInfo>>)
    if (rt.success && rt.data.data && 'aggregation_result' in rt.data.data) {
      ;(rt.data.data as IAggregationData).aggregation_result.forEach((item) => {
        item.items.forEach((item) => {
          injectOrgName(item)
        })
      })
    }
    return this.handleEncryptData(rt)
  }

  async updateWidgetSequence(widgetList: { widget_id: string; sequence: number }[]) {
    await (this.http({
      method: 'POST',
      url: this.getUrl('pharmsnap_webapi/1.0/dashboard/widget/sequence'),
      data: widgetList,
    }) as Promise<IHttpWrap<void>>)
  }
}
