import { defineComponent, PropType } from '@vue/composition-api'
import VClickOutside from 'v-click-outside'
import { GCountryFlag } from '../../ui/GCountryFlag/GCountryFlag'
import { BFamilyTagPopper } from './BFamilyTagPopper'

export const BFamilyTag = defineComponent({
  name: 'BFamilyTag',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    country: {
      required: true,
      type: String,
    },
    count: {
      required: true,
      type: Number,
    },
    family: {
      required: true,
      type: String,
    },
    familyType: {
      required: true,
      type: String as PropType<'simple' | 'inpadoc'>,
    },
    showCountryFlag: {
      type: Boolean,
      default: true,
    },
    showBrackets: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    return {}
  },
  render() {
    return (
      <div>
        {this.showCountryFlag && <GCountryFlag name={this.country} showName={true} useFullName></GCountryFlag>}
        <BFamilyTagPopper country={this.country} family={this.family} familyType={this.familyType}>
          <span class="text-sm text-blue-default cursor-pointer">{this.showBrackets ? `(${this.count})` : this.count}</span>
        </BFamilyTagPopper>
      </div>
    )
  },
})
