import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore } from '@pharmsnap/shared/composition/useAuthStore'
import { ElCheckbox } from '@pharmsnap/shared/element-ui'
import { IAutoCompleteType, IBAcTag, IBrowseCountType, IBrowseType, IListItem, ISelectedLabel } from '@pharmsnap/shared/types'
import { PropType, defineComponent, getCurrentInstance, reactive, ref, toRefs } from '@vue/composition-api'
import '../../../assets/icon-svg/info.svg'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BBrowseNew } from '../BBrowse/BBrowseNew'
import $classes from './BLableAc.module.scss'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BLabelAc = defineComponent({
  name: 'BLabelAc',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    /**
     * 前面的label
     */
    label: {
      type: String,
    },
    /**
     * label tooltip内容
     */
    infoContent: {
      type: String,
    },
    /**
     * label上的tooltip
     */
    mode: {
      type: String as PropType<'text' | 'icon'>,
      default: 'icon',
    },
    /**
     * 构造browse的参数
     */
    type: {
      type: String as PropType<IBrowseType | IAutoCompleteType>,
    },
    /**
     * 显示browse
     */
    showBrowse: {
      type: Boolean,
      default: false,
    },
    /**
     * 剩余个数
     */
    leftNum: {
      type: Number,
    },
    /**
     * 机翻switch
     */
    showSwitch: {
      type: Boolean,
      default: false,
    },
    /**
     * 机翻switch val
     */
    switchVal: {
      type: Boolean,
      default: false,
    },
    /**
     * 机翻的tooltip
     */
    switchInfo: {
      type: String,
    },
    /**
     * tags会传到browse里面，一般有browse才需要传
     */
    tags: {
      type: Array as PropType<IBAcTag[]>,
      default: () => [],
    },
    /**
     * browse的自定义文案，不根据type自动生成
     */
    browseLabel: {
      type: String,
    },
    /**
     * browse的数量类型，可展示药物数量、专利数量
     */
    browseCountType: {
      type: String as PropType<IBrowseCountType>,
      default: 'drug',
    },
    /**
     * 自定义label container样式
     */
    labelContainerClass: {
      type: String,
      default: '',
    },
    viewDetailLabel: {
      type: String,
    },
    auth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const ins = getCurrentInstance()
    const data = reactive({
      browseVisible: false,
    })

    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()
    const windowHeight = window.innerHeight
    const headerHeight = 64
    const browseDialogHeight = 480
    // 是否在按钮上方展示弹窗
    const showOnTop = ref(false)
    const browseBtnRef = ref()
    const toggleBrowse = async () => {
      if (props.auth) {
        if (isFreeUser.value) {
          changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'CLICK_BROWSE' })
          return
        }
      }
      showOnTop.value = getShowOnTop()
      const labelAc = ins?.proxy.$refs.labelAC as HTMLDivElement
      data.browseVisible = !data.browseVisible
      if (data.browseVisible) {
        const target = document.getElementById(`browse_${props.type}`)
        const drugFinder = document.getElementById(`drugFinder`)
        const clinicalFinder = document.getElementById(`clinicalFinder`)
        const clinicalResultFinder = document.getElementById('clinicalResultFinder')
        const patentFinder = document.getElementById(`patentFinder`)
        const literatureFinder = document.getElementById(`literatureFinder`)
        const drugDealFinder = document.getElementById(`drugDealFinder`)
        const translationalMedicineFinder = document.getElementById(`translationalMedicineFinder`)
        const previewBox = document.getElementById(`preview`)
        if (target && previewBox) {
          ;[drugFinder, patentFinder, literatureFinder, clinicalFinder, clinicalResultFinder, drugDealFinder, translationalMedicineFinder]
            .filter(Boolean)
            .forEach((item) => {
              if (item) {
                const scrollOption: ScrollToOptions = {
                  behavior: 'smooth',
                  top: showOnTop.value
                    ? labelAc.offsetTop - (item?.offsetHeight - previewBox.offsetHeight - labelAc.offsetHeight)
                    : labelAc.offsetTop,
                }
                item?.scrollTo(scrollOption)
              }
            })
        }
      }
    }
    function getShowOnTop() {
      const browseBtnRect = browseBtnRef.value.getBoundingClientRect()
      if (browseBtnRect) {
        // 是否在屏幕下半部分
        const isBtnInBottomHalf = browseBtnRect.top + browseBtnRect.height / 2 > windowHeight / 2
        // 是否有足够的空间展示
        const hasEnoughSpace = browseBtnRect.top - headerHeight > browseDialogHeight
        return isBtnInBottomHalf && hasEnoughSpace
      }
      // 兜底展示在下方
      return false
    }
    const closeBrowse = () => {
      data.browseVisible = false
    }

    const handleSubmit = (list: ISelectedLabel[]) => {
      emit('browseSubmit', list)
    }

    const handleMachineTrans = () => {
      emit('includeTrans')
    }

    function handleViewDetail(item: IListItem) {
      emit('viewDetail', item)
    }

    return { toggleBrowse, closeBrowse, handleSubmit, ...toRefs(data), handleMachineTrans, handleViewDetail, browseBtnRef, showOnTop }
  },
  methods: {
    renderToolTip() {
      return (
        <GTooltip theme="light" mode="icon" data-testid="b-label-ac__tooltip" class={$classes.icon}>
          <template slot="content">
            <div domPropsInnerHTML={this.infoContent}></div>
          </template>
        </GTooltip>
      )
    },
    renderBrowse() {
      return this.type && this.showBrowse ? (
        <div class={$classes.browseContainer}>
          <button
            ref="browseBtnRef"
            class={['pt-ui-btn', this.browseVisible ? $classes.browseOpenBtnActive : $classes.browseOpenBtn]}
            data-testid={['b-label-ac__browse-btn']}
            type="button"
            data-type="default"
            onClick={this.toggleBrowse}
          >
            {this.browseLabel ?? this.$t(`AcLabel.${this.type}`)}
          </button>
          {this.browseVisible ? (
            <BBrowseNew
              showOnTop={this.showOnTop}
              type={this.type as IBrowseType}
              title={this.browseLabel ?? (this.$t(`AcLabel.${this.type}`) as string)}
              visible={this.browseVisible}
              onCloseBrowse={this.closeBrowse}
              onBrowseSubmit={this.handleSubmit}
              leftNum={this.leftNum}
              tags={this.tags}
              countType={this.browseCountType}
              viewDetailLabel={this.viewDetailLabel}
              onViewDetail={(item: IListItem) => this.handleViewDetail(item)}
            ></BBrowseNew>
          ) : null}
        </div>
      ) : null
    },
    renderCheckbox() {
      return (
        <div class={$classes.checkboxContainer}>
          <ElCheckbox value={this.switchVal} onChange={this.handleMachineTrans}>
            {this.$t('common.includeTranslation')}
          </ElCheckbox>
        </div>
      )
    },
    renderSwitch() {
      return this.showSwitch ? (
        <div class={$classes.switchContainer}>
          {this.switchInfo ? (
            <GTooltip theme="light">
              <template slot="content">
                <div domPropsInnerHTML={this.switchInfo}></div>
              </template>
              {this.renderCheckbox()}
            </GTooltip>
          ) : (
            this.renderCheckbox()
          )}
        </div>
      ) : null
    },
  },
  render() {
    return (
      <div
        class={[$classes.labelAcContainer, this.labelContainerClass]}
        ref="labelAC"
        data-testid={this.type?.toLocaleLowerCase() ? `b-label-ac--${this.type?.toLocaleLowerCase()}` : ''}
      >
        {this.label ? (
          <div class={$classes.label}>
            <div class="truncate" data-testid="b-label-ac__label">
              {this.label}
            </div>
            {this.infoContent && this.renderToolTip()}
          </div>
        ) : null}
        {this.$slots.ac}
        {this.renderBrowse()}
        {this.renderSwitch()}
        {this.$slots.customLabelSuffix}
      </div>
    )
  },
})
