import { DEFAULT_ANGLE_REVERSED } from '@patsnap/synapse_bullseye_chart'
import { BULLS_EYE_CHART_DEV_STATUS_CONFIG, BULLS_EYE_CHART_DRUG_TYPE, INACTIVE_DEV_STATUS_IDS } from '@patsnap/synapse_common_config'
import { I18nLang, IOneDimensionAggValue, IQueryItemField, ITwoDimensionAggData, ITwoDimensionAggValue } from '@patsnap/synapse_common_interface'
import { cloneDeep, groupBy, intersectionWith, orderBy, partition, sortBy, uniq, uniqBy } from 'lodash'
import { BullsEyeSetting } from '../../features/bulls-eye-setting'
import {
  IBullsEyeChartAggOtherInfo,
  IBullsEyeChartAngleItemWithOtherInfo,
  IBullsEyeChartItemWithOriginData,
  IBullsEyeChartStatInfoCommon,
  ILang,
} from '../../types'
import { isInactivePhase } from '../common'
import { getDicItemName, getDisplayNameDegraded } from '../lang'
import { getInactiveDrugDevStatusField } from '../navList'

/**
 * 获取勾选的phase，默认phase无数据时，会显示其他阶段的数据
 */
export const getBullsEyeChartSelectedPhaseIds = (data: ITwoDimensionAggData) => {
  const defaultPhaseIds = BullsEyeSetting.getSingleton().phaseIds
  if (data.aggregation_result?.length && data.aggregation_result[0]?.items?.length) {
    const aggItems = data.aggregation_result[0].items
    const phaseIds = aggItems.reduce((prev, cur) => {
      const curPhaseIds = cur.aggregation_result?.[0]?.items?.map((item) => item.key) || []
      prev = uniq([...prev, ...curPhaseIds])
      return prev
    }, [] as string[])

    const [defaultShowPhaseIds, otherPhaseIds] = partition(phaseIds, (id) => defaultPhaseIds.includes(id))

    if (defaultShowPhaseIds.length) return defaultPhaseIds

    if (otherPhaseIds.length) {
      const [inactivePhaseIds, activePhaseIds] = partition(otherPhaseIds, (item) => isInactivePhase(item))
      if (inactivePhaseIds.length) {
        activePhaseIds.push('-1')
      }
      return defaultPhaseIds.concat(activePhaseIds)
    }
  }
  return defaultPhaseIds
}

/** 获取指定phaseIds的数据 */
export function getSpecifiedPhasesData<T extends ITwoDimensionAggData>(data: T, phaseIds: string[] = []) {
  const newData = cloneDeep(data)
  if (newData?.aggregation_result?.length && newData?.aggregation_result[0]?.items?.length) {
    newData.aggregation_result[0].items.forEach((item) => {
      if (item.aggregation_result?.[0]?.items?.length) {
        item.aggregation_result[0].items = item.aggregation_result[0].items.filter((subItem) => {
          // 存在非在研
          if (phaseIds.includes('-1')) {
            // 竞争格局非在研：后端使用的key为-1，药物搜索列表页：后端使用的INACTIVE_DEV_STATUS_IDS
            return [...INACTIVE_DEV_STATUS_IDS, ...phaseIds].includes(subItem.key)
          }
          return phaseIds.includes(subItem.key)
        })
      }
    })

    newData.aggregation_result[0].items = newData.aggregation_result[0].items.filter((item) => item.aggregation_result?.[0]?.items?.length)
  }
  return newData
}

export const getBullsEyeChartPhaseOptions = (localeUpcase: ILang) => {
  return BULLS_EYE_CHART_DEV_STATUS_CONFIG.map((phase) => ({
    name: getDicItemName(phase, localeUpcase),
    id: phase.dict_id,
  }))
}

export const getBullsEyeChartOrderedPhase = (phaseIds: string[] = [], localeUpcase: ILang) => {
  const orderedPhase = orderBy(BULLS_EYE_CHART_DEV_STATUS_CONFIG, (i) => i.rank, 'desc')
  return intersectionWith(orderedPhase, phaseIds, (item, id) => item.dict_id === id).map((phase) => getDicItemName(phase, localeUpcase))
}

export const getBullsEyeChartOriginalPhaseIds = (phaseIds: string[]) => {
  const newPhaseIds = cloneDeep(phaseIds)
  const inactivePhaseIndex = newPhaseIds.findIndex((id) => id === '-1')
  if (inactivePhaseIndex > -1) {
    newPhaseIds.splice(inactivePhaseIndex, 1, ...INACTIVE_DEV_STATUS_IDS)
  }
  return newPhaseIds
}

export const getMergedDrugTypeList = (drugTypeList: IBullsEyeChartStatInfoCommon[]) => {
  // 药物类型分组：相同药物类型一组
  const groupByDrugType = groupBy(drugTypeList, 'id')

  const mergedDrugType = Object.values(groupByDrugType).map((drugTypeGroup) => {
    // 相同的药物类型
    return drugTypeGroup.reduce((pre, cur) => {
      // 合并drug_id
      pre.related_drug_id = uniq([...(pre.related_drug_id || []), ...(cur.related_drug_id || [])])
      // 更新count
      pre.count = pre.related_drug_id.length
      return pre
    })
  })

  return mergedDrugType
}

export function mergeData(
  originData: IOneDimensionAggValue<IBullsEyeChartAggOtherInfo>[],
  newDataInfo: { key: string; display_name_cn: string; display_name_en: string }
): IOneDimensionAggValue<IBullsEyeChartAggOtherInfo> | undefined {
  if (originData.length) {
    // 合并非在研数据
    const mergedInactiveData = originData.reduce((prev, cur) => {
      prev.other_info = {
        related_drug_id: [...(prev.other_info?.related_drug_id || []), ...(cur.other_info?.related_drug_id || [])],
        related_drug: [...(prev.other_info?.related_drug || []), ...(cur.other_info?.related_drug || [])],
        stat_info: {
          drug_type: [...(prev.other_info?.stat_info?.drug_type || []), ...(cur.other_info?.stat_info?.drug_type || [])],
        },
      }
      return prev
    }, {} as IOneDimensionAggValue<IBullsEyeChartAggOtherInfo>)
    // 数据去重
    const mergedRelatedDrugId = uniq(mergedInactiveData.other_info?.related_drug_id || [])
    const mergedRelatedDrug = uniqBy(mergedInactiveData.other_info?.related_drug || [], 'id')

    const mergedDrugType = getMergedDrugTypeList(mergedInactiveData.other_info?.stat_info?.drug_type || [])

    return {
      ...mergedInactiveData,
      key: newDataInfo.key,
      display_name_en: newDataInfo.display_name_en,
      display_name_cn: newDataInfo.display_name_cn,
      count: mergedDrugType.reduce((prev, cur) => prev + cur.count, 0) || mergedRelatedDrugId.length,
      other_info: {
        related_drug: mergedRelatedDrug,
        stat_info: {
          drug_type: mergedDrugType,
        },
        related_drug_id: mergedRelatedDrugId,
      },
    }
  }
}

/**
 * 合并非在研数据
 */
export function mergeBullsEyeChartInactiveData(data: IOneDimensionAggValue<IBullsEyeChartAggOtherInfo>[]) {
  const [inactiveData, activeData] = partition(data, (item) => isInactivePhase(item.key))
  const mergedInactiveData = mergeData(inactiveData, { key: '-1', display_name_cn: '非在研', display_name_en: 'Inactive' })
  if (mergedInactiveData) {
    activeData.unshift(mergedInactiveData)
  }
  return activeData
}

export function getBullsEyeChartAngleData(
  data: IOneDimensionAggValue<IBullsEyeChartAggOtherInfo>[],
  aggField: string,
  locale: I18nLang
): IBullsEyeChartAngleItemWithOtherInfo[] {
  if (data.length) {
    return data.map((item) => {
      // 保证颜色排列顺序一致
      const otherInfo = cloneDeep(item.other_info)
      const sortedRelatedDrug = sortBy(otherInfo?.related_drug, (drug) => BULLS_EYE_CHART_DRUG_TYPE.findIndex((item) => item === drug.drug_type?.id))
      const sortedDrugType = sortBy(otherInfo?.stat_info?.drug_type, (drugType) =>
        BULLS_EYE_CHART_DRUG_TYPE.findIndex((item) => item === drugType.id)
      )
      return {
        radiusAxisName: getDisplayNameDegraded(item, locale) || '',
        radiusAxisId: item.key,
        _radiusAxisMeta: {
          aggregation_field: aggField,
          id: item.key,
          display_name_cn: item.display_name_cn,
          display_name_en: item.display_name_en,
        },
        count: item.count,
        relatedMarkers:
          sortedRelatedDrug?.map((relatedDrug) => ({
            id: relatedDrug.id,
            name: getDisplayNameDegraded(relatedDrug, locale) || '',
            colorItem: {
              id: relatedDrug.drug_type.id,
              name: getDisplayNameDegraded(relatedDrug.drug_type, locale) || '',
            },
          })) || [],
        other_info: {
          ...(otherInfo || {}),
          related_drug: sortedRelatedDrug,
          related_drug_id: otherInfo?.related_drug_id || [],
          stat_info: {
            drug_type: sortedDrugType,
          },
        },
      }
    })
  }
  return []
}

export function filterBullsEyeChartData(filterItems: string[], data: IBullsEyeChartAngleItemWithOtherInfo[]): IBullsEyeChartAngleItemWithOtherInfo[] {
  return data.map((item) => {
    const relatedMarkers =
      item.relatedMarkers?.filter((marker) => {
        const markerId = marker.colorItem?.id || ''
        return !filterItems.includes(markerId)
      }) || []

    const otherInfoRelatedDrug =
      item.other_info?.related_drug?.filter((drug) => {
        const markerId = drug.drug_type.id
        return !filterItems.includes(markerId)
      }) || []

    item.other_info?.stat_info.drug_type.forEach((drugType) => {
      if (filterItems.includes(drugType.id)) {
        drugType.count = 0
        drugType.related_drug_id = []
      }
    })

    const otherInfoDrugType = item.other_info?.stat_info.drug_type.filter((drugType) => drugType.count > 0) || []

    const otherInfoRelatedDrugId = otherInfoDrugType.reduce((prev, cur) => {
      prev = uniq([...prev, ...cur.related_drug_id])
      return prev
    }, [] as string[])

    const count = otherInfoDrugType.reduce((prev, cur) => prev + cur.count, 0) || otherInfoRelatedDrugId?.length || 0

    return {
      ...item,
      relatedMarkers,
      count,
      other_info: {
        ...item.other_info,
        related_drug: otherInfoRelatedDrug,
        stat_info: {
          drug_type: otherInfoDrugType,
        },
        related_drug_id: otherInfoRelatedDrugId,
      },
    }
  })
}

export function calculateBullsEyeChartWeight(data: IOneDimensionAggValue<IBullsEyeChartAggOtherInfo>[]): number {
  if (data.length) {
    return data.reduce((prev, cur) => {
      const phaseId = isInactivePhase(cur.key) ? '-1' : cur.key
      const phase = BULLS_EYE_CHART_DEV_STATUS_CONFIG.find((item) => item.dict_id === phaseId)
      if (phase?.rank) {
        prev += phase?.rank * cur.count
      } else {
        console.error(`phaseId: ${phaseId} not found`)
      }

      return prev
    }, 0)
  }
  return 0
}

/**
 * @param weights 原始权重
 * @param minAngle 最小角度
 * @param reversedAngle 预留角度
 * @returns 调整后的权重
 */
export function adjustWeights(weights: number[], minAngle = 10, reversedAngle = DEFAULT_ANGLE_REVERSED) {
  // 计算权重总和
  const totalWeight = weights.reduce((sum, weight) => sum + weight, 0)

  const totalAngle = 360 - reversedAngle

  // 计算每个权重对应的角度
  const angles = weights.map((weight) => Math.max(Math.round((weight / totalWeight) * totalAngle), minAngle))

  // 计算每个扇形的新权重，并进行四舍五入
  const adjustedWeights = angles.map((angle) => Math.round((angle / totalAngle) * totalWeight))

  return adjustedWeights
}

export function getBullsEyeChartWeight(data: ITwoDimensionAggValue<IBullsEyeChartAggOtherInfo>[]) {
  const weights = data.map((item) => {
    const subAggItems = item.aggregation_result?.[0]?.items || []
    return calculateBullsEyeChartWeight(subAggItems)
  })
  return adjustWeights(weights, 15)
}

export function getBullsEyeChartCustomQuery(data: IBullsEyeChartItemWithOriginData): {
  xAxisQuery: IQueryItemField
  yAxisQuery: IQueryItemField
} {
  return {
    xAxisQuery: {
      type: 'field',
      fields: [data._angleAxisMeta.aggregation_field],
      value: [
        {
          type: 'text',
          value: data._angleAxisMeta.id,
          display_name_cn: data._angleAxisMeta.display_name_cn || data._angleAxisMeta.display_name_en || '',
          display_name_en: data._angleAxisMeta.display_name_en || '',
        },
      ],
    },
    yAxisQuery:
      data._radiusAxisMeta.id === '-1'
        ? getInactiveDrugDevStatusField(data._radiusAxisMeta.aggregation_field)
        : ({
            type: 'field',
            fields: [data._radiusAxisMeta.aggregation_field],
            value: [
              {
                type: 'text',
                value: data._radiusAxisMeta.id,
                display_name_cn: data._radiusAxisMeta.display_name_cn || data._radiusAxisMeta.display_name_en || '',
                display_name_en: data._radiusAxisMeta.display_name_en || '',
              },
            ],
          } as IQueryItemField),
  }
}
