import { BOrganizationItemWithToolTip } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IBaseOrgWithItemInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BOrganizationWithToolTipFetchListCard = defineComponent({
  name: 'BOrganizationWithToolTipFetchListCard',
  props: {
    data: {
      type: Array as PropType<IBaseOrgWithItemInfo[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const ids = props.data.map((o) => o.org_id)
    const { loading, data: orgData } = useRequest(
      {
        requestFn: sharedCtx.service.organization.getBatchOrg.bind(sharedCtx.service.organization),
      },
      ids
    )

    watch(loading, () => {
      ctx.emit('update')
    })

    const combineData = computed(() => {
      return props.data.map((item) => {
        const view = orgData.value?.find((o) => o.entity_id === item.org_id)
        return {
          ...item,
          org_id_view: view,
        }
      })
    })

    return {
      loading,
      combineData,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div class="flex flex-wrap">
          {this.combineData?.map((item) => {
            return <BOrganizationItemWithToolTip class="mr-1 mb-1" data={item}></BOrganizationItemWithToolTip>
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
