import { defineComponent, toRefs } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { useEnableMultiplePopper } from '../useEnableMultiplePopper'
import { BOrganizationFetchCard } from './BOrganizationFetchCard'

export const BOrganizationPopperCard = defineComponent({
  name: 'BOrganizationPopperCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    zIndex: Number,
    placement: String,
  },
  setup(props) {
    const { tippyConfig } = useEnableMultiplePopper(toRefs(props).multiple, props.zIndex, props.placement as any)
    return {
      tippyConfig,
    }
  },
  render() {
    return (
      <PtPopper data={{ id: this.id }} tippyConfig={this.tippyConfig} component={BOrganizationFetchCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
