import { ITransMedicineDetail, ITranslateLang } from '@patsnap/synapse_common_interface'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { ElCheckbox } from '@pharmsnap/shared/src/element-ui'
import {
  renderTransMedicineDisease,
  renderTransMedicineDrug,
  renderTransMedicineHighlight,
  renderTransMedicineJournalOrMeetings,
  renderTransMedicineOrganization,
  renderTransMedicinePublicationDate,
  renderTransMedicineSubject,
  renderTransMedicineTarget,
  renderTransMedicineTechnology,
  renderTransMedicineTitle,
} from '@pharmsnap/shared/src/render'
import { PropType, computed, defineComponent } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import { GDescription } from '../../../ui/GDescription/GDescription'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import { BEntityLoadingCard } from '../../card/BEntityLoadingCard/BEntityLoadingCard'
import $classes from '../BCard.module.scss'

export const BCardTranslationalMedicine = defineComponent({
  name: 'BCardTranslationalMedicine',
  props: {
    data: {
      type: Object as PropType<ITransMedicineDetail>,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    disabledTranslate: {
      type: Boolean,
      default: false,
    },
    forceTranslation: {
      type: String as PropType<ITranslateLang>,
      default: '',
    },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    showDescription: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String as PropType<'original' | 'intelligent'>,
      default: 'original',
    },
  },
  setup(props) {
    const {
      getters: { customSettingTranslation },
    } = useAuthStore()
    const { isCN } = useLocale()

    const translationLang = computed(() => {
      if (props.disabledTranslate) {
        return 'original'
      }
      return props.forceTranslation || customSettingTranslation.value
    })

    const isIntelligentMode = computed(() => props.mode === 'intelligent')

    return { translationLang, isCN, isIntelligentMode }
  },
  methods: {
    renderCheckbox() {
      return (
        <div class="mr-2.5">
          <ElCheckbox value={this.isChecked} onChange={() => this.$emit('checkboxChange', { checked: !this.isChecked, row: this.data })}></ElCheckbox>
        </div>
      )
    },
    renderHeader() {
      const nodes = [
        this.data.pub_dt ? <div class="flex-0">{renderTransMedicinePublicationDate(this.data, this.$i18n)}</div> : null,
        this.data.subject?.length ? <div class="flex-0">{renderTransMedicineSubject(this.data, this.$i18n, 'mini')}</div> : null,
        this.data.journal_id_view?.length || this.data.meeting_id_view?.length ? (
          <div class="flex-1 truncate text-sm">{renderTransMedicineJournalOrMeetings(this.data, this.$i18n)}</div>
        ) : null,
      ].filter((item) => item)
      return (
        <div class={['flex items-center text-gray-450', this.isIntelligentMode ? '' : 'px-4 pt-3']} slot="header">
          {this.showCheckbox ? this.renderCheckbox() : null}
          {nodes.map((node, index) => [node, index < nodes.length - 1 ? <span class=" mx-1 text-sm">·</span> : null])}
        </div>
      )
    },
    renderDescription() {
      return (
        <GDescription
          data={this.data}
          width={this.isCN ? 50 : 100}
          items={[
            {
              label: `${this.$tc('synapse_i18n.data_type.organizations')}:`,
              field: 'organization',
              contentCls: $classes.contentItem,
              isShowFn: () => !!this.data.research_sponsor?.length,
            },
            {
              label: `${this.$tc('synapse_i18n.data_type.drugs')}:`,
              field: 'drug',
              contentCls: $classes.contentItem,
              isShowFn: () => !!this.data.drug?.length,
            },
            {
              label: `${this.$tc('synapse_i18n.data_type.targets')}:`,
              field: 'target',
              contentCls: $classes.contentItem,
              isShowFn: () => !!this.data.multi_target_id_view?.length,
            },
            {
              label: `${this.$tc('synapse_i18n.data_type.indications')}:`,
              field: 'indication',
              contentCls: $classes.contentItem,
              isShowFn: () => !!this.data.disease?.length,
            },
            {
              label: `${this.$tc('synapse_i18n.translational_medicine.technology')}:`,
              field: 'technology',
              contentCls: $classes.contentItem,
              isShowFn: () => !!this.data.technology?.length,
            },
          ]}
          scopedSlots={{
            drug: () => renderTransMedicineDrug(this.data),
            target: () => renderTransMedicineTarget(this.data),
            indication: () => renderTransMedicineDisease(this.data),
            organization: () => renderTransMedicineOrganization(this.data),
            technology: () => renderTransMedicineTechnology(this.data, this.$i18n),
          }}
        ></GDescription>
      )
    },
    renderBody() {
      return (
        <div class="mt-2">
          <div class="font-semibold">{renderTransMedicineTitle(this.data, this.$i18n, this.translationLang)}</div>
          <div class="mt-2">{renderTransMedicineHighlight(this.data, this.$i18n, false, { moreText: this.$tc('common.showAll') }, '')}</div>
          {this.showDescription && <div class="mt-4">{this.renderDescription()}</div>}
        </div>
      )
    },
  },
  render() {
    return (
      <BEntityLoadingCard hasImg={false} hasDesc={false} loading={isEmpty(this.data)}>
        <BCardContainer {...{ props: this.$attrs }} class={$classes.container}>
          {this.renderHeader()}
          {this.renderBody()}
        </BCardContainer>
      </BEntityLoadingCard>
    )
  },
})
