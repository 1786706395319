import { legalSettings } from '@patsnap-utils/biz'
import { findKey, get, mapValues } from 'lodash'
import { sharedCtx } from '../../context'
import { ILegalStatusConfig } from './patentLegalStatus.type'
const backendFieldsMap = {
  LEGAL_STATUS: 'legalStatus',
  SIMPLE_LEGAL_STATUS: 'simpleLegalStatus',
  LEGAL_EVENT: 'legalEvents',
}
export class PatentLegalStatusService {
  inited = false
  legalStatusConfigPromise!: Promise<ILegalStatusConfig>
  legalStatusConfig!: ILegalStatusConfig
  constructor() {
    this.initLegalSettings()
  }

  public getLegalStatusName(code: number | string) {
    if (!this.legalStatusConfig) {
      throw new Error('legalStatusConfig is not ready')
    }
    const allMap = {
      ...this.legalStatusConfig.legalStatus,
      ...this.legalStatusConfig.simpleLegalStatus,
      ...this.legalStatusConfig.legalEvents,
    }
    return get(allMap[code], 'title')
  }

  async getPatentLegalStatusConfig() {
    if (this.legalStatusConfigPromise) {
      return this.legalStatusConfigPromise
    }
    this.legalStatusConfigPromise = sharedCtx.service.patent.getPatentLegalStatusConfig().then((res) => res.data.data)
    return this.legalStatusConfigPromise
  }

  private async initLegalSettings() {
    if (this.inited) {
      return
    }
    this.legalStatusConfig = await this.getPatentLegalStatusConfig()
    const result: any[] = []
    mapValues(this.legalStatusConfig, (value, key) => {
      mapValues(value, (codeValue, code) => {
        result.push({
          codeType: findKey(backendFieldsMap, (o) => o === key),
          label: get(codeValue, 'title', {}),
          color: get(codeValue, 'color', ''),
          desc: get(codeValue, 'desc', {}),
          code: Number(code),
        })
      })
    })
    legalSettings(document, result)
    this.inited = true
  }
  static singleton: PatentLegalStatusService
  static createSingleton() {
    if (PatentLegalStatusService.singleton) {
      return PatentLegalStatusService.singleton
    }
    PatentLegalStatusService.singleton = new PatentLegalStatusService()
    return PatentLegalStatusService.singleton
  }
}
