/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IAggregationValue } from '@patsnap/synapse_common_interface'
import { GMiniSwitch, GTooltip } from '@pharmsnap/shared/components'
import { useAuthStore } from '@pharmsnap/shared/composition'
import { FREE_SHOW_MORE_UPGRADE_SHOWED } from '@pharmsnap/shared/constants'
import { getLocalStorage, setLocalStorage } from '@pharmsnap/shared/utils'
import { computed, defineComponent, nextTick, PropType } from '@vue/composition-api'
import { userInjectFilterService } from '../../../BFilterService'
import { userFilterItemState } from '../../../composable/userFilterItemState'
import { IFilterItemCheckBoxConfig } from '../../../types'
import { renderDisplayNameWithTitle2Line } from '../../../utils/render'
import { CheckBoxWithLine } from '../../ui/CheckBoxWithLine/CheckBoxWithLine'
import { LimitContainer } from '../../ui/LimitContainer/LimitContainer'
/**
 * 聚合后的数据
 * R 原始的数据结构
 */
export interface IFilterValueItem<R = any> {
  count: number
  label: string
  value: string
  _raw?: R
}

function findMaxCount<T extends { count: number }>(params: T[]): number {
  return params.reduce((acc, curr) => {
    return acc > curr.count ? acc : curr.count
  }, 0)
}
function injectPercentage<T extends { count: number }>(params: T[]): Array<T & { percentage: string }> {
  const maxCount = findMaxCount(params)
  return params.map((item) => {
    return {
      ...item,
      percentage: `${(item.count / maxCount) * 100}%`,
    }
  })
}
export const FilterItemCheckBox = defineComponent({
  name: 'FilterItemCheckBox',
  props: {
    config: {
      required: true,
      type: Object as PropType<IFilterItemCheckBoxConfig>,
    },
    limitFilter: {
      type: Function as PropType<() => boolean>,
    },
  },
  i18n: {
    messages: {
      cn: {
        'FilterItemCheckBox.noData': '无结果，请尝试去掉一些已过滤项或调整检索式',
        'FilterItemCheckBox.groupByParentTooltip': '切换此开关，该过滤项的值都会被清空',
      },
      en: {
        'FilterItemCheckBox.noData': 'No results. Try removing some filters or broadening your search.',
        'FilterItemCheckBox.groupByParentTooltip': 'Underlying filter would be cleared if you switch off or on',
      },
    },
  },
  setup(props, ctx) {
    const { field } = props.config
    const { state, actions } = userInjectFilterService()
    const {
      getters: { isFreeUser },
    } = useAuthStore()
    const { locale, filterData, currentValue, checkedMap, disabledMap, getUuKey, onChange } = userFilterItemState(
      props.config,
      'list',
      injectPercentage
    )
    const renderItemFn = computed(() => {
      return props.config.renderFn || renderDisplayNameWithTitle2Line.bind(null, locale.value)
    })

    function onChangeAggregationField(value: boolean) {
      actions?.setAggregationByParentMap(field, value)
      const hasValue = state?.filterValueArr.find((item) => item.field === field)
      // 如果存在值,需要清空值,重新聚合所有的过滤项
      if (hasValue) {
        actions?.removeAllFilterValueArrByFieldName(field)
        nextTick(() => {
          actions?.refine(false)
        })
      } else {
        // 没有选择的值,只要重新聚合自己
        nextTick(() => {
          ctx.emit('aggregation', field)
        })
      }
      // 为什么要 nextTick,
      // node_modules/element-ui/lib/switch.js 的handleChange方法在的nextTick 回调需要获取dom结构
      // 如果立马执行 refine, filter 就显示loading状态了 switch组件 dom就会被销毁,导致 nextTick中获取 ref就报错了
      // 下面是 switch 源码
      //  handleChange: function handleChange(event) {
      //   var _this = this;

      //   var val = this.checked ? this.inactiveValue : this.activeValue;
      //   this.$emit('input', val);
      //   this.$emit('change', val);
      //   this.$nextTick(function () {
      //     // set input's checked property
      //     // in case parent refuses to change component's value
      //     _this.$refs.input.checked = _this.checked;
      //   });
      // },
    }
    function onClickContent(data: IAggregationValue) {
      if (props.limitFilter) {
        const val = props.limitFilter()
        if (!val) return
      }
      const key = getUuKey(data)
      if (disabledMap.value[key]) {
        return
      }
      if (checkedMap.value[key]) {
        return
      }
      const isImmediatelyRefine = !props.config.disableImmediatelyRefine
      onChange(key, true, isImmediatelyRefine)
      nextTick(() => {
        actions?.refine(false)
      })
    }
    const handelClickMore = computed(() => {
      if (!props.config.isSupportAdvanceRefine) {
        return undefined
      }
      if (isFreeUser.value) {
        const showed = getLocalStorage(FREE_SHOW_MORE_UPGRADE_SHOWED)
        const stateShowFreeUpgrade = state?.freeUpgradeShow
        if (showed || stateShowFreeUpgrade) {
          return undefined
        }
        return () => {
          setLocalStorage(FREE_SHOW_MORE_UPGRADE_SHOWED, String(true))
          actions?.setFreeUpgradeShow(true)
        }
      }
      return () => {
        actions?.setAdvanceSelectField(field)
        actions?.setIsShowAdvanceDialog(true)
      }
    })

    return {
      state,
      filterData,
      currentValue,
      checkedMap,
      disabledMap,
      locale,
      renderItemFn,
      onChange,
      onChangeAggregationField,
      getUuKey,
      onClickContent,
      handelClickMore,
    }
  },
  render() {
    return (
      <div>
        {this.config.aggregationByParentField && this.state && (
          <div>
            <GTooltip theme="light" content={this.$tc('FilterItemCheckBox.groupByParentTooltip')}>
              <GMiniSwitch
                v-model={this.state.aggregationByParentFieldMap[this.config.field]}
                onChange={this.onChangeAggregationField}
                width={16}
                active-color="#1976D2"
                inactive-color="#BCC2CC"
                active-text={this.config.aggregationByParentFieldLangMap?.[this.locale] || this.$tc('chart.aggregation')}
              ></GMiniSwitch>
            </GTooltip>
          </div>
        )}
        {this.filterData.length === 0 ? (
          <div class="text-xs text-text-t3">{this.$tc('FilterItemCheckBox.noData')}</div>
        ) : (
          <LimitContainer
            limit={this.config.limitShowCount}
            data={this.config.customSort ? this.config.customSort(this.filterData) : this.filterData}
            clickMore={this.handelClickMore}
            scopedSlots={{
              default: (data: Array<IAggregationValue & { percentage: string }>) => {
                return data.map((item) => {
                  const key = this.getUuKey(item)
                  const disabled = !!this.disabledMap[key]
                  const checked = !!this.checkedMap[key] || disabled
                  return (
                    <CheckBoxWithLine
                      class="text-xs mb-1"
                      width={item.percentage}
                      onChange={(value: boolean) => this.onChange(key, value)}
                      disabled={disabled}
                      checked={checked}
                      count={item.count}
                      onClickContent={() => this.onClickContent(item)}
                      align={this.config.checkboxAlign}
                      single={this.config.single}
                      singleValue={key}
                    >
                      {this.renderItemFn(item)}
                    </CheckBoxWithLine>
                  )
                })
              },
            }}
          ></LimitContainer>
        )}
      </div>
    )
  },
})
