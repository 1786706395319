import { CopilotCommand, CopilotPageContext, FeedbackType } from '@pharmsnap/shared/types'

export type Content = string | (() => JSX.Element)

export enum MessageType {
  assistant = 'assistant',
  user = 'user',
}

export interface BaseMessage {
  timestamp: number
  time?: string
  item_id?: string
}
/**
 *  UserMessage 接口
 */
export interface UserMessage extends BaseMessage {
  type: MessageType.user
  /**
   * 带有引用上下文的用户消息
   */
  referencedContent?: string
  content: string
}

/**
 * AssistantMessage 接口
 */
export interface AssistantMessage extends BaseMessage {
  type: MessageType.assistant
  title?: string
  //给assistant message使用，用于toolbar的展示，summarize包含generate，report包含export xxx report
  // 不需要了,因为content可以直接写render函数
  // subtype?: 'summarize' | 'report'
  loading?: boolean
  notShowLoading?: boolean
  error?: string
  // markdown解析
  content: Content[]
  command?: CopilotCommand
  isTyping?: boolean
  feedback?: FeedbackType
  pageContext?: CopilotPageContext
  /**
   * 是否是欢迎消息
   */
  isWelcome?: boolean
}
export type ChatMessage = UserMessage | AssistantMessage

/**
 * chat tab（暂时不用）
 */
export type ChatType = 'assistant' | 'chat'
