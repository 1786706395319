import { toThousands } from '@patsnap/synapse_common_utils'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { GLoading } from '../../ui/GLoading/GLoading'
import { BBasicLoadingCard } from '../card/BBasicLoadingCard/BBasicLoadingCard'
import { getAsyncListTippyConfig } from '../card/config'
import cn from './locales/cn.json'
import en from './locales/en.json'
const LoadMoreEntityList = defineComponent({
  name: 'LoadMoreEntityList',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    getMoreDataFn: {
      type: Function as PropType<(params: { limit: number; offset: number }) => Promise<{ total: number; items: any[] }>>,
      required: true,
    },
    renderEntity: {
      type: Function as PropType<(item: any, index: number, className?: string) => JSX.Element>,
      required: true,
    },
    defaultLimit: {
      type: Number,
      default: 50,
    },
  },
  setup(props, ctx) {
    let page = 0
    const data: Ref<any[]> = ref([])
    const show = ref(false)
    const showMore = ref(true)
    setTimeout(() => {
      show.value = true
    }, 0)
    async function infiniteHandler($state: { loaded: () => void; complete: () => void }) {
      const rt = await props.getMoreDataFn({
        limit: props.defaultLimit,
        offset: page * props.defaultLimit,
      })
      page++
      data.value.push(...rt.items)
      if (rt.items.length) {
        $state.loaded()
        if (data.value.length === rt.total) {
          $state.complete()
          // 加载的数据和总数一致,代表是第一次就加载完了全部数据,不需要显示"没有更多了"
          if (rt.items.length === rt.total) {
            showMore.value = false
          }
        }
      } else {
        $state.complete()
      }
      ctx.emit('update')
    }
    return { show, data, showMore, infiniteHandler }
  },
  render() {
    if (!this.show) {
      return <div></div>
    }
    return (
      <BBasicLoadingCard class="overflow-y-auto">
        <div class="flex flex-wrap">
          {this.data.map((item, index) => {
            return this.renderEntity(item, index, 'mr-1 mb-1')
          })}
        </div>
        <infinite-loading onInfinite={this.infiniteHandler} distance={10}>
          <template slot="spinner">
            <div class="relative h-14">
              <GLoading></GLoading>
            </div>
          </template>
          <div slot="no-more">{this.showMore && <div class="text-text-t2 pt-2 border-t mt-2">{this.$tc('LoadMoreEntityList.noMore')}</div>}</div>
          <div slot="no-results">
            <div class="text-text-t2 py-4">{this.$tc('LoadMoreEntityList.noData')}</div>
          </div>
        </infinite-loading>
      </BBasicLoadingCard>
    )
  },
})
export const BEntityPopoverLoadMore = defineComponent({
  name: 'BDiseasePopperListCard',
  props: {
    initialData: {
      type: Array as PropType<any[]>,
    },
    total: {
      type: Number,
      required: true,
    },
    limitCount: {
      type: Number,
      default: 3,
    },
    getMoreDataFn: {
      type: Function as PropType<(params: { limit: number; offset: number }) => Promise<{ total: number; items: any[] }>>,
      required: true,
    },
    renderEntity: {
      type: Function as PropType<(item: any, index: number, className?: string) => JSX.Element>,
      required: true,
    },
  },
  setup(props) {
    const loadMoreEntityListProps = computed(() => {
      return { getMoreDataFn: props.getMoreDataFn, renderEntity: props.renderEntity }
    })
    return { loadMoreEntityListProps }
  },
  render() {
    if (!this.initialData?.length) {
      return <span>{EMPTY_PLACEHOLDER}</span>
    }
    const more =
      this.total > this.initialData.length ? (
        <PtPopper data={this.loadMoreEntityListProps} tippyConfig={getAsyncListTippyConfig()} component={LoadMoreEntityList}>
          <span class="text-blue-default cursor-pointer mb-1">[+{toThousands(this.total - this.initialData.length)}]</span>
        </PtPopper>
      ) : null
    return (
      <div class="flex flex-wrap items-center max-w-full">
        {this.initialData.map((item, index) => this.renderEntity(item, index, 'mr-1 mb-1'))}
        {more}
      </div>
    )
  },
})
