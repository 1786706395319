import { BMechanismItem } from '@pharmsnap/shared/components'
import { useRequest } from '@pharmsnap/shared/composition/useRequest'
import { sharedCtx } from '@pharmsnap/shared/context'
import { defineComponent, PropType, watch } from '@vue/composition-api'
import { BBasicLoadingCard } from '../BBasicLoadingCard/BBasicLoadingCard'
export const BMechanismFetchListCard = defineComponent({
  name: 'BMechanismFetchListCard',
  props: {
    ids: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { loading, data } = useRequest(
      {
        requestFn: sharedCtx.service.mechanism.getBatchMechanism.bind(sharedCtx.service.mechanism),
      },
      props.ids
    )
    watch(loading, () => {
      ctx.emit('update')
    })
    return {
      loading,
      data,
    }
  },
  render() {
    return (
      <BBasicLoadingCard loading={this.loading}>
        <div>
          {this.data?.map((item) => {
            return (
              <div>
                <BMechanismItem class="mr-1 mb-1" data={item}></BMechanismItem>
              </div>
            )
          })}
        </div>
      </BBasicLoadingCard>
    )
  },
})
