import { IAggregationData, IAggregationParams, IAnnotationTag, ISearchParams } from '@patsnap/synapse_common_interface'
import { decryptTarget } from '@pharmsnap/shared/utils'
import { transformAggregationResponse } from '@pharmsnap/shared/utils/business/aggregationTransform/base'
import { transformNewsAggregationSingleResult } from '@pharmsnap/shared/utils/business/aggregationTransform/news'
import { IBaseDetailParams, IBaseRelatedParams, IDrugDetail, INewsDetail, ISearchResult } from '../../types'
import { IHttp, IHttpWrap, IRequestConfig } from '../http/types'
import { decryptNewsDate } from '../utils/decrypt-response/news'
import { BaseApi } from './baseApi'

export class NewsApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/news/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformNewsAggregationSingleResult))
  }

  /**
   * 新闻搜索列表，不计搜索次数
   */
  async getNewsList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<INewsDetail>>> {
    const rt: IHttpWrap<ISearchResult<INewsDetail>> = await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/news/search'),
      cache: true,
    })
    return decryptNewsDetail(rt)
  }
  /**
   * 此API只能在列表页调用,后端会基于这个路径做统计
   * @param params
   * @returns
   */
  async getNewsListForListPageWithCalcLimits(params: ISearchParams): Promise<IHttpWrap<ISearchResult<INewsDetail>>> {
    const rt: IHttpWrap<ISearchResult<INewsDetail>> = await this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/news'),
      cache: true,
      trackingName: 'NEWS_LIST',
    })
    return decryptNewsDetail(rt)
  }

  async getNewsDetail(params: IBaseDetailParams): Promise<IHttpWrap<INewsDetail>> {
    // seo新闻id会拼一些描述,截取的前36位才是真正的id
    const NEWS_ID_LENGTH = 36
    const rt: IHttpWrap<INewsDetail> = await this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/news/${params.id.slice(0, NEWS_ID_LENGTH)}`),
      params: {
        translation: params.translation,
      },
      method: 'GET',
      cache: true,
    })
    if (rt.success) {
      decryptNewsDate(rt.data, rt.xCorrelationId)
      rt.data.annotation_tag?.forEach(convertTagTypeToLowercase)
      rt.data.target_id_view?.forEach((view) => {
        decryptTarget(view, rt.xCorrelationId)
        view.fontClassName = rt.fontClassName
      })
    }
    return rt
  }

  async getRelatedNews(params: IBaseRelatedParams): Promise<IHttpWrap<ISearchResult<INewsDetail>>> {
    return this.http({
      url: this.getUrl(`pharmsnap_webapi/1.0/news/${params.id}/related`),
      params: {
        offset: params.offset || 0,
        limit: params.limit || 5,
      },
      method: 'GET',
      cache: true,
    })
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IDrugDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/news/search_for_count'),
      cache: true,
    })
  }

  async getNewsCardData({ id }: { id: string }, config: IRequestConfig) {
    return this.http({
      ...config,
      url: this.getUrl(`pharmsnap_webapi/1.0/news/card/${id}`),
      cache: true,
      method: 'GET',
    })
  }
}

function convertTagTypeToLowercase(tag: IAnnotationTag) {
  tag.type = tag.type.toLowerCase() as IAnnotationTag['type']
}

function decryptNewsDetail(rt: IHttpWrap<ISearchResult<INewsDetail>>) {
  if (rt.success) {
    rt.data.items?.forEach((item) => {
      decryptNewsDate(item, rt.xCorrelationId)
      item.annotation_tag?.forEach(convertTagTypeToLowercase)
      item.target_id_view?.forEach((view) => {
        decryptTarget(view, rt.xCorrelationId)
        view.fontClassName = rt.fontClassName
      })
    })
  }
  return rt
}
