export const MAX_CARD_WIDTH = 230
export const MIN_CARD_WIDTH = 130
export const PADDING_CARD_VALUE = 8

export const OUTCOME_NODE_RANK_SEP = 150

export const LOWER_DISPERSION_TYPES = ['Standard Deviation', 'Standard Error', 'Geometric Coefficient of Variation']
// 不显示的参数类型
export const HIDE_OUTCOME_MEASURE_PARAMS_TYPES = ['Count of Participants', 'Count of Units', 'Number']

// outcome_type
export const OUTCOME_MEASURE_TYPE = {
  Primary: {
    color: '#1D8820',
    bg: '#F1F8EE',
  },
  Secondary: {
    color: '#914AED',
    bg: '#F7F3FA',
  },
}

export const MANUAL_DICT_ID = ['4b539a6998ea315690491474a7c72fdc']
export const MULTI_VALUE_SEPARATOR = ' | '
export const MULTI_VALUE_SEPARATOR_COMMA = ', '

export const ENDPOINT_EVENTS_I18N = {
  en: {
    all_cause_mortality: 'All-Cause Mortality',
    serious_events: 'Serious Adverse Events',
    other_events: 'Other (Not Including Serious) Adverse Events',
  },
  cn: {
    all_cause_mortality: '全因死亡率',
    serious_events: '严重不良反应',
    other_events: '其他（不包括严重）不良反应',
  },
}
