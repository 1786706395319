import type { IAggregationData } from '@patsnap/synapse_common_interface'
import { BasicWorldMapChart, BasicWorldMapChartDataItem } from '@pharmsnap/shared/chart'
import { WorldMap } from '@pharmsnap/shared/chart/plots/worldmap'
import { IUseWorldMapChartData } from '@pharmsnap/shared/types'
import { computed, Ref } from '@vue/composition-api'
import type { EChartsType } from 'echarts/core'
import { sum } from 'lodash'
import { useChart } from '../composition'

export function transformAggResult2WorldMapData(aggData?: IAggregationData): IUseWorldMapChartData {
  const emptyData: IUseWorldMapChartData = {
    data: [],
  }
  if (!aggData) return emptyData
  if (aggData && !aggData.aggregation_result) return emptyData
  if (aggData && aggData.aggregation_result.length === 0) return emptyData
  const items = aggData.aggregation_result[0].items
  return {
    data: items.map((item) => ({
      count: item.count,
      countryName: item.display_name_en,
      originData: item,
    })),
  }
}

export function useWorldMap(
  data: Ref<IUseWorldMapChartData>,
  config: {
    name?: string | (() => string)
    roam?: boolean
    showVisualMap?: boolean
    showPercent?: boolean
    registerEvent?: (instance: EChartsType) => void
    mixShowChildren?: boolean
  }
) {
  const showVisualMap = typeof config.showVisualMap === 'undefined' ? true : config.showVisualMap
  const roam = !!config.roam
  const showPercent = !!config.showPercent
  const getName = () => (typeof config.name === 'function' ? config.name() || '' : config.name || '')

  const total = computed(() => {
    const values = data.value.data.map((i) => i.count)

    return sum(values) || 0
  })
  const isEmpty = computed(() => data.value.data.length === 0)

  const worldMapOption = computed<BasicWorldMapChart>(() => {
    const { mixShowChildren = true } = config
    const option: BasicWorldMapChart = {
      type: 'world-map',
      data: data.value.data.map((i) => {
        const item: BasicWorldMapChartDataItem = {
          code: i.countryName,
          value: i.count,
          percent: showPercent ? (total.value === 0 ? 0 : i.count / total.value) : undefined,
          originData: i.originData,
        }

        return item
      }),
      roam: roam,
      name: getName(),
      showVisualMap,
      mixShowChildren,
    }

    return option
  })

  const beforeInitHook = async () => {
    await WorldMap.registerMap()
  }

  const { initChart, chartContainer, render, options, getEchartsInstance } = useChart(worldMapOption, {
    autoResize: true,
    beforeInitHook,
    registerEvent: config.registerEvent,
  })

  return {
    isEmpty,
    initChart,
    chartContainer,
    render,
    options,
    getEchartsInstance,
  }
}
