import { VUE_APP_STATIC_PATH } from '@pharmsnap/pharmsnap-web/config'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'

interface IUpgradeGifMap {
  i18nKey: string
  gifFile: string
}

export const UPGRADE_GIF_MAP: Partial<Record<E_UPGRADE_TYPE, IUpgradeGifMap>> = {
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_KG]: {
    i18nKey: 'upgrade.kg',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/kg.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_GROUP_BY]: {
    i18nKey: 'upgrade.groupBy',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/group_by.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_ADVANCE_FILTER]: {
    i18nKey: 'upgrade.advanceFilter',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/advance_filter.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_FINANCING]: {
    i18nKey: 'upgrade.financing',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/financing.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_INVESTMENT]: {
    i18nKey: 'upgrade.investment',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/investment.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_WORKSPACE]: {
    i18nKey: 'upgrade.workspace',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/workspace.gif`,
  },
  [E_UPGRADE_TYPE.USE_PAID_FEATURE_COMPANY]: {
    i18nKey: 'upgrade.company',
    gifFile: `${VUE_APP_STATIC_PATH}/static/img/upgrade_guidance/company.png`,
  },
}
