import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import { IAggregationValue, IBaseLangName, IQuery, IQueryDataType, IQueryItem, IQueryValue } from '@patsnap/synapse_common_interface'
import { IBatchDrugInfo, ISearchCollapse } from '@pharmsnap/shared/types'
import { cloneDeep, flatten, includes, uniqBy } from 'lodash'
import { phaseWeightMap } from '../config'
import { DRUG_PHASE_IDS_WITHOUT_TRIAL, regulationCountryBinaryTuplesSortIndex } from '../constants'
import { sharedCtx } from '../context'
import {
  IBaseCountry,
  IBaseDevStatusInfo,
  IBaseDictItem,
  IBaseLink,
  IDrugRDStatusInfo,
  IHistorySearchParams,
  ILang,
  IListItem,
  IQueryMustFilter,
  ISpecialApprovalItem,
} from '../types'
import { getDictItemName, sortFunc } from './common'
import { getSpecialLang } from './lang'

/**
 * 获取药物名称
 * @param data
 * @param locale
 * @param isDegraded 是否降级查找
 * @returns
 */
export function getDrugName<T extends { drug_name?: IBaseLangName[] }>(data: T, locale: ILang, isDegraded = true) {
  return getSpecialLang({ data: data.drug_name || [], lang: locale, field: 'name', isDegraded })
}

export function getDicNames(data: IBaseDictItem[], locale: ILang) {
  return data.map((i) => getDictItemName(i, locale))
}

/**
 * 国家的Alpha2code 特殊处理逻辑
 * @param data
 * @returns
 */
export function handelCountryAlpha2Code(data?: IBaseCountry, isCN?: boolean): string {
  if (data?.alpha_2_code && data.display_name_cn) {
    return isCN ? data.display_name_cn : data.alpha_2_code
  }
  if (data?.alpha_2_code) {
    return data.alpha_2_code
  }
  if (data?.display_name === 'European Union') {
    return 'EU'
  }
  return ''
}
/**
 * 渲染特殊审批_国家的二元组
 * @param data
 * @param locale
 * @returns
 */
export function getDrugSpecialApproval<T extends { special_approval?: ISpecialApprovalItem[] }>(data: T, locale: ILang) {
  return uniqBy(
    data.special_approval?.reduce(
      (acc, curr) => {
        const countryCode = handelCountryAlpha2Code(curr.country_view)
        if (!countryCode) {
          return acc
        }
        if (!curr.special_review_view) {
          return acc
        }
        return [
          ...acc,
          {
            label: `${getDictItemName(curr.special_review_view, locale)}(${countryCode})`,
            value: `${curr.special_review_view.dict_id}-${curr.country_view?.id}`,
          },
        ]
      },
      [] as Array<{
        label: string
        value: string
      }>
    ),
    (item) => item.value
  )
    .sort((a, b) => ((regulationCountryBinaryTuplesSortIndex[a.value] || 0) > (regulationCountryBinaryTuplesSortIndex[b.value] || 0) ? 1 : -1))
    .map((item) => item.label)
}

async function getRegulationTags(): Promise<IListItem[]> {
  const res = await sharedCtx.service.dictionary.searchTreeWithoutLimit({
    dict_type: 'APPROVAL_COUNTRY_TUPLE',
    offset: 0,
    limit: 100,
  })
  if (res.success) {
    return res.data.items.map((item) => {
      return {
        id: item.id,
        name_en: item.name_en || '',
        name_cn: item.name_cn || '',
      }
    })
  }
  return []
}
let storeRegulations: Promise<IListItem[]>
export async function getTotalRegulations() {
  if (storeRegulations) return storeRegulations
  const res = getRegulationTags()
  return (storeRegulations = res)
}

export function getDrugAtcLinks(atc_code_view: IBaseDictItem[]) {
  return (atc_code_view || [])
    .map((i) => ({ name: i.atc_code, href: `https://www.whocc.no/atc_ddd_index/?code=${i.atc_code}&showdescription=yes` }))
    .filter((i) => !!i.name) as IBaseLink[]
}

export function pickCountriesFromDrugPipeline(pipeline: IDrugRDStatusInfo) {
  const list = pipeline.dev_status || []
  return flatten(list.map((i) => i.country_info))
}

export function pickUniqCountriesFromDrugPipelines(pipelines: IDrugRDStatusInfo[]) {
  const countries = flatten(pipelines.map((i) => pickCountriesFromDrugPipeline(i)))
  return uniqBy(countries, (i) => i.country)
}

/**
 * drugIds转到query的{typeKey}里面
 * @param ids
 * @param oldQuery
 * @param typeKey
 * @returns
 */
export const convertDrugIds2QueryId = async (
  ids: string[],
  oldQuery?: IQueryMustFilter,
  typeKey: 'filter' | 'must' = 'must',
  data_type: IQueryDataType = 'drug',
  getDrugList?: (ids: string[]) => IBatchDrugInfo[]
) => {
  const getQueryValues = async () => {
    if (getDrugList) {
      return getDrugList(ids).map((o) => {
        const cnDta = o.drug_name.find((drug) => drug.lang === 'CN')
        const enData = o.drug_name.find((drug) => drug.lang === 'EN')
        return {
          display_name_cn: cnDta?.name ?? '',
          display_name_en: enData?.name ?? '',
          type: 'text',
          value: o.drug_id,
        }
      })
    }
    const drugList = await sharedCtx.service.drug.getBatchDrugs(ids)

    if (drugList.success) {
      return drugList.data.map((o) => {
        const cnDta = o.drug_name.find((drug) => drug.lang === 'CN')
        const enData = o.drug_name.find((drug) => drug.lang === 'EN')
        return {
          display_name_cn: cnDta?.name ?? '',
          display_name_en: enData?.name ?? '',
          type: 'text',
          value: o.drug_id,
        }
      })
    }

    return []
  }

  let queryId = ''
  const queryValues = (await getQueryValues()) as IQueryValue[]

  if (queryValues.length) {
    const queryItem: IQueryItem = {
      type: 'field',
      fields: ['DRUG_ID'],
      value: queryValues,
    }
    const searchParams: IHistorySearchParams = {
      data_type,
      query: {
        type: 'group',
      },
      hidden_flag: true,
    }
    searchParams.query[typeKey] = [queryItem]
    if (oldQuery) {
      const newQuery = cloneDeep(oldQuery)
      newQuery[typeKey].push(queryItem)
      searchParams.query = { ...newQuery, type: 'group' }
    }

    const queryRes = await sharedCtx.service.history.saveQuery(searchParams)
    if (queryRes.success) {
      queryId = queryRes.data.id
    }
  }

  return queryId
}

/**
 * 手动自己传query,可指定dataType
 * @param query
 * @param dataType
 * @returns
 */
export const getQueryIdByQuery = async (query: IQuery, dataType: IQueryDataType = 'drug', collapse?: ISearchCollapse) => {
  let queryId = ''
  const searchParams: IHistorySearchParams = {
    data_type: dataType,
    query: cloneDeep(query) as IQuery,
    hidden_flag: true,
    ...{ collapse },
  }
  const queryRes = await sharedCtx.service.history.saveQuery(searchParams)
  if (queryRes.success) {
    queryId = queryRes.data.id
  }
  return queryId
}

/**
 * 是否需要展示查看临床
 * @param query
 * @param dev_status
 * @returns
 */
export const getIsShowViewTrial = (devStatus: IBaseDevStatusInfo[] | undefined) => {
  if (!devStatus || !devStatus.length) {
    return false
  }
  const devStatusLength = devStatus.length
  const highestDevStatus = devStatus[devStatusLength - 1]

  return !includes(DRUG_PHASE_IDS_WITHOUT_TRIAL, highestDevStatus.dev_status_id)
}

export const sortDrugStatus = (data: IAggregationValue[]): IAggregationValue[] => {
  return sortFunc({
    data,
    map: phaseWeightMap,
    getKey: (d) => d.key || '',
    sort: 'ab',
  })
}

export function sortClinicalPhase<T extends { key: string }>(data: T[]): T[] {
  return data.slice().sort((a, b) => {
    return (ALL_DEV_STATUS_MAP[a.key].rank || 0) - (ALL_DEV_STATUS_MAP[b.key].rank || 0)
  })
}
