import { IBaseClinicalResult } from '@patsnap/synapse_common_interface'
import { IS_CN_REGION } from '@pharmsnap/shared/config/base'
import { IClinicalDetail, INewsDetail } from '@pharmsnap/shared/types'
import { ILang, ITranslateLang } from '@pharmsnap/shared/types/base'
/**
 * 默认的机翻语言
 * 中美的默认值不一样
 */
const defaultTranslateLang: ILang = IS_CN_REGION ? 'CN' : 'EN'
export function isOpenNewsTranslate(newsData: INewsDetail, customSettingTranslation?: ITranslateLang) {
  if (!IS_CN_REGION) {
    return false
  }
  if (!customSettingTranslation) {
    customSettingTranslation = defaultTranslateLang
  }
  if (customSettingTranslation === 'original') {
    return false
  }
  if (customSettingTranslation && customSettingTranslation === newsData.language) {
    return false
  }
  return true
}
export function isOpenCTTranslate(CTData: IClinicalDetail, customSettingTranslation?: ITranslateLang) {
  if (!customSettingTranslation) {
    customSettingTranslation = defaultTranslateLang
  }
  if (customSettingTranslation === 'original') {
    return false
  }
  if (CTData.lang) {
    return CTData.lang !== customSettingTranslation
  } else {
    // 兜底没有lang的情况,按照原来逻辑
    if (customSettingTranslation === 'EN' && (CTData.data_source === 'CTGOV' || CTData.data_source === 'WHO')) {
      return false
    }
    if (customSettingTranslation === 'CN' && CTData.data_source === 'CTR') {
      return false
    }
    return true
  }
}
/**
 * 生成是否需要翻译多语言字段的方法的函数
 * @param params
 */
export function isOpenCTResultTranslate(CTResultData: IBaseClinicalResult, customSettingTranslation?: ITranslateLang): boolean {
  // 没有值需要按照部署区域默认开启翻译
  if (!customSettingTranslation) {
    customSettingTranslation = defaultTranslateLang
  }
  // 值为 'original' 表示关闭翻译
  if (customSettingTranslation === 'original') {
    return false
  }
  return CTResultData.clinical_trial_translation?.some((item) => item.lang === customSettingTranslation)
}
export function isOpenTranslate<T extends { lang: ILang }>(translationData: T[] = [], customSettingTranslation?: ITranslateLang): boolean {
  // 没有值需要按照部署区域默认开启翻译
  if (!customSettingTranslation) {
    customSettingTranslation = defaultTranslateLang
  }
  // 值为 'original' 表示关闭翻译
  if (customSettingTranslation === 'original') {
    return false
  }
  return translationData.some((item) => item.lang === customSettingTranslation)
}
/**
 * 生成是否需要翻译多语言字段的方法的函数
 * @param params
 */
export function generatorLangFieldISTranslateFn<T extends { lang: ILang }>(customSettingTranslation?: ITranslateLang): (data?: T[]) => boolean {
  // 没有值需要按照部署区域默认开启翻译
  if (!customSettingTranslation) {
    customSettingTranslation = defaultTranslateLang
  }
  // 值为 'original' 表示关闭翻译
  if (customSettingTranslation === 'original') {
    return () => false
  }
  return (data?: Array<T>) => {
    if (!data?.length) {
      return false
    }
    // 原始数据中有用户需要翻译的语言,就不用显示翻译区块了
    return !data.some((item) => item.lang === customSettingTranslation)
  }
}
