export const AXIS_NAME_COLOR = '#020A1A'
export const AXIS_NAME_LINE_HEIGHT = 16
export const AXIS_X_NAME_GAP = 28
export const AXIS_Y_NAME_GAP = 14
export const AXIS_LINE_COLOR = '#DFE1E6'
export const AXIS_LINE_LABEL_COLOR = '#020A1A'
export const TOOLTIP_BG_COLOR = '#fefefe'
export const TOOLTIP_BORDER_COLOR = '#333333'
export const TOOLTIP_BORDER_WIDTH = 0
export const TOOLTIP_PADDING = [4, 8, 8, 8]
export const TOOLTIP_TEXT_COLOR = '#020A1A'
export const TOOLTIP_FONT_SIZE = 12
export const TOOLTIP_LINE_HEIGHT = 18
export const HEATMAP_LABEL_COLOR = '#020A1A'
export const SHOW_DATA_ZOOM_LIMIT = 10
