import '@patsnap-ui/icon/assets/solid/LoadingBars.svg'
import { query2Text } from '@patsnap/synapse_common_business'
import { IQueryItem } from '@patsnap/synapse_common_interface'
import '@pharmsnap/shared/assets/icon-svg/drag.svg'
import { useLocale } from '@pharmsnap/shared/composition'
import { autoPreviewConfigMap } from '@pharmsnap/shared/config'
import { ElTooltip } from '@pharmsnap/shared/element-ui'
import { IAutoPreview, ISearchCollapse } from '@pharmsnap/shared/types'
import { computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, PropType, reactive, toRefs } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import $classes from './BAutoPreview.module.scss'
import { BAutoPreviewCounts } from './components/BAutoPreviewCounts/BAutoPreviewCounts'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BAutoPreview = defineComponent({
  name: 'BAutoPreview',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<IAutoPreview>,
      required: true,
    },
    storeQuery: {
      type: Array as PropType<IQueryItem[]>,
      default: () => [],
    },
    collapse: {
      type: Object as PropType<ISearchCollapse>,
    },
    searchAble: {
      type: Boolean,
      default: true,
    },
    searchToolTip: {
      type: String,
      default: '',
    },
    customDisplayType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const ins = getCurrentInstance()
    const { locale, isCN } = useLocale()

    const data = reactive({
      move: false,
      mouse: {
        x: 0,
        y: 0,
      },
      queryContainerHeight: 0,
    })

    const displayQuery = computed(() => {
      if (!props.storeQuery.length) return ''
      const { dataType } = autoPreviewConfigMap[props.type]
      const res = query2Text(props.storeQuery, locale.value, dataType)
      return res
    })
    const isAble = computed(() => displayQuery.value && props.searchAble)

    const handleCLear = () => {
      emit('clear')
    }

    const handleSearch = () => {
      if (isAble.value) {
        emit('search')
      }
    }

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      data.move = true
      const queryContainer = ins?.proxy.$refs.queryContainer as HTMLElement
      data.mouse.y = e.pageY
      data.queryContainerHeight = queryContainer.offsetHeight
      document.body.addEventListener('mousemove', handleMouseMove)
      document.body.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = (e: MouseEvent) => {
      e.preventDefault()
      if (data.move) {
        const queryContainer = ins?.proxy.$refs.queryContainer as HTMLElement
        let newH = data.queryContainerHeight - (e.pageY - data.mouse.y)
        if (newH > 200) {
          newH = 200
        }
        if (newH < 56) {
          newH = 56
        }
        queryContainer.style.height = `${newH}px`
      }
    }

    const handleMouseUp = (e: MouseEvent) => {
      e.preventDefault()
      data.move = false
      document.body.removeEventListener('mousemove', handleMouseMove)
      document.body.removeEventListener('mouseup', handleMouseUp)
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      const { keyCode } = e
      if (keyCode === 13) {
        handleSearch()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown)
    })

    return { ...toRefs(data), isCN, isAble, displayQuery, handleCLear, handleSearch, handleMouseDown, handleMouseUp }
  },
  methods: {
    renderPreviewRes() {
      return this.displayQuery ? (
        <BAutoPreviewCounts
          type={this.type}
          query={this.storeQuery}
          collapse={this.collapse}
          customDisplayType={this.customDisplayType}
        ></BAutoPreviewCounts>
      ) : (
        <div class={$classes.previewRes}>
          {this.$t('common.previewRes')}
          {this.type === 'Patent' ? this.$t('BAutoPreview.patentInfo') : ''}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={$classes.previewContainerVertical}>
        <div class={$classes.dragBtn} onMousedown={(e: MouseEvent) => this.handleMouseDown(e)} onMouseup={(e: MouseEvent) => this.handleMouseUp(e)}>
          <PtIcon class={$classes.dragIcon} icon="Drag"></PtIcon>
        </div>
        <div
          ref="queryContainer"
          data-testid="b-auto-preview__query"
          class={this.displayQuery ? $classes.queryContainerVertical : $classes.queryContainerVerticalHolder}
        >
          {this.displayQuery || this.placeholder}
        </div>
        <div class={$classes.btnContainerVertical}>
          <div class="flex-1 text-left">{this.renderPreviewRes()}</div>
          <div>
            <button
              data-testid="b-auto-preview__clean"
              class="pt-ui-btn"
              data-size="big"
              type="button"
              data-type="default"
              onClick={this.handleCLear}
            >
              {this.$t('common.clear')}
            </button>
            <ElTooltip placement="top" content={this.searchToolTip} disabled={!this.searchToolTip} popperClass={$classes.elPopper}>
              <div class="inline-block ml-2.5">
                <button
                  data-testid="b-auto-preview__search"
                  class="pt-ui-btn"
                  disabled={!this.isAble}
                  data-size="big"
                  type="button"
                  data-type="submit"
                  onClick={this.handleSearch}
                >
                  {this.$t('common.search')}
                </button>
              </div>
            </ElTooltip>
          </div>
        </div>
      </div>
    )
  },
})
