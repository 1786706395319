import { ISearchParams } from '@patsnap/synapse_common_interface'
import { E_ROUTER_NAME } from '@pharmsnap/pharmsnap-web/types/enum'
import { IBOverviewPanelItem } from './component'
import { IGroupSearchParams } from './search'

export enum CopilotCommand {
  translate = 'TRANSLATE',
  summarize = 'SUMMARIZE',
  explain = 'EXPLAIN',
  start_chat = 'START_CHAT',
  start_chat_sent_immediately = 'START_CHAT_SENT_IMMEDIATELY',
  generate_report = 'GENERATE_REPORT',
  page_summarize = 'PAGE_SUMMARIZE',
}

// 请求相关
export interface DetailPageContext {
  page_name: ICopilotPage
  params: {
    language: string
    entity_id?: string
    entity_name?: string
    use_cache?: boolean
  }
}
export interface SearchPageContext {
  page_name: ICopilotPage
  params: {
    language: string
    query: ISearchParams | IGroupSearchParams
    query_string: string
    use_cache?: boolean
    refine: string
    group_by?: string
  }
}
export type PageContext = DetailPageContext | SearchPageContext

export interface ICopilotTaskParams {
  page_context: PageContext
  content?: string
  action: CopilotCommand
  stream?: boolean
  signal?: AbortSignal
  onDownloadProgress?: (progressEvent: any) => void
}

export interface ICopilotTaskRes {
  conversation_id: string
  content: string
  item_id: string
  report_id?: string
}

export enum CopilotTaskStatus {
  // PENDING，RUNNING，SUCCESS，FAILED
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  STOPPED = 'STOPPED',
  ILLEGAL = 'ILLEGAL',
}

export interface ModuleStatus {
  module_name: string
  status: CopilotTaskStatus
}

export type CopilotReport = {
  report_id: string
  report_name: string
  type: string
  s3_path: string
  generate_time: number
}

export interface IAsyncTaskResult {
  conversation_id: string
  status: CopilotTaskStatus
  module_status?: ModuleStatus[]
  report_detail?: CopilotReport
  content: string
}

export interface ICopilotReportParams {
  offset: number
  limit: number
  page_name?: string
}

export interface ICopilotReportRes {
  items: CopilotReport[]
  total: number
}

export interface ICopilotCapacity {
  total: number
  newly_invited_user_count: number
  used: number
}

export enum ContextPageType {
  detail = 'detail',
  search = 'search',
}
// export type E_ROUTER_NAME = E_ROUTER_NAME.DRUG_LIST | E_ROUTER_NAME.PATENT_LIST
// export type E_ROUTER_NAME =
//   | E_ROUTER_NAME.DRUG
//   | E_ROUTER_NAME.DISEASE
//   | E_ROUTER_NAME.DRUG_TYPE
//   | E_ROUTER_NAME.ORGANIZATION
//   | E_ROUTER_NAME.TARGET
// {
//   E_ROUTER_NAME.DRUG = E_ROUTER_NAME.DRUG,
//   target = 'target',
//   disease = 'disease',
//   drugType = 'drugType',
//   org = 'org',
// }

// 支持copilot的搜索列表页面
export type ICopilotSearchPage = Extract<E_ROUTER_NAME, E_ROUTER_NAME.DRUG_LIST | E_ROUTER_NAME.PATENT_LIST | E_ROUTER_NAME.DRUG_DEAL_LIST>
// 支持copilot的详情页页面
export type ICopilotDetailPage = Extract<
  E_ROUTER_NAME,
  E_ROUTER_NAME.DRUG | E_ROUTER_NAME.ORGANIZATION | E_ROUTER_NAME.DISEASE | E_ROUTER_NAME.TARGET | E_ROUTER_NAME.DRUG_TYPE
>

export type ICopilotPage = ICopilotSearchPage | ICopilotDetailPage

// 支持copilot 总结报告的页面
export type ICopilotReportPage = Extract<
  ICopilotPage,
  | E_ROUTER_NAME.DRUG_LIST
  | E_ROUTER_NAME.DISEASE
  | E_ROUTER_NAME.TARGET
  | E_ROUTER_NAME.DRUG_TYPE
  | E_ROUTER_NAME.PATENT_LIST
  | E_ROUTER_NAME.DRUG_DEAL_LIST
>
export interface CopilotBasePageContext {
  page_name: ICopilotPage
  alert?: boolean
  isLoading?: boolean
}
export interface CopilotDetailPageContext extends CopilotBasePageContext {
  type: ContextPageType.detail
  page_type: ICopilotDetailPage
  entity_id: string
  entity_name: string
  overview_items?: IBOverviewPanelItem[]
}

export interface CopilotSearchPageContext extends CopilotBasePageContext {
  type: ContextPageType.search
  query: ISearchParams | IGroupSearchParams
  refine: string
  query_string: string
  search_count?: number
  group_by?: string
  summarizeCallback?: () => void
}

export type CopilotPageContext = CopilotDetailPageContext | CopilotSearchPageContext

export interface IStreamResponse {
  item_id: string
  conversation_id: string
  choices: Choice[]
}

interface Choice {
  finish_reason: string
  delta: Delta
  index: number
}

interface Delta {
  role: string
  content?: string
}
interface ICopilotHistoryItem {
  item_id: string
  feedback: FeedbackType
  conversation_id: string
  user_id: string
  request: ICopilotTaskParams
  response?: {
    status: CopilotTaskStatus
  } & IAsyncTaskResult
  status: CopilotTaskStatus
  generate_time: number
}
export interface ICopilotHistoryRes {
  items: ICopilotHistoryItem[]
  total: number
}

export type FeedbackType = 'LIKE' | 'DISLIKE'
