import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { BAnalysisChartCard, BAnalysisChartFullScreenCard, BCorpTree, BIcon, GBlockNext } from '@pharmsnap/shared/src/components'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem } from '@pharmsnap/shared/src/components/business/BAnalysisChartNext/type'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from '@vue/composition-api'
import { BBasicCorpTree } from './BBasicCorpTree'
import { VIEW_MORE } from './constant'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { clearWebImageCache, defineWebImage } from './utils/webImage'

export const BCorpTreeOverview = defineComponent({
  name: 'BCorpTreeOverview',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    src: {
      type: String,
    },
    name: {
      type: String,
    },
    height: {
      type: Number,
      default: 500,
    },
    fullscreenCfg: {
      type: Object as PropType<{ enable: boolean; position?: { position?: string; top: string; left: string; bottom: string; right: string } }>,
      default: () => ({ enable: true }),
    },
    selectedFilterUniqId: {
      type: String,
      default: '',
    },
    portalName: {
      type: String,
    },
  },
  setup(props) {
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()
    const { isCN } = useLocale()
    const corpTreeBasic = ref<IOrgCorpTree>()
    const basicCorpTreeContainer = ref()
    const hasViewMore = ref(false)
    const loading = ref(false)
    const packCorpTree = (root: IOrgCorpTree, parentCorp?: IOrgCorpTree) => {
      if (parentCorp) {
        const temp = root
        root = parentCorp
        root.subsidiary = [temp]
      }
      return root
    }
    const getCorpTreeBasic = async () => {
      loading.value = true
      const rt = await sharedCtx.service.organization.getCorpTreeBasic(props.id)
      if (rt.success && rt.data?.company) {
        const company = rt.data.company
        let root = company
        company.subsidiary = rt.data.subsidiary || []
        if ((company.subsidiary_count || 0) > company.subsidiary.length) {
          hasViewMore.value = true
          company.subsidiary.push({ id: VIEW_MORE, parent_id: company.id })
        }
        root = packCorpTree(root, rt.data.parent_company)
        root = packCorpTree(root, rt.data.ultimate_parent_company)
        corpTreeBasic.value = root
      }
      loading.value = false
    }
    onMounted(() => {
      defineWebImage()
      getCorpTreeBasic()
    })
    onUnmounted(() => {
      clearWebImageCache()
    })

    const fullscreenVisible = ref(false)
    const loadingAll = ref(false)
    const corpTree = ref<IOrgCorpTree>()
    const corpTreeContainer = ref()
    const getCorpTree = async () => {
      loadingAll.value = true
      const rt = await sharedCtx.service.organization.getCorpTree(props.id)
      if (rt.success && rt.data) {
        corpTree.value = rt.data
      }
      loadingAll.value = false
    }
    const handleViewAll = () => {
      if (isFreeUser.value) {
        changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'VIEW_CORP_TREE' })
        return
      }
      fullscreenVisible.value = true
      getCorpTree()
    }

    const model = ref({
      display: 'vertical-bar',
    })
    const controls = ref<IAnalysisChartControlItem[]>([
      {
        type: 'display',
        bindField: 'display',
        items: [{ type: 'vertical-bar', value: 'vertical-bar' }],
      },
    ])

    const controlsLayout = ref<IAnalysisChartControlLayoutItem[]>([
      {
        field: 'display',
        position: 'left',
      },
    ])
    const fullscreenStyle = computed(() => {
      return {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        'z-index': 100,
        background: '#fff',
      }
    })
    const handleDownloadBasic = async () => {
      if (isFreeUser.value) {
        changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD_CORP_TREE' })
        return
      }
      basicCorpTreeContainer.value?.handleDownload(props.name, props.id)
    }
    const handleDownload = async () => {
      if (isFreeUser.value) {
        changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD_CORP_TREE' })
        return
      }
      corpTreeContainer.value?.handleDownload(props.name, props.id)
    }

    return {
      corpTreeBasic,
      loading,
      hasViewMore,
      basicCorpTreeContainer,
      handleDownloadBasic,

      corpTree,
      loadingAll,
      fullscreenVisible,
      model,
      controls,
      controlsLayout,
      fullscreenStyle,
      handleViewAll,
      corpTreeContainer,
      handleDownload,

      isCN,
    }
  },
  render() {
    if (!this.corpTreeBasic) {
      return (
        <GBlockNext
          title={this.$tc('corpTree.title')}
          desc={this.$tc('corpTree.desc')}
          isLoading={this.loading}
          emptyMode="text"
          size="small"
          isEmpty={true}
          emptyText={this.$tc('common.noData')}
          tip={this.$tc('corpTree.tip')}
        ></GBlockNext>
      )
    }
    return (
      <div>
        <GBlockNext size="small" title={this.$tc('corpTree.title')} isEmpty={false} tip={this.$tc('corpTree.tip')}>
          <BAnalysisChartCard
            isLoading={this.loading}
            isEmpty={!this.corpTreeBasic}
            showSettings={false}
            height={this.height}
            emptyHeight={this.height}
            showFullScreen={this.fullscreenCfg.enable}
            controls={this.controls}
            layout={this.controlsLayout}
            value={this.model}
            downloadCfg={{ visible: true, type: 'custom', showLabel: true }}
            fullscreenCfg={{ label: this.$tc('corpTree.viewAll'), showLabel: true }}
            onOpen={this.handleViewAll}
            onDownload={() => this.handleDownloadBasic()}
            // onAfterDownload={this.handleTrackDownload}
          >
            <template slot="title">
              <span class="font-normal">
                {this.isCN
                  ? [
                      '视图仅展示当前企业简单的上下层级关系（有药物管线），查看详情请点击「',
                      <a class="cursor-pointer" style="color: #1976D2" onClick={this.handleViewAll}>
                        查看全部
                      </a>,
                      '」',
                    ]
                  : [
                      'This chart shows the simple hierarchy of current corp. with pipelines. Click「',
                      <a class="cursor-pointer" style="color: #1976D2" onClick={this.handleViewAll}>
                        View all
                      </a>,
                      '」for details',
                    ]}
              </span>
            </template>
            <BBasicCorpTree
              ref="basicCorpTreeContainer"
              corpTree={this.corpTreeBasic}
              id={this.id}
              onViewAll={this.handleViewAll}
              hasViewMore={this.hasViewMore}
            ></BBasicCorpTree>
          </BAnalysisChartCard>
        </GBlockNext>

        {this.fullscreenVisible && (
          <portal to={this.portalName} disabled={!this.portalName}>
            <BAnalysisChartFullScreenCard
              escape
              style={this.fullscreenStyle}
              isLoading={this.loadingAll || this.corpTreeContainer?.filtering}
              isEmpty={!this.corpTree}
              controls={this.controls}
              layout={this.controlsLayout}
              value={this.model}
              downloadCfg={{ visible: true, type: 'custom', showLabel: true }}
              onClose={() => (this.fullscreenVisible = false)}
              useDefaultContentCls={false}
              subHeaderStyle={{ border: 0, padding: 0 }}
              onDownload={() => this.handleDownload()}
            >
              <template slot="title">
                <div class="flex items-center">
                  <BIcon class="mr-3 flex-shrink-0" border={true} type="company" src={this.src} size={28}></BIcon>
                  <div class="text-xl font-semibold">{this.name}</div>
                </div>
              </template>
              {this.corpTree && <BCorpTree ref="corpTreeContainer" id={this.id} class="px-6 pb-4" corpTree={this.corpTree}></BCorpTree>}
            </BAnalysisChartFullScreenCard>
          </portal>
        )}
      </div>
    )
  },
})
