import { E_NODATA_SIZE } from '@pharmsnap/shared/src/types/enum'
import { defineComponent, PropType } from '@vue/composition-api'
import { GEmpty } from '../GEmpty/GEmpty'
import { GLoading } from '../GLoading/GLoading'
import { GTooltip } from '../GTooltip/GTooltip'
import $classes from './GBlockNext.module.scss'

export const GBlockNext = defineComponent({
  name: 'GBlock',
  props: {
    size: {
      type: String as PropType<'medium' | 'small'>,
      default: 'medium',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    emptyMode: {
      type: String as PropType<'text' | 'image' | 'all'>,
      default: 'image',
    },
    emptyText: {
      type: String,
      default: '',
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingStyle: {
      type: Object,
      default: () => ({}),
    },
    contentStyle: {
      type: Object,
      default: () => ({}),
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showDesc: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    renderHeader() {
      if (this.isEmpty && this.emptyMode === 'text') {
        return (
          <div class={$classes.blockHeader}>
            <div class={$classes.blockTitle}>{this.title}</div>
          </div>
        )
      }

      return (
        <div class={$classes.blockHeader}>
          <div class={$classes.blockTitle}>{this.title}</div>
          {this.tip || this.$slots.tip ? (
            <div class={$classes.blockTip}>
              <GTooltip theme="light" mode="icon">
                <template slot="content">
                  {this.tip ? (
                    <div class="break-words max-w-xs text-xs leading-4" domPropsInnerHTML={this.tip}></div>
                  ) : (
                    <div class="break-words max-w-xs text-xs leading-4">{this.$slots.tip}</div>
                  )}
                </template>
              </GTooltip>
            </div>
          ) : null}
          {this.$slots.rightAction ? <div class={$classes.blockRightAction}>{this.$slots.rightAction}</div> : null}
        </div>
      )
    },
    renderDesc() {
      if (this.isEmpty && this.emptyMode === 'text') return null

      return this.$slots.desc || this.desc ? <div class={$classes.blockDesc}>{this.$slots.desc || this.desc}</div> : null
    },
    renderEmptyContent() {
      if (this.emptyMode === 'all') return null

      if (this.emptyMode === 'text')
        return (
          <div key="empty" class={$classes.blockEmptyText}>
            {this.emptyText}
          </div>
        )

      return (
        <div key="empty-image" style="height: 400px">
          <GEmpty size={E_NODATA_SIZE.MIDDLE}></GEmpty>
        </div>
      )
    },
    renderLoadingContent() {
      return (
        <div key="loading" class="relative" style={Object.assign({ height: '600px' }, this.loadingStyle)}>
          <GLoading></GLoading>
        </div>
      )
    },
    renderContent() {
      return (
        <div class={$classes.blockMain} style={!this.isLoading && !this.isEmpty ? this.contentStyle : {}}>
          {this.isLoading ? this.renderLoadingContent() : null}
          {!this.isLoading && this.isEmpty ? this.renderEmptyContent() : null}
          {!this.isLoading && !this.isEmpty ? this.$slots.default : null}
        </div>
      )
    },
    renderMain() {
      if (this.emptyMode === 'all') {
        if (this.isLoading) {
          return [this.renderLoadingContent()]
        } else {
          if (this.isEmpty) {
            return [this.renderEmptyContent()]
          } else {
            return [this.showHeader && this.renderHeader(), this.showDesc && this.renderDesc(), this.renderContent()]
          }
        }
      }

      return [this.showHeader && this.renderHeader(), this.showDesc && this.renderDesc(), this.renderContent()]
    },
  },
  render() {
    return <div class={[this.size === 'small' ? $classes.blockSmall : '']}>{this.renderMain()}</div>
  },
})
