import { computed, defineComponent } from '@vue/composition-api'
export const BDecodeUniCodeContainer = defineComponent({
  name: 'BDecodeUniCodeContainer',
  props: {
    /**
     * 计算后的用哪套字体
     */
    fontClassName: {
      type: String,
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  setup(props) {
    const _fontClassName = computed(() => {
      if (props.fontClassName) {
        return props.fontClassName
      }
      return ''
    })
    return { _fontClassName }
  },
  render(h) {
    return h(
      this.tag as string,
      {
        class: this._fontClassName,
      },
      this.$slots.default
    )
  },
})
