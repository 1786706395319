/* eslint-disable @typescript-eslint/no-explicit-any */
import { ALL_DEV_STATUS_MAP } from '@patsnap/synapse_common_config'
import { AggTwoDimDataItem } from '@patsnap/synapse_domain'
import { defineComponent, h } from '@vue/composition-api'
import { BAnalysisTwoDimStackTable, BAnalysisTwoDimStackTableProps } from './BAnalysisTwoDimStackTable'

export const BAnalysisTwoDimStackTableWithSorter = defineComponent({
  name: 'BAnalysisTwoDimStackTableWithSorter',
  props: BAnalysisTwoDimStackTableProps,
  render() {
    const defaultStackFieldNameSorter = (a: AggTwoDimDataItem, b: AggTwoDimDataItem) => {
      const aDictId = a.x.key
      const bDictId = b.x.key
      const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
      const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
      return aRank - bRank
    }
    return h(BAnalysisTwoDimStackTable, {
      props: {
        ...this.$props,
        stackFieldNameSorter: this.stackFieldNameSorter || defaultStackFieldNameSorter,
      },
    })
  },
})
