import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.DASHBOARD,
  title: createWebTitle('自定义分析', 'Dashboard'),
}

export default [
  {
    path: 'dashboard',
    name: E_ROUTER_NAME.DASHBOARD,
    component: () => import('@pharmsnap/pharmsnap-web/views/dashboard/views/dashboard-list/DashboardList'),
    meta,
  },
  {
    path: `dashboard/:${E_ROUTER_PARAMS.DASHBOARD_ID}`,
    name: E_ROUTER_NAME.DASHBOARD_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/dashboard/views/dashboard-detail/DashboardDetail'),
    props: {
      isTpl: false,
    },
    meta,
  },
  {
    path: `dashboard_tpl/:${E_ROUTER_PARAMS.DASHBOARD_TPL_ID}`,
    name: E_ROUTER_NAME.DASHBOARD_TPL_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/dashboard/views/dashboard-detail/DashboardDetail'),
    props: {
      isTpl: true,
    },
    meta,
  },
] as RouteConfig[]
