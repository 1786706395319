import { IBaseDrugWithItemInfo } from '@pharmsnap/shared/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { Popper as PtPopper } from 'patsnap-biz'
import { getAsyncListTippyConfig } from '../config'
import { BDrugWithToolTipFetchListCard } from './BDrugWithToolTipFetchListCard'
export const BDrugWithToolTipPopperListCard = defineComponent({
  name: 'BDrugWithToolTipPopperListCard',
  props: {
    data: {
      type: Array as PropType<IBaseDrugWithItemInfo[]>,
      required: true,
    },
  },
  render() {
    return (
      <PtPopper data={{ data: this.data }} tippyConfig={getAsyncListTippyConfig()} component={BDrugWithToolTipFetchListCard}>
        {this.$slots.default}
      </PtPopper>
    )
  },
})
