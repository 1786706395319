import { trueOrFalseLangMap } from '@patsnap/synapse_common_config'
import { IAggregationSingleResult, IAggregationValue } from '@patsnap/synapse_common_interface'
import { drugDealOrgAttrLangMap } from '../../../constants/enum-lang/drug-deal'
import { sortDrugStatus } from '../../drug'
import { sortBooleanData } from '../../drugDeal'
import {
  filterInvalidData,
  injectLangFieldByKey,
  injectLangFieldByKeyLangMap,
  injectOrgName,
  replaceDisplayNameByShortName,
  transformGroupAggregationSingleResultByMap,
} from './base'

const fieldTransformFnMap: Record<string, (data: IAggregationValue, xCorrelationId?: string) => IAggregationValue> = {
  TARGET_ID_EXTEND: replaceDisplayNameByShortName,
  TARGET_ID_FLATTEN: replaceDisplayNameByShortName,
  TARGET_ID_EXTEND_FLATTEN: replaceDisplayNameByShortName,
  PRINCIPLE_ORG_ID: injectOrgName,
  PRINCIPLE_ORG_ID_ROOT: injectOrgName,
  PARTNER_ORG_ID_ROOT: injectOrgName,
  PARTNER_ORG_ID: injectOrgName,
  TOTAL_VALUE: injectLangFieldByKey,
  UPFRONT_PAYMENT: injectLangFieldByKey,
  MILESTONE_PAYMENT: injectLangFieldByKey,
  LEAD_ORG_RELATED: (data) => injectLangFieldByKeyLangMap(trueOrFalseLangMap, data),
  AMOUNT_DISCLOSURE: (data) => injectLangFieldByKeyLangMap(trueOrFalseLangMap, data),
  PRINCIPLE_ORG_ATTR: (data) => injectLangFieldByKeyLangMap(drugDealOrgAttrLangMap, data),
  PARTNER_ORG_ATTR: (data) => injectLangFieldByKeyLangMap(drugDealOrgAttrLangMap, data),
}

const drugDealDataTransformFn = (data: IAggregationSingleResult<IAggregationValue<any>>) => {
  const drugDealFieldDataOrderMap: Record<
    string,
    (data: IAggregationSingleResult<IAggregationValue<any>>) => IAggregationSingleResult<IAggregationValue<any>>
  > = {
    TOTAL_VALUE: (data) => filterInvalidData(data),
    UPFRONT_PAYMENT: (data) => filterInvalidData(data),
    MILESTONE_PAYMENT: (data) => filterInvalidData(data),
    NORMALIZED_PHASE_SIGN_ID: (data) => {
      return {
        ...data,
        items: sortDrugStatus(data.items),
      }
    },
    AMOUNT_DISCLOSURE: (data) => {
      return {
        ...data,
        items: sortBooleanData(data.items),
      }
    },
    LEAD_ORG_RELATED: (data) => {
      return {
        ...data,
        items: sortBooleanData(data.items),
      }
    },
  }

  return drugDealFieldDataOrderMap[data.aggregation_field] ? drugDealFieldDataOrderMap[data.aggregation_field](data) : data
}

export function transformDrugDealAggregationSingleResult(data: IAggregationSingleResult, xCorrelationId: string): IAggregationSingleResult {
  return transformGroupAggregationSingleResultByMap(drugDealDataTransformFn(data), fieldTransformFnMap, xCorrelationId)
}
