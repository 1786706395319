import { GIconProps } from '@pharmsnap/shared/components/props'
import { ElAvatar } from '@pharmsnap/shared/element-ui'
import { defineComponent } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import $classes from './GIcon.module.scss'

export const GIcon = defineComponent({
  name: 'GIcon',
  props: GIconProps,
  render() {
    const border = this.border
    const Avatar = this.svgName ? (
      <PtIcon
        icon={this.svgName}
        style={[{ fontSize: `${this.size}px` }, this.useSvgDefaultColor ? { color: '#495973' } : this.color ? { color: this.color } : {}]}
      ></PtIcon>
    ) : (
      <ElAvatar class={$classes.icon} alt={this.alt} shape={this.shape} size={this.size} src={this.src} fit="contain">
        <img alt={this.alt} src={this.fallbackSrc} style={{ 'object-fit': 'cover' }} />
      </ElAvatar>
    )

    return border ? (
      <span class={[$classes.icon, $classes.iconBorder, this.shape === 'circle' ? $classes.iconCircle : $classes.iconSquare]}>{Avatar}</span>
    ) : (
      Avatar
    )
  },
})
