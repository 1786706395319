import '@patsnap-ui/icon/assets/solid/qr.svg'
import '@patsnap-ui/icon/assets/solid/wechat.svg'
import { useAuthStore } from '@pharmsnap/shared/composition/useAuthStore'
import { sharedCtx } from '@pharmsnap/shared/context'
import { showSingleToast } from '@pharmsnap/shared/utils'
import { defineComponent, ref } from '@vue/composition-api'
import { Icon as PtIcon } from 'patsnap-biz'
import { GDialog } from '../../ui/GDialog/GDialog'
import { BWeChatBindDialog } from '../BWeChatBindDialog/BWeChatBindDialog'
import cn from './locales/cn.json'
import en from './locales/en.json'
export const BWechatBindButton = defineComponent({
  name: 'BWechatBindButton',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup() {
    const {
      getters: { hasFollowedPharmsnap },
      actions: { syncUserInfo },
    } = useAuthStore()
    /**
     * 显示取消关注的提示弹窗
     */
    const isShowDecouplingDialog = ref(false)
    /**
     * 是否显示绑定弹窗
     */
    const isShowBindDialog = ref(false)
    /**
     * 显示绑定弹窗
     */
    function showBind() {
      isShowBindDialog.value = true
    }
    /**
     * 关闭绑定
     */
    function onCloseFun() {
      isShowBindDialog.value = false
    }
    /**
     * 绑定失败提示
     * @param error
     */
    function bindFailCallback(error: { errorMsg: string }) {
      showSingleToast({
        type: 'error',
        message: error.errorMsg,
      })
    }
    /**
     * 绑定成功回调
     */
    function bindSuccessCallback() {
      isShowBindDialog.value = false
      syncUserInfo(true)
    }
    /**
     * 确定解绑微信unionId,但是openid不解绑
     */
    async function unbindWechat() {
      const rt = await sharedCtx.service.login.wechatUnbind()
      if (rt.success) {
        syncUserInfo(true)
      }
      isShowDecouplingDialog.value = false
    }
    /**
     * 点击解绑按钮回调
     */
    function onUnBind() {
      isShowDecouplingDialog.value = true
    }
    /**
     * 取消解绑的提示框
     */
    function onCloseDecouplingDialog() {
      isShowDecouplingDialog.value = false
    }
    return {
      isShowBindDialog,
      isShowDecouplingDialog,
      showBind,
      onCloseFun,
      bindFailCallback,
      hasFollowedPharmsnap,
      bindSuccessCallback,
      onUnBind,
      unbindWechat,
      onCloseDecouplingDialog,
    }
  },
  render() {
    return (
      <div>
        {this.hasFollowedPharmsnap ? (
          <div class="flex items-center">
            <PtIcon class="text-2xl mr-1 text-green-default" icon="SolidWechat"></PtIcon>
            <span>{this.$tc('BWechatBindButton.followed')}</span>
            <span class="ml-4 cursor-pointer text-blue-default font-normal" onClick={this.onUnBind}>
              {this.$tc('BWechatBindButton.unBind')}
            </span>
          </div>
        ) : (
          <div class="flex items-center cursor-pointer" onClick={this.showBind}>
            <PtIcon class="text-2xl mr-1" icon="SolidQr"></PtIcon>
            <span>{this.$tc('BWechatBindButton.bindWechat')}</span>
          </div>
        )}
        <GDialog
          append-to-body={true}
          visible={this.isShowDecouplingDialog}
          onclose={this.onCloseDecouplingDialog}
          title={this.$tc('BWechatBindButton.unlinkWechat')}
          width="400px"
          confirmText={this.$tc('BWechatBindButton.unBind')}
          onCancel={this.onCloseDecouplingDialog}
          onConfirm={this.unbindWechat}
        >
          <div>{this.$t('BWechatBindButton.unlinkWechatMessage')}</div>
        </GDialog>
        <BWeChatBindDialog visible={this.isShowBindDialog} onClose={this.onCloseFun} onSuccess={this.bindSuccessCallback}></BWeChatBindDialog>
      </div>
    )
  },
})
