import '@patsnap-ui/icon/assets/solid/ResizeCollapse.svg'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { IGTabsPaneItem } from '@pharmsnap/shared/src/types'
import { exportChartImage, showSingleToast } from '@pharmsnap/shared/src/utils'
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, ref, toRefs, unref, watch } from '@vue/composition-api'
import { useEventListener } from '@vueuse/core'
import { EChartsType } from 'echarts/core'
import { GIcon } from '../../../ui/GIcon/GIcon'
import { GTabs } from '../../../ui/GTabs/GTabs'
import { GTooltip } from '../../../ui/GTooltip/GTooltip'
import { BCardContainer } from '../../BCardContainer/BCardContainer'
import { useAnalysisChartCard } from '../compositions/useAnalysisChartControls'
import { useAnalysisRegisterChartRefs } from '../compositions/useAnalysisRegisterChartRefs'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import {
  IAnalysisChartCheckboxControl,
  IAnalysisChartCheckboxGroupControl,
  IAnalysisChartControlItem,
  IAnalysisChartControlLayoutItem,
  IAnalysisChartDisplayControl,
  IAnalysisChartDropDownSingleSelectControl,
  IAnalysisChartRadioControl,
  IAnalysisChartRollupControl,
  IAnalysisDisplayIconType,
} from '../type'
import { BAnalysisChartControlItem } from './BAnalysisChartControlItem'
import { BAnalysisChartCheckbox } from './form-items/BAnalysisChartCheckbox'
import { BAnalysisChartCheckboxGroup } from './form-items/BAnalysisChartCheckboxGroup'
import { BAnalysisChartDisplaySwitch } from './form-items/BAnalysisChartDisplaySwitch'
import { BAnalysisChartDropdownSingleSelect } from './form-items/BAnalysisChartDropdownSingleSelect'
import { BAnalysisChartRadioGroup } from './form-items/BAnalysisChartRadioGroup'
import { BAnalysisChartRollupSwitch } from './form-items/BAnalysisChartRollupSwitch'

const DRAWER_WIDTH = 270
export const BAnalysisChartFullScreenCard = defineComponent({
  name: 'BAnalysisChartFullScreenCard',
  i18n: {
    messages: { cn, en },
  },
  props: {
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showFullScreen: {
      type: Boolean,
      default: true,
    },
    downloadCfg: {
      type: Object as PropType<{
        visible: boolean
        type?: 'custom' | 'chart-img'
        auth?: boolean
        label?: string
        showLabel?: boolean
        chartInsFinder?: (controls: IAnalysisChartControlItem[]) => IAnalysisDisplayIconType | undefined
      }>,
      default: () => ({ visible: false }),
    },
    controls: {
      type: Array as PropType<IAnalysisChartControlItem[]>,
      default: () => [],
    },
    layout: {
      type: Array as PropType<IAnalysisChartControlLayoutItem[]>,
      default: () => [],
    },
    dataPanelTitle: {
      type: String,
    },
    chartPanelTitle: {
      type: String,
    },
    value: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<Record<PropertyKey, any>>,
      default: () => ({}),
    },
    useDefaultContentCls: {
      type: Boolean,
      default: true,
    },
    isDrawer: {
      type: Boolean,
      default: false,
    },
    // 全屏没有视图切换，使用value.display判断,会受小屏切换视图影响
    displayType: {
      type: String as PropType<IAnalysisDisplayIconType>,
    },
    subHeaderStyle: {
      type: [Object, String],
    },
    escape: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const ins = getCurrentInstance()
    const { isCN } = useLocale()
    const {
      state: { userInfo },
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const { layout, controls, downloadCfg, isEmpty } = toRefs(props)

    const { controlsPositionDataPanel, controlsPositionChartPanel } = useAnalysisChartCard({
      controls: controls,
      layout: layout,
    })

    const showDownload = computed(() => downloadCfg.value.visible)

    const downloadAuth = computed(() => downloadCfg.value.auth ?? true)

    const activeTab = ref<'chart' | 'data' | ''>('')

    const panes = ref<Array<IGTabsPaneItem>>([])

    watch(
      [controlsPositionChartPanel, controlsPositionDataPanel],
      () => {
        initTabInfo()
      },
      {
        immediate: true,
      }
    )

    function initTabInfo() {
      if (!unref(controlsPositionChartPanel).length && !unref(controlsPositionDataPanel).length) {
        activeTab.value = ''
        panes.value = []
      }
      if (unref(controlsPositionChartPanel).length && !unref(controlsPositionDataPanel).length) {
        activeTab.value = 'chart'
        panes.value = [
          {
            label: ins?.proxy.$tc('analysisChart.fullScreen.pane.chart') || '',
            key: 'chart',
          },
        ]
      }
      if (!unref(controlsPositionChartPanel).length && unref(controlsPositionDataPanel).length) {
        activeTab.value = 'data'
        panes.value = [
          {
            label: ins?.proxy.$tc('analysisChart.fullScreen.pane.data') || '',
            key: 'data',
          },
        ]
      }
      if (unref(controlsPositionChartPanel).length && unref(controlsPositionDataPanel).length) {
        activeTab.value = 'data'
        panes.value = [
          {
            label: ins?.proxy.$tc('analysisChart.fullScreen.pane.data') || '',
            key: 'data',
          },
          {
            label: ins?.proxy.$tc('analysisChart.fullScreen.pane.chart') || '',
            key: 'chart',
          },
        ]
      }
    }

    const { echartsRefs } = useAnalysisRegisterChartRefs()

    function handleDownload() {
      if (isEmpty.value) return

      if (isFreeUser.value && downloadAuth.value) {
        return changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_NORMAL, trigger_point: 'DOWNLOAD' })
      }

      const { type } = downloadCfg.value

      if (type === 'custom') {
        emit('download')
      }

      if (type === 'chart-img') {
        const echartInstance = getEchartInstance()
        if (echartInstance) {
          exportChartImage(echartInstance, props.title || '', isCN.value, {
            userId: userInfo.value?.user_id || '',
          })
        }
      }
      function getEchartInstance(): EChartsType | undefined {
        // 如果有chartInsFinder，根据finder找到对应的echart实例
        if (props.downloadCfg.chartInsFinder) {
          return echartsRefs.value.find((item) => {
            return item.displayType === props.downloadCfg.chartInsFinder?.(props.controls)
          })?.chartIns as EChartsType
        }
        // 默认返回第一个echart实例
        return echartsRefs.value[0]?.chartIns as EChartsType
      }
      emit('afterDownload')
    }

    props.escape &&
      useEventListener(document, 'keyup', (evt) => {
        if (evt.key.toLowerCase() === 'escape') {
          emit('close')
        }
      })
    onMounted(() => {
      props.escape &&
        showSingleToast({
          message: ins?.proxy.$t('analysisChart.fullScreen.esc') as string,
          type: 'info',
          duration: 3000,
        })
    })

    return {
      controlsPositionChartPanel,
      controlsPositionDataPanel,
      activeTab,
      panes,
      handleDownload,
      showDownload,
      isCN,
    }
  },
  methods: {
    renderDisplayControlItem(item: IAnalysisChartDisplayControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartDisplaySwitch v-model={this.value[item.bindField]} options={item.items}></BAnalysisChartDisplaySwitch>
        </BAnalysisChartControlItem>
      )
    },
    renderRadioControlItem(item: IAnalysisChartRadioControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartRadioGroup
            options={item.items}
            v-model={this.value[item.bindField]}
            layout={layout === 'horizontal' ? 'inline' : 'block'}
            isCN={this.isCN}
            isManualTrigger={item.isManualTrigger}
          ></BAnalysisChartRadioGroup>
        </BAnalysisChartControlItem>
      )
    },
    renderRollupControlItem(item: IAnalysisChartRollupControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartRollupSwitch text={item.text} v-model={this.value[item.bindField]}></BAnalysisChartRollupSwitch>
        </BAnalysisChartControlItem>
      )
    },
    renderCheckboxGroupControlItem(item: IAnalysisChartCheckboxGroupControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartCheckboxGroup
            min={item.minChecked}
            options={item.options}
            v-model={this.value[item.bindField]}
          ></BAnalysisChartCheckboxGroup>
        </BAnalysisChartControlItem>
      )
    },
    renderCheckboxControlItem(item: IAnalysisChartCheckboxControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartCheckbox name={item.name} v-model={this.value[item.bindField]}></BAnalysisChartCheckbox>
        </BAnalysisChartControlItem>
      )
    },
    renderDropdownSingleSelectControlItem(item: IAnalysisChartDropDownSingleSelectControl, layout: 'vertical' | 'horizontal', blod: boolean) {
      return (
        <BAnalysisChartControlItem item={item} layout={layout} blod={blod}>
          <BAnalysisChartDropdownSingleSelect
            v-model={this.value[item.bindField]}
            options={item.options}
            title={item.title}
          ></BAnalysisChartDropdownSingleSelect>
        </BAnalysisChartControlItem>
      )
    },
    renderControl(item: IAnalysisChartControlItem, layout: 'vertical' | 'horizontal', blod: boolean) {
      if (item.type === 'display') return this.renderDisplayControlItem(item, layout, blod)
      if (item.type === 'radio') return this.renderRadioControlItem(item, layout, blod)
      if (item.type === 'rollup') return this.renderRollupControlItem(item, layout, blod)
      if (item.type === 'checkbox') return this.renderCheckboxControlItem(item, layout, blod)
      if (item.type === 'checkbox-group') return this.renderCheckboxGroupControlItem(item, layout, blod)
      if (item.type === 'dropdown-single-select') return this.renderDropdownSingleSelectControlItem(item, layout, blod)
      return null
    },
    renderFullScreen() {
      return (
        <span
          onClick={() => this.$emit('close')}
          style="line-height: 0;"
          class="inline-flex items-center p-1 rounded hover:bg-gray-30 cursor-pointer"
        >
          <GIcon svgName="SolidResizeCollapse" useSvgDefaultColor={true} size={24}></GIcon>
          <span class="text-text-t1 leading-6 text-sm ml-1">{this.$t('analysisChart.fullScreen.close')}</span>
        </span>
      )
    },
    renderDownload() {
      return (
        <GTooltip placement="top" theme="dark" content={this.downloadCfg.label || (this.$t('common.export') as string)}>
          <span class="inline-flex items-center p-1 rounded hover:bg-gray-30 cursor-pointer" onClick={this.handleDownload}>
            <GIcon svgName="SolidExport" size={24} color={this.isEmpty ? '#b3bac5' : '#495973'}></GIcon>
            {this.downloadCfg.showLabel && <span class="ml-1">{this.downloadCfg.label || (this.$t('common.export') as string)}</span>}
          </span>
        </GTooltip>
      )
    },
    renderHeader() {
      return (
        <div class="pt-3 px-6 flex items-center">
          <div class="leading-6 text-base text-text-t1 font-semibold">{this.$slots.title || this.title}</div>
          <div class="ml-auto leading-none flex items-center">
            {this.$slots.headerActionSlot ? this.$slots.headerActionSlot : null}
            {this.showDownload ? this.renderDownload() : null}
            {this.showFullScreen ? this.renderFullScreen() : null}
          </div>
        </div>
      )
    },
    renderSubHeader() {
      return (
        <div class="pb-3 px-6 border-b border-solid border-gray-40" style={this.subHeaderStyle}>
          {this.desc && <div class="leading-4 text-xs text-text-t2 mt-2">{this.desc}</div>}
        </div>
      )
    },
    renderDataPanel() {
      return (
        <div class="flex flex-col px-2">
          {this.dataPanelTitle ? <div class="font-semibold leading-5 text-sm text-text-t1">{this.dataPanelTitle}</div> : null}
          {this.controlsPositionDataPanel.map((item, index) => (
            <div class={{ 'mt-6': index !== 0 }}>{this.renderControl(item, 'vertical', true)}</div>
          ))}
        </div>
      )
    },
    renderChartPanel() {
      return (
        <div class="flex flex-col px-2">
          {this.chartPanelTitle ? <div class="font-semibold leading-5 text-sm text-text-t1">{this.chartPanelTitle}</div> : null}
          {this.controlsPositionChartPanel.map((item, index) => (
            <div class={{ 'mt-6': index !== 0 }}>{this.renderControl(item, 'vertical', true)}</div>
          ))}
        </div>
      )
    },
    renderRight() {
      if (!this.controlsPositionChartPanel.length && !this.controlsPositionDataPanel.length) return null
      return (
        <div class="pt-4 pb-6 px-2 border-l border-solid border-gray-40 min-h-full flex flex-col" style={{ width: `${DRAWER_WIDTH}px` }}>
          <div class="flex-1 overflow-hidden">
            <GTabs flex={true} size="small" v-model={this.activeTab} panes={this.panes}>
              <template slot="data">{this.renderDataPanel()}</template>
              <template slot="chart">{this.renderChartPanel()}</template>
            </GTabs>
          </div>
        </div>
      )
    },
  },
  render() {
    return (
      <BCardContainer
        border={false}
        emptyMode={true}
        emptyCls="p-4"
        mainLayout="left-right"
        isLoading={this.isLoading}
        isEmpty={this.isEmpty}
        isDrawer={this.isDrawer}
        drawMaxWidth={DRAWER_WIDTH}
      >
        <template slot="header">{this.renderHeader()}</template>
        <template slot="subHeader">{this.renderSubHeader()}</template>
        <template slot="default">
          {this.useDefaultContentCls ? (
            <div class="h-full overflow-y-auto">
              <div class="px-6 py-4">{this.$slots.default}</div>
            </div>
          ) : (
            this.$slots.default
          )}
        </template>
        <template slot="right">{this.renderRight()}</template>
      </BCardContainer>
    )
  },
})
