import { IAggregationData, IAggregationParams, IDrugDealDetail, IDrugDealGroupByDrugDeal, ISearchParams } from '@patsnap/synapse_common_interface'
import { AxiosRequestConfig } from 'axios'
import { IGroupAggregationParams, ISearchResult } from '../../types'
import { transformAggregationResponse } from '../../utils/business/aggregationTransform/base'
import { transformDrugDealAggregationSingleResult } from '../../utils/business/aggregationTransform/drug-deal'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DrugDealApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getSearchCount(params: ISearchParams): Promise<IHttpWrap<ISearchResult<any>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/search_for_count'),
      cache: true,
    })
  }

  /**
   * 交易搜索列表，不计搜索次数
   */
  async getDrugDealList(
    params: ISearchParams & { all_org?: boolean },
    config?: AxiosRequestConfig
  ): Promise<IHttpWrap<ISearchResult<IDrugDealDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/search'),
      trackingName: 'DRUG_DEAL_LIST',
      cache: true,
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }
  /**
   * 交易搜索列表，计搜索次数
   */
  async getDrugDealListWithCalcLimits(
    params: ISearchParams & { all_org?: boolean },
    config?: AxiosRequestConfig
  ): Promise<IHttpWrap<ISearchResult<IDrugDealDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal'),
      trackingName: 'DRUG_DEAL_LIST',
      cache: true,
      ignoreErrorCodes: config?.ignoreErrorCodes || [],
    })
  }
  /**
   * 此接口在非列表页调用,不会埋点
   * @param params
   * @returns
   */
  async getDrugDealListInDetail(params: ISearchParams & { all_org?: boolean }): Promise<IHttpWrap<ISearchResult<IDrugDealDetail>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/search'),
      cache: true,
    })
  }

  async getAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugDealAggregationSingleResult))
  }

  async getDealAnalysisAggregation(params: IAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/aggregation/analysis'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugDealAggregationSingleResult))
  }

  async getDrugDealGroupList(params: ISearchParams): Promise<IHttpWrap<ISearchResult<IDrugDealGroupByDrugDeal>>> {
    return this.http({
      method: 'POST',
      data: params,
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/group/search'),
      trackingName: 'DRUG_DEAL_GROUP_LIST',
      cache: true,
    })
  }

  async getGroupAggregation(params: IGroupAggregationParams): Promise<IHttpWrap<IAggregationData>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/group/aggregation'),
      data: params,
      method: 'POST',
      cache: true,
    }).then((rt) => transformAggregationResponse(rt, transformDrugDealAggregationSingleResult))
  }

  async getBatchDeals(params: { ids: string[]; translation?: boolean }): Promise<IHttpWrap<ISearchResult<IDrugDealDetail>>> {
    return this.http({
      url: this.getUrl('pharmsnap_webapi/1.0/drug_deal/batch'),
      data: params,
      method: 'POST',
      cache: true,
    })
  }
}
