import { useMergedState } from '@pharmsnap/shared/composition'
import { computed, defineComponent, toRef } from '@vue/composition-api'
import styles from './GDrawer.module.scss'
import { gDrawerProps } from './GDrawerProps'
import { GDrawerToggle } from './GDrawerToggle'

export const GDrawer = defineComponent({
  name: 'GDrawer',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: gDrawerProps(),
  setup(props, ctx) {
    const drawerSize = computed(() => {
      const size = props.size
      return typeof size === 'number' ? `${size}px` : size
    })
    const [mergedVisible, setVisible] = useMergedState(false, {
      value: toRef(props, 'visible'),
      defaultValue: props.defaultVisible,
      onChange(val) {
        ctx.emit('change', val)
      },
    })
    const position = computed(() => {
      const size = mergedVisible.value ? drawerSize.value : 0
      return props.direction === 'rtl' ? `right: ${size}` : `left: ${size}`
    })
    return {
      drawerSize,
      mergedVisible,
      position,
      setVisible,
    }
  },
  methods: {
    renderDrawerSide() {
      return (
        <div class={['absolute bottom-11 top-0']} style={this.position}>
          {this.$slots.drawSide ?? <GDrawerToggle value={this.visible} onChange={this.setVisible} direction={this.direction} />}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={[styles.gDrawer, styles[this.direction], this.mergedVisible && this.maskDisabled && styles.gDrawerFull, this.wrapperClass]}>
        {/* mask */}
        {this.mergedVisible && this.maskDisabled && <div class={[styles.gDrawerMask, 'drawer-mask', this.wrapperMaskClass]}></div>}
        {/* container */}
        <section
          class={[styles.gDrawerBody, this.mergedVisible && styles.gDrawerBodyOpen]}
          style={`width: ${this.drawerSize};${this.direction === 'rtl' ? 'right: 0' : 'left:0'}`}
        >
          {this.$slots.default}
        </section>
        {/* drawer side */}
        {this.renderDrawerSide()}
      </div>
    )
  },
})
