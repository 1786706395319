/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { computed, ref, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, isUndefined } from 'lodash'
import { IFilterValueNext } from '..'
import { DEFAULT_LIMIT_CONTAINER_CONFIG } from '../config'
import {
  IFilterAggValueNext,
  IFilterItemLimitContainerConfig,
  IFilterItemListData,
  IFilterItemListRollupData,
  IFilterItemUiType,
  IFilterSingleAggFiledConfig,
  IFilterSwitchAggFieldConfig,
} from '../type'
import { getRealSimpleText, transAggValue2FilterAggValue } from '../utils'
import { useFilterAdvanceEnhanceService } from './useFilterAdvanceEnhanceService'
import { useFilterListAutocompleteService } from './useFilterAutocompleteService'
import { useFilterLimitContainerService } from './useFilterLimitContainerService'
import { IFilterServiceInjection, useInjectFilterService } from './useProvideFilterService'

type IUseFilterUiListCommonServiceOptions = {
  list: MaybeRef<IFilterAggValueNext[]>
  limitContainerConfig: MaybeRef<IFilterItemLimitContainerConfig>
}

export function useFilterUiListCommonService(options: IUseFilterUiListCommonServiceOptions) {
  const { list, limitContainerConfig } = options

  const { filterService, selectService, locale } = useInjectFilterService() as IFilterServiceInjection

  const { showExpandTrigger, showMore, displayList, triggerText, onExpand, onCollapse } = useFilterLimitContainerService({
    limitContainerConfig: limitContainerConfig,
    list: list,
    onBeforeCollapse: filterService.hooks.onBeforeCollapse,
    onBeforeExpand: filterService.hooks.onBeforeExpand,
    locale,
  })

  function check(value: IFilterValueNext) {
    if (selectService.checkIsDisabled(value) || selectService.checkIsSelected(value)) {
      filterService.hooks.onSelectedValueExist?.()
      return
    }

    const limit = unref(filterService.selectedLimit)

    if (!isUndefined(limit) && filterService.hooks.onSelectedValueLimit) {
      if (unref(selectService.filterValueTemporaryList).length >= limit) {
        filterService.hooks.onSelectedValueLimit(limit)
        return
      }
    }

    selectService.addFilterValueToTemporary(value)
  }

  function unCheck(value: IFilterValueNext) {
    selectService.removeFilterValueFromTemporary(value)
  }

  function onCheck(value: IFilterAggValueNext, action: 'add' | 'remove') {
    const { raw, count, ...filterValue } = value
    if (action === 'add') {
      check(filterValue)
    }
    if (action === 'remove') {
      unCheck(filterValue)
    }
  }

  return {
    showExpandTrigger,
    showMore,
    displayList,
    triggerText,
    onExpand,
    onCollapse,
    onCheck,
    check,
    unCheck,
  }
}

export function useFilterUiListService<IDENTITY extends string = string, AGG_FIELD extends string = string>(options: {
  identity: MaybeRef<IDENTITY>
  uiType: MaybeRef<IFilterItemUiType>
}) {
  const { identity, uiType } = options

  const { filterService, selectService, locale } = useInjectFilterService() as IFilterServiceInjection

  const state = computed(() => filterService.stateRecord[unref(identity)])

  const data = computed(() => state.value.data?.[unref(uiType)] as IFilterItemListData | undefined)

  const limitContainer = computed(() => unref(data)?.limitContainer || DEFAULT_LIMIT_CONTAINER_CONFIG)

  const fieldConfig = computed(() => unref(state)._meta.originAggFieldConfig as IFilterSingleAggFiledConfig)

  const supportAdvanceFilter = computed(() => {
    const config = unref(state)._meta.originLayoutConfig
    return !!config.uiAggField.advanceUiTypes?.length
  })

  const processedList = computed(() => {
    const originList = unref(data)?.data || []
    const config = unref(fieldConfig)

    const list = originList
      .map((item) => transAggValue2FilterAggValue(item, unref(identity), config.field, config.filterType))
      .filter((i) => !!i) as IFilterAggValueNext<IDENTITY, AGG_FIELD>[]

    return list
  })

  const {
    showExpandTrigger,
    showMore,
    displayList: originDisplayList,
    triggerText: originTriggerText,
    onExpand: originOnExpand,
    onCollapse,
    onCheck,
  } = useFilterUiListCommonService({
    list: processedList,
    limitContainerConfig: limitContainer,
  })

  const { triggerText, onExpand } = useFilterAdvanceEnhanceService({
    isAdvance: supportAdvanceFilter,
    identity,
    triggerText: originTriggerText,
    onExpand: originOnExpand,
  })

  const {
    list: displayList,
    hasSelectFromAutocomplete,
    onClear,
    onSelect,
    keywords,
    placeholder,
  } = useFilterListAutocompleteService({
    locale,
    list: originDisplayList,
  })

  const onEnhanceAutocompleteSelect: typeof onSelect = (type, value) => {
    onSelect(type, value)
    const id = unref(value.id)
    const found = unref(originDisplayList).find((i) => String(i.uuid) === String(id))
    if (found) {
      onCheck(found, 'add')
    }
  }

  return {
    showMore,
    showExpandTrigger,
    displayList,
    locale,
    triggerText,
    onCheck,
    onExpand,
    onCollapse,
    checkIsSelected: selectService.checkIsSelected,
    checkIsDisabled: selectService.checkIsDisabled,
    isEmpty: computed(() => unref(data)?.data.length === 0),
    isAutocompleteEmpty: computed(() => unref(hasSelectFromAutocomplete) && unref(displayList).length === 0),
    onAutocompleteSelect: onEnhanceAutocompleteSelect,
    onAutocompleteClear: onClear,
    autocompleteKeywords: keywords,
    autocompletePlaceholder: placeholder,
  }
}

export function useFilterUiListRollupService<IDENTITY extends string = string, AGG_FIELD extends string = string>(options: {
  identity: MaybeRef<IDENTITY>
  uiType: MaybeRef<IFilterItemUiType>
}) {
  const { identity, uiType } = options

  const { filterService, selectService, locale } = useInjectFilterService() as IFilterServiceInjection

  const state = computed(() => filterService.stateRecord[unref(identity)])

  const data = computed(() => state.value.data?.[unref(uiType)] as IFilterItemListRollupData | undefined)

  const limitContainer = computed(() => unref(data)?.limitContainer || DEFAULT_LIMIT_CONTAINER_CONFIG)

  const isLoading = ref(false)

  const fieldConfig = computed(() => state.value._meta.originAggFieldConfig as IFilterSwitchAggFieldConfig)

  const activeFieldConfig = computed(() => {
    const [noRollupFieldConfig, rollupFieldConfig] = unref(fieldConfig).fields
    return unref(data)?.rollup ? rollupFieldConfig : noRollupFieldConfig
  })

  const rollupTriggerText = computed(() => getRealSimpleText(unref(data)?.rollupTriggerText || '', unref(locale)))

  const rollupTriggerTooltip = computed(() => getRealSimpleText(unref(data)?.rollupTriggerTooltip || '', unref(locale)))

  const supportAdvanceFilter = computed(() => {
    const config = unref(state)._meta.originLayoutConfig
    return !!config.uiAggField.advanceUiTypes?.length
  })

  const processedList = computed(() => {
    const originList = unref(data)?.data || []

    const config = unref(activeFieldConfig)

    const list = originList
      .map((item) => transAggValue2FilterAggValue(item, unref(identity), config.field, config.filterType))
      .filter((i) => !!i) as IFilterAggValueNext<IDENTITY, AGG_FIELD>[]

    return list
  })

  const {
    showExpandTrigger,
    showMore,
    displayList: originDisplayList,
    triggerText: originTriggerText,
    onExpand: originOnExpand,
    onCollapse,
    onCheck,
  } = useFilterUiListCommonService({
    list: processedList,
    limitContainerConfig: limitContainer,
  })

  const { triggerText, onExpand } = useFilterAdvanceEnhanceService({
    isAdvance: supportAdvanceFilter,
    identity,
    triggerText: originTriggerText,
    onExpand: originOnExpand,
  })

  const {
    list: displayList,
    hasSelectFromAutocomplete,
    onClear,
    onSelect,
    keywords,
    placeholder,
  } = useFilterListAutocompleteService({
    locale,
    list: originDisplayList,
  })

  const onEnhanceAutocompleteSelect: typeof onSelect = (type, value) => {
    onSelect(type, value)
    const id = unref(value.id)
    const found = unref(originDisplayList).find((i) => String(i.uuid) === String(id))
    if (found) {
      onCheck(found, 'add')
    }
  }

  async function onRollupChange(isRollup: boolean) {
    const [noRollupFieldConfig, rollupFieldConfig] = unref(fieldConfig).fields

    const originFieldConfig = isRollup ? noRollupFieldConfig : rollupFieldConfig

    const isFieldSelected = selectService.checkFieldIsInGroup(originFieldConfig.field)

    let action: 'refine' | 'update' = 'update'

    if (isFieldSelected) action = 'refine'

    if (action === 'refine') {
      const queryItems = selectService.getOriginQueryItems([originFieldConfig.field])
      filterService.hooks.onSubmit(queryItems, 'refine')
    }
    if (action === 'update') {
      // 移除当前字段的选中值
      selectService.removeAllFilterValueFromTemporary([originFieldConfig.field])
      // 更新数据
      isLoading.value = true

      await filterService.updateFilterItemData(unref(state), {
        rollup: isRollup,
        uiType: unref(uiType),
        fieldConfig: cloneDeep(unref(state)._meta.originAggFieldConfig),
      })
      isLoading.value = false
    }
  }

  return {
    showExpandTrigger,
    showMore,
    displayList,
    triggerText,
    onExpand,
    onCollapse,
    onCheck,
    onRollupChange,
    rollupTriggerText,
    rollupTriggerTooltip,
    locale,
    data,
    isLoading,
    checkIsSelected: selectService.checkIsSelected,
    checkIsDisabled: selectService.checkIsDisabled,
    isEmpty: computed(() => unref(data)?.data.length === 0),
    isAutocompleteEmpty: computed(() => unref(hasSelectFromAutocomplete) && unref(displayList).length === 0),
    onAutocompleteSelect: onEnhanceAutocompleteSelect,
    onAutocompleteClear: onClear,
    autocompleteKeywords: keywords,
    autocompletePlaceholder: placeholder,
  }
}
