import { PatentCollapseSetting } from '@pharmsnap/pharmsnap-web/features/patent-collapse-setting'
import { ISearchCollapse, ISearchCollapseRule } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import VClickOutside from 'v-click-outside'
import { BPtCollapseOptionPanel } from './BPtCollapseOptionPanel'
import $style from './BPtCollapseOptions.module.scss'
import { PATENT_COLLAPSE_ALL_FIELD } from './config'
import { useInjectPatentCollapse } from './usePatentCollapse'

export const BPtCollapseOptions = defineComponent({
  name: 'BPtCollapseOptions',
  directives: {
    clickOutside: VClickOutside.directive,
  },
  props: {
    field: {
      type: String,
      default: '',
    },
    rules: {
      type: Array as PropType<ISearchCollapseRule[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { state, action } = useInjectPatentCollapse()

    const currentFiled = ref('')

    const currentCollapseData = computed(() => {
      const data = state.collapseOptionRecord[currentFiled.value]
      return data
    })

    const showPanel = computed(() => currentFiled.value && !action.isUnCollapseField(currentFiled.value))

    const panelStyle = ref({})

    return {
      collapseFieldOptions: state.collapseFieldOptions,
      collapseField: state.collapseField,
      collapseRules: state.collapseRules,
      collapseOptionRecord: state.collapseOptionRecord,
      isUnCollapseField: action.isUnCollapseField,
      currentFiled,
      currentCollapseData,
      updateOptionRecord: action.updateOptionRecord,
      showPanel,
      panelStyle,
      addRule: action.addRule,
      deleteRule: action.deleteRule,
      pickCollapseParams: action.pickCollapseParams,
    }
  },
  methods: {
    handleUpdateCurrentField(field: string, index: number) {
      this.currentFiled = field
      this.panelStyle = {
        top: `${4 + index * 32}px`,
      }
      this.$emit('lock', false)
    },
    handleMouseEnterPanel() {
      this.$emit('lock', true)
    },
    handleChangeField(rule: ISearchCollapseRule, field: string) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, field: field })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleChangeType(rule: ISearchCollapseRule, type: ISearchCollapseRule['type']) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, type: type })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleChangeValue(rule: ISearchCollapseRule, value: string) {
      const originRule = cloneDeep(rule)
      const updateRule = cloneDeep({ ...rule, value: value })
      this.updateOptionRecord(originRule, updateRule)
    },
    handleAddRule(rule: ISearchCollapseRule) {
      this.addRule()
    },
    handleDelete(rule: ISearchCollapseRule) {
      this.deleteRule(rule)
    },
    handleCancel() {
      this.$emit('cancel')
      this.currentFiled = ''
    },
    handleConfirm() {
      this.save(this.currentFiled)
    },
    save(field: string) {
      const params: ISearchCollapse = this.pickCollapseParams(field)
      PatentCollapseSetting.getSingleton().collapseSetting = params
      this.$emit('save', params)
      this.currentFiled = ''
    },
    isActive(field: string) {
      if (this.collapseField === '') {
        return field === PATENT_COLLAPSE_ALL_FIELD
      }

      return this.collapseField === field
    },
  },
  render() {
    return (
      <div class={$style.container} v-click-outside={this.handleCancel}>
        {this.collapseFieldOptions.map((item, index) => {
          return (
            <div
              on={{ mouseenter: () => this.handleUpdateCurrentField(item.value, index), click: () => this.save(item.value) }}
              class={[$style.item, this.isActive(item.value) ? $style.itemActive : '']}
            >
              {item.label}
            </div>
          )
        })}
        {this.showPanel ? (
          <BPtCollapseOptionPanel
            key={this.currentFiled}
            nativeOn={{ mouseenter: this.handleMouseEnterPanel }}
            class={$style.panel}
            style={this.panelStyle}
            data={this.currentCollapseData}
            field={this.currentFiled}
            onChangeField={this.handleChangeField}
            onChangeType={this.handleChangeType}
            onChangeValue={this.handleChangeValue}
            onAdd={this.handleAddRule}
            onDelete={this.handleDelete}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          ></BPtCollapseOptionPanel>
        ) : null}
      </div>
    )
  },
})
