import { FlowFunction } from '@pharmsnap/shared/types'
import { curry } from 'lodash'
import { BasicPieChart } from '..'
import { ECOption, Plot } from '../core/plot'
import { mergeTooltip, mergeSeries, flow } from '../util'
import { getTooltip, getSeries, pieChartTooltipFormatter, getChartColor, pieChartLabelFormatter } from './config'

export class Pie extends Plot {
  public type = 'pie'

  protected getDefaultOption(options: ECOption): ECOption {
    const tooltip: FlowFunction<ECOption> = curry(mergeTooltip)(getTooltip)
    const mergeRectSeries: FlowFunction<ECOption> = curry(mergeSeries)(getSeries)
    return flow<ECOption>(tooltip, mergeRectSeries)(options)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  protected adaptor(params: BasicPieChart): ECOption {
    const { legend, grid, data, color, series } = params

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const options: ECOption = {
      tooltip: {
        formatter: pieChartTooltipFormatter,
      },
      grid,
      legend,
      ...(color ? { color } : { color: getChartColor(data.length) }),
      series: [series].map((i) => ({
        ...i,
        type: 'pie',
        encode: { itemName: 'name', value: 'value' },
        label: { formatter: pieChartLabelFormatter },
      })),
      dataset: {
        dimensions: [
          {
            name: 'name',
          },
          {
            name: 'value',
          },
        ],
        source: data,
      },
    }

    return options
  }
}
