/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import type { IBaseDrugInfo, IQuery, IQueryItemField } from '@patsnap/synapse_common_interface'
import { ISearchCollapse } from '@patsnap/synapse_common_interface'
import { getCurrentInstance, unref } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import { cloneDeep, isEqual, isUndefined, sum } from 'lodash'
import { sharedCtx } from '../context'
import type { IHistorySearchParams, IUseChartTwoDimTupleItem } from '../types'
import { checkGoingDownDrugListByDrugIds, getLast30daysRange } from '../utils'
import {
  getTimeRelatedQueryFieldByNavList,
  transSingleBar2QueryItem,
  transTextQueryField2EntityQueryField,
  transTwoDimTupleItems2QueryItem,
} from '../utils/navList'
import { IUseNavListBaseOptions, IUseNavListInterceptor, useNavList } from './useNavList'
import { useNavListIds } from './useNavListIds'
import { transformPhaseStackBarEventParam2QueryField } from './usePhaseStackBarChart'

export interface IUseBasicChartNavListOptions extends IUseNavListBaseOptions {
  appendTo?: MaybeRef<'must' | 'filter'>
  customQueryItemPicker?: (params: any) => IQueryItemField
  /**
   * query 结构
   */
  query: MaybeRef<IQuery>
  adjustQueryField?: (queryField: IQueryItemField) => IQueryItemField
  adjustSearchParams?: (params: IHistorySearchParams) => IHistorySearchParams | Promise<IHistorySearchParams>
  searchParams?: Partial<IHistorySearchParams>
}

interface IUseStackBarChartNavListOptions extends IUseNavListBaseOptions {
  appendTo?: MaybeRef<['must' | 'filter', 'must' | 'filter']>
  customQueryItemPicker?: (params: any) => { xAxisQuery: IQueryItemField; yAxisQuery: IQueryItemField }
  adjustQueryField?: (data: { xAxisQuery?: IQueryItemField; yAxisQuery?: IQueryItemField }) => {
    xAxisQuery?: IQueryItemField
    yAxisQuery?: IQueryItemField
  }
  adjustSearchParams?: (params: IHistorySearchParams) => IHistorySearchParams | Promise<IHistorySearchParams>
  adjustSearchParamsBeforeOpenPage?: (params: IHistorySearchParams) => Promise<IHistorySearchParams> | IHistorySearchParams
  query: MaybeRef<IQuery>
}

interface IUseHeatmapChartNavListOptions extends IUseNavListBaseOptions {
  appendTo?: MaybeRef<['must' | 'filter', 'must' | 'filter']>
  adjustQueryField?: (data: { xAxisQuery?: IQueryItemField; yAxisQuery?: IQueryItemField }) => {
    xAxisQuery?: IQueryItemField
    yAxisQuery?: IQueryItemField
  }
  query: MaybeRef<IQuery>
  last30AppendTo?: MaybeRef<'must' | 'filter'>
  last30Interceptors?: IUseNavListInterceptor<[IUseChartTwoDimTupleItem<{ recent_30?: number; recent_30_from?: number; recent_30_to?: number }>[]]>[]
  collapse?: MaybeRef<ISearchCollapse | undefined>
}

interface IUseWorldmapChartNavListOptions extends IUseNavListBaseOptions {
  appendTo?: MaybeRef<'must' | 'filter'>
  customQueryItemPicker?: (params: any) => IQueryItemField
  adjustSearchParams?: (params: IHistorySearchParams) => IHistorySearchParams | Promise<IHistorySearchParams>
  /**
   * query 结构
   */
  query: MaybeRef<IQuery>

  fields?: string[]
}

export function useBasicBarChartNavList(options: IUseBasicChartNavListOptions) {
  const { query, appendTo = 'filter', adjustQueryField, customQueryItemPicker, searchParams = {}, adjustSearchParams } = options

  const { enhanceAuthNavList, defaultHistorySearchParams, dataType, navToList } = useNavList(options)

  const onClickBar = enhanceAuthNavList(async (params: any) => {
    // const barItem = params.value as IUseBarCount
    const originQueryField = customQueryItemPicker
      ? customQueryItemPicker(params)
      : transTextQueryField2EntityQueryField(transSingleBar2QueryItem(params.value))
    const queryItem = adjustQueryField ? adjustQueryField(originQueryField) : originQueryField
    const originQuery = cloneDeep(unref(query))
    const appendKey = unref(appendTo)
    if (!originQuery[appendKey]) originQuery[appendKey] = []
    originQuery[appendKey]?.push(queryItem)
    const defaultParams = cloneDeep({ ...defaultHistorySearchParams, ...searchParams })
    const historySearchParams: IHistorySearchParams = {
      ...defaultParams,
      data_type: dataType.value,
      query: {
        ...defaultParams.query,
        ...originQuery,
      },
    }
    await navToList(historySearchParams, {
      adjustSearchParamsBeforeOpenPage: adjustSearchParams,
    })
  })

  return {
    onClickBar,
  }
}

export function useHeatmapChartNavList(options: IUseHeatmapChartNavListOptions) {
  const {
    query,
    navList,
    appendTo = ['filter', 'filter'],
    last30AppendTo = unref(navList) === 'drug_group_dev_status' || unref(navList) === 'patent' ? 'must' : 'filter',
    adjustQueryField,
    collapse,
  } = options

  const { enhanceAuthNavList, defaultHistorySearchParams, dataType, navToList, actualInterceptors } = useNavList(options)

  const { enhanceAuthNavList: enhanceAuthLast30NavList } = useNavList({
    ...options,
    interceptors: options.last30Interceptors,
  })

  actualInterceptors.unshift((params) => {
    if (params.componentType !== 'series') {
      return false
    }
    return true
  })

  const getSearchParams = (data: { xAxisQuery?: IQueryItemField; yAxisQuery?: IQueryItemField }) => {
    const { xAxisQuery, yAxisQuery } = data
    const originQuery = cloneDeep(unref(query))
    const [xAppendTo, yAppendTo] = unref(appendTo)
    const collapseData = unref(collapse)
    if (!originQuery[xAppendTo]) originQuery[xAppendTo] = []
    if (!originQuery[yAppendTo]) originQuery[yAppendTo] = []
    xAxisQuery && originQuery[xAppendTo]?.push(xAxisQuery)
    yAxisQuery && originQuery[yAppendTo]?.push(yAxisQuery)
    const defaultParams = cloneDeep(defaultHistorySearchParams)
    const historySearchParams: IHistorySearchParams = {
      ...defaultParams,
      data_type: dataType.value,
      query: {
        ...defaultParams.query,
        ...originQuery,
      },
    }
    if (collapseData) {
      historySearchParams.collapse = collapseData
    }
    return historySearchParams
  }

  const getIncrease30SearchParams = (
    tupleItems: IUseChartTwoDimTupleItem<{ recent_30?: number; recent_30_from?: number; recent_30_to?: number }>[]
  ) => {
    const infoList = tupleItems.map((i) => i[1].otherInfo).filter((i) => !!i) as Array<{
      recent_30?: number
      recent_30_from?: number
      recent_30_to?: number
    }>
    if (infoList.length === 0) return

    const increaseCount = sum(infoList.map((i) => i.recent_30 || 0))
    if (increaseCount === 0) return

    const { recent_30_from, recent_30_to } = infoList[0]
    const { from, to } =
      isUndefined(recent_30_from) || isUndefined(recent_30_to) ? getLast30daysRange(true) : { from: recent_30_from, to: recent_30_to }

    const { xAxisQuery: originXAxisQuery, yAxisQuery: originYAxisQuery } = transTwoDimTupleItems2QueryItem(tupleItems)

    const xAxisQueryTemp = originXAxisQuery && transTextQueryField2EntityQueryField(originXAxisQuery)

    const yAxisQueryTemp = originYAxisQuery && transTextQueryField2EntityQueryField(originYAxisQuery)

    const { xAxisQuery, yAxisQuery } = adjustQueryField
      ? adjustQueryField({ xAxisQuery: xAxisQueryTemp, yAxisQuery: yAxisQueryTemp })
      : { yAxisQuery: yAxisQueryTemp, xAxisQuery: xAxisQueryTemp }

    const timeRangeQueryField = getTimeRelatedQueryFieldByNavList([from, to], unref(navList))

    const timeRangeFields = timeRangeQueryField?.fields || []

    const hasSameXAxisField = isEqual(xAxisQuery?.fields, timeRangeFields) && timeRangeFields.length > 0

    const hasSameYAxisField = isEqual(yAxisQuery?.fields, timeRangeFields) && timeRangeFields.length > 0

    if (hasSameXAxisField && timeRangeQueryField?.value && xAxisQuery) {
      xAxisQuery.value = timeRangeQueryField.value
    }

    if (hasSameYAxisField && timeRangeQueryField?.value && yAxisQuery) {
      yAxisQuery.value = timeRangeQueryField.value
    }

    const searchParams = getSearchParams({ xAxisQuery, yAxisQuery })

    if (!hasSameXAxisField && !hasSameYAxisField && timeRangeQueryField?.value) {
      searchParams.query[unref(last30AppendTo)]?.push(timeRangeQueryField)
    }

    return searchParams
  }

  const onClickHeatmapCell = enhanceAuthNavList(async (params: any) => {
    console.log(params)
    const data = Array.isArray(params.value) ? (params.value[params.value.length - 1] as unknown as IUseChartTwoDimTupleItem[]) : []
    // 点击total
    if (!data || !Array.isArray(data)) return
    const tupleItems = data
    const { xAxisQuery, yAxisQuery } = adjustQueryField
      ? adjustQueryField(transTwoDimTupleItems2QueryItem(tupleItems))
      : transTwoDimTupleItems2QueryItem(tupleItems)
    await navToList(getSearchParams({ xAxisQuery, yAxisQuery }))
  })

  const onClickHeatmapLast30 = enhanceAuthLast30NavList(
    async (tupleItems: IUseChartTwoDimTupleItem<{ recent_30?: number; recent_30_from?: number; recent_30_to?: number }>[]) => {
      const searchParams = getIncrease30SearchParams(tupleItems)
      if (!searchParams) return
      await navToList(searchParams)
    }
  )

  const onFetchDrugDevStatusData = async (
    tupleItems: IUseChartTwoDimTupleItem<{ recent_30?: number; recent_30_from?: number; recent_30_to?: number }>[]
  ): Promise<{ total: number; sub_total: number; items: IBaseDrugInfo[] }> => {
    const searchParams = getIncrease30SearchParams(tupleItems)

    const emptyRes = { total: 0, sub_total: 0, items: [] }

    if (!searchParams) return emptyRes

    const rt = await sharedCtx.service.drug.getDrugList(searchParams)

    if (rt.success) {
      return {
        total: rt.data.total,
        sub_total: rt.data.sub_total || 0,
        items: rt.data.items || [],
      }
    } else {
      return emptyRes
    }
  }

  return {
    onClickHeatmapCell,
    onClickHeatmapLast30,
    onFetchDrugDevStatusData,
  }
}

export function usePhaseStackBarChartNavList(options: IUseStackBarChartNavListOptions) {
  const {
    query,
    appendTo = ['filter', 'filter'],
    adjustQueryField,
    adjustSearchParams,
    adjustSearchParamsBeforeOpenPage,
    customQueryItemPicker,
  } = options

  const { enhanceAuthNavList, defaultHistorySearchParams, dataType, navToList } = useNavList(options)

  const getSearchParamsFromStackBar = (params: any) => {
    const { xAxisQuery: xAxisQueryTemp, yAxisQuery: yAxisQueryTemp } = customQueryItemPicker
      ? customQueryItemPicker(params)
      : transTwoDimTupleItems2QueryItem(transformPhaseStackBarEventParam2QueryField(params))

    const { xAxisQuery, yAxisQuery } = adjustQueryField
      ? adjustQueryField({ xAxisQuery: xAxisQueryTemp, yAxisQuery: yAxisQueryTemp })
      : { yAxisQuery: yAxisQueryTemp, xAxisQuery: xAxisQueryTemp }

    const originQuery = cloneDeep(unref(query))
    const [xAppendTo, yAppendTo] = unref(appendTo)
    if (!originQuery[xAppendTo]) originQuery[xAppendTo] = []
    if (!originQuery[yAppendTo]) originQuery[yAppendTo] = []
    xAxisQuery && originQuery[xAppendTo]?.push(xAxisQuery)
    yAxisQuery && originQuery[yAppendTo]?.push(yAxisQuery)
    const defaultParams = cloneDeep(defaultHistorySearchParams)
    const historySearchParams: IHistorySearchParams = {
      ...defaultParams,
      data_type: dataType.value,
      query: {
        ...defaultParams.query,
        ...originQuery,
      },
    }
    return historySearchParams
  }

  const onClickStackBar = enhanceAuthNavList(async (params: any) => {
    await navToList(getSearchParamsFromStackBar(params), {
      adjustSearchParamsAfterOpenPage: adjustSearchParams,
      adjustSearchParamsBeforeOpenPage,
    })
  })

  return {
    onClickStackBar,
  }
}

export function useWorldmapChartNavList(options: IUseWorldmapChartNavListOptions) {
  const { query, appendTo = 'filter', customQueryItemPicker, fields = ['COUNTRY_NORMALIZED_ID'], adjustSearchParams } = options

  const { enhanceAuthNavList, defaultHistorySearchParams, dataType, navToList } = useNavList(options)

  const onClickWorldmap = enhanceAuthNavList(async (params: any) => {
    const queryItem: IQueryItemField = customQueryItemPicker
      ? customQueryItemPicker(params)
      : {
          type: 'field',
          fields,
          value: [params.data, ...(params.data?.subLocations || [])].map((i) => ({
            type: 'text',
            value: i.originData.key,
            display_name_cn: i.originData.display_name_cn,
            display_name_en: i.originData.display_name_en,
          })),
        }
    const originQuery = cloneDeep(unref(query))
    const appendKey = unref(appendTo)
    if (!originQuery[appendKey]) originQuery[appendKey] = []
    originQuery[appendKey]?.push(queryItem)
    const defaultParams = cloneDeep(defaultHistorySearchParams)
    const historySearchParams: IHistorySearchParams = {
      ...defaultParams,
      data_type: dataType.value,
      query: {
        ...defaultParams.query,
        ...originQuery,
      },
    }
    await navToList(historySearchParams, {
      adjustSearchParamsBeforeOpenPage: adjustSearchParams,
    })
  })

  return {
    onClickWorldmap,
  }
}

export function usePhaseStackBarChartWithIdsNavList(options: IUseStackBarChartNavListOptions) {
  const { navList, query, adjustQueryField, adjustSearchParams, customQueryItemPicker } = options
  const ins = getCurrentInstance()

  const getSearchParamsFromStackBar = (params: any) => {
    const { xAxisQuery: xAxisQueryTemp, yAxisQuery: yAxisQueryTemp } = customQueryItemPicker
      ? customQueryItemPicker(params)
      : transTwoDimTupleItems2QueryItem(transformPhaseStackBarEventParam2QueryField(params))

    const { xAxisQuery, yAxisQuery } = adjustQueryField
      ? adjustQueryField({ xAxisQuery: xAxisQueryTemp, yAxisQuery: yAxisQueryTemp })
      : { yAxisQuery: yAxisQueryTemp, xAxisQuery: xAxisQueryTemp }

    return {
      xAxisQuery,
      yAxisQuery,
    }
  }

  const { onNavToListWithIds } = useNavListIds({
    navList,
    query,
    appendTo: 'must',
    auth: true,
  })

  const onClickStackBar = async (params: any) => {
    const { ids, dataType, field, ...reset } = params
    const limitedDrugIds = await checkGoingDownDrugListByDrugIds(ids, ins?.proxy.$tc('drug.limitWarning') || '')
    const { xAxisQuery, yAxisQuery } = getSearchParamsFromStackBar(reset)
    await onNavToListWithIds(limitedDrugIds, dataType, field, async (searchParams) => {
      xAxisQuery && searchParams.query.must?.push(xAxisQuery)
      yAxisQuery && searchParams.query.must?.push(yAxisQuery)
      searchParams.query.filter && searchParams.query.must?.push(...searchParams.query.filter)
      searchParams.query.filter = []
      return adjustSearchParams ? await adjustSearchParams(searchParams) : searchParams
    })
  }

  return {
    onClickStackBar,
  }
}
