import {
  E_AGGREGATION_TYPE,
  IAggregationData,
  IAggregationItem,
  IAggregationParams,
  IQuery,
  IQueryDataType,
  ITranslationalMedicineIndexField,
} from '@patsnap/synapse_common_interface'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import {
  IResearchDirectionChartAnalysisEntityType,
  IResearchDirectionChartEntityItem,
  IResearchDirectionChartParams,
  IResearchDirectionChartResponse,
} from '@pharmsnap/shared/src/types/translational-medicine'
import { getRangeQueryField, getSpecialLang, getYearRangeFromNow } from '@pharmsnap/shared/src/utils'
import { aggResultIsEmpty } from '@pharmsnap/shared/src/utils/business/aggregationTransform/base'
import { computed, reactive, ref, shallowRef, unref, watch } from '@vue/composition-api'
import { MaybeRef } from '@vueuse/core'
import dayjs from 'dayjs'
import { cloneDeep, isUndefined } from 'lodash'
import { IAnalysisChartControlItem, IAnalysisChartControlLayoutItem, IAnalysisDisplayIconType } from '../../BAnalysisChartNext/type'

export type IUseTransMedicineAggChartType =
  | 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT'
  | 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT'
  | 'ACTIVE_ORGANIZATION_STACK_BAR'

export interface IUseTransMedicineAggChartOptions {
  query?: MaybeRef<IQuery>
  type: MaybeRef<IUseTransMedicineAggChartType>
}

type ITransMedicineTrendAnalysisEntityType = Extract<IQueryDataType, 'target' | 'disease' | 'drug_type'>

type ITransMedicineAggChartModel = {
  display: IAnalysisDisplayIconType
  rollup?: boolean
  top?: string
  time?: { selectedValue: 'all' | '10' | '20' | 'custom'; customTime?: [number, number] }
  entityType?: { selectedValue: ITransMedicineTrendAnalysisEntityType }
  entityCombineType?: { selectedValue: IResearchDirectionChartAnalysisEntityType }
}

const entityTypeToAggFieldMap: Record<ITransMedicineTrendAnalysisEntityType, ITranslationalMedicineIndexField> = {
  target: 'TARGET_ID_EXTEND',
  disease: 'DISEASE_ID',
  drug_type: 'DRUG_TYPE',
}

export const stackBarChartTypes: IUseTransMedicineAggChartType[] = ['ACTIVE_ORGANIZATION_STACK_BAR']
export const multiPieChartTypes: IUseTransMedicineAggChartType[] = ['RESEARCH_TREND_MULTI_PIE_DOUGHNUT', 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT']

const supportRollupControlChartTypes: IUseTransMedicineAggChartType[] = ['ACTIVE_ORGANIZATION_STACK_BAR']
const supportTopControlChartTypes: IUseTransMedicineAggChartType[] = ['ACTIVE_ORGANIZATION_STACK_BAR']
// 二维聚合，支持TOP控制
const subAggSupportTopControlChartTypes: IUseTransMedicineAggChartType[] = ['RESEARCH_TREND_MULTI_PIE_DOUGHNUT']

function initModel(chartType: IUseTransMedicineAggChartType) {
  const model: ITransMedicineAggChartModel = {
    display: 'multi-pie-doughnut',
  }

  // 根据类型赋值不同的display初始值
  if (stackBarChartTypes.includes(chartType)) {
    model.display = 'horizontal-bar'
  }
  if (supportRollupControlChartTypes.includes(chartType)) {
    model.rollup = true
  }

  if (supportTopControlChartTypes.includes(chartType) || subAggSupportTopControlChartTypes.includes(chartType)) {
    model.top = '10'
  }

  if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
    model.entityType = { selectedValue: 'target' }
    model.time = { selectedValue: '10' }
  }

  if (chartType === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
    model.entityCombineType = { selectedValue: 'TARGET_INDICATION' }
  }

  return model
}

const MAX_AGG_LIMIT = 8000
// 研究方向图：聚合limit
export const RESEARCH_DIRECTION_CHART_LIMIT = 200
// 研究方向图：一唯数据导出上限
export const RESEARCH_DIRECTION_CHART_ONE_DIM_ENTITY_EXPORT_LIMIT = 50

const aggregationItemRecordType: Record<IUseTransMedicineAggChartType, IAggregationItem> = {
  RESEARCH_TREND_MULTI_PIE_DOUGHNUT: {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'PUB_DT_YEAR',
    limit: MAX_AGG_LIMIT,
    sort_type: 'index',
    order: 'desc',
  },
  RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT: {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'THERAPEUTIC_AREA_ID',
  },
  ACTIVE_ORGANIZATION_STACK_BAR: {
    aggregation_type: E_AGGREGATION_TYPE.TERMS,
    aggregation_field: 'RESEARCH_SPONSOR_ID',
    sub_aggregation: [
      {
        aggregation_type: E_AGGREGATION_TYPE.TERMS,
        aggregation_field: 'SUBJECT_ID',
      },
    ],
  },
}

const entityNameRecord: Record<ITransMedicineTrendAnalysisEntityType, { en: string; cn: string }> = {
  target: { en: 'Target', cn: '靶点' },
  disease: { en: 'Indication', cn: '适应症' },
  drug_type: { en: 'Drug Type', cn: '药物类型' },
}

const entityCombineNameRecord: Record<IResearchDirectionChartAnalysisEntityType, { en: string; cn: string }> = {
  TARGET_INDICATION: { en: 'Target-Indication', cn: '靶点-适应症' },
  TARGET_DRUG_TYPE: { en: 'Target-Drug Type', cn: '靶点-药物类型' },
  DRUG_TYPE_INDICATION: { en: 'Drug Type-Indication', cn: '药物类型-适应症' },
}

export function useTransMedicineAggChart(options: IUseTransMedicineAggChartOptions) {
  const { query = { type: 'group', must: [], filter: [] }, type } = options

  const { locale } = useLocale()

  const loading = ref(true)

  const isEmpty = ref(true)
  const isResearchDirectionEmpty = ref(true)

  const model = reactive(initModel(unref(type)))

  const controls = reactive(initControls(unref(type)))

  const controlLayout = reactive(initControlLayout(unref(type), false))

  const controlLayoutFullscreen = reactive(initControlLayout(unref(type), true))

  const aggData = shallowRef<IAggregationData>({ aggregation_result: [{ items: [], aggregation_field: '', total: 0 }], total: 0 })

  const researchDirectionData = shallowRef<IResearchDirectionChartResponse>({
    aggregation_result: [{ items: [], aggregation_field: '', total: 0 }],
    total: 0,
  })
  const researchDirectionExpandDiseaseData = shallowRef<IResearchDirectionChartResponse>({
    aggregation_result: [{ items: [], aggregation_field: '', total: 0 }],
    total: 0,
  })
  const expandDisease = ref<IResearchDirectionChartEntityItem>()

  const researchDirectionChartAggData = computed(() => {
    return expandDisease.value ? researchDirectionExpandDiseaseData.value : researchDirectionData.value
  })

  const chartData = computed(() => {
    if (unref(type) === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      return researchDirectionChartAggData.value
    }
    return aggData.value
  })

  const isEmptyComputed = computed(() => {
    if (unref(type) === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      return isResearchDirectionEmpty.value
    }
    return isEmpty.value
  })

  const researchDirectionTargetItems = computed<IResearchDirectionChartEntityItem[]>(() => {
    if (!isResearchDirectionEmpty.value) {
      return (researchDirectionChartAggData.value.target_list || []).map((item) => ({
        id: item.target_id,
        name_cn: item.entity_name_cn || '',
        name_en: item.entity_name_en || '',
        type: 'target',
      }))
    }
    return []
  })

  const researchDirectionDrugTypeItems = computed<IResearchDirectionChartEntityItem[]>(() => {
    if (!isResearchDirectionEmpty.value) {
      return (researchDirectionChartAggData.value.drug_type_list || []).map((item) => ({
        id: item.dict_id,
        name_en: item.name_en,
        name_cn: item.name_cn,
        type: 'drug_type',
      }))
    }
    return []
  })

  const researchDirectionDiseaseItems = computed<IResearchDirectionChartEntityItem[]>(() => {
    if (!isResearchDirectionEmpty.value) {
      return (researchDirectionChartAggData.value.disease_list || []).map((item) => ({
        id: item.disease_id,
        name_en: getSpecialLang({ data: item.disease_name, field: 'name', lang: 'EN' }) || '',
        name_cn: getSpecialLang({ data: item.disease_name, field: 'name', lang: 'CN' }) || '',
        type: 'disease',
      }))
    }
    return []
  })

  const researchDirectionXDirectionList = computed(() => {
    const analysisEntityType = model.entityCombineType?.selectedValue
    if (analysisEntityType && !isResearchDirectionEmpty.value) {
      // 横向为药物类型
      if (analysisEntityType === 'TARGET_DRUG_TYPE') {
        return researchDirectionDrugTypeItems.value
      }
      // 横向为适应症数据
      return researchDirectionDiseaseItems.value
    }
    return []
  })

  const researchDirectionYDirectionList = computed(() => {
    const analysisEntityType = model.entityCombineType?.selectedValue
    if (analysisEntityType && !isResearchDirectionEmpty.value) {
      // 纵向为药物类型
      if (analysisEntityType === 'DRUG_TYPE_INDICATION') {
        return researchDirectionDrugTypeItems.value
      }
      // 纵向为靶点
      return researchDirectionTargetItems.value
    }
    return []
  })

  const aggParams = computed(() => {
    const clonedQuery = getQuery(unref(type), unref(query), cloneDeep(model))
    const clonedAggregation = getAggregationItem(unref(type), cloneDeep(model))
    const params: IAggregationParams = {
      data_type: 'translational_medicine',
      query: clonedQuery,
      aggregation: [clonedAggregation],
    }
    return params
  })

  const researchDirectionAggParams = computed<IResearchDirectionChartParams>(() => {
    const clonedQuery = cloneDeep(unref(query))
    return {
      data_type: 'translational_medicine',
      query: clonedQuery,
      dimension: model.entityCombineType?.selectedValue as IResearchDirectionChartAnalysisEntityType,
      expand_disease_id: expandDisease.value?.id,
      limit: RESEARCH_DIRECTION_CHART_LIMIT,
    }
  })

  const categoryName = computed(() => {
    const chartType = unref(type)

    switch (chartType) {
      case 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT':
        return unref(locale) === 'cn' ? '出版年' : 'Publication Year'
      case 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT':
        if (model.entityCombineType?.selectedValue === 'DRUG_TYPE_INDICATION') {
          return unref(locale) === 'cn' ? '药物类型' : 'Drug Type'
        }
        return unref(locale) === 'cn' ? '靶点' : 'Target'

      default:
        return unref(locale) === 'cn' ? '机构' : 'Organization'
    }
  })

  const analysisEntityName = computed(() => {
    const chartType = unref(type)
    if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT' && model.entityType?.selectedValue) {
      return entityNameRecord[model.entityType.selectedValue][unref(locale)]
    }

    if (chartType === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT' && model.entityCombineType?.selectedValue) {
      if (model.entityCombineType?.selectedValue === 'TARGET_DRUG_TYPE') {
        return unref(locale) === 'cn' ? '药物类型' : 'Drug Type'
      }
      return unref(locale) === 'cn' ? '适应症' : 'Indication'
    }

    return ''
  })

  const countName = computed(() => (unref(locale) === 'cn' ? '文献数量' : 'Document Count'))

  const settingPanelTitle = computed(() => {
    // const chartType = unref(type)
    // if (chartType === 'research-trend-multi-pie-doughnut') {
    //   return unref(locale) === 'cn' ? '时间范围' : 'Time Range'
    // }

    return ''
  })

  const settingDataPanelTitle = computed(() => {
    const chartType = unref(type)

    if (supportRollupControlChartTypes.includes(chartType)) {
      return unref(locale) === 'cn' ? '机构' : 'Organization'
    }
    return ''
  })

  const settingChartPanelTitle = computed(() => {
    const chartType = unref(type)
    const lang = unref(locale)
    if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
      if (model.entityType?.selectedValue) {
        return `${entityNameRecord[model.entityType.selectedValue][lang]}${lang === 'cn' ? '排名' : ' Range'}`
      }
    }
    if (chartType === 'ACTIVE_ORGANIZATION_STACK_BAR') {
      return lang === 'cn' ? '机构排名' : 'Organization Range'
    }
    return ''
  })

  function initControls(chartType: IUseTransMedicineAggChartType) {
    const controls: IAnalysisChartControlItem<keyof ITransMedicineAggChartModel>[] = []

    if (stackBarChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'horizontal-bar', value: 'horizontal-bar' },
          { type: 'table', value: 'table' },
        ],
      })
    }
    if (multiPieChartTypes.includes(chartType)) {
      controls.push({
        type: 'display',
        bindField: 'display',
        items: [
          { type: 'multi-pie-doughnut', value: 'multi-pie-doughnut' },
          { type: 'table', value: 'table' },
        ],
      })
    }

    if (supportRollupControlChartTypes.includes(chartType)) {
      controls.push({
        type: 'rollup',
        bindField: 'rollup',
      })
    }

    if (supportTopControlChartTypes.includes(chartType) || subAggSupportTopControlChartTypes.includes(chartType)) {
      controls.push({
        type: 'dropdown-single-select',
        bindField: 'top',
        width: 140,
        options: [
          { name: unref(locale) === 'cn' ? '前10' : 'Top 10', id: '10' },
          { name: unref(locale) === 'cn' ? '前20' : 'Top 20', id: '20' },
          { name: unref(locale) === 'cn' ? '前50' : 'Top 50', id: '50' },
        ],
      })
    }

    if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
      controls.push({
        type: 'radio',
        bindField: 'entityType',
        items: [
          {
            label: entityNameRecord.target[unref(locale)],
            type: 'common',
            value: 'target',
          },
          {
            label: entityNameRecord.disease[unref(locale)],
            type: 'common',
            value: 'disease',
          },
          {
            label: entityNameRecord.drug_type[unref(locale)],
            type: 'common',
            value: 'drug_type',
          },
        ],
      })

      controls.push({
        type: 'radio',
        bindField: 'time',
        isManualTrigger: true,
        label: unref(locale) === 'cn' ? '<span class="font-semibold">时间范围</span>' : '<span class="font-semibold">Time Range</span>',
        items: [
          {
            label: unref(locale) === 'cn' ? '全部' : 'All',
            type: 'common',
            value: 'all',
          },
          {
            label: unref(locale) === 'cn' ? '过去10年' : 'Last 10 years',
            type: 'common',
            value: '10',
          },
          {
            label: unref(locale) === 'cn' ? '过去20年' : 'Last 20 years',
            type: 'common',
            value: '20',
          },
          {
            field: 'customTime',
            type: 'range-input',
            initRange: [dayjs().subtract(10, 'years').year(), dayjs().year()],
            value: 'custom',
          },
        ],
      })
    }

    if (chartType === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      controls.push({
        type: 'radio',
        bindField: 'entityCombineType',
        items: [
          {
            label: entityCombineNameRecord['TARGET_INDICATION'][unref(locale)],
            type: 'common',
            value: 'TARGET_INDICATION',
          },
          {
            label: entityCombineNameRecord['TARGET_DRUG_TYPE'][unref(locale)],
            type: 'common',
            value: 'TARGET_DRUG_TYPE',
          },
          {
            label: entityCombineNameRecord['DRUG_TYPE_INDICATION'][unref(locale)],
            type: 'common',
            value: 'DRUG_TYPE_INDICATION',
          },
        ],
      })
    }

    return controls
  }

  function initControlLayout(chartType: IUseTransMedicineAggChartType, isFullScreen = false) {
    const layoutItems: Array<IAnalysisChartControlLayoutItem<keyof ITransMedicineAggChartModel>> = [
      {
        field: 'display',
        position: 'left',
      },
      {
        field: 'rollup',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'entityType',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'entityCombineType',
        position: isFullScreen ? 'data-panel' : 'middle',
      },
      {
        field: 'top',
        position: isFullScreen ? 'chart-panel' : 'middle',
      },
      {
        field: 'time',
        position: isFullScreen ? 'data-panel' : 'setting',
      },
    ]

    return layoutItems
  }

  function getQuery(chartType: IUseTransMedicineAggChartType, query: IQuery, model: ITransMedicineAggChartModel) {
    const clonedQuery = cloneDeep(query)
    // 研究趋势，时间放query中，为了能够不返回无数据的年份，支持年份排序
    if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
      const { time, entityType } = cloneDeep(model)
      let fromTime = 0
      let toTime = 0

      if (!isUndefined(time)) {
        if (time.selectedValue === 'all') {
          fromTime = 0
          toTime = dayjs().valueOf()
        } else if (time.selectedValue === '10' || time.selectedValue === '20') {
          const { from, to } = getYearRangeFromNow(Number(time.selectedValue), false)
          fromTime = from
          toTime = to
        } else if (time.selectedValue === 'custom') {
          const [from, to] = time.customTime || []
          fromTime = dayjs(`${from}`).valueOf()
          toTime = dayjs(`${to}`).valueOf()
        }
        clonedQuery.must?.push(getRangeQueryField([fromTime, toTime], 'PUB_DT', 'YYYYMMDD'))
      }

      if (!isUndefined(entityType)) {
        // 只返回分析实体存在的数据，避免某一年有转化医学数据，但是没有对应的分析实体
        clonedQuery.must?.push({
          type: 'field',
          fields: [entityTypeToAggFieldMap[entityType.selectedValue]],
          value: [
            {
              type: 'exist',
              value: true,
              display_name_cn: '',
              display_name_en: '',
              hidden: true,
            },
          ],
        })
      }
    }

    return clonedQuery
  }

  function getAggregationItem(chartType: IUseTransMedicineAggChartType, controlModel: ITransMedicineAggChartModel): IAggregationItem {
    const clonedAggregationItem = cloneDeep(aggregationItemRecordType[chartType])

    const { top, rollup, entityType } = controlModel

    if (!isUndefined(entityType)) {
      // 实体类型
      if (chartType === 'RESEARCH_TREND_MULTI_PIE_DOUGHNUT') {
        clonedAggregationItem.sub_aggregation = [
          {
            aggregation_type: E_AGGREGATION_TYPE.TERMS,
            aggregation_field: entityTypeToAggFieldMap[entityType.selectedValue],
            sub_aggregation: [
              {
                aggregation_type: E_AGGREGATION_TYPE.TERMS,
                aggregation_field: 'SUBJECT_ID',
              },
            ],
          },
        ]
      }
    }

    if (!isUndefined(rollup) && supportRollupControlChartTypes.includes(chartType)) {
      if (chartType === 'ACTIVE_ORGANIZATION_STACK_BAR') {
        clonedAggregationItem.aggregation_field = rollup ? 'RESEARCH_SPONSOR_ID_ROOT' : 'RESEARCH_SPONSOR_ID'
      }
    }

    if (!isUndefined(top)) {
      if (supportTopControlChartTypes.includes(chartType)) clonedAggregationItem.limit = Number(top)
      if (subAggSupportTopControlChartTypes.includes(chartType) && clonedAggregationItem.sub_aggregation?.[0]) {
        clonedAggregationItem.sub_aggregation[0].limit = Number(top)
      }
    }

    return clonedAggregationItem
  }

  async function fetchCommonAggData() {
    loading.value = true
    const rt = await sharedCtx.service.translationalMedicine.getAggregation(aggParams.value)
    if (rt.success) {
      isEmpty.value = aggResultIsEmpty(rt.data.aggregation_result)
      aggData.value = isEmpty.value ? { aggregation_result: [{ items: [], aggregation_field: '', total: 0 }], total: 0 } : rt.data
      loading.value = false
    } else {
      loading.value = false
      isEmpty.value = true
    }
  }

  async function fetchResearchDirectionAggData() {
    loading.value = true
    const rt = await sharedCtx.service.translationalMedicine.getResearchDirectionChartData(researchDirectionAggParams.value)
    if (rt.success) {
      isResearchDirectionEmpty.value = aggResultIsEmpty(rt.data.aggregation_result)
      const data = isResearchDirectionEmpty.value ? { aggregation_result: [{ items: [], aggregation_field: '', total: 0 }], total: 0 } : rt.data
      expandDisease.value?.id ? (researchDirectionExpandDiseaseData.value = data) : (researchDirectionData.value = data)
      loading.value = false
    } else {
      loading.value = false
      isResearchDirectionEmpty.value = true
    }
  }

  async function fetchAggData() {
    if (unref(type) === 'RESEARCH_DIRECTION_MULTI_PIE_DOUGHNUT') {
      await fetchResearchDirectionAggData()
    } else {
      await fetchCommonAggData()
    }
  }

  watch(
    () => model.entityCombineType?.selectedValue,
    () => {
      expandDisease.value = undefined
    }
  )

  watch(
    model,
    () => {
      fetchAggData()
    },
    { deep: true }
  )

  function handleToggleExpand(expandData?: IResearchDirectionChartEntityItem) {
    expandDisease.value = expandData
    fetchResearchDirectionAggData()
  }

  return {
    model,
    controls,
    controlLayout,
    controlLayoutFullscreen,
    aggData: chartData,
    expandDisease,
    researchDirectionAggParams,
    researchDirectionData,
    researchDirectionExpandDiseaseData,
    categoryFieldName: categoryName,
    countFieldName: countName,
    analysisEntityName,
    fetchAggData,
    settingPanelTitle,
    loading,
    isEmpty: isEmptyComputed,
    settingDataPanelTitle,
    settingChartPanelTitle,
    researchDirectionXDirectionList,
    researchDirectionYDirectionList,
    handleToggleExpand,
  }
}
