import '@patsnap-ui/icon/assets/solid/AddMedium.svg'
import '@patsnap-ui/icon/assets/solid/MinusMedium.svg'
import '@patsnap-ui/icon/assets/solid/OneToOne.svg'
import '@patsnap-ui/icon/assets/solid/all.svg'
import { toThousands } from '@patsnap/synapse_common_utils'
import { ITreeChartNode, ITreeChartNodeSizeConfig, TreeChart } from '@patsnap/synapse_tree_chart'
import foldPlugin from '@patsnap/synapse_tree_chart/plugins/fold'
import miniPlugin from '@patsnap/synapse_tree_chart/plugins/mini'
import moveZoomPlugin from '@patsnap/synapse_tree_chart/plugins/moveZoom'
import shadowPlugin from '@patsnap/synapse_tree_chart/plugins/shadow'
import '@pharmsnap/shared/src/assets/icon-svg/collapsed.svg'
import '@pharmsnap/shared/src/assets/icon-svg/expanded.svg'
import { useLocale } from '@pharmsnap/shared/src/composition'
import { trackingEvent } from '@pharmsnap/shared/src/features/tracking'
import { IOrgCorpTree } from '@pharmsnap/shared/src/types'
import { showSingleToast, sleep } from '@pharmsnap/shared/src/utils'
import { PropType, computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useEventListener, useWindowSize } from '@vueuse/core'
import { GIcon } from '../../ui/GIcon/GIcon'
import styles from './BCorpTree.module.scss'
import { BCorpTreeFilter, ICorpTreeFilterParams, getDefaultCorpTreeFilterParams } from './BCorpTreeFilter'
import { useCorpCardRender } from './composition/useCorpCardRender'
import { useDownloadCorpTree } from './composition/useDownloadCorpTree'
import cn from './locales/cn.json'
import en from './locales/en.json'
import filterPlugin from './plugins/filter'
import highlightPlugin from './plugins/highlight'
import { getDefaultLogo, getDefaultWhiteLogo } from './utils/logo'
import { renderForeignObject, renderRect } from './utils/node'

export const BCorpTree = defineComponent({
  name: 'BCorpTree',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  // model: {
  //   prop: 'loading',
  //   event: 'update:loading',
  // },
  props: {
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
    id: {
      type: String,
    },
    corpTree: {
      type: Object as PropType<IOrgCorpTree>,
      required: true,
    },
    nodeSizeConfig: {
      type: Object as PropType<ITreeChartNodeSizeConfig>,
      default: () => ({
        width: 240,
        height: 96,
        xGap: 100,
        yGap: 16,
      }),
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const [setLoading] = useMergedState(false, {
    //   value: toRef(props, 'loading'),
    //   onChange(val) {
    //     emit('update:loading', val)
    //   },
    // })
    const { locale, ts } = useLocale()
    const { companyCardContainerRef, closeCompanyCard, onClickCompanyNode } = useCorpCardRender()
    const corpTreeRef = ref()
    const corpTreeMiniRef = ref()
    const highlightOrg = ref<string | undefined>()
    const collapsed = ref(false)
    const totalNodeCount = ref(0)
    const displayedNodeCount = ref(0)
    const params = ref<ICorpTreeFilterParams>(getDefaultCorpTreeFilterParams())
    const checkedCountriesSet = computed(() => {
      return new Set(params.value.checkedCountries)
    })
    const handleFilterLeaf = (data?: IOrgCorpTree) => {
      if (data?.id === props.id) return true
      let filter = true
      if (params.value.showCorpWithDrugs) {
        filter = !!data?.drug_count_roll_up
      }
      if (checkedCountriesSet.value.size) {
        const country = data?.country_short_name_en
        filter = filter && checkedCountriesSet.value.has(country || '')
      }
      return filter
    }
    const treeChart = new TreeChart({
      data: props.corpTree,
      dataKeyAccessor: (i) => i.id,
      childrenAccessor: (i) => i.subsidiary,
      nodeSizeConfig: props.nodeSizeConfig,
      drawNode: [
        (g, rect, foreignObject) => {
          renderForeignObject(foreignObject, locale.value, props.id)
          foreignObject.on('click', (e, d) => {
            e.stopPropagation()
            onClickCompanyNode(d.data.id, `${TreeChart.ID_PREFIX}${d.data.id}`)
            treeChart.highlight(d.data.id)
          })

          return g
        },
        (g, rect) => {
          renderRect(rect)
          return g
        },
      ],
      updateNode: (g, rect) => {
        renderRect(rect)
        rect
          .filter((d) => (checkedCountriesSet.value.size ? !checkedCountriesSet.value.has(d.data.country_short_name_en || '') : false))
          .attr('fill', '#F4F5F7')
        return g
      },
    })
    treeChart
      .use(moveZoomPlugin, {
        zoomFactor: [0, 2],
      })
      .use(shadowPlugin)
      .use(foldPlugin, { useIcon: { expanded: '#Expanded', collapsed: '#Collapsed' } })
      .use(highlightPlugin, { defaultHighlightId: props.id })
      .use(filterPlugin, {
        filterLeaf: (data) => handleFilterLeaf(data as IOrgCorpTree),
      })
      .use(miniPlugin, {
        canvasOptions: {
          nodeFillColor: (data: ITreeChartNode<any>) => {
            if (data.data.id === props.id) return '#1976d2'
            return '#EBECF0'
          },
        },
      })
      .init()
    totalNodeCount.value = treeChart._hierarchyNode.descendants().length
    treeChart.on('mounted', () => {
      displayedNodeCount.value = treeChart._hierarchyNode.descendants().length
    })
    treeChart.on('updated', () => {
      displayedNodeCount.value = treeChart._hierarchyNode.descendants().length
    })
    useEventListener(document.body, 'click', closeCompanyCard)
    onMounted(() => {
      treeChart.render(corpTreeRef.value)
      treeChart.renderMini(corpTreeMiniRef.value)

      getDefaultLogo()
      getDefaultWhiteLogo()
      if (props.id) {
        treeChart.moveToNodeCenter(props.id)
      } else {
        treeChart.moveToCenter()
      }
    })
    const { width, height } = useWindowSize()
    watch(
      () => [width.value, height.value],
      () => {
        treeChart.render()
      }
    )
    const { downloading, handleDownload } = useDownloadCorpTree(treeChart, corpTreeRef)
    const handleDownloadCorpTree = (name: string, id: string) => {
      if (displayedNodeCount.value > 250) {
        showSingleToast({
          message: ts('corpTree.exportTooMuch') as string,
          type: 'error',
        })
        return
      }
      handleDownload(name, id)
      trackingEvent('EXPORT_CORP_TREE', {
        payload: {
          entity_id: id,
          show_corp_with_drugs: params.value.showCorpWithDrugs,
        },
      })
    }
    const filtering = ref(false)
    const handleFilter = (p: any) => {
      params.value = p
      filtering.value = true

      sleep(1000).then(() => {
        filtering.value = false
        treeChart.filterLeaf()
        treeChart.moveToNodeCenter(props.id)
      })
    }

    function handleToggle() {
      collapsed.value = !collapsed.value
    }

    function handleZoomOut() {
      treeChart.zoomOutByStep()
    }

    function handleZoomIn() {
      treeChart.zoomInByStep()
    }

    function handleZoomFullExtent() {
      treeChart.fitView()
    }

    function handleFitOneOne() {
      if (treeChart._scaleFactor < 1) {
        treeChart.zoomIn(1)
      } else {
        treeChart.zoomOut(1)
      }
    }

    return {
      corpTreeRef,
      corpTreeMiniRef,
      companyCardContainerRef,
      treeChart,
      params,
      highlightOrg,
      handleDownload: handleDownloadCorpTree,
      downloading,
      filtering,
      handleFilter,
      collapsed,
      handleZoomOut,
      handleZoomIn,
      handleZoomFullExtent,
      handleFitOneOne,
      handleToggle,
      locale,
      totalNodeCount,
      displayedNodeCount,
    }
  },
  render() {
    return (
      <div class="w-full h-full relative">
        <BCorpTreeFilter
          showFilter={this.showFilter}
          corpTree={this.corpTree}
          v-model={this.highlightOrg}
          id={this.id}
          onChange={this.handleFilter}
          onHighlightChange={() => {
            if (this.highlightOrg) {
              this.treeChart.highlight(this.highlightOrg)
              this.treeChart.moveToNodeCenter(this.highlightOrg)
            }
          }}
        />
        <div class={[styles.corpTree, 'h-[calc(100%-56px)]', 'corp-tree']} ref="corpTreeRef"></div>
        <div class={[styles.corpTreeMini]}>
          <div class={['navigation-view', this.collapsed ? 'navigation-view--collapsed' : '']}>
            <div class="navigation-view__toggler" onClick={this.handleToggle}>
              <GIcon svgName={this.collapsed ? 'SolidArrowUp' : 'SolidArrowDown'} class="absolute"></GIcon>
            </div>
            <div class="navigation-view__screen" ref="corpTreeMiniRef"></div>
            <ul class="navigation-view__actions">
              <li class="navigation-view__action" onClick={this.handleZoomFullExtent}>
                <GIcon svgName="SolidAll" size={24}></GIcon>
              </li>
              <li class="navigation-view__action" onClick={this.handleFitOneOne}>
                <GIcon svgName="SolidOneToOne" size={24}></GIcon>
              </li>
              <li class="navigation-view__action" onClick={this.handleZoomOut}>
                <GIcon svgName="SolidMinusMedium" size={24}></GIcon>
              </li>
              <li class="navigation-view__action" onClick={this.handleZoomIn}>
                <GIcon svgName="SolidAddMedium" size={24}></GIcon>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="select-none absolute left-8 bottom-5 h-8 flex items-center text-xs leading-4 text-text-t1 p-2 rounded border"
          style="background: #f4f5f7"
        >
          <span>
            {this.locale === 'cn' ? '画布上的机构数量' : 'Organizations on canvas'}: {toThousands(this.displayedNodeCount)}
          </span>
          <span class="text-text-t2 ml-4">
            {this.locale === 'cn' ? '总机构数量' : 'Total organizations'}: {toThousands(this.totalNodeCount)}
          </span>
        </div>
        <div ref="companyCardContainerRef" class="fixed z-50" onClick={(e: any) => e.stopPropagation()}></div>
      </div>
    )
  },
})
