import { defineComponent } from '@vue/composition-api'
import { ElTag } from '../../../../element-ui'
import $style from './BEntityTag.module.scss'

export const BEntityTag = defineComponent({
  name: 'BEntityTag',
  render() {
    return (
      <ElTag data-testid="b-entity-tag" class={$style.bEntityTag} disable-transitions={true} type="info" size="small" effect="dark">
        {this.$slots.default}
      </ElTag>
    )
  },
})
