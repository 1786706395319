import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale } from '@pharmsnap/shared/composition'
import { sharedCtx } from '@pharmsnap/shared/context'
import { i18n } from '@pharmsnap/shared/i18n'
import { IOrganizationDetail } from '@pharmsnap/shared/types/organization'
import { PropType, computed, defineComponent, ref } from '@vue/composition-api'
import { CorporateTree } from 'patsnap-biz'
import Vue from 'vue'
import '../../../assets/icon-svg/tree.svg'
import { GDialog } from '../../ui/GDialog/GDialog'
import { GFunctionButton } from '../../ui/GFunctionButton/GFunctionButton'
import { GIcon } from '../../ui/GIcon/GIcon'
import { GTooltip } from '../../ui/GTooltip/GTooltip'
import { BOrganizationFetchCard } from '../card/BOrganizationCard/BOrganizationFetchCard'
import $style from './BCorporateTreeButton.module.scss'
import defaultCompanyLogo from './default-company-logo.svg'
import cn from './locales/cn.json'
import en from './locales/en.json'
import { transformCorpTreeData, transformOrganization2CorpTreeData } from './utils'
const BOrganizationFetchCardCom = Vue.extend(BOrganizationFetchCard)

export const BCorporateTreeButton = defineComponent({
  name: 'BCorporateTreeButton',
  props: {
    orgData: {
      required: true,
      type: Object as PropType<IOrganizationDetail>,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  setup(props) {
    let cardVm: null | Vue = null
    const { locale } = useLocale()
    const companyDataRef = computed(() => {
      return transformOrganization2CorpTreeData(props.orgData, locale.value)
    })
    const {
      getters: { isFreeUser },
      actions: { changeUpgrade },
    } = useAuthStore()

    const isShowDialog = ref(false)
    const loading = ref(false)
    async function getDataFn() {
      loading.value = true
      const rt = await sharedCtx.service.organization.getCorpTree(props.orgData.entity_id)
      if (rt.success) {
        loading.value = false
        return transformCorpTreeData(rt.data, locale.value)
      }
      loading.value = false
      return {}
    }
    function open() {
      if (isFreeUser.value) {
        changeUpgrade({ show: true, type: E_UPGRADE_TYPE.USE_PAID_FEATURE_COMPANY, trigger_point: 'CORPORATE_TREE' })
        return
      }
      isShowDialog.value = true
    }
    function renderCardFn(id: string) {
      if (cardVm) {
        cardVm.$destroy()
      }
      cardVm = new BOrganizationFetchCardCom({
        i18n,
        propsData: {
          id,
        },
      })
      cardVm.$mount()
      return cardVm.$el
    }
    function onClose() {
      isShowDialog.value = false
    }
    return { isShowDialog, companyDataRef, getDataFn, open, renderCardFn, onClose, loading }
  },
  render() {
    return (
      <div>
        <GTooltip placement="bottom">
          <GFunctionButton onClick={this.open}>
            <span class="inline-flex">
              <GIcon slot="icon" svgName="Tree" size={24}></GIcon>
              {this.showTitle ? <span class="text-sm ml-1 leading-6">{this.$t('BCorporateTreeButton.CorporateTree')}</span> : null}
            </span>
          </GFunctionButton>
          <div slot="content">{this.$t('BCorporateTreeButton.CorporateTree')}</div>
        </GTooltip>

        <GDialog
          v-model={this.isShowDialog}
          width="90vw"
          customClass={$style.dialog}
          emptyMode={true}
          scopedSlots={{
            default: () => {
              return (
                <div class="h-full relative" v-ls-loading={this.loading}>
                  {this.isShowDialog && (
                    <CorporateTree
                      rootNodeData={this.companyDataRef}
                      logoPlaceholder={defaultCompanyLogo}
                      getDataFn={this.getDataFn}
                      renderCardFn={this.renderCardFn}
                    ></CorporateTree>
                  )}
                  <GIcon
                    class={['cursor-pointer', $style.close]}
                    nativeOnClick={this.onClose}
                    size={24}
                    useSvgDefaultColor
                    svgName="SolidCloseMedium"
                  ></GIcon>
                </div>
              )
            },
          }}
        ></GDialog>
      </div>
    )
  },
})
