import { IFieldLangMap } from '@pharmsnap/shared/types'

export const CT_DATA_SOURCE_LANG: IFieldLangMap = {
  CTR: { en: 'CTR', cn: 'CTR' },
  CTGOV: { en: 'ClinicalTrials.gov', cn: 'ClinicalTrials.gov' },
  WHO: { en: 'WHO', cn: 'WHO' },
}

export const CT_SINGLE_MULTI_CENTER_LANG: IFieldLangMap = {
  UNKNOWN: { en: 'Unknown', cn: '未知' },
  SINGLE: { en: 'Single', cn: '单中心' },
  MULTI: { en: 'Multi', cn: '多中心' },
}

export const CT_IF_RESULT_LANG: IFieldLangMap = {
  0: { en: 'No', cn: '无' },
  1: { en: 'Yes', cn: '有' },
}

export const CT_IIT_LANG: IFieldLangMap = {
  0: { en: 'No', cn: '否' },
  1: { en: 'Yes', cn: '是' },
}

export const SPONSOR_FUNDER_TYPE_LANG: IFieldLangMap = {
  'All others (individuals, universities, organizations)': {
    en: 'All others (individuals, universities, organizations)',
    cn: '其他（个人，大学，组织）',
  },
  Industry: {
    en: 'Industry',
    cn: '企业',
  },
  NIH: {
    en: 'NIH',
    cn: 'NIH',
  },
  'Other U.S. Federal agency': {
    en: 'Other U.S. Federal agency',
    cn: '其他美国联邦机构',
  },
}

export const SINGLE_OR_MULTI_DRUG_LANG: IFieldLangMap = {
  MULTIPLE_DRUGS: {
    cn: '联合',
    en: 'Combo',
  },
  SINGLE_DRUG: {
    cn: '单药',
    en: 'Mono',
  },
  'N/A': {
    cn: 'N/A',
    en: 'N/A',
  },
}
