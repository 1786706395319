import { E_AGGREGATION_TYPE, IAggregationData, IAggregationItem, IAggregationValue, IQuery, IQueryItem } from '@patsnap/synapse_common_interface'
import { BasicHorizontalBarChart } from '@pharmsnap/shared/chart'
import { GAnalysisDisplayField } from '@pharmsnap/shared/components/ui/GAnalysisDisplayField/GAnalysisDisplayField'
import { useLocale } from '@pharmsnap/shared/composition'
import { useChart } from '@pharmsnap/shared/composition/useChart'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { sharedCtx } from '@pharmsnap/shared/context'
import { E_ALIGN_DIRECTION, IQueryDataEntityType } from '@pharmsnap/shared/types'
import { pickDisplayNameByLocale } from '@pharmsnap/shared/utils'
import { computed, defineComponent, getCurrentInstance, PropType, ref, watch } from '@vue/composition-api'
import { EChartsType } from 'echarts/core'
import { TableOptions } from 'vxe-table'
import cn from '../../../locales/cn.json'
import en from '../../../locales/en.json'
import { useLazyComponent } from '../../../use/useLazyComponent'
import { useMouseOverAxis } from '../../../use/useMouseOverAxis'
import { usePatentAnalysis } from '../../../use/usePatentAnalysis'
import { usePatentChartDrill } from '../../../use/usePatentChartDrill'
import {
  checkAggDataIsEmpty,
  countColumnFormatter,
  countRowFormatter,
  generatePatentAggParams,
  getChartHeight,
  getGrantedFilterItem,
  getHorizontalBarOptions,
} from '../../../utils'
import $style from '../../style/Common.module.scss'
import { AnalysisBlock } from '../../ui/AnalysisBlock/AnalysisBlock'
import { PatentFieldSelection } from '../../ui/PatentFieldSelection/PatentFieldSelection'
import { PatentSetting } from '../../ui/PatentSetting/PatentSetting'
import { PatentStatusField } from '../PatentStatusField/PatentStatusField'

export const TargetsRank = defineComponent({
  name: 'TargetsRank',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    queryService: {
      required: true,
      type: Object as PropType<IQueryService>,
    },
  },
  setup(props) {
    const maxLimit = 50
    const { locale } = useLocale()
    const ins = getCurrentInstance()
    const selectedLimit = ref(10)
    const labelRotate = ref(0)
    const layout = ref<'vertical' | 'horizontal'>('horizontal')
    const entityType = ref<Exclude<IQueryDataEntityType, 'mechanism'>>('target')

    const limitOptions = computed(() => {
      return [10, 20, maxLimit].map((limit) => ({
        label: ins?.proxy.$t('targetsRank.limit', { limit }) as string,
        value: limit,
      }))
    })

    const params = computed(() => {
      const {
        query: { must, filter = [] },
      } = props.queryService.state

      const customQuery: IQuery = {
        must,
        filter: selectedPatentStatus.value === 'granted' ? filter.concat(getGrantedFilterItem()) : filter,
        type: 'group',
      }
      const aggregation: IAggregationItem[] = [
        {
          aggregation_type: E_AGGREGATION_TYPE.TERMS,
          aggregation_field: 'PHS_TARGET_ID',
          limit: maxLimit,
          sort_type: 'count',
          order: 'desc',
        },
      ]
      return generatePatentAggParams(props.queryService.state, aggregation, customQuery)
    })

    const {
      isLoading,
      isFullScreen,
      isEmpty,
      popoverVisible,
      selectedField,
      selectedPatentStatus,
      data: aggData,
      changePatentStatus,
      toggleSelectedField,
      toggleFullScreen,
      togglePopoverVisible,
      getChartData,
    } = usePatentAnalysis(params, {
      requestFn: sharedCtx.service.patent.getAggregation.bind(sharedCtx.service.patent),
      checkEmptyFn: checkAggDataIsEmpty,
    })

    const changeLimit = (val: number) => {
      selectedLimit.value = val
    }

    const chartData = computed(() => {
      if (checkAggDataIsEmpty(aggData.value)) {
        return []
      }
      return (aggData.value as IAggregationData).aggregation_result[0].items
        .map((item) => ({
          ...item,
          name: pickDisplayNameByLocale(item, locale.value),
        }))
        .slice(0, selectedLimit.value)
    })
    const chartOption = computed<BasicHorizontalBarChart>(() => {
      const name = selectedPatentStatus.value === 'application' ? ins?.proxy.$tc('patentsApplication') : ins?.proxy.$tc('patentsGranted')
      return getHorizontalBarOptions({
        source: chartData.value,
        xAxisName: name,
      })
    })

    const handleBarDrill = (params: any) => {
      handleDrill({ key: params.value.key, display_name_en: params.value.display_name_en, display_name_cn: params.value.display_name_cn })
    }
    const { isScrollInView, handleScrollInView } = useLazyComponent()
    const { onClickChart } = usePatentChartDrill()
    const handleDrill = (tooltipData: { key: string; display_name_en: string; display_name_cn: string }) => {
      const extraFilter: IQueryItem[] = [
        {
          type: 'field',
          fields: ['PHS_TARGET_ID'],
          value: [
            {
              type: 'text',
              value: tooltipData.key,
              display_name_cn: tooltipData.display_name_cn,
              display_name_en: tooltipData.display_name_en,
            },
          ],
        },
      ]
      if (selectedPatentStatus.value === 'granted') {
        extraFilter.push(getGrantedFilterItem())
      }
      onClickChart({ queryState: props.queryService.state, extraFilter })
    }

    const founder = (item: IAggregationValue, name: string) => {
      return item.display_name_cn === name || item.display_name_en === name
    }

    const getEntityId = (item: IAggregationValue) => {
      return item.key
    }

    const registerEvent = (instance: EChartsType) => {
      instance.on('click', handleBarDrill)
      instance.on('mouseover', 'yAxis', handleAxisMouseover)
    }
    const { chartContainer } = useChart(chartOption, { autoResize: true, registerEvent })
    const { handleAxisMouseover } = useMouseOverAxis<IAggregationValue>({
      container: chartContainer,
      list: chartData,
      labelRotate,
      layout,
      entityType,
      founder,
      getEntityId,
    })

    const registerFullScreenEvent = (instance: EChartsType) => {
      instance.on('click', handleBarDrill)
      instance.on('mouseover', 'yAxis', handleFullScreenAxisMouseover)
    }
    const { chartContainer: fullScreenChartContainer } = useChart(chartOption, { autoResize: true, registerEvent: registerFullScreenEvent })
    const { handleAxisMouseover: handleFullScreenAxisMouseover } = useMouseOverAxis<IAggregationValue>({
      container: fullScreenChartContainer,
      list: chartData,
      labelRotate,
      layout,
      entityType,
      founder,
      getEntityId,
    })

    watch(
      () => [isScrollInView.value, props.queryService.state.query, props.queryService.state.collapse, selectedPatentStatus],
      () => {
        if (isScrollInView.value) getChartData()
      },
      {
        deep: true,
        immediate: true,
      }
    )
    const chartContainerHeight = computed(() => getChartHeight(chartData.value))

    return {
      locale,
      popoverVisible,
      selectedField,
      isFullScreen,
      selectedPatentStatus,
      selectedLimit,
      limitOptions,
      toggleFullScreen,
      togglePopoverVisible,
      toggleSelectedField,
      changePatentStatus,
      changeLimit,
      isEmpty,
      chartContainerHeight,
      chartContainer,
      isLoading,
      handleScrollInView,
      chartData,
      fullScreenChartContainer,
    }
  },
  methods: {
    getFullScreenColumnConfig() {
      const fullScreenColumnConfig = []
      fullScreenColumnConfig.push({
        field: 'col0',
        width: '172px',
        fixed: 'left',
      })
      for (let i = 0; i < this.chartData.length; i++) {
        fullScreenColumnConfig.push({
          field: `col${i + 1}`,
          minWidth: 140,
          formatter: countRowFormatter,
        })
      }
      return fullScreenColumnConfig
    },
    getFullScreenTableData(columnConfig: Array<{ field: 'display_name_en' | 'display_name_cn' | 'count'; title: string }>) {
      const fullScreenTableData = []
      for (let i = 0; i < columnConfig.length; i++) {
        const obj: Record<string, string> = {}
        this.chartData.forEach((item, index) => {
          obj[`col${index + 1}`] = String(item[columnConfig[i].field])
        })
        obj.col0 = columnConfig[i].title
        fullScreenTableData.push(obj)
      }
      return fullScreenTableData
    },
    renderPatentStatus(isFullScreen: boolean) {
      return (
        <PatentStatusField
          isFullScreen={isFullScreen}
          value={this.selectedPatentStatus}
          onChangePatentStatus={this.changePatentStatus}
        ></PatentStatusField>
      )
    },
    renderTable() {
      let vxeProps: TableOptions = {
        border: true,
        resizable: true,
        align: 'left',
      }
      const columnConfig: Array<{
        field: 'display_name_en' | 'display_name_cn' | 'count'
        title: string
        formatter?: ({ cellValue }: { cellValue: any }) => any
      }> = [
        { field: this.locale === 'en' ? 'display_name_en' : 'display_name_cn', title: this.$tc('targetsRank.target') },
        {
          field: 'count',
          title: this.selectedPatentStatus === 'application' ? this.$tc('patentsApplication') : this.$tc('patentsGranted'),
          formatter: countColumnFormatter,
        },
      ]
      const fullScreenColumnConfig = this.getFullScreenColumnConfig()
      const fullScreenTableData = this.getFullScreenTableData(columnConfig)

      if (this.isFullScreen) {
        vxeProps = {
          ...vxeProps,
          showHeader: false,
          showOverflow: 'title',
          columns: fullScreenColumnConfig.slice(0, this.selectedLimit + 1),
          data: fullScreenTableData,
        }
      } else {
        vxeProps = {
          ...vxeProps,
          maxHeight: `${this.chartContainerHeight}px`,
          columns: columnConfig,
          data: this.chartData.slice(0, this.selectedLimit),
        }
      }
      return <vxe-grid size="small" class={[this.isFullScreen ? $style.fullScreenTable : null]} props={vxeProps}></vxe-grid>
    },
    renderLimitRange() {
      return (
        <PatentFieldSelection
          title={this.$tc('targetsRank.range')}
          options={this.limitOptions}
          value={this.selectedLimit}
          align={E_ALIGN_DIRECTION.VERTICAL}
          onChangeField={(value: number) => {
            this.togglePopoverVisible(false)
            this.changeLimit(value)
          }}
        ></PatentFieldSelection>
      )
    },
    renderContent() {
      if (this.selectedField === 'chart') {
        return <div ref="chartContainer" class="w-full" style={{ height: `${this.chartContainerHeight}px` }}></div>
      }
      return this.renderTable()
    },
    renderAnalysis() {
      return (
        <vue-lazy-component onBeforeInit={this.handleScrollInView} style="min-height: 400px;">
          <AnalysisBlock
            isLoading={this.isLoading}
            blockTitle={this.$tc('targetsRank.targetsAnalysis')}
            isEmpty={this.isEmpty}
            title={this.$tc('targetsRank.title')}
            desc={this.$tc('targetsRank.desc')}
            onToggleFullScreen={this.toggleFullScreen}
            class="mt-12 min-h-full"
          >
            <template slot="settings">
              <GAnalysisDisplayField
                chartType="horizonBar"
                selectedValue={this.selectedField}
                onChangeField={this.toggleSelectedField}
              ></GAnalysisDisplayField>
              <div class="flex items-center">
                {this.renderPatentStatus(false)}
                <PatentSetting
                  class="ml-6"
                  visible={this.popoverVisible}
                  onChangeVisible={this.togglePopoverVisible}
                  tips={this.$tc('common.setting')}
                >
                  {this.renderLimitRange()}
                </PatentSetting>
              </div>
            </template>
            <template slot="default">{this.renderContent()}</template>
          </AnalysisBlock>
        </vue-lazy-component>
      )
    },
    renderFullScreen() {
      if (this.isFullScreen) {
        return (
          <AnalysisBlock
            title={this.$tc('targetsRank.title')}
            desc={this.$tc('targetsRank.desc')}
            isFullScreen
            isEmpty={this.isEmpty}
            isLoading={this.isLoading}
            onToggleFullScreen={this.toggleFullScreen}
            fullScreenSettings={[
              { label: this.$tc('data'), content: () => this.renderPatentStatus(true) },
              { label: this.$tc('chart'), content: this.renderLimitRange },
            ]}
          >
            <template slot="default">
              <div class={$style.fullScreenContent}>
                <div class={[$style.fullScreenChart, 'flex-1 overflow-auto']}>
                  <div ref="fullScreenChartContainer" class="w-full min-h-full" style={{ height: `${this.chartContainerHeight}px` }}></div>
                </div>
                <div class="mt-8 flex-shrink-0">{this.renderTable()}</div>
              </div>
            </template>
          </AnalysisBlock>
        )
      }
      return null
    },
  },
  render() {
    return (
      <div>
        {this.renderAnalysis()}
        {this.renderFullScreen()}
      </div>
    )
  },
})
