import { BEntityLoadingCard, GTooltip } from '@pharmsnap/shared/components'
import { useLocale } from '@pharmsnap/shared/composition'
import { IS_CN_REGION } from '@pharmsnap/shared/config'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { sharedCtx } from '@pharmsnap/shared/context'
import { renderPopoverLimitedOrgAsync } from '@pharmsnap/shared/render'
import '@pharmsnap/shared/src/assets/icon-svg/startups.svg'
import { ILang, IOrganizationCardInfo } from '@pharmsnap/shared/types'
import {
  checkOrganizationIsClosed,
  CTRL_C_INFO,
  getEmployeesRangeNumber,
  getNormalizedLocationDisplayName,
  getOrganizationName,
  getOrganizationTypeDisplayName,
} from '@pharmsnap/shared/utils'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import dayjs from 'dayjs'
import { DetailsCard as PtDetailsCard } from 'patsnap-biz'
import { BSignatureImg } from '../../BSignatureImg/BSignatureImg'
import $classes from '../EntityCard.module.scss'
import $orgClasses from './BOrganizationCard.module.scss'
import { BStartupTag } from './BStartupTag'
import cn from './locales/cn.json'
import en from './locales/en.json'

export const BOrganizationCard = defineComponent({
  name: 'BOrganizationCard',
  i18n: {
    messages: {
      cn,
      en,
    },
  },
  props: {
    data: {
      type: Object as PropType<IOrganizationCardInfo>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { data } = toRefs(props)
    const { isCN, locale: lowerCaseLocale } = useLocale()
    const locale = computed(() => ctx.root.$i18n.locale.toUpperCase() as ILang)
    const href = computed(() => (data?.value?.entity_id ? sharedCtx.router.generatorOrgPath(data.value.entity_id || '') : ''))
    const avatar = computed(() => data?.value?.logo_s3_path || data?.value?.logo || '')
    const type = computed(() =>
      data?.value?.normalized_entity_type_en
        ? getOrganizationTypeDisplayName(data?.value?.normalized_entity_type_en, locale.value)
        : EMPTY_PLACEHOLDER
    )
    const isLsRelated = computed(() => !!data?.value?.is_ls_related)
    const isClosed = computed(() => checkOrganizationIsClosed(data?.value?.entity_status))
    const founded = computed(() =>
      typeof data?.value?.founded_date === 'undefined' ? EMPTY_PLACEHOLDER : dayjs(`${data?.value?.founded_date}`).year()
    )
    const isStartup = computed(() => {
      return data?.value?.start_up
    })
    const location = computed(() => getNormalizedLocationDisplayName(data?.value, lowerCaseLocale.value))
    const desc = computed(() => {
      const enDesc = data?.value?.short_description_en || data?.value?.long_description_en
      const cnDesc = data?.value?.description_cn
      const langDesc = lowerCaseLocale.value === 'cn' ? cnDesc || enDesc : enDesc || cnDesc
      return langDesc || data?.value?.description_cn_translation || EMPTY_PLACEHOLDER
    })
    const employees = computed(() =>
      typeof data?.value?.employee_number === 'undefined' ? EMPTY_PLACEHOLDER : getEmployeesRangeNumber(data?.value?.employee_number)
    )

    return {
      isLsRelated,
      isClosed,
      employees,
      desc,
      href,
      avatar,
      type,
      founded,
      location,
      isCN,
      isStartup,
    }
  },
  methods: {
    renderLink(content: string, needTooltip = true) {
      if (this.isClosed) {
        return (
          <GTooltip disabled={!needTooltip} theme="light" placement="top" content={this.$tc('organizationCard.notExists')}>
            <span class="text-text-t1 line-clamp-2 font-medium cursor-not-allowed" title={content}>
              {content}
            </span>
          </GTooltip>
        )
      }
      if (!this.isLsRelated) {
        return (
          <GTooltip disabled={!needTooltip} theme="light" placement="top" content={this.$tc('organizationCard.notLsOrgMessage')}>
            <span class="text-text-t1 line-clamp-2 font-medium cursor-not-allowed" title={content}>
              {content}
            </span>
          </GTooltip>
        )
      }

      return this.href ? (
        <a class="line-clamp-2 text-blue-default font-medium hover:underline" href={this.href} title={content} target="_blank">
          {content}
        </a>
      ) : (
        <span class="text-text-t1 line-clamp-2 font-medium" title={content}>
          {content}
        </span>
      )
    },
    renderTitle() {
      const orgENName = getOrganizationName(this.data || {}, 'en')
      return (
        <div>
          {/* 有英文名称时，才展示中文名称:因为第二行英文名称不存在会使用中文名称兜底，避免展示两行中文名称 */}
          {IS_CN_REGION && this.data?.name_cn && this.data.name_en && this.isCN && <div>{this.renderLink(this.data?.name_cn, false)}</div>}
          {orgENName && <div>{this.renderLink(orgENName)}</div>}
          {this.isStartup && <BStartupTag class="mt-2" />}
        </div>
      )
    },
  },
  render() {
    const props = {
      type: 'organization',
      isLoading: false,
      detailsData: {
        avatar: this.avatar,
        title: this.title,
        href: this.href,
        contentText: this.desc,
        rowsList: [
          {
            label: this.$t('organizationCard.type'),
            value: this.type,
          },
          {
            label: this.$t('organizationCard.name'),
            value: this.title,
          },
          {
            label: this.$t('organizationCard.founded'),
            value: this.founded,
          },
          ...(this.data?.ultimate_parent_id_view
            ? [
                {
                  label: this.$t('organizationCard.parentCompany'),
                  value: {},
                  renderFn: () => {
                    return renderPopoverLimitedOrgAsync(this.data?.ultimate_parent_id_view || [], this.data?.ultimate_parent_id || [], 3)
                  },
                },
              ]
            : []),
          {
            label: this.$t('organizationCard.location'),
            value: this.location.displayLocation || EMPTY_PLACEHOLDER,
          },
          {
            label: this.$t('organizationCard.employees'),
            value: this.employees,
          },
        ],
      },
      showFollow: false,
      tag: '',
    }
    const titleContent = this.renderTitle()
    const showVertical = this.direction === 'vertical'

    const classes = [
      $classes.card,
      $orgClasses.orgCard,
      'browser-name--chrome',
      CTRL_C_INFO,
      !this.border && $classes.cardNoBorder,
      showVertical && $orgClasses.orgCardVertical,
    ]
    return (
      <BEntityLoadingCard hasImg={true} hasDesc={true} loading={this.loading} border={this.border}>
        {this.avatar !== '' ? (
          <BSignatureImg
            src={this.avatar}
            scopedSlots={{
              default: ({ loading, src }: { loading: boolean; src: string }) => {
                return (
                  <PtDetailsCard
                    {...{ props: { ...props, detailsData: { ...props.detailsData, avatar: loading ? '' : src } } }}
                    class={classes}
                    scopedSlots={{
                      rowListValue: (scope: { rowListValue: { label: string; value: string; renderFn: any } }) => {
                        if (scope.rowListValue.renderFn) {
                          return scope.rowListValue.renderFn()
                        }
                      },
                    }}
                  >
                    <template slot="title">{titleContent}</template>
                  </PtDetailsCard>
                )
              },
            }}
          ></BSignatureImg>
        ) : (
          <PtDetailsCard
            {...{ props }}
            class={classes}
            scopedSlots={{
              rowListValue: (scope: { rowListValue: { label: string; value: string; renderFn: any } }) => {
                if (scope.rowListValue.renderFn) {
                  return scope.rowListValue.renderFn()
                }
              },
            }}
          >
            <template slot="title">{titleContent}</template>
          </PtDetailsCard>
        )}
      </BEntityLoadingCard>
    )
  },
})
