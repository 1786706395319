import { E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { RouteConfig } from 'vue-router'

export default [
  {
    path: `drug-type/:${E_ROUTER_PARAMS.DRUG_TYPE_ID}`,
    name: E_ROUTER_NAME.DRUG_TYPE,
    props: (route) => ({
      id: route.params[E_ROUTER_PARAMS.DRUG_TYPE_ID],
    }),
    component: () => import('@pharmsnap/pharmsnap-web/views/drugType/DrugTypeDetail'),
    meta: {
      notWide: true,
      trackingRouteName: E_ROUTER_NAME.DRUG_TYPE_DETAIL,
    },
    children: [
      {
        path: 'overview',
        name: E_ROUTER_NAME.DRUG_TYPE_OVERVIEW,
        redirect: () => {
          return {
            name: E_ROUTER_NAME.DRUG_TYPE,
          }
        },
      },
    ],
  },
] as RouteConfig[]
