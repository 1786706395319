import { BDiseaseCard } from '@pharmsnap/shared/components'
import { IDiseaseCardInfo } from '@pharmsnap/shared/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { useDiseaseFetchCard } from '../useEntityFetchCard'

export const BDiseaseFetchCard = defineComponent({
  name: 'BDiseaseFetchCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    drug_id: {
      type: String,
    },
    width: {
      type: Number,
      default: 360,
    },
    direction: {
      type: String as PropType<'vertical' | 'horizontal'>,
      default: 'horizontal',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { id, drug_id } = toRefs(props)
    const options = computed(() => {
      return {
        drug_id: drug_id?.value,
      }
    })
    const { loading, data } = useDiseaseFetchCard(id, options)
    return {
      loading,
      data,
    }
  },
  render() {
    return (
      <BDiseaseCard
        style={{ width: `${this.width}px` }}
        loading={this.loading}
        data={this.data as IDiseaseCardInfo}
        direction={this.direction}
        border={this.border}
      ></BDiseaseCard>
    )
  },
})
