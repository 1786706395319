import { ISignType } from '@pharmsnap/shared/service/apis/searchApi'
import { defineComponent, PropType } from '@vue/composition-api'
import { GLoading } from '../../ui/GLoading/GLoading'
import { BSignatureImg } from '../BSignatureImg/BSignatureImg'
import $styles from './BLazyImg.module.scss'
/**
 * 支持异步签名的图片懒加载组件
 */
export const BLazyImg = defineComponent({
  name: 'BLazyImg',
  props: {
    /**
     * 待签名的s3地址
     */
    src: {
      required: true,
      type: String,
    },
    imgStyle: {
      type: String,
    },
    imgClass: {
      type: [String, Array] as PropType<string | Array<string>>,
    },
    signType: {
      type: String as PropType<ISignType>,
    },
  },
  render() {
    if (!this.src) {
      return <div></div>
    }
    const loading = <GLoading size={24}></GLoading>
    return (
      <vue-lazy-component class={$styles.blazyimg}>
        <BSignatureImg
          src={this.src}
          signType={this.signType}
          scopedSlots={{
            default: (data: { src: string; loading: boolean }) => {
              if (data.loading) {
                return loading
              } else {
                return <img src={data.src} style={this.imgStyle} class={this.imgClass}></img>
              }
            },
          }}
        />
        <template slot="skeleton">{loading}</template>
      </vue-lazy-component>
    )
  },
})
