import { computed, defineComponent, PropType, unref } from '@vue/composition-api'
import { useInjectFilterService } from '../compositions/useProvideFilterService'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import jp from '../locales/jp.json'
import tw from '../locales/tw.json'
export const BFilterSubmit = defineComponent({
  name: 'BFilterSubmit',
  i18n: {
    messages: {
      cn,
      en,
      jp,
      tw,
    },
  },
  props: {
    // 横向还是竖向
    layout: {
      type: String as PropType<'horizontal' | 'vertical'>,
      default: 'horizontal',
    },
    // 不可用时是否需要隐藏
    hideWhenDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const service = useInjectFilterService()

    const disabled = computed(() => service?.selectService?.filterValueTemporaryListExcludeReal.value.length === 0)

    const theme = computed(() => unref(service?.filterService.theme))

    return {
      disabled,
      theme,
      locale: service?.locale,
    }
  },
  render() {
    return (
      <span class={this.layout === 'horizontal' ? 'inline-flex items-center' : 'inline-flex flex-col-reverse'}>
        {this.hideWhenDisabled && this.disabled ? null : (
          <button
            onClick={() => this.$emit('exclude')}
            class={['pt-ui-btn', this.layout === 'horizontal' ? 'mr-2' : this.theme === 'synapse' ? 'mt-1' : 'mt-1 w-[86px]']}
            type="button"
            disabled={this.disabled}
            data-type="danger"
          >
            {this.$t('bFilterNext.exclude')}
          </button>
        )}
        {this.hideWhenDisabled && this.disabled ? null : (
          <button
            onClick={() => this.$emit('refine')}
            class={['pt-ui-btn', this.layout === 'vertical' && this.theme !== 'synapse' ? 'w-[86px]' : '']}
            type="button"
            disabled={this.disabled}
            data-type={this.theme === 'synapse' ? 'submit' : 'primary'}
          >
            {this.$t('bFilterNext.refine')}
          </button>
        )}
      </span>
    )
  },
})
