import { IBaseDictionaryInfo } from '@pharmsnap/shared/types'
import { IHttp, IHttpWrap } from '../http/types'
import { BaseApi } from './baseApi'

export class DrugTypeApi extends BaseApi {
  constructor(http: IHttp, baseUrl: string) {
    super(http, baseUrl)
  }

  async getDrugType(id: string): Promise<IHttpWrap<IBaseDictionaryInfo>> {
    return this.http({
      method: 'GET',
      url: this.getUrl(`pharmsnap_webapi/1.0/drug-type/${id}`),
      cache: true,
    })
  }
}
