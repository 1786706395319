import '@patsnap-ui/icon/assets/solid/AddLarge.svg'
import '@patsnap-ui/icon/assets/solid/CloseMedium.svg'
import '@patsnap-ui/icon/assets/solid/delete.svg'
import '@patsnap-ui/icon/assets/solid/DropdownClose.svg'
import '@patsnap-ui/icon/assets/solid/DropdownOpen.svg'
import { E_SORT_ORDER, ISort } from '@patsnap/synapse_common_interface'
import { GIcon } from '@pharmsnap/shared/components/ui/GIcon/GIcon'
import { ElCheckbox, ElOption, ElPopover, ElSelect } from '@pharmsnap/shared/element-ui'
import { computed, ComputedRef, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api'
import { cloneDeep, keyBy } from 'lodash'
import { Icon } from 'patsnap-biz'
import { IBooleanSortOption, ISortOption } from '../../types'
import { orderConfigMap } from '../config'
import cn from '../locales/cn.json'
import en from '../locales/en.json'
import { SortOrder } from '../SortOrder/SortOrder'
import $classes from './SortCardButton.module.scss'
export const SortCardButton = defineComponent({
  name: 'SortCardButton',
  i18n: {
    messages: {
      en,
      cn,
    },
  },
  props: {
    limitCount: {
      type: Number,
      default: 2,
    },
    sortOptions: {
      type: Array as PropType<ISortOption[]>,
      default: () => [],
    },
    booleanSortOption: {
      type: Object as PropType<IBooleanSortOption>,
    },
    value: {
      type: Array as PropType<ISort[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const show = ref(false)
    const sortItem: Ref<ISort[]> = ref([])
    const isBooleanSortChecked = ref(false)
    /** 之前已经选择的sort */
    const selectedField: ComputedRef<Record<string, boolean>> = computed(() => {
      return sortItem.value.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.sort_field]: true,
        }
      }, {})
    })
    const sortFiledConfigMap = computed(() => {
      return keyBy(props.sortOptions, (item) => item.field)
    })
    const isShowBiosimilars = computed(() => {
      return props.sortOptions.find((sort) => sort.field === 'DRUG_TYPE_SORT')
    })

    function updateCurrentSort() {
      sortItem.value = cloneDeep(props.value.filter((item) => item.sort_field !== props.booleanSortOption?.field))
      if (props.booleanSortOption) {
        isBooleanSortChecked.value = !!props.value.find((item) => item.sort_field === props.booleanSortOption?.field)
      }
    }
    watch(
      () => props.value,
      () => {
        updateCurrentSort()
      },
      {
        immediate: true,
      }
    )
    watch(show, (value) => {
      if (value) {
        updateCurrentSort()
      }
    })

    function onAddSortItem() {
      const unSelectedSortField = props.sortOptions.filter((sort) => !selectedField.value[sort.field])[0]
      sortItem.value.push({
        sort_field: unSelectedSortField?.field || '',
        sort_order: E_SORT_ORDER.DESC,
      })
    }
    function onRemoveSortItem(index: number) {
      sortItem.value.splice(index, 1)
    }
    function onSubmit() {
      const sortItems = sortItem.value.filter((item) => item.sort_field)
      const sortData = sortItems.map((item) => {
        const config = sortFiledConfigMap.value[item.sort_field]
        return {
          ...item,
          sort_order: config?.defaultOrder || item.sort_order,
        }
      })
      if (props.booleanSortOption && isBooleanSortChecked.value) {
        sortData.unshift({
          sort_field: props.booleanSortOption.field,
          sort_order: props.booleanSortOption.defaultOrder,
        })
      }
      ctx.emit('change', sortData)
      show.value = false
    }
    function onCancel() {
      show.value = false
    }
    function onCheckBooleanSort(isCheck: boolean) {
      isBooleanSortChecked.value = isCheck
    }
    return {
      isShowBiosimilars,
      isBooleanSortChecked,
      sortFiledConfigMap,
      show,
      selectedField,
      sortItem,
      onRemoveSortItem,
      onAddSortItem,
      onCheckBooleanSort,
      onSubmit,
      onCancel,
    }
  },
  methods: {
    renderButtonText() {
      const sortFieldCount = this.value.length
      if (sortFieldCount === 0) {
        return 'sort'
      } else if (sortFieldCount === 1) {
        const sortItem = this.value[0]
        const orderConfig = orderConfigMap[sortItem.sort_order]
        const sort_field = sortItem.sort_field
        const i18nKey = this.sortOptions.find((item) => item.field === sort_field)
        if (i18nKey) {
          if (i18nKey.hideOrderConfig) {
            return `${this.$tc(i18nKey.i18nKey)}`
          }
          return `${this.$tc(i18nKey.i18nKey)} | ${this.$tc(orderConfig.i18nKey)}`
        }
      } else {
        return this.$t('SortCardButton.sortByCountField', { count: sortFieldCount })
      }
    },
    renderContent() {
      return (
        <div>
          {this.sortItem.map((sortItem, index) => {
            const config = this.sortFiledConfigMap[sortItem.sort_field]
            return (
              <div class="flex items-center mb-2 sort-card-button" data-testid={`sort-card-button--${sortItem.sort_field}`}>
                <div class="w-14">{index === 0 ? this.$tc('SortCardButton.priority') : this.$tc('SortCardButton.secondly')}</div>
                <div class={['flex-1 flex items-center', $classes.sortItem]}>
                  <ElSelect v-model={sortItem.sort_field} size="small">
                    {this.sortOptions.map((optionItem) => {
                      return (
                        <ElOption
                          key={optionItem.field}
                          value={optionItem.field}
                          label={this.$tc(optionItem.i18nKey)}
                          disabled={optionItem.field === sortItem.sort_field ? false : this.selectedField[optionItem.field]}
                        ></ElOption>
                      )
                    })}
                  </ElSelect>
                  {config.hideOrderConfig
                    ? null
                    : !config?.disableOrder && <SortOrder v-model={sortItem.sort_order} disabled={!sortItem.sort_field} class="ml-2"></SortOrder>}
                </div>
                <div class="w-6 flex justify-end">
                  {this.sortItem.length > 1 && (
                    <Icon
                      class="text-2xl cursor-pointer"
                      data-testid="sort-card-button__remove"
                      icon="SolidDelete"
                      onClick={() => {
                        this.onRemoveSortItem(index)
                      }}
                    ></Icon>
                  )}
                </div>
              </div>
            )
          })}
          {this.sortItem.length < this.limitCount && this.sortItem.length > 0 && (
            <div>
              <span class="text-blue-default cursor-pointer" data-testid="sort-card-button__add" onClick={this.onAddSortItem}>
                <Icon class="mr-1" icon="SolidAddLarge"></Icon>
                {this.$t('SortCardButton.addSortField')}
              </span>
            </div>
          )}
        </div>
      )
    },
    renderBooleanSort() {
      if (!this.booleanSortOption) {
        return null
      }
      return (
        <ElCheckbox v-model={this.isBooleanSortChecked} class="h-8 leading-8" onChange={this.onCheckBooleanSort}>
          <span class="mr-3 text-text-t1 text-sm">{this.$tc(this.booleanSortOption.i18nKey)}</span>
        </ElCheckbox>
      )
    },
  },
  render() {
    const renderButtonText = this.renderButtonText()
    return (
      <ElPopover v-model={this.show} placement="bottom-start" disabled={this.disabled} appendToBody={false} data-testid="sort-card-button">
        <template slot="reference">
          <span
            class={['cursor-pointer inline-flex items-center', this.disabled ? 'text-gray-55 cursor-not-allowed' : 'text-text-default']}
            style="max-width:300px"
          >
            <span class="truncate" title={renderButtonText}>
              {renderButtonText}
            </span>
            <GIcon
              class={['flex-shrink-0', this.disabled ? 'text-gray-55' : 'text-text-t2']}
              svgName={this.show ? 'SolidDropdownClose' : 'SolidDropdownOpen'}
              size={24}
            ></GIcon>
          </span>
        </template>
        <div style="width:550px" class="p-2">
          <div class="flex items-center">
            <div class="flex-1">
              <div class="font-medium text-text-t1" style="font-size:20px">
                {this.$tc('SortCardButton.advanceSort')}
              </div>
            </div>
            <Icon class="text-xl cursor-pointer" icon="SolidCloseBig" onClick={this.onCancel}></Icon>
          </div>
          <div class="mt-2">{this.renderContent()}</div>
          <div class="flex justify-end mt-6">
            {this.renderBooleanSort()}
            <button class="pt-ui-btn" data-type="default" onClick={this.onCancel}>
              {this.$tc('SortCardButton.cancel')}
            </button>
            <button class="pt-ui-btn ml-2" data-type="submit" onClick={this.onSubmit}>
              {this.$tc('SortCardButton.submit')}
            </button>
          </div>
        </div>
      </ElPopover>
    )
  },
})
