import { GRadio } from '@pharmsnap/shared/src/components/ui/GRadio/GRadio'
import { ComponentProps, E_ALIGN_DIRECTION } from '@pharmsnap/shared/src/types'
import { PropType, computed, defineComponent, ref, toRefs, watch } from '@vue/composition-api'
import { IAnalysisRadioOptionItem, IAnalysisRadioOptionValue, IAnalysisRadioRangeInputOptionItem } from '../../type'
import { BAnalysisChartYearRangeInput } from './BAnalysisChartYearRangeInput'

type GRadioItems = ComponentProps<typeof GRadio>['items']

export const BAnalysisChartRadioGroup = defineComponent({
  name: 'BAnalysisChartRadioGroup',
  props: {
    options: {
      type: Array as PropType<Array<IAnalysisRadioOptionItem>>,
      required: true,
    },
    value: {
      type: Object as PropType<IAnalysisRadioOptionValue>,
    },
    layout: {
      type: String as PropType<'inline' | 'block'>,
      default: 'inline',
    },
    mode: {
      type: String as PropType<'circle' | 'button'>,
      default: 'circle',
    },
    isCN: {
      type: Boolean,
    },
    isManualTrigger: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { options, value } = toRefs(props)

    const temporarySelectValue = ref(value?.value?.selectedValue)
    const temporaryRangeValue = ref<{ [key: string]: [number, number] }>({})
    const disabled = ref(false)

    const selectRadioValue = computed(() => (props.isManualTrigger ? temporarySelectValue.value : value?.value?.selectedValue))

    const rangeInputItem = computed(() => props.options.find((item) => item.type === 'range-input') as IAnalysisRadioRangeInputOptionItem | undefined)

    const isSelectRangeInput = computed(() => {
      return rangeInputItem.value?.value === selectRadioValue.value
    })

    const radioItems = computed<GRadioItems>(() =>
      options.value.map((item) => {
        const { type } = item

        if (type === 'common') {
          return { label: item.label, value: item.value }
        } else {
          return {
            label: '',
            customRender: () => (
              <BAnalysisChartYearRangeInput
                isCN={props.isCN}
                initRange={item.initRange}
                validRange={item.validRange}
                inputDisable={item.value !== selectRadioValue.value}
                onInput={(range: [number, number]) => handleRangeChange(range, item.field)}
                onError={(isError: boolean) => (disabled.value = isError)}
                value={(props.isManualTrigger ? temporaryRangeValue.value?.[item.field] : value?.value?.[item.field]) || item.initRange}
              ></BAnalysisChartYearRangeInput>
            ),
            value: item.value,
          }
        }
      })
    )

    function handleInput(val: IAnalysisRadioOptionValue) {
      if (props.isManualTrigger) {
        temporarySelectValue.value = val
        return
      }
      emit('input', { ...value?.value, ...{ selectedValue: val } })
    }

    function handleRangeChange(range: [number, number], field: string) {
      if (props.isManualTrigger) {
        temporaryRangeValue.value[field] = range
        return
      }
      emit('input', { ...value?.value, ...{ [field]: range } })
    }

    function handleCancel() {
      emit('cancel')
    }

    function handleConfirm() {
      emit('input', { ...value?.value, ...{ selectedValue: temporarySelectValue.value }, ...temporaryRangeValue.value })
      emit('cancel')
    }

    watch(
      () => props.value,
      () => {
        if (props.isManualTrigger) {
          temporarySelectValue.value = props.value?.selectedValue
          if (rangeInputItem.value) {
            temporaryRangeValue.value[rangeInputItem.value.field] = props.value?.[rangeInputItem.value.field]
          }
        }
      },
      {
        immediate: true,
      }
    )

    return {
      selectRadioValue,
      radioItems,
      handleInput,
      disabled,
      isSelectRangeInput,
      handleRangeChange,
      handleCancel,
      handleConfirm,
    }
  },
  methods: {
    renderActions() {
      if (this.showCancel) {
        return (
          <div class="flex items-center justify-end mt-6">
            <button onClick={this.handleCancel} class="pt-ui-btn mr-2" data-size="medium" type="default">
              {this.isCN ? '取消' : 'Cancel'}
            </button>
            <button
              onClick={this.handleConfirm}
              disabled={this.isSelectRangeInput && this.disabled}
              class="pt-ui-btn"
              data-size="medium"
              type="submit"
            >
              {this.isCN ? '提交' : 'Apply'}
            </button>
          </div>
        )
      }
      return (
        <button
          onClick={this.handleConfirm}
          disabled={this.isSelectRangeInput && this.disabled}
          class="pt-ui-btn w-full mt-6"
          data-size="medium"
          type="submit"
        >
          {this.isCN ? '提交' : 'Apply'}
        </button>
      )
    },
  },
  render() {
    const radioContent = (
      <GRadio
        value={this.selectRadioValue}
        onInput={this.handleInput}
        items={this.radioItems}
        mode={this.mode}
        inline={this.layout === 'inline'}
        align={this.layout === 'inline' ? E_ALIGN_DIRECTION.HORIZONTAL : E_ALIGN_DIRECTION.VERTICAL}
      ></GRadio>
    )
    if (this.isManualTrigger) {
      return (
        <div>
          {radioContent}
          {this.renderActions()}
        </div>
      )
    }
    return radioContent
  },
})
