import { IAnnotationEntityItem } from '@patsnap/synapse_common_interface'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { highLightAnnotation } from '@pharmsnap/shared/utils'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'

const labels = [
  { normalized_type: 'drug', conf_ner_entity_name: 'Drug', color: '#A6F2F7', hoverColor: '#77DFEB' },
  { normalized_type: 'disease', conf_ner_entity_name: 'Disease', color: '#DFB8FF', hoverColor: '#C78FFF' },
  { normalized_type: 'target', conf_ner_entity_name: 'Target', color: '#FFD485', hoverColor: '#F5B957' },
  { normalized_type: 'org', conf_ner_entity_name: 'Pharm Organization', color: '#96D977', hoverColor: '#73CC50' },
]

export const BTextHighLight = defineComponent({
  props: {
    config: {
      type: Array as PropType<IAnnotationEntityItem[]>,
      default: () => [],
      required: true,
    },
    field_path: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const rootRef = ref()

    onMounted(() => {
      if (!props.config) {
        console.error('请检查 "config" 配置')
      }
      const highlight = props.config.filter((item) => {
        if (props.field_path) {
          return item.field_path === props.field_path && item.normalized_type
        }
        return item.normalized_type
      })
      highlight.forEach((item) => {
        highLightAnnotation(item, labels, rootRef.value)
      })
    })

    return {
      rootRef,
    }
  },
  render() {
    return <div ref="rootRef">{this.$slots.default || EMPTY_PLACEHOLDER}</div>
  },
})
