import { IBOFreeRegisterUrlParams } from '@patsnap/synapse_common_interface'
import { useAuthStore, useLocale } from '@pharmsnap/shared/src/composition'
import { sharedCtx } from '@pharmsnap/shared/src/context'
import { IUserShareInfo, SHARE_TYPE } from '@pharmsnap/shared/src/types'
import { showSingleToast } from '@pharmsnap/shared/src/utils'
import { computed, getCurrentInstance, ref } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import qs from 'qs'
import { IS_CN_REGION, VUE_APP_DOMAIN_URL, VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL } from '../config'
interface IGenerateShareCodeOptions {
  type: SHARE_TYPE
  shareInfo?: Partial<IUserShareInfo>
  miniProgramRouterName?: string
  registerInfo?: Partial<IBOFreeRegisterUrlParams>
}
export function useInviteLink(options: { linkConfig?: IGenerateShareCodeOptions; qrCodeConfig?: IGenerateShareCodeOptions }) {
  const qrCodeLoading = ref(true)
  const sceneLoading = ref(true)
  /**
   * 场景值
   */
  const scene = ref('')
  const qrCode = ref('')
  const copySuccess = ref(false)
  const {
    state: { userInfo },
    getters: { userName },
  } = useAuthStore()
  const { isCN } = useLocale()
  const ins = getCurrentInstance()
  const baseShareInfo = computed<Partial<IUserShareInfo>>(() => {
    return {
      url: location.href,
      user_id: userInfo.value?.user_id,
      detail: {
        __pageName: ins?.proxy?.$route.name,
      },
    }
  })
  async function generateShareCode(_options: IGenerateShareCodeOptions) {
    const mappingRes = await sharedCtx.service.account.exchangeQrCodeMappingId({
      type: _options.type,
      value: {
        user_id: userInfo.value?.user_id,
        user_name: userName.value,
        name: _options.miniProgramRouterName,
        share_info: Object.assign({ type: _options.type }, baseShareInfo.value, _options.shareInfo),
        ..._options.registerInfo,
      },
    })
    if (mappingRes.success && mappingRes.data) {
      return mappingRes.data
    }
    return ''
  }
  async function getUserMiniQrCode() {
    if (!options.qrCodeConfig) {
      return ''
    }
    const scene = await generateShareCode(options.qrCodeConfig)
    const qrCodeRes = await sharedCtx.service.login.getMiniCode({
      page: 'pages/jump-host/index',
      check_path: false,
      mapping_id: scene,
    })
    if (qrCodeRes.success && qrCodeRes.data) {
      return qrCodeRes.data
    }
    return ''
  }
  async function trackingShareInfo(_options: { scene?: string; shareInfo?: Partial<IUserShareInfo> }) {
    const trackingShareInfo: Partial<IUserShareInfo> = {}
    Object.assign(trackingShareInfo, baseShareInfo.value)
    if (_options.scene) {
      const revertRt = await sharedCtx.service.account.revertMappingId(_options.scene)
      if (revertRt.success && revertRt.data) {
        const { value } = revertRt.data
        const parsedValue = qs.parse(value) as { share_info?: IUserShareInfo }
        Object.assign(trackingShareInfo, parsedValue.share_info)
      }
    }
    if (_options.shareInfo) {
      Object.assign(trackingShareInfo, _options.shareInfo)
    }
    if (!isEmpty(trackingShareInfo)) {
      sharedCtx.service.tracking.share(trackingShareInfo as IUserShareInfo)
    }
  }
  function getCopilotInviteLink(_scene: string, isSocialMedia = false) {
    if (!_scene) {
      return ''
    }
    if (IS_CN_REGION) {
      return `${VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL}/copilot-invite/?scene=${_scene}`
    } else {
      if (isSocialMedia) {
        return `${VUE_APP_DOMAIN_URL}/copilot-invite/?scene=${_scene}`
      }
      return `${VUE_APP_DOMAIN_URL}/?scene=${_scene}`
    }
  }
  async function copyInviteLink(link: string) {
    if (copySuccess.value) {
      return
    }
    await navigator.clipboard.writeText(link)
    copySuccess.value = true
    showSingleToast({
      message: isCN.value ? '邀请链接复制成功' : 'The share link has been copied to the clipboard',
      type: 'success',
    })
    // 复制成功之后隔几秒再重新回到原始状态
    setTimeout(() => {
      copySuccess.value = false
    }, 5000)
  }
  async function init() {
    if (options.qrCodeConfig) {
      qrCodeLoading.value = true
      qrCode.value = await getUserMiniQrCode()
      qrCodeLoading.value = false
    }
    if (options.linkConfig) {
      sceneLoading.value = true
      scene.value = await generateShareCode(options.linkConfig)
      sceneLoading.value = false
    }
  }
  const copilotInviteLink = computed(() => {
    return getCopilotInviteLink(scene.value)
  })
  const commonInviteLink = computed(() => {
    if (!scene.value) {
      return ''
    }
    if (IS_CN_REGION) {
      return `${VUE_APP_PHARMSNAP_MOBILE_DOMAIN_URL}/invite/?scene=${scene.value}`
    } else {
      return `${VUE_APP_DOMAIN_URL}/?scene=${scene.value}`
    }
  })
  return {
    qrCodeLoading,
    sceneLoading,
    qrCode,
    scene,
    copilotInviteLink,
    commonInviteLink,
    copySuccess,
    init,
    getCopilotInviteLink,
    trackingShareInfo,
    copyInviteLink,
    generateShareCode,
  }
}
