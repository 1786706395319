import { toThousands } from '@patsnap/synapse_common_utils'
import { defineComponent } from '@vue/composition-api'
import $classes from './BHeatMapTooltip.module.scss'

export const BHeatMapTooltip = defineComponent({
  name: 'BHeatMapTooltip',
  props: {
    color: {
      type: String,
    },
    xCategory: {
      type: String,
    },
    yCategory: {
      type: String,
    },
    count: {
      type: Number,
    },
    seriesName: {
      type: String,
    },
    originData: {
      type: [Array, Object],
    },
  },
  render() {
    const content = this.$scopedSlots.default && this.$scopedSlots.default({ originData: this.originData })

    return (
      // 由于热力图类目轴需要显示实体卡片，所以取消了echarts tooltip 上的 padding，这边需要添加下
      <div style="padding: 4px 8px 8px 8px">
        <div class="leading-4">
          <div class={$classes.category}>{this.yCategory}</div>
          <div style="margin-top: 8px;">
            <span class={$classes.marker} style={{ backgroundColor: this.color }}></span>
            <span class={$classes.subCategory}>{this.xCategory}</span>
          </div>
          <div style="margin-top: 4px" class={$classes.val}>
            {this.seriesName}: {toThousands(this.count || 0)}
          </div>
          {content ? <div style="margin-top: 12px;">{content}</div> : null}
        </div>
      </div>
    )
  },
})
