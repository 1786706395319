import Vue, { CreateElement, RenderContext } from 'vue'
export const GFunctionButton = Vue.extend({
  functional: true,
  name: 'GFunctionButton',
  render(
    h: CreateElement,
    ctx: RenderContext<{
      propsKey: string
    }>
  ) {
    const slots = ctx.slots()
    return (
      <div class="h-8 inline-flex items-center px-1 hover:bg-gray-30 rounded cursor-pointer" {...ctx.data}>
        {slots.default}
      </div>
    )
  },
})
