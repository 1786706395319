import Vue, { CreateElement, PropType } from 'vue'
import { GIcon } from '../..'
export const GChartTypeButton = Vue.extend({
  functional: true,
  name: 'GChartTypeButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<'small' | 'default'>,
      default: 'default',
    },
  },
  render(h: CreateElement, ctx) {
    const slots = ctx.slots()
    const props = ctx.props
    return (
      <div
        class={[
          'inline-flex items-center rounded cursor-pointer text-sm',
          props.size === 'small' ? 'leading-6 px-1' : 'leading-8 px-2',
          props.active ? 'bg-gray-450 text-white-default' : 'bg-gray-20 hover:bg-gray-30',
        ]}
        {...ctx.data}
      >
        <GIcon svgName={props.icon} class={[props.active ? 'text-white-default' : 'text-gray-450']} size={props.size === 'small' ? 16 : 24}></GIcon>
        {slots.default ? slots.default : <span>{props.text}</span>}
      </div>
    )
  },
})
