import { IBAcTag } from '@pharmsnap/shared/types'
import { IOnBoardingInfo } from '@pharmsnap/shared/types/login'

export function transformData(params: any) {
  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key])
  })
  return formData
}

export function setType(infos: IOnBoardingInfo[] | undefined, type: string): IOnBoardingInfo[] {
  infos?.forEach((info) => {
    info.itemType = type
    // 保存时把temp_name_en回写给name_en
    info.temp_name_en = info.name_en
    info.name_en = info.display_name_en || info.entity_name_en || info.name_en
  })
  return infos as IBAcTag[]
}
