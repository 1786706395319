import { ALL_DEV_STATUS_MAP, clinicalIndexFieldLangMap } from '@patsnap/synapse_common_config'
import { IClinicalIndexField } from '@patsnap/synapse_common_interface'
import { AggStackBarChartConfig, AggStackLineChartConfig } from '@patsnap/synapse_domain'
import { useLocale } from '@pharmsnap/shared/composition'
import { getPhaseColor } from '@pharmsnap/shared/utils'
import { computed, unref } from '@vue/composition-api'
import { IUseAnalysisTwoDimChartEntityOptions } from '../../type'
import { useAnalysisTwoDimChartEntity } from '../useAnalysisTwoDimChartEntity'

export function useAnalysisTrialTwoDimChartEntity(options: Omit<IUseAnalysisTwoDimChartEntityOptions, 'getChartConfig'>) {
  const { xAxisName, yAxisName, layout } = options

  const { locale } = useLocale()
  const getStackChartConfig: IUseAnalysisTwoDimChartEntityOptions['getChartConfig'] = (type, config) => {
    const { firstAggField, secondAggField } = config
    console.log('clinical_trial', options.data)

    const chartConfig: Partial<AggStackBarChartConfig | AggStackLineChartConfig> = {}

    if (firstAggField === 'STUDY_FIRST_POSTED_DATE_YEAR' && secondAggField === 'NORMALIZED_PHASE') {
      if (type === 'line') {
        chartConfig.yCategory = (item) => item.key
        chartConfig.xSorter = (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        }
        chartConfig.color = (item) => getPhaseColor(item.key)

        chartConfig.optionAdjust = (op) => {
          const series = Array.isArray(op.series) ? op.series : ([op.series] as any[])

          series.forEach((s: any) => {
            if (s && s.stack) {
              delete s.stack
            }
          })

          return op
        }
      }
    }

    if (secondAggField === 'NORMALIZED_PHASE') {
      if (type === 'bar') {
        chartConfig.xSorter = (a, b) => {
          const aDictId = a.key
          const bDictId = b.key
          const aRank = ALL_DEV_STATUS_MAP[aDictId]?.rank || -1
          const bRank = ALL_DEV_STATUS_MAP[bDictId]?.rank || -1
          return aRank - bRank
        }
        chartConfig.color = (item) => getPhaseColor(item.key)
      }
    }

    return chartConfig
  }

  const { firstDimAggField, secondDimAggField, chartEntity, dataList, uniqEntityDataList } = useAnalysisTwoDimChartEntity({
    ...options,
    getChartConfig: (type, config) => {
      if (type === 'bar' || type === 'line') return getStackChartConfig(type, config)
      return
    },
  })

  // 聚合实体名称，从轴名称中获取
  const entityFieldName = computed(() => (unref(layout) === 'horizontal' ? unref(yAxisName) : unref(xAxisName)))

  // 聚合数量名称，从轴名称中获取
  const countFieldName = computed(() => (unref(layout) === 'horizontal' ? unref(xAxisName) : unref(yAxisName)))

  // 聚合类目名称，从临床语言包获取
  const stackFieldName = computed(() => clinicalIndexFieldLangMap[unref(secondDimAggField) as IClinicalIndexField]?.[unref(locale)] || '')

  return {
    firstDimAggField,
    secondDimAggField,
    chartEntity,
    dataList,
    uniqEntityDataList,
    entityFieldName,
    countFieldName,
    stackFieldName,
  }
}
