import { sharedCtx } from '@pharmsnap/shared/context'
import { ISignType } from '@pharmsnap/shared/service/apis/searchApi'
import { downloadFileByUrl, openNewTab } from '@pharmsnap/shared/utils'
import { PropType, defineComponent } from '@vue/composition-api'
async function getSignatureUrl(s3_path: string, signType: ISignType) {
  const rt = await sharedCtx.service.search.logoBatchSignatureWithCache({ logoList: [s3_path], signType })
  if (rt.success) {
    return rt.data[s3_path] || ''
  }
  return ''
}
export const BSignaturePdf = defineComponent({
  name: 'BSignaturePdf',
  props: {
    src: {
      required: true,
      type: String,
    },
    signType: {
      type: String as PropType<ISignType>,
      default: 'discovery_attachment',
    },
    extension: {
      type: String,
      default: 'pdf',
    },
    /**
     * pdf签名后的操作，默认下载
     * 针对pdf较大的文件，可以选择预览
     */
    action: {
      type: String as PropType<'download' | 'preview'>,
      default: 'download',
    },
  },
  setup(props) {
    async function onClick(e: Event) {
      e.preventDefault()
      const url = await getSignatureUrl(props.src, props.signType as ISignType)
      props.action === 'download' ? downloadFileByUrl(url, `${Date.now()}.${props.extension}`) : openNewTab(url)
    }
    return { onClick }
  },
  render() {
    return (
      <a class="cursor-pointer" onClick={this.onClick}>
        {this.$slots.default}
      </a>
    )
  },
})
