import { E_NAV_KEY, E_ROUTER_NAME, E_ROUTER_PARAMS } from '@pharmsnap/pharmsnap-web/types/enum'
import { createWebTitle } from '@pharmsnap/pharmsnap-web/utils/business/document-title'
import { RouteConfig } from 'vue-router'

const meta = {
  activeKey: E_NAV_KEY.EMAIL_ALERT,
  title: createWebTitle('情报监控', 'Email Alert'),
}

export default [
  {
    path: `email-alert`,
    name: E_ROUTER_NAME.EMAIL_ALERT,
    component: () => import('@pharmsnap/pharmsnap-web/views/emailAlert/EmailAlert'),
    meta,
  },
  {
    path: `email-alert-detail/:${E_ROUTER_PARAMS.EMAIL_ALERT_ID}`,
    name: E_ROUTER_NAME.EMAIL_ALERT_DETAIL,
    component: () => import('@pharmsnap/pharmsnap-web/views/emailAlert/EmailAlertDetail'),
    meta,
  },
  {
    path: `new-email-alert`,
    name: E_ROUTER_NAME.NEW_EMAIL_ALERT,
    component: () => import('@pharmsnap/pharmsnap-web/views/emailAlert/NewEmailAlert'),
    meta,
  },
] as RouteConfig[]
