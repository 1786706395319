import { GIconProps, GLinkProps } from '@pharmsnap/shared/components/props'
import { EMPTY_PLACEHOLDER } from '@pharmsnap/shared/constants'
import { handleSpace } from '@pharmsnap/shared/utils/encrypt/encryptSpace'
import { defineComponent, PropType } from '@vue/composition-api'
import { GIcon } from '../GIcon/GIcon'
import { GLink } from '../GLink/GLink'
import $classes from './GIconLink.module.scss'

export const GIconLink = defineComponent({
  name: 'GIconLink',
  props: {
    ...GLinkProps,
    ...GIconProps,
    ellipsisLine: {
      type: Number as PropType<-1 | 1 | 2 | 3 | 4>,
      default: -1,
    },
    encryptClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    renderIcon() {
      return this.src || this.svgName ? (
        <GIcon
          class={$classes.icon}
          src={this.src}
          fallbackSrc={this.fallbackSrc}
          border={this.border}
          size={this.size}
          shape={this.shape}
          alt={this.alt}
          svgName={this.svgName}
        ></GIcon>
      ) : (
        this.$slots.icon || null
      )
    },
    renderContent() {
      const truncateMap = {
        1: $classes.linkTruncate,
        2: $classes.linkTruncateTwo,
        3: $classes.linkTruncateThree,
        4: $classes.linkTruncateFour,
      }
      const cls = [$classes.link, this.ellipsisLine === -1 ? '' : truncateMap[this.ellipsisLine] || '']
      return this.encryptClass ? (
        <span class={[cls]}>{handleSpace(this.name || EMPTY_PLACEHOLDER, this.encryptClass)}</span>
      ) : (
        <span class={cls}>{this.name || EMPTY_PLACEHOLDER}</span>
      )
    },
  },
  render() {
    return (
      <GLink
        class={$classes.iconLink}
        style={{ lineHeight: `${this.size}px` }}
        defaultColor={this.defaultColor}
        href={this.href}
        newTab={this.newTab}
      >
        {this.renderIcon()}
        {this.renderContent()}
        {this.$slots.rightIcon ?? null}
      </GLink>
    )
  },
})
