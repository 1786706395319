/* eslint-disable @typescript-eslint/no-explicit-any */
import { IQuery, IQueryDataType, IQueryItemField } from '@patsnap/synapse_common_interface'
import { ERROR_CODE_MAP } from '@pharmsnap/pharmsnap-web/service/error-code-config'
import { E_UPGRADE_TYPE } from '@pharmsnap/pharmsnap-web/types/enum'
import { useAuthStore, useLocale, userErrorMessage } from '@pharmsnap/shared/composition'
import { IQueryService } from '@pharmsnap/shared/composition/useQueryService'
import { ICollapseOptionField } from '@pharmsnap/shared/config'
import { ExportDataTypeLangMap } from '@pharmsnap/shared/constants/data-type-config'
import { sharedCtx } from '@pharmsnap/shared/context'
import { IFieldLangMap, ILang } from '@pharmsnap/shared/types'
import { IFieldConfigData, IFieldShowItem } from '@pharmsnap/shared/types/account'
import { IQueryMustFilter } from '@pharmsnap/shared/types/search'
import { computed, defineComponent, getCurrentInstance, PropType, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { isString, map } from 'lodash'
import { GExportDialog } from '../../ui/GExportDialog/GExportDialog'
import { IExportConfig, IExportFieldConfigItem, IExportFieldItem } from '../../ui/GExportDialog/GExportDialogType'
import { GLoading } from '../../ui/GLoading/GLoading'
import { ICommonListConfig, IExportFieldConfigMap } from '../BCommonList/types'

/**
 * [...已选字段, ...新增字段]
 * @param remoteField 已选字段
 * @param allField
 * @returns
 */
function mergeField(allField: IFieldShowItem[], remoteField: IFieldShowItem[]): IFieldShowItem[] {
  if (remoteField.length === 0) return allField

  const mergedField = allField.slice()

  const tempField = remoteField.slice()

  for (let i = 0; i < mergedField.length; i++) {
    const el: IFieldShowItem = mergedField[i]
    const foundIndexInRemote = tempField.findIndex((item) => item.field === el.field)
    if (foundIndexInRemote === -1) {
      if (i === 0) {
        tempField.unshift(el)
      } else {
        let j = i - 1
        while (j >= 0) {
          const foundIndexInRemote = tempField.findIndex((item) => item.field === mergedField[j].field)
          if (foundIndexInRemote !== -1) {
            tempField.splice(foundIndexInRemote + 1, 0, el)
            break
          }
          j--
        }
      }
    }
  }

  // 移除不在allField中的字段，结构式检索有一个相关度的字段，只在相似度检索才支持导出，所以当正常药物导出时，后端有存储相关度字段，但是前端不需要支持，所以需要移除
  for (let i = tempField.length - 1; i >= 0; i--) {
    const el = tempField[i]
    if (!allField.find((item) => item.field === el.field)) {
      tempField.splice(i, 1)
    }
  }

  return tempField
}

async function getUserExportFieldConfig(dataType: IQueryDataType) {
  const rt = await sharedCtx.service.account.getUserFieldConfig(dataType)
  if (rt.success) {
    return rt.data.export_config
  }
  return undefined
}
function injectFiledConfig(
  fields: IFieldShowItem[],
  langMap: Partial<IFieldLangMap<string>>,
  fieldsConfigMap: Partial<Record<string, IExportFieldConfigMap>>
): IExportFieldItem[] {
  return fields.reduce((acc, item) => {
    const fieldLangMap = langMap[item.field]
    if (!fieldLangMap) {
      console.error(`字段${item.field}语言包没有找到,请检查`)
      return acc
    }
    const fieldConfig = fieldsConfigMap[item.field]
    return [
      ...acc,
      {
        ...item,
        isForbid: !!fieldConfig?.exportFixed,
        fieldNameCn: fieldLangMap.cn,
        tooltip: fieldConfig?.tooltip,
        fieldNameEn: fieldLangMap.en,
      },
    ]
  }, [] as IExportFieldItem[])
}

function generateExportQuery(params: {
  type: 'export_all' | 'export_checked_must' | 'export_checked_filter'
  query: IQueryMustFilter
  checkedIdList?: string[]
  checkedMap?: Record<string, any>
  customDisplayName?: (item: any) => {
    display_name_en: string
    display_name_cn: string
  }
  customCheckedQuery?: (checkedItems: any[]) => IQueryItemField
}): IQuery {
  const { type, query, checkedIdList = [], checkedMap = {}, customCheckedQuery, customDisplayName } = params
  if (type === 'export_all') {
    return {
      type: 'group',
      ...query,
    }
  }
  const checkedItems = map(checkedIdList, (id) => checkedMap[id])
  const defaultCheckedQueryField: IQueryItemField = {
    type: 'field',
    fields: ['_id'],
    value: map(checkedIdList, (id) => {
      const enCnObj = customDisplayName && customDisplayName(checkedMap[id])
      return {
        id: id,
        display_name_en: enCnObj?.display_name_en || '',
        display_name_cn: enCnObj?.display_name_cn || '',
        type: 'CommonEntity',
      }
    }),
  }
  const checkedQueryItems = customCheckedQuery ? [customCheckedQuery(checkedItems)] : [defaultCheckedQueryField]

  if (type === 'export_checked_must') {
    return {
      type: 'group',
      must: [...checkedQueryItems, ...query.must],
      filter: query.filter,
    }
  }

  if (type === 'export_checked_filter') {
    return {
      type: 'group',
      must: query.must,
      filter: [...checkedQueryItems, ...query.filter],
    }
  }

  return {
    type: 'group',
    must: [],
    filter: [],
  }
}

export const BExportDialog = defineComponent({
  name: 'BExportDialog',
  props: {
    dataType: {
      type: String as PropType<IQueryDataType>,
      required: true,
    },
    lang: {
      type: String as PropType<ILang>,
    },
    checkedIdList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    checkedMap: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    commonListConfig: {
      type: Object as PropType<ICommonListConfig>,
    },
    queryService: {
      type: Object as PropType<IQueryService>,
      required: true,
    },
    resultExportLength: {
      type: Number,
      required: true,
    },
    limitPreExport: {
      type: Number,
      required: true,
    },
    exportCallBack: {
      type: Function as PropType<(data: IExportConfig, requestId: string) => Promise<void>>,
    },
    optionType: {
      type: String as PropType<'radio' | 'select'>,
      default: 'select',
    },
    showMaxLimitTip: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { showErrorMessageByToast } = userErrorMessage()
    const {
      actions: { changeUpgrade },
    } = useAuthStore()
    const ins = getCurrentInstance()
    const { tsc } = useLocale()
    const loading = ref(true)
    const exportFieldConfig = ref<IExportFieldConfigItem[]>([])
    const extraExportFieldConfig = ref<IFieldConfigData['export_config']>({ export_fields_config: [] })
    const exportConfig = computed(() => {
      return props.commonListConfig?.exportConfig || []
    })
    const dataTypeName = computed(() => {
      return tsc(`main_entity.${props.dataType}`, props.resultExportLength)
    })
    const exportFormatter = computed(() => {
      return exportConfig.value.find((item) => item.exportDataType === props.dataType)?.exportFormatter
    })
    init()
    async function init() {
      const userExportConfig = await getUserExportFieldConfig(props.dataType)
      const config = exportConfig.value.reduce((acc, exportConfigItem) => {
        const dataTypeLang = ExportDataTypeLangMap[exportConfigItem.exportDataType]
        if (!dataTypeLang) {
          throw new Error(`dataType:${exportConfigItem.exportDataType}语言包没有配置,请检查`)
        }
        const remoteConfig = userExportConfig?.export_fields_config?.find((item) => item.export_data_type === exportConfigItem.exportDataType)
        const exportConfigFields =
          typeof exportConfigItem.fields === 'function' ? exportConfigItem.fields(props.queryService.state) : exportConfigItem.fields
        const mergedFields = mergeField(
          exportConfigFields.map((item) => (isString(item) ? ({ field: item, show: true } as IFieldShowItem) : item)),
          remoteConfig?.fields || []
        )
        const fields = injectFiledConfig(mergedFields, exportConfigItem.fieldLangMap, exportConfigItem.fieldsConfigMap)
        return [
          ...acc,
          {
            exportDataType: exportConfigItem.exportDataType,
            dataTypeNameCn: dataTypeLang.cn,
            dataTypeNameEn: dataTypeLang.en,
            fields,
            include_detail: typeof remoteConfig?.include_detail !== 'undefined' ? remoteConfig?.include_detail : true,
            tooltip: exportConfigItem.tooltip,
          },
        ]
      }, [] as IExportFieldConfigItem[])
      exportFieldConfig.value = config
      extraExportFieldConfig.value = {
        export_fields_config: (userExportConfig?.export_fields_config || []).filter(
          (i) => !config.map((ii) => ii.exportDataType).includes(i.export_data_type)
        ),
      }
      loading.value = false
    }

    function onCancel() {
      emit('cancel')
    }
    async function onExport(data: IExportConfig) {
      // 存储字段配置
      sharedCtx.service.account.setUserFieldConfig({
        data_type: props.dataType,
        export_config: {
          export_fields_config: [...data.exportFieldsConfig, ...(extraExportFieldConfig.value?.export_fields_config || [])],
        },
      })
      const offset = data.exportRangeType === 'range' ? data.from - 1 : 0
      const limit = data.exportRangeType === 'range' ? data.to - offset : props.resultExportLength
      const res = await sharedCtx.service.drug.export(
        {
          data_type: props.dataType,
          lang: props.lang || 'EN',
          query: generateExportQuery({
            type: props.checkedIdList.length ? (data.exportDataType === 'drug_type' ? 'export_checked_filter' : 'export_checked_must') : 'export_all',
            query: props.queryService.state.query,
            checkedIdList: props.checkedIdList,
            checkedMap: props.checkedMap,
            customDisplayName: props.commonListConfig?.getExportDisplayCnEn,
            customCheckedQuery: props.commonListConfig?.getExportQuery,
          }),
          org_roll_up: props.queryService.state.orgRollup,
          query_id: props.queryService.state.queryId,
          from_module: props.commonListConfig?.fromModule,
          offset,
          limit,
          sort: props.queryService.state.sort,
          export_fields: data.fields,
          include_search_detail: data.includeDetail,
          export_type: data.exportDataType,
          query_name:
            props.queryService.state.searchQueryType === 'STRUCTURE'
              ? props.queryService.state.queryStructureText
              : props.queryService.state.queryMustText,
          filter_name: props.queryService.state.queryFilterText,
          query_url: window.location.href,
          export_time: dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          patent_group_desc: collapseRule.value || '',
          ...(props.queryService.state.searchQueryType === 'STRUCTURE'
            ? {
                query_type: props.queryService.state.searchQueryType,
                structure_query: props.queryService.state.structureQuery,
              }
            : undefined),
          ...(props.dataType === 'patent'
            ? {
                collapse: props.queryService.state.collapse,
              }
            : {}),
        },
        {
          ignoreErrorCodes: [ERROR_CODE_MAP.GROUP_EXPORT_LIMIT_ERROR_CODE_130136],
        }
      )
      if (res.success) {
        props.exportCallBack && props.exportCallBack(data, res.data.request_id)
        // 这里添加延迟是为了 GDialog的 onConfirm的流程走完再销毁弹窗
        setTimeout(() => {
          emit('confirm')
        })
      } else {
        // 关闭弹窗
        setTimeout(() => {
          emit('cancel')
        })
        // intercept已处理过错误，无需再提示
        if (res.data.hasShowedError) return

        // 集团账号导出已超限，给出弹窗提示
        if (res.data.numeric_error_code && res.data.numeric_error_code === ERROR_CODE_MAP.GROUP_EXPORT_LIMIT_ERROR_CODE_130136) {
          changeUpgrade({ show: true, type: E_UPGRADE_TYPE.GROUP_EXCEEDS_EXPORT_MAX_LIMIT, trigger_point: 'DOWNLOAD' })
          return
        }

        showErrorMessageByToast(res.data.numeric_error_code)
      }
    }

    /**
     * 专利折叠的string表达
     */
    const collapseRule = computed(() => {
      const collapse = props.queryService.state.collapse
      if (!collapse) return ''
      const collapseTitle = ins?.proxy.$tc(`patent.collapseFields.${collapse.field || '__ALL__'}`)
      const collapseRuleString = collapse.rules.reduce((acc, cur, index) => {
        const field = cur.field as ICollapseOptionField
        const label = ins?.proxy.$tc(`patent.collapseRule.label.${index}`)
        const title = ins?.proxy.$tc(`patent.collapseRule.options.${field}`)
        const type = ['max', 'min'].includes(cur.type) ? ins?.proxy.$tc(`patent.collapseRule.type.${cur.type}`) : ''

        const labelType = type ? `${type}` : ''
        let labelVal = cur.value || ''
        if (field === 'COUNTRY') {
          labelVal = labelVal.replaceAll(',', '>')
        }
        const val = labelVal || labelType ? `,${labelVal || labelType}` : ''
        return `${acc}${label}: ${title}${val};`
      }, '')
      return collapseRuleString ? `${collapseTitle}(${collapseRuleString})` : collapseTitle
    })

    return { exportFieldConfig, exportFormatter, onCancel, onExport, dataTypeName, loading }
  },
  render() {
    if (this.loading) {
      return (
        <div style="height: 500px; position: relative;">
          <GLoading></GLoading>
        </div>
      )
    }
    return (
      <GExportDialog
        exportType={this.checkedIdList.length ? 'selectData' : 'allData'}
        exportFormatter={this.exportFormatter}
        limitPreExport={this.limitPreExport}
        total={this.resultExportLength}
        dataTypeName={this.dataTypeName}
        exportFieldConfig={this.exportFieldConfig}
        exportCallBack={this.onExport}
        cancelCallback={this.onCancel}
        optionType={this.optionType}
        showMaxLimitTip={this.showMaxLimitTip}
      ></GExportDialog>
    )
  },
})
